csmodule.directive('daterange', ['prefix', '$rootScope', '$http', '$modal', '$timeout', function(prefix, $rootScope, $http, $modal, $timeout) {
    var handleClickHandlers = function handleClickHandlers(scope, element) {
        $timeout(function() {
            scope.$evalAsync(function() {
                $rootScope.$broadcast('daterange:template:loaded');
            });
        }, 0);

        var _element = element;

        scope.selectFilter = function selectFilter(filter) {
            if (filter.string != 'Date Range') {
                scope.$broadcast('close:daterange');
            }
        }
    }

    return {
        restrict: 'A',
        templateUrl: 'template/date_filter.html',
        link: handleClickHandlers,
        controller: CSDateFilterCtrl
    };
}]);
