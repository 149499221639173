commusoftCommon.directive('csletter', ['prefix', '$http', '$modal', function(prefix, $http, $modal) {
    return {
        replace: true,
        restrict: 'E',
        scope: {
            title: '@',
            buttonClass: '@',
            previewData: '=',
            shouldBeOpenLetterPreview: '=',
            showPdfView: '@',
            emptyTitle1: '@',
            emptyTitle2: '@'
        },
        controller: function($scope, $element, $attrs, $transclude) {
            $scope.$watch('shouldBeOpenLetterPreview', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal) {
                    modalInstance = $modal.open({
                        templateUrl: 'template/letterpreview_modal.html',
                        scope: $scope,
                        controller: CSLetterCtrl
                    })
                } else {

                }
            });
        }
    }
}]);