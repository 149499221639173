commusoftCommon.directive('cssortable', ['prefix', '$http', '$rootScope', '$interpolate', function(prefix, $http, $rootScope, $interpolate) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var featureAndAccess = element.attr('cssortable-permission');
            var featureArray = featureAndAccess.split(',');
            var featureName = $interpolate(featureArray[0])(scope);
            var featureAccess = featureArray[1];
            var featureId = features[featureName];

            var hasPermission = $rootScope.hasPermission(featureId, featureAccess);

            if(hasPermission) {
                element.sortable({
                    update: function (event, ui) {
                        var sortedArray = new Array();
                        var count = 0;
                        var id = '';
                        element.children('li').each(function () {
                            var id = $(this).find('div.sortable_options > a').attr('id');
                            sortedArray[count] = id;
                            count++;
                        });

                        if (count > 0) {
                            scope.$apply($http.post(prefix + attrs.cssortable, "page=" + scope.currentPage + "&limit=" + scope.limit + "&mode=drag&id=" + sortedArray.join(',')));
                        }
                    }
                })
            } else {
                element.removeClass('ui-sortable');
            }
        }
    }
}]);