commusoftCommon.directive('cspagingoption', ['prefix', '$http', function(prefix, $http) {
    return {
        restrict: 'E',
        scope: {
            totalEntries: '=',
            limit: '=',
            large: '=',
            currentPage: '=',
            screenName: '=',
            records: '=',
        },
        templateUrl: 'template/paging_options.html',
        controller: function($scope, defaultPagingValue) {
            $scope.startFrom = 1;
            $scope.endNumber = defaultPagingValue;

            $scope.$watchCollection('[currentPage, limit, totalEntries]', function(newVal, oldVal){
                $scope.updateStartAndEndNumbers($scope.currentPage);
            });

            $scope.updateStartAndEndNumbers = function(pageNum) {
                if(pageNum != 1) {
                    $scope.startNumber = parseInt($scope.limit) * (pageNum - 1) + 1;
                } else {
                    $scope.startNumber = 1;
                }
                $scope.endNumber = parseInt($scope.limit) * pageNum;
                if($scope.endNumber > $scope.totalEntries) {
                    $scope.endNumber = $scope.totalEntries;
                }
            }

            if ($scope.large) {
                $scope.menuOptions = [
                    { numberOfItems: 5,  innerHeight: 204, outerHeight: 270 },
                    { numberOfItems: 10, innerHeight: 408, outerHeight: 474 },
                    { numberOfItems: 20, innerHeight: 816, outerHeight: 882 },
                    { numberOfItems: 40, innerHeight: 1632, outerHeight: 1764 },
                    { numberOfItems: 80, innerHeight: 3264, outerHeight: 3528 },
                    { numberOfItems: 160, innerHeight: 6528, outerHeight: 7056 }
                ];
            }
            else {
                $scope.menuOptions = [
                    { numberOfItems: 5,  innerHeight: 204, outerHeight: 270 },
                    { numberOfItems: 10, innerHeight: 408, outerHeight: 474 },
                    { numberOfItems: 15, innerHeight: 612, outerHeight: 678 },
                    { numberOfItems: 20, innerHeight: 816, outerHeight: 882 }
                ];
            }
        },
        link: function(scope, element, attrs, ctrl) {
            scope.updateMenu = function(innerHeight, outerHeight, numberOfItems) {
                scope.innerHeight = innerHeight;
                scope.outerHeight = outerHeight;
                scope.limit = numberOfItems;
                $(element).find("#contextual-menu-wrapper").removeClass('open');
            }

            scope.updateListHeight = function() {
                for(var i = 0; i <= scope.menuOptions.length; i++) {
                    if(scope.menuOptions[i].numberOfItems == scope.limit) {
                        scope.innerHeight = scope.menuOptions[i].innerHeight;
                        scope.outerHeight = scope.menuOptions[i].outerHeight;
                        break;
                    }
                }
                angular.element('#contextual-menu-container').css('height', scope.outerHeight + 'px');
                angular.element("#sortables").css('height', scope.innerHeight);
            }

            scope.$watch('records', function(newVal, oldVal) {
                scope.updateListHeight();
            });

            scope.$watch('totalEntries', function(newVal, oldVal) {
                if(newVal == 0) {
                    scope.limit = 5;
                    scope.updateListHeight();
                }
            });

            setTimeout(function(){scope.updateListHeight()},100);
        }
    }
}]);
