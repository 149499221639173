commusoftCommon.directive('csmodaluser', ['prefix', '$http', '$modal',function(prefix, $http, $modal) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            title: '@',
            deleteUrl: '@',
            deleteParams: '@',
            textToConfirm: '@',
            buttonClass: '@',
            buttonValue: '@',
            validationMessage: '@',
            isDefaultValue: '=',
            selectedId: '=',
            shouldBeOpenUser: '=',
            deleteFunc: '&'
        },
        controller: function($scope, $element, $attrs, $transclude) {
            $scope.$watch('shouldBeOpenUser', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal == true) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;

                    modalInstance = $modal.open({
                        templateUrl: 'template/delete_user.html',
                        scope: $scope,
                        controller: CSUserModalCtrl
                    })
                }
            });
        }
    }
}]);