csmodule.controller('addNewProperty', ['$scope', '$rootScope', 'prefix', '$http', 'confirmationBoxHelper', 'postCodeHelper', 'addNewProperty', '$timeout', function($scope, $rootScope, prefix, $http, confirmationBoxHelper, postCodeHelper, addNewProperty, $timeout) {
	/*==========================================================================================
    Initial Variables
  ==========================================================================================*/
  $scope.new_property_forms = {};
  $scope.diary_ctrl_scope = $scope.$parent.$parent.$parent;
  // Default form models
  $scope.choose_property_type_inputs = { property_type: "", customer_type: "" };
	$scope.add_new_customer_inputs = { title: "", first_name: "", surname: "", landline: "", telephone_code: "", mobile: "", mobile_code: "", email: "", postcode: "", addrs_1: "", addrs_2: "", addrs_3: "", town: "",settingsstateprovinceid: "", county: "", advertising_channel: "" };
	$scope.add_new_company_inputs = { company_name: "", postcode: "", addrs_1: "", addrs_2: "", addrs_3: "", town: "",settingsstateprovinceid: "", county: "", title: "", first_name: "", surname: "", position: "", landline: "", telephone_code: "", mobile: "", mobile_code: "", email: "", advertising_channel: "" };
	$scope.add_new_work_address_inputs = { work_address: true, customer: "", branch: "", landlord: "", title: "", first_name: "", surname: "", company_name: "", landline: "", telephone_code: "", mobile: "", mobile_code: "", email: "", postcode: "", addrs_1: "", addrs_2: "", addrs_3: "", town: "", settingsstateprovinceid: "",county: "" };
	$scope.add_new_landlord_inputs = { title: "", first_name: "", surname: "", company_name: "", postcode: "", addrs_1: "", addrs_2: "", addrs_3: "", town: "", settingsstateprovinceid: "",county: "" };

	$scope.property_types = [{ form_type: 'customer', display_name: 'Customer' },{ form_type: 'work_address', display_name: 'Work address' }];
	$scope.customer_types = $scope.diary_ctrl_scope.customerTypes;
	$scope.titles = $scope.diary_ctrl_scope.titles;
    $scope.landlords = $scope.diary_ctrl_scope.landlords;
	$scope.advertising_channels = $scope.diary_ctrl_scope.advertisingTypes;
    $scope.showPostCodeLookUp = $scope.diary_ctrl_scope.showPostCodeLookUp;
    $scope.checkCustomFieldsPermission=$scope.diary_ctrl_scope.checkCustomFieldsPermission;
    var titleId = $scope.add_new_customer_inputs.title = $scope.add_new_work_address_inputs.title = $scope.add_new_landlord_inputs.title = $scope.add_new_company_inputs.title = $scope.titles[0].id;

    $scope.formCustomerCustomFieldsData=[];
    $scope.customFieldsValidate = {};
    $scope.isFormValid = true;
    $scope.formValue = {};
    $scope.previous_property_type = {};
    $scope.previous_customer_type = "";
    $scope.userViewPreference = true;

    $scope.invalid_postcode = true;

    $scope.countyLists = [];
    $http.get(prefix +'/get_state_provinces').success(function (data) {
        $scope.countyLists = data.countyLists;
    });

    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_dont_work_customers',
            data: function (term, page) {
                return { id: 0, 'q': term, 'excludeCustomers': 0, 'mode' : 'diary'};
            },
            results: function (data, page) {
                return { results: data };
            }
        }
    }
    $scope.selectOptions = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_advertising_type',
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }
    $scope.select2OptionsLandLords = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_landlords',
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }


    $scope.$watch('add_new_work_address_inputs.customer', function (newVal, oldVal) {
        if (newVal) {
            var id = $scope.add_new_work_address_inputs.customer.id;
            $http.get(prefix + '/diary/customer/details?id=' + id).success(function (data) {
                $scope.branches = data.branches;
            });

            if($scope.checkCustomFieldsPermission == 1 && $scope.userViewPreference){
                $scope.getCustomFieldTemplates('Workaddress','WorkaddressType',newVal.customerTypeId);
            }
        }
    });

    /*==========================================================================================
        Handle different property / customer types
    ==========================================================================================*/
	$scope.setPropertyType = function setPropertyType() {
        if($scope.formCustomerCustomFieldsData.length>0){
            $scope.checkCustomFieldsLoaded_PropertyType($scope.choose_property_type_inputs.property_type);
        }else{
            $scope.selected_customer_type = null;
            $scope.formCustomerCustomFieldsData='';
            $scope.customFieldsValidate = '';
            $scope.choose_property_type_inputs.customer_type = null;
            $scope.selected_property_type = $scope.choose_property_type_inputs.property_type.form_type;
            $scope.previous_property_type = $scope.choose_property_type_inputs.property_type;
        }
	}

        $scope.setCustomerType = function setCustomerType() {
        if($scope.formCustomerCustomFieldsData.length>0){
            $scope.checkCustomFieldsLoaded_CustomerType($scope.choose_property_type_inputs.customer_type);
        }else{
            var customerType = _.where($scope.customer_types, {id: parseInt($scope.choose_property_type_inputs.customer_type)})[0];
            if(customerType.companynamerequired) {
                $scope.selected_customer_type = 'new_company';
            } else {
                $scope.selected_customer_type = 'new_customer';
            }
            if($scope.selected_property_type=='customer'){
                var objects='Customers';
                var objectTypes='CustomerType';
            }else if($scope.selected_property_type=='work_address'){
                var objects='Workaddress';
                var objectTypes='WorkaddressType';
            }
            $scope.previous_customer_type=$scope.choose_property_type_inputs.customer_type;
            if($scope.checkCustomFieldsPermission == 1 && $scope.userViewPreference){
                $scope.getCustomFieldTemplates(objects,objectTypes,parseInt($scope.choose_property_type_inputs.customer_type));
            }
        }
    }

	/*==========================================================================================
		Reset any input models / view logic variables when going into add new property view
	==========================================================================================*/
	$rootScope.$on('add_new_property:reset_property_type', function() {
        $scope.selected_property_type = null;
        $scope.selected_customer_type = null;
        $scope.choose_property_type_inputs.property_type = null;
        $scope.choose_property_type_inputs.customer_type = null;
        $scope.formCustomerCustomFieldsData='';
        $scope.customFieldsValidate = '';
	});

	/*==========================================================================================
		Set address models in the previous form view with values from the address selected in the
		postcode look up
	==========================================================================================*/
	$rootScope.$on('look_up_postcode:address_selected', function(e, data) {
		$scope[data.returning_inputs_ref].addrs_1 = data.address.addrs_1;
		$scope[data.returning_inputs_ref].addrs_2 = data.address.addrs_2;
		$scope[data.returning_inputs_ref].addrs_3 = data.address.addrs_3;
		$scope[data.returning_inputs_ref].county = data.address.county;
		$scope[data.returning_inputs_ref].town = data.address.town;
		$scope[data.returning_inputs_ref].settingsstateprovinceid = data.address.settingsstateprovinceid;
		$scope[data.returning_inputs_ref].postcode = data.address.postcode;
	});

	/*==========================================================================================
		Check that postcode is valid before removing the disabled state from the postcode look up
		button
	==========================================================================================*/
	$scope.isInputPostcode = function isInputPostcode(postcode) {
		$scope.invalid_postcode = postCodeHelper.validatePostcode(postcode);
	}

	/*==========================================================================================
		Handle saving
	==========================================================================================*/
	$scope.saveCustomer = function saveCustomer() {
        $scope.add_new_customer_inputs.advertising_channel = $scope.add_new_customer_inputs.advertising_channel.id;
        $scope.add_new_customer_inputs.customerType = $scope.choose_property_type_inputs.customer_type;
        $scope.add_new_customer_inputs.telephone_code = document.getElementById('telephone_code')['defaultValue'];
        $scope.add_new_customer_inputs.mobile_code = document.getElementById('mobile_code')['defaultValue'];
        $scope.add_new_customer_inputs.customTemplates=$scope.formValue;
        var deferred = addNewProperty.saveCustomer($scope.add_new_customer_inputs);
        $scope.$emit('add_new_property:pending', deferred);

		deferred.then(function() {
			$scope.add_new_customer_inputs = { title: titleId, first_name: null, surname: null, landline: null, mobile: null, email: null, postcode: null, addrs_1: null, addrs_2: null, addrs_3: null, town: null,settingsstateprovinceid:null, county: null, advertising_channel: null };
		});
	}

	$scope.saveCompany = function saveCompany() {
        $scope.add_new_company_inputs.advertising_channel = $scope.add_new_company_inputs.advertising_channel.id;
        $scope.add_new_company_inputs.customerType = $scope.choose_property_type_inputs.customer_type;
        $scope.add_new_company_inputs.telephone_code = document.getElementById('telephone_code')['defaultValue'];
        $scope.add_new_company_inputs.mobile_code = document.getElementById('mobile_code')['defaultValue'];
        $scope.add_new_company_inputs.title = document.getElementById('title')['value'];
        $scope.add_new_company_inputs.customTemplates=$scope.formValue;
		var deferred = addNewProperty.saveCompany($scope.add_new_company_inputs);
		$scope.$emit('add_new_property:pending', deferred);

		deferred.then(function() {
			$scope.add_new_company_inputs = { company_name: null, postcode: null, addrs_1: null, addrs_2: null, addrs_3: null, town: null, settingsstateprovinceid:null, county: null, title: null, first_name: null, surname: null, position: null, landline: null, mobile: null, email: null, advertising_channel: null };
		});
	}

	$scope.saveWorkAddress = function saveWorkAddress() {
        $scope.add_new_work_address_inputs.telephone_code = document.getElementById('telephone_code')['defaultValue'];
        $scope.add_new_work_address_inputs.mobile_code = document.getElementById('mobile_code')['defaultValue'];
        $scope.add_new_work_address_inputs.landlord = (typeof $scope.add_new_work_address_inputs.landlord !='undefined')?$scope.add_new_work_address_inputs.landlord.id:'';
        $scope.add_new_work_address_inputs.screenType='Web';
        $scope.add_new_work_address_inputs.customTemplates=$scope.formValue;
		var deferred = addNewProperty.saveWorkAddress($scope.add_new_work_address_inputs);
		$scope.$emit('add_new_property:pending', deferred);

		deferred.then(function() {
			$scope.add_new_work_address_inputs = { work_address: true, customer: null, branch: null, landlord: "", title: titleId, first_name: null, surname: null, company_name: null, landline: null, mobile: null, email: null, postcode: null, addrs_1: null, addrs_2: null, addrs_3: null, town: null, settingsstateprovinceid: null, county: null };
		});
	}

	$scope.saveLandlord = function saveLandlord() {
		var deferred = addNewProperty.saveLandlord($scope.add_new_landlord_inputs);
		$scope.$emit('add_new_landlord:pending', deferred);

		deferred.then(function(resp) {
			var landlord = resp.data.responseData.landlord;
			$scope.landlords.push(landlord);
            $timeout(function () {
                $scope.add_new_work_address_inputs.landlord = landlord;
            }, 100);
			$rootScope.$emit('add_new_property:landlord_saved');
            $scope.add_new_landlord_inputs = { title: titleId, first_name: "", surname: "", company_name: "", postcode: "", addrs_1: "", addrs_2: "", addrs_3: "", town: "", settingsstateprovinceid: null, county: "" };
		});
	}

    $scope.$on('smartpostcode:address', function (evt, data) {
        countyId = '';
        //var countyId = _.indexOf($scope.countyLists,data.county);
        var selectedCounty = _.findWhere($scope.countyLists,{'description':data.county});
        //countyId = countyId ? countyId : '';
        if(selectedCounty){
            countyId = selectedCounty.id;
        }else if(data.county){
            $http.post(prefix + '/insert_new_state_provinces?newCounty=' + data.county).success(function (data) {
                var newCounty = {'id':data.countyNewID,'description':data.desc};
                $scope.countyLists.push(newCounty);
                countyId = newCounty.id;

            });
        }

        /* New Customer Screen */
        if ($scope.selected_customer_type == 'new_customer') {
            $scope.add_new_customer_inputs = {
                title: $scope.add_new_customer_inputs.title,
                first_name: $scope.add_new_customer_inputs.first_name,
                surname: $scope.add_new_customer_inputs.surname,
                email: $scope.add_new_customer_inputs.email,
                landline: $scope.add_new_customer_inputs.landline,
                mobile: $scope.add_new_customer_inputs.mobile,
                advertising_channel: $scope.add_new_customer_inputs.advertising_channel,
                addrs_1: data.addressLine1,
                addrs_2: data.addressLine2,
                addrs_3: data.addressLine3,
                town: data.town,
                county: data.county,
                settingsstateprovinceid: countyId,
                postcode: data.postcode
            };
        }
        /* New Company Screen */
        if ($scope.selected_customer_type == 'new_company') {
            $scope.add_new_company_inputs = {
                company_name: $scope.add_new_company_inputs.company_name,
                first_name: $scope.add_new_company_inputs.first_name,
                surname: $scope.add_new_company_inputs.surname,
                position: $scope.add_new_company_inputs.position,
                landline: $scope.add_new_company_inputs.landline,
                mobile: $scope.add_new_company_inputs.mobile,
                email: $scope.add_new_company_inputs.email,
                advertising_channel: $scope.add_new_company_inputs.advertising_channel,
                addrs_1: data.addressLine1,
                addrs_2: data.addressLine2,
                addrs_3: data.addressLine3,
                town: data.town,
                county: data.county,
                settingsstateprovinceid: countyId,
                postcode: data.postcode
            };
        }
        /* New Work Address Screen */
        if ($scope.selected_property_type == 'work_address') {
            /* New Landlord Screen */
            if($scope.$parent.landlordFormOpened) {
                $scope.add_new_landlord_inputs = {
                    title: $scope.add_new_landlord_inputs.title,
                    company_name: $scope.add_new_landlord_inputs.company_name,
                    first_name: $scope.add_new_landlord_inputs.first_name,
                    surname: $scope.add_new_landlord_inputs.surname,
                    email: $scope.add_new_landlord_inputs.email,
                    addrs_1: data.addressLine1,
                    addrs_2: data.addressLine2,
                    addrs_3: data.addressLine3,
                    town: data.town,
                    county: data.county,
                    settingsstateprovinceid: countyId,
                    postcode: data.postcode
                };
            } else {
                $scope.add_new_work_address_inputs = {
                    landlord: $scope.add_new_work_address_inputs.landlord.id,
                    company_name: $scope.add_new_work_address_inputs.company_name,
                    first_name: $scope.add_new_work_address_inputs.first_name,
                    surname: $scope.add_new_work_address_inputs.surname,
                    landline: $scope.add_new_work_address_inputs.landline,
                    mobile: $scope.add_new_work_address_inputs.mobile,
                    email: $scope.add_new_work_address_inputs.email,
                    title: $scope.add_new_work_address_inputs.title,
                    addrs_1: data.addressLine1,
                    addrs_2: data.addressLine2,
                    addrs_3: data.addressLine3,
                    town: data.town,
                    county: data.county,
                    settingsstateprovinceid: countyId,
                    postcode: data.postcode,
                    customer: $scope.add_new_work_address_inputs.customer,
                    branch: $scope.add_new_work_address_inputs.branch
                };
            }

        }
    });

    $scope.getCustomFieldTemplates = function(objects,objectTypes,objectTypeID){
        $http.get(prefix + '/listCustomConfigTemplates?screenMode=addCustomTemplates&action=add&objects='+objects+'&objectTypes='+objectTypes+'&objectTypeID='+objectTypeID+'&propertyID=0&tablePKID=0').success(function (data) {
            let customFieldsArray = [];
            data['templatesList'].forEach((val, key) => {
                customFieldsArray[val.ID]=true;
            });
            $scope.customFieldsValidate=customFieldsArray;
            $scope.formCustomerCustomFieldsData=data.templatesList;
        });
    }

    $scope.getCustomFieldFormValueChanges = function getCustomFieldFormValueChanges(formValue,event){
        $scope.formValue[event.ID] = formValue;
    }

    $scope.getCustomFieldFormStatusChanges = function getCustomFieldFormStatusChanges(isFormValid){
        $scope.customFieldsValidate[isFormValid.ID]=isFormValid.formValidStatus;
        let isValid=true;
        $scope.formCustomerCustomFieldsData.forEach((val, key) => {
            if($scope.customFieldsValidate[val.ID]==false){
                isValid=false;
            }
        });
        $scope.isFormValid = isValid;
    }

    $scope.checkCustomFieldsLoaded_PropertyType = function(selected_property_type) {
        confirmationBoxHelper.getConfirmation("Are you sure you want to" +
            " change the type of property? (Un-saved custom fields will be" +
            " lost)", $scope)
            .then(function() {
                $scope.formCustomerCustomFieldsData=[];
                $scope.isFormValid = true;
                $scope.formValue = {};
                $scope.customFieldsValidate={};

                $scope.selected_customer_type = null;
                $scope.formCustomerCustomFieldsData='';
                $scope.choose_property_type_inputs.customer_type = null;
                $scope.selected_property_type = selected_property_type.form_type;
                $scope.previous_property_type = selected_property_type;
            }).catch(function () {
            $scope.choose_property_type_inputs.property_type=$scope.previous_property_type;
        });
    }

    $scope.checkCustomFieldsLoaded_CustomerType = function(selected_customer_type) {
        confirmationBoxHelper.getConfirmation("Are you sure you want to" +
            " change the type of customer? (Un-saved custom fields will be" +
            " lost)", $scope)
            .then(function() {
                var customerType = _.where($scope.customer_types, {id: parseInt(selected_customer_type)})[0];
                if(customerType.companynamerequired) {
                    $scope.selected_customer_type = 'new_company';
                } else {
                    $scope.selected_customer_type = 'new_customer';
                }
                if($scope.selected_property_type=='customer'){
                    var objects='Customers';
                    var objectTypes='CustomerType';
                }else if($scope.selected_property_type=='work_address'){
                    var objects='Workaddress';
                    var objectTypes='WorkaddressType';
                }
                $scope.previous_customer_type=selected_customer_type;
                if($scope.checkCustomFieldsPermission == 1 && $scope.userViewPreference){
                    $scope.getCustomFieldTemplates(objects,objectTypes,parseInt(selected_customer_type));
                }
            }).catch(function () {
            $scope.choose_property_type_inputs.customer_type=$scope.previous_customer_type;
        });
    }

}]);
