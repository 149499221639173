commusoftCommon.directive('resize', function($window){
    return function (scope, element) {
        var w = angular.element($window);
        let skills_div =  angular.element('#suggestion_skills');
        let length_event =  angular.element('#length_event');
        let range_section =  angular.element('#range_section');
        var changeheight = function (height) { element.css('height', (w.height() - (height+160) ) + 'px'); };
        w.bind('resize', function () {
            setTimeout(function () {
                changeheight((skills_div.height()+range_section.height()+length_event.height()))
            },100)
        });
        skills_div.bind('resize', function () {
            console.log('bind resize')
        });
        setTimeout(function () {
            changeheight((skills_div.height()+range_section.height()+length_event.height()))
        },100)
    }

});