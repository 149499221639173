commusoftCommon.directive('csdownloadoption', ['prefix', '$http', '$compile', function(prefix, $http, $compile) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            pageName: '=',
            channelId: '=',
            id: '='
        },
        controller: function($scope, prefix) {
            $scope.prefix = prefix;
        },
        link:function (scope, elm, prefix) {
            if(scope.pageName == 'AssetStandard' || scope.pageName == 'AssetGroup' || scope.pageName == 'AssetType') {
                var html = '&nbsp;&nbsp;<a class="icon ss-download" target="_self" data-placement="top" tooltip-placement="top" tooltip="Download to JSON" rel="tooltip" data-original-title="Download to Json" download="' + scope.pageName + '.json"' + 'href="' + scope.prefix + '/download_asset_json?page=' + scope.pageName + '&id='+scope.id+'"></a>';
            } else {
                if(scope.channelId != undefined){
                    var html = '&nbsp;&nbsp;<a class="icon ss-download" target="_self" data-placement="top" rel="tooltip" tooltip-placement="top" tooltip="Download to PDF" data-original-title="Download to Pdf" download="' + scope.pageName + '.pdf"' + 'href="' + scope.prefix + '/printChatPdf?page=' + scope.pageName +'&'+'channelid='+ scope.channelId + '"></a>';
                    html += '&nbsp;&nbsp;<a class="icon ss-print" target="_blank" id="printscreen" data-placement="top" rel="tooltip" tooltip-placement="top" tooltip="Print" data-original-title="Print"href="' + scope.prefix + '/printChatPdf?page=' + scope.pageName +'&'+'channelid='+ scope.channelId + '"></a>';
                }else{
                    var html = '&nbsp;&nbsp;<a class="icon ss-download" target="_self" data-placement="top" tooltip-placement="top" tooltip="Download to Excel" rel="tooltip" data-original-title="Download to Excel" download="' + scope.pageName + '.xls"' + 'href="' + scope.prefix + '/download_excel?page=' + scope.pageName + '"></a>';
                    html += '&nbsp;&nbsp;<a class="icon ss-print" target="_blank" id="printscreen" data-placement="top" rel="tooltip" tooltip-placement="top" tooltip="Print" data-original-title="Print"href="' + scope.prefix + '/viewpdf?page=' + scope.pageName + '"></a>';
                }
            }
            elm.html ($compile(html)(scope));
        }
    };
}]);