'use strict';
function CustomerAssetCtrl($scope, getIdData, tableCollection, $document, formPanelCollection, $state, $http, prefix){
    //tableCollection.setData('assets', getIdData.assets);
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.customerSubNavStatic = false;
    $scope.selectedTab = "Assets";
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.assetGroupRecords = getIdData.assetGroupRecords;
    $scope.dropdownFilters = getIdData.dropdownFilters;
    //# By default filter dropdown will be groupId
    $scope.assetFilter = 'groupId';

    $scope.selectFilter = function(filterData) {
        $scope.filterData = encodeURIComponent(filterData);
        $http.get(prefix + '/getAssetFilter/'+$scope.selectedId+'?filter=' + encodeURIComponent(filterData)).success(function (data) {
            $scope.assetGroupRecords = data.assetGroupRecords;
        });
    }

    $scope.selectFilter($scope.assetFilter);

}
function CustomerAddAssetCtrl($scope, $rootScope, $stateParams, $http, $state, prefix, getIdData, warningModal, toastBox,$location){
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type;
    $scope.assetId = $state.params.assetId? $state.params.assetId : '';
    $scope.assetTypeId = $state.params.assetTypeId? $state.params.assetTypeId : '';
    $scope.isSubAsset = $state.params.assetId? true : false;
    $scope.showStandardForm = getIdData.showStandardForm;
    $scope.parentAssetId = $state.params.parentAssetId? $state.params.parentAssetId : '';
    $scope.isDisabled = true;
    $scope.customerAssetDesign = false;
    $scope.$emit('displayAssetScreen', false);
    $scope.show_loader=false;

    //# Check sub asset is valid or not
    if(getIdData.errorMsg && $scope.isSubAsset) {
        warningModal.show(getIdData.errorMsg, 'Asset', 'Invalid');
        $state.transitionTo('loggedin.customer_list.view.assets', {'id': $state.params.id,'type': $state.params.type });
        return true;
    }

    $scope.customerDetails = getIdData.customerDetails;
    $scope.validateAction = false;

    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);

    $scope.customerSubNavStatic = true;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': '', 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.assetGroups = getIdData.assetGroups;

    if($scope.isSubAsset) {
        $scope.superParentAssetId = getIdData.superParentAssetId? getIdData.superParentAssetId : '';

        $scope.$emit('assetId:selected', { 'assetId' : $scope.assetId, 'parentAssetId' : $scope.parentAssetId,
            'superParentAssetId' : $scope.superParentAssetId, 'assetIdDescription' : getIdData.assetIdDescription,
            'parentAssetIdDescription' : getIdData.parentAssetIdDescription});
    }

    $scope.changeAssetGroupType = function() {
        if(typeof this.assetGroup != 'undefined') {
            $scope.assetTypeId = this.assetGroup;
        }
        $scope.show_loader=true;
        $http.get(prefix + '/getSpecificAssetDetails?showStandardForm='+ $scope.showStandardForm +'&assetTypeId=' + encodeURIComponent($scope.assetTypeId)).success(function (data) {
            $scope.$broadcast("Dynamic_Form_View_Inputs", { 'assetRecords': data.assetRecords});
            $scope.show_loader=false;
            console.log(angular.toJson(data.assetRecords));
        });
    };

    if($scope.assetId && $scope.assetTypeId) {
        $scope.changeAssetGroupType();
    }

    $scope.$on("handleSave", function(event, data) {
        $scope.updating = true;
        console.log(angular.toJson(data.assetDetails));
        console.log($scope.assetTypeId);
        console.log($scope.selectedId);

        var params = "asset[id]="+ $scope.selectedId
            + "&asset[typeId]=" + encodeURIComponent($scope.assetTypeId)
            + "&asset[data]=" + encodeURIComponent(angular.toJson(data.assetDetails));

        if($scope.assetId) {
            params = params + "&asset[assetId]=" + $scope.assetId;
        }
        $scope.saving=true;
        $http.post(prefix + '/saveAssetDetails', params).success(function (data) {
            if(angular.isArray(data)) {
                toastBox.show('Invalid form data', 2000);
                $scope.saving = false;
            } else {
                $rootScope.flashMessage = "Record inserted";
                $scope.viewAssetDetails($scope.selectedId, $scope.customerTabName, data, $scope.assetId);
                //$scope.updating = $scope.saving = false;
            }
        });
    });

    $scope.cancel = function(){
        if($scope.parentAssetId) {
            $state.transitionTo('loggedin.customer_list.view.assets.details.associated_asset_list', {'id':$scope.selectedId,'type':$scope.customerTabName,'parentAssetId':$scope.parentAssetId,'assetId':$scope.assetId})
        } else if($scope.assetId) {
            $state.transitionTo('loggedin.customer_list.view.assets.details.associated_asset', {'id': $scope.selectedId, 'type': $scope.customerTabName, 'assetId':$scope.assetId});
        } else {
            // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
                $location.path('/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/view_v2/assets/view');
            // }else{
            //     $state.transitionTo('loggedin.customer_list.view.assets', {'id': $scope.selectedId, 'type': $scope.customerTabName});
            // }
        }
    }
}

function CustomerAssetSubNavCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal,$location) {
    $scope.assetId = $state.params.assetId || getIdData.assetId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.hideSubNavCustomers = true;
    $scope.customerAssetDesign = true;
    $scope.$emit('displayAssetScreen', true);
    //$scope.assetSubNav = true;
    $scope.show_tabs = true;

    $scope.type = getIdData.customerType;
    $scope.tabCustomerSurname = getIdData.customerName;
    $scope.parentPropertyName = getIdData.parentPropertyName;

    $scope.tabAddressline1 = $scope.tabAddressline2 = $scope.tabAddressline3 = $scope.tabTown = $scope.tabCounty = $scope.tabPostcode = '';
    if(getIdData.addressline1){ $scope.tabAddressline1 = getIdData.addressline1; }
    if(getIdData.addressline2){ $scope.tabAddressline2 = ', '+ getIdData.addressline2; }
    if(getIdData.addressline3){ $scope.tabAddressline3 = ', '+ getIdData.addressline3; }
    if(getIdData.town){ $scope.tabTown = ', '+ getIdData.town; }
    if(getIdData.county){ $scope.tabCounty = ', '+ getIdData.county; }
    if(getIdData.postcode){ $scope.tabPostcode = ', '+ getIdData.postcode; }

    $scope.customerAddress = $scope.tabAddressline1 + $scope.tabAddressline2 + $scope.tabAddressline3 + $scope.tabTown + $scope.tabCounty + $scope.tabPostcode;

    //$scope.$emit('assetId:selected', { 'assetId' : getIdData.assetId});

    $scope.$on('assetTab:selected', function(event, data) {
        $scope.assetSubNav = data.assetSubNav;
        $scope.show_tabs = data.show_tabs;
    });

    $scope.showAssetDeleteBox = function () {
        var fetchUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/assets/' + $scope.assetId + '/is-deletable';
        $http.get(fetchUrl).success(function (data) {
            console.log('data', data);
            if (data.deletable === false || data.deletable === 'false') {
                warningModal.show(data.reason, 'Delete asset');
            } else {
                $scope.deleteAssetShouldBeOpen = true;
            }
        });
    }

    $scope.deleteAssetDetails = function() {
        $scope.flashMessage = 'Asset deleted';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        if($scope.superParentAssetId) {
            $state.transitionTo('loggedin.customer_list.view.assets.details.associated_asset_list', {'id':$scope.selectedId,'type':$scope.customerTabName,'parentAssetId':$scope.superParentAssetId,'assetId':$scope.parentAssetId}, {reload:true})
        } else if($scope.parentAssetId) {
            $state.transitionTo('loggedin.customer_list.view.assets.details.associated_asset', {'id': $scope.selectedId, 'type': $scope.customerTabName, 'assetId':$scope.parentAssetId}, {reload:true});
        } else {
            // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
                $location.path('/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/view_v2/assets/view');
            // }else{
            //     $state.transitionTo('loggedin.customer_list.view.assets', {'id': $scope.selectedId, 'type': $scope.customerTabName}, {reload: true});
            // }
        }

    }
}

function AssetViewCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $translate, $compile, $timeout) {
    $scope.assetId = $state.params.assetId || getIdData.assetId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.assetGroupName = getIdData.assetGroupName ? getIdData.assetGroupName : '';
    $scope.assetTypeName = getIdData.assetTypeName ? getIdData.assetTypeName : '';
    $scope.show_loader=true;

    //# Check asset is valid or not
    if(getIdData.errorMsg) {
        warningModal.show(getIdData.errorMsg, 'Asset', 'assetDeleted');
        $state.transitionTo('loggedin.customer_list.view.assets', {'id': $state.params.id,'type': $state.params.type }, {reload:true});
        return true;
    }

    $scope.hideSubNavCustomers = true;
    $scope.show_tabs = true;
    $scope.$emit('assetTab:selected', { 'assetSubNav' : false, 'show_tabs' : true} );
    $scope.assetDetails = getIdData.assetDetails;
    $scope.assetRecordValues = getIdData.assetRecordValues;
    $scope.assetRecordValuesId = getIdData.assetRecordValuesId;

    $scope.$emit('assetId:selected', { 'assetId' : $scope.assetId, 'assetIdDescription' : getIdData.assetIdDescription});

    $timeout(function() {
        $scope.$broadcast("Dynamic_Form_View_Inputs", { 'assetRecords': getIdData.assetDetails});
    }, 1000);
}
function CustomerEditAssetCtrl($scope, $state, prefix, $http, getIdData, $timeout, $rootScope, warningModal, toastBox) {
    $scope.assetId = $state.params.assetId || getIdData.assetId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.parentAssetId = $state.params.parentAssetId?$state.params.parentAssetId : '';
    $scope.assetGroupName = getIdData.assetGroupName ? getIdData.assetGroupName : '';
    $scope.assetTypeName = getIdData.assetTypeName ? getIdData.assetTypeName : '';
    $scope.isDisabled = false;
    $scope.show_loader=true;
    //# Check asset is valid or not
    if(getIdData.errorMsg) {
        warningModal.show(getIdData.errorMsg, 'Invalid asset', 'assetDeleted');
        $state.transitionTo('loggedin.customer_list.view.assets', {'id': $state.params.id,'type': $state.params.type }, {reload:true});
        return true;
    }

    $scope.$emit('displayAssetScreen', true);
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.$emit('assetTab:selected', { 'assetSubNav' : true, 'show_tabs' : false} );
    $scope.superParentAssetId = getIdData.superParentAssetId? getIdData.superParentAssetId : '';

    $scope.$emit('assetId:selected', { 'assetId' : $scope.assetId, 'parentAssetId' : $scope.parentAssetId,
        'superParentAssetId' : $scope.superParentAssetId, 'parentAssetIdDescription' : getIdData.parentAssetIdDescription,
        'assetIdDescription' : getIdData.assetIdDescription});

    $scope.assetRecordValues = getIdData.assetRecordValues;
    $scope.assetRecordValuesId = getIdData.assetRecordValuesId;

    $scope.cancel = function() {
        if($scope.parentAssetId) {
            $state.transitionTo('loggedin.customer_list.view.assets.details.view_sub_asset', {'id': $state.params.id,'type': $state.params.type, 'parentAssetId': $scope.parentAssetId, 'assetId':$scope.assetId });
        } else {
            $state.transitionTo('loggedin.customer_list.view.assets.details.view_asset', {'id': $state.params.id,'type': $state.params.type, 'assetId' : $scope.assetId });
        }
    }

    $timeout(function() {
        console.log(angular.toJson(getIdData.assetRecords));
        $scope.$broadcast("Dynamic_Form_View_Inputs", { 'assetRecords': getIdData.assetRecords});
    }, 1000);

    $scope.$on("handleSave", function(event, data) {
        $scope.updating = true;
        console.log(angular.toJson(data.assetDetails));
        console.log($scope.assetId);
        console.log($scope.selectedId);
        $scope.saving=true;
        $http.post(prefix + '/saveAssetDetails', "asset[id]="+ $scope.selectedId
            + "&asset[assetId]=" + encodeURIComponent($scope.assetId)
            + "&asset[data]=" + encodeURIComponent(angular.toJson(data.assetDetails))).success(function (data) {
                if(angular.isArray(data)) {
                    toastBox.show('Invalid form data', 2000);
                    $scope.saving = false;
                } else {
                    $rootScope.flashMessage = "Record updated";
                    $scope.viewAssetDetails($scope.selectedId, $scope.customerTabName, $scope.assetId, $scope.parentAssetId);
                    //$scope.updating = $scope.saving = false;
                }
        });
    });
}

function AssociatedAssetViewCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $translate, $compile, $timeout) {
    $scope.assetId = $state.params.assetId || getIdData.assetId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.parentAssetId = (typeof $state.params.parentAssetId != 'undefined') ? $state.params.parentAssetId : '';
    $scope.show_loader=true;
    //# Check asset is valid or not
    if(getIdData.errorMsg) {
        warningModal.show(getIdData.errorMsg, 'Invalid asset', 'assetDeleted');
        $state.transitionTo('loggedin.customer_list.view.assets', {'id': $state.params.id,'type': $state.params.type }, {reload:true});
        return true;
    }

    $scope.hideSubNavCustomers = true;
    $scope.show_tabs = true;
    $scope.subAssetTypes = getIdData.subAssetTypes;
    $scope.showSubAssetRecords = getIdData.showSubAssetRecords;
    $scope.subAssetParentAssetId = getIdData.subAssetParentAssetId;
    $scope.$emit('assetTab:selected', { 'assetSubNav' : false, 'show_tabs' : true} );
    $scope.superParentAssetId = getIdData.superParentAssetId? getIdData.superParentAssetId : '';

    $scope.$emit('assetId:selected', { 'assetId' : $scope.assetId, 'parentAssetId' : $scope.parentAssetId,
        'superParentAssetId' : $scope.superParentAssetId, 'parentAssetIdDescription' : getIdData.parentAssetIdDescription,
        'assetIdDescription' : getIdData.assetIdDescription});
}

function AssetSingleViewCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $translate, $compile, $timeout) {
    $scope.assetId = $state.params.assetId || getIdData.assetId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;

    //# Check sub asset is valid or not
    if(getIdData.errorMsg) {
        warningModal.show(getIdData.errorMsg, 'Asset', 'InvalidAssociate');
        $state.transitionTo('loggedin.customer_list.view.assets', {'id': $state.params.id,'type': $state.params.type }, {reload:true});
        return true;
    }

    $scope.hideSubNavCustomers = true;
    $scope.show_tabs = true;
    $scope.$emit('assetTab:selected', { 'assetSubNav' : false, 'show_tabs' : true} );
    $scope.assetDetails = getIdData.assetDetails;
    $scope.assetRecordValues = getIdData.assetRecordValues;
    $scope.assetRecordValuesId = getIdData.assetRecordValuesId;
    $scope.parentAssetId = $state.params.parentAssetId || getIdData.parentAssetId;
    $scope.superParentAssetId = getIdData.superParentAssetId? getIdData.superParentAssetId : '';

    $scope.$emit('assetId:selected', { 'assetId' : $scope.assetId, 'parentAssetId' : $scope.parentAssetId,
        'superParentAssetId' : $scope.superParentAssetId, 'assetIdDescription' : getIdData.assetIdDescription,
        'parentAssetIdDescription' : getIdData.parentAssetIdDescription});

    $timeout(function() {
        $scope.$broadcast("Dynamic_Form_View_Inputs", { 'assetRecords': getIdData.assetDetails});
    }, 1000);
}
function AssetJobHistoryViewCtrl($scope, $state, defaultPagingValue, getIdData, prefix, $http) {
    $scope.assetId = $state.params.assetId || getIdData.assetId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;

    $scope.hideSubNavCustomers = true;
    $scope.show_tabs = true;
    $scope.$emit('assetTab:selected', { 'assetSubNav' : false, 'show_tabs' : true} );

    $scope.parentAssetId = $state.params.parentAssetId || getIdData.parentAssetId;
    $scope.superParentAssetId = getIdData.superParentAssetId? getIdData.superParentAssetId : '';

    $scope.$emit('assetId:selected', { 'assetId' : $scope.assetId, 'parentAssetId' : $scope.parentAssetId,
        'superParentAssetId' : $scope.superParentAssetId, 'parentAssetIdDescription' : getIdData.parentAssetIdDescription,
        'assetIdDescription' : getIdData.assetIdDescription});

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.pageNum = parseInt($state.current.data.pageNum);
    } else {
        $scope.pageNum = 1;
    }

    $scope.jobHistory = getIdData.jobHistory;
    $scope.jobHistoryCount = getIdData.jobHistoryCount;

    var fetchUrl = prefix + '/customers/'+$scope.customerTabName+'/' + $scope.selectedId + '/assets/'+$scope.assetId+'/job/history/view';

    $scope.fetchJobHistory = function (pageNum) {
        $http.get(fetchUrl + '?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.jobHistory = getIdData.jobHistory;
            $scope.jobHistoryCount = getIdData.jobHistoryCount;
        })
    }

    $scope.newJob = function() {
        $state.transitionTo('loggedin.customer_list.view.asset_new_job', {'id': $scope.selectedId,'type': $scope.customerTabName,'assetId': $scope.assetId }, {reload:true});
    }
    $scope.viewJob = function(jobId) {
        $state.transitionTo('loggedin.customer_list.view.job.details', {'id': $scope.selectedId,'type': $scope.customerTabName,'jobId': jobId }, {reload:true});
    }
}