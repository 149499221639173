commusoftCommon.directive('operationalTable', ['prefix', '$http', '$modal', 'tableCollection', '$document', '$timeout', 'clickEvents', '$rootScope', '$compile', 'dragHelper', '$state', function(prefix, $http, $modal, tableCollection, $document, $timeout, clickEvents, $rootScope, $compile, dragHelper, $state) {
    return {
        restrict: 'ACE',
        controller: function($scope) {
            $scope.getTemplateUrl = function() {
                return 'template/operational_table/' + $scope.templateSource + '.html';
            }
        },
        template: '<ng-include src="getTemplateUrl()"/>',
        scope: {
            templateSource: '@template'
        }
    };
}]);