commusoftCommon.service('jobInvoices', function($rootScope) {

    invoice_with_payments_and_edit = {
        id : '12345',
        type: 'Final',
        date : 'Mon 24th Dec 2013',
        breakdown: 'no_breakdown',
        job_number : '12345',
        description : 'No breakdown and edited invoice with payments',
        more_details : 'Old boiler removed, area tidied, new boiler fitted and gas safety certificate provided',
        nominal_code : '100 - Parts',
        job_number : '2300',
        total : 810,
        taxes : 64.80,
        cis: 810 * 0.3,
        grand_total : 810 + 64.80 + (810 * 0.3),
        balance: 100,
        printed : 'Printed By Joseph Luck on 23rd November 2014',
        with_line_items: true,
        sent_to_accounting_package: true,
        audits : [
            {
                type : 'sending',
                message : 'Sent to the customer',
                additional_message : '- Joseph Luck printed this invoice',
                date : 'Wed 23rd November 2014',
                time : '10:24am'
            },
            {
                type : 'edit',
                message : 'Edited',
                name : 'Joseph Luck',
                date : 'Wed 23rd November 2014',
                time : '14:32pm',
                required : true,
                completed : {
                    completed : false,
                    message : 'Marked off in SAGE',
                    name : 'Joseph Luck',
                    date : 'Tues 24th November 2014',
                    time : '14:32pm'
                }
            },
            {
                type : 'check',
                message : 'Successfully saved in SAGE',
                date : 'Tue 22th November 2014',
                time : '10:11am'
            },
            {
                type : 'warning',
                message : 'Failed to save in SAGE because the credentials in company settings are incorrect',
                date : 'Sun 20th September 2014',
                time : '19:14pm',
                help_text : 'You can resend this record by going to quick links'
            }
        ],
        items : [
            {
                id : 243425234,
                type : 'Part',
                description : 'Ariston E-Combi Evo 30HE Condensing Combi Gas Boiler ',
                cis: 'Yes',
                quantity : '1',
                nominal: '232',
                cost : 300,
                price : 45,
                VAT_rate : '20%',
                total : 300
            },
            {
                id : 59340785,
                type : 'Part',
                description : '"6" 316 / 316 Flexible Flue Liner',
                cis: 'Yes',
                quantity : '3',
                nominal: '232',
                cost : 100,
                price : 45,
                VAT_rate : '20%',
                total : 300
            },
            {
                id : 59028411,
                type : 'Labour',
                description : 'Perform all the prep work for installing the new boiler',
                cis: 'Yes',
                quantity : '3.5',
                nominal: '232',
                cost : 60,
                price : 45,
                VAT_rate : '20%',
                total : 210
            }
        ],
        payments : [
            {
                id: 879325,
                date: 'Mon 25th Dec 2013',
                method: 'Card',
                description: 'Payment for single invoice',
                reference: '879325',
                nominal : '322',
                amount : 20,
                sent_to_accounting_package : true,
                audits : [
                    {
                        type : 'check',
                        message : 'Sent to SAGE',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm',
                        edits : [
                            {
                                type : 'edit',
                                message : 'Edited',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : true,
                                completed : {
                                    completed : true,
                                    message : 'Marked off in SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm'
                                }
                            },
                            {
                                type : 'resend',
                                message : 'Resent to SAGE',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : false
                            }
                        ]
                    }
                ],
                invoices : [
                    {
                        id : 589023,
                        description : 'Card charge invoice (from a payment)',
                        nominal_code : '100 - Parts',
                        amount : 388.80,
                        remainder : 0
                    }
                ],
                card_details : {
                    name_on_card: 'Joseph Luck',
                    email_address: 'joseph.luck@commusoft.com',
                    telephone_number: '+44 74392 39482',
                    card_number: '**** **** **** 4828',
                    expiry_date: '** / **',
                    cvc_code: '***',
                    address_line_1 : '6',
                    address_line_2 : 'Old street',
                    address_line_3 : 'Shoreditch',
                    town : 'London',
                    county : 'Greater London',
                    postcode: 'N2, 3DL'
                }
            },
            {
                id: 34237801,
                date: 'Mon 28th Dec 2013',
                method: 'Credit',
                description: 'Credit allocation for multiple invoices (cash allocation)',
                reference: '34237801',
                nominal : '322',
                amount : 20,
                sent_to_accounting_package : true,
                audits : [
                    {
                        type : 'check',
                        message : 'Sent to SAGE',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm',
                        edits : [
                            {
                                type : 'edit',
                                message : 'Edited',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : true,
                                completed : {
                                    completed : true,
                                    message : 'Marked off in SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm'
                                }
                            },
                            {
                                type : 'resend',
                                message : 'Resent to SAGE',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : false
                            }
                        ]
                    }
                ],
                invoices : [
                    {
                        id : 12345,
                        description : 'Baxi Boiler',
                        nominal_code : '100 - Parts',
                        amount : 20,
                        remainder : 100
                    },
                    {
                        id : 54253141,
                        description : 'Baxi Boiler',
                        nominal_code : '100 - Parts',
                        amount : 20,
                        remainder : 100
                    }
                ]
            },
            {
                id: 2589074,
                date: 'Mon 19th Jan 2014',
                method: 'Cheque',
                description: 'Cheque payment for multiple invoices',
                reference: '2589074',
                nominal : '322',
                amount : 20,
                sent_to_accounting_package : true,
                audits : [
                    {
                        type : 'check',
                        message : 'Sent to SAGE',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm',
                        edits : [
                            {
                                type : 'edit',
                                message : 'Edited',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : true,
                                completed : {
                                    completed : true,
                                    message : 'Marked off in SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm'
                                }
                            },
                            {
                                type : 'resend',
                                message : 'Resent to SAGE',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : false
                            }
                        ]
                    }
                ],
                invoices : [
                    {
                        id : 12345,
                        description : 'Baxi Boiler',
                        nominal_code : '100 - Parts',
                        amount : 20,
                        remainder : 100
                    },
                    {
                        id : 54253141,
                        description : 'Baxi Boiler',
                        nominal_code : '100 - Parts',
                        amount : 20,
                        remainder : 100
                    }
                ]
            }
        ]
    };

    invoice_without_payments = {
        id : '435231',
        type: 'Partial',
        date : 'Sat 30th Dec 2013',
        breakdown: 'category_breakdown',
        description : 'Category breakdown without payments',
        more_details : 'Old boiler removed, area tidied, new boiler fitted and gas safety certificate provided',
        nominal_code : '100 - Parts',
        job_number : '2300',
        total : 810,
        taxes : 64.80,
        cis: 810 * 0.3,
        grand_total : 810 + 64.80 + (810 * 0.3),
        balance: 100,
        printed : 'Printed By Joseph Luck on 23rd November 2014',
        with_line_items: false,
        sent_to_accounting_package: false,
        items : [
            {
                id : 59023851,
                type : 'Part',
                description : 'Parts',
                cis: 'Yes',
                quantity : '1',
                nominal: '232',
                cost : 600,
                price : 45,
                VAT_rate : '20%',
                total : 600
            },
            {
                id : 059270134,
                type : 'Labour',
                description : 'Labour',
                cis: 'Yes',
                quantity : '1',
                nominal: '232',
                cost : 210,
                price : 45,
                VAT_rate : '20%',
                total : 210
            }
        ],
        payments : [
        ]
    };

    invoice_on_way_to_accounting_package = {
        id : '6578987',
        type: 'Partial',
        date : 'Mon 24th Dec 2013',
        breakdown: 'full_breakdown',
        job_number : '6578987',
        description : 'Full breakdown invoice on its way to the accounting package',
        more_details : 'Old boiler removed, area tidied, new boiler fitted and gas safety certificate provided',
        job_number : '2700',
        nominal_code : '100 - Parts',
        total : 810,
        taxes : 64.80,
        cis: 810 * 0.3,
        grand_total : 810 + 64.80 + (810 * 0.3),
        balance: 100,
        printed : 'Printed By Joseph Luck on 23rd November 2014',
        with_line_items: true,
        sent_to_accounting_package: false,
        audits : [
            {
                type : 'sending',
                message : 'On its way to SAGE',
                date : 'Tues 24th November 2014',
                time : '14:32pm'
            }
        ],
        items : [
            {
                id : 12345902735,
                type : 'Part',
                description : 'Ariston E-Combi Evo 30HE Condensing Combi Gas Boiler ',
                cis: 'Yes',
                quantity : '1',
                nominal: '232',
                cost : 300,
                price : 45,
                VAT_rate : '20%',
                total : 300
            },
            {
                id : 9072395732,
                type : 'Part',
                description : '"6" 316 / 316 Flexible Flue Liner',
                cis: 'Yes',
                quantity : '3',
                nominal: '232',
                cost : 100,
                price : 45,
                VAT_rate : '20%',
                total : 300
            },
            {
                id : 89723951,
                type : 'Labour',
                description : 'Perform all the prep work for installing the new boiler',
                cis: 'Yes',
                quantity : '3.5',
                nominal: '232',
                cost : 60,
                price : 45,
                VAT_rate : '20%',
                total : 210
            }
        ],
        payments : [
            {
                id: 879325,
                date: 'Mon 25th Dec 2013',
                method: 'Cash',
                description: 'Payment for single invoice',
                reference: '879325',
                nominal : '322',
                amount : 20,
                sent_to_accounting_package : true,
                audits : [
                    {
                        type : 'check',
                        message : 'Sent to SAGE',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm',
                        edits : [
                            {
                                type : 'edit',
                                message : 'Edited',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : true,
                                completed : {
                                    completed : true,
                                    message : 'Marked off in SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm'
                                }
                            },
                            {
                                type : 'resend',
                                message : 'Resent to SAGE',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : false
                            }
                        ]
                    }
                ],
                invoices : [
                    {
                        id : 589023,
                        description : 'Card charge invoice (from a payment)',
                        nominal_code : '100 - Parts',
                        amount : 388.80,
                        remainder : 0
                    }
                ]
            },
            {
                id: 34237801,
                date: 'Mon 28th Dec 2013',
                method: 'Credit',
                description: 'Credit allocation for multiple invoices (cash allocation)',
                reference: '34237801',
                nominal : '322',
                amount : 20,
                sent_to_accounting_package : true,
                audits : [
                    {
                        type : 'check',
                        message : 'Sent to SAGE',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm',
                        edits : [
                            {
                                type : 'edit',
                                message : 'Edited',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : true,
                                completed : {
                                    completed : true,
                                    message : 'Marked off in SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm'
                                }
                            },
                            {
                                type : 'resend',
                                message : 'Resent to SAGE',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : false
                            }
                        ]
                    }
                ],
                invoices : [
                    {
                        id : 6578987,
                        description : 'Baxi Boiler',
                        nominal_code : '100 - Parts',
                        amount : 20,
                        remainder : 100
                    },
                    {
                        id : 948327,
                        description : 'Baxi Boiler',
                        nominal_code : '100 - Parts',
                        amount : 20,
                        remainder : 100
                    }
                ]
            },
            {
                id: 2589074,
                date: 'Mon 19th Jan 2014',
                method: 'Cheque',
                description: 'Cheque payment for multiple invoices',
                reference: '2589074',
                nominal : '322',
                amount : 20,
                sent_to_accounting_package : true,
                audits : [
                    {
                        type : 'check',
                        message : 'Sent to SAGE',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm',
                        edits : [
                            {
                                type : 'edit',
                                message : 'Edited',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : true,
                                completed : {
                                    completed : true,
                                    message : 'Marked off in SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm'
                                }
                            },
                            {
                                type : 'resend',
                                message : 'Resent to SAGE',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : false
                            }
                        ]
                    }
                ],
                invoices : [
                    {
                        id : 6578987,
                        description : 'Baxi Boiler',
                        nominal_code : '100 - Parts',
                        amount : 20,
                        remainder : 100
                    },
                    {
                        id : 54253141,
                        description : 'Baxi Boiler',
                        nominal_code : '100 - Parts',
                        amount : 20,
                        remainder : 100
                    }
                ]
            }
        ]
    };

    full_breakdown_by_category_invoice = {
        id : '948327',
        type: 'Partial',
        date : 'Mon 24th Dec 2013',
        breakdown: 'full_breakdown_by_category',
        description : 'Full breakdown by category invoice on its way to the accounting package',
        more_details : 'Old boiler removed, area tidied, new boiler fitted and gas safety certificate provided',
        job_number : '2300',
        nominal_code : '100 - Parts',
        total : 810,
        taxes : 64.80,
        cis: 810 * 0.3,
        grand_total : 810 + 64.80 + (810 * 0.3),
        balance: 100,
        printed : 'Printed By Joseph Luck on 23rd November 2014',
        with_line_items: true,
        sent_to_accounting_package: false,
        audits : [
            {
                type : 'sending',
                message : 'On its way to SAGE',
                date : 'Tues 24th November 2014',
                time : '14:32pm'
            }
        ],
        items : {
            parts: [
                {
                    id : 31905823431,
                    type : 'Part',
                    description : 'Ariston E-Combi Evo 30HE Condensing Combi Gas Boiler ',
                    cis: 'Yes',
                    quantity : '1',
                    nominal: '232',
                    cost : 300,
                    price : 45,
                    VAT_rate : '20%',
                    total : 300
                },
                {
                    id : 6783945954,
                    type : 'Part',
                    description : '"6" 316 / 316 Flexible Flue Liner',
                    cis: 'Yes',
                    quantity : '3',
                    nominal: '232',
                    cost : 100,
                    price : 45,
                    VAT_rate : '20%',
                    total : 300
                }
            ],
            labour: [
                {
                    id : 9023750324,
                    type : 'Labour',
                    description : 'Perform all the prep work for installing the new boiler',
                    cis: 'Yes',
                    quantity : '3.5',
                    nominal: '232',
                    cost : 60,
                    price : 45,
                    VAT_rate : '20%',
                    total : 210
                }
            ]
        },
        payments : []
    };

    multiple_jobs_invoice = {
        id : '54253141',
        type: 'Multiple',
        date : 'Mon 24th Dec 2013',
        breakdown: 'full_breakdown',
        description : 'Invoice for multiple jobs',
        more_details : 'End of month invoice for all works at tenant properties',
        jobs: [
            {
                id: '2300',
                description: 'Job description'
            },
            {
                id: '2500',
                description: 'Job description'
            }
        ],
        nominal_code : '100 - Parts',
        total : 7200,
        taxes : 1086,
        grand_total : 7200 + 1086,
        balance: 100,
        printed : 'Printed By Joseph Luck on 23rd November 2014',
        with_line_items: true,
        sent_to_accounting_package: false,
        audits : [
            {
                type : 'sending',
                message : 'On its way to SAGE',
                date : 'Tues 24th November 2014',
                time : '14:32pm'
            }
        ],
        items : [
            {
                id : 0325470239,
                type : 'Job',
                description : 'Supply and fit a new boiler at 6 Mitre Passage',
                job_number: '2300',
                quantity : '1',
                nominal: '232',
                cost : 2810,
                price : 45,
                VAT_rate : '20%',
                total : 2810
            },
            {
                id : 9203850231,
                type : 'Job',
                description : 'Perform a gas safety certificate at 12 The Mews',
                job_number: '2400',
                quantity : '1',
                nominal: '232',
                cost : 60,
                price : 45,
                VAT_rate : '20%',
                total : 60
            },
            {
                id : 6738635254,
                type : 'Job',
                description : 'Fix a leaking tap at 6 Old Street',
                job_number: '2500',
                quantity : '1',
                nominal: '232',
                cost : 80,
                price : 45,
                VAT_rate : '20%',
                total : 80
            },
            {
                id : 394903423,
                type : 'Job',
                description : 'Re-wire the 1st floor of 2 New Change',
                job_number: '2600',
                quantity : '1',
                nominal: '232',
                cost : 4250,
                price : 45,
                VAT_rate : '20%',
                total : 4250
            }
        ],
        payments : []
    };

    card_charge_invoice = {
        id : '589023',
        type: 'Partial',
        date : 'Mon 24th Dec 2013',
        breakdown: 'no_breakdown',
        job_number : '2300',
        payment_id : '879325',
        description : 'Card charge invoice (from a payment)',
        nominal_code : '100 - Parts',
        total : 3.50,
        taxes : 0,
        grand_total : 3.50,
        balance: 0,
        printed : 'Printed By Joseph Luck on 23rd November 2014',
        with_line_items: true,
        sent_to_accounting_package: true,
        audits : [
            {
                type : 'check',
                message : 'Successfully saved in SAGE',
                date : 'Tue 22th November 2014',
                time : '10:11am'
            },
            {
                type : 'info',
                message : 'This invoice is a card charge invoice from a payment',
                help_text: 'You can view the original payment using the link on the right',
                date : 'Tue 20th November 2014',
                time : '10:11am',
                category : 'card_charge'
            }
        ],
        payments : [
            {
                id: 879325,
                date: 'Mon 25th Dec 2013',
                method: 'Card',
                description: 'Payment for single invoice',
                reference: '879325',
                nominal : '322',
                amount : 3.50,
                sent_to_accounting_package : true,
                audits : [
                    {
                        type : 'check',
                        message : 'Sent to SAGE',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm',
                        edits : [
                            {
                                type : 'edit',
                                message : 'Edited',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : true,
                                completed : {
                                    completed : true,
                                    message : 'Marked off in SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm'
                                }
                            },
                            {
                                type : 'resend',
                                message : 'Resent to SAGE',
                                name : 'Joseph Luck',
                                date : 'Tues 24th November 2014',
                                time : '14:32pm',
                                required : false
                            }
                        ]
                    }
                ],
                invoices : [
                    {
                        id : 6578987,
                        description : 'Baxi Boiler',
                        nominal_code : '100 - Parts',
                        amount : 1117.80,
                        remainder : 0
                    },
                    {
                        id : 589023,
                        description : 'Card charge invoice (from a payment)',
                        nominal_code : '100 - Parts',
                        amount : 3.50,
                        remainder : 0
                    }
                ],
                card_details : {
                    name_on_card: 'Joseph Luck',
                    email_address: 'joseph.luck@commusoft.com',
                    telephone_number: '+44 74392 39482',
                    card_number: '**** **** **** 4828',
                    expiry_date: '** / **',
                    cvc_code: '***',
                    address_line_1 : '6',
                    address_line_2 : 'Old street',
                    address_line_3 : 'Shoreditch',
                    town : 'London',
                    county : 'Greater London',
                    postcode: 'N2, 3DL'
                }
            }
        ]
    };

    initial_payment_invoice = {
        id : '4782913235',
        type: 'Partial',
        date : 'Mon 24th Dec 2013',
        breakdown: 'no_breakdown',
        job_number : '2100',
        estimate_no : '879325',
        description : 'Initial payment invoice for estimate no. 2100',
        nominal_code : '100 - Parts',
        total : 333.33,
        taxes : 66.67,
        grand_total : 400,
        balance: 0,
        printed : 'Printed By Joseph Luck on 23rd November 2014',
        with_line_items: false,
        sent_to_accounting_package: true,
        audits : [
            {
                type : 'check',
                message : 'Successfully saved in SAGE',
                date : 'Tue 22th November 2014',
                time : '10:11am'
            },
            {
                type : 'info',
                message : 'This invoice is a initial payment invoice for estimate no. 2100',
                help_text: 'You can view the estimate using the link on the right',
                date : 'Tue 20th November 2014',
                time : '10:11am',
                category : 'initial_payment_invoice'
            }
        ],
        payments : []
    };

    invoice_for_milestone_1 = {
        id : '43927502345425',
        type: 'Partial',
        milestone: 'Removal and disposal',
        date : 'Mon 24th Dec 2013',
        breakdown: 'no_breakdown',
        description : 'Invoice for milestone Removal and disposal',
        more_details : 'Old boiler removed, area tidied, new boiler fitted and gas safety certificate provided',
        nominal_code : '100 - Parts',
        job_number : '4000',
        milestone_id: 4,
        total : 4521,
        taxes : 361.8,
        grand_total : 4521 + 361.8,
        balance: 2170,
        printed : 'Printed By Joseph Luck on 23rd November 2014',
        with_line_items: false,
        sent_to_accounting_package: false,
        payments : []
    };

    invoice_for_milestone_2 = {
        id : '673489084360',
        type: 'Partial',
        milestone: 'Preperation',
        date : 'Mon 24th Dec 2013',
        breakdown: 'no_breakdown',
        description : 'Invoice for milestone Preperation',
        more_details : 'Area prepped ready for installation',
        nominal_code : '100 - Parts',
        job_number : '4000',
        milestone_id: 4,
        total : 1854,
        taxes : 148.32,
        grand_total : 1854 + 148.32,
        balance: 890,
        printed : 'Printed By Joseph Luck on 23rd November 2014',
        with_line_items: false,
        sent_to_accounting_package: false,
        payments : []
    };

    draft_invoice = {
        id : '482570314135',
        type: 'Draft',
        milestone: 'Preperation',
        date : 'Mon 24th Dec 2013',
        breakdown: 'full_breakdown',
        description : 'This is a draft invoice for a boiler installation',
        more_details : 'Boiler installed and area cleaned',
        nominal_code : '100 - Parts',
        job_number : '4000',
        total : 1854,
        taxes : 148.32,
        grand_total : 1854 + 148.32,
        balance: 890,
        printed : 'Printed By Joseph Luck on 23rd November 2014',
        draft: true,
        with_line_items: true,
        audits : [
            {
                type : 'info',
                message : 'This is a draft invoice',
                help_text: 'You can edit this invoice freely it being sent to accounts. To save this draft as an invoice, click the link on the right',
                category : 'draft_invoice'
            }
        ],
        items : [
            {
                id : 12345902735,
                type : 'Part',
                description : 'Ariston E-Combi Evo 30HE Condensing Combi Gas Boiler ',
                quantity : '1',
                nominal: '232',
                cost : 300,
                price : 45,
                VAT_rate : '20%',
                total : 300
            },
            {
                id : 9072395732,
                type : 'Part',
                description : '"6" 316 / 316 Flexible Flue Liner',
                quantity : '3',
                nominal: '232',
                cost : 100,
                price : 45,
                VAT_rate : '20%',
                total : 300
            },
            {
                id : 89723951,
                type : 'Labour',
                description : 'Perform all the prep work for installing the new boiler',
                quantity : '3.5',
                nominal: '232',
                cost : 60,
                price : 45,
                VAT_rate : '20%',
                total : 210
            }
        ],
        payments : []
    };

    this.getInvoice = function getInvoice(type) {
        switch (type) {
            case 'invoice_with_payments_and_edit':
                var invoice = angular.copy(invoice_with_payments_and_edit);
                return invoice;
                break;
            case 'invoice_without_payments':
                var invoice = angular.copy(invoice_without_payments);
                return invoice;
                break;
            case 'invoice_on_way_to_accounting_package':
                var invoice = angular.copy(invoice_on_way_to_accounting_package);
                return invoice;
                break;
            case 'full_breakdown_by_category_invoice':
                var invoice = angular.copy(full_breakdown_by_category_invoice);
                return invoice;
                break;
            case 'multiple_jobs_invoice':
                var invoice = angular.copy(multiple_jobs_invoice);
                return invoice;
                break;
            case 'card_charge_invoice':
                var invoice = angular.copy(card_charge_invoice);
                return invoice;
                break;
            case 'initial_payment_invoice':
                var invoice = angular.copy(initial_payment_invoice);
                return invoice;
                break;
            case 'invoice_for_milestone_1':
                var invoice = angular.copy(invoice_for_milestone_1);
                return invoice;
                break;
            case 'invoice_for_milestone_2':
                var invoice = angular.copy(invoice_for_milestone_2);
                return invoice;
                break;
            case 'draft_invoice':
                var invoice = angular.copy(draft_invoice);
                return invoice;
                break;
        }
    }

    this.getInvoiceById = function getInvoiceById(id) {
        switch (id) {
            case '12345':
                var invoice = angular.copy(invoice_with_payments_and_edit);
                return invoice;
                break;
            case '435231':
                var invoice = angular.copy(invoice_without_payments);
                return invoice;
                break;
            case '6578987':
                var invoice = angular.copy(invoice_on_way_to_accounting_package);
                return invoice;
                break;
            case '948327':
                var invoice = angular.copy(full_breakdown_by_category_invoice);
                return invoice;
                break;
            case '54253141':
                var invoice = angular.copy(multiple_jobs_invoice);
                return invoice;
                break;
            case '589023':
                var invoice = angular.copy(card_charge_invoice);
                return invoice;
                break;
            case '4782913235':
                var invoice = angular.copy(initial_payment_invoice);
                return invoice;
                break;
            case '43927502345425':
                var invoice = angular.copy(invoice_for_milestone_1);
                return invoice;
                break;
            case '673489084360':
                var invoice = angular.copy(invoice_for_milestone_2);
                return invoice;
                break;
            case '482570314135':
                var invoice = angular.copy(draft_invoice);
                return invoice;
                break;
        }
    }

    this.getInvoices = function() {
        return [
            invoice_with_payments_and_edit,
            invoice_without_payments,
            invoice_on_way_to_accounting_package,
            full_breakdown_by_category_invoice,
            multiple_jobs_invoice,
            card_charge_invoice,
            draft_invoice,
            initial_payment_invoice,
            invoice_for_milestone_1,
            invoice_for_milestone_2
        ]
    }
});