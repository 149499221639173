commusoftCommon.directive('draggableEvent', function () {
    return {
        link: function (scope, elem, attrs, ctrl) {
            var previous_width;
            var options = scope.$eval(attrs.draggableEvent);
            options = options instanceof Object? options: {};

            elem.draggable(Object.assign({
                appendTo: 'body',
                helper: 'clone',
                disabled: true
            }, options));

            elem.on("drag", function(e, ui) {
                var placeholder_elem = document.querySelector('.fc-helper-skeleton .cs-event') || document.querySelector('.fc-highlight-skeleton .fc-highlight'),
                    job_elem = document.querySelector('.ticket-wrapper.ui-draggable.ui-draggable-dragging'),
                    user_is_in_daily_view = document.querySelector('.fc-highlight-skeleton') === null || document.querySelector('.fc-highlight-skeleton.all-day-highlight-skeleton') !== null;

                if (placeholder_elem) {
                    var event_width = placeholder_elem.getBoundingClientRect().width - 1,
                        job_elem_top = ui.position.top,
                        job_elem_left = ui.position.left,
                        placeholder_top = placeholder_elem.getBoundingClientRect().top,
                        placeholder_left = placeholder_elem.getBoundingClientRect().left,
                        margin_top = (placeholder_top - job_elem_top),
                        margin_left = (placeholder_left - job_elem_left),
                        top_offset = 0;

                    if (placeholder_elem.classList.contains('fc-highlight')) { // In all day
                        event_width += 1;
                    }

                    // Make jQuery draggable snap to a grid that matches the event width and height
                    var snap_height = placeholder_elem.offsetHeight,
                        snap_width = document.querySelector('.fc-day').offsetWidth;

                    if (user_is_in_daily_view) {
                        // Set the position of the outstanding job to the top left of the placeholder
                        job_elem.style.marginTop = (margin_top + top_offset) + 'px';
                        job_elem.style.marginLeft = margin_left + 'px';

                        elem.draggable("option", "grid", [snap_width, snap_height]);
                    }

                    job_elem.style.width = event_width + 'px'; // Set the width of the outstanding job to match the placeholder
                } else {
                    elem.draggable("option", "grid", false);
                    job_elem.style.marginTop = 'auto';
                    job_elem.style.marginLeft = 'auto';
                    job_elem.style.width = previous_width + 'px';
                }
            })

            elem.on("dragstart", function(e, ui) {
                $(e.currentTarget).addClass("being-dragged");

                previous_width = $(e.currentTarget)[0].getBoundingClientRect().width;
            });

            elem.on("dragstop", function(e, ui) {
                $(e.currentTarget).removeClass("being-dragged");
                /*var eventInfo = elem.data('event');
                $(e.currentTarget).remove();
                scope.$emit('removeOutstandingJob', {eventInfo: eventInfo});*/
            });
        }
    };
});