commusoftCommon.directive('csWeekpicker', ['dateFilter', '$parse', 'datepickerConfig', '$log','$timeout', function (dateFilter, $parse, datepickerConfig, $log, $timeout) {
    return {
        restrict: 'EA',
        replace: true,
        templateUrl: '/template/date_picker.html',
        scope: {
            dateDisabled: '&'
        },
        require: ['cs-weekpicker', '?^ngModel'],
        controller: 'DatepickerController',
        link: function(scope, element, attrs, ctrls) {
            var datepickerCtrl = ctrls[0], ngModel = ctrls[1];

            if (!ngModel) {
                return; // do nothing if no ng-model
            }

            // Configuration parameters
            var mode = 0, selected = new Date();
            scope.datepickerMode = datepickerCtrl.modes[mode].name;

            if (attrs.min) {
                scope.$parent.$watch($parse(attrs.min), function(value) {
                    datepickerCtrl.minDate = value ? new Date(value) : null;
                    refill();
                });
            }
            if (attrs.max) {
                scope.$parent.$watch($parse(attrs.max), function(value) {
                    datepickerCtrl.maxDate = value ? new Date(value) : null;
                    refill();
                });
            }

            // Split array into smaller arrays
            function split(arr, size) {
                var arrays = [];
                while (arr.length > 0) {
                    arrays.push(arr.splice(0, size));
                }
                return arrays;
            }

            function refill( updateSelected ) {
                var date = null, valid = true;

                if ( ngModel.$modelValue ) {
                    if(!Array.isArray(ngModel.$modelValue) ){
                        valid = false;
                        $log.error('Datepicker directive: "ng-model" value must be Array of two Date objects , a number of milliseconds since 01.01.1970 or a string representing an RFC2822 or ISO 8601 date.');
                    }
                    if ( ngModel.$modelValue.filter(function(d){ return isNaN(new Date(d)); }).length > 0 ) {
                        valid = false;
                        $log.error('Datepicker directive: Elements of "ng-model" value must be a Date object, a number of milliseconds since 01.01.1970 or a string representing an RFC2822 or ISO 8601 date.');
                    } else if ( updateSelected ) {
                        date = new Date(ngModel.$modelValue[0]);
                        selected = date;
                    }
                }
                ngModel.$setValidity('date', valid);

                var currentMode = datepickerCtrl.modes[mode], data = currentMode.getVisibleDates(selected, date);
                angular.forEach(data.objects, function(obj) {
                    obj.disabled = datepickerCtrl.isDisabled(obj.date, mode);
                });

                ngModel.$setValidity('date-disabled', (!date || !datepickerCtrl.isDisabled(date)));

                scope.rows = split(data.objects, currentMode.split);
                scope.labels = data.labels || [];
                scope.title = data.title;
            }

            function setMode(value) {
                mode = value;
                scope.datepickerMode = datepickerCtrl.modes[mode].name;
                refill();
            }

            ngModel.$render = function() {
                refill( true );
            };

            scope.select = function( date ) {
                if ( mode === 0 ) {
                    var wkStart = moment(date).startOf('week')._d;
                    var wkEnd = moment(date).endOf('week')._d;
                    var wk = [wkStart, wkEnd];
                    ngModel.$setViewValue( wk );
                    refill( true );
                } else {
                    selected = date;
                    setMode( mode - 1 );
                }

                scope.highlightRow();
            };
            scope.move = function(direction) {
                var step = datepickerCtrl.modes[mode].step;
                selected.setMonth( selected.getMonth() + direction * (step.months || 0) );
                selected.setFullYear( selected.getFullYear() + direction * (step.years || 0) );
                refill();
            };
            scope.toggleMode = function() {
                setMode( (mode + 1) % datepickerCtrl.modes.length );
            };

          // Highlighting the entire week range on date select
          scope.highlightRow = function () {
            $(element).find('tr').removeClass('selected-row');

            $timeout(function(){
              $(element).find('td>a.selected-day').each(function () {
                $(this).parents('tr').addClass('selected-row');
              })
            });
          };

          scope.highlightRow();
        }
    }
}]);