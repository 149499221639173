commusoftCommon.filter('customTimeFormat', ["$rootScope", 'CS_LANGUAGE', 'CS_COUNTRY', function ($rootScope, CS_LANGUAGE, CS_COUNTRY) {
    return function (timeString, customFormat=1) {
        if (timeString == '' || timeString == null) {
            return '';
        }
        var timeFormats = {
            0 : 'h:mm a',
            1 : 'HH:mm'
        };
        let language = CS_LANGUAGE;
        if(language == 'en'){
            language = 'en-GB';
        }
        const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:?\d{2}|Z)$/;
        let utcTimeString  = (isoRegex.test(timeString)) ? new Date(timeString) : new Date(timeString + ' UTC');
        utcTimeString = new Date(utcTimeString.toLocaleString(CS_LANGUAGE, { timeZone: CS_COUNTRY.timezone }));

        moment.locale(language);
        if ( typeof timeFormats[parseInt($rootScope.settings_value['time'])] !== 'undefined' ) {
            if(customFormat == 1) {
                return moment(utcTimeString).format(timeFormats[parseInt($rootScope.settings_value['time'])]);
            } else if (customFormat == 2) {
                return moment.utc(timeString).zone(moment().format('Z')).format(timeFormats[parseInt($rootScope.settings_value['time'])]);
            }
        } else {
            return moment(utcTimeString).format('h:mm a');
        }
    };
}]);
