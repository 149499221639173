/*==========================================================================================
 - Helper service to web/javascripts/commusoft/controllers/diary_controller.js
 - Companion service to web/javascripts/commusoft/controllers/filter_users_on_diary.js
 ==========================================================================================*/

csmodule.service('filterUsersOnDiary', ['$rootScope', '$compile', '$timeout', '$translate', function($rootScope, $compile, $timeout, $translate) {
  /*==========================================================================================
   Initial Variables
   ==========================================================================================*/
  this.init = function init(diary_view, all_users, users_on_calendar) {
    this.panel_data = {};
    this.panel_data.diary_view = diary_view;
    this.panel_data.users = all_users;
    this.showFilterDiaryUsersPanel();
  }

  /*==========================================================================================
   Show side panel
   ==========================================================================================*/
  this.showFilterDiaryUsersPanel = function showFilterDiaryUsersPanel() {
      var panel_data = JSON.stringify(this.panel_data);
      var dataToAppend = panel_data.replace(/'/g, "&#39;");
      var sidepanelTitle = $translate('filter_users_on_diary');
    var side_panel_tpl = $compile("<span id='filter_users_on_diary' sidepanel template='filter_users_on_diary' title='"+ sidepanelTitle +"' remove_on_close='true' href='' data='" + dataToAppend + "'></span>")($rootScope);

    angular.element('body').append(side_panel_tpl);

    $timeout(function() {
      angular.element('#filter_users_on_diary').scope().$$childTail.initialise();
    }, 100);
  }
}]).service('filterSkillsOnDiary', ['$rootScope', '$compile', '$timeout', '$translate', function($rootScope, $compile, $timeout, $translate) {
  /*==========================================================================================
   Initial Variables
   ==========================================================================================*/
  this.init = function init(diary_view, skills_data, users) {
    this.panel_data = {};
    this.panel_data.diary_view = diary_view;
    this.panel_data.skills_data = skills_data;
    this.panel_data.users = users;
    this.showFilterDiaryUsersPanel();
  }

  /*==========================================================================================
   Show side panel
   ==========================================================================================*/
  this.showFilterDiaryUsersPanel = function showFilterDiaryUsersPanel() {
      var panel_data = JSON.stringify(this.panel_data);
      var dataToAppend = panel_data.replace(/'/g, "&#39;");
    var sidepanelTitle = $translate('filter_skills_on_diary');
    var side_panel_tpl = $compile("<span id='filter_skills_on_diary' sidepanel template='filter_skills_on_diary' title='"+ sidepanelTitle +"' remove_on_close='true' href='' data='" + dataToAppend + "'></span>")($rootScope);

    angular.element('body').append(side_panel_tpl);

    $timeout(function() {
      angular.element('#filter_skills_on_diary').scope().$$childTail.initialise();
    }, 100);
  }
}]);