commusoftCommon.filter('localLength', function ($rootScope) {
    /*
        Converts length into local unit (km/mi)

        @param number value
        @param string sourceUnit --valid units={m|km|mi}
        @param boolean showUnit
        @return float
    */
    var unit = $rootScope.localStandards.length;

    return function (value, sourceUnit, showUnit) {
        if(!sourceUnit) sourceUnit = 'm';
        value = parseFloat(value);

        var meters = 0, converted = 0;
        switch (sourceUnit) {
            case "mi": meters = value * 1.60934 * 1000; break; // miles to meters
            case "km": meters = value * 1000; break; // kilometers to meters
            default: meters = value; break;
        }

        switch (unit) {
            case "mi": converted = meters / (1.60934 * 1000); break; // meters to miles
            case "km": converted = meters / 1000; break; // meters to kilometers
            default: converted = meters; break;
        }

        return showUnit ? converted.toFixed(2) + ' ' + unit : converted.toFixed(2);
    }
});