commusoftCommon.service('jobPrice', function() {

    new_pricing_categories = [
        'Travel',
        'Sundries'
    ]

    customisable_spreadsheet_columns_multiple_invoice = [
        {
            name: 'Unit Price',
            selected: false
        },
        {
            name: 'VAT Rate',
            selected: false
        },
        {
            name: 'Total',
            selected: false
        }
    ]

    customisable_spreadsheet_columns_default = [
        {
            name: 'Quantity',
            selected: false
        },
        {
            name: 'Unit Price',
            selected: false
        },
        {
            name: 'VAT Rate',
            selected: false
        },
        {
            name: 'Total',
            selected: false
        }
    ]

    /*====================================================
     Getters
     ====================================================*/

    this.getNewPricingCategories = function getNewPricingCategories() {
        return new_pricing_categories;
    }

    this.getCustomisableSpreadsheetColumns = function getCustomisableSpreadsheetColumns(type) {
        if (type === 'multiple_invoice') {
            return customisable_spreadsheet_columns_multiple_invoice;
        } else {
            return customisable_spreadsheet_columns_default
        }
    }
});