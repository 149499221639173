commusoftCommon.directive('upcomingPayments',['prefix', '$http', 'canLoad', '$q','$rootScope', function(prefix, $http, canLoad, $q,$rootScope) {
    return {
        restrict: 'E',
        replace:true,
        scope: {
            supplierId : '@'
        },
        templateUrl: '/template/suppliers/upcoming_payments.html',
        controller: function($scope, prefix, $http, $q, canLoad,$rootScope){
            $scope.view_by = "month";
            $scope.startDate = moment().format('YYYY-MM-DD');
            $scope.widget = {
                type: 'column',
                graph_options: {
                    x_axis_categories: [],
                    y_axis: [
                        {
                            labels: {
                                format: $rootScope.CustomCurrency +'{value}'
                            },
                            title: {
                                text: 'Amount'
                            },
                            opposite: false
                        }
                    ],
                    series: [
                        {
                            name: 'Amount',
                            data: []
                        }
                    ]
                }
            }

            $scope.changeViewBy = function(view) {
                if($scope.view_by != view) {
                    $scope.view_by = view;
                    if($scope.view_by == 'day') {
                        $scope.startDate = moment($scope.startDate).startOf('week').format('YYYY-MM-DD');
                    }
                    $scope.reflowCharts();
                }
            }

            var canceler = null;

            $scope.reflowCharts = function() {
                canLoad.setLoadValue(false);
                if (canceler) {
                    canceler.resolve();
                }
                canceler = $q.defer();
                $http.get(prefix + '/suppliers/' + $scope.supplierId + '/upcoming_payments/' + $scope.view_by + '?startDate=' + $scope.startDate, {timeout: canceler.promise}).success(function (data) {
                    $scope.widget = {
                        type: 'column',
                        graph_options: {
                            x_axis_categories: data.dateArray,
                            y_axis: [
                                {
                                    labels: {
                                        format: $rootScope.CustomCurrency+'{value}'
                                    },
                                    title: {
                                        text: 'Amount'
                                    },
                                    opposite: false
                                }
                            ],
                            series: [
                                {
                                    name: 'Amount',
                                    data: data.amountArray
                                }
                            ]
                        }
                    };
                });
            }

            $scope.reflowCharts();

            $scope.handleNextPrevClick = function(option) {
                var delta = 1;
                var startDate, interval, intervalString;
                if(option == 'prev') {
                    delta = -1;
                }
                if($scope.view_by == 'day') {
                    startDate = moment($scope.startDate).startOf('week').add(1, 'day');
                    interval = 1;
                    intervalString = 'week';
                } else {
                    startDate = moment($scope.startDate).startOf('month');
                    interval = 6;
                    intervalString = 'month';
                }
                $scope.startDate = moment($scope.startDate).add(delta * interval, intervalString).format('YYYY-MM-DD');
                $scope.reflowCharts();
            }
        }
    }
}]);