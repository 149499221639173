csmodule.service('dragNDropService', function(adminPrefix, $translate) {
    this.errors = {
        'misc': $translate('An.Error.Has.Occurred.Please.Contact.Support'),
        'pattern': $translate('Unable.To.Upload.Because.This.File.Type.Is.Not.Supported'), 
        'maxSize': $translate('File.Is.Too.Large.Maximum.Size.Is'),
        'minQty': $translate('Please.Select.At.Least.1.File.To.Upload'),
        'maxFiles': $translate('You.can.only.upload.10.files.at.a.time')
    };

    this.customErrorsList = {};

    this.customErrors  = function(group, obj) {
        if (!group) {
            return 'Error: ngf-group needs to be specified on the directive and passed as the first parameter';
        }

        angular.forEach(obj, function(err, errkey) {
            if (!this.customErrors[group]) {
                this.customErrors[group] = {};
            }

            this.customErrors[group][errkey] = err;

        }, this);
    };

    this.error = function(group, err, file) {
        if (!group) {
            return 'Error: ngf-group needs to be specified on the directive and passed as the first parameter';
        }

        if (!this.errors[group]) {
            this.customErrorsList[group] = angular.copy(this.errors);
        }

        if (this.customErrorsList[group][err]) {
            if (this.customErrorsList[group][err].indexOf('ERROR_PARAM') > -1) {
                if (file) {
                    var returnVal = this.customErrorsList[group][err];
                    return returnVal.replace('ERROR_PARAM', file['$errorParam']);
                }
            }
            
            return this.customErrorsList[group][err];
        }
        return this.customErrorsList[group]['misc'];
    }
});
