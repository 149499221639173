commusoftCommon.service('cardExpiryYears', function(){
    var todaysDate = new Date();

    var cardExpiryYears = [
        {
            id: todaysDate.getFullYear(),
            text: todaysDate.getFullYear()
        },
        {
            id: todaysDate.getFullYear() + 1,
            text: todaysDate.getFullYear() + 1
        },
        {
            id: todaysDate.getFullYear() + 2,
            text: todaysDate.getFullYear() + 2
        },
        {
            id: todaysDate.getFullYear() + 3,
            text: todaysDate.getFullYear() + 3
        },
        {
            id: todaysDate.getFullYear() + 4,
            text: todaysDate.getFullYear() + 4
        },
        {
            id: todaysDate.getFullYear() + 5,
            text: todaysDate.getFullYear() + 5
        },
        {
            id: todaysDate.getFullYear() + 6,
            text: todaysDate.getFullYear() + 6
        },
        {
            id: todaysDate.getFullYear() + 7,
            text: todaysDate.getFullYear() + 7
        },
        {
            id: todaysDate.getFullYear() + 8,
            text: todaysDate.getFullYear() + 8
        },
        {
            id: todaysDate.getFullYear() + 9,
            text: todaysDate.getFullYear() + 9
        },
        {
            id: todaysDate.getFullYear() + 10,
            text: todaysDate.getFullYear() + 10
        }
    ];
    this.getCardExpiryYears = function () {
        return cardExpiryYears;
    }
})