/*
	Calculates the optimal position for a pop up (and its arrow)
	- element with the pop up needs data-pop-up-identifier="..."
	- and the pop up for it needs data-pop-up="..."
	- pop up can also have data-gutter-space-for-arrow="15";
		which sets the distance between the element and its pop up
*/

csmodule.service('csPopUpPosition', ['$q', function($q) {
	var self;

	this.init = function init($event, isSvg) {
		self = this;
		this.elm = $event.currentTarget;
		if(isSvg && !this.elm.dataset.popUpIdentifier) {
			this.elm = $event.currentTarget.closest('svg');
		}
		this.pop_up = document.querySelector('[data-pop-up=' + this.elm.dataset.popUpIdentifier + ']');
		this.arrow = this.pop_up.querySelector('#cs-pop-up-arrow');
		this.gutter_space_for_arrow = parseInt(this.pop_up.dataset.gutterSpaceForArrow) || 0;
		this.handlePopUpPosition();
	}

	this.handlePopUpPosition = function handlePopUpPosition() {
		this.getPopUpSize().then(function(pop_up_size) {
			var top_pos,
					left_pos,
					elm_rect = self.elm.getBoundingClientRect(),
					elm_vertical_mid_point = elm_rect.top + (elm_rect.height / 2),
					optimal_top_pos = elm_vertical_mid_point - (pop_up_size.height / 2), // middle of pop up at same height as middle of diary event
					optimal_left_pos = elm_rect.left + elm_rect.width + self.gutter_space_for_arrow, // to the right-hand side of the diary event
					can_use_optimal_top_pos = ((optimal_top_pos + pop_up_size.height) < window.innerHeight) &&
																		(pop_up_size.height / 2) < elm_rect.top,
					can_use_optimal_left_pos = (optimal_left_pos + pop_up_size.width) < window.innerWidth,
					cant_go_left_or_right = (elm_rect.left < pop_up_size.width) &&
																	(window.innerWidth - elm_rect.right) < pop_up_size.width,
					arrow_side;

			if(cant_go_left_or_right) {
				// determine if enough room above or below elm
				var can_place_pop_up_above_elm = (window.innerHeight - elm_rect.bottom) > pop_up_size.height;
				left_pos = ((elm_rect.width / 2) + elm_rect.left) - pop_up_size.width / 2;

				if(can_place_pop_up_above_elm) {
					top_pos = elm_rect.top + elm_rect.height + self.gutter_space_for_arrow;
					arrow_side = 'top';
				}else {
					top_pos = elm_rect.top - pop_up_size.height - self.gutter_space_for_arrow;
					arrow_side = 'bottom';
				}
			}else {
				if(can_use_optimal_top_pos) {
					top_pos = optimal_top_pos;
				}else {
					// either not enough room above OR below pop up
					var not_enough_room_above_elm = optimal_top_pos < (pop_up_size.height / 2);

					if(not_enough_room_above_elm) {
						top_pos = elm_rect.top;
					}else {
						// not enough room below elm
						top_pos = elm_rect.top - (pop_up_size.height - elm_rect.height);
					}
				}

				if(can_use_optimal_left_pos) {
					left_pos = optimal_left_pos;
					arrow_side = 'left';
				}else {
					left_pos = elm_rect.left - pop_up_size.width - self.gutter_space_for_arrow;
					arrow_side = 'right';
				}
			}

			self.handleArrowPos(arrow_side, elm_vertical_mid_point, left_pos, elm_rect, pop_up_size);
			self.setPopUpPos(top_pos, left_pos);
		});
	}

	this.getPopUpSize = function getPopUpSize() {
		var deferred = $q.defer();

		function calcPopUpSize() {
			var pop_up_rect = self.pop_up.getBoundingClientRect(),
					pop_up_size = {};

			pop_up_size.height = pop_up_rect.height;
			pop_up_size.width = pop_up_rect.width;

			deferred.resolve(pop_up_size);
		}

		window.requestAnimationFrame(calcPopUpSize);
		return deferred.promise;
	}

	this.handleArrowPos = function handleArrowPos(side, elm_vertical_mid_point, pop_up_left_pos, elm_rect, pop_up_size) {
		var arrow_rect = this.arrow.getBoundingClientRect(),
				top_pos,
				left_pos;

		if(side == 'left' || side == 'right') {
			top_pos = elm_vertical_mid_point - (arrow_rect.height / 2);
		}
		if(side == 'top' || side == 'bottom') {
			left_pos = (elm_rect.left + elm_rect.width / 2) - arrow_rect.width / 2;
		}

		if(side == 'left') {
			left_pos = pop_up_left_pos - arrow_rect.width;
		}else if(side == 'right') {
			left_pos = elm_rect.left - this.gutter_space_for_arrow;
		}else if(side == 'top') {
			top_pos = elm_rect.top + elm_rect.height + this.gutter_space_for_arrow - arrow_rect.height / 2;
		}else if(side == 'bottom') {
			top_pos = elm_rect.top - this.gutter_space_for_arrow - arrow_rect.height / 2;
		}

		this.setArrowPos(top_pos, left_pos);
		this.setArrowDirection(side);
	}

	this.setPopUpPos = function setPopUpPos(top_pos, left_pos) {
		this.pop_up.style.top = Math.ceil(top_pos) + 'px';
		this.pop_up.style.left = Math.ceil(left_pos) + 'px';
	}

	this.setArrowPos = function setArrowPos(top_pos, left_pos) {
		this.arrow.style.top = Math.ceil(top_pos) + 'px';
		this.arrow.style.left = Math.ceil(left_pos) + 'px';
	}

	this.setArrowDirection = function setArrowDirection(direction) {
		this.arrow.classList.remove('top', 'bottom', 'left', 'right');
		this.arrow.classList.add(direction);
	}

}]);