commusoftCommon.directive('colorpicker', ['colorSelector', '$rootScope', function(colorSelector, $rootScope) {
    var handleColors = function handleColors(scope, element, attrs) {

        scope.colourdescription = attrs.colourdescription;
        scope.all_colors = colorSelector.getColors(attrs.category);

        scope.color_help_text = attrs.description;
        scope.supports_spectrum = false;
        scope.current_color_label = 'Current';
        scope.default_color = 'black';
        scope.showing_spectrum = false;

        scope.getDefaultColor = function getDefaultColor() {
            var default_color = attrs.defaultColor;
            for(var i = 0, l = scope.all_colors.length; i < l; i++) {
                if (scope.all_colors[i].color === default_color ||
                    scope.all_colors[i].rgba === default_color) {
                    return scope.all_colors[i];
                }
            }
            // If its a spectrum-color
            if (default_color.charAt(0) == '#') {
                var selected_color = {
                    'color' : 'spectrum-color',
                    'rgba' : default_color
                }
                scope.all_colors.unshift(selected_color);
                return selected_color;
            }
        }

        if (attrs.defaultColor !== undefined) {
            scope.selected_color = scope.getDefaultColor();
        }

        if (attrs.currentColorHex) {
            console.log(attrs.currentColorHex);
            if (attrs.currentColorHex !== 'undefined') {
                var all_color_hex = scope.all_colors.map(function(color) {
                        return color.hex;
                    }),
                    existing_color_index = all_color_hex.indexOf(attrs.currentColorHex);
                scope.selected_color = existing_color_index == -1 ? scope.all_colors[0] : scope.all_colors[existing_color_index];
            }
        } else if (attrs.defaultColor === undefined) {
            scope.selected_color = scope.all_colors[0];
        }

        if (attrs.supportsSpectrum === 'true') {
            scope.supports_spectrum = true;
        }

        if (attrs.currentColorLabel) {
            scope.current_color_label = attrs.currentColorLabel;
        }

        scope.storePreviousColor = function storePreviousColor() {
            scope.previous_color = scope.selected_color;
        }

        scope.selectColor = function selectColor(color) {
            scope.selected_color = color;
            scope.broadcastColor(color);
        }

        scope.changeSpectrumColor = function changeSpectrumColor(color) {
            if (scope.showing_spectrum && color !== undefined) {
                scope.spectrum_color = color;
                var chosen_color_obj = {
                    'color' : 'spectrum-color',
                    'rgba' : color
                }
                scope.broadcastColor(chosen_color_obj);
                scope.selected_color = chosen_color_obj
            }
        }

        scope.saveSpectrumColor = function saveSpectrumColor() {
            scope.all_colors.unshift({
                color: 'spectrum-color',
                rgba: scope.spectrum_color
            });
            scope.selectColor(scope.all_colors[0]);
            scope.showing_spectrum = false;
        }

        $rootScope.$on('brandingcolors:savespectrumcolor', function() {
            if (scope.showing_spectrum === true) {
                var all_rgba = scope.all_colors.map(function(color) {
                    return color.rgba;
                })

                if (all_rgba.indexOf(scope.spectrum_color) === -1) {
                    scope.saveSpectrumColor();
                }
            }
        })

        scope.cancelSpectrum = function cancelSpectrum() {
            scope.selected_color = scope.previous_color;
            scope.broadcastColor(scope.selected_color);
            scope.showing_spectrum = false;
        }

        scope.broadcastColor = function broadcastColor(color) {
            if (attrs.category !== undefined) {
                if (color === undefined) {
                    color = scope.selected_color;
                }
                $rootScope.$broadcast('colorpicker:' + attrs.category, color);

                scope.current_color_style = {
                    'background' : color.rgba
                }
                scope.selected_color = color;
            }
        }

        scope.toggleSpectrum = function() {
            scope.showing_spectrum = !scope.showing_spectrum;
        }

        // initialise
        scope.broadcastColor(scope.selected_color);
    }
    return {
        restrict: 'A',
        scope: {},
        templateUrl: 'template/color_picker.html',
        link: handleColors
    }
}]);