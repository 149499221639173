commusoftCommon.directive('csmassupdatemodal', ['prefix', '$http', '$modal', function(prefix, $http, $modal) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            title: '@',
            deleteUrl: '@',
            templateUrl: '@',
            textToConfirm: '@',
            dropdownlistUrl: '@',
            buttonClass: '@',
            buttonValue: '@',
            validationMessage: '@',
            shouldBeOpen: '=',
            deleteFunc: '&',
            limit: '=',
            totalEntries: '=',
            formtypename: '@',
            screentype: '@',
            data: '=',
            modalDescription: '@',
            userAuthDescription1: '@',
            userAuthDescription2: '@'
        },
        controller: function($scope, $element, $attrs, $transclude) {
            $scope.$watch('shouldBeOpen', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;

                    modalInstance = $modal.open({
                        templateUrl: 'template/'+$scope.templateUrl+'.html',
                        scope: $scope,
                        controller: CSmassUpdateModalCtrl
                    })
                } else {

                }
            });
        }
    }
}]);