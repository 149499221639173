commusoftCommon.directive('cspdfdownloadoptions', ['prefix', '$http', '$compile', function (prefix, $http, $compile) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            pageName: '@',
            pdfRoute: '=',
            excelRoute: '=',
            type: '='
        },
        controller: function ($scope, prefix) {
            $scope.prefix = prefix;
            $scope.$watchCollection('[pdfRoute, excelRoute]', function (newVal, oldVal) {
                var pageQuery = '&page=' + $scope.pageName;
                var type = '&type=' + $scope.type;
                angular.element("#downloadscreen").attr('href',$scope.excelRoute + pageQuery + type);
                angular.element("#printscreen").attr('href',$scope.pdfRoute + pageQuery + type );
            });
        },
        link: function (scope, elm, prefix) {
            var html = '<div class="download_options">'+
                '<a href="" class="icon ss-download" download="' + scope.pageName + '.xls" target="_self" id="downloadscreen" tooltip-placement="top" tooltip="Download to Excel">&nbsp;</a>'+
                '<a href="" class="icon ss-print" target="_blank" id="printscreen" tooltip-placement="top" tooltip="Print">&nbsp;</a>'+
                '</div>';
            elm.html ($compile(html)(scope));
        }
    };
}]);