commusoftCommon.directive('sidepanel', ['prefix', '$http', '$rootScope', 'template', 'sidePanel', 'smsTemplates', '$document', '$timeout', '$sce', function (prefix, $http, $rootScope, template, sidePanel, smsTemplates, $document, $timeout, $sce) {

    var SidePanel = function SidePanel(scope, element, attrs) {
        /*====================================================
         Load the template
         ====================================================*/
        scope.templateLoaded = false;
        scope.taxLabel = $rootScope.taxLabel;
        scope.configure_columns = {
            'quantity' : 'Quantity',
            'unitPrice': 'Unit price',
            'taxItemId': $rootScope.taxLabel,
            'total' : 'Total'
        };

        scope.templates = smsTemplates.getSmsTemplates();
        if (scope.panel_data) {
            try {
                scope.panel_content = angular.fromJson(scope.panel_data);
            }catch(e) {
                scope.panel_content = scope.panel_data;
            }
        }
        var tpl_path = "template/side_panels/" + attrs.template + ".html";
        if(attrs.template == 'supplier_default_list_sidepanel'){
          $timeout(function () {
            template.load(tpl_path, element, scope).then(function () {
              scope.templateLoaded = true;
            }, function () {
              scope.templateLoaded = false;
            });
          }, 1000);
        }
        else{
          template.load(tpl_path, element, scope).then(function () {
            scope.templateLoaded = true;
          }, function () {
            scope.templateLoaded = false;
          });
        }
        // set up the listener id for remote opening
        if (attrs.listenerId) {
            var sidepanel_listener_id = attrs.listenerId;
        }
        if (attrs.helpText) {
            scope.help_text = attrs.helpText;
        }
        if (attrs.uniqueId) {
            scope.uniqueId = attrs.uniqueId;
        }
        if (attrs.openOnClick !== undefined) {
            scope.openOnClick = attrs.openOnClick;
        }

        attrs.$observe('data', function () {
          if(attrs.template === 'export_report_template') {
            scope.panel_content = scope.getPanelContent();
          }
          else {
            $timeout(function () {
              scope.$apply(function () {
                scope.panel_content = scope.getPanelContent();
              });
            });
          }
        });

        /*====================================================
         Initialise
         ====================================================*/
        scope.initialise = function initialise() {
            scope.directive_scope = scope;
            scope.panel_title = attrs.title;
            scope.get_list_url = attrs.getListUrl;
            scope.repeat_index = scope.index;
            scope.extended_keyboard_controls = false;
            scope.remove_on_close = false;

            if(scope.panel_data) {
              $timeout(function(){
                scope.$apply(function () {
                  scope.panel_content = scope.getPanelContent();
                });
              });
            }

            //# Common fix for load data while clicking link.
            if(attrs.getDataRequest) {
                scope.loading=false;
                $http.get(prefix + attrs.getDataRequest).success(function (data) {
                    scope.panel_data = angular.toJson(data);
                    scope.panel_content = data;
                    scope.$broadcast('loadSidePanelData');
                });
            }

            if(attrs.removeOnClose) {
                scope.remove_on_close = true;
            }

            var additional_data_exists = scope.additional_data !== undefined;

            if(attrs.template === 'print_and_post' || attrs.template === 'send_statement') {
                scope.supports_print = false;
                scope.supports_email = false;
                scope.supports_print_and_post = false;
                scope.supports_print_and_post = attrs.supportsPrintAndPost;
                scope.supports_copy = false;
                scope.supports_print = attrs.supportsPrint;
                scope.supports_email = attrs.supportsEmail;
                scope.supports_copy = attrs.supportsCopy;
                scope.print_and_post_message = attrs.printAndPostMessage;
                scope.print_and_post_error_message = null;
                $http.get(prefix + '/check_print_post_credits').then(function(res) {
                    scope.print_and_post_error_message = (res.data.error)?res.data.error:null;
                });
            }

            scope.handleExtendableOptions();

            if (scope.templateLoaded) {
              scope.panel = element.next()[0];
              sidePanel.open(scope.panel, scope.remove_on_close, attrs.template);
              scope.registerKeyBoardListeners();
            }
            else {
              var templateLoaderObservable = scope.$watch('templateLoaded', function (newVal) {
                if (!newVal) return;

                templateLoaderObservable();
                scope.templateLoaded = true;

                scope.panel = element.next()[0];
                sidePanel.open(scope.panel, scope.remove_on_close, attrs.template);
                scope.registerKeyBoardListeners();

              });
            }
            if(attrs.title == 'feedback') {
                scope.$broadcast('viewFeedback');
            }

        }

        scope.insertSelectedContent = function insertSelectedContent(message) {
            scope.$emit('templateAccordion', message);
            sidePanel.close();
        }

        scope.renderHtml = function (html_code) {
            return $sce.trustAsHtml(html_code);
        }

        scope.getPanelContent = function getPanelContent() {
            var panel_content;

            try {
                panel_content = angular.fromJson(scope.panel_data);
            }catch(e) {
                panel_content = scope.panel_data;
            }

            return panel_content;
        }

        /*====================================================
         Override any options that are conditional, extend scope vars
         ====================================================*/
        scope.handleExtendableOptions = function handleExtendableOptions() {
            if (attrs.keyboardControls != undefined) {
                scope.extended_keyboard_controls = true;
                scope.extended_keyboard_type = attrs.keyboardControls;
            }
            else if (attrs.focusInput != undefined) {
                sidePanel.focusInput(scope.panel, attrs.focusInput);
            }

            /*====================================================
             Pricing step of estimates
             - The panel where line items can be merged should
             only show items that can be grouped together
             ====================================================*/
            if (attrs.template === 'merge_line_items') {
                scope.tmp_vat_count = _.countBy(scope.panel_content, "VAT");
                var indexes = [];

                _.filter(scope.panel_content, function (line_item, index, collection) {
                    var has_unique_vat = scope.tmp_vat_count[line_item['VAT']] === 1;
                    if (has_unique_vat) {
                        indexes.push(index);
                    }
                });

                if (indexes.length > 0) {
                    indexes.sort(function (a, b) {
                        return a < b ? 1 : -1;
                    });

                    for (var i = 0, l = indexes.length; i < l; i++) {
                        scope.panel_content.splice(indexes[i], 1);
                    }
                }
            }
        }

        /*====================================================
         Keyboard Shortcuts
         ====================================================*/
        scope.registerKeyBoardListeners = function registerKeyBoardListeners() {
            window.onkeydown = function (evt) {

                var handleEscKey = (function () {
                    // esc keydown
                    if (evt.keyCode == 27) {
                        sidePanel.close(true);
                    }
                })();

                if (scope.extended_keyboard_controls) {
                    var type = scope.extended_keyboard_type;

                    switch (type) {
                        case 'vertical_navigate':
                            if (evt.keyCode == 38 || evt.keyCode == 40) {
                                scope.handleVerticalCtrls(evt);
                            }
                            break;
                        case 'something_else':
                            // debugger;
                            break;
                    }
                }
            }
        }

        /*====================================================
         Extended keyboard shortcuts
         ====================================================*/
        scope.handleVerticalCtrls = function handleVerticalCtrls(evt) {
            scope.collection = angular.fromJson(scope.collection);
            scope.index = parseInt(scope.index);
            var in_bounds;

            // Up
            if (evt.keyCode == 38) {
                in_bounds = scope.isViewInBounds(-1);
                if (in_bounds) {
                    scope.index--;
                }
            } else if (evt.keyCode == 40) {
                in_bounds = scope.isViewInBounds(1);
                if (in_bounds) {
                    scope.index++;
                }
            }

            if (in_bounds) {
                scope.updatePanelContent(scope.index);
            }
        }

        /*====================================================
         Checks that the index in the collection exists
         ====================================================*/
        scope.isViewInBounds = function isViewInBounds(increment) {
            var in_bounds;
            scope.collection[scope.index + increment] != undefined ? in_bounds = true : in_bounds = false;
            return in_bounds;
        }

        scope.updatePanelContent = function updatePanelContent(i) {
            scope.panel_content = scope.collection[i];
        }

        $rootScope.$on('sidepanel:close', function() {
            sidePanel.close(false);
        });

        /*====================================================
         When link is clicked instanciate directive
         ====================================================*/
        var registerEventListeners = (function (e) {
            if (scope.openOnClick !== "false") {
                element[0].addEventListener('click', scope.initialise);
            }
            scope.$on('open_sidepanel_' + scope.uniqueId, function(evt, settings) {
                if (settings.listObject) {
                    scope.data_object = settings.listObject;
                }
                scope.initialise();
            });
        })();

        scope.resetPricingCategoryName = function resetPricingCategoryName() {
            if (scope.pricing_category_name) {
                scope.pricing_category_name = '';
                sidePanel.close();
            }
        };

        scope.closeSidePanel = function closeSidePanel() {
            sidePanel.close();
        };

        if(attrs.template === 'configure_job_pricing_columns' || attrs.template === 'configure_estimate_pricing_columns') {
            scope.config_column = attrs.configColumn;
        }
        $rootScope.$on('update_config_columns', function(evt, data) {
            scope.config_column = data;
            scope.show_vat_in_grand_total = !angular.fromJson(scope.config_column);
            scope.$parent.show_cis_in_grand_totals = scope.config_column;
        });
        scope.closePanel = function closePanel() {
            scope.show_vat_in_grand_total = !angular.fromJson(scope.config_column);
            scope.$parent.show_cis_in_grand_totals = scope.config_column;
            scope.line_item_name = '';
            scope.panel_content = '';
            if(attrs.template !='deferred_payment_card_details'){
                scope.maindata = '';
            }
            $rootScope.$broadcast('closeAllSidepanels');
        }
        scope.attachTermsAndConditions = function attachTermsAndConditions() {
            if (scope.panel_content) {
                scope.$emit('estimateTerms', scope.panel_content)
            } else {
                // handle error UI - pricing category name has not been filled out
            }
        }

        // Used in Visits tab for project job screen.
        scope.handleFilteringEvents = function handleFilteringEvents() {
            $rootScope.$broadcast('jobs:handleFilteringEvents', scope.panel_content);
        }

        // Used to remove the scope value currently working in merge sidepanel for estimate price screen.
        $rootScope.$on('sidepanel:removecontent', function() {
            scope.line_item_name = '';
            scope.panel_content = '';
            scope.data_object = [];
        });

        scope.processData = function processData(){
            scope.$emit('sidepanel:process_data', scope.panel_content);
        }
    }

    return {
        restrict: 'A',
        link: SidePanel,
        scope: {
            panel_data: '@data',
            collection: '@collection',
            index: '@index',
            focus_input_identifier: '@focus_input_identifier',
            print_and_post_message : '@printAndPostMessage',
            pdf_url: '@pdfUrl',
            printpost_params: '@printpostParams',
            additional_data : '@additionalData',
            watermark: '@watermark',
            maindata: '=maindata'
        }
    }
}]);
