/**
 * Created by robert on 28/2/18.
 */

function HandleDurationModal($scope, $modalInstance) {
    $scope = $scope.$parent;

    $scope.saveDuration = function() {
        if(this.convertToNormalEvent || ($scope.toConvertOrResize && !$scope.timeDiffArray.length)) {
            $scope.eventToEdit.isSpecialEvent = false;
        }
        $scope.eventToEdit.actualTime = this.actualTime;
        $scope.updateEvent($scope.eventToEdit);
        $scope.eventToEdit = {};
        $scope.close();
    }

    $scope.close = function () {
        $scope.durationModalOpened = false;
        if($scope.toConvertOrResize) {
            $scope.toConvertOrResize = false;
            $scope.updateEvents();
        }
        $modalInstance.close();
    }
}

function serviceWindowCtrl($scope, prefix, $http, serviceWindowsObj, canLoad, $q, $state, $timeout, $rootScope, $compile, uiCalendarConfig, csPopUpPosition, warningModal, toastBox) {
    $scope.selectable = serviceWindowsObj.selectable;
    $scope.editable = serviceWindowsObj.editable;
    $scope.serviceWindowsObj = serviceWindowsObj.service_windows
    $scope.service_window_status = serviceWindowsObj.service_window_status;
    $scope.settings_tab_status = serviceWindowsObj.settings_tab_status;
    $scope.overlapping = false;
    $scope.defaultDate = moment();
    $scope.currentView = 'agendaDay';
    $scope.resourceId = '';
    $scope.allEvents = [];
    $scope.currentEvents = [];
    $scope.eventToUndo = {};
    $scope.viewStart = moment();
    $scope.viewEnd = moment();
    $scope.showSticky = false;
    $scope.showRevert = false;
    $scope.stickyMessage = '';
    $scope.events = [];
    $scope.eventSources = [$scope.events];
    $scope.timeDiffArray = [];
    $scope.selectedUserGroups = [];
    $scope.isFromFilter = false;
    //This is to handle the event loading
    $scope.prevNextClicked = false;
    $scope.nextEvents = [];
    $scope.prevEvents = [];
    $scope.show_event_pop_up = false;
    $scope.eventDragStarted = false;
    $scope.selectedUserIdAsArray = [];

    $scope.outstandingJobDropped = false;
    $scope.milestones = [];
    $scope.doubleClicked = false;
    $scope.selectedDiaryEventId = false;
    $scope.slots_booked = false;
    $scope.amEvent = false;
    $scope.pmEvent = false;
    $scope.allDayEvent = false;

    if($state.params.diaryId > 0) {
        $scope.selectedDiaryEventId = $state.params.diaryId;
    }
    // handle the height of the calendar
    function handleFullCalendarHeight() {
        return (window.innerHeight - 280);
    }

    function resizeCalendar() {
        $('#service-windows-cs-calendar').fullCalendar('option', 'contentHeight', handleFullCalendarHeight());    }
    $(window).on('resize', resizeCalendar);
    $scope.serviceWindowsUiConfig = {
        calendar: {
            schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
            header: false,
            buttonText: {
                agendaDay: "Service window"
            },
            contentHeight: handleFullCalendarHeight(),
            scrollTime: '00:00:00',
            allDaySlot: false,
            contentHeight: handleFullCalendarHeight(),
            slotDuration: "00:30:00",
            displayEventEnd: true,
            forceEventDuration: true,
            defaultTimedEventDuration: "01:00:00",
            eventLimit:true,
            defaultDate: $scope.defaultDate,
            displayEventTime:false,
            handleWindowResize: true,
            selectable: $scope.selectable,
            selectHelper: true,
            unselectCancel: ['div.in-view', '.confirmation-showing'],
            editable: $scope.editable,
            defaultView: 'agendaDay',
            resources: [{'name':'Service window','title': 'Service window','id':2,'backgroundColor':"cs-event-colour-14",'borderColor':false,'checked':true,'shown_on_diary':true}],
            fixedWeekCount: false,
            unselectAuto: false,
            select: function(start, end, event, view, resource, allDay) {
                $scope.onSelect(start, end, allDay, resource.id);
            },
            viewRender: function(view, element) {
                $scope.currentView = view.name;
                $scope.viewStart = moment(view.start.format('YYYY-MM-DD HH:mm:ss'));
                $scope.viewEnd = moment(view.end.format('YYYY-MM-DD HH:mm:ss'));
                $scope.startDate = view.intervalStart;
                if($scope.prevNextClicked && $scope.prevNextClicked != 'today' && $scope.nextEvents !== false && $scope.prevEvents !== false) {
                    $scope.loadNextOrPrevEvents(view, $scope.prevNextClicked);
                    $scope.prevNextClicked = false;
                } else {
                    $scope.loadAllEvents();
                    //Todo : This will be removed later, added for testing
                    if($scope.prevNextClicked) {
                        console.log('clicked multiple times / clicked before the event is loading');
                    }
                }
                if ($scope.currentView !== view.name ) {
                    // maintains the view
                    $scope.serviceWindowsUiConfig.calendar.defaultView = view.name;
                    $scope.serviceWindowsUiConfig.calendar.contentHeight = handleFullCalendarHeight();
                }
            },
            eventClick: function(calEvent, jsEvent, view) {
                /*var elements = document.getElementsByClassName('side-panel-add-service-window');
                while(elements.length > 0){
                    elements[0].parentNode.removeChild(elements[0]);
                }*/
                if($scope.editable) {
                    $scope.current_event = calEvent
                    var start_Time = calEvent.start.format("HH:mm")+":00";
                    var end_date = calEvent.end
                    if(end_date.format('mm')== '00' || end_date.format('mm')== '30'){
                        end_date.subtract({ minutes: 1});
                    }
                    var end_Time = end_date.format("HH:mm")+":00";
                    var side_panel_tpl = $compile('<span id="edit_service_window" ' +
                        'sidepanel template="edit_service_window" ' +
                        'title="view" ' +
                        'remove_on_close="true" ' +
                        'index="' + this.$index + '"' +
                        'collection=""></span>')($scope);

                    angular.element('body').append(side_panel_tpl);
                    /*
                    let overlapping = $scope.isOverlapping(calEvent.start, end_date);
                    if(!overlapping){
                        var side_panel_tpl = $compile('<span id="edit_service_window" ' +
                            'sidepanel template="edit_service_window" ' +
                            'title="view" ' +
                            'remove_on_close="true" ' +
                            'index="' + this.$index + '"' +
                            'collection=""></span>')($scope);

                        angular.element('body').append(side_panel_tpl);
                    }
                    $http.get(Routing.generate("service_window_slot_exist")+"?startTime="+start_Time+"&endTime="+end_Time)
                        .success(function (data, status) {
                                if (status == 200) {
                                    $scope.slot_exist = data
                                    var side_panel_tpl = $compile('<span id="edit_service_window" ' +
                                        'sidepanel template="edit_service_window" ' +
                                        'title="view" ' +
                                        'remove_on_close="true" ' +
                                        'index="' + this.$index + '"' +
                                        'collection=""></span>')($scope);

                                    angular.element('body').append(side_panel_tpl);

                                }});*/
                }
            },
            droppable: true,
            drop: function(date, jsEvent, ui, resourceId, allDay) {
                if($scope.currentView == 'twoWeek' || $scope.currentView == 'month') {
                    allDay = 'fullday';
                }
                //$scope.handleEventDrop(date, jsEvent, resourceId, allDay);
            },
            eventDrop: function(event) {
                $scope.eventDragStarted = false;
                $scope.handleMultipleDaySelection(event);
            },
            eventResizeStart: function(event) {
                $scope.eventDragStarted = true;
            },
            eventResizeStop: function(event) {
                $scope.eventDragStarted = false;
                $scope.show_event_pop_up = false;
            },
            eventDragStart: function(event) {
                $scope.eventDragStarted = true;
            },
            eventDragStop: function(event) {
                $scope.eventDragStarted = false;
                $scope.show_event_pop_up = false;
            },
            eventResize: function(event) {
                //var newEvent = $scope.convertEvent(event);
                $scope.updateEvent(event);
            },
            eventRender: function (event, element, view) {
                var contentObj = csDiaryHelpers.getEventHtml(view, event);
                var content = contentObj.html;

                if (view.name == 'timelineDay' || view.name == 'timelineWeek') {
                    // Add the popup attributes
                    angular.forEach(contentObj.popup, function(val, key) {
                        element.attr(key, val);
                    });
                }

                var classNames = $(content).attr('class');
                if (view.name == 'twoWeek' || view.name == 'month') {

                } else {
                    element.addClass(' ' + classNames);
                }
                content = $(content).removeClass(classNames);
                element.html(content);
                $compile(element)($scope);
            }
        }
    };
    $scope.getPopUpData = function getPopUpData($event, eventId) {
        //$scope.pop_up_data =  _.where($scope.events, { id: eventId })[0];
       // csPopUpPosition.init($event);
    }

    $scope.eventDoubleClick = function eventDoubleClick(eventId) {
        //console.log("event Double click"+eventId)
    }
    $scope.eventSingleClick = function eventSingleClick(eventId) {
        //console.log("event single click"+eventId)
    }
    $scope.setTimeDifferenceArray = function (start, end, allDay) {
        $scope.timeDiffArray = [];
        if(!allDay) {
            var timeDiff = moment(end).diff(moment(start))/1000/60;
            var slotMinutes = moment.duration($scope.serviceWindowsUiConfig.calendar.slotDuration)._data.minutes;
            if(timeDiff > slotMinutes) {
                for(var count = 1; count < timeDiff/slotMinutes; count++) {
                    var duration = count*slotMinutes;
                    var durationStr = '';
                    var hours = Math.floor(duration / 60);
                    var minutes = duration % 60;
                    if(hours > 0) {
                        durationStr += hours + ' hour';
                    }
                    if(minutes > 0) {
                        durationStr += (durationStr != '') ? ' ' : '';
                        durationStr += minutes + ' mins';
                    }
                    $scope.timeDiffArray.push({'value' : duration, 'description':durationStr});
                }
            }
        }
    }
    $scope.onSelect = function(start, end, allDay, resourceId) {
        $scope.start = start;
        $scope.end = end;
        $scope.allDay = allDay;
        $scope.resourceId = resourceId;
        $scope.milestones = [];
        $scope.setTimeDifferenceArray(start, end, allDay);
        $scope.handleViewDiaryEvent()

    }
    $scope.handleMultipleDaySelection = function(event) {
        var selectionStart = moment(event.start.format());
        var selectionEnd = moment(event.end.format());
        //var eventToCheck = _.findWhere($scope.events, { id: event.id });
        var eventStart = selectionStart.clone();//eventToCheck.start;
        var startOfDate = moment(eventStart).startOf('day');
        var endOfDate = moment(eventStart).add(1, 'day').startOf('day');
        if(!event.allDay && ((selectionStart < startOfDate && selectionEnd >= startOfDate) || (selectionEnd > endOfDate && selectionStart <= endOfDate))) {
            warningModal.show('ServiceWindow.multiple.day.warning.message', 'ServiceWindow.multiple.day.title', 'service_window_multiple_day_message');
            $http.get(Routing.generate("getServiceWindow", { service_window_id: oldEventId}))
                .success(function (data, status) {
                    var response = data[0]
                    $scope.delete_data_loaded = false;
                    const index = _.findIndex($scope.serviceWindowsObj, {id: response.id});
                    $scope.serviceWindowsObj[index] = response
                    $scope.updateEvents();
                })
                .catch(function (e) {
                    console.log(e)
                })
        } else {
            //var newEvent = $scope.convertEvent(event);
            $scope.updateEvent(event);
        }
    }

    $scope.loadingAllEvents = false; // To restrict the next and prev click when loading all events
    $scope.loadAllEvents = function() {
        $scope.service_windows_slots = [];
        if($scope.serviceWindowsObj.length){
            angular.forEach($scope.serviceWindowsObj, function (value, key) {
                var default_date = $scope.defaultDate.format('YYYY-MM-DD');
                let event_start_Time = default_date+" "+moment(value['startTime'].date).format("HH:mm")+":00";
                let event_end_Time =moment(default_date+" "+moment(value['endTime'].date).format("HH:mm")+":00");
                let temp_event_end = event_end_Time
                if(temp_event_end.format("HH:mm") != "23:59"){
                    event_end_Time.add({ minutes: 1});
                }
                let all_day_event = value['allDayEvent'];
                let am_event = value['amEvent']
                let pm_event = value['pmEvent']
                $scope.service_windows_slots.push({
                    all_day_event:all_day_event,
                    am_event:am_event,
                    pm_event:pm_event,
                    customerAppearance:value['customerAppearance'],
                    id:value['id'],
                    name:value['name'],
                    startTime: moment(event_start_Time),
                    endTime: event_end_Time
                })
            });
            $scope.check_all_slots_booked();
            $scope.updateEvents();
        }

    }
    $scope.updateEvent = function(event) {
        var statusObj = event.status;
        var showUndo = true;
        var oldEventId = event.id;
        var selectionStart = moment(event.start.format());
        var selectionEnd = moment(event.end.format());
        var start_Time = selectionStart.format("HH:mm")+":00";
        var end_date = selectionEnd
        if(end_date.format('mm')== '00' || end_date.format('mm')== '30'){
            end_date.subtract({ minutes: 1});
        }
        var end_Time = end_date.format("HH:mm")+":00"
        let overlapping = $scope.isOverlapping(selectionStart, end_date, oldEventId);
        if(overlapping){
            warningModal.show('ServiceWindow.Overlapping.message', 'Close', 'service_window_overlapping_message');
            angular.element('#service-windows-cs-calendar').fullCalendar('unselect');
            $scope.updateEvents();
        }
        else {
            event.startTime = selectionStart;
            selectionEnd.add({minutes: 1})
            event.endTime = selectionEnd;
            const index = _.findIndex($scope.service_windows_slots, {id: oldEventId});
            $scope.service_windows_slots[index] = event;
            $scope.settings_tab_status = false;
            $scope.check_all_slots_booked();
            $scope.updateEvents();
            $scope.stickyMessage = "ServiceWindow slot time updated";
            $scope.handleSticky(10000);
        }
        /*$http.get(Routing.generate("service_window_slot_exist")+"?startTime="+start_Time+"&endTime="+end_Time+"&slot_Id="+oldEventId)
            .then(function(data, status) {
                if (data.data['overlapping'] == true) {
                    warningModal.show('ServiceWindow.Overlapping.message', 'ServiceWindow.Overlapping.title', 'service_window_overlapping_message');
                    $http.get(Routing.generate("getServiceWindow", { service_window_id: oldEventId}))
                        .success(function (data, status) {
                            var response = data[0]
                            $scope.delete_data_loaded = false;
                            const index = _.findIndex($scope.serviceWindowsObj, {id: response.id});
                            $scope.serviceWindowsObj[index] = response
                            $scope.updateEvents();
                        })
                        .catch(function (e) {
                            console.log(e)
                        })
                    return false;
                }
                else {
                    $scope.service_window_status = data.data['service_window_status'];
                    $scope.settings_tab_status = data.data['settings_tab_status'];
                    $http.get(Routing.generate("getServiceWindow", { service_window_id: oldEventId}))
                        .success(function (data, status) {
                            var response = data[0]
                            const index = _.findIndex($scope.serviceWindowsObj, {id: response.id});
                            $scope.serviceWindowsObj[index] = response
                            $scope.updateEvents();
                            $scope.stickyMessage = "ServiceWindow slot time updated";
                            $scope.handleSticky(10000);
                        })
                        .catch(function (e) {
                            console.log(e)
                        })

                }
            }).catch(function (e) {
            console.log(e)
        });*/

    }
    $scope.updateEvents = function() {
        var events = []
        if($scope.service_windows_slots.length){
            angular.forEach($scope.service_windows_slots, function (value, key) {
                var default_date = $scope.defaultDate.format('YYYY-MM-DD');
                var event_start_Time,event_start_Time_formatted,event_end_Time_formatted,event_end_Time_formatted;
                event_start_Time = default_date+" "+value['startTime'].format("HH:mm")+":00";
                event_start_Time_formatted = value['startTime'].format("hh:mm A");
                var event_end = value['endTime'];
                event_end_Time = default_date+" "+event_end.format("HH:mm")+":00";
                var temp_event_end = value['endTime'];
                if(temp_event_end.format("HH:mm") != "23:59"){
                    event_end_Time_formatted = (event_end).format("hh:mm A");
                }
                else {
                    event_end_Time_formatted = '12:00 AM';
                }
                let all_day_event = value['all_day_event'];
                let am_event = value['am_event'];
                let pm_event = value['pm_event'];
                let  event_included = $scope.event_included(all_day_event, am_event, pm_event)
                value.editable = true;
                value.durationEditable = true;
                if(!$scope.editable) {
                    value.editable = false;
                    value.durationEditable = false;
                }
                events.push({
                    actualTime:0,
                    allDay:false,
                    status:{class:''},
                    end_time:event_end_Time_formatted,
                    start_time:event_start_Time_formatted,
                    duration:"",
                    event_included:event_included,
                    all_day_event:all_day_event,
                    am_event:am_event,
                    pm_event:pm_event,
                    durationEditable:value.durationEditable,
                    editable:value.editable,
                    startTime:value['startTime'],
                    endTime:event_end,
                    eventColor:"cs-event-colour-14",
                    customerAppearance:value['customerAppearance'],
                    grayedOut:false,
                    id:value['id'],
                    isSpecialEvent:false,
                    resourceId:2,
                    start:event_start_Time,
                    end:event_end_Time,
                    title:value['name'],
                    name: value['name'],
                    type:"service_window"
                })
                $scope.service_windows_slots[key]['startDT'] = value['startTime'].format('HH:mm')
                $scope.service_windows_slots[key]['endDT'] = event_end.format('HH:mm')
            });
        }
        angular.element('#service-windows-cs-calendar').fullCalendar('unselect');
        angular.element('#service-windows-cs-calendar').fullCalendar('removeEvents');
        $scope.currentEvents = events;
        $scope.events = events;
        if(events.length) {
            angular.element('#service-windows-cs-calendar').fullCalendar('addEventSource', events);
        }
    }
    $scope.event_included = function (full_day, am, pm) {
        var event_included = ''
        if(full_day && am && pm){
            event_included = "Full day event,AM event,PM event included"
        }
        else if(full_day && !am && !pm){
            event_included = "Full day event included"
        }
        else if(!full_day && am && !pm){
            event_included = "AM event included"
        }
        else if(!full_day && !am && pm){
            event_included = "PM event included"
        }
        else if(full_day && am && !pm){
            event_included = "Full day event, AM event included"
        }
        else if(full_day && !am && pm){
            event_included = "Full day event, PM event included"
        }
        else if(!full_day && am && pm){
            event_included = "Am event, PM event included"
        }
        else {
            event_included = "No events included"
        }
        return event_included;
    }
    $scope.handleViewDiaryEvent = function handleViewDiaryEvent() {
        var start_Time = $scope.start.format("HH:mm")+":00";
        var end_date = $scope.end
        //end_date.subtract({ minutes: 1});
        var end_Time = end_date.format("HH:mm")+":00";
        var eventStart = $scope.start;
        let overlapping = $scope.isOverlapping(eventStart, end_date)
        if(!overlapping){
            var side_panel_tpl = $compile('<span id="add_service_window" ' +
                'sidepanel template="add_service_window" ' +
                'title="view" ' +
                'remove_on_close="true" ' +
                'index="' + this.$index + '"' +
                'collection=""></span>')($scope);

            angular.element('body').append(side_panel_tpl);
        }
        else {
            warningModal.show('ServiceWindow.Overlapping.message', 'Close', 'service_window_overlapping_message');
            angular.element('#service-windows-cs-calendar').fullCalendar('unselect');
        }
        /*$http.get(Routing.generate("service_window_slot_exist")+"?startTime="+start_Time+"&endTime="+end_Time)
            .success(function (data, status) {
                if (status == 200 && !data['overlapping']) {
                    $scope.slot_exist = data
                    var side_panel_tpl = $compile('<span id="add_service_window" ' +
                        'sidepanel template="add_service_window" ' +
                        'title="view" ' +
                        'remove_on_close="true" ' +
                        'index="' + this.$index + '"' +
                        'collection=""></span>')($scope);

                    angular.element('body').append(side_panel_tpl);
                }
                else{
                    warningModal.show('ServiceWindow.Overlapping.message', 'ServiceWindow.Overlapping.title', 'service_window_overlapping_message');
                    uiCalendarConfig.calendars.csCalendar.fullCalendar('unselect');
                }
            })
            .catch(function (e) {
                console.log(e)
            })*/
    }

    $scope.getStartEndDate = function(viewName, dateObj, option) {
        var stringToAdd = '';
        var numberToAdd = 1;
        var valueToMultiple = (option == 'next') ? 1 : -1;
        var newEndDate = false;
        var newStartDate = false;
        if(viewName == 'agendaDay') {
            stringToAdd = 'days';
            numberToAdd = 1 * valueToMultiple;
        } else if(viewName == 'agendaWeek') {
            stringToAdd = 'weeks';
            numberToAdd = 1 * valueToMultiple;
        } else if(viewName == 'twoWeek') {
            stringToAdd = 'weeks';
            numberToAdd = 2 * valueToMultiple;
        } else if(viewName == 'month') {
            stringToAdd = 'months';
            numberToAdd = 1 * valueToMultiple;
            var addMonth = moment($scope.startDate).add(numberToAdd, stringToAdd);
            var startOfMonth = addMonth.clone().startOf('month');
            newStartDate = startOfMonth.clone().startOf('week').format('YYYY-MM-DD');
            var endOfMonth = addMonth.clone().endOf('month');
            newEndDate = endOfMonth.clone().endOf('week').add(1, 'day').format('YYYY-MM-DD');
        } else {
            return false;
        }
        var newStartDate = (!newStartDate) ? moment(dateObj).add(numberToAdd, stringToAdd).format('YYYY-MM-DD') : newStartDate;
        var newEndDate = (!newEndDate) ? moment(newStartDate).add(Math.abs(numberToAdd), stringToAdd).format('YYYY-MM-DD') : newEndDate;
        return {startDate: newStartDate, endDate: newEndDate};
    }
    $scope.revertUpdatedEvent = function() {
        $scope.closeSticky();
        var event = $scope.eventToUndo;
        //newEvent.revertedEvent = true;
        $scope.eventToUndo = {}
    }

    $scope.handleSticky = function(time) {
        $scope.showSticky = true;
        $timeout(function () {
            $scope.closeSticky();
        }, time);
    }

    $scope.closeSticky = function() {
        $scope.showRevert = false;
        $scope.showSticky = false;
    }

    $scope.setUndoEvent = function(diaryId) {
        for(var index = 0; index < $scope.events.length; index++) {
            if($scope.events[index].id == diaryId) {
                $scope.eventToUndo = $scope.events[index];
                break;
            }
        }
    }
    $rootScope.$on('service_window:updated', function(e, event_details) {
        //$scope.events.push(event);
        $scope.service_window_status = event_details['service_window_status']
        $scope.settings_tab_status = event_details['settings_tab_status']
        $scope.service_windows_slots.push(event_details);
        $scope.check_all_slots_booked();
        $scope.settings_tab_status = false;
        $scope.updateEvents();
    });
    $rootScope.$on('service_window:event_updated', function(e, value) {
        var event_id = value.id;
        const index = _.findIndex($scope.service_windows_slots, {id: event_id});
        $scope.service_windows_slots[index] = value;
        $scope.check_all_slots_booked();
        $scope.settings_tab_status = false;
        $scope.updateEvents();
    });
    $rootScope.$on('service_window:event_deleted', function(e, value) {
        var event_id = value.id;
        const index = _.findIndex($scope.service_windows_slots, {id: event_id});
        $scope.service_windows_slots.splice(index, 1)
        $scope.service_window_status = value['service_window_status']
        $scope.settings_tab_status = false;
        $scope.check_all_slots_booked();
        $scope.updateEvents();

    });
    $rootScope.$on('parent:updated',function(){
        angular.element('#service-windows-cs-calendar').fullCalendar('unselect');
    });
    $rootScope.$on("service_window:sidepanel:closed",function (e, value) {
        angular.element('#service-windows-cs-calendar').fullCalendar('unselect');
    })

    $scope.getEventStartEndValues = function(start, allDay) {
        var startOfDay = start.clone().startOf('day');
        var startDateTime = (allDay == 'pm') ? startOfDay.clone().add(12, 'h') : startOfDay;
        var timeToAdd = (allDay == 'fullday') ? 24 : 12;
        var endDateTime = startDateTime.clone().add(timeToAdd, 'h');
        return {start: startDateTime, end: endDateTime};
    }
    $scope.convertEvent = function(event) {
        var end = event.end;
        var editable = true;
        if(!$scope.editable) {
            editable = false;
        }
        if(event.allDay == 'fullday' && end == null) {
            end = moment(event.start).clone().add(1, 'day').startOf('day');
        }
        var newEvent = {
            id: event.id,
            title: event.title,
            start: event.start,
            event_start: event.start,
            end: end,
            event_end: end,
            allDay: event.allDay,
            resourceId: event.resourceId,
            isSpecialEvent: event.isSpecialEvent,
            actualTime: event.actualTime,
            duration: event.duration,
            status: event.status,
            type: event.type,
            editable: editable,
            durationEditable: event.durationEditable
        };
        return newEvent;
    }
    /**
     * Function to handle save all service windows
     */
    $scope.saveServiceWindowSlots = function() {
        $scope.data_loaded = true;
        var data_items = formatBackendData($scope.service_windows_slots);
        $http.post(Routing.generate("saveServiceWindow"),JSON.stringify(data_items))
            .success(function (data) {
                $scope.data_loaded = false;
                toastBox.show(data.message, 4000);
                $scope.settings_tab_status = true;
            })
            .catch(function (e) {
                console.log(e)
            })
    }
    /**
     * Function to check service windows is overlapped
     * @param start
     * @param end
     * @param service_window_id
     * @returns {boolean}
     */
    $scope.isOverlapping = function(start, end, service_window_id) {
        let overlapping = false;
        let current_date = moment().format('YYYY-MM-DD');
        start = moment(current_date+' '+ start.format('HH:mm:ss'));
        end = moment(current_date+' '+ end.format('HH:mm:ss'));
        if(start.isSame(end) && $scope.service_windows_slots && $scope.service_windows_slots.length > 0) {
            overlapping =true;
        }
        else {
            $.each($scope.service_windows_slots, function(index, value){
                let service_window_startTime, service_window_endTime;
                service_window_startTime = moment(current_date+' '+ value['startTime'].format('HH:mm:ss'));
                service_window_endTime = moment(current_date+' '+ value['endTime'].format('HH:mm:ss'));
                if((start > service_window_startTime && start < service_window_endTime) || (end > service_window_startTime && end < service_window_endTime)){
                    if(service_window_id ==  value['id']){
                        overlapping = false;
                        return true;
                    }
                    else {
                        overlapping =true;
                        return false;
                    }
                }
                else {
                    return true;
                }
            })
        }
        return overlapping;
    }
    /**
     * Function to handle check all slots booked or not
     */
    $scope.check_all_slots_booked = function(){
        let duration = 0;
        $scope.amEvent = false;
        $scope.pmEvent = false;
        $scope.allDayEvent = false;
        $scope.slots_booked = false;
        $.each($scope.service_windows_slots, function(index, value){
            let start=value['startTime'], end=value['endTime'];
            let diff = end.diff(start, "minutes");
            duration = duration + diff;
            if(value['amEvent'] || value['am_event']){
                $scope.amEvent = true;
            }
            if(value['pmEvent'] || value['pm_event']){
                 $scope.pmEvent= true;
            }
            if(value['allDayEvent'] || value['all_day_event']){
                $scope.allDayEvent = true;
            }
        });
        duration  = (duration + $scope.service_windows_slots.length) * 0.0166667;
        if(Math.round(duration) == 24){
            $scope.slots_booked = true;
        }
        if($scope.slots_booked && $scope.allDayEvent && $scope.amEvent && $scope.pmEvent){
            $scope.service_window_status = true;
        }
        else{
            $scope.service_window_status = false;
        }
    }

    /**
     * Function to format the backend data
     * @param service_windows
     * @returns {Array}
     */
    function formatBackendData(service_windows) {
        var data_items = []
        service_windows.forEach(data => {
            var item = {
                id: data['id'],
                name: data['name'],
                all_day_event: data['all_day_event'],
                am_event: data['am_event'],
                pm_event: data['pm_event'],
                customerAppearance: data['customerAppearance'],
                startDT: data['startDT'],
                endDT: data['endDT']
            };
            data_items.push(item);
        })
        return data_items;
    }
    /**
     * Function to handle delete all service windows
     */
    $scope.deleteServiceWindows = function() {
        $scope.data_loaded = true;
        var data_items = formatBackendData($scope.service_windows_slots);
        $http.post(Routing.generate("deleteAllServiceWindows"),JSON.stringify(data_items))
            .success(function (data) {
                $scope.data_loaded = false;
                if(data.status == 200){
                    toastBox.show(data.message, 4000);
                    $scope.settings_tab_status = false;
                    $scope.service_window_status = false;
                    $scope.service_windows_slots = [];
                    $scope.check_all_slots_booked();
                    $scope.updateEvents();
                }
                else{
                    toastBox.show(data.message, 4000);
                }

            })
            .catch(function (e) {
                console.log(e)
            })
    }
}

var serviceWindowsResolver = {
    serviceWindowsObj: function ($q, $http, $stateParams, $state, PubNub, $rootScope, cacheBust,prefix, adminPrefix) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var deferred = $q.defer();
        $http.get(this.templateUrl($stateParams)).success(function (data, status, headersObj) {
            var headers = headersObj();
            SetRootScopeValues($rootScope, headers, PubNub, cacheBust, $http, prefix, adminPrefix, $state);
            if (data.location && data.location == 'logout') {
                $rootScope.$broadcast('chat:unregister');
                $state.transitionTo('home');
            } else if (data.location && data.location.endsWith("/")) {
                //$state.transitionTo('home');
            } else {
                deferred.resolve(data);
            }
        });
        return deferred.promise;
    }
}

function getTimeDifferenceArrayData(start, end, allDay) {
    var timeDiffArrayData = [];
    if(!allDay) {
        var timeDiff = moment(end).diff(moment(start))/1000/60;
        var slotMinutes = 30;
        if(timeDiff > slotMinutes) {
            for(var count = 1; count < timeDiff/slotMinutes; count++) {
                var duration = count*slotMinutes;
                var durationStr = '';
                var hours = Math.floor(duration / 60);
                var minutes = duration % 60;
                if(hours > 0) {
                    durationStr += hours + ' hour';
                }
                if(minutes > 0) {
                    durationStr += (durationStr != '') ? ' ' : '';
                    durationStr += minutes + ' mins';
                }
                timeDiffArrayData.push({'value' : duration, 'description':durationStr});
            }
        }
    }
    return timeDiffArrayData;
}

/**
 * Function to handle check exist service window name and customer customerAppearance
 * @param $scope
 */
function serviceWindowCheckExist($scope) {
    $scope.checkExist = function(key_name, id, existValue){
        var checkExist = false;
        $.each($scope.service_window_ctrl_scope.service_windows_slots, function(index, value){
            if(existValue == value[key_name] && id != value['id']){
                checkExist = true;
                return false;
            }
            else {
                return true;
            }
        });
        return checkExist;
    }
};
csmodule.controller('sidePanel_service_window', ['$scope', '$rootScope', '$q', '$filter', '$timeout',  'diaryRightBarHelper', 'prefix', '$window', '$state','$http','formService', 'toastBox', function($scope, $rootScope, $q, $filter, $timeout,  diaryRightBarHelper, prefix, $window, $state, $http, formService, toastBox) {
    serviceWindowCheckExist.call(this, $scope);
    $scope.service_window_ctrl_scope = $scope.$parent.$parent;
    $scope.start_date = $scope.service_window_ctrl_scope.start.format("hh:mm A");
    let endTime = moment(moment().format('YYYY-MM-DD')+' '+ $scope.service_window_ctrl_scope.end.format('HH:mm'));
    let temp_date = endTime;
    let local_dt;
    if(temp_date.format("HH:mm") == '00:00'){
        local_dt = $scope.service_window_ctrl_scope.end;
        local_dt.subtract({minutes:1});
    }
    else {
        local_dt = $scope.service_window_ctrl_scope.end;
    }
    if(temp_date.format('mm') == '00' || temp_date.format('mm') == '30'){
        $scope.end_date = endTime.format("hh:mm A");
    }
    else {
        temp_date.add({minutes:1});
        $scope.end_date = temp_date.format("hh:mm A");
    }
    $scope.service_window = {
        startTime:$scope.service_window_ctrl_scope.start,
        endTime:local_dt,
        customerAppearance:"",
        name:"",
        am_event:false,
        pm_event:false,
        all_day_event:false,
        id: Math.floor(1000 + Math.random() * 9000)

    }
    $scope.openPanel = function openPanel() {
        $timeout(function() {
            $scope.initialise();
        }, 0);
    }
    $scope.$watch('service_window.name', function(newVal, oldVal) {
        if(newVal != oldVal && newVal){
            let isExist = $scope.checkExist('name',$scope.service_window.id, newVal);
            if(isExist){
                $scope.serviceWindowForm["service_window.name"].$setValidity('checkExists', false);
            }
            else {
                $scope.serviceWindowForm["service_window.name"].$setValidity('checkExists', true);
            }
        }
    });
    $scope.$watch('service_window.customerAppearance', function(newVal, oldVal) {
        if(newVal != oldVal && newVal){
            let isExist = $scope.checkExist('customerAppearance',$scope.service_window.id, newVal);
            if(isExist){
                $scope.serviceWindowForm["service_window.customerAppearance"].$setValidity('checkExists', false);
            }
            else {
                $scope.serviceWindowForm["service_window.customerAppearance"].$setValidity('checkExists', true);
            }
        }
    });
    $scope.saveServiceWindow = function() {
        $scope.data_loaded = true;
        toastBox.show("Service window created", 4000);
        $rootScope.$emit('service_window:updated', $scope.service_window);
        $scope.handleSticky(10000);
        $rootScope.$broadcast('sidepanel:close');
    }
    $scope.handleSticky = function(time) {
        $scope.showSticky = true;
        $timeout(function () {
            $scope.closeSticky();
        }, time);
    }
    $scope.closeSidePanel = function () {
        $rootScope.$broadcast('sidepanel:close');
        $rootScope.$emit('service_window:sidepanel:closed', 'closed');
    }

    $scope.closeSticky = function() {
        $scope.showRevert = false;
        $scope.showSticky = false;
    }


}]);
csmodule.controller('sidePanel_edit_service_window', ['$scope', '$rootScope', '$q', '$filter', '$timeout',  'diaryRightBarHelper', 'prefix', '$window', '$state','$http','formService', 'toastBox', function($scope, $rootScope, $q, $filter, $timeout,  diaryRightBarHelper, prefix, $window, $state, $http, formService, toastBox) {
    serviceWindowCheckExist.call(this, $scope);
    $scope.service_window_ctrl_scope = $scope.$parent.$parent;
    $scope.start_date = $scope.service_window_ctrl_scope.current_event.start.format("hh:mm A");
    let temp_date = moment(moment().format('YYYY-MM-DD')+' '+ $scope.service_window_ctrl_scope.current_event.end.format('HH:mm'));
    let local_dt;
    if(temp_date.format("HH:mm") == '00:00'){
        local_dt = $scope.service_window_ctrl_scope.current_event.end;
        local_dt.subtract({minutes:1});
    }
    else {
        local_dt = $scope.service_window_ctrl_scope.current_event.end;
        if(temp_date.format('HH:mm') != '23:59' && (temp_date.format('mm') != '00' || temp_date.format('mm') != '30')){
            local_dt.add({minutes:1});
        }
    }
    if(temp_date.format('mm') != '00' || temp_date.format('mm') != '30'){
        temp_date.add({minutes:1});
        $scope.end_date = temp_date.format("hh:mm A");
    }
    $scope.previous_am_event = $scope.$parent.$parent.current_event.am_event;
    $scope.previous_pm_event = $scope.$parent.$parent.current_event.pm_event;
    $scope.previous_all_day_event = $scope.$parent.$parent.current_event.all_day_event;
    $scope.service_window = {
        startTime:$scope.service_window_ctrl_scope.current_event.start,
        endTime:local_dt,
        customerAppearance:$scope.service_window_ctrl_scope.current_event.customerAppearance,
        name:$scope.service_window_ctrl_scope.current_event.title,
        am_event:$scope.service_window_ctrl_scope.current_event.am_event,
        pm_event:$scope.service_window_ctrl_scope.current_event.pm_event,
        all_day_event:$scope.service_window_ctrl_scope.current_event.all_day_event,
        id:$scope.service_window_ctrl_scope.current_event.id

    };
    $scope.$watch('service_window.name', function(newVal, oldVal) {
        if(newVal != oldVal && newVal){
            let isExist = $scope.checkExist('name',$scope.service_window.id, newVal);
            if(isExist){
                $scope.serviceWindowForm["service_window.name"].$setValidity('checkExists', false);
            }
            else{
                $scope.serviceWindowForm["service_window.name"].$setValidity('checkExists', true);
            }
        }
    });
    $scope.$watch('service_window.customerAppearance', function(newVal, oldVal) {
        if(newVal != oldVal && newVal){
            let isExist = $scope.checkExist('customerAppearance',$scope.service_window.id, newVal);
            if(isExist){
                $scope.serviceWindowForm["service_window.customerAppearance"].$setValidity('checkExists', false);
            }
            else{
                $scope.serviceWindowForm["service_window.customerAppearance"].$setValidity('checkExists', true);
            }
        }
    });

    $scope.openPanel = function openPanel() {
        $timeout(function() {
            $scope.initialise();
        }, 0);
    }
    $scope.closeSidePanel = function () {
        $rootScope.$broadcast('sidepanel:close');
        $rootScope.$emit('service_window:sidepanel:closed', 'closed');
    }

    $scope.updateServiceWindow = function() {
        toastBox.show("Service window updated", 4000);
        $rootScope.$emit('service_window:event_updated', $scope.service_window);
        $scope.handleSticky(10000);
        $rootScope.$broadcast('sidepanel:close');
    }
    $scope.deleteRecord = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        //$scope.delete_data_loaded = true;
        toastBox.show("Service window deleted", 4000);
        $scope.service_window['service_window_enabled'] = false;
        $scope.service_window['service_window_status'] = false;
        $rootScope.$emit('service_window:event_deleted', $scope.service_window);
        $scope.handleSticky(10000);
        $rootScope.$broadcast('sidepanel:close');
    }

    $scope.handleSticky = function(time) {
        $scope.showSticky = true;
        $timeout(function () {
            $scope.closeSticky();
        }, time);
    }

    $scope.closeSticky = function() {
        $scope.showRevert = false;
        $scope.showSticky = false;
    }

}]);

