/** Factory for interacting with map */
var csMapperFactory = ['$compile', '$timeout', 'gpsServerConfig', '$interpolate', '$diaryMapScope', '$q', 'mapDetails', function ($compile, $timeout, gpsServerConfig, $interpolate, $diaryMapScope, $q, $mapDetails) {

    function CS_MAPPER_FACTORY() {
        var mapper = this;
        this.map = null;
        this.tileServer = gpsServerConfig.mapTilesServer || 'https://map.commusoft.net/styles/com-streets/{z}/{x}/{y}.png';
        this.mapStyle = gpsServerConfig.mapStylesUrl || 'https://map.commusoft.net/styles/com-streets/style.json';
        this.renderType = 'RASTER';

        this.config = {
            center: $mapDetails.mapLocation, // London
            // center: [13.021601, 80.207022], // Chennai
            zoom: $mapDetails.mapZoom,
            // zoom: 15,
            layers: [],
            inertia: true,
            inertiaDeceleration: 10000,
            inertiaMaxSpeed: 1000
        };

        this.mapEventsObervable = $q.defer();
        this.mapEvents = this.mapEventsObervable.promise;

        this.layerControl = L.control.layers(null, null);

        // Creating custom map controls
        this.extraControl = L.Control.extend({
            options: {
                position: 'topleft'
            },

            onAdd: function (map) {
                var panel = L.DomUtil.create('div', 'leaflet-control-zoom leaflet-bar leaflet-control');

                var resetButton = L.DomUtil.create('a', 'leaflet-control-zoom-in', panel);
                resetButton.href = 'javascript:void(0);';
                resetButton.innerHTML = '<i class="fas fa-expand"></i>';

                resetButton.onmouseover = function () {
                };
                resetButton.onmouseout = function () {
                };

                resetButton.onclick = function (event) {
                    event.stopPropagation();
                    mapper.adjustMapBounds();
                };

                return panel;
            }
        });

        this.layers = {};
        this.layersAttached = [];
        this.markers = {};
        this.routes = {};
        this.navPaths = {};
        this.customMapControls = {};

        // Initializes map
        this.initialize = function (elementId, renderType, show_layer_controls = true, onLoadCallback) {
            this.map = L.map(elementId, this.config);

            // Defining Render type
            if (renderType && renderType.toUpperCase() === 'VECTOR') {

                this.renderType = 'VECTOR';
                // Tooling GL
                this.GL = L.mapboxGL({accessToken: 'no-token', style: this.mapStyle});
                this.GL.addTo(this.map);

            } else {

                this.renderType = 'RASTER';
                // Adding base tiles layer
                this.layers.tileLayer = L.tileLayer(this.tileServer, {attribution: ''});
                this.layers.tileLayer.addTo(this.map);

            }
            if (show_layer_controls) {
                this.layerControl.addTo(this.map); // Layer controls for map
            }

            new this.extraControl().addTo(this.map); // Custom map controls

            var dblClkTimer;
            this.map.on('click', function (e) {
                mapper.map.doubleClickZoom.enable();

                // Actions only on single click
                if (dblClkTimer) {
                    clearTimeout(dblClkTimer);
                    dblClkTimer = null;
                }
                else
                    dblClkTimer = setTimeout(function () {
                        mapper.mapEventsObervable.notify({
                            action: 'mapClick',
                            event: e
                        });

                        if (mapper.markers['_focusMarker']) mapper.removeFocusPointerMarker();
                        dblClkTimer = null;
                    }, 300);
            });

            // Trigger on load callback
            if (isFunction(onLoadCallback)) this.map.on('load', onLoadCallback());

            return this.map;
        };

        this.addMapControl = function (name, iconClass, callback) {
            if (this.customMapControls[name]) this.removeMapControl(name);

            var control = L.Control.extend({
                options: {
                    position: 'topleft'
                },

                onAdd: function (map) {
                    var panel = L.DomUtil.create('div', 'leaflet-control-zoom leaflet-bar leaflet-control');

                    var button = L.DomUtil.create('a', 'leaflet-control-zoom-in', panel);
                    button.innerHTML = '<i class="' + iconClass + '"></i>';

                    button.onmouseover = function () {
                    };
                    button.onmouseout = function () {
                    };

                    button.onclick = function (event) {
                        event.stopPropagation();
                        callback();
                    };

                    return panel;
                }
            });

            this.customMapControls[name] = new control().addTo(this.map);

            return control;
        };

        this.removeMapControl = function (name) {
            if (this.customMapControls[name]) this.map.removeControl(this.customMapControls[name]);
        };

        // Including additional layers to the map
        this.addOverlay = function (name, layerControl = true, controlLabel, hidden = false, target) {
            target = target && this.layers[target] ? this.layers[target] : this.map;
            var elements = [];
            if (!controlLabel) controlLabel = name;

            var lGroup = L.featureGroup(elements);
            this.layers[name] = lGroup;

            if (!hidden) {
                lGroup.addTo(target);
                mapper.layersAttached.push(name);
            }
            if (layerControl) this.layerControl.addOverlay(lGroup, controlLabel);

            return lGroup;
        };

        this.hasOverlay = function (name) {
            return this.layers[name];
        };

        this.showOverlay = function (name, target) {
            if (!target) target = this.map;

            if (this.layers[name] && !target.hasLayer(this.layers[name])) {
                target.addLayer(this.layers[name]);
                mapper.layersAttached.push(name);
            }
        };

        this.hideOverlay = function (name, target) {
            if (!target) target = this.map;

            if (this.layers[name] && target.hasLayer(this.layers[name])) {
                target.removeLayer(this.layers[name]);

                if (mapper.layersAttached.indexOf(name) >= 0) mapper.layersAttached.splice(mapper.layersAttached.indexOf(name), 1);
            }
        };

        // Removes all the elements from the layer
        this.cleanOverlay = function (name, target) {
            if (!target) target = this.map;

            if (this.hasOverlay(name) && target.hasLayer(this.layers[name])) this.layers[name].clearLayers();

            var removedMarkers = Object.keys(this.markers).filter(function (lName) {
                return lName.startsWith(name + '_');
            });

            removedMarkers.forEach(function (name) {
                delete mapper.markers[name];
            })
        };

        // Creating Markers on a layer
        this.addDivMarker = function (layerName, details, scope, html, tooltipHtml, anchor) {

            var lat = details.latitude;
            var long = details.longitude;
            if (!anchor) anchor = [0, 0];

            if (typeof(lat) !== 'number' || typeof(long) !== 'number') return null;

            var markerName = layerName + '_' + details.markerSuffix;
            this.markers[markerName] = {scope: scope, html: html, tooltipHtml: tooltipHtml};

            // Binding scope into the marker icon template
            if (scope) {
                this.markers[markerName].html = $compile(this.markers[markerName].html)(scope);
                if (tooltipHtml) this.markers[markerName].tooltipHtml = $compile(this.markers[markerName].tooltipHtml)(scope);
            }

            var marker = L.marker([lat, long], {riseOnHover: true});


            // Add marker icon
            marker.setIcon(
                L.divIcon({
                    className: details.markerIconClassName,
                    iconSize: null,
                    iconAnchor: anchor
                })
            ).on('add', function (e) {
                $(e.target._icon).html(mapper.markers[markerName].html);

                $timeout(function () {
                    scope.$apply();
                })
            }).on('dblclick', function (e) {
                mapper.map.doubleClickZoom.disable();
            }).on('remove', function (e) {
                if (mapper.markers[markerName].scope) mapper.markers[markerName].scope.unSubscribeEvents();
            });

            // Add marker tooltip
            if (tooltipHtml) {
                marker.bindTooltip('', {
                        opacity: 1,
                        direction: "left"
                    }
                ).on('tooltipopen', function (e) {
                    $(e.target._tooltip._contentNode).html(mapper.markers[markerName].tooltipHtml);

                    $timeout(function () {
                        scope.$apply();
                    })
                });
            }

            this.markers[markerName]['marker'] = marker;

            marker.addTo(this.layers[layerName]);
            // L.marker([lat, long]).addTo(this.layers[layerName]); // For adjusting position
            return markerName;
        };

        // Moving Marker
        this.moveMarker = function (layerName, details) {
            var lat = details.latitude;
            var long = details.longitude;

            var markerName = layerName + '_' + details.markerSuffix;

            if (Object.keys(this.markers).indexOf(markerName) >= 0) {
                this.markers[markerName].marker.setLatLng([lat, long]);

                return markerName;
            } else return false;

        };

        // Checks the marker is already drawn
        this.markerExists = function (layerName, markerSuffix) {
            return this.markers[layerName + '_' + markerSuffix];
        };

        // Removing Marker
        this.deleteMarker = function (layerName, markerSuffix) {
            var markerName = layerName + '_' + markerSuffix;
            if (!this.markers[markerName]) return;

            var marker = this.markers[markerName].marker;

            angular.forEach(marker._eventParents, function (parentLayer, parentLayerId) {
                parentLayer.removeLayer(marker);
            });

            delete  this.markers[markerName];

        };

        // Displaying Marker
        this.showMarker = function (layerName, markerSuffix) {
            var markerName = layerName + '_' + markerSuffix;
            if (!this.layers[layerName].hasLayer(this.markers[markerName].marker))
                this.layers[layerName].addLayer(this.markers[markerName].marker);

            return markerName;
        };

        // Hiding Marker
        this.hideMarker = function (layerName, markerSuffix) {
            var markerName = layerName + '_' + markerSuffix;
            this.layers[layerName].removeLayer(this.markers[markerName].marker);

            return markerName;
        };

        // Drawing route
        this.addRoute = function (layerName, points, color, suffix, content, popupHtml) {
            var routeName = layerName + '_' + suffix;
            color = color ? color : 'blue';

            mapper.routes[routeName] = {
                path: L.featureGroup()
            };

            // Path stroke
            var stroke = L.polyline(points, {
                color: mapper.lightenDarkenColor(color, 50),
                lineJoin: 'round',
                weight: 7,
                className: ''
            });
            mapper.routes[routeName]['path'].addLayer(stroke);

            // Path
            var path = L.polyline(points, {
                color: color,
                lineJoin: 'round',
                weight: 5,
                className: ''
            });
            mapper.routes[routeName]['path'].addLayer(path);

            mapper.routes[routeName]['startPoint'] = L.marker(points[0], {
                icon: L.divIcon({
                    html: markerIconHtml(content, 'green'),
                    iconAnchor: [24, 47],
                    className: 'user-route-start-end-marker'
                }),
                riseOnHover: true
            });

            mapper.routes[routeName]['endPoint'] = L.marker(points[points.length - 1], {
                icon: L.divIcon({
                    html: markerIconHtml(content, 'red'),
                    iconAnchor: [24, 47],
                    className: 'user-route-start-end-marker',
                    opacity: 0
                }),
                riseOnHover: true
            });

            if (popupHtml) {
                var popupOptions = {
                    className: 'user-route-popup',
                    position: 'left'
                };

                mapper.routes[routeName]['path'].bindPopup(popupHtml, popupOptions);

                popupOptions.className = popupOptions.className.concat(' popup-right');
                popupOptions.offset = L.point(-6, 12);

                mapper.routes[routeName]['startPoint'].bindPopup(popupHtml, popupOptions);
                mapper.routes[routeName]['endPoint'].bindPopup(popupHtml, popupOptions);

            }

            mapper.routes[routeName]['startPoint'].addTo(mapper.layers[layerName]);
            mapper.routes[routeName]['path'].addTo(mapper.layers[layerName]);
            mapper.routes[routeName]['endPoint'].addTo(mapper.layers[layerName]);

            // Smoothly draw
            // (function addLatLong(i) {
            //     ++i;
            //
            //     if (i <= points.length - 1 && points[i][0] !== 0 && points[i][1] !== 0) { // point 0.0,0.0 will be omitted
            //         setTimeout(function(){
            //             stroke.addLatLng(points[i]);
            //             path.addLatLng(points[i]);
            //             addLatLong(i);
            //         }, 0);
            //     } else if(i <= points.length - 1) {
            //         addLatLong(i);
            //     } else {
            //         mapper.routes[routeName]['endPoint'].addTo(mapper.layers[layerName]);
            //         mapper.adjustMapBounds();
            //     }
            // })(0);

            return routeName;
        };

        // Displaying route
        this.showRoute = function (layerName, suffix) {
            var routeName = layerName + '_' + suffix;

            if (!this.layers[layerName].hasLayer(this.routes[routeName].startPoint)) this.layers[layerName].addLayer(this.routes[routeName].startPoint);
            if (!this.layers[layerName].hasLayer(this.routes[routeName].path)) this.layers[layerName].addLayer(this.routes[routeName].path);
            if (!this.layers[layerName].hasLayer(this.routes[routeName].endPoint)) this.layers[layerName].addLayer(this.routes[routeName].endPoint);

            return routeName;
        };

        // Hiding route
        this.hideRoute = function (layerName, suffix) {
            var routeName = layerName + '_' + suffix;


            this.layers[layerName].removeLayer(this.routes[routeName].startPoint);
            this.layers[layerName].removeLayer(this.routes[routeName].path);
            this.layers[layerName].removeLayer(this.routes[routeName].endPoint);

            return routeName;
        };

        // Updating map bounds in order to view all layers
        this.adjustMapBounds = function (layers) {
            var layersToFit = layers instanceof Array ? layers : mapper.layersAttached;

            var bounds = {
                'northEast_lat': [],
                'northEast_lng': [],
                'southWest_lat': [],
                'southWest_lng': []
            };

            layersToFit.forEach(function (lName) {
                if (lName === 'tileLayer') return;

                var bound = mapper.layers[lName].getBounds();

                if (!_.isEmpty(bound)) {
                    bounds.northEast_lat.push(bound._northEast.lat);
                    bounds.northEast_lng.push(bound._northEast.lng);
                    bounds.southWest_lat.push(bound._southWest.lat);
                    bounds.southWest_lng.push(bound._southWest.lng);
                }
            });

            if (!_.isEmpty(bounds.northEast_lat) && !_.isEmpty(bounds.northEast_lng) && !_.isEmpty(bounds.southWest_lat) && !_.isEmpty(bounds.southWest_lng)) {

                var sw = L.latLng(Math.min.apply(null, bounds.southWest_lat), Math.min.apply(null, bounds.southWest_lng));
                var ne = L.latLng(Math.max.apply(null, bounds.northEast_lat), Math.max.apply(null, bounds.northEast_lng));

                if (sw.equals(ne)) {
                    sw.lat -= 0.1;
                    sw.lng -= 0.1;

                    ne.lat += 0.1;
                    ne.lng += 0.1;
                }


                var latLngBounds = L.latLngBounds(sw, ne);

                this.map.flyToBounds(latLngBounds, {
                    duration: 2,
                    maxZoom: this.map.getBoundsZoom(latLngBounds, false)
                });
            }
        };

        this.fitMapToMarkers = function (markers) {
            if (!markers.length) return;

            if (markers.length === 1) {
                this.map.flyTo(markers[0].getLatLng(), 9, {
                    duration: 2
                });
            } else {
                var latLngBounds = L.latLngBounds(markers.map(function (m) {
                    return m.getLatLng();
                }));

                this.map.flyToBounds(latLngBounds, {
                    duration: 2,
                    maxZoom: this.map.getBoundsZoom(latLngBounds, false) - 1
                });
            }

        };

        // Creating a Pointer & Centering map on that
        this.addPointingMarker = function (layerName, alias, details, scope, tooltipHtml, clickCallback, anchor) {

            // set marker position
            var iconAnchorPosition = [24, 45];
            if (anchor) {
                iconAnchorPosition = anchor;
            }
            var lat = details.latitude,
                long = details.longitude,
                content = details.content || '<i class="icon fas fa-home"></i>',
                color = details.color || 'brown';


            if (typeof(lat) !== 'number' || typeof(long) !== 'number') return null;

            if (!alias) alias = 'pointer';
            var markerName = layerName + '_' + alias;
            this.removePointingMarker(layerName);

            this.markers[markerName] = {scope: scope, tooltipHtml: tooltipHtml};

            // Binding scope into the marker icon template
            if (scope && tooltipHtml) {
                this.markers[markerName].tooltipHtml = $compile(this.markers[markerName].tooltipHtml)(scope);
            }

            var marker = L.marker([lat, long], {riseOnHover: true});

            // Add marker icon
            marker.setIcon(
                L.divIcon({
                    html: markerIconHtml(content, color),
                    iconSize: [48, 48],
                    iconAnchor: iconAnchorPosition,
                    popupAnchor: [-37, -10],
                    shadowAnchor: [0, 0],
                    className: 'user-route-start-end-marker'
                })
            );

            // Add marker tooltip
            if (tooltipHtml) {
                marker.bindTooltip('', {
                        opacity: 1,
                        direction: "left"
                    }
                ).on('tooltipopen', function (e) {
                    $(e.target._tooltip._contentNode).html(mapper.markers[markerName].tooltipHtml);

                    $timeout(function () {
                        scope.$apply();
                    })
                });
            }

            // Callback for marker click event
            if (clickCallback) {
                marker.on('click', function (e) {
                    clickCallback(e);
                });
            }

            this.markers[markerName]['marker'] = marker;

            var event = marker.addTo(this.layers[layerName]);

            // Binding data to marker icon for future reference
            if (details.bindData) {
                Object.keys(details.bindData).forEach(function (key) {
                    $(event).data(key, details.bindData[key]);
                });
            }

            this.panToMarker(layerName, alias);

            return markerName;
        };

        // Removing pointing marker from the layer
        this.removePointingMarker = function (layerName, alias) {
            if (!alias) alias = 'pointer';
            var markerName = layerName + '_' + alias;

            if (this.markerExists(layerName, alias)) this.deleteMarker(layerName, alias);

            return markerName;
        };

        this.panToMarker = function (layerName, markerSuffix) {
            var markerName = layerName + '_' + markerSuffix;

            if (!this.markerExists(layerName, markerSuffix)) return null;

            // Centering map
            var marker = this.markers[markerName].marker, latLng;
            if (typeof(marker) === 'number') {
                marker = this.getLayer(marker);
                latLng = marker.getLatLng();
            }
            else if (marker instanceof L.FeatureGroup) {
                var bounds = marker.getBounds();

                if (JSON.stringify(bounds._southWest) !== JSON.stringify(bounds._northEast)) {

                    this.map.flyToBounds(bounds, {
                        duration: 2,
                        maxZoom: this.map.getBoundsZoom(getBounds(), false) - 3
                    });
                    return markerName;
                }

                latLng = bounds.getCenter();

            } else {
                latLng = marker.getLatLng();
            }

            this.map.flyTo(latLng, 15, {duration: 2});
            return markerName;
        };

        // Drawing navigation polyline
        this.addNavigation = function (layerName, pathSuffix, points, color, details, popupHtml) {
            var pathName = layerName + '_' + pathSuffix;
            color = color ? color : 'blue';
            var route = L.featureGroup();
            var subscriptions = {};

            // Path stroke
            var stroke = L.polyline(points, {
                color: mapper.lightenDarkenColor(color, 60),
                lineJoin: 'round',
                weight: 6,
                className: ''
            });
            route.addLayer(stroke);

            // Path
            var path = L.polyline(points, {
                color: color,
                lineJoin: 'round',
                weight: 5,
                className: ''
            });
            route.addLayer(path);

            if (popupHtml) {
                route.bindPopup(popupHtml, {

                    className: 'cs-map-nav-path-popup',
                    closeButton: false,
                    offset: L.point(0, 0),
                    autoPan: false
                }).on('add', function (event) {
                    event.target.openPopup();
                });

                // subscriptions['zoomstart'] = function (e) {
                //     route.openPopup();
                // };
                // this.map.on('zoomstart', subscriptions['zoomstart']);

                subscriptions['zoomend'] = function (e) {
                    route.openPopup();
                };
                this.map.on('zoomend', subscriptions['zoomend']);
            }


            route.addTo(this.layers[layerName]);

            this.navPaths[pathName] = {
                path: route,
                details: details,
                eventSubscriptions: subscriptions
            };

            return pathName;
        };

        // Create dot in map
        this.addPoint = function (layerName, latLong, options) {
            if (!options instanceof Object) options = {};

            var point = L.circle(latLong, options);
            point.addTo(this.layers[layerName]);
        };

        // Adding previously created navigation path into layer
        this.showNavigation = function (layerName, pathSuffix) {
            var pathName = layerName + '_' + pathSuffix;

            if (this.navPaths[pathName] && !this.layers[layerName].hasLayer(this.navPaths[pathName].path))
                this.navPaths[pathName].path.addTo(this.layers[layerName]);

            return pathName;
        };

        // Removing/Deleting navigation polyline from layer
        this.hideNavigation = function (layerName, pathSuffix, remove) {
            var pathName = layerName + '_' + pathSuffix;
            var map = this.map;

            if (this.navPaths[pathName]) {
                angular.forEach(this.navPaths[pathName].eventSubscriptions, function (fn, eventName) {
                    map.removeEventListener(eventName, fn);
                });

                this.layers[layerName].removeLayer(this.navPaths[pathName].path);
                if (remove) delete this.navPaths[pathName];
            }

            return pathName;
        };

        this.lightenDarkenColor = function (col, amt) {
            var format = 'rgb';

            if (col[0] == "#") {
                col = col.slice(1);
                format = 'hex';
            }

            // Converting to HEX
            if (format === 'rgb') {
                col = col.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

                function hex(x) {
                    return ("0" + parseInt(x).toString(16)).slice(-2);
                }

                col = '#' + hex(col[1]) + hex(col[2]) + hex(col[3]);
            }

            var ldColor = '000';
            var num = parseInt(col, 16);
            var r = (num >> 16) + amt;

            if (r > 255) r = 255;
            else if (r < 0) r = 0;

            var b = ((num >> 8) & 0x00FF) + amt;

            if (b > 255) b = 255;
            else if (b < 0) b = 0;

            var g = (num & 0x0000FF) + amt;

            if (g > 255) g = 255;
            else if (g < 0) g = 0;
            ldColor = (g | (b << 8) | (r << 16)).toString(16);

            return '#' + ldColor;
        };

        // Resizing map to fit into the container
        this.resize = function () {
            this.map.invalidateSize(true);
        }

        this.addUpdateDivInterpolatedMarker = function (layerName, details, template, anchor) {
            details.bindingData = Object.assign({
                width: 250,
                height: 25,
                color: '#202020'
            }, details.bindingData);

            var lat = details.latitude,
                lng = details.longitude,
                content = $interpolate(template)(details.bindingData),
                markerName = layerName + '_' + details.markerSuffix;

            anchor = anchor || [12, 22];


            var markerData = this.markerExists(layerName, details.markerSuffix), marker = null;


            // Updating existing marker
            if (markerData) {
                marker = markerData.marker;

                marker.setIcon(
                    L.divIcon({
                        html: content,
                        iconAnchor: anchor,
                        className: '',
                        zIndexOffset: 100
                    })
                );

                marker.setLatLng([lat, lng]);
                marker.fire('add');
            }
            // Create new marker
            else {
                marker = L.marker([lat, lng], {riseOnHover: true});
                marker.identifier = markerName;

                // Add marker icon
                marker.setIcon(
                    L.divIcon({
                        html: content,
                        iconAnchor: anchor,
                        className: '',
                        zIndexOffset: 100
                    })
                );

                marker.addTo(this.layers[layerName]);

                this.markers[markerName] = {marker: marker};
            }
            return marker;
        };

        this.bindFocusEvent = function (layerName, marker, data) {
            marker.focusEvent = true;
            marker.onFocusData = data;

            marker.on('click', function (event) {
                if (event.originalEvent) {
                    event.originalEvent.preventDefault();
                    event.originalEvent.stopPropagation();
                }

                mapper.addFocusPointerMarker(event);
            });
        };

        this.addFocusPointerMarker = function (event) {
            var focusingMarker = event.target;
            var latLng = focusingMarker.getLatLng(),
                focusPointer = L.featureGroup([]),
                removedLayers = [],
                data = Object.assign({
                    width: 300,
                    height: 55,
                    color: '#E60000',
                }, focusingMarker.onFocusData);

            if (!focusingMarker instanceof L.Layer) return;

            if (this.markers['_focusMarker']) this.removeFocusPointerMarker();
            focusPointer.identifier = focusingMarker.identifier;

            // Pointer
            L.marker(latLng, {riseOnHover: true})
                .setIcon(
                    L.divIcon({
                        html: $diaryMapScope.mapElementTemplates['focus_pointer_marker'],
                        iconAnchor: [18, 57],
                        className: '',
                        zIndexOffset: 500
                    })
                ).addTo(focusPointer);

            // Address
            $diaryMapScope.handleMapInfoWidgetChange('show', data.type, data.data);

            // Add events
            focusPointer.on('click', function (event) {
                event.originalEvent.stopPropagation();
            }).on('remove', function () {
                $diaryMapScope.handleMapInfoWidgetChange('hide');
            });

            angular.forEach(focusingMarker._eventParents, function (parentLayer, parentLayerId) {
                removedLayers.push([parentLayerId, focusingMarker]);
                parentLayer.removeLayer(focusingMarker);

                if (data.links instanceof Array) {
                    data.links.forEach(function (linkedMarker) {
                        var m = mapper.getLayer(linkedMarker[1]);
                        if (!m) return;

                        angular.forEach(m._eventParents, function (mParentLayer, mParentLayerId) {
                            removedLayers.push([mParentLayerId, m]);
                            mParentLayer.removeLayer(m);
                        });
                    })
                }

            });


            focusPointer.addTo(this.map);

            this.markers['_focusMarker'] = {
                marker: focusPointer,
                detachedMarkers: removedLayers
            };

        };

        this.removeFocusPointerMarker = function (expired = false) {
            var data = this.markers['_focusMarker'];

            if (this.map.hasLayer(data.marker)) {
                this.map.removeLayer(data.marker);

                if (!expired)
                    data.detachedMarkers.forEach(function (m) {
                        var parentLayer = mapper.getLayer(m[0]);
                        if (parentLayer && !parentLayer.hasLayer(m[1])) parentLayer.addLayer(m[1]);
                    });
            }

            delete this.markers['_focusMarker'];
        };

        this.getLayer = function (id) {
            var layerGroups = Object.values(this.layers),
                layer;

            for (var i = 0; i < layerGroups.length; i++) {
                layer = layerGroups[i]._leaflet_id == id ? layerGroups[i] : layerGroups[i].getLayer(id);
                if (layer) break;
            }

            return layer;
        };

        this.getLayerId = function (layer) {
            if (layer instanceof L.Layer) {
                return layer._leaflet_id;
            }
        };

        this.validateLatLng = function (lat, lng) {
            lat = parseFloat((lat || '').toString());
            lng = parseFloat((lng || '').toString());
            return Boolean(lat !== NaN && lng !== NaN && lat !== 0 && lng !== 0 && -90 <= lat && lat <= 90 && -180 <= lng && lng <= 180);
        }

        this.removeMap = function() {
            this.map.remove();
        }

    }

    function markerIconHtml(content, mClass) {
        var colors = {
            red: ' #a63a3a',
            green: '#50a69a',
            blue: '#0d11a6',
            brown: ' #b14b1a',
        };

        return '<svg  ' +
            'xmlns="http://www.w3.org/2000/svg"' +
            'xmlns:xlink="http://www.w3.org/1999/xlink"' +
            'xmlns:xhtml="http://www.w3.org/1999/xhtml" ' +
            'class="cs-marker-round" width="50" height="50" viewBox="0 0 30 30">\n' +
            '    <defs>\n' +
            '        <filter id="svg_2_blur">\n' +
            '            <feGaussianBlur stdDeviation="0.5" in="SourceGraphic"/>\n' +
            '        </filter>\n' +
            '    </defs>\n' +
            '    <g>\n' +
            '        <title>background</title>\n' +
            '        <rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/>\n' +
            '    </g>\n' +
            '    <g>\n' +
            '        <ellipse filter="url(#svg_2_blur)" opacity="0.2" ry="1.4" rx="3.7" id="svg_2" cy="27.51494" cx="15.032382" fill-opacity="null" stroke-opacity="null" stroke-width="null" stroke="null" fill="null"/>\n' +
            '        <path id="svg_1" fill="' +
            (colors[mClass] || colors['blue']) +  // -------------------- Marker color
            '" d="m15,0.834197c-6.075,0 -11,4.925 -11,11c0,7.234 7.152,10.697 8.048,11.503c0.915,0.823 1.671,2.668 1.976,3.714c0.148,0.508 0.564,0.765 0.976,0.776c0.413,-0.012 0.828,-0.269 0.976,-0.776c0.305,-1.046 1.061,-2.89 1.976,-3.714c0.896,-0.806 8.048,-4.269 8.048,-11.503c0,-6.075 -4.925,-11 -11,-11z"/>\n' +
            '        <foreignObject xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="9" font-weight="bold" color="white" id="svg_3" y="6.5" x="10.3" stroke-opacity="null" stroke-width="0" stroke="null" fill="#ffffff" width="50" height="50">' +
            content + // ------------- Marker content
            '</foreignObject>\n' +
            '    </g>\n' +
            '</svg>';
    }

    return {
        get: function () {
            return new CS_MAPPER_FACTORY();
        }
    }
}];

/** Factory to create child scope for diary map marker for jobs/estimates */
var jobEstimateMapMarkerScopeService = ['csPopUpPosition', '$timeout', '$sce', function (csPopUpPosition, $timeout, $sce) {
    this.new = function ($parentScope, properties) {
        var $childScope = $parentScope.$new(true);
        $childScope.show_event_pop_up = true;
        $childScope.diaryEvent = {};
        $childScope.csColour = '';
        $childScope.tooltipConfig = {
            placement: 'left',
            offset: 15,
            html: true,
            htmlContentSelector: '#tooltip-content',
            className: 'map-jobestimatemarker-tooltip',
            animation: false
        };
        $childScope = angular.extend($childScope, properties);
        $childScope.pop_up_data = $childScope.diaryEvent;

        $childScope.mouseEnter = function ($event) {
            $childScope.$emit('dmJobEstimateMarker:eventEnter', [$event, $childScope.diaryEvent.id]);
        };
        $childScope.mouseLeave = function ($event) {
            $childScope.$emit('dmJobEstimateMarker:eventLeave', [$event, $childScope.diaryEvent.id]);
        };

        // Job/Estimate single click action
        $childScope.singleClick = function ($event) {
            $childScope.$emit('dmJobEstimateMarker:singleClick', $childScope.diaryEvent.id);
        };

        // Job/Estimate double click action
        $childScope.doubleClick = function ($event) {
            $childScope.$emit('dmJobEstimateMarker:doubleClick', $childScope.diaryEvent.id);
        };

        // Job/Estimate user image single click action
        $childScope.singleClickUser = function ($event) {
            $childScope.$emit('dmJobEstimateMarker:singleClickUser', $childScope.diaryEvent.resourceId);
        };

        $childScope.update = function (data) {
            if (data instanceof Object) $timeout(function () {
                Object.keys(data).forEach(function (k) {
                    $childScope[k] = data[k]
                });
            })
        };

        $childScope.formatEventTime = function (datetime) {
            return moment(datetime).format('hh:mm a');
        };

        $childScope.trustSrc = function (url) {
            return $sce.trustAsResourceUrl(url);
        };

        // Unsubscribing events
        $childScope.unSubscribeEvents = function () {
        };

        return $childScope;
    }
}];

/** Factory to create child scope for diary map marker for users */
var userMapMarkerScopeService = ['$timeout', '$sce', function ($timeout, $sce) {
    this.new = function ($parentScope, properties) {
        var $childScope = $parentScope.$new(true);
        $childScope.markerDefaultColor = '#3A6889';
        $childScope.markerColor = null;
        $childScope.userDetails = {};
        $childScope.positionSource = 'GPS';
        $childScope.positionDetails = {};
        $childScope.destinationDetails = {};
        $childScope.lastIdentifiedTime = new Date();
        $childScope.tooltipConfig = {
            placement: 'left',
            html: true,
            htmlContentSelector: '#tooltip-content',
            className: 'map-usermarker-tooltip',
            animation: false
        };
        $childScope = angular.extend($childScope, properties);

        $childScope.mouseEnter = function ($event) {
            $childScope.$emit('dmUserMarker:enter', [$event, {
                userDetails: $childScope.userDetails,
                positionDetails: $childScope.positionDetails,
                positionSource: $childScope.positionSource
            }]);
        };
        $childScope.mouseLeave = function ($event) {
            $childScope.$emit('dmUserMarker:leave', [$event, {
                userDetails: $childScope.userDetails,
                positionDetails: $childScope.positionDetails,
                positionSource: $childScope.positionSource
            }]);
        };

        // User single click action
        $childScope.singleClick = function ($event) {
            $event.stopPropagation();
            $childScope.$emit('dmUserMarker:singleClick', $childScope.userDetails.id);
        };

        // Defines the class for coloring the clock
        $childScope.getClkColor = function () {
            if ($childScope.positionSource !== 'GPS') return false;

            var duration = moment.duration(moment().diff($childScope.positionDetails.deviceTime));
            var minutes = duration.asMinutes();

            if (minutes <= 19) return null;
            else if (19 < minutes && minutes <= 60) return '#60a369';
            else return '#a36060';
        };

        $childScope.isMoving = function () {
            if ($childScope.positionSource !== 'GPS') return false;

            var duration = moment.duration(moment().diff($childScope.lastIdentifiedTime));
            return duration.asMinutes() <= 3;

        };

        $childScope.getEstimateDurationStr = function (seconds) {
            // As format 12h 2m
            // var str = [];
            // var duration = moment.duration(parseInt(seconds), 'seconds');
            //
            // if(duration.asDays() > 1) str.push(parseInt(duration.asDays()) + ' days');
            // if(duration.asHours() > 0) str.push(parseInt(duration.asHours() % 24) + ' hrs');
            // if(duration.asMinutes() > 0) str.push(parseInt(duration.asMinutes() % 60) + ' mins');
            //
            // return str.slice(0, 2).join(' ');

            // As format 12:02
            return moment().add(parseInt(seconds), 's').format('HH:mm');
        };

        var updateTimer;
        $childScope.update = function (data, selfInvoke) {
            if (!selfInvoke && updateTimer) {
                clearTimeout(updateTimer);
                updateTimer = null;
            }

            if (data instanceof Object) $timeout(function () {

                Object.keys(data).forEach(function (k) {
                    $childScope[k] = data[k]
                });

                if ($childScope.positionDetails.deviceTime)
                    $childScope.lastIdentifiedTime = $childScope.positionDetails.deviceTime;
                // var
            });

            setUpdateTimer();
        };

        function setUpdateTimer() {
            // Setting self update timer
            updateTimer = setTimeout(function () {
                $childScope.update({
                    lastIdentifiedTime: $childScope.positionDetails.deviceTime
                }, true)
            }, 15000);
        }

        // Unsubscribing events
        $childScope.unSubscribeEvents = function () {
            if (updateTimer) {
                clearTimeout(updateTimer);
                updateTimer = null;
            }
        };

        $childScope.trustSrc = function (url) {
            return $sce.trustAsResourceUrl(url);
        };

        // Initialization
        $childScope.init = function () {
            if ($childScope.positionDetails.deviceTime)
                $childScope.lastIdentifiedTime = $childScope.positionDetails.deviceTime;
        };

        $childScope.init();
        return $childScope;
    }
}];

/** Factory to create child scope for diary map marker for users */
var outstandingJobMapElementService = ['$timeout', '$sce', function ($timeout, $sce) {
    this.new = function ($parentScope, mapper, properties) {
        var $childScope = $parentScope.$new(true);
        $childScope.layerGroup = L.featureGroup([]);

        $childScope.location = null;
        $childScope.address = '';
        $childScope.showAddress = false;

        $childScope.init = function () {
            mapper.addDivMarker();

        };

        mapper.map.on('zoomend', function () {

        });

        return $childScope;
    }
}];

/** Factory for with GPS tracker API */
var gpsTracker = ['$rootScope', '$http', '$q', 'gpsServerConfig', function ($rootScope, $http, $q, gpsServerConfig) {
    this.session = null;
    this.socket = null;
    this.httpRequests = {};
    var self = this;


    // Creating gpsServerConfig session
    this.createSession = function () {
        var deffered = $q.defer();

        // Checking module feature permission
        var hasPermission = $rootScope.hasPermission(features['Viewengineerslocation'], 'readaccess');
        if (!hasPermission) deffered.reject("User doesn't have permission to view engineers locations");

        if (self.session) {
            deffered.resolve(self.session);
        } else if (!gpsServerConfig.apiUrl || !gpsServerConfig.token) {
            deffered.reject('Invalid API details');
        } else {

            var c = atob(gpsServerConfig.token).split(':');

            $http({
                url: gpsServerConfig.apiUrl + '/session',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'Accept': 'application/json',
                    'Original-Request': undefined,
                    'Timezone': undefined
                },
                data: 'email=' + c[0] + '&password=' + c[1],
                withCredentials: true,
                gpsOverride: true
            }).success(function (sessionResponse, status, headers) {
                self.session = sessionResponse;

                deffered.resolve(sessionResponse);
            }).error(function (error) {
                console.log(error);
                self.session = null;

                deffered.reject(error);
            });
        }

        return deffered.promise;
    };

    // Opening gpsServerConfig socket
    this.createSocket = function () {
        self.closeSocket();

        var deffered = $q.defer();

        // Creating session if not done before
        self.createSession().then(function (session) {
            handshake();

        }).catch(function (error) {
            deffered.reject('Failed to open Gps socket : Invalid session', error);
        });

        function handshake() {
            try {

                var socket = new WebSocket(gpsServerConfig.socketUrl);

                socket.onopen = function (event) {
                    console.log("Gps socket opened");

                    self.socket = socket;
                    deffered.resolve(self.socket);
                };

                socket.onclose = function (event) {
                    self.socket = null;
                    console.log("Gps socket closed");
                };

                socket.onerror = function (event) {
                    deffered.reject(event);
                };

            } catch (e) {
                deffered.reject(e);
            }
        }

        return deffered.promise;
    };

    // Closing gpsServerConfig socket
    this.closeSocket = function () {
        if (self.socket) self.socket.close();
    };

    // Retrieve last know positions of devices
    this.getPosition = function (deviceIds) {
        if (!deviceIds) deviceIds = [];

        var deffered = $q.defer();

        self.createSession().then(function (session) {
            $http({
                url: gpsServerConfig.apiUrl + '/positions'.concat((deviceIds.length > 0) ? '?deviceId='.concat(deviceIds.join("&deviceId=")) : ''),
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Original-Request': undefined,
                    'Timezone': undefined
                },
                withCredentials: true,
                gpsOverride: true
            }).success(function (positions) {
                deffered.resolve(positions);
            }).error(function (e) {
                console.log('Error while fetching positions : ', e);

                deffered.reject(e);
            });

        }).catch(function (e) {
            deffered.reject(e);
        });


        return deffered.promise;
    };

    // Retrieve route of devices within time interval
    this.getRoute = function (deviceIds, fromTimestamp, toTimestamp) {
        if (!deviceIds) deviceIds = [];

        console.log('css', $('#loading-indicator').css('display'));
        this.cancelGetRouteRequest();

        var deffered = $q.defer();

        if (deviceIds.length === 0) deffered.reject('Atleast one deviceId is required');

        var params = {
            from: fromTimestamp,
            to: toTimestamp,
            deviceIds: deviceIds.join(',')
        };

        var url = Routing.generate('map_historical_route')
            + '?from=' + encodeURIComponent(params.from)
            + '&to=' + encodeURIComponent(params.to)
            + '&deviceIds=' + encodeURIComponent(params.deviceIds)
        ;

        /*deviceIds.forEach(function (d) {
            url = url.concat('&deviceId=' + d);
        });*/

        self.createSession().then(function (session) {
            self.httpRequests['getRouteRequest'] = deffered;

            /*$http({
                url: url,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                withCredentials: true,
                timeout: deffered.promise
            }).success(function (positions) {
                delete self.httpRequests['getRouteRequest'];

                deffered.resolve(positions instanceof Array ? positions : []);
            }).error(function (e) {
                delete self.httpRequests['getRouteRequest'];

                console.log('Error while fetching routes : ', e);
                deffered.reject(e);
            });*/

            $http({
                url: url,
                method: 'GET',
                timeout: deffered.promise
            }).success(function (response, status, headers) {
                deffered.resolve(response);
            }).error(function (error) {
                console.log(error);

                deffered.reject(error);
            });

        }).catch(function (e) {
            deffered.reject(e);
        });

        return deffered.promise;
    };

    this.cancelGetRouteRequest = function () {
        if ('getRouteRequest' in this.httpRequests) {
            this.httpRequests['getRouteRequest'].resolve([]);
            delete this.httpRequests['getRouteRequest'];
        }
    };

    // Retrieve navigation paths
    this.getNavigations = function (details, requestName) {
        var deffered = $q.defer();

        this.httpRequests[requestName || 'getNavigationsRequest'] = deffered;

        $http({
            url: Routing.generate('map_route', {profile: 'driving'}),
            method: 'POST',
            data: 'data=' + encodeURIComponent(JSON.stringify(details)),
            timeout: deffered.promise
        }).success(function (response, status, headers) {
            deffered.resolve(response);
        }).error(function (error) {
            console.log(error);

            deffered.reject(error);
        });

        return deffered.promise;
    };

    this.cancelGetNavigationsRequest = function (requestName) {
        if (!requestName) requestName = 'getNavigationsRequest';
        if (requestName in this.httpRequests) {
            this.httpRequests[requestName].resolve([]);
            delete this.httpRequests[requestName];
        }
    };

    // Retrieve trips of devices within time interval
    this.getTrips = function (deviceIds, fromTimestamp, toTimestamp) {
        if (!deviceIds) deviceIds = [];

        var deffered = $q.defer();

        if (deviceIds.length === 0) deffered.reject('Atleast one deviceId is required');

        var params = {
            from: fromTimestamp,
            to: toTimestamp,
            deviceIds: deviceIds.join(',')
        };

        var url = gpsServerConfig.apiUrl
            + '/reports/trips'
            + '?from=' + encodeURIComponent(params.from)
            + '&to=' + encodeURIComponent(params.to)
            + '&deviceIds=' + encodeURIComponent(params.deviceIds)
        ;

        /*deviceIds.forEach(function (d) {
            url = url.concat('&deviceId=' + d);
        });*/

        self.createSession().then(function (session) {
            $http({
                url: gpsServerConfig.apiUrl + '/reports/trips?from=' + fromTimestamp + '&to=' + toTimestamp.concat((deviceIds.length > 0) ? '&deviceId='.concat(deviceIds.join("&deviceId=")) : ''),
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Original-Request': undefined,
                    'Timezone': undefined
                },
                withCredentials: true,
                gpsOverride: true
            }).success(function (trips) {
                deffered.resolve(trips);
            }).error(function (e) {
                console.log('Error while fetching trips : ', e);

                deffered.reject(e);
            });

        }).catch(function (e) {
            deffered.reject(e);
        });

        return deffered.promise;
    };

}];

