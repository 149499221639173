csmodule.controller('jobsSidePanelOnDiaryCtrl', function ($scope, $rootScope, $timeout, $state, $filter, $diaryMapScope, dateRange, outstandingJobsService) {
    $scope.foreignWatchers = {};
    $scope.selectedWindowIndex = $scope.panel_content.jobDueWindows.findIndex(function (w) {
        return w.windowName === $scope.panel_content.selectedWindowName;
    });
    $scope.windowColor = $scope.panel_content.jobDueWindows[$scope.selectedWindowIndex].color;
    $scope.outstandingJobsList = angular.copy($scope.panel_content.jobDueWindows[$scope.selectedWindowIndex].outstandingJobs);
    $scope.jobSearchQuery = '';

    // Filters
    $scope.filtersParams = {
        outstanding_jobs_count: false,
        specific: true,
        job_duration: $scope.panel_content.jobDuration,
        window_type: $scope.panel_content.jobDueWindows[$scope.selectedWindowIndex].windowType,
        window_start: $scope.panel_content.jobDueWindows[$scope.selectedWindowIndex].start,
        window_end: $scope.panel_content.jobDueWindows[$scope.selectedWindowIndex].end,
    };

    $scope._editFilters = false;
    $scope.fromDateTime = new Date($scope.panel_content.filtersApplied.bounding_start_datetime);
    $scope.toDateTime = new Date($scope.panel_content.filtersApplied.bounding_end_datetime);
    $scope.showDatePickerPopup = {
        fromDateTime: false,
        toDateTime: false
    };
    $scope.dateFilterOptions = dateRange.getFilterOptions();

    // Moment locale settings
    moment.locale('en', {
        calendar: {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: '[Last] dddd',
            nextWeek: 'dddd',
            sameElse: 'DD/MM/YYYY'
        }
    });

    $scope.showDatePicker = function (name) {
        $timeout(function () {
            $scope.$apply(function () {
                $scope.showDatePickerPopup[name] = true;
            })
        })
    };

    $scope.onTimeChange = function (name, value) {
        if (name === 'fromDateTime') {
            $scope.fromDateTime = value ? value._d : '';
        }
        else if (name === 'toDateTime') {
            $scope.toDateTime = value ? value._d : '';
        }
    };

    $scope.editFilters = function () {
        $scope._editFilters = true;
    };

    $scope.filtersAreValid = function () {
        return (!$scope.fromDateTime && !$scope.toDateTime)
            || ($scope.fromDateTime && $scope.toDateTime && moment($scope.fromDateTime).isBefore($scope.toDateTime));
    };

    $scope.applyFilters = function () {
        $scope._editFilters = false;

        var filters = angular.copy($scope.filtersParams);

        filters.bounding_start_datetime = $scope.fromDateTime ? moment($scope.fromDateTime).set({seconds: 0}).toISOString() : null;
        filters.bounding_end_datetime = $scope.toDateTime ? moment($scope.toDateTime).set({seconds: 59}).toISOString() : null;
        filters.search_text = $scope.jobSearchQuery;

        outstandingJobsService.getDueWindowJobs(filters).then(function (data) {
            if (data) $scope.updateData(data);
        });
    };

    $scope.handleJobSearchQuery = function () {
        var filters = angular.copy($scope.filtersParams);

        filters.bounding_start_datetime = $scope.fromDateTime ? moment($scope.fromDateTime).set({seconds: 0}).toISOString() : null;
        filters.bounding_end_datetime = $scope.toDateTime ? moment($scope.toDateTime).set({seconds: 59}).toISOString() : null;

        outstandingJobsService.searchDueWindowJobs($scope.jobSearchQuery, filters).then(function (data) {
            if (data) $scope.updateData(data);
        });
    };

    $scope.updateData = function (data) {
        $scope.outstandingJobsList = data.dueWindows[0].outstandingJobs;

        // Publish data to render jobs in map
        var newData = angular.copy($scope.panel_content.jobDueWindows);
        newData[$scope.selectedWindowIndex].outstandingJobs = $scope.outstandingJobsList;
        $diaryMapScope.onJobDueWindowsRefresh(newData);
    };

    $scope.clearFilters = function () {
        $scope.fromDateTime = '';
        $scope.toDateTime = '';
        $scope._fromTimePicker = '';
        $scope._toTimePicker = '';

        $scope.jobSearchQuery = '';

        $scope.applyFilters();
    };

    $scope.redirectToJob = function (event, oj) {
        event.stopPropagation();
        var url = '';
        if(oj.recordType == 'job') {
            var url = $state.href("loggedin.customer_list.view.job.details", {
                id: oj.customerId,
                jobId: oj.id,
                type: oj.propertyType
            });
        } else {
            var stateName = oj.isProject ? 'loggedin.customer_list.view.project_estimate_details.survey' : 'loggedin.customer_list.view.estimate_details.survey';
            url = $state.href(stateName, {
                id: oj.customerId,
                estimateId: oj.id,
                type: oj.propertyType
            });
        }

        window.open(url, '_blank');

        $scope.closeJobsSidePanel();
    };

    $scope.handleJobClick = function (oj) {
        $diaryMapScope.handleOutstandingJobSelection(oj, 'SIDE_PANEL');
        $scope.closeJobsSidePanel();
    };

    $scope.closeSidePanel = function () {
        debugger
    }

    $scope.getJobTime = function (oj) {
        var start = oj.startDatetime ? moment(oj.startDatetime) : null,
            end = oj.endDatetime ? moment(oj.endDatetime) : null;

        if (!start || !end) return '';
        else if (end.diff(start, 'Days') == 0) {
            return start.calendar() + ' ' + start.format('hh:mm a') + ' - ' + end.format('hh:mm a');

        } else {
            return start.format('DD/MM/YYYY hh:mm a') + ' - ' + end.format('DD/MM/YYYY hh:mm a');
        }
    };

    // Close panel
    $scope.closeJobsSidePanel = function () {
        $scope.$destroy();
        $diaryMapScope.$emit('jobsSidePanelOnDiary:close', {
            windowName: $scope.panel_content.jobDueWindows[$scope.selectedWindowIndex].windowName,
            windowColor: $scope.panel_content.jobDueWindows[$scope.selectedWindowIndex].color,
            windowType: $scope.panel_content.jobDueWindows[$scope.selectedWindowIndex].windowType,
            windowStartTime: $scope.panel_content.jobDueWindows[$scope.selectedWindowIndex].start,
            windowEndTime: $scope.panel_content.jobDueWindows[$scope.selectedWindowIndex].end,
            outstandingJobs: $scope.outstandingJobsList
        });
        $rootScope.$broadcast('sidepanel:close');
    };

    // Removes events on panel close
    $scope.$on('$destroy', function () {

        Object.keys($scope.foreignWatchers).forEach(function (n) {
            $scope.foreignWatchers[n]();
        });

    });
});