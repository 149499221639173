csmodule.directive('loading', function() {

	var handleLoading = function handleLoading(scope, element, attrs) {
		scope.loading_text = attrs.loadingText;

		attrs.$observe('loadingText', function(loading_text) {
      scope.loading_text = loading_text;
    });
	}

  return {
    restrict: 'A',
    templateUrl: 'template/shared/loading.html',
    scope: {},
    link: handleLoading
  }
});
