'use strict';

var assetsModule = angular.module('assets', []);

assetsModule.constant('prefix', window.prefixVal).
constant('defaultPagingValue', defaultPaginationLimit).
constant('excludedFileTypes', excludedFileTypes).
provider('canLoad',function () {
    this.$get = function () {
        return {
            canLoadAjax: true,
            setLoadValue: function (canLoad) {
                this.canLoadAjax = canLoad;
            },
            canLoad: function () {
                return this.canLoadAjax;
            }
        }
    }
}).
config(
    ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
        function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {
            $stateProvider.
            state('loggedin.customer_list.view.assets', {
                url: Routing.generateAngularRoute('customer_asset', true),
                templateUrl: function (params) {
                    return Routing.generate('customer_asset', params)
                },
                data: {
                    'pageNum': 1,
                    'limit': 5
                },
                views: {
                    "customerTabPane@": {
                        templateUrl: 'template/customers/assets/index.html',
                        controller: CustomerAssetCtrl,
                        resolve: BreadCrumbsPageLimitResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.add_customer_asset', {
                url: Routing.generateAngularRoute('add_customer_asset', true),
                templateUrl: function (params) { return Routing.generate('add_customer_asset', params)},
                views: {
                    "customerTabPane@": {
                        templateUrl: 'template/customers/assets/add-assets.html',
                        controller: CustomerAddAssetCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.add_sub_customer_asset', {
                url: Routing.generateAngularRoute('add_sub_customer_asset', true),
                templateUrl: function (params) { return Routing.generate('add_sub_customer_asset', params)},
                views: {
                    "customerTabPane@": {
                        templateUrl: 'template/customers/assets/add-assets.html',
                        controller: CustomerAddAssetCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.assets.details', {
                url: Routing.generateAngularRoute('asset_subnav', true),
                templateUrl: function (params) {
                    return Routing.generate('asset_subnav', params)
                },
                views: {
                    "customersPane@": {
                        templateUrl: 'template/customers/assets/subnav.html',
                        controller: CustomerAssetSubNavCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.assets.details.view_asset', {
                url: Routing.generateAngularRoute('asset_view', true),
                templateUrl: function (params) {
                    return Routing.generate('asset_view', params)
                },
                views: {
                    "customerAssetTabPane@loggedin.customer_list.view.assets.details": {
                        templateUrl: 'template/customers/assets/view.html',
                        controller: AssetViewCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.assets.details.view_sub_asset', {
                url: Routing.generateAngularRoute('sub_asset_view', true),
                templateUrl: function (params) {
                    return Routing.generate('sub_asset_view', params)
                },
                views: {
                    "customerAssetTabPane@loggedin.customer_list.view.assets.details": {
                        templateUrl: 'template/customers/assets/view.html',
                        controller: AssetSingleViewCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.assets.details.associated_asset', {
                url: Routing.generateAngularRoute('associated_asset_view', true),
                templateUrl: function (params) {
                    return Routing.generate('associated_asset_view', params)
                },
                views: {
                    "customerAssetTabPane@loggedin.customer_list.view.assets.details": {
                        templateUrl: 'template/customers/assets/associated_asset_view.html',
                        controller: AssociatedAssetViewCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.assets.details.associated_asset_list', {
                url: Routing.generateAngularRoute('associated_sub_asset_list_view', true),
                templateUrl: function (params) {
                    return Routing.generate('associated_sub_asset_list_view', params)
                },
                views: {
                    "customerAssetTabPane@loggedin.customer_list.view.assets.details": {
                        templateUrl: 'template/customers/assets/associated_asset_view.html',
                        controller: AssociatedAssetViewCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.assets.details.edit', {
                url: Routing.generateAngularRoute('edit_customer_asset', true),
                templateUrl: function (params) {
                    return Routing.generate('edit_customer_asset', params)
                },
                views: {
                    "customerAssetTabPane@loggedin.customer_list.view.assets.details": {
                        templateUrl: 'template/customers/assets/edit-asset.html',
                        controller: CustomerEditAssetCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.add_associated_customer_asset', {
                url: Routing.generateAngularRoute('add_associated_customer_asset', true),
                templateUrl: function (params) { return Routing.generate('add_associated_customer_asset', params)},
                views: {
                    "customerTabPane@": {
                        templateUrl: 'template/customers/assets/add-assets.html',
                        controller: CustomerAddAssetCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.assets.details.associated_edit', {
                url: Routing.generateAngularRoute('edit_associated_customer_asset', true),
                templateUrl: function (params) {
                    return Routing.generate('edit_associated_customer_asset', params)
                },
                views: {
                    "customerAssetTabPane@loggedin.customer_list.view.assets.details": {
                        templateUrl: 'template/customers/assets/edit-asset.html',
                        controller: CustomerEditAssetCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.assets.details.job_history', {
                url: Routing.generateAngularRoute('asset_job_history_view', true),
                templateUrl: function (params) {
                    return Routing.generate('asset_job_history_view', params)
                },
                views: {
                    "customerAssetTabPane@loggedin.customer_list.view.assets.details": {
                        templateUrl: 'template/customers/assets/asset_job_history_view.html',
                        controller: AssetJobHistoryViewCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.assets.details.associated_job_history', {
                url: Routing.generateAngularRoute('asset_associated_job_history_view', true),
                templateUrl: function (params) {
                    return Routing.generate('asset_associated_job_history_view', params)
                },
                views: {
                    "customerAssetTabPane@loggedin.customer_list.view.assets.details": {
                        templateUrl: 'template/customers/assets/asset_job_history_view.html',
                        controller: AssetJobHistoryViewCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            });
        }
    ]);