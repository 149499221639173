commusoftCommon.service('emailService', function() {
    var blank_email_data = {
        "recipient_addresses" : [],
        "ccs" : [],
        "from_addresses" : [
            "joe@commusoft.co.uk",
            "jason@commusoft.co.uk",
            "callum@commusoft.co.uk",
            "will@commusoft.co.uk"
        ],
        "selected_from_address" : null,
        "selected_recipient_address" : null,
        "subject" : "",
        "message" : "",
        "available_files" : [
            {
                id: 100,
                file_name: 'Invoice no.321531',
                file_size: 3
            },
            {
                id: 101,
                file_name: 'Invoice no.321532',
                file_size: 7
            },
            {
                id: 102,
                file_name: 'Invoice no.321533',
                file_size: 22
            },
            {
                id: 103,
                file_name: 'Invoice no.321534',
                file_size: 6.2
            },
            {
                id: 104,
                file_name: 'Invoice no.321535',
                file_size: 12.5
            },
            {
                id: 105,
                file_name: 'Invoice no.321536',
                file_size: 15.2
            },
            {
                id: 106,
                file_name: 'Invoice no.321537',
                file_size: 7.8
            },
            {
                id: 107,
                file_name: 'Invoice no.321538',
                file_size: 4.7
            }
        ]
    }
    this.generateBlankEmailData = function generateBlankEmailData() {
        return blank_email_data;
    };

    this.decorateRowsFromAttachedFiles = function decorateRowsFromAttachedFiles(rows) {
        var files = [];
        for(var i = 0, l = rows.length; i < l; i++) {
            var row_file_id = rows[i][1].id,
                row_file_name = rows[i][1].value,
                row_file_size_string = "" + rows[i][2].value,
                row_file_size = row_file_size_string.match(/[\.\d]+/g),
                row_file_size = parseFloat(row_file_size.join(""));

            files.push({
                id : row_file_id,
                file_name : row_file_name,
                file_size : row_file_size
            })
        }

        return files
    }
});