/*
  Child controller of addDiaryEvent to handle recurring events
*/

csmodule.controller('recurringEvent', ['$scope', '$rootScope', function($scope, $rootScope) {
  /*==========================================================================================
    Initial Variables
  ==========================================================================================*/
  $scope.start_date_as_int = parseInt($scope.start.format('DD'));
  $scope.start_date_as_weekday = moment($scope.start).day();
  $scope.add_diary_event_form_inputs.repeat_type = 'Daily';
  $scope.add_diary_event_form_inputs.repeats_every = 1;
  $scope.add_diary_event_form_inputs.repeats_on = [];
  $scope.add_diary_event_form_inputs.repeats_on.push($scope.start_date_as_weekday);
  $scope.weekly_repeats_on_text = null;
  $scope.show_last_day_of_month_help_text = false;

  $scope.setRepeatType = function() {
    if($scope.start_date_as_int > 28 && $scope.add_diary_event_form_inputs.repeat_type == 'Monthly') {
      $scope.show_last_day_of_month_help_text = true;
    } else {
      $scope.show_last_day_of_month_help_text = false;
    }
  }

  $scope.handleRepeatsOnDays = function handleRepeatsOnDays() {
    var add_day = $scope.add_diary_event_form_inputs.repeats_on.indexOf(this.day.value) < 0;

    if(add_day) {
      $scope.add_diary_event_form_inputs.repeats_on.push(this.day.value);
    }else {
      var index = $scope.add_diary_event_form_inputs.repeats_on.indexOf(this.day.value);
      $scope.add_diary_event_form_inputs.repeats_on.splice(index, 1);
    }
  }
}]);

