csmodule.controller('AddDynamicFieldOptionCtrl', [
  '$timeout',
  '$scope',
  'getFieldOptions',
  '$modalInstance',
  'dfConfirmationToast',
  '$http',
  'prefix',
  function($timeout, $scope, getFieldOptions, $modalInstance, dfConfirmationToast, $http, prefix) {
    $scope.elemId = 'addDynamicOptionModal';
    $scope.fieldName = getFieldOptions.parentScope.fieldData.description;
    $scope.fieldItems = prepareDependciesOrder(validateDatas(getFieldOptions.parentScope.fieldDependencies), getFieldOptions.parentScope.data);
    $scope.fieldData = getFieldOptions.parentScope.fieldData;
    $scope.inputs = {};
    $scope.inputs.parents = [];
    $scope.inputs.optionValue = '';
    $scope.isDisabledSave = false;
    $scope.id = getFieldOptions.id;
    $scope.isEdit = getFieldOptions.isEditMode ? true : false;
    $scope.error = null;
    //$scope.inputs.createAnother = $scope.isEdit ? false : true;
    $scope.inputs.createAnother = false;
    $scope.hasFieldDependencies = $scope.fieldItems.fieldDependencies.length > 0 && $scope.isEdit === false;

    function validateDatas(fieldDependencies) {
      return _.chain(fieldDependencies)
        .forEach(function(field){
          field.items = _.map(field.items, function(x){
            x.id = ""+x.id;
            return x;
          });
        })
        .value();
      //console.log('fie', fieldDependencies);
      //return fieldDependencies;
    }

    $scope.select2Options = {
      allowClear:false,
      placeholder: 'Select an option',
      editable: true,
      minimumInputLength:2,
      multiple: false,
      ajax: {
        url: prefix + '/'+ getFieldOptions.parentScope.listUrl +'?fieldId=' + $scope.fieldData.id,
        dataType: 'json',
        data: function (term, page) {
          return {
            searchText: term
          };
        },
        results: function (data, page) {
          var items = _.map(data.options, function(item){
            return {
              id: item.id,
              text: item.description
            }
          });
          return {
            results: items
          };
        },
      },
      createSearchChoice: function(term,data) {
        if(!data.length) {
          return {id:'@@@'+term, text:term};
        }
        else {
          var contents = _.pluck(data, 'text'),
          exists =  _.some(contents, function(text){
            return text.toLowerCase() === term.toLowerCase();
          });
          return exists ? {} : {id:'@@@'+term, text:term};
        }
      }
    };

    prepareInputModel();
    $scope.fieldsCount = $scope.inputs.parents.length;

    $modalInstance.opened.then(function(isOpened) {
      if (isOpened) {
        dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
        $timeout(function(){
          var $element = $('#'+$scope.elemId);
          $element.bind('keydown', function(e){
            if(e.which === 13) {
              //# If the save button enabled then submit logic will work
              if($scope.isDisabledSave == false) {
                  $scope.onSubmit();
              }
            }
            if(e.which === 27) {
              $scope.onClose();
            }
          });
        },300);
      }
    });

    $scope.onChangeOption = function(item, index) {
      var isFirst = index === 0, isLast = index === ($scope.fieldsCount - 1),
        nextIndex = index+1 ;
      for(var i = 0; $scope.fieldsCount > i; i++) {
        if(i === nextIndex && $scope.inputs.parents[nextIndex]) {
          $scope.inputs.parents[nextIndex].options = _.isEmpty(item.values) ? [] : $scope.findMyDependants(item.values);
          if(_.isEmpty(item.values)) {
            $scope.inputs.parents[nextIndex].values = [];
          }
        }
        // else if (nextIndex < i) {
        //   if($scope.inputs.parents[i]) {
        //     $scope.inputs.parents[i].options = [];
        //     $scope.inputs.parents[i].values = [];
        //   }
        // }
        else if(isLast) {
          break;
        }
      }
      $scope.validate();
    };

    $scope.validate = function() {
      var p = _.map(_.pluck($scope.inputs.parents,'values'), function(x){ return x.length});
      var isValid = !_.contains(p, 0) && !_.isEmpty($scope.inputs.optionValue);
      $scope.isDisabledSave = isValid === false;
      return isValid;
    };
    $scope.validate();

    $scope.isOptionValueExists = function() {
      if($scope.validate() ) {
        var exists = _.find($scope.fieldItems.options, function(x) {
          return x.description.toLowerCase() == $scope.inputs.optionValue.toLowerCase();
        });
        if(exists) {
          $scope.isDisabledSave = true;
          $scope.error = 'This item already exists.';
        }
        else {
          $scope.isDisabledSave = false;
          $scope.error = null;
        }
        return $scope.error === null ? true : false;
      }
      return false;
    };

    $scope.onSubmit = function() {
      var parentItems = [];
      _.forEach($scope.inputs.parents, function(x){
        parentItems.push(x.values);
      });
      var optionValue = $scope.inputs.optionValue;
      if(_.isObject(optionValue) && _.has(optionValue,'id') && optionValue.id.indexOf('@@@') === 0) {
        optionValue.id = 0;
      }
      getFieldOptions.parentScope.addItem(
        optionValue,
        $scope.fieldData.id,
        parentItems,
        $scope.id
      );
      $scope.inputs.optionValue='';
      $scope.validate();
      if($scope.id || !$scope.inputs.createAnother) {
        $scope.onClose('afterSave');
      }
    };

    $scope.onClose = function(info) {
      $modalInstance.dismiss(info);
    };

    $scope.$on('$destroy', function(){
      $(document).unbind('keydown');
      $('#'+$scope.elemId).unbind('keydown');
      $('body').find('.modal-backdrop').off('click');
    });

    function prepareInputModel() {
      if($scope.isEdit) {
        var selectedOption = _.find($scope.fieldItems.options, function(x){
          return x.id === $scope.id;
        });
        $scope.inputs.optionValue = selectedOption.description;
      }
      else {
        $scope.inputs.optionValue = '';
      }

      var fieldsDeps = angular.copy($scope.fieldItems.fieldDependencies);
      _.forEach(fieldsDeps, function(item, key){
        $scope.inputs.parents.push({
          fieldId: item.id,
          fieldName: item.name,
          fieldDependencies: item.dependencies,
          level: key,
          values: ($scope.isEdit && selectedOption.optionDependencies[key]) ? selectedOption.optionDependencies[key] : [],
          options: key===0 ? item.items : []
        });
      });
    }

    $scope.findMyDependants = function (ids) {
      if(_.isEmpty(ids)) {
        return [];
      }
      return _.filter($scope.fieldItems.listOptions, function(x){
        return _.intersection(ids, x.depsLast).length > 0;
      })
    };

    function prepareDependciesOrder(items, curOptions) {
      var _tmp=[], options=curOptions, dependantOptions=[];
      if(_.isEmpty(items)) {
        items = [];
      }
      if(! _.isArray(options) )  {
        options = [];
      }
      _tmp = _.chain(items)
        .forEach(function(item){
          item.order = !item.dependencies ? 0 : angular.fromJson(item.dependencies).length;
          item.dependencies = !item.dependencies ? [] : angular.fromJson(item.dependencies);
          dependantOptions.push(_.map(item.items, function(x){x.id = ""+x.id;return x;}));
        })
        .sortBy(function(item){
          return item.order;
        })
        .value();
      return {
        fieldDependencies: _tmp,
        listOptions: _.chain(dependantOptions)
          .flatten()
          .map(function(x){
            x.depsSource = x.dependencies ? angular.fromJson(x.dependencies) : null;
            x.depsLast = x.depsSource ? _.last(x.depsSource) : null;
            x.dependencies = !x.dependencies ? [] : angular.fromJson(x.dependencies);
            return x;
          })
          .value(),
        options: _.map(options, function(x){
          x.optionDependencies = ! x.optionDependencies ? [] : angular.fromJson(x.optionDependencies);
          x.fieldDependency = ! x.fieldDependency ? [] : angular.fromJson(x.fieldDependency);
          return x;
        })
      };
    }
}]);