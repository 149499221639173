commusoftCommon.directive('landlord', ['prefix', '$http', '$modal',function(prefix, $http, $modal) {
    return {
        transclude: true,
        restrict: 'E',
        scope: {
            shouldBeOpenLord: '=',
            panelTile: '@',
            landlordReload: '&'

        },
        controller: function($scope, $element, $attrs, $transclude) {
            $scope.$watch('shouldBeOpenLord', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal == true) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;

                    modalInstance = $modal.open({
                        templateUrl: Routing.generate('landlord_create'),
                        scope: $scope,
                        controller: CSLandlordCtrl
                    })
                }
            });
        }
    }
}]);