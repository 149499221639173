commusoftCommon.service('formService', function($http) {
    return {
        mUrl: '',
        partialPageSubmit: function(url, formElem) {
            return $http.post(url, formElem.serialize()).then(function(result) {
                return result.data;
            }).catch(function (err) {
                return err;
            });
        }
    }
});