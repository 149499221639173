commusoftCommon.directive('cslinkinvoicepaymentmodal', ['prefix', '$http', '$modal', function(prefix, $http, $modal) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            title: '@',
            deleteUrl: '@',
            deleteParams: '@',
            buttonClass: '@',
            buttonValue: '@',
            validationMessage: '@',
            selectedId: '=',
            shouldBeOpen: '=',
            deleteFunc: '&',
            propertyEmail: '=',
            propertySms:'='
        },
        controller: function($scope, $element, $attrs, $transclude) {
            $scope.$watch('shouldBeOpen', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;

                    modalInstance = $modal.open({
                        templateUrl: 'template/link_invoice_payment_modal.html',
                        scope: $scope,
                        controller: CSLinkInvoicePaymentModalCtrl
                    })
                }
            });
        }
    }
}]);