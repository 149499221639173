csmodule.directive('spreadsheet', ['spreadSheetData', 'spreadSheetHelper', 'spreadsheetMergeRowHelper', 'spreadsheetCheckboxHelper', 'supplierCashAllocationHelper', 'invoiceScheduleHelper', 'budgetAndCostHelper', 'spreadsheetDropzoneHelper', 'configurePricingColumnsHelper', 'confirmationBoxHelper', 'toastBox', 'dragHelper', '$q', '$rootScope', '$http', '$timeout', '$document', 'spreadSheetRefsHelper', 'spreadSheetShortcutRefs', 'warningModal', '$translate', function(spreadSheetData, spreadSheetHelper, spreadsheetMergeRowHelper, spreadsheetCheckboxHelper, supplierCashAllocationHelper, invoiceScheduleHelper, budgetAndCostHelper, spreadsheetDropzoneHelper, configurePricingColumnsHelper, confirmationBoxHelper, toastBox, dragHelper, $q, $rootScope, $http, $timeout, $document, spreadSheetRefsHelper, spreadSheetShortcutRefs, warningModal, $translate) {

  var handleSpreadSheet = function handleSpreadSheet(scope, element, attrs) {
    window.aa = scope; // Just for dev.

    /*==========================================================================================
      Initial Variables
    ==========================================================================================*/
    scope.element = element;
    scope.category = attrs.category;
    scope.spreadsheet_title = attrs.spreadsheetTitle;
    scope.spreadsheet_rows = [];
    scope.active_row_axis = null;
    scope.active_col_axis = null;

    if(scope.categoryId) {
      // This condition used to pass the selected invoice item categories to avoid the repeated values in dropdown for basic estimate screen.
      if(typeof attrs.tempInvoiceItemCategories != 'undefined') {
        scope.path_to_data = scope.getListUrl + '/' + scope.categoryId + '?itemCategories='+ encodeURIComponent(attrs.tempInvoiceItemCategories);
      } else {
        scope.path_to_data = scope.getListUrl + '/' + scope.categoryId;
      }
    }else {
      scope.path_to_data = scope.getListUrl;
    }
    if(attrs.klass) {
      scope.klass = attrs.klass;
    }
    scope.data_arrived = false;
    scope.server_side_errors_present = false;
    scope.cell_being_edited = false;
    scope.auto_complete_generates_row = false;

    /*==========================================================================================
      Set conditional options to default values
    ==========================================================================================*/
    scope.supports_fullscreen = false;
    scope.supports_download = false;
    scope.supports_print = false;
    scope.supports_actions_col = false;
    scope.supports_disable_action = false;
    scope.supports_delete = true;
    scope.supports_totals = false;
    scope.supports_grouped_grand_totals = false;
    scope.supports_sidepanel = false;
    scope.supports_auto_save = true;
    scope.supports_merge_rows = false;
    scope.can_add_new_rows = true;
    scope.insert_exisiting_data = true;
    scope.supports_batch = false;
    scope.supports_draggable_rows = false;
    scope.supports_statuses = false;
    scope.supports_percentage_inputs = false;
    scope.in_fullscreen_mode = false;
    scope.section_open = true;
    scope.supports_arrow = false;
    scope.help_text = false;
    scope.selectAll = false;
    scope.disabled = false;
    scope.selectAllMessage = 'Select All';
    scope.supports_delete_spreadsheet = false;
    // Specific to customer invoicing
    scope.initial_payment = false;
    scope.retention_payment = false;

    // Used to edit/delete the milestone in project estimate screen.
    scope.editDelete = false;
    scope.supportPriceTotal = false;

    $rootScope.spreadsheet_instance_id = 'all';

    //Hide the Vat
    scope.isHideVat = false;

    // Hide the CIS used in customer invoice screen.
    scope.isHideCIS = false;

    /*==========================================================================================
      Re set conditional options if supported in instance
    ==========================================================================================*/

    // Assigned the attribute value because to find the screen name and modified the condition in spread_sheet.html file
    if(attrs.editDelete) {
      scope.editDelete = attrs.editDelete;
    }

    if(attrs.isHideVat == 'true') {
      scope.isHideVat = true;
    }

    if(attrs.isHideCIS == 'true') {
      scope.isHideCIS = true;
    }

    if(attrs.supportPriceTotal) {
      scope.supportPriceTotal = true;
    }

    if(attrs.supportsFullscreen) {
      scope.supports_fullscreen = true;
    }
    if(attrs.supportsDownload == 'true') {
      scope.supports_download = true;
    }
    if(attrs.supportsPrint == 'true') {
      scope.supports_print = true;
    }
    if(attrs.supportsActionsColumn === 'true') {
      scope.supports_actions_col = true;
      if (attrs.supportsDelete === 'false') {
        scope.supports_delete = false;
      } else {
        scope.supports_delete = true;
      }
    }
    if(attrs.supportsDisableAction === 'true') {
      scope.supports_disable_action = true;
      if (attrs.disableActionLinkText !== undefined) {
        scope.disable_action_link_text = attrs.disableActionLinkText;
      }
    }
    if(attrs.supportsTotals) {
      scope.supports_totals = true;
      scope.column_total_indexs = attrs.columnsThatNeedTotals;
    }
    if(attrs.groupedGrandTotalsReference) {
      scope.supports_grouped_grand_totals = true;
      scope.grouped_grand_totals_reference = attrs.groupedGrandTotalsReference;
    }
    if(attrs.supportsSidepanel) {
      scope.supports_sidepanel = true;
      spreadsheetDropzoneHelper.attachDropzone(element[0]);
    }
    if(attrs.supportsAutoSave === "false") {
      scope.supports_auto_save = false;
    }
    if(attrs.supportsMergeRows && attrs.supportsMergeRows !== "false") {
      scope.supports_merge_rows = true;
      scope.row_to_unmerge = '';
      scope.merge_pricing_items_store = [];
      scope.scope_id = scope.$id;
      $rootScope.spreadsheet_instance_id = 'all';

      if(attrs.mergeConditionalColumns) {
        scope.merge_conditional_columns = attrs.mergeConditionalColumns;
      }
    }
    if(attrs.canAddNewRows === "false") {
      scope.can_add_new_rows = false;
    }
    if(attrs.supportsBatch === "true") {
      scope.supports_batch = true;
      scope.batch_process_rows_store = [];
    }
    if(attrs.supportsStatuses) {
      scope.supports_statuses = true;
    }
    if(attrs.insertExisitingData === "false") {
      scope.insert_exisiting_data = false;
    }
    if(attrs.supportsArrow) {
      scope.supports_arrow = true;
    }
    if(attrs.helpText) {
      scope.help_text = attrs.helpText;
    }
    if(attrs.estimateTotal) {
      scope.supports_percentage_inputs = true;
      scope.can_add_new_rows = false;
      scope.estimate_total = parseFloat(attrs.estimateTotal);
      scope.milestones_exist = attrs.milestones !== undefined && attrs.milestones.length > 0;

      if(scope.milestones_exist) {
        scope.milestones = angular.fromJson(attrs.milestones);
      }

      if(scope.category === 'invoice_schedule') {
        scope.invoice_schedule_spreadsheet = true;
      }
    }
    if (attrs.supportsDiscount === 'true') {
      if (attrs.discountPercentage !== undefined) {
        scope.discount_percentage = parseFloat(attrs.discountPercentage.substring(0, attrs.discountPercentage.length - 1));
      }
    }
    if (attrs.supportsCommission === 'true') {
      if (attrs.commissionPercentage !== undefined) {
        scope.commission_percentage = parseFloat(attrs.commissionPercentage.substring(0, attrs.commissionPercentage.length - 1));
      }
    }
    if (scope.category === 'job_labour' ||
        scope.category === 'job_invoice_full_breakdown' ||
        scope.category === 'job_invoice_category_breakdown' ||
        scope.category === 'job_parts') {

      scope.can_add_new_rows = true;

      /*==========================================================================================
        Observe whether to calculate discounts and commissions. When the model changes, re-run
        the calculations in the spreadsheet
      ==========================================================================================*/
      attrs.$observe('supportsDiscount', function(supportsDiscount) {
        if (attrs.supportsDiscount === 'true') {
          scope.discount_percentage = parseFloat(attrs.discountPercentage.substring(0, attrs.discountPercentage.length - 1));
        } else {
          scope.discount_percentage = 0;
        }

        scope.forceAllCellCalculations();
      });

      attrs.$observe('supportsCommission', function(supportsCommission) {
        if (attrs.supportsCommission === 'true') {
          scope.commission_percentage = parseFloat(attrs.commissionPercentage.substring(0, attrs.commissionPercentage.length - 1));
        } else {
          scope.commission_percentage = 0;
        }

        scope.forceAllCellCalculations();
      });
    }
    if(attrs.initialPayment === 'true') {
      scope.initial_payment = true;
    }
    if(attrs.retentionPayment === 'true') {
      scope.retention_payment = true;
    }
    if(attrs.disabled === 'true') {
      scope.disabled = true;
    }
    if(attrs.supportsDeleteSpreadsheet === 'true') {
      scope.supports_delete_spreadsheet = true;
    }

    /*==========================================================================================
      Observe directive attributes that change ~ this is used in job todos when editing a
      milestone name
    ==========================================================================================*/
    attrs.$observe('spreadsheetTitle', function(spreadsheetTitle) {
      scope.spreadsheet_title = spreadsheetTitle;
    });
    attrs.$observe('helpText', function(helpText) {
      scope.help_text = helpText;
    });
    attrs.$observe('supportsDeleteSpreadsheet', function(supportsDeleteSpreadsheet) {
      if(supportsDeleteSpreadsheet === 'true') {
        scope.supports_delete_spreadsheet = true;
      } else {
        scope.supports_delete_spreadsheet = false;
      }
    });

      /* while we create Purchase order to  select supplier and load respective branch logics*/
      scope.$on("spreadsheet:supplierData", function(evt, data) {
          if(scope.category == 'new_job_purchase_order_parts_prepopulated' || scope.category == 'new_purchase_order_items' || scope.category == 'new_job_purchase_order_parts' || scope.category == 'new_job_purchase_order_items_prepopulated'){
              scope.defaultNominalCode = data.defaultNominalCode.description;
              scope.defaultNominalCodeId = data.defaultNominalCode.id;

              if(scope.spreadsheet_title == 'Parts' ){
                  scope.spreadsheet_data[scope.category].auto_complete_options[data.optionName] = data.optionData;
                  scope.spreadsheet_data[scope.category].rows_to_insert['Collect from supplier'][5]['value'] = data.optionData[0].description;
                  scope.spreadsheet_data[scope.category].rows_to_insert['Collect from supplier'][5]['primaryid_value'] = data.optionData[0].id;
                  if(scope.spreadsheet_data[scope.category].row_templates){
                      angular.forEach(scope.spreadsheet_data[scope.category].row_templates,function(value,key){
                          value[0].row[0].associated_rows[0][4].value = scope.defaultNominalCode;
                          value[0].row[0].associated_rows[0][4].primaryid_value = scope.defaultNominalCodeId;
                          value[0].row[0].associated_rows[0][4].valid = true;
                      })
                  }
              }

              if(scope.defaultNominalCode) {
                  for(var i = 0, l = scope.spreadsheet_rows.length; i < l; i++) {
                      if(scope.spreadsheet_rows[i].row[0].associated_rows[0][4].value == '' || !scope.spreadsheet_rows[i].row[0].associated_rows[0][4].value) {
                          scope.spreadsheet_rows[i].row[0].associated_rows[0][4].value = scope.defaultNominalCode;
                          scope.spreadsheet_rows[i].row[0].associated_rows[0][4].primaryid_value = scope.defaultNominalCodeId;
                          scope.spreadsheet_rows[i].row[0].associated_rows[0][4].valid = true;
                      }
                  }

                  if(scope.spreadsheet_data[scope.category].empty_row && scope.spreadsheet_data[scope.category].empty_row.length) {
                      scope.spreadsheet_data[scope.category].empty_row[0].row[0].associated_rows[0][4].value = scope.defaultNominalCode;
                      scope.spreadsheet_data[scope.category].empty_row[0].row[0].associated_rows[0][4].primaryid_value = scope.defaultNominalCodeId;
                      scope.spreadsheet_data[scope.category].empty_row[0].row[0].associated_rows[0][4].valid = true;
                  }
              }

              spreadSheetHelper.directive_scope = scope;
              var row_is_valid = spreadSheetHelper.isRowValid(scope.spreadsheet_rows[0].row[0]);
              if(row_is_valid) {
                  var spread_sheet_data = {categoryId: '', category: scope.category, rows: angular.copy(scope.spreadsheet_rows)};
                  scope.$emit('event:save-spreadsheet-data', spread_sheet_data);
              }
          }
      });
      scope.$on('spreadsheet:todoupdate', function(evt, data) {
          scope.refreshData();
      });
      scope.$on('spreadsheet:todoupdatecategory', function(evt, data) {
          scope.insert_exisiting_data = true;
      });
      scope.fileObject = {};
      scope.imageTypes = {'jpeg': true, 'jpg': true, 'png': true, 'gif': true};

      scope.$on('event:spreadSheetFileUploaded', function(event, fileDetails) {
          scope.fileObject = fileDetails.fileObject;
          scope.imageFile = false;
          scope.loadFromObject = false;
          if (scope.imageTypes.hasOwnProperty(scope.fileObject.extension.toLowerCase())) {
              scope.imageFile = true;
          } else if (scope.fileObject.extension.toLowerCase() == 'txt' || scope.fileObject.extension.toLowerCase() == 'pdf' ) {
              scope.loadFromObject = true;
          }
      });

    /*==========================================================================================
      Get existing spreadsheet data
    ==========================================================================================*/
      scope.refreshData = function refreshData() {
          spreadSheetData.getData(scope.path_to_data).then(function (data) {
              scope.data_arrived = true;
              scope.spreadsheet_data = data;
              scope.warningMessage = scope.spreadsheet_data.warningMessage;
              if(scope.spreadsheet_data.warningMessage) {
                scope.supports_fullscreen = false;
              }

              scope.spreadsheet_headers = scope.spreadsheet_data[scope.category].column_headers;

              if (scope.category == "new_pricing_category" || scope.category == "Parts") {
                  scope.$emit('remove:added-invoice-item', data.invoiceItemCategory);
              }

              // This condition on for Full breakdown & Full breakdown by category tabs.
              if (data.customisableSpreadsheetColumns) {
                  scope.$parent.customisable_spreadsheet_columns = data.customisableSpreadsheetColumns;
                  configurePricingColumnsHelper.handleColumnsToShow(data.customisableSpreadsheetColumns, scope);
              }

              if (scope.insert_exisiting_data) {
                  scope.spreadsheet_rows = scope.orderRowsByPos(scope.spreadsheet_data[scope.category].existing_data);
                  if(scope.category != 'new_job_purchase_order_parts_prepopulated' &&
                      scope.category != 'new_job_purchase_order_items_prepopulated') {//Some of the fields in this cat has empty values
                      var data = {categoryId: scope.categoryId, category: scope.category, rows: angular.copy(scope.spreadsheet_rows)};
                      scope.$emit('event:save-spreadsheet-data', data);
                  }
              }
              if (scope.supports_sidepanel) {
                  scope.sidepanel_data = scope.spreadsheet_data[scope.category].sidepanel_data;
              }
              if (scope.invoice_schedule_spreadsheet) {
                  invoiceScheduleHelper.handleData(scope);
              }
              if (scope.can_add_new_rows && !scope.disabled) {
                  scope.appendEmptyRow();
              }
              if (scope.supports_totals) {
                  scope.generateSubTotals();
                  scope.handleGrandTotals();
              }
              if (scope.disabled) {
                  scope.handleDisabledAttr();
              }
              scope.handlePersistentData();
              scope.handleRowCount();
              scope.forceAllCellCalculations();
          });
      }
      scope.refreshData();
    scope.orderRowsByPos = function orderRowsByPos(rows) {
      var ordered_rows = _.indexBy(rows, 'pos');
      return _.values(ordered_rows);
    }

    scope.handlePersistentData = function handlePersistentData() {
      var localstorage_reference = 'spreadsheet:' + scope.category + ':persistent_data',
          persistent_data = JSON.parse(localStorage.getItem(localstorage_reference));

      if (persistent_data) {
        var index_to_push = scope.spreadsheet_rows.length - 1;
        for(var i = 0, l = persistent_data.length; i < l; i++) {
          var row_to_push = persistent_data[i];
          scope.spreadsheet_rows.splice(index_to_push, 0, row_to_push);
        }
        localStorage.removeItem(localstorage_reference);
        $rootScope.$broadcast('closeAllSidepanels'); // TODO - see if this is needed
      }
    }

    // 2016-01-19 -- JOSEPH edited this to only count the number of valid rows. This may be refactored when Callum has finished the logic for notifying the developer if the entire spreadsheet is valid
    scope.handleRowCount = function handleRowCount() {
      scope.number_of_valid_rows = 0;

      scope.spreadsheet_rows.map(function(row) {
        if (spreadSheetHelper.isRowValid(row.row)) {
          scope.number_of_valid_rows ++;
        }
      });

      if(scope.category === 'todos') {
        $rootScope.$broadcast('leftnav:todos_count_updated', scope.number_of_valid_rows);
      }

      $rootScope.$broadcast('spreadsheet:' + scope.category + ':row_count_updated', scope.number_of_valid_rows, scope.spreadsheet_title);
    }

    scope.generateSubTotals = function generateSubTotals() {
      scope.totals = [];
      var col_indexes = spreadSheetHelper.getColIndexesFromStr(scope.column_total_indexs);

      // generate labels for the totals
      for(var i = 0, l = col_indexes.length; i < l; i++) {
        if (scope.spreadsheet_headers) {
          var header = scope.spreadsheet_headers[col_indexes[i]].value,
              not_profit_header = header !== 'Profit Margin';
          // total profit margin is calculated on the fly in the view

          if(not_profit_header) {
            scope.totals.push({ name: header, sum: 0,currency:$rootScope.CustomCurrency });
          }
        }
      }

      // loop through each row and calc the sum
      for(var i = 0, l = scope.spreadsheet_rows.length; i < l; i++) {
        var row = scope.spreadsheet_rows[i],
            row_is_associated = row.row[0].associated_rows !== undefined;

        if(row_is_associated) {
          var associated_rows = row.row[0].associated_rows;
              merged_row = row.merged_row_ids;

          if(!merged_row) {
            for(var x = 0, y = associated_rows.length; x < y; x++) {
              scope.subTotalHelper(row, col_indexes, x, associated_rows);
            }
          }
        }else {
          // @params: 3rd and 4th are not needed as they are for associated rows
          scope.subTotalHelper(row, col_indexes, false, false);
        }
      }

      if(scope.supports_merge_rows) {
        scope.handleMergedRowSubTotals();
      }
    }

    scope.subTotalHelper = function subTotalHelper(row, col_indexes, associated_row_index, associated_rows) {
      var row_is_associated = associated_row_index !== false;

      for(var a = 0, b = col_indexes.length; a < b; a++) {
        var cell = null;
        if(row_is_associated) {
          cell = associated_rows[associated_row_index][col_indexes[a]];
        }else {
          cell = row.row[col_indexes[a]];
        }

        var value_is_number = _.isNumber(parseFloat(cell.value));

        if(value_is_number) {
          var number = (cell.value) ? cell.value : 0,
              sum_needs_rounding = scope.totals[a].sum % 1 !== 0;

          if(typeof number == 'string') {
            scope.totals[a].sum += parseFloat(number.replace(",",""));
          } else {
            scope.totals[a].sum += parseFloat(number);
          }


          if(sum_needs_rounding) {
            scope.totals[a].sum = parseFloat(scope.totals[a].sum.toFixed(2));
          }else {
            scope.totals[a].sum = parseFloat(scope.totals[a].sum);
          }
        }else {
          scope.handleNANValuesUsedInSubTotals(cell, a);
        }
      }
    }

    scope.handleMergedRowSubTotals = function handleMergedRowSubTotals() {
      var merged_rows = [];

      for(var i = 0, l = scope.spreadsheet_rows.length; i < l; i++) {
        var row = scope.spreadsheet_rows[i],
            merged_row = row.merged_row_ids;

        if(merged_row) {
          merged_rows.push(row);
        }
      }

      var merged_rows_to_process = merged_rows.length > 0;

      if(merged_rows_to_process) {
        scope.processMergedRowSubTotals(merged_rows);
      }
    }

    scope.processMergedRowSubTotals = function processMergedRowSubTotals(merged_rows) {
      var col_headers = _.pluck(scope.totals, 'name');

      for(var i = 0, l = merged_rows.length; i < l; i++) {
        var merged_row = merged_rows[i];

        for(var x = 0, s = col_headers.length; x < s; x++) {
          var total_obj = _.where(scope.totals, { name: col_headers[x] })[0];

          if(typeof merged_row[col_headers[x]] == 'string') {
            total_obj.sum += parseFloat(merged_row[col_headers[x]].replace(",",""));
          } else {
            total_obj.sum += parseFloat(merged_row[col_headers[x]]);
          }
        }
      }
    }

    /*==========================================================================================
      Handle cell values which are used in the sub total calculation but aren't numbers, i.e
      percentages used in invoice schedule (in estimates)
    ==========================================================================================*/
    scope.handleNANValuesUsedInSubTotals = function handleNANValuesUsedInSubTotals(cell, col_index) {
      var percentage_str = /%/,
          input_is_percentage = percentage_str.test(cell.value);

      if(input_is_percentage) {
        var percentage = parseFloat(cell.value.substring(0, cell.value.length - 1));

        if(percentage) {
          // specific to invoice schedule spreadsheet
          if(scope.invoice_schedule_spreadsheet) {
            var amount_to_add = (percentage / 100) * scope.estimate_total;
            scope.totals[col_index].sum += amount_to_add;
          }
        }
      } else {
        var amount_to_add = parseFloat(cell.value);
          amount_to_add = isNaN(amount_to_add) ? 0 : amount_to_add;

        scope.totals[col_index].sum += amount_to_add;
      }
    }

    scope.handleInvoiceScheduleAmounts = function handleInvoiceScheduleAmounts(row_index, percentage_str, input_is_percentage) {
      var allows_percentage = spreadSheetHelper.CELL.allows_percentage !== false;

      if(allows_percentage) {
        invoiceScheduleHelper.handleInvoiceScheduleAmounts(row_index, percentage_str, input_is_percentage);
      }
    }

    scope.$on('spreadsheet:reCalculateSubtotals', function() {
      scope.generateSubTotals();
    });

    /*==========================================================================================
      When directive loads store each sub total in localstorage
    ==========================================================================================*/
    scope.handleGrandTotals = function handleGrandTotals() {
      var localstorage_reference,
          grand_totals_exist;

      if(scope.supports_grouped_grand_totals) {
        localstorage_reference = scope.grouped_grand_totals_reference + ":" + "spreadsheetGrandTotals";
      }else {
        localstorage_reference = scope.category + ":" + "spreadsheetGrandTotals";
      }

      grand_totals_exist = localStorage.getItem(localstorage_reference) !== null;

      if(grand_totals_exist) {
        // grand totals array is retrieved from local storage
        var grand_total_str = localStorage.getItem(localstorage_reference),
            grand_totals = JSON.parse(grand_total_str);

        scope.grandTotalHelper(grand_totals, localstorage_reference);
      }else {
        // otherwise set up localstorage for the first time
        var grand_totals = [];
        scope.grandTotalHelper(grand_totals, localstorage_reference);
      }
    }

    /*==========================================================================================
      Helper that formats the grand total array
    ==========================================================================================*/
    scope.grandTotalHelper = function grandTotalHelper(grand_totals, localstorage_reference) {
      var milestone_total = {},
          tmp_obj = {};

      for(var i = 0, l = scope.totals.length; i < l; i++) {
        milestone_total[scope.totals[i].name] = scope.totals[i].sum;
      }

      tmp_obj[scope.spreadsheet_title] = milestone_total;
      grand_totals.push(tmp_obj);
      localStorage.setItem(localstorage_reference, JSON.stringify(grand_totals));
      $rootScope.$broadcast('update:' + localstorage_reference);
    }

    /*==========================================================================================
      When sub totals are updated (i.e when a cell value changes) update the values in local
      storage
    ==========================================================================================*/
    scope.updateGrandTotals = function updateGrandTotals() {
      var localstorage_reference;

      if(scope.supports_grouped_grand_totals) {
        localstorage_reference = scope.grouped_grand_totals_reference + ":" + "spreadsheetGrandTotals";
      }else {
        localstorage_reference = scope.category + ":" + "spreadsheetGrandTotals";
      }

      var grand_total_str = localStorage.getItem(localstorage_reference),
          grand_totals = JSON.parse(grand_total_str),
          obj_to_update = _.find(grand_totals, function (obj) {
                                  return scope.spreadsheet_title in obj
                                });

      if (obj_to_update) {
        for(var i = 0, l = scope.totals.length; i < l; i++) {
          obj_to_update[scope.spreadsheet_title][scope.totals[i].name] = scope.totals[i].sum;
        }
        localStorage.removeItem(localstorage_reference);
        localStorage.setItem(localstorage_reference, JSON.stringify(grand_totals));
        $rootScope.$broadcast('update:' + localstorage_reference);
      }
    }

    scope.removeGrandTotal = function removeGrandTotal() {
      var localstorage_reference;

      if (scope.supports_grouped_grand_totals) {
        localstorage_reference = scope.grouped_grand_totals_reference + ":" + "spreadsheetGrandTotals";
      } else {
        localstorage_reference = scope.category + ":" + "spreadsheetGrandTotals";
      }

      var grand_total_str = localStorage.getItem(localstorage_reference),
          grand_totals = JSON.parse(grand_total_str),
          updated_grand_totals = grand_totals.filter(function(obj) {
            return Object.keys(obj).indexOf(scope.spreadsheet_title) === -1
          })

      localStorage.removeItem(localstorage_reference);
      localStorage.setItem(localstorage_reference, JSON.stringify(updated_grand_totals));
      $rootScope.$broadcast('update:' + localstorage_reference);
    }

    /*==========================================================================================
      Add empty row to the spreadsheet
    ==========================================================================================*/
    scope.appendEmptyRow = function appendEmptyRow() {

      var row_to_clone = scope.spreadsheet_data[scope.category].empty_row,
          row_is_associated = row_to_clone[0].row[0].associated_rows !== undefined,
          new_row;
        if(scope.spreadsheet_data[scope.category].empty_row[0].row[0].associated_rows[0][4]){
            scope.defaultNominalCode = scope.spreadsheet_data[scope.category].empty_row[0].row[0].associated_rows[0][4].value;
            scope.defaultNominalCodeId = scope.spreadsheet_data[scope.category].empty_row[0].row[0].associated_rows[0][4].primaryid_value;
        }

      if(row_is_associated) {
        new_row = spreadSheetData.cloneAssociatedRow(row_to_clone);
      }else {
        new_row = spreadSheetData.cloneNormalRow(row_to_clone);
      }

      scope.spreadsheet_rows.push(new_row[0]);
    }

    /*==========================================================================================
      Click events
    ==========================================================================================*/
    scope.singleClick = function singleClick(e) {
      var cell_selectable = this.cell.state != "not_selectable";

      if(cell_selectable) {
        spreadSheetHelper.selectCell(this, scope, 'active_mode');
      }
    }

    scope.doubleClick = function doubleClick() {
      var cell_selectable = this.cell.state != "not_selectable";

      if(cell_selectable) {
        spreadSheetHelper.selectCell(this, scope, 'edit_mode');
      }
    }

    $rootScope.$on('spreadsheet_cell:in_active_mode', function() {
      scope.cell_being_edited = false;
    });

    $rootScope.$on('spreadsheet_cell:in_edit_mode', function() {
      scope.cell_being_edited = true;
    });

    /*==========================================================================================
      Clicking on the auto complete chevron should open / close it
    ==========================================================================================*/
    scope.toggleAutoComplete = function toggleAutoComplete() {
      var cell_context = this.$parent,
          open_dropdown = cell_context.cell.state === null ||
                          cell_context.cell.state === 'active_mode';

      // timeout needed to override the singleClick event
      $timeout(function() {
        if(open_dropdown) {
          spreadSheetHelper.selectCell(cell_context, scope, 'edit_mode');
        }else {
          spreadSheetHelper.selectCell(cell_context, scope, 'active_mode');
        }
      }, 300);
    }

    /*==========================================================================================
      Save
    ==========================================================================================*/
    scope.save = function save(row) {
      var create = row.id === null,
          deferred = $q.defer();

      if (create) {
        scope.create(row).then(function(saved_row) {
          scope.handleRowCount();
          deferred.resolve(saved_row);
        })
      } else {
        scope.update(row).then(function(updated_row) {
          scope.handleRowCount();
          deferred.resolve(updated_row);
        })
      }

      return deferred.promise;
    }

    /*==========================================================================================
      Create (save a new row)
    ==========================================================================================*/
      scope.urlEntry = 0;
      scope.create = function create(row) {
          var deferred = $q.defer(),
              newPath = "";

          row.pos = scope.active_row_axis;
          scope.row = row;

          /*This for getting category id in basic estimate in full break down by category screen new insert */
          if((this.category == "new_pricing_category") && (!scope.categoryId)){
              scope.categoryId = scope.path_to_data.split("/").pop();
          } else if(scope.path_to_data.indexOf('?') > -1) { /* full breakdown by category mutiple category select with out any insert in category */
              scope.categoryId = scope.path_to_data.substring(0, scope.path_to_data.lastIndexOf('?')).split('/').pop();
          }

          // Only in estimate project plan screen we get the categoryId as milestoneId.
          if(scope.categoryId) {
              newPath = scope.addUrl + "/" + scope.categoryId;
          }else {
              newPath = scope.addUrl;
          }
          // urlEntry introduced for to restrict the duplicate entry in create budget cost record
          if(scope.urlEntry == 0) {
              // In customer invoice screen while add line item to invoice, we didn't auto save, so that this condition is used.
              if(typeof scope.addUrl != 'undefined') {
                  scope.urlEntry = 1;
                  spreadSheetData.create(newPath, scope.category, row).then(function(response) {
                      scope.row.id = response.data.id;
                      scope.urlEntry = 0;
                      if(scope.category == "estimate_basic_no_breakdown") {
                          /* while insert the basic price(no breakdown) need to change in left nav tab */
                          scope.estimateValues = response.data.leftNavCount;
                          scope.$emit('estimateDetail:value', scope.estimateValues);
                      }

                      scope.server_side_errors_present = false;
                      toastBox.show('Row saved', 1500);

                      scope.handleRowCount();

                      deferred.resolve(scope.row);
                  }, function(response) {
                      scope.handleFailedServerValidations(response.data);
                      scope.urlEntry = 0;
                      deferred.reject();
                  });
              } else {
                  deferred.resolve(scope.row);
              }
              return deferred.promise;
          }
      }

    scope.saveMergedRow = function saveMergedRow(path, row, merged_row_ids) {
        scope.row = row;
        scope.merged_row_ids = merged_row_ids;

        spreadSheetData.createMergedRow(path, scope.category, row, merged_row_ids).then(function(response) {
            scope.row.id = response.data.id;
            scope.row.merged_row_ids = scope.merged_row_ids;
            scope.row.pos = scope.row.row[0].associated_rows[1].pos;
            scope.spreadsheet_rows.push(scope.row);
            scope.forceAllCellCalculations();
            scope.generateSubTotals();
            scope.spreadsheet_rows = scope.orderRowsByPos(scope.spreadsheet_rows);
            scope.server_side_errors_present = false;

            var flash = 'Merged row saved';
            scope.$emit('flash', flash);
        }, function(response) {
            scope.handleFailedServerValidations(response.data);
        });
    }

    /*==========================================================================================
      Update (edit existing row)
    ==========================================================================================*/
    scope.update = function update(row) {
      var deferred = $q.defer(),
          newPath = "";

      // Only in estimate project plan screen we get the categoryId as milestoneId.
      if(scope.categoryId) {
        newPath = scope.updateUrl + "/" + scope.categoryId;
      }else {
        newPath = scope.updateUrl;
      }

      spreadSheetData.update(newPath, scope.category, row).then(function(response) {
        scope.server_side_errors_present = false;

        /* while updating the cost or price need to change in left nav tab */
        scope.estimateValues = response.data.leftNavCount;
        scope.$emit('estimateDetail:value', scope.estimateValues);

        toastBox.show('Row updated', 1500);

        deferred.resolve(row);
      }, function(response) {
        scope.handleFailedServerValidations(response.data);

        deferred.reject();
      });

      return deferred.promise;
    }

    scope.unMergeLineItems = function unMergeLineItems() {
      spreadSheetData.updateMergedRow(scope.unmergeUrl, scope.category, scope.row_to_unmerge).then(function(response) {
        var un_merge_modal = scope.element[0].querySelector('#unmerge-line-item');

        spreadsheetMergeRowHelper.hideUnMergeModal(un_merge_modal);
        // remove top row of the merged row
        response.data.un_merged_rows.associated_rows.shift();

        _.each(response.data.un_merged_rows.associated_rows, function(row) {
          scope.spreadsheet_rows.push(row);
        });

        // remove merged row from spread sheet rows
        scope.spreadsheet_rows.splice(_.findIndex(scope.spreadsheet_rows, scope.row_to_unmerge), 1);
        scope.spreadsheet_rows = scope.orderRowsByPos(scope.spreadsheet_rows);
        scope.server_side_errors_present = false;
        toastBox.show('Rows un-merged', 1500);
        scope.handleRowCount();
      }, function(response) {
        scope.handleFailedServerValidations(response.data);
      });
    }

    // Close the unmerge popup
    scope.closeUnmerge = function closeUnmerge()
    {
      var un_merge_modal = scope.element[0].querySelector('#unmerge-line-item');
      spreadsheetMergeRowHelper.hideUnMergeModal(un_merge_modal);
    }

    /*==========================================================================================
      Disable
    ==========================================================================================*/
    scope.removeRow = function removeRow(row_index) {
      if (scope.category === 'job_labour' ||
          scope.category === 'job_invoice_full_breakdown' ||
          scope.category === 'job_invoice_category_breakdown' ||
          scope.category === 'job_parts' ||
          scope.category === 'job_discounts' ||
          scope.category === 'credit_note_parts' ||
          scope.category === 'credit_note_labour' ||
          scope.category === 'credit_note_category_breakdown' ||
          scope.category === 'credit_note_full_breakdown') {

        var delete_text = 'invoice';

        if (scope.category.includes('credit_note')) {
          delete_text = 'credit note';
        }

        scope.handleDelete(row_index, false);
        toastBox.show('Removed from ' + delete_text, 1500);
      }
    }

    /*==========================================================================================
      Delete
    ==========================================================================================*/
    scope.deleteRow = function deleteRow(show_confirmation) {
      scope.handleDelete(this.$index, show_confirmation);
    }

    scope.deleteRowAndAssociatedRows = function deleteRowAndAssociatedRows(show_confirmation) {
      scope.handleDelete(this.$parent.$parent.$parent.$parent.$parent.$index, show_confirmation);
    }

    /*==========================================================================================
      Delete a row, check that id isn't null because you can click the delete link on a row that
      hasn't yet been saved.
    ==========================================================================================*/
    scope.handleDelete = function handleDelete(row_index) {
        var id = scope.spreadsheet_rows[row_index].id,
            delete_can_happen = id !== null;

      if(delete_can_happen && !scope.supports_auto_save) {
        confirmationBoxHelper.getConfirmation("Are you sure you want to delete it?", scope)
            .then(function() {
              scope.$emit('event:delete_spreadsheet_row', id);
              scope.spreadsheet_rows.splice(row_index, 1);
              spreadSheetHelper.removeAllInstanceVariables();
              if(scope.supports_totals) {
                scope.generateSubTotals();
                scope.updateGrandTotals();
              }
              var categoryId = '';
              if(scope.categoryId) {
                categoryId = scope.categoryId;
              }
              var spread_sheet_data = {categoryId: categoryId, category: scope.category, rows: angular.copy(scope.spreadsheet_rows)};
              scope.$emit('event:save-spreadsheet-data', spread_sheet_data);
              /*
              if(scope.category == 'complete_purchase_order_items' || scope.category == 'complete_purchase_order_parts') {
                var spread_sheet_data = {category: scope.category, rows: angular.copy(scope.spreadsheet_rows)};
                scope.$emit('event:save-spreadsheet-data', spread_sheet_data);
              }*/
            });
      } else if(delete_can_happen && scope.supports_auto_save) {
            spreadSheetHelper.deleteInterceptor(row_index);

            // Only in estimate project plan screen we get the categoryId as milestoneId.
            var newPath = "";
            if(scope.categoryId) {
                newPath = scope.deleteUrl + "/" + scope.categoryId;
            } else {
                newPath = scope.deleteUrl;
            }

            spreadSheetData.destroy( newPath, scope.category, id).then(function(response) {
                scope.spreadsheet_rows.splice(row_index, 1);

                /*if(scope.category === 'jobtodos') {
                    scope.handleRowCount();
                }*/
                /* while deteling the cost need to change in left nav tab */
                // scope.estimateValues = response.data.leftNavCount;
                scope.handleRowCount();

                scope.$emit('estimateDetail:value', scope.estimateValues);

                var flash = 'Row deleted';
                scope.$emit('flash', flash);

                if(scope.supports_totals) {
                    scope.generateSubTotals();
                    scope.updateGrandTotals();
                }

            }, function(response) {
                // TODO: couldn't delete row, reflect why in UI
            });
        } else {
            // user wants to delete a row that hasn't yet been saved, so get confirmation from them
            confirmationBoxHelper.getConfirmation("This row has not been saved yet, are you sure you want to delete it?", scope)
                .then(function() {
                    scope.spreadsheet_rows.splice(row_index, 1);
                    spreadSheetHelper.removeAllInstanceVariables();
                    if(scope.supports_totals) {
                        scope.generateSubTotals();
                        scope.updateGrandTotals();
                    }
                    var categoryId = '';
                    if(typeof scope.categoryId != 'undefined') {
                        categoryId = scope.categoryId;
                    }
                    var spread_sheet_data = {categoryId: categoryId, category: scope.category, rows: angular.copy(scope.spreadsheet_rows)};
                  scope.$emit('event:save-spreadsheet-data', spread_sheet_data);
                });
        }
    }

    scope.handleRemovingDeletedRowFromRowsStore = function handleRemovingDeletedRowFromRowsStore(row_index) {
      var deferred = $q.defer();
      if ($rootScope.spreadsheet_rows_store) {
        for(var i = 0, l = $rootScope.spreadsheet_rows_store.length; i < l; i++) {
          if ($rootScope.spreadsheet_rows_store[i].pos === row_index) {
            var index_to_remove = i;
          }
        }
        $rootScope.spreadsheet_rows_store.splice(index_to_remove, 1);
        deferred.resolve();
      }
      return deferred.promise;
    }

    /*==========================================================================================
      When deleting a cost first need to check that it is not a todo
    ==========================================================================================*/
      scope.handleDeletingCost = function handleDeletingCost() {
          // scope.cost_row_index = this.$parent.$parent.$parent.$parent.$index;

          // budgetAndCostHelper.handleDeleteCost(scope);

          scope.cost_row_index = this.$parent.$parent.$parent.$parent.$parent.$parent.$index;
          var budgetCostId = scope.spreadsheet_rows[scope.cost_row_index].id;

          if (budgetCostId) {

              if (scope.in_fullscreen_mode) {
                  scope.show_delete_modal_overlay = true;
                  scope.moveGrandTotals();
                  $timeout(function(){
                      scope.animate_delete_modal_overlay = true;
                  }, 30);
              }
              if (scope.category === 'costs' || scope.category === 'milestone') {
                  $document.bind('keydown.spreadSheetModalShortcuts', function(evt) {
                      var handleEscKey = (function() {
                          // esc keydown
                          if (evt.keyCode == 27) {
                              // check if the overlay contains the darker class
                              scope.hideDeleteModalOverlay();
                          }
                      })();
                  });
              }


              scope.cost_row_index = this.$parent.$parent.$parent.$parent.$parent.$parent.$index;
              scope.delete_row_index = this.$parent.$parent.$parent.$parent.$parent.$parent.row.id;
              var delete_cost_modal = scope.element[0].querySelector('#delete-cost-modal-'+scope.delete_row_index);
              budgetAndCostHelper.handleDeleteCost(scope, delete_cost_modal);
              scope.loading = false;
              scope.closebutton = true;
              scope.deletebutton = true;
              scope.confirmDeleteValidity = true;
          } else {
              warningModal.show($translate('Delete.cost.warning.modal.help.text'), $translate('Delete.Cost'));
          }
      }

    /*==========================================================================================
      When deleting a cost first need to check that it is not a todo
    ==========================================================================================*/
    scope.handleDeletingMilestone = function handleDeletingMilestone() {
      if (scope.in_fullscreen_mode) {
        scope.show_delete_modal_overlay = true;
        scope.moveGrandTotals();
        $timeout(function(){
          scope.animate_delete_modal_overlay = true;
        }, 30);
      }
      if (scope.category === 'costs' || (typeof scope.categoryId != 'undefined')) {
        $document.bind('keydown.spreadSheetModalShortcuts', function(evt) {
          var handleEscKey = (function() {
            // esc keydown
            if (evt.keyCode == 27) {
              // check if the overlay contains the darker class
              scope.hideDeleteModalOverlay();
            }
          })();
        });
      }
    }


    scope.$on('hide_cost_overlay', function() {
      scope.hideDeleteModalOverlay();
    })

    scope.hideDeleteModalOverlay = function hideDeleteModalOverlay() {
      scope.animate_delete_modal_overlay = false;
      this.$parent.confirmText = '';
      this.confirmText = '';
      scope.confirmDeleteValidity = false;
      scope.loading = false;
      scope.closebutton = false;
      if(scope.delete_row_index) {
        $('#delete-cost-modal-'+scope.delete_row_index).modal('hide');
      } else {
        $('#delete-cost-modal').modal('hide');
      }

      $('#delete-milestone-modal-' + scope.categoryId).modal('hide');
      $('#delete-invoice-item-category-' + scope.categoryId).modal('hide');
      scope.moveGrandTotalsBack();
      $timeout(function(){
        scope.show_delete_modal_overlay = false;
      }, 200);
      $document.unbind('keydown.spreadSheetModalShortcuts');
    }

    // Move the grand totals out of the way for the modal
    scope.moveGrandTotals = function moveGrandTotals() {
      var grand_totals_wrapper = document.querySelector('ul.spreadsheet-totals-wrapper') ||
                                 document.querySelector('div.spreadsheet-totals-wrapper'),
          grand_totals_exist = grand_totals_wrapper !== null;

      if(grand_totals_exist) {
        grand_totals_wrapper.style.bottom = "-100px";
      }
    }

    // Move the grand totals back in view after the modal disappears
    scope.moveGrandTotalsBack = function moveGrandTotalsBack() {
      var grand_totals_wrapper = document.querySelector('ul.spreadsheet-totals-wrapper') ||
                                 document.querySelector('div.spreadsheet-totals-wrapper'),
          grand_totals_exist = grand_totals_wrapper !== null;

      if(grand_totals_exist) {
        grand_totals_wrapper.style.bottom = "65px";
      }
    }

    /*==========================================================================================
      Handle deleting row in costs spreadsheet (as only some rows are allowed to be deleted)
    ==========================================================================================*/
    scope.deleteCostRow = function deleteCostRow() {

      if (this.confirmText == undefined) {
        scope.confirmDeleteValidity = false;
      } else if ((this.confirmText.toLowerCase() == 'delete') ||
          ((this.$parent.confirmText != undefined) && (this.$parent.confirmText.toLowerCase() == 'delete'))) {
        scope.loading = true;
        scope.closebutton = false;
        scope.deletebutton = false;
        scope.confirmDeleteValidity = true;
        this.$parent.confirmText = '';
        this.confirmText = '';

        $rootScope.$emit('spreadsheet_panel:open');

        var id = scope.spreadsheet_rows[scope.cost_row_index].id,
            delete_can_happen = id !== null;

        if(delete_can_happen) {
          var newPath = "";

          // Only in estimate project plan screen we get the categoryId as milestoneId.
          if(scope.categoryId) {
            newPath = scope.deleteUrl + "/" + scope.categoryId;
          } else {
            newPath = scope.deleteUrl;
          }
          spreadSheetData.destroy(newPath, scope.category, id).then(function(response) {
            scope.spreadsheet_rows.splice(scope.cost_row_index, 1);
            budgetAndCostHelper.cancelDeleteUI();

            /* while deteling the cost need to change in left nav tab */
            scope.estimateValues = response.data.leftNavCount;
            scope.$emit('estimateDetail:value', scope.estimateValues);

            toastBox.show('Row deleted', 1500);
            //scope.handleRowCount();

            if(scope.supports_totals) {
              scope.generateSubTotals();
              scope.updateGrandTotals();
            }

            scope.hideDeleteModalOverlay();
          }, function(response) {
            // TODO: couldn't delete row, reflect why in UI
          });
        }else {
          var show_confirmation = $("body").hasClass("modal-open") === false;

          if(show_confirmation) {
            // user wants to delete a row that hasn't yet been saved, so get confirmation from them
            confirmationBoxHelper.getConfirmation("This row has not been saved yet, are you sure you want to delete it?", scope)
                .then(function() {
                  scope.spreadsheet_rows.splice(scope.cost_row_index, 1);
                  spreadSheetHelper.removeAllInstanceVariables();
                });
          }
        }
      } else {
        scope.confirmDeleteValidity = false;
      }

    }

    scope.close = function(){
      scope.confirmDeleteValidity = true;
      budgetAndCostHelper.cancelDeleteUI();
    }


    /*==========================================================================================
      Handle the errors sent back from server
    ==========================================================================================*/
    scope.handleFailedServerValidations = function handleFailedServerValidations(response) {
      scope.server_side_errors_present = true;
      spreadSheetHelper.removeAllInstanceVariables();

      for(var i = 0, l = response.errors.length; i < l; i++) {
        var invalid_cell = spreadSheetRefsHelper.getCellFromStrIndex(scope.spreadsheet_rows, response.errors[i].index_ref);
        invalid_cell.valid = false;
        invalid_cell.invalid_reason = response.errors[i].invalid_reason;
      }
    }

    /*==========================================================================================
      BACKEND: logic needed, should delete the milestone based on id rather than title
    ==========================================================================================*/
    scope.deleteSpreadsheet = function deleteSpreadsheet() {
      if (this.confirmText == undefined) {
        scope.confirmDeleteValidity = true;
      } else if (this.confirmText.toLowerCase() == 'delete') {


      if(typeof scope.$parent.$parent.$parent.deleteMilestoneUrl != 'undefined') {
        var path = scope.$parent.$parent.$parent.deleteMilestoneUrl,
            newlyAddedMilestone = true;

      } else {
        var path = scope.$parent.$parent.deleteMilestoneUrl,
            newlyAddedMilestone = false;
      }

        // If condition used in customer invoice screen and the Else part used to delete the milestone in estimate project screen.
        if(typeof path == 'undefined') {
          scope.$emit('delete_spreadsheet', scope.categoryId);
          scope.removeGrandTotal();
          scope.hideDeleteModalOverlay();

        } else {

          // Re-assign the parent scope value to current scope value.
          if(newlyAddedMilestone == false) {
            scope.categoryId = scope.$parent.categoryId;
            scope.category = scope.$parent.category;
            scope.spreadsheet_data = scope.$parent.spreadsheet_data;
            scope.element = scope.$parent.element;
          }

          // Call the backend call to delete the milestone.
          spreadSheetData.destroy(path, scope.category, scope.categoryId).then(function(response) {
          }, function(response) {  });

          this.confirmText = "";
          scope.confirmText = "";
          scope.loading = false;
          scope.closebutton = false;
          scope.deletebutton = false;
          scope.confirmDeleteValidity = false;

          scope.spreadsheet_data[scope.category] = null;
          var delete_milestone_modal = document.querySelector('#delete-milestone-modal-' + scope.categoryId);
          $(delete_milestone_modal).modal('hide');
          scope.element.remove();
        }
      } else {
        scope.confirmDeleteValidity = true;
      }
    }
    scope.milestoneClose = function milestoneClose()
    {
      scope.confirmDeleteValidity = false;
      scope.loading = false;
      scope.closebutton = false;
      scope.deletebutton = false;
      this.confirmText = "";

      var delete_milestone_modal = document.querySelector('#delete-milestone-modal-' + scope.categoryId);
      $(delete_milestone_modal).modal('hide');

    }

    /*==========================================================================================
      Handle editing a cell
    ==========================================================================================*/
    scope.getInputType = function getInputType(cell) {
      scope.input_type = cell.input_type;
      var input_is_auto_complete = scope.input_type === 'auto_complete';

      if(input_is_auto_complete) {
        var options = scope.spreadsheet_data[scope.category].auto_complete_options[cell.auto_complete_options_reference],
            many_records = _.isArray(options) === false;

        if(many_records) {
          $http.get(options).then(function(response) {
            scope.auto_complete_options = response.data;
          });
        }else {
          scope.auto_complete_options = options;
        }
      }else {
        scope.auto_complete_options = null;
      }
    }

    scope.generateInputId = function generateInputId(cell) {
      return cell.input_type + cell.index_ref.replace(/[^\w\s]/gi, '');
    }

    /*==========================================================================================
      When a autocomplete selected
    ==========================================================================================*/
    scope.autoCompleteSelected = function autoCompleteSelected(value, indexVal) {
      if(typeof this.suggestions[this.selectedIndex].hours != 'undefined') {
          var hour = this.suggestions[this.selectedIndex].hours;
          this.$parent.row.row[0].associated_rows[2][1].value = 1;
          this.$parent.row.row[0].associated_rows[2][1].valid = true;
          this.$parent.row.row[0].associated_rows[3][1].value = hour;
          this.$parent.row.row[0].associated_rows[3][1].valid = true;
      }

      // TODO: can use this.selectedIndex so that the value can be matched with an id
      // 12/03/2015 ~ this has been updated to use a method rather than checking for a cells value RE: CUI-1366
      scope.auto_complete_generates_row = scope.checkIfAutoCompleteGeneratesRow(value);
      spreadSheetHelper.updateAutocompleteCell(value, indexVal);

      if(scope.category === "todos" ||
         scope.category === "jobtodos" ||
         scope.category === "jobtodosnewmilestone" ||
         scope.category === "creditor_days" ||
         scope.category === "new_supplier_cash_allocation_invoices" ||
         scope.category === "new_customer_cash_allocation_invoices" ||
         scope.category === "new_supplier_credit_allocation" ||
         scope.category === "edit_supplier_invoice_payment" ||
         (typeof scope.categoryId != 'undefined')) {
        scope.handleGeneratedRowTemplate(value);
      }
      else if(scope.category === "new_purchase_order_parts" ||
          scope.category === "new_job_purchase_order_parts" ||
          scope.category === "edit_purchase_order_parts" ||
          scope.category === "new_supplier_invoice_parts" ||
          scope.category === "new_job_purchase_order_parts_prepopulated" ||
          scope.category === "edit_supplier_invoice_parts" ||
          scope.category === "complete_purchase_order_parts" ||
          scope.category === "new_supplier_credit_note_parts" || "" +
          scope.category === "edit_supplier_credit_note_parts") {
          scope.handlePurchaseOrderCompleteSelected(value);
          //this condition added due to update the parts respective data.
          if(scope.category === "new_job_purchase_order_parts" ||
              scope.category === "new_purchase_order_parts" || scope.category === "new_supplier_invoice_parts" || scope.category === "edit_purchase_order_parts" ||
              scope.category === "edit_supplier_invoice_parts" || scope.category === "new_supplier_credit_note_parts" || scope.category === "edit_supplier_credit_note_parts"){
              scope.handleGeneratedRowTemplate(value);
          }
      } else if(scope.category === "job_invoice_category_breakdown") {
        $rootScope.$broadcast('addLineItemCategory', value, indexVal);
      }

      spreadSheetHelper.handleAutoComplete();

      if(scope.supports_totals) {
        $timeout(function(){
          scope.updateGrandTotals();
        },100);
      }

      //spreadSheetHelper.updateAutocompleteCell(value);
    }

    scope.checkIfAutoCompleteGeneratesRow = function checkIfAutoCompleteGeneratesRow(value) {
      var templates = scope.spreadsheet_data[scope.category].row_templates;
      if (templates) {
        var keys = Object.keys(templates),
            auto_complete_generates_row = keys.indexOf(value) > -1;

        return auto_complete_generates_row;
      } else {
        return false;
      }
    }

    /*==========================================================================================
      When autocomplete that generates a row is selected.
    ==========================================================================================*/
    scope.handleGeneratedRowTemplate = function handleGeneratedRowTemplate(value) {
      if(scope.auto_complete_generates_row) {
        var row_to_insert = scope.spreadsheet_data[scope.category].row_templates[value],
            row_index = scope.active_row_axis,
            col_index = scope.active_col_axis,
            replacing_existing_row = scope.spreadsheet_rows[scope.active_row_axis].id !== null,
            existing_row_data = scope.spreadsheet_data[scope.category].existing_data[row_index],
            new_row = spreadSheetData.cloneAssociatedRow(row_to_insert, scope.spreadsheet_data, scope.category, value, existing_row_data),
            cell_index_to_focus;

        // delete the row that is being replaced
        if(replacing_existing_row) {
          var id = scope.spreadsheet_rows[scope.active_row_axis].id,
              newPath = "";

          if(scope.categoryId) {
              newPath = scope.deleteUrl + "/" + scope.categoryId;
          } else {
              newPath = scope.deleteUrl;
          }

          spreadSheetData.destroy(newPath, scope.category, id).then(function(response) {
          }, function(response) {
            // TODO: couldn't delete row, reflect why in UI
          });
        }

        // replace the current row with the new row
        scope.spreadsheet_rows[row_index] = new_row[0];
        /*if(scope.defaultNominalCode){
          scope.spreadsheet_rows[row_index].row[0].associated_rows[0][4].value = scope.defaultNominalCode;
          scope.spreadsheet_rows[row_index].row[0].associated_rows[0][4].primaryid_value = scope.defaultNominalCodeId;
        }*/

        spreadSheetShortcutRefs.initialize(scope.spreadsheet_rows);
        cell_index_to_focus = spreadSheetRefsHelper.getCellFromStrIndex(scope.spreadsheet_rows, "[" + row_index + "].row[0].associated_rows[0]" + "[" + col_index + "]").right_ref;

        if(scope.supports_totals) {
          scope.generateSubTotals();
        }

        spreadSheetHelper.enter_in_edit_goes_down = false;

        $timeout(function() {
          spreadSheetHelper.setNewActiveCell(cell_index_to_focus);
          spreadSheetHelper.enter_in_edit_goes_down = true;
        }, 100);
      }
    }

    /*==========================================================================================
      When autocomplete in the shipping column is selected in the purchase orders spreadsheet
    ==========================================================================================*/
    scope.handlePurchaseOrderCompleteSelected = function handlePurchaseOrderCompleteSelected(value) {
      var autocomplete_is_shipping_methods = spreadSheetHelper.CELL.auto_complete_options_reference === 'delivery_methods',
          need_to_insert_branch_row = value === 'Collect from supplier' && autocomplete_is_shipping_methods,
          need_to_insert_customer_branch_row = value === 'Delivery to Customer' && autocomplete_is_shipping_methods,
          need_to_insert_other_row = value === 'Other' && autocomplete_is_shipping_methods,
          branch_row_exisits = scope.doesBranchRowExist(),
          cell_index_to_focus;

      if(need_to_insert_branch_row || need_to_insert_customer_branch_row || need_to_insert_other_row) {
        if (branch_row_exisits) {
          scope.removePOPreviouslyInsertedRow();
        }
        var tmp_row_to_insert = scope.spreadsheet_data[scope.category].rows_to_insert[value],
            row_to_insert = spreadSheetData.cloneRowToInsert(tmp_row_to_insert);

        // Insert new row
        scope.spreadsheet_rows[scope.active_row_axis].row[0].associated_rows.push(row_to_insert);
        spreadSheetShortcutRefs.initialize(scope.spreadsheet_rows);
      }
      else if(autocomplete_is_shipping_methods && branch_row_exisits) {
        scope.removePOPreviouslyInsertedRow();
        spreadSheetShortcutRefs.initialize(scope.spreadsheet_rows);
      }
    }

    scope.doesBranchRowExist = function doesBranchRowExist() {
      var current_row = spreadSheetRefsHelper.getRowFromStrIndex(scope.spreadsheet_rows, scope.active_row_axis.toString()),
          branch_row_exisits = current_row[0].associated_rows.length > 2;

      return branch_row_exisits;
    }

    /*==========================================================================================
      When autocomplete in the shipping column should remove a previously inserted row
    ==========================================================================================*/
    scope.removePOPreviouslyInsertedRow = function removePOPreviouslyInsertedRow() {
      scope.spreadsheet_rows[scope.active_row_axis].row[0].associated_rows.splice(2, 1);
    }

    /*==========================================================================================
      Handle spreadsheet fullscreen mode
    ==========================================================================================*/
    scope.handleFullScreen = function handleFullScreen() {
      var elm = scope.element[0],
          in_fullscreen_mode = elm.classList.contains('spreadsheet-fullscreen');

      if(in_fullscreen_mode) {
        if(scope.supports_totals) {
          var spreadsheet_elm = document.querySelector('.spreadsheet-wrapper.spreadsheet-fullscreen');
          // Not sure if the following line needs to have padding-bottom: 0px
          // spreadsheet_elm.style.paddingBottom = '0px';
        }

        elm.classList.remove('spreadsheet-fullscreen');
        scope.in_fullscreen_mode = false;
        document.body.classList.remove('in-fullscreen-mode');

        var pos_fixed_spacer = document.querySelector('#pos_fixed_spacer');

        var data_section = elm.children[0];
        data_section.style.overflow = 'visible';

        $timeout(function() {
          elm.style.transition = "all .5s";
          elm.style.position = 'static';
          pos_fixed_spacer.parentNode.removeChild(pos_fixed_spacer);
          data_section.style.overflow = 'visible';
        }, 500);

        if(scope.supports_totals) {
          var grand_totals_wrapper = document.querySelector('ul.spreadsheet-totals-wrapper') ||
                                     document.querySelector('div.spreadsheet-totals-wrapper'),
              grand_totals_exist = grand_totals_wrapper !== null;

          if(grand_totals_exist) {
            grand_totals_wrapper.style.bottom = "-500px";
            grand_totals_wrapper.style.opacity = "1";
            grand_totals_wrapper.style.position = 'static';

            if(scope.supports_sidepanel) {
              grand_totals_wrapper.style.right = "61px";
            }else{
              grand_totals_wrapper.style.right = "12px";
            }
          }
        }

      }else {
        scope.sidepanel_in_view = false;
        scope.handleFullScreenTransition(elm);
        if(scope.supports_totals) {
          scope.handleFullScreenGrandTotalsTransition();
        }
      }
    }

    scope.handleSidepanel = function handleSidepanel() {
      scope.sidepanel_in_view = !scope.sidepanel_in_view;
      if(scope.supports_totals) {
        scope.moveFullScreenGrandTotals();
      }
    }

    scope.handleCollapse = function handleCollapse(e) {
      scope.section_open = !scope.section_open;
    }

    /*==========================================================================================
      Add a CSS transition when spreadsheet goes into fullscreen mode
    ==========================================================================================*/
    scope.handleFullScreenTransition = function handleFullScreenTransition(elm) {
      var offsets = elm.getBoundingClientRect(),
          top_offset = offsets.top,
          left_offset = offsets.left,
          right_offset = window.innerWidth - offsets.right;

      /*==========================================================================================
        Before making the spreadsheet position fixed need to insert a dummy element that has the
        same height and width of the spreadsheet so that the element below stays in its place
      ==========================================================================================*/

      elm.insertAdjacentHTML('beforebegin', '<div id="pos_fixed_spacer"></div>');
      var pos_fixed_spacer = document.querySelector('#pos_fixed_spacer');
      pos_fixed_spacer.style.width = offsets.width + "px";
      pos_fixed_spacer.style.height = offsets.height + "px";

      /*==========================================================================================
        Make the element fixed and position it so that it stays in its current place and give
        it a css tranistion. Now when the element gets the 'spreadsheet-fullscreen' class the
        transition will apply
      ==========================================================================================*/

      var data_section = elm.children[0];
      data_section.style.overflow = 'visible';
      elm.style.position = 'fixed';
      elm.style.zIndex = 999;
      elm.style.transition = "all .6s";
      elm.style.top = top_offset + "px";
      elm.style.left = left_offset + "px";
      elm.style.right = right_offset + "px";

      $timeout(function() {
        elm.classList.add('spreadsheet-fullscreen');
        scope.in_fullscreen_mode = true;
        document.body.classList.add('in-fullscreen-mode');

        if(scope.supports_totals) {
          var grand_totals_wrapper = document.querySelector('ul.spreadsheet-totals-wrapper') ||
                                     document.querySelector('div.spreadsheet-totals-wrapper'),
              grand_totals_exist = grand_totals_wrapper !== null;

          if(grand_totals_exist) {
            var scroll_wrapper = elm.querySelector('.scrollable-wrapper'),
                number_of_totals = scope.column_total_indexs.split('-').length;

            scroll_wrapper.style.bottom = (number_of_totals * 40) + 75 + 'px';
          }
        }
      }, 10);

      $timeout(function() {
        data_section.style.overflow = 'auto';
      }, 600);
    }

    /*==========================================================================================
      If the spreadsheet supports grand totals, animate the grand totals into view
    ==========================================================================================*/
    scope.handleFullScreenGrandTotalsTransition = function handleFullScreenGrandTotalsTransition() {
      var grand_totals_wrapper = document.querySelector('ul.spreadsheet-totals-wrapper') ||
                                 document.querySelector('div.spreadsheet-totals-wrapper'),
          grand_totals_exist = grand_totals_wrapper !== null;

      if(grand_totals_exist) {
        grand_totals_wrapper.style.position = 'fixed';
        grand_totals_wrapper.style.zIndex = 999;
        grand_totals_wrapper.style.transition = "all .5s ease-in-out";
        grand_totals_wrapper.style.bottom = "-500px";
        grand_totals_wrapper.style.opacity = "0";

        if(scope.supports_sidepanel) {
          grand_totals_wrapper.style.right = "61px";
        }else{
          grand_totals_wrapper.style.right = "12px";
        }

        $timeout(function() {
          grand_totals_wrapper.style.bottom = "65px";
          grand_totals_wrapper.style.opacity = "1";
        },300);
      }
    }

    /*==========================================================================================
      When the spreadsheet sidepanel is open and the spreadsheet supports grand totals
      move the grand totals over
    ==========================================================================================*/
    scope.moveFullScreenGrandTotals = function moveFullScreenGrandTotals() {
      var grand_totals_wrapper_div = document.querySelector('div.spreadsheet-totals-wrapper'),
          grand_totals_wrapper_ul = document.querySelector('ul.spreadsheet-totals-wrapper');

      if(scope.sidepanel_in_view) {
        if (grand_totals_wrapper_div) grand_totals_wrapper_div.style.right = "607px";
        if (grand_totals_wrapper_ul) grand_totals_wrapper_ul.style.right = "607px";
      }
      else {
        if(scope.supports_sidepanel) {
          if (grand_totals_wrapper_div) grand_totals_wrapper_div.style.right = "61px";
          if (grand_totals_wrapper_ul) grand_totals_wrapper_ul.style.right = "61px";
        }else{
          if (grand_totals_wrapper_div) grand_totals_wrapper_div.style.right = "12px";
          if (grand_totals_wrapper_ul) grand_totals_wrapper_ul.style.right = "12px";
        }
      }
    }

    /*==========================================================================================
      When the merge sidepanel opens (and when the spreadsheet is in fullsceeen)
      hide the grand totals elm
    ==========================================================================================*/

    scope.hideGrandTotals = function hideGrandTotals() {
      var elm = scope.element[0],
          in_fullscreen_mode = elm.classList.contains('spreadsheet-fullscreen');

      if(in_fullscreen_mode) {
        var grand_totals_wrapper = document.querySelector('ul.spreadsheet-totals-wrapper') ||
                                   document.querySelector('div.spreadsheet-totals-wrapper'),
            grand_totals_exist = grand_totals_wrapper !== null;

        if(grand_totals_exist) {
          scope.previous_grand_totals_style = grand_totals_wrapper.style.display;
          grand_totals_wrapper.style.display = "none";
        }
      }
    }

    scope.showGrandTotals = function showGrandTotals() { // Used when merging line items in fullscreen
      var elm = scope.element[0],
          in_fullscreen_mode = elm.classList.contains('spreadsheet-fullscreen');

      if(in_fullscreen_mode) {
        var grand_totals_wrapper = document.querySelector('ul.spreadsheet-totals-wrapper') ||
                                   document.querySelector('div.spreadsheet-totals-wrapper'),
            grand_totals_exist = grand_totals_wrapper !== null;

        if(grand_totals_exist) {
          grand_totals_wrapper.style.display = scope.previous_grand_totals_style;
        }
      }

    }

    $rootScope.$on('sidepanel:closed', function() {
      scope.showGrandTotals();
    })

    /*==========================================================================================
      Merging line items in full breakdown view in pricing (estimates)
    ==========================================================================================*/

    scope.handleMergeRows = function handleMergeRows() {
      spreadSheetHelper.removeAllInstanceVariables();
      spreadsheetMergeRowHelper.handleMerge(this, scope);
    }

    scope.$watchCollection('merge_pricing_items_store', function() {
      var items_to_merge_defined = scope.merge_pricing_items_store !== undefined;

      if(items_to_merge_defined) {
        var no_items_to_merge = scope.merge_pricing_items_store.length === 0;

        if(no_items_to_merge) {
          $rootScope.spreadsheet_instance_id = 'all';

          for(var i = 0, l = scope.spreadsheet_rows.length; i < l; i++) {
            var row = scope.spreadsheet_rows[i];

            row.merge_allowed = true;
          }
        }
      }
    });

    scope.mergePricingLineItem = function mergePricingLineItem() {
      $timeout(function() {
        document.querySelector('#side-panel.in-view #input-line-item-name').focus();
      });
    }

    scope.handleUnMerge = function handleUnMerge() {
      var un_merge_modal = scope.element[0].querySelector('#unmerge-line-item');
      scope.row_to_unmerge = this.$parent.$parent.$parent.$parent.$parent.row;
      $(un_merge_modal).modal('show');
    }

    /*==========================================================================================
      Handle checking spreadsheet rows
    ==========================================================================================*/
    scope.handleSelectingRow = function handleSelectingRow(row_index) {
      if (this.row.meta_data !== undefined) {
        this.row.meta_data.row_selected = !this.row.meta_data.row_selected;
          this.row.row_index = this.row.pos;

        spreadsheetCheckboxHelper.handleRowSelection(this, scope, this.row.meta_data.row_selected);
        scope.updateSelectAllUi();
      }
    }

    scope.markInvoicesAsPaid = function markInvoicesAsPaid() {
      supplierCashAllocationHelper.markAllInvoicesAsPaid('', scope, $rootScope.spreadsheet_rows_store)
        .then(function(){
          scope.uncheckAllRows();
          scope.generateSubTotals();
          scope.updateGrandTotals();
            var categoryId = '';
            if(typeof scope.categoryId != 'undefined') {
              categoryId = scope.categoryId;
            }
            var spread_sheet_data = {categoryId: categoryId, category: scope.category, rows: angular.copy(scope.spreadsheet_rows)};
            scope.$emit('event:save-spreadsheet-data', spread_sheet_data);
        })
    }

    /*==========================================================================================
      Handle changes to a milestones name
    ==========================================================================================*/
    scope.editMilestone = function editMilestone() {
      scope.previous_milestone_name = scope.spreadsheet_title;
      scope.element[0].querySelector("#edit-milestone-name").focus();
    }

    scope.deleteMilestone = function deleteMilestone() {
      $rootScope.$emit('delete_milestone', scope);
    }

    scope.saveMilestoneName = function saveMilestoneName(path, milestoneId, milestone_name) {
      var deferred = $q.defer();

      spreadSheetData.updateMilestoneName(scope, milestone_name, path, milestoneId).then(function(res) {
        scope.spreadsheet_title = res.data.milestoneName;
        deferred.resolve();
      }, function() {
        deferred.reject();
      });

      return deferred.promise;
    }

    /*==========================================================================================
      Handle the checking and unchecking of all spreadsheet rows
    ==========================================================================================*/
    $rootScope.$on('spreadsheet:uncheckAllRows', function(e) {
      scope.uncheckAllRows();
    });

    scope.handleSelectingAllCheckboxes = function handleSelectingAllCheckboxes(){
      if (scope.selectAll === false) {
        scope.checkAllRows();
      } else {
        scope.uncheckAllRows();
      }
    }

    scope.checkAllRows = function checkAllRows() {
      scope.selectAll = true;
      scope.selectAllMessage = 'Deselect All';
      $rootScope.spreadsheet_rows_store = [];
      $rootScope.spreadsheet_instance_id = scope.scope_id;
      for(var i = 0, l = scope.spreadsheet_rows.length; i < l; i++) {
        var row = scope.spreadsheet_rows[i];
        if (row.row_valid !== false && row.row_valid !== undefined) {
          row.meta_data.row_selected = true;
            scope.spreadsheet_rows[i].row_index = i;
          $rootScope.spreadsheet_rows_store.push(scope.spreadsheet_rows[i]);
        }
      }

      scope.spreadsheetRowsStoreUpdated();
    }

    scope.uncheckAllRows = function uncheckAllRows() {
      scope.selectAll = false;
      scope.selectAllMessage = 'Select All';
      $rootScope.spreadsheet_instance_id = 'all';
      $rootScope.spreadsheet_rows_store = [];
      for(var i = 0, l = scope.spreadsheet_rows.length; i < l; i++) {
        var row = scope.spreadsheet_rows[i];
        if (row.meta_data !== undefined) {
          row.meta_data.row_selected = false;
        }
      }

      scope.spreadsheetRowsStoreUpdated();
    }

    scope.updateSelectAllUi = function updateSelectAllUi(){
      var valid_rows = [];
      for(var i = 0, l = scope.spreadsheet_rows.length; i < l; i++) {
        var row = scope.spreadsheet_rows[i];
        if (row.row_valid !== false) {
          valid_rows.push(row);
        }
      }
      if (valid_rows.length === $rootScope.spreadsheet_rows_store.length) {
        scope.selectAll = true;
        scope.selectAllMessage = 'Deselect All';
        $rootScope.spreadsheet_instance_id = scope.scope_id;
      } else {
        scope.selectAll = false;
        scope.selectAllMessage = 'Select All';
      }
      if ($rootScope.spreadsheet_rows_store.length === 0) {
        $rootScope.spreadsheet_instance_id = 'all';
        scope.selectAll = false;
        scope.selectAllMessage = 'Select All';
      }

      scope.spreadsheetRowsStoreUpdated();
    }

    /*==========================================================================================
      Customisable columns in pricing spreadsheets (only in the full breakdown and full
      breakdown by category view)
    ==========================================================================================*/
    $rootScope.$on('spreadsheet:updatePricingColumns', function(e, column_settings) {
      configurePricingColumnsHelper.handleColumnsToShow(column_settings, scope);
    });

    scope.spreadsheetRowsStoreUpdated = function spreadsheetRowsStoreUpdated() {
      dragHelper.setContextData($rootScope.spreadsheet_rows_store);
    }

    /*==========================================================================================
      Utility helpers
    ==========================================================================================*/
    $rootScope.$on('spreadsheet:remove_all_checked_rows', function(event) {
      scope.deleteAllCheckedRows();
    });

    scope.deleteAllCheckedRows = function deleteAllCheckedRows(){
      for (var i = scope.spreadsheet_rows.length -1, l = 0; i >= l; i--) {
        var row = scope.spreadsheet_rows[i];
        if (row.meta_data.row_selected === true) {
          var index_to_remove = i;
          scope.handleDelete(index_to_remove, false);
        }
      }
      $rootScope.spreadsheet_rows_store = [];
    }

    /*==========================================================================================
      Handle drag and drop validation
    ==========================================================================================*/
    scope.handleSettingDragInstanceId = function handleSettingDragInstanceId() {
      $rootScope.spreadsheet_drag_instance = scope.scope_id
    }

    scope.dropValidate = function dropValidate(target_scope_id) {
      return $rootScope.spreadsheet_drag_instance !== scope.scope_id;
    }

    /*==========================================================================================
      Handle calculations on the entire spreadsheet
      ~ used in customer invoicing when adding rows to the spreadsheet
    ==========================================================================================*/
    scope.forceAllCellCalculations = function forceAllCellCalculations() {
      // Store merged rows for calculations after the rest of the rows are calculated
      var merged_rows = [];

      for(var i = 0, l = scope.spreadsheet_rows.length; i < l; i++) {
        var row = scope.spreadsheet_rows[i];

        // Category condition used to calculate the add line item for the empty spreadsheet.
        if ((row.id !== null) || (scope.category == 'job_invoice_category_breakdown') ) {
          if ((row.row_valid === true) || (scope.category == 'job_invoice_category_breakdown')) {
            var row_is_associated = row.row[0].associated_rows !== undefined,
                row_is_top_merged_row = row.merged_row_ids !== undefined;

            if (row_is_associated) {
              for(var x = 0, c = row.row[0].associated_rows.length; x < c; x++) {
                var associated_row = row.row[0].associated_rows[x];

                if (associated_row.row) {
                  // The row is a child merged row
                  var row_to_process = associated_row.row;
                } else {
                  var row_to_process = associated_row;
                }

                for(var v = 0, b = row_to_process.length; v < b; v++) {
                  var cell = row_to_process[v];

                  if (cell.calculation !== undefined) {
                    spreadSheetHelper.handleCellCalculation(row_to_process, cell, scope);
                  }
                }
              }
            } else {
              var cells = row.row;

              for(var x = 0, c = cells.length; x < c; x++) {
                var cell = cells[x];

                if (cell.calculation !== undefined) {
                  spreadSheetHelper.handleCellCalculation(cells, cell, scope);
                }
              }
            }

            if (row_is_top_merged_row) {
              merged_rows.push(row);
            }
          }
        }
      }

      for(var i = 0, l = merged_rows.length; i < l; i++) {
        var merged_row_to_process = merged_rows[i];

        scope.calculateTopMergedRowValues(merged_row_to_process);
      }
      scope.generateSubTotals();
      scope.updateGrandTotals();
    }

    /*==========================================================================================
      Run calculations on the top merged row (adds up the values in the columns below)
    ==========================================================================================*/
    scope.calculateTopMergedRowValues = function calculateTopMergedRowValues(merged_row_to_process) {
      var all_rows_including_children = merged_row_to_process.row[0].associated_rows,
          top_row_cells = all_rows_including_children[0];

      // loop through the columns
      for(var i = top_row_cells.length - 1; i >= 0; i--) {
        var top_row_cell = top_row_cells[i],
            col_index = i,
            col_name = scope.spreadsheet_headers[col_index].value;

        // check if the current cell in the top merged row's value
        // is the sum of the cells in the column of the child rows below
        if (top_row_cell.value_is_sum_of_child_rows === true) {
          var total = 0;

          // loop through the child rows and sum the cell values
          // x is 1 to start at the first child row (not the top merged row)
          for(var x = 1, c = all_rows_including_children.length; x < c; x++) {
            var child_row = all_rows_including_children[x].row[0].associated_rows[0],
                child_row_cell_to_total = parseFloat(child_row[col_index].value);

            total += child_row_cell_to_total;
          }
          top_row_cell.value = total;

          merged_row_to_process[col_name] = total;
        } else {
          if (col_index !== 0) {
            top_row_cell.value = all_rows_including_children[1].row[0].associated_rows[0][col_index].value;
          }
        }

        if (col_name === 'Quantity') {
          top_row_cell.value = 1;
        }
        if (col_name === 'Unit Price') {
          var total_cell_index = _.indexOf(_.pluck(scope.spreadsheet_headers, 'value'), 'Total'),
              total_value = top_row_cells[total_cell_index].value;

          top_row_cells[i].value = total_value;
        }
      }
    }

    /*==========================================================================================
      Listen for when a spreadsheet row should be inserted
      ~ used in customer invoicing adding line items from the job to the invoice

      ~ create a queue of items to process in the case of multiple broadcasts to the
        spreadsheet.
      ~ process the queue one broadcast at a time to ensure that the rows get inserted into the
        correct spreadsheet and that rows are not duplicated
    ==========================================================================================*/
    scope.ready_to_process_queue = true;
    scope.queued_batch_of_rows = [];

    scope.$on('spreadsheet:insert_rows', function(event, data) {
      if ((scope.category === data.category) || (scope.categoryId === data.categoryId)) {
        scope.queued_batch_of_rows.push(data);

        if (scope.ready_to_process_queue === true) {
          scope.ready_to_process_queue = false;

          scope.processBatchedRowsQueue().then(function() {
            // The entire queue has finished ~ tell the spreadsheet it's
            // ready to process again
            scope.ready_to_process_queue = true;
            //$rootScope.$broadcast('spreadsheet:insert_rows_completed');
          })
        }
      }
    });

    /*==========================================================================================
      Begin processing the queue ~ this handles use case of adding into queue whilst this
      function is already running
    ==========================================================================================*/
    scope.processBatchedRowsQueue = function processBatchedRowsQueue() {
      var deferred = $q.defer();
      (function iterate(i) {
        if (i > 0) {
          var queue_index_to_process = scope.queued_batch_of_rows.length - 1,
              rows = scope.queued_batch_of_rows[queue_index_to_process].rows;

          return scope.handleInsertingRows(rows).then(function(){
            scope.queued_batch_of_rows.splice(queue_index_to_process, 1);

            return iterate(scope.queued_batch_of_rows.length);
          });
        }
      })(scope.queued_batch_of_rows.length).then(function () {
        deferred.resolve();
      });

      return deferred.promise;
    }

    /*==========================================================================================
      Handle inserting a group of rows into the spreadsheet
    ==========================================================================================*/
    scope.handleInsertingRows = function handleInsertingRows(rows) {
      var deferred = $q.defer();
      spreadSheetHelper.setDirectiveScope(scope);

      (function iterate(i, x) {
        if (i < x) {
          var row = rows[i];

          return scope.handleInsertingRow(row).then(function(){
            i++;

            return iterate(i, x);
          });
        }
      })(0, rows.length).then(function () {
        // The rows have finished inserting, run calculations and cell shortcuts

        // scope.spreadsheet_rows = scope.orderRowsByPos(scope.spreadsheet_rows); TODO ~ this prevents some rows inserting
        scope.handleRowCount();
        spreadSheetShortcutRefs.initialize(scope.spreadsheet_rows);
        scope.forceAllCellCalculations();

        deferred.resolve();
      });

      return deferred.promise;
    }

    /*==========================================================================================
      Handle inserting a single row into the spreadsheet
    ==========================================================================================*/
    scope.handleInsertingRow = function handleInsertingRow(row) {
      var deferred = $q.defer(),
          in_invoicing = scope.category === 'job_invoice_full_breakdown' ||
                         scope.category === 'job_labour' ||
                         scope.category === 'job_parts',
          invoicing_estimate_with_initial_payment = in_invoicing &&
                                                    scope.initial_payment === true,
          invoicing_estimate_with_retention_payment = in_invoicing &&
                                                    scope.retention_payment === true;

      spreadSheetHelper.attemptSave(row).then(function(saved_row) {
        var row_to_push = angular.copy(saved_row),
            modifer = 0; // Copy to strip out hashkey from the row

        // This is where we decide where to push it ---- check if this is needed!
        if (invoicing_estimate_with_retention_payment && invoicing_estimate_with_initial_payment) {
          modifer = 3;
        } else if (invoicing_estimate_with_retention_payment || invoicing_estimate_with_initial_payment) {
          modifer = 2;
        } else {
          if (scope.can_add_new_rows) {
            modifer = 1;
          }
        }

        row_to_push.pos = scope.spreadsheet_rows.length - modifer;
        scope.spreadsheet_rows.splice(row_to_push.pos, 0, row_to_push);

        spreadSheetHelper.setDirectiveScope(scope);
        deferred.resolve();
      });

      return deferred.promise;
    }

    /*==========================================================================================
     Update a cells value at a particular reference
     ==========================================================================================*/
    scope.$on('spreadsheet:add_cell_value_at_ref', function(e, options) {
      var coordinates = options.coordinates,
          new_cell_value = options.value;

      scope.addCellValueAtReference(coordinates, new_cell_value);
    });

    scope.addCellValueAtReference = function(cell_reference, value) {
      var regex = /(\d+)/g,
          refs = cell_reference.match(regex);

      scope.newTotal = parseFloat(scope.spreadsheet_rows[refs[0]].row[refs[1]].associated_rows[refs[2]][refs[refs[3]]].value) + value;
      scope.spreadsheet_rows[refs[0]].row[refs[1]].associated_rows[refs[2]][refs[refs[3]]].value = scope.newTotal;
      scope.forceAllCellCalculations();

      /*
       Don't delete this code. Used for add line items for add/edit invoice screen.
       Initiated the categoryId for customer invoice spreadsheet screen.
       */

      var categoryId = '';
      if(typeof scope.categoryId != 'undefined') {
        categoryId = scope.categoryId;
      }
      var spread_sheet_data = {categoryId: categoryId, category: scope.category, rows: angular.copy(scope.spreadsheet_rows)};
      scope.$emit('event:save-spreadsheet-data', spread_sheet_data);
      scope.handleRowCount();

      /* End */

    };

    scope.handleDisabledAttr = function handleDisabledAttr() {
      for(var i = 0, l = scope.spreadsheet_rows.length; i < l; i++) {
        var row = scope.spreadsheet_rows[i],
            row_is_associated = row.row[0].associated_rows !== undefined,
            row_is_top_merged_row = row.merged_row_ids !== undefined;

        if (row_is_associated) {
          for(var x = 0, c = row.row[0].associated_rows.length; x < c; x++) {
            var associated_row = row.row[0].associated_rows[x];

            if (associated_row.row) {
              // The row is a child merged row
              var row_to_process = associated_row.row;
            } else {
              var row_to_process = associated_row;
            }

            for(var v = 0, b = row_to_process.length; v < b; v++) {
              var cell = row_to_process[v];

              cell.state = 'not_selectable';
            }
          }
        } else {
          var cells = row.row;

          for(var x = 0, c = cells.length; x < c; x++) {
            var cell = cells[x];

            cell.state = 'not_selectable';
          }
        }
      }
    }
  }

  return {
    restrict: 'A',
    templateUrl: 'template/spread_sheet/spread_sheet.html',
    scope: {
      getListUrl: '=',
      addUrl: '=',
      updateUrl: '=',
      deleteUrl: '=',
      categoryId: '=',
      mergeUrl: '=',
      unmergeUrl: '=',
      addMilestoneUrl: '=',
      editMilestoneUrl: '=',
      checkMilestoneUrl: '=',
      deleteMilestoneUrl: '='
    },
    link: handleSpreadSheet
  }
}]);
