commusoftCommon.directive('smoothScroll', function($timeout) {
    return {
        restrict: "ACE",
        link: function($scope, elem, attrs) {
            $scope.$on('smoothscroll', function(evt, settings) {
                $scope.smoothScrollToElement(settings.target, settings.offset);
            });

            $scope.smoothScrollToElement = function(target, offset, previousScrollTop) {
                if (typeof target == "string") {
                    target = $(target);
                }
                if ($(target).length) {
                    var container = elem;
                    var lastKnownScrollTop = $(container).scrollTop();
                    if (previousScrollTop) {
                        if (previousScrollTop == $(container).scrollTop()) {
                            // If the previous scrollTop position is the same as last time, it probably means we reached the end of the page. So, return false and don't scroll anymore.
                            $scope.finishedScrolling = true;
                            return false;
                        }
                    }

                    var targetPixel = 0;
                    var targetTop = $(target).offset().top;

                    if (typeof offset == "number") {
                        targetPixel = offset;
                    } else if (typeof offset == "string") {
                        targetPixel = parseInt(offset);
                    }
                    else {
                        targetPixel = 0;
                    }

                    if (targetPixel > targetTop) {
                        // means we have to scroll up
                        if ((targetPixel - targetTop) !== 0) {
                            if ((targetPixel - targetTop) > 150) {
                                $(container).scrollTop($(container).scrollTop() - 100);
                                $timeout(function() {
                                    $scope.smoothScrollToElement(target, offset, lastKnownScrollTop);
                                }, 10);
                            }
                            else if ((targetPixel - targetTop) > 50) {
                                $(container).scrollTop($(container).scrollTop() - 50);
                                $timeout(function() {
                                    $scope.smoothScrollToElement(target, offset, lastKnownScrollTop);
                                }, 10);
                            }
                            else if ((targetPixel - targetTop) > 20) {
                                $(container).scrollTop($(container).scrollTop() - 20);
                                $timeout(function() {
                                    $scope.smoothScrollToElement(target, offset, lastKnownScrollTop);
                                }, 10);
                            }
                            else if ((targetPixel - targetTop) > 5) {
                                $(container).scrollTop($(container).scrollTop() - 5);
                                $timeout(function() {
                                    $scope.smoothScrollToElement(target, offset, lastKnownScrollTop);
                                }, 10);
                            }
                            else if ((targetPixel - targetTop) >= 1) {
                                $(container).scrollTop($(container).scrollTop() - 1);
                                $timeout(function() {
                                    $scope.smoothScrollToElement(target, offset, lastKnownScrollTop);
                                }, 10);
                            }
                        }
                    }
                    else if (targetPixel < targetTop) {
                        // means we have to scroll down
                        if ((targetPixel - targetTop) !== 0) {
                            if ((targetTop - targetPixel) > 150) {
                                $(container).scrollTop($(container).scrollTop() + 100);
                                $timeout(function() {
                                    $scope.smoothScrollToElement(target, offset, lastKnownScrollTop);
                                }, 10);
                            }
                            else if ((targetTop - targetPixel) > 50) {
                                $(container).scrollTop($(container).scrollTop() + 50);
                                $timeout(function() {
                                    $scope.smoothScrollToElement(target, offset, lastKnownScrollTop);
                                }, 10);
                            }
                            else if ((targetTop - targetPixel) > 20) {
                                $(container).scrollTop($(container).scrollTop() + 20);
                                $timeout(function() {
                                    $scope.smoothScrollToElement(target, offset, lastKnownScrollTop);
                                }, 10);
                            }
                            else if ((targetTop - targetPixel) > 5) {
                                $(container).scrollTop($(container).scrollTop() + 5);
                                $timeout(function() {
                                    $scope.smoothScrollToElement(target, offset, lastKnownScrollTop);
                                }, 10);
                            }
                            else if ((targetTop - targetPixel) >= 1) {
                                $(container).scrollTop($(container).scrollTop() + 1);
                                $timeout(function() {
                                    $scope.smoothScrollToElement(target, offset, lastKnownScrollTop);
                                }, 10);
                            }
                        }
                    }
                }
            }
        }
    }
});