commusoftCommon.filter('dateNewFormat', function(CS_LANGUAGE) {
    return function(dateString) {
        moment.locale(CS_LANGUAGE);
        if(moment().isSame(dateString, 'day')){
            return 'Today';
        }else if(moment().subtract('days', 1).format('YYYY-MM-DD') == moment(dateString).format('YYYY-MM-DD')){
            return 'Yesterday'
        }else{
            return moment(dateString).format('ddd Do MMMM YYYY')
        }
    };
});