commusoftCommon.directive('changeSearchTab', function() {
    return function(scope, element, attrs) {
      //jQuery upgrade issue fixes
      $(element).find('a').on('click', function(e){
        e.preventDefault();
        $(element).find('a').removeClass('active');
        $(this).addClass('active');
        var link, tagetTable, topOffset, topTable;
        link = $('.' + $(this).data('filter'));
        tagetTable = parseInt(link.offset().top);
        topOffset = $('.results-table-wrapper').offset().top;
        topTable = $('#top-table');
        if (link.is(topTable)) {
          return $(".results-wrapper").animate({
            scrollTop: tagetTable - topOffset
          }, 500);
        } else {
          return $(".results-wrapper").animate({
            scrollTop: tagetTable - (topOffset - 12)
          }, 500);
        }
      });
        /*$('.filter-results a').click(function() {
            $('.filter-results a').removeClass('active');
            return $(this).addClass('active');
        });

        return $(".filter-results a").click(function(e) {
            var link, tagetTable, topOffset, topTable;
            e.preventDefault();
            link = $('.' + $(this).data('filter'));
            tagetTable = parseInt(link.offset().top);
            topOffset = $('.results-table-wrapper').offset().top;
            topTable = $('#top-table');
            if (link.is(topTable)) {
                return $(".results-wrapper").animate({
                    scrollTop: tagetTable - topOffset
                }, 500);
            } else {
                return $(".results-wrapper").animate({
                    scrollTop: tagetTable - (topOffset - 12)
                }, 500);
            }
        });*/
    };
});