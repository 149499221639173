csmodule.service('configurePricingColumnsHelper', function($q) {

    this.handleColumnsToShow = function handleColumnsToShow(column_settings, directive_scope) {
        var self = this;
        this.column_settings = column_settings;

        this.directive_scope = directive_scope;
        this.rows = directive_scope.spreadsheet_rows;
        this.headers = this.directive_scope.spreadsheet_headers;
        this.columns_names_to_hide = _.pluck(_.where(this.column_settings, {selected: true}), 'name');

        this.handleColumns();
    }

    this.handleColumns = function handleColumns() {
        this.cells_to_hide_indexes = [];

        for(var i = 0, l = this.headers.length; i < l; i++) {
            var header = this.headers[i],
                header_name = header.value,
                column_should_be_hidden = this.columns_names_to_hide.indexOf(header_name) > -1;

            if(column_should_be_hidden) {
                header.not_shown_to_user = true;
                this.cells_to_hide_indexes.push(i);
            }else {
                header.not_shown_to_user = false;
            }
        }

        this.handleCellsInRow();
    }

    this.handleCellsInRow = function handleCellsInRow() {
        for(var i = 0, l = this.rows.length; i < l; i++) {
            var row = this.rows[i];

            for(var x = 0, y = row.length; x < y; x++) {
                var cell = row[x],
                    cell_should_be_hidden = this.cells_to_hide_indexes.indexOf(x) > -1;

                if(cell_should_be_hidden) {
                    cell.not_shown_to_user = true;
                }else {
                    cell.not_shown_to_user = false;
                }
            }
        }
    }
});