commusoftCommon.filter('dateFormatShortDate', function(CS_LANGUAGE) {
    return function(dateString) {
        if(dateString == '' ){
            return '';
        }
        let language = CS_LANGUAGE;
        if(language == 'en'){
            language = 'en-GB';
        }
        moment.locale(language);
        //Note: Changing this filter will affect all over the application - Santha
        //return moment.utc(dateString).zone(moment().format('Z')).format('L');

        //when convert a datestring(10/04/2016 00:00:00) to utc,the format of L ignores the HH:mm:ss so its consider(00:00:00) which means midnight. the utc behind +1 so its -1 from midnight((09/04/2016 23:00:00)) obviously that is previous day)
        return moment(dateString).lang(language).format('L');
    };
}).filter('dateFormatShortDates', function(CS_LANGUAGE) {
    return function(dateString) {
        if(dateString == '' ){
            return '';
        }
        let language = CS_LANGUAGE;
        if(language == 'en'){
            language = 'en-GB';
        }
        moment.locale(language);
        //above used getting chinse format
        return moment(dateString).format('L');
    };
}).filter('datetimeTimezone', function(CS_LANGUAGE) {
    return function (dateString, format) {
        if(dateString == ''){
            return '';
        }
        let language = CS_LANGUAGE;
        if(language == 'en'){
            language = 'en-GB';
        }
        moment.locale(language);
        return moment.utc(dateString).zone(moment().format('Z')).format(format)
    }
});
