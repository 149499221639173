commusoftCommon.directive('counterbox', function($timeout) {

    var handleCounter = function handleCounter(scope, element, attrs) {
        scope.title = attrs.title;
        scope.number = attrs.initialValue;
        scope.total_number = attrs.initialValue;
        scope.count_direction = attrs.countDirection;
        scope.count_complete = false;
        scope.count_speed = 200;

        /*====================================================
         Number is increased or decreased depending on the
         count_direction value
         ====================================================*/
        scope.startCounter = function startCounter() {
            if(scope.number < 1) {
                scope.handleCompleteUI();
                return;
            }

            if (scope.count_direction === 'down') {
                scope.number--;
            }else if(scope.count_direction === 'up') {
                scope.number++;
            }

            $timeout(scope.startCounter, scope.count_speed);
        }

        scope.handleCompleteUI = function handleCompleteUI() {
            scope.count_complete = true;
        }

        // start the timer for the instance
        scope.startCounter();
    }

    return {
        restrict: 'A',
        templateUrl: 'template/counter_box.html',
        scope: {},
        link: handleCounter
    }
});