/**
 * Created on 11/11/17.
 */

/** DiaryMapCtrl declaration ----------------------------------------------------------------------------------------*/

var DiaryMapCtrl = ['$scope', '$rootScope', '$http', '$csMapper', '$gpsTracker', '$jobEstimateMapMarkerScope', '$diaryMapScope', '$userMapMarkerScope', 'colorSelector', '$timeout', 'uiCalendarConfig', '$compile', '$filter',
    function ($scope, $rootScope, $http, $csMapper, $gpsTracker, $jobEstimateMapMarkerScope, $diaryMapScope, $userMapMarkerScope, colorSelector, $timeout, uiCalendarConfig, $compile, $filter) {
        $scope.foreignWatchers = {};
        $scope.users = [];
        $scope.filteredUsers = [];
        $scope.usersToShowOnMap = [];
        $scope.eventsToShowOnMap = [];
        $scope.availableGpsDevices = {};
        $scope.gpsDevicesToShowOnMap = [];
        $scope.mapTimeBounds = {};
        $scope.contollerDestroyed = false;
        $scope.userAgenda = null;
        $scope.usersSidePanelOpened = false;
        $scope.estimateJobDetails = null;
        $scope.resetEstimateJobDetails = function () {
            $scope.estimateJobDetails = {
                canShow: false,
                source: null,
                type: null,
                latitude: null,
                longitude: null,
                data: null,
                callback: null
            };
        };
        $scope.resetEstimateJobDetails();

        $scope.warnings = [];
        $scope.isInfobarInitiated = false;
        $scope.usersOnTravel = {};
        $scope.eventsCouldNotBeShownOnMap = [0, {
            events: [],
            outstanding: [],
            noEvent: []
        }];
        $scope.adjustMap = true;
        $scope.navSuggestion = {showing: false, userId: null, origin: null, destination: null, data: null};
        $scope.normalEvents = [];
        $scope.currentMapZoomLevel = 0;
        $scope.mapLayersZoomLevel = {
            13: []
        };
        $scope.focusedMarker = null;
        $scope.usersWithoutEvents = [];
        $scope.userWithoutEventMarkerColor = "#747474";
        $scope.infoWidgetInitiated = false;
        $scope.infoWidgetData = null;
        $scope.jdwInFocus = null;
        $scope.dueWindows = [];

        var diaryCtrlScope = $scope.$parent.$parent;
        $scope.userShift = diaryCtrlScope.userShift;

        $scope.uiMapCalendarConfig = {
            calendar: {
                schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
                header: false,
                defaultView: 'agendaDay',
                contentHeight: handleFullCalendarHeight(),
                scrollTime: '08:00:00',
                slotDuration: "00:30:00",
                displayEventEnd: true,
                forceEventDuration: true,
                defaultTimedEventDuration: "01:00:00",
                smallTimeFormat: diaryCtrlScope.smallTimeFormat,
                eventLimit: true,
                defaultDate: new Date(),
                handleWindowResize: true,
                selectable: true,
                selectHelper: true,
                editable: true,
                fixedWeekCount: false,
                resources: $scope.usersToShowOnMap,
                droppable: true,
                /*select: function (start, end, event, resourceId, allDay) {
                    diaryCtrlScope.onSelect(start, end, allDay, resourceId);
                },*/
                select: function(start, end, event, view, resource, allDay) {
                    var viewName = $scope.currentView;
                    //Temp fix
                    if(viewName == 'twoWeek' || viewName == 'month') {
                        allDay = 'fullday';
                    }
                    diaryCtrlScope.onSelect(start, end, allDay, parseInt(resource.id));
                },
                eventClick: function (calEvent, jsEvent, view) {
                },
                drop: function (date, jsEvent, ui, resourceId, allDay) {
                    diaryCtrlScope.uiConfig.calendar.drop(date, jsEvent, ui, resourceId, allDay);
                },
                eventDrop: function (event) {
                    diaryCtrlScope.uiConfig.calendar.eventDrop(event);
                },
                eventResizeStart: function (event) {
                    diaryCtrlScope.uiConfig.calendar.eventResizeStart(event);
                },
                eventResizeStop: function (event) {
                    diaryCtrlScope.uiConfig.calendar.eventResizeStop(event);
                },
                eventDragStart: function (event) {
                    diaryCtrlScope.uiConfig.calendar.eventDragStart(event);
                },
                eventDragStop: function (event) {
                    diaryCtrlScope.uiConfig.calendar.eventDragStop(event);
                },
                eventResize: function (event) {
                    diaryCtrlScope.uiConfig.calendar.eventResize(event);
                },
                eventRender: function (event, element, view) {
                    var contentObj = csDiaryHelpers.getEventHtml(view, event);
                    var content = contentObj.html;

                    if (view.name == 'timelineDay' || view.name == 'timelineWeek') {
                        // Add the popup attributes
                        angular.forEach(contentObj.popup, function(val, key) {
                            element.attr(key, val);
                        });
                    }

                    var classNames = $(content).attr('class');
                    if (view.name == 'twoWeek' || view.name == 'month') {

                    } else {
                        element.addClass(' ' + classNames);
                    }
                    content = $(content).removeClass(classNames);
                    element.html(content);
                    if (view.name == 'twoWeek' || view.name == 'month') {
                        $('div', element).addClass(' cs-event-no-background ' + classNames);
                    }
                    $compile(element)($scope);
                }
            },
            resourceId: null,
            eventSources: []
        };

        // Rendering map
        var csMapper = $csMapper.get();
        csMapper.initialize(
            'diary-map',
            'vector',
            true,

            // on load callback
            function () {
                csMapper.addOverlay('Estimates');
                csMapper.addOverlay('Jobs');
                csMapper.addOverlay('Users');
                csMapper.addOverlay('InAction', false, null, false);
                $scope.currentMapZoomLevel = csMapper.map.getZoom();

                // On map zoom level change
                csMapper.map.on('zoomend ', function (event) {
                    var zoom = csMapper.map.getZoom();

                    if ($scope.currentMapZoomLevel !== zoom) {
                        var prevZoom = $scope.currentMapZoomLevel;
                        $scope.currentMapZoomLevel = zoom;

                        $scope.onMapZoomLevelChange(prevZoom, zoom);
                    }
                });

                $diaryMapScope.diaryMapRenderObservable.promise.then(null, null, function (message) {
                    if ($diaryMapScope.diaryMapRenderObservable.stages.indexOf('USERS_RENDERED') >= 0
                        && $diaryMapScope.diaryMapRenderObservable.stages.indexOf('EVENTS_RENDERED') >= 0
                        && $diaryMapScope.diaryMapRenderObservable.stages.indexOf('VIEW_ADJUSTED') < 0) {

                        csMapper.adjustMapBounds();
                        $diaryMapScope.diaryMapRenderObservable.notify('VIEW_ADJUSTED');
                        $diaryMapScope.diaryMapRenderObservable.resolve();

                    }
                });

                $diaryMapScope.diaryMapRenderObservable.notify('INITIATED');

                // Refreshing the map to fit into full diary view
                setTimeout(function () {
                    csMapper.addOverlay('Navigation', false, null, false, 'Users');
                    csMapper.resize();
                }, 250);
            });

        csMapper.mapEvents.then(null, null, function (event) {
            switch (event.action) {
                case "mapClick": {
                    if ($scope.userAgenda) $scope.hideUserAgenda();
                    break
                }
            }
        });

        // Initialize variables
        var init = function () {
            $scope.users = angular.copy(diaryCtrlScope.allUsers);
            let unAssignedUsers = angular.copy(diaryCtrlScope.unAssignedUsers);
            if(unAssignedUsers && unAssignedUsers.length) {
                angular.forEach(unAssignedUsers, function (value, key) {
                    $scope.users.push(value);
                });
            }
            updateAvailableGpsDevices();
            $scope.filteredUsers = angular.copy($scope.users_to_show_on_dairy);
            $scope.usersToShowOnMap = $scope.filteredUsers;
            updateGpsDevicesToShowOnMap();
        };

        // Listens filtered users
        $scope.foreignWatchers['diaryMapScopeUsersToShowOnMapUpdatedListener'] = $diaryMapScope.$on('usersToShowOnMap:updated', function (event, filteredUsers) {
            if (JSON.stringify($scope.usersToShowOnMap) === JSON.stringify(filteredUsers)) return;
            $scope.filteredUsers = filteredUsers;

            if ($scope.mapTimeBounds.mode === 'BETWEEN_HOURS') {
                if ($scope.filteredUsers.findIndex(function (u) {
                    return u.id == $scope.mapTimeBounds.userToShowOnHistoricalView
                }) < 0) {
                    $diaryMapScope.setMapTimeBounds(Object.assign(
                        $diaryMapScope.mapTimeBounds,
                        {
                            userToShowOnHistoricalView: $scope.filteredUsers.length ? $scope.filteredUsers[0].id : null
                        })
                    )
                }
            }
            else {
                $scope.usersToShowOnMap = angular.copy($scope.filteredUsers);
                updateGpsDevicesToShowOnMap();

                if ($diaryMapScope.diaryMapRenderObservable.stages.indexOf('USERS_RENDERED') >= 0)
                    updateMarkers('userFiltering');
            }
        });

        // Listens current events changes
        $scope.$on('currentEventsChanged', function (event, args) {
            if (!args.length && $diaryMapScope.diaryMapRenderObservable.stages.indexOf('RENDER_COMPETED') < 0)
                $diaryMapScope.diaryMapRenderObservable.notify('EVENTS_RENDERED');

            if (JSON.stringify($scope.eventsToShowOnMap) === JSON.stringify(args)) {
                // Refresh user agenda diary events
                if ($scope.userAgenda) $scope.refreshUserAgenda();

                return;
            }

            $scope.eventsToShowOnMap = angular.copy(args.filter(function (e) {
                return e.type !== 'normal' && csMapper.validateLatLng(e.otherDetails.latitude, e.otherDetails.longitude);
            }));
            $scope.eventsCouldNotBeShownOnMap[1].events = angular.copy(args.filter(function (e) {
                return e.type !== 'normal' && !csMapper.validateLatLng(e.otherDetails.latitude, e.otherDetails.longitude);
            }));
            $scope.normalEvents = angular.copy(args.filter(function (e) {
                return e.type === 'normal';
            }));
            $scope.updateInvalidEventsCount();

            if ($scope.eventsCouldNotBeShownOnMap[0] > 0) $scope.addWarning('EVENTS_HIDED');
            else $scope.warnings = $scope.warnings.filter(function (w) {
                return w !== 'EVENTS_HIDED';
            });

            updateTravelEvents();

            // Re-render user agenda diary on events update
            if ($scope.userAgenda) $scope.showUserAgenda();

            // Removing outstanding job/estimate markers if event booked
            if ($scope.estimateJobDetails.canShow) {
                var type, id;

                type = $scope.estimateJobDetails.type;
                id = $scope.estimateJobDetails.data.id;

                var events = args.filter(function (e) {
                    return e.type !== 'normal' && e.type === type && parseInt(e.redirectDetails[e.type + 'Id']) === parseInt(id);
                });

                if (events.length > 0) {
                    var layer = events[0].type === 'job' ? 'Jobs' : 'Estimates';

                    if ($scope.estimateJobDetails.source === 'FOCUS_MARKER' && csMapper.markerExists('', 'focusMarker')) {
                        csMapper.removeFocusPointerMarker(true);
                        $scope.hideUserAgenda();
                        $scope.setDefaultMapView();
                    } else {
                        csMapper.removePointingMarker(layer, 'outstandingJobEstimate');
                    }

                    $scope.resetEstimateJobDetails();
                }
            }

            updateMarkers('eventsUpdated');
            if ($diaryMapScope.diaryMapRenderObservable.stages.indexOf('RENDER_COMPETED') < 0) {
                $diaryMapScope.diaryMapRenderObservable.notify('EVENTS_RENDERED');
            }
        });

        $scope.foreignWatchers['diaryMapScopeMapBoundsUpdatedListener'] = $diaryMapScope.$on('mapTimeBounds:updated', function (event, bounds) {
            if (JSON.stringify($scope.mapTimeBounds) === JSON.stringify(bounds)) return;

            var calendarDate = $scope.mapTimeBounds.date;
            $scope.mapTimeBounds = angular.copy(bounds);
            if (calendarDate !== bounds.date) updateTravelEvents();
            $scope.onTimeBoundsChange();
        });

        $scope.foreignWatchers['outstandingJobSelectionListener'] = $diaryMapScope.$on('outstandingJob:selected', function (event, data) {
            var oj = data['jobDetails'], source = data['source'],
                lat, lng, id, type, title, redirectDetails, otherDetails, callback;

            if (['SIDE_PANEL', 'FOCUS_MARKER'].indexOf(source) >= 0) {
                csMapper.removePointingMarker('Jobs', 'outstandingJobEstimate');
                csMapper.removePointingMarker('Estimates', 'outstandingJobEstimate');
            }

            switch (source) {
                case "FROM_PREVIOUS": {
                    id = oj.id;
                    lat = oj.property.latitude_s;
                    lng = oj.property.longitude_s;
                    type = oj.type;
                    title = 'Yet to book appointment';
                    redirectDetails = {
                        type: type,
                        customerId: oj.property.id,
                        customerType: 'customer'
                    };
                    redirectDetails[type + 'Id'] = oj.id;

                    otherDetails = {
                        customerName: oj.property.customer_name,
                        addressline: oj.property.addressline1_s,
                        postcode: oj.property.postcodewithoutspace_s
                    };

                    callback = $scope.onJobEstimateMarkerClick;
                    break;
                }
                default: {
                    id = oj.id;
                    lat = oj.property_latitude;
                    lng = oj.property_longitude;
                    type = oj.recordType;
                    title = oj.description;
                    redirectDetails = {
                        type: type,
                        customerId: oj.customerId,
                        customerType: oj.propertyType
                    };
                    redirectDetails[type + 'Id'] = oj.id;

                    otherDetails = {
                        customerName: oj.customerName,
                        addressline: oj.addressLine1,
                        postcode: oj.postcode
                    };

                    if (source === 'RIGHT_BAR') callback = $scope.onOutstandingJobEstimateMarkerClick;

                    break;
                }
            }

            // Show in map
            if (csMapper.validateLatLng(lat, lng)) {
                $scope.estimateJobDetails = {
                    canShow: true,
                    source: source,
                    type: type,
                    latitude: parseFloat(lat),
                    longitude: parseFloat(lng),
                    data: oj,
                    callback: callback
                };

                drawOutstandingJobMarker();
            }
            else { // Add to warning
                $scope.estimateJobDetails = {
                    canShow: false,
                    source: null,
                    type: null,
                    latitude: null,
                    longitude: null,
                    data: null,
                    callback: null
                };

                var alreadyExists = _.where($scope.eventsCouldNotBeShownOnMap[1].noEvent, {id: id}).length;
                if (!alreadyExists) {
                    var details = {
                        id: id,
                        type: type,
                        title: description,
                        redirectDetails: redirectDetails,
                        otherDetails: otherDetails
                    };

                    $scope.eventsCouldNotBeShownOnMap[1].noEvent.push(details);
                    $scope.updateInvalidEventsCount();

                    $scope.addWarning('EVENTS_HIDED');
                }
            }
        });

        $scope.foreignWatchers['sidepanelOpeningListener'] = $rootScope.$on('sidepanel:open', function (event, panelName) {
            if (panelName !== 'filter_users_on_diary' && panelName !== 'users_activity_panel_on_diary') return;

            $scope.usersSidePanelOpened = true;
            if ($scope.mapTimeBounds.mode === 'REAL_TIME') publishUsersLastIdentifiedInfo();
        });

        $scope.foreignWatchers['sidepanelClosingListener'] = $rootScope.$on('sidepanel:filter_users_on_diary:closed', function (event) {
            $scope.usersSidePanelOpened = false;
        });

        $scope.foreignWatchers['userRecenteringListener'] = $rootScope.$on('dmUser:recenter', function (event, userId) {
            csMapper.showMarker('Users', userId);
            csMapper.panToMarker('Users', userId);
        });


        // Real-time users positioning
        $scope.onTimeBoundsChange = function () {
            var bounds = $scope.mapTimeBounds;
            csMapper.cleanOverlay('Users');
            $gpsTracker.cancelGetRouteRequest();
            $gpsTracker.cancelGetNavigationsRequest();
            $gpsTracker.cancelGetNavigationsRequest();

            $scope.hideUserAgenda();
            $scope.adjustMap = true;
            csMapper.hideNavigation('Navigation', 'routeToOutstanding', true);

            if (bounds['mode'] === 'REAL_TIME') {
                // Permission check
                if (!$rootScope.hasPermission(features['Viewengineerslocation'], 'readaccess')) return;

                $scope.usersToShowOnMap = angular.copy($scope.filteredUsers);
                updateGpsDevicesToShowOnMap();
                drawJobEstimateMarkers('userFiltering');

                $gpsTracker.getPosition()
                    .then(function (result) {
                        if (!result.length) return;

                        result.forEach(function (position) {
                            if ($scope.availableGpsDevices[position.deviceId]) {
                                $scope.availableGpsDevices[position.deviceId].position = position;
                            }
                        });

                        // Repositioning users
                        drawUserMarkers();
                        setNoEventUsersMarkerColor($scope.usersWithoutEvents, []);
                        $scope.adjustMap = false;

                        // Draw ETA of travel events
                        drawUserNavigationPaths();
                        // Draw ETA to outstanding job
                        if ($scope.navSuggestion.showing) drawNavSuggestion();
                        // Publish updates to users sidepanel
                        if ($scope.usersSidePanelOpened) publishUsersLastIdentifiedInfo();

                        if ($diaryMapScope.diaryMapRenderObservable.stages.indexOf('RENDER_COMPETED') < 0) {
                            $diaryMapScope.diaryMapRenderObservable.notify('USERS_RENDERED');
                        }
                        socket();
                    });

                // Opening traccar socket
                function socket() {
                    var travelNavRefreshScheduler = null, schedulePool = [];

                    $gpsTracker.createSocket().then(function (socket) {

                        // Subscribing events
                        socket.onmessage = function (event) {
                            var eventData = JSON.parse(event.data);
                            if (Object.keys(eventData).indexOf('positions') >= 0) {
                                var deviceIds = [];

                                // Updating position details
                                eventData.positions.forEach(function (position) {
                                    if ($scope.availableGpsDevices[position.deviceId]) {
                                        $scope.availableGpsDevices[position.deviceId].position = position;
                                        deviceIds.push(position.deviceId);
                                    }
                                });

                                // Skip marker updates if there is no location update in the filtered users
                                deviceIds = deviceIds.filter(function (di) {
                                    return $scope.gpsDevicesToShowOnMap.indexOf(di) >= 0;
                                });
                                if (!deviceIds.length) return;

                                // Repositioning users
                                drawUserMarkers('REAL_TIME_LOCATION_UPDATE', deviceIds);
                                if ($scope.usersSidePanelOpened) publishUsersLastIdentifiedInfo();

                                // Scheduling travel events ETA refresh
                                if (!travelNavRefreshScheduler) travelNavRefreshScheduler = setTimeout(function () {

                                    if (schedulePool.length) drawUserNavigationPaths('REAL_TIME_LOCATION_UPDATE', angular.copy(schedulePool));
                                    travelNavRefreshScheduler = null;
                                    schedulePool = [];

                                }, 15 * 1000); // 15 seconds delay

                                schedulePool = schedulePool.concat(deviceIds).filter(function (val, index, self) {
                                    return self.indexOf(val) === index;
                                });

                            }
                        };
                    }).catch(function (e) {
                        throw e;
                    });

                }
            }
            else if (bounds['mode'] === 'BETWEEN_HOURS') {
                // Permission check
                if (!$rootScope.hasPermission(features['Viewengineerslocation'], 'readaccess')) return;

                $gpsTracker.closeSocket();
                $scope.usersToShowOnMap = $scope.filteredUsers.filter(function (u) {
                    return u.id === bounds.userToShowOnHistoricalView;
                });
                updateGpsDevicesToShowOnMap();
                drawJobEstimateMarkers('userFiltering');

                if ($scope.gpsDevicesToShowOnMap.length > 0) {
                    // Fetches route points from api
                    if ($scope.contollerDestroyed || $scope.mapTimeBounds.mode !== 'BETWEEN_HOURS') return;

                    $gpsTracker.getRoute($scope.gpsDevicesToShowOnMap, bounds.duration.startTime.toISOString(), bounds.duration.endTime.toISOString()).then(function (result) {
                        if (result instanceof Object) {
                            drawUserRoutes(result);
                            if ($diaryMapScope.diaryMapRenderObservable.stages.indexOf('RENDER_COMPETED') < 0) {
                                $diaryMapScope.diaryMapRenderObservable.notify('USERS_RENDERED');
                            }
                        } else {
                            csMapper.adjustMapBounds();
                        }

                    }).catch(function (e) {
                        console.log(e);
                    });
                }
            }
            else if (bounds['mode'] === 'FUTURE') {
                $scope.usersToShowOnMap = angular.copy($scope.filteredUsers);
                updateGpsDevicesToShowOnMap();
                drawJobEstimateMarkers('userFiltering');
                $gpsTracker.closeSocket();
            }
        };

        // Show user's diary
        $scope.showUserAgenda = function (userId, date) {
            if (userId) $scope.userAgenda = userId;
            if (!date) date = $scope.mapTimeBounds.date ? moment($scope.mapTimeBounds.date, 'YYYY-MM-DD')._d : new Date();
            var shifts = {};
            if ($scope.userShift && $scope.userShift[$scope.userAgenda]) {
                shifts[$scope.userAgenda] = $scope.userShift[$scope.userAgenda]
            }

            $scope.uiMapCalendarConfig.calendar.resourceId = $scope.userAgenda;
            $scope.uiMapCalendarConfig.calendar.resources = [_.where($scope.usersToShowOnMap, {id: $scope.userAgenda})[0]];
            $scope.uiMapCalendarConfig.calendar.eventSources = _.where($scope.eventsToShowOnMap.concat($scope.eventsCouldNotBeShownOnMap[1].events).concat($scope.normalEvents), {resourceId: $scope.userAgenda});
            $scope.uiMapCalendarConfig.calendar.defaultDate = date;
            $scope.uiMapCalendarConfig.calendar.shift = shifts;

            uiCalendarConfig.calendars.csDiarymapCalendar.fullCalendar('removeEvents');
            $timeout(function () {
                uiCalendarConfig.calendars.csDiarymapCalendar.fullCalendar('prev');
                uiCalendarConfig.calendars.csDiarymapCalendar.fullCalendar('next');
                resizeUserAgenda();

                if ($scope.uiMapCalendarConfig.calendar.eventSources.length) {
                    uiCalendarConfig.calendars.csDiarymapCalendar.fullCalendar('addEventSource', $scope.uiMapCalendarConfig.calendar.eventSources);
                }


                $scope.$broadcast('diaryMapUserAgenda:open');
            });

        };

        // Re-rendering the diary events in user agenda
        $scope.refreshUserAgenda = function () {
            $scope.uiMapCalendarConfig.calendar.eventSources = _.where($scope.eventsToShowOnMap.concat($scope.eventsCouldNotBeShownOnMap[1].events).concat($scope.normalEvents), {resourceId: $scope.userAgenda});

            setTimeout(function () {
                uiCalendarConfig.calendars.csDiarymapCalendar.fullCalendar('removeEvents');

                $timeout(function () {

                    uiCalendarConfig.calendars.csDiarymapCalendar.fullCalendar('prev');
                    uiCalendarConfig.calendars.csDiarymapCalendar.fullCalendar('next');
                    resizeUserAgenda();

                    if ($scope.uiMapCalendarConfig.calendar.eventSources.length) {
                        uiCalendarConfig.calendars.csDiarymapCalendar.fullCalendar('addEventSource', $scope.uiMapCalendarConfig.calendar.eventSources);
                    }

                });
            }, 200);
        };

        // Hide user's diary
        $scope.hideUserAgenda = function () {
            $scope.userAgenda = null;
            $timeout(function () {
                csMapper.resize();
                $scope.$broadcast('diaryMapUserAgenda:close');
            }, 250);
        };

        $scope.foreignWatchers['addDiaryEventPanelCloseListener'] = $rootScope.$on('sidepanel:add_new_diary_event:closed', function (ev) {
            uiCalendarConfig.calendars.csDiarymapCalendar.fullCalendar('unselect');
        });

        $scope.$on('dmUserMarker:singleClick', function (event, userId) {

            if (userId === $scope.userAgenda) {
                $scope.hideUserAgenda();
                $scope.navSuggestion.showing = false;
                csMapper.hideNavigation('Navigation', 'routeToOutstanding');
            }
            else {
                $scope.showUserAgenda(userId);

                var ojMaker = csMapper.markerExists('Jobs', 'outstandingJobEstimate') || csMapper.markerExists('Estimates', 'outstandingJobEstimate') || csMapper.markerExists('', 'focusMarker');

                if (ojMaker) {
                    $scope.navSuggestion.userId = userId;
                    $scope.navSuggestion.showing = true;
                    var destination = ojMaker.marker instanceof L.FeatureGroup ?
                        ojMaker.marker.getBounds().getCenter() :
                        ojMaker.marker.getLatLng();
                    drawNavSuggestion([destination.lat, destination.lng]);

                }
            }
        });

        $scope.$on('dmJobEstimateMarker:singleClickUser', function (event, userId) {

            if (userId === $scope.userAgenda) $scope.hideUserAgenda();
            else {
                $scope.showUserAgenda(userId);
            }
        });

        $scope.addWarning = function (warning) {
            $scope.warnings.push(warning);
            $scope.isInfobarInitiated = true;
        };

        // Opening sidepanel on job/estimate marker click
        $scope.onJobEstimateMarkerClick = function (e) {
            var date = moment($scope.mapTimeBounds.date, 'YYYY-MM-DD');

            diaryCtrlScope.onSelect(date, date, 'fullday', $scope.userAgenda || null, true);
        };

        // Opening sidepanel on job/estimate marker click
        $scope.onOutstandingJobEstimateMarkerClick = function (e) {
            var date = moment($scope.mapTimeBounds.date, 'YYYY-MM-DD');

            diaryCtrlScope.handleEventDrop(date, e, $scope.userAgenda || null, 'fullday');
        };

        $scope.updateInvalidEventsCount = function () {
            $scope.eventsCouldNotBeShownOnMap[0] = Object.values($scope.eventsCouldNotBeShownOnMap[1]).reduce(function (total, l) {
                return total + l.length
            }, 0);
        };

        /** Outstanding Jobs scheduling ******************************** */

        $scope.setDefaultMapView = function () {
            csMapper.cleanOverlay('InAction');
            csMapper.hideOverlay('InAction');
            if (csMapper.markerExists('', 'focusMarker')) csMapper.removeFocusPointerMarker();

            // Hide user agenda on resetting map view
            $scope.hideUserAgenda();

            csMapper.showOverlay('Jobs');
            csMapper.showOverlay('Estimates');
            csMapper.showOverlay('Users');

            if (csMapper.hasOverlay('DueWindowJobs')) {
                $diaryMapScope.onJobDueWindowsRefresh($diaryMapScope.jobDueWindows);
                csMapper.showOverlay('DueWindowJobs');
            }

            csMapper.removeMapControl('setDefaultView');

            setTimeout(function () {
                csMapper.adjustMapBounds();
            }, 100);
        };

        $scope.foreignWatchers['diaryMapScopeJobDueWindows'] = $diaryMapScope.$on('jobDueWindows:refreshed', function (event, data) {
            $scope.dueWindows = data;

            var layerName = 'DueWindowJobs',
                drawMarkers = [],
                markerTemplateName = 'outstanding_job_marker';

            if (!csMapper.hasOverlay(layerName)) {
                csMapper.addOverlay(layerName, false);
                csMapper.hasOverlay(layerName).bringToFront();
            }


            data.forEach(function (wDetails) {
                var iconSize = $scope.jdwInFocus === wDetails.windowName ? 'Large' : 'Medium';
                wDetails.outstandingJobs.forEach(function (oj) {

                    // Skip marker if booking diary event from estimate/job screen
                    if ($scope.estimateJobDetails.canShow && $scope.estimateJobDetails.type === 'job' && $scope.estimateJobDetails.data.id == oj.id) return;

                    var markerSuffix = 'oj-' + oj.id;
                    var iconText = oj.recordType == 'job' ? 'J' : 'E';

                    var marker = csMapper.addUpdateDivInterpolatedMarker(
                        layerName,
                        {
                            markerSuffix: markerSuffix,
                            latitude: oj.property_latitude,
                            longitude: oj.property_longitude,
                            bindingData: {
                                color: wDetails.color,
                                width: $diaryMapScope.mapElementSpecifications[markerTemplateName]['width' + iconSize],
                                height: $diaryMapScope.mapElementSpecifications[markerTemplateName]['height' + iconSize],
                                iconText: iconText
                            }
                        },
                        $diaryMapScope.mapElementTemplates[markerTemplateName],
                        $diaryMapScope.mapElementSpecifications[markerTemplateName][['anchor' + iconSize]]
                    );
                    drawMarkers.push(marker.identifier);

                    if (!marker.focusEvent) csMapper.bindFocusEvent(layerName, marker, {
                        links: [],
                        type: 'OUTSTANDING_JOB',
                        data: oj
                    });

                    // Preserve focus pointer
                    if (csMapper.markers['_focusMarker']
                        && (
                            csMapper.markers['_focusMarker'].marker.identifier === layerName + '_' + markerSuffix
                        )) {
                        setTimeout(function () {
                            marker.fire('click');
                        }, 53);
                    }
                });
            });

            // Remove unnecessary markers
            var markersInMap = Object.keys(csMapper.markers).join('|'),
                pattern = layerName + '_oj-[0-9]+';

            (markersInMap.match(new RegExp(pattern, 'g')) || []).filter(function (m) {
                return drawMarkers.indexOf(m) < 0;
            }).forEach(function (m) {
                csMapper.deleteMarker(m.split('_')[0], m.split('_')[1]);
            });
        });

        $scope.foreignWatchers['diaryMapScopeJobDueWindowLabelClick'] = $diaryMapScope.$on('jobDueWindows:label:clicked', function (event, data) {
            csMapper.showOverlay('DueWindowJobs');
            $scope.setOutstandingJobMarkerSize(data, 'Large');
        });

        $scope.foreignWatchers['diaryMapScopeJobsSidePanelOnDiaryClose'] = $diaryMapScope.$on('jobsSidePanelOnDiary:close', function (event, data) {
            $scope.setOutstandingJobMarkerSize(data, 'Medium', false);
            $scope.jdwInFocus = null;
        });

        $scope.foreignWatchers['diaryMapScopeUsersWithoutEvents'] = $diaryMapScope.$on('usersWithoutEvents:updated', function (event, data) {
            var prev = $scope.usersWithoutEvents;
            $scope.usersWithoutEvents = data;
            if ($scope.mapTimeBounds.mode === 'REAL_TIME') setNoEventUsersMarkerColor($scope.usersWithoutEvents, prev);
        });

        $scope.foreignWatchers['diaryMapScopeInfoWidgetChange'] = $diaryMapScope.$on('mapInfoWidget:changed', function (event, data) {
            $scope.infoWidgetInitiated = true;

            switch (data.action) {
                case "hide": {
                    $timeout(function () {
                        $scope.infoWidgetData = null;
                    });

                    if ($scope.navSuggestion.showing) {
                        $scope.estimateJobDetails.canShow = false;
                        $scope.navSuggestion.showing = false;
                        csMapper.hideNavigation('Navigation', 'routeToOutstanding', true);
                    }
                    break;
                }
                case "show": {
                    $timeout(function () {
                        $scope.infoWidgetData = {
                            type: data.type,
                            data: data.data
                        };
                    });

                    if (data.type === 'OUTSTANDING_JOB') {
                        $diaryMapScope.handleOutstandingJobSelection(data.data, 'FOCUS_MARKER');
                    }
                    break;
                }
                case "update": {
                    $timeout(function () {
                        $scope.infoWidgetData = {
                            type: data.type,
                            data: data.data
                        };
                    });
                    break;
                }
            }
        });

        $scope.foreignWatchers['diaryMapScopeUsersActivityPanelUserSelect'] = $diaryMapScope.$on('usersActivityPanel:user:selected', function (event, user) {

            $scope.usersToShowOnMap = [user];

            $scope.showUserAgenda(user.id);
            if ($scope.navSuggestion.showing) {
                csMapper.hideNavigation('Navigation', 'routeToOutstanding', true);
                $scope.navSuggestion.userId = $scope.userAgenda;
                drawNavSuggestion();
            }
            else if ($scope.estimateJobDetails.canShow) {
                $scope.navSuggestion.showing = true;
                $scope.navSuggestion.userId = $scope.userAgenda;
                $scope.navSuggestion.destination = [$scope.estimateJobDetails.latitude, $scope.estimateJobDetails.longitude];

                drawNavSuggestion();
            }

            csMapper.hideOverlay('Users');
            csMapper.hideOverlay('Jobs');
            csMapper.hideOverlay('Estimates');
            csMapper.showOverlay('DueWindowJobs');

            var layerName = 'InAction',
                layer = csMapper.hasOverlay(layerName),
                homeMarkerTemplateName = 'outstanding_job_marker';

            layer.bringToFront();
            csMapper.cleanOverlay(layerName);
            csMapper.showOverlay(layerName);

            csMapper.hasOverlay('Navigation').addTo(layer);

            // Find and add the user & user related diary event markers
            var availableMakers = Object.keys(csMapper.markers).join('|'),
                patterns = ['(Users_(ND-|)(' + user.id + '))'];

            if ($diaryMapScope.usersEvents[user.id]) {
                var eI = [], jI = [];
                $diaryMapScope.usersEvents[user.id].forEach(function (event) {
                    if (event.type === 'estimate') eI.push(event.id);
                    else if (event.type === 'job') jI.push(event.id);
                });

                if (eI.length) patterns.push('(Estimates_event(' + eI.join('|') + '))');
                if (jI.length) patterns.push('(Jobs_event(' + jI.join('|') + '))');
            }

            var matches = availableMakers.match(new RegExp(patterns.join('|'), 'g')), matchedUserMarkers = [];
            if (matches) matches.forEach(function (mName) {
                csMapper.markers[mName].marker.addTo(layer);
                matchedUserMarkers.push(csMapper.markers[mName].marker);
            });

            // Add user's home address
            if (csMapper.validateLatLng(user.latitude, user.longitude)) {
                var drawHomeMarker = false, uM = availableMakers.match(new RegExp(patterns[0])) || [];

                if (!uM.length)
                    drawHomeMarker = true;
                else if (JSON.stringify(csMapper.markers[uM[0]].marker.getLatLng()) !== JSON.stringify(L.latLng(user.latitude, user.longitude)))
                    drawHomeMarker = true;

                // Draw the user marker not in his home location
                if (drawHomeMarker) {

                    csMapper.addUpdateDivInterpolatedMarker(
                        layerName,
                        {
                            markerSuffix: user.id,
                            latitude: user.latitude,
                            longitude: user.longitude,
                            bindingData: {
                                width: $diaryMapScope.mapElementSpecifications[homeMarkerTemplateName].widthLarge,
                                height: $diaryMapScope.mapElementSpecifications[homeMarkerTemplateName].heightLarge,
                                color: '#5567f4',
                                iconFont: 'fas fa-home'
                            }
                        },
                        $diaryMapScope.mapElementTemplates[homeMarkerTemplateName],
                        $diaryMapScope.mapElementSpecifications[homeMarkerTemplateName].anchorLarge
                    );
                }
            }

            csMapper.addMapControl('setDefaultView', 'far fa-window-restore', $scope.setDefaultMapView);

            $timeout(function () {
                csMapper.fitMapToMarkers(matchedUserMarkers);
            });
        });

        $scope.$on('outstandingJob:click', function (event, click) {
            $scope.onOutstandingJobEstimateMarkerClick(click);
        });

        $scope.setOutstandingJobMarkerSize = function (wDetails, size, recenter = true) {
            $scope.jdwInFocus = wDetails.windowName;
            if (!size) size = 'Medium';

            var layerName = 'DueWindowJobs',
                markerTemplateName = 'outstanding_job_marker',
                iconWidth = $diaryMapScope.mapElementSpecifications[markerTemplateName]['width' + size],
                iconHeight = $diaryMapScope.mapElementSpecifications[markerTemplateName]['height' + size],
                iconAnchor = $diaryMapScope.mapElementSpecifications[markerTemplateName]['anchor' + size],
                resizedMarkers = [];


            wDetails.outstandingJobs.forEach(function (oj) {
                var markerSuffix = 'oj-' + oj.id;
                var iconText = oj.recordType == 'job' ? 'J' : 'E';

                var marker = csMapper.addUpdateDivInterpolatedMarker(
                    layerName,
                    {
                        markerSuffix: markerSuffix,
                        latitude: oj.property_latitude,
                        longitude: oj.property_longitude,
                        bindingData: {
                            color: wDetails.windowColor,
                            width: iconWidth,
                            height: iconHeight,
                            iconText: iconText
                        }
                    },
                    $diaryMapScope.mapElementTemplates[markerTemplateName],
                    iconAnchor
                );

                resizedMarkers.push(marker);
            });

            if (recenter && resizedMarkers.length) {
                setTimeout(function () {
                    csMapper.fitMapToMarkers(resizedMarkers);
                }, 200);
            }

        };

        $scope.onMapZoomLevelChange = function (prev, current) {

            angular.forEach($scope.mapLayersZoomLevel, function (layers, zoom) {

                if (current >= parseInt(zoom)) {
                    layers.forEach(function (layer) {
                        csMapper.showOverlay(layer);
                    })
                } else {
                    layers.forEach(function (layer) {
                        csMapper.hideOverlay(layer);
                    })
                }
            })
        };


        function updateAvailableGpsDevices() {
            var diaryColors = {};
            colorSelector.getColors('diary_color').forEach(function (c) {
                diaryColors[c['color']] = c['rgba_dark'];
            });

            $scope.users.forEach(function (u) {
                if (!u.gpsDeviceId) return;

                u['backgroundColorValue'] = 'rgba(0,0,0)';
                if (u.backgroundColor in diaryColors) u.backgroundColorValue = diaryColors[u.backgroundColor];

                var data = {
                    user: u,
                    position: null
                };

                if ($scope.availableGpsDevices[u.gpsDeviceId]) data.position = $scope.availableGpsDevices[u.gpsDeviceId].position;
                $scope.availableGpsDevices[u.gpsDeviceId] = data;
            });
        }

        function updateGpsDevicesToShowOnMap() {
            $scope.gpsDevicesToShowOnMap = $scope.usersToShowOnMap.filter(function (u) {
                return u.gpsDeviceId;
            }).map(function (u) {
                return u.gpsDeviceId;
            });

            $scope.gpsDevicesToShowOnMap = Array.from(new Set($scope.gpsDevicesToShowOnMap));

            publishUsersLastIdentifiedInfo();
        }

        // Broadcasting user's position details
        function publishUsersLastIdentifiedInfo() {
            var data = {};

            Object.values($scope.availableGpsDevices).forEach(function (device) {
                if (device.position) {
                    data[device.user.id] = {
                        lastIdentifiedTime: new Date(device.position.deviceTime)
                    };
                }
            });
            $rootScope.$emit('dmUser:lastIdentifiedInfo', data);
        }

        function updateTravelEvents() {
            $scope.usersOnTravel = $scope.eventsToShowOnMap
                .filter(function (e) {
                    return e.status.status === 'travel'
                        && $scope.mapTimeBounds.date
                        && $scope.mapTimeBounds.date === moment(e.end).format('YYYY-MM-DD');
                }).reduce(function (resultant, e) {
                    resultant[e.resourceId] = [e.otherDetails.latitude, e.otherDetails.longitude];
                    return resultant;
                }, {})
        }

        // Creating/updating/removing markers from map, with respect to data update
        function updateMarkers(option) {
            if (option === 'userFiltering') {
                $scope.mapTimeBounds.mode === 'REAL_TIME' ? drawUserMarkers(option) :
                    $scope.mapTimeBounds.mode === 'BETWEEN_HOURS' ? filterUserRoutes(option) : '';
            }
            else if (option !== 'onlyEvents') {
                drawUserMarkers();
            }

            drawJobEstimateMarkers(option);

            if (['eventsUpdated', 'userFiltering'].indexOf(option) >= 0
                && $scope.mapTimeBounds.mode === 'REAL_TIME') {
                drawUserNavigationPaths(option);
                if ($scope.navSuggestion.showing) drawNavSuggestion();
            }

            // Drawing marker for outstanding job/estimate
            if ($scope.estimateJobDetails.canShow) {
                drawOutstandingJobMarker();
            }
        }

        // Pointing User on map
        function drawUserMarkers(option, deviceIds) {
            if (!(deviceIds instanceof Array)) deviceIds = $scope.gpsDevicesToShowOnMap;

            var drawnMarkers = [];

            // Rendering user markers
            deviceIds.forEach(function (deviceId) {
                if($scope.availableGpsDevices[deviceId] && $scope.availableGpsDevices[deviceId].user) {
                    var details = {
                        scopeData: {
                            markerColor: $scope.usersWithoutEvents.indexOf($scope.availableGpsDevices[deviceId].user.id) >= 0 ? $scope.userWithoutEventMarkerColor : null,
                            userDetails: $scope.availableGpsDevices[deviceId].user,
                            positionDetails: $scope.availableGpsDevices[deviceId].position
                        },
                        html: $diaryMapScope.mapElementTemplates['user_marker']
                    };

                    // Escaping for insufficient details
                    if (!details.scopeData.userDetails || !details.scopeData.positionDetails || $scope.mapTimeBounds['mode'] !== 'REAL_TIME') return;

                    details.markerDetails = {
                        latitude: $scope.availableGpsDevices[deviceId].position.latitude,
                        longitude: $scope.availableGpsDevices[deviceId].position.longitude,
                        markerIconClassName: 'leaflet-user-marker-icon',
                        markerSuffix: $scope.availableGpsDevices[deviceId].user.id
                    };

                    // Repositioning Marker
                    var markerName = 'Users_' + details.markerDetails.markerSuffix;

                    if (csMapper.markers[markerName]) {

                        csMapper.markers[markerName].scope.update(details.scopeData);
                        csMapper.moveMarker('Users', details.markerDetails);
                        csMapper.showMarker('Users', details.markerDetails.markerSuffix);

                    } else {
                        details.scopeData.destinationDetails = {
                            distance: 0,
                            duration: 0,
                            coordinate: null,
                            status: ''
                        };

                        var markerScope = $userMapMarkerScope.new($scope, details.scopeData);

                        // Drawing marker
                        markerName = csMapper.addDivMarker('Users', details.markerDetails, markerScope, details.html, null, [34, 104]);
                    }

                    drawnMarkers.push(markerName);
                }
            });

            // Filter out the markers based on selected users. On real time user location update, there is no need to filter the user markers.
            if (option !== 'REAL_TIME_LOCATION_UPDATE') {
                var userMarkerNameRegx = new RegExp('^Users_[0-9]+$');
                var markersInMap = Object.keys(csMapper.markers).filter(function (m) {
                    return userMarkerNameRegx.test(m);
                });

                // Hiding non-required markers
                markersInMap.forEach(function (m) {
                    if (drawnMarkers.indexOf(m) < 0) {
                        csMapper.hideMarker(m.split('_')[0], m.split('_')[1]);
                    }
                });
            }
        }


        // Pointing Jobs & Estimates on map
        function drawJobEstimateMarkers(option) {
            var userIdsToShowOnMap = $scope.usersToShowOnMap.map(function (u) {
                return u.id;
            });

            var eventToBeRendered = $scope.eventsToShowOnMap.filter(function (e) {
                return userIdsToShowOnMap.indexOf(e.resourceId) >= 0;
            });

            var eventsInMap = Object.keys(csMapper.markers).filter(function (m) {
                return m.startsWith('Jobs_event') || m.startsWith('Estimates_event');
            });

            // Show/Hide markers on user filtering
            if (option === 'userFiltering') {
                var projectedMarkerNames = eventToBeRendered.map(function (e) {
                    switch (e.type) {
                        case 'job':
                            return 'Jobs_event' + e.id;
                            break;
                        case 'estimate':
                            return 'Estimates_event' + e.id;
                            break;
                    }
                });
                eventsInMap.forEach(function (m) {
                    if (projectedMarkerNames.indexOf(m) < 0) csMapper.markers[m].marker.setOpacity(0);
                    else csMapper.markers[m].marker.setOpacity(1);
                });

                return;
            }

            // Cleaning up layers
            else if (option === 'eventsUpdated') {
                // csMapper.cleanOverlay('Jobs');
                // csMapper.cleanOverlay('Estimates');
                $scope.estimateJobDetails.drawn = false;
            }

            var drawnMarkers = [];
            var diaryColors = colorSelector.getColors('diary_color');

            // Rendering event markers
            eventToBeRendered.forEach(function (t) {
                var className = t.userColor ? t.userColor : '';
                var color = diaryColors.find(function (c) {
                    return c.color == className;
                });

                if (!color) color = '#3A6889';
                else color = color['rgba_dark'];

                var markerData = {
                    event: t,
                    layerName: '',
                    html: '',
                    details: {
                        latitude: t.otherDetails.latitude,
                        longitude: t.otherDetails.longitude,
                        markerIconClassName: '',
                        markerSuffix: 'event' + t.id
                    },
                    csColour: color

                };


                switch (t.type) {
                    case 'estimate':
                        markerData.layerName = 'Estimates';
                        markerData.html = $diaryMapScope.mapElementTemplates['job_estimate_marker'];
                        markerData.details.markerIconClassName = 'leaflet-estimate-marker-icon ';
                        break;
                    case 'job':
                        markerData.layerName = 'Jobs';
                        markerData.html = $diaryMapScope.mapElementTemplates['job_estimate_marker'];
                        markerData.details.markerIconClassName = 'leaflet-job-marker-icon ';
                        break;
                    default:
                        return;
                        break;
                }

                var markerName = markerData.layerName + '_' + markerData.details.markerSuffix;
                if (csMapper.markers[markerName]) {
                    csMapper.markers[markerName].scope.update({
                        diaryEvent: markerData.event,
                        csColour: markerData.csColour
                    });
                    drawnMarkers.push(markerName);
                }
                else drawnMarkers.push(draw(markerData));

            });

            eventsInMap = Object.keys(csMapper.markers).filter(function (m) {
                return m.startsWith('Jobs_event') || m.startsWith('Estimates_event');
            });

            // Deleting non-required markers
            eventsInMap.forEach(function (m) {
                if (drawnMarkers.indexOf(m) < 0) {
                    csMapper.deleteMarker(m.split('_')[0], m.split('_')[1]);
                }
            });


            function draw(d) {
                // Drawing marker
                if (d.layerName !== '') {
                    var markerScope = $jobEstimateMapMarkerScope.new($scope, {
                        diaryEvent: d.event,
                        csColour: d.csColour
                    });
                    markerScope.diaryEvent.start = new Date(markerScope.diaryEvent.start);
                    markerScope.diaryEvent.end = new Date(markerScope.diaryEvent.end);

                    var markerName = csMapper.addDivMarker(d.layerName, d.details, markerScope, d.html, null, [17, 41]);

                    return markerName;
                }

                return null;
            }
        }

        // Drawing polyline for user's travelled route
        function drawUserRoutes(result) {

            var dataArray = [];

            Object.keys(result).forEach(function (deviceId) {
                csMapper.addPoint('Users', [result[deviceId][0].latitude, result[deviceId][0].longitude], {
                    opacity: 0,
                    radius: 0.001
                });
                csMapper.addPoint('Users', [result[deviceId][result[deviceId].length - 1].latitude, result[deviceId][result[deviceId].length - 1].longitude], {
                    opacity: 0,
                    radius: 0.001
                });

                dataArray.push({
                    'user': $scope.availableGpsDevices[deviceId].user,
                    'points': result[deviceId].map(function (p) {
                        return [p.latitude, p.longitude]
                    }).filter(function (p) {
                        return p[0] !== 0 && p[1] !== 0;
                    })
                });
            });

            // Waiting for view adjust
            setTimeout(function () {

                // Asynchronous rendering
                dataArray.forEach(function (data) {
                    setTimeout(function () {
                        var content = '<span class="text">' + data.user.initial + '</span>';
                        var popupHtml = null;

                        csMapper.addRoute('Users', data.points, data.user.backgroundColorValue, data.user.id, content, popupHtml);
                    }, 1);

                });

            }, 2000);

        }

        // Show/Hide routes on user filtering
        function filterUserRoutes() {
            var projectedRouteNames = $scope.usersToShowOnMap.map(function (u) {
                return 'Users_' + u.id;
            });

            var routesInMap = Object.keys(csMapper.routes);

            routesInMap.forEach(function (m) {
                if (projectedRouteNames.indexOf(m) < 0) csMapper.hideRoute(m.split('_')[0], m.split('_')[1]);
                else csMapper.showRoute(m.split('_')[0], m.split('_')[1]);
            });

        }

        // Pointing Outstanding Job on map
        function drawOutstandingJobMarker() {

            switch ($scope.estimateJobDetails.source) {
                case "SIDE_PANEL": {
                    $scope.estimateJobDetails.canShow = false;
                    var markerSuffix = 'oj-' + $scope.estimateJobDetails.data.id;

                    var marker = csMapper.markerExists('DueWindowJobs', markerSuffix).marker,
                        layerName = 'InAction',
                        layer = csMapper.hasOverlay(layerName);

                    layer.bringToFront();
                    if (csMapper.markerExists('', 'focusMarker')) csMapper.removeFocusPointerMarker();
                    csMapper.cleanOverlay(layerName);

                    csMapper.hideOverlay('Jobs');
                    csMapper.hideOverlay('Estimates');
                    csMapper.hideOverlay('DueWindowJobs');
                    csMapper.showOverlay('Users');
                    csMapper.showOverlay(layerName);

                    marker.addTo(csMapper.hasOverlay(layerName));

                    setTimeout(function () {
                        marker.fire('click');

                        $timeout(function () {
                            csMapper.fitMapToMarkers(csMapper.markerExists('', 'focusMarker').marker.getLayers());
                        });
                    }, 11);

                    csMapper.addMapControl('setDefaultView', 'far fa-window-restore', $scope.setDefaultMapView);

                    break;
                }
                case "FOCUS_MARKER": {
                    break;
                }
                default: {
                    var markerAlias = 'outstandingJobEstimate',
                        layerName = $scope.estimateJobDetails.type === 'job' ? 'Jobs' : 'Estimates',
                        details = {
                            latitude: $scope.estimateJobDetails.latitude,
                            longitude: $scope.estimateJobDetails.longitude,
                            markerIconClassName: 'leaflet-pointer-marker-icon',
                            bindData: {event: $scope.estimateJobDetails.data}
                        },
                        callback = $scope.estimateJobDetails.callback;

                    if (!csMapper.markerExists(layerName, markerAlias))
                        csMapper.addPointingMarker(layerName, markerAlias, details, null, null, callback);

                    break;
                }

            }

            // Show nav suggestion
            if ($scope.userAgenda) {
                $scope.navSuggestion.userId = $scope.userAgenda;
                $scope.navSuggestion.showing = true;

                drawNavSuggestion([$scope.estimateJobDetails.latitude, $scope.estimateJobDetails.longitude]);
            }

        }

        // Drawing navigation paths for travelling devices
        function drawUserNavigationPaths(option, deviceIds = []) {
            if (!deviceIds.length) deviceIds = $scope.gpsDevicesToShowOnMap;

            var devicesInTravel = {};
            var drawnPaths = [];
            var destinationDetailsInterface = {
                distance: 0,
                duration: 0,
                coordinate: null,
                status: '',
                deviceId: null
            };

            var userIdsToShowOnMap = $scope.usersToShowOnMap.map(function (u) {
                return u.id;
            });

            deviceIds.forEach(function (deviceId) {
                var device = $scope.availableGpsDevices[deviceId];
                // Escaping for insufficient details
                if (!device.user
                    || !device.position
                    || $scope.mapTimeBounds['mode'] !== 'REAL_TIME') return;

                if ($scope.usersOnTravel[device.user.id] && userIdsToShowOnMap.indexOf(device.user.id) >= 0) {
                    devicesInTravel[deviceId] = {
                        from: [device.position.latitude, device.position.longitude],
                        to: $scope.usersOnTravel[device.user.id],
                        userId: device.user.id
                    };

                    // Update ETA status in marker
                    var scopeData = {
                        destinationDetails: {
                            coordinate: $scope.usersOnTravel[device.user.id],
                            status: 'FETCHING'
                        }
                    };

                    var marker = csMapper.markerExists('Users', device.user.id);
                    marker.scope.update(scopeData);
                }


            });

            // Just show/hide paths on user filtering
            if (option === 'userFiltering') {
                Object.keys(devicesInTravel).forEach(function (deviceId) {
                    var pathName = 'Users_navigation' + devicesInTravel[deviceId].userId;
                    if ($scope.gpsDevicesToShowOnMap.indexOf(parseInt(deviceId)) >= 0 && csMapper.navPaths[pathName]) {
                        var pathNameSigs = pathName.split('_');
                        csMapper.showNavigation(pathNameSigs[0], pathName[1]);
                        csMapper.markers['Users_' + devicesInTravel[deviceId].userId].scope.update({destinationDetails: csMapper.navPaths[pathName].details});
                        drawnPaths.push(pathName);
                        delete devicesInTravel[deviceId];
                    }
                });
            }

            // Drawing paths with updated data
            $gpsTracker.cancelGetNavigationsRequest();
            if (_.isEmpty(devicesInTravel)) {
                clean();
                $scope.userNavigations = true;
                return;
            }
            $gpsTracker.getNavigations(devicesInTravel).then(function (result) {

                Object.keys(result).forEach(function (deviceId) {
                    var destinationDetails = angular.copy(destinationDetailsInterface);
                    destinationDetails.coordinate = devicesInTravel[deviceId]['to'];
                    destinationDetails.deviceId = deviceId;
                    destinationDetails.userId = devicesInTravel[deviceId]['userId'];

                    if (result['error']) {
                        destinationDetails.status = 'NOT_FOUND';
                    } else {
                        csMapper.hideNavigation('Users', 'navigation' + destinationDetails.userId, true);
                        drawnPaths.push(csMapper.addNavigation(
                            'Users',
                            'navigation' + destinationDetails.userId,
                            result[deviceId]['geometry'],
                            $scope.availableGpsDevices[deviceId].user.backgroundColorValue,
                            destinationDetails));

                        destinationDetails.distance = result[deviceId]['distance'];
                        destinationDetails.duration = result[deviceId]['duration'];
                        destinationDetails.status = 'FOUND';

                    }

                    csMapper.markers['Users_' + destinationDetails.userId].scope.update({destinationDetails: destinationDetails});

                    if (option !== 'REAL_TIME_LOCATION_UPDATE') clean();
                    $scope.userNavigations = true;
                });

            }).catch(function (err) {
                console.log(err);
                $scope.userNavigations = true;
            });

            function clean() {
                var pathsInMap = Object.keys(csMapper.navPaths).filter(function (m) {
                    return m.startsWith('Users_navigation');
                });

                // Deleting non-required paths
                pathsInMap.forEach(function (p) {
                    if (drawnPaths.indexOf(p) < 0) {
                        var userId = csMapper.navPaths[p].details.userId;
                        csMapper.hideNavigation(p.split('_')[0], p.split('_')[1], true);
                        csMapper.markers['Users_' + userId].scope.update({destinationDetails: destinationDetailsInterface});
                    }
                });
            }

        }

        // Drawing a route suggestion for the given points
        function drawNavSuggestion(destination) {

            var userMarker = csMapper.markerExists('Users', $scope.navSuggestion.userId) || csMapper.markerExists('Users', 'ND-' + $scope.navSuggestion.userId);

            if (!userMarker) {
                $scope.navSuggestion.showing = false;
                csMapper.hideNavigation('Navigation', 'routeToOutstanding', true);
                return;
            }

            var startPosition = userMarker.marker.getLatLng();
            var origin = [startPosition.lat, startPosition.lng];

            if (!destination) destination = $scope.navSuggestion.destination;

            if (
                JSON.stringify($scope.navSuggestion.origin) != JSON.stringify(origin)
                || JSON.stringify($scope.navSuggestion.destination) != JSON.stringify(destination)
            ) {
                $scope.navSuggestion.origin = origin;
                $scope.navSuggestion.destination = destination;
                $gpsTracker.cancelGetNavigationsRequest('navSuggestionRequest');
                $gpsTracker.getNavigations({
                    'navSuggestion': {
                        from: $scope.navSuggestion.origin,
                        to: $scope.navSuggestion.destination
                    }
                }, 'navSuggestionRequest').then(function (result) {
                    if (!result.navSuggestion) return;

                    $scope.navSuggestion.data = result['navSuggestion'];
                    draw();
                }).catch(function (err) {
                    console.log(err);
                });
            } else {
                setTimeout(function () {
                    draw();
                }, 100);
            }

            function draw() {
                var data = $scope.navSuggestion.data;
                var distance = $filter('localLength')(data.distance, 'm', true);
                var duration = $filter('millisecToHms')(data.duration * 1000, 'named');
                var popupHtml = '<div class="inline-divider"><span class="ss-clock"></span> ' + duration + '</div><div><span class="ss-compass"></span> ' + distance + '</div>';

                csMapper.hideNavigation('Navigation', 'routeToOutstanding', true);
                csMapper.addNavigation('Navigation', 'routeToOutstanding', data['geometry'], 'rgb(168, 170, 173)', {}, popupHtml);

            }
        }

        // Change the marker color of the users without any diary events
        function setNoEventUsersMarkerColor(currentIds, prevIds) {

            currentIds.forEach(function (uId) {

                var u = $scope.usersToShowOnMap.find(function (u) {
                    return u.id === uId;
                });
                if (!u) return;

                var marker = csMapper.markerExists('Users', u.id),
                    markerName = 'Users_' + u.id;

                if (marker) {
                    marker.scope.update({markerColor: $scope.userWithoutEventMarkerColor});
                }
                else if (!marker) {
                    var scopeData = {
                            markerColor: $scope.userWithoutEventMarkerColor,
                            userDetails: u,
                            positionDetails: {},
                            positionSource: null,
                        },
                        markerHtml = $diaryMapScope.mapElementTemplates['user_marker'],
                        markerDetails = {
                            latitude: null,
                            longitude: null,
                            markerIconClassName: 'leaflet-user-marker-icon',
                            markerSuffix: "ND-" + u.id
                        }, locationObtained = false;

                    // If the user has any completed diary event
                    if ($diaryMapScope.usersEvents[u.id] instanceof Array) {

                        for (var i = 0; i < $diaryMapScope.usersEvents[u.id].length; i++) {
                            var dE = $diaryMapScope.usersEvents[u.id][i];
                            if (['left', 'completed'].indexOf(dE.status.status) >= 0 && csMapper.validateLatLng(dE.otherDetails.latitude, dE.otherDetails.longitude)) {
                                markerDetails.latitude = dE.otherDetails.latitude;
                                markerDetails.longitude = dE.otherDetails.longitude;

                                scopeData.positionSource = 'PROPERTY';
                                scopeData.positionDetails = {
                                    statusUpdated: dE.statusUpdated ? moment(dE.statusUpdated, 'ddd Do MMM YYYY HH:mm A')._d : null,
                                    customerName: dE.otherDetails.customerName,
                                    addressline: dE.otherDetails.addressline,
                                    addressline2: dE.otherDetails.addressline2,
                                    addressline3: dE.otherDetails.addressline3,
                                    county: dE.otherDetails.county,
                                    town: dE.otherDetails.town,
                                    postcode: dE.otherDetails.postcode,
                                    work_addrs_name: dE.otherDetails.work_addrs_name
                                };

                                locationObtained = true;
                                break;
                            }
                        }

                    }

                    // If the user has no completed diary events for the day
                    if (!locationObtained && csMapper.validateLatLng(u.latitude, u.longitude)) {
                        markerDetails.latitude = u.latitude;
                        markerDetails.longitude = u.longitude;

                        scopeData.positionSource = 'HOME';
                        scopeData.positionDetails = {};
                        locationObtained = true;
                    }


                    // Skip if no location details
                    if (!locationObtained) return;

                    marker = csMapper.markerExists('Users', markerDetails.markerSuffix);
                    markerName = 'Users_' + markerDetails.markerSuffix;

                    if (marker) {
                        marker.scope.update(scopeData);
                        csMapper.moveMarker('Users', markerDetails);
                        csMapper.showMarker('Users', markerDetails.markerSuffix);

                    }
                    else {

                        var markerScope = $userMapMarkerScope.new($scope, scopeData);

                        // Drawing marker
                        markerName = csMapper.addDivMarker('Users', markerDetails, markerScope, markerHtml, null, [34, 104]);
                    }
                }

                if (prevIds.indexOf(u.id) >= 0) {
                    prevIds.splice(prevIds.indexOf(u.id), 1);
                }
            });

            // Removing non-required markers
            if (prevIds.length) {
                var removedMarkerNameRegx = new RegExp('(Users_(' + prevIds.join("|") + '))|(Users_ND-(' + prevIds.join("|") + '))', 'g');
                var removedMarkersInMap = Object.keys(csMapper.markers).join('|').match(removedMarkerNameRegx) || [];

                removedMarkersInMap.forEach(function (m) {
                    var [layer, suffix] = m.split('_');
                    if (suffix.startsWith('ND')) {
                        csMapper.deleteMarker(layer, suffix);
                    } else {
                        csMapper.markerExists(layer, suffix).scope.update({markerColor: null})
                    }
                });
            }
        }

        // handle the height of the calendar
        function handleFullCalendarHeight() {
            return (window.innerHeight - 150);
        }

        function handleUserAgendaHeight() {
            return (window.innerHeight - 117);
        }

        function resizeUserAgenda() {
            $('#cs-diarymap-calendar').fullCalendar('option', 'contentHeight', handleUserAgendaHeight());
        }

        $(window).on('resize', resizeUserAgenda);

        // Removes events
        $scope.$on('$destroy', function () {
            $scope.contollerDestroyed = true;
            $diaryMapScope.resetMapInitializeWaiter();

            $scope.mapTimeBounds.mode === 'REAL_TIME' ? $gpsTracker.closeSocket() :
                $scope.mapTimeBounds.mode === 'BETWEEN_HOURS' ? $gpsTracker.cancelGetRouteRequest() : '';

            Object.keys($scope.foreignWatchers).forEach(function (n) {
                $scope.foreignWatchers[n]();
            });

            // Removing null values
            Object.keys($diaryMapScope.$$listeners).forEach(function (l) {
                $diaryMapScope.$$listeners[l] = $diaryMapScope.$$listeners[l].filter(function (f) {
                    return f !== null;
                })
            });
        });

        // Initializing Controller
        init();
    }];


/** DiaryMapRightbarCalendarOptionsCtrl declaration ------------------------------------------------------------------------ */

var DiaryMapRightbarCalendarOptionsCtrl = ['$rootScope', '$scope', '$http', 'uiCalendarConfig', '$diaryMapScope', '$timeout', function ($rootScope, $scope, $http, uiCalendarConfig, $diaryMapScope, $timeout) {
    $scope.mapMode = null;
    $scope.startTime = 0;
    $scope.endTime = 0;
    $scope.userToShowOnHistoricalView = null;
    $scope.calendarDate = (new Date()).toDateString();
    $scope.diaryIn = 'TODAY';
    $scope.foreignWatchers = {};
    $scope.historicalTimeValidationTooltip = {
        title: 'End time should be greater than start time',
        placement: 'left',
        container: 'body',
        trigger: 'manual',
        className: 'historical-time-error-message',
        animation: false
    };


    $scope.$on('calendarDateChanged', function (event, date) {
        var prevDate = moment($scope.calendarDate).format('YYYY/MM/DD');
        var newDate = moment(date).format('YYYY/MM/DD');

        if (newDate === prevDate) return;

        $scope.onDateChange(date);

    });

    // Actions on diary date change
    $scope.onDateChange = function (date) {
        var now = moment();
        var newDate = moment(date);

        $scope.calendarDate = date.toDateString();

        if (newDate.format('YYYY/MM/DD') === now.format('YYYY/MM/DD')) {
            $scope.diaryIn = 'TODAY';
            $scope.onModeChange('REAL_TIME');
        }
        else if (newDate._d < now._d) {
            $scope.diaryIn = 'PAST';
            $scope.onModeChange('BETWEEN_HOURS');
        }
        else {
            $scope.diaryIn = 'FUTURE';
            $scope.onModeChange('FUTURE');
        }
    };

    // Actions on map mode change
    $scope.onModeChange = function (newValue) {
        var oldValue = $scope.mapMode;
        $scope.mapMode = newValue;

        if (newValue === 'REAL_TIME') {
            // $scope.$emit('disableDatepicker', true);
            $scope.startTime = 0;
            $scope.endTime = 0;

        } else if (newValue === 'BETWEEN_HOURS' || newValue === 'FUTURE') {
            // $scope.$emit('disableDatepicker', false);

            var d = moment($scope.calendarDate);
            d.set({hours: 17, minutes: 0, seconds: 0, milliseconds: 0});
            $scope.endTime = d._d;

            $scope.startTime = moment($scope.endTime).subtract(9, 'hours')._d;
        }

        // Broadcasting for sibling controllers
        $scope.broadcastTimeBounds();
    };

    // Updating $diaryMapScope time bounds to publish the new value through the module
    $scope.broadcastTimeBounds = function () {

        if ($scope.mapMode === 'BETWEEN_HOURS' && (moment($scope.startTime).isAfter($scope.endTime) || moment($scope.startTime).isSame($scope.endTime))) {
            $scope.showHistoricalTimeValidation();
            return;
        }
        else {
            $scope.hideHistoricalTimeValidation();
        }

        if ($diaryMapScope.diaryMapRenderObservable.stages.indexOf('INITIATED') >= 0) broadcast();
        // Wait for map initialization
        else $diaryMapScope.diaryMapRenderObservable.promise.then(null, null, function (message) {
            if (message === 'INITIATED') broadcast();
        });

        function broadcast() {

            $diaryMapScope.setMapTimeBounds({
                mode: $scope.mapMode,
                duration: {
                    startTime: $scope.startTime,
                    endTime: $scope.endTime
                },
                date: moment($scope.calendarDate).format('YYYY-MM-DD'),
                userToShowOnHistoricalView: $scope.userToShowOnHistoricalView
            });

        }
    };

    $diaryMapScope.$on('mapTimeBounds:updated', function (event, bounds) {
        $scope.userToShowOnHistoricalView = bounds.userToShowOnHistoricalView;
    });

    $scope.showHistoricalTimeValidation = function () {
        if ($('#historical-start-time').data && $('#historical-start-time').data('tooltip')) $('#historical-start-time').tooltip('show');
    };

    $scope.hideHistoricalTimeValidation = function () {
        if ($('#historical-start-time').data && $('#historical-start-time').data('tooltip')) $('#historical-start-time').tooltip('hide');
    };

    $scope.$watch('currentView', function (newVal, oldVal) {
        if (newVal !== 'diaryMap') {
            $scope.hideHistoricalTimeValidation();
        } else if (newVal === 'diaryMap') {
            $timeout(function () {
                $scope.broadcastTimeBounds();
            });
        }
    });

    // Initializing $scope variables
    $timeout(function () {
        $scope.onDateChange($scope.diary_ctrl_scope.defaultDate._d);
    });

    // Removes events
    $scope.$on('$destroy', function () {

        Object.keys($scope.foreignWatchers).forEach(function (n) {
            $scope.foreignWatchers[n]();
        });

    });
}];

