'use strict';

var companySettingsModule = angular.module('company-settings', []);

companySettingsModule.constant('prefix', window.prefixVal).
    constant('defaultPagingValue', defaultPaginationLimit).
    provider('canLoad',function () {
        this.$get = function () {
            return {
                canLoadAjax: true,
                setLoadValue: function (canLoad) {
                    this.canLoadAjax = canLoad;
                },
                canLoad: function () {
                    return this.canLoadAjax;
                }
            }
        }
    }).
    config(
        ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
            function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {

                $stateProvider.
                    state('loggedin.company_settings', {
                        url: Routing.generateAngularRoute('company_settings', false),
                        templateUrl: function (params) {
                            return Routing.generate('company_settings')
                        },
                        template: globalTemplate,
                        controller: SettingsCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.companydetails', {
                        url: Routing.generateAngularRoute('company_details', true),
                        templateUrl: function (params) {
                            return Routing.generate('company_details')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.companynumbers', {
                        url: Routing.generateAngularRoute('companynumbers', false),
                        templateUrl: function (params) {
                            return Routing.generate('companynumbers')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.cis_percentage', {
                        url: Routing.generateAngularRoute('cis_percentage', true),
                        templateUrl: function (params) {
                            return Routing.generate('cis_percentage')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.sms_name', {
                        url: Routing.generateAngularRoute('sms_name', true),
                        templateUrl: function (params) {
                            return Routing.generate('sms_name')
                        },
                        template: globalTemplate,
                        controller: smsNameCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.holiday_year_end', {
                        url: Routing.generateAngularRoute('holiday_year_end', true),
                        templateUrl: function (params) {
                            return Routing.generate('holiday_year_end')
                        },
                        template: globalTemplate,
                        controller: HolidayYearEndCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.setup_email', {
                        url: Routing.generateAngularRoute('setup_email', false),
                        templateUrl: function (params) {
                            return Routing.generate('setup_email')
                        },
                        data: {
                            'setupEmailUrl': '/listSetupEmail',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: SetupEmailCtrl,
                                resolve: OpenIdResolver
                            },
                            "setupEmailSmtpPane@": {
                                templateUrl: 'template/settings/setupemails/list_setupEmail.html',
                                controller: SetupEmailListEditCtrl,

                                resolve: {
                                    setupEmailresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.setupEmailUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.setup_email.edit', {
                        params: ['id', 'emailaddress', 'password','username','serveraddress', 'portnumber', 'securitytype', 'pageNum', 'limit'],
                        views: {
                            "setupEmailSmtpPane@": {
                                templateUrl: 'template/settings/setupemails/edit_setupEmail.html',
                                controller: SetupEmailEditCtrl
                            }
                        }

                    }).
                    state('loggedin.create_letterhead', {
                        url: Routing.generateAngularRoute('create_letterhead', true),
                        templateUrl: function (params) {
                            return Routing.generate('create_letterhead')
                        },
                        views: {
                            "": {
                                templateUrl: 'template/settings/letterheads/letterhead.html',
                                controller: NewLetterheadCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                state('loggedin.default_PDF_font_size', {
                    url: Routing.generateAngularRoute('default_PDF_font_size', true), 
                    templateUrl: function (params) {
                        return Routing.generate('default_PDF_font_size')
                    },
                    controller: RootCtrl,
                    resolve: Resolver
                }).
                    state('loggedin.new_letterhead', {
                        url: Routing.generateAngularRoute('new_letterhead', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_letterhead', params)
                        },
                        views: {
                            "": {
                                templateUrl: 'template/settings/letterheads/letterhead.html',
                                controller: NewLetterheadCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.upload_certificate_logo', {
                        url: Routing.generateAngularRoute('upload_certificate_logo', true),
                        templateUrl: function (params) {
                            return Routing.generate('upload_certificate_logo')
                        },
                        template: globalTemplate,
                        controller: CompanySettingsUploadLogoCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.bankdetails', {
                        url: Routing.generateAngularRoute('bankdetails', true),
                        templateUrl: function (params) {
                            return Routing.generate('bankdetails')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.cheques', {
                        url: Routing.generateAngularRoute('cheques', true),
                        templateUrl: function (params) {
                            return Routing.generate('cheques')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.import_properties', {
                        url: Routing.generateAngularRoute('import_properties', false),
                        templateUrl: function (params) {
                            return Routing.generate('import_properties')
                        },
                        template: globalTemplate,
                        controller: SettingsImportDataCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.branding_colours', {
                        url: Routing.generateAngularRoute('branding_colours', false),
                        templateUrl: function (params) {
                            return Routing.generate('branding_colours')
                        },
                        template: globalTemplate,
                        controller: BrandingColoursCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.billing', {
                        url: Routing.generateAngularRoute('billing', false),
                        templateUrl: function (params) {
                            return Routing.generate('billing')
                        },
                        views: {
                            "": {
                                templateUrl: 'template/settings/billing/index.html',
                                controller: BillingCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.purchasetrackers', {
                        url: Routing.generateAngularRoute('purchasetrackers', false),
                        templateUrl: function (params) {
                            return Routing.generate('purchasetrackers')
                        },
                        views: {
                            "": {
                                templateUrl: 'template/settings/purchasetrackers/index.html',
                                controller: PurchaseTrackersCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.users', {
                        url: Routing.generateAngularRoute('users', true),
                        templateUrl: function (params) {
                            return Routing.generate('users')
                        },
                        data: {
                            'usersUrl': '/listUsers',
                            'pageNum': 1,
                            'limit': 10,
                            'usersTab': 0
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: UsersCtrl,
                                resolve: {
                                    datasets: Resolver.datasets,
                                    skills_data: SkillsResolver.skills_data,
                                    public_holiday_group: PublicholidayGroupsResolver.public_holiday_group
                                }
                            },
                            "configurePane@": {
                                templateUrl: 'template/settings/users/index.html',
                                controller: UsersListCtrl,
                                resolve: {
                                    usersresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.usersUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit + '&selectedTab=' + this.data.usersTab).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            },
                            "editPane@": {
                                template: globalTemplate
                            }
                        }
                    }).
                    state('loggedin.add_user', {
                        url: Routing.generateAngularRoute('add_user', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_user')
                        },
                        template: globalTemplate,
                        controller: AddUserCtrl,
                        resolve: {
                            datasets: Resolver.datasets,
                            skills_data: SkillsResolver.skills_data,
                            public_holiday_group: PublicholidayGroupsResolver.public_holiday_group
                        }
                    }).state('loggedin.users.view', {
                        url: Routing.generateAngularRoute('user_subnav', true),
                        templateUrl: function (params) {
                            return Routing.generate('user_subnav', params)
                        },
                        views: {
                            "configurePane@": {
                                templateUrl: 'template/settings/users/user_subnav.html',
                                controller: UsersSubNavCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.users.view.details', {
                        url: Routing.generateAngularRoute('users_details', true),
                        templateUrl: function (params) {
                            return Routing.generate('users_details', params)
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: 'template/settings/users/user_details.html',
                                controller: ConfigureUserDetailsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.users.edit', {
                        params: ['screen', 'userDetails', 'skills_data','public_holiday_group'],
                        views: {
                            "configurePane@": {
                                templateUrl: 'template/settings/users/edit_index.html',
                                controller: EditDetailsCtrl
                            }
                        }
                    }).
                    state('loggedin.users.view.contacts', {
                        url: Routing.generateAngularRoute('emergency_contacts', true),
                        templateUrl: function (params) {
                            return Routing.generate('emergency_contacts', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: 'template/settings/users/emergency_contacts.html',
                                controller: EmergencyContactCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    // reminders screen
                    state('loggedin.users.view.reminders', {
                        url: Routing.generateAngularRoute('users_reminders', true),
                        templateUrl: function (params) {
                            return Routing.generate('users_reminders', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: 'template/settings/users/users_reminders.html',
                                controller: UsersRemindersCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.users.view.new_users_reminder', {
                        url: Routing.generateAngularRoute('add_new_users_reminder', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_new_users_reminder', params)
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: function(params) {
                                    return Routing.generate('new_users_reminder', params);
                                },
                                controller: AddUserReminderCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.users.view.edit_user_reminder', {
                        url: Routing.generateAngularRoute('edit_user_reminder_url', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_user_reminder_url', params)
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: 'template/settings/users/edit_user_reminder.html',
                                controller: EditUserReminderCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.users.view.new_emergency_contact', {
                        url: Routing.generateAngularRoute('add_new_emergency_contact', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_new_emergency_contact', params)
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: function(params) {
                                    return Routing.generate('new_emergency_contact', params);
                                },
                                controller: AddEmergencyContactCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.users.view.edit_emergency_contact', {
                        url: Routing.generateAngularRoute('edit_emergency_contact_url', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_emergency_contact_url', params)
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: 'template/settings/users/edit_emergency_contact.html',
                                controller: EditEmergencyContactCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.users.view.mobile', {
                        url: Routing.generateAngularRoute('mobile_setting', true),
                        templateUrl: function (params) {
                            return Routing.generate('mobile_setting', params)
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('mobile_settings', params)
                                },
                                controller: MobileSettingsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.users.view.files', {
                        url: Routing.generateAngularRoute('get_user_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('get_user_files', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: 'template/settings/users/attached_files.html',
                                controller: AttachedFilesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.users.view.new_file', {
                        url: Routing.generateAngularRoute('user_new_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('user_new_files', params)
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: 'template/settings/users/new_file.html',
                                controller: AddFileCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.users.view.notes', {
                        url: Routing.generateAngularRoute('user_notes', true),
                        templateUrl: function (params) {
                            return Routing.generate('user_notes', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: 'template/settings/users/user_notes.html',
                                controller: UserNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.users.view.new_note', {
                        url: Routing.generateAngularRoute('add_user_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_user_note', params)
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_user_note', params)
                                },
                                controller: NewNoteCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.users.view.edit_user_note', {
                        url: Routing.generateAngularRoute('get_user_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('get_user_note', params)
                        },
                        views: {
                            "configureTabPane@": {
                                templateUrl: 'template/settings/users/edit_note.html',
                                controller: EditNoteCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).state('loggedin.users.transfer_gps_device', {
                        url: '/:userId/transfer_gps_device',
                        views: {
                            "configurePane@": {
                                templateUrl: '/template/settings/users/transfer_gps_device.html',
                                resolve: TransferGpsDeviceResolver,
                                controller: UserTransferGpsDeviceCtrl

                            }
                        }
                    }).state('loggedin.vehicles_parameter', {
                        url: Routing.generateAngularRoute('vehicles_parameter', false),
                        templateUrl: function (params) {
                            return Routing.generate('vehicles_parameter')
                        },
                        data: {
                            'vehicleParamtersUrl': '/listVehicleParamters',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: VehicleParamtersCtrl,
                                resolve: Resolver
                            },
                            "vehicleParameterPane@": {
                                templateUrl: 'template/settings/vehicleparameters/list_vehicleparameters.html',
                                controller: VehicleParamtersListEditCtrl,

                                resolve: {
                                    vehicleparameterresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.vehicleParamtersUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.vehicles_parameter.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "vehicleParameterPane@": {
                                templateUrl: 'template/settings/vehicleparameters/edit_vehicleparameters.html',
                                controller: VehicleParameterEditCtrl
                            }
                        }
                    }).
                    state('loggedin.audits', {
                        url: Routing.generateAngularRoute('audits', true),
                        templateUrl: function (params) {
                            return Routing.generate('audits')
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: AuditsCtrl,
                                resolve: Resolver
                            },
                            "auditPane@": {
                                templateUrl: 'template/audits/default.html',
                                controller: RootCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.audits.view', {
                        url: Routing.generateAngularRoute('audits_view', true),
                        templateUrl: function (params) {
                            return Routing.generate('audits_view', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "auditPane@": {
                                templateUrl: 'template/audits/view.html',
                                controller: AuditsViewCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.contract', {
                        url: Routing.generateAngularRoute('contract', true),
                        templateUrl: function (params) {
                            return Routing.generate('contract')
                        },
                        template: globalTemplate,
                        controller: PdfShowCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.api_keys', {
                        url: Routing.generateAngularRoute('api_keys', false),
                        templateUrl: function (params) {
                            return Routing.generate('api_keys')
                        },
                        data: {
                            'apiKeysUrl': '/listApiKeys',
                            'pageNum': 1,
                            'editedId': -1,
                            'limit': 10
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: RootCtrl,
                                resolve: Resolver
                            },
                            "apiKeysPane@": {
                                templateUrl: 'template/settings/apikeys/index_apikeys.html',
                                controller: ApiKeysListEditCtrl,

                                resolve: {
                                    apikeysresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.apiKeysUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.credit_card', {
                        url: Routing.generateAngularRoute('credit_card', true),
                        templateUrl: function (params) {
                            return Routing.generate('credit_card')
                        },
                        template: globalTemplate,
                        controller: CreditCardCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.manage_backups', {
                        url: Routing.generateAngularRoute('manage_backups', true),
                        templateUrl: function (params) {
                            return Routing.generate('manage_backups')
                        },
                        template: globalTemplate,
                        controller: ManageBackupsCtrl,
                        resolve: OpenIdResolver
                    }).
                    state('google_drive', {
                        url: '/google_drive',
                        templateUrl: function (params) {
                            return Routing.generate('google_drive')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: OpenIdResolver
                    }).
                    state('loggedin.email_signature_message', {
                        url: Routing.generateAngularRoute('email_signature_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('email_signature_message')
                        },
                        template: globalTemplate,
                        controller: CertificateEmailMessageCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.travel_rate', {
                        url: Routing.generateAngularRoute('travel_rate', true),
                        templateUrl: function (params) {
                            return Routing.generate('travel_rate')
                        },
                        template: globalTemplate,
                        controller: TravelRateCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.accounting_interfaces', {
                        url: Routing.generateAngularRoute('accounting_interfaces', true),
                        templateUrl: function (params) {
                            return Routing.generate('accounting_interfaces')
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: AccountingInterfaceCtrl,
                                resolve: Resolver
                            },
                            "aiPane@": {
                                templateUrl: 'template/accounting_interface/dashboard/view.html',
                                controller: AccountingInterfaceDashboardCtrl
                            }
                        }
                    }).
                    state('loggedin.accounting_interfaces.notifications', {
                        url: Routing.generateAngularRoute('accounting_interfaces_notifications_subnav', true),
                        templateUrl: function (params) {
                            return Routing.generate('accounting_interfaces_notifications_subnav', params)
                        },
                        views: {
                            "aiPane@": {
                                templateUrl: 'template/accounting_interface/notifications/view.html',
                                controller: AccountingIntegrationNotificationsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.accounting_interfaces.notifications_new', {
                        url: Routing.generateAngularRoute('accounting_interfaces_notifications_new', true),
                        templateUrl: function (params) {
                            return Routing.generate('accounting_interfaces_notifications_new', params)
                        },
                        views: {
                            "aiPane@": {
                                templateUrl: 'template/accounting_interface/notifications/new.html',
                                controller: AddNotificationContactCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.accounting_interfaces.do_not_send', {
                        url: Routing.generateAngularRoute('accounting_interfaces_do_not_send_subnav', true),
                        templateUrl: function (params) {
                            return Routing.generate('accounting_interfaces_do_not_send_subnav', params)
                        },
                        views: {
                            "aiPane@": {
                                templateUrl: 'template/accounting_interface/do_not_send/view.html',
                                controller: AccountingInterfaceDoNotSendCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.accounting_interfaces.logs', {
                        url: Routing.generateAngularRoute('accounting_interfaces_logs_subnav', true),
                        templateUrl: function (params) {
                            return Routing.generate('accounting_interfaces_logs_subnav', params)
                        },
                        views: {
                            "aiPane@": {
                                templateUrl: 'template/accounting_interface/logs/logs.html',
                                controller: AccountingInterfaceLogsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.accounting_interfaces.link', {
                        url: Routing.generateAngularRoute('accounting_interfaces_link_subnav', true),
                        templateUrl: function (params) {
                            return Routing.generate('accounting_interfaces_link_subnav', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "aiPane@": {
                                templateUrl: 'template/accounting_interface/link/edit.html',
                                controller: AccountingIntegrationReferencesCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.accounting_interfaces.resend', {
                        url: Routing.generateAngularRoute('accounting_interfaces_resend_subnav', true),
                        templateUrl: function (params) {
                            return Routing.generate('accounting_interfaces_resend_subnav', params)
                        },
                        views: {
                            "aiPane@": {
                                templateUrl: 'template/accounting_interface/resend/edit.html',
                                controller: AccountingInterfaceResendCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.client_payment_required', {
                        url: Routing.generateAngularRoute('client_payment_required', false),
                        templateUrl: function (params) {
                            return Routing.generate('client_payment_required')
                        },
                        views: {
                            "": {
                                templateUrl: 'template/payment_details.html',
                                controller: MakePaymentCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.client_show_creditcard', {
                        url: Routing.generateAngularRoute('client_show_creditcard', false),
                        templateUrl: function (params) {
                            return Routing.generate('client_show_creditcard')
                        },
                        views: {
                            "": {
                                templateUrl: 'template/creditcard_form.html',
                                controller: AddCreditCardDetails,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.client_show_ddmandate', {
                        url: Routing.generateAngularRoute('client_show_ddmandate', false),
                        templateUrl: function (params) {
                            return Routing.generate('client_show_ddmandate')
                        },
                        views: {
                            "": {
                                templateUrl: 'template/afterlogin/directdebitmandate.html',
                                controller: ShowDDMandate,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.show_contract', {
                        url: Routing.generateAngularRoute('show_contract', false),
                        templateUrl: function (params) {
                            return Routing.generate('show_contract')
                        },
                        views: {
                            "": {
                                templateUrl: 'template/show_contract.html',
                                controller: ShowContractCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('stripe_connect', {
                        url: '/stripe_connect',
                        templateUrl: function (params) {
                            return Routing.generate('stripe_connect')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: OpenIdResolver
                    }).
                state('loggedin.set_currency',{
                    url: Routing.generateAngularRoute('set_currency', true),
                    templateUrl: function (params) {
                        return Routing.generate('set_currency')
                    },
                    views: {
                        "": {
                            templateUrl: 'template/list_currency.html',
                            controller: SetCurrencyCtrl,
                            resolve: Resolver
                        }
                    }

                }).state('loggedin.public_holiday_calender', {
                    url: Routing.generateAngularRoute('public_holiday_calender', true),
                    templateUrl: function () {
                        return Routing.generate('public_holiday_calender')
                    },
                    views: {
                        "": {
                            templateUrl: 'template/settings/publicholidaycalendar/publicholidaycalendar.html',
                            controller: ShowPublicHolidayCalenderCtrl,
                            resolve: publicHolidayCalendarResolver
                        }
                    }
                }).state('loggedin.public_holiday_groups', {
                    url: Routing.generateAngularRoute('public_holiday_groups', false),
                    templateUrl: function (params) {
                        return Routing.generate('public_holiday_groups')
                    },
                    data: {
                        'PublicHolidayNamesGroupUrl': '/listPublicholidayNames',
                        'pageNum': 1,
                        'editedId': -1,
                        'addedId': -1,
                        'limit': 5
                    },
                    views: {
                        "": {
                            template: globalTemplate,
                            controller: PublicHolidayGroupsCtrl,
                            resolve: Resolver
                        },
                        "publicHolidayGroupsPane@": {
                            templateUrl: 'template/settings/publicholidaygroups/list_publicholidaygroups.html',
                            controller: PublicHolidayGroupsListEditCtrl,

                            resolve: {
                                publicHoildayGroupsresolver: function ($http, $q, $state, prefix) {
                                    var deferred = $q.defer();
                                    $http.get(prefix + this.data.PublicHolidayNamesGroupUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                        deferred.resolve(data);
                                    })
                                    return deferred.promise;
                                }
                            }
                        }
                    }
                }).state('loggedin.public_holiday_groups.edit', {
                    params: ['id', 'name', 'year', 'pageNum', 'limit'],
                    views: {
                        "publicHolidayGroupsPane@": {
                            templateUrl: 'template/settings/publicholidaygroups/edit_publicholidaygroups.html',
                            controller: PublicHolidayGroupsEditCtrl
                        }
                    }
                }).state('loggedin.public_holiday_groups_configure', {
                    url: Routing.generateAngularRoute('public_holiday_groups_configure', true),
                    templateUrl: function (params) {
                        return Routing.generate('public_holiday_groups_configure', params)
                    },
                    data: {
                        'pageNum': 1,
                        'limit': 5
                    },
                    views: {
                        "": {
                            templateUrl: function (params) {
                                return Routing.generate('new_public_holiday_group_dates', params)
                            },
                            controller: ConfigurePublicHolidayDateCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                                }).state('gocardless_connect', {
                        url: '/gocardless_connect',
                        templateUrl: function (params) {
                            return Routing.generate('gocardless_connect')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: OpenIdResolver
                }).state('loggedin.settings', {
                        url: '/settings',
                                });

            }
        ]);
