function LocationCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.locationForm['location[description]'].$error.checkExists = true;
            } else {
                $scope.locationForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("LOCATION_ADDED", { 'id': data.id, 'location': data.description});
            }
            $scope.saving = false;
        });
    }
}

function LocationListEditCtrl($scope, $state, prefix, $http, locationresolver, defaultPagingValue, warningModal) {
    $scope.locations = locationresolver.locations;
    $scope.totalLocations = locationresolver.count;
    $scope.shouldBeOpen = false;

    JustAddedUpdatedChanges.call(this, $scope, 'location', $state, defaultPagingValue, 'locations');

    $scope.triggerDelete = function (id) {
        $http.get(prefix + '/check_delete_location?id=' + id).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.fetchLocations = function (pageNum) {
        $http.get(prefix + '/listLocations?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.locations = data.locations;
            $scope.totalLocations = data.count;
            locationresolver.locations = data.locations;
            locationresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }

    $scope.$on("LOCATION_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, locationresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.locations.push({'id': message.id, 'description': message.location, 'justadded': true});
            locationresolver.locations = $scope.locations;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        locationresolver.count = parseInt(locationresolver.count) + 1
        $scope.totalLocations = locationresolver.count;

    });

    $scope.editLocation = function (id, location) {
        $state.transitionTo('loggedin.location.edit', {'id': id, 'selectedLocation': location, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }
}

function LocationEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedLocation = $state.params.selectedLocation;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.location");
    }

    $scope.editLocation = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $http.post(prefix + '/edit_location', "location[id]=" + this.selectedId + "&location[description]=" + encodeURIComponent(this.selectedLocation)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editLocationForm['selectedLocation'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.location");
                    }
                }
            });
    }
}

function FuelTypeCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.fuelTypeForm['fueltype[description]'].$error.checkExists = true;
            } else {
                $scope.fuelTypeForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("FUELTYPE_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function FuelTypeEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedFuelType = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.fueltype");
    }

    $scope.editFuelTypes = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_fueltype', "fueltype[id]=" + this.selectedId + "&fueltype[description]=" + encodeURIComponent(this.selectedFuelType)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editFuelTypeForm['selectedFuelType'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.fueltype");
                    }
                }
            });
    }
}

function FuelTypeListEditCtrl($scope, $state, prefix, $http, fuelTypesresolver, defaultPagingValue, warningModal) {
    $scope.fuelTypes = fuelTypesresolver.fuelTypes;
    $scope.totalFuelTypes = fuelTypesresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;


    JustAddedUpdatedChanges.call(this, $scope, 'fuelType', $state, defaultPagingValue, 'fuelTypes');


    $scope.$on("FUELTYPE_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, fuelTypesresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.fuelTypes.push({'id': message.id, 'description': message.description, 'justadded': true});
            fuelTypesresolver.fuelTypes = $scope.fuelTypes;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        fuelTypesresolver.count = parseInt(fuelTypesresolver.count) + 1
        $scope.totalFuelTypes = fuelTypesresolver.count;
    });


    $scope.editFuelType = function (id, description) {
        $state.transitionTo('loggedin.fueltype.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id) {
        $http.get(prefix + '/is_fuel_type_delete?id=' + id).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.fetchFuelTypes = function (pageNum) {
        $http.get(prefix + '/listFueltypes?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.fuelTypes = data.fuelTypes;
            $scope.totalFuelTypes = data.count;
            fuelTypesresolver.fuelTypes = data.fuelTypes;
            fuelTypesresolver.count = data.count;

            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function ApplianceGroupsCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.applianceGroupForm['appliancegroup[description]'].$error.checkExists = true;
            } else {
                $scope.applianceGroupForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("APPLIANCEGROUP_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function ApplianceGroupsEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedApplianceGroups = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.appliance_groups");
    }

    $scope.editAppliance = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_applianceGroups', "appliancegroup[id]=" + this.selectedId + "&appliancegroup[description]=" + encodeURIComponent(this.selectedApplianceGroups)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editApplianceGroupForm['selectedApplianceGroups'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.appliance_groups");
                    }
                }
            });
    }
}

function ApplianceGroupsListEditCtrl($scope, $state, prefix, $http, appliancegroupsresolver, defaultPagingValue, warningModal) {
    $scope.applianceGroups = appliancegroupsresolver.applianceGroups;
    $scope.totalApplianceGroups = appliancegroupsresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.canDelete = true;

    JustAddedUpdatedChanges.call(this, $scope, 'applianceGroup', $state, defaultPagingValue, 'applianceGroups');


    $scope.$on("APPLIANCEGROUP_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, appliancegroupsresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.applianceGroups.push({'id': message.id, 'description': message.description, 'justadded': true});
            appliancegroupsresolver.applianceGroups = $scope.applianceGroups;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        appliancegroupsresolver.count = parseInt(appliancegroupsresolver.count) + 1
        $scope.totalApplianceGroups = appliancegroupsresolver.count;

    });

    $scope.editApplianceGroup = function (id, description) {
        $state.transitionTo('loggedin.appliance_groups.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, description) {
        $scope.selectedId = id;
        $scope.invoiceRecord= description;
        $http.get(prefix + '/is_appliance_group_delete?id=' + id).success(function (data) {
            if(data.warning === true) {
                $scope.canDelete = false;
            }  else {
                $scope.canDelete = true;
            }
            $scope.shouldBeOpen = true;
        });
    }

    $scope.fetchApplianceGroups = function (pageNum) {
        $http.get(prefix + '/listApplianceGroups?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.applianceGroups = data.applianceGroups;
            $scope.totalApplianceGroups = data.count;
            appliancegroupsresolver.applianceGroups = data.applianceGroups;
            appliancegroupsresolver.count = data.count;

            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function ApplianceTypesCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.applianceTypeForm['appliancetypes[description]'].$error.checkExists = true;
            } else {
                $scope.applianceTypeForm.$setPristine();
                $scope.settingsAppliancegroupsid = "";
                $scope.description = "";
                $scope.$broadcast("APPLIANCE_TYPE", { 'id': data.id, 'description': data.description, 'settingsAppliancegroupsDescription': data.settingsAppliancegroupsDescription, 'applianceGroupId': data.applianceGroupId });
            }
            $scope.saving = false;
        });
    }
}

function ApplianceTypesEditCtrl($scope, $state, $http, prefix, appliancetypesresolver, defaultPagingValue) {
    $scope.selectedApplianceType = $state.params.description;
    $scope.selectedApplianceGroup = $state.params.settingsAppliancegroupsDescription;
    $scope.applianceGroupId = parseInt($state.params.applianceGroupId);
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.appliancegroupslist = appliancetypesresolver.appliancegroupslist;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.appliance_type");
    }

    $scope.editApplianceType = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_appliancetype', "appliancetypes[id]=" + this.selectedId + "&appliancetypes[description]=" + encodeURIComponent(this.selectedApplianceType) + "&appliancetypes[settingsAppliancegroupsid]=" + this.applianceGroupId).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editApplianceTypeForm['selectedApplianceType'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.appliance_type");
                    }
                }
            });
    }
}

function ApplianceTypesEditListCtrl($scope, $state, prefix, $http, $timeout, appliancetypesresolver, defaultPagingValue, canLoad, $q, warningModal) {
    $scope.appliancetypes = appliancetypesresolver.appliancetypes;
    $scope.totalApplianceTypes = appliancetypesresolver.count;
    $scope.appliancegroupslist = appliancetypesresolver.appliancegroupslist;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.selectedGroup = '';
    $scope.searchResult = false;
    $scope.show_filters = true;
    $scope.canDelete = true;

    JustAddedUpdatedChanges.call(this, $scope, 'appliancetype', $state, defaultPagingValue, 'appliancetypes');

    $scope.change = function () {
        $scope.currentPage = 1;
        $scope.fetchApplianceTypes($scope.currentPage, this.selectedGroup);
    }

    $scope.blurCallback = function () {
        $scope.focused = false;

        $timeout(function() {
          $scope.show_filters = true;
        }, 700);
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.currentPage = 1;
            $scope.fetchApplianceTypes($scope.currentPage);
        }
    });

    var canceler = null;
    $scope.fetchApplianceTypes = function (pageNum, selectedGroup) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;

        if (searchText) {
            $scope.closeDelt = false;
        }
        else {
            $scope.closeDelt = true;
        }

        var selGroup = selectedGroup || $scope.selectedGroup;
        $http.get(prefix + '/listApplianceType?page=' + pageNum + '&group=' + selGroup + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.appliancetypes = data.appliancetypes;
            $scope.selectedGroup = selGroup;
            $scope.totalApplianceTypes = data.count;
            appliancetypesresolver.appliancetypes = data.appliancetypes;
            appliancetypesresolver.count = data.count;


            if ((searchText != '') || (selGroup != '')) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }

            $scope.triggerJustAddedUpdatedTags();
        })
    }


    $scope.$on("APPLIANCE_TYPE", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, appliancetypesresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.appliancetypes.push({'id': message.id, 'description': message.description, 'settingsAppliancegroupsDescription': message.settingsAppliancegroupsDescription, 'applianceGroupId': message.applianceGroupId, 'justadded': true});
            appliancetypesresolver.appliancetypes = $scope.appliancetypes;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        appliancetypesresolver.count = parseInt(appliancetypesresolver.count) + 1
        $scope.totalApplianceTypes = appliancetypesresolver.count;
    });

    $scope.triggerDelete = function (id, description) {
        $scope.invoiceRecord= description;
        $scope.selectedId = id;
        $http.get(prefix + '/is_appliance_type_delete?id=' + id).success(function(data) {
            if (data.warning === true) {
                $scope.canDelete = false;
            }else{
                $scope.canDelete = true;
            }
            $scope.shouldBeOpen = true;
        });
    }

    $scope.editAppliancetype = function (appliancetype) {
        $state.transitionTo('loggedin.appliance_type.edit',
            {'id': appliancetype.id,
                'description': appliancetype.description,
                'settingsAppliancegroupsDescription': appliancetype.settingsAppliancegroupsDescription,
                'applianceGroupId': appliancetype.applianceGroupId,
                'pageNum': $scope.currentPage, 'limit': $scope.limit
            })
    }
}

function ApplianceMakesCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.opened = false;

    $scope.showAdvancedOption = function () {
        $scope.opened = !$scope.opened;
    }

    $scope.showAdvancedOption = function () {
        $scope.opened = !$scope.opened;
    }

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.applianceForm['appliancemake[description]'].$error.checkExists = true;

            } else {
                $scope.applianceForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("APPLIANCEMAKES_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function ListApplianceMakesCtrl($scope, $state, prefix, $http, appliancemakesresolver, defaultPagingValue, canLoad, $q, warningModal) {
    $scope.makes = appliancemakesresolver.makes;
    $scope.totalMakes = appliancemakesresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.searchText = '';

    JustAddedUpdatedChanges.call(this, $scope, 'make', $state, defaultPagingValue, 'makes');

    $scope.$on("APPLIANCEMAKES_ADDED", function (event, message) {
        $scope.searchText = '';
        GetCurrentPage.call(this, $scope, $state, appliancemakesresolver, message);

        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.makes.push({'id': message.id, 'description': message.description, 'justadded': true});
            appliancemakesresolver.makes = $scope.makes;
            $state.current.data.addedId = -1;

        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        appliancemakesresolver.count = parseInt(appliancemakesresolver.count) + 1;
        $scope.totalMakes = appliancemakesresolver.count;

    });

    $scope.editMakes = function (id, description) {
        $state.transitionTo('loggedin.appliance_makes.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id) {
        $http.get(prefix + '/is_appliance_make_delete?id=' + id).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.blurCallback = function () {
        $scope.focused = false;

    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if ((newVal != undefined) && (newVal != oldVal)) {
            $scope.currentPage = 1;
            $scope.fetchMakes($scope.currentPage);
        }
    });
    var canceler = null;
    $scope.fetchMakes = function (pageNum) {

        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;

        if (searchText) {
            $scope.closeDelt = false;
        }
        else {
            $scope.closeDelt = true;
        }

        $http.get(prefix + '/list_ApplianceMakes?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.makes = data.makes;
            $scope.totalMakes = data.count;

            appliancemakesresolver.makes = $scope.makes;
            appliancemakesresolver.count = $scope.totalMakes;

            if (searchText != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
            $scope.triggerJustAddedUpdatedTags();

        })
    }
}

function ApplianceMakesEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedMakes = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.appliance_makes");
    }

    $scope.editMakes = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_appliancemakes', "appliancemake[id]=" + this.selectedId + "&appliancemake[description]=" + encodeURIComponent(this.selectedMakes)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editMakesForm['selectedMakes'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.appliance_makes");
                    }
                }
            });
    }
}

function ApplianceModelsCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.showAdvancedOption = function () {
        $scope.opened = !$scope.opened;
    }

    $scope.checkApplianceModelsExists = function (applianceModel,applianceGroup, FuelType, applianceMake) {
        $http.get(prefix + '/applianceModelsExists?description='+ applianceModel + '&applianceGroupId=' + applianceGroup +'&fuelTypeId=' + FuelType + '&applianceMakeId=' + applianceMake).success(function(data){
            if(data == "true"){
                $scope.applianceModelsForm['appliancemodel[description]'].$error.checkExists = true;
            }else{
                $scope.applianceModelsForm['appliancemodel[description]'].$error.checkExists = false;
            }
        });
    }

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.applianceModelsForm['appliancemodel[description]'].$error.checkExists = true;
            } else {
                $scope.applianceModelsForm.$setPristine();
                $scope.description = "";
                $scope.groupType = "";
                $scope.appliancemakeid = "";
                $scope.settingsfueltypesid = "";
                $scope.$broadcast("Appliance_Model", { 'id': data.id, 'description': data.description, 'settingsTypesDescription': data.settingsTypesDescription, 'appliancetypesId': data.appliancetypesId, 'settingsMakesDescription': data.settingsMakesDescription, 'appliancemakesId': data.appliancemakesId, 'fuelTypesid': data.settingsFuelId });
            }
            $scope.saving = false;
        });
    }
}

function ListApplianceModelsCtrl($scope, $state, prefix, $http, appliancemodelsresolver, defaultPagingValue, canLoad, $q, warningModal) {
    $scope.appliancemodels = appliancemodelsresolver.appliancemodels;
    $scope.totalApplianceModels = appliancemodelsresolver.count;
    $scope.appliancemakeslist = appliancemodelsresolver.appliancemakeslist;
    $scope.appliancetypeslist = appliancemodelsresolver.appliancetypeslist;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.selectedAppliancemakes = '';
    $scope.searchText = '';

    JustAddedUpdatedChanges.call(this, $scope, 'appliancemodel', $state, defaultPagingValue, 'appliancemodels');

    $scope.$watch('searchText', function (newVal, oldVal) {
        if ((newVal != undefined) && (newVal != oldVal)) {
            $scope.currentPage = 1;
            $scope.fetchApplianceModels($scope.currentPage);
        }
    });

    $scope.blurCallback = function () {
        $scope.focused = false;

    }
    var canceler = null;
    $scope.fetchApplianceModels = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;

        if (searchText) {
            $scope.closeDelt = false;
        }
        else {
            $scope.closeDelt = true;
        }

        $http.get(prefix + '/listApplianceModels?page=' + pageNum + '&searchText=' + encodeURIComponent(searchText) + '&limit=' + $scope.limit, {timeout: canceler.promise}).success(function (data) {
            $scope.appliancemodels = data.appliancemodels;
            $scope.totalApplianceModels = data.count;

            appliancemodelsresolver.appliancemodels = $scope.appliancemodels;
            appliancemodelsresolver.count = $scope.totalApplianceModels;

            if (searchText != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
            $scope.triggerJustAddedUpdatedTags();
        })
    }

    $scope.$on("Appliance_Model", function (event, message) {
        $scope.searchText = '';
        GetCurrentPage.call(this, $scope, $state, appliancemodelsresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.appliancemodels.push({'id': message.id, 'text': message.description, 'settingsTypesDescription': message.settingsTypesDescription, 'appliancetypesId': message.appliancetypesId, 'settingsMakesDescription': message.settingsMakesDescription, 'appliancemakesId': message.appliancemakesId, 'settingsFuelTypesId': message.fuelTypesid, 'justadded': true});
            appliancemodelsresolver.appliancemodels = $scope.appliancemodels;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        appliancemodelsresolver.count = parseInt(appliancemodelsresolver.count) + 1
        $scope.totalApplianceModels = appliancemodelsresolver.count;
    });

    $scope.triggerDelete = function (id) {
        $http.get(prefix + '/is_appliance_modal_delete?id=' + id).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.editApplianceModels = function (appliancemodels) {
        $state.transitionTo('loggedin.appliance_models.edit',
            {'id': appliancemodels.id,
                'description': appliancemodels.text,
                'settingsTypesDescription': appliancemodels.settingsTypesDescription,
                'appliancetypesId': appliancemodels.appliancetypesId,
                'settingsMakesDescription': appliancemodels.settingsMakesDescription,
                'appliancemakesId': appliancemodels.appliancemakesId,
                'settingsFuelTypesId': appliancemodels.settingsFuelTypesId,
                'settingsFuelDescription': appliancemodels.settingsFuelDescription,
                'pageNum': $scope.currentPage, 'limit': $scope.limit
            })
    }
}

function ApplianceModelsEditCtrl($scope, $state, $http, prefix, appliancemodelsresolver, defaultPagingValue) {
    $scope.selectedApplianceModels = $state.params.description;
    $scope.selectedTypes = $state.params.settingsTypesDescription;
    $scope.typesId = parseInt($state.params.appliancetypesId);
    $scope.selectedFuelTypes = $state.params.settingsFuelDescription;
    $scope.selectedMakes = $state.params.settingsMakesDescription;
    $scope.makesId = parseInt($state.params.appliancemakesId);
    $scope.fuelTypesId = parseInt($state.params.settingsFuelTypesId);
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.appliancetypeslist = appliancemodelsresolver.appliancetypeslist;
    $scope.appliancemakeslist = appliancemodelsresolver.appliancemakeslist;
    $scope.fueltypelist = appliancemodelsresolver.fueltypelist;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.appliance_models");
    }

    $scope.checkApplianceModelsExists = function (applianceModel,applianceGroup, FuelType, applianceMake) {
        $http.get(prefix + '/applianceModelsExists?description='+ applianceModel + '&applianceGroupId=' + applianceGroup +'&fuelTypeId=' + FuelType + '&applianceMakeId=' + applianceMake).success(function(data){
            if(data == "true"){
                $scope.editApplianceModelsForm['selectedApplianceModels'].$error.checkExists = true;
                $scope.editApplianceModelsForm.$valid = false;
            }else{
                $scope.editApplianceModelsForm['selectedApplianceModels'].$error.checkExists = false;
                $scope.editApplianceModelsForm.$valid = true;
            }
        });
    }

    $scope.editApplianceModels = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $http.post(prefix + '/edit_appliancemodels', "appliancemodel[id]=" + this.selectedId + "&appliancemodel[description]=" + encodeURIComponent(this.selectedApplianceModels) + "&appliancemodel[settingsAppliancetypesid]=" + this.typesId + "&appliancemodel[settingsMakesid]=" + this.makesId + "&appliancemodel[settingsFuelTypesid]=" + this.fuelTypesId).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.editApplianceModelsForm['selectedApplianceModels'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.appliance_models");
                    }
                }
            });
    }
}

function TechnicalRefCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.technicalRefForm['technicalreference[description]'].$error.checkExists = true;
            } else {
                $scope.technicalRefForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("TECHNICALREF_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function TechnicalRefListEditCtrl($scope, $state, prefix, $http, technicalresolver, defaultPagingValue) {
    $scope.technicalInfo = technicalresolver.technicalInfo;
    $scope.totalTechnicalInfo = technicalresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'entity', $state, defaultPagingValue, 'technicalInfo');

    $scope.$on("TECHNICALREF_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, technicalresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.technicalInfo.push({'id': message.id, 'description': message.description, 'justadded': true});
            technicalresolver.technicalInfo = $scope.technicalInfo;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        technicalresolver.count = parseInt(technicalresolver.count) + 1
        $scope.totalTechnicalInfo = technicalresolver.count;

    });

    $scope.editTechnicalReferenceType = function (id, description) {
        $state.transitionTo('loggedin.technical_reference.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, defaultValue) {
        $scope.selectedId = id;
        $scope.isDefaultValue = defaultValue;
        $scope.shouldBeOpen = true;
    }

    $scope.fetchTechnicalInfo = function (pageNum) {
        $http.get(prefix + '/listTechnicalReference?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.technicalInfo = data.technicalInfo;
            $scope.totalTechnicalInfo = data.count;
            technicalresolver.technicalInfo = data.technicalInfo;
            technicalresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function TechnicalRefEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedTechnicalReference = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.technical_reference");
    }

    $scope.editTechnicalReference = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_technicalreference', "technicalreference[id]=" + this.selectedId + "&technicalreference[description]=" + encodeURIComponent(this.selectedTechnicalReference)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editTechnicalReferenceForm['selectedTechnicalReference'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.transitionTo("loggedin.technical_reference");
                    }
                }
            });
    }
}

function PricingItemsCtrl($scope, $http, prefix, datasets, formService, $modal) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.opened = false;

    $scope.showAdvancedOption = function () {
        $scope.opened = !$scope.opened;
    }

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.pricingItemForm['pricingitem[description]'].$error.checkExists = true;
            } else {
                $scope.pricingItemForm.$setPristine();
                $scope.description = "";
                $scope.unitprice = "";
                $scope.show_slected_taxItemId = '';
                $scope.$broadcast("PRICINGITEM_ADDED", { 'id': data.id, 'description': data.description, 'unitPrice': data.unitPrice, 'taxItemId': data.taxItemId });
            }
            $scope.saving = false;
        });
    }

    $scope.columns = [
        {name: 'Description', isinuse: false},
        {name: 'Unit Price', isinuse: false},
        {name: 'Tax', isinuse: false}
    ];

    $scope.submitForm = function () {
        $scope.csvProps.csvImportIsSaving = true;
        $http.post(prefix + '/import_records_from_csv/pricing_items', $.param({ flds: $scope.columnHeaders, csvfile: $scope.uploadedFile, containsHeader: $scope.containsHeader})).
            success(function (data) {
                $scope.csvProps.csvImportHasSaved = true;
                $scope.csvProps.csvImportIsSaving = false;
                $scope.$broadcast("CSVIMPORTCOMPLETE");
            });
    }
    HandleCsvImports.call(this, $scope, $modal);
}

function PricingItemsListEditCtrl($scope, $state, prefix, $http, pricingitemsresolver, defaultPagingValue, canLoad, $q) {
    $scope.pricingItems = pricingitemsresolver.pricingItems;
    $scope.totalPricingItems = pricingitemsresolver.count;
    $scope.shouldBeOpen = false;
    $scope.searchResult = false;
    $scope.searchText = "";

    JustAddedUpdatedChanges.call(this, $scope, 'pricingItem', $state, defaultPagingValue, 'pricingItems');

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            if (newVal != '') {
                $scope.currentPage = 1;
            }
            $scope.fetchPricingItems($scope.currentPage);
        }
    });

    var canceler = null;
    $scope.fetchPricingItems = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;

        if (searchText) {
            $scope.closeDelt = false;
        }
        else {
            $scope.closeDelt = true;
        }

        $http.get(prefix + '/listPricingItems?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.pricingItems = data.pricingItems;
            $scope.totalPricingItems = data.count;
            pricingitemsresolver.pricingItems = data.pricingItems;
            pricingitemsresolver.count = data.count;

            if (searchText != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
            $scope.triggerJustAddedUpdatedTags();
        })
    }

    $scope.$on("PRICINGITEM_ADDED", function (event, message) {
        $scope.searchText = '';
        GetCurrentPage.call(this, $scope, $state, pricingitemsresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.pricingItems.push({'id': message.id, 'description': message.description, 'unitPrice': message.unitPrice, 'taxItemId': message.taxItemId, 'justadded': true});
            pricingitemsresolver.pricingItems = $scope.pricingItems;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        pricingitemsresolver.count = parseInt(pricingitemsresolver.count) + 1
        $scope.totalPricingItems = pricingitemsresolver.count;
    });

    $scope.$on("CSVIMPORTCOMPLETE", function (event, message) {
        $scope.searchText = '';
        $scope.fetchPricingItems(1);
    });

    $scope.editPricingItem = function (id, description, unitPrice, taxItemId) {
        $state.transitionTo('loggedin.pricing_items.edit', {'id': id, 'selectedPricingItem': description, 'selectedUnitPrice': unitPrice, 'selectedTaxItemId': taxItemId, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }
}

function PricingItemEditCtrl($scope, $state, $http, prefix,pricingitemsresolver) {
    $scope.selectedPricingItem = $state.params.selectedPricingItem;
    $scope.selectedId = $state.params.id;
    $scope.editPricingItem = $state.params.selectedPricingItem;
    $scope.selectedUnitPrice = $state.params.selectedUnitPrice;
    $scope.selectedTaxItemId = $state.params.selectedTaxItemId;
    $scope.taxLists = pricingitemsresolver.taxLists;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;
    $scope.hideVat = pricingitemsresolver.hideVat;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.pricing_items");
    }

    $scope.saveEditedPricingItem = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $http.post(prefix + '/edit_pricingitem', "pricingitem[id]=" + this.selectedId + "&pricingitem[description]=" + encodeURIComponent(this.editPricingItem) + "&pricingitem[unitprice]=" + this.selectedUnitPrice + "&pricingitem[settingstaxitemid]=" + this.selectedTaxItemId).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.saveEditedPricingItem['editPricingItem'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.pricing_items");
                    }
                }
            });
    }
}

function NominalGroupsCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.nominalGroupsForm['nominalgroup[description]'].$error.checkExists = true;

            } else {
                $scope.nominalGroupsForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("NOMINAL_GROUPS", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function NominalGroupsEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedNominalGroup = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.nominalgroups");
    }

    $scope.editNominalGroups = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_nominalgroups', "nominalgroup[id]=" + this.selectedId + "&nominalgroup[description]=" + encodeURIComponent(this.selectedNominalGroup)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editNominalGroupsForm['selectedNominalGroup'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.transitionTo("loggedin.nominalgroups");
                    }
                }
            });
    }
}

function NominalGroupsEditListCtrl($scope, $state, prefix, $http, nominalgroupresolver, defaultPagingValue, warningModal) {
    $scope.nominalGroups = nominalgroupresolver.nominalGroups;
    $scope.totalNominalGroups = nominalgroupresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'nominalGroup', $state, defaultPagingValue, 'nominalGroups');

    $scope.triggerDelete = function (id, defaultValue) {
        $http.get(prefix + '/is_nominal_group_delete?id=' + id).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.selectedId = id;
                $scope.isDefaultValue = defaultValue;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.fetchNominalGroups = function (pageNum) {
        $http.get(prefix + '/listNominalGroups?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.nominalGroups = data.nominalGroups;
            $scope.totalNominalGroups = data.count;

            nominalgroupresolver.nominalGroups = $scope.nominalGroups;
            nominalgroupresolver.count = $scope.totalNominalGroups;

            $scope.triggerJustAddedUpdatedTags();
        })
    }

    $scope.$on("NOMINAL_GROUPS", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, nominalgroupresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.nominalGroups.push({'id': message.id, 'description': message.description, 'justadded': true});
            nominalgroupresolver.nominalGroups = $scope.nominalGroups;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        nominalgroupresolver.count = parseInt(nominalgroupresolver.count) + 1
        $scope.totalNominalGroups = nominalgroupresolver.count;

    });

    $scope.editNominal = function (id, description) {
        $state.transitionTo('loggedin.nominalgroups.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }


}

function NominalAccountsCtrl($scope, $http, prefix, $state, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.nominalGroupsList = [];
    $scope.nominalGroupId = $state.params.nominalGroupId;


    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.nominalAccountForm['nominalaccounttype[description]'].$error.checkExists = true;
            } else {
                $scope.nominalAccountForm.$setPristine();
                $scope.description = "";
                $scope.thirdpartyaccountingreference = "";
                $scope.settingsNominalgroupsid = "";
                $scope.settingsNominaltypesid = "";
                $scope.showThirdPartyAccountingCode = false;
                angular.element('#nominalaccounttype_thirdpartyaccountingreference').val('');
                $scope.$broadcast("NOMINALACCOUNTS_ADDED", { 'id': data.id, 'description': data.description, 'thirdpartyAccountingReference': data.thirdpartyAccountingReference, 'settingsNominaltypesid': data.settingsNominaltypesid, 'nominalGroupId': data.nominalGroupId});
            }
            $scope.saving = false;
        });
    }
    $scope.showThirdPartyAccountingCode = false;
    $scope.changeThirdPartyAccountingCode = function changeThirdPartyAccountingCode(nominalType) {
        $scope.thirdpartyaccountingreference = "";
        $scope.thirdPartyAccountingCodes = {};
        $scope.showThirdPartyAccountingCode = false;
        $http.post(prefix + '/checkThirdPartyAccountingCode', "nominalType=" + nominalType).success(function (data) {
            if(data.length > 0) {
                $scope.thirdPartyAccountingCodes = data;
                $scope.showThirdPartyAccountingCode = true;
            }
        });
    }
}

function NominalAccountsEditCtrl($scope, $state, $http, prefix, nominalAccountsresolver) {
    $scope.nominalGroupsList = nominalAccountsresolver.nominalGroupsList;
    $scope.nominalTypesList = nominalAccountsresolver.nominalTypesList;
    $scope.AIAccountSetup = nominalAccountsresolver.AIAccountSetup;
    $scope.AIPackageId = nominalAccountsresolver.AIPackageId;
    $scope.selectedNominalTypes = parseInt($state.params.settingsNominaltypesid);
    $scope.selectedNominalGroups = parseInt($state.params.nominalGroupId);
    $scope.selectedDescription = $state.params.description;
    $scope.selectedThirdparty = $state.params.thirdpartyAccountingReference;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;
    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.nominal_accounts");
    }
    $scope.changeThirdpartyCode = function (thirdpartyCode) {
        $scope.selectedThirdparty = thirdpartyCode;
    }
    $scope.editNominalAccount = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_nominalaccounts', "nominalaccounttype[id]=" + this.selectedId + "&nominalaccounttype[settingsNominaltypesid]=" + this.selectedNominalTypes + "&nominalaccounttype[settingsNominalgroupsid]=" + this.selectedNominalGroups + "&nominalaccounttype[description]=" + this.selectedDescription + "&nominalaccounttype[thirdpartyaccountingreference]=" + $scope.selectedThirdparty).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editNominalAccountForm['selectedDescription'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.nominal_accounts");
                    }
                }
            });
    }
    $scope.editNominalTypes = angular.copy($scope.selectedNominalTypes);
    $scope.showEditThirdPartyAccountingCode = false;
    $scope.changeEditThirdPartyAccountingCode = function changeEditThirdPartyAccountingCode(nominalType) {
        if($scope.AIPackageId == 3 || $scope.AIPackageId == 7) {
            if ($scope.editNominalTypes != nominalType) {
                $scope.selectedThirdparty = '';
            }
            $scope.editNominalTypes = angular.copy($scope.selectedNominalTypes);
            $scope.editThirdPartyAccountingCodes = {};
            $scope.showEditThirdPartyAccountingCode = false;
            $http.post(prefix + '/checkThirdPartyAccountingCode', "nominalType=" + nominalType).success(function (data) {
                if (data.length > 0) {
                    $scope.editThirdPartyAccountingCodes = data;
                    $scope.showEditThirdPartyAccountingCode = true;
                }
            });
        }
    }

    $scope.changeEditThirdPartyAccountingCode($scope.selectedNominalTypes);
}

function NominalAccountsListEditCtrl($scope, $state, prefix, $http, $timeout, nominalAccountsresolver, defaultPagingValue, canLoad, $q, warningModal) {
    $scope.nominalAccounts = nominalAccountsresolver.nominalAccounts;
    $scope.nominalGroupsList = nominalAccountsresolver.nominalGroupsList;
    $scope.nominalTypesList = nominalAccountsresolver.nominalTypesList;
    $scope.totalnominalAccounts = nominalAccountsresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.searchResult = false;
    $scope.show_filters = true;

    $scope.selectedGroup = $scope.selectedType = '';

    JustAddedUpdatedChanges.call(this, $scope, 'nominalAccount', $state, defaultPagingValue, 'nominalAccounts');

    $scope.change = function () {
        $scope.currentPage = 1;
        $scope.fetchNominalAccounts($scope.currentPage, this.selectedGroup, this.selectedType);
    }

    $scope.triggerDelete = function (id) {
        $http.get(prefix + '/is_nominal_account_delete?id=' + id).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.blurCallback = function () {
        $scope.focused = false;

        $timeout(function() {
          $scope.show_filters = true;
        }, 700);
    }

    $scope.$on("NOMINALACCOUNTS_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, nominalAccountsresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.nominalAccounts.push({'id': message.id, 'description': message.description, 'thirdpartyAccountingReference': message.thirdpartyAccountingReference, 'settingsNominaltypesid': message.settingsNominaltypesid, 'nominalGroupId': message.nominalGroupId, 'justadded': true});
            nominalAccountsresolver.nominalAccounts = $scope.nominalAccounts;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        nominalAccountsresolver.count = parseInt(nominalAccountsresolver.count) + 1
        $scope.totalnominalAccounts = nominalAccountsresolver.count;

    });

    $scope.editNominalAccount = function (nominalaccount) {
        $state.transitionTo('loggedin.nominal_accounts.edit',
            {'id': nominalaccount.id,
                'settingsNominaltypesid': nominalaccount.settingsNominaltypesid,
                'nominalGroupId': nominalaccount.nominalGroupId,
                'description': nominalaccount.description,
                'thirdpartyAccountingReference': nominalaccount.thirdpartyAccountingReference,
                'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.currentPage = 1;
            $scope.fetchNominalAccounts($scope.currentPage);
        }
    });

    var canceler = null;
    $scope.fetchNominalAccounts = function (pageNum, selectedGroup, selectedType) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var selGroup = selectedGroup || $scope.selectedGroup;
        var selType = selectedType || $scope.selectedType;
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;

        if (searchText) {
            $scope.closeDelt = false;
        }
        else {
            $scope.closeDelt = true;
        }

        $http.get(prefix + '/listNominalAccounts?page=' + pageNum + '&group=' + selGroup + '&type=' + selType + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.nominalAccounts = data.nominalAccounts;
            $scope.totalnominalAccounts = data.count;
            $scope.selectedGroup = selGroup;
            $scope.selectedType = selType;
            nominalAccountsresolver.nominalAccounts = data.nominalAccounts;
            nominalAccountsresolver.count = data.count;


            if ((searchText != '') || (selType != '') || (selGroup != '')) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }

            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function TelephoneTypeCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.telephoneTypeForm['telephonetype[description]'].$error.checkExists = true;

            } else {
                $scope.telephoneTypeForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("TELEPHONETYPE_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function TelephoneTypeEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedTelephoneType = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.telephonetypes");
    }

    $scope.editTelephoneType = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_telephonetypes', "telephonetype[id]=" + this.selectedId + "&telephonetype[description]=" + encodeURIComponent(this.selectedTelephoneType)).
            success(function (data, status) {
                if (status == 200) {

                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editTelephoneTypeForm['selectedTelephoneType'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.transitionTo("loggedin.telephonetypes");
                    }
                }
            });
    }
}

function TelephoneTypeListEditCtrl($scope, $state, prefix, $http, telephonetyperesolver, defaultPagingValue) {
    $scope.telephoneTypes = telephonetyperesolver.telephoneTypes;
    $scope.totalTelephoneTypes = telephonetyperesolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'telephoneType', $state, defaultPagingValue, 'telephoneTypes');


    $scope.$on("TELEPHONETYPE_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, telephonetyperesolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.telephoneTypes.push({'id': message.id, 'description': message.description, 'justadded': true});
            telephonetyperesolver.telephoneTypes = $scope.telephoneTypes;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        telephonetyperesolver.count = parseInt(telephonetyperesolver.count) + 1
        $scope.totalTelephoneTypes = telephonetyperesolver.count;
    });


    $scope.editTelephoneType = function (id, description) {
        $state.transitionTo('loggedin.telephonetypes.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.fetchTelephoneTypes = function (pageNum) {
        $http.get(prefix + '/listTelephoneTypes?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.telephoneTypes = data.telephoneTypes;
            $scope.totalTelephoneTypes = data.count;
            telephonetyperesolver.telephoneTypes = $scope.telephoneTypes;
            telephonetyperesolver.count = $scope.totalTelephoneTypes;

            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function TitleCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.titleForm['title[description]'].$error.checkExists = true;
            } else {
                $scope.titleForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("TITLE_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function TitlesEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedTitle = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.title");
    }

    $scope.editTitle = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_title', "title[id]=" + this.selectedId + "&title[description]=" + encodeURIComponent(this.selectedTitle)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editTitleForm['selectedTitle'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.title");
                    }
                }
            });
    }
}

function TitlesListEditCtrl($scope, $state, prefix, $http, titleresolver, defaultPagingValue, warningModal) {
    $scope.titles = titleresolver.titles;
    $scope.totalTitles = titleresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'title', $state, defaultPagingValue, 'titles');

    $scope.$on("TITLE_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, titleresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.titles.push({'id': message.id, 'description': message.description, 'justadded': true});
            titleresolver.titles = $scope.titles;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        titleresolver.count = parseInt(titleresolver.count) + 1
        $scope.totalTitles = titleresolver.count;
    });

    $scope.editTitle = function (id, description) {
        $state.transitionTo('loggedin.title.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, description, canDelete) {
        if(canDelete == false) {
            warningModal.show('You cannot delete this title because it has already used in properties', 'Customer titles');
        } else {
            $scope.selectedId = id;
            $scope.shouldBeOpen = true;
            $scope.description = description;
        }
    }

    $scope.fetchTitles = function (pageNum) {
        $http.get(prefix + '/listTitles?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.titles = data.titles;
            $scope.totalTitles = data.count;
            titleresolver.titles = data.titles;
            titleresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function FirstAccountNumberCtrl($scope, $http, $state, datasets, $rootScope) {
    $scope.data = datasets;
    var setFormName = "firstAccountNumberForm";
    FirstNumberCtrl.call(this, $scope, $http, $state, $rootScope);
    ErrorCtrl.call(this, $scope, $http, $state, datasets, $rootScope, setFormName);
}

function CustomerTypesCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.customerTypesForm['customertype[description]'].$error.checkExists = true;
            } else {
                $scope.customerTypesForm.$setPristine();
                $scope.description = "";
                $scope.customerdescription = "";
                $scope.typeofjobaddress = "";
                $scope.companynamerequired = "";
                $scope.allowforbranches = "";
                $scope.saving = false;
                $scope.$broadcast("CUSTOMERTYPES_ADDED", { 'id': data.id, 'description': data.description, 'customerdescription': data.customerdescription, 'companynamerequired': data.companynamerequired, 'allowforbranches': data.allowforbranches, 'typeofjobaddress': data.typeofjobaddress});
            }
            $scope.saving = false;
        });
    }
}

function CustomerTypesListEditCtrl($scope, $state, prefix, $http, customertyperesolver, defaultPagingValue) {
    $scope.customerTypesList = customertyperesolver.customertypeslist;
    $scope.customerTypesCount = customertyperesolver.count;
    $scope.shouldBeOpen = false;
    $scope.cannotDelete = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'customertype', $state, defaultPagingValue, 'customerTypesList');


    $scope.$on("CUSTOMERTYPES_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, customertyperesolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.customerTypesList.push({'id': message.id, 'description': message.description, 'customerdescription': message.customerdescription, 'companynamerequired': message.companynamerequired, 'allowforbranches': message.allowforbranches, 'typeofjobaddress': message.typeofjobaddress, 'justadded': true});
            customertyperesolver.customerTypesList = $scope.customerTypesList;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        customertyperesolver.count = parseInt(customertyperesolver.count) + 1
        $scope.customerTypesCount = customertyperesolver.count;
    });


    $scope.editCustomerTypes = function (id, description, customerdescription, companynamerequired, allowforbranches, typeofjobaddress) {
        $state.transitionTo('loggedin.customer_types.edit', {'id': id, 'description': description, 'customerdescription': customerdescription, 'companynamerequired': companynamerequired, 'allowforbranches': allowforbranches, 'typeofjobaddress': typeofjobaddress, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }


    $scope.triggerDelete = function (id, description) {
        $http.get(prefix + '/is_active_customertype?id=' + id).success(function(data) {
            if (data === "true") {
                $scope.cannotDelete = true;
            }  else {
                $scope.cannotDelete = false;
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
                $scope.customertype = description;
            }
        });

    }

    $scope.fetchCustomertypes = function (pageNum) {
        $http.get(prefix + '/listCustomerTypes?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.customerTypesList = data.customertypeslist;
            $scope.customerTypesCount = data.count;

            customertyperesolver.customertypeslist = $scope.customerTypesList;
            customertyperesolver.count = $scope.customerTypesCount

            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function CustomerTypesEditCtrl($scope, $state, $http, prefix) {
    $scope.customerType = $state.params.description;
    $scope.customerDescription = $state.params.customerdescription;
    $scope.companyNameRequired = $state.params.companynamerequired;
    $scope.allowForBranches = $state.params.allowforbranches;
    $scope.typeOfJobAddress = $state.params.typeofjobaddress;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    if ($scope.companyNameRequired == 'true') {
        $scope.companyName = false;
        $scope.companyNameRequired = true;
    } else {
        $scope.companyName = true;
        $scope.companyNameRequired = false;
    }

    if ($scope.allowForBranches == 'true') {
        $scope.allowBranch = false;
        $scope.allowForBranches = true;
    } else {
        $scope.allowBranch = true;
        $scope.allowForBranches = false;
    }

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.customer_types");
    }

    $scope.editCustomerType = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_customertypes', "customertype[id]=" + this.selectedId + "&customertype[description]=" + encodeURIComponent(this.customerType) + "&customertype[customerdescription]=" + encodeURIComponent(this.customerDescription)
                + "&customertype[companynamerequired]=" + encodeURIComponent(this.companyNameRequired) + "&customertype[allowforbranches]=" + encodeURIComponent(this.allowForBranches)
                + "&customertype[typeofjobaddress]=" + encodeURIComponent(this.typeOfJobAddress)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editCustomerTypeForm['customerType'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.customer_types");
                    }
                }
            });
    }
}

function AdvertisingTypeCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.advertisingForm['advertisingtype[description]'].$error.checkExists = true;
            } else {
                $scope.advertisingForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("ADVERTISINGTYPE_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function AdvertisingTypeListEditCtrl($scope, $state, prefix, $http, advertisingtyperesolver, defaultPagingValue) {
    $scope.advertisingType = advertisingtyperesolver.advertisingType;
    $scope.totalAdvertisingType = advertisingtyperesolver.count;
    $scope.shouldBeOpen = false;

    JustAddedUpdatedChanges.call(this, $scope, 'entity', $state, defaultPagingValue, 'advertisingType');

    $scope.$on("ADVERTISINGTYPE_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, advertisingtyperesolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.advertisingType.push({'id': message.id, 'description': message.description, 'justadded': true});
            advertisingtyperesolver.advertisingType = $scope.advertisingType;
            $state.current.data.addedId = -1;
        } else {
            $scope.currentPage = $scope.currentNewPage;
        }
        advertisingtyperesolver.count = parseInt(advertisingtyperesolver.count) + 1
        $scope.totalAdvertisingType = advertisingtyperesolver.count;

    });

    $scope.editAdvertisingType = function (id, description, key, showtext) {
        $state.transitionTo('loggedin.advertisingtypes.edit', {'id': id, 'description': description, 'key': key, 'showtext' : showtext, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, description) {
        $scope.selectedId = id;
        $scope.selectedDescription = description;
        $scope.shouldBeOpen = true;
    }

    $scope.fetchAdvertisingType = function (pageNum) {
        $http.get(prefix + '/listAdvertisingTypes?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.advertisingType = data.advertisingType;
            $scope.totalAdvertisingType = data.count;
            advertisingtyperesolver.advertisingType = data.advertisingType;
            advertisingtyperesolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function AdvertisingTypeEditCtrl($scope, $state, $http, prefix, mergeresolver) {
    $scope.selectedAdvertisingType = $state.params.description;
    $scope.showtext = $state.params.showtext;
    $scope.selectedId = $state.params.id;
    $scope.currentPage = $state.params.pageNum;
    $scope.listAdvertising = mergeresolver.totalAdvertisingType;
    $scope.advertisingListCount = mergeresolver.advertisingListCount;
    $scope.limit = $state.params.limit;
    $scope.index = $state.params.key;
    $scope.noAdvertising = true;
    $scope.submitButton = true;

    for (var i = $scope.listAdvertising.length - 1; i >= 0; i--) {
        if ($scope.listAdvertising[i].description == $scope.selectedAdvertisingType) {
            $scope.listAdvertising.splice(i, 1);
        }
    }

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.advertisingtypes");
    }

    $scope.triggerMerge = function () {
        $scope.shouldBeOpen = true;
    }

    $scope.mergeSelected = function (mergeadvertising) {
        var mergeValue = mergeadvertising.split('|');
        $scope.mergeId = mergeValue[0];
        $scope.mergeDescription = mergeValue[1];
    }
    if (!$scope.listAdvertising.length) {
        $scope.noAdvertising = false;
    }
    $scope.showMerge = function () {
        $scope.isChecked = true;
        $scope.submitButton = false;
    }
    $scope.hideMerge = function () {
        $scope.isChecked = false;
        $scope.submitButton = true;
    }
    if($scope.showtext=='true') {
        $scope.isChecked = true;
        $scope.submitButton = false;
    }

    $scope.editAdvertisingType = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_advertisingtype', "advertisingtype[id]=" + $scope.selectedId + "&advertisingtype[description]=" + encodeURIComponent(this.selectedAdvertisingType)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editAdvertisingForm['selectedAdvertisingType'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.advertisingtypes");
                    }
                }
            });
    }

    $scope.fetchAdvertisingType = function (pageNum) {
        $http.get(prefix + '/listAdvertisingTypes?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $state.$current.parent.self.data.pageNum = $scope.pageNum;
            $state.$current.parent.self.data.limit = $scope.limit;
            $state.transitionTo("loggedin.advertisingtypes");
        })
    }
}

function CreditorCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.opened = false;

    $scope.showAdvancedOption = function () {
        $scope.opened = !$scope.opened;
    }

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.creditorForm['creditorday[numberofdays]'].$error.checkExists = true;
            } else {
                $scope.creditorForm.$setPristine();
                $scope.numberofdays = "";
                $scope.$broadcast("CREDITOR_ADDED", { 'id': data.id, 'numberofdays': data.numberofdays});
            }
            $scope.saving = false;
        });
    }
}

function CreditorListEditCtrl($scope, $state, prefix, $http, creditorresolver, defaultPagingValue, warningModal) {
    $scope.creditors = creditorresolver.creditors;
    $scope.totalCreditors = creditorresolver.count;
    $scope.shouldBeOpen = false;
    $scope.openDefaultModal = false;
    $scope.confirmDefaultValidity = true;
    $scope.isDefaultValue = false;

    JustAddedUpdatedChanges.call(this, $scope, 'entity', $state, defaultPagingValue, 'creditors');

    $scope.triggerDelete = function (id, defaultValue) {
        $scope.isDefaultValue = defaultValue;
        $scope.selectedId = id;
        $http.get(prefix + '/CheckdeleteCreditorDays/'+ $scope.selectedId).success(function (data){
            if(data.warning == true){
                warningModal.show(data.message, data.title, data.id);
            }else if($scope.isDefaultValue){
                warningModal.clean();
                warningModal.show('delete.warning.creditor.days.system.settings', 'Delete.Creditor.Days', 'warning_creditor_days');
            }else{
                $scope.shouldBeOpen = true;
            }
        })
    }

    $scope.triggerDefault = function (id) {
        $scope.openMakeDefaultModal = true;
        $scope.selectedId = id;
        $scope.confirmDefaultValidity = true;
    }

    $scope.fetchCreditors = function (pageNum) {
        $http.get(prefix + '/listCreditordays?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.creditors = data.creditors;
            $scope.totalCreditors = data.count;
            creditorresolver.creditors = data.creditors;
            creditorresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }

    $scope.$on("CREDITOR_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, creditorresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.creditors.push({'id': message.id, 'numberofdays': message.numberofdays, 'justadded': true});
            creditorresolver.creditors = $scope.creditors;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        creditorresolver.count = parseInt(creditorresolver.count) + 1
        $scope.totalCreditors = creditorresolver.count;

    });

    $scope.editCreditorDays = function (id, numberofdays) {
        $state.transitionTo('loggedin.creditor_days.edit', {'id': id, 'selectedCreditor': numberofdays, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }
}

function CreditorEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedCreditor = $state.params.selectedCreditor;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.updating = false;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.creditor_days");
    }

    $scope.editCreditor = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/creditorday_edit', "creditorday[id]=" + this.selectedId + "&creditorday[numberofdays]=" + encodeURIComponent(this.selectedCreditor)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editCreditorDaysForm['selectedCreditor'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.creditor_days");
                    }
                }
            });
    }
}

function NewCreditorsController($scope, $rootScope, $http, prefix, $modalInstance) {
    $scope = $scope.$parent;

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }
    $scope.close = function () {
        $modalInstance.close();
    }

    $scope.applyCreditorDays = function () {
        $scope.saving = true;
        var customerType = ($scope.selectedCustomerType == null || $scope.selectedCustomerType == '') ? 0 : $scope.selectedCustomerType.id;
        var creditorDay = ($scope.creditorDays == null || $scope.creditorDays == '') ? 0 : $scope.creditorDays.id;
        $http.post(prefix + '/apply_creditordays?searchText=' + encodeURIComponent($scope.searchText) + '&customerType=' + customerType + '&creditorDays=' + creditorDay + '&creditordaysToApply=' + this.creditordaysToApply).
            success(function (data, status) {
                if (status == 200) {
                    $scope.saving = false;
                    //$scope.searchText = '';
                    //$scope.selectedCustomerType = null;
                    $scope.creditorDays = null;
                    $scope.fetchCustomerCreditors();
                    $scope.close();
                }
            });
    }
}

function CustomerCreditorListEditCtrl($scope, $state, prefix, $http, customercreditorresolver, $modal, canLoad, $q) {
    $scope.creditors = customercreditorresolver.creditors;
    $scope.customerCreditors = customercreditorresolver.customerCreditors;
    $scope.totalEntries = customercreditorresolver.count;
    $scope.customerTypes = customercreditorresolver.customerTypes;
    $scope.searchText = '';
    $scope.selectedCustomerType = null;
    $scope.creditorDays = null;
    $scope.enableApplyButton = false;
    $scope.currentPage = 1;
    $scope.limit = 30;

    $scope.$watchCollection('[searchText, selectedCustomerType, creditorDays]', function (newVal, oldVal) {
        if (typeof(newVal) != 'undefined' && typeof(oldVal) != 'undefined') {
            $scope.fetchCustomerCreditors();
        }
    });
    $scope.enterKeyPress = function (event) {
        if(event.keyCode == 13) {
            event.preventDefault();
        } return;
    }

    $scope.editCustomerCreditorDay = function (id, selectedCreditor) {
        $scope.updating = true;
        if(selectedCreditor == '') {
            selectedCreditor = $scope.selectedCreditor;
        }
        $http.post(prefix + '/edit_customer_creditor', "id=" + id + "&creditorId=" + selectedCreditor).
            success(function (data, status) {
                if (status == 200) {
                    updateValues(id, selectedCreditor);
                    $scope.updating = false;
                    $scope.selectedId = false;
                }
            });
    }

    $scope.editCustomer = function (customerId) {
        angular.forEach($scope.customerCreditors, function (value, key) {
            if (value.id == customerId) {
                value['active'] = true;
                $scope.selectedCreditor = value.settingsCreditordaysid;
            } else {
                value['active'] = false;
            }
        });
    }

    $scope.openCreditorModal = function () {
        if (!$scope.enableApplyButton || !$scope.customerCreditors) {
            return false;
        }
        var modalInstance = null;
        modalInstance = $modal.open({
            templateUrl: 'template/settings/customercreditor/customer_creditordays_modal.html',
            scope: $scope,
            controller: NewCreditorsController
        })
    }

    function updateValues(id, creditorId) {
        angular.forEach($scope.customerCreditors, function (value, key) {
            if (value.id == id) {
                angular.forEach($scope.creditors, function (creditorvalue, creditorkey) {
                    if (creditorvalue.id == creditorId) {
                        $scope.numberOfDays = creditorvalue.numberofdays;
                    }
                });
                value['currentCreditorDays'] = $scope.numberOfDays;
                value['active'] = false;
            }
        });
    }

    var canceler = null;
    $scope.fetchCustomerCreditors = function () {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var customerType = ($scope.selectedCustomerType == null || $scope.selectedCustomerType == '') ? 0 : $scope.selectedCustomerType.id;
        var creditorDay = ($scope.creditorDays == null || $scope.creditorDays == '') ? 0 : $scope.creditorDays.id;
        $http.get(prefix + '/listCustomerCreditordays?searchText=' + encodeURIComponent($scope.searchText) +
            '&customerType=' + customerType + '&creditorDays=' + creditorDay + '&page=' + $scope.currentPage +
            '&limit=' + $scope.limit, {timeout: canceler.promise}).success(function (data) {
            $scope.customerCreditors = data.customerCreditors;
            $scope.totalEntries = data.count;

            if (customerType == 0 && creditorDay == 0 && $scope.searchText == '') {
                $scope.enableApplyButton = false;
            } else {
                $scope.enableApplyButton = true;
            }
        })
    }
}

function BuildingTypesCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.buildingTypeForm['buildingtype[description]'].$error.checkExists = true;
            } else {
                $scope.buildingTypeForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("BUILDINGTYPE_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function BuildingTypesListEditCtrl($scope, $state, prefix, $http, buildingtypesresolver, defaultPagingValue) {
    $scope.buildingTypes = buildingtypesresolver.buildingTypes;
    $scope.totalBuildingTypes = buildingtypesresolver.count;
    $scope.shouldBeOpen = false;

    JustAddedUpdatedChanges.call(this, $scope, 'buildingType', $state, defaultPagingValue, 'buildingTypes');


    $scope.triggerDelete = function (id, defaultValue) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.fetchBuildingTypes = function (pageNum) {
        $http.get(prefix + '/listBuildingTypes?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.buildingTypes = data.buildingTypes;
            $scope.totalBuildingTypes = data.count;
            buildingtypesresolver.buildingTypes = data.buildingTypes;
            buildingtypesresolver.count = data.count;

            $scope.triggerJustAddedUpdatedTags();
        })
    }

    $scope.$on("BUILDINGTYPE_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, buildingtypesresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.buildingTypes.push({'id': message.id, 'description': message.description, 'justadded': true});
            buildingtypesresolver.buildingTypes = $scope.buildingTypes;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        buildingtypesresolver.count = parseInt(buildingtypesresolver.count) + 1
        $scope.totalBuildingTypes = buildingtypesresolver.count;
    });

    $scope.editBuildingType = function (id, description) {
        $state.transitionTo('loggedin.buildingtypes.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }
}

function BuildingTypeEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedBuildingType = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.buildingtypes");
    }

    $scope.editBuildingType = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/editBuildingType', "buildingtype[id]=" + this.selectedId + "&buildingtype[description]=" + encodeURIComponent(this.selectedBuildingType)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editBuildingTypeForm['selectedBuildingType'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.buildingtypes");
                    }
                }
            });
    }
}

function CustomerSmsTemplateCtrl($scope, $http, prefix, $state, datasets, formService, $rootScope) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.customerSmsTemplateForm['customersmstemplate[title]'].$error.checkExists = true;
            } else {
                $scope.customerSmsTemplateForm.$setPristine();
                $scope.title = "";
                $scope.message = '';
                var tinyInstance = tinymce.get('customersmstemplate_message');
                if (tinyInstance) {
                    tinyInstance.setContent($scope.message || '');
                }
                $scope.$broadcast("CUSTOMERSMSTEMPLATE_ADDED", { 'id': data.id, 'title': data.title, 'message': data.message});
            }
            $scope.saving = false;
        });
    }
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function CustomerSmsTemplateEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedCustomerSmsTitle = $state.params.title;
    $scope.selectedCustomerSmsMessage = $state.params.message;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.customer_sms_template");
    }

    $scope.editCustomerSmsTemplate = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_editcustomersmstemplates', "customersmstemplate[id]=" + this.selectedId + "&customersmstemplate[title]=" + encodeURIComponent(this.selectedCustomerSmsTitle) + "&customersmstemplate[message]=" + encodeURIComponent(this.selectedCustomerSmsMessage)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editCustomerSmsTemplateForm['selectedCustomerSmsTitle'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.customer_sms_template");
                    }
                }
            });
    }
}

function CustomerSmsTemplateListEditCtrl($scope, $state, prefix, $http, customerSmsTemplateresolver, defaultPagingValue) {
    $scope.customerSmsTemplates = customerSmsTemplateresolver.customerSmsTemplates;
    $scope.totalCustomerSmsTemplates = customerSmsTemplateresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'customerSmsTemplate', $state, defaultPagingValue, 'customerSmsTemplates');

    $scope.triggerDelete = function (id, description) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.customerSmsTemplate = description;
    }

    $scope.$on("CUSTOMERSMSTEMPLATE_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, customerSmsTemplateresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.customerSmsTemplates.push({'id': message.id, 'title': message.title, 'message': message.message, 'justadded': true});
            customerSmsTemplateresolver.customerSmsTemplates = $scope.customerSmsTemplates;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        customerSmsTemplateresolver.count = parseInt(customerSmsTemplateresolver.count) + 1
        $scope.totalCustomerSmsTemplates = customerSmsTemplateresolver.count;

    });

    $scope.editCustomerSms = function (id, title, message) {
        $state.transitionTo('loggedin.customer_sms_template.edit', {'id': id, 'title': title, 'message': message, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.fetchCustomerSmsTemplate = function (pageNum) {
        $http.get(prefix + '/listCustomerSmsTemplate?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.customerSmsTemplates = data.customerSmsTemplates;
            $scope.totalCustomerSmsTemplates = data.count;
            customerSmsTemplateresolver.customerSmsTemplates = $scope.customerSmsTemplates;
            customerSmsTemplateresolver.count = $scope.totalCustomerSmsTemplates;
            $scope.triggerJustAddedUpdatedTags();
        })
    }

}

function FirstEstimateNumberCtrl($scope, $http, $state, datasets, $rootScope) {
    $scope.data = datasets;
    var setFormName = "firstEstimateNumberForm";
    FirstNumberCtrl.call(this, $scope, $http, $state, $rootScope);
    ErrorCtrl.call(this, $scope, $http, $state, datasets, $rootScope, setFormName);
}

function FirstInvoiceNumberCtrl($scope, $http, $state, datasets, $rootScope) {
    $scope.data = datasets;
    var setFormName = "invoiceNumberForm";
    FirstNumberCtrl.call(this, $scope, $http, $state, $rootScope);
    ErrorCtrl.call(this, $scope, $http, $state, datasets, $rootScope, setFormName);
}

function EstimateTemplateCtrl($scope, $state, $http, prefix, datasets, formService, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
    $scope.templatePanelHide = false;
    $scope.data = datasets;
    $scope.saving = false;
    $scope.colorValueHide = true;
    $scope.$on('colorpicker:diary_color', function(evt,data) {
        $scope.colorValue = data.color;
    });

    $scope.backEstimateTemplate = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $scope.templatePanelHide = false;
        $state.transitionTo("loggedin.estimate_template");

    }
    $scope.configureEstimateTemplate = function (id) {

        $http.get(prefix + '/listConfigureEstimateTemplate?templateId=' + id).success(function (data) {
            $scope.templatePanelHide = true;
            $state.transitionTo('loggedin.estimate_template.configure', {'templateId': id, 'templateSubject': data.estimateTemplate[0]['subject'], 'templateMessage': data.estimateTemplate[0]['message']})
        })

    }
    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.estimateTemplateForm['estimatetemplate[description]'].$error.checkExists = true;
            } else {
                $scope.estimateTemplateForm.$setPristine();
                $scope.description = "";
                $scope.selected = '';
                $scope.selectedColor = 'light-blue';
                $scope.colorValue = '#9ccccb';
                $scope.settingsInvoiceCategoryId = '';
                $scope.settingsjobdescriptionid='';
                $scope.isQuote = '';
                $scope.$broadcast("ESTIMATE_TEMPLATE_ADDED", { 'id': data.id, 'type': data.type, 'description': data.description, 'colour': data.colour, 'invoiceCategoryId': data.invoiceCategoryId, 'isQuote' : data.isQuote,'settingsJobDescriptionId':data.settingsJobDescriptionId});
            }
            $scope.saving = false;
        });
    }
}

function EstimateTemplateEditCtrl($scope, $state, $http, prefix, estimatetemplateresolver) {
    $scope.selectedEstimateTemplate = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.colorValue = $state.params.colour;
    $scope.isQuoteValue = $state.params.isQuote;
    $scope.invoiceCategoryId = $state.params.invoiceCategoryId ? parseInt($state.params.invoiceCategoryId) : '';
    $scope.settingsJobDescriptionId =$state.params.settingsJobDescriptionId ? parseInt($state.params.settingsJobDescriptionId) : '';
    $scope.invoiceCategories = estimatetemplateresolver.invoiceCategories;
    $scope.settingsJobDescriptions = estimatetemplateresolver.jobDescriptions;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.colorValueHide = true;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.estimate_template");
    }

    $scope.$on('colorpicker:diary_color', function(evt,data) {
        $scope.colorValue = data.color;
    });

    $scope.editEstimateTemplate = function ($event) {
        $event.preventDefault();
        $scope.updating = true;
        $scope.current = this;
        var params = "estimatetemplate[id]=" + this.selectedId + "&estimatetemplate[description]=" + encodeURIComponent(this.selectedEstimateTemplate) + "&estimatetemplate[colour]=" + encodeURIComponent(this.colorValue) + "&estimatetemplate[isQuote]=" + this.isQuoteValue;
        if (this.invoiceCategories.length > 0) {
            params = params + "&estimatetemplate[invoiceCategoryId]=" + this.invoiceCategoryId;
        }
        if (this.settingsJobDescriptions.length > 0) {
            params = params + "&estimatetemplate[settingsJobDescriptionId]=" + this.settingsJobDescriptionId;
        }
        $http.post(prefix + '/edit_estimate_template', params).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editEstimateTemplateForm['selectedEstimateTemplate'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.estimate_template");
                    }
                }
            });
    }
}

function EstimateTemplateListEditCtrl($scope, $state, prefix, $http, estimatetemplateresolver, defaultPagingValue) {
    $scope.estimateTemplate = estimatetemplateresolver.estimateTemplate;
    $scope.totalEstimateTemplate = estimatetemplateresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'template', $state, defaultPagingValue, 'estimateTemplate');

    $scope.$on("ESTIMATE_TEMPLATE_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, estimatetemplateresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.estimateTemplate.push({'id': message.id, 'type': message.type, 'description': message.description, 'colour': message.colour, 'invoiceCategoryId': message.invoiceCategoryId, 'isQuote' : message.isQuote,'settingsJobDescriptionId' :message.settingsJobDescriptionId, 'justadded': true});
            estimatetemplateresolver.estimateTemplate = $scope.estimateTemplate;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        estimatetemplateresolver.count = parseInt(estimatetemplateresolver.count) + 1
        $scope.totalEstimateTemplate = estimatetemplateresolver.count;

    });

    $scope.editEstimateTemplate = function (id, description, colour, invoiceCategoryId, isQuote,settingsJobDescriptionId) {
        $state.transitionTo('loggedin.estimate_template.edit',
            {'id': id,
             'description': description,
             'colour': colour,
             'invoiceCategoryId': invoiceCategoryId,
             'isQuote' : isQuote,
             'settingsJobDescriptionId': settingsJobDescriptionId,
             'pageNum': $scope.currentPage,
             'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, deleteTemplateName) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.selectedDeleteTemplateName = deleteTemplateName;
    }

    $scope.fetchEstimateTemplate = function (pageNum) {
        $http.get(prefix + '/listEstimateTemplate?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.estimateTemplate = data.estimateTemplate;
            $scope.totalEstimateTemplate = data.count;
            estimatetemplateresolver.estimateTemplate = data.estimateTemplate;
            estimatetemplateresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function ConfigureEstimateTemplateCtrl($scope, $state, $http, prefix) {
    $scope.selectedtemplateId = $state.params.templateId;
    $scope.selectedEstimateTemplateSubject = $state.params.templateSubject;
    $scope.selectedEstimateTemplateMessage = $state.params.templateMessage;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;
    $scope.shouldBeOpenLetterPreview = false;


    $scope.templatePreview = function (subject, message) {
        if (subject == null && message == null) {
            $scope.previewData = '';
        }
        else if (subject == null) {
            $scope.previewData = message;
        }
        else if (message == null) {
            $scope.previewData = subject;
        }
        else if (subject != null && message != null) {
            $scope.previewData = subject + message;
        }

        $scope.shouldBeOpenLetterPreview = true;
    };

    $scope.configureEstimateTemplate = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/editConfigureEstimateTemplate', "estimatetemplatecontent[id]=" + this.selectedtemplateId + "&estimatetemplatecontent[subject]=" + encodeURIComponent(this.selectedEstimateTemplateSubject) + "&estimatetemplatecontent[message]=" + encodeURIComponent(this.selectedEstimateTemplateMessage)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                    $state.$current.parent.self.data.editedId = $scope.selectedtemplateId;
                    $state.$current.parent.self.data.limit = $scope.limit;
                    $scope.backEstimateTemplate();
                }
            });
    }

}

function EngineerEstimatesheetEmailCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function EngineerEstimateSmsCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function CustomerEstimateConfirmationCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);

    $scope.letterPreview = function (letter) {
        $scope.previewData = letter;
        $scope.shouldBeOpenLetterPreview = true;
    };
}

function WorkAddressEstimateConfirmationCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);

    $scope.letterPreview = function (letter) {
        $scope.previewData = letter;
        $scope.shouldBeOpenLetterPreview = true;
    };
}

function EstimateReminderCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);

    $scope.letterPreview = function (letter) {
        $scope.previewData = letter;
        $scope.shouldBeOpenLetterPreview = true;
    };
}

function EstimateTermsAndConditionsCtrl($scope, $http, $state, datasets, $rootScope, $timeout) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
    $rootScope.flashSuccessMessage = '';
    $scope.close = function () {
        $scope.flashSuccessMessage = '';
    }
    $scope.$on('tabTermsAndConditions:successMessage', function (event, data) {
        $rootScope.flashSuccessMessage = data;
        $timeout(function () {
            $("#editTermsAndConditionSaveMessage").fadeIn();
            $("#editTermsAndConditionSaveMessage").fadeOut(4000);
            $("#saveMessage").show();
        });
    });
    if ($rootScope.flashMessage) {
        $scope.$emit('tabTermsAndConditions:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
}

function ListEstimateTermsAndConditionsCtrl($scope, $http, prefix, $state, termsresolver, $sce) {
    $scope.termsandconditions = termsresolver.termsandconditions;
    $scope.counttermsandconditions = termsresolver.counttermsandconditions;
    $scope.saving = false;

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    $scope.fecthTermsandConditions = function (pageNum) {
        $http.get(prefix + '/listalltermsandconditions?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.termsandconditions = data.termsandconditions;
            $scope.counttermsandconditions = termsresolver.counttermsandconditions;

        })
    }
    $scope.triggerDeleteTremsandconditions = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }
    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
}

function AdditionalEstimateTermsAndConditionsCtrl($scope, $http, prefix, datasets, formService, $state, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function EditTermsAndConditionsCtrl($scope, $stateParams, $location, $http, $state, prefix, datasets, $rootScope, formService) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);

    $scope.selectedId = $state.params.id;
    $scope.edittermsandconditions = datasets.edittermsandconditions;

    $scope.edittermsandconditionsId = $scope.edittermsandconditions.id;
    $scope.edittermsandconditionsSubject = $scope.edittermsandconditions.subject;
    $scope.edittermsandconditionsMessage = $scope.edittermsandconditions.message;

    $scope.editTermsandconditions = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_terms_and_condition', "estimatetermsandconditions[id]=" + this.edittermsandconditionsId + "&estimatetermsandconditions[subject]=" + encodeURIComponent(this.edittermsandconditionsSubject) + "&estimatetermsandconditions[message]=" + encodeURIComponent(this.edittermsandconditionsMessage)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    $rootScope.flashMessage = data;
                    $state.transitionTo('loggedin.terms_and_conditions_estimates');
                }
            });
    }
}

function FirstJobNumberCtrl($scope, $http, $state, datasets, $rootScope) {
    $scope.data = datasets;
    var setFormName = "firstJobNumberForm";
    FirstNumberCtrl.call(this, $scope, $http, $state, $rootScope);
    ErrorCtrl.call(this, $scope, $http, $state, datasets, $rootScope, setFormName);
}

var SkillsResolver = {
    skills_data: function ($q, $http, prefix) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var deferred = $q.defer();
        $http.get(prefix + '/search_skills?searchText=').success(function (data) {
            deferred.resolve(data);
        });
        return deferred.promise;
    }
}

function SkillsManager($scope, skills_data) {
    $scope.select2Options = {
        placeholder: "Select some skills",
        'multiple': true,
        id: function (obj) {
            return obj.id;
        },
        'data': skills_data,
        formatResult: function (item) {
            return item.text;
        },
        formatSelection: function (item) {
            return item.text;
        },
        formatNoMatches: function (item) {
            if (item != '') {
                return 'No results match "' + item + '"';
            } else {
                return "";
            }
        }
    };
}

function JobDescriptionsCtrl($scope, $state, $http, prefix, datasets, formService, $rootScope, skills_data) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.$state = $state;
    $scope.timetocomplete = 0;
    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)$/);
        $scope.reTurnState= matches[2];
        if(matches[2] === 'job_descriptions.todo'){
            $scope.todolist = true;
        }else {
            $scope.todolist = false;
        }
    });

    $scope.$on("SetJobDescriptionLimitPage", function (event, message) {
        $rootScope.page = message.page;
        $rootScope.limit = message.limit;
    });

    SkillsManager.call(this, $scope, skills_data);

    $scope.skillsChanged = function() {
        var skills = '';
        if($scope.selectedSkills && $scope.selectedSkills.length) {
            angular.forEach($scope.selectedSkills, function (val, key) {
                var concat = (skills != '' ? "," : "");
                skills += concat + val.id;
            });
        }
        // Todo: Need to fix this, as this is coming from twig, I'm using JQuery to set value
        $('#selectedSkill').val(skills);
    }

    $scope.getBackJobDescription = function getBack() {
        $state.transitionTo('loggedin.job_descriptions');
        if ($rootScope.page != undefined && $rootScope.limit != undefined) {
            var pagevalue = $rootScope.page;
            var limitvalue = $rootScope.limit;
        } else {
            var pagevalue = 1;
            var limitvalue = 5;
        }
        $scope.todolist = false;
           $scope.$broadcast("List_JobdescriptionsSetLimitPage", {'pagevalue': pagevalue,'limitvalue':limitvalue});
    }

    $scope.showJobDescriptions = function () {
        $scope.todolist = false;
        $scope.$broadcast("JOBDESCRIPTIONS_RETURN");
        $state.transitionTo('loggedin.job_descriptions');
    }

    $scope.colorValuehide = true;

    $scope.$on('colorpicker:diary_color', function(evt,data) {
        $scope.colorValue = data.color;
    });

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.jobForm['jobdescription[description]'].$error.checkExists = true;
            } else {
                $scope.jobForm.$setPristine();
                $scope.description = "";
                $scope.timetocomplete = 0;
                $scope.selectedSkills = [];
                $scope.price = "";
                $scope.appearincustomerlogin_model = false;
                $scope.appearinwebbooking_model = false;
                $scope.selectedColor = 'light-blue';
                $scope.colorValue = '#9ccccb';

                $scope.$broadcast("JOBDESCRIPTIONS_ADDED", { 'id': data.id, 'description': data.description, 'timetocomplete': data.timetocomplete,
                    'price': data.price, 'appearincustomerlogin': data.appearincustomerlogin, 'appearinwebbooking': data.appearinwebbooking, 'skills': data.skills,
                    'jobcolor': data.jobcolor, 'todoCount': data.todoCount, 'todoResult': data.todoResult, 'todoTableName': data.todoTableName});
            }
            $scope.saving = false;
        });
    }
}

function ListJobDescriptionsCtrl($scope, $state, prefix, $http, jobdescriptionsresolver, defaultPagingValue, canLoad, $q, warningModal, skills_data) {
    $scope.jobDescriptions = jobdescriptionsresolver.jobDescriptions;
    $scope.totalJobDescriptions = jobdescriptionsresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.searchText = '';
    $scope.skills_data = skills_data;
    $scope.todoModule = jobdescriptionsresolver.todoModule;
    $scope.$on("List_JobdescriptionsSetLimitPage", function (event, message) {
        $scope.fetchJobDescriptions(message.pagevalue)
    });


    JustAddedUpdatedChanges.call(this, $scope, 'jobdescription', $state, defaultPagingValue, 'jobDescriptions');

    $scope.$watch('searchText', function (newVal, oldVal) {
        if ((newVal != undefined) && (newVal != oldVal)) {
            $scope.currentPage = 1;
            $scope.fetchJobDescriptions($scope.currentPage);
        }
    });

    var canceler = null;
    $scope.fetchJobDescriptions = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        if (searchText) {
            $scope.closeDelt = false;
        }
        else {
            $scope.closeDelt = true;
        }

        $http.get(prefix + '/list_JobDescriptions?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.jobDescriptions = data.jobDescriptions;
            $scope.totalJobDescriptions = data.count;

            jobdescriptionsresolver.jobDescriptions = $scope.jobDescriptions;
            jobdescriptionsresolver.count = $scope.totalJobDescriptions;

            if (searchText != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
            $scope.triggerJustAddedUpdatedTags();
            $scope.$emit('SetJobDescriptionLimitPage', {'page': pageNum, 'limit': $scope.limit});
        })
    }
    $scope.$emit('SetJobDescriptionLimitPage', {'page': $scope.currentPage, 'limit': $scope.limit});

    $scope.blurCallback = function () {
        $scope.focused = false;

    }

    $scope.$on("JOBDESCRIPTIONS_ADDED", function (event, message) {
        $scope.searchText = '';

        GetCurrentPage.call(this, $scope, $state, jobdescriptionsresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.jobDescriptions.push({'id': message.id, 'description': message.description, 'timetocomplete': message.timetocomplete,
                'price': message.price, 'appearincustomerlogin': message.appearincustomerlogin, 'appearinwebbooking': message.appearinwebbooking, 'skills': message.skills,
                'jobcolor': message.jobcolor, 'todoCount': message.todoCount, 'todoResult': message.todoResult, 'todoTableName': message.todoTableName, 'justadded': true});
            jobdescriptionsresolver.jobDescriptions = $scope.jobDescriptions;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        jobdescriptionsresolver.count = parseInt(jobdescriptionsresolver.count) + 1
        $scope.totalJobDescriptions = jobdescriptionsresolver.count;

    });

    $scope.$on("JOBDESCRIPTIONS_RETURN", function (event, message) {
        $scope.searchText = '';
        $scope.fetchJobDescriptions($scope.currentPage);
    });

    $scope.editJobDescriptions = function (id, description, timetocomplete, price, appearincustomerlogin, appearinwebbooking, jobcolor, jobDescriptionObj) {
        $state.transitionTo('loggedin.job_descriptions.edit', {'id': id, 'description': description, 'timetocomplete': timetocomplete, 'price': price, 'appearincustomerlogin': appearincustomerlogin, 'appearinwebbooking': appearinwebbooking, 'jobcolor': jobcolor, 'pageNum': $scope.currentPage, 'limit': $scope.limit, 'skills': JSON.stringify(jobDescriptionObj.skills), 'skills_data': JSON.stringify($scope.skills_data) })
    }

    $scope.triggerDelete = function (id) {
        $http.get(prefix + '/check_job_description_delete?id=' + id).success(function (data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            } else {
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });
    }
}

function JobDescriptionsEditCtrl($scope, $state, $http, prefix, jobdescriptionsresolver, defaultPagingValue) {

    $scope.selectedJobDescriptions = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.colorValue = $state.params.jobcolor;
    $scope.selectedSkills = JSON.parse($state.params.skills);
    $scope.skills_data = JSON.parse($state.params.skills_data);
    $scope.selectedJobTimetocomplete = ($state.params.timetocomplete == null || $state.params.timetocomplete == '') ? 0 : $state.params.timetocomplete;
    if ($state.params.price == 0) {
        $scope.selectedJobPrice = "";
    }
    else {
        $scope.selectedJobPrice = $state.params.price;
    }

    if ($state.params.appearincustomerlogin == 'true') {
        $scope.selectedJobAppearincustomerlogin = true;
    }
    else {
        $scope.selectedJobAppearincustomerlogin = false;
    }
    if ($state.params.appearinwebbooking == 'true') {
        $scope.selectedJobAppearinwebbooking = true;
    }
    else {
        $scope.selectedJobAppearinwebbooking = false;
    }

    $scope.colorValuehide = true;
    $scope.editcolorValue = $state.params.jobcolor;

    $scope.listTimetocomplete = jobdescriptionsresolver.listTimetocomplete;

    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.job_descriptions");
    }

    $scope.changeTime = function () {
        $scope.selectedJobTimetocomplete = 0;
    }

    $scope.$on('colorpicker:diary_color', function(evt,data) {
        $scope.colorValue = data.color;
    });

    SkillsManager.call(this, $scope, $scope.skills_data);

    $scope.editJobDescriptions = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        var skills = '';
        if(this.selectedSkills && this.selectedSkills.length) {
            angular.forEach(this.selectedSkills, function (val, key) {
                var concat = (skills != '' ? "," : "");
                skills += concat + val.id;
            });
        }
        $scope.updating = true;
        $scope.current = this;
        var price = this.selectedJobPrice == null ? "" : this.selectedJobPrice;
        var timetoComplete = this.selectedJobTimetocomplete == null ? 0 : this.selectedJobTimetocomplete;
        $http.post(prefix + '/edit_jobdescriptions', "jobdescription[id]=" + this.selectedId + "&jobdescription[description]=" + encodeURIComponent(this.selectedJobDescriptions) + "&jobdescription[timetocomplete]=" + timetoComplete + "&jobdescription[price]=" + price + "&jobdescription[appearincustomerlogin]=" + this.selectedJobAppearincustomerlogin + "&jobdescription[appearinwebbooking]=" + encodeURIComponent(this.selectedJobAppearinwebbooking) + "&jobdescription[jobcolor]=" + this.colorValue + "&skills=" + skills).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editJobForm['selectedJobDescriptions'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.job_descriptions");
                    }
                }
            });
    }
}

function JobDescriptionsToDoCtrl($scope, $state, $http, prefix, datasets, formService, $rootScope, $timeout, getIdData, defaultPagingValue) {
   $scope.todolist = true;
    $scope.jobDescriptions = getIdData.jobDescriptions;
    $scope.todocategory = getIdData.todoCategory;
    $scope.jobDescription = $scope.jobDescriptions.description;
    $scope.jobId =  $scope.jobDescriptions.id;
    $scope.todos = getIdData.resultTodos;
    $scope.todoCertificate = getIdData.todoCertificate;

    if (getIdData.todoCount != 0) {
        $scope.viewTemplateUrl = "template/settings/jobdescriptions/existing_todos.html";
        $scope.editTemplateUrl = "template/settings/jobdescriptions/edit_todo.html";
        $scope.addTemplateUrl = "template/settings/jobdescriptions/add_todo.html";
        $scope.existingTodos = true;
        $scope.addTodo = true;
    }else{
        $scope.addTodo = false;
    }

    $scope.distodos = function () {
        $scope.addTodo = true;
        $scope.addTemplateUrl = "template/settings/jobdescriptions/add_todo.html";
        $scope.editTemplateUrl = "";
        $scope.viewTemplateUrl = '';
    }

    $scope.cancelEditTodo = function () {
        $scope.addTemplateUrl = "template/settings/jobdescriptions/add_todo.html";
        $scope.cancelAddingTodo();
        $scope.editingTodo = false;
    }

    $scope.editTodo = function (index, categoryId, categoryName, todoId, todoDescription, todoCertificateId) {
        $scope.$broadcast('event:edit_job_description_todo', {paramsList:arguments, todos: $scope.todos});
        $scope.editingTodo = true;
    }

    $scope.fetchSetupTodos = function (id) {
        $http.get(prefix + '/system_settings/job_descriptions/' + id +'/todos' ).success(function (data) {
            $scope.jobDescriptions = data.jobDescriptions;
            $scope.jobDescription = $scope.jobDescriptions.description;
            $scope.jobId =  data.jobDescriptions.id;
            $scope.todos = data.resultTodos;
            $scope.todocategory = data.todoCategory;
            $scope.todoCertificate = data.todoCertificate;
            if (data.todoCount != 0) {
                $scope.viewTemplateUrl = "template/settings/jobdescriptions/existing_todos.html";
                $scope.editTemplateUrl = "template/settings/jobdescriptions/edit_todo.html";
                $scope.addTemplateUrl = "template/settings/jobdescriptions/add_todo.html";
                $scope.existingTodos = true;
                $scope.addTodo = true;
            }
        });
    }

    $scope.deleteTodo = function (todoId) {

        $http.post(prefix + '/delete_todo', "todos[id]=" + todoId).
            success(function (data, status) {
                if (status == 200) {
                    $scope.fetchSetupTodos($scope.jobId);
                }

            });
    }

    $scope.$on('event:fetch_todos_list', function(){
        $scope.fetchSetupTodos($scope.jobId);
    });

    HandleJobDescriptionTodo.call(this, $scope, prefix);
}

csmodule.controller('SettingTodoEditCtrl', function($scope, $http, prefix) {
    HandleJobDescriptionTodo.call(this, $scope, prefix);
    $scope.editingTodo = false;
    $scope.todos = [];

    $scope.$on('event:edit_job_description_todo', function(event, message){
        $scope.editingTodo = true;
        var paramsList = message.paramsList;
        $scope.todos = message.todos;
        $scope.editTodo(paramsList[0],paramsList[1],paramsList[2],paramsList[3],paramsList[4],paramsList[5]);
    });

    $scope.cancelEditTodo = function () {
        $scope.$parent.cancelEditTodo();
        $scope.editingTodo = false;
    }

    $scope.editTodo = function (index, categoryId, categoryName, todoId, todoDescription, todoCertificateId) {
        $scope.addTemplateUrl = "";
        $scope.editingTodo = true;
        $scope.editCategoryName = categoryName;
        $scope.editCategoryId = categoryId;
        $scope.editToDoId = todoId;
        $scope.listToDo = categoryId;
        $scope.listCertificateEdit = todoCertificateId;
        $scope.selectedTodoId = categoryId;

        $scope.todoIndex = index;

        $scope.labelTodoName = categoryName;

        if (categoryId == 1 || categoryId == 2) {
            $scope.selectedDescription = todoDescription;
        } else if(categoryId == 6 || categoryId == 3) {
            var todoObj = _.where($scope.todos, { todoId: todoId })[0];
            if(categoryId != 3) {
                $scope.partId = {id: todoObj.partId, text: todoObj.partDescription};
                $scope.quantity = parseInt(todoObj.quantity);
            }
            $scope.howlong = todoObj.howLong;
            $scope.selectedDescription = todoDescription;
        }
        $scope.handleCategory('');

        if ($scope.listCertificateEdit) {
            $("#certifcateFilter").select2("val", $scope.listCertificateEdit);
            $scope.listCertificate = $scope.listCertificateEdit;
        }

        $("#edit-category-select").focus(function () {
            if ($(this).find("option").eq(0).text() == '') {
                $(this).find("option").eq(0).remove();
            }
        });
        $scope.selectedDescription = todoDescription;
    }

    $scope.editsaveChanges = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        var categoryName = $scope.selectedTodoName;
        var todo = JSON.parse($scope.listToDo);
        var categoryId = todo.todoId;
        var description = "Help text related to " + categoryName;

        if (!categoryId) {
            categoryId = $scope.editCategoryId;
        }

        var certificateId = '';
        if (categoryId == 3) {
            certificateId = $("#certifcateFilter").val();
        } else if ((categoryId == 1) || (categoryId == 2) || (categoryId == 6)) {
            description = $scope.selectedDescription;
        }

        var installTodoParams = '';
        if($scope.selectedTodoId == 6) {
            installTodoParams = '&todos[partid]=' + $scope.partId.id + '&todos[quantity]=' + $scope.quantity + '&todos[howlong]=' + $scope.howlong;
        } else if($scope.selectedTodoId == 3) {
            installTodoParams = '&todos[howlong]=' + $scope.howlong;
        }

        $http.post(prefix + '/edit_todo', "todos[id]=" + $scope.editToDoId + "&todos[description]=" + encodeURIComponent(description) + "&todos[tablepkid]=" + $scope.jobId + "&todos[certificateid]=" + certificateId + "&todos[settingsTodocategoriesid]=" + categoryId + installTodoParams).
            success(function (data, status) {
                if (status == 200) {
                    if (data.error) {
                        $scope.errorMsg = true;
                        $scope.updating = false;
                    }
                    else {
                        $scope.cancelEditTodo();
                        $scope.fetchSetupTodos($scope.jobId);
                        $scope.updating =  false;
                        $scope.handleCategory(0);
                    }
                }

            });
    }
})

function HandleJobDescriptionTodo($scope, prefix) {
    $scope.handleCategory = function (toDo) {
        $scope.showActions = true;
        $scope.showError = false;

        var todoId, todoCategory = "";
        if (toDo == '') {
            if ($scope.editCategoryId) {
                todoId = $scope.editCategoryId;
                todoCategory = $scope.editCategoryName;
                $scope.listToDoEmpty = "";
            }
        } else if (toDo) {
            // In edit section if the user change only the category, then the descripiton will not delete.
            if (($scope.editCategoryName != "undefined") && ($scope.editCategoryName == '')) {
                $scope.selectedDescription = "";
            }

            var toDo = JSON.parse(toDo);
            todoId = toDo.todoId;
            todoCategory = toDo.todoCategory;
            $scope.listToDoEmpty = "";

            $scope.partId = '';
            $scope.quantity = 1;
            $scope.howlong = 1;
        } else {
            $scope.listToDoEmpty = "empty";
            $scope.showError = true;
            $scope.selectedDescription = "";
        }

        $scope.selectedTodoId = todoId;

        $scope.selectedTodoType = "";
        $scope.selectedDescription = "";
        $scope.requiredCertificate = false;
        $scope.requiredDescription = false;

        if (todoId == 1 || todoId == 2 || todoId == 6) {
            $scope.selectedTodoType = "input";
            $scope.requiredDescription = true;
            $scope.requiredCertificate = false;
        } else if (todoId == 3) {
            $scope.selectedDescription = "";
            $scope.selectedTodoType = "select";
            $scope.listCertificate = '';
            $scope.requiredCertificate = true;
            $scope.requiredDescription = false;
        } else if (todoId == 4 || todoId == 5) {
            $scope.selectedTodoType = "no-next-step";
            $scope.requiredCertificate = false;
            $scope.requiredDescription = false;
        }

        $scope.selectedTodoName = todoCategory;
        if (toDo == '') {
            $scope.selectedTodoType == 'input' ? handleFocus('exist-description-input') : false;
        } else {
            $scope.selectedTodoType == 'input' ? handleFocus('adddescription-input') : false;
        }

    }

    var handleFocus = function (element) {
        setTimeout(function () {
            document.getElementById(element).focus();
        }, 1);
    }

    $scope.cancelAddingTodo = function () {
        $scope.selectedDescription = '';
        $scope.listToDoEmpty = "empty";
        $scope.listCertificate = '';
        $scope.listToDo = '';
        $scope.editCategoryId = '';

        $scope.showActions = false;
        $scope.selectedTodoType = '';

        $scope.selectedTodoType = "";
        $scope.listToDoEmpty = "empty";
        $scope.selectedDescription = '';
        $scope.editCategoryId = '';
        $scope.editCategoryName = '';
        $scope.listToDo = '';
        $scope.selectedTodoId = "";

        // This condition used, in edit section remove the empty option in "Edit Todos Category dropdown"
        $("#category-select").focus(function () {
            if ($(this).find("option").eq(0).text() == '') {
                $(this).find("option").eq(0).remove();
            }
        });

        $scope.handleCategory('');

        $scope.showActions = false;
        $scope.requiredCertificate = false;
        $scope.requiredDescription = false;
        $scope.showSError = $scope.showDError = false;
    }

    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_parts',
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }
}

csmodule.controller('SettingTodoAddCtrl', function($scope, $http, prefix) {
    HandleJobDescriptionTodo.call(this, $scope, prefix);
    $scope.listToDoEmpty = "empty";

    $scope.saveTodo = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        var categoryName = $scope.selectedTodoName;
        var todo = JSON.parse($scope.listToDo);
        var categoryId = todo.todoId;
        var description = "Help text related to " + categoryName;

        if ((categoryId == 1) || (categoryId == 2) || (categoryId == 6)) {
            description = $scope.selectedDescription;
        }

        var installTodoParams = '';
        if($scope.selectedTodoId == 6) {
            installTodoParams = '&todos[partid]=' + $scope.partId.id + '&todos[quantity]=' + $scope.quantity + '&todos[howlong]=' + $scope.howlong;
        } else if($scope.selectedTodoId == 3) {
            installTodoParams = '&todos[howlong]=' + $scope.howlong;
        }

        this.toDoForm.$setPristine();

        $http.post(prefix + '/save_todo', "&todos[description]=" + encodeURIComponent(description) + "&todos[certificateid]=" + $scope.listCertificate + "&todos[tablepkid]=" + $scope.jobId + "&todos[settingsTodocategoriesid]=" + categoryId + installTodoParams).
            success(function (data, status) {
                if (status == 200) {
                    if (data.error == "Exists") {
                        $scope.errorMsg = true;
                        $scope.updating = false;
                    } else {
                        $scope.cancelAddingTodo();
                        $scope.existingTodos = $scope.addTodo = true;
                        $scope.$emit('event:fetch_todos_list');
                        $scope.updating = $scope.errorMsg = false;
                        $scope.handleCategory(0);
                        $scope.showActions = false;
                    }
                }

            });
    }
})

function JobSummaryDesignCtrl($scope, $http, $state, datasets, prefix, $rootScope) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $http.get(prefix + '/jobSummaryTemplateValues').success(function (data) {
        $scope.date = data.date;
    })
}

function EngineerJobSheetEmailCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function EngineerJobSheetSmsCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function CustomerJobConfirmationCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);

    $scope.letterPreview = function (Letter) {
        $scope.previewData = Letter;
        $scope.shouldBeOpenLetterPreview = true;
    };
}

function WorkAddressJobConfirmationCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);

    $scope.letterPreview = function (Letter) {
        $scope.previewData = Letter;
        $scope.shouldBeOpenLetterPreview = true;
    };
}

function JobReminderCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function FreeOfChargeReasonsCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.freeOfChargeReasonsForm['freeofchargereason[description]'].$error.checkExists = true;
            } else {
                $scope.freeOfChargeReasonsForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("FREEOFCHARGEREASON_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function FreeOfChargeReasonsListEditCtrl($scope, $state, prefix, $http, chargereasonresolver, defaultPagingValue) {
    $scope.chargeReason = chargereasonresolver.chargeReason;
    $scope.totalChargeReason = chargereasonresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'entity', $state, defaultPagingValue, 'chargeReason');

    $scope.$on("FREEOFCHARGEREASON_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, chargereasonresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.chargeReason.push({'id': message.id, 'description': message.description, 'justadded': true});
            chargereasonresolver.chargeReason = $scope.chargeReason;
            $state.current.data.addedId = -1;
        } else {
            $scope.currentPage = $scope.currentNewPage;
        }
        chargereasonresolver.count = parseInt(chargereasonresolver.count) + 1
        $scope.totalChargeReason = chargereasonresolver.count;

    });

    $scope.editChargeReasonType = function (id, description) {
        $state.transitionTo('loggedin.freeofcharge.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.fetchChargeReason = function (pageNum) {
        $http.get(prefix + '/listFreeOfChargeReasons?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.chargeReason = data.chargeReason;
            $scope.totalChargeReason = data.count;
            chargereasonresolver.chargeReason = data.chargeReason;
            chargereasonresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function FreeOfChargeReasonsEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedFreeOfChargeReason = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.freeofcharge");
    }

    $scope.editChargeReason = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_freeOfChargeReason', "freeofchargereason[id]=" + this.selectedId + "&freeofchargereason[description]=" + encodeURIComponent(this.selectedFreeOfChargeReason)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editFreeOfChargeReasonsForm['selectedFreeOfChargeReason'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.freeofcharge");
                    }
                }
            });
    }
}

function AbortReasonCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.abortReasonForm['abortreason[description]'].$error.checkExists = true;
            } else {
                $scope.abortReasonForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("ABORTREASON_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function AbortReasonEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedAbortReason = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.abortreason");
    }

    $scope.editAbort = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_abortreason', "abortreason[id]=" + this.selectedId + "&abortreason[description]=" + encodeURIComponent(this.selectedAbortReason)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editAbortReasonForm['selectedAbortReason'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.abortreason");
                    }
                }
            });
    }
}

function AbortReasonListEditCtrl($scope, $state, prefix, $http, abortreasonsresolver, defaultPagingValue) {
    $scope.abortReasons = abortreasonsresolver.abortReasons;
    $scope.totalAbortReasons = abortreasonsresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'abortReason', $state, defaultPagingValue, 'abortReasons');

    $scope.$on("ABORTREASON_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, abortreasonsresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.abortReasons.push({'id': message.id, 'description': message.description, 'justadded': true});
            abortreasonsresolver.abortReasons = $scope.abortReasons;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        abortreasonsresolver.count = parseInt(abortreasonsresolver.count) + 1
        $scope.totalAbortReasons = abortreasonsresolver.count;

    });

    $scope.editAbortReason = function (id, description) {
        $state.transitionTo('loggedin.abortreason.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.fetchAbortReasons = function (pageNum) {
        $http.get(prefix + '/listAbortReason?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.abortReasons = data.abortReasons;
            $scope.totalAbortReasons = data.count;
            abortreasonsresolver.abortReasons = data.abortReasons;
            abortreasonsresolver.count = data.count;

            $scope.triggerJustAddedUpdatedTags();

        })
    }
}

function CertificateEmailMessageCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
    $scope.postProcessCheck = function(data, status) {
        $rootScope.$emit("email_footer_changed");
        return true;
    }
    $scope.saveResizedImageSuccess = function(data) {
        var editor = tinyMCE.activeEditor;
        // Append to end of content
        editor.dom.add(editor.getBody(), "img", { src: data.message.file } , "");

        // Add it to the scope
        this.message = editor.getContent();
    }
}

function DefaultStatementMessageCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function PrintAndPostCoverLetterCtrl($scope, $http, $state, datasets, $rootScope, prefix) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
    $scope.coverLetterPdfShow = Routing.generate('printAndPostCoveringLetterPreview');
    $scope.letterPreview = function (message) {
        $scope.previewData = $scope.coverLetterPdfShow + "?message=";
        $scope.shouldBeOpenLetterPreview = true;
    };
}

function CustomCertificatesCtrl($scope, $state, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.customCertificatesPanelHide = false;
    $scope.saving = false;
    $scope.editboxes = [];
    $scope.editlink = null;
    $scope.deletelink = null;

    $scope.hideOptionsBox = function(index) {
        if($scope.editboxes.indexOf(index) >= 0){
            $scope.editboxes.splice($scope.editboxes.indexOf(index), 1);
        }
        else {

                $scope.editboxes.push(index);

        }
    }

    $scope.showCustomCertificate = function () {
        $scope.customCertificatesPanelHide = false;
        $state.transitionTo('loggedin.customcertificates');
    }
    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $scope.customCertificatesPanelHide = false;
        $state.transitionTo("loggedin.customcertificates");
    }
    $scope.editConfigureCertificates = function (id, certificateName, description) {
        $scope.customCertificatesPanelHide = true;
        $state.transitionTo('loggedin.customcertificates.configure', {'customCertificateId': id, 'certificateName': certificateName, 'description': description })
    }

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.customCertificatesForm['customcertificate[name]'].$error.checkExists = true;

            } else {
                $scope.customCertificatesForm.$setPristine();
                $scope.name = "";
                $scope.description = "";
                $scope.$broadcast("CUSTOMCERTIFICATE_ADDED", { 'id': data.id, 'certificateName': data.certificateName, 'certificateDescription': data.certificateDescription});
            }
            $scope.saving = false;
        });
    }
}

function CustomCertificatesEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedCustomCertificateName = $state.params.certificateName;
    $scope.selectedDescription = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $scope.customCertificatesPanelHide = false;
        $state.transitionTo("loggedin.customcertificates");
    }


    $scope.editCustomCertificates = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/editCustomCertificates', "customcertificate[id]=" + this.selectedId + "&customcertificate[name]=" + encodeURIComponent(this.selectedCustomCertificateName) + "&customcertificate[description]=" + encodeURIComponent(this.selectedDescription)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editCustomCertificateForm['selectedCustomCertificateName'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.customcertificates");
                    }
                }
            });
    }
}

function CustomCertificateListEditCtrl($scope, $state, prefix, $http, customcertificatesresolver, defaultPagingValue) {
    $scope.customCertificates = customcertificatesresolver.customCertificates;
    $scope.totalCustomCertificates = customcertificatesresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'customCertificate', $state, defaultPagingValue, 'customCertificates');

    $scope.$on("CUSTOMCERTIFICATE_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, customcertificatesresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.customCertificates.push({'id': message.id, 'certificateName': message.certificateName, 'description': message.certificateDescription, 'justadded': true});
            customcertificatesresolver.customCertificates = $scope.customCertificates;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        customcertificatesresolver.count = parseInt(customcertificatesresolver.count) + 1
        $scope.totalCustomCertificates = customcertificatesresolver.count;
    });

    $scope.editCustomCertificates = function (id, certificateName, description) {
        $state.transitionTo('loggedin.customcertificates.edit', {'id': id, 'certificateName': certificateName, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, certificateName) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.certificateName = certificateName;
    }

    $scope.fetchCustomCertificates = function (pageNum) {
        $http.get(prefix + '/listCustomCertificates?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.customCertificates = data.customCertificates;
            $scope.totalCustomCertificates = data.count;
            customcertificatesresolver.customCertificates = data.customCertificates;
            customcertificatesresolver.count = data.count;

            $scope.triggerJustAddedUpdatedTags();

        })
    }
}

function CustomCertificatesConfigureCtrl($scope, $state, $http, prefix, datasets, formService, $rootScope, $timeout) {
    $scope.customCertificateId = $state.params.customCertificateId;
    $scope.certificateName = $state.params.certificateName;
    $scope.description = $state.params.description;
    $scope.questionPdfShow = Routing.generate('certificatePreview');

    $scope.questions = [];
    $scope.checkboxes = [];
    $scope.checkboxArray = [];
    $scope.radios = [];
    $scope.radioArray = [];
    $scope.dropdowns = [];
    $scope.dropdownArray = [];

    $http.get(prefix + '/listCertificateQuestions?customCertificateId=' + $scope.customCertificateId).success(function (data) {
        $scope.questions = data.questions;
        $scope.questionsObject =  angular.copy($scope.questions);
    })


    $scope.dropdownOptions = [
        { name: 'Small Text Field', id: '1'},
        { name: 'Large Text Field', id: '2' },
        { name: 'Dropdown', id: '3' },
        { name: 'Checkbox', id: '4' },
        { name: 'Radio', id: '5' }
    ];
    $scope.questionType = $scope.dropdownOptions[0];

    function questionObject(item, questionId, questionName, questionHelptext, questionType, questionRequired, questionOptionArray, customCertificateId) {
        var questionOptionArray = (questionType == 'Small Text Field') || (questionType == 'Large Text Field') ? [] : questionOptionArray;

        return [
            {
                "id": questionId,
                "questionType": {'name': questionType},
                "required": questionRequired,
                "helpText": questionHelptext,
                "name": questionName,
                "questionOptionArray": questionOptionArray,
                "customCertificateId": customCertificateId,
                "index":item
            }
        ];
    }

    $scope.focusInputEdit = function (item, questionId, name, helpText, questionType, required, questionOptionArray) {
        //document.getElementById('edit-question-input').focus();
        if(helpText == "null"){
            this.questions[item].helpText = ''
            helpText = '';
        }
            this.questions[item].questionType.name = questionType;

        this.questions = questionObject(item, questionId, name, helpText, questionType, required, questionOptionArray);
    }

    $scope.add_form_visible = false;

    $scope.focusInput = function () {
        document.getElementById('add-question-input').focus();
    }
    $scope.backOptionError = false;
    // Add Option
    $scope.addOption = function(value, option) {
        if(value != '' && value != undefined){
            $scope.optionCapitalised = option.charAt(0).toUpperCase() + option.slice(1);
            $scope.optionArray = (option == 'checkbox') ? $scope.optionArray = option + 'es' : $scope.optionArray =
                option + 's';
            $scope.optionLabel = 'new' + $scope.optionCapitalised + 'Label'
            $scope.optionId = 'add' + $scope.optionCapitalised + 'Input'

            $scope.this[$scope.optionArray].push({
                value:this.this[$scope.optionLabel]
            });
            this.this[$scope.optionLabel] = '';
            document.getElementById($scope.optionId).focus()
            $scope.backOptionError = false;
        }else{
            $scope.backOptionError = true;
        }
    }

    $scope.backOptionError[0] = false;

    $scope.edit_form_visible = false;

    $scope.saveOption = function(option_index, value, optionName, optionId){
        $scope[optionName] = false;

        var values = $scope.questionsObject;
        var OptionValues = '';
        var Questionstype = '';

        angular.forEach(values, function (value, key) {

            if (values[key].questionOptionDropdownArray) {
                var OptionValues = values[key].questionOptionDropdownArray;
                var Questionstype = 'Dropdown';

            }
            if (values[key].questionOptionCheckboxArray) {
                var OptionValues = values[key].questionOptionCheckboxArray;
                var Questionstype = 'Checkbox';

            }
            if (values[key].questionOptionRadioArray) {
                var OptionValues = values[key].questionOptionRadioArray;
                var Questionstype = 'Radio';
            }

        });

        angular.forEach(OptionValues, function (value, key, Questionstype) {  //this is nested angular.forEach loop

            if (OptionValues[key].optionId == optionId) {

                OptionValues[key].edited = 1;

                if (Questionstype == 'Dropdown') {
                    values[key].questionOptionDropdownArray = OptionValues;

                }
                if (Questionstype == 'Checkbox') {
                    values[key].questionOptionCheckboxArray = OptionValues;

                }
                if (Questionstype == 'Radio') {
                    values[key].questionOptionRadioArray = OptionValues;
                }


            }

        });

        var val = 'backOptionError' + option_index;
        var editVal = optionName + option_index;

        if(value != '' && value != undefined){
            this[optionName] = false;
            $scope[val] = false;
        }else{
            this[optionName] = true;
            $scope[val] = true;
        }
    }

    $scope.deleteOption = function (item, option) {
        $scope.optionArray = (option == 'checkbox') ? $scope.optionArray = option + 'es' : $scope.optionArray = option + 's';
        this.this[$scope.optionArray].splice([item], 1);
    }

    $scope.addOptionInEditMode = function (index, option, value) {
        if (value != '' && value != undefined) {
            var editOptionCapitalised = option.charAt(0).toUpperCase() + option.slice(1),
                editOptionArray = 'questionOption' + editOptionCapitalised + 'Array',
                editOptionLabel = option + 'LabelInEditMode',
                editOptionId = 'add' + editOptionCapitalised + 'InputInEdit';

            if ($scope.questions[index][editOptionArray] === undefined) {
                $scope.questions[index][editOptionArray] = [];
            }

            $scope.questions[index][editOptionArray].push({
                value: this.this[editOptionLabel]
            });

            this.this[editOptionLabel] = '';
            document.getElementById(editOptionId).focus()
            $scope.backOptionError = false;

        } else{
            $scope.backOptionError = true;
        }
    }

    $scope.saveUpdateQuestion = function (questionId, name, helpText, questionType, required, questionOptionArray) {
        $scope.questionTypeText = questionType;
        angular.forEach($scope.dropdownOptions, function (value, key) {
            if (value.name == $scope.questionTypeText) {
                $scope.dropdownoptionvalue = value.id;
            }
        });

        $http.post(prefix + '/editQuestion', "certificatequestion[questiondescription]=" + encodeURIComponent(name) + "&certificatequestion[questionId]=" + questionId + "&certificatequestion[questionhelptext]=" + helpText + "&certificatequestion[questionrequired]=" + required + "&certificatequestion[questiontype]=" + $scope.dropdownoptionvalue + "&certificatequestion[questionOptions]=" + angular.toJson(questionOptionArray)).
            success(function (data, status) {

            angular.forEach($scope.questions, function (questionvalue, index) {

                    if (data.id == questionvalue.id) {

                        $scope.questions[index] = data;

                    }
                });

            });
        this.questions = questionObject(questionId, name, helpText, questionType, required, questionOptionArray);
        this.editing = false;
        $scope.showAddQuestionBtn();
    }

    $scope.previewCertificate = function () {
        $scope.previewData = $scope.questionPdfShow + "?customCertificateId=" + $scope.customCertificateId;
        $scope.shouldBeOpenLetterPreview = true;
    }

    $scope.deleteOptionInEditMode = function (index, questionIndex, option) {
        var editOptionCapitalised = option.charAt(0).toUpperCase() + option.slice(1);
        $scope.editOptionArray = 'questionOption' + editOptionCapitalised + 'Array';
        questionIndex[$scope.editOptionArray][index]. deleted = 1;
    }

    $scope.inEditMode = function () {
        $scope.edit_form_visible = true;
    }

    $scope.outOfEditMode = function () {
        $scope.edit_form_visible = false;
    }

    $scope.hideEditLinks = function () {
        $scope.edit_form_visible = true;
    }

    $scope.showEditLinks = function () {
        $scope.edit_form_visible = false;
    }

    $scope.showCancelLinks = function () {
        $http.get(prefix + '/listCertificateQuestions?customCertificateId=' + $scope.customCertificateId).success(function (data) {
            $scope.questions = data.questions;
            $scope.edit_form_visible = false;
        })
    }

    $scope.showAddQuestionBtn = function () {
        $scope.addQuestionBtnShown = true;
    }

    $scope.addQuestion = function () {
        if (this.questionName == undefined || (this.questionName).length == 0) {
            this.visible = true;
            $scope.add_form_visible = true;
            document.getElementById('add-question-input').focus();
        }
        else {
            $scope.add_form_visible = false;
            this.visible = false;
            $scope.showAddQuestionBtn();
            $scope.checkboxArray.push.apply($scope.checkboxArray, $scope.checkboxes);
            $scope.radioArray.push.apply($scope.radioArray, $scope.radios);
            $scope.dropdownArray.push.apply($scope.dropdownArray, $scope.dropdowns);

            var questionOption = [];
            if (this.checkboxArray != '') {
                var questionOption = this.checkboxArray;
            }
            if (this.radioArray != '') {
                var questionOption = this.radioArray;
            }
            //sample data "&certificatequestion[questionOptions][0][value]=" + "dine"
            if (this.dropdownArray != '') {
                var questionOption = this.dropdownArray;
            }
            $scope.app = '';
            if (questionOption != '') {
                $scope.app = [];
                var i = 0;
                angular.forEach(questionOption, function (val, key) {
                    $scope.app += "&certificatequestion[questionOptions][" + i + "][value]=" + val.value;
                    i++;
                });
            }
            var myscope = this;
            $http.post(prefix + '/addCertificateQuestion', "certificatequestion[questiondescription]=" + encodeURIComponent(this.questionName) + "&certificatequestion[settingscertificateid]=" + $scope.customCertificateId + "&certificatequestion[questionhelptext]=" + (this.helpText === undefined ? "" : this.helpText) + "&certificatequestion[questionrequired]=" + this.required + "&certificatequestion[questiontype]=" + this.questionType.id + $scope.app).
                success(function (data, status) {
                    myscope.questions.push(data);
                });
            this.resetForm();
        }
    }

    $scope.deleteQuestion = function (index, questionDeleteId) {
        $scope.questions.splice(index, 1);
        $http.post(prefix + '/deleteCertificateQuestion', "certificatequestion[id]=" + questionDeleteId).
            success(function (data, status) {
            });
    }

    $scope.cancelQuestion = function () {
        this.$parent.editing = false;
        $scope.add_form_visible = false;
    }

    $scope.resetForm = function () {
        // use $pristine to reset form
        $scope.checkboxes = [];
        $scope.checkboxArray = [];
        $scope.radios = [];
        $scope.radioArray = [];
        $scope.dropdowns = [];
        $scope.dropdownArray = [];
        $scope.add_form_visible = false;
        this.questionName = '';
        this.helpText = '';
        this.required = '';
        this.questionType = $scope.dropdownOptions[0];
    }
}

function ServiceRemindersCtrl($scope, $state, $http, prefix, datasets, formService, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
    $scope.$state=$state;
    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)$/);
        if(matches[2] === 'service_reminder.followup_edit'){
            $scope.servicePanelHide = true;
            $scope.servicePanelFollow = true;
        }else if(matches[2] === 'service_reminder.configure_single'){
            $scope.servicePanelHide = true;
            $scope.servicePanelFollow = false;
        }else if(matches[2] === 'service_reminder.configure_multiple'){
            $scope.servicePanelHide = true;
            $scope.servicePanelFollow = false;
        }else if(matches[2] === 'service_reminder.followup_single'){
            $scope.servicePanelFollow = true;
            $scope.servicePanelHide = true;
        }else if(matches[2] === 'service_reminder.followup_multiple'){
            $scope.servicePanelHide = true;
            $scope.servicePanelFollow = true;
        }else {
            $scope.servicePanelHide = false;
            $scope.servicePanelFollow = false;
        }
    });
    $scope.$on('tabServiceReminder:selected', function (event, data) {
        $scope.selectedId = data.serviceReminderId;
        $scope.type = data.propertyType;
    });
    $scope.getBack = function getBack(selectedType,selectedId) {
        if (selectedType == 1) {
            $state.transitionTo('loggedin.service_reminder.configure_single', {'id': selectedId});
        }
        else if(selectedType == 2)  {
            $state.transitionTo('loggedin.service_reminder.configure_multiple', {'id': selectedId});
        }
    }
    $scope.data = datasets;
    $scope.saving = false;
    $rootScope.flashSuccessMessage = '';
    $scope.close = function () {
        $scope.flashSuccessMessage = '';
    }
    $scope.backServiceReminders = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.service_reminder");
        $scope.servicePanelHide = false
    }
    $scope.backConfigureServiceReminder = function () {
        $scope.$broadcast("SERVICE_REMINDER_CONFIGURE");
    }
    $scope.configureServiceReminder = function (type, id, name, advanced_section) {
        $http.get(prefix + '/listConfigureServiceReminder?serviceReminderId=' + id + '&propertyType=' + type).success(function (data) {
            $scope.servicePanelHide = true;
            $scope.advanced_section = advanced_section;
            $state.transitionTo('loggedin.service_reminder.configure', {'type': type, 'id': id, 'name': name, 'serviceReminder': angular.toJson(data.serviceReminder), 'followUpMessageCollection': angular.toJson(data.followUpMessageCollection)});
        })
    }
    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.serviceReminderForm['servicereminder[name]'].$error.checkExists = true;
            } else {
                $scope.serviceReminderForm.$setPristine();
                $scope.name = "";
                $scope.sendletters = false;
                $scope.serviceperiod = "";
                $scope.sendreminders = "";
                $scope.settingsjobdescriptionid = "";
                $scope.$broadcast("SERVICE_REMINDER_ADDED", { 'id': data.id, 'name': data.name, 'sendLetters': data.sendLetters, 'servicePeriod': data.servicePeriod, 'sendReminders': data.sendReminders, 'settingsJobDescriptionId': data.settingsJobDescriptionId});
            }
            $scope.saving = false;
        });
    }
}

function ServiceRemindersNotificationCtrl($scope, $state, $http, prefix, datasets, $rootScope){
    $scope.data = datasets;
    $scope.userGroupDetails = datasets.dropdownDetails;
    $scope.selectedVal = datasets.selectedVal;

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.checkNotification = function () {
        $http.post(prefix + '/system_settings/service_reminder_notification', "serviceremindernotification[description]=" + $scope.selectedUserOrGroup).
            success(function (data, status) {
                if (status == 200) {
                    $state.transitionTo("loggedin.system_settings");
                }
            }
        );
    }
}


function ServiceRemindersEditCtrl($scope, $state, $http, prefix, serviceremindersresolver,defaultPagingValue,jobdesciptionresolver) {
    $scope.selectedId = $state.params.id;
    $scope.selectedServiceReminderName = $state.params.name;
    $scope.selectedSendLetters = $state.params.sendLetters;
    $scope.showPrintPost = serviceremindersresolver.showPrintPost;
    $scope.jobDescriptions = jobdesciptionresolver.jobDescriptions;
    if ($state.params.servicePeriod) {
        $scope.selectedServiceReminderServicePeriod = $state.params.servicePeriod;
    } else {
        $scope.selectedServiceReminderServicePeriod = 0;
    }
    if ($state.params.sendReminders) {
        $scope.selectedServiceReminderSendReminders = $state.params.sendReminders;
    } else {
        $scope.selectedServiceReminderSendReminders = 0;
    }
    if($state.params.settingsJobDescriptionId){
        $scope.selectedSettingsJobDescriptionId = $state.params.settingsJobDescriptionId;
    }else{
        $scope.selectedSettingsJobDescriptionId = '';
    }


    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.service_reminder");
    }

    $scope.editServiceReminder = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        var params ="servicereminder[id]=" + this.selectedId + "&servicereminder[name]=" + encodeURIComponent(this.selectedServiceReminderName) + "&servicereminder[sendletters]=" + encodeURIComponent(this.selectedSendLetters) + "&servicereminder[serviceperiod]=" + encodeURIComponent(this.selectedServiceReminderServicePeriod) + "&servicereminder[sendreminders]=" + encodeURIComponent(this.selectedServiceReminderSendReminders);
        params = params + "&servicereminder[settingsjobdescriptionid]=" + this.selectedSettingsJobDescriptionId;
        $http.post(prefix + '/edit_service_reminder', params).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editServiceReminderForm['selectedServiceReminderName'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.service_reminder");
                    }
                }
            });
    }
}

function ServiceRemindersListEditCtrl($scope, $state, prefix, $http, serviceremindersresolver, defaultPagingValue) {
    $scope.serviceReminder = serviceremindersresolver.serviceReminder;
    $scope.totalServiceReminder = serviceremindersresolver.count;
    $scope.shouldBeOpen = false;
    $scope.cannotDelete = false;    // Initially all records are deletable
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'service', $state, defaultPagingValue, 'serviceReminder');

    $scope.$on("SERVICE_REMINDER_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, serviceremindersresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.serviceReminder.push({'id': message.id, 'name': message.name, 'sendLetters': message.sendLetters, 'servicePeriod': message.servicePeriod, 'sendReminders': message.sendReminders, 'settingsJobDescriptionId':message.settingsJobDescriptionId, 'justadded': true});
            serviceremindersresolver.serviceReminder = $scope.serviceReminder;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        serviceremindersresolver.count = parseInt(serviceremindersresolver.count) + 1
        $scope.totalServiceReminder = serviceremindersresolver.count;
    });

    $scope.editServiceReminder = function (id, name, sendLetters, servicePeriod, sendReminders, settingsJobDescriptionId) {
        $state.transitionTo('loggedin.service_reminder.edit', {'id': id, 'name': name, 'sendLetters': sendLetters, 'servicePeriod': servicePeriod, 'sendReminders': sendReminders, 'settingsJobDescriptionId': settingsJobDescriptionId, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, deleteServiceReminderName) {
        $http.get(prefix + '/is_active_service_reminder?id=' + id).success(function(data) {
           if (data === "true") {
               $scope.cannotDelete = true;
           }  else {
               $scope.cannotDelete = false;
               $scope.selectedId = id;
               $scope.shouldBeOpen = true;
               $scope.selectedDeleteServiceReminderName = deleteServiceReminderName;
           }
        });

    }

    $scope.fetchServiceReminder = function (pageNum) {
        $http.get(prefix + '/listServiceReminder?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.serviceReminder = data.serviceReminder;
            $scope.totalServiceReminder = data.count;
            serviceremindersresolver.serviceReminder = data.serviceReminder;
            serviceremindersresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}
function CheckExistServiceReminderIntervalCtrl($scope,data){
    angular.forEach(data, function (val) {
        if (val.reminderinterval == '2 Weeks') {
            $scope.intervalTwo = false;
        } else if (val.reminderinterval == '3 Weeks') {
            $scope.intervalThree = false;
        } else if (val.reminderinterval == '4 Weeks') {
            $scope.intervalFour = false;
        } else if (val.reminderinterval == '5 Weeks') {
            $scope.intervalFive = false;
        }
        if($scope.intervalTwo == false && $scope.intervalThree == false && $scope.intervalFour == false && $scope.intervalFive == false) {
            $scope.showAddAnotherFollowup = false;
        }else{
            $scope.showAddAnotherFollowup = true;
        }
    });
}

function ConfigureServiceRemindersSingleCtrl($scope, $state, $http, prefix, $timeout, $rootScope, getIdData) {
    $scope.servicePanelHide = true;

    $scope.types =getIdData.propertyType;
    if ($scope.types == 1) {
        $scope.selectedType = 'single';
        $scope.stateName ='loggedin.service_reminder.followup_single';
    }
    else if($scope.types == 2)  {
        $scope.selectedType = 'multiple';
        $scope.stateName ='loggedin.service_reminder.followup_multiple';
    }
    $scope.$emit('tabServiceReminder:selected', getIdData);
    $scope.followUpMessageCollection = angular.fromJson(getIdData.followUpMessageCollection);
    $scope.smsModuleAccess = getIdData.smsModuleAccess;
    CheckExistServiceReminderIntervalCtrl.call(this, $scope, getIdData.followUpMessageCollection);


    $scope.selectedId = getIdData.serviceReminderId;
    $scope.selectedServiceReminderName = getIdData.serviceName;
    $scope.selectedServiceReminders = angular.fromJson(getIdData.serviceReminder);

    if(getIdData.serviceReminder.length > 0 && $scope.types == 1){
        if (( $scope.selectedServiceReminders[0]['subject'] != null) || ($scope.selectedServiceReminders[0]['message'] != null)) {
            $scope.serviceRemindersEmailsubject = $scope.selectedServiceReminders[0]['subject'];
            $scope.serviceRemindersEmailmessage = $scope.selectedServiceReminders[0]['message'];
            $scope.serviceRemindersNameId =$scope.selectedServiceReminders[0]['nameId'];
            $scope.reminderinterval = $scope.selectedServiceReminders[0]['reminderinterval'];
            $scope.email_done = true;
        }
        if ($scope.selectedServiceReminders[1]['message'] != null) {
            $scope.serviceRemindersSmsmessage  = $scope.selectedServiceReminders[1]['message'];
            $scope.serviceRemindersNameId =$scope.selectedServiceReminders[1]['nameId'];
            $scope.reminderinterval = $scope.selectedServiceReminders[1]['reminderinterval'];
            $scope.sms_done = true;
        }
        if ($scope.selectedServiceReminders[2]['message'] != null) {
            $scope.serviceRemindersLettermessage = $scope.selectedServiceReminders[2]['message'];
            $scope.serviceRemindersLetterTypeId =$scope.selectedServiceReminders[2]['nameId'];
            $scope.reminderinterval = $scope.selectedServiceReminders[2]['reminderinterval'];
            $scope.letter_done = true;
        }
    }else if(getIdData.serviceReminder.length > 0 && $scope.types == 2){
        if (( $scope.selectedServiceReminders[0]['subject'] != null) || ($scope.selectedServiceReminders[0]['message'] != null)) {
            $scope.serviceRemindersEmailsubject = $scope.selectedServiceReminders[0]['subject'];
            $scope.serviceRemindersEmailmessage = $scope.selectedServiceReminders[0]['message'];
            $scope.serviceRemindersNameId =$scope.selectedServiceReminders[0]['nameId'];
            $scope.reminderinterval = $scope.selectedServiceReminders[0]['reminderinterval'];
            $scope.email_done = true;
        }
        if ($scope.selectedServiceReminders[1]['message'] != null) {
            $scope.serviceRemindersLettermessage = $scope.selectedServiceReminders[1]['message'];
            $scope.serviceRemindersNameId =$scope.selectedServiceReminders[1]['nameId'];
            $scope.reminderinterval = $scope.selectedServiceReminders[1]['reminderinterval'];
            $scope.letter_done = true;
        }
    }else{
        $scope.serviceRemindersNameId = '';
        $scope.serviceRemindersEmailmessage = '';
        $scope.serviceRemindersEmailsubject = '';
        $scope.serviceRemindersLettermessage = '';
        $scope.serviceRemindersSmsmessage = '';

    }



    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;
    $scope.shouldBeOpen = false;
    $scope.triggerDeleteFollowup = function (id) {
        $scope.selectedFollowupId = id;
        $scope.shouldBeOpen = true;

    }

    $scope.letterPreview = function (message) {
        $scope.previewData = message;
        $scope.shouldBeOpenLetterPreview = true;
    };

    $scope.$on('tabServiceReminder:successMessage', function (event, data) {
        $rootScope.flashSuccessMessage = data;
        $timeout(function () {
            $("#serviceReminderSaveMessage").fadeIn();
            $("#serviceReminderSaveMessage").fadeOut(4000);
            $("#saveMessage").show();
        });
    });

    if ($rootScope.flashMessage) {
        $scope.$emit('tabServiceReminder:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
    $scope.remindername='';
    $scope.fetchFollowup =function(followUpMessageCollection){
        $http.post(prefix + '/system_settings/service_reminder/' + $scope.selectedId + '/configure/' + $scope.selectedType).success(function (data){
            $scope.selectedId = data.serviceReminderId;
            $scope.selectedServiceReminderName = data.serviceName;
            $scope.selectedServiceReminders = angular.fromJson(data.serviceReminder);
            $scope.serviceRemindersNameId =$scope.selectedServiceReminders[0]['nameId'];
            $scope.followUpMessageCollection = angular.fromJson(data.followUpMessageCollection);
            CheckExistServiceReminderIntervalCtrl.call(this, $scope, data.followUpMessageCollection);
            if(data.followUpMessageCollection.length == 4) {
                $scope.showAddAnotherFollowup = false;
            }else{
                $scope.showAddAnotherFollowup = true;
            }
        });
        $scope.shouldBeOpen = false;
    }


    $scope.processReminderInterval = false;
    $scope.checkedServiceInterval = false;

    $scope.reminderIntervalChange = function(reminderinterval) {
        $http.get(prefix + '/checkServiceReminderInterval/' + $scope.serviceRemindersNameId +'/'+ reminderinterval).success(function (data) {
                $scope.checkedServiceInterval = data.value;
                $scope.reminderIntervalValue = data.interval;
                $scope.processReminderInterval = false;
        })
    };
    $scope.$on('modal:close',function(){
        $scope.processReminderInterval = false;
          $scope.checkedServiceInterval = false;
        $http.get(prefix + '/checkServiceReminderInterval/' + $scope.serviceRemindersNameId +'/'+ $scope.reminderIntervalValue).success(function (data) {
            $scope.checkedServiceInterval = data.value;
            $scope.processReminderInterval = false;
        })
    });


    $scope.configureServiceReminderMessage = function ($event, formStatus) {
        $scope.current = this;
        if($scope.checkedServiceInterval == true){
            $scope.processReminderInterval = true;
            $scope.postParams = "id=" + this.selectedId +'&reminderinterval=' + encodeURIComponent(this.reminderinterval) +'&remindername=' + encodeURIComponent(this.remindername) +
                "&propertyType=" + $scope.types + "&serviceRemindersNameId=" + this.serviceRemindersNameId+
                "&serviceRemindersEmailsubject=" + encodeURIComponent(this.serviceRemindersEmailsubject) +"&serviceRemindersEmailmessage=" + encodeURIComponent(this.serviceRemindersEmailmessage) +
                "&serviceRemindersSmsmessage=" + encodeURIComponent(this.serviceRemindersSmsmessage) + "&serviceRemindersLettermessage=" +encodeURIComponent(this.serviceRemindersLettermessage);
        }
        if($scope.checkedServiceInterval == false){
            $event.preventDefault();
            if (!formStatus) {
                return;
            }
            $scope.updating = true;
            $http.post(prefix + '/editConfigureServiceReminder', "id=" + this.selectedId +'&reminderinterval=' + encodeURIComponent(this.reminderinterval) +'&remindername=' + encodeURIComponent(this.remindername) +
                "&propertyType=" + $scope.types + "&serviceRemindersNameId=" + this.serviceRemindersNameId+
                "&serviceRemindersEmailsubject=" + encodeURIComponent(this.serviceRemindersEmailsubject) +"&serviceRemindersEmailmessage=" + encodeURIComponent(this.serviceRemindersEmailmessage) +
                "&serviceRemindersSmsmessage=" + encodeURIComponent(this.serviceRemindersSmsmessage) + "&serviceRemindersLettermessage=" +encodeURIComponent(this.serviceRemindersLettermessage)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.$emit('tabServiceReminder:successMessage', data);
                    $http.get(prefix + '/system_settings/service_reminder/' + $scope.selectedId + '/configure/' + $scope.selectedType).success(function (data){
                        $scope.selectedId = data.serviceReminderId;
                        $scope.selectedServiceReminderName = data.serviceName;
                        $scope.selectedServiceReminders = angular.fromJson(data.serviceReminder);
                        $scope.serviceRemindersNameId =$scope.selectedServiceReminders[0]['nameId'];
                    });
                    $scope.updating = false;
                    $state.$current.parent.self.data.pageNum = 1;
                    $state.$current.parent.self.data.limit = 5;
                    $scope.processReminderInterval = false;
                    $scope.checkedServiceInterval = false;
                }
            });
        }
    }

}
function ConfigureServiceRemindersMultipleCtrl($scope, $state, $http, prefix, $timeout, $rootScope, getIdData){
    ConfigureServiceRemindersSingleCtrl.call(this, $scope, $state, $http, prefix, $timeout, $rootScope, getIdData);
}

function FollowUpServiceRemindersSingleCtrl($scope, $state, $http, prefix, $rootScope, getIdData) {
    $scope.$emit('tabServiceReminder:selected', getIdData);
    $scope.types =getIdData.propertyType;
    if ($scope.types == 1) {
        $scope.selectedType = 'single';
        $scope.stateName ='loggedin.service_reminder.configure_single';
    }
    else if($scope.types == 2)  {
        $scope.selectedType = 'multiple';
        $scope.stateName ='loggedin.service_reminder.configure_multiple';
    }
    $scope.selectedId = getIdData.serviceReminderId;
    $scope.selectedServiceReminderName = getIdData.serviceName;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;
    $scope.shouldBeOpen = false;
    $scope.followUpMessages = angular.fromJson(getIdData.followUpmesage);
    $scope.followUpMessageCollection = angular.fromJson(getIdData.followUpMessageCollection);
    $scope.smsModuleAccess = getIdData.smsModuleAccess;
    $scope.intervalTwo = $scope.intervalThree = $scope.intervalFour = $scope.intervalFive = true;
    $scope.followUpMessageInterval = 14;

    angular.forEach(getIdData.followUpMessageCollection, function (val) {
        if (val.reminderinterval == '2 Weeks') {
            $scope.intervalTwo = false;
        } else if (val.reminderinterval == '3 Weeks') {
            $scope.intervalThree = false;
        } else if (val.reminderinterval == '4 Weeks') {
            $scope.intervalFour = false;
        } else if (val.reminderinterval == '5 Weeks') {
            $scope.intervalFive = false;
        }
        if ($scope.intervalTwo == false && $scope.intervalThree == true && $scope.intervalFour == true && $scope.intervalFive == true) {
            $scope.followUpMessageInterval = 21;
        } else if ($scope.intervalTwo == true && $scope.intervalThree == false && $scope.intervalFour == true && $scope.intervalFive == true) {
            $scope.followUpMessageInterval = 14;
        } else if ($scope.intervalTwo == false && $scope.intervalThree == false && $scope.intervalFour == true && $scope.intervalFive == true) {
            $scope.followUpMessageInterval = 28;
        } else if ($scope.intervalTwo == false && $scope.intervalThree == false && $scope.intervalFour == true && $scope.intervalFive == false) {
            $scope.followUpMessageInterval = 28;
        }  else if ($scope.intervalTwo == false && $scope.intervalThree == false && $scope.intervalFour == false && $scope.intervalFive == true) {
            $scope.followUpMessageInterval = 35;
        } else if ($scope.intervalTwo == false && $scope.intervalThree == false && $scope.intervalFour == false && $scope.intervalFive == false && getIdData.followUpMessageEdit == false) {
            if ($scope.selectedType == 'single') {
                $state.transitionTo("loggedin.service_reminder.configure_single", {'id': $scope.selectedId});
            } else if ($scope.selectedType == 'multiple') {
                $state.transitionTo("loggedin.service_reminder.configure_multiple", {'id': $scope.selectedId});
            }
        }
    });
    if(getIdData.followUpmesage.length > 0 && $scope.types == 1) {
        if (( $scope.followUpMessages[0]['subject'] != null) || ($scope.followUpMessages[0]['message'] != null)) {
            $scope.email_done = true;
            $scope.followUpEmailsubject = $scope.followUpMessages[0]['subject'];
            $scope.followUpEmailmessage = $scope.followUpMessages[0]['message'];
            $scope.serviceRemindersNameId=$scope.followUpMessages[0]['nameId'];
            $scope.followUpMessageInterval = $scope.followUpMessages[0]['reminderinterval'];
            $scope.followUpMessageName = $scope.followUpMessages[0]['remindername'];
        }
        if ($scope.followUpMessages[1]['message'] != null) {
            $scope.sms_done = true;
            $scope.followUpSmsmessage= $scope.followUpMessages[1]['message'];
            $scope.serviceRemindersNameId =$scope.followUpMessages[1]['nameId'];
            $scope.followUpMessageInterval = $scope.followUpMessages[1]['reminderinterval'];
            $scope.followUpMessageName = $scope.followUpMessages[1]['remindername'];
        }
        if ($scope.followUpMessages[2]['message'] != null) {
            $scope.letter_done = true;
            $scope.followUpLettermessage= $scope.followUpMessages[2]['message'];
            $scope.serviceRemindersNameId =$scope.followUpMessages[2]['nameId'];
            $scope.followUpMessageInterval = $scope.followUpMessages[2]['reminderinterval'];
            $scope.followUpMessageName = $scope.followUpMessages[2]['remindername'];
        }
    }else if(getIdData.followUpmesage.length > 0 && $scope.types == 2){
        if (( $scope.followUpMessages[0]['subject'] != null) || ( $scope.followUpMessages[0]['message'] != null)) {
            $scope.email_done = true;
            $scope.followUpEmailsubject = $scope.followUpMessages[0]['subject'];
            $scope.followUpEmailmessage = $scope.followUpMessages[0]['message'];
            $scope.serviceRemindersNameId=$scope.followUpMessages[0]['nameId'];
            $scope.followUpMessageInterval = $scope.followUpMessages[0]['reminderinterval'];
            $scope.followUpMessageName = $scope.followUpMessages[0]['remindername'];
        }
        if ($scope.followUpMessages[1]['message'] != null) {
            $scope.letter_done = true;
            $scope.followUpLettermessage= $scope.followUpMessages[1]['message'];
            $scope.serviceRemindersNameId =$scope.followUpMessages[1]['nameId'];
            $scope.followUpMessageInterval = $scope.followUpMessages[1]['reminderinterval'];
            $scope.followUpMessageName = $scope.followUpMessages[1]['remindername'];
        }
    }else{
        $scope.serviceRemindersNameId = '';
        $scope.followUpEmailmessage = '';
        $scope.followUpEmailsubject  = '';
        $scope.followUpLettermessage = '';
        $scope.followUpSmsmessage = '';
    }

    $scope.letterPreview = function (message) {
        $scope.previewData = message;
        $scope.shouldBeOpenLetterPreview = true;
    };

    $scope.followUpMessage = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.current = this;
        $scope.updating = true;
        $http.post(prefix + '/editConfigureServiceReminder', "id=" + this.selectedId +'&reminderinterval=' + encodeURIComponent(this.followUpMessageInterval) +'&remindername=' + encodeURIComponent(this.followUpMessageName) +
                "&propertyType=" + $scope.types +  "&serviceRemindersNameId=" + this.serviceRemindersNameId+
                "&serviceRemindersEmailsubject=" + encodeURIComponent(this.followUpEmailsubject) +"&serviceRemindersEmailmessage=" + encodeURIComponent(this.followUpEmailmessage) +
                "&serviceRemindersSmsmessage=" + encodeURIComponent(this.followUpSmsmessage) + "&serviceRemindersLettermessage=" +encodeURIComponent(this.followUpLettermessage)).
            success(function (data, status) {
                if (status == 200) {
                    if (data.error && data.error == 'Exists') {
                        $scope.current.followUpMessageForm['followUpMessageName'].$error.checkExists = true;
                    } else {
                        if($scope.selectedType =='single'){
                            $state.transitionTo("loggedin.service_reminder.configure_single",{'id': $scope.selectedId});
                        }else if($scope.selectedType =='multiple'){
                            $state.transitionTo("loggedin.service_reminder.configure_multiple",{'id': $scope.selectedId});
                        }
                        $rootScope.flashMessage = data;
                    }
                    $scope.updating = false;
                    $state.$current.parent.self.data.pageNum = 1;
                    $state.$current.parent.self.data.limit = 5;
                }
            })
    }
}
function FollowUpServiceRemindersMultipleCtrl($scope, $state, $http, prefix, $rootScope, getIdData){
    FollowUpServiceRemindersSingleCtrl.call(this, $scope, $state, $http, prefix, $rootScope, getIdData);
}
function FollowUpServiceRemindersEditCtrl($scope, $state, $http, prefix, $rootScope, getIdData){
    FollowUpServiceRemindersSingleCtrl.call(this, $scope, $state, $http, prefix, $rootScope, getIdData);
}


function StatementEmailMessageCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

// function InvoiceEmailMessageCtrl($scope, $http, $state, datasets, $rootScope) {
//     EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
// }

function InvoiceCategoriesCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.invoiceCategoriesForm['invoicecategory[description]'].$error.checkExists = true;
            } else {
                $scope.invoiceCategoriesForm.$setPristine();
                $scope.description = "";
                $scope.thirdpartyreference = "";
                $scope.$broadcast("INVOICECATEGORIES_ADDED", { 'id': data.id, 'description': data.description, 'thirdpartyreference' : data.thirdpartyreference});
            }
            $scope.saving = false;
        });
    }
}

function InvoiceCategoriesEditCtrl($scope, $state, $http, prefix, invoicecategoriesresolver) {
    $scope.selectedInvoiceCategory = $state.params.description;
    $scope.selectedThirdparty = $state.params.thirdpartyreference;
    $scope.AIPackageId = invoicecategoriesresolver.AIPackageId;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.invoicecategories");
    }
    $scope.changeThirdpartyCode = function (thirdpartyCode) {
        $scope.selectedThirdparty = thirdpartyCode;
    }
    $scope.thirdpartyreferences = {};
    if(invoicecategoriesresolver.thirdPartyInvoiceCategoriesCount > 0) {
        $scope.thirdpartyreferences = invoicecategoriesresolver.thirdPartyInvoiceCategories;
    }
    $scope.editInvoice = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_invoiceCategories', "invoicecategory[id]=" + this.selectedId + "&invoicecategory[description]=" + encodeURIComponent(this.selectedInvoiceCategory) + "&invoicecategory[thirdpartyreference]=" + encodeURIComponent(this.selectedThirdparty)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editInvoiceCategoryForm['selectedInvoiceCategory'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.invoicecategories");
                    }
                }
            });
    }
}

function InvoiceCategoriesListEditCtrl($scope, $state, prefix, $http, invoicecategoriesresolver, defaultPagingValue) {
    $scope.invoiceCategories = invoicecategoriesresolver.invoiceCategories;
    $scope.totalInvoiceCategories = invoicecategoriesresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'invoiceCategory', $state, defaultPagingValue, 'invoiceCategories');

    $scope.$on("INVOICECATEGORIES_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, invoicecategoriesresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.invoiceCategories.push({'id': message.id, 'description': message.description, 'thirdpartyreference' : message.thirdpartyreference, 'justadded': true});
            invoicecategoriesresolver.invoiceCategories = $scope.invoiceCategories;
            $state.current.data.addedId = -1;
        } else {
            $scope.currentPage = $scope.currentNewPage;
        }
        invoicecategoriesresolver.count = parseInt(invoicecategoriesresolver.count) + 1
        $scope.totalInvoiceCategories = invoicecategoriesresolver.count;
    });

    $scope.editInvoiceCategory = function (id, description,thirdpartyreference) {
        $state.transitionTo('loggedin.invoicecategories.edit', {'id': id, 'description': description, 'thirdpartyreference' : thirdpartyreference, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, invoiceCategory) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.invoiceRecord = invoiceCategory;
    }

    $scope.fetchInvoiceCategories = function (pageNum) {
        $http.get(prefix + '/listInvoiceCategories?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.invoiceCategories = data.invoiceCategories;
            $scope.totalInvoiceCategories = data.count;
            invoicecategoriesresolver.invoiceCategories = data.invoiceCategories;
            invoicecategoriesresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function InvoiceItemCategoriesCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.invoiceItemCategoriesForm['invoiceitemcategory[description]'].$error.checkExists = true;
            } else {
                $scope.invoiceItemCategoriesForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("INVOICEITEMCATEGORIES_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function InvoiceItemCategoriesEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedInvoiceItemCategory = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.invoiceitemcategories");
    }

    $scope.editInvoiceItem = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/editInvoiceItemCategories', "invoiceitemcategory[id]=" + this.selectedId + "&invoiceitemcategory[description]=" + encodeURIComponent(this.selectedInvoiceItemCategory)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editInvoiceItemCategoryForm['selectedInvoiceItemCategory'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.invoiceitemcategories");
                    }
                }
            });
    }
}

function InvoiceItemCategoriesListEditCtrl($scope, $state, prefix, $http, invoiceitemcategoriesresolver, defaultPagingValue) {
    $scope.invoiceItemCategories = invoiceitemcategoriesresolver.invoiceItemCategories;
    $scope.totalInvoiceItemCategories = invoiceitemcategoriesresolver.count;
    $scope.shouldBeOpen = false;
    $scope.cannotDelete = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'invoiceItemCategory', $state, defaultPagingValue, 'invoiceItemCategories');

    $scope.$on("INVOICEITEMCATEGORIES_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, invoiceitemcategoriesresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.invoiceItemCategories.push({'id': message.id, 'description': message.description, 'justadded': true});
            invoiceitemcategoriesresolver.invoiceItemCategories = $scope.invoiceItemCategories;
            $state.current.data.addedId = -1;
        } else {
            $scope.currentPage = $scope.currentNewPage;
        }
        invoiceitemcategoriesresolver.count = parseInt(invoiceitemcategoriesresolver.count) + 1
        $scope.totalInvoiceItemCategories = invoiceitemcategoriesresolver.count;
    });

    $scope.editInvoiceItemCategory = function (id, description) {
        $state.transitionTo('loggedin.invoiceitemcategories.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.fetchInvoiceItemCategories = function (pageNum) {
        $http.get(prefix + '/listInvoiceItemCategories?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.invoiceItemCategories = data.invoiceItemCategories;
            $scope.totalInvoiceItemCategories = data.count;
            invoiceitemcategoriesresolver.invoiceItemCategories = data.invoiceItemCategories;
            invoiceitemcategoriesresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }

    $scope.triggerDelete = function (id, invoiceItemCategory) {
        $http.get(prefix + '/is_active_invoice_item_category?id=' + id).success(function(data) {
            if (data === "false") {
                $scope.cannotDelete = true;
            }  else {
                $scope.cannotDelete = false;
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
                $scope.invoiceRecord = invoiceItemCategory;
            }
        });
    }
}

function PaymentMethodCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.paymentMethodForm['paymentMethod[description]'].$error.checkExists = true;
            } else {
                $scope.paymentMethodForm.$setPristine();
                $scope.description = "";
                $scope.showcard = 0;
                $scope.$broadcast("PAYMENTMETHOD_ADDED", { 'id': data.id, 'description': data.description, 'enableCardCharge': data.showCard });
            }
            $scope.saving = false;
        });
    }
}

function PaymentMethodEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedPaymentMethod = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;
    $scope.card = {'cardChargesPermissionInEdit':$scope.$parent.cardChargesPermission,'enableCardCharge':$state.params.enableCardCharge};
    $scope.enableCardCharge = $state.params.enableCardCharge;
    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.payment_methods");
    }

    $scope.editPaymentMethod = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_paymentmethods', "paymentMethod[id]=" + this.selectedId + "&paymentMethod[showcard]="+ this.card.enableCardCharge +"&paymentMethod[description]=" + encodeURIComponent(this.selectedPaymentMethod)).
            success(function (data, status) {
                $scope.updating = false;
                if (data.error && data.error == 'Exists') {
                    $scope.current.editPaymentMethodForm['selectedPaymentMethod'].$error.checkExists = true;
                } else {
                    $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                    $state.$current.parent.self.data.editedId = $scope.selectedId;
                    $state.$current.parent.self.data.limit = $scope.limit;
                    $state.transitionTo("loggedin.payment_methods");
                }
            });
    }
}

function PaymentMethodsListEditCtrl($scope, $state, prefix, $http, paymentMethodresolver, defaultPagingValue) {
    $scope.paymentMethods = paymentMethodresolver.paymentMethods;
    $scope.totalPaymentMethods = paymentMethodresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.$parent.cardChargesPermission = paymentMethodresolver.isCardPermission;

    JustAddedUpdatedChanges.call(this, $scope, 'paymentMethod', $state, defaultPagingValue, 'paymentMethods');


    $scope.$on("PAYMENTMETHOD_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, paymentMethodresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.paymentMethods.push({'id': message.id, 'description': message.description,'justadded': true , 'showCard': message.enableCardCharge});
            paymentMethodresolver.paymentMethods = $scope.paymentMethods;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        paymentMethodresolver.count = parseInt(paymentMethodresolver.count) + 1
        $scope.totalPaymentMethods = paymentMethodresolver.count;
    });


    $scope.editPayment = function (id, description,showCard) {
        $state.transitionTo('loggedin.payment_methods.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit, 'enableCardCharge':showCard})
    }

    $scope.triggerDelete = function (id) {
        
        $http.get(prefix + '/is_active_payment_method?id=' + id).success(function(data) {
            if (data !== "") {
                $scope.cannotDelete = true;
            }  else {
                $scope.cannotDelete = false;
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
                //$scope.invoiceRecord = invoiceItemCategory;
            }
        });
    }

    $scope.fetchPaymentMethods = function (pageNum) {
        $http.get(prefix + '/listPaymentMethods?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.paymentMethods = data.paymentMethods;
            $scope.totalPaymentMethods = data.count;
            paymentMethodresolver.paymentMethods = data.paymentMethods;
            paymentMethodresolver.count = data.count;

            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function InvoiceTermsAndConditionsCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);

    $scope.letterPreview = function (message) {
        $scope.previewData = message;
        $scope.shouldBeOpenLetterPreview = true;
    };
}

function AfterSalesCommunicationCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);

    $scope.letterPreview = function (Letter) {
        $scope.previewData = Letter;
        $scope.shouldBeOpenLetterPreview = true;
    };
}

function PurchaseOrderEmailMessageCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}
function SupplierPartsQuotationEmail($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function SupplierPartsReminderEmail($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function BccEmail($scope, $http, $state, datasets, $rootScope,prefix) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.invalidEmail = false;
    $scope.saving = false;
    $scope.validateEmail = function(){
        Email = $scope.value.split(',');
        angular.forEach(Email, function(value, key){
            var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var isEmailAddress = emailRegex.test(value);
            $scope.invalidEmail = (isEmailAddress == true || value == "") ? false : true;
        });
    }
}

function IndustryCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.industryForm['industry[description]'].$error.checkExists = true;
            } else {
                $scope.industryForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("INDUSTRY_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function IndustriesEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedIndustry = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.industries");
    }

    $scope.editIndustry = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_industry', "industry[id]=" + this.selectedId + "&industry[description]=" + encodeURIComponent(this.selectedIndustry)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editIndustryForm['selectedIndustry'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.industries");
                    }
                }
            });
    }
}

function IndustriesListEditCtrl($scope, $state, prefix, $http, industryresolver, defaultPagingValue, warningModal) {
    $scope.industries = industryresolver.industries;
    $scope.totalIndustries = industryresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'industry', $state, defaultPagingValue, 'industries');

    $scope.$on("INDUSTRY_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, industryresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.industries.push({'id': message.id, 'description': message.description, 'justadded': true});
            industryresolver.industries = $scope.industries;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        industryresolver.count = parseInt(industryresolver.count) + 1
        $scope.totalIndustries = industryresolver.count;

    });

    $scope.editIndustry = function (id, description) {
        $state.transitionTo('loggedin.industries.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id) {
        $http.get(prefix + '/is_industry_delete?id=' + id).success(function (data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.fetchIndustries = function (pageNum) {
        $http.get(prefix + '/listIndustries?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.industries = data.industries;
            $scope.totalIndustries = data.count;
            industryresolver.industries = data.industries;
            industryresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function CategoriesCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.imageUrl = "";
    $scope.error = false;
    $scope.errorMsg = "";
    $scope.imgElem = document.createElement('img');

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.categoryForm['category[description]'].$error.checkExists = true;

            } else {
                $scope.categoryForm['category[description]'].$error.checkExists = false;
                $scope.categoryForm.$setPristine();
                $scope.description = "";
                $scope.imageUrl = "";
                document.getElementById('imageCont').innerHTML = "";
                $scope.$broadcast("RESET_PROGRESS");
                $scope.$broadcast("CATEGORY_ADDED", { 'id': data.id, 'description': data.description, 'imageUrl': data.imageUrl});
            }
            $scope.saving = false;
        });
    }

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
            $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
            $scope.imageUrl = '';
            $scope.$broadcast("RESET_PROGRESS");
        } else {
            $scope.error = false;
            $scope.errorMsg = '';
            $scope.imgElem.src = resp.url;
            $scope.imgElem.classList.add('image-preview');
            document.getElementById('imageCont').append($scope.imgElem);
            // $rootScope.flashMessage = "File.Uploaded";
            $scope.imageUrl = resp.message;
        }
    }
}

function CategoriesListEditCtrl($scope, $state, prefix, $http, categoryresolver, defaultPagingValue, warningModal) {
    $scope.categories = categoryresolver.categories;
    $scope.totalCategories = categoryresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'category', $state, defaultPagingValue, 'categories');

    $scope.$on("CATEGORY_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, categoryresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.categories.push({'id': message.id, 'description': message.description, 'imageUrl': message.imageUrl, 'justadded': true});
            categoryresolver.categories = $scope.categories;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        categoryresolver.count = parseInt(categoryresolver.count) + 1
        $scope.totalCategories = categoryresolver.count;

    });

    $scope.editCategory = function (id, description, imageUrl) {
        $state.transitionTo('loggedin.category.edit', {'id': id, 'description': description,'imageUrl': imageUrl, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id) {
        $http.get(prefix + '/is_category_delete?id=' + id).success(function (data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.fetchCategories = function (pageNum) {
        $http.get(prefix + '/listCategories?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.categories = data.categories;
            $scope.totalCategories = data.count;

            categoryresolver.categories = $scope.categories;
            categoryresolver.count = $scope.totalCategories;

            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function CategoriesEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedCategory = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.imageUrl = $state.params.imageUrl;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.categoryImageUrl = "";
    if ($scope.imageUrl) {
        if ($scope.imageUrl.includes('clientfiles')) {
            $scope.categoryImageUrl = $scope.imageUrl;
        } else {
            $scope.categoryImageUrl = $scope.imageUrl + '?version=' + Math.random();
        }
    }
    $scope.error = false;
    $scope.errorMsg = '';

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.category");
    }

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
            $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
            $scope.imageUrl = '';
            $scope.$broadcast("RESET_PROGRESS");
        } else {
            $scope.error = false;
            $scope.errorMsg = '';
            $scope.categoryImageUrl = resp.url;
            $scope.imageUrl = resp.message;
        }
    }

    $scope.editCategory = function ($event, formStatus) {

        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_category', "category[id]=" + this.selectedId + "&category[description]=" + encodeURIComponent(this.selectedCategory) + "&category[url]=" + encodeURIComponent(this.imageUrl)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editCategoryForm['selectedCategory'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.category");
                    }
                }
            });
    }
}

function SubCategoriesCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.error = false;
    $scope.errorMsg = '';
    $scope.imageUrl = '';
    $scope.imgElem = document.createElement('img');

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.subCategoriesForm['subcategory[description]'].$error.checkExists = true;

            } else {
                $scope.subCategoriesForm.$setPristine();
                $scope.settingscategoriesid = "";
                $scope.description = "";
                document.getElementById('imageCont').innerHTML = "";
                $scope.$broadcast("RESET_PROGRESS");
                $scope.$broadcast("SUB_CATEGORIES", { 'id': data.id, 'description': data.description, 'imageUrl': data.imageUrl ,'settingsCategoriesDescription': data.settingsCategoriesDescription, 'categoriesId': data.categoriesId });
            }
            $scope.saving = false;
        });
    }

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
            $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
            $scope.imageUrl = '';
            $scope.$broadcast("RESET_PROGRESS");
        } else {
            $scope.error = false;
            $scope.imgElem.src = resp.url;
            $scope.imgElem.classList.add('image-preview');
            document.getElementById('imageCont').append($scope.imgElem);
            // $rootScope.flashMessage = "File.Uploaded";
            $scope.imageUrl = resp.message;
        }
    }
}

function ListSubCategoriesCtrl($scope, $state, prefix, $http, $timeout, subcategoriesresolver, defaultPagingValue, canLoad, $q, warningModal) {
    $scope.subcategories = subcategoriesresolver.subcategories;
    if (subcategoriesresolver == "") {
        $scope.totalSubCategories = 0;
        $scope.categorieslist = [];
    } else {
        $scope.totalSubCategories = subcategoriesresolver.count;
        $scope.categorieslist = subcategoriesresolver.categorieslist;
    }
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.selectedCategories = '';
    $scope.searchText = '';
    $scope.show_filters = true;

    JustAddedUpdatedChanges.call(this, $scope, 'subcategory', $state, defaultPagingValue, 'subcategories');


    $scope.change = function () {
        $scope.fetchSubCategories($scope.currentPage, this.selectedCategories);
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if ((newVal != undefined) && (newVal != oldVal)) {
            $scope.fetchSubCategories($scope.currentPage, '');
        }
    });

    $scope.blurCallback = function () {
        $scope.focused = false;

        $timeout(function() {
          $scope.show_filters = true;
        }, 700);
    }

    var canceler = null;
    $scope.fetchSubCategories = function (pageNum, selectedCategories) {

        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        if (searchText) {
            $scope.closeDelt = false;
        }
        else {
            $scope.closeDelt = true;
        }

        var selCategories = selectedCategories || $scope.selectedCategories;

        $http.get(prefix + '/listSubCategories?page=' + pageNum + '&categories=' + selCategories + '&searchText=' + encodeURIComponent(searchText) + '&limit=' + $scope.limit, {timeout: canceler.promise}).success(function (data) {
            if (data == '') {   // happens if there are no categories selected
                $scope.subcategories = [];
                $scope.totalSubCategories = 0;
            } else {
                $scope.subcategories = data.subcategories;
                $scope.totalSubCategories = data.count;
            }

            $scope.selectedCategories = selCategories;

            subcategoriesresolver.subcategories = $scope.subcategories;
            subcategoriesresolver.count = $scope.totalSubCategories;

            if ((searchText != '') || (selCategories != '')) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
            $scope.triggerJustAddedUpdatedTags();
        })
    }

    $scope.$on("SUB_CATEGORIES", function (event, message) {
        $scope.searchText = '';
        GetCurrentPage.call(this, $scope, $state, subcategoriesresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.subcategories.push({'id': message.id, 'description': message.description,'imageUrl': message.imageUrl, 'settingsCategoriesDescription': message.settingsCategoriesDescription, 'categoriesId': message.categoriesId, 'justadded': true});
            subcategoriesresolver.subcategories = $scope.subcategories;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        subcategoriesresolver.count = parseInt(subcategoriesresolver.count) + 1
        $scope.totalSubCategories = subcategoriesresolver.count;

    });

    $scope.triggerDelete = function (id) {
       $http.get(prefix + '/is_sub_category_delete?id=' + id).success(function (data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.editSubCategories = function (subcategories) {
        $state.transitionTo('loggedin.sub_categories.edit',
            {'id': subcategories.id,
                'description': subcategories.description,
                'settingsCategoriesDescription': subcategories.settingsCategoriesDescription,
                'categoriesId': subcategories.categoriesId,
                'imageUrl': subcategories.imageUrl,
                'pageNum': $scope.currentPage, 'limit': $scope.limit
            })
    }
}

function SubCategoriesEditCtrl($scope, $state, $http, prefix, subcategoriesresolver, defaultPagingValue) {
    $scope.selectedSubCategories = $state.params.description;
    $scope.selectedCategories = $state.params.settingsCategoriesDescription;
    $scope.categoriesId = parseInt($state.params.categoriesId);
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.categorieslist = subcategoriesresolver.categorieslist;
    $scope.limit = $state.params.limit;

    $scope.imageUrl = $state.params.imageUrl;
    $scope.subCategoryImage = "";
    if ($scope.imageUrl) {
        if ($scope.imageUrl.includes('clientfiles')) {
            $scope.subCategoryImage = $scope.imageUrl;
        } else {
            $scope.subCategoryImage = $scope.imageUrl + '?version=' + Math.random();
        }
    }

    $scope.error = false;
    $scope.errorMsg = '';

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.sub_categories");
    }

    $scope.changeCategoriesId = function () {
        $scope.categories = this.categoriesId;
    }

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
            $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
            $scope.imageUrl = '';
            $scope.$broadcast("RESET_PROGRESS");
        } else {
            $scope.error = false;
            $scope.errorMsg = '';
            $scope.imageUrl = resp.message;
            $scope.subCategoryImage = resp.url;
        }
    }

    $scope.editSubCategories = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $http.post(prefix + '/edit_subcategories', "subcategory[id]=" + this.selectedId + "&subcategory[description]=" + encodeURIComponent(this.selectedSubCategories) + "&subcategory[settingsCategoriesid]=" + this.categoriesId + "&subcategory[imageUrl]=" + encodeURIComponent(this.imageUrl)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editSubCategoriesForm['selectedSubCategories'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.sub_categories");
                    }
                }
            });
    }
}

/*function PartsAndPricesCtrl($scope, $http, prefix, datasets, formService, $modal) {
    $scope.data = datasets;
    $scope.saving = false;

    $http.get(prefix + '/countPartsAndPrices' ).success(function (data) {
        $scope.countPartsAndPrices = data;
    });

    $scope.showAdvancedOption = function () {
        $scope.opened = !$scope.opened;
    }

    $scope.onUploadSuccess = function (resp) {
        if (!resp.error) {
            $scope.filePath = resp.message;
            $scope.displayError = '';
        } else {
            $scope.errorMessage = resp.message;
            $scope.displaySuccess = '';
        }
    }

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.addPartsAndPricesForm['partsandprices[description]'].$error.checkExists = true;
                $scope.addPartsAndPricesForm.$setValidity(false);
            } else {
                $scope.addPartsAndPricesForm.$setPristine();
                $scope.description = "";
                $scope.show_slected_industry = "";
                $scope.show_slected_category = "";
                $scope.show_selected_type = "1";
                $scope.manufacturepartnumber = "";
                $scope.price = "";
                $scope.markup = "";
                $scope.installtime = "";
                $scope.show_slected_taxItemId = "";
                $scope.$broadcast("RESET_PROGRESS");
                $scope.$broadcast("PARTS_ANDPRICES", { 'id': data.id, 'description': data.description,
                    'type': data.type,
                    'industryId': data.industryId,
                    'subCategoryId': data.subCategoryId,
                    'manufacturePartNumber': data.manufacturePartNumber,
                    'defaultInstallTime': data.defaultInstallTime,
                    'price': data.price,
                    'markUp': data.markUp,
                    'taxItemId': data.taxItemId});
            }
            $scope.saving = false;
        });
    }

    $scope.selectedCsvType = function selectedCsvType(partlist) {
        $scope.csvType = 'standardPartList';
        if (partlist == 1) {
            $scope.$broadcast('fileUpload:disabled', false);
            $scope.csvType = 'standardPartList';
            $scope.columns = [
                {name: 'Industry', isinuse: false},
                {name: 'Category', isinuse: false},
                {name: 'Sub category', isinuse: false},
                {name: 'Part name', isinuse: false},
                {name: 'Type', isinuse: false},
                {name: 'Manufacture part number', isinuse: false},
                {name: 'Default install time', isinuse: false},
                {name: 'Cost', isinuse: false},
                {name: 'Markup', isinuse: false},
                {name: 'Tax', isinuse: false}
            ];
        } else {
            $scope.$broadcast('fileUpload:disabled', true);
            $scope.csvType = 'supplierPartList';
            $scope.columns = [
                {name: 'Part id', isinuse: false},
                {name: 'Part name', isinuse: false},
                {name: 'Manufacture part number', isinuse: false},
                {name: 'Supplier reference', isinuse: false},
                {name: 'Tax', isinuse: false},
                {name: 'Price', isinuse: false}
            ];
        }
        HandleCsvImports.call(this, $scope, $modal);
    }
    $scope.selectedCsvType(1);
    $scope.selectedSupplierId = '';
    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_suppliers',
            data: function (term, page) {
                return { 'q': term };
            },
            results: function (data, page) {
                return { results: data };
            }
        }
    }
    $scope.changeSupplier = function(id) {
        $scope.selectedSupplierId = id;
        if(id != undefined) {
            $scope.$broadcast('fileUpload:disabled', false);
        }
    }
    $scope.openMassUpdateParts = function(){
        $scope.shouldBeMassUpdateParts = true;
    }

    $scope.pastRecords = [];
    $scope.shouldBeOpenDelete = false;
    $scope.shouldBeOpenReactivate = false;
    $scope.selectedId = '';

    $scope.submitForm = function () {
        $scope.csvProps.csvImportIsSaving = true;
        if($scope.csvType == 'standardPartList') {
            $http.post(prefix + '/import_records_from_csv/parts_and_prices', $.param({ flds: $scope.columnHeaders, csvfile: $scope.uploadedFile, containsHeader: $scope.containsHeader})).
                success(function (data) {
                    $scope.csvProps.csvImportHasSaved = true;
                    $scope.csvProps.csvImportIsSaving = false;
                    $scope.fetchPastRecords();
                });
        } else {
            $http.post(prefix + '/import_records_from_csv/supplier_part_and_prices', $.param({ flds: $scope.columnHeaders, csvfile: $scope.uploadedFile, containsHeader: $scope.containsHeader, supplierId: $scope.selectedSupplierId})).
                success(function (data) {
                    $scope.csvProps.csvImportHasSaved = true;
                    $scope.csvProps.csvImportIsSaving = false;
                    $scope.fetchPastRecords();
                });
        }

    }

    HandleCsvImports.call(this, $scope, $modal);

    $scope.fetchPastRecords = function () {
        $http.get(prefix + '/getPastImportRecords').success(function (data) {
            $scope.pastRecords = data.pastRecords;
        })
    }
    $scope.fetchPastRecords();

    $scope.triggerDeleteReactivate = function (selectedId, action) {
        $scope.selectedId = selectedId;
        if (action == "Delete") {
            $scope.shouldBeOpenDelete = true;
        } else {
            $scope.shouldBeOpenReactivate = true;
        }
    }
}

function PartsAndPricesListEditCtrl($scope, $state, prefix, $http, $timeout, partsandpriceresolver, defaultPagingValue, canLoad, $q, warningModal) {
    $scope.partsandprices = partsandpriceresolver.partsandprices;
    $scope.totalPartsAndPrices = partsandpriceresolver.count;
    $scope.industrylist = partsandpriceresolver.industrylist;
    $scope.categorylist = partsandpriceresolver.categorylist;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.show_filters = true;

    JustAddedUpdatedChanges.call(this, $scope, 'partsandprice', $state, defaultPagingValue, 'partsandprices');

    $scope.change = function () {
        $scope.fetchPartsAndPrices($scope.currentPage, this.subCategoryId);
    }

    $scope.blurCallback = function () {
        $scope.focused = false;

        $timeout(function() {
          $scope.show_filters = true;
        } , 700);
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            //$scope.currentPage = 1;
            $scope.fetchPartsAndPrices($scope.currentPage);
        }
    });

    var canceler = null;
    $scope.fetchPartsAndPrices = function (pageNum, selectedCategories) {

        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;

        if (searchText) {
            $scope.closeDelt = false;
        }
        else {
            $scope.closeDelt = true;
        }

        var selCategories = selectedCategories || (($scope.subCategoryId == undefined) ? '' : $scope.subCategoryId);
        $http.get(prefix + '/listPartsAndPrices?page=' + pageNum + '&category=' + selCategories + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.partsandprices = data.partsandprices;
            $scope.totalPartsAndPrices = data.count;
            $scope.subCategoryId = selCategories;
            partsandpriceresolver.partsandprices = $scope.partsandprices;
            partsandpriceresolver.count = data.count;

            if (searchText != '' || selCategories != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }

            $scope.triggerJustAddedUpdatedTags();
        })
    }

    $scope.$on("PARTS_ANDPRICES", function (event, message) {
        $scope.searchText = '';
        GetCurrentPage.call(this, $scope, $state, partsandpriceresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.partsandprices.push({'id': message.id, 'description': message.description, 'type': message.type, 'industryId': message.industryId, 'subCategoryId': message.subCategoryId, 'manufacturePartNumber': message.manufacturePartNumber, 'defaultInstallTime':  message.defaultInstallTime,'price': message.price, 'markUp': message.markUp, 'settingstaxitemid': message.taxItemId, 'justadded': true});
            partsandpriceresolver.partsandprices = $scope.partsandprices;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        partsandpriceresolver.count = parseInt(partsandpriceresolver.count) + 1
        $scope.totalPartsAndPrices = partsandpriceresolver.count;
    });

    $scope.triggerDelete = function (id) {
        $http.get(prefix + '/deletePartsAndPrices/'+ id).success(function (data){
            if(data.warning == true){
                if(data.stockPending){
                    warningModal.clean(data.id);
                    warningModal.show(data.message, data.title, data.id);
                }else{
                    $scope.selectedId = id;
                    $scope.shouldOpenArchive = true;
                }
            }else{
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.editPartsAndPrices = function (partsandprices) {
        $state.transitionTo('loggedin.parts_and_prices.edit',
            {'id': partsandprices.id,
                'description': partsandprices.description,
                'type': partsandprices.type,
                'industryId': partsandprices.industryId,
                'subCategoryId': partsandprices.subCategoryId,
                'manufacturePartNumber': partsandprices.manufacturePartNumber,
                'defaultInstallTime': partsandprices.defaultInstallTime,
                'price': partsandprices.price,
                'markUp': partsandprices.markUp,
                'taxItemId': partsandprices.taxItemId,
                'pageNum': $scope.currentPage, 'limit': $scope.limit
            })
    }
}*/

function PartsAndPricesEditCtrl($scope, $state, $http, prefix, partsandpriceresolver, defaultPagingValue) {
    $scope.selectedDescription = $state.params.description;
    $scope.selectedIndustry = $state.params.industryId == null ? '' : parseInt($state.params.industryId) ;
    $scope.subCategoryId = $state.params.subCategoryId == null ? '' : parseInt($state.params.subCategoryId) ;
    $scope.taxItemId = $state.params.taxItemId == null ? '' : parseInt($state.params.taxItemId) ;
    $scope.selectedPartTypeInEdit = $state.params.type;

    $scope.selectedManufacturePartNumber = $state.params.manufacturePartNumber;
    $scope.selectedDefaultInstallTime = $state.params.defaultInstallTime;
    $scope.selectedPrice = $state.params.price;
    $scope.selectedMarkUp = $state.params.markUp;
    $scope.industrylist = partsandpriceresolver.industrylist;
    $scope.categorylist = partsandpriceresolver.categorylist;
    $scope.taxLists = partsandpriceresolver.taxLists;

    $scope.filePath = '';

    $scope.onUploadSuccess = function (resp) {
        if (!resp.error) {
            $scope.filePath = resp.message;
            $scope.displayError = '';
        } else {
            $scope.errorMessage = resp.message;
            $scope.displaySuccess = '';
        }
    }

    /*$scope.typeOptions = [
        { name: 'Part', id: '1'},
        { name: 'Appliance', id: '2' }
    ];*/

    $scope.typeOptions = partsandpriceresolver.partTypeDropdown;

    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.parts_and_prices");
    }


    $scope.changeCategoriesId = function () {
        $scope.categories = this.categoriesId;
    }

    $scope.editPartsAndPrices = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $http.post(prefix + '/edit_partsAndPrices', "partsandprices[id]=" + this.selectedId + "&partsandprices[description]=" + encodeURIComponent(this.selectedDescription) + "&partsandprices[settingsIndustryid]=" + encodeURIComponent(this.selectedIndustry) + "&partsandprices[settingsSubcategoryid]=" + this.subCategoryId + "&partsandprices[parttype]=" + this.selectedPartTypeInEdit +
            "&partsandprices[manufacturepartnumber]=" + this.selectedManufacturePartNumber + "&partsandprices[defaultInstallTime]=" + this.selectedDefaultInstallTime + "&partsandprices[price]=" + this.selectedPrice + "&partsandprices[markup]=" + this.selectedMarkUp + "&partsandprices[settingstaxitemid]=" + this.taxItemId + "&partsandprices[filePath]=" + this.filePath  ).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editPartsAndPricesForm['selectedDescription'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.parts_and_prices");
                    }
                }
            });
    }
}

function StockLocationsCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.stockLocationForm['stocklocation[description]'].$error.checkExists = true;
            } else {
                $scope.stockLocationForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("STOCKLOCATION_ADDED", { 'id': data.id, 'description': data.description, 'selectedBranch': data.selectedBranch, 'selectedBranchId': data.selectedBranchId, 'error':data.error, 'errorMessage':data.message});
            }
            $scope.saving = false;
        });
    }
}

function StockLocationsListEditCtrl($scope, $state, prefix, $http, stocklocationresolver, defaultPagingValue, warningModal) {
    $scope.stockLocation = stocklocationresolver.stockLocation;
    $scope.totalStockLocations = stocklocationresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'entity', $state, defaultPagingValue, 'stockLocation');

    $scope.$on("STOCKLOCATION_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, stocklocationresolver, message);
        if(message.error){
            warningModal.show(message.errorMessage, 'Location');
        }
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.stockLocation.push({'id': message.id, 'description': message.description, 'selectedBranch': message.selectedBranch, 'selectedBranchId': message.selectedBranchId, 'justadded': true});
            stocklocationresolver.stockLocation = $scope.stockLocation;

            $state.current.data.addedId = -1;
        } else {
            $scope.currentPage = $scope.currentNewPage;
        }
        stocklocationresolver.count = parseInt(stocklocationresolver.count) + 1
        $scope.totalStockLocations = stocklocationresolver.count;
    });

    $scope.editStockLocation = function (id, description, selectedBranch, selectedBranchId) {
        $state.transitionTo('loggedin.stock_locations.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit, 'selectedBranch': selectedBranch, 'selectedBranchId': selectedBranchId})
    }

    $scope.triggerDelete = function (id, description) {
        $http.get(prefix + '/check_delete_stock_location?id=' + id).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            } else {
                $scope.selectedId = id;
                $scope.selectedStockLocation = description;
                $scope.shouldBeOpen = true;
            }
        });
        }
    $scope.fetchStockLocations = function (pageNum) {
        $http.get(prefix + '/listStockLocations?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.stockLocation = data.stockLocation;
            $scope.totalStockLocations = data.count;
            stocklocationresolver.stockLocation = data.stockLocation;
            stocklocationresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function StockLocationsEditCtrl($scope, $state, $http, prefix, stocklocationresolver) {
    $scope.selectedStockLocation = $state.params.description;
    $scope.selectedBranch = $state.params.selectedBranch;
    $scope.selectedBranchId = parseInt($state.params.selectedBranchId);
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;
    $scope.stockLocations = stocklocationresolver.branchLocationList;


    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.stock_locations");
    }

    $scope.editStockLocation = function ($event, formStatus) {
        if (!formStatus) {
            $event.preventDefault();
            return;
        }
        $scope.updating = true;
        $http.post(prefix + '/edit_stockLocations', "stocklocation[id]=" + this.selectedId + "&stocklocation[description]=" + this.selectedStockLocation + "&stocklocation[clientsbranchid]=" + this.selectedBranchId).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editStockLocationsForm['selectedStockLocation'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.transitionTo("loggedin.stock_locations");
                    }
                }
            });
    }
}

function QualificationTypesCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.QualificationForm['qualification[description]'].$error.checkExists = true;
            } else {
                $scope.QualificationForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("QUALIFICATION_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function QualificationListEditCtrl($scope, $state, prefix, $http, qualificationresolver, defaultPagingValue) {
    $scope.qualifications = qualificationresolver.qualifications;
    $scope.countQualification = qualificationresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'entity', $state, defaultPagingValue, 'qualifications');

    $scope.$on("QUALIFICATION_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, qualificationresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.qualifications.push({'id': message.id, 'description': message.description, 'justadded': true});
            qualificationresolver.qualifications = $scope.qualifications;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        qualificationresolver.count = parseInt(qualificationresolver.count) + 1
        $scope.countQualification = qualificationresolver.count;
    });

    $scope.editQualificationType = function (id, description) {
        $state.transitionTo('loggedin.add_qualification.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, description) {
        $scope.selectedId = id;
        $scope.selectedDescription = description;
        $scope.shouldBeOpen = true;
    }

    $scope.fetchAddQualification = function (pageNum) {
        $http.get(prefix + '/listQualifications?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.qualifications = data.qualifications;
            $scope.countQualification = data.count;

            qualificationresolver.qualifications = data.qualifications;
            qualificationresolver.count = data.count;

            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function QualificationEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedQualification = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.add_qualification");
    }

    $scope.editQualification = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/editQualification', "qualification[id]=" + this.selectedId + "&qualification[description]=" + encodeURIComponent(this.selectedQualification)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editQualificationForm['editQualification'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.transitionTo("loggedin.add_qualification");
                    }
                }
            });
    }
}

function UserGroupsCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.userGroupsForm['usergroup[description]'].$error.checkExists = true;
            } else {
                $scope.userGroupsForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("USER_GROUPS", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function UserGroupsEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedUserGroup = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.user_groups");
    }

    $scope.editUserGroups = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $http.post(prefix + '/edit_usergroups', "usergroup[id]=" + this.selectedId + "&usergroup[description]=" + encodeURIComponent(this.selectedUserGroup)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editUserGroupForm['selectedUserGroup'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.user_groups");
                    }
                }
            });
    }
}

function UserGroupsListEditCtrl($scope, $state, prefix, $http, userGroupsresolver, defaultPagingValue, warningModal) {
    $scope.userGroups = userGroupsresolver.userGroups;
    $scope.totalUserGroups = userGroupsresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'entity', $state, defaultPagingValue, 'userGroups');

    $scope.triggerDelete = function (id, entity) {
        $http.get(prefix + '/is_user_group_delete?id=' + id).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
                $scope.usergroup = entity;
            }
        });
    }

    $scope.fetchUserGroups = function (pageNum) {
        $http.get(prefix + '/listUserGroups?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.userGroups = data.userGroups;
            $scope.totalUserGroups = data.count;
            userGroupsresolver.userGroups = data.userGroups;
            userGroupsresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
    $scope.editUserGroup = function (id, description) {
        $state.transitionTo('loggedin.user_groups.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.$on("USER_GROUPS", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, userGroupsresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.userGroups.push({'id': message.id, 'description': message.description, 'justadded': true});
            userGroupsresolver.userGroups = $scope.userGroups;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        userGroupsresolver.count = parseInt(userGroupsresolver.count) + 1
        $scope.totalUserGroups = userGroupsresolver.count;

    });
}

function VehiclesListCtrl($scope, $state, prefix, $http, vehiclesresolver, defaultPagingValue) {
    $scope.vehicles = vehiclesresolver.vehicles;
    $scope.totalVehicles = vehiclesresolver.count;
    $scope.ascending = false;
    $scope.descending = true;
    $scope.makeascending = false;
    $scope.makedescending = true;

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.fetchVehicles($scope.currentPage);
        }
    });

    $scope.fetchVehicles = function (pageNum) {
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        $http.get(prefix + '/listVehicles?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText)).success(function (data) {
            $scope.vehicles = data.vehicles;
            $scope.totalVehicles = data.count;

            if (searchText != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    $scope.createVehicle = function () {
        $scope.saving = true;
        $state.transitionTo("loggedin.add_vehicle");
    }

    // Here Ascending and Descending for registernumber and make fields
    $scope.orderChange = function (ordering) {
        $scope.order = ordering;
        if ($scope.order == 0) {
            $scope.descending = true;
            $scope.ascending = false;
            $scope.name = 'registerAscending';
        } else if ($scope.order == 1) {
            $scope.descending = false;
            $scope.ascending = true;
            $scope.name = 'registerDescending';
        } else if ($scope.order == 2) {
            $scope.makedescending = true;
            $scope.makeascending = false;
            $scope.name = 'makeAscending';
        } else if ($scope.order == 3) {
            $scope.makedescending = false;
            $scope.makeascending = true;
            $scope.name = 'makeDescending';

        }
        $http.get(prefix + '/listVehicles?order=' + $scope.name + '&page=' + $scope.currentPage + '&limit=' + $scope.limit).success(function (data) {
            $scope.vehicles = data.vehicles;
            $scope.totalVehicles = data.count;

        })
    }
}

function MobileEngineerSmsCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function QuestionsCtrl($scope, $http, prefix, datasets, formService, $state) {

    $scope.data = datasets;
    $scope.saving = false;
    $scope.options = [
        {placeholder: 'Option 1'}
    ];

    $scope.isRemovable = function () {
        return $scope.options.length > 1;
    };

    $scope.add = function () {
        $scope.options.push({});
    };

    $scope.remove = function (index) {
        $scope.options.splice(index, 1);
    };
    $scope.settingsType = $state.current.data.settingsType;

    $scope.updateArriveRatioButton = function (value) {
        $http.post(prefix + '/update_arrive_question?yesOrNo=' + value + "&settingsType=" + $scope.settingsType ).success(function (data) {
            $scope.show_checked_div = data;
        })
    }

    $scope.updateCompleteJobRatioButton = function (value) {
        $http.post(prefix + '/update_complete_question?yesOrNo=' + value).success(function (data) {
            $scope.show_checked_div = data;
        })
    }

    $scope.performFormSubmit = function (url, formElem) {
        $scope.setUrl = url;
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                if ($scope.setUrl == "/frontend_dev.php/addCompleteJobQuestion") {
                    $scope.questionsForm['completejobquestion[questiondescription]'].$error.checkExists = true;
                }
                else {
                    $scope.questionsForm['sitearrivalquestion[questiondescription]'].$error.checkExists = true;
                }
            } else {
                $scope.questionsForm.$setPristine();
                $scope.questiondescription = "";
                $scope.questionhelptext = "";
                $scope.questionrequired = false;
                $scope.show_options_div = "";
                $scope.options = [
                    {placeholder: 'Option 1'}
                ];
                $scope.saving = false;
                $scope.$broadcast("QUESTION_ADDED", { 'id': data.id, 'question': data.questiondescription, 'questionHelpText': data.questionhelptext,
                    'questionRequired': data.questionrequired, 'questionType': data.questiontype, 'questionOptions': angular.toJson(data.options)});
            }
            $scope.saving = false;
        });
    }

}

function QuestionsEditCtrl($scope, $state, $http, prefix, $translate, warningModal) {
    $scope.selectedQuestion = $state.params.question;
    $scope.selectedQuestionHelpText = $state.params.questionHelpText;
    $scope.selectedQuestionRequired = $state.params.questionRequired;
    $scope.selectedQuestionType = $state.params.questionType;
    $scope.confirmationNeeded = $state.params.confirmationNeeded;
    $scope.selectedQuestionOptions = angular.fromJson($state.params.questionOptions);
    if ($scope.selectedQuestionOptions == '') {
        $scope.selectedQuestionOptions = [
            {placeholder: 'Option 1'}
        ];
    }
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    if ($state.params.questionType == 4 || $state.params.questionType == 5) {
        $scope.classExpand = 'expanded-sortable';
    } else {
        $scope.classExpand = '';
    }

    if($state.current.data.settingsType == 'Estimate'){
        $scope.backUrl = 'loggedin.site_arrival_questions';
    }else if($state.current.data.settingsType == 'Job'){
        $scope.backUrl = 'loggedin.job_site_arrival_questions';
    }

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo($scope.backUrl);
    }

    $scope.expandDiv = function () {
        $scope.current = this;
        if (this.selectedQuestionType == 4 || this.selectedQuestionType == 5) {
            $scope.classExpand = 'expanded-sortable';
        } else {
            $scope.classExpand = '';
        }
    }

    $scope.isRemovableEdit = function () {
        return $scope.selectedQuestionOptions.length > 1;
    };

    $scope.addEdit = function () {
        $scope.selectedQuestionOptions.push({});
    };

    $scope.removeEdit = function (index) {
        $scope.selectedQuestionOptions.splice(index, 1);
    };

    if($scope.confirmationNeeded) {
        $scope.questionOptions = angular.fromJson($state.params.questionOptions);
        $scope.confirmation = false;
        $scope.handleOptionChange = function(option, index) {
            $scope.oldValue = $scope.questionOptions[index].value;
            if($scope.oldValue != option && $scope.confirmation == false) {
                $scope.confirmation = true;
                $scope.triggerEditQuestion();
            }
        };

        $scope.questionDescription = $state.params.question;
        $scope.handleQuestionChange = function(question) {
            if($scope.questionDescription != question && $scope.confirmation == false) {
                $scope.confirmation = true;
                $scope.triggerEditQuestion();
            }
        }
    }

    $scope.updateQuestion = function() {
        $scope.selectedQuestionOptions = $scope.questionOptions;
        $scope.selectedQuestion = $scope.questionDescription;
    }

    $scope.editQuestion = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;

        $http.post(prefix + '/edit_arrivequestion', "sitearrivalquestion[id]=" + this.selectedId + "&sitearrivalquestion[questiondescription]=" + encodeURIComponent(this.selectedQuestion)
                + "&sitearrivalquestion[questionhelptext]=" + encodeURIComponent(this.selectedQuestionHelpText)
                + "&sitearrivalquestion[questionrequired]=" + encodeURIComponent(this.selectedQuestionRequired)
                + "&sitearrivalquestion[questiontype]=" + encodeURIComponent(this.selectedQuestionType)
                + "&sitearrivalquestion[options]=" + encodeURIComponent(angular.toJson($scope.selectedQuestionOptions)) + "&settingsType=" + $state.current.data.settingsType).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editQuestionForm['selectedQuestion'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.transitionTo($scope.backUrl);
                    }
                }
            });
    }

    $scope.title = $translate('Update.arrive.on.site.question') + ':' + $scope.selectedQuestion;
    $scope.certificateName = $scope.selectedQuestion;
    $scope.questionType = 'Arrive';
    $scope.message = $translate('Update.Arrive.Questions.Title1') +' '+ $scope.selectedQuestion +' '+ $translate('Update.Arrive.Questions.Title3');

    $scope.triggerEditQuestion = function() {
        warningModal.show($scope.message, $scope.title, "CONFIRM_"+ Date.now());
    }
}
function HandleSettingsQuestionModal($scope, $modalInstance) {
    $scope = $scope.$parent;
    $scope.confirmDeleteValidity = true;
    $scope.modalupdating = false;
    $scope.closebutton = true;
    $scope.deletebutton = true;
    $scope.confirmText = '';

    $scope.saveUpdatedQuestion = function () {
        if (this.confirmText.toLowerCase() == $scope.textToConfirm.toLowerCase()) {
        $scope.modalupdating = true;
        $scope.current = this;
        $scope.closebutton = false;
        $scope.deletebutton = false;
        $modalInstance.close();
        } else {
            $scope.confirmDeleteValidity = false;
            $scope.updating = false;
        }
    }

    $scope.close = function () {
        $scope.updateQuestion();
        $modalInstance.close();
    }
}

function QuestionsListEditCtrl($scope, $state, prefix, $http, questionresolver, defaultPagingValue) {
    $scope.questions = questionresolver.questions;
    $scope.totalQuestions = questionresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.limit = defaultPagingValue;

    JustAddedUpdatedChanges.call(this, $scope, 'question', $state, defaultPagingValue, 'questions');

    $scope.$on("QUESTION_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, questionresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.questions.push({'id': message.id, 'questiondescription': message.question, 'questionhelptext': message.questionHelpText,
                'questionrequired': message.questionRequired, 'questiontype': message.questionType,
                'options': angular.fromJson(message.questionOptions), 'justadded': true});
            questionresolver.questions = $scope.questions;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        questionresolver.count = parseInt(questionresolver.count) + 1
        $scope.totalQuestions = questionresolver.count;
    });

    if($state.current.data.settingsType == 'Estimate'){
        $scope.editUrl = 'loggedin.site_arrival_questions.edit';
    }else if($state.current.data.settingsType == 'Job'){
        $scope.editUrl = 'loggedin.job_site_arrival_questions.edit';
    }
    $scope.editQuestion = function (id, question, questionHelpText, questionRequired, questionType, questionOptions, confirmationNeeded) {
        $state.transitionTo($scope.editUrl,
            {'id': id, 'question': question, 'questionHelpText': questionHelpText,
                'questionRequired': questionRequired, 'questionType': questionType,
                'questionOptions': angular.toJson(questionOptions), 'confirmationNeeded': confirmationNeeded, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, deleteQuestionName) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.selectedDeleteQuestionName = deleteQuestionName;
    }

    $scope.fetchQuestions = function (pageNum) {
        $http.get(prefix + '/listQuestions?page=' + pageNum + '&limit=' + $scope.limit + "&settingsType=" + $state.current.data.settingsType).success(function (data) {
            $scope.questions = data.questions;
            $scope.totalQuestions = data.count;
            questionresolver.questions = data.questions;
            questionresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function LeaveJobQuesCtrl($scope, $http, prefix, datasets, formService, $state) {

    $scope.data = datasets;
    $scope.saving = false;
    $scope.options = [
        {placeholder: 'Option 1'}
    ];

    $scope.isRemovable = function () {
        return $scope.options.length > 1;
    };

    $scope.add = function () {
        $scope.options.push({});
    };

    $scope.remove = function (index) {
        $scope.options.splice(index, 1);
    };
    $scope.settingsType = $state.current.data.settingsType;
    // CLOSE THE EXPAND LIST QUESTIONS PAGE WHEN CLICK NO RADIO BUTTON
    $scope.noLeaveJobQuestions = function () {

        $scope.updateRadioValue('no');
        $scope.showLeaveJobQuestions = false;
    }

    // EXPAND THE LIST QUESTION PAGE WHEN CLICK YES RADIO BUTTON
    $scope.yesLeaveJobQuestions = function () {

        $scope.updateRadioValue('yes');
        $scope.showLeaveJobQuestions = true;
    }

    $scope.updateRadioValue = function (vals) {

        $http.get(prefix + '/updateOptions_LeaveQuestion?options=' + vals + "&settingsType=" + $scope.settingsType ).success(function (data) {
            //  $scope.questions = data.questions;
            //  $scope.totalQuestions = data.count;
        })
    }

    // Check at the initial stage the radio button is Yes/No (if Yes=> expand the list questions page)
    $scope.showLeaveJobQuestions = function (vals) {
        $scope.showLeaveJobQuestions = vals;

        if (vals == 'true') {
            $scope.yesLeave = true;
        }
        else {
            $scope.noLeave = true;
        }
    }

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.questionsForm['leavejobquestion[questiondescription]'].$error.checkExists = true;
            } else {

                $scope.questionsForm.$setPristine();
                $scope.questiondescription = "";
                $scope.questionhelptext = "";
                $scope.questionrequired = false;
                $scope.show_options_div = "";
                $scope.options = [
                    {placeholder: 'Option 1'}
                ];
                $scope.$broadcast("QUESTION_ADDED", { 'id': data.id, 'questiondescription': data.questiondescription, 'questionhelptext': data.questionhelptext,
                    'questionrequired': data.questionrequired, 'questiontype': data.questiontype, 'options': angular.toJson(data.options)});
            }
            $scope.saving = false;
        });
    }

}

function ListLeaveJobQuesCtrl($scope, $state, prefix, $http, jobquestionsresolver, defaultPagingValue, $filter) {

    $scope.questions = jobquestionsresolver.questions;
    $scope.settingType = jobquestionsresolver.settingsType;
    $scope.totalQuestions = jobquestionsresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'question', $state, defaultPagingValue, 'questions');

    $scope.$on("QUESTION_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, jobquestionsresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.questions.push({'id': message.id, 'questiondescription': message.questiondescription, 'questionhelptext': message.questionhelptext,
                'questionrequired': message.questionrequired, 'questiontype': message.questiontype,
                'options': angular.fromJson(message.options), 'justadded': true});
            jobquestionsresolver.questions = $scope.questions;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        jobquestionsresolver.count = parseInt(jobquestionsresolver.count) + 1
        $scope.totalQuestions = jobquestionsresolver.count;
    });
    if($state.current.data.settingsType == 'Estimate'){
        $scope.editUrl = 'loggedin.leave_estimate_questions.edit';
        $scope.title = "Delete leave estimate questions";
        $scope.validationmessage = "'DELETE' to remove the Leave Estimate Questions";

    }else if($state.current.data.settingsType == 'Job'){
        $scope.editUrl = 'loggedin.leave_job_questions.edit';
        $scope.title = "Delete leave job questions";
        $scope.validationmessage = "'DELETE' to remove the Leave Job Questions";
    }

    $scope.editQuestion = function (id, questiondescription, questionhelptext, questionrequired, questiontype, options, confirmationNeeded) {
        $state.transitionTo($scope.editUrl,
            {'id': id, 'questiondescription': questiondescription, 'questionhelptext': questionhelptext,
                'questionrequired': questionrequired, 'questiontype': questiontype,
                'options': angular.toJson(options), 'confirmationNeeded' : confirmationNeeded, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.fetchJobQuestions = function (pageNum) {
        $http.get(prefix + '/list_LeaveQuestions?page=' + pageNum + '&limit=' + $scope.limit + "&settingsType=" + $state.current.data.settingsType).success(function (data) {
            $scope.questions = data.questions;
            $scope.totalQuestions = data.count;

            jobquestionsresolver.questions = $scope.questions;
            jobquestionsresolver.count = $scope.totalQuestions;

            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function LeaveJobQuesEditCtrl($scope, $state, $http, prefix, $filter, $translate, warningModal) {
    if($state.$current.name == "loggedin.leave_job_questions.edit"){
        $scope.settingType =  $filter('translate')("Question.Job");
        $scope.showmessage = $filter('translate')("Save.Leave.job.Question");
        $scope.updatemessage = $filter('translate')("Saving.Leave.job.Question");

    }
    if($state.$current.name == "loggedin.leave_estimate_questions.edit"){
        $scope.settingType =  $filter('translate')("Question.Estimate");
        $scope.showmessage = $filter('translate')("Save.Leave.estimate.Question");
        $scope.updatemessage = $filter('translate')("Saving.Leave.estimate.Question");


    }
    $scope.selectedQuestion = $state.params.questiondescription;
    $scope.selectedQuestionHelpText = $state.params.questionhelptext;
    $scope.selectedQuestionRequired = $state.params.questionrequired;
    $scope.selectedQuestionType = $state.params.questiontype;
    $scope.selectedQuestionOptions = angular.fromJson($state.params.options);
    $scope.confirmationNeeded = $state.params.confirmationNeeded;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    if ($scope.selectedQuestionOptions == '') {
        $scope.selectedQuestionOptions = angular.fromJson('[{"placeholder":"Option 1"}]');
    }

    // Condition only for checkbox==4 or dropwown==5 otherwise else part will work.
    if ($state.params.questiontype == 4 || $state.params.questiontype == 5)
        $scope.classExpand = 'expanded-sortable';
    else
        $scope.classExpand = '';

    if($state.current.data.settingsType == 'Estimate'){
        $scope.backUrl = 'loggedin.leave_estimate_questions';
    }else if($state.current.data.settingsType == 'Job'){
        $scope.backUrl = 'loggedin.leave_job_questions';
    }

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo($scope.backUrl);
    }

    $scope.expandDiv = function () {
        $scope.current = this;

        // Condition only for checkbox==4 or dropwown==5 otherwise else part will work.
        if (this.selectedQuestionType == 4 || this.selectedQuestionType == 5)
            $scope.classExpand = 'expanded-sortable';
        else
            $scope.classExpand = '';
    }

    $scope.isRemovableEdit = function () {
        return $scope.selectedQuestionOptions.length > 1;
    };

    $scope.addEdit = function () {
        $scope.selectedQuestionOptions.push({});
    };

    $scope.removeEdit = function (index) {
        $scope.selectedQuestionOptions.splice(index, 1);
    };

    if($scope.confirmationNeeded) {
        $scope.questionOptions = angular.fromJson($state.params.options);
        $scope.confirmation = false;
        $scope.handleOptionChange = function(option, index) {
            $scope.oldValue = $scope.questionOptions[index].value;
            if($scope.oldValue != option && $scope.confirmation == false) {
                $scope.confirmation = true;
                $scope.triggerEditQuestion();
            }
        };

        $scope.questionDescription = $state.params.questiondescription;
        $scope.handleQuestionChange = function(question) {
            if($scope.questionDescription != question && $scope.confirmation == false) {
                $scope.confirmation = true;
                $scope.triggerEditQuestion();
            }
        }
    }

    $scope.updateQuestion = function() {
        $scope.selectedQuestionOptions = $scope.questionOptions;
        $scope.selectedQuestion = $scope.questionDescription;
    }

    $scope.editQuestion = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;

        // Get the Option values when click on save button in edit page.
        var optionsArray = new Array();
        var optionsArrayValue = new Array();
        var count = 0;
        angular.forEach($scope.selectedQuestionOptions, function (val) {
            optionsArray[count] = val.id;
            optionsArrayValue[count] = val.value;
            count++;

        });

        $http.post(prefix + '/edit_LeaveQuestions', "leavequestion[id]=" + this.selectedId + "&leavequestion[questiondescription]=" + encodeURIComponent(this.selectedQuestion) + "&leavequestion[questionhelptext]=" + encodeURIComponent(this.selectedQuestionHelpText) + "&leavequestion[questionrequired]=" + encodeURIComponent(this.selectedQuestionRequired) + "&leavequestion[questiontype]=" + encodeURIComponent(this.selectedQuestionType) + "&leavequestion[options]=" + encodeURIComponent(angular.toJson($scope.selectedQuestionOptions)) + "&settingsType=" + $state.current.data.settingsType).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editQuestionForm['questiondescription'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.transitionTo($scope.backUrl);
                    }
                }
            });

    }

    $scope.title = $translate('Update.leave.on.site.question') + ':' + $scope.selectedQuestion;
    $scope.certificateName = $scope.selectedQuestion;
    $scope.questionType = 'Leave';
    $scope.message = $translate('Update.leave.Questions.Title1') +' '+ $scope.selectedQuestion +' '+ $translate('Update.leave.Questions.Title3');

    $scope.triggerEditQuestion = function() {
        warningModal.show($scope.message, $scope.title, "CONFIRM_"+ Date.now());
    }
}

function CompleteJobQuestionsEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedQuestion = $state.params.question;
    $scope.selectedQuestionHelpText = $state.params.questionHelpText;
    $scope.selectedQuestionRequired = $state.params.questionRequired;
    $scope.selectedQuestionType = $state.params.questionType;
    $scope.selectedQuestionOptions = angular.fromJson($state.params.questionOptions);
    if ($scope.selectedQuestionOptions == '') {
        $scope.selectedQuestionOptions = [
            {placeholder: 'Option 1'}
        ];
    }
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    if ($state.params.questionType == 4 || $state.params.questionType == 5) {
        $scope.classExpand = 'expanded-sortable';
    } else {
        $scope.classExpand = '';
    }


    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.complete_job_questions");
    }

    $scope.expandDiv = function () {
        $scope.current = this;
        if (this.selectedQuestionType == 4 || this.selectedQuestionType == 5) {
            $scope.classExpand = 'expanded-sortable';
        } else {
            $scope.classExpand = '';
        }
    }

    $scope.isRemovableEdit = function () {
        return $scope.selectedQuestionOptions.length > 1;
    };

    $scope.addEdit = function () {
        $scope.selectedQuestionOptions.push({});
    };

    $scope.removeEdit = function (index) {
        $scope.selectedQuestionOptions.splice(index, 1);
    };


    $scope.editQuestion = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;

        $http.post(prefix + '/edit_completejobquestion', "completejobquestion[id]=" + this.selectedId + "&completejobquestion[questiondescription]=" + encodeURIComponent(this.selectedQuestion)
                + "&completejobquestion[questionhelptext]=" + encodeURIComponent(this.selectedQuestionHelpText)
                + "&completejobquestion[questionrequired]=" + encodeURIComponent(this.selectedQuestionRequired)
                + "&completejobquestion[questiontype]=" + encodeURIComponent(this.selectedQuestionType)
                + "&completejobquestion[options]=" + encodeURIComponent(angular.toJson($scope.selectedQuestionOptions))).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editQuestionForm['selectedQuestion'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.transitionTo("loggedin.complete_job_questions");
                    }
                }
            });


    }
}

function CompleteJobQuestionsListEditCtrl($scope, $state, prefix, $http, questionresolver, defaultPagingValue) {
    $scope.questions = questionresolver.questions;
    $scope.totalQuestions = questionresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.limit = defaultPagingValue;

    JustAddedUpdatedChanges.call(this, $scope, 'question', $state, defaultPagingValue, 'questions');

    $scope.$on("QUESTION_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, questionresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.questions.push({'id': message.id, 'questiondescription': message.question, 'questionhelptext': message.questionHelpText,
                'questionrequired': message.questionRequired, 'questiontype': message.questionType,
                'options': angular.fromJson(message.questionOptions), 'justadded': true});
            questionresolver.questions = $scope.questions;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        questionresolver.count = parseInt(questionresolver.count) + 1
        $scope.totalQuestions = questionresolver.count;
    });

    $scope.editQuestion = function (id, question, questionHelpText, questionRequired, questionType, questionOptions) {
        $state.transitionTo('loggedin.complete_job_questions.edit',
            {'id': id, 'question': question, 'questionHelpText': questionHelpText,
                'questionRequired': questionRequired, 'questionType': questionType,
                'questionOptions': angular.toJson(questionOptions), 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, deleteQuestionName) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.selectedDeleteQuestionName = deleteQuestionName;
    }

    $scope.fetchQuestions = function (pageNum) {
        $http.get(prefix + '/listCompleteJobQuestions?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.questions = data.questions;
            $scope.totalQuestions = data.count;
            questionresolver.questions = data.questions;
            questionresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function NoAccessMessageCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function CustomiseMiniAppCtrl($scope, $http, $state, datasets, $rootScope, prefix , $timeout) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
    $scope.generateHtml = function () {
        $scope.generating = true;
        $http.get(prefix + '/generateMiniAppHtml').success(function (data) {
            $scope.apiKey = data.apiKey;
            $scope.generating = false;
            $scope.showApp = true;
        })
    };
    $scope.removeAccess = function () {
        $scope.removing = true;
        if(this.confirmText == "undefined" || this.confirmText.toLowerCase() != 'confirm')
        {
            $scope.confirmDeleteValidity = true;
        }else
        {
            $http.get(prefix + '/removeAccessMiniApp').success(function (data) {
                $scope.apiKey = data.apiKey;
                $scope.removing = false;
                $scope.showApp = false;
                $scope.animate_delete_modal_overlay = false;
                $scope.confirmDeleteValidity = false;
                $scope.loading = false;
                $scope.closebutton = false;
                $('#Remove-booking-form-access').modal('hide');
                $scope.show_delete_modal_overlay = false;
            });
        }

    };

    $scope.hideDeleteModalOverlay = function (itemCategory) {
        $scope.animate_delete_modal_overlay = false;
        this.$parent.confirmText = '';
        this.confirmText = '';
        $scope.confirmText = '';
        $scope.confirmDeleteValidity = false;
        $scope.loading = false;
        $scope.closebutton = false;

        $('#Remove-booking-form-access').modal('hide');
        $timeout(function(){
            $scope.show_delete_modal_overlay = false;
        }, 200);
    };
}

function SecurityRolesCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.securityRoleForm['security_role[description]'].$error.checkExists = true;
            } else {
                if(data.id){
                    $scope.securityRoleForm.$setPristine();
                    $scope.description = "";
                    $scope.$broadcast("SECURITY_ROLE_ADDED", { 'id': data.id, 'description': data.description});
                }
            }
            $scope.saving = false;
        });
    }
}

function SecurityRolesEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedRole = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.role_based_security");
    }

    $scope.editRole = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_security_role', "id=" + this.selectedId + "&description=" + encodeURIComponent(this.selectedRole)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editRoleForm['selectedRole'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.role_based_security");
                    }
                }
            });
    }
}

function CSRolesSecurityCtrl($scope, $modalInstance) {
    $scope = $scope.$parent;

    $modalInstance.result.then(function () {
        $scope.close();
    }, function () {
        $scope.close();
    });

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function () {
        $modalInstance.close();
    }

}

function SecurityRolesListEditCtrl($scope, $state, prefix, $http, securityrolesresolver, defaultPagingValue) {
    $scope.roles = securityrolesresolver.roles;
    $scope.totalRoles = securityrolesresolver.count;
    $scope.shouldBeOpen = false;

    JustAddedUpdatedChanges.call(this, $scope, 'role', $state, defaultPagingValue, 'roles');

    $scope.$on("SECURITY_ROLE_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, securityrolesresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.roles.push({'id': message.id, 'description': message.description, 'justadded': true});
            securityrolesresolver.roles = $scope.roles;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        securityrolesresolver.count = parseInt(securityrolesresolver.count) + 1
        $scope.totalRoles = securityrolesresolver.count;

    });

    $scope.editRole = function (id, description) {
        $state.transitionTo('loggedin.role_based_security.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, description) {
        $http.get(prefix + '/is_active_role?id=' + id).success(function(data) {
            if (data === "true") {
                $scope.cannotDelete = true;
            }  else {
                $scope.cannotDelete = false;
                $scope.selectedId = id;
                $scope.selectedDescription = description;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.fetchSecurityRoles = function (pageNum) {
        $http.get(prefix + '/listSecurityRoles?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.roles = data.roles;
            $scope.totalRoles = data.count;
            securityrolesresolver.roles = data.roles;
            securityrolesresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function ConfigureRoleCtrl($scope, $state, $http, $timeout, prefix, datasets, $modal, $rootScope) {
    $scope.data = datasets;
    $scope.showSlider = false;
    $scope.selectedLevel = '';
    $scope.roleId = 0;
    $scope.modules = [];
    $scope.isConfigurationChanged = false;
    $scope.shouldBeOpen = false;
    $rootScope.queryString = '';

    $scope.addRemoveAccess = function (element, featureId, moduleName, accessName) {
        var obj = _.where($scope.modules[moduleName],{featureId: featureId})[0];
        obj[accessName] = !obj[accessName];
        $scope.isConfigurationChanged = true;
        if (obj[accessName]) {
            if (accessName == 'delete') {
                obj['read'] = true;
                obj['write'] = true;
                obj['update'] = true;
            } else if (accessName == 'update') {
                obj['read'] = true;
                obj['write'] = true;
            } else if (accessName == 'write') {
                obj['read'] = true;
            }
        } else {
            if (accessName == 'read') {
                obj['write'] = false;
                obj['update'] = false;
                obj['delete'] = false;
            } else if (accessName == 'write') {
                obj['update'] = false;
                obj['delete'] = false;
            } else if (accessName == 'update') {
                obj['delete'] = false;
            }
        }
    }

    $scope.handleModuleGroupFilter = function handleModuleGroupFilter(filter) {
        var modules = $scope.modules;

        $scope.no_results = true;

        for (var key in modules) {
            var module = modules[key];

            module.showing = false;

            for (var i = 0, x = module.length; i < x; i++) {
                var feature_name = module[i].featureName,
                    feature_is_in_search = (feature_name.toLowerCase()).indexOf(filter.toLowerCase()) > -1;

                if (feature_is_in_search) {
                    $scope.no_results = false;
                    module.showing = true;
                }
            }
        }
    }

    $scope.search_filter = '';

    $scope.resetForm = function resetForm() {
        var modules = $scope.modules;

        $scope.no_results = false;

        for (var key in modules) {
            var module = modules[key];

            module.showing = true;
        }

        if ($scope.search_filter != '') {
            $scope.handleModuleGroupFilter($scope.search_filter);
        }

        console.log(modules);
    };

    $scope.saveRoleConfig = function (moduleGroupsId) {
        $scope.saving = true;
        $http.post(prefix + '/save_role_config', "roleId=" + $scope.roleId + "&level=" + $scope.sliderValue + "&moduleGroupsId=" + moduleGroupsId + "&modules=" + encodeURIComponent(angular.toJson($scope.modules))).
            success(function (data, status) {
                console.log('fired saveRoleConfig');

                $scope.isConfigurationChanged = false;
                $scope.saving = false;
                $scope.loadExistingConfiguration();
            });
    }

    $scope.setConfigurationChanged = function (changed) {
        $scope.isConfigurationChanged = changed;
    }

    $scope.$watch('selectedModuleGroups', function (newVal, oldVal) {
        if (newVal >= 0) {
            if ($scope.isConfigurationChanged) {
                $scope.notSaved = true;
                $scope.oldModuleGroupsValue = oldVal;
                $scope.levelWarning = false;
                $scope.loadmodal();
            } else {
                $scope.search_filter = '';      // Reset because the module group is changed
                $scope.notSaved = false;
                $scope.loadExistingConfiguration();
            }
        }
    });

    $scope.$watch('sliderValue', function (newVal, oldVal) {
        if ($scope.groupChanged) {
            $scope.groupChanged = false;
        } else if ($scope.configuredAlready) {
            $scope.levelWarning = true;
            $scope.notSaved = false;
            $scope.oldSliderValue = oldVal;
            $scope.loadmodal();
        } else if ($scope.sliderValue >= 0 && $scope.selectedModuleGroups >= 0 && $scope.showSlider) {
            $scope.loadConfiguration();
        }
    });

    $scope.loadmodal = function () {
        var modalInstance = null;
        modalInstance = $modal.open({
            templateUrl: 'template/settings/securityroles/roles_modal.html',
            scope: $scope,
            controller: CSRolesSecurityCtrl
        })

    }

    $scope.loadConfiguration = function () {
        $scope.modules_loading = true;

        $http.post(prefix + '/load_configuration', "level=" + $scope.sliderValue + "&moduleGroup=" + $scope.selectedModuleGroups).
            success(function (data, status) {
                $scope.modules_loading = false;
                console.log('fired loadConfiguration');

                $scope.modules = data.modules;
                $scope.modulesName = _.keys($scope.modules);
                $scope.isConfigurationChanged = true;

                $scope.resetForm();
            });
    }

    $scope.loadExistingConfiguration = function () {
        $scope.modules_loading = true;

        $http.post(prefix + '/load_existing_configuration', "moduleGroupsId=" + $scope.selectedModuleGroups + "&roleId=" + $scope.roleId).
            success(function (data, status) {
                $scope.modules_loading = false;
                console.log('fired loadExistingConfiguration');

                $scope.modules = data.modules;
                $scope.modulesName = _.keys($scope.modules);
                $scope.groupChanged = true;
                $scope.sliderValue = data.sliderValue;
                $scope.showSlider = true;
                $scope.configuredAlready = data.configuredAlready;

                $scope.resetForm();
            });
    }
}

function WorkAddressJobConfirmationListCtrl($scope) {
    $scope.workaddressjobconfirmationSms = false;
    $scope.workaddressjobconfirmationLetter = false;
    $scope.workaddressjobconfirmationEmail = true;

    //Show the SMS Screen
    $scope.smsTo = function () {
        $scope.workaddressjobconfirmationSms = true;
        $scope.workaddressjobconfirmationLetter = false;
        $scope.workaddressjobconfirmationEmail = false;
        tinyMCE.get("SmsId").focus();
    }
    //Show the Letter Screen
    $scope.letterTo = function () {
        $scope.workaddressjobconfirmationSms = false;
        $scope.workaddressjobconfirmationLetter = true;
        $scope.workaddressjobconfirmationEmail = false;
        tinyMCE.get("LetterId").focus();
    }
    //Show the Email Screen
    $scope.emailTo = function () {
        $scope.workaddressjobconfirmationSms = false;
        $scope.workaddressjobconfirmationLetter = false;
        $scope.workaddressjobconfirmationEmail = true;
        tinyMCE.get("EmailId").focus();
    }

    $scope.controlscope = function () {
        tinyMCE.get("EmailId").focus();
    }
}

function CustomerEstimateConfirmationListCtrl($scope) {

    $scope.customerestimateconfirmationSms = false;
    $scope.customerestimateconfirmationLetter = false;
    $scope.customerestimateconfirmationEmail = true;

    //Show the SMS Screen
    $scope.smsTo = function () {
        $scope.customerestimateconfirmationSms = true;
        $scope.customerestimateconfirmationLetter = false;
        $scope.customerestimateconfirmationEmail = false;
        tinyMCE.get("SmsId").focus();
    }
    //Show the Letter Screen
    $scope.letterTo = function () {
        $scope.customerestimateconfirmationSms = false;
        $scope.customerestimateconfirmationLetter = true;
        $scope.customerestimateconfirmationEmail = false;
        tinyMCE.get("LetterId").focus();
    }
    //Show the Email Screen
    $scope.emailTo = function () {
        $scope.customerestimateconfirmationSms = false;
        $scope.customerestimateconfirmationLetter = false;
        $scope.customerestimateconfirmationEmail = true;
        tinyMCE.get("EmailId").focus();
    }

    $scope.controlscope = function () {
        tinyMCE.get("EmailId").focus();
    }
}

function AfterSalesCommunicationListCtrl($scope) {
    $scope.aftersalescommunicationSms = false;
    $scope.aftersalescommunicationLetter = false;
    $scope.aftersalescommunicationEmail = true;

    //Show the SMS Screen
    $scope.smsTo = function () {
        $scope.aftersalescommunicationSms = true;
        $scope.aftersalescommunicationLetter = false;
        $scope.aftersalescommunicationEmail = false;
        tinyMCE.get("SmsId").focus();
    }
    //Show the Letter Screen
    $scope.letterTo = function () {
        $scope.aftersalescommunicationSms = false;
        $scope.aftersalescommunicationLetter = true;
        $scope.aftersalescommunicationEmail = false;
        tinyMCE.get("LetterId").focus();
    }
    //Show the Email Screen
    $scope.emailTo = function () {
        $scope.aftersalescommunicationSms = false;
        $scope.aftersalescommunicationLetter = false;
        $scope.aftersalescommunicationEmail = true;
        tinyMCE.get("EmailId").focus();
    }
    $scope.controlscope = function () {
        tinyMCE.get("EmailId").focus();
    }
}

function CustomerJobConfirmationListCtrl($scope) {
    $scope.customerjobconfirmationSms = false;
    $scope.customerjobconfirmationLetter = false;
    $scope.customerjobconfirmationEmail = true;

    //Show the SMS Screen
    $scope.smsTo = function () {
        $scope.customerjobconfirmationSms = true;
        $scope.customerjobconfirmationLetter = false;
        $scope.customerjobconfirmationEmail = false;
        tinyMCE.get("SmsId").focus();
    }
    //Show the Letter Screen
    $scope.letterTo = function () {
        $scope.customerjobconfirmationSms = false;
        $scope.customerjobconfirmationLetter = true;
        $scope.customerjobconfirmationEmail = false;
        tinyMCE.get("LetterId").focus();
    }
    //Show the Email Screen
    $scope.emailTo = function () {
        $scope.customerjobconfirmationSms = false;
        $scope.customerjobconfirmationLetter = false;
        $scope.customerjobconfirmationEmail = true;
        tinyMCE.get("EmailId").focus();
    }

    $scope.controlscope = function () {
        tinyMCE.get("EmailId").focus();
    }
}

function EstimateRejectReasonCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.estimateRejectReasonForm['estimateRejectReason[description]'].$error.checkExists = true;
            } else {
                $scope.estimateRejectReasonForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("ESTIMATE_REJECT_REASON_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function EstimateRejectReasonsEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedTitle = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.estimaterejectreasons");
    }

    $scope.editEstimateRejectReason = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_estimate_reject_reason', "estimateRejectReason[id]=" + this.selectedId + "&estimateRejectReason[description]=" + encodeURIComponent(this.selectedTitle)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editEstimateRejectReasonForm['selectedTitle'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.estimaterejectreasons");
                    }
                }
            });
    }
}

function EstimateRejectReasonsListEditCtrl($scope, $state, prefix, $http, estimaterejectreasonresolver, defaultPagingValue) {
    $scope.estimateRejectReasons = estimaterejectreasonresolver.estimateRejectReasons;
    $scope.totalEstimateRejectReasons = estimaterejectreasonresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'estimateRejectReason', $state, defaultPagingValue, 'estimateRejectReasons');

    $scope.$on("ESTIMATE_REJECT_REASON_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, estimaterejectreasonresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.estimateRejectReasons.push({'id': message.id, 'description': message.description, 'justadded': true});
            estimaterejectreasonresolver.titles = $scope.titles;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        estimaterejectreasonresolver.count = parseInt(estimaterejectreasonresolver.count) + 1
        $scope.totalEstimateRejectReasons = estimaterejectreasonresolver.count;
    });

    $scope.editEstimateRejectReason = function (id, description) {
        $state.transitionTo('loggedin.estimaterejectreasons.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, description) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.description = description;
    }

    $scope.fetchEstimateRejectReasons = function (pageNum) {
        $http.get(prefix + '/list_estimate_reject_reasons?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.estimateRejectReasons = data.estimateRejectReasons;
            $scope.totalEstimateRejectReasons = data.count;
            estimaterejectreasonresolver.estimateRejectReasons= data.estimateRejectReasons;
            estimaterejectreasonresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function StockWastageReasonsCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.stockWastageReasonForm['stockWastageReasons[description]'].$error.checkExists = true;
            } else {
                $scope.stockWastageReasonForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("STOCK_WASTAGE_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function StockWastageReasonsEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedStockWastage = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.stock_wastage_reasons");
    }

    $scope.editStockWastageReason = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_stock_wastage_reasons', "stockWastageReasons[id]=" + this.selectedId + "&stockWastageReasons[description]=" + encodeURIComponent(this.selectedStockWastage)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editWastageReasonForm['selectedStockWastage'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.stock_wastage_reasons");
                    }
                }
            });
    }
}

function StockWastageReasonsListEditCtrl($scope, $state, prefix, $http, stockwastagereasonsresolver, defaultPagingValue) {
    $scope.stockWastages = stockwastagereasonsresolver.stockWastages;
    $scope.totalStockWastages = stockwastagereasonsresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'stockWastage', $state, defaultPagingValue, 'stockWastages');

    $scope.$on("STOCK_WASTAGE_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, stockwastagereasonsresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.stockWastages.push({'id': message.id, 'description': message.description, 'justadded': true});
            stockwastagereasonsresolver.stockWastages = $scope.stockWastages;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        stockwastagereasonsresolver.count = parseInt(stockwastagereasonsresolver.count) + 1
        $scope.totalStockWastages = stockwastagereasonsresolver.count;
    });

    $scope.editStockWastageReason = function (id, description) {
        $state.transitionTo('loggedin.stock_wastage_reasons.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, description) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.description = description;
    }

    $scope.fetchWastageReason = function (pageNum) {
        $http.get(prefix + '/listStockWastageReasons?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.stockWastages = data.stockWastages;
            $scope.totalStockWastages = data.count;
            stockwastagereasonsresolver.stockWastages = data.stockWastages;
            stockwastagereasonsresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}
function DebtChasingCommunicationCtrl($scope, $state, $http, prefix, getIdData, formService, $rootScope, $timeout) {
    EmailMessageControllers.call(this, $scope, $http, $state, getIdData, $rootScope);
    $scope.$state = $state;
    $scope.debtChasingCommunication = getIdData.debtChasingCommunication;

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {

        // When view loads get the return state url

        var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)$/);
        $scope.reTurnState = matches[2];
        if ((matches[2] === 'debt_chasing_communication_message.followup') || (matches[2] === 'debt_chasing_communication_message.followup_edit')) {
            $scope.debtChasingCommunication = true;
        } else {
            $scope.debtChasingCommunication = false;
            $scope.fetchFollowup();
        }
    });
    $scope.debtChasingCommunication = getIdData.debtChasingCommunication;
    $scope.followupMessageCollection = getIdData.followupMessageCollection;
    $scope.smsModuleAccess = getIdData.smsModuleAccess;

    if ($scope.debtChasingCommunication.length > 0) {
        if (( $scope.debtChasingCommunication[0]['subject'] != null) || ($scope.debtChasingCommunication[0]['message'] != null)) {
            $scope.debtchasingcommunicationEmail = $scope.debtChasingCommunication[0]['message'];
            $scope.debtchasingcommunicationsubject = $scope.debtChasingCommunication[0]['subject'];
            $scope.nameId = $scope.debtChasingCommunication[0]['nameId'];
            $scope.chasinginterval = $scope.debtChasingCommunication[0]['communicationinterval'];
            $scope.email_done_message = true;
        }
        if (( $scope.debtChasingCommunication[1]['message'] != null)) {
            $scope.debtchasingcommunicationSms = $scope.debtChasingCommunication[1]['message'];
            $scope.sms_done_message = true;
        }
        if (( $scope.debtChasingCommunication[2]['message'] != null)) {
            $scope.debtchasingcommunicationLetter = $scope.debtChasingCommunication[2]['message'];
            $scope.letter_done_message = true;
        }
    } else {
        $scope.debtchasingcommunicationEmail = '';
        $scope.debtchasingcommunicationsubject = '';
        $scope.debtchasingcommunicationLetter = '';
        $scope.debtchasingcommunicationSms = '';
        $scope.chasinginterval = '';
        $scope.nameId = '';
    }

    $scope.shouldBeOpen = false;
    $scope.triggerDeleteFollowup = function (id) {
        $scope.selectedFollowupId = id;
        $scope.shouldBeOpen = true;
    }
    $scope.letterPreview = function (message) {
        $scope.previewData = message;
        $scope.shouldBeOpenLetterPreview = true;
    };
    $rootScope.flashSuccessMessage = '';
    $scope.close = function () {
        $scope.flashSuccessMessage = '';
    }
    $scope.$on('debtChasingCommunication:successMessage', function (event, data) {
        $rootScope.flashSuccessMessage = data;
        $timeout(function () {
            $("#debtChasingCommunicationMessage").fadeIn();
            $("#debtChasingCommunicationMessage").fadeOut(4000);
            $("#saveMessage").show();
        });
    });

    if ($rootScope.flashMessage) {
        $scope.$emit('debtChasingCommunication:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    $scope.saving = false;
    $scope.debtChasingCommunicationMessage = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/save_debt_chasing_communication', "debtchasingcommunication[nameId]=" + this.nameId + "&debtchasingcommunication[communicationinterval]=" + this.chasinginterval + '&debtchasingcommunication[messages][email][subject]=' + encodeURIComponent(this.debtchasingcommunicationsubject) + '&debtchasingcommunication[messages][email][message]=' + encodeURIComponent(this.debtchasingcommunicationEmail) +
        "&debtchasingcommunication[messages][sms][message]=" + encodeURIComponent(this.debtchasingcommunicationSms) + "&debtchasingcommunication[messages][letter][message]=" + encodeURIComponent(this.debtchasingcommunicationLetter)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.fetchFollowup();
                    $rootScope.flashMessage = data;
                    $scope.$emit('debtChasingCommunication:successMessage', $rootScope.flashMessage);
                    $scope.updating = false;
                }
            });
    }
    $scope.fetchFollowup = function () {
        $http.get(prefix + '/system_settings/debt_chasing/messaging').success(function (data) {
            $scope.debtChasingCommunication = data.debtChasingCommunication;
            $scope.followupMessageCollection = data.followupMessageCollection;
            $scope.debtChasingCommunication = false;
        })
    }

}
function DebtChasingCommunicationMessagingCtrl($scope, $state, $http, prefix, $rootScope, getIdData) {
    EmailMessageControllers.call(this, $scope, $http, $state, getIdData, $rootScope);
    $scope.debtChasingCommunication = getIdData.debtChasingCommunication;
    $scope.followupMessageCollection = getIdData.followupMessageCollection;
    $scope.smsModuleAccess = getIdData.smsModuleAccess;
    $scope.chasinginterval = 14;
    $scope.intervalTwo = $scope.intervalThree = $scope.intervalFour = $scope.intervalFive = true;

    angular.forEach($scope.followupMessageCollection, function (val) {
        if (val.communicationinterval == '2 Weeks') {
            $scope.intervalTwo = false;
        } else if (val.communicationinterval == '3 Weeks') {
            $scope.intervalThree = false;
        } else if (val.communicationinterval == '4 Weeks') {
            $scope.intervalFour = false;
        } else if (val.communicationinterval == '5 Weeks') {
            $scope.intervalFive = false;
        }
        if ($scope.intervalTwo == false && $scope.intervalThree == true && $scope.intervalFour == true && $scope.intervalFive == true) {
            $scope.chasinginterval = 21;
        } else if ($scope.intervalTwo == true && $scope.intervalThree == false && $scope.intervalFour == true && $scope.intervalFive == true) {
            $scope.chasinginterval = 14;
        } else if ($scope.intervalTwo == false && $scope.intervalThree == false && $scope.intervalFour == true && $scope.intervalFive == true) {
            $scope.chasinginterval = 28;
        } else if ($scope.intervalTwo == false && $scope.intervalThree == false && $scope.intervalFour == true && $scope.intervalFive == false) {
            $scope.chasinginterval = 28;
        } else if ($scope.intervalTwo == false && $scope.intervalThree == false && $scope.intervalFour == false && $scope.intervalFive == true) {
            $scope.chasinginterval = 35;
        }
    });

    if ($scope.debtChasingCommunication.length > 0) {
        if (( $scope.debtChasingCommunication[0]['subject'] != null) || ($scope.debtChasingCommunication[0]['message'] != null)) {
            $scope.debtchasingEmail = $scope.debtChasingCommunication[0]['message'];
            $scope.debtchasingsubject = $scope.debtChasingCommunication[0]['subject'];
            $scope.nameId = $scope.debtChasingCommunication[0]['nameId'];
            $scope.chasinginterval = $scope.debtChasingCommunication[0]['communicationinterval'];
            $scope.followUpMessageName = $scope.debtChasingCommunication[0]['communicationname'];
            $scope.email_done_debt = true;
        }
        if (( $scope.debtChasingCommunication[1]['message'] != null)) {
            $scope.debtchasingSms = $scope.debtChasingCommunication[1]['message'];
            $scope.sms_done_debt = true;
        }
        if (( $scope.debtChasingCommunication[2]['message'] != null)) {
            $scope.debtchasingLetter = $scope.debtChasingCommunication[2]['message'];
            $scope.letter_done_debt = true;
        }
    } else {
        $scope.debtchasingEmail = '';
        $scope.debtchasingsubject = '';
        $scope.debtchasingLetter = '';
        $scope.debtchasingSms = '';
        $scope.nameId = '';
        $scope.followUpMessageName = '';
    }

    $scope.shouldBeOpen = false;
    $scope.triggerDeleteFollowup = function (id) {
        $scope.selectedFollowupId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.letterPreview = function (message) {
        $scope.previewData = message;
        $scope.shouldBeOpenLetterPreview = true;
    };


    if ($rootScope.flashMessage) {
        $rootScope.flashMessage = '';
        $scope.$emit('debtChasingCommunication:successMessage', $rootScope.flashMessage);
    }
    $scope.followUpMessage = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/save_debt_chasing_communication', "debtchasingcommunication[nameId]=" + this.nameId + "&debtchasingcommunication[communicationinterval]=" + this.chasinginterval + '&debtchasingcommunication[communicationname]=' + encodeURIComponent(this.followUpMessageName) + '&debtchasingcommunication[messages][email][subject]=' + encodeURIComponent(this.debtchasingsubject) + '&debtchasingcommunication[messages][email][message]=' + encodeURIComponent(this.debtchasingEmail) +
        "&debtchasingcommunication[messages][sms][message]=" + encodeURIComponent(this.debtchasingSms) + "&debtchasingcommunication[messages][letter][message]=" + encodeURIComponent(this.debtchasingLetter)).
            success(function (data, status) {
                if (status == 200) {
                    $state.transitionTo("loggedin.debt_chasing_communication_message");
                    $scope.debtChasingCommunication = false;
                    $rootScope.flashMessage = data;
                    $scope.$emit('debtChasingCommunication:successMessage', $rootScope.flashMessage);
                    $scope.updating = false;
                }
            });
    }

}
function FollowUpDebtChasingCommunicationEditCtrl($scope, $state, $http, prefix, $rootScope, getIdData) {
    DebtChasingCommunicationMessagingCtrl.call(this, $scope, $state, $http, prefix, $rootScope, getIdData);
}

function NotificationPreferencesCtrl($scope, $state, $http, prefix, $filter, toastBox, datasets) {
    console.log(datasets);

    $scope.preferences = _.values(datasets.notifications);
    $scope.preferenceValues = {};
    angular.forEach($scope.preferences, function(pref, key) {
        var prefValues = [];
        angular.forEach(pref.value.split(","), function(group, key) {
            var matchRole = _.filter(datasets.roles, function(role) {
                return role.id == group;
            });
            if (matchRole.length > 0) {
                prefValues.push({'id': group, 'text': matchRole[0].text});
            }
        });
        $scope.preferenceValues[pref.id] = prefValues;
    });

    $scope.select2Options = {
        placeholder: "Select some groups",
        'multiple': true,
        id: function (obj) {
            return obj.id;
        },
        'data': datasets.roles,
        formatResult: function (item) {
            return item.text;
        },
        formatSelection: function (item) {
            return item.text;
        },
        formatNoMatches: function (item) {
            if (item != '') {
                return 'No results match "' + item + '"';
            } else {
                return "";
            }
        }
    };

    $scope.$watchCollection('preferenceValues', function(newVal, old) {
        if ($scope.activeNotification != "default" && newVal !== undefined && !angular.equals(newVal,old)) {

            var values = (_.map(newVal[$scope.activeNotification], function(k) { return k.id})).join(',')
            if ($scope.activeNotification != 0) {
                $http.post(prefix + '/save_notification_preferences',
                    "type=" + $scope.activeNotification + "&value=" + values).success(function() {
                        toastBox.show('Auto Saved', 2000);
                    });
            }
        }
    });

    $scope.handleSearch = function handleSearch() {
        var filtered_preferences = $filter('filter')($scope.preferences, $scope.searchFilter),
            no_results = filtered_preferences.length === 0;

        if (no_results) {
            $scope.no_results = true;
        } else {
            $scope.no_results = false;
        }
    }

    $scope.savePreference = function savePreference() {
        $state.transitionTo('loggedin.system_settings');
        toastBox.show('Notification.Preferences.Saved', 4000);
    }
}

function WorkAddressDynamicRouteChangeCtrl($scope, $state) {
    var rootMatch = $state.current.name.match(/^(.*?)\.(.*?)\.(.*?)$/);
    if (rootMatch[3] == 'estimate_work_address_messages' || rootMatch[3] == 'estimate_no_answer_messages' || rootMatch[3] == 'estimate_work_address_message_followup' || rootMatch[3] == 'estimate_work_address_message_followup_edit') {
        $scope.dynamicStateWorkAddress = 'estimate_work_address_messages';
        $scope.dynamicStateNoAnswer = 'estimate_no_answer_messages';
        $scope.dynamicStateFollowUp = 'estimate_work_address_message_followup';
        $scope.hasPermissionName = 'EstimateWorkAddressAccessCommunications';
        $scope.deleteRoute = 'delete_estimate_followup_work_address';
        $scope.workAddressMessage = "/save_estimate_work_address_communication";
        $scope.noAnswerMessage = "/save_estimate_no_answer_communication";
        $scope.followUpEdit = "estimate_work_address_message_followup_edit";
    }
    if (rootMatch[3] == 'job_work_address_messages' || rootMatch[3] == 'job_no_answer_messages' || rootMatch[3] == 'job_work_address_message_followup' || rootMatch[3] == 'job_work_address_message_followup_edit') {
        $scope.dynamicStateWorkAddress = 'job_work_address_messages';
        $scope.dynamicStateNoAnswer = 'job_no_answer_messages';
        $scope.dynamicStateFollowUp = 'job_work_address_message_followup';
        $scope.hasPermissionName = 'JobWorkAddressAccessCommunications';
        $scope.deleteRoute = 'delete_job_followup_work_address';
        $scope.workAddressMessage = "/save_job_work_address_communication";
        $scope.noAnswerMessage = "/save_job_no_answer_communication";
        $scope.followUpEdit = "job_work_address_message_followup_edit";
    }
    $scope.$state = $state;
    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        // When view loads get the return state url
        var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)$/);
        $scope.reTurnState = matches[2];
        if ((matches[2] === 'work_address_messages.estimate_work_address_message_followup') ||  (matches[2] === 'work_address_messages.estimate_work_address_message_followup_edit')) {
            $scope.workAddressMessages = true;
            $scope.messagePanelFollow = true;
            $scope.messagePanelHide = false;
            $scope.followUpView = false;
        }else if((matches[2] === 'work_address_messages.job_work_address_message_followup')  || (matches[2] === 'work_address_messages.job_work_address_message_followup_edit')) {
            $scope.workAddressMessages = true;
            $scope.messagePanelFollow = true;
            $scope.messagePanelHide = false;
            $scope.followUpView = false;
        }else{
            $scope.messagePanelFollow = false;
            $scope.messagePanelHide = true;
            $scope.workAddressMessages = true;
            $scope.followUpView = true;
        }
    });
}

function WorkAddressMessagesCtrl($scope, $state, $rootScope) {
    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);
    WorkAddressDynamicRouteChangeCtrl.call(this, $scope, $state);
    $scope.$on('tab:selected', function (event, tab_to_activate) {
        $scope.selectedTab = tab_to_activate;
    });
}

function WorkAddressMessageCtrl($scope, $state, $http, prefix, getIdData, formService, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, getIdData, $rootScope);
    WorkAddressDynamicRouteChangeCtrl.call(this, $scope, $state)

    $scope.dontAllow = false;
    $scope.workAddressMessages = getIdData.workAddressMessages;
    if($scope.workAddressMessages.length == 0){
        $scope.dontAllow = true;
    }
    $scope.smsModuleAccess = getIdData.smsModuleAccess;
    $scope.selectedTab = "WorkAddressMessage";
    $scope.$emit('tab:selected', $scope.selectedTab);

    $scope.followupMessageCollection = getIdData.followupMessageCollection;
    if ($scope.workAddressMessages.length > 0) {
        $scope.email_done = $scope.sms_done = $scope.letter_done ='';
        if ((($scope.workAddressMessages[0]['subject'] != null) || ($scope.workAddressMessages[0]['message'] != null)) && (($scope.workAddressMessages[0]['subject'] != 'undefined') || ($scope.workAddressMessages[0]['message'] != 'undefined'))) {
            $scope.workAddressMessagessubject = $scope.workAddressMessages[0]['subject'];
            $scope.workAddressMessagesEmail = $scope.workAddressMessages[0]['message'];
            $scope.nameId = $scope.workAddressMessages[0]['nameId'];
            $scope.workaddressinterval = $scope.workAddressMessages[0]['communicationinterval'];
            $scope.email_done = true;
        }
        if (( $scope.workAddressMessages[1]['message'] != null && $scope.workAddressMessages[1]['message'] != 'undefined')) {
            $scope.workAddressMessagesSms = $scope.workAddressMessages[1]['message'];
            $scope.sms_done = true;
        }
        if (( $scope.workAddressMessages[2]['message'] != null && $scope.workAddressMessages[2]['message'] != 'undefined')) {
            $scope.workAddressMessagesLetter = $scope.workAddressMessages[2]['message'];
            $scope.letter_done = true;
        }
    } else {
        $scope.workAddressMessagesEmail = '';
        $scope.workAddressMessagessubject = '';
        $scope.workAddressMessagesLetter = '';
        $scope.workAddressMessagesSms = '';
        $scope.workaddressinterval = '';
        $scope.nameId = '';
    }

    if($scope.email_done =='' && $scope.sms_done =='' && $scope.letter_done ==''){
        $scope.dontAllow = true;
    }

    $scope.shouldBeOpen = false;
    $scope.triggerDeleteFollowup = function (id) {
        $scope.selectedFollowupId = id;
        $scope.shouldBeOpen = true;
    }
    $scope.letterPreview = function (message) {
        $scope.previewData = message;
        $scope.shouldBeOpenLetterPreview = true;
    };

    $scope.saving = false;
    $scope.workAddressMessagesvalue = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        if(!this.nameId) this.nameId='';
        $http.post(prefix + $scope.workAddressMessage, "workaddresscommunication[nameId]=" + this.nameId + "&workaddresscommunication[communicationinterval]=" + this.chasinginterval + '&workaddresscommunication[messages][email][subject]=' + encodeURIComponent(this.workAddressMessagessubject) + '&workaddresscommunication[messages][email][message]=' + encodeURIComponent(this.workAddressMessagesEmail) +
        "&workaddresscommunication[messages][sms][message]=" + encodeURIComponent(this.workAddressMessagesSms) + "&workaddresscommunication[messages][letter][message]=" + encodeURIComponent(this.workAddressMessagesLetter)).
            success(function (data, status) {
                if (status == 200) {
                    if($scope.workAddressMessage == "/save_estimate_work_address_communication") {
                        $scope.dynamicStateFollowUp = 'estimate_work_address_message_followup';
                    } else if($scope.workAddressMessage == "/save_job_work_address_communication") {
                        $scope.dynamicStateFollowUp = 'job_work_address_message_followup';
                    }
                    $scope.fetchFollowup();
                    $scope.updating = false;
                }
            });
    }

    $scope.fetchFollowup = function fetchFollowup () {
        $http.get(Routing.generate($scope.dynamicStateFollowUp)).success(function (data) {
            $scope.workAddressMessages = data.workAddressMessages;
            $scope.dontAllow = true;
            if($scope.workAddressMessages.length == 0){
                $scope.dontAllow = false;
            }
            $scope.followupMessageCollection = data.followupMessageCollection;
            $scope.workAddressMessages = false;
        })
    }
}

function WorkAddressFollowUpCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, getIdData, $rootScope);
    WorkAddressDynamicRouteChangeCtrl.call(this, $scope, $state);

    $scope.$state = $state;
    $scope.workAddressMessages = getIdData.workAddressMessages;
    $scope.followupMessageCollection = getIdData.followupMessageCollection;
    $scope.smsModuleAccess = getIdData.smsModuleAccess;


    $scope.intervalTwo = $scope.intervalThree = $scope.intervalFour = $scope.intervalFive = true;

    angular.forEach($scope.followupMessageCollection, function (val) {
        if (val.communicationinterval == '2 Weeks') {
            $scope.intervalTwo = false;
        } else if (val.communicationinterval == '3 Weeks') {
            $scope.intervalThree = false;
        } else if (val.communicationinterval == '4 Weeks') {
            $scope.intervalFour = false;
        } else if (val.communicationinterval == '5 Weeks') {
            $scope.intervalFive = false;
        }
        if ($scope.intervalTwo == false && $scope.intervalThree == true && $scope.intervalFour == true && $scope.intervalFive == true) {
            $scope.workaddressinterval = 21;
        } else if ($scope.intervalTwo == true && $scope.intervalThree == false && $scope.intervalFour == true && $scope.intervalFive == true) {
            $scope.workaddressinterval = 14;
        } else if ($scope.intervalTwo == false && $scope.intervalThree == false && $scope.intervalFour == true && $scope.intervalFive == true) {
            $scope.workaddressinterval = 28;
        } else if ($scope.intervalTwo == false && $scope.intervalThree == false && $scope.intervalFour == true && $scope.intervalFive == false) {
            $scope.workaddressinterval = 28;
        } else if ($scope.intervalTwo == false && $scope.intervalThree == false && $scope.intervalFour == false && $scope.intervalFive == true) {
            $scope.workaddressinterval = 35;
        }
    });

    if ($scope.workAddressMessages.length > 0) {
        if ((( $scope.workAddressMessages[0]['subject'] != null) || ($scope.workAddressMessages[0]['message'] != null) && ( $scope.workAddressMessages[0]['subject'] != 'undefined') || ($scope.workAddressMessages[0]['message'] != 'undefined'))) {
            $scope.workAddressEmail = $scope.workAddressMessages[0]['message'];
            $scope.workAddressSubject = $scope.workAddressMessages[0]['subject'];
            $scope.nameId = $scope.workAddressMessages[0]['nameId'];
            $scope.workaddressinterval = $scope.workAddressMessages[0]['communicationinterval'];
            $scope.followUpMessageName = $scope.workAddressMessages[0]['communicationname'];
            $scope.email_done = true;
        }
        if (( $scope.workAddressMessages[1]['message'] != null && $scope.workAddressMessages[1]['message'] != 'undefined')) {
            $scope.workAddressSms = $scope.workAddressMessages[1]['message'];
            $scope.sms_done = true;
        }
        if (( $scope.workAddressMessages[2]['message'] != null && $scope.workAddressMessages[2]['message'] != 'undefined')) {
            $scope.workAddressLetter = $scope.workAddressMessages[2]['message'];
            $scope.letter_done = true;
        }
    } else {
        $scope.workAddressEmail = '';
        $scope.workAddressSubject = '';
        $scope.workAddressLetter = '';
        $scope.workAddressSms = '';
        $scope.workaddressinterval = 1;
        $scope.nameId = '';
        $scope.followUpMessageName = '';
    }

    $scope.shouldBeOpen = false;
    $scope.triggerDeleteFollowup = function (id) {
        $scope.selectedFollowupId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.letterPreview = function (message) {
        $scope.previewData = message;
        $scope.shouldBeOpenLetterPreview = true;
    };

    $scope.followUpMessage = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        if(!this.nameId) this.nameId='';
        $http.post(prefix + $scope.workAddressMessage, "workaddresscommunication[nameId]=" + this.nameId + "&workaddresscommunication[communicationinterval]=" + this.workaddressinterval + '&workaddresscommunication[communicationname]=' + encodeURIComponent(this.followUpMessageName) + '&workaddresscommunication[messages][email][subject]=' + encodeURIComponent(this.workAddressSubject) + '&workaddresscommunication[messages][email][message]=' + encodeURIComponent(this.workAddressEmail) +
        "&workaddresscommunication[messages][sms][message]=" + encodeURIComponent(this.workAddressSms) + "&workaddresscommunication[messages][letter][message]=" + encodeURIComponent(this.workAddressLetter)).
            success(function (data, status) {
                if (status == 200) {
                    if ($scope.workAddressMessage === '/save_estimate_work_address_communication') {
                        $state.transitionTo("loggedin.work_address_messages.estimate_work_address_messages");
                    } else {
                        $state.transitionTo("loggedin.work_address_messages.job_work_address_messages");
                    }
                    $scope.workAddressMessages = false;
                    $scope.updating = false;
                }
            });
    }

    $scope.fetchFollowup = function fetchFollowup () {
        $http.get(Routing.generate($scope.dynamicStateFollowUp)).success(function (data) {
            $scope.workAddressMessages = data.workAddressMessages;
            $scope.followupMessageCollection = data.followupMessageCollection;
            $scope.workAddressMessages = false;
        })
    }
    $scope.numbers = [];

    angular.forEach($scope.followupMessageCollection, function (val) {
        if (val.communicationinterval) {
            var value = parseInt(val.communicationinterval.replace(/[^0-9]/g, ''));
            $scope.numbers.push(value);
        }

    });

    $scope.hasNumber = function(value){
        return $scope.numbers.indexOf(value) >= 0;
    }

    if($scope.workAddressMessages.length == 0){
        // Find first value in available
        for(var day=1; day <= 31; day++){
            if(!$scope.hasNumber(day)) {
                $scope.workaddressinterval = day;
                break;
            }
        };

    }


}

function WorkAddressFollowUpEditCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    WorkAddressFollowUpCtrl.call(this, $scope, $state, $http, prefix, getIdData, $rootScope);
    WorkAddressDynamicRouteChangeCtrl.call(this, $scope, $state);
}

function NoAnswerMessageCtrl($scope, $state, $http, prefix, formService, getIdData, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, getIdData, $rootScope);
    WorkAddressDynamicRouteChangeCtrl.call(this, $scope, $state);
    $scope.selectedTab = "NoResultMessage";
    $scope.$emit('tab:selected', $scope.selectedTab);
    $scope.workAddressMessages = getIdData.workAddressMessages;
    $scope.smsModuleAccess = getIdData.smsModuleAccess;

    $scope.intervals = [
        {day: "1 Day", value: 1},
        {day: "2 Days", value: 2},
        {day: "3 Days", value: 3},
        {day: "4 Days", value: 4},
        {day: "5 Days", value: 5},
        {day: "6 Days", value: 6},
        {day: "7 Days", value: 7},
        {day: "10 Days", value: 10},
        {day: "14 Days", value: 14},
        {day: "21 Days", value: 21}
    ];

    if ($scope.workAddressMessages.length > 0) {
        if ((( $scope.workAddressMessages[0]['subject'] != null) || ($scope.workAddressMessages[0]['message'] != null) && ($scope.workAddressMessages[0]['subject'] != 'undefined') || ($scope.workAddressMessages[0]['message'] != 'undefined'))) {
            $scope.workAddressMessagessubject = $scope.workAddressMessages[0]['subject'];
            $scope.workAddressMessagesEmail = $scope.workAddressMessages[0]['message'];
            $scope.nameId = $scope.workAddressMessages[0]['nameId'];
            $scope.workaddressinterval = $scope.workAddressMessages[0]['communicationinterval'];
            if(!$scope.workaddressinterval) {
                $scope.workaddressinterval = $scope.intervals[0]['value'];
            }
            $scope.email_done = true;
        }
        if (( $scope.workAddressMessages[1]['message'] != null && $scope.workAddressMessages[1]['message'] != 'undefined')) {
            $scope.workAddressMessagesSms = $scope.workAddressMessages[1]['message'];
            $scope.sms_done = true;
        }
    } else {
        $scope.workAddressMessagesEmail = '';
        $scope.workAddressMessagessubject = '';
        $scope.workAddressMessagesSms = '';
        $scope.workaddressinterval = '';
        if(!$scope.workaddressinterval) {
            $scope.workaddressinterval = $scope.intervals[0]['value'];
        }
        $scope.nameId = '';
    }
    $scope.saving = false;

    $scope.editNameID =  null;
    $scope.workAddressMessagesvalue = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        if(!this.nameId) this.nameId='';
        if($scope.editNameID){
            this.nameId = $scope.editNameID;
        }
        $http.post(prefix + $scope.noAnswerMessage, "workaddresscommunication[nameId]=" + this.nameId + "&workaddresscommunication[communicationinterval]=" + angular.toJson(this.workaddressinterval) + '&workaddresscommunication[messages][email][subject]=' + encodeURIComponent(this.workAddressMessagessubject) + '&workaddresscommunication[messages][email][message]=' + encodeURIComponent(this.workAddressMessagesEmail) +
        "&workaddresscommunication[messages][sms][message]=" + encodeURIComponent(this.workAddressMessagesSms)).
            success(function (data, status) {
            $scope.editNameID = data;
                if (status == 200) {
                   // $rootScope.flashMessage = data;
                    $scope.updating = false;
                }
            });
    }
}

function AutomaticPostSalesCommunicationCtrl($scope, $http, $state, datasets, $rootScope, prefix) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.data = datasets;
    $scope.$state = $state;
    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)$/);
        $scope.reTurnState = matches[2];
        if (matches[2] === 'automatic_post_sales_communication') {
            $scope.automaticPostSalesview = false;
        } else if (matches[2] === 'automatic_post_sales_communication.view_work_addresses') {
            $scope.automaticPostSalesview = true;
            $scope.automaticPostSales = 'Work Addresses';
        } else if (matches[2] === 'automatic_post_sales_communication.view_customer') {
            $scope.automaticPostSalesview = true;
            $scope.automaticPostSales = 'Customers';
        }
    });
}
function AutomaticPostSalesCommunicationCustomerCtrl($scope, $http, $state, prefix, getIdData, $rootScope, canLoad, $q) {
    $scope.viewCustomer = getIdData.customers;
    $scope.count = getIdData.count;
    $scope.customerTypes = getIdData.customerTypes;
    $scope.settingsValue = getIdData.settingsValue;
    $scope.selectedCustomerType = '';
    $scope.shouldBeOpen = false;

    $scope.triggerDelete = function (settingsValue) {
        $scope.shouldBeOpen = true;
        $scope.viewPreferences = true;
        $scope.settingsValues = settingsValue;
    }

    $scope.$watchCollection('[searchText, selectedCustomerType]', function (newVal, oldVal) {
        if (typeof(newVal) != 'undefined' && typeof(oldVal) != 'undefined') {
            $scope.currentPage = 1;
            $scope.fetchViewCustomer($scope.currentPage);
        }
    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.$on('changeCommunicationPreference', function (event, data) {
        $scope.automatic_statement = (data.automatic_statement == undefined) ? '':data.automatic_statement;
        $scope.automatic_debt_chasing = (data.automatic_debt_chasing == undefined) ? '': data.automatic_debt_chasing;
        $scope.after_sales_care = (data.after_sales_care == undefined) ? '':data.after_sales_care;
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var selectedCustomerType = ($scope.selectedCustomerType == '') ? '' : $scope.selectedCustomerType;
        $http.post(prefix + '/change_communication_preference_view_customers', "communication_preference[automatic_statement]=" + $scope.automatic_statement + "&communication_preference[automatic_debt_chasing]=" + $scope.automatic_debt_chasing + "&communication_preference[after_sales_care]=" + $scope.after_sales_care + '&searchText=' + encodeURIComponent(searchText) + '&selectedCustomerType=' + encodeURIComponent(selectedCustomerType)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.currentPage = 1;
                    $scope.fetchViewCustomer($scope.currentPage);
                }
            });
    });

    var canceler = null;
    $scope.fetchViewCustomer = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var selectedCustomerType = ($scope.selectedCustomerType == '') ? '' : $scope.selectedCustomerType;
        $http.get(prefix + '/system_settings/automatic_post_sales_communication/view_customers?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&selectedCustomerType=' + encodeURIComponent(selectedCustomerType), {timeout: canceler.promise}).success(function (data) {
            $scope.viewCustomer = data.customers;
            $scope.count = data.count;
            $scope.customerTypes = data.customerTypes;
            $scope.selectedCustomerType = selectedCustomerType;
        })
    }

    $scope.clearFilters = function () {
        $scope.selectedCustomerType = null;
        $scope.searchText = '';
    };
}
function AutomaticPostSalesCommunicationAddressesCtrl($scope, $http, $state, prefix, getIdData, $rootScope, canLoad, $q) {
    $scope.viewWorkAddresses = getIdData.customers;
    $scope.count = getIdData.count;
    $scope.customerTypes = getIdData.customerTypes;
    $scope.selectedCustomerType = '';
    $scope.selectedCustomers = '';
    $scope.shouldBeOpen = false;
    $scope.settingsValue = getIdData.settingsValue;
    $scope.$parent.transferCustomerId = null;

    $scope.triggerDelete = function (settingsValue) {
        $scope.shouldBeOpen = true;
        $scope.viewPreferences = false;
        $scope.settingsValues = settingsValue;
    }

    $scope.$watchCollection('[searchText, selectedCustomerType ,$parent.transferCustomerId]', function (newVal, oldVal) {
        if (typeof(newVal) != 'undefined' && typeof(oldVal) != 'undefined') {
            $scope.currentPage = 1;
            $scope.fetchViewWorkAddress($scope.currentPage);
        }
    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.$on('changeCommunicationPreference', function (event, data) {
        $scope.automatic_statement = (data.automatic_statement == undefined) ? '':data.automatic_statement;
        $scope.automatic_debt_chasing = (data.automatic_debt_chasing == undefined) ? '': data.automatic_debt_chasing;
        $scope.after_sales_care = (data.after_sales_care == undefined) ? '':data.after_sales_care;
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var selectedCustomerType = ($scope.selectedCustomerType == '') ? '' : $scope.selectedCustomerType;
        var selectedCustomer = ($scope.$parent.transferCustomerId == null) ? '' : $scope.$parent.transferCustomerId.id;
        $http.post(prefix + '/change_communication_preference_view_work_addresses', "communication_preference[automatic_statement]=" + $scope.automatic_statement + "&communication_preference[automatic_debt_chasing]=" + $scope.automatic_debt_chasing + "&communication_preference[after_sales_care]=" + $scope.after_sales_care + '&searchText=' + encodeURIComponent(searchText) + '&selectedCustomerType=' + encodeURIComponent(selectedCustomerType) + '&selectedCustomer=' + selectedCustomer).
            success(function (data, status) {
                if (status == 200) {
                    $scope.currentPage = 1;
                    $scope.fetchViewWorkAddress($scope.currentPage);
                }
            });
    });

    var canceler = null;
    $scope.fetchViewWorkAddress = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var selectedCustomerType = ($scope.selectedCustomerType == '') ? '' : $scope.selectedCustomerType;
        var selectedCustomer = ($scope.$parent.transferCustomerId == null) ? '' : $scope.$parent.transferCustomerId.id;
        $http.get(prefix + '/system_settings/automatic_post_sales_communication/view_work_addresses?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&selectedCustomerType=' + encodeURIComponent(selectedCustomerType) + '&selectedCustomer=' + selectedCustomer, {timeout: canceler.promise}).success(function (data) {
            $scope.viewWorkAddresses = data.customers;
            $scope.count = data.count;
            $scope.customerTypes = data.customerTypes;
            $scope.selectedCustomerType = selectedCustomerType;
        })
    }

    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_workAddress',
            data: function (term, page) {
                return {'q': term};
            },
            results: function (data, page) {
                return {results: data};
            }
        }
    }

    $scope.clearFilters = function () {
        $scope.selectedCustomerType = null;
        $scope.searchText = '';
        $scope.$parent.transferCustomerId = null;
    };

}

function SettingsCommunicationPreferenceCtrl($scope, $http, $state, datasets, $rootScope, prefix) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.data = datasets;
    $scope.$state = $state;
    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)$/);
        $scope.reTurnState = matches[2];
        if (matches[2] === 'settings_communication_preference') {
            $scope.settingsCommunicationPreferenceView = false;
        } else if (matches[2] === 'settings_communication_preference.view_customer') {
            $scope.settingsCommunicationPreferenceView = true;
            $scope.settingsCommunicationPreference = 'Customers';
        } else if (matches[2] === 'settings_communication_preference.view_work_address') {
            $scope.settingsCommunicationPreferenceView = true;
            $scope.settingsCommunicationPreference = 'Work Addresses';
        }
    });
}

function SettingsCommunicationPreferenceCustomerCtrl($scope, $http, $state, prefix, getIdData, $rootScope, canLoad, $q,toastBox,warningModal) {
    $scope.viewCustomer = getIdData.customers;
    $scope.count = getIdData.count;
    $scope.customerTypes = getIdData.customerTypes;
    $scope.settingsValue = getIdData.settingsValue;
    $scope.selectedCustomerType = '';
    $scope.smsReadAccess = getIdData.settingsValue.smsReadAccess;
    $scope.shouldBeOpen = false;

    $scope.triggerDelete = function (settingsValue) {
        $scope.shouldBeOpen = true;
        $scope.viewPreferences = true;
        $scope.settingsValues = settingsValue;
    }

    $scope.$watchCollection('[searchText, selectedCustomerType]', function (newVal, oldVal) {
        if (typeof(newVal) != 'undefined' && typeof(oldVal) != 'undefined') {
            $scope.currentPage = 1;
            $scope.fetchViewCustomer($scope.currentPage);
        }
    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.$on('communicationPreference', function (event, data) {
        $scope.PhoneCall = (data.PhoneCall == undefined) ? '':data.PhoneCall;
        $scope.Email = (data.Email == undefined) ? '':data.Email;
        $scope.Sms = (data.Sms == undefined) ? '':data.Sms;
        $scope.Letter = (data.Letter == undefined) ? '':data.Letter;
        $scope.PhoneRemove = (data.PhoneRemove == undefined) ? '':data.PhoneRemove;
        $scope.EmailRemove = (data.EmailRemove == undefined) ? '':data.EmailRemove;
        $scope.SmsRemove = (data.SmsRemove == undefined) ? '':data.SmsRemove;
        $scope.LetterRemove = (data.LetterRemove == undefined) ? '':data.LetterRemove;
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var selectedCustomerType = ($scope.selectedCustomerType == '') ? '' : $scope.selectedCustomerType;
        $http.post(prefix + '/communication_preference_view_customers', "&communication_preference[PhoneCall]=" + $scope.PhoneCall + "&communication_preference[Email]=" + $scope.Email + "&communication_preference[Sms]=" + $scope.Sms + "&communication_preference[Letter]=" + $scope.Letter + "&communication_preference[PhoneRemove]=" + $scope.PhoneRemove + "&communication_preference[EmailRemove]=" + $scope.EmailRemove + "&communication_preference[SmsRemove]=" + $scope.SmsRemove + "&communication_preference[LetterRemove]=" + $scope.LetterRemove + '&searchText=' + encodeURIComponent(searchText) + '&selectedCustomerType=' + encodeURIComponent(selectedCustomerType)).
        success(function (data, status) {
            if (status == 200) {
                $scope.currentPage = 1;
                $scope.fetchViewCustomer($scope.currentPage);
            }
            if(data.toast == true) {
                toastBox.show('Successfully updated', 4000);
            }else if(data.warning == true){
                warningModal.show(data.message, data.title, data.id);
            }
        });
    });

    var canceler = null;
    $scope.fetchViewCustomer = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var selectedCustomerType = ($scope.selectedCustomerType == '') ? '' : $scope.selectedCustomerType;
        $http.get(prefix + '/system_settings/settings_communication_preference/view_customer?page=' + pageNum + '&limit=' + $scope.limit +  '&searchText=' + encodeURIComponent(searchText) + '&selectedCustomerType=' + encodeURIComponent(selectedCustomerType), {timeout: canceler.promise}).success(function (data) {
            $scope.viewCustomer = data.customers;
            $scope.count = data.count;
            $scope.customerTypes = data.customerTypes;
            $scope.selectedCustomerType = selectedCustomerType;
        })
    }

    $scope.clearFilters = function () {
        $scope.selectedCustomerType = null;
        $scope.searchText = '';
    };
}
function SettingsCommunicationPreferenceAddressesCtrl($scope, $http, $state, prefix, getIdData, $rootScope, canLoad, $q,toastBox,warningModal) {
    $scope.viewWorkAddresses = getIdData.customers;
    $scope.count = getIdData.count;
    $scope.customerTypes = getIdData.customerTypes;
    $scope.selectedCustomerType = '';
    $scope.selectedCustomers = '';
    $scope.shouldBeOpen = false;
    $scope.settingsValue = getIdData.settingsValue;
    $scope.smsReadAccess = getIdData.settingsValue.smsReadAccess;
    $scope.$parent.transferCustomerId = null;

    $scope.triggerDelete = function (settingsValue) {
        $scope.shouldBeOpen = true;
        $scope.viewPreferences = false;
        $scope.settingsValues = settingsValue;
    }

    $scope.$watchCollection('[searchText, selectedCustomerType ,$parent.transferCustomerId]', function (newVal, oldVal) {
        if (typeof(newVal) != 'undefined' && typeof(oldVal) != 'undefined') {
            $scope.currentPage = 1;
            $scope.fetchViewWorkAddress($scope.currentPage);
        }
    });
0
    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.$on('communicationPreference', function (event, data) {
        $scope.PhoneCall = (data.PhoneCall == undefined) ? '':data.PhoneCall;
        $scope.Email = (data.Email == undefined) ? '':data.Email;
        $scope.Sms = (data.Sms == undefined) ? '':data.Sms;
        $scope.Letter = (data.Letter == undefined) ? '':data.Letter;
        $scope.PhoneRemove = (data.PhoneRemove == undefined) ? '':data.PhoneRemove;
        $scope.EmailRemove = (data.EmailRemove == undefined) ? '':data.EmailRemove;
        $scope.SmsRemove = (data.SmsRemove == undefined) ? '':data.SmsRemove;
        $scope.LetterRemove = (data.LetterRemove == undefined) ? '':data.LetterRemove;
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var selectedCustomerType = ($scope.selectedCustomerType == '') ? '' : $scope.selectedCustomerType;
        var selectedCustomer = ($scope.$parent.transferCustomerId == null) ? '' : $scope.$parent.transferCustomerId.id;
        $http.post(prefix + '/communication_preference_view_work_addresses', "&communication_preference[PhoneCall]=" + $scope.PhoneCall + "&communication_preference[Email]=" + $scope.Email + "&communication_preference[Sms]=" + $scope.Sms + "&communication_preference[Letter]=" + $scope.Letter  + "&communication_preference[PhoneRemove]=" + $scope.PhoneRemove + "&communication_preference[EmailRemove]=" + $scope.EmailRemove + "&communication_preference[SmsRemove]=" + $scope.SmsRemove + "&communication_preference[LetterRemove]=" + $scope.LetterRemove +  '&searchText=' + encodeURIComponent(searchText) + '&selectedCustomerType=' + encodeURIComponent(selectedCustomerType) + '&selectedCustomer=' + selectedCustomer).
        success(function (data, status) {
            if (status == 200) {
                $scope.currentPage = 1;
                $scope.fetchViewWorkAddress($scope.currentPage);
            }
            if(data.toast == true) {
                toastBox.show('Successfully updated', 4000);
            }else if(data.warning == true){
                warningModal.show(data.message, data.title, data.id);
            }
        });
    });

    var canceler = null;
    $scope.fetchViewWorkAddress = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var selectedCustomerType = ($scope.selectedCustomerType == '') ? '' : $scope.selectedCustomerType;
        var selectedCustomer = ($scope.$parent.transferCustomerId == null) ? '' : $scope.$parent.transferCustomerId.id;
        $http.get(prefix + '/system_settings/settings_communication_preference/view_work_address?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&selectedCustomerType=' + encodeURIComponent(selectedCustomerType) + '&selectedCustomer=' + selectedCustomer, {timeout: canceler.promise}).success(function (data) {
            $scope.viewWorkAddresses = data.customers;
            $scope.count = data.count;
            $scope.customerTypes = data.customerTypes;
            $scope.selectedCustomerType = selectedCustomerType;
        })
    }

    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_workAddress',
            data: function (term, page) {
                return {'q': term};
            },
            results: function (data, page) {
                return {results: data};
            }
        }
    }

    $scope.clearFilters = function () {
        $scope.selectedCustomerType = null;
        $scope.searchText = '';
        $scope.$parent.transferCustomerId = null;
    };

}

function OnHoldDescriptionCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.onHoldDescriptionForm['onholddescription[description]'].$error.checkExists = true;
            } else {
                $scope.onHoldDescriptionForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("ONHOLDDESRIPTIONS_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function OnHoldDescriptionListEditCtrl($scope, $state, prefix, $http, onholddescriptionresolver, defaultPagingValue, warningModal) {
    $scope.onHoldDescriptions = onholddescriptionresolver.onHoldDescriptions;
    $scope.totalOnHoldDescriptions = onholddescriptionresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'onHoldDescription', $state, defaultPagingValue, 'onHoldDescriptions');

    $scope.$on("ONHOLDDESRIPTIONS_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, onholddescriptionresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.onHoldDescriptions.push({'id': message.id, 'description': message.description, 'jobCount': 0, 'justadded': true});
            onholddescriptionresolver.onHoldDescriptions = $scope.onHoldDescriptions;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        onholddescriptionresolver.count = parseInt(onholddescriptionresolver.count) + 1
        $scope.totalOnHoldDescriptions = onholddescriptionresolver.count;
    });

    $scope.editOnHoldDescription = function (id, description) {
        $state.transitionTo('loggedin.on_hold_descriptions.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerOnHoldDescriptionDelete = function (id, description, jobCount) {
        if(jobCount != 0){
            warningModal.show('You cannot delete this on-hold Description', 'On hold description');
        } else {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.description = description;
        }
    }

    $scope.fetchOnHoldDescriptions = function (pageNum) {
        $http.get(prefix + '/list_on_hold_descriptions?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.onHoldDescriptions = data.onHoldDescriptions;
            $scope.totalOnHoldDescriptions = data.count;
            onholddescriptionresolver.onHoldDescriptions = data.onHoldDescriptions;
            onholddescriptionresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function OnHoldDescriptionEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedOnHoldDescription = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.on_hold_descriptions");
    }

    $scope.editOnHoldDescriptions = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_on_hold_description', "onholddescription[id]=" + this.selectedId + "&onholddescription[description]=" + encodeURIComponent(this.selectedOnHoldDescription)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editOnHoldDescriptionsForm['selectedOnHoldDescription'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.on_hold_descriptions");
                    }
                }
            });
    }
}
function JobReportEmailMessageCtrl($scope, $http, $state, datasets, $rootScope) {

    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function ServicePlanCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.servicePlanForm['serviceplan[description]'].$error.checkExists = true;
            } else {
                $scope.servicePlanForm.$setPristine();
                $scope.description = "";
                $scope.details = "";
                $scope.$broadcast("SERVICE_PLAN_ADDED", { 'id': data.id, 'description': data.description, 'details': data.details});
            }
            $scope.saving = false;
        });
    }
}

function ServicePlanListCtrl($scope, $state, prefix, $http, serviceplanresolver, defaultPagingValue) {
    $scope.serviceplans = serviceplanresolver.serviceplans;
    $scope.totalServicePlans = serviceplanresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'serviceplan', $state, defaultPagingValue, 'serviceplans');

    $scope.$on("SERVICE_PLAN_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, serviceplanresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.serviceplans.push({'id': message.id, 'description': message.description, 'details': message.details, 'justadded': true});
            serviceplanresolver.serviceplans = $scope.serviceplans;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        serviceplanresolver.count = parseInt(serviceplanresolver.count) + 1
        $scope.totalServicePlans = serviceplanresolver.count;
    });

    $scope.editServicePlan = function (id, description, details) {
        $state.transitionTo('loggedin.serviceplan.edit', {'id': id, 'description': description, 'details': details, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, description) {
        $http.get(prefix + '/is_active_service_plan?id=' + id).success(function(data) {
            if (data === "false") {
                $scope.cannotDelete = true;
            }  else {
                $scope.cannotDelete = false;
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
                $scope.description = description;
            }
        });
    }

    $scope.fetchServicePlan = function (pageNum) {
        $http.get(prefix + '/listServicePlan?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.serviceplans = data.serviceplans;
            $scope.totalServicePlans = data.count;
            serviceplanresolver.serviceplans = data.serviceplans;
            serviceplanresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function ServicePlanEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedServicePlan = $state.params.description;
    $scope.selectedDetails = $state.params.details;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.serviceplan");
    }

    $scope.updateServicePlan = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/editServicePlan', "serviceplan[id]=" + this.selectedId + "&serviceplan[description]=" + encodeURIComponent(this.selectedServicePlan)+ "&serviceplan[details]=" + encodeURIComponent(this.selectedDetails)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editServicePlanForm['selectedServicePlan'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.serviceplan");
                    }
                }
            });
    }
}

function CertificateDefaultListCtrl($scope, $state, $http, prefix, defaultcertificateresolver, $filter, canLoad, $q) {
    $scope.value=[];
    $scope.defaultCertificatesFields = defaultcertificateresolver.defaultCertificatesFields;
    $scope.show_default_panel = true;
    $scope.no_results = false;
    $scope.certificateCount = defaultcertificateresolver.count;

    $scope.$watch('searchFilter', function (newVal, oldVal) {
        if (newVal != undefined) {
             $scope.fetchCertificates();
          }
     });
    var canceler = null;
    $scope.fetchCertificates = function () {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchFilter == undefined) ? '' : $scope.searchFilter;
        $http.get(prefix + '/listDefaultCertificates?searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.defaultCertificatesFields = data.defaultCertificatesFields;
            $scope.show_default_panel = true;
            if($scope.defaultCertificatesFields == ''){
                $scope.no_results = true;
            } else {
                $scope.no_results = false;
            }
        })
    }


    $scope.deselectAllOptions = function deselectAllOptions() {
        angular.forEach($scope.defaultCertificatesFields,function(values,keys){
            $scope.defaultCertificatesFields[keys].selected = false;
            angular.forEach($scope.defaultCertificatesFields[keys].fields,function(value,key){
                $scope.defaultCertificatesFields[keys].fields[key].selected = false;
            });
        });
    }

    $scope.handleSelectingOption = function handleSelectingOption(option, group, optionId, groupId) {
        $scope.show_default_panel = false;
        $scope.deselectAllOptions();
        group.selected = true;
        option.selected = true;
    }
}

function ApplianceModelListMergeCtrl($scope, $state, $http, prefix, appliancemodelresolver, toastBox, canLoad, $q){
    $scope.appliancemodels = appliancemodelresolver.appliancemodels;
    $scope.appliancetypeslist = appliancemodelresolver.appliancetypeslist;
    $scope.appliancemakeslist = appliancemodelresolver.appliancemakeslist;
    $scope.fueltypelist = appliancemodelresolver.fueltypelist;
    $scope.totalModels = appliancemodelresolver.count;
    $scope.searchText = '';
    $scope.pageNum = $state.current.data.pageNum;
    $scope.limit = $state.current.data.limit;
    $scope.currentPage = parseInt($state.current.data.pageNum);
    $scope.shouldBeOpen = false;
    $scope.selectedModel=[];
    $scope.enableMergeButton = true;
    $scope.toggle_message = 'Select All'

    $scope.select2OptionsMake = {
        minimumInputLength: 2,
        ajax: {
            url: function() {
                return prefix + '/list_ApplianceMakes'+'?screenType=merge';
            },
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }

    $scope.enterKeyPress = function (event) {
        if(event.keyCode == 13) {
            event.preventDefault();
        } return;
    }
    $scope.applianceType = '';
    $scope.fuelType = '';
    $scope.makeVal = '';


    $scope.$watch('searchText', function (newVal, oldVal) {
        if (typeof(newVal) != 'undefined' && typeof(oldVal) != 'undefined') {
            $scope.pageNum = 1;
            var applianceMakeID = ($scope.selectedApplianceMake) ? $scope.selectedApplianceMake.id : '';
            $scope.fetchApplianceModels($scope.selectedApplianceGroup, $scope.selectedFuelType, applianceMakeID, $scope.pageNum);
        }
    });

    var canceler = null;
    $scope.fetchApplianceModels = function (selectedApplianceGroup, selectedFuelType, selectedApplianceMake, pageNum) {
        if($scope.pageNum != pageNum) {
            $scope.pageNum = pageNum;
            return;;
        }
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        if(pageNum) {
            $http.get(prefix + '/list_ApplianceModels?searchText=' + encodeURIComponent($scope.searchText) +
                '&selectedApplianceGroup='+ selectedApplianceGroup + '&selectedFuelType=' + selectedFuelType +
                '&selectedApplianceMake='+ selectedApplianceMake + '&page=' + pageNum + '&limit=' + $scope.limit +
                '&option=' + 'merge&screenType=merge', {timeout: canceler.promise}).success(function (data) {
                $scope.appliancemodels = data.appliancemodels;
                $scope.totalModels = data.count;
            })
            $scope.selectedModel = [];
            angular.forEach($scope.appliancemodels, function (value, key) {
                value.checked = false;
            });
            $scope.selectAllChecked = false;
            $scope.toggle_message = 'Select All';
            if($scope.selectedModel.length > 0){
                $scope.enableMergeButton = false;
            } else {
                $scope.enableMergeButton = true;
            }
        }
    }
    $scope.editAppliance = function (model,bulk){
        if(model && $scope.selectedModel.length && bulk == false) {
            return false
        }
        $scope.shouldBeOpen = true;
        $scope.settingstypesId = model.appliancetypesId;
        $scope.settingsFuelId = model.settingsFuelTypesId;
        $scope.settingsmakesId = model.appliancemakesId;
        if(model.id){
            $scope.selectedModel = [];
            $scope.toggleSelection(model.id);
        }
    }
    $scope.toggleSelection = function toggleSelection(selectedId) {
        var idx = $scope.selectedModel.indexOf(selectedId);

        if (idx > -1) {
            $scope.selectedModel.splice(idx, 1);
            angular.forEach($scope.appliancemodels, function (value, key) {
                if (value.id == selectedId) {
                    value.checked = false;
                }
            });
            $scope.selectAllChecked = false;
            $scope.toggle_message = 'Select All';
        } else {
            $scope.selectedModel.push(selectedId);
            angular.forEach($scope.appliancemodels, function (value, key) {
                if (value.id == selectedId) {
                    value.checked = true;
                }
            });
            if($scope.selectedModel.length == $scope.appliancemodels.length){
                $scope.selectAllChecked = true;
                $scope.toggle_message = 'Unselect All';
            }
        }
        if($scope.selectedModel.length > 0){
            $scope.enableMergeButton = false;
        } else {
            $scope.enableMergeButton = true;
        }
    };

    $scope.$on('updated_appliance_models', function(evt,data) {
        $scope.appliancemodels = data.appliancemodels;
        $scope.totalModels = data.count;
        toastBox.show('Merged appliance models', 4000, 300);
    });

    $scope.$on('selected_models', function(evt,data) {
        $scope.selectedModel = data;
        if($scope.selectedModel.length > 0){
            $scope.enableMergeButton = false;
        }else{
            $scope.enableMergeButton = true;
            angular.forEach($scope.appliancemodels, function (value, key) {
                value.checked = false;
            });
            $scope.selectAllChecked = false;
            $scope.toggle_message = 'Select All';
        }
    });

    $scope.toggleAllRows = function toggleAllRows(toggle_message){
        if(toggle_message == 'Select All'){
            $scope.selectedModel = [];
            $scope.selectAllChecked = true;
            angular.forEach($scope.appliancemodels, function (value, key) {
                $scope.selectedModel.push(value.id);
                value.checked = true;
            });
            $scope.toggle_message = 'Unselect All';
        }else if(toggle_message == 'Unselect All'){
            $scope.selectedModel = [];
            $scope.selectAllChecked = false;
            angular.forEach($scope.appliancemodels, function (value, key) {
                value.checked = false;
            });
            $scope.toggle_message = 'Select All';
        }
        if($scope.selectedModel.length > 0){
            $scope.enableMergeButton = false;
        }else{
            $scope.enableMergeButton = true;
        }
    }

}

function ApplianceMakeListMergeCtrl($scope, $state, $http, prefix, appliancemakeresolver, warningModal, toastBox, canLoad, $q){
    $scope.applianceMakes = appliancemakeresolver.makes;
    $scope.totalMakes = appliancemakeresolver.count;
    $scope.applianceMakesToMerge = $scope.applianceMakes;
    $scope.searchText = '';
    $scope.enableMergeButton = false;
    $scope.enableMerge = true;
    $scope.selectedMake = [];
    $scope.pageNum = $state.current.data.pageNum;
    $scope.limit = $state.current.data.limit;
    $scope.currentPage = parseInt($state.current.data.pageNum);
    $scope.shouldBeOpen = false;
    $scope.selectedId = '';
    $scope.toggle_message = 'Select All';

    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: function() {
                var selectedMakes = ($scope.selectedMake.length) ? $scope.selectedMake.join() : '';
                return prefix + '/list_ApplianceMakes?selectedId=' + selectedMakes +'&screenType=merge';
            },
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }

    $scope.select2OptionsInline = {
        minimumInputLength: 2,
        ajax: {
            url: function() {
                return prefix + '/list_ApplianceMakes?selectedId=' + $scope.selectedId +'&screenType=merge';
            },
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        $scope.pageNum = 1;
        if (typeof(newVal) != 'undefined' && typeof(oldVal) != 'undefined') {
            $scope.selectedMake = [];
            $scope.handleMergeButton();
            $scope.fetchApplianceMakes($scope.pageNum);
        }
    });

    $scope.enterKeyPress = function (event) {
        if(event.keyCode == 13) {
            event.preventDefault();
        } return;
    }

    var canceler = null;
    $scope.fetchApplianceMakes = function (pageNum) {
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        $http.get(prefix + '/list_ApplianceMakes?searchText=' + encodeURIComponent($scope.searchText) +
            '&page=' + pageNum + '&limit=' + $scope.limit + '&list=' + 'all'+ '&screenType=merge', {timeout: canceler.promise}).success(function (data) {
            if(data.makes && data.makes.length) {
                angular.forEach(data.makes, function (value, key) {
                    value.checked = false;
                });
            }
            angular.forEach(data.makes, function (value, key) {
                if ($scope.selectedApplianceMake && value.id == $scope.selectedApplianceMake.id) {
                    value.disableCheckBox = true;
                }else{
                    value.disableCheckBox = false;
                }
            });
            $scope.applianceMakes = data.makes;
            $scope.totalMakes = data.count;
            $scope.selectedMake = [];
            $scope.selectAllChecked = false;
            $scope.toggle_message = 'Select All';
        })
    }

    $scope.toggleSelection = function toggleSelection(selectedId) {
        var idx = $scope.selectedMake.indexOf(selectedId);

        if (idx > -1) {
            $scope.selectedMake.splice(idx, 1);
            angular.forEach($scope.applianceMakes, function (value, key) {
                if (value.id == selectedId) {
                    value.checked = false;
                }
            });
            $scope.selectAllChecked = false;
            $scope.toggle_message = 'Select All'
        } else {
            if(($scope.selectedApplianceMake && $scope.selectedApplianceMake.id != selectedId) || !$scope.selectedApplianceMake){
                $scope.selectedMake.push(selectedId);
            }

            angular.forEach($scope.applianceMakes, function (value, key) {
                if($scope.selectedApplianceMake){
                    if (value.id == selectedId && selectedId != $scope.selectedApplianceMake.id) {
                        value.checked = true;
                    }
                }else{
                    if(value.id == selectedId){
                        value.checked = true;
                    }
                }

            });
            if($scope.selectedMake.length == $scope.applianceMakes.length){
                $scope.selectAllChecked = true;
                $scope.toggle_message = 'Unselect All'
            }
        }

        $scope.handleMergeButton();
        $scope.listapplianceMake('');
    };

    $scope.handleMergeButton = function() {
        if($scope.selectedApplianceMake && $scope.selectedApplianceMake.id && $scope.selectedMake.length > 0) {
            for(var y=0; y < $scope.selectedMake.length; y++){
                if($scope.selectedMake[y] != $scope.selectedApplianceMake.id){
                    $scope.enableMergeButton = true;
                }else{
                    $scope.enableMergeButton = false;
                    break;
                }
            }
        } else {
            $scope.enableMergeButton = false;
        }
    }

    $scope.listapplianceMake = function listapplianceMake(selectedMakeId){
        if(selectedMakeId && $scope.selectedMake.length) {
            return false
        }
        $scope.selectedMakeRow = '';
        $scope.selectedId = selectedMakeId;
        angular.forEach($scope.applianceMakes, function (value, key) {
            if (value.id == selectedMakeId) {
                value['active'] = true;
            } else {
                value['active'] = false;
            }
        });
    }

    $scope.mergeApplianceMakes = function mergeApplianceMakes(selectedId, selectedMakeToMerge){
        var mergeId;
        if(!selectedMakeToMerge) {
            return false;
        } else {
            mergeId = selectedMakeToMerge.id;
            if(selectedId == mergeId) {
                console.log('Trying to merge the same make');
                $scope.listapplianceMake('');
                return false;
            }
        }
        $http.post(prefix + '/merge_with_applianceMake?selectedId=' + selectedId + '&mergeId=' +mergeId).
            success(function (data) {
                if (data.warning && data.warning.warning === true) {
                    warningModal.show(data.warning.message, data.warning.title, data.warning.id);
                    $scope.fetchApplianceMakes($scope.pageNum);
                    $scope.selectedMake = [];
                    $scope.enableMergeButton = false;
                } else {
                    $scope.fetchApplianceMakes($scope.pageNum);
                    $scope.selectedMake = [];
                    $scope.enableMergeButton = false;
                    toastBox.show('Merged.appliance.makes', 4000);
                }
            });
    }

    $scope.$watch('selectedApplianceMake', function (newVal, oldVal) {
        $scope.handleMergeButton();
        angular.forEach($scope.applianceMakes, function (value, key) {
            if (value.id == newVal.id) {
                value.disableCheckBox = true;
            }else{
                value.disableCheckBox = false;
            }
        });
        if(newVal = ''){
            value.disableCheckBox = false;
        }
    });

    $scope.mergeDescription = '';
    $scope.triggerMerge = function triggerMerge(){
        $scope.mergeDescription = $scope.selectedApplianceMake.text;
        $scope.shouldBeOpen = true;
    }

    $scope.toggleAllRows = function toggleAllRows(toggle_message){
        if(toggle_message == 'Select All'){
            $scope.selectAllChecked = true;
            $scope.selectedMake = [];
            angular.forEach($scope.applianceMakes, function (value, key) {
                if($scope.selectedApplianceMake){
                    if(value.id != $scope.selectedApplianceMake.id){
                        $scope.selectedMake.push(value.id);
                        value.checked = true;
                    }
                }else{
                    $scope.selectedMake.push(value.id);
                    value.checked = true;
                }
            });
            $scope.toggle_message = 'Unselect All';
        }else if(toggle_message == 'Unselect All'){
            $scope.selectAllChecked = false;
            $scope.selectedMake = [];
            angular.forEach($scope.applianceMakes, function (value, key) {
                value.checked = false;
            });
            $scope.toggle_message = 'Select All';
        }
        if($scope.selectedApplianceMake && $scope.selectedApplianceMake.id && $scope.selectedMake.length > 0){
            for(var y=0; y < $scope.selectedMake.length; y++){
                if($scope.selectedMake[y] != $scope.selectedApplianceMake.id){
                    $scope.enableMergeButton = true;
                }else{
                    $scope.enableMergeButton = false;
                    break;
                }
            }
        }else{
            $scope.enableMergeButton = false;
        }
    }
}

function creditcard_option($scope,$http,prefix,$state,toastBox,creditcardcheckresolver) {
    $scope.creditcardvalidation = creditcardcheckresolver.creditCardModule;
    $scope.storedCards = creditcardcheckresolver.storedCards;
    $scope.settingCreditCard = creditcardcheckresolver.settingCreditCard;
    if ($scope.creditcardvalidation && !$scope.settingCreditCard) {
        $scope.errormsg = ' Please enable credit card facilities.';
    } else if ($scope.creditcardvalidation && !$scope.storedCards) {
        $scope.errormsg = ' Please enable Save Cards facilities';
    }
    $scope.credit_option = parseInt(creditcardcheckresolver.value);
    if($scope.credit_option == 4 || $scope.credit_option == 5)
        $scope.isDeferredPayment = 'is_deferred_payment';
    else
        $scope.isDeferredPayment = 'no_deferred_payment';
    $scope.saving = false;
    $scope.changeValue = function(value){
       if(value != 'is_deferred_payment'){
           $scope.isDeferredPayment = false;
       }
       else
           $scope.credit_option = 4;
    }
    $scope.creditcard_submit = function () {
        $scope.saving = true;
        $http.post(prefix + '/system_settings/creditcard_option/update', "creditCard[option]=" + $scope.credit_option).success(function (data, status) {
            if (status == 200) {
                $scope.saving = true;
                $state.go('loggedin.system_settings');
                toastBox.show('credit card type saved', 2000);
            } else {
                $scope.saving = false;
                console.log('error');
            }
        });
    }
}
function PaymentReceiptsEmail($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}


function PartKitCtrl($scope, $http, prefix, $state, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.partkitsPanelHide = false;
    $scope.$state = $state;
    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)$/);
        if(matches[2] === 'partsKit.add_part'){
            $scope.partkitsPanelHide = true;
        } else {
            $scope.partkitsPanelHide = false;
        }
    });

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.partskitForm['partkit[description]'].$error.checkExists = true;
            } else {
                $scope.partskitForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("PARTKIT_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
            $scope.partkitsPanelHide = false;
        });
    }
}

function PartsKitEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedPartskit = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;
    $scope.partkitsPanelHide = false;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.partsKit");
    }

    $scope.editPartKit = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/editPartKit', "id=" + this.selectedId + "&description=" + encodeURIComponent(this.selectedPartskit)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editPartskitForm['selectedPartskit'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.partsKit");
                    }
                }
                $scope.partkitsPanelHide = false;
            });
    }
}

function PartskitListEditCtrl($scope, $state, prefix, $http, partKitresolver, defaultPagingValue, warningModal, canLoad) {
    $scope.partKits = partKitresolver.partKits;
    $scope.totalPartskit = partKitresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.partkitsPanelHide = false;
    $scope.searchResult = false;
    $scope.searchText = "";

    JustAddedUpdatedChanges.call(this, $scope, 'partskit', $state, defaultPagingValue, 'partKits');

    $scope.$on("PARTKIT_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, partKitresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.partKits.push({'id': message.id, 'description': message.description, 'justadded': true});
            partKitresolver.partKits = $scope.partKits;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        partKitresolver.count = parseInt(partKitresolver.count) + 1;
        $scope.totalPartsKit = partKitresolver.count;
    });

    $scope.editPartKit = function (id, description) {
        $state.transitionTo('loggedin.partsKit.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, description) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.description = description;
        $scope.partkitsPanelHide = false;
    }
    $scope.$watch('searchText', function (newVal, oldVal) {
        if (typeof(newVal) != undefined || newVal != '') {
            $scope.currentPage = 1;
            $scope.fetchPartskit($scope.currentPage);
        }
    });

     var canceler = null;
    $scope.fetchPartskit = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;

        $scope.closeDelt = (searchText) ? false : true;

        $http.get(prefix + '/listPartKits?page=' + pageNum + '&limit=' + $scope.limit+'&searchText=' + searchText,{timeout: canceler.promise}).success(function (data) {
            $scope.partKits = data.partKits;
            $scope.totalPartskit = data.count;
            partKitresolver.partKits = data.partKits;
            partKitresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
            $scope.partkitsPanelHide = false;
            $scope.searchResult = (searchText != '') ? true : false;
        })
    }
}

function addPartCtrl($scope, $state, $http, prefix, $timeout, $rootScope, getIdData, confirmationBoxHelper,warningModal, $compile, $translate) {
    $scope.readAccess = getIdData.readAccess;
    $scope.partkitsPanelHide = true;
    $scope.selectedPartsDetails = getIdData.partkitItems;
    $scope.selectedPartKit = getIdData.description;
    $scope.addUrl = prefix + '/system_settings/partkits/' + $state.params.id + '/addPart/addSpreadsheet';

    $scope.rowToDelete = [];

    //$scope.$on("event:save-spreadsheet-data", function (event, message) {
    //    var category = message.category;
    //    var rows = message.rows;
    //    $scope[category] = rows;
    //});

    $scope.addPart = function (event) {
        $scope.saving = true;
        var rowsToDelete = ($scope.rowToDelete && $scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
        $http.post($scope.addUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) + "&rowsToDelete=" + rowsToDelete).
            success(function (data, status) {
                if (status == 200) {
                    $scope.saving = false;
                    if(data.error) {
                        warningModal.show(data.message, 'Error', 'add_part_error');
                        $scope.isErrorReturn = true;
                        $scope.spreadSheetErrorRows(data.partInvalidRows);

                    } else {
                        $state.transitionTo("loggedin.partsKit");
                    }
                }
            });
    }


    /*** Routing URL for to load the default data ***/
    $scope.getPartSpreadsheetURL = prefix + '/system_settings/partkits/' + $state.params.id + '/get_part_spreadsheet';

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.partHotId = "partkit_addpart";
    $scope.isErrorReturn = false;

    /*** Loaded new spreadsheet ***/
    addpartSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $timeout, $compile, $translate);

}

function addPartReorderCtrl($scope, $state, $http, prefix, $timeout, $rootScope, getIdData, confirmationBoxHelper,warningModal,toastBox, $compile, $translate) {
    $scope.readAccess = getIdData.readAccess;
    $scope.selectedPartReorder = getIdData.description;
    $scope.stockLocations = getIdData.stockLocations;
    $scope.addUrl = prefix + '/system_settings/partsreorder/' + $state.params.id + '/addPart/addSpreadsheet';
    $scope.selectedStockLocations = getIdData.selectedStockLocationsArray;
    $scope.selectedStockLocation = getIdData.selectedStockLocations;

    PartsReorderListManager.call(this, $scope, $scope.stockLocations);

    $scope.rowToDelete = [];

    $scope.StockLocationChanged = function(selectedStockLocations){
        var stockLocations = '';
        $scope.selectedStockLocations = selectedStockLocations;
        if($scope.stockLoctionInitialValue == 1 || selectedStockLocations.length > 0){
            $scope.isSelectedStockLocations = true;
            $scope.stockLoctionInitialValue = 2;
        }else{
            $scope.isSelectedStockLocations = false;
        }
        if($scope.selectedStockLocations && $scope.selectedStockLocations.length) {
            angular.forEach($scope.selectedStockLocations, function (val, key) {
                var concat = (stockLocations != '' ? "," : "");
                stockLocations += concat + val.id;
            });
        }
        // Todo: Need to fix this, as this is coming from twig, I'm using JQuery to set value
        $('#selectedStockLocation').val(stockLocations);

        $scope.addPart = function (event) {
            $scope.saving = true;
            $http.post(prefix + '/save_reorderlist_stockLocation', 'reorderListId= ' + $state.params.id + '&stockLocationList= ' + stockLocations).success(function (data) {

            });
            var rowsToDelete = ($scope.rowToDelete && $scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
            $http.post($scope.addUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) + "&rowsToDelete=" + rowsToDelete + "&stockLocation=" + $scope.selectedStockLocation).
            success(function (data, status) {
                if (status == 200) {
                    $scope.saving = false;
                    if(data.error) {
                        warningModal.show(data.message, 'Error', 'add_part_error');
                        $scope.isErrorReturn = true;
                        $scope.spreadSheetErrorRows(data.partInvalidRows);

                    } else {
                        $state.transitionTo("loggedin.partsReorder");
                    }
                }
            });
        }
    }


    /*** Routing URL for to load the default data ***/
    $scope.getPartSpreadsheetURL = prefix + '/system_settings/partsreorder/' + $state.params.id + '/get_partReorder_spreadsheet';

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.partHotId = "partReorderAddpart";
    $scope.isErrorReturn = false;

    /*** Loaded new spreadsheet ***/
    addpartReorderSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $timeout,toastBox,$compile, $translate);

}

function addpartReorderSpreadsheet($scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $timeout,toastBox,$compile, $translate)
{
    $scope.spreadSheetParts = [];
    $scope.isPartSpreadSheetEmpty = true;
    $scope.displaySpreadsheet = false;
    $scope.instanceLoad=[];
    $scope.colHeadersRequired =[];

    spreadsheetScroll.call(this, $scope);

    /*** Spreadsheet default settings for both parts & supplier ***/
    $scope.rowHeaders = true;
    $scope.startRows = 1;
    /*** Set spare row as 0, because when load default data it automatically insert an another empty row ***/
    $scope.minSpareRows = 0;
    $scope.minRows = 1;
    $scope.width = "auto";
    $scope.autoWrapCol = true;
    $scope.autoWrapRow = true;
    $scope.partRowCount = 0;
    var screenType = 'add_part_reorder';
    /*** Load the default data into parts spreadsheet ***/
    $scope.partsAfterInit= function() {

        /*** Get the default handsontable settings ***/
        $scope.updateSettings = this.updateSettings;

        $http.get($scope.getPartSpreadsheetURL).success(function(response) {

            $scope.colHeadersParts = response.colHeaders;
            $scope.startColsParts = response.startColsParts;
            $scope.spreadSheetParts = response.parts;
            $scope.selectedPartsDetails = response.selectedPartsDetails;
            $scope.selectedSupplierDetails = response.selectedSupplierDetails;
            $scope.selectedDeliveryMethods = response.selectedDeliveryMethods;
            $scope.deliveryMethodList = response.deliveryMethodList;
            $scope.nominalCodeList = response.nominalCodeList;
            $scope.replenishment = response.replenishment;
            $scope.replenishmentLocation = response.replenishmentLocation;
            $scope.stockLocation = response.stockLocation;
            $scope.partScope = [];
            $scope.supplierScope = [];
            $scope.deliveryMethodScope = [];
            $scope.colHeadersRequired[$scope.partHotId] = response.colHeadersRequired;

            /*** Load the default value when new row created ***/
            $scope.partDataSchema= {id: '', partsId: '',  minimumStockLevel: '',orderQuantity: '',replenishment: '',replenishmentLocation: '',supplierId: '', reorderDeliveryMethod: '', nominalCodeId: "", daysToDelivery: '', deleteAction: ""};
            $scope.partColWidths = [1,300,200,200,200,200,150,200,200,200,150,50];

            /*** Assign the spreadsheet columns and its cell type ***/
            $scope.partColumns = [
                {data: 'id', type: 'numeric', readOnly: true},
                { renderer: $scope.partRenderer, data: 'partsId', editor: 'select2', placeholder: 'Please select', className: 'overflow_handsontable select_icon',
                    select2Options: {
                        editable: true,
                        minimumInputLength:2,
                        ajax: { url: prefix + '/get_parts_dropdown',
                            data: function (query, page, partIds, supplierId) {
                                if(query == '') {
                                    return false;
                                }
                                partIds = $scope.selectedPartIds;
                                //This is for select2 js
                                if($(this).val()){
                                    var selected_part_id = parseInt($(this).val());
                                    partIds = _.without(partIds,selected_part_id)
                                }
                                supplierId = '';
                                var instance = $scope.instanceLoad['partReorderAddpart'];
                                if(instance) {
                                    var activeRow = instance.getActiveEditor().row;
                                    supplierId = instance.getSourceDataAtCell(activeRow,'supplierId');
                                }
                                return { 'query': encodeURIComponent(query), 'selectedPartIds': partIds, 'supplierId': supplierId, 'screenType' : screenType};
                            },
                            results: function (data, page) {
                                $scope.partScope = data.parts;
                                $scope.defaultPartsData = data.defaultPartsData;

                                // grouping parts
                                var formatResult = _.groupBy(data['parts'],'id');

                                var parts = [];
                                angular.forEach(formatResult, function(groupedParts,key) {
                                    parts[key] = groupedParts[0];
                                    var supplierRef = [];
                                    angular.forEach(groupedParts, function(part) {
                                        if(part['supplierpartnumber'] != null) {
                                            supplierRef.push({'supplierpartnumber' : part['supplierpartnumber'], 'suppliername' : part['suppliername']});
                                        }
                                    });
                                    parts[key]['supplierpartnumber'] = supplierRef;
                                });
                                return { results: Object.values(parts) };
                            }
                        },
                        formatResult: function(item, container, query, escapeMarkup) {
                            var splitSearchTerm = query.term.split(" ");
                            angular.forEach(splitSearchTerm, function (v,k) {
                                splitSearchTerm[k] = "(" + v + ")";
                            });
                            let regex = new RegExp(splitSearchTerm.join("|"), 'gi');
                            var htmlresult = item.text.replace(regex, function (i) { return '<u>' + i + '</u>'; });

                            if(item.manufacturepartnumber){
                                htmlresult += ', '+ $translate('MPN') + ' - ' + item.manufacturepartnumber.replace(regex, function (i) { return '<u>' + i + '</u>'; });
                            }

                            if(item.supplierpartnumber.length > 0) {
                                var spns = item.supplierpartnumber;

                                htmlresult += '<br>';
                                angular.forEach(spns, function (spn) {
                                    if(spn['suppliername'] != null && regex.test(spn['supplierpartnumber'])) {
                                        spn['supplierpartnumber'] = spn['supplierpartnumber'].replace(regex, function(i) { return '<u>' + i + '</u>' });
                                        htmlresult += '<div class="select2 supplier-product-code cs-text-muted"><span>' + $translate('product.code', { supplierName : spn['suppliername'] }) + '</span>: ' + spn['supplierpartnumber']+ '</div>';
                                    }
                                });

                            }
                            return $compile('<div class="select2 parts-list-dropdown">' + htmlresult + '</div>')($scope);
                        },
                        dropdownCssClass: "handsontable-select",
                        dropdownAutoWidth: true, width: 'resolve'
                    },
                    strict: true, allowInvalid: false
                },
                {renderer: $scope.partRenderer, data: 'minimumStockLevel', type: 'numeric', allowInvalid: false, validator: $scope.cellValidator},
                {renderer: $scope.partRenderer, data: 'orderQuantity', type: 'numeric', allowInvalid: false, validator: $scope.cellValidator},
                { renderer: $scope.partRenderer, className: 'overflow_handsontable select_icon',
                    data: 'replenishment',
                    editor: 'select2',
                    placeholder: 'Please select',
                    validator: $scope.cellValidator,
                    select2Options: {
                        data: $scope.replenishment,
                        dropdownAutoWidth: true,
                        dropdownCssClass: "handsontable-select",
                        width: 'resolve'
                    }
                },
                { renderer: $scope.partRenderer, className: 'overflow_handsontable select_icon',
                    data: 'replenishmentLocation',
                    editor: 'select2',
                    placeholder: 'Please select',
                    readOnly: true,
                    // validator: $scope.cellValidator,
                    select2Options: {
                        data: $scope.replenishmentLocation,
                        dropdownAutoWidth: true,
                        dropdownCssClass: "handsontable-select",
                        width: 'resolve'
                    }
                },

                {renderer: $scope.partRenderer, data: 'supplierId', editor: 'select2', placeholder: 'Please select', className: 'overflow_handsontable select_icon',
                    select2Options: {
                        minimumInputLength:2,
                        ajax: { url: prefix + '/get_all_suppliers',
                            data: function (query, page) {
                                if(query == '') {
                                    return false;
                                }
                                return { 'q': query};
                            },
                            results: function (data, page) {

                                var supplierScope = [];

                                angular.forEach(data, function (supplier, key) {
                                    supplier.deliveryMethods = _.reject(supplier.deliveryMethods, function(value, key, object) {return value.id === 3;});
                                    supplierScope.push(supplier);
                                });

                                $scope.supplierScope = supplierScope;
                                return { results: supplierScope };
                            }
                        },
                        dropdownCssClass: "handsontable-select",
                        dropdownAutoWidth: true, width: 'resolve'
                    },
                    strict: true, allowInvalid: false},

                  { renderer: $scope.partRenderer, className: 'overflow_handsontable select_icon',
                    data: 'reorderDeliveryMethod',
                    editor: 'select2',
                    placeholder: 'Please select',
                    validator: $scope.cellValidator,
                    select2Options: [],
                    defaultOptions: {
                        data: $scope.deliveryMethodList,
                        dropdownAutoWidth: true,
                        dropdownCssClass: "handsontable-select",
                        width: 'resolve'
                    }
                },
                { renderer: $scope.partRenderer, className: 'overflow_handsontable select_icon',
                    data: "nominalCodeId",
                    editor: 'select2',
                    placeholder: 'Please select',
                    validator: $scope.cellValidator,
                    select2Options: {
                        data: $scope.nominalCodeList,
                        dropdownAutoWidth: true,
                        dropdownCssClass: "handsontable-select",
                        width: 'resolve'
                    }
                },
                {renderer: $scope.partRenderer, data: 'daysToDelivery', type: 'numeric', allowInvalid: false, validator: $scope.cellValidator},
                {renderer: $scope.partRenderer, data: 'deleteAction', readOnly: true, disableVisualSelection: true}
            ];

            $scope.hidePartColumns = {columns:[0]};
            /*** Load the new settings into the spreadsheet and load them ***/
            $scope.updateSettings({colHeaders: $scope.colHeadersParts, fillHandle: {direction: 'vertical', autoInsertRow: true},
                rowHeaders: $scope.rowHeaders, startRows: $scope.startRows, startCols: $scope.startColsParts,
                colWidths: $scope.partColWidths, columns: $scope.partColumns, minSpareCols: 0,stretchH: 'all',comments: true,
                autoWrapRow:$scope.autoWrapRow,data: $scope.spreadSheetParts,hiddenColumns:$scope.hidePartColumns,
                formulas: true, minSpareRows: $scope.minSpareRows, dataSchema: $scope.partDataSchema, minRows: $scope.minRows, viewportColumnRenderingOffset: 10,beforeValidate: $scope.beforeValidate,afterSelection : $scope.afterSelection,afterGetColHeader : $scope.afterGetColHeader}); /*dataSchema: $scope.dataSchema,*/

            $scope.displaySpreadsheet = true;
            $scope.show_part_handsontable_spreadsheet = true;
        });
    }

    $scope.beforeValidate = function(value, row, prop, source){
        if(value != '') {
            var activeEditor = this.getActiveEditor(),
                col = activeEditor.col,
                removeHighlightRow = [],
                hotId = this.getSettings().hotId,
                cellType = activeEditor.cellProperties.type;

            if (prop == 'daysToDelivery') {
                $scope.numericLimitValidator(value, row, col, prop, 3, cellType, hotId);
            }else if (prop == 'minimumStockLevel' || prop == 'orderQuantity') {
                $scope.numericLimitValidator(value, row, col, prop, 6, cellType, hotId);
            }
        }
    }


    $scope.numericLimitValidator = function (value, row, col, prop, limit, cellType, hotId) {
        if(value != '') {
            var isInvalid = false;
            if(prop == 'daysToDelivery'){
                var data = parseInt(value);
            }else{
                var data = parseFloat(value);
            }
            if(isNaN(value))
            {
                toastBox.show('Invalid data', 1000);
            }else if(prop == 'daysToDelivery' && data.toString().length > limit){
                isInvalid = true;
                toastBox.show('Invalid data length', 1000);
            } else if((prop == 'minimumStockLevel' || prop == 'orderQuantity') && ((roundFloat(data,0,'str')).length) > limit){
                isInvalid = true;
                toastBox.show('Invalid data length', 1000);
            }

            if(isInvalid)
            {
                $scope.instanceLoad[hotId].setCellMeta(row,col,"className","errorRowList");
            }else{
                $scope.instanceLoad[hotId].setCellMeta(row,col,"className","");
            }
            $scope.instanceLoad[hotId].render();
        }
    };

    $scope.selectedPartDropDownValues= [];
    $scope.selectedSupplierDropDownValues=[];
    /*** Renderer for parts spreadsheet ***/
    $scope.partRenderer = function(instance, td, row, col, prop, value, cellProperties) {
        var cellTitle = instance.getCellMeta(row, col);
        var cellCase = cellTitle.prop;

        //# Used to scroll to the last row
        if((typeof instance.getActiveEditor() != 'undefined') && (instance.getActiveEditor().row == row)) {
            $scope.scrollToLastRow(row, col, instance);
        }

        switch(cellCase) {

            case 'partsId':
                //# Check its active editor
                $scope.isPartActiveEditor = $scope.checkActiveEditor(instance, col, row, true, false);

                //# Avoid while delete line items, the previous part description stored in empty line item.
                if(value == '') {
                    instance.getCell(row,col).innerText = '';
                    instance.getCell(row,col).innerHTML = '';
                }

                var partsId;
                //# In edit during load didn't get this scope value
                if($scope.partScope.length && instance.getActiveEditor().row == row && instance.getActiveEditor().col == 1) {
                    for (var index = 0; index < $scope.partScope.length; index++)
                    {
                        if (value == $scope.partScope[index].id)
                        {
                            partsId = $scope.partScope[index].id;
                            value = $scope.partScope[index].text;
                            if($scope.isPartActiveEditor) {
                                /*** Load the default value into the row once added in parts table ***/
                                $scope.selectedPartDropDownValues[partsId] = value;
                            }
                            break;
                        }
                    }
                }
                else if($scope.selectedPartDropDownValues.length) { //# Will execute only validation error from backend call
                    partsId = value;

                    if(typeof $scope.selectedPartDropDownValues[parseInt(value)] != 'undefined') {
                        value = $scope.selectedPartDropDownValues[parseInt(value)];
                    } else if($scope.selectedPartsDetails != null) { //# Execute during edit screen and having error rows.
                        value = $scope.selectedPartsDetails[value];
                    }

                }
                else if($scope.selectedPartsDetails != null) { //# Else part work only in edit section
                    partsId = value;
                    value = $scope.selectedPartsDetails[value];
                }
                var toolTip = value;
                if((typeof toolTip != 'undefined') && (toolTip != '') && toolTip != null && (toolTip.length > length)) {
                    cellProperties.comment={value: toolTip};
                }
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'supplierId':
                //# Check its active editor
                $scope.isSupplierActiveEditor = $scope.checkActiveEditor(instance, col, row, true, false);

                //# Avoid while delete line items, the previous part description stored in empty line item.
                if(value == '') {
                    instance.getCell(row,col).innerText = '';
                    instance.getCell(row,col).innerHTML = '';
                }

                var suppliersId;
                //# In edit during load didn't get this scope value
                if($scope.supplierScope.length && instance.getActiveEditor().row == row && instance.getActiveEditor().col == 6) {
                    for (var index = 0; index < $scope.supplierScope.length; index++)
                    {
                        if (parseInt(value) === $scope.supplierScope[index].id)
                        {
                            suppliersId = $scope.supplierScope[index].id;
                            value = $scope.supplierScope[index].text;

                            if($scope.spreadSheetParts[row]['nominalCodeId'] == ''){
                                $scope.spreadSheetParts[row]['nominalCodeId'] = $scope.supplierScope[index].nominalCode.id;
                            }
                            $scope.deliveryMethodScope[row] = $scope.supplierScope[index].deliveryMethods;

                             $scope.partColumns[7].select2Options[row] = {
                                 data: $scope.supplierScope[index].deliveryMethods,
                                 dropdownAutoWidth: true,
                                 dropdownCssClass: "handsontable-select",
                                 width: 'resolve'
                             }

                            $scope.selectedSupplierDropDownValues[suppliersId] = value;

                            // Handsontable.cellTypes.text.renderer.apply(this, arguments);
                            // break;
                        }
                    }
                }
                else if($scope.selectedSupplierDropDownValues.length) { //# Will execute only validation error from backend call
                    suppliersId = value;

                    if(typeof $scope.selectedSupplierDropDownValues[parseInt(value)] != 'undefined') {
                        value = $scope.selectedSupplierDropDownValues[parseInt(value)];
                    } else if($scope.selectedSupplierDetails != null) { //# Execute during edit screen and having error rows.
                        value = $scope.selectedSupplierDetails[value];
                    }


                }
                else if($scope.selectedSupplierDetails != null) { //# Else part work only in edit section
                    suppliersId = value;
                    value = $scope.selectedSupplierDetails[value];
                    $scope.deliveryMethodScope[row] = $scope.selectedDeliveryMethods[suppliersId];

                    $scope.partColumns[7].select2Options[row] = {
                        data: $scope.selectedDeliveryMethods[suppliersId],
                        dropdownAutoWidth: true,
                        dropdownCssClass: "handsontable-select",
                        width: 'resolve'
                    }

                }
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'minimumStockLevel':

                //# Check and update with two decimal point in spreadsheet
                $scope.newminimumStockLevel = value > 0 ? parseFloat(value) : 1;
                $scope.spreadSheetParts[row]['minimumStockLevel'] = $scope.newminimumStockLevel.toFixed(2);
                value = $scope.newminimumStockLevel.toFixed(2);
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'orderQuantity':

                //# Check and update with two decimal point in spreadsheet
                $scope.neworderQuantity = value > 0 ? parseFloat(value) : 1;
                $scope.spreadSheetParts[row]['orderQuantity'] = $scope.neworderQuantity.toFixed(2);
                value = $scope.neworderQuantity.toFixed(2);
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'reorderDeliveryMethod':
                if(typeof $scope.deliveryMethodScope[row] !== 'undefined'){
                    var selectedId;
                    for (var index = 0; index < $scope.deliveryMethodScope[row].length; index++)
                    {
                        if(value !=1 && value != '' && (typeof $scope.deliveryMethodScope[row][index].children != 'undefined')) {
                            var children = $scope.deliveryMethodScope[row][index].children;
                            for(var i =0; i < children.length; i++) {
                                if (value === children[i].id)
                                {
                                    selectedId = children[i].id;
                                    value = children[i].text;
                                }
                            }
                        } else {
                            if (parseInt(value) === $scope.deliveryMethodScope[row][index].id)
                            {
                                selectedId = $scope.deliveryMethodScope[row][index].id;
                                value = $scope.deliveryMethodScope[row][index].text;
                            }
                        }
                    }
                }else {
                    var selectedId;
                    for (var index = 0; index < $scope.deliveryMethodList.length; index++)
                    {
                        if (parseInt(value) === $scope.deliveryMethodList[index].id)
                        {
                            selectedId = $scope.deliveryMethodList[index].id;
                            value = $scope.deliveryMethodList[index].text;
                        }else{
                            $scope.partColumns[7].select2Options[row] = {
                                data: $scope.deliveryMethodList,
                                dropdownAutoWidth: true,
                                dropdownCssClass: "handsontable-select",
                                width: 'resolve'
                            }
                        }
                    }
                }
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'nominalCodeId':
                var selectedId;
                for (var index = 0; index < $scope.nominalCodeList.length; index++)
                {
                    if (parseInt(value) === $scope.nominalCodeList[index].id)
                    {
                        selectedId = $scope.nominalCodeList[index].id;
                        value = $scope.nominalCodeList[index].text;
                    }
                }
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'daysToDelivery':

                //# Check and update with two decimal point in spreadsheet
                $scope.newdaysToDelivery = value > 0 ? parseInt(value) : 1;
                $scope.spreadSheetParts[row]['daysToDelivery'] = $scope.newdaysToDelivery;
                value = $scope.newdaysToDelivery;
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'replenishment':
                var selectedId;
                for (var index = 0; index < $scope.replenishment.length; index++)
                {
                    if (parseInt(value) === $scope.replenishment[index].id)
                    {
                        selectedId = $scope.replenishment[index].id;
                        value = $scope.replenishment[index].text;
                    }
                }
                let cellProperty = instance.getCellMeta(row, 5);
                if(value === "Stock and PO"){
                    cellProperty.readOnly = false;
                }else{
                    cellProperty.readOnly = true;
                }
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'replenishmentLocation':
                var selectedLocationId;
                for (var index = 0; index < $scope.replenishmentLocation.length; index++)
                {
                    if (value == $scope.replenishmentLocation[index].id)
                    {
                        selectedLocationId = $scope.replenishmentLocation[index].id;
                        value = $scope.replenishmentLocation[index].text;
                    }
                }
                if(typeof selectedLocationId == 'undefined'){
                    for (var index = 0; index < $scope.stockLocation.length; index++)
                    {
                        if (value == $scope.stockLocation[index].id)
                        {
                            selectedLocationId = $scope.stockLocation[index].id;
                            value = $scope.stockLocation[index].text;
                        }
                    }
                }


                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'deleteAction':
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                var div = document.createElement('DIV');
                var a = document.createElement('a');
                var linkText = document.createTextNode("Delete");
                a.appendChild(linkText);
                a.title = "Delete Action";
                a.style= "cursor:pointer;"
                div.appendChild(a);
                $(td).empty().append(div).append(a);
                $(a).on('click', function(e) {
                    $scope.deleteAction(row, col, $scope.partHotId);
                });
                return td;
                break;
        }
    }

    /*** After change action from parts spreadsheet ***/
    $scope.partsAfterChange=function(changes, src) {

        //# Load the spreadsheet Instance record to alter the spreadsheet
        $scope.instanceLoad[$scope.partHotId] = this;

        //# If the current src is edit mode, then call the insert function
        if(src === 'edit') {
            $scope.insertRow(this, changes, src);

            if($scope.isErrorReturn) {
                var row = this.getActiveEditor().row;
                $scope.removeErrorMessage(row, $scope.partHotId, 'errorRowList');
            }
            if (changes[0][1] == 'replenishment' && changes[0][3] == '2') {
                this.setDataAtCell(changes[0][0],5,'','edit');
            }
        }
        //# Load an empty default data during edit section
        else if((src === 'loadData' && $scope.spreadSheetParts.length)) {
            var recordLength = $scope.spreadSheetParts.length-1;
            //# Check the last record having id value
            if($scope.spreadSheetParts[recordLength].id != '') {
                this.alter('insert_row', this.countRows());
                this.render();
            }

            $timeout(function() {
                $scope.scrollingSpreadsheet($scope.partHotId, $scope.instanceLoad[$scope.partHotId]);
            }, 1000);
        }

        //# Check with custom scope value, by default in spreadsheet it having 5 empty rows
        if($scope.spreadSheetParts.length > 1) {
            $scope.partsSaveRecord = this.getData();

            //# Validate each cell from the row to enable the save button
            for(var i=0; i< $scope.partsSaveRecord.length-1; i++) {

                //# Get the single row details
                var row = $scope.partsSaveRecord[i];

                //# j=1 assigned to avoid the first column of 'primaryID' of the row.
                //# length-1 used to avoid the last column of 'delete' of the row
                for(var j=1; j<row.length-1; j++ ) {
                    if (screenType == 'add_part_reorder'  && j == 4 && row[4] == '2' ) {
                        j++;
                        continue;
                    }
                    if(screenType == 'add_part_reorder' && parseInt(row[2]) > parseInt(row[3])){
                        $scope.validateCell=true;
                        break;
                    }
                    if(screenType == 'add_part_reorder' && roundFloat(parseFloat(row[3]),0,'str').length > 6){
                        $scope.validateCell=true;
                        break;
                    }
                    //# Cell all the cells are valid or not
                    if(row[j] === '' || row[j] == null) {
                        $scope.validateCell=true;
                        break;
                    } else {
                        $scope.validateCell=false;
                    }
                }
                //# If any cols is invalid then break the loop.
                if($scope.validateCell == true) {
                    $scope.isPartSpreadSheetEmpty=true;
                    break;
                } else {
                    $scope.isPartSpreadSheetEmpty=false;
                }
            }
        }

        if(screenType == 'add_part_kit'){
            var validationCond = $scope.spreadSheetParts.length > 0;
            var emptyRow = false;
        }else{
            var validationCond = $scope.spreadSheetParts.length > 1;
            var emptyRow = true;
        }

        //# Check with custom scope value, by default in spreadsheet it having 5 empty rows
        if(validationCond) {
            $scope.partsSaveRecord = this.getData();
            if(emptyRow == true){
                var limit = $scope.partsSaveRecord.length-1;
            }else if(emptyRow == false){
                var limit = $scope.partsSaveRecord.length;
            }

            //# Validate each cell from the row to enable the save button
            $scope.spreadSheetValidation($scope.partsSaveRecord, $scope.partHotId, limit);
            var colData = this.getDataAtCol(1);
            $scope.partRowCount = $scope.NonEmptyRowsCount(colData);
        }
    }
    $scope.NonEmptyRowsCount = function NonEmptyRowsCount(colData){
        var nonEmptyRowsCount = colData.reduce(function (count, data) {
            return $scope.isColEmpty(data) ? count : count + 1;
        }, 0);
        return nonEmptyRowsCount;
    }
    $scope.isColEmpty = function isColEmpty(value){
        return typeof value == 'undefined' || value === null || value.length === 0;
    }
    $scope.spreadSheetValidation = function(saveRecords, isSpreadSheetEmpty, limit) {
        $scope[isSpreadSheetEmpty] = true;

        //# Relax the cell validation.
        var releaseCells = [7];

        for(var i=0; i< limit; i++) {

            //# Get the single row details
            var row = saveRecords[i];

            //# j=1 assigned to avoid the first column of 'primaryID' of the row.
            //# length-1 used to avoid the last column of 'delete' of the row
            for(var j=1; j<row.length-1; j++ ) {

                //# Relax the validation for particular cell
                var cellRelax = releaseCells.indexOf(j);
                //# Cell all the cells are valid or not
                if((row[j] === '' || row[j] == null) && cellRelax == -1 ) {
                    $scope.validateCell=true;
                    break;
                } else {
                    $scope.validateCell=false;
                }
            }
            //# If any cols is invalid then break the loop.
            if($scope.validateCell == true) {
                $scope[isSpreadSheetEmpty]=true;
                break;
            } else {
                $scope[isSpreadSheetEmpty]=false;
            }
        }
    }

    $scope.deleteAction = function(r, c, hotId) {
        var confirmation_message = 'This row has not been saved yet, are you sure you want to delete it?';
        if(c === ($scope.instanceLoad[hotId].getInstance().countCols() -1)) {
            //# Before delete confirm
            confirmationBoxHelper.getConfirmation(confirmation_message, this)
                .then(function() {

                    //# Get the primary id of the edit screen, to delete from backend
                    var rowId=$scope.instanceLoad[hotId].getSourceDataAtRow(r);
                    if((typeof rowId != 'undefined') && rowId.id) {
                        $scope.rowToDelete.push(rowId.id);
                    }
                    var ColData = $scope.instanceLoad[hotId].getDataAtCol(1);
                    $scope.partRowCount = $scope.NonEmptyRowsCount(ColData);

                    $scope.instanceLoad[hotId].alter('remove_row', r);
                    $scope.instanceLoad[hotId].render();

                }, function() {
                    return false
                });
        }
    }

    $scope.tabMoves= function (event) {
        if(event.shiftKey) {
            return {row: 2, col: 1};
        }
        else {
            return {row: 0, col: 1};
        }
    }

    /** Check the cell validation ***/
    $scope.cellValidator = function (value, callback) {

        if(value != '') {
            if(this.type == 'numeric' && isNaN(value)) {
                callback(false);
            }
            callback(true);
        } else {
            callback(false);
        }
    }

    /*** If edit the last row then need to insert a new row with default row ***/
    $scope.insertRow = function(currentScope,changes, src) {
        var description = currentScope.getDataAtRow(currentScope.countRows()-1);
        if (changes != null && changes[0][0] === (currentScope.countRows() - 1) && src === 'edit' && description[1] != '') {
            currentScope.alter('insert_row', currentScope.countRows());
            $scope.scrollToLastRow(currentScope.getActiveEditor().row, currentScope.getActiveEditor().col, currentScope);
            currentScope.render();
        }
    }

    /*** Check the valid html tag ***/
    $scope.strip_tags = function(input, allowed) {
        var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
            commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

        // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
        allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

        return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });
    }

    /*** Get the current active editor and validate ***/
    $scope.checkActiveEditor = function(instance, col, row, checkCol, checkRow) {
        var activeEditor = instance.getActiveEditor();

        if(typeof activeEditor != 'undefined') {
            var cellTitle = instance.getCellMeta(activeEditor.row, activeEditor.col);
            var cellCase = cellTitle.prop;

            //# Used to update the default quantity/unit cost for changing the parts.
            var data = instance.getData();
            if(data[row][0] === '' && cellCase === 'partsId') {
                return true;
            }
        }
        return false;
    }

    /*** Once select the value from dropdown update the value in spreadsheet line items ***/
    $scope.alterSave = function(value, dropDownType) {

        $scope.isPartSpreadsheet = false;
        $scope.newSelectedValue = '';

        if($scope.isPartSpreadsheet) {
            $scope.spreadSheetParts = $scope.reLoadSpreadsheet($scope.spreadSheetParts, dropDownType, $scope.newSelectedValue, $scope.partHotId);

            /*** Replace the default schema value, when new screen it will automatically update it ***/
            $scope.partDataSchema[dropDownType]=$scope.newSelectedValue;
            $scope.instanceLoad[$scope.partHotId].updateSettings({dataSchema: $scope.partDataSchema});
            $scope.instanceLoad[$scope.partHotId].render();
        }
    }

    /*** Reload the spreadsheet data with hotId ***/
    $scope.reLoadSpreadsheet = function(spreadsheet, key, newValue, hotId) {

        for(var i = 0, l = spreadsheet.length; i < l; i++) {
            if(typeof spreadsheet[i][key] != 'undefined') {
                spreadsheet[i][key] = newValue;
            }
        }
        return spreadsheet;
    }

    /*** Display the error rows ***/
    $scope.spreadSheetErrorRows = function(partInvalidRows) {

        for(var i=0; i<partInvalidRows.length; i++) {
            var currentRow = partInvalidRows[i];
            $scope.displayErrorRows(currentRow, $scope.partHotId);
        }
    }

    /** Display the error row line items in spreadsheet **/
    $scope.displayErrorRows = function(currentRow, hotId) {
        var currentMetaRow = $scope.instanceLoad[hotId].getCellMetaAtRow(currentRow);

        for(var j=0; j<currentMetaRow.length; j++ ) {
            $scope.instanceLoad[hotId].setCellMeta(currentRow,j,"className","errorRowList");
            $scope.instanceLoad[hotId].render();
        }
    }

    /** Remove the error class from the line item **/
    $scope.removeErrorMessage = function(row, hotId, toRemove) {
        var currentMetaRow = $scope.instanceLoad[hotId].getCellMetaAtRow(row);
        var reg = new RegExp('(\\s|^)'+toRemove+'(\\s|$)');

        for(var j=0; j<currentMetaRow.length; j++ ) {
            var newCssClass = currentMetaRow[j].className;

            if(newCssClass) {
                newCssClass=newCssClass.replace(reg,'');
                $scope.instanceLoad[hotId].setCellMeta(row,j,"className", newCssClass);
            }
        }
        $scope.instanceLoad[hotId].render();
    }

    $scope.afterSelection = function(value, row, prop, source){
        var instanceSettings = this.getSettings();
        if(row == 1)
        {
            instanceSettings.fillHandle = false;
        }else {
            instanceSettings.fillHandle = { direction: 'vertical', autoInsertRow: true};
        }

    }

    //# Update the column header with required field
    $scope.afterGetColHeader = function(col, TH) {
        if($scope.colHeadersRequired[this.getSettings().hotId][col]) {
            TH.className='col_header_bg';
        }
    }

}

function PaymentReceiptsEmail($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}
function PartsReorderCtrl($scope, $http, prefix, $state, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.partsReorderPanelHide = false;
    $scope.$state = $state;
    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)$/);
        if(matches[2] === 'partsReorder.add_part'){
            $scope.partsReorderPanelHide = true;
        } else {
            $scope.partsReorderPanelHide = false;
        }
    });

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.partsReorderForm['partsreorder[description]'].$error.checkExists = true;
            } else {
                $scope.partsReorderForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("PARTREORDER_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
            $scope.partsReorderPanelHide = false;
        });
    }
}

function PartsReorderEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedPartsReorder = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;
    $scope.partsReorderPanelHide = false;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.partsReorder");
    }

    $scope.editPartsReorder = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/editPartReorder', "id=" + this.selectedId + "&description=" + encodeURIComponent(this.selectedPartsReorder)).
        success(function (data, status) {
            if (status == 200) {
                $scope.updating = false;
                if (data.error && data.error == 'Exists') {
                    $scope.current.editPartsReorderForm['selectedPartsReorder'].$error.checkExists = true;
                } else {
                    $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                    $state.$current.parent.self.data.editedId = $scope.selectedId;
                    $state.$current.parent.self.data.limit = $scope.limit;
                    $state.transitionTo("loggedin.partsReorder");
                }
            }
            $scope.partsReorderPanelHide = false;
        });
    }
}

function PartsReorderListEditCtrl($scope, $state, prefix, $http, partsReorderresolver, defaultPagingValue, warningModal, canLoad) {
    $scope.partsReorders = partsReorderresolver.partsReorders;
    $scope.totalPartsReorder = partsReorderresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.partsReorderPanelHide = false;
    $scope.searchResult = false;
    $scope.searchText = "";

    JustAddedUpdatedChanges.call(this, $scope, 'partsreorder', $state, defaultPagingValue, 'partsReorders');

    $scope.$on("PARTREORDER_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, partsReorderresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.partsReorders.push({'id': message.id, 'description': message.description, 'justadded': true});
            partsReorderresolver.partsReorders = $scope.partsReorders;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        partsReorderresolver.count = parseInt(partsReorderresolver.count) + 1;
        $scope.totalPartsReorder = partsReorderresolver.count;
    });

    $scope.editPartsReorder = function (id, description) {
        $state.transitionTo('loggedin.partsReorder.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, description) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.description = description;
        $scope.partsReorderPanelHide = false;
    }
    $scope.$watch('searchText', function (newVal, oldVal) {
        if (typeof(newVal) != undefined || newVal != '') {
            $scope.currentPage = 1;
            $scope.fetchPartsReorder($scope.currentPage);
        }
    });

    var canceler = null;
    $scope.fetchPartsReorder = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;

        $scope.closeDelt = (searchText) ? false : true;

        $http.get(prefix + '/listPartsReorder?page=' + pageNum + '&limit=' + $scope.limit+'&searchText=' + searchText,{timeout: canceler.promise}).success(function (data) {
            $scope.partsReorders = data.partsReorders;
            $scope.totalPartsReorder = data.count;
            partsReorderresolver.partsReorders = data.partsReorders;
            partsReorderresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
            $scope.partsReorderPanelHide = false;
            $scope.searchResult = (searchText != '') ? true : false;
        })
    }
}

function PartsReorderListManager($scope, reorderList) {
    $scope.select2Options = {
        placeholder: "Select a stock location",
        'multiple': true,
        id: function (obj) {
            return obj.id;
        },
        'data': reorderList,
        formatResult: function (item) {
            return item.text;
        },
        formatSelection: function (item) {
            return item.text;
        },
        formatNoMatches: function (item) {
            if (item != '') {
                return 'No results match "' + item + '"';
            } else {
                return "";
            }
        }
    };
}


/** GPS Views ============================================================================== */
var GpsViewsResolver = {
    data: function ($q, $http, $stateParams, $state, PubNub, $rootScope, cacheBust,prefix, adminPrefix) {

        var deferred = $q.defer();
        $http.get(Routing.generate('gpsViews')).success(function (data, status, headersObj) {
            var headers = headersObj();
            SetRootScopeValues($rootScope, headers, PubNub, cacheBust,$http,prefix, adminPrefix);
            if (data.location && data.location == 'logout') {
                $rootScope.$broadcast('chat:unregister');
                $state.transitionTo('home');
            } else if (data.location && data.location.endsWith("/")) {
                //$state.transitionTo('home');
            } else {
                deferred.resolve(data);
            }
        });
        return deferred.promise;
    }
};

var GpsViewsCtrl = ['$scope', '$rootScope', '$http', 'data', '$state', '$timeout', 'toastBox', '$q', 'defaultPagingValue', function($scope, $rootScope, $http, data, $state,$timeout, toastBox, $q, defaultPagingValue) {

    $scope.users = data.users.map(function (u) {
        u['text'] = u.name + ' ' + u.surname;
        return u;
    });
    $scope.userGroups = data.userGroups.map(function (g) {
        g['text'] = g.description;
        return g;
    });
    $scope.userSkills = data.userSkills;
    $scope.gpsViews = [];
    $scope.totalGpsViews = 0;
    $scope.gpsViews = {
        records: [],
        totalRecords: 0
    };
    $scope.fetchingData = false;

    // Plugin configs
    $scope.userGroupsSelect2Options = {
        placeholder: "Select user groups",
        'multiple': true,
        id: function (obj) {
            return obj.id;
        },
        'data': $scope.userGroups,
        formatResult: function (item) {
            return item.text;
        },
        formatSelection: function (item) {
            return item.text;
        },
        formatNoMatches: function (item) {
            if (item != '') {
                return 'No results match "' + item + '"';
            } else {
                return "";
            }
        }
    };

    $scope.userSkillsSelect2Options = {
        placeholder: "Select user skills",
        'multiple': true,
        id: function (obj) {
            return obj.id;
        },
        'data': $scope.userSkills,
        formatResult: function (item) {
            return item.text;
        },
        formatSelection: function (item) {
            return item.text;
        },
        formatNoMatches: function (item) {
            if (item != '') {
                return 'No results match "' + item + '"';
            } else {
                return "";
            }
        }
    };

    $scope.usersSelect2Options = {
        placeholder: "Select some users",
        'multiple': true,
        id: function (obj) {
            return obj.id;
        },
        'data': $scope.users,
        formatResult: function (item) {
            return item.text;
        },
        formatSelection: function (item) {
            return item.text;
        },
        formatNoMatches: function (item) {
            if (item != '') {
                return 'No results match "' + item + '"';
            } else {
                return "";
            }
        }
    };
    // --
    $scope.currentPage = 1;
    $scope.limit = defaultPagingValue;
    $scope.searchText = '';
    $scope.action = null;
    $scope.showInfo = false;
    $scope.infoData = {
        action: 'ADD',
        gpsViewUrl: '#'
    };
    $scope.searchFocused = false;
    $scope.rightSection = 'LIST_SCREEN';

    $state.current.data = {
        limit: null,
        pageNum: null,
        addedId: -1,
        editedId:-1
    };
    JustAddedUpdatedChanges.call(this, $scope, 'fuelType', $state, defaultPagingValue, 'gpsViews');
    $scope.selectedId = null;
    $scope.shouldBeOpen = false;

    $scope.addGpsViewFormValue = {
        name: '',
        users: [],
        userGroups: [],
        userSkills: []
    };

    $scope.editGpsViewFormValue = {
        id: '',
        name: '',
        users: [],
        userGroups: [],
        userSkills: [],
        shortenedUrl: ''
    };

    $scope.isAddFormUsersTouched = false;
    $scope.isEditFormUsersTouched = false;

    $scope.isAddFormUserGroupsTouched = false;
    $scope.isEditFormUserGroupsTouched = false;

    $scope.isAddFormUserSkillsTouched = false;
    $scope.isEditFormUserSkillsTouched = false;

    $scope.hasNoGroupsOrSkills = !$scope.userGroups.length && !$scope.userSkills.length;

    $scope.dropdownValueSelected = function(name, $model, $formType){
        var variableName = name[0].toUpperCase() + name.slice(1);

        if($formType === 'ADD'){
            if(!$scope['isAddForm' + variableName + 'Touched']) {
                $model.$setPristine();
            }

            if(name === 'users' && $scope.hasNoGroupsOrSkills){
                if($scope.addGpsViewFormValue[name].length > 0) {
                    $model.$valid = true;
                    $model.$error.required = false;
                }
                else {
                    $model.$valid = false;
                    $model.$error.required = true;
                }
            }

            $scope['isAddForm' + variableName + 'Touched'] = true;

        } else if($formType === 'EDIT'){
            if(!$scope['isEditForm' + variableName + 'Touched']) {
                $model.$setPristine();
            }

            if(name === 'users' && $scope.hasNoGroupsOrSkills) {
                if ($scope.editGpsViewFormValue[name].length > 0) {
                    $model.$valid = true;
                    $model.$error.required = false;
                }
                else {
                    $model.$valid = false;
                    $model.$error.required = true;
                }
            }

            $scope['isEditForm' + variableName + 'Touched'] = true;

            $scope.adjustEditFormContainerHeight();
        }

    };

    $scope.isFormValid = function(type) {
        var formValue = type === 'ADD' ? $scope.addGpsViewFormValue : $scope.editGpsViewFormValue;

        return (!$scope.hasNoGroupsOrSkills && (formValue.users.length || formValue.userGroups.length || formValue.userSkills.length))
            || ($scope.hasNoGroupsOrSkills && formValue.users.length);

    };


    var listHttpRequest;
    $scope.fetchGpsViews = function (page, limit) {
        $scope.fetchingData = true;
        if(!page) page = $scope.currentPage;
        if(!limit) limit = $scope.limit;

        if(listHttpRequest) listHttpRequest.resolve();
        listHttpRequest = $q.defer();

        $http({
            url: Routing.generate('listGpsViews') + '?limit=' + limit + '&page=' + page + '&searchText=' + $scope.searchText,
            method: 'GET',
            timeout: $scope.httpRequest
        }).success(function (result) {
            $scope.currentPage = page;
            $scope.limit = limit;
            $scope.gpsViews = result.records;
            $scope.totalGpsViews = result.totalRecords;
            $scope.resetDeleteModalValues();
            listHttpRequest.resolve();

            if($state.current.data.addedId > 0 || $state.current.data.editedId > 0)
                $scope.triggerJustAddedUpdatedTags();
            $scope.fetchingData = false;

        }).error(function (err) {
            console.log(err);
            $scope.gpsViews = [];
            $scope.totalGpsViews = 0;
            $scope.resetDeleteModalValues();
            listHttpRequest.reject();
            $scope.fetchingData = false;
        });
    };

    var timer;
    $scope.searchGpsViews = function(searchText) {
        $scope.searchText = searchText;

        if(timer) clearTimeout(timer);

        timer = setTimeout(function(){
            $scope.fetchGpsViews();
        }, 700);
    };

    $scope.onSearchBlur  = function() {
        $scope.searchFocused = false;
    };

    $scope.editGpsView = function (gpsView) {
        $scope.editGpsViewFormValue.id = gpsView.id;
        $scope.editGpsViewFormValue.name = gpsView.name;
        $scope.editGpsViewFormValue.shortenedUrl = gpsView.shortenedUrl;

        $scope.editGpsViewFormValue.users = $scope.users.filter(function(u){return gpsView.users.indexOf(u.id) >=0; });
        $scope.editGpsViewFormValue.userGroups = $scope.userGroups.filter(function(g){return gpsView.userGroups.indexOf(g.id) >=0; });
        $scope.editGpsViewFormValue.userSkills = $scope.userSkills.filter(function(s){return gpsView.userSkills.indexOf(s.id) >=0; });

        $scope.rightSection = 'EDIT_SCREEN';

    };

    $scope.cancelEditGpsView = function () {
        $scope.editGpsViewFormValue.id = '';
        $scope.editGpsViewFormValue.name = '';
        $scope.editGpsViewFormValue.users = [];
        $scope.editGpsViewFormValue.userGroups = [];
        $scope.editGpsViewFormValue.userGroups = [];
        $scope.editGpsViewFormValue.shortenedUrl = '';

        $scope.rightSection = 'LIST_SCREEN';
    };

    $scope.saveGpsView = function (form, id){
        if(form.$valid){

            if(!id){
                $scope.action = 'SAVING';
                $scope.showInfo = false;

                var formValue = angular.copy($scope.addGpsViewFormValue);
                formValue.users = formValue.users.map(function(u) {return u.id;});
                formValue.userGroups = formValue.userGroups.map(function(g) {return g.id;});
                formValue.userSkills = formValue.userSkills.map(function(s) {return s.id;});

                $http({
                    url: Routing.generate('createGpsView'),
                    method: 'POST',
                    data: JSON.stringify(formValue)
                }).success(function (result) {

                    toastBox.show('Saved', 3000);
                    // debugger
                    $scope.action = 'SAVED';

                    // Reset form
                    $scope.addGpsViewFormValue = {
                        name: '',
                        users: [],
                        userGroups: [],
                        userSkills: []
                    };
                    form.$setPristine();
                    $scope.isAddFormUsersTouched = false;
                    $scope.isAddFormUserGroupsTouched = false;
                    $scope.isAddFormUserSkillsTouched = false;

                    $scope.infoData.action = 'ADD';
                    $scope.infoData.gpsViewUrl = result.shortenedUrl;
                    $scope.showInfo = true;

                    // Navigating to the page where the new record lies
                    $state.current.data.addedId = result.id;
                    $state.current.data.editedId = -1;
                    $state.$current.data = $state.current.data;
                    GetCurrentPage.call(this, $scope, $state, {count: $scope.totalGpsViews }, {id: result.id});
                    $scope.fetchGpsViews($scope.currentNewPage);

                }).error(function (err) {
                    console.log(err);
                    $scope.action = 'FAILED_TO_SAVE';
                })
            }
            else {
                $scope.action = 'UPDATING';
                $scope.showInfo = false;

                var formValue = angular.copy($scope.editGpsViewFormValue);
                delete formValue['shortenedUrl'];

                formValue.users = formValue.users.map(function(u) {return u.id;});
                formValue.userGroups = formValue.userGroups.map(function(g) {return g.id;});
                formValue.userSkills = formValue.userSkills.map(function(s) {return s.id;});

                $http({
                    url: Routing.generate('updateGpsView', {'id': formValue.id}),
                    method: 'PUT',
                    data: JSON.stringify(formValue)
                }).success(function (result) {

                    toastBox.show('Updated', 3000);
                    // debugger
                    $scope.action = 'UPDATED';

                    // Reset form
                    $scope.editGpsViewFormValue = {
                        id: '',
                        name: '',
                        users: [],
                        userGroups: [],
                        userSkills: [],
                        shortenedUrl: ''
                    };
                    form.$setPristine();
                    $scope.isEditFormUsersTouched = false;
                    $scope.isAddFormUserGroupsTouched = false;
                    $scope.isAddFormUserSkillsTouched = false;

                    $scope.infoData.action = 'EDIT';
                    $scope.infoData.gpsViewUrl = result.shortenedUrl;
                    $scope.showInfo = true;

                    // Navigating to the page where the new record lies

                    $state.current.data.addedId = -1;
                    $state.current.data.editedId = result.id;

                    $scope.rightSection = "LIST_SCREEN";

                }).error(function (err) {
                    console.log(err);
                    $scope.action = 'FAILED_TO_UPDATE';
                })
            }

        } else {
            console.log('form data is invalid');
        }
    };

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    };

    $scope.resetDeleteModalValues = function() {
        $scope.selectedId = null;
        $scope.shouldBeOpen = false;
    };

    $scope.$on('modal:close', function(event) {
        $scope.resetDeleteModalValues();
    });

    $scope.adjustViewContainerHeight = function(){
        var formHeight = $('#gps-view-edit-form-content .edit_titles').outerHeight();

        $('#gps-view-edit-form-content').height(formHeight);
        $('#gps-view-edit-container').height(formHeight + 38)
    }

    $scope.adjustEditFormContainerHeight = function(){
        var formHeight = $('#gps-view-edit-form-content .edit_titles').outerHeight();

        $('#gps-view-edit-form-content').height(formHeight);
        $('#gps-view-edit-container').height(formHeight + 38)
    }
}];

function EngineerShiftCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.engineerShiftForm['engineerShiftForm[engineerShiftName]'].$error.checkExists = true;
            } else {
                $scope.engineerShiftForm.$setPristine();
                $scope.description = "";
                $scope.engineerShiftName = "";
                $scope.$broadcast("SHIFT_ADDED", {
                    'id': data.id,
                    'description': data.description,
                    'engineerShiftName': data.engineerShiftName
                });
            }
            $scope.saving = false;
        });
    }
}

function EngineerShiftListCtrl($scope, $state, prefix, $http, shiftresolver, defaultPagingValue, warningModal) {
    $scope.titles = shiftresolver.titles;
    $scope.shifts = shiftresolver.titles;
    $scope.totalShifts = shiftresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'engineershift', $state, defaultPagingValue, 'shifts');

    $scope.$on("SHIFT_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, shiftresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.titles.push({
                'id': message.id,
                'engineerShiftName': message.engineerShiftName,
                'description': message.description,
                'justadded': true
            });
            shiftresolver.titles = $scope.titles;
            $scope.shifts = $scope.titles;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        shiftresolver.count = parseInt(shiftresolver.count) + 1
        $scope.totalShifts = shiftresolver.count;
    });

    $scope.editEngineerShift = function (id, shiftEngineerName, description) {
        $state.transitionTo('loggedin.engineer_shift.edit', {
            'id': id,
            'description': description,
            'shiftEngineerName': shiftEngineerName,
            'pageNum': $scope.currentPage,
            'limit': $scope.limit
        })
    }

    $scope.configureEngineerShift = function (id) {
        $state.transitionTo('loggedin.engineer_shift.configure', {
            'id': id,
        })
    }

    $scope.triggerDelete = function (id, description) {
        $http.get(prefix + '/is_active_engineer_shift?id=' + id).success(function (data) {
            if (data === "false") {
                $scope.cannotDelete = true;
                warningModal.show('You cannot delete shift as this is configured for an user ', 'Engineer shift', 'Engineer_shift_error');
            } else {
                $scope.cannotDelete = false;
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
                $scope.description = description;
            }
        });
    }

    $scope.fetchEngineerShift = function (pageNum) {
        $http.get(prefix + '/listEngineerShift?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.titles = data.titles;
            $scope.shifts = data.titles;
            $scope.totalShifts = data.count;
            shiftresolver.titles = data.titles;
            shiftresolver.totalShifts = data.count;
            shiftresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function EngineerShiftEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedDetails = $state.params.description;
    $scope.selectedEngineerShift = $state.params.shiftEngineerName;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.engineer_shift");
    }

    $scope.updateEngineerShift = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/editEngineerShift', "engineershift[id]=" + this.selectedId + "&engineershift[description]=" + encodeURIComponent(this.selectedDetails)+ "&engineershift[shiftEngineerName]=" + encodeURIComponent(this.selectedEngineerShift)).
        success(function (data, status) {
            if (status == 200) {
                $scope.updating = false;
                if (data.error && data.error == 'Exists') {
                    $scope.current.editEngineerShiftForm['selectedEngineerShift'].$error.checkExists = true;
                } else {
                    $state.$current.parent.self.data.pageNum = $scope.pageNum;
                    $state.$current.parent.self.data.editedId = $scope.selectedId;
                    $state.$current.parent.self.data.limit = $scope.limit;
                    $state.transitionTo("loggedin.engineer_shift");
                }
            }
        });
    }
}

var PaymentMessageSettingsCtrl = function ($scope, $rootScope, $http, getIdData, $state, $q, $timeout, toastBox, datasets, prefix) {

    $scope.message = getIdData.message;
    $scope.updateMessage = function(message){
        $scope.message = message;
    };
    $scope.onFormSubmit = function (form) {

        var data = angular.copy($scope.message);
        $scope.action = 'SAVING';
        $http({
            url: Routing.generate('setPaymentAuthorizationMessage'),
            method: 'POST',
            data: JSON.stringify(data)
        }).success(function (result) {
            toastBox.show('Saved', 3000);
            $scope.data = angular.copy(result);
            $scope.action = 'SAVED';
            form.$setPristine();

        }).error(function (err) {
            console.log(err);
            $scope.action = 'FAILED_TO_SAVE';

        });
    };
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
};

function EstimateDisclaimerMessageCtrl($scope, $http, $state, datasets, $rootScope, prefix) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}



function PurchaseOderNumberCtrl($scope, $http, $state, datasets, $rootScope) {
    $scope.data = datasets;
    var setFormName = "purchaseOrderNumberForm";
    FirstNumberCtrl.call(this, $scope, $http, $state, $rootScope);
    ErrorCtrl.call(this, $scope, $http, $state, datasets, $rootScope, setFormName);
}

function ChatSettingsCtrl($scope, $http, prefix, $state, usersresolver,userGroupsresolver,chatresolver,toastBox) {
    $scope.chatEnable = false;
    if( chatresolver.isEnable ) {
        $scope.chatEnable = true;
        
        $scope.userpreferences = [];
        $scope.chatSettings = {
            "notifications": {
                "desktop": "",
                "desktop_sound": "",
                "push": "",
                "push_status": "online",
                "comments": "",
                "mention_keys": "",
                "first_name": 0,
                "channel": 0,
            },
            "preferences": {
                "channel_switcher": "",
                "channel": "when_required",
                "user": {
                    "person_created_job": 1,
                    "all_diary": 0,
                    "specific_users": 0,
                    "job_user_group": 0,
                    "other_user_group": 0,
                    "defaultUsers" : 0,
                    "defaultGroups": 0,
                },
                "timeformat": 12
            }
        };
        
        $http.get(prefix + '/getUserChatPreferences').success(function (data) {
            $scope.notification = data.userpreferences.notification.notify_props;
            
            $scope.chatSettings.notifications.desktop = $scope.notification.desktop;

            $scope.chatSettings.notifications.desktop_sound = $scope.notification.desktop_sound;
            $scope.chatSettings.notifications.push = $scope.notification.push;
            if( $scope.notification.push_status != "undefined") {
                $scope.chatSettings.notifications.push_status = $scope.notification.push_status;
            } else {
                $scope.chatSettings.notifications.push_status = "online";
            }
            $scope.chatSettings.notifications.comments = $scope.notification.comments;
            $scope.preferences = data.userpreferences.preferences;
            if( $scope.preferences.channel_switcher != 'undefined' ) {
                $scope.chatSettings.preferences.channel_switcher = $scope.preferences.channel_switcher;
            } else {
                $scope.chatSettings.preferences.channel_switcher = true;
            }
        
            $scope.chatSettings.notifications.mention_keys = $scope.notification.mention_keys;
            if( $scope.notification.mention_keys != 'undefined' && $scope.notification.mention_keys != '') {
                $scope.chatSettings.notifications.mention_keys_checkbox = true;
            } else {
                $scope.chatSettings.notifications.mention_keys_checkbox = false;
            }
            if( $scope.notification.first_name) {
                $scope.chatSettings.notifications.first_name = "true";
            }
            if( $scope.notification.channel ) {
                $scope.chatSettings.notifications.channel = "true";
            }
            $scope.enableJobEstimatechannel = false;
            if( data.userpreferences.features ) {
                if( data.userpreferences.features[1]) {
                    $scope.enableJobEstimatechannel = true;
                }
            }

            $scope.chatSettings.preferences.channel = $scope.preferences.channel;
            $scope.chatSettings.preferences.timeformat = $scope.preferences.timeformat;
            $scope.chatSettings.preferences.user.specific_users = $scope.preferences.user.specific_users;
            $scope.chatSettings.preferences.user.defaultUsers = $scope.preferences.user.defaultUsers;
            $scope.chatSettings.preferences.user.person_created_job = $scope.preferences.user.person_created_job;
            $scope.chatSettings.preferences.user.other_user_group = $scope.preferences.user.other_user_group;
            $scope.chatSettings.preferences.user.defaultGroups = $scope.preferences.user.defaultGroups;
            $scope.chatSettings.preferences.user.all_diary = $scope.preferences.user.all_diary;
            $scope.chatSettings.preferences.user.job_user_group = $scope.preferences.user.job_user_group;
        });
        $scope.saving = false;
        $scope.savePreferences = function() {
            $scope.saving = true;
            $http({
                url: prefix + '/savePreferences' ,
                method: 'POST',
                data: JSON.stringify($scope.chatSettings),

            }).success(function (data, status) {
                    if (status == 200) {
                        $scope.saving = false;
                        toastBox.show('Settings saved successfully', 2000);
                    }
            });
        }

        $scope.users = usersresolver.users.map(function (u) {
            u['text'] = u.name + ' ';
            return u;
        });


        $scope.usersSelect2Options = {
            placeholder: "Select some users",
            multiple: true,
            minimumInputLength: 2,
            id: function (obj) {
                return obj.userId;
            },
            ajax: {
                url: prefix + '/searchUser',
                data: function (term, page) {
                    return { q: term };
                },
                results: function (data, page) {
                    return { results: data };
                }
            },
            formatResult: function (rec) {
                
                
                return rec.username;
            },
            formatSelection: function (rec) {
                return rec.username
            },
            formatNoMatches: function (item) {
                if (item != '') {
                    return 'No results match "' + item + '"';
                } else {
                    return "Search for users";
                }
            }
        }
        /*$scope.usersSelect2Options = {
            placeholder: "Select some users",
            'multiple': true,
            id: function (obj) {
                return obj.id;
            },
            'data': $scope.users,
            formatResult: function (item) {
                return item.text;
            },
            formatSelection: function (item) {
                return item.text;
            },
            formatNoMatches: function (item) {
                if (item != '') {
                    return 'No results match "' + item + '"';
                } else {
                    return "";
                }
            }
        };
*/
        $scope.userGroups = userGroupsresolver.userGroups.map(function (g) {
            g['text'] = g.description;
            return g;
        });
        // Plugin configs
        $scope.userGroupsSelect2Options = {
            placeholder: "Select user groups",
            'multiple': true,
            id: function (obj) {
                return obj.id;
            },
            'data': $scope.userGroups,
            formatResult: function (item) {
                return item.text;
            },
            formatSelection: function (item) {
                return item.text;
            },
            formatNoMatches: function (item) {
                if (item != '') {
                    return 'No results match "' + item + '"';
                } else {
                    return "";
                }
            }
        };
    }
}

function AuthorizationCardPaymentCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}
function AuthorizationCardPaymentListCtrl($scope) {

    $scope.authorizationcardpaymentSms = false;
    $scope.authorizationcardpaymentEmail = true;

    //Show the SMS Screen
    $scope.smsTo = function () {
        $scope.authorizationcardpaymentSms = true;
        $scope.authorizationcardpaymentEmail = false;
        tinyMCE.get("SmsId").focus();
    }
    //Show the Email Screen
    $scope.emailTo = function () {
        $scope.authorizationcardpaymentSms = false;
        $scope.authorizationcardpaymentEmail = true;
        tinyMCE.get("EmailId").focus();
    }

    $scope.controlscope = function () {
        tinyMCE.get("EmailId").focus();
    }
}


