/*==========================================================================================
	Companion service to web/javascripts/commusoft/controllers/diary_right_bar.js
==========================================================================================*/

csmodule.service('diaryRightBarHelper', ['$http', '$q', '$timeout', 'prefix', 'canLoad', function($http, $q, $timeout, prefix, canLoad) {
	/*==========================================================================================
		Initial Variables
	==========================================================================================*/
	self = this;
  this.nearby_events_path = prefix + '/diary/search_nearby_events';
  this.slide_up_animation_duration = 400;

    var canceler = null;
    this.searchForNearbyEvents = function searchForNearbyEvents(param, engineers) {
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var nearby_events = $http.get(this.nearby_events_path+'?searchText=' + encodeURIComponent(param) + '&engineers=' + engineers, {timeout: canceler.promise})
            .then(function(response) {
                return response.data.events;
            });
        return nearby_events;
    }

	/*==========================================================================================
		Show outstanding jobs
	==========================================================================================*/
	this.showOutstandingJobs = function showOutstandingJobs(controller_scope) {
    var diary_bar_sections = document.querySelector('.diary-bar-sections');
    diary_bar_sections.scrollTop = 0;

		$timeout(function() {
			var scrollable_wrapper = document.querySelector('#scrollable-outstanding-jobs');
			controller_scope.outstanding_jobs_open = true;
			scrollable_wrapper.scrollTop = 0;
		}, this.slide_up_animation_duration);
	}

	/*==========================================================================================
		Close outstanding jobs
	==========================================================================================*/
	this.hideOutstandingJobs = function hideOutstandingJobs(controller_scope) {
		$timeout(function() {
			controller_scope.outstanding_jobs_open = false;
			controller_scope.outstanding_jobs_closing = false;
			self.resetOutstandingFilters(controller_scope);
		}, this.slide_up_animation_duration + 100);
	}

  /*==========================================================================================
   Show nearby events
   ==========================================================================================*/
  this.showNearbyEvents = function showNearbyEvents(controller_scope) {
    var diary_bar_sections = document.querySelector('.diary-bar-sections');
    diary_bar_sections.scrollTop = 0;

    $timeout(function() {
      var scrollable_wrapper = document.querySelector('#scrollable-nearby-events');
      controller_scope.nearby_events_open = true;
      controller_scope.search_nearby_events_focussed = true;
      self.focusSearchInput('#search-nearby-events-input');
      scrollable_wrapper.scrollTop = 0;
    }, this.slide_up_animation_duration);
  }

  /*==========================================================================================
   Close nearby events
   ==========================================================================================*/
  this.hideNearbyEvents = function hideNearbyEvents(controller_scope) {
    $timeout(function() {
      controller_scope.nearby_events_open = false;
      controller_scope.nearby_events_closing = false;
      self.resetNearbyEvents(controller_scope);
    }, this.slide_up_animation_duration + 100);
  }

	/*==========================================================================================
		When outstanding jobs is closed, reset the job filters
	==========================================================================================*/
	this.resetOutstandingFilters = function resetOutstandingFilters(controller_scope) {
		controller_scope.no_access = true;
		controller_scope.parts_ready = true;
		controller_scope.without_events = true;
		controller_scope.with_todos = true;
		controller_scope.search_outstanding_jobs = false;
		controller_scope.no_search_results = false;
		controller_scope.searchOutstandingJobParam = '';
	}

  this.resetNearbyEvents = function resetNearbyEvents(controller_scope) {
    controller_scope.nearby_events = [];
    controller_scope.searchNearbyEventParam = '';
  }

	/*==========================================================================================
		Focus search input
	==========================================================================================*/
	this.focusSearchInput = function focusSearchInput(selector) {
		$timeout(function() {
			var search_input = document.querySelector(selector);
			search_input.focus();
		}, 100);
	}

}]);