// Generated by CoffeeScript 1.4.0
(function () {
    var e, t, n;
    jQuery(function () {
        // $(".help_button button").on("click", function (e) {
        //     return $(".help_sidebar").toggleClass("open")
        // });
        $("[data-chat^=toggle]").on("click", function (e) {
            var t, n;
            e.preventDefault();
            t = $(e.target);
            n = t.parents("div.chat_window");
            return n.toggleClass("closed")
        });
        $("[data-chat^=close]").on("click", function (e) {
            var t;
            e.preventDefault();
            t = $(e.target);
            t.parents("div.chat_window").toggleClass("closed");
        });
        $("#new_message").on("show", function () {
            return $(".dropdown.open").toggleClass("open")
        });
        $("[data-toggle^=chat]").on("click", function (e) {});
        $("[rel=popover]").popover({
            html: !0
        });
        $('input[type="search"]').on("keyup", function () {
            if ($(this).val()) return $(".search_box .ss-delete").fadeIn()
        });
        $(".search_box .ss-delete").on("click", function (e) {
            e.preventDefault();
            $(this).prev().val("");
            return $(this).fadeOut()
        });
        $("li.user").on("click", function (e) {
            $(this).toggleClass("open");
            $(this).find(".menu").toggleClass("closed");
            return e.stopPropagation()
        });
        $(".btn-quick-links").on("click", function (e) {
            e.preventDefault();
            return $(this).next().toggleClass("open")
        });
        $(document).click(function () {
            if ($(".menu").hasClass("open")) {
                $(".menu").removeClass("closed");
                return $("li.user").removeClass("open")
            }
        });
        $(".auto_complete").chosen({
            //no_results_text: "Would you like to add",
            //no_results_callback: function (e, t) {
//                e.css("cursor", "pointer");
//                return e.click(function () {
//                    $(".auto_complete").append($("<option selected></option>").attr("value", t).text(t));
//                    $(".auto_complete").trigger("liszt:updated");
//                    return $(".auto_complete").focus()
//                })
//            },
            max_selected_options: $(".auto_complete").data("max-select")
        });
        $(".chzn-drop").css('width','50%');
        $(".show-more").on("click", function (e) {
            e.preventDefault();
            $(this).siblings().first().toggleClass("show");
            return $(".show-more").children().toggleClass("up")
        });
        /**
         * $('[data-toggle="modal"]').on("click", function (e) {
         *             var t;
         *             e.preventDefault();
         *             t = $(this).attr("href");
         *             return t.indexOf("#") === 0 ? $(t).modal("open") : $.get(t, function (e) {
         *                 return $('<div class="modal hide fade">' + e + "</div>").modal()
         *             }).success(function () {
         *                 return $("input:text:visible:first").focus()
         *             })
         *         });
         */
        /*return $(".postcodes ul li a").on("click", function (e) {
            var t;
            e.preventDefault();
            t = $(this);
            $("#address_line_1").val(t.data("address-line-1"));
            $("#address_line_2").val(t.data("address-line-2"));
            $("#address_line_3").val(t.data("address-line-3"));
            $("#town").val(t.data("town"));
            $("#county").val(t.data("county"));
            return $(".modal").modal("hide")
        })*/
    });
    $("select[name=customer]").change(function () {
        if ($("select[name=customer] option:selected").val() === "add-new-customer") return $.get("/customers/new_customer_modal", function (e) {
                return $("body").append($('<div id="add-new-customer" class="modal hide fade">' + e + "</div>"))
            }).success(function () {
                $("input:text:visible:first").focus();
                return $("#add-new-customer").modal()
            })
    });
    $(".controls select, .controls input").fieldHelper();
    $("[data-job-dropdown=annual_service]").change(function () {
        return $("#annual_service").slideToggle()
    });
    $("select[name=annual_service]").change(function () {
        if ($(this).val() !== 0) return $(".another_service").slideDown()
    });
    $(".sticky-header").waypoint(function (e, t) {
        t === "down" && $(".customers").css("margin-top", "" + $(".sticky-header").outerHeight() + "px");
        t === "up" && $(".customers").css("margin-top", "20px");
        return $(this).toggleClass("sticky", t === "down")
    }, {
        offset: function () {
            return "0"
        }
    });
    t = this;
    t.steps;
    $(".task-type").on("change", function () {
        var e;
        e = $(this).val();
        t.steps = new Steps(".steps", e);
        return steps.currentStep.slideDown()
    });
    $(".steps :input").on("change", function () {
        return steps.nextStep()
    });
    $("#add-task").on("click", function (t) {
        var r;
        t.preventDefault();
        r = $(".tasks :input").serialize();
        n(r);
        return e()
    });
    n = function (e) {
        var t;
        t = $('<div class="task">').html("" + ($(".checklist li").size() + 1) + ". " + $(".task-type").val() + "<a href='#' class='edit'> edit </a> <a href='#' class='remove ss-icon'>delete</a> ");
        t.data("input_attributes", e);
        return $("<li>").appendTo(".checklist").html(t)
    };
    e = function () {
        return $(".tasks :input").not("#add-task").each(function () {
            if (this.type === "text" || this.type === "textarea") this.value = "";
            else if (this.type === "radio" || this.type === "checkbox") this.checked = !1;
            else if (this.type === "select-one" || this.type === "select-multiple") {
                this.value = "";
                $(this).trigger("liszt:updated")
            }
            return $(".question").slideUp()
        })
    };
    $(".job-tasks .edit").on("click", function (e) {
        var t, n;
        e.preventDefault();
        n = $(this).parent().data("task_type");
        t = $(this).parent().data("input_attributes");
        new Steps(".step", n);
        $(".question").slideDown();
        $(".tasks").unserializeForm(t, {
            "override-values": !0
        });
        return $(".tasks :input").trigger("liszt:updated")
    });
    $(".job-tasks .remove").on("click", function (e) {
        return $(this).parent().parent().remove()
    });
    this.Steps = function () {
        function e(e, t) {
            var n = this;
            this.steps = $(e);
            $.ajax({
                url: "/jobs/tasks/installation",
                async: !1,
                success: function (e) {
                    $("#task_container").html(e);
                    n.setStep($(".steps .question").first());
                    $(".auto_complete").chosen();
                    $(".datepicker").datepicker();
                    return $("#task_container").data("task_type", t)
                }
            })
        }
        e.prototype.setStep = function (e) {
            return this.currentStep = e
        };
        e.prototype.currentStep = function () {
            return this.currentStep
        };
        e.prototype.nextStep = function () {
            var e;
            e = this.currentStep.data("next-step");
            if (e) {
                this.currentStep = $("[data-step=" + e + "]");
                return this.currentStep.slideDown()
            }
        };
        return e
    }();
    $("#search-input").focus(function () {
        $(this).parent().addClass("widen");
        $("#main-nav").hide();
        return $(".help_button").hide()
    });
    $("#search-input").blur(function () {
        var e;
        $(this).parent().removeClass("widen");
        e = setTimeout(function () {
            $("#main-nav").show();
            return $(".help_button").show()
        }, 700);
        return $(window).blur(function () {
            return window.clearTimeout(e)
        })
    });
    $(function () {
        return $("[rel='tooltip']").tooltip()
    });
    $("[rel='tooltip']").on("click", function () {
        return $(this).tooltip("hide")
    });
    jQuery(function () {
        $(".nav_bar li").click(function () {
            $(".nav_bar li.active").removeClass("active");
            return $(this).addClass("active")
        });
        $("#settings-caret").click(function () {
            return $(".ss-settings").trigger("click")
        });
        return $("#user-caret").click(function () {
            return $(".user").trigger("click")
        })
    });
    jQuery(function () {
        var e;
        e = 0;
        return $(".password-input").blur(function () {
            //$("#"+$(this).data("validate")).show();e++;if(e>5)return $("#password-error").show();
        })
    });
    $(".goToNextForm").click(function (e) {
        e.preventDefault();
        $(this).parents(".setup_wrapper").hide();
        $("#" + $(this).data("next-page")).show();
        return $("span." + $(this).data("next-page")).empty().addClass("ss-check")
    });
    if (navigator.userAgent.indexOf("Mac") > 0) {
        $(".amount").addClass("mac-align");
        $(".contract-duration").addClass("mac-align")
    }
    //$("#successful-login").click(function(e){e.preventDefault();return window.location.replace("/contract_summary")});
    jQuery(function () {
        $(".import_options li").click(function (e) {
            e.preventDefault();
            $(".import_options li.active").removeClass("active");
            $(this).addClass("active");
            $(".import_panel").hide();
            $("#" + $(this).data("import-option")).show();
        });
        $("#import-csv-button").click(function () {
            $("#form_submitCsv").click();
        });
        $(".edit_column_link").click(function (e) {
            var t, n, r;
            e.preventDefault();
            n = $(this);
            r = n.parent();
            r.hide();
            t = r.parent().find(".edit_column_actions");
            t.show();
            t.find(".back_to_column_options").show();
            return t.find(".delete_column").show()
        });
        $(".back_to_column_options").click(function (e) {
            var t, n, r, i;
            e.preventDefault();
            t = $(this);
            n = t.parents(".edit_column_actions");
            n.hide();
            i = t.parents(".table_header");
            r = i.find(".edit_column_options");
            return r.show()
        });
        $(".delete_column_link").click(function (e) {
            var t, n, r, i, s, o;
            e.preventDefault();
            t = $(this);
            o = t.parents(".table_header");
            t.hide();
            n = o.find(".deleted_message");
            n.show();
            r = t.parent().find(".unamed");
            r.hide();
            i = t.parent().find(".edit_column_link");
            i.hide();
            s = t.parent().find(".readd_column_link");
            return s.show()
        });
        $(".delete_column").click(function (e) {
            var t, n;
            e.preventDefault();
            t = $(this);
            t.hide();
            n = t.parents(".table_header");
            n.find(".edit_column_options").show();
            n.find(".edit_column_options a, .edit_column_options span").hide();
            n.find(".edit_column_options .readd_column_link").show();
            n.find(".edit_column_actions").hide();
            return n.find(".deleted_message").show()
        });
        $(".readd_column_link").click(function (e) {
            var t, n, r;
            e.preventDefault();
            n = $(this).parent();
            n.hide();
            r = n.parent();
            r.find(".deleted_message").hide();
            t = r.find(".edit_column_actions");
            t.show();
            t.find(".back_to_column_options").hide();
            return t.find(".delete_column").hide()
        });
        return $(".save_column").click(function (e) {
            var t, n, r;
            e.preventDefault();
            r = $(this);
            t = r.parents(".edit_column_actions");
            t.hide();
            n = t.parent().find(".edit_column_options");
            n.show();
            n.find(".unamed").show();
            n.find(".edit_column_link").show();
            n.find(".delete_column_link").show();
            return n.find(".readd_column_link").hide()
        })
    });
    $("input[name=bank_details]").click(function () {
        return $("#yes_bank_details:checked").length > 0 ? $(".bank_details_form").show() : $(".bank_details_form").hide()
    });
    $(".email_editor").wysihtml5({
        "font-styles": !0,
        emphasis: !0,
        lists: !1,
        html: !1,
        link: !0,
        image: !0,
        color: !1
    });
    $(".edit_email_link").click(function () {
        return $(".edit_email_inputs").show()
    });
    $("#save-email").click(function () {
        return !1
    });
    $("#user_login_with_pc_mobile").click(function () {
        return $("#user_login_with_pc_mobile").is(":checked") ? $(".user-access-wrapper").show() : $(".user-access-wrapper").hide()
    });
   /* jQuery(function () {
        var e;
        e = 0;
        $(".validate-control-input").blur(function () {
            if (!$(this).val()) {
                $("#" + $(this).data("validate")).show();
                return e++
            }
        });
        return $("#client-setup-users").submit(function () {
            var e, t, n, r;
            e = $("#new-users-name").val();
            t = $(".current_users_table table tbody");
            if (e.length < 2) $("#new-user-firstname").show();
            else {
                $("#new-user-firstname").hide();
                $("tr.just-added").removeClass("just-added");
                $("tr td span").remove("span");
                t.prepend($('<tr class="just-added"><td>' + e + "<span>Just Added</span></td></tr>"));
                $(".just-added td").effect("highlight", {
                    color: "rgb(225, 248, 212)"
                }, 5e3);
                n = parseInt($(".number-of-users-added").html());
                r = parseInt($(".number-of-users-left").html());
                $(".number-of-users-added").text(n + 1);
                $(".number-of-users-left").text(r - 1);
                r === 0 && $("#trigger-account-number").click();
                r === 1 && $("#save-user-button").val("Continue to Next Step")
            }
            return !1
        })
    });*/
    $("input[name=account_number]").click(function () {
        $(".custom_default_record_number").hide();
        return $("input[name=account_number]:checked").length > 0 ? $("#" + $(this).data("record-number")).show() : $(".custom_default_record_number").hide()
    });

    jQuery(function () {
        var e;
        e = 0;
        return $(".thumbnail_image").click(function (t) {
            t.preventDefault();
            e++;
            $(".thumbnail_image.active").removeClass("active");
            $(this).addClass("active");
            $(".invoice_template").removeClass("show_template");
            $("#template_preview_header").addClass("show_header");
            return e > 1 ? $("#" + $(this).data("template-option")).delay(850).queue(function (e) {
                $(this).addClass("show_template");
                return e()
            }) : $("#" + $(this).data("template-option")).addClass("show_template")
        })
    });
    $("#change-profile-image-btn").click(function () {
        return $("#default-file-input").trigger("click")
    });
    jQuery(function () {
        return $(".trigger-loading").click(function (e) {
            e.preventDefault();
            return $(".page_loading_wrapper").toggleClass("show_loading_message")
        })
    });
    $(function () {
        $(".ui-sortable").sortable();
        return $(".ui-sortable").disableSelection()
    });
    $(".add_record_button").click(function () {
        var e, t, n;
        n = $("." + $(this).parent().data("settings-input"));
        t = n.parents(".customise_settings_wrapper").find(".add_record_input").val();
        e = n.parents(".customise_settings_wrapper").find(".customise_settings_error");
        if (t < 2) return e.show();
        n.find("li:first").clone().prependTo(n);
        n.find("li:first span").html(t);
        return e.hide()
    });
    /*$(".delete_link").on("click", function (e) {
        var t;
        e.preventDefault();
        t = $(this);
        return $("#delete_record").on("click", function () {
            var e;
            e = $("#confirm_delete_input").val().toLowerCase();
            if (e === "delete") {
                $(this).parents(".modal").hide();
                t.parents("li").remove();
                return $("#" + $(this).data("validate")).hide()
            }
            $(this).parents(".modal").show();
            return $("#" + $(this).data("validate")).show()
        })
    }); */
    jQuery(function () {
        $(".search_box .PLACEHOLDER-INPUT").attr("placeholder", "Search");
        return $("input[placeholder],textarea[placeholder]").placeholder()
    });
    jQuery(function () {
        $(".advanced_link").click(function (e) {
            e.preventDefault();
            return console.log("show-advanced")
        });
        /*$(".edit_title_link").click(function (e) {
            var t;
            e.preventDefault();
            t = $(this).parents(".system_settings_sortable");
            t.addClass("slide_left");
            $("#" + t.data("settings")).addClass("show_edit_view");
            return t.nextAll(".pagination").fadeOut()
        });
        $(".hide_edit_view_wrapper").click(function (e) {
            var t, n;
            e.preventDefault();
            t = $(this).parents(".edit_view_wrapper");
            t.removeClass("show_edit_view");
            n = t.attr("id");
            $("[data-settings= " + n + "]").removeClass("slide_left");
            return t.nextAll(".pagination").fadeIn()
        }); */
        return $(".inline-advanced-panel .btn").click(function (e) {
            e.preventDefault();
            return $(".additional_settings_inputs").toggleClass("show")
        })
    })

    jQuery(function () {
        return $(".backup-option-wrapper").click(function (e) {
            e.preventDefault();
            $(".backup-option-wrapper").removeClass("active");
            return $(this).addClass("active")
        })
    });
    $("input[name=merge]").click(function () {
        return $("#yes_merge:checked").length > 0 ? $(".merge-with").addClass("show") : $(".merge-with").removeClass("show")
    });
    $(".confirm-merge").click(function () {
        $(".hide_edit_view_wrapper").trigger("click");
        $(".merge-with").removeClass("show");
        return $("#no_merge").prop("checked", !0)
    });
    /*$(".inline-edit").on("click", function () {
        var e;
        $(".customer_creditor_days table tr").removeClass("active");
        e = $(this);
        return e.parents("tr").addClass("active")
    });
    $(".inline-cancel").on("click", function () {
        var e;
        e = $(this);
        return e.parents("tr").removeClass("active")
    });
    $(".inline-save").on("click", function () {
        var e, t, n, r;
        e = $(this);
        n = e.parent("td").find(".current-creditor-day");
        r = n.html();
        t = e.parent("td").find(".inline-input").val();
        t.length > 0 && n.html(t);
        return e.parents("tr").removeClass("active")
    });*/
    $(function () {
        return $(".filter_links select").on("change", function () {
            var e, t;
            $(".edit-group-of-customers").hide();
            $(".edit-group-of-customers-active").show();
            t = $(".filter-select-type").val();
            e = $(".filter-select-days").val();
            if (t === "title" && e === "title") {
                $(".edit-group-of-customers").show();
                return $(".edit-group-of-customers-active").hide()
            }
        })
    });
    $(function () {
        $(".new-nominal-link").on("click", function (e) {
            e.preventDefault();
            $(".default-nominal-inputs").hide();
            $(".new-nominal-inputs").show();
            return $(".nominal-input").focus()
        });
        $(".cancel-nominal-link").on("click", function (e) {
            e.preventDefault();
            $(".new-nominal-inputs").hide();
            $("#add_new_error").hide();
            return $(".default-nominal-inputs").show()
        });
        return $(".save-nominal").on("click", function (e) {
            var t;
            e.preventDefault();
            t = $(".nominal-input").val();
            if (t.length >= 2) {
                $("#add_new_error").hide();
                $(".nominal-select").append("<option>" + t + "</option>");
                $(".new-nominal-inputs").hide();
                $(".default-nominal-inputs").show();
                return $(".nominal-input").val("")
            }
            $("#add_new_error").show();
            return $(".nominal-input").val("")
        })
    });
    $(".filter-results a").on('click', function () {
        $(".filter-results a").removeClass("active");
        return $(this).addClass("active")
    });
    $(function () {
        return $(".filter-results a").on('click', function (e) {
            var t, n, r, i;
            e.preventDefault();
            t = $("." + $(this).data("filter"));
            n = parseInt(t.offset().top);
            r = $(".results-table-wrapper").offset().top;
            i = $("#top-table");
            return t.is(i) ? $(".results-wrapper").animate({
                scrollTop: n - r
            }, 500) : $(".results-wrapper").animate({
                scrollTop: n - (r - 12)
            }, 500)
        })
    });
    $("#warning_modal").on("shown", function () {
        var e, t;
        setTimeout(function () {
            return window.location.replace("logout");
            $("#warning_modal").modal('hide');
        }, 1e4);
        e = 10;
        return t = setInterval(function () {
            e === 1 && clearInterval(t);
            e--;
            console.log(e);
            return $("#redirect-countdown").html(e)
        }, 1e3)
    });
}).call(this);

(function() {
    var handleOS;

    handleOS = (function() {
        var body, checkBrowser, handleWindowsMachine, handleWindowsSafari, os, vendor;
        os = navigator.appVersion;
        vendor = navigator.vendor;
        body = document.getElementsByTagName('body')[0];
        handleWindowsMachine = function() {
            return body.className = body.className + ' windows-os';
        };
        handleWindowsSafari = function() {
            return body.className = body.className + ' windows-safari';
        };
        checkBrowser = function() {
            if (vendor.indexOf('Apple') > -1) {
                return handleWindowsSafari();
            }
        };
        if (os.indexOf('Win') > -1) {
            handleWindowsMachine();
            return checkBrowser();
        }
    })();

}).call(this);

(function ( $ ) {
  $.fn.textWidth = function() {
    var html_org = $(this).html();
    var html_calc = '<span>' + html_org + '</span>';
    $(this).html(html_calc);
    var width = $(this).find('span:first').width();
    $(this).html(html_org);
    return width;
  };
}( jQuery ));
