function ShowPublicHolidayCalenderCtrl($scope, prefix, $http, publicHolidayCalendarObj, canLoad, $q, $state, $timeout, $rootScope, $compile, uiCalendarConfig, csPopUpPosition, $modal, warningModal, $window, $translate, toastBox, filterSkillsOnDiary, $csMapper) {
    var today = new Date();
    $scope.defaultDate = moment(publicHolidayCalendarObj.defaultDate);
    $scope.currentView = "month";

    $scope.resourceId = '';
    $scope.currentEvents = [];
    $scope.eventToUndo = {};
    $scope.viewStart = moment(today);
    $scope.viewEnd = moment(today);
    $scope.events = [];
    $scope.eventSources = [$scope.events];
    $scope.timeDiffArray = [];
    $scope.isFromFilter = false;

    //This is to handle the event loading
    $scope.prevNextClicked = false;
    $scope.nextEvents = [];
    $scope.prevEvents = [];
    $scope.show_event_pop_up = false;
    $scope.eventDragStarted = false;



    // handle the height of the calendar
    function handleFullCalendarHeight() {
        return (window.innerHeight - 160);
    }

    function resizeCalendar() {
        $('#cs-calendar').fullCalendar('option', 'contentHeight', handleFullCalendarHeight());
    }

    $(window).on('resize', resizeCalendar);

    var diaryViews = ['month'];


    // Choosing default view
    if(diaryViews.indexOf($scope.currentView) < 0) $scope.currentView = diaryViews[0];

    $scope.uiCalendar = {
        calendar: {
            schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
            header: {
                left: 'title',
                center: '',
                right: ''
            },
            contentHeight: handleFullCalendarHeight(),
            buttonText: {
                agendaResource: "Public Holiday Calendar"
            },
            displayEventEnd: true,
            forceEventDuration: true,
            eventLimit:true,
            defaultDate: $scope.defaultDate,
            handleWindowResize: true,
            selectable: $scope.selectable,
            selectHelper: true,
            unselectCancel: ['div.in-view', '.confirmation-showing'],
            defaultView: $scope.currentView,
            fixedWeekCount: false,
            diaryTimeFormat: $scope.diaryTimeFormat,
            viewRender: function(view, element) {
                $scope.currentView = view.name;
                $scope.viewStart = moment(view.start.format('YYYY-MM-DD'));
                $scope.viewEnd = moment(view.end.format('YYYY-MM-DD'));
                $scope.startDate = view.intervalStart;
                if($scope.prevNextClicked && $scope.prevNextClicked != 'today' && $scope.nextEvents !== false && $scope.prevEvents !== false) {
                    $scope.loadNextOrPrevEvents(view, $scope.prevNextClicked);
                    $scope.prevNextClicked = false;
                } else {
                    $scope.loadAllEvents();
                }
            },
            droppable: false,
            eventRender: function (event, element, view) {
                var contentObj = csDiaryHelpers.getEventHtml(view, event);
                var content = contentObj.html;
                element.html(content);
            }
        }
    };


    $scope.getPopUpData = function getPopUpData($event, eventId) {
        $scope.pop_up_data =  _.where($scope.events, { id: eventId })[0];
        csPopUpPosition.init($event);
    }


    var canceler = null;
    $scope.loadingAllEvents = false; // To restrict the next and prev click when loading all events
    $scope.loadAllEvents = function() {
        var name = $scope.currentView;

        var start = moment($scope.viewStart).format('YYYY-MM-DD');
        var end = moment($scope.viewEnd).format('YYYY-MM-DD');
        var nextDates = $scope.getStartEndDate(name, start, 'next');
        $scope.nextStart = nextDates.startDate;
        $scope.nextEnd = nextDates.endDate;
        var prevDates = $scope.getStartEndDate(name, start, 'prev');
        $scope.prevEnd = prevDates.endDate;
        $scope.prevStart = prevDates.startDate;
        var dataToSend = 'current=' + start + '|' + end + '&next=' + $scope.nextStart + '|' + $scope.nextEnd + '&prev=' + $scope.prevStart + '|' + $scope.prevEnd;

        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        $('#loading-indicator').show();
        $scope.loadingAllEvents = true;

        $scope.loadingEvents = true;
        $http.get(prefix + '/get_all_public_holiday/all?' + dataToSend, {timeout: canceler.promise}).success(function (data) {
            $scope.events = data.events;
            $scope.nextEvents = data.nextEvents;
            $scope.prevEvents = data.prevEvents;
            $scope.updateEvents();
            $scope.loadingAllEvents = false;
            $scope.loadingEvents = false;
        })
    }

    $scope.updateEvents = function() {
        var events = [];
        angular.forEach($scope.events, function (value, key) {
                events.push(value);
        });
        uiCalendarConfig.calendars.csCalendar.fullCalendar('removeEvents');
        $scope.currentEvents = events;
        if(events.length) {
            uiCalendarConfig.calendars.csCalendar.fullCalendar('addEventSource', events);
        }
    }


    $scope.getStartEndDate = function(viewName, dateObj, option) {
        var stringToAdd = '';
        var numberToAdd = 1;
        var valueToMultiple = (option == 'next') ? 1 : -1;
        var newEndDate = false;
        var newStartDate = false;
        if(viewName == 'month') {
            stringToAdd = 'months';
            numberToAdd = 1 * valueToMultiple;
            var addMonth = moment($scope.startDate).add(numberToAdd, stringToAdd);
            var startOfMonth = addMonth.clone().startOf('month');
            newStartDate = startOfMonth.clone().startOf('week').format('YYYY-MM-DD');
            var endOfMonth = addMonth.clone().endOf('month');
            newEndDate = endOfMonth.clone().endOf('week').add(1, 'day').format('YYYY-MM-DD');
        } else {
            return false;
        }
        var newStartDate = (!newStartDate) ? moment(dateObj).add(numberToAdd, stringToAdd).format('YYYY-MM-DD') : newStartDate;
        var newEndDate = (!newEndDate) ? moment(newStartDate).add(Math.abs(numberToAdd), stringToAdd).format('YYYY-MM-DD') : newEndDate;
        return {startDate: newStartDate, endDate: newEndDate};
    }

    var getPartialEventsCanceler = null;
    $scope.loadNextOrPrevEvents = function(view, buttonClicked) {
        var start = moment(view.start);
        var end = moment(view.end);
        var nameToSet = buttonClicked + 'Events';
        var startDateToSet = buttonClicked + 'Start';
        var endDateToSet = buttonClicked + 'End';

        if(buttonClicked == 'next') {
            $scope.prevEvents = $scope.events;
            $scope.events = $scope.nextEvents;
            $scope.prevStart = $scope.viewStart;
            $scope.prevEnd = $scope.viewEnd;
        } else {
            $scope.nextEvents = $scope.events;
            $scope.events = $scope.prevEvents;
            $scope.nextStart = $scope.viewStart;
            $scope.nextEnd = $scope.viewEnd;
        }

        var dates = $scope.getStartEndDate(view.name, start, buttonClicked);
        start = dates.startDate;
        end = dates.endDate;
        $scope.updateEvents();

        //This is to handle the failure case, if the user clicks next/prev before the event is loaded, the above logic won't work
        $scope[nameToSet] = false;

        $scope[startDateToSet] = start;
        $scope[endDateToSet] = end;

        var start = moment(start).format('YYYY-MM-DD');
        var end = moment(end).format('YYYY-MM-DD');
        var dataToSend = 'fromdate=' + start + '&todate=' + end ;

        canLoad.setLoadValue(false);
        if (getPartialEventsCanceler) {
            getPartialEventsCanceler.resolve();
        }
        getPartialEventsCanceler = $q.defer();

        $scope.loadingEvents = true;
        $http.get(prefix + '/get_all_public_holiday/next_prev?' + dataToSend, {timeout: getPartialEventsCanceler.promise}).success(function (data) {
            if($scope[nameToSet] === false) {
                $scope[nameToSet] = data.events;
            }
            $scope.loadingEvents = false;
        })
    }

    $scope.refreshCurrentEvents = function() {
        var start = moment($scope.viewStart).format('YYYY-MM-DD');
        var end = moment($scope.viewEnd).format('YYYY-MM-DD');
        var dataToSend = 'fromdate=' + start + '&todate=' + end ;
        $scope.loadingEvents = true;
        $http.get(prefix + '/get_all_public_holiday/next_prev?' + dataToSend).success(function (data) {
            if(data.events) {
                $scope.events = data.events;
                $scope.updateEvents();
            }
            $scope.loadingEvents = false;
        })
    }


}



var publicHolidayCalendarResolver = {
    publicHolidayCalendarObj: function ($q, $http, $stateParams, $state, PubNub, $rootScope, cacheBust,prefix, adminPrefix) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var deferred = $q.defer();
        $http.get(this.templateUrl($stateParams)).success(function (data, status, headersObj) {
            var headers = headersObj();
            SetRootScopeValues($rootScope, headers, PubNub, cacheBust, $http, prefix, adminPrefix,$state);
            if (data.location && data.location == 'logout') {
                $rootScope.$broadcast('chat:unregister');
                $state.transitionTo('home');
            } else if (data.location && data.location.endsWith("/")) {
                //$state.transitionTo('home');
            } else {
                deferred.resolve(data);
            }
        });
        return deferred.promise;
    }
}
