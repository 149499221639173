function CSCalendarModalCtrl($scope, $state,$http, prefix, $modalInstance) {
    $scope = $scope.$parent;
    $scope.shouldBeOpenUserList = false;
    $scope.shouldBeOpenEvents = false;
    
    $scope.limit = 5;
    $scope.title = "Calendar Integration";

    $scope.triggerCloseCalendarModalBox = function () {
        $scope.close();
    }
    
    $scope.triggerCloseCalendarModalBox = function () {
        $scope.close();
    }

    $scope.close = function() {
        $scope.shouldBeOpenCalendar = false;
        $scope.$emit('modal:close');
        $modalInstance.close();
    }
    
    window.onkeydown = function (evt) {
        $scope.modal_confirmation_done = false;
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }

    $scope.fetch_users_list = true;
    $scope.loading = true;
    $scope.showList = true;
    
    $http.get(prefix + '/getCalendarAccountLists?page=1&limit='+$scope.limit).success(function(data) {
            $scope.showList = false;
            $scope.usersData = data.data;
            $scope.totalUsers = data.count;
            
            $scope.fetch_users_list = false;
            $scope.loading = false;
    });
    
    $scope.getCalendarAccountLists = function (pageNum) {
        
        $http.get(prefix + '/getCalendarAccountLists?page=' + pageNum + '&limit='+$scope.limit).success(function(data) {
            $scope.showList = false;
            $scope.usersData = data.data;
            //alert(JSON.stringify(data.data));
            $scope.totalUsers = data.count;
            
            $scope.fetch_users_list = false;
            $scope.loading = false;
        });

    }
    
    
    $scope.getDiscardedEvents = function(pageNum) {
        $http.get(prefix + '/getDiscrdedEvents?page=' + pageNum + '&limit='+$scope.limit).success(function(data) {
            $scope.showList = false;
            $scope.eventData = data.data;
            $scope.totalDiscardedEvents = data.count;
            
            $scope.fetch_users_list = false;
            $scope.loading = false;
        });
    }
    
    $scope.getDiscardedEvents(1);
    
}

commusoftCommon.directive('csmodalcalendar', ['prefix', '$http', '$modal',function(prefix, $http, $modal) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            shouldBeOpenCalendar: '=',
            step: '=',
            name: '=',
            path: '='
        },
        controller: function($scope, $element, $attrs, $transclude) {
            
            $scope.$watch('shouldBeOpenCalendar', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;
                    modalInstance = $modal.open({
                        templateUrl: $scope.path,
                        scope: $scope,
                        controller: CSCalendarModalCtrl,
                        windowClass: 'modal with-slides large'
                    })
                }
           })
            
        }
    }
}]);

