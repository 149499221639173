commusoftCommon.service('estimateOptions', function() {
    this.estimate_options = [];

    this.new_option = {
        title: '',
        estimate_body: '',
        todos: [],
        cost: '',
        price: '',
        profit: ''
    }

    this.existing_option = {
        title: '',
        estimate_body: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo.',
        todos: [
            'todo1',
            'todo2',
            'todo3'
        ],
        cost: '300',
        price: '400',
        profit: '100',
        status: {
            decision: null,
            name: null,
            date: null,
            time: null
        }
    }

    // populate the array with an existing option
    var new_obj = {};
    var clone_obj = _.extend(new_obj, this.existing_option);
    clone_obj.title = 'Option ' + 1;
    clone_obj.status = {};
    this.estimate_options.push(clone_obj);

    this.generateEstimate = function generateEstimate(type) {
        var new_obj = {};
        if (type === 'clone') {
            var clone_obj = _.extend(new_obj, this.existing_option);
            clone_obj.title = 'Option ' + (this.estimate_options.length + 1);
            clone_obj.status = {
                decision: null,
                name: null,
                date: null,
                time: null
            };
        }else {
            var clone_obj = _.extend(new_obj, this.new_option);
            clone_obj.title = 'Option ' + (this.estimate_options.length + 1);
        }

        this.estimate_options.push(clone_obj);
        return this.estimate_options;
    }

    this.initEstimate = function initEstimate() {
        return this.estimate_options;
    }
});