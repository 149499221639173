csmodule.service('spreadsheetMergeRowHelper', function($rootScope, spreadSheetHelper, spreadSheetShortcutRefs) {
  var self;
  this.disabled_rows = [];

  this.handleMerge = function handleMerge(context, directive_scope) {
    self = this;
    this.checkbox_scope = context;
    this.directive_scope = directive_scope;
    this.rows = directive_scope.spreadsheet_rows;
    $rootScope.spreadsheet_instance_id = directive_scope.scope_id;

    this.handleCheckboxes();
  }

  /*==========================================================================================
    Keep this.directive_scope.merge_pricing_items_store array in sync with selected rows
  ==========================================================================================*/
  this.handleCheckboxes = function handleCheckboxes() {
    var not_on_page_load = this.checkbox_scope.merge_row !== undefined;

    if(not_on_page_load) {
      var should_merge = this.checkbox_scope.merge_row;

      if(should_merge) {
        this.directive_scope.merge_pricing_items_store.push({
          checkbox_id : this.checkbox_scope.$id,
          row_index: this.checkbox_scope.$parent.$parent.$parent.$index,
          row : this.checkbox_scope.$parent.$parent.$parent.row
        });

        this.disableRowsThatDontMatchMergeConditions();
      }
      else {
        var id_of_checkbox_to_remove = this.checkbox_scope.$id;

        for(var i = 0, l = this.directive_scope.merge_pricing_items_store.length; i < l; i++) {
          var checkbox_id = this.directive_scope.merge_pricing_items_store[i].checkbox_id;

          if(checkbox_id === id_of_checkbox_to_remove) {
            this.directive_scope.merge_pricing_items_store.splice(i, 1);

            var should_undisable_rows = l === 1;
            if (should_undisable_rows) {
              this.unDisableRowsThatDontMatchMergeConditions();
            }
            return;
          }
        }
      }
    }
  }

  this.disableRowsThatDontMatchMergeConditions = function disableRowsThatDontMatchMergeConditions() {
    var columns_that_should_match = this.directive_scope.merge_conditional_columns.split('-'),
        checked_row = this.checkbox_scope.$parent.row.row,
        total_rows_to_check = this.directive_scope.spreadsheet_rows.length;

    if(this.directive_scope.can_add_new_rows) {
      total_rows_to_check = this.directive_scope.spreadsheet_rows.length - 1;
    }

    for(var i = 0; i < total_rows_to_check; i++) {
      var row = this.directive_scope.spreadsheet_rows[i],
          disable_row = false;

      for(var x = 0, s = columns_that_should_match.length; x < s; x++) {
        if(checked_row[0].associated_rows[0][columns_that_should_match[x]].value !== row.row[0].associated_rows[0][columns_that_should_match[x]].value) {
          disable_row = true;
          break;
        }
      }

      if(disable_row) {
        // Only need to disable rows once.
        if(this.directive_scope.merge_pricing_items_store.length === 1) {
          this.disabled_rows.push(row);
          spreadSheetHelper.disableCellsInRow(row);
        }
      }
    }
  }

  this.unDisableRowsThatDontMatchMergeConditions = function unDisableRowsThatDontMatchMergeConditions() {
    for(var i = 0, l = this.disabled_rows.length; i < l; i++) {
      spreadSheetHelper.unDisableCellsInRow(this.disabled_rows[i]);
    }
    this.disabled_rows = []
  }

  this.insertMergedRow = function insertMergedRow(path, row, merged_row_ids) {
    this.directive_scope.merge_pricing_items_store = [];
    this.directive_scope.saveMergedRow(path, row, merged_row_ids);
  }

  this.removeMergedRows = function removeMergedRows(merge_data) {
    var indexes_to_remove = [];

    for(var i = 0, l = merge_data.length; i < l; i++) {
      var row_index = merge_data[i].row_index;
      indexes_to_remove.push(row_index);
    }

    // sort the indexes from biggest number to smallest
    indexes_to_remove.sort(function(a, b) {
      return b - a;
    });

    // remove the rows
    for(var i = 0, l = indexes_to_remove.length; i < l; i++) {
      var row_index = indexes_to_remove[i];
      this.rows.splice(row_index, 1);
    }
  }

  /*==========================================================================================
    Handle unmerging an already merged row
  ==========================================================================================*/
  this.hideUnMergeModal = function hideUnMergeModal(un_merge_modal) {
    $(un_merge_modal).modal('hide');
  }

  this.generateAssociatedRowsTemplate = function generateAssociatedRowsTemplate() {
    var empty_row_cells = this.directive_scope.spreadsheet_rows[this.directive_scope.spreadsheet_rows.length - 1].row[0].associated_rows[0],
        row_template = {
          "id" : null,
          "pos" : null,
          "merged_row_ids" : [],
          "row_valid" : true,
          "row" : [
            {
              "associated_rows" : [
                []
              ]
            }
          ]
        };

    for(var i = 0, l = empty_row_cells.length; i < l; i++) {
      var cell = empty_row_cells[i],
          cell_template = {
            "value" : "",
            "state" : "not_selectable",
            "valid" : true,
            "top_merged_row" : true
          };

      if (cell.hidden === true) {
        cell_template["hidden"] = true
      }
      if (cell.calculation !== undefined) {
        cell_template["value_is_sum_of_child_rows"] = true
      }

      row_template.row[0].associated_rows[0].push(cell_template);
    }

    return row_template;
  }
});
