commusoftCommon.directive('elementSize', function ($timeout, $window) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var w = angular.element($window);
            var getSize = function () {
                var height  = element[0].offsetHeight;
                var width  = element[0].offsetWidth;
                var parent = $(element).parent();
                var minWidth = 1660;
                // element.css({width: parent[0].offsetWidth > width ? parent[0].offsetWidth : width});
                element.css({width: parent[0].offsetWidth > width ? parent[0].offsetWidth : width});
                var zoom = Math.ceil((( window.outerWidth - 10 ) / window.innerWidth) * 100);
                // console.log(parent[0].offsetWidth, width, zoom);
                if(zoom < 100) {
                    element.css({width: parent[0].offsetWidth});
                }
                else if (zoom > 100) {
                    element.css({width: width});
                }
                else {
                    element.css({width: parent[0].offsetWidth});
                }

                if($( window).width() < 1367) {
                    element.css({width: minWidth});
                }

                if (attrs.key) {
                    scope[attrs.key] = {
                        height: height,
                        width: width
                    };
                    return;
                }
                scope.elementSize = {
                    height: height,
                    width: width
                };
            };
            $timeout(getSize,100);
            $( window ).resize(function(){
                getSize();
            });
        }
    };
});