commusoftCommon.directive('csestimatemodal', ['prefix', '$http', '$modal', function (prefix, $http, $modal) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            title: '@',
            deleteUrl: '@',
            templateUrl: '@',
            buttonValue: '@',
            modalToDisplay: '@',
            jobStatus: '=',
            selectedId: '=',
            jobId: '=',
            shouldBeOpen: '=',
            bookDiaryEvent: '=',
            deleteFunc: '&',
            estimateOptions: '=',
            buttonClass: '@',
            textToConfirm: '@',
            options: '=',
            selectedReasons: '='
        },
        controller: function ($scope, $element, $attrs, $transclude) {
            $scope.$watch('shouldBeOpen', function (newVal, oldVal, scope) {
                var modalInstance = null;
                $scope.choose_option_to_clone = false;
                if (newVal) {
                    var elem = $transclude($scope, function () {
                    });
                    $scope.transData = elem;
                    modalInstance = $modal.open({
                        templateUrl: $scope.templateUrl,
                        scope: $scope,
                        controller: CSEstimateModalCtrl
                    })
                }
            });
        }
    }
}]);