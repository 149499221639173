commusoftCommon.directive('officeTasksSidepanel', ['$rootScope', '$http', '$timeout', '$location', 'prefix', function($rootScope, $http, $timeout, $location, prefix) {
    return {
        restrict: 'ACE',
        controller: OfficeTasksSidepanelCtrl,
        link: function($scope, $elem, $attrs) {
            $scope.closeOfficeTasksPanel = function closePanel(evt) {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                $scope.removeBothElements();
            };

            $scope.activateOfficeTasksTab = function activateOfficeTasksTab(tab) {
                $scope.active_office_tasks_tab = tab;
            };

            $scope.triggerOfficeTasksSidepanel = function triggerOfficeTasksSidepanel(id, category) {
                $rootScope[category + 'SidepanelTriggered'] = false;
                $scope.category = category;
                $scope.$emit('triggerSidepanel', id, category);
                $scope.populateViewableTasks();
            };

            $scope.assignToMe = function assignToMe(evt, cat, index, id) {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                var officetask = angular.copy($scope.viewableTasks[cat][index]);
                var updatedOfficetask = {};
                var newAssignee = $rootScope.userId;
                updatedOfficetask.assigned_to_user_id = newAssignee;
                updatedOfficetask.tablepkid = (officetask.jobnumber !== null) ? officetask.jobnumber : officetask.estimatenumber;
                updatedOfficetask.tabletype = (officetask.jobnumber !== null) ? 122 : 123;
                updatedOfficetask.description = officetask.description;

                $scope.viewableTasks[cat][index].animated = true;
                $scope.viewableTasks[cat][index]['before-assignment'] = true;
                $scope.viewableTasks[cat][index]['fadeout'] = true;

                $http({
                    url: prefix + '/office_task_notification_update/' + id,
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {officetask: updatedOfficetask}
                })
                    .then(function(response) {
                        $timeout(function() {
                            $scope.viewableTasks[cat][index].animated = false;
                            $scope.tasksLoading[cat] = true;
                            $scope.viewableTasks[cat][index]['before-assignment'] = false;
                            $scope.viewableTasks[cat][index]['after-assignment'] = true;
                            $scope.populateViewableTasks('refresh');
                        }, 100);
                    });
            };

            $scope.toggleSelection = function toggleSelection(cat, index, id) {
                if ($scope.viewableTasks[cat][index].complete !== true) {
                    $scope.viewableTasks[cat][index].complete = 'timer';
                    $scope.viewableTasks[cat][index].animated = true;
                    $scope.timer = $timeout(function() {
                        $scope.viewableTasks[cat][index].fadeout = true;
                        $scope.viewableTasks[cat][index].shrinking = true;
                        $http({
                            url: prefix + '/office_task_notification_complete',
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            params: {id: id}
                        })
                            .then(function(response) {
                                $scope.viewableTasks[cat][index].animated = false;
                                $scope.viewableTasks[cat][index].complete = true;
                                $scope.populateViewableTasks('refresh');
                            });
                    }, 2000);
                }
                else if ($scope.viewableTasks[cat][index].complete == 'timer') {
                    $timeout.cancel($scope.timer);
                    $scope.viewableTasks[cat][index].complete = false;
                    $scope.viewableTasks[cat][index].fadeout = false;
                }
            };

            $scope.beginSearchingTasks = function beginSearchingTasks(tab) {
                $scope.disableInfiniteScroll[tab] = false;
                $scope.tasksLoading[tab] = true;
                $timeout.cancel($scope.tasksSearchTimer);
                $scope.tasksSearchTimer = $timeout(function() {
                    $scope.disableInfiniteScroll[tab] = true;
                    $scope.searchTasks(tab);
                }, 400);
            };

            $scope.canShowJob = function canShowJob(cat, index) {
                if ($scope.viewableTasks[cat][index]['estimatenumber'] !== null) {
                    return false;
                }
                return true;
            };

            $scope.goToJob = function goToJob(evt, cat, index) {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                var task = $scope.viewableTasks[cat][index];
                $location.path('/customers/customer/' + task.propertyid + '/jobs/' + task.jobnumber + '/details/view');
                $scope.removeBothElements();
            };

            $scope.goToEstimate = function goToEstimate(evt, cat, index) {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                var task = $scope.viewableTasks[cat][index];
                $location.path('/customers/customer/' + task.propertyid + '/estimate/' + task.estimatenumber + '/estimate_details/survey/view');
                $scope.removeBothElements();
            };

            $scope.areThereNoTasks = function areThereNoTasks(cat) {
                if ($scope.tasksLoading[cat] == true || $scope.areThereNoResultsFound(cat)) {
                    return false;
                }
                var numTasks = 0;
                if (cat == 'officetasks_assigned') {
                    angular.forEach($scope.viewableTasks[cat], function(val, key) {
                        if ($scope.viewableTasks[cat][key]['complete'] !== true || $scope.viewableTasks[cat][key]['complete'] == 'timer') {
                            numTasks++;
                        }
                    });
                }
                else {
                    numTasks = $scope.viewableTasks[cat].length;
                }
                return (numTasks == 0);
            };

            $scope.searchTasks = function searchTasks(cat) {
                if ($scope.searchQueries[cat].length > 0) {
                    $scope.tasksLoading[cat] = true;
                    var userid = (cat == 'officetasks_unassigned') ? 0 : $rootScope.userId;

                    $scope.tasksLoading[cat] = true;
                    $http({
                        url: prefix + '/office_task_notifications',
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        params: {
                            'search': $scope.searchQueries[cat],
                            'userid': userid,
                            status: 'incomplete'
                        }
                    })
                        .then(function(response) {
                            $scope.viewableTasks[cat] = angular.copy(response.data.officetasknotifications);
                            if ($scope.viewableTasks[cat].length == 0) {
                                $scope.tasksSearchResultsNotFound[cat] = true;
                            } else {
                                $scope.tasksSearchResultsNotFound[cat] = false;
                            }

                            $scope.disableInfiniteScroll[cat] = false;
                            $scope.tasksLoading[cat] = false;
                        });
                }
                else {
                    $scope.populateViewableTasks('refresh');
                }
            };

            $scope.loadMore = function loadMore(tab) {
                if ($scope.disableInfiniteScroll[tab]) {
                    return false;
                }
                // prevent an infinite loop
                document.getElementById(tab).scrollTop = document.getElementById(tab).scrollTop - 10;
                if (!$scope.tasksInfiniteScrollMessage[tab]) {
                    $scope.tasksInfiniteScrollMessage[tab] = true;
                    if (!$scope.reachedEndOfTasks[tab]) {
                        // don't increase pagination if we've already reached the max # of available tasks
                        $scope.viewableTasksPage[tab]++;
                    }
                    $scope.populateViewableTasks();
                }
            };

            $scope.canShowEstimate = function canShowEstimate(cat, index) {
                if ($scope.viewableTasks[cat][index]['jobnumber'] !== null) {
                    return false;
                }
                return true;
            };

            $scope.goToCustomer = function goToCustomer(evt, cat, index) {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                var task = $scope.viewableTasks[cat][index];
                $location.path('/customers/customer/' + task.propertyid + '/view/property/view');
                $scope.removeBothElements();
            };

            $scope.areTasksLoading = function areTasksLoading(cat) {
                return $scope.tasksLoading[cat];
            };

            $scope.populateViewableTasks();
        }
    }
}]);