function NewChatController($scope, $rootScope, $http, prefix, $modal, $modalInstance, canLoad) {
//($scope, $http, $state, $rootScope, $timeout, datasets, prefix,$location,$modal, $modalInstance, canLoad) { 
    $modalInstance.result.then(function () {
        $scope.close();
    }, function () {
        $scope.close();
    });

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }
    $scope.close = function () {
        $modalInstance.close();
    }
    
    $scope.showMessage = false;
    $scope.isOwner = 0;
    $scope.loading = true;
    $scope.showSetupDetails = function() {
        $http.get(prefix + '/showChatMessage').success(function (data) {
            $scope.loading = false;
            $scope.isOwner = data.isOwner;
            $scope.showMessage = true;
            $scope.chatOption = data.data;
            
        });
    }
    
    $scope.showSetupDetails();

}
