/**
 * Created by sivachandran on 20/10/17.
 */

/** function - handsontable spreadsheet in customer cash allocation **/
function customerAllocationSpreadsheet($scope, prefix, $http, $rootScope, $state,confirmationBoxHelper,$timeout)
{
    $scope.spreadSheetInvoice = [];
    $scope.invoiceNumberDropDown = [];
    $scope.defaultInvoiceDetails = [];
    $scope.defaultInvoiceCount = 0;
    $scope.addedInvoiceCount = 0;
    $scope.readOnlyInvoice = [];
    $scope.isInvoiceSpreadSheetEmpty = true;
    $scope.displaySpreadsheet = false;
    $scope.allocationInstanceLoad='';
    $scope.rowToDelete= [];
    $scope.section_open = true;   // show and hide spreadsheet

    /*** Spreadsheet default settings for both parts & supplier ***/
    $scope.rowHeaders = true;
    $scope.startRows = 1;
    /*** Set spare row as 0, because when load default data it automatically insert an another empty row ***/
    $scope.minSpareRows = 0;
    $scope.spreadsheetCount = 0;
    $scope.minRows = 0;
    $scope.width = "auto";
    $scope.autoWrapCol = true;
    $scope.autoWrapRow = true;
    CustomerspreadsheetScroll.call(this,$scope);

    //* Load the default data into the spreadsheet
    $scope.invoiceAfterInit = function() {

        //* Get the default handsontable  settings
        $scope.updateSettings = this.updateSettings;

        $http.get($scope.getListUrl).success(function(response) {

            $scope.colHeadersInvoices = response.colHeaders;
            $scope.startColsInvoices = response.startColsInvoices;
            $scope.spreadSheetInvoice = response.spreadSheetInvoice;
            //$scope.invoiceNumberDropDown = response.invoiceNumberDropDown;
            $scope.invoiceNumberDropDown = [];
            // $scope.defaultInvoiceDetails = response.defaultInvoiceDetails;
            $scope.defaultInvoiceDetails = [];
            $scope.defaultInvoiceCount = response.defaultInvoiceCount;
            $scope.readOnlyInvoice = response.readOnlyInvoice;
            $scope.selectedInvoices = [];
            for(var row=0; row < $scope.spreadSheetInvoice.length; row++){
              $scope.selectedInvoices.push($scope.spreadSheetInvoice[row]['invoiceNumber']);
            }

            /*** Calculation the Total section ***/
            $scope.allocationSumTotal();

            //* Load the default value when new row created
            $scope.invoiceDataSchema= {id: '',invoiceId:'',invoiceNumber: '', invoiceDate: '', description: '', invoiceAmount: 0.00, paidAmount: 0.00,
                amountToAllocate: 0.00, remainderAmount: 0.00, remainderToPay: 0.00, deleteAction: "", invoiceType:''};
            $scope.invoiceColWidths = [1,1,150,120,200,130,140,140,135,140,70,1];

            //* Assign the spreadsheet columns and its cell type
            $scope.invoiceColumns = [
                {data: 'id', type: 'numeric', readOnly: true},
                {data: 'invoiceId', type: 'numeric', readOnly: true},
                {
                  renderer:$scope.invoiceRenderer,
                  className: 'overflow_handsontable select_icon',
                  data: 'invoiceNumber',
                  editor: 'select2',
                  placeholder: 'Please select',
                  validator: $scope.cellValidator,
                  select2Options: {
                    editable: true,
                    //minimumInputLength:1,
                    dropdownCssClass: "bigdrop",
                    formatLoadMore: function(){
                      return ' Loading...';
                    },
                    ajax: {
                      quietMillis: 250,
                      delay: 250,
                      url: prefix + '/get_customer_invoices_dropdown',
                      dataType: 'json',
                      data: function(query, page){
                        if(_.isUndefined(query)) {
                          query='';
                        }
                        var params = {
                          'query': encodeURIComponent(query),
                          'id': $scope.selectedId,
                          'selectedInvoices': $scope.selectedInvoices,
                          'page': page
                        };
                        if($scope.isFromInvoiceAddress===true) {
                          params.mode = 'invoice_address';
                        }
                        if($state.params.creditNoteId) {
                          params.creditNoteId = $state.params.creditNoteId;
                        }
                        if($state.params.paymentId) {
                          params.paymentId = $state.params.paymentId;
                        }
                        return params;
                      },
                      results: function(data, page) {
                        $scope.$apply(function(){
                          $scope.defaultInvoiceDetails = data.invoiceDetails;
                        });
                        var items = _.map(data.invoiceDetails, function(inv){ return {id:inv.invoiceNumber, text:inv.invoiceNumber}});
                        if( !data.needLazyLoad && (page * 10) < data.invoiceTotalCount ) {
                          items.push({id: '0', text: 'Type to load more', disabled: true});
                        }
                        return {
                          results: items,
                          more: data.needLazyLoad && (page * 10) < data.invoiceTotalCount
                        };
                      }
                    }
                  }
                },
                /*{renderer: $scope.invoiceRenderer,className: 'overflow_handsontable select_icon',
                    data: 'invoiceNumber',
                    editor: 'select2',
                    placeholder: 'Please select',
                    allowEmpty:false,
                    validator: $scope.cellValidator,
                    select2Options: {
                        data: $scope.invoiceNumberDropDown,
                        dropdownAutoWidth: false,
                        dropdownCssClass: "handsontable-select",
                        width: 'resolve'
                    }
                },*/
                {data: 'invoiceDate', type: 'text', readOnly: true, disableVisualSelection: true},
                {data: 'description', type: 'text', readOnly: true, disableVisualSelection: true},
                {data: 'invoiceAmount', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
                {data: 'paidAmount', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
                {renderer: $scope.invoiceRenderer, data: 'amountToAllocate', type: 'numeric', format: '0.00',allowInvalid: false},
                {data: 'remainderAmount', type: 'numeric', format: '0.00'},
                {data: 'remainderToPay', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
                {renderer: $scope.invoiceRenderer, data: 'deleteAction', readOnly: true, disableVisualSelection: true},
                {data: 'invoiceType', type: 'text', readOnly: true}
            ];

            //* Column to hide in spreadsheet
            $scope.hideInvoiceColumns = {columns:[0,1,8,11]};

            /*** Load the new settings into the spreadsheet and load them ***/
            $scope.updateSettings({colHeaders: $scope.colHeadersInvoices, fillHandle: {direction: 'vertical', autoInsertRow: true},
                rowHeaders: $scope.rowHeaders, startRows: $scope.startRows, startCols: $scope.startColsInvoices,
                colWidths: $scope.invoiceColWidths, columns: $scope.invoiceColumns, minSpareCols: 0,
                autoWrapRow:$scope.autoWrapRow,data: $scope.spreadSheetInvoice,hiddenColumns:$scope.hideInvoiceColumns, stretchH: 'all',
                formulas: true, minSpareRows: $scope.minSpareRows, dataSchema: $scope.invoiceDataSchema, minRows: $scope.minRows}); /*dataSchema: $scope.dataSchema,*/

            $scope.displaySpreadsheet = true;
            $scope.show_invoice_spreadsheet = true;


        });
    }

    $scope.invoiceRenderer = function(instance, td, row, col, prop, value, cellProperties) {

        var cellTitle = instance.getCellMeta(row, col);
        var cellCase = cellTitle.prop;
        switch(cellCase) {
            case 'invoiceNumber':

                if( value && $scope.readOnlyInvoice[value]) {
                    cellProperties.readOnly=true;
                    cellProperties.disableVisualSelection= true;
                } else {
                  if(_.has($scope.defaultInvoiceDetails, value) && $scope.defaultInvoiceDetails[value] && !_.contains($scope.selectedInvoices, value)) {
                    $scope.spreadSheetInvoice[row]['invoiceDate'] = $scope.defaultInvoiceDetails[value]['invoiceDate'];
                    $scope.spreadSheetInvoice[row]['invoiceAmount'] = $scope.defaultInvoiceDetails[value]['invoiceAmount'];
                    $scope.spreadSheetInvoice[row]['description'] = $scope.defaultInvoiceDetails[value]['description'];
                    $scope.spreadSheetInvoice[row]['paidAmount'] = $scope.defaultInvoiceDetails[value]['paidAmount'];
                    //* Used calculation for to assign the allocated value while select the invoice number.
                    if($scope.allocationHotId == 'customerCreditNoteSpreadsheet') {
                      $scope.allocateValue = 0; $scope.remainingToAllocate = $scope.remainingCredit - $scope.amount_allocated;
                      if($scope.remainingToAllocate > 0) {
                        $scope.remainderAmounts = parseFloat($scope.defaultInvoiceDetails[value]['remainderAmount']);
                        if($scope.remainingToAllocate >= $scope.remainderAmounts) {
                          $scope.allocateValue = roundFloat($scope.remainderAmounts,2);
                        } else if($scope.remainingToAllocate < $scope.remainderAmounts) {
                          $scope.allocateValue = roundFloat($scope.remainingToAllocate,2);
                        }
                      }
                    } else {
                      $scope.amountPaid = parseFloat($scope.defaultInvoiceDetails[value]['amountPaid']);
                      $scope.remainderAmounts = parseFloat($scope.defaultInvoiceDetails[value]['remainderAmount']);
                      if($scope.remainderAmounts == $scope.amountPaid) {
                        $scope.allocateValue = 0;
                      } else {
                        $scope.allocateValue = roundFloat($scope.remainderAmounts,2);
                      }
                    }

                    $scope.spreadSheetInvoice[row]['id'] = $scope.defaultInvoiceDetails[value]['id'];
                    $scope.spreadSheetInvoice[row]['invoiceId'] = $scope.defaultInvoiceDetails[value]['invoiceId'];
                    $scope.spreadSheetInvoice[row]['amountToAllocate'] = $scope.allocateValue;
                    $scope.spreadSheetInvoice[row]['remainderAmount'] = $scope.defaultInvoiceDetails[value]['remainderAmount'];
                    $scope.spreadSheetInvoice[row]['remainderToPay'] = $scope.defaultInvoiceDetails[value]['remainderToPay'];
                     $scope.spreadSheetInvoice[row]['invoiceType'] = $scope.defaultInvoiceDetails[value]['invoiceType'];

                    cellProperties.readOnly=true;
                    cellProperties.disableVisualSelection= true;
                    if(!_.contains($scope.selectedInvoices, value)) {
                      $scope.selectedInvoices.push(value);
                      $scope.addedInvoiceCount++;
                    }

                    //$scope.invoiceNumberDropDown.splice(m,1);
                  }
                    /*if($scope.invoiceNumberDropDown.length) {
                        for (var m = 0; m < $scope.invoiceNumberDropDown.length; m++) {
                            if (value === $scope.invoiceNumberDropDown[m].id) {
                                $scope.spreadSheetInvoice[row]['invoiceDate'] = $scope.defaultInvoiceDetails[value]['invoiceDate'];
                                $scope.spreadSheetInvoice[row]['invoiceAmount'] = $scope.defaultInvoiceDetails[value]['invoiceAmount'];
                                $scope.spreadSheetInvoice[row]['description'] = $scope.defaultInvoiceDetails[value]['description'];
                                $scope.spreadSheetInvoice[row]['paidAmount'] = $scope.defaultInvoiceDetails[value]['paidAmount'];

                                //!* Used calculation for to assign the allocated value while select the invoice number.
                                if($scope.allocationHotId == 'customerCreditNoteSpreadsheet') {
                                    $scope.allocateValue = 0; $scope.remainingToAllocate = $scope.remainingCredit - $scope.amount_allocated;
                                    if($scope.remainingToAllocate > 0) {
                                        $scope.remainderAmounts = parseFloat($scope.defaultInvoiceDetails[value]['remainderAmount']);
                                        if($scope.remainingToAllocate >= $scope.remainderAmounts) {
                                            $scope.allocateValue = roundFloat($scope.remainderAmounts,2);
                                        } else if($scope.remainingToAllocate < $scope.remainderAmounts) {
                                            $scope.allocateValue = roundFloat($scope.remainingToAllocate,2);
                                        }
                                    }
                                } else {
                                    $scope.amountPaid = parseFloat($scope.defaultInvoiceDetails[value]['amountPaid']);
                                    $scope.remainderAmounts = parseFloat($scope.defaultInvoiceDetails[value]['remainderAmount']);
                                    if($scope.remainderAmounts == $scope.amountPaid) {
                                        $scope.allocateValue = 0;
                                    } else {
                                        $scope.allocateValue = roundFloat($scope.remainderAmounts,2);
                                    }
                                }


                                $scope.spreadSheetInvoice[row]['id'] = $scope.defaultInvoiceDetails[value]['id'];
                                $scope.spreadSheetInvoice[row]['invoiceId'] = $scope.defaultInvoiceDetails[value]['invoiceId'];
                                $scope.spreadSheetInvoice[row]['amountToAllocate'] = $scope.allocateValue;
                                $scope.spreadSheetInvoice[row]['remainderAmount'] = $scope.defaultInvoiceDetails[value]['remainderAmount'];
                                $scope.spreadSheetInvoice[row]['remainderToPay'] = $scope.defaultInvoiceDetails[value]['remainderToPay'];

                                cellProperties.readOnly=true;
                                cellProperties.disableVisualSelection= true;

                                $scope.invoiceNumberDropDown.splice(m,1);
                            }
                        }
                    }*/
                }
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'amountToAllocate':
                var allocatedAmount =0.00, remainderAmount =0.00, remainderToPay =0.00;
                value =  (value) ? value: 0.00;
                var invoiceNo = $scope.spreadSheetInvoice[row]['invoiceNumber'];
                if(invoiceNo){
                    allocatedAmount = roundFloat(value,2);
                    remainderAmount = roundFloat($scope.spreadSheetInvoice[row]['remainderAmount'],2);
                    remainderToPay = parseFloat(remainderAmount) - parseFloat(allocatedAmount);
                    $scope.spreadSheetInvoice[row]['remainderToPay'] = roundFloat(remainderToPay,2);
                    $scope.spreadSheetInvoice[row]['amountToAllocate'] = allocatedAmount;
                    value =round(allocatedAmount,2);
                    Handsontable.cellTypes.text.renderer.apply(this, arguments);
                    $scope.allocationSumTotal();
                }
                break;
            case 'deleteAction':
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                var div = document.createElement('DIV');
                var a = document.createElement('a');
                var linkText = document.createTextNode("Delete");
                a.appendChild(linkText);
                a.title = "Delete Action";
                a.style= "cursor:pointer;";
                div.appendChild(a);
                $(td).empty().append(div).append(a);
                $(a).on('click', function(e) {
                    $scope.invoiceDeleteAction(row, col);
                });
                return td;
                break;
        }

    }

    $scope.invoiceAfterChange = function(changes, src) {

        //* Load the spreadsheet Instance record to alter the spreadsheet
        $scope.allocationInstanceLoad = this;
        var getSelect = this.getSelected();
        var HotId = this.getSettings().hotId;
        var selectedRow = (typeof  getSelect != 'undefined') ? getSelect[0] : 0;
        //* Get the current remaining to allocate amount only for credit note allocation screen.
        $scope.currentRemainingToAllocate = $scope.remainingCredit - $scope.amount_allocated;
        //$scope.allowInsertRow = (($scope.currentRemainingToAllocate > 0) || $scope.invoiceNumberDropDown.length > 0) ? true : false;
        //console.log($scope.defaultInvoiceCount, $scope.addedInvoiceCount)
        $scope.allowInsertRow = (($scope.currentRemainingToAllocate > 0) || ($scope.defaultInvoiceCount > $scope.addedInvoiceCount) ) ? true : false;

        //* Load an empty default data during
        if(src === 'loadData' && ($scope.allowInsertRow || $scope.spreadSheetInvoice.length === 0)) {
            $timeout(function () {
                $scope.scrollingSpreadsheet(HotId,$scope.allocationInstanceLoad);
            },1000);
                this.alter('insert_row', this.countRows());
                this.render();
        }else if(src === 'edit' && $scope.allowInsertRow) {
            $scope.insertRowInvoice(this, changes, src);
        }
        this.scrollViewportTo(selectedRow -1,1);

        //* Check with custom scope value validation
        for(var x=0; x<$scope.spreadSheetInvoice.length-1; x++) {
            if(this.getDataAtRowProp(x, "invoiceNumber") === '') {
                $scope.validateCell=true;
                break;
            } else {
                $scope.validateCell=false;
            }
        }

        //* If any cols is invalid then break the loop.
        if($scope.validateCell == true) {
            $scope.isInvoiceSpreadSheetEmpty=true;

        } else {
            $scope.isInvoiceSpreadSheetEmpty=false;
        }

        if(src == 'edit') {
            var activeEditor = this.getActiveEditor(),
                currentRow = activeEditor.row,
                totalRows = this.countRows()-1,
                moveRow = currentRow +1;

            if((activeEditor.prop == 'amountToAllocate') && (totalRows >= moveRow)) {
                //# In edit screen the invoice number is editable
                if(this.getCellMeta(moveRow, 2).disableVisualSelection) {
                    this.selectCell(moveRow, 7);
                } else {
                    this.selectCell(moveRow, 2);
                }
            }
        }

    }

    //* If edit the last row then need to insert a new row with default row
    $scope.insertRowInvoice = function(currentScope,changes, src) {
        var selected =  currentScope.getActiveEditor(),
            selectedCol =  selected.col,
            selectedRow =  selected.row,
            rowVal =  currentScope.getSourceDataAtCell(selectedRow,"2");
        var isRowValidate = !!(selectedCol == 2 && rowVal);
        if (changes != null && changes[0][0] === (currentScope.countRows() - 1) && src === 'edit' && isRowValidate) {
            currentScope.alter('insert_row', currentScope.countRows());
            currentScope.render();
        }
    }

    $scope.tabMoves= function (event) {
        if(event.shiftKey) {
            return {row: 2, col: 1};
        }
        else {
            return {row: 0, col: 1};
        }
    };

    //* Check the cell validation
    $scope.cellValidator = function (value, callback) {

        if(value != '') {
            if(this.type == 'numeric' && isNaN(value)) {
                callback(false);
            }
            callback(true);
        } else {
            callback(false);
        }
    }

    //* Delete the spreadsheet items
    $scope.invoiceDeleteAction = function(r, c) {
        var confirmation_message = 'This row has not been saved yet, are you sure you want to delete it?';
        if(c === ($scope.allocationInstanceLoad.getInstance().countCols() -2)) {
            //# Before delete confirm
            confirmationBoxHelper.getConfirmation(confirmation_message, this)
                .then(function() {

                    //# Get the primary id of the edit screen, to delete from backend
                    var rowId=$scope.allocationInstanceLoad.getSourceDataAtRow(r);
                    if((typeof rowId != 'undefined') && rowId.id) {
                        $scope.rowToDelete.push(rowId.id);
                    }

                    $scope.allocationInstanceLoad.alter('remove_row', r);
                    $scope.allocationInstanceLoad.render();

                    //* Push the outstanding invoice record, to reload into drop down
                    if($scope.readOnlyInvoice[rowId.invoiceNumber] === false) {
                        //$scope.invoiceNumberDropDown.push({id:rowId.invoiceNumber, text: rowId.invoiceNumber});
                        $scope.selectedInvoices.splice(r,1);
                        $scope.addedInvoiceCount--;
                    }

                    //* Get the current remaining to allocate amount only for credit note allocation screen.
                    $scope.currentRemainingToAllocate = $scope.remainingCredit - $scope.amount_allocated;
                    $scope.allowInsertRow = (($scope.currentRemainingToAllocate > 0) || ($scope.allocationHotId == 'customerAllocationSpreadsheet')) ? true : false;

                    var countRows=$scope.allocationInstanceLoad.countRows();
                    if(countRows == 0 || (countRows == r && $scope.allowInsertRow)) {
                        $scope.allocationInstanceLoad.alter('insert_row', countRows);
                        $scope.allocationInstanceLoad.render();
                    }

                    $scope.allocationSumTotal();

                }, function() {
                    return false
                });
        }
    }

    //* Sum the allocation details
    $scope.allocationSumTotal = function() {
        $scope.amount_allocated = 0.00;
        $scope.invoice_amount = 0.00;
        $scope.remainder_to_pay = 0.00;
        $scope.spreadsheetCount = $scope.spreadSheetInvoice.length;
        var j = 0, multiple_invoice_amount = 0 ;
        for(var i = 0, l = $scope.spreadSheetInvoice.length; i < l; i++) {
            $scope.amount_allocated += parseFloat($scope.spreadSheetInvoice[i]["amountToAllocate"]);
            $scope.invoice_amount += parseFloat($scope.spreadSheetInvoice[i]["invoiceAmount"]);
            $scope.remainder_to_pay += parseFloat($scope.spreadSheetInvoice[i]["remainderToPay"]);
             j = (!($scope.spreadSheetInvoice[i]["invoiceNumber"])) ? j+1 : j;
             if($scope.spreadSheetInvoice[i]["invoiceType"] == 'multiple'){
                 multiple_invoice_amount += parseFloat($scope.spreadSheetInvoice[i]["amountToAllocate"]);
                 multiple_invoice_amount = roundFloat(multiple_invoice_amount,2)
             }
        }
        $scope.spreadsheetCount = $scope.spreadsheetCount - j ;
        $scope.amount_allocated = roundFloat($scope.amount_allocated,2);
        $scope.invoice_amount = roundFloat($scope.invoice_amount,2);
        $scope.remainder_to_pay = roundFloat($scope.remainder_to_pay,2);
        //* Get the current remaining to allocate amount
        $scope.sumRemainingToAllocate = $scope.remainingCredit - ($scope.amount_allocated+$scope.receivedAmount);

        if($scope.allocationHotId == 'customerAllocationSpreadsheet')
        {   $scope.payment.consolidatedInvoiceAmount = multiple_invoice_amount;
            $scope.payment.amount = $scope.amount_allocated;
            $scope.handleBreakdown();
        }


    };
    $scope.handleCollapse = function handleCollapse(e) {
        $scope.section_open = !$scope.section_open;
    }
}

function CustomerspreadsheetScroll($scope) {

//# After scroll vertical without change need to update the class name
    $scope.scrollingSpreadsheet = function(hotId, instance) {
        //# Update the class name after spreadsheet loaded.
        instance.updateSettings({className: 'overflow_handsontable'});
        instance.render();
    }
}

/** function - handsontable spreadsheet in customer creditnote and invoice **/
function customersSpreadsheet($scope, prefix, $http,$timeout, $rootScope,$state, confirmationBoxHelper,toastBox) {

    var handsontable_settings = {
        "rowHeaders": true,
        "startRows": 1,
        "minSpareRows": 0,
        "minRows": 0,
        "width": "auto",
        "autoWrapCol": true,
        "autoWrapRow": true
    }; // initialize handsontable default settings

    /** initialize spreadsheet settings */
    var spreadsheetId,
        pricingItem,
        url,
        disableColHeader = '',
        validateErrorRow = false,
        hiddenColumns = [],
        requiredFields = {
            "1": "name",
            "2": "cis",
            "3": "quantity",
            "4": "unit price",
            "5": "VAT item",
            "6": "VAT rate",
            "7": "nominal code"
        };

    $scope.spreadsheetInstance = []; // list of all handsontable instance
    $scope.spreadsheetLineItems = {}; //  line items of spreadsheet
    $scope.show_spreadsheet = true; // for single spreadsheet in one page
    $scope.show_spreadsheets = {}; // for multiple spreadsheet in one page
    $scope.section_open = true; // button to show or hide spreadsheet section
    $scope.isSpreadsheetValidate = true; // check spreadsheet validate or not
    $scope.isLoadExistingSpreadsheetData = true; // is check whether data load or not
    $scope.rowToDelete = []; // store deleted row's
    $scope.default_tax_item_id = $scope.defaultTaxItem ? $scope.defaultTaxItem.toString() : $scope.defaultTaxItem; // default tax item id
    $scope.spreadsheetData = [];
    $scope.breakdownSubTotal = [];
    $scope.totalCount = [];
    $scope.listOfTaxItems = [];

    CustomerspreadsheetScroll.call(this, $scope);
    getSpreadsheetDefaultSchema.call(this, $scope, prefix, $http);
    internalizationTaxSettings.call(this,$scope);

    $scope.default_tax_item_id = $scope.isHideVat ? null : $scope.default_tax_item_id;

    /*** set some default values of spreadsheet for every breakdown -- (column width,hide column)***/
    $scope.getSpreadsheetDefaults = function (hotid) {
        var active_option = $scope.active_option;

        switch (active_option) {
            case 'category_breakdown':
                url = $scope.getBreakdownListUrl;
                $scope.colWidths = [60, 220, 70, 120, 140, 200, 120, 170, 160, 70, 70, 70,70,70,80];
                hiddenColumns[hotid] = {columns: [0,3,6,9,10,11,12,13]};
                break;

            case 'full_breakdown':
                url = $scope.getFullBreakdownListUrl;
                $scope.colWidths = [60, 220, 70, 120, 140, 200, 120, 170, 160, 70, 70, 70,70,70,80];
                hiddenColumns[hotid] = {columns: [0,6,9,10,11,12,13]};
                break;

            case 'full_breakdown_by_category':
                hiddenColumns[hotid] = (hotid == 'CU_Labour') ? {columns: [0,6,9,10,11,12,13]} : {columns: [0,2,6,9,10,11,12,13]};
                url = $scope.getFullBreakdownListUrl + '/' + $scope.spreadsheetCategoryId;
                $scope.colWidths = [60, 220, 70, 120, 140, 200, 120, 170, 160, 70, 70, 70,70,70,80];
                $scope.isdeleteSpreadsheet = true;
                break;
        }

        if ($scope.isHideVat) {
            hiddenColumns[hotid].columns.push(5)
        }

        if ($scope.isHideCIS) {
            hiddenColumns[hotid].columns.push(2)
        }
    };

    /*** Load the default data into the spreadsheet ***/
    $scope.spreadsheetAfterInit = function() {
        var hotId;
        $scope.pricingItems = $scope.invoiceLineItemCategories =  [];

        hotId = this.getSettings().hotId;
        $scope.spreadsheetInstance[hotId] = this;
        $scope.getSpreadsheetDefaults(hotId);

        $scope.show_spreadsheet = false;
        $scope.show_spreadsheets[hotId] = false;
        $scope.showHideSpreadsheet();

        $http.get(url).success(function(response) {
            $scope.spreadsheetLineItems[hotId] =  ($scope.isLoadExistingSpreadsheetData) ? response.spreadsheetLineItems: [];
            $scope.cisRate = response.cisRate;

            $scope.getVatGroupByProvinces($scope.taxDetails,response.existTaxLists);

            $scope.spreadsheetData[hotId] = {
                "colHeaders": response.colHeaders,
                "startCols": response.startCols,
                "nominalcode": response.auto_complete_options['nominal_codes'],
                "defaultNominalCode": response.auto_complete_options['default_nominal'],
                "vatRate": response.auto_complete_options['vat_rates'],
                "taxItems": response.auto_complete_options['taxDetails'],
                "cis": response.auto_complete_options['CIS'],
                "categoryID": response.categoryID
            };

            $scope.updateSpreadsheet(hotId); // render and load spreadsheet

            $scope.show_spreadsheet = true;
            $scope.show_spreadsheets[hotId] = true;
            $scope.showHideSpreadsheet();

        });
    };

    $scope.updateSpreadsheet = function (hotId) {

        var instance = $scope.spreadsheetInstance[hotId];
        var responseObj = $scope.spreadsheetData[hotId];
        var screenName = $scope.spreadsheetPageId;
        $scope.default_tax_item_id = $scope.isHideVat ? null : $scope.default_tax_item_id;
        switch (screenName) {
            case "invoices" :
                if ($scope.active_option == 'category_breakdown') {
                    $scope.breakDownByCategory(hotId);
                } else if ($scope.active_option == 'full_breakdown') {
                    $scope.invoiceFullbreakdown(hotId);
                } else if ($scope.active_option == 'full_breakdown_by_category') {
                    $scope.invoiceFullbreakdownByCategory(hotId);
                }
                break;
            case "creditNote" :
                if ($scope.active_option == 'category_breakdown') {
                    $scope.breakDownByCategory(hotId);
                } else if ($scope.active_option == 'full_breakdown') {
                    $scope.fullbreakdown(hotId);
                } else if ($scope.active_option == 'full_breakdown_by_category') {
                    $scope.fullbreakdownByCategory(hotId);
                }
                break;
        }

        if(Object.keys($scope.spreadsheetInstance).length === _.reject($scope.invoiceItemCategories, function (r){ return r.chosen !== true;}).length || $scope.active_option !== 'full_breakdown_by_category'){
            // tax column handle
            var combinedTaxItems = _.pluck(_.flatten(_.values($scope.spreadsheetLineItems)), 'taxItemId');

            //set only once to find the actual invoice state
            if(!$scope.invoiceTaxState){
                $scope.invoiceTaxState = (_.max(combinedTaxItems) === null);
            }
            if($scope.invoiceTaxState){
                $scope.isHideVat = true;
                $scope.default_tax_item_id = null;
                $scope.defaultSchemaData['taxItemId'] = null;
                Object.keys($scope.spreadsheetInstance).forEach((hot) => {
                    hiddenColumns[hot].columns.push(5);
                });
            }
        }


        /*** Load the new settings into the spreadsheet and load them ***/
        instance.updateSettings({
            colHeaders: $scope.customHeader,
            fillHandle: {
                direction: 'vertical',
                autoInsertRow: true
            },
            rowHeaders: handsontable_settings['rowHeaders'],
            startRows: handsontable_settings['startRows'],
            startCols: responseObj['startCols'],
            colWidths: $scope.colWidths,
            columns: $scope.columnsParams,
            minSpareCols: 0,
            autoWrapRow: handsontable_settings['autoWrapRow'],
            data: $scope.spreadsheetLineItems[hotId],
            hiddenColumns: hiddenColumns[hotId],
            formulas: true,
            minSpareRows: handsontable_settings['minSpareRows'],
            dataSchema: $scope.defaultSchemaData,
            minRows: handsontable_settings['minRows'],
            comments: true,
            afterGetColHeader: $scope.requiredFields,
            beforeValidate: $scope.beforeValidate,
            currentColClassName: 'selectedCol',
            currentRowClassName: 'selectedRow',
            afterSelection: $scope.afterSelection,
            viewportRowRenderingOffset: 1000,
            stretchH: 'all'
        });
        /*dataSchema: $scope.dataSchema,*/

    };

    $scope.customHeader = function (index) {
        var hotId = this.hotId, check, header,prop;

        disableColHeader = '';
        prop             = this.columns[index]['data'] == 'unitprice' ? 'unitPrice' : this.columns[index]['data'];
        header           = $scope.spreadsheetData[hotId].colHeaders[index];
        check            = _.where($scope.customisable_spreadsheet_columns, {name: prop});
        if (check.length > 0 && check[0].selected) {
            header = header + '<span  tooltip="Column hidden from customer" tooltip-placement="top" class="ss-ban hidden-column"> </span>';
            disableColHeader = index;
        }
        return header;
    };

    $scope.customRender = function (instance, td, row, col, prop, value, cellProperties) {
        var cellCase = prop, selectedId, index;
        var hotId = instance.getSettings().hotId;
        var responseParam = $scope.spreadsheetData[hotId];

        switch (cellCase) {

            case 'item':
                for (index = 0; index < $scope.invoiceLineItemCategories.length; index++) {
                    if (value === $scope.invoiceLineItemCategories[index].id) {
                        $scope.spreadsheetLineItems[hotId][row].item =  value;
                        $scope.spreadsheetLineItems[hotId][row].itemId = $scope.invoiceLineItemCategories[index].itemId;
                    }
                }
                //# Display with tool tip
                var percentage  = (document.documentElement.clientWidth / 1366) * 100;
                var length = parseInt((30/100) * percentage);
                var toolTip = value;
                if((typeof toolTip != 'undefined') && (toolTip != '') && toolTip != null && (toolTip.length > length)) {
                    cellProperties.comment={value: toolTip};
                }
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'pricingItem':
                var selected = instance.getActiveEditor();
                for (index = 0; index < $scope.pricingItems.length; index++) {
                    pricingItem = $scope.pricingItems[index];
                    if (value === pricingItem.id) {
                        selectedId = pricingItem.id;
                        value = pricingItem.text;
                        if (selected && selected.row === row && selected.col === 1) {
                            $scope.spreadsheetLineItems[hotId][row].itemId = pricingItem.itemId;
                            $scope.spreadsheetLineItems[hotId][row].unitprice = pricingItem.unitPrice;

                            var taxItemId = (pricingItem.taxItemId) ? pricingItem.taxItemId.toString() : "1";
                            selectedTax = _.where($scope.listOfTaxItems, {id: taxItemId} );
                            if(!$scope.isHideVat && selectedTax.length != 0)
                            {
                                $scope.spreadsheetLineItems[hotId][row].taxItemId  = selectedTax[0]['id'];
                                $scope.spreadsheetLineItems[hotId][row].vatrate    = selectedTax[0]['effective_tax'];
                            }else
                            {
                                $scope.spreadsheetLineItems[hotId][row].taxItemId  = "1";
                                $scope.spreadsheetLineItems[hotId][row].vatrate    = 0;
                            }
                        }
                    }
                }
                //# Display with tool tip
                var percentage  = (document.documentElement.clientWidth / 1366) * 100;
                var length = parseInt((30/100) * percentage);
                var toolTip = value;
                if((typeof toolTip != 'undefined') && (toolTip != '') && toolTip != null && (toolTip.length > length)) {
                    cellProperties.comment={value: toolTip};
                }
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;

            case 'cis':
                var cisTotal;
                for (index = 0; index < responseParam.cis.length; index++) {
                    if (parseInt(value) === responseParam.cis[index].id) {
                        selectedId = responseParam.cis[index].id;
                        value = responseParam.cis[index].text;
                    }
                }
                if(value == 'Yes'){
                    cisTotal = roundFloat(($scope.spreadsheetLineItems[hotId][row].total * $scope.cisRate) / 100 ,2 );
                    $scope.spreadsheetLineItems[hotId][row].totalcis = cisTotal;
                }else{
                    $scope.spreadsheetLineItems[hotId][row].totalcis = 0;
                }
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;

            case 'taxItemId':
                var totalvat, effective_tax,total,cistotal;

                if(value){
                    value = (value) ? value.toString() : value;
                    var selectedTax = _.where($scope.listOfTaxItems, {id: value} );

                    if (selectedTax.length == 0) {
                        selectedTax = _.where($scope.listOfTaxItems, {id: $scope.default_tax_item_id} );
                    }

                    selectedId = selectedTax[0].id;
                    value = selectedTax[0].text;
                    effective_tax = selectedTax[0].effective_tax;
                } else {
                    effective_tax = 0;
                    selectedId = value;
                }

                /** calculate exc total*/
                if($scope.vatType == 'inc_vat'){
                    total = ($scope.spreadsheetLineItems[hotId][row].quantity * $scope.spreadsheetLineItems[hotId][row].unitprice * 100)/ (100 + parseFloat(effective_tax));
                }else{
                    total = $scope.spreadsheetLineItems[hotId][row].quantity * $scope.spreadsheetLineItems[hotId][row].unitprice;
                }
                $scope.spreadsheetLineItems[hotId][row].total = total = roundFloat(total,2);

                /** calculate total vat */
                if($scope.vatType == 'inc_vat'){
                    totalvat = ($scope.spreadsheetLineItems[hotId][row].quantity * $scope.spreadsheetLineItems[hotId][row].unitprice) - total;
                }else{
                    totalvat = (total * effective_tax)/100;
                }
                $scope.spreadsheetLineItems[hotId][row].vatrate = effective_tax;
                $scope.spreadsheetLineItems[hotId][row].totalvat = roundFloat(totalvat,2);
                $scope.spreadsheetLineItems[hotId][row].taxItemId = selectedId;

                /** calculate total cis */
                if ($scope.spreadsheetLineItems[hotId][row].cis == 'Yes') {
                    cistotal = roundFloat(((total / 100 ) * $scope.cisRate), 2);
                    $scope.spreadsheetLineItems[hotId][row].totalcis = cistotal;
                }

                $scope.breakdownTotal();
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;

            case 'nominalcode':
                for (index = 0; index < responseParam.nominalcode.length; index++) {
                    if (parseInt(value) === responseParam.nominalcode[index].id) {
                        selectedId = responseParam.nominalcode[index].id;
                        value = responseParam.nominalcode[index].text;
                    }
                }
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;

            case 'quantity':
                $scope.newQuantity = (value < 0 || isNaN(parseFloat(value))) ? 1 : parseFloat(value);
                $scope.spreadsheetLineItems[hotId][row].quantity = roundFloat($scope.newQuantity, 2);
                value = roundFloat($scope.newQuantity, 2);
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;

            case 'unitprice':
                var total,cistotal;
                value = (value == 0) ? "0.00" : roundFloat(value,2,"str");
                $scope.spreadsheetLineItems[hotId][row].unitprice = value;

                if($scope.isHideVat)
                {
                    if($scope.vatType == 'inc_vat'){
                        total = ($scope.spreadsheetLineItems[hotId][row].quantity * value * 100)/ (100 + parseFloat($scope.spreadsheetLineItems[hotId][row].vatrate));
                    }else {
                        total = $scope.spreadsheetLineItems[hotId][row].quantity * value;
                    }
                    $scope.spreadsheetLineItems[hotId][row].total = roundFloat(total,2);
                    if ($scope.spreadsheetLineItems[hotId][row].cis == 'Yes') {
                        cistotal = roundFloat((($scope.spreadsheetLineItems[hotId][row].total / 100 ) * $scope.cisRate), 2);
                        $scope.spreadsheetLineItems[hotId][row].totalcis = cistotal;
                    }
                }

                $scope.breakdownTotal();
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;

            case 'deleteAction':
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                var div = document.createElement('DIV');
                var a = document.createElement('a');
                var linkText = document.createTextNode("Delete");
                a.appendChild(linkText);
                a.title = "Delete Action";
                a.style = "cursor:pointer;";
                div.appendChild(a);
                $(td).empty().append(div).append(a);
                $(a).on('click', function (e) {
                    $scope.deleteAction(row, col, instance.getSettings().hotId, 'creditNote');
                });
                return td;
                break;
        }
    };

    $scope.spreadsheetAfterChange = function (changes, src) {
        spreadsheetId = this.getSettings().hotId;
        var current_instance = $scope.spreadsheetInstance[spreadsheetId] = this;
        var getSelect = this.getSelected();
        var selectedRow = (typeof  getSelect != 'undefined') ? getSelect[0] : 0;
        // Load an empty default data during
        var countRows = this.countRows();
        var allowInsertRow = true;
        if ($scope.active_option == "category_breakdown" && $scope.spreadsheetData.length > 0) {
            allowInsertRow = false;
        }

        if (src === 'loadData' && (allowInsertRow === true || $scope.spreadsheetLineItems[spreadsheetId].length === 0)) {
            this.alter('insert_row', countRows);
            $timeout(function () {
                $scope.scrollingSpreadsheet(spreadsheetId, current_instance);
            }, 1000);
            this.render();

        } else if (src === 'edit') {
            this.allowInsertRow = allowInsertRow;
            $scope.insertRow(this, changes, src);
        }
        $scope.spreadSheetValidation();
        this.scrollViewportTo(selectedRow - 1, 1);

    };

    // If edit the last row then need to insert a new row with default row
    $scope.insertRow = function (currentScope, changes, src) {
        var selected = currentScope.getActiveEditor(),
            selectedCol = selected.col,
            selectedRow = selected.row,
            rowVal = currentScope.getSourceDataAtCell(selectedRow, "1");
        var isRowValidate = !!(selectedCol == 1 && rowVal);
        if (changes != null && changes[0][0] === (currentScope.countRows() - 1) && src === 'edit' && isRowValidate && currentScope.allowInsertRow != false) {
            currentScope.alter('insert_row', currentScope.countRows());
            currentScope.render();
        }
    };

    //* Check the cell validation
    $scope.cellValidator = function (value, callback) {
        if (value != '') {
            callback(true);
        } else {
            callback(false);
        }
    };


    $scope.deleteAction = function (r, c, hotId) {
        var confirmation_message = 'This row has not been saved yet, are you sure you want to delete it?';
        var instance = $scope.spreadsheetInstance[hotId];
        if (c === (instance.getInstance().countCols() - 1)) {
            //# Before delete confirm
            confirmationBoxHelper.getConfirmation(confirmation_message, this)
                .then(function () {

                    //# Get the primary id of the edit screen, to delete from backend
                    var rowId = instance.getSourceDataAtRow(r);
                    if ((typeof rowId != 'undefined') && rowId.id) {
                        $scope.rowToDelete.push(rowId.id);
                    }

                    instance.alter('remove_row', r);
                    instance.render();
                    $scope.breakdownTotal();

                    //# Check if last row is not empty, then create an empty row.
                    var rowsCount = instance.countRows();
                    if (rowsCount == 0 || rowsCount == r) {
                        instance.alter('insert_row', rowsCount);
                        instance.render();
                    }
                    $scope.spreadSheetValidation();
                }, function () {
                    return false
                });

        }
    };

    $scope.spreadSheetValidation = function () {
        var spreadsheetData = $scope.spreadsheetLineItems;
        $scope.isSpreadsheetValidate = false;
        _.each(spreadsheetData, function (value, key) {
            if (spreadsheetData[key].length > 1) {
                $scope.isSpreadsheetValidate = true;
            }
        });
    };

    /** calculate overall breakdown total price, breakdown types wise price, vat price against vat breakdown types  */
    $scope.breakdownTotal = function () {

        var total_price = 0, total_tax = 0, total_cis = 0, i, total_vat_rate = 0, breakdown_by_vat_rates = {}, vat_cost;

        angular.forEach($scope.spreadsheetLineItems, function (value, key) {

            var sub_total_price = 0, totalCount = 0;
            for (i = 0; i < value.length; i++) {

                totalCount = ((typeof value[i] != "undefined" && value[i].item) || value[i].pricingItem) ? totalCount + 1 : totalCount;

                total_price += parseFloat(value[i].total);
                sub_total_price += parseFloat(value[i].total);
                total_tax += parseFloat(value[i].totalvat);
                total_cis += parseFloat(value[i].totalcis);

                if(value[i].taxItemId != 0 && (value[i].item || value[i].pricingItem))
                {
                    vat_cost = (typeof breakdown_by_vat_rates[value[i].vatrate] == 'undefined') ? 0 : breakdown_by_vat_rates[value[i].vatrate];
                    breakdown_by_vat_rates[value[i].vatrate]  = vat_cost +  parseFloat(value[i].totalvat) ;
                }
                total_vat_rate += (value[i].totalvat > 0) ? parseFloat(value[i].vatrate) : 0;                }
            $scope.breakdownSubTotal[key] = sub_total_price;
            $scope.totalCount[key] = totalCount;
        });

        $scope.total_price = roundFloat(total_price, 2);
        $scope.totalpriceExc = roundFloat(total_price, 2);
        $scope.total_tax = (roundFloat(total_tax, 2)) ? roundFloat(total_tax, 2) : 0;
        $scope.total_cis = (parseInt(total_price) || parseInt(total_price) != 0) ? roundFloat(total_cis, 2) : 0;

        $scope.total_vat_rate = roundFloat(total_vat_rate, 3);
        $scope.breakdown_by_vat_rates = breakdown_by_vat_rates;
    };

    $scope.getSubTotal = function (hotID) {
        return $scope.breakdownSubTotal[hotID];
    };

    /** update spreadsheet header cloumns for 'customer who can see' purpose **/
    $scope.$on('spreadsheet:updateHeaderSettings', function () {
        var hot_ids = Object.keys($scope.spreadsheetLineItems);
        angular.forEach(hot_ids, function (value) {
            var instance = $scope.spreadsheetInstance[value];
            instance.render();
        });
    });

    /** set category for full breakdown by category **/
    $scope.setCategoryID = function (categoryID) {
        $scope.spreadsheetCategoryId = categoryID;
    };

    /**  hide /show for full breakdown by category **/
    $scope.showHideSpreadsheet = function (categoryID) {
        if (typeof $scope.show_spreadsheets[categoryID] != 'undefined' && $scope.show_spreadsheets[categoryID]) {
            $scope.show_spreadsheets[categoryID] = true;
            return $scope.show_spreadsheets[categoryID];
        } else if (typeof  categoryID != 'undefined') {
            $scope.show_spreadsheets[categoryID] = false;
            return $scope.show_spreadsheets[categoryID];
        }

    };

    /** show or hide in spreadsheet **/
    $scope.handleCollapse = function handleCollapse(e, hotId) {
        var hotTableElement = angular.element.find("[hot-id='" + hotId + "']");
        if (angular.element(hotTableElement).hasClass('ng-hide')) {
            angular.element(hotTableElement).removeClass('ng-hide');
        } else {
            angular.element(hotTableElement).addClass('ng-hide');
        }
    };

    /** get total valid spreasheet column count **/
    $scope.spreadsheetRowCount = function (HotId) {
        var validLen = 0;
        if (typeof  $scope.spreadsheetLineItems[HotId] != 'undefined') {
            validLen = $scope.totalCount[HotId];
        }
        return validLen;
    };

    /** delete spreadsheet **/
    $scope.deleteSpreadsheetData = function (hotID, categoryID) {
        if (this.confirmText == "undefined" || this.confirmText.toLowerCase() != 'delete') {
            $scope.confirmDeleteValidity = true;
        } else {
            $scope.$emit('delete_spreadsheet', categoryID);
            var deletedSpreadsheet = $scope.spreadsheetLineItems[hotID];
            var deletedData = _.filter(deletedSpreadsheet, function (data) {
                if (data.id) {
                    $scope.rowToDelete.push(data.id);
                    return data.id;
                }
            });

            delete  $scope.spreadsheetLineItems[hotID];

            $scope.animate_delete_modal_overlay = false;
            $scope.confirmDeleteValidity = false;
            $scope.loading = false;
            $scope.closebutton = false;
            if ($scope.delete_row_index) {
                $('#delete-cost-modal-' + $scope.delete_row_index).modal('hide');
            } else {
                $('#delete-cost-modal').modal('hide');
            }

            $('#delete-invoice-item-category-' + categoryID).modal('hide');

            $scope.show_delete_modal_overlay = false;
            $scope.breakdownTotal();
            $scope.isdeleteSpreadsheet = !!((Object.keys($scope.spreadsheetLineItems)).length > 1);
        }
    };

    $scope.hideDeleteModalOverlay = function (itemCategory) {
        $scope.animate_delete_modal_overlay = false;
        this.$parent.confirmText = '';
        this.confirmText = '';
        $scope.confirmText = '';
        $scope.confirmDeleteValidity = false;
        $scope.loading = false;
        $scope.closebutton = false;

        $('#delete-invoice-item-category-' + itemCategory).modal('hide');
        $timeout(function () {
            $scope.show_delete_modal_overlay = false;
        }, 200);
    };

    /** update spreadsheet total depends on vat type  **/
    $scope.updateVatType = function () {
        if($scope.active_option == "no_breakdown"){
            $scope.calculateNoBreakdownGrandTotals();
        }else{
            angular.forEach($scope.spreadsheetLineItems ,function (value,key) {
                $scope.spreadsheetInstance[key].render();
            });
        }
    };

    $scope.updateCustomerSpreadsheet = function (datas) {
        var hotId, i,
            activeOption = $scope.active_option,
            pageId = $scope.spreadsheetPageId;
        var defaultNominalId = (typeof $scope.firstNominalCode != "undefined") ? $scope.firstNominalCode : "";
        angular.forEach(datas, function (value, key) {
            hotId = value.hotId;

            var dataSchema = {
                id: null,
                cis: 'No',
                quantity: value.quantity,
                unitprice: value.unit_price,
                taxItemId: value.taxItemId,
                vatrate: value.vatRate,
                nominalcode: defaultNominalId,
                total: 0.00,
                totalvat: 0.00,
                totalcis: 0.00,
                categoryid: value.categoryId,
                itemId: "",
                deleteAction: ""
            };

            if (activeOption == "category_breakdown") {
                var invoiceItem = _.where($scope.invoiceItemCategories, {spreadsheet_categoryId: value.categoryId});
                dataSchema.item = invoiceItem[0].spreadsheet_category;

                var spareadsheetData = $scope.spreadsheetLineItems[hotId];
                var categoryBreakdown = _.where(spareadsheetData, {item: dataSchema.item});
                if ((categoryBreakdown).length > 0) {
                    var lineItemData = categoryBreakdown[0];
                    lineItemData.unitprice = parseFloat(lineItemData.unitprice) + parseFloat(dataSchema.unitprice);
                    var vat = _.max(spareadsheetData, function (data) {
                        return data.vatrate;

                    });
                    lineItemData.vatrate = vat.vatrate;
                } else {
                    i = $scope.spreadsheetLineItems[hotId].length - 1;
                    $scope.spreadsheetLineItems[hotId].splice(i, 0, dataSchema);
                }
            } else {
                if (pageId === "invoices") {
                    dataSchema.pricingItem = value.description;
                } else {
                    dataSchema.item = value.description;
                }
                i = $scope.spreadsheetLineItems[hotId].length - 1;
                $scope.spreadsheetLineItems[hotId].splice(i, 0, dataSchema);
            }
        });

        $scope.isSpreadsheetValidate = true;
        angular.forEach($scope.spreadsheetLineItems, function (value, key) {
            $scope.spreadsheetInstance[key].render();
        });
    }

    $scope.requiredFields = function (col, TH) {

        var className = '';
        if (disableColHeader) {
            className = "disable-col-header ";
        }
        if (requiredFields[col]) {
            className += "col_header_bg";
        }

        if (className && TH) {
            TH.className = className;
        }
    };

    /*** Check the valid html tag ***/
    $scope.strip_tags = function (input, allowed) {
        var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
            commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

        // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
        allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

        return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });
    };

    $scope.tabMoves = function (event) {
        if (event.shiftKey) {
            return {row: 2, col: 1};
        }
        else {
            return {row: 0, col: 1};
        }
    };

    $scope.emptyCellValidator = function (value, callback) {
        if (value === '') {
            toastBox.show('Empty cell not allowed', 1000);
        }
        callback(true);
    };

    $scope.numericLimitValidator = function (value, row, col, prop, limit, cellType, hotId) {
        if (value != '') {
            var isInvalid = false;
            var data = parseFloat(value);
            if (isNaN(value)) {
                toastBox.show('Invalid data', 1000);
            } else if (limit !== '' && ((roundFloat(data, 0,'str')).length) > limit) {
                isInvalid = true;
                toastBox.show('Invalid data length', 1000);
            }

            if (isInvalid) {
                $scope.spreadsheetInstance[hotId].setCellMeta(row, col, "className", "errorRowList");
            } else {
                $scope.spreadsheetInstance[hotId].setCellMeta(row, col, "className", "");
            }
            $scope.spreadsheetInstance[hotId].render();
        }
    };

    $scope.beforeValidate = function (value, row, prop, source) {
        if (value != '') {
            var activeEditor = this.getActiveEditor(),
                col = activeEditor.col,
                removeHighlightRow = [],
                hotId = this.getSettings().hotId,
                cellType = activeEditor.cellProperties.type;
            if (validateErrorRow === true) {
                removeHighlightRow[0] = {"row": row, "hotId": hotId};
                $scope.highlightErrorRows(removeHighlightRow, [hotId], false);
            }
            if (prop == 'unitprice') {
                $scope.numericLimitValidator(value, row, col, prop, 10, cellType, hotId);
            } else if (prop == 'quantity') {
                $scope.numericLimitValidator(value, row, col, prop, '', cellType, hotId);
            }
        }
    }

    $scope.highlightErrorRows = function (errorRow, hotId, isHighlight) {
        validateErrorRow = true;
        for (var i = 0; i < errorRow.length; i++) {
            var currentRow = errorRow[i]["row"];
            var currentRowHotId = errorRow[i]["hotId"];
            var currentInstance = $scope.spreadsheetInstance[currentRowHotId];
            var currentMetaRow = currentInstance.getCellMetaAtRow(currentRow);
            for (var j = 1; j < currentMetaRow.length; j++) {
                if (isHighlight === true) {
                    currentInstance.setCellMeta(currentRow, j, "className", "errorRowList");
                } else {
                    currentInstance.setCellMeta(currentRow, j, "className", "");
                }
            }
        }
        _.each(hotId, function (value) {
            $scope.spreadsheetInstance[value].render();
        });
    }

    $scope.readOnlyCol = function (dataSchema, hotId) {
        var col = hiddenColumns[hotId].columns;
        _.each(col, function (value, key) {
            dataSchema[value].readOnly = true;

        });
        return dataSchema;
    };

    $scope.afterSelection = function (r, c, r2, c2, preventScrolling) {
        var instanceSettings = this.getSettings();
        if (c == 1) {
            instanceSettings.fillHandle = false;
        } else {
            instanceSettings.fillHandle = {direction: 'vertical', autoInsertRow: true};
        }
    };
    
    $scope.updateStateProvinceTaxRates = function (taxItems,isUpdateLintItem) {
        $scope.getVatGroupByProvinces(taxItems);

        if(isUpdateLintItem)
        {
            angular.forEach($scope.spreadsheetLineItems, function (value,key) {
                angular.forEach(value,function (v,k) {
                    v.taxItemId = 1;
                })
            });
        }

        angular.forEach($scope.spreadsheetLineItems ,function (value,key) {

            switch ($scope.active_option) {
                case "category_breakdown" :
                    $scope.breakDownByCategory(key);
                    break;
                case "full_breakdown" :
                    $scope.invoiceFullbreakdown(key);
                    break;
                case "full_breakdown_by_category" :
                    $scope.invoiceFullbreakdownByCategory(key);
                    break;
            }
            $scope.spreadsheetInstance[key].updateSettings({columns: $scope.columnsParams});
            $scope.spreadsheetInstance[key].render();
        });
    }
}

function getSpreadsheetDefaultSchema($scope,prefix, $http) {
    $scope.breakDownByCategory = function(hotId) {
        var params = $scope.spreadsheetData[hotId];
        $scope.defaultSchemaData = {
            id: null, item: '', cis: 'No', quantity: 1, unitprice: "0.00",taxItemId: $scope.default_tax_item_id ,vatrate: 0,
            nominalcode: params.defaultNominalCode,total: 0.00, totalvat: 0.00, totalcis: 0.00,
            categoryid : null,itemId:"" , deleteAction: ""}; //* Load the default value when new row created

        /** Assign the spreadsheet columns and its cell type **/
        $scope.columnsParams = [
            {data: 'id', type: 'numeric', readOnly: true},
            {renderer: $scope.customRender, data: 'item', editor: 'select2', placeholder: 'Please select', allowEmpty: false,
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    minimumInputLength:2,
                    ajax: { url: prefix + '/getInvoiceCategory',
                        data: function (query, page,partIds) {
                            if(query == '') {
                                return false;
                            }
                            var spreadsheetRecord = $scope.spreadsheetLineItems[hotId];
                            partIds = _.pluck(spreadsheetRecord,"itemId");
                            // This is for select2 js
                            if($(this.val())) {
                                var selected_data = _.findWhere(spreadsheetRecord,{"item":$(this).val()});
                                partIds = _.without(partIds,selected_data['itemId']);
                            }
                            return { 'searchText': encodeURIComponent(query), 'selectedInvoiceCategoryId': partIds};
                        },
                        results: function (data, page) {
                            $scope.invoiceLineItemCategories = data.invoiceCategories;
                            return { results: data.invoiceCategories };
                        }
                    },
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    dropdownCssClass: "handsontable-select"
                }
            },
            {renderer: $scope.customRender, data: 'cis', editor: 'select2', placeholder: 'Please select',
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    data: params.cis,
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    minimumResultsForSearch: Infinity,
                    dropdownCssClass: "handsontable-select"
                }
            },
            {data: 'quantity', type: 'numeric', readOnly: true, validator:$scope.emptyCellValidator},
            {renderer: $scope.customRender, data: 'unitprice', type: 'numeric', allowInvalid: true,  validator:$scope.emptyCellValidator},
            {renderer: $scope.customRender, data: 'taxItemId', editor: 'select2', placeholder: 'Please select',
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    data: $scope.taxItemsGroupByProvinces,
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    dropdownCssClass: "handsontable-select"
                }
            },
            {data: 'vatrate', type: 'numeric', readOnly: true, format: '0.00'},
            {renderer: $scope.customRender, data: 'nominalcode', editor: 'select2', placeholder: 'Please select',
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    data: params.nominalcode,
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    dropdownCssClass: "handsontable-select"
                }
            },
            {data: 'total', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
            {data: 'totalvat', type: 'numeric', readOnly: true, format: '0.00'},
            {data: 'totalcis', type: 'numeric', readOnly: true, format: '0.00'},
            {data: 'categoryid', type: 'numeric', readOnly: true},
            {data: 'unitPrice_exc', type: 'numeric', readOnly: true,format: '0.00'},
            {data: 'itemId', type: 'numeric', readOnly: true,format: '0'},
            {renderer: $scope.customRender, data: 'deleteAction', readOnly: true, disableVisualSelection: true}];

        $scope.readOnlyCol($scope.columnsParams,hotId);
        return true;

    };

    $scope.fullbreakdown = function(hotId) {
        var params = $scope.spreadsheetData[hotId];
        $scope.defaultSchemaData = {id: null, item: '', cis: 'No', quantity: 1, unitprice: "0.00",
            taxItemId: $scope.default_tax_item_id ,vatrate: 0, nominalcode: params.defaultNominalCode, total: 0.00,
            totalvat: 0.00, totalcis: 0.00, categoryid : null, itemId:"", deleteAction: ""}; //* Load the default value when new row created

        /** Assign the spreadsheet columns and its cell type **/
        $scope.columnsParams = [{data: 'id', type: 'numeric', readOnly: true},
            {data: 'item', type: 'text', allowEmpty : false, validator:$scope.emptyCellValidator},
            {renderer: $scope.customRender, data: 'cis', editor: 'select2', placeholder: 'Please select',
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    data: params.cis,
                    dropdownAutoWidth: false,
                    minimumResultsForSearch: Infinity,
                    width: 'resolve'
                }
            },
            {renderer: $scope.customRender, data: 'quantity', type: 'numeric',  allowInvalid: true,  validator:$scope.emptyCellValidator},
            {renderer: $scope.customRender, data: 'unitprice', type: 'numeric', allowInvalid: true,  validator:$scope.emptyCellValidator},
            {renderer: $scope.customRender, data: 'taxItemId', editor: 'select2', placeholder: 'Please select',
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    data: $scope.taxItemsGroupByProvinces,
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    dropdownCssClass: "handsontable-select"
                }
            },
            {data: 'vatrate', type: 'numeric', readOnly: true, format: '0.00'},
            {renderer: $scope.customRender, data: 'nominalcode', editor: 'select2', placeholder: 'Please select', allowEmpty: false,
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    data: params.nominalcode,
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    dropdownCssClass: "handsontable-select"
                }
            },
            {data: 'total', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
            {data: 'totalvat', type: 'numeric', readOnly: true, format: '0.00'},
            {data: 'totalcis', type: 'numeric', readOnly: true, format: '0.00'},
            {data: 'categoryid', type: 'numeric', readOnly: true},
            {data: 'unitPrice_exc', type: 'numeric', readOnly: true,format: '0.00'},
            {data: 'itemId', type: 'numeric', readOnly: true,format: '0'},
            {renderer: $scope.customRender, data: 'deleteAction', readOnly: true, disableVisualSelection: true}];
        $scope.readOnlyCol($scope.columnsParams,hotId);
        return true;

    };

    $scope.invoiceFullbreakdown = function(hotId) {
        var params = $scope.spreadsheetData[hotId];
        $scope.defaultSchemaData = {id: null, pricingItem: '', cis: 'No', quantity: 1, unitprice: "0.00",
            taxItemId: $scope.default_tax_item_id ,vatrate: 0, nominalcode: params.defaultNominalCode, total: 0.00,
            totalvat: 0.00, totalcis: 0.00, categoryid : null, itemId:"" , deleteAction: ""}; //* Load the default value when new row created

        /** Assign the spreadsheet columns and its cell type **/
        $scope.columnsParams = [{data: 'id', type: 'numeric', readOnly: true},
            {renderer: $scope.customRender, data: 'pricingItem', editor: 'select2', placeholder: 'Please Enter', allowEmpty: false,
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    minimumInputLength:2,
                    ajax: { url: prefix + '/getPricingItems',
                        data: function (query, page) {
                            if(query == '') {
                                return false;
                            }
                            var spreadsheetRecord = $scope.spreadsheetLineItems[hotId];
                            return { 'searchText': encodeURIComponent(query), 'jobId': $scope.jobId};
                        },
                        results: function (data, page) {
                            $scope.pricingItems = angular.copy(data.pricingItems);
                            return { results: data.pricingItems };
                        }
                    },
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    dropdownCssClass: "handsontable-select",
                    createSearchChoice:function (term,data) {
                        if ( $(data).filter( function() {
                                return this.text.localeCompare(term)===0;
                            }).length===0) {
                            return {id:term, text:term};
                        }
                    }
                }
            },
            {renderer: $scope.customRender, data: 'cis', editor: 'select2', placeholder: 'Please select',
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    data: params.cis,
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    minimumResultsForSearch: Infinity,
                    dropdownCssClass: "handsontable-select"
                }
            },
            {renderer: $scope.customRender, data: 'quantity', type: 'numeric', allowInvalid: true, validator:$scope.emptyCellValidator},
            {renderer: $scope.customRender, data: 'unitprice', type: 'numeric', allowInvalid: true, validator:$scope.emptyCellValidator},
            {renderer: $scope.customRender, data: 'taxItemId', editor: 'select2', placeholder: 'Please select',
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    data: $scope.taxItemsGroupByProvinces,
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    dropdownCssClass: "handsontable-select"
                }
            },
            {data: 'vatrate', type: 'numeric', readOnly: true, format: '0.00'},
            {renderer: $scope.customRender, data: 'nominalcode', editor: 'select2', placeholder: 'Please select', allowEmpty: false,
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    data: params.nominalcode,
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    dropdownCssClass: "handsontable-select"
                }
            },
            {data: 'total', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
            {data: 'totalvat', type: 'numeric', readOnly: true, format: '0.00'},
            {data: 'totalcis', type: 'numeric', readOnly: true, format: '0.00'},
            {data: 'categoryid', type: 'numeric', readOnly: true},
            {data: 'unitPrice_exc', type: 'numeric', readOnly: true,format: '0.00'},
            {data: 'itemId', type: 'numeric', readOnly: true,format: '0'},
            {renderer: $scope.customRender, data: 'deleteAction', readOnly: true, disableVisualSelection: true}];
        $scope.readOnlyCol($scope.columnsParams,hotId);
        return true;

    };

    $scope.fullbreakdownByCategory = function(hotId) {
        var params = $scope.spreadsheetData[hotId];
        $scope.defaultSchemaData = {id: null, item: '', cis: 'No', quantity: 1, unitprice: "0.00",
            taxItemId: $scope.default_tax_item_id ,vatrate: 0,
            nominalcode: params.defaultNominalCode, total: 0.00, totalvat: 0.00, totalcis: 0.00,
            categoryid : params.categoryID, itemId:""  ,deleteAction: ""}; //* Load the default value when new row created
        /** Assign the spreadsheet columns and its cell type **/
        $scope.columnsParams = [
            {data: 'id', type: 'numeric', readOnly: true},
            {data: 'item', type: 'text', allowEmpty:false,validator:$scope.emptyCellValidator},
            {renderer: $scope.customRender, data: 'cis', editor: 'select2',
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                placeholder: 'Please select',
                select2Options: {
                    editable: true,
                    data: params.cis,
                    dropdownAutoWidth: false,
                    dropdownCssClass: "handsontable-select",
                    minimumResultsForSearch: Infinity,
                    width: 'resolve' }
            },
            {renderer: $scope.customRender, data: 'quantity', type: 'numeric', allowInvalid: true, validator:$scope.emptyCellValidator},
            {renderer: $scope.customRender, data: 'unitprice', type: 'numeric', allowInvalid: true, validator:$scope.emptyCellValidator},
            {renderer: $scope.customRender, data: 'taxItemId', editor: 'select2', placeholder: 'Please select',
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    data: $scope.taxItemsGroupByProvinces,
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    dropdownCssClass: "handsontable-select"
                }
            },
            {data: 'vatrate', type: 'numeric', readOnly: true, format: '0.00'},
            {renderer: $scope.customRender, data: 'nominalcode', editor: 'select2', placeholder: 'Please select',
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    data: params.nominalcode,
                    dropdownCssClass: "handsontable-select",
                    dropdownAutoWidth: false,
                    width: 'resolve'}
            },
            {data: 'total', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
            {data: 'totalvat', type: 'numeric', readOnly: true, format: '0.00'},
            {data: 'totalcis', type: 'numeric', readOnly: true, format: '0.00'},
            {data: 'categoryid', type: 'numeric', readOnly: true},
            {data: 'unitPrice_exc', type: 'numeric', readOnly: true,format: '0.00'},
            {data: 'itemId', type: 'numeric', readOnly: true,format: '0'},
            {renderer: $scope.customRender, data: 'deleteAction', readOnly: true, disableVisualSelection: true}];
        $scope.readOnlyCol($scope.columnsParams,hotId);
        return true;

    };

    $scope.invoiceFullbreakdownByCategory = function(hotId) {
        var params = $scope.spreadsheetData[hotId];
        $scope.defaultSchemaData = {id: null, pricingItem: '', cis: 'No', quantity: 1, unitprice: "0.00",  taxItemId: $scope.default_tax_item_id ,vatrate: 0,
            nominalcode: params.defaultNominalCode, total: 0.00, totalvat: 0.00, totalcis: 0.00, categoryid : params.categoryID,
            itemId:"",deleteAction: "" }; //* Load the default value when new row created

        /** Assign the spreadsheet columns and its cell type **/
        $scope.columnsParams = [
            {data: 'id', type: 'numeric', readOnly: true},
            {renderer: $scope.customRender, data: 'pricingItem',
                validator:$scope.emptyCellValidator,
                editor: 'select2', placeholder: 'Please Enter',
                className: 'overflow_handsontable select_icon',
                allowEmpty:true,
                select2Options: {
                    editable: true,
                    minimumInputLength:2,
                    ajax: { url: prefix + '/getPricingItems',
                        data: function (query, page) {
                            if(query == '') {
                                return false;
                            }
                            var spreadsheetRecord = $scope.spreadsheetLineItems[hotId];
                            return { 'searchText': encodeURIComponent(query), 'jobId': $scope.jobId};
                        },
                        results: function (data, page) {
                            $scope.pricingItems = angular.copy(data.pricingItems);
                            return { results: data.pricingItems };
                        }
                    },
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    dropdownCssClass: "handsontable-select",
                    createSearchChoice:function (term,data) {
                        if ( $(data).filter( function() {
                                return this.text.localeCompare(term)===0;
                            }).length===0) {
                            return {id:term, text:term};
                        }
                    }
                }
            },
            {renderer: $scope.customRender, data: 'cis',
                editor: 'select2', placeholder: 'Please select',
                validator:$scope.emptyCellValidator,
                className: 'overflow_handsontable select_icon',
                select2Options: {
                    editable: true,
                    data: params.cis,
                    dropdownAutoWidth: false,
                    minimumResultsForSearch: Infinity,
                    dropdownCssClass: "handsontable-select",
                    width: 'resolve'
                }
            },
            {renderer: $scope.customRender, data: 'quantity',
                validator:$scope.emptyCellValidator,
                type: 'numeric', allowInvalid: false,
                forceNumeric: true},
            {renderer: $scope.customRender, data: 'unitprice',
                validator:$scope.emptyCellValidator,
                type: 'numeric'},
            {renderer: $scope.customRender, data: 'taxItemId', editor: 'select2', placeholder: 'Please select',
                className: 'overflow_handsontable select_icon',
                validator:$scope.emptyCellValidator,
                select2Options: {
                    editable: true,
                    data: $scope.taxItemsGroupByProvinces,
                    dropdownAutoWidth: false,
                    width: 'resolve',
                    dropdownCssClass: "handsontable-select"
                }
            },
            {data: 'vatrate', type: 'numeric', readOnly: true, format: '0.00'},
            {renderer: $scope.customRender, data: 'nominalcode',
                validator:$scope.emptyCellValidator,
                editor: 'select2', placeholder: 'Please select',
                className: 'overflow_handsontable select_icon',
                select2Options: {
                    editable: true,
                    data: params.nominalcode,
                    dropdownAutoWidth: false,
                    dropdownCssClass: "handsontable-select",
                    width: 'resolve' }
            },
            {data: 'total', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
            {data: 'totalvat', type: 'numeric', readOnly: true, format: '0.00'},
            {data: 'totalcis', type: 'numeric', readOnly: true, format: '0.00'},
            {data: 'categoryid', type: 'numeric', readOnly: true},
            {data: 'unitPrice_exc', type: 'numeric', readOnly: true,format: '0.00'},
            {data: 'itemId', type: 'numeric', readOnly: true,format: '0'},
            {renderer: $scope.customRender, data: 'deleteAction', readOnly: true, disableVisualSelection: true}];


        $scope.readOnlyCol($scope.columnsParams,hotId);
        return true;

    };
}

function internalizationTaxSettings($scope) {
    $scope.getVatGroupByProvinces = function (taxItems,existsTaxItemLists) {
        for (var i = 0; i < taxItems.length; i++) {
            $scope.listOfTaxItems[i] = {
                'id': String(taxItems[i]['id']),
                'text': taxItems[i]['text'],
                'effective_tax': taxItems[i]['effective_tax']
            };
        }
        $scope.default_tax_item_id = ($scope.listOfTaxItems.length > 0 && !$scope.isHideVat) ? $scope.listOfTaxItems[0].id : "1";

        var a = [];
        for (var j = 0; j < taxItems.length; j++) {
            if (taxItems[j]['deleted']) { continue; }
            if (typeof a[taxItems[j]['taxProvinceId']] == 'undefined') {
                a[taxItems[j]['taxProvinceId']] = {
                    "text": taxItems[j]['taxProvinceName'],
                    "children": [
                        {
                            "id": taxItems[j]['id'],
                            "text": taxItems[j]['text']
                        }
                    ]
                }
            } else {
                a[taxItems[j]['taxProvinceId']].children.push({
                    "id": taxItems[j]['id'],
                    "text": taxItems[j]['text']
                });
            }
        }

        $scope.taxItemsGroupByProvinces = {
            "results": Object.values(a),
            "paginate": {
                "more": true
            }

        };

        // For only in edit screen
        if(existsTaxItemLists)
        {
            var taxItemLists = [];
              _.each(existsTaxItemLists,function (value,key) {
                value.id =  (value.id).toString();
                taxItemLists.push(value);
            });
            $scope.listOfTaxItems = _.uniq(_.union($scope.listOfTaxItems,taxItemLists),false,_.property('id') ) ;
        }
    };
}
