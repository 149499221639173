'use strict';

/* Controllers */
function MainCtrl($scope, datasets) {
    $scope.data = datasets;
}

function ShortCutsFunctions($scope, $rootScope) {

    $scope.selectRow = function (arg, args, selectedId, index) {
        $rootScope.$broadcast('smarttable:rowclicked', {'index': index});
        $rootScope.$broadcast('smarttable:filepreview', {file: arg});

        $scope.selectedRow = arg.id;
        $scope.recordArgs = args;
        var selectedObj = {rowObject: arg, recordId: selectedId};
        $rootScope.selection = selectedObj;
        if($scope.active_rows == true){
            $rootScope.selection = selectedObj + {activeRow: $scope.active_rows};
        }
    }

    $scope.changeRows = function (event) {
        var changed = false;
        angular.forEach($scope.recordArgs, function (value, index) {
            if (!changed) {
                if ($scope.recordArgs[index].id == $scope.selectedRow) {
                    var args = '';
                    if (event == 'up') {
                        if (index != 0 && $scope.recordArgs[index - 1]) {
                            args = $scope.recordArgs[index - 1];
                        } else {
                            var length = $scope.recordArgs.length;
                            args = $scope.recordArgs[length - 1];
                        }
                    } else {
                        if ($scope.recordArgs[index + 1]) {
                            args = $scope.recordArgs[index + 1];
                        } else {
                            args = $scope.recordArgs[0];
                        }
                    }
                    $scope.selectedRow = args.id;
                    $rootScope.selection = {rowObject: args};
                    changed = true;

                    // This condition only for preview the file in right side. When move up or down using shortcuts the preview will change.
                    if ($scope.enablePreviewFiles != '') {
                        $scope.$broadcast($scope.enablePreviewFiles, "preview", angular.toJson(args));
                    }
                }
            }
        });
    }

    $rootScope.$on('event:moveUp', function () {
        $scope.changeRows('up');
    });

    $rootScope.$on('event:moveDown', function () {
        $scope.changeRows('down');
    });

    $rootScope.$on('event:escape', function () {
        $scope.selectedRow = "";
        $scope.enablePreviewFiles = '';
        $scope.fileSelected = false;
    });
}

function ShortCutsResponse($rootScope) {
    this.$broadcast($rootScope.screen, $rootScope.action, $rootScope.parameters);
}

// Function for download any type of files
function DownloadFunction($scope, fileUrl, fileName) {

    //Creating new link node.
    var link = document.createElement('a');
    link.href = fileUrl;

    if (link.download !== undefined) {
        //Set HTML5 download attribute. This will prevent file from opening if supported.
        link.download = fileName;
    }

    //Dispatching click event.
    if (document.createEvent) {
        var e = document.createEvent('MouseEvents');
        e.initEvent('click', true, true);
        link.dispatchEvent(e);
        return true;
    }

    // Force file download (whether supported by server).
    var query = '?download';

    window.open(sUrl + query);
}

// Function for print any type of files
function PrintPDFFunction($scope, fileUrl, fileName) {

    //Creating new link node.
    var link = document.createElement('a');
    link.href = fileUrl;

    if (link.href !== undefined) {
        //Set HTML5 download attribute. This will prevent file from opening if supported.
        link.href = fileUrl;
        link.target = "_blank";
    }

    //Dispatching click event.
    if (document.createEvent) {
        var e = document.createEvent('MouseEvents');
        e.initEvent('click', true, true);
        link.dispatchEvent(e);
        return true;
    }
    window.open(sUrl);
}

/* Function to take care of just added/updated changes in all controllers */
function JustAddedUpdatedChanges($scope, object, $state, defaultPagingValue, scopeName) {
    $scope.updateopts = {
        'duration': 5000,
        'start': function () {
            var scope = angular.element(this).scope();
            if (scope) {
                scope.justUpdated = false;
                if (scope[object] && scope[object]['justupdated']) {
                    angular.element(this).parent().css('background-color', '#fff6b9');
                    scope.justUpdated = true;
                    scope[object]['justupdated'] = false;

                    /* After Just Edited to show unconfirmed label in setup email screen */
                    window.setTimeout(function () {
                        $scope.$apply(function () {
                            angular.element('.fadeIn').fadeIn(5e3);
                        });
                    }, 5000);
                }
            }
        },
        'progress': function (animation, progress, remainingMS) {
            var opacity = '';
            if (remainingMS > 4500) {
                opacity = 0.9;
            } else if (remainingMS > 4000) {
                opacity = 0.8;
            } else if (remainingMS > 3500) {
                opacity = 0.7;
            } else if (remainingMS > 3000) {
                opacity = 0.6;
            } else if (remainingMS > 2500) {
                opacity = 0.5;
            } else if (remainingMS > 2000) {
                opacity = 0.4;
            } else if (remainingMS > 1500) {
                opacity = 0.3;
            } else if (remainingMS > 1000) {
                opacity = 0.2;
            } else if (remainingMS > 500) {
                opacity = 0.1;
            } else if (remainingMS > 10) {
                opacity = 0.05;
            } else {
                opacity = 0;
            }
            var scope = angular.element(this).scope();
            if (typeof(scope) != 'undefined' && typeof(scope.justUpdated) != 'undefined' && scope.justUpdated) {
                angular.element(this).parent().css('background-color', 'rgba(255, 246, 185, ' + opacity + ')');
            }
        },
        'complete': function () {
            var scope = angular.element(this).scope();
            if (scope && scope[object] && scope[object]['justupdated']) {

                angular.element(this).parent().css('background-color', '#ffffff');
                scope[object]['justupdated'] = false;
            }
        }
    }
    $scope.addopts = {
        'duration': 5000,
        'start': function () {
            var scope = angular.element(this).scope();
            if (scope) {
                scope.justAdded = false;
                if (scope && scope[object] && scope[object]['justadded']) {
                    angular.element(this).parent().css('background-color', '#fff6b9');
                    scope.justAdded = true;
                    scope[object]['justadded'] = false;

                    /* After Just Added to show unconfirmed label in setup email screen */
                    window.setTimeout(function () {
                        $scope.$apply(function () {
                            angular.element('.fadeIn').fadeIn(5e3);
                        });
                    }, 5000);
                }
            }
        },
        'progress': function (animation, progress, remainingMS) {
            var opacity = '';
            if (remainingMS > 4500) {
                opacity = 0.9;
            } else if (remainingMS > 4000) {
                opacity = 0.8;
            } else if (remainingMS > 3500) {
                opacity = 0.7;
            } else if (remainingMS > 3000) {
                opacity = 0.6;
            } else if (remainingMS > 2500) {
                opacity = 0.5;
            } else if (remainingMS > 2000) {
                opacity = 0.4;
            } else if (remainingMS > 1500) {
                opacity = 0.3;
            } else if (remainingMS > 1000) {
                opacity = 0.2;
            } else if (remainingMS > 500) {
                opacity = 0.1;
            } else if (remainingMS > 10) {
                opacity = 0.05;
            } else {
                opacity = 0;
            }
            var scope = angular.element(this).scope();
            if (typeof(scope) != 'undefined' && typeof(scope.justAdded) != 'undefined' && scope.justAdded) {
                angular.element(this).parent().css('background-color', 'rgba(255, 246, 185, ' + opacity + ')');
            }
        },
        'complete': function () {
            var scope = angular.element(this).scope();
            if (scope && scope[object] && scope[object]['justadded']) {
                angular.element(this).parent().css('background-color', '#ffffff');
                scope[object]['justadded'] = false;
            }
        }
    }

    $scope.addJustAddedUpdatedTag = function (id, method) {
        angular.forEach($scope[scopeName], function (value, key) {
            if (value.id == id)
                value['' + method + ''] = true;
        });
    }

    if ($state && $state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state && $state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    $scope.triggerJustAddedUpdatedTags = function () {

        if ($state.current.data.editedId != -1) { // Default Value for editedId is -1, reset after performing operation
            $scope.addJustAddedUpdatedTag(parseInt($state.current.data.editedId), 'justupdated');
            $state.current.data.editedId = -1;    // Reset the State
        }

        if ($state.current.data.addedId != -1) {
            $scope.addJustAddedUpdatedTag(parseInt($state.current.data.addedId), 'justadded');
            $state.current.data.addedId = -1;
        }
    };
    angular.forEach(angular.element('.just-added'), function (elem, key) {
        angular.element(elem).fadeOut(5e3);
    });
}

function GetCurrentPage($scope, $state, resolver, message) {
    $state.$current.data.addedId = message.id;
    var count = parseInt(resolver.count) + 1;
    $scope.currentNewPage = (count % $scope.limit == 0) ? Math.floor(count / $scope.limit) : (Math.floor(count / $scope.limit) + 1);
}

csmodule.controller('emailWidgetController', function($http, prefix, $scope, $timeout, $rootScope, $state, $filter, $q, emailWidget, confirmationBoxHelper, emailService, toastBox, warningModal) {

    $scope.tinymceSmsOptions = {
        menubar: false,
        toolbar: false,
        statusbar: false,
        content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
        plugins: "paste,autolink",
        paste_as_text: true,
        browser_spellcheck : true,
        paste_block_drop: false,
        placeholder: "Message",
        relative_urls : false,
        remove_script_host : false,
        convert_urls : true
    };

    $scope.footerMessage = '';
    $scope.email_file_size_limit = 16;
    $scope.attached_files_view_limit = 3;
    /*==========================================================================================
     Initialize email widget
     ==========================================================================================*/
    $scope.resetEmailWidget = function resetEmailWidget() {
        if ($scope.available_attachments) {
            for(var i = 0, l = $scope.available_attachments.length; i < l; i++) {
                $scope.available_attachments[i].attached = false;
                $scope.available_attachments[i].selected = false;
            }
        }

        $scope.total_file_size = 0;
        $scope.show_email_widget = false;
        $rootScope.show_email_overlay = $scope.show_email_widget;
        $rootScope.show_email_widget = $scope.show_email_widget;
        $scope.email_widget_open = false;
        $scope.email_attachments_open = false;
        $scope.email_attached_files_open = false;
        $scope.available_attachments = [];
        $scope.selected_attachments = [];
        $scope.attached_files = [];
        $scope.email = emailService.generateBlankEmailData();
        $scope.email.selected_recipient_address = null;
        $scope.email.selected_from_address = null;
        $scope.email.ccs = [];
        $scope.recipientDropdownOpen = false;
        $scope.fromDropdownOpen = false;
    };

    $scope.resetEmailWidget();
    $scope.available_attachments = emailWidget.getAvailableAttachments();
    $scope.email = emailWidget.getEmailData();

    /*==========================================================================================
     Initiate an email
     ==========================================================================================*/
    var off = $rootScope.$on('email:createNew', function(ev, email_data) {
        if($scope.show_email_widget === true) {
            $scope.discardEmail(false).then(function() {
                $scope.resetEmailWidget();
                $scope.createEmail(email_data);
                $rootScope.show_email_overlay = $scope.show_email_widget;
                $rootScope.show_email_widget = $scope.show_email_widget;
            });
        } else {
            $scope.resetEmailWidget();
            $scope.show_email_widget = true;
            $scope.createEmail(email_data);
        }
    });

    $scope.$on('$destroy', off);        // Turn off the listener when scope is unbound

    $scope.createEmail = function createEmail(email_data) {
        $scope.email = email_data;

        $scope.email_widget_open = true;
        var subject_input = document.querySelector('.email-widget-wrapper input.subject');
        if (subject_input) {
            subject_input.focus();
        }

        if(email_data.message) {
            $scope.email.message = email_data.message + $scope.footerMessage;
        } else {
            $scope.email.message = $scope.footerMessage;
        }

        $scope.loading_attachments = true;
        if ($scope.email.attachments_url) {
            $http.get(prefix + $scope.email.attachments_url).then(function(data) {
                $scope.loading_attachments = false;
                $scope.available_attachments = data.data;
            });
        } else {
            $scope.loading_attachments = false;
            $scope.available_attachments = emailWidget.getAvailableAttachments();
        }

        $scope.loading_from_addresses = true;
        if ($scope.email.from_addresses_url) {
            $http.get(prefix + $scope.email.from_addresses_url).then(function(data) {
                $scope.loading_from_addresses = false;
                $scope.email.from_addresses = data.data;
                //fix for jquery updated issue COM-17239
                $scope.email.selected_from_address = angular.toJson(data.data[0]);
            });
        } else {
            $scope.loading_from_addresses = false;
            $scope.email.selected_from_address = email_data.from_addresses[0];
        }

        $scope.loading_recipient_addresses = true;
        if ($scope.email.recipient_addresses_url) {
            $http.get(prefix + $scope.email.recipient_addresses_url).then(function(data) {
                $scope.loading_recipient_addresses = false;
                var addresses = data.data;

                if (addresses.length > 0) {
                    $scope.email.recipient_addresses = addresses;
                    $scope.email.selected_recipient_address=  addresses[0].description;
                }

                $scope.show_email_widget = true;
                $rootScope.show_email_overlay = $scope.show_email_widget;
                $rootScope.show_email_widget = $scope.show_email_widget;

                if (email_data.attached_files !== undefined) {
                    $scope.attached_files = email_data.attached_files;
                } else {
                    $scope.attached_files = [];
                }

                $scope.checkIfAttachmentsAreTooLarge();

                $timeout(function() {
                    var mainRecipientSelect2Input = document.querySelector('.main-recipient .select2-input');
                    $scope.handleSelect2InputChange(mainRecipientSelect2Input, 'selected_recipient_address');
                }, 10);
            });
        } else {
            $scope.loading_recipient_addresses = false;
            $scope.email.selected_recipient_address = [JSON.stringify(email_data.recipient_addresses[0])];
        }
    }

    $scope.handleSelect2InputChange = function handleSelect2InputChange(input, keyToPushValueInTo) {
        var addEmailAddress = function(emailAddress) {
            // Check if it's an email address
            var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var isEmailAddress = emailRegex.test(emailAddress);
            var emailAddressAlreadyExists = _.findWhere($scope.email.recipient_addresses, {
                description: emailAddress
            }) !== undefined;
            if (isEmailAddress && !emailAddressAlreadyExists) {
                var newRecipient = {
                    "id": $scope.email.recipient_addresses.length,
                    "description": emailAddress
                };
                $timeout(function() {
                    $scope.email.recipient_addresses.push(newRecipient);
                });
                $timeout(function() {
                    $scope.email[keyToPushValueInTo].push(angular.toJson(newRecipient));
                }, 1);
            }
        }
        input.addEventListener('input', function(e) {
            var inputtedValue = e.target.value;
            var inputtedValueWithoutTrailingCharacter = inputtedValue.substring(0, inputtedValue.length - 1);
            // Check if the input ends in a space
            var lastChar = inputtedValue.slice(-1);
            if ([" ", ","].indexOf(lastChar) > -1) {
                addEmailAddress(inputtedValueWithoutTrailingCharacter);
            }
        });

        input.addEventListener('keyup', function(e) {
            if (e.keyCode == 13) {
                var inputtedValue = e.target.value;
                addEmailAddress(inputtedValue);
            }
        });
    }

    $scope.addCcAddress = function addCcAddress() {
        $scope.ccsShowing = true;
        $timeout(function() {
            var ccEmailAddress = document.querySelector('.cc-email .select2-input');
            $scope.handleSelect2InputChange(ccEmailAddress, 'selected_cc_address');
            $timeout(function() {
                $(ccEmailAddress).click();
            }, 10);
        }, 10);
    }

    $scope.removeCcAddress = function removeCcAddress() {
        $scope.email.selected_cc_address = [];
        $scope.ccsShowing = false;
    }

    /*==========================================================================================
     When an attachment is selected in the attached files window
     ==========================================================================================*/
    $scope.selectAttachment = function selectAttachment(attachment) {
        var allow_attachment_to_be_selected = (attachment.attached !== true && (attachment.attachment_is_too_large !== true) || attachment.selected === true);

        if (allow_attachment_to_be_selected) {
            attachment.selected = !attachment.selected;
            var attachment_should_push = attachment.selected === true;

            if (attachment_should_push) {
                $scope.selected_attachments.push(attachment);
                $scope.checkIfAttachmentsAreTooLarge();
            } else {
                for(var i = 0, l = $scope.selected_attachments.length; i < l; i++) {
                    if ($scope.selected_attachments[i].id === attachment.id) {
                        var index_to_remove = i;
                        $scope.selected_attachments.splice(index_to_remove, 1);
                        $scope.checkIfAttachmentsAreTooLarge();
                        return
                    }
                }
            }
        }
    }

    $scope.checkIfAttachmentsAreTooLarge = function checkIfAttachmentsAreTooLarge() {
        $scope.total_file_size = 0;

        for(var i = 0, l = $scope.available_attachments.length; i < l; i++) {
            var attachment = $scope.available_attachments[i];

            if (attachment.selected === true || attachment.attached === true) {
                $scope.total_file_size += attachment.file_size;
            }
        }

        for(var i = 0, l = $scope.attached_files.length; i < l; i++) {
            var file_should_be_added = true,
                attached_file = $scope.attached_files[i];

            for(var x = 0, c = $scope.available_attachments.length; x < c; x++) {
                if ($scope.available_attachments[x].id === attached_file.id) {
                    file_should_be_added = false;
                }
            }
            if (file_should_be_added === true) {
                $scope.total_file_size += attached_file.file_size;
            }
        }

        for(var i = 0, l = $scope.available_attachments.length; i < l; i++) {
            var attachment = $scope.available_attachments[i],
                attachment_is_too_large = ($scope.total_file_size + attachment.file_size) > $scope.email_file_size_limit;
            if (attachment_is_too_large) {
                attachment.attachment_is_too_large = true;
            } else {
                attachment.attachment_is_too_large = false;
            }
        }
    }

    /*==========================================================================================
     Attach files to the email from the attached files window
     ==========================================================================================*/
    $scope.attachFiles = function attachFiles(){
        for(var i = 0, l = $scope.selected_attachments.length; i < l; i++) {
            $scope.selected_attachments[i].attached = true;
            $scope.attached_files.push($scope.selected_attachments[i]);
        }

        $scope.selected_attachments = [];
        $scope.deselectAllSelectedAttachments();
        $scope.checkIfAttachmentsAreTooLarge();
    }

    $scope.deselectAllSelectedAttachments = function deselectAllSelectedAttachments() {
        for(var i = 0, l = $scope.available_attachments.length; i < l; i++) {
            $scope.available_attachments[i].selected = false;
        }

        $scope.selected_attachments = [];
    }

    /*==========================================================================================
     Remove an attached file from the email window
     ==========================================================================================*/
    $scope.deleteAttachedFile = function deleteAttachedFile(attached_file) {
        for(var i = 0, l = $scope.available_attachments.length; i < l; i++) {
            if ($scope.available_attachments[i].id === attached_file.id) {
                $scope.available_attachments[i].attached = false;
            }
        }

        for(var i = 0, l = $scope.attached_files.length; i < l; i++) {
            if ($scope.attached_files[i].id === attached_file.id) {
                var index_to_remove = i;
                $scope.attached_files.splice(index_to_remove, 1);
                return
            }
        }
        $scope.checkIfAttachmentsAreTooLarge();
    }

    /*==========================================================================================
     Discard the email (with a confirmation toast message)
     ==========================================================================================*/
    $scope.discardEmail = function discardEmail(should_hide_widget) {
        var deferred = $q.defer(),
            confirmation_message = 'Are you sure you want to discard the email?';

        confirmationBoxHelper.getConfirmation(confirmation_message, $scope)
            .then(function() {
                if (should_hide_widget !== false) {
                    $scope.email_widget_open = false;
                }
                $timeout(function() {
                    $scope.resetEmailWidget();
                    deferred.resolve();
                }, 300);
            });
        return deferred.promise;
    }

    var toFormParams = function(obj) {
        var params = []
        angular.forEach(obj, function(val, key) {
            params.push(key + '=' + encodeURIComponent(val));
        })
        return params.join("&");
    }

    $scope.validateEmail = function validateEmail() {
        var deferred = $q.defer(),
            error,
            recipient_exists = $scope.email.selected_recipient_address.length > 0,
            from_exists = $scope.email.selected_from_address.length > 0,
            subject_exists = $scope.email.subject.length > 0,
            message_exists = $scope.email.message.length > 0 || $scope.attached_files.length > 0;

        if (!recipient_exists || !from_exists || !subject_exists || !message_exists) {
            deferred.reject({
                'recipient_exists' : recipient_exists,
                'from_exists' : from_exists,
                'subject_exists' : subject_exists,
                'message_exists' : message_exists
            });
        } else {
            deferred.resolve();
        }
        return deferred.promise;
    }

    $scope.sendEmail = function sendEmail(){
        $scope.validateEmail().then(function() {
            $scope.actuallySendEmail();
        }, function(error) {
            if (!error.recipient_exists) {
                $scope.email_error_message = 'Please choose a recipient';
                $scope.showErrorView();
            } else if (!error.from_exists) {
                $scope.email_error_message = 'Please choose a from address';
                $scope.showErrorView();
            } else if (!error.subject_exists) {
                $scope.email_error_message = "You have not written a subject";
                $scope.showErrorView();
            } else if (!error.message_exists) {
                $scope.email_error_message = "You have not written a message";
                $scope.showErrorView();
            } else {
                return false
            }
        });
    }

    $scope.showErrorView = function showErrorView() {
        $scope.email_errors_open = true;
    }
    $scope.hideErrorView = function hideErrorView() {
        $scope.email_errors_open = false;
    }

    $scope.actuallySendEmail = function actuallySendEmail() {
        // Get ContactId
        var toAddress = { "description" : ""};

        var toRecipients = _.map($scope.email.selected_recipient_address, function(elm) { return angular.fromJson(elm); });
        var fromAddress = angular.fromJson($scope.email.selected_from_address);

        // For now pick up only the first ToAddress's ContactRec. We need to change the backend to store
        // all possible contactRecords, but till then..
        if (toRecipients.length > 0) {
            toAddress = toRecipients[0];
        }
        var contactRec = _.find($scope.email.recipient_addresses, function(addr) {
            return addr.description == toAddress.description
        });
        var contactId = -1;
        if (contactRec) {
            contactId = contactRec['id'];
        }

        var fileIds = "";
        if ($scope.attached_files && $scope.attached_files.length > 0) {
            fileIds = _.map($scope.attached_files, function(obj) { return obj.id}).join(',');
        }

        var ccAddress = "";
        if ($scope.ccsShowing) {
            var ccAddresses = $scope.email.selected_cc_address;
            ccAddress = _.map(ccAddresses, function(cc) { return angular.fromJson(cc).description}).join(',');
        }
        // Used to enable the tick option for send to customer tab.
        if($scope.email.relatedObjectType == 5) {
            $rootScope.$broadcast('estimateSendToCustomer:status', true);
        }

        var id = $scope.email.relatedObjectId;
        var type = $scope.email.relatedObjectType;

        $http.post(prefix + '/process_email', toFormParams({
            'contactId': contactId,
            'tableType' : $scope.email.relatedObjectType,  // TODO: Has to come from the method
            'tablePKID': $scope.email.relatedObjectId,
            'toAddresses': _.map(toRecipients, function(addr) { return addr.description }).join(','),
            'fromAddress': fromAddress.description,
            'ccAddress' : ccAddress,
            'body': $scope.email.message,
            'subject': $scope.email.subject,
            'attachments': fileIds
        })).success(function(resp){
            $rootScope.$broadcast('email:email_sent');
            var data = {'id' : id, 'type' : type};
            $rootScope.$broadcast('event:sentStatus', data);
        });
        toastBox.show('Your email is being sent', 4000);
        $scope.email_widget_open = false;
        $timeout(function() {
            $scope.resetEmailWidget();
        }, 400);
    }

    /*==========================================================================================
     Logic for the autocomplete dropdown arrow on rhs of select boxes
     ==========================================================================================*/
    $scope.toggleRecipientDropdown = function toggleRecipientDropdown(event, index, isRecipient) {
        if (isRecipient === true) {
            $scope.email.selected_recipient_address = null;
        } else {
            $scope.email.ccs[index].selected_recipient_address = null;
        }
    }

    $scope.toggleFromDropdown = function toggleFromDropdown() {
        $scope.email.selected_from_address = null;
        $timeout(function(){
            $('.autocomplete-wrapper.from input').click();
        }, 10)
    };

    $scope.closeDropdowns = function closeDropdowns() {
        $scope.recipientDropdownOpen = false;
        $scope.fromDropdownOpen = false;
    }

    /*==========================================================================================
     Showing and hiding panels
     ==========================================================================================*/
    $scope.toggleEmailMinimise = function toggleEmailMinimise() {
        $scope.email_widget_open = !$scope.email_widget_open;
        $scope.email_attachments_open = false;
        $rootScope.show_email_overlay = $scope.email_widget_open;
    }

    $scope.toggleAttachmentView = function toggleAttachmentView() {
        $scope.email_attachments_open = !$scope.email_attachments_open;
        $scope.checkIfAttachmentsAreTooLarge();
    }

    $scope.toggleAttachedFilesView = function toggleAttachedFilesView() {
        $scope.email_attached_files_open = !$scope.email_attached_files_open;
    }

    $scope.toggleSettingsView = function toggleSettingsView() {
        $scope.email_settings_open = !$scope.email_settings_open;
    }

    $scope.minimiseEmailAttachments = function minimiseEmailAttachments() {
        $scope.email_attachments_open = false;
        $scope.checkIfAttachmentsAreTooLarge();
    }

    $scope.minimiseAttachedFilesView = function minimiseAttachedFilesView() {
        $scope.email_attached_files_open = false;
    }

    $scope.minimiseSettingsView = function minimiseSettingsView() {
        $scope.email_settings_open = false;
    }

    /*==========================================================================================
     Handle dragging and dropping from attached files
     ==========================================================================================*/
    $scope.dropValidate = function dropValidate() {
        return true
    }

    $scope.handleDropEvent = function handleDropEvent(a, data){
        var rows_to_process = $rootScope.rows_store,
            decorated_files = emailService.decorateRowsFromAttachedFiles(rows_to_process),
            files_are_not_too_large_to_insert = $scope.checkIfDroppedFilesAreTooLarge(decorated_files);

        if (files_are_not_too_large_to_insert) {
            $scope.insertFilesFromAttachedFiles(decorated_files);
            $scope.updateAvailableFilesList(decorated_files);
            $scope.checkIfAttachmentsAreTooLarge();
        } else {
            $rootScope.$broadcast('drophelper:drop_error');
        }
        $rootScope.$broadcast('smart_table:unpin_all_pinned_rows');
    }

    $scope.checkIfDroppedFilesAreTooLarge = function checkIfDroppedFilesAreTooLarge(files) {
        $scope.total_file_size;
        var files_total_size = 0;
        for(var i = 0, l = files.length; i < l; i++) {
            files_total_size += files[i].file_size;
        };
        if ((files_total_size + $scope.total_file_size) > $scope.email_file_size_limit) {
            return false
        } else {
            return true
        }
    }

    /*==========================================================================================
     Handle inserting files
     ==========================================================================================*/
    $scope.insertFilesFromAttachedFiles = function insertFilesFromAttachedFiles(files) {
        var files_are_ok_to_insert = true;
        for(var i = 0, l = files.length; i < l; i++) {
            var file = files[i],
                file_is_ok_to_insert = $scope.checkIfFileDoesNotAlreadyExist(file);

            if (!file_is_ok_to_insert) {
                files_are_ok_to_insert = false;
            }
        }
        if (files_are_ok_to_insert === true) {
            for(var i = 0, l = files.length; i < l; i++) {
                var file = files[i];

                $scope.attached_files.push(file);
            }
        } else {
            $rootScope.$broadcast('drophelper:drop_error', 'File already attached');
            return
        }
    }

    /*==========================================================================================
     Check if the file has not already been attached
     ==========================================================================================*/
    $scope.checkIfFileDoesNotAlreadyExist = function checkIfFileDoesNotAlreadyExist(file) {
        var file_already_attached = $scope.attached_files.map(function(file) {
            return file.id;
        }).indexOf(file.id) > -1;
        return !file_already_attached;
     }


    /*==========================================================================================
     This function keeps the attached files and available files list in sync ~ used when dragging
     rows from the smart table (attached files list) into the email widget
     ==========================================================================================*/
    $scope.updateAvailableFilesList = function updateAvailableFilesList(files) {
        var selected_files_ids = files.map(function(file) {
            return file.id;
        });

        for(var i = 0, l = $scope.available_attachments.length; i < l; i++) {
            var available_attachment = $scope.available_attachments[i],
                file_has_been_attached = selected_files_ids.indexOf(available_attachment.id) > -1;

            if (file_has_been_attached) {
                available_attachment.attached = true;
            }
        }
     }

    /*==========================================================================================
     Listen for events
     ==========================================================================================*/
    $rootScope.$on('ANGULAR_DRAG_START', function(e, channel){
        if (channel === 'attached_files') {
            $scope.minimiseEmailAttachments();
            if ($scope.email_widget_open === false) {
                $scope.toggleEmailMinimise();
            }
        }
    })

    $rootScope.$on('email:attachFiles', function(e, files) {
        $scope.insertFilesFromAttachedFiles(files);
    })

    $scope.handleSearch = function handleSearch() {
        var filtered_preferences = $filter('filter')($scope.available_attachments, $scope.searchFilter),
            no_results = filtered_preferences.length === 0;

        if (no_results) {
            $scope.no_results = true;
        } else {
            $scope.no_results = false
        }
    }

    $scope.setFooterMessage = function() {
        $http.get(prefix + '/get_default_email_footer').then(function(response) {
            if(response.data.message) {
                $scope.footerMessage = '<br />' + response.data.message;
                $scope.email.message = $scope.email.message + $scope.footerMessage;
            }
        });
    }
    $scope.setFooterMessage();

    $rootScope.$on("email_footer_changed", function() {
        $scope.setFooterMessage();
    })

});

/* Root Ctrl */
function RootCtrl($scope, $http, $state, datasets, $rootScope, prefix, $modal, tableCollection, searchPageNumName, warningModal, $translate,$timeout,toastBox) {
    
    if( navigator.platform.includes('Mac')) {
        $scope.downloadPhone  = "https://www.commusoft.com/downloads/VoIP.dmg";
    } else {
        $scope.downloadPhone  = "https://www.commusoft.com/downloads/VoIP.exe";
    }
    
    $scope.data = datasets;
    $rootScope.hash = '';
    $scope.hideLayout = false;

    $rootScope.$on('change:layoutValue', function(evt, data){
        $scope.hideLayout = data;
    });

    $scope.updatePermissionValues = function() {
        $scope.hasSearchPermission = $rootScope.hasPermission(features['Search'], 'readaccess');
        $scope.hasNewUIMenuPermission = 1;
        // Check whether Chat module have permission or not.
        $scope.chatPermissions = $rootScope.hasPermission(features['ChatIntegration'], 'writeaccess');
    }

    $scope.updatePermissionValues();
    $scope.$on('permissionsChanged', function() {
        $scope.updatePermissionValues();
    });

    var isLoggedIn = $state.current.name.indexOf('loggedin') != -1;

    $scope.DownloadPhone = function () {
        window.open($scope.downloadPhone,'_parent');
    }
    $scope.scrollTo = function (id) {
        angular.element('html').scope();
        $rootScope.hash = id;
    };
    $scope.postProcessCheck = function (data, status) {
    }

    $scope.formSubmit = function (formElem) {
        $rootScope.errorMessage = "";
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.afterCheck = $scope.postProcessCheck(data, status);
            if ($scope.afterCheck == false) {
                return false;
            }
            if (data.location) {
                if ($rootScope.stateToRedirectFromLogin != '' && $state.$current.name == "home") {
                    var toState = $rootScope.stateToRedirectFromLogin;
                    var toParams = $rootScope.paramsToRedirectFromLogin;
                    $rootScope.stateToRedirectFromLogin = '';
                    $state.transitionTo(toState, toParams);
                    return;
                }
                $scope.data = data.location.replace(/\//g, '');
                try {

                    //TODO: REdirect to some other page after all phases are completed.
                    if ($scope.data == '' || $scope.data === 'logout') {
                        $scope.data = 'customers';
                    }

                        $rootScope.changableState = true;
                        $rootScope.chatWindows = [];
                    $rootScope.waitAndRedirect($scope.data, {}, {});
                        //$state.transitionTo('loggedin.' + $scope.data);
                    } catch (err) {
                        // If the URL has more than one segment, take the last and transition to that
                        var indexLocation = data.location.lastIndexOf('/');
                        if (indexLocation == -1) {
                            $state.transitionTo(data.location);  // For cases before login
                        } else if (indexLocation != 0) {
                            $state.transitionTo('loggedin.' + data.location.substr(indexLocation + 1));
                        } else {
                            throw err;
                        }
                    }
                } else {
                    $scope.data = data;
                }
            }).
            error(function (data, status) {
                $scope.data = data;
            });
    }

    $scope.openMessageWindow = function () {
        var modalInstance = null;
        modalInstance = $modal.open({
            templateUrl: 'template/new_message.html',
            controller: NewMessageController
        })
    }
    $scope.hideMenu = function() {
        const element = document.querySelector('#new-top-menu');
        const element1 = document.querySelector('#side-panel');
        if (element.classList.contains("open") ) {
            if( element1.classList.contains("in-view")) {
            } else {
                element.classList.remove("open");
            }

        }
    }

    $scope.$on("REFRESH_PROFILE_PIC", function () {
        $scope.getProfilePicture();
    });

    $scope.getProfilePicture = function () {
        $http.get(prefix + '/getProfilePicture').success(function (data) {
            $scope.profilePicture = data.imageSrc;
            $scope.facebookUrl = data.facebookUrl;
            $scope.linkedinUrl = data.linkedinUrl;
            $scope.showFacebook = data.showFacebook;
            $scope.showLinkedin = data.showLinkedin;
            $scope.userName = data.user['Username'];
            $scope.userType = data.user['UserType'];
            $scope.clientId = data.user['Client'];
            $scope.knowledgebaseuri = data.user['knowledgebaseuri'];
        })
    };

    if (!$scope.profilePicture && isLoggedIn) {
        $scope.getProfilePicture();
    }

    $scope.nonReadMessagesCount = '';
    $scope.setReadMessages = function () {
        $scope.getNonReadMessages(1);
    };

    $scope.getNonReadMessages = function (update) {
        $http.get(prefix + '/getNonReadMessages?update=' + update).success(function (data) {
            $scope.nonReadMessagesCount = data.nonReadMessagesCount;
            $scope.nonReadMessages = [];
            angular.forEach(data.nonReadMessages, function (value, key) {
                $scope.chat = {
                    'message': value.message,
                    'userId': value.id,
                    'name': value.name,
                    'timeformat': moment(value.fromTime.date).format("h:mm a"),
                    'channelid': value.channelId
                };
                $scope.nonReadMessages.push($scope.chat);
            });

        })
    };
    if (!$scope.nonReadMessagesCount&& isLoggedIn) {
        $scope.getNonReadMessages(0);
    }
    $scope['search'] = function () {
        $state.transitionTo('loggedin.search');
        $scope.$broadcast('search:top');
    }
    $scope.allNotifications = [{'count' : 0}];
    $scope.getNotifications = function () {
        $http.get(prefix + '/listNotifications').success(function (data) {
            if(angular.isObject(data)){
                if(tableCollection != undefined){
                    tableCollection.setData('notificationsTopMenu', data.notifications);
                }
                $scope.allNotifications = data;
            }
        })
    };

    if(isLoggedIn) {
        $scope.getNotifications();
    }

    $scope.openChatWindow = function (chatWindow) {
        $http.get(prefix + '/getChatUsers?channelId=' + chatWindow.channelid +'&userId=' + chatWindow.userId).success(function (data) {
            $scope.openNewChatWindow(chatWindow, data);
        });
    }

    $scope.openNewChatWindow = function (chat, data) {
        var chatMessage = chat.message;
        $scope.showWindow = false;

        var groupUsers = [{
            userId: chat.userId,
            username: chat.name
        }];

        this.users = data;
        groupUsers = groupUsers.concat(this.users);

        var channelId = chat.channelid;
        var time = moment().valueOf();
        var self = this;

        // Create a Local Window for this user, indicating he sent it
        if ($rootScope.chatMessages[chat.channelid] == undefined && chat.userId == $rootScope.userId) {
            var newChatWindow = {
                userId: data[0].userId,
                username: data[0].username,
                channelId: channelId
            };
            if (self.users.length > 1) {
                newChatWindow.groupUsers = groupUsers;
            }
            $rootScope.chatWindows.push(newChatWindow);
            $rootScope.chatMessages[chat.channelid] = [];
        }

        var chatUsers = [
            {
                userId: chat.userId,
                username: chat.name
            }
        ];

        var type = 'personal';
        if (this.users && this.users.length > 1) {
            type = 'group';
        }

        var readStatus = [];

        var unReadStatus = 1; // Indicates offline

        if ($rootScope.channelMembers.indexOf($rootScope.username) != -1) {
            unReadStatus = 0;
            var messageFrom = '';
            if (chat.userId == $rootScope.userId) {
                var msg = {
                    'text': chatMessage,
                    'timestamp': time,
                    'formattedTime': moment.unix(Math.round(time / 1000)).format('HH:mm'),
                    'from': {
                        'userId': $rootScope.userId,
                        'username': 'Me'
                    }
                }

                $rootScope.chatMessages[channelId].push(msg);
            }
            else
            {
                var messageFrom = {
                    userId: chat.userId,
                    username: chat.name,
                    channelId: channelId
                };
            }
            if (self.users.length > 1) {
                messageFrom.groupUsers = groupUsers;
            }
            if (chat.userId != $rootScope.userId) {
                pubnub.publish({
                    channel: 'commusoft_' + $rootScope.clientId + '_' + $rootScope.userId,
                    message: {
                        text: chatMessage,
                        from: messageFrom,
                        channelId: channelId
                    }
                });
            }
            readStatus.push({
                'userId': $rootScope.userId,
                'status': unReadStatus
            })
        }
    }

    //advance options
    $scope.opened = false;
    $scope.showAdvancedOption = function () {
        $scope.opened = !$scope.opened;
    }
    $scope.modalId = 'dynamic-field-import-notification';
    $scope.afterImportNotification=false;
    HandleCsvImports.call(this, $scope, $modal, warningModal, $translate);

    $scope.csvSuccessCallBack = function(resp) {
        console.log('response', resp);
        var data = angular.fromJson(resp);
        if (data.error) {
            $scope.csvError = data.errorMessage;
            $scope.progress = 0;
            return;
        }
        $scope.afterImportNotification=true;
        var modal_elm = $('#' + $scope.modalId );
        modal_elm.modal('show');
    };

    // calendar integration
   
    $scope.isCalendarIntegrated = function() {
        $rootScope.showViewCalendar = false;
        $rootScope.showSetupCalendar = false;


	    $rootScope.allowedCalendar = '';

        $http.get(prefix + '/checkCalendarAccountExist').success(function (data) {

        if( data.activeIntegrations ) {
            $rootScope.allowedCalendar = data.activeIntegrations[0];
            if( $rootScope.allowedCalendar ) {
                    if( data.isCalendarIntegrated > 0 ) {
                        $rootScope.showViewCalendar = true;
                        $rootScope.showSetupCalendar = false;
                    
                    } else {
                        $rootScope.showSetupCalendar = true;
                        $rootScope.showViewCalendar = false;
                    }
            }
        }
        }).error(function (data, status) {

        });
    }

    if (isLoggedIn && prefix != undefined) {
        $scope.isCalendarIntegrated();
    }

    $scope.connectCalendar = function (calendarType) {
        //alert(calendarType);
        $rootScope.calendarType = calendarType;
        $http({
                method  : 'POST',
                url     : prefix + '/oauthCalendar',
                data    :  $.param({
                    'calendar': calendarType
                }),
                headers : { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).
        success(function (data, status) {
            var oAuthUrl = data.oAuthUrl;
            //alert(oAuthUrl);
            window.open(oAuthUrl, "_self");
        }).
        error(function (data, status) {
            $scope.data = data;
        });
    }

     $scope.academyButtonClicked = function () {

        $http.get(prefix + '/createSessionAcademy').success(function (data) {
            if( data.isLMSOn == 1 ) {
                $rootScope.isLMSOn = true;
                $rootScope.accessurl = data.accessurl;
            } else {
                $rootScope.isLMSOn = false;
                $rootScope.accessurl = data.accessurl;
            }
            if(data.error != "" || data.error != 0){
                toastBox.show(data.error, 4000);
            } 
            if (data.error == 0) {
                if ( $rootScope.accessurl != "" && $rootScope.accessurl != 'undefined') {
                    $rootScope.isLMSOn = true;
                    window.open($rootScope.accessurl,"_blank");
                }
            }
            }).error(function (data) {
            }
        );
    }
 
    $scope.checkSetupCompleted = function() {
        
        $scope.loading = true;
        $http.get(prefix + '/isChatSetup').success(function (data) {
             $scope.loading = false;
            if( data.data == "chat_enable_owner" || data.data == "chat_enable_user") {
                $scope.redirecturl = data.chatUrl + "/login?commusoft="+data.chatDetail+"_"+data.chatAuthKey+"_"+data.clientId;
            } else {
                // chat module enable but chat is not setup
                if( data.data == "chat_not_enable_owner" )  {
                    $scope.redirecturl = "loggedin.chat_details";
                } else if(data.data == "chat_not_enable_user") {
                    //$scope.redirecturl = "loggedin.chat_details";
                    $scope.redirecturl = "popup";
                }
            }
            
        });
    }
    
    $scope.OpenChatSetup = function (redirect)  {
        $http.get(prefix + '/showChatMessage').success(function (data) {
             $scope.loading = false;
            if( data.data == "chat_enable_owner" || data.data == "chat_enable_user") {
                $scope.redirecturl = data.chatUrl + "/login?commusoft="+data.chatDetail+"_"+data.chatAuthKey+"_"+data.clientId;
                window.open($scope.redirecturl,"_blank");
                
            } else {
                // chat module enable but chat is not setup
                if( data.data == "chat_not_enable_owner" )  {
                    $scope.redirecturl = "loggedin.chat_details";
                    $state.transitionTo($scope.redirecturl);
                } else if(data.data == "chat_not_enable_user") {
                    //$scope.redirecturl = "loggedin.chat_details";
                    $scope.redirecturl = "popup";
                    var modalInstance = null;
                    modalInstance = $modal.open({
                       templateUrl: 'template/chat/chat_modal.html',
                       controller: NewChatController
           
                    });
                }
            }
            
        });
    }

    if (isLoggedIn && prefix != undefined && $scope.chatPermissions) {
        $scope.checkSetupCompleted();
    }

    $scope.switchUI = function (redirectUrl) {
        window.dispatchEvent(new CustomEvent("callAngular7Function", {
            'detail': {
                url: redirectUrl
            }
        }))
    }

    $scope.helpButtonClicked = function (knowledgebaseuri) {
        window.open($scope.knowledgebaseuri, '_blank');
    }
}

function SearchCtrl($scope, $http, $state, datasets, $rootScope,prefix) {
    $scope.data = datasets;
    $scope.redirectEstimateToJobAdditionalWorks = function redirectEstimateToJobAdditionalWorks(type,propertyId,estimateId,JobId){
        $http.post(prefix + '/customers/'+type+'/'+propertyId+'/estimate/'+estimateId+'/job_additional_work/'+JobId).success(function (data) {
            $state.transitionTo("loggedin.customer_list.view.job.additional_works.view.details",{ type: type, id: propertyId, jobId: JobId, additionalWorkId: data.addtional_work_id }, {reload:true});
        });
    }
}

function NeedHelpCtrl($scope, $http, $state, $rootScope, NgConfigKBUrlService) {
    $scope.clientId = $rootScope.clientId;
    $scope.searchPath = NgConfigKBUrlService.getData("searchPath");
    $scope.domainName = NgConfigKBUrlService.getDomain();
    $scope.supportEmail = NgConfigKBUrlService.getEmail();
    $scope.featureRequest = NgConfigKBUrlService.getData("featureRequest")
    $scope.searchKnowledgebase = function() {
        window.open($scope.searchPath  + $scope.search_knowledgebase_query + '&commit=Search', '_blank');
    }
    $scope.sendSupportEmail = function() {
        $scope.closePanel();
        $rootScope.$broadcast('email:createNew', {
            from_addresses_url: '/get_from_emails',
            recipient_addresses: [
                {
                    description: "support@commusoft.co.uk"
                }
            ]
        })
    }
}

function SearchListCtrl($scope, $state, prefix, $http, defaultPagingValue, canLoad, $q, $rootScope, searchPageNumName) {
    $scope.checkTabs = false;
    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }
    $scope.pageNumName = searchPageNumName.getSearchPageNumName();
    $scope.pageNumNameValue = {};
    if ($state.current.data.pageNum) {
        angular.forEach($scope.pageNumName, function(val, key){
            $scope[val] = parseInt($state.current.data.pageNum);
            $scope.pageNumNameValue[key] = parseInt($state.current.data.pageNum);
        });
    } else {
        angular.forEach($scope.pageNumName, function(val, key){
            $scope[val] = 1;
            $scope.pageNumNameValue[key] = 1;
        });
    }
    $scope.pageTab = '';
    var pageNumScope = this;
    $scope.$on("search:top", function (event) {
        angular.forEach($scope.pageNumName, function(val, key){
            pageNumScope[val] = 1;
            $scope.pageNumNameValue[key] = 1;
        });
        $scope.fetchSearchTop(parseInt($state.current.data.pageNum), 'all');
    });

    if ($scope.searchQuery === undefined) {
        $scope.no_search_param = true;
    } else {
        $scope.no_search_param = false;
    }

    var canceler = null;
    $scope.fetchSearchTop = function (pageNum, index) {
        $scope.searching = true;
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        if ($scope.searchQuery.length > 0) {
            $scope.no_search_param = false;
            var q = ($scope.searchQuery == undefined) ? '' : $scope.searchQuery;
            $http.post(prefix + '/searchResults', 'page=' + angular.toJson($scope.pageNumNameValue) + '&limit=' + $scope.limit + '&pageTab=' + angular.toJson($scope.pageNumName) + '&q=' + encodeURIComponent(q) + '&type=' + index, {timeout: canceler.promise}).success(function (data) {
                $scope.results = data;
                $scope.searching = false;
            })
        } else {
            $scope.no_search_param = true;
        }
    }
    $scope.fetchSearch = function (pageNum, index) {
        if($scope.searching === false) {
            $scope.pageNumNameValue[index]= pageNum;
            if ($scope.searchQuery.length > 0) {
                $scope.no_search_param = false;
                var q = ($scope.searchQuery == undefined) ? '' : $scope.searchQuery;
                $http.post(prefix + '/searchResults', 'page=' + angular.toJson($scope.pageNumNameValue) + '&limit=' + $scope.limit + '&pageTab=' + angular.toJson($scope.pageNumName) + '&q=' + encodeURIComponent(q) + '&type=' + index, {timeout: canceler.promise}).success(function (data) {
                    $scope.results[index] = data[index];
                })
            } else {
                $scope.no_search_param = true;
            }
        }
    }
    if ($scope.searchQuery && $scope.searchQuery.length > 0) {
        $scope.fetchSearchTop(parseInt($state.current.data.pageNum), 'all');
    }
}

function ResetPasswordCtrl($scope, $http, $state, $rootScope, $timeout, datasets) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.flashmessageclose = function () {
        $rootScope.successMessage = '';
        $rootScope.errorMessage = '';
        $rootScope.errorMessageToken = '';
    }

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                if (data == 'Success') {
                    $rootScope.errorMessage = "";
                    $scope.successMessage = 1;
                    $scope.saving = false;
                    $timeout(function () {
                        $("#saveMessage").fadeIn();
                        $("#saveMessage").fadeOut(4000);
                    }, 2000);
                    $scope.changeclose = function () {
                        $scope.successMessage = '';
                    }
                    $timeout(function () {
                        $state.transitionTo('home');
                    }, 5000);
                } else if (data == 'LengthNotValid') {
                    $scope.resetPasswordForm['resetPassword[password]'].$error.minlength = true;
                } else if (data == 'PattenNotValid') {
                    $scope.letterMessage = true;
                } else if (data == 'PasswordNotMatch') {
                    $scope.passwordNotMatch = true;
                } else {
                    $rootScope.errorMessage = data;
                    $scope.saving = false;
                }
                $scope.resetPasswordForm.$setPristine();
                $scope.password = '';
                $scope.reenter = '';
                $scope.validLength = '';
                $scope.validLetter = '';
                $scope.validNumber = '';
            }).
            error(function (data, status) {
                $scope.data = data;
            });
    }
}

function LoginCtrl($scope, $http, $state, $rootScope, datasets, prefix, $timeout) {
    $scope.openGoogleSignIn = false;

    if ($rootScope.errorMessage === undefined) {
        $rootScope.errorMessage = '';
    }

    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);

    $scope.close = function () {
        $rootScope.errorMessage = "";
    }

    $scope.sendToProvider = function (provider) {
        $http.get(prefix + '/get_provider_url?provider=' + provider).success(function (data) {
            window.location = data;
        });
    }

    $scope.signInToGoogle = function (provider) {
        $scope.openGoogleSignIn = true;
        var googleSignInButton = angular.element('#my-signin2').children();
        googleSignInButton.trigger('click');
    }

    $scope.postProcessCheck = function (data, status) {
        $rootScope.$broadcast('event:auth-loginConfirmed', {fromLogin: true});
        return true;
    }

    $scope.$watch('username', function (newVal, oldVal) {
        $scope.updatePassword();
    });

    //This function is to fix the issue COM-1627
    $scope.updatePassword = function() {
        $timeout(function () {
            var password = angular.element("#logintype_password").val();
            if($scope.password != password) {
                $scope.password = password;
            }
        }, 10);
    }

    $scope.onSuccess = function onSuccess(googleUser) {
        //This is to stop logging in automatically to the application, should login only if the user press sign-in button
        if(!$scope.openGoogleSignIn) {
            return false;
        }
        if($state.current.name != 'home') {
            console.log('you are already logged in!!');
            return false;
        }
        var profile = googleUser.getBasicProfile();

        var authResp = googleUser.getAuthResponse();
        var idToken = authResp.id_token;
        $('#loading-indicator').show();

        $http.post(prefix + '/google_openid', 'token='+idToken).success(function (data) {
            $rootScope.errorMessage = '';
            $('#loading-indicator').hide();
            if(data.location) {
                var dataLocation = data.location.replace(/\//g, '');
                if (dataLocation == 'openidlogin') {
                    $timeout(function () {
                        $state.transitionTo('openidlogin');
                    }, 100, true);
                } if (dataLocation == 'customers') {
                    $state.transitionTo('loggedin.customers');
                } else if (dataLocation == 'show_contract') {
                    $state.transitionTo('loggedin.show_contract');
                } else {
                    $state.transitionTo('home');
                }
            } else {
                if(typeof data == 'string') {
                    $rootScope.errorMessage = data;
                }
            }
        });
    }

    $scope.onFailure = function onFailure(error) {
        console.log(error);
    }

    $scope.renderButton = function renderButton() {
        gapi.signin2.render('my-signin2', {
            'client_id': '183648225123-vb6in25ejf6kpo33b8s8ghrril350bes.apps.googleusercontent.com',
            'cookie_policy' : 'none',
            'prompt' : 'select_account',
            'scope': 'profile email',
            'width': 10,
            'height': 10,
            'longtitle': true,
            'theme': 'dark',
            'onsuccess': $scope.onSuccess,
            'onfailure': $scope.onFailure
        });
    }

    $timeout(function () {
        $scope.renderButton();
    }, 100, true);
}

function ForgetPasswordCtrl($scope, $http, $state, $rootScope, $timeout, toastBox, $translate, datasets) {
    $scope.data = datasets;
    $scope.saving = false;
    $rootScope.errorMessage = '';

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                if (data == 'Success') {
                    toastBox.show($translate('password.link.email'), 4000);
                    $scope.saving = false;
                    $timeout(function () {
                        $state.transitionTo('home');
                    }, 5000, true)
                } else {
                    $rootScope.errorMessage = data;
                    $scope.saving = false;
                }
                $scope.forgetPasswordForm.$setPristine();
                $scope.companyId = '';
                $scope.username = '';
            }).
            error(function (data, status) {
                $scope.data = data;
            });
    }
}

function ChangePasswordCtrl($scope, $http, $state, $rootScope, $timeout, datasets) {
    $rootScope.toState = $rootScope.previousState;
    $rootScope.successMessage = '';
    $rootScope.errorMessage = '';
    $scope.data = datasets;
    $scope.saving = false;
    $scope.flashMessageClose = function () {
        $rootScope.successMessage = '';
        $rootScope.errorMessage = '';
    }

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                if (data == 'Success') {
                    $rootScope.errorMessage = "";
                    $scope.successMessage = 1;
                    $timeout(function () {
                        $("#saveMessage").fadeIn();
                        $("#saveMessage").fadeOut(4000);
                    }, 2000);
                    $scope.changeclose = function () {
                        $scope.successMessage = '';
                    }

                } else if (data == 'LengthNotValid') {
                    $scope.resetPasswordForm['resetPassword[password]'].$error.minlength = true;
                } else if (data == 'PattenNotValid') {
                    $scope.letterMessage = true;
                } else if (data == 'PasswordNotMatch') {
                    $scope.passwordNotMatch = true;
                } else {
                    $rootScope.errorMessage = data;
                    $rootScope.successMessage = '';
                }
                $scope.saving = false;
                $scope.resetPasswordForm.$setPristine();
                $scope.password = '';
                $scope.reenter = '';
            }).
            error(function (data, status) {
                $scope.data = data;
            });
    }
}

function ChatController($scope, $rootScope, $http, prefix, canLoad, $location, $anchorScroll, $timeout) {
    $scope.getChatWindowNames = function (chatWindow) {
        var users = "";
        angular.forEach(chatWindow.groupUsers, function (val, key) {
            if (val.userId != $rootScope.userId) {
                users += val.username + ",";
            }
        });
        if (users != "") {
            users = users.substr(0, users.length - 1);
        }
        return users;
    }

    $scope.addToGroupChat = function (chatWindow) {
        var newUserIds = [];
        angular.forEach(this.newUsers, function (val, key) {
            newUserIds.push(val.userId);
        });

        // Filter out users that are already added in chat
        var self = this;
        angular.forEach(chatWindow.groupUsers, function (val, key) {
            if (newUserIds.indexOf(val.userId) != -1) {
                self.newUsers.splice(newUserIds.indexOf(val.userId), 1);
            }
        });

        if (this.newUsers.length > 0) {
            var groupUsers = chatWindow.groupUsers || [
                {
                    userId: $rootScope.userId,
                    username: $rootScope.username
                },
                {
                    userId: chatWindow.userId,
                    username: chatWindow.username
                }
            ];
            groupUsers = groupUsers.concat(this.newUsers);

            // Check if the chatwindow is already present
            var newChat = true;
            if ($rootScope.chatWindows) {
                for (var i = 0; i < $rootScope.chatWindows.length; i++) {
                    var val = $rootScope.chatWindows[i];
                    if (val.groupUsers && val.groupUsers.length == groupUsers.length) {
                        var groupUsernames = jQuery.map(groupUsers, function (elem, idx) {
                            return elem.username;
                        });
                        groupUsernames.sort();
                        var existingGroupUsernames = jQuery.map(val.groupUsers, function (elem, idx) {
                            return elem.username;
                        });
                        existingGroupUsernames.sort();
                        if (angular.equals(groupUsernames, existingGroupUsernames)) {
                            newChat = false;
                        }
                    }
                }
            }

            if (newChat) {
                var newChannelWindow = {
                    userId: chatWindow.userId,
                    username: chatWindow.username,
                    channelId: pubnub.uuid(),
                    groupUsers: groupUsers
                }

                $scope.sendMessage(newChannelWindow, "Welcome to GroupChat");
            }
        }

        chatWindow.windowOpened = !chatWindow.windowOpened;
    }

    $scope.getChannel = function (channelId) {
        $scope.currentChannelId = channelId;
        angular.forEach($scope.chatWindows, function (val, key) {
            if (val.channelId != channelId) {
                val.windowOpened = false;
            }
        });
    }

    $scope.select2GroupsChat = {
        multiple: true,
        minimumInputLength: 2,
        id: function (obj) {
            return obj.userId;
        },
        ajax: {
            url: prefix + '/searchUser',
            data: function (term, page) {
                return { q: term, filterMe: true, chats: $rootScope.chatWindows, channelId: $scope.currentChannelId };
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        formatResult: function (rec) {
            var userStatus = "online";
            if ($rootScope.channelMembers.indexOf(rec.username) == -1) {
                userStatus = "offline";
            }
            return rec.username + '<div class="' + userStatus + '"></div>';
        },
        formatSelection: function (rec) {
            return rec.username
        }
    }

    $scope.closeChat = function (channelId) {
        var chatWindows = [];
        angular.forEach($rootScope.chatWindows, function (val, key) {
            if (val.channelId != channelId) {
                chatWindows.push(val);
            } else {
                delete $rootScope.chatMessages[channelId];
            }
        });
        $rootScope.chatWindows = chatWindows;
    }

    function storeMessage(readStatus, from, msg, time, channelId, type) {
        var chatParams = { 'from': from,
            'msg': msg,
            'msgtime': time,
            'type': type,
            'channelId': channelId,
            'readStatus': JSON.stringify(readStatus)
        };
        canLoad.setLoadValue(false);
        $http.post(prefix + '/addChatHistory', chatParams).
            success(function (data, status) {
            });
    }

    $scope.sendMessage = function (chatWindow, msg) {
        if (msg) {
            this.chatMessage = msg;
        }
        var channelId = chatWindow.channelId;
        var time = moment().valueOf();

        // Create a Local Window for this user, indicating he sent it
        if ($rootScope.chatMessages[chatWindow.channelId] == undefined) {
            var newChatWindow = {
                userId: chatWindow.userId,
                username: chatWindow.username,
                channelId: channelId
            };
            if (chatWindow.groupUsers && chatWindow.groupUsers.length > 0) {
                newChatWindow.groupUsers = chatWindow.groupUsers;
            }

            $rootScope.chatWindows.push(newChatWindow);
            $rootScope.chatMessages[chatWindow.channelId] = [];
        }

        var chatUsers = [
            {
                userId: chatWindow.userId,
                username: chatWindow.username
            }
        ];

        var type = 'personal';
        var targetUser = chatWindow.userId;

        if (chatWindow.groupUsers && chatWindow.groupUsers.length > 0) {
            type = 'group';
            chatUsers = chatWindow.groupUsers;
        }

        var self = this;
        var readStatus = [];
        angular.forEach(chatUsers, function (val, key) {
            if (val.userId == $rootScope.userId) {
                return;
            }

            var unReadStatus = 1;
            if ($rootScope.channelMembers.indexOf(val.username) != -1) {
                unReadStatus = 0;

                var messageFrom = {
                    userId: $rootScope.userId,
                    username: $rootScope.username,
                    channelId: channelId
                };
                if (chatWindow.groupUsers && chatWindow.groupUsers.length > 0) {
                    messageFrom.groupUsers = chatWindow.groupUsers;
                }

                pubnub.publish({
                    channel: 'commusoft_' + $rootScope.clientId + '_' + val.userId,
                    message: {
                        text: self.chatMessage,
                        from: messageFrom,
                        channelId: channelId
                    }
                });
            }
            readStatus.push({
                'userId': val.userId,
                'status': unReadStatus
            })
        });
        storeMessage(readStatus, $rootScope.userId, this.chatMessage, time, channelId, type);

        var msg = {
            'text': self.chatMessage,
            'timestamp': time,
            'formattedTime': moment.unix(Math.round(time / 1000)).format('HH:mm'),
            'from': {
                'userId': $rootScope.userId,
                'username': 'Me'
            }
        }

        $rootScope.chatMessages[chatWindow.channelId].push(msg);
        this.chatMessage = "";
        $location.hash(msg.timestamp);
        $anchorScroll();
        $timeout(function () {
            $location.hash('');
        });
    }

    $scope.chatWindowClicked = function (selectedIndex) {
        this.selectedIndex = selectedIndex;
        if (this.closed === "closed") {
            this.closed = "";
        }
        else {
            this.closed = "closed";
        }
    }
}

function NewMessageController($scope, $rootScope, $http, prefix, $modal, $modalInstance, canLoad) {
    $modalInstance.result.then(function () {
        $scope.close();
    }, function () {
        $scope.close();
    });

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }
    $scope.close = function () {
        $modalInstance.close();
    }

    $scope.select2Options = {
        multiple: true,
        minimumInputLength: 2,
        id: function (obj) {
            return obj.userId;
        },
        ajax: {
            url: prefix + '/searchUser',
            data: function (term, page) {
                return { q: term, filterMe: true };
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        formatResult: function (rec) {
            var userStatus = "online";
            if ($rootScope.channelMembers.indexOf(rec.username) == -1) {
                userStatus = "offline";
            }
            return rec.username + '<div class="' + userStatus + '"></div>';
        },
        formatSelection: function (rec) {
            return rec.username
        }
    }

    function storeMessage(readStatus, from, msg, time, channelId, type) {
        var chatParams = { 'from': from,
            'msg': msg,
            'msgtime': time,
            'type': type,
            'channelId': channelId,
            'readStatus': JSON.stringify(readStatus)
        };
        canLoad.setLoadValue(false);
        $http.post(prefix + '/addChatHistory', chatParams).
            success(function (data, status) {
            });
    }

    $scope.sendMessage = function () {
    var chatMessage = this.message;
    $scope.showWindow = false;

    var groupUsers = [
        {
            userId: $rootScope.userId,
            username: $rootScope.username
        }
    ];
    groupUsers = groupUsers.concat(this.users);

    var channelId = pubnub.uuid();

    // Check if the chatwindow is already present
    var newChat = true;
    if ($rootScope.chatWindows) {
        for (var i = 0; i < $rootScope.chatWindows.length; i++) {
            var val = $rootScope.chatWindows[i];
            if (val.groupUsers && val.groupUsers.length == groupUsers.length) {
                var groupUsernames = jQuery.map(groupUsers, function (elem, idx) {
                    return elem.username;
                });
                groupUsernames.sort();
                var existingGroupUsernames = jQuery.map(val.groupUsers, function (elem, idx) {
                    return elem.username;
                });
                existingGroupUsernames.sort();
                if (angular.equals(groupUsernames, existingGroupUsernames)) {
                    newChat = false;
                    channelId = val.channelId;
                    break;
                }
            } else {
                if (this.users.length == 1 && this.users[0].username == val.username) {
                    newChat = false;
                    channelId = val.channelId;
                    break;
                }
            }
        }
    }

    var readStatus = [];
    var time = moment().valueOf();

    var type = 'personal';
    if (this.users && this.users.length > 1) {
        type = 'group';
    }

    var readStatus = [];
    var self = this;
    angular.forEach(groupUsers, function (val, key) {
        if (val.userId == $rootScope.userId) {
            return;
        }

        var unReadStatus = 1; // Indicates offline

        if ($rootScope.channelMembers.indexOf(val.username) != -1) {
            unReadStatus = 0;

            var messageFrom = {
                userId: $rootScope.userId,
                username: $rootScope.username,
                channelId: channelId
            };
            if (self.users.length > 1) {
                messageFrom.groupUsers = groupUsers;
            }

            pubnub.publish({
                channel: 'commusoft_' + $rootScope.clientId + '_' + val.userId,
                message: {
                    text: chatMessage,
                    from: messageFrom,
                    channelId: channelId

                }
            });
        }

        var newWindow = {
            userId: val.userId,
            username: val.username,
            channelId: channelId
        };
        if (self.users.length > 1) {
            newWindow.groupUsers = groupUsers;
        }

        var msg = {
            'text': chatMessage,
            'timestamp': time,
            'formattedTime': moment.unix(Math.round(time / 1000)).format('HH:mm'),
            'from': {
                'userId': $rootScope.userId,
                'username': 'Me'
            }
        }

        // Create a Local Window for this user, indicating he sent it
        if (newChat && $rootScope.chatMessages[channelId] == undefined) {
            $rootScope.chatWindows.push(newWindow);
            $rootScope.chatMessages[channelId] = [];
        }
        if (key == 1) {
            $rootScope.chatMessages[channelId].push(msg);
        }

        readStatus.push({
            'userId': val.userId,
            'status': unReadStatus
        });
    });

    storeMessage(readStatus, $rootScope.userId, chatMessage, time, channelId, type);
    $modalInstance.close();
}
}

function HandleCsvImports($scope, $modal, warningModal, $translate) {
    $scope.containsHeader = 0;
    $scope.showAdvanced = false;
    $scope.displayError = '';
    $scope.csvUploaded = false;

    $scope.csvSuccess = function (resp) {
        var data = angular.fromJson(resp);
        $scope.csv = data;
        $scope.uploadedFile = data.csvFile;
        $scope.displayError = '';
        $scope.columnHeaders = [];
        $scope.csvUploaded = true;

        if (data.error) {
            $scope.csvError = data.errorMessage;
            $scope.progress = 0;
            return;
        }

        if ($scope.csv['errorMessage'] && $scope.csv['recordCount']) {
            let warningMessage = $translate('import.warning');
            warningModal.show(warningMessage, "Custom data import");
            $('#loading-indicator').hide();
            $scope.csvUploaded = false;
            return;
        }

        if ($scope.csv['worker'] && $scope.csv['workerMessage']) {
            let warningMessage = $translate('worker.already.running.warning');
            warningModal.show(warningMessage, "Custom data import");
            $('#loading-indicator').hide();
            $scope.csvUploaded = false;
            return;
        }

        $scope.csvProps = {
            csvData: $scope.csv,
            fieldNames: $scope.columns,
            numberOfColumnsInCsv: $scope.csv.totalColumns,
            csvSampleRows: _.toArray(angular.fromJson($scope.csv.data)),
            csvFileName: $scope.csv.csvFile,
            csvHasHeaderRow: $scope.containsHeader === 0 ? false : true,
            onSubmit: $scope.submitForm,
            headerText: "Custom data import",
            csvImportHasSaved: false,
            csvImportIsSaving: false,
            onChange: function(csvColumns) {
                $scope.columnHeaders = csvColumns;
            },
            onSave: $scope.submitForm,
            onClose: function() {
                setTimeout(function() {
                    $scope.csvUploaded = false;
                    $scope.$apply();
                }, 2000);
            }
        }
    }
}

function EmailMessageControllers($scope, $http, $state, datasets, $rootScope, prefix) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.tinymceOptions = {
        menubar: false,
        toolbar: "fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright",
        content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
        plugins: "paste,textcolor,autolink",
        paste_as_text: true,
        browser_spellcheck : true,
        paste_block_drop: false,
        fontsize_formats: "6pt 8pt 10pt 12pt 14pt 18pt 24pt 36pt",
        relative_urls : false,
        remove_script_host : false,
        convert_urls : true
    };
    $scope.tinymceSmsOptions = {
        menubar: false,
        toolbar: false,
        statusbar: false,
        content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
        plugins: "paste",
        paste_as_text: true,
        browser_spellcheck : true,
        paste_block_drop: false
    };
    $scope.tinymceLetterOptions = {
        menubar: false,
        toolbar: "fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright",
        content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
        plugins: "paste,textcolor",
        paste_as_text: true,
        browser_spellcheck : true,
        paste_block_drop: false,
        fontsize_formats: "6pt 8pt 10pt 12pt 14pt 18pt 24pt 36pt",
        relative_urls : false,
        remove_script_host : false,
        convert_urls : true
    };
    $scope.tinymceEstimateIntroductionOptions = {
        menubar: false,
        toolbar: "fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright",
        content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
        plugins: "paste,textcolor,legacyoutput",
        paste_as_text: true,
        browser_spellcheck : true,
        paste_block_drop: false,
        fontsize_formats: "1 2 3 4 5 6 7",
        relative_urls : false,
        remove_script_host : false,
        convert_urls : true
    };
    $scope.tinymceEstimateBodyOptions = {
        menubar: false,
        toolbar: "fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright",
        content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
        plugins: "paste,textcolor,legacyoutput",
        paste_as_text: true,
        browser_spellcheck : true,
        paste_block_drop: false,
        fontsize_formats: "1 2 3 4 5 6 7",
        relative_urls : false,
        remove_script_host : false,
        convert_urls : true
    };

    $scope.init = function (formReturnName) {
        /* EmailOptions -  Subject and Message screen */
        $scope.emailOptions = {
            tokenName: formReturnName + '[_token]',
            subjectName: formReturnName + '[subject]',
            emailMessage: formReturnName + '[message]'
        }
        /* SmsOptions -  Message(Single Editor) screen */
        $scope.smsOptions = {
            tokenName: formReturnName + '[_token]',
            smsMessage: formReturnName + '[message]'
        }

        /* EmailSmsOptions -  Email(Subject, Message), Sms(Message) */
        $scope.emailSmsOptions = {
            tokenName: formReturnName + '[_token]',
            subjectName: formReturnName + '[messages][email][subject]',
            emailMessage: formReturnName + '[messages][email][message]',
            smsMessage: formReturnName + '[messages][sms][message]'
        }

        /* EmailSmsLetterOptions -  Email(Subject, Message), Sms(Message), Letter(Message) */
        $scope.emailSmsLetterOptions = {
            tokenName: formReturnName + '[_token]',
            subjectName: formReturnName + '[messages][email][subject]',
            emailMessage: formReturnName + '[messages][email][message]',
            smsMessage: formReturnName + '[messages][sms][message]',
            letterMessage: formReturnName + '[messages][letter][message]'
        }
    }
   //TODO: We need to customise this function
    $scope.checkTickMark = function () {
        if (this.subject != '' || this.Email != '') {
            this.emailCheck = true;
            this.emailText = true;
        }
        else
        {
            this.emailCheck = false;
            this.emailText = false;
        }
        if (this.Sms != '') {
            this.smsCheck = true;
            this.smsText = true;
        }
        else{
            this.smsCheck = false;
            this.smsText = false;
        }
        if (this.Letter != '') {
            this.letterCheck = true;
            this.letterText = true;
        }
        else{
            this.letterCheck = false;
            this.letterText = false;
        }
        if (this.yesEstimate == true) {
            this.estimateCheck = true;
            this.estimateText = true;
        }
        else{
            this.estimateCheck = false;
            this.estimateText = false;
        }
        if (this.Declaration != '') {
            this.declarationCheck = true;
            this.declarationText = true;
        }
        else{
            this.declarationCheck = false;
            this.declarationText = false;
        }
        if (this.EmailSubject != '' || this.Email != '') {
            this.customerEmailCheck = true;
            this.customerEmailText = true;
        }
        else{
            this.customerEmailCheck = false;
            this.customerEmailText = false;
        }
        if (this.WemailSubject != '' || this.Wemail != '') {
            this.workAddressEmailCheck = true;
            this.workAddressEmailText = true;
        }
        else{
            this.workAddressEmailCheck = false;
            this.workAddressEmailText = false;
        }
        if (this.Wsms != '') {
            this.workAddressSmsCheck = true;
            this.workAddressSmsText = true;
        }
        else{
            this.workAddressSmsCheck = false;
            this.workAddressSmsText = false;
        }
        if (((this.serviceRemindersEmailsubject) && this.serviceRemindersEmailsubject != '') ||
            ((this.serviceRemindersEmailmessage) && this.serviceRemindersEmailmessage != '') ||
            ((this.followUpEmailsubject) && this.followUpEmailsubject != '') ||
            ((this.followUpEmailmessage) && this.followUpEmailmessage != '') ||
            ((this.workAddressMessagessubject) && this.workAddressMessagessubject != '') ||
            ((this.workAddressMessagesEmail) && this.workAddressMessagesEmail != '') ||
            ((this.workAddressSubject) && this.workAddressSubject != '') ||
            ((this.workAddressEmail) && this.workAddressEmail != '')){
            this.email_done = true;
        }
        else{
            this.email_done = false;
        }
        if (((this.serviceRemindersSmsmessage) && this.serviceRemindersSmsmessage != '') ||
            ((this.followUpSmsmessage) && this.followUpSmsmessage != '') ||
            ((this.workAddressMessagesSms) && this.workAddressMessagesSms != '') ||
            ((this.workAddressSms) && this.workAddressSms != '')){
            this.sms_done = true;
        }
        else{
            this.sms_done = false;
        }
        if (((this.serviceRemindersLettermessage) && this.serviceRemindersLettermessage != '') ||
            ((this.followUpLettermessage) && this.followUpLettermessage != '') ||
            ((this.workAddressMessagesLetter) && this.workAddressMessagesLetter != '') ||
            ((this.workAddressLetter) && this.workAddressLetter != '')) {
            this.letter_done = true;
        }
        else{
            this.letter_done = false;
        }
        if (((this.debtchasingcommunicationsubject) && (this.debtchasingcommunicationsubject != '')) || ((this.debtchasingcommunicationEmail) && (this.debtchasingcommunicationEmail != ''))){
            this.email_done_message = true;
        }
        else{
            this.email_done_message = false;
        }
        if (((this.debtchasingsubject) && (this.debtchasingsubject != '')) || ((this.debtchasingEmail) && (this.debtchasingEmail != ''))){
            this.email_done_debt = true;
        }
        else{
            this.email_done_debt = false;
        }
        if (((this.debtchasingcommunicationSms) && (this.debtchasingcommunicationSms != ''))){
            this.sms_done_message = true;
        }
        else{
            this.sms_done_message = false;
        }
        if(((this.debtchasingSms) && (this.debtchasingSms != ''))){
            this.sms_done_debt = true;
        }else{
            this.sms_done_debt = false;
        }
        if (((this.debtchasingcommunicationLetter) && (this.debtchasingcommunicationLetter != ''))){
            this.letter_done_message = true;
        }
        else{
            this.letter_done_message = false;
        }
        if(((this.debtchasingLetter) && (this.debtchasingLetter != ''))){
            this.letter_done_debt = true;
        }else{
            this.letter_done_debt = false;
        }
    }

    // Open the image upload modal
    $scope.image_modal_open = false;
    $scope.openImageModal = function openImageModal() {
        $scope.image_modal_open = true;
    }
}

function ChatMessageMainCtrl($scope, $http, $state, datasets, $rootScope, dateRange, prefix, $modal, tableCollection, searchPageNumName) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix, $modal, tableCollection, searchPageNumName);
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.end_date = moment();
    $scope.start_date = moment();
    $scope.active_channel = null;
    $scope.$on("openChat", function (event, value) {
        $scope.chat = {
            'message': value.message,
            'userId': value.userId,
            'name': value.name,
            'timeformat': moment(value.timeformat.date).format("h:mm a"),
            'channelid': value.channelid
        };
    });
    $scope.show_Conversations = function (channelid, element) {
        $scope.active_channel = channelid;
        $state.transitionTo('loggedin.chat_message_view.show', {'channelid': channelid});
    }
    $scope.chatDetails = [];
    $scope.$on("dateRangeFilter", function (event, message) {
        $scope.show_daterange = true;

        if (message) {
            if (message.startdate != undefined && message.startdate != "" &&
                message.enddate != undefined && message.enddate != "") {
                $scope.dateValue = {'startDate': message.startdate + ' 00:00:00', 'endDate': message.enddate + ' 23:59:59'};
                $scope.selectedRange = message.string;
                $scope.startDate = message.startdate;
                $scope.endDate = message.enddate;

                var url = prefix + '/listChatHistory?startDate=' + message.startdate + '&endDate=' + message.enddate + '&searchText=' + $scope.searchText;
                $scope.chatDetails = [];
                $http.get(url).success(function (data) {
                    $scope.$broadcast('subdatefilter', data);
                });
            } else {
                $scope.dateValue = undefined;
                var url = prefix + '/listChatHistory?searchText=' + $scope.searchText;
                $scope.chatDetails = [];
                $http.get(url).success(function (data) {
                    $scope.$broadcast('subdatefilter', data);
                });
            }

        }
    });
}

function ChatPrintCtrl($scope, $state) {
    $scope.channelid = $state.params.channelid;
}

function ChatMessageViewCtrl($scope, $http, $state, datasets, $rootScope, prefix, canLoad, $q, chathistoryresolver) {
    $scope.chathistory = chathistoryresolver.chatDetails;
    $scope.loading = false;
    $scope.$on("subdatefilter", function (event, message) {
        $scope.chatDetails = [];
        $scope.show_Conversations();
        ChatConversationCtrl.call(this, $scope, message.chatDetails);
    });

    ChatConversationCtrl.call(this, $scope, $scope.chathistory);
    var canceler = null;
    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.loading = true;
            $scope.chatDetails = [];
            canLoad.setLoadValue(false);  // Turn off AJAX
            if (canceler) {
                canceler.resolve();
            }
            canceler = $q.defer();

            var url = prefix + '/listChatHistory?searchText=' + $scope.searchText;

            $http.get(url, {timeout: canceler.promise}).success(function (data) {
                $scope.loading = false;
                ChatConversationCtrl.call(this, $scope, data.chatDetails);
            });
        }
    });
}

function ChatConversationCtrl($scope, data) {
    var i = 0;
    if (data != '') {
        angular.forEach(data, function (value, key) {
            $scope.chatList = {
                'users': value.users,
                'channelid': value.channelid,
                'chatdate': value.startTime,
                'chattime': value.startTime,
                'unread': value.unread
            };
            $scope.chatDetails.push($scope.chatList);
            if (i == 0) {
                $scope.show_Conversations(value.channelid);
            }
            i++;
        });
    } else {
        $scope.show_Conversations();
    }
}

function ChatMessageShowCtrl($scope, $http, $state, datasets, $rootScope, prefix) {
    $scope.channelid = $state.params.channelid;

    $http.post(prefix + '/singleChatConversation', "channelid=" + $scope.channelid).
        success(function (data, status) {
            if (status == 200) {
                $scope.singleconversations = [];
                angular.forEach(data.chatresult, function (value, key) {
                    $scope.chat = {
                        'message': value.message,
                        'userId': value.id,
                        'name': value.name,
                        'timeformat': value.fromTime.date,
                        'monthformat': moment(value.fromTime.date).format("MMMM DD"),
                        'profileimage': value.profilepicture,
                        'channelid': $scope.channelid
                    };
                    $scope.singleconversations.push($scope.chat);
                });
                $scope.chat = $scope.singleconversations[$scope.singleconversations.length - 1];
                $scope.$emit('openChat', $scope.chat);
            }
        });
}

function SetupEmailMessageControllers($scope, $http, $state, datasets) {
    SetupRootCtrl.call(this, $scope, $http, $state, datasets);
    $scope.tinymceOptions = {
        plugins: "link,image,paste,textcolor",
        menubar: false,
        content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
        toolbar: "fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright",
        paste_as_text: true,
        paste_block_drop: false,
        browser_spellcheck : true
    };
}

function EstimateEmailCtrl($scope, $http, $state, datasets, $rootScope) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
}

function NoAccessMessageListCtrl($scope) {
    $scope.customerEmail = true;
    $scope.customerSms = false;
    $scope.customerWemail = false;
    $scope.customerWsms = false;

    $scope.emailTo = function () {
        $scope.customerSms = false;
        $scope.customerWemail = false;
        $scope.customerWsms = false;
        $scope.customerEmail = true;
        tinyMCE.get("CustomerEmailId").focus();
    }

    $scope.smsTo = function () {
        $scope.customerEmail = false;
        $scope.customerWemail = false;
        $scope.customerWsms = false;
        $scope.customerSms = true;
        tinyMCE.get("CustomerSmsId").focus();
    }


    $scope.workAddressEmailTo = function () {
        $scope.customerEmail = false;
        $scope.customerSms = false;
        $scope.customerWsms = false;
        $scope.customerWemail = true;
        tinyMCE.get("WorkAddressEmailId").focus();
    }

    $scope.WorkAddressSmsType = function () {
        $scope.customerEmail = false;
        $scope.customerSms = false;
        $scope.customerWemail = false;
        $scope.customerWsms = true;
        tinyMCE.get("WorkAddressSmsId").focus();
    }
    $scope.controlscope = function () {
        tinyMCE.get("CustomerEmailId").focus();
    }
}

function ReminderListCtrl($scope) {
    $scope.reminderSms = false;
    $scope.reminderLetter = false;
    $scope.reminderEmail = true;

    //Show the SMS Screen
    $scope.smsTo = function () {
        $scope.declarationCheck = true;
        $scope.declarationText = true;
        $scope.reminderSms = true;
        $scope.reminderLetter = false;
        $scope.reminderEmail = false;
        tinyMCE.get("SmsId").focus();
    }
    //Show the Letter Screen
    $scope.letterTo = function () {
        $scope.reminderSms = false;
        $scope.reminderLetter = true;
        $scope.reminderEmail = false;
        tinyMCE.get("LetterId").focus();
    }
    //Show the Email Screen
    $scope.emailTo = function () {
        $scope.estimateCheck = true;
        $scope.estimateText = true;
        $scope.reminderSms = false;
        $scope.reminderLetter = false;
        $scope.reminderEmail = true;
        tinyMCE.get("EmailId").focus();
    }

    $scope.controlscope = function () {
        tinyMCE.get("EmailId").focus();
    }
    $scope.turnOn = function(){
        $scope.reminderEmail = true;
        $scope.reminderSms = false;
        $scope.estimateCheck = true;
        $scope.estimateText = true;
        $scope.reminderLetter = false;
        angular.element('#online-estimate-turn-on').addClass("active");
        angular.element('#online-estimate-declaration').removeClass("active");
    }
    $scope.declaration = function(){
        $scope.declarationCheck = true;
        $scope.declarationText = true;
        $scope.reminderSms = true;
        $scope.reminderLetter = false;
        $scope.reminderEmail = false;
        tinyMCE.get("SmsId").focus();
        angular.element('#online-estimate-declaration').addClass("active");
        angular.element('#online-estimate-turn-on').removeClass("active");
    }
}

function WorkAddressEstimateConfirmationListCtrl($scope) {
    $scope.workaddressestimateconfirmationSms = false;
    $scope.workaddressestimateconfirmationLetter = false;
    $scope.workaddressestimateconfirmationEmail = true;

    //Show the SMS Screen
    $scope.smsTo = function () {
        $scope.workaddressestimateconfirmationSms = true;
        $scope.workaddressestimateconfirmationLetter = false;
        $scope.workaddressestimateconfirmationEmail = false;
        tinyMCE.get("SmsId").focus();
    }
    //Show the Letter Screen
    $scope.letterTo = function () {
        $scope.workaddressestimateconfirmationSms = false;
        $scope.workaddressestimateconfirmationLetter = true;
        $scope.workaddressestimateconfirmationEmail = false;
        tinyMCE.get("LetterId").focus();
    }
    //Show the Email Screen
    $scope.emailTo = function () {
        $scope.workaddressestimateconfirmationSms = false;
        $scope.workaddressestimateconfirmationLetter = false;
        $scope.workaddressestimateconfirmationEmail = true;
        tinyMCE.get("EmailId").focus();
    }

    $scope.controlscope = function () {
        tinyMCE.get("EmailId").focus();
    }
}

function StageController($scope) {
    $scope.$on("stageNumberEvent", function (event, stage) {
        $scope.stage = stage;
    });
}

function LogoutCtrl($scope, $stateParams, $http, $state, $location, $rootScope, datasets) {
    $rootScope.sessionId = null;
    $rootScope.previousUrl = '';
    $rootScope.changableState = false;
    $scope.data = datasets;

    // remove token from storage
    try { $rootScope.msClearToken(); }
    catch(err) {}
}

function ContractRootCtrl($scope, $http, $state, setupDatasets) {
    $scope.data = setupDatasets;

    $scope.$watch("stageNumber", function () {
        $scope.$emit('stageNumberEvent', $scope.stageNumber);
    });

    $scope.formSubmit = function (formElem) {
        $scope.error = false;
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                if (data == "Invalid") {
                    $scope.error = true;
                    $scope.checking = false;
                } else {
                    if (data.location) {
                        $scope.data = data.location.replace(/\//g, '');
                        if ($scope.data == 'companydetails') { //If we finish contract, redirect to setup_wizard
                            $state.transitionTo('setupwizard.' + $scope.data);
                        } else {
                            $state.transitionTo('contract.' + $scope.data);
                        }
                    } else {
                        $scope.data = data;
                    }
                }
            }).
            error(function (data, status) {
                $scope.data = data;
            });
    }
}

function FirstNumberCtrl($scope, $http, $state) {
    $scope.textBoxValue = 0;
    $scope.numberType = 2;

    $scope.changeValues = function (value) {
        $scope.value = value;
        $scope.showCustomValue = false;
        $scope.selectedNumber = value;
    };
    $scope.$watch('selectedNumber', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.value = newVal;
        }
    });

    $scope.customOptionClick = function () {
        $scope.value = $scope.textBoxValue;
        $scope.showCustomValue = true;
        $scope.selectedNumber = 'custom';
    };

    // Used for first invoice number
    $scope.$watch('startingfrom', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.value = newVal;
        }
    });

    $scope.manualInvoiceNumber = function(value) {
        $scope.value = value;
        $scope.startingfrom = value;
        $scope.numberType = 2;
    };
    $scope.sequentialInvoiceNumber = function() {
        $scope.numberType = 1;
    };
}

function ErrorCtrl($scope, $http, $state, datasets, $rootScope, setFormName, prefix) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.postProcessCheck = function (data, status) {
        if (data.error) {
            $scope[setFormName].$error.checkMaximumValue = true;
            $scope.saving = false;
            return false;
        } else {
            return true;
        }
    }
}

function BranchMergeAndDeleteCtrl($scope) {
    $scope.triggerDisable = function (branchId, selectedId, branchName, name) {
        $scope.branchId = branchId;
        $scope.selectedId = selectedId;
        $scope.branchname = branchName;
        $scope.contentMessage = true;
        $scope.titleName = 'Disable Branch';
        if (name == 'customer') {
            $scope.redirectUrl = '/disable_customerbranch';
        } else if (name == 'supplier') {
            $scope.redirectUrl = '/disable_supplierbranch';
        }
        $scope.buttonValue = 'Confirm Disable Branch';
        $scope.textToConfirm = 'DISABLE';
        $scope.validationMessage = "'DISABLE' to Disable this branch";
        $scope.shouldBeOpenMergeAndDisable = true;
    }
    $scope.triggerMerge = function (branchId, selectedBranchId, selectedId, branchName, branches, name, branchDetails) {
        $scope.branchId = branchId;
        $scope.selectedId = selectedId;
        $scope.mergeId = selectedBranchId;
        $scope.branchname = branchName;
        $scope.branches = branches;
        $scope.contentMessage = false;
        $scope.branchDetails = branchDetails;
        $scope.titleName = 'Merge branch';
        if (name == 'customer') {
            $scope.redirectUrl = '/merge_customerbranch';
        } else if (name == 'supplier') {
            $scope.redirectUrl = '/merge_supplierbranch';
        }
        $scope.buttonValue = 'Confirm merge branch';
        $scope.textToConfirm = 'MERGE';
        $scope.validationMessage = "'MERGE' to Merge this branch";
        if (selectedBranchId) {
            $scope.shouldBeOpenMergeAndDisable = true;
        }
    }
}

function PostcodeSearchCtrl($scope, $http, prefix, $rootScope) {

    $scope.$on('smartpostcode:address', function(evt,data) {
        if(!$rootScope.shouldBeOpenLord) {
            $scope.addressline1 = data.addressLine1;
            $scope.addressline2 = data.addressLine2;
            $scope.addressline3 = data.addressLine3;
            $scope.town = data.town;
            $scope.county = data.county;
            $scope.postcode = data.postcode;
        }
    });

    $scope.$on('PostcodeData', function (evt, data) {
        if(!$rootScope.shouldBeOpenLord) {
            $scope.addressline1 = data.addressLine1;
            $scope.addressline2 = data.addressLine2;
            $scope.addressline3 = data.addressLine3;
            $scope.town = data.town;
            $scope.county = data.county;
            $scope.shouldBeOpen = false;
        }

    });
}

function EditProfileCtrl($scope, $stateParams, $location, $http, $state, datasets, prefix, $rootScope, formService, $upload, toastBox) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $rootScope.toState = $rootScope.previousState;
    $scope.displayError = false;
    $scope.displaySuccess = false;
    $scope.shouldBeOpen = false;
    $scope.addressArray = [];
    $scope.error = false;
    $scope.errorMessage = '';
    $scope.selectedId = 'currentUser';
    $scope.previousImage = '';
    $scope.openResizeModal = false;
    $scope.imageResizeOptions = { orgHeight: 500, orgWidth: 500, reqHeight: 100, reqWidth: 100, ratio: '1:1' };

    $scope.imageResizeSave = function (resp) {
        $scope.oldprofilepicture = resp;
        $scope.$emit('REFRESH_PROFILE_PIC');
        $scope.displaySuccess = true;
        $scope.openResizeModal = false;
        toastBox.show('Image uploaded', 4000);
    }

    $scope.onUploadSuccess = function (resp) {
        if (!resp.error) {
            /*if (resp.message.saved) {
                $scope.oldprofilepicture = resp.message.file;
                $scope.displayError = '';
                $scope.$emit('REFRESH_PROFILE_PIC');
                $scope.displaySuccess = true;
            }*/
            $scope.profilepicture = resp.message.file;
            $scope.displayError = false;
            $scope.imageResizeOptions.orgHeight = resp.message.height;
            $scope.imageResizeOptions.orgWidth = resp.message.width;
            $scope.openResizeModal = true;
        } else {
            $scope.errorMessage = resp.message;
            $scope.displayError = true;
            $scope.displaySuccess = false;
        }
    }

    var action = "/upload_profile_picture";
    handleUploads.call(this, $scope, prefix, $upload, action);

    PostCodeCtrl.call(this, $scope, $state, $http, prefix);

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.editUserForm['user[username]'].$error.checkExists = true;
                $scope.editUserForm.$setValidity(false);
            } else {
                //TODO: Need to add redirect here
                //$scope.$emit('REFRESH_PROFILE_PIC');
            }
            $scope.saving = false;
        });
    }

    $scope.submitForm = function () {
        angular.element("#submitForm").trigger('click');
    }
}



function handleUploads($scope, prefix, $uploads, action) {
    $scope.ondrag = false;

    $scope.$on("event:DragOver", function () {
        $scope.ondrag = true;
        $scope.$apply();
    });

    $scope.openCloseDragDesign = function () {
        $scope.ondrag = false;
    }

    $scope.onFileSelect = function ($files) {
        $scope.ondrag = false;
        for (var i = 0; i < $files.length; i++) {
            var file = $files[i];
            var fileName = file.name;
            var extension = fileName.split('.').pop();
            var ext = extension.toLowerCase();
            var allowed = true;
            var progress = 0;
            if (typeof $scope.excludedeTypes != "undefined") {
                if ($scope.excludedeTypes.length) {
                    for (var index = 0; index < $scope.excludedeTypes.length; index++) {
                        if ($scope.excludedeTypes[index] == ext) {
                            allowed = false;
                        }
                    }
                }
            } else {
                allowed = false;
            }
            if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || allowed) {
                $scope.upload = $uploads.upload({
                    url: prefix + action,
                    method: 'POST',
                    file: file
                }).progress(function (evt) {
                        progress = parseInt(100.0 * evt.loaded / evt.total);
                        angular.element('.upload-progress').text(progress + '%');
                    }).success(function (data, status, headers, config) {
                        if (i == $files.length) {
                            $scope.onUploadSuccess(data);
                        }
                    });
            } else {
                var data = {error: true, message: 'Please upload a valid image file, should be in the format of jpg or jpeg or png'};
                $scope.onUploadSuccess(data);
            }
        }
    };
}

function ProgressLevelCtrl($scope, $stateParams, $location, $http, $state, getIdData, prefix, $rootScope, $timeout) {
    $rootScope.state_progress = "levels";

    $scope.progress = getIdData.progress;
   // $scope.colleagues = {};
    $scope.currentTask = getIdData.currentTask;

    $scope.class = "inactive";
    $scope.changeClass = function(){
        if ($scope.class === "inactive")
            $scope.class = "active";
        else
            $scope.class = "inactive";
    }

    $scope.initCurrentLevel = function(){
        $timeout(function() {

            var levelsContainer = document.getElementById('levelContainer');
            var level = document.getElementsByClassName('current-level');
            var levelPos = level[0].offsetTop;
            scrollTo(levelsContainer, levelPos - 30, 500);
        }, 400);
    }

    $scope.skipTask = function(taskId) {
        $scope.selectedTaskId = taskId;
        $scope.shouldBeOpenUnnecessary = true;
    }

    $scope.fetchProgressTask = function () {
        $http.get(prefix + '/progress/levels').success(function (data) {

            $scope.progress = data.progress;
            $scope.currentTask = data.currentTask;
        })
    }
}

function ProgressColleaguesCtrl($scope, $stateParams, $location, $http, $state, prefix, $rootScope, getIdData, $timeout) {
    $rootScope.state_progress = "colleagues";
    $scope.colleagues = getIdData.colleagues;
    $scope.currentUser = getIdData.currentUser;

    $scope.progressBeginner = getIdData.progressBeginner;
    $scope.progressGetting = getIdData.progressGetting;
    $scope.progressCompetent = getIdData.progressCompetent;
    $scope.progressAdvanced = getIdData.progressAdvanced;
    $scope.progressGenius = getIdData.progressGenius;

    $scope.runtimelineanimation = 'true';
    $scope.runavataranimation = 'true';

    $scope.updateCurrentUser = function(data, event){
        // fix the tooltip
        $('.tooltip').removeClass("active");
        $(event.target).parent().parent().parent().find('.tooltip').addClass("active");
        // scroll to the current timeline item and update the scope
        $scope.useractive = data;
        var timelineContainer = document.getElementById('timelineid');
        var timelineItem = document.getElementsByClassName(data);
        var timelineItemPos = timelineItem[0].offsetTop;
        scrollTo(timelineContainer, timelineItemPos - 70, 400);
        // animate the particular level entry in the timeline with a CSS class
        var timelineItemContent = document.querySelectorAll(".item-inner"),
            len = timelineItemContent !=null ? timelineItemContent.length : 0,
            i = 0;
        for(i;i < len; i++){
            timelineItemContent[i].className = "item-inner";
        }
        timelineItemContent[data-1].className = timelineItemContent[data-1].className + " active";
    }

    $scope.initCurrentUser = function(){
        // scroll to the current timeline item and update the scope
        $scope.useractive = $scope.currentUser;
        var timelineContainer = document.getElementById('timelineid');
        var timelineItem = document.getElementsByClassName( $scope.currentUser);
        var timelineItemPos = timelineItem[0].offsetTop;
        scrollTo(timelineContainer, timelineItemPos - 70, 500);

        // animate the particular level entry in the timeline with a CSS class
        var timelineItemContent = document.querySelectorAll(".item-inner"),
            len = timelineItemContent !=null ? timelineItemContent.length : 0,
            i = 0;
        for(i;i < len; i++){
            timelineItemContent[i].className = "item-inner";
        }
        timelineItemContent[ $scope.currentUser-1].className = timelineItemContent[ $scope.currentUser-1].className + " active";
    }

    $timeout(function(){
        $scope.initCurrentUser();
    },500);

    //triggering timeline animation on page load
    $scope.triggerTimeline = false;
    $scope.initTimeline = function(){
        $scope.triggerTimeline = false;
        $timeout(function(){
            $scope.triggerTimeline = true;
        }, 0);
    };
}

function scrollTo(element, to, duration) {
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 2;

    var animateScroll = function(){
        currentTime += increment;
        var val = Math.easeInOutCirc(currentTime, start, change, duration);
        if (element) {
            element.scrollTop = val;
        }
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

Math.easeInOutCirc = function (t, b, c, d) {
    if (t==0) return b;
    if (t==d) return b+c;
    if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
    return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
};

function PostCodeCtrl($scope, $state, $http, prefix) {

    $scope.updatePostcode = function () {
        $scope.postcode = this.postcode;
    }

    $scope.$on('smartpostcode:address', function(evt,data) {
        $scope.addressline1 = data.addressLine1;
        $scope.addressline2 = data.addressLine2;
        $scope.addressline3 = data.addressLine3;
        $scope.town = data.town;
        $scope.county = data.county;
        $scope.postcode = data.postcode;

        if($scope.countyLists)
        {
            //Defining prior before calling it,Set the state province id if exsists or insert a new one and set it.
            $scope.setSettingsstateprovinceid = function(){
                setTimeout(function() {
                    $scope.settingsstateprovinceid = counties.id;
                    if($scope.screenType == 'newProperty'){
                        $('select[ng-model="settingsstateprovinceid"]').val(counties.id).trigger('change');
                    }else{
                        $scope.countyId = counties.id;
                    }
                },300)
            }

            $scope.countyId = '';
            $scope.settingsstateprovinceid = '';
            var counties = _.findWhere($scope.countyLists,{'description':data.county});
            if(data.county && typeof counties == 'undefined'){
                $http.post(prefix + '/insert_new_state_provinces?newCounty=' + $scope.county).success(function (data) {
                    counties = {'id':data.countyNewID,'description':data.desc};
                    $scope.countyLists.push(counties);

                    //Manually adding option to invoice address form  and supplier form as it rendered via twig
                    if($scope.hasOwnProperty("newInvoiceForm") || $scope.hasOwnProperty("newSupplierForm") || $scope.hasOwnProperty("newBranchForm")){
                        var newOption = new Option(counties.description, counties.id, false, false);
                        $('select[ng-model="settingsstateprovinceid"]').append(newOption).trigger('change');
                    }
                }).then(function(){
                    $scope.setSettingsstateprovinceid();
                });
            }else{
                $scope.setSettingsstateprovinceid();
            }
        }
    });

}


function DeleteUserCtrl($scope, warningModal) {
    $scope.shouldBeOpenUser = false;
    $scope.triggerDeleteUser = function (id) {
        var data = $scope.deleteWarningMessage;
            if(data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            } else {
                $scope.selectedId = id;
                $scope.shouldBeOpenUser = true;
            }
    }
}


function DatePickerWidget($scope, $element, $rootScope, clickEvents, $filter) {
    $scope.show_date_picker = false;
    $scope.element = $element;
    //# Avoice the second call if have 2 date picker in a single screen.
    $scope.valueChanged=[];

    $scope.$watch('show_date_picker', function() {
        if ($scope.show_date_picker === true) {
            clickEvents.registerIgnoreElements('datepicker');
        }else{
            $rootScope.$emit('datepicker_selected', {
                date : $scope[$scope.extractDateModelRef($element)],
                element : $scope.element,
                modelName : $scope.extractDateModelRef($element)
            });
        }
    });
    $scope.extractDateModelRef = function extractDateModelRef(element) {
        var date_input = element[0].querySelector('#datepicker-input');
        $scope.model_ref = date_input.getAttribute('ng-model');
        return $scope.model_ref;
    }

    $scope.$on('closeallwithapply', function(event) {
        $scope.$apply(function() {
            $scope.show_date_picker = false;
        });
        $('.custom-datepicker').removeClass('show-above');
        //# Changes for spreadsheet screen.
        if($scope.valueChanged[$scope.modelName]) {
            // used in the panel with form directive
            $scope.$emit('datepicker:datepicker_selected', {
                date : $scope.selectedDate,
                element : $scope.element,
                name : $scope.modelName
            });
        }
        $scope.valueChanged[$scope.modelName] = false;
    });

    $scope.$on('closeall', function(event) {
        $scope.show_date_picker = false;
        $('.custom-datepicker').removeClass('show-above');
    });

    $scope.$on('event:close-side-panel-date-picker', function(event, message) {
        if(message.name == $scope.modelName && $scope.show_date_picker) {
            $scope.show_date_picker = false;
            $('.custom-datepicker').removeClass('show-above');
        }
    });

    $scope.$watch('selectedDate', function(newVal, oldVal){
        //# Changes for spreadsheet screen
        $scope.valueChanged[$scope.modelName] = $scope.show_date_picker;
        if($scope.modelName && newVal) {
            var splitName = $scope.modelName.split('.');
            if(splitName.length == 1) {
                $scope[$scope.modelName] = $filter($scope.filterName)(newVal);
            } else {
                //Todo: Need to handle the nested object, currently supplorts for single property
                $scope[splitName[0]][splitName[1]] = $filter($scope.filterName)(newVal);
            }
        }
    });

    //# Reset the date.
    $scope.$on("resetDate", function(event) {
        $scope.selectedDate = new Date();
    });

    $scope.$on("event:change_default_selected_date", function (event, message) {
        $scope.selectedDate = new Date(moment(message.date.date));
        $scope.$emit('datepicker:date_updated');
    });

    $scope.$on("event:change_specified_date", function (event, message) {
        var scopeName = message.scopeName;
        var dateToSet = message.date;
        if($scope.modelName && (scopeName == $scope.modelName) && dateToSet) {
            dateToSet = moment(dateToSet);
            $scope.selectedDate = dateToSet;
            //$scope[scopeName] = $filter($scope.filterName)(dateToSet);
        }
    });
};

function SetRootScopeValues($rootScope, headers, PubNub, cacheBustProvider) {
    // Set CacheBust if it exists
    if (headers['x-csversion'] && parseInt(headers['x-csversion']) != parseInt(cacheBustProvider.cache.cacheBust)) {
        cacheBustProvider.setBustValue(parseInt(headers['x-csversion']));
        window.location.reload();
    }

    if (headers['x-clientid'] != $rootScope['clientId']) {
        // Call the permissions handler to re-trigger get_access_permissions. This happens when you
        // login again but the get_access gets called before the session is authenticated.
        $rootScope.$broadcast('event:auth-loginConfirmed');
        $rootScope.clientId = headers['x-clientid'];
        $rootScope.username = headers['x-username'];
        $rootScope.userId = headers['x-userid'];
        $rootScope.sessionId = headers['x-sessionid'];
        $rootScope.userLanguage = headers['x-userlanguage'];
        moment.lang($rootScope.userLanguage);
        $rootScope.userTimezone = headers['x-usertimezone'];

        if ($rootScope.clientId && $rootScope.username && $rootScope.userId) {
            // Logout the other users
            if (!pubnub) {
                pubnub = $rootScope.initializePubnub();
            }
            if (pubnub){
                $rootScope.publishLogoutEventToCommusoft();
            }

        }
    }
}
//endregion

function SettingsCtrl($scope, $http, $state, datasets, $rootScope, $timeout, prefix, $location) {
    $scope.hasNewUIMenuPermission = 1;
    $location.path("/settings");
    return false;
    $scope.modulePermissionsCheck = $rootScope.moduleDetails['workflow'];
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $timeout(function () {
        $('div.additional:visible:first').addClass('general');
    }, 200);
    
}

var ReportResolver = {
    datasets: function ($q, $http, $stateParams, $state, $rootScope, $timeout, PubNub, reportFilterSettings, $cookies, $location, reportNavSidebar, cacheBust, prefix, adminPrefix) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var inputTags = {};

        var deferred = $q.defer();
        var savedFiltersFound = false;
        var that = this;
        var isFlag = _.has($stateParams,'flag') ? $stateParams['flag'] : false;
        reportNavSidebar.getReportingSubnavItems(isFlag).then(function(subnavItems) {
            /* The below is used for loading filters in saved reports. Continue work on this later. */
            console.log('subnavitems');
            console.log(subnavItems);
            // Resetting saved filters before loading new report page
            reportFilterSettings.savedFilters = false;

            angular.forEach(subnavItems, function(category, index) {
                if (category.id == $stateParams.category) {
                    angular.forEach(category.secondTier, function(secondTierItem, index) {
                        if (secondTierItem.id == $stateParams.subcategory) {
                            if (secondTierItem['custom']) {
                                // This means this is a saved report
                                savedFiltersFound = true;
                                //inputTags = reportFilterSettings.simplifyFilters(secondTierItem.filters);
                                reportFilterSettings.addSavedFilters(secondTierItem.filters).then(function(resp) {
                                    return resp;
                                }, function(error) {
                                    throw error;
                                });
                            }
                        }
                    });
                }
            });

            /* If the user has used filter searches before, their filters will have been stored in a cookie. Fetch the filters from that cookie if it exists. */

            /* See https://stackoverflow.com/a/23088644 */
            /* Because $state.current.name is empty at the time this code runs, we need to fetch it from 'this' instead of $state */
            var currentStateName = that.self.name;

//                if (reportFilterSettings.queryParamsExist() || reportFilterSettings.savedFilters) {
            //inputTags = reportFilterSettings.getQueryParamsWithoutParsing();
//                }
            /*
             else {
             if ($cookies['reportingFilters_' + currentStateName + "_" + $stateParams.category + "_" + $stateParams.subcategory]) {
             var cookieTags = angular.fromJson($cookies['reportingFilters_' + currentStateName + "_" + $stateParams.category + "_" + $stateParams.subcategory])['reporting'];
             angular.forEach(cookieTags, function(tag, index) {
             var model = tag['model'];
             inputTags[model] = tag['value'];
             });
             }
             }
             */
            inputTags = reportFilterSettings.getAppliedFilters({'category': $stateParams.category, 'subcategory': $stateParams.subcategory});
            if (Object.keys(inputTags).length == 0) {
                inputTags = null;
            }

            var URLtofetch = false;
            var operational = false;

            if ($stateParams.category.indexOf('reporting_operational_list') > -1) {
                URLtofetch = prefix+'/' + $stateParams.subcategory + '_operational_list?page=1&limit=20&status=' + $stateParams.type;
                operational = true;
            }
            else {
                URLtofetch = that.templateUrl($stateParams) + '?page=1&limit=20';
            }
            $http.post(URLtofetch, 'filter=' + encodeURIComponent(angular.toJson(inputTags)))
                .success(function (data, status, headersObj) {
                    data.initcall=true;
                    // fetch data.appliedfilters here later
                    // marker

                    if (!operational && savedFiltersFound) {
                        // If it's a saved report, try to retrieve the list of available dropdown filters from its parent report.
                        // For example, if this is a saved custom report based off of the Sales report, then retrieve the list of available filters from the Sales report.
                        var parentReportName = data['reporting_' + $stateParams.category + '_' + $stateParams.subcategory]['from'];
                        if (parentReportName) {
                            data['filter']['reporting_' + $stateParams.category + '_' + $stateParams.subcategory] = angular.copy(data['filter'][parentReportName]);
                        }
                    }

                    if (data.location && data.location == 'logout') {
                        // Handle chat unregistration here as below the rootScope will be nulled
                        $rootScope.$broadcast('chat:unregister');
                    }

                    var headers = headersObj();
                    SetRootScopeValues($rootScope, headers, PubNub, cacheBust, $http, prefix, adminPrefix, $state);

                    if (data.location) {
                        deferred.resolve("");
                        var slashCount = (data.location.match(/\//g) || []).length;
                        //Special case for Login: TODO: Fix this better
                        if(slashCount > 1 && !data.location.endsWith("/")) {
                            if(!$rootScope.stateToRedirectFromLogin) {
                                $rootScope.stateToRedirectFromLogin = 'home';
                                $rootScope.paramsToRedirectFromLogin = {};
                            }
                            $state.transitionTo($rootScope.stateToRedirectFromLogin, $rootScope.paramsToRedirectFromLogin,{reload:true});
                        } else if (data.location.endsWith("/")) {
                            //$state.transitionTo('home');
                        } else if (data.location.startsWith("https://") || data.location.startsWith("http://")) {
                            window.location.href = data.location;
                        } else if (data.location == 'logout') {
                            $state.transitionTo('home');
                        } else if (data.location == '/companydetails') {
                            var data = data.location.replace(/\//g, '');
                            $state.transitionTo('setupwizard.' + data);
                        } else {
                            var data = data.location.replace(/\//g, '');
                            var additionalOptions = {};
                            if($state.current.name == data) {
                                additionalOptions = {reload: true};
                            }
                            $state.transitionTo('loggedin.' + data, {}, additionalOptions);
                        }
                    } else {
                        deferred.resolve(data);
                    }
                });
        });
        return deferred.promise;
    }
};

var Resolver = {
    datasets: function ($q, $http, $stateParams, $state, $rootScope, $timeout, PubNub, cacheBust) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }

        // Set logout error message
        if ($rootScope.nextUrl.startsWith('/logout') && $stateParams.errorMessage) {
            $rootScope.errorMessage = $stateParams.errorMessage;
        }

        var deferred = $q.defer();
        $http.get(this.templateUrl($stateParams)).success(function (data, status, headersObj) {
            if (data.location && data.location == 'logout') {
                // Handle chat unregistration here as below the rootScope will be nulled
                $rootScope.$broadcast('chat:unregister');
            }

            var headers = headersObj();
            SetRootScopeValues($rootScope, headers, PubNub, cacheBust);

            if (data.location) {
                deferred.resolve("");
                var slashCount = (data.location.match(/\//g) || []).length;
                //Special case for Login: TODO: Fix this better
                if(slashCount > 1 && !data.location.endsWith("/")) {
                    if(!$rootScope.stateToRedirectFromLogin) {
                        $rootScope.stateToRedirectFromLogin = 'home';
                        $rootScope.paramsToRedirectFromLogin = {};
                    }
                    $state.transitionTo($rootScope.stateToRedirectFromLogin, $rootScope.paramsToRedirectFromLogin,{reload:true});
                } else if (data.location.endsWith("/")) {
                    //$state.transitionTo('home');
                } else if (data.location.startsWith("https://") || data.location.startsWith("http://")) {
                    window.location.href = data.location;
                } else if (data.location == 'logout') {
                    $state.transitionTo('home');
                } else if (data.location == '/companydetails') {
                    var data = data.location.replace(/\//g, '');
                    $state.transitionTo('setupwizard.' + data);
                } else {
                    var data = data.location.replace(/\//g, '');
                    var additionalOptions = {};
                    if($state.current.name == data) {
                        additionalOptions = {reload: true};
                    }
                    $rootScope.waitAndRedirect(data, {}, additionalOptions);
                    //$state.transitionTo('loggedin.' + data, {}, additionalOptions);
                }
            } else {
                deferred.resolve(data);
            }
        });
        return deferred.promise;
    }
}

var SetupResolver = {
    setupDatasets: function ($q, $http, $stateParams, $state) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var deferred = $q.defer();
        $http.get(this.templateUrl($stateParams)).success(function (data) {
            if (data.location) {
                deferred.resolve("");
                var orgData = data;
                var data = data.location.replace(/\//g, '');
                if (data == 'customers' || data == 'client_show_ddmandate') { //If we finish setupwizard, redirect to cmpany settings
                    $state.transitionTo('loggedin.' + data);
                } else if (orgData.location.endsWith("/")) {
                    $state.transitionTo('home');
                } else {
                    $state.transitionTo('setupwizard.' + data);
                }
            } else {
                deferred.resolve(data);
            }
        });
        return deferred.promise;
    }
}

var OpenIdResolver = {
    datasets: function ($q, $http, $stateParams, $state, $location, $rootScope) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var absoluteUrl = $location.absUrl();
        var urlArray = absoluteUrl.split("?");
        var params = '';
        if (typeof(urlArray[1]) != 'undefined' && urlArray[1] != '') {
            params = "?" + urlArray[1];
        }
        if (typeof(urlArray[1]) != 'undefined' && urlArray[1] != '' && (typeof($rootScope.queryString) == 'undefined' || $rootScope.queryString == '')) {
            $rootScope.queryString = params;
        }
        if($rootScope.queryString != '' && typeof($rootScope.queryString) != 'undefined') {
            params = $rootScope.queryString;
        }
        var deferred = $q.defer();
        $http.get(this.templateUrl($stateParams) + params).success(function (data) {
            if (data.location) {
                deferred.resolve("");
                //Special case for Login: TODO: Fix this better
                if (data.location.startsWith("https://") || data.location.includes("integrations_v2")) {
                    window.location.href = data.location;
                } else if (data.location == "/openidlogin") {
                    $state.transitionTo('openidlogin');
                } else if (data.location.endsWith("/")) {
                    //$state.transitionTo('home');
                } else {
                    var data = data.location.replace(/\//g, '');
                    $state.transitionTo('loggedin.' + data);
                }
            } else {
                deferred.resolve(data);
            }
        });
        return deferred.promise;
    }
}

var ContractResolver = {
    setupDatasets: function ($q, $http, $stateParams, $state) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var deferred = $q.defer();
        $http.get(this.templateUrl($stateParams)).success(function (data) {
            if (data.location) {
                deferred.resolve("");
                var data = data.location.replace(/\//g, '');
                if (data == 'companydetails') { //If we finish contract, redirect to setup_wizard
                    $state.transitionTo('setupwizard.' + data);
                } else if (data == 'customers') {
                    $state.transitionTo('loggedin.' + data);
                }  else if (data == 'client_payment_required' || data == 'client_show_ddmandate') {
                    $state.transitionTo('loggedin.' + data);
                } else {
                    $state.transitionTo('setupwizard.' + data);
                }
            } else {
                deferred.resolve(data);
            }
        });
        return deferred.promise;
    }
}

var BreadCrumbsResolver = {
    getIdData: function ($q, $http, $stateParams, $state, $rootScope, PubNub, fiveHundredError,redirectScreen, cacheBust) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var deferred = $q.defer();
        $http.get(this.templateUrl($stateParams)).success(function (data, status, headersObj) {
            var headers = headersObj();
            SetRootScopeValues($rootScope, headers, PubNub, cacheBust);
            deferred.resolve(data);
            if (data.allow) {
                deferred.resolve("");
                $state.transitionTo('loggedin.customer_list.view.property', { 'id': data.id, 'type': $stateParams.type });
            }
            if (data.can_work == false) {
                deferred.resolve("");
                $state.transitionTo('loggedin.customer_list.view.property', { 'id': data.id, 'type': $stateParams.type });
            }
            if(data.deletedStatus != 'undefined' && data.deletedStatus) {
                redirectScreen.redirectTo(data.redirectPage,$stateParams,data);
            }
        }).error(function(data, status, headers, config, statusText) {
                if(status == 404){
                    fiveHundredError.setData(data);
                }else if(status == 500){
                    fiveHundredError.setData({'message' : 'Please contact your administrator'});
                }
                if(status == 404 || status == 500) {
                    $state.transitionTo('fivehundred');
                }
            });
        return deferred.promise;
    }
}

var BreadCrumbsPageLimitResolver = {
    getIdData: function ($q, $http, $stateParams, $state, fiveHundredError, tableCollection) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }

        var deferred = $q.defer();
        $http.get(this.templateUrl($stateParams) + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
            deferred.resolve(data);
            if (data.allow) {
                deferred.resolve("");
                $state.transitionTo('loggedin.customer_list.property', { 'id': data.id, 'type': $stateParams.type });
            }
        }).error(function(data, status, headers, config, statusText) {
            if(status == 404){
                fiveHundredError.setData(data);
            }else if(status == 500){
                fiveHundredError.setData({'message' : 'Please contact your administrator'});
            }
            if(status == 404 || status == 500) {
                $state.transitionTo('fivehundred');
            }
        });
        return deferred.promise;
    }
}

var ServiceReminderStatementResolver = {
    getIdData: function ($q, $http, $stateParams, $state, formPanelCollection) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var filterData = '';
        if(this.data.pageNum && this.data.limit){
            filterData = '?page=' + this.data.pageNum + '&limit=' + this.data.limit;
        }
        if(this.data.category){
            if(formPanelCollection.getMoreFilterData(this.data.category) != undefined) {
                filterData += '&filter='+angular.toJson(formPanelCollection.getMoreFilterData(this.data.category));
            }
        }
        else {
            formPanelCollection.setMoreFilterData('serviceRemindersStatement', null);
        }
        var deferred = $q.defer();
        $http.get(this.templateUrl($stateParams) + filterData).success(function (data) {
            deferred.resolve(data);
            if (data.allow) {
                deferred.resolve("");
                $state.transitionTo('loggedin.customer_list.property', { 'id': data.id, 'type': $stateParams.type });
            }
        });
        return deferred.promise;
    }
}
var SpreadsheetBreadCrumbsResolver = {
    getIdData: function ($q, $http, $stateParams, $state, $rootScope, PubNub, prefix, cacheBust) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var deferred = $q.defer();
        $http.get(this.templateUrl($stateParams)).success(function (data, status, headersObj) {

            var headers = headersObj();
            SetRootScopeValues($rootScope, headers, PubNub, cacheBust);

            if($rootScope.estimate_total_price > 0) {
                if(data.estimateId) {
                    var url = '/customers/' + data.id + '/estimate/' + data.estimateId + '/updateInvoiceSchedule';
                    var jsonInputData = {"totalPrice":$rootScope.estimate_total_price, "optionId": data.optionId};
                } else{
                    var url = '/customers/' + data.id + '/jobs/' + data.jobId + '/additional_works/' + data.additionalWorkId + '/updateInvoiceSchedule';
                    var jsonInputData = {"totalPrice":$rootScope.estimate_total_price};
                }


                $http.put(prefix + url, jsonInputData).success(function (ignore) {
                    deferred.resolve(data);
                });
            } else {
                deferred.resolve(data);
            }
        });
        return deferred.promise;
    }
}
function smartTableActionsCtrl($scope, $rootScope, $http, $compile, $document, $timeout, $location, $sce, tableCollection, clickEvents, changeStatusDrop, emailService, prefix, jobData, warningModal, $state){

    PermissionCheck.call(this, $scope);
    $scope.$on('modal:close', function(evt,data) {
        $scope.shouldBeOpen = false;
    });

    $scope.handleReportingMenu = function handleReportingMenu(category, $index, angularRoute, params, dynamicParams, queryParams, isUpgradedRoute = false) {
        if  (tableCollection.collection[category].hasOwnProperty('pkeys')) {

            var pkeys = {};
            angular.forEach(dynamicParams, function(val, key) {
                pkeys[key] = tableCollection.collection[category]['pkeys'][val][$index];
            });

            var url = '';
            // upgraded routes are handled here.
            if(isUpgradedRoute){
                url = angularRoute;
                Object.keys(pkeys).forEach(function(key) {
                    url = url.replace(key, pkeys[key]);
                });
            }
            else {
                url = $state.href(angularRoute,
                    angular.extend(pkeys, params));
            }

            // Append query params
            var filterData = $scope.filterData == undefined ? {} : JSON.parse($scope.filterData);
            switch(category){
                case "reporting_users_gps_leaderboard": {

                    if(!filterData.daterange) { filterData.daterange = { "startDate" : moment()._d} ; }

                    queryParams = {
                        date: moment(filterData.daterange.startDate).format('YYYY-MM-DD')
                    };
                    break;
                }
                case "reporting_users_driver_detail": {

                    if(!filterData.daterange_last_week) { filterData.daterange_last_week = { "startDate" : moment().startOf('week').subtract('7', 'days')._d }; }

                    queryParams = {
                        date: moment(filterData.daterange_last_week.startDate).format('YYYY-MM-DD')
                    };
                    break;
                }
            }

            if(queryParams instanceof Object){
                url += '?';
                angular.forEach(queryParams, function(val, key) {
                    url += encodeURIComponent(key) + '=' + encodeURIComponent(val) + '&';
                });
            }

            //Notes:: Drill down filters
            if(params['subcategory'] == params['category']+'_report') {

                var id = tableCollection.collection[category].data[$index]['id'];

                if(params['category'] == 'sales' && params['subcategory'] !='sales_report' ) {
                    $scope.filter = [{"model_name": "Type", "value": "type"}, {
                        "model_name": "InvoiceAddressName",
                        "value": id
                    }];
                }else if(params['subcategory'] =='sales_report'){
                    $scope.filter = [/*{"model_name": "Type", "value": "type"}*/,
                        {"model_name": "InvoiceAddressName", "value": id},
                        {"model_name": "notpaid","value": true },
                        {"model_name": "partiallypaid","value": true }];
                }

                var putData = {
                    'category': params['category'],
                    'subcategory': params['subcategory'],
                    'filters': $scope.filter
                };

                var newWindow = window.open('about:blank', '_blank');

                $http.post(prefix + '/update_drill_down_filters', "data=" + encodeURIComponent(angular.toJson(putData))).then(function (response) {
                    newWindow.location.href = url;
                });
            }
            else {
                window.open(url, '_blank');
            }
        }
    }

    $scope.canDisplayMenu = function canDisplayMenu(category, type, $index) {
        if  (tableCollection.collection[category].hasOwnProperty('pkeys')) {
            var pkey = tableCollection.collection[category]['pkeys'][type][$index];
            return (pkey != -1);
        } else {
            return false;
        }
    }

    $scope.handleSidePanelTemplate = function handleSidePanelTemplate($event, row, template, index, category) {
        var rowData = tableCollection.getSidepanelData(row, category);
        $http.get(prefix + '/getStockLocationAvailable' + '?partId='+ rowData.partId).success(function (data) {
            jobData.setPartLocations(data);
            $scope.showSidepanel($event, row, template, index);
        });
    }

    $scope.handleJobPartViewTemplate = function handleJobPartViewTemplate($event, row, template, index, category) {
        var rowData = tableCollection.getSidepanelData(row, category);
        $http.get(prefix + '/getJobPartViewList' + '?partId='+ rowData.partId + '&id=' + rowData.id + '&jobId=' + rowData.jobId + '&status=' + rowData.Status ).success(function (data) {
            jobData.setJobPartData(data);
            $scope.showSidepanel($event, row, template, index);
        });
    }

    $scope.deleteParam = [
        {
            "job_parts": {
                "url": "/delete_part",
                "validationMessage": "DELETE to Remove this Part",
                "title": "Delete Part",
                "content": "To confirm you wish to delete the Description then please type DELETE in the box below and click 'Delete'.",
                "deleteText": "Delete",
                "deleteArray": "jobPart[id]="
            },
            "job_parts_requested": {
                "url": "/delete_part",
                "validationMessage": "DELETE to Remove this Part",
                "title": "Delete Part",
                "content": "To confirm you wish to delete the Description then please type DELETE in the box below and click 'Delete'.",
                "deleteText": "Delete",
                "deleteArray": "jobPart[id]="
            },
            "job_parts_available": {
                "url": "/mark_part_installed",
                "validationMessage": "CONFIRM to Part as installed",
                "title": "Mark part as installed",
                "content": "To confirm you wish to mark this part as installed, please type 'CONFIRM' in the box below and click mark part as installed.",
                "deleteText": "Mark part as installed",
                "deleteArray": "jobPartInstalled[id]="
            },
            "job_parts_installed": {
                "url": "/mark_part_available",
                "validationMessage": "CONFIRM to Part as Available",
                "title": "Mark part as available",
                "content": "To confirm you wish to mark this part as available, please type 'CONFIRM' in the box below and click mark part as available.",
                "deleteText": "Mark part as available",
                "deleteArray": "jobPartAvailable[id]="
            },
            "job_parts_on_order": {
                "url": "/mark_part_available",
                "validationMessage": "CONFIRM to Part as Available",
                "title": "Mark part as available",
                "content": "To confirm you wish to mark this part as available, please type 'CONFIRM' in the box below and click mark part as available.",
                "deleteText": "Mark part as available",
                "deleteArray": "jobPartAvailable[id]="
            },
            "service_reminders_phone_call_list": {
                "url": "/remove_service_reminder_phonecall",
                "validationMessage": "REMOVE to Remove this PhoneCall",
                "title": "Remove ServiceReminder PhoneCall",
                "content": "By removing this call you are not removing the service reminder, but the service reminder will be marked as 'pending'. To confirm, please type 'REMOVE' in the box below and click 'Remove'.",
                "deleteText": "Remove",
                "deleteArray": "phoneCall[id]="
            },
            "job_assigned_todos": {
                "url": "/removeJobTodoFromDiary",
                "validationMessage": "CONFIRM to Remove this todo",
                "title": "Remove todo from diary event",
                "content": "To confirm you wish to remove this todo from diary event, please type 'CONFIRM' in the box below and click remove todo from diary event. ",
                "deleteText": "Remove todo from diary event",
                "deleteTextConfirm": "CONFIRM",
                "deleteArray" : "jobTodo[id]="
            },
            "job_completed_todos_all": {
                "url": "/moveTodoAvailable",
                "validationMessage": "'CONFIRM' to move this todo to available",
                "title": "Move todo to available",
                "content": "To confirm you wish to move this todo to available, please type 'CONFIRM' in the box below and click move todo to available.",
                "deleteText": "Move todo to available",
                "deleteTextConfirm": "CONFIRM",
                "deleteArray" : "jobTodo[id]="
            },
            "job_completed_todos_not_done": {
                "url": "/moveTodoAvailable",
                "validationMessage": "'CONFIRM' to move this todo to available",
                "title": "Move todo to available",
                "content": "To confirm you wish to move this todo to available, please type 'CONFIRM' in the box below and click move todo to available.",
                "deleteText": "Move todo to available",
                "deleteTextConfirm": "CONFIRM",
                "deleteArray" : "jobTodo[id]="
            },
            "customer_account_credit": {
                "url": "/delete_account_credit",
                "validationMessage": "'DELETE' to Delete this account credit",
                "title": "Delete Credit",
                "content": "To confirm you wish to delete this credit then please type 'DELETE' in the box below and click 'Delete'.",
                "deleteText": "Delete",
                "deleteArray" : "accountCredit[id]="
            },
            "invoice_address_account_credit": {
                "url": "/delete_account_credit",
                "validationMessage": "'DELETE' to Delete this account credit",
                "title": "Delete Credit",
                "content": "To confirm you wish to delete this credit then please type 'DELETE' in the box below and click 'Delete'.",
                "deleteText": "Delete",
                "deleteArray" : "accountCredit[id]="
            },
            "job_certificates": {
                "url": "/delete_job_certificate",
                "validationMessage": "'DELETE' to Delete this job certificate",
                "title": "Delete Certificate",
                "content": "To confirm you wish to delete this certificate then please type 'DELETE' in the box below and click 'Delete'.",
                "deleteText": "Delete",
                "deleteArray" : "certificate[id]="
            },
            "job_certificates_void": {
                "url": "/delete_job_certificate",
                "validationMessage": "'VOID' to void this job certificate",
                "title": "Void Certificate",
                "content": "You cannot delete this certificate because it has already been completed. You can, however, mark it as ‘VOID’. This will allow you to keep a historical record of the certificate that you can view, but it will also differentiate it from your active certificates. To confirm you wish to void this certificate then please type 'VOID' in the box below and click 'Void'.",
                "deleteText": "Void",
                "deleteArray" : "certificate[id]="
            },
            "job_cost_supplier_returns": {
                "url": "/mark_credit_note_not_required",
                "validationMessage": "'CONFIRM' to mark credit note not required for returns",
                "title": "Mark credit note not required",
                "content": "To confirm you wish not to raise credit note for this return then please type 'CONFIRM' in the box below and click 'Credit note not required'.",
                "deleteText": "Credit note not required",
                "deleteArray" : "returnId="
            },
            "supplier_returns": {
                "url": "/mark_credit_note_not_required",
                "validationMessage": "'CONFIRM' to mark credit note not required for returns",
                "title": "Mark credit note not required",
                "content": "To confirm you wish not to raise credit note for this return then please type 'CONFIRM' in the box below and click 'Credit note not required'.",
                "deleteText": "Credit note not required",
                "deleteArray" : "returnId="
            }
        }
    ];
    $scope.triggerRemove = function triggerRemove(id){
        $scope.currentPage = $scope.$parent.pageNum;
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.deleteText = $scope.deleteParam[0][$scope.category].deleteText;
        $scope.deleteTextConfirm = 'REMOVE';
        $scope.deleteUrl = $scope.deleteParam[0][$scope.category].url;
        $scope.title = $scope.deleteParam[0][$scope.category].title;
        $scope.validationMessage = $scope.deleteParam[0][$scope.category].validationMessage;
        $scope.content = $scope.deleteParam[0][$scope.category].content;
        $scope.deleteArrayText = $scope.deleteParam[0][$scope.category].deleteArray;

    }

    $scope.setCreditNoteNotRequired = function(returnId, category, jobId){
        $scope.selectedId = returnId;
        $scope.shouldBeOpen = true;
        $scope.limit = $scope.$parent.limit;
        $scope.title = $scope.deleteParam[0][$scope.category].title;
        $scope.deleteUrl = $scope.deleteParam[0][$scope.category].url;
        $scope.deleteArrayText = $scope.deleteParam[0][$scope.category].deleteArray;
        $scope.content = $scope.deleteParam[0][$scope.category].content;
        $scope.category = category;
        $scope.validationMessage = $scope.deleteParam[0][$scope.category].validationMessage;
        $scope.deleteText = $scope.deleteParam[0][$scope.category].deleteText;
        $scope.deleteTextConfirm = 'CONFIRM';
        $scope.jobId = jobId;
    }

    $scope.triggerDelete = function triggerDelete(id){
        $scope.currentPage = $scope.$parent.pageNum;
        $scope.selectedId = tableCollection.collection[$scope.category].data[id].id;
        $scope.shouldBeOpen = true;
        $scope.deleteText = $scope.deleteParam[0][$scope.category].deleteText;
        $scope.deleteTextConfirm = 'DELETE';
        $scope.deleteUrl = $scope.deleteParam[0][$scope.category].url;
        $scope.title = $scope.deleteParam[0][$scope.category].title;
        $scope.validationMessage = $scope.deleteParam[0][$scope.category].validationMessage;
        $scope.content = $scope.deleteParam[0][$scope.category].content;
        $scope.deleteArrayText = $scope.deleteParam[0][$scope.category].deleteArray;
        if(tableCollection.collection[$scope.category].data[id].Part){
            var partName = tableCollection.collection[$scope.category].data[id].Part;
            $scope.content = $scope.deleteParam[0][$scope.category].content.replace("Description", partName);
        }
    }

    $scope.triggerPartDelete = function triggerPartDelete(row, category){
        var rowData = tableCollection.getSidepanelData(row, category);
        $scope.currentPage = $scope.$parent.pageNum;
        $scope.selectedId = rowData.id;
        $scope.shouldBeOpen = true;
        $scope.deleteText = $scope.deleteParam[0][$scope.category].deleteText;
        $scope.deleteTextConfirm = 'DELETE';
        $scope.deleteUrl = $scope.deleteParam[0][$scope.category].url;
        $scope.title = $scope.deleteParam[0][$scope.category].title;
        $scope.validationMessage = $scope.deleteParam[0][$scope.category].validationMessage;
        $scope.content = $scope.deleteParam[0][$scope.category].content;
        $scope.deleteArrayText = $scope.deleteParam[0][$scope.category].deleteArray;
        var partName = rowData.Part;
        $scope.content = $scope.deleteParam[0][$scope.category].content.replace("Description", partName);
    }



    $scope.triggerAccountCreditDelete = function triggerAccountCreditDelete(id, doNotDelete){
        if(doNotDelete) {
            $scope.currentPage = $scope.$parent.pageNum;
            $scope.selectedId = id;
            $scope.shouldBeOpen = true;
            $scope.deleteText = $scope.deleteParam[0][$scope.category].deleteText;
            $scope.deleteTextConfirm = 'DELETE';
            $scope.deleteUrl = $scope.deleteParam[0][$scope.category].url;
            $scope.title = $scope.deleteParam[0][$scope.category].title;
            $scope.validationMessage = $scope.deleteParam[0][$scope.category].validationMessage;
            $scope.content = $scope.deleteParam[0][$scope.category].content;
            $scope.deleteArrayText = $scope.deleteParam[0][$scope.category].deleteArray;
        } else {
            warningModal.show('You.cannot.can.not.delete.this.account.credit.because.it.has.already.been.allocation.and.paid.back', 'Delete.account.credit');
        }
    }

    $scope.triggerCertificateDelete = function triggerCertificateDelete(id, isCompleted){
        if(isCompleted) {
            var category = $scope.category + '_void';
            $scope.deleteTextConfirm = 'VOID'
        } else {
            var category = $scope.category;
            $scope.deleteTextConfirm = 'DELETE';
        }

        $scope.currentPage = $scope.$parent.pageNum;
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $scope.deleteText = $scope.deleteParam[0][category].deleteText;
        //$scope.deleteTextConfirm = 'VOID';
        $scope.deleteUrl = $scope.deleteParam[0][category].url;
        $scope.title = $scope.deleteParam[0][category].title;
        $scope.validationMessage = $scope.deleteParam[0][category].validationMessage;
        $scope.content = $scope.deleteParam[0][category].content;
        $scope.deleteArrayText = $scope.deleteParam[0][category].deleteArray;
    }

    // Triggers on clicking edit certificate button
    $scope.triggerCertificateEdit = function triggerCertificateEdit(id){
        $rootScope.$broadcast('dynamic_certificate:edit', id);
    };

    $scope.triggerConfirm = function triggerConfirm(id){
        $scope.currentPage = $scope.$parent.pageNum;
        $scope.selectedId = tableCollection.collection[$scope.category].data[id].id;
        $scope.shouldBeOpen = true;
        $scope.deleteText = $scope.deleteParam[0][$scope.category].deleteText;
        $scope.deleteTextConfirm = 'CONFIRM';
        $scope.deleteUrl = $scope.deleteParam[0][$scope.category].url;
        $scope.title = $scope.deleteParam[0][$scope.category].title;
        $scope.validationMessage = $scope.deleteParam[0][$scope.category].validationMessage;
        $scope.content = $scope.deleteParam[0][$scope.category].content;
        $scope.deleteArrayText = $scope.deleteParam[0][$scope.category].deleteArray;
        if(tableCollection.collection[$scope.category].data[id].Part){
            var partName = tableCollection.collection[$scope.category].data[id].Part;
            $scope.content = $scope.deleteParam[0][$scope.category].content.replace("Description", partName);
        }
    }

    $scope.$watch('Quantity', function (newVal, oldVal) {
        var quantityregexp = /^[1-9][0-9]{0,6}(\.[0-9]{1,2})?$/;
        $scope.QuantityCheck = '';
        if (!newVal) {
            $scope.quantityvalidationmessage = false;
        }
        else {
            var quantitycheckregexp = /^[0-9]{0,6}(\.[0-9]{1,2})?$/;

            if(newVal !=0 && quantitycheckregexp.test(parseFloat(newVal))) {
                $scope.quantityvalidationmessage = false;
            }
            else if(newVal == 0){
                $scope.QuantityCheck = 0;
            }
            else {
                $scope.quantityvalidationmessage = true;
            }
        }
    });

    $scope.$watch('costprice', function (newVal, oldVal) {
        if(newVal){
            var newVal = newVal.replace($rootScope.CustomCurrency, '');
        }
        var costpriceregexp = /^[1-9][0-9]{0,6}(\.[0-9]{1,4})?$/;
        $scope.costPriceCheck = '';
        if (!newVal || costpriceregexp.test(parseFloat(newVal))) {
            $scope.costpricevalidationmessage = false;
        } else {
            var costpricecheckregexp = /^[0-9]{0,7}(\.[0-9]{1,4})?$/;

            if(costpricecheckregexp.test(parseFloat(newVal))) {
                //$scope.costPriceCheck = 0;
                $scope.costpricevalidationmessage = false;
            }
            else {
                $scope.costpricevalidationmessage = true;
            }

        }

    });

    $scope.$watch('saleprice', function (newVal, oldVal) {
        if(newVal){
            var newVal = newVal.replace($rootScope.CustomCurrency, '');
        }
        var salepriceregexp = /^[0-9]{0,7}(\.[0-9]{1,4})?$/;
        if (!newVal || salepriceregexp.test(parseFloat(newVal))) {
            $scope.salepricevalidationmessage = false;
        } else {
            $scope.salepricevalidationmessage = true;
        }

    });


    // Send Email
    $scope.sendEmail = function(customerId) {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + customerId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=2&id=' + customerId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 2;        // For Customers
        clone_obj.relatedObjectId = customerId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.closePanel = function closePanel() {
        $rootScope.$broadcast('smart_table:unpin_all_pinned_rows');
        $rootScope.$broadcast('closeAllSidepanels');
    }

    var supports_associated_rows = $scope.$parent.$parent.$parent.$parent.supports_associated_rows;
    if (supports_associated_rows) {
        var table_scope = $scope.$parent.$parent.$parent.$parent;
    } else {
        var table_scope = $scope.$parent.$parent.$parent.$parent;
    }

    $scope.initiateEmailJobCertificate = function(sendJobCertificateEmail){
        $scope.emailJobCertificate = function(val){
            sendJobCertificateEmail(val);
        }
    }

    $scope.initiateActionButtons = function initiateActionButtons(e, index) {
        if ($scope.category == 'serviceRemindersStatement') {
            $scope.button_identifier = tableCollection.getSidepanelData(index, 'serviceRemindersStatement')["Service status"];
            $scope.jobId = tableCollection.getSidepanelData(index, 'serviceRemindersStatement')["Job no"];
            $scope.customerId = tableCollection.getSidepanelData(index, 'serviceRemindersStatement')["propertyId"];
        }
        else if ($scope.category == 'serviceReminderStatementOverDue' || $scope.category == 'serviceReminderStatementDueBooked') {
            $scope.jobId = tableCollection.getSidepanelData(index, $scope.category)["Job no"];
        }
        else if ($scope.category == 'serviceReminderStatementOverNot' || $scope.category == 'serviceReminderStatementDueNotBooked') {
            $scope.customerId = tableCollection.getSidepanelData(index, $scope.category)["propertyId"];
        }
        else if ($scope.category == 'service_reminders_phone_call_list') {
            $scope.button_identifier = tableCollection.getSidepanelData(index, 'service_reminders_phone_call_list').meta_data.Type;
            $scope.customer_id = tableCollection.getSidepanelData(index, 'service_reminders_phone_call_list').meta_data.customer_id;
            $scope.propertyid = tableCollection.getSidepanelData(index, 'service_reminders_phone_call_list').meta_data.propertyid;
            $scope.id = tableCollection.collection[$scope.category].data[index].id;
            $scope.canWork = tableCollection.collection[$scope.category].data[index].canWork;
        }
        else if ($scope.category == 'stock_history') {
            $scope.button_identifier = tableCollection.getSidepanelData(index, 'stock_history')["Action"];
            $scope.fulfillPartStatus = tableCollection.getSidepanelData(index, 'stock_history')["fulfillPartStatus"];
            $scope.canReturn = tableCollection.getSidepanelData(index, 'stock_history')['canReturn'];
            $scope.canReturnQuantity = tableCollection.getSidepanelData(index,'stock_history')['canReturnQuantity'];
        }
        else if($scope.category == 'stock_location_history'){
            $scope.button_identifier = tableCollection.getSidepanelData(index, 'stock_location_history')["Action"];
            $scope.fulfillPartStatus = tableCollection.getSidepanelData(index, 'stock_location_history')["fulfillPartStatus"];
            $scope.canReturn = tableCollection.getSidepanelData(index, 'stock_location_history')['canReturn'];
            $scope.canReturnQuantity = tableCollection.getSidepanelData(index,'stock_location_history')['canReturnQuantity'];
        }
        else if ($scope.category == 'supplier_returns') {
            $scope.selectedId = table_scope.$parent.selectedId;
            $scope.button_identifier = tableCollection.getSidepanelData(index, 'supplier_returns')["Credit received"];
            $scope.returnId = tableCollection.getSidepanelData(index, 'supplier_returns')["id"];
        }
        else if($scope.category == 'job_cost_supplier_returns'){
            $scope.selectedId = table_scope.$parent.selectedId;
            $scope.jobId = table_scope.$parent.jobId;
            $scope.button_identifier = tableCollection.getSidepanelData(index, 'job_cost_supplier_returns')["Credit received"];
            $scope.returnId = tableCollection.getSidepanelData(index, 'job_cost_supplier_returns')["id"];
        }
        else if ($scope.category == 'job_completed_todos_all') {
            $scope.button_identifier = tableCollection.getSidepanelData(index, 'job_completed_todos_all')["Status"];
        }
        else if ($scope.category == 'job_completed_todos_not_done') {
            $scope.button_identifier = tableCollection.getSidepanelData(index, 'job_completed_todos_not_done')["Status"];
        }
        else if ($scope.category == 'stock_reserve_list') {
            $scope.pickedup = tableCollection.getSidepanelData(index, 'stock_reserve_list')["pickedup"];
        } else if ($scope.category == 'customer_account_credit') {
            $scope.selectedId = table_scope.$parent.$parent.$parent.selectedId;
            $scope.accountCreditId = tableCollection.getSidepanelData(index, 'customer_account_credit')["id"];
            $scope.id = tableCollection.collection[$scope.category].data[index].id;
            $scope.doNotDelete = tableCollection.collection[$scope.category].data[index].doNotDelete;
        } else if ($scope.category == 'invoice_address_account_credit') {
            $scope.selectedId = table_scope.$parent.$parent.$parent.selectedId;
            $scope.accountCreditId = tableCollection.getSidepanelData(index, 'invoice_address_account_credit')["id"];
            $scope.id = tableCollection.collection[$scope.category].data[index].id;
            $scope.doNotDelete = tableCollection.collection[$scope.category].data[index].doNotDelete;
        } else if ($scope.category == 'job_certificates') {
            $scope.id = tableCollection.collection[$scope.category].data[index].id;
            $scope.isCompleted = tableCollection.collection[$scope.category].data[index].isCompleted;
            $scope.isVoided = tableCollection.collection[$scope.category].data[index].isVoided;
            $scope.settingsCertificateType = tableCollection.collection[$scope.category].data[index].settingsCertificateType;
        }
        else if ($scope.limitText == true) {
            $scope.record_type = tableCollection.collection[$scope.category].record_type;
            $scope.log_record_type = tableCollection.collection[$scope.category].log_record_type;
        }else if ($scope.category.startsWith("job_parts_")){
            $scope.fulfillType = tableCollection.collection[$scope.category].data[index].FulfillType;
            $scope.fulfillment = tableCollection.collection[$scope.category].data[index].FulfillType;
        }else if ($scope.category == 'job_parts'){
            $scope.fulfillment = tableCollection.collection[$scope.category].data[index].FulfillType;
            $scope.status = tableCollection.collection[$scope.category].data[index].Status;
            $scope.jobId = tableCollection.collection[$scope.category].data[index].jobId;
        }
        else if ($scope.category == 'job_parts_requested'){
            $scope.jobId = tableCollection.collection[$scope.category].data[index].jobId;
        }
        else if ($scope.category.startsWith("reporting_")) {
            $scope.button_identifier = (tableCollection.hasSummaryRow() && index == tableCollection.collection[$scope.category].data.length-1) ? 'Yes' : '';
        }
        else if ($scope.category === 'viewAllServiceReminder') {
            $scope.button_identifier = tableCollection.collection[$scope.category].data[index].type;
            $scope.customer_id = tableCollection.collection[$scope.category].data[index].parentpropertiesid ? tableCollection.collection[$scope.category].data[index].parentpropertiesid : tableCollection.collection[$scope.category].data[index].propertyid;
            $scope.propertyid = tableCollection.collection[$scope.category].data[index].propertyid;
            $scope.id = tableCollection.collection[$scope.category].data[index].id;
            $scope.canWork = tableCollection.collection[$scope.category].data[index].canWork;
        }
    }


    $scope.showActions = function showActions(e, index) {
        $rootScope.$broadcast('closeall');

        table_scope.active_actions_index = index;

        clickEvents.registerIgnoreElements('actionbuttons');

        if(e.target.classList.contains('ss-delete')){
            table_scope.active_actions_index = null;
        }
    }

    $rootScope.$on('closeAllSidepanels', function(){
        var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');

        if (panel[0]) {
            panel[0].classList.remove('in-view');
        }
        $timeout(function() {
            if (panel[0]) {
                if (panel[0].parentNode) {
                    panel[0].parentNode.removeChild(panel[0]);
                }
            }
        }, 800);

    });

    $scope.removeTodoDiaryEvent = function removeTodoDiaryEvent(index) {
        $scope.currentPage = $scope.$parent.pageNum;
        $scope.selectedId = tableCollection.collection[$scope.category].data[index].id;
        $scope.shouldBeOpen = true;
        $scope.deleteText = $scope.deleteParam[0][$scope.category].deleteText;
        $scope.deleteTextConfirm = $scope.deleteParam[0][$scope.category].deleteTextConfirm;
        $scope.deleteUrl = $scope.deleteParam[0][$scope.category].url;
        $scope.title = $scope.deleteParam[0][$scope.category].title;
        $scope.validationMessage = $scope.deleteParam[0][$scope.category].validationMessage;
        $scope.content = $scope.deleteParam[0][$scope.category].content;
        $scope.deleteArrayText = $scope.deleteParam[0][$scope.category].deleteArray;
    }

    $scope.showSidepanel = function showSidepanel(e, index, tmplt, tblpos) {

        var cat = $(e.target).parents('.smart-table-container').attr('category'),
            tablePosition = tblpos,

            sidepanelData = tableCollection.getSidepanelData(index, cat);
        if(cat == 'notifications') {
            table_scope.triggerNotificationSidepanelFromDropdown(index);
            return;
        }

        if(cat == 'job_parts_requested'){
            sidepanelData = _.clone(tableCollection.getSidepanelData(index, cat));
        }

        if(tmplt == "preview_certificate"){
            if(sidepanelData.isVoided == true) {
                $http.get(prefix + '/certificate_void_get_date?jobCertificateLookupID=' + sidepanelData.id).success(function (data) {
                    angular.extend(sidepanelData, data);
                });
            }
        }

        if(cat === "job_assigned_todos") {
            var dropdownData = tableCollection.getDropdownData(cat);
        }

        $scope.changeStatusValues = changeStatusDrop.getData(cat);

        if(cat === 'job_parts'){
            sidepanelData = _.clone(tableCollection.getSidepanelData(index, cat));
            $scope.changeStatusValues = $scope.changeStatusValues[$scope.status]
            $scope.fulfillments = angular.copy(sidepanelData.fulfillment);
        }

            if(sidepanelData.FulfillType){
                if(sidepanelData.FulfillType == 'General'){
                    $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Returned_To_Stock');
                    $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Returned');
                }
                if($rootScope.clientJobType === 1){

                }else if($rootScope.clientJobType === 2){
                    if(sidepanelData.FulfillType == 'OrderFromSupplier'){
                        if( sidepanelData.Status == 'Available'){
                            $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Cancelled');
                        }
                    }
                }else if($rootScope.clientJobType === 3){
                    if(sidepanelData.FulfillType == 'FromStock'){
                        // $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Returned');
                        if(sidepanelData.Status == 'Available'){
                            $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Cancelled');
                            // $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Installed');
                        }
                    }else if(sidepanelData.FulfillType === 'OrderFromSupplier'){
                        if(sidepanelData.Status == 'Available'){
                            $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Cancelled');
                            // $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Returned');
                        }
                        // $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Returned_To_Stock');
                    }
                }

            }

        //if(sidepanelData['Unit cost price']){
        //    sidepanelData['Unit cost price'] = sidepanelData['Unit cost price'].replace($rootScope.CustomCurrency, '');
        //}
        //
        //if(sidepanelData['Unit sales price']){
        //    sidepanelData['Unit sales price'] = sidepanelData['Unit sales price'].replace($rootScope.CustomCurrency, '');
        //}

        $scope.jobType = $rootScope.clientJobType;

        $scope.instanceId = tableCollection.getSidepanelData(index, cat);
        var values = $scope.instanceId.records;

        var instanceValue = [];
        angular.forEach(values, function(value, key) {
            instanceValue.push(value.instanceId);
        }, instanceValue);

        $scope.instancesId = instanceValue.toString();

        $rootScope.instancesId = $scope.instancesId;

        var removeBothElements = function removeBothElements(){
            var should_close = true;
            var anchor = 'A';
            var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');
            var overlay = document.getElementById('page-overlay');

            if (overlay.classList.contains('darker') === true){
                should_close = false;
            }

            if (should_close === true) {
                // if the target element is an anchor (ie the save the cancel link)
                // then prevent the link changing the URl.
                if (e != undefined && e.currentTarget.tagName === anchor) {
                    e.preventDefault();
                }
                if (panel[0]) {
                    panel[0].classList.remove('in-view');
                }
                overlay.classList.remove('in-view');

                $timeout(function() {
                    overlay.parentNode.removeChild(overlay);
                    if (panel[0]) {
                        panel[0].parentNode.removeChild(panel[0]);
                    }
                }, 800);

                // remove keyboard events
                unbindKeyboardEvents();
                $rootScope.$broadcast('closeall');
            }
        }

        var insertPanel = function insertPanel() {
            // close all open widgets
            $rootScope.$broadcast('closeall');
            /*====================================================
             Builds the panel element and insert it into the DOM,
             the panel is hidden in the CSS so that it can be
             animated with a CSS transition with the showPanel
             function.
             ====================================================*/
            var constructAndInsertPanel = (function() {
                var panel_wrap = document.createElement('div'),
                    sidepanelTemplate = '';

                panel_wrap.id = 'more-details-panel';
                panel_wrap.className = 'smart-table-sidepanel'
                document.getElementsByTagName('body')[0].appendChild(panel_wrap);

                /*====================================================
                 1. Fetches the sidepanel template to use
                 2. Brings the sidepanel data into the sidepanel's scope
                 3. Appends a compiled result of the data and template
                 into the view
                 ====================================================*/
                var tpl_path = "/template/side_panels/"
                    + tmplt + ".html";

                $scope.sidepanelTemplate = [];

                var fetchsidepanelTemplate = function fetchsidepanelTemplate() {
                    $http.get(tpl_path).then(function(result){
                        sidepanelTemplate = result.data;
                        $scope.sidepanelData = sidepanelData;

                        if(dropdownData) {
                            $scope.dropdownData = dropdownData;
                        }

                        $scope.currentTableIndex = tblpos;
                        panel_wrap.appendChild($compile(result.data)($scope)[0]);

                        /*====================================================
                         After the panel is built, push it into view
                         and attach keyboard listeners
                         ====================================================*/
                        var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');
                        $timeout(function() {
                            panel[0].classList.add('in-view');
                        }, 10);
                        bindKeyboardEvents(index, tablePosition);
                        insertPageOverlay();
                            $scope.certificateError= 'saved';
                            $scope.print_and_post_message = "Your certificate is on its way to the customer";
                            $scope.print_and_post_error_message = null;
                            $http.get(prefix +'/check_print_post_credits').then(function(res) {
                                if(res.data.error)
                                {
                                    $scope.print_and_post_message = res.data.error ;
                                    $scope.certificateError = 'error';
                                }
                    });
                    });
                }
                fetchsidepanelTemplate();
            })();
        }

        var insertPageOverlay = function insertPageOverlay() {
            /*====================================================
             Builds the overlay element and insert it into the DOM,
             the overlay is hidden in the CSS so that it can be
             animated with a CSS transition
             ====================================================*/
            var constructAndInsertOverlay = (function(){
                $scope.insertElm = function insertElm() {
                    var wrapper = document.createElement('div');
                    wrapper.id = 'page-overlay';
                    document.getElementsByTagName('body')[0].appendChild(wrapper);
                }
                if (document.getElementById('page-overlay') === null) {
                    $scope.insertElm();
                }else {
                    // remove the existing one before inserting the new one
                    document.getElementById('page-overlay').remove();
                    $scope.insertElm();
                }
            })();
            /*====================================================
             Show the overlay element and attach click event listeners
             to it.
             ====================================================*/
            var showAndAttachEvts = (function() {
                $scope.handleElm = function handleElm() {
                    $timeout(function() {
                        var overlay = document.getElementById('page-overlay');
                        overlay.classList.add('in-view');
                    }, 100);

                    // attach click listeners
                    var overlay = document.querySelectorAll('#page-overlay');
                    var save_link = document.querySelectorAll('#more-details-panel.smart-table-sidepanel #save-panel-btn');
                    var cancel_link = document.querySelectorAll('#more-details-panel.smart-table-sidepanel #cancel-panel-btn');
                    for (var i = 0; i < overlay.length; i++) {
                        overlay[i].addEventListener('click', removeBothElements);
                    }
                    for (var i = 0; i < save_link.length; i++) {
                        save_link[i].addEventListener('click', removeBothElements);
                    }
                    for (var i = 0; i < cancel_link.length; i++) {
                        cancel_link[i].addEventListener('click', removeBothElements);
                    }
                }
                $scope.handleElm();
            })();
        }

        /*====================================================
         Update the panel content when a keyboard shortcut
         is pressed
         ====================================================*/
        var updatePanelContent = function updatePanelContent(index, row){
            $scope.sidepanelData = tableCollection.getSidepanelData(row, cat);

            if(cat === "job_assigned_todos") {
                $scope.dropdownData = tableCollection.getDropdownData(cat);
            }
            // update the active row in the DOM
            // $scope.$parent.$parent.actions_active_id = index; OLD
        }

        /*====================================================
         Bind keyboard event listeners
         - 'esc' closes the panel
         - 'up' & 'down' to navigate through the rows
         ** gets the correct index for the data from the index
         of the table using $index so that pinned rows
         are respected.
         ====================================================*/
        var bindKeyboardEvents = function bindKeyboardEvents(row, index) {
            $document.bind('keydown.sidepanelShortcuts', function(evt) {
                if (evt !== undefined) {
                    var evt = evt;
                } else {
                    var evt = window.event;
                }

                var src_element_exists = evt.srcElement !== undefined;

                if (src_element_exists) {
                    var elm_type = evt.srcElement.tagName,
                        form_element = elm_type == 'INPUT' ||
                            elm_type == 'SELECT' ||
                            elm_type == 'TEXTAREA',
                        shortcut_is_allowed = !form_element;
                } else {
                    var elm_type = evt.currentTarget.activeElement.localName,
                        form_element = elm_type == 'input' ||
                            elm_type == 'select' ||
                            elm_type == 'textarea',
                        shortcut_is_allowed = !form_element;
                }

                if(shortcut_is_allowed) {
                    var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');
                    var totalRows = tableCollection.getOrderedRows().length - 1;

                    if (panel[0] != null) {
                        var handleEscKey = (function() {
                            // esc keydown
                            if (evt.keyCode == 27) {
                                removeBothElements();
                            }
                        })();
                        var handleShortcuts = (function() {
                            // up keydown
                            if (evt.keyCode == 38) {
                                if (index != 0){
                                    index = index-1;
                                    var rowData = $scope.rows[index][0].row
                                    updatePanelContent(index, rowData);
                                    table_scope.active_actions_index --;
                                }
                            }
                            // down keydown
                            else if (evt.keyCode == 40) {
                                if(index <= totalRows){
                                    index = index+1;
                                    var row_exists = $scope.rows[index] !== undefined;
                                    if(row_exists){
                                        var rowData = $scope.rows[index][0].row
                                        updatePanelContent(index, rowData);
                                        table_scope.active_actions_index ++;
                                    }else{
                                        index = index-1;
                                    }
                                }
                            }
                        })();
                    }
                }
            })
        }

        /*====================================================
         Unbind keyboard events
         ====================================================*/
        var unbindKeyboardEvents = function unbindKeyboardEvents() {
            $document.unbind('keydown.sidepanelShortcuts');
        }

        /*====================================================
         Instantiate panel instance
         ====================================================*/
        insertPanel();

        /*==========================================================================================
        * get record history
        * =========================================================================================*/
        $scope.showhistory   = false;
        $scope.sidepanelData = tableCollection.getSidepanelData(index, cat); //record id

        var typeArray = {
            "customers": 2,
            "customer_invoices": 59, 
            "customer_payments": 54,
            "customer_creditnotes": 57,
            "suppliers":3,
            "supplier_invoices":19,
            "supplier_creditnotes":23,
            "supplier_payments":27
        };
        var typeValue   = typeArray[$scope.log_record_type];
        var datesArray  = [];
        var statusArray = [];
        $scope.limit    = 10;

        //call get audit history for pageNo 1 when click on sidepanel
        $http.post(prefix+'/get_audit_history', 'page=1'+'&limit='+$scope.limit+'&record_type='+typeValue+'&id='+JSON.stringify($scope.sidepanelData.id))
            .success(function (resp) {
                resp.records.forEach(function(data) {
                    if(data.date.length>0 && data.status.length > 0){
                        $scope.showhistory = true;
                    } else {
                        $scope.showhistory = false;
                    }
                    datesArray.push(moment(data.date).format('L H:mm a'));
                    statusArray.push(data.status);
                });
                $scope.dates        = datesArray;
                $scope.activities   = statusArray;
                $scope.totalRecords = resp.totalrecords;
            }).error(function(resp) {
        });

        //call get audit history when click on pagination
        $scope.getAuditHistory = function (pageNum) {
            $scope.showhistory   = false;
            $scope.sidepanelData = tableCollection.getSidepanelData(index, cat); //record id

            var typeArray = {
                "customers": 2,
                "customer_invoices": 59, 
                "customer_payments": 54,
                "customer_creditnotes": 57,
                "suppliers":3,
                "supplier_invoices":19,
                "supplier_creditnotes":23,
                "supplier_payments":27
            };
            var typeValue   = typeArray[$scope.log_record_type];
            var datesArray  = [];
            var statusArray = [];
            $scope.limit    = 10;

            $http.post(prefix+'/get_audit_history', 'page='+pageNum+'&limit='+$scope.limit+'&record_type='+typeValue+'&id='+JSON.stringify($scope.sidepanelData.id))
                .success(function (resp) {
                    resp.records.forEach(function(data) {
                        if(data.date.length>0 && data.status.length > 0){
                            $scope.showhistory = true;
                        } else {
                            $scope.showhistory = false;
                        }
                        datesArray.push(moment(data.date).format('L H:mm a'));
                        statusArray.push(data.status);
                    });
                    $scope.dates        = datesArray;
                    $scope.activities   = statusArray;
                    $scope.totalRecords = resp.totalrecords;
                }).error(function(resp) {
            });
        }
    }

    $scope.setWorkReminderFilterDate = function setWorkReminderFilterDate() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();

        if(dd < 10) {
            dd = '0' + dd
        }

        if(mm < 10) {
            mm = '0' + mm
        }

        today = mm + '/' + dd + '/' + yyyy;
        $rootScope.work_reminder_filter_date = today;
    }


    /* View Todos */
    $rootScope.$on('sidepanel:closed', function(e) {
        $scope.resetPanel();
    });

    /*==========================================================================================
     Panel flip logic
     ==========================================================================================*/

    $scope.hideAllSlides = function hideAllSlides() {
        $scope.active_slide = null;
    }

    $scope.slideInView = function slideInView(slide) {
        $scope.active_slide = slide;
    }

    $scope.resetPanel = function resetPanel() {
        $scope.hideAllSlides();
        $scope.active_slide = 1;
    }

    $scope.resetPanel();

    $scope.handleViewDiaryEvent = function handleViewDiaryEvent() {
        $rootScope.$broadcast('closeAllSidepanels');
        $rootScope.$broadcast('closeAll');

        $rootScope.$broadcast('jobs:openDiaryEvent', 0, 0);
    }

    $scope.handleButtonClick = function(e, index, action) {
        $rootScope.$broadcast('logs:delete', index, action, $scope.category, $scope.rows[index]);
    }
}

function sortableTableColumnsCtrl($scope){
    $scope.$on('smart_table:columns_loaded', function(event, table_columns) {
        $scope.sortable_items = table_columns;
    });

    $scope.sortableColumns = {
        stop: function(e, ui) {
            $scope.$emit('smart_table:order_updated');
        }
    }
}
function FiveHundredErrorCtrl($scope, $state, $stateParams, fiveHundredError){
    $scope.getMessage = fiveHundredError.getData();
    if($scope.getMessage) {
        $scope.url =  $scope.getMessage.url;
        $scope.error =  $scope.getMessage.message;
        fiveHundredError.setData({});
    }else{
        $scope.url = null;
        $scope.message = null;
    };
}

function PhoneCallList($rootScope, $scope, prefix, $http){

    $scope.note = '';
    $scope.contact = '';
    $scope.sidepanelDataAction = '';
    $scope.selectedDate = '';
    $scope.postPoneDate = '';

    $scope.seletedDate = $scope.$parent.sidepanelData.Service_date;
    $scope.serviceType = $scope.$parent.sidepanelData.Service_type;
    if($scope.$parent.sidepanelData.duedate) {
        $scope.maxservicedate = moment($scope.$parent.sidepanelData.duedate.date);
        $scope.seletedDate = $scope.maxservicedate;
    }

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope.selectedDate = message.date;
        $scope.actionValue = message.date;
    });

    $scope.$watch('newServiceDate', function (newVal, oldVal) {
        if (newVal != undefined && newVal != '' && newVal != oldVal) {
            $scope.actionValue = newVal;
            var oldDate = $scope.seletedDate;
            var newDate = moment($scope.actionValue).format("DD/MM/YY");
            if (newDate < oldDate) {
                $scope.dueDateMessage = true;
            } else {
                $scope.dueDateMessage = false;
            }
        }
    });

    if($scope.serviceType == 'Multiple Services'){
        $scope.instanceId = $rootScope.instancesId;
    } else {
        $scope.instanceId = $scope.sidepanelData.id;
    }

    $scope.savePhoneCall = function (){

        if($scope.sidepanelDataAction == 'PostponeService'){
            $scope.postPoneDate = moment($scope.actionValue,'L').format("YYYY-MM-DD");
        }
        $scope.currentPage = $scope.$parent.pageNum;
        $scope.$emit('smart_table_with_filter:filtervalues', angular.toJson({'serviceType':$rootScope.serviceType,
            'contact':$scope.contact,
            'note':$scope.note,
            'instanceId':$scope.instanceId,
            'action':$scope.sidepanelDataAction,
            'postPoneDate':$scope.postPoneDate,
            'currentPage':$scope.currentPage,
            'serviceName':$scope.serviceType,
            'serviceReminderInstanceId':$scope.sidepanelData.serviceReminderInstanceId,
            'instanceIdStatus':$scope.sidepanelData.instanceIdStatus}));
    }

    $scope.isDisabled = function () {
        if(($scope.note == '' || $scope.contact == '') && $scope.sidepanelDataAction == '') {
            return true;
        }
        else if($scope.sidepanelDataAction == 'PostponeService' && ($scope.dueDateMessage == true || $scope.selectedDate == undefined)) {
            return true;
        } else {
            return false;
        }
    }

}
var ProcessingResolver = {
    getIdData: function ($q, $http, $stateParams, $state, $rootScope, PubNub,setDataCollection, cacheBust, prefix, adminPrefix) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var deferred = $q.defer();
        if(setDataCollection.getData() !=undefined){
            $http.get(this.templateUrl($stateParams)+ '?selectedInstancesId=' + setDataCollection.getData()).success(function (data, status, headersObj) {
                var headers = headersObj();
                SetRootScopeValues($rootScope, headers, PubNub, cacheBust);
                deferred.resolve(data);
            });

        }else{
            return;
        }
        return deferred.promise;

    }
}
function EmailAttachedFileSidePanelCtrl($scope, $rootScope){
    $scope.imageTypes = {'jpeg': true, 'jpg': true, 'png': true, 'gif': true};

    $scope.previewFile = function (fileObj) {
  	$rootScope.$broadcast('email_attachment:preview', fileObj);
/*
        var data = fileObj;
        var file = {
            'name': data['name'],
            'location': data['location'],
            'mimeType': data['type'],
            'createdondatetime': data['createdondatetime'],
            'type': data['type'],
            'id': data['id']
        };

        $scope.enablePreviewFiles = "AttachedFiles";
        $scope.imageFile = false;
        $scope.loadFromObject = false;
        $scope.file = file;
        $scope.selectedRow = fileObj.id;
        if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
            $scope.imageFile = true;
        } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf' ) {
            $scope.loadFromObject = true;
        }
        $scope.fileSelected = true;
*/
    }

    $scope.closePreview = function () {
/*
        $scope.enablePreviewFiles = "";
        $scope.selectedRow = "";
        $scope.file = "";
        $scope.fileSelected = false;
        $scope.recordArgs = '';
        $rootScope.selection = '';
        $rootScope.$broadcast('closeall');
*/
  	$rootScope.$broadcast('file:closepreview');
    }
}

function removeThousandsSeparator(currencySettings, value) {
    switch(currencySettings) {
        case 3: {
            return value;
        }
        case 4: {
            return value.replace(' ', '');
        }
        case 5: {
            return value.replace('.', '');
        }
        default: {
            return value.replace(',', '');
        }
    }
}

function PayBackMonies($scope, $rootScope, prefix, $http, $translate, warningModal){

    $scope.payBackMonies = $scope.$parent.sidepanelData.pay_back_monies;
    $scope.selectedId = $scope.$parent.sidepanelData.id;
    $scope.balance = removeThousandsSeparator($rootScope.intlSettingsCurrency, $scope.$parent.sidepanelData.Balance);
    $scope.paymentMethods = $scope.payBackMonies.paymentMethods;
    $scope.nominalAccounts = $scope.payBackMonies.nominalAccounts;
    $scope.isDisable = true;
    $scope.showError = false;
    $scope.CustomCurrency = $rootScope.CustomCurrency;

    if($scope.$parent.sidepanelData.category){
        $scope.category = $scope.$parent.sidepanelData.category;
    } else {
        $scope.category = '';
    }

    $scope.validateAllocation = function() {
        var allocation = $scope.amount,
            input_is_number = !isNaN(allocation);

        $scope.valid = true;

        if(allocation){
            var input_pattern =/^[0-9]{0,9}(\.[0-9]{1,2})?$/,
                is_valid = input_pattern.test(allocation);
        }

        if(!is_valid) {
            $scope.valid = false;
            $scope.validation_message = $translate('Please.Enter.Valid.Price');
            $scope.showBalance = false;
            $scope.isDisable = true;
        }

        if (input_is_number === true) {
            var amount = allocation.split('.');
            if(amount.length > 1){
                var valOne = amount[0];
                var valTwo = amount[1];
                if(valTwo.length == 1){
                    var newVal = 0 + valTwo;
                    allocation = valOne + '.' + newVal;
                }
            }
            if (allocation > $scope.balance ) {
                $scope.valid = false;
                $scope.validation_message = $translate('The.remaining.balance.is');
                $scope.balanceAmount = $scope.balance;
                $scope.showBalance = true;
                $scope.isDisable = true;
            }  else {
                $scope.isDisable = false;
            }
        } else if (input_is_number === false && (allocation == undefined || allocation == '')) {
            $scope.valid = false;
            $scope.validation_message = $translate('Please.enter.a.amount');
            $scope.showBalance = false;
            $scope.isDisable = true;
        } else {
            $scope.valid = false;
            $scope.validation_message = $translate('Please.enter.a.number');
            $scope.showBalance = false;
            $scope.isDisable = true;
        }

        $scope.isDisable = (!$scope.nominalCode || $scope.isDisable);
    }

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    if($scope.balance == 0.00) {
        $scope.isDisable = true;
    }

    $scope.savePayBackMonies = function (){

        $scope.currentPage = $scope.$parent.pageNum;

        $http.post(prefix + '/save_pay_back_money', "packBackMoney[paymentDate]=" + moment($scope.selectedDate).format("YYYY-MM-DD")
                + "&packBackMoney[nominalCode]=" + encodeURIComponent($scope.nominalCode)
                + "&packBackMoney[paymentMethod]=" + encodeURIComponent($scope.paymentMethod)
                + "&packBackMoney[amount]=" + encodeURIComponent($scope.amount)
                + "&packBackMoney[id]=" + $scope.selectedId
                + "&packBackMoney[description]=" + encodeURIComponent($scope.description)
                + "&packBackMoney[reference]=" + encodeURIComponent($scope.reference)
                + "&packBackMoney[currentPage]=" + encodeURIComponent($scope.currentPage)).
            success(function (data, status) {
                if(data.error == true){
                    $rootScope.flashMessage = data.errorMessage;
                    warningModal.show(data.errorMessage, "Save Payback Money", 'save_pay_back_money');
                } else {
                    $scope.saving = false;

                    if($scope.category == 'customer_account_credit' || $scope.category == 'invoice_address_account_credit') {

                        var data = {category: $scope.category};
                        $scope.$emit('smart_tablefilter:newsearch', data);
                    }
                }
            });
    }
}

function roundFloat(value,decimals,type) {
    value = parseFloat(Number(value).toFixed(decimals+11)); // Avoid COM-13466
    return (type === 'str') ? (Number(Math.round(value+'e'+decimals)+'e-'+decimals)).toFixed(decimals) : Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

function BatchFulfillParts($scope, $rootScope, $state, $stateParams, $timeout, jobData, $http, prefix,StockData) {
    $scope.jobId = $state.params.jobId;
    $scope.customerId = $state.params.customerId;

    $rootScope.$on('smart_table:pinned_rows_part_category', function(event, category) {
        $scope.category = category;
    });
    /*=========================================================================================================
     Set data on the scope
     =========================================================================================================*/
    $scope.show_modal_view = false;
    $scope.with_modal_transition = false;
    $rootScope.$on('smart_table:pinned_rows_updated', function(event, rows_store) {
        $scope.chosenParts = rows_store;
        if(!_.has($scope.chosenParts[0],'part')) {
          return;
        }

        if ($scope.chosenParts.length > 0) {
          var part_ids = [];
          for(var i = 0, l = $scope.chosenParts.length; i < l; i++) {
            $scope.chosenParts[i].partId = $scope.chosenParts[i].part.partId;
            $scope.chosenParts[i].id = $scope.chosenParts[i].part.id;
              $scope.chosenParts[i].Quantity = $scope.chosenParts[i].part.Quantity;
            part_ids.push($scope.chosenParts[i].part.partId);
          }
          $http.get(prefix + '/getStockLocationAvailable' + '?partId='+ part_ids).success(function (data) {
            jobData.setPartLocations(data);
            for(var i = 0, l = $scope.chosenParts.length; i < l; i++) {
              var chosen_part = $scope.chosenParts[i];
              var part_locations = '';
              if (chosen_part.part !== undefined && chosen_part.part.id !== undefined) {

                var part_id = chosen_part.part.partId,
                    part_locations = jobData.getStockLocationsByPartId(part_id) ? jobData.getStockLocationsByPartId(part_id).locations : '';


                chosen_part['locations'] = part_locations;
                chosen_part.part_allocation_total = $scope.generatePartAllocationTotal(part_locations);

                var order_from_supplier_amount_exists = chosen_part.order_from_supplier !== 'undefined' &&
                    chosen_part.order_from_supplier !== undefined;
                if (order_from_supplier_amount_exists) {
                  chosen_part.part_allocation_total += parseFloat(chosen_part.order_from_supplier.amount);
                } else {
                  chosen_part.order_from_supplier = {amount : 0};
                }

                if (chosen_part.part_allocation_total == chosen_part.part.Quantity) {
                  chosen_part.valid = true;
                } else {
                  chosen_part.valid = false;
                }
              } else {
                break
              }
            }
            if ($scope.chosenParts[0].part !== undefined &&
                $scope.chosenParts[0].part.id !== undefined) {

              $scope.updateChosenPartsAllocationTotals();
              $scope.updateSavingButtonText();
              $scope.checkIfAnyPartsAreInvalid();
              $rootScope.$broadcast('operationalTable:selectedChosenParts', $scope.chosenParts);
            }
          });
        }
    });

    $scope.generatePartAllocationTotal = function generatePartAllocationTotal(locations) {
        var allocation_total = 0;

        for(var i = 0, l = locations.length; i < l; i++) {
            var allocation = parseFloat(locations[i].allocation);
            allocation_total += allocation;
        }

        return allocation_total;
    }

    /*=========================================================================================================
     Validate the input if its a number - remove all
     letters
     =========================================================================================================*/
    $scope.validateOptions = function validateOptions(amount, part_id, location_id) {
        var allocation = this.location.allocation,
            allocation = allocation.replace(/[^0-9.]/g,'');

        $scope.chosenParts[part_id].locations[location_id].allocation = allocation;

        $scope.isQuantityInBounds(allocation, part_id);
    }

    /*=========================================================================================================
     Validate order from supplier input
     =========================================================================================================*/
    $scope.validateOrderQuantity = function validateOrderQuantity(amount, part_id) {

        var amount = amount.replace(/[^0-9.]/g,''),
            input_is_number = !isNaN(parseFloat(amount));

        $scope.chosenParts[part_id].order_from_supplier.amount = amount;

        if(input_is_number) {
            $scope.isQuantityInBounds(amount, part_id);
        }
    }

    /*=========================================================================================================
     Check if the inputted value is valid
     =========================================================================================================*/
    $scope.isQuantityInBounds = function isQuantityInBounds(num, part_id) {
        var grand_total = 0,
            num = parseFloat(num),
            total_allocated = 0,
            new_quantity_in_bounds,
            new_quantity_valid = true;

        var chosen_parts = $scope.chosenParts;

        $scope.handlePartValidation(part_id, num);
    }

    /*=========================================================================================================
     Check if the inputted value is valid
     =========================================================================================================*/
    $scope.handlePartValidation = function handlePartValidation(part_id, num) {
        var chosen_parts = $scope.chosenParts;

        $timeout(function(){
            var allocation = 0,
                quantity = 0,
                part_allocation_total = 0,
                current_part_quantity_needed = 0,
                part_locations,
                order_from_supplier_amount = parseFloat(chosen_parts[part_id].order_from_supplier.amount);

            $scope.disable_saving = true;

            // check if each allocation is valid against its stock location
            part_locations = chosen_parts[part_id].locations;
            for(var x = 0, c = part_locations.length; x < c; x++) {

                allocation = parseFloat(part_locations[x].allocation);
                quantity = parseFloat(part_locations[x].quantity);
                part_allocation_total += allocation;

                if (allocation <= quantity) {
                    $scope.chosenParts[part_id].locations[x].valid = true;
                }else{
                    $scope.chosenParts[part_id].locations[x].valid = false;
                }
                if (part_locations[x].location === 'General') {
                    $scope.chosenParts[part_id].locations[x].valid = true;
                }
            }
            // check if the sum of these allocations exceeds the total required for the part
            part_allocation_total += order_from_supplier_amount;
            var part_required_total = $scope.chosenParts[part_id].part.Quantity;
            if (part_allocation_total == part_required_total) {
                $scope.chosenParts[part_id].valid = true;
            } else {
                $scope.chosenParts[part_id].valid = false;
            }

            $scope.updateChosenPartsAllocationTotals();
            $scope.updateSavingButtonText();
            $scope.checkIfAnyPartsAreInvalid();
        },15);
    }

    /*=========================================================================================================
     Check if all inputs are valid
     =========================================================================================================*/
    $scope.updateChosenPartsAllocationTotals = function updateChosenPartsAllocationTotals(){
        var chosen_parts = $scope.chosenParts;

        for(var i = 0, l = chosen_parts.length; i < l; i++) {
           var  part_locations = chosen_parts[i].locations;
            var current_part_quantity_needed = parseFloat(chosen_parts[i].Quantity);
            var current_part_allocation_total = 0;
            var order_from_supplier_amount = parseFloat(chosen_parts[i].order_from_supplier.amount);

            for(var x = 0, c = part_locations.length; x < c; x++) {
               var  allocation = parseFloat(part_locations[x].allocation);
               current_part_allocation_total += (allocation)?allocation:0;
            }

            current_part_allocation_total += order_from_supplier_amount;
            $scope.chosenParts[i].part_allocation_total = current_part_allocation_total;
        }
    }

    $scope.updateSavingButtonText = function updateSavingButtonText() {
        var chosen_parts = $scope.chosenParts;

        $scope.saving_text = 'Save';
        $scope.show_purchase_order_button = false;
        $scope.hide_fulfill_all_parts = false;
        // update saving button text
        for(var i = 0, l = chosen_parts.length; i < l; i++) {
            var order_from_supplier_amount = parseFloat(chosen_parts[i].order_from_supplier.amount);

            for(var j = 0, ll = chosen_parts[i].locations.length; j < ll; j++) {
                var location_allocation = parseFloat(chosen_parts[i].locations[j].allocation);
                if (location_allocation > 0) {
                    $scope.hide_fulfill_all_parts = true;
                }
            }

            if (order_from_supplier_amount > 0) {
                if($rootScope.clientJobType == 1){
                    $scope.saving_text = 'Save';
                    $scope.show_purchase_order_button = false;
                    $scope.hide_fulfill_all_parts = true;
                }else{
                    $scope.saving_text = 'Save and create purchase order';
                    $scope.show_purchase_order_button = true;
                }
            }
        }
    }

    $scope.checkIfAnyPartsAreInvalid = function checkIfAnyPartsAreInvalid() {
        $scope.disable_saving = false;
        var chosen_parts = $scope.chosenParts;

        for(var i = 0, l = chosen_parts.length; i < l; i++) {
            var entire_part_valid = chosen_parts[i].valid === true;
            if (entire_part_valid) {
                var current_part_allocation_total = 0;
                var part_locations = chosen_parts[i].locations;

                for(var x = 0, c = part_locations.length; x < c; x++) {
                    var allocation = parseFloat(part_locations[x].allocation);
                    current_part_allocation_total += allocation;

                    var part_location_invalid = part_locations[x].valid === false;

                    if (part_location_invalid) {
                        $scope.disable_saving = true;
                        $scope.chosenParts[i].valid = false;
                        return
                    }
                }
                // check if the part allocation total matches the required total
                current_part_allocation_total += parseFloat(chosen_parts[i].order_from_supplier.amount);

                var current_part_quantity_needed = parseFloat(chosen_parts[i].part.Quantity);

                if (current_part_allocation_total !== current_part_quantity_needed) {
                    $scope.disable_saving = true;
                    $scope.chosenParts[i].valid = false;
                    return
                }

            }else{
                $scope.disable_saving = true;
                $scope.chosenParts[i].valid = false;
                return
            }

        }
        if (current_part_allocation_total !== current_part_quantity_needed && $scope.disable_saving === false) {
            $scope.disable_saving = true;
            $scope.chosenParts[i].valid = false;
        } else{
            $scope.disable_saving = false;
        }
    }


    /*=========================================================================================================
     When clicking order all from supplier, set all the
     other inputs to 0 and order from supplier input
     to the quantity required
     =========================================================================================================*/
    $scope.orderAllFromSupplier = function orderAllFromSupplier(part_id) {
        var locations = $scope.chosenParts[part_id].locations,
            chosen_parts = $scope.chosenParts;

        for(var i = 0, l = locations.length; i < l; i++) {
            locations[i].allocation = 0;
            locations[i].valid = true;
        }

        $scope.chosenParts[part_id].valid = true;
        $scope.chosenParts[part_id].order_from_supplier.amount = parseFloat($scope.chosenParts[part_id].part.Quantity);

        $scope.handlePartValidation(part_id);
        $scope.updateChosenPartsAllocationTotals();
        $scope.updateSavingButtonText();
        $scope.checkIfAnyPartsAreInvalid();
    }

    /*=========================================================================================================
     If the user blurs away from the inout and the input
     is blank
     =========================================================================================================*/
    $scope.handleBlur = function handleBlur(event, location_id, part_id) {
        if (event.target.value.length === 0) {
            $scope.chosenParts[part_id].locations[location_id].allocation = 0;

            $scope.handlePartValidation(part_id);
            $scope.updateChosenPartsAllocationTotals();
            $scope.updateSavingButtonText();
            $scope.checkIfAnyPartsAreInvalid();
        }
    }

    /*$scope.unCheck = function unCheck(part){
        angular.forEach(part, function (val, key) {
            if(val['allocation'] != 0){
                val['allocation'] = 0;
                $("#"+val['location']).removeAttr("checked");
            }
        })
    }*/

    /*=========================================================================================================
     If the user blurs away from the order from supplier
     input and the input is blank
     =========================================================================================================*/
    $scope.handleSupplierOrderBlur = function handleSupplierOrderBlur(event, part_id) {
        if (event.target.value.length === 0) {
            $scope.chosenParts[part_id].order_from_supplier.amount = 0;

            $scope.handlePartValidation(part_id);
            $scope.updateChosenPartsAllocationTotals();
            $scope.updateSavingButtonText();
            $scope.checkIfAnyPartsAreInvalid();
        }
    }

    /*=========================================================================================================
     When a purchase order needs to be generated
     =========================================================================================================*/
    $scope.populateItemsForPurchaseOrder = function populateItemsForPurchaseOrder() {
        var chosen_parts = $scope.chosenParts;
        $scope.itemsForPurchaseOrder = [];
        for(var i = 0, l = chosen_parts.length; i < l; i++) {
            var order_from_supplier_amount = parseFloat(chosen_parts[i].order_from_supplier.amount);
            if (order_from_supplier_amount > 0) {
                $scope.itemsForPurchaseOrder.push(chosen_parts[i]);
            }
        }
    }

    $scope.goToPurchaseOrder = function goToPurchaseOrder(partsData, fulfil) {

        $state.params.prepopulatePurchaseOrder = true;
        $scope.totalPartData = [];
        $scope.selectedPartData = [];
        var jobId = false;
        var customerId = false;
        var customerType = false;
        var operational = false;
        var category = false;
        var subcat = false;

        if(fulfil === 'OrderFromSupplier' && $rootScope.clientJobType != 1) {
            $scope.show_purchase_order_button = true;
        }
        angular.forEach(partsData, function (key, value){
           var orderToSupplier = (fulfil === 'OrderFromSupplier')?key.part.Quantity:key.order_from_supplier.amount;
            _.find(key.locations, function (location, lkey) {
                if (location && (location.location === fulfil)) {
                    key.locations[lkey].allocation = key.part.Quantity;
                }
            });
            if (key.operational == true) {
                /* this will be true if the user has selected the part through the parts operational list (i.e. /reporting/operational/parts/parts) */
                /* see reportingOperationalTable directive for more details */

                if (jobId == false) {
                        jobId = key.jobid;
                }
                if (operational == false) {
                        operational = key.operational;
                }
                if (customerId == false) {
                        customerId = key.customerId;
                }
                if (customerType == false) {
                        customerType = key.customerType;
                }
                if (category == false) {
                        category = key.category;
                }
                if (subcat == false) {
                        subcat = key.subcat;
                }
            }
            if(orderToSupplier > 0 || _.contains(_.map(key.locations, function(val){ return val.allocation > 0; }),true)) {
                $scope.selectedPartData.push({'orderToSupplier':orderToSupplier, 'totalQuantity':key.part.Quantity, 'availableParts': key.locations, 'jobPartId': key.part.id, 'partId':key.part.partId, 'salesPrice': key.part['Unit sales price'], 'costPrice': key.part['Unit cost price']})
            }
            $scope.totalPartData.push({'orderToSupplier':orderToSupplier, 'totalQuantity':key.part.Quantity, 'availableParts': key.locations, 'jobPartId': key.part.id, 'partId':key.part.partId, 'salesPrice': key.part['Unit sales price'], 'costPrice': key.part['Unit cost price']})
        });
        jobData.setSelectedPart($scope.selectedPartData);
        if($scope.show_purchase_order_button){
            $scope.populateItemsForPurchaseOrder();
            $scope.closePanel();

            if (!operational) {
                    if($state.params.milestoneId){
                        $state.transitionTo("loggedin.customer_list.view.job.details.new_job_part_purchase_order_milestone", {id: $state.params.id, jobId: $state.params.jobId, type: $state.params.type, milestoneId : $state.params.milestoneId });
                    }else{
                        $state.mergeJobPartIds =  jobData.getMergeJobParts();
                        $state.transitionTo("loggedin.customer_list.view.job.details.new_job_part_purchase_order", { id: $state.params.id, jobId: $state.params.jobId, type: $state.params.type});
                    }
            }
            else {
                /* this will be true if the user has selected the part through the parts operational list (i.e. /reporting/operational/parts/parts) */
                /* see reportingOperationalTable directive for more details */
                localStorage.setItem('operational_part_add_po', true);
                $state.transitionTo("loggedin.customer_list.view.job.details.new_job_part_purchase_order_operational", { id: customerId, jobId: jobId, type: customerType, operational: true, category: category, subcategory: subcat});
            }

        }else{
                if (!operational) {
                    $http.post(prefix + '/save_part_fulfill', "selectedParts=" + encodeURIComponent(angular.toJson($scope.totalPartData)) + "&clientJobType=" + $rootScope.clientJobType + "&jobToDos=" + $rootScope.clientJobTodos + "&jobId=" + $state.params.jobId + "&milestoneId=" + $state.params.milestoneId+"&mergeJobPartIds="+ encodeURIComponent(angular.toJson(jobData.getMergeJobParts()))).
                        success(function (data, status) {
                            if (status == 200) {
                                $rootScope.$broadcast('smart_tablefilter:newsearch',{'category': $scope.category});
                                $rootScope.$broadcast('spreadsheet:todoupdate');
                                $rootScope.$broadcast('partFulfilled', $scope.totalPartData, jobId);
                                $scope.closePanel();
                            }
                        });
                }else {
                /* this will be true if the user has selected the part through the parts operational list (i.e. /reporting/operational/parts/parts) */
                /* see reportingOperationalTable directive for more details */
                    $http.post(prefix + '/fulfill_job_parts', 'selectedParts=' + encodeURIComponent(angular.toJson($scope.totalPartData)) + '&jobId=' + jobId+"&mergeJobPartIds="+ encodeURIComponent(angular.toJson(jobData.getMergeJobParts()))).
                        success(function (data, status) {
                            if (status == 200) {
                                $rootScope.$broadcast('smart_tablefilter:newsearch',{'category': $scope.category});
                                $rootScope.$broadcast('spreadsheet:todoupdate');
                                $rootScope.$broadcast('partFulfilled', $scope.totalPartData, jobId);
                                $scope.closePanel();
                            }
                        });
        }
        }
    }

    $scope.closePanel = function closePanel() {
        $rootScope.$broadcast('smart_table:unpin_all_pinned_rows');
        $rootScope.$broadcast('closeAllSidepanels');
        $scope.massfulfilltype = '';
    }


    $scope.cancelSidePanel = function cancelSidePanel() {
      $scope.$emit('sidepanel:close');
    }

    //handled when it is other tha
    $rootScope.$on('parent:updated',function(){
        $scope.massfulfilltype = '';
    });
    // Check whether Purchase order screen have permission or not.
    var featureName = 'Purchaseorder', featureAccess = 'writeaccess', featureId = features[featureName];
    var purchaseOrderPermissions = $rootScope.hasPermission(featureId, featureAccess);

    if(purchaseOrderPermissions || $rootScope.clientJobType == 1 ){
        $scope.purchaseOrderAccess = true;
    }else{
        $scope.purchaseOrderAccess = false;
    }
    GetAllStockLocationsCtrl.call(this, $scope, $http, prefix,false,true);

    $scope.initMassFulfill = function initMassFulfill(source){
        $scope.source = source;
        $scope.massFulfill = false;
        $scope.loading = true;
        $scope.selectedStockLocation = '';
        $scope.massfulfilltype = '';
        var customerId = '';var jobId = '';
        if($scope.$root.clientJobType === 3){
            $scope.massfulfilltype = 'fromstock';
        }
        if(source === 'job_parts'){
            if($scope.$root.rows_store.length === 0)
            {
                $scope.getMassFulfillmentParts(source);
                $scope.massFulfill = true;
            }else{
                $scope.changeMassFulfillmentType();
                //santize the parts
                jobData.setMergeJobParts($scope.chosenParts,source);
                $scope.chosenParts = jobData.getSantizedParts();
            }
            customerId = $state.params.id;
            jobId = $state.params.jobId;
        }else if(source === 'parts_operational_list'){
                if($scope.chosenParts){
                    customerId = $scope.chosenParts[0].customerId;
                    jobId = $scope.chosenParts[0].jobid;

                    //santize the parts
                    jobData.setMergeJobParts($scope.chosenParts,source);
                    $scope.chosenParts = jobData.getSantizedParts();
                }
            $timeout(function() {
                $scope.changeMassFulfillmentType();
            },500);
        }
        if(customerId != '' && jobId != ''){
            StockData.getDiaryEngineerStockLocationsAndWarehouses(customerId,jobId).then(function(data){
                $scope.selectedStockLocation = data.data.locations;
                $scope.defaultStockLocation = data.data.locations;
            });
        }

        $scope.disable_saving = true;
        $scope.saving_text = 'Save';
        $timeout(function() {
            $scope.loading = false;
        } , 700);
    }

    $scope.$on('sidepanel_mass_fulfillment_stock_refresh',function(e,data,parts){
        if(data === 'parts_operational_list'){
            $scope.chosenParts = parts;
        }
        $scope.initMassFulfill(data);
    });

    $scope.allocateQtyToLocation = function allocateQtyToLocation(locationStatus, part_id, location_id) {
        var part = $scope.chosenParts[part_id];
        if(locationStatus){
            var requiredQty = part.part.Quantity - part.part_allocation_total;
            if($scope.order_remaining_from_supplier && parseFloat(part.order_from_supplier.amount)){
                var reducedQty = (this.location.quantity > part.part.Quantity) ? part.part.Quantity : this.location.quantity;
                var newOrderFromSupplierQty = part.order_from_supplier.amount - ((requiredQty > 0) ? requiredQty : reducedQty);
                $scope.validateOrderQuantity(newOrderFromSupplierQty.toString(), part_id);
                $scope.updateChosenPartsAllocationTotals();
                requiredQty = part.part.Quantity - part.part_allocation_total;
            }
            if(requiredQty < this.location.quantity){
                var allocation = requiredQty;
            }else{
                var allocation = this.location.quantity;
            }
        }else{
            var allocation = 0;
        }
        $scope.chosenParts[part_id].locations[location_id].allocation = allocation.toString();
        $scope.isQuantityInBounds(this.location.allocation, part_id);
        $scope.updateChosenPartsAllocationTotals();
        if($scope.order_remaining_from_supplier && allocation === 0){
            var qty = parseFloat(part.part.Quantity) - part.part_allocation_total;
            if(parseFloat(part.order_from_supplier.amount) > 0){
                qty += parseFloat(part.order_from_supplier.amount);
            }
            $scope.validateOrderQuantity(qty.toString(), part_id);
        }
    }
    $scope.changeMassFulfillmentType =function changeMassFulfillmentType(){
        $scope.disable_saving = true;
        $scope.allocationTab = true;
        if((typeof $scope.chosenParts  === 'undefined' || $scope.chosenParts.length < 1 )&& $scope.massFulfill){
            $scope.getMassFulfillmentParts();
        }else if(typeof $scope.chosenParts  != 'undefined'){
            $scope.resetChosenPartsTotals();
        }
        if($scope.massfulfilltype === 'raisepo'){
            $scope.disable_saving = false;
            if($rootScope.clientJobType ===2) {
                $scope.saving_text = 'Save and create purchase order';
            }
        }else if($scope.massfulfilltype === 'fromstock'){
            if(_.pluck($scope.chosenParts,'partId').length > 0){
                $http.get(prefix + '/getStockLocationAvailable?partId='+_.pluck($scope.chosenParts,'partId').join()).then(function(response) {
                    if(response.data){
                        angular.forEach($scope.chosenParts,function(value,key){
                            var item = _.find(response.data,function(x){return x.part_id == value.part.partId });
                            angular.forEach(item.locations, function(val, key) {
                                val.view = false;
                            });
                            value.locations = item.locations;
                        });
                        $scope.getAvailableStock($scope.selectedStockLocation);
                    }
                });
            }
        }
    }

    $scope.massFulfillParts = function massFulfillParts(){
        if($scope.massfulfilltype === 'raisepo'){
            $scope.goToPurchaseOrder($scope.chosenParts, 'OrderFromSupplier');
        }
        if($scope.massfulfilltype === 'fromstock'  || $scope.massfulfilltype === 'individually'){
            $scope.goToPurchaseOrder($scope.chosenParts, '');
        }
    }

    $scope.getMassFulfillmentParts = function getMassFulfillmentParts(source){
        $http.get(prefix + '/customers/customer/'+ $state.params.id +'/jobs/'+ $state.params.jobId +'/parts?category=job_parts_requested&status=Requested&massfulfill=true').success(function (data, status) {
            $scope.massFulfillmentData = data.job_parts_requested.data;
            // $scope.mergeJobPartsIds = data.mergedJobParts;
            angular.forEach($scope.massFulfillmentData, function(part, partIndex) {
                if (!part.part) {
                    part.part = {};
                }
                part.part.Part = part.Part;
                part.part.Quantity = part.Quantity;
                part.part.FulfillType = "";
                part.part.general = undefined;
                part.part.partId = part.partId;
                part.part.partStatus = "Requested";
                part.part.id = part.id;
            });
            $scope.chosenParts = $scope.massFulfillmentData;
            jobData.setMergeJobParts($scope.chosenParts,source);
            $scope.chosenParts = jobData.getSantizedParts();
            if($scope.$root.clientJobType === 3 && $scope.chosenParts.length > 0){
                $scope.changeMassFulfillmentType();
            }
        });
    }

    $scope.resetChosenPartsTotals = function resetChosenPartsTotals(){
        $scope.selectedStockLocation = $scope.defaultStockLocation;
        angular.forEach($scope.chosenParts, function (chosen_part,key){
            var locations = chosen_part.locations;
            var resetValue = 0;
            chosen_part.part_allocation_total = resetValue.toString();
            chosen_part.order_from_supplier = {amount : resetValue.toString()};
            chosen_part.valid = false;
            chosen_part.massFulfillFromStock = false;
            chosen_part.stockLocationAvailableIndex = -1;
            angular.forEach(chosen_part.locations, function (location,index){
                location.allocation = resetValue.toString();
            });
        });
        if ($scope.chosenParts[0].part !== undefined && $scope.chosenParts[0].part.id !== undefined) {
            $scope.updateChosenPartsAllocationTotals();
            $scope.updateSavingButtonText();
            $scope.checkIfAnyPartsAreInvalid();
        }
    }

    $scope.OrderRemainingFromSupplier = function OrderRemainingFromSupplier(order_remaining_from_supplier){
        $scope.order_remaining_from_supplier = order_remaining_from_supplier;
        angular.forEach($scope.chosenParts, function (chosen_part,key) {
            if(order_remaining_from_supplier == true) {
                var qty = chosen_part.part.Quantity - chosen_part.part_allocation_total;
                chosen_part.order_from_supplier = {amount : qty.toString()};
            }else{
                var qty = 0;
                chosen_part.order_from_supplier = {amount : qty.toString()};
            }
            $scope.validateOrderQuantity(chosen_part.order_from_supplier.amount, key);
        });
    };

    $scope.getAvailableStock = function getAvailableStock(Location){
        $scope.selectedStockLocation = Location;
        var selectedLocations = _.pluck(Location, 'id');
        angular.forEach($scope.chosenParts, function (partVal,partKey){
            angular.forEach(partVal.locations, function (value,key){
                if(value.location != 'General'){
                    if(_.indexOf(selectedLocations, parseInt(value.id)) > -1 && value.view === false){
                        value.view = true;
                        if(value.engineerName === '' || typeof value.engineerName === undefined) { value.engineerName = Location[_.indexOf(selectedLocations, parseInt(value.id))].engineerName; }
                    }else if(_.indexOf(selectedLocations, parseInt(value.id)) === -1 && value.view === true){
                        value.allocation = 0;
                        value.view = false;
                        value.status = false;
                        $scope.isQuantityInBounds(value.allocation, key);
                    }
                }
            });
        });
    }
    $scope.checkNoStockLocation = function(Locations){
        var checkLocationShown = _.pluck(Locations,'view');
        return (_.indexOf(checkLocationShown,true) > -1 ? false : true);
    }
    $scope.checkAllPartFulfilledFromStock = function(){
        var checkFulfilType = _.map($scope.chosenParts ,function(item,index){
            return ((item.part_allocation_total === parseInt(item.part.Quantity) && item.valid === true && item.order_from_supplier.amount === '0')? true : false);
        });
        return !(_.contains(checkFulfilType,false));
    }
    $scope.massAllocateFromStock = function massAllocateFromStock(){
        angular.forEach($scope.chosenParts, function (value,key){
            if(value.massFulfillFromStock){
                value.massFulfillFromStock = false;
                var allocateQty = 0;
                var QuantityNeeded = (value.part_allocation_total && value.part_allocation_total > 0) ?  (value.part.Quantity - value.part_allocation_total) : value.part.Quantity;
                if($scope.stockLocationAvailable[value.stockLocationAvailableIndex]['Balance available'] >= QuantityNeeded){
                    allocateQty = QuantityNeeded;
                }else if($scope.stockLocationAvailable[value.stockLocationAvailableIndex]['Balance available'] < QuantityNeeded){
                    allocateQty = $scope.stockLocationAvailable[value.stockLocationAvailableIndex]['Balance available'];
                }
                if(allocateQty > 0){
                    var item = _.find(value.locations,function(val,index){val.index = index; return val.id == $scope.selectedStockLocation.id; });
                    value.locations[item.index].allocation = allocateQty;
                    $scope.isQuantityInBounds(allocateQty, key);
                }
            }
        });
    }
}
function ReportingIncompletePartsOperationalCtrl($scope, $state, $stateParams, $timeout, $http, prefix, tableCollection, $translate, $rootScope){
    $scope = $scope.$parent;
    $scope.MassCancelParts = function(){
        if($scope.mode === 'requested' || $scope.mode === 'on_order'){
            $scope.postParamsJobs = [];
            var params = [];$scope.postParams = [];
            if($scope.mode === 'requested'){
                var partStatus = 'Requested';
            }else if($scope.mode === 'on_order'){
                var partStatus = 'On Order';
            }
            angular.forEach($scope.operationalList,function(value,key){
                var subIndex = [];
                angular.forEach(value.parts,function(val,index){
                    if(val.selected === true){
                        if(partStatus === 'Requested'){
                            params.push({'id':val.part.id,'totalQuantity': val.part.Quantity,'allocatedQuantity': val.part.Quantity});
                        }else{
                            params.push({'id':val.fulfillmentid,'totalQuantity': val.part.Quantity,'allocatedQuantity': val.part.Quantity});
                        }
                        $scope.postParamsJobs.push(value.jobnumber);
                    }
                });
            });
            if(params.length > 0){
                $scope.$parent.showMassCancelPartsConfirmation = true;
                $scope.postPartsParams = params;
                $scope.postParams =  'partStatus=' + partStatus + '&partsData=' + angular.toJson(params)  + '&selectedStatus=Cancelled&moduleId=' + $scope.clientJobType + '&jobtodos=' + $scope.clientJobTodos;
            }
        }
    }
    $scope.MassInstallParts = function(){
        var params = [];$scope.postParams = [];
        $scope.postParamsJobs = [];
        if($scope.mode === 'requested'){
            var partStatus = 'Requested';
        }else if($scope.mode === 'on_order'){
            var partStatus = 'On_Order';
        }else if($scope.mode == 'available'){
            var partStatus = 'Available';
        }
        angular.forEach($scope.operationalList,function(value,key){
                angular.forEach(value.parts,function(val,index){
                if(val.selected === true){
                    if(partStatus === 'Requested'){
                        params.push({'id':val.part.id,'totalQuantity': val.part.Quantity,'allocatedQuantity': val.part.Quantity,'jobId':val.jobid,'jobPartId':val.jobpartid,'partId':val.part.partId});
                    }else{
                        params.push({'id':val.fulfillmentid,'totalQuantity': val.part.Quantity,'allocatedQuantity': val.part.Quantity,'jobId':val.jobid,'jobPartId':val.jobpartid,'partId':val.part.partId});
                    }
                    $scope.postParamsJobs.push(value.jobnumber);
                }
            });
        });
        if(params.length > 0){
            $scope.$parent.showInstallPartsConfirmation = true;
            $scope.postPartsParams = params;
            $scope.postParams =  'partStatus=' + partStatus + '&partsData=' + angular.toJson(params)  + '&moduleId=' + $scope.clientJobType + '&jobtodos=' + $scope.clientJobTodos;
        }
    }
    $scope.refreshParts = function(){
        var pluckParts = _.pluck($scope.postPartsParams,'id');
        angular.forEach($scope.operationalList,function(value,key){
            if(_.contains( $scope.postParamsJobs,value.jobnumber))
            {
                angular.forEach(value.parts,function(val,index) {
                    if (val.selected === true) {
                        if ($scope.mode === 'requested') {
                            value.parts= _.reject(value.parts,function(val,index){ return _.contains(pluckParts,val.part.id); });
                        } else {
                            value.parts= _.reject(value.parts,function(val,index){ return _.contains(pluckParts,val.fulfillmentid); });
                        }
                        $scope.postParamsJobs.push(value.jobnumber);
                    }
                })
            }
            $scope.postPartsParams = [];
            $scope.postParamsJobs = [];
        });
    }
}
function ReportingIncompletePartsOperationalStockCtrl($scope, $state, $stateParams, $timeout, $http, prefix, tableCollection, $translate, $rootScope){
    $scope = $scope.$parent;
    $scope.StockMassCancelParts = function(){
        if($scope.mode === 'requested' || $scope.mode === 'on_order'){
            $scope.postParamsJobs = [];
            var params = [];$scope.postParams = [];
            if($scope.mode === 'requested'){
                var partStatus = 'Requested';
            }else if($scope.mode === 'on_order'){
                var partStatus = 'On Order';
            }
            angular.forEach($scope.operationalList,function(value,key){
                var subIndex = [];
                angular.forEach(value.parts,function(val,index){
                    if(val.selected === true){
                        if(partStatus === 'Requested'){
                            params.push({'id':val.part.id,'totalQuantity': val.part.Quantity,'allocatedQuantity': val.part.Quantity});
                        }else{
                            params.push({'id':val.fulfillmentid,'totalQuantity': val.part.Quantity,'allocatedQuantity': val.part.Quantity});
                        }
                        $scope.postParamsJobs.push(value.jobnumber);
                    }
                });
            });
            if(params.length > 0){
                $scope.$parent.showMassCancelPartsConfirmation = true;
                $scope.postPartsParams = params;
                $scope.postURL = Routing.generate('part_batch_status_update',{'jobId' : $scope.jobId,'currentStatus': partStatus.replace(/\s+/g, '_'),'updateStatus' : 'Cancelled'});
                $scope.postParams =  'partStatus=' + partStatus + '&partsData=' + angular.toJson(params)  + '&selectedStatus=Cancelled&moduleId=' + $scope.clientJobType + '&jobtodos=' + $scope.clientJobTodos;
            }
        }
    }
    $scope.StockMassInstallParts = function(){
        var params = [];$scope.postParams = [];
        $scope.postParamsJobs = [];
        if($scope.mode == 'available'){
            var partStatus = 'Available';
            angular.forEach($scope.operationalList,function(value,key){
                angular.forEach(value.parts,function(val,index){
                    if(val.selected === true){
                        params.push({'id':val.fulfillmentid,'totalQuantity': val.part.Quantity,'allocatedQuantity': val.part.Quantity,'jobId':val.jobid,'jobPartId':val.jobpartid,'partId':val.part.partId});
                        $scope.postParamsJobs.push(value.jobnumber);
                    }
                });
            });
            if(params.length > 0){
                $scope.$parent.showInstallPartsStockConfirmation = true;
                $scope.postPartsParams = params;
                $scope.postParams =  'partStatus=' + partStatus + '&partsData=' + angular.toJson(params)  + '&moduleId=' + $scope.clientJobType + '&jobtodos=' + $scope.clientJobTodos;
            }
        }
    }
    $scope.StockMassReturnParts = function(){
        var params = [];$scope.postParams = [];
        $scope.postParamsJobs = [];
        if($scope.mode == 'available'){
            var partStatus = 'Available';
            angular.forEach($scope.operationalList,function(value,key){
                angular.forEach(value.parts,function(val,index){
                    if(val.selected === true){
                        if(partStatus === 'Requested'){
                            params.push({'id':val.part.id,'totalQuantity': val.part.Quantity,'allocatedQuantity': val.part.Quantity,'jobId':val.jobid,'jobPartId':val.jobpartid,'partId':val.part.partId});
                        }else{
                            params.push({'id':val.fulfillmentid,'totalQuantity': val.part.Quantity,'allocatedQuantity': val.part.Quantity,'jobId':val.jobid,'jobPartId':val.jobpartid,'partId':val.part.partId});
                        }
                        $scope.postParamsJobs.push(value.jobnumber);
                    }
                });
            });
            if(params.length > 0){
                $scope.$parent.showReturnPartsConfirmation = true;
                $scope.postPartsParams = params;
                $scope.postParams =  'partStatus=' + partStatus + '&partsData=' + angular.toJson(params)  + '&moduleId=' + $scope.clientJobType + '&jobtodos=' + $scope.clientJobTodos;
            }
        }
    }
    $scope.refreshParts = function(){
        $scope.$parent.showMassCancelPartsConfirmation = false;
        $scope.$parent.showInstallPartsStockConfirmation = false;
        var pluckParts = _.pluck($scope.postPartsParams,'id');
        angular.forEach($scope.operationalList,function(value,key){
            if(_.contains( $scope.postParamsJobs,value.jobnumber))
            {
                angular.forEach(value.parts,function(val,index) {
                    if (val.selected === true) {
                        if ($scope.mode === 'requested') {
                            value.parts= _.reject(value.parts,function(val,index){ return _.contains(pluckParts,val.part.id); });
                        } else {
                            value.parts= _.reject(value.parts,function(val,index){ return _.contains(pluckParts,val.fulfillmentid); });
                        }
                        $scope.postParamsJobs.push(value.jobnumber);
                    }
                })
            }
            $scope.postPartsParams = [];
            $scope.postParamsJobs = [];
        });
    }
}

