'use strict';
angular.module('csDynamicForm', ['dndLists','checklist-model','ui.datepicker'])
.config(['$stateProvider', function($stateProvider){

}])
.factory('_', ['$window', function($window) {
  return $window._;
}])
.constant('DF_CONFIG_FIELDSTYPE',{
  fields: 'fields',
  nested_dropdown: 'nested_dropdown',
  dropdown: 'dropdown',
  text: 'text',
  column: 'column'
})
.constant('DF_DATE_FORMATS',{
  items: [
    'dd/MM/yyyy',
    'EEEE, MMMM dd, yyyy',
    'MMMM dd, yyyy',
    'MMM dd, yyyy',
    'MM/dd/yyyy'],
  defaultValue: 'dd/MM/yyyy'
})
.constant('DF_Properties', {
  image: {
    //showAlt: false,
    width: [0, 'px'],
    height: [0, 'px']
  },
  barcode_scanner: {
    preferFrontCamera: false,
    showFlipCameraButton: true,
    showTorchButton: true,
    orientation: 'portrait'
  },
  barcode_generator: {
    format: 'CODE128',
    lineColor: '#000000',
    width: 2,
    height: 100,
    displayValue: true,
    font: 'monospace',
    textAlign: 'center',
    textPosition: 'bottom',
    textMargin: 2,
    fontSize: 20,
    background: '#ffffff',
    margin: 0,
    // marginTop: undefined,
    // marginBottom: undefined,
    // marginLeft: undefined,
    // marginRight: undefined,
  }
})
.service('dynamicActionService', [function(){
  var $_actions = {
    add_section_action: {
      templateUrl: '/template/dynamic-form/modal/add_section_modal.html',
      controller: 'DynamicFormModalAddSectionCtrl',
      modalClass: 'df-action df-action-add_section-modal df-action-modal sidepanel'
    },
    remove_section_action: {
      templateUrl: '/template/dynamic-form/modal/remove_section_modal.html',
      controller: 'DynamicFormModalRemoveSectionCtrl',
      modalClass: 'df-action df-action-remove_section-modal'
    },
    delete_action: {
      templateUrl: '/template/dynamic-form/modal/delete_element_modal.html',
      controller: 'DynamicFormModalDeleteCtrl',
      modalClass: 'df-action df-action-delete-modal'
    },
    add_element: {
      templateUrl: '/template/dynamic-form/modal/add_element_modal.html',
      controller: 'DynamicFormModalAddCtrl',
      modalClass: 'df-action df-action-add-modal df-action-modal sidepanel'
    },
    add_dependant: {
      templateUrl: '/template/dynamic-form/modal/add_dependant_modal.html',
      controller: 'DynamicFormModalDependantCtrl',
      modalClass: 'df-action df-action-add_dependant-modal df-action-modal sidepanel'
    },
    add_value_dependant: {
      templateUrl: '/template/dynamic-form/modal/add_value_dependant_modal.html',
      controller: 'DynamicFormModalValueDependantCtrl',
      modalClass: 'df-action df-action-add_dependant-modal df-action-modal sidepanel'
    },
    add_defaults: {
      templateUrl: '/template/dynamic-form/modal/add_defaults_modal.html',
      controller: 'DynamicFormModalDefaultsCtrl',
      modalClass: 'df-action df-action-add_defaults-modal df-action-modal sidepanel'
    },
    add_items: {
      templateUrl: '/template/dynamic-form/modal/add_items_modal.html',
      controller: 'DynamicFormModalAddOptionsCtrl',
      modalClass: 'df-action df-action-add_items-modal df-action-modal sidepanel'
    },
    add_group_items: {
      templateUrl: '/template/dynamic-form/modal/add_group_items_modal.html',
      controller: 'DynamicFormModalAddGroupOptionsCtrl',
      modalClass: 'df-action df-action-add_items-modal add_group_items df-action-modal sidepanel'
    },
    add_validations: {
      templateUrl: '/template/dynamic-form/modal/add_validations_modal.html',
      controller: 'DynamicFormModalAddValidationsCtrl',
      modalClass: 'df-action df-action-add_validations-modal df-action-modal sidepanel'
    },
    set_formatter: {
      templateUrl: '/template/dynamic-form/modal/set_formatter_modal.html',
      controller: 'DynamicFormModalElementFormatCtrl',
      modalClass: 'df-action df-action-set_formatter-modal df-action-modal sidepanel'
    },
    set_properties: {
      templateUrl: '/template/dynamic-form/modal/set_properties_modal.html',
      controller: 'DynamicFormModalPropertiesCtrl',
      modalClass: 'df-action df-action-set_properties-modal df-action-modal sidepanel'
    },
    add_formulas: {
      templateUrl: '/template/dynamic-form/modal/add_formulas_modal.html',
      controller: 'DynamicFormModalFormulasCtrl',
      modalClass: 'df-action df-action-add_formulas-modal df-action-modal sidepanel'
    },
    quick_filters: {
      templateUrl: '/template/dynamic-form/modal/quick_filters_modal.html',
      controller: 'DynamicFormModalQuickFiltersCtrl',
      modalClass: 'df-action df-action-add_dependant-modal df-action-modal sidepanel'
    }
  };
  this.getModalActions = function() {
    return $_actions;
  };
}])
.service('dfDataManipulationService', ['DF_CONFIG_FIELDSTYPE',function(DF_CONFIG_FIELDSTYPE){
  var var_fields = DF_CONFIG_FIELDSTYPE.fields,
  var_column = DF_CONFIG_FIELDSTYPE.column;

  this.assetRecords = {};
  this.setAssetRecords = function(records) {
    this.assetRecords = records;
  };

  function _deepFlatten(data, deep) {
    var _deep = deep || false, _tmp = [];
    _.forEach(data, function(x){
      if(x.type === var_column) {
        _tmp.push(_deepFlatten(x.columns));
      }
      else {
        _tmp.push( _.has(x, var_fields) ? x[var_fields] : x);
      }
    });
    return _tmp;
  }

  this.getExistsAssetReferenceIs = function(jsonModal) {
    var refs = [];
    _.forEach(jsonModal.sections, function(section){
      if(section.type==='assets' && section.referenceId !== '') {
        refs.push(parseInt(section.referenceId));
      }
    });
    return refs;
  };

  this.getLastQuestionId = function(jsonModal) {
    return _.last(this.getFlattenArray(jsonModal));
  };

  this.getFlattenArray = function(jsonModal) {
    var list = [];
    /*if(!_.isEmpty(this.assetRecords)) {
      jsonModal = this.assetRecords;
    }*/
    list = _.map(jsonModal.sections, function(x){
      return _deepFlatten(x[var_fields]);
    });
    return _.flatten(list);
  };

  this.prepareFormModel = function(values) {
    values = _.isUndefined(values) ? false : values;
    var formModel={}, elements = this.getFlattenArray(this.assetRecords);
    return _.chain(this.getFlattenArray(this.assetRecords))
      .map(function(field){
        var item = {
          key: field.questionID,
          value: '',
          fieldType: field.type,
          fieldValueId: '',
          dependencies: field.dependencies
        };
        return item;
      })
      .value();

  };

  this.getSupportedFormulaElement = function(jsonModal, types) {
    if(types.length === 0) {
      return [];
    }
    var flattenArray = this.getFlattenArray(jsonModal), e;
    e = _.chain(flattenArray)
      .filter(function(x){
        return _.indexOf(types, x.type) > -1;
      })
      .value();
    return e;
  };

  this.getMarkedAsFlag = function(jsonModal) {
    return _.chain(this.getFlattenArray(jsonModal))
      .filter(function(x){
        return _.has(x, 'flag') && x.flag === true;
      })
      .value();
  };

  this.getValidationList = function(jsonModal) {
    var flattenArray = this.getFlattenArray(jsonModal), e; //console.log('flattern', flattenArray);
    e = _.chain(flattenArray)
      .filter(function(x){
        return _.has(x,'validations') ? x.validations.length > 0 ? true : false : false;
      })
      .value();
    return e;
  };

  this.getDateFieldsList = function(jsonModal, existsId){
    var flattenArray = this.getFlattenArray(jsonModal);
    return _.chain(flattenArray)
      .filter(function(x){
        return x.questionID !== existsId && x.type=='date';
      })
      .value();
  };

  this.getDependantFieldsList = function(jsonModal, existsId, isSection, key) {
    var flattenArray = this.getFlattenArray(jsonModal), removableQuestionIds=[], sectionElems,
      allowableFields = this.getDependantTypes(key);

    if(isSection === true) {
      var sectionId = existsId;
      var sectionObj = _.find(jsonModal.sections, function(x){
        return x.sectionID == sectionId;
      });
      if(sectionObj) {
        var _obj = {
          sections: []
        };
        _obj.sections.push(sectionObj);
        sectionElems = this.getFlattenArray(_obj);
        removableQuestionIds = _.pluck(sectionElems, 'questionID');
      }
    }
    return _.chain(flattenArray)
      .filter(function(x){
        return _.contains(allowableFields, x.type) && x.questionID !== existsId && !_.contains(removableQuestionIds, x.questionID);
      })
      .value();
  };

  this.getDynamicFieldsQuestion = function(jsonModel, existsId) {
    var flattenArray = this.getFlattenArray(jsonModel), allowableFields = ['dynamic_dropdown'];
    return _.chain(flattenArray)
      .filter(function(x){
        return _.contains(allowableFields, x.type) &&
          x.questionID !== existsId &&
          parseInt(x.selectedOption) > 0 ;
      })
      .filter(function(x){
        return _.isUndefined(existsId) ? true : !_.contains(_.pluck(x.valueDependencies, 'questionID'), existsId);
      })
      .value();
  };

  this.getMappedDynamicFieldsQuestions = function(jsonModel, questionId) {
    var flattenArray = this.getFlattenArray(jsonModel), allowableFields = ['dynamic_dropdown'];
    return _.chain(flattenArray)
      .filter(function(x){
        return _.contains(allowableFields, x.type)
          && x.questionID !== questionId
          && parseInt(x.selectedOption) > 0
          && _.contains(_.pluck(x.valueDependencies, 'questionID'), questionId);
      })
      .value();
  };

  this.formatViewElements = function(jsonModal) {
    function prepareListType(field) {
      var items = _.has(field,'items') ? field.items : [];
      var obj = {};
      _.forEach(items, function(x){
        obj[x.id] = _.has(x, 'children') ? x.text : x.description;
        if(_.has(x, 'children') && x.children.length > 0) {
          _.forEach(x.children, function(child){
            obj[child.value] = child.text;
          });
        }
      });
      return obj;
    }
    var flattenArray = this.getFlattenArray(jsonModal);
    var listtype = ['checkboxlist', 'dropdownlist', 'radiolist', 'predefined_toggle','nested_dropdown','number', 'toggle'];
    var newForm = {};
    _.chain(flattenArray)
      .forEach(function(x){
        var newObj = {};
        newObj.description = x.fieldLabel;
        newObj.flag = _.has(x,'flag') ? x.flag : false;
        if(_.contains(listtype, x.type)) {
          newObj.items = prepareListType(x)
        }
        if(_.has(x,'format')) {
          newObj.format = x.format;
        }
        if(x.type === 'dynamic_dropdown') {
          newObj.selectedOption = x.selectedOption == -1 ? '' : x.selectedOption;
          newObj.selectedOptionUUID = x.selectedOptionUUID;
        }
        if(_.has(x,'certificateKey')) {
            newObj.certificateKey = x.certificateKey;
        }
        newForm[x.questionID] = newObj;
      }).value();
    return newForm;
  };

  this.formatSubAssets = function(jsonModal) {
      var assetsFlattenArray = jsonModal.sections;
      var newForm = {};
      _.chain(assetsFlattenArray)
          .forEach(function(x) {
              var newObj = {};
            if(x.type == 'assets') {
                newObj.id= x.referenceId;
                newObj.title=x.title;
                newObj.dependencies = x.dependencies;
                newObj.showStandardFields = x.showStandardFields;
                newForm[x.assetsID] = newObj;
            }
          }).value();
      return newForm;
  };

  this.getDependantTypes = function(key){
    if( !_.isUndefined(key) && _.isArray(key) ) {
      return key;
    }
    return [
      'section',
      'dynamic_dropdown',
      'checkbox',
      'checkboxlist',
      'dropdownlist',
      'radiolist',
      'toggle',
      'predefined_toggle',
      'nested_dropdown'
    ];
  };

}])
.service('dynamicElementsService', ['dfDataManipulationService', 'DF_DATE_FORMATS',function(dfDataManipulationService, DF_DATE_FORMATS){
    var elements = {
      elementTypes: [
        {
          id:1,
          typeText: 'Section',
          type: 'section',
          sectionType: 'standard',
          title: 'Untitled',
          description: '',
          dependencies: [],
          fields: []
        },
        {
          id:20,
          typeText: 'Assets',
          type: 'assets',
          title: '',
          dependencies: [],
          referenceId: '',
          referenceData: {},
          showStandardFields: false,
        },
        {
          id:21,
          typeText: 'Column',
          type: 'column',
          title: '',
          defaultColumn: 2,
          supportedColumn: [1,2,3,4],
          columns: [{fields:[]}, {fields: []}]
        },
      ],
      inputTypes: [
        {
          id:2,
          typeText: 'Single line text box',
          type: 'text',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          validations: []
        },
        {
          id:3,
          typeText: 'Dynamic drop down',
          type: 'dynamic_dropdown',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          validations: [],
          dynamicOptions: [],
          selectedOption: -1,
          selectedOptionUUID: '',
          itemsCount: 0,
          valueDependencies:[]
        },
        {
          id:4,
          typeText: 'Text area',
          type: 'textarea',
          defaultValue: [],
          fieldLabel: '',
          dependencies: [],
          helpText: '',
          validations: []
        },
        {
          id:14,
          typeText: 'Number',
          type: 'number',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          items:[],
          validations: []
        },
        {
          id:5,
          typeText: 'Single checkbox',
          type: 'checkbox',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          validations: []
        },
        {
          id:6,
          typeText: 'Multiple checkbox',
          type: 'checkboxlist',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          items: [],
          emptyText: '',
          validations: []
        },
        {
          id:7,
          typeText: 'Drop down',
          type: 'dropdownlist',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          items: [],
          emptyText: '',
          validations: []
        },
        {
          id:8,
          typeText: 'Radio options',
          type: 'radiolist',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          items: [],
          validations: []
        },
        {
          id:9,
          typeText: 'Date',
          type: 'date',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          emptyText: '',
          validations: [],
          format: DF_DATE_FORMATS.defaultValue,
          formatItems: DF_DATE_FORMATS.items
        },
        {
          id:10,
          typeText: 'Time',
          type: 'time',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          emptyText: '',
          validations: [],
          format: 'h:mm a',
          formatItems: [
            'h:mm a',
            'HH:mm'
          ]
        },
        {
          id:11,
          typeText: 'Toggle',
          type: 'toggle',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          items: [],
          validations: []
        },
        {
          id:12,
          typeText: 'Yes/No toggle',
          type: 'predefined_toggle',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          items: [{
            id:'56733555-37515377-56557586-66075695',
            description:'Yes'
          },
          {
            id:'59315699-66577396-13959613-25669996',
            description:'No'
          }],
          validations: []
        },
        {
          id:13,
          typeText: 'Yes/No/NA toggle',
          type: 'predefined_toggle',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          items: [{
            id:'59523765-66037557-76670077-99665006',
            description:'Yes'
          },
          {
            id:'25177603-55657260-00171602-82555675',
            description:'No'
          },
          {
            id:'50177597-71063566-59050359-36252976',
            description:'NA'
          }],
          validations: []
        },
        {
          id:14,
          typeText: 'Pass/Fail/NA toggle',
          type: 'predefined_toggle',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          items: [{
            id:'59523765-66037557-76670077-99665006',
            description:'Pass'
          },
          {
            id:'25177603-55657260-00171602-82555675',
            description:'Fail'
          },
          {
            id:'50177597-71063566-59050359-36252976',
            description:'NA'
          }],
          validations: []
        },
        {
          id:15,
          typeText: 'Barcode',
          type: 'barcode',
          behavior: 'scanner',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          properties: {},
          validations: []
        },
        {
          id:16,
          typeText: 'Photo',
          type: 'image',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          properties: {},
          validations: []
        },
        {
          id:17,
          typeText: 'Decimal',
          type: 'decimal',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          format: '0,2',
          validations: []
        },
        // {
        //   id:18,
        //   typeText: 'Computational',
        //   type: 'formula',
        //   defaultValue: [],
        //   fieldLabel: '',
        //   helpText: '',
        //   visibility: false,
        //   mappedFieldType: ['number', 'decimal'],
        //   rules: []
        // },
        {
          id:19,
          typeText: 'Nested dropdown',
          type: 'nested_dropdown',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          items: [],
          emptyText: '',
          validations: []
        },
        {
          id: 20,
          typeText: 'User field',
          type: 'user_field',
          defaultValue: [],
          fieldLabel: '',
          helpText: '',
          dependencies: [],
          items: [],
          emptyText: '',
          validations: []
        }
      ]
    };

    var makeCRCTable = function(){
      var c;
      var crcTable = [];
      for(var n =0; n < 256; n++){
        c = n;
        for(var k =0; k < 8; k++){
          c = ((c&1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
        }
        crcTable[n] = c;
      }
      return crcTable;
    };

    var crc32 = function(str) {
      var crcTable = makeCRCTable();
      var crc = 0 ^ (-1);

      for (var i = 0; i < str.length; i++ ) {
        crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xFF];
      }

      return (crc ^ (-1)) >>> 0;
    };

    var generateId = function(length) {
      var newlength = !length ? 12 : length,
        timestamp = +new Date,
        _getRandomInt = function( min, max ) {
          return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
        },
        id='',
        ts = timestamp.toString(),
        parts = ts.split('').reverse();
      for(var i=0; i < newlength; ++i) {
        var index = _getRandomInt(0, parts.length - 1);
        id += parts[index];
      }
      return id;
    };

    this.getUniqueId = function() {
      //return generateId(8)+'-'+generateId(8)+'-'+generateId(8)+'-'+generateId(8);
      return crc32(generateId(8)+'-'+generateId(8)+'-'+generateId(8)+'-'+generateId(8)).toString(16)
        + '-'
        + crc32(generateId(8)+'-'+generateId(8)+'-'+generateId(8)+'-'+generateId(8)).toString(16);
    };

    /*this.getCertificateInputTypes = function() {
      return certificates.certificateInputTypes;
    };*/
    this.getInputTypes = function() {
      return elements.inputTypes;
    };

    this.getElementTypes = function (isStandard) {
      var e = elements.elementTypes;
      if(isStandard) {
        e = _.reject(e, function(x){
          return x.type === 'assets';
        });
      }
      return e;
    };

    this.isElementValueType = function(type) {
      if(['text', 'textarea', 'checkbox', 'number', 'decimal', 'barcode','date','time', 'user_field'].indexOf(type) > -1) {
        return 'single';
      }
      else if(['checkboxlist', 'dropdownlist', 'radiolist', 'toggle', 'predefined_toggle'].indexOf(type) > -1) {
        return 'multiple';
      }
      else if(['date', 'time'].indexOf(type) > -1) {
        return 'format'
      }
      else {
        return false;
      }
    };

    this.getFormulaTypes = function() {
      var addition  = {id: 'addition', text:'+'},
        subtraction = {id: 'subtraction', text: '-'},
        multiply    = {id: 'multiply', text:'*'},
        division    = {id: 'division', text:'/'};
      return [addition, subtraction,multiply,division];
    };

    this.getValidationTypes = function(){
      var empty = {
          id:'empty',
          title: 'Allow Empty',
          rule: '',
          require_input:false,
          msg: 'Please enter a valid text',
          helpText:''
        },
        notEmpty = {
          id:'notEmpty',
          title: 'Required',
          rule: '',
          require_input:false,
          msg: 'This field is required.',
          helpText:''
        },
        imageFormat = {
          id:'imageFormat',
          title: 'Allowable extension',
          rule: [],
          require_input: 'checkbox',
          data: [{'value':'jpg','text':'JPG'}, {'value':'png','text':'PNG'}, {'value':'gif','text':'GIF'}],
          msg: 'Invalid image format',
          helpText: ''
        },
        customPattern = {
          id:'customPattern',
          title: 'Custom Pattern',
          rule: null,
          require_input:true,
          msg: 'Invalid value',
          helpText: 'example: [a-zA-Z\\s]+$'
        },
        minLength = {
          id:'minLength',
          title: 'Minimum length',
          rule: '',
          require_input:true,
          msg: 'Minimum #rule# characters required.',
          helpText:''
        },
        maxLength     = {
          id:'maxLength',
          title: 'Maximum length',
          rule: '',
          require_input:true,
          msg: 'Maximum #rule# characters required.',
          helpText:''
        },
        allowTags     = {
          id:'allowTags',
          title: 'Allow Html',
          rule: '',
          require_input:false,
          msg: 'Invalid tags are found.'
      };
      return {
        text: [notEmpty, minLength, maxLength, customPattern],
        textarea: [notEmpty,minLength,maxLength],
        checkboxlist: [notEmpty],
        radiolist: [notEmpty],
        dropdownlist: [notEmpty],
        date: [notEmpty],
        time: [notEmpty],
        toggle: [notEmpty],
        predefined_toggle: [notEmpty],
        number: [notEmpty],
        image: [notEmpty,imageFormat],
        dynamic_dropdown: [notEmpty],
        decimal: [notEmpty],
        checkbox: [notEmpty],
        nested_dropdown: [notEmpty],
        user_field: [notEmpty]
      };
    };




    this.validationListMapping = function(jsonModal) {
      var self = this;
      var _listOfValidation = dfDataManipulationService.getValidationList(jsonModal);
      _.forEach(_listOfValidation, function(item) {
        item.validations = self.validationCallbackMapping(item.validations);
      });
      return _listOfValidation;
    };

    this.validationCallbackMapping = function(options) {
        var cb = {
          empty: function(value) {
            return _.isEmpty(value);
          },
          notEmpty: function (value) {
            var list = [false,'false', null, 'null', undefined, 'undefined','', -1];
            if(_.contains(list, value)) {
              return false;
            }
            if(_.isArray(value) || _.isObject(value)) {
              if(_.isObject(value) && (value instanceof Date || moment.isMoment(value)) ) {
                return true;
              }
              return !_.isEmpty(value);
            }
            return !_.isEmpty(value);
          },
          maxLength: function(value, num) {
            if(!value) return false;
            var _n = num ? parseInt(num):0;
            return value.length <= _n;
          },
          minLength: function(value, num) {
            if(!value) return false;
            var _n = num ? parseInt(num) : 0;
            return value.length >= _n;
          },
          customPattern: function(value, pattern) {
            var regex = new RegExp(pattern,'g');
            return regex.test(value);
          },
          imageFormat: function(value) {
            return true;
          }
        };
        _.forEach(options, function(opt){
          opt.callback = _.has(cb, opt.id) ? cb[opt.id] : _.noop;
        });
        return options;
    };

    this.isRequiredField = function(options) {
      var $C = _.pluck(options, 'id');
      return $C.indexOf('notEmpty') > -1 ? true : false;
    };

}])
.service('dfDragElementService',[function(){
  this.displayDrag = function(data, event, scope, type) {
    scope.dragItem = data;
    scope.dragType = data.type || 'element';
    var dragtype = data.type || 'element',
      $target = $(event.target),
    $elem = $('<div></div>');
    if(type === 'self') {
      var _w = $target.width(), _h = $target.height(),
        $clone = $target.clone();
      $clone.css({width: _w, height:_h});
    }
    $elem.attr({id: 'drag-host-wrapper'})
      .html(this.prepareElementContainer(data, type))
      .addClass('df-pane-drag df-'+dragtype+'-pane-drag');
    $('body').append($elem);
    return $elem[0];
  };

  this.removeDrag = function() {
    return $('body').find('#drag-host-wrapper').length && $('body').find('#drag-host-wrapper').remove();
  };

  this.prepareElementContainer = function(item, type) {
    var $innerHtml = $('<div></div>').addClass('drag-item-element');
    if(type === 'self') {
      $innerHtml.text(item.fieldLabel);
    }
    else {
      $innerHtml.text(item.typeText);
    }
    return $innerHtml;
  };
}])
.service('dfConfirmationToast',[
  'confirmationBoxHelper',
  '$timeout','$translate',
  '$rootScope', function(confirmationBoxHelper,$timeout,$translate, $rootScope){

  function confirmationToast (callback) {
    return confirmationBoxHelper.getConfirmation($translate('Are.you.sure.you.want.to.close.side.panel?'), $rootScope)
      .then(callback);
  }

  this.sidePanelConfirmationToast = function(callback) {
    $timeout(function(){
      var $element = $(document);
      $element.bind('keydown', function(e){
        if(e.which === 27) {
          confirmationToast(callback);
        }
      });
      $('body').find('.modal-backdrop').on('click', function(e){
        e.preventDefault();
        confirmationToast(callback);
      });
    },300);
  };

}])

.directive('dynmicFormElements', ['$compile', function($compile){
  return {
    restrict: 'A',
    link: function(scope, element, attr) {
      scope.elementQuery = {};
      scope.elementQueryBy = 'typeText';
      scope.screenType = 'standard';
      if(attr.screenType && attr.screenType != '') {
        scope.screenType = attr.screenType;
      }
    },
    templateUrl: '/template/dynamic-form/dynamic--elements-container.html'
  };
}])
.directive('dynamicFormResize', function ($window) {
  return function (scope, element) {
    var w = angular.element($window);
    var $el = $(element).css({'overflow': 'hidden', 'overflow-y': 'auto'});
    scope.getWindowDimensions = function () {
      return { 'h': w.height(), 'w': w.width() };
    };
    scope.$watch(scope.getWindowDimensions, function (newValue, oldValue) {
      scope.windowHeight = newValue.h;
      scope.windowWidth = newValue.w;
      $el.css('height', (newValue.h - ($el.offset().top + 88) )  + 'px');

    }, true);

    w.bind('resize', function () {
      scope.$apply();
    });
  }
})
.directive('numbersOnly', function(){
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, modelCtrl) {
      modelCtrl.$parsers.push(function (inputValue) {
        if (inputValue == undefined){
          return;
        }
        var transformedInput = inputValue.replace(/^0+|[^0-9]+/g, '');
        if (transformedInput!=inputValue) {
          modelCtrl.$setViewValue(transformedInput);
          modelCtrl.$render();
        }
        return transformedInput;
      });
    }
  };
})
.directive('dfDecimalOnly', function(){
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, el, attrs, modelCtrl) {
      var pattern = attrs.pattern;
      if(!pattern) {
        pattern = '^\\d{0,}(\\.\\d{0,})?$';
      }
      console.log('pattern', pattern);
      modelCtrl.$parsers.push(function (inputValue) {
        var old = modelCtrl.$modelValue;
        var reg = new RegExp(pattern);
        if (inputValue == undefined){
          return;
        }
        if(reg.test(inputValue) ) {
          return inputValue;
        }
        else {
          modelCtrl.$setViewValue(old);
          modelCtrl.$render();
          return old;
        }
      });
    }
  }
})
.directive('dfTimePicker', function() {
    var handleTimePicker = function handleGraphWidget(scope, element, attrs, ngModel) {
        scope.selected = moment();
        var meridians = ['AM', 'PM'];
        scope.showMeridian = false;
        var hStart = 0, hLimit = 24, hRange = 1;
        scope.isDisabled = (attrs['isDisabled'] == 'true') ? true : false;
        if(attrs.defaultFormat == 'h:mm a') {
            hStart =1, hLimit = 13;
            scope.showMeridian = true;
        }

        scope.hoursRange = _.map(_.range(hStart,hLimit,hRange), function(x){
            return x < 10 ? '0'+x : x;
        });
        scope.minRange = _.map(_.range(0,60,1), function(x){
            return x < 10 ? '0'+x : x;
        });

        scope.updateTemplate = function(keyboardChange) {
            var hours = scope.selected.hours(), minutes = scope.selected.minutes();
            hours = scope.showMeridian ? ( hours === 0 || hours === 12 ) ? 12 : hours % 12 : hours;
            scope.hours =  keyboardChange === 'h' ? hours : pad(hours);
            scope.minutes = keyboardChange === 'm' ? minutes : pad(minutes);
            scope.meridian = scope.selected.hours() < 12 ? meridians[0] : meridians[1];
        };

        function pad( value ) {
            return ( angular.isDefined(value) && value.toString().length < 2 ) ? '0' + value : value;
        }

        scope.toggleMeridian = function() {
            var add = (scope.meridian == 'PM') ? 12 : -12;
            scope.selected.add(add, 'hour');
            scope.updateModel();
            scope.updateTemplate();
        };

        ngModel.$render = function() {
            var date = ngModel.$modelValue ? moment(ngModel.$modelValue) : null;

            if ( isNaN(date) ) {
                ngModel.$setValidity('time', false);
                console.log('Commusoft time picker directive: "ng-model" value must be a Date object.');
            } else {
                if ( date ) {
                    scope.selected = date;
                }
                scope.updateTemplate();
            }
        };

        scope.updateHours = function() {
            var hoursSelected = parseInt(scope.hours);
            var actualHour = scope.showMeridian ? (scope.meridian == 'PM' && hoursSelected < 12) ? hoursSelected + 12 : hoursSelected : hoursSelected;
            scope.selected.hours(actualHour);
            scope.updateModel();
        }

        scope.updateMinutes = function() {
            scope.selected.minutes(scope.minutes);
            scope.updateModel();
        }

        scope.updateModel = function () {
            scope.updateTemplate();
            var dateTime = moment(scope.selected);
            ngModel.$setViewValue(dateTime);
            scope.changeFunc({name: attrs['ngModel'], value: scope.selected.format('YYYY-MM-DD HH:mm')});
            //console.log(scope.selected.format('YYYY-MM-DD HH:mm') + ' updated');
        }

        scope.$on('disableStartDateTime', function(event, message) {
            if(message.name == attrs['ngModel']) {
                scope.isDisabled = true;
            }
        })

        scope.$on('event:change-date-value', function(event, message) {
            var temp = moment(scope.selected);
            scope.selected = moment(message.date).clone().hours(temp.hours()).minutes(temp.minutes());
            console.log(scope.selected.format('DD/MM/YYYY HH:mm') + ' dit');
        });
    }

    return {
        restrict: 'E',
        templateUrl: 'template/dynamic-form/modal/timepicker.html',
        scope: {
            changeFunc: '&'
        },
        require: 'ngModel',
        replace: true,
        transclude: true,
        link: handleTimePicker
    }
})

.filter('dfFilterValidation', function(){
  return function(items, models, currentVal) {
    var filtered = [];
    if(models.length === 1) {
      return items;
    }
    _.forEach(items, function(x){
      if(_.contains(models, x.id) && currentVal !== x.id) {
        return;
      }
      filtered.push(x);
    });
    return filtered;
  };
})

.filter('filterByQuestionId', function() {
  return function(items, model, currentVal){
    var filtered = [];
    if(model.length === 1) {
      return items;
    }
    var questionIds = _.chain(model)
      .filter(function(x){
        return !_.isEmpty(x.questionID);
      })
      .value();

    var modelQueIds = _.pluck(questionIds, 'questionID');
    _.forEach(items, function(x){
      if(_.contains(modelQueIds, x.questionID) && currentVal !== x.questionID) {
        return;
      }
      filtered.push(x);
    });
    return filtered;
  };
})
.filter('filterByAssignedValidateId', function(){
  return function(items, assigedItems, assigedItem) {
    var filtered = [];
    //console.log(assigedItem);
    var removedCurrent = _.reject(assigedItems, function(x){
      return x.id === assigedItem
    });
    //console.log('removedCurrent', removedCurrent);
    if(_.isEmpty(removedCurrent)) {
      return items;
    }
    //return removedCurrent;
    var ids = _.pluck(removedCurrent, 'id');
    //console.log('item.count', items.length);
    _.each(items, function(x){
      if(_.contains(ids, x.id)) {
        return;
      }
      filtered.push(x);
    });
    return filtered;
  };
})
.filter('chunk', function () {
  function cacheIt(func) {
    var cache = {};
    return function(arg) {
      return cache[arg] ? cache[arg] : cache[arg] = func(arg);
    };
  }
  function chunk(items, chunk_size) {
    var chunks = [];
    if (angular.isArray(items)) {
      if (isNaN(chunk_size))
        chunk_size = 2;
      for (var i = 0; i < items.length; i += chunk_size) {
        chunks.push(items.slice(i, i + chunk_size));
      }
    } else {
      console.log("items is not an array: " + angular.toJson(items));
    }
    return chunks;
  }
  return cacheIt(chunk);
})
.filter('typeof', function() {
  return function(obj) {
    return typeof obj
  };
})
.filter('range', function() {
  return function(input, total) {
    total = parseInt(total);
    for (var i=0; i<total; i++) {
      input.push(i);
    }
    return input;
  };
})
.directive('focusMe', function($timeout) {
  return {
    scope: { trigger: '@focusMe' },
    link: function(scope, element) {
      scope.$watch('trigger', function(value) {
        if(value === "true") {
          $timeout(function() {
            element[0].focus();
          },300);
        }
      });
    }
  };
})
.run(function($rootScope, $templateCache, $http, dynamicActionService){
  // $rootScope.$on('$viewContentLoaded', function () {
  //   var requiredTemplates = _.pluck(dynamicActionService.getModalActions(),'templateUrl');
  //   var templateToCache = [];
  //   var _t = 'template/datepicker/';
  //   requiredTemplates.push(_t+'datepicker.html',_t+'popup.html');
  //
  //   for(var index = 0; index < requiredTemplates.length; index++) {
  //     var templateId = requiredTemplates[index];
  //     templateToCache.push({templateId: templateId, content: $templateCache.get(templateId)});
  //   }
  //   if(templateToCache.length) {
  //     for(var i = 0; i < templateToCache.length; i++) {
  //       var templateId = templateToCache[i].templateId;
  //       if(!templateToCache[i].content) {
  //         $http.get(templateId, {cache: $templateCache});
  //       }
  //     }
  //   }
  // });
});
