commusoftCommon.directive('csphonewidget', ['prefix', '$http', 'countries', '$timeout', 'CS_COUNTRY', function(prefix, $http, countries, $timeout, CS_COUNTRY) {

    var handleInstance = function handleInstance(scope, element, attrs) {

        // default scope variables
        scope.selected_country_id = CS_COUNTRY.id; //Assign Client Country ID
        scope.selected_country_code = CS_COUNTRY.code;

        scope.numbers_collection = [];
        scope.countries = countries.getCountries();
        scope.number_types = countries.getNumberTypes();
        scope.number_type = scope.number_types[0].label;
        scope.number_type_id = scope.number_types[0].id;
        scope.show_edit = [];
        var defaultCountryCode , defaultCountryId = '';

        if (scope.selected_country_code == '' || scope.selected_country_code == undefined || scope.selected_country_code == "null") {
            $http.get(prefix + '/get_client_country_code').success(function (data) {
                scope.selected_country_id = data.id;
                scope.selected_country_code = data.code;
                angular.forEach(scope.countries, function (val, key) {
                    if (key == scope.selected_country_code) {
                        var countryArr = countries.getCountryByCode(key);
                        scope.selected_country_id = countryArr[0].id;
                        scope.selected_country_code = key;
                        defaultCountryCode = key;
                        defaultCountryId = countryArr[0].id;
                    }
                });
            });
        }

        if (scope.selected_country_code != '') {
            angular.forEach(scope.countries, function (val, key) {
                if (key == scope.selected_country_code) {
                    var countryArr = countries.getCountryByCode(key);
                    scope.selected_country_id = countryArr[0].id;
                    scope.selected_country_code = key;
                    defaultCountryCode = key;
                    defaultCountryId = countryArr[0].id;
                }
            });
        }

        // used to hide the add btn and existing numbers view
        scope.multiple_numbers_supported = attrs.multipleNumbers

        scope.phone_input = element.find('.phone-number-input');
        scope.input_collection = element.find('.phone-number-wrapper');
        scope.area_code = element.find('.selected-area-code');
        scope.error_msg = element.find('.control-error-message');

        if(scope.$parent.numbers_collection){
            scope.numbers_collection = scope.$parent.numbers_collection;
            angular.forEach(scope.numbers_collection, function (val, key) {
                var code = scope.numbers_collection[key].code;
                scope.numbers_collection[key].image_id = code;
            });
        }



        /*===================================
         Behaviours / Interactions
         ===================================*/

        scope.handleNumberType = function handleNumberType() {
            // the left positioning of the dropdown needs to be claculated based
            // on the width of the text in the control label
            var dropdown_left_pos = element.find('.number-type-toggle').position().left;
            var number_type_dropdown = element.find('.phone-type-wrapper').find('.custom-dropdown');

            number_type_dropdown.css('left', dropdown_left_pos);
        }

        scope.selectNumberType = function selectNumberType(type, id) {
            scope.number_type = type;
            scope.number_type_id = id;
            scope.show_number_type = false;
        }

        // Handle when a country is selected
        scope.selectCountry = function selectCountry(code) {

            var country_obj = countries.getCountryByCode(code);
            scope.selected_country_id = country_obj[0].id;
            scope.selected_country_code = code;
            scope.show_countries = false;

            // The width of the input changes depends on the number of characters the country code is
            (code.length > 2)? scope.$emit('country_code_width:changed', 3) : scope.$emit('country_code_width:changed', 2);

            $timeout(function() {
                scope.phone_input.focus();
            }, 1);
        }

        // When the input is focusssed add focussed class to parent elm
        var handleInputEvents = (function() {
            /*scope.phone_input.on('focus', function() {
                scope.input_collection.addClass('focussed');
            });

            scope.phone_input.on('blur', function() {
                scope.input_collection.removeClass('focussed');
                scope.validateNumberInput();
            });*/
        })();

        // When the area code is clicked focus the span
        var handleAreaCodeClick = (function() {
            scope.area_code.on('click', function() {
                scope.phone_input.focus();
            })
        })();

        var handleErrorMsgWidth = (function() {
            scope.$on('country_code_width:changed', function(e, char_length) {
                if (char_length === 2) {
                    scope.phone_input.css('width', '150px');
                }else if(char_length === 3) {
                    scope.phone_input.css('width', '142px');
                }
            });
        })();

        /*===================================
         Functional Methods
         ===================================*/

        // Add number

        scope.addNumber = function addNumber() {
            var is_valid = scope.validateNumberInput();

            if (is_valid === true) {
                scope.numbers_collection.push({
                    type: this.number_type,
                    type_id: this.number_type_id,
                    phone_number: this.phone_number,
                    image_id: this.selected_country_code,
                    code: this.selected_country_code
                });
                scope.$emit('numbersCollection', scope.numbers_collection);
                scope.resetModels();
                scope.phone_input.css('width', '150px');

                scope.selected_country_id = CS_COUNTRY.id; //Assign Client Country ID
            }
        }
        //Reset Models
        scope.resetModels = function resetModels() {
            scope.phone_number = '';
            scope.number_type = scope.number_types[0].label;
            scope.number_type_id = scope.number_types[0].id;
            scope.selected_country_id = defaultCountryId;
            scope.selected_country_code = defaultCountryCode;
        }

        // Validate the number
        scope.validateNumberInput = function validateNumberInput() {
            var test =  this.phone_number;
            scope.$emit('GetErrorMessage', test);

            if (test == undefined || test == "") {
                this.invalid_number = true;
                return false;
            } else {
                this.invalid_number = false;
                return true;
            }
        }
    }

    return {
        restrict: 'A',
        scope: {},
        templateUrl: 'template/csphonewidget.html',
        controller: CSPhoneWidgetCtrl,
        link: handleInstance
    }
}]);