csmodule.service('diarySidePanel', ['$rootScope', '$compile', '$timeout', function ($rootScope, $compile, $timeout) {
    /*==========================================================================================
     Initial Variables
     ==========================================================================================*/
    this.init = function init(panelIdentity, panelTitle, panelData) {
        this.panelIdentity = panelIdentity;
        this.panelTitle = panelTitle;
        this.panel_data = panelData instanceof Object ? angular.copy(panelData) : {};
        this.showPanel();
    };


    /*==========================================================================================
     Show side panel
     ==========================================================================================*/
    this.showPanel = function () {
        var panelIdentity = this.panelIdentity, panelTitle = this.panelTitle;
        var panel_data = JSON.stringify(this.panel_data);
        var dataToAppend = panel_data.replace(/'/g, "&#39;");
        var side_panel_tpl = $compile("<span id='" + panelIdentity + "' sidepanel template='" + panelIdentity + "' title='" + panelTitle + "' remove_on_close='true' href='' data='" + dataToAppend + "'></span>")($rootScope);

        angular.element('body').append(side_panel_tpl);

        $timeout(function () {
            angular.element('#' + panelIdentity).scope().$$childTail.initialise();
        }, 100);
    }
}]);