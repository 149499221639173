csmodule.service('jobData', ['$compile', '$timeout', '$http', '$q', '$rootScope', function($compile, $timeout, $http, $q, $rootScope) {

	jobs = [
	  {
	    id: '450',
      final_invoice_raised: true,
      final_invoice_number: 234324,
      complete: true,
      has_purchase_orders: true,
      has_certificates: true,
      has_invoices: true,
      has_used_parts: true,
      has_additional_works: true,
      has_recalls: true,
      has_diary_events: true,
	    date: 'Mon 24th Dec',
	    type: 'Project',
      status: 'In Progress',
	    description: 'Gas Saftey Certificate',
	    next_visit: 'Wed 17th Dec 2013 (9:00 - 10:00)',
	    job_contact: 'Joseph Luck',
      created_by: 'Jason Mojaria',
      created_on: '25th November 2013',
      last_modified_on: '26th November 2013',
	    notes_for_engineer: 'Make sure that you ask the customer if they would like a quote to replace the boiler',
      no_pending_additional_works: 1,
      audits : [
        {
          type : 'check',
          message : "This project's final invoice has been raised",
          date : 'Tue 10th January 2015',
          time : '9:21am',
          additional_message : '- The invoice number is 234324',
          category : 'final_invoice'
        },
        {
          type : 'warning',
          message : "We've contacted the agent because the tenant has not responded",
          date : 'Tue 12th January 2015',
          time : '13:21pm',
          help_text : 'You can view the SMS in notes and communications'
        },
        {
          type : 'warning',
          message : 'The tenant has not confirmed access',
          additional_message : '- last communication sent on 13th January 2015',
          date : 'Tue 12th January 2015',
          time : '13:21pm',
          help_text : 'We will try to contact the tenant again on the 15th of January 2015'
        },
        {
          type : 'check',
          message : 'This project is complete',
          additional_message : '- after care sales email sent on 13th January 2015',
          date : 'Tue 12th January 2015',
          time : '13:21pm',
          comment_positive : true,
          comment_by : 'Joseph Luck',
          comment : 'The service was fantastic, on time, reliable and the engineer was very friendly. I would definitely recommend ABC plumbing again.',
          category : 'after_care_sales'
        },
        {
          type : 'check',
          message : 'This project is complete',
          additional_message : '- after care sales email sent on 13th January 2015',
          date : 'Tue 12th January 2015',
          time : '13:21pm',
          help_text : 'The customer has not yet responded to the after care sales email'
        },
        {
          type : 'info',
          message : 'Joseph Luck put this project on hold',
          additional_message : '- Customer needs to clean property',
          date : 'Tue 22th November 2014',
          time : '10:11am',
          help_text : 'You can remove the on-hold status by going to quick links on the right',
          category : 'job_on_hold'
        },
        {
          type : 'info',
          message : 'Joseph Luck put this project on the reserve list',
          additional_message : '- (it is on 4 engineers reserve lists)',
          date : 'Tue 22th November 2014',
          time : '10:11am',
          help_text : 'You can view and edit the list of engineers by using the link on the right',
          category : 'reserve_list'
        },
        {
          type : 'warning',
          message : 'Access to the property has not been confirmed',
          help_text : 'You can confirm access to the property by using the link on the right',
          category : 'job_access'
        },
        {
          type : 'info',
          message : 'This job is from an estimate',
          additional_message : '- (the estimate number is 1424533)',
          help_text : 'You can view a PDF of the estimate by using the link on the right',
          category : 'job_view_estimate'
        }
      ],
      additional_work: {
        additional_work: false
      },
      on_hold: true,
      estimate_number: 2314,
      customer_purchase_order_number: 234324,
      access: 'Collect keys from tenant',
      invoice_category: 'GSC Services',
      service: false,
      recall: {
        is_recall: false,
        original_job_id: 250,
        original_job_type: 'Project'
      },
      recalls: [
        {
          job_id: 141
        },
        {
          job_id: 141
        },
        {
          job_id: 141
        }
      ],
      reserve_list: {
        on_reserve_list: true,
        by: 'Joseph Luck',
        date: '13th November 2013',
        reserves: [
          {
            name: 'Joseph Luck'
          },
          {
            name: 'Callum Hart'
          },
          {
            name: 'Jason Morjaria'
          },
          {
            name: 'William Godwin'
          }
        ]
      },
      invoices: [
        {
          id : '234324',
          type: 'Final',
          supplier_name : 'Jason Morjaria',
          supplier_branch : 'Hackney',
          supplier_addrs_1 : 'London',
          supplier_addrs_2 : 'UK',
          supplier_postcode : 'E12 6AP',
          supplier_phone : '01234567890',
          supplier_email : 'demo@commusoft',
          date : 'Mon 24th Dec 2013',
          invoice_number : '234324',
          description : 'Multiple Items',
          nominal_code : '100 - Parts',
          job_number : '1534',
          job_description : 'Boiler Install',
          VAT_rate : 5,
          sub_total : 194,
          taxes : 20,
          total : 224,
          remainder : 30,
          printed : 'Printed By Joseph Luck on 23rd November 2014',
          with_line_items: true,
          sent_to_accounting_package: true,
          send_to_accounting_package: true,
          audits : [
            {
              type : 'edit',
              message : 'Edited',
              name : 'Joseph Luck',
              date : 'Wed 23th November 2014',
              time : '14:32pm',
              required : true,
              completed : {
                completed : false,
                message : 'Marked off in SAGE',
                name : 'Joseph Luck',
                date : 'Tues 24th November 2014',
                time : '14:32pm'
              }
            },
            {
              type : 'check',
              message : 'Successfully saved in SAGE',
              date : 'Tue 22th November 2014',
              time : '10:11am'
            },
            {
              type : 'warning',
              message : 'Failed to save in SAGE because the credentials in company settings are incorrect',
              date : 'Sun 20th september 2014',
              time : '19:14pm',
              help_text : 'You can resend this record by going to quick links'
            }
          ],
          items : [
            {
              type : 'Part',
              description : 'Lorem Ipsum',
              quantity : '3',
              nominal: '2314',
              cost : 32,
              price : 45,
              VAT_rate : '20%',
              total : 100
            },
            {
              type : 'Part',
              description : 'Lorem Ipsum',
              quantity : '3',
              nominal: '2314',
              cost : 32,
              price : 45,
              VAT_rate : '20%',
              total : 100
            },
            {
              type : 'Part',
              description : 'Lorem Ipsum',
              quantity : '3',
              nominal: '2314',
              cost : 32,
              price : 45,
              VAT_rate : '20%',
              total : 100
            }
          ],
          payments : [
            {
              id: 879325,
              date: 'Mon 25th Dec 2013',
              method: 'Cash',
              description: 'Payment for invoices',
              reference: '879325',
              nominal : '322',
              amount : 20,
              sent_to_accounting_package : true,
              send_to_accounting_package: true,
              audits : [
                {
                  type : 'check',
                  message : 'Sent to SAGE',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm',
                  edits : [
                    {
                      type : 'edit',
                      message : 'Edited',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : true,
                      completed : {
                        completed : true,
                        message : 'Marked off in SAGE',
                        name : 'Joseph Luck',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm'
                      }
                    },
                    {
                      type : 'resend',
                      message : 'Resent to SAGE',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : false
                    }
                  ]
                }
              ],
              invoices : [
                {
                  id : 6456543,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                },
                {
                  id : 234324,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                }
              ]
            },
            {
              id: 6456543,
              date: 'Mon 28th Dec 2013',
              method: 'Credit',
              description: 'Credit allocation for invoices',
              reference: '6456543',
              nominal : '322',
              amount : 20,
              sent_to_accounting_package : true,
              send_to_accounting_package: true,
              audits : [
                {
                  type : 'check',
                  message : 'Sent to SAGE',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm',
                  edits : [
                    {
                      type : 'edit',
                      message : 'Edited',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : true,
                      completed : {
                        completed : true,
                        message : 'Marked off in SAGE',
                        name : 'Joseph Luck',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm'
                      }
                    },
                    {
                      type : 'resend',
                      message : 'Resent to SAGE',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : false
                    }
                  ]
                }
              ],
              invoices : [
                {
                  id : 6456543,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                },
                {
                  id : 234324,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                }
              ]
            },
            {
              id: 2589074,
              date: 'Mon 19th Jan 2014',
              method: 'Cheque',
              description: 'Cheque payment',
              reference: '2589074',
              nominal : '322',
              amount : 20,
              sent_to_accounting_package : true,
              send_to_accounting_package: true,
              audits : [
                {
                  type : 'check',
                  message : 'Sent to SAGE',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm',
                  edits : [
                    {
                      type : 'edit',
                      message : 'Edited',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : true,
                      completed : {
                        completed : true,
                        message : 'Marked off in SAGE',
                        name : 'Joseph Luck',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm'
                      }
                    },
                    {
                      type : 'resend',
                      message : 'Resent to SAGE',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : false
                    }
                  ]
                }
              ],
              invoices : [
                {
                  id : 6456543,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                },
                {
                  id : 234324,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                }
              ]
            }
          ]
        },
        {
          id : '6456543',
          type: 'Partial',
          supplier_name : 'Jason Morjaria',
          supplier_branch : 'Hackney',
          supplier_addrs_1 : 'London',
          supplier_addrs_2 : 'UK',
          supplier_postcode : 'E12 6AP',
          supplier_phone : '01234567890',
          supplier_email : 'demo@commusoft',
          date : 'Sat 30th Dec 2013',
          invoice_number : '6456543',
          description : 'Baxi Boiler',
          nominal_code : '100 - Parts',
          job_number : '1534',
          job_description : 'Boiler Install',
          VAT_rate : 20,
          sub_total : 1904,
          taxes : 220,
          total : 3024,
          remainder : 100,
          printed : 'Printed By Joseph Luck on 23rd November 2014',
          with_line_items: false,
          sent_to_accounting_package: false,
          send_to_accounting_package: false,
          payments : [
          ]
        },
        {
          id : '123456',
          type: 'Partial',
          supplier_name : 'Jason Morjaria',
          supplier_branch : 'Hackney',
          supplier_addrs_1 : 'London',
          supplier_addrs_2 : 'UK',
          supplier_postcode : 'E12 6AP',
          supplier_phone : '01234567890',
          supplier_email : 'demo@commusoft',
          date : 'Mon 24th Dec 2013',
          invoice_number : '234324',
          description : 'Multiple Items',
          job_number : '1534',
          job_description : 'Boiler Install',
          nominal_code : '100 - Parts',
          VAT_rate : 5,
          sub_total : 194,
          taxes : 20,
          total : 224,
          remainder : 30,
          printed : 'Printed By Joseph Luck on 23rd November 2014',
          with_line_items: true,
          sent_to_accounting_package: false,
          send_to_accounting_package: false,
          audits : [
            {
              type : 'sending',
              message : 'On its way to SAGE',
              date : 'Tues 24th November 2014',
              time : '14:32pm'
            }
          ],
          items : [
            {
              type : 'Part',
              description : 'Lorem Ipsum',
              quantity : '3',
              nominal: '2314',
              cost : 32,
              price : 45,
              VAT_rate : '20%',
              total : 100
            },
            {
              type : 'Part',
              description : 'Lorem Ipsum',
              quantity : '3',
              nominal: '2314',
              cost : 32,
              price : 45,
              VAT_rate : '20%',
              total : 100
            },
            {
              type : 'Part',
              description : 'Lorem Ipsum',
              quantity : '3',
              nominal: '2314',
              cost : 32,
              price : 45,
              VAT_rate : '20%',
              total : 100
            }
          ],
          payments : [
            {
              id: 1,
              date: 'Mon 25th Dec 2013',
              method: 'Cash',
              description: 'Lorem Ipsum',
              reference: '324324',
              nominal : '322',
              amount : 20,
              sent_to_accounting_package : true,
              send_to_accounting_package: true,
              audits : [
                {
                  type : 'check',
                  message : 'Sent to SAGE',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm'
                }
              ],
              invoices : [
                {
                  id : 6456543,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                },
                {
                  id : 234324,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                }
              ]
            },
            {
              id: 6456543,
              date: 'Mon 28th Dec 2013',
              method: 'Credit',
              description: 'Lorem Ipsum',
              reference: '6456543',
              nominal : '322',
              amount : 20,
              sent_to_accounting_package : true,
              send_to_accounting_package: true,
              audits : [
                {
                  type : 'check',
                  message : 'Sent to SAGE',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm'
                },
                {
                  type : 'resend',
                  message : 'Resent to SAGE',
                  name : 'Joseph Luck',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm',
                  required : false
                }
              ],
              invoices : [
                {
                  id : 6456543,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                },
                {
                  id : 234324,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                }
              ]
            },
            {
              id: 3,
              date: 'Mon 19th Jan 2014',
              method: 'Cheque',
              description: 'Lorem Ipsum',
              reference: '324987',
              nominal : '322',
              amount : 20,
              sent_to_accounting_package : true,
              send_to_accounting_package: true,
              audits : [
                {
                  type : 'check',
                  message : 'Sent to SAGE',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm',
                  edits : [
                    {
                      type : 'edit',
                      message : 'Edited',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : true,
                      completed : {
                        completed : true,
                        message : 'Marked off in SAGE',
                        name : 'Joseph Luck',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm'
                      }
                    },
                    {
                      type : 'resend',
                      message : 'Resent to SAGE',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : false
                    }
                  ]
                }
              ],
              invoices : [
                {
                  id : 6456543,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                },
                {
                  id : 234324,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                }
              ]
            }
          ]
        }
      ],
      milestones: [
        {
          id: 1,
          new_milestone : false,
          name: 'Removal and disposal',
          available_todos: 15,
          completed_todos: 4,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('Pending'),
            jobDiaryEvents.getDiaryEvent('Accept')*/
          ],
          unassigned_todos: [
            {
              id: 13,
              status: "Pending",
              type: "install",
              description: 'Install the radiator valves',
              item: 'Radiator Valves',
              estimated_hours: '1',
              quantity: '50',
              quantity_installed: '34',
              part_status: "Available",
              complete: {
                time: '10:00',
                notes: 'There wasnt enough time to install all of the valves'
              }
            },
            {
              id: 14,
              status: "Pending",
              type: "maintenance",
              description: 'This is urgent',
              appliance: 'Vaillant EcoTech',
              estimated_hours: '1.4',
              complete: {
                time: '10:00',
                notes: 'Tightened up fixtures and fittings'
              }
            },
            {
              id: 15,
              status: "Pending",
              type: "breakdown",
              description: 'This is urgent',
              appliance: 'Vaillant EcoTech',
              estimated_hours: '1.4',
              complete: {
                time: '10:00',
                notes: 'Tightened up fixtures and fittings'
              }
            }
          ],
          costs: {
            labour: [
              {
                visit: '13th July 2014',
                engineer: 'Joseph Luck',
                onsite: 2,
                travel: 0.5,
                labourrate: 60.00,
                travelrate: 60.00,
                total: 180.00
              }
            ],
            travel: [
              {
                date: '13th July 2014',
                description: 'Travel to property',
                travel: 0.5,
                rate: 0.50,
                vehicle: 'AU10 SBZ',
                engineer: 'Joseph Luck',
                total: 2.70
              }
            ],
            purchase_orders: [
              {
                supplier_id: '1',
                id: '234324',
                date: '13th July 2014',
                supplier: 'Plumbase',
                description: 'Piping',
                total_exc_vat: 150.00,
                vat: 30.00,
                total: 180.00
              }
            ],
            supplier_invoices: [
              {
                supplier_id: '1',
                id: '234324',
                date: '13th July 2014',
                supplier: 'Plumbase',
                description: 'Vailant Boiler',
                total_exc_vat: 2000.00,
                vat: 400.00,
                remainder_to_pay: 300.00,
                total: 2400.00
              }
            ],
            stock_items: [
              {
                date: '13th July 2014',
                description: 'Flue',
                stocklocation: 'AU10 SBZ',
                quantity: '1',
                total_exc_vat: 800.00,
                vat: 160.00,
                total: 960.00
              }
            ],
            estimated_costs: [
              {
                description: 'Fittings',
                supplier: 'Plumbase',
                cost: 20,
                quantity: '10',
                total_exc_vat: 300.00,
                vat: 60.00,
                total: 360.00
              }
            ],
            total_labour: 120.00,
            total_travel: 2.70,
            total_purchase_orders: 180.00,
            total_supplier_invoices: 2400.00,
            total_stock_items: 960.00,
            total_estimated_costs: 360.00,
            total: 4025.00,
            total_tax: 805.00,
            grand_total: 4830.00
          }
        },
        {
          id: 2,
          new_milestone : false,
          name: 'Preperation',
          available_todos: 4,
          completed_todos: 0,
          diary_events: [],
          unassigned_todos: [
            {
              id: 12,
              status: "Complete",
              type: "general",
              description: 'Tidy up property',
              complete: {
                time: '10:00',
                notes: 'I tidied up the property, there was a lot of junk near the pipework in the kitchen'
              }
            }
          ],
          costs: {
            labour: [
              {
                visit: '13th July 2014',
                engineer: 'Joseph Luck',
                onsite: 2,
                travel: 0.5,
                labourrate: 60.00,
                travelrate: 60.00,
                total: 180.00
              }
            ],
            travel: [
            ],
            purchase_orders: [
              {
                supplier_id: '1',
                id: '234324',
                date: '13th July 2014',
                supplier: 'Plumbase',
                description: 'Piping',
                total_exc_vat: 150.00,
                vat: 30.00,
                total: 180.00
              }
            ],
            supplier_invoices: [],
            stock_items: [
              {
                date: '13th July 2014',
                description: 'Flue',
                stocklocation: 'AU10 SBZ',
                quantity: '1',
                total_exc_vat: 800.00,
                vat: 160.00,
                total: 960.00
              }
            ],
            estimated_costs: [
              {
                description: 'Fittings',
                supplier: 'Plumbase',
                cost: 20,
                quantity: '10',
                total_exc_vat: 300.00,
                vat: 60.00,
                total: 360.00
              }
            ],
            total_labour: 120.00,
            total_travel: 2.70,
            total_purchase_orders: 180.00,
            total_supplier_invoices: 2400.00,
            total_stock_items: 960.00,
            total_estimated_costs: 360.00,
            total: 4025.00,
            total_tax: 805.00,
            grand_total: 4830.00
          }
        },
        {
          id: 3,
          new_milestone : false,
          name: 'Installation',
          available_todos: 26,
          completed_todos: 2,
          diary_events: [],
          unassigned_todos: [
            {
              id: 10,
              status: "Complete",
              type: "certificate",
              description: 'GSC for boiler',
              certificate: 'CP4 - Gas Safety Inspection',
              estimated_hours: '2.5',
              complete: {
                time: '10:00',
                certificate: '',
                notes: 'Boiler inspection passed'
              }
            },
            {
              id: 11,
              status: "Complete",
              type: "decommission",
              description: 'Old boiler is defective',
              appliance: 'Vaillant EcoTech',
              estimated_hours: '3.2',
              complete: {
                time: '10:00',
                notes: 'The boiler was difficult to remove'
              }
            }
          ],
          costs: {
            labour: [],
            travel: [],
            purchase_orders: [],
            supplier_invoices: [],
            stock_items: [],
            estimated_costs: [],
            total_labour: 0,
            total_travel: 0,
            total_purchase_orders: 0,
            total_supplier_invoices: 0,
            total_stock_items: 0,
            total_estimated_costs: 0,
            total: 0,
            total_tax: 0,
            grand_total: 0
          }
        }
      ],
      additional_works: [
        {
          title: 'Fix the leaking pipe',
          id: '750',
          status: 'Accepted',
          created_by: 'Joseph Luck',
          date: '13th Nov 2014',
          time: '14:21pm',
          todos: 4,
          parts: 2,
          total_exc_vat: 200,
          vat: 40,
          total: 240,
          cost: 100,
          profit: 140
        },
        {
          title: 'Fix the leaking pipe',
          id: '750',
          status: 'Rejected',
          created_by: 'Joseph Luck',
          date: '13th Nov 2014',
          time: '14:21pm',
          todos: 4,
          parts: 2,
          total_exc_vat: 200,
          vat: 40,
          total: 240,
          cost: 100,
          profit: 140
        },
        {
          title: 'Fix the leaking pipe',
          id: '750',
          status: 'Waiting Approval',
          created_by: 'Joseph Luck',
          date: '13th Nov 2014',
          time: '14:21',
          todos: 4,
          parts: 2,
          total_exc_vat: 200,
          vat: 40,
          total: 240,
          cost: 100,
          profit: 140
        },
        {
          title: 'Fix the tap fittings',
          id: '754',
          status: 'Draft',
          created_by: 'Callum Hart',
          date: '11th Jan 2014',
          time: '10:21am'
        }
      ],
      dashboard: [
        {
          parts_requested : 5,
          parts_on_order : 10,
          last_diary_event: {
            engineer_profile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=45',
            engineer: 'Callum Hart',
            status: 'Completed',
            date: '13th November 2014',
            time: '12:00 - 18:00',
            notes: 'All todos completed, additional work required to repair leaking pipe'
          },
          next_diary_event: {
            engineer_profile_image: 'https://s.gravatar.com/avatar/453a7a095109ec93f419a54ad19956f6?s=45',
            engineer: 'Joseph Luck',
            status: 'Not Complete',
            date: '17th November 2014',
            time: '14:00 - 21:00',
            description: 'Please install the boiler and make sure its completely secure'
          }
        }
      ],
      timeline_sections: [
        {
          id: 3,
          time: '13th January 2015'
        },
        {
          id: 2,
          time: '10th January 2015'
        },
        {
          id: 1,
          time: '9th January 2015'
        }
      ],
      quoted_amount: 250,
      total_invoiced: 950,
      grand_total: 3200
	  },
    // 2100 - just created (from estimate)
    {
      id: '1',
      final_invoice_raised: false,
      final_invoice_number: null,
      complete: false,
      has_purchase_orders: false,
      has_certificates: false,
      has_invoices: false,
      has_used_parts: false,
      has_additional_works: false,
      has_recalls: false,
      has_diary_events: false,
      date: 'Mon 12th Dec',
      type: 'Job',
      status: 'New',
      description: 'This job is from an estimate - perform a gas safety certificate',
      job_contact: 'Joseph Luck',
      notes_for_engineer: 'Make sure that you ask the customer if they would like a quote to replace the boiler',
      no_pending_additional_works: 0,
      audits : [
        {
          type : 'info',
          message : 'This job is from an estimate',
          additional_message : '- (the estimate number is 1424533)',
          help_text : 'You can view a PDF of the estimate by using the link on the right',
          category : 'job_view_estimate'
        }
      ],
      additional_work: {
        additional_work: false
      },
      on_hold: false,
      customer_purchase_order_number: 234324,
      access: 'We have keys',
      invoice_category: 'GSC Services',
      service: false,
      recall: {
        is_recall: false
      },
      recalls: [],
      reserve_list: {
        on_reserve_list: false
      },
      invoices: [],
      created_by: 'Jason Mojaria',
      created_on: '25th November 2013',
      last_modified_on: '26th November 2013',
      milestones: [
        {
          id: 1,
          new_milestone : true,
          name: 'Available Todos',
          available_todos: 0,
          completed_todos: 0,
          diary_events: [],
          unassigned_todos: [],
          costs: {
            labour: [],
            travel: [],
            purchase_orders: [],
            supplier_invoices: [],
            stock_items: [],
            estimated_costs: [],
            total_labour: 0,
            total_travel: 0,
            total_purchase_orders: 0,
            total_supplier_invoices: 0,
            total_stock_items: 0,
            total_estimated_costs: 0,
            total: 0,
            total_tax: 0,
            grand_total: 0
          }
        }
      ],
      additional_works: [],
      timeline_sections: [],
      quoted_amount: 250
    },
    // 2200 - In progress (with one complete diary event and one in progress)
    {
      id: '2200',
      final_invoice_raised: false,
      final_invoice_number: null,
      complete: false,
      has_purchase_orders: false,
      has_certificates: false,
      has_invoices: false,
      has_used_parts: false,
      has_additional_works: false,
      has_recalls: false,
      has_diary_events: true,
      date: 'Tues 13th Dec',
      type: 'Job',
      status: 'In progress',
      description: 'This job is in progress - perform a gas safety certificate',
      job_contact: 'Joseph Luck',
      notes_for_engineer: 'Make sure that you ask the customer if they would like a quote to replace the boiler',
      no_pending_additional_works: 0,
      audits : [],
      additional_work: {
        additional_work: false
      },
      on_hold: false,
      customer_purchase_order_number: 234324,
      access: 'We have keys',
      invoice_category: 'GSC Services',
      service: false,
      recall: {
        is_recall: false
      },
      recalls: [],
      reserve_list: {
        on_reserve_list: false
      },
      invoices: [],
      created_by: 'Jason Mojaria',
      created_on: '25th November 2013',
      last_modified_on: '26th November 2013',
      milestones: [
        {
          id: 1,
          new_milestone : true,
          name: 'Available Todos',
          available_todos: 0,
          completed_todos: 0,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('Travel'),
            jobDiaryEvents.getDiaryEvent('Leave')*/
          ],
          unassigned_todos: [],
          costs: {
            labour: [],
            travel: [],
            purchase_orders: [],
            supplier_invoices: [],
            stock_items: [],
            estimated_costs: [],
            total_labour: 0,
            total_travel: 0,
            total_purchase_orders: 0,
            total_supplier_invoices: 0,
            total_stock_items: 0,
            total_estimated_costs: 0,
            total: 0,
            total_tax: 0,
            grand_total: 0
          }
        }
      ],
      additional_works: [],
      timeline_sections: [],
      quoted_amount: 250
    },
    // 2300 - Completed and final invoiced
    {
      id: '2300',
      final_invoice_raised: true,
      final_invoice_number: 3242352,
      complete: true,
      has_purchase_orders: false,
      has_certificates: false,
      has_invoices: true,
      has_used_parts: false,
      has_additional_works: false,
      has_recalls: false,
      has_diary_events: false,
      date: 'Wed 14th Dec',
      type: 'Job',
      status: 'In progress',
      description: 'This job has been final invoiced - perform a gas safety certificate',
      job_contact: 'Joseph Luck',
      notes_for_engineer: 'Make sure that you ask the customer if they would like a quote to replace the boiler',
      no_pending_additional_works: 0,
      audits : [
        {
          type : 'check',
          message : 'This job is complete',
          additional_message : '- after care sales email sent on 13th January 2015',
          date : 'Tue 12th January 2015',
          time : '13:21pm',
          comment_positive : true,
          comment_by : 'Joseph Luck',
          comment : 'The service was fantastic, on time, reliable and the engineer was very friendly. I would definitely recommend ABC plumbing again.',
          category : 'after_care_sales'
        },
        {
          type : 'check',
          message : "This job's final invoice has been raised",
          date : 'Tue 10th January 2015',
          time : '9:21am',
          additional_message : '- The invoice number is 234324',
          category : 'final_invoice'
        }
      ],
      additional_work: {
        additional_work: false
      },
      on_hold: false,
      customer_purchase_order_number: 234324,
      access: 'We have keys',
      invoice_category: 'GSC Services',
      service: false,
      recall: {
        is_recall: false
      },
      recalls: [],
      reserve_list: {
        on_reserve_list: false
      },
      invoices: [],
      created_by: 'Jason Mojaria',
      created_on: '25th November 2013',
      last_modified_on: '26th November 2013',
      milestones: [
        {
          id: 1,
          new_milestone : true,
          name: 'Available Todos',
          available_todos: 0,
          completed_todos: 0,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('Leave'),
            jobDiaryEvents.getDiaryEvent('No access')*/
          ],
          unassigned_todos: [],
          costs: {
            labour: [],
            travel: [],
            purchase_orders: [],
            supplier_invoices: [],
            stock_items: [],
            estimated_costs: [],
            total_labour: 0,
            total_travel: 0,
            total_purchase_orders: 0,
            total_supplier_invoices: 0,
            total_stock_items: 0,
            total_estimated_costs: 0,
            total: 0,
            total_tax: 0,
            grand_total: 0
          }
        }
      ],
      additional_works: [],
      timeline_sections: [],
      quoted_amount: 250
    },
    // 2400 - Completed and free-of-charged
    {
      id: '2400',
      final_invoice_raised: false,
      final_invoice_number: null,
      complete: true,
      has_purchase_orders: false,
      has_certificates: false,
      has_invoices: true,
      has_used_parts: false,
      has_additional_works: false,
      has_recalls: false,
      has_diary_events: false,
      date: 'Thu 15th Dec',
      type: 'Job',
      status: 'Free of charge',
      description: 'This job has been free-of-charged - perform a gas safety certificate',
      job_contact: 'Joseph Luck',
      notes_for_engineer: 'Make sure that you ask the customer if they would like a quote to replace the boiler',
      no_pending_additional_works: 0,
      audits : [
        {
          type : 'check',
          message : 'This job is complete',
          additional_message : '- after care sales email sent on 13th January 2015',
          date : 'Tue 12th January 2015',
          time : '13:21pm',
          help_text : 'The customer has not yet responded to the after care sales email'
        },
        {
          type : 'warning',
          message : "This job has been free of charged",
          date : 'Tue 10th January 2015',
          time : '9:21am',
          additional_message : '- Joseph Luck marked the job as free of charge'
        },
        {
          type : 'warning',
          message : 'The tenant has not confirmed access',
          additional_message : '- last communication sent on 13th January 2015',
          date : 'Tue 12th January 2015',
          time : '13:21pm',
          help_text : 'We will try to contact the tenant again on the 15th of January 2015'
        }
      ],
      additional_work: {
        additional_work: false
      },
      on_hold: false,
      customer_purchase_order_number: 234324,
      access: 'We have keys',
      invoice_category: 'GSC Services',
      service: false,
      recall: {
        is_recall: false
      },
      recalls: [],
      reserve_list: {
        on_reserve_list: false
      },
      invoices: [],
      created_by: 'Jason Mojaria',
      created_on: '25th November 2013',
      last_modified_on: '26th November 2013',
      milestones: [
        {
          id: 1,
          new_milestone : true,
          name: 'Available Todos',
          available_todos: 0,
          completed_todos: 0,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('Leave')*/
          ],
          unassigned_todos: [],
          costs: {
            labour: [],
            travel: [],
            purchase_orders: [],
            supplier_invoices: [],
            stock_items: [],
            estimated_costs: [],
            total_labour: 0,
            total_travel: 0,
            total_purchase_orders: 0,
            total_supplier_invoices: 0,
            total_stock_items: 0,
            total_estimated_costs: 0,
            total: 0,
            total_tax: 0,
            grand_total: 0
          }
        }
      ],
      additional_works: [],
      timeline_sections: [],
      quoted_amount: 250
    },
    // 2500 - Completed and aborted
    {
      id: '2500',
      final_invoice_raised: false,
      final_invoice_number: null,
      complete: true,
      has_purchase_orders: false,
      has_certificates: false,
      has_invoices: true,
      has_used_parts: false,
      has_additional_works: false,
      has_recalls: false,
      has_diary_events: false,
      date: 'Fri 16th Dec',
      type: 'Job',
      status: 'Aborted',
      description: 'This job has been aborted - perform a gas safety certificate',
      job_contact: 'Joseph Luck',
      notes_for_engineer: 'Make sure that you ask the customer if they would like a quote to replace the boiler',
      no_pending_additional_works: 0,
      audits : [
        {
          type : 'warning',
          message : "This job has been aborted",
          date : 'Tue 10th January 2015',
          time : '9:21am',
          additional_message : '- Callum Hart aborted it'
        },
        {
          type : 'warning',
          message : "We've contacted the agent because the tenant has not responded",
          date : 'Tue 12th January 2015',
          time : '13:21pm',
          help_text : 'You can view the SMS in notes and communications'
        }
      ],
      additional_work: {
        additional_work: false
      },
      on_hold: false,
      customer_purchase_order_number: 234324,
      access: 'We have keys',
      invoice_category: 'GSC Services',
      service: false,
      recall: {
        is_recall: false
      },
      recalls: [],
      reserve_list: {
        on_reserve_list: false
      },
      invoices: [],
      created_by: 'Jason Mojaria',
      created_on: '25th November 2013',
      last_modified_on: '26th November 2013',
      milestones: [
        {
          id: 1,
          new_milestone : true,
          name: 'Available Todos',
          available_todos: 0,
          completed_todos: 0,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('Abort'),
            jobDiaryEvents.getDiaryEvent('Cancel')*/
          ],
          unassigned_todos: [],
          costs: {
            labour: [],
            travel: [],
            purchase_orders: [],
            supplier_invoices: [],
            stock_items: [],
            estimated_costs: [],
            total_labour: 0,
            total_travel: 0,
            total_purchase_orders: 0,
            total_supplier_invoices: 0,
            total_stock_items: 0,
            total_estimated_costs: 0,
            total: 0,
            total_tax: 0,
            grand_total: 0
          }
        }
      ],
      additional_works: [],
      timeline_sections: [],
      quoted_amount: 250
    },
    // 2600 - Recall in progress
    {
      id: '2600',
      final_invoice_raised: false,
      final_invoice_number: null,
      complete: false,
      has_purchase_orders: false,
      has_certificates: false,
      has_invoices: false,
      has_used_parts: false,
      has_additional_works: false,
      has_recalls: false,
      has_diary_events: true,
      date: 'Sat 17th Dec',
      type: 'Job',
      status: 'In progress',
      description: 'This recall is in progress - perform a gas safety certificate',
      job_contact: 'Joseph Luck',
      notes_for_engineer: 'Make sure that you ask the customer if they would like a quote to replace the boiler',
      no_pending_additional_works: 0,
      audits : [
        {
          type : 'info',
          message : 'This job is a recall',
          additional_message : '- Danny Wilcox is to blame',
          help_text : 'You can convert this recall into its own job by clicking edit in the job overview section converting to job',
          category : 'job_recall'
        }
      ],
      additional_work: {
        additional_work: false
      },
      on_hold: false,
      customer_purchase_order_number: 234324,
      access: 'We have keys',
      invoice_category: 'GSC Services',
      service: false,
      recall: {
        is_recall: true
      },
      recalls: [],
      reserve_list: {
        on_reserve_list: false
      },
      invoices: [],
      created_by: 'Jason Mojaria',
      created_on: '25th November 2013',
      last_modified_on: '26th November 2013',
      milestones: [
        {
          id: 1,
          new_milestone : true,
          name: 'Available Todos',
          available_todos: 0,
          completed_todos: 0,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('Accept')*/
          ],
          unassigned_todos: [],
          costs: {
            labour: [],
            travel: [],
            purchase_orders: [],
            supplier_invoices: [],
            stock_items: [],
            estimated_costs: [],
            total_labour: 0,
            total_travel: 0,
            total_purchase_orders: 0,
            total_supplier_invoices: 0,
            total_stock_items: 0,
            total_estimated_costs: 0,
            total: 0,
            total_tax: 0,
            grand_total: 0
          }
        }
      ],
      additional_works: [],
      timeline_sections: [],
      quoted_amount: 250
    },
    // 2700 - Completed and needs invoicing
    {
      id: '2700',
      final_invoice_raised: false,
      final_invoice_number: null,
      complete: true,
      has_purchase_orders: false,
      has_certificates: false,
      has_invoices: true,
      has_used_parts: false,
      has_additional_works: false,
      has_recalls: false,
      has_diary_events: false,
      date: 'Mon 19th Dec',
      type: 'Job',
      status: 'Needs invoicing',
      description: 'This job is complete but not invoiced - perform a gas safety certificate',
      job_contact: 'Joseph Luck',
      notes_for_engineer: 'Make sure that you ask the customer if they would like a quote to replace the boiler',
      no_pending_additional_works: 0,
      audits : [
        {
          type : 'info',
          message : 'This job is complete, and you need to action it',
          help_text : 'You can invoice the job using the actions on the right hand side',
          category : 'job_complete'
        }
      ],
      additional_work: {
        additional_work: false
      },
      on_hold: false,
      customer_purchase_order_number: 234324,
      access: 'We have keys',
      invoice_category: 'GSC Services',
      service: false,
      recall: {
        is_recall: false
      },
      recalls: [],
      reserve_list: {
        on_reserve_list: false
      },
      invoices: [],
      created_by: 'Jason Mojaria',
      created_on: '25th November 2013',
      last_modified_on: '26th November 2013',
      milestones: [
        {
          id: 1,
          new_milestone : true,
          name: 'Available Todos',
          available_todos: 0,
          completed_todos: 0,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('Leave')*/
          ],
          unassigned_todos: [],
          costs: {
            labour: [],
            travel: [],
            purchase_orders: [],
            supplier_invoices: [],
            stock_items: [],
            estimated_costs: [],
            total_labour: 0,
            total_travel: 0,
            total_purchase_orders: 0,
            total_supplier_invoices: 0,
            total_stock_items: 0,
            total_estimated_costs: 0,
            total: 0,
            total_tax: 0,
            grand_total: 0
          }
        }
      ],
      additional_works: [],
      timeline_sections: [],
      quoted_amount: 250
    },
    // 2800 - Pending and on hold
    {
      id: '2800',
      final_invoice_raised: false,
      final_invoice_number: null,
      complete: true,
      has_purchase_orders: false,
      has_certificates: false,
      has_invoices: true,
      has_used_parts: false,
      has_additional_works: false,
      has_recalls: false,
      has_diary_events: false,
      date: 'Tues 20th Dec',
      type: 'Job',
      status: 'Pending',
      description: 'This job is pending and on hold - perform a gas safety certificate',
      next_visit: 'Tues 15th Jan 2015 (11:00am to 12:30pm)',
      job_contact: 'Joseph Luck',
      notes_for_engineer: 'Make sure that you ask the customer if they would like a quote to replace the boiler',
      no_pending_additional_works: 0,
      audits : [
        {
          type : 'info',
          message : 'Joseph Luck put this job on hold',
          additional_message : '- Customer needs to clean property',
          date : 'Tue 22th November 2014',
          time : '10:11am',
          help_text : 'You can remove the on-hold status by going to quick links on the right',
          category : 'job_on_hold'
        }
      ],
      additional_work: {
        additional_work: false
      },
      on_hold: true,
      customer_purchase_order_number: 234324,
      access: 'We have keys',
      invoice_category: 'GSC Services',
      service: false,
      recall: {
        is_recall: false
      },
      recalls: [],
      reserve_list: {
        on_reserve_list: false
      },
      invoices: [],
      created_by: 'Jason Mojaria',
      created_on: '25th November 2013',
      last_modified_on: '26th November 2013',
      milestones: [
        {
          id: 1,
          new_milestone : true,
          name: 'Available Todos',
          available_todos: 0,
          completed_todos: 0,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('Pending'),
            jobDiaryEvents.getDiaryEvent('Accept')*/
          ],
          unassigned_todos: [],
          costs: {
            labour: [],
            travel: [],
            purchase_orders: [],
            supplier_invoices: [],
            stock_items: [],
            estimated_costs: [],
            total_labour: 0,
            total_travel: 0,
            total_purchase_orders: 0,
            total_supplier_invoices: 0,
            total_stock_items: 0,
            total_estimated_costs: 0,
            total: 0,
            total_tax: 0,
            grand_total: 0
          }
        }
      ],
      additional_works: [],
      timeline_sections: [],
      quoted_amount: 250
    },
    // 2900 - Additional work
    {
      id: '2900',
      final_invoice_raised: false,
      final_invoice_number: null,
      complete: true,
      has_purchase_orders: false,
      has_certificates: false,
      has_invoices: true,
      has_used_parts: false,
      has_additional_works: false,
      has_recalls: false,
      has_diary_events: false,
      date: 'Wed 21st Dec',
      type: 'Job',
      status: 'Pending',
      description: 'This job is pending and an additional work - perform a gas safety certificate',
      job_contact: 'Joseph Luck',
      next_visit: 'Tues 15th Jan 2015 (11:00am to 12:30pm)',
      notes_for_engineer: 'Make sure that you ask the customer if they would like a quote to replace the boiler',
      no_pending_additional_works: 0,
      audits : [
        {
          type : 'info',
          message : 'This job is an additional work',
          help_text : 'You can view the additional work estimate by following the link on the right',
          category : 'job_additional_work'
        }
      ],
      additional_work: {
        additional_work: true,
        original_job_id: 2700,
        original_job_type: 'Job'
      },
      on_hold: false,
      customer_purchase_order_number: 234324,
      access: 'We have keys',
      invoice_category: 'GSC Services',
      service: false,
      recall: {
        is_recall: false
      },
      recalls: [],
      reserve_list: {
        on_reserve_list: false
      },
      invoices: [],
      created_by: 'Jason Mojaria',
      created_on: '25th November 2013',
      last_modified_on: '26th November 2013',
      milestones: [
        {
          id: 1,
          new_milestone : true,
          name: 'Available Todos',
          available_todos: 0,
          completed_todos: 0,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('Pending')*/
          ],
          unassigned_todos: [],
          costs: {
            labour: [],
            travel: [],
            purchase_orders: [],
            supplier_invoices: [],
            stock_items: [],
            estimated_costs: [],
            total_labour: 0,
            total_travel: 0,
            total_purchase_orders: 0,
            total_supplier_invoices: 0,
            total_stock_items: 0,
            total_estimated_costs: 0,
            total: 0,
            total_tax: 0,
            grand_total: 0
          }
        }
      ],
      additional_works: [],
      timeline_sections: [],
      quoted_amount: 250
    },
    // 3000 - On reserve list
    {
      id: '3000',
      final_invoice_raised: false,
      final_invoice_number: null,
      complete: true,
      has_purchase_orders: false,
      has_certificates: false,
      has_invoices: true,
      has_used_parts: false,
      has_additional_works: false,
      has_recalls: false,
      has_diary_events: false,
      date: 'Thurs 22nd Dec',
      type: 'Job',
      status: 'Pending',
      description: 'This job is pending and on the reserve list - perform a gas safety certificate',
      job_contact: 'Joseph Luck',
      next_visit: 'Tues 15th Jan 2015 (11:00am to 12:30pm)',
      notes_for_engineer: 'Make sure that you ask the customer if they would like a quote to replace the boiler',
      no_pending_additional_works: 0,
      audits : [
        {
          type : 'info',
          message : 'Joseph Luck put this project on the reserve list',
          additional_message : '- (it is on 4 engineers reserve lists)',
          date : 'Tue 22th November 2014',
          time : '10:11am',
          help_text : 'You can view and edit the list of engineers by using the link on the right',
          category : 'reserve_list'
        }
      ],
      additional_work: {
        additional_work: false
      },
      on_hold: false,
      customer_purchase_order_number: 234324,
      access: 'We have keys',
      invoice_category: 'GSC Services',
      service: false,
      recall: {
        is_recall: false
      },
      recalls: [],
      reserve_list: {
        on_reserve_list: true,
        by: 'Joseph Luck',
        date: '13th November 2013',
        reserves: [
          {
            name: 'Joseph Luck'
          },
          {
            name: 'Callum Hart'
          },
          {
            name: 'Jason Morjaria'
          },
          {
            name: 'William Godwin'
          }
        ]
      },
      invoices: [],
      created_by: 'Jason Mojaria',
      created_on: '25th November 2013',
      last_modified_on: '26th November 2013',
      milestones: [
        {
          id: 1,
          new_milestone : true,
          name: 'Available Todos',
          available_todos: 0,
          completed_todos: 0,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('Pending')*/
          ],
          unassigned_todos: [],
          costs: {
            labour: [],
            travel: [],
            purchase_orders: [],
            supplier_invoices: [],
            stock_items: [],
            estimated_costs: [],
            total_labour: 0,
            total_travel: 0,
            total_purchase_orders: 0,
            total_supplier_invoices: 0,
            total_stock_items: 0,
            total_estimated_costs: 0,
            total: 0,
            total_tax: 0,
            grand_total: 0
          }
        }
      ],
      additional_works: [],
      timeline_sections: [],
      quoted_amount: 250
    },
    // 4000 - Project (small)
    {
      id: '4000',
      final_invoice_raised: false,
      final_invoice_number: null,
      complete: false,
      has_purchase_orders: true,
      has_certificates: true,
      has_invoices: true,
      has_used_parts: true,
      has_additional_works: true,
      has_recalls: true,
      has_diary_events: true,
      date: 'Mon 24th Dec',
      type: 'Project',
      status: 'In Progress',
      description: 'Gas Saftey Certificate',
      next_visit: 'Wed 17th Dec 2013 (9:00 - 10:00)',
      job_contact: 'Joseph Luck',
      created_by: 'Jason Mojaria',
      created_on: '25th November 2013',
      last_modified_on: '26th November 2013',
      notes_for_engineer: 'Make sure that you ask the customer if they would like a quote to replace the boiler',
      no_pending_additional_works: 1,
      audits : [],
      additional_work: {
        additional_work: false
      },
      on_hold: false,
      estimate_number: 2314,
      customer_purchase_order_number: 234324,
      access: 'Collect keys from tenant',
      invoice_category: 'GSC Services',
      service: false,
      recall: {
        is_recall: false
      },
      recalls: [],
      reserve_list: {
        on_reserve_list: false
      },
      invoices: [
        {
          id : '234324',
          type: 'Final',
          supplier_name : 'Jason Morjaria',
          supplier_branch : 'Hackney',
          supplier_addrs_1 : 'London',
          supplier_addrs_2 : 'UK',
          supplier_postcode : 'E12 6AP',
          supplier_phone : '01234567890',
          supplier_email : 'demo@commusoft',
          date : 'Mon 24th Dec 2013',
          invoice_number : '234324',
          description : 'Multiple Items',
          nominal_code : '100 - Parts',
          job_number : '1534',
          job_description : 'Boiler Install',
          VAT_rate : 5,
          sub_total : 194,
          taxes : 20,
          total : 224,
          remainder : 30,
          printed : 'Printed By Joseph Luck on 23rd November 2014',
          with_line_items: true,
          sent_to_accounting_package: true,
          send_to_accounting_package: true,
          audits : [
            {
              type : 'edit',
              message : 'Edited',
              name : 'Joseph Luck',
              date : 'Wed 23th November 2014',
              time : '14:32pm',
              required : true,
              completed : {
                completed : false,
                message : 'Marked off in SAGE',
                name : 'Joseph Luck',
                date : 'Tues 24th November 2014',
                time : '14:32pm'
              }
            },
            {
              type : 'check',
              message : 'Successfully saved in SAGE',
              date : 'Tue 22th November 2014',
              time : '10:11am'
            },
            {
              type : 'warning',
              message : 'Failed to save in SAGE because the credentials in company settings are incorrect',
              date : 'Sun 20th september 2014',
              time : '19:14pm',
              help_text : 'You can resend this record by going to quick links'
            }
          ],
          items : [
            {
              type : 'Part',
              description : 'Lorem Ipsum',
              quantity : '3',
              nominal: '2314',
              cost : 32,
              price : 45,
              VAT_rate : '20%',
              total : 100
            },
            {
              type : 'Part',
              description : 'Lorem Ipsum',
              quantity : '3',
              nominal: '2314',
              cost : 32,
              price : 45,
              VAT_rate : '20%',
              total : 100
            },
            {
              type : 'Part',
              description : 'Lorem Ipsum',
              quantity : '3',
              nominal: '2314',
              cost : 32,
              price : 45,
              VAT_rate : '20%',
              total : 100
            }
          ],
          payments : [
            {
              id: 879325,
              date: 'Mon 25th Dec 2013',
              method: 'Cash',
              description: 'Payment for invoices',
              reference: '879325',
              nominal : '322',
              amount : 20,
              sent_to_accounting_package : true,
              send_to_accounting_package: true,
              audits : [
                {
                  type : 'check',
                  message : 'Sent to SAGE',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm',
                  edits : [
                    {
                      type : 'edit',
                      message : 'Edited',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : true,
                      completed : {
                        completed : true,
                        message : 'Marked off in SAGE',
                        name : 'Joseph Luck',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm'
                      }
                    },
                    {
                      type : 'resend',
                      message : 'Resent to SAGE',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : false
                    }
                  ]
                }
              ],
              invoices : [
                {
                  id : 6456543,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                },
                {
                  id : 234324,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                }
              ]
            },
            {
              id: 6456543,
              date: 'Mon 28th Dec 2013',
              method: 'Credit',
              description: 'Credit allocation for invoices',
              reference: '6456543',
              nominal : '322',
              amount : 20,
              sent_to_accounting_package : true,
              send_to_accounting_package: true,
              audits : [
                {
                  type : 'check',
                  message : 'Sent to SAGE',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm',
                  edits : [
                    {
                      type : 'edit',
                      message : 'Edited',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : true,
                      completed : {
                        completed : true,
                        message : 'Marked off in SAGE',
                        name : 'Joseph Luck',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm'
                      }
                    },
                    {
                      type : 'resend',
                      message : 'Resent to SAGE',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : false
                    }
                  ]
                }
              ],
              invoices : [
                {
                  id : 6456543,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                },
                {
                  id : 234324,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                }
              ]
            },
            {
              id: 2589074,
              date: 'Mon 19th Jan 2014',
              method: 'Cheque',
              description: 'Cheque payment',
              reference: '2589074',
              nominal : '322',
              amount : 20,
              sent_to_accounting_package : true,
              send_to_accounting_package: true,
              audits : [
                {
                  type : 'check',
                  message : 'Sent to SAGE',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm',
                  edits : [
                    {
                      type : 'edit',
                      message : 'Edited',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : true,
                      completed : {
                        completed : true,
                        message : 'Marked off in SAGE',
                        name : 'Joseph Luck',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm'
                      }
                    },
                    {
                      type : 'resend',
                      message : 'Resent to SAGE',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : false
                    }
                  ]
                }
              ],
              invoices : [
                {
                  id : 6456543,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                },
                {
                  id : 234324,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                }
              ]
            }
          ]
        },
        {
          id : '6456543',
          type: 'Partial',
          supplier_name : 'Jason Morjaria',
          supplier_branch : 'Hackney',
          supplier_addrs_1 : 'London',
          supplier_addrs_2 : 'UK',
          supplier_postcode : 'E12 6AP',
          supplier_phone : '01234567890',
          supplier_email : 'demo@commusoft',
          date : 'Sat 30th Dec 2013',
          invoice_number : '6456543',
          description : 'Baxi Boiler',
          nominal_code : '100 - Parts',
          job_number : '1534',
          job_description : 'Boiler Install',
          VAT_rate : 20,
          sub_total : 1904,
          taxes : 220,
          total : 3024,
          remainder : 100,
          printed : 'Printed By Joseph Luck on 23rd November 2014',
          with_line_items: false,
          sent_to_accounting_package: false,
          send_to_accounting_package: false,
          payments : [
          ]
        },
        {
          id : '123456',
          type: 'Partial',
          supplier_name : 'Jason Morjaria',
          supplier_branch : 'Hackney',
          supplier_addrs_1 : 'London',
          supplier_addrs_2 : 'UK',
          supplier_postcode : 'E12 6AP',
          supplier_phone : '01234567890',
          supplier_email : 'demo@commusoft',
          date : 'Mon 24th Dec 2013',
          invoice_number : '234324',
          description : 'Multiple Items',
          job_number : '1534',
          job_description : 'Boiler Install',
          nominal_code : '100 - Parts',
          VAT_rate : 5,
          sub_total : 194,
          taxes : 20,
          total : 224,
          remainder : 30,
          printed : 'Printed By Joseph Luck on 23rd November 2014',
          with_line_items: true,
          sent_to_accounting_package: false,
          send_to_accounting_package: false,
          audits : [
            {
              type : 'sending',
              message : 'On its way to SAGE',
              date : 'Tues 24th November 2014',
              time : '14:32pm'
            }
          ],
          items : [
            {
              type : 'Part',
              description : 'Lorem Ipsum',
              quantity : '3',
              nominal: '2314',
              cost : 32,
              price : 45,
              VAT_rate : '20%',
              total : 100
            },
            {
              type : 'Part',
              description : 'Lorem Ipsum',
              quantity : '3',
              nominal: '2314',
              cost : 32,
              price : 45,
              VAT_rate : '20%',
              total : 100
            },
            {
              type : 'Part',
              description : 'Lorem Ipsum',
              quantity : '3',
              nominal: '2314',
              cost : 32,
              price : 45,
              VAT_rate : '20%',
              total : 100
            }
          ],
          payments : [
            {
              id: 1,
              date: 'Mon 25th Dec 2013',
              method: 'Cash',
              description: 'Lorem Ipsum',
              reference: '324324',
              nominal : '322',
              amount : 20,
              sent_to_accounting_package : true,
              send_to_accounting_package: true,
              audits : [
                {
                  type : 'check',
                  message : 'Sent to SAGE',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm'
                }
              ],
              invoices : [
                {
                  id : 6456543,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                },
                {
                  id : 234324,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                }
              ]
            },
            {
              id: 6456543,
              date: 'Mon 28th Dec 2013',
              method: 'Credit',
              description: 'Lorem Ipsum',
              reference: '6456543',
              nominal : '322',
              amount : 20,
              sent_to_accounting_package : true,
              send_to_accounting_package: true,
              audits : [
                {
                  type : 'check',
                  message : 'Sent to SAGE',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm'
                },
                {
                  type : 'resend',
                  message : 'Resent to SAGE',
                  name : 'Joseph Luck',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm',
                  required : false
                }
              ],
              invoices : [
                {
                  id : 6456543,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                },
                {
                  id : 234324,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                }
              ]
            },
            {
              id: 3,
              date: 'Mon 19th Jan 2014',
              method: 'Cheque',
              description: 'Lorem Ipsum',
              reference: '324987',
              nominal : '322',
              amount : 20,
              sent_to_accounting_package : true,
              send_to_accounting_package: true,
              audits : [
                {
                  type : 'check',
                  message : 'Sent to SAGE',
                  date : 'Tues 24th November 2014',
                  time : '14:32pm',
                  edits : [
                    {
                      type : 'edit',
                      message : 'Edited',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : true,
                      completed : {
                        completed : true,
                        message : 'Marked off in SAGE',
                        name : 'Joseph Luck',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm'
                      }
                    },
                    {
                      type : 'resend',
                      message : 'Resent to SAGE',
                      name : 'Joseph Luck',
                      date : 'Tues 24th November 2014',
                      time : '14:32pm',
                      required : false
                    }
                  ]
                }
              ],
              invoices : [
                {
                  id : 6456543,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                },
                {
                  id : 234324,
                  description : 'Baxi Boiler',
                  nominal_code : '100 - Parts',
                  amount : 20,
                  remainder : 100
                }
              ]
            }
          ]
        }
      ],
      milestones: [
        {
          id: 1,
          new_milestone : false,
          name: 'Removal and disposal',
          available_todos: 15,
          completed_todos: 4,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('Pending', 3),
            jobDiaryEvents.getDiaryEvent('Accept', 3),
            jobDiaryEvents.getDiaryEvent('Travel', 3),
            jobDiaryEvents.getDiaryEvent('Arrive', 3)*/
          ],
          unassigned_todos: [
            {
              id: 13,
              status: "Pending",
              type: "install",
              description: 'Install the radiator valves',
              item: 'Radiator Valves',
              estimated_hours: '1',
              quantity: '50',
              quantity_installed: '34',
              part_status: "Available",
              complete: {
                time: '10:00',
                notes: 'There wasnt enough time to install all of the valves'
              }
            },
            {
              id: 14,
              status: "Pending",
              type: "maintenance",
              description: 'This is urgent',
              appliance: 'Vaillant EcoTech',
              estimated_hours: '1.4',
              complete: {
                time: '10:00',
                notes: 'Tightened up fixtures and fittings'
              }
            },
            {
              id: 15,
              status: "Pending",
              type: "breakdown",
              description: 'This is urgent',
              appliance: 'Vaillant EcoTech',
              estimated_hours: '1.4',
              complete: {
                time: '10:00',
                notes: 'Tightened up fixtures and fittings'
              }
            }
          ],
          costs: {
            labour: [
              {
                visit: '13th July 2014',
                engineer: 'Joseph Luck',
                onsite: 2,
                travel: 0.5,
                labourrate: 60.00,
                travelrate: 60.00,
                total: 180.00
              }
            ],
            travel: [
              {
                date: '13th July 2014',
                description: 'Travel to property',
                travel: 0.5,
                rate: 0.50,
                vehicle: 'AU10 SBZ',
                engineer: 'Joseph Luck',
                total: 2.70
              }
            ],
            purchase_orders: [
              {
                supplier_id: '1',
                id: '234324',
                date: '13th July 2014',
                supplier: 'Plumbase',
                description: 'Piping',
                total_exc_vat: 150.00,
                vat: 30.00,
                total: 180.00
              }
            ],
            supplier_invoices: [
              {
                supplier_id: '1',
                id: '234324',
                date: '13th July 2014',
                supplier: 'Plumbase',
                description: 'Vailant Boiler',
                total_exc_vat: 2000.00,
                vat: 400.00,
                remainder_to_pay: 300.00,
                total: 2400.00
              }
            ],
            stock_items: [
              {
                date: '13th July 2014',
                description: 'Flue',
                stocklocation: 'AU10 SBZ',
                quantity: '1',
                total_exc_vat: 800.00,
                vat: 160.00,
                total: 960.00
              }
            ],
            estimated_costs: [
              {
                description: 'Fittings',
                supplier: 'Plumbase',
                cost: 20,
                quantity: '10',
                total_exc_vat: 300.00,
                vat: 60.00,
                total: 360.00
              }
            ],
            total_labour: 120.00,
            total_travel: 2.70,
            total_purchase_orders: 180.00,
            total_supplier_invoices: 2400.00,
            total_stock_items: 960.00,
            total_estimated_costs: 360.00,
            total: 4025.00,
            total_tax: 805.00,
            grand_total: 4830.00
          }
        },
        {
          id: 2,
          new_milestone : false,
          name: 'Preperation',
          available_todos: 4,
          completed_todos: 0,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('No access', 2),
            jobDiaryEvents.getDiaryEvent('Leave', 2),
            jobDiaryEvents.getDiaryEvent('Leave', 2)*/
          ],
          unassigned_todos: [
            {
              id: 12,
              status: "Complete",
              type: "general",
              description: 'Tidy up property',
              complete: {
                time: '10:00',
                notes: 'I tidied up the property, there was a lot of junk near the pipework in the kitchen'
              }
            }
          ],
          costs: {
            labour: [
              {
                visit: '13th July 2014',
                engineer: 'Joseph Luck',
                onsite: 2,
                travel: 0.5,
                labourrate: 60.00,
                travelrate: 60.00,
                total: 180.00
              }
            ],
            travel: [
            ],
            purchase_orders: [
              {
                supplier_id: '1',
                id: '234324',
                date: '13th July 2014',
                supplier: 'Plumbase',
                description: 'Piping',
                total_exc_vat: 150.00,
                vat: 30.00,
                total: 180.00
              }
            ],
            supplier_invoices: [],
            stock_items: [
              {
                date: '13th July 2014',
                description: 'Flue',
                stocklocation: 'AU10 SBZ',
                quantity: '1',
                total_exc_vat: 800.00,
                vat: 160.00,
                total: 960.00
              }
            ],
            estimated_costs: [
              {
                description: 'Fittings',
                supplier: 'Plumbase',
                cost: 20,
                quantity: '10',
                total_exc_vat: 300.00,
                vat: 60.00,
                total: 360.00
              }
            ],
            total_labour: 120.00,
            total_travel: 2.70,
            total_purchase_orders: 180.00,
            total_supplier_invoices: 2400.00,
            total_stock_items: 960.00,
            total_estimated_costs: 360.00,
            total: 4025.00,
            total_tax: 805.00,
            grand_total: 4830.00
          }
        },
        {
          id: 3,
          new_milestone : false,
          name: 'Installation',
          available_todos: 26,
          completed_todos: 2,
          diary_events: [
            /*jobDiaryEvents.getDiaryEvent('Abort', 1),
            jobDiaryEvents.getDiaryEvent('Cancel', 1)*/
          ],
          unassigned_todos: [
            {
              id: 10,
              status: "Complete",
              type: "certificate",
              description: 'GSC for boiler',
              certificate: 'CP4 - Gas Safety Inspection',
              estimated_hours: '2.5',
              complete: {
                time: '10:00',
                certificate: '',
                notes: 'Boiler inspection passed'
              }
            },
            {
              id: 11,
              status: "Complete",
              type: "decommission",
              description: 'Old boiler is defective',
              appliance: 'Vaillant EcoTech',
              estimated_hours: '3.2',
              complete: {
                time: '10:00',
                notes: 'The boiler was difficult to remove'
              }
            }
          ],
          costs: {
            labour: [],
            travel: [],
            purchase_orders: [],
            supplier_invoices: [],
            stock_items: [],
            estimated_costs: [],
            total_labour: 0,
            total_travel: 0,
            total_purchase_orders: 0,
            total_supplier_invoices: 0,
            total_stock_items: 0,
            total_estimated_costs: 0,
            total: 0,
            total_tax: 0,
            grand_total: 0
          }
        }
      ],
      additional_works: [
        {
          title: 'Fix the leaking pipe',
          id: '750',
          status: 'Accepted',
          created_by: 'Joseph Luck',
          date: '13th Nov 2014',
          time: '14:21pm',
          todos: 4,
          parts: 2,
          total_exc_vat: 200,
          vat: 40,
          total: 240,
          cost: 100,
          profit: 140
        },
        {
          title: 'Fix the leaking pipe',
          id: '750',
          status: 'Rejected',
          created_by: 'Joseph Luck',
          date: '13th Nov 2014',
          time: '14:21pm',
          todos: 4,
          parts: 2,
          total_exc_vat: 200,
          vat: 40,
          total: 240,
          cost: 100,
          profit: 140
        },
        {
          title: 'Fix the leaking pipe',
          id: '750',
          status: 'Waiting Approval',
          created_by: 'Joseph Luck',
          date: '13th Nov 2014',
          time: '14:21',
          todos: 4,
          parts: 2,
          total_exc_vat: 200,
          vat: 40,
          total: 240,
          cost: 100,
          profit: 140
        },
        {
          title: 'Fix the tap fittings',
          id: '754',
          status: 'Draft',
          created_by: 'Callum Hart',
          date: '11th Jan 2014',
          time: '10:21am'
        }
      ],
      dashboard: [
        {
          parts_requested : 5,
          parts_on_order : 10,
          last_diary_event: {
            engineer_profile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=45',
            engineer: 'Callum Hart',
            status: 'Completed',
            date: '13th November 2014',
            time: '12:00 - 18:00',
            notes: 'All todos completed, additional work required to repair leaking pipe'
          },
          next_diary_event: {
            engineer_profile_image: 'https://s.gravatar.com/avatar/453a7a095109ec93f419a54ad19956f6?s=45',
            engineer: 'Joseph Luck',
            status: 'Not Complete',
            date: '17th November 2014',
            time: '14:00 - 21:00',
            description: 'Please install the boiler and make sure its completely secure'
          }
        }
      ],
      timeline_sections: [
        {
          id: 3,
          time: '13th January 2015'
        },
        {
          id: 2,
          time: '10th January 2015'
        },
        {
          id: 1,
          time: '9th January 2015'
        }
      ],
      quoted_amount: 250,
      total_invoiced: 950,
      grand_total: 3200
    }
	]

	unassigned_todos = [
		{
      id: 110,
      status: "Not Complete",
      type: "certificate",
      description: 'GSC for cooker',
      certificate: 'CP4 - Gas Safety Inspection',
      estimated_hours: '2.5'
    },
    {
      id: 111,
      status: "Not Complete",
      type: "decommission",
      description: 'Old cooker is defective',
      appliance: 'Bosch Classixx',
      estimated_hours: '2.1'
    },
    {
      id: 112,
      status: "Not Complete",
      type: "general",
      description: 'Get petrol en-route'
    },
    {
      id: 113,
      status: "Not Complete",
      type: "install",
      description: 'Install the new boiler',
      item: 'Bosch Avantixx',
      estimated_hours: '4',
      quantity: '1',
      quantity_installed: '1',
      part_status: "Available"
    },
    {
      id: 114,
      status: "Not Complete",
      type: "maintenance",
      description: 'Make sure all fixtures and fittings are tight and secure',
      appliance: 'Bosch Avantixx',
      estimated_hours: '0.6'
    },
    {
      id: 117,
      status: "Not Complete",
      type: "breakdown",
      description: 'Make sure all fixtures and fittings are tight and secure',
      appliance: 'Bosch Avantixx',
      estimated_hours: '0.6'
    },
    {
      id: 115,
      status: "Not Complete",
      type: "photo",
      description: 'Take a photograph of the gas main seal'
    },
    {
      id: 116,
      status: "Not Complete",
      type: "service",
      description: 'Service the inflow pipe',
      appliance: 'Inflow pipe',
      estimated_hours: '1.8'
    }
	]

  /*parts_locations = [
    {
      part_id: 16,
      locations: [
        {
          location: 'Van AU10 SBZ',
          quantity: 20,
          allocation: 0
        },
        {
          location: 'Stock room 1',
          quantity: 20,
          allocation: 0
        },
        {
          location: 'Warehouse',
          quantity: 300,
          allocation: 0
        },
        {
          location: 'General',
          allocation: 0
        }
      ]
    },
    {
      part_id: 15,
      locations: [
        {
          location: 'Van TK1 3DL',
          quantity: 14,
          allocation: 0
        },
        {
          location: 'Stock room 1',
          quantity: 32,
          allocation: 0
        },
        {
          location: 'Warehouse',
          quantity: 650,
          allocation: 0
        },
        {
          location: 'General',
          allocation: 0
        }
      ]
    },
    {
      part_id: 14,
      locations: [
        {
          location: 'Van X20 ONK',
          quantity: 80,
          allocation: 0
        },
        {
          location: 'Stock room 1',
          quantity: 1000,
          allocation: 0
        },
        {
          location: 'Warehouse',
          quantity: 9213,
          allocation: 0
        },
        {
          location: 'General',
          allocation: 0
        }
      ]
    },
    {
      part_id: 27,
      locations: [
        {
          location: 'Van MM57 CDK',
          quantity: 10,
          allocation: 0
        },
        {
          location: 'Stock room 1',
          quantity: 140,
          allocation: 0
        },
        {
          location: 'Warehouse',
          quantity: 120,
          allocation: 0
        },
        {
          location: 'General',
          allocation: 0
        }
      ]
    }
  ]*/
    this.parts_locations = [];
    this.setPartLocations = function setPartLocations(data){
        this.parts_locations = data;
    }

    this.part_view_data = [];
    this.setJobPartData = function setJobPartData(data){
        this.part_view_data = data;
    }


    this.selectedParts = [];
    this.setSelectedPart = function setSelectedPart(data){
        this.selectedParts = data;
    }
    this.getSelectedPart = function getSelectedPart(){
        return this.selectedParts;
    }
    this.getJobPartData = function getJobPartData(){
        return this.part_view_data;
    }
    diary = [
    [
      {},
      {},
      {
        events: [
          {
            'engineer' : 'Callum Hart',
            'time' : '13:00 - 15:00',
            'milestone' : 0,
            'id' : 0,
            'showing' : true
          },
          {
            'engineer' : 'Jason Morjaria',
            'time' : '13:00 - 15:00',
            'milestone' : 0,
            'id' : 0,
            'showing' : true
          }
        ]
      },
      {
        events: [
          {
            'engineer' : 'Callum Hart',
            'time' : '13:00 - 15:00',
            'milestone' : 0,
            'id' : 0,
            'showing' : true
          }
        ]
      },
      {},
      {},
      {}
    ],
    [
      {},
      {},
      {},
      {},
      {},
      {
        events: [
          {
            'engineer' : 'Joseph Luck',
            'time' : '13:00 - 15:00',
            'milestone' : 0,
            'id' : 0,
            'showing' : true
          }
        ]
      },
      {}
    ],
    [
      {},
      {
        events: [
          {
            'engineer' : 'William Godwin',
            'time' : '13:00 - 15:00',
            'milestone' : 0,
            'id' : 0,
            'showing' : true
          }
        ]
      },
      {},
      {},
      {},
      {},
      {}
    ],
    [
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ],
    [
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ]
  ];

  diary_engineers = [
    {
      'name' : 'Callum Hart',
      'color' : '#d25656',
      'engineer_gravatar' : 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=20'
    },
    {
      'name' : 'Jason Morjaria',
      'color' : '#6393b5',
      'engineer_gravatar' : 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=20'
    },
    {
      'name' : 'Joseph Luck',
      'color' : '#746ca6',
      'engineer_gravatar' : 'https://s.gravatar.com/avatar/453a7a095109ec93f419a54ad19956f6?s=20'
    },
    {
      'name' : 'William Godwin',
      'color' : '#60a369'
    }
  ];

  additional_work = [
    {
      title: 'Additional Work',
      estimate_body: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo.',
      todos: [
        'todo1',
        'todo2',
        'todo3'
      ],
      cost: '300',
      price: '400',
      profit: '100',
      status: {
        decision: null,
        name: null,
        date: null,
        time: null
      }
    }
  ];

  this.countUnassignedTodos = function countUnassignedTodos(job_id) {
    var number_of_assigned_todos = 0,
        job_milestones = this.getJobById(job_id).milestones;

    for (var v = 0, b = job_milestones.length; v < b; v++) {
      number_of_assigned_todos + job_milestones[v].unassigned_todos.length;
    }

  	return number_of_assigned_todos;
  }

  this.getJobs = function getJobs() {
  	return jobs;
  }

  this.getJobById = function getJobById(id) {
  	for (var i = 0, l = jobs.length; i < l; i++) {
  		if (jobs[i].id === id) {
  			return jobs[i];
  		}
  	}
  }

  this.getAllAssignedTodosById = function getAllAssignedTodosById(job_id) {
    var job_assigned_todos = [];

    for (var v = 0, b = this.getJobById(job_id).milestones.length; v < b; v++) {
      var milestone_diary_events = this.getJobById(job_id).milestones[v].diary_events,
          milestone_todos = [];

      for (var i = 0, l = milestone_diary_events.length; i < l; i++) {
        var diary_event_todos = milestone_diary_events[i].todos;
        for (var x = 0, c = diary_event_todos.length; x < c; x++) {
          milestone_todos.push(diary_event_todos[x]);
        }
      }
      for (var i = 0, l = milestone_todos.length; i < l; i++) {
        job_assigned_todos.push(milestone_todos[i]);
      }

    }
    return job_assigned_todos;
  }

  this.getStockLocationsByPartId = function getStockLocationsByPartId(part_id){
    for (var i = 0, l = this.parts_locations.length; i < l; i++) {
      if (parseInt(this.parts_locations[i].part_id) === parseInt(part_id)) {
        return this.parts_locations[i];
      }
    }
  }

  this.generateMilestone = function generateMilestone(milestone_name) {
    var milestone = {
          id: Math.floor(Math.random() * 600) + 1000,
          new_milestone: true,
          name: milestone_name,
          available_todos: 0,
          completed_todos: 0,
          diary_events: [],
          unassigned_todos: []
        }

    return milestone;
  }

  this.getUnassignedTodos = function getUnassignedTodos() {
    return unassigned_todos;
  }

  this.getDiary = function getDiary() {
    return diary;
  }

  this.getdiary_engineers = function getdiary_engineers() {
    return diary_engineers;
  }

  this.getAdditionalWork = function getAdditionalWork() {
    return additional_work
  }

  // Invoicing
  this.getJobInvoiceById = function getJobInvoiceById(job_id, invoice_id) {
    for (var i = 0, l = jobs.length; i < l; i++) {
      var job = jobs[i];
      if (job.id === job_id) {
        for (var x = 0, c = job.invoices.length; x < c; x++) {
          var invoice = job.invoices[x];
          if (invoice.id === invoice_id) {
            return invoice;
          }
        }
      }
    }
  }

  this.getJobInvoicePaymentByIds = function getJobInvoicePaymentByIds(job_id, invoice_id, payment_id) {
    for (var i = 0, l = jobs.length; i < l; i++) {
      var job = jobs[i];
      if (job.id === job_id) {
        for (var x = 0, c = job.invoices.length; x < c; x++) {
          var invoice = job.invoices[x];
          if (invoice.id === invoice_id) {
            for (var t = 0, j = invoice.payments.length; t < j; t++) {
              var payment = invoice.payments[t];
              if (payment.id == payment_id) {
                return payment;
              }
            }
          }
        }
      }
    }
  }
    this.mergeJobParts = [];this.santizedParts = [];
    this.setMergeJobParts = function(Parts,src){
        if(typeof Parts !== 'undefined'){
            var partIds = _.pluck(Parts,'partId');
            var groupedParts = _.groupBy(Parts, function(val){ return val.partId });var santizedParts = [];var mergeJobPartIds = [];

            angular.forEach(groupedParts,function(value,partId){
                if(value.length > 1){
                    var sum = 0;var latestPart = {};
                    // sum the quantity
                    sum = _.reduce(value, function(memo,val){ return memo + parseFloat(val.part.Quantity); }, 0);

                    //pick the latest jobpart
                    latestPart  = _.max(value, function(part){ return part.id; });
                    if(src === 'job_parts'){
                        latestPart.Quantity = parseFloat(sum).toFixed(2);
                    }

                    if(src === 'parts_operational_list'){
                        latestPart.quantity = parseFloat(sum).toFixed(2);
                    }
                    latestPart.part.Quantity = parseFloat(sum).toFixed(2);

                      // var jobPartIds = _.reject(_.pluck(value,'id'), function(data){ return data == latestPart.id; });
                    mergeJobPartIds[partId] = _.pluck(value,'id');

                    //push the part into stack to display in the sidepanel
                    groupedParts[partId] = _.reject(value, function(data){ return data.id != latestPart.id; });
                }
            });
            angular.forEach(groupedParts,function(value,partId) {
                santizedParts.push(value[0]);
            });

            this.mergeJobParts = mergeJobPartIds;
            this.santizedParts = santizedParts;
        }
    }

    this.getSantizedParts = function getSantizedParts(){
      return this.santizedParts;
    }
    this.getMergeJobParts = function getMergeJobParts(){
      return this.mergeJobParts;
    }
}]);
