/** Payment Authorization message Settings screen resolver ======================================*/
var PaymentMessageResolver = {
    getIdData: function ($q, $http, $stateParams, $state, PubNub, $rootScope, cacheBust, prefix, adminPrefix) {
        var deferred = $q.defer();
        $http.get(Routing.generate('getPaymentAuthorizationMessage')).success(function (data, status, headersObj) {
            var headers = headersObj();
            SetRootScopeValues($rootScope, headers, PubNub, cacheBust, $http, prefix, adminPrefix, $state);
            if (data.location && data.location == 'logout') {
                $rootScope.$broadcast('chat:unregister');
                $state.transitionTo('home');
            } else if (data.location && data.location.endsWith("/")) {
            } else {
                deferred.resolve(data);
            }
        });

        return deferred.promise;
    }
};
/** Payment Authorization message Settings screen controller ======================================*/
var PaymentMessageSettingsCtrl = function ($scope, $rootScope, $http, getIdData, $state, $q, $timeout, toastBox, datasets, prefix) {

    $scope.message = getIdData.message;
    $scope.updateMessage = function(message){
        $scope.message = message;
    };
    $scope.onFormSubmit = function (form) {

        var data = angular.copy($scope.message);
        $scope.action = 'SAVING';
        $http({
            url: Routing.generate('setPaymentAuthorizationMessage'),
            method: 'POST',
            data: JSON.stringify(data)
        }).success(function (result) {
            $state.go('loggedin.system_settings');
            $scope.scrollTo('invoicing');
            var $message = 'Message.Saved'
            toastBox.show($message, 2000);
            $scope.data = angular.copy(result);
            $scope.action = 'SAVED';
            form.$setPristine();
        }).error(function (err) {
            console.log(err);
            $scope.action = 'FAILED_TO_SAVE';

        });
    };
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
};
