commusoftCommon.directive("completeMe", function($http, $compile, $timeout, $rootScope, canLoad, $q) {
    var handleCompleteMe = function handleCompleteMe(scope, element, attrs) {
        scope.element = element[0];
        scope.selected_value = attrs.selectedValue;
        scope.inputs_to_set = [];
        var canceler = null;
        scope.search = false;
        scope.reference = null;
        scope.initializeCompleteMe = function initializeCompleteMe(config) {
            var placeholder = config.placeholder || "",
                completeMe = new CompleteMe(scope.element,{
                    data: config.data,
                    placeholder: placeholder,
                    canAddNewRecords: config.canAddNewRecords,
                    suggestResult: config.suggestResult,
                    selectedValue: scope.selected_value,
                    selectedIndex: scope.selectedIndex,
                    onSelect: function(record) {
                        scope.selected_record = record;
                        scope.$apply(scope.onSelected());

                    },
                    onKeyedUp: function(search) {
                        canLoad.setLoadValue(false);
                        if (canceler) {
                            canceler.resolve();
                        }
                        canceler = $q.defer();
                        $timeout($('#loading-indicator').show(), 1000);
                        $http.get(Routing.generate('accounting_interfaces_xero_link', { record_type: scope.recordType })+'?search='+search, {timeout: canceler.promise}).then(function(response) {
                            config.data = (response.data.contacts)?response.data.contacts:[];
                            if(config.data.length > 0) {
                                scope.reference = response.data.reference;
                                scope.selected_value = search;
                                scope.search = true;
                                scope.initializeCompleteMe(config);
                            }
                            $('#loading-indicator').hide();
                        }, errorCallback);
                    },
                    onAdd: function(new_record) {
                        scope.new_cell_value = new_record;
                        scope.$apply(scope.onAdd());

                        if(config.side_panel) {
                            scope.handleNewRecord(new_record, config.side_panel);
                        }
                    }});
            if(scope.search == true) {
                angular.element('#cm-input'+scope.selectedIndex).focus();
                scope.search = false;
            }

        }
        function errorCallback() {
            $('#loading-indicator').hide();
            $timeout($('#loading-indicator').show(), 1000);
        }
        scope.handleNewRecord = function handleNewRecord(new_record, side_panel_name) {
            scope.new_cell_value = new_record;
            var side_panel_elm = angular.element('#' + side_panel_name);
            side_panel_elm.click();

            switch(side_panel_name) {
                case "new_part":
                    scope.inputs_to_set.push({model: 'description', value: new_record, set_cell_value: true});
                    scope.inputs_to_set.push({model: 'type', value: 'Part'});
            }

            // When sidepanel is in the DOM
            window.requestAnimationFrame(function() {
                for(var i = 0, l = scope.inputs_to_set.length; i < l; i++) {
                    var input = angular.element('#quick-add-panel').find("[ng-model=" + scope.inputs_to_set[i].model + "]");
                    input.val(scope.inputs_to_set[i].value);
                    input.scope()[scope.inputs_to_set[i].model] = scope.inputs_to_set[i].value; input.focus().blur(); // this is because inputs are validated on blur.
                }

                // This fires when the panel closes. It's passed the form values to set in the spreadsheet
                $rootScope.$once('quick_add_panel:closed', function(e, form) {
                    var key_to_set = _.findWhere(scope.inputs_to_set, {set_cell_value: true}).model,
                        value_to_set = _.findWhere(form, {model_name: key_to_set}).value;

                    scope.new_cell_value = value_to_set;

                    $timeout(function() {
                        scope.$apply(scope.onPanelClosed());
                    });
                });
            });
        }

        // Handle initialization

        var config = JSON.parse(attrs.config),
            remote_data = typeof(config.data) !== "object";

        if(remote_data) {
            $http.get(config.data).then(function(response) {
                config.data = response.data;
                scope.initializeCompleteMe(config);
            });
        }else {
            scope.initializeCompleteMe(config);
        }
    }

    return {
        restrict: "A",
        scope: {
            onSelected: '&',
            onAdd: '&',
            onPanelClosed: '&',
            recordType: '@',
            selectedIndex: '@'
        },
        link: handleCompleteMe
    }
});