'use strict';

function SetupRootCtrl($scope, $http, $state, setupDatasets, prefix, $rootScope) {
    $scope.data = setupDatasets;

    $scope.$watch("stageNumber", function () {
        $scope.$emit('stageNumberEvent', $scope.stageNumber);
    });

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                if (data.location) {
                    $scope.data = data.location.replace(/\//g, '');
                    if ($scope.data == 'customers') { //If we finish setupwizard, redirect to cmpany settings
                        $rootScope.$broadcast('event:auth-loginConfirmed');
                        $state.transitionTo('loggedin.' + $scope.data);
                    } else {
                        $state.transitionTo('setupwizard.' + $scope.data);
                    }
                } else {
                    //$scope.data = data;
                }
            }).
            error(function (data, status) {
                $scope.data = data;
            });
    }

    $scope.skipStage = function($pathName){
        $http.get(prefix + '/' + $pathName).
            success(function (data, status) {
                if (data.location) {
                    $scope.data = data.location.replace(/\//g, '');
                    $state.transitionTo('setupwizard.' + $scope.data);
                } else {
                    $scope.data = data;
                }
            }).
            error(function (data, status) {
                $scope.data = data;
            });
    }
}

function SetupUserCtrl($scope, $state, prefix, $http, setupDatasets, formService) {
    var initialUserCount = setupDatasets.initialUserCount;
    $scope.data = setupDatasets;
    $scope.existingUserCount = $scope.data.users.length;
    $scope.canAddUserCount = initialUserCount - $scope.existingUserCount;
    $scope.saveUser = ($scope.canAddUserCount > 1) ? "Save User" : "Continue to Next Step";
    $scope.savingUser = ($scope.canAddUserCount > 1) ? "Saving User" : "Continuing to Next Step";
    $scope.saving = false;

    $scope.checkIfUsersCreated = function() {
        if ($scope.existingUserCount >= 3) {
            $scope.setupUserForm['firstName'].$setValidity('required', true);
            $scope.setupUserForm['surname'].$setValidity('required', true);
            $scope.setupUserForm['email'].$setValidity('required', true);
            return true;
        }
    }

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.location) {
                $state.transitionTo('setupwizard.firstaccountnumber');
            } else {
                if ($scope.data.users.length == 0) {
                    $scope.data.users = [];
                }
                JustAddedUpdatedChanges.call(this, $scope, 'user');
                $scope.data.users.unshift({'name': $scope.firstName, 'surname': $scope.surname, 'justadded': true});
                $scope.existingUserCount = $scope.existingUserCount + 1;
                $scope.canAddUserCount = $scope.canAddUserCount - 1;
                $scope.saveUser = ($scope.canAddUserCount > 1) ? "Save User" : "Continue to Next Step";
                if ($scope.canAddUserCount == 0) {
                    $state.transitionTo('setupwizard.firstaccountnumber');
                }
                $scope.setupUserForm.$setPristine();
                $scope.firstName = $scope.surname = $scope.email = "";
                $scope.loginMethod = 0;
            }
            $scope.saving = false;
        });
    }
}

function SetupFirstNumberCtrl($scope, $http, $state, setupDatasets) {
    $scope.data = setupDatasets;
    FirstNumberCtrl.call(this, $scope, $http, $state);
    SetupRootCtrl.call(this, $scope, $http, $state, setupDatasets);
}

function SetupRootSetupEmailCtrl($scope, $stateParams, prefix, $location, $http, $window, $state, setupDatasets, canLoad, $q) {
    $scope.data = setupDatasets;
    $scope.selectedServer = 'smtp';
    $scope.existEmailAddress = true;

    $scope.changeServerAddress = function () {
        $scope.serverAddressInvalid = false;
    };
    $scope.changePortNumber = function () {
        $scope.portNumberInvalid = false;
    };
    $scope.changeSecurityType = function () {
        $scope.securityTypeInvalid = false;
    };
    $scope.changePassword = function () {
        $scope.passwordInvalid = false;
    };

    $scope.formSubmit = function (formElem) {
        $scope.passwordInvalid = false;
        $scope.serverAddressInvalid = false;
        $scope.portNumberInvalid = false;
        $scope.securityTypeInvalid = false;
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                if (data.error && data.error == 'Exists') {
                    $scope.setupEmailForm['setupemails[emailaddress]'].$dirty = true;
                    $scope.setupEmailForm['setupemails[emailaddress]'].$error.required = false;
                    $scope.setupEmailForm['setupemails[emailaddress]'].$error.email = false;
                    $scope.existEmailAddress = false;
                } else if (data == 'Invalid Password') {
                    $scope.passwordInvalid = true;
                } else if (data == 'Invalid Server Address') {
                    $scope.serverAddressInvalid = true;
                } else if (data == 'Enter the Email Providers') {
                    $scope.showDetails = true;
                } else if (data == 'Invalid Providers') {
                    $scope.securityTypeInvalid = true;
                } else if (data == 'Invalid PortNumber') {
                    $scope.portNumberInvalid = true;
                } else if (data.location) {
                    $scope.data = data.location.replace(/\//g, '');
                    $state.transitionTo('setupwizard.' + $scope.data);
                } else {
                    $scope.data = data;
                }
                $scope.saving = false;
            });
    }


    var canceler = null;
    $scope.$watch('emailaddress', function (newVal, oldVal) {
        if(!newVal) {
            return false;
        }
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        $scope.email = $scope.emailaddress.split('@');
        if (typeof $scope.email[1] != '' && $scope.email[1] != 'undefined') {
            $http.get(prefix + '/setupEmailDomainExists?emailaddress=' + $scope.emailaddress + '&selectedServer=' + $scope.selectedServer, {timeout: canceler.promise}).
                success(function (data, status) {
                    if (status == 200) {
                        if (data == 'true') {
                            $scope.showDetails = false;
                        } else {
                            $scope.showDetails = true;
                        }
                    }
                })
            $http.get(prefix + '/setupEmailExists?emailaddress=' + $scope.emailaddress + '&selectedServer=' + $scope.selectedServer, {timeout: canceler.promise}).
                success(function (data, status) {
                    if (status == 200) {
                        if (data == 'true') {
                            $scope.existEmailAddress = false;
                        } else {
                            $scope.existEmailAddress = true;
                        }
                    }
                })
        }
    });

    $scope.$watch("stageNumber", function () {
        $scope.$emit('stageNumberEvent', $scope.stageNumber);
    });
}
