commusoftCommon.service('estimateDiaryEvents', function() {

    var diary_events = {
        estimates: {
            data: [
                {
                    id: 0,
                    status: "Not Complete",
                    date: "22nd Apr 2014",
                    time: "09:00 - 10:00",
                    description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo.",
                    location: "EN3 5PB",
                    engineer_name: "Callum Hart",
                    profile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                    notes: "This is the engineer notes for event 1",
                    todos: [
                        {
                            todo : "Photograph boiler",
                            status : "Done",
                            marked_off_by: 'John Smith',
                            more_details : {
                                photos: [
                                    {
                                        name: 'Bolier Photograph',
                                        date_added: 'Mon 21st June 2013',
                                        url: 'images/files/test1.png'
                                    }
                                ],
                                history: [
                                    {
                                        date: '18/03/14',
                                        note: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                        engineer: 'Paul Smith',
                                        proile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                                        tag: 'Service'
                                    },
                                    {
                                        date: '17/03/14',
                                        note: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                        engineer: 'David James',
                                        proile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                                        tag: 'Breakdown'
                                    },
                                    {
                                        date: '16/03/14',
                                        note: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                        engineer: 'Callum Hart',
                                        proile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                                        tag: 'Installation'
                                    }
                                ]
                            }
                        },
                        {
                            todo : "Photograph parking location",
                            status : "Done",
                            marked_off_by: 'John Smith',
                            more_details : {
                                photos: [
                                    {
                                        name: 'Parking Location',
                                        date_added: 'Mon 21st June 2013',
                                        url: 'images/files/test2.png'
                                    }
                                ],
                                history: [
                                    {
                                        date: '20/04/14',
                                        note: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                        engineer: 'John Martin',
                                        proile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                                        tag: 'Service'
                                    },
                                    {
                                        date: '21/04/14',
                                        note: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                        engineer: 'Warren Martin',
                                        proile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                                        tag: 'Breakdown'
                                    }
                                ]
                            }
                        },
                        {
                            todo : "This is todo 3",
                            status : "To-do"
                        }
                    ]
                },
                {
                    id: 1,
                    status: "Not Complete",
                    date: "24th Apr 2014",
                    time: "09:20 - 11:00",
                    description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo.",
                    location: "SW1 3AB",
                    engineer_name: "Nick Brown",
                    profile_image: 'http://gravatar.com/avatar/a6968fbfb11b00704757be49891ffb80.png?s=30',
                    notes: "This is the engineer notes for event 1"
                },
                {
                    id: 3,
                    status: "Completed",
                    date: "09th Jan 2014",
                    time: "12:00 - 14:00",
                    description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo.",
                    location: "WA1 5SK",
                    engineer_name: "Peter Smith",
                    profile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                    notes: "This is the engineer notes for event 3",
                    todos: [
                        {
                            todo : "This is todo 1",
                            status : "Done"
                        },
                        {
                            todo : "This is todo 2",
                            status : "Done"
                        },
                        {
                            todo : "This is todo 3",
                            status : "To-do"
                        }
                    ],
                    travel_details: {
                        accept: "12:03",
                        travel: "12:07",
                        arrival: "12:40",
                        complete: "15:30",
                        time_travelling: "33 mins",
                        time_on_site: "1 hour 50 mins"
                    },
                    arrive_on_site_questions: [
                        {
                            question: "This is question 1",
                            answer: "This is the answer to question 1"
                        },
                        {
                            question: "This is question 2",
                            answer: "This is the answer to question 2"
                        },
                        {
                            question: "This is question 3",
                            answer: "This is the answer to question 3"
                        }
                    ],
                    signatures: [
                        {
                            type: "Leave Signature",
                            path: "../images/signature.png"
                        }
                    ]
                },
                {
                    id: 4,
                    status: "Completed",
                    date: "04th Feb 2014",
                    time: "10:00 - 12:00",
                    description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo.",
                    location: "DA2 7HG",
                    engineer_name: "Sam Jones",
                    profile_image: 'http://gravatar.com/avatar/a6968fbfb11b00704757be49891ffb80.png?s=30',
                    notes: "This is the engineer notes for event 4",
                    todos: [
                        {
                            todo : "This is todo 1",
                            status : "Done"
                        },
                        {
                            todo : "This is todo 2",
                            status : "Done"
                        },
                        {
                            todo : "This is todo 3",
                            status : "To-do"
                        }
                    ],
                    travel_details: {
                        accept: "12:03",
                        travel: "12:07",
                        arrival: "12:40",
                        complete: "15:30",
                        time_travelling: "33 mins",
                        time_on_site: "1 hour 50 mins"
                    },
                    arrive_on_site_questions: [
                        {
                            question: "This is question 1",
                            answer: "This is the answer to question 1"
                        },
                        {
                            question: "This is question 2",
                            answer: "This is the answer to question 2"
                        },
                        {
                            question: "This is question 3",
                            answer: "This is the answer to question 3"
                        }
                    ],
                    signatures: [
                        {
                            type: "Complete Signature",
                            path: "../images/signature.png"
                        }
                    ]
                }
            ]
        },
        projects: {
            data: [
                {},
                {}
            ]
        }
    }

    /*====================================================
     Getters
     ====================================================*/
    this.getDiaryEvents = function getDiaryEvents(category) {
        return diary_events[category].data;
    }
});