commusoftCommon.directive('angularFileUpload', [function() {
    return {
        restrict: 'EA',
        replace: false,
        scope: {
            action: '@',
            excludedFileTypes: '=',
            validFileTypes: '=',
            btnLabel: '@',
            btnClass: '@',
            inputName: '@',
            progressClass: '@',
            checkAllowedMemory: '@',
            onSuccess: '&'
        },
        link: function(scope, elem, attrs, ctrl) {
            attrs.btnLabel = attrs.btnLabel || "Choose File";
            attrs.inputName = attrs.inputName || "file";
            attrs.btnClass = attrs.btnClass || "btn";
            attrs.progressClass = attrs.progressClass || "btn";

            elem.find('.fake-uploader').click(function() {
                elem.find('input[type="file"]').click();
            });
        },
        template: "\
            <div class='uploader'> \
				<input \
					type='file' \
					name='{{ inputName }}' \
					style='display: none; opacity: 0; width:0px;' \
					ng-file-select='onFileSelect($files);'/> \
				<div class='btn-group'> \
					<button \
						class='{{ btnClass }} fake-uploader' \
						type='button' \
						readonly='readonly' \
						ng-disabled='buttonHide' ng-model='avatar'>{{ btnLabel }}</button> \
					<button \
						disabled \
						class='{{ progressClass }} btn upload_file_insafari upload-progress' \
						ng-class='{ \"btn-primary\": progress < 100, \"btn-success\": progress == 100 }' \
						ui-if=\"progress > 0\">{{ progress }}%</button>\
				</div> \
			</div>",
        controller: ['$scope', 'prefix', '$upload', '$http', function ($scope, prefix, $upload, $http) {
            $scope.progress = 0;
            $scope.avatar = '';
            if ($scope.checkAllowedMemory != 'no') {
                $scope.buttonHide = true;
                $http.get(prefix + '/file_upload_block?').success(function (data) {
                    if (data == 'true') {
                        $scope.buttonHide = true;
                        $scope.$parent.disk_space_error = true;
                    } else {
                        $scope.buttonHide = false;
                        $scope.$parent.disk_space_error = false;
                    }
                });
            }

            $scope.$on('RESET_PROGRESS', function(event) {
                $scope.progress = 0;
            });
            $scope.onFileSelect = function ($files) {
                for (var i = 0; i < $files.length; i++) {
                    var file = $files[i];
                    var fileName = file.name;
                    var extension = fileName.split('.').pop();
                    var ext = extension.toLowerCase();
                    var allowed = true;
                    if (typeof $scope.excludedFileTypes != "undefined") {
                        if ($scope.excludedFileTypes.length) {
                            for (var i = 0; i < $scope.excludedFileTypes.length; i++) {
                                if ($scope.excludedFileTypes[i] == ext) {
                                    allowed = false;
                                }
                            }
                        }
                    } else {
                        allowed = false;
                    }
                    if(file.size >= 33554432){
                      var data = {error: true, message: 'The File ' +fileName + 'exceeds your upload upload_max_filesize ini directive (limit is 2048 kb).', action: $scope.action};
                      $scope.onSuccess({resp: data});
                    }

                    //# Validate with only file type allowed
                    if((typeof $scope.validFileTypes != "undefined") && ($scope.validFileTypes.length)) {

                        if($scope.validFileTypes.indexOf(ext) != -1) {
                            $scope.progress = 0;
                            $scope.upload = $upload.upload({
                                url: prefix + $scope.action,
                                method: 'POST',
                                file: file
                            }).progress(function (evt) {
                                $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
                            }).success(function (data, status, headers, config) {
                                $scope.onSuccess({resp: data});
                            });
                        } else {
                            var errorExtension = $scope.validFileTypes.join(' or ');
                            var data = {error: true, message: 'Please upload a valid file, should be in the format of ' + errorExtension, action: $scope.action};
                            $scope.onSuccess({resp: data});
                        }

                    }else if(ext == 'jpg' || ext == 'jpeg' || ext == 'png' || allowed) {
                        $scope.progress = 0;
                        $scope.upload = $upload.upload({
                            url: prefix + $scope.action,
                            method: 'POST',
                            file: file
                        }).progress(function (evt) {
                            $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
                        }).success(function (data, status, headers, config) {
                            $scope.onSuccess({resp: data});
                        });
                    } else {
                        var data = {error: true, message: 'Please upload a valid image file, should be in the format of jpg or jpeg or png', action: $scope.action};
                        $scope.onSuccess({resp: data});
                    }
                }
            };
        }]
    };
}]);