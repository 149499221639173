commusoftCommon.service('jobMultipleInvoiceDecorator', function($state, $rootScope, jobData) {
    this.getJobFromId = function getJobFromId(id) {
        return jobData.getJobById(id);
    }

    this.decorateJobFromServiceForSmartTable = function decorateJobFromServiceForSmartTable(job) {
        var number_of_diary_events = 0,
            no_parts = 0,
            no_todos = 0,
            no_additional_works = job.additional_works.length,
            job_status = 'New';

        job.milestones.map(function(milestone) {
            number_of_diary_events += milestone.diary_events.length;
            no_parts += milestone.parts.length;
            no_todos += milestone.unassigned_todos;
        })

        if (job.complete === true) {
            job_status = 'Complete'
        } else if (job.has_diary_events === true) {
            job_status = 'In progress'
        }

        return {
            'Job number': job.id,
            'Job description': job.description,
            'Status' : job_status,
            'Property name' : job.property_name,
            'Property address' : job.property_address_line1 + ' ' + job.property_address_line2,
            'Property postcode' : job.property_postcode,
            'No. diary events': number_of_diary_events,
            'No. parts': no_parts,
            'No. additional works': no_additional_works,
            'No. todos': no_todos,
            'Customer purchase order' : 43285673 + parseFloat(job.id)
        }
    }

    this.handleJobData = function handleJobData() {
        var all_jobs = jobData.getJobs(),
            decorated_jobs = [];

        for(var i = 0, l = all_jobs.length; i < l; i++) {
            var job = all_jobs[i];

            if (job.estimate === undefined && job.complete !== true) {
                decorated_jobs.push(this.decorateJobFromServiceForSmartTable(job));
            }
        }

        return decorated_jobs;
    }
});