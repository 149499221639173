/**
 * Created by santha on 3/6/14.
 */
'use strict';
csmodule.service('shortcutsContainer', ['$timeout', function ($timeout) {
    this.urlList = [];
    this.accessDetails = [];
    this.shortcuts = [];
    this.data = [];

    this.setUrlList = function () {
        var urlArray = [];
        var shortcuts = this.shortcuts;
        angular.forEach(shortcuts, function (shortcutObj, key) {
            angular.forEach(shortcutObj.options, function (optionObj, key) {
                urlArray[optionObj.description] = {state: optionObj.state, parameters: optionObj.parameters};
            });
        });
        this.urlList = urlArray;
    }

    this.setData = function (object) {
        this.data = object;
    }

    this.groupExist = function (name, shortcuts){
        for(var i = 0; i < shortcuts.length; i++) {
            if( shortcuts[i].group == name){
               return shortcuts[i];
            }
        }
        return false;
    }

    this.getShortcuts = function (state, selection) {
        if(Object.keys(state.params).length!=0) {
        var params = state.params;
        var state = state.current.name;
        }
         if(state == 'loggedin.settings' || state == 'loggedin.system_settings'){
            var generalLink = [
                { "description": "Customers", "state": "loggedin.customers", 'featureName': 'Customers', 'access': 'writeaccess' },
                { "description": "Diary", "state": "loggedin.diary", 'featureName': 'Diary', 'access': 'readaccess' },
                { "description": "Reporting", "state": "loggedin.reporting", 'featureName': 'Reporting', 'access': 'readaccess' },
                { "description": "Suppliers", "state": "loggedin.suppliers", 'featureName': 'Suppliers', 'access': 'readaccess' },
                { "description": "Integrations", "state": "loggedin.integrations" }
            ]
        }else if(state == 'loggedin.company_settings' || state == 'loggedin.settings' ){
            console.log('loggedin.company_settings');
            var generalLink = [
                { "description": "Customers", "state": "loggedin.customers", 'featureName': 'Customers', 'access': 'writeaccess' },
                { "description": "Diary", "state": "loggedin.diary", 'featureName': 'Diary', 'access': 'readaccess' },
                { "description": "Reporting", "state": "loggedin.reporting", 'featureName': 'Reporting', 'access': 'readaccess' },
                { "description": "Suppliers", "state": "loggedin.suppliers", 'featureName': 'Suppliers', 'access': 'readaccess' },
                { "description": "Integrations", "state": "loggedin.integrations" }
            ]
        }else if (state == 'loggedin.customers'){
            var generalLink = [
                { "description": "Suppliers", "state": "loggedin.suppliers", 'featureName': 'Suppliers', 'access': 'readaccess' },
                { "description": "Diary", "state": "loggedin.diary", 'featureName': 'Diary', 'access': 'readaccess' },
                { "description": "Reporting", "state": "loggedin.reporting", 'featureName': 'Reporting', 'access': 'readaccess' },
                { "description": "Integrations", "state": "loggedin.integrations"},
                { "description": "Settings", "state": "loggedin.settings", 'featureName': 'Settings', 'access': 'writeaccess'}

            ]
        }else if (state == 'loggedin.suppliers'){
            var generalLink = [
                { "description": "Customers", "state": "loggedin.customers", 'featureName': 'Customers', 'access': 'writeaccess' },
                { "description": "Diary", "state": "loggedin.diary", 'featureName': 'Diary', 'access': 'readaccess' },
                { "description": "Reporting", "state": "loggedin.reporting", 'featureName': 'Reporting', 'access': 'readaccess' },
                { "description": "Integrations", "state": "loggedin.integrations"},
                { "description": "Settings", "state": "loggedin.settings", 'featureName': 'Settings', 'access': 'writeaccess'}
            ]
        }else{
            var generalLink = [
                { "description": "Customers", "state": "loggedin.customers", 'featureName': 'Customers', 'access': 'writeaccess' },
                { "description": "Diary", "state": "loggedin.diary", 'featureName': 'Diary', 'access': 'readaccess' },
                { "description": "Suppliers", "state": "loggedin.suppliers", 'featureName': 'Suppliers', 'access': 'readaccess' },
                { "description": "Reporting", "state": "loggedin.reporting", 'featureName': 'Reporting', 'access': 'readaccess' },
                { "description": "Integrations", "state": "loggedin.integrations"},
                { "description": "Settings", "state": "loggedin.settings", 'featureName': 'Settings', 'access': 'writeaccess'}
            ]
            
        }

        var data = {shortcuts: [
            {group: "General Shortcuts", options: generalLink }
        ]};
        if(state == 'loggedin.system_settings')
        {
            data.shortcuts.unshift(
                {group: "Security", options: [
                    { "description": "Role Based Security", "state": "loggedin.role_based_security", 'featureName': 'Setuprolemanagement', 'access': 'updateaccess' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "Online Portals", options: [
                    { "description": "Customise Mini App", "state": "loggedin.customise_mini_app", 'featureName': 'Setupminiappforwebsite', 'access': 'updateaccess' },
                    { "description": "Customer Login Role Based Security", "state": "", 'featureName': '', 'access': '' }
                ]}
            );

            data.shortcuts.unshift(
                {group: "Mobile Settings", options: [
                    { "description": "Estimate Leave Signature Message", "state": "loggedin.estimate_leave_signature_message", 'featureName': 'Setupestimateleavesignaturemessage', 'access': 'updateaccess' },
                    { "description": "Estimate Mobile Engineer SMS", "state": "loggedin.estimate_mobile_engineer_sms", 'featureName': 'Setupengineerontheirwaysmsforestimates', 'access': 'updateaccess' },
                    { "description": "Estimate Leave Questions", "state": "loggedin.leave_job_questions", 'featureName': 'Setupleaveestimatequestions', 'access': 'writeaccess' },
                    { "description": "Estimate No Access Message", "state": "loggedin.estimate_no_access_message", 'featureName': 'Customisenoaccessmessageforestimates', 'access': 'updateaccess' },
                    { "description": "Job Arrive Signature Message", "state": "loggedin.job_arrive_signature_message", 'featureName': 'Setupjobarrivesignaturemessage', 'access': 'updateaccess' },
                    { "description": "Job Leave Signature Message", "state": "loggedin.job_leave_signature_message", 'featureName': 'Setupjobleavesignaturemessage', 'access': 'updateaccess' },
                    { "description": "Job Mobile Engineer SMS", "state": "loggedin.job_mobile_engineer_sms", 'featureName': 'Setupengineerontheirwayforjobs', 'access': 'updateaccess' },
                    { "description": "Job Arrive On Site Questions", "state": "loggedin.job_site_arrival_questions", 'featureName': 'Setupjobarriveonsitequestions', 'access': 'writeaccess' },
                    { "description": "Leave Job Questions", "state": "loggedin.leave_job_questions", 'featureName': 'Setupleavejobquestions', 'access': 'writeaccess' },
                    { "description": "Job No Access Message", "state": "loggedin.job_no_access_message", 'featureName': 'Customisenoaccessmessageforjobs', 'access': 'updateaccess' }
                ]}
            );

            data.shortcuts.unshift(
                {group: "Employee & Vehicles", options: [
                    { "description": "User Groups", "state": "loggedin.user_groups", 'featureName': 'Setupusergroups', 'access': 'writeaccess' },
                    { "description": "Vehicles Parameters", "state": "loggedin.vehicles_parameter", 'featureName': 'Vehiclesparameters', 'access': 'writeaccess' },
                    { "description": "Notification Preferences", "state": "loggedin.notification_preferences", 'featureName': 'Notificationpreferences', 'access': 'readaccess' },
                    { "description": "Shifts", "state": "loggedin.engineer_shift", 'featureName': 'SetupEngineerShift', 'access': 'updateaccess' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "Stock Control", options: [
                    { "description": "Stock Locations", "state": "loggedin.stock_locations", 'featureName': 'Setupstocklocations', 'access': 'writeaccess' },
                    { "description": "Stock Inventory System", "state": "loggedin.stock_inventory_system", 'featureName': 'Setupstockinventorysystem', 'access': 'updateaccess' },
                    { "description": "Stock Wastage Reasons", "state": "loggedin.stock_wastage_reasons", 'featureName': 'Setupstockwastagereason', 'access': 'writeaccess' },
                    { "description": "Setup part collection workflow","state": "loggedin.setup_part_collection_workflow", 'featureName': 'Setuppartcollectionworkflow', 'access': 'updateaccess' },
                    { "description": "Part Reorder Lists","state": "loggedin.partsReorder", 'featureName': 'SetupPartsReorder', 'access': 'writeaccess' },
                ]}
            );
            data.shortcuts.unshift(
                {group: "Parts Management", options: [
                    { "description": "Industries", "state": "loggedin.industries", 'featureName': 'Setupindustries', 'access': 'writeaccess' },
                    { "description": "Categories", "state": "loggedin.category", 'featureName': 'Setuppartscategories', 'access': 'writeaccess' },
                    { "description": "Sub Categories", "state": "loggedin.sub_categories", 'featureName': 'Setupsub-categories', 'access': 'writeaccess' },
                    { "description": "Parts and Prices", "state": "loggedin.parts_and_prices", 'featureName': 'Setuppartsandprices', 'access': 'writeaccess' },
                    { "description": "Part Kits", "state": "loggedin.partsKit", 'featureName': 'Setuppartkit', 'access': 'writeaccess' },
                    { "description": "Supplier Parts Quotation Email Message", "state": "loggedin.supplier_parts_quotation_email", 'featureName': 'Supplierpartsquotationemailmessage', 'access': 'updateaccess' },
                        { "description": "Supplier Parts Undelivered Notification", "state": "loggedin.supplier_parts_undelivered_notifications", 'featureName': 'SupplierPartsUndeliveredNotification', 'access': 'updateaccess' }

                    ]}
            );
            data.shortcuts.unshift(
                {group: "Suppliers", options: [
                    { "description": "Purchase Order Email Message", "state": "loggedin.purchase_order_email", 'featureName': 'Setuppurchaseorderemailmessage', 'access': 'updateaccess' },
                    { "description": "Purchase order number", "state": "loggedin.purchase_order_number", 'featureName': 'SetPoNumber', 'access': 'updateaccess' }
                    ]}
            );
            data.shortcuts.unshift(
                {group: "Invoicing", options: [
                    { "description": "Invoice Number", "state": "loggedin.firstinvoicenumber", 'featureName': 'Configurethewayinvoicenumbersaregenerated', 'access': 'updateaccess' },
                    { "description": "Invoice Template", "state": "loggedin.invoicetemplate", 'featureName': 'Setuptheinvoicetemplatedesign', 'access': 'updateaccess' },
                    { "description": "Statement Email Message", "state": "loggedin.statement_emailmessage", 'featureName': 'Setupthestatementemailmessage', 'access': 'updateaccess' },
                    { "description": "Invoice Email Message", "state": "/system_settings/invoice_message", 'featureName': 'Setupdefaultinvoiceemailmessage', 'access': 'updateaccess' },
                    { "description": "Invoice Categories", "state": "loggedin.invoicecategories", 'featureName': 'Setupinvoicecategories', 'access': 'writeaccess' },
                    { "description": "Invoice Item Categories", "state": "loggedin.invoiceitemcategories", 'featureName': 'Customiseinvoiceitemcategories', 'access': 'writeaccess' },
                    { "description": "Payment Methods", "state": "loggedin.payment_methods", 'featureName': 'Setuppaymentmethods', 'access': 'writeaccess' },
                    { "description": "Note at the Bottom of Invoice", "state": "loggedin.invoice_note", 'featureName': 'Setupinvoicenote', 'access': 'updateaccess' },
                    { "description": "Invoice Terms and Conditions", "state": "loggedin.invoice_terms_conditions", 'featureName': 'Setupinvoicetermsandconditions', 'access': 'updateaccess' },
                    { "description": "After Sales Communication", "state": "loggedin.after_sales_communication", 'featureName': 'Setupaftersalescommunication', 'access': 'updateaccess' },
                    { "description": "Statement preferences", "state": "loggedin.statement_preferences", 'featureName': 'Statementpreferences', 'access': 'updateaccess' },
                    { "description": "Payment Receipt Email Message", "state": "loggedin.payment_receipts_message", 'featureName': 'Setuppaymentreceiptemailmessage', 'access': 'updateaccess' },
                        { "description": "Payment authorization message", "state": "loggedin.payment_authorization_message", 'featureName': 'SetupDeferredPayment', 'access': 'updateaccess' }

                    ]}
            );
            data.shortcuts.unshift(
                {group: "Service Reminders", options: [
                    { "description": "Service Reminders", "state": "loggedin.service_reminder", 'featureName': 'Setupservicereminders', 'access': 'writeaccess' },
                    { "description": "Service Plan", "state": "loggedin.serviceplan", 'featureName': 'Setupserviceplan', 'access': 'writeaccess' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "Certificates", options: [
                    { "description": "Certificate Email Message", "state": "loggedin.certificate_emailmessage", 'featureName': 'Setupthecertificateemailmessage', 'access': 'updateaccess' },
                    { "description": "Print and Post Covering Letter", "state": "loggedin.printpost_cover_letter", 'featureName': 'Setupthecertificateprintandpostletter', 'access': 'updateaccess' },
                    { "description": "Custom Certificates", "state": "loggedin.customcertificates", 'featureName': 'Setupcustomcertificate', 'access': 'writeaccess' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "Jobs", options: [
                    { "description": "First Job Number", "state": "loggedin.firstjobnumber", 'featureName': 'Setupfirstjobnumber', 'access': 'updateaccess' },
                    { "description": "Job Descriptions", "state": "/system_settings/job-descriptions", 'featureName': 'Setupjobdescriptions', 'access': 'writeaccess' },
                    { "description": "Job Sheet Design", "state": "loggedin.job_sheetdesign", 'featureName': 'Setupjobsheetdesign,updateaccess', 'access': 'updateaccess' },
                    { "description": "Engineer 'Job Sheet' Email", "state": "loggedin.engineer_emailjobsheetmessage", 'featureName': 'Setupengineeremailjobsheetmessage', 'access': 'updateaccess' },
                    { "description": "Engineer Job Sheet SMS", "state": "loggedin.engineer_jobsheetsms", 'featureName': 'Setupengineerjobsheetsms', 'access': 'updateaccess' },
                    { "description": "Customer Job Confirmation", "state": "loggedin.customer_job_confirmation", 'featureName': 'Setupcustomerjobconfirmation', 'access': 'updateaccess' },
                    { "description": "Work Address Job Confirmation", "state": "loggedin.work_address_job_confirmation", 'featureName': 'Setupworkaddressjobconfirmation', 'access': 'updateaccess' },
                    { "description": "Job Address Reminder", "state": "loggedin.job_reminder", 'featureName': 'Setupworkaddressjobreminder', 'access': 'updateaccess' },
                    { "description": "Free of Charge Reasons", "state": "loggedin.freeofcharge", 'featureName': 'Setupfreeofchargereasons', 'access': 'writeaccess' },
                    { "description": "On Hold Description", "state": "loggedin.on_hold_descriptions", 'featureName': 'Setuponholddescriptions', 'access': 'writeaccess' },
                    { "description": "Abort Reasons", "state": "loggedin.abortreason", 'featureName': 'Setupabortreasons', 'access': 'writeaccess' },
                    { "description": "Remedial Works", "state": "loggedin.remedial_works", 'featureName': 'Setuprecallreason', 'access': 'updateaccess' },
                    { "description": "Job Work Address Access Communications", "state": "loggedin.work_address_messages.job_work_address_messages", 'featureName': 'Setupjobworkaddressaccesscommunications', 'access': 'updateaccess' },
                    { "description": "Job Report Customisation", "state": "loggedin.job_report_customization", 'featureName': 'Setupjobreportcustomisation', 'access': 'updateaccess' },
                    { "description": "Job Report Email Message", "state": "loggedin.job_report_emailmessage", 'featureName': 'Setupjobreportemailmessage', 'access': 'updateaccess' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "Estimates", options: [
                    { "description": "First Estimate Number", "state": "loggedin.firstestimatenumber", 'featureName': 'Setupfirstestimatenumber', 'access': 'updateaccess' },
                    { "description": "Setup Templates", "state": "loggedin.estimate_template", 'featureName': 'SetupEstimateTemplates', 'access': 'writeaccess' },
                    { "description": "Setup Estimate Note", "state": "loggedin.estimate_note", 'featureName': 'Setupestimatenote', 'access': 'updateaccess' },
                    { "description": "Engineer 'Estimate Sheet' Email", "state": "loggedin.estimate_sheet_message", 'featureName': 'Setupengineerestimatesheetemailmessage', 'access': 'updateaccess' },
                    { "description": "Engineer 'Estimate Sheet' SMS", "state": "loggedin.engineer_estimate_sms", 'featureName': 'Setupengineerestimatesms', 'access': 'updateaccess' },
                    { "description": "Customer Estimate Confirmation", "state": "loggedin.customer_estimate_confirmation", 'featureName': 'Setupcustomerestimateconfirmation', 'access': 'updateaccess' },
                    { "description": "Work Address Estimate Confirmation", "state": "loggedin.work_address_estimate_confirmation", 'featureName': 'Setupworkaddressestimateconfirmation', 'access': 'updateaccess' },
                    { "description": "Estimate Address Reminder", "state": "loggedin.estimate_reminder", 'featureName': 'Setupworkaddressestimatereminder', 'access': 'updateaccess' },
                    { "description": "Estimate Reject Reasons", "state": "loggedin.estimaterejectreasons", 'featureName': 'Setupestimaterejectreasons', 'access': 'writeaccess' },
                    { "description": "Estimate Email Message", "state": "loggedin.estimatedefaultemail", 'featureName': 'Setupestimatedefaultemailmessage', 'access': 'updateaccess' },
                    { "description": "Online Estimate Portal", "state": "loggedin.online_estimateportal", 'featureName': 'Onlineestimateportal', 'access': 'updateaccess' },
                    { "description": "T&C for Estimates", "state": "loggedin.terms_and_conditions_estimates", 'featureName': 'Setuptermsandconditionforestimate', 'access': 'updateaccess' },
                    { "description": "Estimate Work Address Access Communications", "state": "loggedin.work_address_messages.estimate_work_address_messages", 'featureName': 'Setupestimateworkaddressaccesscommunications', 'access': 'updateaccess' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "Customer Information", options: [
                    { "description": "First Customer Account Number", "state": "loggedin.firstaccountnumber", 'featureName': 'Setupfirstcustomeraccountnumber', 'access': 'updateaccess' },
                    { "description": "Customer Types", "state": "loggedin.customer_types", 'featureName': 'Setupcustomertypes', 'access': 'writeaccess' },
                    { "description": "Contract Plans", "state": "", 'featureName': 'SetupContractPlans', 'access': 'writeaccess' },
                    { "description": "Advertising 'How did you hear from us?'", "state": "loggedin.advertisingtypes", 'featureName': 'Setupadvertising', 'access': 'writeaccess' },
                    { "description": "Creditor Days", "state": "loggedin.creditor_days", 'featureName': 'Setupcreditordays', 'access': 'writeaccess' },
                    { "description": "Types of Buildings", "state": "loggedin.buildingtypes", 'featureName': 'Setuptypesofbuildings', 'access': 'writeaccess' },
                    { "description": "Customer SMS templates", "state": "loggedin.customer_sms_template", 'featureName': 'Setupcustomersmstemplates', 'access': 'writeaccess' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "Sales", options: [
                        { "description": "Set up pipeline", "state": "/system_settings/set_up_pipelines", 'featureName': '', 'access': '' },
                        { "description": "Opportunity template", "state": "/system_settings/opportunity_template", 'featureName': '', 'access': '' },
                        { "description": "Proposal templates", "state": "/system_settings/proposal_templates", 'featureName': '', 'access': '' },
                        { "description": "Option template", "state": "/system_settings/option_template", 'featureName': '', 'access': '' },
                        { "description": "Reporting category", "state": "/system_settings/reporting_categories", 'featureName': '', 'access': '' },
                        { "description": "Email template", "state": "/system_settings/email_templates", 'featureName': '', 'access': '' },
                        { "description": "SMS templates", "state": "/system_settings/sms_templates", 'featureName': '', 'access': '' },
                        { "description": "Opportunity reject reasons", "state": "/system_settings/opportunity_reject_reasons", 'featureName': '', 'access': '' },
                        { "description": "Opportunity cancel reasons", "state": "/system_settings/opportunity_cancel_reasons", 'featureName': '', 'access': '' },
                        { "description": "Acceptance template", "state": "/system_settings/acceptance_template", 'featureName': '', 'access': '' },
                    ]}
            );
            data.shortcuts.unshift(
                {group: "Contracts", options: [
                        { "description": "Contract templates", "state": "/system_settings/contract-templates", 'featureName': 'SetupContractTemplates', 'access': 'readaccess' },
                        { "description": "Set up PPM", "state": "/system_settings/ppm", 'featureName': 'SetupPPM', 'access': 'writeaccess' },
                        { "description": "PPM job auto-creation", "state": "/system_settings/ppm-jobs-setup", 'featureName': 'SetupPPM', 'access': 'updateaccess' },
                        { "description": "Set up SLA", "state": "/system_settings/sla_template", 'featureName': 'SetupSLA', 'access': 'writeaccess' },
                        { "description": "Contract categories", "state": "/system_settings/contract-categories", 'featureName': 'ContractTemplateCategories', 'access': 'writeaccess' }
                    ]}
            );
            data.shortcuts.unshift(
                {group: "Assets", options: [
                    { "description": "Asset groups", "state": "loggedin.asset_groups", 'featureName': 'setupAssetGroupAndTypes', 'access': 'writeaccess' },
                    { "description": "Asset types", "state": "loggedin.asset_type", 'featureName': 'setupAssetGroupAndTypes', 'access': 'writeaccess' },
                    { "description": "Standard asset fields", "state": "loggedin.settings_standard_asset_fields", 'featureName': 'setupAssetFromConfiguration', 'access': 'updateaccess' },
                    { "description": "Asset dynamic fields", "state": "loggedin.asset_dynamic_list", 'featureName': 'setupAssetDropDownList', 'access': 'writeaccess' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "General Settings", options: [
                    { "description": "Diary Settings", "state": "loggedin.companydetails", 'featureName': 'Setupdiary', 'access': 'updateaccess' },
                    { "description": "Service Window", "state": "loggedin.service_windows", 'featureName': 'DiaryServiceWindows', 'access': 'readaccess' },
                    { "description": "Locations", "state": "loggedin.location", 'featureName': 'Setuplocations', 'access': 'writeaccess' },
                    { "description": "Fuel Types", "state": "loggedin.fueltype", 'featureName': 'Setupfueltypes', 'access': 'writeaccess' },
                    { "description": "Appliance Groups", "state": "loggedin.appliance_groups", 'featureName': 'Setupappliancegroups', 'access': 'writeaccess' },
                    { "description": "Appliance Types", "state": "loggedin.appliance_type", 'featureName': 'Setupappliancetypes', 'access': 'writeaccess' },
                    { "description": "Appliance Makes", "state": "loggedin.appliance_makes", 'featureName': 'Setupappliancemake', 'access': 'writeaccess' },
                    { "description": "Appliance Models", "state": "loggedin.appliance_models", 'featureName': 'Setupmodels', 'access': 'writeaccess' },
                    { "description": "Technical References", "state": "loggedin.technical_reference", 'featureName': 'Setuptechnicalreference', 'access': 'writeaccess' },
                    { "description": "Pricing Items", "state": "loggedin.pricing_items", 'featureName': 'Pricingitems', 'access': 'writeaccess' },
                    { "description": "Nominal Groups", "state": "loggedin.nominalgroups", 'featureName': 'Setupnominalgroups', 'access': 'writeaccess' },
                    { "description": "Nominal Accounts", "state": "loggedin.nominal_accounts", 'featureName': 'Setupnominalaccounts', 'access': 'writeaccess' },
                    { "description": "Types of Telephone Numbers", "state": "loggedin.telephonetypes", 'featureName': 'Setuptypeoftelephonenumbers', 'access': 'writeaccess' },
                    { "description": "Titles", "state": "loggedin.title", 'featureName': 'Setupcustomertitles', 'access': 'writeaccess' },
                    { "description": "Bcc emails", "state": "loggedin.setup_bcc_email_address", 'featureName': 'Bccemails', 'access': 'updateaccess' },
                    { "description": "Vat", "state": "loggedin.setup_vat", 'featureName': 'Setupvat', 'access': 'writeaccess' }
                ]}
            );
        }
        if(state == 'loggedin.company_settings')
        {
            data.shortcuts.unshift(
                {group: "Close Account", options: [
                    { "description": "Close Account", "state": "", 'featureName': '', 'access': '' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "Backups", options: [
                    { "description": "Manage Backups", "state": "loggedin.manage_backups", 'featureName': '', 'access': '' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "Integrations", options: [
                    { "description": "API Keys", "state": "loggedin.api_keys", 'featureName': 'Apikeys', 'access': 'updateaccess' },
                    { "description": "Credit Card", "state": "loggedin.credit_card", 'featureName': '', 'access': '' },
                    { "description": "Accounting Interfaces", "state": "loggedin.accounting_interfaces", 'featureName': 'Setupaccountinginterface', 'access': 'readaccess' },
                    { "description": "Integrations", "state": "loggedin.integrations", 'featureName': '', 'access': '' }
                ]}
            );
            // data.shortcuts.unshift(
            //     {group: "Account and Billing", options: [
            //         { "description": "Billing", "state": "", 'featureName': '', 'access': '' },
            //         { "description": "Account Plan", "state": "", 'featureName': '', 'access': '' },
            //         { "description": "Contract", "state": "", 'featureName': '', 'access': '' },
            //         { "description": "Delete Account", "state": "", 'featureName': '', 'access': '' }
            //     ]}
            // );
            data.shortcuts.unshift(
                {group: "Audits", options: [
                    { "description": "Audits", "state": "loggedin.audits", 'featureName': 'Audits', 'access': 'readaccess' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "Account and Billing", options: [
                    { "description": "Billing", "state": "loggedin.billing", 'featureName': 'Billing', 'access': "updateaccess"},
                    { "description": "Purchase trackers", "state": "loggedin.purchasetrackers", 'featureName': 'PurchaseTrackers', 'access': "updateaccess"}
/*
                    { "description": "Account Plan", "state": "loggedin.account_plan", 'featureName': 'Accountplan', 'access': "updateaccess"},
                    { "description": "Contract", "state": "loggedin.contract", 'featureName': 'Contract', 'access': "updateaccess"},
                    { "description": "Delete Account", "state": "loggedin.delete_account", 'featureName': 'Deleteaccount', 'access': "updateaccess"}
*/
                ]}
            );
            data.shortcuts.unshift(
                {group: "Users & Vehicles", options: [
                    { "description": "Users", "state": "loggedin.users", 'featureName': 'Users', 'access': 'updateaccess' },
                    { "description": "Vehicles", "state": "loggedin.vehicles", 'featureName': 'Vehicle', 'access': 'readaccess' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "General Settings", options: [
                    { "description": "Company Details", "state": "loggedin.companydetails", 'featureName': 'Companydetails', 'access': 'updateaccess' },
                    { "description": "CIS Percentage", "state": "loggedin.cis_percentage", 'featureName': 'Cispercentage', 'access': 'updateaccess' },
                    { "description": "Setup Email", "state": "loggedin.setup_email", 'featureName': 'Setupemail', 'access': 'readaccess' },
                    { "description": "Create Letterheads", "state": "loggedin.create_letterhead", 'featureName': 'Setupletterhead', 'access': 'updateaccess' },
                    { "description": "Upload Certificate Logos", "state": "loggedin.upload_certificate_logo", 'featureName': 'Setuplogocertificate', 'access': 'writeaccess' },
                    { "description": "VAT & Company Numbers", "state": "loggedin.companynumbers", 'featureName': 'Vatandcompanynumbers', 'access': 'updateaccess' },
                    { "description": "Bank Details", "state": "loggedin.bankdetails", 'featureName': 'Bankdetails', 'access': 'updateaccess' },
                    { "description": "Cheques Made Payable", "state": "loggedin.cheques", 'featureName': 'Chequesmadepayable', 'access': 'updateaccess' },
                    { "description": "Importing Data", "state": "loggedin.setup_email", 'featureName': 'Importingdata', 'access': 'readaccess' }
                ]}
            );

        }

        // This is common parameter for attached files tab.
        if((state == 'loggedin.customer_list.view.files') || (state == 'loggedin.list_supplier.view.files') ||(state == 'loggedin.users.view.files') ||(state == 'loggedin.customer_list.view.estimate.estimatefiles') ||(state == 'loggedin.list_supplier.view.purchase_order.purchaseorderfiles') ||(state == 'loggedin.list_supplier.view.invoice.files') ||(state == 'loggedin.list_supplier.view.credit_note.files'))
        {
            var attachedFile = {group: "", options: [
                { "description": "Preview File", "state": "false", parameters: {'screen': '', 'action': 'preview', 'parameters': ''}, 'featureName': '', 'access': 'readaccess' },
                { "description": "Delete File", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': '', 'access': 'deleteaccess' },
                { "description": "Download File", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''}, 'featureName': '', 'access': 'readaccess' }
            ]};
        }

        if((state == 'loggedin.customer_list.view.contacts'))
        {
            var currentRecord = selection.recordId
            var id = params.id;

            if(selection != ''){
                var mainContact = selection.rowObject.maincontact;
                var customerContactOptions = {group: "", options: [
                    { "description": "Set as Primary Contact", "state": "false", parameters: {'screen': '', 'action': 'primary', 'parameters': ''}, 'featureName': 'Customercontacts', 'access': 'updateaccess' },
                    { "description": "Edit", "state": "loggedin.customer_list.view.edit_contact", parameters: {id: id, contactsid: currentRecord, type: 'customer_list'}, 'featureName': 'Customercontacts', 'access': 'updateaccess' },
                    { "description": "Delete", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Customercontacts', 'access': 'deleteaccess' }
                ]};
            }else{
                var customerContactOptions = {group: "", options: [
                    { "description": "Edit", "state": "loggedin.customer_list.view.edit_contact", parameters: {id: id, contactsid: currentRecord, type: 'customer_list'}, 'featureName': 'Customercontacts', 'access': 'updateaccess' },
                    { "description": "Delete", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Customercontacts', 'access': 'deleteaccess' }
                ]};
            }
        }
        if((state == 'loggedin.list_supplier.view.contacts'))
        {
            var currentRecord = selection.recordId
            var id = params.id

            var supplierContactOptions = {group: "", options: [
                { "description": "Edit", "state": "loggedin.list_supplier.view.contacts_edit", parameters: {id: id, contactsid: currentRecord}, 'featureName': 'Suppliercontacts', 'access': 'updateaccess' },
                { "description": "Delete", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Suppliercontacts', 'access': 'deleteaccess' }
            ]};
        }
        if (state == 'loggedin.users') {// Based on the url we need to provide the options
            data.shortcuts.unshift(
                {group: "Users", options: [
                    { "description": "Add New User", "state": "loggedin.add_user", 'featureName': 'Users', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var userObj = selection.userObject;
                var userName = userObj.name;
                data.shortcuts.unshift(
                    {group: "Actions for User: " + userName, options: [
                        { "description": "Edit Login Details", "state": "loggedin.users.edit", parameters: {'screen': 'login', 'userDetails': angular.toJson(userObj)}, 'featureName': 'Users', 'access': 'updateaccess' },
                        { "description": "Edit Personal Details", "state": "loggedin.users.edit", parameters: {'screen': 'personal', 'userDetails': angular.toJson(userObj)}, 'featureName': 'Users', 'access': 'updateaccess' },
                        { "description": "Edit Business Details", "state": "loggedin.users.edit", parameters: {'screen': 'business', 'userDetails': angular.toJson(userObj)}, 'featureName': 'Users', 'access': 'updateaccess' },
                        { "description": "Edit Official Numbers", "state": "loggedin.users.edit", parameters: {'screen': 'official', 'userDetails': angular.toJson(userObj)}, 'featureName': 'Users', 'access': 'updateaccess' }
                    ]}
                );
            }
        }
        else if (state == 'loggedin.customer_list.view.files') {// Based on the url we need to provide the options

            // If you need pass the record value in URL then use this variable.
            var currentRecord = params.id;
            var type = params.type;

            data.shortcuts.unshift(
                {group: "Attached Files", options: [
                    { "description": "Add New File", "state": "loggedin.customer_list.view.new_file", parameters: {'id': currentRecord, 'type': type}, 'featureName': 'Customerattachedfiles', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var rowObj = selection.rowObject;
                var fileName = rowObj.name;

                // Copy the common parameter and assign dynamic values.
                var customer = JSON.parse(JSON.stringify(attachedFile));
                customer['group'] = "Actions for Attached File: " + fileName;
                customer['options'][0]['parameters']['screen'] = "CustomerAttachedFile";
                customer['options'][0]['featureName'] = "Customerattachedfiles";
                customer['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                customer['options'][1]['parameters']['screen'] = "CustomerAttachedFile";
                customer['options'][1]['featureName'] = "Customerattachedfiles";
                customer['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);
                customer['options'][2]['parameters']['screen'] = "CustomerAttachedFile";
                customer['options'][2]['featureName'] = "Customerattachedfiles";
                customer['options'][2]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(customer);
            }
        }
        //Customer Estimate Attached Files
        else if (state == 'loggedin.customer_list.view.estimate.estimatefiles') {// Based on the url we need to provide the options

            // If you need pass the record value in URL then use this variable.
            var currentRecord = params.id;
            var estimateId = params.estimateId;
            var type = params.type;

            data.shortcuts.unshift(
                {group: "Attached Files", options: [
                    { "description": "Add New File", "state": "loggedin.customer_list.view.estimate.new_estimate_file", parameters: {'id': currentRecord, 'estimateId': estimateId, 'type': type}, 'featureName': 'Estimatesattachedfiles', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var rowObj = selection.rowObject;
                var fileName = rowObj.name;

                // Copy the common parameter and assign dynamic values.
                var customer = JSON.parse(JSON.stringify(attachedFile));
                customer['group'] = "Actions for Attached File: " + fileName;
                customer['options'][0]['parameters']['screen'] = "EstimatesAttachedFiles";
                customer['options'][0]['featureName'] = "Estimatesattachedfiles";
                customer['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                customer['options'][1]['parameters']['screen'] = "EstimatesAttachedFiles";
                customer['options'][1]['featureName'] = "Estimatesattachedfiles";
                customer['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);
                customer['options'][2]['parameters']['screen'] = "EstimatesAttachedFiles";
                customer['options'][2]['featureName'] = "Estimatesattachedfiles";
                customer['options'][2]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(customer);
            }
        }
        //Customer Estimate Reminders
        else if (state == 'loggedin.customer_list.view.estimate.estimate_reminders') {// Based on the url we need to provide the options

            // If you need pass the record value in URL then use this variable.
            var currentRecord = params.id;
            var estimateId = params.estimateId;
            var type = params.type;

            data.shortcuts.unshift(
                {group: "Add New Reminder", options: [
                    { "description": "Add New Reminder", "state": "loggedin.customer_list.view.estimate.new_estimate_reminder", parameters: {'id': currentRecord, 'estimateId': estimateId, 'type': type}, 'featureName': 'Customerestimatereminders', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var rowObj = selection.rowObject;
                var fileName = rowObj.name;

                // Copy the common parameter and assign dynamic values.
                var customer = JSON.parse(JSON.stringify(estimateReminders));
                customer['group'] = "Actions for Estimate Reminders: " + fileName;
                customer['options'][0]['parameters']['screen'] = "EstimateReminders";
                customer['options'][0]['featureName'] = "Customerestimatereminders";
                customer['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(customer);
            }
        }
        else if (state == 'loggedin.list_supplier.view.files') {// Based on the url we need to provide the options

            // If you need pass the record value in URL then use this variable.
            var currentRecord =  params.id;

            data.shortcuts.unshift(
                {group: "Attached Files", options: [
                    { "description": "Add New File", "state": "loggedin.list_supplier.view.new_file", parameters: {'id': currentRecord}, 'featureName': 'Suppliersattachedfiles', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var rowObj = selection.rowObject;
                var fileName = rowObj.name;

                // Copy the common parameter and assign dynamic values.
                var supplier = JSON.parse(JSON.stringify(attachedFile));
                supplier['group'] = "Actions for Attached File: " + fileName;
                supplier['options'][0]['parameters']['screen'] = "SuppliersAttachedFile";
                supplier['options'][0]['featureName'] = "Suppliersattachedfiles";
                supplier['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                supplier['options'][1]['parameters']['screen'] = "SuppliersAttachedFile";
                supplier['options'][1]['featureName'] = "Suppliersattachedfiles";
                supplier['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);
                supplier['options'][2]['parameters']['screen'] = "SuppliersAttachedFile";
                supplier['options'][2]['featureName'] = "Suppliersattachedfiles";
                supplier['options'][2]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(supplier);
            }
        }
        //Purchase Order Attached Files
        else if (state == 'loggedin.list_supplier.view.purchase_order.purchaseorderfiles') {// Based on the url we need to provide the options

            // If you need pass the record value in URL then use this variable.
            var currentRecord = params.id;
            var purchaseOrderId = params.purchaseOrderId;

            data.shortcuts.unshift(
                {group: "Attached Files", options: [
                    { "description": "Add New File", "state": "loggedin.list_supplier.view.purchase_order.new_purchaseorder_files", parameters: {'id': currentRecord, 'purchaseOrderId': purchaseOrderId}, 'featureName': 'Purchaseorderattachedfiles', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var rowObj = selection.rowObject;
                var fileName = rowObj.name;

                // Copy the common parameter and assign dynamic values.
                var supplier = JSON.parse(JSON.stringify(attachedFile));
                supplier['group'] = "Actions for Attached File: " + fileName;
                supplier['options'][0]['parameters']['screen'] = "PurchaseOrderAttachedFiles";
                supplier['options'][0]['featureName'] = "Purchaseorderattachedfiles";
                supplier['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                supplier['options'][1]['parameters']['screen'] = "PurchaseOrderAttachedFiles";
                supplier['options'][1]['featureName'] = "Purchaseorderattachedfiles";
                supplier['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);
                supplier['options'][2]['parameters']['screen'] = "PurchaseOrderAttachedFiles";
                supplier['options'][2]['featureName'] = "Purchaseorderattachedfiles";
                supplier['options'][2]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(supplier);
            }
        }
        //Supplier Invoice Attached Files
        else if (state == 'loggedin.list_supplier.view.invoice.files') {// Based on the url we need to provide the options

            // If you need pass the record value in URL then use this variable.
            var currentRecord = params.id;
            var invoiceId = params.invoiceId;

            data.shortcuts.unshift(
                {group: "Attached Files", options: [
                    { "description": "Add New File", "state": "loggedin.list_supplier.view.invoice.new_file", parameters: {'id': currentRecord, 'invoiceId': invoiceId}, 'featureName': 'Supplierinvoiceattachedfiles', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var rowObj = selection.rowObject;
                var fileName = rowObj.name;

                // Copy the common parameter and assign dynamic values.
                var supplier = JSON.parse(JSON.stringify(attachedFile));
                supplier['group'] = "Actions for Attached File: " + fileName;
                supplier['options'][0]['parameters']['screen'] = "SupplierInvoiceAttachedFiles";
                supplier['options'][0]['featureName'] = "Supplierinvoiceattachedfiles";
                supplier['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                supplier['options'][1]['parameters']['screen'] = "SupplierInvoiceAttachedFiles";
                supplier['options'][1]['featureName'] = "Supplierinvoiceattachedfiles";
                supplier['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);
                supplier['options'][2]['parameters']['screen'] = "SupplierInvoiceAttachedFiles";
                supplier['options'][2]['featureName'] = "Supplierinvoiceattachedfiles";
                supplier['options'][2]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(supplier);
            }
        }
        //Supplier Credit Note Attached Files
        else if (state == 'loggedin.list_supplier.view.credit_note.files') {// Based on the url we need to provide the options

            // If you need pass the record value in URL then use this variable.
            var currentRecord = params.id;
            var creditNoteId = params.creditNoteId;

            data.shortcuts.unshift(
                {group: "Attached Files", options: [
                    { "description": "Add New File", "state": "loggedin.list_supplier.view.credit_note.new_creditnote_files", parameters: {'id': currentRecord, 'creditNoteId': creditNoteId}, 'featureName': 'Suppliercreditnoteattachedfiles', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var rowObj = selection.rowObject;
                var fileName = rowObj.name;

                // Copy the common parameter and assign dynamic values.
                var supplier = JSON.parse(JSON.stringify(attachedFile));
                supplier['group'] = "Actions for Attached File: " + fileName;
                supplier['options'][0]['parameters']['screen'] = "CreditNoteAttachedFiles";
                supplier['options'][0]['featureName'] = "Suppliercreditnoteattachedfiles";
                supplier['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                supplier['options'][1]['parameters']['screen'] = "CreditNoteAttachedFiles";
                supplier['options'][1]['featureName'] = "Suppliercreditnoteattachedfiles";
                supplier['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);
                supplier['options'][2]['parameters']['screen'] = "CreditNoteAttachedFiles";
                supplier['options'][2]['featureName'] = "Suppliercreditnoteattachedfiles";
                supplier['options'][2]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(supplier);
            }
        }
        //Jobs Attached Files
        else if (state == 'loggedin.customer_list.view.job.files') {// Based on the url we need to provide the options

            // If you need pass the record value in URL then use this variable.
            var currentRecord = params.id;
            var type = params.type;
            var jobId = params.jobId;

            data.shortcuts.unshift(
                {group: "Attached Files", options: [
                    { "description": "Add New File", "state": "loggedin.customer_list.view.job.new_file", parameters: {'id': currentRecord, 'type': type, 'jobId': jobId}, 'featureName': 'Jobattachedfiles', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var rowObj = selection.rowObject;
                var fileName = rowObj.name;

                // Copy the common parameter and assign dynamic values.
                var jobs = JSON.parse(JSON.stringify(attachedFile));
                jobs['group'] = "Actions for Attached File: " + fileName;
                jobs['options'][0]['parameters']['screen'] = "JobsAttachedFiles";
                jobs['options'][0]['featureName'] = "Jobattachedfiles";
                jobs['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                jobs['options'][1]['parameters']['screen'] = "JobsAttachedFiles";
                jobs['options'][1]['featureName'] = "Jobattachedfiles";
                jobs['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);
                jobs['options'][2]['parameters']['screen'] = "JobsAttachedFiles";
                jobs['options'][2]['featureName'] = "Jobattachedfiles";
                jobs['options'][2]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(jobs);
            }
        }
        else if (state == 'loggedin.users.view.files') {// Based on the url we need to provide the options

           // If you need pass the record value in URL then use this variable.
            var currentRecord =  params.id;

            data.shortcuts.unshift(
                {group: "Attached Files", options: [
                    { "description": "Add New File", "state": "loggedin.users.view.new_file", parameters: {'id': currentRecord}, 'featureName': 'Userattachedfiles', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var rowObj = selection.rowObject;
                var fileName = rowObj.name;

                // Copy the common parameter and assign dynamic values.
                var user = JSON.parse(JSON.stringify(attachedFile));
                user['group'] = "Actions for Attached File: " + fileName;
                user['options'][0]['parameters']['screen'] = "UserAttachedFile";
                user['options'][0]['featureName'] = "Userattachedfiles";
                user['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                user['options'][1]['parameters']['screen'] = "UserAttachedFile";
                user['options'][1]['featureName'] = "Userattachedfiles";
                user['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);
                user['options'][2]['parameters']['screen'] = "UserAttachedFile";
                user['options'][2]['featureName'] = "Userattachedfiles";
                user['options'][2]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(user);
            }
        }
        else if (state == 'loggedin.customer_list.view.contacts') {// Based on the url we need to provide the options

            // If you need pass the record value in URL then use this variable.
            var currentRecord = selection.recordId
            var id = params.id
            data.shortcuts.unshift(
                {group: "Customer Contacts", options: [
                    { "description": "Add New Contact", "state": "loggedin.customer_list.view.new_contact", parameters: {type: 'customer_list', id: id}, 'featureName': 'Customercontacts', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var rowObj = selection.rowObject;
                var fileName = rowObj.name + ' ' + rowObj.surname;
                var mainContact = rowObj.maincontact;

                    if(mainContact == null){
                        if(rowObj.name != null){
                            var fileName = rowObj.name + ' ' + rowObj.surname;
                        }else{
                            var fileName = rowObj.surname;
                        }
                        // Copy the common parameter and assign dynamic values.
                        var customerContact = JSON.parse(JSON.stringify(customerContactOptions));
                        customerContact['group'] = "Actions for Customer Contact: " + fileName;
                        customerContact['options'][0]['parameters']['screen'] = "CustomerContacts";
                        customerContact['options'][0]['parameters']['featureName'] = "Customercontacts";
                        customerContact['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                        customerContact['options'][1]['parameters']['screen'] = "CustomerContacts";
                        customerContact['options'][1]['parameters']['featureName'] = "Customercontacts";
                        customerContact['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);
                        customerContact['options'][2]['parameters']['screen'] = "CustomerContacts";
                        customerContact['options'][2]['parameters']['featureName'] = "Customercontacts";
                        customerContact['options'][2]['parameters']['parameters'] = angular.toJson(rowObj);
                        data.shortcuts.unshift(customerContact);
                    }else{
                        // Copy the common parameter and assign dynamic values.
                        var customerContact = JSON.parse(JSON.stringify(customerContactOptions));
                        customerContact['group'] = "Actions for Customer Contact: " + fileName;
                        customerContact['options'][0]['parameters']['screen'] = "CustomerContacts";
                        customerContact['options'][0]['parameters']['featureName'] = "Customercontacts";
                        customerContact['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                        customerContact['options'][1]['parameters']['screen'] = "CustomerContacts";
                        customerContact['options'][1]['parameters']['featureName'] = "Customercontacts";
                        customerContact['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);
                        data.shortcuts.unshift(customerContact);
                    }
            }
        }
        else if (state == 'loggedin.list_supplier.view.contacts') {// Based on the url we need to provide the options

            // If you need pass the record value in URL then use this variable.
            var currentRecord = selection.recordId
            var id = params.id
            data.shortcuts.unshift(
                {group: "Supplier Contacts", options: [
                    { "description": "Add New Contact", "state": "loggedin.list_supplier.view.contacts_new", parameters: {type: 'list_supplier', id: id}, 'featureName': 'Suppliercontacts', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var rowObj = selection.rowObject;
                var fileName = rowObj.name;

                // Copy the common parameter and assign dynamic values.
                var supplierContact = JSON.parse(JSON.stringify(supplierContactOptions));
                supplierContact['group'] = "Actions for Supplier Contact: " + fileName;
                supplierContact['options'][0]['parameters']['screen'] = "SupplierContacts";
                supplierContact['options'][0]['parameters']['featureName'] = "Suppliercontacts";
                supplierContact['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                supplierContact['options'][1]['parameters']['screen'] = "SupplierContacts";
                supplierContact['options'][1]['parameters']['featureName'] = "Suppliercontacts";
                supplierContact['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);
                data.shortcuts.unshift(supplierContact);
            }
        }

        if ((state == 'loggedin.customer_list.view.notes.table') || (state == 'loggedin.list_supplier.view.notes.table') || (state == 'loggedin.list_supplier.view.notes.timeline') || (state == 'loggedin.customer_list.view.notes.timeline') || (state == 'loggedin.list_supplier.view.credit_note.notes.table') || (state == 'loggedin.list_supplier.view.credit_note.notes.timeline') || (state == 'loggedin.list_supplier.view.invoice.notes.table') || (state == 'loggedin.list_supplier.view.invoice.notes.timeline') || (state == 'loggedin.customer_list.view.job.notes.table') || (state == 'loggedin.customer_list.view.job.notes.timeline') || (state == 'loggedin.list_supplier.view.purchase_order.notes.table') || (state == 'loggedin.list_supplier.view.purchase_order.notes.timeline') || (state == 'loggedin.invoice_address.view.notes.table') || (state == 'loggedin.invoice_address.view.notes.timeline')) {

            var notesprintanddownload = {group: "", options: [
                { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }

            ]};
            var rowObj = selection.rowObject;
            var printanddownload = JSON.parse(JSON.stringify(notesprintanddownload));
            printanddownload['group'] = "Print and Download for Note";
            printanddownload['options'][0]['parameters']['screen'] = "NotesPrintandDownload";
            printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
            printanddownload['options'][1]['parameters']['screen'] = "NotesPrintandDownload";
            printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

            data.shortcuts.unshift(printanddownload);
        }

        //This is for Estimate Notes and Communications Print and Download
        if ((state == 'loggedin.customer_list.view.estimate.notes.table' || state == 'loggedin.customer_list.view.estimate.notes.timeline')) {
            var notesprintanddownload = {group: "", options: [
                { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }

            ]};
            var rowObj = selection.rowObject;
            var printanddownload = JSON.parse(JSON.stringify(notesprintanddownload));
            printanddownload['group'] = "Print and Download for Note";
            printanddownload['options'][0]['parameters']['screen'] = "NotesPrintandDownload";
            printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
            printanddownload['options'][1]['parameters']['screen'] = "NotesPrintandDownload";
            printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

            data.shortcuts.unshift(printanddownload);
        }


        // This is parameter for Supplier Notes and communications tab.
        if(state == 'loggedin.list_supplier.view.notes.table')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.list_supplier.view.notes_new", parameters: {'id': currentRecord,'type':'list_supplier','mode':'table'}, 'featureName': 'Suppliernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.list_supplier.view.new_phone_call", parameters: {'id': currentRecord,'type':'list_supplier','mode':'table'}, 'featureName': 'Suppliernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.list_supplier.view.new_sms_message", parameters: {'id': currentRecord,'type':'list_supplier','mode':'table'}, 'featureName': 'Suppliernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'SupplierNotes', 'action': 'sendEmail', 'featureName': 'Suppliernotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );

            if (selection != '') {

                //edit section
                var notesid =selection.rowObject.id;
                var title =selection.rowObject.title;
                data.shortcuts.unshift(
                    {group: "Supplier Notes: " + title, options: [
                        { "description": "Edit Note", "state": "loggedin.list_supplier.view.notes_edit", parameters: {'id': currentRecord,'notesid':notesid,'mode':'table'}, 'featureName': 'Suppliernotesandcommunications', 'access': 'updateaccess' }
                    ]}
                );
                var notesdelete = {group: "", options: [
                    { "description": "Delete Note", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Suppliernotesandcommunications', 'access': 'deleteaccess' }
                ]};

                //delete section
                var rowObj = selection.rowObject;
                var supplierdelete = JSON.parse(JSON.stringify(notesdelete));
                supplierdelete['group'] = "Delete for Supplier Note: " + title;
                supplierdelete['options'][0]['parameters']['screen'] = "SupplierNotes";
                supplierdelete['options'][0]['parameters']['featureName'] = "Suppliernotesandcommunications";
                supplierdelete['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(supplierdelete);
            }

        }
        if(state == 'loggedin.list_supplier.view.notes.timeline')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.list_supplier.view.notes_new", parameters: {'id': currentRecord,'type':'list_supplier','mode':'timeline'}, 'featureName': 'Suppliernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.list_supplier.view.new_phone_call", parameters: {'id': currentRecord,'type':'list_supplier','mode':'timeline'}, 'featureName': 'Suppliernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.list_supplier.view.new_sms_message", parameters: {'id': currentRecord,'type':'list_supplier','mode':'timeline'}, 'featureName': 'Suppliernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'SupplierNotes', 'action': 'sendEmail', 'featureName': 'Suppliernotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );
        }
        if(state == 'loggedin.customer_list.view.notes.timeline')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var customerTabName=params.type;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.customer_list.view.notes_new", parameters: {'id': currentRecord,'type':customerTabName,'mode':'timeline'}, 'featureName': 'Customernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.customer_list.view.new_phone_call", parameters: {'id': currentRecord,'type':customerTabName,'mode':'timeline'}, 'featureName': 'Customernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.customer_list.view.new_sms_message", parameters: {'id': currentRecord,'type':customerTabName,'mode':'timeline'}, 'featureName': 'Customernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'CustomerNotes', 'action': 'sendEmail', 'featureName': 'Customernotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );
        }
        //This is for Estimates Notes Timeline view
        if(state == 'loggedin.customer_list.view.estimate.notes.timeline')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var estimateId = params.estimateId;
            var customerTabName=params.type;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.customer_list.view.estimate.notes_new", parameters: {'id': currentRecord, 'estimateId': estimateId, 'type':customerTabName,'mode':'timeline'}, 'featureName': 'Estimatenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.customer_list.view.estimate.new_phonecall", parameters: {'id': currentRecord, 'estimateId': estimateId, 'type':customerTabName,'mode':'timeline'}, 'featureName': 'Estimatenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.customer_list.view.estimate.new_smsmessage", parameters: {'id': currentRecord, 'estimateId': estimateId, 'type':customerTabName,'mode':'timeline'}, 'featureName': 'Estimatenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'EstimateNotes', 'action': 'sendEmail', 'featureName': 'Estimatenotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );
        }

        // This is parameter for Api Keys Screen.
        if (state == 'loggedin.api_keys') {

            if (selection != '') {
                var apikeyid = selection.rowObject.id;
                var apikeydelete = {group: "", options: [
                    { "description": "Delete Api Key", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Apikeys', 'access': 'deleteaccess' }
                ]};

                //delete section
                var rowObj = selection.rowObject;
                var deleteapikey = JSON.parse(JSON.stringify(apikeydelete));
                deleteapikey['group'] = "Delete for Api Key";
                deleteapikey['options'][0]['parameters']['screen'] = "APIKeys";
                deleteapikey['options'][0]['parameters']['featureName'] = "Apikeys";
                deleteapikey['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(deleteapikey);
            }
        }


        // This is parameter for Customer Notes and communications tab.
        if(state == 'loggedin.customer_list.view.notes.table')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var customerTabName=params.type;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.customer_list.view.notes_new", parameters: {'id': currentRecord,'type':customerTabName,'mode':'table'}, 'featureName': 'Customernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.customer_list.view.new_phone_call", parameters: {'id': currentRecord,'type':customerTabName,'mode':'table'}, 'featureName': 'Customernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.customer_list.view.new_sms_message", parameters: {'id': currentRecord,'type':customerTabName,'mode':'table'}, 'featureName': 'Customernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'CustomerNotes', 'action': 'sendEmail', 'featureName': 'Customernotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );

            if (selection != '') {

                //edit section
                var notesid =selection.rowObject.id;
                var title =selection.rowObject.title;
                data.shortcuts.unshift(
                    {group: "Customer Notes: " + title, options: [
                        { "description": "Edit Note", "state": "loggedin.customer_list.view.notes_edit", parameters: {'id': currentRecord,'notesid':notesid,'type':'customer_list','mode':'table'}, 'featureName': 'Customernotesandcommunications', 'access': 'updateaccess' }
                    ]}
                );
                var notesdelete = {group: "", options: [
                    { "description": "Delete Note", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Customernotesandcommunications', 'access': 'deleteaccess' }
                ]};

                //delete section
                var rowObj = selection.rowObject;
                var customerdelete = JSON.parse(JSON.stringify(notesdelete));
                customerdelete['group'] = "Delete for Customer Note: " + title;
                customerdelete['options'][0]['parameters']['screen'] = "CustomerNotes";
                customerdelete['options'][0]['parameters']['featureName'] = "Customernotesandcommunications";
                customerdelete['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(customerdelete);
            }

        }

        // This is parameter for Estimate Notes and communications tab.
        if(state == 'loggedin.customer_list.view.estimate.notes.table')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var estimateId = params.estimateId;
            var customerTabName=params.type;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.customer_list.view.estimate.notes_new", parameters: {'id': currentRecord, 'estimateId': estimateId, 'type':customerTabName,'mode':'table'}, 'featureName': 'Estimatenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.customer_list.view.estimate.new_phonecall", parameters: {'id': currentRecord,'estimateId': estimateId, 'type':customerTabName,'mode':'table'}, 'featureName': 'Estimatenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.customer_list.view.estimate.new_smsmessage", parameters: {'id': currentRecord, 'estimateId': estimateId, 'type':customerTabName,'mode':'table'}, 'featureName': 'Estimatenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'EstimateNotes', 'action': 'sendEmail', 'featureName': 'Estimatenotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );

            if (selection != '') {

                //edit section
                var notesid =selection.rowObject.id;
                var title =selection.rowObject.title;
                data.shortcuts.unshift(
                    {group: "Estimate Notes: " + title, options: [
                        { "description": "Edit Note", "state": "loggedin.customer_list.view.estimate.notes_edit", parameters: {'id': currentRecord,'estimateId': estimateId, 'notesid':notesid,'type':'customer_list','mode':'table'}, 'featureName': 'Estimatenotesandcommunications', 'access': 'updateaccess' }
                    ]}
                );
                var notesdelete = {group: "", options: [
                    { "description": "Delete Note", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Estimatenotesandcommunications', 'access': 'deleteaccess' }
                ]};

                //delete section
                var rowObj = selection.rowObject;
                var customerdelete = JSON.parse(JSON.stringify(notesdelete));
                customerdelete['group'] = "Delete for Estimate Note: " + title;
                customerdelete['options'][0]['parameters']['screen'] = "EstimateNotes";
                customerdelete['options'][0]['parameters']['featureName'] = "EstimateNotes";
                customerdelete['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(customerdelete);
            }

        }

        if((state == 'loggedin.customer_list.view.branches') ||(state == 'loggedin.list_supplier.view.branches')){
            var branchesprintanddownload = {group: "", options: [
                { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }

            ]};
            var rowObj = selection.rowObject;
            var printanddownload = JSON.parse(JSON.stringify(branchesprintanddownload));
            printanddownload['group'] = "Print and Download for Branches";
            printanddownload['options'][0]['parameters']['screen'] = "BranchesPrintandDownload";
            printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
            printanddownload['options'][1]['parameters']['screen'] = "BranchesPrintandDownload";
            printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

            data.shortcuts.unshift(printanddownload);
        }


        // This is parameter for Customer Branch tab.
        if(state == 'loggedin.customer_list.view.branches')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var type = params.type;
            data.shortcuts.unshift(
                {group: "Add Customer Branches", options: [
                    { "description": "Add New Branch", "state": "loggedin.customer_list.view.new_branch", parameters: {'id': currentRecord, 'type': type }, 'featureName': 'Customerbranches', 'access': 'writeaccess' }
                ]}
            );

            if (selection != '') {

                //edit section
                var branchid = selection.rowObject.id;
                var title = selection.rowObject.branchname;
                var type = params.type;
                var option = selection.rowObject.option;
                data.shortcuts.unshift(
                    {group: "Customer Branches: " + title, options: [
                        { "description": "Edit Branch", "state": "loggedin.customer_list.view.edit_branch", parameters: {'id': currentRecord, 'branchId': branchid, 'type': type}, 'featureName': 'Customerbranches', 'access': 'updateaccess' }
                    ]}
                );

                if(option == true){
                    var branchdelete = {group: "", options: [
                        { "description": "Delete Branch", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Customerbranches', 'access': 'deleteaccess' }
                    ]};

                    //delete section
                    var rowObj = selection.rowObject;
                    var customerbranchdelete = JSON.parse(JSON.stringify(branchdelete));
                    customerbranchdelete['group'] = "Delete for Customer Branch: " + title;
                    customerbranchdelete['options'][0]['parameters']['screen'] = "CustomerBranches";
                    customerbranchdelete['options'][0]['parameters']['featureName'] = "Customerbranches";
                    customerbranchdelete['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                    data.shortcuts.unshift(customerbranchdelete);
                }
            }
        }

        if((state == 'loggedin.customer_list.view.workaddresses')){
            var workaddressprintanddownload = {group: "", options: [
                { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }

            ]};
            var rowObj = selection.rowObject;
            var printanddownload = JSON.parse(JSON.stringify(workaddressprintanddownload));
            printanddownload['group'] = "Print and Download for Work Address";
            printanddownload['options'][0]['parameters']['screen'] = "WorkAddressPrintandDownload";
            printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
            printanddownload['options'][1]['parameters']['screen'] = "WorkAddressPrintandDownload";
            printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

            data.shortcuts.unshift(printanddownload);
        }


        // This is parameter for Customer WorkAddress tab.
        if(state == 'loggedin.customer_list.view.workaddresses')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var type = params.type;
            var state ='/customers/'+type+'/'+currentRecord+'/workaddress/new';
            data.shortcuts.unshift(
                {group: "Add Customer Work Address", options: [
                    { "description": "Add New Work Address", "state": state, 'featureName': 'CustomerWorkAddress', 'access': 'writeaccess' }
                ]}
            );
        }

        // This is parameter for Supplier Branch tab.
        if(state == 'loggedin.list_supplier.view.branches')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var type = params.type;

            data.shortcuts.unshift(
                {group: "Add Supplier Branches", options: [
                    { "description": "Add New Branch", "state": "loggedin.list_supplier.view.new_branch", parameters: {'id': currentRecord,'type': type}, 'featureName': 'Supplierbranches', 'access': 'writeaccess' }
                ]}
            );

            if (selection != '') {

                //edit section
                var branchid = selection.rowObject.id;
                var title = selection.rowObject.branchname;
                var option = selection.rowObject.option;
                var type = params.type;
                data.shortcuts.unshift(
                    {group: "Supplier Branches: " + title, options: [
                        { "description": "Edit Branch", "state": "loggedin.list_supplier.view.edit_branch", parameters: {'id': currentRecord, 'branchId': branchid, 'type': type}, 'featureName': 'Supplierbranches', 'access': 'updateaccess' }
                    ]}
                );

                if(option == true){
                    var branchdelete = {group: "", options: [
                        { "description": "Delete Branch", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Supplierbranches', 'access': 'deleteaccess' }
                    ]};

                    //delete section
                    var rowObj = selection.rowObject;
                    var supplierbranchdelete = JSON.parse(JSON.stringify(branchdelete));
                    supplierbranchdelete['group'] = "Delete for Supplier Branch: " + title;
                    supplierbranchdelete['options'][0]['parameters']['screen'] = "SupplierBranches";
                    supplierbranchdelete['options'][0]['parameters']['featureName'] = "Supplierbranches";
                    supplierbranchdelete['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                    data.shortcuts.unshift(supplierbranchdelete);
                }
            }
        }
        if (state == 'loggedin.suppliers') {// Based on the url we need to provide the options

            data.shortcuts.unshift(
                {group: "Supplier", options: [
                    { "description": "Add New Supplier", "state": "loggedin.new_supplier", 'featureName': 'Suppliers', 'access': 'writeaccess' },
                    { "description": "View All Suppliers", "state": "loggedin.list_supplier", 'featureName': 'Suppliers', 'access': 'readaccess' }
                ]}
            );
        }
        if (state == 'loggedin.list_supplier') {// Based on the url we need to provide the options

            data.shortcuts.unshift(
                {group: "Supplier", options: [
                    { "description": "Suppliers", "state": "loggedin.suppliers", 'featureName': 'Suppliers', 'access': 'readaccess' },
                    { "description": "Add New Supplier", "state": "loggedin.new_supplier", 'featureName': 'Suppliers', 'access': 'writeaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var supplierObj = selection.rowObject;
                var supplierName = supplierObj.companyname;
                var supplierId = supplierObj.id;
                data.shortcuts.unshift(
                    {group: "Actions for Supplier: " + supplierName, options: [
                        { "description": "Supplier Details", "state": "loggedin.list_supplier.view.details", parameters: {id: supplierId}, 'featureName': 'Suppliers', 'access': 'readaccess' },
                        { "description": "Edit Supplier", "state": "loggedin.list_supplier.view.details_edit", parameters: {id: supplierId}, 'featureName': 'Suppliers', 'access': 'updateaccess' }
                    ]}
                );
            } else {
                var supplierprintanddownload = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} },
                    { "description": "Name Up", "state": "false", parameters: {'screen': '', 'action': 'nameAsc', 'parameters': ''} },
                    { "description": "Name Down ", "state": "false", parameters: {'screen': '', 'action': 'nameDesc', 'parameters': ''} },
                    { "description": "Address Line 1 Up", "state": "false", parameters: {'screen': '', 'action': 'nameAsc', 'parameters': ''} },
                    { "description": "Address Line 1 Down ", "state": "false", parameters: {'screen': '', 'action': 'nameDesc', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(supplierprintanddownload));
                printanddownload['group'] = "Actions for Supplier";
                printanddownload['options'][0]['parameters']['screen'] = "SupplierPrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "SupplierPrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][2]['parameters']['screen'] = "SupplierPrintandDownload";
                printanddownload['options'][2]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][3]['parameters']['screen'] = "SupplierPrintandDownload";
                printanddownload['options'][3]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][4]['parameters']['screen'] = "SupplierPrintandDownload";
                printanddownload['options'][4]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][5]['parameters']['screen'] = "SupplierPrintandDownload";
                printanddownload['options'][5]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }


        }
        if (state == 'loggedin.list_supplier.view.details') {// Based on the url we need to provide the options
            var currentRecord=params.id;


            data.shortcuts.unshift(
                {group: "Supplier", options: [
                    { "description": "Suppliers", "state": "loggedin.suppliers", 'featureName': 'Suppliers', 'access': 'readaccess' },
                    { "description": "Edit Supplier", "state": "loggedin.list_supplier.view.details_edit", parameters: {id: currentRecord}, 'featureName': 'Suppliers', 'access': 'updateaccess' },
                    { "description": "Notes", "state": "loggedin.list_supplier.view.notes.table", parameters: {id: currentRecord}, 'featureName': 'Suppliernotesandcommunications', 'access': 'readaccess' },
                    { "description": "Contacts", "state": "loggedin.list_supplier.view.contacts", parameters: {id: currentRecord}, 'featureName': 'Suppliercontacts', 'access': 'readaccess' },
                    { "description": "Branches", "state": "loggedin.list_supplier.view.branches", parameters: {id: currentRecord}, 'featureName': 'Supplierbranches', 'access': 'readaccess' },
                    { "description": "Attached Files", "state": "loggedin.list_supplier.view.files", parameters: {id: currentRecord}, 'featureName': 'Suppliersattachedfiles', 'access': 'readaccess' },
                    { "description": "Add new purchase order", "state": "loggedin.list_supplier.view.new_purchase_order", parameters: {id: currentRecord}, 'featureName': 'Purchaseorder', 'access': 'write' },
                    { "description": "Add new invoice", "state": "loggedin.list_supplier.view.new_supplier_invoice", parameters: {id: currentRecord}, 'featureName': '', 'access': 'write' },
                    { "description": "Add new credit note", "state": "loggedin.list_supplier.view.new_credit_note", parameters: {id: currentRecord}, 'featureName': '', 'access': 'write' }
                ]}
            );

            if (selection != '') {
            // If something is selected we need to show the options for the selected item

                var purchaseOrderId =  selection.rowObject.id;

                var title =selection.rowObject.description;

                data.shortcuts.unshift(
                    {group: "Purchase Order: " + title, options: [
                        { "description": "View Purchase Order", "state": "loggedin.list_supplier.view.purchase_order.details", parameters: {'id': currentRecord,'purchaseOrderId':purchaseOrderId,}, 'featureName': '', 'access': 'readaccess' }
                    ]}
                );
            }
        }
        if (state == 'loggedin.customers') {// Based on the url we need to provide the options
            for(var i = 0; i < this.data.length; i++) {
                var groupName = 'Customer';
                var id = this.data[i].id;
                var state = '/customers/new_customer/'+id+'/new';
                var companyNameRequired = this.data[i].companyNameRequired;
                var description = this.data[i].description;

                if(companyNameRequired){
                    state = '/customers/new_company/'+id+'/new';
                    groupName = 'Company';
                }
                var groupObj = this.groupExist(groupName, data.shortcuts);
                if(groupObj) {
                    groupObj.options.push({ "description": 'New ' + description, "state": state,'featureName': 'Customersandworkaddresses', 'access': 'writeaccess' });
                } else {
                    data.shortcuts.unshift(
                        {group: groupName, options: [
                            { "description": 'New '+ description, "state": state,  'featureName': 'Customersandworkaddresses', 'access': 'writeaccess' }
                        ]}
                    );
                }
            }
            data.shortcuts.unshift(
                {group: "Customer Statistics", options: [
                    { "description": "View All Properties", "state": "loggedin.customer_list", 'featureName': 'Customersandworkaddresses', 'access': 'updateaccess' },
                    { "description": "Total Number of Customers", "state": "loggedin.customer", 'featureName': 'Customersandworkaddresses', 'access': 'updateaccess' },
                    { "description": "Total Number of Work Addresses", "state": "loggedin.workaddresses", parameters: {id: currentRecord}, 'featureName': 'Customersandworkaddresses', 'access': 'updateaccess' }
                ]}
            );
            data.shortcuts.unshift(
                {group: "Service Reminders", options: [
                    { "description": "View All Service Reminders", "state": "loggedin.service_reminders", 'featureName': 'Servicereminderreports', 'access': 'updateaccess' },
                    { "description": "Service Reminders Due in the Next 30 Days", "state": "loggedin.service_reminders_due_days", 'featureName': 'Serviceremindersduein30dayslist', 'access': 'readaccess' },
                    { "description": "Service Reminders Number of Phone Calls That Need Making", "state": "loggedin.service_reminders_phone_call", 'featureName': 'Servicereminderphonecalllist', 'access': 'readaccess' }
                ]}
            );
        }
        if (state == 'loggedin.customer_list') {// Based on the url we need to provide the options

            data.shortcuts.unshift(
                {group: "Customer", options: [
                    { "description": "Customers", "state": "loggedin.customers", 'featureName': 'Customersandworkaddresses', 'access': 'readaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var customerObj = selection.rowObject;
                var customerName = customerObj.name;
                var customerSurname = customerObj.surname;
                var companyName = customerObj.companyname;
                var customerType = customerObj.customerType;

                if(customerName == null){
                    customerName = '';
                }
                if(customerSurname == null){
                    customerSurname = '';
                }
                if(companyName == null){
                    companyName = '';
                }
                if(customerType == 'workaddress'){
                    var description = 'Edit Work Address'
                }
                if(customerType == 'customer'|| customerType == 'company'){
                    var description = 'Edit Customer'
                }
                var name = customerName +' '+ customerSurname +' '+ companyName;
                var customermode = customerObj.customerType;
                var customerId = customerObj.id;

                data.shortcuts.unshift(
                    {group: "Actions for Customer: " + name, options: [
                        { "description": "Customers Details", "state": "loggedin.customer_list.view.property", parameters: {id: customerId, type: 'customer_list'}, 'featureName': 'Customersandworkaddresses', 'access': 'readaccess' },
                        { "description": description, "state": "loggedin.customer_list.view.propertyedit", parameters: {id: customerId, type: 'customer_list'}, 'featureName': 'Customersandworkaddresses', 'access': 'updateaccess' }
                    ]}
                );
            } else {
                var CustomerPrintandDownload = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(CustomerPrintandDownload));
                printanddownload['group'] = "Actions for Supplier";
                printanddownload['options'][0]['parameters']['screen'] = "CustomerPrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "CustomerPrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }
        }
        if (state == 'loggedin.customer') {// Based on the url we need to provide the options

            data.shortcuts.unshift(
                {group: "Customer", options: [
                    { "description": "Customers", "state": "loggedin.customers", 'featureName': 'Customersandworkaddresses', 'access': 'readaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var customerObj = selection.rowObject;
                var customerName = customerObj.name;
                var customerSurname = customerObj.surname;
                var companyName = customerObj.companyname;
                var customermode = customerObj.customerType;
                var customerId = customerObj.id;

                if(customerName == null){
                    customerName = '';
                }
                if(customerSurname == null){
                    customerSurname = '';
                }
                if(companyName == null){
                    companyName = '';
                }
                if(customerType == 'customer'|| customerType == 'company'){
                    var description = 'Edit Customer'
                }
                var name = customerName +' '+ customerSurname +' '+ companyName;

                data.shortcuts.unshift(
                    {group: "Actions for Customer: " + name, options: [
                        { "description": "Customers Details", "state": "loggedin.customer_list.view.property", parameters: {id: customerId, type: 'customer'}, 'featureName': 'Customersandworkaddresses', 'access': 'readaccess' },
                        { "description": "Edit Customer", "state": "loggedin.customer_list.view.propertyedit", parameters: {id: customerId, type: 'customer'}, 'featureName': 'Customersandworkaddresses', 'access': 'updateaccess' }
                    ]}
                );
            } else {
                var CustomerPrintandDownload = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(CustomerPrintandDownload));
                printanddownload['group'] = "Actions for Supplier";
                printanddownload['options'][0]['parameters']['screen'] = "CustomerPrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "CustomerPrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }

        }
        if (state == 'loggedin.workaddresses') {// Based on the url we need to provide the options

            data.shortcuts.unshift(
                {group: "Customer", options: [
                    { "description": "Customers", "state": "loggedin.customers", 'featureName': 'Customersandworkaddresses', 'access': 'readaccess' }
                ]}
            );
            if (selection != '') {// If something is selected we need to show the options for the selected item
                var customerObj = selection.rowObject;
                var customerName = customerObj.name;
                var customermode = customerObj.customerType;
                var customerId = customerObj.id;

                data.shortcuts.unshift(
                    {group: "Actions for Customer: " + customerName, options: [
                        { "description": "Customers Details", "state": "loggedin.customer_list.view.property", parameters: {id: customerId, type: 'work_address'}, 'featureName': 'Customersandworkaddresses', 'access': 'readaccess' },
                        { "description": "Edit Work Address", "state": "loggedin.customer_list.view.propertyedit", parameters: {id: customerId, type: 'work_address'}, 'featureName': 'Customersandworkaddresses', 'access': 'updateaccess' }
                    ]}
                );
            } else {
                var CustomerPrintandDownload = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(CustomerPrintandDownload));
                printanddownload['group'] = "Actions for Supplier";
                printanddownload['options'][0]['parameters']['screen'] = "CustomerPrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "CustomerPrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }

        }
        if (state == 'loggedin.customer_list.view.property') {// Based on the url we need to provide the options
            var currentRecord = params.id;
            var customerTabName = params.type;
            var state ='/customers/'+customerTabName+'/'+currentRecord+'/property/edits';

            data.shortcuts.unshift(
                {group: "Customers", options: [
                    { "description": "Edit Customer", "state": state, 'featureName': 'Customersandworkaddresses', 'access': 'updateaccess' }
                ]}
            )
        }
        if (state == 'loggedin.customer_list.view.work_address_reminders') {// Based on the url we need to provide the options

            if(selection != ''){
                data.shortcuts.unshift(
                    {group: "Work Address Reminder Due", options: [
                        { "description": "Batch Process", "state": "", 'featureName': '', 'access': '' }
                    ]}
                )
            } else {
                var WorkAddressReminderDuePrintandDownload = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(WorkAddressReminderDuePrintandDownload));
                printanddownload['group'] = "Work Address Reminder Due";
                printanddownload['options'][0]['parameters']['screen'] = "WorkAddressReminderDuePrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "WorkAddressReminderDuePrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }
        }
        if (state == 'loggedin.service_reminders') {// Based on the url we need to provide the options
            if(selection == ''){
                var ServiceRemindersPrintandDownload = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(ServiceRemindersPrintandDownload));
                printanddownload['group'] = "View All Service Reminders";
                printanddownload['options'][0]['parameters']['screen'] = "ServiceRemindersPrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "ServiceRemindersPrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }
        }
        if (state == 'loggedin.service_reminders_due_days') {// Based on the url we need to provide the options
            if(selection == ''){
                var ServiceReminderDueDaysPrintandDownload = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(ServiceReminderDueDaysPrintandDownload));
                printanddownload['group'] = "Due in the next 30days";
                printanddownload['options'][0]['parameters']['screen'] = "ServiceReminderDueDaysPrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "ServiceReminderDueDaysPrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }
        }
        if (state == 'loggedin.service_reminders_phone_call') {// Based on the url we need to provide the options
            if(selection == ''){
                var ServiceReminderPhoneCllPrintandDownload = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(ServiceReminderPhoneCllPrintandDownload));
                printanddownload['group'] = "Number of phone calls that need making";
                printanddownload['options'][0]['parameters']['screen'] = "ServiceReminderPhoneCllPrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "ServiceReminderPhoneCllPrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }
        }
        if (state == 'loggedin.customer_list.view.service_reminder.overview') {// Based on the url we need to provide the options
            if(selection == ''){
                var ServiceRemindersPrintandDownloadOverview = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(ServiceRemindersPrintandDownloadOverview));
                printanddownload['group'] = "Service Reminder Statement Overview";
                printanddownload['options'][0]['parameters']['screen'] = "ServiceRemindersPrintandDownloadOverview";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "ServiceRemindersPrintandDownloadOverview";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }
        }
        if (state == 'loggedin.customer_list.view.service_reminder.summary') {// Based on the url we need to provide the options
            if(selection == ''){
                var ServiceRemindersPrintandDownloadSummary = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(ServiceRemindersPrintandDownloadSummary));
                printanddownload['group'] = "Service Reminder Statement Summary";
                printanddownload['options'][0]['parameters']['screen'] = "ServiceRemindersPrintandDownloadSummary";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "ServiceRemindersPrintandDownloadSummary";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }
        }
        if (state == 'loggedin.stocks_view.suppliers') {// Based on the url we need to provide the options
            if(selection == ''){
                var StockSupplierPrintandDownload = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(StockSupplierPrintandDownload));
                printanddownload['group'] = "Stock Supplier Details";
                printanddownload['options'][0]['parameters']['screen'] = "StockSupplierPrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "StockSupplierPrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }
        }
        if (state == 'loggedin.stocks_view.history') {// Based on the url we need to provide the options
            if(selection == ''){
                var StockHistoryPrintandDownload = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(StockHistoryPrintandDownload));
                printanddownload['group'] = "Stock History";
                printanddownload['options'][0]['parameters']['screen'] = "StockHistoryPrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "StockHistoryPrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }
        }
        if (state == 'loggedin.stocks_view.stock') {// Based on the url we need to provide the options
            if(selection == ''){
                var StockDuePrintandDownload = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownloadstockdue = JSON.parse(JSON.stringify(StockDuePrintandDownload));
                printanddownloadstockdue['group'] = "Stock Due Details";
                printanddownloadstockdue['options'][0]['parameters']['screen'] = "StockDuePrintandDownload";
                printanddownloadstockdue['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownloadstockdue['options'][1]['parameters']['screen'] = "StockDuePrintandDownload";
                printanddownloadstockdue['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                var CurrentStockPrintandDownload = {group: "", options: [
                    { "description": "Download to Current Stock Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print Current Stock PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                //var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(CurrentStockPrintandDownload));
                printanddownload['group'] = "Current Stock Details";
                printanddownload['options'][0]['parameters']['screen'] = "CurrentStockPrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "CurrentStockPrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownloadstockdue);
                data.shortcuts.unshift(printanddownload);
            }
        }
        if (state == 'loggedin.stocks_view.reserve_list') {// Based on the url we need to provide the options
            if(selection == ''){
                var ReserveListPrintandDownload = {group: "", options: [
                    { "description": "Download to Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var rowObj = selection.rowObject;
                var printanddownload = JSON.parse(JSON.stringify(ReserveListPrintandDownload));
                printanddownload['group'] = "Reserve list";
                printanddownload['options'][0]['parameters']['screen'] = "ReserveListPrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "ReserveListPrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownload);
            }
        }
        if (state == 'loggedin.list_supplier.view.invoice.details') {// Based on the url we need to provide the options
            var currentRecord=params.id;
            var invoiceId=params.invoiceId;
            var recordId = selection.recordId
            data.shortcuts.unshift(
                {group: "Supplier", options: [
                    { "description": "Suppliers", "state": "loggedin.suppliers", 'featureName': 'Suppliers', 'access': 'readaccess' },
                    { "description": "Suppliers List", "state": "loggedin.list_supplier", 'featureName': 'Suppliers', 'access': 'readaccess' }
                ]},
                {group: "Payment & Allocation History", options: [
                    { "description": "Add New Payment", "state": "loggedin.list_supplier.view.new_supplier_invoice_payment", parameters: {'id': currentRecord, 'invoiceId': invoiceId}, 'featureName': 'Supplierpayments', 'access': 'writeaccess' },
                    { "description": "View Payment", "state": "loggedin.list_supplier.view.invoice.view_payment", parameters: {'id': currentRecord, 'invoiceId': invoiceId, 'invoicePaymentId': recordId}, 'featureName': 'Supplierpayments', 'access': 'readaccess' }
                ]}
            );
        }
        if(state == 'loggedin.list_supplier.view.invoice.notes.table')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var invoiceId=params.invoiceId;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.list_supplier.view.invoice.notes.new", parameters: {'id': currentRecord,'invoiceId':invoiceId,'mode':'table'}, 'featureName': 'Supplierinvoicenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.list_supplier.view.invoice.notes.newphonecall", parameters: {'id': currentRecord,'invoiceId':invoiceId,'mode':'table'}, 'featureName': 'Supplierinvoicenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.list_supplier.view.invoice.notes.new_sms_message", parameters: {'id': currentRecord,'invoiceId':invoiceId,'mode':'table'}, 'featureName': 'Supplierinvoicenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'SupplierInvoiceNotesandCommunications', 'action': 'sendEmail', 'featureName': 'Supplierinvoicenotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );

            if (selection != '') {

                //edit section
                var notesid =selection.rowObject.id;
                var title =selection.rowObject.title;
                data.shortcuts.unshift(
                    {group: "Supplier Invoice: " + title, options: [
                        { "description": "Edit Note", "state": "loggedin.list_supplier.view.invoice.notes.edit", parameters: {'id': currentRecord,'invoiceId':invoiceId,'notesid':notesid,'mode':'table'}, 'featureName': 'Supplierinvoicenotesandcommunications', 'access': 'updateaccess' }
                    ]}
                );
                var notesdelete = {group: "", options: [
                    { "description": "Delete Note", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Supplierinvoicenotesandcommunications', 'access': 'deleteaccess' }
                ]};

                //delete section
                var rowObj = selection.rowObject;
                var supplierinvoicenotedelete = JSON.parse(JSON.stringify(notesdelete));
                supplierinvoicenotedelete['group'] = "Delete for Supplier Ivoice: " + title;
                supplierinvoicenotedelete['options'][0]['parameters']['screen'] = "SupplierInvoiceNotesandCommunications";
                supplierinvoicenotedelete['options'][0]['parameters']['featureName'] = "Supplierinvoicenotesandcommunications";
                supplierinvoicenotedelete['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(supplierinvoicenotedelete);
            }

        }
        if(state == 'loggedin.list_supplier.view.invoice.notes.timeline')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var invoiceId=params.invoiceId;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.list_supplier.view.invoice.notes.new", parameters: {'id': currentRecord,'invoiceId':invoiceId,'mode':'timeline'}, 'featureName': 'Supplierinvoicenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.list_supplier.view.invoice.notes.newphonecall", parameters: {'id': currentRecord,'invoiceId':invoiceId,'mode':'timeline'}, 'featureName': 'Supplierinvoicenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.list_supplier.view.invoice.notes.new_sms_message", parameters: {'id': currentRecord,'invoiceId':invoiceId,'mode':'timeline'}, 'featureName': 'Supplierinvoicenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'SupplierInvoiceNotesandCommunications', 'action': 'sendEmail', 'featureName': 'Supplierinvoicenotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );
        }

        if(state == 'loggedin.list_supplier.view.purchase_order.notes.table')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var purchaseOrderId=params.purchaseOrderId;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.list_supplier.view.purchase_order.notes.new", parameters: {'id': currentRecord,'purchaseOrderId':purchaseOrderId,'mode':'table'}, 'featureName': 'Purchaseordernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.list_supplier.view.purchase_order.notes.newphonecall", parameters: {'id': currentRecord,'purchaseOrderId':purchaseOrderId,'mode':'table'}, 'featureName': 'Purchaseordernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'PurchaseOrderNotesAndCommunication', 'action': 'sendEmail', 'featureName': 'Purchaseordernotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );

            if (selection != '') {

                //edit section
                var notesid =selection.rowObject.id;
                var title =selection.rowObject.title;
                data.shortcuts.unshift(
                    {group: "Purchase Order: " + title, options: [
                        { "description": "Edit Note", "state": "loggedin.list_supplier.view.purchase_order.notes.edit", parameters: {'id': currentRecord,'purchaseOrderId':purchaseOrderId,'notesid':notesid,'mode':'table'}, 'featureName': 'Purchaseordernotesandcommunications', 'access': 'updateaccess' }
                    ]}
                );
                var notesdelete = {group: "", options: [
                    { "description": "Delete Note", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Purchaseordernotesandcommunications', 'access': 'deleteaccess' }
                ]};

                //delete section
                var rowObj = selection.rowObject;
                var purchaseordernotedelete = JSON.parse(JSON.stringify(notesdelete));
                purchaseordernotedelete['group'] = "Delete for Purchase Order: " + title;
                purchaseordernotedelete['options'][0]['parameters']['screen'] = "PurchaseOrderNotesAndCommunication";
                purchaseordernotedelete['options'][0]['parameters']['featureName'] = "Purchaseordernotesandcommunications";
                purchaseordernotedelete['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(purchaseordernotedelete);
            }

        }
        if(state == 'loggedin.list_supplier.view.purchase_order.notes.timeline')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var purchaseOrderId=params.purchaseOrderId;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.list_supplier.view.purchase_order.notes.new", parameters: {'id': currentRecord,'purchaseOrderId':purchaseOrderId,'mode':'timeline'}, 'featureName': 'Purchaseordernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.list_supplier.view.purchase_order.notes.newphonecall", parameters: {'id': currentRecord,'purchaseOrderId':purchaseOrderId,'mode':'timeline'}, 'featureName': 'Purchaseordernotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'PurchaseOrderNotesAndCommunication', 'action': 'sendEmail', 'featureName': 'Purchaseordernotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );
        }

        if (state == 'loggedin.list_supplier.view.creditnotes.details') {// Based on the url we need to provide the options
            var currentRecord=params.id;
            var creditNoteId=params.creditNoteId;
            var recordId = selection.recordId
            data.shortcuts.unshift(
                {group: "Supplier", options: [
                    { "description": "Suppliers", "state": "loggedin.suppliers", 'featureName': 'Suppliers', 'access': 'readaccess' },
                    { "description": "Suppliers List", "state": "loggedin.list_supplier", 'featureName': 'Suppliers', 'access': 'readaccess' }
                ]},
                {group: "Allocation History", options: [
                    { "description": "Receive remaining credit as cash", "state": "loggedin.list_supplier.view.new_supplier_credit_note_receive_credit", parameters: {'id': currentRecord, 'creditNoteId': creditNoteId}, 'featureName': 'Suppliercreditnoteallocation', 'access': 'writeaccess' },
                    { "description": "Edit Allocation", "state": "loggedin.list_supplier.view.creditnotes.edit_receive_credit", parameters: {'id': currentRecord, 'creditNoteId': creditNoteId, 'allocationId': recordId}, 'featureName': 'Suppliercreditnoteallocation', 'access': 'updateaccess' }
                ]}
            );
        }
        // This is parameter for Credit Note Notes and communications tab.
        if(state == 'loggedin.list_supplier.view.credit_note.notes.table')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var creditNoteId=params.creditNoteId;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.list_supplier.view.credit_note.notes.new", parameters: {'id': currentRecord,'creditNoteId':creditNoteId,'mode':'table'}, 'featureName': 'Creditnotenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.list_supplier.view.credit_note.notes.newphonecall", parameters: {'id': currentRecord,'creditNoteId':creditNoteId,'mode':'table'}, 'featureName': 'Creditnotenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.list_supplier.view.credit_note.new_sms_message", parameters: {'id': currentRecord,'creditNoteId':creditNoteId,'mode':'table'}, 'featureName': 'Creditnotenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'CreditNoteNotesandCommunication', 'action': 'sendEmail', 'featureName': 'Creditnotenotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );

            if (selection != '') {

                //edit section
                var notesid =selection.rowObject.id;
                var title =selection.rowObject.title;
                data.shortcuts.unshift(
                    {group: "Credit Notes: " + title, options: [
                        { "description": "Edit Note", "state": "loggedin.list_supplier.view.credit_note.notes.edit", parameters: {'id': currentRecord,'creditNoteId':creditNoteId,'notesid':notesid,'mode':'table'}, 'featureName': 'Creditnotenotesandcommunications', 'access': 'updateaccess' }
                    ]}
                );
                var notesdelete = {group: "", options: [
                    { "description": "Delete Note", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Creditnotenotesandcommunications', 'access': 'deleteaccess' }
                ]};

                //delete section
                var rowObj = selection.rowObject;
                var creditnotedelete = JSON.parse(JSON.stringify(notesdelete));
                creditnotedelete['group'] = "Delete for Credit Note: " + title;
                creditnotedelete['options'][0]['parameters']['screen'] = "CreditNoteNotesandCommunication";
                creditnotedelete['options'][0]['parameters']['featureName'] = "Creditnotenotesandcommunications";
                creditnotedelete['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(creditnotedelete);
            }

        }
        if(state == 'loggedin.list_supplier.view.credit_note.notes.timeline')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var creditNoteId=params.creditNoteId;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.list_supplier.view.credit_note.notes.new", parameters: {'id': currentRecord,'creditNoteId':creditNoteId,'mode':'timeline'}, 'featureName': 'Creditnotenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.list_supplier.view.credit_note.notes.newphonecall", parameters: {'id': currentRecord,'creditNoteId':creditNoteId,'mode':'timeline'}, 'featureName': 'Creditnotenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.list_supplier.view.credit_note.new_sms_message", parameters: {'id': currentRecord,'creditNoteId':creditNoteId,'mode':'timeline'}, 'featureName': 'Creditnotenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'CreditNoteNotesandCommunication', 'action': 'sendEmail', 'featureName': 'Creditnotenotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );
        }
        if(state == 'loggedin.customer_list.view.job.notes.table')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var jobId=params.jobId;
            var customerTabName=params.type;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.customer_list.view.job.notes_new", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId,'mode':'table'}, 'featureName': 'Jobnotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.customer_list.view.job.new_phonecall", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId,'mode':'table'}, 'featureName': 'Jobnotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.customer_list.view.job.new_smsmessage", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId,'mode':'table'}, 'featureName': 'Jobnotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'Jobnotesandcommunications', 'action': 'sendEmail', 'featureName': 'Jobnotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );

            if (selection != '') {

                //edit section
                var notesid =selection.rowObject.id;
                var title =selection.rowObject.title;
                data.shortcuts.unshift(
                    {group: "Job: " + title, options: [
                        { "description": "Edit Note", "state": "loggedin.customer_list.view.job.notes_edit", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId,'notesid':notesid,'mode':'table'}, 'featureName': 'Jobnotesandcommunications', 'access': 'updateaccess' }
                    ]}
                );
                var notesdelete = {group: "", options: [
                    { "description": "Delete Note", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Jobnotesandcommunications', 'access': 'deleteaccess' }
                ]};

                //delete section
                var rowObj = selection.rowObject;
                var customerjobnotedelete = JSON.parse(JSON.stringify(notesdelete));
                customerjobnotedelete['group'] = "Delete for Job: " + title;
                customerjobnotedelete['options'][0]['parameters']['screen'] = "Jobnotesandcommunications";
                customerjobnotedelete['options'][0]['parameters']['featureName'] = "Jobnotesandcommunications";
                customerjobnotedelete['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(customerjobnotedelete);
            }

        }
        if(state == 'loggedin.customer_list.view.job.notes.timeline')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var jobId=params.jobId;
            var customerTabName=params.type;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.customer_list.view.job.notes_new", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId,'mode':'timeline'}, 'featureName': 'Jobnotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.customer_list.view.job.new_phonecall", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId,'mode':'timeline'}, 'featureName': 'Jobnotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.customer_list.view.job.new_smsmessage", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId,'mode':'timeline'}, 'featureName': 'Jobnotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'CustomerJobNotesandCommunication', 'action': 'sendEmail', 'featureName': 'Jobnotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );
        }
        if(state == 'loggedin.customer_list.view.job.invoices.view.view_notes.table')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var jobId=params.jobId;
            var invoiceId=params.invoiceId;
            var customerTabName=params.type;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.customer_list.view.job.invoices.view.notes_new", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId, 'invoiceId': invoiceId,'mode':'table'}, 'featureName': 'Jobinvoicenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.customer_list.view.job.invoices.view.notes_new_phonecall", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId, 'invoiceId': invoiceId, 'mode':'table'}, 'featureName': 'Jobinvoicenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.customer_list.view.job.invoices.view.notes_new_smsmessage", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId, 'invoiceId': invoiceId, 'mode':'table'}, 'featureName': 'Jobinvoicenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'Jobinvoicenotesandcommunications', 'action': 'sendEmail', 'featureName': 'Jobinvoicenotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );

            if (selection != '') {

                //edit section
                var notesid =selection.rowObject.id;
                var title =selection.rowObject.title;
                data.shortcuts.unshift(
                    {group: "Job: " + title, options: [
                        { "description": "Edit Note", "state": "loggedin.customer_list.view.job.invoices.notes_edit", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId, 'invoiceId': invoiceId, 'notesid':notesid,'mode':'table'}, 'featureName': 'Jobinvoicenotesandcommunications', 'access': 'updateaccess' }
                    ]}
                );
                var notesdelete = {group: "", options: [
                    { "description": "Delete Note", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Jobinvoicenotesandcommunications', 'access': 'deleteaccess' }
                ]};

                //delete section
                var rowObj = selection.rowObject;
                var customerjobnotedelete = JSON.parse(JSON.stringify(notesdelete));
                customerjobnotedelete['group'] = "Delete for Job: " + title;
                customerjobnotedelete['options'][0]['parameters']['screen'] = "Jobinvoicenotesandcommunications";
                customerjobnotedelete['options'][0]['parameters']['featureName'] = "";
                customerjobnotedelete['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(customerjobnotedelete);
            }

        }
        else if(state == 'loggedin.customer_list.view.job.invoices.view.view_notes.timeline')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;
            var jobId=params.jobId;
            var invoiceId=params.invoiceId;
            var customerTabName=params.type;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.customer_list.view.job.invoices.view.notes_new", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId, 'invoiceId': invoiceId, 'mode':'timeline'}, 'featureName': 'Jobinvoicenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.customer_list.view.job.invoices.view.notes_new_phonecall", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId,'invoiceId': invoiceId, 'mode':'timeline'}, 'featureName': 'Jobinvoicenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.customer_list.view.job.invoices.view.notes_new_smsmessage", parameters: {'id': currentRecord,'type':customerTabName,'jobId':jobId, 'invoiceId': invoiceId,'mode':'timeline'}, 'featureName': 'Jobinvoicenotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'Jobinvoicenotesandcommunications', 'action': 'sendEmail', 'featureName': 'Jobinvoicenotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );
        }
        if(state == 'loggedin.invoice_address.view.notes.table')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.invoice_address.view.notes_new", parameters: {'id': currentRecord,'mode':'table'}, 'featureName': 'Invoiceaddressnotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.invoice_address.view.new_phone_call", parameters: {'id': currentRecord,'mode':'table'}, 'featureName': 'Invoiceaddressnotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.invoice_address.view.new_sms_message", parameters: {'id': currentRecord,'mode':'table'}, 'featureName': 'Invoiceaddressnotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'CustomerInvoiceAddressNotesandCommunications', 'action': 'sendEmail', 'featureName': 'Invoiceaddressnotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );

            if (selection != '') {

                //edit section
                var notesid =selection.rowObject.id;
                var title =selection.rowObject.title;
                data.shortcuts.unshift(
                    {group: "Invoice Address: " + title, options: [
                        { "description": "Edit Note", "state": "loggedin.invoice_address.view.notes_edit", parameters: {'id': currentRecord,'notesid':notesid,'mode':'table'}, 'featureName': 'Invoiceaddressnotesandcommunications', 'access': 'updateaccess' }
                    ]}
                );
                var notesdelete = {group: "", options: [
                    { "description": "Delete Note", "state": "false", parameters: {'screen': '', 'action': 'delete', 'parameters': ''}, 'featureName': 'Invoiceaddressnotesandcommunications', 'access': 'deleteaccess' }
                ]};

                //delete section
                var rowObj = selection.rowObject;
                var customerinvoiceaddressnotedelete = JSON.parse(JSON.stringify(notesdelete));
                customerinvoiceaddressnotedelete['group'] = "Delete for Invoice Address: " + title;
                customerinvoiceaddressnotedelete['options'][0]['parameters']['screen'] = "CustomerInvoiceAddressNotesandCommunications";
                customerinvoiceaddressnotedelete['options'][0]['parameters']['featureName'] = "Invoiceaddressnotesandcommunications";
                customerinvoiceaddressnotedelete['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(customerinvoiceaddressnotedelete);
            }

        }
        if(state == 'loggedin.invoice_address.view.notes.timeline')
        {
            // If you need pass the record value in URL then use this variable.
            var currentRecord=params.id;

            data.shortcuts.unshift(
                {group: "Add Notes & Communications", options: [
                    { "description": "Add New Note", "state": "loggedin.invoice_address.view.notes_new", parameters: {'id': currentRecord,'mode':'timeline'}, 'featureName': 'Invoiceaddressnotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Add Phone Call", "state": "loggedin.invoice_address.view.new_phone_call", parameters: {'id': currentRecord,'mode':'timeline'}, 'featureName': 'Invoiceaddressnotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New SMS", "state": "loggedin.invoice_address.view.new_sms_message", parameters: {'id': currentRecord,'mode':'timeline'}, 'featureName': 'Invoiceaddressnotesandcommunications', 'access': 'writeaccess' },
                    { "description": "Send New Email", "state": "false", parameters: {'screen': 'CustomerInvoiceAddressNotesandCommunications', 'action': 'sendEmail', 'featureName': 'Invoiceaddressnotesandcommunications', 'access': 'writeaccess'} }
                ]}
            );
        }
        if(state == 'loggedin.customer_list.view.job.details')
        {
            // If you need pass the record value in URL then use this variable.
            var id = params.id;
            var jobId = params.jobId;
            var customerTabName = params.type;

            data.shortcuts.unshift(
                {group: "Recalls", options: [
                    { "description": "Create recall", "state": "loggedin.customer_list.view.job.recalls", parameters: {'id': id,'type':customerTabName,'jobId':jobId}, 'featureName': 'Recalljob', 'access': 'writeaccess' }
                ]}
            );
        }
        if (state == 'loggedin.stock_locations_view.stock_location_details') {// Based on the url we need to provide the options
            if(selection == ''){
                var CurrentStockPrintandDownload = {group: "", options: [
                    { "description": "Download to Current Stock Excel", "state": "false", parameters: {'screen': '', 'action': 'download', 'parameters': ''} },
                    { "description": "Print Current Stock PDF", "state": "false", parameters: {'screen': '', 'action': 'print', 'parameters': ''} }
                ]};
                var printanddownload = JSON.parse(JSON.stringify(CurrentStockPrintandDownload));
                printanddownload['group'] = "Current Stock Details";
                printanddownload['options'][0]['parameters']['screen'] = "CurrentStockPrintandDownload";
                printanddownload['options'][0]['parameters']['parameters'] = angular.toJson(rowObj);
                printanddownload['options'][1]['parameters']['screen'] = "CurrentStockPrintandDownload";
                printanddownload['options'][1]['parameters']['parameters'] = angular.toJson(rowObj);

                data.shortcuts.unshift(printanddownloadstockdue);
                data.shortcuts.unshift(printanddownload);
            }
        }
        this.shortcuts = this.checkPermissions(data.shortcuts);
        this.setUrlList();
        return this.shortcuts;
    }

    this.getUrl = function (selectedOption) {

        let url;
        if (selectedOption == 'Settings') {
            url = '/settings'
        }
        if (selectedOption == 'Integrations') {
            url="/company_settings/integrations"
         }

        $timeout(function () {
            window.dispatchEvent(new CustomEvent("callAngular7Function", {
                'detail': {
                    url: url
                }
            }))
        }, 10);
        return this.urlList[selectedOption].state;
    }

    this.getParameter = function (selectedOption) {
        return this.urlList[selectedOption].parameters;
    }

    this.hasPermission = function (featureId, featureAccess) {
        //TODO: Need to remove this later, after adding permissions
        if (featureId == undefined) {
            return true;
        }
        if (this.accessDetails.hasOwnProperty(featureId)) {
            return this.accessDetails[featureId][featureAccess];
        } else {
            //TODO: Need to remove this later, after adding permissions
            console.log("Please add permissions to this feature Id(SHORTCUT): " + featureId);
        }
        return false;
    }

    this.checkPermissions = function (shortcuts) {
        var shortcutContainer = this;
        var shortcutObjNew = [];
        angular.forEach(shortcuts, function (shortcutObj, groupName) {
            var options = [];
            angular.forEach(shortcutObj.options, function (shortcutObjValue, objKey) {
                var featureName = shortcutObjValue.featureName;
                var access = shortcutObjValue.access;
                var hasPermission = shortcutContainer.hasPermission(features[featureName], access);
                if (hasPermission) {
                    options.push(shortcutObjValue);
                }
            });
            if (options.length > 0) {
                shortcutObjNew.push({group: shortcutObj.group, options: options});
            }
        });
        return shortcutObjNew;
    }
}]);
