commusoftCommon.directive('inlinenoresults', function($document) {
    var handleNoResults = function handleNoResults(scope, element, attrs) {
        scope.translated = false;
        scope.translated = angular.fromJson(attrs.translated);
        var translated = scope.translated;

        var icon = attrs.icon;
        if (icon) {
            scope.icon = attrs.icon;
        } else {
            scope.icon = 'ss-info';
        }


        if (translated === true) {
            scope.message = $filter('translate')(attrs.message);
        } else {
            scope.message = attrs.message;
        }

        attrs.$observe('message', function(message) {
            scope.message = message;
        });

    }

    return {
        restrict: 'A',
        templateUrl: 'template/shared/inline_no_results.html',
        scope: {
        },
        link: handleNoResults
    }
});