/**
 * Created by sivachandran on 21/5/19.
 */

'use strict';
var estimateModule = angular.module('estimate', []);

estimateModule.constant('prefix', window.prefixVal)
    .config(['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
    function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {
        $stateProvider.state('loggedin.customer_list.view.estimate_details', {
            url: Routing.generateAngularRoute('estimate_subnav', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_subnav', params)
            },
            resolve: BreadCrumbsResolver,
            views: {
                "customersPane@": {
                    templateUrl: 'template/estimate/estimate_configure.html',
                    controller: EstimateSubNavCtrl,
                    /*resolve: BreadCrumbsResolver*/
                },
                "estimatesTabPane@": {
                    templateUrl: 'template/estimate/estimate_layout.html',
                    controller: EstimateSubNavCtrl,
                    /*resolve: BreadCrumbsResolver*/
                },
                "estimatesInfoTabPane@": {
                    templateUrl: 'template/estimate/estimate_details/estimate_information_bar.html',
                    controller: EstimateSubNavCtrl,
                    /*resolve: BreadCrumbsResolver*/
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details', {
            url: Routing.generateAngularRoute('project_subnav', true),
            templateUrl: function (params) {
                return Routing.generate('project_subnav', params)
            },
            resolve: BreadCrumbsResolver,
            views: {
                "customersPane@": {
                    templateUrl: 'template/estimate/estimate_configure.html',
                    controller: EstimateSubNavCtrl,
                    /*resolve: BreadCrumbsResolver*/
                },
                "estimatesTabPane@": {
                    templateUrl: 'template/estimate/estimate_layout.html',
                    controller: EstimateSubNavCtrl,
                    /*resolve: BreadCrumbsResolver*/
                },
                "estimatesInfoTabPane@": {
                    templateUrl: 'template/estimate/estimate_details/estimate_information_bar.html',
                    controller: EstimateSubNavCtrl,
                    /*resolve: BreadCrumbsResolver*/
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.survey', {
            url: Routing.generateAngularRoute('project_estimate_details', true),
            templateUrl: function (params) {
                return Routing.generate('project_estimate_details', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/survey/view.html',
                    controller: EstimateSurveyCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.survey_edit', {
            url: Routing.generateAngularRoute('project_details_edit', true),
            templateUrl: function (params) {
                return Routing.generate('project_details_edit', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/estimate_details/survey/edit.html',
                    controller: EstimateSurveyEditCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.introduction', {
            url: Routing.generateAngularRoute('project_estimate_introduction', true),
            templateUrl: function (params) {
                return Routing.generate('project_estimate_introduction', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/introduction/view.html',
                    controller: EstimateIntroductionCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.body', {
            url: Routing.generateAngularRoute('project_estimate_body', true),
            templateUrl: function (params) {
                return Routing.generate('project_estimate_body', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/estimate_body/view.html',
                    controller: EstimateBodyCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.todos', {
            url: Routing.generateAngularRoute('projectPlan', true),
            templateUrl: function (params) {
                return Routing.generate('projectPlan', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/todos/project.html',
                    controller: EstimateTodosCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.cost', {
            url: Routing.generateAngularRoute('projectCost', true),
            templateUrl: function (params) {
                return Routing.generate('projectCost', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/cost/project.html',
                    controller: EstimateBudgetCostCtrl,
                    resolve: BreadCrumbsResolver
                }
            },
            onEnter: function() {
                var grand_totals_exist = localStorage.getItem('costs:spreadsheetGrandTotals') !== null;
                if(grand_totals_exist) {
                    localStorage.removeItem('costs:spreadsheetGrandTotals');
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.price', {
            url: Routing.generateAngularRoute('projectPrice', true),
            templateUrl: function (params) {
                return Routing.generate('projectPrice', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/price/view.html',
                    controller: EstimatePriceCtrl,
                    resolve: BreadCrumbsResolver
                }
            },
            onEnter: function() {
                var grand_totals_exist = localStorage.getItem('estimates:price:spreadsheetGrandTotals') !== null;

                if(grand_totals_exist) {
                    localStorage.removeItem('estimates:price:spreadsheetGrandTotals');
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.invoice_schedule', {
            url: Routing.generateAngularRoute('projectInvoiceSchedule', true),
            templateUrl: function (params) {
                return Routing.generate('projectInvoiceSchedule', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/invoice_schedule/view.html',
                    controller: EstimateInvoiceScheduleCtrl,
                    resolve: SpreadsheetBreadCrumbsResolver
                }
            },
            onEnter: function() {
                var grand_totals_exist = localStorage.getItem('invoice_schedule:spreadsheetGrandTotals') !== null;

                if(grand_totals_exist) {
                    localStorage.removeItem('invoice_schedule:spreadsheetGrandTotals');
                }
            }
        }).
        state('loggedin.customer_list.view.project.estimate_reminders', {
            url: Routing.generateAngularRoute('project_reminders', true),
            templateUrl: function (params) {
                return Routing.generate('project_reminders', params)
            },
            data: {
                'pageNum': 1,
                'limit': 5,
                'serviceReminderPageNum': 1,
                'serviceReminderLimit': 5
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/reminders/view.html',
                    controller: EstimateRemindersListCtrl,
                    resolve: BreadCrumbsPageLimitResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project.new_estimate_reminder', {
            url: Routing.generateAngularRoute('add_project_reminder', true),
            templateUrl: function (params) {
                return Routing.generate('add_project_reminder', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: function (params) {
                        return Routing.generate('new_project_reminder', params)
                    },
                    controller: EstimateNewReminderCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project.edit_estimate_reminder', {
            url: Routing.generateAngularRoute('edit_project_url', true),
            templateUrl: function (params) {
                return Routing.generate('edit_project_url', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/reminders/edit_estimate_reminder.html',
                    controller: EditEstimateReminderCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project.files', {
            url: Routing.generateAngularRoute('get_project_files', true),
            templateUrl: function (params) {
                return Routing.generate('get_project_files', params)
            },
            data: {
                'pageNum': 1,
                'limit': 10
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/files/attached_files.html',
                    controller: EstimateUploadedFilesCtrl,
                    resolve: BreadCrumbsPageLimitResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project.new_file', {
            url: Routing.generateAngularRoute('project_new_files', true),
            templateUrl: function (params) {
                return Routing.generate('project_new_files', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/files/new_file.html',
                    controller: AddNewEstimateFileCtrl,
                    resolve: BreadCrumbsPageLimitResolver

                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.survey', {
            url: Routing.generateAngularRoute('estimate_details', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_details', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/survey/view.html',
                    controller: EstimateSurveyCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.survey_edit', {
            url: Routing.generateAngularRoute('estimate_details_edit', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_details_edit', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/estimate_details/survey/edit.html',
                    controller: EstimateSurveyEditCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.introduction', {
            url: Routing.generateAngularRoute('estimate_introduction', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_introduction', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/introduction/view.html',
                    controller: EstimateIntroductionCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.additional_option', {
            url: Routing.generateAngularRoute('estimate_additional_option', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_additional_option', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/additional_option/view.html',
                    controller: EstimateAdditionOptionCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.additional_option', {
            url: Routing.generateAngularRoute('project_estimate_additional_option', true),
            templateUrl: function (params) {
                return Routing.generate('project_estimate_additional_option', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/additional_option/view.html',
                    controller: EstimateAdditionOptionCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.edit_option', {
            url: Routing.generateAngularRoute('estimate_option_subnav', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_option_subnav', params)
            },
            views: {
                "estimatesTabPane@": {
                    templateUrl: 'template/estimate/estimate_details/additional_option/edit/estimate_option_layout.html',
                    controller: EstimateSubNavCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.edit_option', {
            url: Routing.generateAngularRoute('project_estimate_option_subnav', true),
            templateUrl: function (params) {
                return Routing.generate('project_estimate_option_subnav', params)
            },
            views: {
                "estimatesTabPane@": {
                    templateUrl: 'template/estimate/estimate_details/additional_option/edit/estimate_option_layout.html',
                    controller: EstimateSubNavCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.edit_option.body', {
            url: Routing.generateAngularRoute('edit_option_body', true),
            templateUrl: function (params) {
                return Routing.generate('edit_option_body', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/estimate_body/view.html',
                    controller: EstimateBodyCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.edit_option.body', {
            url: Routing.generateAngularRoute('project_edit_option_body', true),
            templateUrl: function (params) {
                return Routing.generate('project_edit_option_body', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/estimate_body/view.html',
                    controller: EstimateBodyCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.edit_option.parts', {
            url: Routing.generateAngularRoute('edit_option_parts', true),
            templateUrl: function (params) {
                return Routing.generate('edit_option_parts', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/parts/view.html',
                    controller: EstimatePartsCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.edit_option.todos', {
            url: Routing.generateAngularRoute('edit_option_todo', true),
            templateUrl: function (params) {
                return Routing.generate('edit_option_todo', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/todos/estimate.html',
                    controller: EstimateTodosCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.edit_option.todos', {
            url: Routing.generateAngularRoute('project_edit_option_todo', true),
            templateUrl: function (params) {
                return Routing.generate('project_edit_option_todo', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/todos/project.html',
                    controller: EstimateTodosCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.edit_option.cost', {
            url: Routing.generateAngularRoute('edit_option_cost', true),
            templateUrl: function (params) {
                return Routing.generate('edit_option_cost', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/cost/estimate.html',
                    controller: EstimateBudgetCostCtrl,
                    resolve: BreadCrumbsResolver
                }
            },
            onEnter: function() {
                var grand_totals_exist = localStorage.getItem('costs:spreadsheetGrandTotals') !== null;
                if(grand_totals_exist) {
                    localStorage.removeItem('costs:spreadsheetGrandTotals');
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.edit_option.cost', {
            url: Routing.generateAngularRoute('project_edit_option_cost', true),
            templateUrl: function (params) {
                return Routing.generate('project_edit_option_cost', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/cost/project.html',
                    controller: EstimateBudgetCostCtrl,
                    resolve: BreadCrumbsResolver
                }
            },
            onEnter: function() {
                var grand_totals_exist = localStorage.getItem('costs:spreadsheetGrandTotals') !== null;
                if(grand_totals_exist) {
                    localStorage.removeItem('costs:spreadsheetGrandTotals');
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.edit_option.price', {
            url: Routing.generateAngularRoute('edit_option_price', true),
            templateUrl: function (params) {
                return Routing.generate('edit_option_price', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/price/view.html',
                    controller: EstimatePriceCtrl,
                    resolve: BreadCrumbsResolver
                }
            },
            onEnter: function() {
                var grand_totals_exist = localStorage.getItem('estimates:price:spreadsheetGrandTotals') !== null;

                if(grand_totals_exist) {
                    localStorage.removeItem('estimates:price:spreadsheetGrandTotals');
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.edit_option.price', {
            url: Routing.generateAngularRoute('project_edit_option_price', true),
            templateUrl: function (params) {
                return Routing.generate('project_edit_option_price', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/price/view.html',
                    controller: EstimatePriceCtrl,
                    resolve: BreadCrumbsResolver
                }
            },
            onEnter: function() {
                var grand_totals_exist = localStorage.getItem('estimates:price:spreadsheetGrandTotals') !== null;

                if(grand_totals_exist) {
                    localStorage.removeItem('estimates:price:spreadsheetGrandTotals');
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.edit_option.invoice_schedule', {
            url: Routing.generateAngularRoute('edit_estimate_invoice_schedule', true),
            templateUrl: function (params) {
                return Routing.generate('edit_estimate_invoice_schedule', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/invoice_schedule/view.html',
                    controller: EstimateInvoiceScheduleCtrl,
                    resolve: SpreadsheetBreadCrumbsResolver
                }
            },
            onEnter: function() {
                var grand_totals_exist = localStorage.getItem('invoice_schedule:spreadsheetGrandTotals') !== null;

                if(grand_totals_exist) {
                    localStorage.removeItem('invoice_schedule:spreadsheetGrandTotals');
                }
            }
        }).state('loggedin.customer_list.view.project_estimate_details.edit_option.invoice_schedule', {
            url: Routing.generateAngularRoute('project_estimate_edit_invoice_schedule', true),
            templateUrl: function (params) {
                return Routing.generate('project_estimate_edit_invoice_schedule', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/invoice_schedule/view.html',
                    controller: EstimateInvoiceScheduleCtrl,
                    resolve: SpreadsheetBreadCrumbsResolver
                }
            },
            onEnter: function() {
                var grand_totals_exist = localStorage.getItem('invoice_schedule:spreadsheetGrandTotals') !== null;

                if(grand_totals_exist) {
                    localStorage.removeItem('invoice_schedule:spreadsheetGrandTotals');
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.body', {
            url: Routing.generateAngularRoute('estimate_body', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_body', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/estimate_body/view.html',
                    controller: EstimateBodyCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.parts', {
            url: Routing.generateAngularRoute('estimate_parts', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_parts', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/parts/view.html',
                    controller: EstimatePartsCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.todos', {
            url: Routing.generateAngularRoute('estimateTodo', true),
            templateUrl: function (params) {
                return Routing.generate('estimateTodo', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/todos/estimate.html',
                    controller: EstimateTodosCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.cost', {
            url: Routing.generateAngularRoute('estimateCost', true),
            templateUrl: function (params) {
                return Routing.generate('estimateCost', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/cost/estimate.html',
                    controller: EstimateBudgetCostCtrl,
                    resolve: BreadCrumbsResolver
                }
            },
            onEnter: function() {
                var grand_totals_exist = localStorage.getItem('costs:spreadsheetGrandTotals') !== null;
                if(grand_totals_exist) {
                    localStorage.removeItem('costs:spreadsheetGrandTotals');
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.price', {
            url: Routing.generateAngularRoute('estimatePrice', true),
            templateUrl: function (params) {
                return Routing.generate('estimatePrice', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/price/view.html',
                    controller: EstimatePriceCtrl,
                    resolve: BreadCrumbsResolver
                }
            },
            onEnter: function() {
                var grand_totals_exist = localStorage.getItem('estimates:price:spreadsheetGrandTotals') !== null;

                if(grand_totals_exist) {
                    localStorage.removeItem('estimates:price:spreadsheetGrandTotals');
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.invoice_schedule', {
            url: Routing.generateAngularRoute('estimateInvoiceSchedule', true),
            templateUrl: function (params) {
                return Routing.generate('estimateInvoiceSchedule', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/invoice_schedule/view.html',
                    controller: EstimateInvoiceScheduleCtrl,
                    resolve: SpreadsheetBreadCrumbsResolver
                }
            },
            onEnter: function() {
                var grand_totals_exist = localStorage.getItem('invoice_schedule:spreadsheetGrandTotals') !== null;

                if(grand_totals_exist) {
                    localStorage.removeItem('invoice_schedule:spreadsheetGrandTotals');
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.sendtocustomer', {
            url: Routing.generateAngularRoute('estimateSendToCustomer', true),
            templateUrl: function (params) {
                return Routing.generate('estimateSendToCustomer', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/send_to_customer/view.html',
                    controller: EstimateSendToCustomerCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.sendtocustomer', {
            url: Routing.generateAngularRoute('projectSendToCustomer', true),
            templateUrl: function (params) {
                return Routing.generate('projectSendToCustomer', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/send_to_customer/view.html',
                    controller: EstimateSendToCustomerCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate_details.accept_reject', {
            url: Routing.generateAngularRoute('estimate_accept_reject', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_accept_reject', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/accept_or_reject/view.html',
                    controller: EstimateAcceptRejectCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project_estimate_details.accept_reject', {
            url: Routing.generateAngularRoute('project_estimate_accept_reject', true),
            templateUrl: function (params) {
                return Routing.generate('project_estimate_accept_reject', params)
            },
            views: {
                "estimatesPane@": {
                    templateUrl: 'template/estimate/estimate_details/accept_or_reject/view.html',
                    controller: EstimateAcceptRejectCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate', {
            url: Routing.generateAngularRoute('estimate_subnav', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_subnav', params)
            },
            views: {
                "customersPane@": {
                    templateUrl: 'template/estimate/estimate_configure.html',
                    controller: EstimateSubNavCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project', {
            url: Routing.generateAngularRoute('project_subnav', true),
            templateUrl: function (params) {
                return Routing.generate('project_subnav', params)
            },
            views: {
                "customersPane@": {
                    templateUrl: 'template/estimate/estimate_configure.html',
                    controller: EstimateSubNavCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate.files', {
            url: Routing.generateAngularRoute('get_estimate_files', true),
            templateUrl: function (params) {
                return Routing.generate('get_estimate_files', params)
            },
            data: {
                'pageNum': 1,
                'limit': 10
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/files/attached_files.html',
                    controller: EstimateUploadedFilesCtrl,
                    resolve: BreadCrumbsPageLimitResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate.new_file', {
            url: Routing.generateAngularRoute('estimate_new_files', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_new_files', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/files/new_file.html',
                    controller: AddNewEstimateFileCtrl,
                    resolve: BreadCrumbsPageLimitResolver

                }
            }
        }).
        state('loggedin.customer_list.view.estimate.estimate_reminders', {
            url: Routing.generateAngularRoute('estimate_reminders', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_reminders', params)
            },
            data: {
                'pageNum': 1,
                'limit': 5,
                'serviceReminderPageNum': 1,
                'serviceReminderLimit': 5
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/reminders/view.html',
                    controller: EstimateRemindersListCtrl,
                    resolve: BreadCrumbsPageLimitResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate.new_estimate_reminder', {
            url: Routing.generateAngularRoute('add_estimate_reminder', true),
            templateUrl: function (params) {
                return Routing.generate('add_estimate_reminder', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: function (params) {
                        return Routing.generate('new_estimate_reminder', params)
                    },
                    controller: EstimateNewReminderCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate.edit_estimate_reminder', {
            url: Routing.generateAngularRoute('edit_estimate_url', true),
            templateUrl: function (params) {
                return Routing.generate('edit_estimate_url', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/reminders/edit_estimate_reminder.html',
                    controller: EditEstimateReminderCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate.notes', {
            url: Routing.generateAngularRoute('customerestimate_note', true),
            templateUrl: function (params) {
                return Routing.generate('customerestimate_note', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/notes/view.html',
                    controller: EstimateCommunicationsCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project.notes', {
            url: Routing.generateAngularRoute('project_estimate_note', true),
            templateUrl: function (params) {
                return Routing.generate('project_estimate_note', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/notes/view.html',
                    controller: EstimateCommunicationsCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate.notes.table', {
            url: Routing.generateAngularRoute('estimate_notes', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_notes', params)
            },
            data: {
                'pageNum': 1,
                'limit': 5,
                'pageNumPhoneCalls': 1,
                'limitPhoneCalls': 5,
                'pageNumSmsMessages': 1,
                'limitSmsMessages': 5
            },
            views: {
                "": {
                    templateUrl: 'template/estimate/notes/tableview.html',
                    controller: EstimateNotesListCtrl,
                    resolve: BreadCrumbsPageLimitResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project.notes.table', {
            url: Routing.generateAngularRoute('project_notes', true),
            templateUrl: function (params) {
                return Routing.generate('project_notes', params)
            },
            data: {
                'pageNum': 1,
                'limit': 5,
                'pageNumPhoneCalls': 1,
                'limitPhoneCalls': 5,
                'pageNumSmsMessages': 1,
                'limitSmsMessages': 5
            },
            views: {
                "": {
                    templateUrl: 'template/estimate/notes/tableview.html',
                    controller: EstimateNotesListCtrl,
                    resolve: BreadCrumbsPageLimitResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate.notes.timeline', {
            url: Routing.generateAngularRoute('estimate_notes_timeline', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_notes_timeline', params)
            },
            views: {
                "": {
                    templateUrl: 'template/estimate/notes/timeline.html',
                    controller: EstimateNotesTimeViewCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.project.notes.timeline', {
            url: Routing.generateAngularRoute('project_notes_timeline', true),
            templateUrl: function (params) {
                return Routing.generate('project_notes_timeline', params)
            },
            views: {
                "": {
                    templateUrl: 'template/estimate/notes/timeline.html',
                    controller: EstimateNotesTimeViewCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate.notes_new', {
            url: Routing.generateAngularRoute('estimates_note', true),
            templateUrl: function (params) {
                return Routing.generate('estimates_note', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: function (params) {
                        return Routing.generate('new_estimatenotes', params)
                    },
                    controller: EstimateNewNotesCtrl,
                    resolve: BreadCrumbsResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate.notes_edit', {
            url: Routing.generateAngularRoute('edit_estimate_notes', true),
            templateUrl: function (params) {
                return Routing.generate('edit_estimate_notes', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: 'template/estimate/notes/edit_note.html',
                    controller: EstimateEditNotesCtrl,
                    resolve: BreadCrumbsPageLimitResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate.new_smsmessage', {
            url: Routing.generateAngularRoute('estimate_smsmessage', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_smsmessage', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: function (params) {
                        return Routing.generate('new_estimatemessages', params)
                    },
                    controller: EstimateNewSmsMessageCtrl,
                    resolve: BreadCrumbsPageLimitResolver
                }
            }
        }).
        state('loggedin.customer_list.view.estimate.new_phonecall', {
            url: Routing.generateAngularRoute('estimate_phone_call', true),
            templateUrl: function (params) {
                return Routing.generate('estimate_phone_call', params)
            },
            views: {
                "customerTabPane@": {
                    templateUrl: function (params) {
                        return Routing.generate('new_estimatephone_call', params)
                    },
                    controller: EstimateNewPhoneCallCtrl,
                    resolve: BreadCrumbsPageLimitResolver
                }
            }
        })
    }]);