commusoftCommon.service('accountingInterfaceValidation', function($q) {
    this.validateCredentials = function(form) {
        var deferred = $q.defer(),
            form_keys = Object.keys(form),
            errors = {};

        for (var i = 0, x = form_keys.length; i < x; i++) {
            var key = form_keys[i];
            if (key !== "errors") { // note that errors are pushed into the form_keys object when errors return from the server
                if (!this.validatePrescence(form[key])) {
                    errors[key] = "Please enter a " + key;
                }
            }
        }

        if (Object.keys(errors).length === 0) {
            deferred.resolve();
        } else {
            deferred.reject(errors);
        }

        return deferred.promise;
    }

    this.validatePrescence = function(input) {
        if (input) {
            return true
        } else {
            return false
        }
    }
});