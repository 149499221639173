/*==========================================================================================
	Companion service to:
	- web/javascripts/commusoft/controllers/add_new_contact.js
==========================================================================================*/

csmodule.service('addNewContact', ['$http','prefix', function($http, prefix) {
  /*==========================================================================================
		Endpoints
	==========================================================================================*/
    this.new_contact_path = prefix + '/diary/contact/new';

  /*==========================================================================================
		Crud methods
	==========================================================================================*/
	this.saveContact = function saveContact(contact, id) {
        return $http.post(prefix + '/diary/' + id + '/contact/new', 'data=' + encodeURIComponent(angular.toJson(contact)));
	}
}]);