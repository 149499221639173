csmodule.directive('editmilestone', function(sidePanel, $http) {

    var handleEditingMilestone = function handleEditingMilestone(scope, element, attrs) {

        scope.handleMilestone = function handleMilestone() {
            var milestone_name_valid = attrs.milestoneName.length > 0;

            if(milestone_name_valid) {
                scope.checkMilestoneUrl = scope.$parent.$parent.checkMilestoneUrl;
                $http.post(scope.checkMilestoneUrl, 'milestone=' + attrs.milestoneName).success(function (data, status) {
                    if(data == true) {
                        scope.updating = false;
                        scope.$parent.milestone_name_error = false;
                        scope.$parent.milestone_name_check_error = true;
                    } else {
                        scope.updating = true;
                        scope.editMileStone();
                    }
                });

            }else {
                scope.handleErrorUI();
            }
        }

        scope.editMileStone = function editMileStone() {
            scope.$parent.milestone_name_error = false;
            scope.$parent.milestone_name_check_error = false;

            var spreadsheet_scope = scope.$parent.$parent,
                path = scope.$parent.$parent.editMilestoneUrl,
                milestoneId = scope.$parent.$parent.categoryId;

            spreadsheet_scope.saveMilestoneName(path, milestoneId, attrs.milestoneName).then(function() {
                scope.updating = false;
                sidePanel.close();
            });
        }

        scope.handleErrorUI = function handleErrorUI() {
            scope.$parent.milestone_name_error = true;
            scope.$parent.milestone_name_check_error = false;
        }

        scope.close = function close() {
            scope.$parent.milestone_name_error = false;
            scope.$parent.milestone_name_check_error = false;
            sidePanel.close();
        }
    }

    return {
        restrict: 'A',
        templateUrl: 'template/estimate/estimate_details/todos/edit_milestone.html',
        scope: {
        },
        link: handleEditingMilestone
    }
});