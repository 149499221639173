commusoftCommon.directive('assetWidget', function($http, prefix) {
    var handleAssetType = function(scope, element, attrs) {
        scope.params = {
          propertyId: attrs.propertyId,
            tablePKID: attrs.tablePkId ? attrs.tablePkId : '',
            screenType: attrs.screenType
        };
        scope.assetOptions = [];
        scope.inputs={};
        scope.inputs.jobassets=[];

        scope.tablePKID = attrs.tablePkId ? attrs.tablePkId : '';
        scope.assetId = attrs.assetId ? attrs.assetId : '';

        //# Get the assets details which is associated into job.
        if(scope.tablePKID || scope.assetId) {
            $http.get(prefix + '/get_job_asset_details?propertyId=' + scope.params.propertyId + '&jobId='+scope.tablePKID + '&assetId='+scope.assetId).success(function (data,status){
                if(status == 200){
                    scope.onSubmitCallback(data);
                }
            });
        }

        scope.openAssetWidget = function() {
          var exists = _.filter(scope.assetOptions, function(x){
            return _.contains(scope.inputs.jobassets, ''+x.id);
          });
          scope.$broadcast('jobAsset:open-widget', {
            selectedAssets: angular.copy(exists)
          });
        };

        scope.reset = function(){
          scope.assetOptions=[];
          scope.inputs.jobassets=[];
        };

        scope.closeAssetCallback = function() {
        };

        scope.onSubmitCallback = function(items) {
          scope.assetOptions = items;
          scope.inputs.jobassets = _.pluck(scope.assetOptions, 'id');
        };

        scope.$watch('inputs.jobassets', function (e) {
            scope.$emit('jobAsset:selected', {
                selectedAssets: scope.inputs.jobassets
            });
        });

    };
    return {
        require: '^form',
        restrict: 'E',
        templateUrl: '/template/asset_widget.html',
        link: handleAssetType,
    }
});

