function standardAssetFieldsCtrl($scope, $http, prefix, $state, toastBox) {
    $scope.saving = false;

    $http.get(prefix + '/system_settings/get_standard_asset_fields').success(function (data) {
        $scope.assetRecord = data.assetRecord;
        $scope.$broadcast("Dynamic_Form_Inputs", { 'data': data.assetRecord, 'dynamicFields': data.dynamicFields,
            'assetFields': data.allAssetDetails, 'engineerFields': data.engineerFields});
    });

    $scope.$on("Dynamic_Form_Outputs", function (event, message) {
        $scope.assetRecords = message.data;
        $scope.dynamicDropdownDependencies =(!_.isEmpty(message.dynamicDropDownDependencies)) ? message.dynamicDropDownDependencies : null;
        $scope.handleSave();
    });

    $scope.handleSave = function () { console.log('angular.toJson($scope.assetRecords)', (angular.toJson($scope.assetRecords)));
        $scope.saving = true;
        $http.post(prefix + '/system_settings/save_standard_asset_fields', "assets[data]=" + encodeURIComponent(angular.toJson($scope.assetRecords)) + "&assets[dynamicDropDownDependencies]=" + encodeURIComponent(angular.toJson($scope.dynamicDropdownDependencies))).success(function (data, status) {
            if (status == 200) {
                $scope.saving = false;
                // $state.go('loggedin.system_settings');
                toastBox.show('Standard asset fields updated', 2000);
            }
        });
    }
}

function AssetDynamicFieldsCtrl($scope, $state, $http, prefix, assetdynamicresolver, $filter, canLoad, $q, $modal) {
    $scope.dynamicAssetFields = assetdynamicresolver.dynamicAssetFields;
    $scope.show_default_panel = true;
    $scope.no_results = false;
    $scope.dynamicAssetFieldsCount = assetdynamicresolver.count;

    $scope.$watch('searchFilter', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.fetchDynamicData();
        }
    });
    var canceler = null;
    $scope.fetchDynamicData = function () {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchFilter == undefined) ? '' : $scope.searchFilter;
        $http.get(prefix + '/listDynamicAsset?searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.dynamicAssetFields = data.dynamicAssetFields;
            $scope.show_default_panel = true;
            if($scope.dynamicAssetFields == ''){
                $scope.no_results = true;
            } else {
                $scope.no_results = false;
            }
        })
    }

    $scope.$on('onHandleDynamicAsset', function(e,data) {
        $scope.fetchDynamicData();
    });

    $scope.triggerDelete = function (id, description) {
        $scope.selectedId = id;
        $scope.selectedDescription = description;
        $scope.shouldBeOpen = true;
    };

    $scope.handleSelectingOption = function handleSelectingOption(fieldData) {
      if($scope.selectedField && $scope.selectedField.id === fieldData.id) {
        return;
      }
      $scope.show_default_panel = false;
      $scope.deselectAllOptions();
      fieldData.selected = true;
      $scope.selectedField = fieldData;
      $scope.$broadcast("shouldBeLoad", fieldData);
    };

    $scope.deselectAllOptions = function deselectAllOptions() {
        angular.forEach($scope.dynamicAssetFields,function(values,keys){
            $scope.dynamicAssetFields[keys].selected = false;
        });
    };

    $scope.openFieldSidepanel = function(data) {
        var modalInstance = $modal.open({
            windowClass: 'df-action df-action-add_dependant-modal df-action-modal sidepanel',
            templateUrl: '/template/side_panels/add_asset_dynamic_field.html',
            controller: 'AddDynamicFieldPanel',
            keyboard: false,
            resolve: {
                getFieldOptions: function(){
                    return {
                        data: data
                    };
                }
            }
        });
        modalInstance.result.then(
            function(){
            },
            function(data){
                //console.log('sidepanel is closed...')
                if(data == 'afterSave') {
                    $scope.fetchDynamicData();
                }
            });
    };
}

function AddDynamicFieldPanel($scope, $rootScope, $timeout, $state, prefix, $http, $q, getFieldOptions, $modalInstance, dfConfirmationToast) {

    $scope.elemId = 'editDynamicFieldModal';
    $scope.inputText={};
    $scope.inputText.field_name=getFieldOptions.data.description;
    $scope.field_id=getFieldOptions.data.id;
    $scope.loading = false;
    $scope.listUrl = getFieldOptions.listUrl;
    $scope.dynamicFieldId = getFieldOptions.data.selectedOption;
    $scope.data = [];
    $scope.fieldDependencies = [];

    //console.log('getFieldOptions', getFieldOptions);
    $scope.getFieldOptionsData = function() {
        $scope.loading = true;
        $http.get(prefix + '/'+ $scope.listUrl +'?fieldId=' + $scope.dynamicFieldId ).success(function (value,status){
            if(status == 200){
                $scope.data = value.options;
                $scope.fieldDependencies = value.fieldDependencies;
                $scope.loading = false;
            }
        });
    };
    //$scope.getFieldOptionsData();

    $modalInstance.opened.then(function(isOpened) {
        if (isOpened) {
            dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
            $timeout(function(){
                var $element = $('#'+$scope.elemId);
                $element.bind('keydown', function(e){
                    if(e.which === 13) {
                        $scope.handleAssetField();
                    }
                    if(e.which === 27) {
                        $scope.onClose();
                    }
                });
            },300);
        }
    });

    $scope.handleAssetField = function() {

        var addUrl = prefix + "/saveDynamicAsset";
        if($scope.inputText.field_name) {
            $http.post(addUrl, "dynamicAsset[fieldName]=" + $scope.inputText.field_name + "&dynamicAsset[fieldId]=" + $scope.field_id).
            success(function (data, status) {
                if (status == 200) {
                    $scope.onClose("afterSave");
                }
            });
        }
    }

    $scope.onChangeDynamicField = function() {
        if($scope.inputText.field_name == '') {
            $scope.isDisabledSave = true;
            $scope.error = 'Dynamic field name cannot be blank.';
        } else {
            $scope.isDisabledSave = false;
            $scope.error = null;
        }
        return $scope.error === null ? true : false;
    }

    $scope.onClose = function(info) {
        $modalInstance.dismiss(info);
    };

    $scope.$on('$destroy', function(){
        $(document).unbind('keydown');
        $('#'+$scope.elemId).unbind('keydown');
        $('body').find('.modal-backdrop').off('click');
    });
}

function AsssetDynamicFieldOptionMergeCtrl($scope, $state, $http, prefix, assetDynamicFieldOptionResolver, warningModal, toastBox, canLoad, $q){
    $scope.fieldOptions = assetDynamicFieldOptionResolver.fieldOptions;
    $scope.totalfieldOptions = assetDynamicFieldOptionResolver.count;
    $scope.fieldId = assetDynamicFieldOptionResolver.fieldId;
    $scope.fieldOptionsToMerge = $scope.fieldOptions;
    $scope.searchText = '';
    $scope.enableMergeButton = false;
    $scope.enableMerge = true;
    $scope.selectedOption = [];
    $scope.pageNum = $state.current.data.pageNum;
    $scope.limit = $state.current.data.limit;
    $scope.currentPage = parseInt($state.current.data.pageNum);
    $scope.shouldBeOpen = false;
    $scope.selectedId = '';
    $scope.toggle_message = 'Select All';

    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: function() {
                var selectedOptions = ($scope.selectedOption.length) ? $scope.selectedOption.join() : '';
                return prefix + '/list_merge_dynamic_field_options?fieldId='+ $scope.fieldId +'&selectedId=' + selectedOptions;
            },
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }

    $scope.select2OptionsInline = {
        minimumInputLength: 2,
        ajax: {
            url: function() {
                return prefix + '/list_merge_dynamic_field_options?fieldId='+ $scope.fieldId +'&selectedId=' + $scope.selectedId;
            },
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        $scope.pageNum = 1;
        if (typeof(newVal) != 'undefined' && typeof(oldVal) != 'undefined') {
            $scope.selectedOption = [];
            $scope.handleMergeButton();
            $scope.fetchFieldOptions($scope.pageNum);
        }
    });

    $scope.enterKeyPress = function (event) {
        if(event.keyCode == 13) {
            event.preventDefault();
        } return;
    }

    var canceler = null;
    $scope.fetchFieldOptions = function (pageNum) {
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        $http.get(prefix + '/list_merge_dynamic_field_options?fieldId='+ $scope.fieldId +'&searchText=' + encodeURIComponent($scope.searchText) +
            '&page=' + pageNum + '&limit=' + $scope.limit , {timeout: canceler.promise}).success(function (data) {
            if(data.fieldOptions && data.fieldOptions.length) {
                angular.forEach(data.fieldOptions, function (value, key) {
                    value.checked = false;
                });
            }
            angular.forEach(data.fieldOptions, function (value, key) {
                if ($scope.selectedFieldOption && value.id == $scope.selectedFieldOption.id) {
                    value.disableCheckBox = true;
                }else{
                    value.disableCheckBox = false;
                }
            });
            $scope.fieldOptions = data.fieldOptions;
            $scope.totalfieldOptions = data.count;
            $scope.selectedOption = [];
            $scope.selectAllChecked = false;
            $scope.toggle_message = 'Select All';
        })
    }

    $scope.toggleSelection = function toggleSelection(selectedId) {
        var idx = $scope.selectedOption.indexOf(selectedId);

        if (idx > -1) {
            $scope.selectedOption.splice(idx, 1);
            angular.forEach($scope.fieldOptions, function (value, key) {
                if (value.id == selectedId) {
                    value.checked = false;
                }
            });
            $scope.selectAllChecked = false;
            $scope.toggle_message = 'Select All'
        } else {
            if(($scope.selectedFieldOption && $scope.selectedFieldOption.id != selectedId) || !$scope.selectedFieldOption){
                $scope.selectedOption.push(selectedId);
            }

            angular.forEach($scope.fieldOptions, function (value, key) {
                if($scope.selectedFieldOption){
                    if (value.id == selectedId && selectedId != $scope.selectedFieldOption.id) {
                        value.checked = true;
                    }
                }else{
                    if(value.id == selectedId){
                        value.checked = true;
                    }
                }

            });
            if($scope.selectedOption.length == $scope.fieldOptions.length){
                $scope.selectAllChecked = true;
                $scope.toggle_message = 'Unselect All'
            }
        }

        $scope.handleMergeButton();
        $scope.listFieldOptions('');
    };

    $scope.handleMergeButton = function() {
        if($scope.selectedFieldOption && $scope.selectedFieldOption.id && $scope.selectedOption.length > 0) {
            for(var y=0; y < $scope.selectedOption.length; y++){
                if($scope.selectedOption[y] != $scope.selectedFieldOption.id){
                    $scope.enableMergeButton = true;
                }else{
                    $scope.enableMergeButton = false;
                    break;
                }
            }
        } else {
            $scope.enableMergeButton = false;
        }
    }

    $scope.listFieldOptions = function listFieldOptions(selectedOptionId){
        if(selectedOptionId && $scope.selectedOption.length) {
            return false
        }
        $scope.selectedOptionRow = '';
        $scope.selectedId = selectedOptionId;
        angular.forEach($scope.fieldOptions, function (value, key) {
            if (value.id == selectedOptionId) {
                value['active'] = true;
            } else {
                value['active'] = false;
            }
        });
    }

    $scope.mergeFieldOptions = function mergeFieldOptions(selectedId, selectedOptionToMerge){
        var mergeId;
        if(!selectedOptionToMerge) {
            return false;
        } else {
            mergeId = selectedOptionToMerge.id;
            if(selectedId == mergeId) {
                console.log('Trying to merge the same dynamic dropdown option');
                $scope.listFieldOptions('');
                return false;
            }
        }
        $http.post(prefix + '/mergeWithFieldOptions?fieldId='+ $scope.fieldId +'&selectedId=' + selectedId + '&mergeId=' +mergeId).
        success(function (data) {
            if (data.warning && data.warning.warning === true) {
                warningModal.show(data.warning.message, data.warning.title, data.warning.id);
                $scope.fetchFieldOptions($scope.pageNum);
                $scope.selectedOption = [];
                $scope.enableMergeButton = false;
            } else {
                $scope.fetchFieldOptions($scope.pageNum);
                $scope.selectedOption = [];
                $scope.enableMergeButton = false;
                toastBox.show('Merged dynamic dropdown options', 4000);
            }
        });
    }

    $scope.$watch('selectedFieldOption', function (newVal, oldVal) {
        $scope.handleMergeButton();
        if (typeof(newVal) != "undefined" && newVal != '') {
            angular.forEach($scope.fieldOptions, function (value, key) {
                if (value.id == newVal.id) {
                    value.disableCheckBox = true;
                } else {
                    value.disableCheckBox = false;
                }
            });
        }
    });

    $scope.mergeDescription = '';
    $scope.triggerMerge = function triggerMerge(){
        $scope.mergeDescription = $scope.selectedFieldOption.text;
        $scope.shouldBeOpen = true;
    }

    $scope.toggleAllRows = function toggleAllRows(toggle_message){
        if(toggle_message == 'Select All'){
            $scope.selectAllChecked = true;
            $scope.selectedOption = [];
            angular.forEach($scope.fieldOptions, function (value, key) {
                if($scope.selectedFieldOption){
                    if(value.id != $scope.selectedFieldOption.id){
                        $scope.selectedOption.push(value.id);
                        value.checked = true;
                    }
                }else{
                    $scope.selectedOption.push(value.id);
                    value.checked = true;
                }
            });
            $scope.toggle_message = 'Unselect All';
        }else if(toggle_message == 'Unselect All'){
            $scope.selectAllChecked = false;
            $scope.selectedOption = [];
            angular.forEach($scope.fieldOptions, function (value, key) {
                value.checked = false;
            });
            $scope.toggle_message = 'Select All';
        }
        if($scope.selectedFieldOption && $scope.selectedFieldOption.id && $scope.selectedOption.length > 0){
            for(var y=0; y < $scope.selectedOption.length; y++){
                if($scope.selectedOption[y] != $scope.selectedFieldOption.id){
                    $scope.enableMergeButton = true;
                }else{
                    $scope.enableMergeButton = false;
                    break;
                }
            }
        }else{
            $scope.enableMergeButton = false;
        }
    }
}

function AssetGroupsCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.assetGroupForm['assetgroup[description]'].$error.checkExists = true;
            } else {
                $scope.assetGroupForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("ASSETGROUP_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function AssetGroupsListEditCtrl($scope, $state, prefix, $http, assetgroupsresolver, defaultPagingValue, warningModal, canLoad, $q, $timeout) {
    $scope.assetGroups = assetgroupsresolver.assetGroups;
    $scope.totalAssetGroups = assetgroupsresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.canDelete = true;
    $scope.searchResult = false;
    $scope.show_filters = true;

    JustAddedUpdatedChanges.call(this, $scope, 'assetGroup', $state, defaultPagingValue, 'assetGroups');

    $scope.blurCallback = function () {
        $scope.focused = false;

        $timeout(function() {
            $scope.show_filters = true;
        }, 700);
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.currentPage = 1;
            $scope.fetchAssetGroups($scope.currentPage);
        }
    });

    $scope.$on("ASSETGROUP_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, assetgroupsresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.assetGroups.push({'id': message.id, 'description': message.description, 'justadded': true});
            assetgroupsresolver.assetGroups = $scope.assetGroups;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        assetgroupsresolver.count = parseInt(assetgroupsresolver.count) + 1
        $scope.totalAssetGroups = assetgroupsresolver.count;

    });

    $scope.editAssetGroup = function (id, description) {
        $state.transitionTo('loggedin.asset_groups.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, description) {
        $scope.selectedId = id;
        $scope.assetRecordDescription= description;
        $http.get(prefix + '/is_asset_group_delete?id=' + id).success(function (data) {
            if (data.warning === true && data.id == 'cannot-delete-modal-asset-group') {
                $scope.canDelete = false;
                warningModal.show(data.message, data.title, data.id);
            } else if (data.warning === true && data.id == 'can-archive-modal-asset-group') {
                $scope.canDelete = false;
                $scope.archiveShouldBeOpen = true;
            } else {
                $scope.canDelete = true;
                $scope.shouldBeOpen = true;
            }
        });
    }

    var canceler = null;
    $scope.fetchAssetGroups = function (pageNum) {

        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        if (searchText) {
            $scope.closeDelt = false;
        } else {
            $scope.closeDelt = true;
        }

        $http.get(prefix + '/listAssetGroups?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.assetGroups = data.assetGroups;
            $scope.totalAssetGroups = data.count;
            assetgroupsresolver.assetGroups = data.assetGroups;
            assetgroupsresolver.count = data.count;

            if (searchText != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }

            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function AssetGroupsEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedAssetGroups = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.asset_groups");
    }

    $scope.editAsset = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_assetGroups', "assetgroup[id]=" + this.selectedId + "&assetgroup[description]=" + encodeURIComponent(this.selectedAssetGroups)).
        success(function (data, status) {
            if (status == 200) {
                $scope.updating = false;
                if (data.error && data.error == 'Exists') {
                    $scope.current.editApplianceGroupForm['selectedAssetGroups'].$error.checkExists = true;
                } else {
                    $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                    $state.$current.parent.self.data.editedId = $scope.selectedId;
                    $state.$current.parent.self.data.limit = $scope.limit;
                    $state.transitionTo("loggedin.asset_groups");
                }
            }
        });
    }
}

function AssetGroupsConfigureCtrl($scope, $state, $http, prefix, toastBox, confirmationBoxHelper,$timeout) {
    $scope.assetGroupId = $state.params.id;
    $scope.assetDraftFieldId = '';
    $scope.assetFieldId = '';
    $scope.selectVersion = '';
    //$scope.assetFieldIdUsed = false;

    $scope.getAssetGroupConfigForm = function(assetFieldId) {
        var path = '/system_settings/asset_groups/'+$scope.assetGroupId+'/get_asset_group_configure_form';
        if(assetFieldId) {
            path = path + '?assetFieldId=' + assetFieldId;
        }
        $http.get(prefix + path).success(function (data) {

          $scope.assetRecord = data.assetRecord;
          $scope.assetDraftFieldId = data.assetDraftFieldId;
          $scope.assetFieldStatus = data.assetFieldStatus;
          $scope.assetFieldId = data.assetFieldId;
          $scope.assetVersions = data.assetVersions;
          $timeout(function(){
            $scope.selectVersion = data.assetFieldId;
            $scope.saving = false;
          },0);

            $scope.$broadcast("Dynamic_Form_Inputs", { 'data': data.assetRecord, 'dynamicFields': data.dynamicFields,
                'assetFields': data.allAssetDetails, 'engineerFields': data.engineerFields, 'standardRecordFields': data.standardRecordFields});
        });
    }
    $scope.getAssetGroupConfigForm('');
    $scope.$on("Dynamic_Form_Outputs", function (event, message) {
        $scope.assetRecords = message.data;
        $scope.dynamicDropdownDependencies = (!_.isEmpty(message.dynamicDropDownDependencies)) ? message.dynamicDropDownDependencies : null;
        $scope.subAssetDeleted = message.subAssetDeleted ? message.subAssetDeleted : null;
        $scope.handleSave();
    });

    $scope.versionStatus = function(option) {
        $scope.versionStatus = '';
        return "Active";
        if(option.status == 1) {
            $scope.versionStatus[option.id] = "(Draft)";
        } else if(option.status == 2) {
            $scope.versionStatus[option.id] = "(Active)";
        } else if(option.status == 3) {
            $scope.versionStatus[option.id] = "(In-active)";
        }
    }

    $scope.changeVersions = function(value) {
        $scope.getAssetGroupConfigForm(value);
    }

    $scope.handleSave = function() {

        var confirmationText = '';
        $scope.saving = true;
        if(($scope.assetFieldStatus != 1) && ($scope.assetFieldStatus != '')) {
            //# Check whether already having draft record or not.
            if($scope.assetDraftFieldId) {
                confirmationText = "Form design already used. Do you want to save into the draft version?";
            } else {
                confirmationText = "Form design already used. Do you want to generate with new version?";
            }
            confirmationBoxHelper.getConfirmation(confirmationText, $scope)
                .then(function() {
                    $scope.saveData($scope.assetDraftFieldId);
                    $scope.saving = false;
                }, function() {
                    $scope.saveData($scope.assetFieldId);
                    $scope.saving = false;
                });
        } else {
            $scope.saveData($scope.assetFieldId);
            $scope.saving = false;
        }
    }

    $scope.saveData = function(updateVersionId) {

        $scope.params = "assets[assetGroupId]=" + encodeURIComponent($scope.assetGroupId) + "&assets[data]=" + encodeURIComponent(angular.toJson($scope.assetRecords))
            + "&assets[dynamicDropDownDependencies]=" + encodeURIComponent(angular.toJson($scope.dynamicDropdownDependencies)) + "&assets[updateVersionId]=" + encodeURIComponent(updateVersionId);
        if($scope.subAssetDeleted) {
            $scope.params += "&assets[subAssetDeleted]="+$scope.subAssetDeleted;
        }
        $scope.saving = true;
        $http.post(prefix + '/system_settings/asset_groups/save_asset_group_configure_form', $scope.params).
        success(function (data) {
            if (data.error && data.error == 'Exists') {
                toastBox.show('Certificate key already exists', 2000);
            }else{

                //$scope.saving = false;
                toastBox.show('Records saved', 2000);
            }
            $scope.getAssetGroupConfigForm(data);
        });
    }

    $scope.convertDraftToActive = function() {
        $scope.convertDraftToActiveShouldBeOpen =  true;
    }
}

function AssetTypesConfigureCtrl($scope, $state, $http, prefix, toastBox, confirmationBoxHelper, $timeout) {
    $scope.assetTypeId = $state.params.id;
    $scope.assetDraftFieldId = '';
    $scope.assetFieldId = '';
    $scope.selectVersion = '';
    //$scope.assetFieldIdUsed = false;

    $scope.getAssetTypeConfigForm = function(assetFieldId) {
        var path = '/system_settings/asset_type/'+$scope.assetTypeId+'/get_asset_type_configure_form';
        if(assetFieldId) {
            path = path + '?assetFieldId=' + assetFieldId;
        }
        $http.get(prefix + path).success(function (data) {

            $scope.assetRecord = data.assetRecord;
            $scope.assetDraftFieldId = data.assetDraftFieldId;
            $scope.assetFieldStatus = data.assetFieldStatus;
            $scope.assetFieldId = data.assetFieldId;
            $scope.assetVersions = data.assetVersions;
            $timeout(function(){
              $scope.selectVersion = data.assetFieldId;
              $scope.saving = false;
            },0);


            $scope.$broadcast("Dynamic_Form_Inputs", { 'data': data.assetRecord, 'dynamicFields': data.dynamicFields,
                'assetFields': data.allAssetDetails, 'engineerFields': data.engineerFields,
                'standardRecordFields': data.standardRecordFields, 'groupRecordField': data.groupRecordField});
        });
    }

    $scope.getAssetTypeConfigForm('');
    $scope.$on("Dynamic_Form_Outputs", function (event, message) {
        $scope.assetRecords = message.data;
        $scope.dynamicDropdownDependencies = (!_.isEmpty(message.dynamicDropDownDependencies)) ? message.dynamicDropDownDependencies : null;
        $scope.subAssetDeleted = message.subAssetDeleted ? message.subAssetDeleted : null;
        $scope.handleSave();
    });

    $scope.changeVersions = function(value) {
        $scope.getAssetTypeConfigForm(value);
    }

    $scope.handleSave = function() {

        var confirmationText = '';
        $scope.saving = true;
        if(($scope.assetFieldStatus != 1) && ($scope.assetFieldStatus != '')) {
            //# Check whether already having draft record or not.
            if($scope.assetDraftFieldId) {
                confirmationText = "Form design already used. Do you want to save into the draft version?";
            } else {
                confirmationText = "Form design already used. Do you want to generate with new version?";
            }
            confirmationBoxHelper.getConfirmation(confirmationText, $scope)
                .then(function() {
                    $scope.saveData($scope.assetDraftFieldId);
                    $scope.saving = false;
                }, function() {
                    $scope.saveData($scope.assetFieldId);
                    $scope.saving = false;
                });
        } else {
            $scope.saveData($scope.assetFieldId);
            $scope.saving = false;
        }
    }

    $scope.saveData = function(updateVersionId) {
        $scope.saving = true;
        $scope.params = "assets[assetTypeId]=" + $scope.assetTypeId + "&assets[data]=" + encodeURIComponent(angular.toJson($scope.assetRecords))
            + "&assets[dynamicDropDownDependencies]=" + encodeURIComponent(angular.toJson($scope.dynamicDropdownDependencies)) + "&assets[updateVersionId]=" + updateVersionId;
        if($scope.subAssetDeleted) {
          $scope.params += "&assets[subAssetDeleted]="+$scope.subAssetDeleted;
        }
        $http.post(prefix + '/system_settings/asset_type/save_asset_type_configure_form', $scope.params).
        success(function (data) {

            $scope.getAssetTypeConfigForm(data);

            //$scope.saving = false;
            toastBox.show('Records saved', 2000);
        });
    }

    $scope.convertDraftToActive = function() {
        $scope.convertDraftToActiveShouldBeOpen =  true;
    }
}

function AssetTypesCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.assetTypeForm['assettypes[description]'].$error.checkExists = true;
            } else {
                $scope.assetTypeForm.$setPristine();
                $scope.settingsAssetgroupsid = "";
                $scope.description = "";
                $scope.$broadcast("ASSET_TYPE", { 'id': data.id, 'description': data.description, 'settingsAssetgroupsDescription': data.settingsAssetgroupsDescription, 'assetGroupId': data.assetGroupId });
            }
            $scope.saving = false;
        });
    }
}

function AssetTypesEditCtrl($scope, $state, $http, prefix, assettypesresolver, defaultPagingValue) {
    $scope.selectedAssetType = $state.params.description;
    $scope.selectedAssetGroup = $state.params.settingsAssetgroupsDescription;
    $scope.assetGroupId = parseInt($state.params.assetGroupId);
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.assetgroupslist = assettypesresolver.assetgroupslist;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.asset_type");
    }

    $scope.editAssetType = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_assettype', "assettypes[id]=" + this.selectedId + "&assettypes[description]=" + encodeURIComponent(this.selectedAssetType) + "&assettypes[settingsAssetgroupsid]=" + this.assetGroupId).
        success(function (data, status) {
            if (status == 200) {
                $scope.updating = false;
                if (data.error && data.error == 'Exists') {
                    $scope.current.editAssetTypeForm['selectedAssetType'].$error.checkExists = true;
                } else {
                    $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                    $state.$current.parent.self.data.editedId = $scope.selectedId;
                    $state.$current.parent.self.data.limit = $scope.limit;
                    $state.transitionTo("loggedin.asset_type");
                }
            }
        });
    }
}

function AssetTypesEditListCtrl($scope, $state, prefix, $http, $timeout, assettypesresolver, defaultPagingValue, canLoad, $q, warningModal, confirmationBoxHelper) {
    $scope.assettypes = assettypesresolver.assettypes;
    $scope.totalAssetTypes = assettypesresolver.count;
    $scope.assetgroupslist = assettypesresolver.assetgroupslist;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.selectedGroup = '';
    $scope.searchResult = false;
    $scope.show_filters = true;
    $scope.canDelete = true;

    JustAddedUpdatedChanges.call(this, $scope, 'assettype', $state, defaultPagingValue, 'assettypes');

    $scope.change = function () {
        $scope.currentPage = 1;
        $scope.fetchAssetTypes($scope.currentPage, this.selectedGroup);
    }

    $scope.blurCallback = function () {
        $scope.focused = false;

        $timeout(function() {
            $scope.show_filters = true;
        }, 700);
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.currentPage = 1;
            $scope.fetchAssetTypes($scope.currentPage);
        }
    });

    var canceler = null;
    $scope.fetchAssetTypes = function (pageNum, selectedGroup) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;

        if (searchText) {
            $scope.closeDelt = false;
        }
        else {
            $scope.closeDelt = true;
        }

        var selGroup = selectedGroup || $scope.selectedGroup;
        $http.get(prefix + '/listAssetType?page=' + pageNum + '&group=' + selGroup + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.assettypes = data.assettypes;
            $scope.selectedGroup = selGroup;
            $scope.totalAssetTypes = data.count;
            assettypesresolver.assettypes = data.assettypes;
            assettypesresolver.count = data.count;


            if ((searchText != '') || (selGroup != '')) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }

            $scope.triggerJustAddedUpdatedTags();
        })
    }

    $scope.$on("ASSET_TYPE", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, assettypesresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.assettypes.push({'id': message.id, 'description': message.description, 'settingsAssetgroupsDescription': message.settingsAssetgroupsDescription, 'assetGroupId': message.assetGroupId, 'justadded': true});
            assettypesresolver.assettypes = $scope.assettypes;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        assettypesresolver.count = parseInt(assettypesresolver.count) + 1
        $scope.totalAssetTypes = assettypesresolver.count;
    });

    $scope.triggerDelete = function (id, isAssetTypeUsed) {
        $scope.invoiceRecord= '';
        $scope.selectedId = id;
        var confirmationText = '';
        if(isAssetTypeUsed) {
            confirmationText = "Asset type already used. Do you want to delete?";
            confirmationBoxHelper.getConfirmation(confirmationText, $scope)
                .then(function() {
                    $http.get(prefix + '/is_asset_type_hascustomerassets?id=' + id).success(function(data) {
                        if (data.warning === true) {
                            $scope.archiveShouldBeOpen = true;
                        }else{
                            $scope.shouldBeOpen = true;
                        }
                    });
                });
        } else {
            $scope.shouldBeOpen = true;
        }


        /*$http.get(prefix + '/is_asset_type_delete?id=' + id).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }else{
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });*/
    }

    $scope.editAssettype = function (assettype) {
        $state.transitionTo('loggedin.asset_type.edit',
            {'id': assettype.id,
                'description': assettype.description,
                'settingsAssetgroupsDescription': assettype.settingsAssetgroupsDescription,
                'assetGroupId': assettype.assetGroupId,
                'pageNum': $scope.currentPage, 'limit': $scope.limit
            })
    }

    $scope.configureAsset = function(assetTypeId) {
        $state.transitionTo('loggedin.asset_type.configureasset', {'id': assetTypeId})
    }
}
