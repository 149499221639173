commusoftCommon.directive('partialSubmit', ['$window', 'prefix', function($window, prefix) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var url = prefix + element.attr('action');
            element.removeAttr('action');
            var formname = element.attr('name');
            element.on('submit', function(e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                if(scope[formname].$invalid){
                    return false;
                }
                scope.saving = true;
                scope.$apply(scope.performFormSubmit(url, element));
                return false;
            });
        }
    };
}]);