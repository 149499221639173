commusoftCommon.directive('csDesignSmartableCell', function($compile, $filter, tableCollection, $rootScope) {
    return{
        restrict: 'A',
        scope: false,
        link: function(scope, element, attrs){
            attrs.limitText = scope.$eval(attrs.limitText);

            if(attrs.row) scope.row = scope.$eval(attrs.row);
            else {
                attrs.rowIndex = scope.$eval(attrs.rowIndex);
                var data = tableCollection.collection[attrs.csDesignSmartableCell].data;
                scope.row = data[attrs.rowIndex];
            }

            var columnFormats  = tableCollection.collection[attrs.csDesignSmartableCell].column_formats? tableCollection.collection[attrs.csDesignSmartableCell].column_formats: {};
            var content = scope.row[attrs.column];

            // Old formatting
            if (!scope.show && !attrs.limitText) content = content;
            else if (!scope.show && attrs.limitText && content.length > 25) content = $filter('limitTo')(content, 25) + '...';

            // Formatting as by the column format definitions
            if (columnFormats[attrs.column]) {
                switch (columnFormats[attrs.column].type) {
                    case "binded-html": { // Compiling content with scope
                        content = $compile(columnFormats[attrs.column].htmlWrapper)(scope);
                        break;
                    }
                    case "filtered": { // Applying angular filters
                        if(columnFormats[attrs.column].filter[0] == "dynamicDecimalWithCurrency"){
                            content = $filter(columnFormats[attrs.column].filter[0])(content, $rootScope.CustomCurrency, 4);
                        }else{
                            content = $filter(columnFormats[attrs.column].filter[0])(...[content].concat(columnFormats[attrs.column].filter.slice(1)));
                        }
                        break;
                    }
                    default: content = content; break;
                }
            }

            element.html(content);
        }
    }
});