commusoftCommon.directive('loadedImg', ['prefix', '$http', '$timeout', function() {
    return {
        restrict: 'ACE',
        scope: {
            loadedImgSrc: '&',
        },
        replace: true,
        template: '<div class="loaded-img-wrapper"><img ng-src="{{loadedImgSrc()}}" class="loaded-img-invisible" /><div class="spinner-wrapper"><i class="loaded-img-spinner fa fa-spinner fa-spin"></i></div></div>', 
        link: function($scope, elem, attrs) {
            elem.find('img').on('load', function() {
                $(elem).find('.spinner-wrapper').remove();
                $(elem).find('img').removeClass('loaded-img-invisible');
            });
        }
    }
}]);
