commusoftCommon.directive('flexibleSidepanel', ['prefix', '$http', '$modal', 'tableCollection', '$document', '$timeout', 'clickEvents', '$rootScope', '$compile', 'dragHelper', '$state', 'reportFilterSettings', 'jobData', 'changeStatusDrop', function(prefix, $http, $modal, tableCollection, $document, $timeout, clickEvents, $rootScope, $compile, dragHelper, $state, reportFilterSettings, jobData, changeStatusDrop) {
    /* This sidepanel is called flexibleSidepanel because it does not require isolate scope */
    return {
        restrict: 'ACE',
        scope: false,
        link: function($scope, elem, attrs) {
            $scope.setVariables = function() {
                $scope.sidepanelCategory = attrs.category;
                $scope.sidepanelTemplate = attrs.template;
                $scope.sidepanelData = attrs.source;
                $scope.openAutomatically = attrs.openAutomatically;

                if ($scope.openAutomatically !== "false") {
                    $(elem).click(function(evt) {
                        $scope.showSidepanel(evt, 0);
                    });
                }
            }

            $scope.$on('flexibleSidepanel_' + attrs.template, function(params) {
                $scope.setVariables();
                $scope.showSidepanel(params['evt'], params['index']);
            });

            $scope.$on('flexibleSidepanel_close', function(e, event_object) {
                $scope.setVariables();
                $scope.removeBothElements(event_object.click_event);
            });

            $scope.setVariables();
        },
        controller: function($scope) {
            /*====================================================
               Unbind keyboard events
               ====================================================*/
            $scope.unbindKeyboardEvents = function unbindKeyboardEvents() {
                $document.unbind('keydown.sidepanelShortcuts');
            }


            $scope.removeBothElements = function removeBothElements(e){
                var should_close = true;
                var anchor = 'A';
                var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');
                var overlay = document.getElementById('page-overlay');

                if (overlay.classList.contains('darker') === true){
                    should_close = false;
                }

                if (should_close === true) {
                    // if the target element is an anchor (ie the save the cancel link)
                    // then prevent the link changing the URl.
                    if (e != undefined && e.currentTarget.tagName === anchor) {
                        e.preventDefault();
                    }
                    if (panel[0]) {
                        panel[0].classList.remove('in-view');
                    }
                    overlay.classList.remove('in-view');

                    $timeout(function() {
                        $(overlay).remove();
//                              overlay.parentNode.removeChild(overlay);
                        if (panel[0]) {
//                                  panel[0].parentNode.removeChild(panel[0]);
                            $(panel[0]).remove();
                        }

                    }, 800);
                    // remove keyboard events
                    $scope.unbindKeyboardEvents();
                    $rootScope.$broadcast('closeall');
                }
            }

            $scope.showSidepanel = function showSidepanel(e, index) {

                var $isExistsPanel = $('#more-details-panel');
                if($isExistsPanel.length > 0) {
                  return;
                }
                if (index == undefined) {
                    index = 0;
                }

                var cat = $scope.sidepanelCategory,
                    tmplt = $scope.sidepanelTemplate,
                    tablePosition = index,
//                      sidepanelData = tableCollection.getSidepanelData(index, cat);
                    sidepanelData = $scope.sidepanelData;
                if (typeof tmplt !== 'string') {
                    return false;
                }

                if(cat == 'notifications') {
                    table_scope.triggerNotificationSidepanelFromDropdown(index);
                    return;
                }

                if(cat === "job_assigned_todos") {
                    var dropdownData = tableCollection.getDropdownData(cat);
                }

                $scope.changeStatusValues = changeStatusDrop.getData(cat);

                if(cat === 'job_parts'){
                    $scope.changeStatusValues = $scope.changeStatusValues[$scope.status]
                    $scope.fulfillments = angular.copy(sidepanelData.fulfillment);
                }

                if(sidepanelData.FulfillType && sidepanelData.FulfillType == 'General'){
                    $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Returned_To_Stock');
                    $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Returned');
                }

                if(sidepanelData.FulfillType && sidepanelData.FulfillType == 'FromStock'){
                    $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Cancelled');
                    $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Returned');
                }

                if(sidepanelData.FulfillType && sidepanelData.FulfillType == 'OrderFromSupplier' && $rootScope.clientJobType == 3){
                    $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Returned_To_Stock');
                }

                $scope.jobType = $rootScope.clientJobType;
                $scope.instanceId = sidepanelData;
                var values = $scope.instanceId.records;

                var instanceValue = [];
                angular.forEach(values, function(value, key) {
                    instanceValue.push(value.instanceId);
                }, instanceValue);

                $scope.instancesId = instanceValue.toString();

                $rootScope.instancesId = $scope.instancesId;

                var insertPanel = function insertPanel() {
                    // close all open widgets
                    $rootScope.$broadcast('closeall');
                    /*====================================================
                     Builds the panel element and insert it into the DOM,
                     the panel is hidden in the CSS so that it can be
                     animated with a CSS transition with the showPanel
                     function.
                     ====================================================*/
                    var constructAndInsertPanel = (function() {
                        var panel_wrap = document.createElement('div'),
                            sidepanelTemplate = '';

                        panel_wrap.id = 'more-details-panel';
                        panel_wrap.className = 'smart-table-sidepanel'
                        document.getElementsByTagName('body')[0].appendChild(panel_wrap);

                        /*====================================================
                         1. Fetches the sidepanel template to use
                         2. Brings the sidepanel data into the sidepanel's scope
                         3. Appends a compiled result of the data and template
                         into the view
                         ====================================================*/
                        var tpl_path = "/template/side_panels/"
                            + tmplt + ".html";
                        $scope.sidepanelTemplate = [];

                        var fetchsidepanelTemplate = function fetchsidepanelTemplate() {
                            $http.get(tpl_path).then(function(result){
                                sidepanelTemplate = result.data;
                                $scope.sidepanelData = sidepanelData;

                                if(dropdownData) {
                                    $scope.dropdownData = dropdownData;
                                }

                                $scope.currentTableIndex = index;
                                panel_wrap.appendChild($compile(result.data)($scope)[0]);

                                /*====================================================
                                 After the panel is built, push it into view
                                 and attach keyboard listeners
                                 ====================================================*/
                                var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');
                                $timeout(function() {
                                    panel[0].classList.add('in-view');
                                }, 10);
                                bindKeyboardEvents(index, tablePosition);
                                insertPageOverlay();
                                $scope.certificateError= 'saved';
                                $scope.print_and_post_message = "Your certificate is on its way to the customer";
                                $scope.print_and_post_error_message = null;
                                $http.get(prefix +'/check_print_post_credits').then(function(res) {
                                    if(res.data.error)
                                    {
                                        $scope.print_and_post_message = res.data.error ;
                                        $scope.certificateError = 'error';
                                    }
                                });
                            });
                        }
                        fetchsidepanelTemplate();
                    })();
                }

                var insertPageOverlay = function insertPageOverlay() {
                    /*====================================================
                     Builds the overlay element and insert it into the DOM,
                     the overlay is hidden in the CSS so that it can be
                     animated with a CSS transition
                     ====================================================*/
                    var constructAndInsertOverlay = (function(){
                        $scope.insertElm = function insertElm() {
                            var wrapper = document.createElement('div');
                            wrapper.id = 'page-overlay';
                            document.getElementsByTagName('body')[0].appendChild(wrapper);
                        }
                        if (document.getElementById('page-overlay') === null) {
                            $scope.insertElm();
                        }else {
                            // remove the existing one before inserting the new one
                            document.getElementById('page-overlay').remove();
                            $scope.insertElm();
                        }
                    })();
                    /*====================================================
                     Show the overlay element and attach click event listeners
                     to it.
                     ====================================================*/
                    var showAndAttachEvts = (function() {
                        $scope.handleElm = function handleElm() {
                            $timeout(function() {
                                var overlay = document.getElementById('page-overlay');
                                overlay.classList.add('in-view');
                            }, 100);

                            // attach click listeners
                            var overlay = document.querySelectorAll('#page-overlay');
                            var save_link = document.querySelectorAll('#more-details-panel.smart-table-sidepanel #save-panel-btn');
                            var cancel_link = document.querySelectorAll('#more-details-panel.smart-table-sidepanel #cancel-panel-btn');
                            for (var i = 0; i < overlay.length; i++) {
                                overlay[i].addEventListener('click',function(e){
                                    $rootScope.$broadcast('flex:sidepanel_close');
                                    $scope.removeBothElements(e)
                                });
                            }
                            for (var i = 0; i < save_link.length; i++) {
                                save_link[i].addEventListener('click', $scope.removeBothElements);
                            }
                            for (var i = 0; i < cancel_link.length; i++) {
                                cancel_link[i].addEventListener('click', $scope.removeBothElements);
                            }
                        }
                        $scope.handleElm();
                    })();
                }


                /*====================================================
                 Bind keyboard event listeners
                 - 'esc' closes the panel
                 - 'up' & 'down' to navigate through the rows
                 ** gets the correct index for the data from the index
                 of the table using $index so that pinned rows
                 are respected.
                 ====================================================*/
                var bindKeyboardEvents = function bindKeyboardEvents(row, index) {
                    $document.bind('keydown.sidepanelShortcuts', function(evt) {
                        if (evt !== undefined) {
                            var evt = evt;
                        } else {
                            var evt = window.event;
                        }
                        var src_element_exists = evt.srcElement !== undefined;

                        if (src_element_exists) {
                            var elm_type = evt.srcElement.tagName,
                                form_element = elm_type == 'INPUT' ||
                                    elm_type == 'SELECT' ||
                                    elm_type == 'TEXTAREA',
                                shortcut_is_allowed = !form_element;
                        } else {
                            var elm_type = evt.currentTarget.activeElement.localName,
                                form_element = elm_type == 'input' ||
                                    elm_type == 'select' ||
                                    elm_type == 'textarea',
                                shortcut_is_allowed = !form_element;
                        }

                        if(shortcut_is_allowed) {
                            var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');
                            var totalRows = $scope.sidepanelData.length - 1;

                            if (panel[0] != null) {
                                var handleEscKey = (function() {
                                    // esc keydown
                                    if (evt.keyCode == 27) {
                                        $scope.removeBothElements();
                                    }
                                })();
                                var handleShortcuts = (function() {
                                    // up keydown
                                    if (evt.keyCode == 38) {
                                        if (index != 0){
                                            index = index-1;
                                            var rowData = $scope.rows[index][0].row
                                            updatePanelContent(index, rowData);
                                            table_scope.active_actions_index --;
                                        }
                                    }
                                    // down keydown
                                    else if (evt.keyCode == 40) {
                                        if(index <= totalRows){
                                            index = index+1;
                                            var row_exists = $scope.rows[index] !== undefined;
                                            if(row_exists){
                                                var rowData = $scope.rows[index][0].row
                                                updatePanelContent(index, rowData);
                                                table_scope.active_actions_index ++;
                                            }else{
                                                index = index-1;
                                            }
                                        }
                                    }
                                })();
                            }
                        }
                    })
                }


                /*====================================================
                 Instantiate panel instance
                 ====================================================*/
                insertPanel();
            }

        }
    }
}]);