var chart_primary_yellow = 'rgba(239,207,154,1)',
    chart_light_slate_gray = 'rgba(122,141,155,1)',
    chart_pastel_light_blue = 'rgba(176,208,215,1)',
    chart_pastel_green = 'rgba(139,206,191,1)',
    chart_light_brown = 'rgba(207,154,131,1)',
    chart_melon_red = 'rgba(252,177,178,1)',
    chart_slate_gray = 'rgba(184,184,184,1)',
    chart_pastel_violet = 'rgba(190,160,194,1)',
    chart_light_green = 'rgba(188,212,165,1)',
    chart_deep_green = 'rgba(124,151,142,1)';

Highcharts.theme = {
  colors: [
    chart_primary_yellow,
    chart_light_slate_gray,
    chart_pastel_light_blue,
    chart_pastel_green,
    chart_light_brown,
    chart_melon_red,
    chart_slate_gray,
    chart_pastel_violet,
    chart_light_green,
    chart_deep_green
  ],
  chart: {
    backgroundColor: {
      linearGradient: [0, 0, 500, 500],
      stops: [
        [0, 'rgb(255, 255, 255)'],
        [1, 'rgb(240, 240, 255)']
      ]
    },
      alignTicks: false
  },
  title: {
    style: {
      color: 'rgba(80,91,101,1)',
      font: '16px "Arial", Verdana, sans-serif'
    }
  },
  subtitle: {
    style: {
      color: 'rgba(162,162,162,1)',
      font: 'bold 12px "Arial", Verdana, sans-serif'
    }
  },
  legend: {
    itemStyle: {
      font: '9pt Arial, Verdana, sans-serif',
      color: 'rgba(80,91,101,1)'
    },
    itemHoverStyle:{
      color: 'rgba(162,162,162,1)'
    }
  }
};

// Apply the theme
Highcharts.setOptions(Highcharts.theme);

csmodule.service('graphGenerator', ['$state','$rootScope', function($state,$rootScope) {
  this.generateGraph = function(type, graph_options, category){
    var category = category;
    var chart = {
      options: {
        chart: {
          type: 'line',
          backgroundColor: null,
          legend: {enabled: false}
        },
        plotOptions: {
          series: {
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 1,
            borderColor: 'white',
            lineWidth: 6,
            marker: {
              radius: 8
            },
            point: {
              events: {
                mouseOver: function(event) {
                  $rootScope.$broadcast('dashboard_widget:change_detail:' + category, this.category);
                },
                mouseOut: function(event) {
                  $rootScope.$broadcast('dashboard_widget:change_detail:' + category, 'default');
                }
              }
            }
          },
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true
            }
          },
          pie: {
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          area: {
            fillOpacity: 0.3
          }
        },
        tooltip: {
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderWidth: 0,
          style: {
            padding: 0
          },
          useHTML: true,
          formatter: function() {
            var key = this.x || this.key,
                tooltip = '<div class="cs-highcharts-tooltip">' +
                          '<strong>' +
                          this.series.name +
                          '</strong><span class="value">' +
                          key +
                          ': ' +
                          this.y +
                          '</span></div>';

            return tooltip;
          }
        },
        legend: {
          itemDistance: 15
        }
      },
      title: {text: ''},
      credits: {enabled: false},
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        minorTickLength: 0,
        endOnTick: false,
        title: {text: ''},
        gridLineColor: '#C5EEFA',
        plotLines: [{
          color: 'rgba(0,0,0,0)',
          width: 4,
          value: 0
        }],
        labels: {enabled: true},
        opposite: false
      },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        minorTickLength: 0,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        tickLength: 0
      }
    };

    if (type === 'column') {
      chart.options.chart.type = 'column';

      chart.xAxis.categories = graph_options.x_axis_categories;
      chart.series = graph_options.series;
    } else if (type === 'pie') {
      chart.options.chart.type = 'pie';

      chart.series = graph_options.series;
      var category = category;
      if(category == "Debtor days by age of debt"){
        angular.forEach(chart.series, function(series, key){
          angular.forEach(series['data'], function (value, i){
            value['events'] = { click : function () {
                if (value.name == 'Current') {
                  event = 'sales_current';
                }else if (value.name == '1-30 Days') {
                  event = 'sales_1_30_days';
                }else if (value.name == '31-60 Days') {
                  event = 'sales_31_60_days';
                }else if (value.name == '61-90 Days') {
                  event = 'sales_61_90_days';
                }else if (value.name == 'More than 90 Days') {
                  event = 'sales_morethan_90_days';
                }
                $state.go('loggedin.reporting.view.summary', {'category': 'sales', 'subcategory': event, 'flag' :1});
              }
            }
          });
        });
      }
    } else if (type === 'bar_with_line') {
      chart.options.chart.type = 'column';

      chart.xAxis.categories = graph_options.x_axis_categories;
      chart.series = graph_options.series;
    } else if (type === 'stacked_area') {
      chart.options.chart.type = 'area';

      chart.xAxis.categories = graph_options.x_axis_categories;
      chart.series = graph_options.series;
    } else if (type === 'stacked_bar') {
      chart.options.chart.type = 'column';

      chart.xAxis.categories = graph_options.x_axis_categories;
      chart.options.legend.reversed = true;
      chart.series = graph_options.series;
      chart.options.plotOptions.column = {
        stacking: 'normal'
      };
    } else if (type === 'guage') {
      chart.options.chart.type = 'pie';
      chart.options.tooltip.enabled = false;
      chart.options.plotOptions.series.enableMouseTracking = false;
      chart.options.plotOptions.pie = {
        startAngle: -135,
        endAngle: 135,
      }

      chart.series = graph_options.series;
      chart.series[0].innerSize = '80%';
      chart.series[0].data[1].color = 'rgba(230, 230, 230, 1)';

      var percentage_decimal = chart.series[0].data[0].y / (chart.series[0].data[0].y + chart.series[0].data[1].y),
          percentage_text = parseFloat((percentage_decimal * 100).toFixed(2)) + '%';

      chart.title = {
        text: percentage_text,
        align: 'center',
        verticalAlign: 'middle',
        style: {
          color: 'rgba(100,118,124,1)',
          fontWeight: 'bold',
          fontSize: '40px'
        },
        y: 2,
        x: -2
      }
    }

    chart.yAxis = graph_options.y_axis;

    if (chart.series) {
      chart.series.map(function(series, index) {
        series.dataLabels = {
          enabled: false
        }
      })
    }

    if(graph_options.options) {
      chart.options.chart = Object.assign(chart.options.chart, graph_options.options);
    }

    if(graph_options.title) {
        chart.title = Object.assign(chart.title, graph_options.title);
    }

    return chart;
  };
}]);

