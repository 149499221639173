commusoftCommon.directive('select2Wrapper', ['prefix', '$http',function(prefix, $http) {
    return {
        restrict: 'ACE',
        controller: function($scope) {
            $scope.enableChanges = false;
            $scope.docparserTemplate = false;

            $scope.$watch('docparserTemplate', function(newvalue, oldvalue) {
                if (newvalue !== oldvalue) {
                    /* This is needed becauses of a quirk with ui-select2.
                       Basically, ui-select2 changes its ng-model to the value of the first <option>, which
                       is problematic because it won't show the saved selection on page load. To get around this,
                       there is a check to see if the ng-model has been changed from the initial value of "false" (which has been set above).
                       When this value changes from "false" to something else for the first time, we then assign the value of the saved docparser template to the ng-model.
                    */
                    if ($scope.enableChanges == false) {
                        if ($scope.supplierDetails) {
                            if ($scope.supplierDetails.adminlookupdocparserid) {
                                $scope.docparserTemplate = $scope.supplierDetails.adminlookupdocparserid;
                                $scope.enableChanges = true;
                            }
                        }
                    }
                }
            }, true);

            $scope.changeDocparser = function() {
                if ($scope.docparserTemplate == "" || $scope.docparserTemplate == undefined || $scope.docparserTemplate == null || $scope.docparserTemplate == false) {
                    $scope.docparserTemplate = 0;
                }
            };
        }
    };
}]);