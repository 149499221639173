csmodule.factory('paginationService', function() {
    var paginationService = {
        limit: 10,
        totalCount: 0,
        setLimit: function(limit) {
            this.limit = limit;
            return this.limit;
        },
        setTotalCount: function(totalCount) {
            this.totalCount = totalCount;
            return this.totalCount;
        },
        setCurrentPage: function(currentPage) {
            this.currentPage = currentPage;
            return this.currentPage;
        }
    };

    return paginationService;
});
