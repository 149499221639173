commusoftCommon.filter('dateLongFormat', ["$rootScope", 'CS_LANGUAGE', function ($rootScope, CS_LANGUAGE) {
    return function (dateString,customFormat) {
        if (dateString == '' || dateString == null) {
            return '';
        }
        var longFormat = {
            0: 'ddd Do MMM YYYY',
            1: 'dddd Do MMM YYYY',
            2: 'ddd Do MMMM YYYY',
            3: 'dddd Do MMMM YYYY',
            50: 'ddd MMM Do YYYY',
            51: 'dddd MMM Do YYYY',
            52: 'ddd MMMM Do YYYY',
            53: 'dddd MMMM Do YYYY'
        };

        var timeFormats = {
            0 : 'h:mm a',
            1 : 'HH:mm'
        };
        let language = CS_LANGUAGE;
        if(language == 'en'){
            language = 'en-GB';
        }
        moment.locale(language);
        if(customFormat!='undefined' && (customFormat==1 || customFormat==2)){
            newDate = new Date();
            cDateString = moment(dateString).format('YYYY-MM-DD');
            todayDate = moment(newDate).format('YYYY-MM-DD');

            yesterday = new Date(newDate);
            yesterday.setDate(yesterday.getDate() - 1);
            yesterdayDate = moment(yesterday).format('YYYY-MM-DD');

            tomorrow = new Date(newDate);
            tomorrow.setDate(tomorrow.getDate() + 1);
            tomorrowDate = moment(tomorrow).format('YYYY-MM-DD');

            if (cDateString == todayDate) {
                if(customFormat==2){
                    return "Today"
                }else{
                    return "Today"+" "+moment.utc(dateString).zone(moment().format('Z')).format(timeFormats[parseInt($rootScope.settings_value['time'])]);
                }
            }else if (cDateString == yesterdayDate && customFormat==1) {
                return "Yesterday"+" "+moment.utc(dateString).zone(moment().format('Z')).format(timeFormats[parseInt($rootScope.settings_value['time'])]);
            }else if (cDateString == tomorrowDate && customFormat==1) {
                return "Tomorrow"+" "+moment.utc(dateString).zone(moment().format('Z')).format(timeFormats[parseInt($rootScope.settings_value['time'])]);
            }else{
                if ( typeof longFormat[parseInt($rootScope.settings_value['long_date'])] !== 'undefined' ) {
                    return moment(dateString).format(longFormat[parseInt($rootScope.settings_value['long_date'])]);
                } else {
                    return moment(dateString).format('ddd Do MMM YYYY')
                }
            }
        }else{
            if ( typeof longFormat[parseInt($rootScope.settings_value['long_date'])] !== 'undefined' ) {
                return moment(dateString).format(longFormat[parseInt($rootScope.settings_value['long_date'])]);
            } else {
                return moment(dateString).format('ddd Do MMM YYYY')
            }
        }


    };
}]);