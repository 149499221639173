csmodule.directive('cslistwidget', function($modal, $document, $timeout, confirmationBoxHelper, toastBox, $http, prefix, $rootScope) {
    var handleListWidget = function handleListWidget(scope, element, attrs) {

        scope.sortable_options = {
          update: function(e, ui) {},
          axis: 'x',
          containment: "parent"
        };

        scope.fieldData = scope.$eval(attrs.fieldData);

        //var fieldId = scope.fieldData.id;
        scope.listUrl = attrs.listurl;
        scope.saveUrl = attrs.saveurl;
        scope.deleteUrl = attrs.deleteurl;
        scope.orderUrl = attrs.orderurl;
        scope.hasPermission = attrs.haspermission;

        scope.input_placeholder = attrs.inputPlaceholder ? attrs.inputPlaceholder : 'Add an item';
        scope.add_button_text = attrs.addButtonText ? attrs.addButtonText : 'Add';
        scope.no_results_message = attrs.noResultsMessage ? attrs.noResultsMessage + ' ' + scope.fieldData.description.toLowerCase() : 'There are no items';
        scope.no_results_help_text = attrs.noResultsHelpText ? attrs.noResultsHelpText : 'You can add one here';
        scope.supports_edit = attrs.supportsEdit ? JSON.parse(attrs.supportsEdit) : false;
        scope.supports_drag = attrs.supportsDrag ? JSON.parse(attrs.supportsDrag) : false;
        scope.supports_no_results_ui = attrs.supportsNoResultsUi ? JSON.parse(attrs.supportsNoResultsUi) : false;
        scope.supports_no_results_help_text = attrs.supportsNoResultsHelpText ? JSON.parse(attrs.supportsNoResultsHelpText) : false;
        scope.delete_confirmation_message = attrs.deleteConfirmationMessage ? attrs.deleteConfirmationMessage : 'Item deleted';

        scope.loading = true;
        scope.fieldDependencies = [];
        scope.inputs={};
        scope.data = [];
        scope.dataTotalCount = 0;
        scope.reachedEndOfOptions = false;
        scope.page=1;

        scope.assetDynamicInfiniteScroll = function() {
          if(scope.loading) {
            return true;
          }
          if(scope.reachedEndOfOptions === true) {
            return;
          }
          scope.page++;
          scope.getFieldOptions();
        };

        scope.getFieldOptions = function(isReset) {
          scope.loading = true;
            if(isReset === true) {
                scope.page = 1;
                scope.data = [];
                scope.reachedEndOfOptions = false;
            }
          $http.get(prefix + '/'+ scope.listUrl +'?fieldId=' + scope.fieldData.id + '&page='+scope.page).success(function (value,status){
              if(status == 200){
                if(_.isEmpty(value.options)) {
                  scope.reachedEndOfOptions = true;
                }
                /*if(isReset === true) {
                  scope.page = 1;
                  scope.data = [];
                  scope.reachedEndOfOptions = false;
                }*/
                scope.dataTotalCount = parseInt(value.optionCount);
                _.forEach(value.options, function(option){
                  scope.data.push(option);
                });
                scope.fieldDependencies = value.fieldDependencies;
                scope.loading = false;
                if(scope.dataTotalCount === scope.data.length) {
                  scope.reachedEndOfOptions = true;
                }
              }
              scope.loading = false;
          });
        };

        scope.$on('shouldBeLoad', function(event, fielddata) {
            scope.fieldData = fielddata;
            scope.reachedEndOfOptions = false;
            scope.page = 1;
            scope.data = [];
            scope.dataTotalCount = 0;
            if(fielddata == '') {
                scope.no_results_message = 'There are no items';
                scope.loading = false;
            } else {
                scope.loading = true;
                scope.no_results_message = attrs.noResultsMessage ? attrs.noResultsMessage + ' ' + scope.fieldData.description.toLowerCase() : 'There are no items';
                scope.getFieldOptions();
            }

        });

        //# Load the options at initial level
        scope.getFieldOptions();
        scope.button_clicked=false;
        scope.openSidepanel = function(id) {
            scope.button_clicked=true;
          var modalInstance = $modal.open({
            windowClass: 'df-action df-action-add_dependant-modal df-action-modal sidepanel',
            templateUrl: '/template/dynamic-form/modal/add_dynamic_options_modal.html',
            controller: 'AddDynamicFieldOptionCtrl',
            keyboard: false,
            resolve: {
              getFieldOptions: function(){
                return {
                  //fieldData: scope.fieldData,
                  //options: scope.data,
                  //fieldDependencies: scope.fieldDependencies,
                  id: id,
                  isEditMode: _.isUndefined(id) ? false : true,
                  parentScope: scope
                };
              }
            }
          });
          modalInstance.result.then(
            function(){
                scope.button_clicked=false;
            },
            function(){
                scope.button_clicked=false;
              //console.log('sidepanel is closed...')
            });
        };

        scope.onClose = function() {

        };


        scope.handleAddingItem = function handleAddingItem(new_item_title) {
            if(new_item_title == ''){
                return false;
            }
            scope.new_item = '';
            var item_already_exists = scope.checkIfItemAlreadyExists(new_item_title),
                confirmation_message = 'This item already exists, are you sure you wish to add it again?';

            if (!item_already_exists) {
                scope.addItem(new_item_title,scope.fieldData.id);
            } else {
                confirmationBoxHelper.getConfirmation(confirmation_message, this)
                    .then(function() {
                        scope.addItem(new_item_title,scope.fieldData.id);
                    }, function() {
                        return false
                    });
            }
        };

        scope.addItem = function addItem(newItem, fieldId, parentItems, id) {
          var url = prefix + '/'+ scope.saveUrl,
              params = 'fieldId=' +fieldId +
              '&dependencies=' + encodeURIComponent(angular.toJson(parentItems));
          if(_.isObject(newItem)  ) {
            params +=  newItem.id === 0 ? '&newOption=' + newItem.text : '&newOptionId='+ newItem.id +'&newOption=';
          }
          else {
            params += '&newOption=' + newItem;
          }

          if(typeof id != 'undefined' && id != '') {
              params+= '&id=' + id;
          }

            $http.post(url, params).success(function (value,status){
                scope.loading = true;
                if(status == 200){
                    scope.page=1;
                    scope.data = [];
                    scope.getFieldOptions(true);
                    toastBox.show('Records saved', 2000);
                    //scope.loading = false;
                }
            });
            scope.new_item_title = null;
        };

        scope.handleDeleteItem = function handleDeleteItem(id, description) {

            scope.selectedId = id;
            scope.optionDescription = description;
            scope.shouldBeOpen = false;
            $http.get(prefix + '/is_asset_type_delete?id=' + id).success(function(data) {
                if (data.warning === true) {
                    if(data.message == 'Archive') {
                        scope.archiveShouldBeOpen = true;
                    } else {
                        toastBox.show(data.message, 2000);
                    }
                } else {
                    scope.shouldBeOpen = true;
                }
            });

            /*var deleted_item = angular.copy(scope.data[index_to_remove]),
                delete_message = "Deleted " + deleted_item.description.toLowerCase();*/

            /*$http.post(prefix+ '/'+ scope.deleteUrl +'?id=' + id).success(function(value,status){
                if(status == 200){
                    $http.get(prefix + '/'+scope.listUrl+'?fieldId=' + fieldId).success(function (value,status){
                        if(status == 200){
                            scope.data = value.options;
                            scope.loading = false;
                        }
                    })
                    toastBox.show(delete_message, 2000, 0);
                }
            })*/
        }

        scope.handleEditItem = function handleEditItem(index_to_edit) {
            scope.editing = true;
            scope.data[index_to_edit].editing = true;
            scope.focusCurrentlyEditingInput();

        };

        scope.handleSaveEdit = function handleSaveEdit(index_to_save, Id) {
            var edited_item = angular.copy(scope.data[index_to_save]),
                edit_message = "Saved " + edited_item.description.toLowerCase();
            if(edited_item.description == ''){
                return false;
            }

            var item_already_exists = scope.checkIfItemAlreadyExists(edited_item.description, index_to_save);

            if (!item_already_exists) {
                scope.editing = false;
                scope.data[index_to_save].editing = false;
                $http.post(prefix + '/'+ scope.saveUrl +'?fieldId=' + scope.fieldData.id + '&newOption=' + encodeURIComponent(edited_item.description) +'&id=' + Id).success(function (value,status){
                    if(status == 200){
                        toastBox.show(edit_message, 2000, 0);
                        scope.flashItemUI(index_to_save);
                    }
                })
            } else {
                var confirmation_message = 'This item already exists, are you sure you wish to add it again?';

                confirmationBoxHelper.getConfirmation(confirmation_message, this)
                    .then(function() {
                        scope.editing = false;
                        scope.data[index_to_save].editing = false;
                        $http.post(prefix + '/'+ scope.saveUrl +'?fieldId=' + scope.fieldData.id + '&newOption=' + encodeURIComponent(edited_item.description) +'&id=' + Id).success(function (value,status){
                            if(status == 200){
                                toastBox.show(edit_message, 2000, 0);
                                scope.flashItemUI(index_to_save);
                            }
                        })
                    }, function() {
                        scope.focusCurrentlyEditingInput();
                        return false
                    });
            }
        }

        scope.checkIfItemAlreadyExists = function checkIfItemAlreadyExists(new_item_title, index_to_ignore) {
            // Index to ignore is used in edit cases, where we do not care if the edited item matches
            var items = scope.data.map(function(item) {
                return item.description.toLowerCase();
            });

            if (index_to_ignore !== undefined) {
                items.splice(index_to_ignore, 1);
            }

            var item_already_exists = items.indexOf(new_item_title.toLowerCase()) > -1;

            return item_already_exists;
        }

        scope.getIndexOfItem = function getIndexOfItem(item) {
            var index_of_item = scope.data.map(function(item) {
                return item.title;
            }).indexOf(item.title);

            return index_of_item;
        }

        scope.flashItemUI = function flashItemUI(index_to_flash) {
            scope.data[index_to_flash].added = true;
            scope.data[index_to_flash].with_highlight_transition = true;

            $timeout(function() {
                for(var i = 0, l = scope.data.length; i < l; i++) {
                    scope.data[i].added = false;
                }
            }, 50);
            $timeout(function() {
                for(var i = 0, l = scope.data.length; i < l; i++) {
                    scope.data[i].with_highlight_transition = false;
                }
            }, 1500);
        }

        scope.focusCurrentlyEditingInput = function focusCurrentlyEditingInput() {
            window.requestAnimationFrame(function() {
                var input = document.querySelector('.list-widget li input');

                input.focus();
                input.select();
            });
        }
    }

    return {
        restrict: 'A',
        link: handleListWidget,
        templateUrl: function (elem,attr) {
            return attr.filepath;
        }
    }
});