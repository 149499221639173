/*!
 * jQuery Migrate - v1.4.1 - 2016-05-19
 * Copyright jQuery Foundation and other contributors
 */
(function( jQuery, window, undefined ) {
// See http://bugs.jquery.com/ticket/13335
// "use strict";


  jQuery.migrateVersion = "1.4.1";


  var warnedAbout = {};

// List of warnings already given; public read only
  jQuery.migrateWarnings = [];

// Set to true to prevent console output; migrateWarnings still maintained
 jQuery.migrateMute = true;

// Show a message on the console so devs know we're active
  if ( window.console && window.console.log ) {
    window.console.log( "JQMIGRATE: Migrate is installed" +
      ( jQuery.migrateMute ? "" : " with logging active" ) +
      ", version " + jQuery.migrateVersion );
  }

// Set to false to disable traces that appear with warnings
  if ( jQuery.migrateTrace === undefined ) {
    jQuery.migrateTrace = true;
  }

// Forget any warnings we've already given; public
  jQuery.migrateReset = function() {
    warnedAbout = {};
    jQuery.migrateWarnings.length = 0;
  };

  function migrateWarn( msg) {
    var console = window.console;
    if ( !warnedAbout[ msg ] ) {
      warnedAbout[ msg ] = true;
      jQuery.migrateWarnings.push( msg );
      if ( console && console.warn && !jQuery.migrateMute ) {
        console.warn( "JQMIGRATE: " + msg );
        if ( jQuery.migrateTrace && console.trace ) {
          console.trace();
        }
      }
    }
  }

  function migrateWarnProp( obj, prop, value, msg ) {
    if ( Object.defineProperty ) {
      // On ES5 browsers (non-oldIE), warn if the code tries to get prop;
      // allow property to be overwritten in case some other plugin wants it
      try {
        Object.defineProperty( obj, prop, {
          configurable: true,
          enumerable: true,
          get: function() {
            migrateWarn( msg );
            return value;
          },
          set: function( newValue ) {
            migrateWarn( msg );
            value = newValue;
          }
        });
        return;
      } catch( err ) {
        // IE8 is a dope about Object.defineProperty, can't warn there
      }
    }

    // Non-ES5 (or broken) browser; just set the property
    jQuery._definePropertyBroken = true;
    obj[ prop ] = value;
  }

  if ( document.compatMode === "BackCompat" ) {
    // jQuery has never supported or tested Quirks Mode
    migrateWarn( "jQuery is not compatible with Quirks Mode" );
  }


  var attrFn = jQuery( "<input/>", { size: 1 } ).attr("size") && jQuery.attrFn,
    oldAttr = jQuery.attr,
    valueAttrGet = jQuery.attrHooks.value && jQuery.attrHooks.value.get ||
      function() { return null; },
    valueAttrSet = jQuery.attrHooks.value && jQuery.attrHooks.value.set ||
      function() { return undefined; },
    rnoType = /^(?:input|button)$/i,
    rnoAttrNodeType = /^[238]$/,
    rboolean = /^(?:autofocus|autoplay|async|checked|controls|defer|disabled|hidden|loop|multiple|open|readonly|required|scoped|selected)$/i,
    ruseDefault = /^(?:checked|selected)$/i;

// jQuery.attrFn
  migrateWarnProp( jQuery, "attrFn", attrFn || {}, "jQuery.attrFn is deprecated" );

  jQuery.attr = function( elem, name, value, pass ) {
    var lowerName = name.toLowerCase(),
      nType = elem && elem.nodeType;

    if ( pass ) {
      // Since pass is used internally, we only warn for new jQuery
      // versions where there isn't a pass arg in the formal params
      if ( oldAttr.length < 4 ) {
        migrateWarn("jQuery.fn.attr( props, pass ) is deprecated");
      }
      if ( elem && !rnoAttrNodeType.test( nType ) &&
        (attrFn ? name in attrFn : jQuery.isFunction(jQuery.fn[name])) ) {
        return jQuery( elem )[ name ]( value );
      }
    }

    // Warn if user tries to set `type`, since it breaks on IE 6/7/8; by checking
    // for disconnected elements we don't warn on $( "<button>", { type: "button" } ).
    if ( name === "type" && value !== undefined && rnoType.test( elem.nodeName ) && elem.parentNode ) {
      migrateWarn("Can't change the 'type' of an input or button in IE 6/7/8");
    }

    // Restore boolHook for boolean property/attribute synchronization
    if ( !jQuery.attrHooks[ lowerName ] && rboolean.test( lowerName ) ) {
      jQuery.attrHooks[ lowerName ] = {
        get: function( elem, name ) {
          // Align boolean attributes with corresponding properties
          // Fall back to attribute presence where some booleans are not supported
          var attrNode,
            property = jQuery.prop( elem, name );
          return property === true || typeof property !== "boolean" &&
          ( attrNode = elem.getAttributeNode(name) ) && attrNode.nodeValue !== false ?

            name.toLowerCase() :
            undefined;
        },
        set: function( elem, value, name ) {
          var propName;
          if ( value === false ) {
            // Remove boolean attributes when set to false
            jQuery.removeAttr( elem, name );
          } else {
            // value is true since we know at this point it's type boolean and not false
            // Set boolean attributes to the same name and set the DOM property
            propName = jQuery.propFix[ name ] || name;
            if ( propName in elem ) {
              // Only set the IDL specifically if it already exists on the element
              elem[ propName ] = true;
            }

            elem.setAttribute( name, name.toLowerCase() );
          }
          return name;
        }
      };

      // Warn only for attributes that can remain distinct from their properties post-1.9
      if ( ruseDefault.test( lowerName ) ) {
        migrateWarn( "jQuery.fn.attr('" + lowerName + "') might use property instead of attribute" );
      }
    }

    return oldAttr.call( jQuery, elem, name, value );
  };

// attrHooks: value
  jQuery.attrHooks.value = {
    get: function( elem, name ) {
      var nodeName = ( elem.nodeName || "" ).toLowerCase();
      if ( nodeName === "button" ) {
        return valueAttrGet.apply( this, arguments );
      }
      if ( nodeName !== "input" && nodeName !== "option" ) {
        migrateWarn("jQuery.fn.attr('value') no longer gets properties");
      }
      return name in elem ?
        elem.value :
        null;
    },
    set: function( elem, value ) {
      var nodeName = ( elem.nodeName || "" ).toLowerCase();
      if ( nodeName === "button" ) {
        return valueAttrSet.apply( this, arguments );
      }
      if ( nodeName !== "input" && nodeName !== "option" ) {
        migrateWarn("jQuery.fn.attr('value', val) no longer sets properties");
      }
      // Does not return so that setAttribute is also used
      elem.value = value;
    }
  };


  var matched, browser,
    oldInit = jQuery.fn.init,
    oldFind = jQuery.find,
    oldParseJSON = jQuery.parseJSON,
    rspaceAngle = /^\s*</,
    rattrHashTest = /\[(\s*[-\w]+\s*)([~|^$*]?=)\s*([-\w#]*?#[-\w#]*)\s*\]/,
    rattrHashGlob = /\[(\s*[-\w]+\s*)([~|^$*]?=)\s*([-\w#]*?#[-\w#]*)\s*\]/g,
    // Note: XSS check is done below after string is trimmed
    rquickExpr = /^([^<]*)(<[\w\W]+>)([^>]*)$/;

// $(html) "looks like html" rule change
  jQuery.fn.init = function( selector, context, rootjQuery ) {
    var match, ret;

    if ( selector && typeof selector === "string" ) {
      if ( !jQuery.isPlainObject( context ) &&
        (match = rquickExpr.exec( jQuery.trim( selector ) )) && match[ 0 ] ) {

        // This is an HTML string according to the "old" rules; is it still?
        if ( !rspaceAngle.test( selector ) ) {
          migrateWarn("$(html) HTML strings must start with '<' character");
        }
        if ( match[ 3 ] ) {
          migrateWarn("$(html) HTML text after last tag is ignored");
        }

        // Consistently reject any HTML-like string starting with a hash (gh-9521)
        // Note that this may break jQuery 1.6.x code that otherwise would work.
        if ( match[ 0 ].charAt( 0 ) === "#" ) {
          migrateWarn("HTML string cannot start with a '#' character");
          jQuery.error("JQMIGRATE: Invalid selector string (XSS)");
        }

        // Now process using loose rules; let pre-1.8 play too
        // Is this a jQuery context? parseHTML expects a DOM element (#178)
        if ( context && context.context && context.context.nodeType ) {
          context = context.context;
        }

        if ( jQuery.parseHTML ) {
          return oldInit.call( this,
            jQuery.parseHTML( match[ 2 ], context && context.ownerDocument ||
              context || document, true ), context, rootjQuery );
        }
      }
    }

    ret = oldInit.apply( this, arguments );

    // Fill in selector and context properties so .live() works
    if ( selector && selector.selector !== undefined ) {
      // A jQuery object, copy its properties
      ret.selector = selector.selector;
      ret.context = selector.context;

    } else {
      ret.selector = typeof selector === "string" ? selector : "";
      if ( selector ) {
        ret.context = selector.nodeType? selector : context || document;
      }
    }

    return ret;
  };
  jQuery.fn.init.prototype = jQuery.fn;

  jQuery.find = function( selector ) {
    var args = Array.prototype.slice.call( arguments );

    // Support: PhantomJS 1.x
    // String#match fails to match when used with a //g RegExp, only on some strings
    if ( typeof selector === "string" && rattrHashTest.test( selector ) ) {

      // The nonstandard and undocumented unquoted-hash was removed in jQuery 1.12.0
      // First see if qS thinks it's a valid selector, if so avoid a false positive
      try {
        document.querySelector( selector );
      } catch ( err1 ) {

        // Didn't *look* valid to qSA, warn and try quoting what we think is the value
        selector = selector.replace( rattrHashGlob, function( _, attr, op, value ) {
          return "[" + attr + op + "\"" + value + "\"]";
        } );

        // If the regexp *may* have created an invalid selector, don't update it
        // Note that there may be false alarms if selector uses jQuery extensions
        try {
          document.querySelector( selector );
          migrateWarn( "Attribute selector with '#' must be quoted: " + args[ 0 ] );
          args[ 0 ] = selector;
        } catch ( err2 ) {
          migrateWarn( "Attribute selector with '#' was not fixed: " + args[ 0 ] );
        }
      }
    }

    return oldFind.apply( this, args );
  };

// Copy properties attached to original jQuery.find method (e.g. .attr, .isXML)
  var findProp;
  for ( findProp in oldFind ) {
    if ( Object.prototype.hasOwnProperty.call( oldFind, findProp ) ) {
      jQuery.find[ findProp ] = oldFind[ findProp ];
    }
  }

// Let $.parseJSON(falsy_value) return null
  jQuery.parseJSON = function( json ) {
    if ( !json ) {
      migrateWarn("jQuery.parseJSON requires a valid JSON string");
      return null;
    }
    return oldParseJSON.apply( this, arguments );
  };

  jQuery.uaMatch = function( ua ) {
    ua = ua.toLowerCase();

    var match = /(chrome)[ \/]([\w.]+)/.exec( ua ) ||
      /(webkit)[ \/]([\w.]+)/.exec( ua ) ||
      /(opera)(?:.*version|)[ \/]([\w.]+)/.exec( ua ) ||
      /(msie) ([\w.]+)/.exec( ua ) ||
      ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec( ua ) ||
      [];

    return {
      browser: match[ 1 ] || "",
      version: match[ 2 ] || "0"
    };
  };

// Don't clobber any existing jQuery.browser in case it's different
  if ( !jQuery.browser ) {
    matched = jQuery.uaMatch( navigator.userAgent );
    browser = {};

    if ( matched.browser ) {
      browser[ matched.browser ] = true;
      browser.version = matched.version;
    }

    // Chrome is Webkit, but Webkit is also Safari.
    if ( browser.chrome ) {
      browser.webkit = true;
    } else if ( browser.webkit ) {
      browser.safari = true;
    }

    jQuery.browser = browser;
  }

// Warn if the code tries to get jQuery.browser
  migrateWarnProp( jQuery, "browser", jQuery.browser, "jQuery.browser is deprecated" );

// jQuery.boxModel deprecated in 1.3, jQuery.support.boxModel deprecated in 1.7
  jQuery.boxModel = jQuery.support.boxModel = (document.compatMode === "CSS1Compat");
  migrateWarnProp( jQuery, "boxModel", jQuery.boxModel, "jQuery.boxModel is deprecated" );
  migrateWarnProp( jQuery.support, "boxModel", jQuery.support.boxModel, "jQuery.support.boxModel is deprecated" );

  jQuery.sub = function() {
    function jQuerySub( selector, context ) {
      return new jQuerySub.fn.init( selector, context );
    }
    jQuery.extend( true, jQuerySub, this );
    jQuerySub.superclass = this;
    jQuerySub.fn = jQuerySub.prototype = this();
    jQuerySub.fn.constructor = jQuerySub;
    jQuerySub.sub = this.sub;
    jQuerySub.fn.init = function init( selector, context ) {
      var instance = jQuery.fn.init.call( this, selector, context, rootjQuerySub );
      return instance instanceof jQuerySub ?
        instance :
        jQuerySub( instance );
    };
    jQuerySub.fn.init.prototype = jQuerySub.fn;
    var rootjQuerySub = jQuerySub(document);
    migrateWarn( "jQuery.sub() is deprecated" );
    return jQuerySub;
  };

// The number of elements contained in the matched element set
  jQuery.fn.size = function() {
    migrateWarn( "jQuery.fn.size() is deprecated; use the .length property" );
    return this.length;
  };


  var internalSwapCall = false;

// If this version of jQuery has .swap(), don't false-alarm on internal uses
  if ( jQuery.swap ) {
    jQuery.each( [ "height", "width", "reliableMarginRight" ], function( _, name ) {
      var oldHook = jQuery.cssHooks[ name ] && jQuery.cssHooks[ name ].get;

      if ( oldHook ) {
        jQuery.cssHooks[ name ].get = function() {
          var ret;

          internalSwapCall = true;
          ret = oldHook.apply( this, arguments );
          internalSwapCall = false;
          return ret;
        };
      }
    });
  }

  jQuery.swap = function( elem, options, callback, args ) {
    var ret, name,
      old = {};

    if ( !internalSwapCall ) {
      migrateWarn( "jQuery.swap() is undocumented and deprecated" );
    }

    // Remember the old values, and insert the new ones
    for ( name in options ) {
      old[ name ] = elem.style[ name ];
      elem.style[ name ] = options[ name ];
    }

    ret = callback.apply( elem, args || [] );

    // Revert the old values
    for ( name in options ) {
      elem.style[ name ] = old[ name ];
    }

    return ret;
  };


// Ensure that $.ajax gets the new parseJSON defined in core.js
  jQuery.ajaxSetup({
    converters: {
      "text json": jQuery.parseJSON
    }
  });


  var oldFnData = jQuery.fn.data;

  jQuery.fn.data = function( name ) {
    var ret, evt,
      elem = this[0];

    // Handles 1.7 which has this behavior and 1.8 which doesn't
    if ( elem && name === "events" && arguments.length === 1 ) {
      ret = jQuery.data( elem, name );
      evt = jQuery._data( elem, name );
      if ( ( ret === undefined || ret === evt ) && evt !== undefined ) {
        migrateWarn("Use of jQuery.fn.data('events') is deprecated");
        return evt;
      }
    }
    return oldFnData.apply( this, arguments );
  };


  var rscriptType = /\/(java|ecma)script/i;

// Since jQuery.clean is used internally on older versions, we only shim if it's missing
  if ( !jQuery.clean ) {
    jQuery.clean = function( elems, context, fragment, scripts ) {
      // Set context per 1.8 logic
      context = context || document;
      context = !context.nodeType && context[0] || context;
      context = context.ownerDocument || context;

      migrateWarn("jQuery.clean() is deprecated");

      var i, elem, handleScript, jsTags,
        ret = [];

      jQuery.merge( ret, jQuery.buildFragment( elems, context ).childNodes );

      // Complex logic lifted directly from jQuery 1.8
      if ( fragment ) {
        // Special handling of each script element
        handleScript = function( elem ) {
          // Check if we consider it executable
          if ( !elem.type || rscriptType.test( elem.type ) ) {
            // Detach the script and store it in the scripts array (if provided) or the fragment
            // Return truthy to indicate that it has been handled
            return scripts ?
              scripts.push( elem.parentNode ? elem.parentNode.removeChild( elem ) : elem ) :
              fragment.appendChild( elem );
          }
        };

        for ( i = 0; (elem = ret[i]) != null; i++ ) {
          // Check if we're done after handling an executable script
          if ( !( jQuery.nodeName( elem, "script" ) && handleScript( elem ) ) ) {
            // Append to fragment and handle embedded scripts
            fragment.appendChild( elem );
            if ( typeof elem.getElementsByTagName !== "undefined" ) {
              // handleScript alters the DOM, so use jQuery.merge to ensure snapshot iteration
              jsTags = jQuery.grep( jQuery.merge( [], elem.getElementsByTagName("script") ), handleScript );

              // Splice the scripts into ret after their former ancestor and advance our index beyond them
              ret.splice.apply( ret, [i + 1, 0].concat( jsTags ) );
              i += jsTags.length;
            }
          }
        }
      }

      return ret;
    };
  }

  var eventAdd = jQuery.event.add,
    eventRemove = jQuery.event.remove,
    eventTrigger = jQuery.event.trigger,
    oldToggle = jQuery.fn.toggle,
    oldLive = jQuery.fn.live,
    oldDie = jQuery.fn.die,
    oldLoad = jQuery.fn.load,
    ajaxEvents = "ajaxStart|ajaxStop|ajaxSend|ajaxComplete|ajaxError|ajaxSuccess",
    rajaxEvent = new RegExp( "\\b(?:" + ajaxEvents + ")\\b" ),
    rhoverHack = /(?:^|\s)hover(\.\S+|)\b/,
    hoverHack = function( events ) {
      if ( typeof( events ) !== "string" || jQuery.event.special.hover ) {
        return events;
      }
      if ( rhoverHack.test( events ) ) {
        migrateWarn("'hover' pseudo-event is deprecated, use 'mouseenter mouseleave'");
      }
      return events && events.replace( rhoverHack, "mouseenter$1 mouseleave$1" );
    };

// Event props removed in 1.9, put them back if needed; no practical way to warn them
  if ( jQuery.event.props && jQuery.event.props[ 0 ] !== "attrChange" ) {
    jQuery.event.props.unshift( "attrChange", "attrName", "relatedNode", "srcElement" );
  }

// Undocumented jQuery.event.handle was "deprecated" in jQuery 1.7
  if ( jQuery.event.dispatch ) {
    migrateWarnProp( jQuery.event, "handle", jQuery.event.dispatch, "jQuery.event.handle is undocumented and deprecated" );
  }

// Support for 'hover' pseudo-event and ajax event warnings
  jQuery.event.add = function( elem, types, handler, data, selector ){
    if ( elem !== document && rajaxEvent.test( types ) ) {
      migrateWarn( "AJAX events should be attached to document: " + types );
    }
    eventAdd.call( this, elem, hoverHack( types || "" ), handler, data, selector );
  };
  jQuery.event.remove = function( elem, types, handler, selector, mappedTypes ){
    eventRemove.call( this, elem, hoverHack( types ) || "", handler, selector, mappedTypes );
  };

  jQuery.each( [ "load", "unload", "error" ], function( _, name ) {

    jQuery.fn[ name ] = function() {
      var args = Array.prototype.slice.call( arguments, 0 );

      // If this is an ajax load() the first arg should be the string URL;
      // technically this could also be the "Anything" arg of the event .load()
      // which just goes to show why this dumb signature has been deprecated!
      // jQuery custom builds that exclude the Ajax module justifiably die here.
      if ( name === "load" && typeof args[ 0 ] === "string" ) {
        return oldLoad.apply( this, args );
      }

      migrateWarn( "jQuery.fn." + name + "() is deprecated" );

      args.splice( 0, 0, name );
      if ( arguments.length ) {
        return this.bind.apply( this, args );
      }

      // Use .triggerHandler here because:
      // - load and unload events don't need to bubble, only applied to window or image
      // - error event should not bubble to window, although it does pre-1.7
      // See http://bugs.jquery.com/ticket/11820
      this.triggerHandler.apply( this, args );
      return this;
    };

  });

  jQuery.fn.toggle = function( fn, fn2 ) {

    // Don't mess with animation or css toggles
    if ( !jQuery.isFunction( fn ) || !jQuery.isFunction( fn2 ) ) {
      return oldToggle.apply( this, arguments );
    }
    migrateWarn("jQuery.fn.toggle(handler, handler...) is deprecated");

    // Save reference to arguments for access in closure
    var args = arguments,
      guid = fn.guid || jQuery.guid++,
      i = 0,
      toggler = function( event ) {
        // Figure out which function to execute
        var lastToggle = ( jQuery._data( this, "lastToggle" + fn.guid ) || 0 ) % i;
        jQuery._data( this, "lastToggle" + fn.guid, lastToggle + 1 );

        // Make sure that clicks stop
        event.preventDefault();

        // and execute the function
        return args[ lastToggle ].apply( this, arguments ) || false;
      };

    // link all the functions, so any of them can unbind this click handler
    toggler.guid = guid;
    while ( i < args.length ) {
      args[ i++ ].guid = guid;
    }

    return this.click( toggler );
  };

  jQuery.fn.live = function( types, data, fn ) {
    migrateWarn("jQuery.fn.live() is deprecated");
    if ( oldLive ) {
      return oldLive.apply( this, arguments );
    }
    jQuery( this.context ).on( types, this.selector, data, fn );
    return this;
  };

  jQuery.fn.die = function( types, fn ) {
    migrateWarn("jQuery.fn.die() is deprecated");
    if ( oldDie ) {
      return oldDie.apply( this, arguments );
    }
    jQuery( this.context ).off( types, this.selector || "**", fn );
    return this;
  };

// Turn global events into document-triggered events
  jQuery.event.trigger = function( event, data, elem, onlyHandlers  ){
    if ( !elem && !rajaxEvent.test( event ) ) {
      migrateWarn( "Global events are undocumented and deprecated" );
    }
    return eventTrigger.call( this,  event, data, elem || document, onlyHandlers  );
  };
  jQuery.each( ajaxEvents.split("|"),
    function( _, name ) {
      jQuery.event.special[ name ] = {
        setup: function() {
          var elem = this;

          // The document needs no shimming; must be !== for oldIE
          if ( elem !== document ) {
            jQuery.event.add( document, name + "." + jQuery.guid, function() {
              jQuery.event.trigger( name, Array.prototype.slice.call( arguments, 1 ), elem, true );
            });
            jQuery._data( this, name, jQuery.guid++ );
          }
          return false;
        },
        teardown: function() {
          if ( this !== document ) {
            jQuery.event.remove( document, name + "." + jQuery._data( this, name ) );
          }
          return false;
        }
      };
    }
  );

  jQuery.event.special.ready = {
    setup: function() {
      if ( this === document ) {
        migrateWarn( "'ready' event is deprecated" );
      }
    }
  };

  var oldSelf = jQuery.fn.andSelf || jQuery.fn.addBack,
    oldFnFind = jQuery.fn.find;

  jQuery.fn.andSelf = function() {
    migrateWarn("jQuery.fn.andSelf() replaced by jQuery.fn.addBack()");
    return oldSelf.apply( this, arguments );
  };

  jQuery.fn.find = function( selector ) {
    var ret = oldFnFind.apply( this, arguments );
    ret.context = this.context;
    ret.selector = this.selector ? this.selector + " " + selector : selector;
    return ret;
  };


// jQuery 1.6 did not support Callbacks, do not warn there
  if ( jQuery.Callbacks ) {

    var oldDeferred = jQuery.Deferred,
      tuples = [
        // action, add listener, callbacks, .then handlers, final state
        [ "resolve", "done", jQuery.Callbacks("once memory"),
          jQuery.Callbacks("once memory"), "resolved" ],
        [ "reject", "fail", jQuery.Callbacks("once memory"),
          jQuery.Callbacks("once memory"), "rejected" ],
        [ "notify", "progress", jQuery.Callbacks("memory"),
          jQuery.Callbacks("memory") ]
      ];

    jQuery.Deferred = function( func ) {
      var deferred = oldDeferred(),
        promise = deferred.promise();

      deferred.pipe = promise.pipe = function( /* fnDone, fnFail, fnProgress */ ) {
        var fns = arguments;

        migrateWarn( "deferred.pipe() is deprecated" );

        return jQuery.Deferred(function( newDefer ) {
          jQuery.each( tuples, function( i, tuple ) {
            var fn = jQuery.isFunction( fns[ i ] ) && fns[ i ];
            // deferred.done(function() { bind to newDefer or newDefer.resolve })
            // deferred.fail(function() { bind to newDefer or newDefer.reject })
            // deferred.progress(function() { bind to newDefer or newDefer.notify })
            deferred[ tuple[1] ](function() {
              var returned = fn && fn.apply( this, arguments );
              if ( returned && jQuery.isFunction( returned.promise ) ) {
                returned.promise()
                  .done( newDefer.resolve )
                  .fail( newDefer.reject )
                  .progress( newDefer.notify );
              } else {
                newDefer[ tuple[ 0 ] + "With" ](
                  this === promise ? newDefer.promise() : this,
                  fn ? [ returned ] : arguments
                );
              }
            });
          });
          fns = null;
        }).promise();

      };

      deferred.isResolved = function() {
        migrateWarn( "deferred.isResolved is deprecated" );
        return deferred.state() === "resolved";
      };

      deferred.isRejected = function() {
        migrateWarn( "deferred.isRejected is deprecated" );
        return deferred.state() === "rejected";
      };

      if ( func ) {
        func.call( deferred, deferred );
      }

      return deferred;
    };

  }

})( jQuery, window );