commusoftCommon.directive('assetWidgetModal', function($modal, $compile){
  return {
    restrict: 'E',
    scope: {
      templateUrl: '@',
      controllerName: '@',
      autoOpen: '@',
      params: '@',
      preSubmitHook: '@',
      onSubmit: '&',
      onClose: '&',
      viewScreen: '@',
      resolveUrl: '@'
    },
    template: '',
    link: function(scope, element, attrs){
      var modalTpl, modalCtrl, isAutoOpen, params, preSubmitHook, viewScreen, resolveUrl;

      // On Angular7 migration the attribute bindings won't work, So trying the scope binds
      if(attrs === 'NOT_SUPPORTED'){

        scope.$watch('templateUrl', function (val) {
          modalTpl = val;
        });

        scope.$watch('controllerName', function (val) {
          modalCtrl = val;
        });

        scope.$watch('autoOpen', function (val) {
          isAutoOpen = val;
        });

        scope.$watch('params', function (val) {
          params = val;
        });

        scope.$watch('viewScreen', function (val) {
          viewScreen = !val ? 'form' : val;
        });

        scope.$watch('preSubmitHook', function (val) {
          preSubmitHook = scope.preSubmitHook;
        });

        scope.$watch('resolveUrl', function (val) {
          resolveUrl = scope.resolveUrl;
        });

      } else {
        modalTpl = scope.$eval(attrs.templateUrl);
        modalCtrl= scope.$eval(attrs.controllerName);
        isAutoOpen= _.isUndefined(scope.$eval(attrs.autoOpen)) ? false : scope.$eval(attrs.autoOpen);
        params = scope.$eval(attrs.params);
        viewScreen = !scope.$eval(attrs.viewScreen) ? 'form' : scope.$eval(attrs.viewScreen);
        preSubmitHook = _.isUndefined(scope.$eval(attrs.preSubmitHook)) ? null : scope.$eval(attrs.preSubmitHook);
        resolveUrl = _.isUndefined(scope.$eval(attrs.resolveUrl)) ? null : scope.$eval(attrs.resolveUrl);
      }

      scope.openModal = function(datas) {
        var modalInstance = $modal.open({
          windowClass: 'df-action df-action-modal default job-asset-widget-modal',
          templateUrl: modalTpl,
          controller: modalCtrl,
          keyboard: false,
          resolve: {
            dataSet: function(){
              return {
                datas: datas,
                params: params,
                preSubmitHook: preSubmitHook,
                viewScreen: viewScreen,
                resolveUrl: resolveUrl
              };
            }
          }
        });
        modalInstance.result.then(
          function(datas){
            if(scope.onSubmit != null) {
              return scope.onSubmit({items:datas});
            }
          },
          function(datas){
            if(scope.onClose != null) {
              return scope.onClose({items:datas});
            }
          }
        );
      };

      if(isAutoOpen) {
        scope.openModal();
      }

      scope.$on('jobAsset:open-widget', function(e, datas){
        scope.openModal(datas)
      });
    }
  };
})
  .directive('placeholderLoader', function(){
    return {
      restrict: 'A',
      template: '<div class="table-view placehold-loader">' +
      '<table><tbody>' +
      '<tr ng-repeat="(k,i) in placeholder.rows">' +
        //'<td class="col-w-20"><div class="text-layout animated-background"></div></td>'+
        //'<td class="col-w-30"><div class="text-layout animated-background "></div></td>' +
        '<td ng-repeat="(ind, style) in cols" ng-style="style"><div class="text-layout animated-background"></div></td>' +
        //'<td class="col-w-2" ><div class="text-layout animated-background long"></div></td>' +
      '</tr>'+
      '</tbody></table>'+
      '</div>',
      link: function(scope, element, attrs){ console.log('cols', scope.cols);
        if(!attrs.cols) {
          scope.cols = [{width: '30%'},{width: '30%'},{width: '30%'},{width: '10%'}];
        }
        else {
          scope.cols = scope.$eval(attrs.cols);
        }
        var rows = !attrs.noOfRows ? 10 : parseInt(attrs.noOfRows);
        scope.placeholder={};
        scope.placeholder.rows = _.range(rows);
        scope.sizeClass = {
          1 : 'short',
          2 : 'medium',
          3 : 'long'
        };
        scope.randomSize = function(k) {
          return _.random(1,3);
        };
      }
    };
  })
  .filter('randomSelection', function(){
  return function(){
    var sizeClass = {
      1 : 'short',
      2 : 'medium',
      3 : 'long'
    };
    var r = _.random(1,3);
    return sizeClass[r];
  };
});
