commusoftCommon.filter('fullDateTimeWithAMPM', ['CS_COUNTRY', 'CS_LANGUAGE', function (CS_COUNTRY, CS_LANGUAGE) {
    return function(dateString, format) {
        if(typeof dateString == 'undefined' || dateString == ''){
            return '';
        }
        moment.locale(CS_LANGUAGE);

        const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:?\d{2}|Z)$/;
        let dateObject  = (isoRegex.test(dateString)) ? new Date(dateString) : new Date(dateString + ' UTC');
        let zoneDateObject = new Date(dateObject.toLocaleString('en-US', { timeZone: CS_COUNTRY.timezone }));

        return moment(zoneDateObject).format(format);
    };
}]);
