commusoftCommon.filter('customCurrency', ["$filter", "$rootScope", function ($filter, $rootScope) {
    return function (amount, currencySymbol) {
        var currency = $filter('currency');
        var negativeCurrency = '';
        var positiveCurrency = '';
        if(parseInt($rootScope.settings_value['currency']) === 0){
            amount = typeof amount === 'string' ? amount.replace(/,/g,'') : amount;
            positiveCurrency = currency(amount,currencySymbol);
            negativeCurrency = positiveCurrency.replace("(" + currencySymbol, "-" +currencySymbol).replace(")", "");
        }
        else if(parseInt($rootScope.settings_value['currency']) === 1){
            positiveCurrency = currency(parseInt(amount), currencySymbol).split('.')[0];
            negativeCurrency = positiveCurrency.replace("(" + currencySymbol, "-" +currencySymbol).replace(")", "");
        }
        else if(parseInt($rootScope.settings_value['currency']) === 2){
            positiveCurrency = currencySymbol + parseInt(amount);
            negativeCurrency = positiveCurrency.replace(currencySymbol + "-", "-" +currencySymbol);
        }
        else if(parseInt($rootScope.settings_value['currency']) === 3){
            positiveCurrency = currencySymbol + parseFloat(amount).toFixed(2);
            negativeCurrency = positiveCurrency.replace(currencySymbol + "-", "-" +currencySymbol);
        }
        else if(parseInt($rootScope.settings_value['currency']) === 4){
            positiveCurrency = currency(amount,currencySymbol).replace(/,/g, ' ');
            negativeCurrency = positiveCurrency.replace("(" + currencySymbol, "-" +currencySymbol).replace(")", "");
        }
        else if(parseInt($rootScope.settings_value['currency']) === 5){
            let currency_arr = currency(amount,currencySymbol).toString().split(".");
            positiveCurrency = currency_arr[0].replace(/,/g, '.') + ',' + currency_arr[1];
            negativeCurrency = positiveCurrency.replace("(" + currencySymbol, "-" +currencySymbol).replace(")", "");
        }

        if(amount == null || amount === ''){
            return '';
        }
        else if(amount < 0){
            return negativeCurrency;
        }else{
            return positiveCurrency;
        }
    };
}]);