commusoftCommon.directive('cspagination', ['prefix', '$http', function(prefix, $http) {
    return {
        restrict: 'E',
        scope: {
            totalEntries: '=',
            currentPage: '=',
            fetchData: '&',
            limit: '=',
            showNewPagination: '='
        },
        template: '<pagination previous-text="Prev" max-size="5" rotate="false" items-per-page=limit total-items="totalEntries" page="currentPage" show-new-pagination="showNewPagination"></pagination>',
        controller: function($scope, defaultPagingValue) {
            if(!$scope.itemsPerPage) {
                $scope.itemsPerPage = defaultPagingValue;
            }
            $scope.$watch('currentPage', function(newVal, oldVal) {
                if (typeof newVal != 'undefined' && newVal  != '') {
                    $scope.fetchData({ page: newVal});
                }
            });
            $scope.$watch('totalEntries', function(newVal, oldVal) {
                $scope.noOfPages = (newVal % $scope.limit == 0) ? Math.floor(newVal / $scope.limit ): (Math.floor(newVal / $scope.limit) + 1)
            });
            $scope.$watch('limit', function(newVal, oldVal) {
                if(newVal != oldVal) {
                    $scope.itemsPerPage = $scope.limit;
                    $scope.currentPage = 1;
                    $scope.fetchData({ page: 1,limit:$scope.limit});
                    $scope.noOfPages = ($scope.totalEntries % $scope.limit == 0) ? Math.floor($scope.totalEntries / $scope.limit ): (Math.floor($scope.totalEntries / $scope.limit) + 1)
                }
            });
        },
        link: function(scope, element, attrs, ctrl) {
        }
    }
}]);
