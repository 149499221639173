
/*==========================================================================================
	Provides a callback when a scrollable element has reached its bottom
==========================================================================================*/

csmodule.directive('loadOnScroll', [ function() {
	var handleLoadOnScroll = function handleLoadOnScroll(scope, element, attrs) {
        var node = element[0];

    element.bind('scroll', function(event) {
        node = event.srcElement;
      scope.handleShadow(node);
      scope.handleLoadingCallback(node);
    });

    scope.handleShadow = function handleShadow() {
      node.scrollTop > 0 ? scope.on_scroll = true : scope.on_scroll = false;
      scope.$apply();
    }

        scope.$on("event:scroll-to-top", function (event) {
            node.scrollTop = 0;
        });

    scope.handleLoadingCallback = function handleLoadOnScroll() {
      if((node.scrollTop + node.offsetHeight) >= (node.scrollHeight - 500)) {
        scope.$apply(scope.loadOnScroll);
      }
    }
	}

  return {
    link: handleLoadOnScroll,
      scope: {
          loadOnScroll: '&'
      }
  }
}]);