commusoftCommon.service('purchaseTrackerService', function(adminPrefix) {
    this.deliveryAddress = {
        'addressline1': '',
        'addressline2': '',
        'addressline3': '',
        'town': '',
        'county': '',
        'postcode': '',
        'country': ''
    }
});
