commusoftCommon.directive('dragEnter', function () {
    return {
        link: function (scope, elem, attrs, ctrl) {
            if ('draggable' in document.createElement('span')) {
                elem[0].addEventListener("dragover", function (evt) {
                    evt.stopPropagation();
                    evt.preventDefault();
                    scope.onDragOver();
                }, false);
            }
        },
        controller: function ($scope) {
            $scope.ondrag = false;
            $scope.onDragOver = function () {
                if (!$scope.ondrag) {
                    $scope.$emit('event:DragOver');
                    $scope.ondrag = true;
                }
            }
        }
    };
});