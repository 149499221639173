var csDiaryHelpers = (function () {
    /**
     *  CS Diary Helper functions
     */

    var calendar;

    function smartProperty(obj, name) { // get a camel-cased/namespaced property of an object
        obj = obj || {};
        if (obj[name] !== undefined) {
            return obj[name];
        }
        var parts = name.split(/(?=[A-Z])/),
            i = parts.length - 1, res;
        for (; i >= 0; i--) {
            res = obj[parts[i].toLowerCase()];
            if (res !== undefined) {
                return res;
            }
        }
        return obj['default'];
    }

    function opt(name) {
        var options = calendar.options;

        var v = options[name];
        if ($.isPlainObject(v) && !isForcedAtomicOption(name)) {
            return smartProperty(v, calendar.view.name);
        }
        return v;
    }

    var getEventTimeText = function (event, formatStr) {
        var start;
        var end;

        if (typeof event === 'object' && typeof formatStr === 'object') {
            // first two arguments are actually moments (or null). shift arguments.
            start = event;
            end = formatStr;
            formatStr = arguments[2];
        }
        else {
            // otherwise, an event object was the first argument
            start = event.start;
            end = event.end;
        }

        formatStr = formatStr || opt('timeFormat');

        if (end && opt('displayEventEnd')) {
            return calendar.formatRange(start, end, formatStr);
        }
        else {
            return calendar.formatDate(start, formatStr);
        }
    };

    var getEventDesign = function(view, event){
        calendar = view;
        var viewName = view.name;
        var customHtml = '';
        var specialEventDesign = '';
        var workAddress = '';
        var workAddressImage = '';
        var img = '<span class="ss-user"></span>';
        var resizer = '<span></span>';
        var additionalClasses = '';
        var clickFunction = 'eventSingleClick('+event.id+')';
        var statusIcon = '';
        var weekClass = ''
                if(viewName == 'agendaWeek'){
                    weekClass = 'cs_event_position'
                }
                else {
                    weekClass = '';
                }
        /*if(event.type == 'estimate' || event.type == 'job') {
            clickFunction = 'eventSingleClick('+event.id+')';
        }*/

        var timelinePopupAttributes = '';
        var popUpAttributes =  'data-pop-up-identifier="diary" ng-mousedown="show_event_pop_up = false" '+
            'data-event-data="" ng-mouseenter="show_event_pop_up = true;getPopUpData($event, '+event.id+')" '+
            'ng-mouseleave="show_event_pop_up = false" ng-click="show_event_pop_up = false;'+clickFunction+'"';

        if (viewName == 'timelineDay' || viewName == 'timelineWeek') {
            timelinePopupAttributes = {
                'data-pop-up-identifier': 'diary',
                'ng-mousedown': 'show_event_pop_up = false',
                'data-event-data': '',
                'ng-mouseenter': 'show_event_pop_up = true;getPopUpData($event,' + event.id + ')',
                'ng-mouseleave': 'show_event_pop_up = false',
                'ng-click': 'show_event_pop_up = false;' + clickFunction
            };
            popUpAttributes = '';    
        }
        
        //Todo: This is temp, we may need to reduce the time text length based on the event width
        var timeText = (event.allDay) ? event.allDay : event.start.format('hh:mm') +' - ' + event.end.format('hh:mm');


        if(event.userProfilePicture) {
            img = '<img src="'+event.userProfilePicture+'">';
        }

        if(!event.allDay && !event.isSpecialEvent) {
            if (viewName == 'timelineDay') {
                resizer = '<div class="fc-resizer fc-start-resizer"></div><div class="fc-resizer fc-end-resizer"></div>';

            }else if(viewName == 'timelineWeek'){
                resizer= ""
            }
             else {
                resizer = '<div class="cs-event-resizer fc-resizer"></div>';
            }
        }

        if(viewName == 'twoWeek' || viewName == 'month'){
            additionalClasses += 'with-static-height';
            var resizer = '<span></span>';
        }

        if(event.grayedOut){
            additionalClasses += (additionalClasses != '') ? ' ' : '';
            additionalClasses += 'greyed-out';
        }

        if(event.eventColor && event.eventColor != ''){
            additionalClasses += (additionalClasses != '') ? ' ' : '';
            additionalClasses += event.eventColor;
        }

        if(event.isSpecialEvent){
            timeText = '<a ng-click="changeSpecialEventDuration('+event.id+')">' + event.duration + '</a>';
         if(viewName == 'timelineDay'){
            specialEventDesign += '<span class="cs-special-event-spacer for-'+event.type+'-event"><div class="fc-resizer fc-start-resizer" " style="width: 10px;height: 100%;left: 0px;position: absolute"></div></span>'+
            '<span class="cs-special-event-spacer for-'+event.type+'-event"><div class="fc-resizer fc-end-resizer" " style="width: 10px;height: 100%;right: 0px;position: absolute"></div></span>';
         }else{
            specialEventDesign += '<span class="cs-special-event-spacer for-'+event.type+'-event"></span>'+
                '<span class="cs-special-event-spacer for-'+event.type+'-event"><span class="cs-event-resizer fc-resizer"></span></span>';
        }
    }
        if(event.type == 'estimate' || event.type == 'job' || event.type == 'opportunity') {
            var doubleClick = 'ng-dblclick="eventDoubleClick('+event.id+')" ng-class="{\'highlight_event\':'+event.id+' == selectedDiaryEventId}"';
            var customerName = '<strong>'+event.otherDetails.customerName+'</strong>';
            var phoneSymDesign = (event.otherDetails.contactNumber != '') ? '<span class="ss-phone"></span>' : '';
            if(event.otherDetails.work_addrs_name) {
                workAddress = '<strong>'+event.otherDetails.work_addrs_name+'</strong>';
                workAddressImage = '<span class="ss-buildings"> ';
            }
            var addressDetails = '<p class="address"></span>' + workAddressImage + event.otherDetails.addressline + ',<strong>' + event.otherDetails.postcode + '</strong></p>';

            if (viewName != 'timelineDay' && viewName != 'timelineWeek') {
                addressDetails += '<span class="contact-number">' + phoneSymDesign +
                                        '<span>' + event.otherDetails.contactNumber +'</span>' +
                                  '</span>';
            } else {
                if (event.otherDetails.work_addrs_name) {
                    addressDetails = '';
                } else {
                    addressDetails = '<p class="address"></span>' + event.otherDetails.addressline + ',<strong>' + event.otherDetails.postcode + '</strong></p>';
                }

            }

            var statusName = event.type + 'Status';
            var jobStatus = event.otherDetails[statusName];
            if(jobStatus) {
                var freeOfChargeArray = ['FreeOfCharge', 'Free of charged', 'Marked as free of charge'];
                var acceptedArray = ['Invoiced', 'Accepted', 'Completed'];
                var rejectedArray = ['Aborted', 'Rejected', 'Terminated'];
                var statusClass = '';
                if(freeOfChargeArray.indexOf(jobStatus) != -1) {
                    statusClass = 'free-of-charged';
                } else if(acceptedArray.indexOf(jobStatus) != -1) {
                    statusClass = 'accepted';
                } else if(rejectedArray.indexOf(jobStatus) != -1) {
                    statusClass = 'rejected';
                }
                if(jobStatus == 'Invoiced' && event.type == 'job') {
                    statusClass = 'invoiced';
                }
                statusIcon = '<span class="estimate-status with-abs-pos '+statusClass+'">&nbsp;</span>';
            }
        }

        var extraSchedulerClass = '';
        var extraSchedulerClassNormal = '';
        if (viewName == 'timelineDay' || viewName == 'timelineWeek') {
            extraSchedulerClass = 'cs-event-header-scheduler';
            extraSchedulerClassNormal = 'cs-normal-event-scheduler';
        }
        if(event.type=='service_window'){
            customHtml = '' +
                '<div class="cs-event cs-event cs-event-colour-5" '+popUpAttributes+'>' +specialEventDesign+
                '<div class="cs-event-inner">' +
                '<div class="cs-event-body">' +
                '<p><strong>Service window name</strong>: ' + event.title + '</p>' +
                '<p><strong>Customer appearance</strong>: ' + event.customerAppearance + '</p>' +
                '<p><strong>Other events</strong>: ' + event.event_included + '</p>' +
                '<p><strong>Time slot</strong>: ' + event.start_time+' - '+event.end_time + '</p>' +
                '</div>' + resizer +
                '</div>' +
                '</div>';
        }
        if(event.type=='shift_type'){
            if(event.shift_type =='Overtime') {
                customHtml = '' +
                    '<div class="cs-event cs-event cs-event-colour-13" ' + popUpAttributes + ' style="cursor: pointer;">' + specialEventDesign +
                    '<div class="cs-event-inner">' +
                    '<div class="cs-event-body">' +
                    '<p><strong>Shift type</strong>: ' + event.shift_type + '</p>' +
                    '<p><strong>Time slot</strong>: ' + event.start_time + ' - ' + event.end_time + '</p>' +
                    '</div>' +
                    '</div>' +
                    '</div>';
            }
            else {
                customHtml = '' +
                    '<div class="cs-event cs-event cs-event-colour-5" ' + popUpAttributes + ' style="cursor: pointer;">' + specialEventDesign +
                    '<div class="cs-event-inner">' +
                    '<div class="cs-event-body">' +
                    '<p><strong>Shift type</strong>: ' + event.shift_type + '</p>' +
                    '<p><strong>Time slot</strong>: ' + event.start_time + ' - ' + event.end_time + '</p>' +
                    '</div>' +
                    '</div>' +
                    '</div>';
            }
        }
        //TODO:Need to move this to html
        if(event.type == 'normal') {
            customHtml = '' +
                '<div ng-class="{\'normal_event_overflow\': currentView == \'agendaDay\' || currentView == \'diaryMap\' || currentView == \'agendaWeek\'}" class="cs-event '+additionalClasses+'" '+popUpAttributes+'>' +specialEventDesign+
                '<div ng-class="{\'cs-event-position\': currentView == \'agendaDay\' || currentView == \'diaryMap\'}" class="cs-event-inner '+weekClass+'">' +
                '<div class="cs-event-header ' + extraSchedulerClass + '">' +
                '<div class="cs-event-header-upper">' +
                '<span class="cs-event-header-event-type normal">n</span>' +
                '<span class="cs-event-header-time">' + timeText + '</span>' +
                '<span class="cs-event-header-text">' + event.title + '</span>' +
                '<span class="cs-event-header-status">' +
                '<span class="diary-event-status ' + event.status.class + '"></span>' +
                '</span>' +
                '</div>';

            if (viewName != 'timelineDay' && viewName != 'timelineWeek') {
                customHtml += '<div class="cs-event-header-lower">' +
                '<span class="cs-event-header-user">' + img +
                '<span>' + event.userName + '</span>' +
                '</span>' +
                '</div>'
                '<hr>';
            }
            customHtml +=  '</div>' +
                '<div class="cs-event-body" ng-class="{\'timeline-normal-event-body \': currentView == \'timelineDay\' || currentView == \'timelineWeek\'}">' +
                '<p class="' + extraSchedulerClassNormal + '">' + event.title + '</p>';

            if (viewName == 'timelineDay' || viewName == 'timelineWeek') {
                customHtml += '<p class="' + extraSchedulerClassNormal + '">&nbsp;</p>';
            }

            customHtml += '</div>' + resizer +
                '</div>' +
                '</div>';
        } else if(event.type == 'estimate') {
            customHtml = '' +
                '<div class="cs-event '+additionalClasses+'" '+popUpAttributes+' '+doubleClick+'>' +specialEventDesign+statusIcon+
                '<span class="estimate-status with-abs-pos '+ event.otherDetails.estimateStatus +'">&nbsp;</span>' +
                '<div ng-class="{\'cs-event-position\': currentView == \'agendaDay\' || currentView == \'diaryMap\' }" class="cs-event-inner '+weekClass+'">' +
                '<div class="cs-event-header ' + extraSchedulerClass + '">' +
                '<div class="cs-event-header-upper">' +
                '<span class="cs-event-header-event-type estimate">e</span>' +
                '<span class="cs-event-header-time">' + timeText  + '</span>' +
                '<span class="cs-event-header-text">' + event.title + '</span>' +
                '<span has-permission="LockDiaryEvents,readaccess" class="diary-lock-icon ' + event.event_lock_class + '"><span class="ss-lock"></span></span>'+
                '<span class="cs-event-header-status">' +
                '<span class="diary-event-status ' + event.status.class + '"></span>' +
                '</span>' +
                '</div>';

            if (viewName != 'timelineDay' && viewName != 'timelineWeek') {
                customHtml += '<div class="cs-event-header-lower">' +
                    '<span class="cs-event-header-user">' + img +
                    '<span>' + event.userName + '</span>' +
                    '</span>' +
                    '</div>' +
                    '<hr>';
            }

            customHtml +=  '</div>' +
                '<div class="cs-event-body">' +
                '<div class="property-item">' +
                '<span class="property-details">' +workAddress+customerName+addressDetails+ '</span>' +
                '</div>' +
                '</div>' + resizer +
                '</div>' +
                '</div>';
        } else if(event.type == 'job') {
            customHtml = '' +
                '<div class="cs-event '+additionalClasses+'" '+popUpAttributes+'  '+doubleClick+'>' +specialEventDesign+statusIcon+
                '<span class="estimate-status with-abs-pos">&nbsp;</span>'+
                '<div ng-class="{\'cs-event-position\': currentView == \'agendaDay\' || currentView == \'diaryMap\'}" class="cs-event-inner '+weekClass+'">'+
                '<div class="cs-event-header ' + extraSchedulerClass + '">' +
                '<div class="cs-event-header-upper">'+
                '<span class="cs-event-header-event-type job">j</span>'+
                '<span class="cs-event-header-time">' + timeText  + '</span>'+
                '<span class="cs-event-header-text">' + event.title + '</span>'+
                '<span has-permission="LockDiaryEvents,readaccess" class="diary-lock-icon ' + event.event_lock_class + '"><span class="ss-lock"></span></span>'+
                '<span class="cs-event-header-status">'+
                '<span class="diary-event-status ' + event.status.class + '"></span>'+
                '</span>'+
                '</div>';

            if (viewName != 'timelineDay' && viewName != 'timelineWeek') {
                customHtml += '<div class="cs-event-header-lower">' +
                    '<span class="cs-event-header-user">' + img +
                    '<span>' + event.userName + '</span>' +
                    '</span>' +
                    '</div>' +
                    '<hr>';
            }

            customHtml += '</div>'+
                '<div class="cs-event-body">'+
                '<div class="property-item">'+
                '<span class="property-details">'+workAddress+customerName+addressDetails+'</span>'+
                '</div>'+
                '</div>'+resizer+
                '</div>'+
                '</div>';
        }else if(event.type == 'publicholidayCalender') {
            customHtml = '' +
                '<div class="cs-event '+additionalClasses+'" '+popUpAttributes+'>' +specialEventDesign+
                '<div class="cs-event-inner">' +
                '<div class="cs-event-header">' +
                '<div class="cs-event-header-upper">' +
                '<span class="cs-event-header-time">' + event.title + '</span>' +
                '<span class="cs-event-header-status">' +
                '</span>' +
                '</div>' +
                '<div class="cs-event-header-lower">' +
                '<span class="cs-event-header-user">' +
                '<span>' + event.userName + '</span>' +
                '</span>' +
                '</div>' +
                '<hr>' +
                '</div>' +
                '<div class="cs-event-body">' +
                '<p>' + event.title + '</p>' +
                '</div>' + resizer +
                '</div>' +
                '</div>';
        }
        else if(event.type == 'opportunity') {
            customHtml = '' +
                '<div class="cs-event '+additionalClasses+'" '+popUpAttributes+' '+doubleClick+'>' +specialEventDesign+statusIcon+
                '<span class="estimate-status with-abs-pos '+ event.otherDetails.opportunityStatus +'">&nbsp;</span>' +
                '<div ng-class="{\'cs-event-position\': currentView == \'agendaDay\' || currentView == \'diaryMap\' }" class="cs-event-inner '+weekClass+'">' +
                '<div class="cs-event-header ' + extraSchedulerClass + '">' +
                '<div class="cs-event-header-upper">' +
                '<span class="cs-event-header-event-type opportunity">o</span>' +
                '<span class="cs-event-header-time">' + timeText  + '</span>' +
                '<span class="cs-event-header-text diary-event-title-alignment">' + event.title + '</span>' +
                '<span has-permission="LockDiaryEvents,readaccess" class="diary-lock-icon ' + event.event_lock_class + '"><span class="ss-lock"></span></span>'+
                '<span class="cs-event-header-status">' +
                '<span class="diary-event-status ' + event.status.class + '"></span>' +
                '</span>' +
                '</div>';

            if (viewName != 'timelineDay' && viewName != 'timelineWeek') {
                customHtml += '<div class="cs-event-header-lower">' +
                    '<span class="cs-event-header-user">' + img +
                    '<span>' + event.userName + '</span>' +
                    '</span>' +
                    '</div>' +
                    '<hr>';
            }

            customHtml +=  '</div>' +
                '<div class="cs-event-body">' +
                '<div class="property-item">' +
                '<span class="property-details">' +workAddress+customerName+addressDetails+ '</span>' +
                '</div>' +
                '</div>' + resizer +
                '</div>' +
                '</div>';
        }
        return { html: customHtml, popup: timelinePopupAttributes};
    };

    var getEventHtml = function (view, event) {
        var htmlObject = {};
        if(view.name == 'agendaWeek'){
            weekClass = 'cs_event_position'
        }
        else {
            weekClass = '';
        }
        if(event.id > 0) {
            htmlObject = getEventDesign(view, event);
        } else {
            var unzonedRange = null;
            // for views that span a large unit of time, show the proper interval, ignoring stray days before and after
            if (/^(year|month)$/.test(view.dateProfile.currentRangeUnit)) {
                unzonedRange = view.dateProfile.currentUnzonedRange
            } else { // for day units or smaller, use the actual day range
                unzonedRange = view.dateProfile.activeUnzonedRange
            }

            var dateRange = (event.allDay) ? event.allDay : event.start.format('hh:mm') +' - ' + event.end.format('hh:mm');
            //  view.formatRange(
                                // {
                                    // start: view.calendar.msToMoment(unzonedRange.startMs, view.dateProfile.isRangeAllDay),
                                    // end: view.calendar.msToMoment(unzonedRange.endMs, view.dateProfile.isRangeAllDay)
                                // }, view.dateProfile.isRangeAllDay, view.computeTitleFormat(view.dateProfile),view.opt('titleRangeSeparator'));

            customHtml = '<div class="cs-event">' +
            '<div class="cs-event-inner '+weekClass+'">' +
                '<div class="cs-event-header">' +
                '<div class="cs-event-header-upper">' +
                '<span class="cs-event-header-event-type selection-placeholder"></span>' +
                '<span class="cs-event-header-time">' + dateRange + '</span>' +
                '<span class="cs-event-header-text"></span>' +
                '<span class="cs-event-header-status">' +
                '<span class="diary-event-status"></span>' +
                '</span>' +
                '</div>' +
                '<div class="cs-event-header-lower">' +
                '<span class="cs-event-header-user">'+
                '<span></span>' +
                '</span>' +
                '</div>' +
                '</div>' +
                '<div class="cs-event-body">' +
                '<p></p>' +
                '</div>' +
                '<span class="cs-event-resizer"></span>' +
                '</div>' +
                '</div>';
            htmlObject = {
                html: customHtml,
                popup: ''
            }
        }

        return htmlObject;
    };

    return {
        getEventDesign: getEventDesign,
        getEventHtml: getEventHtml
    }
}());
