commusoftCommon.directive('csimageuploadmodal', ['prefix', '$http', '$modal', function(prefix, $http, $modal) {
    return {
        transclude: true,
        restrict: 'AE',
        scope: {
            shouldBeOpen: '=',
            imgOptions: '=',
            action: '=',
            mode: '=',
            saveResizedImage: '&'
        },
        controller: function($scope, $element, $attrs, $transclude) {
            $scope.$watch('shouldBeOpen', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal == true) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;

                    modalInstance = $modal.open({
                        templateUrl: 'template/image_upload_modal.html',
                        scope: $scope,
                        controller: CSImageUploadModalCtrl,
                        windowClass: 'modal image-upload-modal fixed-height'
                    })
                }
            });
        }
    }
}]);