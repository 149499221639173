function getEstimatePriceSpreadsheetData($scope) {

    $scope.invoiceCategories = [];

    var spreadsheet_schema = {
        'no_breakdown': {
            'column_header': ['Id','Total', $scope.taxLabel, 'Tax rate','Quantity','Total','Total tax'],
            'default_schema': {id: null, unitPrice: 0.00, taxItemId: $scope.default_tax_item_id, taxRate: 0.00,quantity:1,total:0.00,totalTax:0.00},
            'required_column': {"1": "Total", "2": "Tax"},
            'column_width': [60,120,110,60,60,60],
            'hidden_column': {columns: [0,3,4,5,6]},
            'row_limit' : 1,
            'autoSave_col' : 'unitPrice',
            'col_params': [
                {data: 'id', type: 'numeric', readOnly: true},
                {renderer: $scope.estimateCustomRender, data: 'unitPrice', type: 'numeric', format:'0.00', validator:$scope.emptyCellValidator},
                {
                    renderer: $scope.estimateCustomRender,
                    validator:$scope.emptyCellValidator,
                    data: 'taxItemId',
                    editor: 'select2',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    select2Options: {
                        editable: true,
                        data: $scope.taxItemsGroupByProvinces,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: "handsontable-select"
                    }
                },
                {data: 'taxRate', type: 'numeric', readOnly: true},
                {data: 'quantity', type: 'numeric', readOnly: true},
                {data: 'total', type: 'numeric', readOnly: true},
                {data: 'totalTax', type: 'numeric', readOnly: true}
            ]
        },
        'category_breakdown' : {
            'column_header': ['Id','Description','Total', $scope.taxLabel, 'Tax rate','Quantity','Total','Total tax','InvoiceItemId'],
            'default_schema': {id: null,description:'', unitPrice: 0.00, taxItemId: $scope.default_tax_item_id, taxRate: 0.00,quantity:1,total:0.00,totalTax:0.00,invoiceItemId:null},
            'required_column': {"1": "Total","2":"Total", "3": "Tax"},
            'column_width': [60,170,70,150,60,60,60,60,60],
            'hidden_column': {columns: [0,4,5,6,7,8]},
            'row_limit' : $scope.invoiceItemCategoryLists.length,
            'autoSave_col' : 'description',
            'col_params': [
                {data: 'id', type: 'numeric', readOnly: true},
                {data: 'description', type: 'text',readOnly:true},
                {renderer: $scope.estimateCustomRender, data: 'unitPrice', type: 'numeric',format:'0.00' ,validator:$scope.emptyCellValidator},
                {
                    renderer: $scope.estimateCustomRender,
                    validator:$scope.emptyCellValidator,
                    data: 'taxItemId',
                    editor: 'select2',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    select2Options: {
                        editable: true,
                        data: $scope.taxItemsGroupByProvinces,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: "handsontable-select"
                    }
                },
                {data: 'taxRate', type: 'numeric', readOnly: true},
                {data: 'quantity', type: 'numeric', readOnly: true},
                {data: 'total', type: 'numeric', readOnly: true,format:'0.00'},
                {data: 'totalTax', type: 'numeric', readOnly: true},
                {data: 'invoiceItemId', type: 'numeric', readOnly: true}
            ]
        },
        'full_breakdown' : {
            'column_header': ['Id','Description','Quantity','Unit Price', $scope.taxLabel, 'Tax rate','Total','Total tax','Action'],
            'default_schema': {id: null,description:'',quantity: 1,unitPrice: 0.00, taxItemId: $scope.default_tax_item_id, taxRate: 0.00,total:0.00,totalTax:0.00,action:''},
            'required_column': {"1": "Description", "2": "Quantity","3":"Unit Price","4":"Tax"},
            'column_width': [60,170,80,85,150,60,80,60,60],
            'hidden_column': {columns: [0,5,7]},
            'row_limit' : null,
            'autoSave_col' : 'description',
            'col_params': [
                {data: 'id', type: 'numeric', readOnly: true},
                {data: 'description', type: 'text'},
                {renderer: $scope.estimateCustomRender, data: 'quantity', type: 'numeric', allowInvalid: true, validator:$scope.emptyCellValidator},
                {renderer: $scope.estimateCustomRender, data: 'unitPrice', type: 'numeric', format:'0.00',validator:$scope.emptyCellValidator},
                {
                    renderer: $scope.estimateCustomRender,
                    validator:$scope.emptyCellValidator,
                    data: 'taxItemId',
                    editor: 'select2',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    select2Options: {
                        editable: true,
                        data: $scope.taxItemsGroupByProvinces,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: "handsontable-select"
                    }
                },
                {data: 'taxRate', type: 'numeric', readOnly: true},
                {data: 'total', type: 'numeric', format:'0.00', readOnly: true},
                {data: 'totalTax', type: 'numeric', readOnly: true},
                {renderer: $scope.estimateCustomRender, data: 'action', readOnly: true, disableVisualSelection: true}
            ]
        },
        'full_breakdown_by_category' : {
            'column_header': ['Id','Description','Quantity','Unit Price', $scope.taxLabel, 'Tax rate','Total','Total tax','CategoryId','Action'],
            'default_schema': {id: null,description:'',quantity: 1,unitPrice: 0.00, taxItemId: $scope.default_tax_item_id, taxRate: 0.00,total:0.00,totalTax:0.00,categoryId:'',action:''},
            'required_column': {"1": "Description", "2": "Quantity","3":"Unit Price","4":"Tax"},
            'column_width': [60,170,80,85,150,60,80,60,60,60],
            'hidden_column': {columns: [0,5,7,8]},
            'row_limit' : null,
            'autoSave_col' : 'description',
            'col_params': [
                {data: 'id', type: 'numeric', readOnly: true},
                {data: 'description', type: 'text'},
                {renderer: $scope.estimateCustomRender, data: 'quantity', type: 'numeric', allowInvalid: true, validator:$scope.emptyCellValidator},
                {renderer: $scope.estimateCustomRender, data: 'unitPrice', type: 'numeric', format:'0.00',validator:$scope.emptyCellValidator},
                {
                    renderer: $scope.estimateCustomRender,
                    validator:$scope.emptyCellValidator,
                    data: 'taxItemId',
                    editor: 'select2',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    select2Options: {
                        editable: true,
                        data: $scope.taxItemsGroupByProvinces,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: "handsontable-select"
                    }
                },
                {data: 'taxRate', type: 'numeric', readOnly: true},
                {data: 'total', type: 'numeric', format:'0.00', readOnly: true},
                {data: 'totalTax', type: 'numeric', readOnly: true},
                {dara: 'categoryId' , type: 'numeric', readOnly:true},
                {renderer: $scope.estimateCustomRender, data: 'action', readOnly: true, disableVisualSelection: true}
            ]
        }
    };

    return {
        getSchema: function (category) {

                //# if tax is not enabled
                if ($scope.isHideVat){
                    var taxIndex = spreadsheet_schema[category]['column_header'].indexOf($scope.taxLabel);
                    spreadsheet_schema[category]['hidden_column']['columns'].push(taxIndex);
                }

                //# update readonly settings for hidden column
                var col = spreadsheet_schema[category]['hidden_column']['columns'];
                _.each(col, function (value, key) {
                    spreadsheet_schema[category]['col_params'][value].readOnly = true;
                });

                if($scope.estimateStatus == 'accepted')
                {
                     var schema = spreadsheet_schema[category]['col_params'];
                    _.each(schema, function (value, key) {
                        spreadsheet_schema[category]['col_params'][key].readOnly = true;
                        spreadsheet_schema[category]['col_params'][key]['className'] = "cell-without-border";
                    });

                    var key = $scope.spreadsheetLineItems.length;
                    spreadsheet_schema[category]["row_limit"] = $scope.lineItemCount[key]['lineItemCount'];
                }

            return spreadsheet_schema[category];
        }
    };
}

function getEstimatePriceSpreadsheet($scope,toastBox,prefix,$http,confirmationBoxHelper) {

    //# Initialize additional params
    $scope.spreadsheetLineItems = [];
    $scope.listOfTaxItems = [];
    $scope.spreadsheetTotal = [];
    $scope.prefix = prefix;
    $scope.section_open = true;
    $scope.total_price = 0;
    $scope.total_tax = 0;
    $scope.grandTotal = 0;

    var getSidePanelTotal = prefix + '/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/estimate/'+$scope.estimateId+'/getEstimateTotal?optionId='+$scope.estimateOptionId;


    $scope.getVatGroupByProvinces($scope.taxItems,$scope.deletedTaxItems);

    //# Validate empty cell
    $scope.emptyCellValidator = function (value, callback) {
        if (value === '') {
            toastBox.show('Empty cell not allowed', 1000);
        }
        callback(true);
    };
    
    $scope.$on('spreadsheetData:deleteFunction',function (event,spreadsheet) {
        if($scope.estimateStatus == 'accepted')
        {
            return false;
        }
        $scope.deleteAction = function (r, c, hotId) {
            var confirmation_message = 'This row has not been saved yet, are you sure you want to delete it?';
            var instance = spreadsheet['instance'][hotId];
            if (c === (instance.getInstance().countCols() - 1)) {
                //# Before delete confirm
                confirmationBoxHelper.getConfirmation(confirmation_message, this)
                    .then(function () {

                        //# Get the primary id of the edit screen, to delete from backend
                        var data = instance.getSourceDataAtRow(r);

                        if(instance.getSettings().autoSave === true && data && data.id) {
                            var url  =  spreadsheet.deleteUrl;
                            url = url.replace('{id}',data.id);

                            toastBox.show('Page loading',1000);
                            $http.post(url,'data='+angular.toJson(data)).success(function (data,status) {
                                $http.get(getSidePanelTotal).success(function (data) {
                                    $scope.$emit('estimateDetail:value',data);
                                });
                            }).error(function (data,status) {
                                console.log('error');
                                return false
                            });
                        }else if ((typeof data != 'undefined') && data.id) {
                            $scope.rowToDelete.push(data.id);
                        }
                        instance.alter('remove_row', r);
                        toastBox.show('Row deleted',1500);

                        //# Check if last row is not empty, then create an empty row.
                        var rowsCount = instance.countRows();
                        if (rowsCount == 0 || rowsCount == r) {
                            instance.alter('insert_row', rowsCount);
                        }

                        instance.render();
                        $scope.calculateEstimateTotal();
                    }, function () {
                        return false
                    });
            }
        };
    });

    $scope.estimateCustomRender = function (instance, td, row, col, prop, value, cellProperties) {
        var cellCase = prop, selectedId;
        var hotId = instance.getSettings().hotId;

        switch (cellCase) {

            case 'taxItemId' :
                var effective_tax,selectedTax,totalTax;

                value = (value) ? value.toString() : value;
                selectedTax = _.where($scope.listOfTaxItems, {id: value} );

                if (selectedTax.length > 0) {
                    selectedId = selectedTax[0].id;
                    value = selectedTax[0].text;
                    effective_tax = selectedTax[0].effective_tax;
                } else {
                    selectedId = 0;
                    value = '0.00';
                    effective_tax = 0;
                }

                totalTax = ($scope.spreadsheetLineItems[hotId][row].total * effective_tax)/100;

                $scope.spreadsheetLineItems[hotId][row].taxRate = effective_tax;
                $scope.spreadsheetLineItems[hotId][row].totalTax = roundFloat(totalTax,2);
                $scope.calculateEstimateTotal();
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'unitPrice' :
                value = (value && !isNaN(parseFloat(value))) ? roundFloat(value,2,'str') : '0.00';

                $scope.spreadsheetLineItems[hotId][row].unitPrice = value;
                $scope.spreadsheetLineItems[hotId][row].total = roundFloat($scope.spreadsheetLineItems[hotId][row].quantity * value,2);
                $scope.calculateEstimateTotal();
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;

            case 'quantity' :
                value = (value < 0 || isNaN(parseFloat(value))) ? 1 : roundFloat(value,2);
                $scope.spreadsheetLineItems[hotId][row].quantity = value;
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'action' :
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                var div = document.createElement('DIV');
                var a = document.createElement('a');
                var linkText = document.createTextNode("Delete");
                a.appendChild(linkText);
                a.title = "Delete Action";
                a.style = "cursor:pointer;";
                div.appendChild(a);
                $(td).empty().append(div).append(a);
                $(a).on('click', function (e) {
                    $scope.deleteAction(row, col, instance.getSettings().hotId);
                });
                break;
        }
    };

    /** show or hide in spreadsheet **/
    $scope.handleCollapse = function handleCollapse(e) {
        var hotTableElement = $(e.target).closest('.handleSpreadsheet').find('.handsontable-spreadsheet');
        if (angular.element(hotTableElement).hasClass('ng-hide')) {
            angular.element(hotTableElement).removeClass('ng-hide');
        } else {
            angular.element(hotTableElement).addClass('ng-hide');
        }
    };

    $scope.calculateEstimateTotal = function () {
        var total_tax   = 0,
            total_price = 0,
            spreadsheetTotal = 0,
            breakdown_by_vat_rates = {},
            taxCost,
            i;

        angular.forEach($scope.spreadsheetLineItems, function (value, key) {
            spreadsheetTotal = 0;
            for (i = 0; i < value.length; i++) {
                total_tax   += roundFloat(value[i]['totalTax'],2);
                total_price +=  roundFloat(value[i]['total'],2);
                spreadsheetTotal +=  roundFloat(value[i]['total'],2);

                //# for breakdown by type 2
                if(value[i]['taxItemId'] != 0 && (typeof value[i]['description'] == "undefined" || value[i]['description']))
                {
                    taxCost = (typeof breakdown_by_vat_rates[value[i].taxRate] == 'undefined') ? 0 : breakdown_by_vat_rates[value[i].taxRate];
                    breakdown_by_vat_rates[value[i].taxRate]  = taxCost +  parseFloat(value[i].totalTax) ;
                }
            }
            $scope.spreadsheetTotal[key] = spreadsheetTotal;
        });
        $scope.total_price = total_price;
        $scope.total_tax = total_tax;
        $scope.grandTotal = total_price + total_tax;
        $scope.breakdown_by_vat_rates = breakdown_by_vat_rates;
    };

    var schema = getEstimatePriceSpreadsheetData.call(this,$scope);

    $scope.$on('spreadsheetData:lineItems', function(event,data) {

        if(isArray(data)) {
            $scope.spreadsheetLineItems[data[1]] = data[0];
        }else {
            $scope.spreadsheetLineItems = data;
        }

        // Update side panel estimate total
        if($scope.grandTotal != undefined && $scope.estimateStatus != 'accepted')
        {
            $http.get(getSidePanelTotal).success(function (data) {
                $scope.$emit('estimateDetail:value',data);
            });
        }

    });

    $scope.$on('spreadsheet:addData',function (event,data) {
        $scope.addSpreadsheetData(data);
    });

    // side panel data update
    $scope.addSpreadsheetData = function(spreadsheetData){
       var schema;
       var i,lineItems;
       var hotId = ($scope.active_option === 'full_breakdown_by_category') ? $scope.active_option+'_2' : $scope.active_option; // 2 for parts

        angular.forEach(spreadsheetData, function (value, key) {
            schema =  angular.copy($scope.spreadsheet_settings.dataSchema);

            if ($scope.active_option === 'no_breakdown') {
                lineItems = $scope.spreadsheetLineItems[hotId];
                schema.unitPrice = value.unitPrice * value.quantity;

                lineItems[0]['id']       = value.id;
                lineItems[0]['taxRate'] = value.taxRate;
                lineItems[0]['taxItemId'] = value.taxItemId;
                lineItems[0]['unitPrice'] = parseFloat(schema.unitPrice);
            } else if ($scope.active_option === 'category_breakdown') {
                schema.unitPrice = value.unitPrice * value.quantity;

                lineItems = $scope.spreadsheetLineItems[hotId];
                var lineItem = _.where(lineItems, {invoiceItemId: value.invoiceItemId});

                lineItem[0]['id']       = value.id;
                lineItem[0]['taxRate']  = value.taxRate;
                lineItem[0]['taxItemId'] = value.taxItemId;
                lineItem[0]['unitPrice'] = parseFloat(schema.unitPrice);
            } else if ($scope.active_option === 'full_breakdown' || $scope.active_option === 'full_breakdown_by_category') {

                var category = _.where($scope.invoiceItemCategory, {id: value.categoryId});
                if(category.length === 0 && $scope.active_option === 'full_breakdown_by_category')
                {
                    var newCategory = _.where($scope.invoiceItem,{id:value.categoryId});
                    $scope.invoiceItemCategory.push(newCategory[0]);
                    var invoiceItem = _.filter($scope.invoiceItem,function(obj){
                        return obj['id'] != newCategory[0]['id'];
                    });
                    $scope.$emit('remove:added-invoice-item',invoiceItem);
                }

                schema.id           = value.id;
                schema.description = value.description;
                schema.quantity = value.quantity;
                schema.unitPrice = value.unitPrice;
                schema.taxItemId = value.taxItemId;
                schema.taxRate = value.taxRate;
                schema.categoryId = value.categoryId;

                i = $scope.spreadsheetLineItems[hotId].length - 1;
                $scope.spreadsheetLineItems[hotId].splice(i, 0, schema);
            }

        });

        $scope.$broadcast('spreadsheet:updateHeaderSettings');
    };

    $scope.$on('spreadsheetData:triggerSettings',function (event) {
        $scope.$broadcast('event:getSettings',$scope.spreadsheet_settings);
    });

    $scope.$on('spreadsheet:remove-added-invoice-item',function (event,data) {
        if($scope.active_option === 'full_breakdown_by_category')
        {
            var invoiceItem = _.filter($scope.invoiceItem,function(obj){
                return obj['id'] != data['id'];
            });
            $scope.$emit('remove:added-invoice-item',invoiceItem);
        }
    });


    /** init spreadsheet param **/
    $scope.getSpreadsheetInitialParams = function () {
        var spreadsheet_schema = schema.getSchema($scope.active_option);
        $scope.spreadsheet_settings = {
            startCols : 1,
            column_header : spreadsheet_schema.column_header,
            columns : spreadsheet_schema.col_params,
            hiddenColumns : spreadsheet_schema.hidden_column,
            colWidths : spreadsheet_schema.column_width,
            dataSchema : spreadsheet_schema.default_schema,
            required_column : spreadsheet_schema.required_column,
            autoSave : true,
            autoSave_col : spreadsheet_schema.autoSave_col,
            row_limit : spreadsheet_schema.row_limit
        };

        $scope.show_grand_totals = ($scope.priceView == 4 && $scope.invoiceItemCategory.length == 0) ? false : true;
    };

    $scope.$watch('invoiceItemCategory',function (newVal,oldVal) {
        $scope.show_grand_totals = ($scope.priceView == 4 && $scope.invoiceItemCategory.length == 0) ? false : true;
    },true);
}