commusoftCommon.service('checkEngineerShift',['$http','$q',function($http,$q){
    this.checkShift = function(event_start,event_end,engineerId,shift,allday){
        var deferred = $q.defer();
        if(shift == 1){
            $http.get(Routing.generate("check_engineer_shift") + "?event_start=" + event_start + "&event_end=" + event_end + "&engineer_id=" + engineerId+ "&type=" + allday)
                .success(function(data) {
                        deferred.resolve(data.shifttype); // Resolve
                    }
                );
        }else{
            deferred.resolve("noShift");
        }

        return deferred.promise;
    }

}]);