commusoftCommon.service('toastBox', function($q, $compile, $rootScope) {
    this.show = function show(message, duration) {
        var scope = $rootScope.$new(true),
            toast_box_tpl = '<div toastbox message=' + "'" +  message + "'" + ' duration=' + "'" + duration + "'" + ' id="toast-box-wrapper"></div>',
            toast_box_dir = $compile(toast_box_tpl)(scope)[0],
            toast_box = document.querySelector('#toast-box-wrapper');

        if(toast_box) {
            this.removeToastBox(toast_box);
            this.insertToastBox(toast_box_dir);
        }else {
            this.insertToastBox(toast_box_dir);
        }
    }

    this.insertToastBox = function insertToastBox(toast_box_dir) {
        angular.element('body').append(toast_box_dir);
    }

    this.removeToastBox = function removeToastBox(toast_box_elm) {
        angular.element(toast_box_elm).remove();
    }
});