csmodule.service('spreadSheetClickEvents', function($state, $rootScope, clickEvents, confirmationBoxHelper) {
  var self = this;

  this.init = function init(spread_sheet_helper_context) {
    clickEvents.registerIgnoreElements('spreadsheet');
    this.spread_sheet_helper_context = spread_sheet_helper_context;
    this.directive_scope = spread_sheet_helper_context.directive_scope;
    this.prevent_state_change = true;
    /*==========================================================================================
      Event fired when a click event is detected outside of the spreadsheet when a cell is in
      active / edit mode
    ==========================================================================================*/
    $rootScope.$on('closeallwithapply', function($event, e) {
      if(self.spread_sheet_helper_context.CELL !== undefined) {
        self.handleClickOutsideSpreadSheet(e);
      }
    });
  }

  /*==========================================================================================
    Determine what should happen when a click outside the spreadsheet happens
  ==========================================================================================*/
  this.handleClickOutsideSpreadSheet = function handleClickOutsideSpreadSheet(e) {
    var cell_in_active_mode = this.spread_sheet_helper_context.cell_state === 'active_mode',
        cell_in_edit_mode = this.spread_sheet_helper_context.cell_state === 'edit_mode',
        link_clicked = e.target.hash;

    if(link_clicked) {
      var link_has_path = link_clicked.length > 1;
      this.is_ui_router_link = e.target.hasAttribute('ui-sref');
    }

    if(cell_in_active_mode) {
      this.prevent_state_change = false;
      this.spread_sheet_helper_context.removeAllInstanceVariables();
    }

    if(cell_in_edit_mode) {
      if(this.spread_sheet_helper_context.directive_scope.supports_totals) {
        this.spread_sheet_helper_context.handleCalculations();
      }

      if(link_has_path) {
        this.checkUserWantsToChangeView(e);
      }else {
        this.spread_sheet_helper_context.removeActiveState();
      }
    }
  }

  /*==========================================================================================
    Called when a cell is in edit mode and user has clicked a anchor with a valid href
  ==========================================================================================*/
  this.checkUserWantsToChangeView = function checkUserWantsToChangeView(e) {
    if(this.is_ui_router_link) {
      this.preventStateChange();
    }else {
      this.preventPageChange(e);
    }

    this.getConfirmationFromUser();
  }

  /*==========================================================================================
    Prompt user that changing state/page could result in loss of current cell data, and
    determine what they wish todo - (change page || stay on current page)
  ==========================================================================================*/
  this.getConfirmationFromUser = function getConfirmationFromUser() {
    var column_name = this.directive_scope.spreadsheet_headers[this.directive_scope.active_col_axis].value.toLocaleLowerCase(),
        row_number = (this.directive_scope.active_row_axis + 1),
        confirmation_message = 'You are currently editing the ' + '&#34;' + column_name + '&#34;' + ' in row ' + row_number + '. Do you want to discard un-saved changes?';

    confirmationBoxHelper.getConfirmation(confirmation_message, this.directive_scope)
      .then(function() {
        self.discardUnSavedCellChanges();
      }, function() {
        self.prevent_state_change = false;
        self.refocusCellInEditMode();
      });
  }

  /*==========================================================================================
    Prevent a ui-router state change, so we can confirm that current changes to selected cell
    will be discarded
  ==========================================================================================*/
  this.preventStateChange = function preventStateChange() {
    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
      if(self.prevent_state_change) {
        event.preventDefault();
        self.potential_to_state = toState;
        self.potential_to_params = toParams;
        self.potential_from_state = fromState;
        self.potential_from_params = fromParams;
      }
    });
  }

  /*==========================================================================================
    Prevent a base url change, so we can confirm that current changes to selected cell will
    be discarded
  ==========================================================================================*/
  this.preventPageChange = function preventPageChange(e) {
    e.preventDefault();
    this.potential_to_path = e.target.href;
  }

  /*==========================================================================================
    Remove spreadsheet instances and then navigate to new state / route
  ==========================================================================================*/
  this.discardUnSavedCellChanges = function discardUnSavedCellChanges() {
    this.spread_sheet_helper_context.removeAllInstanceVariables();

    if(this.is_ui_router_link) {
      this.makeStateChange();
    }else {
      this.makePageChange();
    }
  }

  /*==========================================================================================
    Move to the new state; state data is stored in preventStateChange
  ==========================================================================================*/
  this.makeStateChange = function makeStateChange() {
    $state.transitionTo(this.potential_to_state, this.potential_to_params, { notify: false, reload: true }).then(function(state) {
      $rootScope.$broadcast('$stateChangeSuccess',
                            self.potential_to_state,
                            self.potential_to_params,
                            self.potential_from_state,
                            self.potential_from_params);

      self.prevent_state_change = false;
    },function(error) {
      console.warn(error);
    });
  }

  /*==========================================================================================
    Move to the new page; page href is stored in preventPageChange
  ==========================================================================================*/
  this.makePageChange = function makePageChange() {
    window.location.href = this.potential_to_path;
  }

  /*==========================================================================================
    When user answers no to the confirmation focus the cell's input
  ==========================================================================================*/
  this.refocusCellInEditMode = function refocusCellInEditMode() {
    var selector = '#' + this.spread_sheet_helper_context.CELL.input_type +
                   this.spread_sheet_helper_context.CELL.index_ref.replace(/[^\w\s]/gi, ''),
        element = this.directive_scope.element[0].querySelector(selector),
        element_found = element !== null;

    if(element_found) {
      element.focus();
    }
  }
});
