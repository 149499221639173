/**
 * Created by sivachandran on 14/2/18.
 */

'use strict';
var jobPartsModule = angular.module('jobParts', []);

jobPartsModule.constant('prefix', window.prefixVal).config(
    ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
        function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {
            $stateProvider.state('loggedin.customer_list.view.job.parts', {
                url: Routing.generateAngularRoute('projectJobParts', true),
                templateUrl: function (params) {
                    return Routing.generate('projectJobParts', params)
                },
                data: {
                    'pageNum': 1,
                    'limit': 5
                },
                views: {
                    "customerTabPane@": {
                        templateUrl: 'template/jobs/view/parts/view.html',
                        controller: JobPartsCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.job.parts.view', {
                url: Routing.generateAngularRoute('projectJobPartsView', true),
                templateUrl: function (params) {
                    return Routing.generate('projectJobPartsView', params)
                },
                data: {
                    'pageNum': 1,
                    'limit': 20
                },
                views: {
                    "JobTabPane@loggedin.customer_list.view.job.parts": {
                        templateUrl: 'template/jobs/view/parts/view_parts.html',
                        controller: JobPartsViewCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            }).
            state('loggedin.customer_list.view.job.parts.view_parts', {
                url: Routing.generateAngularRoute('jobPartsView', true),
                templateUrl: function (params) {
                    return Routing.generate('jobPartsView', params)
                },
                data: {
                    'pageNum': 1,
                    'limit': 20
                },
                views: {
                    "JobTabPane@loggedin.customer_list.view.job.parts": {
                        templateUrl: 'template/jobs/view/parts/job_view_parts.html',
                        controller: JobViewPartsCtrl,
                        resolve: BreadCrumbsResolver
                    }
                }
            })
        }]);