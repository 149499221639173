commusoftCommon.factory('StockData',['$http',function($http){
    var getStockLocationAvailable = function(LocationId){
        return $http.get(Routing.generate("stock_location_details", { id: LocationId})).then(function(data) {
                return data;
            }
        );
    }
    var getStockLocations = function(){
        return $http.get(Routing.generate("get_all_stock_locations")).then(function(data) {
                return data;
            }
        );
    }
    var getDiaryEngineerStockLocationsAndWarehouses = function getDiaryEngineerStockLocationsAndWarehouses(customer_id,job_id){
        return $http.get(Routing.generate("list_diary_engineer_stock_location", { type: 'customer',id: customer_id,jobId: job_id})).then(function(data){
            return data;
        });
    }
    return {
        getStockLocationAvailable:getStockLocationAvailable,
        getStockLocations:getStockLocations,
        getDiaryEngineerStockLocationsAndWarehouses:getDiaryEngineerStockLocationsAndWarehouses
    }
}]);