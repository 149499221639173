commusoftCommon.service('jobLineItemDecorator', function() {
    this.decorateLineItem = function(breakdown, line_item, spreadsheet_category) {
        if (breakdown === 'full_breakdown') {
            return {
                "id" : null,
                "pos" : null,
                "row_valid" : true,
                "row" : [
                    {
                        "associated_rows" : [
                            [
                                {
                                    "value" : line_item.description,
                                    "input_type" : "auto_complete",
                                    "auto_complete_options_reference" : "parts",
                                    "placeholder" : "Choose Part",
                                    "state" : null,
                                    "valid" : true
                                },
                                {
                                    "value" :"No",
                                    "input_type" : "auto_complete",
                                    "auto_complete_options_reference" : "CIS",
                                    "used_in_calculation" : true,
                                    "state" : null,
                                    "valid" : true
                                },
                                {
                                    "value" : line_item.quantity,
                                    "input_type" : "time",
                                    "used_in_calculation" : true,
                                    "state" : null,
                                    "valid" : true
                                },
                                {
                                    "value" : line_item.unit_price,
                                    "input_type" : "time",
                                    "used_in_calculation" : true,
                                    "state" : null,
                                    "valid" : true
                                },
                                {
                                    "value" : "20",
                                    "input_type" : "auto_complete",
                                    "auto_complete_options_reference" : "vat_rates",
                                    "used_in_calculation" : true,
                                    "state" : null,
                                    "valid" : true
                                },
                                {
                                    "value" :"232 - Appliances",
                                    "input_type" : "auto_complete",
                                    "auto_complete_options_reference" : "nominal_codes",
                                    "state" : null,
                                    "valid" : true
                                },
                                {
                                    "value" : "30",
                                    "input_type" : "auto_complete",
                                    "auto_complete_options_reference" : "cis_rates",
                                    "used_in_calculation" : true,
                                    "state" : "not_selectable",
                                    "hidden" : true,
                                    "valid" : true
                                },
                                {
                                    "value" : 0,
                                    "calculation" : "multiply_2_by_3",
                                    "state" : "not_selectable",
                                    "valid" : true
                                },
                                {
                                    "value" : 0,
                                    "calculation" : "tax_2_by_3_by_4",
                                    "state" : "not_selectable",
                                    "hidden" : true,
                                    "valid" : true
                                },
                                {
                                    "value" : 0,
                                    "calculation" : "tax_7_by_6",
                                    "calculation_condition_cell" : "1",
                                    "calculation_condition_value" : "Yes",
                                    "state" : "not_selectable",
                                    "hidden" : true,
                                    "valid" : true
                                }
                            ]
                        ]
                    }
                ],
                "meta_data": {
                    "prevent_dragging" : false,
                    "part_status" : null,
                    "part_locations" : [],
                    "row_selected" : false
                }
            }
        } else {
            if (spreadsheet_category === 'labour') {
                return {
                    "id" : null,
                    "pos" : null,
                    "row_valid" : true,
                    "row" : [
                        {
                            "associated_rows" : [
                                [
                                    {
                                        "value" : line_item.description,
                                        "input_type" : "text",
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" :"No",
                                        "input_type" : "auto_complete",
                                        "auto_complete_options_reference" : "CIS",
                                        "used_in_calculation" : true,
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" : line_item.quantity,
                                        "input_type" : "time",
                                        "used_in_calculation" : true,
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" : line_item.unit_price,
                                        "input_type" : "time",
                                        "used_in_calculation" : true,
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" : "20",
                                        "input_type" : "auto_complete",
                                        "auto_complete_options_reference" : "vat_rates",
                                        "used_in_calculation" : true,
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" :"232 - Appliances",
                                        "input_type" : "auto_complete",
                                        "auto_complete_options_reference" : "nominal_codes",
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" : "30",
                                        "input_type" : "auto_complete",
                                        "auto_complete_options_reference" : "cis_rates",
                                        "used_in_calculation" : true,
                                        "state" : "not_selectable",
                                        "hidden" : true,
                                        "valid" : true
                                    },
                                    {
                                        "value" : 0,
                                        "calculation" : "multiply_2_by_3",
                                        "state" : "not_selectable",
                                        "valid" : true
                                    },
                                    {
                                        "value" : 0,
                                        "calculation" : "tax_2_by_3_by_4",
                                        "state" : "not_selectable",
                                        "hidden" : true,
                                        "valid" : true
                                    },
                                    {
                                        "value" : 0,
                                        "calculation" : "tax_7_by_6",
                                        "calculation_condition_cell" : "1",
                                        "calculation_condition_value" : "Yes",
                                        "state" : "not_selectable",
                                        "hidden" : true,
                                        "valid" : true
                                    }
                                ]
                            ]
                        }
                    ]
                }
            } else {
                return {
                    "id" : null,
                    "pos" : null,
                    "row_valid" : true,
                    "row" : [
                        {
                            "associated_rows" : [
                                [
                                    {
                                        "value" : line_item.description,
                                        "input_type" : "auto_complete",
                                        "auto_complete_options_reference" : "parts",
                                        "placeholder" : "Choose Part",
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" : line_item.quantity,
                                        "input_type" : "time",
                                        "used_in_calculation" : true,
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" : line_item.unit_price,
                                        "input_type" : "time",
                                        "used_in_calculation" : true,
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" : "20",
                                        "input_type" : "auto_complete",
                                        "auto_complete_options_reference" : "vat_rates",
                                        "used_in_calculation" : true,
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" :"232 - Appliances",
                                        "input_type" : "auto_complete",
                                        "auto_complete_options_reference" : "nominal_codes",
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" : 0,
                                        "calculation" : "multiply_1_by_2",
                                        "state" : "not_selectable",
                                        "valid" : true
                                    },
                                    {
                                        "value" : 0,
                                        "calculation" : "tax_1_by_2_by_3",
                                        "state" : "not_selectable",
                                        "hidden" : true,
                                        "valid" : true
                                    }
                                ]
                            ]
                        }
                    ]
                }
            }
        }
    }

    /*====================================================
     Getters
     ====================================================*/

    this.getNewPricingCategories = function getNewPricingCategories() {
        return new_pricing_categories;
    }

    this.getCustomisableSpreadsheetColumns = function getCustomisableSpreadsheetColumns() {
        return customisable_spreadsheet_columns;
    }
});