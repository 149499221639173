commusoftCommon.factory('ProvinceService',['$http',function($http){
    var getProvinces = function(){
        return $http.get(Routing.generate("get_state_provinces")).then(function(data) {
            return data.data.countyLists;
            }
        );
    }
    return {
        getProvinces:getProvinces
    }
}]);