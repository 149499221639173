/*==========================================================================================
	Adds a inner shadow to the element when it scrolls.
==========================================================================================*/

csmodule.directive('shadowOnScroll', [ function() {
	var handleShadowOnScroll = function handleShadowOnScroll(scope, element, attrs) {
    /*==========================================================================================
      Initial Variables
    ==========================================================================================*/
		scope.elm = element[0];

    /*==========================================================================================
      Set conditional options to default values
    ==========================================================================================*/
    scope.needs_top_shadow = true;
    scope.needs_left_shadow = false;
    scope.needs_right_shadow = false;
    scope.needs_bottom_shadow = false;

    /*==========================================================================================
      Re set conditional options if supported in instance
    ==========================================================================================*/
    if(attrs.needsBottomShadow) {
      scope.needs_bottom_shadow = true;
    }

    /*==========================================================================================
      Listen for scrolling and delegate what should happen
    ==========================================================================================*/
    element.bind('scroll', function() {
      var needs_top_shadow     = scope.needs_top_shadow &&
                                 !scope.has_top_shadow &&
                                 scope.elm.scrollTop > 0,
          remove_top_shadow    = scope.elm.scrollTop == 0 &&
                                 scope.has_top_shadow,
          needs_bottom_shadow  = scope.needs_bottom_shadow &&
                                 !scope.has_bottom_shadow
                                 scope.elm.scrollHeight - scope.elm.scrollTop > scope.elm.clientHeight,
          remove_bottom_shadow = scope.needs_bottom_shadow &&
                                 scope.has_bottom_shadow &&
                                 scope.elm.scrollHeight - scope.elm.scrollTop === scope.elm.clientHeight;

      if(needs_top_shadow) {
        scope.insertShadowAt('top');
      }
      if(remove_top_shadow) {
        scope.removeShadowAt('top');
      }
      if(needs_bottom_shadow) {
        scope.insertShadowAt('bottom');
      }
      if(remove_bottom_shadow) {
        scope.removeShadowAt('bottom');
      }
    });

    /*==========================================================================================
      Insert a shadow element into the directive element
    ==========================================================================================*/
    scope.insertShadowAt = function insertShadowAt(pos) {
      var pos_styles = scope.calcShadowPositioning(pos),
          shadow_elm = '<span class="scrollable-shadow ' + pos + '-scrollable-shadow" style="' + pos_styles + '"' + '></span>';

      angular.element(scope.elm).append(shadow_elm);
      scope['has_' + pos + '_shadow'] = true;
    }

    /*==========================================================================================
      Remove a shadow element from the directive element
    ==========================================================================================*/
    scope.removeShadowAt = function removeShadowAt(pos) {
      var shadow_elm_class = '.' + pos + '-scrollable-shadow';
      angular.element(scope.elm)[0].querySelector(shadow_elm_class).remove();
      scope['has_' + pos + '_shadow'] = false;
    }

    /*==========================================================================================
      As the shadows have a fixed position calculate and return their width and top values
    ==========================================================================================*/
    scope.calcShadowPositioning = function calcShadowPositioning(pos) {
      var shadow_height = 5, // correlates to @scrollableShadowHeight LESS var
          position_styles = '',
          rect = scope.elm.getBoundingClientRect(),
          width_style = 'width: ' + rect.width + 'px;',
          top_pos_style = 'top: ' + rect.top + 'px;',
          bottom_pos_style = 'top: ' + ((rect.top + rect.height) - shadow_height) + 'px;';

      position_styles = width_style;

      if(pos === 'top') {
        position_styles += top_pos_style;
      }else if(pos === 'bottom') {
        position_styles += bottom_pos_style;
      }

      return position_styles;
    }
	}

  return {
    scope: {},
    link: handleShadowOnScroll
  }
}]);