commusoftCommon.filter('localSpeed', function ($rootScope) {
    /*
        Converts speed units into local units (knots,m/s,km/h,mph)

        @param number value
        @param string sourceUnit  --valid units={knots|m/s|km/h|mph}
        @param boolean showUnit
        @return float
    */
    var unit = $rootScope.localStandards.speed;

    return function (value, sourceUnit, showUnit) {
        if(!sourceUnit) sourceUnit = 'kn';
        value = parseFloat(value);

        var meterPerSec = 0, converted = 0;
        switch (sourceUnit) {
            case "kn": meterPerSec = value * (1.852 * 1000 / 3600); break; // knots to meter/sec
            case "mph": meterPerSec = value * (1.60934 * 1000 / 3600); break; // miles/hour to meter/sec
            case "km/h": meterPerSec = value * (1000 / 3600); break; // kilometer/hour to meter/sec
            default: meterPerSec = value; break;
        }

        switch (unit) {
            case "kn": converted = meterPerSec / (1.852 * 1000 / 3600); break; // meter/sec to knots
            case "mph": converted = meterPerSec / (1.60934 * 1000 / 3600); break; // meter/sec to miles/hour
            case "km/h": converted = meterPerSec / (1000 / 3600); break; // meter/sec to kilometer/hour
            default: converted = meterPerSec; break;
        }

        return showUnit ? converted.toFixed(2) + ' ' + unit : converted.toFixed(2);
    }
});