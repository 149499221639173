// 'use strict';

/* Services */


// Demonstrate how to register services
// In this case it is a simple value service.
angular.module('commusoft.services', []).
  value('version', '0.1')
    .service('formService', function($http) {
        return {
            mUrl: '',
            partialPageSubmit: function(url, formElem) {
                return $http.post(url, formElem.serialize()).then(function(result) {
                   return result.data;
                }).catch(function (err) {
                    return err;
                });
            }
        }
    })
    .service('dateRange', function() {
       var  filterOptions = [
            {
                string: 'Today',
                withCalendar: false,
                view: null,
                startdate: moment().format('YYYY-MM-DD'),
                enddate: moment().format('YYYY-MM-DD')
            },
            {
                string: 'This Week',
                withCalendar: false,
                view: null,
                startdate: moment().weekday(0).format('YYYY-MM-DD'),
                enddate: moment().weekday(6).format('YYYY-MM-DD')
            },
            {
                string: 'Last Week',
                withCalendar: false,
                view: null,
                startdate: moment().weekday(-7).format('YYYY-MM-DD'),
                enddate: moment().weekday(-1).format('YYYY-MM-DD')
            },
            {
                string: 'This Month',
                withCalendar: false,
                view: null,
                startdate: moment().startOf('month').format('YYYY-MM-DD'),
                enddate: moment().endOf('month').format('YYYY-MM-DD')
            },
            {
                string: 'Last Month',
                withCalendar: false,
                view: null,
                startdate: moment(moment().months((moment().months()-1)).format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD'),
                enddate: moment(moment().months((moment().months()-1)).format('YYYY-MM-DD')).endOf("month").format('YYYY-MM-DD')
            },
            {
                string: 'Next Month',
                withCalendar: false,
                view: null,
                startdate: moment(moment().months((moment().months() + 1)).format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD'),
                enddate: moment(moment().months((moment().months() + 1)).format('YYYY-MM-DD')).endOf("month").format('YYYY-MM-DD')
            },
            {
                string: 'Last 3 Months',
                withCalendar: false,
                view: null,
                startdate: moment(moment().months((moment().months()-3)).format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD'),
                enddate: moment().endOf('month').format('YYYY-MM-DD')
            },
            {
                string: 'Date Range',
                withCalendar: true,
                view: 'default',
                startdate: moment().format('YYYY-MM-DD'),
                enddate: moment().format('YYYY-MM-DD')
            }
        ];

        this.getFilterOptions = function() {
            return filterOptions;
        };
    })
    .factory('reportFilterSettings', function() {
       var filterSettings = {};

       return {
      } 
    })
    .service('navSidebar', function () {
        var subnavItems = [
            { id: 'accounting_interface',
                firstTier: 'Accounting.Interface',
                secondTier: [
                    { id: 'accounting_package_log',
                        item: 'Accounting.Package.Log',
                        state: 'accounting_package_log',
                        description: 'Accounting.Package.Log.Stores',
                        method: 'fetchAIAuditAction'
                    },
                    { id: 'accounting_package_failure_log',
                        item: 'Accounting.Package.Failure.Log',
                        state: 'accounting_package_failure_log',
                        description: 'Accounting.Package.Failure.Log.Stores'
                    }
                ]
            },
            { id: 'transactions',
                firstTier: 'Transactions',
                secondTier: [
                    { id: 'text_messages',
                        item: 'Text.Messages',
                        state: 'text_messages',
                        description: 'Text.Messages.Log'
                    },
                    { id: 'postcode_lookups',
                        item: 'Postcode.Lookups',
                        state: 'postcode_lookups',
                        description: 'Postcode.Lookups.Log'
                    },
                    { id: 'print_and_post',
                        item: 'Print.And.Post',
                        state: 'print_and_post',
                        description: 'Print.And.Post.Log'
                    },
                    { id: 'all_edits',
                        item: 'All.Edits',
                        state: 'all_edits',
                        description: 'List.Of.Every.Edit'
                    },
                    { id: 'all_deletes',
                        item: 'All.Deletes',
                        state: 'all_deletes',
                        description: 'List.Of.Every.Deleted.Records'
                    },
                    { id: 'invoice_additions',
                        item: 'Added.Records',
                        state: 'invoice_additions',
                        description: 'List.Of.Every.Records'
                    }
                ]
            },
            { id: 'customer_and_tenant',
                firstTier: 'Customer.And.Tenant',
                secondTier: [
                    { id: 'customer_transfer',
                        item: 'Transfer',
                        state: 'customer_transfer',
                        description: 'Transfer.Log'
                    },
                    { id: 'customer_merge',
                        item: 'Merge',
                        state: 'customer_merge',
                        description: 'Merge.Log'
                    },
                    { id: 'customer_convert',
                        item: 'Convert',
                        state: 'customer_convert',
                        description: 'Convert.Log'
                    },
                    { id: 'customer_login_audit',
                        item: 'Login.Audit',
                        state: 'customer_login_audit',
                        description: 'Login.Audit.Shows'
                    }
                ]
            },
            { id: 'estimates',
                firstTier: 'Estimates',
                secondTier: [
                    { id: 'move_back_stage',
                        item: 'Moved.Back.a.Stage',
                        state: 'move_back_stage',
                        description: 'Shows.List.Of.Estimates.'
                    },
                    { id: 'move_to_different_property',
                        item: 'Moved.to.a.Different.Property',
                        state: 'move_to_different_property',
                        description: 'Estimate.Different.Property.log'
                    }
                ]
            },
            { id: 'jobs',
                firstTier: 'Jobs',
                secondTier: [
                    { id: 'jobs_moved_property',
                        item: 'Moved.to.a.Different.Property',
                        state: 'jobs_moved_property',
                        description: 'Job.Different.Property.log'
                    }
                ]
            },
            { id: 'invoices',
                firstTier: 'invoices',
                secondTier: [
                    { id: 'invoices_moved_property',
                        item: 'Moved.to.a.Different.Property',
                        state: 'invoices_moved_property',
                        description: 'Invoice.Different.Property.log'
                    }
                ]
            },
            { id: 'login_access',
                firstTier: 'Login.Access',
                secondTier: [
                    { id: 'login_audit',
                        item: 'Login.Audit',
                        state: 'login_audit',
                        description: 'Login.Audit.Description'
                    },
                    { id: 'mobile_access',
                        item: 'Mobile.Access',
                        state: 'mobile_access',
                        description: 'Mobile.Access.Description'
                    }
                ]
            }
        ];

        this.getsubnavItems = function () {
            return subnavItems;
        };
    })
    .service('underscore', function() {
        var underscore = angular.module('underscore', []);
        underscore.factory('_', function() {
            return window._;
        });
    })
    .service('tableCollection', function() {

        /*====================================================
         Tabular data
         ====================================================*/
        // TODO: i18n this
        this.jobTodosHeader = ['Type', 'Description', 'Certificate', 'Part', 'Appliance', 'Estimated Hours', 'Quantity', 'Diary Event', 'Engineer Name'];
        this.jobTodosClosedHeader = ['Status', 'Type', 'Description', 'Certificate', 'Part', 'Appliance', 'Estimated Hours', 'Quantity', 'Diary Event', 'Engineer Name'];

        this.tableHeaders = {
            'viewAllServiceReminder': ['Service date', 'Reminder date', 'Service type', 'Number of days until service due', 'Processing type', 'Method', 'Last communication date', 'Last communication detail', 'Customer name', 'Customer contact telephone', 'Customer contact email', 'Property name', 'Property contact telephone', 'Property contact email', 'Property address line 1', 'Property address line 2', 'Property address line 3', 'Property town', 'Property county', 'Property postcode'],
            'serviceReminderDueDays': ['Service date', 'Service type', 'Number of days until service due', 'Processing type', 'Method', 'Last communication date', 'Last communication detail', 'Customer name', 'Service contact', 'Customer contact telephone', 'Customer contact email', 'Property name', 'Property contact telephone', 'Property contact email', 'Property address line 1', 'Property address line 2', 'Property address line 3', 'Property town', 'Property county', 'Property postcode'],
            'service_reminders_phone_call_list': ['Customer_name', 'Address_line_1', 'Address_line_2', 'Address_line_3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Service_type', 'Service_date', 'Number_of_days_until_service_due', 'Service_reminder_contact_name', 'Contact_telephone', 'Contact_mobile', 'Number_of_phone_calls_made'],
            'appliances': ['Appliance group', 'Appliance type', 'Fuel type', 'Make', 'Model', 'Location', 'Reference', 'Gc number', 'Serial number', 'In warranty', 'Warranty start date', 'Warranty end date', 'Warranty duration'],
            'job_certificates': ['Date', 'Type', 'Number', 'Engineer', 'Sent to customer', 'Sent to customer on', 'Sent to customer method'],
            'job_estimate_costs': ['Description', 'Supplier', 'Category', 'Cost', 'Quantity', 'Total (exc VAT)', 'Vat', 'Total'],
            'serviceRemindersStatement': ['Service status', 'Service due date','Communication method', 'Contact name', 'Contact telephone', 'Contact email', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town', 'Work address county', 'Work address postcode', 'Service type', 'Job booked', 'Job reported to us', 'Job no', 'Job description', 'Last diary event', 'Last engineer name', 'Last diary status', 'Number of diary events', 'Number of no access events', 'Number of aborted events', 'Number of rejected events', 'Completion date', 'Completed description', 'Completed notes', 'On hold status', 'On hold description'],
            'serviceReminderStatementOverDue': ['Number of days overdue', 'Contact name','Communication method', 'Contact telephone', 'Contact email', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town', 'Work address county', 'Work address postcode', 'Job date', 'Job no', 'Job description', 'Diary date', 'Engineer name', 'Service due date', 'Last diary status', 'Number of diary events', 'Number of no access events', 'Number of aborted events', 'Number of rejected events', 'On hold status', 'On hold description'],
            'serviceReminderStatementOverNot': ['Number of days overdue', 'Service due date','Communication method', 'Contact name', 'Contact telephone', 'Contact email', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town', 'Work address county', 'Work address postcode', 'Job date', 'Job no', 'Job description', 'On hold status', 'On hold description'],
            'serviceReminderStatementDueBooked': ['Contact name', 'Contact telephone', 'Communication method','Contact email', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town', 'Work address county', 'Work address postcode', 'Job date', 'Job no', 'Job description', 'Diary date', 'Engineer name', 'Service due date', 'Last diary status', 'Number of diary events', 'Number of no access events', 'Number of aborted events', 'Number of rejected events', 'On hold status', 'On hold description'],
            'serviceReminderStatementDueNotBooked': ['Service due date', 'Contact name','Communication method', 'Contact telephone', 'Contact email', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town', 'Work address county', 'Work address postcode', 'Job date', 'Job no', 'Job description', 'On hold status', 'On hold description'],
            'serviceRemindersStatementCompleted': ['Contact name', 'Contact telephone','Communication method', 'Contact email', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town', 'Work address county', 'Work address postcode', 'Job date', 'Job no', 'Job description', 'Diary date', 'Engineer name', 'Service due date', 'Complete date', 'Completed description', 'Completed notes'],
            'work_reminders_view_pending': ['Due_date', 'Name', 'Address_line_1', 'Address_line_2', 'Address_line_3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Email', 'Service_type'],
            'work_reminders_view_authorised': ['Due_date', 'Name', 'Address_line_1', 'Address_line_2', 'Address_line_3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Email', 'Service_Type', 'Job Booked', 'Job Reported To Us', 'Job No', 'Job Description', 'Last Diary Event', 'Last Engineer Name', 'Last Diary Status', 'Number of Diary Events', 'Number of No Access Events', 'Number of Aborted Events', 'Number of Rejected Events'],
            'work_reminders_view_waiting_customer': ['Due_date', 'Name', 'Address_line_1', 'Address_line_2', 'Address_line_3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Email', 'Service_Type', 'Job Booked', 'Job Reported To Us', 'Job No', 'Job Description'],
            'notifications': ['Title', 'Date', 'Time', 'Status'],
            'stock_current': ['Available stock', 'Total stock', 'Location'],
            'supplier_returns': ['Reference ID', 'Part name','Quantity','Unit cost', 'Total (exc VAT)', 'VAT', 'Total (inc VAT)', 'Date', 'Credit received', 'Job number', 'Job date', 'Customer name', 'Customer address line 1', 'Customer address line 2', 'Customer town', 'Customer county', 'Customer postcode', 'Supplier invoice no', 'Supplier invoice date', 'Purchase order no', 'Created by', 'Created on'],
            'stock_due_in': ['Quantity', 'Supplier', 'Date ordered', 'Delivery due date', 'Price per unit', 'Purchase order no'],
            'stock_history': ['Part name', 'Quantity', 'Action', 'Value change', 'Lots added date', 'Created on time', 'Created by', 'Manufacture part number', 'Date of transaction', 'Time of transaction', 'Unit cost', 'Customer name', 'Customer address line 1', 'Customer address line 2', 'Customer address line 3', 'Customer town', 'Customer county', 'Customer postcode', 'Job no', 'Job description', 'Purchase order no', 'Supplier name'],
            'stock_location_history': ['Part name', 'Quantity', 'Action', 'Value change', 'Lots added date', 'Created on time', 'Created by', 'Manufacture part number', 'Date of transaction', 'Time of transaction', 'Unit cost', 'Customer name', 'Customer address line 1', 'Customer address line 2', 'Customer address line 3','Customer town', 'Customer county', 'Customer postcode', 'Job no', 'Job description', 'Purchase order no', 'Supplier name'],
            'stock_suppliers': ['Name', 'Highest price paid', 'Lowest price paid', 'Average price paid', 'Last price paid', 'Landline number'],
            'stock_reserve_list': ['Stock location', 'Quantity', 'Date reserved', 'Job number', 'Job date', 'Customer name', 'Address line 1', 'Address line 2', 'Address line 3', 'Town', 'County', 'Postcode'],
            'reporting_customers_customers': ['Name', 'Telephone', 'Mobile', 'Email', 'Account.No', 'Address.Line1', 'Address.Line2', 'Address.Line3', 'Town', 'County', 'Postcode', 'Building.Type', 'Marketing', 'Vacant', 'Status', 'Date.Created', 'Created.By', 'Customer.Type','Automatic.Sales.Care.turned.on'],
            'reporting_customers_contacts': ['Contact.Name', 'Customer.Name', 'Branch Name', 'Property.Address.Line1', 'Property.Address.Line2', 'Property.Address.Line3', 'Town', 'County', 'Postcode', 'Contact.Position', 'Main.Telephone', 'Main.Mobile', 'Email', 'Date.Created', 'Created.By'],
            'reporting_service_reminders_service_reminders': ['Service Type', 'Service Due On', 'Communication Type', 'Communications Sent', 'Phone Calls Made', 'Follow-up Communication Sent', 'Customer Name', 'Property Name', 'Property Address Line 1', 'Property Address Line 2', 'property Address Line 3', 'Property Town', 'Property County', 'Property Postcode', 'Contact Telephone', 'Contact Email', 'Job Created', 'Job Date', 'Booked into Diary', 'First Engineer Name', 'Job Invoiced/FOC/Aborted'],
            'reporting_customers_work_addresses': ['Customer.Name', 'Branch.Name', 'Landlord.Name', 'Work Address Name','Work.Address.Line1', 'Work.Address.Line2', 'Work.Address.Line3', 'Work.Address.Town', 'Work.Address.County', 'Work.Address.Postcode', 'Work.Address.Telephone', 'Work.Address.Mobile', 'Work.Address.Email', 'Building.Type', 'Vacant', 'Status', 'Date.Created', 'Created.By'],
            'reporting_customers_branches': ['Customer.Name','Branch Name', 'Branch.Address.Line1', 'Branch.Address.Line2', 'Branch.Address.Line3', 'Branch.Town', 'Branch.County', 'Branch.Postcode'],
            'reporting_customers_landlords': ['Landlord Name', 'Address.Line1', 'Address.Line2', 'Address.Line3', 'Address.Town', 'Address.County', 'Address.Postcode', 'Number.of.Linked.Properties'],
            'reporting_customers_notes': ['Customer.Name', 'Work.Address.Name', 'Address.Line1', 'Address.Line2', 'Address.Line3', 'Town', 'County', 'Postcode', 'Telephone Number', 'Mobile Number', 'Email Address', 'Note.Created.On', 'Note.Created.By', 'Note.Title', 'Note', 'Important', 'Do.not.work.with', 'Send.to.mobile'],
            'reporting_customers_reminders': ['Reminder.Date', 'Notes', 'Created.On', 'Created.By', 'User', 'Customer.Name', 'Property.Name', 'Property.Address.Line1', 'Property.Address.Line2', 'Property.Address.Line3', 'Property.Town', 'Property.County', 'Property.Postcode', 'Property.Telephone', 'Property.Mobile', 'Property.Email'],
            'reporting_customers_phone_calls': ['Customer.Name', 'Address.Line.1', 'Address.Line.2', 'Address.Line.3', 'Town', 'County', 'Postcode', 'Telephone Number', 'Email Address', 'Contact.Name','Number','Call.Type','Note', 'Created.By', 'Date.Created'],
            'reporting_suppliers_suppliers': ['Name', 'Address.Line1', 'Address.Line2', 'Address.Line3', 'Town', 'County', 'Postcode', 'Telephone', 'Email', 'VAT.Registration', 'Credit.Terms', 'Third.Party.Accounting.Ref', 'Default.Nominal.Code', 'Number.of.Contacts', 'Number.of.Notes', 'Created.On', 'Created.By'],
            'reporting_suppliers_branches': ['Name', 'Address.Line1', 'Address.Line2', 'Address.Line3', 'Town', 'County', 'Postcode'],
            'reporting_suppliers_notes': ['Supplier.Name', 'Address.Line1', 'Address.Line2', 'Address.Line3', 'Town', 'County', 'Postcode', 'Telephone', 'Email', 'Note.Created.On', 'Note.Created.By', 'Note.Title', 'Note', 'Important', 'Do.not.work.with', 'Send.to.mobile'],
            'reporting_estimates_notes': ['Customer Name', 'Address Line 1', 'Address Line 2', 'Address Line 3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Email', 'Estimate No', 'Estimate Date', 'Estimate Description', 'Status', 'Note Created On', 'Note Created By', 'Note Title', 'Note', 'Important', 'Do Not Work With', 'Send to mobile'],
            'reporting_suppliers_contacts': ['Supplier.Name', 'Address Line 1', 'Address Line 2', 'Address Line 3', 'Town', 'County', 'Postcode', 'Contact.Name', 'Contact.Position', 'Contact Telephone', 'Contact Mobile', 'Contact Email', 'Date.Created', 'Created.By'],
            'reporting_suppliers_attached_files': ['Supplier.Name', 'Address.Line.1', 'Address.Line.2', 'Address.Line.3', 'Town', 'County', 'Postcode', 'Main.Telephone', 'Email', 'File.Created.On', 'Created.By','File name', 'File type', 'File size (MB)'],
            'reporting_suppliers_phone_calls': ['Supplier.Name', 'Address Line 1', 'Address Line 2', 'Address Line 3', 'Town', 'County', 'Postcode', 'Main.Telephone', 'Email Address', 'Date.Created', 'Created.By','Call Type', 'Contact name','Number','Note'],
            'reporting_customers_appliance_details':['Customer Name', 'Address Line 1', 'Address Line 2', 'Address Line 3', 'Town', 'County', 'Postcode', 'Landline', 'Mobile', 'Email', 'Appliance Group', 'Appliance Type', 'Make', 'Model', 'Fuel', 'Location', 'Flue', 'Serial Number', 'Reference', 'Ventilation', 'Status', 'Used For', 'Did you install', 'Have you notified the manufacturer', 'Installed on', 'Installed By', 'Commissioned On', 'Warranty Expiry', 'GC Number', 'Gas Safe Notification No', 'Fuel Type', 'Air Setting', 'Oil Pump Pressure Setting', 'Draught', 'Co2', 'Smoke No', 'Flue Gas Temperature', 'Fire Valve Type', 'Fire Valve Temperature', 'Oil Filter Type', 'Oil Filter Size', 'First Burner Type', 'First Burner Make', 'First Burner Model', 'First Burner Serial No', 'First Burner Code', 'First Nozzle Type', 'First Nozzle Size', 'First Nozzle Angle', 'First Nozzle Spray Pattern', 'Total No Burners', 'Total No Nozzles'],
            'reporting_customers_attached_files': ['Customer.Name', 'Address.Line.1', 'Address.Line.2', 'Address.Line.3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Email', 'File.Created.On', 'Created.By','File name', 'File type', 'File size (MB)'],
            'user_attached_files': ['Date added', 'File name', 'Size (MB)', 'Type'],
            'reporting_service_reminders_properties_without_services': ['Customer.Name', 'Work.Address.Name', 'Address.Line1', 'Address.Line2' , 'Address.Line3','Town' , 'County' , 'Postcode','Telephone Number', 'Email Address' ],
            'reporting_purchase_orders_purchase_orders': ['Date', 'Number', 'Supplier Name', 'Supplier Address Line 1', 'Supplier Address Line 2' ,'Supplier Address Line 3' , 'Supplier Town', 'Supplier County', 'Supplier Postcode' , 'Supplier Telephone' ,'Supplier Email', 'Customer Name', 'Customer Landline' , 'Customer Email', 'Job Address Name','Job Address Line 1' , 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County' , 'Job Address Postcode', 'Job Address Telephone', 'Job Number' , 'Job Description', 'Job Purchase Order Number', 'Created On', 'Created By', 'Purchase Order Sent', 'Total exc VAT', 'VAT', 'Total', 'Status'],
            'reporting_stock_control_stock_items' : ['Part Name', 'Industry', 'Category', 'Subcategory', 'Type', 'Part Number', 'Price', 'Mark up', 'VAT Rate', 'Minimum Stock Level', 'Total Number of Items', 'Number of Unallocated Items', 'Number of Allocated Items', 'Stock Value'],
            'job_parts': ['Created by', 'Created on', 'Part', 'MPN', 'Quantity', 'Status', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT'],
            'job_parts_requested': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Quantity', 'Status'],
            'job_parts_on_order': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Quantity', 'Status'],
            'job_parts_available': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Quantity', 'Status'],
            'job_parts_available_with_stock': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Quantity', 'Quantity Delivered', 'Status'],
            'job_parts_scheduled': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Quantity', 'Status', 'Engineer name', 'Diary time', 'Picked up status', 'Picked up at', 'Quantity scheduled'],
            'job_parts_progress': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Quantity', 'Quantity Installed', 'Quantity Left to Install', 'Status'],
            'job_parts_installed': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Quantity', 'Status'],
            'job_parts_cancelled': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Quantity', 'Status'],
            'job_parts_returned': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Quantity', 'Status'],
            'job_parts_returned_to_stock': ['Requested by', 'Requested on', 'MPN', 'Unit cost price', 'Total cost price', 'Unit sales price', 'Total sales price', 'Unit profit', 'Total profit', 'Mark up', 'VAT', 'Part', 'Quantity', 'Status'],
            'reporting_users_user_list': ['Name', 'Date Created', 'Created By', 'Address Line 1', 'Address Line 2', 'Address Line 3', 'Town', 'County', 'Postcode', 'Telephone', 'Mobile', 'Email', 'Access Level', 'Username','Status', 'User Group', 'Associated Group', 'Appear on Diary', 'Colour on Diary', 'Employment Type', 'Date Joined', 'Cost rate', 'Sale rate', 'NI Number', 'Gas Safe card number','Branch', 'Last login date'],
            'job_assigned_todos': this.jobTodosHeader,
            'job_completed_todos_all': this.jobTodosClosedHeader,
            'job_completed_todos_not_done': this.jobTodosClosedHeader,
            'job_completed_todos_done': this.jobTodosClosedHeader,
            'customer_account_credit' : ['Date', 'Method', 'Reference', 'Amount', 'Amount allocated', 'Amount paid back', 'Balance'],
            'invoice_address_account_credit' : ['Date', 'Method', 'Reference', 'Amount', 'Amount allocated', 'Amount paid back', 'Balance'],
            'reporting_expenses_supplier_invoices': ['Date', 'Number', 'Supplier Name', 'Supplier Address Line 1', 'Supplier Address Line 2' ,'Supplier Address Line 3' , 'Supplier Town', 'Supplier County', 'Supplier Postcode' , 'Supplier Telephone' ,'Supplier Email', 'Customer Name', 'Customer Landline' , 'Customer Email', 'Job Address Name','Job Address Line 1' , 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County' , 'Job Address Postcode', 'Job Address Telephone', 'Job Number' , 'Job Description', 'Job purchase order number', 'Created On', 'Created By', 'Purchase Order Number', 'Total exc VAT', 'VAT', 'Total', 'Payment Date', 'Total Paid', 'Total Credited', 'Remainder To Pay'],
            'reporting_expenses_payments': ['Date', 'Supplier Name', 'Supplier Address Line 1', 'Supplier Address Line 2' ,'Supplier Address Line 3' , 'Supplier Town', 'Supplier County', 'Supplier Postcode' , 'Supplier Telephone' ,'Supplier Email', 'Created On', 'Created By', 'Method', 'Nominal Code', 'Reference', 'Total Paid'],
            'reporting_estimates_estimates' : ['Estimate Date', 'Estimate Number', 'Contact Name', 'Contact Telephone','Contact Mobile', 'Contact Email', 'Estimate Description', 'Estimate Status', 'Number of Planned Todos', 'Total Estimated Labour Cost', 'Total Estimated Labour Price', 'Total Estimated Parts Cost', 'Total Estimated Parts Price', 'Estimate Total', 'Expected Profit', 'Profit Margin', 'Number of Options', 'Number of Notes', 'Number of Communications', 'Customer Name', 'Estimate Address Name', 'Estimate Address Line 1', 'Estimate Address Line 2', 'Estimate Address Line 3', 'Estimate Town', 'Estimate County', 'Estimate Postcode','Property Telephone','Property Mobile','Open office task count', 'Total office task count'],
            'reporting_estimates_accepted_vs_rejected_estimates' : ['User','Number of Accepted Estimates', 'Number of Rejected Estimates', 'Percentage of Accepted Estimates', 'Value of Accepted Estimates','Value of Rejected Estimates'],
            'reporting_estimates_rejected_reasons': ['Estimate Date', 'Estimate Number', 'Contact Name', 'Contact Telephone', 'Contact Email', 'Estimate Description', 'Estimate Status', 'Number of Planned Todos', 'Total Estimated Labour Cost', 'Total Estimated Parts Cost', 'Estimate Total', 'Expected Profit', 'Profit Margin', 'Number of Notes', 'Number of Communications', 'Customer Name', 'Estimate Address Name', 'Estimate Address Line 1', 'Estimate Address Line 2', 'Estimate Address Line 3', 'Estimate Town', 'Estimate County', 'Estimate Postcode','Rejected By','Rejected On','Rejected Reason'],
            'reporting_jobs_additional_works':['Job No','Job Description','Customer reference', 'Additional Works Description', 'Additional Works Status', 'Total Cost', 'Total Price', 'Profit', 'Customer Name', 'Job Address Name', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Town', 'Job County', 'Job Postcode', 'Contact Name', 'Contact Telephone', 'Contact Email','Created On', 'Created By'],
            'reporting_debtors_debtors': ['Invoice Address Name', 'Invoice Address Address Line 1', 'Invoice Address Address Line 2' ,'Invoice Address Address Line 3' , 'Invoice Address Town', 'Invoice Address County', 'Invoice Address Postcode' , 'Invoice Address Credit Days', 'Current' ,'1 to 30 Days', '31 to 60 Days', '61 to 90 Days', 'More than 90 Days', 'Total Monies Owed'],
            'reporting_users_time_sheets':['Name', 'Date', 'Scheduled time', 'Customer name', 'Work address name', 'Work address line 1', 'Work address line 2', 'Work address line 3', 'Work address town','Work address county','Work address postcode', 'Work address telephone', 'Work address mobile', 'Work address email', 'Job/Quotation number', 'Description', 'Engineer diary description', 'Notes for engineer', 'Access information', 'Access notes','Travel time', 'Arrive time', 'Total travel time', 'Leave time', 'Total time on site', 'Final Status'],
            'reporting_diary_events_diary_events':['Customer Name', 'Customer Landline', 'Customer Mobile', 'Customer Email', 'Customer Address Line 1', 'Type of Event', 'Job/Estimate Number', 'Job/Estimate Date', 'Contact Name', 'Job/Estimate Description', 'Customer reference', 'Quoted Amount', 'Job Address Name', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Job Address Telephone', 'Job Address Mobile', 'Job Address Email', 'Diary Created By', 'Diary Created On', 'Diary Date', 'Diary Description', 'Diary Engineers Notes', 'Access Method', 'Access Notes', 'Engineer Name', 'Time', 'Confirmation sent to Customer', 'Confirmation sent to Work Address', 'Engineer Status', 'Total Travel Time', 'Total Time on Site', 'Number of To-Dos', 'Number of To-Dos left to complete', 'Total Labour Cost', 'Total Travel Cost'],
            'reporting_jobs_jobs':['Customer Name', 'Customer Landline', 'Customer Mobile', 'Customer Email', 'Customer Address Line 1', 'Landlord Name', 'Job Number', 'Job Date', 'Contact Name', 'Job Description', 'Engineer Notes', 'Customer reference', 'Quoted Amount', 'Job Address Name', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Job Address Telephone', 'Job Address Mobile', 'Job Address Email', 'Number of Diary Events', 'Priority', 'Invoice Category', 'Additional Contact', 'Last Diary Event', 'Next Diary Event', 'Last Engineer Name On Site', 'Next Engineer Name On Site', 'Last Engineer Mobile Status', 'Is Job Complete', 'Is a Recall', 'Number of Invoices Raised', 'Created From', 'Preferred date', 'Completed Date'],
            'reporting_jobs_aborted_jobs':['Abort date/time', 'Abort created by', 'Abort reason', 'Abort note', 'Customer Name', 'Customer Landline', 'Customer Mobile', 'Customer Email', 'Customer Address Line 1', 'Landlord Name', 'Job Number', 'Job Date', 'Contact Name', 'Job Description', 'Engineer Notes', 'Customer reference', 'Quoted Amount', 'Job Address Name', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Job Address Telephone', 'Job Address Mobile', 'Job Address Email', 'Number of Diary Events', 'Priority', 'Invoice Category', 'Additional Contact', 'Last Diary Event', 'Next Diary Event', 'Last Engineer Name On Site', 'Next Engineer Name On Site', 'Last Engineer Mobile Status', 'Number of To-Dos', 'Total Labour Cost', 'Total Travel Cost', 'Total Parts Costs', 'Number of Open Reminders', 'Is a Recall',  'Number of Communications', 'Number of Notes'],
            'reporting_jobs_free_of_charge_jobs':['Free of charge date/time', 'Free of charge created by', 'Free of charge reason','Free of charge note', 'Customer Name', 'Customer Landline', 'Customer Mobile', 'Customer Email', 'Customer Address Line 1', 'Landlord Name', 'Job Number', 'Job Date', 'Contact Name', 'Job Description', 'Engineer Notes', 'Customer reference', 'Quoted Amount', 'Job Address Name', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Job Address Telephone', 'Job Address Mobile', 'Job Address Email', 'Number of Diary Events', 'Priority', 'Invoice Category', 'Additional Contact', 'Last Diary Event', 'Next Diary Event', 'Last Engineer Name On Site', 'Next Engineer Name On Site', 'Last Engineer Mobile Status', 'Number of To-Dos', 'Total Labour Cost', 'Total Travel Cost', 'Total Parts Costs', 'Number of Open Reminders', 'Is a Recall', 'Number of Communications', 'Number of Notes'],
            'reporting_creditors_creditors' : ['Supplier Name', 'Address Line 1', 'Address Line 2', 'Address Line 3', 'Town', 'County', 'Postcode', 'Telephone', 'Email', 'Credit Terms', 'Total Amount Owed', 'Current', '1 to 30 Days', '31 to 60 Days', '61 to 90 Days', 'More than 90 Days'],
            'reporting_profit_profit_by_job':['Job date', 'Customer Name', 'Customer Address Line 1', 'Customer Address Line 2', 'Customer Address Line 3', 'Customer Town', 'Customer County', 'Customer Postcode', 'Job contact', 'Job description', 'Job notes for engineer', 'Job number', 'Customer reference', 'Job is complete', 'Final invoice raised', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Total number of diary events', 'Total travel time', 'Total time on site', 'Total labour costs', 'Total travel costs', 'Total cost of parts', 'Total cost', 'Total revenue', 'Total profit', 'Profit margin', 'Quoted amount', 'Completed Date'],
            'reporting_sales_sales' : ['Invoice or Credit Date', 'Invoice Address Name', 'Invoice Address Line 1', 'Invoice Address Line 2', 'Invoice Address Line 3', 'Invoice Town', 'Invoice County', 'Invoice Postcode', 'Description', 'Invoice No or Credit No', 'Customer reference', 'Type', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Net', 'Deductions (CIS)', 'VAT', 'Total', 'Amount paid or allocated', 'Balance', 'Last payment or allocation date', 'Invoice Type'],
            'reporting_customers_jobEstimateAccessPhoneCallList':['Type', 'Job.estimate.no', 'Description', 'Status', 'Created.by', 'Created.on', 'Days.since.job.estimate.created', 'No.of.follow.up.sent', 'Last.communication.sent.on', 'Customer.name', 'Customer.reference', 'Customer.address.line.1', 'Customer.address.line.2', 'Customer.address.line.3', 'Customer.town', 'Customer.county', 'Customer.postcode', 'Customer.telephone.no', 'Customer.email', 'Work.address.name', 'Work.address.line.1', 'Work.address.line.2', 'Work.address.line.3', 'Work.address.town', 'Work.address.county', 'Work.address.postcode', 'Work.address.telephone.no', 'Work.address.email'],
            'reporting_customers_advertising_performance' : ['Marketing', 'No New Customers', 'No Estimates Created', 'No Jobs Created', 'No Diary Events linked to Jobs', 'Total Revenue from Jobs', 'Total Costs from Jobs', 'Total Profit from Jobs'],
            'reporting_users_office_tasks':['Customer account number', 'Customer.name', 'Customer.address.line.1', 'Customer.address.line.2', 'Customer.address.line.3', 'Customer.town','Customer.county', 'Customer.postcode', 'Customer.telephone.no', 'Customer.mobile', 'Customer.email', 'Job.address.name', 'Job.address.line.1', 'Job.address.line.2', 'Job.address.line.3', 'Job.address.town', 'Job.address.county', 'Job.address.postcode', 'Job.address.telephone.no', 'Job.address.mobile', 'Job.address.email', 'Type', 'Job or Estimate created by', 'Job or Estimate created on', 'Job or Estimate no', 'Job or Estimate description', 'Job or Estimate notes for engineer', 'Task created on', 'Task created by', 'Task', 'Assigned to', 'Done', 'Done by', 'Done date time'],
            'ai_logs_waiting_customers' : ['Customer ID', 'Name', 'Surname', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Error'],
            'ai_logs_processing_customers' : ['Customer ID', 'Name', 'Surname', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Processing reason'],
            'ai_logs_failed_customers' : ['Customer ID', 'Name', 'Surname', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Failed reason'],
            'ai_logs_successful_customers' : ['Customer ID', 'Name', 'Surname', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Accounting reference', 'Sent on'],
            'ai_logs_do_not_send_customers' : ['Customer ID', 'Name', 'Surname', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode'],
            'ai_logs_waiting_customer_invoices' : ['Invoice date', 'Invoice no', 'Description', 'VAT', 'Total', 'Error'],
            'ai_logs_processing_customer_invoices' : ['Invoice date', 'Invoice no', 'Description', 'VAT', 'Total', 'Processing reason'],
            'ai_logs_failed_customer_invoices' : ['Invoice date', 'Invoice no', 'Description', 'VAT', 'Total', 'Failed reason'],
            'ai_logs_successful_customer_invoices' : ['Invoice date', 'Invoice no', 'Description', 'VAT', 'Total', 'Sent on'],
            'ai_logs_do_not_send_customer_invoices' : ['Invoice date', 'Invoice no', 'Description', 'VAT', 'Total'],
            'ai_logs_waiting_customer_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Error'],
            'ai_logs_processing_customer_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Processing reason'],
            'ai_logs_failed_customer_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Failed reason'],
            'ai_logs_successful_customer_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Sent on'],
            'ai_logs_do_not_send_customer_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference'],
            'ai_logs_waiting_customer_creditnotes' : ['Credit note date', 'Credit note no', 'Description', 'VAT', 'Total', 'Error'],
            'ai_logs_processing_customer_creditnotes' : ['Credit note date', 'Credit note no', 'Description', 'VAT', 'Total', 'Processing reason'],
            'ai_logs_failed_customer_creditnotes' : ['Credit note date', 'Credit note no', 'Description', 'VAT', 'Total', 'Failed reason'],
            'ai_logs_successful_customer_creditnotes' : ['Credit note date', 'Credit note no', 'Description', 'VAT', 'Total', 'Sent on'],
            'ai_logs_do_not_send_customer_creditnotes' : ['Credit note date', 'Credit note no', 'Description', 'VAT', 'Total'],
            'ai_logs_waiting_suppliers' : ['Supplier ID', 'Name', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Error'],
            'ai_logs_processing_suppliers' : ['Supplier ID', 'Name', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Processing reason'],
            'ai_logs_failed_suppliers' : ['Supplier ID', 'Name', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Failed reason'],
            'ai_logs_successful_suppliers' : ['Supplier ID', 'Name', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode', 'Accounting reference', 'Sent on'],
            'ai_logs_do_not_send_suppliers' : ['Supplier ID', 'Name', 'Address line 1', 'Address line 2', 'Address line 3', 'Postcode'],
            'ai_logs_waiting_supplier_invoices' : ['Invoice date', 'Invoice no', 'VAT', 'Total', 'Error'],
            'ai_logs_processing_supplier_invoices' : ['Invoice date', 'Invoice no', 'VAT', 'Total', 'Processing reason'],
            'ai_logs_failed_supplier_invoices' : ['Invoice date', 'Invoice no', 'VAT', 'Total', 'Failed reason'],
            'ai_logs_successful_supplier_invoices' : ['Invoice date', 'Invoice no', 'VAT', 'Total', 'Sent on'],
            'ai_logs_do_not_send_supplier_invoices' : ['Invoice date', 'Invoice no', 'VAT', 'Total'],
            'ai_logs_waiting_supplier_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Error'],
            'ai_logs_processing_supplier_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Processing reason'],
            'ai_logs_failed_supplier_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Failed reason'],
            'ai_logs_successful_supplier_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference', 'Sent on'],
            'ai_logs_do_not_send_supplier_payments' : ['Payment date', 'Payment no', 'Description', 'Total', 'Payment reference'],
            'ai_logs_waiting_supplier_creditnotes' : ['Credit note date', 'Credit note no', 'VAT', 'Total', 'Error'],
            'ai_logs_processing_supplier_creditnotes' : ['Credit note date', 'Credit note no', 'VAT', 'Total', 'Processing reason'],
            'ai_logs_failed_supplier_creditnotes' : ['Credit note date', 'Credit note no', 'VAT', 'Total', 'Failed reason'],
            'ai_logs_successful_supplier_creditnotes' : ['Credit note date', 'Credit note no', 'VAT', 'Total', 'Sent on'],
            'ai_logs_do_not_send_supplier_creditnotes' : ['Credit note date', 'Credit note no', 'VAT', 'Total'],
            'ai_logs_failed_customer_import_payments' : ['Payment date', 'Description', 'Total', 'Payment reference', 'Failed reason'],
            'ai_logs_failed_supplier_import_payments' : ['Payment date', 'Description', 'Total', 'Payment reference', 'Failed reason'],
            'reporting_customers_advertising_performance' : ['Marketing', 'No New WorkAddresses', 'No New Customers', 'No Estimates Created', 'No Jobs Created', 'No Diary Events linked to Jobs', 'Total Revenue from Jobs', 'Total Costs from Jobs', 'Total Profit from Jobs'],
            'reporting_customers_servicePlan':['Customer name', 'Customer address line 1', 'Customer address line 2', 'Customer address line 3', 'Customer town', 'Customer county', 'Customer postcode', 'Customer Telephone', 'Customer mobile', 'Customer email', 'Number of properties related to the plan', 'Service plan', 'Service plan created by', 'Service plan created on date/time', 'Service plan expiry date'],
            'reporting_parts_parts':['Part Name', 'Customer Name', 'Customer Address Line 1', 'Customer Telephone', 'Customer Mobile', 'Customer Email', 'Job Address Name', 'Job Address Line 1', 'Job Address Line 2', 'Job Address Line 3', 'Job Address Town', 'Job Address County', 'Job Address Postcode', 'Job Address Telephone', 'Job Address Mobile', 'Job Address Email', 'Job Number', 'Job Description', 'Manufacturer Number', 'Quantity', 'Cost Price', 'Sale Price', 'Status', 'Requested Time', 'Fulfilled Time', 'Created By'],
            'reporting_parts_partsInstalled':['Part Name', 'Total Quantity', 'Total Cost Price', 'Total Sale Price'],
            'reporting_sales_Customer_Payments':['Payment Date', 'Invoice address name', 'Invoice address line 1', 'Invoice address line 2', 'Invoice address line 3', 'Invoice town', 'Invoice county', 'Invoice postcode', 'Invoice telephone', 'Invoice email', 'Payment created on', 'Payment created by', 'Invoice date', 'Invoice number', 'Invoice payment amount', 'Invoice customer reference', 'Invoice description', 'Invoice Amount', 'Payment method', 'Payment description', 'Payment nominal code', 'Payment reference', 'Payment amount', 'Payment Type'],
            'job_cost_supplier_returns': ['Reference ID', 'Part name','Quantity','Unit cost', 'Total (exc VAT)', 'VAT', 'Total (inc VAT)', 'Date', 'Credit received', 'Supplier invoice no', 'Supplier invoice date', 'Purchase order no', 'Created by', 'Created on'],
            'stock_location_reserve_list': ['Part', 'Quantity', 'Date reserved', 'Job number', 'Job date', 'Customer name', 'Address line 1', 'Address line 2', 'Address line 3', 'Town', 'County', 'Postcode'],
            'reporting_users_gps_leaderboard': [
                'User',
                'Distance',
                'Avg speed',
                'Excessive speeding',
                'Excessive braking',
                'Excessive acceleration',
                'Excessive cornering',
                'Score',
                'Max speed',
                'Engine hours',
                'Device ID',
                'Device unique identifier',
                'Device added date'
            ],
            'reporting_users_driver_trips' : [
                'User',
                "Start time",
                "Start address",
                "Start latitude",
                "Start longitude",
                "End time",
                "End address",
                "End latitude",
                "End longitude",
                "Distance",
                "Avg speed",
                "Duration",
                "Max speed"
            ],
            'reporting_users_driver_detail': [
                'Distance',
                'Avg speed',
                'Excessive speeding',
                'Excessive braking',
                'Excessive acceleration',
                'Excessive cornering',
                'Score',
                'Max speed',
                'Engine hours'
            ]
        };
        this.collection = {};
        this.pageValue = false;
        this.setData = function setData(category, fullObject) {
            this.collection[category] = fullObject;
        }
        this.setHeaders = function setHeaders(category, fullObject){
            this.tableHeaders[category] = fullObject.all_columns;
        }
        this.setPageValue = function setPageValue(pageVal){
            this.pageValue = pageVal;
        }
        this.getCount = function getCount(category) {
            this.count = parseInt(this.collection[category].count);
            return this.count;
        }
        this.getPageValue = function getPageValue(){
            return this.pageValue;
        }



        /*====================================================
         Instance variables
         ====================================================*/
        this.table_category;
        this.table_header_collection = [];
        this.table_data_collection = [];
        this.table_updated = false;
        this.table_view = 'table';
        this.table_view_params = {};

        /*====================================================
         setup the table with the last set of active_columns
         ====================================================*/
        this.handleTable = function handleTable(category) {
            this.table_category = category;
            if (this.collection[category]) {
                if(this.collection[category].view) {
                    this.table_view = this.collection[category].view;
                    this.table_view_params = this.collection[category].viewParams;
                } else {
                    this.table_view = 'table';
                    this.table_view_params = {};
                }

                this.handleTableData();
            }
        }

        /*====================================================
         - Builds an array of objects, each object stores the
         values for the relevant table headers.
         ====================================================*/
        this.handleTableData = function handleTableData() {
            var active_columns = this.orderColumnsByPos();
            var active_rows = this.orderRowsByPos();
            var data = this.collection[this.table_category].data;
            var rows = [];
            this.active_columns = active_columns;
            this.active_rows = active_rows;

            for(var x = 0, s = data.length; x < s; x++) {
                // reset the loop variables
                var row_cells = [];
                var temp_row = {};
                var temp_row_tpl = '';

                /*
                 Build the temp_row object. Temp row obj attribute names
                 are the table headers and the values will be the table cells.
                 */
                for (var i = 0, l = active_columns.length; i < l; i++) {
                    temp_row[active_columns[i].col_name] = data[x][active_columns[i].col_name];
                }

                /*
                 Put each object attribute into an array.
                 */

                for (var key in temp_row) {
                    if (temp_row.hasOwnProperty(key)) {
                        row_cells.push(temp_row[key]);
                    }
                }
                rows.push(temp_row);
            }
        }

        /*====================================================
         Update the table columns
         ====================================================*/
        this.updateTableCols = function updateTableCols(chosen_columns) {
            // replace the active_column obj with the columns selected by user
            this.collection[this.table_category].active_columns = chosen_columns;
            this.table_updated = true;
            this.handleTableData();
        }

        /*====================================================
         Re-order the objects in the active_columns array
         based on the attr 'pos' value
         ====================================================*/
        this.orderColumnsByPos = function orderColumnsByPos() {
            var active_columns = this.collection[this.table_category].active_columns;

            active_columns.sort(function(last, next) {
                return last.pos - next.pos
            });

            return active_columns;
        }

        /*====================================================
         Re-order the rows based on the pos of the row columns
         ====================================================*/
        this.summaryRowAdded = false;
        this.orderRowsByPos = function orderRowsByPos() {
            var columns = this.collection[this.table_category].active_columns;
            var data = this.collection[this.table_category].data;
            var summaryRow = (this.collection[this.table_category].summaryRow) ? this.collection[this.table_category].summaryRow : false;
            var rows = [];
            var sorted_rows = [];

            if(summaryRow && !this.summaryRowAdded && data.length) {
                var newRowToAppend = [];
                for (var colIndex = 0;colIndex < columns.length; colIndex++) {
                    var columnName = columns[colIndex].col_name;
                    if(summaryRow.hasOwnProperty(columnName)) {
                        var operation = summaryRow[columnName];
                        newRowToAppend[columnName] = this.getAdditionalData(columnName, data, operation);
                    } else {
                        newRowToAppend[columnName] = '';
                    }
                }
                data.push(newRowToAppend);
                this.summaryRowAdded = true;
            }

            if(this.table_view == 'table_customized'){

                rows = data;
                sorted_rows = rows;

            } else {

                for (var i = 0, l = columns.length; i < l; i++) {
                    var col_name = columns[i].col_name;
                    var col_pos = columns[i].pos;

                    for (var c = 0, x = data.length; c < x; c++) {
                        for(var key in data[c]) {
                            if (key === col_name ) {
                                rows.push({ row: c, value: data[c][col_name], col_pos: col_pos, id: data[c]['id'] });
                            }

                        }
                    }

                }


                for (var i = 0, l = rows.length / columns.length; i < l; i++) {
                    sorted_rows.push(_.where(rows, {row: i}));
                }
            }

            return sorted_rows;
        }

        /*====================================================
         To check whether it has  summary rows
         ====================================================*/
        this.hasSummaryRow = function hasSummaryRow() {
            return (this.collection[this.table_category].summaryRow) ? true : false;
        }

        /*====================================================
         Get additional data for reporting
         ====================================================*/
        this.getAdditionalData = function getAdditionalData(columnName, data, operation) {
            var columnValue = 0;
            for (var row = 0; row < data.length; row++) {
                for(var key in data[row]) {
                    if (key === columnName ) {
                        var valueToAdd = parseFloat(data[row][key]);
                        if(operation == 'SUM' || operation == 'AVG') {
                            columnValue += valueToAdd;
                        } else if(operation == 'MAX') {
                            columnValue = (valueToAdd > columnValue) ? valueToAdd : columnValue;
                        } else {
                            console.log('Dev : Please specify the operation');
                        }
                    }
                }
            }
            if(operation == 'AVG' && data.length) {
                columnValue = (columnValue / data.length).toFixed(2);
            }
            return columnValue.toFixed(2);
        }

        /*====================================================
         Getters
         ====================================================*/
        this.getActiveColumns = function getActiveColumns(category) {
            return this.collection[category].active_columns;
        }

        this.getOrderedColumns = function getOrderedColumns() {
            return this.active_columns;
        }

        this.getOrderedRows = function getOrderedRows() {
            return this.active_rows;
        }

        this.getBasicDetails = function getBasicDetails(category, i) {
            return this.collection[category].data[i];
        }

        this.getExtraDetails = function getExtraDetails(category, i) {
            return _.where(this.collection[category].panel_data,
                { id: this.collection[category].data[i].id }
            );
        }
        this.getAccessMethods = function getAccessMethods(category) {
            return this.collection[category].access_methods;
        }
        this.getSmsAccessMethods = function getSmsAccessMethods(category) {
            return this.collection[category].read_access;
        }
        this.getJobId = function getJobId(category) {
            return this.collection[category].jobId;
        }
        this.getAllColumns = function getAllColumns(category) {
            var category_collection = this.collection[category];
            var all_column_names = [];
            var columns = [];

            // Build an array of all of the available category names.

            for (var key in this.tableHeaders[category]) {
                // commented out because if we dont have data, no headers are available - Raja - COM-1898
                //if (key != 'id' && category_collection.data[0].hasOwnProperty(key)) {
                var col = this.tableHeaders[category][key];
                if (col != 'id') {
                    all_column_names.push(col);
                }
            }

            /*====================================================
             For all of the table headers in the category build an
             object that has the header name and whether the header
             is currently active.
             ====================================================*/
            for (var i = 0, l = all_column_names.length; i < l; i++) {

                var name = all_column_names[i];
                var temp = { col_name: name, active: false };

                for (var x = 0, s = category_collection.active_columns.length; x < s; x++) {
                    var col = category_collection.active_columns[x];

                    if(temp.col_name === col.col_name) {
                        temp.active = true;
                        temp.pos = col.pos;
                    }
                }
                columns.push(temp);
            }

            return _.sortBy(columns, function(col) { return col.pos });  // Sort by user preference
        }

        this.getSidepanelData = function getSidepanelData(index, cat){
            return (this.collection[cat] != undefined)?this.collection[cat].data[index]:undefined;
        }

        this.getTableData = function getTableData(category){
            return this.collection[category].data;
        }

        /* Function for get the dropdown list for job edit todos */
        this.getDropdownData = function getDropdownData(cat) {
            return this.collection[cat].dropdownData;
        }

        this.updateActiveColumns = function updateActiveColumns(new_columns) {
            var new_active_columns = [];

            for (var i = 0, l = new_columns.length; i < l; i++) {
                if (new_columns[i].active === true) {
                    new_columns[i].pos = i + 1;
                    new_active_columns.push(new_columns[i]);
                }
            }

            // sort the new array based on the updated pos
            new_active_columns.sort(function(last, next) {
                return last.pos - next.pos
            });

            return new_active_columns;
        }
        this.getReminder = function getReminder(index){
            var data = [];
            data = this.collection.work_reminders_view_pending.data[index];
            return data;
        }
        this.countReminders = function countReminders(){
            var amount = this.collection.work_reminders_view_pending.data.length;
            return amount
        }
        this.getViewType = function() {
            return {
                name: this.table_view,
                params: this.table_view_params
            }
        }
    })
    .service('colorSelector', function() {
        var branding_colors = [
            {
                color: 'cs-blue',
                rgba: 'rgba(0,183,201,1)'
            },
            {
                color: 'black',
                rgba: 'rgba(0,0,0,1)'
            },
            {
                color: 'gray',
                rgba: 'rgba(100,100,100,1)'
            },
            {
                color: 'white',
                rgba: 'rgba(255,255,255,1)'
            },
            {
                color: 'light-blue',
                rgba: 'rgba(112,178,177,1)'
            },
            {
                color: 'pale-blue',
                rgba: 'rgba(112,178,177,1)'
            },
            {
                color: 'lighter-blue',
                rgba: 'rgba(115,114,224,1)'
            },
            {
                color: 'true-blue',
                rgba: 'rgba(83,119,204,1)'
            },
            {
                color: 'dark-purple',
                rgba: 'rgba(148,148,185,1)'
            },
            {
                color: 'lightest-pink',
                rgba: 'rgba(255,225,248,1)'
            },
            {
                color: 'pink',
                rgba: 'rgba(255,205,244,1)'
            },
            {
                color: 'pale-purple',
                rgba: 'rgba(206,176,200,1)'
            },
            {
                color: 'purple',
                rgba: 'rgba(178,130,168,1)'
            },
            {
                color: 'light-yellow',
                rgba: 'rgba(254,243,162,1)'
            },
            {
                color: 'yellow',
                rgba: 'rgba(255,240,124,1)'
            },
            {
                color: 'dark-yellow',
                rgba: 'rgba(245,225,77,1)'
            },
            {
                color: 'lightest-orange',
                rgba: 'rgba(254,200,158,1)'
            },
            {
                color: 'light-orange',
                rgba: 'rgba(255,165,96,1)'
            },
            {
                color: 'dark-orange',
                rgba: 'rgba(255,136,96,1)'
            },
            {
                color: 'red',
                rgba: 'rgba(255,101,93,1)'
            },
            {
                color: 'dark-pink',
                rgba: 'rgba(211,133,133,1)'
            },
            {
                color: 'light-brown',
                rgba: 'rgba(204,169,157,1)'
            },
            {
                color: 'brown',
                rgba: 'rgba(172,114,94,1)'
            },
            {
                color: 'light-green',
                rgba: 'rgba(139,255,136,1)'
            },
            {
                color: 'medium-green',
                rgba: 'rgba(109,235,99,1)'
            },
            {
                color: 'dark-green',
                rgba: 'rgba(75,189,66,1)'
            },
            {
                color: 'sludge-green',
                rgba: 'rgba(144,180,141,1)'
            },
            {
                color: 'grey',
                rgba: 'rgba(203,203,203,1)'
            },
            {
                color: 'grey-medium',
                rgba: 'rgba(178,178,178,1)'
            }
        ];


        var diary_colors = [
            {
                "color": "cs-event-colour-1",
                "rgba": "rgb(151, 193, 231)",
                "rgba_dark": "rgb(90, 155, 216)"
            },
            {
                "color": "cs-event-colour-2",
                "rgba": "rgb(231, 189, 151)",
                "rgba_dark": "rgb(216, 151, 90)"
            },
            {
                "color": "cs-event-colour-3",
                "rgba": "rgb(231, 151, 193)",
                "rgba_dark": "rgb(216, 90, 155)"
            },
            {
                "color": "cs-event-colour-4",
                "rgba": "rgb(189, 151, 231)",
                "rgba_dark": "rgb(151, 90, 216)"
            },
            {
                "color": "cs-event-colour-5",
                "rgba": "rgb(193, 231, 151)",
                "rgba_dark": "rgb(155, 216, 90)"
            },
            {
                "color": "cs-event-colour-6",
                "rgba": "rgb(201, 223, 242)",
                "rgba_dark": "rgb(152, 194, 230)"
            },
            {
                "color": "cs-event-colour-7",
                "rgba": "rgb(242, 221, 201)",
                "rgba_dark": "rgb(230, 190, 152)"
            },
            {
                "color": "cs-event-colour-8",
                "rgba": "rgb(242, 201, 223)",
                "rgba_dark": "rgb(230, 152, 194)"
            },
            {
                "color": "cs-event-colour-9",
                "rgba": "rgb(221, 201, 242)",
                "rgba_dark": "rgb(190, 152, 230)"
            },
            {
                "color": "cs-event-colour-10",
                "rgba": "rgb(223, 242, 201)",
                "rgba_dark": "rgb(194, 230, 152)"
            },
            {
                "color": "cs-event-colour-11",
                "rgba": "rgb(184, 213, 239)",
                "rgba_dark": "rgb(130, 182, 227)"
            },
            {
                "color": "cs-event-colour-12",
                "rgba": "rgb(239, 210, 184)",
                "rgba_dark": "rgb(227, 175, 130)"
            },
            {
                "color": "cs-event-colour-13",
                "rgba": "rgb(239, 184, 213)",
                "rgba_dark": "rgb(227, 130, 182)"
            },
            {
                "color": "cs-event-colour-14",
                "rgba": "rgb(210, 184, 239)",
                "rgba_dark": "rgb(175, 130, 227)"
            },
            {
                "color": "cs-event-colour-15",
                "rgba": "rgb(213, 239, 184)",
                "rgba_dark": "rgb(182, 227, 130)"
            },
            {
                "color": "cs-event-colour-16",
                "rgba": "rgb(168, 203, 235)",
                "rgba_dark": "rgb(110, 168, 222)"
            },
            {
                "color": "cs-event-colour-17",
                "rgba": "rgb(235, 200, 168)",
                "rgba_dark": "rgb(222, 164, 110)"
            },
            {
                "color": "cs-event-colour-18",
                "rgba": "rgb(235, 168, 203)",
                "rgba_dark": "rgb(222, 110, 168)"
            },
            {
                "color": "cs-event-colour-19",
                "rgba": "rgb(200, 168, 235)",
                "rgba_dark": "rgb(164, 110, 222)"
            },
            {
                "color": "cs-event-colour-20",
                "rgba": "rgb(203, 235, 168)",
                "rgba_dark": "rgb(168, 222, 110)"
            },
            {
                "color": "cs-event-colour-21",
                "rgba": "rgb(217, 233, 246)",
                "rgba_dark": "rgb(172, 207, 236)"
            },
            {
                "color": "cs-event-colour-22",
                "rgba": "rgb(246, 231, 217)",
                "rgba_dark": "rgb(236, 203, 172)"
            },
            {
                "color": "cs-event-colour-23",
                "rgba": "rgb(246, 217, 233)",
                "rgba_dark": "rgb(236, 172, 207)"
            },
            {
                "color": "cs-event-colour-24",
                "rgba": "rgb(231, 217, 246)",
                "rgba_dark": "rgb(203, 172, 236)"
            },
            {
                "color": "cs-event-colour-25",
                "rgba": "rgb(233, 246, 217)",
                "rgba_dark": "rgb(207, 236, 172)"
            }
        ];

        var all_colors = [
            {
                color: 'black',
                rgba: 'rgba(0,0,0,1)'
            },
            {
                color: 'gray',
                rgba: 'rgba(100,100,100,1)'
            },
            {
                color: 'white',
                rgba: 'rgba(255,255,255,1)'
            },
            {
                color: 'light-blue',
                rgba: 'rgba(112,178,177,1)'
            },
            {
                color: 'pale-blue',
                rgba: 'rgba(112,178,177,1)'
            },
            {
                color: 'lighter-blue',
                rgba: 'rgba(115,114,224,1)'
            },
            {
                color: 'true-blue',
                rgba: 'rgba(83,119,204,1)'
            },
            {
                color: 'dark-purple',
                rgba: 'rgba(148,148,185,1)'
            },
            {
                color: 'lightest-pink',
                rgba: 'rgba(255,225,248,1)'
            },
            {
                color: 'pink',
                rgba: 'rgba(255,205,244,1)'
            },
            {
                color: 'pale-purple',
                rgba: 'rgba(206,176,200,1)'
            },
            {
                color: 'purple',
                rgba: 'rgba(178,130,168,1)'
            },
            {
                color: 'light-yellow',
                rgba: 'rgba(254,243,162,1)'
            },
            {
                color: 'yellow',
                rgba: 'rgba(255,240,124,1)'
            },
            {
                color: 'dark-yellow',
                rgba: 'rgba(245,225,77,1)'
            },
            {
                color: 'lightest-orange',
                rgba: 'rgba(254,200,158,1)'
            },
            {
                color: 'light-orange',
                rgba: 'rgba(255,165,96,1)'
            },
            {
                color: 'dark-orange',
                rgba: 'rgba(255,136,96,1)'
            },
            {
                color: 'red',
                rgba: 'rgba(255,101,93,1)'
            },
            {
                color: 'dark-pink',
                rgba: 'rgba(211,133,133,1)'
            },
            {
                color: 'light-brown',
                rgba: 'rgba(204,169,157,1)'
            },
            {
                color: 'brown',
                rgba: 'rgba(172,114,94,1)'
            },
            {
                color: 'light-green',
                rgba: 'rgba(139,255,136,1)'
            },
            {
                color: 'medium-green',
                rgba: 'rgba(109,235,99,1)'
            },
            {
                color: 'dark-green',
                rgba: 'rgba(75,189,66,1)'
            },
            {
                color: 'sludge-green',
                rgba: 'rgba(144,180,141,1)'
            },
            {
                color: 'grey',
                rgba: 'rgba(203,203,203,1)'
            },
            {
                color: 'grey-medium',
                rgba: 'rgba(178,178,178,1)'
            }
        ];

        this.getColors = function(category) {
            if (category === 'branding_background' ||
                  category === 'branding_text') {
                return branding_colors;
            } else if (category === 'diary_color') {
                return diary_colors;
            } else {
                return all_colors;
            }
        };
    })
    .service('template', function ($compile, $http, $templateCache, $q) {
        this.load = function load(tpl_path, elm, scope) {
            var deferred = $q.defer();

            $http.get(tpl_path, {cache: $templateCache})
                .success(function (templateContent) {

                    elm.after($compile(templateContent)(scope));
                    deferred.resolve(true);

                }).error(function () {

                console.warn("template not found");
                deferred.reject(false);
            });

            return deferred.promise;
        }
    })
    .service('formPanelCollection', function(formBuilder, $translate) {
        // Used everywhere postcode lookup is needed
        this.postcode_form_inputs = {
            fields: [
                {
                    label: 'Postcode',
                    type: 'postcode',
                    required: true,
                    error_message: $translate('Please.Enter.Valid.Postcode'),
                    model: 'postcode'
                }
            ]
        };

        this.quick_add_categories = [];

        this.setFilterData = function setFilterData(Value){
            this.quick_add_categories = Value;
        }

        this.setMoreFilterData = function setMoreFilterData(category, Value){
            this.quick_add_categories[category] = Value;
        }
        this.getMoreFilterData = function getMoreFilterData(category) {
            return this.quick_add_categories[category];
        }
        this.getFieldCollection = function getFieldCollection(category) {
            if (! this.quick_add_categories.hasOwnProperty('postcode')) {
                this.quick_add_categories['postcode'] = this.postcode_form_inputs;
            }
            return this.quick_add_categories[category].fields;
        }

        this.handleInputs = function handleInputs(category) {
            var fields = this.getFieldCollection(category);
            formBuilder.assessbleInputs(fields);
        }

        this.getInputTemplates = function getInputTemplates() {
            var templates = formBuilder.getTemplates();
            return templates;
        }
    })
    .service('formBuilder', function(stringHelpers) {
        // instance variables
        this.input_collection;

        // input templates
        var form_input_tpls = {
            text: '<input type="text" ng-model="{{model}}">',
            number: '<input type="text" ng-model="{{model}}">',
            email: '<input type="email" ng-model="{{model}}">',
            from: '<input type="text" ng-model="{{model}}">',
            textarea: '<textarea ng-model="{{model}}"></textarea>',
            checkbox: '<label {{paidFilter}} ><input type="checkbox" ng-model="{{model}}">{{label}}</label>',
            radio: '<label><input type="radio" name="radiobox" ng-model="{{model}}" value = "false">{{label}}</label>',
            select: '' +
                '<div class="select-with-clear-link-container" {{paidFilter}}>' +
                    '<select ng-model="{{model}}" id="{{id}}" class="full-width-select">' +
                        '<option ng-show="model==prompt && model!=\'Type\'" selected value="prompt" disabled>-- Please choose --</option>' +
                        '<option ng-show="model!=prompt && model==\'Type\'" value="type">All</option>' +
                        '<option ng-repeat="item in {{repeater_options}}" value="{{item.id}}" ng-selected="{{item.id == {{model}}}}">{{item.text}}</option>' +
                    '</select>' +
                    '<a ng-click="resetSalesReportMoreFilter(\'{{model}}\'); {{model}} = \'prompt\'" href="" ng-show="{{model}} !== \'prompt\' && {{model}} !== \'type\'">Reset</a>' +
                '</div>',
            titleSelect: '' +
                '<div class="select-with-clear-link-container">' +
                '<select ng-model="{{model}}" id="{{id}}" class="full-width-select" ng-options="item.id as item.text for item in {{repeater_options}}" ng-init="{{model}} = {{defaultId}}">' +
                '</select>' +
                '<a ng-click="{{model}} = {{defaultId}}" href="" ng-show="{{model}} !== {{defaultId}}">Reset</a>' +
                '</div>',
            postcode: '<div class="input-append"><input type="text" ng-model="postcode" ng-change="isInputPostcode(postcode)"><a class="add-on" href="" ng-click="lookUpPostCode()" ng-show="!invalid_postcode"><span class="ss-search"></span></a><a class="add-on disabled" href="" ng-show="invalid_postcode" tooltip="Please enter a valid postcode" tooltip-placement="right"><span class="ss-search"></span></a></div>',
            phone: '<div phonewidget multiple-numbers="true"></div>',
            date: '<div ng-controller="DatePickerWidget"><div class="control-group datepicker-control-group"><div class="controls"><div class="input-append datepicker-append"><input id="datepicker-input" type="text" ng-model="{{model}}" value="{{ {{model}} | date:\'yyyy-MM-dd\'}}"><span class="add-on" ng-click="show_date_picker = !show_date_picker"><span class="ss-calendar"></span></span></div></div><div class="page-datepicker"><div class="calendar-wrapper" ng-show="show_date_picker"><datepicker type="pagePicker" min="minDate" show-weeks="showWeeks" ng-model="{{model}}"></datepicker></div></div></div></div>',
            smart_filter: '<div class="smart-filter"><select chosen ng-model="{{model}}" ng-options="option for option in {{repeater_options}}" data-placeholder="-- Please Choose --" class="chosen-select full-width-select"></select></div>',
            smart_filter_with_optgroup: '' +
                '<div class="select-with-clear-link-container">' +
                    '<select ng-model="{{model}}" class="full-width-select" >' +
                        '<option selected value="prompt" disabled>-- Please choose --</option>' +
                        '<optgroup ng-repeat="(key, row) in {{repeater_options}}" label="{{key}}">' +
                            '<option ng-repeat="item in row.items" ng-selected="{{model}} == item.id" value="{{item.id}}">{{item.text}}</option>' +
                        '</optgroup>' +
                    '</select>' +
                    '<a ng-click="resetSalesReportMoreFilter(\'{{model}}\'); {{model}} = \'prompt\'" href="" ng-show="{{model}} !== \'prompt\'">Reset</a>' +
                '</div>',
            smart_filter_dynamic: '<div ng-model="{{model}}" class="full-width-select" {{multiple}} {{paidFilter}} ui-select2="select2Options_{{model}}"></div>',
            smart_filter_withoutoptgroup: '' +
                '<div class="controls full-width-smart-filter">' +
                    '<div class="container smart-filter">' +
                        '<select ng-model="{{model}}" class="chosen-select" id="userGroupSelector">' +
                            '<option selected="selected"></option>' +
                            '<option ng-repeat="group in {{repeater_options}}" value="{{ group.id }}" ng-class="{group: group.group }">{{group.description}}</option>' +
                        '</select>'+
                        '<span fixchosen="userGroupSelector" data="{{repeater_options1}}"></span>' +
                    '</div>' +
                '</div>',
             dynamicTemplate: ''+ '<panelwithformtemplate templateCategory="{{templateCategory}}" filePath="{{filePath}}" ></panelwithformtemplate>'
        };

        this.assessbleInputs = function assessbleInputs(input_collection) {
            this.input_collection = input_collection;
            this.handleInputs();
        }

        this.handleInputs = function handleInputs() {
            this.grouped_inputs = [];
            var tmp_input = {};

            for (var i = 0, l = this.input_collection.length; i < l; i++) {
                var type = this.input_collection[i].type,
                    titleSelect_input = type === 'titleSelect',
                    select_input = type === 'select',
                    smart_filter_input = type === 'smart_filter',
                    smart_filter_with_optgroup = type === 'smart_filter_with_optgroup',
                    smart_filter_withoutoptgroup = type === 'smart_filter_withoutoptgroup',
                    checkbox_input = type === 'checkbox',
                    Radio_inputs = type === 'radio',
                    smart_filter_dynamic = type === 'smart_filter_dynamic',
                    checkbox_collection_input = type === 'checkbox_collection',
                    input_has_model = this.input_collection[i].model !== undefined,
                    input_has_id = this.input_collection[i].input_id !== undefined,
                    input_has_permission = this.input_collection[i].hasPermission !== undefined,
                    input_has_access = this.input_collection[i].access !== undefined,
                    required_input = this.input_collection[i].required,
                    email_input = type ==='email',
                    from_input = type ==='from',
                    dynamicTemplate = type ==='dynamicTemplate';

                tmp_input.input = form_input_tpls[type];
                tmp_input.type = type;
                tmp_input.label = this.input_collection[i].label;
                tmp_input.model = this.input_collection[i].model;
                tmp_input.depends = this.input_collection[i].depends;
                tmp_input.paidFilter = this.input_collection[i].paidFilter;
                tmp_input.hasPermission = this.input_collection[i].hasPermission;
                tmp_input.access = this.input_collection[i].access;
                if(this.input_collection[i].check_exists) {
                    tmp_input.check_exists = this.input_collection[i].check_exists;
                    tmp_input.check_exists_url = this.input_collection[i].check_exists_url;
                    tmp_input.additional_params = this.input_collection[i].additional_params;
                    tmp_input.exists_error_message = this.input_collection[i].exists_error_message;
                }
                if (tmp_input.depends || smart_filter_dynamic) {
                    tmp_input.dynamicUrl = this.input_collection[i].url;
                }
                if (dynamicTemplate) {
                    tmp_input.filePath = this.input_collection[i].filePath;
                    tmp_input.templateCategory = this.input_collection[i].templateCategory;
                    tmp_input.data = this.input_collection[i].data;
                    tmp_input.input = tmp_input.input.replace(/{{filePath}}/g, this.input_collection[i].filePath);
                    tmp_input.input = tmp_input.input.replace(/{{templateCategory}}/g, this.input_collection[i].templateCategory);
                    tmp_input.input = tmp_input.input.replace(/{{data}}/g, this.input_collection[i].data);
                }

                if(select_input) {
                    var select_options = this.input_collection[i].options;
                    tmp_input.input = this.handleSelect(select_options,  this.input_collection[i].paidFilter);
                    tmp_input.options = select_options;
                }else if(titleSelect_input){
                    var select_options = this.input_collection[i].options;
                    tmp_input.input = this.handleTitleSelect(select_options);
                    tmp_input.options = select_options;
                }else if(smart_filter_input) {
                    tmp_input.url = this.input_collection[i].url;
                }else if(checkbox_input) {
                    tmp_input.input = this.handleCheckbox(this.input_collection[i].label, this.input_collection[i].paidFilter);
                    tmp_input.type = 'checkbox';
                }else if(Radio_inputs){
                    tmp_input.input = this.handleRadio(this.input_collection[i].label);
                    tmp_input.type = 'radio';
                }else if(checkbox_collection_input) {
                    var checkbox_labels = this.input_collection[i].checkboxes;
                    tmp_input.type = 'checkbox_collection';
                    tmp_input.checkboxes = this.handleCheckboxCollection(checkbox_labels);
                } else if (smart_filter_with_optgroup) {
                    tmp_input.data = this.input_collection[i].data;
                } else if (smart_filter_withoutoptgroup) {
                    tmp_input.data = this.input_collection[i].data;
                } else if (smart_filter_dynamic) {
                    var multiple = (this.input_collection[i].multiple && this.input_collection[i].multiple == true) ? "multiple=multiple" : "";
                    tmp_input.input = tmp_input.input.replace(/{{multiple}}/, multiple);
                    var paidFilter =  this.input_collection[i].paidFilter;
                    tmp_input.input = tmp_input.input.replace('{{paidFilter}}', paidFilter);

                }

                if(required_input) {
                    tmp_input.required = this.input_collection[i].required;
                    tmp_input.error_message = this.input_collection[i].error_message;
                }
                if(this.input_collection[i].type === 'number') {
                    tmp_input.error_message = this.input_collection[i].error_message;
                }
                if(this.input_collection[i].type === 'email') {
                    tmp_input.error_message = this.input_collection[i].error_message;
                }
                if(this.input_collection[i].type === 'from') {
                    tmp_input.error_message = this.input_collection[i].error_message;
                }
                if(input_has_id) {
                    tmp_input.input_id = this.input_collection[i].input_id;
                    tmp_input.input = tmp_input.input.replace('{{id}}', tmp_input.input_id);
                }
                if(input_has_model) {
                    tmp_input.input = tmp_input.input.replace(/{{model}}/g, this.input_collection[i].model);
                }else {
                    if(checkbox_collection_input) {
                        tmp_input.checkboxes = this.handleCheckboxCollectionModels(i, tmp_input.checkboxes);
                        tmp_input.models = _.pluck(this.input_collection[i].checkboxes, 'model');
                    }else {
                        tmp_input.input = tmp_input.input.replace('ng-model="{{model}}"', '');
                    }
                }

                this.grouped_inputs.push(tmp_input);
                tmp_input = {};
            }
        }

        /*====================================================
         Construct a select and return the template
         ====================================================*/
        this.handleSelect = function handleSelect(select_options, paidFilter) {
            var select_tpl = form_input_tpls.select,
                option_collection = '';

            if((paidFilter) == '') {
                var select_tpl= select_tpl;

            } else {
                select_tpl = select_tpl.replace('{{paidFilter}}', paidFilter);
            }
            return select_tpl;
        }

        this.handleTitleSelect= function handleTitleSelect(select_options){
            var select_tpl=form_input_tpls.titleSelect,
                option_collection = '';
                return select_tpl;
        }

         /*====================================================
         Construct a checkbox and return the template
         ====================================================*/
        this.handleCheckbox = function handleCheckbox(label, paidFilter) {
            var label = label.label || label,
            paidFilter = paidFilter,
            checkbox_tpl = form_input_tpls.checkbox;
                if((paidFilter) == '') {
                    var checkbox = checkbox_tpl.replace('{{label}}', label);
                } else {
                    checkbox = checkbox_tpl.replace('{{paidFilter}}', paidFilter).replace('{{label}}', label);
                }
            return checkbox;
        }
        this.handleRadio = function handleRadio(label) {
            var label = label.label || label,
                radio_tpl = form_input_tpls.radio,
                radio = radio_tpl.replace('{{label}}', label);

            return radio;
        }

        /*==========================================================================================
         Constructs the html for a group of checkboxes
         ==========================================================================================*/
        this.handleCheckboxCollection = function handleCheckboxCollection(checkboxes) {
            var checkboxes_collection = '';

            for (var i = 0, l = checkboxes.length; i < l; i++) {
                var checkbox = this.handleCheckbox(checkboxes[i]);
                checkboxes_collection += checkbox;
            }

            return checkboxes_collection;
        }

        /*==========================================================================================
         Add models to checkboxes that belong in a collection
         ==========================================================================================*/
        this.handleCheckboxCollectionModels = function handleCheckboxCollectionModels(index, checkboxes_str) {
            for(var i = this.input_collection[index].checkboxes.length - 1; i >= 0; i--) {
                var model = this.input_collection[index].checkboxes[i].model,
                    model_strs = stringHelpers.findAllOccurances(checkboxes_str, '{{model}}');

                checkboxes_str = stringHelpers.replaceStringAt(checkboxes_str, model_strs[i], '{{model}}', model);
            }
            return checkboxes_str;
        }

        // return the templates
        this.getTemplates = function getTemplates() {
            return this.grouped_inputs;
        }
        this.getSmartFilterData = function getSmartFilterData(){
            return this.pathData;
        }
    })
    .service('smsTemplates',function () {
        this.templates = [];
        this.setTemplatesValue = function setTemplatesValue(smsTempValues) {
            this.templates = smsTempValues;
        }
        this.getSmsTemplates = function getSmsTemplates() {
            return this.templates;
        }
    })
    .service('sidePanel', function($timeout, $rootScope, $document, confirmationBoxHelper, $translate) {
        /*====================================================
         Methods called from outside
         ====================================================*/
        this.open = function open(panel, remove_on_close, panel_identifier) {
            var panel_not_showing = (panel && panel.classList)?panel.classList.contains('in-view') ===  false:false;

            if(panel_not_showing) {
                this.panel = panel;
                this.overlay = document.createElement('div');
                this.overlay.id = 'page-overlay';
                this.directive_elm = document.querySelector('#' + panel_identifier);
                this.remove_on_close = remove_on_close;
                this.showPanel();
                this.showOverLay();
                //this.attachListeners();

                var panelName = this.directive_elm.attributes.template.value;
                $rootScope.$emit('sidepanel:open', panelName);

            }else {
                return;
            }
        }

        $rootScope.$on('closeAllSidepanels', function(){
            confirmationBoxHelper.hideConfirmationBox();
            var panel = document.querySelectorAll('#side-panel.in-view');
            for (var i = 0; i < panel.length; i++) {
                panel[i].classList.remove('in-view');
            }
            var overlay = document.querySelectorAll('#page-overlay.in-view');
            for (var i = 0; i < overlay.length; i++) {
                overlay[i].classList.remove('darker');
                overlay[i].classList.remove('in-view');
            }
            $timeout(function(){
                var panel = document.querySelectorAll('#send_statement');
                for (var i = 0; i < panel.length; i++) {
                    panel[i].parentNode.removeChild(panel[i]);
                }
                var panel = document.querySelectorAll('#view_job_report');
                for (var i = 0; i < panel.length; i++) {
                    panel[i].parentNode.removeChild(panel[i]);
                }
                var panel = document.querySelectorAll('#view_print_invoice');
                for (var i = 0; i < panel.length; i++) {
                    panel[i].parentNode.removeChild(panel[i]);
                }
                var panel = document.querySelectorAll('#side-panel');
                var static_panel = angular.element(panel).hasClass('static-side-panel');
                if(!static_panel)   // for customer credit note and invoice screens
                {
                    for (var i = 0; i < panel.length; i++) {
                        panel[i].parentNode.removeChild(panel[i]);
                    }
                }
                var overlay = document.querySelectorAll('#page-overlay');
                for (var i = 0; i < overlay.length; i++) {
                    overlay[i].parentNode.removeChild(overlay[i]);
                }
            },600);
            $rootScope.$emit('sidepanel:closed');
        });

        this.close = function close(confirm) {
            if(this.panel.classList.contains("in-view")) {
                var self = this;
                if (confirm) {
                    confirmationBoxHelper.getConfirmation($translate('Are.you.sure.you.want.to.close.side.panel?'), $rootScope).then(function() {
                        $rootScope.$emit('sidepanel:removecontent');
                        self.hidePanel();
                        self.hideOverlay();
                        if (self.directive_elm) {
                            var event_name = 'sidepanel:' + self.directive_elm.attributes.template.value + ':closed';
                            $rootScope.$emit(event_name);
                        }
                        $rootScope.$emit('parent:updated');
                    });
                } else {
                    confirmationBoxHelper.hideConfirmationBox();
                    this.hidePanel();
                    this.hideOverlay();

                    if (self.directive_elm) {
                        var event_name = 'sidepanel:' + self.directive_elm.attributes.template.value + ':closed';
                        $rootScope.$emit(event_name);
                    }
                    $rootScope.$emit('parent:updated');
                    $rootScope.$emit('sidepanel:closed');
                }
            }
        }

        /*====================================================
         Internal Methods
         ====================================================*/
        this.showPanel = function showPanel() {
            this.panel.classList.add('in-view');
        }

        this.showOverLay = function showOverLay() {
            var self = this;
            document.getElementsByTagName('body')[0].appendChild(this.overlay);

            $timeout(function() {
                self.overlay.classList.add('in-view');
            }, 100);

            this.overlay.addEventListener('click', function() {
                self.close(true);
            }, false);
        }

        this.attachListeners = function attachListeners(){
            // attach click listeners
            var overlay = document.querySelectorAll('#page-overlay');
            var save_link = document.querySelectorAll('#save-panel-btn');
            var cancel_link = document.querySelectorAll('#cancel-panel-btn');
            for (var i = 0; i < overlay.length; i++) {
                overlay[i].addEventListener('click', handleListenerClose);
            }
            for (var i = 0; i < save_link.length; i++) {
                save_link[i].addEventListener('click', handleListenerClose);
            }
            for (var i = 0; i < cancel_link.length; i++) {
            cancel_link[i].addEventListener('click', handleListenerClose);
            }
        }

        var handleListenerClose = function handleListenerClose(){
            this.removeConfirmationIfItExists();
            var should_close = true;
            // check if overlay contains darker class
            var overlay = document.querySelectorAll('#page-overlay.in-view');
            for (var i = 0; i < overlay.length; i++) {
                 if (overlay[i].classList.contains('darker') === true){
                     should_close = false;
                 }
            }

            if (should_close === true) {
                 var overlay = document.querySelectorAll('#page-overlay.in-view');
                 for (var i = 0; i < overlay.length; i++) {
                     overlay[i].classList.remove('in-view');
                     overlay[i].parentNode.removeChild(overlay[i]);
                 }
                 var panel = document.querySelectorAll('#side-panel.in-view');
                 for (var i = 0; i < panel.length; i++) {
                     panel[i].classList.remove('in-view');
                 }
            }
            $rootScope.$emit('sidepanel:closed');
        }

        this.hidePanel = function hidePanel() {
            this.panel.classList.remove('in-view');

            if(this.remove_on_close && this.panel.parentNode) {
                var self = this;

                $timeout(function() {
                    if(self.directive_elm && self.directive_elm.parentNode) {
                        self.directive_elm.parentNode.removeChild(self.directive_elm);
                    }
                    if(self.panel.parentNode) {
                        self.panel.parentNode.removeChild(self.panel);
                    }
                }, 200);
            }
        }

        this.hideOverlay = function hideOverlay() {
            var self = this;
            this.overlay.classList.remove('in-view');

            $timeout(function() {
                if(self.overlay.parentNode) {
                    self.overlay.parentNode.removeChild(self.overlay);
                }
            }, 200);
        }

        this.focusInput = function focusInput(panel, selector) {
            $timeout(function() {
                var input = panel.querySelector(selector);
                input.focus();
            }, 100);
        }
    })
    .service('reportNavSidebar', function ($translate, prefix, $http) {
        var reportsubnavItems = [
            { id: 'customers',
                firstTier: 'Customers',
                moduleFeature: 'customerReports',
                secondTier: [
                    { id: 'customers',
                        item: 'Customers',
                        state: 'customers',
                        description: 'Description.for.Customers',
                        feature: 'Basiccustomerreports'
                    },
//                    { id: 'branches',
//                        item: 'Branches',
//                        state: 'branches',
//                        description: 'Description.for.Branches'
//                    },
                    { id: 'work_addresses',
                        item: $translate('Work.Addresses'),
                        state: 'work_addresses',
                        description: 'Description.for.Work.Addresses',
                        feature: 'Basiccustomerreports'
                    },
//                    { id: 'contacts',
//                        item: 'Contacts',
//                        state: 'contacts',
//                        description: 'Description.for.Contacts'
//                    },
//                    { id: 'landlords',
//                        item: 'Landlords',
//                        state: 'landlords',
//                        description: 'Description.for.Landlords'
//                    },
                    { id: 'appliance_details',
                        item: $translate('Appliance.Details'),
                        state: 'appliance_details',
                        description: 'Description.for.Appliance.Details',
                        feature: 'Basiccustomerreports'
                    },
//                    { id: 'notes',
//                        item: 'Notes',
//                        state: 'notes',
//                        description: 'Description.for.Notes'
//                    },
//                    { id: 'emails',
//                        item: 'Emails',
//                        state: 'emails',
//                        description: 'Description.for.Emails'
//                    },
//                    { id: 'prints',
//                        item: 'Prints',
//                        state: 'prints',
//                        description: 'Description.for.Prints'
//                    },
//                    { id: 'SMS',
//                        item: 'SMS',
//                        state: 'SMS',
//                        description: 'Description.for.SMS'
//                    },
//                    { id: 'phone_calls',
//                        item: $translate('Phone.Calls'),
//                        state: 'phone_calls',
//                        description: 'Description.for.Phone.Calls'
//                    },
//                    { id: 'reminders',
//                        item: 'Reminders',
//                        state: 'reminders',
//                        description: 'Description.for.Reminders'
//                    },
//                    { id: 'attached_files',
//                        item: $translate('Attached.Files'),
//                        state: 'attached_files',
//                        description: 'Description.for.Attached.Files'
//                    },
                    { id: 'advertising_performance',
                        item: $translate('Advertising.Performance'),
                        state: 'advertising_performance',
                        description: 'Description.for.Advertising.Performance',
                        feature: 'Basiccustomerreports'
                    },
//                    { id: 'properties_with_expired_gas_safety_certificates',
//                        item: $translate('Properties.with.expired.Gas.Safety.Certificates'),
//                        state: 'properties_with_expired_gas_safety_certificates',
//                        description: 'Description.for.Properties.with.expired.Gas.Safety.Certificates'
//                    },
                    { id: 'jobEstimateAccessPhoneCallList',
                        item: $translate('Job.estimate.access.phone.call.list'),
                        state: 'jobEstimateAccessPhoneCallList',
                        description: 'Description.for.job.estimate.access.phone.call.list',
                        feature: 'Basiccustomerreports'
                    },
                    { id: 'servicePlan',
                        item: $translate('Service.Plan'),
                        state: 'servicePlan',
                        description: 'Description.for.Service.Plan',
                        feature: 'Basiccustomerreports'
                    }
                ]
            },
            { id: 'service_reminders',
                firstTier: 'Service Reminders',
                moduleFeature: 'serviceReminderReports',
                secondTier: [
                    { id: 'service_reminders',
                        item: $translate('Service.Reminders'),
                        state: 'service_reminders',
                        description: 'Description.for.Service.Reminders',
                        feature: 'Basicservicereminderreports'
                    },
                    { id: 'properties_without_services',
                        item: $translate('Properties.without.Services'),
                        state: 'properties_without_services',
                        description: 'Description.for.Properties.without.Services',
                        feature: 'Basicservicereminderreports'
                    }
//                    { id: 'service_reminder_performance_analysis',
//                        item: $translate('Service.Reminder.Performance.Analysis'),
//                        state: 'service_reminder_performance_analysis',
//                        description: 'Description.for.Service.Reminder.Performance.Analysis'
//                    },
//                    { id: 'service_reminder_communications',
//                        item: $translate('Service.Reminder.Communications'),
//                        state: 'service_reminder_communications',
//                        description: 'Description.for.Service.Reminders.Communications'
//                    }
                ]
            },
//            { id: 'contract_plans',
//                firstTier: 'Contract Plans',
//                secondTier: [
//                    { id: 'customers_on_contract_plans',
//                        item: $translate('Customers.on.Contract.Plans'),
//                        state: 'customers_on_contract_plans',
//                        description: 'Description.for.Customers.on.Contract.Plans'
//                    },
//                    { id: 'expiring_contract_plans',
//                        item: $translate('Expiring.Contract.Plans'),
//                        state: 'expiring_contract_plans',
//                        description: 'Description.for.Expiring.Contract.Plans'
//                    },
//                    { id: 'contract_plans_profit_by_customer',
//                        item: $translate('Contract.Plans.Profit.by.Customer'),
//                        state: 'contract_plans_profit_by_customer',
//                        description: 'Description.for.Contract.Plans.Profit.by.Customer'
//                    },
//                    { id: 'notifications_sent_to_customers',
//                        item: $translate('Notifications.sent.to.Customers'),
//                        state: 'notifications_sent_to_customers',
//                        description: 'Description.for.Notifications.sent.to.Customers'
//                    }
//                ]
//            },
            { id: 'suppliers',
                firstTier: 'Suppliers',
                moduleFeature: 'supplierReports',
                secondTier: [
                    { id: 'suppliers',
                        item: 'Suppliers',
                        state: 'suppliers',
                        description: 'Description.for.Suppliers',
                        feature: 'Basicsupplierreports'
                    },
//                    { id: 'branches',
//                        item: 'Branches',
//                        state: 'branches',
//                        description: 'Description.for.Branches'
//                    },
                    { id: 'contacts',
                        item: 'Contacts',
                        state: 'contacts',
                        description: 'Description.for.Contacts',
                        feature: 'Basicsupplierreports'
                    }
//                    { id: 'notes',
//                        item: 'Notes',
//                        state: 'notes',
//                        description: 'Description.for.Notes'
//                    },
//                    { id: 'emails',
//                        item: 'Emails',
//                        state: 'emails',
//                        description: 'Description.for.Emails'
//                    },
//                    { id: 'prints',
//                        item: 'Prints',
//                        state: 'prints',
//                        description: 'Description.for.Prints'
//                    },
//                    { id: 'SMS',
//                        item: 'SMS',
//                        state: 'SMS',
//                        description: 'Description.for.SMS'
//                    },
//                    { id: 'phone_calls',
//                        item: $translate('Phone.Calls'),
//                        state: 'phone_calls',
//                        description: 'Description.for.Phone.Calls'
//                    },
//                    { id: 'attached_files',
//                        item: $translate('Attached.Files'),
//                        state: 'attached_files',
//                        description: 'Description.for.Attached.Files'
//                    }
                ]
            },
            { id: 'estimates',
                firstTier: 'Estimates',
                moduleFeature: 'estimateReports',
                secondTier: [
                    { id: 'estimates',
                        item: 'Estimates',
                        state: 'estimates',
                        description: 'Description.for.Estimates',
                        feature: 'Basicestimatereports'
                    },
//                    { id: 'budgets',
//                        item: 'Budgets',
//                        state: 'budgets',
//                        description: 'Description.for.Budgets'
//                    },
//                    { id: 'budget_vs_actual_profit',
//                        item: $translate('Budget.vs.Actual.Profit'),
//                        state: 'budget_vs_actual_profit',
//                        description: 'Description.for.Budget.vs.Actual.Profit'
//                    },
//                    { id: 'notes',
//                        item: 'Notes',
//                        state: 'notes',
//                        description: 'Description.for.Notes'
//                    },
//                    { id: 'emails',
//                        item: 'Emails',
//                        state: 'emails',
//                        description: 'Description.for.Emails'
//                    },
//                    { id: 'prints',
//                        item: 'Prints',
//                        state: 'prints',
//                        description: 'Description.for.Prints'
//                    },
//                    { id: 'SMS',
//                        item: 'SMS',
//                        state: 'SMS',
//                        description: 'Description.for.SMS'
//                    },
//                    { id: 'phone_calls',
//                        item: $translate('Phone.Calls'),
//                        state: 'phone_calls',
//                        description: 'Description.for.Phone.Calls'
//                    },
//                    { id: 'attached_files',
//                        item: $translate('Attached.Files'),
//                        state: 'attached_files',
//                        description: 'Description.for.Attached.Files'
//                    },
                    { id: 'accepted_vs_rejected_estimates',
                        item: $translate('Accepted.vs.Rejected.Estimates'),
                        state: 'accepted_vs_rejected_estimates',
                        description: 'Description.for.Accepted.vs.Rejected.Estimates',
                        feature: 'Basicestimatereports'
                    },
                    { id: 'rejected_reasons',
                        item: $translate('Rejected.Reasons'),
                        state: 'rejected_reasons',
                        description: 'Description.for.Rejected.Reasons',
                        feature: 'Basicestimatereports'
                    }
                ]
            },
            { id: 'jobs',
                firstTier: 'Jobs',
                moduleFeature: 'jobReports',
                secondTier: [
                    { id: 'jobs',
                        item: 'Jobs',
                        state: 'jobs',
                        description: 'Description.for.Jobs',
                        feature: 'Basicjobreports'
                    },
//                    { id: 'to-dos',
//                        item: 'To-dos',
//                        state: 'to-dos',
//                        description: 'Description.for.To-dos'
//                    },
                    { id: 'additional_works',
                        item: $translate('Additional.Works'),
                        state: 'additional_works',
                        description: 'Description.for.Additional.Works',
                        feature: 'Basicjobreports'
                    },
//                    { id: 'recalls',
//                        item: 'Recalls',
//                        state: 'recalls',
//                        description: 'Description.for.Recalls'
//                    },
//                    { id: 'job_costs',
//                        item: $translate('Job.Costs'),
//                        state: 'job_costs',
//                        description: 'Description.for.Job.Costs'
//                    },
//                    { id: 'job_costs_by_category',
//                        item: $translate('Job.Costs.by.Category'),
//                        state: 'job_costs_by_category',
//                        description: 'Description.for.Job.Costs.by.Category'
//                    },
//                    { id: 'notes',
//                        item: 'Notes',
//                        state: 'notes',
//                        description: 'Description.for.Notes'
//                    },
//                    { id: 'attached_files',
//                        item: $translate('Attached.Files'),
//                        state: 'attached_files',
//                        description: 'Description.for.Attached.Files'
//                    },
//                    { id: 'engineer_jobs_by_month',
//                        item: $translate('Engineer.Jobs.by.Month'),
//                        state: 'engineer_jobs_by_month',
//                        description: 'Description.for.Debtors'
//                    },
                    { id: 'free_of_charge_jobs',
                        item: $translate('Free.of.Charge.Jobs'),
                        state: 'free_of_charge_jobs',
                        description: 'Description for.Free.of.Charge.Jobs',
                        feature: 'Basicjobreports'
                    },
                    { id: 'aborted_jobs',
                        item: $translate('Aborted.Jobs'),
                        state: 'aborted_jobs',
                        description: 'Description.for.Aborted.Jobs',
                        feature: 'Basicjobreports'
                    },
//                    { id: 'on_contract_plan_jobs',
//                        item: $translate('On.Contract.Plan.Jobs'),
//                        state: 'on_contract_plan_jobs',
//                        description: 'Description.for.Debtors'
//                    },
//                    { id: 'jobs_by_complete_type',
//                        item: $translate('Jobs.by.Complete.Type'),
//                        state: 'jobs_by_complete_type',
//                        description: 'Description.for.Jobs.by.Complete.Type'
//                    },
//                    { id: 'customer_job_requests',
//                        item: $translate('Customer.Job.Requests'),
//                        state: 'customer_job_requests',
//                        description: 'Description.for.Customer.Job.Requests'
//                    },
//                    { id: 'number_of_jobs_by_type_vs_administrative_effort',
//                        item: $translate('Number.of.Jobs.by.Type.vs.Administrative.Effort'),
//                        state: 'number_of_jobs_by_type_vs_administrative_effort',
//                        description: 'Description.for.Number.of.Jobs.by.Type.vs.Administrative.Effort'
//                    },
//                    { id: 'number_of_jobs_by_type_vs_diary_visits',
//                        item: $translate('Number.of.Jobs.by.Type.vs.Diary.Visits'),
//                        state: 'number_of_jobs_by_type_vs_diary_visits',
//                        description: 'Description.for.Number.of.Jobs.by.Type.vs.Diary.Visits'
//                    }
                ]
            },
//            { id: 'certificates',
//                firstTier: 'Certificates',
//                secondTier: [
//                    { id: 'domestic_gas_safety_certificates',
//                        item: $translate('Domestic.Gas.Safety.Certificates'),
//                        state: 'domestic_gas_safety_certificates',
//                        description: 'Description.for.Domestic.Gas.Safety.Certificates.'
//                    },
//                    { id: 'non-Domestic_gas_safety_certificates',
//                        item: $translate('Non-Domestic.Gas.Safety.Certificates'),
//                        state: 'Domestic_gas_safety_certificates',
//                        description: 'Description.for.Non-Domestic.Gas.Safety.Certificates'
//                    },
//                    { id: 'service_and_maintenance_certificates',
//                        item: $translate('Service.and.Maintenance.Certificates'),
//                        state: 'service_and_maintenance_certificates',
//                        description: 'Description.for.Service.and.Maintenance.Certificates'
//                    },
//                    { id: 'customers_with_expired_CP12',
//                        item: $translate('Customers.with.Expired.CP12'),
//                        state: 'customers_with_expired_CP12',
//                        description: 'Description.for.Customers.with.Expired.CP12'
//                    },
//                    { id: 'customers_with_expiring_CP12',
//                        item: $translate('Customers.with.Expiring.CP12'),
//                        state: 'customers_with_expiring_CP12',
//                        description: 'Description.for.Customers.with.Expiring.CP12'
//                    }
//                ]
//            },
            { id: 'parts',
                firstTier: 'Parts',
               moduleFeature: 'partReports',
                secondTier: [
                    { id: 'parts',
                        item: 'Parts',
                        state: 'parts',
                        description: 'Description.for.Parts',
                        feature: 'Basicpartsreports'
                    },
                    { id: 'partsInstalled',
                        item: $translate('Number.Of.Parts.Installed'),
                        state: 'partsInstalled',
                        description: $translate('Description.for.Number.of.Parts.Installed'),
                        feature: 'Basicpartsreports'
                    },
                    { id: 'parts_operational_list',
                        item: $translate('Parts.Operational.List'),
                        customState: 'loggedin.reporting.operational',
                        customStateParams: {category: 'parts', subcategory: 'parts', type: 'requested'},
                        description: $translate('Description.for.Parts.Operational.List'),
                        feature: 'Basicpartsreports'
                    }
//                    { id: 'part_delivery_time',
//                        item: $translate('Part.Delivery.Time'),
//                        state: 'part_delivery_time',
//                        description: 'Description.for.Part.Delivery.Time'
//                    },
//                    { id: 'part_requests_by_engineer',
//                        item: $translate('Part.Requests.by.Engineer'),
//                        state: 'part_requests_by_engineer',
//                        description: 'Description.for.Part.Requests.by.Engineer'
//                    },
//                    { id: 'part_orders_by_user',
//                        item: $translate('Part.Orders.by.User'),
//                        state: 'part_orders_by_user',
//                        description: 'Description.for.Part.Orders.by.User'
//                    }
                ]
            },
            /*{ id: 'projects',
                firstTier: 'Projects',
                secondTier: [
                    { id: 'projects',
                        item: 'Projects',
                        state: 'projects',
                        description: 'Description.for.Projects'
                    }
                    { id: 'budgets',
                        item: 'Budgets',
                        state: 'budgets',
                        description: 'Description.for.Budgets'
                    },
                    { id: 'planned_todos',
                        item: $translate('Planned.Todos'),
                        state: 'planned_todos',
                        description: 'Description.for.Planned.Todos'
                    },
                    { id: 'attached_files',
                        item: $translate('Attached.Files'),
                        state: 'attached_files',
                        description: 'Description.for.Attached.Files'
                    },
                    { id: 'sales_performance_by_sales_person',
                        item: $translate('Sales.Performance.by.Sales.Person'),
                        state: 'sales_performance_by_sales_person',
                        description: 'Description.for.Sales.Performance.by.Sales.Person'
                    }
                ]
            },*/
            { id: 'diary_events',
                firstTier: 'Diary Events',
                moduleFeature: 'diaryReports',
                secondTier: [
                    { id: 'diary_events',
                        item: $translate('Diary.Events'),
                        state: 'diary_events',
                        description: 'Description.for.Diary.Events',
                        feature: 'Basicdiaryreports'
                    },
//                    { id: 'predicted_hours_worked_by_month',
//                        item: $translate('Predicted.Hours.Worked.by.Month'),
//                        state: 'predicted_hours_worked_by_month',
//                        description: 'Description.for.Predicted.Hours.Worked.by.Month'
//                    },
//                    { id: 'engineer_feedback',
//                        item: $translate('Engineer.Feedback'),
//                        state: 'engineer_feedback',
//                        description: 'Description.for.Engineer.Feedback',
//                        feature: 'Basicdiaryreports'
//                    }
                ]
            },
            { id: 'sales',
                firstTier: 'Sales',
                moduleFeature: 'salesReports',
                secondTier: [
                    { id: 'sales',
                        item: 'Sales',
                        state: 'sales',
                        description: 'Description.for.Sales',
                        feature: 'Basicsalesreports'
                    },
                    { id: 'Customer_Payments',
                        item: $translate('Customer.Payments'),
                        state: 'Customer_Payments',
                        description: 'Description.for.Customer.Payments',
                        feature: 'Basicsalesreports'
                    },
//                    { id: 'sales_by_line_item',
//                        item: $translate('Sales.by.Line.Item'),
//                        state: 'sales_by_line_item',
//                        description: 'Description.for.Sales.by.Line.Item'
//                    },
//                    { id: 'sales_by_category_by_month',
//                        item: $translate('Sales.by.Category.by.Month'),
//                        state: 'sales_by_category_by_month',
//                        description: 'Description.for.Sales.by.Category.by.Month'
//                    },
//                    { id: 'sales_and_CIS',
//                        item: $translate('Sales.and.CIS'),
//                        state: 'sales_and_CIS',
//                        description: 'Description.for.Sales.and.CIS'
//                    },
//                    { id: 'payments',
//                        item: 'Payments',
//                        state: 'payments',
//                        description: 'Description.for.Payments',
//                        feature: 'Basicsalesreports'
//                    },
                    /*{ id: 'invoices_not_sent',
                        item: $translate('Invoices.not.Sent'),
                        state: 'invoices_not_sent',
                        description: 'Description.for.Invoices.not.Sent'
                    }*/
                ]
            },
            { id: 'purchase_orders',
                firstTier: $translate('Purchase.Orders'),
                moduleFeature: 'purchaseOrderReports',
                secondTier: [
                    { id: 'purchase_orders',
                        item: $translate('Purchase.Orders'),
                        state: 'purchase_orders',
                        description: 'Description.for.Purchase.Orders',
                        feature: 'Basicpurchaseorderreports'
                    }
                ]
            },
            { id: 'expenses',
                firstTier: 'Expenses',
                moduleFeature: 'expenseReports',
                secondTier: [
                    { id: 'supplier_invoices',
                        item: $translate('Supplier.Invoices'),
                        state: 'supplier_invoices',
                        description: 'Description.for.Supplier.Invoices',
                        feature: 'Basicexpensereports'
                    },
//                    { id: 'supplier_invoices_by_line_item',
//                        item: $translate('Supplier.Invoices.by.Line.Item'),
//                        state: 'supplier_invoices_by_line_item',
//                        description: 'Description.for.Supplier.Invoices.by.Line.Item'
//                    },
                    { id: 'payments',
                        item: 'Payments',
                        state: 'payments',
                        description: 'Description.for.Payments',
                        feature: 'Basicexpensereports'
                    }
//                    { id: 'PO_vs_supplier_invoice',
//                        item: $translate('PO.vs.Supplier.Invoice'),
//                        state: 'PO_vs_supplier_invoice',
//                        description: 'Description.for.PO.vs.Supplier.Invoice'
//                    }
                ]
            },
            { id: 'profit',
                firstTier: 'Profit',
                moduleFeature: 'profitReports',
                secondTier: [
                    { id: 'profit_by_job',
                        item: $translate('Profit.by.Job'),
                        state: 'profit_by_job',
                        description: 'Description.for.Profit.by.Job',
                        feature: 'Basicprofitreports'
                    },
//                    { id: 'profit_by_customer',
//                        item: 'Profit.by.Customer',
//                        state: 'profit_by_customer',
//                        description: 'Description.for.Profit.by.Customer'
//                    },
//                    { id: 'profit_by_job_type',
//                        item: $translate('Profit.by.Job.Type'),
//                        state: 'profit_by_job_type',
//                        description: 'Description.for.Profit.by.Job.Type'
//                    },
//                    { id: 'profit_by_invoice_category',
//                        item: $translate('Profit.by.Invoice.Category'),
//                        state: 'profit_by_invoice_category',
//                        description: 'Description.for.Profit.by.Invoice.Category'
//                    },
                    /*{ id: 'profit_by_engineer',
                        item: $translate('Profit.by.Engineer'),
                        state: 'profit_by_engineer',
                        description: 'Description.for.Profit.by.Engineer'
                    }*/
//                    { id: 'profit_by_town',
//                        item: $translate('Profit.by.Town'),
//                        state: 'profit_by_town',
//                        description: 'Description.for.Profit.by.Town'
//                    }
                ]
            },
            { id: 'stock_control',
                firstTier: $translate('Stock.Control'),
                moduleFeature: 'stockControlReports',
                secondTier: [
                    { id: 'stock_items',
                        item: $translate('Stock.Items'),
                        state: 'stock_items',
                        description: 'Description.for.Stock.Items',
                        feature: 'Advancedstockcontrolreports'
                    }
//                    { id: 'current_stock_levels',
//                        item: $translate('Current.Stock.Levels'),
//                        state: 'current_stock_levels',
//                        description: 'Description.for.Current.Stock.Levels',
//                        feature: 'Advancedstockcontrolreports'
//                    }
//                    { id: 'stock_activity',
//                        item: $translate('Stock.Activity'),
//                        state: 'stock_activity',
//                        description: 'Description.for.Stock.Activity'
//                    }
                ]
            },
            { id: 'users',
                firstTier: 'Users',
                moduleFeature: 'userReports',
                secondTier: [
                    { id: 'user_list',
                        item: $translate('User.List'),
                        state: 'user_list',
                        description: 'Description.for.User.List',
                        feature: 'Basicusersreports'
                    },
//                    { id: 'holiday_requests',
//                        item: $translate('Holiday.Requests'),
//                        state: 'holiday_requests',
//                        description: 'Description.for.Holiday.Requests'
//                    },
//                    { id: 'remaining_holiday_entitlement',
//                        item: $translate('Remaining.Holiday.Entitlement'),
//                        state: 'remaining_holiday_entitlement',
//                        description: 'Description.for.Remaining.Holiday.Entitlement'
//                    },
                    { id: 'time_sheets',
                        item: $translate('Time.Sheets'),
                        state: 'time_sheets',
                        description: 'Description.for.Time.Sheets.',
                        feature: 'Basicusersreports'
                    },
                    { id: 'office_tasks',
                        item: $translate('Office.Tasks'),
                        state: 'office_tasks',
                        description: 'Description.for.Office.Tasks',
                        feature: 'Basicofficetaskreports'
                    },
//                    { id: 'reminders',
//                        item: 'Reminders',
//                        state: 'reminders',
//                        description: 'Description.for.Reminders'
//                    },
//                    { id: 'emergency_contacts',
//                        item: $translate('Emergency.Contacts'),
//                        state: 'emergency_contacts',
//                        description: 'Description.for.Emergency.Contacts'
//                    },
//                    { id: 'attached_files',
//                        item: $translate('Attached.Files'),
//                        state: 'attached_files',
//                        description: 'Description.for.Attached.Files'
//                    },
//                    { id: 'profit_by_engineer',
//                        item: $translate('Profit.by.Engineer'),
//                        state: 'profit_by_engineer',
//                        description: 'Description.for.Profit.by.engineer'
//                    },
//                    { id: 'profit_by_hour_by_engineer',
//                        item: $translate('Profit.by.Hour.by.Engineer'),
//                        state: 'profit_by_hour_by_engineer',
//                        description: 'Description.for.Profit.by.Hour.by.Engineer'
//                    },
                    {
                        id: 'gps_leaderboard',
                        item: 'GPS Leaderboard',
                        state: 'gps_leaderboard',
                        description: 'Description.for.Gps.Leaderboard.Report',
                        feature: 'Vehiclereports'
                    },
                    {
                        id: 'driver_trips',
                        item: 'Driver Trips',
                        state: 'driver_trips',
                        description: 'Description.for.Driver.Trips.Report',
                        feature: 'Vehiclereports'
                    },
                    {
                        id: 'driver_detail',
                        item: 'Driver Detail',
                        state: 'driver_detail',
                        description: 'Description.for.Driver.Detail.Report',
                        feature: 'Vehiclereports'
                    }
                ]
            },
//            { id: 'progress',
//                firstTier: 'Progress',
//                secondTier: [
//                    { id: 'user_commusoft_learning_progress',
//                        item: $translate('User.Commusoft.learning.progress'),
//                        state: 'user_commusoft_learning_progress',
//                        description: 'Description.for.Debtors'
//                    }
//                ]
//            },
            { id: 'debtors',
                firstTier: 'Debtors',
                moduleFeature: 'debtorsReports',
                secondTier: [
                    { id: 'debtors',
                        item: 'Debtors',
                        state: 'debtors',
                        description: 'Description.for.Debtors',
                        feature: 'Basicdebtorsreports'
                    }
//                    { id: 'unpaid_invoices',
//                        item: $translate('Unpaid.Invoices'),
//                        state: 'unpaid_invoices',
//                        description: 'Description.for.Unpaid.Invoices'
//                    }
                ]
            },
            { id: 'creditors',
                firstTier: 'Creditors',
                moduleFeature: 'creditorsReports',
                secondTier: [
                    { id: 'creditors',
                        item: 'Creditors',
                        state: 'creditors', //If you change this, need to change in reporting controller too
                        description: 'Description.for.Creditors',
                        feature: 'Basiccreditorsreports'
                    }
//                    { id: 'unpaid_invoices',
//                        item: $translate('Unpaid.Invoices'),
//                        state: 'unpaid_invoices',
//                        description: 'Description.for.Unpaid.Invoices'
//                    }
                ]
            }
        ];

        this.getReportingSubnavItems = function () {
            return $http.get(prefix + '/get_saved_queries').then(function(data) {
                var extraData = data.data;
                var clonedNav = angular.copy(reportsubnavItems);
                angular.forEach(clonedNav, function(val, key) {
                    angular.forEach(extraData, function(extraDataVal, extraDataKey) {
                        if (val['id'] === extraDataVal['id']) {
                            angular.forEach(extraDataVal['secondTier'], function(secondTierVal, secondTierKey) {
                                val['secondTier'].push(secondTierVal);
                            })
                        }
                    })
                });
                return clonedNav;
            });

        };

        this.getMenuItems = function(extraData) {
            var clonedNav = angular.copy(reportsubnavItems);
            angular.forEach(clonedNav, function(val, key) {
                angular.forEach(extraData, function(extraDataVal, extraDataKey) {
                    if (val['id'] === extraDataVal['id']) {
                        angular.forEach(extraDataVal['secondTier'], function(secondTierVal, secondTierKey) {
                            val['secondTier'].push(secondTierVal);
                        })
                    }
                })
            });
            return clonedNav;
        }

        this.getTitle = function (id, option, extraData) {
            var menuItems = this.getMenuItems(extraData);
            for(var index = 0; index < menuItems.length; index++) {
                if(menuItems[index].id == id) {
                    return menuItems[index][option];
                }
            };
            return false;
        };

        this.getSecondTierDetails = function (id, secondTierId, extraData) {
            var secondTier = this.getTitle(id, 'secondTier', extraData);
            if(secondTier.length > 0) {
                for(var i = 0; i < secondTier.length; i++) {
                    if (secondTier[i].id == secondTierId) {
                        return secondTier[i];
                    }
                }
                return false;
            }
        };
    })
    .service('hasCheckPermission', function() {
        this.companyGeneralSettings = [{'featureName' : 'Companydetails','featureAccess' : 'updateaccess'},{'featureName' : 'Cispercentage','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setupemail','featureAccess' : 'readaccess'},{'featureName' : 'Setupletterhead','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setuplogocertificate','featureAccess' : 'writeaccess'},{'featureName' : 'Vatandcompanynumbers','featureAccess' : 'updateaccess'},
            {'featureName' : 'Bankdetails','featureAccess' : 'updateaccess'},{'featureName' : 'Chequesmadepayable','featureAccess' : 'updateaccess'},
            {'featureName' : 'Importingdata','featureAccess' : 'writeaccess'}, {'featureName': 'SetupBrandingColours', 'featureAccess': 'updateaccess'}];

        this.companyUsersAndVehicles = [{'featureName' : 'Users','featureAccess' : 'updateaccess'},{'featureName' : 'Vehicle','featureAccess' : 'readaccess'},{'featureName' : 'Setuptravelrate','featureAccess' : 'readaccess'}];

        this.companyAudits = [{'featureName' : 'Audits','featureAccess' : 'readaccess'}];

//        this.companyAccountAndBilling = [{'featureName' : 'CommusoftInvoices','featureAccess' : 'updateaccess'}];
        this.commusoftInvoices = [{'featureName' : 'Commusoftinvoices','featureAccess' : 'readaccess'}];

        this.companyIntegrations = [{'featureName' : 'Apikeys','featureAccess' : 'updateaccess'},{'featureName' : 'Setupaccountinginterface','featureAccess' : 'readaccess'},{'featureName' : 'Setupcreditcard','featureAccess' : 'updateaccess'},{'featureName' : 'Setupmailchimpintegration','featureAccess' : 'updateaccess'},{'featureName': 'Setupnaturalformintegration', 'featureAccess': 'updateaccess'},{'featureName': 'Setupjdpintegration', 'featureAccess': 'updateaccess'}];
        this.backups = [{'featureName': 'Backups', 'featureAccess': 'writeaccess'}];
        this.integrations = [{'featureName': 'Setupmailchimpintegration', 'featureAccess': 'updateaccess'},{'featureName': 'Setupnaturalformintegration', 'featureAccess': 'updateaccess'},{'featureName': 'Setupdocparserintegration', 'featureAccess': 'updateaccess'},{'featureName': 'Setupjdpintegration', 'featureAccess': 'updateaccess'}];

        this.systemGeneralSettings = [{'featureName' : 'Setupdiary','featureAccess' : 'updateaccess'},{'featureName' : 'Setuplocations','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupfueltypes','featureAccess' : 'writeaccess'},{'featureName' : 'Setupappliancegroups','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupappliancetypes','featureAccess' : 'writeaccess'},{'featureName' : 'Setupappliancemake','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupmodels','featureAccess' : 'writeaccess'},{'featureName' : 'Setuptechnicalreference','featureAccess' : 'writeaccess'},
            {'featureName' : 'Pricingitems','featureAccess' : 'writeaccess'},{'featureName' : 'Setupnominalgroups','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupnominalaccounts','featureAccess' : 'writeaccess'},{'featureName' : 'Setuptypeoftelephonenumbers','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupcustomertitles','featureAccess' : 'writeaccess'},{'featureName' : 'Setupvat','featureAccess' : 'writeaccess'},{'featureName' : 'Bccemails','featureAccess' : 'updateaccess'}];

        this.systemCustomerInformation = [{'featureName' : 'Setupfirstcustomeraccountnumber','featureAccess' : 'updateaccess'},{'featureName' : 'Setupcustomertypes','featureAccess' : 'writeaccess'},
            {'featureName' : 'SetupContractPlans','featureAccess' : 'writeaccess'},{'featureName' : 'Setupadvertising','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupcreditordays','featureAccess' : 'writeaccess'},{'featureName' : 'Setuptypesofbuildings','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupcustomersmstemplates','featureAccess' : 'writeaccess'}];

        this.systemEstimates = [{'featureName' : 'Setupfirstestimatenumber','featureAccess' : 'updateaccess'},{'featureName' : 'SetupEstimateTemplates','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupestimatenote','featureAccess' : 'updateaccess'},{'featureName' : 'Setupengineerestimatesheetemailmessage','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setupengineerestimatesms','featureAccess' : 'updateaccess'},{'featureName' : 'Setupcustomerestimateconfirmation','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setupworkaddressestimateconfirmation','featureAccess' : 'updateaccess'},{'featureName' : 'Setupworkaddressestimatereminder','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setupestimatedefaultemailmessage','featureAccess' : 'updateaccess'},{'featureName' : 'Onlineestimateportal','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setuptermsandconditionforestimate','featureAccess' : 'updateaccess'}];
        this.systemJobs = [{'featureName' : 'Setupfirstjobnumber','featureAccess' : 'updateaccess'},{'featureName' : 'Setupjobdescriptions','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupjobsheetdesign','featureAccess' : 'updateaccess'},{'featureName' : 'Setupjobsummarydesign','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setupengineeremailjobsheetmessage','featureAccess' : 'updateaccess'},{'featureName' : 'Setupengineerjobsheetsms','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setupcustomerjobconfirmation','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setupworkaddressjobconfirmation','featureAccess' : 'updateaccess'},{'featureName' : 'Setupworkaddressjobreminder','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setupfreeofchargereasons','featureAccess' : 'writeaccess'},{'featureName' : 'Setupabortreasons','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setuprecallreason','featureAccess' : 'updateaccess'}];

        this.systemCertificates = [{'featureName' : 'Setupthecertificateemailmessage','featureAccess' : 'updateaccess'},{'featureName' : 'Setupthecertificateprintandpostletter','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupcustomcertificate','featureAccess' : 'writeaccess'}];

        this.systemServiceReminders = [{'featureName' : 'Setupservicereminders','featureAccess' : 'writeaccess'}];

        this.systemInvoicing = [{'featureName' : 'Configurethewayinvoicenumbersaregenerated','featureAccess' : 'updateaccess'},{'featureName' : 'Setuptheinvoicetemplatedesign','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupinvoicecategories','featureAccess' : 'writeaccess'},{'featureName' : 'Customiseinvoiceitemcategories','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setuppaymentmethods','featureAccess' : 'writeaccess'},{'featureName' : 'Setupinvoicenote','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setupinvoicetermsandconditions','featureAccess' : 'updateaccess'},{'featureName' : 'Setupaftersalescommunication','featureAccess' : 'updateaccess'},
            {'featureName' : 'Defaultstatementmessage','featureAccess' : 'updateaccess'},{'featureName' : 'Setuppaymentreceiptemailmessage','featureAccess' : 'updateaccess'}];

        this.systemSuppliers = [{'featureName' : 'Setuppurchaseorderemailmessage','featureAccess' : 'updateaccess'}];

        this.systemPartsManagement = [{'featureName' : 'Setupindustries','featureAccess' : 'writeaccess'},{'featureName' : 'Setuppartscategories','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupsub-categories','featureAccess' : 'writeaccess'},{'featureName' : 'Setuppartsandprices','featureAccess' : 'writeaccess'},{'featureName' : 'Setuppartkit','featureAccess' : 'writeaccess'},{'featureName' : 'Supplierpartsquotationemailmessage','featureAccess' : 'updateaccess'}];

        this.systemStockControl = [{'featureName' : 'Setupstocklocations','featureAccess' : 'writeaccess'}];

        this.systemEmployeeAndVehicles = [{'featureName' : 'Setupengineerqualifications','featureAccess' : 'writeaccess'},{'featureName' : 'Setupusergroups','featureAccess' : 'writeaccess'},
            {'featureName' : 'Vehiclesparameters','featureAccess' : 'writeaccess'}];

        this.systemMobileSettings = [{'featureName' : 'Setupestimatearrivesignaturemessage','featureAccess' : 'updateaccess'},{'featureName' : 'Setupestimateleavesignaturemessage','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setupengineerontheirwaysmsforestimates','featureAccess' : 'updateaccess'},{'featureName' : 'Setupestimatearriveonsitequestions','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupleaveestimatequestions','featureAccess' : 'writeaccess'},{'featureName' : 'Customisenoaccessmessageforestimates','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setupjobarrivesignaturemessage','featureAccess' : 'updateaccess'},{'featureName' : 'Setupjobleavesignaturemessage','featureAccess' : 'updateaccess'},
            {'featureName' : 'Setupengineerontheirwayforjobs','featureAccess' : 'updateaccess'},{'featureName' : 'Setupjobarriveonsitequestions','featureAccess' : 'writeaccess'},
            {'featureName' : 'Setupleavejobquestions','featureAccess' : 'writeaccess'},{'featureName' : 'Customisenoaccessmessageforjobs','featureAccess' : 'updateaccess'}];

        this.systemOnlinePortals = [{'featureName' : 'Setupminiappforwebsite','featureAccess' : 'updateaccess'},{'featureName' : 'Setupdefaultcustomerloginemailmessage','featureAccess' : 'updateaccess'}];

        this.estimateQuickLinks = [{'featureName' : 'Estimates','featureAccess' : 'writeaccess'},{'featureName' : 'Estimatereservelist','featureAccess' : 'writeaccess'},{'featureName' : 'Estimatedetails','featureAccess' : 'writeaccess'}];

        this.reportings = [{'featureName' : 'Basiccustomerreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Servicereminderreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Advancedcustomerreporting', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicservicereminderreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Advancedservicereminderreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicsupplierreports', 'featureAccess' : 'readaccess'},
            //{'featureName' : 'Advancedsupplierreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicjobreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Advancedjobreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basiccertificatereports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Advancedcertificatereports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicestimatereports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Advancedestimatereports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicpartsreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Advancedpartsreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Advancedprojectreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicdiaryreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Advanceddiaryreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicsalesreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Advancedsalesreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicpurchaseorderreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Advancedpurchaseorderreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicexpensereports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Advancedexpensereports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicprofitreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Advancedstockcontrolreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicusersreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicdebtorsreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicofficetaskreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basiccreditorsreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Vehiclereports', 'featureAccess' : 'readaccess'}];
        this.financialQuickLinks = [{'featureName' : 'Invoiceaddresses','featureAccess' : 'readaccess'},{'featureName' : 'Customerpayments','featureAccess' : 'writeaccess'},{'featureName' : 'CustomerCreditNote','featureAccess' : 'writeaccess'},{'featureName' : 'Customerpayments','featureAccess' : 'readaccess'}];
        this.serviceReminderQuickLinks = [{'featureName' : 'Workaddressremindersdue','featureAccess' : 'readaccess'}];
        this.customerSettingsQuickLinks = [{'featureName' : 'Customerinvoicesetup','featureAccess' : 'updateaccess'}];
        this.adminToolsQuickLinks = [{'featureName' : 'Customersandworkaddresses','featureAccess' : 'deleteaccess','exportCustomerData' : 'readaccess'}];
        this.jobGeneralQuickLinks = [{'featureName' : 'Jobsplacedonhold','featureAccess' : 'writeaccess'},{'featureName' : 'Jobsplacedonhold','featureAccess' : 'deleteaccess'},{'featureName' : 'Jobreservelist','featureAccess' : 'writeaccess'},{'featureName' : 'Jobreport','featureAccess' : 'readaccess'}];
        this.jobFinancialQuickLinks = [{'featureName' : 'Completejobasfreeofcharge','featureAccess' : 'writeaccess'}];
        this.jobAdminQuickLinks = [{'featureName' : 'Recalljob','featureAccess' : 'writeaccess'},{'featureName' : 'Completejobasaborted','featureAccess' : 'writeaccess'},{'featureName' : 'Jobs','featureAccess' : 'deleteaccess'}];
        this.customerReports = [{'featureName' : 'Basiccustomerreports', 'featureAccess' : 'readaccess'}];
        this.serviceReminderReports = [{'featureName' : 'Basicservicereminderreports', 'featureAccess' : 'readaccess'}];
        this.diaryReports = [{'featureName' : 'Basicdiaryreports', 'featureAccess' : 'readaccess'}];
        this.supplierReports = [{'featureName' : 'Basicsupplierreports', 'featureAccess' : 'readaccess'}];
        this.purchaseOrderReports = [{'featureName' : 'Basicpurchaseorderreports', 'featureAccess' : 'readaccess'}];
        this.expenseReports = [{'featureName' : 'Basicexpensereports', 'featureAccess' : 'readaccess'}];
        this.profitReports = [{'featureName' : 'Basicprofitreports', 'featureAccess' : 'readaccess'}];
        this.creditorsReports = [{'featureName' : 'Basiccreditorsreports', 'featureAccess' : 'readaccess'}];
        this.estimateReports = [{'featureName' : 'Basicestimatereports', 'featureAccess' : 'readaccess'}];
        this.jobReports = [{'featureName' : 'Basicjobreports', 'featureAccess' : 'readaccess'}];
        this.partReports = [{'featureName' : 'Basicpartsreports', 'featureAccess' : 'readaccess'}];
        this.salesReports = [{'featureName' : 'Basicsalesreports', 'featureAccess' : 'readaccess'}];
        this.debtorsReports = [{'featureName' : 'Basicdebtorsreports', 'featureAccess' : 'readaccess'}];
        this.stockControlReports = [{'featureName' : 'Advancedstockcontrolreports', 'featureAccess' : 'readaccess'}];
        this.userReports = [
            {'featureName' : 'Basicusersreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Basicofficetaskreports', 'featureAccess' : 'readaccess'},
            {'featureName' : 'Vehiclereports', 'featureAccess' : 'readaccess'}
            ];

    })
    .service('countries', function() {
        var countries = [
            {
                id: 1,
                name: 'United Kingdom',
                code: '44',
                image_name: "GB"
            },
            {
                "image_name": "AF",
                "code": "93",
                "id": 2,
                "name": "Afghanistan"
            },
            {
                "image_name": "AL",
                "code": "355",
                "id": 3,
                "name": "Albania"
            },
            {
                "image_name": "DZ",
                "code": "213",
                "id": 4,
                "name": "Algeria"
            },
            {
                "image_name": "AD",
                "code": "376",
                "id": 5,
                "name": "Andorra"
            },
            {
                "image_name": "AO",
                "code": "244",
                "id": 6,
                "name": "Angola"
            },
            {
                "image_name": "AR",
                "code": "54",
                "id": 7,
                "name": "Argentina"
            },
            {
                "image_name": "AM",
                "code": "374",
                "id": 8,
                "name": "Armenia"
            },
            {
                "image_name": "AW",
                "code": "297",
                "id": 9,
                "name": "Aruba"
            },
            {
                "image_name": "AU",
                "code": "61",
                "id": 10,
                "name": "Australia"
            },
            {
                "image_name": "AT",
                "code": "43",
                "id": 11,
                "name": "Austria"
            },
            {
                "image_name": "AZ",
                "code": "994",
                "id": 12,
                "name": "Azerbaijan"
            },
            {
                "image_name": "BH",
                "code": "973",
                "id": 13,
                "name": "Bahrain"
            },
            {
                "image_name": "BD",
                "code": "880",
                "id": 14,
                "name": "Bangladesh"
            },
            {
                "image_name": "BY",
                "code": "375",
                "id": 15,
                "name": "Belarus"
            },
            {
                "image_name": "BE",
                "code": "32",
                "id": 16,
                "name": "Belgium"
            },
            {
                "image_name": "BZ",
                "code": "501",
                "id": 17,
                "name": "Belize"
            },
            {
                "image_name": "BJ",
                "code": "229",
                "id": 18,
                "name": "Benin"
            },
            {
                "image_name": "BT",
                "code": "975",
                "id": 19,
                "name": "Bhutan"
            },
            {
                "image_name": "BO",
                "code": "591",
                "id": 20,
                "name": "Bolivia, Plurinational State of"
            },
            {
                "image_name": "BA",
                "code": "387",
                "id": 21,
                "name": "Bosnia and Herzegovina"
            },
            {
                "image_name": "BW",
                "code": "267",
                "id": 22,
                "name": "Botswana"
            },
            {
                "image_name": "BR",
                "code": "55",
                "id": 23,
                "name": "Brazil"
            },
            {
                "image_name": "BN",
                "code": "673",
                "id": 24,
                "name": "Brunei Darussalam"
            },
            {
                "image_name": "BG",
                "code": "359",
                "id": 25,
                "name": "Bulgaria"
            },
            {
                "image_name": "BF",
                "code": "226",
                "id": 26,
                "name": "Burkina Faso"
            },
            {
                "image_name": "MM",
                "code": "95",
                "id": 27,
                "name": "Myanmar"
            },
            {
                "image_name": "BI",
                "code": "257",
                "id": 28,
                "name": "Burundi"
            },
            {
                "image_name": "KH",
                "code": "855",
                "id": 29,
                "name": "Cambodia"
            },
            {
                "image_name": "CM",
                "code": "237",
                "id": 30,
                "name": "Cameroon"
            },
            {
                "image_name": "CA",
                "code": "001",
                "id": 31,
                "name": "Canada"
            },
            {
                "image_name": "CV",
                "code": "238",
                "id": 32,
                "name": "Cape Verde"
            },
            {
                "image_name": "CF",
                "code": "236",
                "id": 33,
                "name": "Central African Republic"
            },
            {
                "image_name": "TD",
                "code": "235",
                "id": 34,
                "name": "Chad"
            },
            {
                "image_name": "CL",
                "code": "56",
                "id": 35,
                "name": "Chile"
            },
            {
                "image_name": "CN",
                "code": "86",
                "id": 36,
                "name": "China"
            },
            {
                "image_name": "CX",
                "code": "61",
                "id": 37,
                "name": "Christmas Island"
            },
            {
                "image_name": "CC",
                "code": "61",
                "id": 38,
                "name": "Cocos (Keeling) Islands"
            },
            {
                "image_name": "CO",
                "code": "57",
                "id": 39,
                "name": "Colombia"
            },
            {
                "image_name": "KM",
                "code": "269",
                "id": 40,
                "name": "Comoros"
            },
            {
                "image_name": "CG",
                "code": "242",
                "id": 41,
                "name": "Congo"
            },
            {
                "image_name": "CD",
                "code": "243",
                "id": 42,
                "name": "Congo, The Democratic Republic of the"
            },
            {
                "image_name": "CK",
                "code": "682",
                "id": 43,
                "name": "Cook Islands"
            },
            {
                "image_name": "CR",
                "code": "506",
                "id": 44,
                "name": "Costa Rica"
            },
            {
                "image_name": "HR",
                "code": "385",
                "id": 45,
                "name": "Croatia"
            },
            {
                "image_name": "CU",
                "code": "53",
                "id": 46,
                "name": "Cuba"
            },
            {
                "image_name": "CY",
                "code": "357",
                "id": 47,
                "name": "Cyprus"
            },
            {
                "image_name": "CZ",
                "code": "420",
                "id": 48,
                "name": "Czech Republic"
            },
            {
                "image_name": "DK",
                "code": "45",
                "id": 49,
                "name": "Denmark"
            },
            {
                "image_name": "DJ",
                "code": "253",
                "id": 50,
                "name": "Djibouti"
            },
            {
                "image_name": "TL",
                "code": "670",
                "id": 51,
                "name": "Timor-Leste"
            },
            {
                "image_name": "EC",
                "code": "593",
                "id": 52,
                "name": "Ecuador"
            },
            {
                "image_name": "EG",
                "code": "20",
                "id": 53,
                "name": "Egypt"
            },
            {
                "image_name": "SV",
                "code": "503",
                "id": 54,
                "name": "El Salvador"
            },
            {
                "image_name": "GQ",
                "code": "240",
                "id": 55,
                "name": "Equatorial Guinea"
            },
            {
                "image_name": "ER",
                "code": "291",
                "id": 56,
                "name": "Eritrea"
            },
            {
                "image_name": "EE",
                "code": "372",
                "id": 57,
                "name": "Estonia"
            },
            {
                "image_name": "ET",
                "code": "251",
                "id": 58,
                "name": "Ethiopia"
            },
            {
                "image_name": "FK",
                "code": "500",
                "id": 59,
                "name": "Falkland Islands (Malvinas)"
            },
            {
                "image_name": "FO",
                "code": "298",
                "id": 60,
                "name": "Faroe Islands"
            },
            {
                "image_name": "FJ",
                "code": "679",
                "id": 61,
                "name": "Fiji"
            },
            {
                "image_name": "FI",
                "code": "358",
                "id": 62,
                "name": "Finland"
            },
            {
                "image_name": "FR",
                "code": "33",
                "id": 63,
                "name": "France"
            },
            {
                "image_name": "PF",
                "code": "689",
                "id": 64,
                "name": "French Polynesia"
            },
            {
                "image_name": "GA",
                "code": "241",
                "id": 65,
                "name": "Gabon"
            },
            {
                "image_name": "GM",
                "code": "220",
                "id": 66,
                "name": "Gambia"
            },
            {
                "image_name": "GE",
                "code": "995",
                "id": 67,
                "name": "Georgia"
            },
            {
                "image_name": "DE",
                "code": "49",
                "id": 68,
                "name": "Germany"
            },
            {
                "image_name": "GI",
                "code": "350",
                "id": 69,
                "name": "Gibraltar"
            },
            {
                "image_name": "GR",
                "code": "30",
                "id": 70,
                "name": "Greece"
            },
            {
                "image_name": "GL",
                "code": "299",
                "id": 71,
                "name": "Greenland"
            },
            {
                "image_name": "GT",
                "code": "502",
                "id": 72,
                "name": "Guatemala"
            },
            {
                "image_name": "GN",
                "code": "224",
                "id": 73,
                "name": "Guinea"
            },
            {
                "image_name": "GW",
                "code": "245",
                "id": 74,
                "name": "Guinea-Bissau"
            },
            {
                "image_name": "GY",
                "code": "592",
                "id": 75,
                "name": "Guyana"
            },
            {
                "image_name": "HT",
                "code": "509",
                "id": 76,
                "name": "Haiti"
            },
            {
                "image_name": "HN",
                "code": "504",
                "id": 77,
                "name": "Honduras"
            },
            {
                "image_name": "HK",
                "code": "852",
                "id": 78,
                "name": "Hong Kong"
            },
            {
                "image_name": "HU",
                "code": "36",
                "id": 79,
                "name": "Hungary"
            },
            {
                "image_name": "IN",
                "code": "91",
                "id": 80,
                "name": "India"
            },
            {
                "image_name": "ID",
                "code": "62",
                "id": 81,
                "name": "Indonesia"
            },
            {
                "image_name": "IR",
                "code": "98",
                "id": 82,
                "name": "Iran, Islamic Republic of"
            },
            {
                "image_name": "IQ",
                "code": "964",
                "id": 83,
                "name": "Iraq"
            },
            {
                "image_name": "IE",
                "code": "353",
                "id": 84,
                "name": "Ireland"
            },
            {
                "image_name": "IL",
                "code": "972",
                "id": 85,
                "name": "Israel"
            },
            {
                "image_name": "IT",
                "code": "39",
                "id": 86,
                "name": "Italy"
            },
            {
                "image_name": "CI",
                "code": "225",
                "id": 87,
                "name": "C\u00f4te d'Ivoire"
            },
            {
                "image_name": "JP",
                "code": "81",
                "id": 88,
                "name": "Japan"
            },
            {
                "image_name": "JO",
                "code": "962",
                "id": 89,
                "name": "Jordan"
            },
            {
                "image_name": "KZ",
                "code": "7",
                "id": 90,
                "name": "Kazakhstan"
            },
            {
                "image_name": "KE",
                "code": "254",
                "id": 91,
                "name": "Kenya"
            },
            {
                "image_name": "KI",
                "code": "686",
                "id": 92,
                "name": "Kiribati"
            },
            {
                "image_name": "KW",
                "code": "965",
                "id": 93,
                "name": "Kuwait"
            },
            {
                "image_name": "KG",
                "code": "996",
                "id": 94,
                "name": "Kyrgyzstan"
            },
            {
                "image_name": "LA",
                "code": "856",
                "id": 95,
                "name": "Lao People's Democratic Republic"
            },
            {
                "image_name": "LV",
                "code": "371",
                "id": 96,
                "name": "Latvia"
            },
            {
                "image_name": "LB",
                "code": "961",
                "id": 97,
                "name": "Lebanon"
            },
            {
                "image_name": "LS",
                "code": "266",
                "id": 98,
                "name": "Lesotho"
            },
            {
                "image_name": "LR",
                "code": "231",
                "id": 99,
                "name": "Liberia"
            },
            {
                "image_name": "LY",
                "code": "218",
                "id": 100,
                "name": "Libya"
            },
            {
                "image_name": "LI",
                "code": "423",
                "id": 101,
                "name": "Liechtenstein"
            },
            {
                "image_name": "LT",
                "code": "370",
                "id": 102,
                "name": "Lithuania"
            },
            {
                "image_name": "LU",
                "code": "352",
                "id": 103,
                "name": "Luxembourg"
            },
            {
                "image_name": "MO",
                "code": "853",
                "id": 104,
                "name": "Macao"
            },
            {
                "image_name": "MK",
                "code": "389",
                "id": 105,
                "name": "Macedonia, Republic of"
            },
            {
                "image_name": "MG",
                "code": "261",
                "id": 106,
                "name": "Madagascar"
            },
            {
                "image_name": "MW",
                "code": "265",
                "id": 107,
                "name": "Malawi"
            },
            {
                "image_name": "MY",
                "code": "60",
                "id": 108,
                "name": "Malaysia"
            },
            {
                "image_name": "MV",
                "code": "960",
                "id": 109,
                "name": "Maldives"
            },
            {
                "image_name": "ML",
                "code": "223",
                "id": 110,
                "name": "Mali"
            },
            {
                "image_name": "MT",
                "code": "356",
                "id": 111,
                "name": "Malta"
            },
            {
                "image_name": "MH",
                "code": "692",
                "id": 112,
                "name": "Marshall Islands"
            },
            {
                "image_name": "MR",
                "code": "222",
                "id": 113,
                "name": "Mauritania"
            },
            {
                "image_name": "MU",
                "code": "230",
                "id": 114,
                "name": "Mauritius"
            },
            {
                "image_name": "YT",
                "code": "262",
                "id": 115,
                "name": "Mayotte"
            },
            {
                "image_name": "MX",
                "code": "52",
                "id": 116,
                "name": "Mexico"
            },
            {
                "image_name": "FM",
                "code": "691",
                "id": 117,
                "name": "Micronesia, Federated States of"
            },
            {
                "image_name": "MD",
                "code": "373",
                "id": 118,
                "name": "Moldova, Republic of"
            },
            {
                "image_name": "MC",
                "code": "377",
                "id": 119,
                "name": "Monaco"
            },
            {
                "image_name": "MN",
                "code": "976",
                "id": 120,
                "name": "Mongolia"
            },
            {
                "image_name": "ME",
                "code": "382",
                "id": 121,
                "name": "Montenegro"
            },
            {
                "image_name": "MA",
                "code": "212",
                "id": 122,
                "name": "Morocco"
            },
            {
                "image_name": "MZ",
                "code": "258",
                "id": 123,
                "name": "Mozambique"
            },
            {
                "image_name": "NA",
                "code": "264",
                "id": 124,
                "name": "Namibia"
            },
            {
                "image_name": "NR",
                "code": "674",
                "id": 125,
                "name": "Nauru"
            },
            {
                "image_name": "NP",
                "code": "977",
                "id": 126,
                "name": "Nepal"
            },
            {
                "image_name": "NL",
                "code": "31",
                "id": 127,
                "name": "Netherlands"
            },
            {
                "image_name": "NC",
                "code": "687",
                "id": 128,
                "name": "New Caledonia"
            },
            {
                "image_name": "NZ",
                "code": "64",
                "id": 129,
                "name": "New Zealand"
            },
            {
                "image_name": "NI",
                "code": "505",
                "id": 130,
                "name": "Nicaragua"
            },
            {
                "image_name": "NE",
                "code": "227",
                "id": 131,
                "name": "Niger"
            },
            {
                "image_name": "NG",
                "code": "234",
                "id": 132,
                "name": "Nigeria"
            },
            {
                "image_name": "NU",
                "code": "683",
                "id": 133,
                "name": "Niue"
            },
            {
                "image_name": "KP",
                "code": "850",
                "id": 134,
                "name": "Korea, Democratic People's Republic of"
            },
            {
                "image_name": "NO",
                "code": "47",
                "id": 135,
                "name": "Norway"
            },
            {
                "image_name": "OM",
                "code": "968",
                "id": 136,
                "name": "Oman"
            },
            {
                "image_name": "PK",
                "code": "92",
                "id": 137,
                "name": "Pakistan"
            },
            {
                "image_name": "PW",
                "code": "680",
                "id": 138,
                "name": "Palau"
            },
            {
                "image_name": "PA",
                "code": "507",
                "id": 139,
                "name": "Panama"
            },
            {
                "image_name": "PG",
                "code": "675",
                "id": 140,
                "name": "Papua New Guinea"
            },
            {
                "image_name": "PY",
                "code": "595",
                "id": 141,
                "name": "Paraguay"
            },
            {
                "image_name": "PE",
                "code": "51",
                "id": 142,
                "name": "Peru"
            },
            {
                "image_name": "PH",
                "code": "63",
                "id": 143,
                "name": "Philippines"
            },
            {
                "image_name": "PN",
                "code": "870",
                "id": 144,
                "name": "Pitcairn"
            },
            {
                "image_name": "PL",
                "code": "48",
                "id": 145,
                "name": "Poland"
            },
            {
                "image_name": "PT",
                "code": "351",
                "id": 146,
                "name": "Portugal"
            },
            {
                "image_name": "QA",
                "code": "974",
                "id": 148,
                "name": "Qatar"
            },
            {
                "image_name": "RO",
                "code": "40",
                "id": 149,
                "name": "Romania"
            },
            {
                "image_name": "IE",
                "code": "353",
                "id": 203,
                "name": "Republic of Ireland"
            },
            {
                "image_name": "RU",
                "code": "7",
                "id": 150,
                "name": "Russian Federation"
            },
            {
                "image_name": "RW",
                "code": "250",
                "id": 151,
                "name": "Rwanda"
            },
            {
                "image_name": "BL",
                "code": "590",
                "id": 152,
                "name": "Saint Barth\u00e9lemy"
            },
            {
                "image_name": "WS",
                "code": "685",
                "id": 153,
                "name": "Samoa"
            },
            {
                "image_name": "SM",
                "code": "378",
                "id": 154,
                "name": "San Marino"
            },
            {
                "image_name": "ST",
                "code": "239",
                "id": 155,
                "name": "Sao Tome and Principe"
            },
            {
                "image_name": "SA",
                "code": "966",
                "id": 156,
                "name": "Saudi Arabia"
            },
            {
                "image_name": "SN",
                "code": "221",
                "id": 157,
                "name": "Senegal"
            },
            {
                "image_name": "RS",
                "code": "381",
                "id": 158,
                "name": "Serbia"
            },
            {
                "image_name": "SC",
                "code": "248",
                "id": 159,
                "name": "Seychelles"
            },
            {
                "image_name": "SL",
                "code": "232",
                "id": 160,
                "name": "Sierra Leone"
            },
            {
                "image_name": "SG",
                "code": "65",
                "id": 161,
                "name": "Singapore"
            },
            {
                "image_name": "SK",
                "code": "421",
                "id": 162,
                "name": "Slovakia"
            },
            {
                "image_name": "SI",
                "code": "386",
                "id": 163,
                "name": "Slovenia"
            },
            {
                "image_name": "SB",
                "code": "677",
                "id": 164,
                "name": "Solomon Islands"
            },
            {
                "image_name": "SO",
                "code": "252",
                "id": 165,
                "name": "Somalia"
            },
            {
                "image_name": "ZA",
                "code": "27",
                "id": 166,
                "name": "South Africa"
            },
            {
                "image_name": "KR",
                "code": "82",
                "id": 167,
                "name": "Korea, Republic of"
            },
            {
                "image_name": "ES",
                "code": "34",
                "id": 168,
                "name": "Spain"
            },
            {
                "image_name": "LK",
                "code": "94",
                "id": 169,
                "name": "Sri Lanka"
            },
            {
                "image_name": "SH",
                "code": "290",
                "id": 170,
                "name": "Saint Helena, Ascension and Tristan da Cunha"
            },
            {
                "image_name": "PM",
                "code": "508",
                "id": 171,
                "name": "Saint Pierre and Miquelon"
            },
            {
                "image_name": "SD",
                "code": "249",
                "id": 172,
                "name": "Sudan"
            },
            {
                "image_name": "SR",
                "code": "597",
                "id": 173,
                "name": "Suriname"
            },
            {
                "image_name": "SZ",
                "code": "268",
                "id": 174,
                "name": "Swaziland"
            },
            {
                "image_name": "SE",
                "code": "46",
                "id": 175,
                "name": "Sweden"
            },
            {
                "image_name": "CH",
                "code": "41",
                "id": 176,
                "name": "Switzerland"
            },
            {
                "image_name": "SY",
                "code": "963",
                "id": 177,
                "name": "Syrian Arab Republic"
            },
            {
                "image_name": "TW",
                "code": "886",
                "id": 178,
                "name": "Taiwan, Province of China"
            },
            {
                "image_name": "TJ",
                "code": "992",
                "id": 179,
                "name": "Tajikistan"
            },
            {
                "image_name": "TZ",
                "code": "255",
                "id": 180,
                "name": "Tanzania, United Republic of"
            },
            {
                "image_name": "TH",
                "code": "66",
                "id": 181,
                "name": "Thailand"
            },
            {
                "image_name": "TG",
                "code": "228",
                "id": 182,
                "name": "Togo"
            },
            {
                "image_name": "TK",
                "code": "690",
                "id": 183,
                "name": "Tokelau"
            },
            {
                "image_name": "TO",
                "code": "676",
                "id": 184,
                "name": "Tonga"
            },
            {
                "image_name": "TN",
                "code": "216",
                "id": 185,
                "name": "Tunisia"
            },
            {
                "image_name": "TR",
                "code": "90",
                "id": 186,
                "name": "Turkey"
            },
            {
                "image_name": "TM",
                "code": "993",
                "id": 187,
                "name": "Turkmenistan"
            },
            {
                "image_name": "TV",
                "code": "688",
                "id": 188,
                "name": "Tuvalu"
            },
            {
                "image_name": "AE",
                "code": "971",
                "id": 189,
                "name": "United Arab Emirates"
            },
            {
                "image_name": "UG",
                "code": "256",
                "id": 190,
                "name": "Uganda"
            },
            {
                "image_name": "UA",
                "code": "380",
                "id": 191,
                "name": "Ukraine"
            },
            {
                "image_name": "UY",
                "code": "598",
                "id": 192,
                "name": "Uruguay"
            },
            {
                "image_name": "US",
                "code": "1",
                "id": 193,
                "name": "USA"
            },
            {
                "image_name": "UZ",
                "code": "998",
                "id": 194,
                "name": "Uzbekistan"
            },
            {
                "image_name": "VU",
                "code": "678",
                "id": 195,
                "name": "Vanuatu"
            },
            {
                "image_name": "VA",
                "code": "39",
                "id": 196,
                "name": "Holy See (Vatican City State)"
            },
            {
                "image_name": "VE",
                "code": "58",
                "id": 197,
                "name": "Venezuela, Bolivarian Republic of"
            },
            {
                "image_name": "VN",
                "code": "84",
                "id": 198,
                "name": "Viet Nam"
            },
            {
                "image_name": "WF",
                "code": "681",
                "id": 199,
                "name": "Wallis and Futuna"
            },
            {
                "image_name": "YE",
                "code": "967",
                "id": 200,
                "name": "Yemen"
            },
            {
                "image_name": "ZM",
                "code": "260",
                "id": 201,
                "name": "Zambia"
            },
            {
                "image_name": "ZW",
                "code": "263",
                "id": 202,
                "name": "Zimbabwe"
            }
            // id 203 is reserved for republic of ireland and its placed in alphabetical order in this list

        ]
        this.setNumberTypes = function setNumberTypes(number_types) {
            this.number_types = number_types;
        }

        this.getNumberTypes = function getNumberTypes() {
            return this.number_types;
        }

        this.getCountries = function getCountries() {
            return countries;
        }

        this.getCountryById = function getCountryById(id) {
            for (var i = 0, l = countries.length; i < l; i++) {
                if (countries[i].id === id) {
                    return countries[i];
                }
            }
        }

        this.getCountryByCode = function getCountryByCode(code) {
            for (var i = 0, l = countries.length; i < l; i++) {
                if (countries[i].code === code) {
                    return countries[i];
                }
            }
        }
    })
    .service('clickEvents', function($rootScope, $timeout) {
        /*====================================================
            Data sets
        ====================================================*/
        category = '';

        click_events_collection = {
            daterange: {
                parent_elm : '.daterange-wrapper',
                ignore_list : '#datepicker-input, .daterange-day, .daterange_filter span.ss-calendar, #side-panel, .right-icon'
            },
            datepicker: {
                parent_elm: '.custom-datepicker',
                ignore_list : '#datepicker-input, .datepicker-append .ss-calendar, .custom-datepicker th, #side-panel, .right-icon'
            },
            actionbuttons: {
                parent_elm: '.actions-dropdown',
                ignore_list : '.actions-toggle, #side-panel, #page-panel-main, #update-column-panel, #page-overlay, #smart-panel-overlay, #update-column-link'
            },
            spreadsheet: {
                parent_elm : '.spreadsheet-table',
                ignore_list: '.spreadsheet-section .fullscreen-icon'
            }
        }

        /*====================================================
            Methods called from outside
        ====================================================*/

        this.registerIgnoreElements = function registerIgnoreElements(cat) {
            category = cat;
            document.onclick = function(e) {
                $rootScope.firedCloseAll = false;
                var enable_events = true;
                /*====================================================
                    If the category is a single datepicker then check
                    if the user has clicked a month or year in the
                    date view - if the user has clicked a month or year
                    then disable the click handler - if the user hasn't
                    then go ahead and fire the click handler to handle
                    closing the datepicker
                ====================================================*/
                if (category == 'datepicker'){
                    if( $(e.target).is('.daterange-day') ){
                        var is_day = parseInt($(e.target).text());

                        enable_events = true;

                        if($.isNumeric(is_day)){
                            if( is_day>40 ){
                                enable_events = false
                            }else{
                                enable_events = true;
                            }
                        }else{
                            enable_events = false;
                        }
                    }
                }

                /*====================================================
                    Check if the click happened on an element that has
                    been listed in the ignore_list
                ====================================================*/
                if(category != undefined){
                    if($(e.target).is(click_events_collection[category].ignore_list)){
                        var click_ignored_element = true;
                    }else if ($(e.target).parents().is(click_events_collection[category].ignore_list)){
                        var click_in_ignored_element = true;
                    }
                    else{
                        var click_ignored_element = false;
                    }
                    /*====================================================
                        Checks if the click happened inside the element that
                        we want to keep open, if not broadcast to all scopes
                    ====================================================*/
                    var click_in_elm = $(e.target).parents(click_events_collection[category].parent_elm).length > 0;

                    if (click_ignored_element === false && enable_events === true) {
                        if (click_in_elm) {
                            return;
                        }
                        else {
                            $rootScope.$broadcast('closeallwithapply', e);
                            category = null;
                            return
                        }
                    }
                }
            }
        }
    })
    .service('keyBoardKeys', function() {

        this.alphanumeric_keys = {
            48 : '0',
            49 : '1',
            50 : '2',
            51 : '3',
            52 : '4',
            53 : '5',
            54 : '6',
            55 : '7',
            56 : '8',
            57 : '9',
            96 : '0',
            97 : '1',
            98 : '2',
            99 : '3',
            100 : '4',
            101 : '5',
            102 : '6',
            103 : '7',
            104 : '8',
            105 : '9',
            65 : 'a',
            66 : 'b',
            67 : 'c',
            68 : 'd',
            69 : 'e',
            70 : 'f',
            71 : 'g',
            72 : 'h',
            73 : 'i',
            74 : 'j',
            75 : 'k',
            76 : 'l',
            77 : 'm',
            78 : 'n',
            79 : 'o',
            80 : 'p',
            81 : 'q',
            82 : 'r',
            83 : 's',
            84 : 't',
            85 : 'u',
            86 : 'v',
            87 : 'w',
            88 : 'x',
            89 : 'y',
            90 : 'z'
        }

        this.isAlphaNumeric = function isAlphaNumeric(char_code) {
            var valid_key = this.alphanumeric_keys[char_code] != undefined;

            if(valid_key) {
                return this.alphanumeric_keys[char_code];
            }
        }

        this.getKey = function(char_code) {
            var valid_key = this.alphanumeric_keys[char_code] != undefined;

            if(valid_key) {
                return this.alphanumeric_keys[char_code];
            }
        }
    })
    .service('fullScreenEditorCollection', function() {
        this.setPanelData = function setPanelData(fullObject){
            this.notesCollection = fullObject;
        }
        this.getPanelData = function getPanelData(categoty) {
            return this.notesCollection;
        }
    })
    .service('setDataCollection', function() {
        this.setData = function setData(fullObject){
            this.setInstanceId = fullObject;
        }
        this.getData = function getData(){
            return this.setInstanceId;
        }
    })
    .service('confirmationBoxHelper', function($compile, $q) {

        this.getConfirmation = function getConfirmation(message, origin_scope) {
            var deferred = $q.defer(),
                confirmation_box_tpl = this.getConfirmationBoxDirective(message),
                confirmation_box_directive = $compile(confirmation_box_tpl)(origin_scope);

            confirmation_box_directive[0].addEventListener('confirmation_box_resolved', function(e) {
                var confirmed = e.detail;
                confirmed ? deferred.resolve() : deferred.reject();
            }, false);

            return deferred.promise;
        }

        this.getConfirmationBoxDirective = function getConfirmationBoxDirective(message) {
            return '<div confirmationbox message=' + "'"+  message + "'"+  ' id="confirmation-box"></div>';
        }

        // called if the cancel link in panel with form is clicked whilst the box is still showing
        this.hideConfirmationBox = function hideConfirmationBox() {
            var elm = document.querySelector('#confirmation-box'),
                box_showing = elm !== undefined;

            if(box_showing) {
                angular.element(elm).remove();
            }
        }
    })
    .service('feedbackBox', function($timeout, $rootScope) {
        this.elm = $('#feedback-box');
        this.message_wrapper = this.elm.find('.text');
        $rootScope.allow_toast_box_to_close = true;

        this.setFeedback = function setFeedback(text) {
            $rootScope.allow_toast_box_to_close = true;
            this.message_wrapper.html(text);
        }

        var self = this;
        this.showFeedback = function showFeedback() {
            this.elm.fadeIn(200);
            this.hideFeedback();
        }
        this.hideFeedback = function hideFeedback() {
            $timeout(function(){
                var allowed_to_close = $rootScope.allow_toast_box_to_close === true;
                if (allowed_to_close) {
                    self.elm.fadeOut(300);
                }
            }, 1000);
        }
        $rootScope.$on('closeFeedbackBox', function(){
            $rootScope.allow_toast_box_to_close = true;
            self.elm.fadeOut(300);
        });
    })
    .service('stepAnimate', function($timeout) {

        /*====================================================
         External Methods
         ====================================================*/

        // merge steps
        this.mergeSteps = function mergeSteps(merge_type, advanced_estimate) {
            var self = this;
            this.merge_type = merge_type;
            this.advanced_estimate = advanced_estimate;

            $timeout(function() {
                self.mergeAnimationCtrl();
            }, 810);
        }

        // un merge steps
        this.unmergeSteps = function unmergeSteps() {
            this.unmergeAnimationCtrl();
        }

        /*====================================================
         Internal Methods
         ====================================================*/
        this.getElms = function getElms() {
            this.panel = document.querySelector('#estimates-shelf');
            this.step_text = document.querySelector('#step-7-text');
            this.step_count = document.querySelector('#invoice-schedule-count');
            this.loading_icon = document.querySelector('#step-loading-image');
            this.confirmation_icon = document.querySelector('#step-confirmation-image');

            if(this.advanced_estimate) {
                this.step_4 = document.querySelector('#estimate-todo-step');
                this.step_5 = document.querySelector('#estimate-budget-step');
            }

            this.step_6 = document.querySelector('#estimate-price-step');
            this.step_7 = document.querySelector('#estimate-invoice-step');
        }

        /*====================================================
         Merge Steps
         ====================================================*/

        this.mergeAnimationCtrl = function mergeAnimationCtrl() {
            var self = this;
            this.getElms();
            this.setAnimationText();
            this.beginningAnimation();

            $timeout(function() {
                self.applyLoadingState();
            }, 650);

            $timeout(function() {
                self.showLoadingText();
            }, 900);

            $timeout(function() {
                self.moveStepsUp();
            }, 1100);

            $timeout(function() {
                self.applyConfirmationState();
            }, 1700);

            $timeout(function() {
                self.showConfirmationIcon();
            }, 2000);

            $timeout(function() {
                self.finishingPos1();
                self.finishingPos2();
            }, 3000);

            $timeout(function() {
                self.finishingPos3();
            }, 3500);

            $timeout(function() {
                self.triggerMergeAnimateEnd();
            }, 3800);

            $timeout(function() {
                self.resetMergeClasses();
            }, 3900);
        }

        this.setAnimationText = function setAnimationText() {
            if (this.merge_type === 'clone') {
                this.loading_message = 'Cloning Option';
                this.confirmation_message = 'Option Cloned';
            }else if (this.merge_type === 'new_option') {
                this.loading_message = 'Creating Option';
                this.confirmation_message = 'Option Created';
            }
        }

        this.beginningAnimation = function beginningAnimation() {
            if(this.advanced_estimate) {
                this.step_4.className += ' animate-step';
                this.step_5.className += ' animate-step';
            }

            this.step_6.className += ' animate-step';
            this.step_7.className += ' animate-step';
        }

        this.applyLoadingState = function applyLoadingState() {
            this.step_7.className += ' loading-state';
            this.step_text.className += ' text-hidden';
            this.step_text.innerHTML = this.loading_message;
        }

        this.showLoadingText = function showLoadingText() {
            this.step_text.classList.remove('text-hidden');
        }

        this.moveStepsUp = function moveStepsUp() {
            if(this.advanced_estimate) {
                this.step_4.className += ' animate-step-up';
                this.step_5.className += ' animate-step-up';
            }

            this.step_6.className += ' animate-step-up';
            this.step_7.className += ' animate-step-up';
        }

        this.applyConfirmationState = function applyConfirmationState() {
            this.loading_icon.className += ' image-hidden';
            this.step_text.innerHTML = this.confirmation_message;
        }

        this.showConfirmationIcon = function showConfirmationIcon() {
            this.confirmation_icon.className += ' image-showing';
        }

        // finishing animation sequence when the steps have moved up

        this.finishingPos1 = function finishingPos1() {
            if(this.advanced_estimate) {
                this.step_4.className += ' faded-out';
                this.step_5.className += ' faded-out';
            }

            this.step_6.className += ' faded-out';
            this.step_7.className += ' move-left';
        }

        this.finishingPos2 = function finishingPos2() {
            this.step_7.className += ' move-up';
        }

        this.finishingPos3 = function finishingPos3() {
            this.confirmation_icon.classList.remove('image-showing');
            this.confirmation_icon.className += ' image-hidden';

            // show the step count
            this.step_count.childNodes[1].innerHTML = '3';
            this.step_count.className += ' count-showing';

            // show the 'options' text
            this.step_text.innerHTML = 'Options';
            this.step_text.className += ' normal-link';

            // remove border & shadow from step
            this.step_7.className += ' without-outline';
        }

        this.triggerMergeAnimateEnd = function triggerMergeAnimateEnd() {
            var panel_updated = new Event('merge_animation_complete');
            this.panel.dispatchEvent(panel_updated);
        }

        this.resetMergeClasses = function resetMergeClasses() {
            if(this.advanced_estimate) {
                this.step_4.className = 'step-that-animates ng-hide';
                this.step_5.className = 'step-that-animates ng-hide';
            }

            this.step_6.className = 'step-that-animates ng-hide';
            this.step_7.className = 'step-that-animates ng-hide';

            // Todo modified the value 6 => 7 & Price => Invoice Schedule
            this.step_count.childNodes[1].innerHTML = '7';
            this.step_text.innerHTML = 'Invoice Schedule';
            this.loading_icon.classList.remove('image-hidden');
            this.step_count.classList.remove('count-showing');
            this.step_text.classList.remove('normal-link');
        }

        /*====================================================
         Un merge steps
         ====================================================*/
        this.unmergeAnimationCtrl = function unmergeAnimationCtrl() {
            var self = this;
            this.getElms();
            this.getExtraElms();
            this.resetStepPositions();

            $timeout(function() {
                self.unfoldSteps();
            }, 200);

            $timeout(function() {
                self.showStepCounts();
            }, 800);

            $timeout(function() {
                self.hideStepCountsBelow();
            }, 1400);

            $timeout(function() {
                self.showStepCountsBelow();
                self.slideStepsLeft();
            }, 2200);

            $timeout(function() {
                self.removeBorders();
            }, 2800);

            $timeout(function() {
                self.triggerUnmergeAnimateEnd();
            }, 2900);

            $timeout(function() {
                self.resetUnmergeClasses();
            }, 3000);
        }

        // elms that are only needed for the un-merging animaiton
        this.getExtraElms = function getExtraElms() {
            this.step_7 = document.querySelector('#estimate-invoice-step');
            this.step_8 = document.querySelector('#estimate-customer-step');
            this.step_9 = document.querySelector('#estimate-accept-step');
        }

        this.resetStepPositions = function resetStepPositions() {
            if(this.advanced_estimate) {
                this.step_4.className += ' step-showing';
                this.step_5.className += ' step-showing';
            }

            this.step_6.className += ' step-showing';
            this.step_7.className += ' step-showing';
        }

        this.unfoldSteps = function unfoldSteps() {
            if(this.advanced_estimate) {
                this.step_4.className += ' unfold-step';
                this.step_5.className += ' unfold-step';
            }

            this.step_6.className += ' unfold-step';
            this.step_7.className += ' unfold-step';
        }

        this.showStepCounts = function showStepCounts() {
            if(this.advanced_estimate) {
                this.step_4.className += ' with-steps-showing';
                this.step_5.className += ' with-steps-showing';
            }

            this.step_6.className += ' with-steps-showing';
            this.step_7.className += ' with-steps-showing';
        }

        this.hideStepCountsBelow = function hideStepCountsBelow() {
            this.step_8.className += ' with-hidden-count';
            this.step_9.className += ' with-hidden-count';
        }

        this.showStepCountsBelow = function showStepCountsBelow() {
            var step_8_count = this.step_8.querySelector('.text');
            var step_9_count = this.step_9.querySelector('.text');

            step_8_count.innerHTML = '8';
            step_9_count.innerHTML = '9';

            this.step_8.classList.remove('with-hidden-count');
            this.step_9.classList.remove('with-hidden-count');
            this.step_8.className += ' with-count-showing';
            this.step_9.className += ' with-count-showing';
        }

        this.slideStepsLeft = function slideStepsLeft() {
            if(this.advanced_estimate) {
                this.step_4.className += ' left-aligned';
                this.step_5.className += ' left-aligned';
            }

            this.step_6.className += ' left-aligned';
            this.step_7.className += ' left-aligned';
        }

        this.removeBorders = function removeBorders() {
            if(this.advanced_estimate) {
                this.step_4.className += ' without-border';
                this.step_5.className += ' without-border';
            }

            this.step_6.className += ' without-border';
            this.step_7.className += ' without-border';
        }

        this.triggerUnmergeAnimateEnd = function triggerUnmergeAnimateEnd() {
            var panel_updated = new Event('un_merge_animation_complete');
            this.panel.dispatchEvent(panel_updated);
        }

        this.resetUnmergeClasses = function resetUnmergeClasses() {
            var step_8_count = this.step_8.querySelector('.text');
            var step_9_count = this.step_9.querySelector('.text');

            step_8_count.innerHTML = '5';
            step_9_count.innerHTML = '6';

            this.step_8.classList.remove('with-count-showing');
            this.step_9.classList.remove('with-count-showing');

            if(this.advanced_estimate) {
                this.step_4.className = 'step-that-animates';
                this.step_5.className = 'step-that-animates';
            }

            this.step_6.className = 'step-that-animates';
            this.step_7.className = 'step-that-animates';
        }
    })
    .service('estimateOptions', function() {
        this.estimate_options = [];

        this.new_option = {
            title: '',
            estimate_body: '',
            todos: [],
            cost: '',
            price: '',
            profit: ''
        }

        this.existing_option = {
            title: '',
            estimate_body: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo.',
            todos: [
                'todo1',
                'todo2',
                'todo3'
            ],
            cost: '300',
            price: '400',
            profit: '100',
            status: {
                decision: null,
                name: null,
                date: null,
                time: null
            }
        }

        // populate the array with an existing option
        var new_obj = {};
        var clone_obj = _.extend(new_obj, this.existing_option);
        clone_obj.title = 'Option ' + 1;
        clone_obj.status = {};
        this.estimate_options.push(clone_obj);

        this.generateEstimate = function generateEstimate(type) {
            var new_obj = {};
            if (type === 'clone') {
                var clone_obj = _.extend(new_obj, this.existing_option);
                clone_obj.title = 'Option ' + (this.estimate_options.length + 1);
                clone_obj.status = {
                    decision: null,
                    name: null,
                    date: null,
                    time: null
                };
            }else {
                var clone_obj = _.extend(new_obj, this.new_option);
                clone_obj.title = 'Option ' + (this.estimate_options.length + 1);
            }

            this.estimate_options.push(clone_obj);
            return this.estimate_options;
        }

        this.initEstimate = function initEstimate() {
            return this.estimate_options;
        }
    })
    .service('estimateDiaryEvents', function() {

        var diary_events = {
            estimates: {
                data: [
                    {
                        id: 0,
                        status: "Not Complete",
                        date: "22nd Apr 2014",
                        time: "09:00 - 10:00",
                        description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo.",
                        location: "EN3 5PB",
                        engineer_name: "Callum Hart",
                        profile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                        notes: "This is the engineer notes for event 1",
                        todos: [
                            {
                                todo : "Photograph boiler",
                                status : "Done",
                                marked_off_by: 'John Smith',
                                more_details : {
                                    photos: [
                                        {
                                            name: 'Bolier Photograph',
                                            date_added: 'Mon 21st June 2013',
                                            url: 'images/files/test1.png'
                                        }
                                    ],
                                    history: [
                                        {
                                            date: '18/03/14',
                                            note: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                            engineer: 'Paul Smith',
                                            proile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                                            tag: 'Service'
                                        },
                                        {
                                            date: '17/03/14',
                                            note: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                            engineer: 'David James',
                                            proile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                                            tag: 'Breakdown'
                                        },
                                        {
                                            date: '16/03/14',
                                            note: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                            engineer: 'Callum Hart',
                                            proile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                                            tag: 'Installation'
                                        }
                                    ]
                                }
                            },
                            {
                                todo : "Photograph parking location",
                                status : "Done",
                                marked_off_by: 'John Smith',
                                more_details : {
                                    photos: [
                                        {
                                            name: 'Parking Location',
                                            date_added: 'Mon 21st June 2013',
                                            url: 'images/files/test2.png'
                                        }
                                    ],
                                    history: [
                                        {
                                            date: '20/04/14',
                                            note: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                            engineer: 'John Martin',
                                            proile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                                            tag: 'Service'
                                        },
                                        {
                                            date: '21/04/14',
                                            note: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                            engineer: 'Warren Martin',
                                            proile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                                            tag: 'Breakdown'
                                        }
                                    ]
                                }
                            },
                            {
                                todo : "This is todo 3",
                                status : "To-do"
                            }
                        ]
                    },
                    {
                        id: 1,
                        status: "Not Complete",
                        date: "24th Apr 2014",
                        time: "09:20 - 11:00",
                        description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo.",
                        location: "SW1 3AB",
                        engineer_name: "Nick Brown",
                        profile_image: 'http://gravatar.com/avatar/a6968fbfb11b00704757be49891ffb80.png?s=30',
                        notes: "This is the engineer notes for event 1"
                    },
                    {
                        id: 3,
                        status: "Completed",
                        date: "09th Jan 2014",
                        time: "12:00 - 14:00",
                        description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo.",
                        location: "WA1 5SK",
                        engineer_name: "Peter Smith",
                        profile_image: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
                        notes: "This is the engineer notes for event 3",
                        todos: [
                            {
                                todo : "This is todo 1",
                                status : "Done"
                            },
                            {
                                todo : "This is todo 2",
                                status : "Done"
                            },
                            {
                                todo : "This is todo 3",
                                status : "To-do"
                            }
                        ],
                        travel_details: {
                            accept: "12:03",
                            travel: "12:07",
                            arrival: "12:40",
                            complete: "15:30",
                            time_travelling: "33 mins",
                            time_on_site: "1 hour 50 mins"
                        },
                        arrive_on_site_questions: [
                            {
                                question: "This is question 1",
                                answer: "This is the answer to question 1"
                            },
                            {
                                question: "This is question 2",
                                answer: "This is the answer to question 2"
                            },
                            {
                                question: "This is question 3",
                                answer: "This is the answer to question 3"
                            }
                        ],
                        signatures: [
                            {
                                type: "Leave Signature",
                                path: "../images/signature.png"
                            }
                        ]
                    },
                    {
                        id: 4,
                        status: "Completed",
                        date: "04th Feb 2014",
                        time: "10:00 - 12:00",
                        description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo.",
                        location: "DA2 7HG",
                        engineer_name: "Sam Jones",
                        profile_image: 'http://gravatar.com/avatar/a6968fbfb11b00704757be49891ffb80.png?s=30',
                        notes: "This is the engineer notes for event 4",
                        todos: [
                            {
                                todo : "This is todo 1",
                                status : "Done"
                            },
                            {
                                todo : "This is todo 2",
                                status : "Done"
                            },
                            {
                                todo : "This is todo 3",
                                status : "To-do"
                            }
                        ],
                        travel_details: {
                            accept: "12:03",
                            travel: "12:07",
                            arrival: "12:40",
                            complete: "15:30",
                            time_travelling: "33 mins",
                            time_on_site: "1 hour 50 mins"
                        },
                        arrive_on_site_questions: [
                            {
                                question: "This is question 1",
                                answer: "This is the answer to question 1"
                            },
                            {
                                question: "This is question 2",
                                answer: "This is the answer to question 2"
                            },
                            {
                                question: "This is question 3",
                                answer: "This is the answer to question 3"
                            }
                        ],
                        signatures: [
                            {
                                type: "Complete Signature",
                                path: "../images/signature.png"
                            }
                        ]
                    }
                ]
            },
            projects: {
                data: [
                    {},
                    {}
                ]
            }
        }

        /*====================================================
         Getters
         ====================================================*/
        this.getDiaryEvents = function getDiaryEvents(category) {
            return diary_events[category].data;
        }
    })
    .service('toastBox', function($q, $compile, $rootScope) {
        this.show = function show(message, duration) {
            var scope = $rootScope.$new(true),
                toast_box_tpl = '<div toastbox message=' + "'" +  message + "'" + ' duration=' + "'" + duration + "'" + ' id="toast-box-wrapper"></div>',
                toast_box_dir = $compile(toast_box_tpl)(scope)[0],
                toast_box = document.querySelector('#toast-box-wrapper');

            if(toast_box) {
                this.removeToastBox(toast_box);
                this.insertToastBox(toast_box_dir);
            }else {
                this.insertToastBox(toast_box_dir);
            }
        }

        this.insertToastBox = function insertToastBox(toast_box_dir) {
            angular.element('body').append(toast_box_dir);
        }

        this.removeToastBox = function removeToastBox(toast_box_elm) {
            angular.element(toast_box_elm).remove();
        }
    })
    .service('warningModal', function($q, $compile, $rootScope) {
        this.show = function show(message, title, identifier, translateParams) {
            var scope = $rootScope.$new(true);
            translateParams = (translateParams === undefined) ? {} : translateParams;

            if (identifier === undefined) {
                identifier = 'cannot-delete-modal';
            }

            var modal_tpl = '<div cannotdeletemodal message=' + "'" +  message + "'" +
                                                                ' title=' + "'" + title + "'" +
                                                                ' translateparams=' + "'" + JSON.stringify(translateParams) + "'" +
                                                                ' identifier=' + "'" + identifier + "'" +
                                                                ' class=' + "'cannot-delete-directive " + identifier + "'" +
                                                                ' open_on_compile="true" remove_on_close="true"></div>',
                modal_dir = $compile(modal_tpl)(scope)[0];

            angular.element('body').append(modal_dir);
        }
        this.clean = function clean(identifier) {
            angular.element("div[identifier='" + identifier + "']").remove();
        }
    })
    .service('postCodeHelper', function($compile, $rootScope, $timeout) {
        var self;

        /*==========================================================================================
         Replace the first view with the postcode look up view
         ==========================================================================================*/
        this.initialise = function initialise(directive_scope) {
            self = this;
            this.directive_scope = directive_scope;
            var justPostcodeInForm = directive_scope.just_postcode === "true" ? true: false;
            this.postcode_directive_tpl = '<div smartpostcodeform just-postcode=' + justPostcodeInForm +
                ' id="smart-post-code-wrap"></div>';
            this.side_panel = document.querySelectorAll('#quick-add-panel')[0];
            this.swivelled_transition_duration = 500;

            this.insertPostNode();
            this.switchView('postcode');
        }

        this.insertPostNode = function insertPostNode() {
            var postcode_directive = $compile(this.postcode_directive_tpl)(this.directive_scope);
            angular.element(this.side_panel).after(postcode_directive);
        }

        this.switchView = function switchView(view) {
            var show_postcode_form = view === 'postcode';
            this.postcode_elm = document.querySelector('#smart-post-code-wrap');
            show_postcode_form ? this.showPostCodeForm() : this.showInitialForm();
        }

        this.validatePostcode = function validatePostcode(postcode) {
            var trimmed_postcode = (postcode) ? postcode.replace(/\s/g, "") : '',
                regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i,
                is_valid = !regex.test(postcode);

            return is_valid;
        }

        this.getPreviousPostcode = function getPreviousPostcode() {
            return this.previous_postcode;
        }

        this.showPostCodeForm = function showPostCodeForm() {
            this.side_panel.classList.remove('swivelled-in');
            this.side_panel.classList.add('swivelled-out');

            /*
             self.postcode_elm.classList.add('swivelled-in'); // remove after dev
             */

            $timeout(function() {
                self.postcode_elm.classList.add('swivelled-in');
            }, self.swivelled_transition_duration);
        }

        this.showInitialForm = function showInitialForm() {
            self.postcode_elm.classList.remove('swivelled-in');
            self.postcode_elm.classList.add('swivelled-out');
            self.side_panel.classList.add('swivelled-in');

            /*
             self.side_panel.classList.remove('swivelled-out');// remove after dev
             self.removePostNode(); // remove after dev
             */

            $timeout(function() {
                self.side_panel.classList.remove('swivelled-out');
                self.removePostNode();
            }, self.swivelled_transition_duration);
        }

        this.removePostNode = function removePostNode() {
            var post_node_exists = this.postcode_elm.parentNode !== null;

            if(post_node_exists) {
                this.postcode_elm.parentNode.removeChild(this.postcode_elm);
            }
        }

        $rootScope.$on('quick_add_panel:closed', function() {
            var active_instance = self !== undefined;

            if(active_instance) {
                self.removePostNode();
            }
        });
    })
    .service('panelValidationHelper', function($q, $rootScope, postCodeHelper) {
        this.invalid_input_refs = [];
        this.server_side_validation_failed = false;

        /*==========================================================================================
         Add event to input template which calls handleValidaiton
         ==========================================================================================*/
        this.addValidation = function addValidation(input_tpl, input_type, model_ref, input_label, error_message) {
            var validation_function = '"handleValidation(' + "'"+  input_type + "', " + "'" +  model_ref + "', " + "'" +  input_label + "', " + "'" +  error_message + "'" + ', $event)"',
                validation_tpl = ' ng-keyup=' + validation_function,
                text_input = input_type === 'text',
                postcode_input = input_type === 'postcode',
                date_input = input_type === 'date',
                textarea_input = input_type === 'textarea',
                select_input = input_type === 'select',
                smart_filter_input = input_type === 'smart_filter',
                smart_filter_with_optgroup_input = input_type === 'smart_filter_with_optgroup',
                number_input = input_type === 'number',
                email_input = input_type === 'email',
                from_input = input_type === 'from',
                pos,
                new_input_tpl;

            if(text_input || number_input || email_input || from_input) {
                pos = input_tpl.length - 1;
            }else if(postcode_input) {
                pos = (input_tpl.indexOf('ng-change') - 1);
            }else if(date_input) {
                pos = (input_tpl.indexOf('type="text"') - 1);
            }else if(textarea_input) {
                pos = (input_tpl.indexOf('ng-model') - 1);
            }else if(select_input || smart_filter_input || smart_filter_with_optgroup_input) {
                pos = (input_tpl.indexOf('ng-model') - 1);
                validation_tpl += ' ng-change=' + validation_function;
            }
            if(input_type != 'number'&& input_type != 'email'&& input_type != 'from' && input_type != 'time' && input_type != 'price' && input_type != 'markup'){
                if (this.invalid_input_refs.indexOf(model_ref) == -1) {
                    this.invalid_input_refs.push(model_ref);
                }
            }
            new_input_tpl = [input_tpl.slice(0, pos), validation_tpl, input_tpl.slice(pos)].join('');
            return new_input_tpl;
        }

        /*==========================================================================================
         Assert whether the input is valid or not
         ==========================================================================================*/
        this.validateInput = function validateInput(type, model_ref, input_label, error_message, e) {
            var deferred = $q.defer(),
                input,
                value,
                text_input = type === 'text',
                postcode_input = type === 'postcode',
                date_input = type === 'date',
                textarea_input = type === 'textarea',
                select_input = type === 'select',
                smart_filter_input = type === 'smart_filter',
                smart_filter_with_optgroup_input = type === 'smart_filter_with_optgroup',
                number_input = type === 'number',
                email_input = type === 'email',
                from_input = type === 'from',
                is_valid;

            if(select_input || smart_filter_with_optgroup_input) {
                input = document.querySelector("[ng-model=" + model_ref + "]");
                value = input.value;
                is_valid = this.validateSelectField(value);
            }else if(smart_filter_input) {
                input = document.querySelector("[ng-model=" + model_ref + "]");
                value = input.options[input.selectedIndex].text;
                is_valid = this.validateTextField(value);
            }else {
                input = e.target;
                value = input.value;
            }

            if(text_input) {
                is_valid = this.validateTextField(value);
            }else if(postcode_input) {
                is_valid = this.validatePostcodeField(value);
            }else if(date_input) {
                is_valid = this.validateDateField(value);
            }else if(textarea_input) {
                is_valid = this.validateTextField(value);
            }else if(number_input && model_ref != 'installtime' && model_ref != 'price' && model_ref != 'markup') {
                is_valid = this.validateNumberField(value);
            }else if(email_input) {
                is_valid = this.validateEmailField(value);
            }else if(from_input) {
                is_valid = this.validateFromField(value);
            }else if(model_ref == 'installtime') {
                is_valid = this.validateTimeField(value);
            }else if(model_ref == 'price') {
                is_valid = this.validatePriceField(value);
            }else if(model_ref == 'markup') {
                is_valid = this.validateMarkupField(value);
            }
            if(this.server_side_validation_failed) {
                this.resetClientSideErrorMessages(input, error_message, text_input, textarea_input, postcode_input, date_input, select_input, smart_filter_input, smart_filter_with_optgroup_input, number_input);
            }

            var return_obj = {
                input_type: type,
                model_ref: model_ref,
                event: e
            }

            is_valid ? deferred.resolve(return_obj) : deferred.reject(return_obj);
            return deferred.promise;
        }

        /*==========================================================================================
         Validate text input
         ==========================================================================================*/
        this.validateTextField = function validateTextField(value) {
            var is_valid = value.trim().length > 0;
            return is_valid;
        }

        /*==========================================================================================
         Validate postcode input
         ==========================================================================================*/
        this.validatePostcodeField = function validatePostcodeField(value) {
            var is_valid = !postCodeHelper.validatePostcode(value);
            return is_valid;
        }

        /*==========================================================================================
         Validate date input
         ==========================================================================================*/
        this.validateDateField = function validateDateField(value) {
            var date_pattern =/^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{2}|[0-9]{4})$/,
                is_valid = date_pattern.test(value);

            return is_valid;
        }
        /*==========================================================================================
         Validate Number input
         ==========================================================================================*/
        this.validateNumberField = function validateNumberField(value) {
            if(value){
                var input_pattern =/^([0-9]{1,})$/,
                    is_valid = input_pattern.test(value);
            }
            else {
                is_valid = true;
            }
            return is_valid;
        }
        /*==========================================================================================
         Validate From input
         ==========================================================================================*/
        this.validateFromField = function validateFromField(value) {
            if(value){
                var input_pattern =/^(\-?[0-9]{1,2}(\.[0-9]{1,2})?|100)$/,
                    is_valid = input_pattern.test(value);
            }
            else {
                is_valid = true;
            }
            return is_valid;
        }
        /*===========================================================================================
         Validate Email input
         =============================================================================================*/
        this.validateEmailField = function validateEmailField(value) {
            if(value){
                var input_pattern =/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})*$/i,
                    is_valid = input_pattern.test(value);
            }
            else {
                is_valid = true;
            }
            return is_valid;
        }
        this.validateSelectField = function validateSelectField(value) {
            var is_valid = value.length > 0 && value !== 'prompt';
            return is_valid;
        }
        /*===========================================================================================
         Validate Time input
         =============================================================================================*/
        this.validateTimeField = function validateTimeField(value) {
            if(value){
                var input_pattern =/^[0-9]{0,2}(\.[0-9]{1,2})?$/,
                    is_valid = input_pattern.test(value);
            }
            else {
                is_valid = true;
            }
            return is_valid;
        }
        /*===========================================================================================
         Validate Price input
         =============================================================================================*/
        this.validatePriceField = function validatePriceField(value) {
            if(value){
                var input_pattern =/^[0-9]{0,7}(\.[0-9]{0,4})?$/,
                    is_valid = input_pattern.test(value);
            }
            else {
                is_valid = true;
            }
            return is_valid;
        }
        /*===========================================================================================
         Validate Markup input
         =============================================================================================*/
        this.validateMarkupField = function validateMarkupField(value) {
            if(value){
                var input_pattern =/^[0-9]{0,3}(\.[0-9]{1,2})?$/,
                    is_valid = input_pattern.test(value);
            }
            else {
                is_valid = true;
            }
            return is_valid;
        }

        /*==========================================================================================
         Show error message and store the model_ref in invalid_input_refs (if not already in there)
         ==========================================================================================*/
        this.handleInvalidInput = function handleInvalidInput(input_type, model_ref, e, directive_scope) {
            var deferred = $q.defer();
            var validation_node = this.getErrorMessageNode(e, model_ref, input_type),
                store_model_ref = this.invalid_input_refs.indexOf(model_ref) < 0;

            if(store_model_ref) {
                this.invalid_input_refs.push(model_ref);
            }

            validation_node.classList.add('with-error');
            validation_node.querySelector('.control-error-message').classList.remove('dn');
            deferred.resolve();
            return deferred.promise;
        }
        this.handleInvalidInputCheck = function handleInvalidInputCheck(input_type, model_ref, e, directive_scope) {
            var deferred = $q.defer();
            var validation_node = this.getErrorMessageNode(e, model_ref, input_type),
                store_model_ref = this.invalid_input_refs.indexOf(model_ref) < 0;

            if (store_model_ref) {
                this.invalid_input_refs.push(model_ref);
            }
            deferred.resolve();
            return deferred.promise;
        }

        /*==========================================================================================
         Hide error message and remove the model_ref from invalid_input_refs
         ==========================================================================================*/
        this.handleValidInput = function handleValidInput(input_type, model_ref, e, directive_scope) {
            var deferred = $q.defer(),
                validation_node = this.getErrorMessageNode(e, model_ref, input_type),
                invalid_input_ref_pos = this.invalid_input_refs.indexOf(model_ref),
                remove_model_ref = this.invalid_input_refs[invalid_input_ref_pos] !== undefined ||
                    this.invalid_input_refs[invalid_input_ref_pos] === model_ref;

            if(remove_model_ref) {
                this.invalid_input_refs.splice(invalid_input_ref_pos, 1);
            }

            validation_node.classList.remove('with-error');
            validation_node.querySelector('.control-error-message').classList.add('dn')
            deferred.resolve();
            return deferred.promise;
        }

        /*==========================================================================================
         Remove the model_ref of date input when date is selected in the dropdown
         ==========================================================================================*/
        this.removeDateInputModelFromInvalidInputRefs = function removeDateInputModelFromInvalidInputRefs(model_ref) {
            var invalid_input_ref_pos = this.invalid_input_refs.indexOf(model_ref),
                remove_model_ref = this.invalid_input_refs[invalid_input_ref_pos] !== undefined ||
                    this.invalid_input_refs[invalid_input_ref_pos] === model_ref;

            if(remove_model_ref) {
                this.invalid_input_refs.splice(invalid_input_ref_pos, 1);
            }
        }

        /*==========================================================================================
         Remove addrs1 model_ref from invalid_input_refs (if present) when its automatically set
         in the postcode look up form
         ==========================================================================================*/
        this.handleAddressValidation = function handleAddressValidation() {
            var addrs1_pos = this.invalid_input_refs.indexOf('addrs1'),
                remove_addrs1_ref = this.invalid_input_refs[addrs1_pos] !== undefined ||
                    this.invalid_input_refs[addrs1_pos] === 'addrs1';

            if(remove_addrs1_ref) {
                var validation_node = document.querySelectorAll('[ng-model="addrs1"]')[0].parentElement;

                this.invalid_input_refs.splice(addrs1_pos, 1);
                validation_node.classList.remove('with-error');
                this.notifyDirective();
            }
        }

        /*==========================================================================================
         In the postcode lookup the user pressed 'go back without saving' and the previous
         postcode was empty
         ==========================================================================================*/
        this.postcodeInputEmptied = function postcodeInputEmptied() {
            this.invalid_input_refs.push('postcode');
            this.notifyDirective();
        }

        /*==========================================================================================
         Tell directive re-check if form is valid
         ==========================================================================================*/
        this.notifyDirective = function notifyDirective() {
            $rootScope.$emit('panel_with_form:form_validity_changed');
        }

        /*==========================================================================================
         Get the '.required-block' element
         ==========================================================================================*/
        this.getErrorMessageNode = function getErrorMessageNode(e, model_ref, input_type) {
            var input,
                text_input = input_type === 'text',
                number_input = input_type === 'number',
                email_input = input_type ==='email',
                from_input = input_type ==='from',
                postcode_input = input_type === 'postcode',
                date_input = input_type === 'date',
                textarea_input = input_type === 'textarea',
                select_input = input_type === 'select',
                smart_filter_input = input_type === 'smart_filter',
                smart_filter_with_optgroup_input = input_type === 'smart_filter_with_optgroup',
                validation_node;

            if(select_input || smart_filter_input || smart_filter_with_optgroup_input) {
                input = document.querySelector("[ng-model=" + model_ref + "]");
            }else {
                input = e.target;
            }

            if(text_input || textarea_input || number_input || email_input || from_input) {
                validation_node = input.parentElement;
            }else if(postcode_input) {
                validation_node = input.parentElement.parentElement;
            }else if(date_input) {
                validation_node = input.parentElement.parentElement.parentElement.parentElement.parentElement;
            }else if(select_input || smart_filter_with_optgroup_input || smart_filter_input) {
                validation_node = input.parentElement.parentElement;
            }

            return validation_node;
        }

        /*==========================================================================================
         Assert whether the form has no errors
         ==========================================================================================*/
        this.isFormValid = function isFormValid() {
            var form_validity = this.invalid_input_refs.length < 1;
            return form_validity;
        }

        /*==========================================================================================
         Reset the invalid_input_refs, called when panel closes
         ==========================================================================================*/
        this.emptyInvalidInputRefs = function emptyInvalidInputRefs() {
            this.invalid_input_refs = [];
        }

        /*==========================================================================================
         Handle the server response
         ==========================================================================================*/
        this.handleFailedServerValidations = function handleFailedServerValidations(errors_obj) {
            for(var key in errors_obj) {
                if(errors_obj.hasOwnProperty(key)) {
                    var invalid_input_elm = document.querySelector("[ng-model=" + key + "]"),
                        error_message = errors_obj[key];

                    this.invalid_input_refs.push(key);
                    this.handleServerFailedInput(invalid_input_elm, error_message);
                }
            }
        }

        /*==========================================================================================
         Get error message element so that it can be shown and the error message text can be
         updated with the server error message
         ==========================================================================================*/
        this.handleServerFailedInput = function handleServerFailedInput(input_elm, error) {
            this.server_side_validation_failed = true;

            var text_input = input_elm.parentElement.classList.contains('required-block'),
                validation_node;

            if(text_input) {
                validation_node = input_elm.parentElement;
            }else {
                var postcode_input = input_elm.parentElement.parentElement.classList.contains('required-block'),
                    date_input = input_elm.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains('required-block');

                if(postcode_input) {
                    validation_node = input_elm.parentElement.parentElement;
                }else if(date_input) {
                    validation_node = input_elm.parentElement.parentElement.parentElement.parentElement.parentElement;
                }
            }

            this.showServerFailedError(validation_node, error);
        }

        /*==========================================================================================
         Show error message element displaying server error message
         ==========================================================================================*/
        this.showServerFailedError = function showServerFailedError(validation_node, error) {
            validation_node.classList.add('with-error');
            validation_node.querySelector('.control-error-message').childNodes[0].innerHTML = error;
            validation_node.querySelector('.control-error-message').classList.remove('dn');
        }

        /*==========================================================================================
         When input validation fails after its server side validation has already failed for it -
         reset the error mnessges with the clientside error message
         ==========================================================================================*/
        this.resetClientSideErrorMessages = function resetClientSideErrorMessages(input_elm, error_message, text_input, textarea_input, postcode_input, date_input, select_input, smart_filter_input, smart_filter_with_optgroup_input, number_input) {
            var validation_node;

            if(text_input || textarea_input || number_input) {
                validation_node = input_elm.parentElement;
            }else if(postcode_input) {
                validation_node = input_elm.parentElement.parentElement;
            }else if(date_input) {
                validation_node = input_elm.parentElement.parentElement.parentElement.parentElement.parentElement;
            }else if(select_input || smart_filter_with_optgroup_input) {
                validation_node = input_elm.parentElement;
            }else if(smart_filter_input) {
                validation_node = input_elm.parentElement.parentElement;
            }

            validation_node.querySelector('.control-error-message').childNodes[0].innerHTML = error_message;
        }

        this.getSelectedSmartFilterValue = function getSelectedSmartFilterValue(model_name) {
            return document.querySelector("[ng-model=" + model_name + "]").querySelector('.result-selected').innerHTML;
        }
    })
    .service('sharedPhraises', function($rootScope) {
        $rootScope.error_messages = {};

        this.create = function create() {
            $rootScope.error_messages.fatal_error = "There has been an error please contact us on 0123456789";
            $rootScope.error_messages.unsaved_sidepanel = "Form in progress. Are you sure you want to close side panel without saving?";
            $rootScope.error_messages.no_filters_present = "Please select a valid filter";
        }
    })
    .service('stringHelpers', function() {
        /*==========================================================================================
         Get the index / indexes of a word in a string
         ==========================================================================================*/
        this.findAllOccurances = function findAllOccurances(str, find) {
            var occurances = [];
            for(var i = 0; i < str.length; i++) {
                if(str.substring(i, i + find.length) == find) {
                    occurances.push(i);
                }
            }
            return occurances;
        }

        /*==========================================================================================
         Replace a part of a string with another string
         ==========================================================================================*/
        this.replaceStringAt = function replaceStringAt(str, index, str_to_replace, replacement) {
            return str.substring(0, index) + replacement + str.substring(index + str_to_replace.length);
        }
    })
    .service('emailService', function() {
        var blank_email_data = {
            "recipient_addresses" : [],
            "ccs" : [],
            "from_addresses" : [
                "joe@commusoft.co.uk",
                "jason@commusoft.co.uk",
                "callum@commusoft.co.uk",
                "will@commusoft.co.uk"
            ],
            "selected_from_address" : null,
            "selected_recipient_address" : null,
            "subject" : "",
            "message" : "",
            "available_files" : [
                {
                    id: 100,
                    file_name: 'Invoice no.321531',
                    file_size: 3
                },
                {
                    id: 101,
                    file_name: 'Invoice no.321532',
                    file_size: 7
                },
                {
                    id: 102,
                    file_name: 'Invoice no.321533',
                    file_size: 22
                },
                {
                    id: 103,
                    file_name: 'Invoice no.321534',
                    file_size: 6.2
                },
                {
                    id: 104,
                    file_name: 'Invoice no.321535',
                    file_size: 12.5
                },
                {
                    id: 105,
                    file_name: 'Invoice no.321536',
                    file_size: 15.2
                },
                {
                    id: 106,
                    file_name: 'Invoice no.321537',
                    file_size: 7.8
                },
                {
                    id: 107,
                    file_name: 'Invoice no.321538',
                    file_size: 4.7
                }
            ]
        }
        this.generateBlankEmailData = function generateBlankEmailData() {
            return blank_email_data;
        };

        this.decorateRowsFromAttachedFiles = function decorateRowsFromAttachedFiles(rows) {
            var files = [];
            for(var i = 0, l = rows.length; i < l; i++) {
                var row_file_id = rows[i][1].id,
                    row_file_name = rows[i][1].value,
                    row_file_size_string = "" + rows[i][2].value,
                    row_file_size = row_file_size_string.match(/[\.\d]+/g),
                    row_file_size = parseFloat(row_file_size.join(""));

                files.push({
                    id : row_file_id,
                    file_name : row_file_name,
                    file_size : row_file_size
                })
            }

            return files
        }
    })
    .service('emailWidget', function() {
        var available_attachments = [];

        var email_data = {
            "recipient_addresses" : [
                "harry@customer.co",
                "mark@customer.co",
                "alan@customer.co",
                "rob@customer.co"
            ],
            "from_addresses" : [
                "joe@local.co",
                "callum@local.co",
                "jason@local.co",
                "will@local.co"
            ]
        }

        this.getAvailableAttachments = function getAvailableAttachments() {
            return available_attachments;
        }

        this.setAvailableAttachments = function setAvailableAttachments(attach) {
            available_attachments = attach;
        }
        this.getEmailData = function getEmailData() {
            return email_data;
        }
    })
    .service('changeStatusDrop', function(){
        this.changeStatus = {};
        this.setData = function setData(category, fullObject) {
            this.changeStatus[category] = fullObject;
        }
        this.getData = function getData(category){
            return this.changeStatus[category]
        }
    })
    .service('fiveHundredError', function(){
        this.errorMessage = {};
        this.setData = function setData(fullObject) {
            this.errorMessage = fullObject;
        }
        this.getData = function getData(){
            return this.errorMessage;
        }
    })
    .service('dragHelper', function($rootScope, $timeout) {

        this.singleton_name = '';
        this.plural_name = '';

        var self;

        this.setNames = function setNames(singleton_name, plural_name) {
            this.singleton_name = singleton_name;
            this.plural_name = plural_name;
            self = this;
        }

        this.setContextData = function setContextData(context_data) {
            this.context_data = context_data;
            self = this;
        }

        /*==========================================================================================================================
         Setup variables for dragging ~ used when dragging a todo to a diary event
         ==========================================================================================================================*/
        var scroll_wrapper = document.querySelector('.commusoft_scroll_wrapper'),
            should_scroll_up = false,
            should_scroll_down = false;

        $rootScope.allowed_drag_start = true;

        /*==========================================================================================================================
         When an element on the page is dragged
         ==========================================================================================================================*/
        $rootScope.$on('ANGULAR_DRAG_START', function(scope, channel){
            if ($rootScope.allowed_drag_start === true) {
                $rootScope.allowed_drag_end = true;

                var dragging_elm = document.querySelector('.on-dragging');

                /*==========================================================================================================================
                 Create and move the helper
                 ==========================================================================================================================*/
                var helper_text;

                if (self.context_data !== undefined && self.context_data !== null) {
                    if (self.context_data.length > 1) {
                        helper_text = self.context_data.length + ' ' + self.plural_name + ' selected';
                    }else {
                        helper_text = '1 ' + self.singleton_name + ' selected';
                    }
                }else{
                    helper_text = '1 ' + self.singleton_name + ' selected';
                }

                document.body.insertAdjacentHTML('beforeend', '<div id="drag_helper">' + helper_text + '</div>');
                /*==========================================================================================================================
                 Do things on drag
                 ==========================================================================================================================*/
                var scroll_wrapper = document.querySelector('.commusoft_scroll_wrapper'),
                    document_height = window.innerHeight ||
                        document.documentElement.clientHeight ||
                        document.body.clientHeight,
                    thresh_modulus = document_height * 0.1,
                    top_thresh = thresh_modulus,
                    bottom_thresh = document_height - thresh_modulus,
                    helper = document.querySelector('#drag_helper');

                dragging_elm.addEventListener('drag', function(e){

                    if (helper) {
                        helper.style["-webkit-transform"] = 'translate(' + (e.pageX + 20) + 'px, ' + (e.pageY) + 'px)';
                    }

                    var mouse_pos = e.pageY;

                    if (mouse_pos < top_thresh && mouse_pos !== 0) {
                        should_scroll_up = true;
                    }else{
                        should_scroll_up = false;
                    }
                    if (mouse_pos > bottom_thresh) {
                        should_scroll_down = true;
                    }else{
                        should_scroll_down = false;
                    }
                    if (mouse_pos === 0) {
                        helper.style.visibility = 'hidden';
                    }else{
                        helper.style.visibility = 'visible';
                    }
                });
                window.setInterval(function(){
                    if (should_scroll_down === true) {
                        scroll_wrapper.scrollTop = scroll_wrapper.scrollTop + 15;
                    }
                    if (should_scroll_up === true) {
                        scroll_wrapper.scrollTop = scroll_wrapper.scrollTop - 15;
                    }
                }, 10);

                $rootScope.allowed_drag_start = false;
            }
        })

        /*==========================================================================================================================
         When an element on the page has finished dragging
         ==========================================================================================================================*/
        $rootScope.$on('ANGULAR_DRAG_END', function(scope, channel){
            $rootScope.allowed_drag_start = true;

            if ($rootScope.allowed_drag_end === true) {

                /*==========================================================================================================================
                 Animate and remove the helper
                 ==========================================================================================================================*/
                var helper = document.querySelector('#drag_helper');
                if (helper && helper.parentElement) {
                    helper.classList.add('puff-away');
                    $timeout(function(){
                        $( "#drag_helper" ).remove();
                    },600);

                    var drop_elm = document.querySelector('.on-drag-hover');
                    if (drop_elm) {
                        drop_elm.classList.add('highlight');
                        $timeout(function(){
                            drop_elm.classList.remove('highlight');
                        },100);
                    }

                }
            }
            $rootScope.allowed_drag_end = false;
        });
    })
    .service('sessionLogger', function() {
        this.startTime = this.endTime = 0;

        this.setStartTime = function(time) {
            this.startTime = time;
        }

        this.setEndTime = function(time) {
            this.endTime = time;
        }

        this.getDuration = function() {
            return this.endTime - this.startTime;
        }
     })
    .service('searchPageNumName', function() {

        this.getSearchPageNumName= function getSearchPageNumName() {
            var pageNumName = {
                "properties": "currentPageProperties",
                "suppliers": "currentPageSuppliers",
                "invoiceaddress": "currentPageInvoiceaddress",
                "estimates": "currentPageEstimates",
                "supplierspo": "currentPageSupplierspo",
                "suppliersinvoices": "currentPageSuppliersinvoices",
                "stocks": "currentPageStocks",
                "jobs": "currentPageJobs",
                "invoices": "currentPageInvoices",
                "customercreditnotes":"currentPageCustomercreditnotes",
                "suppliercreditnotes":"currentPageSuppliercreditnotes"
            }

            return pageNumName;
        }

        this.getSearchPageNumNameValue= function getSearchPageNumNameValue() {
            var pageNumNameValue = {};
            angular.forEach(this.getSearchPageNumName, function(val, key){
                pageNumNameValue[key] = 1;
            });
            return pageNumNameValue;
        }
    })
    .service('jobDiaryEvents', function($rootScope, $timeout, $q) {

        diary_event_pending = {
            id: 1000,
            timeline_id: 3,
            status: "Pending",
            labour_and_parts_invoiced: false,
            complete: false,
            already_invoiced: false,
            js_date: new Date('2015-01-15'),
            date: "Tuesday 15th January 2015",
            truncated_date: "Tues 15th Jan 2015",
            time: "10:15am to 12:15pm",
            special_event: false,
            start_time: '10:30',
            end_time: '12:30',
            duration: 2,
            travel_duration: 0,
            on_site_duration: 0,
            description: "Prepare the property for the new central heating installation",
            customer_name: 'Jason Morjaria',
            customer_contact_telephone: '01234567890',
            work_address_telephone: '01348271749',
            customer_contact_email: 'jason@demo.local',
            property_name: 'CommuSoft',
            property_contact_telephone: '01234567890',
            property_contact_email: 'info@commusoft.local',
            property_address_line1: '123',
            property_address_line2: 'Main Street',
            property_address_line3: 'Old Street',
            property_town: 'London',
            property_county: 'Greater London',
            property_postcode: 'E4 3AB',
            engineer_name: "Jason Morjaria",
            engineer_email: "jason@commusoft.co.uk",
            engineer_profile: 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=30',
            large_engineer_profile: 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=70',
            engineer_notes: "The property is detatched and gated, if you have trouble with access then please call the tennant",
            diary_event_type: 'Job',
            engineer_feedback: false,
            completed_todos: 0,
            available_todos: 4,
            parts_installed: 0,
            parts_decommissioned: 0,
            access: {
                method: 'Landlord has keys',
                notes: 'The key is silver with a square handle'
            },
            engineer_job_sheet: [
                {
                    name: 'Engineer Job Sheet',
                    sent: true,
                    method: 'Email',
                    date: '15th January 2015 at 10:15am',
                    audits: [
                        {
                            method: 'Email',
                            date: '16th January 2015 at 13:00pm',
                            recipient: 'callum@commusoft.co.uk',
                            subject: 'Job sheet',
                            message: 'Please find the attached job sheet for your upcoming visit'
                        }
                    ]
                }
            ],
            confirmations: [
                {
                    name: 'Customer Confirmation',
                    sent: false,
                    method: 'SMS',
                    date: '14th January 2015 at 13:40pm',
                    type: 'confirmation',
                    audits: []
                },
                {
                    name: 'Work Address Confirmation',
                    sent: false,
                    method: 'Letter',
                    date: '14th January 2015 at 13:40pm',
                    type: 'confirmation',
                    audits: []
                },
                {
                    name: 'Work Address Reminder',
                    sent: false,
                    method: 'Letter',
                    date: '14th January 2015 at 13:40pm',
                    type: 'reminder',
                    audits: []
                }
            ],
            parts: [],
            keys: [
                {
                    key: 'Landlord Keys',
                    status: 'Not collected'
                }
            ],
            todos: [
                {
                    id: 1,
                    status: "Pending",
                    type: "general",
                    description: 'Assess current central heating system',
                    milestone_index: 0
                },
                {
                    id: 2,
                    status: "Pending",
                    type: "general",
                    description: 'Diagnose any current faults with the existing system',
                    milestone_index: 0
                },
                {
                    id: 3,
                    status: "Pending",
                    type: "general",
                    description: 'Assess how beneficial a new system will be',
                    milestone_index: 0
                },
                {
                    id: 4,
                    status: "Pending",
                    type: "photo",
                    description: 'Take a photograph of the parking location',
                    milestone_index: 0
                },
                {
                    id: 5,
                    status: "Done",
                    type: "photo",
                    description: 'Take a photograph of the existing boiler',
                    milestone_index: 0,
                    complete: {
                        time: '10:00',
                        photo: '../images/leaking-pipe.jpg',
                        notes: 'The pipework is ruptured at the bottom. This is likely due to the pressure'
                    },
                    deleted: true,
                    deleted_by: 'Joseph Luck',
                    deleted_on: '13th July 2015 at 13:12PM'
                }
            ],
            feedback: {
                arrive_on_site: {
                    name: 'Arrive on site',
                    questions: [
                        {
                            name: 'Is there parking on site?',
                            time: '11:29am',
                            type: 'select',
                            answered: false
                        },
                        {
                            name: 'Is the property easy to access?',
                            time: '11:33am',
                            type: 'input',
                            answered: false
                        },
                        {
                            name: 'How is the boiler accessed?',
                            time: '11:57am',
                            type: 'textarea',
                            answered: false
                        }
                    ],
                    signatures: []
                },
                leave_site: {
                    name: 'Leave site',
                    questions: [
                        {
                            name: 'Did you leave the property tidy?',
                            time: '13:42pm',
                            type: 'select',
                            answered: false
                        }
                    ],
                    signatures: []
                }
            },
            geolocation: [
            ]
        };

        diary_event_accept = {
            id: 2000,
            timeline_id: 3,
            status: "Accepted",
            complete: false,
            already_invoiced: false,
            labour_and_parts_invoiced: false,
            js_date: new Date('2015-01-17'),
            date: "Thursday 17th January 2015",
            truncated_date: "Thu 17th Jan 2015",
            time: "AM (9:00am to 12:00pm)",
            special_event: false,
            start_time: '09:00',
            end_time: '12:00',
            duration: 3,
            travel_duration: 0,
            on_site_duration: 0,
            description: "Remove / strip out the existing system and pipework",
            customer_name: 'Jason Morjaria',
            customer_contact_telephone: '01234567890',
            work_address_telephone: '01348271749',
            customer_contact_email: 'jason@demo.local',
            property_name: 'CommuSoft',
            property_contact_telephone: '01234567890',
            property_contact_email: 'info@commusoft.local',
            property_address_line1: '123',
            property_address_line2: 'Main Street',
            property_address_line3: 'Old Street',
            property_town: 'London',
            property_county: 'Greater London',
            property_postcode: 'E4 3AB',
            engineer_name: "Callum Hart",
            engineer_email: "callum@commusoft.co.uk",
            engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
            large_engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=70',
            engineer_notes: "Ensure that you dispose of the metal work securely and safetly.",
            engineer_feedback: false,
            diary_event_type: 'Job',
            completed_todos: 0,
            available_todos: 4,
            parts_installed: 0,
            parts_decommissioned: 0,
            access: {
                method: 'Landlord has keys',
                notes: 'The key is silver with a square handle'
            },
            engineer_job_sheet: [
                {
                    name: 'Engineer Job Sheet',
                    sent: true,
                    method: 'Email',
                    date: '16th January 2015 at 10:20am',
                    audits: [
                        {
                            method: 'Email',
                            date: '16th January 2015 at 13:00pm',
                            recipient: 'callum@commusoft.co.uk',
                            subject: 'Job sheet',
                            message: 'Please find the attached job sheet for your upcoming visit'
                        }
                    ]
                }
            ],
            confirmations: [
                {
                    name: 'Customer Confirmation',
                    sent: false,
                    method: 'SMS',
                    date: '16th January 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: []
                },
                {
                    name: 'Work Address Confirmation',
                    sent: false,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: []
                },
                {
                    name: 'Work Address Reminder',
                    sent: false,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'reminder',
                    audits: []
                }
            ],
            parts: [],
            keys: [
                {
                    key: 'Landlord Keys',
                    status: 'Not collected'
                }
            ],
            todos: [
                {
                    id: 5,
                    status: "Pending",
                    type: "decommission",
                    description: 'Decommission the existing radiators',
                    appliance: 'Radiator',
                    estimated_hours: '4 hours',
                    milestone_index: 0
                },
                {
                    id: 6,
                    status: "Pending",
                    type: "decommission",
                    description: 'Remove all remaining pipework',
                    appliance: 'Pipes',
                    estimated_hours: '2 hours',
                    milestone_index: 0
                }
            ],
            feedback: {
                arrive_on_site: {
                    name: 'Arrive on site',
                    questions: [
                        {
                            name: 'Is there parking on site?',
                            time: '11:29am',
                            type: 'select',
                            answered: false
                        },
                        {
                            name: 'Is the property easy to access?',
                            time: '11:33am',
                            type: 'input',
                            answered: false
                        },
                        {
                            name: 'How is the boiler accessed?',
                            time: '11:57am',
                            type: 'textarea',
                            answered: false
                        }
                    ],
                    signatures: []
                },
                leave_site: {
                    name: 'Leave site',
                    questions: [
                        {
                            name: 'Did you leave the property tidy?',
                            time: '13:42pm',
                            type: 'select',
                            answered: false
                        }
                    ],
                    signatures: []
                }
            },
            geolocation: [
                {
                    status: 'Accepted',
                    time: '10:32am',
                    real_time: '10:32'
                }
            ]
        };

        diary_event_travel = {
            id: 3000,
            timeline_id: 3,
            status: "Travel",
            complete: false,
            already_invoiced: false,
            labour_and_parts_invoiced: false,
            js_date: new Date('2015-01-24'),
            date: "Tuesday 24th January 2015",
            truncated_date: "Tues 24th Jan 2015",
            time: "between 11:00am and 12:30pm",
            special_event: true,
            start_time: '11:00',
            end_time: '12:30',
            duration: 1.5,
            travel_duration: 0.5,
            on_site_duration: 0,
            description: "Prepare property for new central heating system",
            customer_name: 'Jason Morjaria',
            customer_contact_telephone: '01234567890',
            work_address_telephone: '01348271749',
            customer_contact_email: 'jason@demo.local',
            property_name: 'CommuSoft',
            property_contact_telephone: '01234567890',
            property_contact_email: 'info@commusoft.local',
            property_address_line1: '123',
            property_address_line2: 'Main Street',
            property_address_line3: 'Old Street',
            property_town: 'London',
            property_county: 'Greater London',
            property_postcode: 'E4 3AB',
            engineer_name: "Jason Morjaria",
            engineer_email: "jason@commusoft.co.uk",
            engineer_profile: 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=30',
            large_engineer_profile: 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=70',
            engineer_notes: "The property needs to be safe and secure when you leave.",
            engineer_feedback: false,
            diary_event_type: 'Job',
            completed_todos: 0,
            available_todos: 4,
            parts_installed: 0,
            parts_decommissioned: 0,
            access: {
                method: 'Landlord has keys',
                notes: 'The key is silver with a square handle'
            },
            engineer_job_sheet: [
                {
                    name: 'Engineer Job Sheet',
                    sent: true,
                    method: 'Email',
                    date: '16th January 2015 at 13:00pm',
                    audits: [
                        {
                            method: 'Email',
                            date: 'Tuesday 24th January 2015 at 10:00am',
                            recipient: 'callum@commusoft.co.uk',
                            subject: 'Job sheet',
                            message: 'Please find the attached job sheet for your upcoming visit'
                        }
                    ]
                }
            ],
            confirmations: [
                {
                    name: 'Customer Confirmation',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                },
                {
                    name: 'Work Address Confirmation',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                },
                {
                    name: 'Work Address Reminder',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                }
            ],
            parts: [],
            keys: [
                {
                    key: 'Landlord Keys',
                    status: 'Collected',
                    picked_up: {
                        date: 'Tuesday 24th January 2015 at 10:00am',
                        property_name: 'Foxtons',
                        property_address_line1: '193',
                        property_address_line2: 'Old Road',
                        property_address_line3: 'Old Street',
                        property_town: 'London',
                        property_county: 'Greater London',
                        property_postcode: 'E5 9AR'
                    }
                }
            ],
            todos: [
                {
                    id: 7,
                    status: "Pending",
                    type: "general",
                    description: 'Clean the property in preperation for new installation',
                    milestone_index: 0
                }
            ],
            feedback: {
                arrive_on_site: {
                    name: 'Arrive on site',
                    questions: [
                        {
                            name: 'Is there parking on site?',
                            time: '11:29am',
                            type: 'select',
                            answered: false
                        },
                        {
                            name: 'Is the property easy to access?',
                            time: '11:33am',
                            type: 'input',
                            answered: false
                        },
                        {
                            name: 'How is the boiler accessed?',
                            time: '11:57am',
                            type: 'textarea',
                            answered: false
                        }
                    ],
                    signatures: []
                },
                leave_site: {
                    name: 'Leave site',
                    questions: [
                        {
                            name: 'Did you leave the property tidy?',
                            time: '13:42pm',
                            type: 'select',
                            answered: false
                        }
                    ],
                    signatures: []
                }
            },
            geolocation: [
                {
                    status: 'Accepted',
                    time: '10:32am',
                    real_time: '10:32'
                },
                {
                    status: 'Travel',
                    time: '11:05am',
                    real_time: '11:05'
                }
            ]
        };

        diary_event_arrive = {
            id: 4000,
            timeline_id: 3,
            status: "Arrived",
            complete: false,
            already_invoiced: false,
            labour_and_parts_invoiced: false,
            js_date: new Date('2015-01-16'),
            date: "Wednesday 16th January 2015",
            truncated_date: "Wed 25th Jan 2015",
            time: "All day (9:00am to 18:00pm)",
            special_event: false,
            start_time: '09:00',
            end_time: '18:00',
            duration: 9,
            travel_duration: 1.4,
            on_site_duration: 0,
            description: "Install the pipework for the new central heating system",
            customer_name: 'Jason Morjaria',
            customer_contact_telephone: '01234567890',
            work_address_telephone: '01348271749',
            customer_contact_email: 'jason@demo.local',
            property_name: 'CommuSoft',
            property_contact_telephone: '01234567890',
            property_contact_email: 'info@commusoft.local',
            property_address_line1: '123',
            property_address_line2: 'Main Street',
            property_address_line3: 'Old Street',
            property_town: 'London',
            property_county: 'Greater London',
            property_postcode: 'E4 3AB',
            engineer_name: "Joseph Luck",
            engineer_email: "joseph@commusoft.co.uk",
            engineer_profile: 'https://s.gravatar.com/avatar/453a7a095109ec93f419a54ad19956f6?s=30',
            large_engineer_profile: 'https://s.gravatar.com/avatar/453a7a095109ec93f419a54ad19956f6?s=70',
            engineer_notes: "Make sure that you take a photograph of the pipework when you are done for our records.",
            engineer_feedback: true,
            diary_event_type: 'Job',
            completed_todos: 0,
            available_todos: 4,
            parts_installed: 0,
            parts_decommissioned: 0,
            access: {
                method: 'Landlord has keys',
                notes: 'The key is silver with a square handle'
            },
            engineer_job_sheet: [
                {
                    name: 'Engineer Job Sheet',
                    sent: true,
                    method: 'Email',
                    date: 'Monday 10th January 2015 at 13:00pm',
                    audits: [
                        {
                            method: 'Email',
                            date: 'Monday 10th January 2015 at 13:00pm',
                            recipient: 'callum@commusoft.co.uk',
                            subject: 'Job sheet',
                            message: 'Please find the attached job sheet for your upcoming visit'
                        }
                    ]
                }
            ],
            confirmations: [
                {
                    name: 'Customer Confirmation',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                },
                {
                    name: 'Work Address Confirmation',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                },
                {
                    name: 'Work Address Reminder',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                }
            ],
            parts: [
                {
                    id: 1,
                    part: 'CAP',
                    status: 'Picked up',
                    quantity: 3,
                    price: 150,
                    already_invoiced: false,
                    engineer_instructions: 'Collect from supplier',
                    picked_up: {
                        date: 'Tuesday 11th January 2015 at 8:52am'
                    }
                },
                {
                    id: 2,
                    part: 'CLIP 8',
                    status: 'Picked up',
                    quantity: 1,
                    price: 10,
                    already_invoiced: false,
                    engineer_instructions: 'Collect from supplier',
                    picked_up: {
                        date: 'Tuesday 11th January 2015 at 8:52am'
                    }
                },
                {
                    id: 3,
                    part: 'DRIVE SPROCKET 35MM HA',
                    status: 'Picked up',
                    quantity: 4,
                    price: 120,
                    already_invoiced: false,
                    engineer_instructions: 'Collect from supplier',
                    picked_up: {
                        date: 'Tuesday 11th January 2015 at 8:52am'
                    }
                },
                {
                    id: 4,
                    part: 'ZTG-STAB 11X1620',
                    status: 'Picked up',
                    quantity: 10,
                    price: 4,
                    already_invoiced: false,
                    engineer_instructions: 'Collect from supplier',
                    picked_up: {
                        date: 'Tuesday 11th January 2015 at 8:52am'
                    }
                }
            ],
            keys: [
                {
                    key: 'Landlord Keys',
                    status: 'Collected',
                    picked_up: {
                        date: 'Tuesday 11th January 2015 at 18:31pm',
                        property_name: 'Foxtons',
                        property_address_line1: '193',
                        property_address_line2: 'Old Road',
                        property_address_line3: 'Old Street',
                        property_town: 'London',
                        property_county: 'Greater London',
                        property_postcode: 'E5 9AR'
                    }
                }
            ],
            todos: [
                {
                    id: 8,
                    status: "Pending",
                    type: "install",
                    description: 'Install the pipework for the new central heating system',
                    item: 'Pipework',
                    estimated_hours: '4 hours',
                    quantity: '14',
                    quantity_installed: '0',
                    part_status: "Available",
                    milestone_index: 0
                },
                {
                    id: 9,
                    status: "Pending",
                    type: "photo",
                    description: 'Take a photograph of the pipework schema for our records',
                    milestone_index: 0
                },
                {
                    id: 10,
                    status: "Pending",
                    type: "general",
                    description: 'Ask the customer if they are happy with the placement of the new radiators',
                    milestone_index: 0
                }
            ],
            feedback: {
                arrive_on_site: {
                    name: 'Arrive on site',
                    questions: [
                        {
                            name: 'Is there parking on site?',
                            time: '11:29am',
                            type: 'select',
                            answer: 'Yes',
                            answered: true
                        },
                        {
                            name: 'Is the property easy to access?',
                            time: '11:33am',
                            type: 'input',
                            answer: 'Yes, the property is a gated detached house',
                            answered: true
                        },
                        {
                            name: 'How is the boiler accessed?',
                            time: '11:57am',
                            type: 'textarea',
                            answer: 'The boiler is in the kitchen inside a cupboard',
                            answered: true
                        }
                    ],
                    signatures: [
                        {
                            name: 'Adam Shaw',
                            title: 'tenant',
                            time: '11:37am',
                            url: 'images/signature.png'
                        }
                    ]
                },
                leave_site: {
                    name: 'Leave site',
                    questions: [
                        {
                            name: 'Did you leave the property tidy?',
                            time: '17:42pm',
                            type: 'select',
                            answered: false
                        }
                    ],
                    signatures: []
                }
            },
            geolocation: [
                {
                    status: 'Accepted',
                    time: '10:32am',
                    real_time: '10:32'
                },
                {
                    status: 'Travel',
                    time: '11:05am',
                    real_time: '11:05'
                },
                {
                    status: 'Arrived',
                    time: '11:28am',
                    real_time: '11:28'
                }
            ]
        };

        diary_event_no_access = {
            id: 5000,
            timeline_id: 3,
            status: "No Access",
            complete: true,
            already_invoiced: false,
            labour_and_parts_invoiced: false,
            js_date: new Date('2015-01-05'),
            date: "Thursday 5th January 2015",
            truncated_date: "Thu 5th Jan 2015",
            time: "PM (13:00pm to 18:00pm)",
            special_event: false,
            start_time: '13:00',
            end_time: '18:00',
            duration: 5,
            travel_duration: 0.8,
            on_site_duration: 0,
            description: "Install the new radiators",
            customer_name: 'Jason Morjaria',
            customer_contact_telephone: '01234567890',
            work_address_telephone: '01348271749',
            customer_contact_email: 'jason@demo.local',
            property_name: 'CommuSoft',
            property_contact_telephone: '01234567890',
            property_contact_email: 'info@commusoft.local',
            property_address_line1: '123',
            property_address_line2: 'Main Street',
            property_address_line3: 'Old Street',
            property_town: 'London',
            property_county: 'Greater London',
            property_postcode: 'E4 3AB',
            engineer_name: "Callum Hart",
            engineer_email: "callum@commusoft.co.uk",
            engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
            large_engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=70',
            engineer_notes: "Be careful when installing the hallway radiator. Make sure that it doesn't obstruct the front door",
            engineer_feedback: false,
            diary_event_type: 'Job',
            completed_todos: 0,
            available_todos: 4,
            parts_installed: 0,
            parts_decommissioned: 0,
            access: {
                method: 'Landlord has keys',
                notes: 'The key is silver with a square handle'
            },
            engineer_job_sheet: [
                {
                    name: 'Engineer Job Sheet',
                    sent: true,
                    method: 'Email',
                    date: 'Wednesday 10th January at 13:00pm',
                    audits: [
                        {
                            method: 'Email',
                            date: '16th January 2015 at 13:00pm',
                            recipient: 'callum@commusoft.co.uk',
                            subject: 'Job sheet',
                            message: 'Please find the attached job sheet for your upcoming visit'
                        }
                    ]
                }
            ],
            confirmations: [
                {
                    name: 'Customer Confirmation',
                    sent: true,
                    method: 'Letter',
                    date: 'Wednesday 10th January at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: 'Wednesday 10th January at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                },
                {
                    name: 'Work Address Confirmation',
                    sent: true,
                    method: 'Letter',
                    date: 'Wednesday 10th January at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: 'Wednesday 10th January at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                },
                {
                    name: 'Work Address Reminder',
                    sent: true,
                    method: 'Letter',
                    date: 'Wednesday 10th January at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: 'Wednesday 10th January at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                }
            ],
            parts: [
                {
                    id: 5,
                    part: 'Compression Wallplate Elbow 12 x 15mm',
                    status: 'Picked up',
                    quantity: 1,
                    price: 300,
                    already_invoiced: false,
                    engineer_instructions: 'Collect from supplier',
                    picked_up: {
                        date: 'Wednesday 10th January at 13:00pm at 9:12am'
                    }
                },
                {
                    id: 6,
                    part: 'Wickes Solder Ring Elbow 15mm Pack 10',
                    status: 'Picked up',
                    quantity: 1,
                    price: 300,
                    already_invoiced: false,
                    engineer_instructions: 'Collect from supplier',
                    picked_up: {
                        date: 'Wednesday 10th January at 13:00pm at 9:12am'
                    }
                }
            ],
            keys: [
                {
                    key: 'Landlord Keys',
                    status: 'Collected',
                    picked_up: {
                        date: 'Wednesday 10th January at 13:00pm at 10:46am',
                        property_name: 'Foxtons',
                        property_address_line1: '193',
                        property_address_line2: 'Old Road',
                        property_address_line3: 'Old Street',
                        property_town: 'London',
                        property_county: 'Greater London',
                        property_postcode: 'E5 9AR'
                    }
                }
            ],
            todos: [
                {
                    id: 11,
                    status: "Pending",
                    type: "install",
                    description: 'Install the radiators',
                    item: 'Radiator',
                    estimated_hours: '8 Hours',
                    quantity: '10',
                    quantity_installed: '0',
                    part_status: "Available",
                    milestone_index: 0
                },
                {
                    id: 12,
                    status: "Pending",
                    type: "photo",
                    description: 'Photograph each radiator and the valve location',
                    milestone_index: 0
                },
                {
                    id: 13,
                    status: "Pending",
                    type: "general",
                    description: 'Make sure the property is tidy before leaving',
                    milestone_index: 0
                }
            ],
            feedback: {
                arrive_on_site: {
                    name: 'Arrive on site',
                    questions: [
                        {
                            name: 'Is there parking on site?',
                            time: '13:29pm',
                            type: 'select',
                            answered: false
                        },
                        {
                            name: 'Is the property easy to access?',
                            time: '13:33pm',
                            type: 'input',
                            answered: false
                        },
                        {
                            name: 'How is the boiler accessed?',
                            time: '13:57pm',
                            type: 'textarea',
                            answered: false
                        }
                    ],
                    signatures: []
                },
                leave_site: {
                    name: 'Leave site',
                    questions: [
                        {
                            name: 'Did you leave the property tidy?',
                            time: '13:42pm',
                            type: 'select',
                            answered: false
                        }
                    ],
                    signatures: []
                }
            },
            geolocation: [
                {
                    status: 'Accepted',
                    time: '10:32am',
                    real_time: '10:32'
                },
                {
                    status: 'Travel',
                    time: '11:05am',
                    real_time: '13:05'
                },
                {
                    status: 'No access',
                    time: '12:15am',
                    real_time: '14:15'
                }
            ],
            no_access_photo: {
                url: 'images/front-door.jpg'
            }
        };

        diary_event_leave = {
            id: 6000,
            timeline_id: 3,
            status: "Left",
            complete: true,
            already_invoiced: false,
            labour_and_parts_invoiced: true,
            js_date: new Date('2015-01-27'),
            date: "Friday 27th January 2015",
            truncated_date: "Fri 27th Jan 2015",
            time: "14:30am to 18:00pm",
            special_event: false,
            start_time: '14:30',
            end_time: '18:00',
            duration: 3.5,
            travel_duration: 0.5,
            on_site_duration: 2,
            description: "Perform all the prep work for installing the new boiler",
            customer_name: 'Jason Morjaria',
            customer_contact_telephone: '01234567890',
            work_address_telephone: '01348271749',
            customer_contact_email: 'jason@demo.local',
            property_name: 'CommuSoft',
            property_contact_telephone: '01234567890',
            property_contact_email: 'info@commusoft.local',
            property_address_line1: '123',
            property_address_line2: 'Main Street',
            property_address_line3: 'Old Street',
            property_town: 'London',
            property_county: 'Greater London',
            property_postcode: 'E4 3AB',
            engineer_name: "Joseph Luck",
            engineer_email: "joseph@commusoft.co.uk",
            engineer_profile: 'https://s.gravatar.com/avatar/453a7a095109ec93f419a54ad19956f6?s=30',
            large_engineer_profile: 'https://s.gravatar.com/avatar/453a7a095109ec93f419a54ad19956f6?s=70',
            engineer_notes: "Please note that the boiler is difficult to access, if you need help, call John",
            engineer_feedback: true,
            diary_event_type: 'Job',
            completed_todos: 3,
            available_todos: 4,
            parts_installed: 0,
            parts_decommissioned: 1,
            access: {
                method: 'Landlord has keys',
                notes: 'The key is silver with a square handle'
            },
            engineer_job_sheet: [
                {
                    name: 'Engineer Job Sheet',
                    sent: true,
                    method: 'Email',
                    date: '13th September 2014 at 13:00pm',
                    audits: [
                        {
                            method: 'Email',
                            date: '13th September 2014 at 13:00pm',
                            recipient: 'callum@commusoft.co.uk',
                            subject: 'Job sheet',
                            message: 'Please find the attached job sheet for your upcoming visit'
                        }
                    ]
                }
            ],
            confirmations: [
                {
                    name: 'Customer Confirmation',
                    sent: true,
                    method: 'SMS',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'SMS',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                },
                {
                    name: 'Work Address Confirmation',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                },
                {
                    name: 'Work Address Reminder',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'reminder',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                }
            ],
            parts: [
                {
                    id: 7,
                    part: 'Ariston E-Combi Evo 30HE Condensing Combi Gas Boiler',
                    status: 'Installed',
                    quantity: 1,
                    price: 300,
                    already_invoiced: false,
                    engineer_instructions: 'Collect from supplier',
                    picked_up: {
                        date: 'Friday 9th January 2015 at 12:23pm'
                    },
                    installed: {
                        date: 'Friday 9th January 2015 at 16:10pm'
                    }
                },
                {
                    id: 8,
                    part: '"6" 316 / 316 Flexible Flue Liner',
                    status: 'Installed',
                    quantity: 3,
                    price: 300,
                    already_invoiced: false,
                    engineer_instructions: 'Collect from supplier',
                    picked_up: {
                        date: 'Friday 9th January 2015 at 12:23pm'
                    },
                    installed: {
                        date: 'Friday 9th January 2015 at 18:52pm'
                    }
                }
            ],
            keys: [
                {
                    key: 'Landlord Keys',
                    status: 'Dropped off',
                    picked_up: {
                        date: 'Tuesday 24th January 2015 at 13:00pm',
                        property_name: 'Foxtons',
                        property_address_line1: '193',
                        property_address_line2: 'Old Road',
                        property_address_line3: 'Old Street',
                        property_town: 'London',
                        property_county: 'Greater London',
                        property_postcode: 'E5 9AR'
                    },
                    dropped_off: {
                        date: '14th November 2014 at 12:23pm',
                        property_name: 'Foxtons',
                        property_address_line1: '193',
                        property_address_line2: 'Old Road',
                        property_address_line3: 'Old Street',
                        property_town: 'London',
                        property_county: 'Greater London',
                        property_postcode: 'E5 9AR'
                    }
                }
            ],
            todos: [
                {
                    id: 14,
                    status: "Done",
                    type: "decommission",
                    description: 'Old boiler is defective',
                    appliance: 'Vaillant EcoTech',
                    estimated_hours: '3.2 Hours',
                    complete: {
                        time: '10:00',
                        notes: 'The boiler was difficult to remove'
                    },
                    milestone_index: 0
                },
                {
                    id: 12,
                    status: "Done",
                    type: "general",
                    description: 'Tidy up property',
                    complete: {
                        time: '10:00',
                        notes: 'I tidied up the property, there was a lot of junk near the pipework in the kitchen'
                    },
                    milestone_index: 0
                }
            ],
            feedback: {
                arrive_on_site: {
                    name: 'Arrive on site',
                    questions: [
                        {
                            name: 'Is there parking on site?',
                            time: '11:29am',
                            type: 'select',
                            answer: 'Yes',
                            answered: true
                        },
                        {
                            name: 'Is the property easy to access?',
                            time: '11:33am',
                            type: 'input',
                            answer: 'Yes, the property is a gated detached house',
                            answered: true
                        },
                        {
                            name: 'How is the boiler accessed?',
                            time: '11:57am',
                            type: 'textarea',
                            answer: 'The boiler is in the kitchen inside a cupboard',
                            answered: true
                        }
                    ],
                    signatures: [
                        {
                            name: 'Adam Shaw',
                            title: 'tenant',
                            time: '11:37am',
                            url: 'images/signature.png'
                        }
                    ]
                },
                leave_site: {
                    name: 'Leave site',
                    questions: [
                        {
                            name: 'Did you leave the property tidy?',
                            time: '13:42pm',
                            type: 'select',
                            answer: 'Yes',
                            answered: true
                        },
                        {
                            name: 'Did you get feedback from the customer?',
                            time: '13:42pm',
                            type: 'select',
                            answer: 'Yes',
                            answered: true
                        },
                        {
                            name: 'Was the customer interested in any of our other services?',
                            time: '13:42pm',
                            type: 'select',
                            answer: 'No',
                            answered: true
                        },
                        {
                            name: 'Did you complete all the work?',
                            time: '13:42pm',
                            type: 'select',
                            answer: 'Yes',
                            answered: true
                        }
                    ],
                    signatures: [
                        {
                            name: 'Adam Shaw',
                            title: 'tenant',
                            time: '13:44pm',
                            url: 'images/signature.png'
                        }
                    ]
                }
            },
            geolocation: [
                {
                    status: 'Accepted',
                    time: '10:32am',
                    real_time: '10:32'
                },
                {
                    status: 'Travel',
                    time: '11:05am',
                    real_time: '11:05'
                },
                {
                    status: 'Arrived',
                    time: '11:28am',
                    real_time: '11:28'
                },
                {
                    status: 'Left',
                    time: '13:45pm',
                    real_time: '13:45'
                }
            ]
        };

        diary_event_abort = {
            id: 8000,
            timeline_id: 3,
            status: "Aborted",
            complete: true,
            already_invoiced: false,
            labour_and_parts_invoiced: false,
            js_date: new Date('2015-01-30'),
            date: "Monday 30th January 2015",
            truncated_date: "Mon 30th Jan 2015",
            time: "11:00am to 12:30pm",
            special_event: false,
            start_time: '11:00',
            end_time: '12:30',
            duration: 1.5,
            travel_duration: 0.4,
            on_site_duration: 0.3,
            description: "Please install the radiator valves and clean the property",
            customer_name: 'Jason Morjaria',
            customer_contact_telephone: '01234567890',
            work_address_telephone: '01348271749',
            customer_contact_email: 'jason@demo.local',
            property_name: 'CommuSoft',
            property_contact_telephone: '01234567890',
            property_contact_email: 'info@commusoft.local',
            property_address_line1: '123',
            property_address_line2: 'Main Street',
            property_address_line3: 'Old Street',
            property_town: 'London',
            property_county: 'Greater London',
            property_postcode: 'E4 3AB',
            engineer_name: "Callum Hart",
            engineer_email: "callum@commusoft.co.uk",
            engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
            large_engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=70',
            engineer_notes: "Beware that parking is limited, please take a photo of where you park.",
            engineer_feedback: false,
            diary_event_type: 'Job',
            completed_todos: 0,
            available_todos: 4,
            parts_installed: 0,
            parts_decommissioned: 0,
            access: {
                method: 'Landlord has keys',
                notes: 'The key is silver with a square handle'
            },
            engineer_job_sheet: [
                {
                    name: 'Engineer Job Sheet',
                    sent: true,
                    method: 'Email',
                    date: '16th January 2015 at 13:00pm',
                    audits: [
                        {
                            method: 'Email',
                            date: '16th January 2015 at 13:00pm',
                            recipient: 'callum@commusoft.co.uk',
                            subject: 'Job sheet',
                            message: 'Please find the attached job sheet for your upcoming visit'
                        }
                    ]
                }
            ],
            confirmations: [
                {
                    name: 'Customer Confirmation',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                },
                {
                    name: 'Work Address Confirmation',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                },
                {
                    name: 'Work Address Reminder',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                }
            ],
            parts: [
                {
                    id: 9,
                    part: 'Boiler',
                    status: 'Picked up',
                    quantity: 2,
                    price: 4000,
                    already_invoiced: false,
                    engineer_instructions: 'Collect from supplier',
                    picked_up: {
                        date: '12th November 2014 at 12:23pm'
                    }
                }
            ],
            keys: [
                {
                    key: 'Landlord Keys',
                    status: 'Collected',
                    picked_up: {
                        date: 'Tuesday 24th January 2015 at 13:00pm',
                        property_name: 'Foxtons',
                        property_address_line1: '193',
                        property_address_line2: 'Old Road',
                        property_address_line3: 'Old Street',
                        property_town: 'London',
                        property_county: 'Greater London',
                        property_postcode: 'E5 9AR'
                    }
                }
            ],
            todos: [
                {
                    id: 10,
                    status: "Done",
                    type: "certificate",
                    description: 'GSC for boiler',
                    certificate: 'CP4 - Gas Safety Inspection',
                    estimated_hours: '2.5 Hours',
                    complete: {
                        time: '10:00',
                        certificate: '',
                        notes: 'Boiler inspection passed'
                    },
                    milestone_index: 0
                },
                {
                    id: 11,
                    status: "Done",
                    type: "decommission",
                    description: 'Old boiler is defective',
                    appliance: 'Vaillant EcoTech',
                    estimated_hours: '3.2 Hours',
                    complete: {
                        time: '10:00',
                        notes: 'The boiler was difficult to remove'
                    },
                    milestone_index: 0
                },
                {
                    id: 12,
                    status: "Done",
                    type: "general",
                    description: 'Tidy up property',
                    complete: {
                        time: '10:00',
                        notes: 'I tidied up the property, there was a lot of junk near the pipework in the kitchen'
                    },
                    milestone_index: 0
                },
                {
                    id: 13,
                    status: "Pending",
                    type: "install",
                    description: 'Install the radiator valves',
                    item: 'Radiator Valves',
                    estimated_hours: '1 Hours',
                    quantity: '50',
                    quantity_installed: '34',
                    part_status: "Available",
                    complete: {
                        time: '10:00',
                        notes: 'There wasnt enough time to install all of the valves'
                    },
                    milestone_index: 0
                },
                {
                    id: 14,
                    status: "Done",
                    type: "maintenance",
                    description: 'This is urgent',
                    appliance: 'Vaillant EcoTech',
                    estimated_hours: '1.4 Hours',
                    complete: {
                        time: '10:00',
                        notes: 'Tightened up fixtures and fittings'
                    },
                    milestone_index: 0
                },
                {
                    id: 17,
                    status: "Done",
                    type: "breakdown",
                    description: 'This is urgent',
                    appliance: 'Vaillant EcoTech',
                    estimated_hours: '1.4 Hours',
                    complete: {
                        time: '10:00',
                        notes: 'Tightened up fixtures and fittings'
                    },
                    milestone_index: 0
                },
                {
                    id: 15,
                    status: "Done",
                    type: "photo",
                    description: 'Take a photograph of the existing pipework, make sure you photograph all seals and fittings',
                    complete: {
                        time: '10:00',
                        photo: '../images/leaking-pipe.jpg',
                        notes: 'The pipework is ruptured at the bottom. This is likely due to the pressure'
                    },
                    milestone_index: 0
                },
                {
                    id: 16,
                    status: "Done",
                    type: "service",
                    description: 'This is urgent',
                    appliance: 'Vaillant EcoTech',
                    estimated_hours: '1.8 Hours',
                    complete: {
                        time: '10:00',
                        notes: 'Boiler looks ok, some slight wear and tear around the seals but nothing major'
                    },
                    milestone_index: 0
                }
            ],
            feedback: {
                arrive_on_site: {
                    name: 'Arrive on site',
                    questions: [
                        {
                            name: 'Is there parking on site?',
                            time: '11:29am',
                            type: 'select',
                            answer: 'Yes',
                            answered: true
                        },
                        {
                            name: 'Is the property easy to access?',
                            time: '11:33am',
                            type: 'input',
                            answer: 'Yes, the property is a gated detached house',
                            answered: true
                        },
                        {
                            name: 'How is the boiler accessed?',
                            time: '11:57am',
                            type: 'textarea',
                            answer: 'The boiler is in the kitchen inside a cupboard',
                            answered: true
                        }
                    ],
                    signatures: [
                        {
                            name: 'Adam Shaw',
                            title: 'tenant',
                            time: '11:37am',
                            url: 'images/signature.png'
                        }
                    ]
                },
                leave_site: {
                    name: 'Leave site',
                    questions: [
                        {
                            name: 'Did you leave the property tidy?',
                            time: '13:42pm',
                            type: 'select',
                            answered: false
                        }
                    ],
                    signatures: []
                }
            },
            geolocation: [
                {
                    status: 'Accepted',
                    time: '10:32am',
                    real_time: '10:32'
                },
                {
                    status: 'Travel',
                    time: '11:05am',
                    real_time: '11:05'
                },
                {
                    status: 'Arrived',
                    time: '11:28am',
                    real_time: '11:28'
                },
                {
                    status: 'Aborted',
                    time: '12:28pm',
                    real_time: '12:28'
                }
            ]
        };

        diary_event_cancel = {
            id: 9000,
            timeline_id: 3,
            status: "Cancelled",
            reason_for_cancellation: "Customer no longer needs the work done",
            cancelled_by: "Callum Hart",
            complete: true,
            already_invoiced: false,
            labour_and_parts_invoiced: false,
            js_date: new Date('2015-01-31'),
            date: "Tuesday 31th January 2015",
            truncated_date: "Tues 31th Jan 2015",
            time: "11:00am to 12:30pm",
            special_event: false,
            start_time: '11:00',
            end_time: '12:30',
            duration: 1.5,
            travel_duration: 0,
            on_site_duration: 0,
            description: "Please install the radiator valves and clean the property",
            customer_name: 'Jason Morjaria',
            customer_contact_telephone: '01234567890',
            work_address_telephone: '01348271749',
            customer_contact_email: 'jason@demo.local',
            property_name: 'CommuSoft',
            property_contact_telephone: '01234567890',
            property_contact_email: 'info@commusoft.local',
            property_address_line1: '123',
            property_address_line2: 'Main Street',
            property_address_line3: 'Old Street',
            property_town: 'London',
            property_county: 'Greater London',
            property_postcode: 'E4 3AB',
            engineer_name: "Callum Hart",
            engineer_email: "callum@commusoft.co.uk",
            engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
            large_engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=70',
            engineer_notes: "Beware that parking is limited, please take a photo of where you park.",
            engineer_feedback: false,
            diary_event_type: 'Job',
            completed_todos: 0,
            available_todos: 4,
            parts_installed: 0,
            parts_decommissioned: 0,
            access: {
                method: 'Landlord has keys',
                notes: 'The key is silver with a square handle'
            },
            engineer_job_sheet: [
                {
                    name: 'Engineer Job Sheet',
                    sent: true,
                    method: 'Email',
                    date: '16th January 2015 at 13:00pm',
                    audits: [
                        {
                            method: 'Email',
                            date: '16th January 2015 at 13:00pm',
                            recipient: 'callum@commusoft.co.uk',
                            subject: 'Job sheet',
                            message: 'Please find the attached job sheet for your upcoming visit'
                        }
                    ]
                }
            ],
            confirmations: [
                {
                    name: 'Customer Confirmation',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                },
                {
                    name: 'Work Address Confirmation',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                },
                {
                    name: 'Work Address Reminder',
                    sent: true,
                    method: 'Letter',
                    date: '13th September 2014 at 13:00pm',
                    type: 'confirmation',
                    audits: [
                        {
                            pending: false,
                            method: 'Letter',
                            date: '13th September 2014 at 13:00pm',
                            message: 'Please be reminded that we will be visiting your property soon'
                        }
                    ]
                }
            ],
            parts: [
                {
                    id: 10,
                    part: 'Piping',
                    status: 'Picked up',
                    quantity: 10,
                    price: 40,
                    engineer_instructions: 'Collect from supplier',
                    already_invoiced: false,
                    picked_up: {
                        date: '12th November 2014 at 12:23pm'
                    }
                }
            ],
            keys: [
                {
                    key: 'Landlord Keys',
                    status: 'Collected',
                    picked_up: {
                        date: 'Tuesday 24th January 2015 at 13:00pm',
                        property_name: 'Foxtons',
                        property_address_line1: '193',
                        property_address_line2: 'Old Road',
                        property_address_line3: 'Old Street',
                        property_town: 'London',
                        property_county: 'Greater London',
                        property_postcode: 'E5 9AR'
                    }
                }
            ],
            todos: [
                {
                    id: 10,
                    status: "Pending",
                    type: "certificate",
                    description: 'GSC for boiler',
                    certificate: 'CP4 - Gas Safety Inspection',
                    estimated_hours: '2.5 Hours',
                    milestone_index: 0
                },
                {
                    id: 11,
                    status: "Pending",
                    type: "decommission",
                    description: 'Old boiler is defective',
                    appliance: 'Vaillant EcoTech',
                    estimated_hours: '3.2 Hours',
                    milestone_index: 0
                },
                {
                    id: 12,
                    status: "Pending",
                    type: "general",
                    description: 'Tidy up property',
                    milestone_index: 0
                },
                {
                    id: 13,
                    status: "Pending",
                    type: "install",
                    description: 'Install the radiator valves',
                    item: 'Radiator Valves',
                    estimated_hours: '1 Hours',
                    quantity: '4',
                    quantity_installed: '0',
                    part_status: "Available",
                    milestone_index: 0
                },
                {
                    id: 14,
                    status: "Pending",
                    type: "maintenance",
                    description: 'This is urgent',
                    appliance: 'Vaillant EcoTech',
                    estimated_hours: '1.4 Hours',
                    milestone_index: 0
                },
                {
                    id: 17,
                    status: "Pending",
                    type: "breakdown",
                    description: 'This is urgent',
                    appliance: 'Vaillant EcoTech',
                    estimated_hours: '1.4 Hours',
                    milestone_index: 0
                },
                {
                    id: 15,
                    status: "Pending",
                    type: "photo",
                    description: 'Take a photograph of the parking location',
                    milestone_index: 0
                },
                {
                    id: 16,
                    status: "Pending",
                    type: "service",
                    description: 'This is urgent',
                    appliance: 'Vaillant EcoTech',
                    estimated_hours: '1.8 Hours',
                    milestone_index: 0
                }
            ],
            feedback: {
                arrive_on_site: {
                    name: 'Arrive on site',
                    questions: [
                        {
                            name: 'Is there parking on site?',
                            time: '11:29am',
                            type: 'select',
                            answered: false
                        },
                        {
                            name: 'Is the property easy to access?',
                            time: '11:33am',
                            type: 'input',
                            answered: false
                        },
                        {
                            name: 'How is the boiler accessed?',
                            time: '11:57am',
                            type: 'textarea',
                            answered: false
                        }
                    ],
                    signatures: []
                },
                leave_site: {
                    name: 'Leave site',
                    questions: [
                        {
                            name: 'Did you leave the property tidy?',
                            time: '13:42pm',
                            type: 'select',
                            answered: false
                        }
                    ],
                    signatures: []
                }
            },
            geolocation: [
                {
                    status: 'Accepted',
                    time: '10:32am',
                    real_time: '10:32'
                },
                {
                    status: 'Travel',
                    time: '11:05am',
                    real_time: '11:05'
                },
                {
                    status: 'Cancel',
                    time: '12:02pm',
                    real_time: '12:02'
                }
            ]
        };

        this.getDiaryEvent = function getDiaryEvent(status, timeline_index) {
            if (timeline_index === undefined) {
                timeline_index = 1;
            }
            switch (status) {
                case 'Pending':
                    var diary_event = angular.copy(diary_event_pending);
                    diary_event.timeline_id = timeline_index;
                    return diary_event;
                    break;
                case 'Accepted':
                    var diary_event = angular.copy(diary_event_accept);
                    diary_event.timeline_id = timeline_index;
                    return diary_event;
                    break;
                case 'Travel':
                    var diary_event = angular.copy(diary_event_travel);
                    diary_event.timeline_id = timeline_index;
                    return diary_event;
                    break;
                case 'Arrived':
                    var diary_event = angular.copy(diary_event_arrive);
                    diary_event.timeline_id = timeline_index;
                    return diary_event;
                    break;
                case 'No access':
                    var diary_event = angular.copy(diary_event_no_access);
                    diary_event.timeline_id = timeline_index;
                    return diary_event;
                    break;
                case 'Left':
                    var diary_event = angular.copy(diary_event_leave);
                    diary_event.timeline_id = timeline_index;
                    diary_event.description += timeline_index;
                    return diary_event;
                    break;
                case 'Aborted':
                    var diary_event = angular.copy(diary_event_abort);
                    diary_event.timeline_id = timeline_index;
                    return diary_event;
                    break;
                case 'Cancel':
                    var diary_event = angular.copy(diary_event_cancel);
                    diary_event.timeline_id = timeline_index;
                    return diary_event;
                    break;
            }
        }

        this.getDiaryEventById = function getDiaryEventById(diary_event_id) {
            var deferred = $q.defer();

            $timeout(function() {
                if (diary_event_id === 1000) {
                    var diary_event = this.diary_event_pending;
                } else if (diary_event_id === 2000) {
                    var diary_event = this.diary_event_accept;
                } else if (diary_event_id === 3000) {
                    var diary_event = this.diary_event_travel;
                } else if (diary_event_id === 4000) {
                    var diary_event = this.diary_event_arrive;
                } else if (diary_event_id === 5000) {
                    var diary_event = this.diary_event_no_access;
                } else if (diary_event_id === 6000) {
                    var diary_event = this.diary_event_leave;
                } else if (diary_event_id === 8000) {
                    var diary_event = this.diary_event_abort;
                } else if (diary_event_id === 9000) {
                    var diary_event = this.diary_event_cancel
                }

                deferred.resolve(diary_event);
            }, 2000);

            return deferred.promise;
        }

        this.getNormalEvent = function() {
            return {
                id: 1000,
                timeline_id: 3,
                status: "Pending",
                complete: false,
                js_date: new Date('2015-01-15'),
                date: "Tuesday 15th January 2015",
                truncated_date: "Tues 15th Jan 2015",
                time: "10:15am to 12:15pm",
                special_event: false,
                start_time: '10:30',
                end_time: '12:30',
                duration: 2,
                travel_duration: 0,
                on_site_duration: 0,
                description: "Prepare the property for the new central heating installation",
                engineer_name: "Jason Morjaria",
                engineer_email: "jason@commusoft.co.uk",
                engineer_profile: 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=30',
                large_engineer_profile: 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=70',
                engineer_notes: "The property is detatched and gated, if you have trouble with access then please call the tennant",
                diary_event_type: 'Job',
                confirmations: [
                    {
                        name: 'Engineer reminder',
                        sent: false,
                        method: 'SMS',
                        date: '14th January 2015 at 13:40pm',
                        type: 'reminder',
                        audits: []
                    }
                ]
            }
        }
    })
    .service('jobInvoices', function($rootScope) {

        invoice_with_payments_and_edit = {
            id : '12345',
            type: 'Final',
            date : 'Mon 24th Dec 2013',
            breakdown: 'no_breakdown',
            job_number : '12345',
            description : 'No breakdown and edited invoice with payments',
            more_details : 'Old boiler removed, area tidied, new boiler fitted and gas safety certificate provided',
            nominal_code : '100 - Parts',
            job_number : '2300',
            total : 810,
            taxes : 64.80,
            cis: 810 * 0.3,
            grand_total : 810 + 64.80 + (810 * 0.3),
            balance: 100,
            printed : 'Printed By Joseph Luck on 23rd November 2014',
            with_line_items: true,
            sent_to_accounting_package: true,
            audits : [
                {
                    type : 'sending',
                    message : 'Sent to the customer',
                    additional_message : '- Joseph Luck printed this invoice',
                    date : 'Wed 23rd November 2014',
                    time : '10:24am'
                },
                {
                    type : 'edit',
                    message : 'Edited',
                    name : 'Joseph Luck',
                    date : 'Wed 23rd November 2014',
                    time : '14:32pm',
                    required : true,
                    completed : {
                        completed : false,
                        message : 'Marked off in SAGE',
                        name : 'Joseph Luck',
                        date : 'Tues 24th November 2014',
                        time : '14:32pm'
                    }
                },
                {
                    type : 'check',
                    message : 'Successfully saved in SAGE',
                    date : 'Tue 22th November 2014',
                    time : '10:11am'
                },
                {
                    type : 'warning',
                    message : 'Failed to save in SAGE because the credentials in company settings are incorrect',
                    date : 'Sun 20th September 2014',
                    time : '19:14pm',
                    help_text : 'You can resend this record by going to quick links'
                }
            ],
            items : [
                {
                    id : 243425234,
                    type : 'Part',
                    description : 'Ariston E-Combi Evo 30HE Condensing Combi Gas Boiler ',
                    cis: 'Yes',
                    quantity : '1',
                    nominal: '232',
                    cost : 300,
                    price : 45,
                    VAT_rate : '20%',
                    total : 300
                },
                {
                    id : 59340785,
                    type : 'Part',
                    description : '"6" 316 / 316 Flexible Flue Liner',
                    cis: 'Yes',
                    quantity : '3',
                    nominal: '232',
                    cost : 100,
                    price : 45,
                    VAT_rate : '20%',
                    total : 300
                },
                {
                    id : 59028411,
                    type : 'Labour',
                    description : 'Perform all the prep work for installing the new boiler',
                    cis: 'Yes',
                    quantity : '3.5',
                    nominal: '232',
                    cost : 60,
                    price : 45,
                    VAT_rate : '20%',
                    total : 210
                }
            ],
            payments : [
                {
                    id: 879325,
                    date: 'Mon 25th Dec 2013',
                    method: 'Card',
                    description: 'Payment for single invoice',
                    reference: '879325',
                    nominal : '322',
                    amount : 20,
                    sent_to_accounting_package : true,
                    audits : [
                        {
                            type : 'check',
                            message : 'Sent to SAGE',
                            date : 'Tues 24th November 2014',
                            time : '14:32pm',
                            edits : [
                                {
                                    type : 'edit',
                                    message : 'Edited',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : true,
                                    completed : {
                                        completed : true,
                                        message : 'Marked off in SAGE',
                                        name : 'Joseph Luck',
                                        date : 'Tues 24th November 2014',
                                        time : '14:32pm'
                                    }
                                },
                                {
                                    type : 'resend',
                                    message : 'Resent to SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : false
                                }
                            ]
                        }
                    ],
                    invoices : [
                        {
                            id : 589023,
                            description : 'Card charge invoice (from a payment)',
                            nominal_code : '100 - Parts',
                            amount : 388.80,
                            remainder : 0
                        }
                    ],
                    card_details : {
                        name_on_card: 'Joseph Luck',
                        email_address: 'joseph.luck@commusoft.com',
                        telephone_number: '+44 74392 39482',
                        card_number: '**** **** **** 4828',
                        expiry_date: '** / **',
                        cvc_code: '***',
                        address_line_1 : '6',
                        address_line_2 : 'Old street',
                        address_line_3 : 'Shoreditch',
                        town : 'London',
                        county : 'Greater London',
                        postcode: 'N2, 3DL'
                    }
                },
                {
                    id: 34237801,
                    date: 'Mon 28th Dec 2013',
                    method: 'Credit',
                    description: 'Credit allocation for multiple invoices (cash allocation)',
                    reference: '34237801',
                    nominal : '322',
                    amount : 20,
                    sent_to_accounting_package : true,
                    audits : [
                        {
                            type : 'check',
                            message : 'Sent to SAGE',
                            date : 'Tues 24th November 2014',
                            time : '14:32pm',
                            edits : [
                                {
                                    type : 'edit',
                                    message : 'Edited',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : true,
                                    completed : {
                                        completed : true,
                                        message : 'Marked off in SAGE',
                                        name : 'Joseph Luck',
                                        date : 'Tues 24th November 2014',
                                        time : '14:32pm'
                                    }
                                },
                                {
                                    type : 'resend',
                                    message : 'Resent to SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : false
                                }
                            ]
                        }
                    ],
                    invoices : [
                        {
                            id : 12345,
                            description : 'Baxi Boiler',
                            nominal_code : '100 - Parts',
                            amount : 20,
                            remainder : 100
                        },
                        {
                            id : 54253141,
                            description : 'Baxi Boiler',
                            nominal_code : '100 - Parts',
                            amount : 20,
                            remainder : 100
                        }
                    ]
                },
                {
                    id: 2589074,
                    date: 'Mon 19th Jan 2014',
                    method: 'Cheque',
                    description: 'Cheque payment for multiple invoices',
                    reference: '2589074',
                    nominal : '322',
                    amount : 20,
                    sent_to_accounting_package : true,
                    audits : [
                        {
                            type : 'check',
                            message : 'Sent to SAGE',
                            date : 'Tues 24th November 2014',
                            time : '14:32pm',
                            edits : [
                                {
                                    type : 'edit',
                                    message : 'Edited',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : true,
                                    completed : {
                                        completed : true,
                                        message : 'Marked off in SAGE',
                                        name : 'Joseph Luck',
                                        date : 'Tues 24th November 2014',
                                        time : '14:32pm'
                                    }
                                },
                                {
                                    type : 'resend',
                                    message : 'Resent to SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : false
                                }
                            ]
                        }
                    ],
                    invoices : [
                        {
                            id : 12345,
                            description : 'Baxi Boiler',
                            nominal_code : '100 - Parts',
                            amount : 20,
                            remainder : 100
                        },
                        {
                            id : 54253141,
                            description : 'Baxi Boiler',
                            nominal_code : '100 - Parts',
                            amount : 20,
                            remainder : 100
                        }
                    ]
                }
            ]
        };

        invoice_without_payments = {
            id : '435231',
            type: 'Partial',
            date : 'Sat 30th Dec 2013',
            breakdown: 'category_breakdown',
            description : 'Category breakdown without payments',
            more_details : 'Old boiler removed, area tidied, new boiler fitted and gas safety certificate provided',
            nominal_code : '100 - Parts',
            job_number : '2300',
            total : 810,
            taxes : 64.80,
            cis: 810 * 0.3,
            grand_total : 810 + 64.80 + (810 * 0.3),
            balance: 100,
            printed : 'Printed By Joseph Luck on 23rd November 2014',
            with_line_items: false,
            sent_to_accounting_package: false,
            items : [
                {
                    id : 59023851,
                    type : 'Part',
                    description : 'Parts',
                    cis: 'Yes',
                    quantity : '1',
                    nominal: '232',
                    cost : 600,
                    price : 45,
                    VAT_rate : '20%',
                    total : 600
                },
                {
                    id : 059270134,
                    type : 'Labour',
                    description : 'Labour',
                    cis: 'Yes',
                    quantity : '1',
                    nominal: '232',
                    cost : 210,
                    price : 45,
                    VAT_rate : '20%',
                    total : 210
                }
            ],
            payments : [
            ]
        };

        invoice_on_way_to_accounting_package = {
            id : '6578987',
            type: 'Partial',
            date : 'Mon 24th Dec 2013',
            breakdown: 'full_breakdown',
            job_number : '6578987',
            description : 'Full breakdown invoice on its way to the accounting package',
            more_details : 'Old boiler removed, area tidied, new boiler fitted and gas safety certificate provided',
            job_number : '2700',
            nominal_code : '100 - Parts',
            total : 810,
            taxes : 64.80,
            cis: 810 * 0.3,
            grand_total : 810 + 64.80 + (810 * 0.3),
            balance: 100,
            printed : 'Printed By Joseph Luck on 23rd November 2014',
            with_line_items: true,
            sent_to_accounting_package: false,
            audits : [
                {
                    type : 'sending',
                    message : 'On its way to SAGE',
                    date : 'Tues 24th November 2014',
                    time : '14:32pm'
                }
            ],
            items : [
                {
                    id : 12345902735,
                    type : 'Part',
                    description : 'Ariston E-Combi Evo 30HE Condensing Combi Gas Boiler ',
                    cis: 'Yes',
                    quantity : '1',
                    nominal: '232',
                    cost : 300,
                    price : 45,
                    VAT_rate : '20%',
                    total : 300
                },
                {
                    id : 9072395732,
                    type : 'Part',
                    description : '"6" 316 / 316 Flexible Flue Liner',
                    cis: 'Yes',
                    quantity : '3',
                    nominal: '232',
                    cost : 100,
                    price : 45,
                    VAT_rate : '20%',
                    total : 300
                },
                {
                    id : 89723951,
                    type : 'Labour',
                    description : 'Perform all the prep work for installing the new boiler',
                    cis: 'Yes',
                    quantity : '3.5',
                    nominal: '232',
                    cost : 60,
                    price : 45,
                    VAT_rate : '20%',
                    total : 210
                }
            ],
            payments : [
                {
                    id: 879325,
                    date: 'Mon 25th Dec 2013',
                    method: 'Cash',
                    description: 'Payment for single invoice',
                    reference: '879325',
                    nominal : '322',
                    amount : 20,
                    sent_to_accounting_package : true,
                    audits : [
                        {
                            type : 'check',
                            message : 'Sent to SAGE',
                            date : 'Tues 24th November 2014',
                            time : '14:32pm',
                            edits : [
                                {
                                    type : 'edit',
                                    message : 'Edited',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : true,
                                    completed : {
                                        completed : true,
                                        message : 'Marked off in SAGE',
                                        name : 'Joseph Luck',
                                        date : 'Tues 24th November 2014',
                                        time : '14:32pm'
                                    }
                                },
                                {
                                    type : 'resend',
                                    message : 'Resent to SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : false
                                }
                            ]
                        }
                    ],
                    invoices : [
                        {
                            id : 589023,
                            description : 'Card charge invoice (from a payment)',
                            nominal_code : '100 - Parts',
                            amount : 388.80,
                            remainder : 0
                        }
                    ]
                },
                {
                    id: 34237801,
                    date: 'Mon 28th Dec 2013',
                    method: 'Credit',
                    description: 'Credit allocation for multiple invoices (cash allocation)',
                    reference: '34237801',
                    nominal : '322',
                    amount : 20,
                    sent_to_accounting_package : true,
                    audits : [
                        {
                            type : 'check',
                            message : 'Sent to SAGE',
                            date : 'Tues 24th November 2014',
                            time : '14:32pm',
                            edits : [
                                {
                                    type : 'edit',
                                    message : 'Edited',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : true,
                                    completed : {
                                        completed : true,
                                        message : 'Marked off in SAGE',
                                        name : 'Joseph Luck',
                                        date : 'Tues 24th November 2014',
                                        time : '14:32pm'
                                    }
                                },
                                {
                                    type : 'resend',
                                    message : 'Resent to SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : false
                                }
                            ]
                        }
                    ],
                    invoices : [
                        {
                            id : 6578987,
                            description : 'Baxi Boiler',
                            nominal_code : '100 - Parts',
                            amount : 20,
                            remainder : 100
                        },
                        {
                            id : 948327,
                            description : 'Baxi Boiler',
                            nominal_code : '100 - Parts',
                            amount : 20,
                            remainder : 100
                        }
                    ]
                },
                {
                    id: 2589074,
                    date: 'Mon 19th Jan 2014',
                    method: 'Cheque',
                    description: 'Cheque payment for multiple invoices',
                    reference: '2589074',
                    nominal : '322',
                    amount : 20,
                    sent_to_accounting_package : true,
                    audits : [
                        {
                            type : 'check',
                            message : 'Sent to SAGE',
                            date : 'Tues 24th November 2014',
                            time : '14:32pm',
                            edits : [
                                {
                                    type : 'edit',
                                    message : 'Edited',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : true,
                                    completed : {
                                        completed : true,
                                        message : 'Marked off in SAGE',
                                        name : 'Joseph Luck',
                                        date : 'Tues 24th November 2014',
                                        time : '14:32pm'
                                    }
                                },
                                {
                                    type : 'resend',
                                    message : 'Resent to SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : false
                                }
                            ]
                        }
                    ],
                    invoices : [
                        {
                            id : 6578987,
                            description : 'Baxi Boiler',
                            nominal_code : '100 - Parts',
                            amount : 20,
                            remainder : 100
                        },
                        {
                            id : 54253141,
                            description : 'Baxi Boiler',
                            nominal_code : '100 - Parts',
                            amount : 20,
                            remainder : 100
                        }
                    ]
                }
            ]
        };

        full_breakdown_by_category_invoice = {
            id : '948327',
            type: 'Partial',
            date : 'Mon 24th Dec 2013',
            breakdown: 'full_breakdown_by_category',
            description : 'Full breakdown by category invoice on its way to the accounting package',
            more_details : 'Old boiler removed, area tidied, new boiler fitted and gas safety certificate provided',
            job_number : '2300',
            nominal_code : '100 - Parts',
            total : 810,
            taxes : 64.80,
            cis: 810 * 0.3,
            grand_total : 810 + 64.80 + (810 * 0.3),
            balance: 100,
            printed : 'Printed By Joseph Luck on 23rd November 2014',
            with_line_items: true,
            sent_to_accounting_package: false,
            audits : [
                {
                    type : 'sending',
                    message : 'On its way to SAGE',
                    date : 'Tues 24th November 2014',
                    time : '14:32pm'
                }
            ],
            items : {
                parts: [
                    {
                        id : 31905823431,
                        type : 'Part',
                        description : 'Ariston E-Combi Evo 30HE Condensing Combi Gas Boiler ',
                        cis: 'Yes',
                        quantity : '1',
                        nominal: '232',
                        cost : 300,
                        price : 45,
                        VAT_rate : '20%',
                        total : 300
                    },
                    {
                        id : 6783945954,
                        type : 'Part',
                        description : '"6" 316 / 316 Flexible Flue Liner',
                        cis: 'Yes',
                        quantity : '3',
                        nominal: '232',
                        cost : 100,
                        price : 45,
                        VAT_rate : '20%',
                        total : 300
                    }
                ],
                labour: [
                    {
                        id : 9023750324,
                        type : 'Labour',
                        description : 'Perform all the prep work for installing the new boiler',
                        cis: 'Yes',
                        quantity : '3.5',
                        nominal: '232',
                        cost : 60,
                        price : 45,
                        VAT_rate : '20%',
                        total : 210
                    }
                ]
            },
            payments : []
        };

        multiple_jobs_invoice = {
            id : '54253141',
            type: 'Multiple',
            date : 'Mon 24th Dec 2013',
            breakdown: 'full_breakdown',
            description : 'Invoice for multiple jobs',
            more_details : 'End of month invoice for all works at tenant properties',
            jobs: [
                {
                    id: '2300',
                    description: 'Job description'
                },
                {
                    id: '2500',
                    description: 'Job description'
                }
            ],
            nominal_code : '100 - Parts',
            total : 7200,
            taxes : 1086,
            grand_total : 7200 + 1086,
            balance: 100,
            printed : 'Printed By Joseph Luck on 23rd November 2014',
            with_line_items: true,
            sent_to_accounting_package: false,
            audits : [
                {
                    type : 'sending',
                    message : 'On its way to SAGE',
                    date : 'Tues 24th November 2014',
                    time : '14:32pm'
                }
            ],
            items : [
                {
                    id : 0325470239,
                    type : 'Job',
                    description : 'Supply and fit a new boiler at 6 Mitre Passage',
                    job_number: '2300',
                    quantity : '1',
                    nominal: '232',
                    cost : 2810,
                    price : 45,
                    VAT_rate : '20%',
                    total : 2810
                },
                {
                    id : 9203850231,
                    type : 'Job',
                    description : 'Perform a gas safety certificate at 12 The Mews',
                    job_number: '2400',
                    quantity : '1',
                    nominal: '232',
                    cost : 60,
                    price : 45,
                    VAT_rate : '20%',
                    total : 60
                },
                {
                    id : 6738635254,
                    type : 'Job',
                    description : 'Fix a leaking tap at 6 Old Street',
                    job_number: '2500',
                    quantity : '1',
                    nominal: '232',
                    cost : 80,
                    price : 45,
                    VAT_rate : '20%',
                    total : 80
                },
                {
                    id : 394903423,
                    type : 'Job',
                    description : 'Re-wire the 1st floor of 2 New Change',
                    job_number: '2600',
                    quantity : '1',
                    nominal: '232',
                    cost : 4250,
                    price : 45,
                    VAT_rate : '20%',
                    total : 4250
                }
            ],
            payments : []
        };

        card_charge_invoice = {
            id : '589023',
            type: 'Partial',
            date : 'Mon 24th Dec 2013',
            breakdown: 'no_breakdown',
            job_number : '2300',
            payment_id : '879325',
            description : 'Card charge invoice (from a payment)',
            nominal_code : '100 - Parts',
            total : 3.50,
            taxes : 0,
            grand_total : 3.50,
            balance: 0,
            printed : 'Printed By Joseph Luck on 23rd November 2014',
            with_line_items: true,
            sent_to_accounting_package: true,
            audits : [
                {
                    type : 'check',
                    message : 'Successfully saved in SAGE',
                    date : 'Tue 22th November 2014',
                    time : '10:11am'
                },
                {
                    type : 'info',
                    message : 'This invoice is a card charge invoice from a payment',
                    help_text: 'You can view the original payment using the link on the right',
                    date : 'Tue 20th November 2014',
                    time : '10:11am',
                    category : 'card_charge'
                }
            ],
            payments : [
                {
                    id: 879325,
                    date: 'Mon 25th Dec 2013',
                    method: 'Card',
                    description: 'Payment for single invoice',
                    reference: '879325',
                    nominal : '322',
                    amount : 3.50,
                    sent_to_accounting_package : true,
                    audits : [
                        {
                            type : 'check',
                            message : 'Sent to SAGE',
                            date : 'Tues 24th November 2014',
                            time : '14:32pm',
                            edits : [
                                {
                                    type : 'edit',
                                    message : 'Edited',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : true,
                                    completed : {
                                        completed : true,
                                        message : 'Marked off in SAGE',
                                        name : 'Joseph Luck',
                                        date : 'Tues 24th November 2014',
                                        time : '14:32pm'
                                    }
                                },
                                {
                                    type : 'resend',
                                    message : 'Resent to SAGE',
                                    name : 'Joseph Luck',
                                    date : 'Tues 24th November 2014',
                                    time : '14:32pm',
                                    required : false
                                }
                            ]
                        }
                    ],
                    invoices : [
                        {
                            id : 6578987,
                            description : 'Baxi Boiler',
                            nominal_code : '100 - Parts',
                            amount : 1117.80,
                            remainder : 0
                        },
                        {
                            id : 589023,
                            description : 'Card charge invoice (from a payment)',
                            nominal_code : '100 - Parts',
                            amount : 3.50,
                            remainder : 0
                        }
                    ],
                    card_details : {
                        name_on_card: 'Joseph Luck',
                        email_address: 'joseph.luck@commusoft.com',
                        telephone_number: '+44 74392 39482',
                        card_number: '**** **** **** 4828',
                        expiry_date: '** / **',
                        cvc_code: '***',
                        address_line_1 : '6',
                        address_line_2 : 'Old street',
                        address_line_3 : 'Shoreditch',
                        town : 'London',
                        county : 'Greater London',
                        postcode: 'N2, 3DL'
                    }
                }
            ]
        };

        initial_payment_invoice = {
            id : '4782913235',
            type: 'Partial',
            date : 'Mon 24th Dec 2013',
            breakdown: 'no_breakdown',
            job_number : '2100',
            estimate_no : '879325',
            description : 'Initial payment invoice for estimate no. 2100',
            nominal_code : '100 - Parts',
            total : 333.33,
            taxes : 66.67,
            grand_total : 400,
            balance: 0,
            printed : 'Printed By Joseph Luck on 23rd November 2014',
            with_line_items: false,
            sent_to_accounting_package: true,
            audits : [
                {
                    type : 'check',
                    message : 'Successfully saved in SAGE',
                    date : 'Tue 22th November 2014',
                    time : '10:11am'
                },
                {
                    type : 'info',
                    message : 'This invoice is a initial payment invoice for estimate no. 2100',
                    help_text: 'You can view the estimate using the link on the right',
                    date : 'Tue 20th November 2014',
                    time : '10:11am',
                    category : 'initial_payment_invoice'
                }
            ],
            payments : []
        };

        invoice_for_milestone_1 = {
            id : '43927502345425',
            type: 'Partial',
            milestone: 'Removal and disposal',
            date : 'Mon 24th Dec 2013',
            breakdown: 'no_breakdown',
            description : 'Invoice for milestone Removal and disposal',
            more_details : 'Old boiler removed, area tidied, new boiler fitted and gas safety certificate provided',
            nominal_code : '100 - Parts',
            job_number : '4000',
            milestone_id: 4,
            total : 4521,
            taxes : 361.8,
            grand_total : 4521 + 361.8,
            balance: 2170,
            printed : 'Printed By Joseph Luck on 23rd November 2014',
            with_line_items: false,
            sent_to_accounting_package: false,
            payments : []
        };

        invoice_for_milestone_2 = {
            id : '673489084360',
            type: 'Partial',
            milestone: 'Preperation',
            date : 'Mon 24th Dec 2013',
            breakdown: 'no_breakdown',
            description : 'Invoice for milestone Preperation',
            more_details : 'Area prepped ready for installation',
            nominal_code : '100 - Parts',
            job_number : '4000',
            milestone_id: 4,
            total : 1854,
            taxes : 148.32,
            grand_total : 1854 + 148.32,
            balance: 890,
            printed : 'Printed By Joseph Luck on 23rd November 2014',
            with_line_items: false,
            sent_to_accounting_package: false,
            payments : []
        };

        draft_invoice = {
            id : '482570314135',
            type: 'Draft',
            milestone: 'Preperation',
            date : 'Mon 24th Dec 2013',
            breakdown: 'full_breakdown',
            description : 'This is a draft invoice for a boiler installation',
            more_details : 'Boiler installed and area cleaned',
            nominal_code : '100 - Parts',
            job_number : '4000',
            total : 1854,
            taxes : 148.32,
            grand_total : 1854 + 148.32,
            balance: 890,
            printed : 'Printed By Joseph Luck on 23rd November 2014',
            draft: true,
            with_line_items: true,
            audits : [
                {
                    type : 'info',
                    message : 'This is a draft invoice',
                    help_text: 'You can edit this invoice freely it being sent to accounts. To save this draft as an invoice, click the link on the right',
                    category : 'draft_invoice'
                }
            ],
            items : [
                {
                    id : 12345902735,
                    type : 'Part',
                    description : 'Ariston E-Combi Evo 30HE Condensing Combi Gas Boiler ',
                    quantity : '1',
                    nominal: '232',
                    cost : 300,
                    price : 45,
                    VAT_rate : '20%',
                    total : 300
                },
                {
                    id : 9072395732,
                    type : 'Part',
                    description : '"6" 316 / 316 Flexible Flue Liner',
                    quantity : '3',
                    nominal: '232',
                    cost : 100,
                    price : 45,
                    VAT_rate : '20%',
                    total : 300
                },
                {
                    id : 89723951,
                    type : 'Labour',
                    description : 'Perform all the prep work for installing the new boiler',
                    quantity : '3.5',
                    nominal: '232',
                    cost : 60,
                    price : 45,
                    VAT_rate : '20%',
                    total : 210
                }
            ],
            payments : []
        };

        this.getInvoice = function getInvoice(type) {
            switch (type) {
                case 'invoice_with_payments_and_edit':
                    var invoice = angular.copy(invoice_with_payments_and_edit);
                    return invoice;
                    break;
                case 'invoice_without_payments':
                    var invoice = angular.copy(invoice_without_payments);
                    return invoice;
                    break;
                case 'invoice_on_way_to_accounting_package':
                    var invoice = angular.copy(invoice_on_way_to_accounting_package);
                    return invoice;
                    break;
                case 'full_breakdown_by_category_invoice':
                    var invoice = angular.copy(full_breakdown_by_category_invoice);
                    return invoice;
                    break;
                case 'multiple_jobs_invoice':
                    var invoice = angular.copy(multiple_jobs_invoice);
                    return invoice;
                    break;
                case 'card_charge_invoice':
                    var invoice = angular.copy(card_charge_invoice);
                    return invoice;
                    break;
                case 'initial_payment_invoice':
                    var invoice = angular.copy(initial_payment_invoice);
                    return invoice;
                    break;
                case 'invoice_for_milestone_1':
                    var invoice = angular.copy(invoice_for_milestone_1);
                    return invoice;
                    break;
                case 'invoice_for_milestone_2':
                    var invoice = angular.copy(invoice_for_milestone_2);
                    return invoice;
                    break;
                case 'draft_invoice':
                    var invoice = angular.copy(draft_invoice);
                    return invoice;
                    break;
            }
        }

        this.getInvoiceById = function getInvoiceById(id) {
            switch (id) {
                case '12345':
                    var invoice = angular.copy(invoice_with_payments_and_edit);
                    return invoice;
                    break;
                case '435231':
                    var invoice = angular.copy(invoice_without_payments);
                    return invoice;
                    break;
                case '6578987':
                    var invoice = angular.copy(invoice_on_way_to_accounting_package);
                    return invoice;
                    break;
                case '948327':
                    var invoice = angular.copy(full_breakdown_by_category_invoice);
                    return invoice;
                    break;
                case '54253141':
                    var invoice = angular.copy(multiple_jobs_invoice);
                    return invoice;
                    break;
                case '589023':
                    var invoice = angular.copy(card_charge_invoice);
                    return invoice;
                    break;
                case '4782913235':
                    var invoice = angular.copy(initial_payment_invoice);
                    return invoice;
                    break;
                case '43927502345425':
                    var invoice = angular.copy(invoice_for_milestone_1);
                    return invoice;
                    break;
                case '673489084360':
                    var invoice = angular.copy(invoice_for_milestone_2);
                    return invoice;
                    break;
                case '482570314135':
                    var invoice = angular.copy(draft_invoice);
                    return invoice;
                    break;
            }
        }

        this.getInvoices = function() {
            return [
                invoice_with_payments_and_edit,
                invoice_without_payments,
                invoice_on_way_to_accounting_package,
                full_breakdown_by_category_invoice,
                multiple_jobs_invoice,
                card_charge_invoice,
                draft_invoice,
                initial_payment_invoice,
                invoice_for_milestone_1,
                invoice_for_milestone_2
            ]
        }
    })
    .service('jobLineItemDecorator', function() {
        this.decorateLineItem = function(breakdown, line_item, spreadsheet_category) {
            if (breakdown === 'full_breakdown') {
                return {
                    "id" : null,
                    "pos" : null,
                    "row_valid" : true,
                    "row" : [
                        {
                            "associated_rows" : [
                                [
                                    {
                                        "value" : line_item.description,
                                        "input_type" : "auto_complete",
                                        "auto_complete_options_reference" : "parts",
                                        "placeholder" : "Choose Part",
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" :"No",
                                        "input_type" : "auto_complete",
                                        "auto_complete_options_reference" : "CIS",
                                        "used_in_calculation" : true,
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" : line_item.quantity,
                                        "input_type" : "time",
                                        "used_in_calculation" : true,
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" : line_item.unit_price,
                                        "input_type" : "time",
                                        "used_in_calculation" : true,
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" : "20",
                                        "input_type" : "auto_complete",
                                        "auto_complete_options_reference" : "vat_rates",
                                        "used_in_calculation" : true,
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" :"232 - Appliances",
                                        "input_type" : "auto_complete",
                                        "auto_complete_options_reference" : "nominal_codes",
                                        "state" : null,
                                        "valid" : true
                                    },
                                    {
                                        "value" : "30",
                                        "input_type" : "auto_complete",
                                        "auto_complete_options_reference" : "cis_rates",
                                        "used_in_calculation" : true,
                                        "state" : "not_selectable",
                                        "hidden" : true,
                                        "valid" : true
                                    },
                                    {
                                        "value" : 0,
                                        "calculation" : "multiply_2_by_3",
                                        "state" : "not_selectable",
                                        "valid" : true
                                    },
                                    {
                                        "value" : 0,
                                        "calculation" : "tax_2_by_3_by_4",
                                        "state" : "not_selectable",
                                        "hidden" : true,
                                        "valid" : true
                                    },
                                    {
                                        "value" : 0,
                                        "calculation" : "tax_7_by_6",
                                        "calculation_condition_cell" : "1",
                                        "calculation_condition_value" : "Yes",
                                        "state" : "not_selectable",
                                        "hidden" : true,
                                        "valid" : true
                                    }
                                ]
                            ]
                        }
                    ],
                    "meta_data": {
                        "prevent_dragging" : false,
                        "part_status" : null,
                        "part_locations" : [],
                        "row_selected" : false
                    }
                }
            } else {
                if (spreadsheet_category === 'labour') {
                    return {
                        "id" : null,
                        "pos" : null,
                        "row_valid" : true,
                        "row" : [
                            {
                                "associated_rows" : [
                                    [
                                        {
                                            "value" : line_item.description,
                                            "input_type" : "text",
                                            "state" : null,
                                            "valid" : true
                                        },
                                        {
                                            "value" :"No",
                                            "input_type" : "auto_complete",
                                            "auto_complete_options_reference" : "CIS",
                                            "used_in_calculation" : true,
                                            "state" : null,
                                            "valid" : true
                                        },
                                        {
                                            "value" : line_item.quantity,
                                            "input_type" : "time",
                                            "used_in_calculation" : true,
                                            "state" : null,
                                            "valid" : true
                                        },
                                        {
                                            "value" : line_item.unit_price,
                                            "input_type" : "time",
                                            "used_in_calculation" : true,
                                            "state" : null,
                                            "valid" : true
                                        },
                                        {
                                            "value" : "20",
                                            "input_type" : "auto_complete",
                                            "auto_complete_options_reference" : "vat_rates",
                                            "used_in_calculation" : true,
                                            "state" : null,
                                            "valid" : true
                                        },
                                        {
                                            "value" :"232 - Appliances",
                                            "input_type" : "auto_complete",
                                            "auto_complete_options_reference" : "nominal_codes",
                                            "state" : null,
                                            "valid" : true
                                        },
                                        {
                                            "value" : "30",
                                            "input_type" : "auto_complete",
                                            "auto_complete_options_reference" : "cis_rates",
                                            "used_in_calculation" : true,
                                            "state" : "not_selectable",
                                            "hidden" : true,
                                            "valid" : true
                                        },
                                        {
                                            "value" : 0,
                                            "calculation" : "multiply_2_by_3",
                                            "state" : "not_selectable",
                                            "valid" : true
                                        },
                                        {
                                            "value" : 0,
                                            "calculation" : "tax_2_by_3_by_4",
                                            "state" : "not_selectable",
                                            "hidden" : true,
                                            "valid" : true
                                        },
                                        {
                                            "value" : 0,
                                            "calculation" : "tax_7_by_6",
                                            "calculation_condition_cell" : "1",
                                            "calculation_condition_value" : "Yes",
                                            "state" : "not_selectable",
                                            "hidden" : true,
                                            "valid" : true
                                        }
                                    ]
                                ]
                            }
                        ]
                    }
                } else {
                    return {
                        "id" : null,
                        "pos" : null,
                        "row_valid" : true,
                        "row" : [
                            {
                                "associated_rows" : [
                                    [
                                        {
                                            "value" : line_item.description,
                                            "input_type" : "auto_complete",
                                            "auto_complete_options_reference" : "parts",
                                            "placeholder" : "Choose Part",
                                            "state" : null,
                                            "valid" : true
                                        },
                                        {
                                            "value" : line_item.quantity,
                                            "input_type" : "time",
                                            "used_in_calculation" : true,
                                            "state" : null,
                                            "valid" : true
                                        },
                                        {
                                            "value" : line_item.unit_price,
                                            "input_type" : "time",
                                            "used_in_calculation" : true,
                                            "state" : null,
                                            "valid" : true
                                        },
                                        {
                                            "value" : "20",
                                            "input_type" : "auto_complete",
                                            "auto_complete_options_reference" : "vat_rates",
                                            "used_in_calculation" : true,
                                            "state" : null,
                                            "valid" : true
                                        },
                                        {
                                            "value" :"232 - Appliances",
                                            "input_type" : "auto_complete",
                                            "auto_complete_options_reference" : "nominal_codes",
                                            "state" : null,
                                            "valid" : true
                                        },
                                        {
                                            "value" : 0,
                                            "calculation" : "multiply_1_by_2",
                                            "state" : "not_selectable",
                                            "valid" : true
                                        },
                                        {
                                            "value" : 0,
                                            "calculation" : "tax_1_by_2_by_3",
                                            "state" : "not_selectable",
                                            "hidden" : true,
                                            "valid" : true
                                        }
                                    ]
                                ]
                            }
                        ]
                    }
                }
            }
        }

        /*====================================================
         Getters
         ====================================================*/

        this.getNewPricingCategories = function getNewPricingCategories() {
            return new_pricing_categories;
        }

        this.getCustomisableSpreadsheetColumns = function getCustomisableSpreadsheetColumns() {
            return customisable_spreadsheet_columns;
        }
    })
    .service('jobMultipleInvoiceDecorator', function($state, $rootScope, jobData) {
        this.getJobFromId = function getJobFromId(id) {
            return jobData.getJobById(id);
        }

        this.decorateJobFromServiceForSmartTable = function decorateJobFromServiceForSmartTable(job) {
            var number_of_diary_events = 0,
                no_parts = 0,
                no_todos = 0,
                no_additional_works = job.additional_works.length,
                job_status = 'New';

            job.milestones.map(function(milestone) {
                number_of_diary_events += milestone.diary_events.length;
                no_parts += milestone.parts.length;
                no_todos += milestone.unassigned_todos;
            })

            if (job.complete === true) {
                job_status = 'Complete'
            } else if (job.has_diary_events === true) {
                job_status = 'In progress'
            }

            return {
                'Job number': job.id,
                'Job description': job.description,
                'Status' : job_status,
                'Property name' : job.property_name,
                'Property address' : job.property_address_line1 + ' ' + job.property_address_line2,
                'Property postcode' : job.property_postcode,
                'No. diary events': number_of_diary_events,
                'No. parts': no_parts,
                'No. additional works': no_additional_works,
                'No. todos': no_todos,
                'Customer purchase order' : 43285673 + parseFloat(job.id)
            }
        }

        this.handleJobData = function handleJobData() {
            var all_jobs = jobData.getJobs(),
                decorated_jobs = [];

            for(var i = 0, l = all_jobs.length; i < l; i++) {
                var job = all_jobs[i];

                if (job.estimate === undefined && job.complete !== true) {
                    decorated_jobs.push(this.decorateJobFromServiceForSmartTable(job));
                }
            }

            return decorated_jobs;
        }
    })
    .service('jobPrice', function() {

        new_pricing_categories = [
            'Travel',
            'Sundries'
        ]

        customisable_spreadsheet_columns_multiple_invoice = [
            {
                name: 'Unit Price',
                selected: false
            },
            {
                name: 'VAT Rate',
                selected: false
            },
            {
                name: 'Total',
                selected: false
            }
        ]

        customisable_spreadsheet_columns_default = [
            {
                name: 'Quantity',
                selected: false
            },
            {
                name: 'Unit Price',
                selected: false
            },
            {
                name: 'VAT Rate',
                selected: false
            },
            {
                name: 'Total',
                selected: false
            }
        ]

        /*====================================================
         Getters
         ====================================================*/

        this.getNewPricingCategories = function getNewPricingCategories() {
            return new_pricing_categories;
        }

        this.getCustomisableSpreadsheetColumns = function getCustomisableSpreadsheetColumns(type) {
            if (type === 'multiple_invoice') {
                return customisable_spreadsheet_columns_multiple_invoice;
            } else {
                return customisable_spreadsheet_columns_default
            }
        }
    })
    .service('permissions', function($rootScope) {
        this.check = function(permissions) {
            var featureAndAccess = permissions;
            var checkFeatureArray = [];

            // Get an array of the features being checked (from the string)
            if (featureAndAccess.indexOf("|") !== -1) {
                checkFeatureArray = featureAndAccess.split("|");
            } else {
                checkFeatureArray.push(featureAndAccess);
            }

            var userHasPermission = _.some(checkFeatureArray, function(feat) {
                var featureArray = feat.split(','),
                    featureName = featureArray[0],
                    featureAccess = featureArray[1],
                    featureId = features[featureName];

                // Check if the current user has this permission for this feature
                return $rootScope.hasPermission(featureId, featureAccess);
            });

            return userHasPermission;
        }
    })
    .service('accountingInterfaceValidation', function($q) {
        this.validateCredentials = function(form) {
            var deferred = $q.defer(),
                form_keys = Object.keys(form),
                errors = {};

            for (var i = 0, x = form_keys.length; i < x; i++) {
                var key = form_keys[i];
                if (key !== "errors") { // note that errors are pushed into the form_keys object when errors return from the server
                    if (!this.validatePrescence(form[key])) {
                        errors[key] = "Please enter a " + key;
                    }
                }
            }

            if (Object.keys(errors).length === 0) {
                deferred.resolve();
            } else {
                deferred.reject(errors);
            }

            return deferred.promise;
        }

        this.validatePrescence = function(input) {
            if (input) {
                return true
            } else {
                return false
            }
        }
    }).service('cardExpiryMonths', function(){
        var cardExpiryMonths = [
            {
                id: 1,
                text: 'January'
            },
            {
                id: 2,
                text: 'February'
            },
            {
                id: 3,
                text: 'March'
            },
            {
                id: 4,
                text: 'April'
            },
            {
                id: 5,
                text: 'May'
            },
            {
                id: 6,
                text: 'June'
            },
            {
                id: 7,
                text: 'July'
            },
            {
                id: 8,
                text: 'August'
            },
            {
                id: 9,
                text: 'September'
            },
            {
                id: 10,
                text: 'October'
            },
            {
                id: 11,
                text: 'November'
            },
            {
                id: 12,
                text: 'December'
            }
        ];
        this.getCardExpiryMonths = function() {
            return cardExpiryMonths;
        }
    }).service('cardExpiryYears', function(){
        var todaysDate = new Date();

        var cardExpiryYears = [
            {
                id: todaysDate.getFullYear(),
                text: todaysDate.getFullYear()
            },
            {
                id: todaysDate.getFullYear() + 1,
                text: todaysDate.getFullYear() + 1
            },
            {
                id: todaysDate.getFullYear() + 2,
                text: todaysDate.getFullYear() + 2
            },
            {
                id: todaysDate.getFullYear() + 3,
                text: todaysDate.getFullYear() + 3
            },
            {
                id: todaysDate.getFullYear() + 4,
                text: todaysDate.getFullYear() + 4
            },
            {
                id: todaysDate.getFullYear() + 5,
                text: todaysDate.getFullYear() + 5
            },
            {
                id: todaysDate.getFullYear() + 6,
                text: todaysDate.getFullYear() + 6
            },
            {
                id: todaysDate.getFullYear() + 7,
                text: todaysDate.getFullYear() + 7
            },
            {
                id: todaysDate.getFullYear() + 8,
                text: todaysDate.getFullYear() + 8
            },
            {
                id: todaysDate.getFullYear() + 9,
                text: todaysDate.getFullYear() + 9
            },
            {
                id: todaysDate.getFullYear() + 10,
                text: todaysDate.getFullYear() + 10
            }
        ];
        this.getCardExpiryYears = function () {
            return cardExpiryYears;
        }
    }).service('serFormatwidgetData', function($translate,$rootScope){
        this.typeCastToInt = function (arrData){
            //return arrData;
            var arrReturn = []
            for(i = 0; i < arrData.length; i++){
                arrReturn.push(parseInt(arrData[i]));
            }
            return arrReturn;
        };
        this.getDataForWidgetDirective = function(widgetData){
            if (widgetData.widget.toUpperCase()=='JOBSBYMONTH'){
                var  return_variable = {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    link: '',
                    type: 'column',
                    no_records_found_message:widgetData.no_records_found_message,
                    with_expanded_detail: false,
                    expanded_detail: null
                };
                if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_data')){
                    return_variable.graph_options = {
                        x_axis_categories: widgetData.xaxis_data,
                            y_axis: [
                            {title: {text: widgetData.yaxis_text },opposite: false }
                        ],
                            series: [
                            {name: widgetData.yaxis_text, data: this.typeCastToInt(widgetData.yaxis_data)}
                        ]
                    }
                }
                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;

            } else if (widgetData.widget.toUpperCase() == 'ESTIMATESBYMONTH'){
               var return_variable =  {
                   maxSizeY: 1,
                   maxSizeX: 1,
                   sizeX: 1,
                   title: widgetData.title,
                   link: '',
                   type: 'bar_with_line',
                   no_records_found_message:widgetData.no_records_found_message,
                   with_expanded_detail: false,
                   expanded_detail: null
               };
               if(widgetData.hasOwnProperty('row')){
                   return_variable.row = widgetData.row
               }
               if(widgetData.hasOwnProperty('col')){
                   return_variable.col = widgetData.col
               }
               if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis0_data') && widgetData.hasOwnProperty('yaxis1_data')){
                    return_variable.graph_options =
                    {
                        x_axis_categories: widgetData.xaxis_data,
                            y_axis: [
                            {title: {text: widgetData.yaxis0_text},	opposite: false},
                            {title: {text: widgetData.yaxis1_text},	opposite: true,labels: {format: $rootScope.CustomCurrency+'{value}'}}
                        ],
                            series: [
                            {yAxis: 0,	type: 'column',	name: widgetData.yaxis0_text,		data: this.typeCastToInt(widgetData.yaxis0_data)},
                            {yAxis: 1,	type: 'line',	name: widgetData.yaxis1_text,	data: this.typeCastToInt(widgetData.yaxis1_data)}
                        ]
                    }
               }
               return_variable.featureid = widgetData.featureid;
               return return_variable;
            } else if (widgetData.widget.toUpperCase() == 'ESTIMATESREJECTIONBYREASON'){
                var server_data = widgetData.pie;
                var typecasted_piedata = [];
                angular.forEach(server_data, function(data){
                    this.push({name:data.name, y:parseInt(data.y)});
                },typecasted_piedata);
                var return_variable =  {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    type: 'pie',
                    no_records_found_message:widgetData.no_records_found_message,
                    with_expanded_detail: false,
                    expanded_detail: null,
                    graph_options: {    series: [{name: 'No. rejections',data: typecasted_piedata}] }
                };
                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;
            }else if (widgetData.widget.toUpperCase() == 'NOOFJOBSCLOSEDAFTERFIRSTVISIT'){
                var server_data = widgetData.guage;
                var typecasted_guagedata = [];
                angular.forEach(server_data, function(data){
                    this.push({y:parseInt(data.y)});
                },typecasted_guagedata);
                var return_variable =  {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    link: '',
                    type: 'guage',
                    no_records_found_message:widgetData.no_records_found_message,
                    graph_options: {
                        series: [	{data: typecasted_guagedata }	]
                    },
                    with_expanded_detail: false
                };
                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;
            }else if (widgetData.widget.toUpperCase() == 'AFTERSALESCARE'){
                var server_data = widgetData.guage;
                var typecasted_guagedata = [];
                angular.forEach(server_data, function(data){
                    this.push({y:parseInt(data.y)});
                },typecasted_guagedata);
                var return_variable =  {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    link: '',
                    type: 'guage',
                    no_records_found_message:widgetData.no_records_found_message,
                    graph_options: {
                        series: [	{data: typecasted_guagedata }	]
                    },
                    with_expanded_detail: false
                };
                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;
            }else if (widgetData.widget.toUpperCase() == 'SALESBYMONTH'){
                return_variable = {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    link: '',
                    type: 'column',
                    no_records_found_message:widgetData.no_records_found_message,
                }
                if(widgetData.hasOwnProperty('yaxis_data')){
                    return_variable.graph_options =  {
                        x_axis_categories: widgetData.xaxis_data,
                            y_axis: [{labels: {format: $rootScope.CustomCurrency+'{value}'},title: {text: widgetData.yaxis_text},opposite: false}],
                            series: [   {name: widgetData.xaxis_text,   data: this.typeCastToInt(widgetData.yaxis_data)}    ]
                    }
                }

                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;
            }  else if (widgetData.widget.toUpperCase() == 'RECALLSBYENGINEER'){
                var server_data = widgetData.pie;
                var typecasted_piedata = [];
                angular.forEach(server_data, function(data){
                    this.push({name:data.name, y:parseInt(data.y)});
                },typecasted_piedata);
                var return_variable =  {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    type: 'pie',
                    no_records_found_message:widgetData.no_records_found_message,
                    with_expanded_detail: false,
                    expanded_detail: null,
                    graph_options: {    series: [{name: 'No. Recalls',data: typecasted_piedata}] }
                };
                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;
            } else if (widgetData.widget.toUpperCase()=='DIARYEVENTSBYSTATUS'){
                var  return_variable = {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    link: '',
                    type: 'stacked_bar',
                    no_records_found_message:widgetData.no_records_found_message,
                    with_expanded_detail: false,
                    expanded_detail: null
                };
                if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_data0')){
                    return_variable.graph_options = {
                        x_axis_categories: widgetData.xaxis_data,
                        y_axis: [
                            {title: {text: widgetData.yaxis_text },opposite: false }
                        ],
                        series: [
                            {name: widgetData.yaxis0_text, data: this.typeCastToInt(widgetData.yaxis_data0), index: 0 },
                            {name: widgetData.yaxis1_text, data: this.typeCastToInt(widgetData.yaxis_data1), index: 1},
                            {name: widgetData.yaxis2_text, data: this.typeCastToInt(widgetData.yaxis_data2), index: 2},
                            {name: widgetData.yaxis3_text, data: this.typeCastToInt(widgetData.yaxis_data3), index: 3},
                            {name: widgetData.yaxis4_text, data: this.typeCastToInt(widgetData.yaxis_data4), index: 4},
                            {name: widgetData.yaxis5_text, data: this.typeCastToInt(widgetData.yaxis_data5), index: 5},
                            {name: widgetData.yaxis6_text, data: this.typeCastToInt(widgetData.yaxis_data6), index: 6},
                            {name: widgetData.yaxis7_text, data: this.typeCastToInt(widgetData.yaxis_data7), index: 7},
                            {name: widgetData.yaxis8_text, data: this.typeCastToInt(widgetData.yaxis_data8), index: 8}
                        ]
                    }
                }
                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;
            } else if (widgetData.widget.toUpperCase() == 'DEBTORDAYSBYAGEOFDEBT'){
                var server_data = widgetData.pie;
                var typecasted_piedata = [];
                var positive_value_count = 0;
                angular.forEach(server_data, function(data){
                    var value=parseFloat(data.y);
                    if(value < 0) {
                        value = 0;
                    } else {
                        positive_value_count ++;
                    }
                    this.push({name:data.name, y:value});
                },typecasted_piedata);

                var return_variable =  {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    type: 'pie',
                    no_records_found_message:widgetData.no_records_found_message,
                    with_expanded_detail: false,
                    expanded_detail: null
                };
                if(positive_value_count > 0) {
                    return_variable.graph_options = { series: [{name: 'Age of debt',data: typecasted_piedata}] }
                } else {
                    return_variable.no_records_found_message = 'No debtors days by age of debt during this period';
                }

                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;
            } else if (widgetData.widget.toUpperCase()=='ADVERTISINGPERFORMANCE') {

                var yaxis_data = [];
                if(widgetData.no_records_found_message == ''){
                    for(var i=0; i< widgetData.yaxis_data.length;i++) {
                        var text = widgetData.yaxis_data[i].name;
                        var data = widgetData.yaxis_data[i].data;
                        var typecasted_data = [];
                        angular.forEach(data, function(cnt){
                            this.push(parseInt(cnt));
                        },typecasted_data);
                        yaxis_data.push({name:text,data: typecasted_data});
                    }
                }

                var  return_variable = {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    type: 'stacked_area',
                    no_records_found_message:widgetData.no_records_found_message,
                    with_expanded_detail: false,
                    expanded_detail: null
                };
                if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_data')){
                    return_variable.graph_options = {
                        x_axis_categories: widgetData.xaxis_data,
                        y_axis: [
                            {title: {text: 'No. Customers gained' },opposite: false }
                        ],
                        series: yaxis_data
                    }
                }
                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;
            }
            else if(widgetData.widget.toUpperCase()=='SALESPERINVOICECATEGORYPERMONTH'){

                var yaxis_data = [];
                if(widgetData.no_records_found_message == ''){
                    for(var i=0; i< widgetData.yaxis_data.length;i++) {
                        var text = widgetData.yaxis_texts[i];
                        var data = widgetData.yaxis_data[i];
                        var typecasted_data = [];
                        angular.forEach(data, function(cnt){
                            this.push(parseInt(cnt));
                        },typecasted_data);
                        yaxis_data.push({name:text,data: typecasted_data});
                    }
                }

                var  return_variable = {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    link: '',
                    type: 'stacked_bar',
                    no_records_found_message:widgetData.no_records_found_message,
                    with_expanded_detail: false,
                    expanded_detail: null
                };
                if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_data')){
                    return_variable.graph_options = {
                        x_axis_categories: widgetData.xaxis_data,
                        y_axis: [
                            {labels: {format: $rootScope.CustomCurrency+'{value}'},title: {text: widgetData.yaxis_text },opposite: false }
                        ],
                        series: yaxis_data
                    }
                }

                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;

            }
            else if(widgetData.widget.toUpperCase()=='JOBSTATUS'){
                var  return_variable = {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    link: '',
                    type: 'html-stats-grid',
                    subtype: 'jobs-status',
                    html: true,
                    no_records_found_message: widgetData.no_records_found_message,
                    with_expanded_detail: false,
                    expanded_detail: null,
                    html_data : []
                };
                if(typeof widgetData.html_data != 'undefined')
                {
                    return_variable['html_data'] = [{'heading' : 'Without diary events', 'content' : widgetData.html_data['nodiaryevents_count']},
                        {'heading' : 'In progress', 'content' : widgetData.html_data['jobinprogresscount']},
                        {'heading' : 'Need attention', 'content' : widgetData.html_data['reviewjobcount']},
                        {'heading' : 'On hold', 'content' : widgetData.html_data['onhold_count']},
                        {'heading' : 'Completed', 'content' : widgetData.html_data['completed_count']}];
                }
                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;
            }
            else if(widgetData.widget.toUpperCase()=='PARTSSTATUS'){
                var  return_variable = {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    link: '',
                    type: 'html-stats-grid',
                    subtype: 'parts-status',
                    html : true,
                    no_records_found_message:widgetData.no_records_found_message,
                    with_expanded_detail: false,
                    expanded_detail: null,
                    html_data : []
                };
                if(typeof widgetData.html_data != 'undefined')
                {
                    return_variable['html_data'] = [{'heading' : 'Requested parts', 'content' : widgetData.html_data['requestedcount']},
                        {'heading' : 'Parts due', 'content' : widgetData.html_data['orderedDuePartsCount']},
                        {'heading' : 'Parts available', 'content' : widgetData.html_data['availablePartsWithoutFutureevents']}];
                }
                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;
            }
            else if(widgetData.widget.toUpperCase()=='PARTSSTATUS'){
                var  return_variable = {
                    maxSizeY: 1,
                    maxSizeX: 1,
                    title: widgetData.title,
                    link: '',
                    type: 'html-stats-grid',
                    subtype: 'parts-status',
                    html : true,
                    no_records_found_message:widgetData.no_records_found_message,
                    with_expanded_detail: false,
                    expanded_detail: null,
                    html_data : [{'heading' : 'Requested parts', 'content' : widgetData.html_data['olderrequestedPartcount']},
                        {'heading' : 'Parts due', 'content' : widgetData.html_data['orderedDuePartsCount']},
                        {'heading' : 'Parts available', 'content' : widgetData.html_data['availablePartsWithoutFutureevents']}]
                };
                if(widgetData.hasOwnProperty('row')){
                    return_variable.row = widgetData.row
                }
                if(widgetData.hasOwnProperty('col')){
                    return_variable.col = widgetData.col
                }
                return_variable.featureid = widgetData.featureid;
                return return_variable;
            }

        }
    }).service('CurrencySymbol', function(){

    var CurrencySymbol = [
        {
            "currency": "United Arab Emirates",
            "code": "AED",
            "symbol": "&#1583;.&#1573;"
        },
        {
            "currency": "Albania Lek",
            "code": "ALL",
            "symbol": "&#76;&#101;&#107;"
        },
        {
            "currency": "Afghanistan Afghani",
            "code": "AFN",
            "symbol": "&#1547;"
        },
        {
            "currency": "Argentina Peso",
            "code": "ARS",
            "symbol": "&#36;"
        },
        {
            "currency": "Aruba Guilder",
            "code": "AWG",
            "symbol": "&#402;"
        },
        {
            "currency": "Australia Dollar",
            "code": "AUD",
            "symbol": "&#36;"
        },
        {
            "currency": "Azerbaijan New Manat",
            "code": "AZN",
            "symbol": "&#1084;&#1072;&#1085;"
        },
        {
            "currency": "Bahamas Dollar",
            "code": "BSD",
            "symbol": "&#36;"
        },
        {
            "currency": "Barbados Dollar",
            "code": "BBD",
            "symbol": "&#36;"
        },
        {
            "currency": "Belarus Ruble",
            "code": "BYR",
            "symbol": "&#112;&#46;"
        },
        {
            "currency": "Belize Dollar",
            "code": "BZD",
            "symbol": "&#66;&#90;&#36;"
        },
        {
            "currency": "Bermuda Dollar",
            "code": "BMD",
            "symbol": "&#36;"
        },
        {
            "currency": "Bolivia Boliviano",
            "code": "BOB",
            "symbol": "&#36;&#98;"
        },
        {
            "currency": "Bosnia and Herzegovina Convertible Marka",
            "code": "BAM",
            "symbol": "&#75;&#77;"
        },
        {
            "currency": "Botswana Pula",
            "code": "BWP",
            "symbol": "&#80;"
        },
        {
            "currency": "Bulgaria Lev",
            "code": "BGN",
            "symbol": "&#1083;&#1074;"
        },
        {
            "currency": "Brazil Real",
            "code": "BRL",
            "symbol": "&#82;&#36;"
        },
        {
            "currency": "Brunei Darussalam Dollar",
            "code": "BND",
            "symbol": "&#36;"
        },
        {
            "currency": "Cambodia Riel",
            "code": "KHR",
            "symbol": "&#6107;"
        },
        {
            "currency": "Canada Dollar",
            "code": "CAD",
            "symbol": "&#36;"
        },
        {
            "currency": "Cayman Islands Dollar",
            "code": "KYD",
            "symbol": "&#36;"
        },
        {
            "currency": "Chile Peso",
            "code": "CLP",
            "symbol": "&#36;"
        },
        {
            "currency": "China Yuan Renminbi",
            "code": "CNY",
            "symbol": "&#165;"
        },
        {
            "currency": "Colombia Peso",
            "code": "COP",
            "symbol": "&#36;"
        },
        {
            "currency": "Costa Rica Colon",
            "code": "CRC",
            "symbol": "&#8353;"
        },
        {
            "currency": "Croatia Kuna",
            "code": "HRK",
            "symbol": "&#107;&#110;"
        },
        {
            "currency": "Cuba Peso",
            "code": "CUP",
            "symbol": "&#8369;"
        },
        {
            "currency": "Czech Republic Koruna",
            "code": "CZK",
            "symbol": "&#75;&#269;"
        },
        {
            "currency": "Denmark Krone",
            "code": "DKK",
            "symbol": "&#107;&#114;"
        },
        {
            "currency": "Dominican Republic Peso",
            "code": "DOP",
            "symbol": "&#82;&#68;&#36;"
        },
        {
            "currency": "East Caribbean Dollar",
            "code": "XCD",
            "symbol": "&#36;"
        },
        {
            "currency": "Egypt Pound",
            "code": "EGP",
            "symbol": "&#163;"
        },
        {
            "currency": "El Salvador Colon",
            "code": "SVC",
            "symbol": "&#36;"
        },
        {
            "currency": "Estonia Kroon",
            "code": "EEK",
            "symbol": "&#107;&#114;"
        },
        {
            "currency": "Euro Member Countries",
            "code": "EUR",
            "symbol": "&#8364;"
        },
        {
            "currency": "Falkland Islands (Malvinas) Pound",
            "code": "FKP",
            "symbol": "&#163;"
        },
        {
            "currency": "Fiji Dollar",
            "code": "FJD",
            "symbol": "&#36;"
        },
        {
            "currency": "Ghana Cedis",
            "code": "GHC",
            "symbol": "&#162;"
        },
        {
            "currency": "Gibraltar Pound",
            "code": "GIP",
            "symbol": "&#163;"
        },
        {
            "currency": "Guatemala Quetzal",
            "code": "GTQ",
            "symbol": "&#81;"
        },
        {
            "currency": "Guernsey Pound",
            "code": "GGP",
            "symbol": "&#163;"
        },
        {
            "currency": "Guyana Dollar",
            "code": "GYD",
            "symbol": "&#36;"
        },
        {
            "currency": "Honduras Lempira",
            "code": "HNL",
            "symbol": "&#76;"
        },
        {
            "currency": "Hong Kong Dollar",
            "code": "HKD",
            "symbol": "&#36;"
        },
        {
            "currency": "Hungary Forint",
            "code": "HUF",
            "symbol": "&#70;&#116;"
        },
        {
            "currency": "Iceland Krona",
            "code": "ISK",
            "symbol": "&#107;&#114;"
        },
        {
            "currency": "India Rupee",
            "code": "INR",
            "symbol": "&#8377;"
        },
        {
            "currency": "Indonesia Rupiah",
            "code": "IDR",
            "symbol": "&#82;&#112;"
        },
        {
            "currency": "Iran Rial",
            "code": "IRR",
            "symbol": "&#65020;"
        },
        {
            "currency": "Isle of Man Pound",
            "code": "IMP",
            "symbol": "&#163;"
        },
        {
            "currency": "Israel Shekel",
            "code": "ILS",
            "symbol": "&#8362;"
        },
        {
            "currency": "Jamaica Dollar",
            "code": "JMD",
            "symbol": "&#74;&#36;"
        },
        {
            "currency": "Japan Yen",
            "code": "JPY",
            "symbol": "&#165;"
        },
        {
            "currency": "Jersey Pound",
            "code": "JEP",
            "symbol": "&#163;"
        },
        {
            "currency": "Kazakhstan Tenge",
            "code": "KZT",
            "symbol": "&#1083;&#1074;"
        },
        {
            "currency": "Korea (North) Won",
            "code": "KPW",
            "symbol": "&#8361;"
        },
        {
            "currency": "Korea (South) Won",
            "code": "KRW",
            "symbol": "&#8361;"
        },
        {
            "currency": "Kyrgyzstan Som",
            "code": "KGS",
            "symbol": "&#1083;&#1074;"
        },
        {
            "currency": "Laos Kip",
            "code": "LAK",
            "symbol": "&#8365;"
        },
        {
            "currency": "Latvia Lat",
            "code": "LVL",
            "symbol": "&#76;&#115;"
        },
        {
            "currency": "Lebanon Pound",
            "code": "LBP",
            "symbol": "&#163;"
        },
        {
            "currency": "Liberia Dollar",
            "code": "LRD",
            "symbol": "&#36;"
        },
        {
            "currency": "Lithuania Litas",
            "code": "LTL",
            "symbol": "&#76;&#116;"
        },
        {
            "currency": "Macedonia Denar",
            "code": "MKD",
            "symbol": "&#1076;&#1077;&#1085;"
        },
        {
            "currency": "Malaysia Ringgit",
            "code": "MYR",
            "symbol": "&#82;&#77;"
        },
        {
            "currency": "Mauritius Rupee",
            "code": "MUR",
            "symbol": "&#8360;"
        },
        {
            "currency": "Mexico Peso",
            "code": "MXN",
            "symbol": "&#36;"
        },
        {
            "currency": "Mongolia Tughrik",
            "code": "MNT",
            "symbol": "&#8366;"
        },
        {
            "currency": "Mozambique Metical",
            "code": "MZN",
            "symbol": "&#77;&#84;"
        },
        {
            "currency": "Namibia Dollar",
            "code": "NAD",
            "symbol": "&#36;"
        },
        {
            "currency": "Nepal Rupee",
            "code": "NPR",
            "symbol": "&#8360;"
        },
        {
            "currency": "Netherlands Antilles Guilder",
            "code": "ANG",
            "symbol": "&#402;"
        },
        {
            "currency": "New Zealand Dollar",
            "code": "NZD",
            "symbol": "&#36;"
        },
        {
            "currency": "Nicaragua Cordoba",
            "code": "NIO",
            "symbol": "&#67;&#36;"
        },
        {
            "currency": "Nigeria Naira",
            "code": "NGN",
            "symbol": "&#8358;"
        },
        {
            "currency": "Korea (North) Won",
            "code": "KPW",
            "symbol": "&#8361;"
        },
        {
            "currency": "Norway Krone",
            "code": "NOK",
            "symbol": "&#107;&#114;"
        },
        {
            "currency": "Oman Rial",
            "code": "OMR",
            "symbol": "&#65020;"
        },
        {
            "currency": "Pakistan Rupee",
            "code": "PKR",
            "symbol": "&#8360;"
        },
        {
            "currency": "Panama Balboa",
            "code": "PAB",
            "symbol": "&#66;&#47;&#46;"
        },
        {
            "currency": "Paraguay Guarani",
            "code": "PYG",
            "symbol": "&#71;&#115;"
        },
        {
            "currency": "Peru Nuevo Sol",
            "code": "PEN",
            "symbol": "&#83;&#47;&#46;"
        },
        {
            "currency": "Philippines Peso",
            "code": "PHP",
            "symbol": "&#8369;"
        },
        {
            "currency": "Poland Zloty",
            "code": "PLN",
            "symbol": "&#122;&#322;"
        },
        {
            "currency": "Qatar Riyal",
            "code": "QAR",
            "symbol": "&#65020;"
        },
        {
            "currency": "Romania New Leu",
            "code": "RON",
            "symbol": "&#108;&#101;&#105;"
        },
        {
            "currency": "Russia Ruble",
            "code": "RUB",
            "symbol": "&#1088;&#1091;&#1073;"
        },
        {
            "currency": "Saint Helena Pound",
            "code": "SHP",
            "symbol": "&#163;"
        },
        {
            "currency": "Saudi Arabia Riyal",
            "code": "SAR",
            "symbol": "&#65020;"
        },
        {
            "currency": "Serbia Dinar",
            "code": "RSD",
            "symbol": "&#1044;&#1080;&#1085;&#46;"
        },
        {
            "currency": "Seychelles Rupee",
            "code": "SCR",
            "symbol": "&#8360;"
        },
        {
            "currency": "Singapore Dollar",
            "code": "SGD",
            "symbol": "&#36;"
        },
        {
            "currency": "Solomon Islands Dollar",
            "code": "SBD",
            "symbol": "&#36;"
        },
        {
            "currency": "Somalia Shilling",
            "code": "SOS",
            "symbol": "&#83;"
        },
        {
            "currency": "South Africa Rand",
            "code": "ZAR",
            "symbol": "&#82;"
        },
        {
            "currency": "Korea (South) Won",
            "code": "KRW",
            "symbol": "&#8361;"
        },
        {
            "currency": "Sri Lanka Rupee",
            "code": "LKR",
            "symbol": "&#8360;"
        },
        {
            "currency": "Sweden Krona",
            "code": "SEK",
            "symbol": "&#107;&#114;"
        },
        {
            "currency": "Switzerland Franc",
            "code": "CHF",
            "symbol": "&#67;&#72;&#70;"
        },
        {
            "currency": "Suriname Dollar",
            "code": "SRD",
            "symbol": "&#36;"
        },
        {
            "currency": "Syria Pound",
            "code": "SYP",
            "symbol": "&#163;"
        },
        {
            "currency": "Taiwan New Dollar",
            "code": "TWD",
            "symbol": "&#78;&#84;&#36;"
        },
        {
            "currency": "Thailand Baht",
            "code": "THB",
            "symbol": "&#3647;"
        },
        {
            "currency": "Trinidad and Tobago Dollar",
            "code": "TTD",
            "symbol": "&#84;&#84;&#36;"
        },
        {
            "currency": "Turkey Lira",
            "code": "TRY",
            "symbol": "&#8378;"
        },
        {
            "currency": "Turkey Lira",
            "code": "TRL",
            "symbol": "&#8356;"
        },
        {
            "currency": "Tuvalu Dollar",
            "code": "TVD",
            "symbol": "&#36;"
        },
        {
            "currency": "Ukraine Hryvna",
            "code": "UAH",
            "symbol": "&#8372;"
        },
        {
            "currency": "United Kingdom Pound",
            "code": "GBP",
            "symbol": "&#163;"
        },
        {
            "currency": "United States Dollar",
            "code": "USD",
            "symbol": "&#36;"
        },
        {
            "currency": "Uruguay Peso",
            "code": "UYU",
            "symbol": "&#36;&#85;"
        },
        {
            "currency": "Uzbekistan Som",
            "code": "UZS",
            "symbol": "&#1083;&#1074;"
        },
        {
            "currency": "Venezuela Bolivar",
            "code": "VEF",
            "symbol": "&#66;&#115;"
        },
        {
            "currency": "Viet Nam Dong",
            "code": "VND",
            "symbol": "&#8363;"
        },
        {
            "currency": "Yemen Rial",
            "code": "YER",
            "symbol": "&#65020;"
        },
        {
            "currency": "Zimbabwe Dollar",
            "code": "ZWD",
            "symbol": "&#90;&#36;"
        }
    ];
    this.getCurrencySymbol = function () {
        return CurrencySymbol;
    }
}).service('redirectScreen',function($state,$rootScope,warningModal){
    var state,stateParam;
    this.redirectTo = function (screen, stateParams, warningMessage) {

        if(screen === 'customerDetails') {
             state = "loggedin.customer_list.view.property";
             stateParam = {'id': stateParams.id, 'type': stateParams.type};
        }else if(screen === 'CustomersList'){
             state = "loggedin.customer_list";
             stateParam = {};
        }

        warningModal.show(warningMessage.message, warningMessage.title,"Deleted");
        $state.transitionTo(state,stateParam,{reload: true});
        return false;
    }
})
    .service('diaryEventLockOption',function () {
        /**
         * Todo Define diary events lock options
         */

        this.no_lock = 0;
        this.lock_by_engineer = 1;
        this.lock_by_datetime = 2;
        this.lock_by_engineer_and_datetime = 3;
        this.lock_by_service_window = 4;
    })
    .service('creditNoteData', ['$http','prefix', function($http, prefix) {
        this.selectedReturns = [];
        this.setSelectedReturns = function setSelectedReturns(data){
            this.selectedReturns = data;
        }
        this.getSelectedReturns = function getSelectedReturns(){
            return this.selectedReturns;
        }
    }]);
