function CSIntegrationModalCtrl($scope, $state,$http,$location, prefix, $modalInstance, $timeout, $rootScope, $filter, $q, toastBox, confirmationBoxHelper, tableCollection) {
    
    $scope.limit = 5;
    $scope.title = "Chat Integration";

    $scope.close = function() {
        $scope.loading = false;
        $state.reload();
        $scope.shouldBeOpenIntegration = false;
        $modalInstance.close();
    }
    
    window.onkeydown = function (evt) {
        $scope.modal_confirmation_done = false;
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }

    $scope.triggerCloseModalBox1 = function () {
        $scope.close();
        $scope.shouldBeOpenIntegration = false;
    }
    
    $scope.fetch_users_list = true;
    $scope.loading = true;
    $scope.showList = true;
    
    $rootScope.listChatUsers = function() {
        alert("chat users");
        $http.get(prefix + '/getChatUsersByClient').success(function(data) {
                $scope.showList = false;
                $scope.usersData = data.data;
                $scope.totalUsers = data.count;
                alert($scope.usersData);
                /*if( data.count > 0 ) {
                    $scope.showManageButton = true;
                } else {
                    $scope.showManageButton = false;
                }*/
                $scope.fetch_users_list = false;
                $scope.loading = false;
        });
    }
    $rootScope.listChatUsers();
}

commusoftCommon.directive('csmodalcommonintegration', ['prefix', '$http', '$modal',function(prefix, $http, $modal) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            shouldBeOpenIntegration: '=',
            step: '=',
            name: '=',
            path: '='
        },
        controller: function($scope, $element, $attrs, $transclude) {
            
            $scope.$watch('shouldBeOpenIntegration', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal == true) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;
                    modalInstance = $modal.open({
                        templateUrl: $scope.path,
                        scope: $scope,
                        controller: CSIntegrationModalCtrl,
                        windowClass: 'modal with-slides large'
                    })
                }
           })
            
        }
    }
}]);

