commusoftCommon.directive('informationbar', function($document, $rootScope, warningModal, $translate, prefix, $http, $compile, $timeout) {
    var handleInformationBar = function handleInformationBar(scope, element, attrs) {
        var supports_audits = attrs.supportsAudits,
            supports_multiple = attrs.supportsMultiple,
            single_notification_bar = supports_multiple === undefined || supports_multiple === 'undefined';

        scope.category = attrs.category;
        scope.message = attrs.message;
        scope.notification_bar = true;
        scope.hasInvoices = attrs.hasInvoices;
        scope.hasFinalInvoice = attrs.hasFinalInvoice;
        scope.jobCompletedReason = attrs.jobcompletedreason;
        scope.showCloseBtn = attrs.closeButton? true: false;

        attrs.$observe('message', function (message) {
            scope.message = message;
        });
        attrs.$observe('informationData', function (informationData) {
            scope.information_data = angular.fromJson(informationData);
        });

        if (single_notification_bar) {
            scope.single_notification_bar = true;
            scope.icon = attrs.icon;
        } else {
            scope.single_notification_bar = false;
            scope.edit_help_text = attrs.editHelpText;
        }

        scope.handleInformationActions = function handleInformationActions(information_type, jobCompletedReason) {
            if(jobCompletedReason == 'Aborted'){
                var message = $translate('Job.aborted.Cannot.convert.draft.to.invoice');
                var title = $translate('Edit.Invoice');
                var id = 'cannot-delete-modal-invoice';
                warningModal.show(message, title, id);
            }else{
                scope.$emit('informationbar:'+information_type);
            }
        }

        scope.handleConsolidatedInvoiceAction = function handleConsolidatedInvoiceAction(information_type,information){
            scope.$emit('informationbar:'+information_type,information);
        }
        scope.showpdf = function showpdf(information){
            var side_panel_tpl = $compile('<span id="view_pdf" ' +
                'sidepanel template="file_preview" ' +
                'title="'+information.panel_title +'"'+
                'pdf_url="' + information.pdf_url +'"'+
                'data="'+ information +'"'+
                'printpost_params="tableType=5&tablePKID='+information.estimateId+'"></span>')(scope);

            angular.element('body').append(side_panel_tpl);
            $timeout(function() {
                angular.element('#view_pdf').scope().$$childTail.initialise();
            }, 600);
        }

        $rootScope.$on('parent:updated', function(evt) {
            if (angular.element('#view_pdf')) {
                angular.element('#view_pdf').remove();
            };
        });

        scope.information_open = true;
        scope.toggleInformationView = function toggleInformationView() {
            scope.information_open = !scope.information_open;
        }

        scope.$on('jobs:blame_removed', function() {
            scope.information_data[0].blame = false;
            scope.information_data[0].blame_engineer = '';
            scope.information_data[0].additional_message = '';
        });

        scope.$on('jobs:blame_changed', function(e, engineer) {
            scope.information_data[0].blame = true;
            scope.information_data[0].blame_engineer = engineer;
            scope.information_data[0].additional_message = ' - ' + engineer + ' is to blame';
        });

        scope.$on('jobs:reserve_list_changed', function(e, engineers) {
            angular.forEach(scope.information_data, function (value, key) {
                if(value.category == "reserve_list"){
                    scope.information_data[key].additional_message = ' - (it is on ' + engineers + ' engineers reserve lists)';
                }
            });
        });
        scope.handleResend = function handleResend(information) {
            if(information.jobId != '') {
                scope.type = 'job';
                scope.id = information.jobId;
                scope.messageType = information.messageType;
            } else if(information.estimateId != '') {
                scope.type = 'estimate';
                scope.id = information.estimateId;
                scope.messageType = information.messageType;
            }
            if(information.messageType == 'initialMessage') {
                $http.get(prefix + '/resendWorkAddressInitialCommunicationMessage?id=' + scope.id + '&type=' +scope.type).success(function(data) {
                    scope.information_open = true;
                    if(scope.type == 'job') {
                        scope.$emit('jobTimeline:reload', scope.$parent.repeat_index);
                    } else {
                        scope.$emit('estimateTimeline:reload');
                    }
                });
            } else {
                $http.get(prefix + '/resendWorkAddressNoAnswerCustomerMessage?type=' +scope.type + '&messageType=' +scope.messageType + '&id=' + scope.id).success(function(data) {
                    scope.information_open = true;
                    if(scope.type == 'job') {
                        scope.$emit('jobTimeline:reload', scope.$parent.repeat_index);
                    } else {
                        scope.$emit('estimateTimeline:reload');
                    }
                });
            }
        }

        scope.closeInfobar = function() {
            scope.notification_bar = false;
        };
    }

    return {
        restrict: 'A',
        templateUrl: '/template/information_bars/information_bar.html',
        scope: {},
        link: handleInformationBar
    }
});
