commusoftCommon.directive('drophelper', function($document, $rootScope, $timeout) {
    var handleDropHelper = function handleDropHelper(scope, element, attrs) {
        var show_channel = attrs.showChannel;

        scope.drag_message = attrs.dragMessage;
        scope.drop_message = attrs.dropMessage;
        // NOTE ~ the store name has to be a param on the rootScope (i.e rows_store)
        scope.store_name = attrs.storeName;
        scope.singleton_success_message = attrs.singletonSuccessMessage;
        scope.plural_success_message = attrs.pluralSuccessMessage;
        scope.error_message = attrs.errorMessage;
        scope.message = scope.drag_message;
        scope.element = element;

        if (attrs.spreadsheetScopeId) {
            scope.spreadsheet_scope_id = attrs.spreadsheetScopeId;
            scope.drop_is_for_spreadsheet = true;
        } else {
            scope.drop_is_for_spreadsheet = false;
        }

        attrs.$observe('success_message', function(success_message) {
            scope.success_message = attrs.successMessage;
        });

        scope.drop_state = 'start';
        scope.show_success = false;
        scope.show_error = false;
        scope.allow_reset = true;

        scope.showStartUi = function showStartUi(e, channel){
            var should_allow_drop_helper = true;

            // NOTE ~ 31/03/2015 ~ This code was commented out as it is to do with the spreadsheet
            //                     drag code, where the current spreadsheet should not show a
            //                     drop helper
            // if (scope.drop_is_for_spreadsheet === true) {
            //   if ($rootScope.spreadsheet_drag_instance === scope.spreadsheet_scope_id) {
            //     should_allow_drop_helper = false;
            //   }
            // }

            if (should_allow_drop_helper === true) {
                scope.show_drop_helper = true;
                scope.animate_drop_helper = true;
                scope.show_success = false;
                scope.show_error = false;
                scope.allow_success_message = false;
                scope.drop_state = 'start';
                scope.allow_reset = true;
                scope.message = scope.drag_message;
                scope.$apply();
            }
        }

        $rootScope.$on('ANGULAR_DRAG_START', function(e, channel){
            scope.showStartUi(e, channel);
            scope.allow_reset = true;
        });

        $rootScope.$on('ANGULAR_HOVER', function(e, channel){
            if (scope.store_name !== undefined && $rootScope[scope.store_name] !== undefined) {
                if ($rootScope[scope.store_name].length) {
                    scope.number_of_items = $rootScope[scope.store_name].length;
                }
            } else {
                scope.number_of_items = 1;
            }

            $timeout(function() {
                if ($(scope.element).parents('.on-drag-hover').length > 0) {
                    scope.drop_state = 'hover';
                    scope.message = scope.drop_message;
                    scope.allow_success_message = true;
                }
            }, 50);
        });

        $rootScope.$on('CUSTOM_ANGULAR_HOVER_LEAVE', function(e, channel){
            scope.showStartUi(e, channel);
        });

        $rootScope.$on('ANGULAR_DRAG_END', function(e, channel){
            $timeout(function(){
                if (scope.allow_success_message === true) {
                    scope.drop_state = 'end';
                    scope.message = scope.success_message;

                    if (scope.number_of_items > 1) {
                        scope.message = scope.number_of_items.toString() + ' ' + scope.plural_success_message;
                    } else {
                        scope.message = scope.singleton_success_message;
                    }

                    $timeout(function(){
                        scope.show_success = true;
                    },100);
                    $timeout(function(){
                        scope.animate_drop_helper = false;
                    }, 1000);
                    $timeout(function(){
                        scope.show_drop_helper = false;
                    },1400);
                    scope.$apply();
                } else if (scope.allow_reset === true) {
                    scope.drop_state = 'start';
                    scope.animate_drop_helper = false;
                    $timeout(function(){
                        scope.show_drop_helper = false;
                    },400);
                }
            }, 100);
        });

        $rootScope.$on('drophelper:drop_error', function(e, message){
            scope.allow_success_message = false;
            scope.allow_reset = false;
            scope.drop_state = 'error';
            $timeout(function(){
                if (message === undefined) {
                    scope.message = scope.error_message;
                } else {
                    scope.message = message;
                }
                scope.show_error = true;
            }, 10);
            $timeout(function(){
                scope.animate_drop_helper = false;
                $timeout(function(){
                    scope.show_drop_helper = false;
                }, 400);
            }, 2000);
        });
    }

    return {
        restrict: 'A',
        templateUrl: '/template/shared/drop_helper.html',
        scope: {},
        link: handleDropHelper
    }
});