csmodule.directive('reportingOperationalSearch', ['prefix', '$http', '$modal', 'tableCollection', '$document', '$timeout', 'clickEvents', '$rootScope', '$compile', 'dragHelper', '$state', '$window', 'temporaryCookies', 'reportFilterSettings', function(prefix, $http, $modal, tableCollection, $document, $timeout, clickEvents, $rootScope, $compile, dragHelper, $state, $window, temporaryCookies, reportFilterSettings) {
    return {
        restrict: 'ACE',
        link: function($scope, element, attrs) {
            $scope.tabMode = $state.current.data.type;
            $scope.filterStorage = {};
            $scope.stickyHeader = false;
            if ($window.localStorage.getItem('reportingOperationalListFilters_' + $scope.tabMode) !== null) {
                $scope.filterStorage[$scope.tabMode] = {
                    'filters': angular.fromJson($window.localStorage.getItem('reportingOperationalListFilters_' + $scope.tabMode))['filters'],
                    'count': angular.fromJson($window.localStorage.getItem('reportingOperationalListFilters_' + $scope.tabMode))['count']
                }
                $scope.inputTags = $scope.filterStorage[$scope.tabMode]['filters'];
            }
            else {
                $scope.filterStorage = {};
            }

            $scope.$on('clearOperationalTableFilters', function(e) {
                $scope.inputTags = [];
            });

            $scope.$on('appliedFiltersUpdated', function(e) {
                $scope.filterStorage[$scope.tabMode] = {
                    'filters': $scope.inputTags,
                    'count': $scope.counts[$scope.tabMode]
                }

                $window.localStorage.setItem('reportingOperationalListFilters_' + $scope.tabMode, angular.toJson($scope.filterStorage[$scope.tabMode]));
                temporaryCookies.trackLocalstorage('reportingOperationalListFilters_' + $scope.tabMode);
            });

            $scope.$on('tabCounts', function(e, counts) {
                $scope.counts = counts;
            });

            $scope.$on('fetchFiltersFromCookie', function(e, mode) {
                if ($window.localStorage.getItem('reportingOperationalListFilters_' + mode) !== null) {
                    $scope.filterStorage[mode] = {
                        'filters': angular.fromJson($window.localStorage.getItem('reportingOperationalListFilters_' + mode))['filters'],
                        'count': angular.fromJson($window.localStorage.getItem('reportingOperationalListFilters_' + mode))['count']
                    }
                }
                if ($scope.filterStorage[mode]) {
                    if (Array.isArray($scope.filterStorage[mode]['filters'])) {
                        $scope.inputTags = $scope.filterStorage[mode]['filters'];
                    }
                    else {
                        $scope.inputTags = new Array($scope.filterStorage[mode]['filters']);
                    }
                }
            });

            $scope.$on('updateOperationalTableFilters', function(e, newFilters, mode) {
                $scope.tabMode = mode;
                reportFilterSettings.availableFilters = newFilters;
                $(element).find('.filter-search-field').autocomplete('enable');
                $(element).find('.filter-search-field').autocomplete('option','source', reportFilterSettings.availableFilters);
            });

            $scope.$on('changeOperationalTableTab', function(e, fromTab, toTab) {
                if (!!($window.localStorage.getItem('reportingOperationalListFilters_' + toTab)) !== false) {
                    $scope.filterStorage[toTab] = {
                        'filters': angular.fromJson($window.localStorage.getItem('reportingOperationalListFilters_' + toTab))['filters'],
                        'count': angular.fromJson($window.localStorage.getItem('reportingOperationalListFilters_' + toTab))['count']
                    }
                }

                if ($scope.inputTags.length > 0) {
                    $scope.filterStorage[fromTab] = {
                        'filters': $scope.inputTags,
                        'count': $scope.counts[fromTab]
                    }
                    $window.localStorage.setItem('reportingOperationalListFilters_' + fromTab, angular.toJson($scope.filterStorage[fromTab]));
                    temporaryCookies.trackLocalstorage('reportingOperationalListFilters_' + fromTab);
                }


                if (!!($scope.filterStorage[toTab]) !== false) {
                    if (Array.isArray($scope.filterStorage[toTab]['filters'])) {
                        $scope.inputTags = $scope.filterStorage[toTab]['filters'];
                        $scope.counts[toTab] = $scope.filterStorage[toTab]['count'];
                    }
                    else {
                        $scope.inputTags = new Array($scope.filterStorage[toTab]['filters']);
                    }
                }
                else {
                    $scope.inputTags = [];
                }

                $scope.tabMode = toTab;
            });

        },
        controller: function($scope) {
            /* temporary for operational list testing */
            /*
             $scope.availableFilters = [
             {label: 'Job', value: 'jobId', condition: "contains", type: "text"},
             {label: 'Description', value: 'jobDescription', condition: "contains", type: "text"},
             {label: 'Address', value: 'address', condition: "contains", type: "text"},
             {label: 'Part Name', value: 'partName', condition: "contains", type: "text"},
             {label: 'Manufacturer No', value: 'manufacturerId', condition: "contains", type: "text"},
             {label: 'Quantity', value: 'quantity', condition: "equals", type: "text"},
             {label: 'Requested By', value: 'requestedBy', condition: "contains", type: "text"}
             ];
             */

//                $scope.options = reportFilterSettings.availableFilterOptions;

        }
    }
}]);
