/**
 * Created by sivachandran on 21/8/18.
 */

'use strict';
var vatModule = angular.module('vat', []);

vatModule.constant('prefix', window.prefixVal).
    config(
        ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
        function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {
            $stateProvider.state('loggedin.add_tax_components', {
                url: Routing.generateAngularRoute('add_tax_components', false),
                templateUrl: function (params) {
                    return Routing.generate('add_tax_components')
                },
                data: {
                    'taxComponentListUrl': '/list_tax_components',
                    'pageNum': 1,
                    'editedId': -1,
                    'addedId': -1,
                    'limit': 5
                },
                views: {
                    "": {
                        template: globalTemplate,
                        controller: taxComponentCtrl,
                        resolve: Resolver
                    },
                    "taxComponentListPane@": {
                        templateUrl: 'template/settings/vat_internalization/list_tax_components.html',
                        controller: taxComponentListCtrl,

                        resolve: {
                            taxcomponentresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                var deferred = $q.defer();
                                $http.get(prefix + this.data.taxComponentListUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                    deferred.resolve(data);
                                })
                                return deferred.promise;
                            }
                        }
                    }
                }
            }).state('loggedin.add_tax_components.edit', {
                params: ['id', 'description', 'pageNum', 'limit'],
                views: {
                    "taxComponentListPane@": {
                        templateUrl: 'template/settings/vat_internalization/edit_tax_component.html',
                        controller: taxComponentEditCtrl
                    }
                }
            }).state('loggedin.add_tax_item', {
                url: Routing.generateAngularRoute('add_tax_item', false),
                templateUrl: function (params) {
                    return Routing.generate('add_tax_item')
                },
                data: {
                    'taxItemListUrl': '/list_tax_items',
                    'pageNum': 1,
                    'editedId': -1,
                    'addedId': -1,
                    'limit': 5
                },
                views: {
                    "": {
                        template: globalTemplate,
                        controller: taxItemCtrl,
                        resolve: Resolver
                    },
                    "taxItemListPane@": {
                        templateUrl: 'template/settings/vat_internalization/list_tax_items.html',
                        controller: taxItemListCtrl,

                        resolve: {
                            taxitemresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                var deferred = $q.defer();
                                $http.get(prefix + this.data.taxItemListUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit + '&group=all').success(function (data) {
                                    deferred.resolve(data);
                                })
                                return deferred.promise;
                            }
                        }
                    }
                }
            }).state('loggedin.add_tax_item.edit', {
                params: ['id', 'description', 'taxProvinces' ,'pageNum', 'limit'],
                data : {'taxItemList' : '/list_tax_items'},
                views: {
                    "taxItemListPane@": {
                        templateUrl: 'template/settings/vat_internalization/edit_tax_item.html',
                        controller: taxItemEditCtrl,
                        resolve: {
                            taxItemListResolver: function ($http, $q, $state, prefix) {
                                var deferred = $q.defer();
                                $http.get(prefix + this.data.taxItemList).success(function (data) {
                                    deferred.resolve(data);
                                })
                                return deferred.promise;
                            }
                        }
                    }
                }
            }).state('loggedin.add_tax_item.add_tax_rate', {
                url: Routing.generateAngularRoute('add_tax_rate', true),
                templateUrl: function (params) {
                    return Routing.generate('add_tax_rate', params)
                },
                views: {
                    "addTaxRatePane@": {
                        templateUrl: 'template/settings/vat_internalization/add_tax_rates.html',
                        controller: addTaxRateCtrl,
                        resolve: BreadCrumbsPageLimitResolver
                    }
                }
            }).state('loggedin.add_provinces', {
                url: Routing.generateAngularRoute('add_provinces', false),
                templateUrl: function (params) {
                    return Routing.generate('add_provinces')
                },
                data: {
                    'provincesListUrl': '/list_provinces',
                    'pageNum': 1,
                    'editedId': -1,
                    'addedId': -1,
                    'limit': 5
                },
                views: {
                    "": {
                        template: globalTemplate,
                        controller: provincesCtrl,
                        resolve: Resolver
                    },
                    "provincesPane@": {
                        templateUrl: 'template/settings/vat_internalization/list_provinces.html',
                        controller: provincesListCtrl,

                        resolve: {
                            provincesresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                var deferred = $q.defer();
                                $http.get(prefix + this.data.provincesListUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                    deferred.resolve(data);
                                })
                                return deferred.promise;
                            }
                        }
                    }
                }
            }).state('loggedin.add_provinces.edit', {
                params: ['id', 'description','pageNum', 'limit'],
                data : {'provinceList' : '/list_provinces'},
                views: {
                    "provincesPane@": {
                        templateUrl: 'template/settings/vat_internalization/edit_province.html',
                        controller: provincesEditCtrl,
                        resolve: {
                            provinceListResolver: function ($http, $q, $state, prefix) {
                                var deferred = $q.defer();
                                $http.get(prefix + this.data.provinceList).success(function (data) {
                                    deferred.resolve(data);
                                })
                                return deferred.promise;
                            }
                        }
                    }
                }
            });
        }
    ]);