commusoftCommon.service('dateRange', function() {
        var  filterOptions = [
            {
                string: 'Today',
                withCalendar: false,
                view: null,
                startdate: moment().format('YYYY-MM-DD'),
                enddate: moment().format('YYYY-MM-DD')
            },
            {
                string: 'This Week',
                withCalendar: false,
                view: null,
                startdate: moment().weekday(0).format('YYYY-MM-DD'),
                enddate: moment().weekday(6).format('YYYY-MM-DD')
            },
            {
                string: 'Last Week',
                withCalendar: false,
                view: null,
                startdate: moment().weekday(-7).format('YYYY-MM-DD'),
                enddate: moment().weekday(-1).format('YYYY-MM-DD')
            },
            {
                string: 'This Month',
                withCalendar: false,
                view: null,
                startdate: moment().startOf('month').format('YYYY-MM-DD'),
                enddate: moment().endOf('month').format('YYYY-MM-DD')
            },
            {
                string: 'Last Month',
                withCalendar: false,
                view: null,
                startdate: moment(moment().months((moment().months()-1)).format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD'),
                enddate: moment(moment().months((moment().months()-1)).format('YYYY-MM-DD')).endOf("month").format('YYYY-MM-DD')
            },
            {
                string: 'Next Month',
                withCalendar: false,
                view: null,
                startdate: moment(moment().months((moment().months() + 1)).format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD'),
                enddate: moment(moment().months((moment().months() + 1)).format('YYYY-MM-DD')).endOf("month").format('YYYY-MM-DD')
            },
            {
                string: 'Last 3 Months',
                withCalendar: false,
                view: null,
                startdate: moment(moment().months((moment().months()-3)).format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD'),
                enddate: moment().endOf('month').format('YYYY-MM-DD')
            },
            {
                string: 'Date Range',
                withCalendar: true,
                view: 'default',
                startdate: moment().format('YYYY-MM-DD'),
                enddate: moment().format('YYYY-MM-DD')
            }
        ];

        this.getFilterOptions = function() {
            return filterOptions;
        };
});
