commusoftCommon.directive('addAttributes', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var attributesArray = scope.attributesArray;
            if(attributesArray) {
                _.each(element.find('option'), function(optionElement,key) {
                    var optionValue = $(optionElement).val();
                    if (optionValue > 0 && attributesArray.hasOwnProperty(optionValue)) {
                        for(var index = 0; index < attributesArray[optionValue].length; index ++) {
                            var attribute = attributesArray[optionValue][index];
                            $(optionElement).attr(attribute.key, attribute.value);
                        }
                    }
                });
            }
        }
    }
});