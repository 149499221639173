// // Known as spreadsheet_merge_row_helper.js in CUI (rename after merge).
// // csmodule.service('spreadsheetMergeRowHelper', function($rootScope) {

// csmodule.service('mergePricingItemsHelper', function($rootScope) {
//   var self;

//   this.handleMerge = function handleMerge(context, directive_scope) {
//       self = this;
//       this.checkbox_scope = context;
//       this.directive_scope = directive_scope;
//       this.rows = directive_scope.spreadsheet_rows;
//       this.tax_rate = this.rows[context.$index].row[3].value;
//       this.un_merged_row_ids = [];

//       // spreadsheet scope
//       directive_scope.allowed_tax_rate = this.tax_rate;
//       $rootScope.spreadsheet_instance_id = directive_scope.scope_id;

//       this.handleCheckboxes();
//   }

//   /*==========================================================================================
//    Keep this.directive_scope.merge_pricing_items_store array in sync with selected rows
//    ==========================================================================================*/
//   this.handleCheckboxes = function handleCheckboxes() {
//       var not_on_page_load = this.checkbox_scope.merge_row !== undefined;

//       if(not_on_page_load) {
//           var should_merge = this.checkbox_scope.merge_row;

//           if(should_merge) {
//               // push the checkbox into merge_pricing_items_store
//               this.directive_scope.merge_pricing_items_store.push({
//                   checkbox_id : this.checkbox_scope.$id,
//                   row_index: this.checkbox_scope.$parent.$index,
//                   row : this.checkbox_scope.$parent.row
//               });
//           }
//           else {
//               // remove the checkbox from this.directive_scope.merge_pricing_items_store
//               var id_of_checkbox_to_remove = this.checkbox_scope.$id;

//               for(var i = 0, l = this.directive_scope.merge_pricing_items_store.length; i < l; i++) {
//                   var checkbox_id = this.directive_scope.merge_pricing_items_store[i].checkbox_id;

//                   if(checkbox_id === id_of_checkbox_to_remove) {
//                       this.directive_scope.merge_pricing_items_store.splice(i, 1);
//                       return;
//                   }
//               }
//           }
//       }
//   }

//   this.insertMergedRow = function insertMergedRow(path, row, merged_row_ids) {
//       this.directive_scope.merge_pricing_items_store = [];
//       this.directive_scope.saveMergedRow(path, row, merged_row_ids);
//   }

//   this.removeMergedRows = function removeMergedRows(merge_data) {
//       var indexes_to_remove = [];

//       for(var i = 0, l = merge_data.length; i < l; i++) {
//           var row_index = merge_data[i].row_index;
//           indexes_to_remove.push(row_index);
//       }

//       // sort the indexes from biggest number to smallest
//       indexes_to_remove.sort(function(a, b) {
//           return b - a;
//       });

//       // remove the rows
//       for(var i = 0, l = indexes_to_remove.length; i < l; i++) {
//           var row_index = indexes_to_remove[i];
//           this.rows.splice(row_index, 1);
//       }
//   }

//   /*==========================================================================================
//    Handle unmerging an already merged row
//    ==========================================================================================*/

//   this.handleUnMerge = function handleUnMerge() {
//       this.directive_scope.updateMergedRow();
//       this.hideUnMergeModal();
//   }

//   this.hideUnMergeModal = function hideUnMergeModal(un_merge_modal) {
//       $(un_merge_modal).modal('hide');
//   }
// });