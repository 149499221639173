commusoftCommon.directive('smartexpiryinput', function($rootScope, $timeout, keyBoardKeys) {
    var getCursorPos = function(input) {
        var cursor_pos = 0;

        if (document.selection) {
            input.focus();
            var oSel = document.selection.createRange();
            oSel.moveStart('character', -input.value.length);
            cursor_pos = oSel.text.length;
        }

        else if (input.selectionStart || input.selectionStart == '0') {
            cursor_pos = input.selectionStart;
        }

        return (cursor_pos);
    }

    var setCursorPos = function(input, cursor_pos) {
        if (input != null) {
            if (input.createTextRange) {
                var range = input.createTextRange();
                range.move('character', cursor_pos);
                range.select();
            }
            else {
                if (input.selectionStart) {
                    input.focus();
                    input.setSelectionRange(cursor_pos, cursor_pos);
                }
                else {
                    input.focus();
                }
            }
        }
    }

    var handle_smart_expiry_input = function(scope, element, attrs) {
        scope.isSetRequired = false;

        if(attrs.isSetRequired == "true"){
            scope.isSetRequired = true;
        }

        if (attrs.disabled === "true") {
            scope.disabled = true;
        } else {
            scope.disabled = false;
        }

        scope.element = element;
        if(attrs.defaultValue) {
            scope.expiry_date = moment(attrs.defaultValue).format('MM/YY');
        } else {
            scope.expiry_date = '';
        }

        scope.storePreviousValue = function(expiry_date) {
            scope.previous_value = expiry_date;
        }

        scope.handleExpiryDate = function handleExpiryDate(e, expiry_date) {
            var inputted_month = parseInt(expiry_date.substring(0, 2)),
                key_is_numeric = false,
                key_code = keyBoardKeys.getKey(event.keyCode),
                backspace_pressed = event.keyCode === 8;
            key_is_numeric = !isNaN(parseFloat(key_code));

            scope.cursor_position = getCursorPos(e.target);

            if (key_is_numeric) {
                // When inputting the month
                if (scope.cursor_position === 1) {
                    scope.handleFirstCharOfMonth(inputted_month);
                } else if (scope.cursor_position === 2) {
                    scope.handleSecondCharOfMonth(inputted_month);
                }

                // When trying to input something that's in between the month and year
                else if (scope.cursor_position === 3 || scope.cursor_position === 4 || scope.cursor_position === 5) {
                    var inputted_month = scope.expiry_date.substring(0, 2);

                    scope.expiry_date = inputted_month + ' / ';
                    scope.expiry_date += key_code;
                }

                // > 9 so that year supports "15" and "2015" before trimming preventing the input of more characters
                // Trim off characters, the user has filled out too many characters
                else if (scope.cursor_position > 9) {
                    scope.preventChange();
                } else if (scope.expiry_date.length > 9) {
                    scope.expiry_date = scope.expiry_date.slice(0, -1);
                }
            }

            // Handle backspaces
            else if (backspace_pressed) {
                if (scope.cursor_position === 2 || scope.cursor_position === 3 || scope.cursor_position === 4) {
                    var previous_char = scope.previous_value.charAt(scope.cursor_position);

                    if (scope.expiry_date.length === 2) {
                        previous_char = ' / ';
                    }

                    scope.appendCharacterAtCursorPosition(previous_char);

                    if (scope.expiry_date.length === 4) {
                        scope.expiry_date += ' ';
                    }
                }
            }

            // Note that key_code is undefined if the key is not alphanumeric
            else if (key_code !== undefined) {
                scope.preventChange(scope.cursor_position);
            }

            $rootScope.$broadcast('smartexpiryinput:date_changed', scope.expiry_date);

            $timeout(function() {
                if (scope.expiry_date.length > 5 && scope.expiry_date.length !== 6) {
                    var month = scope.expiry_date.split(' / ')[0],
                        year = scope.expiry_date.split(' / ')[1];

                    var date = moment(month + '/01/' + year);

                    if (date.isValid()) {
                        if (moment().diff(date, 'months') > 0) {
                            $rootScope.$broadcast('smartexpiryinput:date_invalid');
                        }
                    } else {
                        $rootScope.$broadcast('smartexpiryinput:date_invalid');
                    }
                }
            }, 100)
        }

        scope.handleFirstCharOfMonth = function(inputted_month) {
            if (inputted_month < 13 && scope.expiry_date.length === 1) {
                if (inputted_month > 1) {
                    scope.expiry_date = '0' + inputted_month + ' / ';
                }
            } else if (inputted_month < 13 &&
                scope.cursor_position === 1 &&
                scope.expiry_date.length > 1 &&
                scope.expiry_date.indexOf('/') === 2) {

                scope.cursor_position --;
                scope.appendCharacterAtCursorPosition('0');

                var input = scope.element[0].querySelector('#expiry_date');

                input.focus();
                input.value = scope.expiry_date;
            } else if (inputted_month < 13 &&
                scope.cursor_position === 1 &&
                scope.expiry_date.length > 1 &&
                scope.expiry_date.indexOf('/') === 3) {

                var input = scope.element[0].querySelector('#expiry_date');

                input.focus();
                input.value = scope.expiry_date;

                if (scope.expiry_date.length === 4) {
                    scope.expiry_date += ' ';
                }
            } else if (inputted_month < 13 && scope.expiry_date.length > 1) {
                // The user is editing the month and the month is valid, replace the month with what the user wants
                var potential_new_date = scope.expiry_date.slice(0, 1) + scope.expiry_date.slice(scope.cursor_position + 1);
                if (parseInt(potential_new_date.substring(0, 2)) < 13) {
                    scope.expiry_date = potential_new_date;
                } else {
                    scope.preventChange();
                }
            } else {
                scope.preventChange();

                if (scope.expiry_date.length === 3) {
                    scope.cursor_position --;
                    scope.appendCharacterAtCursorPosition('0');
                    scope.expiry_date += ' ';
                }
            }
        }

        scope.handleSecondCharOfMonth = function(inputted_month) {
            if (inputted_month < 13 && scope.expiry_date.length === 2) {
                scope.expiry_date += ' / ';
            } else if (inputted_month < 13 &&
                scope.cursor_position === 2 &&
                scope.expiry_date.length > 2 &&
                scope.expiry_date.indexOf('/') === 3) {

                var input = scope.element[0].querySelector('#expiry_date');

                input.focus();
                input.value = scope.expiry_date;
                scope.expiry_date += ' ';
            } else if (inputted_month < 13 && scope.expiry_date.length > 2) {
                // The user is editing the month and the month is valid, replace the month with what the user wants
                scope.expiry_date = scope.expiry_date.slice(0, 2) + scope.expiry_date.slice(scope.cursor_position + 1);
            } else {
                scope.preventChange();
            }
        }

        scope.preventChange = function() {
            var input = scope.element[0].querySelector('#expiry_date');
            scope.expiry_date = scope.expiry_date.slice(0, scope.cursor_position - 1) + scope.expiry_date.slice(scope.cursor_position);

            $timeout(function() {
                setCursorPos(input, scope.cursor_position - 1);
            });
        }

        scope.appendCharacterAtCursorPosition = function(character) {
            scope.expiry_date = scope.expiry_date.slice(0, scope.cursor_position) + character + scope.expiry_date.slice(scope.cursor_position);
        }

    }

    return {
        restrict: 'A',
        scope: {},
        templateUrl: '/template/smart_expiry_input.html',
        link: handle_smart_expiry_input
    }
});