csmodule.directive('dashboardHtmlWidget', ['$rootScope', '$document', '$window', '$timeout', '$state', function($rootScope, $document, $window, $timeout, $state) {
    return {
        restrict: 'A',
        priority: -1000,
        templateUrl: 'template/dashboard_html_widget.html',
        link: function(scope, elem, attrs) {
            scope.determineTextWidth = function(index) {
                if ($(elem).find('.html-stats-box').eq(index).find('.html-stats-box-content .text').eq(0).text().indexOf('{{ box.content }}') == -1) {
                    // Wait until after content has been loaded into the UI
                    var difference = $(elem).find('.html-stats-box').eq(index).width() - $(elem).find('.html-stats-box').eq(index).find('.html-stats-box-content .text-inner').eq(0).width();
                    if (difference < 50 && difference >= -23) {
                        $(elem).find('.html-stats-box').eq(index).find('.html-stats-box-content .text').addClass('font-size-62');
                    }
                    if (difference < -23 && difference >= -181) {
                        $(elem).find('.html-stats-box').eq(index).find('.html-stats-box-content .text').addClass('font-size-42');
                    }
                    if (difference < -181 && difference >= -340) {
                        $(elem).find('.html-stats-box').eq(index).find('.html-stats-box-content .text').addClass('font-size-32');
                    }
                    if (difference < -340 && difference >= -498) {
                        $(elem).find('.html-stats-box').eq(index).find('.html-stats-box-content .text').addClass('font-size-22');
                    }
                    if (difference < -498) {
                        $(elem).find('.html-stats-box').eq(index).find('.html-stats-box-content .text').addClass('font-size-15');
                    }
                }
            }
            scope.determineBoxWidth = function(index, array, type) {
                if (array[index]['content']) {
                    scope.determineTextWidth(index);

                    var correctedIndex = index + 1;
                    //For SLA widgets with 4 stats show 2x2 design
                    if (type === 'SLA due to breach') {
                        return 'half-width'
                    }



                    // maxnum is the max number of cells that can be in a row.
                    var maxnum = 3;

                    if ((correctedIndex % maxnum) == 1 && (correctedIndex == (array.length - 1))) {
                        // This is true, if, for example, the maxnum is 3, correctedIndex is 4 (fourth cell) and the total number of cells is 5
                        // This means that the 4th cell with have a width of 50% of the row. The 5th cell will also have 50% width.
                        // This is the cell AFTER the last cell of the preceding row
                        return 'half-width';
                    }
                    if ((correctedIndex % maxnum) == 2 && (correctedIndex == (array.length))) {
                        // This is true, if, for example, the maxnum is 3, correctedIndex is 5 (fifth cell) and the total number of cells is 5
                        // This means that the 5th cell with have a width of 50% of the row.
                        // This is the 2nd cell after the 1st in the current row
                        return 'half-width';
                    }
                    if ((correctedIndex % maxnum) == 1 && (correctedIndex == (array.length))) {
                        // This is true, if, for example, the maxnum is 3, correctedIndex is 1 or 4 (first or fourth cell) and the total number of cells is 1 or 4.
                        // The cell will have 100% width.
                        // This is the cell AFTER the last cell of the preceding row
                        return 'full-width';
                    }
                    if ((correctedIndex % maxnum) == 0 && (correctedIndex == (array.length))) {
                        // This is true, if, for example, the maxnum is 3, correctedIndex is 3 or 6 (third or sixth cell) and the total number of cells is 3 or 6.
                        // The cell will have 100% width.
                        // This is the cell at the very end of the current row
                        return 'full-width';
                    }
                    else if ((array.length % maxnum) == 0) {
                        // If the total number of cells is divisible by 3, put two cells in the top row with 50% width
                        return 'half-width';
                    }
                    else {
                        // Else, if the total number if cells is NOT divisible by 3 and none of the cases above are true, then put three cells in the top row with 33% width each
                        return 'third-width';
                    }
                }
            }

		scope.redirectreportwithfilter = function (event) {
			$state.go('loggedin.reporting.view', {'category': 'jobs', 'subcategory': event, 'flag' :1});

            }

        scope.redirectToReportsStates = function (state, params) {
            $state.go(state, params);
        }
            scope.redirectToReports = function (title, event) {
                var category = '';
                if (title == 'Parts status') {
                    category = 'parts';
                    if (event == 'requested_parts') {
                        var state = 'requested';
                    } else if (event == 'parts_on_order') {
                        var state = 'on_order';
                    } else if (event == 'parts_available_without_future_diary_events') {
                        var state = 'available';
                    }
                    $state.go('loggedin.reporting.operational', {
                        'category': 'reporting_operational_list_parts',
                        'subcategory': 'parts',
                        'type': state
                    });
                    return;
                } else if (title == 'Parts on order' || title == 'Parts not arrived for upcoming diary events' || title == 'Parts due to be delivered to site') {
                    category = 'parts';
                } else if (title == 'Parts to be returned') {
                    category = 'stock_items';
                    if (event == 'parts.not.used') {
                        var state = 'excess_for_job';
                    } else if (event == 'Returning.to.stock') {
                        var state = 'stock_location';
                    } else if (event == 'Returning.to.supplier') {
                        var state = 'supplier';
                    }
                    $state.go('loggedin.reporting.operational.stocks', {
                        'category': 'stock_control',
                        'subcategory': 'returns_operational_list',
                        'type': state
                    });
                    return;
                } else if (title == 'SLA metrics breached status') {
                    category = 'jobs';
                    if (event == 'Job creation to first diary event') {
                        event = 'sla_job_creation_to_first_diary_event_breached';
                    }
                    if (event == 'Job creation to engineer actual arrival time') {
                        event = 'sla_job_creation_to_engineer_actual_arrival_time_breached';
                    }
                    if (event == 'Job creation to job completion') {
                        event = 'sla_job_creation_to_job_completion_breached';
                    }
                    if (event == 'Actual complete date/time vs Job required complete date/time') {
                        event = 'sla_job_start_to_enddatetime_versus_actual_breached';
                    }
                } else if (title == 'Timesheets awaiting approval' || event == 'EngineerTimesheetStatus.helptext') {
                    category = 'users';
                    event = 'timesheet_approval';
                } else if (title == 'SLA due to breach') {
                    category = 'jobs';
                    if (event == 'SLA due to breach in 0-60 minutes') {
                        event = 'sla_due_to_breach_in_0-60_minutes';
                    }
                    if (event == 'SLA due to breach in 61-120 minutes') {
                        event = 'sla_due_to_breach_in_61-120_minutes';
                    }
                    if (event == 'SLA due to breach in 121-240 minutes') {
                        event = 'sla_due_to_breach_in_121-240 minutes';
                    }
                    if (event == 'SLA due to breach in 240+ minutes') {
                        event = 'sla_due_to_breach_in_240+_minutes';
                    }
                }
                $state.go('loggedin.reporting.view', {
                    'category': category, 'subcategory': event,
                    'flag': 1
                });
            }
        }
    }
}]);