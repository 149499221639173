csmodule.factory('reportFilterSettings', function($location, $timeout, prefix, $http, dateRange) {
    var filterSettings = {
        availableFilterOptions: {},
        availableTabFilters: {},
        filtersFinishedLoading: false,
        deletedTags: [],
        inputTagsWithMetadata: [],
        savedFilters: [],
        defaultFilters: {
            'customers': {
                'advertising_performance': [
                    {'model': 'daterange', 'value_label': 'Last 3 months', 'value': dateRange.getFilterOptions()[6]}
                ]
            },
            'users': {
                'gps_leaderboard': [
                    {'label': 'Date', 'model': 'daterange_today', 'value_label': 'Today', 'value': (function() {
                        var startDate = new Date();
                        startDate = startDate.getFullYear() + '-' + (startDate.getMonth()+1) + '-' + startDate.getDate() + ' 00:00:00';

                        var endDate = new Date();
                        endDate = endDate.getFullYear() + '-' + (endDate.getMonth()+1) + '-' + endDate.getDate() + ' 23:59:59';

                        var date = {'startDate': startDate, 'endDate': endDate};

                        return date;
                    })()}
                ],
                'driver_detail': [
                    {
                        'label': 'Date',
                        'model': 'daterange_last_week',
                        'value_label': 'Lastweek',
                        'value': (function () {
                            var today = moment();
                            var daystoLastsunday = today.weekday(-7).format('YYYY-MM-DD');
                            var startDate = daystoLastsunday + ' 00:00:00';
                            var endDate = moment(daystoLastsunday).add('days', 6).format('YYYY-MM-DD') + ' 23:59:59';
                            var date = {'startDate': startDate, 'endDate': endDate};
                            return date;
                        })()
                    }
                ],

                'driver_trips': [
                    {
                        'label': 'Date',
                        'model': 'daterange_last_week',
                        'value_label': 'Lastweek',
                        'value': (function () {
                            var today = moment();
                            var daystoLastsunday = today.weekday(-7).format('YYYY-MM-DD');
                            var startDate = daystoLastsunday + ' 00:00:00';
                            var endDate = moment(daystoLastsunday).add('days', 6).format('YYYY-MM-DD') + ' 23:59:59';
                            var date = {'startDate': startDate, 'endDate': endDate};
                            return date;
                        })()
                    }
                ]
            }
        },
        monthLookup: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ],
        dayOfWeekLookup: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ],
        getDefaultFilters: function(category, subcategory) {
            if (this.defaultFilters[category]) {
                if (this.defaultFilters[category][subcategory]) {
                    return this.defaultFilters[category][subcategory];
                }
            }
            return false;
        },
        parseDynamicURL: function(inputTags, url, searchterm) {
            /* START URL PARSING */

            /*  The purpose of this block of code is to parse the following pattern of URL parameters:
             /get_all_customers?customerTypeId={{customerType}}&q=searchterm

             In this example, {{customerType}} will be replaced by the value of the customerType filter, if it has been applied by the user.

             If the customerType filter has not been applied by the user, then the following block of code will remove "customerTypeId={{customerType}}" from the URL string, resulting in the new string:
             /get_all_customers?q=searchterm

             It is possible for URL parameter strings to contain multiple dynamic values, such as the following:
             /get_all_customers?customerTypeId={{customerType}}&makeId={{make}}&q=searchterm

             In this example, {{customerType}} and {{make}} all refer to other filters. Each of these will be replaced by the corresponding filter's value.

             If the filter with the model "make" has been selected by the user and given the value "Baxi", then the parsed URL will result in the following:

             /get_all_customers?customerTypeId=1&makeId=Baxi&q=searchterm
             */

            var parameters = url.match(/(\?|&)(.+?)(?=&|$)/g);

            angular.forEach(parameters, function(param, index) {
                if (param[0]) {
                    param = param.substr(1, param.length);

                    var paramval = param.match(/{{.+?}}/g);

                    if (paramval instanceof Array) {
                        paramval.forEach(function (unparsed){
                            var modelName = unparsed.replace('{{', '').replace('}}', '');
                            var interpolatedValueFound = false;

                            angular.forEach(inputTags, function(tag, tagindex) {
                                if (modelName == tag['model']) {
                                    interpolatedValueFound = tag['value'];
                                }
                            });

                            if (interpolatedValueFound) {
                                url = url.replace(unparsed, interpolatedValueFound);
                            }
                            else {
                                if (modelName == 'Type') {
                                    url = url.replace(unparsed, 'type');
                                }
                                else {
                                    var regexIncludingAmpersand = new RegExp(param + "(&|$)", 'g');
                                    url = url.replace(regexIncludingAmpersand, ""); // delete the whole parameter if a value isn't found above

                                }
                            }

                        })


                    }
                }
            });

            var urlToUse = prefix + url;

            if (urlToUse.indexOf('?') > -1 && urlToUse.indexOf('?') !== urlToUse.length-1) {
                urlToUse = urlToUse + '&';
            }
            else if (urlToUse.indexOf('?') == -1) {
                urlToUse = urlToUse + '?';
            }

            /* END URL PARSING */

            return urlToUse + 'q=' + searchterm;

        },
        getJsonifiedFilter: function(filtermodel, filtervalue) {
            if (filtervalue instanceof Object) {
                filtervalue = angular.toJson(filtervalue);
                if (filtervalue.indexOf('startDate') > -1 && filtervalue.indexOf('endDate') > -1) {
                    // this means it's a daterange filter
                    filtermodel = 'daterange_' + filtermodel;
                }
                else {
                    // else, if it's not a daterange filter but it contains json, prepend "json_" to the filter key so that we can convert it back into an object later
                    filtermodel = 'json_' + filtermodel;
                }
            }

            if (typeof filtervalue !== "string") {
                // if it's a boolean value, convert it to a string
                filtervalue = String(filtervalue);
            }

            if (typeof filtermodel !== "string") {
                // to catch weird cases where the model is a boolean
                filtermodel = String(filtermodel);
            }

            return {'model': filtermodel, 'val': filtervalue};
        },
        stateChange: function(from, fromParams) {
            this.deleteFiltersFromURL();
            this.deletedTags = [];
            this.previousState = from;
            this.previousStateParams = fromParams;
        },
        unjsonifyFilter: function(filter) {
            var model = filter['model'];
            var val = filter['value'];

            if (model.indexOf('json_') > -1) {
                // convert the date object back into an object from JSON
                model.split('json_')[1];
                val = angular.fromJson(val);
            }
            if (model.indexOf('daterange_') > -1) {
                // if model has the format "daterange_somedatefilter", re-format it so that we get the model name: "somedatefilter"
                model = model.split('daterange_')[1];
                // convert the date object back into an object from JSON
                val = angular.fromJson(val);
            }

            /* convert number strings back into numbers */
            if (typeof val == "string") {
                var containsAlphaChars = val.match(/[a-zA-Z]/i);
                if (containsAlphaChars == false || containsAlphaChars == null || containsAlphaChars == undefined) {
                    val = parseInt(val);
                }
                else if (containsAlphaChars instanceof Array) {
                    if (containsAlphaChars.length == 0) {
                        val = parseInt(val);
                    }
                }
            }

            /* convert true/false strings back into booleans */
            if (val == "true") {
                val = true;
            }
            if (val == "false") {
                val = false;
            }

            filter['model'] = model;
            filter['value'] = val;

            return filter;
        },
        setAvailableFilterOptions: function(val) {
            this.availableFilterOptions = val;
        },
        getAvailableFilterOptions: function() {
            return this.availableFilterOptions;
        },
        availableFilters: {},
        setAvailableFilters: function(val) {
            this.availableFilters = val;
        },
        getAvailableFilters: function() {
            return this.availableFilters;
        },
        simplifyFilters: function(data) {
            var simple = {};
            angular.forEach(data, function(filter, index) {
                simple[filter.model] = filter.value;
            });
            return simple;
        },
        queryParamsExist: function() {
            var filtersExist = false;
            angular.forEach($location.search(), function(val, key) {
                if (key.indexOf('f_') == 0) {
                    filtersExist = true;
                }
            });
            return filtersExist;
        },
        getDateString: function(start, end) {
            start = new Date(start);
            end = new Date(end);
            return this.monthLookup[start.getMonth()] + ' ' + start.getDate() + ', ' + start.getFullYear() + ' - ' + this.monthLookup[end.getMonth()] + ' ' + end.getDate() + ', ' + end.getFullYear();
        },
        deleteFilterFromURL: function(filterkey) {
            // deletes a single filterkey
            if ($location.search()['f_' + filterkey]) {
                // deleting filter
                $location.search('f_' + filterkey, null);
            }
        },
        deleteUnusedFilters: function() {
            this.deleteFilterKeysFromURL(this.deletedTags);
            this.deletedTags = [];
        },
        deleteFilterKeysFromURL: function(filters) {
            // expects the format: ['f_filtername', 'f_anotherfilter']
            angular.forEach(filters, function(filterkey, index) {
                $location.search('f_' + filterkey, null);
            });
        },
        deleteFiltersFromURL: function(filters) {
            if (filters == undefined) {
                angular.forEach($location.search(), function(filtervalue, filtermodel) {
                    this.deleteFilterFromURL(filtermodel.split('f_')[1]);
                }, this);
            }
            else {
                // expects the format: [{'f_filtername': 'somevalue'}, {'f_anotherfilter': 'someothervalue'}]
                angular.forEach(filters, function(filtervalue, filtermodel) {
                    // There are two possible filter formats. Here, we check to see what structure the object has.
                    if (filtervalue['model']) {
                        this.deleteFilterFromURL(filtervalue['model']);
                    }
                    else {
                        this.deleteFilterFromURL(filtermodel);
                    }
                }, this);
            }
        },
        getAppliedFiltersWithMetadata: function(settings) {
            var filters = settings['filters'];
            var availableFilters = settings['availableFilters'];
            var category = settings['category'];
            var subcategory = settings['subcategory'];

            var okayToAdd = true;
            var deferred = $q.defer();

            this.inputTagsWithMetadata = [];

            angular.forEach(filters, function(filter, index) {
                this.inputTagsWithMetadata.push($q.when(filter));
            }, this);

            var URLFilters = this.getQueryParamsFull();

            URLFilters.then(function(URLFiltersResp) {
                angular.forEach(URLFiltersResp, function(filter, findex) {
                    okayToAdd = true;

                    angular.forEach(this.deletedTags, function(deletedtag, dtindex) {
                        if (deletedtag == filter.model) {
                            okayToAdd = false;
                        }
                    }, this);

                    // if a list of available filters has been supplied, loop through that list and check that this is a valid filter that is able to be applied to this report type
                    if (availableFilters && okayToAdd) {
                        okayToAdd = false;

                        angular.forEach(availableFilters, function(afilter, afindex) {
                            if (afilter.model == filter.model) {
                                url_filter = angular.copy(afilter);
                                url_filter['value'] = filter.value;
                                url_filter = this.extractValueLabel(url_filter).then(function(resp) {
                                    resp['applied'] = true;
                                    return resp;
                                }, function(resp) {
                                    return url_filter;
                                });
                                okayToAdd = true;
                            }
                        }, this);
                    }

                    if (okayToAdd && url_filter) {
                        this.inputTagsWithMetadata.push($q.when(url_filter));
                    }

                    else if (okayToAdd) {
                        this.inputTagsWithMetadata.push($q.when(filter));
                    }
                }, this);

                $q.all(this.inputTagsWithMetadata).then(
                    function(resolvedInputTags) {
                        if (this.getDefaultFilters(category, subcategory)) {
                            angular.forEach(this.defaultFilters[category][subcategory], function(df, dfindex) {
                                okayToAdd = true;

                                angular.forEach(resolvedInputTags, function(inputtag, inputtagindex) {
                                    if (inputtag.model == df.model) {
                                        okayToAdd = false;
                                    }
                                }, this);

                                // if a list of available filters has been supplied, loop through that list and check that this is a valid filter that is able to be applied to this report type
                                if (availableFilters && okayToAdd) {
                                    okayToAdd = false;

                                    angular.forEach(availableFilters, function(afilter, afindex) {
                                        if (afilter.model == df.model) {
                                            default_filter = angular.copy(afilter);
                                            default_filter['value'] = df.value;
                                            default_filter = this.extractValueLabel(default_filter).then(function(resp) {
                                                resp['applied'] = true;
                                                return resp;
                                            }, function(resp) {
                                                return default_filter;
                                            });
                                            /*
                                             default_filter = this.getFilterMetadata(default_filter, availableFilters).then(function(resp) {
                                             resp['applied'] = true;
                                             return resp;
                                             }, function(resp) {
                                             return default_filter;
                                             });
                                             */

                                            okayToAdd = true;
                                        }
                                    }, this);
                                }

                                if (okayToAdd && default_filter) {
                                    this.inputTagsWithMetadata.push($q.when(default_filter));
                                }
                                else if (okayToAdd) {
                                    this.inputTagsWithMetadata.push($q.when(df));
                                }

                            }, this);
                        }

                        $q.all(this.inputTagsWithMetadata).then(
                            function(resolvedInputTags) {

                                if (this.savedFilters) {
                                    angular.forEach(this.savedFilters, function(sfilter, index) {
                                        okayToAdd = true;

                                        angular.forEach(resolvedInputTags, function(inputtag, inputtagindex) {
                                            if (inputtag.model == sfilter.model) {
                                                okayToAdd = false;
                                            }
                                        }, this);

                                        // if a list of available filters has been supplied, loop through that list and check that this is a valid filter that is able to be applied to this report type
                                        if (availableFilters && okayToAdd) {
                                            okayToAdd = false;

                                            angular.forEach(availableFilters, function(afilter, afindex) {
                                                if (afilter.model == sfilter.model) {
                                                    saved_filter = angular.copy(afilter);
                                                    saved_filter['value'] = sfilter.value;
                                                    saved_filter = this.getFilterMetadata(saved_filter, availableFilters).then(function(resp) {
                                                        resp['applied'] = true;
                                                        return resp;
                                                    }, function(resp) {
                                                        return saved_filter;
                                                    });

                                                    okayToAdd = true;
                                                }
                                            }, this);
                                        }

                                        if (okayToAdd && saved_filter) {
                                            this.inputTagsWithMetadata.push($q.when(saved_filter));
                                        }
                                        else if (okayToAdd) {
                                            this.inputTagsWithMetadata.push($q.when(sfilter));
                                        }

                                    }, this);
                                }

                                $q.all(this.inputTagsWithMetadata).then(
                                    function(resolvedInputTags) {
                                        deferred.resolve(resolvedInputTags);
                                    }.bind(this),
                                    function() {
                                        deferred.resolve(this.inputTagsWithMetadata);
                                    }.bind(this)
                                );

                            }.bind(this)
                        );

                    }.bind(this)
                );


            }.bind(this));

//                return this.inputTagsWithMetadata;

            return deferred.promise;
        },
        getAppliedFilters: function(settings) {
            var filters = settings['filters'];
            var availableFilters = settings['availableFilters'];
            var category = settings['category'];
            var subcategory = settings['subcategory'];

            var okayToAdd = true;
            this.inputTagsSimplified = {};

            angular.forEach(filters, function(filterval, filtermodel) {
                this.inputTagsSimplified[filtermodel] = filterval;
            }, this);

            var simplifiedURLFilters = this.getQueryParamsSimplified();

            angular.forEach(simplifiedURLFilters, function(filterval, filtermodel) {
                okayToAdd = true;

                angular.forEach(this.deletedTags, function(deletedtag, dtindex) {
                    if (deletedtag == filtermodel) {
                        okayToAdd = false;
                    }
                });

                // if a list of available filters has been supplied, loop through that list and check that this is a valid filter that is able to be applied to this report type
                if (availableFilters && okayToAdd) {
                    okayToAdd = false;

                    angular.forEach(availableFilters, function(afilter, afindex) {
                        if (afilter.model == filtermodel) {
                            okayToAdd = true;
                        }
                    });
                }

                if (okayToAdd) {
                    this.inputTagsSimplified[filtermodel] = filterval;
                }
            }, this);

            if (this.getDefaultFilters(category, subcategory)) {
                angular.forEach(this.defaultFilters[category][subcategory], function(df, dfindex) {
                    okayToAdd = true;
                    angular.forEach(this.inputTagsSimplified, function(inputtagvalue, inputtagmodel) {
                        if (inputtagmodel == df.model) {
                            okayToAdd = false;
                        }
                    });
                    if (availableFilters && okayToAdd) {
                        okayToAdd = false;

                        angular.forEach(availableFilters, function(afilter, afindex) {
                            if (afilter.model == df.model) {
                                okayToAdd = true;
                            }
                        });
                    }

                    // finally, check that the saved filter hasn't been manually deleted by the user
                    if (availableFilters && okayToAdd && this.deletedTags) {
                        okayToAdd = false;
                        var deletedfilter = false;
                        angular.forEach(this.deletedTags, function(deletedtag, deletedtagindex) {
                            if (deletedtag == df['model']) {
                                deletedfilter = true;
                            }
                        });
                        if (!deletedfilter) {
                            okayToAdd = true;
                        }
                    }

                    if (okayToAdd) {
                        this.inputTagsSimplified[df.model] = df.value;
                    }

                }, this);
            }

            if (this.savedFilters) {
                angular.forEach(this.savedFilters, function(sfilter, index) {
                    okayToAdd = true;

                    angular.forEach(this.inputTagsSimplified, function(inputtagvalue, inputtagmodel) {
                        if (inputtagmodel == sfilter.model) {
                            okayToAdd = false;
                        }
                    });

                    // if a list of available filters has been supplied, loop through that list and check that this is a valid filter that is able to be applied to this report type
                    if (availableFilters && okayToAdd) {
                        okayToAdd = false;

                        angular.forEach(availableFilters, function(afilter, afindex) {
                            if (afilter.model == sfilter.model) {
                                okayToAdd = true;
                            }
                        });
                    }

                    // finally, check that the saved filter hasn't been manually deleted by the user
                    if (availableFilters && okayToAdd && this.deletedTags) {
                        okayToAdd = false;
                        var deletedfilter = false;
                        angular.forEach(this.deletedTags, function(deletedtag, deletedtagindex) {
                            if (deletedtag == sfilter['model']) {
                                deletedfilter = true;
                            }
                        });
                        if (!deletedfilter) {
                            okayToAdd = true;
                        }
                    }

                    if (okayToAdd) {
                        this.inputTagsSimplified[sfilter.model] = sfilter.value;
                    }
                }, this);
            }
            return this.inputTagsSimplified;
        },
        extractValueLabel: function(filter_object) {
            var result = $q.defer();

            if (filter_object['type'] == 'smart_filter_dynamic') {
                if (!filter_object['value_label']) {
                    $http.get(prefix + filter_object['url'] + '?id=' + filter_object['value']).then(function(resp) {
                        if (resp.data) {
                            if (resp.data[0]['text']) {
                                filter_object['value_label'] = resp.data[0]['text'];
                            }
                        }

                        result.resolve(filter_object);
                    }, function(error) {
                        console.log('extractValueLabel error:');
                        console.log(error);

                        result.resolve(filter_object);
                    });
                }
                else {
                    // Else, in this case, the value_label is already defined. No need to make an API call.
                    result.resolve(filter_object);
                }
            }
            else if (filter_object['type'] == 'select') {
                angular.forEach(filter_object['options'], function(afoval, afokey) {
                    if (String(afoval['value']).toLowerCase() == String(filter_object['value']).toLowerCase()) {
                        filter_object['value_label'] = afoval['value_label'];
                    }
                }, this);

                result.resolve(filter_object);
            }
            else if (filter_object['type'] == 'checkbox') {
                filter_object['value_label'] = "";

                result.resolve(filter_object);
            }
            else if (filter_object['type'] == 'daterange') {
                if (filter_object['value']) {
                    if (filter_object['value']['startDate'] && filter_object['value']['endDate']) {
                        filter_object['value_label'] = this.getDateString(filter_object['value']['startDate'], filter_object['value']['endDate']);
                    }
                    else if (filter_object['value']['startdate'] && filter_object['value']['enddate']) {
                        filter_object['value_label'] = this.getDateString(filter_object['value']['startdate'], filter_object['value']['enddate']);
                    }
                }

                result.resolve(filter_object);
            }
            else if (filter_object['value'] instanceof Object) {
                // accounting for edge cases, such as when the value is an object
                filter_object['value_label'] = angular.toJson(filter_object['value']);

                result.resolve(filter_object);
            }
            else {
                filter_object['value_label'] = filter_object['value'];

                result.resolve(filter_object);
            }

            return result.promise;
//                    return filter_object;
        },
        updateURLFilters: function(filters, availableFilters) {
            $timeout(function() {
                var okayToAdd = false;

                angular.forEach($location.search(), function(urlfiltervalue, urlfiltermodel) {
                    $location.search(urlfiltermodel, null);
                });

                // always prioritise user-defined filters over report-specific saved filters
                angular.forEach(filters, function(filtervalue, filtermodel) {
                    if ($location.search()['f_' + filtermodel] == undefined) {
                        okayToAdd = false;

                        angular.forEach(availableFilters, function(afilter, afindex) {
                            // check to see if this is a valid filter that is applicable to this report type
                            if (afilter.model == filtermodel) {
                                okayToAdd = true;
                            }
                        });

                        if (okayToAdd && this.deletedTags) {
                            angular.forEach(this.deletedTags, function(dtag, dindex) {
                                if (dtag == filtermodel) {
                                    okayToAdd = false;
                                }
                            }, this);
                        }

                        if (okayToAdd) {
                            var jsonifiedFilter = this.getJsonifiedFilter(filtermodel, filtervalue);
                            $location.search('f_' + jsonifiedFilter.model, jsonifiedFilter.val);
                        }
                    }
                }, this);

                if (this.savedFilters) {
                    angular.forEach(this.savedFilters, function(sfilter, index) {
                        if ($location.search()['f_' + sfilter.model] == undefined) {
                            okayToAdd = false;

                            angular.forEach(availableFilters, function(afilter, afindex) {
                                // check to see if this is a valid filter that is applicable to this report type
                                if (afilter.model == sfilter.model) {
                                    okayToAdd = true;
                                }
                            });

                            if (okayToAdd && this.deletedTags) {
                                angular.forEach(this.deletedTags, function(dtag, dindex) {
                                    if (dtag == sfilter.model) {
                                        okayToAdd = false;
                                    }
                                }, this);
                            }

                            if (okayToAdd) {
                                var jsonifiedFilter = this.getJsonifiedFilter(sfilter.model, sfilter.value);
                                $location.search('f_' + jsonifiedFilter.model, jsonifiedFilter.val);
                            }
                        }
                    }, this);
                }


                //this.deleteUnusedFilters();

            }.bind(this), 0);

        },
        getFilterMetadata: function(filter, availableFilters) {
            var filter_object = false,
                filtersToLoopThrough = this.availableFilters,
                returndata = false;

            if (filter instanceof Object) {
                if (availableFilters) {
                    filtersToLoopThrough = availableFilters;
                }

                angular.forEach(filtersToLoopThrough, function(afval, afkey) {
                    if (afval['model']) {
                        if (typeof afval['model'] == "string") {
                            var unJsonifiedFilter = this.unjsonifyFilter(filter);

                            if (unJsonifiedFilter.model.toLowerCase() == afval['model'].toLowerCase()) {
                                filter_object = angular.copy(unJsonifiedFilter);

                                angular.forEach(unJsonifiedFilter, function(prop, propkey) {
                                    if (propkey !== 'value' && propkey !== 'model') {
                                        filter_object[propkey] = prop;
                                    }
                                }, this);
                            }
                        }
                    }
                }, this);
            }

            if (filter_object == false) {
                var unJsonifiedFilter = this.unjsonifyFilter(filter);
                filter_object = unJsonifiedFilter;
                returndata = filter_object;
            }
            else if (filter_object !== false) {
                returndata = this.extractValueLabel(filter_object).then(function(filterdata) {
                    return filterdata;
                }, function(error) {
                    return filter;
                });
            }

            return $q.when(returndata);
        },
        getQueryParamsFull: function() {
            var tempTags = false;
            tempTags = [];

            angular.forEach($location.search(), function(val, key) {
                if (key.indexOf('f_') == 0) {
                    var model = key.split('f_')[1];
                    var filter_object = this.getFilterMetadata({'model': model, 'value': val}).then(
                        function(resolvedFilter) {
                            return resolvedFilter;
                        }.bind(this),
                        function() {
                            return {};
                        }.bind(this)
                    );

                    tempTags.push($q.when(filter_object));
                }
            }, this);

            /*
             if (tempTags.length > 0) {
             var filterPromises = $q.all(tempTags);
             }
             else {
             var filterPromises = $q.when(tempTags);
             }
             */

            return $q.all(tempTags);
        },
        promiseRace: function(promises) {
            var deferred = $q.defer();

            angular.forEach(promises, function(promise) {
                $q.when(promise).then(function() {
                    deferred.resolve(promises);
                }, function() {
                    deferred.reject(promises);
                });
            });
            return deferred.promise;
        },
        addSavedFilters: function(filters) {
            var filterWithMetadata = {};
            var returnarray = [];
            var deferred = $q.defer();

            angular.forEach(filters, function(filter, index) {
                filterpromise = this.getFilterMetadata(filter).then(
                    function(filterWithMetadata) {
                        return filterWithMetadata;
                    },
                    function() {
                        return {};
                    }
                );

                if (filterpromise) {
                    returnarray.push($q.when(filterpromise).then(function(filter) {
                        return filter;
                    }));
                }
                else {
                    // else, if no metadata was found, just save whatever filter data that we do have
                    returnarray.push($q.when(filter).then(function(resolvedfilter) {
                        return resolvedfilter;
                    }));
                }
            }, this);

            $q.all(returnarray).then(function(returnedarray) {
                    this.savedFilters = returnedarray;
                    deferred.resolve(this.savedFilters);
                }.bind(this),
                function(error) {
                    console.log('error saving filters');
                    this.savedFilters = [];
                    deferred.reject(this.savedFilters);
                }.bind(this));

            return deferred.promise;
        },
        getQueryParamsWithoutParsing: function() {
            var tempTags = false;
            tempTags = {};
            angular.forEach($location.search(), function(val, key) {
                if (key !== 'filters' && key.indexOf('f_') == 0) {
                    var model = key.split('f_')[1].toLowerCase();
                    var unJsonifiedFilter = this.unjsonifyFilter({'model': model, 'value': val});
                    tempTags[unJsonifiedFilter.model] = unJsonifiedFilter.value;
                }
            }, this);
            return tempTags;
        },
        getQueryParamsSimplified: function() {
            var tempTags = false;
            tempTags = {};

            angular.forEach($location.search(), function(val, key) {
                if (key !== 'filters' && key.indexOf('f_') == 0) {
                    var model = key.split('f_')[1];
                    var unJsonifiedFilter = this.unjsonifyFilter({'model': model, 'value': val});

                    tempTags[unJsonifiedFilter.model] = unJsonifiedFilter.value;
                }
            }, this);
            return tempTags;
        }
    };

    return filterSettings;
});