commusoftCommon.filter('millisecToHms', function () {
    /*
        Converts milliseconds into combined Hours, Minutes & Seconds

        @param number milliseconds
        @param null format --valid units={null|named}
        @return string
    */

    return function (milliseconds, format) {
        var sigs = { "H": 0, "M": 0, "S": 0 };
        milliseconds = parseInt(milliseconds);

        sigs['S'] = Math.floor(milliseconds / 1000);
        sigs['M'] = Math.floor(sigs['S'] / 60);
        sigs['H'] = Math.floor(sigs['M'] / 60);
        sigs['S'] = sigs['S'] % 60;
        sigs['M'] = sigs['M'] % 60;

        var hms = '';

        function lPad(v, p) {
            v.toString().padStart(p, 0).slice(-p);
        }

        switch (format) {
            case "named": {
                if (sigs['H'] > 0) hms += sigs['H'] + ' h ';
                if (sigs['M'] > 0) hms += sigs['M'] + ' m ';
                if (sigs['S'] > 0) hms += sigs['S'] + ' s ';

                if (hms === '') hms = '0 h 0 m';
                break;
            }
            default: {
                hms = lPad(sigs['H'], 2) + ':' + lPad(sigs['M'], 2) + ':' + lPad(sigs['S'], 2);
                break;
            }
        }

        return hms;
    }
});