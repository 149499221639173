commusoftCommon.service('panelValidationHelper', function($q, $rootScope, postCodeHelper) {
    this.invalid_input_refs = [];
    this.server_side_validation_failed = false;

    /*==========================================================================================
     Add event to input template which calls handleValidaiton
     ==========================================================================================*/
    this.addValidation = function addValidation(input_tpl, input_type, model_ref, input_label, error_message) {
        var validation_function = '"handleValidation(' + "'"+  input_type + "', " + "'" +  model_ref + "', " + "'" +  input_label + "', " + "'" +  error_message + "'" + ', $event)"',
            validation_tpl = ' ng-keyup=' + validation_function,
            text_input = input_type === 'text',
            postcode_input = input_type === 'postcode',
            date_input = input_type === 'date',
            textarea_input = input_type === 'textarea',
            select_input = input_type === 'select',
            smart_filter_input = input_type === 'smart_filter',
            smart_filter_with_optgroup_input = input_type === 'smart_filter_with_optgroup',
            number_input = input_type === 'number',
            email_input = input_type === 'email',
            from_input = input_type === 'from',
            pos,
            new_input_tpl;

        if(text_input || number_input || email_input || from_input) {
            pos = input_tpl.length - 1;
        }else if(postcode_input) {
            pos = (input_tpl.indexOf('ng-change') - 1);
        }else if(date_input) {
            pos = (input_tpl.indexOf('type="text"') - 1);
        }else if(textarea_input) {
            pos = (input_tpl.indexOf('ng-model') - 1);
        }else if(select_input || smart_filter_input || smart_filter_with_optgroup_input) {
            pos = (input_tpl.indexOf('ng-model') - 1);
            validation_tpl += ' ng-change=' + validation_function;
        }
        if(input_type != 'number'&& input_type != 'email'&& input_type != 'from' && input_type != 'time' && input_type != 'price' && input_type != 'markup'){
            if (this.invalid_input_refs.indexOf(model_ref) == -1) {
                this.invalid_input_refs.push(model_ref);
            }
        }
        new_input_tpl = [input_tpl.slice(0, pos), validation_tpl, input_tpl.slice(pos)].join('');
        return new_input_tpl;
    }

    /*==========================================================================================
     Assert whether the input is valid or not
     ==========================================================================================*/
    this.validateInput = function validateInput(type, model_ref, input_label, error_message, e) {
        var deferred = $q.defer(),
            input,
            value,
            text_input = type === 'text',
            postcode_input = type === 'postcode',
            date_input = type === 'date',
            textarea_input = type === 'textarea',
            select_input = type === 'select',
            smart_filter_input = type === 'smart_filter',
            smart_filter_with_optgroup_input = type === 'smart_filter_with_optgroup',
            number_input = type === 'number',
            email_input = type === 'email',
            from_input = type === 'from',
            is_valid;

        if(select_input || smart_filter_with_optgroup_input) {
            input = document.querySelector("[ng-model=" + model_ref + "]");
            value = input.value;
            is_valid = this.validateSelectField(value);
        }else if(smart_filter_input) {
            input = document.querySelector("[ng-model=" + model_ref + "]");
            value = input.options[input.selectedIndex].text;
            is_valid = this.validateTextField(value);
        }else {
            input = e.target;
            value = input.value;
        }

        if(text_input) {
            is_valid = this.validateTextField(value);
        }else if(postcode_input) {
            is_valid = this.validatePostcodeField(value);
        }else if(date_input) {
            is_valid = this.validateDateField(value);
        }else if(textarea_input) {
            is_valid = this.validateTextField(value);
        }else if(number_input && model_ref != 'installtime' && model_ref != 'price' && model_ref != 'markup') {
            is_valid = this.validateNumberField(value);
        }else if(email_input) {
            is_valid = this.validateEmailField(value);
        }else if(from_input) {
            is_valid = this.validateFromField(value);
        }else if(model_ref == 'installtime') {
            is_valid = this.validateTimeField(value);
        }else if(model_ref == 'price') {
            is_valid = this.validatePriceField(value);
        }else if(model_ref == 'markup') {
            is_valid = this.validateMarkupField(value);
        }
        if(this.server_side_validation_failed) {
            this.resetClientSideErrorMessages(input, error_message, text_input, textarea_input, postcode_input, date_input, select_input, smart_filter_input, smart_filter_with_optgroup_input, number_input);
        }

        var return_obj = {
            input_type: type,
            model_ref: model_ref,
            event: e
        }

        is_valid ? deferred.resolve(return_obj) : deferred.reject(return_obj);
        return deferred.promise;
    }

    /*==========================================================================================
     Validate text input
     ==========================================================================================*/
    this.validateTextField = function validateTextField(value) {
        var is_valid = value.trim().length > 0;
        return is_valid;
    }

    /*==========================================================================================
     Validate postcode input
     ==========================================================================================*/
    this.validatePostcodeField = function validatePostcodeField(value) {
        var is_valid = !postCodeHelper.validatePostcode(value);
        return is_valid;
    }

    /*==========================================================================================
     Validate date input
     ==========================================================================================*/
    this.validateDateField = function validateDateField(value) {
        var date_pattern =/^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{2}|[0-9]{4})$/,
            is_valid = date_pattern.test(value);

        return is_valid;
    }
    /*==========================================================================================
     Validate Number input
     ==========================================================================================*/
    this.validateNumberField = function validateNumberField(value) {
        if(value){
            var input_pattern =/^([0-9]{1,})$/,
                is_valid = input_pattern.test(value);
        }
        else {
            is_valid = true;
        }
        return is_valid;
    }
    /*==========================================================================================
     Validate From input
     ==========================================================================================*/
    this.validateFromField = function validateFromField(value) {
        if(value){
            var input_pattern =/^(\-?[0-9]{1,2}(\.[0-9]{1,2})?|100)$/,
                is_valid = input_pattern.test(value);
        }
        else {
            is_valid = true;
        }
        return is_valid;
    }
    /*===========================================================================================
     Validate Email input
     =============================================================================================*/
    this.validateEmailField = function validateEmailField(value) {
        if(value){
            var input_pattern =/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})*$/i,
                is_valid = input_pattern.test(value);
        }
        else {
            is_valid = true;
        }
        return is_valid;
    }
    this.validateSelectField = function validateSelectField(value) {
        var is_valid = value.length > 0 && value !== 'prompt';
        return is_valid;
    }
    /*===========================================================================================
     Validate Time input
     =============================================================================================*/
    this.validateTimeField = function validateTimeField(value) {
        if(value){
            var input_pattern =/^[0-9]{0,2}(\.[0-9]{1,2})?$/,
                is_valid = input_pattern.test(value);
        }
        else {
            is_valid = true;
        }
        return is_valid;
    }
    /*===========================================================================================
     Validate Price input
     =============================================================================================*/
    this.validatePriceField = function validatePriceField(value) {
        if(value){
            var input_pattern =/^[0-9]{0,7}(\.[0-9]{0,4})?$/,
                is_valid = input_pattern.test(value);
        }
        else {
            is_valid = true;
        }
        return is_valid;
    }
    /*===========================================================================================
     Validate Markup input
     =============================================================================================*/
    this.validateMarkupField = function validateMarkupField(value) {
        if(value){
            var input_pattern =/^[0-9]{0,3}(\.[0-9]{1,2})?$/,
                is_valid = input_pattern.test(value);
        }
        else {
            is_valid = true;
        }
        return is_valid;
    }

    /*==========================================================================================
     Show error message and store the model_ref in invalid_input_refs (if not already in there)
     ==========================================================================================*/
    this.handleInvalidInput = function handleInvalidInput(input_type, model_ref, e, directive_scope) {
        var deferred = $q.defer();
        var validation_node = this.getErrorMessageNode(e, model_ref, input_type),
            store_model_ref = this.invalid_input_refs.indexOf(model_ref) < 0;

        if(store_model_ref) {
            this.invalid_input_refs.push(model_ref);
        }

        validation_node.classList.add('with-error');
        validation_node.querySelector('.control-error-message').classList.remove('dn');
        deferred.resolve();
        return deferred.promise;
    }
    this.handleInvalidInputCheck = function handleInvalidInputCheck(input_type, model_ref, e, directive_scope) {
        var deferred = $q.defer();
        var validation_node = this.getErrorMessageNode(e, model_ref, input_type),
            store_model_ref = this.invalid_input_refs.indexOf(model_ref) < 0;

        if (store_model_ref) {
            this.invalid_input_refs.push(model_ref);
        }
        deferred.resolve();
        return deferred.promise;
    }

    /*==========================================================================================
     Hide error message and remove the model_ref from invalid_input_refs
     ==========================================================================================*/
    this.handleValidInput = function handleValidInput(input_type, model_ref, e, directive_scope) {
        var deferred = $q.defer(),
            validation_node = this.getErrorMessageNode(e, model_ref, input_type),
            invalid_input_ref_pos = this.invalid_input_refs.indexOf(model_ref),
            remove_model_ref = this.invalid_input_refs[invalid_input_ref_pos] !== undefined ||
                this.invalid_input_refs[invalid_input_ref_pos] === model_ref;

        if(remove_model_ref) {
            this.invalid_input_refs.splice(invalid_input_ref_pos, 1);
        }

        validation_node.classList.remove('with-error');
        validation_node.querySelector('.control-error-message').classList.add('dn')
        deferred.resolve();
        return deferred.promise;
    }

    /*==========================================================================================
     Remove the model_ref of date input when date is selected in the dropdown
     ==========================================================================================*/
    this.removeDateInputModelFromInvalidInputRefs = function removeDateInputModelFromInvalidInputRefs(model_ref) {
        var invalid_input_ref_pos = this.invalid_input_refs.indexOf(model_ref),
            remove_model_ref = this.invalid_input_refs[invalid_input_ref_pos] !== undefined ||
                this.invalid_input_refs[invalid_input_ref_pos] === model_ref;

        if(remove_model_ref) {
            this.invalid_input_refs.splice(invalid_input_ref_pos, 1);
        }
    }

    /*==========================================================================================
     Remove addrs1 model_ref from invalid_input_refs (if present) when its automatically set
     in the postcode look up form
     ==========================================================================================*/
    this.handleAddressValidation = function handleAddressValidation() {
        var addrs1_pos = this.invalid_input_refs.indexOf('addrs1'),
            remove_addrs1_ref = this.invalid_input_refs[addrs1_pos] !== undefined ||
                this.invalid_input_refs[addrs1_pos] === 'addrs1';

        if(remove_addrs1_ref) {
            var validation_node = document.querySelectorAll('[ng-model="addrs1"]')[0].parentElement;

            this.invalid_input_refs.splice(addrs1_pos, 1);
            validation_node.classList.remove('with-error');
            this.notifyDirective();
        }
    }

    /*==========================================================================================
     In the postcode lookup the user pressed 'go back without saving' and the previous
     postcode was empty
     ==========================================================================================*/
    this.postcodeInputEmptied = function postcodeInputEmptied() {
        this.invalid_input_refs.push('postcode');
        this.notifyDirective();
    }

    /*==========================================================================================
     Tell directive re-check if form is valid
     ==========================================================================================*/
    this.notifyDirective = function notifyDirective() {
        $rootScope.$emit('panel_with_form:form_validity_changed');
    }

    /*==========================================================================================
     Get the '.required-block' element
     ==========================================================================================*/
    this.getErrorMessageNode = function getErrorMessageNode(e, model_ref, input_type) {
        var input,
            text_input = input_type === 'text',
            number_input = input_type === 'number',
            email_input = input_type ==='email',
            from_input = input_type ==='from',
            postcode_input = input_type === 'postcode',
            date_input = input_type === 'date',
            textarea_input = input_type === 'textarea',
            select_input = input_type === 'select',
            smart_filter_input = input_type === 'smart_filter',
            smart_filter_with_optgroup_input = input_type === 'smart_filter_with_optgroup',
            validation_node;

        if(select_input || smart_filter_input || smart_filter_with_optgroup_input) {
            input = document.querySelector("[ng-model=" + model_ref + "]");
        }else {
            input = e.target;
        }

        if(text_input || textarea_input || number_input || email_input || from_input) {
            validation_node = input.parentElement;
        }else if(postcode_input) {
            validation_node = input.parentElement.parentElement;
        }else if(date_input) {
            validation_node = input.parentElement.parentElement.parentElement.parentElement.parentElement;
        }else if(select_input || smart_filter_with_optgroup_input || smart_filter_input) {
            validation_node = input.parentElement.parentElement;
        }

        return validation_node;
    }

    /*==========================================================================================
     Assert whether the form has no errors
     ==========================================================================================*/
    this.isFormValid = function isFormValid() {
        var form_validity = this.invalid_input_refs.length < 1;
        return form_validity;
    }

    /*==========================================================================================
     Reset the invalid_input_refs, called when panel closes
     ==========================================================================================*/
    this.emptyInvalidInputRefs = function emptyInvalidInputRefs() {
        this.invalid_input_refs = [];
    }

    /*==========================================================================================
     Handle the server response
     ==========================================================================================*/
    this.handleFailedServerValidations = function handleFailedServerValidations(errors_obj) {
        for(var key in errors_obj) {
            if(errors_obj.hasOwnProperty(key)) {
                var invalid_input_elm = document.querySelector("[ng-model=" + key + "]"),
                    error_message = errors_obj[key];

                this.invalid_input_refs.push(key);
                this.handleServerFailedInput(invalid_input_elm, error_message);
            }
        }
    }

    /*==========================================================================================
     Get error message element so that it can be shown and the error message text can be
     updated with the server error message
     ==========================================================================================*/
    this.handleServerFailedInput = function handleServerFailedInput(input_elm, error) {
        this.server_side_validation_failed = true;

        var text_input = input_elm.parentElement.classList.contains('required-block'),
            validation_node;

        if(text_input) {
            validation_node = input_elm.parentElement;
        }else {
            var postcode_input = input_elm.parentElement.parentElement.classList.contains('required-block'),
                date_input = input_elm.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains('required-block');

            if(postcode_input) {
                validation_node = input_elm.parentElement.parentElement;
            }else if(date_input) {
                validation_node = input_elm.parentElement.parentElement.parentElement.parentElement.parentElement;
            }
        }

        this.showServerFailedError(validation_node, error);
    }

    /*==========================================================================================
     Show error message element displaying server error message
     ==========================================================================================*/
    this.showServerFailedError = function showServerFailedError(validation_node, error) {
        validation_node.classList.add('with-error');
        validation_node.querySelector('.control-error-message').childNodes[0].innerHTML = error;
        validation_node.querySelector('.control-error-message').classList.remove('dn');
    }

    /*==========================================================================================
     When input validation fails after its server side validation has already failed for it -
     reset the error mnessges with the clientside error message
     ==========================================================================================*/
    this.resetClientSideErrorMessages = function resetClientSideErrorMessages(input_elm, error_message, text_input, textarea_input, postcode_input, date_input, select_input, smart_filter_input, smart_filter_with_optgroup_input, number_input) {
        var validation_node;

        if(text_input || textarea_input || number_input) {
            validation_node = input_elm.parentElement;
        }else if(postcode_input) {
            validation_node = input_elm.parentElement.parentElement;
        }else if(date_input) {
            validation_node = input_elm.parentElement.parentElement.parentElement.parentElement.parentElement;
        }else if(select_input || smart_filter_with_optgroup_input) {
            validation_node = input_elm.parentElement;
        }else if(smart_filter_input) {
            validation_node = input_elm.parentElement.parentElement;
        }

        validation_node.querySelector('.control-error-message').childNodes[0].innerHTML = error_message;
    }

    this.getSelectedSmartFilterValue = function getSelectedSmartFilterValue(model_name) {
        return document.querySelector("[ng-model=" + model_name + "]").querySelector('.result-selected').innerHTML;
    }
});