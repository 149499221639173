commusoftCommon.directive('angularSubmit', ['$window', 'prefix', function($window, prefix) {
    return {
        restrict: 'A',
        replace: true,
        link: function(scope, element, attrs) {
            scope.actionVal = prefix  + element.attr('action');
            element.removeAttr('action');
            var formname = element.attr('name');
            element.on('submit', function() {
                if(scope[formname].$valid){
                    scope.saving = true;
                }else{
                    scope.saving = false;
                    return false;
                }
                scope.$apply(scope.formSubmit(element));
                return false;
            });
        }
    };
}]);