/*********************************************************
Outstanding jobs view
 *********************************************************/
function OutStandingJobViewCtrl($scope, $state, $stateParams, prefix, $http, tableCollection, datasets, reportNavSidebar, formPanelCollection, dateRange, toastBox, $rootScope, $translate, $timeout,confirmationBoxHelper, reportFilterSettings, $filter, $cookies, temporaryCookies, $compile) {
    $scope.initcall = datasets.initcall;
    $scope.datasets = datasets;
    $scope.sidePanelData = {};
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.category = 'jobs';
    $scope.subCategory = 'outstanding_jobs';
    $scope.filterDataInitialized = false;
    $scope.isSavedReport = false;
    $scope.originalType = null;
    $scope.selectedSubCategory = $scope.subCategory;
    $scope.deleteReport = false;
    $scope.hasTotalSummary = datasets.hasTotalSummary || false;
    $scope.number_of_rows_selected = 0;
    $scope.users = datasets.users;
    $rootScope.$on('smart_table:active_row_id', function (e, row_selected) {
        $scope.number_of_rows_selected = row_selected.length;
        $scope.sidePanelData['rows_selected'] = row_selected;
    });

    $scope.$on('$stateChangeStart', function( event, to, toParams, from, fromParams) {
        if (fromParams['category'] !== toParams['category'] || fromParams['subcategory'] !== toParams['subcategory']) {
            $scope.inputTags = [];
            reportFilterSettings.stateChange(from, fromParams);
        }
    });

    $scope.recordCount = 0;
    reportFilterSettings.availableFilters = [{'label': 'Date', 'value': 'date'}];
    //$scope.availableFilterOptions = {};
    $scope.tagFilterPlaceholder = $filter('translate')("Filter.Instructions");
    $scope.currentDateRangeFilterName = "daterange";
    $scope.filterSettings = reportFilterSettings;
    var type = 'reporting_' + $scope.category + '_' + $scope.subCategory;
    $scope.recordCount = datasets[type].count;
    $scope.sidepanelFilters = !!datasets.filter[type];
    // Filter configs, configurable from backend
    $scope.filtersConfig = {
        daterange: {
            dateRange: null,
            dropdownOptions: dateRange.getFilterOptions().map(function (op) { return op.string; }),
            customDateRangeView: 'default'
        }
    };
    // Filtering date filter options to show only the required date filters, which configured from backend. Default: shows all date filters
    $scope.dateFilterOptions = dateRange.getFilterOptions().filter(function (option) {
        return $scope.filtersConfig.daterange.dropdownOptions.indexOf(option['string']) >= 0;
    }).map(function (option) {
        switch (option.string) {
            case "Date Range": {
                if ($scope.filtersConfig.daterange.customDateRangeView)
                    option.view = $scope.filtersConfig.daterange.customDateRangeView;
                break;
            }
        }

        return option;
    });
    $scope.$on('reportFilterLoading', function(evt, report_name, newvalue) {
        if (report_name == 'standard_report_jobs_outstanding_jobs') {
            $scope.filterLoadingSpinner = newvalue;
        }
    });

    $scope.$on('smarttable:record-count', function(evt, count) {
        $rootScope.$broadcast('reportFilterLoading', 'standard_report_jobs_outstanding_jobs', false);
        $timeout(function() {
            $scope.filterLoadingSpinner = false;
        }, 0);
    });

    if (datasets['filter']) {
        if ($scope.operational) {
            if (datasets['filter'][$scope.category + '_' + $scope.subCategory]) {
                if (reportFilterSettings.availableFilters = datasets.filter[$scope.category + '_' + $scope.subCategory][$state.params.type]) {
                    reportFilterSettings.availableFilters = datasets.filter[$scope.category + '_' + $scope.subCategory][$state.params.type]['fields'];
                    reportFilterSettings.availableTabFilters = datasets.filter[$scope.category + '_' + $scope.subCategory];
                }
            }
        }
        else {
            if (datasets['filter']['reporting_' + $scope.category + '_' + $scope.subCategory]) {
                reportFilterSettings.availableFilters = datasets.filter['reporting_' + $scope.category + '_' + $scope.subCategory]['fields'];
            }
        }
    }

    // Translating filter labels
    angular.forEach(reportFilterSettings.availableFilters, function(filter, key) {
        reportFilterSettings.availableFilters[key]['label'] = $filter('translate')(filter.label);
    });

    var type = 'reporting_' + $scope.category + '_' + $scope.subCategory;

    $scope.doesInputTagEqual = function(value, comparison) {
        var found = false;
        angular.forEach($scope.inputTags, function(tag, index) {
            if (tag['model_name'].toLowerCase() == value.toLowerCase()) {
                if (tag['value'] == comparison) {
                    found = true;
                }
            }
        });
        return found;
    };

    $scope.getTagLabelFromValue = function(value) {
        var label = value;
        angular.forEach(reportFilterSettings.availableFilters, function(filter, index) {
            if (filter.model == value) {
                label = filter.label;
            }
        });

        var capitalized = label[0];
        label = capitalized.toUpperCase() + label.slice(1);

        return label;
    };

    $scope.getAvailableFilters = function() {
        var targets = [];
        var dependentFilters = [];
        var limitedFilters = [];
        /* Process checkboxes and determine which ones should be checked (or not checked) in the dropdown menu */
        angular.forEach(reportFilterSettings.availableFilters, function(aftag, afindex) {
            if (aftag['type'] == 'checkbox') {
                if (aftag['checked']) {
                    var foundFilter = false;

                    angular.forEach($scope.inputTags, function(tag, tagIndex) {
                        if (tag['model'] == aftag['model']) {
                            foundFilter = true;
                        }
                    });
                    if (!foundFilter) {
                        aftag['checked'] = false;
                    }
                }
            }
        });
        if ($scope.inputTags.length > 0) {
            /* Adding filters which rely on a filter that has already been added. For example, the 'Not Paid' checkbox filter depends on the 'Type' filter being active. If this condition is met, then the 'Not Paid' filter is added to the list of visibile filters. */
            angular.forEach($scope.inputTags, function(tag, tagIndex) {
                if (tag['dependentFilters'] && !tag['dependentFiltersAdded']) {
                    angular.forEach(tag['dependentFilters'], function(dftag, dfindex) {
                        var pushFilter = true;

                        /* Double check that this filter hasn't been added already. This could happen when a user selects some checkboxes, then deletes a filter which those checkboxes depend on, re-adds the same filter, then tries to re-select the checkboxes. */
                        angular.forEach(reportFilterSettings.availableFilters, function(aftag, afindex) {
                            if (aftag.model == dftag.model) {
                                aftag['checked'] = false;
                                pushFilter = false;
                            }
                        });
                        if (pushFilter) {
                            reportFilterSettings.availableFilters.push(dftag);
                        }
                    });

                    tag['dependentFiltersAdded'] = true;
                }
            });

            /* Preventing duplicate filters from appearing in the filter dropdown */
            angular.forEach(reportFilterSettings.availableFilters, function(filter, index) {
                var showFilter = true;
                angular.forEach($scope.inputTags, function(tag, tagIndex) {
                    if (tag['model'] == filter['model']) {
                        // If this is true, it means this is a duplicate
                        reportFilterSettings.availableFilters[index]['checked'] = $scope.inputTags[tagIndex]['checked'];
                        reportFilterSettings.availableFilters[index]['inputTagsIndex'] = tagIndex;
                        $scope.inputTags[tagIndex]['inputTagsIndex'] = tagIndex;

                        if (tag['type'] !== 'checkbox') {
                            showFilter = false;
                        }
                    }
                });

                /* Remove any filters that rely on another filter which hasn't been selected */
                if (filter['depends']) {
                    angular.forEach(filter['depends'], function(dependentarray, dependsonkey) {
                        angular.forEach(dependentarray, function(dependentarrayvalue, valueposition) {
                            var dav = dependentarrayvalue;
                            if (typeof dav == "string") {
                                dav = dav.toLowerCase();
                            }
                            filter['conditionFulfilled'] = false;
                            angular.forEach($scope.inputTags, function(dtag, dindex) {
                                if (dependsonkey.toLowerCase() == dtag.model.toLowerCase()) {
                                    dtagValue = dtag.value;
                                    if (typeof dtagValue == "string") {
                                        dtagValue = dtagValue.toLowerCase();
                                    }
                                    if (dav == dtagValue) {
                                        filter['conditionFulfilled'] = true;
                                    }
                                }
                            });
                            if (!filter['conditionFulfilled']) {
                                showFilter = false;
                            }
                        });
                    });

                }

                if (showFilter) {
                    filter['label'] = $filter('translate')(filter.label);
                    limitedFilters.push(filter);
                }
            });
        }
        else {
            limitedFilters = [];
            angular.forEach(reportFilterSettings.availableFilters, function(filter, index) {
                var showFilter = true;
                /* Remove any filters that rely on another filter which hasn't been selected */
                if (filter['depends']) {
                    angular.forEach(filter['depends'], function(dependsonvalue, dependsonkey) {
                        filter['conditionFulfilled'] = false;
                        angular.forEach($scope.inputTags, function(dtag, dindex) {
                            if (dependsonkey == dtag.model) {
                                filter['conditionFulfilled'] = true;
                            }
                        });
                        if (!filter['conditionFulfilled']) {
                            showFilter = false;
                        }
                    });

                }

                if (showFilter) {
                    filter['label'] = $filter('translate')(filter.label);
                    limitedFilters.push(filter);
                }
            });
        }
        return limitedFilters;
    };

    $scope.getFilterSettings = function() {
        if ($scope.filterSettings[$scope.category]) {
            if ($scope.filterSettings[$scope.category][$scope.subCategory]) {
                if ($scope.filterSettings[$scope.category][$scope.subCategory]['order']) {
                    return $scope.filterSettings[$scope.category][$scope.subCategory]['order'];
                }
            }
        }
        return [{'tags': true}, {'search': true}];
    };

    $scope.inputTags = [];
    $scope.settings = $scope.getFilterSettings();
    /*$scope.options = reportFilterSettings.availableFilterOptions;*/
    $scope.filterOrder = $scope.getFilterSettings();

    if ($scope.filterSettings[$scope.category]) {
        if ($scope.filterSettings[$scope.category][$scope.subCategory]) {
            var filterSettings = $scope.filterSettings[$scope.category][$scope.subCategory];
            if (filterSettings['order']) {
                angular.forEach(filterSettings['order'], function(settings, index) {
                    // Add daterange filter to the main filter array
                    if (filterSettings['daterange']) {
                        $scope.inputTags.push(filterSettings['daterange']);
                    }
                    // tags = filters
                    if (settings['tags']) {
                        $scope.inputTags = $scope.inputTags.concat(settings['tags']);
                    }
                });
            }
        }
    }

    var newTags = [];
    angular.forEach($scope.inputTags, function(tag, index) {
        if (tag !== "{}") {
            newTags.push(tag);
        }
    });
    reportFilterSettings.getAppliedFiltersWithMetadata({'category': $scope.category, 'subcategory': $scope.subCategory, 'filters': newTags, 'availableFilters': reportFilterSettings.availableFilters}).then(function(result) {
        $scope.inputTags = _.uniq(result,'model');
        reportFilterSettings.filtersFinishedLoading = true;
        $rootScope.$broadcast('reporting_filters:loaded', result);
    }, function(error) {
        $scope.inputTags = angular.copy(newTags);
        reportFilterSettings.filtersFinishedLoading = true;
        $rootScope.$broadcast('reporting_filters:loaded', newTags);
    });
    // Emit before assigning the  real subcategory from which this report was taken.
    $scope.$emit('reporting:viewchanged', {
        'category': $scope.category,
        'subcategory': $scope.subCategory
    });
    if (datasets[type].hasOwnProperty('from')) {

        $scope.isSavedReport = true;
        $scope.originalType = type;    // Save the original Type
        type = datasets[type]['from'];

        $scope.subCategory = type.substr(("reporting_" + $scope.category).length + 1);

        $scope.sidepanelFilters = !!datasets.filter['reporting_' + $scope.category + '_' + $scope.subCategory];
    }
    $scope.categoryTitle = reportNavSidebar.getTitle($scope.category, 'firstTier', datasets.savedQueries);
    if ($scope.operational) {
        $scope.secondTier = reportNavSidebar.getSecondTierDetails
        ($scope.isSavedReport? $state.params.subcategory: $scope.subCategory, 'parts_operational_list', datasets.savedQueries, $scope.isSavedReport);
    }
    else {
        $scope.secondTier = reportNavSidebar.getSecondTierDetails
        ($scope.category, $scope.isSavedReport? $state.params.subcategory: $scope.subCategory, datasets.savedQueries, $scope.isSavedReport);
    }

    $scope.showDateFilter = true;
    if($scope.secondTier && ($scope.secondTier.state == 'creditors' || $scope.secondTier.state == 'debtors')) {
        $scope.showDateFilter = false;
    }

    $scope.$on('tabCustomer:successMessage', function (event, data) {
        $rootScope.successMessage = data;
        toastBox.show($rootScope.successMessage, 5000);

    });

    $scope.$on('countActiveFilters',function (event, data){
        $scope.filterData = data;
        $scope.countActiveFilters();
    });

    $scope.needsActionMenu = false;
    $scope.tableCollection = tableCollection;
    tableCollection.summaryRowAdded = false;
    tableCollection.setData(type, datasets[type]);
    formPanelCollection.setFilterData(datasets.filter);

    formPanelCollection.setMoreFilterData('new_saved_report', {
        'fields' : [{
            'label': 'Enter report name',
            'type': 'text',
            'error_message' : 'Please enter name for report',
            'required': true,
            'model' : 'saved_query'
        }]
    });
    $scope.$on("dateRangeFilter", function(event, message) {
        if (message.startdate != undefined && message.startdate != "" &&
            message.enddate != undefined && message.enddate != "") {
            $scope.dateValue = {
                'startDate': message.startdate + ' 00:00:00',
                'endDate': message.enddate + ' 23:59:59'
            };
            $scope.selectedRange = message.string;
            $scope.startDate = message.startdate;
            $scope.endDate = message.enddate;
        } else {
            $scope.dateValue = undefined;
        }
        var filterData = {};

        var existingFilters = event.currentScope.filterData;
        angular.forEach(existingFilters, function(val, key) {
            if (key != 'daterange') {
                filterData[key] = val;
            }
        });

        if($scope.mode === 'filter-key-select' && $scope.inputTags.length > 0 && $scope.dateValue){
            filterData['daterange'] = $scope.dateValue;
        }
    });

    $scope.$on('fromsmarttable:filters', function(evt, data) {
        var extraData = [];
        extraData.push({
            'model_name': 'daterange',
            'value': $scope.dateValue
        });
        var storedEvent = evt;
        // The !$evt.targetScope.filterData is to make sure that the original filters arent applied when pagination happens
        // HACKY! but the whole sidepanel is!
        if (($scope.isSavedReport && !evt.targetScope.filterData) || $scope.filtersConfig) {
//            var filters = angular.copy($scope.filtersConfig);
            var filters = angular.copy($scope.inputTags);

            // Skipping the filter settings for the changed values
            if (evt.targetScope.filterData) angular.forEach(angular.fromJson(evt.targetScope.filterData), function (val, key) {
                if (filters[key]) delete filters[key];
            });
            var hasDateRangePreFilter = null;
            if(!$scope.isSavedReport)
                hasDateRangePreFilter = !!filters.daterange && filters.daterange.dateRange;

            if ($scope.secondTier && $scope.secondTier.filters) Object.assign(filters, $scope.secondTier.filters);

            angular.forEach(filters, function (val, key) {

                if (key == 'daterange') {
                    var selectedDateRange = _.filter($scope.dateFilterOptions, function (elem) {
                        return elem.string == val.dateRange;
                    });
                    if (selectedDateRange.length>0) {
                        if (selectedDateRange[0].string == 'Date Range') {
                            selectedDateRange[0].startdate = val.startDate;
                            selectedDateRange[0].enddate = val.endDate;
                        }
                        val = selectedDateRange[0]; // Reset the value so that it works properly for date-filters
                        var dateRegx = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');

                        val.startDate = dateRegx.test(val.startdate)? val.startdate + ' 00:00:00': val.startdate; // Backend needs it case-sensitive
                        val.endDate = dateRegx.test(val.enddate)? val.enddate + ' 23:59:59': val.enddate;

                        if (!hasDateRangePreFilter) {
                            $timeout(function () {
                                $scope.$emit('daterange:changed', selectedDateRange[0]);
                                $scope.$broadcast('daterange:changed', selectedDateRange[0]);
                            }, 600);
                        }
                        else {
                            $scope.selectedRange = val.string;
                            $scope.startDate = moment(val.startDate).format('YYYY-MM-DD');
                            $scope.endDate = moment(val.endDate).format('YYYY-MM-DD');

                            $scope.dateValue = {
                                'startDate': val.startDate,
                                'endDate': val.endDate
                            };
                        }
                    }

                    if(!val.startDate || !val.endDate) val = null;
                }

                if(val) extraData.push({
                    'model_name': key,
                    'value': val
                })
            });
        } else {
            angular.forEach(angular.fromJson(evt.targetScope.filterData), function (val, key) {
                extraData.push({
                    'model_name' : key,
                    'value': val
                })
            });
        }
        $scope.$broadcast('tosidepanel:filtervalues', extraData);
    });


    $scope.dateRangeClose = function(e) {
        var input = $(e.target).parents('.daterange_filter');
        var date_range_widget = $(e.target).parents('.daterange-container');
        if (input.length > 0 || date_range_widget.length > 0 || e.target.className == 'ng-binding muted') {
            e.preventDefault();
        } else {
            $scope.$broadcast('body:clicked', '');
        }
    }
    $scope.sidePanelData = {'reportTypeCategory': $scope.category,
        'reportTypeSubCategory': $scope.subCategory,
        'users': $scope.users,
        'records': datasets[type].data
    };
    $rootScope.$on('smart_table:jobs_outstanding_jobs_rows', function(evt, data) {
        if(data.type == 'append') {
            $scope.sidePanelData['records'] = $scope.sidePanelData['records'].concat(data.rows);
        }
        else{
            $scope.sidePanelData['records'] = data.rows;
        }
    });
    var off = $rootScope.$on('panelwithform:form_save_data', function(evt, data) {
        var savedQueryId = data.data.id;

        var filtersToSend = reportFilterSettings.getAppliedFilters({'category': $scope.category, 'subcategory': $scope.subCategory, 'filters': $scope.filterData, 'availableFilters': reportFilterSettings.availableFilters});

        var postData = {
            'id': savedQueryId,
            'filters': filtersToSend,
            'columns': $scope.tableCollection.getOrderedColumns()
        };

        $http.post(prefix + '/add_saved_report_filters', "data=" + encodeURIComponent(angular.toJson(postData)))
            .success(function(res) {
                toastBox.show('Query saved');
                $scope.$emit('reporting:refreshentries', {});
            })
            .error(function(res) {
                toastBox.show('Query save failed');
            });
    });
    $scope.$on('$destroy', off);
    $scope.filterData = {};
    $scope.show_clear_filter_link = false;
    $scope.number_of_filters = 0;

    $scope.countActiveFilters = function() {
        $scope.show_clear_filter_link = false;
        $scope.number_of_filters = 0;

        for (var key in $scope.filterData) {
            var value = $scope.filterData[key],
                value_exists = value &&
                    value !== "all" &&
                    value.toString().length > 0

            if (value_exists) {
                $scope.show_clear_filter_link = true;
                $scope.number_of_filters ++;
            }
        }
    }

    $scope.clearFilters = function() {
        $scope.filterData = {};
        $scope.dateValue = undefined;
        $scope.secondTier.filters = {};

        $scope.$emit('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.$broadcast('event:reset_date_filter');
        $scope.countActiveFilters();
    }

    $scope.clearOperationalFilters = function(){
        $scope.$broadcast('clearOpertionalFilters', true);
    }
    $scope.$on('smarttable:record-count', function(evt, value) {
        $scope.recordCount = value;
    });
    $scope.completeFunc = function(){
        $('#loading-indicator').hide();
        $scope.exportReportProps.exportIsProcessing = false;
        $scope.exportReportProps.exportHasFinished = true;
    }
    $scope.$on('fromsidepanel:inited', function(evt, data) {
        if ($scope.originalType && !$scope.filterDataInitialized) {
            if ($scope.secondTier.filters) {
                angular.forEach($scope.secondTier.filters, function (val, key) {
                    evt.targetScope[key] = val;
                });
                evt.targetScope.storeFilterModelValues();
            }
            $scope.filterDataInitialized = true;
        }
    });
    $scope.$on('panel_with_form:filter:reporting_' + $scope.category + '_' + $scope.subCategory, function(evt, data, extraData) {
        $scope.filterData = {};
        var _extraData = _.isUndefined(extraData) ? {} : extraData;

        $scope.filterData = reportFilterSettings.simplifyFilters(data);

        $scope.filterData = reportFilterSettings.getAppliedFilters({'category': $scope.category, 'subcategory': $scope.subCategory, 'filters': $scope.filterData, 'availableFilters': reportFilterSettings.availableFilters});
        /* Events fired after fetching new filtered results */
        if($scope.initcall!==true) {
            $rootScope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData), _extraData);
        }
        $scope.initcall=false;
        $scope.countActiveFilters();
    });

    $scope.openSidePanel  = function () {
        var side_panel_tpl = $compile('<span id="bulk_scheduling" ' +
            'sidepanel template="bulk_scheduling" ' +
            'title="view" ' +
            'remove_on_close="true" ' +
            'index="' + this.$index + '"' +
            'collection=""></span>')($scope);

        angular.element('body').append(side_panel_tpl);
    }
    var sidepanel_close = $rootScope.$on("schedule:sidepanel:close",function (e, value) {
        $state.transitionTo($state.current, $stateParams, {
            reload: true, inherit: false, notify: true
        });
    })
    $scope.$on("$destroy", sidepanel_close);
}

var OutStandingJobViewResolver = {
    datasets: function ($q, $http, $stateParams, $state, $rootScope, $timeout, PubNub, reportFilterSettings, $cookies, $location, reportNavSidebar, cacheBust, prefix, adminPrefix) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var inputTags = {};

        var deferred = $q.defer();
        var savedFiltersFound = false;
        var that = this;

        reportNavSidebar.getReportingSubnavItems().then(function(subnavItems) {
            reportFilterSettings.savedFilters = false;

            angular.forEach(subnavItems, function(category, index) {
                if (category.id == $stateParams.category) {
                    angular.forEach(category.secondTier, function(secondTierItem, index) {
                        if (secondTierItem.id == $stateParams.subcategory) {
                            if (secondTierItem['custom']) {
                                savedFiltersFound = true;
                                //inputTags = reportFilterSettings.simplifyFilters(secondTierItem.filters);
                                reportFilterSettings.addSavedFilters(secondTierItem.filters).then(function(resp) {
                                    return resp;
                                }, function(error) {
                                    throw error;
                                });
                            }
                        }
                    });
                }
            });
            inputTags = reportFilterSettings.getAppliedFilters({'category': $stateParams.category, 'subcategory': $stateParams.subcategory});
            if (Object.keys(inputTags).length == 0) {
                inputTags = null;
            }
            var URLtofetch = that.templateUrl($stateParams) + '?page=1&limit=20';
            $http.post(URLtofetch, 'filter=' + encodeURIComponent(angular.toJson(inputTags)))
                .success(function (data, status, headersObj) {
                    data.initcall=true;
                    if (savedFiltersFound) {
                        // If it's a saved report, try to retrieve the list of available dropdown filters from its parent report.
                        // For example, if this is a saved custom report based off of the Sales report, then retrieve the list of available filters from the Sales report.
                        var parentReportName = data['reporting_' + $stateParams.category + '_' + $stateParams.subcategory]['from'];
                        if (parentReportName) {
                            data['filter']['reporting_' + $stateParams.category + '_' + $stateParams.subcategory] = angular.copy(data['filter'][parentReportName]);
                        }
                    }

                    if (data.location && data.location == 'logout') {
                        // Handle chat unregistration here as below the rootScope will be nulled
                        $rootScope.$broadcast('chat:unregister');
                    }

                    var headers = headersObj();
                    SetRootScopeValues($rootScope, headers, PubNub, cacheBust, $http, prefix, adminPrefix, $state);

                    if (data.location) {
                        deferred.resolve("");
                        var slashCount = (data.location.match(/\//g) || []).length;
                        //Special case for Login: TODO: Fix this better
                        if(slashCount > 1 && !data.location.endsWith("/")) {
                            if(!$rootScope.stateToRedirectFromLogin) {
                                $rootScope.stateToRedirectFromLogin = 'home';
                                $rootScope.paramsToRedirectFromLogin = {};
                            }
                            $state.transitionTo($rootScope.stateToRedirectFromLogin, $rootScope.paramsToRedirectFromLogin,{reload:true});
                        } else if (data.location.endsWith("/")) {
                            //$state.transitionTo('home');
                        } else if (data.location.startsWith("https://") || data.location.startsWith("http://")) {
                            window.location.href = data.location;
                        } else if (data.location == 'logout') {
                            $state.transitionTo('home');
                        } else if (data.location == '/companydetails') {
                            var data = data.location.replace(/\//g, '');
                            $state.transitionTo('setupwizard.' + data);
                        } else if (data.location == '/payment') {
                            var data = data.location.replace(/\//g, '');
                            $state.transitionTo('contract.' + data);
                        } else {
                            var data = data.location.replace(/\//g, '');
                            var additionalOptions = {};
                            if($state.current.name == data) {
                                additionalOptions = {reload: true};
                            }
                            $state.transitionTo('loggedin.' + data, {}, additionalOptions);
                        }
                    } else {
                        deferred.resolve(data);
                    }
                });
        });
        return deferred.promise;
    }
};
/*********************************************************
 MassScheduling Side Panels
 *********************************************************/
function MassSchedulingCtrl($scope, $state, $stateParams, $rootScope, $http, $timeout, prefix, toastBox, $filter, CS_LANGUAGE)
{
    $scope.loading = false;
    let language = CS_LANGUAGE;
    if(language == 'en'){
        language = 'en-GB';
    }
    moment.locale(language);
    var local_date_format = moment.localeData(language).longDateFormat('L');
    $scope.active_slide = 1;
    $scope.show_daterange = false;
    $scope.date_type = 'specific';
    $scope.start_date = moment();
    $scope.schedule_date = moment();
    $scope.minDate = moment();
    $scope.end_date = moment($scope.start_date, "DD-MM-YYYY").add(1, 'days');
    $scope.show_range_picker = false;
    $scope.date_range_string = $scope.start_date.format('L') + ' to ' + $scope.end_date.format('L');
    $scope.outstanding_jobs_ctrl_scope = $scope.$parent.$parent;
    $scope.panel_title = 'Mass scheduling';
    $scope.section_to_show = 'form';
    $scope.closePanel = function closePanel(refresh_state) {
        $scope.active_slide = 2;
        $scope.loading = false;
        $rootScope.$broadcast('closeAllSidepanels');
        if(refresh_state){
            $scope.outstanding_jobs_ctrl_scope.inputTags = [];
            $state.transitionTo($state.current, $stateParams, {
                reload: true, inherit: false, notify: true
            });
        }
        $timeout(function(){
            var elem = $('#bulk_scheduling');
            if(elem.length) {
                var sidepanel$ = $('body > #side-panel');
                if(sidepanel$.attr('ng-controller') == 'MassSchedulingCtrl'){
                    sidepanel$.remove();
                }
                elem.remove();
            }
        },300);
    }

    $rootScope.$on('parent:updated', function(evt) {
        $scope.active_slide = 1;
        $scope.loading = false;
    });

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });
    $rootScope.$on("event:date_range_picker", function (event, message) {
        if(message.apply) {
            $scope.start_date = moment(message.start_date);
            $scope.end_date = moment(message.end_date);
            $scope.date_range_string = $scope.start_date.format('L') + ' to ' + $scope.end_date.format('L');
        }
        $scope.show_range_picker = false;
    });
    $scope.openPicker = function() {
        $scope.show_range_picker = true;
    }
    $scope.panelData = $scope.outstanding_jobs_ctrl_scope.sidePanelData;
    $scope.number_job_selected = $scope.panelData['rows_selected'].length;
    /**
     * select2 related options
     */
    $scope.users = JSON.parse(JSON.stringify($scope.panelData['users']));
    angular.forEach($scope.users,function (value) {
        value['text'] = value.name;
    });
    $scope.users.unshift({'id':-1, 'text': 'All'});
    $scope.select2Options = {
        placeholder: "Select some users",
        'multiple': true,
        id: function (obj) {
            return obj.id;
        },
        'data': $scope.users,
        formatResult: function (item) {
            return item.text;
        },
        formatSelection: function (item) {
            return item.text;
        },
        formatNoMatches: function (item) {
            if (item != '') {
                return 'No results match "' + item + '"';
            } else {
                return "";
            }
        },
        onSelect: function(data, options) {
            $scope.onSelected(data)
        },
        opened: function(data, options) {
            console.log('selected', data)
        }
    };
    /**
     * set the default values to selected users
     */
    $scope.selected_users = [];
    /**
     * function to handle process schedule
     * @param date_type
     */
    $scope.processSchedule = function (date_type) {
        $scope.loading = true;

        var userSelected;
        if($scope.selected_users.length ==1 && $scope.selected_users[0]['id'] == -1 ) {
            userSelected = $scope.users;
            userSelected.splice(0,1)
        }
        else {
            userSelected = $scope.selected_users;
        }
        userSelected = userSelected.map(function(user) {
            return user.id;
        });

        var start = $scope.start_date;
        var selected_rows = $scope.panelData['rows_selected'];
        var job_ids = $scope.panelData['records'].filter(job=>{
           if(selected_rows.includes(job.id) && job.type == 'job'){
               return true;
           }
           else{
               return false;
           }
        });
        job_ids = job_ids.map(function(job) {
            return job.id;
        });
        var estimate_ids = $scope.panelData['records'].filter(job=>{
            if(selected_rows.includes(job.id) && job.type == 'estimate'){
                return true;
            }
            else{
                return false;
            }
        });
        estimate_ids = estimate_ids.map(function(estimate) {
            return estimate.id;
        });
        var accept_end_date = true;
        if(date_type == 'specific') {
            start = moment($scope.schedule_date,local_date_format);
            accept_end_date = false;
        }
        var data = {
            'users':userSelected.join(','),
            'estimate_ids': estimate_ids,
            'job_ids': job_ids,
            'accept_end_date': accept_end_date,
            'start': start.format('YYYY-MM-DD'),
            'end': $scope.end_date.format('YYYY-MM-DD')
        };
        $http.post(Routing.generate("bulk_scheduling"),'data='+encodeURIComponent(JSON.stringify(data)))
            .success(function (data) {
                $scope.loading = false;
                if(data.status){
                    toastBox.show('You will be notified once the schedule has been generated', 4000);
                    $scope.closePanel(true);
                }
                else {
                    toastBox.show(data.message, 4000);
                    $scope.closePanel(true);
                }
            })
            .catch(function (e) {
                $scope.loading = false;
                console.log(e)
            });
    }
    $scope.openPanel = function openPanel() {
        $timeout(function() {
            $scope.initialise();
        }, 0);
    }

    /**
     * select related changes
     */
    $scope.load_first = false;
    $scope.onUserChanged = function () {
        if($scope.load_first){
            var old_index= _.findIndex($scope.clone_selected_users, { id: -1 });
            var new_index= _.findIndex($scope.selected_users, { id: -1 });
            if($scope.selected_users.length > 1 && old_index != -1){
                $scope.selected_users.splice(new_index,1);
            }
            else if(new_index != -1) {
                $scope.selected_users = $scope.selected_users.splice(new_index,1);
            }
            $scope.clone_selected_users = $scope.selected_users;
        }
        else {
            $scope.selected_users.push({'id':-1, 'text': 'All'});
            $scope.clone_selected_users = $scope.selected_users;
            $scope.load_first = true;
        }
    }
}

csmodule.controller('MassSchedulingCtrl',MassSchedulingCtrl);

/*********************************************************
 Scheduling Results
 *********************************************************/
function ScheduleViewCtrl($scope, datasets, $state, $stateParams, $rootScope, $http, prefix, $timeout, csPopUpPosition, colorSelector, toastBox,$csMapper, $diaryMapScope, $jobEstimateMapMarkerScope, $compile)
{
    $scope.schedule_data = datasets.data;
    $scope.waring_data = [{
        'message': 'Some of the job/estimates could not be scheduled.',
        'type': 'warning'
    }]
    $scope.drawnMarkers = [];
    $scope.mapNavigation = [];
    $scope.show_info_bar = true;
    $scope.show_no_results_section = false;
    var csMapper = $csMapper.get();
    $scope.initMassSchedulingMap = function() {
        setTimeout(function() {
            $scope.mapInitialised = true;
            csMapper.initialize('mass_scheduling', 'vector', false);
            csMapper.addOverlay('Jobs');
            csMapper.addOverlay('Users');
            csMapper.addOverlay('Estimates');
            $scope.get_active_records();
        },2000)
    };
    $scope.scheulde_results = $scope.schedule_data['result'];
    $rootScope.hasTotalSummary = false;
    $scope.initMassSchedulingMap();
    $scope.results = $scope.schedule_data.result;
    $scope.show_invalid_section = false;
    $scope.active_master_index = 0;
    $scope.active_index = 0;
    var diaryColors = colorSelector.getColors('diary_color');
    $scope.keys = Object.keys;
    $scope.error_data = '';
    $scope.change_active_index = function(master_index, index){
        $scope.showPropertiesMarkerPopup = false;
        $scope.active_master_index = master_index;
        $scope.active_index = index;
        $scope.get_active_records();
    }
    /**
     * Function to handle remove existing navigations
     */
    function removeExistingNavigation() {
        if ($scope.mapNavigation.length) {
            _.each($scope.mapNavigation, function(value) {
                if (value) {
                    csMapper.hideNavigation('Jobs', value, true);
                }
            });
            $scope.mapNavigation = [];
        }
    };
    function removeExistingMarkers() {
        if ($scope.drawnMarkers.length) {
            _.each($scope.drawnMarkers, function(value) {
                if (value) {
                    var marker = value.split('_');
                    csMapper.deleteMarker(marker[0], marker[1]);
                }
            });
            $scope.drawnMarkers = [];
        }
    };
    $scope.getPopUpData = function getPopUpData($event, eventId) {
        var keys = eventId.split('_')
        $scope.properites_data = $scope.active_map_data.engineer_appointment.filter(function(data){return data.id == eventId})[0]
        csPopUpPosition.init($event);
        $timeout(function () {
            $scope.showPropertiesMarkerPopup = true;
        });
    }

    // Pointing Jobs & Estimates on map
    function drawJobEstimateMarkers(appointments, userColor) {
        // Rendering event markers
        var color = diaryColors.find(function (c) {
            return c.color == userColor;
        });

        if (!color) color = '#3A6889';
        else color = color['rgba_dark'];
        var order_iteration = 1;
        draw_user_marker(color);
        appointments.forEach(function (t,i) {
            if(t.latitude != null) {
                t['order_string'] = order_iteration;
                var markerData = {
                    event: t,
                    layerName: '',
                    html: '',
                    details: {
                        latitude: t.latitude,
                        longitude: t.longitude,
                        markerIconClassName: '',
                        markerSuffix: 'event' + t.job_estimate_id + '#' + i
                    },
                    csColour: color

                };
                switch (t.type) {
                    case 'estimate':
                        markerData.layerName = 'Estimates';
                        markerData.html = $diaryMapScope.mapElementTemplates['scheduling_job_estimate_marker'];
                        markerData.details.markerIconClassName = 'leaflet-estimate-marker-icon ';
                        order_iteration ++;
                        break;
                    case 'job':
                        markerData.layerName = 'Jobs';
                        markerData.html = $diaryMapScope.mapElementTemplates['scheduling_job_estimate_marker'];
                        markerData.details.markerIconClassName = 'leaflet-job-marker-icon ';
                        order_iteration ++;
                        break;
                    default:
                        return;
                        break;
                }
                $scope.drawnMarkers.push(draw(markerData));
            }

        });

        function draw(d) {
            // Drawing marker
            if (d.layerName !== '') {
                var markerScope = $jobEstimateMapMarkerScope.new($scope, {
                    diaryEvent: d.event,
                    csColour: d.csColour
                });
                var markerName = csMapper.addDivMarker(d.layerName, d.details, markerScope, d.html, null, [17, 41]);

                return markerName;
            }

            return null;
        }
    }
    function draw_user_marker(userColor){
        var user_details = $scope.active_map_data;
        var details = {
            latitude: user_details.lat,
            longitude: user_details.lng,
            markerSuffix: user_details.engineer_id
        };
        var markerName = csMapper.addPointingMarker('Users','JOB', details, null, null, null, [28, 48], function() {});
        $scope.drawnMarkers.push(markerName)
    }
    $scope.$on('dmJobEstimateMarker:eventEnter', function (event, data) {
        $scope.getPopUpData(data[0], data[1]);
    });

    $scope.$on('dmJobEstimateMarker:eventLeave', function (event, data) {
        $scope.showPropertiesMarkerPopup = false;
    });
    $scope.handleMapProcess = function (map_data) {
        if (map_data) {
            removeExistingMarkers();
            removeExistingNavigation();
            drawJobEstimateMarkers(map_data['engineer_appointment'], map_data.colour);
            let colorDetails = _.where(diaryColors, { color: map_data.colour });
            let polylineColor = 'rgb(90, 155, 216)';
            if (colorDetails.length) {
                polylineColor = colorDetails[0]['rgba_dark']
            }
            var typeStr = 'Jobs' + map_data.engineer_id;
            csMapper.addNavigation('Jobs', 'navigation_' + typeStr, swapElements(map_data.polyline), polylineColor, map_data, null);
            let checkExist = $scope.mapNavigation.indexOf('navigation_' + typeStr);
            if (checkExist === -1) {
                $scope.mapNavigation.push('navigation_' + typeStr);
            }
            csMapper.adjustMapBounds();
        }
    }
    $scope.$emit('is_schedule_view_watch', true);
    $scope.get_active_records = function(){
        if($scope.results && Object.keys($scope.results).length > 0) {
            var top_level_keys = Object.keys($scope.results);
            var active_object_key = top_level_keys[$scope.active_master_index];
            $scope.active_map_data  = $scope.results[active_object_key][$scope.active_index];
            if(!$scope.active_map_data){
                change_active_mater_key();
            }
            $scope.handleMapProcess($scope.active_map_data);
        }
        else {
            $scope.$apply(function(){
                $scope.show_no_results_section = true;
            });
        }
    }
    function change_active_mater_key() {
        var active_master_index = $scope.active_master_index;
        var top_level_keys = Object.keys($scope.results);
        var data;
        while (active_master_index < top_level_keys.length) {
            active_master_index ++ ;
            var active_object_key = top_level_keys[active_master_index];
            if ($scope.results[active_object_key] && $scope.results[active_object_key].length > 0) {
                data = $scope.results[active_object_key][$scope.active_index];
                break;
            }
        }
        $scope.active_master_index = active_master_index;
        $scope.active_map_data = data;
        if(!$scope.active_map_data){
            $scope.$apply(function(){
                $scope.show_no_results_section = true;
            });
        }
    }

    /**
     * Function to handle swap list array element
     * @param mapping
     * @returns {Array}
     */
    function swapElements(mapping) {
        let swapped_array = [];
        mapping.forEach(function(inner_array) {
            swapped_array.push([inner_array[1], inner_array[0]]);
        });
        return swapped_array;
    }
    /**
     * schedule results process action in backend
     * @param type_of_action
     */
    $scope.scheduleResultProcess = function () {
        $scope.data_loaded = true;
        $http.post(Routing.generate("schedule_results_process", { resultId: $stateParams.resultId}))
            .success(function (response, status) {
                $scope.data_loaded = false;
                if(!response['result_valid']){ // open side panel show the details
                    $scope.error_data = response;
                    $scope.openSidePanel();
                }
                else{
                    $scope.schedule_data.status = response['status'];
                    toastBox.show('Results are valid and events created based on schedule results', 4000);
                }
            })
            .catch(function (e) {
                console.log(e);
                $scope.data_loaded = false;
            });
    };
    $scope.openSidePanel = function (){
        var side_panel_tpl = $compile('<span id="mass_scheduling_reprocess" ' +
            'sidepanel template="mass_scheduling_reprocess" ' +
            'title="view" ' +
            'remove_on_close="true" ' +
            'index="' + this.$index + '"' +
            'collection=""></span>')($scope);

        angular.element('body').append(side_panel_tpl);
    }
    $scope.openInfoSidePanel = function () {
        //$scope.show_info_bar = false;
        var side_panel_tpl = $compile('<span id="mass_scheduling_info" ' +
            'sidepanel template="mass_scheduling_info" ' +
            'title="view" ' +
            'remove_on_close="true" ' +
            'index="' + this.$index + '"' +
            'collection=""></span>')($scope);
        angular.element('body').append(side_panel_tpl);
    }
    $rootScope.$on('informationbar:mass_scheduling', function(evt) {
        $scope.openInfoSidePanel();
    });}

/*********************************************************
 Mass Scheduling reprocess Side Panels
 *********************************************************/
function BulkSchedulingReProcess($scope, $state, $stateParams, $rootScope, $http, $timeout, prefix, toastBox, $filter)
{
    $scope.loading = false;
    $scope.scheduling_result_scope = $scope.$parent.$parent;
    $scope.show_user_diff = 0;
    $scope.user_diff_events = $scope.scheduling_result_scope.error_data.user_diff_events;
    if($scope.user_diff_events) {
        $scope.show_user_diff = Object.keys($scope.user_diff_events).length;
    }
    $scope.panel_title = 'Schedule results';
    $scope.section_to_show = 'form';
    $scope.error_msg = $scope.scheduling_result_scope.error_data.error;
    $scope.closePanel = function closePanel() {
        $scope.active_slide = 1;
        $scope.loading = false;
        $rootScope.$broadcast('closeAllSidepanels');
    }

    $rootScope.$on('parent:updated', function(evt) {
        $scope.active_slide = 1;
        $scope.loading = false;
        $scope.closePanel();
    });
    $scope.openPanel = function openPanel() {
        $timeout(function() {
            $scope.initialise();
        }, 0);
    };
    $scope.reProcessSchedule = function () {
        $scope.loading = true;
        var data = {
            'error_data':$scope.scheduling_result_scope.error_data,
            'results': $scope.scheduling_result_scope.schedule_data
        };
        $http.post(Routing.generate("re_scheduling"),'data='+encodeURIComponent(JSON.stringify(data)))
            .success(function (data) {
                $scope.loading = false;
                if(data.status) {
                    toastBox.show('You will be notified once the schedule has been generated', 4000);
                    $scope.scheduling_result_scope.schedule_data.status = 'Completed';
                    $scope.closePanel()
                }
                else{
                    toastBox.show(data.message, 4000);
                    $scope.closePanel();
                }
            })
            .catch(function (e) {
                $scope.loading = false;
                console.log(e)
            });
    }
}

csmodule.controller('BulkSchedulingReProcess', BulkSchedulingReProcess);
/*********************************************************
 Mass Scheduling Info Side Panels
 *********************************************************/
function MassSchedulingInfo($scope, $state, $stateParams, $rootScope, $http, $timeout, prefix, toastBox, $filter)
{
    $scope.loading = false;
    $scope.scheduling_result_scope = $scope.$parent.$parent;
    $scope.panel_title = 'Scheduling feedback';
    $scope.section_to_show = 'form';
    $scope.error_info = $scope.scheduling_result_scope.schedule_data.input.errors;
    $scope.show_user_geocode_error = _.where( $scope.error_info, { type: 'user' });
    $scope.show_job_estimate_geocode_error = _.where( $scope.error_info, { type: 'job_estimate' });
    $scope.show_unable_to_scheudle_error = _.where( $scope.error_info, { type: 'failed_to_schedule' });
    $scope.show_skill_dropped_error = _.where($scope.error_info, { type: 'skill_dropped'});
    $scope.show_or_tool_error = _.where($scope.error_info, { type: 'or_tool'});
    $scope.failed_to_get_distance_from_google = _.where($scope.error_info, { type: 'failed_to_get_distance_from_google'})
    $scope.user_shift_error = _.where($scope.error_info, { type: 'user_shift'});

    $rootScope.$on('parent:updated', function(evt) {
        $scope.active_slide = 1;
        $scope.loading = false;
        $scope.closeSidePanel();
    });
    $scope.openPanel = function openPanel() {
        $timeout(function() {
            $scope.initialise();
        }, 0);
    };
    $scope.redirectToJobAndEstimate = function ($event, job) {
        $rootScope.$broadcast('closeAllSidepanels');
        $rootScope.$emit('mass_scheduling_info:sidepanel:close',null);
        var type = job.details.eventType;
        if(type == 'estimate') {
            $state.transitionTo('loggedin.customer_list.view.estimate_details.survey', { id: job.details.propertyId, estimateId: job.details.estimate_id, type: job.details.propertyType});
        } else if(type == 'project_estimate') {
            $state.transitionTo('loggedin.customer_list.view.project_estimate_details.survey', { id: job.details.propertyId, estimateId: job.details.estimate_id});
        } else if(type == 'job' || type == 'additional_work') {
            $state.transitionTo('loggedin.customer_list.view.job.details', { id: job.details.propertyId, jobId: job.details.job_id, type: job.details.propertyType});
        }
    };
    $scope.redirectToUsers = function ($event, user) {
        $rootScope.$broadcast('closeAllSidepanels');
        $rootScope.$emit('mass_scheduling_info:sidepanel:close',null);
        $state.transitionTo('loggedin.users.view.details', { id: user.id});

    };
    $scope.handleSticky = function(time) {
        $scope.showSticky = true;
        $timeout(function () {
            $scope.closeSticky();
        }, time);
    };
    $scope.closeSidePanel = function () {
        $rootScope.$broadcast('closeAllSidepanels');
    };

    $scope.closeSticky = function() {
        $scope.showRevert = false;
        $scope.showSticky = false;
    };
}

csmodule.controller('MassSchedulingInfo', MassSchedulingInfo);
