csmodule.directive('dragNDropOverlays', ['prefix', '$http', '$timeout', function(prefix, $http, $timeout, dragNDropService) {
    return {
        restrict: 'ACE',
//            templateUrl: 'template/drag_n_drop_overlays.html',
        link: function($scope, elem, attrs) {
            var callback = attrs.uploadCallback.split('(')[0];
            var args = attrs.uploadCallback.split('(')[1].split(')')[0].split(',');

            $scope.groupId = attrs['dragndropGroup']; 

            angular.forEach(args, function(value, index) {
                args[index] = $scope[value];
            });
            $scope.dragNDropUploadCallback = callback;
            $scope.dragNDropUploadCallbackArgs = args;

            $scope.expandingIn = false;
            $scope.mouseoverTimeout = false;
            $scope.openCount = 0;
            $scope.mouseleaveTimeout = false;
            $scope.expandedIntoViewTimeout = false;
            $scope.completedUpload = false;
            $scope.invalidFiles = [];

            $scope.getCompletedUploadStatus = function() {
                return $scope.completedUpload;
            }

            $scope.dismissDialog = function() {
                $scope.uploadError = false;
                $scope.invalidFiles = [];
                $scope.closeUploadPrompt();
                $scope.removeBackground();

                $scope.getReadyForNextUpload();
            }

            $scope.checkDragNDropVisibility = function() {
                var visible = ($scope.fileAboutToBeDropped || $scope.uploadError || $scope.fadeInUploadingFiles || $scope.completedUpload || $scope.uploadInProgress || $scope.fakeUploadInProgress);
                if (visible) {
                    return true;
                }

                return false;
            }

            $scope.dismissUploadInProgress = function(validFiles) {
                $scope.uploadError = false;
                $scope.uploadInProgress = false;
                $scope.fakeUploadInProgress = false;
                $scope.completedUpload = true;
                $scope.fadeInUploadingFiles = false;

                $scope.expandingIn = false;
                $scope.uploadProgressStyle = {};
                $scope.filesToBeUploaded = [];
                $scope.progressBar = 0;
                $scope.uploadPercentage = '0%';
                $scope.uploadProgressStyle.width = '0%';

                $scope.closeUploadPrompt();
                $scope.removeBackground();

                $scope.getReadyForNextUpload();

                if (validFiles.length > 0) {
                    $scope[$scope.dragNDropUploadCallback].apply(this, $scope.dragNDropUploadCallbackArgs);
                }
            };

            $scope.uploadMouseover = function() {
                if (!$scope.expandingIn) {

                    $(elem).removeClass('upload-prompt-expanded-into-view');
                    $timeout.cancel($scope.expandedIntoViewTimeout);

                    $scope.expandingIn = true;

                    $timeout(function() {
                        $scope.completedUpload = false;
                    }, 1);

                    $(elem).removeClass('upload-prompt-fading-out');
                    $(elem).addClass('upload-prompt-expanding-into-view');

                    $('#attach-file-dragover').addClass('visible-on-screen');

                    $scope.expandedIntoViewTimeout = $timeout(function() {
                        $scope.expandingIn = false;
                    }, 300);
                }
            }

            $(document).on('dragenter', function(evt) {
                if( $scope.groupId == "docparser") {
                    if( $scope.isDocparserEnabled('writeaccess') ) {
                        $scope.allowDrag = true;
                    } else {
                        $scope.allowDrag = false;
                    }
                } else {
                    $scope.allowDrag = true;
                }
                if( $scope.allowDrag ) {
                    if ($(evt.target).closest('.dragndrop-dialog').length > 0) {
                        $(elem).addClass('upload-prompt-activated');
                        $scope.fileAboutToBeDropped = true;
                    }

                    if ($(evt.target).hasClass('dragndrop-dialog')) {
                        $(elem).addClass('upload-prompt-activated');
                        $scope.fileAboutToBeDropped = true;
                    }

                    if ($(evt.target).closest('.dragndrop-dialog').length == 0 && !$(evt.target).hasClass('dragndrop-dialog')) {
                        $(elem).removeClass('upload-prompt-activated');
                        $scope.fileAboutToBeDropped = false;
                    }
                    $scope.uploadMouseover();
                }
            });

            $(document).on('dragleave', function(evt) {
                $scope.uploadMouseleave(evt);
            });

            $scope.$on('dragndrop:showuploadprompt', function(evt) {
                $scope.uploadMouseover();
            });

            $scope.$on('dragndrop:closeuploadprompt', function(evt) {
                $scope.dismissDialog();
            });

            $scope.removeBackground = function() {
                $('#attach-file-dragover').removeClass('visible-on-screen');
            }

            $scope.addBackground = function() {
                $('#attach-file-dragover').addClass('visible-on-screen');
            }

            $scope.closeUploadPrompt = function() {
                $(elem).closest('#attach-file-dragover').removeClass('visible-on-screen');

                $(elem).removeClass('upload-prompt-expanding-into-view');
                $(elem).removeClass('upload-prompt-expanded-into-view');
                $(elem).addClass('upload-prompt-fading-out');

                $scope.addBackground();

                $(elem).removeClass('upload-in-progress-activated');
                
                var target = $(elem).find('.dragndrop-dialog');
                target.css('height', 'auto');

                $timeout(function() {
                    $scope.completedUpload = false;
                    $(elem).removeClass('processing-upload-in-background');
                }, 1);
            };

            $scope.uploadMouseleave = function(evt) {
                if ((evt.originalEvent.screenX == 0 && evt.originalEvent.screenY == 0)) {
                    $(elem).closest('#attach-file-dragover').removeClass('visible-on-screen');

                    $(elem).removeClass('upload-prompt-expanding-into-view');
                    $(elem).removeClass('upload-prompt-expanded-into-view');
                    $(elem).addClass('upload-prompt-fading-out');

                    $(elem).removeClass('upload-in-progress-activated');
                    $scope.getReadyForNextUpload();
                }

                if (!$scope.expandingIn) {
                    $(elem).removeClass('upload-prompt-expanding-into-view');
                    $(elem).removeClass('upload-prompt-expanded-into-view');
                    $(elem).addClass('upload-prompt-fading-out');

                    $scope.removeBackground();
                    $(elem).removeClass('upload-in-progress-activated');
                }
            }

            $scope.hideUploadPrompt = function() {
                $(elem).removeClass('upload-prompt-expanding-into-view');
                $(elem).addClass('upload-prompt-expanded-into-view');
                $(elem).removeClass('upload-prompt-morphing');
            }

            $scope.getReadyForNextUpload = function() {
                $(elem).removeClass('upload-prompt-expanding-into-view');
                $(elem).removeClass('upload-prompt-expanded-into-view');
                $(elem).removeClass('upload-prompt-fading-out');
                $(elem).removeClass('upload-prompt-faded-out');
                $(elem).removeClass('upload-prompt-morphing');
                $(elem).removeClass('upload-prompt-activated');
                $(elem).removeClass('upload-in-progress-activated');
                $(elem).removeClass('upload-in-progress-error');

                $timeout(function() {
                          $scope.progressBar = 0;
                          $scope.uploadPercentage = '0%';
                          $scope.uploadProgressStyle = {};
                          $scope.uploadProgressStyle.width = '0%';

                          $scope.fadeInUploadingFiles = false;
                          $scope.expandingIn = false;
                          $scope.uploadError = false;
                          $scope.uploadInProgress = false;
                          $scope.fakeUploadInProgress = false;
                          $scope.fileAboutToBeDropped = false;
                          $scope.completedUpload = false;
                }, 1);

                $(elem).find('.upload-in-progress-overlay').removeClass('drag-n-drop-overlay-visible');

                $scope.setHeightToAuto();
            }

            $scope.resetClasses = function() {
                $scope.hideUploadPrompt();

                $(elem).find('.upload-in-progress-overlay').removeClass('drag-n-drop-overlay-visible');

                $scope.progressBar = 0;
                $scope.supplierId = '';
                $scope.uploadPercentage = '0%';
                $scope.uploadProgressStyle = {};
                $scope.uploadProgressStyle.width = '0%';

                $scope.fadeInUploadingFiles = false;

                $scope.setHeightToAuto();
            }

            $scope.getUploadInProgress = function() {
                return ($scope.uploadInProgress || $scope.fakeUploadInProgress);
            }

            $scope.setHeightToAuto = function() {
                var target = $(elem).find('.upload-progress-container');
                target.css('height', 'auto');

                var marginTop = target.outerHeight()/2;

                $(elem).addClass('upload-in-progress-height-calculated');
            }

            $scope.calculateUploadHeight = function() {
                var uploadProgressContainer = $(elem).find('.upload-progress-container-padding');

                var height = uploadProgressContainer.outerHeight();

                var marginTop = height/2;

                $(elem).addClass('upload-in-progress-height-calculated');
            }

            $scope.resizeDragNDropOverlay = function() {
//		      $(elem).addClass('upoad-prompt-morphing');

//		      var uploadProgressContainer = $(elem).find('.upload-progress-container-padding');
                var uploadProgressContainer = $(elem).find('.dragndrop-dialog');

                var height = uploadProgressContainer.height();
                var width = uploadProgressContainer.width();

                var marginTop = parseFloat(uploadProgressContainer.outerHeight())/2;

                $(elem).find('.dragndrop-dialog').css('height', height).css('width', width).css('margin-top', '-' + marginTop + 'px');
            }

            $scope.showUploadInProgress = function() {
                $timeout(function() {
                    $(elem).addClass('upload-in-progress-activated');
                    $scope.resizeDragNDropOverlay();
                }, 1300);
            }

            $scope.fileUploadErrorUI = function(errormsg) {
                $scope.uploadPercentage = '100%';
                $scope.uploadProgressStyle.width = '100%';

                $timeout(function() {
                    $scope.filesToBeUploaded = [];
                    $scope.uploadError = errormsg;

                    $scope.fadeInUploadingFiles = false;

                    $scope.expandingIn = false;
                    $scope.uploadProgressStyle = {};
                    $scope.filesToBeUploaded = [];
                    $scope.uploadInProgress = false;
                    $scope.progressBar = 0;
                    $scope.uploadPercentage = '0%';
                    $scope.uploadProgressStyle.width = '0%';

                    $scope.fadeInUploadingFiles = true;
                    $scope.activated = false;
                    $scope.fadingOut = true;

                    $scope.fadingOut = false;
                    $scope.expandingIn = true;

                    $scope.calculateUploadHeight();
                    $scope.hideUploadPrompt();
                    $(elem).addClass('upload-in-progress-activated');
                    $(elem).addClass('upload-in-progress-error');
                    if( $scope.groupId == "docparser") {
                        var target = $(elem).find('.dragndrop-dialog');
                        var height = target.height();
                        $(elem).find('.dragndrop-dialog').css('height', height/2);
                    } else {
                        $scope.resizeDragNDropOverlay();
                    }
                }, 100);
            }
            
            $scope.fileUploadUI = function(settings) {
                $scope.uploadPercentage = '100%';
                $scope.uploadProgressStyle.width = '100%';

                
                $timeout(function() {
                    $scope.completedUpload = false;
                    
                    $scope.fadeInUploadingFiles = false;
                    $scope.settings = settings;
                    $scope.expandingIn = false;
                    $scope.uploadProgressStyle = {};
                    $scope.fakeUploadInProgress = false;
                    $scope.uploadInProgress = false;
                    $scope.progressBar = 0;
                    $scope.uploadPercentage = '0%';
                    $scope.uploadProgressStyle.width = '0%';

                    $scope.fadeInUploadingFiles = true;
                    $scope.activated = false;
                    $scope.fadingOut = true;

                    $scope.fadingOut = false;
                    $scope.expandingIn = true;

                    $scope.calculateUploadHeight();
                    $scope.hideUploadPrompt();
                    $(elem).addClass('upload-in-progress-activated');
                    $(elem).addClass('upload-in-progress-error');
                    $scope.resizeDragNDropOverlay();
                }, 100);
            }
        },
        controller: function($rootScope, $scope, Upload, $timeout, dragNDropService) {
            $scope.supplierId = '';
            $scope.addHoverClass = function() {
                $scope.dragDropActive = true;
            };

            $scope.uploadNull = function() {
                return false;
            }

            $scope.$on('ngFileUpload', function(evt, settings) {
                if( $scope.allowDrag ) {
                    if (settings.files || settings.invalidFiles) {
                        $scope.upload(settings);
                    }
                }
            });

            $scope.$on('ngFileUploadFake', function(evt, settings) {
                if( $scope.allowDrag ) {
                    if (settings.files || settings.invalidFiles) {
                        $scope.upload(settings, 'fakeUpload');
                    }
                }
            });

            /* Params in settings:
                array 'files',
                object/array 'customUploadObject',
                object 'singleFile',
                array 'newFiles',
                array 'duplicateFiles',
                array 'invalidFiles',
                object 'originalEvent'
            */
            $scope.upload = function(settings, fakeUpload) {
                
                if (!settings.files) {
                    $scope.uploadError = dragNDropService.error($scope,groupId, 'minQty');
                }
                
                if( settings.files.length > 10 && $scope.groupId == "docparser") {
                    $scope.uploadError = dragNDropService.error($scope.groupId, 'maxFiles');
                    $scope.fileUploadErrorUI($scope.uploadError);
                    return false;
                }
                
                if ($scope.fakeUploadInProgress && !fakeUpload) {
                    // This will only be true if there was previously a fake/test upload, and now the user is doing a real upload straight afterwards. 
                    // In this case, we need to reset the value of $scope.uploadInProgress
                    $scope.uploadInProgress = false;
                }

                if (!$scope.uploadInProgress) {
                    if (!settings.customUploadObject) {
                        // this should usually be defined in the parent controller but if it's not, it gets defined here
                        settings.customUploadObject = {'files': settings.files};
                    }

                    $scope.completedUpload = false;
                    $scope.activated = true;
                    $scope.uploadInProgress = true;

                    if (fakeUpload) {
                        $scope.fakeUploadInProgress = true;
                    }
                    else {
                        $scope.fakeUploadInProgress = false;
                    }

                    $scope.uploadProgressStyle = {};
                    $scope.filesToBeUploaded = [];
                    $scope.progressBar = 0;
                    $scope.uploadPercentage = '0%';
                    $scope.uploadProgressStyle.width = '0%';

                    if ($scope.uploadError) {
                        $scope.fileUploadErrorUI($scope.uploadError);
                        return false;
                    }

                    $timeout(function() {
                        $scope.calculateUploadHeight();

                        $scope.showUploadInProgress();

                        $scope.fadeInUploadingFiles = true;
                        $scope.activated = false;
                        $scope.fadingOut = true;


                        $timeout(function() {
                            $scope.fadingOut = false;
                            $scope.expandingIn = true;

                            var fileParams = "";

                            $scope.resetClasses();

                            angular.forEach(settings.invalidFiles, function(file, fileindex) {
                                if (file.$error) {
                                    if (file.$error == 'pattern') {
                                        if (!file['humanReadableError'] || file['humanReadableError'] == '') {
                                            file['humanReadableError'] = dragNDropService.error($scope.groupId, 'pattern', file);
                                        }
                                    }
                                    else {
                                        if (file['$errorMessages']) {
                                            if (file.$errorMessages['maxSize']) {
                                                file['humanReadableError'] = dragNDropService.error($scope.groupId, 'maxSize', file);
                                            }
                                        }
                                        if (!file['humanReadableError'] || file['humanReadableError'] == '') {
                                            file['humanReadableError'] = dragNDropService.error($scope.groupId);
                                        }
                                    }
                                }
                            });
console.log('custom upload object');
console.log(settings.customUploadObject);

                            $scope.invalidFiles = angular.copy(settings.invalidFiles);
                            $scope.filesToBeUploaded = angular.copy(settings.files);

                            if ($scope.filesToBeUploaded && !fakeUpload) {
                                if (Array.isArray($scope.filesToBeUploaded) && Array.isArray($scope.invalidFiles)) {
                                    $scope.completedUpload = false;
                                    if ($scope.filesToBeUploaded.length > 0) {
                                        Upload.upload({
                                            url: prefix + $scope.uploadAction,
                                            data: settings.customUploadObject

                                        }).then(function(resp) {
                                            if (resp.data.error || $scope.invalidFiles.length > 0) {
                                                if (resp.data.error) {
                                                    if(resp.data.status==2){
                                                        $scope.fileUploadErrorUI('maxsize');
                                                    }else if(resp.data.status==0){
                                                        $scope.fileUploadErrorUI('spaceExceeded');
                                                    }else{
                                                        $scope.fileUploadErrorUI(resp.data.error);
                                                    }
                                                }
                                                else if ($scope.invalidFiles.length > 0) {
                                                    $scope.uploadError = 'invalid';
                                                    //$scope.fileUploadErrorUI($scope.uploadError);
                                                }
                                            }
                                            else if ($scope.invalidFiles.length == 0) {
                                                $scope.uploadPercentage = '100%';
                                                $scope.uploadProgressStyle.width = '100%';

                                                $timeout(function() {
                                                    $scope[$scope.dragNDropUploadCallback].apply(this, $scope.dragNDropUploadCallbackArgs);
                                                    $scope.completedUpload = true;
                                                    $scope.fadeInUploadingFiles = false;
                                                    $scope.expandingIn = false;

                                                    $scope.uploadInProgress = false;
                                                    $scope.fakeUploadInProgress = false;
                                                    $scope.uploadError = false;
                                                }, 800);
                                            }
                                        }, function(resp) {
                                            $scope.filesToBeUploaded = [];
                                            $scope.uploadInProgress = false;
                                            $scope.fakeUploadInProgress = false;
                                            $scope.progressBar = 0;
                                            $scope.uploadPercentage = '0%';
                                            $scope.uploadProgressStyle.width = '0%';

					    var errorStatusFound = false;

					    if (resp) {
						if (resp.status) {
							if (resp.status == 413) {
								errorStatusFound = true;
								$scope.uploadError = "maxsize";
							}
						}
					    }

					    if (!errorStatusFound) {
	                                            $scope.uploadError = "server-error";
					    }

                                        }, function(evt) {
                                            $scope.progressBar = parseInt(100.0 * evt.loaded / evt.total);
                                            if ($scope.progressBar < 100) {
                                                $scope.uploadPercentage = $scope.progressBar + '%';
                                                $scope.uploadProgressStyle.width = $scope.progressBar + '%';
                                            }
                                        });
                                    }
                                    else if ($scope.invalidFiles.length > 0) {
                                        $scope.uploadError = 'invalid';
                                    }
                                }
                            }
                        }, 200);
                    }, 100);
                }
            };

        }
    }
}]);
