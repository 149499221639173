commusoftCommon.directive('searchdropdown', function() {
    var handleDropdown = function handleDropdown(scope, element, attrs) {

        // scope vars
        scope.search_placeholder = attrs.searchPlaceholder;
        scope.person_results_title = attrs.personResultsTitle;
        scope.search_contacts=angular.fromJson(attrs.searchContacts);
        scope.splitSearchText = function(string, nb) {
            scope.array = string.split('@');
            return scope.result = scope.array[nb];
        }

        // instance vars
        scope.input = element.find('input[type=search]');
        scope.dropdown = element.find('.search-dropdown-wrapper');

        var handleInput = (function() {

            scope.handleInputEvents = (function handleInputEvents () {

                scope.input.on('keyup', function(e) {

                    if ($(this).val().match(/[@]/)) {
                        scope.handleResultSet('users');

                    }

                    // when input is empty hide the dropdown
                    if ($(this).val().length === 0) {
                        scope.hideDropdown();
                    }

                });

            })();

            scope.handleResultSet = function handleResultSet(type) {
                scope.dropdown.addClass('open');
                scope.result_collection = type;
            }
            scope.$on('body:clicked', function (event, data) {
                scope.dropdown.removeClass('open');
            });

            scope.hideDropdown = function hideDropdown() {
                scope.dropdown.removeClass('open');
                scope.input.val('');
            }

        })();
    }

    return {
        restrict: 'A',
        scope: { },
        link: handleDropdown,
        templateUrl: 'template/searchdropdown/searchdropdown.html',
        controller: function($scope) {
            $scope.$watch('searchText', function(newVal, oldVal){
                if (newVal != undefined) {
                    this.searchText=newVal;
                    $scope.$emit('searchDropDown', this.searchText);
                }
            });
            $scope.selectContactName = function selectContactName (contacts) {
                $scope.searchText = '@'+contacts.name;
                $scope.$emit('searchDropDown', contacts);
                $scope.dropdown.removeClass('open');
            }
        }

    }
});