commusoftCommon.directive('dropzone', ['prefix', '$http', '$rootScope', function(prefix, $http, $rootScope) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var urlToSend = prefix + element.attr('dropzone-url');
            var dropzoneConfig = {
                'url': urlToSend,
                'clickable': true,
                'maxFiles': 1,
                'previewTemplate': '<div class="preview file-preview"><div class="details"><div class="filename"><span></span></div></div><div class="progress"><span class="upload"></span></div><div class="success-mark"><span>?</span></div><div class="error-mark"><span>?</span></div></div>',
                'sending': function (file, xhr, formData) {
                    scope.onDropzoneSending();
                },
                'success': function (file, response) {
                    angular.element('div.progress').hide();
                    if(response.error) {
                        angular.element('div.processing').remove();
                    }
                    scope.onDropzoneSuccess(response);
                }
            };

            var dropzone = new Dropzone(element, dropzoneConfig);

            scope.triggerDropZoneClick = function() {
                element.trigger('click');
            }

            setTimeout(function(){angular.element('div.default').remove();},100);
        }
    }
}]);