commusoftCommon.directive('csimageresize', ['prefix', '$http','$modal', function(prefix, $http, $modal) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            openResizeModal: '=',
            imageSrc: '=',
            imgOptions: "=",
            saveResizedImage: '&',
            saveUrl: '@'
        },
        controller: function($scope, $element, $attrs, $transclude) {
            $scope.$watch('openResizeModal', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;
                    modalInstance = $modal.open({
                        templateUrl: 'template/image_resize_modal.html',
                        scope: $scope,
                        controller: CSImageResigeModalCtrl,
                        windowClass: 'modal preview-modal'
                    })
                }
            });
        }
    }
}]);