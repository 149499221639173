csmodule.directive('savingcomponent', ['$timeout', function($timeout) {
	var handleSavingComponent = function handleSavingComponent(scope, element, attrs) {
		attrs.$observe('state', function(state) {
      scope.state = state;
    });

    attrs.$observe('message', function(message) {
      scope.message = message;
    });
	}

  return {
    templateUrl: 'template/shared/saving_component.html',
    scope: {},
    link: handleSavingComponent
  }
}]);