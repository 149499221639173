function CSChatModalCtrl($scope, $state,$http,$location, prefix, $modalInstance, $timeout, $rootScope, $filter, $q, toastBox, confirmationBoxHelper, tableCollection) {
    
    $scope.limit = 5;
    $scope.title = "Setup Chat";
    //$scope.shouldBeOpenChat = false;
    
    $scope.close = function() {
        $scope.loading = false;
        $state.reload();
        $scope.shouldBeOpenChat = false;
        $modalInstance.close();
    }
    
    window.onkeydown = function (evt) {
        $scope.modal_confirmation_done = false;
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }

    $scope.triggerCloseModalBox1 = function () {
        $scope.close();
        $scope.shouldBeOpenChat = false;
    }
    
    $scope.loadingchat = false;  

    $scope.showSetupButton = true;
    $scope.checkSetupCompleted = function() {
        $scope.loading = true;
        $http.get(prefix + '/getChatSetupStatus').success(function (data) {
            if( data.setup == "new" ) {
                $scope.loading = false;
                $scope.showSetupButton = true;
            } else if (data.setup == "process") {
                $scope.loading = false;
                $scope.showSetupButton = false;
                $scope.showProcessDetails = true;
                $scope.totalUsers = data.totalUsers;
                $scope.processCount = data.count;
                $scope.remainingCount = data.totalUsers - data.count;
                $scope.usersData = data.data;
            } else if(data.setup == "complete") {
                $scope.loading = false;
                $scope.showSetupButton = false;
                $scope.showProcessDetails = false;
                $scope.showSetupComplete = true;
                $scope.usersData = data.data;
            }
            
        });
    }
    
    if( $scope.path == "template/chat/chat_messages.html" ) {
        $rootScope.showChatModal = false;
        //$scope.fetchChatMessages();
        $scope.loadingChat = true;
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        //alert($rootScope.firstMessageId);
       // alert($rootScope.lastMessageId);
        $http.get(prefix + '/chat/' + $rootScope.selectedId + '/jobs/' + $rootScope.jobId +  '/listChatMessages?searchText=' + encodeURIComponent(searchText) + '&contactId=' + $scope.contactId + '&startDate=' + $rootScope.messageDate + '&endDate=' + $rootScope.messageDate).success(function (data) {
          $scope.chatMessages = data.chatMessages;
          $scope.chatMessagesCount = data.chatMessagesCount;
          $scope.chatApi = data.chatApi; 
          $scope.loadingChat = false;
        })
        
    }
    
}

commusoftCommon.directive('csmodalchat', ['prefix', '$http', '$modal',function(prefix, $http, $modal) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            shouldBeOpenChat: '=',
            step: '=',
            name: '=',
            path: '='
        },
        controller: function($scope, $element, $attrs, $transclude) {
            
            $scope.$watch('shouldBeOpenChat', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal == true) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;
                    modalInstance = $modal.open({
                        templateUrl: $scope.path,
                        scope: $scope,
                        controller: CSChatModalCtrl,
                        windowClass: 'modal with-slides large'
                    })
                }
           })
            
        }
    }
}]);

