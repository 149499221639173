csmodule.service("spreadSheetRefsHelper", function() {
  this.getCellFromStrIndex = function getCellFromStrIndex(rows, cell_index) {
    var associated_str = /associated_rows/,
        number_pattern = /\d+/g,
        cell_is_associated = associated_str.test(cell_index),
        number_matches = cell_index.match(number_pattern),
        new_cell;

    if(cell_is_associated) {
      var new_row_index = number_matches[0],
          new_col_index = number_matches[1],
          new_associated_row_index = number_matches[2],
          new_associated_col_index = number_matches[3],
          row_exists = rows[new_row_index] !== undefined;

      if(row_exists) {
        new_cell = rows[new_row_index].row[new_col_index].associated_rows[new_associated_row_index][new_associated_col_index];
      }
    }else {
      var new_row_index = number_matches[0],
          new_col_index = number_matches[1],
          row_exists = rows[new_row_index] !== undefined;

      if(row_exists) {
        new_cell = rows[new_row_index].row[new_col_index];
      }
    }

    return new_cell;
  }

  this.getRowFromStrIndex = function getRowFromStrIndex(rows, row_index) {
    var associated_str = /associated_rows/,
        number_pattern = /\d+/g,
        cell_is_associated = associated_str.test(row_index),
        number_matches = row_index.match(number_pattern),
        new_row;

    if(cell_is_associated) {
      var new_row_index = number_matches[0],
          new_col_index = number_matches[1],
          new_associated_row_index = number_matches[2];

      new_row = rows[new_row_index].row[new_col_index].associated_rows[new_associated_row_index];
    }else {
      var new_row_index = number_matches[0]

      new_row = rows[new_row_index].row;
    }

    return new_row;
  }
});