commusoftCommon.directive('fixchosen', function($timeout) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var elementToApplyScope = angular.element('#' + attrs.fixchosen);
            var data = attrs.data;

            angular.forEach(scope[data], function(val, key) {
                if (val.group && val.group === true) {
                    angular.element("option[value='group|" + val.id + "']", 'select#' + attrs.fixchosen).addClass('group');
                }
            });
            $timeout(function() {
                elementToApplyScope.chosen();
            }, 100);

        }
    }
});