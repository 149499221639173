/** DiaryMapRightbarOptionsCtrl declaration ------------------------------------------------------------------------ */

var DiaryMapRightbarOptionsCtrl = ['$rootScope', '$scope', '$http', 'uiCalendarConfig', '$diaryMapScope', '$timeout', 'diarySidePanel', 'outstandingJobsService', function ($rootScope, $scope, $http, uiCalendarConfig, $diaryMapScope, $timeout, diarySidePanel, outstandingJobsService) {
    $scope.foreignWatchers = {};
    $scope.mapMode = $diaryMapScope.mapTimeBounds.mode;

    $scope.currentEvents = [];
    $scope.usersWithoutEvents = [];

    $scope.jobDurationSettings = $scope.diary_ctrl_scope.jobDurationSettings;
    $scope.diaryEventActiveStatuses = ['just_booked', 'accepted', 'travel', 'arrived'];

    $scope.calendarDate = null;
    $scope.dueWindowJobFilters = {
        bounding_start_datetime: null,
        bounding_end_datetime: null,
        //record_type: 'job',
        outstanding_jobs_count: true,
        limit: -1
    };
    $scope.loadingOutstandingJobs = false;
    $scope.jobDueWindows = $scope.jobDurationSettings ? $scope.jobDurationSettings.durationWindows : [];
    $scope.totalOutstandingJobs = 0;

    $scope.jobsAndDwMaps = {};
    $scope.isOutstandingJobsSidepanelOpen = false;

    $scope.$on('calendarDateChanged', function (event, date) {
        var prevDate = moment($scope.calendarDate).format('YYYY/MM/DD');
        var newDate = moment(date).format('YYYY/MM/DD');

        if (newDate === prevDate) return;

        $scope.onDateChange(date);

    });

    $scope.onDateChange = function (date) {
        $scope.calendarDate = moment(date);
        $scope.dueWindowJobFilters.bounding_start_datetime = $scope.calendarDate.clone().set({
            hours: 0,
            minutes: 0,
            seconds: 0
        })._d;
        $scope.dueWindowJobFilters.bounding_end_datetime = $scope.calendarDate.clone().set({
            hours: 0,
            minutes: 0,
            seconds: 0
        }).add(1, 'day')._d;

        if ($scope.jobDurationSettings) $scope.setDWRefreshTimer();
    };

    // Outstanding Job selection
    $scope.outstandingJobClicked = function (oj) {
        $diaryMapScope.handleOutstandingJobSelection(oj);
    };

    $scope.publishUsersToShowOnDiary = function (users_list) {
        if ($diaryMapScope.diaryMapRenderObservable.stages.indexOf('INITIATED') >= 0) {
            $diaryMapScope.setUsersToShowOnMap(users_list);
        }
        // Wait for map initialization
        else $diaryMapScope.diaryMapRenderObservable.promise.then(null, null, function (message) {
            if (message === 'INITIATED') {
                $diaryMapScope.setUsersToShowOnMap(users_list);
            }
        });
    };
    // Watcher for diary users
    $scope.foreignWatchers['usersToShowOnDiary'] = $scope.diary_ctrl_scope.$watch('users_to_show_on_dairy', function (users_list, old_users_list) {
        if (JSON.stringify(users_list) === JSON.stringify($diaryMapScope.usersToShowOnMap)) return;

        $scope.publishUsersToShowOnDiary(users_list);

        $scope.updateUsersWithoutEvents($scope.diary_ctrl_scope.currentEvents);
    });

    $scope.refreshJobDueWindows = function () {
        $scope.loadingOutstandingJobs = true;

        var filters = angular.copy($scope.dueWindowJobFilters);
        filters.bounding_start_datetime = filters.bounding_start_datetime.toISOString();
        filters.bounding_end_datetime = filters.bounding_end_datetime.toISOString();

        outstandingJobsService.getDueWindowJobs(filters).then(function (data) {
            $scope.totalOutstandingJobs = data.totalOutstandingJobs;
            $scope.jobDueWindows = $scope.jobDueWindows.map(function (w) {
                var match = data.dueWindows.find(function (wc) {
                    return wc.windowName === w.windowName;
                });

                w.outstandingJobs = match ? match.outstandingJobs : [];
                return w;
            });

            $scope.loadingOutstandingJobs = false;

            if ($scope.isOutstandingJobsSidepanelOpen) {
                $diaryMapScope.jobDueWindows = $scope.jobDueWindows;
            }
            else
                $timeout(function () {
                    $scope.$apply(function () {
                        if ($diaryMapScope.diaryMapRenderObservable.stages.indexOf('INITIATED') >= 0) $diaryMapScope.onJobDueWindowsRefresh($scope.jobDueWindows, true);
                        // Wait for map initialization
                        else $diaryMapScope.diaryMapRenderObservable.promise.then(null, null, function (message) {
                            if (message === 'INITIATED') $diaryMapScope.onJobDueWindowsRefresh($scope.jobDueWindows, true);
                        });
                    });
                });

        }).catch(function () {
            $scope.loadingOutstandingJobs = false;
        });
    };

    var dwRefreshTimer;
    $scope.setDWRefreshTimer = function () {
        // Check for required module and data
        if (!$scope.jobDurationSettings) return;

        if (dwRefreshTimer) clearTimeout(dwRefreshTimer);

        $scope.refreshJobDueWindows();

        dwRefreshTimer = setInterval(function () {
            $scope.refreshJobDueWindows();
        }, 5 * 60 * 1000); // refresh in 5 mins interval
    };

    // Fetch due window jobs and outstanding jobs count on any diary event update or new job
    $scope.$on('refresh:outstanding-jobs', function (e) {
        $scope.setDWRefreshTimer();
    });

    // Opening the outstanding jobs side panel
    $scope.handleOutstandingJobsLabelClick = function () {
        diarySidePanel.init('outstanding_jobs_side_panel_on_diary', 'Outstanding jobs', {
            jobDueWindows: $scope.jobDueWindows
        });

        $scope.isOutstandingJobsSidepanelOpen = true;
    };

    // Set jobs map markers respect to calendar date on side panel close
    $scope.foreignWatchers['ojSidePanelCloseListener'] = $rootScope.$on('sidepanel:outstanding_jobs_side_panel_on_diary:closed', function (e) {
        if ($scope.isOutstandingJobsSidepanelOpen) $scope.isOutstandingJobsSidepanelOpen = false;
        if (!$diaryMapScope.processingSidepanelJobSelection) $diaryMapScope.onJobDueWindowsRefresh($scope.jobDueWindows);
    });

    // Set jobs map markers respect to calendar date on side panel close
    $scope.foreignWatchers['dueWindowsSidePanelCloseListener'] = $rootScope.$on('sidepanel:duewindow_jobs_side_panel_on_diary:closed', function (e) {
        if ($scope.isOutstandingJobsSidepanelOpen) $scope.isOutstandingJobsSidepanelOpen = false;
        if (!$diaryMapScope.processingSidepanelJobSelection) $diaryMapScope.onJobDueWindowsRefresh($scope.jobDueWindows);
    });

    $scope.handleJobDueWindowClick = function (dueWindow) {

        $diaryMapScope.$emit('jobDueWindows:label:clicked', {
            windowName: dueWindow.windowName,
            windowColor: dueWindow.color,
            outstandingJobs: dueWindow.outstandingJobs
        });

        diarySidePanel.init('duewindow_jobs_side_panel_on_diary', 'Outstanding jobs', {
            selectedWindowName: dueWindow.windowName,
            jobDueWindows: $scope.jobDueWindows,
            filtersApplied: angular.copy($scope.dueWindowJobFilters),
            jobDuration: $scope.jobDurationSettings.jobDuration
        });

        $scope.isOutstandingJobsSidepanelOpen = true;
    };

    $scope.updateUsersWithoutEvents = function (diaryEvents) {

        // Check for required module and data
        if (!$scope.jobDurationSettings) return;

        var userIdsInEvents = diaryEvents
            .filter(function (event) {
                return $scope.diaryEventActiveStatuses.indexOf(event.status.status) >= 0;
            }).map(function (event) {
                return event.resourceId;
            });


        var usersWithoutEvents = $scope.diary_ctrl_scope.users_to_show_on_dairy.filter(function (u) {
            return userIdsInEvents.indexOf(u.id) < 0;
        }).map(function (u) {
            u = angular.copy(u);
            u.lastEventStatusTime = $diaryMapScope.usersEvents[u.id] instanceof Array ? $diaryMapScope.usersEvents[u.id][0]['statusUpdated'] : null;
            return u;
        });

        usersWithoutEvents.sort(function (a, b) {
            var aSt = a.lastEventStatusTime ? moment(a.lastEventStatusTime, 'ddd Do MMM YYYY HH:mm A') : moment('0'),
                bSt = b.lastEventStatusTime ? moment(b.lastEventStatusTime, 'ddd Do MMM YYYY HH:mm A') : moment('0');

            if (aSt.isSame(bSt) || aSt.isAfter(bSt)) return 1;
            else if (aSt.isBefore(bSt)) return -1;
            else return 0;
        });

        $scope.usersWithoutEvents = usersWithoutEvents;

        // Publish
        var userIds = $scope.usersWithoutEvents.map(function (u) {
            return u.id;
        });


        if ($diaryMapScope.diaryMapRenderObservable.stages.indexOf('USERS_RENDERED') >= 0)
            $diaryMapScope.onUsersWithoutEventsUpdate(userIds);
        // Wait for map initialization
        else $diaryMapScope.diaryMapRenderObservable.promise.then(null, null, function (message) {
            if (message === 'USERS_RENDERED') $diaryMapScope.onUsersWithoutEventsUpdate(userIds);
        });
    };

    // Listening for diary event changes
    $scope.$on('currentEventsChanged', function (event, diaryEvents) {
        if (JSON.stringify($scope.currentEvents) === JSON.stringify(diaryEvents)) return;

        $scope.currentEvents = angular.copy(diaryEvents);
        $scope.updateUsersWithoutEvents(diaryEvents);
    });

    // Open users activity side panel
    $scope.showUsersActivityPanel = function () {
        diarySidePanel.init('users_activity_panel_on_diary', 'User activity', {users: $scope.usersWithoutEvents});
    };

    $scope.foreignWatchers['mapTimeBounds'] = $diaryMapScope.$on('mapTimeBounds:updated', function (e, timeBounds) {
        $scope.mapMode = timeBounds.mode;
    });

    // Removes events
    $scope.$on('$destroy', function () {
        if (dwRefreshTimer) clearTimeout(dwRefreshTimer);

        Object.keys($scope.foreignWatchers).forEach(function (n) {
            $scope.foreignWatchers[n]();
        })
    });

}];
