commusoftCommon.directive('ng7RouteLink',
  ['HybridFactory','$state', '$location', function(HybridFactory, $state, $location) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        $(element).click(function(e){
          e.preventDefault();
          var link = $(element).prop('tagName') === 'A' ?
            $(element).attr('href') : attrs.ng7RouteLink;
          if($state.current.name === 'loggedin.empty') {
              HybridFactory.redirectNg7Route(link);
          }
          else if($(element).attr('target')) {
              window.open(link,$(element).attr('target'));
          }else{
              $location.path(link);
          }
        });
      }
    };
}]);