commusoftCommon.directive('panelwithformtemplate', ['prefix', '$http', '$modal',function(prefix, $http, $modal) {
    var updateDynamicTemplate = function (scope,element,attribute) {
        scope.$emit("sidepanel-template",scope);
    };
    return {
        restrict: 'EA',
        scope: false,
        link : updateDynamicTemplate,
        templateUrl: function (elem,attr) {
            return attr.filepath;
        }
    }
}]);