commusoftCommon.directive('checkMaximumValue', ['prefix', 'canLoad', '$q','$http', function(prefix, canLoad, $q, $http) {
    return {
        require: '^?form',
        restrict: 'A',

        link: function(scope, element, attrs, ctrl) {
            var canceler = null;
            scope.$watch(attrs.checkMaximumValue, function(val) {
                if (typeof val != 'undefined' && val  != '') {
                    val = (val == 'custom') ? scope.customValue : val;
                    var url = prefix + attrs.checkMaximumValueUrl + val;
                    canLoad.setLoadValue(false);  // Turn off AJAX
                    if (canceler) {
                        canceler.resolve();
                    }
                    canceler = $q.defer();
                    $http.get(url, {timeout: canceler.promise}).
                    success(function(data, status) {
                        if (status == 200) {
                            if (data == "true") {
                                ctrl.$setValidity("checkMaximumValue", true);
                            } else {
                                ctrl.$setValidity("checkMaximumValue", false);
                            }
                        }
                    })

                }
            });
            scope.$watch("customValue", function(newVal, oldVal) {
                if (typeof newVal != 'undefined' && newVal  != '') {
                    var url = prefix + attrs.checkMaximumValueUrl + scope.customValue;
                    canLoad.setLoadValue(false);  // Turn off AJAX
                    if (canceler) {
                        canceler.resolve();
                    }
                    canceler = $q.defer();
                    $http.get(url, {timeout: canceler.promise}).
                    success(function(data, status) {
                        if (status == 200) {
                            if (data == "true") {
                                ctrl.$setValidity("checkMaximumValue", true);
                            } else {
                                ctrl.$setValidity("checkMaximumValue", false);
                            }
                        }
                    })
                }
            });
        }
    }
}]);

commusoftCommon.directive("limitToMax", function() {
    return {
        link: function(scope, element, attributes) {
            element.on("keydown keyup", function(e) {
                if (Number(element.val()) > Number(attributes.max) &&
                    e.keyCode != 46 // delete
                    &&
                    e.keyCode != 8 // backspace
                ) {
                    e.preventDefault();
                    element.val(attributes.max);
                }

            });
        }
    };
});
