commusoftCommon.directive('hasPermission', ['prefix', '$http', '$rootScope', function(prefix, $http, $rootScope) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var featureAndAccess = attrs.hasPermission;
            var checkFeatureArray = [];
            if (featureAndAccess.indexOf("|") !== -1) {
                checkFeatureArray = featureAndAccess.split("|");
            } else {
                checkFeatureArray.push(featureAndAccess);
            }

            scope.toggleVisibilityBasedOnPermission = function() {
                if(!$rootScope.accessDetails) {
                }

                var anyoneHasPermission = _.some(checkFeatureArray, function(feat) {
                    var featureArray = feat.split(','),
                        featureName = featureArray[0],
                        featureAccess = featureArray[1],
                        featureId = features[featureName];
                    scope.featureId = featureId;
                    return $rootScope.hasPermission(featureId, featureAccess);
                });

                // Always hasPermission = true
                //var hasPermission = true;

                // Merge the feature name & its access for assign to scope.
                var scopeFeatureDataArray = checkFeatureArray[0].split(','),
                    scopeFeatureData = scopeFeatureDataArray[0] +'_'+ scopeFeatureDataArray[1];

                if (anyoneHasPermission) {
                    scope.$emit('displayPermissionEnable', scopeFeatureData);
                    //element.css('display', 'inline table');
                } else {
                    scope.$emit('displayPermission', $rootScope.accessDetails[scope.featureId], scopeFeatureData);
                    element.hide();
                }
            }

            scope.toggleVisibilityBasedOnPermission();
            scope.$on('permissionsChanged', function() {
                scope.toggleVisibilityBasedOnPermission();
            });
        }
    }
}]);