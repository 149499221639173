commusoftCommon.service('stringHelpers', function() {
    /*==========================================================================================
     Get the index / indexes of a word in a string
     ==========================================================================================*/
    this.findAllOccurances = function findAllOccurances(str, find) {
        var occurances = [];
        for(var i = 0; i < str.length; i++) {
            if(str.substring(i, i + find.length) == find) {
                occurances.push(i);
            }
        }
        return occurances;
    }

    /*==========================================================================================
     Replace a part of a string with another string
     ==========================================================================================*/
    this.replaceStringAt = function replaceStringAt(str, index, str_to_replace, replacement) {
        return str.substring(0, index) + replacement + str.substring(index + str_to_replace.length);
    }
});