commusoftCommon.service('clickEvents', function($rootScope, $timeout) {
    /*====================================================
        Data sets
    ====================================================*/
    category = '';

    click_events_collection = {
        daterange: {
            parent_elm : '.daterange-wrapper',
            ignore_list : '#datepicker-input, .daterange-day, .daterange_filter span.ss-calendar, #side-panel, .right-icon'
        },
        datepicker: {
            parent_elm: '.custom-datepicker',
            ignore_list : '#datepicker-input, .datepicker-append .ss-calendar, .custom-datepicker th, #side-panel, .right-icon'
        },
        actionbuttons: {
            parent_elm: '.actions-dropdown',
            ignore_list : '.actions-toggle, #side-panel, #page-panel-main, #update-column-panel, #page-overlay, #smart-panel-overlay, #update-column-link'
        },
        spreadsheet: {
            parent_elm : '.spreadsheet-table',
            ignore_list: '.spreadsheet-section .fullscreen-icon'
        }
    }

    /*====================================================
        Methods called from outside
    ====================================================*/

    this.registerIgnoreElements = function registerIgnoreElements(cat) {
        category = cat;
        document.onclick = function(e) {
            $rootScope.firedCloseAll = false;
            var enable_events = true;
            /*====================================================
                If the category is a single datepicker then check
                if the user has clicked a month or year in the
                date view - if the user has clicked a month or year
                then disable the click handler - if the user hasn't
                then go ahead and fire the click handler to handle
                closing the datepicker
            ====================================================*/
            if (category == 'datepicker'){
                if( $(e.target).is('.daterange-day') ){
                    var is_day = parseInt($(e.target).text());

                    enable_events = true;

                    if($.isNumeric(is_day)){
                        if( is_day>40 ){
                            enable_events = false
                        }else{
                            enable_events = true;
                        }
                    }else{
                        enable_events = false;
                    }
                }
            }

            /*====================================================
                Check if the click happened on an element that has
                been listed in the ignore_list
            ====================================================*/
            if(category != undefined){
                if($(e.target).is(click_events_collection[category].ignore_list)){
                    var click_ignored_element = true;
                }else if ($(e.target).parents().is(click_events_collection[category].ignore_list)){
                    var click_in_ignored_element = true;
                }
                else{
                    var click_ignored_element = false;
                }
                /*====================================================
                    Checks if the click happened inside the element that
                    we want to keep open, if not broadcast to all scopes
                ====================================================*/
                var click_in_elm = $(e.target).parents(click_events_collection[category].parent_elm).length > 0;

                if (click_ignored_element === false && enable_events === true) {
                    if (click_in_elm) {
                        return;
                    }
                    else {
                        $rootScope.$broadcast('closeallwithapply', e);
                        category = null;
                        return
                    }
                }
            }
        }
    }
});