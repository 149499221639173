csmodule.directive('cstreetableview', function($document, $timeout, confirmationBoxHelper, toastBox, $http, prefix, $state, defaultPagingValue) {

    var handleTreeTableView = function handleTreeTableView(scope, element, attrs) {
        scope.filterId = attrs.filterId;
        scope.listUrl = attrs.listUrl;
        scope.limits = attrs.limits?attrs.limits : defaultPagingValue;
        scope.customerTabName = attrs.customerTabName;
        scope.records = [];
        scope.cols = [];
        scope.rows = [];
        scope.loading = true;
        scope.checkVisibility = function(data){
          if(! _.has(data,'isChild')) {
            return true;
          }
          return data.show;
        };
        scope.toggleShowHide = function(item) {
            if(item.expand === false) {
                $http.get(prefix + '/listAllAssociatedAssets/'+ item.id).success(function (subAssetData,status){
                    if(status == 200){
                        item.subAssetData = subAssetData.records;
                        item.subAssetDataCount = subAssetData.totalRecords;
                        item.expand = item.expand===true ? false: true;
                        _.forEach(scope.records, function(_item,k){
                            if(_.has(_item,'parentId') && _item.parentId == item.id) {
                                scope.records[k].show = item.expand;
                            }
                        })
                    }
                })
            } else {
                item.subAssetData = [];
                item.subAssetDataCount = 0;
                item.expand = item.expand===true ? false: true;
                _.forEach(scope.records, function(_item,k){
                    if(_.has(_item,'parentId') && _item.parentId == item.id) {
                        scope.records[k].show = item.expand;
                    }
                })
            }
          //alert(item.id)
        };

        function _prepareRecords(data) {
          var items = data.records, _tree_data=[], _cols=[], expanding_property={};
          _.forEach(items, function(item){
            var subAssets = (item.subAssets != null) ? item.subAssets : null;
            var _item = {
              id: item.id,
              hasChild: !_.isEmpty(subAssets),
              expanded: false,
              subAssets: subAssets
            };
            _tree_data.push( _.extend(_item, item.lists) );
          });
          //cols mapping
          _cols = _.chain(data.colHeader)
            .map(function(col, k){
              return {
                field:k,
                displayName: col
              };
            })
            .reject(function(x){
              return x.field === 'default';
            }).value();

          expanding_property = _.find(data.colHeader, function(x, k){
            return k === 'default'
          });

          return {
            records: _tree_data,
            cols: _cols,
            expanding_property: {
              field: 'default',
              displayName: expanding_property
            }
          };
        }

        function prepareRecords (data, donotformat) {
          var tmp = [];
          if(donotformat) {
            return data;
          }
          var pcnt=0;
          _.forEach(data, function(x){
              var subAssets = (x.subAssets != null) ? x.subAssets : null;
            var obj = {};
            obj.id = x.id;
            obj.hasSubAssets = !_.isEmpty(subAssets);
            obj.subAssetsCount = obj.hasSubAssets ? _.size(subAssets) : 0;
            obj.isItems = obj.subAssetsCount===0 ? null : true;
            obj.lists = x.lists;
            obj.expand=false;
            pcnt++;
            obj.isLast = pcnt === data.length;
            tmp.push(obj);
            if(obj.hasSubAssets) {
              var cnt=0;
              _.forEach(subAssets, function(z, k){
                var subObj = {};
                subObj.id = x.id;
                subObj.parentId = x.id;
                subObj.subassetId = z.id;
                subObj.isChild=true;
                subObj.questionID=k;
                subObj.title=z.title;
                subObj.subAssetsCount=0;
                subObj.show=false;
                subObj.lists = [];
                cnt++;
                subObj.isLast=cnt===obj.subAssetsCount;
                tmp.push(subObj);
              });
            }
          });
          //console.log(tmp);
          return tmp;
        }

        if ($state.current.data.pageNum) {
            scope.pageNum = parseInt($state.current.data.pageNum);
        } else {
            scope.pageNum = 1;
        }
      scope.my_tree = tree = {};
      scope.expanding_property = "default";

      scope.my_tree_handler = function(row, index){
        //console.log(row, index)
      };

      scope.fetchRecords = function(pageNum) {
          $http.get(prefix + '/'+ scope.listUrl+ '?page=' + pageNum + '&limit=' + scope.limits).success(function (data,status){
              if(status == 200){
                  //scope.records = prepareRecords(data.records, false);
                  var _data = _prepareRecords(data);
                  //console.log('_data', _data)
                  scope.totalRecordCounts = data.totalRecordCounts;
                  scope.colHeader = data.colHeader;
                  scope.propertyId = data.propertyId;
                  scope.parentAssetId = (typeof data.parentAssetId != 'undefined')?data.parentAssetId:'';
                  scope.loading = false;
                  scope.colSpan = data.colHeader.length + 1;
                  scope.tree_data = _data.records;
                  scope.cols = _data.cols;
                  scope.expanding_property = _data.expanding_property;
              }
          })
      };
    };
    return {
        restrict: 'A',
        //scope: {},
        link: handleTreeTableView,
        templateUrl: function (elem,attr) {
            return attr.filePath;
        }
    }
});