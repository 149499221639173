'use strict';

suppliersModule.config(
    ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
        function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide){
            $stateProvider.
            state('loggedin.list_supplier.view.new_credit_note', {
                url: Routing.generateAngularRoute('new_supplier_credit_note', true),
                templateUrl: function (params) {
                    return Routing.generate('new_supplier_credit_note', params)
                },
                views: {
                    "viewSuppliersTabPane@": {
                        templateUrl: 'template/suppliers/creditnotes/new.html',
                        controller: NewSupplierCreditNoteCtrl,
                        resolve: BreadCrumbsPageLimitResolver
                    }
                },
                onEnter: function() {
                    var grand_totals_exist = localStorage.getItem('supplier:new_credit_note:spreadsheetGrandTotals') !== null;
                    if(grand_totals_exist) {
                        localStorage.removeItem('supplier:new_credit_note:spreadsheetGrandTotals');
                    }
                }
            }).state('loggedin.list_supplier.view.new_supplier_invoice_credit_note', {
                url: Routing.generateAngularRoute('new_supplier_invoice_credit_note', true),
                templateUrl: function (params) {
                    return Routing.generate('new_supplier_invoice_credit_note', params)
                },
                views: {
                    "viewSuppliersTabPane@": {
                        templateUrl: 'template/suppliers/creditnotes/new.html',
                        controller: NewSupplierCreditNoteCtrl,
                        resolve: BreadCrumbsPageLimitResolver
                    }
                },
                onEnter: function() {
                    var grand_totals_exist = localStorage.getItem('supplier:new_credit_note:spreadsheetGrandTotals') !== null;
                    if(grand_totals_exist) {
                        localStorage.removeItem('supplier:new_credit_note:spreadsheetGrandTotals');
                    }
                }
            }).
            state('loggedin.list_supplier.view.credit_note', {
                url: Routing.generateAngularRoute('view_supplier_credit_note', true),
                templateUrl: function (params) {
                    return Routing.generate('view_supplier_credit_note', params)
                },
                views: {
                    "viewSuppliersPane@": {
                        templateUrl: 'template/suppliers/creditnotes/subnav.html',
                        controller: SupplierCreditNoteCtrl,
                        resolve: BreadCrumbsPageLimitResolver
                    }
                }
            }).
            state('loggedin.list_supplier.view.credit_note.details', {
                url: Routing.generateAngularRoute('supplier_credit_note_details', true),
                templateUrl: function (params) {
                    return Routing.generate('supplier_credit_note_details', params)
                },
                views: {
                    "creditNoteTabPane@loggedin.list_supplier.view.credit_note": {
                        templateUrl: 'template/suppliers/creditnotes/view.html',
                        controller: SupplierCreditNoteDetailsCtrl,
                        resolve: BreadCrumbsPageLimitResolver
                    }
                }
            }).
            state('loggedin.list_supplier.view.credit_note.edit', {
                url: Routing.generateAngularRoute('edit_supplier_credit_note', true),
                templateUrl: function (params) {
                    return Routing.generate('edit_supplier_credit_note', params)
                },
                views: {
                    "creditNoteFormPane@loggedin.list_supplier.view.credit_note": {
                        templateUrl: 'template/suppliers/creditnotes/edit.html',
                        controller: EditSupplierCreditNoteCtrl,
                        resolve: BreadCrumbsPageLimitResolver
                    }
                },
                onEnter: function() {
                    var grand_totals_exist = localStorage.getItem('supplier:edit_credit_note:spreadsheetGrandTotals') !== null;
                    if(grand_totals_exist) {
                        localStorage.removeItem('supplier:edit_credit_note:spreadsheetGrandTotals');
                    }
                }
            }).
            state('loggedin.list_supplier.view.new_supplier_credit_note_receive_credit', {
                url: Routing.generateAngularRoute('new_supplier_credit_note_receive_credit', true),
                templateUrl: function (params) {
                    return Routing.generate('new_supplier_credit_note_receive_credit', params)
                },
                views: {
                    "viewSuppliersTabPane@": {
                        templateUrl: function (params) {
                            return Routing.generate('new_credit_note_receive_credit', params)
                        },
                        controller: NewSupplierCreditNoteReceiveCtrl,
                        resolve: BreadCrumbsPageLimitResolver
                    }
                }
            }).
            state('loggedin.list_supplier.view.credit_note.edit_receive_credit', {
                url: Routing.generateAngularRoute('edit_supplier_credit_note_receive_credit', true),
                templateUrl: function (params) {
                    return Routing.generate('edit_supplier_credit_note_receive_credit', params)
                },
                views: {
                    "creditNoteFormPane@loggedin.list_supplier.view.credit_note": {
                        templateUrl: 'template/suppliers/creditnotes/edit_receive_credit.html',
                        controller: EditSupplierCreditNoteReceiveCtrl,
                        resolve: BreadCrumbsPageLimitResolver
                    }
                }
            }).
            state('loggedin.list_supplier.view.credit_note.new_allocation', {
                url: Routing.generateAngularRoute('supplier_new_credit_note_allocation', true),
                templateUrl: function (params) {
                    return Routing.generate('supplier_new_credit_note_allocation', params)
                },
                views: {
                    "creditNoteFormPane@loggedin.list_supplier.view.credit_note": {
                        templateUrl: 'template/suppliers/creditnotes/allocation/new.html',
                        controller: SupplierNewCreditNoteAllocationCtrl,
                        resolve: BreadCrumbsPageLimitResolver
                    }
                },
                onEnter: function() {
                    var grand_totals_exist = localStorage.getItem('new_supplier_credit_allocation:spreadsheetGrandTotals') !== null;
                    if(grand_totals_exist) {
                        localStorage.removeItem('new_supplier_credit_allocation:spreadsheetGrandTotals');
                    }
                }
            }).
            state('loggedin.list_supplier.view.credit_note.edit_allocation', {
                url: Routing.generateAngularRoute('edit_supplier_credit_note_allocation', true),
                templateUrl: function (params) {
                    return Routing.generate('edit_supplier_credit_note_allocation', params)
                },
                views: {
                    "creditNoteFormPane@loggedin.list_supplier.view.credit_note": {
                        templateUrl: 'template/suppliers/creditnotes/allocation/edit.html',
                        controller: SupplierEditCreditNoteAllocationCtrl,
                        resolve: BreadCrumbsPageLimitResolver
                    }
                },
                onEnter: function() {
                    var grand_totals_exist = localStorage.getItem('new_supplier_credit_allocation:spreadsheetGrandTotals') !== null;
                    if(grand_totals_exist) {
                        localStorage.removeItem('new_supplier_credit_allocation:spreadsheetGrandTotals');
                    }
                }
            }).
            state('loggedin.list_supplier.view.new_credit_note_returns', {
                url: Routing.generateAngularRoute('new_supplier_credit_note_returns', true),
                templateUrl: function (params) {
                    return Routing.generate('new_supplier_credit_note_returns', params)
                },
                views: {
                    "viewSuppliersTabPane@": {
                        templateUrl: 'template/suppliers/creditnotes/new.html',
                        controller: NewSupplierCreditNoteCtrl,
                        resolve: BreadCrumbsPageLimitResolver
                    }
                },
                onEnter: function() {
                    var grand_totals_exist = localStorage.getItem('supplier:new_credit_note:spreadsheetGrandTotals') !== null;
                    if(grand_totals_exist) {
                        localStorage.removeItem('supplier:new_credit_note:spreadsheetGrandTotals');
                    }
                }
            });
        }]
);