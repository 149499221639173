commusoftCommon.directive('csTooltip', function ($compile, $timeout) {
    return {
        restrict: 'A',
        scope: "=",
        link: function (scope, element, attrs) {
            var options = {
                template: '<div class="tooltip {{className}}" role="tooltip">' +
                '<div class="tooltip-arrow"></div>' +
                '<div class="tooltip-inner"></div>' +
                '</div>'
            };

            var initTooltip = function(){
                $timeout(function(){
                    if ('csTooltip' in attrs) {
                        options = Object.assign(options, scope.$eval(attrs['csTooltip']));

                        if (options.html) options.title = $(element).find(options.htmlContentSelector).html();

                        if (options.className) options.template = options.template.replace('{{className}}', options.className);
                    }

                    $(element).tooltip(options);
                }, 0, false);
            };

            // Auto updating tooltip template on scope's specific variables changes
            if(scope.tooltipWatchers instanceof Array) {
                scope.tooltipWatchers.forEach(function (watcher) {
                    watcher();
                });
            }
            scope.tooltipWatchers = [];
            if('rerenderTriggers' in attrs){
                scope.$eval(attrs['rerenderTriggers']).forEach(function (trigger) {
                    scope.tooltipWatchers.push(scope.$watch(trigger, function (newValue, oldValue) {

                        if(JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                            $(element).tooltip('destroy');
                            initTooltip();
                        }
                    }))
                })
            }

            scope.$on('$destroy', function () {
              $(element).tooltip('destroy');
            });

            // Initialization
            initTooltip();
        }
    }
});