commusoftCommon.directive('csmodalduplicatecustomer', ['prefix', '$http', '$modal',function(prefix, $http, $modal) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            shouldBeOpenDuplicate: '=',
            postcode: '=',
            customers: '=',
            parentPropertyId: '='
        },
        controller: function($scope, $element, $attrs, $transclude) {
            $scope.$watch('shouldBeOpenDuplicate', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal == true) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;
                    modalInstance = $modal.open({
                        templateUrl: 'template/modal/check_duplicate_customer.html',
                        scope: $scope,
                        controller: CSDuplicateCustomerModalCtrl,
                        windowClass: 'modal with-slides large'
                    })
                }
            });
        }
    }
}]);