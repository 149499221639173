'use strict';

var suppliersModule = angular.module('suppliers', []);

suppliersModule.constant('prefix', window.prefixVal).
    constant('defaultPagingValue', defaultPaginationLimit).
    constant('excludedFileTypes', excludedFileTypes).
    provider('canLoad',function () {
        this.$get = function () {
            return {
                canLoadAjax: true,
                setLoadValue: function (canLoad) {
                    this.canLoadAjax = canLoad;
                },
                canLoad: function () {
                    return this.canLoadAjax;
                }
            }
        }
    }).
    config(
        ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
            function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {
                $stateProvider.
                    state('loggedin.suppliers', {
                        url: Routing.generateAngularRoute('suppliers', false),
                        templateUrl: function (params) {
                            return Routing.generate('suppliers')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.new_supplier', {
                        url: Routing.generateAngularRoute('new_supplier', false),
                        templateUrl: function (params) {
                            return Routing.generate('new_supplier')
                        },
                        template: globalTemplate,
                        controller: NewSuppliersCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.list_supplier', {
                        url: Routing.generateAngularRoute('list_supplier', false),
                        templateUrl: function (params) {
                            return Routing.generate('list_supplier')
                        },
                        data: {
                            'suppliersUrl': '/listAllSuppliers',
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: SupplierCtrl,
                                resolve: Resolver
                            },
                            "viewSuppliersPane@": {
                                templateUrl: 'template/suppliers/list_showsuppliers.html',
                                controller: ListSuppliersCtrl,

                                resolve: {
                                    suppliersresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.suppliersUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).state('loggedin.list_supplier.view', {
                        url: Routing.generateAngularRoute('supplierSubNav', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplierSubNav', params)
                        },
                        views: {
                            "viewSuppliersPane@": {
                                templateUrl: 'template/suppliers/supplier_layout.html',
                                controller: SupplierSubNavCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.details', {
                        url: Routing.generateAngularRoute('supplier_details', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_details', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/view_showsupplier.html',
                                controller: SupplierViewCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.details_edit', {
                        url: Routing.generateAngularRoute('supplier_details_edit', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_details_edit', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/edit_supplier.html',
                                controller: SupplierEditCtrl,

                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.contacts', {
                        url: Routing.generateAngularRoute('supplier_contacts', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_contacts', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/contacts.html',
                                controller: SupplierContactsCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.contacts_new', {
                        url: Routing.generateAngularRoute('supplier_new_contact', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_new_contact', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_contacts', params)
                                },
                                controller: SupplierNewContactsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.contacts_edit', {
                        url: Routing.generateAngularRoute('supplier_edit_contact', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_edit_contact', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/edit_contact.html',
                                controller: SupplierEditContactsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.branches', {
                        url: Routing.generateAngularRoute('supplier_branches', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_branches', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/branches/branch_view.html',
                                controller: SupplierBranchListCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.new_branch', {
                        url: Routing.generateAngularRoute('supplierDetails', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplierDetails', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_supplierbranch', params)
                                },
                                controller: CreateSupplierNewBranchCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.edit_branch', {
                        url: Routing.generateAngularRoute('edit_supplierbranch', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_supplierbranch', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/branches/branch_edit.html',
                                controller: EditSupplierBranchCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.files', {
                        url: Routing.generateAngularRoute('get_supplier_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('get_supplier_files', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/files/attached_files.html',
                                controller: GetSupplierFilesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.new_file', {
                        url: Routing.generateAngularRoute('supplier_new_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_new_files', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/files/new_file.html',
                                controller: AddSupplierFileCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).state('loggedin.list_supplier.view.notes', {
                        url: Routing.generateAngularRoute('supplier_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_note', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/notes.html',
                                controller: SupplierCommunicationsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.notes_new', {
                        url: Routing.generateAngularRoute('suppliers_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('suppliers_note', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_notes', params)
                                },
                                controller: SupplierNewNotesCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.notes_edit', {
                        url: Routing.generateAngularRoute('edit_supplier_notes', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_supplier_notes', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/edit_note.html',
                                controller: SupplierEditNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.notes.table', {
                        url: Routing.generateAngularRoute('supplier_notes', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_notes', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5,
                            'pageNumPhoneCalls': 1,
                            'limitPhoneCalls': 5,
                            'pageNumSmsMessages': 1,
                            'limitSmsMessages': 5
                        },
                        views: {
                            "": {
                                templateUrl: 'template/suppliers/tableview.html',
                                controller: SupplierNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.notes.timeline', {
                        url: Routing.generateAngularRoute('supplier_notes_timeline', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_notes_timeline', params)
                        },
                        views: {
                            "": {
                                templateUrl: 'template/suppliers/timeline.html',
                                controller: SupplierTimelineViewCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.new_phone_call', {
                        url: Routing.generateAngularRoute('supplier_new_phone_call', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_new_phone_call', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_phone_call', params)
                                },
                                controller: SupplierNewPhoneCallCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.new_sms_message', {
                        url: Routing.generateAngularRoute('supplier_new_sms_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_new_sms_message', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_sms_message', params)
                                },
                                controller: SupplierNewSmsMessageCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.new_purchase_order', {
                        url: Routing.generateAngularRoute('new_purchase_order', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_purchase_order', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/purchaseorder/new.html',
                                controller: SupplierNewPurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:new_purchase_order:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:new_purchase_order:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.purchase_order.complete_purchase_order', {
                         url: Routing.generateAngularRoute('complete_purchase_order', true),
                         templateUrl: function (params) {
                             return Routing.generate('complete_purchase_order', params)
                         },
                         views: {
                             "purchaseOrderFormPane": {
                                 templateUrl: 'template/suppliers/purchaseorder/complete.html',
                                 controller: SupplierCompletePurchaseOrderCtrl,
                                 resolve: BreadCrumbsPageLimitResolver
                             }
                         },
                         onEnter: function() {
                             var grand_totals_exist = localStorage.getItem('supplier:complete_purchase_order:spreadsheetGrandTotals') !== null;
                             if(grand_totals_exist) {
                                 localStorage.removeItem('supplier:complete_purchase_order:spreadsheetGrandTotals');
                             }
                         }
                     }).
                    state('loggedin.list_supplier.view.purchase_order.edit_purchase_order', {
                         url: Routing.generateAngularRoute('edit_purchase_order', true),
                         templateUrl: function (params) {
                             return Routing.generate('edit_purchase_order', params)
                         },
                         views: {
                             "purchaseOrderFormPane": {
                                 templateUrl: 'template/suppliers/purchaseorder/edit.html',
                                 controller: SupplierEditPurchaseOrderCtrl,
                                 resolve: BreadCrumbsPageLimitResolver
                             }
                         },
                         onEnter: function() {
                             var grand_totals_exist = localStorage.getItem('supplier:edit_purchase_order:spreadsheetGrandTotals') !== null;
                             if(grand_totals_exist) {
                                 localStorage.removeItem('supplier:edit_purchase_order:spreadsheetGrandTotals');
                             }
                         }
                     }).
                    state('loggedin.list_supplier.view.purchase_order', {
                        url: Routing.generateAngularRoute('view_purchase_order', true),
                        templateUrl: function (params) {
                            return Routing.generate('view_purchase_order', params)
                        },
                        views: {
                            "viewSuppliersPane@": {
                                templateUrl: 'template/suppliers/purchaseorder/subnav.html',
                                controller: SupplierPurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.purchase_order.details', {
                        url: Routing.generateAngularRoute('purchase_order_details', true),
                        templateUrl: function (params) {
                            return Routing.generate('purchase_order_details', params)
                        },
                        views: {
                            "purchaseOrderTabPane": {
                                templateUrl: 'template/suppliers/purchaseorder/view.html',
                                controller: SupplierPurchaseOrderDetailsCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.purchase_order.notes', {
                        url: Routing.generateAngularRoute('purchase_order_notes', true),
                        templateUrl: function (params) {
                            return Routing.generate('purchase_order_notes', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "purchaseOrderTabPane": {
                                templateUrl: 'template/suppliers/purchaseorder/notes/view.html',
                                controller: SupplierPurchaseOrderNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.purchase_order.notes.table', {
                        url: Routing.generateAngularRoute('purchase_order_notes_table', true),
                        templateUrl: function (params) {
                            return Routing.generate('purchase_order_notes_table', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5,
                            'pageNumPhoneCalls': 1,
                            'limitPhoneCalls': 5
                        },

                        views: {
                            "purchaseOrderNotesPane": {
                                templateUrl: 'template/suppliers/purchaseorder/notes/tabular.html',
                                controller: SupplierPurchaseOrderNotesTableCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.purchase_order.notes.timeline', {
                        url: Routing.generateAngularRoute('purchase_order_notes_timeline', true),
                        templateUrl: function (params) {
                            return Routing.generate('purchase_order_notes_timeline', params)
                        },
                        views: {
                            "purchaseOrderNotesPane": {
                                templateUrl: 'template/suppliers/purchaseorder/notes/timeline.html',
                                controller: SupplierPurchaseOrderNotesTimelineCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.purchase_order.notes.new', {
                        url: Routing.generateAngularRoute('purchase_order_new_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('purchase_order_new_note', params)
                        },
                        views: {
                            "purchaseOrderFormPane@loggedin.list_supplier.view.purchase_order": {
                                template: globalTemplate,
                                controller: SupplierPurchaseOrderNotesNewCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.purchase_order.notes.newphonecall', {
                        url: Routing.generateAngularRoute('purchase_order_new_phonecall', true),
                        templateUrl: function (params) {
                            return Routing.generate('purchase_order_new_phonecall', params)
                        },
                        views: {
                            "purchaseOrderFormPane@loggedin.list_supplier.view.purchase_order": {
                                template: globalTemplate,
                                controller: SupplierPurchaseOrderNotesNewCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.purchase_order.notes.edit', {
                        url: Routing.generateAngularRoute('purchase_order_notes_edit', true),
                        templateUrl: function (params) {
                            return Routing.generate('purchase_order_notes_edit', params)
                        },
                        views: {
                            "purchaseOrderFormPane@loggedin.list_supplier.view.purchase_order": {
                                templateUrl: 'template/suppliers/edit_note.html',
                                controller: SupplierPurchaseOrderEditNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.purchase_order.purchaseorderfiles', {
                        url: Routing.generateAngularRoute('get_purchaseorder_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('get_purchaseorder_files', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "purchaseOrderFormPane": {
                                templateUrl: 'template/suppliers/purchaseorder/files/attached_files.html',
                                controller: GetPurchaseOrderFilesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.purchase_order.new_purchaseorder_files', {
                        url: Routing.generateAngularRoute('purchaseorder_new_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('purchaseorder_new_files', params)
                        },
                        views: {
                            "purchaseOrderFormPane": {
                                templateUrl: 'template/suppliers/purchaseorder/files/new_file.html',
                                controller: AddPurchaseOrderFileCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.new_supplier_invoice', {
                        url: Routing.generateAngularRoute('new_supplier_invoice', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_supplier_invoice', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/invoice/new.html',
                                controller: NewSupplierInvoiceCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:new_invoice:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:new_invoice:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice', {
                        url: Routing.generateAngularRoute('view_supplier_invoice', true),
                        templateUrl: function (params) {
                            return Routing.generate('view_supplier_invoice', params)
                        },
                        views: {
                            "viewSuppliersPane@": {
                                templateUrl: 'template/suppliers/invoice/subnav.html',
                                controller: SupplierInvoiceCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice.details', {
                        url: Routing.generateAngularRoute('supplier_invoice_details', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_invoice_details', params)
                        },
                        views: {
                            "invoiceTabPane@loggedin.list_supplier.view.invoice": {
                                templateUrl: 'template/suppliers/invoice/view.html',
                                controller: SupplierInvoiceDetailsCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice.edit', {
                        url: Routing.generateAngularRoute('edit_supplier_invoice', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_supplier_invoice', params)
                        },
                        views: {
                            "invoiceFormPane@loggedin.list_supplier.view.invoice": {
                                templateUrl: 'template/suppliers/invoice/edit.html',
                                controller: EditSupplierInvoiceCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:edit_invoice:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:edit_invoice:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice.notes', {
                        url: Routing.generateAngularRoute('supplier_invoice_notes', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_invoice_notes', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "invoiceTabPane": {
                                templateUrl: 'template/suppliers/invoice/notes/view.html',
                                controller: SupplierInvoiceNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice.notes.table', {
                        url: Routing.generateAngularRoute('supplier_invoice_notes_table', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_invoice_notes_table', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5,
                            'pageNumPhoneCalls': 1,
                            'limitPhoneCalls': 5
                        },

                        views: {
                            "invoiceNotesPane": {
                                templateUrl: 'template/suppliers/invoice/notes/tabular.html',
                                controller: SupplierInvoiceNotesTableCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice.notes.timeline', {
                        url: Routing.generateAngularRoute('supplier_invoice_notes_timeline', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_invoice_notes_timeline', params)
                        },
                        views: {
                            "invoiceNotesPane": {
                                templateUrl: 'template/suppliers/invoice/notes/timeline.html',
                                controller: SupplierInvoiceNotesTimelineCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice.notes.new', {
                        url: Routing.generateAngularRoute('supplier_invoice_new_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_invoice_new_note', params)
                        },
                        views: {
                            "invoiceFormPane@loggedin.list_supplier.view.invoice": {
                                template: globalTemplate,
                                controller: SupplierInvoiceNotesNewCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice.notes.edit', {
                        url: Routing.generateAngularRoute('supplier_invoice_notes_edit', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_invoice_notes_edit', params)
                        },
                        views: {
                            "invoiceFormPane@loggedin.list_supplier.view.invoice": {
                                templateUrl: 'template/suppliers/edit_note.html',
                                controller: SupplierInvoiceEditNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice.notes.new_sms_message', {
                        url: Routing.generateAngularRoute('supplier_invoice_new_sms', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_invoice_new_sms', params)
                        },
                        views: {
                            "invoiceFormPane@loggedin.list_supplier.view.invoice": {
                                template: globalTemplate,
                                controller: SupplierInvoiceNewSmsMessageCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice.notes.newphonecall', {
                        url: Routing.generateAngularRoute('supplier_invoice_new_phonecall', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_invoice_new_phonecall', params)
                        },
                        views: {
                            "invoiceFormPane@loggedin.list_supplier.view.invoice": {
                                template: globalTemplate,
                                controller: SupplierInvoiceNotesNewCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.new_supplier_invoice_payment', {
                        url: Routing.generateAngularRoute('new_supplier_invoice_payment', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_supplier_invoice_payment', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_invoice_payment', params)
                                },
                                controller: NewSupplierInvoicePaymentCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice.view_payment', {
                        url: Routing.generateAngularRoute('supplier_invoice_payment_details', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_invoice_payment_details', params)
                        },
                        views: {
                            "invoiceFormPane@loggedin.list_supplier.view.invoice": {
                                templateUrl: 'template/suppliers/invoice/view_payment.html',
                                controller: SupplierInvoicePaymentDetailsCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            },
                            "invoiceTabPane@loggedin.list_supplier.view.invoice": {
                                template: ''
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice.view_payment.edit', {
                        url: Routing.generateAngularRoute('edit_supplier_invoice_payment', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_supplier_invoice_payment', params)
                        },
                        views: {
                            "invoiceFormPane@loggedin.list_supplier.view.invoice": {
                                templateUrl: 'template/suppliers/cash_allocation/edit.html',
                                controller: SupplierPaymentEditCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            },
                            "invoiceTabPane@loggedin.list_supplier.view.invoice": {
                                template: ''
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:new_cash_allocation:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:new_cash_allocation:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.new_cash_allocation', {
                        url: Routing.generateAngularRoute('new_cash_allocation', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_cash_allocation', params)
                        },
                        views: {
                            "viewSuppliersTabPane@": {
                                templateUrl: 'template/suppliers/cash_allocation/new.html',
                                controller: SupplierCashAllocationCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:new_cash_allocation:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:new_cash_allocation:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.credit_note.files', {
                        url: Routing.generateAngularRoute('supplier_credit_note_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_credit_note_files', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "creditNoteTabPane@loggedin.list_supplier.view.credit_note": {
                                templateUrl: 'template/suppliers/creditnotes/files/attached_files.html',
                                controller: SupplierCreditNoteFilesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.credit_note.new_creditnote_files', {
                        url: Routing.generateAngularRoute('new_creditnote_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_creditnote_files', params)
                        },
                        views: {
                            "creditNoteTabPane@loggedin.list_supplier.view.credit_note": {
                                templateUrl: 'template/suppliers/creditnotes/files/new_file.html',
                                controller: AddCreditNoteFileCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
					state('loggedin.list_supplier.view.invoice.files', {
                        url: Routing.generateAngularRoute('get_supplier_invoice_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('get_supplier_invoice_files', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "invoiceFormPane@loggedin.list_supplier.view.invoice": {
                                templateUrl: 'template/suppliers/invoice/files/attached_files.html',
                                controller: GetSupplierInvoiceFilesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.invoice.new_file', {
                        url: Routing.generateAngularRoute('supplier_invoice_new_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_invoice_new_files', params)
                        },
                        views: {
                            "invoiceFormPane@loggedin.list_supplier.view.invoice": {
                                templateUrl: 'template/suppliers/invoice/files/new_file.html',
                                controller: AddSupplierInvoiceFileCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.credit_note.notes', {
                        url: Routing.generateAngularRoute('credit_note_notes', true),
                        templateUrl: function (params) {
                            return Routing.generate('credit_note_notes', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "creditNoteTabPane@loggedin.list_supplier.view.credit_note": {
                                templateUrl: 'template/suppliers/creditnotes/notes/view.html',
                                controller: SupplierCreditNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.credit_note.notes.table', {
                        url: Routing.generateAngularRoute('credit_note_notes_table', true),
                        templateUrl: function (params) {
                            return Routing.generate('credit_note_notes_table', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5,
                            'pageNumPhoneCalls': 1,
                            'limitPhoneCalls': 5,
                            'pageNumSmsMessages': 1,
                            'limitSmsMessages': 5
                        },

                        views: {
                            "creditNotesCommunicationPane": {
                                templateUrl: 'template/suppliers/creditnotes/notes/tabular.html',
                                controller: SupplierCreditNotesTableCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.credit_note.notes.timeline', {
                        url: Routing.generateAngularRoute('credit_note_notes_timeline', true),
                        templateUrl: function (params) {
                            return Routing.generate('credit_note_notes_timeline', params)
                        },
                        views: {
                            "creditNotesCommunicationPane": {
                                templateUrl: 'template/suppliers/creditnotes/notes/timeline.html',
                                controller: SupplierCreditNotesTimelineCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.credit_note.notes.new', {
                        url: Routing.generateAngularRoute('credit_note_new_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('credit_note_new_note', params)
                        },
                        views: {
                            "creditNoteTabPane@loggedin.list_supplier.view.credit_note": {
                                template: globalTemplate,
                                controller: SupplierCreditNotesNewCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.credit_note.notes.edit', {
                        url: Routing.generateAngularRoute('credit_note_notes_edit', true),
                        templateUrl: function (params) {
                            return Routing.generate('credit_note_notes_edit', params)
                        },
                        views: {
                            "creditNoteTabPane@loggedin.list_supplier.view.credit_note": {
                                templateUrl: 'template/suppliers/edit_note.html',
                                controller: SupplierCreditNotesEditNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.credit_note.notes.newphonecall', {
                        url: Routing.generateAngularRoute('credit_note_new_phonecall', true),
                        templateUrl: function (params) {
                            return Routing.generate('credit_note_new_phonecall', params)
                        },
                        views: {
                            "creditNoteTabPane@loggedin.list_supplier.view.credit_note": {
                                template: globalTemplate,
                                controller: SupplierCreditNotesNewCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.list_supplier.view.credit_note.new_sms_message', {
                        url: Routing.generateAngularRoute('credit_note_new_sms', true),
                        templateUrl: function (params) {
                            return Routing.generate('credit_note_new_sms', params)
                        },
                        views: {
                            "creditNoteTabPane@loggedin.list_supplier.view.credit_note": {
                                template: globalTemplate,
                                controller: SupplierCreditNotesNewSmsMessageCtrl,
                                resolve: Resolver
                            }
                        }
                    });
            }
        ]);
