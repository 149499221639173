csmodule.service("spreadSheetShortcutRefs", function(spreadSheetRefsHelper) {
  this.initialize = function initialize(rows) {
    this.spreadsheet_rows = rows;
    this.left_to_right_refs = [];
    this.giveCellsIndexRefs();
    this.top_to_bottom_refs = _.clone(this.left_to_right_refs);
    this.handleTopToBottomRefs();
    this.setShortcutRefs();
  }

  this.giveCellsIndexRefs = function giveCellsIndexRefs() {
    for(var i = 0, l = this.spreadsheet_rows.length; i < l; i++) {
      var rows = this.spreadsheet_rows[i].row[0].associated_rows;

      for(var x = 0, s = rows.length; x < s; x++) {
        var row = rows[x];
        for(var y = 0, c = row.length; y < c; y ++) {
          cell = row[y];
          cell.index_ref = "[" + i + "].row" + "[0].associated_rows" + "[" + x + "][" + y + "]";
          this.handleLeftToRightRefs(cell)
        }
      }
    }
  }

  this.handleLeftToRightRefs = function handleLeftToRightRefs(cell) {
    if(cell.state !== "not_selectable") {
      this.left_to_right_refs.push(cell.index_ref);
    }
  }

  this.handleTopToBottomRefs = function handleTopToBottomRefs() {
    this.top_to_bottom_refs.sort(function(a, b) {
      var condition_1 = parseInt(a.match(/\d/g)[3]) - parseInt(b.match(/\d/g)[3]),
          condition_2 = parseInt(a.match(/\d/g)[0]) - parseInt(b.match(/\d/g)[0]),
          condition_3 = parseInt(a.match(/\d/g)[2]) - parseInt(b.match(/\d/g)[2]);

      if(condition_1 !== 0) {
        return condition_1;
      }else if (condition_2 !== 0) {
        return condition_2;
      }else {
        return condition_3;
      }
    });
  }

  this.setShortcutRefs = function setShortcutRefs() {
    for(var i = 0, l = this.left_to_right_refs.length; i < l; i++) {
      var cell = spreadSheetRefsHelper.getCellFromStrIndex(this.spreadsheet_rows, this.left_to_right_refs[i]);
          left_ref_index = i - 1,
          right_ref_index = i + 1,
          top_ref_index = this.top_to_bottom_refs.indexOf(this.left_to_right_refs[i]) - 1,
          bottom_ref_index = this.top_to_bottom_refs.indexOf(this.left_to_right_refs[i]) + 1;

      cell.left_ref = this.left_to_right_refs[left_ref_index] || "NA";
      cell.right_ref = this.left_to_right_refs[right_ref_index] || "NA";
      cell.up_ref = this.top_to_bottom_refs[top_ref_index] || "NA";
      cell.down_ref = this.top_to_bottom_refs[bottom_ref_index] || "NA";
    }
  }
});