var FC = $.fullCalendar;
var View = FC.ResourceAgendaView;
var TimelineView = FC.TimelineView;
var DayGrid = $.fullCalendar.DayGrid;
var agendaView;

var CustomDayGrid = $.fullCalendar.DayGrid.extend({
    rowIndex: 0,
    updateDayTable: function() {
        var view = this.view;
        var calendar = view.calendar;
        var date = calendar.msToUtcMoment(this.dateProfile.renderUnzonedRange.startMs, true);
        this.dayDates = [];
        for(var i=0;i<this.computeColCnt() * 2;i++) {
            this.dayDates.push(date.clone())
        }
        //this.dayDates = [date, date, date, date, date];
        this.dayIndices = [0];
        this.daysPerRow = 1;
        this.rowCnt = 2;
        this.updateDayTableCols();
    },
    computeColCnt: function() {
        if (this.view.name == 'agendaWeek') {
            return 7;
        }
        // This is for Day View (Based on number of resources)
        return this.view.calendar.resourceManager.topLevelResources.length;
    },
    getIsNumbersVisible: function(row) {
        return false;
    },
    getSafeHitFootprint: function (t) {
        var e = this.dateProfile.activeUnzonedRange;
        return {
            unzonedRange: e,
            isAllDay: true,
            resourceId: this.view.calendar.resourceManager.topLevelResources[t.col].id
        }
    },
    getHitFootprint1: function(t) {
        var e = this.getCellRange(t.row, t.col);
        return {
            unzonedRange: e,
            isAllDay: true,
            resourceId: this.view.calendar.resourceManager.topLevelResources[t.col].id
        }
    }
});


var allDayHtml = function() {
    if (this.dayGrid.rowIndex == 0) {
        this.dayGrid.rowIndex = 1;
        return 'am';
    }
    this.dayGrid.rowIndex = 0;  // Toggle for next time around
    return "pm";
};


var getColDayIndex = function (col) {
    if (this.isRTL) {
        col = this.colCnt - 1 - col;
    }
    if (this.datesAboveResources) {
        return Math.floor(col / (this.resourceCnt || 1));
    }
    if (this.view.name == 'agendaDay') {
        return col % this.daysPerRow;
    } else {
        return col;
    }
}
var updateGridDates = function () {
    var snapIndex = -1;
    var snapDiff = 0; // index of the diff :(
    var snapDiffToIndex = [];
    var snapIndexToDiff = [];
    if(this.type === 'timelineDay'){
    var snapIndex = 1;
    var AM = this.normalizedUnzonedStart.clone();
    var PM = this.normalizedUnzonedStart.clone();
    this.slotDates.splice(0,0,AM,PM); 
    snapIndexToDiff.push("am");
    snapIndexToDiff.push("pm");
    }
    var date = this.normalizedUnzonedStart.clone();
    while (date < this.normalizedUnzonedEnd) {
        if (this.isValidDate(date)) {
            snapIndex++;
            snapDiffToIndex.push(snapIndex);
            snapIndexToDiff.push(snapDiff);
        }
        else {
            snapDiffToIndex.push(snapIndex + 0.5);
        }
        date.add(this.snapDuration);
        snapDiff++;
    }
    if(this.type === 'timelineDay'){
    snapDiffToIndex.push(snapDiff + 2)
    }
    this.snapDiffToIndex = snapDiffToIndex;
    this.snapIndexToDiff = snapIndexToDiff;
    this.snapCnt = snapIndex +1; // is always one behind
    this.slotCnt = this.snapCnt / this.snapsPerSlot;
};
var renderSlatHtml = function () {
    var cell;
    var date;
    var rowCells;
    var format;
    var theme = this.calendar.theme;
    var labelInterval = this.labelInterval;
    var formats = this.headerFormats;
    var cellRows = formats.map(function (format) { return []; }); // indexed by row,col
    var leadingCell = null;
    var prevWeekNumber = null;
    var slotDates = this.slotDates;
    var slotCells = []; // meta
    var rowUnits = formats.map(function (format) { return (FC.queryMostGranularFormatUnit(format)); });
    for (var _i = 0, slotDates_1 = slotDates; _i < slotDates_1.length; _i++) {
        date = slotDates_1[_i];
        var weekNumber = date.week();
        var isWeekStart = this.emphasizeWeeks && (prevWeekNumber !== null) && (prevWeekNumber !== weekNumber);
        for (var row = 0; row < formats.length; row++) {
            format = formats[row];
            rowCells = cellRows[row];
            leadingCell = rowCells[rowCells.length - 1];
            var isSuperRow = (formats.length > 1) && (row < (formats.length - 1)); // more than one row and not the last
            var newCell = null;
            if (isSuperRow) {
                var text = date.format(format);
                if (!leadingCell || (leadingCell.text !== text)) {
                    newCell = this.buildCellObject(date, text, rowUnits[row]);
                }
                else {
                    leadingCell.colspan += 1;
                }
            }
            if((this.type === 'timelineDay') && (_i === 0 || _i === 1)){
                if(_i === 0){
                    newCell = this.buildCellObject(date, "AM", rowUnits[row]);
                }
                else if(_i === 1){
                    date.add(4.32e+7);
                    newCell = this.buildCellObject(date, "PM", rowUnits[row]);
                    }
            }
            else {
                if (!leadingCell || FC.isInt(FC.divideRangeByDuration(this.normalizedUnzonedStart, date, labelInterval))) {
                    var text = date.format(format);
                    newCell = this.buildCellObject(date, text, rowUnits[row]);
                }
                else {
                    leadingCell.colspan += 1;
                }
            }
            if (newCell) {
                newCell.weekStart = isWeekStart;
                rowCells.push(newCell);
            }
        }
        slotCells.push({ weekStart: isWeekStart });
        prevWeekNumber = weekNumber;
    }
    var isChrono = labelInterval > this.slotDuration;
    var isSingleDay = this.slotDuration.as('days') === 1;
    var html = '<table class="' + theme.getClass('tableGrid') + '">';
    html += '<colgroup>';
    for (var _a = 0, slotDates_2 = slotDates; _a < slotDates_2.length; _a++) {
        date = slotDates_2[_a];
        html += '<col/>';
    }
    html += '</colgroup>';
    html += '<tbody>';
    for (var i = 0; i < cellRows.length; i++) {
        rowCells = cellRows[i];
        var isLast = i === (cellRows.length - 1);
        html += '<tr' + (isChrono && isLast ? ' class="fc-chrono"' : '') + '>';
        for (var _b = 0, rowCells_1 = rowCells; _b < rowCells_1.length; _b++) {
            cell = rowCells_1[_b];
            var headerCellClassNames = [theme.getClass('widgetHeader')];
            if (cell.weekStart) {
                headerCellClassNames.push('fc-em-cell');
            }
            if (isSingleDay) {
                headerCellClassNames = headerCellClassNames.concat(this.getDayClasses(cell.date, true) // adds "today" class and other day-based classes
                );
            }
            html +=
            '<th class="' + headerCellClassNames.join(' ') + '"' +
                ' data-date="' + cell.date.format() + '"' +
                (cell.colspan > 1 ? ' colspan="' + cell.colspan + '"' : '') +
                '>' +
                '<div class="fc-cell-content">' +
                cell.spanHtml +
                '</div>' +
                '</th>';
        }
        html += '</tr>';
    }
    html += '</tbody></table>';
    var slatHtml = '<table class="' + theme.getClass('tableGrid') + '">';
    slatHtml += '<colgroup>';
    for (var _c = 0, slotCells_1 = slotCells; _c < slotCells_1.length; _c++) {
        cell = slotCells_1[_c];
        if (_c == 1 && this.type === 'timelineDay') {
            slatHtml += '<col class = "PM_Double"/>';
        } else {
            slatHtml += '<col/>';
        }
    }
    slatHtml += '</colgroup>';
    slatHtml += '<tbody><tr>';
    for (var i = 0; i < slotCells.length; i++) {
        cell = slotCells[i];
        date = slotDates[i];
        slatHtml += this.slatCellHtml(date, cell.weekStart);
    }
    slatHtml += '</tr></tbody></table>';
    return { headHtml: html, bodyHtml: slatHtml };
}
var getSnapUnzonedRange = function (snapIndex) {
    var end;
    var start = this.normalizedUnzonedStart.clone();
    var dummy = this.normalizedUnzonedStart.clone();
    if (this.snapIndexToDiff[snapIndex] === "am" || this.snapIndexToDiff[snapIndex] === "pm") {
        if (this.snapIndexToDiff[snapIndex] === "pm") {
            start = start.clone().add(4.32e+7);
        }
        end = start.clone().add(4.32e+7);
    }
    else {
        start.add(FC.multiplyDuration(this.snapDuration, this.snapIndexToDiff[snapIndex]));
        end = start.clone().add(this.snapDuration);
    }
    return new FC.UnzonedRange(start, end);
};
var getCellDayIndex = function (row, col) {
    if (row === 1) {
        this.rowIndex = 1;
    } else {
        this.rowIndex = 0;
    }
    if (row === 1 && this.view.name == 'agendaDay') {

        return 1;
    }
    return row * this.daysPerRow + this.getColDayIndex(col);
};

var getDateDayIndex = function (date) {
    var dayIndices = this.dayIndices;
    var dayOffset = date.diff(this.dayDates[0], 'days');
    if (this.rowIndex === 1 && this.view.name == 'agendaDay') {
        dayOffset = dayOffset + 7;
    }
    if (dayOffset < 0) {
        return dayIndices[0] - 1;
    }
    else if (dayOffset >= dayIndices.length) {
        return dayIndices[dayIndices.length - 1] + 1;
    }
    else {
        return dayIndices[dayOffset];
    }
}
var rangeToCoords = function (range) {
    var coordCache = this.slatInnerCoordCache;

    if (range.extra == 'am') {
        var left = coordCache.getLeftPosition(0) + (coordCache.getWidth(0) * 0);
        var right = coordCache.getLeftPosition(0) + (coordCache.getWidth(0) * 1);
        return { left: left, right: right }
    } else if (range.extra == 'pm') {
        var left = coordCache.getLeftPosition(1) + (coordCache.getWidth(1) * 0);
        var right = coordCache.getLeftPosition(1) + (coordCache.getWidth(1) * 1);
        return { left: left, right: right }
    } else if (range.extra == 'allday') {
        var left = coordCache.getLeftPosition(0) + (coordCache.getWidth(0) * 0);
        var right = coordCache.getLeftPosition(1) + (coordCache.getWidth(1) * 1);
        return { left: left, right: right }
    }
    if (this.isRTL) {
        return { right: this.dateToCoord(range.start), left: this.dateToCoord(range.end) };
    }
    else {
        return { left: this.dateToCoord(range.start), right: this.dateToCoord(range.end) };
    }
};
var componentFootprintToSegs = function (footprint) {
    var footprintStart = footprint.unzonedRange.getStart();
    var footprintEnd = footprint.unzonedRange.getEnd();
    var normalFootprint = this.normalizeComponentFootprint(footprint);
    var segs = [];
    var seg1 = this.computeDateSnapCoverage(footprintStart);
    var seg2 = this.computeDateSnapCoverage(footprintEnd);
    // protect against when the span is entirely in an invalid date region
    if (this.computeDateSnapCoverage(footprintStart) < this.computeDateSnapCoverage(footprintEnd)) {
        // intersect the footprint's range with the grid'd range
        var segRange = normalFootprint.unzonedRange.intersect(this.normalizedUnzonedRange);
        if (segRange) {
            var segStart = segRange.getStart();
            var segEnd = segRange.getEnd();
            if (seg1 == 2 && seg2 == 26) {
                segs.push({
                    start: segStart,
                    end: segEnd,
                    isStart: segRange.isStart && this.isValidDate(segStart),
                    isEnd: segRange.isEnd && this.isValidDate(segEnd.clone().subtract(1)),
                    extra: 'am'
                });
            } else if (seg1 == 26 && seg2 == 50) {
                segs.push({
                    start: segStart,
                    end: segEnd,
                    isStart: segRange.isStart && this.isValidDate(segStart),
                    isEnd: segRange.isEnd && this.isValidDate(segEnd.clone().subtract(1)),
                    extra: 'pm'
                });
            } else if (seg1 == 2 && (seg2 == 50 || seg2 == 49.99999944444444)) {
                segs.push({
                    start: segStart,
                    end: segEnd,
                    isStart: segRange.isStart && this.isValidDate(segStart),
                    isEnd: segRange.isEnd && this.isValidDate(segEnd.clone().subtract(1)),
                    extra: 'allday'
                });
            } 
            else {
                segs.push({
                    start: segStart,
                    end: segEnd,
                    isStart: segRange.isStart && this.isValidDate(segStart),
                    isEnd: segRange.isEnd && this.isValidDate(segEnd.clone().subtract(1))
                });
            }
        }
    }
    // TODO: what if month slots? should round it to nearest month
    // TODO: dragging/resizing in this situation? deltas for dragging/resizing breaks down
    return segs;
};

var getSafeHitFootprint = function (hit) {
    var footprint = this.getHitFootprint(hit);

    if (!this.dateProfile.activeUnzonedRange.containsRange(footprint.unzonedRange)) {
        return null;
    }
    if(hit.component.name === 'timelineDay'){
        if (hit.snap === 1) {
            footprint.unzonedRange.extra = 'pm';
            footprint.isAllDay = 'pm';
            return footprint;
        }
        else if (hit.snap === 0 ) {
            footprint.unzonedRange.extra = 'am';
            footprint.isAllDay = 'am';
            return footprint;
        }
    }
    else if(hit.component.view && (hit.component.view.name === 'agendaDay' || hit.component.view.name === 'agendaWeek')){
        if ( hit.row === 1) {
            footprint.unzonedRange.extra = 'pm';
            footprint.isAllDay = 'pm';
            return footprint;
        }
        else if ( hit.row === 0) {
            footprint.unzonedRange.extra = 'am';
            footprint.isAllDay = 'am';
            return footprint;
        }
    }
    return footprint;
};

// TimelineView changes

FC.DateComponent.prototype.getSafeHitFootprint = getSafeHitFootprint;
TimelineView.prototype.getSnapUnzonedRange = getSnapUnzonedRange;
TimelineView.prototype.renderSlatHtml = renderSlatHtml;
TimelineView.prototype.updateGridDates = updateGridDates;
TimelineView.prototype.rangeToCoords = rangeToCoords;
TimelineView.prototype.componentFootprintToSegs = componentFootprintToSegs;

// AgendaView changes




// DayGrid.prototype.getCellDayIndex = getCellDayIndex;
// DayGrid.prototype.getDateDayIndex = getDateDayIndex;


// View.prototype.dayGridClass = CustomDayGrid;
// View.prototype.getAllDayHtml = allDayHtml;
// FC.AgendaView.prototype.dayGridClass = CustomDayGrid;
// FC.AgendaView.prototype.getAllDayHtml = allDayHtml;