csmodule.directive('countdown', [ '$interval','$state',
    function ($interval,$state) {
        return {
            restrict: 'A',
            scope: {
                date: '@'
            },
            link: function (scope, element) {
                function countDownDayHourMinutes (t) {
                    var days,
                        hours,
                        minutes,
                        seconds;
                    days = Math.floor(t / 86400);
                    t -= days * 86400;
                    hours = Math.floor(t / 3600) % 24;
                    t -= hours * 3600;
                    minutes = Math.floor(t / 60) % 60;
                    t -= minutes * 60;
                    seconds = t % 60;
                    var dayvalues = (days==0)?'':days +' d ';
                    var hourvalues = (hours==0 && days==0)?'':(hours==0)?'':hours +' hr ';
                    var minutesvalues = (days==0 && hours==0 && minutes ==0 )?'':minutes +' mins ';

                    return [dayvalues,
                        hourvalues ,
                        minutesvalues ,
                        seconds + ' secs'].join(' ');
                }
                var future;
                var interval= null;

                if($state.current.name == 'loggedin.customer_list.view.job.details' || $state.current.name=='loggedin.customer_list.view.property'
                    || $state.current.name == 'loggedin.reporting.view'){
                    var future;
                    if (scope.date == '') {
                        scope.date = null;
                        return  element.text('');
                    }

                    interval = $interval(function () {
                            var diff;

                            var timeDiff = moment.utc(scope.date).valueOf()- moment.utc(new Date()).valueOf();
                            if (timeDiff <= 0) {
                                return  element.text('SLA Breached');
                            }
                            diff = Math.floor((moment.utc(scope.date).valueOf() - moment.utc(new Date()).valueOf()) / 1000);
                            return element.text(countDownDayHourMinutes(diff));
                        },
                        1000);
                }else{
                    interval=null;
                }

            } };

    }]);