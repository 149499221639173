'use strict';

var reportingModule = angular.module('reporting', ['gridster', 'ui.bootstrap', 'ui.bootstrap.datetimepicker']);

reportingModule.constant('prefix', window.prefixVal).
    constant('defaultPagingValue', defaultPaginationLimit).
    provider('canLoad',function () {
        this.$get = function () {
            return {
                canLoadAjax: true,
                setLoadValue: function (canLoad) {
                    this.canLoadAjax = canLoad;
                },
                canLoad: function () {
                    return this.canLoadAjax;
                }
            }
        }
    }).
    config(
        ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
            function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {

                $stateProvider.
                    state('loggedin.reporting', {
                        url: Routing.generateAngularRoute('reporting', true),           //  ^/reporting
                        templateUrl: function (params) {
                            return Routing.generate('reporting')                        //  /frontend_dev.php/reporting
                        },
                        template: globalTemplate,
                        controller: ReportingCtrl,
                        resolve: Resolver
                    }).state('loggedin.reporting.view', {
                        url: Routing.generateAngularRoute('reporting_view', true) + '?flag',      //  ^/reporting/view/{category}/{subcategory}
                        templateUrl: function (params) {
                            return Routing.generate('reporting_view', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 20,
                        },
                        views: {
                            "reportPane@": {
                                templateUrl: 'template/reporting/view.html',
                                controller: ReportingViewCtrl,
                                resolve: ReportResolver
                            }
                        }
                    }).state('loggedin.reporting.view.summary' ,{
                        url: Routing.generateAngularRoute('view_summary'),
                        templateUrl: function (params){
                            return Routing.generate('view_summary', params)
                        },
                        views: {
                            "summaryPane@": {
                                templateUrl:'template/reporting/summary_view.html',
                                controller: summaryViewCtrl,
                                resolve: Resolver
                            }
                        }
                    }).state('loggedin.reporting.operational' ,{
                        url: Routing.generateAngularRoute('parts_operational_list_view', true),
                        templateUrl: function (params) {
                            return Routing.generate('parts_operational_list_view', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 20
                        },
                        views: {
                            "reportPane@": {
                                templateUrl: 'template/reporting/operational_list.html',
                                controller: ReportingViewCtrl,
                                resolve: ReportResolver
                            }
                        }
                    }).state('loggedin.reporting.operational.stocks' ,{
                        url: Routing.generateAngularRoute('stock_returns_operational_list_view', true),
                        templateUrl: function (params) {
                            return Routing.generate('stock_returns_operational_list_view', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 20
                        },
                        views: {
                            "reportPane@": {
                                templateUrl: 'template/reporting/operational_list.html',
                                controller: ReportingViewCtrl,
                                resolve: Resolver
                            }
                        }
                    }).state('loggedin.reporting.operational.incomplete_part_completed_closed_jobs' ,{
                        url: Routing.generateAngularRoute('parts_operational_list_view', true),
                        templateUrl: function (params) {
                            return Routing.generate('parts_operational_list_view', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 20
                        },
                        views: {
                            "reportPane@": {
                                templateUrl: 'template/reporting/operational_list.html',
                                controller: ReportingViewCtrl,
                                resolve: ReportResolver
                            }
                        }
                    }).state('loggedin.reporting.dashboard', {
                        url:Routing.generateAngularRoute('reporting_dashboard', true),
                        templateUrl:function (){
                            return Routing.generate('reporting_dashboard')
                        },
                        views:{
                            "reportPane@":{
                                    templateUrl: 'template/reporting/dashboard.html',
                                    controller: DashboardCtrl,
                                    resolve: Resolver
                            }
                        }
                    }).state('loggedin.reporting.user_driving_profile', {
                        url: '/user/:userId/driving_profile?date',
                        templateUrl: function (params){
                            return Routing.generate('user_driving_profile', params)
                        },
                        views: {
                            "reportPane@": {
                                templateUrl: 'template/reporting/advanced/user_driving_profile_view.html',
                                controller: UserDrivingProfileViewCtrl,
                                resolve: Resolver
                            }
                        }
                }).state('loggedin.reporting.outstanding_jobs' ,{
                    url: Routing.generateAngularRoute('out_standing_job_view_list', true),
                    templateUrl: function (params) {
                        return Routing.generate('out_standing_job_view_list', params)
                    },
                    data: {
                        'pageNum': 1,
                        'limit': 20
                    },
                    views: {
                        "reportPane@": {
                            templateUrl: 'template/reporting/outstanding_jobs.html',
                            controller: OutStandingJobViewCtrl,
                            resolve: OutStandingJobViewResolver
                        }
                    }
                }).state('loggedin.reporting.schedule_view' ,{
                        url: Routing.generateAngularRoute('schedule_view', true),
                        templateUrl: function (params) {
                            return Routing.generate('schedule_view', params)
                        },
                        views: {
                            "reportPane@": {
                                templateUrl: 'template/reporting/schedule_view.html',
                                controller: ScheduleViewCtrl,
                                resolve: Resolver
                            }
                        }
                    });
            }
        ]);
