csmodule.directive('reportingFilterBar', ['prefix', '$http', '$modal', 'tableCollection', '$document', '$timeout', 'clickEvents', '$rootScope', '$compile', 'dragHelper', '$state', function(prefix, $http, $modal, tableCollection, $document, $timeout, clickEvents, $rootScope, $compile, dragHelper, $state) {
	return {
		restrict: 'ACE',
		link: function(scope, elem, attrs) {
			scope.reportingCategory = attrs.category;
			scope.input_templates;
			scope.quick_add_panel;
			scope.panel_options = attrs;
			scope.is_title_bar_link = false;
			scope.elm_supports_state = false;
			scope.save_form = true;
			scope.fatal_error = false;
			scope.loading = false;
			scope.endpoint = attrs.endpoint;
			scope.input_models = [];
			scope.just_postcode = attrs.justPostcode || false;
			scope.reportingFilters = [];

			scope.$on('panel_with_form:filter:' + scope.reportingCategory, function(evt, filters) {
				scope.reportingFilters = filters;
			});

			/*==========================================================================================
			 Broadcast filter obj if filters are valid
			 ==========================================================================================*/
			scope.broadcastFilter = function broadcastFilter(extra_data) {
				/*
				 var sanitized_models = scope.extractValidFilters(),
				 filter_valid = sanitized_models.length > 0;
				 // if(filter_valid || extra_data) {
				 if (extra_data) {
				 if (angular.isArray(extra_data)) {
				 angular.forEach(extra_data, function(val,key) {
				 sanitized_models.push(val);
				 });
				 } else {
				 sanitized_models.push(extra_data);
				 }
				 }
				 scope.form_submitted = false;
				 scope.form_is_valid = true;
				 scope.btn_text = 'Filtering';
				 scope.loading = true;

				 $rootScope.$broadcast('status:toOperationalList', sanitized_models);
				 $rootScope.$broadcast('panel_with_form:filter:' + scope.reportingCategory, sanitized_models);
				 */

				$rootScope.$broadcast('status:toOperationalList', scope.reportingFilters);
				$rootScope.$broadcast('panel_with_form:filter:' + scope.reportingCategory, scope.reportingFilters);
				//  scope.removeBothElements();
				// } else {
				//     scope.form_submitted = true;
				//     scope.btn_text = 'Filter';
				//     scope.top_error_message = $rootScope.error_messages.no_filters_present;
				//     scope.handleInternalError();
				// }
			}

			/*==========================================================================================
			 Omit invalid filters from input_models
			 ==========================================================================================*/
			scope.extractValidFilters = function extractValidFilters() {
				var filter_models = [];

				for(var i = 0, l = scope.input_models.length; i < l; i++) {
					var filter_defined = scope.input_models[i].value !== undefined,
						filter_valid;

					if(filter_defined) {
						if(scope.input_models[i].input_type == 'date'){
							filter_valid = true;
						}else{
							filter_valid = scope.input_models[i].value.length > 0 ||
								scope.input_models[i].value === true;
						}
					} else {
						filter_valid = false;
					}

					if(filter_valid) {
						filter_models.push(scope.input_models[i]);
					}
				}

				return filter_models;
			}

			/*==========================================================================================
			 Handle using input models for filtering
			 ==========================================================================================*/
			scope.handleFilter = function handleFilter(extra_data) {
				//	scope.setModelValues();
				scope.broadcastFilter(extra_data);
			}

			// This lives for the only reason to send sidepanel data back to controller, so the
			// results can be aggregated and sent to backend.
			scope.$on('tosidepanel:filtervalues', function(evt, data) {
				scope.handleFilter(data);
			});
		}
	}
}]);
