commusoftCommon.directive('animateNumber', function($document, $interval) {
    var handleAnimateNumber = function handleAnimateNumber(scope, element, attrs) {
        scope.number = parseFloat(attrs.value);
        scope.duration = parseFloat(attrs.duration);
        scope.old_val = parseFloat(attrs.value);

        var promise,
            animation_steps = 50;

        scope.stop = function() {
            $interval.cancel(promise);
        };

        scope.start = function(value) {
            scope.stop(); // Cancel any existing intervals

            if (scope.old_val !== value) {
                if (scope.old_val > value) {
                    var value_change = scope.old_val - value,
                        interval_duration = scope.duration / value_change;

                    var value_change = value_change / animation_steps,
                        interval_duration = scope.duration / animation_steps;

                    promise = $interval(function() {
                        scope.number -= value_change;
                        scope.old_val = scope.number;

                        if (scope.number < 1) {
                            scope.number = 0;
                        }
                    }, interval_duration, animation_steps);
                } else {
                    var value_change = value - scope.old_val,
                        interval_duration = scope.duration / value_change;

                    var value_change = value_change / animation_steps,
                        interval_duration = scope.duration / animation_steps;

                    promise = $interval(function() {
                        scope.number += value_change;
                        scope.old_val = scope.number;
                    }, interval_duration, animation_steps);
                }
            }
        }

        attrs.$observe('value', function(value) {
            scope.start(parseFloat(value));
        });
    }

    return {
        restrict: 'A',
        templateUrl: 'template/shared/animate_number.html',
        scope: {},
        link: handleAnimateNumber
    }
});