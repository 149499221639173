commusoftCommon.service('permissions', function($rootScope) {
    this.check = function(permissions) {
        var featureAndAccess = permissions;
        var checkFeatureArray = [];

        // Get an array of the features being checked (from the string)
        if (featureAndAccess.indexOf("|") !== -1) {
            checkFeatureArray = featureAndAccess.split("|");
        } else {
            checkFeatureArray.push(featureAndAccess);
        }

        var userHasPermission = _.some(checkFeatureArray, function(feat) {
            var featureArray = feat.split(','),
                featureName = featureArray[0],
                featureAccess = featureArray[1],
                featureId = features[featureName];

            // Check if the current user has this permission for this feature
            return $rootScope.hasPermission(featureId, featureAccess);
        });

        return userHasPermission;
    }
});