
/*==========================================================================================
  A ui for when there are no results to be shown to the user when searching or viewing
  records
==========================================================================================*/

csmodule.directive('noresults',['$filter', '$rootScope',  function($filter, $rootScope) {
    var handleNoResults = function handleNoResults(scope, element, attrs) {
        scope.translated = false;
        scope.supports_arrow = false;

        var checkPermission = scope.checkPermission = attrs.checkpermission || attrs.checkPermission;
        var hasPermission = true;

        if(typeof checkPermission != "undefined") {
            var featureDetails = checkPermission.split(',');
            var featureId = features[featureDetails[0]];
            var featureAccess = featureDetails[1];
            hasPermission = $rootScope.hasPermission(featureId, featureAccess);
        }

        scope.translated = angular.fromJson(attrs.translated);
        scope.supports_arrow = angular.fromJson(attrs.supportsArrow) && hasPermission;

        var supports_arrow = scope.supportsArrow;

        var icon = attrs.icon;
        if (icon) {
            scope.icon = attrs.icon;
        } else {
            scope.icon = 'ss-info';
        }

        scope.arrow_position = attrs.arrowPosition;

        attrs.$observe('message', function(message) {
            scope.handleMessage();
        });

        scope.handleMessage = function() {
            if (scope.translated === true) {
                scope.message = $filter('translate')(attrs.message);
                scope.helptext = $filter('translate')(attrs.helptext);
                scope.checkPermission = attrs.checkpermission || attrs.checkPermission;
            } else {
                scope.message = attrs.message;
                scope.helptext = attrs.helptext;
            }
            if(scope.message == ''){
                scope.message = 'No record found'
            }
        }
    }

    return {
        restrict: 'A',
        templateUrl: 'template/no_results.html',
        scope: {},
        link: handleNoResults
    }
}]);
