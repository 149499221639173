var FC = $.fullCalendar; // a reference to FullCalendar's root namespace
var View = FC.View;      // the class that all views must inherit from
var SuggestedAppointmentView;          // our subclass

/** Function to create new custom fullcalendar view for integrating SuggestedAppointmentView in diary */
SuggestedAppointmentView = View.extend({ // make a subclass of View

    initialize: function() {
        // called once when the view is instantiated, when the user switches to the view.
        // initialize member variables or do other setup tasks.
    },

    render: function() {
        if (this.el[0].children.length === 0) this.el.addClass('fc-basic-view').html(this.renderHtml());
    },
    renderHtml: function () {
        this.el.html('<div></div>');
    },
    setHeight: function(height, isAuto) {
        // responsible for adjusting the pixel-height of the view. if isAuto is true, the
        // view may be its natural height, and `height` becomes merely a suggestion.
    },

    renderEvents: function(events) {
        // reponsible for rendering the given Event Objects
    },

    destroyEvents: function() {
        // responsible for undoing everything in renderEvents
    },

    renderSelection: function(range) {
        // accepts a {start,end} object made of Moments, and must render the selection
    },

    destroySelection: function() {
        // responsible for undoing everything in renderSelection
    },
    destroy: function() {

        // <your custom cleanup-code here>

        // call the super-class's method, forwarding all arguments
        this.trigger('viewDestroy', this, this, this.el);
    }

});

FC.views.suggestedAppointment = SuggestedAppointmentView;


/** Function to create new custom fullcalendar view for integrating map view in diary */
function GenerateFcMapView($scope, $compile) {
    var parentScope = $scope;
    this.scope = null;
    var DiaryMapView = View.extend({ // make a subclass of View

        initialize: function() {
            // called once when the view is instantiated, when the user switches to the view.
            // initialize member variables or do other setup tasks.
        },

        render: function() {
            if (this.el[0].children.length === 0) this.el.addClass('fc-basic-view').html(this.renderHtml());
        },
        renderHtml: function () {
            this.scope = parentScope.$new();
            this.el.html($compile('<div></div>')(this.scope));
        },
        setHeight: function(height, isAuto) {
            // responsible for adjusting the pixel-height of the view. if isAuto is true, the
            // view may be its natural height, and `height` becomes merely a suggestion.
        },

        renderEvents: function(events) {
            // reponsible for rendering the given Event Objects
        },

        destroyEvents: function() {
            // responsible for undoing everything in renderEvents
        },

        renderSelection: function(range) {
            // accepts a {start,end} object made of Moments, and must render the selection
        },

        destroySelection: function() {
            // responsible for undoing everything in renderSelection
        },
        destroy: function() {
            var view = this.calendar.getView('diaryMap');
            view.unselect();
            this.trigger('viewDestroy', this, this, this.el);
        }

    });
    FC.views.diaryMap = DiaryMapView;
}