commusoftCommon.directive('csplaceholder', function(){
  return {
    restrict: 'A',
    templateUrl: '/template/csplaceholder.html',
    link: function(scope, element, attrs) {
      scope.type = !attrs.type ? 'table' : attrs.type;
      scope.cols = [];
      var rows = !attrs.noOfRows ? 10 : parseInt(attrs.noOfRows);
      var cols = !attrs.cols ? 5 : parseInt(attrs.cols);
      scope.placeholder={};
      scope.placeholder.rows = [];

      if(!attrs.cols) {
        scope.cols = [{title: _.random(1,5)},{title: _.random(1,5)},{title: _.random(1,5)},{title: _.random(1,5)}];
      }
      else {
        scope.cols = scope.$eval(attrs.cols);
      }

      _.forEach(_.range(rows), function(row){
        scope.placeholder.rows.push(prepareRow(row));
      });

      function prepareCol() {
        var _t=[];
        _.forEach(_.range(cols), function(col){
          _t.push({title: _.random(1,5)});
        });
        return _t;
      }

      function prepareRow(row) {
        switch (scope.type) {
          case 'table':
            return {
              title: _.random(1,5),
              items: prepareCol()
            };
          case 'section':
            return {
              title: _.random(1,3),
              items: [
                { title: _.random(1,5), value: _.random(1,5) },
                { title: _.random(1,5), value: _.random(1,5) },
                { title: _.random(1,5), value: _.random(1,5) },
                { title: _.random(1,5), value: _.random(1,5) }
              ]
            }
        }
      }
    }
  };
});