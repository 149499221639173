csmodule.directive('speechbubble', ['$sce', function($sce) {
  var handleSpeechBubble = function handleSpeechBubble(scope, element, attrs) {
    scope.message = attrs.message;
    scope.positions = 'null null null center';

    if (attrs.positions !== undefined) {
      scope.positions = attrs.positions;
    }

    scope.handleVertices = function handleVertices() {
      var vertices = scope.positions.split(' '),
          position,
          top      = vertices[0],
          right    = vertices[1],
          bottom   = vertices[2],
          left     = vertices[3];

      if (top !== 'null') {
        scope.arrow_placement_vertice = 'top';
        scope.position = top;
      }
      if (right !== 'null') {
        scope.arrow_placement_vertice = 'right';
        scope.position = right;
      }
      if (bottom !== 'null') {
        scope.arrow_placement_vertice = 'bottom';
        scope.position = bottom;
      }
      if (left !== 'null') {
        scope.arrow_placement_vertice = 'left';
        scope.position = left;
      }
    }

    scope.handleVertices();

    scope.renderHtml = function (html_code) {
      return $sce.trustAsHtml(html_code);
    }

  }

  return {
    restrict: 'A',
    templateUrl: 'template/shared/speech_bubble.html',
    scope: {},
    link: handleSpeechBubble
  }
}]);
