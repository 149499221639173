commusoftCommon.directive('searchBox', function() {
    return function(scope, element, attrs) {
        element.on('click', function () {
            this.select();
        });
        (function() {
            $("#search-input").focus(function() {
                $(this).parent().addClass("widen");
                $("#main-nav").hide();
                return $(".help_button").hide();
            });

            $("#search-input").keyup(function() {
                if ($(this).val().length > 0) {
                    return $('.search-placeholder').hide();
                } else {
                    $('.search-placeholder').show();
                    return $("#search-input").val("");
                }
            });

            $("#search-input").blur(function() {
                var search_timeout;
                $(this).parent().removeClass("widen");
                search_timeout = setTimeout((function() {
                    $("#main-nav").show();
                    return $(".help_button").show();
                }), 700);
                return $(window).blur(function() {
                    return window.clearTimeout(search_timeout);
                });
            });

            $(function() {
                return $("[rel='tooltip']").tooltip();
            });

            $("[rel='tooltip']").on("click", function() {
                return $(this).tooltip("hide");
            });

            $(".small-search-input").focus(function() {
                $(this).parent().addClass("widen");
                return $('#' + $(this).data('searching')).hide();
            });

            $(".small-search-input").blur(function() {
                var filter, settings_search_timeout;
                filter = $('#' + $(this).data('searching'));
                $(this).parent().removeClass("widen");
                settings_search_timeout = setTimeout((function() {
                    return filter.show();
                }), 700);
                return $(window).blur(function() {
                    return window.clearTimeout(settings_search_timeout);
                });
            });
        }).call(this);
    };
});