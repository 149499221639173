angular.module('csDynamicForm')
.directive('cstreegrid', function(prefix, $http, $state){
  return {
    restrict: 'E',
    templateUrl:'/template/dynamic-form-view/cstree-grid.html',
    replace: false,
    scope: {
      listUrl:'@'
    },
    link: function(scope, element, attrs) {
      scope.loading=false;
      scope.pageNum = 1;
      scope.treeRows = [];
      scope.iconLoading = 'fa fa-spinner fa-spin';
      scope.iconExpand = 'fa fa-plus';
      scope.iconCollapse = 'fa fa-minus';
      scope.iconLeaf = 'fa fa-circle';
      scope.propertyId = attrs.propertyId;
      scope.limits=5;
      scope.customerType = attrs.customerType;
      scope.colDefinitions = [];
      scope.viewType = !attrs.viewType ? 'root' : attrs.viewType;
      scope.totalRows=0;
      scope.parentAssetId=null;
      scope.limits=5;
      scope.actionViewParentAssetId = attrs.actionViewParentAssetId;
      if(!_.isObject(scope.params)) {
        scope.params = {};
      }

      scope.fetchData = function(pageNum) {
        scope.loading = true;
        $http.get(_resolveUrl({page: pageNum, limits: scope.limits}))
          .success(function(data,status){
            if(status === 200) {
              scope.totalRecordCounts = parseInt(data.totalRecordCounts);
              scope.propertyId = data.propertyId;
              scope.actionViewParentAssetId = data.parentAssetId;
              var _datas = _prepareRecords(data, undefined);
              scope.treeRows = _datas.records;
              scope.expandingProperty = _datas.expanding_property;
              scope.colDefinitions = _datas.cols;
              scope.totalRows = parseInt(data.totalRecordCounts);
              if(_.has(data, 'parentAssetId')) {
                scope.parentAssetId = data.parentAssetId;
              }
            }
            scope.loading=false;
          });
      };

      function _onToggleCallback(currentIndex, isChild) {
        var row = this, nextIndex=currentIndex+1;
        // if(isChild) {
        //   console.log('isChild', row);
        //   return;
        // }
        if(!row.hasChild || row.loading===true) {
          return false;
        }
        if(!row.expanded) {
          row.loading = true;
          $http.get(getExpandUrl(row)).success(function(data,status){
            if(status !== 200) {
              return false;
            }
            var childrenList=[], subAssetShowCount=0,
              _data = data.records,
              subAssets = _.isObject(row.subAssets) ? row.subAssets : {};

            //add row for subasset addlink
            childrenList.push({
              'default': 'Add sub-asset',
              isCreateAsset: true,
              id: row.id,
              parentAssetId: data.parentAssetId,
              subAssets: _.forEach(subAssets, function(asset){
                if(asset.isSubAssetDeleted===false && asset.showSubAsset===true) {
                  subAssetShowCount++;
                }
                if(data.parentAssetId) {
                  asset.addLink = $state.href('loggedin.customer_list.view.add_associated_customer_asset',{
                    id: scope.propertyId,
                    type: scope.customerType,
                    parentAssetId: data.parentAssetId,
                    assetId: row.id,
                    assetTypeId: asset.id
                  });
                }
                else {
                  asset.addLink = $state.href('loggedin.customer_list.view.add_sub_customer_asset',{
                    id: scope.propertyId,
                    type: scope.customerType,
                    assetId: row.id,
                    assetTypeId: asset.id
                  });
                }
                asset.visibility=asset.isSubAssetDeleted===false && asset.showSubAsset===true;
              }),
              uid: "" + Math.random(),
              subAssetsCount: subAssetShowCount,
              visible: true,
              viewType: scope.viewType,
              colSpan: scope.colDefinitions.length
            });
            if(subAssetShowCount===0) {
              childrenList = [];
            }
            _.forEach(subAssets, function(asset) {
              var assetRecords = _data[asset.id];
              if(!assetRecords) {
                return;
              }
              var formatedItems = _.chain(assetRecords)
                .map(function(item){
                  item['default'] = item['listData'];
                  item.id = item.assetId;
                  item.viewParentAssetId= row.id;
                  item = _.omit(item,['listData','assetId']);
                  return item;
                })
                .value();
              childrenList = childrenList.concat(formatedItems);
            });
            if(isChild) {
              row.children = _prepareRecords(childrenList,row);
            }
            else {
              scope.treeRows.splice(nextIndex,0, _prepareDummyRow(_prepareRecords(childrenList,row)));
            }

            row.loading = false;
            row.expanded = true;
          });
        }
        else {
          //collapse
          if(row.isRoot) {
            scope.treeRows.splice(currentIndex+1, 1);
          }
          else {
            row.children=[];
          }
          row.expanded=false;
          row.loading = false;
        }
      }

      function _prepareDummyRow(topLevelChildren) {
        return {
          isRoot: true,
          isDummy: true,
          uid: "" + Math.random(),
          visible: true,
          colSpan: scope.colDefinitions.length,
          children: topLevelChildren
        };
      }

      function _prepareRecords(datas, parentItem) {
        var _isHaveParent = _.isUndefined(parentItem) ? false : _.isObject(parentItem) ? true : false,
          items = _isHaveParent ? datas : datas.records,
          _tree_data=[],
          _cols,
          expanding_property;
        _.forEach(items, function(item, key){
          item.isDummy = false;
          item.parentAssetId = _isHaveParent ? parentItem.id : scope.actionViewParentAssetId ? scope.actionViewParentAssetId:null;
          item.isRoot = !_isHaveParent;
          item.isNodeView = _isHaveParent ? true : false;
          item.subAssetCount = _.isObject(item.subAssets) ? _.size(item.subAssets) : 0;
          item.hasChild = item.showPlusIcon;
          item.children = [];
          item.level = _isHaveParent ? parentItem.level+1 : 1;
          item.visible = true;
          item.uid = "" + Math.random();
          item.parent_uid = !_isHaveParent ? null : "" + parentItem.uid;
          item.loading = false;
          item.expanded = false;
          item.selected = false;
          item.colSpan = !_isHaveParent ? null : scope.colDefinitions.length;
          item.onExpandOrCollapse = _onToggleCallback;

          if(item.parentAssetId) {
            item.viewLink = $state.href('loggedin.customer_list.view.assets.details.view_sub_asset', {
              id: scope.propertyId,
              type: scope.customerType,
              parentAssetId: item.parentAssetId,
              assetId: item.id
            });
            item.editLink = $state.href('loggedin.customer_list.view.assets.details.associated_edit', {
              type: scope.customerType,
              id: scope.propertyId,
              parentAssetId: item.parentAssetId,
              assetId: item.id
            });
          }
          else {
            item.viewLink = $state.href('loggedin.customer_list.view.assets.details.view_asset', {
              id: scope.propertyId,
              type: scope.customerType,
              assetId: item.id
            });
            item.editLink = $state.href('loggedin.customer_list.view.assets.details.edit', {
              type : scope.customerType,
              id : scope.propertyId,
              assetId: item.id
            });
          }
          var lists = _isHaveParent ? item.listData : item.lists;
          item = _isHaveParent ? _.omit(item, 'listData') : _.omit(item, 'lists');
          _tree_data.push(_.extend(item, lists));
        });

        if(_isHaveParent) {
          return _tree_data;
        }

        //columns mapping
        _cols = _.chain(datas.colHeader)
          .map(function(col, k){
            return {
              field:k,
              displayName: col
            };
          })
          .reject(function(x){
            return x.field === 'default';
          }).value();

        expanding_property = _.find(datas.colHeader, function(x, k){
          return k === 'default'
        });

        return {
          records: _tree_data,
          cols: _cols,
          expanding_property: {
            field: 'default',
            displayName: expanding_property
          }
        };
      }

      function _resolveUrl(additionalParams) {
        var addParam = !_.isObject(additionalParams) ? {} : additionalParams,
          url = prefix + '/'+scope.listUrl + '?',
          params = _.extend(angular.copy(scope.params), addParam);
        _.forEach(params, function(value, name){
          if(value != '') {
            url += name + "=" + value + "&";
          }
        });
        url = url.slice(0,-1);
        return url;
      }

      function getExpandUrl(item) {
        return prefix + '/listAllAssociatedAssets/'+ item.id;
      }
    }
  };
})
.directive('cstreemodel', function($compile){
  return {
    restrict: 'A',
    scope: {
      cstreemodel: '=',
      template: '@'
    },
    link: function(scope, element, attrs){
      scope.treeModel = scope.cstreemodel;

      scope.onChange = function(item) {
        scope.$emit('cstree:onchange', item);
      };
      var template =
        '<ul class="apps-container">' +
        '<li data-ng-repeat="(key,node) in treeModel" ng-class="{\'node-view\': node.isNodeView, \'action-node-li\':node.isCreateAsset, \'single-item\':treeModel.length === 1, \'first-row\':key==0 && treeModel.length !== 1 }">' +
        '<a class="icon_wrap cursor-pointer" ng-click="node.onExpandOrCollapse(key, true)">' +
          '<span class="icon_content" ng-show="!node.expanded && !node.loading && node.hasChild">' +
            '<i class="fa fa-plus tree-icon"></i>' +
          '</span>' +
          '<span class="icon_content" ng-show="node.expanded && !node.loading && node.hasChild">' +
            '<i class="fa fa-minus tree-icon"></i>' +
          '</span>'+
          '<span class="icon_content" ng-show="node.loading">' +
            '<i class="fa fa-spinner fa-spin indented tree-icon"></i>' +
          '</span>' +
        '</a>' +
        '<span class="node-title" data-ng-class="{\'action-node\':node.isCreateAsset}">' +
          '<a ng-if="!node.isCreateAsset" href="{{node.viewLink}}">{{node.default}}</a>' +
          '<span ng-if="node.isCreateAsset">' +
            '{{node.default}} <a class="action-add-link" href="{{asset.addLink}}" ng-repeat="asset in node.subAssets" ng-if="asset.visibility"> {{ asset.title }}</a>' +
          '</span>'+
        '</span>'+
        '<div ng-if="node.children.length > 0" cstreemodel="node.children"></div>' +
        '</li>' +
        '</ul>';

      var template2 =
        '<ul class="apps-container template-2">' +
        '<li data-ng-repeat="(key,node) in treeModel" ng-class="{\'node-view\': node.isNodeView, \'action-node-li\':node.isCreateAsset, \'single-item\':treeModel.length === 1, \'first-row\':key==0 && treeModel.length !== 1 }">' +
        '<a class="icon_wrap cursor-pointer" ng-click="node.onExpandOrCollapse(key, true)">' +
        '<span class="icon_content" ng-show="!node.expanded && !node.loading && node.hasChild">' +
        '<i class="fa fa-plus tree-icon"></i>' +
        '</span>' +
        '<span class="icon_content" ng-show="node.expanded && !node.loading && node.hasChild">' +
        '<i class="fa fa-minus tree-icon"></i>' +
        '</span>'+
        '<span class="icon_content" ng-show="node.loading">' +
        '<i class="fa fa-spinner fa-spin indented tree-icon"></i>' +
        '</span>' +
        '</a>' +
        '<span class="node-title" data-ng-class="{\'action-node\':node.isCreateAsset}">' +
        '<span>{{node.default}}</span>' +
        '</span>'+
        '<span class="node-input-checkbox">' +
        '<input ng-model="node.selected" ng-change="onChange(node)" class="styled-checkbox" id="styled-checkbox-{{node.level}}-{{node.assetId}}" type="checkbox">'+
        '<label for="styled-checkbox-{{node.level}}-{{node.assetId}}"></label>' +
        '</span>'+
        '<div ng-if="node.children.length > 0 && node.showChildren" cstreemodel="node.children" template="2"></div>' +
        '</li>' +
        '</ul>';

      if(scope.treeModel) {
        element.html('').append( $compile( scope.template=='2' ? template2 : template )( scope ) );
      }
    }
  };
});