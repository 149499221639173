'use strict';
angular.module('csDynamicForm')
.factory('_', ['$window', function($window) {
    return $window._;
}])
.controller('DynamicFormViewCtrl', [
  'dynamicElementsService',
  'initiateDependency',
  'dfDataManipulationService',
  '$scope',
  '$timeout',
  function(dynamicElementsService, initiateDependency, dfDataManipulationService, $scope, $timeout){
    $scope.formModal = {};
    $scope.validationList = [];
    $scope.dependent = {};
    $scope.debug=false;
    $scope.assetRecords = {};
    $scope.assetRecords.sections = [];

    $scope.$on("Dynamic_Form_View_Inputs", function (event, message) {

        $scope.formModal = {};
        $scope.validationList = [];
        $scope.dependent = {};

      $scope.assetRecords = message.assetRecords;
      dfDataManipulationService.setAssetRecords(message.assetRecords);
      $scope.watchDependency = {};
      $scope.watchDependent = {};
      $scope.watchValueDependency = {};
      $scope.watchValueDependent = {};
      $scope.dependencyCondition=[];
      $scope.validationList = dynamicElementsService.validationListMapping($scope.assetRecords);
      $scope.dependencites = initiateDependency.dependencyListMapping($scope);
      $scope.valueDependencites = initiateDependency.valueDependencyListMapping($scope);
      $scope.getAllDynamicFields = _.indexBy(angular.copy(dfDataManipulationService.getDynamicFieldsQuestion($scope.assetRecords)),'questionID');
      $scope.lastQuestion = dfDataManipulationService.getLastQuestionId($scope.assetRecords);
      $scope.layoutDepenentValidationList = getLayoutDepenentValidationList();
      var formModel = dfDataManipulationService.prepareFormModel();
      console.log('formModel', formModel);
    });

    //# Field dependencies
    $scope.$on("parentDependency", function(event, value, param) {

      if(typeof event.currentScope.$$listenerCount["dependency_"+value] != 'undefined') {
          $scope.$broadcast('dependency_'+value, param);
      }
    });

    //# Value dependencies only execute for dymanic dropdown list
    $scope.$on("parentValueDependency", function(event, value, param) {

        if(typeof event.currentScope.$$listenerCount["valueDependency_"+value] != 'undefined') {
            $scope.$broadcast('valueDependency_'+value, param);
        }
    });

    function flattenModelValue($formData) {
      var _t = {};
      _.forEach($formData, function(_formdata){
        _.forEach(_formdata, function(x){
          _t[x.key] = _.isUndefined(x.value) ? '' : x.value;
        });
      });
      return _t;
    }

    function getLayoutDepenentValidationList($validateList, _formData) {
      var d=$validateList, skipableValidationItems=[],
      _tmp = _.chain( _.has($scope.assetRecords, 'sections') ? angular.copy($scope.assetRecords.sections) : [])
        .filter(function(section){
          return section.dependencies.length > 0;
        })
        .map(function(section){
          var mergeColFields=[];
          _.forEach(section.fields, function(field){
            if(field.type === 'column') {
              _.forEach(field.columns, function(x){
                mergeColFields = mergeColFields.concat(x.fields);
              });
            }
          });
          if(mergeColFields.length) {
            section.fields = section.fields.concat(mergeColFields);
          }
          section.fields = _.filter(section.fields, function(field){
            return field.type !== 'column';
          });
          return section;
        })
        .value();

      _.forEach(_tmp, function(section){
        var validateQues = _.chain(section.fields)
          .filter(function(field){
          return field.validations && field.validations.length > 0;
        })
        .pluck('questionID')
        .value();
        //mapping depenentQuestions
        var isCollectableValidationList=[];
        _.forEach(section.dependencies, function(dependent){
          var value = _.has(_formData, dependent.questionID) ? _formData[dependent.questionID] : '';
          isCollectableValidationList.push(_.contains(dependent.answer, value) ? true : false);
        });
        if(isCollectableValidationList.length && _.contains(isCollectableValidationList, false)) {
          skipableValidationItems = skipableValidationItems.concat(validateQues);
        }
      });

      if(skipableValidationItems.length) {
        d = _.filter($validateList, function(item){
          return !_.contains(skipableValidationItems, item.questionID)
        });
      }
      return d;
    }

    function checkValidDependency(dependants, formModel) {
      //console.log('dependants', dependants, formModel);
      var checkVal = [], isValid;
      for(var i=0; dependants.length > i; i++) {
        var fieldVal = formModel[dependants[i].questionID], ansVal = dependants[i].answer;
        //console.log('vals',fieldVal, ansVal);
        if(!_.isArray(fieldVal)) {
          checkVal.push(_.contains(ansVal, fieldVal));
        }
        else {
          if( fieldVal.length >= ansVal.length ) {
            checkVal.push(_.difference(fieldVal, ansVal).length === 0 ? true : false);
          }
          else {
            checkVal.push(_.difference(ansVal, fieldVal).length === 0 ? true : false);
          }
        }
      }
      isValid = ! _.contains(checkVal, false);
      return isValid;
    }

    function checkAndSkipDependencies($validateInputs, formModel) { //console.log('$validateInputs', $validateInputs)
      var newInputs=[], isValid=true;
      _.forEach($validateInputs, function(field) {
        if(_.isArray(field.dependencies) && field.dependencies.length > 0) {
          isValid = checkValidDependency(field.dependencies, formModel);
          if(isValid) {
            newInputs.push(field);
          }
        }
        else {
          newInputs.push(field);
        }
      });
      //console.log('newInputs', newInputs);
      return newInputs;
    }

    //validation event trigger here
    $scope.$on('DF:ValidateInputs', function(event, $formData) {
      var $validateList = angular.copy($scope.validationList),
      validItems = {}, _newValidateList = $validateList,
      _formData = flattenModelValue($formData),
      _newValidateList = getLayoutDepenentValidationList($validateList, _formData);
      $scope.isDisabled=true;
      _.forEach(checkAndSkipDependencies(_newValidateList, _formData), function(field) {
        var inputVal = _formData[field.questionID];
        var isRequiredField = _.find(field.validations, function(x){
          return x.id === 'notEmpty';
        });
        validItems[field.questionID] = [];
        if(inputVal === '' && !isRequiredField) {
          validItems[field.questionID].push( 'true' );
        }
        else {
          _.forEach(field.validations, function(item) {
            var result = item.require_input ?
              item.callback(inputVal, item.rule) : item.callback(inputVal);
            validItems[field.questionID].push( result ? 'true' : 'false' );
          });
        }
      });
      //console.log('validItems', validItems);
      if(_.isEmpty(validItems)) {
        $scope.isDisabled=false;
        return;
      }
      for (var id in validItems) {
        if( _.contains(validItems[id], 'false') ) {
          $scope.isDisabled = true;
          break;
        }
        else {
          $scope.isDisabled=false;
        }
      }
    });

    $scope.afterFormLoad = function(index) {
      var $_ind = index+1;
      if($_ind === $scope.assetRecords.sections.length) {
        //$scope.$emit('DF:ValidateInputs', $scope.formModal);
      }
        $scope.displayedEditLink = true;
    };

    $scope.$on('DF:afterRenderForm', function(evt, questionId, lastId) {
      $timeout(function(){
        $scope.$emit('DF:ValidateInputs', $scope.formModal);
      },3000);
    });

    $scope.$elementValidation = function(value, callbacks) {
      var errorMsg = null;
      if(!callbacks.length) {
        return errorMsg;
      }
      var isRequiredField = _.find(callbacks,function(x){
        return x.id === 'notEmpty'
      });
      if(!isRequiredField && value === '' ) {
        return errorMsg;
      }
      for(var i=0; callbacks.length > i; i++) {
        var item = callbacks[i];
        var result = item.require_input ? item.callback(value, item.rule) : item.callback(value);
        if(!result) {
          errorMsg = item.msg.replace('#rule#', item.rule);
          break;
        }
      }
      return errorMsg;
    };
    //end validation

    function formattedFormModel(data) {
      var _data = angular.copy(data), newModel={};
      _.forEach(_data, function(model, screen){
        newModel[screen] = _.map(model, function(item){
          if(item.fieldType == 'number') {
              return {
                  fieldType: item.fieldType,
                  fieldValueId: item.fieldValueId,
                  value: item.value,
                  key: item.key,
                  numberDropdown: item.numberDropdown
              };
          } else {
              return {
                  fieldType: item.fieldType,
                  fieldValueId: item.fieldValueId,
                  value: item.value,
                  key: item.key
              };
          }

        });
      });
      return newModel;
    }

    $scope.handleSave = function() {
        //console.log(formattedFormModel($scope.formModal,'dependencies'))
        $scope.$emit("handleSave", { 'assetDetails' : formattedFormModel($scope.formModal)});
    }
}])
.controller('CustomerAddDynamicFieldOptionCtrl',[
  '$scope',
  '$rootScope',
  '$timeout',
  '$state',
  'prefix',
  '$http',
  '$q',
  'getFieldOptions',
  '$modalInstance',
  'dfConfirmationToast',
  'toastBox',
  'dynamicFormPrefix',
  function AddDynamicFieldPanel($scope, $rootScope, $timeout, $state, prefix, $http, $q, getFieldOptions, $modalInstance, dfConfirmationToast,toastBox, dynamicFormPrefix) {
  $scope.elemId = 'customer_add_dynamic_field_option';
  $scope.inputText={};
  $scope.inputText.field_name=getFieldOptions.data.description;
  $scope.field_id=getFieldOptions.data.id;
  $scope.field = getFieldOptions.data;
  $scope.formModal = flattenModelValue(getFieldOptions.formModal);
  $scope.loading = false;
  $scope.value = getFieldOptions.value;
  $scope.listUrl = getFieldOptions.listUrl;
  $scope.dynamicFieldId = getFieldOptions.data.selectedOption;
  $scope.data = [];
  $scope.fieldDependencies = [];
  $scope.inputs = {};
  $scope.inputs.parents = {};
  $scope.inputs.optionValue = '';
  $scope.inputs.createAnother = false;
  $scope.getAllDynamicFields = getFieldOptions.getAllDynamicFields;
  $scope.isDisabledSave = false;
  $scope.saveUrl = 'saveAssetDynamicFieldOptions';
  $scope.options = _.isUndefined(getFieldOptions.options) ? [] : getFieldOptions.options;
  $scope.watchValueDependent = getFieldOptions.watchValueDependent;

  $modalInstance.opened.then(function(isOpened) {
    if (isOpened) {
      dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
      $timeout(function(){
        var $element = $('#'+$scope.elemId);
        $element.bind('keydown', function(e){
          if(e.which === 13) {
              $scope.isAddNew ? $scope.handleAssetField() : $scope.onSubmit();
          }
          if(e.which === 27) {
            $scope.onClose();
          }
        });
      },300);
    }
  });

  $scope.getChildValues = function(parent) {
      if(parent.values[0]) {
        var childDropdownID = $scope.watchValueDependent[parent.questionID][0];
        if(childDropdownID != $scope.field.questionID) {
            var childFieldID = $scope.getAllDynamicFields[childDropdownID].selectedOption;
            $http.get(prefix + '/listAssetDynamicDropdownOptions?optionId=' + childFieldID + '&valueDependencyOption=' + parent.values[0]).success(function (value,status){
                if(status == 200){
                    $scope.inputs.parents[childDropdownID].options = value.options;
                }
            });
        }
      }
      $scope.validate();
  }

  $scope.validate = function() {
    var p = _.map(_.pluck($scope.inputs.parents,'values'), function(x){
      if(_.isEmpty(x[0])) {
        return 0;
      }
      return x.length
    });
    var isValid = !_.contains(p, 0) && !_.isEmpty($scope.inputs.optionValue);
    $scope.isDisabledSave = isValid === false;
    return isValid;
  };

  $scope.select2Options = {
    allowClear:false,
    placeholder: 'Select an option',
    editable: true,
    minimumInputLength:2,
    multiple: false,
    ajax: {
      url: prefix + '/listAssetDynamicFieldOptions?fieldId=' + $scope.field.selectedOption,
      dataType: 'json',
      data: function (term) {
        return {
          searchText: term
        };
      },
      results: function (data, page) {
        var items = _.map(data.options, function(item){
          return {
            id: item.id,
            text: item.description
          }
        });
        return {
          results: items
        };
      },
    },
    createSearchChoice: function(term,data) {
      if(!data.length) {
        return {id:0, text:term};
      }
      else {
        var contents = _.pluck(data, 'text'),
          exists =  _.some(contents, function(text){
            return text.toLowerCase() === term.toLowerCase();
          });
        return exists ? {} : {id:0, text:term};
      }
    }
  };

  $scope.onSubmit = function() {
    $scope.isDisabledSave = true;
    var parentItems = [];
    _.forEach($scope.inputs.parents, function(x){
      parentItems.push(_.pluck(x.values,'id'));
    });
    var url = prefix + '/'+ $scope.saveUrl, params, newItem = $scope.inputs.optionValue;
    params = 'fieldId='+$scope.field.selectedOption + '&dependencies=' + encodeURIComponent(angular.toJson(parentItems));

    if(_.isObject(newItem)  ) {
      params +=  newItem.id === 0 ? '&newOption=' + newItem.text : '&newOptionId='+ newItem.id +'&newOption=';
    }
    else {
      params += '&newOption=' + newItem;
    }
    $http.post(url, params).success(function (value,status){
      //$scope.loading = true;
      if(status == 200){
        toastBox.show('Records saved', 2000);

        if(!$scope.inputs.createAnother) {
          $modalInstance.close(value);
        }
        else {
          getFieldOptions.pushOption.push(value);
          $scope.inputs.optionValue = '';
        }
      }
    });
  };

    $scope.isOptionValueExists = function() {
      if($scope.validate() ) {
        var exists = _.find($scope.options, function(x) {
          return x.description.toLowerCase() == $scope.inputs.optionValue.toLowerCase();
        });
        if(exists) {
          $scope.isDisabledSave = true;
          $scope.error = 'This item already exists.';
        }
        else {
          $scope.isDisabledSave = false;
          $scope.error = null;
        }
        return $scope.error === null ? true : false;
      }
      return false;
    };

  $scope.onClose = function(info) {
    $modalInstance.dismiss(info);
  };

  prepareInputModel();

  $scope.$on('$destroy', function(){
    $(document).unbind('keydown');
    $('#'+$scope.elemId).unbind('keydown');
    $('body').find('.modal-backdrop').off('click');
  });

  function flattenModelValue($formData) {
    var _t = {};
    _.forEach($formData, function(_formdata){
      _.forEach(_formdata, function(x){
        _t[x.key] = _.isUndefined(x.value) ? '' : x.value;
      });
    });
    return _t;
  }

  function getParentDropdownValues(hasParents) {
    if(!hasParents) {
      return [];
    }
    var parentVals=[];
    _.forEach($scope.inputs.parents, function(parent) {
        parentVals.push(_.has(parent.values[0], 'id') ? parent.values[0].id : 'null');
    });
    return parentVals;
  }

  function getValueDependencyOption(index, hasParents) {
    var parentVals = getParentDropdownValues(hasParents);
    if(!parentVals.length) {
      return '';
    }
    parentVals = _.filter(parentVals, function(v,i) { return index > i;});
    return parentVals[index-1] != '' ? angular.toJson(parentVals) : '';
  }

  $scope.onChange = function(parent) {
    _.forEach(getChildren(parent), function(child){
      if( _.has($scope.inputs.parents, child.questionID) ) {
        $scope.inputs.parents[child.questionID].values = [];
      }
    });
    $scope.inputs.optionValue='';
  };

  function getChildren(parentField) {
    return _.chain($scope.getAllDynamicFields)
      .filter(function(field) {
        var valueDependencies = [];
        if(field.questionID !== parentField.questionID) {
          valueDependencies = _.pluck(field.valueDependencies,'questionID');
        }
        return valueDependencies.length > 0 && _.contains(valueDependencies, parentField.questionID);
      })
      .value();
  }

  function prepareInputModel() {
    $scope.inputs.optionValue = '';
      $scope.tempParents = [];
    _.forEach($scope.field.valueDependencies, function(item, index) {
      var parentField = $scope.getAllDynamicFields[item.questionID];
        $scope.tempParents.push({
        questionID: item.questionID,
        fieldId: parentField.selectedOption,
        fieldLabel: parentField.fieldLabel,
        options: parentField.items,
        select2Options: {
          dropdownCssClass: "bigdrop",
          formatLoadMore: function(){
            return ' Loading...';
          },
          ajax: {
            quietMillis: 250,
            delay: 250,
            url: prefix + '/listAssetDynamicDropdownOptions',
            data: function (query, page) {
              var params = {};
              params['optionId'] = parentField.selectedOption;
              params['hasParent'] = parentField.valueDependencies.length > 0;
              params['assetScreenType'] = getFieldOptions.assetScreenType;
              params['valueDependencyOption'] = getValueDependencyOption(index, parentField.valueDependencies.length > 0);
              params['query'] = encodeURIComponent(query);
              params['page'] = page;
              return params;
            },
            results: function (data, page) {
              var options = _.map(data.options, function(option){ return {id:option.id, text:option.description}});
              return {
                results: options,
                more: ((page * 10) < data.optionCounts)
              };
            }
          },
          initSelection: function(element, callback) {
            callback(_.isEmpty($scope.formModal[item.questionID]) ? [] : [$scope.formModal[item.questionID]]);
          }
        },
        values: _.isEmpty($scope.formModal[item.questionID]) ? [] : [$scope.formModal[item.questionID]],
        index: index
      });
    });
    $scope.inputs.parents = _.indexBy($scope.tempParents,'questionID');
    $scope.inputParentsLength = _.size($scope.inputs.parents);
    $scope.validate();
  }
}])
.filter('orderObjectBy', function() {
  return function(items, field, reverse) {
    var filtered = [];
    angular.forEach(items, function(item) {
      filtered.push(item);
    });
    filtered.sort(function (a, b) {
      return (a[field] > b[field] ? 1 : -1);
    });
    if(reverse) filtered.reverse();
    return filtered;
  };
});