commusoftCommon.filter('normalDateFormatWithFullYear', function (CS_LANGUAGE) {
    return function (dateString) {
        if (dateString == '') {
            return '';
        }
        if(CS_LANGUAGE === 'en-GB') {
            return moment(dateString).format('DD/MM/YYYY');
        }else if(CS_LANGUAGE === 'en-US'){
            return moment(dateString).format('MM/DD/YYYY');
        }else{
            return moment(dateString).format('DD/MM/YYYY')
        }


    };
});