﻿'use strict';

var prefix = window.prefixVal;
angular.module('csDynamicForm')

    .factory('_', ['$window', function($window) {
        return $window._;
    }])

    .constant('dynamicFormPrefix', window.prefixVal)
    .service('initiateFields', ['initiateDependency','dynamicElementsService', function(initiateDependency, dynamicElementsService) {
        this.initiateValues = function(scope) {

          scope.arrayLength = 0;
          if (typeof (scope.formModal[scope.screenType]) != "undefined") {
            scope.arrayLength = scope.formModal[scope.screenType].length;
          } else {
            scope.formModal[scope.screenType] = [];
          }
          var formValues = '', fieldValueId = '', numberDropdown = '';
          if ((scope.assetScreenType == 'edit') || (scope.assetScreenType == 'view')) {

            //# Condition only execute for number field type
            if (scope.field.type == 'number' && scope.assetValues[scope.field.questionID]) {
              var numberTextValue = scope.assetValues[scope.field.questionID].split(','),
                numberDropdown = numberTextValue[1];
              formValues = numberTextValue[0];
            } else {
              formValues = scope.assetValues[scope.field.questionID];
            }
            fieldValueId = scope.assetValuesId[scope.field.questionID];
          } else {
            formValues = scope.field.defaultValue[0] ? scope.field.defaultValue[0] : '';
          }

          // Default value assign to scope
          if (scope.assetScreenType == 'edit' && scope.field.type == 'number' && scope.assetValues[scope.field.questionID]) {
            scope.formModal[scope.screenType][scope.arrayLength] = {
              'key': scope.field.questionID,
              'value': formValues,
              'fieldType': scope.field.type,
              'fieldValueId': fieldValueId,
              'numberDropdown': numberDropdown,
              'dependencies': scope.field.dependencies
            };
          } else {
            scope.formModal[scope.screenType][scope.arrayLength] = {
              'key': scope.field.questionID,
              'value': formValues,
              'fieldType': scope.field.type,
              'fieldValueId': fieldValueId,
              'dependencies': scope.field.dependencies
            };
          }
          if(scope.field.questionID === scope.lastQuestion.questionID) {
            scope.$emit('DF:afterRenderForm', scope.field.questionID, scope.lastQuestion.questionID);
          }
        };

        this.loadProcessingLevel = function(scope) {
            initiateDependency.initiateDependency(scope);

            if (scope.assetScreenType == 'edit' || scope.assetScreenType == 'view' || (scope.assetScreenType != 'edit' && scope.field.defaultValue[0])) {
                //# During edit and view screen check the current field directive and update the dependency field status.
                initiateDependency.checkDependency(scope, true);
                //# After initial load of the current field directive check with field status to show or hide.
                scope.showHideDependency(scope.field.questionID, scope.watchDependency[scope.field.questionID]);
            }

            if (scope.assetScreenType != 'view') {
                //validate callback
                scope.errorMsg = null;
                var validCallbacks = scope.validationCallbacks = dynamicElementsService.validationCallbackMapping(scope.field.validations);
                scope.isRequiredField = dynamicElementsService.isRequiredField(scope.field.validations);

                var select2VoidInitialCallback= (scope.field.type == "dropdownlist" ||  scope.field.type == "nested_dropdown" || scope.field.type == "toggle" || scope.field.type == "predefined_toggle"  || scope.field.type == "user_field") ? true : false;

                scope.onValidate = function () {
                    initiateDependency.checkDependency(scope);
                    if (validCallbacks.length === 0) {
                        //return true;
                    }

                    //scope.$emit('DF:ValidateInputs', scope.formModal);
                    if(!select2VoidInitialCallback) {
                        scope.errorMsg = scope.$elementValidation(scope.formModal[scope.screenType][scope.arrayLength].value, validCallbacks);
                        select2VoidInitialCallback=false;
                    }

                    scope.$emit('DF:ValidateInputs', scope.formModal);
                };
            }
        }
    }])
    .service('initiateDependency',[function() {

        var var_fields = 'fields',
            var_dependency = 'dependencies',
            var_questionID = 'questionID',
            var_sectionID = 'sectionID',
            var_valueDependency = 'valueDependencies';

        function _deepFlattenRecords(data) {
            var _tmp = [];
            _.forEach(data, function(x){
                if(x.type === 'column') {
                    _tmp.push(_deepFlattenRecords(x.columns));
                }
                else {
                    _tmp.push( _.has(x,'fields') ? x.fields : x);
                }
            });
            return _tmp;
        }

        this.getFlattenArrayRecord = function(scope, jsonModal) {
            var list = [];

            list = _.map(jsonModal.sections, function(x){
                if((typeof x[var_dependency] != 'undefined') && (x[var_dependency].length)) {
                    assignDependency(scope, x[var_dependency], x[var_sectionID]);
                }
                return _deepFlattenRecords(x[var_fields]);
            });
            return _.flatten(list);
        };

        this.getDependencyRecords = function(scope, flattenArrayRecords) {
            var deList;
            deList = _.map(flattenArrayRecords, function(x) {
                if((typeof x[var_dependency] != 'undefined') && (x[var_dependency].length)) {
                    assignDependency(scope, x[var_dependency], x[var_questionID]);
                }
                return true;
            });
        };

        function assignDependency(scope, dependencies, questionID) {
            //# Initialize the dependency fields
            angular.forEach(dependencies, function(value, key){
                if(typeof scope.watchDependency[questionID] == 'undefined') {
                    scope.watchDependency[questionID] =[];
                }

                var objQuestionID = {};
                objQuestionID[value.questionID]=value.answer;
                objQuestionID.status = false;
                scope.watchDependency[questionID].push(objQuestionID);

                if(typeof scope.watchDependent[value.questionID] == 'undefined') {
                    scope.watchDependent[value.questionID] =[];
                }
                scope.watchDependent[value.questionID].push(questionID);
            });
        }

        //# Initially screen load get all the dependency and dependent fields and assign into the scope value for rendering during each directive load.
        //# Its call from the parent controller.
        this.dependencyListMapping = function(scope) {
            var flattenArrayRecords = this.getFlattenArrayRecord(scope, scope.assetRecords);
            this.getDependencyRecords(scope, flattenArrayRecords);
        }

        //# Commonly initiate the dependency for execution process
        this.initiateDependency = function(scope) {

            //# Condition will true execute only for section dependency
            //# Else if part will execute for field dependency
            if(typeof scope.sectionLoadData != 'undefined' && scope.sectionLoadData.type == 'section') {
                scope.fieldDependency = ((typeof scope.sectionLoadData.dependencies != 'undefined') && (scope.sectionLoadData.dependencies.length)) ? false : true;
                scope.dependencyKeyID = scope.sectionLoadData.sectionID;
                scope.sectionLoadData='';
            } else if(typeof scope.field != 'undefined') {
                scope.fieldDependency = ((typeof scope.field.dependencies != 'undefined') && (scope.field.dependencies.length)) ? false : true;
                scope.dependencyKeyID = scope.field.questionID;
            }

            var self = this;
            scope.$on("dependency_"+scope.dependencyKeyID, function(event, param) {

                //# Execute the looping and update the field show/hide status.
                scope.executeDependencyDetails(scope.dependencyKeyID, param);
                scope.showHideDependency(scope.dependencyKeyID, scope.watchDependency[scope.dependencyKeyID]);
                return true;
            });

            //# Execute the loop with dependency questionID and update with its status.
            scope.executeDependencyDetails = function(dependencyKeyID, param) {
                angular.forEach(scope.watchDependency[dependencyKeyID], function(value, key) {
                    if(typeof scope.watchDependency[dependencyKeyID][key][param.key] != 'undefined') {

                        //# During edit and view section for checkbox list we are passing the with "," commas so need to convert into array.
                        if((typeof param.value != 'undefined') && (param.fieldType == 'checkboxlist')) {
                            if(angular.isArray(param.value) == false) {
                                param.value = param.value.split(",");
                            }
                        } else if(param.fieldType == 'dynamic_dropdown') {
                            var tempParam = param.value;
                            param.value = ((typeof param.value != 'undefined') && (typeof param.value.id != 'undefined')) ? param.value.id.toString() : param.value;
                        }
                        //# For checkbox list the value will get as array
                        if(angular.isArray(param.value)) {
                            scope.watchDependency[dependencyKeyID][key].status = false;
                            var paramLength = param.value.length;

                            for(var x = 0; x < paramLength; x++) {
                                if(scope.watchDependency[dependencyKeyID][key][param.key].indexOf(param.value[x]) != -1) {
                                    scope.watchDependency[dependencyKeyID][key].status = true;
                                    break;
                                }
                            }
                        } else {
                            if((typeof param.value != 'undefined') && (scope.watchDependency[dependencyKeyID][key][param.key].indexOf(param.value) != -1)) {
                                scope.watchDependency[dependencyKeyID][key].status = true;
                            } else {
                                scope.watchDependency[dependencyKeyID][key].status = false;
                            }
                        }
                        //# Replace the form data
                        if(param.fieldType == 'dynamic_dropdown') {
                            param.value = tempParam ? tempParam : param.value;
                        }

                        if(typeof scope.dependencyCondition[dependencyKeyID] == 'undefined') {
                            scope.dependencyCondition[dependencyKeyID] = {};
                        }
                        scope.dependencyCondition[dependencyKeyID][param.key] = scope.watchDependency[dependencyKeyID][key].status;
                    }
                });
            }

            //# show or hide the dependency field
            scope.showHideDependency = function(questionID, param) {
                var convertDependencyObj =_(scope.dependencyCondition[questionID]).toArray();
                if((typeof param != 'undefined') && (param.length == convertDependencyObj.length)) {
                    if(convertDependencyObj.indexOf(false) != -1) {
                        scope.fieldDependency = false;
                        if((typeof scope.formModal[scope.screenType] != 'undefined') && (typeof scope.formModal[scope.screenType][scope.arrayLength] != 'undefined') && (scope.section.sectionID != questionID)) {
                            scope.formModal[scope.screenType][scope.arrayLength].value = '';

                            /*if(scope.formModal[scope.screenType][scope.arrayLength].fieldType == 'date') {
                                scope.$broadcast("dateScopeChange",'');
                            }*/

                            //# Check current scope dependency field
                            self.checkDependency(scope);

                        } else if(scope.section.sectionID === questionID) {

                            //# No need to empty the fields during add or view. So this function call is not required
                            if(scope.assetScreenType == 'edit') {
                                scope.emptySectionFields(scope.section.fields);
                            }
                        }
                    } else {
                        //# It will execute only for date format
                        if(typeof scope.arrayLength != 'undefined') {
                            if((scope.formModal[scope.screenType][scope.arrayLength].fieldType == 'date') && ((scope.formModal[scope.screenType][scope.arrayLength].value == '') || (scope.formModal[scope.screenType][scope.arrayLength].value == null))) {
                                scope.onDependencyDate();
                            } else if((scope.formModal[scope.screenType][scope.arrayLength].fieldType == 'time') && ((scope.formModal[scope.screenType][scope.arrayLength].value == '') || (scope.formModal[scope.screenType][scope.arrayLength].value == null))) {
                                scope.changeEventDateTime('', moment().format('YYYY-MM-DD HH:mm'));
                            }
                        }
                        scope.fieldDependency = true;
                    }
                }
            }

            //# If having section dependency while hide the section need to remove the exist data
            scope.emptySectionFields = function(fields) {
                angular.forEach(fields, function(field, key) {
                    if(field.type == 'column') {
                        angular.forEach(field.columns, function (column, colKey) {
                            scope.emptySectionFields(column.fields);
                        });
                    }
                    else {
                        var keyIndex = _.findIndex(scope.formModal[scope.screenType], function(x){return x.key==field.questionID});
                        if(typeof scope.formModal[scope.screenType][keyIndex] != 'undefined') {
                            scope.formModal[scope.screenType][keyIndex].value = '';
                        }
                    }
                });
            }
        }

        //# Check with dependency
        this.checkDependency = function(scope) {

            //# Check with field dependencies
            if(typeof scope.watchDependent[scope.field.questionID] != 'undefined') {
                var dependencyVal = scope.watchDependent[scope.field.questionID],
                    params = scope.formModal[scope.screenType][scope.arrayLength];

                angular.forEach(dependencyVal, function(value, key) {
                    if(typeof value != 'undefined') {
                        //# In edit/view screen during top to bottom field dependency directive loading listener not assigned then this condition will execute
                        if(typeof scope.$emit("parentDependency", value, params).currentScope.$$listenerCount["dependency_"+value] == 'undefined') {
                            scope.executeDependencyDetails(value, params);
                        }
                    }
                });
            }

            //# Check with value dependencies
            if(typeof scope.watchValueDependent[scope.field.questionID] != 'undefined') {
                var valueDependency = scope.watchValueDependent[scope.field.questionID],
                    params = scope.formModal[scope.screenType][scope.arrayLength];

                angular.forEach(valueDependency, function(value, key) {
                    if(typeof value != 'undefined') {
                        scope.$emit("parentValueDependency", value, params);
                    }
                });
            }
        }

        //# Assign the valueDependent and valueDependencies
        function assignValueDependency(scope, dependencies, questionID) {
            //# Initialize the value dependency

            var valueDependencyLength = dependencies.length-1,
                value = dependencies[valueDependencyLength];

            if(typeof value != 'undefined') {
                if(typeof scope.watchValueDependency[questionID] == 'undefined') {
                    scope.watchValueDependency[questionID] =[];
                }
                scope.watchValueDependency[questionID].push(value.questionID);

                if(typeof scope.watchValueDependent[value.questionID] == 'undefined') {
                    scope.watchValueDependent[value.questionID] =[];
                }
                scope.watchValueDependent[value.questionID].push(questionID);
            }
        }

        //# Assign the field level value dependencies
        this.getValueDependencyRecords = function(scope, flattenArrayRecords) {
            var deList;
            deList = _.map(flattenArrayRecords, function(x) {
                if((typeof x[var_valueDependency] != 'undefined') && (x[var_valueDependency].length)) {
                    assignValueDependency(scope, x[var_valueDependency], x[var_questionID]);
                }
                return true;
            });
        };

        //# Get the flatten array records for the value dependencies
        this.getValueFlattenArrayRecord = function(scope, jsonModal) {
            var list = [];

            list = _.map(jsonModal.sections, function(x){
                return _deepFlattenRecords(x[var_fields]);
            });
            return _.flatten(list);
        };

        //# Initially screen load get all the value dependency and dependent field values and assign into the scope value for rendering during each directive load.
        //# Its call from the parent controller.
        this.valueDependencyListMapping = function(scope) {
            var valueFlattenArrayRecords = this.getValueFlattenArrayRecord(scope, scope.assetRecords);
            this.getValueDependencyRecords(scope, valueFlattenArrayRecords);
        }

        //# Will call from dynamic dropdown list directive
        this.initiateValueDependencies = function(scope) {

            if(typeof scope.field != 'undefined') {
                scope.valueDependency = ((typeof scope.field.valueDependencies != 'undefined') && (scope.field.valueDependencies.length)) ? false : true;
                scope.valueDependencyKeyID = scope.field.questionID;
            }

            var self = this;
            scope.$on("valueDependency_"+scope.valueDependencyKeyID, function(event, param) {
                //if((typeof param != 'undefined') && (param.value != null) && ((parseInt(param.value.id)) ||(parseInt(param.value)))) {
                    scope.getDynamicDropdownValues(param);
                //}
                return true;
            });
        }
    }])
    .directive('dynmicFormElementsView', ['$compile', 'initiateFields', function($compile, initiateFields){
        return {
            restrict: 'A',
            link: function(scope, element, attr) {
                scope.screenType = '';
                if(typeof attr.assetId != 'undefined') {
                    scope.assetId = attr.assetId;
                } else {
                    scope.assetId = '';
                }

                if(typeof attr.assetScreenType != 'undefined') {
                    scope.assetScreenType = attr.assetScreenType;
                } else {
                    scope.assetScreenType = '';
                }

                if(typeof attr.assetValues != 'undefined' && attr.assetValues != '') {
                    scope.assetValues = angular.fromJson(attr.assetValues);
                } else {
                    scope.assetValues = [];
                }

                if(typeof attr.assetValuesId != 'undefined' && attr.assetValuesId != '') {
                    scope.assetValuesId = angular.fromJson(attr.assetValuesId);
                } else {
                    scope.assetValuesId = [];
                }

                scope.textBoxOnChange = function(index) {
                    console.log(index);
                };

                scope.showAction = function() {
                  return false;
                };
            },
            templateUrl: '/template/dynamic-form-view/elements-container.html'
        };
    }])
    .directive('sectionElement', ['$compile', 'initiateFields', 'initiateDependency', function($compile, initiateFields, initiateDependency){
        var sectionLink = function(scope, element, attrs) {
            scope.section=scope.$eval(attrs.data);
            scope.sectionLoadData = scope.section;

            //# Initiate the dependency value
            initiateDependency.initiateDependency(scope);
        };
        return {
            restrict: 'A',
            link: sectionLink,
            templateUrl: '/template/dynamic-form-view/section-container.html'
        };
    }])
    .directive('columnElement', ['$compile', 'initiateFields', function($compile, initiateFields){
        var columnLink = function(scope, element, attrs) {
            scope.columnField=scope.$eval(attrs.data);
        };
        return {
            restrict: 'A',
            link: columnLink,
            templateUrl: '/template/dynamic-form-view/column-container.html'
        };
    }])
    .directive('textboxElement', ['$compile', 'dynamicElementsService', 'initiateFields', 'initiateDependency', function($compile, dynamicElementsService, initiateFields, initiateDependency){
        var textBoxLink = function(scope, element, attrs) {
            scope.field=scope.$eval(attrs.data);

            //# get assetId only in view asset detail screen
            if(scope.assetScreenType == 'view') {

                if(scope.field.type == 'number') {
                    var numberTextValue = scope.assetValues[scope.field.questionID]?scope.assetValues[scope.field.questionID].split(','): '';
                    scope.listFormValues = numberTextValue[0];
                    scope.listNumberDropdown = numberTextValue[1];
                } else {
                    scope.listFormValues = scope.assetValues[scope.field.questionID];
                }
                // Initiate the scope value
                initiateFields.initiateValues(scope);

                // Loading the process level
                initiateFields.loadProcessingLevel(scope);
            } else {
                var validCallbacks = dynamicElementsService.validationCallbackMapping(scope.field.validations);
                scope.isRequiredField = dynamicElementsService.isRequiredField(scope.field.validations);

                // Initiate the scope value
                initiateFields.initiateValues(scope);

                // Loading the process level
                initiateFields.loadProcessingLevel(scope);

                scope.errorMsg = null;

                if(scope.field.type == 'decimal') {
                    var f = scope.field.format.split(','),
                        p = f[0] == 0 ? '': f[0],
                        s = f[1] == 0 ? '': f[1];
                    scope.pattern = '^\\d{0,'+p+'}(\\.\\d{0,'+s+'})?$';
                } else if(scope.field.type == 'number' && scope.assetValues[scope.field.questionID]) {
                    var numberTextValue = scope.assetValues[scope.field.questionID].split(','),
                        numberDropdown = numberTextValue[1];
                    scope.formModal[scope.screenType][scope.arrayLength].numberDropdown =  numberDropdown ? numberDropdown : '';
                }
            }
        };
        return {
            restrict: 'A',
            link: textBoxLink,
            templateUrl: '/template/dynamic-form-view/textbox-container.html'
        };
    }])
    .directive('dynamicDropdownElement',
      function($timeout, $compile, prefix, $http, initiateFields, dynamicElementsService, initiateDependency, $modal, dfDataManipulationService){
        var dynamicDropDownLink = function(scope, element, attrs) {
              var eventCount=0;
              scope.field=scope.$eval(attrs.data);
              scope.selectedOption=scope.field.selectedOption;

              var hasParents = scope.field.valueDependencies.length > 0;
              //var havingChildren = getChildren();

              scope.showAddOption = (scope.field.selectedOption != -1) ? true : false;
              var allDynamicFields = scope.getAllDynamicFields;
              scope.fieldOptions = [];

            // Initiate the scope value
            initiateFields.initiateValues(scope);

              function getParentDropdownValues() {
                if(!hasParents) {
                  return [];
                }
                var parentVals=[];
                _.forEach(scope.field.valueDependencies, function(parent) {
                    var parentFieldVal = _.find(scope.formModal[scope.screenType], function(model){
                      return model.key === parent.questionID;
                    });
                    if(parentFieldVal) {
                      parentVals.push(_.has(parentFieldVal.value, 'id') ? parentFieldVal.value['id'] : parentFieldVal.value);
                    }
                });
                return parentVals;
              }

              function getValueDependencyOption() {
                var parentVals = getParentDropdownValues();
                if(!parentVals.length) {
                  return '';
                }
                //return _.last(parentVals);
                  return _.last(parentVals) != '' ? angular.toJson(parentVals) : '';
              }

              function getParams() {
                return {
                  'hasParent': hasParents,
                  'assetScreenType': scope.assetScreenType,
                  'optionId': scope.selectedOption,
                  'valueDependencyOption': getValueDependencyOption()
                };
              }

              /*function getChildren() {
                var fields = dfDataManipulationService.getFlattenArray();
                return _.chain(fields)
                  .filter(function(field) {
                    var valueDependencies = [];
                    if(field.type === 'dynamic_dropdown') {
                      valueDependencies = _.pluck(field.valueDependencies,'questionID');
                    }
                    return valueDependencies.length > 0 && _.contains(valueDependencies, scope.field.questionID);
                  })
                  .pluck('questionID')
                  .value();
              }*/

              /*scope.onChange = function() {
                eventCount++;
                console.log('s', eventCount );
                if(eventCount === 1) {
                  return;
                }
                if(!havingChildren.length) {
                  scope.onValidate();
                  return false;
                }
                _.forEach(havingChildren, function(childQuestionID) {
                  var elementModel = _.find(scope.formModal[scope.screenType], function(model){
                    return childQuestionID === model.key
                  });
                  if(elementModel) {
                    //elementModel.value='';
                      //scope.onValidate();
                    // initiateDependency.checkDependency(scope);
                  }
                });
                if(eventCount > 1)
                  scope.onValidate();
              };*/

              //# Used for to check and rest the child dependency
              scope.getDynamicDropdownValues = function(param) {

                  if((typeof scope.formModal[scope.screenType][scope.arrayLength] != 'undefined') && (scope.initiateSelect2CallBack == false)) {
                      scope.formModal[scope.screenType][scope.arrayLength].value='';
                      initiateDependency.checkDependency(scope);
                  }
                  else if(scope.initiateSelect2CallBack == true) {
                      initiateDependency.checkDependency(scope);
                  }

                  scope.initiateSelect2CallBack = scope.initiateSelect2CallBack == true ? false : scope.initiateSelect2CallBack;
              }

            if(scope.assetScreenType == 'view') {
                  var newParams = getParams();
                scope.initiateSelect2CallBack = (typeof scope.initiateSelect2CallBack == 'undefined') ? true : false;
                scope.assetDynamicFieldValues = (typeof scope.assetValues[scope.field.questionID] != 'undefined') ? scope.assetValues[scope.field.questionID] : '';
                  $http.get(window.prefixVal + '/listAssetDynamicDropdownOptions?hasParent='+ hasParents +
                      '&assetScreenType=' + scope.assetScreenType +
                      '&optionId=' + scope.selectedOption+
                      '&valueDependencyOption=' + newParams.valueDependencyOption+
                      '&optionValueId=' + scope.assetDynamicFieldValues).success(function (data,status){
                      if(status == 200){
                          var options = _.map(data.options, function(option){ return {id:option.id, text:option.description}});
                          scope.fieldOptions = options[0];

                          // Loading the process level
                          initiateFields.loadProcessingLevel(scope);

                          // Initiate the value dependencies
                          initiateDependency.initiateValueDependencies(scope);
                      }
                  });
            }

              scope.valueDependencyOption = '';
              scope.select2DynamicOptions = {
                //minimumInputLength: 2,
                dropdownCssClass: "bigdrop",
                formatLoadMore: function(){
                    return ' Loading...';
                },
                ajax: {
                    quietMillis: 250,
                    delay: 250,
                    url: window.prefixVal + '/listAssetDynamicDropdownOptions',
                    data: function (query, page) {
                        var params = getParams();
                        params['query'] = encodeURIComponent(query);
                        params['page'] = page;
                        return params;
                    },
                    results: function (data, page) {
                        var options = _.map(data.options, function(option){ return {id:option.id, text:option.description}});
                        return {
                            results: options,
                            more: ((page * 10) < data.optionCounts)
                        };
                    }
                },
                initSelection: function(element, callback) {
                    //# It will only execute for edit screen
                    //# Asseted initiateSelect2CallBack because if we set any value dependency or field dependency
                    //# every time this directive will execute and the init will execute and its looping.
                    scope.initiateSelect2CallBack = (typeof scope.initiateSelect2CallBack == 'undefined') ? true : false;
                      if(scope.assetValues[scope.field.questionID] && scope.initiateSelect2CallBack) {
                          var newParams = '';
                          if(newParams = getParams()) {
                              $http.get(window.prefixVal + '/listAssetDynamicDropdownOptions?hasParent='+ hasParents +
                                  '&assetScreenType=' + scope.assetScreenType +
                                  '&optionId=' + scope.selectedOption+
                                  '&valueDependencyOption=' + newParams.valueDependencyOption+
                                  '&optionValueId=' + scope.assetValues[scope.field.questionID]).success(function (data,status){
                                  if(status == 200){
                                      var options = _.map(data.options, function(option){ return {id:option.id, text:option.description}});
                                      callback(options[0]);

                                      // Loading the process level
                                      initiateFields.loadProcessingLevel(scope);

                                      // Initiate the value dependencies
                                      initiateDependency.initiateValueDependencies(scope);
                                  }
                              });
                          }
                      }
                  },
            }

            if((scope.assetScreenType != 'edit') && (scope.assetScreenType != 'view'))  {
                scope.initiateSelect2CallBack = false;
                // Loading the process level
                initiateFields.loadProcessingLevel(scope);

                // Initiate the value dependencies
                initiateDependency.initiateValueDependencies(scope);
            }


          /*if((typeof scope.field.valueDependencies == 'undefined') || (scope.field.valueDependencies.length == 0) ) {
               // scope.getDynamicDropdownValues("");
          } else if ((scope.assetScreenType == 'edit') || (scope.assetScreenType == 'view') && scope.field.valueDependencies.length != 0) {
                var valueDependencyQuestionIdCount = scope.field.valueDependencies.length,
                valueDependencyQuestionId = scope.field.valueDependencies[valueDependencyQuestionIdCount-1],
                valueDependencyId = scope.assetValues[valueDependencyQuestionId.questionID];

               // scope.getDynamicDropdownValues(valueDependencyId);
          }*/


            scope.openSidepanel = function(data) {
                var modalInstance = $modal.open({
                    windowClass: 'df-action df-action-add_dependant-modal df-action-modal sidepanel',
                    templateUrl: '/template/dynamic-form/modal/customer_add_dynamic_options_modal.html',
                    controller: 'CustomerAddDynamicFieldOptionCtrl',
                    keyboard: false,
                    resolve: {
                        getFieldOptions: function(){
                            return {
                                assetScreenType: scope.assetScreenType,
                                value: scope.formModal[scope.screenType][scope.arrayLength].value,
                                getAllDynamicFields: scope.getAllDynamicFields,
                                formModal: scope.formModal,
                                options: angular.copy(scope.fieldOptions),
                                listUrl: 'listAssetDynamicFieldOptions',
                                data: data,
                                pushOption: scope.fieldOptions,
                                watchValueDependent: scope.watchValueDependent
                            };
                        }
                    }
                });
                modalInstance.result.then(
                  function(data){
                    if(data) {
                      var exists = _.find(scope.fieldOptions, function(x){
                        return x.id == data.id;
                      });
                      if(!exists) {
                        scope.fieldOptions.push(data);
                      }
                      $timeout(function(){
                        //scope.formModal[scope.screenType][scope.arrayLength].value=data.id;
                        scope.formModal[scope.screenType][scope.arrayLength].value={
                          id:data.id,
                          text:data.description
                        };
                        if(scope.onValidate) {
                           scope.onValidate();
                        }
                      },100);
                    }
                  },
                  function(data){
                  });
            };

        };
        return {
            restrict: 'A',
            link: dynamicDropDownLink,
            templateUrl: '/template/dynamic-form-view/dropdown-container.html'
        };
    })
    .directive('dropdownElement', ['dynamicElementsService', 'initiateFields', 'initiateDependency', function(dynamicElementsService, initiateFields, initiateDependency){
        var dropDownLink = function(scope, element, attrs) {
          scope.field=scope.$eval(attrs.data);
          scope.defaultSelectedOption= scope.assetScreenType == 'edit' ? scope.assetValues[scope.field.questionID] : scope.field.defaultValue[0];
          scope.fieldOptions = scope.field.items;
          initiateFields.initiateValues(scope);

            // Loading the process level
            initiateFields.loadProcessingLevel(scope);
        };
        return {
            restrict: 'A',
            link: dropDownLink,
            templateUrl: '/template/dynamic-form-view/dropdown-container.html'
        };
    }])
    .directive('textareaElement', function(initiateFields){
      var textareaLink = function(scope, element, attrs) {
        scope.field=scope.$eval(attrs.data);
        initiateFields.initiateValues(scope);

          // Loading the process level
          initiateFields.loadProcessingLevel(scope);
      };

      return {
          restrict: 'A',
          link: textareaLink,
          templateUrl: '/template/dynamic-form-view/textarea-container.html'
      };
    })
    .directive('checkboxElement', ['$compile', 'initiateFields', 'initiateDependency', 'dynamicElementsService',
        function($compile, initiateFields, initiateDependency, dynamicElementsService) {
        var checkboxLink = function(scope, element, attrs) {
            scope.field=scope.$eval(attrs.data);

            if(scope.assetScreenType == 'view') {
                if(scope.field.type != 'checkbox') {
                    var splitVal = scope.assetValues[scope.field.questionID],
                    splitArray = (splitVal != null) ? splitVal.split(",") : [];
                    for(var i=0; i<scope.field.items.length; i++) {
                        if(splitArray.indexOf(scope.field.items[i].id) != -1)
                        scope.assetMultiCheckboxVal = scope.assetMultiCheckboxVal ? scope.assetMultiCheckboxVal +", "+scope.field.items[i].description : scope.field.items[i].description;
                    }
                }
                // Initiate the scope value
                initiateFields.initiateValues(scope);
                // Loading the process level
                initiateFields.loadProcessingLevel(scope);
            } else {
                // Initiate the scope value
                initiateFields.initiateValues(scope);
                // Loading the process level
                initiateFields.loadProcessingLevel(scope);

                if((scope.field.type != 'checkbox') && (scope.assetScreenType == 'edit')) {
                    var splitVal = scope.assetValues[scope.field.questionID],
                        splitArray = (splitVal != null) ? splitVal.split(",") : [];
                    scope.formModal[scope.screenType][scope.arrayLength].value=splitArray;
                } else if(scope.field.type != 'checkbox') {
                    scope.formModal[scope.screenType][scope.arrayLength].value=scope.field.defaultValue;
                }

                scope.check = function(value, checked) {
                    var validCallbacks = dynamicElementsService.validationCallbackMapping(scope.field.validations);
                    var idx = scope.formModal[scope.screenType][scope.arrayLength].value.indexOf(value);
                    if (idx >= 0 && !checked) {
                        scope.formModal[scope.screenType][scope.arrayLength].value.splice(idx, 1);
                    }
                    if (idx < 0 && checked) {
                        if(scope.formModal[scope.screenType][scope.arrayLength].value === '') {
                            scope.formModal[scope.screenType][scope.arrayLength].value = [];
                        }
                        scope.formModal[scope.screenType][scope.arrayLength].value.push(value);
                    }
                    initiateDependency.checkDependency(scope);
                    scope.$emit('DF:ValidateInputs', scope.formModal);
                    scope.errorMsg = scope.$elementValidation(scope.formModal[scope.screenType][scope.arrayLength].value,validCallbacks);
                };

                scope.getModel = function() {
                    return scope.formModal[scope.screenType][scope.arrayLength].value;
                }
            }
        };
        return {
            restrict: 'A',
            link: checkboxLink,
            templateUrl: '/template/dynamic-form-view/checkbox-container.html'
        };
    }])
    .directive('radiobuttonElement', ['$compile', 'initiateFields', 'initiateDependency', function($compile, initiateFields, initiateDependency){
        var radiobuttonLink = function(scope, element, attrs) {
            scope.field=scope.$eval(attrs.data);
            // Initiate the scope value
            initiateFields.initiateValues(scope);
            // Loading the process level
            initiateFields.loadProcessingLevel(scope);
        };
        return {
            restrict: 'A',
            link: radiobuttonLink,
            templateUrl: '/template/dynamic-form-view/radiobutton-container.html'
        };
    }])
    .controller('DynamicFormDatepicker', function($scope){
      $scope.soureDateValue=null;
      $scope.watchlookup=false;

      $scope.hasFilters = function() {
        return !_.has($scope.field, 'filters') ? false : !_.isEmpty($scope.field.filters);
      };

      $scope.input.filters = {};
      if($scope.hasFilters()) {
        $scope.input.filters = angular.copy($scope.field.filters);
        //console.log('formModal', getSourceValue());

        //scope.soureDateValue = scope.formModal[scope.screenType][scope.arrayLength].value;
        _.forEach($scope.input.filters.rules, function(rule){
          var text = (rule.action === 'add') ? '+' : '-';
          text += rule.value > 1 ? rule.value + ' '+rule.type : rule.value + ' '+rule.type.slice(0, -1);
          rule.text = text;
        });
      }

      $scope.calculateDate = function(rule) {
        if(!_.isObject(getSourceValue())) { console.log('dddd', getSourceValue())
          return false;
        }
        if(rule.action === 'add') {
          $scope.date = moment(getSourceValue()).add(parseFloat(rule.value), rule.type).toDate();
        }
        else {
          $scope.date = moment(getSourceValue()).subtract(parseFloat(rule.value), rule.type).toDate();
        }
          $scope.$broadcast("renderDate",$scope.date);
          //$scope.formModal[$scope.screenType][$scope.arrayLength].value = $scope.date;
          $scope.watchlookup = $scope.watchlookup ? false : true;
      };

      function getSourceValue() {
        var sourceDate = _.find($scope.formModal[$scope.input.filters.screenType],function(modal){
          return modal.key === $scope.input.filters.sourceId;
        })
        if(sourceDate) {
          return new Date(sourceDate.value);
        }
        return '';
      }
    })
    .directive('dateElement', ['$compile', 'initiateFields', 'dateFilter', '$timeout', 'initiateDependency', function($compile, initiateFields, dateFilter, $timeout, initiateDependency){
        var dateLink = function(scope, element, attrs) {
            scope.field=scope.$eval(attrs.data);
            scope.input = {};
            scope.input.datepickerOpened = false;

          /*scope.hasFilters = function() {
            return !_.has(scope.field, 'filters') ? false : !_.isEmpty(scope.field.filters);
          };
          scope.input.filters = {};
          if(scope.hasFilters()) {
            scope.input.filters = angular.copy(scope.field.filters);
            //scope.soureDateValue = scope.formModal[scope.screenType][scope.arrayLength].value;
            console.log('scope.formModal', scope.formModal, scope.input.filters)

            _.forEach(scope.input.filters.rules, function(rule){
              var text = (rule.action === 'add') ? '(+) ' : '(-) ';
              text += rule.value > 1 ? rule.value + ' '+rule.type : rule.value + ' '+rule.type.slice(0, -1);
              rule.text = text;
            });
          }*/

            scope.onDependencyDate = function() {
                if((defaultDate == '') && (scope.field.defaultValue != '') && (scope.assetScreenType != 'view')) {
                    defaultDate = new Date(scope.field.defaultValue[0]);
                } else {
                    defaultDate = new Date();
                }
                scope.$broadcast("renderDate",defaultDate);
            }

            scope.dateFormat = function(d) {
                var month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }

            if(scope.assetScreenType == 'view') {
                // Initiate the scope value
                initiateFields.initiateValues(scope);
                // Loading the process level
                initiateFields.loadProcessingLevel(scope);
            } else {

                var defaultDate = '';
                if((scope.field.defaultValue != '') && (scope.assetScreenType != 'edit') && (scope.field.dependencies.length == 0)) {
                    defaultDate = moment(scope.field.defaultValue[0])._d;
                } else if((scope.assetScreenType == 'edit') && (scope.assetValues[scope.field.questionID])) {
                    defaultDate = moment(scope.assetValues[scope.field.questionID])._d;
                } else if(scope.field.dependencies.length == 0) {
                    defaultDate = moment()._d;
                }

                scope.date = defaultDate;

                // Initiate the scope value
                initiateFields.initiateValues(scope);
                // Loading the process level
                initiateFields.loadProcessingLevel(scope);

                scope.formModal[scope.screenType][scope.arrayLength].value = defaultDate !== '' ? scope.dateFormat(defaultDate) : defaultDate;

                //if element type is date
                scope.date_pickerOpen = function($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                  scope.input.datepickerOpened = true;
                };
                scope.onChangeDate = function(date) {
                    if(date) {
                        date = scope.dateFormat(date);
                    }
                  scope.formModal[scope.screenType][scope.arrayLength].value = date;
                    initiateDependency.checkDependency(scope);
                    //scope.$emit('DF:ValidateInputs', scope.formModal);
                };

                /*scope.onDependencyDate = function() {
                    if((defaultDate == '') && (scope.field.defaultValue != '') && (scope.assetScreenType != 'view')) {
                        defaultDate = new Date(scope.field.defaultValue[0]);
                    } else {
                        defaultDate = new Date();
                    }
                    scope.$broadcast("renderDate",defaultDate);
                }*/

                /*scope.$on("dateScopeChange", function(event, newDateParam) {
                    scope.date= new Date();
                    $('#datepicker-input-'+scope.field.questionID).val(newDateParam);
                    scope.$broadcast("renderDate",newDateParam);
                });*/
            }
        }
        return {
            restrict: 'A',
            link: dateLink,
            templateUrl: '/template/dynamic-form-view/date-container.html'
        };
    }])
    .directive('timeElement', ['initiateFields', function(initiateFields){
        var timeLink = function(scope, element, attrs) {
            scope.field=scope.$eval(attrs.data);
            initiateFields.initiateValues(scope);
            // Loading the process level
            initiateFields.loadProcessingLevel(scope);
            if((scope.assetScreenType == '') && (scope.field.dependencies.length == 0)) {
                scope.formModal[scope.screenType][scope.arrayLength].value = moment().format('YYYY-MM-DD HH:mm');
            } else if(scope.assetScreenType == 'view' && scope.assetValues[scope.field.questionID]) {
                scope.timeFormat = moment(scope.assetValues[scope.field.questionID]).toDate();
            }

            scope.changeEventDateTime = function(name, value) {
                scope.formModal[scope.screenType][scope.arrayLength].value = value;
            };
        };
        return {
            restrict: 'A',
            link: timeLink,
            templateUrl: '/template/dynamic-form-view/time-container.html'
        };
    }])
    .directive('uploadElement', ['$compile', 'initiateFields', function($compile, initiateFields){
        var uploadLink = function(scope, element, attrs) {
            scope.field=scope.$eval(attrs.data);
            scope.imageStyle = {};
            if(scope.assetScreenType === 'view') {
              //field.properties
              if(parseInt(scope.field.properties.width[0])!== 0) {
                scope.imageStyle.width = scope.field.properties.width.join('');
              }
              if(parseInt(scope.field.properties.height[0])!== 0) {
                scope.imageStyle.height = scope.field.properties.height.join('');
              }
            }
            // Initiate the scope value
            initiateFields.initiateValues(scope);
            // Loading the process level
            initiateFields.loadProcessingLevel(scope);

            var validation = _.find(scope.field.validations, function(x) {
                return x.id == 'imageFormat';
            });
            scope.validFileTypes = '';
            if(validation) {
                scope.validFileTypes = validation.rule;
            }

            //console.log('scope.validationCallbacks', scope.validationCallbacks)
            scope.beforeUpload = function(files) {
              //console.log('files', files);
              if(! files.length) {
                return false;
              }
              for (var i = 0; i < files.length; i++) {
                var file = files[i], fileName=file.name, extension=fileName.split('.').pop(), ext=extension.toLowerCase();
              }
            };

            scope.onUploadSuccess = function (resp) { console.log('resp', resp);
                if (!resp.error) {
                    scope.formModal[scope.screenType][scope.arrayLength].value = resp.message.file;
                    scope.displayError = false;

                    scope.$emit('DF:ValidateInputs', scope.formModal);

                } else {
                    scope.formModal[scope.screenType][scope.arrayLength].value = '';
                    scope.errorMessage = resp.message;
                    scope.displayError = true;
                }
            }
        }
        return {
            restrict: 'A',
            link: uploadLink,
            templateUrl: '/template/dynamic-form-view/upload-container.html'
        };
    }])
    .directive('engineerElement', ['prefix','$http','initiateFields',  function(prefix, $http, initiateFields){
        var engineerLink = function(scope, element, attrs) {

            scope.field=scope.$eval(attrs.data);
            scope.selectedOption=scope.field.selectedOption;

            // Initiate the scope value
            initiateFields.initiateValues(scope);
            // Loading the process level
            initiateFields.loadProcessingLevel(scope);

            $http.get(prefix + '/listEngineerDetails').success(function (data,status){
                if(status == 200){
                    scope.fieldOptions = data.records;
                }
            });
        };
        return {
            restrict: 'A',
            link: engineerLink,
            templateUrl: '/template/dynamic-form-view/engineer-container.html'
        }
    }]);
