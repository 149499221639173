commusoftCommon.service('diaryEventLockOption',function () {
    /**
     * Todo Define diary events lock options
     */

    this.no_lock = 0;
    this.lock_by_engineer = 1;
    this.lock_by_datetime = 2;
    this.lock_by_engineer_and_datetime = 3;
    this.lock_by_service_window = 4;
});