/*==========================================================================================
	Companion service to:
	- web/javascripts/commusoft/controllers/add_new_property.js
==========================================================================================*/

csmodule.service('addNewProperty', ['$http', 'prefix', function($http, prefix) {
  /*==========================================================================================
		Endpoints
	==========================================================================================*/
	this.new_customer_path = prefix + '/diary/customer/new';
	this.new_company_path = prefix + '/diary/company/new';
	this.new_work_address_path = prefix + '/diary/work_address/new';
	this.new_landlord_path = prefix + '/diary/landlord/new';

  /*==========================================================================================
		Crud operations
	==========================================================================================*/
	this.saveCustomer = function saveCustomer(customer) {
		return $http.post(this.new_customer_path, 'data=' + encodeURIComponent(angular.toJson(customer)));
	}

	this.saveCompany = function saveCompany(company) {
		return $http.post(this.new_company_path, 'data=' + encodeURIComponent(angular.toJson(company)));
	}

	this.saveWorkAddress = function saveWorkAddress(work_address) {
		return $http.post(this.new_work_address_path, 'data=' + encodeURIComponent(angular.toJson(work_address)));
	}

	this.saveLandlord = function saveLandlord(landlord) {
		return $http.post(this.new_landlord_path, 'data=' + encodeURIComponent(angular.toJson(landlord)));
	}
}]);