'use strict';

var invoicesModule = angular.module('invoices', []);

invoicesModule.constant('prefix', window.prefixVal).
    constant('defaultPagingValue', defaultPaginationLimit).
    constant('excludedFileTypes', excludedFileTypes).
    provider('canLoad',function () {
        this.$get = function () {
            return {
                canLoadAjax: true,
                setLoadValue: function (canLoad) {
                    this.canLoadAjax = canLoad;
                },
                canLoad: function () {
                    return this.canLoadAjax;
                }
            }
        }
    }).
    config(
        ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
            function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {
                $stateProvider.
                    state('loggedin.customer_list.view.job.invoices', {
                        url: Routing.generateAngularRoute('invoices', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoices', params)
                        },
                        data: {
                            'pageNumInvoices': 1,
                            'limitInvoices': 5,
                            'pageNumMilestones': 1,
                            'limitMilestones': 5
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/invoices/view.html',
                                controller: InvoicesCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.new', {
                        url: Routing.generateAngularRoute('new_invoices_old', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_invoices_old', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/invoices/new.html',
                                controller: NewInvoicesCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var job_price_grand_totals_exist = localStorage.getItem('jobs:price:spreadsheetGrandTotals') !== null,
                                job_discount_grand_totals_exist = localStorage.getItem('job_discounts:spreadsheetGrandTotals') !== null;

                            if(job_price_grand_totals_exist) {
                                localStorage.removeItem('jobs:price:spreadsheetGrandTotals');
                            }
                            if(job_discount_grand_totals_exist) {
                                localStorage.removeItem('job_discounts:spreadsheetGrandTotals');
                            }
                        },
                        onExit: function() {
                            var remove_instance_variables_from_spreadsheet = new CustomEvent('state_with_spreadsheet_exited');
                            document.dispatchEvent(remove_instance_variables_from_spreadsheet);
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.newmilestone', {
                        url: Routing.generateAngularRoute('new_milestone_invoices', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_milestone_invoices', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/invoices/new.html',
                                controller: NewInvoicesCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var job_price_grand_totals_exist = localStorage.getItem('jobs:price:spreadsheetGrandTotals') !== null,
                                job_discount_grand_totals_exist = localStorage.getItem('job_discounts:spreadsheetGrandTotals') !== null;

                            if(job_price_grand_totals_exist) {
                                localStorage.removeItem('jobs:price:spreadsheetGrandTotals');
                            }
                            if(job_discount_grand_totals_exist) {
                                localStorage.removeItem('job_discounts:spreadsheetGrandTotals');
                            }
                        },
                        onExit: function() {
                            var remove_instance_variables_from_spreadsheet = new CustomEvent('state_with_spreadsheet_exited');
                            document.dispatchEvent(remove_instance_variables_from_spreadsheet);
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view', {
                        url: Routing.generateAngularRoute('job_invoice_subnav', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_invoice_subnav', params)
                        },
                        views: {
                            "customersPane@": {
                                templateUrl: 'template/jobs/invoices/view_invoice/subnav.html',
                                controller: JobInvoiceSubNavCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.edit', {
                        url: Routing.generateAngularRoute('edit_invoices_old', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_invoices_old', params)
                        },
                        views: {
                            "customerInvoiceFormPane@loggedin.customer_list.view.job.invoices.view": {
                                templateUrl: 'template/jobs/invoices/view_invoice/edit.html',
                                controller: EditCustomerInvoiceCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var job_price_grand_totals_exist = localStorage.getItem('jobs:price:spreadsheetGrandTotals') !== null,
                                job_discount_grand_totals_exist = localStorage.getItem('job_discounts:spreadsheetGrandTotals') !== null;

                            if(job_price_grand_totals_exist) {
                                localStorage.removeItem('jobs:price:spreadsheetGrandTotals');
                            }
                            if(job_discount_grand_totals_exist) {
                                localStorage.removeItem('job_discounts:spreadsheetGrandTotals');
                            }
                        },
                        onExit: function() {
                            var remove_instance_variables_from_spreadsheet = new CustomEvent('state_with_spreadsheet_exited');
                            document.dispatchEvent(remove_instance_variables_from_spreadsheet);
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.view_invoice', {
                        url: Routing.generateAngularRoute('view_invoice', true),
                        templateUrl: function (params) {
                            return Routing.generate('view_invoice', params)
                        },
                        views: {
                            "customerInvoiceTabPane@loggedin.customer_list.view.job.invoices.view": {
                                templateUrl: 'template/jobs/invoices/view_invoice/view.html',
                                controller: InvoiceViewCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.view_invoice.new_payment', {
                        url: Routing.generateAngularRoute('new_customer_invoice_payment', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_customer_invoice_payment', params)
                        },
                        views: {
                            "customerInvoiceFormPane@loggedin.customer_list.view.job.invoices.view": {
                                templateUrl: 'template/jobs/invoices/view_invoice/payments/new.html',
                                controller: NewCustomerInvoicePaymentCtrl,
                                resolve: BreadCrumbsResolver
                            },
                            "customerInvoiceTabPane@loggedin.customer_list.view.job.invoices.view": {
                                template: ''
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.view_invoice.view_payment', {
                        url: Routing.generateAngularRoute('customer_invoice_payment_view', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_invoice_payment_view', params)
                        },
                        views: {
                            "customerInvoiceFormPane@loggedin.customer_list.view.job.invoices.view": {
                                templateUrl: 'template/jobs/invoices/view_invoice/payments/view.html',
                                controller: ViewCustomerInvoicePaymentCtrl,
                                resolve: BreadCrumbsResolver
                            },
                            "customerInvoiceTabPane@loggedin.customer_list.view.job.invoices.view": {
                                template: ''
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.cash_allocation', {
                        url: Routing.generateAngularRoute('customer_cash_allocation', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_cash_allocation', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/cash_allocation/new.html',
                                controller: NewCustomerCashAllocationCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('customer:new_cash_allocation:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('customer:new_cash_allocation:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.cash_allocation', {
                        url: Routing.generateAngularRoute('invoice_address_cash_allocation', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoice_address_cash_allocation', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/customers/cash_allocation/new.html',
                                controller: NewCustomerCashAllocationCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('customer:new_cash_allocation:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('customer:new_cash_allocation:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.new_invoice_address_credit_note', {
                        url: Routing.generateAngularRoute('new_invoice_address_credit_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_invoice_address_credit_note', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/customers/credit_notes/new.html',
                                controller: RaiseCustomerCreditNoteCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('jobs:price:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('jobs:price:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.view_invoice.new_credit_note', {
                        url: Routing.generateAngularRoute('raise_customer_credit_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('raise_customer_credit_note', params)
                        },
                        views: {
                            "customerInvoiceFormPane@loggedin.customer_list.view.job.invoices.view": {
                                templateUrl: 'template/customers/credit_notes/new.html',
                                controller: NewCustomerCreditNoteCtrl,
                                resolve: BreadCrumbsResolver
                            },
                            "customerInvoiceTabPane@loggedin.customer_list.view.job.invoices.view": {
                                template: ''
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('jobs:price:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('jobs:price:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.property.new_credit_note', {
                        url: Routing.generateAngularRoute('raise_credit_note_from_customer', true),
                        templateUrl: function (params) {
                            return Routing.generate('raise_credit_note_from_customer', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/credit_notes/new.html',
                                controller: RaiseCustomerCreditNoteCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('jobs:price:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('jobs:price:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.view_invoice.view_payment.edit', {
                        url: Routing.generateAngularRoute('edit_customer_invoice_payment', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_customer_invoice_payment', params)
                        },
                        views: {
                            "customerInvoiceFormPane@loggedin.customer_list.view.job.invoices.view": {
                                templateUrl: 'template/jobs/invoices/view_invoice/payments/edit.html',
                                controller: EditCustomerInvoicePaymentCtrl,
                                resolve: BreadCrumbsResolver
                            },
                            "customerInvoiceTabPane@loggedin.customer_list.view.job.invoices.view": {
                                template: ''
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('customer:new_cash_allocation:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('customer:new_cash_allocation:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.view_notes', {
                        url: Routing.generateAngularRoute('job_invoice_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_invoice_note', params)
                        },
                        views: {
                            "customerInvoiceTabPane": {
                                templateUrl: 'template/jobs/invoices/view_invoice/communications/view.html',
                                controller: JobInvoiceCommunicationsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.view_notes.table', {
                        url: Routing.generateAngularRoute('job_invoice_notes', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_invoice_notes', params)
                        },
                        data : {
                            'pageNum' : 1,
                            'limit' : 5,
                            'pageNumPhoneCalls': 1,
                            'limitPhoneCalls': 5,
                            'pageNumSmsMessages': 1,
                            'limitSmsMessages': 5,
                            'pageNumEmail': 1,
                            'limitEmail': 5
                        },
                        views:{
                            "customerInvoiceNotesPane": {
                                templateUrl:'template/jobs/invoices/view_invoice/communications/tabular.html',
                                controller: JobInvoiceNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.view_notes.timeline', {
                        url: Routing.generateAngularRoute('job_invoice_timeline', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_invoice_timeline', params)
                        },
                        views: {
                            "customerInvoiceNotesPane": {
                                templateUrl: 'template/jobs/invoices/view_invoice/communications/timeline.html',
                                controller: JobInvoiceTimeLineViewCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.notes_new', {
                        url: Routing.generateAngularRoute('jobs_invoice_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobs_invoice_note', params)
                        },
                        views: {
                            "customerInvoiceFormPane@loggedin.customer_list.view.job.invoices.view": {
                                templateUrl: function (params) {
                                    return Routing.generate('job_invoice_new_note', params)
                                },
                                controller: JobInvoicesNewNotesCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.notes_edit', {
                        url: Routing.generateAngularRoute('edit_job_invoice_notes', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_job_invoice_notes', params)
                        },
                        views: {
                            "customerInvoiceFormPane@loggedin.customer_list.view.job.invoices.view": {
                                templateUrl: 'template/jobs/invoices/view_invoice/communications/edit_note.html',
                                controller: JobInvoiceEditNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.notes_new_smsmessage', {
                        url: Routing.generateAngularRoute('job_invoice_smsmessage', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_invoice_smsmessage', params)
                        },
                        views: {
                            "customerInvoiceFormPane@loggedin.customer_list.view.job.invoices.view": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_invoice_jobmessages', params)
                                },
                                controller: JobInvoiceNewSmsMessageCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.invoices.view.notes_new_phonecall', {
                        url: Routing.generateAngularRoute('job_invoice_phone_call', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_invoice_phone_call', params)
                        },
                        views: {
                            "customerInvoiceFormPane@loggedin.customer_list.view.job.invoices.view": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_invoice_jobphone_call', params)
                                },
                                controller: JobInvoiceNewPhoneCallCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                state('loggedin.customer_list.view.new_multiple_invoice_payment', {
                    url: Routing.generateAngularRoute('new_multiple_invoice_payment', true),
                    templateUrl: function (params) {
                        return Routing.generate('new_multiple_invoice_payment', params)
                    },
                    views: {
                        "customerTabPane@": {
                            templateUrl: 'template/jobs/invoices/view_invoice/payments/new.html',
                            controller: NewCustomerInvoicePaymentCtrl,
                            resolve: BreadCrumbsResolver
                        },
                    }
                }).
                state('loggedin.customer_list.view.view_multiple_invoice_payment', {
                    url: Routing.generateAngularRoute('view_multiple_invoice_payment', true),
                    templateUrl: function (params) {
                        return Routing.generate('view_multiple_invoice_payment', params)
                    },
                    views: {
                        "customerTabPane@": {
                            templateUrl: 'template/jobs/invoices/view_invoice/payments/view.html',
                            controller: ViewCustomerInvoicePaymentCtrl,
                            resolve: BreadCrumbsResolver
                        },
                    }
                }).
                state('loggedin.customer_list.view.edit_multiple_invoice_payment', {
                    url: Routing.generateAngularRoute('edit_multiple_invoice_payment', true),
                    templateUrl: function (params) {
                        return Routing.generate('edit_multiple_invoice_payment', params)
                    },
                    views: {
                        "customerTabPane@": {
                            templateUrl: 'template/jobs/invoices/view_invoice/payments/edit.html',
                            controller: EditCustomerInvoicePaymentCtrl,
                            resolve: BreadCrumbsResolver
                        },
                    }
                }).
                state('loggedin.invoice_address.view.multiple_invoice_payment', {
                    url: Routing.generateAngularRoute('new_invoice_address_multiple_invoice_payment', true),
                    templateUrl: function (params) {
                        return Routing.generate('new_invoice_address_multiple_invoice_payment', params)
                    },
                    views: {
                        "invoiceTabPane@": {
                            templateUrl: 'template/jobs/invoices/view_invoice/payments/new.html',
                            controller: NewCustomerInvoicePaymentCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).
                state('loggedin.invoice_address.view.view_multiple_invoice_payment', {
                    url: Routing.generateAngularRoute('view_invoice_address_multiple_invoice_payment', true),
                    templateUrl: function (params) {
                        return Routing.generate('view_invoice_address_multiple_invoice_payment', params)
                    },
                    views: {
                        "invoiceTabPane@": {
                            templateUrl: 'template/jobs/invoices/view_invoice/payments/view.html',
                            controller: ViewCustomerInvoicePaymentCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).state('loggedin.customer_list.view.invoice_view', {
                    url: Routing.generateAngularRoute('contract_invoice_subnav', true),
                    templateUrl: function (params) {
                        return Routing.generate('contract_invoice_subnav', params)
                    },
                    views: {
                        "customersPane@": {
                            templateUrl: 'template/jobs/invoices/view_invoice/contract-invoice/subnav.html',
                            controller: ContractInvoiceSubNavCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).state('loggedin.customer_list.view.invoice_view.view_invoice_contract', {
                    url: Routing.generateAngularRoute('view_invoice_contract', true),
                    templateUrl: function (params) {
                        return Routing.generate('view_invoice_contract', params)
                    },
                    views: {
                        "contractInvoiceTabPane@loggedin.customer_list.view.invoice_view": {
                            templateUrl: 'template/jobs/invoices/view_invoice/contract-invoice/view.html',
                            controller: ContractInvoiceViewCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).state('loggedin.customer_list.view.invoice_view.contract_new_payment', {
                    url: Routing.generateAngularRoute('new_contract_customer_invoice_payment', true),
                    templateUrl: function (params) {
                        return Routing.generate('new_contract_customer_invoice_payment', params)
                    },
                    views: {
                        "contractInvoiceTabPane@loggedin.customer_list.view.invoice_view": {
                            templateUrl: 'template/jobs/invoices/view_invoice/payments/new.html',
                            controller: NewCustomerInvoicePaymentCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).state('loggedin.customer_list.view.invoice_view.view_invoice_payment', {
                    url: Routing.generateAngularRoute('contract_invoice_payment_view', true),
                    templateUrl: function (params) {
                        return Routing.generate('contract_invoice_payment_view', params)
                    },
                    views: {
                        "contractInvoiceTabPane@loggedin.customer_list.view.invoice_view": {
                            templateUrl: 'template/jobs/invoices/view_invoice/payments/view.html',
                            controller: ViewCustomerInvoicePaymentCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).state('loggedin.customer_list.view.invoice_view.edit_invoice_payment', {
                    url: Routing.generateAngularRoute('edit_contract_invoice_payment', true),
                    templateUrl: function (params) {
                        return Routing.generate('edit_contract_invoice_payment', params)
                    },
                    views: {
                        "contractInvoiceTabPane@loggedin.customer_list.view.invoice_view": {
                            templateUrl: 'template/jobs/invoices/view_invoice/payments/edit.html',
                            controller: EditCustomerInvoicePaymentCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    },
                    onEnter: function() {
                        var grand_totals_exist = localStorage.getItem('customer:new_cash_allocation:spreadsheetGrandTotals') !== null;
                        if(grand_totals_exist) {
                            localStorage.removeItem('customer:new_cash_allocation:spreadsheetGrandTotals');
                        }
                    }
                }).state('loggedin.customer_list.view.invoice_view.view_notes', {
                    url: Routing.generateAngularRoute('contract_invoice_note', true),
                    templateUrl: function (params) {
                        return Routing.generate('contract_invoice_note', params)
                    },
                    views: {
                        "contractInvoiceTabPane": {
                            templateUrl: 'template/jobs/invoices/view_invoice/communications/view.html',
                            controller: JobInvoiceCommunicationsCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).state('loggedin.customer_list.view.invoice_view.view_notes.table', {
                    url: Routing.generateAngularRoute('contract_invoice_notes', true),
                    templateUrl: function (params) {
                        return Routing.generate('contract_invoice_notes', params)
                    },
                    data : {
                        'pageNum' : 1,
                        'limit' : 5,
                        'pageNumPhoneCalls': 1,
                        'limitPhoneCalls': 5,
                        'pageNumSmsMessages': 1,
                        'limitSmsMessages': 5,
                        'pageNumEmail': 1,
                        'limitEmail': 5
                    },
                    views:{
                        "customerInvoiceNotesPane": {
                            templateUrl:'template/jobs/invoices/view_invoice/communications/tabular.html',
                            controller: JobInvoiceNotesCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                }).state('loggedin.customer_list.view.invoice_view.view_notes.timeline', {
                    url: Routing.generateAngularRoute('contract_invoice_timeline', true),
                    templateUrl: function (params) {
                        return Routing.generate('contract_invoice_timeline', params)
                    },
                    views: {
                        "customerInvoiceNotesPane": {
                            templateUrl: 'template/jobs/invoices/view_invoice/communications/timeline.html',
                            controller: JobInvoiceTimeLineViewCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                }).
                state('loggedin.customer_list.view.invoice_view.notes_new', {
                    url: Routing.generateAngularRoute('contract_invoice_note_get', true),
                    templateUrl: function (params) {
                        return Routing.generate('contract_invoice_note_get', params)
                    },
                    views: {
                        "contractInvoiceFormPane@loggedin.customer_list.view.invoice_view": {
                            templateUrl: function (params) {
                                return Routing.generate('contract_invoice_new_note', params)
                            },
                            controller: JobInvoicesNewNotesCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).
                state('loggedin.customer_list.view.invoice_view.notes_new_smsmessage', {
                    url: Routing.generateAngularRoute('contract_invoice_smsmessage', true),
                    templateUrl: function (params) {
                        return Routing.generate('contract_invoice_smsmessage', params)
                    },
                    views: {
                        "contractInvoiceFormPane@loggedin.customer_list.view.invoice_view": {
                            templateUrl: function (params) {
                                return Routing.generate('new_invoice_contractmessages', params)
                            },
                            controller: JobInvoiceNewSmsMessageCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                }).
                state('loggedin.customer_list.view.invoice_view.notes_new_phonecall', {
                    url: Routing.generateAngularRoute('contract_invoice_phone_call', true),
                    templateUrl: function (params) {
                        return Routing.generate('contract_invoice_phone_call', params)
                    },
                    views: {
                        "contractInvoiceFormPane@loggedin.customer_list.view.invoice_view": {
                            templateUrl: function (params) {
                                return Routing.generate('new_invoice_contractphone_call', params)
                            },
                            controller: JobInvoiceNewPhoneCallCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                }).
                state('loggedin.customer_list.view.invoice_view.new_credit_note', {
                    url: Routing.generateAngularRoute('raise_contract_credit_note', true),
                    templateUrl: function (params) {
                        return Routing.generate('raise_contract_credit_note', params)
                    },
                    views: {
                        "contractInvoiceFormPane@loggedin.customer_list.view.invoice_view": {
                            templateUrl: 'template/customers/credit_notes/new.html',
                            controller: NewCustomerCreditNoteCtrl,
                            resolve: BreadCrumbsResolver
                        },
                        "customerInvoiceTabPane@loggedin.customer_list.view.invoice_view": {
                            template: ''
                        }
                    },
                    onEnter: function() {
                        var grand_totals_exist = localStorage.getItem('jobs:price:spreadsheetGrandTotals') !== null;
                        if(grand_totals_exist) {
                            localStorage.removeItem('jobs:price:spreadsheetGrandTotals');
                        }
                    }
                }).
                state('loggedin.invoice_address.view.edit_multiple_invoice_payment', {
                    url: Routing.generateAngularRoute('edit_invoice_address_multiple_invoice_payment', true),
                    templateUrl: function (params) {
                        return Routing.generate('edit_invoice_address_multiple_invoice_payment', params)
                    },
                    views: {
                        "invoiceTabPane@": {
                            templateUrl: 'template/jobs/invoices/view_invoice/payments/edit.html',
                            controller: EditCustomerInvoicePaymentCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).
                state('loggedin.customer_list.view.opportunity_invoice_view', {
                    url: Routing.generateAngularRoute('opportunity_invoice_subnav', true),
                    templateUrl: function (params) {
                        return Routing.generate('opportunity_invoice_subnav', params)
                    },
                    views: {
                        "customersPane@": {
                            templateUrl: 'template/jobs/invoices/view_invoice/subnav.html',
                            controller: OpportunityInvoiceSubNavCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).
                state('loggedin.customer_list.view.opportunity_invoice_view.view_invoice', {
                    url: Routing.generateAngularRoute('opportunity_view_invoice', true),
                    templateUrl: function (params) {
                        return Routing.generate('opportunity_view_invoice', params)
                    },
                    views: {
                        "customerInvoiceTabPane@loggedin.customer_list.view.opportunity_invoice_view": {
                            templateUrl: 'template/jobs/invoices/view_invoice/view.html',
                            controller: OpportunityInvoiceViewCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).state('loggedin.customer_list.view.opportunity_invoice_view.opportunity_new_payment', {
                    url: Routing.generateAngularRoute('new_opportunity_customer_invoice_payment', true),
                    templateUrl: function (params) {
                        return Routing.generate('new_opportunity_customer_invoice_payment', params)
                    },
                    views: {
                        "customerInvoiceFormPane@loggedin.customer_list.view.opportunity_invoice_view": {
                            templateUrl: 'template/jobs/invoices/view_invoice/payments/new.html',
                            controller: NewCustomerInvoicePaymentCtrl,
                            resolve: BreadCrumbsResolver
                        },
                        "customerInvoiceTabPane@loggedin.customer_list.view.job.invoices.view": {
                            template: ''
                        }
                    }
                }).
                state('loggedin.customer_list.view.opportunity_invoice_view.edit_invoice_payment', {
                    url: Routing.generateAngularRoute('edit_opportunity_invoice_payment', true),
                    templateUrl: function (params) {
                        return Routing.generate('edit_opportunity_invoice_payment', params)
                    },
                    views: {
                        "customerInvoiceTabPane@loggedin.customer_list.view.opportunity_invoice_view": {
                            templateUrl: 'template/jobs/invoices/view_invoice/payments/edit.html',
                            controller: EditCustomerInvoicePaymentCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    },
                    onEnter: function() {
                        var grand_totals_exist = localStorage.getItem('customer:new_cash_allocation:spreadsheetGrandTotals') !== null;
                        if(grand_totals_exist) {
                            localStorage.removeItem('customer:new_cash_allocation:spreadsheetGrandTotals');
                        }
                    }
                }).
                state('loggedin.customer_list.view.opportunity_invoice_view.view_invoice_payment', {
                    url: Routing.generateAngularRoute('opportunity_invoice_payment_view', true),
                    templateUrl: function (params) {
                        return Routing.generate('opportunity_invoice_payment_view', params)
                    },
                    views: {
                        "customerInvoiceTabPane@loggedin.customer_list.view.opportunity_invoice_view": {
                            templateUrl: 'template/jobs/invoices/view_invoice/payments/view.html',
                            controller: ViewCustomerInvoicePaymentCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).state('loggedin.customer_list.view.opportunity_invoice_view.view_notes', {
                    url: Routing.generateAngularRoute('opportunity_invoice_note', true),
                    templateUrl: function (params) {
                        return Routing.generate('opportunity_invoice_note', params)
                    },
                    views: {
                        "customerInvoiceTabPane": {
                            templateUrl: 'template/jobs/invoices/view_invoice/communications/view.html',
                            controller: JobInvoiceCommunicationsCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).state('loggedin.customer_list.view.opportunity_invoice_view.view_notes.timeline', {
                    url: Routing.generateAngularRoute('opportunity_invoice_timeline', true),
                    templateUrl: function (params) {
                        return Routing.generate('opportunity_invoice_timeline', params)
                    },
                    views: {
                        "customerInvoiceNotesPane": {
                            templateUrl: 'template/jobs/invoices/view_invoice/communications/timeline.html',
                            controller: JobInvoiceTimeLineViewCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                }).state('loggedin.customer_list.view.opportunity_invoice_view.notes_new', {
                    url: Routing.generateAngularRoute('opportunity_invoice_note_get', true),
                    templateUrl: function (params) {
                        return Routing.generate('opportunity_invoice_note_get', params)
                    },
                    views: {
                        "customerInvoiceFormPane@loggedin.customer_list.view.opportunity_invoice_view": {
                            templateUrl: function (params) {
                                return Routing.generate('opportunity_invoice_new_note', params)
                            },
                            controller: JobInvoicesNewNotesCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).state('loggedin.customer_list.view.opportunity_invoice_view.view_notes.table', {
                    url: Routing.generateAngularRoute('opportunity_invoice_notes', true),
                    templateUrl: function (params) {
                        return Routing.generate('opportunity_invoice_notes', params)
                    },
                    data : {
                        'pageNum' : 1,
                        'limit' : 5,
                        'pageNumPhoneCalls': 1,
                        'limitPhoneCalls': 5,
                        'pageNumSmsMessages': 1,
                        'limitSmsMessages': 5,
                        'pageNumEmail': 1,
                        'limitEmail': 5
                    },
                    views:{
                        "customerInvoiceNotesPane": {
                            templateUrl:'template/jobs/invoices/view_invoice/communications/tabular.html',
                            controller: JobInvoiceNotesCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                }).state('loggedin.customer_list.view.opportunity_invoice_view.notes_new_smsmessage', {
                    url: Routing.generateAngularRoute('opportunity_invoice_smsmessage', true),
                    templateUrl: function (params) {
                        return Routing.generate('opportunity_invoice_smsmessage', params)
                    },
                    views: {
                        "customerInvoiceFormPane@loggedin.customer_list.view.opportunity_invoice_view": {
                            templateUrl: function (params) {
                                return Routing.generate('new_invoice_opportunitymessages', params)
                            },
                            controller: JobInvoiceNewSmsMessageCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                }).
                state('loggedin.customer_list.view.opportunity_invoice_view.notes_new_phonecall', {
                    url: Routing.generateAngularRoute('opportunity_invoice_phone_call', true),
                    templateUrl: function (params) {
                        return Routing.generate('opportunity_invoice_phone_call', params)
                    },
                    views: {
                        "customerInvoiceFormPane@loggedin.customer_list.view.opportunity_invoice_view": {
                            templateUrl: function (params) {
                                return Routing.generate('new_invoice_opportunityphone_call', params)
                            },
                            controller: JobInvoiceNewPhoneCallCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                });
            }
        ]);
