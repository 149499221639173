commusoftCommon.directive('hasRootPermission', ['prefix', '$http', '$rootScope', 'hasCheckPermission', function(prefix, $http, $rootScope, hasCheckPermission) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var featureAndAccess = attrs.hasRootPermission;
            scope.toggleVisibilityBasedOnPermission = function() {
                for(var i=0;i<hasCheckPermission[featureAndAccess].length;i++){
                    var feature = hasCheckPermission[featureAndAccess][i];
                    var featureName = feature.featureName;
                    var featureAccess = feature.featureAccess;
                    var featureId = features[featureName];
                    var hasPermission = $rootScope.hasPermission(featureId, featureAccess);
                    if(hasPermission) {
                        element.show();
                        break;
                    }
                }
                if(!hasPermission) {
                    element.hide();
                }
            }

            scope.toggleVisibilityBasedOnPermission();
            scope.$on('permissionsChanged', function() {
                scope.toggleVisibilityBasedOnPermission();
            });
        }
    }
}]);