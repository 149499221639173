commusoftCommon.directive('reportingOperationalTable', ['prefix', '$http', '$modal', 'tableCollection', '$document', '$timeout', 'clickEvents', '$rootScope', '$compile', 'dragHelper', '$state', 'formPanelCollection','reportFilterSettings', function(prefix, $http, $modal, tableCollection, $document, $timeout, clickEvents, $rootScope, $compile, dragHelper, $state, formPanelCollection,reportFilterSettings) {
    return {
        restrict: 'ACE',
        link: function($scope, elem, attrs) {
            $scope.counts = {};
            $scope.visibleRows = {};
            $scope.searchMode = false;
            $scope.highlightedJob = {};
            $scope.firstRun = true;
            $scope.loadingDataLock = false;
            $scope.tableId = 'operational-table';
            $scope.operationalTableSpinner = true;
            $scope.finishedDateScrolling = false;
            $scope.selectedParts = {};
            $scope.selectedPartIds = {};
            $scope.currentFilters = undefined;
            $scope.oldFilters = undefined;
            $scope.selectedStockLoc = undefined;
            $scope.selectedStockLocation = undefined;
            $scope.stockLocationInvalid = false;
            $scope.resetResults = {};
            $scope.noRecordFound = {};
            $scope.validateStockAllocationStarted = false;
            $scope.currentPageDefault = 1;
            $scope.currentPage = {};
            $scope.pageLimitDefault = 10;
            $scope.pageLimit = {};
            $scope.disableInfiniteScrollDefault = false;
            $scope.disableInfiniteScroll = {};
            $scope.reachedEndOfParts = {};
            $scope.stickyHeader = false;
            $scope.todaysDate = false;
            $scope.tabScrollPosition = {};
            $scope.filterStorage = {};

            $scope.$on('selectedStockLocationChanged', function(evt, loc) {
                $scope.selectedStockLocation = loc;
            });

            $scope.getSelectedParts = function() {
              return $scope.selectedParts[$scope.mode];
            };

            $scope.generatePartAllocationTotal = function generatePartAllocationTotal(locations) {
                var allocation_total = 0;
                for(var i = 0, l = locations.length; i < l; i++) {
                    var allocation = parseFloat(locations[i].allocation);
                    allocation_total += allocation;
                }

                return allocation_total;
            };

            $scope.$on('updateSelectedParts', function(evt, part, isSinglePart) {
              var selectedParts = angular.copy($scope.selectedParts[$scope.mode]);
              var partId = part.jobpartid;
              var podetailid = part.podetailid;
              var exists = _.reject(selectedParts, function(item){
                  if($scope.mode === 'on_order' && $scope.clientJobType === 3) {
                      return podetailid === item.podetailid;
                  }else{
                      return partId === item.jobpartid;
                  }
              });
              if(part.selected) {
                exists.push(part);
              }
              $scope.sidePanelPart = $scope.selectedParts[$scope.mode] = exists;
              if ($scope.mode == 'requested' && isSinglePart) {
                $rootScope.$broadcast('smart_table:pinned_rows_updated', $scope.selectedParts[$scope.mode]);
              }
              return;
              /*
                var indexesToSplice = [];
                if (part.selected) {
                    var partAlreadyAdded = false;
                    angular.forEach($scope.selectedParts[$scope.mode], function(selectedPart, index) {
                        if (selectedPart.$$hashKey == part.$$hashKey) {
                            partAlreadyAdded = true;
                        }
                    });

                    if (!partAlreadyAdded) {
                        $scope.selectedParts[$scope.mode].push(part);
//                               $scope.selectedPartIds[$scope.mode].push(part.jobpartid);
                    }
                }
                else {
//                      delete $scope.selectedParts[part.partid];
                    angular.forEach($scope.selectedParts[$scope.mode], function(entry, index) {
                        if (part.$$hashKey == entry.$$hashKey) {
                            indexesToSplice.push(index);
                        }
                    });

                    angular.forEach(indexesToSplice, function(indexToSplice, index) {
                        $scope.selectedParts[$scope.mode].splice(indexToSplice, 1);
                    });
                    /*
                                           var partIdIndex = $scope.selectedPartIds[$scope.mode].indexOf(part.jobpartid);
                                           $scope.selectedPartIds[$scope.mode].splice(partIdIndex, 1);

                }

                if ($scope.mode == 'requested') {
                    $rootScope.$broadcast('smart_table:pinned_rows_updated', $scope.selectedParts[$scope.mode]);
                }

                $scope.sidePanelPart = angular.copy($scope.selectedParts[$scope.mode]);
              */
            });

            $scope.$on('reportFilterLoading', function(evt, id, newvalue) {
                if (id == $scope.tableId) {
                    $scope.operationalTableSpinner = newvalue;
                }
            });

            $scope.saveTabState = function(fromTab, toTab) {
                /*
                                   var toTabFilters = [];
                                   // This fetches the list of applied filters shown on the side of the search bar in blue bubble-shaped tags
                                   // This uses a different cookie than the one in ReportResolver (in controllers.js) because this deals specifically with Operational lists, not just regular reports.
                                   if ($window.localStorage.getItem('reportingOperationalListFilters_' + toTab) !== null) {
                                        // The reason for this for-loop is so that we don't assign an empty 'filters' object
                                        angular.forEach(angular.fromJson($window.localStorage.getItem('reportingOperationalListFilters_' + toTab))['filters'], function(filter, index) {
                                                if (filter !== "{}") {
                                                        toTabFilters.push(filter);
                                                }
                                        });
                                        $scope.filterStorage[toTab] = {
                                                'filters': toTabFilters,
                                                'count': angular.fromJson($window.localStorage.getItem('reportingOperationalListFilters_' + toTab))['count']
                                        }
                                   }
                */

                // The reason for this for-loop is so that we don't assign $scope.currentFilters if it is an empty object
                /*
                                  angular.forEach($scope.currentFilters, function(filter, index) {
                                      if (index == 0) {
                                          $scope.filterStorage[fromTab] = {
                                                'filters': $scope.currentFilters,
                                                'count': $scope.counts[fromTab]
                                          }

                                          $window.localStorage.setItem('reportingOperationalListFilters_' + fromTab, angular.toJson($scope.filterStorage[fromTab]));
                                          temporaryCookies.trackLocalstorage('reportingOperationalListFilters_' + fromTab);
                                      }
                                  });
                */

                // Saving selected parts, if any
                $scope.data[fromTab] = $scope.operationalList;

                /*
                                  $scope.currentFilters = {};
                                   if ($scope.filterStorage[toTab] !== undefined && $scope.filterStorage[toTab] !== null) {
                                        angular.forEach($scope.filterStorage[toTab]['filters'], function(tag, index) {
                                                var model_name = tag['model_name'];
                                                if (model_name !== undefined) {
                                                        $scope.currentFilters[model_name] = tag['value'];
                                                }
                                        });
                                   }
                */
                return $scope.currentFilters;
            };
            $scope.loadMoreResults = function(evt) {
                evt.preventDefault();
                $scope.data[$scope.mode] = $scope.operationalList;
                $scope.currentPage[$scope.mode]++;
                $scope.getData($scope.currentFilters, true);
            }

            $scope.initTable = function(tabchange) {
                var oldmode = $scope.mode;
                $scope.mode = $state.params.type;

                // Setting defaults. The values in these settings will be preserved between tab switches
                if (!$scope.currentPage[$scope.mode]) {
                    $scope.currentPage[$scope.mode] = $scope.currentPageDefault;
                }
                if (!$scope.pageLimit[$scope.mode]) {
                    $scope.pageLimit[$scope.mode] = $scope.pageLimitDefault;
                }
                if (!$scope.disableInfiniteScrollDefault[$scope.mode]) {
                    $scope.disableInfiniteScroll[$scope.mode] = $scope.disableInfiniteScrollDefault;
                }

                $scope.finishedDateScrolling = false;
                if (oldmode !== $scope.mode) {
                    var filters = angular.toJson($scope.saveTabState(oldmode, $scope.mode));
                }
                if ($scope.resetResults[$scope.mode]) {
                    $scope.getData(filters);
                }
                if (!$scope.data[$scope.mode]) {
                    $scope.operationalTableSpinner = true;
                    $scope.getData(filters);
                }
                else {
                    // This updates the list of available filters which shows up in the dropdown when you click on the filter search bar.
                    $rootScope.$broadcast('updateOperationalTableFilters', $scope.tableFilters[$scope.mode], $scope.mode);
                    $scope.$emit('panelwithform:status_fromOperationalList', $scope.mode);
                    formPanelCollection.setFilterData($scope.tableFilters);

                    // This updates the results list
                    $scope.operationalList = angular.copy($scope.data[$scope.mode]);
                    $scope.operationalTableSpinner = false;

                    // On order is skipped here because when the user switches to On Order, we will smooth scroll straight to today's date, and a previously-saved tab scroll position
                    if ($scope.mode !== 'on_order') {
                        $scope.smoothScrollToPosition($scope.tabScrollPosition[$scope.mode]);
                    }
                }
                $scope.visibleRows = {};
                $scope.searchMode = false;
                $scope.appliedFilters = {};

                if (tabchange) {
                    $rootScope.$broadcast('changeOperationalTableTab', oldmode, $scope.mode); // Old tab and new tab names
                }

                if (!$scope.highlightedJob[$scope.mode]) {
                    $scope.highlightedJob[$scope.mode] = false;
                }
            };

            $scope.$on('$stateChangeSuccess', function(e, toState, toParams) {
                $scope.firstRun = false;
                $scope.initTable(true);
            });
            $scope.tableFilters = {};

            $scope.mode = $state.params.type;
            $scope.data = {};

            $scope.switchTab = function(e, newtab) {
                e.preventDefault();
                e.stopPropagation();
                $scope.mode = newtab;
                $scope.operationalList = $scope.data[$scope.mode];
                $scope.getData();
            };

            $scope.getVisibleRows = function(object, rowkey, row) {
                angular.forEach($scope.appliedFilters, function(entry, index) {
                    index = String(index);
                    entry = String(entry);
                    if (typeof object !== "object") {
                        object = String(object);
                        rowkey = String(rowkey);
                        if (String(object).indexOf(entry) > -1 && index == rowkey) {
                            if (row.found.indexOf(index) < 0) {
                                row.found.push(index);
                            }
                            if (Object.keys($scope.appliedFilters).length == row.found.length) {
                                row.visible = true;
                            }
                        }
                    }
                    else {
                        angular.forEach(object, function(value, key) {
                            $scope.getVisibleRows(value, key, row);
                        });
                    }
                });
                return row.visible;
            };

            $scope.isRowVisible = function(row) {
                if (!$scope.searchMode) {
                    return true;
                }

                angular.forEach(row, function(col, colkey) {
                    if (!row.found) {
                        row.found = [];
                    }

                    $scope.getVisibleRows(col, colkey, row);
                });

                if (row.found.length !== Object.keys($scope.appliedFilters).length) {
                    row.visible = false;
                }

                row.found = [];

                return row.visible;
            };
            $scope.loopThroughObject = function(object, filter, filterKey) {
                angular.forEach(object, function(entry, index) {
                    if (typeof entry !== "object") {
                        if (String(entry).indexOf(filter) > -1) {
                            index = String(index);
                            $scope.visibleRows[index] = String(entry);
                        }
                    }
                    else {
                        $scope.loopThroughObject(entry, filter, filterKey);
                    }
                });
            };

            $scope.$on('partFulfilled', function(e, partdata, jobid) {
                $scope.unselectAllParts();
                $scope.data[$scope.mode] = $scope.operationalList;
                $scope.currentPage[$scope.mode] = 1;
                $scope.getData($scope.currentFilters, true);
            });

            $scope.resetTab = function(filters) {
                $scope.unselectAllParts();
                $scope.currentPage[$scope.mode] = 1;
                $scope.data[$scope.mode] = $scope.operationalList;
                $scope.getData(filters);
            };

            $scope.$on('operational_table:filtervalues', function(e, filters) {
                /*
                                  $scope.searchMode = true;
                                  $scope.visibleRows = {};
                                  $scope.appliedFilters = angular.fromJson(filters);

                                  if (Object.keys($scope.appliedFilters).length == 0) {
                                    $scope.searchMode = false;
                                  }
                                  else {
                                    angular.forEach($scope.appliedFilters, function(filter, filterKey) {
                                      $scope.loopThroughObject($scope.data, filter, filterKey);
                                    });
                                  }
                */
                $scope.currentFilters = angular.copy(filters);
                $scope.resetTab(filters);
            });
            // end $scope.$on

            $scope.initTable();
        },
        controller: function($scope) {
            $scope.firstRun = true;

            $scope.selectedJobs = {
            };

            $scope.highlightedJob = {};

            $scope.isPartCheckboxDisabled = function(part) {
                if (part.isJobComplete == 1 && $scope.mode == 'available') {
                    return true;
                }
                return false;
            };

            $scope.areAllPartsSelectable = function (job) {
                var arePartsSelectable = true, _partSelected=[];
                var completedJobs = _.pluck(job.parts, 'isJobComplete') ;
                if($scope.mode == 'on_order') {
                    return !_.contains(completedJobs, 0);
                }
                angular.forEach(job.parts, function (part, partindex) {
                    if (part['isJobComplete'] == 1 && $scope.mode == 'available') {
                        arePartsSelectable = false;
                    }
                    if (part['isJobComplete'] == 1 && $scope.mode == 'on_order') {
                        arePartsSelectable = false;
                    }
                });
                return arePartsSelectable;
            }

            $scope.isCheckboxDisabled = function(job) {
                if (job.isJobComplete == 1 && $scope.mode == 'available') {
                    return true;
                }

                if ($scope.highlightedJob[$scope.mode] !== false) {
                    if ($scope.mode == 'on_order' && $scope.clientJobType != 1) {
                        /*
                                                     if (job.date !== $scope.highlightedJob[$scope.mode]) {
                                                                return true;
                                                     }
                        */
                        return false;
                    }
                    else {
                        if (job.jobnumber !== $scope.highlightedJob[$scope.mode]) {
                            return true;
                        }
                    }
                }
                return false;
            };

            $scope.createNewDiaryEvent = function() {
                if (!$scope.highlightedJob[$scope.mode]) {
                    return false;
                }
                localStorage.setItem('operational_diary_event', angular.toJson({category: $state.params.category, subcategory: $state.params.subcategory}));
                $state.go('loggedin.diary_from_job_estimate', {'type': 'job', 'typeId': $scope.highlightedJob[$scope.mode]});
            };

            $scope.arePartsSelected = function() {
                if ($scope.highlightedJob[$scope.mode]) {
                    return true;
                }
                return false;
            };

            $scope.getSelectedPartsFulfillmentIds = function() {
                var fulfillmentIds = [], podetailids = [], result = [];
                angular.forEach($scope.sidePanelPart, function(part, index) {
                    if (part.fulfillmentid) {
                        fulfillmentIds.push(part.fulfillmentid);
                    }else{
                        podetailids.push(part.podetailid);
                    }
                });
                result['podetailids'] = podetailids;
                result['fulfillmentIds'] = fulfillmentIds;
                return result;
            };

            $scope.getDestinationOptions = function() {
                return $scope.stockLocations;
            }

            $scope.isStockLocationInvalid = function() {
                var invalid = false;
                if ($scope.selectedStockLocation < 1 || $scope.selectedStockLocation === null || $scope.selectedStockLocation === '') {
                    invalid = true;
                }
                return invalid;
            }

            $scope.isPartQuantityInvalid = function(part) {
                if (part.quantity == "") {
                    return true;
                }
                return false;
            }

            $scope.validateQuantity = function(quantity, part, index){
                var input_is_number = !isNaN(parseFloat(quantity));

                if(input_is_number) {
                    $scope.isQuantityInBounds(part, index);
                } else {
                    part.error = true;
                    part.error_message = 'Please enter a valid quantity';
                }
                $scope.checkIfAnyPartsAreInvalid();
            };

            $scope.isQuantityInBounds = function isQuantityInBounds(part, index) {
                if ((parseFloat(part.quantity) < parseFloat(part.quantityModel)) || parseFloat(part.quantityModel) < 0) {
                    part.error = true;

                    if (parseFloat(part.quantityModel) < 0) {
                        part.error_message = 'Quantity cannot be negative';
                    } else {
                        part.error_message = 'There aren\'t enough parts to make available';
                    }
                } else {
                    part.error = false;
                    part.error_message = '';
                }
                if (parseFloat(part.available_quantity) == 0 ){
                    part.error = true;
                    part.error_message = 'Quantity cannot be 0'
                }
            };

            $scope.checkIfAnyPartsAreInvalid = function checkIfAnyPartsAreInvalid() {
                $scope.quantityError = false;
                for(var i = 0, l = $scope.sidePanelPart.length; i < l; i++) {
                    var chosen_part = $scope.sidePanelPart[i];
                    if (chosen_part.error === true) {
                        $scope.quantityError = true;
                    }
                }
            }

            $scope.closeMarkArrivePanel = function closeMarkArrivePanel(){
                for(var i = 0, l = $scope.sidePanelPart.length; i < l; i++) {
                    $scope.sidePanelPart[i].quantity = $scope.selectedParts[$scope.mode][i].quantity;
                    $scope.sidePanelPart[i].error = false;
                    $scope.sidePanelPart[i].error_message = '';
                }
                $scope.checkIfAnyPartsAreInvalid();
            }

            $scope.$on('flex:sidepanel_close', function(){
              $scope.closeMarkArrivePanel();
            });

            $scope.markAvailableWithoutStock = function ($event) {
                var ids = $scope.getSelectedPartsFulfillmentIds();
                var fulfillmentIds = ids['fulfillmentIds'], podetailids = ids['podetailids'];

                var partsToSubmit = [];
                angular.forEach($scope.sidePanelPart, function(part,index){
                    part.quantity = part.quantityModel;
                    partsToSubmit.push({
                        fulfillmentid: part.fulfillmentid,
                        podetailid: part.podetailid,
                        quantity: part.quantity
                    });
                })

                $http.get(prefix + '/mark_as_available?parts=' +angular.toJson(partsToSubmit))
                    .then(function(resp) {
                        $rootScope.$broadcast('flexibleSidepanel_close', {click_event: $event});
                        $scope.unselectAllParts();
                        $scope.data[$scope.mode] = $scope.operationalList;
                        $scope.currentPage[$scope.mode] = 1;
                        $scope.getData($scope.currentFilters, true);
                    });
            }

            $scope.processAllocation = function($event) {
                if ($scope.selectedStockLocation < 1 || typeof $scope.selectedStockLocation === 'undefined' || $scope.selectedStockLocation === ''|| $scope.selectedStockLocation === null) {
                    return false;
                }

                $('.single-reminder .ss-check').addClass('processed');
                /* else, run the below */
                var partsToSubmit = [];
                var locationId = $scope.selectedStockLocation;
                angular.forEach($scope.selectedParts[$scope.mode], function(part, index) {
                    partsToSubmit.push({
                        quantity_delivered: part.quantity,
                        quantity_ordered: part.quantityordered,
                        purchaseOrderDetailId: part.podetailid,
                        price: part.unitprice,
                        poNo: part.purchaseorderno,
                        partId: part.settingspartid,
                        allocationValues: [
                            {quantity: part.quantity, locationId: locationId}
                        ]
                    });
                });

                $http.get(prefix + '/mark_as_available?parts=' + angular.toJson(partsToSubmit))
                    .then(function(resp) {
                        $rootScope.$broadcast('flexibleSidepanel_close', {click_event: $event});
                        $scope.unselectAllParts();
                        $scope.data[$scope.mode] = $scope.operationalList;
                        $scope.currentPage[$scope.mode] = 1;
                        $scope.getData($scope.currentFilters, true);
                    });
            };
            $scope.inputs={};

            $scope.selectedStockLocationChanged = function(loc) {
                $timeout(function() {
                    $scope.selectedStockLocation = loc;
                }, 1);
            }

            $scope.markAsAvailable = function ($event, tpl) {
              if (!$scope.highlightedJob[$scope.mode]) {
                return false;
              }
              $scope.inputs.selectedStockLoc = '';
              $rootScope.$broadcast('flexibleSidepanel_'+tpl, {evt: $event});
            };

          $scope.$on('operationalTable:selectedChosenParts', function(event, data){
            $scope.selectedParts[$scope.mode] = data;
          });

            $scope.fulfillSelectedParts = function() {
                var isEnabledStock = $scope.clientJobType != 2;  //_.has($rootScope.moduleDetails, 'Stock') && $rootScope.moduleDetails['Stock'] === 1;
                $timeout(function(){
                    var selectedParts = angular.copy($scope.selectedParts[$scope.mode]);
                    if(!isEnabledStock) {
                        _.forEach(selectedParts, function(part,k){
                            var loc = _.reject(part.locations, function(x){ 
                                return _.has(x, 'id');
                            });
                            selectedParts[k].locations = loc;
                        });
                    }
                    $rootScope.$broadcast('open_sidepanel_operational-fulfill-parts', {listObject: selectedParts});
                    if($scope.clientJobType === 3){
                        $timeout(function() {
                            $scope.$broadcast('sidepanel_mass_fulfillment_stock_refresh','parts_operational_list',selectedParts);
                        },1);
                    }
                },0);
            };

            $scope.unselectAllParts = function() {
                $scope.selectedParts[$scope.mode] = [];
                $scope.selectedPartIds[$scope.mode] = [];
                angular.forEach($scope.operationalList, function(job, index) {
                    job.selected = false;
                    $scope.highlightedJob[$scope.mode] = false;

                    angular.forEach(job.parts, function(part, index) {
                        part.selected = false;
                        $rootScope.$broadcast('updateSelectedParts', part);
                    });
                });
            }

            $scope.isPartGroupSelected = function(job) {
                return job.selected;
            }

            $scope.isJobSelected = function (job) {
                return (job.selected == true);
            };

            $scope.toggleSelectAllParts = function (job, checkIfDisabled) {
                var isJobComplete = $scope.clientJobType === 1 ? [job['isJobComplete']] : _.pluck( job.parts,'isJobComplete');
                if ($scope.mode == 'on_order' && !_.contains(isJobComplete, 0)) {
                    return false;
                }

                if (checkIfDisabled) {
                    if ($scope.isCheckboxDisabled(job)) {
                        return false;
                    }
                }

                if (!job.selected) {
                    job.selected = true;
                    if (job.jobnumber) {
                        // For Requested and Available tabs
                        $scope.highlightedJob[$scope.mode] = job.jobnumber;
                    }
                    else {
                        // For On Order tab
                        $scope.highlightedJob[$scope.mode] = job.date;
                    }
                }
                else {
                    job.selected = false;
                }
                var chosenParts=[];
                angular.forEach(job.parts, function (part, index) {

                    if(job['waddressID']!=''){
                        part['customerId']= job['waddressID'];

                    }
                    if (part.selected !== job.selected) {
                        part.selected = job.selected;
                        $rootScope.$broadcast('updateSelectedParts', part, false);
                        chosenParts.push(part);
                    }
                });
                if ($scope.mode == 'requested' && job.selected) {
                  $rootScope.$broadcast('smart_table:pinned_rows_updated', chosenParts);
                }

                var noPartsSelected = true;

                angular.forEach($scope.operationalList, function (job, jobIndex) {
                    var noJobPartsSelected = true;
                    angular.forEach(job.parts, function (part, partIndex) {
                        if (part.selected) {
                            noPartsSelected = false;
                        }
                    });
                });

                if (noPartsSelected) {
                    $scope.highlightedJob[$scope.mode] = false;
                }

            }

            $scope.toggleSelectPart = function(job, part, checkIfDisabled) {
                if (part['isJobComplete'] == 1 && $scope.mode == 'available') {
                    return false;
                }


                if (checkIfDisabled) {
                    if ($scope.isCheckboxDisabled(job)) {
                        return false;
                    }
                }

                if(job['waddressID']!=''){
                    part['customerId']= job['waddressID'];

                }

                if (!part.selected) {
                    part.selected = true;

                    if ($scope.mode == 'on_order' && $scope.clientJobType != 1) {
                        $scope.highlightedJob[$scope.mode] = job.date;
                    }
                    else {
                        $scope.highlightedJob[$scope.mode] = job.jobnumber;
                    }

                    $rootScope.$broadcast('updateSelectedParts', part, true);
                }
                else {
                    part.selected = false;
                    job.selected = false;
                    var noPartsSelected = true;

                    angular.forEach($scope.operationalList, function(job, jobIndex) {
                        var noJobPartsSelected = true;
                        angular.forEach(job.parts, function(part, partIndex) {
                            if(job['waddressID']!=''){
                                part['customerId']= job['waddressID'];

                            }
                            if (part.selected) {
                                noPartsSelected = false;
                                noJobPartsSelected = false;
                            }
                        });
                        /*
                                        if (noJobPartsSelected) {
                                            job.selected = false;
                                        }
                        */
                    });

                    if (noPartsSelected) {
                        $scope.highlightedJob[$scope.mode] = false;
                        //job.selected = false;
                    }
                    $rootScope.$broadcast('updateSelectedParts', part, true);
                }

            };


            $scope.monthLookup = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ];

            $scope.dayOfWeekLookup = [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ];

            $scope.getOrdinalIndicator = function(date) {
                date = String(date);
                if (date.length) {
                    var lastDigit = parseInt(date[date.length-1]);
                    date = parseInt(date);
                    if (lastDigit !== "NaN") {
                        if (lastDigit == 0 || lastDigit >= 4 || (date >= 10 && date <= 19)) {
                            return date + "th";
                        }
                        else if (lastDigit == 1) {
                            return date + "st";
                        }
                        else if (lastDigit == 2) {
                            return date + "nd";
                        }
                        else if (lastDigit == 3) {
                            return date + "rd";
                        }
                    }
                }
                return false;
            }


            $scope.transformDate = function(date) {
                var transformedDate = new Date(date);
                transformedDate = $scope.dayOfWeekLookup[transformedDate.getDay()] + ', ' + $scope.getOrdinalIndicator(transformedDate.getDate()) + ' ' + $scope.monthLookup[transformedDate.getMonth()] + ', ' + transformedDate.getFullYear();
                return transformedDate;
            };

            $scope.smoothScrollToPosition = function(target, previousScrollTop) {
                if (!$scope.finishedDateScrolling) {
                    var lastKnownScrollTop = $('.reporting_wrapper').scrollTop();
                    if (previousScrollTop) {
                        if (previousScrollTop == $('.reporting_wrapper').scrollTop()) {
                            // If the previous scrollTop position is the same as last time, it probably means we reached the end of the page. So, return false and don't scroll anymore.
                            $scope.finishedDateScrolling = true;
                            return false;
                        }
                    }
                    if (target > 170 /*combined height of various headers*/) {
                        if ((target - $('.reporting_wrapper').scrollTop()) > 150) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 100);
                            $timeout(function() {
                                $scope.smoothScrollToPosition(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((target - $('.reporting_wrapper').scrollTop()) > 50) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 50);
                            $timeout(function() {
                                $scope.smoothScrollToPosition(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((target - $('.reporting_wrapper').scrollTop()) > 20) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 20);
                            $timeout(function() {
                                $scope.smoothScrollToPosition(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((target - $('.reporting_wrapper').scrollTop()) > 5) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 5);
                            $timeout(function() {
                                $scope.smoothScrollToPosition(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((target - $('.reporting_wrapper').scrollTop()) > 1) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 1);
                            $timeout(function() {
                                $scope.smoothScrollToPosition(target, lastKnownScrollTop);
                            }, 10);
                        }
                    }
                    else {
                        $scope.finishedDateScrolling = true;
                    }
                }
            }

            $scope.smoothScrollToElement = function(target, previousScrollTop) {
                if (!$scope.finishedDateScrolling) {
                    var lastKnownScrollTop = $('.reporting_wrapper').scrollTop();
                    if (previousScrollTop) {
                        if (previousScrollTop == $('.reporting_wrapper').scrollTop()) {
                            // If the previous scrollTop position is the same as last time, it probably means we reached the end of the page. So, return false and don't scroll anymore.
                            $scope.finishedDateScrolling = true;
                            return false;
                        }
                    }

                    var targetTop = $(target).offset().top;

                    if (targetTop > 170 /*combined height of various headers*/) {
                        if ((targetTop - $('.reporting_wrapper').scrollTop()) > 150) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 100);
                            $timeout(function() {
                                $scope.smoothScrollToElement(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((targetTop - $('.reporting_wrapper').scrollTop()) > 50) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 50);
                            $timeout(function() {
                                $scope.smoothScrollToElement(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((targetTop - $('.reporting_wrapper').scrollTop()) > 20) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 20);
                            $timeout(function() {
                                $scope.smoothScrollToElement(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((targetTop - $('.reporting_wrapper').scrollTop()) > 5) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 5);
                            $timeout(function() {
                                $scope.smoothScrollToElement(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((targetTop - $('.reporting_wrapper').scrollTop()) > 1) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 1);
                            $timeout(function() {
                                $scope.smoothScrollToElement(target, lastKnownScrollTop);
                            }, 10);
                        }
                    }
                    else {
                        $scope.finishedDateScrolling = true;
                    }
                }
            }

            /*$scope.changeTab = function(evt, newtab) {
                evt.preventDefault();
                if (newtab !== $scope.mode) {
                    $scope.scrollBackToTop(newtab);
                }
            }*/
          $scope.changeTab = function (evt, newtab) {
            evt.preventDefault();
            if (newtab !== $scope.mode) {
              $rootScope.$broadcast('scrollReportToTop', {newtab: newtab});
            }
          }

            $scope.$on('scrollOperationalListToTop', function(e, data) {
                $scope.scrollBackToTop();
                $scope.searchbarId = data.id;
            });

            $scope.scrollBackToTop = function(newtab, savePosition) {
                var scrolltop = $('.reporting_wrapper').scrollTop();

                if (savePosition) {
                    $scope.tabScrollPosition[$scope.mode] = scrolltop;
                }

                if (scrolltop > 0 && scrolltop > 150) {
                    $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() - 100);
                    $timeout(function() {
                        $scope.scrollBackToTop(newtab, false);
                    }, 10);
                }
                else if (scrolltop > 0 && scrolltop > 50) {
                    $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() - 50);
                    $timeout(function() {
                        $scope.scrollBackToTop(newtab, false);
                    }, 10);
                }
                else if (scrolltop > 0 && scrolltop > 20) {
                    $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() - 20);
                    $timeout(function() {
                        $scope.scrollBackToTop(newtab, false);
                    }, 10);
                }
                else if (scrolltop > 0 && scrolltop > 5) {
                    $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() - 5);
                    $timeout(function() {
                        $scope.scrollBackToTop(newtab, false);
                    }, 10);
                }
                else if (scrolltop > 0 && scrolltop > 1) {
                    $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() - 1);
                    $timeout(function() {
                        $scope.scrollBackToTop(newtab, false);
                    }, 10);
                }
                else {
                    $scope.operationalTableSpinner = true;
                    if (newtab) {
                        $state.go('loggedin.reporting.operational',{category: 'parts', subcategory: 'parts',type : newtab});
                    }
                    else {
                        $rootScope.$broadcast('updateFilters', {id: $scope.searchbarId});
                    }
                }
            };
            $scope.checkTodaysDate = function() {
                angular.forEach($('.operational-header'), function(tableHeader, index) {
                    if ($(tableHeader).find('.due-date').text() == $scope.transformDate($scope.todaysDate)) {
                        $(tableHeader).addClass('todays-date');
                        $scope.smoothScrollToElement($(tableHeader));
                    }
                });
            };

            $scope.transformData = function(data) {
                if ($scope.data[$scope.mode]) {
                    /* Re-format the data so that it works with the "fulfill parts" sidepanel (see BatchFulfillParts controller) */
                    if ($scope.mode == 'on_order') {
                        angular.forEach($scope.data[$scope.mode], function(job, jobIndex) {
                            angular.forEach(job.parts, function(part, partIndex) {
                                if (!part['part']) {
                                    part['part'] = {};
                                }
                                part.part['Part'] = part.partname;
                                part.part['Quantity'] = part.quantity;
                                part.part['FulfillType'] = "";
                                part.part['general'] = undefined;
                                part.part['partId'] = part.settingspartid;
                                part.part['partStatus'] = "Requested";
                                part.part['id'] = part.jobpartid;
                                part['category'] = $state.params.category;
                                part['subcat'] = $state.params.subcategory;
                                part['customerId'] = null;
                                part['customerType'] = null;
                                part['jobid'] = job.parts[0].jobnumber;
                                part['operational'] = true;
                            });
                        });
                    }
                    else {
                        angular.forEach($scope.data[$scope.mode], function(job, jobIndex) {
                            angular.forEach(job.parts, function(part, partIndex) {
                                if (!part['part']) {
                                    part['part'] = {};
                                }
                                part.part['Part'] = part.partname;
                                part.part['Quantity'] = part.quantity;
                                part.part['FulfillType'] = "";
                                part.part['general'] = undefined;
                                part.part['partId'] = part.settingspartid;
                                part.part['partStatus'] = "Requested";
                                part.part['id'] = part.jobpartid;
                                part['category'] = $state.params.category;
                                part['subcat'] = $state.params.subcategory;
                                part['customerId'] = job.customerID;
                                part['customerType'] = job.customerType;
                                part['jobid'] = job.jobnumber;
                                part['operational'] = true;
                            });
                        });
                    }
                }
                $scope.operationalList = angular.copy($scope.data[$scope.mode]);
            };

            $scope.$on('reportingInfiniteScroll', function(evt, elem) {
                if ($scope.disableInfiniteScroll[$scope.mode]) {
                    return false;
                }
                $scope.disableInfiniteScroll[$scope.mode] = true;

                // Save the tab state
                $scope.data[$scope.mode] = $scope.operationalList;

                var dataExists = false;

                // Manually move the scrollbar up so that infinite scrolling is not triggered over and over in a loop
                // $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() - 80);

                // If there is no data, don't increase the page number
                if ($scope.data[$scope.mode]) {
                    if ($scope.data[$scope.mode]) {
                        $scope.currentPage[$scope.mode] = $scope.currentPage[$scope.mode] + 1;
                    }
                }

                if (typeof $scope.currentFilters == "string") {
                    $scope.getData($scope.currentFilters, true);
                }
                else {
                    $scope.getData(angular.toJson($scope.currentFilters), true);
                }
            });

            $scope.$on('status:toOperationalList', function(evt, data){
                $scope.currentFilters = {};
                angular.forEach(data, function(val, key) {
                    if(val && val.value != undefined) {
                        $scope.currentFilters[val.model_name] = val.value;
                    }
                });
                $scope.getData(angular.toJson($scope.currentFilters), true);
                $rootScope.$broadcast('countActiveFilters', $scope.currentFilters);
            });

            $scope.$on('clearOpertionalFilters', function(evt, data){
                $scope.currentFilters = {};
                $scope.getData(angular.toJson($scope.currentFilters), true);
                $rootScope.$broadcast('countActiveFilters', $scope.currentFilters);
                $rootScope.$broadcast('smarttable_and_sidepanel:filtervalues',true);
            });


            $scope.getAddressLine = function(job) {
                var addressArray = [job.waddressname, job.waddressline1, job.waddressline2, job.waddressline3, job.wcounty, job.wtown, job.wpostcode];
                var address = "";

                angular.forEach(addressArray, function(addressPart, index) {
                    if (typeof addressArray[index] == "string") {
                        if (addressArray[index].length > 0) {
                            if (address.length > 0) {
                                address += ", " + addressArray[index];
                            }
                            else {
                                address += addressArray[index];
                            }
                        }

                    }
                });

                return address;
            };

          $scope.extractFilters = function (data) {
            if (data['filter']) {
              if (data['filter'][$state.params.category + '_' + $state.params.subcategory]) {
                var cat = $state.params.category + '_' + $state.params.subcategory;
                if (data['filter'][cat][$scope.mode]) {
                  if (data['filter'][cat][$scope.mode]['fields']) {
                    return data['filter'][cat][$scope.mode]['fields'];
                  }
                }
              }
            }
          };

          $scope.getData = function (filters, fetchCounts) {
            $scope.loadingDataLock = true;

            // Set $scope.noRecordFound[$scope.mode] to false so that we don't show a "No records found" message before we've finished fetching the data
            // If it turns out that there is no data available, this will be set to "true" further down
            $scope.noRecordFound[$scope.mode] = false;

            // Need today's date if the On Order tab is selected, so that we can scroll down to the row for parts which are due today
            var todaysDate = new Date();

            var year = todaysDate.getFullYear();
            var rawMonth = todaysDate.getMonth() + 1;
            var rawDay = todaysDate.getDate();

            // Get the month and the current day. Add a '0' to the beginning if the month or day number is less than 10. So, for example, 7 would get reformatted to 07.
            var month = (rawMonth < 10) ? '0' + rawMonth : rawMonth;
            var date = (rawDay < 10) ? '0' + rawDay : rawDay;

            $scope.todaysDate = year + '-' + month + '-' + date;

            function __getdata(resp) {
              if (!$scope.selectedParts[$scope.mode]) {
                $scope.selectedParts[$scope.mode] = [];
                $scope.selectedPartIds[$scope.mode] = [];
              }

              $rootScope.clientJobType = resp.clientJobType;
              $rootScope.clientJobTodos = resp.clientJobTodos;

              $scope.clientJobType = resp.clientJobType;
              $scope.operationalTableSpinner = false;

              /* Do some quick tests to make sure we have data. This mostly exists as a precaution in case the data strcture changes in the future. */
              //var jobsExist = (resp.recordList['jobs']) ? true : false;
              var jobsExist = _.has(resp.recordList, 'jobs') ? true : false;

              if (jobsExist) {
                if (Array.isArray(resp.recordList['jobs'])) {
                  if (resp.recordList['jobs'].length > 0) {
                    jobsExist = true;
                  }
                  else {
                    jobsExist = false;
                  }
                }
                else {
                  jobsExist = false;
                }
              }

              angular.forEach(resp.recordList.counts, function (count, countIndex) {
                if ($scope.counts[countIndex] !== resp.recordList.counts[countIndex]) {
                  $scope.counts[countIndex] = count;
                  $scope.resetResults[countIndex] = true;

                  if ($scope.mode !== countIndex) {
                    $scope.currentPage[countIndex] = 1;
                  }
                }
              });

              if (($scope.resetResults[$scope.mode]) && jobsExist) {
                // In this case, there are newly-assigned parts. Refresh the whole results list.
                $scope.unselectAllParts();
                $scope.data[$scope.mode] = resp.recordList.jobs;
                $scope.disableInfiniteScroll[$scope.mode] = false;
                $scope.reachedEndOfParts[$scope.mode] = false;
                $scope.resetResults[$scope.mode] = false;
              }
              else if (jobsExist && !$scope.data[$scope.mode] && $scope.currentPage[$scope.mode] == 1) {
                // Check to see if $scope.data['requested'] is defined before assigning to it.
                // This is to prevent an empty array overwriting data we already have, for example when $scope.currentPage is increased but there are no more pages
                $scope.data[$scope.mode] = resp.recordList.jobs;
                $scope.disableInfiniteScroll[$scope.mode] = false;
                $scope.reachedEndOfParts[$scope.mode] = false;
              }
              else if (jobsExist && $scope.data[$scope.mode] && $scope.currentPage[$scope.mode] == 1) {
                $scope.data[$scope.mode] = resp.recordList.jobs;
                $scope.disableInfiniteScroll[$scope.mode] = false;
                $scope.reachedEndOfParts[$scope.mode] = false;
              }
              else if (jobsExist && $scope.data[$scope.mode] && $scope.currentPage[$scope.mode] > 1) {
                // Else, if we already have data ($scope.data['requested'] is defined) and the page greater than 1, concat the new page data onto the existing array


                if ($scope.mode != 'on_order') {
                  var topmostJob = resp.recordList.jobs[0].jobnumber;
                  var newJobsLength = resp.recordList.jobs.length;
                  var lastJobIndex = $scope.data[$scope.mode].length - 1;
                  var lastJob = $scope.data[$scope.mode][lastJobIndex].jobnumber;
                }
                else if ($scope.mode == 'on_order') {
                  var topmostJob = resp.recordList.jobs[0].date;
                  var newJobsLength = resp.recordList.jobs.length;
                  var lastJobIndex = $scope.data[$scope.mode].length - 1;
                  var lastJob = $scope.data[$scope.mode][lastJobIndex].date;
                  
                  if($scope.data[$scope.mode][lastJobIndex].shippingmethod !== resp.recordList.jobs[0].shippingmethod ||
                      $scope.data[$scope.mode][lastJobIndex].shippingbranch !== resp.recordList.jobs[0].shippingbranch) {
                      topmostJob = -1; // handling to create new container
                  }
                }

                if (topmostJob !== undefined && lastJob !== undefined) {
                  if (topmostJob == lastJob) {
                    // If the date from the topmost job matches the date from the last job in the list, merge them into one container
                    $scope.data[$scope.mode][lastJobIndex].parts = $scope.data[$scope.mode][lastJobIndex].parts.concat(resp.recordList.jobs[0].parts);
                    $scope.data[$scope.mode] = $scope.data[$scope.mode].concat(resp.recordList.jobs.slice(1, newJobsLength));
                  }
                  else {
                    $scope.data[$scope.mode] = $scope.data[$scope.mode].concat(resp.recordList.jobs);
                  }
                }
                else {
                  $scope.data[$scope.mode] = $scope.data[$scope.mode].concat(resp.recordList.jobs);
                }

                $scope.disableInfiniteScroll[$scope.mode] = false;
                $scope.reachedEndOfParts[$scope.mode] = false;
              }
              else if (!jobsExist && $scope.currentPage[$scope.mode] == 1) {
                $scope.operationalList = [];
                $scope.data[$scope.mode] = $scope.operationalList;
                $scope.noRecordFound[$scope.mode] = true;
                $scope.reachedEndOfParts[$scope.mode] = false;
                $scope.disableInfiniteScroll[$scope.mode] = true;
              }
              else if (!jobsExist && $scope.data[$scope.mode] && $scope.data[$scope.mode]) {
                $scope.reachedEndOfParts[$scope.mode] = true;
                $scope.disableInfiniteScroll[$scope.mode] = true;
              }
              else {
                $scope.noRecordFound[$scope.mode] = true;
                $scope.reachedEndOfParts[$scope.mode] = false;
                $scope.disableInfiniteScroll[$scope.mode] = true;
              }

              if ($scope.mode == 'on_order') {
                $scope.stockLocations = resp.recordList.stockLocations;

                angular.forEach($scope.data[$scope.mode], function (row, rowindex) {
                  if (row.date == $scope.todaysDate) {
                    row.todaysDate = true;
                  }
                });
              }

              $timeout(function () {
                $scope.operationalList = angular.copy($scope.data[$scope.mode]);
              }, 1);
              reportFilterSettings.availableTabFilters = angular.copy(resp['filter'][$state.params.category + '_' + $state.params.subcategory]);
              /* These two lines are required so that URL parameters are added to the inputTags array in ReportingViewCtrl in reporting-controllers.js */
              reportFilterSettings.availableFilters = angular.copy($scope.extractFilters(resp));
              $rootScope.$broadcast('refreshInputTags');
              // This updates the list of available filters which shows up in the dropdown when you click on the filter search bar.
              $rootScope.$broadcast('updateOperationalTableFilters', reportFilterSettings.availableFilters, $scope.mode);
              reportFilterSettings.updateURLFilters(angular.fromJson(URLFilters), reportFilterSettings.availableFilters);
              /* This is necessary in order for the fulfill parts sidepanel to function */
              $scope.transformData($scope.data);

              /* Check to see what tab we're on. If we're on the "On Order" or "Available" tab, don't transform the data yet,
                 because we will doing another GET call before transforming the data.
              */
              if ($scope.mode !== 'on_order') {
                $scope.smoothScrollToPosition($scope.tabScrollPosition[$scope.mode]);
              }
              $rootScope.$broadcast('tabCounts', $scope.counts);
              $scope.firstRun = false;
              $scope.loadingDataLock = false;
              $rootScope.$broadcast('reportFilterLoading', $scope.tableId, false);
            }

//                    $scope.todaysDate = '2017-07-05'; // This line is for debugging purposes
            var URLFilters = angular.toJson(reportFilterSettings.getAppliedFilters({
              'filters': angular.fromJson(filters),
              'availableFilters': reportFilterSettings.availableTabFilters[$scope.mode]['fields']
            }));
            if($scope.$parent.initcall !== true) {
              $http.get(prefix + '/parts_operational_list?status=' + $scope.mode + '&page=' + $scope.currentPage[$scope.mode] + '&limit=' + $scope.pageLimit[$scope.mode] + '&filter=' + URLFilters)
                .then(function (resp) {
                  __getdata(resp.data);
                });
            }
            else {
              __getdata($scope.$parent.datasets);
              $scope.$parent.initcall=false;
            }
          };

            $scope.getRows = function() {
                if ($scope.operationalList) {
                    if ($scope.operationalList['recordList']) {
                        return $scope.operationalList;
                    }
                }
                return {};
            }

            $scope.getCount = function(tab) {
                return $scope.counts[tab];
            };
        },
        scope: false
    }
}]);
