commusoftCommon.directive('csreactcomponent', ['$state','reactDirective', 'prefix', '$http', '$rootScope', '$document', 'permissions',
    function($state,reactDirective, prefix, $http, $rootScope, $document, permissions) {
        return reactDirective(undefined, {}, {
            $http: $http,
            prefix: prefix,
            $rootScope: $rootScope,
            $document: $document,
            permissions: permissions,
            $state: $state
        });
    }]);