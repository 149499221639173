csmodule.directive('customsingleclick', function($parse, $rootScope) {
    return {
        restrict: 'A',
        link: function(scope, element, attr) {
            var fn = $parse(attr['customsingleclick']);
            var delay = 200, 
                clicks = 0, 
                timer = null;

            /*====================================================
                Ignore the click when this event is triggered 
            ====================================================*/

            element.on('click', function (event) {
                var elm_type = event.target.tagName,
                    form_element = elm_type == 'INPUT' ||
                                   elm_type == 'TEXTAREA';

                if (form_element) {
                    return false;
                }else {
                    clicks++;
                    if(clicks === 1) {
                        timer = setTimeout(function() {
                            scope.$apply(function () {
                                fn(scope, { $event: event });
                            }); 
                            clicks = 0;
                        }, delay);
                    } else {
                        clearTimeout(timer);
                        clicks = 0;
                    }
                }
        });
    }
  }
});

