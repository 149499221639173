/**
 * Created on 15/11/17.
 */


/** csDiaryMap directive declaration */
var dairyMapDirective = ['$compile', function ($compile) {
    return {
        restrict: 'E',
        scope: true,
        controller: 'DiaryMapCtrl',
        templateUrl: 'template/diary/diary_map.html'
    }

}];

/** csDiaryMapRightbarOptions directive declaration */
var diaryMapRightbarOptionsDirective = ['$timeout', '$diaryMapScope', function ($timeout, $diaryMapScope) {
    return {
        restrict: 'A',
        scope: false,
        controller: 'DiaryMapRightbarOptionsCtrl',
        templateUrl: 'template/diary/diary_map_rightbar_options.html',
        link: function (scope, element, attrs) {
            $timeout(function () {
                $diaryMapScope.setUsers(scope.diary_ctrl_scope.allUsers);

                scope.publishUsersToShowOnDiary(scope.diary_ctrl_scope.users_to_show_on_dairy);
                scope.updateUsersWithoutEvents(scope.diary_ctrl_scope.currentEvents);

                scope.onDateChange(scope.$parent.dateSelected);
            });
        }
    }
}];

/** csDrivingProfileTimeLine directive declaration */
var drivingProfileTimeLineDirective = ['$compile', '$interpolate', '$timeout', '$filter', function ($compile, $interpolate, $timeout, $filter) {
    return {
        restrict: 'AE',
        scope: false,
        link: function (scope, element, attrs) {
            scope.date = scope.$eval(attrs.date);
            scope.startDatetime = scope.date.clone().set({hour: 0, minute: 0, second: 0, millisecond: 0});
            scope.endDatetime = scope.date.clone().set({hour: 23, minute: 59, second: 59, millisecond: 0});
            scope.status = attrs.status;
            scope.elId = 'dtt-timeline-' + scope.$id;
            scope.timeTrackingEvents = {
                trips: [],
                diary_time_trackings: []
            };
            scope.timeTrackingData = null;
            scope.initiated = false;

            attrs.$observe('status', function (status) {
                scope.status = status;
            });

            attrs.$observe('data', function (data) {
                scope.timeTrackingData = scope.$eval(data);
                if (scope.initiated) updateTimelineEvents();
            });

            var content = '<div class="dtt-timeline">' +
                '<div class="title">\<h4>{{date.format("ddd Do MMMM, YYYY")}}</h4></div>' +
                '<div id="{{elId}}" class="container">' +
                '    <div ng-show="status == \'LOADING\'" class="loader">' +
                '        <div class="loader-icon"><span><i class="fas fa-spinner fa-spin"></i></span></div>' +
                '    </div>' +
                '</div></div>';

            element.html($compile(content)(scope));

            $timeout(function () {
                initTimeline();
            });

            // Render time tracking timeline
            function initTimeline() {

                var config = {
                    timeType: 'hour',
                    use12HourTime: true,
                    useTimeSuffix: true,
                    navigateAmount: 500,
                    selectedEventArticle: false,
                    shiftOnEventSelect: false,
                    selectedEvent: 0,
                    timeSuffixFunction: function (s) { // Set the time suffix function for displaying as '12 A.M.'
                        return ' ' + s[0].toUpperCase() + '.' + s[1].toUpperCase();
                    },
                    data: {
                        headings: [],
                        events: scope.timeTrackingEvents
                    }
                };

                scope.timespace = $('#' + scope.elId).timespace(config).Timespace;

                scope.timespace.onRangeOverflow = function (direction) {
                };

                scope.initiated = true;
            }

            // Updating driver timeline events
            function updateTimelineEvents() {
                scope.timeTrackingEvents.trips = [];
                scope.timeTrackingEvents.diary_time_trackings = [];

                if (scope.timeTrackingData) {
                    // Diary time tracking events
                    angular.forEach(scope.timeTrackingData.diary_time_tracking_records, function (record, index) {
                        var dt = moment(record.dateTime);

                        if (dt >= scope.startDatetime && dt <= scope.endDatetime) {
                            var event = {
                                start: calculateTimelinePoint(record.dateTime),
                                markerContent: '<span class="' + (record.diaryStatus ? record.diaryStatus.class : "") + '"></span><div class="lbl">' + moment(record.dateTime).utc().format("hh:mm A") + '</div>',
                                'class': 'diary-time-tracking-event diary-event-label ' + scope.diaryEventStausClasses[record.diaryStatus.status],
                                tooltipContent: designTooltip('diaryStatus', record),
                                tooltipClass: record.eventType === 'normal' ? 'diary-event-normal' : 'diary-event-jobestimate',
                            };

                            scope.timeTrackingEvents.diary_time_trackings.push(event);
                        }
                    });

                    // Driver start stops
                    angular.forEach(scope.timeTrackingData.gps_trips_data, function (record, index) {
                        if (moment(record.startTime) >= scope.startDatetime) {
                            var eventStart = {
                                start: calculateTimelinePointForTrip(record.startTime),
                                markerContent: '<span class="icon"><span class="fas fa-play"></span></span><div class="lbl">' + moment(record.startTime).format("hh:mm A") + '</div>',
                                description: record.feedback,
                                'class': 'trip-start',
                                tooltipContent: designTooltip('driveStart', record)
                            };
                            scope.timeTrackingEvents.trips.push(eventStart);
                        }

                        if (moment(record.endTime) <= scope.endDatetime) {
                            var eventStop = {
                                start: calculateTimelinePointForTrip(record.endTime),
                                markerContent: '<span class="icon"><span class="fas fa-stop"></span></span><div class="lbl">' + moment(record.endTime).format("hh:mm A") + '</div>',
                                description: record.feedback,
                                'class': 'trip-stop',
                                tooltipContent: designTooltip('driveStop', record)
                            };
                            scope.timeTrackingEvents.trips.push(eventStop);
                        }

                    });
                }

                scope.timespace.updateEvents(scope.timeTrackingEvents);
            }

            function calculateTimelinePointForTrip(datetime) {
                datetime = moment(datetime);

                var hour = datetime.hour();
                var minute = datetime.minute();

                return hour + '.' + (minute / 60) * 100;
            }

            function calculateTimelinePoint(datetime) {
                datetime = moment(datetime).utc(); // Timezone always set to UTC as like in Diary

                var hour = datetime.hour();
                var minute = datetime.minute();

                return hour + '.' + (minute / 60) * 100;
            }

            // Gets templates of event tooltips
            function designTooltip(type, data) {
                var content = '';

                switch (type) {
                    case "driveStart": {
                        content = $interpolate(scope.timelineMarkerTooltipTemplates.driveStart)({
                            title: 'Trip Start',
                            time: moment(data.startTime).format('hh:mm A'),
                            address: data.startAddress ? data.startAddress : '-',
                            latitude: data.startLat,
                            longitude: data.startLon

                        });
                        break;
                    }
                    case "driveStop": {
                        content = $interpolate(scope.timelineMarkerTooltipTemplates.driveStop)({
                            title: 'Trip End',
                            time: moment(data.endTime).format('hh:mm A'),
                            address: data.endAddress ? data.endAddress : '-',
                            latitude: data.endLat,
                            longitude: data.endLon,
                            distance: $filter('localLength')(data.distance, 'm', true),
                            duration: $filter('millisecToHms')(data.duration, 'named')

                        });
                        break;
                    }
                    case "diaryStatus": {
                        var details = Object.assign({}, data);
                        details.time = moment(details.dateTime).utc().format('hh:mm A');
                        details.eventStartTime = moment(details.eventFromDateTime).utc().format('hh:mm A');
                        details.EventToTime = moment(details.eventToDateTime).utc().format('hh:mm A');
                        details.location = (details.latitude && details.longitude) ? details.latitude + ' ,' + details.longitude : '-';

                        if (data.eventType === 'normal') {
                            content = $interpolate(scope.timelineMarkerTooltipTemplates.normalEvent)(details);
                        }
                        else if (data.eventType === 'estimate' || data.eventType === 'job') {
                            content = $interpolate(scope.timelineMarkerTooltipTemplates.jobEstimate)(details);
                        }

                        break;
                    }
                }

                return content;
            }

            // Tooltip templates
            scope.timelineMarkerTooltipTemplates = {};
            scope.timelineMarkerTooltipTemplates.driveStart = '\
            <div class="timespace-tooltip">\
                <div class="title">{{title}}</div>\
                <div class="content with-top-border">\
                    <div class="field"><span class="field-label">Time</span><span>{{time}}</span></div>\
                    <div class="field"><span class="field-label">Address</span><span>{{address}}</span></div>\
                    <div class="field"><span class="field-label">Location</span><span>{{latitude}}, {{longitude}}</span></div>\
                </div>\
            </div>';

            scope.timelineMarkerTooltipTemplates.driveStop = '\
            <div class="timespace-tooltip">\
                <div class="title">{{title}}</div>\
                <div class="content with-top-border">\
                    <div class="field"><span class="field-label">Time</span><span>{{time}}</span></div>\
                    <div class="field"><span class="field-label">Distance</span><span>{{distance}}</span></div>\
                    <div class="field"><span class="field-label">Duration</span><span>{{duration}}</span></div>\
                    <div class="field"><span class="field-label">Address</span><span>{{address}}</span></div>\
                    <div class="field"><span class="field-label">Location</span><span>{{latitude}}, {{longitude}}</span></div>\
                </div>\
            </div>';

            scope.timelineMarkerTooltipTemplates.normalEvent = '\
            <div class="cs-event cs-expanded-view">\
                <div class="cs-event-inner">\
                    <div class="cs-event-header">\
                        <div class="cs-event-header-upper">\
                            <span class="cs-event-header-event-type {{eventType}}">{{eventTypeChar}}</span> \
                            <span class="cs-event-header-time"><span>{{eventStartTime}} - </span><span>{{EventToTime}}</span></span>\
                            <span class="cs-event-header-status" ><span class="diary-event-status  {{diaryStatus.class}}"></span><i>{{diaryStatus.status}}</i></span>\
                        </div>\
                    </div>\
                    <div class="cs-event-body">\
                        <section>\
                            <div class="cs-event-body-section with-top-border">\
                                <div class="cs-event-body-section-content">\
                                    <section>\
                                        <div class="field">\
                                            <span class="field-label">Description</span> \
                                            <span>{{eventDescription}}</span>\
                                        </div>\
                                        <div class="field">\
                                            <span class="field-label">Status time</span> \
                                            <span>{{time}}</span>\
                                        </div>\
                                        <div class="field">\
                                            <span class="field-label">Location</span>\
                                            <span>{{location}}</span>\
                                        </div>\
                                    </section>\
                                </div>\
                            </div>\
                            <div class="cs-event-feedback-section">\
                                <span class="ss-quote opening"></span>\
                                <p>{{feedback}}</p>\
                                <span class="ss-quote closing"></span>\
                            </div>\
                        </section>\
                    </div>\
                </div>\
            </div>';

            scope.timelineMarkerTooltipTemplates.jobEstimate = '\
            <div class="cs-event cs-expanded-view">\
                <div class="cs-event-inner">\
                    <div class="cs-event-header">\
                        <div class="cs-event-header-upper">\
                            <span class="cs-event-header-event-type {{eventType}}">{{eventTypeChar}}</span> \
                            <span class="cs-event-header-time"><span>{{eventStartTime}} - </span><span>{{EventToTime}}</span></span>\
                            <span class="cs-event-header-status" ><span class="diary-event-status  {{diaryStatus.class}}"></span><i>{{diaryStatus.status}}</i></span>\
                        </div>\
                    </div>\
                    <div class="cs-event-body">\
                        <section>\
                            <div class="property-item flex">\
                                <span class="profile_image extra_extra_small">\
                                    <div class="image_wrapper">\
                                        <div class="avatar">\
                                            <span class="no-avatar ss-user"></span>\
                                        </div>\
                                    </div>\
                                </span>\
                                <span class="property-details flex-1 flex flex-column">\
                                    <strong>{{customerCompanyName || customerName}}</strong>\
                                    <div class="address ">{{propertyAddressline1}},\
                                        <strong class="backup-option-name">{{propertyPostcode}}</strong>\
                                    </div>\
                                </span>\
                            </div>\
                            <div class="cs-event-body-section with-top-border">\
                                <div class="cs-event-body-section-header"><strong translate>Details</strong><span class="group-seporator"></span></div> \
                                <div class="cs-event-body-section-content">\
                                    <section>\
                                        <div class="field">\
                                            <span class="field-label">{{eventType | capitalize}} num</span>\
                                            <span>{{estimateId || jobId}}</span>\
                                        </div>\
                                        <div class="field">\
                                            <span class="field-label">{{estimateDescription? "Template": "Description"}}</span>\
                                            <span>{{estimateDescription || eventDescription}}</span>\
                                        </div> \
                                        <div class="field">\
                                            <span class="field-label">Notes for engineer</span> \
                                            <span>{{engineerNotes}}</span>\
                                        </div> \
                                         <div class="field">\
                                            <span class="field-label">Status time</span> \
                                            <span>{{time}}</span>\
                                        </div>\
                                        <div class="field">\
                                            <span class="field-label">Location</span>\
                                            <span>{{location}}</span>\
                                        </div>\
                                    </section>\
                                </div> \
                            </div> \
                            <div class="cs-event-feedback-section">\
                                <span class="ss-quote opening"></span>\
                                <p>{{feedback}}</p>\
                                <span class="ss-quote closing"></span>\
                            </div>\
                        </section>\
                    </div> \
                </div>\
            </div>';
        }
    }
}];


/** csDiaryMapInfoWidgetOptions directive declaration */
var diaryMapInfoWidgetDirective = function ($timeout, $state) {
    return {
        restrict: 'E',
        scope: false,
        templateUrl: 'template/diary/diary_map_info_widget.html',
        link: function (scope, element, attrs) {
            // Moment locale settings
            moment.locale('en', {
                calendar: {
                    lastDay: '[Yesterday]',
                    sameDay: '[Today]',
                    nextDay: '[Tomorrow]',
                    lastWeek: '[Last] dddd',
                    nextWeek: 'dddd',
                    sameElse: 'DD/MM/YYYY'
                }
            });

            scope.isUserAgendaOpen = !!scope.$parent.userAgenda;

            attrs.$observe('data', function (newVal) {
                var data = scope.$eval(attrs.data);

                if (data) $timeout(function () {
                    scope.$apply(function () {
                        scope.type = data.type;
                        scope.data = data.data;

                        setTimeout(function () {
                            toggleDragging();
                        }, 100)
                    });
                });
            });

            scope.redirectToJob = function () {
                var url = '';
                if(scope.data.recordType == 'job') {
                    url = $state.href("loggedin.customer_list.view.job.details", {
                        id: scope.data.customerId,
                        jobId: scope.data.id,
                        type: scope.data.propertyType
                    });
                } else {
                    url = $state.href("loggedin.customer_list.view.estimate_details.survey", {
                        id: scope.data.customerId,
                        estimateId: scope.data.id,
                        type: scope.data.propertyType
                    });
                }

                window.open(url, '_blank');
            };

            scope.addDiaryEvent = function (event) {
                $(event.target).data('event', scope.data);
                scope.$emit('outstandingJob:click', event);
            };

            scope.$on('diaryMapUserAgenda:open', function (event) {
                scope.isUserAgendaOpen = true;
                toggleDragging();

            });

            scope.$on('diaryMapUserAgenda:close', function (event) {
                scope.isUserAgendaOpen = false;
                toggleDragging();
            });

            scope.getJobTime = function (oj) {
                var start = oj.startDatetime ? moment(oj.startDatetime) : null,
                    end = oj.endDatetime ? moment(oj.endDatetime) : null;

                if (!start || !end) return '';
                else if (end.diff(start, 'Days') == 0) {
                    return start.calendar() + ' ' + start.format('hh:mm a') + ' - ' + end.format('hh:mm a');

                } else {
                    return start.format('DD/MM/YYYY hh:mm a') + ' - ' + end.format('DD/MM/YYYY hh:mm a');
                }
            };

            function toggleDragging() {
                if (scope.type !== 'OUTSTANDING_JOB') return;

                if (scope.isUserAgendaOpen)
                    $('#info-widget-oj').draggable('enable');
                else
                    $('#info-widget-oj').draggable('disable');
            }
        }
    }
};
