commusoftCommon.service('dragHelper', function($rootScope, $timeout) {

    this.singleton_name = '';
    this.plural_name = '';

    var self;

    this.setNames = function setNames(singleton_name, plural_name) {
        this.singleton_name = singleton_name;
        this.plural_name = plural_name;
        self = this;
    }

    this.setContextData = function setContextData(context_data) {
        this.context_data = context_data;
        self = this;
    }

    /*==========================================================================================================================
     Setup variables for dragging ~ used when dragging a todo to a diary event
     ==========================================================================================================================*/
    var scroll_wrapper = document.querySelector('.commusoft_scroll_wrapper'),
        should_scroll_up = false,
        should_scroll_down = false;

    $rootScope.allowed_drag_start = true;

    /*==========================================================================================================================
     When an element on the page is dragged
     ==========================================================================================================================*/
    $rootScope.$on('ANGULAR_DRAG_START', function(scope, channel){
        if ($rootScope.allowed_drag_start === true) {
            $rootScope.allowed_drag_end = true;

            var dragging_elm = document.querySelector('.on-dragging');

            /*==========================================================================================================================
             Create and move the helper
             ==========================================================================================================================*/
            var helper_text;

            if (self.context_data !== undefined && self.context_data !== null) {
                if (self.context_data.length > 1) {
                    helper_text = self.context_data.length + ' ' + self.plural_name + ' selected';
                }else {
                    helper_text = '1 ' + self.singleton_name + ' selected';
                }
            }else{
                helper_text = '1 ' + self.singleton_name + ' selected';
            }

            document.body.insertAdjacentHTML('beforeend', '<div id="drag_helper">' + helper_text + '</div>');
            /*==========================================================================================================================
             Do things on drag
             ==========================================================================================================================*/
            var scroll_wrapper = document.querySelector('.commusoft_scroll_wrapper'),
                document_height = window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight,
                thresh_modulus = document_height * 0.1,
                top_thresh = thresh_modulus,
                bottom_thresh = document_height - thresh_modulus,
                helper = document.querySelector('#drag_helper');

            dragging_elm.addEventListener('drag', function(e){

                if (helper) {
                    helper.style["-webkit-transform"] = 'translate(' + (e.pageX + 20) + 'px, ' + (e.pageY) + 'px)';
                }

                var mouse_pos = e.pageY;

                if (mouse_pos < top_thresh && mouse_pos !== 0) {
                    should_scroll_up = true;
                }else{
                    should_scroll_up = false;
                }
                if (mouse_pos > bottom_thresh) {
                    should_scroll_down = true;
                }else{
                    should_scroll_down = false;
                }
                if (mouse_pos === 0) {
                    helper.style.visibility = 'hidden';
                }else{
                    helper.style.visibility = 'visible';
                }
            });
            window.setInterval(function(){
                if (should_scroll_down === true) {
                    scroll_wrapper.scrollTop = scroll_wrapper.scrollTop + 15;
                }
                if (should_scroll_up === true) {
                    scroll_wrapper.scrollTop = scroll_wrapper.scrollTop - 15;
                }
            }, 10);

            $rootScope.allowed_drag_start = false;
        }
    })

    /*==========================================================================================================================
     When an element on the page has finished dragging
     ==========================================================================================================================*/
    $rootScope.$on('ANGULAR_DRAG_END', function(scope, channel){
        $rootScope.allowed_drag_start = true;

        if ($rootScope.allowed_drag_end === true) {

            /*==========================================================================================================================
             Animate and remove the helper
             ==========================================================================================================================*/
            var helper = document.querySelector('#drag_helper');
            if (helper && helper.parentElement) {
                helper.classList.add('puff-away');
                $timeout(function(){
                    $( "#drag_helper" ).remove();
                },600);

                var drop_elm = document.querySelector('.on-drag-hover');
                if (drop_elm) {
                    drop_elm.classList.add('highlight');
                    $timeout(function(){
                        drop_elm.classList.remove('highlight');
                    },100);
                }

            }
        }
        $rootScope.allowed_drag_end = false;
    });
});