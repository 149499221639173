csmodule.controller('newDiaryEventContainer', ['$scope', '$rootScope', '$http', '$filter', '$timeout', 'confirmationBoxHelper', 'addDiaryEvent', 'diaryRightBarHelper', 'prefix', 'postCodeHelper', function($scope, $rootScope, $http, $filter, $timeout, confirmationBoxHelper, addDiaryEvent, diaryRightBarHelper, prefix, postCodeHelper) {
	/*==========================================================================================
    Initial Variables
  ==========================================================================================*/

	if(diaryRightBarHelper.adding_event_from_previous_page) {
    $scope.event_from_previous_page = true;
		$scope.panel_title = 'Add ' + diaryRightBarHelper.event_from_previous_page.type + ' no.' + diaryRightBarHelper.event_from_previous_page.id;
	}

  $scope.panel_view = 'add_new_event';
  $scope.event_type = 'normal';
  $scope.form_being_saved = false;
    $scope.landlordFormOpened = false;

  // used in postcode look up
  $scope.addresses = $http.get('/javascripts/commusoft/data/shared/addresses.json');
  $scope.address_matches = [];
  $scope.addresses_arrived = false;

  /*==========================================================================================
  	Change view in panel
  ==========================================================================================*/
  $scope.goTo = function goTo(new_view) {
  	$scope.panel_view = new_view;
  }

  /*==========================================================================================
  	Return to previous view (used in breadcrumbs-title-bar)
  ==========================================================================================*/
  $scope.goBackTo = function goBackTo(view) {
  	confirmationBoxHelper.getConfirmation("Are you sure you want to go back?", $scope)
  		.then(function() {
        $scope.landlordFormOpened = false;
  			$scope.goTo(view);
  			$scope.hidePostcodeLookup();
  			$scope.event_type = $scope.return_event_type;
  		});
  }

  /*==========================================================================================
  	Cancel adding new event
  ==========================================================================================*/
  $scope.cancelAddingNewEvent = function cancelAddingNewEvent() {
  	// this will become part of the side panel directive (like the panel with form directive)
  	confirmationBoxHelper.getConfirmation("Are you sure you want to cancel adding a new diary event? (Un-saved data will be lost)", $scope)
  		.then(function() {
			$rootScope.$broadcast('sidepanel:close');
  		});
  }

	/*==========================================================================================
		Switch view to the add new property form
	==========================================================================================*/
	$scope.addNewProperty = function addNewProperty(event_type) {
		$scope.return_event_type = event_type;
		$scope.goTo('add_new_property');
		$rootScope.$broadcast('add_new_property:reset_property_type');
		addDiaryEvent.focusInput('#new-property-type-input');
	}

	/*==========================================================================================
		Switch view to the add new contact form
	==========================================================================================*/
	$scope.addNewContact = function addNewContact(event_type, property) {
		$scope.return_event_type = event_type;
        $scope.propertyId = parseInt(property.id);
        $http.get(prefix + '/diary/customer/details?id=' + $scope.propertyId).success(function (data) {
            $scope.branches = data.branches;
            $scope.login_access_roles = data.login_access_roles;
        });

		$scope.goTo('add_new_contact');
		addDiaryEvent.focusInput('#new-contact-top-input');
	}

	$scope.$on('add_new_contact:pending', function(e, deferred) {
		$scope.form_being_saved = true;
		$scope.saving_state = 'pending';
		$scope.saving_text = 'Saving contact';

		deferred.then(function(res) {
			 $scope.saving_state = 'saved';
			 $scope.saving_text = 'Contact saved';
			 $scope.goTo('add_new_event');

			 $timeout(function() {
			 	$scope.form_being_saved = false;
			 	$scope.$broadcast('add_new_event:contact_added', res.data.responseData);
			 }, 300);
		}, function() {
			$scope.saving_state = 'error';
			$scope.saving_text = 'Error saving contact';

			// re-show the form
			$timeout(function() {
				$scope.form_being_saved = false;
			}, 1000);
		});
	});

	/*==========================================================================================
		Get confirmation form the user that they want to cancel adding a new property, if so they
		are returned back to the new diary event view
	==========================================================================================*/
	$scope.cancelAddingNewProperty = function cancelAddingNewProperty() {
		confirmationBoxHelper.getConfirmation("Are you sure you want to cancel adding a new property?", $scope)
			.then(function() {
				$scope.goTo('add_new_event');
			});
	}

	/*==========================================================================================
		Postcode look up
	==========================================================================================*/
	// $scope.lookUpPostCode = function lookUpPostCode(postcode, returning_inputs_ref) {
	// 	$scope.return_view = $scope.panel_view;
	// 	$scope.returning_inputs_ref = returning_inputs_ref;
	// 	$scope.formatted_postcode = angular.uppercase(postcode);
	// 	$scope.goTo('look_up_postcode');

	// 	$scope.addresses.then(function(addresses) {
	// 	  $scope.address_matches = _.where(addresses.data, {
	// 	                            "postcode" : $scope.formatted_postcode
	// 	                          });
	// 	  $scope.addresses_arrived = true;
	// 	});
	// }

	/*==========================================================================================
		User has selected address's radio button
	==========================================================================================*/
	// $scope.chooseAddress = function chooseAddress() {
	// 	$scope.selected_address = this.address;
	// }

	/*==========================================================================================
		User has clicked choose address button
	==========================================================================================*/
	// $scope.useAddress = function useAddress() {
	// 	var data = {};
	// 	data.address = $scope.selected_address;
	// 	data.returning_inputs_ref = $scope.returning_inputs_ref;

	// 	$rootScope.$broadcast('look_up_postcode:address_selected', data);
	// 	$scope.panel_view = $scope.return_view;
	// }

	/*==========================================================================================
		Switch view to add new landlord form (from new work address form)
	==========================================================================================*/
	$scope.addNewLandlord = function addNewLandlord() {
        $scope.landlordFormOpened = true;
		$scope.goTo('add_new_landlord');
		addDiaryEvent.focusInput('#new-landlord-top-input');
	}

  /*==========================================================================================
  	Handle postcode lookups
  ==========================================================================================*/
  $scope.validatePostcode = function validatePostcode(postcode) {
  	return postCodeHelper.validatePostcode(postcode);
  }
  $scope.showPostcodeLookup = function showPostcodeLookup(postcode) {
  	$scope.postcode_lookup_postcode = postcode;
  	$scope.showing_postcode_lookup = true;
  }
  $scope.hidePostcodeLookup = function hidePostcodeLookup() {
  	$scope.showing_postcode_lookup = false;
  }

  $scope.useAddress = function useAddress() {
    $rootScope.$broadcast('smartpostcode:use_address');
  }

  $rootScope.$on('smartpostcode:loading', function(e, loading) {
      $scope.postcode_panel_loading = loading;
      $scope.form_being_saved = loading;
  })

  $rootScope.$on('smartpostcode:address', function(e, address) {
      $rootScope.$broadcast('look_up_postcode:address_selected', address);
      $scope.showing_postcode_lookup = false;
  });

  $rootScope.$on('smartpostcode:addresses_list_arrived', function(e, addresses) {
  	if (addresses.length > 0) {
  		$scope.show_choose_address_button = true;
  	} else {
  		$scope.show_choose_address_button = false;
  	}
  })

	/*==========================================================================================
		When landlord has been saved return to the new property view
	==========================================================================================*/
	$scope.$on('add_new_landlord:pending', function(e, deferred) {
		$scope.form_being_saved = true;
		$scope.saving_state = 'pending';
		$scope.saving_text = 'Saving landlord';
        $scope.landlordFormOpened = false;

		deferred.then(function(res) {
			$scope.saving_state = 'saved';
			$scope.saving_text = 'Landlord saved';
			$scope.goTo('add_new_property');

			$timeout(function() {
				$scope.form_being_saved = false;
			}, 300);
		}, function() {
			$scope.saving_state = 'error';
			$scope.saving_text = 'Error saving landlord';

			// re-show the form
			$timeout(function() {
				$scope.form_being_saved = false;
			}, 1000);
		});
	});

	$scope.$on('add_new_property:pending', function(e, deferred) {
		$scope.form_being_saved = true;
		$scope.saving_state = 'pending';
		$scope.saving_text = 'Saving property';

		deferred.then(function(res) {
			$scope.saving_state = 'saved';
			$scope.saving_text = 'Property saved';
			$scope.event_type = $scope.return_event_type;
			$scope.goTo('add_new_event');

			$timeout(function() {
			 	$scope.form_being_saved = false;
                $scope.$broadcast('add_new_event:property_added', res.data.responseData);
			}, 300);
		}, function() {
			$scope.saving_state = 'error';
			$scope.saving_text = 'Error saving property';

			// re-show the form
			$timeout(function() {
				$scope.form_being_saved = false;
			}, 1000);
		});
	});

	$scope.$on('add_new_event:pending', function(e, deferred) {
		$scope.form_being_saved = true;
		$scope.saving_state = 'pending';
		$scope.saving_text = 'Saving event';

		deferred.then(function(res) {
			if(res.data.error == true) {
				$scope.saving_state = 'error';
				$scope.saving_text = res.data.message;

				$timeout(function() {
					$scope.form_being_saved = false;
				}, 1000);
			} else {
				$scope.saving_state = 'saved';
				$scope.saving_text = 'Event saved';

				$timeout(function() {
					$scope.form_being_saved = false;
					$rootScope.$broadcast('sidepanel:close');
				}, 300);
			}
		}, function() {
			$scope.saving_state = 'error';
			$scope.saving_text = 'Error saving diary event';

			// re-show the form
			$timeout(function() {
				$scope.form_being_saved = false;
			}, 1000);
		});
	});
}]);