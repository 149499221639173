// Service can be deleted and removed from direcitve when CUI code merged.

csmodule.service('spreadsheetDropzoneHelper', function($document, $timeout) {
    this.attachDropzone = function attachDropzone(spreadsheet_elm){
        var spreadsheet_elm = spreadsheet_elm;

        $timeout(function(){
            var dropzone_elm = spreadsheet_elm.querySelector('#attach_file_dropzone');

            $(dropzone_elm).dropzone({
                error: function() {
                    $('.control-error-message').show();
                    $('.image-success-message').hide();
                    $('.progress').hide();
                    $('#attach_file_dropzone').removeClass('image-dragged');
                    $('.image-dragged-message').hide();
                    return $('.spreadsheet-wrapper').removeClass('add_dropzone_tint');
                },
                dragover: function() {
                    $('#attach_file_dropzone').addClass('image-dragged');
                    $('.image-dragged-message').show();
                    return $('.spreadsheet-wrapper').addClass('add_dropzone_tint');
                },
                dragleave: function() {
                    $('#attach_file_dropzone').removeClass('image-dragged');
                    $('.image-dragged-message').hide();
                    return $('.spreadsheet-wrapper').removeClass('add_dropzone_tint');
                },
                success: function() {
                    $('.postcode-wrapper a').css('height', '20px');
                    $('#attach_file_dropzone').removeClass('dropzone');
                    $('.dropzone-wrapper').addClass('preview-image');
                    $('#attach_file_dropzone').removeClass('image-dragged');
                    $('.image-dragged-message').hide();
                    $('.spreadsheet-wrapper').removeClass('add_dropzone_tint');
                    $('.control-error-message').hide();
                    return $('.image-success-message').show();
                },
                fallback: function() {
                    return false;
                }
            });
            $(dropzone_elm).addClass('dropzone');
        }, 1000);
    }
});