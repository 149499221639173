commusoftCommon.filter('fullTimeFormat', ['CS_COUNTRY', 'CS_LANGUAGE', function (CS_COUNTRY, CS_LANGUAGE) {
    return function (dateString) {
        if (dateString == '') {
            return '';
        }
        moment.locale(CS_LANGUAGE);

        const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:?\d{2}|Z)$/;
        let utcTimeString  = (isoRegex.test(dateString)) ? new Date(dateString) : new Date(dateString + ' UTC');
        utcTimeString = new Date(utcTimeString.toLocaleString(navigator.language, { timeZone: CS_COUNTRY.timezone }));
        return moment(utcTimeString).format('HH:mm');
    };
}]);
