commusoftCommon.service('template', function ($compile, $http, $templateCache, $q) {
    this.load = function load(tpl_path, elm, scope) {
        var deferred = $q.defer();

        $http.get(tpl_path, {cache: $templateCache})
            .success(function (templateContent) {

                elm.after($compile(templateContent)(scope));
                deferred.resolve(true);

            }).error(function () {

            console.warn("template not found");
            deferred.reject(false);
        });

        return deferred.promise;
    }
});