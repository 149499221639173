/**
 * Created by sivachandran on 21/8/18.
 */

function taxComponentCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.addTaxComponentForm['addTaxComponent[description]'].$error.checkExists = true;
            } else {
                $scope.addTaxComponentForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("TAXCOMPONENT_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function taxComponentListCtrl($scope, $state, prefix, $http, taxcomponentresolver, defaultPagingValue,warningModal) {
    $scope.taxComponents = taxcomponentresolver.taxComponentResults;
    $scope.totalTaxComponents = taxcomponentresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'taxComponent', $state, defaultPagingValue, 'taxComponents');

    $scope.$on("TAXCOMPONENT_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, taxcomponentresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.taxComponents.push({'id': message.id, 'description': message.description, 'justadded': true});
            taxcomponentresolver.taxComponents = $scope.taxComponents;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        taxcomponentresolver.count = parseInt(taxcomponentresolver.count) + 1
        $scope.totalTaxComponents = taxcomponentresolver.count;

    });

    $scope.editTaxComponent = function (id, description) {
        $state.transitionTo('loggedin.add_tax_components.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id) {
        $http.get(prefix + '/is_tax_component_delete?id=' + id).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });
    };

    $scope.fetchTaxComponents = function (pageNum) {
        $http.get(prefix + '/list_tax_components?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.taxComponents = data.taxComponentResults;
            $scope.totalTaxComponents = data.count;
            taxcomponentresolver.taxComponents = data.taxComponentResults;
            taxcomponentresolver.totalTaxComponents = data.count;

            $scope.triggerJustAddedUpdatedTags();

        })
    }
}

function taxComponentEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedTaxComponent = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.add_tax_components");
    }

    $scope.editTaxComponent = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_tax_component', "taxComponent[id]=" + this.selectedId + "&taxComponent[description]=" + encodeURIComponent(this.selectedTaxComponent)).
        success(function (data, status) {
            if (status == 200) {
                $scope.updating = false;
                if (data.error && data.error == 'Exists') {
                    $scope.current.editTaxComponentForm['selectedTaxComponent'].$error.checkExists = true;
                } else {
                    $state.$current.parent.self.data.pageNum = $scope.pageNum;
                    $state.$current.parent.self.data.editedId = $scope.selectedId;
                    $state.$current.parent.self.data.limit = $scope.limit;
                    $state.transitionTo("loggedin.add_tax_components");
                }
            }
        });
    }
}

function taxItemCtrl($scope,$state, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.settingsTaxRatePane = true;

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        var matches = $state.current.name.match(/^(.*?)\.(.*?)$/);
        if(matches[2] === 'add_tax_item.add_tax_rate'){
            $scope.settingsTaxRatePane = false;
        } else {
            $scope.settingsTaxRatePane = true;
        }
    });

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.addTaxItemForm['addTaxItem[description]'].$error.checkExists = true;
            } else {
                $scope.addTaxItemForm.$setPristine();
                $scope.description = "";
                $scope.settingsstateprovinceid = "";
                $scope.$broadcast("TAXITEM_ADDED", { 'id': data.id, 'description': data.description , 'taxProvinces': data.taxProvinces});
            }
            $scope.saving = false;
        });
    }
}

function taxItemListCtrl($scope, $state, prefix, $http, taxitemresolver, defaultPagingValue,canLoad,$timeout,warningModal) {
    $scope.taxItems = taxitemresolver.taxItemResults;
    $scope.taxProvinceLists = taxitemresolver.taxProvinces;
    $scope.totalTaxItems = taxitemresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.selectedGroup = 'all';
    $scope.searchResult = false;
    $scope.show_filters = true;

    JustAddedUpdatedChanges.call(this, $scope, 'taxItem', $state, defaultPagingValue, 'taxItems');

    $scope.change = function () {
        $scope.currentPage = 1;
        $scope.searchResult = true;
        $scope.fetchTaxItems($scope.currentPage, this.selectedGroup);
    }

    $scope.blurCallback = function () {
        $scope.focused = false;
        $scope.searchResult = true;
        $timeout(function() {
            $scope.show_filters = true;
        }, 700);
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.currentPage = 1;
            $scope.fetchTaxItems($scope.currentPage);
        }
    });

    $scope.$on("TAXITEM_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, taxitemresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.taxItems.push({'id': message.id, 'description': message.description, 'taxProvinces' : message.taxProvinces, 'justadded': true});
            taxitemresolver.taxItems = $scope.taxItems;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        taxitemresolver.count = parseInt(taxitemresolver.count) + 1;
        $scope.totalTaxItems = taxitemresolver.count;

    });

    $scope.editTaxItem = function (id, description , taxProvinces) {
        taxProvinces = (taxProvinces) ? taxProvinces : '';

        $state.transitionTo('loggedin.add_tax_item.edit', {'id': id, 'description': description, 'taxProvinces':taxProvinces,'pageNum': $scope.currentPage, 'limit': $scope.limit})
        /*$http.get(prefix + '/is_tax_item_edit?id=' + id).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                taxProvinces = (taxProvinces) ? taxProvinces : '';

                $state.transitionTo('loggedin.add_tax_item.edit', {'id': id, 'description': description, 'taxProvinces':taxProvinces,'pageNum': $scope.currentPage, 'limit': $scope.limit})
            }
        });*/
    };

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        $http.get(prefix + '/is_tax_item_delete?id=' + id).success(function(data) {
            if (data.warning === true) {
                $scope.canDelete = false;
            }else{
                $scope.canDelete = true;
            }
            $scope.shouldBeOpen = true;
        });
    };

    var canceler = null;
    $scope.fetchTaxItems = function (pageNum,selectedGroup) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;

        if (searchText) {
            $scope.closeDelt = false;
        }
        else {
            $scope.closeDelt = true;
        }

        var selGroup = selectedGroup || $scope.selectedGroup;

        $http.get(prefix + '/list_tax_items?page=' + pageNum + '&group=' + selGroup + '&searchText='+ encodeURIComponent(searchText) + '&limit=' + $scope.limit).success(function (data) {
            $scope.taxItems = data.taxItemResults;
            $scope.totalTaxItems = data.count;
            taxitemresolver.taxItems = data.taxItemResults;
            taxitemresolver.totalTaxItems = data.count;
            $scope.selectedGroup = selGroup;

            if ((searchText != '') || (selGroup != 'all')) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }

            $scope.triggerJustAddedUpdatedTags();

        })
    }
}

function taxItemEditCtrl($scope, $state, $http, prefix,taxItemListResolver,warningModal) {
    $scope.selectedTaxItem = $state.params.description;
    $scope.selectedTaxProvinces = $state.params.taxProvinces;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;
    $scope.taxProvinces = taxItemListResolver.taxProvinces;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.add_tax_item");
    }

    $scope.editTaxItem = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_tax_item', "taxItem[id]=" + this.selectedId + "&taxItem[description]=" + encodeURIComponent(this.selectedTaxItem) + "&taxItem[selectedTaxProvinces]=" + this.selectedTaxProvinces).
        success(function (data, status) {
            if (status == 200) {
                $scope.updating = false;
                if (data.error && data.error == 'Exists') {
                    $scope.current.editTaxItemForm['selectedTaxItem'].$error.checkExists = true;
                } else if (data.error && data.warning == true)
                {
                    warningModal.show(data.message, data.title, data.id);
                }
                else {
                    $state.$current.parent.self.data.pageNum = $scope.pageNum;
                    $state.$current.parent.self.data.editedId = $scope.selectedId;
                    $state.$current.parent.self.data.limit = $scope.limit;
                    $state.transitionTo("loggedin.add_tax_item");
                }
            }
        });
    }
}

function addTaxRateCtrl($scope, $state, $http, prefix, $timeout, $rootScope, getIdData, confirmationBoxHelper,warningModal,toastBox) {
    $scope.$parent.settingsTaxRatePane = false;
    $scope.readAccess = getIdData.readAccess;
    //$scope.selectedTaxRateListDetails = getIdData.taxRateList;
    $scope.selectedItem = getIdData.description;
    $scope.taxProvince = getIdData.taxProvince;
    $scope.isTaxItemEditable = getIdData.isTaxItemEditable;
    $scope.addUrl = prefix + '/system_settings/taxItem/' + $state.params.id + '/add_tax_rate';

    $scope.rowToDelete = [];

    $scope.addTaxRate = function (event) {
        $scope.saving = true;
        var rowsToDelete = ($scope.rowToDelete && $scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
        $http.post($scope.addUrl, "taxRates=" + encodeURIComponent(angular.toJson($scope.spreadSheetData)) + "&rowsToDelete=" + rowsToDelete).
        success(function (data, status) {
            if (status == 200) {
                $scope.saving = false;
                if(data.error) {
                    $scope.isErrorReturn = true;
                    if(data.warning == true)
                    {
                        warningModal.show(data.message, data.title, data.id);
                    }
                } else {
                    $state.transitionTo("loggedin.add_tax_item");
                }
            }
        });
    };


    /*** Routing URL for to load the default data ***/
    $scope.getSpreadsheetURL = prefix + '/system_settings/taxItem/' + $state.params.id + '/get_tax_rate_spreadsheet_data';

    $scope.show_spreadsheet = false;
    $scope.hotId = "tax_rate_list";
    $scope.isErrorReturn = false;
    $scope.spreadsheetColHeader = ['id','Tax component','Tax rate (%)','Compound','Action'];
    $scope.shpreadsheetDataSchema= {id: '', taxComponent: '', taxRate: 0, compound:0 ,deleteAction: ""};
    $scope.hiddenColumns = (!$scope.isTaxItemEditable) ? [0,4]: [0];
    $scope.spreadsheetColWidths = [40,600,80,75,60];

    /*** Loaded new spreadsheet ***/
    taxSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $timeout, toastBox);

}

function taxSpreadsheet($scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $timeout, toastBox)
{
    var isSpreadsheetReadOnly =  !$scope.isTaxItemEditable;
    var spreadsheetDefaultSettings = function spreadsheetDefaultSettings() {
        $scope.spreadSheetData = [];
        $scope.isSpreadSheetEmpty = true;
        $scope.displaySpreadsheet = false;
        $scope.isSpreadsheetvalidate = true;
        $scope.instanceLoad=[];

        spreadsheetScroll.call(this, $scope);

        /*** Spreadsheet default settings ***/
        $scope.rowHeaders = true;
        $scope.startRows = 1;
        /*** Set spare row as 0, because when load default data it automatically insert an another empty row ***/
        $scope.minSpareRows = 0;
        $scope.minRows = 1;
        $scope.width = "auto";
        $scope.autoWrapCol = true;
        $scope.autoWrapRow = true;
        $scope.rowCount = 0;
        $scope.selectedDropDownValues= [];

        $scope.NonEmptyRowsCount = function NonEmptyRowsCount(colData){
            var nonEmptyRowsCount = colData.reduce(function (count, data) {
                return $scope.isColEmpty(data) ? count : count + 1;
            }, 0);
            return nonEmptyRowsCount;
        };
        $scope.isColEmpty = function isColEmpty(value){
            return typeof value == 'undefined' || value === null || value.length === 0;
        };
        $scope.spreadSheetValidation = function(saveRecords, isSpreadSheetEmpty, limit) {
            $scope[isSpreadSheetEmpty] = true;

            //# Relax the cell validation.
            var releaseCells = [7];

            for(var i=0; i< limit; i++) {

                //# Get the single row details
                var row = saveRecords[i];

                //# j=1 assigned to avoid the first column of 'primaryID' of the row.
                //# length-1 used to avoid the last column of 'delete' of the row
                for(var j=1; j<row.length-1; j++ ) {

                    //# Relax the validation for particular cell
                    var cellRelax = releaseCells.indexOf(j);

                    //# Cell all the cells are valid or not
                    if((row[j] === '' || row[j] == null) && cellRelax == -1 ) {
                        $scope.validateCell=true;
                        break;
                    } else {
                        $scope.validateCell=false;
                    }
                }
                //# If any cols is invalid then break the loop.
                if($scope.validateCell == true) {
                    $scope[isSpreadSheetEmpty]=true;
                    break;
                } else {
                    $scope[isSpreadSheetEmpty]=false;
                }
            }
        };

        $scope.deleteAction = function(r, c, hotId) {
            var confirmation_message = 'This row has not been saved yet, are you sure you want to delete it?';
            if(c === ($scope.instanceLoad[hotId].getInstance().countCols() -1)) {
                //# Before delete confirm
                confirmationBoxHelper.getConfirmation(confirmation_message, this)
                    .then(function() {

                        //# Get the primary id of the edit screen, to delete from backend
                        var rowId=$scope.instanceLoad[hotId].getSourceDataAtRow(r);
                        if((typeof rowId != 'undefined') && rowId.id) {
                            $scope.rowToDelete.push(rowId.id);
                        }
                        var ColData = $scope.instanceLoad[hotId].getDataAtCol(1);
                        $scope.rowCount = $scope.NonEmptyRowsCount(ColData);

                        var taxComponentId = $scope.instanceLoad[hotId].getDataAtCell(r,1);
                        var taxComponent    = _.where($scope.taxComponentList,{id: parseInt(taxComponentId)});
                        $scope.taxComponentSearchBox.push(taxComponent[0]);

                        $scope.instanceLoad[hotId].alter('remove_row', r);
                        $scope.instanceLoad[hotId].render();
                        $scope.getTotalTaxRate();

                    }, function() {
                        return false
                    });
            }
        };

        $scope.readOnlyCol = function (col) {
            var readOnlyCol = [1,2,3];
            if(isSpreadsheetReadOnly)
            {
                _.each(readOnlyCol, function (value, key) {
                    col[value].readOnly = true;

                });
            }
            return col;
        };

        $scope.setCompoundTax = function(r, c, hotId, isCompoundTax) {

            _.each($scope.spreadSheetData,function (value,key) {
                if(r === key && isCompoundTax && $scope.spreadSheetData.length > 1){
                    value.compound = 1;
                }else{
                    value.compound = 0;
                }
            });
            $scope.getTotalTaxRate();
            $scope.$digest();
        };

        $scope.tabMoves= function (event) {
            if(event.shiftKey) {
                return {row: 2, col: 1};
            }
            else {
                return {row: 0, col: 1};
            }
        };

        /** Check the cell validation ***/
        $scope.cellValidator = function (value, callback) {

            if(value != '') {
                if(this.type == 'numeric' && isNaN(value)) {
                    callback(false);
                }
                callback(true);
            } else {
                callback(false);
            }
        };

        /*** If edit the last row then need to insert a new row with default row ***/
        $scope.insertRow = function(currentScope,changes, src) {
            var description = currentScope.getDataAtRow(currentScope.countRows()-1);
            if (changes != null && changes[0][0] === (currentScope.countRows() - 1) && src === 'edit' && description[1] != '') {
                currentScope.alter('insert_row', currentScope.countRows());
                currentScope.render();
            }
        };

        /*** Check the valid html tag ***/
        $scope.strip_tags = function(input, allowed) {
            var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
                commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

            // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
            allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

            return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
                return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
            });
        };

        /*** Get the current active editor and validate ***/
        $scope.checkActiveEditor = function(instance, col, row, checkCol, checkRow) {
            var activeEditor = instance.getActiveEditor();

            if(typeof activeEditor != 'undefined') {
                var cellTitle = instance.getCellMeta(activeEditor.row, activeEditor.col);
                var cellCase = cellTitle.prop;

                var data = instance.getData();
                if(data[row][0] === '') {
                    return true;
                }
            }
            return false;
        };

        /*** Display the error rows ***/
        $scope.spreadSheetErrorRows = function(invalidRows) {

            for(var i=0; i<invalidRows.length; i++) {
                var currentRow = invalidRows[i];
                $scope.displayErrorRows(currentRow, $scope.hotId);
            }
        };

        /** Display the error row line items in spreadsheet **/
        $scope.displayErrorRows = function(currentRow, hotId) {
            var currentMetaRow = $scope.instanceLoad[hotId].getCellMetaAtRow(currentRow);

            for(var j=0; j<currentMetaRow.length; j++ ) {
                $scope.instanceLoad[hotId].setCellMeta(currentRow,j,"className","errorRowList");
                $scope.instanceLoad[hotId].render();
            }
        };

        /** Remove the error class from the line item **/
        $scope.removeErrorMessage = function(row, hotId, toRemove) {
            var currentMetaRow = $scope.instanceLoad[hotId].getCellMetaAtRow(row);
            var reg = new RegExp('(\\s|^)'+toRemove+'(\\s|$)');

            for(var j=0; j<currentMetaRow.length; j++ ) {
                var newCssClass = currentMetaRow[j].className;

                if(newCssClass) {
                    newCssClass=newCssClass.replace(reg,'');
                    $scope.instanceLoad[hotId].setCellMeta(row,j,"className", newCssClass);
                }
            }
            $scope.instanceLoad[hotId].render();
        }
    };

    $scope.beforeValidate = function(value, row, prop, source){
        if(prop == 'taxRate' && value != '')
        {
            var isInvalid = false;
            var col = this.getActiveEditor().col;
            var data = parseInt(value);
            if (isNaN(value)) {
                toastBox.show('Invalid data', 1000);
            } else if (((roundFloat(data, 0,'str')).length) > 2) {
                isInvalid = true;
                toastBox.show('Invalid data length', 1000);
            }

            if (isInvalid) {
                $scope.isSpreadsheetvalidate = false;
                $scope.instanceLoad[$scope.hotId].setCellMeta(row, col, "className", "errorRowList");
            } else {
                $scope.isSpreadsheetvalidate = true;
                $scope.instanceLoad[$scope.hotId].setCellMeta(row, col, "className", "");
            }
            $scope.instanceLoad[$scope.hotId].render();
        }
    };



    var initializeSpreadsheet = function initializeSpreadsheet() {
        spreadsheetDefaultSettings();

        /*** Load the default data into spreadsheet ***/
        $scope.spreadsheetInit= function() {

            /*** Get the default handsontable settings ***/
            $scope.updateSettings = this.updateSettings;

            $http.get($scope.getSpreadsheetURL).success(function(response) {

                $scope.spreadSheetData = response.existTaxRates;
                $scope.taxComponentList = response.taxComponents;
                $scope.taxComponentSearchBox = _.clone(response.taxComponents); // avoid taxComponentList iteration

                /*** Assign the spreadsheet columns and its cell type ***/
                $scope.spreadsheetColumns = [
                    {data: 'id', type: 'numeric', readOnly: true},
                    { renderer: $scope.customRenderer, data: 'taxComponent', editor: 'select2', placeholder: 'Please select',
                        select2Options: {
                            data: $scope.taxComponentSearchBox,
                            dropdownAutoWidth: false,
                            dropdownCssClass: "handsontable-select",
                            width: 'resolve' }
                    },
                    {renderer: $scope.customRenderer, data: 'taxRate',readOnly: false, type: 'numeric', validator: $scope.cellValidator},
                    {renderer: $scope.customRenderer, data: 'compound', disableVisualSelection: true, readOnly: true , className: 'htCenter' },
                    {renderer: $scope.customRenderer, data: 'deleteAction',readOnly: true,disableVisualSelection: true}
                ];

                var hiddenColumns = {columns:$scope.hiddenColumns};
                $scope.readOnlyCol($scope.spreadsheetColumns);
                /*** Load the new settings into the spreadsheet and load them ***/
                $scope.updateSettings({colHeaders: $scope.spreadsheetColHeader, fillHandle: false,
                    rowHeaders: $scope.rowHeaders, startRows: $scope.startRows, startCols: $scope.startCols,
                    colWidths: $scope.spreadsheetColWidths, columns: $scope.spreadsheetColumns, minSpareCols: 0,
                    autoWrapRow:$scope.autoWrapRow,data: $scope.spreadSheetData,hiddenColumns: hiddenColumns,beforeValidate: $scope.beforeValidate,
                    formulas: true,stretchH: 'all', minSpareRows: $scope.minSpareRows, dataSchema: $scope.shpreadsheetDataSchema, minRows: $scope.minRows}); /*dataSchema: $scope.dataSchema,*/

                $scope.displaySpreadsheet = true;
                $scope.show_spreadsheet = true;
            });
        };

        /*** Renderer for spreadsheet ***/
        $scope.customRenderer = function(instance, td, row, col, prop, value, cellProperties) {
            var cellTitle = instance.getCellMeta(row, col);
            var cellCase = cellTitle.prop;
            switch(cellCase) {

                case 'taxComponent':
                    var selectedId;
                    for (var index = 0; index < $scope.taxComponentList.length; index++) {
                        if (parseInt(value) === $scope.taxComponentList[index].id) {
                            selectedId = $scope.taxComponentList[index].id;
                            value = $scope.taxComponentList[index].text;
                            var searchIndex = _.findIndex($scope.taxComponentSearchBox, {'id':selectedId});
                            if(searchIndex != -1)
                            {
                                $scope.taxComponentSearchBox.splice(searchIndex,1);
                            }
                        }
                    }
                    Handsontable.cellTypes.text.renderer.apply(this, arguments);
                    break;
                    break;
                case 'taxRate':

                    //# Check and update with two decimal point in spreadsheet
                    var newTaxRate = value > 0 ? roundFloat(value,3,'str') : 0;
                    $scope.spreadSheetData[row]['taxRate'] = value = roundFloat(newTaxRate,3);
                    Handsontable.cellTypes.text.renderer.apply(this, arguments);
                    $scope.getTotalTaxRate();
                    break;
                case 'compound':
                    Handsontable.cellTypes.text.renderer.apply(this, arguments);
                    var div = document.createElement('DIV');
                    var a = document.createElement('input');
                    a.type = "radio";
                    a.name = "compound";
                    a.value = 0;
                    a.style= "cursor:pointer;";
                    if($scope.spreadSheetData[row]['compound'] == 1){
                        a.checked = true;
                    }
                    div.appendChild(a);
                    $(td).empty().append(div).append(a);
                    $(a).on('click', function(e) {
                        var  isCompoundStatus;
                        if(this.value == 0)
                        {
                            this.checked = isCompoundStatus = true;
                            this.value = 1;
                        }else
                        {
                            this.checked = isCompoundStatus = false;
                            this.value = 0;
                        }
                        $scope.setCompoundTax(row, col, $scope.hotId ,isCompoundStatus);
                    });
                    return td;
                    break;
                case 'deleteAction':
                    Handsontable.cellTypes.text.renderer.apply(this, arguments);
                    var div = document.createElement('DIV');
                    var a = document.createElement('a');
                    var linkText = document.createTextNode("Delete");
                    a.appendChild(linkText);
                    a.title = "Delete Action";
                    a.style= "cursor:pointer;";
                    div.appendChild(a);
                    $(td).empty().append(div).append(a);
                    $(a).on('click', function(e) {
                        $scope.deleteAction(row, col, $scope.hotId);
                    });
                    return td;
                    break;
            }
        };

        /*** After change action from spreadsheet ***/
        $scope.spreadsheetAfterChange=function(changes, src) {

            //# Load the spreadsheet Instance record to alter the spreadsheet
            $scope.instanceLoad[$scope.hotId] = this;

            //# If the current src is edit mode, then call the insert function
            if(src === 'edit') {
                $scope.insertRow(this, changes, src);

                if($scope.isErrorReturn) {
                    var row = this.getActiveEditor().row;
                    $scope.removeErrorMessage(row, $scope.hotId, 'errorRowList');
                }

                if(changes[0][1] == 'taxComponent' && changes[0][2] != '')
                {
                    var taxComponent    = _.where($scope.taxComponentList,{id: parseInt(changes[0][2])});
                    $scope.taxComponentSearchBox.push(taxComponent[0]);
                }

            }
            //# Load an empty default data during edit section
            else if((src === 'loadData' && $scope.spreadSheetData.length)) {
                var recordLength = $scope.spreadSheetData.length-1;
                //# Check the last record having id value
                if(!isSpreadsheetReadOnly && $scope.spreadSheetData[recordLength].id != '') {
                    this.alter('insert_row', this.countRows());
                    this.render();
                }

                $timeout(function() {
                    $scope.scrollingSpreadsheet($scope.hotId, $scope.instanceLoad[$scope.hotId]);
                },100);
            }

            //# Check with custom scope value, by default in spreadsheet it having 5 empty rows
            if($scope.spreadSheetData.length > 1) {
                var currentScopeData = this.getData();

                //# Validate each cell from the row to enable the save button
                for(var i=0; i< currentScopeData.length-1; i++) {

                    //# Get the single row details
                    var row = currentScopeData[i];

                    //# j=1 assigned to avoid the first column of 'primaryID' of the row.
                    //# length-1 used to avoid the last column of 'delete' of the row
                    for(var j=1; j<row.length-1; j++ ) {

                        //# Cell all the cells are valid or not
                        if(row[j] === '' || row[j] == null ) {
                            $scope.validateCell=true;
                            break;
                        } else {
                            $scope.validateCell=false;
                        }
                    }
                    //# If any cols is invalid then break the loop.
                    if($scope.validateCell == true) {
                        $scope.isSpreadSheetEmpty=true;
                        break;
                    } else {
                        $scope.isSpreadSheetEmpty=false;
                    }
                }
            }


            var validationCond = $scope.spreadSheetData.length > 1;
            var emptyRow = true;

            // # Check with custom scope value, by default in spreadsheet it having 5 empty rows
            if(validationCond) {
                currentScopeData = this.getData();
                if(emptyRow == true){
                    var limit = currentScopeData.length-1;
                }else if(emptyRow == false){
                    var limit = currentScopeData.length;
                }

                // # Validate each cell from the row to enable the save button
                $scope.spreadSheetValidation(currentScopeData, $scope.hotId, limit);
                var colData = this.getDataAtCol(1);
                $scope.rowCount = $scope.NonEmptyRowsCount(colData);
            }
        };

        $scope.getTotalTaxRate = function() {
            var totalTaxRate = 0 ,effectiveTotalTaxRate = 0;
            angular.forEach($scope.spreadSheetData,function (value,key) {
                totalTaxRate += value.taxRate;
                totalTaxRate = roundFloat(totalTaxRate,3);
                if(value.compound){
                    effectiveTotalTaxRate += value.taxRate;
                    effectiveTotalTaxRate = roundFloat(effectiveTotalTaxRate,3);
                }
            });
            $scope.totalTaxRate = totalTaxRate;
            if(effectiveTotalTaxRate)
            {
                $scope.effectiveTotalTaxRate = totalTaxRate + ((totalTaxRate - effectiveTotalTaxRate) * effectiveTotalTaxRate/100);
            }else {
                $scope.effectiveTotalTaxRate = effectiveTotalTaxRate
            }
            $scope.effectiveTotalTaxRate = roundFloat($scope.effectiveTotalTaxRate,3,'str');
        };
    };

    return initializeSpreadsheet();

}
function provincesCtrl($scope,$state, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.settingsTaxRatePane = true;

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        var matches = $state.current.name.match(/^(.*?)\.(.*?)$/);
        if(matches[2] === 'add_tax_item.add_tax_rate'){
            $scope.settingsTaxRatePane = false;
        } else {
            $scope.settingsTaxRatePane = true;
        }
    });

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.addProvincesForm['provinces[description]'].$error.checkExists = true;
            } else {
                $scope.addProvincesForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("PROVINCE_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}
function provincesListCtrl($scope, $state, prefix, $http, provincesresolver, defaultPagingValue,canLoad,$timeout,warningModal) {
    $scope.provinces = provincesresolver.provinces;
    $scope.count = provincesresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;
    $scope.searchResult = false;
    $scope.show_filters = true;

    JustAddedUpdatedChanges.call(this, $scope, 'province', $state, defaultPagingValue, 'provinces');

    $scope.change = function () {
        $scope.currentPage = 1;
        $scope.searchResult = true;
        $scope.fetchProvinces($scope.currentPage);
    }

    $scope.blurCallback = function () {
        $scope.focused = false;
        $scope.searchResult = true;
        $timeout(function() {
            $scope.show_filters = true;
        }, 700);
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.currentPage = 1;
            $scope.fetchProvinces($scope.currentPage);
        }
    });

    $scope.$on("PROVINCE_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, provincesresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.provinces.push({'id': message.id, 'description': message.description, 'justadded': true});
            provincesresolver.provinces = $scope.provinces;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        provincesresolver.count = parseInt(provincesresolver.count) + 1;
        $scope.count = provincesresolver.count;

    });

    $scope.editProvince = function (id, description) {

        // $http.get(prefix + '/is_province_edit?id=' + id).success(function(data) {
        //     if (data.warning === true) {
        //         warningModal.show(data.message, data.title, data.id);
        //     }  else {
        //         taxProvinces = (taxProvinces) ? taxProvinces : '';
        //
                $state.transitionTo('loggedin.add_provinces.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
        //     }
        // });
    };

    $scope.triggerDelete = function (id, description, canDelete) {
        if(canDelete == false) {
            warningModal.show('You cannot delete this item because it has already been used in properties.', 'Customer County');
        } else {
            $scope.selectedId = id;
            $scope.shouldBeOpen = true;
        }
    };

    var canceler = null;
    $scope.fetchProvinces = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;

        if (searchText) {
            $scope.closeDelt = false;
        }
        else {
            $scope.closeDelt = true;
        }

        $http.get(prefix + '/list_provinces?page=' + pageNum + '&searchText='+ encodeURIComponent(searchText) + '&limit=' + $scope.limit).success(function (data) {
            $scope.provinces = data.provinces;
            $scope.count = data.count;
            provincesresolver.provinces = data.provinces;
            provincesresolver.count = data.count;

            if ((searchText != '')) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }

            $scope.triggerJustAddedUpdatedTags();

        })
    }
}
function provincesEditCtrl($scope, $state, $http, prefix,provinceListResolver,warningModal) {
    $scope.selectedProvince = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;
    $scope.provinces = provinceListResolver.provinces;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.add_provinces");
    }

    $scope.editProvince = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_province', "province[id]=" + this.selectedId + "&province[description]=" + encodeURIComponent(this.selectedProvince) ).
        success(function (data, status) {
            if (status == 200) {
                $scope.updating = false;
                if (data.error && data.error == 'Exists') {
                    $scope.current.editProvinceForm['selectedProvince'].$error.checkExists = true;
                } else if (data.error && data.warning == true)
                {
                    warningModal.show(data.message, data.title, data.id);
                }
                else {
                    $state.$current.parent.self.data.pageNum = $scope.pageNum;
                    $state.$current.parent.self.data.editedId = $scope.selectedId;
                    $state.$current.parent.self.data.limit = $scope.limit;
                    $state.transitionTo("loggedin.add_provinces");
                }
            }
        });
    }
}
