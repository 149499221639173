function SchedulingCtrl($scope, $http, $q, prefix, $csMapper, canLoad, skills_data, $state, estimateJobDetails, copyDescription, users_to_show_on_dairy, $rootScope, $window, colorSelector, csPopUpPosition, appointmentLengthHour, appointmentLengthMinute, appointmentDuration) {
    $scope.duration = appointmentDuration != null ? appointmentDuration : 60;
    $scope.copyDescription = copyDescription;
    $scope.scheduleHour = appointmentLengthHour != null ? appointmentLengthHour : 1;
    $scope.scheduleMinutes = appointmentLengthMinute != null ? appointmentLengthMinute : '00';
    $scope.loadingData = false;
    $scope.selectedSkills = skills_data;
    $scope.results = [];
    $scope.selectedEvent = false;
    $scope.locations = [];
    $scope.drawnMarkers = [];
    $scope.mapInitialised = false;
    $scope.searching = false;
    $scope.loadingFirstTime = true;
    $scope.skillsData = skills_data;
    $scope.userDetails = false;
    $scope.show_warning = false;
    $scope._ = _;
    $scope.limit  = 10;
    //$scope.users_to_show_on_dairy = users_to_show_on_dairy;
    var csMapper = $csMapper.get();
    var eventMarker = false;
    var afterEventMarker = false;
    var userMarker = false;
    var diaryColors = colorSelector.getColors('diary_color');

    $scope.mapNavigation = [];
    $scope.number_of_days = 7;

    $scope.diary_right_bar = angular.element('#diary-right-bar').scope();
    $scope.unSubscribeEvents = function() {
        //console.log(arguments);
    }

    $scope.setEventMarker = function() {
        $http.get('template/diary/map_elements/event_marker.html').then(function(response) {
            eventMarker = response.data;
            afterEventMarker = eventMarker.replace(/beforeEvent/g, 'afterEvent');
        });
        $http.get('template/diary/map_elements/appointment_user_marker.html').then(function(response) {
            userMarker = response.data;
        });
    }

    $scope.setEventMarker();

    SkillsManager.call(this, $scope, skills_data);

    $scope.$on('$viewContentLoaded', function() {
        if ($state.current.name == 'loggedin.diary_from_job_estimate' || $state.current.name == 'loggedin.diary_from_milestone') {
            //$scope.loadScheduleDetails();
        }
    });

    $scope.initMap = function() {
        if (!$scope.mapInitialised) {
            setTimeout(function() {
                $scope.mapInitialised = true;
                csMapper.initialize('appointment_map', 'vector', false);
                csMapper.addOverlay('Job');
                csMapper.addOverlay('Events');
                csMapper.adjustMapBounds();
                var property = $scope.estimateJobDetails.property;
                var details = {};
                details.latitude = parseFloat(property.latitude_s);
                details.longitude = parseFloat(property.longitude_s);
                if (isNaN(details.longitude) && isNaN(details.latitude)) {
                    if (!$scope.$$phase) {
                        $scope.$apply(function() {
                            $scope.show_warning = true;
                        });

                    } else {

                        $scope.show_warning = true;
                    }
                }
                if (!isNaN(details.latitude)) {
                    csMapper.addPointingMarker('Job', 'JOB', details, null, null, null, [28, 48], function() {});
                    csMapper.panToMarker('Job', 'JOB');
                }
            }, 2000);
        }
    }


    var schedulingCanceler = null;

    $scope.loadScheduleDetails = function() {
        canLoad.setLoadValue(false);
        if (schedulingCanceler) {
            schedulingCanceler.resolve();
        }

        schedulingCanceler = $q.defer();

        var skillsSelected = $scope.selectedSkills.map(function(skill) {
            return skill.id;
        });

        let seletedUsers;
        let diary_right_bar = angular.element('#diary-right-bar').scope();
        if (diary_right_bar && diary_right_bar.hasOwnProperty('users_to_show_on_dairy')) {
            seletedUsers = diary_right_bar.users_to_show_on_dairy.map(function(user) {
                return user.id
            })
        } else {
            seletedUsers = $scope.users_to_show_on_dairy.map(function(user) {
                return user.id
            })
        }
        if(diary_right_bar && diary_right_bar.hasOwnProperty('dateSelected')) {
            $scope.default_date = moment(diary_right_bar.dateSelected);
        }
        else {
            $scope.default_date = $scope.defaultDate;
        }

        $scope.showRightPanel = false;

        $('#loading-indicator').show();
        $scope.searching = true;
        $scope.loadingData = true;
        var data = {
            'length_of_event': $scope.duration,
            'limit': $scope.limit,
            'skills': skillsSelected.join(','),
            'estimate_job_details': estimateJobDetails,
            'users':seletedUsers.join(','),
            'numbers_range': _.range(-$scope.number_of_days, $scope.number_of_days),
            'default_date': $scope.default_date.format('YYYY-MM-DD')
        };
        $http.post(prefix + '/get_suggested_appointments', 'data='+encodeURIComponent(JSON.stringify(data)),
            { timeout: schedulingCanceler.promise }).success(function(data) {
            $scope.loadingData = false;
            $scope.selectedEvent = false;
            $scope.results = data.data;
            console.log()
            var dummyIndex = 0;
            var total_results = [];
            var loopPromises = [];
            angular.forEach($scope.results, function(value, key) {
                var deferred = $q.defer();
                if (value && value.length) {
                    value = value.map(function(record, index) {
                        dummyIndex++;
                        record.dummyIndex = dummyIndex;
                        record.fromDateTime = $scope.convertToUTC(record.fromDateTime);
                        record.endDateTime = $scope.convertToUTC(record.endDateTime);
                        return record;
                    });
                    total_results.push(value);
                    loopPromises.push(deferred.promise);
                    deferred.resolve();
                }
            });
            // re-order the data as we needed.
            $q.all(loopPromises).then(function() {
                var merged = [].concat.apply([], total_results);
                $scope.sorted_results = merged.sort(function(obj1, obj2) {
                    return obj1.timeFromLastJob - obj2.timeFromLastJob;
                });

                $scope.sorted_results = _($scope.sorted_results).chain().sortBy(function(eventDetail) {
                    return eventDetail.current_date_time;
                }).sortBy(function(eventDetail) {
                    return eventDetail.timeFromLastJob;
                }).value();

                $scope.grouped_data = [];
                var lastKey = '';
                angular.forEach($scope.sorted_results, function(value, key) {
                    var data_key = value['current_date_time'];

                    if (data_key == lastKey) {
                        $scope.grouped_data[$scope.grouped_data.length - 1].value.push(value);
                    } else {
                        $scope.grouped_data.push({ date: data_key, value: [value] });
                    }
                    lastKey = data_key;
                });
            });

            $scope.searching = false;
            $('#loading-indicator').hide();
            $scope.removeExistingMarkers();
            $scope.removeExistingNavigation();
        });
    }

    $scope.skillsChanged = function() {
        $scope.selectedSkills = this.selectedSkills;
        if (!$scope.loadingFirstTime) {
            let diary_right_bar = angular.element('#diary-right-bar').scope();
            if ($scope.selectedSkills && $scope.selectedSkills.length) {
                var usersWithSkills = [];
                let skill_loopPromises = [];
                angular.forEach($scope.users, function(value, key) {
                    var deferred = $q.defer();
                    var userAssociatedSkill = value.skills;
                    for (var index = 0, len = userAssociatedSkill.length; index < len; index++) {
                        var tempSkill = userAssociatedSkill[index];
                        var userSkill = _.where($scope.selectedSkills, { id: tempSkill.id });
                        if (userSkill && userSkill.length && usersWithSkills.indexOf(value.id) == -1) {
                            let skill_matched = _.where(usersWithSkills, { id: value.id });
                            if (skill_matched.length == 0) {
                                usersWithSkills.push(value);
                            }
                        }
                        skill_loopPromises.push(deferred.promise);
                    }
                    deferred.resolve();
                })
                $q.all(skill_loopPromises).then(function() {
                    diary_right_bar.users_to_show_on_dairy = usersWithSkills;
                    //$scope.users_to_show_on_dairy = usersWithSkills;
                    loadSchedule()
                });
            } else {
                //diary_right_bar.users_to_show_on_dairy = _.where($scope.users, { shown_on_diary: true });
                //$scope.users_to_show_on_dairy = _.where($scope.users, { shown_on_diary: true });
                loadSchedule()
            }

        } else {
            $scope.loadingFirstTime = false;
        }
    }

    function loadSchedule() {
        $scope.loadScheduleDetails();
        adjustSuggestionHeight()
    }

    //Function to handle adjust Suggestion Height
    function adjustSuggestionHeight() {
        let w = angular.element($window);
        let skills_div =  angular.element('#suggestion_skills');
        let length_event =  angular.element('#length_event');
        let range_section =  angular.element('#range_section');
        let suggestion_div = angular.element('#suggestion_id');
        setTimeout(function() {
            suggestion_div.css('height', (w.height() - (160 + (skills_div.height()+range_section.height()+length_event.height()))) + 'px');
        }, 100)
    }

    $scope.availableTime = '';
    $scope.showSelectedOption = function(eventData) {
        $scope.selectedEvent = eventData;
        $scope.showRightPanel = false;
        var beforeEvent = $scope.selectedEvent.beforeEvent;
        var afterEvent = $scope.selectedEvent.afterEvent;
        var eventId = '';
        $scope.availableTime = eventData.availableTime.toString().replace('(', '').replace(')', '');

        if (beforeEvent.hasOwnProperty('diaryId')) {
            eventId += beforeEvent.diaryId.toString();
        }

        if (afterEvent.hasOwnProperty('diaryId')) {
            eventId = eventId == '' ? eventId : eventId + ',';
            eventId += afterEvent.diaryId.toString();
        }

        $http.get(prefix + '/diary/get_selected_events?events=' + eventId + '&engineerId=' + eventData.engineerId).success(function(data) {
            $scope.setRightPanelData();
            var events = data.events;
            $scope.suggested_apt_events = events;
            $scope.userDetails = data.userDetails;
            $scope.changeMapView(events);
            $scope.showRightPanel = true;
        });
    }

    $scope.changeMapView = function(events) {
        var beforeEvent = $scope.selectedEvent.beforeEvent;
        var afterEvent = $scope.selectedEvent.afterEvent;
        $scope.removeExistingMarkers();
        csMapper.cleanOverlay('Events');
        $scope.handleMapView(beforeEvent, events, 'before');
        $scope.handleMapView(afterEvent, events, 'after');
    }

    $scope.handleMapView = function(selectedEvent, events, typeStr) {
        let colorDetails = _.where(diaryColors, { color: $scope.selectedEvent.engineerColor });
        let polylineColor = 'rgb(90, 155, 216)';
        if (colorDetails.length) {
            polylineColor = colorDetails[0]['rgba_dark']
        }
        if (selectedEvent) {
            var marker = typeStr == 'after' ? afterEventMarker : eventMarker;
            var scopeName = typeStr + 'Event';
            if (selectedEvent.hasOwnProperty('diaryId')) {
                var eventDetails = _.where(events, { id: selectedEvent.diaryId });
                if (eventDetails && eventDetails.length) {
                    selectedEvent['eventDetails'] = eventDetails[0];
                    var details = { event_details: eventDetails, latitude: parseFloat(selectedEvent.Latitude), longitude: parseFloat(selectedEvent.Longitude), markerSuffix: selectedEvent.diaryId.toString(), markerIconClassName: 'leaflet-pointer-marker-icon', };
                    $scope[scopeName] = selectedEvent['eventDetails'];

                    var markerName = csMapper.addDivMarker('Events', details, $scope, marker, null, [14, 42]);
                    $scope.drawnMarkers.push(markerName);
                    if (selectedEvent.geometry_details.length && selectedEvent.geometry_details[0]['geometry']['coordinates'].length > 2) {
                        csMapper.addNavigation('Events', 'navigation_' + typeStr, swapElements(selectedEvent.geometry_details[0]['geometry']['coordinates']), polylineColor, selectedEvent, null);
                        let checkExist = $scope.mapNavigation.indexOf('navigation_' + typeStr);
                        if (checkExist === -1) {
                            $scope.mapNavigation.push('navigation_' + typeStr);
                        }
                    }
                }
            } else {
                if (typeStr == 'before') {
                    var details = { latitude: parseFloat(selectedEvent.Latitude), longitude: parseFloat(selectedEvent.Longitude), markerSuffix: 'user', markerIconClassName: 'leaflet-user-marker-icon' };
                    var markerName = csMapper.addDivMarker('Events', details, $scope, userMarker, null, [28, 74]);
                    $scope.drawnMarkers.push(markerName);
                    if (selectedEvent.geometry_details.length && selectedEvent.geometry_details[0]['geometry']['coordinates'].length > 2) {
                        csMapper.addNavigation('Events', 'navigation_' + typeStr, swapElements(selectedEvent.geometry_details[0]['geometry']['coordinates']), polylineColor, selectedEvent, null);
                        let checkExist = $scope.mapNavigation.indexOf('navigation_' + typeStr);
                        if (checkExist === -1) {
                            $scope.mapNavigation.push('navigation_' + typeStr);
                        }
                    }
                }
            }
            csMapper.adjustMapBounds();
        }
    }

    /**
     * Function to handle swap list array element
     * @param mapping
     * @returns {Array}
     */
    function swapElements(mapping) {
        let swapped_array = [];
        mapping.forEach(function(inner_array) {
            swapped_array.push([inner_array[1], inner_array[0]]);
        });
        return swapped_array;
    }

    /**
     * Function to handle show popup data in job/estimate markers
     * @param $event
     * @param event_details
     */
    $scope.getSuggestedPopUpData = function getPopUpData($event, event_details) {
        $scope.pop_up_data = event_details;
        csPopUpPosition.init($event);
    };

    /**
     * Function to handle redirect when click job/estimate markers
     * @param $event
     * @param event_details
     */
    $scope.singleClick = function markerSingleClick($event, event_details) {
        var redirectDetails = event_details.redirectDetails;
        var primaryId, milestoneId;
        if (event_details.type == 'estimate') {
            primaryId = redirectDetails.estimateId;
        } else {
            primaryId = redirectDetails.jobId;
            milestoneId = redirectDetails.milestoneId;
        }
        let job_estimate_url = '';
        if(redirectDetails.type == 'estimate') {
            job_estimate_url = $state.href('loggedin.customer_list.view.estimate_details.survey', { id: redirectDetails.customerId, estimateId: primaryId, type: redirectDetails.customerType});
        } else if(redirectDetails.type == 'project_estimate') {
            job_estimate_url = $state.href('loggedin.customer_list.view.project_estimate_details.survey', { id: redirectDetails.customerId, estimateId: primaryId, type: redirectDetails.customerType});
        } else if(redirectDetails.type == 'job' || redirectDetails.type == 'additional_work') {
            job_estimate_url = $state.href('loggedin.customer_list.view.job.details', { id: redirectDetails.customerId, jobId: primaryId, type: redirectDetails.customerType});
        } else if(redirectDetails.type == 'project_job') {
            job_estimate_url = $state.href('loggedin.customer_list.view.job.visits.view', { id: redirectDetails.customerId, jobId: primaryId, type: redirectDetails.customerType, milestoneId: milestoneId});
        }
        $window.open(job_estimate_url,'_blank');
    };
    /**
     * Function to handle remove existing navigations
     */
    $scope.removeExistingNavigation = function() {
        if ($scope.mapNavigation.length) {
            _.each($scope.mapNavigation, function(value) {
                if (value) {
                    csMapper.hideNavigation('Events', value, true);
                }
            });
            $scope.mapNavigation = [];
        }
    };

    $scope.removeExistingMarkers = function() {
        if ($scope.drawnMarkers.length) {
            _.each($scope.drawnMarkers, function(value) {
                if (value) {
                    var marker = value.split('_');
                    csMapper.deleteMarker(marker[0], marker[1]);
                }
            });
            $scope.drawnMarkers = [];
        }
    };

    // Right side settings
    $scope.showFromEvent = false;
    $scope.showToEvent = false;
    $scope.showHomeAddress = false;
    $scope.showRightPanel = false;
    $scope.setRightPanelData = function() {
        $scope.showFromEvent = false;
        $scope.showToEvent = false;
        $scope.showHomeAddress = false;

        var beforeEvent = $scope.selectedEvent.beforeEvent;
        var afterEvent = $scope.selectedEvent.afterEvent;
        if (beforeEvent && beforeEvent.hasOwnProperty('diaryId')) {
            $scope.showFromEvent = true;
        }
        if (beforeEvent && !beforeEvent.hasOwnProperty('diaryId')) {
            $scope.showHomeAddress = true;
        }
        if (afterEvent && afterEvent.hasOwnProperty('diaryId')) {
            $scope.showToEvent = true;
        }
        $scope.alignRightSideBar();
    }

    $scope.alignRightSideBar = function() {
        setTimeout(function() {
            var homeIcon = $('.home_icon');
            if (homeIcon && homeIcon.length) {
                var homePosition = $('.home_icon')[0].getBoundingClientRect();
                var suggestionPosition = $('.style_color')[0].getBoundingClientRect();
                var barHeight = suggestionPosition.top - homePosition.top;
                var toEvent = $('#left_bar_last_home_icon');
                if ($scope.showToEvent) {
                    if (toEvent && toEvent.length) {
                        var toEventPosition = $('#left_bar_last_home_icon')[0].getBoundingClientRect();
                        barHeight = toEventPosition.top - homePosition.top;
                    }
                }
                $('#suggestion_left_line_main').css('height', barHeight + 2 + 'px')
            }
        }, 50);
    }

    $scope.openAddDiaryPanel = function(eventDetails) {
        $scope.selectedEvent = eventDetails;
        var start = eventDetails.fromDateTime;
        var end = eventDetails.endDateTime;
        var resourceId = eventDetails.engineerId;
        $scope.onSelect(start, end, false, resourceId);
    }

    $scope.convertToUTC = function(inputDate) {
        return moment(moment(inputDate).utc().format('YYYY-MM-DDTHH:mm:ss'));
    }

    $scope.updateMinutes = function() {
        $scope.duration = (this.scheduleHour * 60) + parseInt(this.scheduleMinutes);
        $scope.loadScheduleDetails();
    }
    $scope.updateNumberOfDays = function (number_selected) {
        $scope.number_of_days = number_selected;
        $scope.loadScheduleDetails();
    }

}
