csmodule.directive('tagInput', ['$rootScope', '$compile', '$timeout', '$http', 'prefix', 'reportFilterSettings', '$translate', function($rootScope, $compile, $timeout, $http, prefix, reportFilterSettings, $translate) {
    return {
        restrict: 'E',
        /*
         scope: {
         inputTags: '=taglist',
         filterSettings: '=settings',
         currentTag: '=currentTag',
         category: '=category',
         subCategory: '=subcategory',
         options: '=options',
         autocomplete: '=autocomplete'
         },
         */
        scope: false,
        link: function($scope, element, attrs) {

            function fixDropdownHeight() {
                var dropdowncontainer = $('body > ul.ui-autocomplete'),
                    wheight = $(window).height(), topOffset=170+50, height=wheight-topOffset;
                dropdowncontainer.css('max-height', Math.ceil(height));
            }
            function fixDropdownWidth() {
                var inputElem = $('input.filter-search-field');

          }

          function resizingTagWrapper() {
            $timeout(function () { //console.log('$scope.inputTags', $scope.inputTags, $scope.mode);
              var inputTagWrapper = $(element).find('.input-tag-wrapper');
              var tagMainContainer = $(element).find('.tag-main-container');
              var tagInputCtn = $(element).find('.tag-input-ctn');
              var tagInputCtnWidth = $scope.tagInputCtnWidth;
              //console.log('tagMainContainer.width()', tagMainContainer.width(), (tagInputCtnWidth / 4) * 2.6)
              if (tagMainContainer.width() > ((tagInputCtnWidth / 4) * 2.6) ) {
                var tagcontainer = $(element).find('.filter-element-tags');
                $scope.overflowing = true;
                if (!$(element).hasClass('filter-overflow')) {
                  $(element).addClass('filter-overflow');
                  $(element).find('.filter-search-field').autocomplete('close');
                  //$scope.resetTagSettings();
                }
              }
              else {
                $scope.overflowing = false;
                if($(element).hasClass('filter-overflow')) {
                  $(element).removeClass('filter-overflow');
                }
              }
            }, 5);
          }

            $(window).resize(function(){
                fixDropdownHeight();
            });
            $scope.activeType=null;

            $('body').addClass('reporting_main_wrapper');

            $scope.defaultWidth = 200;
            $scope.daterangeFirstShow = true;
            $scope.notLoading = false;
            $scope.overflowing = false;
            $scope.mode = 'filter-key-select';
            $scope.filterValue = false;
            $scope.selectingCustomDateRange = false;
            $scope.updateFilterTimer = false;
            $scope.autoselectUiObject = false;
            $scope.placeholder = $scope.tagFilterPlaceholder;
            $scope.loadingSpinner = false;
            $scope.keyboardSelection = false;
            $scope.searchbarId = attrs['uniqueid'];
            $scope.dateRangeFilters = [];
            $scope.filterLoadingSpinner = false;
            $scope.refreshResultsImmediately = false;
            $scope.beforeAddTagAction = attrs['beforeAddTagAction'];
            $scope.beforeRemoveTagAction = attrs['beforeRemoveTagAction'];
            $scope.dateRangeTemplateLoaded = false;
            $scope.tagInputCtnWidth = $(element).find('.tag-input-ctn').width();

            if (attrs['dateFilters'] !== null && attrs['dateFilters'] !== undefined) {
                if (attrs['dateFilters'].length > 0) {
                    $scope.dateRangeFilters = attrs['dateFilters'].split(',');
                }
            }
            /*
             $scope.loadDaterange = function() {
             var daterangeHtml = "<div ng-show=\"show_daterange\" class=\"date_range_include filter-element-daterange\" ng-include src=\"'template/date_range_with_feedback.html'\"></div>";
             var compiledHtml = $compile(daterangeHtml)($scope);
             $(element).find('.filter-element-search').before(compiledHtml);
             }
             */

            $(document).on('click', function(evt) {

                if($(evt.target).prop('tagName') === 'INPUT' && $(evt.target).hasClass('filter-search-field')) {
                    var $availableFiltersUI = $('body > .ui-autocomplete').children();
                    var existsTags = _.map($scope.inputTags, function(x){
                        return x.label;
                    });
                    $availableFiltersUI.each(function(ind, elem){
                        var $_elem = $(elem), _label = $_elem.find('a').text();
                        if( _.contains(existsTags, _label) ) {
                            $_elem.remove();
                        }
                    });
                }
                /*
                Prevent to show the mass scheduling date picker event fireing
                 */
                if($('div.mass_scheduling_datepicker').find('div.custom-datepicker').length > 0) {
                    evt.preventDefault();
                    return false;
                }
                if( $(evt.target).attr('id') === 'datepicker-input' || $(evt.target).closest('.daterange_filter').length > 0) {
                    evt.preventDefault();
                    //$scope.cancelInput(evt);
                    return false;
                }
                if (!$(evt.target).hasClass('ui-autocomplete-input') &&
                    !$(evt.target).hasClass('ui-corner-all') &&
                    !$(evt.target).parents().hasClass('daterange-wrapper') &&
                    !$(evt.target).hasClass('daterange-day')) {
                    $scope.cancelInput(evt);
                }
                else {
                    $scope.enableInput(evt);
                }
            });

            $scope.getCurrentTag = function() {
                if ($scope.inputTags) {
                    return $scope.inputTags[$scope.inputTags.length-1];
                }
            };

            $scope.getCurrentTagIndex = function() {
                if ($scope.inputTags) {
                    return $scope.inputTags.length-1;
                }
            };

            $scope.checkIfValueLabelExists = function(tag) {
                return (tag['value_label'] !== undefined && tag['value_label'] !== null);
            };

            $scope.currentTag = $scope.getCurrentTag();

            $scope.getInputTags = function() {
                return $scope.inputTags;
            };

            $scope.showFiltersUI = function() {
                if (reportFilterSettings.filtersFinishedLoading && $scope.dateRangeTemplateLoaded) {
                    $scope.notLoading = true;
                }
            }

            $scope.$on('daterange:template:loaded', function() {
                $scope.dateRangeTemplateLoaded = true;

                $scope.showFiltersUI();
            });

            $scope.$on('reporting_filters:loaded', function(event, data) {
                //$scope.$apply(function(){
                  $scope.inputTags = data;
                  $scope.showFiltersUI();
                //});
            });

            $scope.$watch(reportFilterSettings.filtersFinishedLoading, function(newval, oldval) {
                if (newval !== oldval) {
                    if (newval === true) {
                        $scope.showFiltersUI();
                    }
                }
            });

            $scope.$watch('inputTags', function(){
              resizingTagWrapper();
            });

            $scope.showFiltersUI();

            $scope.isTagInputHappening = function() {
                if ($scope.inputTags.length > 0) {
                    return !$scope.isTagViewable($scope.inputTags[$scope.inputTags.length-1]);
                }
                else {
                    return false;
                }
            };

            $scope.isTagViewable = function(tag) {;
                if (tag) {
                    // There are several different cases here.
                    // 1. If it's a checkbox, it doesn't matter if the filter value is blank
                    // 2. If the tag/filter has intentionally_blank set to false, make sure the filter value is NOT blank
                    // 3. If the tag/filter has intentionally_blank set to true, then it's okay if the filter value is blank. This is because sometimes users may want to search for rows with blank values, for example, an empty job description.
                    if (tag.type == 'checkbox' || (tag.value_label !== '' && !tag.intentionally_blank) || (tag.value_label == '' && tag.intentionally_blank)) {
                        return true;
                    }
                }
                return false;
            };

            $scope.monthLookup = [
                'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
            ];

            $scope.processDateRangeSelection = function(data) {
                if (data.string == "Date Range" && $scope.selectingCustomDateRange == false) {
                    // Don't do anything yet in this case. Let the user choose a custom date range.
                    $scope.selectingCustomDateRange = true;
                    return true;
                }
                $scope.selectingCustomDateRange = false;
                $scope.show_daterange = false;
                $(element).removeClass('date-filter-mode');
                if($scope.subCategory === 'contracts') {
                    let startDate, endDate;
                    startDate = moment(data.startdate).toISOString();
                    startDate = moment.utc(startDate).format('YYYY-MM-DD HH:mm:ss');
                    endDate = moment(data.enddate).set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 59
                    }).toISOString();
                    endDate = moment.utc(endDate).format('YYYY-MM-DD HH:mm:ss');
                    $scope.dateValue = {
                        'startDate': startDate,
                        'endDate': endDate,
                        'dateRange': !data.string ? 'Date Range' : data.string
                    };
                }else {
                    $scope.dateValue = {
                        'startDate': data.startdate + ' 00:00:00',
                        'endDate': data.enddate + ' 23:59:59',
                        'dateRange': !data.string ? 'Date Range' : data.string
                    };
                }
                var newInputTags = [];
                angular.forEach($scope.inputTags, function(tag, index) {
                    if ($scope.dateRangeFilters.indexOf(tag.model_name) == -1) {
                        newInputTags.push($scope.inputTags[index]);
                    }
                });
                $scope.inputTags = angular.copy(newInputTags);
                if (!data.string) {
                    data.string = reportFilterSettings.getDateString($scope.dateValue.startDate, $scope.dateValue.endDate);
                }
                if (!$scope.activeTag) {
                    $scope.activeTag = {};
                    $scope.activeTag['model'] = 'daterange';
                }
                $scope.activeTag['value_label'] = data.string;
                $scope.activeTag['value'] = $scope.dateValue;
                $scope.inputTags.push($scope.activeTag);

                $scope.mode = 'filter-key-select';
                $(element).find('.filter-search-field').autocomplete('enable');
                $(element).find('.filter-search-field').autocomplete('option','source',$scope.getAvailableFilters());

                $scope.resetTagSettings();

                /* End block */
                if ($scope.beforeAddTagAction) {
                    //$rootScope.$broadcast('beforeAddTagAction', {id: $scope.searchbarId, filters: $scope.inputTags});
                }
                else {
                    //$scope.triggerResultsRefresh();
                }
            };

            $scope.$on('dateRangeFilter',  function(evt, data) {
                $scope.processDateRangeSelection(data);
            });

            $scope.$on('selectedDateRange', function(evt, data) {
                $scope.processDateRangeSelection(data);
            });


            $scope.showDateRange = function(show) {
                if (show) {
                    $rootScope.$broadcast('show_daterange');
                    $scope.show_daterange = true;
                    $(element).addClass('date-filter-mode');
                    $scope.$apply();
                }
                else {
                    $scope.show_daterange = false;
                    $(element).removeClass('date-filter-mode');
                    $scope.$apply();
                }
                $rootScope.$broadcast('event:reset_date_filter');
            };

            $scope.clickCheckbox = function(event, item) {
                return $scope.autocompleteSelect(event, {'item': item});
            };

            $scope.initializeAutoselect = function() {
                $timeout(function() {
                    $(element).find('.filter-search-field').autocomplete({
                            minLength: 0,
                            position: { of : $(element).find('.search_box') },
                            source: function(request, response) {
                                var item;
                                return response((function() {
                                    return $scope.getAvailableFilters();
                                })());
                            },
                            focus: (event, ui) => {
                            return $scope.autocompleteFocus(event, ui);
                },
                    select: (event, ui) => {
                        return $scope.autocompleteSelect(event, ui);
                    },
                    open: function(event) {
                        var menu = $(element).find('.filter-search-field').autocomplete('widget').eq(0);
                        var otherFiltersHeaderAdded = false;
                        if ($scope.activeTag &&
                            _.has($scope.activeTag, 'type') &&
                            $scope.activeTag['type'] == 'text' &&
                            $scope.activeTag['value'] === $scope.activeTag['model']) {
                            menu.hide();
                        }
                        else {
                            menu.show();
                        }
                        angular.forEach($(element).find('.filter-search-field').autocomplete('widget')[0].children, function (child, index) {
                            var type = $(child).data('item.autocomplete')['type'];
                            var condition = $(child).data('item.autocomplete')['depends'];
                            $(child).addClass('ui-'+type).data('item-type', type);
                            if(condition) {
                                var dependsOn = _.keys(condition)[0];
                                $(child).addClass('ui-has-condition condition-'+dependsOn).attr('group-name', dependsOn);
                            }
                            var autocompleteData = angular.copy($(child).data('item.autocomplete'));

                            if($(child).hasClass('ui-checkbox')) {
                                var checkboxContainer = $('<span>').addClass('checkbox-container').addClass('condition-' + type);
                                var checkbox = $('<div>').addClass('faux-checkbox')
                                    .click(function (evt) {
                                        $scope.clickCheckbox(evt, autocompleteData);
                                    });
                                var checkboxChecked = false;
                                angular.forEach($scope.inputTags, function (tag, index) {
                                    if (tag.label == $(child).data('item.autocomplete')['label']) {
                                        checkboxChecked = true;
                                        checkbox.addClass('checked');
                                        $scope.inputTags[index]['checked'] = true;
                                    }
                                });
                                checkbox.append($('<i>').addClass('ss-check'));
                                checkboxContainer.append(checkbox);
                                $(child).prepend(checkboxContainer);
                            }

                            if (type && $scope.mode == 'filter-key-select') {
                                if (type == 'checkbox' && condition !== undefined) {
                                    if (menu.find('.condition-' + dependsOn).length == 0) {
                                    }
                                    else {
                                        var conditionalGroup = menu.find('.condition-' + dependsOn).eq(0);
                                    }
                                }
                                else if (type !== "checkbox") {
                                    $(child).data('item.autocomplete')['value'] = $(child).data('item.autocomplete')['model'];
                                }
                            }
                            else if ($scope.mode == 'filter-value-select') {
                                if ($(child).data('item.autocomplete')['value_label'] !== undefined) {
                                    var value_label = $(child).data('item.autocomplete')['value_label'];
                                    $(child).data('item.autocomplete')['label'] = $(child).data('item.autocomplete')['value_label'];
                                }
                                else if ($(child).data('item.autocomplete')['label'] !== undefined) {
                                    var value_label = $(child).data('item.autocomplete')['label'];
                                    $(child).data('item.autocomplete')['value_label'] = $(child).data('item.autocomplete')['label'];
                                }
                                else {
                                    var value_label = $(child).data('item.autocomplete')['value'];
                                    $(child).data('item.autocomplete')['value_label'] = $(child).data('item.autocomplete')['value'];
                                    $(child).data('item.autocomplete')['label'] = $(child).data('item.autocomplete')['value'];
                                }
                                $(child).find('a').text(value_label);
                            }

                        });
                        if ($scope.updateFilterTimer) {
                            $timeout.cancel($scope.updateFilterTimer);
                        }

                        var $conditions = $(element).find('.filter-search-field').autocomplete('widget').find('.ui-has-condition');
                        if($conditions && $conditions.attr('group-name') ) {
                            var groupName = $conditions.attr('group-name');
                            var $newItem = $('<li>').addClass('new-group-item-'+groupName);
                            if(menu.find('condition-'+groupName).length == 0) {

                                var conditionalGroupHeader = $('<li>').addClass('conditional-group-header').text($scope.getTagLabelFromValue('type') + ':');
                                var conditionalGroup = $('<ul>')
                                    .addClass('conditional-group')
                                    .addClass('condition-type')
                                    .append(conditionalGroupHeader);
                                $newItem.append(conditionalGroup);
                                menu.prepend($newItem);
                                if($conditions.hasClass('condition-type')) {
                                    conditionalGroup.append($conditions)
                                }
                            }
                            var otherFiltersHeaderText = $('<li>').addClass('conditional-group-header').text($translate('Other.Filters'));
                            var otherFiltersHeader = $('<ul>').addClass('conditional-group').append(otherFiltersHeaderText);
                            // $newItem.insertAfter(otherFiltersHeader);
                            otherFiltersHeader.insertAfter($newItem);
                        }

                        fixDropdownHeight();
                    },
                    close: function(event) {
                        if ($scope.mode == 'filter-value-select') {
                            $(element).find('.filter-search-field').autocomplete('search','');
                        }
                    },
                    change: function(event) {
                        /*
                         if ($scope.mode == 'filter-value-select') {
                         $(element).find('.filter-search-field').autocomplete('search','');
                         }
                         */
                    }
                });
                    $(element).find('.filter-search-field').unbind('keypress');
                }, 0);
            };

            if (reportFilterSettings.availableFilters) {
                $scope.autocompleteFocus = function(event, ui) {
                    $(element).find('.filter-search-field').val(ui.item.label);
                    return false;
                };

                $scope.autocompleteSelect = function (event, ui, applytag) {
                    $scope.$apply(function () {
                        $(element).find('.filter-search-field').val('');
                    });

                    $scope.autoselectUiObject = ui;

                    if (ui.item.type == 'daterange') {
                      if ($scope.show_daterange) {
                        $scope.showDateRange(false);
                      }
                      else {
                        $scope.showDateRange(true);
                      }
                    }

                    if (event.handleObj.type !== "click") {
                        $scope.keyboardSelection = true;
                    }

                    //resizingTagWrapper();

                    if ($scope.mode == 'filter-key-select') {
                        $scope.activeTag = angular.copy(ui.item);
                        if (!($scope.activeTag['type'] == "text" && $scope.activeTag['type'] == "filter-value-select")) {
                            $scope.$apply('addTag()');
                        }
                    }
                    else {
                        $(element).find('.filter-search-field').val('');
                        $scope.activeTag = angular.copy(ui.item);
                        $scope.$apply('addTag()');
                    }
                    return false;
                };
                $scope.initializeAutoselect();
            }
            $scope.tagArray = function() {
                return $scope.inputTags;
            };

            $scope.markFiltersAsApplied = function() {
                angular.forEach($scope.inputTags, function(tag, key) {
                    tag.applied = true;
                });
            };

            $scope.$on('reportFilterLoading', function(evt, id, newvalue) {
                if (id == $scope.searchbarId) {
                    if (newvalue == true) {
                        $scope.markFiltersAsApplied();
                    }
                    $scope.filterLoadingSpinner = newvalue;
                }
            });

            $scope.addTag = function(noload) {
                if (!Array.isArray($scope.inputTags)) {
                    $scope.inputTags = [];
                }
                if ($scope.mode == 'filter-key-select') {
                    $scope.activeType = $scope.activeTag['type'];

                    if ($scope.activeTag['type']  == 'checkbox') {
                        if (!$scope.activeTag['checked']) {
                            $scope.activeTag['checked'] = true;
                            $scope.activeTag['value'] = true;
                            $scope.inputTags.push($scope.activeTag);
                            $scope.inputTags[$scope.inputTags.length-1]['inputTagsIndex'] = $scope.inputTags.length-1;
                            //$scope.activeTag['value_label'] = $scope.activeTag['value'];

                        }
                        else {
                            $timeout(function() {
                                // This is where checkboxes get unchecked
                                if ($scope.activeTag['inputTagsIndex'] !== undefined && $scope.activeTag['inputTagsIndex'] !== null) {
                                    var tagIndex = $scope.activeTag['inputTagsIndex'];
                                    $scope.inputTags[tagIndex]['checked'] = false;
                                    $scope.deleteTag(null, tagIndex);
                                }
                            }, 0);
                        }

                        $timeout(function() {
                            /* Comment out this block if you want the filter dropdown to NOT re-open automatically after a new filter has been applied */
                            $(element).find('.filter-search-field').autocomplete('enable');
                            $(element).find('.filter-search-field').autocomplete('option','source',$scope.getAvailableFilters());
                            $(element).find('.filter-search-field').autocomplete('open');
                            $(element).find('.filter-search-field').autocomplete('search','');
                            $(element).find('.filter-search-field').val('');
                            /* End block */
                        }, 100);

                        if ($scope.beforeAddTagAction) {
                            $rootScope.$broadcast('beforeAddTagAction', {id: $scope.searchbarId, filters: $scope.inputTags});
                        } else {
                            if(noload !== true ) {
                                $timeout(function(){
                                    var tagIndex = $scope.activeTag['inputTagsIndex'];
                                    if($scope.inputTags[tagIndex]['checked'] !== false) {
                                        //$scope.refreshResultsImmediately = true;
                                        //$scope.triggerResultsRefresh();
                                        $scope.activeTag = false;
                                    }
                                },2);
                                return;
                            }

                        }
                    }
                    else if ($scope.activeTag['type'] == 'smart_filter_dynamic') {
                        $scope.inputTags.push(angular.copy($scope.activeTag));
                        $scope.inputTags[$scope.inputTags.length-1]['value'] = undefined;

                        $(element).find('.filter-search-field').val('');
                        $(element).find('.filter-search-field').attr('placeholder', 'Please enter at least 2 characters to begin searching');
                        $(element).find('.filter-search-field').autocomplete('option','source',$scope.getAvailableFilters());
                        $(element).find('.filter-search-field').autocomplete('disable');
                        $scope.mode = 'filter-value-select';
                    }
                    else if ($scope.activeTag['type'] == "select") {
                        $scope.inputTags.push(angular.copy($scope.activeTag));
                        $scope.inputTags[$scope.inputTags.length-1]['value'] = undefined;

                        $(element).find('.filter-search-field').val('');
                        $(element).find('.filter-search-field').attr('placeholder', $scope.placeholder);
                        $(element).find('.filter-search-field').autocomplete('enable');
                        $(element).find('.filter-search-field').autocomplete('option','source',$scope.activeTag['options']);
                        $(element).find('.filter-search-field').autocomplete('search','');
                        $scope.mode = 'filter-value-select';
                    }
                    else if ($scope.activeTag['type'] == "text") {
                        $scope.inputTags.push(angular.copy($scope.activeTag));
                        $scope.inputTags[$scope.inputTags.length-1]['value'] = undefined;

                        $(element).find('.filter-search-field').autocomplete('option','source',$scope.getAvailableFilters());
                        $(element).find('.filter-search-field').val('');
                        $(element).find('.filter-search-field').attr('placeholder', 'Type search terms here');
                        //$(element).find('.filter-search-field').autocomplete('disable');
                        $(element).find('.filter-search-field').autocomplete('close');
                        $(element).find('.filter-search-field').blur().focus();

                        //if ($scope.keyboardSelection == false) {
                            $scope.mode = 'filter-value-select';
                        //}
                    }
                    else if ($scope.activeTag['type'] == "from") {
                        $scope.inputTags.push(angular.copy($scope.activeTag));
                        $scope.inputTags[$scope.inputTags.length-1]['value'] = undefined;

                        $(element).find('.filter-search-field').autocomplete('option','source',$scope.getAvailableFilters());
                        $(element).find('.filter-search-field').val('');
                        $(element).find('.filter-search-field').attr('placeholder', 'Type a value that the range should start from. Example, "0" or "0.00"');
                        $(element).find('.filter-search-field').autocomplete('disable');
                        $(element).find('.filter-search-field').blur().focus();

                        if ($scope.keyboardSelection == false) {
                            $scope.mode = 'filter-value-select';
                        }
                    }
                    else if ($scope.activeTag['type'] == "to") {
                        $scope.inputTags.push(angular.copy($scope.activeTag));
                        $scope.inputTags[$scope.inputTags.length-1]['value'] = undefined;

                        $(element).find('.filter-search-field').autocomplete('option','source',$scope.getAvailableFilters());
                        $(element).find('.filter-search-field').val('');
                        $(element).find('.filter-search-field').attr('placeholder', 'Type a value that the range should end at. Example, "100" or "100.00"');
                        $(element).find('.filter-search-field').autocomplete('disable');
                        $(element).find('.filter-search-field').blur().focus();

                        if ($scope.keyboardSelection == false) {
                            $scope.mode = 'filter-value-select';
                        }
                    }
                    if($scope.inputTags.length > 0 && $scope.activeTag['type'] !== 'daterange') {
                      $scope.inputTags[$scope.inputTags.length-1]['value_label'] = undefined;
                    }
                }
                else if ($scope.mode == 'filter-value-select') {
                    $scope.inputTags[$scope.inputTags.length-1]['value'] = $scope.activeTag['value'];
                    $scope.inputTags[$scope.inputTags.length-1]['value_label'] = $scope.activeTag['value_label'];

                    if ($scope.inputTags[$scope.inputTags.length-1]['value_label'] === '') {
                        $scope.inputTags[$scope.inputTags.length-1]['intentionally_blank'] = true;
                    }
                    else {
                        $scope.inputTags[$scope.inputTags.length-1]['intentionally_blank'] = false;
                    }

                    $(element).find('.filter-search-field').attr('placeholder', '');
                    $(element).find('.filter-search-field').val('');


                    /* Comment out this block if you want the filter dropdown to stay open each time a new filter is applied */
                    /*
                     $(element).find('.filter-search-field').autocomplete('disable');
                     $(element).find('.filter-search-field').autocomplete('option','source',$scope.getAvailableFilters());
                     */
                    /* End block */

                    /* Comment out this block if you want the filter dropdown to NOT re-open automatically after a new filter has been applied */
                    $timeout(function() {
                      if(!noload) {
                        $(element).find('.filter-search-field').autocomplete('enable');
                        $(element).find('.filter-search-field').autocomplete('option','source',$scope.getAvailableFilters());
                        $(element).find('.filter-search-field').autocomplete('open');
                        $(element).find('.filter-search-field').autocomplete('search','');
                      }
                    }, 1);
                    $(element).find('.filter-search-field').focus();

                    $scope.mode = 'filter-key-select';

                    if (noload !== true && ($scope.activeTag['type'] == 'text' || $scope.activeTag['type'] == 'from' || $scope.activeTag['type'] == 'to')) {
                        //$scope.refreshResultsImmediately = true;
                        //$scope.triggerResultsRefresh();
                        $scope.filterNeedsToBePushedToInputTags = false;
                        resizingTagWrapper();
                        return;
                    }
                    if(noload !== true && $scope.activeType === 'select') {
                        //$(element).find('.filter-search-field').autocomplete('close');
                        //$scope.refreshResultsImmediately = true;
                        //$scope.triggerResultsRefresh();
                        //$scope.activeTag = false;
                        //return;
                    }

                    $scope.resetTagSettings();

                    /* End block */

                    if ($scope.beforeAddTagAction) {
                        //$rootScope.$broadcast('beforeAddTagAction', {id: $scope.searchbarId, filters: $scope.inputTags});
                    }
                    /*else {
                        if(noload !== true) {
                            $scope.triggerResultsRefresh();
                        }

                    }*/

                }
                resizingTagWrapper();
                $scope.filterNeedsToBePushedToInputTags = false;
                return;
            };

            $scope.shouldApplyFiltersBeActive = function () {
                if($scope.mode === 'filter-key-select' && $scope.inputTags.length > 0) {
                  return true;
                }

                if ($scope.activeTag) {
                    if($scope.activeTag['type'] == 'text' && $(element).find('.filter-search-field').val() == '') {
                        return false;
                    }
                    var textBasedFilter = ($scope.activeTag['type'] == 'text' || $scope.activeTag['type'] == 'from' || $scope.activeTag['type'] == 'to');
                    if ($scope.mode == 'filter-value-select' && textBasedFilter) {
                        $scope.updateQueued = true;
                        $scope.refreshResultsImmediately = true;
                        $scope.filterNeedsToBePushedToInputTags = true;
                        return true;
                    }
                }
                else {
                    if ($scope.isUpdateQueued() && !$scope.filterLoadingSpinner) {
                        $scope.updateQueued = true;
                        $scope.refreshResultsImmediately = true;
                        return true;
                    }
                }
                return false;
            };

            $scope.triggerResultsRefresh = function(data) {
                if ($scope.stickyHeader) {
                    $scope.refreshResultsImmediately = false;
                }
                if (data) {
                    if (data['updateFiltersAfter'] == true) {
                        $scope.refreshResultsImmediately = true;
                    }
                }
                if ($scope.refreshResultsImmediately) {
                    $scope.updateQueued = false;
                    $scope.loadingSpinner = true;

                    $rootScope.$broadcast('reportFilterLoading', $scope.searchbarId, true);
                    $rootScope.$broadcast('appliedFiltersUpdated');
                    if (!$scope.stickyHeader && $scope.inputTags.length) {
//					if ($scope.inputTags[$scope.inputTags.length-1]['type'] == "smart_filter_dynamic" ||
//					    $scope.inputTags[$scope.inputTags.length-1]['type'] == "select") {
                        //$rootScope.$broadcast('smart_tablefilter:resetpage', {category: 'reporting_' + $scope.category + '_' + $scope.subCategory});
                        $rootScope.$broadcast('panel_with_form:filter:reporting_' + $scope.category + '_' + $scope.subCategory, $scope.inputTags,{
                          resetPage:true
                        });
//					}
                    }
                    else if (!$scope.stickyHeader && $scope.inputTags.length == 0) {
                        //$rootScope.$broadcast('smart_tablefilter:resetpage', {category: 'reporting_' + $scope.category + '_' + $scope.subCategory});
                        $rootScope.$broadcast('panel_with_form:filter:reporting_' + $scope.category + '_' + $scope.subCategory, $scope.inputTags,{
                          resetPage:true
                        });
                    }
                    $scope.refreshResultsImmediately=false;
                    return true;
                }
                else {
                    $scope.updateQueued = true;
                }

                $scope.updateFilterTimer = $timeout(function() {
                    if ($scope.refreshResultsImmediately) {
                        $scope.refreshResultsImmediately = false;

                        $scope.updateFilters();
                    }
                    /* Comment out this block if you want the filter dropdown to stay open each time a new filter is applied */
                    /*
                     $timeout(function() {
                     $(element).find('.filter-search-field').autocomplete('enable');
                     }, 100);
                     */
                    /* End block */
                }, 1000);

                $timeout(function() {
                    $scope.mode = 'filter-key-select';
                    $scope.resetTagSettings();
                }, 1);
            };

            $scope.$on('updateFilters', function(e, data) {
                var id = (data) ? data['id'] : undefined;

                if (id == undefined) {
                    // default behavior
                    $scope.triggerResultsRefresh(data);
                }
                else if (id == $scope.searchbarId) {
                    // if a specific id is defined, check to see if $scope.searchbarId matches it
                    $scope.triggerResultsRefresh(data);
                }
            });

            $scope.resetTagSettings = function() {
                $scope.activeTag = false;
                /*
                 $scope.tagText = "";
                 $scope.tagValue = "";
                 $scope.tagCondition = "";
                 $scope.tagType = false;
                 $scope.tagLabel = "";
                 */
            };

            $scope.isUpdateQueued = function() {
                return $scope.updateQueued;
            };

            $scope.$on('stickyHeader', function(evt, newvalue) {
                $scope.stickyHeader = newvalue;
                var appHeight= $(element).find('.tag-input-ctn') + 10;
                if(newvalue === true) {
                  $('body').addClass('reporting_mode_sticky');
                }
                else {
                  $('body').removeClass('reporting_mode_sticky');
                  appHeight='auto';
                }
                $('body').find('.app-filter').css({
                  height: appHeight
                });
            });

            $scope.updateFilters = function(evt) {
                var fromBtn = false;
                if (evt) {
                    fromBtn=true;
                    evt.preventDefault();
                }
                if ($scope.filterNeedsToBePushedToInputTags) {
                    $scope.addTag(fromBtn);
                }
                if ($scope.inputTags.length > 0 || $scope.updateQueued) {
                    if ($scope.stickyHeader) {
                        $rootScope.$broadcast('scrollReportToTop', {'updateFiltersAfter': true, 'id': $scope.searchbarId});
                    }
                    else {
                        $scope.updateQueued = false;
                        $scope.refreshResultsImmediately = false;

                        $scope.loadingSpinner = true;
                        $rootScope.$broadcast('reportFilterLoading', $scope.searchbarId, true);
                        $rootScope.$broadcast('appliedFiltersUpdated');

                        //$rootScope.$broadcast('smart_tablefilter:resetpage', {category: 'reporting_' + $scope.category + '_' + $scope.subCategory});
                        $rootScope.$broadcast('panel_with_form:filter:reporting_' + $scope.category + '_' + $scope.subCategory, $scope.inputTags, {
                          resetPage:true
                        });
                    }
                    $scope.$broadcast('tagInput:afterUpdateOrRefresh');
                }
            };

            $scope.enableInput = function (event) {
                if ($scope.disabled) {
                    $scope.disabled = false;
                    $(element).find('.filter-search-field').autocomplete('enable');
                    $(element).find('.filter-search-field').autocomplete('option', 'source', $scope.getAvailableFilters());
                    $(element).find('.filter-search-field').autocomplete('search', '');
                }
            };

            $scope.processNormalKeyup = function() {
                $timeout(function() {
                    /*
                     $(element).find('.filter-search-field').autocomplete('enable');
                     $(element).find('.filter-search-field').autocomplete('disable');
                     $(element).find('.filter-search-field').autocomplete('enable');
                     */
//                    $(element).find('.filter-search-field').autocomplete('option','source',$scope.getAvailableFilters());
                    if ($scope.mode == 'filter-key-select') {
                        $(element).find('.filter-search-field').autocomplete('option','source',$scope.getAvailableFilters());
                    }
                    else if ($scope.mode == 'filter-value-select') {
//                    $(element).find('.filter-search-field').autocomplete('option','source',$scope.activeTag['options']);
                        /*
                         $(element).find('.filter-search-field').autocomplete('close');
                         $(element).find('.filter-search-field').autocomplete('disable');
                         $(element).find('.filter-search-field').autocomplete('option','source',$scope.activeTag['options']);

                         $(element).find('.filter-search-field').autocomplete('enable');
                         $(element).find('.filter-search-field').autocomplete('option','source',$scope.activeTag['options']);
                         */
                        //                   $(element).find('.filter-search-field').autocomplete('option','source',$scope.getAvailableFilters());

                        /*
                         angular.forEach($scope.activeTag['options'], function(option, optionindex) {
                         $scope.activeTag['options'][optionindex]['label'] = $scope.activeTag['options'][optionindex]['value_label'];
                         });
                         */
                    }

//                      $(element).find('.filter-search-field').autocomplete('search',$(element).find('.filter-search-field').val());
                }, 1);
            };

            $scope.cancelInput = function(event, key) {
                if ($scope.activeTag) {
                    if (!$scope.disabled) {
                        if ($scope.activeTag['type'] == 'daterange') {
                            $scope.showDateRange(false);
                        }
                        else if ($scope.mode == 'filter-value-select') {
                            $timeout(function() {
                                $scope.inputTags.pop();
                                $scope.activeTag = false;

                                $scope.mode = 'filter-key-select';
                                $(element).find('.filter-search-field').autocomplete('close');
                                $(element).find('.filter-search-field').autocomplete('disable');
                                $(element).find('.filter-search-field').attr('placeholder', $scope.placeholder);
                                $(element).find('.filter-search-field').autocomplete('option','source',$scope.getAvailableFilters());

                                $scope.disabled = true;

                                $scope.resetTagSettings();
                            }, 1);
                        }
                    }
                }
                else {
                  $(element).find('.filter-search-field').autocomplete('close');
                  $(element).find('.filter-search-field').autocomplete('disable');
                  $(element).find('.filter-search-field').attr('placeholder', $scope.placeholder);
                  $scope.disabled = true;
                }
            };

            $scope.deleteTag = function(e, key) {
                if ($scope.updateFilterTimer) {
                    $timeout.cancel($scope.updateFilterTimer);
                }

                $timeout(function () {
                    var newInputTags = [];
                    var filtersToDelete = {};

                    /* deleting filters which depend on the filter being removed */
                    angular.forEach($scope.inputTags, function (tag, index) {
                        if (tag['depends']) {
                            angular.forEach(tag['depends'], function (dtag, dindex) {
                                if (dindex.toLowerCase() == $scope.inputTags[key].model.toLowerCase()) {
                                    $scope.inputTags[key]['dependentFiltersAdded'] = false;
                                    var dependentFilter = tag.model;
                                    reportFilterSettings.deletedTags.push(tag.model);
                                    filtersToDelete[dependentFilter] = true;
                                }
                            });
                        }
                    });

                    angular.forEach($scope.inputTags, function (tag, index) {
                        if (!filtersToDelete[tag.model]) {
                            newInputTags.push(tag);
                        }
                    });
                    // if this is a date filter, broadcast the reset_date_filter event
                    if (key !== undefined && key !== false && key !== null) {
                        if (newInputTags[key].type == "daterange") {
                            $rootScope.$broadcast('event:reset_date_filter');
                            $scope.dateValue = undefined;
                        }
                    }

                    if ($scope.inputTags.length > 0 && key === undefined) {
                        // if there's no key defined, delete the last tag in the list
                        newInputTags.pop();
                    }
                    else {
                        /* now, deleting the filter which the user wants to remove */
                        if (key !== undefined) {
                            reportFilterSettings.deletedTags.push(newInputTags[key].model);
                            newInputTags.splice(key, 1);
                        }
                    }

                    /*
                     angular.forEach($scope.inputTags, function(tag, index) {
                     if (tag.model == $scope.getCurrentTag().model) {
                     newInputTags.splice(index, 1);
                     }
                     });
                     */

                    $scope.inputTags = angular.copy(newInputTags);
                    $(element).find('.filter-search-field').autocomplete('option', 'source', $scope.getAvailableFilters());
                    $(element).find('.filter-search-field').val('');
                    $(element).find('.filter-search-field').attr('placeholder', '');
                    $(element).find('.filter-search-field').autocomplete('enable');
                    $(element).find('.filter-search-field').blur().focus();

                    $scope.updateFilterTimer = $timeout(function () {
                        if ($scope.beforeRemoveTagAction) {
                            $rootScope.$broadcast('beforeRemoveTagAction', {id: $scope.searchbarId, filters: $scope.inputTags});
                        }
                        else {
                            if ($scope.refreshResultsImmediately) {
                                $scope.refreshResultsImmediately = false;
                                //$scope.updateFilters();
                            }
                        }
                        $scope.updateFilters();
                    }, 1);

                    if ($scope.inputTags.length == 0) {
                        if ($scope.stickyHeader) {
                            $rootScope.$broadcast('scrollReportToTop', {'updateFiltersAfter': true, 'id': $scope.searchbarId});
                        }
                        else {
                            $rootScope.$broadcast('appliedFiltersUpdated');

                            $rootScope.$broadcast('reportFilterLoading', $scope.searchbarId, true);

                            $scope.loadingSpinner = true;
                            $scope.updateQueued = false;

                            //$rootScope.$broadcast('smart_tablefilter:resetpage', {category: 'reporting_' + $scope.category + '_' + $scope.subCategory});
                            $rootScope.$broadcast('panel_with_form:filter:reporting_' + $scope.category + '_' + $scope.subCategory, $scope.inputTags, {
                              resetPage:true
                            });
                        }
                    }
                    else {
                        $scope.updateQueued = true;
                    }

                }, 1);
            };

            $scope.readyInputTags = function() {
              $timeout(function(){
                resizingTagWrapper();
              },300);
            };

            $scope.processDynamicSearch = function(key) {
                if ($scope.activeTag['type'] == 'smart_filter_dynamic') {
                    if ($scope.mode == 'filter-value-select') {
                        if ($scope.activeTag['value'].length >= 2) {
                            // check to see if user didn't just press an arrow key
                            if (key !== 40 && key !== 38 && key !== 39 && key !== 37) {
                                if ($scope.activeTag['url']) {
                                    var urlToUse = reportFilterSettings.parseDynamicURL($scope.inputTags, $scope.activeTag['url'], $scope.activeTag['value']);

                                    $http.post(urlToUse).then(function(resp){
                                        var resultsList = [];

                                        angular.forEach(resp.data, function(result, index) {
                                            resultsList.push({'label': resp.data[index].text, 'value': resp.data[index].id, 'value_label': resp.data[index].text});
                                        });

                                        $(element).find('.filter-search-field').autocomplete('option','source',resultsList);
                                        $(element).find('.filter-search-field').autocomplete('enable');
                                        $(element).find('.filter-search-field').autocomplete('search','');
                                    });
                                }
                            }
                        }
                        else if ($scope.activeTag['value'].length == 1) {
                            $(element).find('.filter-search-field').attr('placeholder', 'Please enter at least 1 more character to begin searching');
                            $(element).find('.filter-search-field').autocomplete('disable');
                        }
                        else {
                            $(element).find('.filter-search-field').attr('placeholder', 'Please enter at least 2 more characters to begin searching');
                            $(element).find('.filter-search-field').autocomplete('disable');
                        }
                        return true;
                    }
                }
                return false;
            };

            element.bind("click", function(e) {
                if ($(e.target).hasClass('ui-autocomplete-input')) {
                    // Display all options when the user clicks on the input field (i.e. do an empty search)
                    $(element).find('.filter-search-field').autocomplete('search','');
                }
            });

            element.bind("keydown", function(e) {
                $scope.searchFieldValueBeforeKeyup = $(element).find('.filter-search-field').val();
                //console.log('$scope.searchFieldValueBeforeKeyup', $scope.searchFieldValueBeforeKeyup)
            });

            element.bind("keyup", function(e) {
                var key;
                key = e.which;
                if(key == 34 || key == 33 || key == 9) { //console.log('dddddxxxxxxxxxxxxxxxxxx');
                  e.preventDefault();
                  return;
                }

                if ($scope.activeTag) {
                    if (($scope.activeTag['type'] == "text" || $scope.activeTag['type'] == 'from' || $scope.activeTag['type'] == 'to') && $scope.mode == 'filter-value-select') {
                        $(element).find('.filter-search-field').autocomplete('disable');
                    }

                    // Un-comment if you want the filter value text to update automatically every time the user enters a new character
                    //  $scope.activeTag['value'] = $(element).find('.filter-search-field').val();
                    //  $scope.activeTag['value_label'] = $scope.activeTag['value'];

                    // Backspace pressed and the user has not entered anything for the value of the filter. In this case, remove the unfinished filter.
                    if ($scope.mode == 'filter-value-select' && key == 8 && $scope.searchFieldValueBeforeKeyup.length == 0) {

                        // $timeout(function(){
                        //     $('body > .ui-autocomplete').css('display', 'block');
                        //     $(element).find('.filter-search-field').autocomplete('enable');
                        //     $(element).find('.filter-search-field').blur().focus();
                        // },300);

                        $scope.cancelInput(e);
                        return;
                    }

                    // Tab or Enter pressed before value selection and type is "text"
                    if ($scope.mode == 'filter-value-select' &&
                        ($scope.activeTag['type'] == "text" || $scope.activeTag['type'] == 'from' || $scope.activeTag['type'] == 'to') &&
                        (key === 9 || key === 13 || key === 188)) {

                        e.preventDefault();

                        $scope.activeTag['value'] = $(element).find('.filter-search-field').val();
                        $scope.activeTag['value_label'] = $scope.activeTag['value'];
                        if(_.isEmpty($scope.activeTag['value'])) {
                          return null;
                        }
                        return $scope.$apply('addTag()');
                    }
                    // Tab or Enter pressed after value selection and type is "text"
                    else if ($scope.mode == 'filter-key-select' &&
                        ($scope.activeTag['type'] == "text" || $scope.activeTag['type'] == 'from' || $scope.activeTag['type'] == 'to') &&
                        (key === 9 || key === 13 || key === 188)) {
                        if ($scope.updateFilterTimer) {
                            $timeout.cancel($scope.updateFilterTimer);
                        }

                        if( typeof($scope.activeTag['value_label']) == 'string' && typeof($scope.activeTag['value']) == 'string' ) {
                          $scope.keyboardSelection = false;
                          //$scope.activeTag['value_label']=undefined;
                          return false;
                        }
                        $scope.mode = 'filter-value-select';
                        $scope.keyboardSelection = false;

                        return;
                    }
                    // Enter key pressed before value selection and type is NOT "text"
                    else if ($scope.mode == 'filter-key-select' && key == 13) {
                        //$scope.activeTag['value'] = $(element).find('.filter-search-field').val();
                        //$scope.activeTag['value_label'] = $scope.activeTag['value'];

                    }
                    // Dynamic search filter
                    else if ($scope.mode == 'filter-value-select' && $scope.activeTag['type'] == 'smart_filter_dynamic') {
                        $scope.activeTag['value'] = $(element).find('.filter-search-field').val();
                        $scope.activeTag['value_label'] = $scope.activeTag['value'];

                        $scope.processDynamicSearch(key);
                    }
                    else {
                        // Continue normal search
                        $scope.activeTag['value'] = $(element).find('.filter-search-field').val();
                        $scope.activeTag['value_label'] = $scope.activeTag['value'];

                        $scope.processNormalKeyup();
                        return;
                    }
                }
                else {
                    // Continue normal search
                  if($scope.mode === 'filter-key-select' && key===40  && $scope.disabled) {
                    $scope.enableInput();
                  }
                  $scope.processNormalKeyup();
                }

            });

            return true;
        },
        templateUrl: 'template/filter_tags.html'
    };
}]);
