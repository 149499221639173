commusoftCommon.directive('csautosave', ['prefix', 'canLoad', '$q', '$http', '$timeout', '$state','$location', function (prefix, canLoad, $q, $http, $timeout, $state, $location) {
    return {
        replace: true,
        restrict: 'E',
        link: function (scope, element, attrs) {
            var canceler = null;
            var postUrl = $location.path();

            var options = scope.$eval(attrs.saveOptions);
            var tokenName = options.tokenName;
            var subjectName = options.subjectName;
            var emailMessage = options.emailMessage;
            var smsMessage = options.smsMessage;
            var letterMessage = options.letterMessage;
            if (scope.subject == undefined) {
                scope.subject = "";
            }


            if (attrs.saveOptions == 'emailOptions') {
                scope.params = "[message]";
            } else if (attrs.saveOptions == 'emailSmsOptions') {
                scope.params = "[Email, Sms]";
            } else if (attrs.saveOptions == 'emailSmsLetterOptions') {
                scope.params = "[Email, Sms, Letter]";
            } else if(attrs.saveOptions == 'estimateOptions') {
                if (emailMessage == "estimates[estimateIntroduction]") {
                    scope.params = "[estimateIntroduction]";
                } else if(emailMessage == "estimates[estimateBody]") {
                    scope.params = "[estimateBody]";
                }
            }

            scope.onTimeOut = function () {
                /* Checking the url to stop the onTime function */
                if($state.current.url.replace('^', '') == postUrl){
                    canLoad.setLoadValue(false);
                    if (attrs.saveOptions == 'emailOptions') {
                        var autoSaveValues = tokenName+"="+ scope._token +"&"+ subjectName + "=" + encodeURIComponent(scope.subject) + "&" + emailMessage + "=" + encodeURIComponent(tinyMCE.activeEditor.getContent());
                    } else if (attrs.saveOptions == 'smsOptions') {
                        var autoSaveValues = tokenName+"="+ scope._token +"&"+ smsMessage + "=" + encodeURIComponent(tinyMCE.activeEditor.getContent());
                    } else if (attrs.saveOptions == 'emailSmsOptions') {
                        var subject = encodeURIComponent(tinyMCE.get('EmailId').getContent());
                        var email = encodeURIComponent(tinyMCE.get('EmailMessageId').getContent());
                        var sms = encodeURIComponent(tinyMCE.get('SmsId').getContent());
                        var autoSaveValues = tokenName+"="+ scope._token +"&"+ subjectName + "=" + subject + "&" + emailMessage + "=" + email + "&" + smsMessage + "=" + sms;
                    } else if (attrs.saveOptions == 'emailSmsLetterOptions') {
                        var subject = encodeURIComponent(tinyMCE.get('EmailId').getContent());
                        var email = encodeURIComponent(tinyMCE.get('EmailMessageId').getContent());
                        var sms = encodeURIComponent(tinyMCE.get('SmsId').getContent());
                        var letter = encodeURIComponent(tinyMCE.get('LetterId').getContent());
                        var autoSaveValues = tokenName+"="+ scope._token +"&"+ subjectName + "=" + subject + "&" + emailMessage + "=" + email + "&" + smsMessage + "=" + sms + "&" + letterMessage + "=" + letter;
                    } else if (attrs.saveOptions == 'estimateOptions') {
                        var autoSaveValues = "type" + "=" + $state.params.type + "&" + "id" + "=" + $state.params.id + "&" + "estimateId" + "=" + $state.params.estimateId + "&" + emailMessage + "=" + encodeURIComponent(tinyMCE.activeEditor.getContent());
                        if($state.params.optionId){
                            var autoSaveValues =  autoSaveValues + "&" + "optionId" + "=" + $state.params.optionId;
                        }
                    }
                    if(scope.hasOwnProperty('settingsjobreportemailmessage')) {
                        autoSaveValues += '&settingsjobreportemailmessage[automaticemailjobreport]='  + scope.settingsjobreportemailmessage +'&automaticemailjobreportnoaccess=' +scope.automaticemailjobreportnoaccess+'&automaticemailjobreportabort=' +scope.automaticemailjobreportabort+'&automaticemailjobreportreject=' +scope.automaticemailjobreportreject;
                    }
                    if(scope.hasOwnProperty('sendrepeatedreminders')) {
                        autoSaveValues += '&jobreminder[sendrepeatedreminders]=' + Number(scope.sendrepeatedreminders);
                    }
                    $http.post(prefix + postUrl, autoSaveValues +"&"+ "autoSave" + "=" + 'autosave')
                    $timeout(scope.onTimeOut, 10000);
                }
            }
            $timeout(scope.onTimeOut, 10000);
        }
    };
}]);