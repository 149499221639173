commusoftCommon.directive('navigablefiles', ['$rootScope', 'prefix', '$http', function($rootScope, prefix, $http) {
    return {
        restrict: 'A',
        scope: {
            customTableSource: '=customTableSource'
        },
        link: function($scope, elem, attrs) {
            $scope.navigableCurrentIndex = 0;
            $rootScope.$broadcast('smarttable:unselectAllRows');

            if (parseInt(attrs.navigablefiles) !== "NaN") {
                $scope.filesCount = attrs.navigablefiles;
            }
            else {
                return false;
            }

            $scope.$on('smarttable:rowclicked', function(evt, obj) {
                $scope.$emit("file:preview", { 'index': obj.index});
                $scope.navigableCurrentIndex = angular.copy(obj.index);
            });

            document.addEventListener('keydown', function(event) {
                if (event.keyCode == 40 || event.keyCode == 39) {
                    /* down or right */
                    if (($scope.navigableCurrentIndex+1) <= $scope.filesCount) {
                        $rootScope.$broadcast('smarttable:unselectAllRows');
                        $scope.navigableCurrentIndex = $scope.navigableCurrentIndex+1;
                        $rootScope.$broadcast('smarttable:simpleSelectRow', $scope.navigableCurrentIndex, true);

                        if ($scope.customTableSource) {
                            var file = $scope.customTableSource[$scope.navigableCurrentIndex];
                            $rootScope.$broadcast('smarttable:filepreview', {'file': file});
                        } else {
                            $scope.$emit("file:preview", { 'index': $scope.navigableCurrentIndex});
                        }
                    }
                }
                if (event.keyCode == 38 || event.keyCode == 37) {
                    /* up or left */
                    if (($scope.navigableCurrentIndex-1) > -1) {
                        $rootScope.$broadcast('smarttable:unselectAllRows');
                        $scope.navigableCurrentIndex = $scope.navigableCurrentIndex-1;
                        $rootScope.$broadcast('smarttable:simpleSelectRow', $scope.navigableCurrentIndex, true);

                        if ($scope.customTableSource) {
                            var file = $scope.customTableSource[$scope.navigableCurrentIndex];
                            $rootScope.$broadcast('smarttable:filepreview', {'file': file});
                        } else {
                            $scope.$emit("file:preview", { 'index': $scope.navigableCurrentIndex});
                        }
                    }
                }
            });
        }
    }
}]);
