commusoftCommon.service('jobDiaryEvents', function($rootScope, $timeout, $q) {

    diary_event_pending = {
        id: 1000,
        timeline_id: 3,
        status: "Pending",
        labour_and_parts_invoiced: false,
        complete: false,
        already_invoiced: false,
        js_date: new Date('2015-01-15'),
        date: "Tuesday 15th January 2015",
        truncated_date: "Tues 15th Jan 2015",
        time: "10:15am to 12:15pm",
        special_event: false,
        start_time: '10:30',
        end_time: '12:30',
        duration: 2,
        travel_duration: 0,
        on_site_duration: 0,
        description: "Prepare the property for the new central heating installation",
        customer_name: 'Jason Morjaria',
        customer_contact_telephone: '01234567890',
        work_address_telephone: '01348271749',
        customer_contact_email: 'jason@demo.local',
        property_name: 'CommuSoft',
        property_contact_telephone: '01234567890',
        property_contact_email: 'info@commusoft.local',
        property_address_line1: '123',
        property_address_line2: 'Main Street',
        property_address_line3: 'Old Street',
        property_town: 'London',
        property_county: 'Greater London',
        property_postcode: 'E4 3AB',
        engineer_name: "Jason Morjaria",
        engineer_email: "jason@commusoft.co.uk",
        engineer_profile: 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=30',
        large_engineer_profile: 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=70',
        engineer_notes: "The property is detatched and gated, if you have trouble with access then please call the tennant",
        diary_event_type: 'Job',
        engineer_feedback: false,
        completed_todos: 0,
        available_todos: 4,
        parts_installed: 0,
        parts_decommissioned: 0,
        access: {
            method: 'Landlord has keys',
            notes: 'The key is silver with a square handle'
        },
        engineer_job_sheet: [
            {
                name: 'Engineer Job Sheet',
                sent: true,
                method: 'Email',
                date: '15th January 2015 at 10:15am',
                audits: [
                    {
                        method: 'Email',
                        date: '16th January 2015 at 13:00pm',
                        recipient: 'callum@commusoft.co.uk',
                        subject: 'Job sheet',
                        message: 'Please find the attached job sheet for your upcoming visit'
                    }
                ]
            }
        ],
        confirmations: [
            {
                name: 'Customer Confirmation',
                sent: false,
                method: 'SMS',
                date: '14th January 2015 at 13:40pm',
                type: 'confirmation',
                audits: []
            },
            {
                name: 'Work Address Confirmation',
                sent: false,
                method: 'Letter',
                date: '14th January 2015 at 13:40pm',
                type: 'confirmation',
                audits: []
            },
            {
                name: 'Work Address Reminder',
                sent: false,
                method: 'Letter',
                date: '14th January 2015 at 13:40pm',
                type: 'reminder',
                audits: []
            }
        ],
        parts: [],
        keys: [
            {
                key: 'Landlord Keys',
                status: 'Not collected'
            }
        ],
        todos: [
            {
                id: 1,
                status: "Pending",
                type: "general",
                description: 'Assess current central heating system',
                milestone_index: 0
            },
            {
                id: 2,
                status: "Pending",
                type: "general",
                description: 'Diagnose any current faults with the existing system',
                milestone_index: 0
            },
            {
                id: 3,
                status: "Pending",
                type: "general",
                description: 'Assess how beneficial a new system will be',
                milestone_index: 0
            },
            {
                id: 4,
                status: "Pending",
                type: "photo",
                description: 'Take a photograph of the parking location',
                milestone_index: 0
            },
            {
                id: 5,
                status: "Done",
                type: "photo",
                description: 'Take a photograph of the existing boiler',
                milestone_index: 0,
                complete: {
                    time: '10:00',
                    photo: '../images/leaking-pipe.jpg',
                    notes: 'The pipework is ruptured at the bottom. This is likely due to the pressure'
                },
                deleted: true,
                deleted_by: 'Joseph Luck',
                deleted_on: '13th July 2015 at 13:12PM'
            }
        ],
        feedback: {
            arrive_on_site: {
                name: 'Arrive on site',
                questions: [
                    {
                        name: 'Is there parking on site?',
                        time: '11:29am',
                        type: 'select',
                        answered: false
                    },
                    {
                        name: 'Is the property easy to access?',
                        time: '11:33am',
                        type: 'input',
                        answered: false
                    },
                    {
                        name: 'How is the boiler accessed?',
                        time: '11:57am',
                        type: 'textarea',
                        answered: false
                    }
                ],
                signatures: []
            },
            leave_site: {
                name: 'Leave site',
                questions: [
                    {
                        name: 'Did you leave the property tidy?',
                        time: '13:42pm',
                        type: 'select',
                        answered: false
                    }
                ],
                signatures: []
            }
        },
        geolocation: [
        ]
    };

    diary_event_accept = {
        id: 2000,
        timeline_id: 3,
        status: "Accepted",
        complete: false,
        already_invoiced: false,
        labour_and_parts_invoiced: false,
        js_date: new Date('2015-01-17'),
        date: "Thursday 17th January 2015",
        truncated_date: "Thu 17th Jan 2015",
        time: "AM (9:00am to 12:00pm)",
        special_event: false,
        start_time: '09:00',
        end_time: '12:00',
        duration: 3,
        travel_duration: 0,
        on_site_duration: 0,
        description: "Remove / strip out the existing system and pipework",
        customer_name: 'Jason Morjaria',
        customer_contact_telephone: '01234567890',
        work_address_telephone: '01348271749',
        customer_contact_email: 'jason@demo.local',
        property_name: 'CommuSoft',
        property_contact_telephone: '01234567890',
        property_contact_email: 'info@commusoft.local',
        property_address_line1: '123',
        property_address_line2: 'Main Street',
        property_address_line3: 'Old Street',
        property_town: 'London',
        property_county: 'Greater London',
        property_postcode: 'E4 3AB',
        engineer_name: "Callum Hart",
        engineer_email: "callum@commusoft.co.uk",
        engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
        large_engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=70',
        engineer_notes: "Ensure that you dispose of the metal work securely and safetly.",
        engineer_feedback: false,
        diary_event_type: 'Job',
        completed_todos: 0,
        available_todos: 4,
        parts_installed: 0,
        parts_decommissioned: 0,
        access: {
            method: 'Landlord has keys',
            notes: 'The key is silver with a square handle'
        },
        engineer_job_sheet: [
            {
                name: 'Engineer Job Sheet',
                sent: true,
                method: 'Email',
                date: '16th January 2015 at 10:20am',
                audits: [
                    {
                        method: 'Email',
                        date: '16th January 2015 at 13:00pm',
                        recipient: 'callum@commusoft.co.uk',
                        subject: 'Job sheet',
                        message: 'Please find the attached job sheet for your upcoming visit'
                    }
                ]
            }
        ],
        confirmations: [
            {
                name: 'Customer Confirmation',
                sent: false,
                method: 'SMS',
                date: '16th January 2014 at 13:00pm',
                type: 'confirmation',
                audits: []
            },
            {
                name: 'Work Address Confirmation',
                sent: false,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: []
            },
            {
                name: 'Work Address Reminder',
                sent: false,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'reminder',
                audits: []
            }
        ],
        parts: [],
        keys: [
            {
                key: 'Landlord Keys',
                status: 'Not collected'
            }
        ],
        todos: [
            {
                id: 5,
                status: "Pending",
                type: "decommission",
                description: 'Decommission the existing radiators',
                appliance: 'Radiator',
                estimated_hours: '4 hours',
                milestone_index: 0
            },
            {
                id: 6,
                status: "Pending",
                type: "decommission",
                description: 'Remove all remaining pipework',
                appliance: 'Pipes',
                estimated_hours: '2 hours',
                milestone_index: 0
            }
        ],
        feedback: {
            arrive_on_site: {
                name: 'Arrive on site',
                questions: [
                    {
                        name: 'Is there parking on site?',
                        time: '11:29am',
                        type: 'select',
                        answered: false
                    },
                    {
                        name: 'Is the property easy to access?',
                        time: '11:33am',
                        type: 'input',
                        answered: false
                    },
                    {
                        name: 'How is the boiler accessed?',
                        time: '11:57am',
                        type: 'textarea',
                        answered: false
                    }
                ],
                signatures: []
            },
            leave_site: {
                name: 'Leave site',
                questions: [
                    {
                        name: 'Did you leave the property tidy?',
                        time: '13:42pm',
                        type: 'select',
                        answered: false
                    }
                ],
                signatures: []
            }
        },
        geolocation: [
            {
                status: 'Accepted',
                time: '10:32am',
                real_time: '10:32'
            }
        ]
    };

    diary_event_travel = {
        id: 3000,
        timeline_id: 3,
        status: "Travel",
        complete: false,
        already_invoiced: false,
        labour_and_parts_invoiced: false,
        js_date: new Date('2015-01-24'),
        date: "Tuesday 24th January 2015",
        truncated_date: "Tues 24th Jan 2015",
        time: "between 11:00am and 12:30pm",
        special_event: true,
        start_time: '11:00',
        end_time: '12:30',
        duration: 1.5,
        travel_duration: 0.5,
        on_site_duration: 0,
        description: "Prepare property for new central heating system",
        customer_name: 'Jason Morjaria',
        customer_contact_telephone: '01234567890',
        work_address_telephone: '01348271749',
        customer_contact_email: 'jason@demo.local',
        property_name: 'CommuSoft',
        property_contact_telephone: '01234567890',
        property_contact_email: 'info@commusoft.local',
        property_address_line1: '123',
        property_address_line2: 'Main Street',
        property_address_line3: 'Old Street',
        property_town: 'London',
        property_county: 'Greater London',
        property_postcode: 'E4 3AB',
        engineer_name: "Jason Morjaria",
        engineer_email: "jason@commusoft.co.uk",
        engineer_profile: 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=30',
        large_engineer_profile: 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=70',
        engineer_notes: "The property needs to be safe and secure when you leave.",
        engineer_feedback: false,
        diary_event_type: 'Job',
        completed_todos: 0,
        available_todos: 4,
        parts_installed: 0,
        parts_decommissioned: 0,
        access: {
            method: 'Landlord has keys',
            notes: 'The key is silver with a square handle'
        },
        engineer_job_sheet: [
            {
                name: 'Engineer Job Sheet',
                sent: true,
                method: 'Email',
                date: '16th January 2015 at 13:00pm',
                audits: [
                    {
                        method: 'Email',
                        date: 'Tuesday 24th January 2015 at 10:00am',
                        recipient: 'callum@commusoft.co.uk',
                        subject: 'Job sheet',
                        message: 'Please find the attached job sheet for your upcoming visit'
                    }
                ]
            }
        ],
        confirmations: [
            {
                name: 'Customer Confirmation',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            },
            {
                name: 'Work Address Confirmation',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            },
            {
                name: 'Work Address Reminder',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            }
        ],
        parts: [],
        keys: [
            {
                key: 'Landlord Keys',
                status: 'Collected',
                picked_up: {
                    date: 'Tuesday 24th January 2015 at 10:00am',
                    property_name: 'Foxtons',
                    property_address_line1: '193',
                    property_address_line2: 'Old Road',
                    property_address_line3: 'Old Street',
                    property_town: 'London',
                    property_county: 'Greater London',
                    property_postcode: 'E5 9AR'
                }
            }
        ],
        todos: [
            {
                id: 7,
                status: "Pending",
                type: "general",
                description: 'Clean the property in preperation for new installation',
                milestone_index: 0
            }
        ],
        feedback: {
            arrive_on_site: {
                name: 'Arrive on site',
                questions: [
                    {
                        name: 'Is there parking on site?',
                        time: '11:29am',
                        type: 'select',
                        answered: false
                    },
                    {
                        name: 'Is the property easy to access?',
                        time: '11:33am',
                        type: 'input',
                        answered: false
                    },
                    {
                        name: 'How is the boiler accessed?',
                        time: '11:57am',
                        type: 'textarea',
                        answered: false
                    }
                ],
                signatures: []
            },
            leave_site: {
                name: 'Leave site',
                questions: [
                    {
                        name: 'Did you leave the property tidy?',
                        time: '13:42pm',
                        type: 'select',
                        answered: false
                    }
                ],
                signatures: []
            }
        },
        geolocation: [
            {
                status: 'Accepted',
                time: '10:32am',
                real_time: '10:32'
            },
            {
                status: 'Travel',
                time: '11:05am',
                real_time: '11:05'
            }
        ]
    };

    diary_event_arrive = {
        id: 4000,
        timeline_id: 3,
        status: "Arrived",
        complete: false,
        already_invoiced: false,
        labour_and_parts_invoiced: false,
        js_date: new Date('2015-01-16'),
        date: "Wednesday 16th January 2015",
        truncated_date: "Wed 25th Jan 2015",
        time: "All day (9:00am to 18:00pm)",
        special_event: false,
        start_time: '09:00',
        end_time: '18:00',
        duration: 9,
        travel_duration: 1.4,
        on_site_duration: 0,
        description: "Install the pipework for the new central heating system",
        customer_name: 'Jason Morjaria',
        customer_contact_telephone: '01234567890',
        work_address_telephone: '01348271749',
        customer_contact_email: 'jason@demo.local',
        property_name: 'CommuSoft',
        property_contact_telephone: '01234567890',
        property_contact_email: 'info@commusoft.local',
        property_address_line1: '123',
        property_address_line2: 'Main Street',
        property_address_line3: 'Old Street',
        property_town: 'London',
        property_county: 'Greater London',
        property_postcode: 'E4 3AB',
        engineer_name: "Joseph Luck",
        engineer_email: "joseph@commusoft.co.uk",
        engineer_profile: 'https://s.gravatar.com/avatar/453a7a095109ec93f419a54ad19956f6?s=30',
        large_engineer_profile: 'https://s.gravatar.com/avatar/453a7a095109ec93f419a54ad19956f6?s=70',
        engineer_notes: "Make sure that you take a photograph of the pipework when you are done for our records.",
        engineer_feedback: true,
        diary_event_type: 'Job',
        completed_todos: 0,
        available_todos: 4,
        parts_installed: 0,
        parts_decommissioned: 0,
        access: {
            method: 'Landlord has keys',
            notes: 'The key is silver with a square handle'
        },
        engineer_job_sheet: [
            {
                name: 'Engineer Job Sheet',
                sent: true,
                method: 'Email',
                date: 'Monday 10th January 2015 at 13:00pm',
                audits: [
                    {
                        method: 'Email',
                        date: 'Monday 10th January 2015 at 13:00pm',
                        recipient: 'callum@commusoft.co.uk',
                        subject: 'Job sheet',
                        message: 'Please find the attached job sheet for your upcoming visit'
                    }
                ]
            }
        ],
        confirmations: [
            {
                name: 'Customer Confirmation',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            },
            {
                name: 'Work Address Confirmation',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            },
            {
                name: 'Work Address Reminder',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            }
        ],
        parts: [
            {
                id: 1,
                part: 'CAP',
                status: 'Picked up',
                quantity: 3,
                price: 150,
                already_invoiced: false,
                engineer_instructions: 'Collect from supplier',
                picked_up: {
                    date: 'Tuesday 11th January 2015 at 8:52am'
                }
            },
            {
                id: 2,
                part: 'CLIP 8',
                status: 'Picked up',
                quantity: 1,
                price: 10,
                already_invoiced: false,
                engineer_instructions: 'Collect from supplier',
                picked_up: {
                    date: 'Tuesday 11th January 2015 at 8:52am'
                }
            },
            {
                id: 3,
                part: 'DRIVE SPROCKET 35MM HA',
                status: 'Picked up',
                quantity: 4,
                price: 120,
                already_invoiced: false,
                engineer_instructions: 'Collect from supplier',
                picked_up: {
                    date: 'Tuesday 11th January 2015 at 8:52am'
                }
            },
            {
                id: 4,
                part: 'ZTG-STAB 11X1620',
                status: 'Picked up',
                quantity: 10,
                price: 4,
                already_invoiced: false,
                engineer_instructions: 'Collect from supplier',
                picked_up: {
                    date: 'Tuesday 11th January 2015 at 8:52am'
                }
            }
        ],
        keys: [
            {
                key: 'Landlord Keys',
                status: 'Collected',
                picked_up: {
                    date: 'Tuesday 11th January 2015 at 18:31pm',
                    property_name: 'Foxtons',
                    property_address_line1: '193',
                    property_address_line2: 'Old Road',
                    property_address_line3: 'Old Street',
                    property_town: 'London',
                    property_county: 'Greater London',
                    property_postcode: 'E5 9AR'
                }
            }
        ],
        todos: [
            {
                id: 8,
                status: "Pending",
                type: "install",
                description: 'Install the pipework for the new central heating system',
                item: 'Pipework',
                estimated_hours: '4 hours',
                quantity: '14',
                quantity_installed: '0',
                part_status: "Available",
                milestone_index: 0
            },
            {
                id: 9,
                status: "Pending",
                type: "photo",
                description: 'Take a photograph of the pipework schema for our records',
                milestone_index: 0
            },
            {
                id: 10,
                status: "Pending",
                type: "general",
                description: 'Ask the customer if they are happy with the placement of the new radiators',
                milestone_index: 0
            }
        ],
        feedback: {
            arrive_on_site: {
                name: 'Arrive on site',
                questions: [
                    {
                        name: 'Is there parking on site?',
                        time: '11:29am',
                        type: 'select',
                        answer: 'Yes',
                        answered: true
                    },
                    {
                        name: 'Is the property easy to access?',
                        time: '11:33am',
                        type: 'input',
                        answer: 'Yes, the property is a gated detached house',
                        answered: true
                    },
                    {
                        name: 'How is the boiler accessed?',
                        time: '11:57am',
                        type: 'textarea',
                        answer: 'The boiler is in the kitchen inside a cupboard',
                        answered: true
                    }
                ],
                signatures: [
                    {
                        name: 'Adam Shaw',
                        title: 'tenant',
                        time: '11:37am',
                        url: 'images/signature.png'
                    }
                ]
            },
            leave_site: {
                name: 'Leave site',
                questions: [
                    {
                        name: 'Did you leave the property tidy?',
                        time: '17:42pm',
                        type: 'select',
                        answered: false
                    }
                ],
                signatures: []
            }
        },
        geolocation: [
            {
                status: 'Accepted',
                time: '10:32am',
                real_time: '10:32'
            },
            {
                status: 'Travel',
                time: '11:05am',
                real_time: '11:05'
            },
            {
                status: 'Arrived',
                time: '11:28am',
                real_time: '11:28'
            }
        ]
    };

    diary_event_no_access = {
        id: 5000,
        timeline_id: 3,
        status: "No Access",
        complete: true,
        already_invoiced: false,
        labour_and_parts_invoiced: false,
        js_date: new Date('2015-01-05'),
        date: "Thursday 5th January 2015",
        truncated_date: "Thu 5th Jan 2015",
        time: "PM (13:00pm to 18:00pm)",
        special_event: false,
        start_time: '13:00',
        end_time: '18:00',
        duration: 5,
        travel_duration: 0.8,
        on_site_duration: 0,
        description: "Install the new radiators",
        customer_name: 'Jason Morjaria',
        customer_contact_telephone: '01234567890',
        work_address_telephone: '01348271749',
        customer_contact_email: 'jason@demo.local',
        property_name: 'CommuSoft',
        property_contact_telephone: '01234567890',
        property_contact_email: 'info@commusoft.local',
        property_address_line1: '123',
        property_address_line2: 'Main Street',
        property_address_line3: 'Old Street',
        property_town: 'London',
        property_county: 'Greater London',
        property_postcode: 'E4 3AB',
        engineer_name: "Callum Hart",
        engineer_email: "callum@commusoft.co.uk",
        engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
        large_engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=70',
        engineer_notes: "Be careful when installing the hallway radiator. Make sure that it doesn't obstruct the front door",
        engineer_feedback: false,
        diary_event_type: 'Job',
        completed_todos: 0,
        available_todos: 4,
        parts_installed: 0,
        parts_decommissioned: 0,
        access: {
            method: 'Landlord has keys',
            notes: 'The key is silver with a square handle'
        },
        engineer_job_sheet: [
            {
                name: 'Engineer Job Sheet',
                sent: true,
                method: 'Email',
                date: 'Wednesday 10th January at 13:00pm',
                audits: [
                    {
                        method: 'Email',
                        date: '16th January 2015 at 13:00pm',
                        recipient: 'callum@commusoft.co.uk',
                        subject: 'Job sheet',
                        message: 'Please find the attached job sheet for your upcoming visit'
                    }
                ]
            }
        ],
        confirmations: [
            {
                name: 'Customer Confirmation',
                sent: true,
                method: 'Letter',
                date: 'Wednesday 10th January at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: 'Wednesday 10th January at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            },
            {
                name: 'Work Address Confirmation',
                sent: true,
                method: 'Letter',
                date: 'Wednesday 10th January at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: 'Wednesday 10th January at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            },
            {
                name: 'Work Address Reminder',
                sent: true,
                method: 'Letter',
                date: 'Wednesday 10th January at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: 'Wednesday 10th January at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            }
        ],
        parts: [
            {
                id: 5,
                part: 'Compression Wallplate Elbow 12 x 15mm',
                status: 'Picked up',
                quantity: 1,
                price: 300,
                already_invoiced: false,
                engineer_instructions: 'Collect from supplier',
                picked_up: {
                    date: 'Wednesday 10th January at 13:00pm at 9:12am'
                }
            },
            {
                id: 6,
                part: 'Wickes Solder Ring Elbow 15mm Pack 10',
                status: 'Picked up',
                quantity: 1,
                price: 300,
                already_invoiced: false,
                engineer_instructions: 'Collect from supplier',
                picked_up: {
                    date: 'Wednesday 10th January at 13:00pm at 9:12am'
                }
            }
        ],
        keys: [
            {
                key: 'Landlord Keys',
                status: 'Collected',
                picked_up: {
                    date: 'Wednesday 10th January at 13:00pm at 10:46am',
                    property_name: 'Foxtons',
                    property_address_line1: '193',
                    property_address_line2: 'Old Road',
                    property_address_line3: 'Old Street',
                    property_town: 'London',
                    property_county: 'Greater London',
                    property_postcode: 'E5 9AR'
                }
            }
        ],
        todos: [
            {
                id: 11,
                status: "Pending",
                type: "install",
                description: 'Install the radiators',
                item: 'Radiator',
                estimated_hours: '8 Hours',
                quantity: '10',
                quantity_installed: '0',
                part_status: "Available",
                milestone_index: 0
            },
            {
                id: 12,
                status: "Pending",
                type: "photo",
                description: 'Photograph each radiator and the valve location',
                milestone_index: 0
            },
            {
                id: 13,
                status: "Pending",
                type: "general",
                description: 'Make sure the property is tidy before leaving',
                milestone_index: 0
            }
        ],
        feedback: {
            arrive_on_site: {
                name: 'Arrive on site',
                questions: [
                    {
                        name: 'Is there parking on site?',
                        time: '13:29pm',
                        type: 'select',
                        answered: false
                    },
                    {
                        name: 'Is the property easy to access?',
                        time: '13:33pm',
                        type: 'input',
                        answered: false
                    },
                    {
                        name: 'How is the boiler accessed?',
                        time: '13:57pm',
                        type: 'textarea',
                        answered: false
                    }
                ],
                signatures: []
            },
            leave_site: {
                name: 'Leave site',
                questions: [
                    {
                        name: 'Did you leave the property tidy?',
                        time: '13:42pm',
                        type: 'select',
                        answered: false
                    }
                ],
                signatures: []
            }
        },
        geolocation: [
            {
                status: 'Accepted',
                time: '10:32am',
                real_time: '10:32'
            },
            {
                status: 'Travel',
                time: '11:05am',
                real_time: '13:05'
            },
            {
                status: 'No access',
                time: '12:15am',
                real_time: '14:15'
            }
        ],
        no_access_photo: {
            url: 'images/front-door.jpg'
        }
    };

    diary_event_leave = {
        id: 6000,
        timeline_id: 3,
        status: "Left",
        complete: true,
        already_invoiced: false,
        labour_and_parts_invoiced: true,
        js_date: new Date('2015-01-27'),
        date: "Friday 27th January 2015",
        truncated_date: "Fri 27th Jan 2015",
        time: "14:30am to 18:00pm",
        special_event: false,
        start_time: '14:30',
        end_time: '18:00',
        duration: 3.5,
        travel_duration: 0.5,
        on_site_duration: 2,
        description: "Perform all the prep work for installing the new boiler",
        customer_name: 'Jason Morjaria',
        customer_contact_telephone: '01234567890',
        work_address_telephone: '01348271749',
        customer_contact_email: 'jason@demo.local',
        property_name: 'CommuSoft',
        property_contact_telephone: '01234567890',
        property_contact_email: 'info@commusoft.local',
        property_address_line1: '123',
        property_address_line2: 'Main Street',
        property_address_line3: 'Old Street',
        property_town: 'London',
        property_county: 'Greater London',
        property_postcode: 'E4 3AB',
        engineer_name: "Joseph Luck",
        engineer_email: "joseph@commusoft.co.uk",
        engineer_profile: 'https://s.gravatar.com/avatar/453a7a095109ec93f419a54ad19956f6?s=30',
        large_engineer_profile: 'https://s.gravatar.com/avatar/453a7a095109ec93f419a54ad19956f6?s=70',
        engineer_notes: "Please note that the boiler is difficult to access, if you need help, call John",
        engineer_feedback: true,
        diary_event_type: 'Job',
        completed_todos: 3,
        available_todos: 4,
        parts_installed: 0,
        parts_decommissioned: 1,
        access: {
            method: 'Landlord has keys',
            notes: 'The key is silver with a square handle'
        },
        engineer_job_sheet: [
            {
                name: 'Engineer Job Sheet',
                sent: true,
                method: 'Email',
                date: '13th September 2014 at 13:00pm',
                audits: [
                    {
                        method: 'Email',
                        date: '13th September 2014 at 13:00pm',
                        recipient: 'callum@commusoft.co.uk',
                        subject: 'Job sheet',
                        message: 'Please find the attached job sheet for your upcoming visit'
                    }
                ]
            }
        ],
        confirmations: [
            {
                name: 'Customer Confirmation',
                sent: true,
                method: 'SMS',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'SMS',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            },
            {
                name: 'Work Address Confirmation',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            },
            {
                name: 'Work Address Reminder',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'reminder',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            }
        ],
        parts: [
            {
                id: 7,
                part: 'Ariston E-Combi Evo 30HE Condensing Combi Gas Boiler',
                status: 'Installed',
                quantity: 1,
                price: 300,
                already_invoiced: false,
                engineer_instructions: 'Collect from supplier',
                picked_up: {
                    date: 'Friday 9th January 2015 at 12:23pm'
                },
                installed: {
                    date: 'Friday 9th January 2015 at 16:10pm'
                }
            },
            {
                id: 8,
                part: '"6" 316 / 316 Flexible Flue Liner',
                status: 'Installed',
                quantity: 3,
                price: 300,
                already_invoiced: false,
                engineer_instructions: 'Collect from supplier',
                picked_up: {
                    date: 'Friday 9th January 2015 at 12:23pm'
                },
                installed: {
                    date: 'Friday 9th January 2015 at 18:52pm'
                }
            }
        ],
        keys: [
            {
                key: 'Landlord Keys',
                status: 'Dropped off',
                picked_up: {
                    date: 'Tuesday 24th January 2015 at 13:00pm',
                    property_name: 'Foxtons',
                    property_address_line1: '193',
                    property_address_line2: 'Old Road',
                    property_address_line3: 'Old Street',
                    property_town: 'London',
                    property_county: 'Greater London',
                    property_postcode: 'E5 9AR'
                },
                dropped_off: {
                    date: '14th November 2014 at 12:23pm',
                    property_name: 'Foxtons',
                    property_address_line1: '193',
                    property_address_line2: 'Old Road',
                    property_address_line3: 'Old Street',
                    property_town: 'London',
                    property_county: 'Greater London',
                    property_postcode: 'E5 9AR'
                }
            }
        ],
        todos: [
            {
                id: 14,
                status: "Done",
                type: "decommission",
                description: 'Old boiler is defective',
                appliance: 'Vaillant EcoTech',
                estimated_hours: '3.2 Hours',
                complete: {
                    time: '10:00',
                    notes: 'The boiler was difficult to remove'
                },
                milestone_index: 0
            },
            {
                id: 12,
                status: "Done",
                type: "general",
                description: 'Tidy up property',
                complete: {
                    time: '10:00',
                    notes: 'I tidied up the property, there was a lot of junk near the pipework in the kitchen'
                },
                milestone_index: 0
            }
        ],
        feedback: {
            arrive_on_site: {
                name: 'Arrive on site',
                questions: [
                    {
                        name: 'Is there parking on site?',
                        time: '11:29am',
                        type: 'select',
                        answer: 'Yes',
                        answered: true
                    },
                    {
                        name: 'Is the property easy to access?',
                        time: '11:33am',
                        type: 'input',
                        answer: 'Yes, the property is a gated detached house',
                        answered: true
                    },
                    {
                        name: 'How is the boiler accessed?',
                        time: '11:57am',
                        type: 'textarea',
                        answer: 'The boiler is in the kitchen inside a cupboard',
                        answered: true
                    }
                ],
                signatures: [
                    {
                        name: 'Adam Shaw',
                        title: 'tenant',
                        time: '11:37am',
                        url: 'images/signature.png'
                    }
                ]
            },
            leave_site: {
                name: 'Leave site',
                questions: [
                    {
                        name: 'Did you leave the property tidy?',
                        time: '13:42pm',
                        type: 'select',
                        answer: 'Yes',
                        answered: true
                    },
                    {
                        name: 'Did you get feedback from the customer?',
                        time: '13:42pm',
                        type: 'select',
                        answer: 'Yes',
                        answered: true
                    },
                    {
                        name: 'Was the customer interested in any of our other services?',
                        time: '13:42pm',
                        type: 'select',
                        answer: 'No',
                        answered: true
                    },
                    {
                        name: 'Did you complete all the work?',
                        time: '13:42pm',
                        type: 'select',
                        answer: 'Yes',
                        answered: true
                    }
                ],
                signatures: [
                    {
                        name: 'Adam Shaw',
                        title: 'tenant',
                        time: '13:44pm',
                        url: 'images/signature.png'
                    }
                ]
            }
        },
        geolocation: [
            {
                status: 'Accepted',
                time: '10:32am',
                real_time: '10:32'
            },
            {
                status: 'Travel',
                time: '11:05am',
                real_time: '11:05'
            },
            {
                status: 'Arrived',
                time: '11:28am',
                real_time: '11:28'
            },
            {
                status: 'Left',
                time: '13:45pm',
                real_time: '13:45'
            }
        ]
    };

    diary_event_abort = {
        id: 8000,
        timeline_id: 3,
        status: "Aborted",
        complete: true,
        already_invoiced: false,
        labour_and_parts_invoiced: false,
        js_date: new Date('2015-01-30'),
        date: "Monday 30th January 2015",
        truncated_date: "Mon 30th Jan 2015",
        time: "11:00am to 12:30pm",
        special_event: false,
        start_time: '11:00',
        end_time: '12:30',
        duration: 1.5,
        travel_duration: 0.4,
        on_site_duration: 0.3,
        description: "Please install the radiator valves and clean the property",
        customer_name: 'Jason Morjaria',
        customer_contact_telephone: '01234567890',
        work_address_telephone: '01348271749',
        customer_contact_email: 'jason@demo.local',
        property_name: 'CommuSoft',
        property_contact_telephone: '01234567890',
        property_contact_email: 'info@commusoft.local',
        property_address_line1: '123',
        property_address_line2: 'Main Street',
        property_address_line3: 'Old Street',
        property_town: 'London',
        property_county: 'Greater London',
        property_postcode: 'E4 3AB',
        engineer_name: "Callum Hart",
        engineer_email: "callum@commusoft.co.uk",
        engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
        large_engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=70',
        engineer_notes: "Beware that parking is limited, please take a photo of where you park.",
        engineer_feedback: false,
        diary_event_type: 'Job',
        completed_todos: 0,
        available_todos: 4,
        parts_installed: 0,
        parts_decommissioned: 0,
        access: {
            method: 'Landlord has keys',
            notes: 'The key is silver with a square handle'
        },
        engineer_job_sheet: [
            {
                name: 'Engineer Job Sheet',
                sent: true,
                method: 'Email',
                date: '16th January 2015 at 13:00pm',
                audits: [
                    {
                        method: 'Email',
                        date: '16th January 2015 at 13:00pm',
                        recipient: 'callum@commusoft.co.uk',
                        subject: 'Job sheet',
                        message: 'Please find the attached job sheet for your upcoming visit'
                    }
                ]
            }
        ],
        confirmations: [
            {
                name: 'Customer Confirmation',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            },
            {
                name: 'Work Address Confirmation',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            },
            {
                name: 'Work Address Reminder',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            }
        ],
        parts: [
            {
                id: 9,
                part: 'Boiler',
                status: 'Picked up',
                quantity: 2,
                price: 4000,
                already_invoiced: false,
                engineer_instructions: 'Collect from supplier',
                picked_up: {
                    date: '12th November 2014 at 12:23pm'
                }
            }
        ],
        keys: [
            {
                key: 'Landlord Keys',
                status: 'Collected',
                picked_up: {
                    date: 'Tuesday 24th January 2015 at 13:00pm',
                    property_name: 'Foxtons',
                    property_address_line1: '193',
                    property_address_line2: 'Old Road',
                    property_address_line3: 'Old Street',
                    property_town: 'London',
                    property_county: 'Greater London',
                    property_postcode: 'E5 9AR'
                }
            }
        ],
        todos: [
            {
                id: 10,
                status: "Done",
                type: "certificate",
                description: 'GSC for boiler',
                certificate: 'CP4 - Gas Safety Inspection',
                estimated_hours: '2.5 Hours',
                complete: {
                    time: '10:00',
                    certificate: '',
                    notes: 'Boiler inspection passed'
                },
                milestone_index: 0
            },
            {
                id: 11,
                status: "Done",
                type: "decommission",
                description: 'Old boiler is defective',
                appliance: 'Vaillant EcoTech',
                estimated_hours: '3.2 Hours',
                complete: {
                    time: '10:00',
                    notes: 'The boiler was difficult to remove'
                },
                milestone_index: 0
            },
            {
                id: 12,
                status: "Done",
                type: "general",
                description: 'Tidy up property',
                complete: {
                    time: '10:00',
                    notes: 'I tidied up the property, there was a lot of junk near the pipework in the kitchen'
                },
                milestone_index: 0
            },
            {
                id: 13,
                status: "Pending",
                type: "install",
                description: 'Install the radiator valves',
                item: 'Radiator Valves',
                estimated_hours: '1 Hours',
                quantity: '50',
                quantity_installed: '34',
                part_status: "Available",
                complete: {
                    time: '10:00',
                    notes: 'There wasnt enough time to install all of the valves'
                },
                milestone_index: 0
            },
            {
                id: 14,
                status: "Done",
                type: "maintenance",
                description: 'This is urgent',
                appliance: 'Vaillant EcoTech',
                estimated_hours: '1.4 Hours',
                complete: {
                    time: '10:00',
                    notes: 'Tightened up fixtures and fittings'
                },
                milestone_index: 0
            },
            {
                id: 17,
                status: "Done",
                type: "breakdown",
                description: 'This is urgent',
                appliance: 'Vaillant EcoTech',
                estimated_hours: '1.4 Hours',
                complete: {
                    time: '10:00',
                    notes: 'Tightened up fixtures and fittings'
                },
                milestone_index: 0
            },
            {
                id: 15,
                status: "Done",
                type: "photo",
                description: 'Take a photograph of the existing pipework, make sure you photograph all seals and fittings',
                complete: {
                    time: '10:00',
                    photo: '../images/leaking-pipe.jpg',
                    notes: 'The pipework is ruptured at the bottom. This is likely due to the pressure'
                },
                milestone_index: 0
            },
            {
                id: 16,
                status: "Done",
                type: "service",
                description: 'This is urgent',
                appliance: 'Vaillant EcoTech',
                estimated_hours: '1.8 Hours',
                complete: {
                    time: '10:00',
                    notes: 'Boiler looks ok, some slight wear and tear around the seals but nothing major'
                },
                milestone_index: 0
            }
        ],
        feedback: {
            arrive_on_site: {
                name: 'Arrive on site',
                questions: [
                    {
                        name: 'Is there parking on site?',
                        time: '11:29am',
                        type: 'select',
                        answer: 'Yes',
                        answered: true
                    },
                    {
                        name: 'Is the property easy to access?',
                        time: '11:33am',
                        type: 'input',
                        answer: 'Yes, the property is a gated detached house',
                        answered: true
                    },
                    {
                        name: 'How is the boiler accessed?',
                        time: '11:57am',
                        type: 'textarea',
                        answer: 'The boiler is in the kitchen inside a cupboard',
                        answered: true
                    }
                ],
                signatures: [
                    {
                        name: 'Adam Shaw',
                        title: 'tenant',
                        time: '11:37am',
                        url: 'images/signature.png'
                    }
                ]
            },
            leave_site: {
                name: 'Leave site',
                questions: [
                    {
                        name: 'Did you leave the property tidy?',
                        time: '13:42pm',
                        type: 'select',
                        answered: false
                    }
                ],
                signatures: []
            }
        },
        geolocation: [
            {
                status: 'Accepted',
                time: '10:32am',
                real_time: '10:32'
            },
            {
                status: 'Travel',
                time: '11:05am',
                real_time: '11:05'
            },
            {
                status: 'Arrived',
                time: '11:28am',
                real_time: '11:28'
            },
            {
                status: 'Aborted',
                time: '12:28pm',
                real_time: '12:28'
            }
        ]
    };

    diary_event_cancel = {
        id: 9000,
        timeline_id: 3,
        status: "Cancelled",
        reason_for_cancellation: "Customer no longer needs the work done",
        cancelled_by: "Callum Hart",
        complete: true,
        already_invoiced: false,
        labour_and_parts_invoiced: false,
        js_date: new Date('2015-01-31'),
        date: "Tuesday 31th January 2015",
        truncated_date: "Tues 31th Jan 2015",
        time: "11:00am to 12:30pm",
        special_event: false,
        start_time: '11:00',
        end_time: '12:30',
        duration: 1.5,
        travel_duration: 0,
        on_site_duration: 0,
        description: "Please install the radiator valves and clean the property",
        customer_name: 'Jason Morjaria',
        customer_contact_telephone: '01234567890',
        work_address_telephone: '01348271749',
        customer_contact_email: 'jason@demo.local',
        property_name: 'CommuSoft',
        property_contact_telephone: '01234567890',
        property_contact_email: 'info@commusoft.local',
        property_address_line1: '123',
        property_address_line2: 'Main Street',
        property_address_line3: 'Old Street',
        property_town: 'London',
        property_county: 'Greater London',
        property_postcode: 'E4 3AB',
        engineer_name: "Callum Hart",
        engineer_email: "callum@commusoft.co.uk",
        engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=30',
        large_engineer_profile: 'http://gravatar.com/avatar/224921860fdab66b89ad3032e7bd25f7.png?s=70',
        engineer_notes: "Beware that parking is limited, please take a photo of where you park.",
        engineer_feedback: false,
        diary_event_type: 'Job',
        completed_todos: 0,
        available_todos: 4,
        parts_installed: 0,
        parts_decommissioned: 0,
        access: {
            method: 'Landlord has keys',
            notes: 'The key is silver with a square handle'
        },
        engineer_job_sheet: [
            {
                name: 'Engineer Job Sheet',
                sent: true,
                method: 'Email',
                date: '16th January 2015 at 13:00pm',
                audits: [
                    {
                        method: 'Email',
                        date: '16th January 2015 at 13:00pm',
                        recipient: 'callum@commusoft.co.uk',
                        subject: 'Job sheet',
                        message: 'Please find the attached job sheet for your upcoming visit'
                    }
                ]
            }
        ],
        confirmations: [
            {
                name: 'Customer Confirmation',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            },
            {
                name: 'Work Address Confirmation',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            },
            {
                name: 'Work Address Reminder',
                sent: true,
                method: 'Letter',
                date: '13th September 2014 at 13:00pm',
                type: 'confirmation',
                audits: [
                    {
                        pending: false,
                        method: 'Letter',
                        date: '13th September 2014 at 13:00pm',
                        message: 'Please be reminded that we will be visiting your property soon'
                    }
                ]
            }
        ],
        parts: [
            {
                id: 10,
                part: 'Piping',
                status: 'Picked up',
                quantity: 10,
                price: 40,
                engineer_instructions: 'Collect from supplier',
                already_invoiced: false,
                picked_up: {
                    date: '12th November 2014 at 12:23pm'
                }
            }
        ],
        keys: [
            {
                key: 'Landlord Keys',
                status: 'Collected',
                picked_up: {
                    date: 'Tuesday 24th January 2015 at 13:00pm',
                    property_name: 'Foxtons',
                    property_address_line1: '193',
                    property_address_line2: 'Old Road',
                    property_address_line3: 'Old Street',
                    property_town: 'London',
                    property_county: 'Greater London',
                    property_postcode: 'E5 9AR'
                }
            }
        ],
        todos: [
            {
                id: 10,
                status: "Pending",
                type: "certificate",
                description: 'GSC for boiler',
                certificate: 'CP4 - Gas Safety Inspection',
                estimated_hours: '2.5 Hours',
                milestone_index: 0
            },
            {
                id: 11,
                status: "Pending",
                type: "decommission",
                description: 'Old boiler is defective',
                appliance: 'Vaillant EcoTech',
                estimated_hours: '3.2 Hours',
                milestone_index: 0
            },
            {
                id: 12,
                status: "Pending",
                type: "general",
                description: 'Tidy up property',
                milestone_index: 0
            },
            {
                id: 13,
                status: "Pending",
                type: "install",
                description: 'Install the radiator valves',
                item: 'Radiator Valves',
                estimated_hours: '1 Hours',
                quantity: '4',
                quantity_installed: '0',
                part_status: "Available",
                milestone_index: 0
            },
            {
                id: 14,
                status: "Pending",
                type: "maintenance",
                description: 'This is urgent',
                appliance: 'Vaillant EcoTech',
                estimated_hours: '1.4 Hours',
                milestone_index: 0
            },
            {
                id: 17,
                status: "Pending",
                type: "breakdown",
                description: 'This is urgent',
                appliance: 'Vaillant EcoTech',
                estimated_hours: '1.4 Hours',
                milestone_index: 0
            },
            {
                id: 15,
                status: "Pending",
                type: "photo",
                description: 'Take a photograph of the parking location',
                milestone_index: 0
            },
            {
                id: 16,
                status: "Pending",
                type: "service",
                description: 'This is urgent',
                appliance: 'Vaillant EcoTech',
                estimated_hours: '1.8 Hours',
                milestone_index: 0
            }
        ],
        feedback: {
            arrive_on_site: {
                name: 'Arrive on site',
                questions: [
                    {
                        name: 'Is there parking on site?',
                        time: '11:29am',
                        type: 'select',
                        answered: false
                    },
                    {
                        name: 'Is the property easy to access?',
                        time: '11:33am',
                        type: 'input',
                        answered: false
                    },
                    {
                        name: 'How is the boiler accessed?',
                        time: '11:57am',
                        type: 'textarea',
                        answered: false
                    }
                ],
                signatures: []
            },
            leave_site: {
                name: 'Leave site',
                questions: [
                    {
                        name: 'Did you leave the property tidy?',
                        time: '13:42pm',
                        type: 'select',
                        answered: false
                    }
                ],
                signatures: []
            }
        },
        geolocation: [
            {
                status: 'Accepted',
                time: '10:32am',
                real_time: '10:32'
            },
            {
                status: 'Travel',
                time: '11:05am',
                real_time: '11:05'
            },
            {
                status: 'Cancel',
                time: '12:02pm',
                real_time: '12:02'
            }
        ]
    };

    this.getDiaryEvent = function getDiaryEvent(status, timeline_index) {
        if (timeline_index === undefined) {
            timeline_index = 1;
        }
        switch (status) {
            case 'Pending':
                var diary_event = angular.copy(diary_event_pending);
                diary_event.timeline_id = timeline_index;
                return diary_event;
                break;
            case 'Accepted':
                var diary_event = angular.copy(diary_event_accept);
                diary_event.timeline_id = timeline_index;
                return diary_event;
                break;
            case 'Travel':
                var diary_event = angular.copy(diary_event_travel);
                diary_event.timeline_id = timeline_index;
                return diary_event;
                break;
            case 'Arrived':
                var diary_event = angular.copy(diary_event_arrive);
                diary_event.timeline_id = timeline_index;
                return diary_event;
                break;
            case 'No access':
                var diary_event = angular.copy(diary_event_no_access);
                diary_event.timeline_id = timeline_index;
                return diary_event;
                break;
            case 'Left':
                var diary_event = angular.copy(diary_event_leave);
                diary_event.timeline_id = timeline_index;
                diary_event.description += timeline_index;
                return diary_event;
                break;
            case 'Aborted':
                var diary_event = angular.copy(diary_event_abort);
                diary_event.timeline_id = timeline_index;
                return diary_event;
                break;
            case 'Cancel':
                var diary_event = angular.copy(diary_event_cancel);
                diary_event.timeline_id = timeline_index;
                return diary_event;
                break;
        }
    }

    this.getDiaryEventById = function getDiaryEventById(diary_event_id) {
        var deferred = $q.defer();

        $timeout(function() {
            if (diary_event_id === 1000) {
                var diary_event = this.diary_event_pending;
            } else if (diary_event_id === 2000) {
                var diary_event = this.diary_event_accept;
            } else if (diary_event_id === 3000) {
                var diary_event = this.diary_event_travel;
            } else if (diary_event_id === 4000) {
                var diary_event = this.diary_event_arrive;
            } else if (diary_event_id === 5000) {
                var diary_event = this.diary_event_no_access;
            } else if (diary_event_id === 6000) {
                var diary_event = this.diary_event_leave;
            } else if (diary_event_id === 8000) {
                var diary_event = this.diary_event_abort;
            } else if (diary_event_id === 9000) {
                var diary_event = this.diary_event_cancel
            }

            deferred.resolve(diary_event);
        }, 2000);

        return deferred.promise;
    }

    this.getNormalEvent = function() {
        return {
            id: 1000,
            timeline_id: 3,
            status: "Pending",
            complete: false,
            js_date: new Date('2015-01-15'),
            date: "Tuesday 15th January 2015",
            truncated_date: "Tues 15th Jan 2015",
            time: "10:15am to 12:15pm",
            special_event: false,
            start_time: '10:30',
            end_time: '12:30',
            duration: 2,
            travel_duration: 0,
            on_site_duration: 0,
            description: "Prepare the property for the new central heating installation",
            engineer_name: "Jason Morjaria",
            engineer_email: "jason@commusoft.co.uk",
            engineer_profile: 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=30',
            large_engineer_profile: 'http://gravatar.com/avatar/3a9fe063fe7fdbf5606db49a86b04944.png?s=70',
            engineer_notes: "The property is detatched and gated, if you have trouble with access then please call the tennant",
            diary_event_type: 'Job',
            confirmations: [
                {
                    name: 'Engineer reminder',
                    sent: false,
                    method: 'SMS',
                    date: '14th January 2015 at 13:40pm',
                    type: 'reminder',
                    audits: []
                }
            ]
        }
    }
});