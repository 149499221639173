commusoftCommon.service('postCodeHelper', function($compile, $rootScope, $timeout) {
    var self;

    /*==========================================================================================
     Replace the first view with the postcode look up view
     ==========================================================================================*/
    this.initialise = function initialise(directive_scope) {
        self = this;
        this.directive_scope = directive_scope;
        var justPostcodeInForm = directive_scope.just_postcode === "true" ? true: false;
        this.postcode_directive_tpl = '<div smartpostcodeform just-postcode=' + justPostcodeInForm +
            ' id="smart-post-code-wrap"></div>';
        this.side_panel = document.querySelectorAll('#quick-add-panel')[0];
        this.swivelled_transition_duration = 500;

        this.insertPostNode();
        this.switchView('postcode');
    }

    this.insertPostNode = function insertPostNode() {
        var postcode_directive = $compile(this.postcode_directive_tpl)(this.directive_scope);
        angular.element(this.side_panel).after(postcode_directive);
    }

    this.switchView = function switchView(view) {
        var show_postcode_form = view === 'postcode';
        this.postcode_elm = document.querySelector('#smart-post-code-wrap');
        show_postcode_form ? this.showPostCodeForm() : this.showInitialForm();
    }

    this.validatePostcode = function validatePostcode(postcode) {
        var trimmed_postcode = (postcode) ? postcode.replace(/\s/g, "") : '',
            regex = /\b((?:(?:gir)|(?:[a-pr-uwyz])(?:(?:[0-9](?:[a-hjkpstuw]|[0-9])?)|(?:[a-hk-y][0-9](?:[0-9]|[abehmnprv-y])?)))) ?([0-9][abd-hjlnp-uw-z]{2})\b/ig,
            is_valid = !regex.test(postcode);

        return is_valid;
    }

    this.getPreviousPostcode = function getPreviousPostcode() {
        return this.previous_postcode;
    }

    this.showPostCodeForm = function showPostCodeForm() {
        this.side_panel.classList.remove('swivelled-in');
        this.side_panel.classList.add('swivelled-out');

        /*
         self.postcode_elm.classList.add('swivelled-in'); // remove after dev
         */

        $timeout(function() {
            self.postcode_elm.classList.add('swivelled-in');
        }, self.swivelled_transition_duration);
    }

    this.showInitialForm = function showInitialForm() {
        self.postcode_elm.classList.remove('swivelled-in');
        self.postcode_elm.classList.add('swivelled-out');
        self.side_panel.classList.add('swivelled-in');

        /*
         self.side_panel.classList.remove('swivelled-out');// remove after dev
         self.removePostNode(); // remove after dev
         */

        $timeout(function() {
            self.side_panel.classList.remove('swivelled-out');
            self.removePostNode();
        }, self.swivelled_transition_duration);
    }

    this.removePostNode = function removePostNode() {
        var post_node_exists = this.postcode_elm.parentNode !== null;

        if(post_node_exists) {
            this.postcode_elm.parentNode.removeChild(this.postcode_elm);
        }
    }

    $rootScope.$on('quick_add_panel:closed', function() {
        var active_instance = self !== undefined;

        if(active_instance) {
            self.removePostNode();
        }
    });
});