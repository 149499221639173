commusoftCommon.filter('appendLocalUnit', function ($rootScope) {
    /*
        Appends local unit of the value

        @param number|string value
        @param string unitOf --valid units={length|speed|volume}
        @return string
    */


    return function (value, unitOf) {
        var unit = $rootScope.localStandards[unitOf];

        return value + ' ' + unit;
    }
});