csmodule.controller('diaryRightBar', ['$scope', '$rootScope', '$http', '$q', '$filter', 'diaryRightBarHelper', 'uiCalendarConfig', 'prefix', '$state', function ($scope, $rootScope, $http, $q, $filter, diaryRightBarHelper, uiCalendarConfig, prefix, $state) {
    /*==========================================================================================
        Initial Variables
    ==========================================================================================*/
    $scope.diary_ctrl_scope = $scope.$parent.$parent;
    $scope.currentStateName = $state.current.name;
    $scope.dateSelected = $scope.diary_ctrl_scope.defaultDate;
    $scope.diary_color_scheme = $scope.diary_ctrl_scope.currentColorScheme;
    $scope.today = moment()._d.toDateString();
    $scope.calendarDate = $scope.dateSelected._d.toDateString();
    $scope.dateToCheck = moment().format('YYYY-MM-DD');
    $scope.dontChangeDate = false;
    $scope.fetch_more = false;
    $scope.batch_limiter = 50;
    $scope.base_content_loaded = false;
    $scope.groups = $scope.diary_ctrl_scope.userGroups;
    $scope.selectedGroupsLength = _.where($scope.groups, {checked: true}).length;
    $scope.event_from_previous_page = diaryRightBarHelper.adding_event_from_previous_page;
    $scope.groups_open = false;
    $scope.branches = [];
    $scope.branches_open = false;
    $scope.prefix = prefix;

    $scope.outstanding_jobs = [];
    $scope.outstanding_jobs_closing = false;
    $scope.outstanding_jobs_opening = false;
    $scope.outstanding_jobs_open = false;
    $scope.search_outstanding_jobs = false;
    $scope.search_outstanding_jobs_focussed = false;

    $scope.nearby_events = [];
    $scope.nearby_events_closing = false;
    $scope.nearby_events_opening = false;
    $scope.nearby_events_open = false;
    $scope.search_nearby_events = false;
    $scope.search_nearby_events_focussed = false;

    $scope.no_access = true;
    $scope.parts_ready = true;
    $scope.without_events = true;
    $scope.with_todos = true;
    $scope.outstandingFilterParams = '';
    $scope.searchOutstandingJobParam = '';
    $scope.outstanndingJobsPage = 1;
    $scope.outstanndingJobsLimit = 50;
    $scope.outstanndingJobsCount = 0;
    $scope.loadingOutstanndingJobs = false;
    $scope.fromOutstandingFilter = false;
    $scope.previousOutstandingJobs = [];
    $scope.outStandingJobCountToShow = false;
    $scope.isFirstTime = true;

    $scope.watchers = {};
    $scope.updateOutstandingJobsData = function(data){
        $scope.outstanding_jobs = data.outstandingJobs;
        if (data.page === 1) {
            $scope.$broadcast('event:scroll-to-top');
        }

        $scope.previousOutstandingJobs = $scope.outstanding_jobs;

        if ($scope.isFirstTime) {
            $scope.isFirstTime = false;
            $scope.outStandingJobCountToShow = parseInt(data.totalOutstandingJobs);
        }

        $scope.outstanding_jobs_count = parseInt(data.totalOutstandingJobs);
        $scope.handleInitialOutstandingJobBatch();
    };

    $scope.clearOutstandingJobFilters = function () {};

    /*==========================================================================================
        Get outstanding jobs (in filters)
    ==========================================================================================*/
    $scope.getOutstandingJobs = function () {};

    /*==========================================================================================
        Limit the number of outstanding jobs to the first 50
    ==========================================================================================*/
    $scope.handleInitialOutstandingJobBatch = function handleInitialOutstandingJobBatch() {
        $scope.base_content_loaded = true;
    }

    /*==========================================================================================
        Get the next batch of outstanding jobs when scrolled to the bottom
    ==========================================================================================*/
    $scope.loadMoreOutstandingJobs = function loadMoreOutstandingJobs() {
        if ($scope.fromOutstandingFilter) {
            $scope.fromOutstandingFilter = false;
            return;
        }
    };

    /*==========================================================================================
        Open outstanding jobs list
    ==========================================================================================*/
    $scope.openOutstandingJobs = function openOutstandingJobs() {
        $scope.groups_open = false;
        $scope.branches_open = false;
        $scope.outstanding_jobs_opening = true;
        diaryRightBarHelper.showOutstandingJobs($scope);
    }

    /*==========================================================================================
        Close outstanding jobs list
    ==========================================================================================*/
    $scope.closeOutstandingJobs = function closeOutstandingJobs() {
        $scope.clearOutstandingJobFilters();
        $scope.outstanding_jobs_closing = true;
        $scope.outstanding_jobs_opening = false;
        diaryRightBarHelper.hideOutstandingJobs($scope);
    }

    /*==========================================================================================
     Open nearby events list
     ==========================================================================================*/
    $scope.openNearbyEvents = function openNearbyEvents() {
        $scope.groups_open = false;
        $scope.branches_open = false;
        $scope.nearby_events_opening = true;
        $scope.searchNearbyEventParam = "";
        diaryRightBarHelper.showNearbyEvents($scope);
    }

    /*==========================================================================================
     Close nearby events list
     ==========================================================================================*/
    $scope.closeNearbyEvents = function closeNearbyEvents() {
        $scope.nearby_events_closing = true;
        $scope.nearby_events_opening = false;
        $scope.diary_ctrl_scope.selectedDiaryEventId = false;
        diaryRightBarHelper.hideNearbyEvents($scope);
    }

    /*==========================================================================================
        Handle filters
    ==========================================================================================*/
    $scope.filterOutstandingJobs = function filterOutstandingJobs(filter_str) {
        $scope.fromOutstandingFilter = true;
        $scope[filter_str] = !$scope[filter_str];
        $scope.outstanndingJobsPage = 1;
        $scope.getOutstandingJobs();
    }

    /*==========================================================================================
        Returns boolean used for hiding / showing outstanding job tickets based on their type
    ==========================================================================================*/
    /*$scope.matchesCriteria = function matchesCriteria(outstanding_job_type) {
        return $scope[outstanding_job_type];
    }*/

    /*==========================================================================================
        Handle outstanding jobs search bar
    ==========================================================================================*/
    $scope.handleOutstandingJobSearchBar = function handleOutstandingJobSearchBar() {
        $scope.search_outstanding_jobs = !$scope.search_outstanding_jobs;

        if ($scope.search_outstanding_jobs) {
            diaryRightBarHelper.focusSearchInput('#search-outstanding-jobs-input');
        } else {
            $scope.searchOutstandingJobParam = '';
            $scope.no_search_results = false;
            $scope.fromOutstandingFilter = true;
            $scope.outstanndingJobsPage = 1;
            $scope.getOutstandingJobs();
        }
    }

    /*==========================================================================================
        Handle outstanding jobs search ~ TODO: this will need modifying when endpoint exists
    ==========================================================================================*/
    $scope.handleOutstandingJobSearchQuery = function handleOutstandingJobSearchQuery() {
        $scope.fromOutstandingFilter = true;
        $scope.outstanndingJobsPage = 1;
        $scope.getOutstandingJobs();
    };

    /*==========================================================================================
     Handle nearby event search
     ==========================================================================================*/
    $scope.handleNearbyEventSearchQuery = function handleNearbyEventSearchQuery() {
        var engineers = $scope.diary_ctrl_scope.selectedUserIdAsArray.join();
        diaryRightBarHelper.searchForNearbyEvents($scope.searchNearbyEventParam, engineers).then(function (results) {
            $scope.nearby_events = results;
        });
    }

    $scope.redirectDiary = function redirectDiary(event) {
        var date = event.start;
        $scope.diary_ctrl_scope.selectedDiaryEventId = event.id;
        var csCalendar = uiCalendarConfig.calendars.csCalendar,
            view = csCalendar.fullCalendar('getView');
        if (view.name != 'list') {
            uiCalendarConfig.calendars.csCalendar.fullCalendar('gotoDate', moment(date));
            $scope.setCalendarDate();
        }
    }

    $scope.$watch('dateSelected', function (newVal, oldVal) {
        //This block is to avoid the recursion when we set the date from press next, prev
        if (!$scope.dontChangeDate && uiCalendarConfig.calendars.csCalendar) {
            if (!$state.params.diaryId) {
                $scope.diary_ctrl_scope.selectedDiaryEventId = false;
            }
            uiCalendarConfig.calendars.csCalendar.fullCalendar('gotoDate', newVal);
            $scope.setCalendarDate();
            if (newVal != oldVal) {
                $scope.diary_ctrl_scope.save_state = true;
            }
        } else {
            $scope.dontChangeDate = false;
        }
    });

    $scope.changeDate = function (option) {
        $scope.diary_ctrl_scope.prevNextClicked = option;
        $scope.diary_ctrl_scope.selectedDiaryEventId = false;
        $scope.diary_ctrl_scope.save_state = true;
        var csCalendar = uiCalendarConfig.calendars.csCalendar,
            view = csCalendar.fullCalendar('getView');
        //Todo: this will be removed when we add next and prev option to list view, now list just display the events of previous view
        if (view.name != 'list' && !$scope.diary_ctrl_scope.loadingAllEvents) {
            uiCalendarConfig.calendars.csCalendar.fullCalendar(option);
            $scope.setCalendarDate();
        }
    }

    $scope.setCalendarDate = function () {
        var calendarDate = uiCalendarConfig.calendars.csCalendar.fullCalendar('getDate');
        if (!calendarDate || !moment.isMoment(calendarDate)) {
            return false;
        }
        calendarDate = calendarDate.local();
        var calendarDateStr = calendarDate._d.toDateString(),
            dateSelectedStr = moment($scope.dateSelected)._d.toDateString();
        if (calendarDateStr != dateSelectedStr) {
            $scope.dontChangeDate = true;
            $scope.dateSelected = calendarDate;
        }
        $scope.calendarDate = calendarDateStr;
    }

    $scope.filterUsers = function () {
        $scope.selectedGroupsLength = _.where($scope.groups, {checked: true}).length;
        $scope.diary_ctrl_scope.filterUsers($scope.groups, $scope.branches);
    }

    /*==========================================================================================
      When the "add to diary afterwards" checkbox is selected when adding a new job / estimate OR
      when adding an event to an existing job /estimate
       ==========================================================================================*/
    $scope.mockAddingEventFromPreviousScreen = function (estimateJobDetails, should_show_nearby_events) {
        var job_or_estimate_already_has_event = (estimateJobDetails.numberOfEvents > 0) ? true : false;

        var mock_job_estimate_data = estimateJobDetails;

        diaryRightBarHelper.event_from_previous_page = mock_job_estimate_data;
        diaryRightBarHelper.adding_event_from_previous_page = true;
        diaryRightBarHelper.job_or_estimate_already_has_event = job_or_estimate_already_has_event;
        $scope.adding_event_from_previous_page = diaryRightBarHelper.adding_event_from_previous_page;

        if (typeof(should_show_nearby_events) != 'undefined' && should_show_nearby_events !== false) {
            $scope.nearby_events_open = true;
            $scope.nearby_events_opening = true;
        }
        $scope.event_from_previous_page = diaryRightBarHelper.event_from_previous_page;
        $scope.searchNearbyEventParam = "";
    }

    if ($scope.diary_ctrl_scope.estimateJobDetails) {
        $scope.mockAddingEventFromPreviousScreen($scope.diary_ctrl_scope.estimateJobDetails);
    } else {
        diaryRightBarHelper.adding_event_from_previous_page = false;
        $scope.adding_event_from_previous_page = false;
    }

    $scope.$on('diary:set_previous_page_values', function (e, message, should_show_nearby_events) {
        if (!message) {
            diaryRightBarHelper.adding_event_from_previous_page = false;
            $scope.adding_event_from_previous_page = false;
            $scope.outstanding_jobs = $scope.previousOutstandingJobs;
        } else {
            $scope.mockAddingEventFromPreviousScreen(message, should_show_nearby_events);
        }
    });

    /** This for map view ************************************************ */
    // enable/disable datepicker on map view mode changes
    $scope.datepickerDisabled = false;
    $scope.$on('disableDatepicker', function (event, args) {
        $scope.datepickerDisabled = args;
    });

    // Broadcasting diary selected date
    $scope.$watch('calendarDate', function (value) {
        var newDate = $scope.dateSelected;
        if (newDate._isAMomentObject) newDate = newDate._d;

        $scope.$broadcast('calendarDateChanged', newDate);
    });


    $scope.hasPermission = function (feature, access) {
        return $rootScope.hasPermission(features[feature], access);
    };

    $scope.$watch('calendarDate', function (value) {
        var newDate = $scope.dateSelected;
        if (newDate._isAMomentObject) newDate = newDate._d;

        $scope.$broadcast('calendarDateChanged', newDate);
    });

    /** ****************************************************************** */

    $scope.$on('$destroy', function () {
        angular.forEach($scope.watchers, function (subscription, name) {
            subscription();
        });
    });
}]);
