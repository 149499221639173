commusoftCommon.service('formBuilder', function(stringHelpers) {
    // instance variables
    this.input_collection;
    var dateformat = '';
    if(navigator.languages[0] === 'en-GB') {
        dateformat = 'dd/MM/yyyy';
    }else if(navigator.languages[0] === 'en-US'){
        dateformat = 'MM-dd-yyyy';
    }else{
        dateformat = 'dd-MM-yyyy';
    }
    // input templates
    var form_input_tpls = {
        text: '<input type="text" ng-model="{{model}}">',
        number: '<input type="text" ng-model="{{model}}">',
        email: '<input type="email" ng-model="{{model}}">',
        from: '<input type="text" ng-model="{{model}}">',
        textarea: '<textarea ng-model="{{model}}"></textarea>',
        checkbox: '<label {{paidFilter}} ><input type="checkbox" ng-model="{{model}}">{{label}}</label>',
        radio: '<label><input type="radio" name="radiobox" ng-model="{{model}}" value = "false">{{label}}</label>',
        select: '' +
        '<div class="select-with-clear-link-container" {{paidFilter}}>' +
        '<select ng-model="{{model}}" id="{{id}}" class="full-width-select">' +
        '<option ng-show="model==prompt && model!=\'Type\'" selected value="prompt" disabled>-- Please choose --</option>' +
        '<option ng-show="model!=prompt && model==\'Type\'" value="type">All</option>' +
        '<option ng-repeat="item in {{repeater_options}}" value="{{item.id}}" ng-selected="{{item.id == {{model}}}}">{{item.text}}</option>' +
        '</select>' +
        '<a ng-click="resetSalesReportMoreFilter(\'{{model}}\'); {{model}} = \'prompt\'" href="" ng-show="{{model}} !== \'prompt\' && {{model}} !== \'type\'">Reset</a>' +
        '</div>',
        titleSelect: '' +
        '<div class="select-with-clear-link-container">' +
        '<select ng-model="{{model}}" id="{{id}}" class="full-width-select" ng-options="item.id as item.text for item in {{repeater_options}}" ng-init="{{model}} = {{defaultId}}">' +
        '</select>' +
        '<a ng-click="{{model}} = {{defaultId}}" href="" ng-show="{{model}} !== {{defaultId}}">Reset</a>' +
        '</div>',
        postcode: '<div class="input-append"><input type="text" ng-model="postcode" ng-change="isInputPostcode(postcode)"><a class="add-on" href="" ng-click="lookUpPostCode()" ng-show="!invalid_postcode"><span class="ss-search"></span></a><a class="add-on disabled" href="" ng-show="invalid_postcode" tooltip="Please enter a valid postcode" tooltip-placement="right"><span class="ss-search"></span></a></div>',
        phone: '<div phonewidget multiple-numbers="true"></div>',
        date: '<div ng-controller="DatePickerWidget"><div class="control-group datepicker-control-group formbuilder-datepicker"><div class="controls"><div class="input-append datepicker-append"><input id="datepicker-input" type="text" disabled ng-model="{{model}}" value="{{ {{model}} | date:\'{{dateformat}}\'}}"><span class="add-on" ng-click="show_date_picker = !show_date_picker"><span class="ss-calendar"></span></span></div></div><div class="page-datepicker"><div class="calendar-wrapper" ng-show="show_date_picker"><datepicker type="pagePicker" min="minDate" show-weeks="showWeeks" ng-model="{{model}}"></datepicker></div></div></div></div>',
        smart_filter: '<div class="smart-filter"><select chosen ng-model="{{model}}" ng-options="option for option in {{repeater_options}}" data-placeholder="-- Please Choose --" class="chosen-select full-width-select"></select></div>',
        smart_filter_with_optgroup: '' +
        '<div class="select-with-clear-link-container">' +
        '<select ng-model="{{model}}" class="full-width-select" >' +
        '<option selected value="prompt" disabled>-- Please choose --</option>' +
        '<optgroup ng-repeat="(key, row) in {{repeater_options}}" label="{{key}}">' +
        '<option ng-repeat="item in row.items" ng-selected="{{model}} == item.id" value="{{item.id}}">{{item.text}}</option>' +
        '</optgroup>' +
        '</select>' +
        '<a ng-click="resetSalesReportMoreFilter(\'{{model}}\'); {{model}} = \'prompt\'" href="" ng-show="{{model}} !== \'prompt\'">Reset</a>' +
        '</div>',
        smart_filter_dynamic: '<div ng-model="{{model}}" class="full-width-select" {{multiple}} {{paidFilter}} ui-select2="select2Options_{{model}}"></div>',
        smart_filter_withoutoptgroup: '' +
        '<div class="controls full-width-smart-filter">' +
        '<div class="container smart-filter">' +
        '<select ng-model="{{model}}" class="chosen-select" id="userGroupSelector">' +
        '<option selected="selected"></option>' +
        '<option ng-repeat="group in {{repeater_options}}" value="{{ group.id }}" ng-class="{group: group.group }">{{group.description}}</option>' +
        '</select>'+
        '<span fixchosen="userGroupSelector" data="{{repeater_options1}}"></span>' +
        '</div>' +
        '</div>',
        dynamicTemplate: ''+ '<panelwithformtemplate templateCategory="{{templateCategory}}" filePath="{{filePath}}" ></panelwithformtemplate>'
    };

    this.assessbleInputs = function assessbleInputs(input_collection) {
        this.input_collection = input_collection;
        this.handleInputs();
    }

    this.handleInputs = function handleInputs() {
        this.grouped_inputs = [];
        var tmp_input = {};

        for (var i = 0, l = this.input_collection.length; i < l; i++) {
            var type = this.input_collection[i].type,
                titleSelect_input = type === 'titleSelect',
                select_input = type === 'select',
                smart_filter_input = type === 'smart_filter',
                smart_filter_with_optgroup = type === 'smart_filter_with_optgroup',
                smart_filter_withoutoptgroup = type === 'smart_filter_withoutoptgroup',
                checkbox_input = type === 'checkbox',
                Radio_inputs = type === 'radio',
                smart_filter_dynamic = type === 'smart_filter_dynamic',
                checkbox_collection_input = type === 'checkbox_collection',
                input_has_model = this.input_collection[i].model !== undefined,
                input_has_id = this.input_collection[i].input_id !== undefined,
                input_has_permission = this.input_collection[i].hasPermission !== undefined,
                input_has_access = this.input_collection[i].access !== undefined,
                required_input = this.input_collection[i].required,
                email_input = type ==='email',
                from_input = type ==='from',
                dynamicTemplate = type ==='dynamicTemplate';

            tmp_input.input = form_input_tpls[type];
            tmp_input.type = type;
            tmp_input.label = this.input_collection[i].label;
            tmp_input.model = this.input_collection[i].model;
            tmp_input.depends = this.input_collection[i].depends;
            tmp_input.paidFilter = this.input_collection[i].paidFilter;
            tmp_input.hasPermission = this.input_collection[i].hasPermission;
            tmp_input.access = this.input_collection[i].access;
            if(this.input_collection[i].check_exists) {
                tmp_input.check_exists = this.input_collection[i].check_exists;
                tmp_input.check_exists_url = this.input_collection[i].check_exists_url;
                tmp_input.additional_params = this.input_collection[i].additional_params;
                tmp_input.exists_error_message = this.input_collection[i].exists_error_message;
            }
            if (tmp_input.depends || smart_filter_dynamic) {
                tmp_input.dynamicUrl = this.input_collection[i].url;
            }
            if (dynamicTemplate) {
                tmp_input.filePath = this.input_collection[i].filePath;
                tmp_input.templateCategory = this.input_collection[i].templateCategory;
                tmp_input.data = this.input_collection[i].data;
                tmp_input.input = tmp_input.input.replace(/{{filePath}}/g, this.input_collection[i].filePath);
                tmp_input.input = tmp_input.input.replace(/{{templateCategory}}/g, this.input_collection[i].templateCategory);
                tmp_input.input = tmp_input.input.replace(/{{data}}/g, this.input_collection[i].data);
            }

            if(select_input) {
                var select_options = this.input_collection[i].options;
                tmp_input.input = this.handleSelect(select_options,  this.input_collection[i].paidFilter);
                tmp_input.options = select_options;
            }else if(titleSelect_input){
                var select_options = this.input_collection[i].options;
                tmp_input.input = this.handleTitleSelect(select_options);
                tmp_input.options = select_options;
            }else if(smart_filter_input) {
                tmp_input.url = this.input_collection[i].url;
            }else if(checkbox_input) {
                tmp_input.input = this.handleCheckbox(this.input_collection[i].label, this.input_collection[i].paidFilter);
                tmp_input.type = 'checkbox';
            }else if(Radio_inputs){
                tmp_input.input = this.handleRadio(this.input_collection[i].label);
                tmp_input.type = 'radio';
            }else if(checkbox_collection_input) {
                var checkbox_labels = this.input_collection[i].checkboxes;
                tmp_input.type = 'checkbox_collection';
                tmp_input.checkboxes = this.handleCheckboxCollection(checkbox_labels);
            } else if (smart_filter_with_optgroup) {
                tmp_input.data = this.input_collection[i].data;
            } else if (smart_filter_withoutoptgroup) {
                tmp_input.data = this.input_collection[i].data;
            } else if (smart_filter_dynamic) {
                var multiple = (this.input_collection[i].multiple && this.input_collection[i].multiple == true) ? "multiple=multiple" : "";
                tmp_input.input = tmp_input.input.replace(/{{multiple}}/, multiple);
                var paidFilter =  this.input_collection[i].paidFilter;
                tmp_input.input = tmp_input.input.replace('{{paidFilter}}', paidFilter);

            }

            if(required_input) {
                tmp_input.required = this.input_collection[i].required;
                tmp_input.error_message = this.input_collection[i].error_message;
            }
            if(this.input_collection[i].type === 'number') {
                tmp_input.error_message = this.input_collection[i].error_message;
            }
            if(this.input_collection[i].type === 'email') {
                tmp_input.error_message = this.input_collection[i].error_message;
            }
            if(this.input_collection[i].type === 'from') {
                tmp_input.error_message = this.input_collection[i].error_message;
            }
            if(this.input_collection[i].type === 'date') {
                tmp_input.input = tmp_input.input.replace(/{{dateformat}}/g, dateformat);
            }
            if(input_has_id) {
                tmp_input.input_id = this.input_collection[i].input_id;
                tmp_input.input = tmp_input.input.replace('{{id}}', tmp_input.input_id);
            }
            if(input_has_model) {
                tmp_input.input = tmp_input.input.replace(/{{model}}/g, this.input_collection[i].model);
            }else {
                if(checkbox_collection_input) {
                    tmp_input.checkboxes = this.handleCheckboxCollectionModels(i, tmp_input.checkboxes);
                    tmp_input.models = _.pluck(this.input_collection[i].checkboxes, 'model');
                }else {
                    tmp_input.input = tmp_input.input.replace('ng-model="{{model}}"', '');
                }
            }

            this.grouped_inputs.push(tmp_input);
            tmp_input = {};
        }
    }

    /*====================================================
     Construct a select and return the template
     ====================================================*/
    this.handleSelect = function handleSelect(select_options, paidFilter) {
        var select_tpl = form_input_tpls.select,
            option_collection = '';

        if((paidFilter) == '') {
            var select_tpl= select_tpl;

        } else {
            select_tpl = select_tpl.replace('{{paidFilter}}', paidFilter);
        }
        return select_tpl;
    }

    this.handleTitleSelect= function handleTitleSelect(select_options){
        var select_tpl=form_input_tpls.titleSelect,
            option_collection = '';
        return select_tpl;
    }

    /*====================================================
    Construct a checkbox and return the template
    ====================================================*/
    this.handleCheckbox = function handleCheckbox(label, paidFilter) {
        var label = label.label || label,
            paidFilter = paidFilter,
            checkbox_tpl = form_input_tpls.checkbox;
        if((paidFilter) == '') {
            var checkbox = checkbox_tpl.replace('{{label}}', label);
        } else {
            checkbox = checkbox_tpl.replace('{{paidFilter}}', paidFilter).replace('{{label}}', label);
        }
        return checkbox;
    }
    this.handleRadio = function handleRadio(label) {
        var label = label.label || label,
            radio_tpl = form_input_tpls.radio,
            radio = radio_tpl.replace('{{label}}', label);

        return radio;
    }

    /*==========================================================================================
     Constructs the html for a group of checkboxes
     ==========================================================================================*/
    this.handleCheckboxCollection = function handleCheckboxCollection(checkboxes) {
        var checkboxes_collection = '';

        for (var i = 0, l = checkboxes.length; i < l; i++) {
            var checkbox = this.handleCheckbox(checkboxes[i]);
            checkboxes_collection += checkbox;
        }

        return checkboxes_collection;
    }

    /*==========================================================================================
     Add models to checkboxes that belong in a collection
     ==========================================================================================*/
    this.handleCheckboxCollectionModels = function handleCheckboxCollectionModels(index, checkboxes_str) {
        for(var i = this.input_collection[index].checkboxes.length - 1; i >= 0; i--) {
            var model = this.input_collection[index].checkboxes[i].model,
                model_strs = stringHelpers.findAllOccurances(checkboxes_str, '{{model}}');

            checkboxes_str = stringHelpers.replaceStringAt(checkboxes_str, model_strs[i], '{{model}}', model);
        }
        return checkboxes_str;
    }

    // return the templates
    this.getTemplates = function getTemplates() {
        return this.grouped_inputs;
    }
    this.getSmartFilterData = function getSmartFilterData(){
        return this.pathData;
    }
});
