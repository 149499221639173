commusoftCommon.service('searchPageNumName', function() {

    this.getSearchPageNumName= function getSearchPageNumName() {
        var pageNumName = {
            "properties": "currentPageProperties",
            "suppliers": "currentPageSuppliers",
            "invoiceaddress": "currentPageInvoiceaddress",
            "estimates": "currentPageEstimates",
            "supplierspo": "currentPageSupplierspo",
            "suppliersinvoices": "currentPageSuppliersinvoices",
            "stocks": "currentPageStocks",
            "jobs": "currentPageJobs",
            "invoices": "currentPageInvoices",
            "customercreditnotes":"currentPageCustomercreditnotes",
            "suppliercreditnotes":"currentPageSuppliercreditnotes",
            "stocklocations":"currentStockLocations",
            "opportunities":"currentPageOpportunities",
        };

        return pageNumName;
    };

    this.getSearchPageNumNameValue= function getSearchPageNumNameValue() {
        var pageNumNameValue = {};
        angular.forEach(this.getSearchPageNumName, function(val, key){
            pageNumNameValue[key] = 1;
        });
        return pageNumNameValue;
    }
});
