commusoftCommon.directive('confirmationbox', function($rootScope, $timeout) {

    var initialise = function initialise(scope, element, attrs) {
        scope.element = element[0];
        scope.message = attrs.message;
        scope.confirmation_showing = false;

        scope.confirmPrompt = function confirmPrompt() {
            var confirmation = new CustomEvent('confirmation_box_resolved', { 'detail': true });
            scope.element.dispatchEvent(confirmation, true);
        }

        scope.rejectPrompt = function rejectPrompt() {
            var confirmation = new CustomEvent('confirmation_box_resolved', { 'detail': false });
            scope.element.dispatchEvent(confirmation);
        }

        scope.hideConfirmationBox = function hideConfirmationBox() {
            scope.confirmation_showing = false;
            document.body.style.setProperty('pointer-events', 'auto');
            $timeout(function() {
                angular.element(scope.element).remove();
            }, 400);
        }

        scope.showConfirmationBox = function showConfirmationBox() {
            var insert_confirmation = document.querySelector('#confirmation-wrapper') === null;

            if(insert_confirmation) {
                angular.element(document.body).append(scope.element);

                $timeout(function() {
                    document.body.style.setProperty('pointer-events', 'none');
                    scope.confirmation_showing = true;
                }, 100);
            }
        }

        scope.showConfirmationBox();
    }

    return {
        restrict: 'A',
        scope: {},
        templateUrl: 'template/shared/confirmation_box.html',
        link: initialise
    }
});