commusoftCommon.service('sessionLogger', function() {
    this.startTime = this.endTime = 0;

    this.setStartTime = function(time) {
        this.startTime = time;
    }

    this.setEndTime = function(time) {
        this.endTime = time;
    }

    this.getDuration = function() {
        return this.endTime - this.startTime;
    }
});