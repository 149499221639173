csmodule.directive("infinitescrollwrapper", function($rootScope) {                                                                                                                                                                                         
        return {
            restrict: 'ACE',
            link: function(scope, elm, attr) {
                    var raw = elm[0];
                    elm.bind('scroll', function() {
                        $rootScope.$broadcast('scrollEvent', {'elem': elm, 'scrollTop': $(elm).scrollTop()});
                        if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
                            scope.$apply(attr.infiniteScroll);
                        }
                    });
            }

       }
    });
