commusoftCommon.directive('csappliancemodal', ['prefix', '$http', '$modal', function(prefix, $http, $modal) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            title: '@',
            deleteUrl: '@',
            textToConfirm: '@',
            dropdownlistUrl: '@',
            titleMethod: '@',
            titleText: '@',
            textName: '@',
            textMessage: '@',
            textItem:'@',
            buttonClass: '@',
            buttonValue: '@',
            validationMessage: '@',
            shouldBeOpen: '=',
            deleteFunc: '&',
            page: '=',
            limit: '=',
            totalEntries: '=',
            selectedModel: '='
        },
        controller: function($scope, $element, $attrs, $transclude) {
            $scope.$watch('shouldBeOpen', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;

                    modalInstance = $modal.open({
                        templateUrl: 'template/mergeappliancemodel_modal.html',
                        scope: $scope,
                        controller: CSApplianceModalCtrl
                    })
                }
            });
        }
    }
}]);