commusoftCommon.directive('freeofchargemodal', ['jobData', '$modal', 'prefix', '$http', function(jobData, $modal, prefix, $http) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            shouldBeOpen: '=',
            jobId: '='
        },
        controller: function ($scope, $element, $attrs, $transclude) {
            $scope.$watch('shouldBeOpen', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;

                    modalInstance = $modal.open({
                        templateUrl: 'template/free_of_charge_service_modal.html',
                        scope: $scope,
                        controller: CSFreeOfChargeServiceCtrl
                    })
                }
            });
        },
        link: function (scope, element, attrs) {
            scope.finalCollection = [];
            $http.get(prefix + '/get_service_reminder_instance_details?jobId=' + scope.jobId).success(function (data) {
                scope.loading = false;
                scope.ServiceReminderRecords = data.ServiceReminderRecords;
            });
        }
    }
}]);