commusoftCommon.filter('localVolume', function ($rootScope) {
    /*
        Converts volume into local volume (liters, gallons)

        @param number value
        @param string sourceUnit --valid units={l|gal}
        @param boolean showUnit
        @return float
    */
    var unit = $rootScope.localStandards.volume;

    return function (value, sourceUnit, showUnit) {
        if(!sourceUnit) sourceUnit = 'l';
        value = parseFloat(value);

        var ml = 0, converted = 0;
        switch (sourceUnit) {
            case "gal": ml = value / (3.78541 * 1000); break; // liters to gallons
            default: ml = value * 1000; break;
        }

        switch (unit) {
            case "gal": converted = ml * (3.78541 * 1000); break; // gallons to liters
            default: converted = ml * 1000; break;
        }

        return showUnit ? converted.toFixed(2) + ' ' + unit : converted.toFixed(2);
    }
});