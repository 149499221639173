csmodule.service('spreadsheetCheckboxHelper', function($rootScope, $timeout) {
  var self;

  this.handleRowSelection = function handleRowSelection(context, directive_scope, row_selected) {
    self = this;
    this.checkbox_scope = context;
    this.directive_scope = directive_scope;
    $rootScope.spreadsheet_instance_id = directive_scope.scope_id;

    if(row_selected) {
      this.storeSelectedRow();
    }else {
      this.removeSelectedRow();
    }

    $rootScope.$broadcast('spreadsheet:rows_store_updated', $rootScope.spreadsheet_rows_store);
  }

  this.storeSelectedRow = function storeSelectedRow() {
    if($rootScope.spreadsheet_rows_store === undefined) {
      $rootScope.spreadsheet_rows_store = [];
    }

    $rootScope.spreadsheet_rows_store.push(this.checkbox_scope.row);
  }

  this.removeSelectedRow = function removeSelectedRow() {
    var index_to_remove = _.indexOf(_.pluck($rootScope.spreadsheet_rows_store, 'id'), this.checkbox_scope.row.id);
    $rootScope.spreadsheet_rows_store.splice(index_to_remove, 1);
  }

});
