commusoftCommon.directive('reportingOperational', ['prefix', '$http', '$modal', 'tableCollection', '$document', '$timeout', 'clickEvents', '$rootScope', '$compile', 'dragHelper', '$state', 'formPanelCollection','reportFilterSettings','toastBox', function(prefix, $http, $modal, tableCollection, $document, $timeout, clickEvents, $rootScope, $compile, dragHelper, $state, formPanelCollection,reportFilterSettings,toastBox) {
    return {
        restrict: 'ACE',
        link: function($scope, elem, attrs) {
            $scope.counts = {};
            $scope.visibleRows = {};
            $scope.searchMode = false;
            $scope.highlightedJob = {};
            $scope.firstRun = true;
            $scope.loadingDataLock = false;
            $scope.tableId = 'operational-table';
            $scope.operationalTableSpinner = true;
            $scope.finishedDateScrolling = false;
            $scope.selectedParts = {};
            $scope.selectedIds = [];
            $scope.currentFilters = undefined;
            $scope.oldFilters = undefined;
            $scope.selectedStockLoc = undefined;
            $scope.selectedStockLocation = -1;
            $scope.stockLocationInvalid = false;
            $scope.resetResults = {};
            $scope.noRecordFound = {};
            $scope.validateStockAllocationStarted = false;
            $scope.currentPageDefault = 1;
            $scope.currentPage = {};
            $scope.pageLimitDefault = 20;
            $scope.pageLimit = {};
            $scope.disableInfiniteScrollDefault = false;
            $scope.disableInfiniteScroll = {};
            $scope.reachedEndOfParts = false;
            $scope.stickyHeader = false;
            $scope.todaysDate = false;
            $scope.tabScrollPosition = {};
            $scope.filterStorage = {};
            $scope.tableColumns = tableCollection.collection['reporting_stock_control_returns_operational_list'];
            $scope.rowData = tableCollection.collection['reporting_stock_control_returns_operational_list'].data;
            $scope.loadingMore = false;
            $scope.tinymceOptions = {
                menubar: false,
                toolbar: "fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright",
                content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
                plugins: "paste,textcolor,autolink",
                paste_as_text: true,
                browser_spellcheck : true,
                paste_block_drop: false,
                fontsize_formats: "6pt 8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                relative_urls : false,
                remove_script_host : false,
                convert_urls : true,
                height: 260
            };
            $scope.tinymceSmsOptions = {
                menubar: false,
                toolbar: false,
                statusbar: false,
                content_css: "../../css/public/stylesheets/mce-editor.css",
                plugins: "paste",
                paste_as_text: true,
                paste_block_drop: false,
                height: 10
            };

            $scope.saveTabState = function(fromTab, toTab) {
                // Saving selected parts, if any
                $scope.data[fromTab] = $scope.operationalList;
                return $scope.currentFilters;
            };
            $scope.loadMoreResults = function(evt) {
                evt.preventDefault();
                $scope.loadingMore = true;
                $scope.data[$scope.mode] = $scope.operationalList;
                $scope.currentPage[$scope.mode]++;
                $scope.getData($scope.currentFilters, true);
            }

            $scope.initTable = function(tabchange) {
                var oldmode = $scope.mode;
                $scope.mode = $state.params.type;

                // Setting defaults. The values in these settings will be preserved between tab switches
                if (!$scope.currentPage[$scope.mode]) {
                    $scope.currentPage[$scope.mode] = $scope.currentPageDefault;
                }
                if (!$scope.pageLimit[$scope.mode]) {
                    $scope.pageLimit[$scope.mode] = $scope.pageLimitDefault;
                }
                if (!$scope.disableInfiniteScrollDefault[$scope.mode]) {
                    $scope.disableInfiniteScroll[$scope.mode] = $scope.disableInfiniteScrollDefault;
                }

                $scope.finishedDateScrolling = false;
                if (oldmode !== $scope.mode) {
                    var filters = angular.toJson($scope.saveTabState(oldmode, $scope.mode));
                }
                if ($scope.resetResults[$scope.mode]) {
                    // $scope.getData(filters);
                }
                if (!$scope.data[$scope.mode]) {
                    $scope.operationalTableSpinner = false;
                    $scope.disableInfiniteScroll[$scope.mode] = $scope.disableInfiniteScrollDefault;
                    // $scope.getData(filters);
                }
                else {
                    // This updates the list of available filters which shows up in the dropdown when you click on the filter search bar.
                    $rootScope.$broadcast('updateOperationalTableFilters', $scope.tableFilters[$scope.mode], $scope.mode);
                    $scope.$emit('panelwithform:status_fromOperationalList', $scope.mode);
                    formPanelCollection.setFilterData($scope.tableFilters);

                    // This updates the results list
                    $scope.operationalList = angular.copy($scope.data[$scope.mode]);
                    $scope.operationalTableSpinner = false;
                }
                $scope.visibleRows = {};
                $scope.searchMode = false;
                $scope.appliedFilters = {};

                if (tabchange) {
                    $rootScope.$broadcast('changeOperationalTableTab', oldmode, $scope.mode); // Old tab and new tab names
                }

                if (!$scope.highlightedJob[$scope.mode]) {
                    $scope.highlightedJob[$scope.mode] = false;
                }
            };

            $scope.$on('$stateChangeSuccess', function(e, toState, toParams) {
                $scope.firstRun = false;
                $scope.initTable(true);
            });
            $scope.tableFilters = {};

            $scope.mode = $state.params.type;
            $scope.data = {};

            $scope.switchTab = function(e, newtab) {
                e.preventDefault();
                e.stopPropagation();
                $scope.mode = newtab;
                $scope.operationalList = $scope.data[$scope.mode];
                $scope.getData();
            };
            $scope.unselectAllParts = function(){
                $scope.selectedIds = [];
                angular.forEach($scope.rowData, function(Value, Index) {
                    Value.selected = false;
                });
            };

            $scope.resetTab = function(filters) {
                $scope.unselectAllParts();
                $scope.currentPage[$scope.mode] = 1;
                $scope.getData(filters);
                $rootScope.$broadcast('reportFilterLoading', $scope.tableId, false);
            };

            $scope.$on('operational_table:filtervalues', function(e, filters) {
                $scope.currentFilters = angular.copy(filters);
                $scope.resetTab(filters);
            });

            $scope.initTable();
        },
        controller: function($scope) {
            $scope.firstRun = true;
            $scope.highlightedJob = {};
            $scope.openConfirmation = function(mode){
                if(mode == "stock_location"){
                    $scope.stock_location_confimation = true;
                }else if(mode == "supplier"){
                    $scope.supplier_confimation = true;
                }
            }
            $scope.$on('confimration_modal:update', function(evnt,data){
                var processed_returns = _.map(data['processed_returns'],function(val){ return parseInt(val); });
                $scope.rowData = _.reject($scope.rowData, function(value, index){
                    return _.contains(processed_returns,parseInt(value.returnsId));
                });
            });
            $scope.selectPart = function (key){
                $scope.rowData[key].selected = !$scope.rowData[key].selected;
                if($scope.mode != 'excess_for_job'){
                    if($scope.rowData[key].selected){
                        $scope.selectedIds.push($scope.rowData[key].returnsId);
                    }else{
                        angular.forEach($scope.selectedIds, function(value, Index) {
                            if (value === $scope.rowData[key].returnsId) {
                                $scope.selectedIds.splice(Index, 1);
                            }
                        });
                    }
                }
                $scope.rowData[key].allocations = {engineer_van :[{qty:''}],
                                                   stock_room :[{qty: '',stock_location:''}],
                                                   supplier_return :[{qty: '',stock_location:''}]};
                return true;
            }


            $scope.monthLookup = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ];

            $scope.dayOfWeekLookup = [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ];

            // $scope.getOrdinalIndicator = function(date) {
            //     date = String(date);
            //     if (date.length) {
            //         var lastDigit = parseInt(date[date.length-1]);
            //         date = parseInt(date);
            //         if (lastDigit !== "NaN") {
            //             if (lastDigit == 0 || lastDigit >= 4 || (date >= 10 && date <= 19)) {
            //                 return date + "th";
            //             }
            //             else if (lastDigit == 1) {
            //                 return date + "st";
            //             }
            //             else if (lastDigit == 2) {
            //                 return date + "nd";
            //             }
            //             else if (lastDigit == 3) {
            //                 return date + "rd";
            //             }
            //         }
            //     }
            //     return false;
            // }


            $scope.transformDate = function(date) {
                var transformedDate = new Date(date);
                transformedDate = $scope.dayOfWeekLookup[transformedDate.getDay()] + ', ' + $scope.getOrdinalIndicator(transformedDate.getDate()) + ' ' + $scope.monthLookup[transformedDate.getMonth()] + ', ' + transformedDate.getFullYear();
                return transformedDate;
            };

            $scope.smoothScrollToPosition = function(target, previousScrollTop) {
                if (!$scope.finishedDateScrolling) {
                    var lastKnownScrollTop = $('.reporting_wrapper').scrollTop();
                    if (previousScrollTop) {
                        if (previousScrollTop == $('.reporting_wrapper').scrollTop()) {
                            // If the previous scrollTop position is the same as last time, it probably means we reached the end of the page. So, return false and don't scroll anymore.
                            $scope.finishedDateScrolling = true;
                            return false;
                        }
                    }
                    if (target > 170 /*combined height of various headers*/) {
                        if ((target - $('.reporting_wrapper').scrollTop()) > 150) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 100);
                            $timeout(function() {
                                $scope.smoothScrollToPosition(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((target - $('.reporting_wrapper').scrollTop()) > 50) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 50);
                            $timeout(function() {
                                $scope.smoothScrollToPosition(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((target - $('.reporting_wrapper').scrollTop()) > 20) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 20);
                            $timeout(function() {
                                $scope.smoothScrollToPosition(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((target - $('.reporting_wrapper').scrollTop()) > 5) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 5);
                            $timeout(function() {
                                $scope.smoothScrollToPosition(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((target - $('.reporting_wrapper').scrollTop()) > 1) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 1);
                            $timeout(function() {
                                $scope.smoothScrollToPosition(target, lastKnownScrollTop);
                            }, 10);
                        }
                    }
                    else {
                        $scope.finishedDateScrolling = true;
                    }
                }
            }

            $scope.smoothScrollToElement = function(target, previousScrollTop) {
                if (!$scope.finishedDateScrolling) {
                    var lastKnownScrollTop = $('.reporting_wrapper').scrollTop();
                    if (previousScrollTop) {
                        if (previousScrollTop == $('.reporting_wrapper').scrollTop()) {
                            // If the previous scrollTop position is the same as last time, it probably means we reached the end of the page. So, return false and don't scroll anymore.
                            $scope.finishedDateScrolling = true;
                            return false;
                        }
                    }

                    var targetTop = $(target).offset().top;

                    if (targetTop > 170 /*combined height of various headers*/) {
                        if ((targetTop - $('.reporting_wrapper').scrollTop()) > 150) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 100);
                            $timeout(function() {
                                $scope.smoothScrollToElement(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((targetTop - $('.reporting_wrapper').scrollTop()) > 50) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 50);
                            $timeout(function() {
                                $scope.smoothScrollToElement(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((targetTop - $('.reporting_wrapper').scrollTop()) > 20) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 20);
                            $timeout(function() {
                                $scope.smoothScrollToElement(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((targetTop - $('.reporting_wrapper').scrollTop()) > 5) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 5);
                            $timeout(function() {
                                $scope.smoothScrollToElement(target, lastKnownScrollTop);
                            }, 10);
                        }
                        else if ((targetTop - $('.reporting_wrapper').scrollTop()) > 1) {
                            $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() + 1);
                            $timeout(function() {
                                $scope.smoothScrollToElement(target, lastKnownScrollTop);
                            }, 10);
                        }
                    }
                    else {
                        $scope.finishedDateScrolling = true;
                    }
                }
            }

            $scope.changeTab = function(evt, newtab) {
                evt.preventDefault();
                if (newtab !== $scope.mode) {
                    $scope.scrollBackToTop(newtab);
                }
            }

            $scope.$on('scrollOperationalListToTop', function(e, data) {
                $scope.scrollBackToTop();
                $scope.searchbarId = data.id;
            });

            $scope.scrollBackToTop = function(newtab, savePosition) {
                var scrolltop = $('.reporting_wrapper').scrollTop();

                if (savePosition) {
                    $scope.tabScrollPosition[$scope.mode] = scrolltop;
                }

                if (scrolltop > 0 && scrolltop > 150) {
                    $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() - 100);
                    $timeout(function() {
                        $scope.scrollBackToTop(newtab, false);
                    }, 10);
                }
                else if (scrolltop > 0 && scrolltop > 50) {
                    $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() - 50);
                    $timeout(function() {
                        $scope.scrollBackToTop(newtab, false);
                    }, 10);
                }
                else if (scrolltop > 0 && scrolltop > 20) {
                    $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() - 20);
                    $timeout(function() {
                        $scope.scrollBackToTop(newtab, false);
                    }, 10);
                }
                else if (scrolltop > 0 && scrolltop > 5) {
                    $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() - 5);
                    $timeout(function() {
                        $scope.scrollBackToTop(newtab, false);
                    }, 10);
                }
                else if (scrolltop > 0 && scrolltop > 1) {
                    $('.reporting_wrapper').scrollTop($('.reporting_wrapper').scrollTop() - 1);
                    $timeout(function() {
                        $scope.scrollBackToTop(newtab, false);
                    }, 10);
                }
                else {
                    $scope.operationalTableSpinner = true;
                    if (newtab) {
                        $state.go('loggedin.reporting.operational.stocks',{category: 'stock_control', subcategory: 'returns_operational_list', type: newtab});
                    }
                    else {
                        $rootScope.$broadcast('updateFilters', {id: $scope.searchbarId});
                    }
                }
            };
            $scope.checkTodaysDate = function() {
                angular.forEach($('.operational-header'), function(tableHeader, index) {
                    if ($(tableHeader).find('.due-date').text() == $scope.transformDate($scope.todaysDate)) {
                        $(tableHeader).addClass('todays-date');
                        $scope.smoothScrollToElement($(tableHeader));
                    }
                });
            };

            $scope.$on('reportingInfiniteScroll', function(evt, elem) {
                if ($scope.disableInfiniteScroll[$scope.mode]) {
                    return false;
                }
                $scope.disableInfiniteScroll[$scope.mode] = true;
                // Save the tab state
                $scope.data[$scope.mode] = $scope.rowData;
                var dataExists = false;

                // If there is no data, don't increase the page number
                if ($scope.data[$scope.mode]) {
                    if ($scope.data[$scope.mode]) {
                        $scope.loadingMore = true;
                        $scope.currentPage[$scope.mode] = $scope.currentPage[$scope.mode] + 1;
                    }
                }

                if (typeof $scope.currentFilters == "string") {
                    $scope.getData($scope.currentFilters, true);
                }
                else {
                    $scope.getData(angular.toJson($scope.currentFilters), true);
                }
            });

            $scope.$on('status:toOperationalList', function(evt, data){
                $scope.currentFilters = {};
                angular.forEach(data, function(val, key) {
                    if(val && val.value != undefined) {
                        $scope.currentFilters[val.model_name] = val.value;
                    }
                });
                $scope.getData(angular.toJson($scope.currentFilters), true);
                $rootScope.$broadcast('countActiveFilters', $scope.currentFilters);
            });

            $scope.$on('clearOpertionalFilters', function(evt, data){
                $scope.currentFilters = {};
                $scope.getData(angular.toJson($scope.currentFilters), true);
                $rootScope.$broadcast('countActiveFilters', $scope.currentFilters);
                $rootScope.$broadcast('smarttable_and_sidepanel:filtervalues',true);
            });

            $scope.$on('tagInput:afterUpdateOrRefresh', function(){
              $scope.reachedEndOfParts = $scope.loadingMore = false;
              $scope.disableInfiniteScroll[$scope.mode] = false;
            });

            $scope.getData = function(filters, fetchCounts) {
              $scope.loadingDataLock = true;
              $scope.noRecordFound[$scope.mode] = false;
                $http.get(prefix + '/reporting/operational/stock_control/returns_operational_list/'+$scope.mode+'?page=' + $scope.currentPage[$scope.mode] + '&limit=' + $scope.pageLimit[$scope.mode] + '&filter=' + filters)
                  .then(function(resp) {
                    if(resp.status === 200){
                      $scope.extradata = resp.data['reporting_stock_control_returns_operational_list'].data;

                      if($scope.loadingMore == true){
                          if($scope.extradata.length > 0){
                            $scope.rowData=$scope.rowData.concat($scope.extradata);
                            $scope.disableInfiniteScroll[$scope.mode] = false;
                            $scope.reachedEndOfParts = false;
                          }else{
                              $scope.loadingDataLock = false;
                              $scope.reachedEndOfParts = true;
                             $scope.disableInfiniteScroll[$scope.mode] = true;
                          }
                      }else if($scope.loadingMore == false){
                          $scope.rowData = $scope.extradata;
                          $scope.loadingDataLock = false;
                          $scope.reachedEndOfParts = false;

                      }
                      $scope.loadingMore = false;
                    }
                  });
            };
            $scope.allocateStockReturns = function() {
                $scope.data_to_append = angular.toJson($scope.rowData);
                var side_panel_tpl = $compile("<span id='sidepanel_allocate_stock_returns' sidepanel template='sidepanel_allocate_stock_returns' title='Allocate stock returns' data='{{ data_to_append }}'></span>")($scope);

                angular.element('body').append(side_panel_tpl);
                $timeout(function() {
                    angular.element('#sidepanel_allocate_stock_returns').scope().$$childTail.initialise();
                }, 600);

            }
            //used for removing the sidepanel content from view
            $rootScope.$on('parent:updated', function(evt) {
                if (angular.element('#sidepanel_allocate_stock_returns')) {
                    angular.element('#sidepanel_allocate_stock_returns').remove();
                };
                if (angular.element('#sidepanel_stock_returns_inform_to_supplier')) {
                    angular.element('#sidepanel_stock_returns_inform_to_supplier').remove();
                };
            });
            $scope.openInformToSupplierSidepanel = function(){
                $scope.enableSendEmail = false;
                var selectedRecords = _.filter($scope.rowData, function(num){ return num.selected == true; });
                var uniqueSupplierRecords = _.uniq(selectedRecords, function(data){ return data.supplierId; });
                var countNoEmailSuppliers = _.countBy(uniqueSupplierRecords, function(data){ return data.supplierEmailStatus; });
                $scope.noSupplierEmailCount = (typeof countNoEmailSuppliers[0] != "undefined") ? countNoEmailSuppliers[0] : 0;
                $scope.enableSendEmail = _.contains(_.pluck(selectedRecords,'supplierEmailStatus'),"1");

                $http.get(prefix + '/system_settings/email_message/164')
                    .then(function(resp) {
                        if(resp.status === 200){
                            $scope.data_to_append = angular.toJson(resp.data);
                            var side_panel_tpl = $compile("<span id='sidepanel_stock_returns_inform_to_supplier' sidepanel template='sidepanel_email_message' title='Inform to supplier' data='{{ data_to_append }}'></span>")($scope);

                            angular.element('body').append(side_panel_tpl);
                            $timeout(function() {
                                angular.element('#sidepanel_stock_returns_inform_to_supplier').scope().$$childTail.initialise();
                            }, 600);

                        }
                    });
            }

            //To watch the parts are sleected or not to handle the button
            $scope.$watch("rowData", function() {
                    $scope.partSelected = false;
                    angular.forEach($scope.rowData, function(value,index){
                        if(value['selected'] == true){
                            $scope.partSelected = true;
                            return true;
                        }
                    });
            }, true);

            //when sidepanel is closed updating the sidepanel data and assiging to rows done on controller
            $scope.$on('stock_operational_returns',function(evt,data){
                $scope.unselectAllParts();
                $scope.data[$scope.mode] = $scope.operationalList;
                $scope.currentPage[$scope.mode] = 1;
                $scope.getData($scope.currentFilters, true);
            });
            $scope.$on('sidepanel:process_data', function(evnt,data){
                var emailContent = 'subject='+encodeURIComponent(JSON.stringify(data.subject))+'&message='+encodeURIComponent(JSON.stringify(data.message))+'&returnIds='+$scope.selectedIds;
                $http.post(prefix + '/stocks/returns_inform_to_supplier', emailContent).then(function(resp){
                    if(resp.status == 200){
                        $scope.$emit('sidepanel:close');
                        toastBox.show('Sent successfully', 2000);
                    }else{
                        toastBox.show('Something went wrong', 2000);
                    }
                });
            });
        },
        scope: false
    }
}]);
