commusoftCommon.directive('dashboardWidget', function($document, $timeout, $rootScope) {
    var handleDashboardWidget = function handleDashboardWidget(scope, element, attrs) {
        scope.loading = true;

        var load_duration = Math.floor(Math.random() * 3000) + 400,
            element = element;
        scope.widget = angular.fromJson(attrs.widget);

        $timeout(function() {
            scope.loading = false;
            scope.widget = angular.fromJson(attrs.widget);

            if (scope.widget.expanded_detail) {
                scope.expanded_detail = scope.widget.expanded_detail['default'];
                scope.previous_detail = scope.expanded_detail;
            }

            var widget_inner = element[0].querySelector('.content-wrapper'),
                widget_width = parseFloat(element[0].parentNode.getAttribute("data-size-x")),
                widget_height = parseFloat(element[0].parentNode.getAttribute("data-size-y"));

            widget_inner.classList.add('x-' + widget_width);
            widget_inner.classList.add('y-' + widget_height);

            $rootScope.$on('dashboard_widget:change_detail:' + scope.widget.title, function(event, category) {
                if (scope.widget.expanded_detail) {
                    if (scope.widget.expanded_detail[category]) {
                        scope.$apply(function() {
                            if (category !== 'default') {
                                scope.active_drilldown = category;
                            } else {
                                scope.active_drilldown = false;
                            }
                            scope.previous_detail = scope.expanded_detail;
                            scope.expanded_detail = scope.widget.expanded_detail[category];
                        });
                    } else {
                        scope.$apply(function() {
                            scope.active_drilldown = false;
                            scope.previous_detail = scope.expanded_detail;
                            scope.expanded_detail = scope.widget.expanded_detail['default'];
                        });
                    }
                }
            })
        }, load_duration);

    }

    return {
        restrict: 'A',
        templateUrl: 'template/reporting/dashboard_widget.html',
        scope: {},
        link: handleDashboardWidget
    }
});