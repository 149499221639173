commusoftCommon.directive('toastbox', function($timeout) {
    var handleToastBox = function handleToastBox(scope, element, attrs) {
        scope.message = attrs.message;
        scope.duration_to_show = attrs.duration;
        scope.toast_box_showing = false;
        scope.transition_duration = 300;
        scope.allow_toast_to_hide = true;

        var fadeInToastBox = (function() {
            $timeout(function() {
                scope.toast_box_showing = true;
            }, 100);
        })();

        var fadeOutToastBox = (function() {
            $timeout(function() {
                if(scope.allow_toast_to_hide) {
                    scope.toast_box_showing = false;
                }
            }, scope.duration_to_show);
        })();

        scope.closeToastBox = function closeToastBox() {
            scope.toast_box_showing = false;

            $timeout(function() {
                angular.element(element).remove();
            }, scope.transition_duration);
        }

        scope.keepToastShowing = function keepToastShowing() {
            scope.allow_toast_to_hide = false;
        }

        scope.allowToastToHide = function allowToastToHide() {
            scope.allow_toast_to_hide = true;
            scope.closeToastBox();
        }
    }

    return {
        restrict: 'A',
        scope: {},
        templateUrl: '/template/shared/toast_box.html',
        link: handleToastBox
    }
});