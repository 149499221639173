csmodule.service('spreadSheetHelper', function($document, $http, $timeout, $rootScope, $q, keyBoardKeys, toastBox, spreadSheetData, spreadSheetClickEvents, dateLocale, spreadSheetShortcutRefs, spreadSheetRefsHelper) {
  var self;
  dateLocale.init();

  this.selectCell = function selectCell(context, directive_scope, cell_state) {
    self = this;
    this.removeActiveState(); // remove any active states before handling new ones
    this.headers = directive_scope.spreadsheet_headers;
    this.rows = directive_scope.spreadsheet_rows;
    this.directive_scope = directive_scope;
    this.category = context.category;
    this.CELL = context.cell;
    this.cell_state = cell_state;
    spreadSheetShortcutRefs.initialize(this.rows);
    this.addActiveState();
    this.enter_triggers_edit_mode = true;
    var in_active_mode = this.cell_state === 'active_mode';
    var in_edit_mode = this.cell_state === 'edit_mode';

    if(in_active_mode) {
      this.directive_scope.cell_being_edited = false;
    }

    if(in_edit_mode) {
      this.applyCellChanges('edit_mode');
      this.handleCursorPosition('');
    }

    // un-bind existing keyboard listeners
    $document.unbind('keydown.spreadSheetShortcuts');
    this.registerListeners();
  }

  /*==========================================================================================
    Handle cell states
  ==========================================================================================*/
  this.addActiveState = function addActiveState() {
    var no_previous_cell = this.CELL_VALID === undefined;

    if(no_previous_cell) {
      this.activeStateHelper();
    }else {
      if(this.CELL_VALID) {
        this.activeStateHelper();
      }else {
        // override cell to the old one
        this.CELL = this.INVALID_CELL;
      }

      if(this.directive_scope.server_side_errors_present) {
        this.directive_scope.server_side_errors_present = false;
      }
    }

    $rootScope.$broadcast('milestone:can_add_milestone');
  }

  /*==========================================================================================
    Handle saving
  ==========================================================================================*/
  this.attemptSave = function attemptSave(row) {
    var deferred = $q.defer(),
        row = row || this.rows[this.directive_scope.active_row_axis],
        row_is_valid = this.isRowValid(row.row);

    if(row_is_valid) {
      this.save(row).then(function(saved_row) {
        row.row_valid = true;
        deferred.resolve(saved_row);
      });
    }else {
      row.row_valid = false;
      deferred.reject();
    }

    return deferred.promise;
  }

  this.save = function save(row) {
    var deferred = $q.defer(),
        row = row || this.rows[this.directive_scope.active_row_axis];

    this.directive_scope.save(row).then(function(saved_row) {
      deferred.resolve(saved_row);
    });

    return deferred.promise;
  }

  /*==========================================================================================
    Determine whether a row is valid
  ==========================================================================================*/
  this.isRowValid = function isRowValid(row) {
    var associated_row = row[0].associated_rows !== undefined,
        result;

    this.required_cell_count = 0;
    this.valid_cell_count = 0;

    if(associated_row) {
      result = this.isAssociatedRowValid(row[0].associated_rows);
    }else {
      result = this.isNormalRowValid(row);
    }
    return result;
  }

  /*==========================================================================================
    Check that all the required cells in an associated row are valid
  ==========================================================================================*/
  this.isAssociatedRowValid = function isAssociatedRowValid(associated_row) {
    var result;

    for(var i = 0, l = associated_row.length; i < l; i ++) {
      var row = associated_row[i];
      this.isSingleRowValid(row);
    }

    (this.required_cell_count === this.valid_cell_count) ? result = true : result = false;
    return result;
  }

  /*==========================================================================================
    Check that all the required cells in a normal row are valid
  ==========================================================================================*/
  this.isNormalRowValid = function isNormalRowValid(row) {
    var result;
    this.isSingleRowValid(row);

    (this.required_cell_count === this.valid_cell_count) ? result = true : result = false;
    return result;
  }

  /*==========================================================================================
    Check that a row can be deemed as valid
  ==========================================================================================*/
  this.isSingleRowValid = function isSingleRowValid(row) {
    for(var i = 0, l = row.length; i < l; i++) {
      var cell = row[i];
          cell_is_required = cell.required === undefined && cell.state !== 'not_selectable';

      if(cell_is_required) {
        this.required_cell_count++;

        if(cell.valid) {
          this.valid_cell_count++;
          // remove the invalid_reason attribute as cell is now valid
          if(cell.invalid_reason) {
            delete cell.invalid_reason;
          }
        }
      }
    }
  }

  /*==========================================================================================
    Handle when a row is deleted and a cell init is active / being edited
  ==========================================================================================*/
  this.deleteInterceptor = function deleteInterceptor(row_index) {
    var cell_exists = this.CELL !== undefined;

    if(cell_exists) {
      var active_cell_in_row_to_delete = row_index === this.directive_scope.active_row_axis;

      if(active_cell_in_row_to_delete) {
        this.removeAllInstanceVariables();
      }
    }
  }

  this.activeStateHelper = function activeStateHelper() {
    var cell_exists = this.CELL !== undefined;

    if(cell_exists) {
      this.CELL.state = this.cell_state;
      this.handleGridAxis();
      $rootScope.sFocussesAppSearch = false;
      spreadSheetClickEvents.init(this);
    }
  }

  this.removeActiveState = function removeActiveState() {
    var no_active_cell = this.CELL === undefined;

    if(no_active_cell) {
      return;
    }else {
      this.handleCellValidation();
      var invalid_cell = this.CELL_VALID === false;

      if(invalid_cell) {
        this.handleInvalidCell();
      }else {
        if(this.directive_scope.supports_totals) {
          this.handleCalculations();
        }
        this.removeActiveStateHelper();
      }
    }
  }

  this.removeActiveStateHelper = function removeActiveStateHelper() {
    this.CELL.state = null;
    this.directive_scope.active_row_axis = null;
    this.directive_scope.active_col_axis = null;
  }

  this.setNewActiveCell = function setNewActiveCell(cell) {
    spreadSheetClickEvents.init(this);
    this.removeActiveState();
    this.CELL = spreadSheetRefsHelper.getCellFromStrIndex(this.rows, cell);
    spreadSheetShortcutRefs.initialize(this.rows);
    this.applyCellChanges('active_mode');
    this.handleGridAxis();
  }

  /*==========================================================================================
    Remove cell active state and all instance variables ~ used when esc is pressed when a cell
    is in active mode, and when add milestone panel / new pricing category panel etc.. is open
  ==========================================================================================*/
  this.removeAllInstanceVariables = function removeAllInstanceVariables() {
    var cell_exists = this.CELL !== undefined;

    if(cell_exists) {
      // If the cell is an empty required number cell then set the value to 0.
      if(this.CELL.input_type === "time" && this.CELL.value.length < 1 && this.CELL.required !== false) {
        this.CELL.value = 0;
      }

      this.CELL.state = null;
      this.CELL = undefined;
      this.CELL_VALID = true;
      this.INVALID_CELL = null;
      this.directive_scope.active_row_axis = null;
      this.directive_scope.active_col_axis = null;
      this.directive_scope.cell_being_edited = false;

      // only call apply if apply not in progess
      if(this.directive_scope.$root.$$phase != '$apply' &&
        this.directive_scope.$root.$$phase != '$digest') {
        this.directive_scope.$apply();
      }

      $document.unbind('keydown.spreadSheetShortcuts');
    }
  }

  /*==========================================================================================
    Update axis active states
  ==========================================================================================*/
  this.handleGridAxis = function handleGridAxis() {
    var cell_exists = this.CELL !== undefined;

    if(cell_exists) {
      var associated_str = /associated_rows/,
          number_pattern = /\d+/g,
          cell_is_associated = associated_str.test(this.CELL.index_ref),
          number_matches = this.CELL.index_ref.match(number_pattern);

      this.directive_scope.active_row_axis = parseInt(number_matches[0]);

      if(cell_is_associated) {
        this.directive_scope.active_col_axis = parseInt(number_matches[3]);
      }else {
        this.directive_scope.active_col_axis = parseInt(number_matches[1]);
      }
    }
  }

  this.setHelperCellContext = function setHelperCellContext(cell) {
    this.CELL = cell;
  }

  /*==========================================================================================
    Update the cell values that are concerned with which mode a cell is in,
    i.e active_mode || edit_mode
  ==========================================================================================*/
  this.applyCellChanges = function applyCellChanges(mode) {
    var cell_exists = this.CELL !== undefined;

    if(cell_exists) {
      var is_active_mode = mode === 'active_mode',
          is_edit_mode = mode === 'edit_mode',
          input_is_auto_complete = this.CELL.input_type === 'auto_complete',
          can_add_new_rows = this.directive_scope.can_add_new_rows;

      if(is_active_mode) {
        this.handleCellValidation();

        if(this.CELL_VALID) {
          if(this.CELL.used_in_calculation) {
            this.handleCalculations();
          }

          if(this.directive_scope.supports_merge_rows) {
            console.log("Need to disable the row if it's vat rate is different from the one in spreadsheetMergeRowHelper");
          }

          this.cell_state = mode;
          this.CELL.state = mode;
        }else {
          this.handleInvalidCell();
        }
        $rootScope.$emit('spreadsheet_cell:in_active_mode');
      }

      if(is_edit_mode) {
        this.cell_state = mode;
        this.CELL.state = mode;
        $rootScope.$emit('spreadsheet_cell:in_edit_mode');
      }

      if(input_is_auto_complete) {
        if(is_edit_mode) {
          // timeout needed to allow the enter shortcut to happen before changing variable
          $timeout(function() {
            self.enter_triggers_edit_mode = false;
          }, 10);
        }else {
          $timeout(function() {
            self.enter_triggers_edit_mode = true;
          }, 10);
        }
      }

      if(can_add_new_rows) {
        this.handleLastRow();
      }
    }
  }

  /*==========================================================================================
    Check if the cell value is valid based on input type
  ==========================================================================================*/
  this.handleCellValidation = function handleCellValidation() {
    var cell_is_required = this.CELL.required !== false,
        in_edit_mode = this.CELL.state === 'edit_mode';

    if(in_edit_mode) {
      if(cell_is_required) {
        var number_input = this.CELL.input_type === 'time',
            text_input = this.CELL.input_type === 'text',
            date_input = this.CELL.input_type === 'date',
            auto_complete_input = this.CELL.input_type === 'auto_complete',
            cell_needs_validation = this.isCellInLastRow() === false ||
                                    this.directive_scope.can_add_new_rows === false;

        if(cell_needs_validation) {
          if(text_input) {
            this.handleTextValidation();
          }
          if(number_input) {
            var percentage_str = /%/,
                input_is_percentage = percentage_str.test(this.CELL.value);

            if(input_is_percentage) {
              this.handlePercentageValidation();
            }else {
              this.handleNumberValidation();
            }

            // specific to invoice schedule spreadsheet
            if(this.directive_scope.invoice_schedule_spreadsheet) {
              this.directive_scope.handleInvoiceScheduleAmounts(this.directive_scope.active_row_axis, this.CELL.value, input_is_percentage);
            }
          }
          if(date_input) {
            this.handleDateValidation();
          }

          if(auto_complete_input) {
            this.handleAutoCompleteValidation();
          }

          if(this.CELL_VALID) {
            this.CELL.valid = true;
          }else {
            this.CELL.valid = false;
          }
        }else {
          this.CELL.state = null;
        }
      }else {
        this.CELL_VALID = true;
      }

      this.handleGridAxis();

      if(this.directive_scope.supports_auto_save) {
        this.attemptSave();
      } else {

        // Initiated the categoryId for customer invoice spreadsheet screen.
        var categoryId = '';
        if(typeof this.directive_scope.categoryId != 'undefined') {
          categoryId = this.directive_scope.categoryId;
        }
        var row = this.rows[this.directive_scope.active_row_axis],
            row_is_valid = this.isRowValid(row.row);

        if(row_is_valid) {
          var spread_sheet_data = {categoryId: categoryId, category: this.directive_scope.category, rows: angular.copy(this.directive_scope.spreadsheet_rows)};
          this.directive_scope.$emit('event:save-spreadsheet-data', spread_sheet_data);
        } else {
          // This part used to disable the save button for customer invoice spreadsheet screen.
          var is_valid = {type: this.directive_scope.category, isValid: false};
          this.directive_scope.$emit('validate:save-spreadsheet-data', is_valid);
        }
        this.directive_scope.handleRowCount();
      }
    }
  }

  /*==========================================================================================
    Validate text input
  ==========================================================================================*/
  this.handleTextValidation = function handleTextValidation() {
    this.CELL_VALID = this.CELL.value.length > 0;

    if(this.CELL_VALID === false) {
      this.INVALID_CELL = this.CELL;
    }else {
      this.INVALID_CELL = null;
    }
  }

  /*==========================================================================================
    Validate date input
  ==========================================================================================*/
  this.handleDateValidation = function handleDateValidation() {
    var date_is_valid = dateLocale.isDateValid(this.CELL.value);

    if(date_is_valid){
      if (moment(this.CELL.value, $rootScope.date_long_format, $rootScope.date_locale, true).isValid()) {
       this.CELL.value = moment(this.CELL.value, $rootScope.date_long_format, $rootScope.date, true).format($rootScope.date_long_format);
      } else if (moment(this.CELL.value, $rootScope.date_short_format, $rootScope.date_locale, true).isValid()) {
       this.CELL.value = moment(this.CELL.value, $rootScope.date_short_format, $rootScope.date, true).format($rootScope.date_long_format);
      }
      this.CELL_VALID = true;
      this.INVALID_CELL = null;
    }else{
      this.CELL_VALID = false;
      this.INVALID_CELL = this.CELL;
    }
  }

  /*==========================================================================================
    Validate number input
  ==========================================================================================*/
  this.handleNumberValidation = function handleNumberValidation() {
    this.CELL_VALID = parseInt(this.CELL.value);

    if(isNaN(this.CELL_VALID)) {
      this.CELL_VALID = false;
    }else {
      this.CELL_VALID = true;
    }

    if(this.CELL_VALID === false) {
      this.INVALID_CELL = this.CELL;
    }else {
      this.INVALID_CELL = null;

      var number_needs_rounding = this.CELL.value % 1 !== 0;

      if(number_needs_rounding) {
        this.CELL.value = parseFloat(this.CELL.value);
      }else {
        this.CELL.value = parseInt(this.CELL.value);
      }
    }
  }

  /*==========================================================================================
    Validate percentage input
  ==========================================================================================*/
  this.handlePercentageValidation = function handlePercentageValidation() {
    if(this.directive_scope.supports_percentage_inputs) {
      var last_character = this.CELL.value.substring(this.CELL.value.length - 1);
          ends_with_percentage = last_character === '%',
          allows_percentage = this.CELL.allows_percentage !== false;

      if(allows_percentage && ends_with_percentage) {
        // check if only contains numbers
        var chars_before_percentage = this.CELL.value.substring(0, this.CELL.value.length - 1),
            numbers_regex = /^[0-9]*(?:\.\d{1,2})?$/,
            valid_percentage = numbers_regex.test(chars_before_percentage);

        if(valid_percentage) {
          this.CELL_VALID = true;
        }else {
          this.CELL_VALID = false;
          this.INVALID_CELL = this.CELL;
        }
      }else {
        this.CELL_VALID = false;
        this.INVALID_CELL = this.CELL;
      }
    }else {
      this.CELL_VALID = false;
      this.INVALID_CELL = this.CELL;
    }
  }

  /*==========================================================================================
    Validate autocomplete input
  ==========================================================================================*/
  this.handleAutoCompleteValidation = function handleAutoCompleteValidation() {
    var input_contains_text = this.CELL.value.length > 0;

    if(input_contains_text) {
      // this.directive_scope.auto_complete_options contains objects in COM, whereas in CUI it contains strings, hense the pluck.
      var options = _.pluck(this.directive_scope.auto_complete_options, "description"),
          options_loaded = options !== undefined,
          option_found;

      if(options_loaded) {
        if(options !== null) {
          option_found = options.indexOf(self.CELL.value) > -1;
        }

        if(option_found) {
          this.CELL_VALID = true;
          this.INVALID_CELL = null;
        }else {
          this.CELL_VALID = false;
          this.INVALID_CELL = this.CELL;
        }
      }else {
        this.CELL_VALID = true;
        this.INVALID_CELL = null;
      }
    }else {
      this.CELL_VALID = false;
      this.INVALID_CELL = this.CELL;
    }
  }

  /*==========================================================================================
    If cell validation fails then reflect error in the UI
  ==========================================================================================*/
  this.handleInvalidCell = function handleInvalidCell() {
    var cell_elm = document.querySelector('.edit-mode'),
        cell_elm_exists = cell_elm !== null;

    if(cell_elm_exists) {
      var column_name = this.directive_scope.spreadsheet_headers[this.directive_scope.active_col_axis].value.toLocaleLowerCase(),
          row_number = (this.directive_scope.active_row_axis + 1),
          toast_box_message = 'In-valid ' + '&#34;' + column_name + '&#34;' + ' in row ' + row_number;

      toastBox.show(toast_box_message, 2000);
      cell_elm.classList.add('invalid-cell-value');

      $timeout(function() {
        var cell_elm = document.querySelector('.edit-mode'),
            cell_elm_exists = cell_elm !== null;

        if(cell_elm_exists) {
          cell_elm.classList.remove('invalid-cell-value');
          $rootScope.$emit('spreadsheet_cell:in_edit_mode');
          spreadSheetClickEvents.init(self);
        }
      }, 400);
    }
  }

  /*==========================================================================================
    If the cell below belongs in the same associated row then set it to edit mode when current
    row has finished being updated
  ==========================================================================================*/
  this.jumpDownToCellBelow = function jumpDownToCellBelow() {
    var cell_below = this.CELL.down_ref !== 'NA';

    if(cell_below) {
      if(this.CELL_VALID) {
        var cell_index_to_focus = this.CELL.down_ref;
        this.handleStagnantErrorState();
        this.enter_triggers_edit_mode = true;
        // use the remove active state helper as when .removeActiveState gets called in the
        // setNewActiveCell function this.CELL is out of sync as this method gets called in
        // a timeout (as we need to wait for the DOM to update)
        this.removeActiveStateHelper();
        this.setNewActiveCell(cell_index_to_focus);
      }
    }
  }

  this.jumpRightToCell = function jumpRightToCell() {
    var cell_right = this.CELL.right_ref !== 'NA';
    if(cell_right) {
      if(this.CELL_VALID) {
        var cell_index_to_focus = this.CELL.right_ref;
        this.handleStagnantErrorState();
        this.enter_triggers_edit_mode = true;
        this.setNewActiveCell(cell_index_to_focus);
      }
    }
  }

  /*==========================================================================================
    When a cell in the last row is in edit mode add in another empty row below
  ==========================================================================================*/
  this.handleLastRow = function handleLastRow() {
    var cell_in_last_row = this.isCellInLastRow();

    if(cell_in_last_row) {
      var new_row = spreadSheetData.makeNewRow(this.directive_scope.spreadsheet_data, this.category);
      this.rows.push(new_row[0]);
      spreadSheetShortcutRefs.initialize(this.rows);
    }
  }

  this.isCellInLastRow = function isCellInLastRow() {
    var number_pattern = /\d+/g,
        number_matches = this.CELL.index_ref.match(number_pattern),
        current_row_index = number_matches[0],
        cell_in_last_row = this.rows.length - 1 === parseInt(current_row_index);

    return cell_in_last_row;
  }

  /*==========================================================================================
    Focus input in edit mode
    - inputs ids are namespaced with their type along with their co-ordinates in the spreadsheet
    - if the input is auto complete then show the dropdown.
  ==========================================================================================*/
  $rootScope.$on('spreadsheet_cell:in_edit_mode', function() {
    var input_is_auto_complete = self.CELL.input_type === 'auto_complete';

    $timeout(function() {
      var cell_exists = self.CELL !== undefined;

      if(cell_exists) {
        var selector = '#' + self.CELL.input_type + self.CELL.index_ref.replace(/[^\w\s]/gi, ''),
            element = self.directive_scope.element[0].querySelector(selector),
            element_found = element !== null;

        if(element_found) {
          element.focus();

          var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

          if(is_firefox) {
            var val = element.value;
            element.value = ''; //clear the value of the element
            element.value = val; //set that value back.
          }
        }

        if(input_is_auto_complete && element_found) {
          var dropdown = element.nextElementSibling,
              dropdown_exists = dropdown !== null;

          if(dropdown_exists) {
            dropdown.classList.remove('ng-hide');
          }
        }
      }
    }, 200);
  });

  /*==========================================================================================
    Keyboard Events
  ==========================================================================================*/
  this.registerListeners = function registerListeners() {
    var previous_key;

    $document.bind('keydown.spreadSheetShortcuts', function(evt) {
      var cell_exists = self.CELL !== undefined;

      if(cell_exists) {
        var key = evt.keyCode,
            up = key == 38,
            down = key == 40,
            left = key == 37,
            right = key == 39,
            enter = key == 13,
            esc = key == 27,
            tab = key == 9,
            alphanumeric = keyBoardKeys.isAlphaNumeric(key),
            shift = 16,
            in_edit_mode = self.cell_state === 'edit_mode',
            in_active_mode = self.cell_state === 'active_mode',
            quick_edit_activated = false,
            enter_in_edit_goes_down = self.enter_in_edit_goes_down ||
                                      self.enter_in_edit_goes_down === undefined;

        /*==========================================================================================
          Handle keys such as esc, enter and alphanumeric characters
        ==========================================================================================*/

        /*if(enter) {
          // Prevent form submitting when spreadsheets in a form.
          evt.preventDefault();
        }*/
        if(esc) {
          if(in_active_mode) {
            var instance_exists = self !== undefined;
            if(instance_exists) {
              if(self.CELL_VALID) {
                self.removeAllInstanceVariables();
              }else {
                self.handleCellValidation();
              }
            }
          }else {
            self.applyCellChanges('active_mode');
          }
        }
        if(enter && in_edit_mode) {
          if(enter_in_edit_goes_down) {
            self.handleEnterShortcut();
            quick_edit_activated = true;
          }
        }
        if(enter && self.enter_triggers_edit_mode) {
          if(in_active_mode) {
            self.handleCursorPosition('');
            self.applyCellChanges('edit_mode');
          }else if(in_edit_mode) {
            self.applyCellChanges('active_mode');
          }
        }else if(enter && !self.enter_triggers_edit_mode) {
          if(in_edit_mode) {
            self.applyCellChanges('active_mode');
          }
          if(!quick_edit_activated) {
            if(enter_in_edit_goes_down) {
              self.handleEnterShortcut();
            }
          }
        }
        if(alphanumeric) {
          var shift_pressed_before = previous_key === shift;
          if(in_active_mode) {
            self.replaceCellContent(alphanumeric, shift_pressed_before);
            self.applyCellChanges('edit_mode');
          }
        }
        if(tab) {
          evt.preventDefault();
        }

        /*==========================================================================================
          Handle keys for navigating around the spreadsheet
        ==========================================================================================*/

        // check if cell in active mode again as it can change since it was last stored
        var still_in_active_mode = self.cell_state === 'active_mode';

        if(still_in_active_mode) {
          // need to check that cell still exists again as user can hit 'esc' since previous check
          cell_exists = self.CELL !== undefined;

          if(cell_exists) {
            self.handleShortcut(up, down, left, right);
          }
        }

        /*==========================================================================================
          Store the previous key to handle keyboard combinations
        ==========================================================================================*/

        var multiple_shift_pressed = key === shift && previous_key === shift;

        if(multiple_shift_pressed) {
          previous_key = null;
        }else {
          previous_key = key;
        }
      }
    });
  }

  /*==========================================================================================
    If the category is todos 'enter' should either go down and left or down if the cell
    is in an associated row and isn't the last cell in the associated row
  ==========================================================================================*/
  this.handleEnterShortcut = function handleEnterShortcut() {
    var could_jump_down = this.category === 'todos' ||
                          this.category === 'jobtodos' ||
                          this.category === 'jobtodosnewmilestone' ||
                          this.category === 'milestone' ||
                          this.category === 'new_purchase_order_parts' ||
                          this.category === 'edit_purchase_order_parts' ||
                          this.category === 'new_job_purchase_order_parts' ||
                          this.category === 'complete_purchase_order_parts' ||
                          this.category === 'new_purchase_order_items' ||
                          this.category === 'edit_purchase_order_items' ||
                          this.category === 'complete_purchase_order_items' ||
                          this.category === 'new_supplier_invoice_parts' ||
                          this.category === 'edit_supplier_invoice_parts' ||
                          this.category === 'edit_supplier_invoice_items' ||
                          this.category === 'new_supplier_credit_note_parts' ||
                          this.category === 'new_supplier_credit_note_items' ||
                          this.category === 'edit_supplier_credit_note_parts' ||
                          this.category === 'edit_supplier_credit_note_items' ||
                          this.category === 'new_supplier_invoice_items' ||
                          this.category === 'new_supplier_cash_allocation_invoices' ||
                          this.category === 'new_supplier_credit_allocation' ||
                          this.category === 'new_supplier_invoice_payment' ||
                          this.category === 'edit_supplier_invoice_payment';

    $timeout(function() {
      var fullfill_shortcut = self.directive_scope.server_side_errors_present === false;

      if(fullfill_shortcut) {
        if(could_jump_down) {
          var direction_to_go = self.calcJumpDirection(),
              jump_right = direction_to_go === 'right',
              jump_down = direction_to_go === 'down';

          if(jump_right) {
            self.jumpRightToCell();
          }else if(jump_down) {
            self.jumpDownToCellBelow();
          }
        }else {
          self.jumpRightToCell();
        }
      }
    }, 10);
  }

  /*==========================================================================================
    If cell is the last cell in the associated row then direction should be right
    otherwise check if the cell below is selectable, if it is the direction should be down
    if not the direction should be right
  ==========================================================================================*/
  this.calcJumpDirection = function calcJumpDirection() {
    var cell_index_ref = self.CELL.index_ref,
        associated_str = /associated_rows/,
        cell_is_associated = associated_str.test(cell_index_ref),
        number_pattern = /\d+/g,
        number_matches = cell_index_ref.match(number_pattern),
        direction;

    if(cell_is_associated) {
      var associated_row_length = this.rows[number_matches[0]].row[0].associated_rows.length;
          last_associated_cell = (associated_row_length - 1) === parseInt(number_matches[2]);

      if(last_associated_cell) {
        direction = 'right';
      }else {
        var row = number_matches[0],
            col = number_matches[1],
            associated_row = parseInt(number_matches[2])+1,
            associated_col = number_matches[3],
            cell_below_is_selectable = this.rows[row].row[col].associated_rows[associated_row][associated_col].state !== 'not_selectable';

        if(cell_below_is_selectable){
          direction = 'down';
        }else{
          direction = 'right';
        }
      }
    }else {
      direction = 'right';
    }

    return direction;
  }

  /*==========================================================================================
    Handle cursor position in input when a cell goes into edit mode
  ==========================================================================================*/
  this.handleCursorPosition = function handleCursorPosition(appended_value) {
    var existing_cell_value = this.CELL.value.length > 0;

    if(existing_cell_value) {
      var last_character = this.CELL.value.substring(this.CELL.value.length - 1),
          add_empty_space = last_character !== ' ' &&
                            this.CELL.input_type !== 'auto_complete' &&
                            this.CELL.input_type !== 'date';

      if(add_empty_space) {
        this.CELL.value += ' ' + appended_value;
      }else {
        this.CELL.value += appended_value;
      }
    }else {
      this.CELL.value += appended_value;
    }
  }

  /*==========================================================================================
    Replace cell value with character
  ==========================================================================================*/
  this.replaceCellContent = function replaceCellContent(character, capitalise_first_char) {
    if(capitalise_first_char) {
      this.CELL.value = character.toUpperCase();
    }else {
      this.CELL.value = character;
    }
  }

  /*==========================================================================================
    When a directional keyboard shortcut is pressed then remove active state from the current
    cell and add active state to new CELL
  ==========================================================================================*/
  this.handleShortcut = function handleShortcut(up, down, left, right) {
    var shortcut_valid = false,
        directional_shortcut = up || down || left || right,
        cell_in_active_mode = this.CELL.state === 'active_mode';

    if(cell_in_active_mode) {
      self.removeInvalidCSSClass();
      if(up) {
        if(this.CELL.up_ref !== 'NA') {
          self.removeActiveState();
          this.CELL = spreadSheetRefsHelper.getCellFromStrIndex(this.rows, this.CELL.up_ref);
          shortcut_valid = true;
        }
      }else if(down) {
        if(this.CELL.down_ref !== 'NA') {
          self.removeActiveState();
          this.CELL = spreadSheetRefsHelper.getCellFromStrIndex(this.rows, this.CELL.down_ref);
          shortcut_valid = true;
        }
      }else if(left) {
        if(this.CELL.left_ref !== 'NA') {
          self.removeActiveState();
          this.CELL = spreadSheetRefsHelper.getCellFromStrIndex(this.rows, this.CELL.left_ref);
          shortcut_valid = true;
        }
      }else if(right) {
        if(this.CELL.right_ref !== 'NA') {
          self.removeActiveState();
          this.CELL = spreadSheetRefsHelper.getCellFromStrIndex(this.rows, this.CELL.right_ref);
          shortcut_valid = true;
        }
      }

      if(shortcut_valid) {
        self.addActiveState();
        this.checkIfCellInViewPort();
      }else if(!shortcut_valid && directional_shortcut) {
        var cell_elm = document.querySelector('.active-mode'),
            cell_elm_exists = cell_elm !== null,
            class_to_add;

        if(up) {
          class_to_add = 'invalid-up';
        }else if(down) {
          class_to_add = 'invalid-down';
        }else if(left) {
          class_to_add = 'invalid-left';
        }else if(right) {
          class_to_add = 'invalid-right';
        }

        if(cell_elm_exists) {
          cell_elm.classList.add(class_to_add);
        }

        $timeout(function() {
          self.removeInvalidCSSClass();
        }, 200);
      }
    }
  }

  /*==========================================================================================
    Remove the css classes related to invalid shortcuts
  ==========================================================================================*/
  this.removeInvalidCSSClass = function removeInvalidCSSClass() {
    var cell_elm = document.querySelector('.active-mode'),
        active_cell_exists = cell_elm !== null;

    if(active_cell_exists) {
      cell_elm.classList.remove('invalid-up');
      cell_elm.classList.remove('invalid-down');
      cell_elm.classList.remove('invalid-left');
      cell_elm.classList.remove('invalid-right');
    }
  }

  /*==========================================================================================
    Check if the shortut has moved the active cell out of the window viewport
  ==========================================================================================*/
  this.checkIfCellInViewPort = function checkIfCellInViewPort() {
    var cell_elm = document.querySelector('.active-mode') || document.querySelector('.edit-mode'),
        cell_elm_exists = cell_elm !== null;

    if(cell_elm_exists) {
      var cell_below_fold = (window.innerHeight - cell_elm.getBoundingClientRect().top) < 120,
          cell_above_fold = cell_elm.getBoundingClientRect().top < 200;
          container = document.querySelector('.commusoft_scroll_wrapper');

      if(cell_below_fold) {
        this.handleCellBelowFold(container);
      }
      else if(cell_above_fold) {
        this.handleCellAboveFold(container);
      }
    }
  }

  /*==========================================================================================
    Scroll down to new cell
  ==========================================================================================*/
  this.handleCellBelowFold = function handleCellBelowFold(container) {
    // allow time for the new cell to be given the active-mode class
    $timeout(function() {
      var cell_elm = document.querySelector('.active-mode');
      container.scrollTop = container.scrollTop + cell_elm.getBoundingClientRect().top;
    }, 100);
  }

  /*==========================================================================================
    Scroll up to new cell
  ==========================================================================================*/
  this.handleCellAboveFold = function handleCellAboveFold(container) {
    var cell_elm = document.querySelector('.active-mode');
    container.scrollTop = container.scrollTop - cell_elm.getBoundingClientRect().top;
  }

  this.handleAutoComplete = function handleAutoComplete() {
    $timeout(function() {
      self.applyCellChanges('active_mode');
    }, 100);
  }

  /*==========================================================================================
    Set the value of auto complete cell
  ==========================================================================================*/
  this.updateAutocompleteCell = function updateAutocompleteCell(value, indexVal) {
    this.handleStagnantErrorState();
    this.CELL.value = value;

    if(indexVal) {
      this.CELL.primaryid_value = indexVal;
    }
  }

  /*==========================================================================================
    Autocompletes in associated rows get red border has the validaiton fails before the
    item is selected
  ==========================================================================================*/
  this.handleStagnantErrorState = function handleStagnantErrorState() {
    var stagnant_invalid_ui = document.querySelector('.invalid-cell-value'),
        stagnant_invalid_ui_exists = stagnant_invalid_ui !== null;

    if(stagnant_invalid_ui_exists) {
      stagnant_invalid_ui.classList.remove('invalid-cell-value');
    }
  }

  /*==========================================================================================
    Update rows and index refs when data has changed
  ==========================================================================================*/
  $rootScope.$on('spreadsheet_data:saved', function(e, rows) {
    var shortcuts_registered = self !== undefined;
    if(shortcuts_registered) {
      self.rows = rows;
      spreadSheetShortcutRefs.initialize(this.rows);
      self.handleGridAxis();
    }
  });

  /*==========================================================================================
    Calculations
  ==========================================================================================*/
  this.handleCalculations = function handleCalculations() {
    var associated_str = /associated_rows/,
        cell_is_associated = associated_str.test(this.CELL.index_ref),
        row_index,
        row,
        self = this;

    if(cell_is_associated) {
      row_index = this.CELL.index_ref.substring(0, this.CELL.index_ref.lastIndexOf('['));
    }else {
      row_index = this.CELL.index_ref.substring(0, this.CELL.index_ref.indexOf(']') + 1);
    }

    row = spreadSheetRefsHelper.getRowFromStrIndex(this.rows, row_index);

    // timeout needed because we have to wait for autocomplete value to be set in the scope before calculating
    $timeout(function(){
      for(var i = 0, l = row.length; i < l; i++) {
        var cell_value_is_calculated = row[i].calculation !== undefined;

        if(cell_value_is_calculated) {
          self.handleCellCalculation(row, row[i]);
        }
      }
      self.directive_scope.generateSubTotals();
      self.directive_scope.updateGrandTotals();
    },100);
  }

  /*==========================================================================================
    Check to see what type of calculation is required and then perform the calculation if all
    of the values in it exist
  ==========================================================================================*/
  this.handleCellCalculation = function handleCellCalculation(row, cell, scope_helper) {
    /*==========================================================================================
      The scope helper is used when invoking handleCellCalculation from outside of the
      spreadsheet ~ used in customer invoicing when removing discounts and commissions
    ==========================================================================================*/
    if (scope_helper !== undefined) {
      this.directive_scope = scope_helper;
    }

    var multiply_str = /multiply/,
        profit_str = /profit/,
        tax_str = /tax/,
        addition_str = /addition/,
        subtract_str = /subtract/,
        itself_str = /itself/,
        discount_str = /discount/,
        commission_str = /commission/,
        number_pattern = /\d+/g,
        multiply = multiply_str.test(cell.calculation),
        profit = profit_str.test(cell.calculation),
        tax = tax_str.test(cell.calculation),
        discount = discount_str.test(cell.calculation),
        commission = commission_str.test(cell.calculation),
        addition = addition_str.test(cell.calculation),
        subtract = subtract_str.test(cell.calculation),
        calculation_uses_col_indexes = !itself_str.test(cell.calculation) &&
                                       !discount_str.test(cell.calculation) &&
                                       !commission_str.test(cell.calculation),
        calculation_is_conditional = cell.calculation_condition_cell !== undefined,
        calculation_should_be_made = true;

    // Check if the calculation relies on another cells property
    if (calculation_is_conditional) {
      calculation_should_be_made = this.checkIfCalculationShouldBeMade(row, cell);
    }

    if (calculation_should_be_made === true) {
      // This check is used when a cell does not need to be calculated but does need to be pushed
      // into grand totals calculation_uses_col_index is checking whether the cell needs to be
      // calculated. If it doesn't then the function pushes the RAW value of the cell into grand totals
      if(calculation_uses_col_indexes) {
        var cell_indexes = cell.calculation.match(number_pattern),
            cell_one = row[cell_indexes[0]],
            cell_two = row[cell_indexes[1]],
            third_number_exists = row[cell_indexes[2]] !== undefined,
            all_values_present;

        if(third_number_exists) {
          var cell_three = row[cell_indexes[2]];
          all_values_present = _.isNumber(parseInt(cell_one.value)) &&
                               _.isNumber(parseInt(cell_two.value)) &&
                               _.isNumber(parseInt(cell_three.value));
        }else {
          all_values_present = _.isNumber(parseInt(cell_one.value)) &&
                               _.isNumber(parseInt(cell_two.value));
        }

        if(all_values_present) {
          if (cell.bypass_calculation !== true) {
            if(multiply) {
              this.calculateMultiplication(parseFloat(cell_one.value), parseFloat(cell_two.value), cell);
            }
            else if(tax) {
              if(third_number_exists) {
                this.calculateTax(parseFloat(cell_one.value), parseFloat(cell_two.value), parseFloat(cell_three.value), cell);
              }else {
                this.calculateTax(1, parseFloat(cell_one.value), parseFloat(cell_two.value), cell);
              }
            }
            else if(profit) {
              this.calculateProfit(parseFloat(cell_one.value), parseFloat(cell_two.value), cell);
            }
            else if(addition) {
              this.calculateAddition(parseFloat(cell_one.value), parseFloat(cell_two.value), cell);
            }
            else if(subtract) {
              this.calculateSubtract(parseFloat(cell_one.value), parseFloat(cell_two.value), cell);
            }
          }
        }
      } else if (discount && !tax) {
        var cell_index = cell.calculation.match(number_pattern)[0],
            amount_to_calculate_discount_from = row[cell_index].value,
            discount_percentage = this.directive_scope.discount_percentage,
            discount_value = (amount_to_calculate_discount_from / 100) * discount_percentage;

        cell.value = discount_value;
      } else if (commission && !tax) {
        var cell_index = cell.calculation.match(number_pattern)[0],
            amount_to_calculate_commission_from = row[cell_index].value,
            commission_percentage = this.directive_scope.commission_percentage,
            commission_value = (amount_to_calculate_commission_from / 100) * commission_percentage;

        cell.value = commission_value;
      } else if (!itself_str.test(cell.calculation)) {
        cell.value = 0;
      }
    } else {
      cell.value = 0;
    }
  }

  // Check if the calculation relies on another cells property
  // ~ this is used in jobs new invoice with CIS calculation
  this.checkIfCalculationShouldBeMade = function checkIfCalculationShouldBeMade(row, cell) {
    var index_of_cell_to_check = parseInt(cell.calculation_condition_cell),
        cell_to_check = row[index_of_cell_to_check],
        value_of_cell_to_check = cell.calculation_condition_value;

    if (cell_to_check.value === value_of_cell_to_check) {
      return true
    } else {
      return false
    }
  }

  this.calculateMultiplication = function calculateMultiplication(cell_one, cell_two, cell) {
    var cell_one_val = parseFloat(cell_one.toFixed(2)),
        cell_two_val = parseFloat(cell_two.toFixed(2));
    var amount = cell_one_val * cell_two_val,
        amount_needs_rounding = amount % 1 !== 0;

    if(amount_needs_rounding) {
      cell.value =  parseFloat(amount.toFixed(2));
    }else {
      cell.value = parseInt(amount);
    }
  }

  this.calculateTax = function calculateTax(quantity, cost, tax_rate, cell) {
    var quantity_val = parseFloat(quantity.toFixed(2)),
        cost_val = parseFloat(cost.toFixed(2));
    var tax = ((quantity_val * cost_val) * tax_rate) / 100,
        tax_needs_rounding = tax % 1 !== 0;
        invalid_tax = _.isNaN(tax) ||
                            tax === Infinity ||
                            tax === -Infinity;

    if(invalid_tax) {
      tax = '';
    }else if(tax_needs_rounding) {
      //tax = parseFloat(tax.toFixed(2));
      tax = (Math.round( tax * 100 ) / 100).toFixed(2);
    }else {
      tax = parseInt(tax);
    }

    cell.value = tax;
  }

  this.calculateProfit = function calculateProfit(cell_one, cell_two, cell) {
    var amount = cell_two - cell_one,
                 percentage = 100 - (cell_one / cell_two) * 100,
                 amount_needs_rounding = amount % 1 !== 0,
                 percentage_needs_rounding = percentage % 1 !== 0;
                 invalid_percentage = _.isNaN(percentage) ||
                                      percentage === Infinity ||
                                      percentage === -Infinity;

    if(amount > 0) {
      cell.positive = true;
      cell.negative = false;
    }else if(amount === 0) {
      cell.positive = false;
      cell.negative = false;
    }
    else {
      cell.negative = true;
      cell.positive = false;
    }

    if(invalid_percentage) {
      percentage = '';
    }else if(percentage_needs_rounding) {
      percentage = '(' + parseFloat(percentage.toFixed(2)) + ' %)';
    }else {
      percentage = '(' + parseInt(percentage) + ' %)';
    }

    if(amount_needs_rounding) {
      cell.value =  parseFloat(amount.toFixed(2)) + ' ' + percentage;
    }else {
      cell.value = parseInt(amount) + ' ' + percentage;
    }
  }

  this.calculateAddition = function calculateAddition(cell_one, cell_two, cell) {

    var amount = parseFloat(cell_one) + parseFloat(cell_two);
    cell.value = amount.toFixed(2);
  }

  this.calculateSubtract = function calculateSubtract(cell_one, cell_two, cell) {
    var amount = parseFloat(cell_one) - parseFloat(cell_two);
    cell.value = parseFloat(amount.toFixed(2));
    if (this.category === "new_supplier_credit_allocation") {
      if(amount > 0) {
        cell.positive = false;
        cell.negative = true;
      }else if(amount === 0) {
        cell.positive = true;
        cell.negative = false;
      }
      else {
        cell.negative = true;
        cell.positive = false;
      }
    }
  }

  this.getColIndexesFromStr = function getColIndexesFromStr(col_indexes_in_str) {
    var str_indexes = col_indexes_in_str.split('-'),
        int_indexes = [];

    for(var i = 0, l = str_indexes.length; i < l; i++) {
      int_indexes.push(parseInt(str_indexes[i]));
    }

    return int_indexes;
  }

  this.setDirectiveScope = function setDirectiveScope(scope_helper) {
    if (scope_helper !== undefined) {
      this.directive_scope = scope_helper;
      this.rows = this.directive_scope.spreadsheet_rows;

      if(this.directive_scope.supports_auto_save) {
        this.attemptSave();
      } else {

        /*
          Don't delete this code. Used for add line items for add/edit invoice screen.
          Initiated the categoryId for customer invoice spreadsheet screen.
        */
        var categoryId = '';
        if(typeof this.directive_scope.categoryId != 'undefined') {
          categoryId = this.directive_scope.categoryId;
        }
        
        var spread_sheet_data = {categoryId: categoryId, category: this.directive_scope.category, rows: angular.copy(this.directive_scope.spreadsheet_rows)};
        this.directive_scope.$emit('event:save-spreadsheet-data', spread_sheet_data);

        /* End */

      }
    }
  }

  this.disableCellsInRow = function disableCellsInRow(row) {
    row.merge_allowed = false;

    for(var i = 0, l = row.row[0].associated_rows.length; i < l; i++) {
      for(var x = 0, s = row.row[0].associated_rows[i].length; x < s; x++) {
        var cell = row.row[0].associated_rows[i][x];
        cell.previous_state = cell.state;
        cell.state = "not_selectable";
      }
    }
  }

  this.unDisableCellsInRow = function unDisableCellsInRow(row) {
    row.merge_allowed = true;

    for(var i = 0, l = row.row[0].associated_rows.length; i < l; i++) {
      for(var x = 0, s = row.row[0].associated_rows[i].length; x < s; x++) {
        var cell = row.row[0].associated_rows[i][x];
        cell.state = cell.previous_state;
      }
    }
  }

  /*==========================================================================================
   Called onExit of states which contain spreadsheet / spreadsheets
  ==========================================================================================*/
  document.addEventListener('state_with_spreadsheet_exited', function(e) {
    if(self) {
      if(self.CELL) {
        self.removeAllInstanceVariables();
      }
    }
  }, false);
});
