commusoftCommon.filter('dateShortFormat', ["$rootScope", 'CS_LANGUAGE', function ($rootScope, CS_LANGUAGE) {
    return function (dateString,customFormat) {
        if (dateString == '' || dateString == null) {
            return '';
        }

        var shortFormats = {
            0 : 'DD/MM/YY',
            1 : 'DD/MM/YYYY',
            2 : 'DD/MMM/YY',
            3 : 'DD/MMM/YYYY',
            4 : 'DD-MM-YY',
            5 : 'DD-MM-YYYY',
            6 : 'DD-MMM-YY',
            7 : 'DD-MMM-YYYY',
            50 : 'MM/DD/YY',
            51 : 'MM/DD/YYYY',
            52 : 'MMM/DD/YY',
            53 : 'MMM/DD/YYYY',
            54 : 'MM-DD-YY',
            55 : 'MM-DD-YYYY',
            56 : 'MMM-DD-YY',
            57 : 'MMM-DD-YYYY'
        };

        var timeFormats = {
            0 : 'h:mm a',
            1 : 'HH:mm'
        };
        let language = CS_LANGUAGE;
        if(language == 'en'){
            language = 'en-GB';
        }
        moment.locale(language);
        if(customFormat!='undefined' && customFormat==1){
            newDate = new Date();
            cDateString = moment(dateString).format('YYYY-MM-DD');
            todayDate = moment(newDate).format('YYYY-MM-DD');

            yesterday = new Date(newDate);
            yesterday.setDate(yesterday.getDate() - 1);
            yesterdayDate = moment(yesterday).format('YYYY-MM-DD');

            tomorrow = new Date(newDate);
            tomorrow.setDate(tomorrow.getDate() + 1);
            tomorrowDate = moment(tomorrow).format('YYYY-MM-DD');

            if (cDateString == todayDate) {
                return "Today"+" "+moment.utc(dateString).zone(moment().format('Z')).format(timeFormats[parseInt($rootScope.settings_value['time'])]);
            }else if (cDateString == yesterdayDate) {
                return "Yesterday"+" "+moment.utc(dateString).zone(moment().format('Z')).format(timeFormats[parseInt($rootScope.settings_value['time'])]);
            }else if (cDateString == tomorrowDate) {
                return "Tomorrow"+" "+moment.utc(dateString).zone(moment().format('Z')).format(timeFormats[parseInt($rootScope.settings_value['time'])]);
            }else{
                if (typeof shortFormats[parseInt($rootScope.settings_value['short_date'])] !== 'undefined') {
                    return moment(dateString).format(shortFormats[parseInt($rootScope.settings_value['short_date'])]);
                } else {
                    return moment(dateString).format('DD/MM/YYYY')
                }
            }
        }else {

            if (typeof shortFormats[parseInt($rootScope.settings_value['short_date'])] !== 'undefined') {
                return moment(dateString).format(shortFormats[parseInt($rootScope.settings_value['short_date'])]);
            } else {
                return moment(dateString).format('DD/MM/YYYY')
            }
        }
    };
}]);