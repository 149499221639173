commusoftCommon.directive('validateCardNumber', ['creditcards', function(creditcards) {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function(viewValue) {
                var cardNumberIsValid = creditcards.validate({ number: viewValue }).validCardNumber
                ctrl.$setValidity('cardNumber', cardNumberIsValid)
                return viewValue
            });
        }
    };
}]);