csmodule.controller('usersActivityPanelOnDiaryCtrl', function ($scope, $rootScope, $timeout, $filter, $diaryMapScope) {
    $scope.foreignWatchers = {};
    $scope.usersList = angular.copy($scope.panel_content.users);
    $scope.usersGpsData = {};
    $scope.userSearchQuery = '';

    var gpsDataTimer;
    $scope.foreignWatchers['diaryMapUserGpsDataSubscription'] = $rootScope.$on('dmUser:lastIdentifiedInfo',
        function (event, details) {

            if (gpsDataTimer) clearTimeout(gpsDataTimer);
            updateUsersGpsData(details);

            gpsDataTimer = setInterval(function () {
                updateUsersGpsData(details);
            }, 60000);
        });

    function updateUsersGpsData(details) {
        if (!details instanceof Object) details = {};

        $timeout(function () {
            $scope.usersGpsData = details;
        });
    }

    $scope.handleUserSearchQuery = function handleUserSearchQuery() {
        $scope.usersList = $filter('filter')($scope.panel_content.users, $scope.userSearchQuery);
    };

    $scope.handleUserClick = function (u) {
        $diaryMapScope.$emit('usersActivityPanel:user:selected', u);
        $scope.closeUsersActivityPanel();
    };

    // Defines the class for coloring the user gps time info
    $scope.getLptColorClass = function (datetime) {
        var duration = moment.duration(moment().diff(datetime));
        var minutes = duration.asMinutes();

        if (minutes <= 19) return 'green';
        else if (19 < minutes && minutes <= 60) return 'yellow';
        else return 'red';
    };

    // Centering map on user position
    $scope.pointUserOnMap = function (e, userId) {
        e.preventDefault();
        e.stopPropagation();

        $rootScope.$emit('dmUser:recenter', userId);
        $scope.closeUsersActivityPanel();
    };

    // Close panel
    $scope.closeUsersActivityPanel = function () {
        $scope.$destroy();
        $rootScope.$broadcast('sidepanel:close');
    };

    // Removes events on panel close
    $scope.$on('$destroy', function () {

        Object.keys($scope.foreignWatchers).forEach(function (n) {
            $scope.foreignWatchers[n]();
        });

        if (gpsDataTimer) clearTimeout(gpsDataTimer);
    });
});