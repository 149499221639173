commusoftCommon.directive('checkUserExists', ['prefix', 'canLoad', '$q', '$http', function(prefix, canLoad, $q, $http) {
    return {
        require: '?ngForm',
        restrict: 'A',
        link: function(scope, element, attrs, ctrl) {

            var canceler = null;
            scope.$watch('companyId', function(val){
                if (scope.companyId != '') {
                    var url = prefix + '/check_companyid?clientId=' + encodeURIComponent(scope.companyId);
                    canLoad.setLoadValue(false);  // Turn off AJAX
                    if (canceler) {
                        canceler.resolve();
                    }
                    canceler = $q.defer();
                    $http.get(url, {timeout: canceler.promise}).
                    success(function(data, status) {
                        if (status == 200) {
                            if (data == "true") {
                                scope.forgetPasswordForm['forgetpassword[companyId]'].$setValidity("checkUserExists", true);
                                checkUserExists.call();
                            } else {
                                scope.forgetPasswordForm['forgetpassword[companyId]'].$setValidity("checkUserExists", false);
                            }
                        }
                    })
                }
            });
            scope.$watch('username', function(val){
                checkUserExists.call();
            });
            function checkUserExists (){
                if (scope.companyId != '' && scope.username != '') {
                    var url = prefix + '/usernameExists?clientId=' + encodeURIComponent(scope.companyId) +
                        '&username=' + encodeURIComponent(scope.username);
                    canLoad.setLoadValue(false);  // Turn off AJAX
                    if (canceler) {
                        canceler.resolve();
                    }
                    canceler = $q.defer();
                    $http.get(url, {timeout: canceler.promise}).
                    success(function(data, status) {
                        if (status == 200) {
                            if (data == "true") {
                                scope.forgetPasswordForm['forgetpassword[username]'].$setValidity("checkUserExists", true);
                            } else {
                                scope.forgetPasswordForm['forgetpassword[username]'].$setValidity("checkUserExists", false);
                            }
                        }
                    })
                }
            }
        }
    }
}]);
