csmodule.service('invoiceScheduleHelper', function($q, $rootScope, spreadSheetData) {

    this.handleData = function handleData(directive_scope) {
        var self = this;
        this.directive_scope = directive_scope;
        this.rows = directive_scope.spreadsheet_rows;
        var milestones_exist = directive_scope.milestones_exist;

        if(milestones_exist) {
            this.milestones = directive_scope.milestones;
            this.spreadsheet_ready_milestones = [];
            this.handleMilestones();
        }else {
            this.handleCompletionAmount();
        }
    }

    this.handleMilestones = function handleMilestones() {
        this.populateMilestoneRows().then(function() {
            self.insertMilestoneRows();
            self.removeCompletionRow();
            self.syncLocalStorage();
        })
            .catch(function() {
                console.warn("Milestones can't be put into invoice schedule");
            });
    }

    this.syncLocalStorage = function syncLocalStorage() {
        localStorage.removeItem('invoice_schedule:spreadsheetGrandTotals');
        self.directive_scope.generateSubTotals();
        self.directive_scope.handleGrandTotals();
    }

    this.populateMilestoneRows = function populateMilestoneRows() {
        var promises = [];

        for(var i = 0, l = this.milestones.length; i < l; i++) {
            var self = this,
                milestone = this.milestones[i],
                deferred = $q.defer();

            promises.push(
                this.makeNewMilestoneRow(milestone, i).then(function(row) {
                    row.row[0].associated_rows[0][0].valid = true;
                    row.row[0].associated_rows[0][1].valid = true;
                    self.spreadsheet_ready_milestones.push(row);
                    deferred.resolve();
                }, function(response) {
                    deferred.reject();
                })
            );
        };

        return $q.all(promises);
    }

    this.removeCompletionRow = function removeCompletionRow() {
        var deferred = $q.defer(),
            completion_row_index = 1;

        this.rows.splice(completion_row_index, 1);
        deferred.resolve();
        return deferred.promise;
    }

    this.insertMilestoneRows = function insertMilestoneRows() {
        var deferred = $q.defer(),
        index_to_insert_milestone = this.rows.length - 1;

        // insert the milestones in reverse to preserve order
        for(var i = 0, l = this.spreadsheet_ready_milestones.length - 1; i <= l; l--) {
            var milestone = this.spreadsheet_ready_milestones[l];
            this.rows.splice(index_to_insert_milestone, 0, milestone);
        }

        deferred.resolve();
        return deferred.promise;
    }

    this.makeNewMilestoneRow = function makeNewMilestoneRow(milestone, row_index) {
        var deferred = $q.defer(),
            self = this,
            row = {
                "id" : null,
                "pos" : row_index + 1,
                "row" : [
                    {
                        "associated_rows" : [
                            [
                                {
                                    "value" : milestone.name,
                                    "state" : "not_selectable"
                                },
                                {
                                    "value" : milestone.amount,
                                    "input_type" : "time",
                                    "used_in_calculation" : true,
                                    "calculation" : "itself",
                                    "state" : null
                                },
                                {
                                    "value" : "NA",
                                    "state" : "not_selectable"
                                }
                            ]
                        ]
                    }
                ]
            };

        spreadSheetData.create(this.directive_scope.category, row).then(function(response) {
            row.id = response.data.id;
            deferred.resolve(row);
        }, function(response) {
            self.directive_scope.handleFailedServerValidations(response.data);
            deferred.reject();
        });

        return deferred.promise;
    }

    this.handleCompletionAmount = function handleCompletionAmount() {
        var completion_cell = this.rows[1].row[0].associated_rows[0][1];

        // Calculate the entire total.
        var newTotal =0;
        for(var i = 0; i < this.rows.length; i++) {
            newTotal = newTotal + parseFloat(this.rows[i].row[0].associated_rows[0][1].value);
        }

        // If the entire total is zero, the assign the estimate total value.
        if(newTotal <= 0) {
            completion_cell.value = this.directive_scope.estimate_total;
        }
    }

    this.handleInvoiceScheduleAmounts = function handleInvoiceScheduleAmounts(row_index, cell_value, input_is_percentage) {
        var amount_cell_index = 1,
            cell_to_update = this.rows[row_index].row[0].associated_rows[0][amount_cell_index],
            amount_to_deduct;

        if(input_is_percentage) {
            var percentage = parseFloat(cell_value),
                amount_to_deduct = parseFloat(((percentage / 100) * this.directive_scope.estimate_total).toFixed(2));
        }else {
            amount_to_deduct = cell_value;
        }

        cell_to_update.value = amount_to_deduct;
    }
});