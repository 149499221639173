commusoftCommon.directive('fileUpload', [function() {
    return {
        restrict: 'EA',
        replace: false,
        scope: {
            action: '@',
            btnLabel: '@',
            btnClass: '@',
            inputName: '@',
            progressClass: '@',
            onSuccess: '&'
        },
        link: function(scope, elem, attrs, ctrl) {
            attrs.btnLabel = attrs.btnLabel || "Choose File";
            attrs.inputName = attrs.inputName || "file";
            attrs.btnClass = attrs.btnClass || "btn";
            attrs.progressClass = attrs.progressClass || "btn";

            elem.find('.fake-uploader').click(function() {
                elem.find('input[type="file"]').click();
            });
        },
        templateUrl: 'template/fileuploadmodal.html',
        controller: ['$scope', 'prefix', '$attrs', function ($scope, prefix, $attrs) {
            $scope.progress = 0;
            $scope.avatar = '';
            $scope.errorMessage = '';

            $scope.buttonCount = 0;
            if($attrs.buttonCount) {
                $scope.buttonCount = $attrs.buttonCount;
            }

            $scope.$on("fileUpload:disabled", function (event, data) {
                if(data == true) {
                    document.getElementById('avatar').setAttribute("disabled", "");
                }
                else {
                    document.getElementById('avatar').removeAttribute("disabled");
                }
            });

            $scope.sendFile = function(el) {
                $scope.errorMessage = '';
                var $form = $(el).parents('form');
                var filesArray = $form.find('input:file')[$scope.buttonCount].files;
                var fileName = filesArray[0].name;
                var extension = fileName.split('.').pop();

                if(extension != 'csv') {
                    $scope.errorMessage = 'Please upload a valid CSV file.';
                    $scope.$apply();
                    return false;
                }

                if ($(el).val() == '') {
                    return false;
                }

                $form.attr('action', prefix + $scope.action);

                $scope.$apply(function() {
                    $scope.progress = 0;
                });

                $form.ajaxSubmit({
                    type: 'POST',
                    uploadProgress: function(event, position, total, percentComplete) {

                        $scope.$apply(function() {
                            // upload the progress bar during the upload
                            $scope.progress = percentComplete;
                        });

                    },
                    error: function(event, statusText, responseText, form) {
                        // remove the action attribute from the form
                        $form.removeAttr('action');

                        $scope.$apply(function () {
                            $scope.onError({
                                event: event,
                                responseText: responseText,
                                statusText: statusText,
                                form: form
                            });
                        });
                    },
                    success: function(responseText, statusText, xhr, form) {
                        if(responseText.error) {
                            $scope.errorMessage = responseText.errorMessage;
                            $scope.$apply();
                            return false;
                        }
                        var ar = $(el).val().split('\\'),
                            filename =  ar[ar.length-1];

                        // remove the action attribute from the form
                        $form.removeAttr('action');

                        $scope.$apply(function () {
                            $scope.onSuccess({
                                responseText: responseText,
                                statusText: statusText,
                                xhr: xhr,
                                form: form
                            });
                        });

                        $(el).val('');
                        $scope.progress = 0;
                    }
                });
            }
        }]
    };
}]);