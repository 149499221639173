commusoftCommon.directive("infiniteScroll", function() {
    return function(scope, elm, attr) {
        var raw = elm[0];

        elm.bind('scroll', function() {
            // if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
            //     scope.$apply(attr.infiniteScroll);
            // }
          // console.log(elm[0].scrollHeight, elm.scrollTop(), elm.outerHeight(), raw.offsetHeight);
          //(scrollTop > 0 && self.$viewport[0].scrollHeight - scrollTop - self.$viewport.outerHeight() < 10)
          if (elm.scrollTop() > 0 && elm[0].scrollHeight - elm.scrollTop() - elm.outerHeight() < 10) {
            // We're at the bottom.
            scope.$apply(attr.infiniteScroll);
          }
        });
    };
});