commusoftCommon.service('reportNavSidebar', function ($translate, prefix, $http) {
    var reportsubnavItems = [
        { id: 'customers',
            firstTier: 'Customers',
            moduleFeature: 'customerReports',
            secondTier: [
                { id: 'customers',
                    item: 'Customers',
                    state: 'customers',
                    description: 'Description.for.Customers',
                    feature: 'Basiccustomerreports'
                },
//                    { id: 'branches',
//                        item: 'Branches',
//                        state: 'branches',
//                        description: 'Description.for.Branches'
//                    },
                { id: 'work_addresses',
                    item: $translate('Work.Addresses'),
                    state: 'work_addresses',
                    description: 'Description.for.Work.Addresses',
                    feature: 'Basiccustomerreports'
                },
//                    { id: 'contacts',
//                        item: 'Contacts',
//                        state: 'contacts',
//                        description: 'Description.for.Contacts'
//                    },
//                    { id: 'landlords',
//                        item: 'Landlords',
//                        state: 'landlords',
//                        description: 'Description.for.Landlords'
//                    },
                { id: 'appliance_details',
                    item: $translate('Appliance.Details'),
                    state: 'appliance_details',
                    description: 'Description.for.Appliance.Details',
                    feature: 'Basiccustomerreports'
                },
//                    { id: 'notes',
//                        item: 'Notes',
//                        state: 'notes',
//                        description: 'Description.for.Notes'
//                    },
//                    { id: 'emails',
//                        item: 'Emails',
//                        state: 'emails',
//                        description: 'Description.for.Emails'
//                    },
//                    { id: 'prints',
//                        item: 'Prints',
//                        state: 'prints',
//                        description: 'Description.for.Prints'
//                    },
//                    { id: 'SMS',
//                        item: 'SMS',
//                        state: 'SMS',
//                        description: 'Description.for.SMS'
//                    },
//                    { id: 'phone_calls',
//                        item: $translate('Phone.Calls'),
//                        state: 'phone_calls',
//                        description: 'Description.for.Phone.Calls'
//                    },
//                    { id: 'reminders',
//                        item: 'Reminders',
//                        state: 'reminders',
//                        description: 'Description.for.Reminders'
//                    },
//                    { id: 'attached_files',
//                        item: $translate('Attached.Files'),
//                        state: 'attached_files',
//                        description: 'Description.for.Attached.Files'
//                    },
                { id: 'advertising_performance',
                    item: $translate('Advertising.Performance'),
                    state: 'advertising_performance',
                    description: 'Description.for.Advertising.Performance',
                    feature: 'Basiccustomerreports'
                },
//                    { id: 'properties_with_expired_gas_safety_certificates',
//                        item: $translate('Properties.with.expired.Gas.Safety.Certificates'),
//                        state: 'properties_with_expired_gas_safety_certificates',
//                        description: 'Description.for.Properties.with.expired.Gas.Safety.Certificates'
//                    },
                { id: 'jobEstimateAccessPhoneCallList',
                    item: $translate('Job.estimate.access.phone.call.list'),
                    state: 'jobEstimateAccessPhoneCallList',
                    description: 'Description.for.job.estimate.access.phone.call.list',
                    feature: 'Basiccustomerreports'
                },
                { id: 'servicePlan',
                    item: $translate('Service.Plan'),
                    state: 'servicePlan',
                    description: 'Description.for.Service.Plan',
                    feature: 'Basiccustomerreports'
                },
                { id: 'asset',
                    item: $translate('Asset'),
                    state: 'asset',
                    description: 'Description.for.asset',
                    feature: 'customerAsset'
                },
                { id: 'contracts',
                    item: $translate('Contracts'),
                    state: 'contracts',
                    description: 'Description.for.Contracts',
                    feature: 'ViewContractReport'
                },
                { id: 'PPM_performance',
                    item: 'PPM performance',
                    state: 'PPM_performance', //If you change this, need to change in reporting controller too
                    description: 'Description.for.PPM.performance' ,
                    feature: 'ViewContractReport'
                }
            ]
        },
        { id: 'service_reminders',
            firstTier: 'Service Reminders',
            moduleFeature: 'serviceReminderReports',
            secondTier: [
                { id: 'service_reminders',
                    item: $translate('Service.Reminders'),
                    state: 'service_reminders',
                    description: 'Description.for.Service.Reminders',
                    feature: 'Basicservicereminderreports'
                },
                { id: 'properties_without_services',
                    item: $translate('Properties.without.Services'),
                    state: 'properties_without_services',
                    description: 'Description.for.Properties.without.Services',
                    feature: 'Basicservicereminderreports'
                }
//                    { id: 'service_reminder_performance_analysis',
//                        item: $translate('Service.Reminder.Performance.Analysis'),
//                        state: 'service_reminder_performance_analysis',
//                        description: 'Description.for.Service.Reminder.Performance.Analysis'
//                    },
//                    { id: 'service_reminder_communications',
//                        item: $translate('Service.Reminder.Communications'),
//                        state: 'service_reminder_communications',
//                        description: 'Description.for.Service.Reminders.Communications'
//                    }
            ]
        },
//            { id: 'contract_plans',
//                firstTier: 'Contract Plans',
//                secondTier: [
//                    { id: 'customers_on_contract_plans',
//                        item: $translate('Customers.on.Contract.Plans'),
//                        state: 'customers_on_contract_plans',
//                        description: 'Description.for.Customers.on.Contract.Plans'
//                    },
//                    { id: 'expiring_contract_plans',
//                        item: $translate('Expiring.Contract.Plans'),
//                        state: 'expiring_contract_plans',
//                        description: 'Description.for.Expiring.Contract.Plans'
//                    },
//                    { id: 'contract_plans_profit_by_customer',
//                        item: $translate('Contract.Plans.Profit.by.Customer'),
//                        state: 'contract_plans_profit_by_customer',
//                        description: 'Description.for.Contract.Plans.Profit.by.Customer'
//                    },
//                    { id: 'notifications_sent_to_customers',
//                        item: $translate('Notifications.sent.to.Customers'),
//                        state: 'notifications_sent_to_customers',
//                        description: 'Description.for.Notifications.sent.to.Customers'
//                    }
//                ]
//            },
        { id: 'suppliers',
            firstTier: 'Suppliers',
            moduleFeature: 'supplierReports',
            secondTier: [
                { id: 'suppliers',
                    item: 'Suppliers',
                    state: 'suppliers',
                    description: 'Description.for.Suppliers',
                    feature: 'Basicsupplierreports'
                },
//                    { id: 'branches',
//                        item: 'Branches',
//                        state: 'branches',
//                        description: 'Description.for.Branches'
//                    },
                { id: 'contacts',
                    item: 'Contacts',
                    state: 'contacts',
                    description: 'Description.for.Contacts',
                    feature: 'Basicsupplierreports'
                }
//                    { id: 'notes',
//                        item: 'Notes',
//                        state: 'notes',
//                        description: 'Description.for.Notes'
//                    },
//                    { id: 'emails',
//                        item: 'Emails',
//                        state: 'emails',
//                        description: 'Description.for.Emails'
//                    },
//                    { id: 'prints',
//                        item: 'Prints',
//                        state: 'prints',
//                        description: 'Description.for.Prints'
//                    },
//                    { id: 'SMS',
//                        item: 'SMS',
//                        state: 'SMS',
//                        description: 'Description.for.SMS'
//                    },
//                    { id: 'phone_calls',
//                        item: $translate('Phone.Calls'),
//                        state: 'phone_calls',
//                        description: 'Description.for.Phone.Calls'
//                    },
//                    { id: 'attached_files',
//                        item: $translate('Attached.Files'),
//                        state: 'attached_files',
//                        description: 'Description.for.Attached.Files'
//                    }
            ]
        },
        { id: 'estimates',
            firstTier: 'Estimates',
            moduleFeature: 'estimateReports',
            secondTier: [
                { id: 'estimates',
                    item: 'Estimates',
                    state: 'estimates',
                    description: 'Description.for.Estimates',
                    feature: 'Basicestimatereports'
                },
//                    { id: 'budgets',
//                        item: 'Budgets',
//                        state: 'budgets',
//                        description: 'Description.for.Budgets'
//                    },
//                    { id: 'budget_vs_actual_profit',
//                        item: $translate('Budget.vs.Actual.Profit'),
//                        state: 'budget_vs_actual_profit',
//                        description: 'Description.for.Budget.vs.Actual.Profit'
//                    },
//                    { id: 'notes',
//                        item: 'Notes',
//                        state: 'notes',
//                        description: 'Description.for.Notes'
//                    },
//                    { id: 'emails',
//                        item: 'Emails',
//                        state: 'emails',
//                        description: 'Description.for.Emails'
//                    },
//                    { id: 'prints',
//                        item: 'Prints',
//                        state: 'prints',
//                        description: 'Description.for.Prints'
//                    },
//                    { id: 'SMS',
//                        item: 'SMS',
//                        state: 'SMS',
//                        description: 'Description.for.SMS'
//                    },
//                    { id: 'phone_calls',
//                        item: $translate('Phone.Calls'),
//                        state: 'phone_calls',
//                        description: 'Description.for.Phone.Calls'
//                    },
//                    { id: 'attached_files',
//                        item: $translate('Attached.Files'),
//                        state: 'attached_files',
//                        description: 'Description.for.Attached.Files'
//                    },
                { id: 'accepted_vs_rejected_estimates',
                    item: $translate('Accepted.vs.Rejected.Estimates'),
                    state: 'accepted_vs_rejected_estimates',
                    description: 'Description.for.Accepted.vs.Rejected.Estimates',
                    feature: 'Basicestimatereports'
                },
                { id: 'rejected_reasons',
                    item: $translate('Rejected.Reasons'),
                    state: 'rejected_reasons',
                    description: 'Description.for.Rejected.Reasons',
                    feature: 'Basicestimatereports'
                }
            ]
        },
        { id: 'jobs',
            firstTier: 'Jobs',
            moduleFeature: 'jobReports',
            secondTier: [
                { id: 'jobs',
                    item: 'Jobs',
                    state: 'jobs',
                    description: 'Description.for.Jobs',
                    feature: 'Basicjobreports'
                },
//                    { id: 'to-dos',
//                        item: 'To-dos',
//                        state: 'to-dos',
//                        description: 'Description.for.To-dos'
//                    },
                { id: 'additional_works',
                    item: $translate('Additional.Works'),
                    state: 'additional_works',
                    description: 'Description.for.Additional.Works',
                    feature: 'Basicjobreports'
                },
//                    { id: 'recalls',
//                        item: 'Recalls',
//                        state: 'recalls',
//                        description: 'Description.for.Recalls'
//                    },
//                    { id: 'job_costs',
//                        item: $translate('Job.Costs'),
//                        state: 'job_costs',
//                        description: 'Description.for.Job.Costs'
//                    },
//                    { id: 'job_costs_by_category',
//                        item: $translate('Job.Costs.by.Category'),
//                        state: 'job_costs_by_category',
//                        description: 'Description.for.Job.Costs.by.Category'
//                    },
//                    { id: 'notes',
//                        item: 'Notes',
//                        state: 'notes',
//                        description: 'Description.for.Notes'
//                    },
//                    { id: 'attached_files',
//                        item: $translate('Attached.Files'),
//                        state: 'attached_files',
//                        description: 'Description.for.Attached.Files'
//                    },
//                    { id: 'engineer_jobs_by_month',
//                        item: $translate('Engineer.Jobs.by.Month'),
//                        state: 'engineer_jobs_by_month',
//                        description: 'Description.for.Debtors'
//                    },
                { id: 'free_of_charge_jobs',
                    item: $translate('Free.of.Charge.Jobs'),
                    state: 'free_of_charge_jobs',
                    description: 'Description for.Free.of.Charge.Jobs',
                    feature: 'Basicjobreports'
                },
                { id: 'aborted_jobs',
                    item: $translate('Aborted.Jobs'),
                    state: 'aborted_jobs',
                    description: 'Description.for.Aborted.Jobs',
                    feature: 'Basicjobreports'
                },
//                    { id: 'on_contract_plan_jobs',
//                        item: $translate('On.Contract.Plan.Jobs'),
//                        state: 'on_contract_plan_jobs',
//                        description: 'Description.for.Debtors'
//                    },
//                    { id: 'jobs_by_complete_type',
//                        item: $translate('Jobs.by.Complete.Type'),
//                        state: 'jobs_by_complete_type',
//                        description: 'Description.for.Jobs.by.Complete.Type'
//                    },
//                    { id: 'customer_job_requests',
//                        item: $translate('Customer.Job.Requests'),
//                        state: 'customer_job_requests',
//                        description: 'Description.for.Customer.Job.Requests'
//                    },
//                    { id: 'number_of_jobs_by_type_vs_administrative_effort',
//                        item: $translate('Number.of.Jobs.by.Type.vs.Administrative.Effort'),
//                        state: 'number_of_jobs_by_type_vs_administrative_effort',
//                        description: 'Description.for.Number.of.Jobs.by.Type.vs.Administrative.Effort'
//                    },
//                    { id: 'number_of_jobs_by_type_vs_diary_visits',
//                        item: $translate('Number.of.Jobs.by.Type.vs.Diary.Visits'),
//                        state: 'number_of_jobs_by_type_vs_diary_visits',
//                        description: 'Description.for.Number.of.Jobs.by.Type.vs.Diary.Visits'
//                    }
                // UI related dynamic scheduling
                {   id: 'outstanding_jobs',
                    item: $translate('Outstanding.Jobs'),
                    customState: 'loggedin.reporting.outstanding_jobs',
                    customStateParams: {category: 'reporting_operational_list_parts', subcategory: 'parts', type: 'requested'},
                    description: 'Description.for.Outstanding.Jobs',
                    feature: 'Outstandingjobsreport'
                },
                { id: 'SLA_jobs',
                    item: 'SLA jobs',
                    state: 'SLA_jobs', //If you change this, need to change in reporting controller too
                    description: 'Description.for.SLA',
                    feature: 'ViewSLAReport'
                },
                { id: 'SLA_performance_by_job_description',
                    item: 'SLA performance by job description',
                    state: 'SLA_performance_by_job_description', //If you change this, need to change in reporting controller too
                    description: 'Description.for.SLA performance by job description' ,
                    feature: 'ViewSLAReport'
                },
                { id: 'SLA_due_to_breach',
                    item: 'SLA due to breach',
                    state: 'SLA_due_to_breach', //If you change this, need to change in reporting controller too
                    description: 'Description.for.SLA performance by job description' ,
                    feature: 'ViewSLAReport'
                }
            ]
        },
//            { id: 'certificates',
//                firstTier: 'Certificates',
//                secondTier: [
//                    { id: 'domestic_gas_safety_certificates',
//                        item: $translate('Domestic.Gas.Safety.Certificates'),
//                        state: 'domestic_gas_safety_certificates',
//                        description: 'Description.for.Domestic.Gas.Safety.Certificates.'
//                    },
//                    { id: 'non-Domestic_gas_safety_certificates',
//                        item: $translate('Non-Domestic.Gas.Safety.Certificates'),
//                        state: 'Domestic_gas_safety_certificates',
//                        description: 'Description.for.Non-Domestic.Gas.Safety.Certificates'
//                    },
//                    { id: 'service_and_maintenance_certificates',
//                        item: $translate('Service.and.Maintenance.Certificates'),
//                        state: 'service_and_maintenance_certificates',
//                        description: 'Description.for.Service.and.Maintenance.Certificates'
//                    },
//                    { id: 'customers_with_expired_CP12',
//                        item: $translate('Customers.with.Expired.CP12'),
//                        state: 'customers_with_expired_CP12',
//                        description: 'Description.for.Customers.with.Expired.CP12'
//                    },
//                    { id: 'customers_with_expiring_CP12',
//                        item: $translate('Customers.with.Expiring.CP12'),
//                        state: 'customers_with_expiring_CP12',
//                        description: 'Description.for.Customers.with.Expiring.CP12'
//                    }
//                ]
//            },
        { id: 'parts',
            firstTier: 'Parts',
            moduleFeature: 'partReports',
            secondTier: [
                { id: 'parts',
                    item: 'Parts',
                    state: 'parts',
                    description: 'Description.for.Parts',
                    feature: 'Basicpartsreports'
                },
                { id: 'partsInstalled',
                    item: $translate('Number.Of.Parts.Installed'),
                    state: 'partsInstalled',
                    description: $translate('Description.for.Number.of.Parts.Installed'),
                    feature: 'Basicpartsreports'
                },
                { id: 'parts_operational_list',
                  item: $translate('Parts.Operational.List'),
                  customState: 'loggedin.reporting.operational',
                  customStateParams: {category: 'reporting_operational_list_parts', subcategory: 'parts', type: 'requested'},
                  description: $translate('Description.for.Parts.Operational.List'),
                  feature: 'Basicpartsreports'
                },
                { id: 'incomplete_part_completed_closed_jobs_operational_list',
                  item: $translate('Incomplete.Part.Completed.Closed.Jobs'),
                  customState: 'loggedin.reporting.operational.incomplete_part_completed_closed_jobs',
                  customStateParams: {category: 'reporting_operational_list_parts', subcategory: 'incomplete_part_completed_closed_jobs', type: 'requested'},
                  description: $translate('Description.for.Incomplete.Part.Completed.Closed.Jobs.List'),
                  feature: 'Basicpartsreports'
                }
//                    { id: 'part_delivery_time',
//                        item: $translate('Part.Delivery.Time'),
//                        state: 'part_delivery_time',
//                        description: 'Description.for.Part.Delivery.Time'
//                    },
//                    { id: 'part_requests_by_engineer',
//                        item: $translate('Part.Requests.by.Engineer'),
//                        state: 'part_requests_by_engineer',
//                        description: 'Description.for.Part.Requests.by.Engineer'
//                    },
//                    { id: 'part_orders_by_user',
//                        item: $translate('Part.Orders.by.User'),
//                        state: 'part_orders_by_user',
//                        description: 'Description.for.Part.Orders.by.User'
//                    }
            ]
        },
        /*{ id: 'projects',
            firstTier: 'Projects',
            secondTier: [
                { id: 'projects',
                    item: 'Projects',
                    state: 'projects',
                    description: 'Description.for.Projects'
                }
                { id: 'budgets',
                    item: 'Budgets',
                    state: 'budgets',
                    description: 'Description.for.Budgets'
                },
                { id: 'planned_todos',
                    item: $translate('Planned.Todos'),
                    state: 'planned_todos',
                    description: 'Description.for.Planned.Todos'
                },
                { id: 'attached_files',
                    item: $translate('Attached.Files'),
                    state: 'attached_files',
                    description: 'Description.for.Attached.Files'
                },
                { id: 'sales_performance_by_sales_person',
                    item: $translate('Sales.Performance.by.Sales.Person'),
                    state: 'sales_performance_by_sales_person',
                    description: 'Description.for.Sales.Performance.by.Sales.Person'
                }
            ]
        },*/
        { id: 'diary_events',
            firstTier: 'Diary Events',
            moduleFeature: 'diaryReports',
            secondTier: [
                { id: 'diary_events',
                    item: $translate('Diary.Events'),
                    state: 'diary_events',
                    description: 'Description.for.Diary.Events',
                    feature: 'Basicdiaryreports'
                },
//                    { id: 'predicted_hours_worked_by_month',
//                        item: $translate('Predicted.Hours.Worked.by.Month'),
//                        state: 'predicted_hours_worked_by_month',
//                        description: 'Description.for.Predicted.Hours.Worked.by.Month'
//                    },
//                    { id: 'engineer_feedback',
//                        item: $translate('Engineer.Feedback'),
//                        state: 'engineer_feedback',
//                        description: 'Description.for.Engineer.Feedback',
//                        feature: 'Basicdiaryreports'
//                    }
            ]
        },
        { id: 'sales',
            firstTier: 'Sales',
            moduleFeature: 'salesReports',
            secondTier: [
                { id: 'sales',
                    item: 'Sales',
                    state: 'sales',
                    description: 'Description.for.Sales',
                    feature: 'Basicsalesreports'
                },
                { id: 'Customer_Payments',
                    item: $translate('Customer.Payments'),
                    state: 'Customer_Payments',
                    description: 'Description.for.Customer.Payments',
                    feature: 'Basicsalesreports'
                },
                { id: 'draft_invoice',
                    item: $translate('draft.invoice'),
                    state: 'draft_invoice',
                    description: 'Description.for.draft.invoice',
                    feature: 'Basicsalesreports'
                },
//                    { id: 'sales_by_line_item',
//                        item: $translate('Sales.by.Line.Item'),
//                        state: 'sales_by_line_item',
//                        description: 'Description.for.Sales.by.Line.Item'
//                    },
//                    { id: 'sales_by_category_by_month',
//                        item: $translate('Sales.by.Category.by.Month'),
//                        state: 'sales_by_category_by_month',
//                        description: 'Description.for.Sales.by.Category.by.Month'
//                    },
//                    { id: 'sales_and_CIS',
//                        item: $translate('Sales.and.CIS'),
//                        state: 'sales_and_CIS',
//                        description: 'Description.for.Sales.and.CIS'
//                    },
//                    { id: 'payments',
//                        item: 'Payments',
//                        state: 'payments',
//                        description: 'Description.for.Payments',
//                        feature: 'Basicsalesreports'
//                    },
                /*{ id: 'invoices_not_sent',
                    item: $translate('Invoices.not.Sent'),
                    state: 'invoices_not_sent',
                    description: 'Description.for.Invoices.not.Sent'
                }*/
            ]
        },
        { id: 'purchase_orders',
            firstTier: $translate('Purchase.Orders'),
            moduleFeature: 'purchaseOrderReports',
            secondTier: [
                { id: 'purchase_orders',
                    item: $translate('Purchase.Orders'),
                    state: 'purchase_orders',
                    description: 'Description.for.Purchase.Orders',
                    feature: 'Basicpurchaseorderreports'
                }
            ]
        },
        { id: 'expenses',
            firstTier: 'Expenses',
            moduleFeature: 'expenseReports',
            secondTier: [
                { id: 'supplier_invoices',
                    item: $translate('Supplier.Invoices'),
                    state: 'supplier_invoices',
                    description: 'Description.for.Supplier.Invoices',
                    feature: 'Basicexpensereports'
                },
//                    { id: 'supplier_invoices_by_line_item',
//                        item: $translate('Supplier.Invoices.by.Line.Item'),
//                        state: 'supplier_invoices_by_line_item',
//                        description: 'Description.for.Supplier.Invoices.by.Line.Item'
//                    },
                { id: 'payments',
                    item: 'Payments',
                    state: 'payments',
                    description: 'Description.for.Payments',
                    feature: 'Basicexpensereports'
                }
//                    { id: 'PO_vs_supplier_invoice',
//                        item: $translate('PO.vs.Supplier.Invoice'),
//                        state: 'PO_vs_supplier_invoice',
//                        description: 'Description.for.PO.vs.Supplier.Invoice'
//                    }
            ]
        },
        { id: 'profit',
            firstTier: 'Profit',
            moduleFeature: 'profitReports',
            secondTier: [
                { id: 'profit_by_job',
                    item: $translate('Profit.by.Job'),
                    state: 'profit_by_job',
                    description: 'Description.for.Profit.by.Job',
                    feature: 'Basicprofitreports'
                },
//                    { id: 'profit_by_customer',
//                        item: 'Profit.by.Customer',
//                        state: 'profit_by_customer',
//                        description: 'Description.for.Profit.by.Customer'
//                    },
//                    { id: 'profit_by_job_type',
//                        item: $translate('Profit.by.Job.Type'),
//                        state: 'profit_by_job_type',
//                        description: 'Description.for.Profit.by.Job.Type'
//                    },
//                    { id: 'profit_by_invoice_category',
//                        item: $translate('Profit.by.Invoice.Category'),
//                        state: 'profit_by_invoice_category',
//                        description: 'Description.for.Profit.by.Invoice.Category'
//                    },
                /*{ id: 'profit_by_engineer',
                    item: $translate('Profit.by.Engineer'),
                    state: 'profit_by_engineer',
                    description: 'Description.for.Profit.by.Engineer'
                }*/
//                    { id: 'profit_by_town',
//                        item: $translate('Profit.by.Town'),
//                        state: 'profit_by_town',
//                        description: 'Description.for.Profit.by.Town'
//                    }
            ]
        },
        { id: 'stock_control',
            firstTier: $translate('Stock.Control'),
            moduleFeature: 'stockControlReports',
            secondTier: [
                { id: 'stock_items',
                    item: $translate('Stock.Items'),
                    state: 'stock_items',
                    description: 'Description.for.Stock.Items',
                    feature: 'Advancedstockcontrolreports'
                },
                { id: 'returns_operational_list',
                    item:  $translate('Stocks.Returns.Operational.List'),
                    customState: 'loggedin.reporting.operational.stocks',
                    customStateParams: {category: 'stock_control', subcategory: 'returns_operational_list', type: 'excess_for_job'},
                    description: $translate('Description.for.Stock.Returns.Operational.List'),
                    feature: 'StockReturnsWorkflow'
                },
                { id: 'stock_summary',
                    item: $translate('Stock.Summary'),
                    state: 'stock_summary',
                    description: 'Description.for.stock.summary',
                    feature: 'Advancedstockcontrolreports'
                },
            ]
        },
        { id: 'users',
            firstTier: 'Users',
            moduleFeature: 'userReports',
            secondTier: [
                { id: 'user_list',
                    item: $translate('User.List'),
                    state: 'user_list',
                    description: 'Description.for.User.List',
                    feature: 'Basicusersreports'
                },
//                    { id: 'holiday_requests',
//                        item: $translate('Holiday.Requests'),
//                        state: 'holiday_requests',
//                        description: 'Description.for.Holiday.Requests'
//                    },
//                    { id: 'remaining_holiday_entitlement',
//                        item: $translate('Remaining.Holiday.Entitlement'),
//                        state: 'remaining_holiday_entitlement',
//                        description: 'Description.for.Remaining.Holiday.Entitlement'
//                    },
                { id: 'time_sheets',
                    item: $translate('Time.Sheets'),
                    state: 'time_sheets',
                    description: 'Description.for.Time.Sheets.',
                    feature: 'Basicusersreports'
                },
                { id: 'office_tasks',
                    item: $translate('Office.Tasks'),
                    state: 'office_tasks',
                    description: 'Description.for.Office.Tasks',
                    feature: 'Basicofficetaskreports'
                },
//                    { id: 'reminders',
//                        item: 'Reminders',
//                        state: 'reminders',
//                        description: 'Description.for.Reminders'
//                    },
//                    { id: 'emergency_contacts',
//                        item: $translate('Emergency.Contacts'),
//                        state: 'emergency_contacts',
//                        description: 'Description.for.Emergency.Contacts'
//                    },
//                    { id: 'attached_files',
//                        item: $translate('Attached.Files'),
//                        state: 'attached_files',
//                        description: 'Description.for.Attached.Files'
//                    },
//                    { id: 'profit_by_engineer',
//                        item: $translate('Profit.by.Engineer'),
//                        state: 'profit_by_engineer',
//                        description: 'Description.for.Profit.by.engineer'
//                    },
//                    { id: 'profit_by_hour_by_engineer',
//                        item: $translate('Profit.by.Hour.by.Engineer'),
//                        state: 'profit_by_hour_by_engineer',
//                        description: 'Description.for.Profit.by.Hour.by.Engineer'
//                    },
                {
                    id: 'gps_leaderboard',
                    item: 'GPS Leaderboard',
                    state: 'gps_leaderboard',
                    description: 'Description.for.Gps.Leaderboard.Report',
                    feature: 'Vehiclereports'
                },
                {
                    id: 'driver_trips',
                    item: 'Driver Trips',
                    state: 'driver_trips',
                    description: 'Description.for.Driver.Trips.Report',
                    feature: 'Vehiclereports'
                },
                {
                    id: 'driver_detail',
                    item: 'Driver Detail',
                    state: 'driver_detail',
                    description: 'Description.for.Driver.Detail.Report',
                    feature: 'Vehiclereports'
                },
                { id: 'daily_timecard',
                    item: 'Daily Timecard Detail',
                    state: 'daily_timecard',
                    description: 'Description.for.Daily.Time.Card.Detail.Report',
                    feature: 'DailyTimecardReport'
                },
                { id: 'summary_timesheet',
                    item: 'Summary Timesheet Detail',
                    state: 'summary_timesheet',
                    description: 'Description.for.Summary.Time.Card.Detail.Report',
                    feature: 'SummaryTimecardReport'
                },
                {
                    id: 'timesheet_approval',
                    item: 'Timesheet Approval',
                    state: 'timesheet_approval',
                    description: 'Description.for.Time.Approval.Report',
                    feature: 'TimecardApproval'
                }
            ]
        },
//            { id: 'progress',
//                firstTier: 'Progress',
//                secondTier: [
//                    { id: 'user_commusoft_learning_progress',
//                        item: $translate('User.Commusoft.learning.progress'),
//                        state: 'user_commusoft_learning_progress',
//                        description: 'Description.for.Debtors'
//                    }
//                ]
//            },
        { id: 'debtors',
            firstTier: 'Debtors',
            moduleFeature: 'debtorsReports',
            secondTier: [
                { id: 'debtors',
                    item: 'Debtors',
                    state: 'debtors',
                    description: 'Description.for.Debtors',
                    feature: 'Basicdebtorsreports'
                }
//                    { id: 'unpaid_invoices',
//                        item: $translate('Unpaid.Invoices'),
//                        state: 'unpaid_invoices',
//                        description: 'Description.for.Unpaid.Invoices'
//                    }
            ]
        },
        { id: 'creditors',
            firstTier: 'Creditors',
            moduleFeature: 'creditorsReports',
            secondTier: [
                { id: 'creditors',
                    item: 'Creditors',
                    state: 'creditors', //If you change this, need to change in reporting controller too
                    description: 'Description.for.Creditors',
                    feature: 'Basiccreditorsreports'
                }
//                    { id: 'unpaid_invoices',
//                        item: $translate('Unpaid.Invoices'),
//                        state: 'unpaid_invoices',
//                        description: 'Description.for.Unpaid.Invoices'
//                    }
            ]
        },
        { id: 'sales_proposals',
            firstTier: 'Sales proposals',
            moduleFeature: 'SalesCRMReports',
            secondTier: [
                { id: 'opportunities',
                    item: 'Opportunities',
                    state: 'opportunities', //If you change this, need to change in reporting controller too
                    description: 'Description.for.Opportunities',
                    feature: 'BasicReports'
                },
                { id: 'wonloss',
                    item: 'Sales person win/loss',
                    state: 'wonloss', //If you change this, need to change in reporting controller too
                    description: 'Description.for.Opportunities',
                    feature: 'BasicReports'
                }
            ]
        },
        { id: 'finance',
            firstTier: 'Finance',
            moduleFeature: 'FinanceRequest',
            secondTier: [
                { id: 'financerequest',
                    item: 'Finance request',
                    state: 'financerequest', //If you change this, need to change in reporting controller too
                    description: 'Description.for.Opportunities',
                    feature: 'SetupFinance',
                }
            ]
        }
    ];

    this.getReportingSubnavItems = function (isFlag) {
        var url = '/get_saved_queries';
        if(isFlag) {
          url += '?flag=1&donotshow=1';
        }
        return $http.get(prefix + url).then(function(data) {
            var extraData = data.data;
            var clonedNav = angular.copy(reportsubnavItems);
            angular.forEach(clonedNav, function(val, key) {
                angular.forEach(extraData, function(extraDataVal, extraDataKey) {
                    if (val['id'] === extraDataVal['id']) {
                        angular.forEach(extraDataVal['secondTier'], function(secondTierVal, secondTierKey) {
                            val['secondTier'].push(secondTierVal);
                        })
                    }
                })
            });
            return clonedNav;
        });

    };

    this.getMenuItems = function(extraData) {
        var clonedNav = angular.copy(reportsubnavItems);
        angular.forEach(clonedNav, function(val, key) {
            angular.forEach(extraData, function(extraDataVal, extraDataKey) {
                if (val['id'] === extraDataVal['id']) {
                    angular.forEach(extraDataVal['secondTier'], function(secondTierVal, secondTierKey) {
                        val['secondTier'].push(secondTierVal);
                    })
                }
            })
        });
        return clonedNav;
    }

    this.getTitle = function (id, option, extraData) {
        var menuItems = this.getMenuItems(extraData);
        for(var index = 0; index < menuItems.length; index++) {
            if(menuItems[index].id == id) {
                return menuItems[index][option];
            }
        };
        return false;
    };

    this.getSecondTierDetails = function (id, secondTierId, extraData, isCustom) {
        var secondTier = this.getTitle(id, 'secondTier', extraData);
        if(secondTier.length > 0) {
            for(var i = 0; i < secondTier.length; i++) {
                if(isCustom === true) {
                    if (secondTier[i].id == secondTierId && secondTier[i].custom == true) {
                        return secondTier[i];
                    }
                }
                else {
                    if (secondTier[i].id == secondTierId) {
                        return secondTier[i];
                    }
                }
            }
            return false;
        }
    };
});
