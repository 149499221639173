commusoftCommon.service('emailWidget', function() {
    var available_attachments = [];

    var email_data = {
        "recipient_addresses" : [
            "harry@customer.co",
            "mark@customer.co",
            "alan@customer.co",
            "rob@customer.co"
        ],
        "from_addresses" : [
            "joe@local.co",
            "callum@local.co",
            "jason@local.co",
            "will@local.co"
        ]
    }

    this.getAvailableAttachments = function getAvailableAttachments() {
        return available_attachments;
    }

    this.setAvailableAttachments = function setAvailableAttachments(attach) {
        available_attachments = attach;
    }
    this.getEmailData = function getEmailData() {
        return email_data;
    }
});