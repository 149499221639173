csmodule.directive('mergepricinglineitem', function($timeout, $rootScope, spreadsheetMergeRowHelper, sidePanel) {

  var handleMergeLineItem = function handleMergeLineItem(scope, element, attrs) {

      /*==========================================================================================
       Initial Variables
       ==========================================================================================*/
      scope.$parent.line_item_name = '';
      scope.$parent.line_item_name_valid = true;
      scope.$parent.merge_pricing_items = false;
      scope.$parent.show_loading_gif = false;
      scope.$parent.merge_finished = false;

      /*==========================================================================================
       When save button is clicked handle the merge if name is valid, otherwise show error msg
       ==========================================================================================*/
      scope.generateLineItem = function generateLineItem(merge_data) {
          var line_item_name_valid = scope.$parent.line_item_name.length > 0;
          scope.merge_data = scope.removeAnyTrailingStates(merge_data);

          if(line_item_name_valid) {
              scope.line_item_name = scope.$parent.line_item_name;
              scope.$parent.line_item_name_valid = true;
              scope.handleMergingAnimation();
          }else {
              scope.$parent.line_item_name_valid = false;
          }
      }

      /*==========================================================================================
       If cell is in active / edit mode when the row its in is selected need to reset its state
       ==========================================================================================*/
      scope.removeAnyTrailingStates = function removeAnyTrailingStates(merge_data) {
          for(var i = 0, l = merge_data.length; i < l; i++) {
              var row = merge_data[i].row.row,
                  cell_with_trailing_state = _.where(row, { state: 'active_mode' })[0] ||
                      _.where(row, { state: 'edit_mode' })[0];

              if(cell_with_trailing_state) {
                  cell_with_trailing_state.state = null;
              }
          }

          return merge_data;
      }

      scope.handleMerge = function handleMerge() {

          var mergeUrl = scope.$parent.$parent.mergeUrl;
          scope.merged_row_ids = [];
          scope.generated_associated_row = scope.generateAssociatedRow();
          scope.insertRowsIntoGeneratedAssociatedRow();
          scope.generateCellValues();
          spreadsheetMergeRowHelper.insertMergedRow(mergeUrl, scope.generated_associated_row, scope.merged_row_ids);
          spreadsheetMergeRowHelper.removeMergedRows(scope.merge_data);
      }

      scope.generateAssociatedRow = function generateAssociatedRow() {
          /*var associated_row = {
              "id" : null,
              "pos" : null,
              "merged_row_ids" : [],
              "row" : [
                  {
                      "associated_rows" : [
                          [
                              {
                                  "value" : "",
                                  "state" : "not_selectable",
                                  "top_merged_row" : true
                              },
                              {
                                  "value" : "",
                                  "state" : "not_selectable",
                                  "top_merged_row" : true
                              },
                              {
                                  "value" : "",
                                  "state" : "not_selectable",
                                  "top_merged_row" : true
                              },
                              {
                                  "value" : "",
                                  "state" : "not_selectable",
                                  "top_merged_row" : true
                              },
                              {
                                  "value" : "",
                                  "state" : "not_selectable",
                                  "top_merged_row" : true
                              },
                              {
                                  "value" : "",
                                  "state" : "not_selectable",
                                  "top_merged_row" : true,
                                  "hidden" : true
                              },
                              {
                                  "value" : "",
                                  "state" : "not_selectable",
                                  "top_merged_row" : true,
                                  "hidden" : true
                              }
                          ]
                      ]
                  }
              ]
          };*/
          var associated_row = spreadsheetMergeRowHelper.generateAssociatedRowsTemplate();
          scope.generated_associated_row = [];

          var associated_row = spreadsheetMergeRowHelper.generateAssociatedRowsTemplate();
          scope.generated_associated_row = [];

          return associated_row;
      }

      scope.generateCellValues = function generateCellValues() {
          var vat_rate = scope.merge_data[0].row.row[0].associated_rows[0][3].value,
              total = 0,
              total_vat = 0;

          for(var i = 0, l = scope.merge_data.length; i < l; i++) {
              total += parseFloat(scope.merge_data[0].row.row[0].associated_rows[0][4].value);
              total_vat += parseFloat(scope.merge_data[0].row.row[0].associated_rows[0][5].value);
          }

          scope.generated_associated_row['Total'] = total;
          scope.generated_associated_row['Total VAT'] = total_vat;
          scope.setCellValues(vat_rate, total);
      }

      scope.setCellValues = function setCellValues(vat_rate, total) {
          scope.generated_associated_row.row[0].associated_rows[0][0].value = scope.line_item_name;
          scope.generated_associated_row.row[0].associated_rows[0][1].value = 1;
          // Added unit price total
          scope.generated_associated_row.row[0].associated_rows[0][2].value = total;
          scope.generated_associated_row.row[0].associated_rows[0][3].value = vat_rate;
          scope.generated_associated_row.row[0].associated_rows[0][4].value = total;
      }

      scope.insertRowsIntoGeneratedAssociatedRow = function insertRowsIntoGeneratedAssociatedRow() {
          for(var i = 0, l = scope.merge_data.length; i < l; i++) {
              var generated_associated_row = scope.generated_associated_row.row[0].associated_rows,
                  row_id = scope.merge_data[i].row.id,
                  row_to_insert = scope.merge_data[i].row;

              scope.merged_row_ids.push(row_id);
              generated_associated_row.push(row_to_insert);
          }
      }

      /*==========================================================================================
       Merging animation
       ==========================================================================================*/
      scope.handleMergingAnimation = function handleMergingAnimation() {
          scope.$parent.merge_pricing_items = true;
          scope.slideItemsIntoFold();
      }

      scope.slideItemsIntoFold = function slideItemsIntoFold() {
          var pricing_items = document.querySelector('#side-panel.in-view .pricing-items-to-merge'),
              list_elms = pricing_items.children,
              ul_top_offset = pricing_items.getBoundingClientRect().top,
              fold_animation_complete = ((list_elms.length / 2) * 100) + 1100;

          $timeout(function() {
              scope.show_loading_gif = true;

              for(var i = list_elms.length -1, l = 0; i > l; i--) {
                  var list_elm = list_elms[i],
                      li_top_offset = list_elm.getBoundingClientRect().top;

                  list_elm.style.position = 'absolute';
                  list_elm.style.top = (li_top_offset - ul_top_offset) + 'px';
                  list_elm.style.marginTop = - (li_top_offset - ul_top_offset) + 'px';
                  list_elm.style.transitionDelay = ((i/2) * 100) + 'ms';
                  list_elm.style.transform = 'scale(0.9)';
              }
          }, 450);

          // Animation has ended
          $timeout(function() {
              scope.$parent.merge_pricing_items = false;
              scope.show_loading_gif = false;
              scope.$parent.merge_finished = true;

              var top_item = list_elms[0];

              top_item.style.borderBottomLeftRadius = 4 + 'px';
              top_item.style.borderBottomRightRadius = 4 + 'px';

          }, fold_animation_complete);


          // Close sidepanel
          $timeout(function() {
              sidePanel.close();
          }, fold_animation_complete + 1000);

          // When sidepanel has closed create and insert the merged row and reset
          // styles / scope variables used in the animation
          $timeout(function() {
              scope.handleMerge();
              scope.handleResets();
              $rootScope.$emit('spreadsheet:reCalculateSubtotals');
          }, fold_animation_complete + 1300);

      }

      scope.handleResets = function handleResets() {
          scope.$parent.line_item_name = '';
          scope.$parent.merge_pricing_items = false;
          scope.$parent.show_loading_gif = false;
          scope.$parent.merge_finished = false;
      }
  }

  return {
      restrict: 'A',
      templateUrl: 'template/estimate/estimate_details/price/merge_pricing_item_button.html',
      scope: {
          panelContent: '='
      },
      link: handleMergeLineItem
  }
});