/*********************************************************
 ManageBreaksCTL Side Panels
 *********************************************************/
function ManageBreaksCTL($scope, $state, $stateParams, $rootScope, $http, $timeout, prefix, toastBox, $translate)
{
    $scope.loading = false;
    $scope.shift_scope = $scope.$parent.$parent;
    $scope.temp_shift_breaks = JSON.parse(JSON.stringify($rootScope.active_shift_breaks));
    $scope.shift_breaks = JSON.parse(JSON.stringify($rootScope.active_shift_breaks));
    if($scope.shift_breaks){
        $scope.shift_breaks.forEach((value, index) => {
            $scope.shift_breaks[index]['start_time'] = moment(value['startTime']['date']).stripZone().format();
            $scope.shift_breaks[index]['end_time'] = moment(value['endTime']['date']).stripZone().format();
            let break_options = setTimeDifferenceArray($scope.shift_breaks[index]['start_time'], $scope.shift_breaks[index]['end_time']);
            $scope.shift_breaks[index]['length_break_options'] = break_options;
        })
    }
    $scope.panel_title = $translate('Manage.Breaks');
    $scope.closePanel = function closePanel() {
        $scope.active_slide = 1;
        $scope.loading = false;
        angular.element('#mainWindow').remove();
        $rootScope.$broadcast('closeAllSidepanels');
    };
    $rootScope.$on('parent:updated', function(evt) {
        $scope.active_slide = 1;
        $scope.loading = false;
        $scope.closePanel();
    });
    $scope.openPanel = function openPanel() {
        $timeout(function() {
            $scope.initialise();
        }, 0);
    };
    // Add new item shift breaks
    $scope.addNewItem = function() {
        let startDateTime = moment().startOf('day').stripZone().format();
        let demodate = startDateTime;
        let endDateTime = moment(demodate).add(30, "minutes").stripZone().format();
        let break_options = setTimeDifferenceArray(startDateTime, endDateTime);
        $scope.shift_breaks.push({
            'id': null,
            'lengthofbreak': 0,
            'start_time': startDateTime,
            'end_time': endDateTime,
            'deleted': 0,
            'dateError': false,
            'length_break_options': break_options
        })
    };
    // Remove shift breaks while deleting its frontend side only
    $scope.onRemoveItem = function(item_index) {
        $scope.shift_breaks.splice(item_index,1);
    };
    // for now not required this
    $scope.getShiftBreaks = function(){
        $http.get(Routing.generate("manage_shift_breaks", { shift_id: $rootScope.active_shiftId }))
            .success(function (shift_data) {
                $scope.shift_breaks = shift_data['shift_breaks']
            })
            .catch(function (e) {
                console.log(e)
            })
    };
    // Time change function
    $scope.changeEventDateTime = function(name, value, $index) {
        $scope.shift_breaks[$index][name] = moment(value);
        let startDateTime = moment($scope.shift_breaks[$index]['start_time']);
        let end_time = moment($scope.shift_breaks[$index]['end_time'])
        let endDateTime = (moment(end_time).format('HH-mm') == '00-00') ? moment(end_time).subtract(1, 'minutes') : moment(end_time);
        let  timeDiff = moment(endDateTime).diff(moment(startDateTime))/1000/60;
        if(endDateTime.format('YYYY-MM-DD') != startDateTime.format('YYYY-MM-DD') || endDateTime <= startDateTime) {
            $scope.shift_breaks[$index]['dateError'] = true;
            $scope.shift_breaks[$index]['length_break_options'] = []
        }
        else if(timeDiff <= 15) {
            $scope.shift_breaks[$index]['dateError'] = true;
            $scope.shift_breaks[$index]['length_break_options'] = []
        }
        else {
            $scope.shift_breaks[$index]['dateError'] = false;
            $scope.shift_breaks[$index]['length_break_options'] = setTimeDifferenceArray(startDateTime, endDateTime)
        }
        $scope.shift_breaks[$index]['lengthofbreak'] = 0;
        $scope.shift_breaks[$index]['start_time'] = startDateTime.stripZone().format()
        $scope.shift_breaks[$index]['end_time'] = endDateTime.stripZone().format()
    };
    $scope.validateBreaks = function () {
        let time_valid =  _.where($scope.shift_breaks, { dateError: true });
        let length_break_valid =  _.where($scope.shift_breaks, { lengthofbreak: 0 });
        if(($scope.shift_breaks && $scope.shift_breaks.length === 0 && $scope.temp_shift_breaks.length === 0)
            || time_valid.length > 0 || length_break_valid.length > 0) {
            return true
        }
        else {
            return false
        }

    };
    $scope.onSubmit = function () {
        $scope.loading = true;
        let data = {'shiftId': $rootScope.active_shiftId, 'shift_breaks': $scope.shift_breaks};
        $http.post(Routing.generate("add_shift_breaks"), 'data='+encodeURIComponent(JSON.stringify(data)))
            .success(function (shift_data) {
                $scope.loading = false;
                $rootScope.active_shift_breaks = shift_data['shift_breaks'];
                toastBox.show($translate('Shift.Breaks.Saved'), 4000);
                $scope.closePanel();
            })
            .catch(function (e) {
                console.log(e)
            })
    };

    function setTimeDifferenceArray(start, end) {
        let timeDiffArray = [];
        let  timeDiff = moment(end).diff(moment(start))/1000/60;
        let slotDuration = '00:15:00';
        let slotMinutes = moment.duration(slotDuration)._data.minutes;
        if(timeDiff > slotMinutes) {
            for(var count = 1; count < timeDiff/slotMinutes; count++) {
                var duration = count*slotMinutes;
                var durationStr = '';
                var hours = Math.floor(duration / 60);
                var minutes = duration % 60;
                if(hours > 0) {
                    durationStr += hours + ' hour';
                }
                if(minutes > 0) {
                    durationStr += (durationStr != '') ? ' ' : '';
                    durationStr += minutes + ' mins';
                }
                timeDiffArray.push({'value' : duration, 'description':durationStr});
            }
        }
        return timeDiffArray;
    }

    //$scope.getShiftBreaks();
}

csmodule.controller('ManageBreaks', ManageBreaksCTL);