csmodule.controller('outstandingJobsSidePanelOnDiaryCtrl', function ($scope, $rootScope, $timeout, $state, $filter, $diaryMapScope, outstandingJobsService, dateRange) {
    $scope.foreignWatchers = {};
    $scope.jobSearchQuery = '';
    $scope.outstandingJobsList = [];
    $scope.totalOutstandingJobs = 0;
    $scope.fetchingOutstandingJobs = false;

    // Filters
    $scope.filtersParams = {
        recordType: '',
        page: 1,
        limit: 50
    };

    $scope._editFilters = false;
    $scope.fromDateTime = outstandingJobsService.start_time;
    $scope.toDateTime = outstandingJobsService.end_time;
    $scope.showDatePickerPopup = {
        fromDateTime: false,
        toDateTime: false
    };
    $scope.dateFilterOptions = dateRange.getFilterOptions();

    // Moment locale settings
    moment.locale('en', {
        calendar: {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: '[Last] dddd',
            nextWeek: 'dddd',
            sameElse: 'DD/MM/YYYY'
        }
    });

    $scope.showDatePicker = function (name) {
        $timeout(function () {
            $scope.$apply(function () {
                $scope.showDatePickerPopup[name] = true;
            })
        })
    };

    $scope.onTimeChange = function (name, value) {
        if (name === 'fromDateTime') {
            $scope.fromDateTime = value ? value._d : '';
        }
        else if (name === 'toDateTime') {
            $scope.toDateTime = value ? value._d : '';
        }
    };

    $scope.editFilters = function () {
        $scope._editFilters = true;
    };

    $scope.filtersAreValid = function () {
        return (!$scope.fromDateTime && !$scope.toDateTime)
            || ($scope.fromDateTime && $scope.toDateTime && moment($scope.fromDateTime).isBefore($scope.toDateTime));
    };

    $scope.applyFilters = function () {
        $scope._editFilters = false;

        $scope.filtersParams.page = 1;

        var filterParams = angular.copy($scope.filtersParams);
        filterParams.start_time = $scope.fromDateTime ? moment($scope.fromDateTime).set({seconds: 0}).toISOString() : null;
        filterParams.end_time = $scope.toDateTime ? moment($scope.toDateTime).set({seconds: 59}).toISOString() : null;
        filterParams.search_text = $scope.jobSearchQuery;

        $scope.fetchingOutstandingJobs = true;
        outstandingJobsService.getOutstandingJobs(filterParams).then(function (data) {
            if (data) $scope.updateData(data);
            $scope.fetchingOutstandingJobs = false;
        }).catch(function (error) {
            $scope.fetchingOutstandingJobs = false;
        });

    };

    $scope.loadMoreOutstandingJobs = function () {
        if($scope.fetchingOutstandingJobs) return;

        var loadedMaximum = ($scope.filtersParams.page * $scope.filtersParams.limit) >= $scope.totalOutstandingJobs,
            scrollingUp = $scope.filtersParams.page >= Math.ceil($scope.totalOutstandingJobs / $scope.filtersParams.limit);

        if (loadedMaximum || scrollingUp) return;

        $scope.filtersParams.page += 1;

        var filterParams = angular.copy($scope.filtersParams);
        filterParams.start_time = $scope.fromDateTime ? moment($scope.fromDateTime).set({seconds: 0}).toISOString() : null;
        filterParams.end_time = $scope.toDateTime ? moment($scope.toDateTime).set({seconds: 59}).toISOString() : null;
        filterParams.search_text = $scope.jobSearchQuery;

        $scope.fetchingOutstandingJobs = true;
        outstandingJobsService.getOutstandingJobs(filterParams).then(function (data) {
            if (data) $scope.updateData(data);
            $scope.fetchingOutstandingJobs = false;
        }).catch(function (error) {
            $scope.fetchingOutstandingJobs = false;
        });
    };

    $scope.handleJobSearchQuery = function () {
        $scope.filtersParams.page = 1;

        var filterParams = angular.copy($scope.filtersParams);
        filterParams.start_time = $scope.fromDateTime ? moment($scope.fromDateTime).set({seconds: 0}).toISOString() : null;
        filterParams.end_time = $scope.toDateTime ? moment($scope.toDateTime).set({seconds: 59}).toISOString() : null;


        $scope.fetchingOutstandingJobs = true;
        outstandingJobsService.searchOutstandingJobs($scope.jobSearchQuery, filterParams).then(function (data) {
            if (data) $scope.updateData(data);
            $scope.fetchingOutstandingJobs = false;
        }).catch(function (error) {
            $scope.fetchingOutstandingJobs = false;
        });
    };

    $scope.updateData = function (data) {

        if ($scope.filtersParams.page > 1) {
            $scope.outstandingJobsList = $scope.outstandingJobsList.concat(data.outstanding_jobs);
        } else
            $scope.outstandingJobsList = data.outstanding_jobs;

        $scope.totalOutstandingJobs = data.count;

        var dueWindows = angular.copy($scope.panel_content.jobDueWindows);

        dueWindows = dueWindows.map(function (w) {
            w.outstandingJobs = $scope.outstandingJobsList.filter(function (j) {
                return j.dueWindowName === w.windowName;
            });

            return w;
        });

        $diaryMapScope.onJobDueWindowsRefresh(dueWindows);
    };

    $scope.clearFilters = function () {
        $scope.fromDateTime = '';
        $scope.toDateTime = '';
        $scope._fromTimePicker = '';
        $scope._toTimePicker = '';

        $scope.applyFilters();
    };

    $scope.sortOutstandingJobsByImportantFirst = function (jobs) {
        jobs.sort(function (a, b) {

            if (!a.endDatetime || !b.endDatetime) {
                if (a.endDatetime) return -1;
                else if (b.endDatetime) return 1;
                else return a.id - b.id;

            } else {
                var aT = moment(a.endDatetime || '0'),
                    bT = moment(b.endDatetime || '0');

                if (aT.isSame(bT) || aT.isBefore(bT)) return -1;
                else if (aT.isAfter(bT)) return 1;
                else return a.id - b.id;
            }
        });

        $scope.outstandingJobsList = jobs;
    };

    $scope.enableDatePicker = function (fieldName) {
        $scope.selectedDate = $scope[fieldName];
        $scope.showDatePicker = fieldName;
    };
    $scope.onDateFilerChange = function (fieldName) {
        if ($scope[fieldName] !== $scope.selectedDate && $scope.showDatePicker) {
            $scope[fieldName] = $scope.selectedDate;
            $scope.showDatePicker = '';
        }
    };

    $scope.redirectToJob = function (event, oj) {
        event.stopPropagation();
        var url = '';
        if(oj.recordType == 'job') {
            url = $state.href("loggedin.customer_list.view.job.details", {
                id: oj.customerId,
                jobId: oj.id,
                type: oj.propertyType
            });
        } else {
            var stateName = oj.isProject ? 'loggedin.customer_list.view.project_estimate_details.survey' : 'loggedin.customer_list.view.estimate_details.survey';
            url = $state.href(stateName, {
                id: oj.customerId,
                estimateId: oj.id,
                type: oj.propertyType
            });
        }

        window.open(url, '_blank');

        $scope.closeJobsSidePanel();
    };

    $scope.handleJobClick = function (oj) {
        if (oj.dueWindowName) {
            $diaryMapScope.handleOutstandingJobSelection(oj, 'SIDE_PANEL');
            $scope.closeJobsSidePanel();
        } else return false;
    };

    // Close panel
    $scope.closeJobsSidePanel = function () {
        $scope.$destroy();
        $rootScope.$broadcast('sidepanel:close');
    };

    $scope.getJobTime = function (oj) {
        var start = oj.startDatetime ? moment(oj.startDatetime) : null,
            end = oj.endDatetime ? moment(oj.endDatetime) : null;

        if (!start || !end) return '';
        else if (end.diff(start, 'Days') == 0) {
            return start.calendar() + ' ' + start.format('hh:mm a') + ' - ' + end.format('hh:mm a');

        } else {
            return start.format('DD/MM/YYYY hh:mm a') + ' - ' + end.format('DD/MM/YYYY hh:mm a');
        }
    };

    // Removes events on panel close
    $scope.$on('$destroy', function () {

        Object.keys($scope.foreignWatchers).forEach(function (n) {
            $scope.foreignWatchers[n]();
        });

    });

    // Initial load
    $scope.applyFilters();

    // Initialization
    $timeout(function () {
        var scrollable_wrapper = document.querySelector('#scrollable-outstanding-jobs');
        scrollable_wrapper.scrollTop = 0;

        $scope.$broadcast('daterange:changed', $scope.dateFilterOptions[6]);

    }, 1000);
});