commusoftCommon.directive('passwordValidate', function() {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function(viewValue) {

                scope.validLength = (viewValue && viewValue.length >= 6 ? 'active' : undefined);
                scope.validLetter = (viewValue && /[A-z]/.test(viewValue)) ? 'active' : undefined;
                scope.validNumber = (viewValue && /\d/.test(viewValue)) ? 'active' : undefined;

                if(viewValue.length >= 6){
                    if(scope.validLetter == undefined || scope.validNumber == undefined){
                        scope.letterMessage = true;
                    }else{
                        scope.letterMessage = false;
                    }
                }
                if(scope.validLength && scope.validLetter && scope.validNumber) {
                    ctrl.$setValidity('password', true);
                    return viewValue;
                } else {
                    ctrl.$setValidity('password', false);
                    return viewValue;
                }

            });
        }
    };
});