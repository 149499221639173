commusoftCommon.directive('compileHtml', ['$compile', function($compile) {
    return {
        restrict: 'A',
        replace: true,
        link: function (scope, element, attrs) {
            var isHTML = RegExp.prototype.test.bind(/</g);
            scope.$watch(attrs.compileHtml, function(value)  {
                if (value != "" && isHTML(value)) {
                  //element.html ($compile(value)(scope));
                  var ngContainer = $('<ng-container></ng-container>').html(value);
                    element.html ($compile(ngContainer)(scope));
                }
                else {
                  element.html ($compile(value)(scope));
                }
            });
        }
    };
}]);