'use strict';

var stockModule = angular.module('stocks', []);

stockModule.constant('prefix', window.prefixVal).
    constant('defaultPagingValue', defaultPaginationLimit).
    provider('canLoad',function () {
        this.$get = function () {
            return {
                canLoadAjax: true,
                setLoadValue: function (canLoad) {
                    this.canLoadAjax = canLoad;
                },
                canLoad: function () {
                    return this.canLoadAjax;
                }
            }
        }
    }).
    config(
        ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
            function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {
                $stateProvider.
                    state('loggedin.stocks', {//Todo: this will be the list view url
                        url: Routing.generateAngularRoute('stocks', true),
                        templateUrl: function (params) {
                            return Routing.generate('stocks')
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: StocksCtrl,
                                resolve: Resolver
                            },
                            "stocksPane@": {
                                templateUrl: 'template/stock/stock_configure.html',
                                controller: StockNavCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).state('loggedin.stocks_view', {
                        url: Routing.generateAngularRoute('stockNavDetails', true),
                        templateUrl: function (params) {
                            return Routing.generate('stockNavDetails', params)
                        },
                        views: {
                            "": {
                                templateUrl: 'template/stock/layout.html',
                                controller: StockNavCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).state('loggedin.stocks_view.stock', {
                        url: Routing.generateAngularRoute('stock_details', true),
                        templateUrl: function (params) {
                            return Routing.generate('stock_details', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "stocksTabPane": {
                                templateUrl: 'template/stock/stock.html',
                                controller: StocksCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).state('loggedin.stocks_view.history', {
                        url: Routing.generateAngularRoute('stock_history', true),
                        templateUrl: function (params) {
                            return Routing.generate('stock_history', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 20
                        },
                        views: {
                            "stocksTabPane": {
                                templateUrl: 'template/stock/history.html',
                                controller: StocksHistoryCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).state('loggedin.stocks_view.suppliers', {
                        url: Routing.generateAngularRoute('stock_suppliers', true),
                        templateUrl: function (params) {
                            return Routing.generate('stock_suppliers', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 20
                        },
                        views: {
                            "stocksTabPane": {
                                templateUrl: 'template/stock/suppliers.html',
                                controller: StockSuppliersCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).state('loggedin.stocks_view.reserve_list', {
                        url: Routing.generateAngularRoute('stock_reserve_list', true),
                        templateUrl: function (params) {
                            return Routing.generate('stock_reserve_list', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "stocksTabPane": {
                                templateUrl: 'template/stock/reserve_list.html',
                                controller: StockReserveListCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.stocks_view.edit_internal_details', {
                        url: Routing.generateAngularRoute('edit_internal_details', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_internal_details', params)
                        },
                        views: {
                            "stocksTabPane": {
                                template: globalTemplate,
                                controller: EditInternalDetailsCtrl,
                                resolve: Resolver
                            }
                        }
                    })
                    .state('loggedin.stock_locations_view', {
                        url: Routing.generateAngularRoute('stockLocationNavDetails', true),
                        templateUrl: function (params) {
                            return Routing.generate('stockLocationNavDetails', params)
                        },
                        views: {
                            "": {
                                templateUrl: 'template/stock/stock_location_layout.html',
                                controller: StockLocationNavCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    })
                    .state('loggedin.stock_locations_view.stock_location_details', {
                        url: Routing.generateAngularRoute('stock_location_details', true),
                        templateUrl: function (params) {
                            return Routing.generate('stock_location_details', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 20
                        },
                        views: {
                            "stocksTabPane": {
                                templateUrl: 'template/stock/stock_location.html',
                                controller: StockLocationCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    })
                    .state('loggedin.stock_locations_view.history', {
                        url: Routing.generateAngularRoute('stock_location_history', true),
                        templateUrl: function (params) {
                            return Routing.generate('stock_location_history', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 20
                        },
                        views: {
                            "stocksTabPane": {
                                templateUrl: 'template/stock/stock_location_history.html',
                                controller: StockLocationHistoryCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    })
                    .state('loggedin.stock_locations_view.reserve_list', {
                        url: Routing.generateAngularRoute('stock_location_reserve_list', true),
                        templateUrl: function (params) {
                            return Routing.generate('stock_location_reserve_list', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "stocksTabPane": {
                                templateUrl: 'template/stock/stock_location_reserve_list.html',
                                controller: StockLocationReserveListCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    })
                    .state('loggedin.stock_add_stock_for_first_time', {
                        url: Routing.generateAngularRoute('stock_add_stock_for_first_time', true),
                        templateUrl: function (params) {
                            return Routing.generate('stock_add_stock_for_first_time', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "": {
                                templateUrl: 'template/stock/stock_new_part_spreadsheet.html',
                                controller: StockLocationSpreadSheetCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    });
            }
        ]);
