commusoftCommon.directive("smartpostcodeform", function($http, $timeout, $rootScope, postCodeHelper, panelValidationHelper, prefix) {
    var handleSmartPostCode = function handleSmartPostCode(scope, element, attrs) {
        /*==========================================================================================
         Initial Variables
         ==========================================================================================*/
        scope.address_matches = [];
        scope.data_arrived = false;
        scope.search_fulladdress = false;
        scope.just_postcode = attrs.justPostcode || false;

        scope.formatted_postcode = angular.uppercase(attrs.postcode);

        /*==========================================================================================
         Find address with the entered postcode
         ==========================================================================================*/
        scope.lookUpAddress = function lookUpAddress() {
            scope.$emit('smartpostcode:loading', true);
            $http.get(prefix + '/search_postcode?postcode=' + scope.formatted_postcode).then(function(data) {
                scope.$emit('smartpostcode:loading', false);
                // TODO: Handle Errors from Backend
                scope.address_matches = data.data.address;
                scope.data_arrived = true;

                scope.$emit('smartpostcode:addresses_list_arrived', data.data.address)
            });
        }

        // RAJA: Not sure when scope,just_postcode == false as the only times we have used it is for
        // postcode. NOTE in case this happens to handle it properly. We dont need to assign to selected_address
        // because thast the name of the model.
        scope.handleAddress = function handleAddress() {
            if (scope.just_postcode !== "true") {
                scope.selected_address = this.address;
            } else {
                scope.selected_address = this.selected_address;
            }
        }

        scope.useAddress = function useAddress() {
            var addressArray = this.selected_address.split(',');
            scope.$emit('smartpostcode:address', {
                'addressLine1': addressArray[0].trim(),
                'addressLine2': [addressArray[1].trim(),addressArray[2].trim()].filter(Boolean).join(","),
                'addressLine3': [addressArray[3].trim(),addressArray[4].trim()].filter(Boolean).join(","),
                'town'  : addressArray[5].trim(),
                'county': addressArray[6].trim(),
                'postcode': scope.formatted_postcode
            });
        }

        $rootScope.$$listeners['smartpostcode:use_address'] = [];	// Reinit the listeners
        $rootScope.$on('smartpostcode:use_address', function(e) {
            scope.useAddress();
        })

        var initialise = (function() {
            scope.lookUpAddress();
        })();
    }

    return {
        restrict: 'A',
        scope: {},
        templateUrl: '/template/shared/smart_postcode_form.html',
        link: handleSmartPostCode
    }
});