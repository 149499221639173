csmodule.directive('csTimePicker', function() {
    var handleTimePicker = function handleGraphWidget(scope, element, attrs, ngModel) {
        scope.nullable = (attrs['nullable'] == 'true')? true: false;

        scope.selected = !scope.nullable? moment(): null;
        var meridians = ['AM', 'PM'];
        scope.showMeridian = (attrs['showMeridian'] == 'false') ? false : true;
        scope.isDisabled = (attrs['isDisabled'] == 'true') ? true : false;
        scope.clockHours = (attrs['clockHours'] == '24') ? 24: 12;
        scope.perMinute = (attrs['perMinute']) ? attrs['perMinute'] : false; 

        scope.updateTemplate = function(keyboardChange, value) {
            if(scope.selected){
                var hours = scope.selected.hours(), minutes = scope.selected.minutes();
                if(scope.clockHours === 12) hours = ( hours === 0 || hours === 12 ) ? 12 : hours % 12;
                scope.hours =  keyboardChange === 'h' ? hours : pad(hours);
                scope.minutes = keyboardChange === 'm' ? minutes : pad(minutes);
                scope.meridian = scope.selected.hours() < 12 ? meridians[0] : meridians[1];
            } else {
                scope.hours =  '';
                scope.minutes = '';
                scope.meridian = '';
            }

        };

        function pad( value ) {
            return ( angular.isDefined(value) && value.toString().length < 2 ) ? '0' + value : value;
        }

        scope.toggleMeridian = function() {
            var add = (scope.meridian == 'PM') ? 12 : -12;
            if(!scope.selected) scope.selected = moment();
            scope.selected.add(add, 'hour');
            scope.updateModel();
            scope.updateTemplate();
        }

        ngModel.$render = function() {
            var date = ngModel.$modelValue ? moment(ngModel.$modelValue) : null;

            if (isNaN(date)) {
                ngModel.$setValidity('time', false);
                console.log('Commusoft time picker directive: "ng-model" value must be a Date object.');
            } else {
                if (date) {
                    scope.selected = date;
                } else if (scope.nullable) scope.selected = null;
                scope.updateTemplate(null, date);
            }
        };

        scope.updateHours = function(hours) {
            scope.hours = hours;
            var hoursSelected = parseInt(scope.hours);
            var actualHour = hoursSelected;
            if(scope.clockHours === 12) actualHour = (scope.meridian == 'PM' && hoursSelected < 12) ? hoursSelected + 12 : hoursSelected;
            if(!scope.selected) scope.selected = moment().set({minutes: 0});
            scope.selected.hours(actualHour);
            scope.updateModel();
        }

        scope.updateMinutes = function() {
            if(!scope.selected) scope.selected = moment();
            scope.selected.minutes(scope.minutes);
            scope.updateModel();
        }

        scope.updateModel = function () {
            scope.updateTemplate();
            var dateTime = moment(scope.selected);
            ngModel.$setViewValue(dateTime);
            scope.changeFunc({name: attrs['ngModel'], value: dateTime});
            scope.$emit('setDatepicker', {name: attrs['ngModel'], value: dateTime});
            console.log(scope.selected.format('DD/MM/YYYY HH:mm') + ' updated');
        }

        scope.$on('disableStartDateTime', function(event, message) {
            if(message.name == attrs['ngModel']) {
                scope.isDisabled = true;
            }
        })

        scope.$on('event:change-date-value', function(event, message) {
            var temp = moment(scope.selected);
            scope.selected = moment(message.date).clone().hours(temp.hours()).minutes(temp.minutes());
            console.log(scope.selected.format('DD/MM/YYYY HH:mm') + ' dit');
        });

        /**
         * Disable input elements diary events datetime
         */
        scope.$watch('disabledProperty', function(){
            if(scope.disabledProperty == true || scope.isDisabled){
                scope.isDisabled = true;
            }
            else{
                scope.isDisabled = false;
            }
        });
    }

    return {
        restrict: 'E',
        templateUrl: 'template/timepicker/timepicker.html',
        scope: {
            changeFunc: '&',
            disabledProperty: '='
        },
        require: 'ngModel',
        replace: true,
        transclude: true,
        link: handleTimePicker
    }
});


/** TimepickerPopup Directive
 *  Took from https://github.com/angular-ui/bootstrap/issues/1604
 */

csmodule.constant('csTimepickerPopupConfig', {
    timeFormat: 'HH:mm:ss',
    appendToBody: false
}).directive('csTimepickerPopup', ['$compile', '$parse', '$document', '$position', 'dateFilter', 'csTimepickerPopupConfig', 'timepickerConfig',
    function ($compile, $parse, $document, $position, dateFilter, csTimepickerPopupConfig, timepickerConfig) {
        return {
            restrict: 'EA',
            require: 'ngModel',
            priority: 1,
            link: function(originalScope, element, attrs, ngModel) {
                var scope = originalScope.$new(), // create a child scope so we are not polluting original one
                    timeFormat,
                    appendToBody = angular.isDefined(attrs.csTimepickerAppendToBody) ? originalScope.$eval(attrs.csTimepickerAppendToBody) : csTimepickerPopupConfig.appendToBody;

                scope.bindPicker = true;

                attrs.$observe('csTimepickerPopup', function(value) {
                    timeFormat = value || csTimepickerPopupConfig.timeFormat;
                    ngModel.$render();
                });

                originalScope.$on('$destroy', function() {
                    $popup.remove();
                    scope.$destroy();
                });

                var getIsOpen, setIsOpen;
                if ( attrs.isOpen ) {
                    getIsOpen = $parse(attrs.isOpen);
                    setIsOpen = getIsOpen.assign;

                    originalScope.$watch(getIsOpen, function updateOpen(value) {
                        scope.isOpen = !! value;
                    });
                }
                scope.isOpen = getIsOpen ? getIsOpen(originalScope) : false; // Initial state

                function setOpen( value ) {
                    if (setIsOpen) {
                        setIsOpen(originalScope, !!value);
                    } else {
                        scope.isOpen = !!value;
                    }
                }

                var documentClickBind = function(event) {
                    if (scope.isOpen && event.target !== element[0]) {
                        scope.$apply(function() {
                            setOpen(false);
                        });
                    }
                };

                var elementFocusBind = function() {
                    scope.$apply(function() {
                        setOpen( true );
                    });
                };

                // popup element used to display calendar
                var popupEl = angular.element('<div cs-timepicker-popup-wrap><cs-time-picker ng-if="bindPicker" ng-model="date" ng-change="dateSelection(date._d)"></cs-time-picker></div>');
                popupEl.attr({
                    'ng-model': 'date',
                    'ng-change': 'dateSelection()'
                });
                var csTimepickerEl = angular.element(popupEl.children()[0]),
                    csTimepickerOptions = {};
                if (attrs.csTimepickerOptions) {
                    csTimepickerOptions = originalScope.$eval(attrs.csTimepickerOptions);
                    csTimepickerEl.attr(angular.extend({}, csTimepickerOptions));
                }

                function parseTime(viewValue) {
                    if (!viewValue) {
                        ngModel.$setValidity('time', true);
                        return null;
                    } else if (angular.isDate(viewValue)) {
                        ngModel.$setValidity('time', true);
                        return viewValue;
                    } else if (angular.isString(viewValue)) {
                        var date = new moment('1970-01-01 ' + viewValue, 'YYY-MM-DD ' + timeFormat);

                        if (!date.isValid()) {
                            ngModel.$setValidity('time', false);
                            return undefined;
                        } else {
                            ngModel.$setValidity('time', true);
                            return date.toDate();
                        }
                    } else {
                        ngModel.$setValidity('time', false);
                        return undefined;
                    }
                }
                ngModel.$parsers.unshift(parseTime);

                // Inner change
                scope.dateSelection = function(dt) {
                    if (angular.isDefined(dt)) {
                        scope.date = dt;
                    }
                    ngModel.$setViewValue(scope.date);
                    ngModel.$render();
                };

                element.bind('input change keyup', function() {
                    scope.$apply(function() {
                        scope.date = ngModel.$modelValue;
                    });
                });

                // Outter change
                ngModel.$render = function() {
                    var date = ngModel.$viewValue ? dateFilter(ngModel.$viewValue, timeFormat) : '';
                    element.val(date);
                    scope.date = ngModel.$modelValue;
                };

                function addWatchableAttribute(attribute, scopeProperty, csTimepickerAttribute) {
                    if (attribute) {
                        originalScope.$watch($parse(attribute), function(value){
                            scope[scopeProperty] = value;
                        });
                        csTimepickerEl.attr(csTimepickerAttribute || scopeProperty, scopeProperty);
                    }
                }

                if (attrs.showMeridian) {
                    csTimepickerEl.attr('show-meridian', attrs.showMeridian);
                }

                if (attrs.showSeconds) {
                    csTimepickerEl.attr('show-seconds', attrs.showSeconds);
                }

                function updatePosition() {
                    // var position = appendToBody ? $position.offset(element) : $position.position(element);
                    // position.top = scope.position.top + element.prop('offsetHeight');

                    var offset = $position.offset(element);
                    var position = $position.position(element);
                    var windowWidth = window.innerWidth;
                    var windowHeight = window.innerHeight;
                    var hAlign = 'left';
                    var vAlign = 'bottom';

                    var styles = {
                        position: position,
                        width: parseInt($(popupEl).css('width')),
                        height: parseInt($(popupEl).css('height'))
                    };

                    // Horizontal overflow
                    if(offset.left + styles.width >= windowWidth){
                        hAlign = 'right';
                        styles.position.left = -(styles.width - position.width) + position.left;
                    }

                    // Vertical overflow
                    if(offset.top + position.height + styles.height >= windowHeight){
                        vAlign = 'top';
                        styles.position.top = -(styles.height - position.height) + position.top;
                    }

                    styles.align =  vAlign + '-' + hAlign;
                    switch(styles.align) {
                        case "bottom-left": styles.position.top = styles.position.top + element.prop('offsetHeight') + 6;break;
                        case "bottom-right": styles.position.top = styles.position.top + element.prop('offsetHeight') + 6;break;
                        case "top-left": styles.position.top = styles.position.top - element.prop('offsetHeight') - 6;break;
                        case "top-right": styles.position.top = styles.position.top - element.prop('offsetHeight') - 6;break;
                    }
                    scope.styles = styles;
                }

                var documentBindingInitialized = false, elementFocusInitialized = false;
                scope.$watch('isOpen', function(value) {
                    if (value) {
                        updatePosition();
                        $document.bind('click', documentClickBind);
                        if(elementFocusInitialized) {
                            element.unbind('focus', elementFocusBind);
                        }
                        element[0].focus();
                        documentBindingInitialized = true;

                        scope.bindPicker = true;
                    } else {
                        if(documentBindingInitialized) {
                            $document.unbind('click', documentClickBind);
                        }
                        element.bind('focus', elementFocusBind);
                        elementFocusInitialized = true;

                        scope.bindPicker = false;
                    }

                    if ( setIsOpen ) {
                        setIsOpen(originalScope, value);
                    }
                });

                var $popup = $compile(popupEl)(scope);
                if ( appendToBody ) {
                    $document.find('body').append($popup);
                } else {
                    element.after($popup);
                }
            }
        };
    }
]).directive('csTimepickerPopupWrap', function() {
    return {
        restrict:'EA',
        replace: true,
        transclude: true,
        templateUrl: 'template/timepicker/popup.html',
        link:function (scope, element, attrs) {
            element.bind('click', function(event) {
                event.preventDefault();
                event.stopPropagation();
            });
        }
    };
});