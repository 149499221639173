commusoftCommon.directive('fullscreen', ['$state', 'prefix', '$rootScope', '$document', '$http', '$timeout', 'template', 'fullScreenEditorCollection', '$translate', function($state, prefix, $rootScope, $document, $http, $timeout, template, fullScreenEditorCollection, $translate ) {

    var handleFullScreen = function handleFullScreen(scope, element, attrs) {

        scope.tinymceFullOptions = {
            plugins: "paste,link,image,textcolor,legacyoutput",
            menubar: false,
            paste_as_text: true,
            browser_spellcheck : true,
            content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
            toolbar: "fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright",
            resize: false,
            fontsize_formats: "1 2 3 4 5 6 7"
        };

        scope.$on("estimateContent", function (event, message) {
            scope.estimateFullScreen = message;
            var tinyInstance = tinymce.get('fullscreen');
            if (tinyInstance) {
                tinyInstance.setContent(message);
            }
        });


        /*====================================================
         Load the template, currently it can either be an
         wysig editor or a map
         ====================================================*/
        var tpl_path = "template/estimate/full_screen/" + attrs.template + ".html";

        template.load(tpl_path, element, scope);

        /*====================================================
         Instance Variables
         ====================================================*/
        scope.fullscreen_type = attrs.template;
        scope.category = attrs.category;
        scope.full_screen = false;
        scope.full_screen_panel_open = true;
        scope.editor_full_width = false;
        scope.estimateFeedbackPreviewProps = {
            title: 'Estimate feedback and notes'
        };
        scope.showRightBar = false;

        scope.initialise = function initialise() {
            scope.full_screen_elm = element.next()[0];

            if (scope.full_screen === false) {
                scope.showFullScreenEditor();
                scope.registerKeyboardListeners();
            }

            scope.full_screen = true;
        }

        /*====================================================
         Show the full screen editor
         ====================================================*/
        scope.showFullScreenEditor = function showFullScreenEditor() {
            scope.full_screen_elm.classList.add('in-view');

            if (scope.fullscreen_type === "editor") {
                $('#estimate_body_editor').wysihtml5();
                scope.full_screen_panel_elm = document.querySelector('#fullscreen-editor-panel');
                scope.loadPanelData();
            }
        }

        /*====================================================
         Hide the full screen editor
         ====================================================*/
        scope.hideFullScreen = function hideFullScreen(e) {
            if (scope.full_screen_panel_open === true) {
                if (scope.fullscreen_type === "editor") {
                    scope.hideFullScreenPanel();
                }
                var tinyInstance = tinymce.get('fullscreen').getContent();
                scope.$emit('estimateFullScreenView', tinyInstance);

                // only set the timeout if the side panel is open
                $timeout(function() {
                    scope.closingFullScreenSequence();
                });
            }else {
                scope.closingFullScreenSequence();
            }
        }

        /* This function for full screen button click to save for estimate introduction/body */
        scope.saveFullScreen = function saveFullScreen(estimateFullScreenValue) {
            scope.saving = true;
            if (scope.full_screen_panel_open === true) {
                var postUrl = $state.current.url.replace('^', '');
                var postUrl = postUrl.replace('{type}',$state.params.type);
                var postUrl = postUrl.replace('{id}',$state.params.id);
                var matches = $state.current['name'].match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);

                if (matches[5] == 'introduction') {
                    var parameterName = "estimates[estimateIntroduction]";
                    var flashMessage = 'Estimate.Introduction.Saved';
                } else if (matches[5] == 'body' || matches[5] == 'edit_option.body') {
                    var parameterName = "estimates[estimateBody]";
                    var flashMessage = 'Estimate.Body.Saved';
                } else if (matches[5] == "additional_works.view.details") {
                    var estimateDescription = "estimateDescription";
                    var estimateBody = "estimateBody";
                    var activeOption = "activeOption";
                    var flashMessage = 'Additional.work.details.saved';
                }

                if(matches[5] == 'introduction' || matches[5] == 'body'  || matches[5] == 'edit_option.body') {
                    var postUrl = postUrl.replace('{estimateId}',$state.params.estimateId);
                    var postUrl = postUrl.replace('{optionId}',$state.params.optionId);
                    var autoSaveValues = "type" + "=" + $state.params.type + "&" + "id" + "=" + $state.params.id + "&" + "estimateId" + "=" + $state.params.estimateId + "&" + parameterName + "=" + encodeURIComponent(estimateFullScreenValue);
                } else if(matches[5] == 'additional_works.view.details') {
                    var postUrl = postUrl.replace('{jobId}',$state.params.jobId);
                    var postUrl = postUrl.replace('{additionalWorkId}',$state.params.additionalWorkId);
                    var autoSaveValues = "type" + "=" + $state.params.type + "&" + "id" + "=" + $state.params.id + "&" + "jobId" + "=" + $state.params.jobId + "&" + "additionalWorkId" + "=" + $state.params.additionalWorkId + "&" + estimateBody + "=" + encodeURIComponent(estimateFullScreenValue) + "&" + estimateDescription + "=" + encodeURIComponent(this.$parent.estimateDescription) + "&" + activeOption + "=" + encodeURIComponent(this.$parent.active_option);
                }

                if($state.params.optionId){
                    var autoSaveValues =  autoSaveValues + "&" + "optionId" + "=" + $state.params.optionId;
                }
                $http.post(prefix + postUrl, autoSaveValues +"&"+ "autoSave" + "=" + 'autosave').success(function(data, status) {
                    if (status == 200) {
                        scope.saving = false;
                        // only set the timeout if the side panel is open
                        $timeout(function() {
                            scope.closingFullScreenSequence();
                        }, 600);

                        if (scope.fullscreen_type === "editor") {
                            scope.hideFullScreenPanel();
                        }
                        scope.$emit('tabCustomer:successMessage', flashMessage);
                    }
                });
                var estimateFullScreenValue = estimateFullScreenValue;
                scope.$emit('estimateFullScreenView', estimateFullScreenValue);
            }else {
                scope.closingFullScreenSequence();
            }
        }

        scope.closingFullScreenSequence = function closingFullScreenSequence() {
            // reset scope variables
            scope.saving = false;
            scope.full_screen = false;
            scope.full_screen_panel_open = true;
            scope.editor_full_width = false;

            scope.full_screen_elm.classList.remove('in-view');
        }

        /*====================================================
         Side Panel
         ====================================================*/
        scope.loadPanelData = function loadPanelData() {
            if (scope.fullscreen_type === "editor") {
                scope.panel_data = fullScreenEditorCollection.getPanelData(scope.category);
                var diaryEvents = _.findWhere(scope.panel_data, {
                    name: $translate('Diary.Events')
                });
                if (diaryEvents) {
                    diaryEvents = diaryEvents.diaryFeedBack;
                } else {
                    diaryEvents = [];
                }

                scope.estimateFeedbackPreviewProps.title = _.findWhere(scope.panel_data, {
                    name: "title"
                });

                if(scope.estimateFeedbackPreviewProps.title) {
                    scope.estimateFeedbackPreviewProps.title = scope.estimateFeedbackPreviewProps.title.title;
                }

                scope.estimateFeedbackPreviewProps.notes = _.findWhere(scope.panel_data, {
                    name: "notes"
                });

                if (scope.estimateFeedbackPreviewProps.notes) {
                    scope.estimateFeedbackPreviewProps.notes = scope.estimateFeedbackPreviewProps.notes.notes;
                }

                scope.estimateFeedbackPreviewProps.diaryEvents = _.map(diaryEvents, function(diaryEvent) {
                    var newDiaryEvent = diaryEvent;
                    newDiaryEvent.questions = _.flatten(Object.keys(newDiaryEvent.questions).map(function(questionName) {
                        return diaryEvent.questions[questionName];
                    }));
                    return newDiaryEvent;
                });
            }
            scope.showFullScreenPanel();
        }

        /*====================================================
         Show side panel
         ====================================================*/
        scope.showFullScreenPanel = function showFullScreenPanel() {
            $timeout(function() {
                scope.showRightBar = true;
                if(scope.full_screen_panel_elm){
                    scope.full_screen_panel_elm.classList.add('in-view');
                }
            }, 10);
        }

        /*====================================================
         Hide side panel
         ====================================================*/
        scope.hideFullScreenPanel = function hideFullScreenPanel() {
            scope.showRightBar = false;
        }

        /*====================================================
         Close side panel
         ====================================================*/
        scope.closeFullScreenPanel = function closeFullScreenPanel(e) {
            if (scope.fullscreen_type === "editor") {
                scope.hideFullScreenPanel();
            }

            scope.full_screen_panel_open = true;
            scope.editor_full_width = true;
        }

        /*====================================================
         Open side panel
         ====================================================*/
        scope.openFullScreenPanel = function openFullScreenPanel(e) {
            if(scope.full_screen_panel_elm) {
                scope.full_screen_panel_elm.classList.add('in-view');
            }
            scope.full_screen_panel_open = true;
            scope.editor_full_width = false;
        }

        /*====================================================
         Keyboard events
         - esc to exit out of fullscreen mode
         ====================================================*/
        scope.registerKeyboardListeners = function registerKeyboardListeners() {
            $document.bind('keydown', function(evt) {
                var evt = evt || window.event;

                if (scope.full_screen === true) {
                    // esc keydown
                    if (evt.keyCode == 27) {
                        scope.hideFullScreen();
                    }
                }
            });
        }

        /*====================================================
         Only when a link is clicked invoke the directive
         ====================================================*/
        var registerEventListeners = (function() {
            element[0].addEventListener('click', scope.initialise);
        })();
    }

    return {
        restrict: 'A',
        scope: {},
        link: handleFullScreen
    }
}]);
