commusoftCommon.service('confirmationBoxHelper', function($compile, $q) {

    this.getConfirmation = function getConfirmation(message, origin_scope) {
        var deferred = $q.defer(),
            confirmation_box_tpl = this.getConfirmationBoxDirective(message),
            confirmation_box_directive = $compile(confirmation_box_tpl)(origin_scope);

        confirmation_box_directive[0].addEventListener('confirmation_box_resolved', function(e) {
            var confirmed = e.detail;
            confirmed ? deferred.resolve() : deferred.reject();
        }, false);

        return deferred.promise;
    }

    this.getConfirmationBoxDirective = function getConfirmationBoxDirective(message) {
        return '<div confirmationbox message=' + "'"+  message + "'"+  ' id="confirmation-box"></div>';
    }

    // called if the cancel link in panel with form is clicked whilst the box is still showing
    this.hideConfirmationBox = function hideConfirmationBox() {
        var elm = document.querySelector('#confirmation-box'),
            box_showing = elm !== undefined;

        if(box_showing) {
            angular.element(elm).remove();
        }
    }
});