/**
 * Created by sivaraman on 07/09/2018.
 */



function shiftSettingCtrl($scope, prefix, $http, shiftSettingsObj, canLoad, $q, $state, $timeout, $rootScope, $compile, uiCalendarConfig, csPopUpPosition, warningModal, toastBox) {
    var today = new Date();
    $scope.selectable = shiftSettingsObj.selectable;
    $scope.editable = shiftSettingsObj.editable;
    $scope.shiftSettingsObj = shiftSettingsObj.shift_schedules;
    $scope.shiftId = shiftSettingsObj.shift_id;
    $scope.shiftName = shiftSettingsObj.shift_name;
    $rootScope.active_shift_breaks = shiftSettingsObj.shift_breaks;
    $scope.overlapping = false;
    $scope.defaultDate = moment(today);
    $scope.currentView = 'agendaResource';
    $scope.resourceId = '';
    $scope.allEvents = [];
    $scope.currentEvents = [];
    $scope.eventToUndo = {};
    $scope.viewStart = moment(today);
    $scope.viewEnd = moment(today);
    $scope.showSticky = false;
    $scope.showRevert = false;
    $scope.stickyMessage = '';
    $scope.events = [];
    $scope.eventSources = [$scope.events];
    $scope.timeDiffArray = [];
    $scope.selectedUserGroups = [];
    $scope.isFromFilter = false;
    //This is to handle the event loading
    $scope.prevNextClicked = false;
    $scope.nextEvents = [];
    $scope.prevEvents = [];
    $scope.show_event_pop_up = false;
    $scope.eventDragStarted = false;
    $scope.selectedUserIdAsArray = [];

    $scope.outstandingJobDropped = false;
    $scope.milestones = [];
    $scope.doubleClicked = false;
    $scope.selectedDiaryEventId = false;

    if($state.params.diaryId > 0) {
        $scope.selectedDiaryEventId = $state.params.diaryId;
    }
    // handle the height of the calendar
    function handleFullCalendarHeight() {
        return (window.innerHeight - 210);
    }

    function resizeCalendar() {
        $('#shift-cs-calendar').fullCalendar('option', 'contentHeight', handleFullCalendarHeight());
    }
    $(window).on('resize', resizeCalendar);
    $scope.shiftUIConfig = {
        calendar: {
            schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
            header: false,
            buttonText: {
                agendaDay: "Service window"
            },
            allDaySlot: false,
            contentHeight: handleFullCalendarHeight(),
            scrollTime: '08:00:00',
            slotDuration: "00:30:00",
            displayEventEnd: true,
            forceEventDuration: true,
            defaultTimedEventDuration: "01:00:00",
            eventLimit:true,
            defaultDate: moment(),
            displayEventTime:true,
            handleWindowResize: true,
            selectable: true,
            selectHelper: true,
            editable: true,
            defaultView: 'agendaDay',
            resources: [
                {'name':'Sunday','title':'Sunday','id':0,'backgroundColor':"cs-event-colour-14",'borderColor':false,'checked':true,'shown_on_diary':true},
                {'name':'Monday','title':'Monday','id':1,'backgroundColor':"cs-event-colour-14",'borderColor':false,'checked':true,'shown_on_diary':true},
                {'name':'Tuesday','title':'Tuesday','id':2,'backgroundColor':"cs-event-colour-14",'borderColor':false,'checked':true,'shown_on_diary':true},
                {'name':'Wednesday','title':'Wednesday','id':3,'backgroundColor':"cs-event-colour-14",'borderColor':false,'checked':true,'shown_on_diary':true},
                {'name':'Thursday','title':'Thursday','id':4,'backgroundColor':"cs-event-colour-14",'borderColor':false,'checked':true,'shown_on_diary':true},
                {'name':'Friday','title':'Friday','id':5,'backgroundColor':"cs-event-colour-14",'borderColor':false,'checked':true,'shown_on_diary':true},
                {'name':'Saturday','title':'Saturday','id':6,'backgroundColor':"cs-event-colour-14",'borderColor':false,'checked':true,'shown_on_diary':true}],

            fixedWeekCount: false,
            unselectAuto: false,
            select: function(start, end, event, view, resource, allDay) {
                $scope.onSelect(start, end, allDay, resource.id);
            },
            viewRender: function(view, element) {
                $scope.currentView = view.name;
                $scope.viewStart = moment(view.start.format('YYYY-MM-DD HH:mm:ss'));
                $scope.viewEnd = moment(view.end.format('YYYY-MM-DD HH:mm:ss'));
                $scope.startDate = view.intervalStart;
                $scope.updateEvents();
            },
            eventClick: function(calEvent, jsEvent, view) {
                if($scope.editable) {
                    $scope.current_event = calEvent
                    var start_Time = calEvent.start.format("HH:mm")+":00";
                    var end_date = calEvent.end
                    if(end_date.format('mm')== '00' || end_date.format('mm')== '30'){
                        end_date.subtract({ minutes: 1});
                    }
                    var end_Time = end_date.format("HH:mm")+":00";

                    var side_panel_tpl = $compile('<span id="edit_shift_window" ' +
                        'sidepanel template="edit_shift_time" ' +
                        'title="view" ' +
                        'remove_on_close="true" ' +
                        'index="' + this.$index + '"' +
                        'collection=""></span>')($scope);

                    angular.element('body').append(side_panel_tpl);


                }
            },
            droppable: false,
            drop: function(date, jsEvent, ui, resourceId, allDay) {
                if($scope.currentView == 'twoWeek' || $scope.currentView == 'month') {
                    allDay = 'fullday';
                }
                //$scope.handleEventDrop(date, jsEvent, resourceId, allDay);
            },
            eventDrop: function(event) {
                $scope.eventDragStarted = false;
                $scope.handleMultipleDaySelection(event);
            },
            eventResizeStart: function(event) {
                $scope.eventDragStarted = true;
            },
            eventResizeStop: function(event) {
                $scope.eventDragStarted = false;
                $scope.show_event_pop_up = false;
            },
            eventDragStart: function(event) {
                $scope.eventDragStarted = true;
            },
            eventDragStop: function(event) {
                $scope.eventDragStarted = false;
                $scope.show_event_pop_up = false;
            },
            eventRender: function (event, element, view) {
                var contentObj = csDiaryHelpers.getEventHtml(view, event);
                var content = contentObj.html;

                if (view.name == 'timelineDay' || view.name == 'timelineWeek') {
                    // Add the popup attributes
                    angular.forEach(contentObj.popup, function(val, key) {
                        element.attr(key, val);
                    });
                }

                var classNames = $(content).attr('class');
                if (view.name == 'twoWeek' || view.name == 'month') {

                } else {
                    element.addClass(' ' + classNames);
                }
                content = $(content).removeClass(classNames);
                element.html(content);
                $compile(element)($scope);
            }
        }
    }


    $scope.getPopUpData = function getPopUpData($event, eventId) {
        /*$scope.pop_up_data =  _.where($scope.events, { id: eventId })[0];
        csPopUpPosition.init($event);*/
    }

    $scope.eventDoubleClick = function eventDoubleClick(eventId) {
        //console.log("event Double click"+eventId)
    }
    $scope.eventSingleClick = function eventSingleClick(eventId) {
        //console.log("event single click"+eventId)
    }
    $scope.setTimeDifferenceArray = function (start, end, allDay) {
        $scope.timeDiffArray = [];
        if(!allDay) {
            var timeDiff = moment(end).diff(moment(start))/1000/60;
            var slotMinutes = moment.duration($scope.shiftUIConfig.calendar.slotDuration)._data.minutes;
            if(timeDiff > slotMinutes) {
                for(var count = 1; count < timeDiff/slotMinutes; count++) {
                    var duration = count*slotMinutes;
                    var durationStr = '';
                    var hours = Math.floor(duration / 60);
                    var minutes = duration % 60;
                    if(hours > 0) {
                        durationStr += hours + ' hour';
                    }
                    if(minutes > 0) {
                        durationStr += (durationStr != '') ? ' ' : '';
                        durationStr += minutes + ' mins';
                    }
                    $scope.timeDiffArray.push({'value' : duration, 'description':durationStr});
                }
            }
        }
    }
    $scope.onSelect = function(start, end, allDay, resourceId) {
        $scope.start = start;
        $scope.end = end;
        $scope.allDay = allDay;
        $scope.resourceId = resourceId;
        $scope.milestones = [];
        $scope.setTimeDifferenceArray(start, end, allDay);
        $scope.handleViewDiaryEvent()

    }

    $scope.updateEvents = function(is_update_event) {
        var events = []
        if($scope.shiftSettingsObj.length){
            angular.forEach($scope.shiftSettingsObj, function (value, key) {
                var default_date = $scope.defaultDate.format('YYYY-MM-DD');
                var event_start_Time = default_date+" "+moment(value['shiftStartTime'].date).format("HH:mm")+":00"
                var event_start_Time_formatted = moment(value['shiftStartTime'].date).format("hh:mm A");
                var event_end =moment(value['shiftEndTime'].date);
                var temp_event_end = moment(value['shiftEndTime'].date);
                if(temp_event_end.format("HH:mm") != "23:59"){
                    // event_end.add({ minutes: 1});
                    var event_end_Time_formatted = (event_end).format("hh:mm A");

                }
                else {
                    var temp_end_time = temp_event_end;
                    temp_end_time.add({ minutes: 1});
                    var event_end_Time_formatted = (temp_end_time).format("hh:mm A");

                }
                var event_end_Time = default_date+" "+(event_end).format("HH:mm")+":00"
                var event_end =moment(value['shiftEndTime'].date)
                var event_colour = 'cs-event-colour-14';
                if(value['shiftTimeType'] == 'overtime'){
                    event_colour = 'cs-event-colour-13'
                }
                if(!$scope.editable) {
                    value.editable = false;
                    value.durationEditable = false;
                }

                events.push({
                    actualTime:0,
                    allDay:false,
                    status:{class:''},
                    end_time:event_end_Time_formatted,
                    start_time:event_start_Time_formatted,
                    duration:"",
                    durationEditable:true,
                    editable:false,
                    end:event_end_Time,
                    eventColor:event_colour,
                    shiftDay:value['shiftDay'],
                    shiftTimeCost:value['shiftTimeCost'],
                    shiftId:value['shiftId'],
                    shiftTimeType:value['shiftTimeType'],
                    grayedOut:false,id:value['id'],
                    isSpecialEvent:false,
                    resourceId:value['shiftDay'],
                    start:event_start_Time,
                    type:"shift_type",
                    shift_type:value['shiftTimeType']
                })
            });
            $scope.events = events;
        }
        angular.element('#shift-cs-calendar').fullCalendar('unselect');
        angular.element('#shift-cs-calendar').fullCalendar('removeEvents');
        $scope.currentEvents = events;
        $scope.events = events;
        if(events.length) {
            angular.element('#shift-cs-calendar').fullCalendar('addEventSource', events);
        }
    }

    $scope.handleViewDiaryEvent = function handleViewDiaryEvent() {
        var start_Time = $scope.start.format("HH:mm")+":00";
        var end_date = $scope.end
        end_date.subtract({ minutes: 1});
        var end_Time = end_date.format("HH:mm")+":00";
        var side_panel_tpl = $compile('<span id="add_shift_window" ' +
            'sidepanel template="add_shift_time" ' +
            'title="view" ' +
            'remove_on_close="true" ' +
            'index="' + this.$index + '"' +
            'collection=""></span>')($scope);

        angular.element('body').append(side_panel_tpl)

    }

    $scope.getStartEndDate = function(viewName, dateObj, option) {
        var stringToAdd = '';
        var numberToAdd = 1;
        var valueToMultiple = (option == 'next') ? 1 : -1;
        var newEndDate = false;
        var newStartDate = false;
        if(viewName == 'agendaResource') {
            stringToAdd = 'days';
            numberToAdd = 1 * valueToMultiple;
        } else if(viewName == 'agendaWeek') {
            stringToAdd = 'weeks';
            numberToAdd = 1 * valueToMultiple;
        } else if(viewName == 'twoWeek') {
            stringToAdd = 'weeks';
            numberToAdd = 2 * valueToMultiple;
        } else if(viewName == 'month') {
            stringToAdd = 'months';
            numberToAdd = 1 * valueToMultiple;
            var addMonth = moment($scope.startDate).add(numberToAdd, stringToAdd);
            var startOfMonth = addMonth.clone().startOf('month');
            newStartDate = startOfMonth.clone().startOf('week').format('YYYY-MM-DD');
            var endOfMonth = addMonth.clone().endOf('month');
            newEndDate = endOfMonth.clone().endOf('week').add(1, 'day').format('YYYY-MM-DD');
        } else {
            return false;
        }
        var newStartDate = (!newStartDate) ? moment(dateObj).add(numberToAdd, stringToAdd).format('YYYY-MM-DD') : newStartDate;
        var newEndDate = (!newEndDate) ? moment(newStartDate).add(Math.abs(numberToAdd), stringToAdd).format('YYYY-MM-DD') : newEndDate;
        return {startDate: newStartDate, endDate: newEndDate};
    }
    $scope.revertUpdatedEvent = function() {
        $scope.closeSticky();
        var event = $scope.eventToUndo;
        //newEvent.revertedEvent = true;
        $scope.eventToUndo = {}
    }

    $scope.handleSticky = function(time) {
        $scope.showSticky = true;
        $timeout(function () {
            $scope.closeSticky();
        }, time);
    }

    $scope.closeSticky = function() {
        $scope.showRevert = false;
        $scope.showSticky = false;
    }

    $scope.setUndoEvent = function(diaryId) {
        for(var index = 0; index < $scope.events.length; index++) {
            if($scope.events[index].id == diaryId) {
                $scope.eventToUndo = $scope.events[index];
                break;
            }
        }
    }
    $rootScope.$on('shift_window:updated', function(e, event_details) {
        //$scope.events.push(event);
        $scope.service_window_status = event_details['service_window_status']
        $scope.shiftSettingsObj.push(event_details);
        $scope.updateEvents(true);
    });

    $rootScope.$on('engineer_shift:event_deleted', function(e, value) {
        var event_id = value.id;
        const index = _.findIndex($scope.shiftSettingsObj, {id: event_id});
        $scope.shiftSettingsObj.splice(index, 1)
        $scope.service_window_status = value['service_window_status']
        $scope.updateEvents();

    });
    $rootScope.$on('parent:updated',function(){
        angular.element('#shift-cs-calendar').fullCalendar('unselect');

    });
    $rootScope.$on("shift_window:sidepanel:closed",function (e, value) {
        angular.element('#shift-cs-calendar').fullCalendar('unselect');
    })

    $scope.getEventStartEndValues = function(start, allDay) {
        var startOfDay = start.clone().startOf('day');
        var startDateTime = (allDay == 'pm') ? startOfDay.clone().add(12, 'h') : startOfDay;
        var timeToAdd = (allDay == 'fullday') ? 24 : 12;
        var endDateTime = startDateTime.clone().add(timeToAdd, 'h');
        return {start: startDateTime, end: endDateTime};
    }
    $scope.convertEvent = function(event) {
        var end = event.end;
        var editable = true;
        if(!$scope.editable) {
            editable = false;
        }
        if(event.allDay == 'fullday' && end == null) {
            end = moment(event.start).clone().add(1, 'day').startOf('day');
        }
        var newEvent = {
            id: event.id,
            title: event.title,
            start: event.start,
            event_start: event.start,
            end: end,
            event_end: end,
            allDay: event.allDay,
            resourceId: event.resourceId,
            resourceName: event.resourceName,
            isSpecialEvent: event.isSpecialEvent,
            actualTime: event.actualTime,
            duration: event.duration,
            status: event.status,
            type: event.type,
            editable: false,
            durationEditable: event.durationEditable
        };
        return newEvent;
    }

    /**
     * Open manage break side panel
     */
    $scope.openBreakSidePanel = function () {
        $rootScope.active_shiftId = $scope.shiftId;
        var side_panel_tpl = $compile('<span id="manage_breaks" ' +
            'sidepanel template="manage_breaks" ' +
            'title="view" ' +
            'remove_on_close="true" ' +
            'index="' + this.$index + '"' +
            'collection=""></span>')($scope);
        angular.element('body').append(side_panel_tpl);
    }

}

var shiftSettingsResolver = {
    shiftSettingsObj: function ($q, $http, $stateParams, $state, PubNub, $rootScope, cacheBust,prefix, adminPrefix) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var deferred = $q.defer();
        $http.get(this.templateUrl($stateParams)).success(function (data, status, headersObj) {
            var headers = headersObj();
            SetRootScopeValues($rootScope, headers, PubNub, cacheBust, $http, prefix, adminPrefix,$state);
            if (data.location && data.location == 'logout') {
                $rootScope.$broadcast('chat:unregister');
                $state.transitionTo('home');
            } else if (data.location && data.location.endsWith("/")) {
                //$state.transitionTo('home');
            } else {
                deferred.resolve(data);
            }
        });
        return deferred.promise;
    }
}


csmodule.controller('sidePanel_shift_time', ['$scope', '$rootScope', '$q', '$filter', '$timeout',  'diaryRightBarHelper', 'prefix', '$window', '$state','$http','formService', 'toastBox', 'warningModal', function($scope, $rootScope, $q, $filter, $timeout,  diaryRightBarHelper, prefix, $window, $state, $http, formService, toastBox, warningModal) {
    $scope.engineer_shift_ctrl_scope = $scope.$parent.$parent;
    $scope.slot_exist = $scope.engineer_shift_ctrl_scope.slot_exist
    $scope.start_date = $scope.engineer_shift_ctrl_scope.start.format("hh:mm A");
    $scope.temp_date = $scope.engineer_shift_ctrl_scope.end;
    $scope.shift_day = $scope.engineer_shift_ctrl_scope.resourceId
    if($scope.temp_date.format('mm') != '00' || $scope.temp_date.format('mm') != '30'){
        $scope.temp_date.add({minutes:1})
        $scope.end_date = $scope.temp_date.format("hh:mm A");
    }
    $scope.engineer_shift = {
        startTime:$scope.engineer_shift_ctrl_scope.start,
        endTime:$scope.engineer_shift_ctrl_scope.end.add({seconds:1}),
        shiftType:"Normal",
        overtimeCost:0,
        shiftId:$scope.engineer_shift_ctrl_scope.shiftId,
        shiftDay:$scope.engineer_shift_ctrl_scope.resourceId
    }
    $scope.openPanel = function openPanel() {
        $timeout(function() {
            $scope.initialise();
        }, 0);
    }

    $scope.resetTime = function resetTime(el) {
        $scope.shiftType = el.engineer_shift.shiftType;
        if($scope.shiftType == "Normal"){
            el.engineer_shift.overtimeCost = 0;
        }
    }

    $scope.saveShiftSchedule = function() {
        $scope.data_loaded = true;
        $http.post(Routing.generate("addShiftTime"),JSON.stringify($scope.engineer_shift))
            .success(function (data) {
                var response = data[0]
                $scope.data_loaded = false;
                if(data.error){
                    warningModal.show(data.message, 'Engineer shift', 'Shift_scheduling_error');
                    $rootScope.$broadcast('sidepanel:close');
                }
                else if(response['id']){
                    toastBox.show("Shift pattern created", 4000);
                    $rootScope.$emit('shift_window:updated', response);
                    $scope.handleSticky(10000);
                    $rootScope.$broadcast('sidepanel:close');
                }
                else {
                    $scope.data_loaded = false;
                    console.log(data)
                }
            })
            .catch(function (e) {
                console.log(e)
            })
    }
    $scope.handleSticky = function(time) {
        $scope.showSticky = true;
        $timeout(function () {
            $scope.closeSticky();
        }, time);
    }
    $scope.closeSidePanel = function () {
        $rootScope.$broadcast('sidepanel:close');
        $rootScope.$emit('shift_window:sidepanel:closed', 'closed');
    }

    $scope.closeSticky = function() {
        $scope.showRevert = false;
        $scope.showSticky = false;
    }

}]);
csmodule.controller('sidePanel_edit_shift_time', ['$scope', '$rootScope', '$q', '$filter', '$timeout',  'diaryRightBarHelper', 'prefix', '$window', '$state','$http','formService', 'toastBox', function($scope, $rootScope, $q, $filter, $timeout,  diaryRightBarHelper, prefix, $window, $state, $http, formService, toastBox) {
    $scope.shift_window_ctrl_scope = $scope.$parent.$parent;
    $scope.slot_exist = $scope.shift_window_ctrl_scope.slot_exist
    $scope.start_date = $scope.shift_window_ctrl_scope.current_event.start.format("hh:mm A");
    $scope.temp_date = $scope.shift_window_ctrl_scope.current_event.end
    $scope.delete_id = $scope.shift_window_ctrl_scope.current_event.id

    if($scope.temp_date.format('mm') != '00' || $scope.temp_date.format('mm') != '30'){
        $scope.temp_date.add({minutes:1})
        $scope.end_date = $scope.temp_date.format("hh:mm A");
    }
    $scope.shift = {
        startTime:$scope.shift_window_ctrl_scope.current_event.start,
        endTime:$scope.shift_window_ctrl_scope.current_event.end.subtract({ minutes: 1}),
        shiftType:$scope.shift_window_ctrl_scope.current_event.shiftTimeType,
        overtimeCost:$scope.shift_window_ctrl_scope.current_event.shiftTimeCost,
        shiftId:$scope.shift_window_ctrl_scope.current_event.shiftId,
        shiftDay:$scope.shift_window_ctrl_scope.current_event.shiftDay
    }

    $scope.openPanel = function openPanel() {
        $timeout(function() {
            $scope.initialise();
        }, 0);
    }
    $scope.closeSidePanel = function () {
        $rootScope.$broadcast('sidepanel:close');
        $rootScope.$emit('shift_window:sidepanel:closed', 'closed');
    }

    $scope.deleteRecord = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.delete_data_loaded = true;
        $http.delete(Routing.generate("deleteShiftTime", { id: $scope.delete_id }))
            .success(function (data, status) {
                var shiftId = data.shiftId
                $scope.delete_data_loaded = false;
                if(shiftId && status == 200){
                    toastBox.show("Shift pattern has been deleted", 4000);
                    $state.transitionTo('loggedin.configure_shift', {'id':shiftId},{reload: true})
                    $rootScope.$broadcast('sidepanel:close');
                }
                else {
                    $scope.delete_data_loaded = false;
                }
            })
            .catch(function (e) {
                console.log(e)
            })
    }

    $scope.handleSticky = function(time) {
        $scope.showSticky = true;
        $timeout(function () {
            $scope.closeSticky();
        }, time);
    }

    $scope.closeSticky = function() {
        $scope.showRevert = false;
        $scope.showSticky = false;
    }

}]);

