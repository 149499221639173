commusoftCommon.directive('checkExists', ['prefix', 'canLoad', '$q', '$http', function(prefix, canLoad, $q, $http) {

    return {
        require: '?ngModel',
        restrict: 'A',
        link: function(scope, element, attrs, ctrl) {
            var canceler = null;
            scope.$watch(attrs.checkExists, function(val) {
                if (typeof val != 'undefined' && val  != '') {
                    if (attrs.maskpassword && attrs.maskpassword == "true"){
                        var url = prefix + '/validUserPassword', params='' ;
                        if (typeof attrs.useSelectedId != 'undefined' && attrs.useSelectedId == 'true') {
                            params = 'password=' + encodeURIComponent(val) +'&selectedId=' + scope.selectedId;
                        }
                        if (typeof attrs.additionalParams != 'undefined' && attrs.additionalParams != '') {
                            params = params + '&' + attrs.additionalParams;
                        }
                        $http.post(url, params).success(function(data, status) {
                            if (status == 200) {
                                if (data == "true") {
                                    ctrl.$setValidity("checkExists", false);
                                } else {
                                    ctrl.$setValidity("checkExists", true);
                                }
                            }
                        })
                    } else{
                        var url = prefix + attrs.checkExistsUrl + encodeURIComponent(val);
                        if (typeof attrs.useSelectedId != 'undefined' && attrs.useSelectedId == 'true') {
                            url = url + '&selectedId=' + scope.selectedId;
                        }
                        if (typeof attrs.additionalParams != 'undefined' && attrs.additionalParams != '') {
                            url = url + '&' + attrs.additionalParams;
                        }
                        canLoad.setLoadValue(false);  // Turn off AJAX
                        if (canceler) {
                            canceler.resolve();
                        }
                        canceler = $q.defer();
                        $http.get(url, {timeout: canceler.promise}).
                        success(function(data, status) {
                            if (status == 200) {
                                if (data == "true") {
                                    ctrl.$setValidity("checkExists", false);
                                } else {
                                    ctrl.$setValidity("checkExists", true);
                                }
                            }
                        })
                    }
                }
            })
        }
    }
}]).directive('checkArchiveExists', ['prefix', 'canLoad', '$q', '$http', function(prefix, canLoad, $q, $http) {

    return {
        require: '?ngModel',
        restrict: 'A',
        link: function(scope, element, attrs, ctrl) {

            var canceler = null;
            scope.$watch(attrs.checkArchiveExists, function(val) {
                scope.SkillForm.$invalid =true
                ctrl.$error.checkArchiveExists = false;
                if (typeof val != 'undefined' && val  != '') {
                    var url = prefix + attrs.checkArchiveExistsUrl + encodeURIComponent(val);
                    if (typeof attrs.useSelectedId != 'undefined' && attrs.useSelectedId == 'true') {
                        url = url + '&selectedId=' + scope.selectedId;
                    }
                    canLoad.setLoadValue(false);  // Turn off AJAX
                    if (canceler) {
                        canceler.resolve();
                    }
                    canceler = $q.defer();
                    $http.get(url, {timeout: canceler.promise}).
                    success(function(data, status) {
                        if (status == 200) {
                            scope.statusCode = data.statusCode
                            if(data.statusCode == '0'){
                                ctrl.$setValidity("checkArchiveExists", false);
                                ctrl.$error.checkArchiveExists = false;
                                scope.SkillForm.$invalid =false;
                            }else{
                                scope.selectedId = data.skillData.id
                                scope.description = data.skillData.description
                                ctrl.$setValidity("checkArchiveExists", true);
                                ctrl.$error.checkArchiveExists = true;
                                scope.SkillForm.$invalid =true;
                            }
                        }
                    })
                }
            })
        }
    }
}]).directive('checkDataExists', ['prefix', 'canLoad', '$q', '$http', function(prefix, canLoad, $q, $http) {

    /** this directive is same as checkExists directive with also take dynamic additional param for validation */
    return {
        require: '?ngModel',
        restrict: 'A',
        scope:{
            additionalParams : '@',
            currentData : '@'
        },
        link:function (scope, element, attrs, ctrl) {
            var canceler = null;
            scope.$watchCollection('[additionalParams,currentData]',function (newVal, oldVal) {
                if (typeof newVal[0] != 'undefined' && newVal[0]  != '' && typeof newVal[1] != 'undefined' && newVal[1]  != '') {
                    var url = prefix + attrs.checkExistsUrl + encodeURIComponent(newVal[1]);
                    if (typeof attrs.useSelectedId != 'undefined' && attrs.useSelectedId == 'true') {
                        url = url + '&selectedId=' + scope.selectedId;
                    }
                    if (typeof attrs.additionalParams != 'undefined' && attrs.additionalParams != '') {
                        url = url + '&' + attrs.additionalParams;
                    }
                    canLoad.setLoadValue(false);  // Turn off AJAX
                    if (canceler) {
                        canceler.resolve();
                    }
                    canceler = $q.defer();
                    $http.get(url, {timeout: canceler.promise}).
                    success(function(data, status) {
                        if (status == 200) {
                            if (data == "true") {
                                ctrl.$setValidity("checkExists", false);
                            } else {
                                ctrl.$setValidity("checkExists", true);
                            }
                        }
                    })
                }
            });
        }
    }
}]).directive('checkDateExists', ['prefix', 'canLoad', '$q', '$http', function(prefix, canLoad, $q, $http) {
    return {
        require: '?ngModel',
        restrict: 'A',
        link: function(scope, element, attrs, ctrl) {
            var canceler = null;
            scope.$watch(attrs.checkDateExists, function(val) {
                if (typeof val != 'undefined' && val  != '') {
                    var url = prefix + attrs.checkExistsUrl + moment(val, 'L').format('YYYY-MM-DD');
                    canLoad.setLoadValue(false);  // Turn off AJAX
                    if (canceler) {
                        canceler.resolve();
                    }
                    canceler = $q.defer();
                    $http.get(url, {timeout: canceler.promise}).
                    success(function(data, status) {
                        if (status == 200) {
                            if (data == "true") {
                                ctrl.$setValidity("checkDateExists", false);
                            } else {
                                ctrl.$setValidity("checkDateExists", true);
                            }
                        }
                    })
                }
            })
        }
    }
}]);
