function JobConfirmationsCtrl($scope, $rootScope, $http, $state, prefix, datasets) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
    $scope.showAdvanced = true;
    $scope.selectedId = 0;

    $scope.letterPreview = function (Letter) {
        $scope.previewData = Letter;
        $scope.shouldBeOpenLetterPreview = true;
    };

    if($state.params && $state.params.id) {
        $scope.selectedId = $state.params.id;
    }

    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_customers',
            data: function (term, page) {
                return {'q': term, 'mode': 'diary'};
            },
            results: function (data, page) {
                return { results: data };
            }
        }
    }
}

function JobConfirmationAdvancedCtrl(data, $scope, $state, $http, defaultPagingValue, prefix, canLoad, $q) {
    $scope.templates = data.templates;
    $scope.totalTemplates = data.totalTemplates;
    $scope.limit = defaultPagingValue;
    $scope.currentPage = 1;
    $scope.shouldBeOpen = false;
    $scope.selectedId = 0;

    var canceler = null;
    $scope.fetchTemplates = function(pageNum) {
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        $http.get(prefix + '/list_job_confirmation_templates?page=' + pageNum + '&limit=' + $scope.limit, {timeout: canceler.promise}).success(function (data) {
            $scope.templates = data.templates;
            $scope.totalTemplates = data.totalTemplates;
        })
    }

    $scope.deleteTemplate = function(selectedId) {
        $scope.selectedId = selectedId;
        $scope.shouldBeOpen = true;
    }
}