commusoftCommon.service('redirectScreen',function($state,$rootScope,warningModal){
    var state,stateParam;
    this.redirectTo = function (screen, stateParams, warningMessage) {

        if(screen === 'customerDetails') {
            state = "loggedin.customer_list.view.property";
            stateParam = {'id': stateParams.id, 'type': stateParams.type};
        }else if(screen === 'CustomersList'){
            state = "loggedin.customer_list";
            stateParam = {};
        }

        warningModal.show(warningMessage.message, warningMessage.title,"Deleted");
        $state.transitionTo(state,stateParam,{reload: true});
        return false;
    }
});