'use strict';

var assetSettingsModule = angular.module('asset-settings', []);

assetSettingsModule.constant('prefix', window.prefixVal).
constant('defaultPagingValue', defaultPaginationLimit).
provider('canLoad',function () {
    this.$get = function () {
        return {
            canLoadAjax: true,
            setLoadValue: function (canLoad) {
                this.canLoadAjax = canLoad;
            },
            canLoad: function () {
                return this.canLoadAjax;
            }
        }
    }
}).
config(
    ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
        function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {
            $stateProvider.
            state('loggedin.dynamic_form', {
                url: Routing.generateAngularRoute('dynamic_form_view', false),
                templateUrl: function (params) {
                    return Routing.generate('dynamic_form_view')
                },
                views: {
                    "": {
                        controller: 'DynamicFormMainCtrl',
                        templateUrl: 'template/dynamic-form/form_view.html'
                    }

                }
            }).
            state('loggedin.asset_dynamic_list', {
                url: Routing.generateAngularRoute('asset_dynamic_list', false),
                templateUrl: function (params) {
                    return Routing.generate('asset_dynamic_list')
                },
                data: {
                    'assetDynamicUrl': '/listDynamicAsset'
                },
                views: {
                    "": {
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    },
                    "assetDynamicListPane@": {
                        templateUrl: 'template/settings/assets/asset_dynamic_list.html',
                        controller: AssetDynamicFieldsCtrl,

                        resolve: {
                            assetdynamicresolver: function ($http, $q, $state, prefix) {
                                var deferred = $q.defer();
                                $http.get(prefix + this.data.assetDynamicUrl).success(function (data) {
                                    deferred.resolve(data);
                                })
                                return deferred.promise;
                            }
                        }
                    }
                }
            }).
            state('loggedin.merge_dynamic_field_option', {
                url: Routing.generateAngularRoute('merge_dynamic_field_option', true),
                templateUrl: function (params) {
                    return Routing.generate('merge_dynamic_field_option', params)
                },
                data: {
                    'dynamicUrl': '/list_merge_dynamic_field_options',
                    'pageNum': 1,
                    'limit': 30
                },
                views: {
                    "": {
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    },
                    "assetDynamicListPane@": {
                        templateUrl: 'template/settings/assets/merge_dynamic_field_options.html',
                        controller: AsssetDynamicFieldOptionMergeCtrl,

                        resolve: {
                            assetDynamicFieldOptionResolver: function ($http, $q, $state, prefix, $stateParams) {
                                var deferred = $q.defer();
                                $http.get(prefix + this.data.dynamicUrl + '?fieldId='+ $stateParams.id +'&page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                    deferred.resolve(data);
                                })
                                return deferred.promise;
                            }
                        }
                    }
                }
            }).
            state('loggedin.settings_standard_asset_fields', {
                url: Routing.generateAngularRoute('settings_standard_asset_fields', true),
                templateUrl: function (params) {
                    return Routing.generate('settings_standard_asset_fields')
                },
                controller: standardAssetFieldsCtrl,
                resolve: Resolver
            }).
            state('loggedin.asset_groups', {
                url: Routing.generateAngularRoute('asset_groups', false),
                templateUrl: function (params) {
                    return Routing.generate('asset_groups')
                },
                data: {
                    'assetGroupsUrl': '/listAssetGroups',
                    'pageNum': 1,
                    'editedId': -1,
                    'addedId': -1,
                    'limit': 5
                },
                views: {
                    "": {
                        template: globalTemplate,
                        controller: AssetGroupsCtrl,
                        resolve: Resolver
                    },
                    "assetGroupsPane@": {
                        templateUrl: 'template/settings/assetgroup/list_assetgroups.html',
                        controller: AssetGroupsListEditCtrl,

                        resolve: {
                            assetgroupsresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                var deferred = $q.defer();
                                $http.get(prefix + this.data.assetGroupsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                    deferred.resolve(data);
                                })
                                return deferred.promise;
                            }
                        }
                    }
                }
            }).
            state('loggedin.asset_groups.edit', {
                params: ['id', 'description', 'pageNum', 'limit'],
                views: {
                    "assetGroupsPane@": {
                        templateUrl: 'template/settings/assetgroup/edit_assetgroups.html',
                        controller: AssetGroupsEditCtrl
                    }
                }
            }).
            state('loggedin.settings_configure_asset_group', {
                url: Routing.generateAngularRoute('settings_configure_asset_group', true),
                templateUrl: function (params) {
                    return Routing.generate('settings_configure_asset_group', params)
                },
                controller: AssetGroupsConfigureCtrl,
                resolve: Resolver
            }).
            state('loggedin.asset_type', {
                url: Routing.generateAngularRoute('asset_type', false),
                templateUrl: function (params) {
                    return Routing.generate('asset_type')
                },
                data: {
                    'assetTypesUrl': '/listAssetType',
                    'pageNum': 1,
                    'editedId': -1,
                    'addedId': -1,
                    'limit': 5
                },
                views: {
                    "": {
                        template: globalTemplate,
                        controller: AssetTypesCtrl,
                        resolve: Resolver
                    },
                    "assetTypesPane@": {
                        templateUrl: 'template/settings/assettype/list_assetTypes.html',
                        controller: AssetTypesEditListCtrl,

                        resolve: {
                            assettypesresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                var deferred = $q.defer();
                                $http.get(prefix + this.data.assetTypesUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                    deferred.resolve(data);
                                })
                                return deferred.promise;
                            }
                        }
                    }
                }
            }).
            state('loggedin.settings_configure_asset', {
                url: Routing.generateAngularRoute('settings_configure_asset_type', true),
                templateUrl: function (params) {
                    return Routing.generate('settings_configure_asset_type', params)
                },
                controller: AssetTypesConfigureCtrl,
                resolve: Resolver
            }).
            state('loggedin.asset_type.edit', {
                params: ['id', 'description', 'settingsAssetgroupsDescription', 'assetGroupId', 'pageNum', 'limit'],
                data: {
                    'assetTypesUrl': '/listAssetType'
                },
                views: {
                    "assetTypesPane@": {
                        templateUrl: 'template/settings/assettype/edit_assettypes.html',
                        controller: AssetTypesEditCtrl,

                        resolve: {
                            assettypesresolver: function ($http, $q, $state, prefix) {
                                var deferred = $q.defer();
                                $http.get(prefix + this.data.assetTypesUrl).success(function (data) {
                                    deferred.resolve(data);
                                })
                                return deferred.promise;
                            }
                        }
                    }
                }
            });
        }
    ]);