/**
 * Created on 15/11/17.
 */

var csDiaryMapModule = angular.module('csDiaryMap', []);

csDiaryMapModule
    .constant('gpsServerConfig', {
        apiUrl: '',
        socketUrl: '',
        token: null,
        mapTilesServer: null,
        mapStylesUrl: null
    })
    .factory('$diaryMapScope', ['$http', '$rootScope', '$q', function ($http, $rootScope, $q) {
        /** Diary map module storage service */
        var scope = $rootScope.$new();
        scope.mapElementTemplates = {};
        scope.mapElementSpecifications = {
            outstanding_job_marker: {
                widthSmall: 25,
                heightSmall: 25,
                anchorSmall: [12, 22],
                widthMedium: 30,
                heightMedium: 30,
                anchorMedium: [15, 28],
                widthLarge: 35,
                heightLarge: 35,
                anchorLarge: [17, 33],
                widthExtraLarge: 45,
                heightExtraLarge: 45,
                anchorExtraLarge: [22, 42]
            },
            property_address_marker: {
                height: 45,
                anchor: [12, 32]
            }
        };
        scope.users = [];
        scope.usersToShowOnMap = [];
        scope.usersEvents = {};
        scope.mapTimeBounds = {mode: '', duration: {}, date: null, userToShowOnHistoricalView: null};
        scope.processingSidepanelJobSelection = false;

        // Map initialize promise
        scope.diaryMapRenderObservable = null;
        scope.resetMapInitializeWaiter = function () {
            scope.diaryMapRenderObservable = $q.defer();
            scope.diaryMapRenderObservable.stages = [];
            scope.diaryMapRenderObservable.promise.then(function () {
                scope.diaryMapRenderObservable.stages.push('RENDER_COMPLETED');
            }, function (error) {
                console.log('DiaryMap render failed', error);
            }, function (message) {
                if (scope.diaryMapRenderObservable.stages.indexOf(message) < 0) scope.diaryMapRenderObservable.stages.push(message);
            });
        };
        scope.resetMapInitializeWaiter();

        var requiredMapElementTemplates = {
            'job_estimate_marker': 'template/diary/map_elements/job_estimate_marker.html',
            'user_marker': 'template/diary/map_elements/user_marker.html',
            'outstanding_job_marker': 'css/public/stylesheets/images/marker_icons/outstanding-job-marker.svg',
            'property_address_marker': 'css/public/stylesheets/images/marker_icons/property-address-marker.svg',
            'focus_pointer_marker': 'css/public/stylesheets/images/marker_icons/focus-pointer-marker.svg',
            'scheduling_job_estimate_marker': 'template/diary/map_elements/scheduling_job_estimate_marker.html',
        };

        Object.keys(requiredMapElementTemplates).forEach(function (templateName) {
            $http.get(requiredMapElementTemplates[templateName]).then(function (response) {
                scope.mapElementTemplates[templateName] = response.data;
            });
        });

        // Setter for Users
        scope.setUsers = function (users_list) {
            scope.users = angular.copy(users_list);

            scope.$emit("users:updated", scope.users);
        };

        // Setter for Map Users
        scope.setUsersToShowOnMap = function (users_list) {
            var users_to_show_on_diary = _.where(users_list, {shown_on_diary: true});
            if (scope.usersToShowOnMap !== users_to_show_on_diary) scope.usersToShowOnMap = users_to_show_on_diary;

            scope.$emit("usersToShowOnMap:updated", scope.usersToShowOnMap);
        };

        // Setter for mapTimeBounds
        scope.setMapTimeBounds = function (bounds) {
            scope.mapTimeBounds = bounds;
            scope.$emit('mapTimeBounds:updated', scope.mapTimeBounds);
        };

        // Emitting selected outstanding job
        scope.handleOutstandingJobSelection = function (job, source = 'OUTSTANDING') {
            if(source === 'SIDE_PANEL') scope.processingSidepanelJobSelection = true;

            scope.$emit('outstandingJob:selected', {
                source: source,
                jobDetails: job
            });
        };

        scope.onJobDueWindowsRefresh = function (data, preserve) {
            if(preserve) scope.jobDueWindows = data;
            scope.$emit('jobDueWindows:refreshed', angular.copy(data));
        };

        scope.onUsersWithoutEventsUpdate = function (data) {
            scope.$emit('usersWithoutEvents:updated', angular.copy(data));
        };

        scope.handleMapInfoWidgetChange = function (action, type, data) {
            switch (action) {
                case "show": {
                    data = {
                        action: "show",
                        type: type,
                        data: angular.copy(data)
                    };
                    break;
                }
                case "update": {
                    data = {
                        action: "update",
                        type: type,
                        data: angular.copy(data)
                    };
                    break;
                }
                case "hide": {
                    data = {
                        action: "hide"
                    };

                    break;
                }
            }

            scope.$emit('mapInfoWidget:changed', data);
        };


        return scope;

    }]);

// Registering Services
csDiaryMapModule.factory('$csMapper', csMapperFactory);
csDiaryMapModule.service('$gpsTracker', gpsTracker);
csDiaryMapModule.service('$jobEstimateMapMarkerScope', jobEstimateMapMarkerScopeService);
csDiaryMapModule.service('$userMapMarkerScope', userMapMarkerScopeService);

// Registering Controllers
csDiaryMapModule.controller('DiaryMapCtrl', DiaryMapCtrl);
csDiaryMapModule.controller('DiaryMapRightbarOptionsCtrl', DiaryMapRightbarOptionsCtrl);

// Registering Directives
csDiaryMapModule.directive('csDiaryMap', dairyMapDirective);
csDiaryMapModule.directive('csDiaryMapRightbarOptions', diaryMapRightbarOptionsDirective);
csDiaryMapModule.directive('csDrivingProfileTimeline', drivingProfileTimeLineDirective);
csDiaryMapModule.directive('csDiaryMapInfoWidget', diaryMapInfoWidgetDirective);


/** Function to create new custom fullcalendar view for integrating map view in diary */
/*
function GenerateFcMapView($scope, $compile) {
    function createObject(proto) {
        var f = function () {
        };
        f.prototype = proto;
        return new f();
    }

    var FcView = $.fullCalendar.views;
    FcView.diaryMap = DiaryMapView;

    function DiaryMapView(calendar) {
        FcView.blank.call(this, calendar); // calling the super-constructor

        this.parentScope = $scope;
        this.scope = null;
    }


    DiaryMapView.prototype = createObject(FcView.blank.prototype); // defining the super-class
    $.extend(DiaryMapView.prototype, {

        name: 'diaryMap',

        setHeight: function (height, isAuto) {
        },
        render: function (date) {
            this.intervalStart = date.clone().stripTime();
            this.intervalEnd = this.intervalStart.clone().add(1, 'days');

            this.start = this.skipHiddenDays(this.intervalStart);
            this.end = this.skipHiddenDays(this.intervalEnd, -1, true);

            this.title = this.calendar.formatRange(
                this.start,
                this.end.clone().subtract(1), // make inclusive by subtracting 1 ms
                this.opt('titleFormat'),
                ' \u2014 ' // emphasized dash
            );
            // this.title = 'Map';
            FcView.blank.prototype.render.call(this, date); // calling the super-method
        }
    });
}
*/

