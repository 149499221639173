function CalendarIntegrationCtrl($scope, $http, $state, $rootScope, $timeout, datasets, prefix,$location) {
    $rootScope.toState = $rootScope.previousState;
    
    $scope.loading = false;
    if( !$rootScope.previousState || $rootScope.previousState == "loggedin.calendar_integration" ) {
        $rootScope.previousState = "loggedin.customers";
    }
    $scope.data = datasets;
    $scope.saving = false;
	
    $scope.calendarsList = [
        {
            'name': 'Google',
            'imagename': 'google_calendar.png',
        },
        {
            'name': 'Outlook',
            'imagename': 'outlook_calendar.png',
        },
    ];

    
    $scope.chosen_calendar = {
        'name': 'Google',
        'chosen': true,  
    };
    
    /*$scope.connectCalendar = function () {
        alert($scope.chosen_calendar);
        $rootScope.calendarType = $scope.chosen_calendar.name;
        $http({
                method  : 'POST',
                url     : prefix + '/oauthCalendar',
                data    :  $.param({
                    'calendar': $scope.chosen_calendar.name
                }),
                headers : { 'Content-Type': 'application/x-www-form-urlencoded' } 
        }).
        success(function (data, status) {
            var oAuthUrl = data.oAuthUrl;
            alert(oAuthUrl);
            window.open(oAuthUrl, "_self");
        }).
        error(function (data, status) {
            $scope.data = data;
        });
    }*/
    
    $scope.connectCalendar = function (calendarType) {
        //alert(calendarType);
        $rootScope.calendarType = calendarType;
        $http({
                method  : 'POST',
                url     : prefix + '/oauthCalendar',
                data    :  $.param({
                    'calendar': calendarType
                }),
                headers : { 'Content-Type': 'application/x-www-form-urlencoded' } 
        }).
        success(function (data, status) {
            var oAuthUrl = data.oAuthUrl;
            //alert(oAuthUrl);
            window.open(oAuthUrl, "_self");
        }).
        error(function (data, status) {
            $scope.data = data;
        });
    }
    
    $scope.oAuthState = "";
    
    if ( $location.search().hasOwnProperty( 'code' ) ) {
        $scope.loading = true;
        if( $location.search().hasOwnProperty( 'state' ) ) {
            $scope.oAuthState = $location.search()['state'];
        }
        $scope.oAuthCode = ($location.search()['code']);
        
        if( $location.search().hasOwnProperty( 'state' ) && $location.search()['state'].indexOf('Google') === -1) {
            //alert("Outlook");
            $scope.calendarType = "Outlook";
            $scope.session_state = $location.search()['session_state'];
        } else {
            //alert("in google");
            $scope.calendarType = "Google";
        }
        $http({
                method  : 'POST',
                url     : prefix + '/setupCalendarIntegation',
                data    : $.param({
                    'code': $scope.oAuthCode,
                    'state': $scope.oAuthState,
                    'session_state':$scope.session_state,
                    'calendarType': $scope.calendarType
                }),
                headers : { 'Content-Type': 'application/x-www-form-urlencoded' } 
        }).
        success(function (data, status) {
            if( data.error ) {
                $scope.loading = false;
                $scope.errorMessage = data.message;
            } else {
                $scope.loading = false;
                $scope.showMessage = true;
                $scope.message = data.message;
                $scope.successMessage = data.message;
                $timeout(function () {
                    $("#showMessage").fadeIn();
                    $("#showMessage").fadeOut(4000);
                }, 2000);
                $scope.changeclose = function () {
                    $scope.message = '';
                }
                //RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope,prefix);
                //location.reload();
                $state.transitionTo("loggedin.view_calendar_integration",{reload:true});
                /*$state.go("loggedin.view_calendar_integration");
                $state.go('loggedin.view_calendar_integration').then(() => {
                  $window.location.reload();
                });*/
            }
        }).
        error(function (data, status) {
            $scope.message = data.message;
            $scope.loading = false;
            $scope.showMessage = true;
        });
    } else {
        if( $location.search().hasOwnProperty('error') ) {
            $scope.errorMessage = "Your calendar was not integrated as ‘Cancel' was selected at the authentication stage. Please click 'Connect' if you wish to integrate your calendar";
        }
    }
        
    $http.get(prefix + '/getCalendarAccountDetails').success(function (data) {
        $scope.calendarData = data.data;
    });
    
    $http.get(prefix + '/checkCalendarAccountExist').success(function (data) {
        //alert(data.isCalendarIntegrated);
        $rootScope.allowedCalendar = data.activeIntegrations[0];
        if( $rootScope.allowedCalendar ) {

        	if( data.isCalendarIntegrated > 0 ) {
 	        
            	$rootScope.showViewCalendar = true;
            	$rootScope.showSetupCalendar = false;
           	 //console.log("view calendar"+$rootScope.showViewCalendar);
        	} else {
            	$rootScope.showSetupCalendar = true;
            	$rootScope.showViewCalendar = false;
        	}
	}
    });
   
    $scope.shouldBeOpenDeleteCalendarIntegration = false;
    
    $scope.disConnectCalendar = function() {
        $scope.shouldBeOpenDeleteCalendarIntegration = true;
       
    }
    $scope.showDeleteSuccess = false;
    $scope.showViewSettings = false;
    $scope.deleteCalendarAccount = function() {
        $scope.loading = true;
         $http({
                method  : 'POST',
                url     : prefix + '/disconnectCalendar',
                data    :  $.param({
                    'calendar': $scope.chosen_calendar.name
                }),
                headers : { 'Content-Type': 'application/x-www-form-urlencoded' } 
        }).
        success(function (data, status) {
                $scope.loading = false;
                $scope.showDeleteSuccess = true;
                $scope.deleteSuccessMessage = data.message;
                $scope.shouldBeOpenDeleteCalendarIntegration = false;
                $timeout(function () {
                    $("#saveMessage").fadeIn();
                    $("#saveMessage").fadeOut(4000);
                });
            RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope,prefix);
            //location.reload();
            $state.transitionTo('loggedin.view_calendar_integration',{reload: true});
        }).
        error(function (data, status) {
            $scope.loading = false;
            $scope.showDeleteSuccess = true;
            $scope.deleteSuccessMessage = data.message;
            $scope.shouldBeOpenDeleteCalendarIntegration = false;
            $timeout(function () {
                $("#saveMessage").fadeIn();
                $("#saveMessage").fadeOut(4000);
            });
        });
    }
}
