commusoftCommon.service('countries', ['$http', 'COUNTRY_LIST', function($http, COUNTRY_LIST) {
    var countries=COUNTRY_LIST;

    this.setNumberTypes = function setNumberTypes(number_types) {
        this.number_types = number_types;
    }

    this.getNumberTypes = function getNumberTypes() {
        return this.number_types;
    }

    this.getCountries = function getCountries() {
        return countries;
    }

    this.getCountryById = function getCountryById(id) {
        for (var i = 0, l = countries.length; i < l; i++) {
            if (countries[i].id === id) {
                return countries[i];
            }
        }
    }

    this.getCountryByCode = function getCountryByCode(code) {
        if(countries[code]['data'].length>0){
            return countries[code]['data'];
        }
    }
}]);