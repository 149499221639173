commusoftCommon.directive('disableEmptyOptionAfterSelect', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var ngModalName = attrs.ngModel;
            var disabledIsDone = false;
            scope.$watch(ngModalName, function(newVal, oldVal) {
                if(!disabledIsDone && newVal != '' && typeof newVal != "undefined") {
                    _.each(element.find('option'), function(optionElement,key) {
                        var optionValue = $(optionElement).val();
                        if(optionValue == '') {
                            $(optionElement).attr('disabled', 'disabled');
                            disabledIsDone = true;
                        }
                    });
                }
            });
        }
    }
});