$(document).ready(function() {
    // Hide the modal box for Delete
    $('body').on('click', '#hideModal', function () {
        $('#delete-record').modal('hide');
    });

    //To Hide the Save Message
    $("#saveMessage").fadeOut(4000);
    $('.close').on('click', function (event) {
        $("#saveMessage").hide();
    });


 });