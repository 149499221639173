csmodule.directive('configurepricingcolumns', function($rootScope, sidePanel) {

    var configurePricingColumns = function configurePricingColumns(scope, element, attrs) {

        scope.initialise = function initialise() {
            scope.spreadsheet_columns = angular.fromJson(scope.columns);
            var grand_total_vat_exists = scope.grandTotalVat.length > 0;

            if(grand_total_vat_exists) {
                scope.show_vat_in_grand_totals = !angular.fromJson(scope.grandTotalVat);
            }else {
                scope.show_vat_in_grand_totals = true;
            }
            scope.handleSelectedColumns();
            $rootScope.$broadcast('spreadsheet:updateHeaderSettings','');
        }

        scope.handleSelectedColumns = function handleSelectedColumns() {
            scope.$emit('estimate_price:columns_configured', {
                columns: scope.spreadsheet_columns,
                show_vat_in_grand_totals : scope.show_vat_in_grand_totals
            });
            sidePanel.close();
        }

        var registerEventListeners = (function(e) {
            element[0].addEventListener('click', scope.initialise);
        })();
    }

    return {
        restrict: 'A',
        scope: {
            columns : '@',
            grandTotalVat: '@'
        },
        templateUrl: 'template/estimate/estimate_details/price/configure_estimate_pricing.html',
        link: configurePricingColumns
    }
});