/*==========================================================================================
	Companion service to:
	- web/javascripts/commusoft/controllers/diary_controller.js
	- web/javascripts/commusoft/controllers/add_diary_event.js
==========================================================================================*/

csmodule.service('addDiaryEvent', ['$compile', '$timeout', '$http', '$q', 'prefix', 'diaryRightBarHelper', 'canLoad', function($compile, $timeout, $http, $q, prefix, diaryRightBarHelper, canLoad) {
	/*==========================================================================================
    Endpoints
  ==========================================================================================*/
  this.properties = prefix + '/searchResults';
  this.rebook = '/javascripts/commusoft/data/diary/existing_jobs.json'; // TODO: make a specific file for this (can remove the view attr)
  this.reserve_list = '/javascripts/commusoft/data/diary/existing_jobs.json'; // TODO: make a specific file for this (can remove the view attr)
  this.new_diary_event_path; // dynamically constructed on save

	/*=========================================================================================
    Called when a time slot in the diary is clicked
  ==========================================================================================*/
	this.init = function init(diary_ctrl_scope) {
		this.diary_ctrl_scope = diary_ctrl_scope;
		this.showAddDiaryEventPanel();
	}

	/*==========================================================================================
		Handle the add new event sidepanel
	==========================================================================================*/
	this.showAddDiaryEventPanel = function showAddDiaryEventPanel() {
    var show_side_panel = document.querySelector('.add-new-diary-event.in-view') == null;

    if(show_side_panel) {
      var side_panel_tpl = $compile('<span id="add_new_diary_event" sidepanel template="add_new_diary_event" title="Add new event" remove_on_close="true" href=""></span>')(this.diary_ctrl_scope);

      angular.element('body').append(side_panel_tpl);

      $timeout(function() {
        angular.element('#add_new_diary_event').scope().$$childTail.initialise();
      }, 100);

      if(diaryRightBarHelper.adding_event_from_previous_page) {
        this.focusInput('#top-input-in-details-step');
      }else {
        this.focusInput('#top-input-in-event-type');
      }
    }
	}

	this.focusInput = function focusInput(elm) {
		$timeout(function() {
			var search_existing_property_input = document.querySelector(elm);
			if(search_existing_property_input) {
				search_existing_property_input.focus();
			}
		}, 500);
	}

	/*==========================================================================================
		Crud operations
	==========================================================================================*/
    var canceler = null;
	this.getProperties = function getProperties(searchText) {
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
		var properties = $http.post(this.properties, 'q=properties:' + searchText, {timeout: canceler.promise})
										.then(function(response) {
		  								return response;
										});
		return properties;
	}

    this.getProperty = function getProperties(id) {
        var property = $http.post(this.properties, 'q=properties_id:' + id)
            .then(function(response) {
                return response;
            });
        return property;
    }

    this.loadContactsForSelectedCustomer = function loadContactsForSelectedCustomer(selectedId) {
        var contacts = $http.get(prefix + '/get_diary_customer_contacts?id=' + selectedId)
            .then(function(response) {
                return response;
            });
        return contacts;
    }

    this.loadServiceTypesForSelectedCustomer = function loadServiceTypesForSelectedCustomer(selectedId) {
        var serviceTypes = $http.get(prefix + '/customers/service_types/' + selectedId)
            .then(function(response) {
                return response;
            });
        return serviceTypes;
    }

	this.saveDiaryEvent = function saveDiaryEvent(event_type, diary_event) {
		this.new_diary_event_path = prefix + '/diary/add_event/' + event_type;
    return $http.post(this.new_diary_event_path, "event=" + encodeURIComponent(JSON.stringify(diary_event)));
	}

    this.loadAllNotificationData = function loadAllNotificationData(additionalData) {
		var notificationData;
		if(additionalData) {
			notificationData = $http.post(prefix + '/diary/get_all_notification_data', "data=" + encodeURIComponent(JSON.stringify(additionalData)))
				.then(function(response) {
					return response;
				});
		} else {
			notificationData = $http.get(prefix + '/diary/get_all_notification_data')
				.then(function(response) {
					return response;
				});
		}

        return notificationData;
    }

	/*==========================================================================================
		Make notifications object server friendly
	==========================================================================================*/
	this.flattenNotificationsForSave = function flattenNotificationsForSave(add_diary_event_form_inputs) {
		var notification = {};
		notification.for_customer = {};
		notification.for_work_address = {};
		notification.for_engineer = {};

		if(add_diary_event_form_inputs.notify_customer) {
			notification.for_customer.recipient = add_diary_event_form_inputs.notifications.customer.recipient;
			notification.for_customer.type = add_diary_event_form_inputs.notifications.customer.method;
			notification.for_customer.values =  add_diary_event_form_inputs.notifications.customer[notification.for_customer.type];
		}

		if(add_diary_event_form_inputs.notify_work_address) {
			notification.for_work_address.recipient = add_diary_event_form_inputs.notifications.work_address.recipient;
			notification.for_work_address.type = add_diary_event_form_inputs.notifications.work_address.method;
			notification.for_work_address.values =  add_diary_event_form_inputs.notifications.work_address[notification.for_work_address.type];
		}

		if(add_diary_event_form_inputs.notify_engineer) {
			notification.for_engineer.type = add_diary_event_form_inputs.notifications.engineer.method;
			notification.for_engineer.send_on = add_diary_event_form_inputs.notifications.engineer.send_on;
			notification.for_engineer.values =  add_diary_event_form_inputs.notifications.engineer[notification.for_engineer.type];
		}

		return notification;
	}

	/*==========================================================================================
		Animations
	==========================================================================================*/
	this.handlePropertySelectedAnimation = function handlePropertySelectedAnimation(index) {
		var deferred = $q.defer(),
				animation_duration = 600;

		this.property_elm = this.getSelectedPropertyElm(index);
		this.property_top_pos = this.property_elm.getBoundingClientRect().top; // re-used when choose property step is re-opened
		this.property_elm.style.top = this.property_top_pos + 14 + 'px';

		$timeout(function() {
			deferred.resolve();
		}, animation_duration);

		return deferred.promise;
	}

	/*==========================================================================================
		When returning to the choose property step.
		- If selected property came from the search 'this.property_elm' will exist, otherwise if
			the property was newly added (from the 'Add property' views) then this.property_elm
			won't exist
	==========================================================================================*/
	this.handlePropertyUnSelectedAnimation = function handlePropertyUnSelectedAnimation() {
		var deferred = $q.defer(),
				animation_duration = 750,
				self = this;

		if(this.property_elm) {
			// TODO: find a nicer way of doing this
			this.property_elm.style.cssText = 'top: 165px !important';

			$timeout(function() {
				self.property_elm.style.cssText = 'top: ' + self.property_top_pos + 'px !important';
			}, 100);
		}

		$timeout(function() {
			if(self.property_elm) {
				self.property_elm.removeAttribute('style') // remove the 'top' style prop
				self.property_elm.classList.remove('selected-property');
				self.property_elm = null;
			}
			deferred.resolve();
		}, animation_duration);

		return deferred.promise;
	}

	this.getSelectedPropertyElm = function getSelectedPropertyElm(index) {
		let elm = document.querySelector('#side-panel.add-new-diary-event').querySelectorAll('.pretty-list-with-search li.property-item-for-animation')[index];
		console.log(elm)
		return elm;
	}
}]);
