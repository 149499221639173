commusoftCommon.service('stepAnimate', function($timeout) {

    /*====================================================
     External Methods
     ====================================================*/

    // merge steps
    this.mergeSteps = function mergeSteps(merge_type, advanced_estimate) {
        var self = this;
        this.merge_type = merge_type;
        this.advanced_estimate = advanced_estimate;

        $timeout(function() {
            self.mergeAnimationCtrl();
        }, 810);
    }

    // un merge steps
    this.unmergeSteps = function unmergeSteps() {
        this.unmergeAnimationCtrl();
    }

    /*====================================================
     Internal Methods
     ====================================================*/
    this.getElms = function getElms() {
        this.panel = document.querySelector('#estimates-shelf');
        this.step_text = document.querySelector('#step-7-text');
        this.step_count = document.querySelector('#invoice-schedule-count');
        this.loading_icon = document.querySelector('#step-loading-image');
        this.confirmation_icon = document.querySelector('#step-confirmation-image');

        if(this.advanced_estimate) {
            this.step_4 = document.querySelector('#estimate-todo-step');
            this.step_5 = document.querySelector('#estimate-budget-step');
        }

        this.step_6 = document.querySelector('#estimate-price-step');
        this.step_7 = document.querySelector('#estimate-invoice-step');
    }

    /*====================================================
     Merge Steps
     ====================================================*/

    this.mergeAnimationCtrl = function mergeAnimationCtrl() {
        var self = this;
        this.getElms();
        this.setAnimationText();
        this.beginningAnimation();

        $timeout(function() {
            self.applyLoadingState();
        }, 650);

        $timeout(function() {
            self.showLoadingText();
        }, 900);

        $timeout(function() {
            self.moveStepsUp();
        }, 1100);

        $timeout(function() {
            self.applyConfirmationState();
        }, 1700);

        $timeout(function() {
            self.showConfirmationIcon();
        }, 2000);

        $timeout(function() {
            self.finishingPos1();
            self.finishingPos2();
        }, 3000);

        $timeout(function() {
            self.finishingPos3();
        }, 3500);

        $timeout(function() {
            self.triggerMergeAnimateEnd();
        }, 3800);

        $timeout(function() {
            self.resetMergeClasses();
        }, 3900);
    }

    this.setAnimationText = function setAnimationText() {
        if (this.merge_type === 'clone') {
            this.loading_message = 'Cloning Option';
            this.confirmation_message = 'Option Cloned';
        }else if (this.merge_type === 'new_option') {
            this.loading_message = 'Creating Option';
            this.confirmation_message = 'Option Created';
        }
    }

    this.beginningAnimation = function beginningAnimation() {
        if(this.advanced_estimate) {
            this.step_4.className += ' animate-step';
            this.step_5.className += ' animate-step';
        }

        this.step_6.className += ' animate-step';
        this.step_7.className += ' animate-step';
    }

    this.applyLoadingState = function applyLoadingState() {
        this.step_7.className += ' loading-state';
        this.step_text.className += ' text-hidden';
        this.step_text.innerHTML = this.loading_message;
    }

    this.showLoadingText = function showLoadingText() {
        this.step_text.classList.remove('text-hidden');
    }

    this.moveStepsUp = function moveStepsUp() {
        if(this.advanced_estimate) {
            this.step_4.className += ' animate-step-up';
            this.step_5.className += ' animate-step-up';
        }

        this.step_6.className += ' animate-step-up';
        this.step_7.className += ' animate-step-up';
    }

    this.applyConfirmationState = function applyConfirmationState() {
        this.loading_icon.className += ' image-hidden';
        this.step_text.innerHTML = this.confirmation_message;
    }

    this.showConfirmationIcon = function showConfirmationIcon() {
        this.confirmation_icon.className += ' image-showing';
    }

    // finishing animation sequence when the steps have moved up

    this.finishingPos1 = function finishingPos1() {
        if(this.advanced_estimate) {
            this.step_4.className += ' faded-out';
            this.step_5.className += ' faded-out';
        }

        this.step_6.className += ' faded-out';
        this.step_7.className += ' move-left';
    }

    this.finishingPos2 = function finishingPos2() {
        this.step_7.className += ' move-up';
    }

    this.finishingPos3 = function finishingPos3() {
        this.confirmation_icon.classList.remove('image-showing');
        this.confirmation_icon.className += ' image-hidden';

        // show the step count
        this.step_count.childNodes[1].innerHTML = '3';
        this.step_count.className += ' count-showing';

        // show the 'options' text
        this.step_text.innerHTML = 'Options';
        this.step_text.className += ' normal-link';

        // remove border & shadow from step
        this.step_7.className += ' without-outline';
    }

    this.triggerMergeAnimateEnd = function triggerMergeAnimateEnd() {
        var panel_updated = new Event('merge_animation_complete');
        this.panel.dispatchEvent(panel_updated);
    }

    this.resetMergeClasses = function resetMergeClasses() {
        if(this.advanced_estimate) {
            this.step_4.className = 'step-that-animates ng-hide';
            this.step_5.className = 'step-that-animates ng-hide';
        }

        this.step_6.className = 'step-that-animates ng-hide';
        this.step_7.className = 'step-that-animates ng-hide';

        // Todo modified the value 6 => 7 & Price => Invoice Schedule
        this.step_count.childNodes[1].innerHTML = '7';
        this.step_text.innerHTML = 'Invoice Schedule';
        this.loading_icon.classList.remove('image-hidden');
        this.step_count.classList.remove('count-showing');
        this.step_text.classList.remove('normal-link');
    }

    /*====================================================
     Un merge steps
     ====================================================*/
    this.unmergeAnimationCtrl = function unmergeAnimationCtrl() {
        var self = this;
        this.getElms();
        this.getExtraElms();
        this.resetStepPositions();

        $timeout(function() {
            self.unfoldSteps();
        }, 200);

        $timeout(function() {
            self.showStepCounts();
        }, 800);

        $timeout(function() {
            self.hideStepCountsBelow();
        }, 1400);

        $timeout(function() {
            self.showStepCountsBelow();
            self.slideStepsLeft();
        }, 2200);

        $timeout(function() {
            self.removeBorders();
        }, 2800);

        $timeout(function() {
            self.triggerUnmergeAnimateEnd();
        }, 2900);

        $timeout(function() {
            self.resetUnmergeClasses();
        }, 3000);
    }

    // elms that are only needed for the un-merging animaiton
    this.getExtraElms = function getExtraElms() {
        this.step_7 = document.querySelector('#estimate-invoice-step');
        this.step_8 = document.querySelector('#estimate-customer-step');
        this.step_9 = document.querySelector('#estimate-accept-step');
    }

    this.resetStepPositions = function resetStepPositions() {
        if(this.advanced_estimate) {
            this.step_4.className += ' step-showing';
            this.step_5.className += ' step-showing';
        }

        this.step_6.className += ' step-showing';
        this.step_7.className += ' step-showing';
    }

    this.unfoldSteps = function unfoldSteps() {
        if(this.advanced_estimate) {
            this.step_4.className += ' unfold-step';
            this.step_5.className += ' unfold-step';
        }

        this.step_6.className += ' unfold-step';
        this.step_7.className += ' unfold-step';
    }

    this.showStepCounts = function showStepCounts() {
        if(this.advanced_estimate) {
            this.step_4.className += ' with-steps-showing';
            this.step_5.className += ' with-steps-showing';
        }

        this.step_6.className += ' with-steps-showing';
        this.step_7.className += ' with-steps-showing';
    }

    this.hideStepCountsBelow = function hideStepCountsBelow() {
        this.step_8.className += ' with-hidden-count';
        this.step_9.className += ' with-hidden-count';
    }

    this.showStepCountsBelow = function showStepCountsBelow() {
        var step_8_count = this.step_8.querySelector('.text');
        var step_9_count = this.step_9.querySelector('.text');

        step_8_count.innerHTML = '8';
        step_9_count.innerHTML = '9';

        this.step_8.classList.remove('with-hidden-count');
        this.step_9.classList.remove('with-hidden-count');
        this.step_8.className += ' with-count-showing';
        this.step_9.className += ' with-count-showing';
    }

    this.slideStepsLeft = function slideStepsLeft() {
        if(this.advanced_estimate) {
            this.step_4.className += ' left-aligned';
            this.step_5.className += ' left-aligned';
        }

        this.step_6.className += ' left-aligned';
        this.step_7.className += ' left-aligned';
    }

    this.removeBorders = function removeBorders() {
        if(this.advanced_estimate) {
            this.step_4.className += ' without-border';
            this.step_5.className += ' without-border';
        }

        this.step_6.className += ' without-border';
        this.step_7.className += ' without-border';
    }

    this.triggerUnmergeAnimateEnd = function triggerUnmergeAnimateEnd() {
        var panel_updated = new Event('un_merge_animation_complete');
        this.panel.dispatchEvent(panel_updated);
    }

    this.resetUnmergeClasses = function resetUnmergeClasses() {
        var step_8_count = this.step_8.querySelector('.text');
        var step_9_count = this.step_9.querySelector('.text');

        step_8_count.innerHTML = '5';
        step_9_count.innerHTML = '6';

        this.step_8.classList.remove('with-count-showing');
        this.step_9.classList.remove('with-count-showing');

        if(this.advanced_estimate) {
            this.step_4.className = 'step-that-animates';
            this.step_5.className = 'step-that-animates';
        }

        this.step_6.className = 'step-that-animates';
        this.step_7.className = 'step-that-animates';
    }
});