// 'use strict';

/* Services */


// Demonstrate how to register services
// In this case it is a simple value service.
reportingModule.service('serFormatwidgetData', function($translate,$rootScope){
    this.typeCastToInt = function (arrData){
        //return arrData;
        var arrReturn = []
        for(i = 0; i < arrData.length; i++){
            arrReturn.push(parseInt(arrData[i]));
        }
        return arrReturn;
    };
    this.typeCastToFloat = function (arrData){
        //return arrData;
        var arrReturn = []
        for(i = 0; i < arrData.length; i++){
            arrReturn.push(parseFloat(arrData[i]));
        }
        return arrReturn;
    };
    this.getDataForWidgetDirective = function(widgetData){

        if (widgetData.widget.toUpperCase()=='JOBSBYMONTH'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'column',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null
            };
            if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_data')){
                return_variable.graph_options = {
                    x_axis_categories: widgetData.xaxis_data,
                    y_axis: [
                        {title: {text: widgetData.yaxis_text },opposite: false }
                    ],
                    series: [
                        {name: widgetData.yaxis_text, data: this.typeCastToInt(widgetData.yaxis_data)}
                    ]
                }
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;

        } else if (widgetData.widget.toUpperCase() == 'ESTIMATESBYMONTH'){
            var return_variable =  {
                maxSizeY: 1,
                maxSizeX: 1,
                sizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'bar_with_line',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null
            };
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis0_data') && widgetData.hasOwnProperty('yaxis1_data')){
                return_variable.graph_options =
                    {
                        x_axis_categories: widgetData.xaxis_data,
                        y_axis: [
                            {title: {text: widgetData.yaxis0_text},	opposite: false},
                            {title: {text: widgetData.yaxis1_text},	opposite: true,labels: {format: $rootScope.CustomCurrency+'{value}'}}
                        ],
                        series: [
                            {yAxis: 0,	type: 'column',	name: widgetData.yaxis0_text,		data: this.typeCastToInt(widgetData.yaxis0_data)},
                            {yAxis: 1,	type: 'line',	name: widgetData.yaxis1_text,	data: this.typeCastToInt(widgetData.yaxis1_data)}
                        ]
                    }
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        } else if (widgetData.widget.toUpperCase() == 'ESTIMATESREJECTIONBYREASON'){
            var server_data = widgetData.pie;
            var typecasted_piedata = [];
            angular.forEach(server_data, function(data){
                this.push({name:data.name, y:parseInt(data.y)});
            },typecasted_piedata);
            var return_variable =  {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                type: 'pie',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null,
                graph_options: {    series: [{name: 'No. rejections',data: typecasted_piedata}] }
            };
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }else if (widgetData.widget.toUpperCase() == 'NOOFJOBSCLOSEDAFTERFIRSTVISIT'){
            var server_data = widgetData.guage;
            var typecasted_guagedata = [];
            angular.forEach(server_data, function(data){
                this.push({y:parseInt(data.y)});
            },typecasted_guagedata);
            var return_variable =  {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'guage',
                no_records_found_message:widgetData.no_records_found_message,
                graph_options: {
                    series: [	{data: typecasted_guagedata }	]
                },
                with_expanded_detail: false
            };
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }else if (widgetData.widget.toUpperCase() == 'AFTERSALESCARE'){
            var server_data = widgetData.guage;
            var typecasted_guagedata = [];
            angular.forEach(server_data, function(data){
                this.push({y:parseInt(data.y)});
            },typecasted_guagedata);
            var return_variable =  {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'guage',
                no_records_found_message:widgetData.no_records_found_message,
                graph_options: {
                    series: [	{data: typecasted_guagedata }	]
                },
                with_expanded_detail: false
            };
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }else if (widgetData.widget.toUpperCase() == 'SALESBYMONTH'){
            return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'column',
                no_records_found_message:widgetData.no_records_found_message,
            }
            if(widgetData.hasOwnProperty('yaxis_data')){
                return_variable.graph_options =  {
                    x_axis_categories: widgetData.xaxis_data,
                    y_axis: [{labels: {format: $rootScope.CustomCurrency+'{value}'},title: {text: widgetData.yaxis_text},opposite: false}],
                    series: [   {name: widgetData.xaxis_text,   data: this.typeCastToInt(widgetData.yaxis_data)}    ]
                }
            }

            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }  else if (widgetData.widget.toUpperCase() == 'RECALLSBYENGINEER'){
            var server_data = widgetData.pie;
            var typecasted_piedata = [];
            angular.forEach(server_data, function(data){
                this.push({name:data.name, y:parseInt(data.y)});
            },typecasted_piedata);
            var return_variable =  {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                type: 'pie',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null,
                graph_options: {    series: [{name: 'No. Recalls',data: typecasted_piedata}] }
            };
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        } else if (widgetData.widget.toUpperCase()=='DIARYEVENTSBYSTATUS'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'stacked_bar',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null
            };
            if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_data0')){
                return_variable.graph_options = {
                    x_axis_categories: widgetData.xaxis_data,
                    y_axis: [
                        {title: {text: widgetData.yaxis_text },opposite: false }
                    ],
                    series: [
                        {name: widgetData.yaxis0_text, data: this.typeCastToInt(widgetData.yaxis_data0), index: 0 },
                        {name: widgetData.yaxis1_text, data: this.typeCastToInt(widgetData.yaxis_data1), index: 1},
                        {name: widgetData.yaxis2_text, data: this.typeCastToInt(widgetData.yaxis_data2), index: 2},
                        {name: widgetData.yaxis3_text, data: this.typeCastToInt(widgetData.yaxis_data3), index: 3},
                        {name: widgetData.yaxis4_text, data: this.typeCastToInt(widgetData.yaxis_data4), index: 4},
                        {name: widgetData.yaxis5_text, data: this.typeCastToInt(widgetData.yaxis_data5), index: 5},
                        {name: widgetData.yaxis6_text, data: this.typeCastToInt(widgetData.yaxis_data6), index: 6},
                        {name: widgetData.yaxis7_text, data: this.typeCastToInt(widgetData.yaxis_data7), index: 7},
                        {name: widgetData.yaxis8_text, data: this.typeCastToInt(widgetData.yaxis_data8), index: 8}
                    ]
                }
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        } else if (widgetData.widget.toUpperCase() == 'DEBTORDAYSBYAGEOFDEBT'){
            var server_data = widgetData.pie;
            var typecasted_piedata = [];
            var positive_value_count = 0;
            angular.forEach(server_data, function(data){
                var value=parseFloat(data.y);
                if(value < 0) {
                    value = 0;
                } else {
                    positive_value_count ++;
                }
                this.push({name:data.name, y:value});
            },typecasted_piedata);

            var return_variable =  {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                type: 'pie',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null
            };
            if(positive_value_count > 0) {
                return_variable.graph_options = { series: [{name: 'Age of debt',data: typecasted_piedata}] }
            } else {
                return_variable.no_records_found_message = 'No debtors days by age of debt during this period';
            }

            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        } else if (widgetData.widget.toUpperCase()=='ADVERTISINGPERFORMANCE') {

            var yaxis_data = [];
            if(widgetData.no_records_found_message == ''){
                for(var i=0; i< widgetData.yaxis_data.length;i++) {
                    var text = widgetData.yaxis_data[i].name;
                    var data = widgetData.yaxis_data[i].data;
                    var typecasted_data = [];
                    angular.forEach(data, function(cnt){
                        this.push(parseInt(cnt));
                    },typecasted_data);
                    yaxis_data.push({name:text,data: typecasted_data});
                }
            }

            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                type: 'stacked_area',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null
            };
            if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_data')){
                return_variable.graph_options = {
                    x_axis_categories: widgetData.xaxis_data,
                    y_axis: [
                        {title: {text: 'No. Customers gained' },opposite: false }
                    ],
                    series: yaxis_data
                }
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }
        else if(widgetData.widget.toUpperCase()=='SALESPERINVOICECATEGORYPERMONTH'){

            var yaxis_data = [];
            if(widgetData.no_records_found_message == ''){
                for(var i=0; i< widgetData.yaxis_data.length;i++) {
                    var text = widgetData.yaxis_texts[i];
                    var data = widgetData.yaxis_data[i];
                    var typecasted_data = [];
                    angular.forEach(data, function(cnt){
                        this.push(parseInt(cnt));
                    },typecasted_data);
                    yaxis_data.push({name:text,data: typecasted_data});
                }
            }

            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'stacked_bar',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null
            };
            if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_data')){
                return_variable.graph_options = {
                    x_axis_categories: widgetData.xaxis_data,
                    y_axis: [
                        {labels: {format: $rootScope.CustomCurrency+'{value}'},title: {text: widgetData.yaxis_text },opposite: false }
                    ],
                    series: yaxis_data
                }
            }

            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;

        }
        else if(widgetData.widget.toUpperCase()=='JOBSTATUS'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'html-stats-grid',
                subtype: 'jobs-status',
                html: true,
                no_records_found_message: widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null,
                html_data : []
            };
            if(typeof widgetData.html_data != 'undefined')
            {
                return_variable['html_data'] = [{'heading' : 'widget_without_diary_events', 'content' : widgetData.html_data['nodiaryevents_count']},
                    {'heading' : 'widget_in_progress', 'content' : widgetData.html_data['jobinprogresscount']},
                    {'heading' : 'widget_need_attention', 'content' : widgetData.html_data['reviewjobcount']},
                    {'heading' : 'widget_on_hold', 'content' : widgetData.html_data['onhold_count']},
                    {'heading' : 'widget_completed', 'content' : widgetData.html_data['completed_count']}];
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }
        else if(widgetData.widget.toUpperCase()=='SLAJOBSTATUS'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'html-stats-grid',
                subtype: 'sla-jobs-status',
                html: true,
                no_records_found_message: widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null,
                html_data : []
            };
            if(typeof widgetData.html_data != 'undefined')
            {
                return_variable['html_data'] = [{'heading' : 'Job creation to first diary event', 'content' : widgetData.html_data['first_diary_event_created']},
                    {'heading' : 'Job creation to engineer actual arrival time', 'content' : widgetData.html_data['engineer_arrive_on_site']},
                    {'heading' : 'Job creation to job completion', 'content' :widgetData.html_data['job_creation_job_completion']},
                    {'heading' : 'Actual complete date/time vs Job required complete date/time', 'content' :widgetData.html_data['job_expected_completion_date']}];
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }
        else if(widgetData.widget.toUpperCase()=='PARTSSTATUS'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'html-stats-grid',
                subtype: 'parts-status',
                html : true,
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null,
                html_data : []
            };
            if(typeof widgetData.html_data != 'undefined')
            {
                return_variable['html_data'] = [{'heading' : 'requested_parts', 'content' : widgetData.html_data['requestedcount']},
                    {'heading' : 'parts_on_order', 'content' : widgetData.html_data['orderedDuePartsCount']},
                    {'heading' : 'parts_available_without_future_diary_events', 'content' : widgetData.html_data['availablePartsWithoutFutureevents']}];
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }
        else if(widgetData.widget.toUpperCase()=='PARTS'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'html-stats-grid',
                subtype: 'parts-status',
                html : true,
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null,
                html_data : []
            };
            if(typeof widgetData.html_data != 'undefined')
            {
                return_variable['html_data'] = [{'heading' : 'parts_parts_on_order_overdue', 'content' : widgetData.html_data['orderedDuePartsPastcount']},
                    {'heading' : 'parts_parts_on_order_due_to_arrive_today', 'content' : widgetData.html_data['orderedDuePartsTodayCount']},
                    {'heading' : 'parts_parts_on_order_due_to_arrive_tomorrow', 'content' : widgetData.html_data['orderedDuePartsTomorrowCount']}];
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }else if(widgetData.widget.toUpperCase()=='PARTSDUETODELIVERTOSITE'){
            let  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'html-stats-grid',
                subtype: 'parts-status',
                html : true,
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null,
                html_data : []
            };
            if(typeof widgetData.html_data != 'undefined')
            {
                let state = 'loggedin.reporting.view';
                let category = 'parts';
                let totalDueParamsSubcategory = 'parts_due_to_deliver_to_site_total_due';
                let dueTodaySubcategory = 'parts_due_to_deliver_to_site_due_today';
                let dueWithNoFutureAppointmentSubcategory = 'parts_due_to_deliver_to_site_due_with_no_future_appointment';
                return_variable['html_data'] = [
                    {
                        heading: totalDueParamsSubcategory,
                        content : widgetData.html_data['totalDue'],
                        routing: {
                            state: state,
                            params: {
                                category: category,
                                subcategory: totalDueParamsSubcategory,
                                flag: 1
                            }
                        }
                    },
                    {
                        heading: dueTodaySubcategory,
                        content : widgetData.html_data['dueToday'],
                        routing: {
                            state: state,
                            params: {
                                category: category,
                                subcategory: dueTodaySubcategory,
                                flag: 1
                            }
                        }
                    },
                    {
                        heading: dueWithNoFutureAppointmentSubcategory,
                        content : widgetData.html_data['dueWithNoFutureAppointment'],
                        routing: {
                            state: state,
                            params: {
                                category: category,
                                subcategory: dueWithNoFutureAppointmentSubcategory,
                                flag: 1
                            }
                        }
                    }
                ];
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }else if(widgetData.widget.toUpperCase()=='PARTSFORDIARYEVENTS'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'html-stats-grid',
                subtype: 'parts-status',
                html : true,
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null,
                html_data : []
            };
            if(typeof widgetData.html_data != 'undefined')
            {
                return_variable['html_data'] = [{'heading' : 'parts_for_diary_events_no_of_parts_due_today_for_future_events', 'content' : widgetData.html_data['dueTodayWithFutureAppointment']},
                    {'heading' : 'parts_for_diary_events_no_of_parts_overdue_for_future_events', 'content' : widgetData.html_data['overdueWithFutureAppointment']}];
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }else if(widgetData.widget.toUpperCase()=='PARTSTORETURN'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'html-stats-grid',
                subtype: 'parts-status',
                html : true,
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null,
                html_data : []
            };
            if(typeof widgetData.html_data != 'undefined')
            {
                return_variable['html_data'] = [{'heading' : 'parts.not.used', 'content' : widgetData.html_data['Excess_for_job']},
                    {'heading' : 'Returning.to.stock', 'content' : widgetData.html_data['Return_to_stock']},
                    {'heading' : 'Returning.to.supplier', 'content' : widgetData.html_data['Return_to_supplier']}];
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }else if(widgetData.widget.toUpperCase()=='SLAJOBSBYMONTH'){
            var yaxis_data = [];
            if(widgetData.no_records_found_message == ''){
                for(var i=0; i< widgetData.yaxis_data.length;i++) {
                    var text = widgetData.yaxis_data[i].name;
                    var data = widgetData.yaxis_data[i].data;
                    var typecasted_data = [];
                    angular.forEach(data, function(cnt){
                        this.push(parseInt(cnt));
                    },typecasted_data);
                    yaxis_data.push({name:text,data: typecasted_data});
                }
            }
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                type: 'stacked_area',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null
            };
            if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_data')){
                return_variable.graph_options = {
                    x_axis_categories: widgetData.xaxis_data,
                    y_axis: [
                        {title: {text: 'No. SLA metrics breached' },opposite: false }
                    ],
                    series: yaxis_data
                }
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;

        } else if(widgetData.widget.toUpperCase()=='ENGINEERTIMESHEETSTATUS'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'html-stats-grid',
                subtype: 'engineer-timesheet-status',
                html: true,
                no_records_found_message: widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null,
                html_data : []
            };
            if(typeof widgetData.html_data != 'undefined')
            {
                return_variable['html_data'] = [{'heading' : 'EngineerTimesheetStatus.helptext', 'content' : widgetData.html_data['unApprovedCount']}];
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }else if (widgetData.widget.toUpperCase()=='OPPORTUNITIESBYMONTH'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'column',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null
            };
            if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_data')){
                return_variable.graph_options = {
                    x_axis_categories: widgetData.xaxis_data,
                    y_axis: [
                        {title: {text: widgetData.yaxis_text },opposite: false }
                    ],
                    series: [
                        {name: widgetData.yaxis_text, data: this.typeCastToInt(widgetData.yaxis_data)}
                    ]
                }
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }else if (widgetData.widget.toUpperCase() == 'OPPORTUNITIESREJECTIONBYREASON'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'stacked_bar',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null
            };
            if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_array_data')){
                yaxis_data = [];
                for(var i=0; i< widgetData.yaxis_array_data.length;i++) {
                    var text = widgetData.yaxis_array_data[i]['yaxis_text'];
                    var data = this.typeCastToInt(widgetData.yaxis_array_data[i]['yaxis_data']);
                    yaxis_data.push({name:text,data: data, index: i});
                }
                return_variable.graph_options = {
                    x_axis_categories: widgetData.xaxis_data,
                    y_axis: [
                        {title: {text: widgetData.yaxis_text },opposite: false }
                    ],
                    series: yaxis_data
                }
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }else if (widgetData.widget.toUpperCase() == 'OPPORTUNITIESCANCELBYREASON'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'stacked_bar',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null
            };
            if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_array_data')){
                yaxis_data = [];
                for(var i=0; i< widgetData.yaxis_array_data.length;i++) {
                    var text = widgetData.yaxis_array_data[i]['yaxis_text'];
                    var data = this.typeCastToInt(widgetData.yaxis_array_data[i]['yaxis_data']);
                    yaxis_data.push({name:text,data: data, index: i});
                }
                return_variable.graph_options = {
                    x_axis_categories: widgetData.xaxis_data,
                    y_axis: [
                        {title: {text: widgetData.yaxis_text },opposite: false }
                    ],
                    series: yaxis_data
                }
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }else if (widgetData.widget.toUpperCase()=='OPPORTUNITIESWONBYMONTH'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'column',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null
            };
            if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_data')){
                return_variable.graph_options = {
                    x_axis_categories: widgetData.xaxis_data,
                    y_axis: [
                        {title: {text: widgetData.yaxis_text },opposite: false }
                    ],
                    series: [
                        {name: widgetData.yaxis_text, data: this.typeCastToFloat(widgetData.yaxis_data)}
                    ]
                }
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }else if (widgetData.widget.toUpperCase()=='PROPOSALVALUESLOWHIGHBYMONTH'){
            var yaxis_data = [];
            if(widgetData.no_records_found_message == ''){
                for(var i=0; i< widgetData.yaxis_data.length;i++) {
                    var text = widgetData.yaxis_data[i].name;
                    var data = widgetData.yaxis_data[i].data;
                    var typecasted_data = [];
                    angular.forEach(data, function(cnt){
                        this.push(parseFloat(cnt));
                    },typecasted_data);
                    yaxis_data.push({name:text,data: typecasted_data});
                }
            }

            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                type: 'stacked_area',
                no_records_found_message:widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null
            };
            if(widgetData.hasOwnProperty('xaxis_data') && widgetData.hasOwnProperty('yaxis_data')){
                return_variable.graph_options = {
                    x_axis_categories: widgetData.xaxis_data,
                    y_axis: [
                        {title: {text: 'Proposal values' },opposite: false }
                    ],
                    series: yaxis_data
                }
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }
        else if(widgetData.widget.toUpperCase()=='SLADASHBOARD'){
            var  return_variable = {
                maxSizeY: 1,
                maxSizeX: 1,
                title: widgetData.title,
                link: '',
                type: 'html-stats-grid',
                subtype: 'sla-dashboard',
                html: true,
                no_records_found_message: widgetData.no_records_found_message,
                with_expanded_detail: false,
                expanded_detail: null,
                html_data : []
            };
            if(typeof widgetData.html_data != 'undefined')
            {
                return_variable['html_data'] = [{'heading' : 'SLA due to breach in 0-60 minutes', 'content' : widgetData.html_data['sixty_count']},
                    {'heading' : 'SLA due to breach in 61-120 minutes', 'content' : widgetData.html_data['onetwenty_count']},
                    {'heading' : 'SLA due to breach in 121-240 minutes', 'content' :widgetData.html_data['twoforty_count']},
                    {'heading' : 'SLA due to breach in 240+ minutes', 'content' :widgetData.html_data['twofortyplus_count']}];
            }
            if(widgetData.hasOwnProperty('row')){
                return_variable.row = widgetData.row
            }
            if(widgetData.hasOwnProperty('col')){
                return_variable.col = widgetData.col
            }
            return_variable.featureid = widgetData.featureid;
            return return_variable;
        }
    }
});