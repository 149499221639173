csmodule.service('budgetAndCostHelper', function($q, $timeout) {

  this.handleDeleteCost = function handleDeleteCost(directive_scope, delete_cost_modal) {
      var self = this;
      this.directive_scope = directive_scope;
      this.rows = directive_scope.spreadsheet_rows;
      this.row_index = directive_scope.cost_row_index;
      this.row = this.rows[this.row_index].row[0].associated_rows;
      this.protected_costs = this.getProtectedCosts();
      this.directive_scope.cost_to_delete_description = this.row[0][0].value;
      this.directive_scope.delete_row_index = this.rows[this.row_index].id;
      this.initialise();
  }

  this.initialise = function initialise() {
      var self = this;
      this.handleCostType().then(function() {
          self.handleDeleteUI();
      }, function() {
          self.handleUnableToDeleteUI();
      });
  }

  this.handleCostType = function handleCostType() {
      var deferred = $q.defer(),
          cost_description = this.row[0][0].value,
          first_word = cost_description.split(" ")[0],
          type_is_protected = this.protected_costs.indexOf(first_word) > -1;

      if(type_is_protected) {
          deferred.reject();
      }else {
          deferred.resolve();
      }

      return deferred.promise;
  }

  this.handleDeleteUI = function handleDeleteUI() {
      this.directive_scope.cost_can_be_deleted = true;
      var delete_row_index = this.directive_scope.delete_row_index;
      $timeout(function() {
        $("#delete-cost-modal-"+delete_row_index).modal("show");
      }, 100);
  }

  this.handleUnableToDeleteUI = function handleUnableToDeleteUI() {
      this.directive_scope.cost_can_be_deleted = false;
      var delete_row_index = this.directive_scope.delete_row_index;
      $timeout(function() {
        $("#delete-cost-modal-"+delete_row_index).modal("show");
      }, 100);
  }

  this.cancelDeleteUI = function cancelDeleteUI() {
      $("#delete-cost-modal-"+this.directive_scope.delete_row_index).modal('hide');
  }

  /*==========================================================================================
   These are the todo types that can't be deleted from the budget & cost spreadsheet
   ~ todos that have hours assigned to them can't be deleted from this view - they should
   be deleted from the todos spreadsheet
   ==========================================================================================*/
  this.getProtectedCosts = function getProtectedCosts() {
      var protected_costs = [
          "#install",
          "#certificate",
          "#maintenance",
          "#service",
          "#decommission"
      ]

      return protected_costs;
  }
});