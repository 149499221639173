function make_ordered_hash() {
    var keys = [];
    var vals = {};
    return {
        push: function(k,v) {
            if (!vals[k]) keys.push(k);
            vals[k] = v;
        },
        insert: function(pos,k,v) {
            if (!vals[k]) {
                keys.splice(pos,0,k);
                vals[k] = v;
            }
        },
        val: function(k) {return vals[k]},
        length: function(){return keys.length},
        keys: function(){return keys},
        values: function(){return vals},
        remove: function (k,v) {
            var keyArray = this.val(k).keys();
            var keyIndex = keyArray.indexOf(v);
            if(keyIndex !== -1){
                keyArray.splice(keyIndex,1);

                var valueArray = this.val(k).values();
                delete valueArray[v];
            }

        },
        add: function (k,v) {
            var keyArray = this.val(k).keys();
            var keyIndex = keyArray.indexOf(v);
            if(keyIndex === -1){
                keyArray.push(v);
                var valueArray = this.val(k).values();
                valueArray[v] = v;
            }
        },
    };
};


