commusoftCommon.directive('panelwithform', function($http, $q, $document, $timeout, $compile, $parse, $rootScope, $interpolate, prefix, formPanelCollection, postCodeHelper, panelValidationHelper, confirmationBoxHelper) {
    var setupPanel = function setupPanel(scope, element, attrs) {
        scope.category = attrs.category;
        scope.input_templates;
        scope.quick_add_panel;
        scope.panel_options = attrs;
        scope.is_title_bar_link = false;
        scope.elm_supports_state = false;
        scope.save_form = true;
        scope.fatal_error = false;
        scope.loading = false;
        scope.endpoint = attrs.endpoint;
        scope.input_models = [];
        scope.just_postcode = attrs.justPostcode || false;

        panelValidationHelper.emptyInvalidInputRefs();

        window.bb = scope;

        // set instacne variables if the panel title bar has a link
        if (attrs.titleBarLink) {
            scope.is_title_bar_link = true;
            scope.title_bar_link = attrs.titleBarLink;
            scope.title_bar_link_name = attrs.titleBarLinkName;
        }

        if (attrs.elmSupportsState) {
            scope.elm_supports_state = true;
            scope.elm_that_changes_state = attrs.elmThatChangesState;
            scope.tooltip_prefix = attrs.tooltipPrefix;
        }

        if(attrs.filterForm) {
            scope.save_form = false;
        }

        $rootScope.$on('panelwithform:status_fromOperationalList', function(event,status){
            scope.category = status;
        });


        /*====================================================
         Instance only instanciated when the element is clicked
         ====================================================*/

        scope.handleQuickAdd = function handleQuickAdd() {
            scope.active_slide = 1;
            scope.form_submitted = false;
            scope.form_is_valid = false;
            scope.panel_title = scope.panel_options.panelTitle;
            scope.btn_text = scope.panel_options.panelBtnText;
            formPanelCollection.handleInputs(scope.category);
            scope.input_templates = formPanelCollection.getInputTemplates();

            scope.handlePreloading().then(function() {
                scope.getDataToPreload().then(function() {
                    scope.buildQuickAddPanel();
                    scope.handleQuickAddPanel();
                });
            }, function() {
                scope.buildQuickAddPanel();
                scope.handleQuickAddPanel();
                scope.informController();
            });
        }

        scope.informController = function informController() {
            scope.$emit('fromsidepanel:inited', {});
        }

        /*==========================================================================================
         Determine if any inputs rely on remote data, i.e the smart_filter inputs
         ==========================================================================================*/
        scope.handlePreloading = function handlePreloading() {
            var deferred = $q.defer();
            scope.preloaded_input_collection = [];

            for(var i = 0, l = scope.input_templates.length; i < l; i++) {
                var input_obj = scope.input_templates[i],
                    input_has_url = _.has(input_obj, 'url');

                if(input_has_url && input_obj.url) {
                    scope.preloaded_input_collection.push(input_obj)
                }
            }

            (scope.preloaded_input_collection.length > 0) ? deferred.resolve() : deferred.reject();
            return deferred.promise;
        }

        /*==========================================================================================
         Load data for each input, then save response data to input obj
         ==========================================================================================*/
        scope.getDataToPreload = function getDataToPreload() {
            var promises = [];
            var i, l;

            for(i = 0, l = scope.preloaded_input_collection.length; i < l; i++) {
                promises.push(
                    $http({
                        method: 'GET',
                        url: scope.preloaded_input_collection[i].url,
                        input_obj: scope.preloaded_input_collection[i]
                    })
                        .success(function(data, status, headers, config) {
                            config.input_obj.data = data;
                        })
                        .error(function(data, status, headers, config) {
                            console.warn('panelwithform directive failed to preload: ' + config.input_obj.url);
                        })
                );
            }

            return $q.all(promises);
        }

        scope.buildQuickAddPanel = function buildQuickAddPanel() {
            var link_if_needed = scope.handleTitleBarLink();
            var panel = '<section id="quickadd-panel">' +
                '<div id="breadcrumb-title-bar" ng-class="{\'out-of-view\' : hide_title_and_actions === true}">' +
                // '<h4>{{panel_title}}</h4>' + link_if_needed +
                '<a class="breadcrumb-title main-title in-view" ng-class="{\'not-link\' : active_slide === 1}" href="" ng-click="slideInView(1); hideTitleAndActions(false)" translate>{{panel_title}}</a>' + link_if_needed +
                '<a class="breadcrumb-title not-link" ng-class="{\'in-view\' : active_slide === 2}"> <span class="ss-navigateright"></span>Choose postcode</a>' +
                '</div>' +
                '<div id="fatal-error-box" ng-class="{ \'swung-in\' : fatal_error }">' +
                '<span class="ss-alert">&nbsp;</span>' +
                '<p>{{top_error_message}}</p>' +
                '<div class="fatal-error-footer">' +
                '<a href="" ng-click="handleClosePanelLinkClicked()">Close panel</a>' +
                '</div>' +
                '</div>' +
                '<form id="page-panel-main" class="with-slides">' +
                '<div class="panel-scrollable-inner slide with-padding in-view" ng-class="{ \'below-fatal-error\' : fatal_error}">' +
                '{{inner-panel}}' +
                '</div>' +
                '<div class="panel-scrollable-inner slide with-padding" ng-class="{ \'below-fatal-error\' : fatal_error, \'in-view\' : active_slide === 2}">' +
                '<div ng-if="active_slide === 2" smartpostcodeform postcode="{{postcode}}">' +

                '</div>' +
                '</div>' +
                '</form>' +
                '<div class="page-panel-actions with-slides" ng-class="{\'out-of-view\' : hide_title_and_actions === true}">' +
                '<div class="slide-button buttons" ng-class="{\'in-view\' : active_slide === 1}">' +
                '<button type="submit" ng-click="handleFormSubmit()" ng-disabled="!form_is_valid" ng-class="{ \'loading_btn loading\' : loading }" class="btn btn-primary">' +
                '<span>{{btn_text}}</span>' +
                '</button>' +
                '<a href="" ng-click="cancelSidePanel()" id="cancel-panel-btn">Cancel</a>' +
                '</div>' +
                '<div class="slide-button buttons" ng-class="{\'in-view\' : active_slide === 2}">' +
                '<button class="btn btn-primary" ng-show="show_choose_address_button" ng-click="useAddress()">' +
                '<span>Choose address</span>' +
                '</button>' +
                '<button class="btn btn-cancel" ng-show="!show_choose_address_button" ng-click="slideInView(1)">' +
                '<span>Go back</span>' +
                '</button>' +
                '</div>' +
                '</div>' +
                '</section>';

            var input_collection = [];
            var elm = '';

            this.handleFormElms = function handleFormElms() {
                var i, l;
                for(i = 0, l = scope.input_templates.length; i < l; i++) {
                    var label = scope.input_templates[i].label,
                        paidFilter = scope.input_templates[i].paidFilter,
                        input = scope.input_templates[i].input,
                        model = scope.input_templates[i].model,
                        hasPermission =scope.input_templates[i].hasPermission,
                        access = scope.input_templates[i].access,
                        input_type = scope.input_templates[i].type,
                        checkbox_input = input_type === 'checkbox',
                        Radio_inputs = input_type === 'radio',
                        checkbox_collection_input = input_type === 'checkbox_collection',
                        select_input = input_type === 'select',
                        titleSelect_input = input_type === 'titleSelect',
                        smart_filter_input = input_type === 'smart_filter',
                        smart_filter_dynamic = input_type === 'smart_filter_dynamic',
                        smart_filter_with_optgroup_input = input_type === 'smart_filter_with_optgroup',
                        required_input = scope.input_templates[i].required,
                        depends = scope.input_templates[i].depends,
                        dynamicTemplate = input_type === 'dynamicTemplate';

                    if(smart_filter_input) {
                        var data = scope.input_templates[i].data;
                        input = this.handleSmartFilter(input, model, data);
                    }
                    if (smart_filter_dynamic) {
                        input = this.handleSmartFilterDynamic(input, model, scope.input_templates[i].dynamicUrl);
                        $compile(input)(scope);
                    }
                    if(dynamicTemplate)
                    {
                        scope['templateData'] = scope.input_templates[i].data;
                        scope['templateData'] = scope.input_templates[i].data;
                    }
                    if (smart_filter_with_optgroup_input) {
                        var data = scope.input_templates[i].data;
                        input = this.handleSmartFilterWithOptGroup(input, model, data);
                        $compile(input)(scope);
                        if (! scope.hasOwnProperty(model)) {
                            scope[model] = 'prompt';
                        }
                    }
                    if(select_input || titleSelect_input) {
                        input = this.handleSimpleSelect(input, model, scope.input_templates[i].options);
                        $compile(input)(scope);
                        if (! scope.hasOwnProperty(model)) {
                            scope[model] = 'prompt';
                        }
                        if(model == 'Type') {
                            scope[model] = (scope[model] && scope[model] != 'prompt') ? scope[model] : 'type';
                        }
                    }
                    if(checkbox_input) {
                        elm += input;
                        scope.storeInputModels(input_type, model);
                    }else if(Radio_inputs){
                        elm += input;
                        scope.storeInputModels(input_type, model);
                    }else if(checkbox_collection_input) {
                        var checkboxes = scope.input_templates[i].checkboxes;
                        elm += this.assembleCheckboxCollection(label, checkboxes);
                        this.storeCheckboxCollectionModels(scope.input_templates[i].models);
                    }else {
                        if(required_input) {
                            var error_message = scope.input_templates[i].error_message;
                            var exists_error_message = scope.input_templates[i].exists_error_message;
                            input = panelValidationHelper.addValidation(input, input_type, model, label, error_message);
                            elm += this.assembleRequiredInput(label, input, input_type, error_message, exists_error_message);
                        }
                        else if(input_type === 'number' || input_type === 'time' || input_type === 'price' || input_type === 'markup') {
                            var error_message = scope.input_templates[i].error_message;
                            input = panelValidationHelper.addValidation(input, input_type, model, label, error_message);
                            elm += this.assembleNumberInput(label, input, input_type, error_message, hasPermission, access);
                        }
                        else if(input_type === 'email') {
                            var error_message = scope.input_templates[i].error_message;
                            input = panelValidationHelper.addValidation(input, input_type, model, label, error_message);
                            elm += this.assembleEmailInput(label, input, input_type, error_message);
                        }
                        else if(input_type === 'from') {
                            var error_message = scope.input_templates[i].error_message;
                            input = panelValidationHelper.addValidation(input, input_type, model, label, error_message);
                            elm += this.assembleFromInput(label, input, input_type, error_message);
                        }
                        else {
                            elm += this.assembleUnrequiredInput(label, input, input_type, hasPermission, access, paidFilter);
                        }

                        scope.storeInputModels(input_type, model);
                    }

                    // Handle Dependency between fields
                    if (depends) {
                        var depends = depends.split(',');
                        _.each(depends, function(elem) {
                            if (_.some(scope.input_models, function(model) {
                                    return (model.model_name === elem);
                                })) {

                                var dynamicUrl = scope.input_templates[i].dynamicUrl;
                                var element_type = scope.input_templates[i].type;
                                if (element_type === 'select' || element_type === 'smart_filter_with_optgroup') {

                                    scope.$watch(elem, function(newVal, oldVal) {
                                        if (newVal != oldVal && newVal != 'prompt') {
                                            scope[model] = 'prompt';
                                            var url = $interpolate(prefix + dynamicUrl)(scope);
                                            $http.get(url).success(function(data) {
                                                scope[model + "_options"] = data;
                                            });
                                        }
                                    });
                                } else if (element_type === 'smart_filter_dynamic') {
                                    scope.$watch(elem, function(newVal, oldVal) {
                                        if (newVal != oldVal && newVal != 'prompt') {
                                            var url = $interpolate(prefix + dynamicUrl)(scope);
                                            // Update URL to reflect dependent value change
                                            scope.handleSmartFilterDynamic(null, model, url);
                                        }
                                    });
                                }

                                // Handle when the sidepanel is opened after it was closed earlier
                                // to reinstate the dependent fields. Because the sidepanel is destroyed
                                // everytime, this has to be recreated. Better to not destroy it.
                                if (scope[elem]) {
                                    var url = $interpolate(prefix + dynamicUrl)(scope);
                                    $http.get(url).success(function(data) {
                                        if (element_type === 'select' || element_type === 'smart_filter_with_optgroup') {
                                            scope[model + "_options"] = data;
                                        } else {

                                        }
                                    });
                                }
                            }
                        })
                    }
                }
                scope.quick_add_elm = panel.replace('{{inner-panel}}', elm);
            }

            /*==========================================================================================
             Puts model and input type for each checkbox in the collection into the input_models array
             ==========================================================================================*/
            this.storeCheckboxCollectionModels = function storeCheckboxCollectionModels(collection_models) {
                for(var i = 0, l = collection_models.length; i < l; i++) {
                    scope.storeInputModels('checkbox_in_collection', collection_models[i]);
                }
            }

            /*====================================================
             Standard input
             - inputs that are not required
             ====================================================*/
            this.assembleUnrequiredInput = function assembleUnrequiredInput(label, input, input_type, hasPermission, access, paidFilter) {
                if(hasPermission && access){
                    var input = '<div class="block-label" translate has-permission="' + hasPermission +','+ access + ' ">' + label + '</div>' + '<div has-permission="' + hasPermission +','+ access + ' ">' + input + '</div>';
                }
                else if (paidFilter == 'ng-show="InvoiceTypeId==1"') {
                    var input = '' +
                        '<div class="block-label"  ng-show="InvoiceTypeId==1" translate>' +
                        label +
                        '</div>' +
                        '<div>' +
                        input +
                        '</div>';
                } else if(typeof  label == "undefined"){
                    var input = '' +
                        '<div>' +
                        input +
                        '</div>';
                }else {
                    var input = '' +
                        '<div class="block-label" translate>' +
                        label +
                        '</div>' +
                        '<div>' +
                        input +
                        '</div>';
                }
                return input;
            }

            /*====================================================
             Required Inputs
             - inputs with the red asterix and validation error message
             ====================================================*/
            this.assembleRequiredInput = function assembleRequiredInput(label, input, input_type, error_message, exists_error_message) {
                if(input_type == 'text') {
                    var required_input = '' +
                        '<div class="block-label required_field" translate>' + label + '</div>' +
                        '<div class="required-block">' +
                        input +
                        '<span class="control-error-message dn for-' + input_type + '">' +
                        '<span>' + error_message + '</span>' +
                        '</span>' +
                        '<span class="control-error-message for-' + input_type + '" ng-show="checkExists">' +
                        '<span>' + exists_error_message + '</span>' +
                        '</span>' +
                        '</div>';
                }
                else {
                    var required_input = '' +
                        '<div class="block-label required_field" translate>' + label + '</div>' +
                        '<div class="required-block">' +
                        input +
                        '<span class="control-error-message dn for-' + input_type + '">' +
                        '<span>' + error_message + '</span>' +
                        '</span>' +
                        '</div>';
                }
                return required_input;
            }
            /*====================================================
             Number Inputs
             - inputs and validation error message
             ====================================================*/
            this.assembleNumberInput = function assembleNumberInput(label, input, input_type, error_message, hasPermission, access) {
                if(hasPermission && access){
                    var required_input = '<div class="block-label" translate has-permission = "' + hasPermission + ',' + access +'">' + label + '</div>' +
                        '<div class="required-block" has-permission="'+ hasPermission + ',' + access +'">' + input +
                        '<span class="control-error-message dn for-' + input_type + '" style="top:20px">' +
                        '<span>' + error_message + '</span>' +
                        '</span>' +
                        '</div>';
                }else{
                    var required_input = '<div class="block-label" translate>' + label + '</div>' +
                        '<div class="required-block">' +
                        input +
                        '<span class="control-error-message dn for-' + input_type + '" style="top:20px">' +
                        '<span>' + error_message + '</span>' +
                        '</span>' +
                        '</div>';
                }
                return required_input;
            }
            /*====================================================
             Checkbox Group
             - assemble the checkboxes and the group header
             ====================================================*/
            this.assembleCheckboxCollection = function assembleCheckboxCollection(collection_header, checkboxes) {
                var checkbox_collection = '<div class="checkbox-collection">' +
                    '<strong>' + collection_header + '</strong>' +
                    '<span class="checkboxes">' +
                    checkboxes +
                    '</span>' +
                    '</div>';
                return checkbox_collection;
            }
            /*====================================================
             Email Inputs
             - inputs and validation error message
             ====================================================*/
            this.assembleEmailInput = function assembleEmailInput(label, input, input_type, error_message) {
                var required_input = '<div class="block-label" translate>' + label + '</div>' +
                    '<div class="required-block">' +
                    input +
                    '<span class="control-error-message dn for-' + input_type + '">' +
                    '<span>' + error_message + '</span>' +
                    '</span>' +
                    '</div>';
                return required_input;
            }
            /*====================================================
             From Inputs
             - inputs and validation error message
             ====================================================*/
            this.assembleFromInput = function assembleFromInput(label, input, input_type, error_message) {
                var required_input = '<div class="block-label" translate>' + label + '</div>' +
                    '<div class="required-block">' +
                    input +
                    '<span class="control-error-message dn for-' + input_type + '">' +
                    '<span>' + error_message + '</span>' +
                    '</span>' +
                    '</div>';
                return required_input;
            }

            // insert the inputs and their labels
            this.handleFormElms();
            scope.form_is_valid = panelValidationHelper.isFormValid();
        }

        scope.handleSimpleSelect = function handleSelect(input_tpl, model_name, data) {
            scope[model_name + '_options'] = data;
            input_tpl = input_tpl.replace('{{repeater_options}}', model_name + '_options');
            if(data.length != 0 && model_name == 'title'){
                input_tpl = input_tpl.replace(/{{defaultId}}/g, data[0].id);
            }
            return input_tpl;
        }

        scope.handleSmartFilterDynamic = function handleSmartFilterDynamic(input_tpl, model_name, queryUrl) {
            scope['select2Options_' + model_name] = {
                minimumInputLength: 2,
                placeholder: 'select...',
                ajax: {
                    url: function() {
                        var url = $interpolate(prefix + queryUrl)(scope);
                        return url;
                    },
                    data: function (term, page) {
                        return { 'q': term };
                    },
                    results: function (data, page) {
                        return { results: data };
                    }
                }
            }
            return input_tpl;
        }

        scope.handleSmartFilter = function handleSmartFilter(input_tpl, model_name, data) {
            scope[model_name + '_options'] = data;
            input_tpl = input_tpl.replace('{{repeater_options}}', model_name + '_options');
            return input_tpl;
        }

        scope.handleSmartFilterWithOptGroup = function handleSmartFilterWithOptGroup(input_tpl, model_name, data) {
            scope[model_name + '_options'] = data;
            input_tpl = input_tpl.replace('{{repeater_options}}', model_name + '_options');
            return input_tpl;
        }

        scope.handleTitleBarLink = function handleTitleBarLink() {
            var result = '';

            if (scope.is_title_bar_link) {
                result = "<a href=" + scope.title_bar_link + ">" + scope.title_bar_link_name + "</a>";
            }

            return result;
        }

        scope.handleDirElmState = function handleDirElmState() {
            // need to listen what the 'when to send' select is
            // as the color and tooltip of the element icon need
            // to be dynamic

            var elm_that_changes_state = document.getElementById(scope.elm_that_changes_state);
            var value = elm_that_changes_state.value;

            // Update tooltip:
            scope.$parent.tt_content = scope.tooltip_prefix + " " + value;

            // TODO modify this if feature needs to be reused
            // if the value is Immediately element should become green
            // else element should become orange
            //if (value === "Immediately") {
            //    element[0].classList.remove('orange-text');
            //    element[0].classList.add('green-text');
            //    scope.$parent.tt_content = "Sent " + value;
            //}else {
            //    element[0].classList.remove('green-text');
            //    element[0].classList.add('orange-text');
            //}
            var category = element[0].getAttribute("category");
            var diary_event_id = element[0].getAttribute("diary_event_id");
            if (category === 'email_engineer' || category === 'text_engineer') {
                $rootScope.$broadcast('diaryevents:engineer_confirmation_updated', value, diary_event_id);
            }
            if (category === 'diary_event_customer_confirmation') {
                $rootScope.$broadcast('diaryevents:customer_confirmation_updated', value, diary_event_id);
            }
        }

        $rootScope.$on('smarttable_and_sidepanel:filtervalues', function() {
            scope.resetModelValues();
        });

        scope.handleQuickAddPanel = function handleQuickAddPanel() {
            scope.insertPanel();
            scope.keyboardListeners();

            if (scope.just_postcode === "true" && attrs.postcodeValue.trim() != '') {
                scope.postcode = attrs.postcodeValue;
                scope.isInputPostcode(scope.postcode);
                scope.lookUpPostCode();
            }

            if (scope.panel_options.filterForm === "true") {
                scope.storeFilterModelValues();
            }
        }

        /*==========================================================================================
         Either save models or use model for filtering
         ==========================================================================================*/
        scope.handleFormSubmit = function handleFormSubmit() {
            scope.form_submitted = true;

            // If the user is searching for a postcode (only used if postcode is the only input on the form)

            if (scope.just_postcode === "true") {
                scope.lookUpPostCode();
            } else {
                if(scope.save_form) {
                    scope.saveFormData();
                } else {
                    scope.handleFilter();
                }
            }
        }

        /*==========================================================================================
         Handle saving models
         ==========================================================================================*/
        scope.saveFormData = function saveFormData() {
            scope.loading = true;
            scope.btn_text = 'Saving';
            scope.setModelValues();

            var filterData = {};
            if (scope.category == 'contact') {
                filterData = scope.input_models;
            } else {
                angular.forEach(scope.input_models, function (val, key) {
                    filterData[val.model_name] = val.value;
                });
            }

            // Raja: Not sure if there is another way to get the real category as each sidepanel seems holed up
            // in its own category.
            if (scope.category === 'new_saved_report') {
                filterData['basereport'] = 'reporting_' + scope.$parent.category + '_' + scope.$parent.subCategory;
                filterData['category'] = scope.$parent.category;
                filterData['permission'] = scope.$parent.secondTier.feature;
            }

            if(scope.category === 'part_kit')
            {
                filterData["part_status"] = scope.part_status;
            }
            if(scope.category ===  "pricing_item"){
                filterData["PI_includedMinutes"] = scope.PI_includedMinutes;
                filterData["PI_quantity"] = scope.PI_quantity;
                filterData["PI_total"] = scope.PI_total;
                filterData["PI_unitprice"] = scope.PI_unitprice;
                filterData["Tax_rate_id"] = scope.Tax_rate;
            }

            $http.post(prefix + scope.endpoint, "data=" + encodeURIComponent(angular.toJson(filterData)))
                .success(function(res) {
                    scope.removeBothElements();
                    $rootScope.$emit('panelwithform:form_save_data', res);
                })
                .error(function(res) {
                    scope.handleSaveError(res);
                });
        }

        /*==========================================================================================
         Handle error response from POST
         ==========================================================================================*/
        scope.handleSaveError = function handleSaveError(res) {
            scope.form_is_valid = false;
            var server_validation_failed = _.isObject(res.data);

            if(server_validation_failed) {
                panelValidationHelper.handleFailedServerValidations(res.data);
            }else {
                scope.top_error_message = $rootScope.error_messages.fatal_error;
                scope.handleInternalError();
            }

            scope.loading = false;
            scope.btn_text = 'Save';
        }

        /*==========================================================================================
         Handle 500 error without failed server validations
         ==========================================================================================*/
        scope.handleInternalError = function handleInternalError() {
            scope.fatal_error = true;
        }

        scope.resetModelValues = function resetModelValues() {
            for(var i = 0, l = scope.input_models.length; i < l; i++) {
                var model = scope.input_models[i];
                scope[model.model_name] = (model.input_type === 'select' || model.input_type === 'smart_filter_with_optgroup') ? 'prompt' : '';
            }

            scope.input_models = [];
        }

        /*==========================================================================================
         Store the original filter values for later use.
         These values are re-applied when the user cancels the panel without saving it.
         ==========================================================================================*/
        scope.storeFilterModelValues = function storeFilterModelValues() {
            scope.originalFilterValues = this.input_models.map(function(model, i) {
                return {
                    model_name: model.model_name,
                    value: scope[model.model_name]
                }
            })
        }

        /*==========================================================================================
         When the filter form is closed (without saving), reset the values to what they were originally
         ==========================================================================================*/
        scope.resetFilterModelValues = function resetFilterModelValues() {
            this.originalFilterValues.map(function(original_value, i) {
                scope[original_value.model_name] = original_value.value
            })
        }

        /*==========================================================================================
         Handle using input models for filtering
         ==========================================================================================*/
        scope.handleFilter = function handleFilter(extra_data) {
            scope.setModelValues();
            scope.broadcastFilter(extra_data);
        }

        /*==========================================================================================
         Broadcast filter obj if filters are valid
         ==========================================================================================*/
        scope.broadcastFilter = function broadcastFilter(extra_data) {
            var sanitized_models = scope.extractValidFilters(),
                filter_valid = sanitized_models.length > 0;

            // if(filter_valid || extra_data) {
            if (extra_data) {
                if (angular.isArray(extra_data)) {
                    angular.forEach(extra_data, function(val,key) {
                        sanitized_models.push(val);
                    });
                } else {
                    sanitized_models.push(extra_data);
                }
            }

            scope.form_submitted = false;
            scope.form_is_valid = true;
            scope.btn_text = 'Filtering';
            scope.loading = true;
            $rootScope.$broadcast('status:toOperationalList', sanitized_models);
            $rootScope.$broadcast('panel_with_form:filter:' + scope.category, sanitized_models);
            scope.removeBothElements();
            // } else {
            //     scope.form_submitted = true;
            //     scope.btn_text = 'Filter';
            //     scope.top_error_message = $rootScope.error_messages.no_filters_present;
            //     scope.handleInternalError();
            // }
        }

        /*==========================================================================================
         Omit invalid filters from input_models
         ==========================================================================================*/
        scope.extractValidFilters = function extractValidFilters() {
            var filter_models = [];

            for(var i = 0, l = scope.input_models.length; i < l; i++) {
                var filter_defined = scope.input_models[i].value !== undefined,
                    filter_valid;

                if(filter_defined) {
                    if(scope.input_models[i].input_type == 'date'){
                        filter_valid = true;
                    }else{
                        filter_valid = scope.input_models[i].value.length > 0 ||
                            scope.input_models[i].value === true;
                    }
                }else {
                    filter_valid = false;
                }

                if(filter_valid) {
                    filter_models.push(scope.input_models[i]);
                }
            }

            return filter_models;
        }

        $rootScope.$on('datepicker_selected', function(event, message){
            scope[message.modelName] = (typeof message.date != 'undefined' && message.date !== '') ? moment(message.date).format('YYYY-MM-DD') : message.date;
        });

        /*==========================================================================================
         Get the model values
         ==========================================================================================*/
        scope.setModelValues = function setModelValues() {
            for(var i = 0, l = scope.input_models.length; i < l; i++) {
                var model = scope.input_models[i],
                    model_name = model.model_name,
                    model_value,
                    date_input = model.input_type === 'date',
                    select_input = model.input_type === 'select',
                    titleSelect_input = model.input_type === 'titleSelect',
                    smart_filter_with_optgroup = model.input_type === 'smart_filter_with_optgroup',
                    smart_filter_dynamic = model.input_type === 'smart_filter_dynamic';

                if (date_input) {
                    model_value = scope[model.model_name];
                } else if (select_input || smart_filter_with_optgroup || titleSelect_input) {
                    var select_prompt = scope[model.model_name] === 'prompt';
                    select_prompt ? model_value = '' : model_value = scope[model.model_name];
                } else if (smart_filter_dynamic) {
                    var value = scope[model.model_name];
                    if (value)
                        model_value = '' + value.id; // Format is id: x, text: xx
                    else
                        continue;   // Loop through if there is no value setup yet.
                }else {
                    model_value = scope[model.model_name];
                }
                model.value = model_value;
            }
        }

        /*==========================================================================================
         Store the input type and its model name in an object for each form elm.
         Only Store if the models are not already in storeInputModels
         ==========================================================================================*/
        scope.storeInputModels = function storeInputModels(input_type, model_name) {
            if (! _.some(scope.input_models, function(model) {
                    return (model.model_name === model_name);
                })) {
                scope.input_models.push({
                    input_type : input_type,
                    model_name : model_name
                });
            }
        }

        /*==========================================================================================
         Reset the sales report more filters
         ==========================================================================================*/
        scope.resetSalesReportMoreFilter = function resetSalesReportMoreFilter(model) {
            if(model == 'Type') {
                scope[model] = 'type';
            } else {
                scope[model] = 'prompt';
            }
            var paidFilters = 'notpaid,partiallypaid,fullypaid,notallocated,partiallyallocated,fullyallocated,customerType',
                array = paidFilters.split(',');

            for(var i = 0; i < array.length; i++) {
                var filterName = array[i];
                scope[filterName] = false;
            }
            if(model == 'fuel_type' || model == 'appliance_make' || model == 'appliance_group') {
                var validation_node = _.find(scope.input_templates, function (input_template) {
                    return input_template.model === model;
                });
                panelValidationHelper.handleInvalidInputCheck(validation_node.type, validation_node.model, event, scope);
                scope.form_is_valid = panelValidationHelper.isFormValid();
            }
        }

        /*==========================================================================================
         Postcode specific stuff
         ==========================================================================================*/
        scope.slideInView = function slideInView(slide) {
            scope.active_slide = slide;
        }

        scope.invalid_postcode = true;

        scope.lookUpPostCode = function lookUpPostCode() {
            scope.active_slide = 2;
        }

        scope.isInputPostcode = function isInputPostcode(postcode) {
            var postcode = postcode || this.postcode;
            scope.invalid_postcode = postCodeHelper.validatePostcode(postcode);
        }

        scope.newValueCustomer = function newValueCustomer() {
            scope.property = '';
            scope.customerType = '';
            scope.value = '';
        }

        scope.useAddress = function useAddress() {
            $rootScope.$broadcast('smartpostcode:use_address');
        }

        scope.hideTitleAndActions = function hideTitleAndActions(showing) {
            scope.hide_title_and_actions = showing;
        }

        $rootScope.$on('smartpostcode:loading', function(e, loading) {
            scope.hideTitleAndActions(loading);
        })

        $rootScope.$on('smartpostcode:address', function(e, address) {
            scope.active_slide = 1;
            scope.postcode = address.postcode;
            scope.addrs1 = address.addrs1;
            scope.addrs2 = address.addrs2;
            scope.addrs3 = address.addrs3;
            scope.town = address.town;
            scope.county = address.county;

            // Close the panel if the panel only contains a postcode input
            if (scope.just_postcode === "true") {
                scope.removeBothElements();
            };
        });

        // After the address list has arrived (or upon no results)
        $rootScope.$on('smartpostcode:addresses_list_arrived', function(e, addresses) {
            if (addresses.length > 0) {
                scope.show_choose_address_button = true;
            } else {
                scope.show_choose_address_button = false;
            }
        })

        /*==========================================================================================
         Validations
         ==========================================================================================*/
        scope.handleValidation = function handleValidation(input_type, model_ref, input_label, error_message, e) {
            panelValidationHelper.validateInput(input_type, model_ref, input_label, error_message, e)
                .then(function(res) {
                    panelValidationHelper.handleValidInput(res.input_type, res.model_ref, res.event, scope);
                    if(scope.category != "part_kit")
                    {
                        scope.checkExistsCall(res);
                    }
                }, function(res) {
                    if(scope.category != 'new_part' || (scope.category == 'new_part' && scope.description == "")) {
                        scope.checkExists = false;
                    }
                    panelValidationHelper.handleInvalidInput(res.input_type, res.model_ref, res.event, scope);
                })
                .then(function() {
                    scope.form_is_valid = panelValidationHelper.isFormValid();
                });
        }
        scope.checkExistsCall = function checkExistsCall(res)
        {
            var validation_node = _.find(scope.input_templates, function(input_template) {
                return input_template.model === res.model_ref;
            });

            if(scope.category == 'new_appliance_model') {
                var validation_node_appliance_model = _.find(scope.input_templates, function(input_template) {
                    return input_template.model === 'appliance_model';
                });
                if(validation_node_appliance_model.check_exists) {
                    var additional_params = validation_node_appliance_model.additional_params;
                    additional_params = additional_params.replace(/{{typesId}}/g, scope.appliance_group);
                    additional_params = additional_params.replace(/{{fuelTypesId}}/g, scope.fuel_type);
                    additional_params = additional_params.replace(/{{makesId}}/g, scope.appliance_make);
                    var dynamicUrl = validation_node_appliance_model.check_exists_url + scope['appliance_model'] + '&' + additional_params;

                    var url = $interpolate(prefix + dynamicUrl)(scope);

                    scope.getCheckExists(res, url);
                }
            }

            if(validation_node.check_exists && scope.category != 'new_appliance_model') {

                if(res.input_type == 'text'){
                    var valueForDescription =encodeURIComponent(scope[res.model_ref]);
                    var dynamicUrl = validation_node.check_exists_url + valueForDescription;
                }else{
                    var dynamicUrl = validation_node.check_exists_url + scope[res.model_ref];
                }


                var url = $interpolate(prefix + dynamicUrl)(scope);

                scope.getCheckExists(res, url);
            }
        }
        scope.getCheckExists = function getCheckExists(res, url) {
            $http.get(url).success(function (data) {
                if (data == 'true') {
                    scope.checkExists = data;
                    panelValidationHelper.handleInvalidInputCheck(res.input_type, res.model_ref, res.event, scope);
                    scope.form_is_valid = panelValidationHelper.isFormValid();
                }
                else {
                    scope.checkExists = false;
                    if(scope.category == 'new_appliance_model') {
                        if (scope.appliance_group != 'prompt'
                            && scope.fuel_type != 'prompt'
                            && scope.appliance_make != 'prompt'
                            && (scope.appliance_model != '' && scope.appliance_model != undefined)) {
                            scope.form_is_valid = true;
                        }
                    }
                }
            });
        }
        /*==========================================================================================
         When date is selected using dropdown, need to remove the model_ref from invalid_input_refs
         ==========================================================================================*/
        $rootScope.$on('datepicker:datepicker_selected', function(e, res) {
            var date_exisits = res.date !== undefined;

            if(date_exisits) {
                res.element[0].parentElement.classList.remove('with-error');
                var model_ref = scope.extractDateModelRef(res.element);
                panelValidationHelper.removeDateInputModelFromInvalidInputRefs(model_ref);
                scope.form_is_valid = panelValidationHelper.isFormValid();
                scope.$apply();
            }
        });

        /*==========================================================================================
         Get the model name from the date input
         ==========================================================================================*/
        scope.extractDateModelRef = function extractDateModelRef(element) {
            var date_input = element[0].querySelector('#datepicker-input'),
                model_ref = date_input.getAttribute('ng-model');

            return model_ref;
        }

        $rootScope.$on('panel_with_form:form_validity_changed', function() {
            scope.form_is_valid = panelValidationHelper.isFormValid();
        });

        /*==========================================================================================
         Builds the panel element and insert it into the DOM, the panel is hidden in the CSS so that
         it can be animated with a CSS transition with the showPanel function.
         ==========================================================================================*/
        var newScope = scope.$new();
        scope.insertPanel = function insertPanel() {
            var constructAndInsertPanel = (function() {
                var panel_wrap = document.createElement('div');
                panel_wrap.id = 'side-panel';
                panel_wrap.className = 'panel-with-form';
                panel_wrap.innerHTML = scope.quick_add_elm;
                newScope = scope.$new();
                var scopeToCompile = scope;
                if(scope.category == 'postcode') {
                    scopeToCompile = newScope;
                }
                panel_wrap = $compile(panel_wrap)(scopeToCompile);
                document.getElementsByTagName('body')[0].appendChild(panel_wrap[0]);
                scope.insertPageOverlay();
            });
            new constructAndInsertPanel();


            // Show the panel element.
            var showPanel = (function() {
                var panel = document.querySelector('#side-panel.panel-with-form');

                // Add the CSS class which animates the panel
                $timeout(function() {
                    panel.classList.add('in-view');
                }, 10);

                // Only focus the first input if the browser isn't ie
                if(navigator.appVersion.indexOf('Win') > -1) {
                    return;
                }else {
                    // Focus the first input in the panel
                    $timeout(function() {
                        var form = panel.getElementsByTagName('form');
                        form[0].elements[0].focus();
                    }, 200);
                }
            });
            new showPanel();
        }

        // remove the panel from the DOM
        scope.removePanel = function removePanel() {
            document.getElementById('quick-add-panel').remove();
        }

        scope.insertPageOverlay = function insertPageOverlay() {

            /*====================================================
             Builds the overlay element and insert it into the DOM,
             the overlay is hidden in the CSS so that it can be
             animated with a CSS transition
             ====================================================*/
            var constructAndInsertOverlay = (function(){
                this.insertElm = function insertElm() {
                    var wrapper = document.createElement('div');
                    wrapper.id = 'page-overlay';
                    document.getElementsByTagName('body')[0].appendChild(wrapper);
                }

                if(document.getElementById('page-overlay') === null) {
                    this.insertElm();
                }else {
                    // remove the existing one before inserting the new one
                    document.getElementById('page-overlay').remove();
                    this.insertElm();
                }
            });
            new constructAndInsertOverlay();

            /*====================================================
             Show the overlay element and attach click event listeners
             to it.
             ====================================================*/
            var showAndAttachEvts = (function() {
                this.handleElm = function handleElm() {
                    var overlay = document.querySelectorAll('#page-overlay');

                    $timeout(function() {
                        var overlay = document.getElementById('page-overlay');
                        overlay.classList.add('in-view');
                    }, 10);

                    for (var i = 0; i < overlay.length; i++) {
                        overlay[i].addEventListener('click', scope.handleOverlayClicked);
                    }
                }

                this.handleElm();
            });
            new showAndAttachEvts();
        }

        scope.handleOverlayClicked = function() {
            if (scope.panel_options.filterForm === "true") {
                scope.resetFilterModelValues();
            }
            scope.removeBothElements();
        }
        scope.handleClosePanelLinkClicked = function() {
            if (scope.panel_options.filterForm === "true") {
                scope.resetFilterModelValues();
            }
            scope.removeBothElements();
        }

        scope.cancelSidePanel = function cancelSidePanel() {
            scope.form_is_valid = (scope.category === "part_kit")? scope.form_is_valid : false; // COM-11967 only fix in part kit side panel
            if (scope.panel_options.filterForm === "true") {
                scope.resetFilterModelValues();
            }
            scope.removeBothElements();
            confirmationBoxHelper.hideConfirmationBox();
        }

        /*==========================================================================================
         Removes both the panel and the overlay elements from the DOM
         ==========================================================================================*/
        scope.removeBothElements = function removeBothElements(result) {
            if(scope.form_submitted && scope.form_is_valid) {
                var panel = document.querySelector('#side-panel.panel-with-form'),
                    overlay = document.getElementById('page-overlay');

                if(scope.elm_supports_state) {
                    scope.handleDirElmState();
                }

                panel.classList && panel.classList.remove('in-view');
                overlay.classList && overlay.classList.remove('in-view');

                $timeout(function() {
                    overlay && overlay.parentNode.removeChild(overlay);
                    panel && panel.parentNode.removeChild(panel);
                }, 200);

                $rootScope.$broadcast('quick_add_panel:closed', result);
                $document.unbind('keydown.panelWithForm');
                $document.unbind('keydown.sidepanelShortcuts');
                panelValidationHelper.emptyInvalidInputRefs();

                if (scope.save_form) {
                    scope.resetModelValues();
                }

                scope.loading = false;
                scope.fatal_error = false;
                scope.checkExists = false;
            } else {
                // Raja: Ugly! to have to exclude category, TODO: Investigate
                if (scope.save_form && scope.category != 'new_saved_report') {
                    confirmationBoxHelper.getConfirmation($rootScope.error_messages.unsaved_sidepanel, scope)
                        .then(function() {
                            scope.form_is_valid = true;
                            scope.form_submitted = true;
                            $document.unbind('keydown.panelWithForm');
                            $document.unbind('keydown.sidepanelShortcuts');
                            scope.removeBothElements();
                        });
                } else {
                    var panel = document.querySelector('#side-panel.panel-with-form'),
                        overlay = document.getElementById('page-overlay');

                    panel && panel.classList.remove('in-view');
                    overlay && overlay.classList.remove('in-view');

                    $timeout(function() {
                        overlay && overlay.parentNode.removeChild(overlay);
                        panel && panel.parentNode.removeChild(panel);
                    }, 200);
                    $document.unbind('keydown.panelWithForm');
                    $document.unbind('keydown.sidepanelShortcuts');
                    scope.loading = false;
                    scope.fatal_error = false;
                    scope.checkExists = false;
                    newScope.$destroy();
                }
            }
        }


        /*====================================================
         Events
         - when a quickadd directive link is clicked invoke the directive
         ====================================================*/
        var registerEventListeners = (function() {
            element[0].addEventListener('click', scope.handleQuickAdd);
        })();

        /*====================================================
         Keyboard Shortcuts
         - 'esc' key removes the panel & overlay
         - needs to be 'keydown' when listening for esc
         ====================================================*/
        scope.keyboardListeners = function keyboardListeners() {
            $document.bind('keydown.panelWithForm', function(evt) {
                var key = evt.keyCode,
                    esc = key == 27;

                if(esc) {
                    if (scope.panel_options.filterForm === "true") {
                        scope.resetFilterModelValues();
                    }
                    scope.removeBothElements();
                }
            });
        }
        scope.validateQuantity = function validateQuantity(quantity,mins) {
            var input_is_number = !isNaN(parseFloat(quantity));
            var IncMin = !isNaN(parseFloat(mins));
            scope.PI_total = (scope.PI_unitprice * quantity).toFixed(2);
            if(input_is_number && IncMin && ((quantity !== null || quantity > 0 || quantity != 0))) {
                scope.form_is_valid = true;
                scope.error = false;
            }else{
                scope.form_is_valid = false;
                scope.error = true;
            }
        }
        scope.validateIncMin = function validateIncMin(quantity,mins){
            var input_is_number = !isNaN(parseFloat(quantity));
            var IncMin = !isNaN(parseFloat(mins));
            if(IncMin && ((quantity !== null || quantity > 0 || quantity != 0) && IncMin) && input_is_number){
                scope.form_is_valid = true;
                scope.incError = false;
            }else{
                scope.form_is_valid = false;
                scope.incError = true;
            }
        }


        /*scope.saveAction = function(extraVars){
            var fields = formPanelCollection.getFieldCollection(scope.category);
            var scopeVars = this;
            var formVals = '';
            for(var i = 0; i < fields.length; i++) {
                if (scopeVars[fields[i].modelName] == undefined || scopeVars[fields[i].modelName] == '') {
                    scopeVars[fields[i].modelName] = ''
                }
                formVals +=   '"'+fields[i].modelName+'"' +':'+ '"' + scopeVars[fields[i].modelName] + '"';
                if (i + 1 != fields.length) {
                    formVals += ',';
                }
            }

            scope.$emit('tosmarttable:filtervalues', '{'+ formVals +'}');
        }

        */

        // This lives for the only reason to send sidepanel data back to controller, so the
        // results can be aggregated and sent to backend.
        scope.$on('tosidepanel:filtervalues', function(evt, data) {
            scope.handleFilter(data);
        });
    }

    return {
        restrict: 'A',
        scope: {},
        link: setupPanel
    }
});
