csmodule.controller('NotificationViewCtrl', [
  'prefix',
  '$scope',
  '$http',
  'panelData',
  '$modalInstance',
  '$timeout',
  function(prefix, $scope, $http, panelData, $modalInstance, $timeout) {
    $scope.sidepanelData = panelData.data;
    $scope.category = panelData.category;
    $scope.listingData = [];

    var $filterKey = ['active',
      'id',
      '$$hashKey',
      'complete_message',
      'html5_notification',
      'notification_html',
      'notification_history',
      'job_details',
      'customer_data',
      'diary_history',
      'attached_files',
      'Description',
      'actions_buttons',
      'view_user',
      'notificationId',
      'Date',
      'Time',
      'Property',
      'ScreenType',
      'Pid',
      'headerTitles',
      'PropertyID',
      'Estimate',
      'Job',
      'Link',
      'isProject',
      'CustomerType',
      'JobId',
      'InvoiceId',
      'Status',
      'smsModule',
      'Title',
      'SupplierId',
      'InvoiceId',
      'WorkAddress',
      'misMatchDetails',
      'supplierId',
      'supplierInvoiceNumber',
      'deleted',
      'supplierInvoiceId',
      'resultId',
      'SMS'];
    updateAuditNotification();

    $scope.formatedData = function(data) {
      var newData = [];
      _.forEach(Object.keys(data), function(key){
        if( !_.contains($filterKey, key) ) {
          newData.push(key);
        }
        if( _.has($scope.sidepanelData, 'smsModule') && $scope.sidepanelData['smsModule'] !== 0 && key === 'SMS') {
          newData.push(key);
        }
      });
      return newData;
    };

    $scope.completeNotification = function completeNotification(entryId){
      $http.post(prefix + '/completeNotification', 'notificationId=' + entryId).success(function (data) {
        $scope.addToNotificationHistory(entryId, 'completed');
        //$modalInstance.close($scope.sidepanelData);
      });

    };

    $scope.addToNotificationHistory = function addToNotificationHistory(entryId, action) {
      $http.post(prefix + '/auditNotificationView', 'notificationId=' + $scope.sidepanelData.notificationId + '&status=' + action).
      success(function(data) {
        $scope.sidepanelData.notification_history.unshift(data);
      });
      $modalInstance.close($scope.sidepanelData);
    };

    function updateAuditNotification() {
      if($scope.category === 'notifications' || $scope.category === 'notificationsTopMenu') {
        if(!$scope.sidepanelData.notificationId) {
          return;
        }
        $http.post(prefix + '/auditNotificationView', 'notificationId=' + $scope.sidepanelData.notificationId)
          .success(function (data) {
            $scope.sidepanelData.notification_history.unshift(data);
            $scope.listingData = $scope.formatedData($scope.sidepanelData);
            console.log('$scope.listingData ', $scope.listingData )
        });
      }
    }

    $modalInstance.opened.then(function(isOpened) {
      if(isOpened) {
        $timeout(function(){
          $('body').find('.modal-backdrop').on('click',function(e){
            $modalInstance.dismiss();
          });
        },300);
      }
    });
    $scope.$on('$destroy', function(){
      $('body').find('.modal-backdrop').off('click');
    });
  }
]);