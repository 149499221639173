csmodule.service('outstandingJobsService', function ($rootScope, $http, $q) {
    var $this = this;
    $this.outstandingJobs = [];
    $this.totalOutstandingJobs = 0;
    $this.previousOutstandingJobs = [];
    $this.loadedMaximum = false;

    $this.search_text = '';
    $this.start_time = null;
    $this.end_time = null;
    $this.filterParams = {};
    $this.filterParamsDefault = {
        recordType: null,
        no_access: true,
        parts_ready: true,
        without_events: true,
        with_todos: true,
        page: null,
        search_text: '',
        start_time: null,
        end_time: null,
        dueWindowCounts: false
    };

    $this.action = '';
    $this.countsAction = '';
    $this.searching = false;

    var outstandingJobObservable = null, outstandingJobsCountObservable = null;

    $this.setOutstandingQueryParams = function (filters) {

        filters = (filters instanceof Object) ?
            Object.assign({}, $this.filterParamsDefault, filters) :
            angular.copy($this.filterParamsDefault);

        // Set page
        if (!filters.page) filters.page = 1;

        // Set page limit
        if (!filters.limit) filters.limit = $this.pageLimit;

        // Set record type
        if (!filters['recordType']) delete filters['recordType'];

        return filters;
    };

    // Build query params
    $this.getOutstandingQueryParams = function (filters) {
        var filterParams = $this.setOutstandingQueryParams(filters);

        return Object.keys(filterParams).reduce(function (prev, key) {
            if (!filterParams[key] && filterParams[key] !== 0) return prev;

            prev += encodeURIComponent(key) + '=' + encodeURIComponent(filterParams[key]) + '&';
            return prev;
        }, '');

    };

    // Fetching Outstanding jobs
    $this.getOutstandingJobs = function (filterParams) {
        if (outstandingJobObservable) {
            outstandingJobObservable.resolve();
        }

        outstandingJobObservable = $q.defer();

        if (!$rootScope.hasPermission(features['Jobs'], 'readaccess')) {
            var message = 'You don\'t have access to view jobs';
            console.log(message);

            setTimeout(function () {
                outstandingJobObservable.reject(message);
            }, 100);

            return outstandingJobObservable.promise;
        }

        $this.action = 'LOADING';

        filterParams = $this.setOutstandingQueryParams(filterParams);
        if (!filterParams.no_access && !filterParams.parts_ready && !filterParams.without_events && !filterParams.with_todos) {
            setTimeout(function () {
                outstandingJobObservable.resolve({
                    outstandingJobs: [],
                    totalOutstandingJobs: 0,
                    page: 1
                })
            }, 100);

            return outstandingJobObservable.promise;
        }


        $http.get(
            Routing.generate('get_outstanding_jobs') + '?' + $this.getOutstandingQueryParams(filterParams),
            {timeout: outstandingJobObservable.promise}
        ).then(function (response) {

            $this.action = 'LOADED';
            outstandingJobObservable.resolve(response.data);

        }).catch(function (error) {
            console.log(error);
            $this.action = 'FAILED_TO_LOAD';
            outstandingJobObservable.reject(error);
        });

        return outstandingJobObservable.promise;
    };

    // Fetching total outstanding job/estimate counts
    $this.getOutstandingJobsCount = function (filters) {
        if (outstandingJobsCountObservable) {
            outstandingJobsCountObservable.resolve();
        }
        outstandingJobsCountObservable = $q.defer();

        if (!$rootScope.hasPermission(features['Jobs'], 'readaccess')) {
            var message = 'You don\'t have access to view jobs';
            console.log(message);

            setTimeout(function () {
                outstandingJobsCountObservable.reject(message);
            }, 100);

            return outstandingJobsCountObservable.promise;
        }


        $this.countsAction = 'LOADING_COUNT';
        $http.get(
            Routing.generate('get_outstanding_jobs_count') + '?' + $this.getOutstandingQueryParams(filters),
            {timeout: outstandingJobsCountObservable.promise}
        ).then(function (response) {
            var data = response.data;

            $this.countsAction = 'LOADED_COUNT';
            outstandingJobsCountObservable.resolve(data.count);

        }).catch(function (error) {
            console.log(error);
            $this.countsAction = 'FAILED_TO_LOAD_COUNT';
            outstandingJobsCountObservable.reject(error);
        });


        return outstandingJobsCountObservable.promise;
    };

    var debounceTimer;
    $this.searchOutstandingJobs = function (search_text, filterParams) {

        if (debounceTimer) {
            if (outstandingJobObservable) outstandingJobObservable.resolve();
            debounceTimer = clearTimeout(debounceTimer);
        }

        filterParams.search_text = search_text;

        var deferred = $q.defer();
        debounceTimer = setTimeout(function () {

            $this.getOutstandingJobs(filterParams).then(function (data) {
                debounceTimer = null;
                deferred.resolve(data);
            }).catch(function (error) {
                debounceTimer = null;
                deferred.reject(error);
            });
        }, 500);

        return deferred.promise;
    };

    // Fetching due window jobs
    var dueWindowJobsObservable;
    $this.getDueWindowJobs = function (filterParams) {
        if (dueWindowJobsObservable) {
            dueWindowJobsObservable.resolve();
        }

        dueWindowJobsObservable = $q.defer();

        if (!$rootScope.hasPermission(features['Jobs'], 'readaccess')) {
            var message = 'You don\'t have access to view jobs';
            console.log(message);

            setTimeout(function () {
                dueWindowJobsObservable.reject(message);
            }, 100);

            return dueWindowJobsObservable.promise;
        }

        $this.action = 'LOADING';
        $this.previousOutstandingJobs = $this.outstandingJobs;


        $http.get(
            Routing.generate('get_due_window_jobs') + '?' + $this.getOutstandingQueryParams(filterParams),
            {timeout: dueWindowJobsObservable.promise}
        ).then(function (response) {
            var data = response.data;

            dueWindowJobsObservable.resolve({
                dueWindows: data.dueWindows,
                totalOutstandingJobs: data.totalOutstandingJobs
            });

        }).catch(function (error) {
            console.log(error);
            $this.action = 'FAILED_TO_LOAD';
            dueWindowJobsObservable.reject(error);
        });

        return dueWindowJobsObservable.promise;
    };

    var debounceTimer2;
    $this.searchDueWindowJobs = function (searchText, filterParams) {

        if (debounceTimer2) {
            if (dueWindowJobsObservable) dueWindowJobsObservable.reject('Cancelled');
            debounceTimer2 = clearTimeout(debounceTimer2);
        }

        var deferred = $q.defer();
        debounceTimer2 = setTimeout(function () {
            $this.getDueWindowJobs(Object.assign(filterParams, {search_text: searchText})).then(function (data) {
                deferred.resolve(data);
                debounceTimer2 = null;
            }).catch(function (error) {
                deferred.reject(error);
                debounceTimer2 = null;
            });
        }, 500);

        return deferred.promise;
    };

    // Variable value change listener
    $this.watch = function ($scope, property, onChangeCallback) {
        return $scope.$watch(function () {
            return $this[property];
        }, function (newVal, oldVal) {
            if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                onChangeCallback(newVal, oldVal);
            }
        });
    }
});