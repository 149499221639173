commusoftCommon.service('sidePanel', function($timeout, $rootScope, $document, confirmationBoxHelper, $translate) {
    /*====================================================
     Methods called from outside
     ====================================================*/
    this.open = function open(panel, remove_on_close, panel_identifier) {
        var panel_not_showing = (panel && panel.classList)?panel.classList.contains('in-view') ===  false:false;

        if(panel_not_showing) {
            this.panel = panel;
            this.overlay = document.createElement('div');
            this.overlay.id = 'page-overlay';
            this.directive_elm = document.querySelector('#' + panel_identifier);
            this.remove_on_close = remove_on_close;
            this.showPanel();
            this.showOverLay();
            //this.attachListeners();
            if(this.directive_elm) {
              var panelName = this.directive_elm.attributes.template.value;
              $rootScope.$emit('sidepanel:open', panelName);
            }

        }else {
            return;
        }
    }

    $rootScope.$on('closeAllSidepanels', function(){
        confirmationBoxHelper.hideConfirmationBox();
        var panel = document.querySelectorAll('#side-panel.in-view');
        for (var i = 0; i < panel.length; i++) {
            panel[i].classList.remove('in-view');
        }
        var overlay = document.querySelectorAll('#page-overlay.in-view');
        for (var i = 0; i < overlay.length; i++) {
            overlay[i].classList.remove('darker');
            overlay[i].classList.remove('in-view');
        }
        $timeout(function(){
            var panel = document.querySelectorAll('#send_statement');
            for (var i = 0; i < panel.length; i++) {
                panel[i].parentNode.removeChild(panel[i]);
            }
            var panel = document.querySelectorAll('#configure_sla_customer');
            for (var i = 0; i < panel.length; i++) {
                panel[i].parentNode.removeChild(panel[i]);
            }
            var panel = document.querySelectorAll('#view_job_report');
            for (var i = 0; i < panel.length; i++) {
                panel[i].parentNode.removeChild(panel[i]);
            }
            var panel = document.querySelectorAll('#view_print_invoice');
            for (var i = 0; i < panel.length; i++) {
                panel[i].parentNode.removeChild(panel[i]);
            }
            var panel = document.querySelectorAll('#view_pdf');
            for (var i = 0; i < panel.length; i++) {
                panel[i].parentNode.removeChild(panel[i]);
            }
            var panel = document.querySelectorAll('#side-panel');
            var static_panel = angular.element(panel).hasClass('static-side-panel');
            if(!static_panel)   // for customer credit note and invoice screens
            {
                for (var i = 0; i < panel.length; i++) {
                    panel[i].parentNode.removeChild(panel[i]);
                }
            }
            var overlay = document.querySelectorAll('#page-overlay');
            for (var i = 0; i < overlay.length; i++) {
                overlay[i].parentNode.removeChild(overlay[i]);
            }
        },600);
        $rootScope.$emit('sidepanel:closed');
    });

    this.close = function close(confirm) {
        if(this.panel.classList.contains("in-view")) {
            var self = this;
            if (confirm) {
                confirmationBoxHelper.getConfirmation($translate('Are.you.sure.you.want.to.close.side.panel?'), $rootScope).then(function() {
                    $rootScope.$emit('sidepanel:removecontent');
                    self.hidePanel();
                    self.hideOverlay();
                    if (self.directive_elm) {
                        var event_name = 'sidepanel:' + self.directive_elm.attributes.template.value + ':closed';
                        $rootScope.$emit(event_name);
                    }
                    $rootScope.$emit('parent:updated');
                });
            } else {
                confirmationBoxHelper.hideConfirmationBox();
                this.hidePanel();
                this.hideOverlay();

                if (self.directive_elm) {
                    var event_name = 'sidepanel:' + self.directive_elm.attributes.template.value + ':closed';
                    $rootScope.$emit(event_name);
                }
                $rootScope.$emit('parent:updated');
                $rootScope.$emit('sidepanel:closed');
            }
        }
    }

    /*====================================================
     Internal Methods
     ====================================================*/
    this.showPanel = function showPanel() {
        this.panel.classList.add('in-view');
    }

    this.showOverLay = function showOverLay() {
        var self = this;
        document.getElementsByTagName('body')[0].appendChild(this.overlay);

        $timeout(function() {
            self.overlay.classList.add('in-view');
        }, 100);

        this.overlay.addEventListener('click', function() {
            self.close(true);
        }, false);
    }

    this.attachListeners = function attachListeners(){
        // attach click listeners
        var overlay = document.querySelectorAll('#page-overlay');
        var save_link = document.querySelectorAll('#save-panel-btn');
        var cancel_link = document.querySelectorAll('#cancel-panel-btn');
        for (var i = 0; i < overlay.length; i++) {
            overlay[i].addEventListener('click', handleListenerClose);
        }
        for (var i = 0; i < save_link.length; i++) {
            save_link[i].addEventListener('click', handleListenerClose);
        }
        for (var i = 0; i < cancel_link.length; i++) {
            cancel_link[i].addEventListener('click', handleListenerClose);
        }
    }

    var handleListenerClose = function handleListenerClose(){
        this.removeConfirmationIfItExists();
        var should_close = true;
        // check if overlay contains darker class
        var overlay = document.querySelectorAll('#page-overlay.in-view');
        for (var i = 0; i < overlay.length; i++) {
            if (overlay[i].classList.contains('darker') === true){
                should_close = false;
            }
        }

        if (should_close === true) {
            var overlay = document.querySelectorAll('#page-overlay.in-view');
            for (var i = 0; i < overlay.length; i++) {
                overlay[i].classList.remove('in-view');
                overlay[i].parentNode.removeChild(overlay[i]);
            }
            var panel = document.querySelectorAll('#side-panel.in-view');
            for (var i = 0; i < panel.length; i++) {
                panel[i].classList.remove('in-view');
            }
        }
        $rootScope.$emit('sidepanel:closed');
    }

    this.hidePanel = function hidePanel() {
        this.panel.classList.remove('in-view');

        if(this.remove_on_close && this.panel.parentNode) {
            var self = this;

            $timeout(function() {
                let elementScope = angular.element(self.panel) ? angular.element(self.panel).scope() : false;
                if(elementScope) {
                    elementScope.$destroy();
                }
                if(self.directive_elm && self.directive_elm.parentNode) {
                    self.directive_elm.parentNode.removeChild(self.directive_elm);
                }
                if(self.panel.parentNode) {
                    self.panel.parentNode.removeChild(self.panel);
                }
            }, 200);
        }
    }

    this.hideOverlay = function hideOverlay() {
        var self = this;
        this.overlay.classList.remove('in-view');

        $timeout(function() {
            if(self.overlay.parentNode) {
                self.overlay.parentNode.removeChild(self.overlay);
            }
        }, 200);
    }

    this.focusInput = function focusInput(panel, selector) {
        $timeout(function() {
            var input = panel.querySelector(selector);
            input.focus();
        }, 100);
    }
});
