/*==========================================================================================
  Once the keyboard shortcuts have been implemented need to work out the best way to handle
  the column cell input types for when a cell is in edit / add mode.
==========================================================================================*/

csmodule.service('spreadSheetData', function($http) {
  /*==========================================================================================
    Getters
  ==========================================================================================*/
  this.getData = function getData(path) {
    var promise = $http.get(path)
                    .then(function(response) {
                      return response.data;
                    });
    return promise;
  }

  /*==========================================================================================
    Make a new row based on the category
  ==========================================================================================*/
  this.makeNewRow = function makeNewRow(spread_sheet_obj, category) {
    var new_row,
        associated_row = spread_sheet_obj[category].empty_row[0].row[0].associated_rows !== undefined,
        row_to_insert = spread_sheet_obj[category].empty_row;

    if(associated_row) {
      new_row = this.cloneAssociatedRow(row_to_insert);
    }else {
      new_row = this.cloneNormalRow(row_to_insert);
    }

    return new_row;
  }

  /*==========================================================================================
      Handle cloning rows
  ==========================================================================================*/
  this.cloneNormalRow = function cloneNormalRow(row) {
    var new_row = [],
        new_obj = {},
        row_obj = row[0];

    new_obj.id = _.clone(row_obj.id);
    new_obj.pos = _.clone(row_obj.pos);
    new_obj.row = [];
    new_obj.meta_data = {};

    for(var i = 0, l = row_obj.row.length; i < l; i++) {
      var new_cell = _.clone(row_obj.row[i]);
      new_obj.row.push(new_cell);
    }

    new_obj.meta_data = _.clone(row_obj.meta_data);

    new_row.push(new_obj);

    return new_row;
  }

  this.cloneAssociatedRow = function cloneAssociatedRow(row, spread_sheet_obj, category, selected_cell_value, existing_row_data) {
    // TODO: preserve_cell_value

    // var new_row = [
    //   {
    //     "associated_rows" : [],
    //     "meta_data" : []
    //   }
    // ];

    // for(var i = 0, l = row[0].associated_rows.length; i < l; i++) {
    //   var tmp_row = [],
    //       current_rows = row[0].associated_rows[i];

    //   for(var x = 0, s = current_rows.length; x < s; x++) {
    //     var new_cell = _.clone(current_rows[x]);

    //     if (category && selected_cell_value) {
    //       // check whether the row template cell has preserve data attribute
    //       var selected_cell_value = selected_cell_value,
    //           preserve_cell_value = spread_sheet_obj[category].row_templates[selected_cell_value][0].associated_rows[0][x].preserve_cell_value === true;

    //       if (preserve_cell_value) {
    //         var cell_value_to_replace = existing_row_data[0].associated_rows[0][x].value;
    //         new_cell.value = cell_value_to_replace;
    //       }
    //     }

    //     tmp_row.push(new_cell);
    //   }
    //   new_row[0].associated_rows.push(tmp_row);
    //   new_row[0].meta_data = row[0].meta_data;
    // }

    var new_row = [],
        new_obj = {},
        row_obj = row[0],
        row_has_meta_data = row_obj.meta_data !== undefined

    new_obj.id = _.clone(row_obj.id);
    new_obj.pos = _.clone(row_obj.pos);
    new_obj.row = [
      {
        "associated_rows" : []
      }
    ];

    if(row_has_meta_data) {
      var meta_data = {},
          clone_obj = _.extend(meta_data, row_obj.meta_data);

      new_obj.meta_data = clone_obj;
    }

    for(var i = 0, l = row_obj.row[0].associated_rows.length; i < l; i++) {
      var current_row = row_obj.row[0].associated_rows[i],
          tmp_row = [];

      for(var x = 0, s = current_row.length; x < s; x++) {
        var new_cell = _.clone(current_row[x]);
        tmp_row.push(new_cell);
      }

      new_obj.row[0].associated_rows.push(tmp_row);
    }

    new_row.push(new_obj);
    return new_row;
  }

  /*==========================================================================================
    Clone a array of cells (not a full row, but a child row being inserted into an existing
    row obj).
  ==========================================================================================*/
  this.cloneRowToInsert = function cloneRowToInsert(row) {
    var new_row = _.map(row, _.clone);
    return new_row;
  }

  /*==========================================================================================
    Crud operations
  ==========================================================================================*/

  this.create = function create(path, category, row) {
    return $http.post(path, "estimate[row]=" + encodeURIComponent(angular.toJson(row)));
  }

  this.createMergedRow = function createMergedRow(path, category, row, merged_row_ids) {
    var params = {};
    params.row = row;
    params.merged_row_ids = merged_row_ids;

    return $http.post(path, "estimate[row]=" + encodeURIComponent(angular.toJson(params)));
  }

  this.update = function update(path, category, row) {
    return $http.put(path + '/' + row.id, angular.toJson(row));
  }

  this.updateMergedRow = function updateMergedRow(path, category, un_merge_data) {
    var params = {};
    params.id = un_merge_data.id;
    params.row = un_merge_data.row;
    params.merged_row_ids = un_merge_data.merged_row_ids;

    return $http.put(path + '/' + un_merge_data.id, angular.toJson(params));
  }

  this.updateMilestoneName = function updateMilestoneName(directive_scope, milestone_name, path, milestoneId) {
    var milestoneName = {"milestoneName":milestone_name};
    return $http.put(path + '/' + milestoneId, milestoneName);
  }

  this.destroy = function destroy(path, category, id) {
    return $http.delete(path + '/' + id);
  }

  this.cloneRowToInsert = function cloneRowToInsert(row) {
    var new_row = _.map(row, _.clone);
    return new_row;
  }
});