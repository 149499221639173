function CSmassUpdateModalCtrl($scope, $http, prefix, $modalInstance, warningModal){
    $scope = $scope.$parent;    // bootstrap modal creates a new scope instance, get its parent
    $scope.confirmDeleteValidity = true;
    $scope.deletebutton = true;
    $scope.loading = false;
    $scope.closebutton = true;
    $scope.params = {};
    $scope.showMainContent = false;
    $scope.showAuthView = false;
    $scope.input={};
    $scope.input.confirmText = '';

    $scope.delete = function(userExists, passwordExists){
        $scope.massdeletearchive(userExists, passwordExists)
    }

    $scope.showDeleteButton = function(confirmText) {
        if (confirmText.toLowerCase() == $scope.textToConfirm.toLowerCase()) {
            $scope.confirmDeleteValidity = true;
            $scope.deletebutton = false;
        } else {
            $scope.confirmDeleteValidity = false;
            $scope.deletebutton = true;
        }
    }

    $scope.goBack = function() {
        this.$$childTail.selectedItem = '';
        this.$$childTail.input = {};
        $scope.showMainContent = false;
        $scope.showAuthView = false;
    };

    $scope.next = function (){
        $scope.showMainContent = true;
        $scope.showAuthView = true;
    }

    $scope.checkParams = function (){
        return !(_.contains($scope.params, true));
   }

    $scope.massdeletearchive = function(userExists, passwordExists) {
        if (($scope.input.confirmText.toLowerCase() === $scope.textToConfirm.toLowerCase()) && typeof($scope.input.selectedUsername) != 'undefined' && typeof($scope.input.selectedPassword) != 'undefined'
            && userExists === false && passwordExists === false) {
            $scope.confirmDeleteValidity = true;
            $scope.loading = true;
            $scope.closebutton = false;
            $scope.deletebutton = true;
            $scope.params.username = $scope.input.selectedUsername;
            $scope.params.password = $scope.input.selectedPassword;
            $scope.deleteParams = Object.keys($scope.params).map(function(key) {
                return key + '=' + encodeURIComponent($scope.params[key])
            }).join('&');

            $scope.deleteUrl = '/massDeleteArchive'
            $http.post(prefix + $scope.deleteUrl, $scope.deleteParams).
            success(function(data, status) {
                if (status == 200) {
                    $scope.loading = false;
                    $scope.closebutton = true;
                    $scope.deletebutton = false;
                    $scope.close();
                    warningModal.show('You will receive a notification once the mass delete/archive action is complete. This may take some time however you can close this window and continue to use Commusoft.', 'Mass delete / archive action', 'Mass_delete_archive');
                }
            })
        } else {
            if($scope.input.confirmText.toLowerCase() !== $scope.textToConfirm.toLowerCase())
            {
                $scope.confirmDeleteValidity = false;
            }
            $scope.loading = false;
        }
    }

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function() {
        $scope.input.confirmText = '';
        $scope.confirmDeleteValidity = true;
        $scope.$parent.shouldBeMassUpdateParts = false;
        $modalInstance.close();
    }

    window.onkeydown = function (evt) {
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }
}