commusoftCommon.directive('imageResize', ['prefix', '$timeout', function(prefix, $timeout) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            scope = scope.$parent.$parent;
            scope.refreshImageHeight = 0;
            var hasRequiredValues = (scope.imgOptions && scope.imgOptions.reqHeight) ? true : false;

            element.bind("load" , function(event){
                if(hasRequiredValues) {
                    scope.refresh = 0;
                    scope.updateSelection();
                } else {
                    $timeout(scope.setMaxHeightAndWidth, 10);
                }
            });

            setTimeout(function(){
                if(hasRequiredValues) {
                    scope.updateOptionValues();
                    scope.imgAreaSelect = element.imgAreaSelect({
                        aspectRatio: scope.options.ratio,
                        handles: true,
                        persistent: true,
                        fadeSpeed: 200,
                        onSelectChange: scope.preview,
                        x1: 0,
                        y1: 0,
                        x2: scope.endX,
                        y2: scope.endY,
                        instance: true
                    });
                } else {
                    scope.imgAreaSelect = element.imgAreaSelect({
                        handles: true,
                        onSelectChange: scope.setSelectionValues,
                        instance: true
                    });
                }
                angular.element('.imgareaselect-selection').parent().hide();
            },100);

            scope.updateSelection = function () {
                scope.updateOptionValues();
                scope.imgAreaSelect.setSelection(0, 0, scope.endX, scope.endY, true);
                scope.imgAreaSelect.update();
                scope.preview('', scope.imgAreaSelect.getSelection());
                if (scope.refresh <= 2) {
                    $timeout(scope.updateSelection, 250);
                    scope.refresh++;
                } else {
                    angular.element('.imgareaselect-outer').show();
                    angular.element('.imgareaselect-selection').parent().show();
                }
            }

            scope.updateOptionValues = function () {
                scope.setImageRatioValue();
                scope.options = [];
                var optionsArray = element.attr('options').split(',');
                scope.options['reqWidth'] = parseInt(optionsArray[0]);
                scope.options['reqHeight'] = parseInt(optionsArray[1]);
                scope.options['orgWidth'] = parseInt(optionsArray[2]);
                scope.options['orgHeight'] = parseInt(optionsArray[3]);
                scope.options['ratio'] = optionsArray[4];
                scope.startX = 0;
                scope.startY = 0;

                var ratioArray = scope.options.ratio.split(':');
                var orgWidth = scope.options.orgWidth / scope.imageRatio;
                var orgHeight = scope.options.orgHeight / scope.imageRatio;
                var optionsReqWidth = parseInt(scope.options.reqWidth);
                var optionsReqHeight = parseInt(scope.options.reqHeight);
                var count = 0;

                while (optionsReqWidth > orgWidth || optionsReqHeight > orgHeight) {
                    if((optionsReqWidth > orgWidth && optionsReqHeight > orgHeight) || optionsReqWidth > orgWidth) {
                        orgWidth = orgWidth - count * 10;
                        optionsReqWidth = orgWidth;
                        optionsReqHeight = (parseInt(optionsReqWidth) / ratioArray[0]) * ratioArray[1];
                    } else {
                        orgHeight = orgHeight - count * 10;
                        optionsReqHeight = orgHeight;
                        optionsReqWidth = (parseInt(optionsReqHeight) / ratioArray[1]) * ratioArray[0];
                    }
                    count++;
                }


                scope.selectedWidth = scope.endX = optionsReqWidth;
                scope.selectedHeight = scope.endY = optionsReqHeight;
            }

            scope.$on("IMAGE_LOADED", function (event, message) {
                scope.refresh = 0;
                scope.refreshImageHeight = 0;
                scope.setMaxHeightAndWidth();
                if(hasRequiredValues) {
                    scope.updateSelection();
                }
                scope.setImageRatioValue();
            });

            scope.$on("IMAGE_REFRESH", function (event, message) {
                scope.imgAreaSelect.cancelSelection();
            });

            scope.$on("event:escape", function () {
                scope.imgAreaSelect.cancelSelection();
            });

            scope.preview = function (img, selection) {
                if (!selection.width || !selection.height) {
                    return;
                }

                scope.updateOptionValues();

                /*var scaleX = scope.options.reqWidth / (selection.width * scope.imageRatio);
                var scaleY = scope.options.reqHeight / (selection.height * scope.imageRatio);
                $('#previewImage img').css({
                    width: Math.round(scaleX * scope.options.orgWidth),
                    height: Math.round(scaleY * scope.options.orgHeight),
                    marginLeft: -Math.round(scaleX * selection.x1 * scope.imageRatio),
                    marginTop: -Math.round(scaleY * selection.y1 * scope.imageRatio)
                });*/

                scope.startX = selection.x1 * scope.imageRatio;
                scope.startY = selection.y1 * scope.imageRatio;
                scope.endX = selection.x2 * scope.imageRatio;
                scope.endY = selection.y2 * scope.imageRatio;
                scope.selectedWidth = selection.width * scope.imageRatio;
                scope.selectedHeight = selection.height * scope.imageRatio;
            }

            scope.setSelectionValues = function (img, selection) {
                scope.startX = selection.x1 * scope.imageRatio;
                scope.startY = selection.y1 * scope.imageRatio;
                scope.endX = selection.x2 * scope.imageRatio;
                scope.endY = selection.y2 * scope.imageRatio;
                scope.selectedWidth = selection.width * scope.imageRatio;
                scope.selectedHeight = selection.height * scope.imageRatio;
            }

            scope.setImageRatioValue = function() {
                var imageElement = angular.element('#view-uploaded-image')[0];
                var clientImageHeight = imageElement.offsetHeight;
                var clientImageWidth = imageElement.offsetWidth;
                var orgHeight = scope.imgOptions.orgHeight;
                var orgWidth = scope.imgOptions.orgWidth;
                if(clientImageHeight == orgHeight && clientImageWidth == orgWidth) {
                    scope.imageRatio = 1;
                } else {
                    scope.imageRatio = orgWidth / clientImageWidth;
                }
                scope.imageRatio = (scope.imageRatio % 1 != 0) ? scope.imageRatio.toFixed(2) : scope.imageRatio;
            }

            scope.setMaxHeightAndWidth = function() {
                if (!angular.element('.image-resize-modal-body').length) {
                    scope.clientWidth = scope.imgOptions.orgWidth;
                    scope.clientHeight = scope.imgOptions.orgHeight;
                } else {
                    var clientHeight = angular.element('.image-resize-modal-body')[0].offsetHeight - angular.element('.image-resize-modal-body .row-fluid')[0].offsetHeight - 20;
                    var clientWidth = angular.element('.image-resize-modal-body')[0].offsetWidth - 10;
                    if (clientHeight <= 0 || clientWidth <= 0) {
                        scope.clientWidth = 500;
                        scope.clientHeight = 300;
                        if(scope.refreshImageHeight <= 2) {
                            scope.refreshImageHeight ++;
                            $timeout(scope.setMaxHeightAndWidth, 250);
                        }
                    } else {
                        scope.calculateMaxHeightAndWidth(clientHeight, clientWidth);
                    }
                }
            }

            scope.calculateMaxHeightAndWidth = function (clientHeight, clientWidth){
                var imgRatio = 1;
                if (scope.imgOptions.orgHeight > clientHeight) {
                    imgRatio =  clientHeight / scope.imgOptions.orgHeight;
                    scope.clientHeight = Math.round(clientHeight);
                    scope.clientWidth = Math.round(clientWidth * imgRatio);
                    if(scope.imgOptions.orgWidth * imgRatio > scope.clientWidth) {
                        imgRatio = clientWidth / scope.imgOptions.orgWidth;
                        scope.clientWidth = Math.round(clientWidth);
                        scope.clientHeight = Math.round(clientHeight * imgRatio);
                    }
                } else if (scope.imgOptions.orgWidth > clientWidth) {
                    imgRatio = clientWidth / scope.imgOptions.orgWidth;
                    scope.clientWidth = Math.round(clientWidth);
                    scope.clientHeight = Math.round(clientHeight * imgRatio);
                } else {
                    scope.clientWidth = scope.imgOptions.orgWidth;
                    scope.clientHeight = scope.imgOptions.orgHeight;
                }
                scope.setImageRatioValue();
            }

            scope.setMaxHeightAndWidth();

        }
    }
}]);