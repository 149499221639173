commusoftCommon.directive('csmodalaisetup', ['prefix', '$http', '$modal',function(prefix, $http, $modal) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            shouldBeOpenSetup: '='
        },
        controller: function($scope, $element, $attrs, $transclude) {
            $scope.$watch('shouldBeOpenSetup', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal == true) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;

                    modalInstance = $modal.open({
                        templateUrl: 'template/modal/accounting_integration/setup_accounting_integration.html',
                        scope: $scope,
                        controller: CSAISetupModalCtrl,
                        windowClass: 'modal with-slides large'
                    })
                }
            });
        }
    }
}]);