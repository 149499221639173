'use strict';

function SetupEmailCtrl($scope, $http, prefix, datasets, formService, canLoad, $q, $rootScope) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.selectedServer = 'smtp';
    $scope.existEmailAddress = true;
    $rootScope.queryString = '';
    $scope.$on('$viewContentLoaded', function(){
        if($scope.showMessage == 'yes'){
            $scope.flashMessage = 'Your email address has been connected to Commusoft and emails will start sending from your email address.';
            $scope.$broadcast('setupEmailConfirmation:successMessage', $scope.flashMessage);
        } else if($scope.showMessage == 'This email address has already been confirmed.'){
            $scope.flashMessage = 'This email address has already been confirmed.';
            $scope.$broadcast('setupEmailConfirmation:successMessage', $scope.flashMessage);
        } else if($scope.showMessage === 'This settings email server already exists'){
            $scope.flashMessage = 'This settings email server already exists.';
            $scope.$broadcast('setupEmailConfirmation:successMessage', $scope.flashMessage);
        }else{
            $scope.showMessage = 'no';
        }
    });
    $scope.CopyToEmailAddress = function () {
        $scope.username = $scope.emailaddress;
    }

    $scope.smtp = true;
    $scope.changeServer = function (server) {
        $scope.selectedServer = server;
        if (server == 'smtp') {
            $scope.smtp = true;
            $scope.imap = false;
        } else {
            $scope.smtp = false;
            $scope.imap = true;
        }
        $scope.$broadcast("EMAIL_SERVER_CHANGED", { 'server': server});
    }

    $scope.changeServerAddress = function () {
        $scope.serverAddressInvalid = false;
    };
    $scope.changePortNumber = function () {
        $scope.portNumberInvalid = false;
    };
    $scope.changeSecurityType = function () {
        $scope.securityTypeInvalid = false;
    };
    $scope.changePassword = function () {
        $scope.passwordInvalid = false;
    };

    var canceler = null;
    $scope.$watch('emailaddress', function (emailaddress) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        $scope.convertToArray = function () {
            $scope.email = $scope.emailaddress.split('@');
            if (typeof $scope.email[1] != '' && $scope.emailaddress != '') {
                $http.get(prefix + '/setupEmailDomainExists?emailaddress=' + $scope.emailaddress + '&selectedServer=' + $scope.selectedServer, {timeout: canceler.promise}).
                success(function (data, status) {
                    if (status == 200) {
                        if (data == 'true') {
                            $scope.showDetails = false;
                        } else {
                            $scope.showDetails = true;
                        }
                    }
                })
            $http.get(prefix + '/setupEmailExists?emailaddress=' + $scope.emailaddress + '&selectedServer=' + $scope.selectedServer, {timeout: canceler.promise}).
                success(function (data, status) {
                    if (status == 200) {
                        if (data == 'true') {
                            $scope.existEmailAddress = false;
                        } else {
                            $scope.existEmailAddress = true;
                        }
                    }
                })
            }
        }
    });


    $scope.performFormSubmit = function (url, formElem) {
        $scope.passwordInvalid = false;
        $scope.serverAddressInvalid = false;
        $scope.portNumberInvalid = false;
        $scope.securityTypeInvalid = false;
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.setupEmailForm['setupemails[emailaddress]'].$dirty = true;
                $scope.setupEmailForm['setupemails[emailaddress]'].$error.required = false;
                $scope.setupEmailForm['setupemails[emailaddress]'].$error.email = false;
                $scope.existEmailAddress = false;
            } else if (data == 'Invalid Password' || data == 'Too many login failures') {
                $scope.passwordInvalid = true;
            } else if (data == 'Invalid Server Address') {
                $scope.serverAddressInvalid = true;
                $scope.portNumberInvalid = true;
            } else if (data == 'Enter the Email Providers') {
                $scope.showDetails = true;
            } else if (data == 'Invalid Providers') {
                $scope.securityTypeInvalid = true;
            } else if (data == 'Invalid PortNumber') {
                $scope.portNumberInvalid = true;
                $scope.securityTypeInvalid = true;
            } else {
                $scope.setupEmailForm.$setPristine();
                $scope.emailaddress = "";
                $scope.username = "";
                $scope.password = "";
                $scope.serveraddress = "";
                $scope.portnumber = "";
                $scope.securitytype = "";
                $scope.$broadcast("SETUPEMAIL_ADDED", { 'id': data.id, 'emailaddress': data.emailaddress, 'password': data.password, 'username':data.username, 'serveraddress':data.serveraddress, 'portnumber' : data.portnumber, 'securitytype' :data.securitytype, 'settingstype' :data.settingstype });
                $scope.showDetails = false;
                $scope.flashMessage = 'Successfully setup the SMTP email';
                $scope.$broadcast('setupEmailConfirmation:successMessage', $scope.flashMessage);
            }
            $scope.saving = false;
        });
    }
}

function SetupEmailEditCtrl($scope, $state, $http, prefix, canLoad, $q) {
    $scope.selectedSetupEmail = $state.params.emailaddress;
    $scope.selectedSetupPassword = $state.params.password;
    $scope.selectedUsername = $state.params.username;
    $scope.selectedSetupServerAddress = $state.params.serveraddress;
    $scope.selectedSetupPortNumber = $state.params.portnumber;
    $scope.selectedSecurity = $state.params.securitytype;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;
    $scope.CopyToEmailAddress = function () {
        this.selectedUsername = this.selectedSetupEmail;
    }

    $scope.editPasswordChange = function () {
        $scope.editPassword = false;
    };
    $scope.editPortNumberChange = function () {
        $scope.editPortNumber = false;
    };
    $scope.editSecurityTypeChange = function () {
        $scope.editSecurityType = false;
    };
    $scope.editServerAddresschange = function () {
        $scope.editServerAddress = false;
    };
    $scope.$parent.showMessage = 'no';
    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.setup_email");
    }

    $scope.editSetupEmailAddress = function ($event, formStatus) {
        $scope.editPassword = false;
        $scope.editServerAddress = false;
        $scope.editPortNumber = false;
        $scope.editSecurityType = false;
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $http.post(prefix + '/edit_setupemail', "selectedId=" + this.selectedId + "&selectedSetupEmail=" + this.selectedSetupEmail +"&selectedUsername=" + encodeURIComponent(this.selectedUsername) + "&selectedSetupPassword=" + this.selectedSetupPassword + "&selectedSetupServerAddress=" + this.selectedSetupServerAddress + "&selectedSetupPortNumber=" + this.selectedSetupPortNumber + "&selectedSecurity=" + this.selectedSecurity).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.selectedSetupEmailAddress = true;
                    } else if (data == 'Invalid Password' || data == 'Too many login failures') {
                        $scope.editPassword = true;
                    } else if (data == 'Invalid Server Address') {
                        $scope.editServerAddress = true;
                        $scope.editPortNumber = true;
                    } else if (data == 'Enter the Email Providers') {
                        $scope.showEditSmtpDetails = true;
                    } else if (data == 'Invalid Providers') {
                        $scope.editSecurityType = true;
                    } else if (data == 'Invalid PortNumber') {
                        $scope.editPortNumber = true;
                        $scope.editSecurityType = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.setup_email");
                    }
                }
            });
    }

    var canceler = null;
    $scope.$watch('selectedSetupEmail', function (selectedSetupEmail) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        $scope.convertToArray = function () {
            $scope.selectedEmail = $scope.selectedSetupEmail.split('@');
            if ($scope.selectedEmail[1] != '' && $scope.selectedSetupEmail != '') {
                $http.get(prefix + '/setupEmailDomainExists?emailaddress=' + this.selectedSetupEmail + '&selectedServer=' + $scope.selectedServer, {timeout: canceler.promise}).
                    success(function (data, status) {
                        if (status == 200) {
                            if (data == 'true') {
                                $scope.showEditSmtpDetails = false;
                            } else {
                                $scope.showEditSmtpDetails = true;
                            }
                        }
                    })
                $http.get(prefix + '/setupEmailExists?emailaddress=' + this.selectedSetupEmail + '&selectedServer=' + $scope.selectedServer + '&selectedId=' + $scope.selectedId, {timeout: canceler.promise}).
                    success(function (data, status) {
                        if (status == 200) {
                            if (data == 'true') {
                                $scope.selectedSetupEmailAddress = true;
                            } else {
                                $scope.selectedSetupEmailAddress = false;
                            }
                        }
                    })
            }
        }
    });

}

function SetupEmailListEditCtrl($scope, $state, prefix, $http, setupEmailresolver, defaultPagingValue, $rootScope, toastBox) {
    $scope.setupEmails = setupEmailresolver.setupEmails;
    $scope.totalsetupEmails = setupEmailresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    $scope.$on("EMAIL_SERVER_CHANGED", function (event, msg) {
        $scope.selectedServer = msg.server;
        $scope.fetchSetupEmails(1);
    });

    JustAddedUpdatedChanges.call(this, $scope, 'setupEmail', $state, defaultPagingValue, 'setupEmails');

    $scope.$on('setupEmailConfirmation:successMessage', function (event, data) {
        $rootScope.flashSuccessMessage = data;
        toastBox.show($rootScope.flashSuccessMessage, 4000);
    });

    $scope.$on("SETUPEMAIL_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, setupEmailresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.setupEmails.push({'id': message.id, 'emailaddress': message.emailaddress, 'password': message.password, 'username':message.username,'serveraddress':message.serveraddress, 'portnumber':message.portnumber, 'securitytype':message.securitytype, 'justadded': true, 'settingstype' : message.settingstype});
            setupEmailresolver.setupEmails = $scope.setupEmails;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        setupEmailresolver.count = parseInt(setupEmailresolver.count) + 1
        $scope.totalsetupEmails = setupEmailresolver.count;

    });

    $scope.editSetupEmail = function (setupEmail) {
        $state.transitionTo('loggedin.setup_email.edit', {'id':setupEmail.id, 'emailaddress': setupEmail.emailaddress,'username': setupEmail.username, 'password': setupEmail.password,
            'serveraddress':setupEmail.serveraddress, 'portnumber':setupEmail.portnumber, 'securitytype':setupEmail.securitytype,
            'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
        if ($scope.totalsetupEmails == 1) {
            $scope.emailaddress = true;
        } else {
            $scope.emailaddress = false;
        }
    }

    $scope.fetchSetupEmails = function (pageNum) {
        $http.get(prefix + '/listSetupEmail?page=' + pageNum + '&limit=' + $scope.limit + '&selectedServer=' + $scope.selectedServer).success(function (data) {
            $scope.setupEmails = data.setupEmails;
            $scope.totalsetupEmails = data.count;
            setupEmailresolver.setupEmails = data.setupEmails;
            setupEmailresolver.count = data.count;

            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function NewLetterheadCtrl($scope, datasets, prefix, $http, toastBox, $state) {
    $scope.saveChanges = true;
    $scope.newfiles = [];
    $scope.onChange = function(state) {
        console.log('The state is', state);

        if(state['footerLogoImageFiles'].length > 0) {
            angular.forEach(state['footerLogoImageFiles'], function (keys, val) {
                if(state['footerLogoImageFiles'][val]) {
                    var imgobj = _.where($scope.newfiles, { key: val });
                    if(imgobj.length == 0){
                        $scope.newfiles.push({type: 'footerLogoImageFiles', name: state['footerLogoImageFiles'][val].name,  src: state['footerLogoImageFiles'][val].src, 'key': val});
                    }
                }
            });
        }
        if($scope.saveChanges === true) {
            var postData = "data=" + encodeURIComponent(angular.toJson(state))+ "&filenames=" + encodeURIComponent(JSON.stringify($scope.newfiles));
            $http.post(prefix + '/company_settings/create_letterhead', postData).success(function (data) {
                $scope.data = data;
                $state.transitionTo('loggedin.create_letterhead', null, {reload: true});
                toastBox.show('Letterhead saved', 4000);
            })
        }
    }
    $scope.onImageUploaded = function(event) {
        $scope.saveChanges = false;
        $('#loading-indicator').show();
        var $form = $(event.target);
        $form.attr('action', prefix + '/saveLetterheadImage');
        $form.attr('type', 'POST');
        $form.ajaxSubmit({
            type: 'POST',
            success: function(responseText) {
                if(responseText) {
                    if(responseText[0].type == 'footerLogoImageFile0') {
                        responseText[0].type = 'footerLogoImageFiles';
                        datasets[responseText[0].type][0] = responseText[0];
                        $scope.letterheadsProps.footerLogoImageFiles[0].src = responseText[0].src;
                        var key = 0;
                        $scope.newfiles.push({type: responseText[0].type, name: responseText[0].name,  src: responseText[0].src, 'key': key});
                    } else if(responseText[0].type == 'footerLogoImageFile1') {
                        responseText[0].type = 'footerLogoImageFiles';
                        datasets[responseText[0].type][1] = responseText[0];
                        var key = 1;
                        $scope.letterheadsProps.footerLogoImageFiles[1].src = responseText[0].src;
                        $scope.newfiles.push({type: responseText[0].type, name: responseText[0].name,  src: responseText[0].src, 'key': key});
                    } else if(responseText[0].type == 'footerLogoImageFile2') {
                        responseText[0].type = 'footerLogoImageFiles';
                        var key = 2;
                        datasets[responseText[0].type][2] = responseText[0];
                        $scope.letterheadsProps.footerLogoImageFiles[2].src = responseText[0].src;
                        $scope.newfiles.push({type: responseText[0].type, name: responseText[0].name,  src: responseText[0].src, 'key': key});
                    } else if(responseText[0].type == 'letterheadFullImageFile') {
                        datasets[responseText[0].type] = responseText[0];
                        $scope.letterheadsProps.letterheadFullImageFile.src = responseText[0].src;
                        $scope.newfiles.push({type: responseText[0].type, name: responseText[0].name,  src: responseText[0].src});
                    } else if(responseText[0].type == 'letterheadLogoImageFile') {
                        datasets[responseText[0].type] = responseText[0];
                        $scope.letterheadsProps.letterheadLogoImageFile.src = responseText[0].src;
                        $scope.newfiles.push({type: responseText[0].type, name: responseText[0].name,  src: responseText[0].src});
                    } else if(responseText[0].type == 'footerFullImageFile') {
                        datasets[responseText[0].type] = responseText[0];
                        $scope.letterheadsProps.footerFullImageFile.src = responseText[0].src;
                        $scope.newfiles.push({type: responseText[0].type, name: responseText[0].name,  src: responseText[0].src});
                    }
                }
                $scope.saveChanges = true;
                $('#loading-indicator').hide();
                $form.removeAttr('action');
            }
        });
        console.log('image uploaded');
    }
    var response = datasets;
    response.onImageUploaded = $scope.onImageUploaded;
    response.onChange = $scope.onChange;
    $scope.letterheadsProps = datasets;
}

function CreateLetterheadCtrl($scope, $stateParams, $location, $http, $state, datasets, prefix, $rootScope, formService, $upload, toastBox) {
    $scope.data = datasets;
    $scope.shouldBeOpen = false;
    $scope.openResizeModal = false;
    $scope.imgOptions = { orgHeight: 500, orgWidth: 500, reqHeight: 100, reqWidth: 100, ratio: '1:1' };
    $scope.marginOptions = ['0.75', '1', '1.25', '1.5', '1.75', '2', '2.25', '2.5'];
    $scope.topMarginOptions = ['5','5.25','5.5','5.75','6', '6.25', '6.5', '6.75', '7', '7.25', '7.5', '7.75', '8'];
    $scope.bottomMarginOptions = ['4.5', '4.75', '5','5.25','5.5','5.75','6', '6.25', '6.5', '6.75', '7'];
    $scope.topImageError = false;
    $scope.topImageErrorMessage = '';

    console.log($scope.clients);

    $scope.triggerUpload = function (section) {
        if (section == 'Top') {
            $scope.imgOptions.reqHeight = 150;
            $scope.imgOptions.reqWidth = 350;
            $scope.imgOptions.ratio = '7:3';
        } else {
            $scope.imgOptions.reqHeight = $scope.imgOptions.reqWidth = 100;
            $scope.imgOptions.ratio = '1:1';
        }
        $scope.shouldBeOpen = true;
        $scope.selectedSection = section;
    }

    $scope.triggerSystemModal = function() {
        $scope.selectedSection = 'Top';
        angular.element('#upload_top_logo').click();
    }

    $scope.onLetterheadFileSelect = function ($files) {
        for (var i = 0; i < $files.length; i++) {
            var file = $files[i];
            var fileName = file.name;
            var extension = fileName.split('.').pop();
            var ext = extension.toLowerCase();

            if(ext == 'jpg' || ext == 'jpeg' || ext == 'png') {
                $scope.progress = 0;
                $scope.upload = $upload.upload({
                    url: prefix + "/upload_logo?mode=Top",
                    method: 'POST',
                    file: file
                }).success(function (data) {
                    $scope.imageResizeOptions = { orgHeight: 500, orgWidth: 500, reqHeight: 150, reqWidth: 350, ratio: '7:3' };
                    $scope.profilepicture = data.message.file;
                    $scope.imageResizeOptions.orgHeight = data.message.height;
                    $scope.imageResizeOptions.orgWidth = data.message.width;
                    $scope.openResizeModal = true;
                    $scope.topImageError = false;
                    $scope.topImageErrorMessage = '';
                });
            } else {
                $scope.topImageError = true;
                $scope.topImageErrorMessage = "Please upload a valid image file, should be in the format of jpg or jpeg or png";
            }
        }
    };

    $scope.saveResizedImageSuccess = function (resp) {
        var src = 'letterhead' + $scope.selectedSection;
        $scope[src] = resp.message.file;
        $scope.shouldBeOpen = false;
        $scope.openResizeModal = false;
    }

    $scope.onUploadLogoSuccess = function (resp) {
        $scope.shouldBeOpen = false;
        if (!resp.error) {
            var modelName = resp.message.mode + 'Image';
            $scope[modelName] = resp.message.file;
            $scope.displayError = '';
            $scope.$broadcast("RESET_PROGRESS");
        } else {
            $scope.errorMessage = resp.message;
            $scope.displaySuccess = '';
        }
    }

    $scope.saveMarginValues = function () {
        $scope.saving = true;
        $http.post(prefix + '/saveLetterheadMarginValues', $.param({ left: $scope.marginLeft, right: $scope.marginRight, top: $scope.marginTop, bottom: $scope.marginBottom, headerFooterRequired: $scope.headerFooterRequired})).
            success(function (data) {
                $scope.saving = false;
                toastBox.show('Letterhead margins saved', 4000);
            });
    }
    $scope.removeHeaderFooterImage = function (type) {
            $http.post(prefix + '/removeLetterheadImages', '&type=' + type).
                success(function (data, status) {
                    toastBox.show('Letterhead image removed', 4000);
                    $scope[type + 'Image'] = '';
                });
    }
}

function CompanySettingsUploadLogoCtrl($scope, $stateParams, $location, $http, $state, datasets, prefix) {
    $scope.data = datasets;
    $scope.sqImageError = false;
    $scope.recImageError = false;
    $scope.sqButtontName = "Upload";
    $scope.recButtontName = "Upload";
    $scope.squareImage = '';
    $scope.rectangleImage = '';
    $scope.squareError = '';
    $scope.rectangleError = '';
    $scope.imageResizeOptions = { orgHeight: 500, orgWidth: 500, reqHeight: 200, reqWidth: 200, ratio: '1:1' };

    $scope.imageResizeSave = function (resp) {
        var scopeName = $scope.mode + 'Image';
        $scope[scopeName] = resp.message.file;
        var errorScopeName = $scope.mode + 'Error';
        $scope[errorScopeName] = '';
    }

    $scope.uploadLogoSuccess = function (resp) {
        var mode = resp.mode;
        if (!resp.error) {
            $scope.errorMessage = '';
            if (resp.message.resize) {
                $scope.mode = mode;
                $scope.selectedImageSrc = resp.message.file;
                $scope.imageResizeOptions.orgHeight = resp.message.height;
                $scope.imageResizeOptions.orgWidth = resp.message.width;
                if (mode == 'square') {
                    $scope.imageResizeOptions.reqWidth = 200;
                    $scope.imageResizeOptions.ratio = '1:1';
                } else {
                    $scope.imageResizeOptions.ratio = '2:1';
                    $scope.imageResizeOptions.reqWidth = 400;
                }
                $scope.shouldBeOpen = true;
                $scope.$broadcast('IMAGE_LOADED');
            } else {
                var scopeName = mode + 'Image';
                var errorScopeName = mode + 'Error';
                $scope[scopeName] = resp.message.file;
                $scope[errorScopeName] = '';
            }
        } else {
            if (!mode) {
                var action = resp.action;
                var actionArray = action.split('mode=');
                mode = actionArray[1];
            }
            var scopeName = mode + 'Error';
            $scope[scopeName] = resp.message;
        }
    }

    $scope.saveCertificateLogo = function () {
        $scope.saving = true;
        $scope.errorMessage = '';
        $http.post(prefix + '/save_certificate_logo', $.param({  squareImageSrc: $scope.squareImage, rectangleImageSrc: $scope.rectangleImage })).
            success(function (data) {
                $state.transitionTo('loggedin.company_settings');
            });
    }

    $scope.deleteUplodedLogos = function () {
        $scope.removeUploadedLogo($scope.squareImage);
        $scope.removeUploadedLogo($scope.rectangleImage);
        $state.transitionTo('loggedin.company_settings');
    }

    $scope.removeUploadedLogo = function (imageSrc) {
        if (imageSrc != '') {
            $http.post(prefix + '/remove_uploaded_picture', 'imageSrc=' + imageSrc).
                success(function (data, status) {
                });
        }
    }

}

function SettingsImportDataCtrl($scope, $stateParams, $location, $http, $state, datasets, prefix, $modal, warningModal, $translate, NgConfigKBUrlService) {
    $scope.data = datasets;
    $scope.pastRecords = [];
    $scope.shouldBeOpenDelete = false;
    $scope.shouldBeOpenReactivate = false;
    $scope.selectedId = '';
    $scope.importproperty = NgConfigKBUrlService.getData("importProperty");

    $scope.columns = [
        {name: 'Customer Type', isinuse: false},
        {name: 'Title', isinuse: false},
        {name: 'Name', isinuse: false},
        {name: 'Surname', isinuse: false},
        {name: 'Company Name', isinuse: false},
        {name: 'Address Line 1', isinuse: false},
        {name: 'Address Line 2', isinuse: false},
        {name: 'Address Line 3', isinuse: false},
        {name: 'Town', isinuse: false},
        {name: 'County', isinuse: false},
        {name: 'Postcode', isinuse: false},
        {name: 'Telephone', isinuse: false},
        {name: 'Mobile', isinuse: false},
        {name: 'Email', isinuse: false},
        {name: 'Advertising Type', isinuse: false},
        {name: 'Building Type', isinuse: false},
        {name: 'Vacant Property', isinuse: false},
        {name: 'Accounting Reference', isinuse: false},
        {name: 'Service Reminder Name1', isinuse: false},
        {name: 'Service Reminder Date1', isinuse: false},
        {name: 'Service Reminder Name2', isinuse: false},
        {name: 'Service Reminder Date2', isinuse: false},
        {name: 'Notes', isinuse: false}
    ];

    $scope.submitForm = function () {
        $scope.csvProps.csvImportIsSaving = true;
        $scope.csvProps.onSave = false;
        $scope.csvProps.onSubmit = false;
        $http.post(prefix + '/import_records_from_csv/properties', $.param({ flds: $scope.columnHeaders, csvfile: $scope.uploadedFile, containsHeader: $scope.containsHeader})).
            success(function (data) {
                $scope.csvProps.onSave = true;
                $scope.csvProps.onSubmit = true;
                $scope.csvProps.csvImportHasSaved = true;
                $scope.csvProps.csvImportIsSaving = false;
                $scope.fetchPastRecords();
            });
    }

    HandleCsvImports.call(this, $scope, $modal, warningModal, $translate);

    $scope.fetchPastRecords = function () {
        $http.get(prefix + '/getPastImportRecords').success(function (data) {
            $scope.pastRecords = data.pastRecords;
        })
    }
    $scope.fetchPastRecords();

    $scope.triggerDeleteReactivate = function (selectedId, action, importCount) {
        $scope.selectedId = selectedId;
        if(importCount > 1000) {
            if(action == "Delete") {
                $scope.message = $translate('Delete.Customer.Import.Helptext');
                warningModal.show($scope.message, 'Delete customer imports', selectedId);
            } else {
                $scope.message = $translate('Reactivate.Customer.Import.Helptext');
                warningModal.show($scope.message, 'Reactivate customer imports', selectedId);
            }

        } else {
            if (action == "Delete") {
                $scope.shouldBeOpenDelete = true;
            } else {
                $scope.shouldBeOpenReactivate = true;
            }
        }
    }
}
function PurchaseTrackersNewCardFormCtrl($scope, $state, $http, formService, $rootScope, $timeout, $window, purchaseTrackerService) {
    $scope.newCardName = "";
    $scope.newCardNumber = "";
    $scope.newCardCvc = "";
    $scope.newCardExpiryMonth = 1;
    $scope.newCardExpiryYear = new Date().getFullYear();
    $scope.newCardBillingSameAsShipping = true;

    $scope.billingAddressSame = function() {
        $scope.newCardBillingSameAsShipping = true;
        $scope.newCardBillingAddressLine1 = purchaseTrackerService.deliveryAddress.addressline1;
        $scope.newCardBillingAddressLine2 = purchaseTrackerService.deliveryAddress.addressline2;
        $scope.newCardBillingTown = purchaseTrackerService.deliveryAddress.town;
        $scope.newCardBillingCounty = purchaseTrackerService.deliveryAddress.county;
        $scope.newCardBillingCountry = purchaseTrackerService.deliveryAddress.country;
        $scope.newCardBillingPostcode = purchaseTrackerService.deliveryAddress.postcode;
    };

    $scope.billingAddressSame();

    $scope.toggleBillingAddress = function(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        if ($scope.newCardBillingSameAsShipping) {
            $scope.billingAddressNotSame();
        }
        else {
            $scope.billingAddressSame();
        }
    };

    $scope.billingAddressNotSame = function() {
        $scope.newCardBillingSameAsShipping = false;
        $scope.newCardBillingAddressLine1 = '';
        $scope.newCardBillingAddressLine2 = '';
        $scope.newCardBillingTown = '';
        $scope.newCardBillingCounty = '';
        $scope.newCardBillingCountry = '';
        $scope.newCardBillingPostcode = '';
    };
}

function PurchaseTrackersNewCardModalCtrl($scope, $state, $http, formService, $rootScope, $timeout, $window, $modal, $modalInstance) {
    $scope.newCard = {};
    $scope.formSubmitted = false;
    $scope.valid = true;
    $scope.validationError = false;
    $scope.years = [];
    $scope.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    //$scope.cardNumberInvalid = false;

    $scope.generateYears = function(maxYear) {
        var currentYear = new Date().getFullYear();
        maxYear = maxYear || 2040;

        while ( currentYear <= maxYear ) {
                $scope.years.push(currentYear++);
        } 

        return $scope.years;
    };

    $scope.close = function () {
        $modalInstance.close();

        $rootScope.$broadcast('purchasetracker_modal_closed');
    }

    $scope.saveNewCard = function(form) {
        $scope.formSubmitted = true;

        // do validation magic here
        //$scope.saving = true;
    
        var regexp = /[a-zA-Z]/;
        if (regexp.test(form.cardnumber.$viewValue)) {
            if (!form.cardnumber.$error.required) {
                form.cardnumber.$setValidity('integer', false);
            }
        }
        else {
                form.cardnumber.$setValidity('integer', true);
        }

        if (form.cardnumber.$viewValue.length < 16) {
            form.cardnumber.$setValidity('minlength', false);
        }
        else {
            form.cardnumber.$setValidity('minlength', true);
        }
        if (regexp.test(form['cc-csc'].$viewValue)) {
            if (!form['cc-csc'].$error.required) {
                form['cc-csc'].$setValidity('integer', false);
            }
        }
        else {
                form['cc-csc'].$setValidity('integer', true);
        }
        if (form['cc-csc'].$viewValue.length < 3) {
            form['cc-csc'].$setValidity('minlength', false);
        }
        else {
            form['cc-csc'].$setValidity('minlength', true);
        }

        // NEXT STEP: validate the expiry date
        var inputMonth = form.newCardExpiryMonthInput.$viewValue; 
        var inputYear = form.newCardExpiryYearInput.$viewValue;

        var todaysDate = new Date();
        var todaysMonth = todaysDate.getMonth()+1;
        var todaysYear = todaysDate.getFullYear();

        if (inputYear < todaysYear) {
            form.newCardExpiryYearInput.$setValidity('futuredate', false);
        }
        else if (inputMonth <= todaysMonth && inputYear == todaysYear) {
            form.newCardExpiryMonthInput.$setValidity('futuredate', false);
        }
        else {
            form.newCardExpiryMonthInput.$setValidity('futuredate', true);
            form.newCardExpiryYearInput.$setValidity('futuredate', true);
        }

        if (form.$invalid) {
            $scope.saving = false;
        }
        else {
            // change this later, and add an ID which we will get from the back-end
            $rootScope.$broadcast('purchasetracker:creditcard:new', {
                'creditcard_billing_name': form.name.$viewValue, 
                'creditcard_number': form.cardnumber.$viewValue, 
                'creditcard_cvc': form['cc-csc'].$viewValue,
                'creditcard_expirymonth': form.newCardExpiryMonthInput.$viewValue, 
                'creditcard_expiryyear': form.newCardExpiryYearInput.$viewValue,
                'modal': $modalInstance
/*
                'creditcard_billing_address1': form.newCardBillingAddressLine1Input.$viewValue,
                'creditcard_billing_address2': form.newCardBillingAddressLine2Input.$viewValue,
                'creditcard_billing_town': form.newCardBillingTownInput.$viewValue,
                'creditcard_billing_county': form.newCardBillingCountyInput.$viewValue,
                'creditcard_billing_country': form.newCardBillingCountryInput.$viewValue,
                'creditcard_billing_postcode': form.newCardBillingPostcodeInput.$viewValue,    
*/
                }
            );
            
            //if(!$rootScope.cardNumberInvalid) {
                //$modalInstance.close();        
                //$rootScope.$broadcast('purchasetracker_modal_closed');
            //}
        }
    }

    $scope.generateYears(2040);
}

function PurchaseTrackersCtrl($scope, adminPrefix, $state, $http, $q, prefix, datasets, formService, $rootScope, $timeout, $window, $modal, $translate, purchaseTrackerService) {
}

function PurchaseTrackersFormCtrl($scope, adminPrefix, $state, $http, $q, prefix, formService, $rootScope, $timeout, $window, $modal, $translate, purchaseTrackerService) {
    $scope.trackerData = {};
    $scope.trackerDataLoaded = false;
    $scope.total = false;
    $scope.netCost = false;
    $scope.deliveryCost = false;
    $scope.VAT = false;
    $scope.VATRate = "";
    $scope.noTrackersForCountry = false;
    $scope.deliveryAddress = {};
    $scope.deliveryAddressLoaded = false;
    $scope.chosenCard = false;
    $scope.savedCards = [];
    $scope.technicalError = false;
    $scope.billingLoaded = false;
    $scope.purchaseSuccessful = false;
    $scope.purchaseData = {};
//    $scope.basket = [];
    $scope.basket = {};
    $scope.modalInstance = false;
    $scope.purchaseFormSubmitted = false;
    $scope.priceDetails1 = "";
    $scope.totalQuantity = 0;
    $scope.addCardTechnicalError = false;
    $rootScope.cardNumberInvalid = false;
    $scope.quantityError = false;
    $scope.shouldBeOpen = false;
    $rootScope.removeCardId = "";

    $scope.hasPurchaseHappened = function() {
        return ($scope.purchaseSuccessful || $scope.technicalError);
    }

    $scope.$on('purchasetracker_modal_closed', function(evt) {
        $scope.modalInstance = false;
    });

    $scope.getTrackerDescription = function(tracker) {
        return tracker.description + '.description';
    };

    $scope.isBasketEmpty = function() {
        var basketEmpty = true;

        angular.forEach($scope.basket, function(item, itemkey) {
            basketEmpty = false;
        });
        
        return basketEmpty;
    };

    $scope.useNewCard = function(evt) {
        evt.preventDefault();
        $rootScope.cardNumberInvalid = false;
        if ($scope.modalInstance == false) {
            $scope.modalInstance = $modal.open({
                templateUrl: 'template/settings/purchasetrackers/use_new_card.html',
                controller: PurchaseTrackersNewCardModalCtrl
            });
        }
    };

    $scope.updateDeliveryAddress = function() {
        purchaseTrackerService.deliveryAddress = angular.copy($scope.deliveryAddress);
    };

    $scope.processPurchase = function() {
        $scope.purchaseFormSubmitted = true;

        if (!$scope.purchaseTrackerForm.$invalid) {
            var trackersList = '';

            angular.forEach($scope.basket, function(qty, itemkey) {
                if (trackersList.length > 0) {
                    trackersList += '&vehicle_trackers_id[' + itemkey + ']=' + qty;
                }
                else {
                    trackersList += 'vehicle_trackers_id[' + itemkey + ']=' + qty;
                }
            });

            $http({
                'method': 'POST',
                'url': prefix + '/clients/' + $rootScope.clientId + '/purchasevehicletrackers',
                'headers' : { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
                'data': 
                    /* trackersList example with a tracker that has an ID '2' and a quantity of 1:
                    //
                    // vehicle_trackers_id[2] = 1
                    */

                    trackersList +
                    '&clientscreditcardid=' + encodeURIComponent($scope.chosenCard) +  
                    '&vehicle_trackers_address1=' + encodeURIComponent(purchaseTrackerService.deliveryAddress.addressline1) + 
                    '&vehicle_trackers_address2=' + encodeURIComponent(purchaseTrackerService.deliveryAddress.addressline2) +
                    '&vehicle_trackers_address3=' + encodeURIComponent(purchaseTrackerService.deliveryAddress.addressline3) +
                    '&vehicle_trackers_town=' + encodeURIComponent(purchaseTrackerService.deliveryAddress.town) +
                    '&vehicle_trackers_county=' + encodeURIComponent(purchaseTrackerService.deliveryAddress.county) +
                    '&vehicle_trackers_postcode=' + encodeURIComponent(purchaseTrackerService.deliveryAddress.postcode) +  '&actionType=vehicleTracker'
            }).then(function(resp) {
                if (resp) {
                    if( resp.data.records.data && resp.data.records.data.status == 'requires_source_action') {
                        // load stripe js
                        if(_.has($rootScope.loadedScript, 'stripeJs')) {
                            $rootScope.loadedScript.stripeJs.then(function(isLoaded){
                            if( !isLoaded && _.isEmpty($scope.publicKey) ) {
                                throw "StripeJs or Public key not found....";
                            }
                            });
                        }
                        $scope.publicKey = atob(resp.data.records.data.stripe_api_public_key);
                        $scope.stripe = Stripe($scope.publicKey);
                        //
                        $scope.stripe.confirmCardPayment(atob(resp.data.records.data.client_secret))
                        .then(function(result){
                            //console.log('result', result);
                            if( result.error ) {
                                $scope.technicalError = true;
                                $scope.error = result.error.message;
                                $scope.status = "error";
                            } else {
                                $scope.status = result['paymentIntent']['status'];
                            }
                            
                            fetch(prefix + '/confirm_client_payment', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    data: resp.data.records.UUID,
                                    result: $scope.status,
                                    action: "vehicleTracker",
                                    invoiceId: resp.data.records.data.invoiceId
                                })
                            }).then(function(confirmResult) {
                                if( $scope.status != "error") {
                                    $scope.purchaseSuccessful = true; 
                                    $('html').scrollTop(0);
                                }
                                if (!$scope.purchaseSuccessful) {
                                    $scope.technicalError = true;
                
                                    console.log('error response: ');
                                    console.log(resp);
                                }
                            }).then(function(){
    
                            });
                            
                        });
                    }  else if (resp.data.records.error) {
                       $scope.technicalError = true;
                    }  else if (resp['status'] == 201 || resp['status'] == 200) {
                       $scope.purchaseSuccessful = true; 
                       $('html').scrollTop(0);
                    } else {
                        $scope.technicalError = true;
                    }
                }
                
            }, function(resp) {
            });
        }        
    }

    $scope.calculateNewPrice = function(tracker) {
        var trackerCostFound = false;
        $scope.deliveryCost = false;
        //$scope.quantityError = false;
        totalQuantity = 0;
        angular.forEach($scope.basket, function(qty, itemkey) {
            totalQuantity = totalQuantity + qty;
            console.log('totalQuantity'+qty);
                
        });
        
        if(totalQuantity >=0 && totalQuantity <=10000) {
            if (totalQuantity >= 0) {
                $http.get(prefix + '/vehicletrackers/' + tracker.id + '/quantity/' + tracker.quantity  + '/cost').then(function(resp) {
                        if (resp['data']) {
                            if (resp.data['vehicletrackerscost']) {
                                trackerCostFound = true;
                                var moneySymbol = '&#163;';

                                if ($scope.trackerData) {
                                    if ($scope.trackerData['currencysymbol']) {
                                        moneySymbol = $scope.trackerData.currencysymbol;
                                    }
                                }

                                tracker['totalCost'] = parseFloat(resp.data.vehicletrackerscost.totalamount);
                                tracker['netCost'] = parseFloat(resp.data.vehicletrackerscost.amount);

                                if (resp.data.vehicletrackerscost['vat']) {
                                    tracker['VATCost'] = parseFloat(resp.data.vehicletrackerscost.vat);
                                }

                                if (resp.data.vehicletrackerscost['deliverycost']) {
                                    tracker['deliveryCost'] = parseFloat(resp.data.vehicletrackerscost['deliverycost']);
                                }

                                $scope.total = 0.00;
                                $scope.netCost = 0.00;

                                $scope.VAT = null;
                                $scope.deliveryCost = false;
                                $scope.VATRate = parseFloat(resp.data.vehicletrackerscost.vatrate);
                                
                                if (resp.data.vehicletrackerscost['vat']) {
                                    $scope.VAT = 0.00;
                                }

                                if (resp.data.vehicletrackerscost['deliverycost']) {
                                    $scope.deliveryCost = 0.00;
                                }

                                angular.forEach($scope.trackerData['trackers'], function(product, index) {
                                    if (product['totalCost'] !== undefined && product['totalCost'] !== null   ) { //&& ($scope.total != product['totalCost'])
                                        console.log("vehicletrackertotalcost"+product['totalCost']);
                                        //$scope.total = tracker['totalCost'];
                                        $scope.total += product['totalCost'];
                                    }
                                    if (product['netCost'] !== undefined && product['netCost'] !== null ) { //&& $scope.netCost == "0.00"
                                        //$scope.netCost = tracker['netCost'];
                                        $scope.netCost += product['netCost'];
                                    }

                                    if (product['VATCost'] !== undefined && product['VATCost'] !== null) {
                                        //$scope.VAT = tracker['VATCost'];
                                        $scope.VAT += product['VATCost'];
                                    }
                                    
                                    //added check - delivery cost
                                    if (product['deliveryCost'] !== undefined && product['deliveryCost'] !== null && $scope.deliveryCost == "0.00") {
                                        $scope.deliveryCost =  $scope.deliveryCost + product['deliveryCost'];
                                    }
                                    
                                    if(totalQuantity == 0) {
                                        $scope.deliveryCost = false;
                                    }
                                    
                                });

                            }
                        }
                });
            }
        } else {
             //$scope.quantityError = true;
        }

        if (!trackerCostFound) {
            $scope.total = false;
            $scope.netCost = false;
            $scope.deliveryCost = false;
            $scope.VAT = false;
        }
    };

    $scope.cardAvailable = function() {
	return ($scope.chosenCard !== false && $scope.chosenCard !== undefined && $scope.chosenCard !== null);
    };

    $scope.addToBasket = function(tracker) {
        tracker.addedToBasket = true;
        $scope.basket[tracker.id] = tracker.quantity;

        //$scope.totalQuantity = $scope.totalQuantity + tracker.quantity;
        $scope.calculateNewPrice(tracker);
    }

    $scope.removeFromBasket = function(tracker) {
        console.log("removeFromBasket");
        $scope.basket[tracker.id] = tracker.quantity;
        console.log("quantity"+tracker.quantity);
        if (tracker.quantity == 0) {
            console.log("if");
            tracker.addedToBasket = false;
            //$scope.totalQuantity = $scope.totalQuantity + tracker.quantity;

            var newBasket = {};
            var keepInBasket = true;

            angular.forEach($scope.basket, function(qty, itemkey) {
                keepInBasket = true;

                if (itemkey == tracker.id) {
                    keepInBasket = false;
                }
        
                if (keepInBasket) {
                    newBasket[itemkey] = qty;
                }
            });

            $scope.basket = newBasket;
        }
        if (tracker.quantity >= 0) {
            console.log("if1");
            $scope.calculateNewPrice(tracker);
        }
    }

    $scope.decreaseQty = function(tracker) {
        var quantityDefined = true;
        
        console.log("decreaseQty" + $scope.totalQuantity);
        if (tracker) {
            if (tracker.quantity < 0) {
                    tracker.quantity = 0;
            }
            console.log("decreaseQty - if tracker");
            if ($scope.totalQuantity >= 0) {
                $scope.totalQuantity = $scope.totalQuantity - 1;
            }
            
            if (tracker.quantity > 0) {
                console.log("decreaseQty - if tracker quantity"+tracker.quantity);
                tracker.quantity = tracker.quantity - 1;
                $scope.removeFromBasket(tracker);
                quantityDefined = true;
            }

            if (!quantityDefined) {
                console.log("decreaseQty -quantityDefined");
                tracker['quantity'] = 0;
                $scope.removeFromBasket(tracker);
            }
        }
        else {
            throw new Error('Tracker not defined');
        }
    };

    $scope.qtyChanged = function(tracker) {
        console.log("qtyChanged"+tracker.quantity)
        $scope.quantityError = false;
        
        if (tracker.quantity < 0) {
            tracker.quantity = 0;
        }
            
        if(Number.isInteger(tracker.quantity) === false || tracker.quantity < 0 || tracker.quantity > 10000) {
            if (tracker.quantity !== null && tracker.quantity !== undefined) {
                $scope.quantityError = true;
                tracker.prevQty = tracker.quantity;
            }
        } else {
            $scope.quantityError = false;
            if (typeof tracker['prevQty'] == "number") {
                if (tracker.quantity < 0) {
                    tracker.quantity = 0;
                }
                if (tracker.quantity < tracker.prevQty) {
                    $scope.removeFromBasket(tracker);
                }
                else { 
                    $scope.addToBasket(tracker);
                }
                tracker.prevQty = tracker.quantity;
            }
            else {
                if (tracker.quantity < 0) {
                    tracker.quantity = 0;
                }
                else {
                    $scope.addToBasket(tracker);
                }
                tracker.prevQty = tracker.quantity;
            }
        }
    };

    $scope.increaseQty = function(tracker) {
        var quantityDefined = false;
        if ($scope.totalQuantity < 0) {
           $scope.totalQuantity = 0;
        }
        $scope.totalQuantity = $scope.totalQuantity + 1;
        if (tracker) {
            if (tracker.quantity !== null && tracker.quantity !== undefined) {
                tracker.quantity = tracker.quantity + 1;
                $scope.addToBasket(tracker);
                quantityDefined = true;
            }
            if (!quantityDefined) {
                tracker['quantity'] = 0;
                $scope.removeFromBasket(tracker);
            }
        }
        else {
            throw new Error('Tracker not defined');
        }
    };

    $scope.clearDeliveryFields = function(evt) {
        evt.preventDefault();

        $scope.deliveryAddress['addressline1'] = '';
        $scope.deliveryAddress['addressline2'] = '';
        $scope.deliveryAddress['addressline3'] = '';
        $scope.deliveryAddress['town'] = '';
        $scope.deliveryAddress['county'] = '';
        $scope.deliveryAddress['postcode'] = '';
        $scope.deliveryAddress['country'] = ''; 

        purchaseTrackerService['deliveryAddress'] = angular.copy($scope.deliveryAddress);
    };

    $scope.getTrackerImageURL = function(tracker) {
        return '/images/trackers/' + tracker.name.toLowerCase().replace(/\./g, '-') + '-150x150.png';
    };

    $scope.fetchTrackerData = function() {
        $http.get(prefix + '/vehicletrackers').then(function(resp) {
            $http.get(prefix + '/vehicletrackers/price/' + $rootScope.clientId).then(function(resp) {
                priceDetails1 = "step2";
                console.log("vehicletrackersprices step2");
                if (resp['data']) {
                    priceDetails1 = "step3";
                    console.log("vehicletrackersprices step3");
                    if (resp.data['vehicletrackersprices']) {
                        priceDetails1 = resp.data['vehicletrackersprices'];  
                        console.log("vehicletrackersprices step4");
                        $scope.priceDetails = priceDetails1;
                    }
                }
            });

            $http.get(prefix+ '/clients/' + $rootScope.clientId + '/basicdetails').then(function(clientdetails) {
                if (clientdetails) {
                    if (clientdetails['data']) {
                        if (clientdetails.data['clientbasicdetails']) {
                            if (clientdetails.data.clientbasicdetails['countrycode']) {
                                var clientcountrycode = clientdetails.data.clientbasicdetails['countrycode'];

                                var trackersFound = false;
                                if (resp['data']) {
                                    if (resp.data['vehicletrackers']) {
                                        angular.forEach(resp.data.vehicletrackers, function(trackerData, countrycode) {
                                            if (countrycode == clientcountrycode) {
                                                trackersFound = true;
                                                $scope.trackerData = angular.copy(trackerData);
                                            }
                                        });
                                    } 
                                }
                            }
                        }
                    }
                }

                if (!trackersFound) {
                    $scope.noTrackersForCountry = true;
                }

                $scope.trackerDataLoaded = true;

            }, function(error) {
                console.log('Error:');
                console.log(errror);
                $scope.technicalError = true;
            });
        }, function(error) {
            console.log('Error:');
            console.log(errror);
            $scope.technicalError = true;
        });
    }

    $scope.getTrackerPriceFootnote = function() {
        priceDetails1 = "step1";
        console.log("vehicletrackersprices step1");
        $http.get(prefix + '/vehicletrackers/price/' + $rootScope.clientId).then(function(resp) {
            priceDetails1 = "step2";
            console.log("vehicletrackersprices step2");
            if (resp['data']) {
                priceDetails1 = "step3";
                console.log("vehicletrackersprices step3");
                if (resp.data['vehicletrackersprices']) {
                    priceDetails1 = resp.data['vehicletrackersprices'];  
                    priceDetails1 = "step4";  
                    console.log("vehicletrackersprices step4");
                }
            }
        }, function(error) {
            priceDetails1 = "error";  
            console.log("vehicletrackersprices error"+error);
        
        });
       
        $scope.priceDetails = priceDetails1;
    }

    $scope.chooseCard = function(card) {
        if (card) {
            // clunky hack that's required for $scope.chosenCard to properly update, otherwise if $scope.removeCard is called, the chosen card ID will be outdated
            $timeout(function() {
                $scope.chosenCard = card.id;
            }, 1);
        }
    };

    $scope.removeCard = function(evt, card) {
        evt.preventDefault();
            
        if (card) {
            $scope.shouldBeOpen = true;
            $rootScope.removeCardId = card.id;
            //alert("removeCard");
        }
    };
    
    $scope.removeCard1 = function(id) {
        
        //alert("removeCard123"+$rootScope.removeCardId );
        $scope.shouldBeOpen = false;
        $http.delete(prefix + '/client/' + $rootScope.clientId + '/creditcard/' + $rootScope.removeCardId).then(function(resp) {
            var cardDeleted = false;
            $scope.shouldBeOpen = false;

            if (resp) {
                if (resp['status'] == 204) {
                    var newCardList = [];

                    angular.forEach($scope.savedCards, function(savedcard, savedcardindex) {
                        okayToAdd = true;
                        if ($rootScope.removeCardId == savedcard['id']) {
                            okayToAdd = false;
                        }
                        if (okayToAdd) {
                            newCardList.push(savedcard);
                        }
                    }); 

                    $scope.savedCards = angular.copy(newCardList);

                    if ($scope.chosenCard == $rootScope.removeCardId) {
                        // if the deleted card was the active/selected card, then reset the selection to the first available card in the list
                        if ($scope.savedCards.length > 0) {
                            $scope.chosenCard = $scope.savedCards[0]['id'];
                        }
                        else {
                            $scope.chosenCard = false;
                        }
                    }
                    cardDeleted = true;
                }
            }
            if (!cardDeleted) {
                //$scope.technicalError = true;
            }
        }, function(error) {
                //$scope.technicalError = true;
                console.log("Error: ");
                console.log(error);
        });
    };

    $scope.getCardType = function(card) {
        try {
            var type = card['cardbrand'].toLowerCase();
            if (type == "american express") {
                type = "amex";
            }
            return 'fab fa-cc-' + type;
        } catch(err) {
            return 'far fa-credit-card'; 
        }
    }

    $scope.fetchDeliveryAddress = function() {
          $http.get(prefix + '/client/' + $rootScope.clientId + '/vehicletrackersdeliveryaddress').then(function(resp) {
                var deliveryAddressFound = false;

                if (resp['data']) {
                    if (resp.data['deliveryAddress']) {
                        deliveryAddressFound = true;

                        $scope.deliveryAddress = angular.copy(resp.data.deliveryAddress);
                    }
                }

                if (!deliveryAddressFound) {
                        $scope.deliveryAddress = {
                            'addressline1': '',
                            'addressline2': '',
                            'addressline3': '',
                            'town': '',
                            'county': '',
                            'postcode': '',
                            'country': '' 
                        };
                }

                /* Field placeholders */
                $scope.addressline1_placeholder = $translate('Address.Line1');
                $scope.addressline2_placeholder = $translate('Address.Line2');
                $scope.addressline3_placeholder = $translate('Address.Line3');
                $scope.town_placeholder = $translate('Town');
                $scope.county_placeholder = $translate('County');
                $scope.country_placeholder = $translate('Country');
                $scope.postcode_placeholder = $translate('Post.Code');

                purchaseTrackerService['deliveryAddress'] = angular.copy($scope.deliveryAddress);

                $scope.deliveryAddressLoaded = true;
          }, function(error) {
		if (error['data']) {
			if (error['data']['code'] == 404) {
				// In this case, there is no saved delivery address. Allow the user to enter a new one.
				
				$scope.deliveryAddress = {
				    'addressline1': '',
				    'addressline2': '',
				    'addressline3': '',
				    'town': '',
				    'county': '',
				    'postcode': '',
				    'country': '' 
				};

				/* Field placeholders */
				$scope.addressline1_placeholder = $translate('Address.Line1');
				$scope.addressline2_placeholder = $translate('Address.Line2');
				$scope.addressline3_placeholder = $translate('Address.Line3');
				$scope.town_placeholder = $translate('Town');
				$scope.county_placeholder = $translate('County');
				$scope.country_placeholder = $translate('Country');
				$scope.postcode_placeholder = $translate('Post.Code');

				purchaseTrackerService['deliveryAddress'] = angular.copy($scope.deliveryAddress);

				$scope.deliveryAddressLoaded = true;
			}
		}
		if (!$scope.deliveryAddressLoaded) {
			$scope.technicalError = true;
	    
			console.log('error: ');
			console.log(error);
		}
	  });
    };

    $scope.fetchSavedCards = function() {
        $http.get(prefix + '/client/' + $rootScope.clientId + '/creditcard?deleted=0').then(function(resp) {
            var creditCardsFound = false;

            if (resp['data']) {
                if (resp.data['creditcard']) {
                    $scope.savedCards = angular.copy(resp.data.creditcard);

                    if ($scope.savedCards instanceof Array) {
                        if ($scope.savedCards.length) {
                            $scope.chosenCard = $scope.savedCards[0]['id'];
                        }
                    }

                    creditCardsFound = true;
                }
            }

            if (!creditCardsFound) {
               $scope.savedCards = []; 
            }

            $scope.billingLoaded = true;
        });
    };

    $scope.$on('purchasetracker:creditcard:new', function(evt, card) {
        var cardAdded = false;

        $http(
            {'method': 'POST',
            'url':prefix + '/client/' + $rootScope.clientId + '/creditcard',
            'headers' : { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            'data': 
                    'creditcard_billing_name=' + encodeURIComponent(card.creditcard_billing_name) +
                    '&creditcard_number=' + encodeURIComponent(card.creditcard_number) + 
                    '&creditcard_cvc=' + encodeURIComponent(card.creditcard_cvc) +
                    '&creditcard_expirymonth=' + encodeURIComponent(card.creditcard_expirymonth) + 
                    '&creditcard_expiryyear=' + encodeURIComponent(card.creditcard_expiryyear) /*+*/ 
/*
                    '&creditcard_billing_address1=' + encodeURIComponent(card.creditcard_billing_address1) + 
                    '&creditcard_billing_address2=' + encodeURIComponent(card.creditcard_billing_address2) + 
                    '&creditcard_billing_town=' + encodeURIComponent(card.creditcard_billing_town) + 
                    '&creditcard_billing_county=' + encodeURIComponent(card.creditcard_billing_county) + 
                    '&creditcard_billing_country=' + encodeURIComponent(card.creditcard_billing_country) + 
                    '&creditcard_billing_postcode=' + encodeURIComponent(card.creditcard_billing_postcode) 
*/
            }
        ).then(function(resp) {
		$timeout(function() {
		    if (resp) {
			if (resp['data']) {
			    if (resp.data['creditcard']) {
				$scope.savedCards.push(resp.data.creditcard[0]);
				$scope.chosenCard = resp.data.creditcard[0].id;

                cardAdded = true;
                 
                //$modal.close(); 
                //$rootScope.close(); 
                card.modal.close();     
                $rootScope.$broadcast('purchasetracker_modal_closed');
			    } else {
                    console.log('error: 1');
                    $scope.addCardTechnicalError=true;
                    $scope.newCardNumber = false;   

                }
			} else {
                console.log('error: 2');
                $scope.newCardNumber = false;    
                $scope.addCardTechnicalError=true;
            }
		    }

		    if (!cardAdded) {
                //$scope.technicalError = true;
                console.log('error: 3');
                $scope.newCardNumber = false;
                $scope.addCardTechnicalError=true;
                if( resp.data['errors']['creditcard_number'] || resp.data['errors']['creditcard'] ) {
                    $rootScope.cardNumberInvalid=true;
                }
                if( resp.data['errors']['creditcard_cvc'] ) {
                    $rootScope.cardNumberCVCInvalid=true;
                }
			    console.log('error: ');
			    console.log(resp);
		    }
		}, 1);
        }, function(resp) {
            //$scope.technicalError = true;
            $scope.newCardNumber = false;
            $rootScope.cardNumberInvalid=true;
            $rootScope.saving = false;
            $scope.saving = false;
            console.log('error: 4');
            console.log('error: ');
            console.log(resp);
        });
    });

    $scope.hasPurchaseTrackersPermission = function() {
        return $rootScope.hasPermission(features['PurchaseTrackers'], 'updateaccess');
    };

    $scope.init = function() {
        if ($scope.hasPurchaseTrackersPermission()) {
            //$scope.getTrackerPriceFootnote();
            $scope.fetchTrackerData();
            $scope.fetchDeliveryAddress();
            $scope.fetchSavedCards();
        }
    };

    $scope.init();

}

function BillingCtrl($scope, $state, $http, $q, prefix, adminPrefix, datasets, formService, $rootScope, $timeout, $window, $filter) {
    $scope.invoices = [];
    $scope.invoicesLoaded = false;
    $scope.invoicesPage = 1;
    $scope.invoicesLimit = 10;
    $scope.invoicesCount = 0;
    $scope.noInvoices = false;
    $scope.searchTimer = false;
    $scope.query = '';
    $scope.noSearchResults = false;
    $scope.fetchingInvoices = false;
    $scope.searchMode = false;

    $scope.invoicesPagination = function(page) {
        if ($scope.searchMode) {
            $scope.searchInvoices(page);
        } else {
            $scope.getInvoices(page);
        }
    }

    $scope.getInvoices = function getInvoices(page) {
        if ($scope.fetchingInvoices) {
            return false;
        }
        if (page) {
            $scope.invoicesPage = page;
        }
        $scope.searchMode = false;
        $scope.fetchingInvoices = true;
        $http.get(prefix + '/clients/' + $rootScope.clientId + '/invoices?page=' + $scope.invoicesPage + '&limit=' + $scope.invoicesLimit).success(function (data) {
          angular.forEach(data.invoices, function(v, i) {
              data.invoices[i]['date'] = $filter('dateShortFormat')(data.invoices[i]['date']);
          });
          $scope.invoicesCount = data.count;
          $scope.invoices = angular.copy(data.invoices);
          if ($scope.invoices.length == 0) {
              $scope.noInvoices = true;
              $scope.noSearchResults = false;
          }
          $scope.invoicesLoaded = true;
          $scope.fetchingInvoices = false;
        }).error(function (data) {
          $scope.noInvoices = true;
          $scope.noSearchResults = false;
          $scope.invoicesLoaded = true;
          $scope.fetchingInvoices = false;
        });
    };

    $scope.printInvoice = function printInvoice(evt, invoice) {
        evt.preventDefault();
        evt.stopImmediatePropagation();
        var newWindow = window.open('about:blank', '_blank');
        $http.get(prefix + '/invoices/' + invoice.id).success(function (data) {
              newWindow.location.replace(data.invoice.invoicepdfurl + '/1');
        });
    };

    $scope.beginSearchingInvoices = function beginSearchingInvoices() {
        $timeout.cancel($scope.searchTimer);
        $scope.searchTimer = $timeout(function() {
            $scope.searchInvoices();
        }, 500);
    };

    $scope.searchInvoices = function searchInvoices(page) {
        if (page == undefined) {
            page = 1;
        }
        if ($scope.query.length > 0) {
          $scope.searchMode = true;
          $http.get(prefix + '/clients/' + $rootScope.clientId + '/invoices?invoiceid=' + $scope.query + '&page=' + page + '&limit=' + $scope.invoicesLimit).success(function(data) {
              angular.forEach(data.invoices, function(v, i) {
                  data.invoices[i]['date'] = moment(data.invoices[i]['date']).format('DD/MM/YYYY');
              });
              $scope.invoicesCount = data.count;
              $scope.invoices = data.invoices;
              if ($scope.invoices.length == 0) {
                  $scope.noSearchResults = true;
              }
              else {
                  $scope.noSearchResults = false;
              }
          }).error(function(data) {
              $scope.invoices = [];
              $scope.noSearchResults = true;
          });
        }
        else {
          $scope.searchMode = false;
          $scope.getInvoices();
          $scope.noSearchResults = false;
        }
    };

    $scope.getInvoices();
};

function UsersCtrl($scope, $state, $http, prefix, datasets, formService, $rootScope, $timeout, $window, skills_data,public_holiday_group, tableCollection) {
    $scope.data = datasets;
    $scope.skills_data = skills_data;
    $scope.public_holiday_group = public_holiday_group;
    $scope.showSubNavStatic = false;
    $scope.shouldBeOpenUser = false;
    $rootScope.flashSuccessMessage = '';

    $scope.$watch(tableCollection.collection.user_attached_files, function(newv, oldv) {
	if (newv !== oldv) {
		console.log('newv');
		console.log(newv);
		console.log('oldv');
		console.log(oldv);
	}
    });

    $scope.$state = $state;
    /* ========================================
     State Events
     ================================= */

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {

        if (toState.url === '/edit' || toState.url === '/new') {
            $scope.handleFormBreadcrumbs(toState.name);
            $scope.hideSubNav = true;
        } else {
            $scope.hideSubNav = false;
        }

        // When view loads get the return state url
        var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)$/);

        if (matches === null) {
            return;
        }

        $scope.returnState = matches[3];
        $scope.hideSubNavUserName = true;

        if ($scope.returnState === 'view.details') {
            $scope.returnState = 'User Details';
        }
        if (toParams.screen === 'reset') {
            $scope.returnState = 'Reset Password';
        }
        if (toParams.screen === 'configure_gps_device') {
            $scope.returnState = 'Configure vehicle tracker';
        }
        if ($scope.returnState === 'transfer_gps_device') {
            $scope.returnState = 'Transfer vehicle tracker';
        }
        if ($scope.returnState === 'edit') {
            $scope.returnState = 'Edit.' + $state.params.screen + '.details';
        }
        if ($scope.returnState === 'view.notes') {
            $scope.returnState = 'Notes';
        }

        if ($scope.returnState === 'view.new_note') {
            $scope.returnState = 'Notes';
            $scope.hideSubNavUserName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'New Note';
        }
        if ($scope.returnState === "view.edit_user_note") {
            $scope.returnState = 'Notes';
            $scope.formBreadcrumbStr = 'Edit Note';
            $scope.hideSubNav = true;
            $scope.hideSubNavUserName = true;
        }
        if ($scope.returnState === 'view.mobile') {
            $scope.returnState = 'Mobile Settings';
        }
        if ($scope.returnState === 'view.contacts') {
            $scope.returnState = 'Emergency Contacts';
        }
        if ($scope.returnState === 'view.new_emergency_contact') {
            $scope.returnState = 'Emergency Contacts';
            $scope.hideSubNavUserName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'New Emergency Contacts';
        }
        if ($scope.returnState === 'view.edit_emergency_contact') {
            $scope.returnState = 'Emergency Contacts';
            $scope.hideSubNavUserName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'Edit Emergency Contacts';
        }
        if ($scope.returnState === 'view.files') {
            $scope.returnState = 'Files';
        }
        if ($scope.returnState === 'view.new_file') {
            $scope.returnState = 'Files';
            $scope.hideSubNavUserName = true;
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'New File';
        }
        if ($scope.returnState === 'view.reminders') {
            $scope.returnState = 'Reminders';
        }
        if ($scope.returnState === 'view.new_users_reminder') {
            $scope.returnState = 'Reminders';
            $scope.hideSubNavUserName = true;
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = "New Reminder";
        }
        if ($scope.returnState === 'view.edit_user_reminder') {
            $scope.returnState = 'Reminders';
            $scope.hideSubNavUserName = true;
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = "Edit Reminder";
        }
    });

    $scope.handleSpecialCases = function handleSpecialCases(state) {
        $scope.hideSubNav = true;
        var matches = state.match(/^(.*?)\.(.*?)\.(.*?)$/);
        $scope.formBreadcrumbStr = $scope.formatStr(matches[3]);
    }

    /* ========================================
     Replaces underscores with spaces and then capitalises each word
     ========================================*/
    $scope.formatStr = function formatStr(str) {
        var formatted_str = str.split('_');
        for (var i = 0; i < formatted_str.length; i++) {
            formatted_str[i] = formatted_str[i].charAt(0).toUpperCase() + formatted_str[i].slice(1);
        }
        return formatted_str.join(' ');
    }

    $scope.getBack = function getBack() {
        if ($scope.returnState == 'User Details') {
            $scope.userViewDetails($scope.selectedId);
        }
        if ($scope.returnState == 'Notes') {
            $scope.notes($scope.selectedId);
        }
        if ($scope.returnState == 'Emergency Contacts') {
            $scope.emergencyContacts($scope.selectedId);
        }
        if ($scope.returnState == 'Files') {
            $scope.attachedFiles($scope.selectedId);
        }
        if ($scope.returnState == 'Reminders') {
            $scope.userReminders($scope.selectedId);
        }
    }
    $scope.$on('tabUser:selected', function (event, data) {
        if (data != undefined && data != '') {
            $scope.hideSubNavUsers = true;
            $scope.selectedId = data.id;
            $scope.selectedUserName = data.username;
            $scope.selectedFirstName = data.name;
            $scope.selectedLastName = data.surname;
            $scope.deleted = data.deleted;
            $scope.emergencyContactsCount = data.emergencyContactsCount;
            $scope.userRemindersCount = data.userRemindersCount;
            $scope.userNoteCount = data.userNoteCount;
            $scope.filesCount = data.filesCount;
            $scope.userProfileImage = data.userprofileimage;
            $scope.selectedTab = data.selectedTab;
            $scope.showSubNavStatic = data.showSubNavStatic;
        } else {
            $scope.hideSubNavUsers = false;
            $scope.formBreadcrumbStr = '';
            $scope.returnState = '';
            $scope.selectedId = '';
            $scope.selectedUserName = '';
            $scope.selectedFirstName = '';
            $scope.selectedLastName = '';
            $scope.emergencyContactsCount = 0;
            $scope.userRemindersCount = 0;
            $scope.userNoteCount = 0;
            $scope.filesCount = 0;
            $scope.userProfileImage = '';
        }
    });

    $scope.$on('tabUser:successMessage', function (event, data) {
        $rootScope.flashSuccessMessage = data;
        $timeout(function () {
            $("#usersSaveMessage").fadeIn();
            $("#usersSaveMessage").fadeOut(4000);
            $("#saveMessage").show();
        });
        $scope.close = function () {
            $scope.flashSuccessMessage = '';
        }
    });
    $scope.$on('DeletedUser', function (event, data) {
        $window.location.reload();
    });
    $scope.editDetails = function (screen, selectedId) {
        $scope.selectedId = selectedId;

        switch(screen){
            case "transfer_gps_device": {
                transitToEditScreen({}, {});
                break;
            }
            default: {
                var url = prefix + '/get_user_details?id=' + selectedId;

                if(screen === 'configure_gps_device') url += '&gps_device_data=true';

                $http.get(url).success(function (data) {
                    transitToEditScreen(data, $scope.skills_data, $scope.public_holiday_group);
                });

                break;
            }
        }

        // Redirecting to edit screen
        function transitToEditScreen(userDetails, skillsData,public_holiday_group)  {
            $state.transitionTo('loggedin.users.edit', {'screen': screen, 'userDetails': angular.toJson(userDetails), 'skills_data': angular.toJson(skillsData),'public_holiday_group':angular.toJson(public_holiday_group)});
        }
    }
    $scope.userViewDetails = function (id) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.users.view.details', {'id': id});
    }
    $scope.emergencyContacts = function (id) {
        $state.transitionTo('loggedin.users.view.contacts', {'id': id});
    }
    $scope.notes = function (id) {
        $state.transitionTo('loggedin.users.view.notes', {'id': id});
    }
    $scope.attachedFiles = function (id) {
        $state.transitionTo('loggedin.users.view.files', {'id': id});
    }
    $scope.userReminders = function (id) {
        $state.transitionTo('loggedin.users.view.reminders', {'id': id});
    }
}

function UsersListCtrl($scope, $state, prefix, $http, usersresolver, defaultPagingValue, canLoad, $q, $rootScope, warningModal, $modal, $translate) {
    $scope.users = usersresolver.users;
    $scope.totalUsers = usersresolver.count;
    $scope.showWarning = usersresolver.showWarning;
    $scope.showModal = usersresolver.showModal;
    $scope.showActiveWarning = usersresolver.showActiveWarning;
    $scope.$emit('tabUser:selected', '');
    $scope.ascending = false;
    $scope.descending = true;
    $scope.mobileascending = false;
    $scope.mobiledescending = true;
    $scope.name = '';
    $scope.usersTab = parseInt($state.current.data.usersTab);
    if ($rootScope.selection != '') {
        $scope.selectedUser = $rootScope.selection.userObject.id;
    }

    $rootScope.$on('event:moveUp', function () {
        $scope.changeUser('up');
    });

    $rootScope.$on('event:moveDown', function () {
        $scope.changeUser('down');
    });

    //TODO: need to make this genaralized like JustAddedUpdatedChanges function
    $scope.changeUser = function (event) {
        var changed = false;
        angular.forEach($scope.users, function (value, index) {
            if (!changed) {
                if ($scope.users[index].id == $scope.selectedUser) {
                    var userObj = '';
                    if (event == 'up') {
                        if (index != 0 && $scope.users[index - 1]) {
                            userObj = $scope.users[index - 1];
                        } else {
                            var length = $scope.users.length;
                            userObj = $scope.users[length - 1];
                        }
                    } else {
                        if ($scope.users[index + 1]) {
                            userObj = $scope.users[index + 1];
                        } else {
                            userObj = $scope.users[0];
                        }
                    }
                    $scope.selectedUser = userObj.id;
                    $rootScope.selection = {userObject: userObj};
                    changed = true;
                }
            }
        });
    }

    $scope.selectUser = function (userObj) {
        $scope.selectedUser = userObj.id;
        $rootScope.selection = {userObject: userObj};
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.currentPage = 1;
            $scope.fetchUsers($scope.currentPage, $scope.usersTab);
        }
    });

    var canceler = null;
    $scope.fetchUsers = function (pageNum, usersTab) {
        $scope.usersTab = usersTab;
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        if (searchText) {
            $scope.closeDelt = false;
        }
        else {
            $scope.closeDelt = true;
        }

        $http.get(prefix + '/listUsers?order=' + $scope.name + '&page=' + pageNum + '&limit=' + $scope.limit + '&selectedTab=' + $scope.usersTab + '&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.users = data.users;
            $scope.totalUsers = data.count;
            $scope.usersTab = $scope.usersTab;
            if (searchText != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    $scope.validationMessage = $translate('CONFIRM.to.add.new.user');
    $scope.title = $translate('Add.new.user');
    $scope.textToConfirm = 'CONFIRM';

    $scope.getAddUserValidation = function () {
        $http.get(prefix + '/get_adduservalidation').success(function (data) {
            $scope.licenseDetails = data;

            if ($scope.licenseDetails['renewal_addition_validation_require'] == 'yes') {
                $scope.licenceTitle = $translate("unable.create.user");
                $scope.licenceMessage = $translate("contract.renewal.licence");
                warningModal.show($scope.licenceMessage,$scope.licenceTitle, "CONFIRM_" + Date.now()); 
            } else if ($scope.licenseDetails['showWarning']) {
                warningModal.show($scope.licenseDetails.message, 'Warning', "CONFIRM_" + Date.now());
            } else if ($scope.licenseDetails.showModal) {
                $modal.open({
                    templateUrl: 'template/user_confirmation_modal.html',
                    scope: $scope,
                    controller: HandleUserConfirmationModal
                })
            } else {
                $scope.saving = true;
                $state.transitionTo("loggedin.add_user");
            }
            
        })
    };
    $scope.orderChange = function (ordering, usersTab) {
        $scope.name = '';
        $scope.usersTab = usersTab;
        $http.get(prefix + '/listUsers?order=' + $scope.name + '&page=' + $scope.currentPage + '&limit=' + $scope.limit + '&selectedTab=' + $scope.usersTab).success(function (data) {
            $scope.users = data.users;
            $scope.totalUsers = data.count;
            if ($scope.totalUsers == 0) {
                var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
                if (searchText != '') {
                    $scope.searchResult = true;
                } else {
                    $scope.searchResult = false;
                }
            }
        })
    }
}
function HandleUserConfirmationModal($scope, $modalInstance, $state, prefix, $http, toastBox) {
    $scope = $scope.$parent;
    $scope.confirmDeleteValidity = true;
    $scope.modalupdating = false;
    $scope.closebutton = true;
    $scope.deletebutton = true;
    $scope.confirmText = '';

    $scope.confirmation = function () {
        if (this.confirmText.toLowerCase() == $scope.textToConfirm.toLowerCase()) {
            $scope.modalupdating = true;
            $scope.current = this;
            $scope.closebutton = false;
            $scope.deletebutton = false;
            $modalInstance.close();
            $state.transitionTo("loggedin.add_user");
        } else {
            $scope.confirmDeleteValidity = false;
            $scope.updating = false;
        }
    }

    $scope.confirmSendaWorkOrder = function confirmSendaWorkOrder() {
        license_required = "1";
        license_type = "CL";
        if($scope.salesOnlyClient){
            application_type = "SALES";
            application_id = "3";
        } else {
            application_type = "JMS";
            application_id = "4";
        }

        $http.post(prefix + '/postPurchaseLicenses', "license_required=" + encodeURIComponent(license_required)+ "&license_type=" + encodeURIComponent(license_type) + "&application_type=" + encodeURIComponent(application_type) + "&application_id=" + encodeURIComponent(application_id)).
            success(function (data, status) {
                if (status == 200) {
                    toastBox.show('The contract has been sent successfully via email. Please sign the contract from the email to activate licenses.', 3000);
                } else {
                    toastBox.show('We are having some issues sending you the contract. Please try again after sometime.', 3000);
                }
            }).error(function (err) {
                toastBox.show('We are having some issues sending you the contract. Please try again after sometime.', 3000);
                console.log(err);
            });
        $modalInstance.close();
    }

    $scope.close = function () {
        $modalInstance.close();
    }
}
function AddUserCtrl($scope, $stateParams, $http, $state, datasets, prefix, $rootScope, formService, $timeout, skills_data,public_holiday_group, warningModal) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.shouldBeOpen = false;
    $scope.addressArray = [];
    $scope.error = false;
    $scope.errorMessage = '';
    $scope.skills_data = skills_data;
    $scope.public_holiday_group = public_holiday_group;
    $scope.license_info = '';
    $scope.roleSelected = false;
    $scope.selectedAccess = '';
    $scope.roleRequired = 1;
    $scope.roleCount = 0;


    PublicHolidayGroupManager.call(this, $scope, $scope.public_holiday_group);

    PostCodeCtrl.call(this, $scope, $state, $http, prefix);

    SkillsManager.call(this, $scope, $scope.skills_data);

    $scope.accessChanged = function(){
        $scope.roleSelected = ($scope.selectedAccess != '') ? true : false;
        if($scope.roleSelected){
            $scope.roleCount = 1;
        } else {
            $scope.roleCount = 0;
        }
    }

    $scope.skillsChanged = function() {
        var skills = '';
        if($scope.selectedSkills && $scope.selectedSkills.length) {
            angular.forEach($scope.selectedSkills, function (val, key) {
                var concat = (skills != '' ? "," : "");
                skills += concat + val.id;
            });
        }
        // Todo: Need to fix this, as this is coming from twig, I'm using JQuery to set value
        $('#selectedSkill').val(skills);
    }

    $scope.selectedPublicholidayGroupsChanged = function() {
        var publicholidayGroups = '';
        if($scope.selectedPublicholidayGroups && $scope.selectedPublicholidayGroups.length) {
            angular.forEach($scope.selectedPublicholidayGroups, function (val, key) {
                var concat = (publicholidayGroups != '' ? "," : "");
                publicholidayGroups += concat + val.id;
            });
        }
        // Todo: Need to fix this, as this is coming from twig, I'm using JQuery to set value
        $('#selectedPublicholidayGroups').val(publicholidayGroups);
    }

    $scope.licenseChanged = function (type, roles) {
        var selectedLicense, restrictedRoles, licenseRoles;
        if(type === 'license_info') {
            if ($scope.license_info) {
                selectedLicense = $("select[name='license_info[JMS]'] option:selected"). val();
                restrictedRoles = roles[selectedLicense];
                if(!restrictedRoles.includes(parseInt($scope.jmsroleid))) {
                    $scope.jmsroleid = '';
                }
                licenseRoles = $('#newuser_jmsroleid').find('option');
                for(i=0;i<licenseRoles.length;i++){
                    if(restrictedRoles && !restrictedRoles.includes(parseInt(licenseRoles[i]['value']))){
                        $(licenseRoles[i]).hide();
                    } else {
                        $(licenseRoles[i]).show();
                    }
                }
                $('#newuser_jmsroleid').attr('disabled', false);
                $('#newuser_jmsroleid').attr('required', true);
                $scope.roleSelected = false;
            }
            else {
                $('#newuser_jmsroleid').attr('disabled', true);
                $('#newuser_jmsroleid').attr('required', false);
                $scope.jmsroleid = '';
                $scope.roleSelected = false;
            }
        }
        else if(type === 'license_info_chat') {
            if ($scope.license_info_chat) {
                selectedLicense = $("select[name='license_info[Chat]'] option:selected"). val();
                restrictedRoles = roles[selectedLicense];
                if(!restrictedRoles.includes(parseInt($scope.chatroleid))) {
                    $scope.chatroleid = '';
                }
                licenseRoles = $('#newuser_chatroleid').find('option');
                for(i=0;i<licenseRoles.length;i++){
                    if(restrictedRoles && !restrictedRoles.includes(parseInt(licenseRoles[i]['value']))){
                        $(licenseRoles[i]).hide();
                    } else {
                        $(licenseRoles[i]).show();
                    }
                }
                $('#newuser_chatroleid').prop('disabled', true);
                $('#newuser_chatroleid').prop('required', false);
                $scope.roleSelected = false;
            }else{
                $('#newuser_chatroleid').prop('disabled', true);
                $('#newuser_chatroleid').prop('required', false);
                $scope.chatroleid = '';
                $scope.roleSelected = false;
            }
        }
        else if(type === 'license_info_sales') {
            if ($scope.license_info_sales) {
                selectedLicense = $("select[name='license_info[SALES]'] option:selected"). val();
                restrictedRoles = roles[selectedLicense];
                if(!restrictedRoles.includes(parseInt($scope.salesroleid))) {
                    $scope.salesroleid = '';
                }
                licenseRoles = $('#newuser_salesroleid').find('option');
                for(i=0;i<licenseRoles.length;i++){
                    if(restrictedRoles && !restrictedRoles.includes(parseInt(licenseRoles[i]['value']))){
                        $(licenseRoles[i]).hide();
                    } else {
                        $(licenseRoles[i]).show();
                    }
                }
                $('#newuser_salesroleid').prop('disabled', false);
                $('#newuser_salesroleid').prop('required', true);
                $scope.roleSelected = false;
            }else{
                $('#newuser_salesroleid').prop('disabled', true);
                $('#newuser_salesroleid').prop('required', false);
                $scope.salesroleid = '';
                $scope.roleSelected = false;
            }
        }

       /* if ($scope.license_info) {
            $('#newuser_jmsroleid').attr('disabled', false);
            $('#newuser_jmsroleid').attr('required', true);
            $scope.roleSelected = false;
        }else{
            $('#newuser_jmsroleid').attr('disabled', true);
            $('#newuser_jmsroleid').attr('required', false);
            $scope.jmsroleid = '';
            $scope.roleSelected = false;
        }*/

       /* if ($scope.license_info_chat) {
            $('#newuser_chatroleid').attr('disabled', true);
            $('#newuser_chatroleid').attr('required', false);
            $scope.roleSelected = false;
            $scope.chatroleid = '';
        }else{
            $('#newuser_chatroleid').attr('disabled', true);
            $('#newuser_chatroleid').attr('required', false);
            $scope.chatroleid = '';
            $scope.roleSelected = false;
        }*/

        /*if ($scope.license_info_sales) {
            $('#newuser_salesroleid').attr('disabled', false);
            $('#newuser_salesroleid').attr('required', true);
            $scope.roleSelected = false;
        }else{
            $('#newuser_salesroleid').attr('disabled', true);
            $('#newuser_salesroleid').attr('required', false);
            $scope.salesroleid = '';
            $scope.roleSelected = false;
        }*/
        if($scope.license_info && $scope.license_info_sales) {
            $scope.roleRequired = 2;
        } else {
            $scope.roleRequired = 1;
        }
        if($scope.salesroleid && $scope.jmsroleid) {
            $scope.roleCount = 2;
        } else if($scope.salesroleid || $scope.jmsroleid ){
            $scope.roleCount = 1;
        } else {
            $scope.roleCount = 0;
        }
    }

    $scope.jmsRoleChanged = function () {
        if ($scope.license_info) {
            $scope.jmsRoleSelected = ($('#newuser_jmsroleid').val() != '') ? true : false;
        }
        if (($scope.license_info && $scope.jmsRoleSelected)) {
            $scope.roleSelected = true;
        } else {
            $scope.roleSelected = false;
        }
        if($scope.salesroleid && $scope.jmsroleid) {
            $scope.roleCount = 2;
        } else if($scope.salesroleid || $scope.jmsroleid ){
            $scope.roleCount = 1;
        } else {
            $scope.roleCount = 0;
        }
    }
    $scope.chatRoleChanged = function () {
        if ($scope.license_info_chat) {
            $scope.chatRoleSelected = ($('#newuser_chatroleid').val() != '') ? true : false;
        }
        if ($scope.license_info_chat && $scope.chatRoleSelected) {
            $scope.roleSelected = true;
        } else {
            $scope.roleSelected = false;
        }
    }
    $scope.salesRoleChanged = function () {
        if ($scope.license_info_sales) {
            $scope.salesRoleSelected = ($('#newuser_salesroleid').val() != '') ? true : false;
        }
        if ($scope.license_info_sales && $scope.salesRoleSelected) {
            $scope.roleSelected = true;
        } else {
            $scope.roleSelected = false;
        }
        if($scope.salesroleid && $scope.jmsroleid) {
            $scope.roleCount = 2;
        } else if($scope.salesroleid || $scope.jmsroleid ){
            $scope.roleCount = 1;
        } else {
            $scope.roleCount = 0;
        }
    }

    $scope.performFormSubmit = function (url, formElem) {
        $http.get(prefix + '/get_adduservalidation').success(function (data) {
            $scope.licenseDetails = data;
            if ($scope.licenseDetails['showWarning']) {
                warningModal.show($scope.licenseDetails.message, 'Warning', "CONFIRM_" + Date.now());
                $state.transitionTo("loggedin.users");
            }else{
                formService.partialPageSubmit(url, formElem).then(function (data) {
                    if (data.error && data.error == 'Exists') {
                        $scope.newUserForm['newuser[username]'].$error.checkExists = true;
                    } else {
                        $rootScope.flashMessage = 'New User has been Saved';
                        $state.transitionTo('loggedin.users.view.details', {'id': data.id});
                    }
                    $scope.saving = false;
                });
            }
        });
    }
}
function UsersSubNavCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal) {
    var data = getIdData;
    $scope.selectedId = data.id;
    $scope.canDelete = getIdData.canDelete;
    $scope.deleteWarningMessage = getIdData.deleteWarningMessage;
    $scope.normaleventCancelMessage = getIdData.normaleventCancelMessage;
    $scope.hasGpsDevice = getIdData.hasGpsDevice;


    $scope.$on('tabUser:selected', function (event, data) {
        if (data != undefined && data != '') {
            $scope.selectedId = data.id;
            $scope.selectedUserName = data.username;
            $scope.selectedFirstName = data.name;
            $scope.selectedLastName = data.surname;
            $scope.deleted = data.deleted;
            $scope.emergencyContactsCount = data.emergencyContactsCount;
            $scope.userRemindersCount = data.userRemindersCount;
            $scope.userNoteCount = data.userNoteCount;
            $scope.filesCount = data.filesCount;
            $scope.userProfileImage = data.userprofileimage;
            $scope.selectedTab = data.selectedTab;
            $scope.showSubNavStatic = data.showSubNavStatic;
        }
    });
    DeleteUserCtrl.call(this, $scope, warningModal);
}

function ConfigureUserDetailsCtrl($scope, $state, getIdData, $http, prefix, colorSelector, $rootScope, warningModal) {
    $scope.selectedId = $state.params.id;
    $scope.userDetails = getIdData;
    getIdData.showSubNavStatic = false;
    getIdData.selectedTab = 'userDetails';
    $scope.$emit('tabUser:selected', getIdData);
    $scope.branches = [];
    $scope.groups = [];
    $scope.skills = _.pluck($scope.userDetails.skills, 'text').join(',');
    $scope.publicHolidayGroups = _.pluck($scope.userDetails.publicHolidayGroups, 'text').join(',');
    $scope.industries = getIdData.industries.map(function (industry) {
        return industry['industriesID'];
    })

    $scope.diarycolor = "";
    if ($scope.userDetails.colourondiary) {
        var all_colors = colorSelector.getColors('diary_color');
        for(var i = 0, l = all_colors.length; i < l; i++) {
            if (all_colors[i].color === $scope.userDetails.colourondiary) {
                $scope.diarycolor = all_colors[i].rgba;
            }
        }
    }

    if (!$scope.selectedTab) {
        $scope.selectedTab = 'userDetails';
    }

    if ($scope.selectedTab == 'userDetails') {
        $http.get(prefix + '/get_branch_group?id='+$scope.selectedId).success(function (data) {
            $scope.branches = data.branches;
            $scope.groups = data.groups;

        })
    }
    if ($rootScope.flashMessage) {
        $scope.$emit('tabUser:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
    DeleteUserCtrl.call(this, $scope, warningModal);
}

function EditDetailsCtrl($scope, $stateParams, $location, $http, $state, datasets, prefix, $rootScope, formService, $timeout, clickEvents, toastBox, adminPrefix) {
    $scope.selectedScreen = $state.params.screen;
    $scope.userDetails = angular.fromJson($state.params.userDetails);
    $scope.skills_data = angular.fromJson($state.params.skills_data);
    $scope.public_holiday_group = angular.fromJson($state.params.public_holiday_group);
    $scope.selectedId = $scope.userDetails.id;
    $scope.availableLicense = $scope.userDetails.availableLicense;
    $scope.taglineTextAreaEditing = false;

    if ($scope.selectedScreen == 'login') {

        $scope.returnState = 'User Details';

        $scope.selectedUsername = $scope.userDetails.username;
        $scope.selectedStatus = $scope.userDetails.status;
        $scope.selectedUserAccess = $scope.userDetails.useraccess.id;
        $scope.currentUserIsEdited = ($scope.selectedId == $rootScope.userId);
        $scope.licenseModule = $scope.userDetails.licenseModule;

        $http.get(prefix + '/get_user_access').success(function (data) {
            $scope.loginAccess = data.roles;
        })

        $scope.editLogin = function ($event, formStatus) {
            $event.preventDefault();
            if (!formStatus) {
                return;
            }
            $scope.updating = true;
            $scope.current = this;
            $http.post(prefix + '/edit_login', "users[id]=" + this.selectedId + "&user[username]=" + encodeURIComponent(this.selectedUsername) + "&user[status]=" + encodeURIComponent(this.selectedStatus) + "&user[useraccess]=" + encodeURIComponent((this.editLoginForm.selectedUserAccess !== undefined) ? this.editLoginForm.selectedUserAccess.$modelValue : $scope.selectedUserAccess)).
                success(function (data, status) {
                    if (status == 200) {

                        if (data.error && data.error == 'Exists') {
                            $scope.current.editLoginForm['selectedUsername'].$error.checkExists = true;
                        } else {
                            $scope.userViewDetails($scope.selectedId);
                            $rootScope.flashMessage = data;
                        }
                        $scope.updating = false;
                    }
                });
        }
    }

    if ($scope.selectedScreen == 'personal') {
        $scope.showPostCodeLookUp = $scope.userDetails.showPostCodeLookUp;
        $scope.selectedName = $scope.userDetails.name;
        $scope.selectedSurname = $scope.userDetails.surname;
        $scope.selectedEmail = $scope.userDetails.email;
        $scope.nationalInsurance = $scope.userDetails.nationalinsurance;
        $scope.selectedTelephone = $scope.userDetails.telephone;
        $scope.telephone_code = parseInt($scope.userDetails.telephone_code);
        $scope.selectedMobile = $scope.userDetails.mobile;
        $scope.mobile_code = parseInt($scope.userDetails.mobile_code);
        $scope.selectedAddress1 = $scope.userDetails.address1;
        $scope.selectedAddress2 = $scope.userDetails.address2;
        $scope.selectedTown = $scope.userDetails.town;
        $scope.selectedCountry = $scope.userDetails.country;
        $scope.postcode = $scope.userDetails.postcode;
        $scope.imageProfile = $scope.userDetails.profile;
        $scope.imageSignature = $scope.userDetails.signature;
        $scope.tagline = $scope.userDetails.tagline;
        $scope.shouldBeOpenPost = false;
        $scope.shouldBeOpen = false;
        $scope.addressArray = [];
        $scope.error = false;
        $scope.errorMessage = '';
        //$scope.imgOptions = { orgHeight: 500, orgWidth: 500, reqHeight: 100, reqWidth: 100, ratio: '1:1' };
        $scope.openResizeModal = false;
        $scope.imageResizeOptions = { orgHeight: 500, orgWidth: 500, reqHeight: 100, reqWidth: 100, ratio: '1:1' };

        $scope.imageResizeSave = function (resp) {
            var scopeName = 'image' + $scope.selectedSection;
            console.log(scopeName);
            $scope[scopeName] = resp;
            $scope.openResizeModal = false;
        }

        $scope.$on('event:image_resize_modal_closed', function(){
            $scope.openResizeModal = false;
        });

        $scope.onUploadSuccess = function (resp) {
            if (!resp.error) {
                $scope.profilePicture = resp.message.file;
                $scope.displayError = false;
                $scope.imageResizeOptions.orgHeight = resp.message.height;
                $scope.imageResizeOptions.orgWidth = resp.message.width;
                $scope.openResizeModal = true;
            } else {
                $scope.errorMessage = resp.message;
                $scope.displayError = true;
                $scope.displaySuccess = false;
            }
        }

        $scope.changeImage = function (section) {
            if (section == 'Profile') {
                $scope.imageResizeOptions.reqHeight = $scope.imageResizeOptions.reqWidth = 100;
                $scope.imageResizeOptions.ratio = '1:1';
            }
            if (section == 'Signature') {
                $scope.imageResizeOptions.reqHeight = 40
                $scope.imageResizeOptions.reqWidth = 250;
                $scope.imageResizeOptions.ratio = '25:4';
            }
            $scope.selectedSection = section;
        }

        $scope.updatePostcode = function () {
            $scope.postcode = this.postcode;
        }

        $scope.$on('smartpostcode:address', function (evt, data) {
            $scope.selectedAddress1 = data.addressLine1;
            $scope.selectedAddress2 = data.addressLine2;
            $scope.selectedTown = data.town;
            $scope.selectedCountry = data.county;
            $scope.postcode = data.postcode;
        });

        $scope.editPersonal = function ($event, formStatus) {
            if (!this.taglineTextAreaEditing) { $event.preventDefault(); }
            if (!formStatus || this.taglineTextAreaEditing) {
                return;
            }
            $scope.updating = true;
            $scope.current = this;

            $http.post(prefix + '/edit_personal', "users[id]=" + this.selectedId
                    + "&user[name]=" + encodeURIComponent(this.selectedName)
                    + "&user[surname]=" + encodeURIComponent(this.selectedSurname)
                    + "&user[profileimage]=" + encodeURIComponent(this.imageProfile)
                    + "&user[email]=" + encodeURIComponent(this.selectedEmail)
                    + "&user[nationalInsurance]=" + encodeURIComponent(this.nationalInsurance)
                    + "&user[telephone]=" + encodeURIComponent(this.selectedTelephone)
                    + "&user[telephone_code]=" + parseInt(this.telephone_code)
                    + "&user[mobile_code]=" + parseInt(this.mobile_code)
                    + "&user[mobile]=" + encodeURIComponent(this.selectedMobile)
                    + "&user[address1]=" + encodeURIComponent(this.selectedAddress1)
                    + "&user[address2]=" + encodeURIComponent(this.selectedAddress2)
                    + "&user[town]=" + encodeURIComponent(this.selectedTown)
                    + "&user[country]=" + encodeURIComponent(this.selectedCountry)
                    + "&user[postcode]=" + encodeURIComponent(this.postcode)
                    + "&user[tagline]=" + encodeURIComponent(this.tagline)
                    + "&user[signature]=" + encodeURIComponent(this.imageSignature)).
                success(function (data, status) {
                    if (status == 200) {

                        if (data.error && data.error == 'Exists') {
                            $scope.current.editPersonalForm['selectedEmail'].$error.checkExists = true;
                        } else {
                            $scope.$emit('REFRESH_PROFILE_PIC');
                            $scope.userViewDetails($scope.selectedId);
                            $rootScope.flashMessage = data;
                        }
                        $scope.updating = false;
                    }
                });
        }
    }

    if ($scope.selectedScreen == 'business') {
        $scope.hasUserGroups = $scope.userDetails.hasUserGroups;
        $scope.selectedBranch = $scope.userDetails.branch;
        $scope.selectedUsergroup = $scope.userDetails.usergroup;
        $scope.selectedAssociatedgroup = $scope.userDetails.associatedgroup;
        $scope.selectedCostrate = $scope.userDetails.costrate;
        $scope.selectedSalerate = $scope.userDetails.salerate;
        $scope.stockPermission = $scope.userDetails.stockLocationPermission;
        $scope.selectedStockLocation = parseInt($scope.userDetails.selectedStockLocationId)?parseInt($scope.userDetails.selectedStockLocationId):'';
        $scope.employmentTypes = $scope.userDetails.employmenttypes;
        $scope.selectedSkills = $scope.userDetails.skills;
        $scope.selectedPublicHolidaygroups = $scope.userDetails.publicHolidayGroups;
        $scope.selectedShift = parseInt($scope.userDetails.selectedShitId)?parseInt($scope.userDetails.selectedShitId):'';
        SkillsManager.call(this, $scope, $scope.skills_data);
        PublicHolidayGroupManager.call(this, $scope, $scope.public_holiday_group);

        if ($scope.userDetails.appearondiary == true) {
            $scope.selectedAppearOnDiary = 1;
        }
        else {
            $scope.selectedAppearOnDiary = 0;
        }

        $scope.stockLocation = function(locationVal){
            $scope.selectedStockLocation = locationVal;
        }


        $scope.changeUserGroup = function(usergroup){
            $scope.selectedUsergroup = usergroup;
        }

        $scope.changeAssociatedGroup = function(associatedGroupVal){
            $scope.selectedAssociatedgroup = associatedGroupVal;
        }

        $scope.shift = function(locationVal){
            $scope.selectedShift = locationVal;
        }

        $scope.selectedEmploymentType = $scope.userDetails.employmenttype;

        if ($scope.userDetails.datejoined != null) {
            $scope.joinedDate = $scope.selectedDate = moment($scope.userDetails.datejoined.date);
        }
        else {
            $scope.joinedDate = $scope.selectedDate = '';
        }

        $rootScope.$on("datepicker_selected", function (event, message) {
            $scope.selectedDate = message.date;
        });


        $scope.branches = [];
        $scope.groups = [];
        $scope.stockLocations = [];
        $scope.shifts = [];

        $scope.editcolorValue = $scope.userDetails.colourondiary;
        $scope.colorValueHide = true;

        $http.get(prefix + '/get_branch_group?id='+ $scope.selectedId).success(function (data) {
            $scope.branches = data.branches;
            $scope.groups = data.groups;
            $scope.stockLocations = data.stockLocations;
            $scope.shifts = data.shifts;
        });

        /*$http.get(prefix + '/get_stock_location').success(function (data) {
            $scope.stockLocations = data.stockLocations;;
        });*/

        $rootScope.$on('colorpicker:diary_color', function(e, color) {
            $scope.colorValue = color.color;
        });

        $scope.editBusiness = function ($event, formStatus) {
            $event.preventDefault();
            if (!formStatus) {
                return;
            }
            var skills = '';
            if(this.selectedSkills && this.selectedSkills.length) {
                angular.forEach(this.selectedSkills, function (val, key) {
                    var concat = (skills != '' ? "," : "");
                    skills += concat + val.id;
                });
            }
            var publicHolidayGroups = '';
            if(this.selectedPublicHolidaygroups && this.selectedPublicHolidaygroups.length) {
                angular.forEach(this.selectedPublicHolidaygroups, function (val, key) {
                    var concat = (publicHolidayGroups != '' ? "," : "");
                    publicHolidayGroups += concat + val.id;
                });
            }
            $scope.updating = true;
            $scope.current = this;
            if (this.selectedDate == '') {
                var editDate = '';
            } else {
                var editDate = moment(this.selectedDate,'L').format("YYYY-MM-DD");
            }

            $http.post(prefix + '/edit_business', "users[id]=" + this.selectedId
                    + "&user[branch]=" + encodeURIComponent(this.selectedBranch)
                    + "&user[usergroup]=" + encodeURIComponent(this.selectedUsergroup)
                    + "&user[appearondiary]=" + encodeURIComponent(this.selectedAppearOnDiary)
                    + "&user[colourondiary]=" + encodeURIComponent(this.colorValue)
                    + "&user[associatedgroup]=" + encodeURIComponent(this.selectedAssociatedgroup)
                    + "&user[costrate]=" + encodeURIComponent(this.selectedCostrate)
                    + "&user[salerate]=" + encodeURIComponent(this.selectedSalerate)
                    + "&user[employmenttype]=" + encodeURIComponent(this.selectedEmploymentType)
                    + "&user[stocklocation]=" + this.selectedStockLocation
                    + "&user[shift]=" + this.selectedShift
                    + "&user[skills]=" + skills
                    + "&user[publicHolidayGroups]=" + publicHolidayGroups
                + "&user[datejoined]=" + editDate).
                success(function (data, status) {
                    if (status == 200) {

                        if (data.error && data.error == 'Exists') {
                            $scope.current.editBusinessForm['selectedBranch'].$error.checkExists = true;
                        } else {
                            $scope.userViewDetails($scope.selectedId);
                            $rootScope.flashMessage = data;
                        }
                        $scope.updating = false;
                    }
                });
        }
    }

    if ($scope.selectedScreen == 'official') {
        $scope.selectedGasSafeID = $scope.userDetails.gassafeid;
        $scope.selectedOftecID = $scope.userDetails.oftecid;
        $scope.selectedNiceicid = $scope.userDetails.niceicid;
        $scope.selectedNapitid = $scope.userDetails.napitid;
        $scope.selectedElecsaid = $scope.userDetails.elecsaid;


        $scope.editOfficial = function ($event, formStatus) {
            $event.preventDefault();
            if (!formStatus) {
                return;
            }
            $scope.updating = true;
            $scope.current = this;
            $http.post(prefix + '/edit_official', "users[id]=" + this.selectedId + "&user[gassafeid]=" + encodeURIComponent(this.selectedGasSafeID) + "&user[OFTECID]=" + encodeURIComponent(this.selectedOftecID) + "&user[NICEICID]=" + encodeURIComponent(this.selectedNiceicid) + "&user[NAPITID]=" + encodeURIComponent(this.selectedNapitid) + "&user[ELECSAID]=" + encodeURIComponent(this.selectedElecsaid)).
                success(function (data, status) {
                    if (status == 200) {

                        if (data.error && data.error == 'Exists') {
                            $scope.current.editOfficialForm['selectedNI'].$error.checkExists = true;
                        } else {
                            $scope.userViewDetails($scope.selectedId);
                            $rootScope.flashMessage = data;
                        }
                        $scope.updating = false;
                    }
                });
        }
    }
    
    
    if ($scope.selectedScreen == 'license') {
        $scope.licenseTypes = $scope.userDetails.licensetypes;
        $scope.selectedLicensetype = ($scope.userDetails.licensetype !== null) ? $scope.userDetails.licensetype : "";
        $scope.chatLicenseType = ($scope.userDetails.chatlicensetype !== null) ? $scope.userDetails.chatlicensetype : "";
        $scope.salesLicenseType = ($scope.userDetails.salesLicenseType !== null) ? $scope.userDetails.salesLicenseType : "";
        $scope.chatPricePlan = ($scope.userDetails.chatpriceplan !== null) ? $scope.userDetails.chatpriceplan : "";
        $scope.currentUserIsEdited = ($scope.selectedId == $rootScope.userId);
        $scope.selectedJmsAccess = $scope.userDetails.jmsaccess.id;
        $scope.selectedChatAccess = $scope.userDetails.chataccess.id;
        $scope.selectedSalesAccess = $scope.userDetails.salesaccess.id;
        $scope.roleSelected = false;
        $scope.singleUser = $scope.userDetails.singleUser;
        if($scope.selectedLicensetype && $scope.selectedJmsAccess){
            $scope.roleSelected = true;
        }
        if($scope.chatLicenseType && $scope.selectedChatAccess){
            $scope.roleSelected = true;
        }
        if($scope.salesLicenseType && $scope.selectedSalesAccess){
            $scope.roleSelected = true;
        }

        $scope.checkJMSRoleSelected = function(){
            if($scope.selectedLicensetype){
                $scope.roleSelected = false;
                var restrictedRoles = $scope.userDetails.restrictedRoles['JMS'][$scope.selectedLicensetype];
                for(i=0;i<$scope.loginAccess.length;i++ ){
                    if(restrictedRoles.includes($scope.loginAccess[i].id)){
                        $scope.loginAccess[i].restricted = 1;
                    } else {
                        $scope.loginAccess[i].restricted = 0;
                    }
                }
               // $scope.selectedJmsAccess = '';
                if($scope.selectedLicensetype && $scope.selectedJmsAccess){
                    $scope.roleSelected = true;
                }
            }
            $scope.roleSelected = true;
            if((!$scope.selectedLicensetype && !$scope.salesLicenseType) ||
                ($scope.selectedLicensetype && !$scope.selectedJmsAccess) ||
                ($scope.salesLicenseType && !$scope.selectedSalesAccess) ){
                $scope.roleSelected = false;
            }
        }

        $scope.checkChatRoleSelected = function(){
            if($scope.chatLicenseType){
                $scope.roleSelected = false;
                var restrictedRoles = $scope.userDetails.restrictedRoles['Chat'][$scope.chatLicenseType];
                for(i=0;i<$scope.chatAccess.length;i++ ){
                    if(restrictedRoles.includes($scope.chatAccess[i].id)){
                        $scope.chatAccess[i].restricted = 1;
                    } else {
                        $scope.chatAccess[i].restricted = 0;
                    }
                }
                // $scope.selectedChatAccess = '';
                if($scope.chatLicenseType && $scope.selectedChatAccess){
                    $scope.roleSelected = true;
                }
            }
            $scope.roleSelected = true;
            if((!$scope.selectedLicensetype && !$scope.salesLicenseType) ||
                ($scope.selectedLicensetype && !$scope.selectedJmsAccess) ||
                ($scope.salesLicenseType && !$scope.selectedSalesAccess) ){
                $scope.roleSelected = false;
            }
        }

        $scope.checkSalesRoleSelected = function(){
            if($scope.salesLicenseType){
                $scope.roleSelected = false;
                var restrictedRoles = $scope.userDetails.restrictedRoles['Sales'][$scope.salesLicenseType];
                for(i=0;i<$scope.salesAccess.length;i++ ){
                    if(restrictedRoles.includes($scope.salesAccess[i].id)){
                        $scope.salesAccess[i].restricted = 1;
                    } else {
                        $scope.salesAccess[i].restricted = 0;
                    }
                }
                //$scope.selectedSalesAccess = '';
                if($scope.salesLicenseType && $scope.selectedSalesAccess){
                    $scope.roleSelected = true;
                }
            }
            $scope.roleSelected = true;
            if((!$scope.selectedLicensetype && !$scope.salesLicenseType) ||
                ($scope.selectedLicensetype && !$scope.selectedJmsAccess) ||
                ($scope.salesLicenseType && !$scope.selectedSalesAccess) ){
                $scope.roleSelected = false;
            }
        }

        $scope.removeJMSLicenseRole = function (elm) {
            if(elm == "" || ($scope.selectedLicensetype.length != elm.length) || (elm.length == 3 && elm.charAt(0)!= $scope.selectedLicensetype.charAt(0)) ){
                $("#edituser_jmsroleid option[value='']").attr('selected', 'selected');
                $scope.selectedJmsAccess = '';
            }
            $scope.selectedLicensetype = elm;
            $scope.roleSelected = elm != '' && $scope.selectedJmsAccess != '';
        }
        $scope.removeChatLicenseRole = function (elm) {
            if(elm == "" || ($scope.chatLicenseType.length != elm.length) || (elm.length == 3 && elm.charAt(0)!= $scope.chatLicenseType.charAt(0)) ){
                $("#edituser_chatroleid option[value='']").attr('selected', 'selected');
                $scope.selectedChatAccess = '';
            }
            $scope.chatLicenseType = elm;
            $scope.roleSelected = elm != '' && $scope.selectedChatAccess != '';
        }
        $scope.removeSalesLicenseRole = function (elm) {
            if(elm == "" || ($scope.salesLicenseType.length != elm.length) || (elm.length == 3 && elm.charAt(0)!= $scope.salesLicenseType.charAt(0)) ){
                $("#edituser_salesroleid option[value='']").attr('selected', 'selected');
                $scope.selectedSalesAccess = '';
            }
            $scope.salesLicenseType = elm;
            $scope.roleSelected = elm != '' && $scope.selectedSalesAccess != '';
        }

        $scope.jmsLicenseEdited = function (elm) {
            $scope.selectedJmsAccess = elm;
            if ($scope.selectedLicensetype) {
                if (($scope.selectedLicensetype && $('#edituser_jmsroleid').val() != '')) {
                    $scope.roleSelected = true;
                } else {
                    $scope.roleSelected = false;
                }
            }
            if((!$scope.selectedLicensetype && !$scope.salesLicenseType) ||
                ($scope.selectedLicensetype && !$scope.selectedJmsAccess) ||
                ($scope.salesLicenseType && !$scope.selectedSalesAccess) ){
                $scope.roleSelected = false;
            }
        }
        $scope.chatLicenseEdited = function (elm) {
            $scope.selectedChatAccess = elm;
            $scope.roleSelected = true;
            /*if ($scope.chatLicenseType) {
                if (($scope.chatLicenseType && $('#edituser_chatroleid').val() != '')) {
                    $scope.roleSelected = true;
                } else {
                    $scope.roleSelected = false;
                }
            }*/
        }
        $scope.salesLicenseEdited = function (elm){
            $scope.selectedSalesAccess = elm;
            if($scope.salesLicenseType){
                if (($scope.salesLicenseType && $('#edituser_salesroleid').val() != '')) {
                    $scope.roleSelected = true;
                } else {
                    $scope.roleSelected = false;
                }
            }
            if((!$scope.selectedLicensetype && !$scope.salesLicenseType) ||
                ($scope.selectedLicensetype && !$scope.selectedJmsAccess) ||
                ($scope.salesLicenseType && !$scope.selectedSalesAccess) ){
                $scope.roleSelected = false;
            }
        }

        $http.get(prefix + '/get_user_access').success(function (data) {
            $scope.loginAccess = data.roles;
            $scope.chatAccess = data.chatRoles;
            $scope.salesAccess = data.salesRoles;
            if($scope.salesLicenseType) {
                var restrictedRoles = $scope.userDetails.restrictedRoles['Sales'][$scope.salesLicenseType];
                for (i = 0; i < $scope.salesAccess.length; i++) {
                    if (restrictedRoles.includes($scope.salesAccess[i].id)) {
                        $scope.salesAccess[i].restricted = 1;
                    } else {
                        $scope.salesAccess[i].restricted = 0;
                    }
                }
            }
            if($scope.chatLicenseType) {
                var restrictedRoles = $scope.userDetails.restrictedRoles['Chat'][$scope.chatLicenseType];
                for (i = 0; i < $scope.chatAccess.length; i++) {
                    if (restrictedRoles.includes($scope.chatAccess[i].id)) {
                        $scope.chatAccess[i].restricted = 1;
                    } else {
                        $scope.chatAccess[i].restricted = 0;
                    }
                }
            }
            if($scope.selectedLicensetype) {
                var restrictedRoles = $scope.userDetails.restrictedRoles['JMS'][$scope.selectedLicensetype];
                for (i = 0; i < $scope.loginAccess.length; i++) {
                    if (restrictedRoles.includes($scope.loginAccess[i].id)) {
                        $scope.loginAccess[i].restricted = 1;
                    } else {
                        $scope.loginAccess[i].restricted = 0;
                    }
                }
            }
        })

        $scope.getChangeLicenseType = function(chatLicenseType){
            $scope.chatLicenseType = chatLicenseType;
        }
        
        $scope.editLicense = function ($event, formStatus) {
            $event.preventDefault();
            if (!formStatus) {
                return;
            }
            
            $scope.updating = true;
            $scope.current = this;
            
            $http.post(prefix + '/edit_license', "users[id]=" + this.selectedId + "&license_info[JMS]=" + encodeURIComponent(this.selectedLicensetype) +  "&license_info[Chat]=" + encodeURIComponent(this.chatLicenseType)+ "&license_info[ChatPricePlan]=" + encodeURIComponent(this.chatPricePlan) +  "&license_info[SALES]=" + encodeURIComponent(this.salesLicenseType) +  "&user_role[JMS]=" + encodeURIComponent(this.selectedJmsAccess) +  "&user_role[Chat]=" + encodeURIComponent(this.selectedChatAccess) +  "&user_role[Sales]=" + encodeURIComponent(this.selectedSalesAccess)).
            success(function (data, status) {
                if (status == 200) {

                    if (data.error && data.error == 'Exists') {
                        $scope.current.editLicensingForm['selectedNI'].$error.checkExists = true;
                    } else {
                        $scope.userViewDetails($scope.selectedId);
                        $rootScope.flashMessage = data;
                    }
                    $scope.updating = false;
                }
            });
        }
    }

    if ($scope.selectedScreen == 'reset') {

        $scope.newPassword = '';
        $scope.successMessage = '';
        $scope.errorMessage = '';
        $scope.flashMessageClose = function () {
            $scope.successMessage = '';
            this.errorMessage = '';
        }
        $scope.resetPassword = function ($event, formStatus) {
            $event.preventDefault();
            if (!formStatus) {
                return;
            }
            $scope.updating = true;
            $scope.current = this;
            $http.post(prefix + '/reset_password', "id=" + this.selectedId + "&password=" + encodeURIComponent(this.newPassword) + "&reenter=" + encodeURIComponent(this.retypeNewPassword)).
                success(function (data, status) {
                    if (data == 'Success') {
                        $scope.current.errorMessage = "";
                        $scope.current.successMessage = 1;
                        $scope.updating = false;
                        $scope.userViewDetails($scope.selectedId);
                        $rootScope.flashMessage = 'Your Password has been changed';
                    } else if (data == 'LengthNotValid') {
                        $scope.resetPasswordForm['newPassword'].$error.minlength = true;
                    } else if (data == 'PattenNotValid') {
                        $scope.letterMessage = true;
                    } else if (data == 'PasswordNotMatch') {
                        $scope.passwordNotMatch = true;
                    } else {
                        $scope.current.errorMessage = data;
                        $scope.updating = false;
                        $scope.current.successMessage = '';
                    }
                    $scope.current.resetPasswordForm.$setPristine();
                    $scope.current.newPassword = '';
                    $scope.current.retypeNewPassword = '';
                    $scope.current.validLength = '';
                    $scope.current.validLetter = '';
                    $scope.current.validNumber = '';
                });
        }
    }

    if ($scope.selectedScreen == 'configure_gps_device') {
        var gpsDevice = $scope.userDetails.gpsDevice || {};
        $scope.gpsDeviceFormData = $scope.userDetails.gpsDeviceFormData || {};
        $scope.showDeleteWarningModal = false;

        // Initializing form fields
        $scope.configureGpsDeviceFormValue = {
            'id': gpsDevice['id'] || null,
            'user': $scope.userDetails.id,
            'network': gpsDevice['network'] || null,
            'apn': gpsDevice['apn'] || null,
            'apn_username': gpsDevice['apn_username'] || null,
            'apn_password': gpsDevice['apn_password'] || null,
            'tracker_mobile': gpsDevice['tracker_mobile'] || null,
            'tracker_mobile_code': parseInt(gpsDevice['tracker_mobile_code'] || null),
            'device_unique_identifier': gpsDevice['device_unique_identifier'] || null,
            'device_model_id': gpsDevice['device_model_id'] || null,
            'imeiNumber': ''
        };

        //for new device
        $scope.imeiSelect2Options = {
            placeholder: "Select IMEI",
            'multiple': false,
            allowClear: true,
            id: function (obj) {
                return obj.id;
            },
            'data': [],
            formatResult: function (item) {
                return item.text;
            },
            formatSelection: function (item) {
                return item.text;
            },
            formatNoMatches: function (item) {
                if (item != '') {
                    return 'No results match "' + item + '"';
                } else {
                    return "";
                }
            }
        };
        if('device_model_id' in gpsDevice)
            $scope.disableDevice = true;

        if('device_model_id' in gpsDevice && gpsDevice['device_model_id'] == 2){
            $scope.imeiSelect2Options.data.push({'id':-1, 'text': gpsDevice['device_unique_identifier']});
            $scope.configureGpsDeviceFormValue.imeiNumber = {'id':-1, 'text': gpsDevice['device_unique_identifier']}
            $scope.showNetworkConfig = false;
        }
        else
            $scope.showNetworkConfig = true;

        // Form input listeners
        $scope.configureGpsDeviceFormValidated = function(form) {
            return form.valid
                && $scope.configureGpsDeviceFormValue.device_model_details;
        };

        $scope.onNetworkChange = function () {
            var network = $scope.configureGpsDeviceFormValue.network;

            if(!network){
                $scope.configureGpsDeviceFormValue.apn = null;
                $scope.configureGpsDeviceFormValue.apn_username = null;
                $scope.configureGpsDeviceFormValue.apn_password = null;
            } else {
                var networkDetails = _.find($scope.gpsDeviceFormData.networks, {'name': network});

                $scope.configureGpsDeviceFormValue.apn = networkDetails.apn;
                $scope.configureGpsDeviceFormValue.apn_username = networkDetails.apn_username;
                $scope.configureGpsDeviceFormValue.apn_password = networkDetails.apn_password;
            }
        };

        $scope.$on('GetCode', function (event, label, code) { 
            $scope.configureGpsDeviceFormValue[label] = code;
        });

        $scope.onDeviceModelChange = function(){
            $scope.imeiNumberList = [];
            $scope.imeiSelect2Options.data = [];
            if($scope.configureGpsDeviceFormValue.device_model_id == 2){
                $http({
                    url: prefix + "/clients/" +$rootScope.clientId + "/vehicletrackersstocks?limit=1000",
                    method: 'GET',
                }).success(function (result) {
                    $scope.vehicletrackersstocks = result['vehicletrackersstocks'];
                    $scope.vehicletrackersstocks = result['vehicletrackersstocks'].map(function (u) {
                        u['text'] = u['imeinumber'];
                        return u;
                    });
                    angular.forEach(result['vehicletrackersstocks'], function (val, key) {
                        $scope.imeiSelect2Options.data.push(val)
                    });

                }).error(function (err) {
                    console.log(err);
                });


                $scope.showNetworkConfig = false;
            }
            else{
                $scope.configureGpsDeviceFormValue.device_unique_identifier = null;
                $scope.showNetworkConfig = true;
            }
        }

        $scope.isimeiNumberDropdownTouched = false;

        $scope.dropdownValueSelected = function ($model) {
            if (!$scope.isimeiNumberDropdownTouched) {
                $model.$setPristine();
            }

            if ($scope.configureGpsDeviceFormValue.imeiNumber) {
                $model.$valid = true;
                $model.$error.required = false;
            }
            else {
                $model.$valid = false;
                $model.$error.required = true;
            }
            $scope.isimeiNumberDropdownTouched = true;
        };

        // Form submission
        $scope.configureGpsDevice = function (form) {
            if(form.$valid){
                $scope.updating = true;
                console.log($scope.configureGpsDeviceFormValue)
                if($scope.configureGpsDeviceFormValue.device_model_id == 2)
                    $scope.configureGpsDeviceFormValue.device_unique_identifier = $scope.configureGpsDeviceFormValue.imeiNumber['text'];
                $http({
                    url: Routing.generate('configure_gps_device', {
                        userId: $scope.configureGpsDeviceFormValue.user
                    }),
                    method: 'POST',
                    data: 'data=' + JSON.stringify($scope.configureGpsDeviceFormValue)
                }).success(function (result) {
                    if(result.error){
                        $scope.updating = false;
                        $scope.error = true;
                        $scope.errorMessage = result.error;
                    } else {
                        switch (result.status) {
                            case "OK" : {
                                toastBox.show('Saved', 3000);
                                break;
                            }
                            case "INITIATED": {
                                toastBox.show('GPS device configuration process initiated', 10000);
                                break;
                            }
                            case "FAILED": {
                                toastBox.show('Failed to configure GPS device. ' + result.statusText, 10000);
                                break;
                            }
                            case "CONFIGURED": {
                                toastBox.show('GPS device configured successfully', 10000);
                                break;
                            }
                        }

                        // Navigating on success
                        $state.go('loggedin.users.view.details', {id: $scope.userDetails.id});
                    }

                }).error(function (err) {
                    console.log(err);
                    $scope.updating = false;
                    $scope.error = true;
                    $scope.errorMessage = 'Failed to configure the device. Please try again with correct details.';
                })

            } else {
                console.log('form data is invalid');
            }
        };

        $scope.cancel = function() {
            window.history.back();
        };

        $scope.canShowDeleteOption = function(){
            var loggedInUser = JSON.parse($rootScope.paramsToRedirectFromLogin.userDetails);
        };

        $scope.toggleDeleteModal = function() {
            $scope.showDeleteWarningModal = !$scope.showDeleteWarningModal;
        };

        $scope.onDeleteSuccess = function(event){
            toastBox.show('GPS device deleted successfully', 3000);
            window.history.back();
        };

        $scope.$on('modal:close', function(event) {
            $scope.toggleDeleteModal();
        });
    }
}

function EmergencyContactCtrl($scope, $state, getIdData, $http, prefix, $rootScope, warningModal) {
    $scope.selectedId = $state.params.id;
    $scope.totalContacts = getIdData.count;
    $scope.contacts = getIdData.contacts;
    getIdData.user.showSubNavStatic = false;
    getIdData.user.selectedTab = 'EmergencyContacts';
    $scope.$emit('tabUser:selected', getIdData.user);
    DeleteUserCtrl.call(this, $scope, warningModal);
    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    if ($rootScope.flashMessage) {
        $scope.$emit('tabUser:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
    $scope.fetchEmergencyContact = function (pageNum, selectedTab) {
        $scope.currentPage = pageNum;
        $scope.selectedTab = selectedTab;
        $http.get(Routing.generate('emergency_contacts', { id: $scope.selectedId, page: pageNum, limit: $scope.limit, selectedTab: $scope.selectedTab })).success(function (data) {
            data.user.showSubNavStatic = false;
            data.user.selectedTab = 'EmergencyContacts';
            $scope.$emit('tabUser:selected', data.user);
            $scope.contacts = data.contacts;
            $scope.totalContacts = data.count;
        })
    }
    $scope.shouldBeOpen = false;
    $scope.triggerDeleteContact = function (id, name) {
        $scope.selectedContactId = id;
        $scope.selectedName = name;
        $scope.shouldBeOpen = true;
    }
}
function ReminderDateCtrl($scope, roleModules, date1, date2, type, $state) {
    $scope.userReminders = function (id) {
        $state.transitionTo('loggedin.users.view.reminders', {'id': id});
    }
    if (type == 'add') {
        $scope.minDate = moment();
        $scope.dueSelectedDate = '';
        $scope.reminderSelectedDate = moment();
    }else if(type == 'reminderAdd') {
        $scope.currentDate = moment();
        $scope.dueSelectedDate = '';
        $scope.minDate = $scope.currentDate.add(1,'day');
        $scope.reminderSelectedDate =  $scope.minDate;
        $scope.selectedDate = $scope.minDate;
        $scope.$on('$viewContentLoaded', function(){
            $scope.$broadcast('event:change_specified_date', {scopeName: 'reminderSelectedDate', date: $scope.minDate});
        });
    }else if(type == 'edit') {
        $scope.currentDate = moment();
        $scope.minDate = $scope.currentDate.add(1,'day');
        $scope.reminderSelectedDate = moment(date2.date);

    }else if(type == 'userReminderAdd'){
        $scope.currentDate = moment();
        $scope.dueSelectedDate = '';
        $scope.minDate = $scope.currentDate.add(2,'day');
        $scope.reminderSelectedDate =  '';
        $scope.CurrentDateValue = moment();
        $scope.reminderMinDate = $scope.CurrentDateValue.add(1,'day');
    }else if(type == 'userReminderEdit') {
        $scope.currentDate = moment();
        $scope.minDate = $scope.currentDate.add(2,'day');
        $scope.dueSelectedDate = moment(date1.date);
        $scope.maxDate = moment($scope.dueSelectedDate).subtract(1,'day');
        $scope.CurrentDateValue = moment();
        $scope.reminderMinDate = $scope.CurrentDateValue.add(1,'day');
        $scope.reminderSelectedDate = moment(date2.date);

    }
    else {
        $scope.dueSelectedDate = moment(date1.date);
        $scope.reminderSelectedDate = moment(date2.date);
    }
    $scope.select2Options = {
        placeholder: "Select Some Users",
        'multiple': true,
        id: function (obj) {
            return obj.id;
        },
        'data': roleModules.modules,
        formatResult: function (item) {
            return item.text;
        },
        formatSelection: function (item) {
            return item.text;
        },
        formatNoMatches: function (item) {
            if (item != '') {
                return 'No results match "' + item + '"';
            } else {
                return "";
            }
        }
    };
    $scope.select2OptionsUserReminder = {
        placeholder: "Select some remind groups",
        multiple: true,
        id: function (obj) {
            return obj.id;
        },
        data: roleModules.modules,
        formatResult: function (item) {
            return item.text;
        },
        formatSelection: function (item) {
            return item.text;
        },
        formatNoMatches: function (item) {
            if (item != '') {
                return 'No results match "' + item + '"';
            } else {
                return "";
            }
        }
    };

    $scope.$watch('dueSelectedDate', function (newVal, oldVal) {
        if (newVal != undefined && newVal != '' && newVal != oldVal) {
            $scope.maxDate =moment(newVal, 'L').subtract('days', 1);
            //this code affecting my logic so only i command this line
            //$scope.reminderSelectedDate = moment($scope.dueSelectedDate).subtract('weeks', 6);
            $scope.errorDateMessage = false;
            var now = moment();
            var dueDate = moment($scope.dueSelectedDate, 'L');
            if (now == dueDate || dueDate < now) {
                $scope.dueDateMessage = true;
            } else {
                $scope.dueDateMessage = false;
            }
            $scope.show_date_picker = false;
        }
    });
    $scope.$watch('reminderSelectedDate', function (newVal, oldVal) {
        if (newVal != undefined && newVal != '') {
            $scope.errorDateMessage = false;
            var now = moment();
            var sameDate = moment($scope.dueSelectedDate, 'L').isSame(moment($scope.reminderSelectedDate,'L'));
            var lessDate = moment($scope.reminderSelectedDate,'L').isBefore(moment($scope.dueSelectedDate,'L'));
            if (sameDate == true || lessDate == false || $scope.reminderSelectedDate < now || $scope.reminderSelectedDate == now) {
                $scope.reminderDateMessage = true;
            } else {
                $scope.reminderDateMessage = false;
            }
            $scope.show_date_picker = false;
        }
    });
}
function UsersRemindersCtrl($scope, $state, getIdData, $http, prefix, $rootScope, clickEvents) {
    getIdData.user.selectedTab = 'Reminders';
    getIdData.user.showSubNavStatic = false;
    $scope.$emit('tabUser:selected', getIdData.user);
    $scope.totalReminders = getIdData.count;
    $scope.selectedId = $state.params.id;
    $scope.usersData = getIdData.user;
    $scope.userRemindersData = getIdData.reminder;

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    if ($rootScope.flashMessage) {
        $scope.$emit('tabUser:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
    $scope.fetchReminder = function (pageNum, selectedTab) {
        $scope.currentPage = pageNum;
        $scope.selectedTab = selectedTab;
        $http.get(Routing.generate('users_reminders', { id: $scope.selectedId, page: pageNum, limit: $scope.limit, selectedTab: $scope.selectedTab })).success(function (data) {
            data.user.selectedTab = 'Reminders';
            data.user.showSubNavStatic = false;
            $scope.$emit('tabUser:selected', data.user);
            $scope.userRemindersData = data.reminder;
            $scope.totalReminders = data.count;
        })
    }
    $scope.shouldBeOpen = false;
    $scope.triggerDeleteReminder = function (id) {
        $scope.selectedReminderId = id;
        $scope.shouldBeOpen = true;
    }

}

function AddUserReminderCtrl($scope, $state, getIdData, $http, prefix, $rootScope, clickEvents) {
    $scope.selectedId = $state.params.id;
    getIdData.user.showSubNavStatic = true;
    $scope.minDate = new Date();
    $scope.$emit('tabUser:selected', getIdData.user);

    ReminderDateCtrl.call(this, $scope, getIdData, '', '', 'userReminderAdd', $state);
    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });
    $scope.addUserReminder = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.app = '';
        if ($scope.modules != '') {
            $scope.app = [];
            var i = 0;
            angular.forEach($scope.modules, function (val, key) {
                $scope.app += "&userreminder[remindergroupmodules][" + i + "][userrolesid]=" + val.id;
                i++;
            });
        }
        $scope.updating = true;
        $http.post(prefix + '/add_new_reminder', "userreminder[description]=" + encodeURIComponent(this.description)
                + "&userreminder[duedate]=" + moment(this.dueSelectedDate,'L').format('YYYY-MM-DD') + "&userreminder[usersid]=" + $scope.selectedId
                + "&userreminder[reminderdate]=" + moment(this.reminderSelectedDate,'L').format('YYYY-MM-DD') + $scope.app).
            success(function (data, status) {
                if (status == 200) {
                    if (data.error) {
                        $scope.errorDateMessage = data.error;
                    } else {
                        $scope.userReminders($scope.selectedId);
                        $rootScope.flashMessage = data;
                    }
                    $scope.updating = false;
                }
            });
    }

    $scope.$watch('modules', function (newVal, oldVal){
        if(newVal != undefined && newVal != '') {
            $scope.errorModules = false;
            $scope.displayError = false;
            $scope.checkedUser = true;
        }
        else {
            $scope.errorModules = true;
            if($scope.checkedUser == true) {
                $scope.displayError = true;
                $scope.checkedUser = false;
            }

        }
    });

}

function EditUserReminderCtrl($scope, $state, getIdData, $http, prefix, $rootScope, clickEvents) {
    getIdData.showSubNavStatic = true;
    $scope.$emit('tabUser:selected', getIdData.user);
    $scope.minDate = new Date();
    $scope.selectedId = $state.params.id;
    $scope.reminderId = $state.params.reminderId;
    $scope.editRemindGroups = getIdData.selectedroles;
    angular.forEach(getIdData.reminderdata, function (key, val) {
        $scope.editDescription = key.description;
        $scope.dueDate = key.duedate;
        $scope.reminderDate = key.reminderdate;
    });
    ReminderDateCtrl.call(this, $scope, getIdData, $scope.dueDate, $scope.reminderDate, 'userReminderEdit', $state);
    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    $scope.editUserReminder = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.app = [];
        $scope.updating = true;
        $scope.current = this;
        if (this.editRemindGroups != '') {
            var i = 0;
            angular.forEach(this.editRemindGroups, function (val, key) {
                $scope.app.push("&userreminder[remindergroups][" + i + "][userrolesid]=" + val.id);
                i++;
            });
        }

        $http.post(prefix + '/edit_user_reminder', "userreminder[description]=" + encodeURIComponent($scope.editDescription)
                + "&userreminder[reminderId]=" + $scope.reminderId
                + "&userreminder[duedate]=" + moment(this.dueSelectedDate,'L').format('YYYY-MM-DD')
                + "&userreminder[reminderdate]=" + moment(this.reminderSelectedDate,'L').format('YYYY-MM-DD') + $scope.app).
            success(function (data, status) {
                if (status == 200) {
                    if (data.error) {
                        $scope.errorDateMessage = true;
                    } else {
                        $scope.userReminders($scope.selectedId);
                        $rootScope.flashMessage = data;
                    }
                    $scope.updating = false;
                }
            });
    }
    $scope.$watch('editRemindGroups', function (newVal, oldVal){
        if(newVal != undefined && newVal != '') {
            $scope.errorModules = false;
            $scope.displayError = false;
            $scope.checkedUser = true;
        }
        else {
            $scope.errorModules = true;
            if($scope.checkedUser == true) {
                $scope.displayError = true;
                $scope.checkedUser = false;
            }

        }
    });
}

function AddEmergencyContactCtrl($scope, $state, getIdData, $http, prefix, $rootScope) {
    $scope.selectedId = $state.params.id;
    $scope.telephone_code = '';
    $scope.mobile_code = '';
    getIdData.showSubNavStatic = true;

    $scope.$emit('tabUser:selected', getIdData);

    PostCodeCtrl.call(this, $scope, $state, $http, prefix);

    $scope.addEmergencyContact = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;

        $http.post(prefix + '/add_emergency_contact', "emergencycontact[usersid]=" + this.selectedId
                + "&emergencycontact[name]=" + encodeURIComponent($scope.name)
                + "&emergencycontact[surname]=" + encodeURIComponent($scope.surname)
                + "&emergencycontact[email]=" + encodeURIComponent($scope.email)
                + "&emergencycontact[telephone]=" + encodeURIComponent($scope.telephone)
                + "&emergencycontact[mobile]=" + encodeURIComponent($scope.mobile)
                + "&emergencycontact[relationship]=" + encodeURIComponent($scope.relationship)
                + "&emergencycontact[addressline1]=" + encodeURIComponent($scope.addressline1)
                + "&emergencycontact[addressline2]=" + encodeURIComponent($scope.addressline2)
                + "&emergencycontact[addressline3]=" + encodeURIComponent($scope.addressline3)
                + "&emergencycontact[town]=" + encodeURIComponent($scope.town)
                + "&emergencycontact[county]=" + encodeURIComponent($scope.county)
                + "&emergencycontact[postcode]=" + encodeURIComponent($scope.postcode)
                + "&emergencycontact[telephone_code]=" + encodeURIComponent(this.telephone_code)
                + "&emergencycontact[mobile_code]=" + encodeURIComponent(this.mobile_code)).
            success(function (data, status) {
                if (status == 200) {
                    if (data.error && data.error == 'Exists') {
                        $scope.current.addEmergencyContactForm['name'].$error.checkExists = true;
                    } else {
                        $scope.emergencyContacts($scope.selectedId);
                        $rootScope.flashMessage = data;
                    }
                    $scope.updating = false;
                }
            });
    }
}

function EditEmergencyContactCtrl($scope, $state, getIdData, $http, prefix, $rootScope) {
    $scope.selectedId = $state.params.id;
    getIdData.user.showSubNavStatic = true;
    $scope.$emit('tabUser:selected', getIdData.user);
    $scope.showPostCodeLookUp = getIdData.user.showPostCodeLookUp;
    $scope.selectedContact = getIdData.contact;
    $scope.contactId = getIdData.contact.id;
    $scope.name = getIdData.contact.name;
    $scope.surname = getIdData.contact.surname;
    $scope.email = getIdData.contact.email;

    if (getIdData.contact.telephone) {
        var splitTelephone = getIdData.contact.telephone.split(' ');
        $scope.telephone_code = splitTelephone[0].replace('+', '');
        $scope.telephone = splitTelephone[1];
    }
    else {
        $scope.telephone_code = '';
        $scope.telephone = '';
    }
    if (getIdData.contact.mobile) {
        var splitMobile = getIdData.contact.mobile.split(' ');
        $scope.mobile_code = splitMobile[0].replace('+', '');
        $scope.mobile = splitMobile[1];
    }
    else {
        $scope.mobile_code = '';
        $scope.mobile = '';
    }
    $scope.relationship = getIdData.contact.relationship;
    $scope.addressline1 = getIdData.contact.addressline1;
    $scope.addressline2 = getIdData.contact.addressline2;
    $scope.addressline3 = getIdData.contact.addressline3;
    $scope.town = getIdData.contact.town;
    $scope.county = getIdData.contact.county;
    $scope.postcode = getIdData.contact.postcode;

    PostCodeCtrl.call(this, $scope, $state, $http, prefix);

    $scope.editEmergencyContact = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;

        $http.post(prefix + '/edit_emergency_contact', "emergencycontact[contactId]=" + this.contactId
                + "&emergencycontact[id]=" + this.selectedId
                + "&emergencycontact[name]=" + encodeURIComponent(this.name)
                + "&emergencycontact[surname]=" + encodeURIComponent(this.surname)
                + "&emergencycontact[email]=" + encodeURIComponent(this.email)
                + "&emergencycontact[telephone_code]=" + encodeURIComponent(this.telephone_code)
                + "&emergencycontact[mobile_code]=" + encodeURIComponent(this.mobile_code)
                + "&emergencycontact[telephone]=" + encodeURIComponent(this.telephone)
                + "&emergencycontact[mobile]=" + encodeURIComponent(this.mobile)
                + "&emergencycontact[relationship]=" + encodeURIComponent(this.relationship)
                + "&emergencycontact[addressline1]=" + encodeURIComponent(this.addressline1)
                + "&emergencycontact[addressline2]=" + encodeURIComponent(this.addressline2)
                + "&emergencycontact[addressline3]=" + encodeURIComponent(this.addressline3)
                + "&emergencycontact[town]=" + encodeURIComponent(this.town)
                + "&emergencycontact[county]=" + encodeURIComponent(this.county)
                + "&emergencycontact[postcode]=" + encodeURIComponent(this.postcode)).
            success(function (data, status) {
                if (status == 200) {
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editEmergencyContactForm['name'].$error.checkExists = true;
                    } else {
                        $scope.emergencyContacts($scope.selectedId);
                        $rootScope.flashMessage = data;
                    }
                    $scope.updating = false;
                }
            });
    }

}

function MobileSettingsCtrl($scope, $state, $http, prefix, formService, getIdData, warningModal) {
    $scope.selectedId = $state.params.id;
    getIdData.selectedTab = 'MobileSettings';
    getIdData.showSubNavStatic = false;
    $scope.$emit('tabUser:selected', getIdData);
    DeleteUserCtrl.call(this, $scope, warningModal);
    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            $scope.mobileSettingForm.$setPristine();
            $scope.saving = false;
            $scope.$emit('tabUser:successMessage', data);
        });
    }
}

function AttachedFilesCtrl($scope, $state, getIdData, $http, prefix, defaultPagingValue, $rootScope, tableCollection, $timeout) {
    $scope.selectedId = $state.params.id;
    $scope.files = getIdData.files;

    getIdData.user.showSubNavStatic = false;
    getIdData.user.selectedTab = 'AttachedFiles'
    $scope.$emit('tabUser:selected', getIdData.user);
    $scope.shouldBeOpen = false;
    $scope.fileSelected = false;
    $scope.imageTypes = {'jpeg': true, 'jpg': true, 'png': true, 'gif': true};

    // This condition for to display the flash message.
    if ($rootScope.flashMessage) {
        $scope.$emit('tabUser:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("UserAttachedFile", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "preview") {
            $scope.previewFile(myObject);
        } else if (action == "delete") {
            $scope.deleteFile(myObject.id, myObject.name);
        } else if (action == "download") {
            DownloadFunction.call(this, $scope, myObject.location, myObject.name);
        }

    });

    // This condition true when pagination.
    if ($rootScope.selection != '') {
        $scope.selectedUser = $rootScope.selection.rowObject.id;
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    console.log(Routing.generate('get_user_files', { id: $scope.selectedId }) + '?page=1&limit=' + $scope.limit);

    $scope.fetchAttachedFiles = function (pageNum) {

	 $rootScope.$broadcast('file:closepreview');

        $http.get(Routing.generate('get_user_files', { id: $scope.selectedId }) + '?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.files = data.files;
            data.user.showSubNavStatic = false;
            data.user.selectedTab = 'AttachedFiles'
            $scope.$emit('tabUser:selected', data.user);
        })
    }

    $scope.deleteFile = function (id, name) {
        $scope.selectedFileId = id;
        $scope.selectedFileName = name;
        $scope.shouldBeOpen = true;
    }
}

function AddFileCtrl($scope, $state, $http, prefix, $upload, $rootScope, getIdData, NgConfigKBUrlService) {
    $scope.selectedId = $state.params.id;
    $scope.supportedFilesLink = NgConfigKBUrlService.getData('uploadSupportedFilesUrl');
    $scope.errorMsg = null;
    getIdData.user.selectedTab = 'AttachedFiles';
    getIdData.user.showSubNavStatic = true;
    $scope.$emit('tabUser:selected', getIdData.user);
    $scope.error = false;
    $scope.testDrag = true;
    $scope.excludedeTypes = ['exe', 'msi', 'dmg'];
    $scope.action = '/upload_new_file?mode=users&selectedId=' + $scope.selectedId;
    $scope.uploadAction = $scope.action;

    $scope.uploadCallback = function() {
        $scope.attachedFiles($scope.selectedId);
    };

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
            $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
        } else {
            $scope.error = false;
            $rootScope.flashMessage = "File Uploaded";
            $scope.attachedFiles($scope.selectedId);
        }
    }

    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);
}

function UserNotesCtrl($scope, $state, getIdData, $http, prefix, defaultPagingValue, $rootScope, warningModal) {
    $scope.selectedId = $state.params.id;
    $scope.userNotes = getIdData.noteResult;
    $scope.userNotesCount = getIdData.count;
    getIdData.userResult.showSubNavStatic = false;
    getIdData.userResult.selectedTab = "Notes&Communications";
    $scope.$emit('tabUser:selected', getIdData.userResult);

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    if ($rootScope.flashMessage) {
        $scope.$emit('tabUser:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
    $scope.selectedTab = 'NotesCommunications';
    DeleteUserCtrl.call(this, $scope, warningModal);
    $scope.fetchNotes = function (pageNum) {

        $http.get(prefix + '/company_settings/users/' + $scope.selectedId + '/get_user_notes?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            data.userResult.selectedTab = "Notes&Communications";
            data.userResult.showSubNavStatic = false;
            $scope.$emit('tabUser:selected', data.userResult);
            $scope.userNotes = data.noteResult;
        })
    }

    $scope.triggerDeleteNote = function (id) {
        $scope.selectedIdNote = id;
        $scope.shouldBeOpenNote = true;
    }

}

function NewNoteCtrl($scope, $http, prefix, datasets, formService, $state, getIdData, $stateParams, $rootScope) {
    $scope.data = datasets;
    $scope.saving = false;
    getIdData.showSubNavStatic = true;
    $scope.$emit('tabUser:selected', getIdData);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.saving = false;
                //$scope.selectedId = data.tablepkid;
                $scope.notes($scope.selectedId);
                $rootScope.flashMessage = data;
            })
    }
}
function EditNoteCtrl($scope, $stateParams, $location, $http, $state, getIdData, prefix, $rootScope, formService) {

    $scope.selectedId = $state.params.id;
    getIdData.userResult.showSubNavStatic = true;
    $scope.$emit('tabUser:selected', getIdData.userResult);
    var noteResult = getIdData.noteResult;

    $scope.noteId = noteResult.id;
    $scope.noteTitle = noteResult.title;
    $scope.noteDescription = noteResult.note;

    $scope.editNote = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_user_note', "new_user_note[id]=" + this.noteId + "&new_user_note[communicationsnote][title]=" + encodeURIComponent(this.noteTitle) + "&new_user_note[communicationsnote][note]=" + encodeURIComponent(this.noteDescription)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    $scope.notes($scope.selectedId);
                    $rootScope.flashMessage = data;
                }
            });
    }
}

function VehicleParamtersCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.vehicleParameterForm['vehicleParameter[description]'].$error.checkExists = true;

            } else {
                $scope.vehicleParameterForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("VEHICLEPARAMETER_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function VehicleParamtersListEditCtrl($scope, $state, prefix, $http, vehicleparameterresolver, defaultPagingValue) {
    $scope.vehicleparameters = vehicleparameterresolver.vehicleparameters;
    $scope.totalVehicleParameter = vehicleparameterresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'vehicleparameter', $state, defaultPagingValue, 'vehicleparameters');

    $scope.$on("VEHICLEPARAMETER_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, vehicleparameterresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.vehicleparameters.push({'id': message.id, 'description': message.description, 'justadded': true});
            vehicleparameterresolver.vehicleparameters = $scope.vehicleparameters;
            $state.current.data.addedId = -1;
        } else {
            $scope.currentPage = $scope.currentNewPage;
        }
        vehicleparameterresolver.count = parseInt(vehicleparameterresolver.count) + 1
        $scope.totalVehicleParameter = vehicleparameterresolver.count;

    });

    $scope.editVehicleParameterType = function (id, description) {
        $state.transitionTo('loggedin.vehicles_parameter.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.fetchVehicleParameter = function (pageNum) {
        $http.get(prefix + '/listVehicleParamters?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.vehicleparameters = data.vehicleparameters;
            $scope.totalVehicleParameter = data.count;
            vehicleparameterresolver.vehicleparameters = $scope.vehicleparameters;
            vehicleparameterresolver.count = $scope.totalVehicleParameter;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function VehicleParameterEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedVehicleParameter = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.vehicles_parameter");
    }

    $scope.editVehicleParameterType = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/editVehicleParamter', "vehicleparameter[id]=" + this.selectedId + "&vehicleparameter[description]=" + encodeURIComponent(this.selectedVehicleParameter)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editVehicleParameterForm['selectedVehicleParameter'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.transitionTo("loggedin.vehicles_parameter");
                    }
                }
            });
    }
}

function AuditsCtrl($scope, $state, $http, prefix, datasets, $rootScope, navSidebar, $stateParams) {
    $scope.data = datasets;
    $scope.firstTierItems = navSidebar.getsubnavItems();
    $scope.secondTierItems = navSidebar.getsubnavItems();
    $scope.state = $state;
    $scope.stateParams = $stateParams;
    $scope.categoryTitle = 'Help';
    $scope.shouldBeOpen = false;
    $scope.toggleFirstTier = function toggleFirstTier(firstTierItem) {
        $scope.showSecondTier = true;
        $scope.selected = firstTierItem;
    }
    $scope.hideSubNavs = function hideSubNavs() {
        $scope.showFirstTier = false;
        $scope.showSecondTier = false;
    }
    $scope.triggerAuditModel = function () {
        $scope.shouldBeOpen = true;
    }
}

function AuditsViewCtrl($scope, $stateParams, navSidebar, dateRange, datasets, prefix, $http, defaultPagingValue, $state, $sce) {
    $scope.audits = datasets.auditsdata;
    $scope.auditsCount = datasets.auditsCount;
    $scope.categoryId = $stateParams.categoryId;
    $scope.auditId = $stateParams.auditId;
    $scope.auditData = navSidebar.getsubnavItems();

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    for (var i = 0; i < $scope.auditData.length; i += 1) {
        if ($scope.auditData[i].id === $scope.categoryId) {
            var categoryIndex = i;
        }
    }
    for (var i = 0; i < $scope.auditData[categoryIndex].secondTier.length; i += 1) {
        if ($scope.auditData[categoryIndex].secondTier[i].id === $scope.auditId) {
            var auditIndex = i;
        }
    }
    // Need to include the audit template name here, when implement the new audit screen.
    var template = ['all_edits','all_deletes','postcode_lookups','login_audit', 'mobile_access'];
    $scope.categoryTitle = $scope.auditData[categoryIndex].firstTier;
    $scope.$parent.categoryTitle = $scope.categoryTitle;
    $scope.selectedAuditView = $scope.auditData[categoryIndex].secondTier[auditIndex];
    $scope.selectedAuditPath = '/template/audits/tables/' + $scope.selectedAuditView.state + '.html';
    $scope.showTemplate = false;

    // Checked whether the template is exist, if not will hide the render template div in audit/view.html file.
    var templateIndex = template.indexOf($scope.selectedAuditView.state);
    if(templateIndex != -1){
        $scope.showTemplate = true;
    }

    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.$on("dateRangeFilter", function (event, message) {
        if (message.startdate != undefined && message.startdate != "" && message.enddate != undefined &&  message.enddate != "") {
            $scope.selectedRange = message.string;
            $scope.startDate = message.startdate;
            $scope.endDate = message.enddate;
            $scope.fetchAudits($scope.currentPage);
        } else {
            $scope.dateValue = undefined;
        }
    });

    $scope.fetchAudits = function (pageNum) {
        var url = prefix + '/audits/view/' + $scope.categoryId + '/' + $scope.auditId + '?startDate=' + $scope.startDate + '&endDate=' + $scope.endDate + '&auditId=' + $scope.selectedAuditView.id + '&page=' + pageNum + '&limit=' + $scope.limit;
        $http.get(url).success(function (data) {
            $scope.audits = data.auditsdata;
            $scope.auditsCount = data.auditsCount;
        });
    }
    $scope.dateRangeClose = function (e) {
        var input = $(e.target).parents('.daterange_filter');
        var date_range_widget = $(e.target).parents('.daterange-container');
        if (input.length > 0 || date_range_widget.length > 0 || e.target.className == 'ng-binding muted') {
            e.preventDefault();
        } else {
            $scope.$broadcast('body:clicked', '');
        }
    }
    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
}

function ApiKeysListEditCtrl($scope, $state, prefix, $http, apikeysresolver, defaultPagingValue, canLoad, $q, $rootScope, warningModal) {
    $scope.apikeys = apikeysresolver.apikeys;
    $scope.totalApikeys = apikeysresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("APIKeys", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "delete") {
            $scope.triggerDelete(myObject.id);
        }
    });

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.fetchApiKeys($scope.currentPage);
        }
    });

    var canceler = null;
    $scope.fetchApiKeys = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        $http.get(prefix + '/listApiKeys?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.apikeys = data.apikeys;
            $scope.totalApikeys = data.count;

            if (searchText != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    $scope.triggerDelete = function (id) {
        $http.get(prefix + '/is_delete_api_key?id=' + id).success(function(data) {
            if (data === 'false') {
                warningModal.show("Deleting an API key can have repercussions to Commusoft functions (e.g mobile syncing). Please make sure you are deleting the correct API key. If you are unsure, please contact support@commusoft.co.uk for advice", "Delete api key", 'delete_api_key');
            }
            else {
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
            }
        });
    }
}

function PdfShowCtrl($scope, $http, $state, $rootScope, $timeout, datasets, prefix) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.contract = Routing.generate('contractpdfshow');
}


function smsNameCtrl($scope, $http, $state, $rootScope, $timeout, datasets, prefix) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.smsname = '';
    $scope.containsSpace = false;


    $scope.$watch('smsname', function (newVal, oldVal) {
        if (newVal) {
            if(newVal.indexOf(" ") != -1){
                $scope.containsSpace = true;
            } else {
                $scope.containsSpace = false;
            }
        }
    });
}

function HolidayYearEndCtrl($scope, $http, $state, $rootScope, $timeout, datasets, prefix) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.show_date_picker = false;

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });
    var date = new Date();
    $scope.minDate = date.setDate((new Date()).getDate() - 0);
    $scope.maxDate = date.setMonth(date.getMonth() + 12);
}

function CreditCardCtrl($scope, $http, $state, datasets, prefix) {
    $scope.data = datasets;
    $scope.useStripe = 'Use Stripe';
    $scope.confirmText = '';
    $scope.voiceCommerce = 'Voice Commerce';
    $scope.Username = '';
    $scope.Password = '';
    $scope.clientkey = '';
    $scope.servicekey = '';
    $scope.shouldBeOpen = false;
    $scope.confirmUsernameValidity = true;
    $scope.confirmPasswordValidity = true;
    $scope.confirmCleintKeyValidity = true;
    $scope.confirmServiceKeyValidity = true;
    $scope.loading = false;
    $scope.closebutton = true;
    $scope.loginbutton = true;

    $scope.useStripeFunction = function ($event) {
        $http.post(prefix + '/credit_card', "&mode=insert&selectedStripe=" + $scope.useStripe).
            success(function (data, status) {
                if (status == 200) {
                    // Should show the modal
                    $scope.stripe = true;
                }
            });
    }
    $scope.cancelCreditCard = function (card) {
        $scope.value = card;
        $scope.shouldBeOpen = true;
    }

    $scope.cancel = function () {
        $(document.querySelector('#authentication-modal')).modal('hide');
    }
    $scope.removeDisabled = function () {
        $scope.selectedCard = '';
        $scope.clientkey = '';
        $scope.servicekey = '';
        $scope.confirmCleintKeyValidity = true;
        $scope.confirmServiceKeyValidity = true;
    }

    $scope.connectWorldpay = function($event) {
        if($scope.clientkey && $scope.servicekey) {
            $scope.loginbutton = false;
            $scope.loading = true;
            $scope.closebutton = false;
            $http.post(prefix + '/worldpay_connect', "&clientKey=" + $scope.clientkey + "&serviceKey=" + $scope.servicekey).
                success(function (data, status) {
                    if (status == 200) {
                        $scope.loading = false;
                        $scope.closebutton = true;
                        $scope.loginbutton = true;
                        $scope.selectedCard = data;

                        $(document.querySelector('#authentication-modal')).modal('hide');
                    }
                });
        } else if ($scope.clientkey == '' && $scope.servicekey == '') {
            $scope.confirmCleintKeyValidity = false;
            $scope.confirmServiceKeyValidity = false;
        } else if ($scope.clientkey == '') {
            $scope.confirmCleintKeyValidity = false;
            $scope.confirmServiceKeyValidity = true;
        } else if ($scope.servicekey == '') {
            $scope.confirmCleintKeyValidity = true;
            $scope.confirmServiceKeyValidity = false;
        }
    }

    $scope.loginVoice = function ($event) {
        if ($scope.Username != '' && $scope.Password != '') {
            $scope.confirmUsernameValidity = true;
            $scope.confirmPasswordValidity = true;
            $scope.loginbutton = false;
            $scope.loading = true;
            $scope.closebutton = false;
            $http.post(prefix + '/credit_card', "&mode=loginvoice&selectedVoice=" + $scope.voiceCommerce + "&selectedUsername=" + $scope.Username + "&selectedPassword=" + $scope.Password).
                success(function (data, status) {
                    if (status == 200) {
                        $scope.loading = false;
                        $scope.closebutton = true;
                        $scope.loginbutton = true;
                        $scope.voice = true;

                        $(document.querySelector('#authentication-modal')).modal('hide');
                    }
                });
        } else if ($scope.Username == '' && $scope.Password == '') {
            $scope.confirmUsernameValidity = false;
            $scope.confirmPasswordValidity = false;
        } else if ($scope.Username == '') {
            $scope.confirmUsernameValidity = false;
            $scope.confirmPasswordValidity = true;
        } else if ($scope.Password == '') {
            $scope.confirmUsernameValidity = true;
            $scope.confirmPasswordValidity = false;
        }

    }

    /**
     * Payfort related inputs
     */
    $scope.merchant_identifier = '';
    $scope.sha_request_phrase = '';
    $scope.access_code = '';
    $scope.sha_type = ''

    /**
     * Open payfort modal box
     */
    $scope.openPayfortModal = function(){
        $scope.merchant_identifier = '';
        $scope.sha_request_phrase = '';
        $scope.sha_response_phrase = '';
        $scope.access_code = '';
        $scope.sha_type = '';
        $(document.querySelector('#payfort_credentials_modal')).modal({
            backdrop: 'static',
            keyboard: false
        });
    }

    /**
     * Save payport related inputs
     * @param $event
     */
    $scope.connectPayfort = function($event){
        $event.preventDefault();
        $scope.loginbutton = false;
        $scope.loading = true;
        $scope.closebutton = false;
        $http.post(Routing.generate("payfort_connect"),
            'data='+encodeURIComponent(JSON.stringify({
                'merchant_identifier':$scope.merchant_identifier,
                'sha_request_phrase':$scope.sha_request_phrase,
                'sha_response_phrase':$scope.sha_response_phrase,
                'access_code':$scope.access_code,
                'sha_type':$scope.sha_type}))).
        success(function (data, status) {
            if (status == 200) {
                $scope.loading = false;
                $scope.closebutton = true;
                $scope.loginbutton = true;
                $scope.selectedCard = data.result;
                $(document.querySelector('#payfort_credentials_modal')).modal('hide');
            }
        });
    }
    $scope.cancelPayfortModal = function () {
        $(document.querySelector('#payfort_credentials_modal')).modal('hide');
    }
}

function ManageBackupsCtrl(prefix, $scope, datasets, $timeout, $http) {
    $scope.data = datasets;
    $scope.confirmText = '';
    $scope.shouldBeOpen = false;
    $scope.backing_up_with_excel = false;

    $scope.cancelDrive = function (drive) {
        $scope.value = drive;
        $scope.shouldBeOpen = true;
    }
    $scope.removeDisabled = function () {
        $scope.selectedDrive = '';
    }

    $scope.backupExcel = function(e) {
        e.preventDefault();
        $scope.selectedDrive = 'excel';
        $scope.backing_up_with_excel = true;
        $timeout(function() {
            $http.post(Routing.generate('backup_excel_download')).then(function() {
                $scope.backing_up_with_excel = false;
                $scope.selectedDrive = '';
            });
        }, 5000);


    }
}

function BrandingColoursCtrl($scope, $rootScope, $http, $state, toastBox, datasets) {
    $scope.todays_date = moment().format();
    $scope.completed_date = moment().subtract(10, 'd').format();
    $scope.due_date = moment().add(14, 'd').format();
    $scope.data = datasets;

    $scope.formSubmit = function (formElem) {
        $rootScope.$broadcast('brandingcolors:savespectrumcolor');
        $rootScope.errorMessage = "";
        $http.post($scope.actionVal, 'selected_background_color=' + $scope.selected_background +
                                            '&selected_text_color=' + $scope.selected_text).
            success(function (data, status) {
                toastBox.show('Branding Colours Saved', 4000);
                $state.transitionTo('loggedin.company_settings');
            });
    }

    /*==========================================================================================
     Full screen control
     ==========================================================================================*/
    $rootScope.$on('colorpicker:branding_background', function(e, colour){
        $scope.selected_background = (colour && colour.hasOwnProperty('rgba')) ? colour.rgba : "rgba(0,183,201,1)";
        $scope.selected_background_color_text = (colour && colour.hasOwnProperty('color')) ? colour.color: "cs-blue";
    });
    $rootScope.$on('colorpicker:branding_text', function(e, colour){
        $scope.selected_text = (colour && colour.hasOwnProperty('rgba')) ? colour.rgba : "rgba(255,255,255,1)";
        $scope.selected_text_color_text = (colour && colour.hasOwnProperty('color')) ? colour.color: "white";

    });
}
function TravelRateCtrl($scope, $http, $state, datasets, $rootScope, prefix) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
}

function AccountingInterfaceCtrl($scope, $state, $rootScope, datasets, $window, tableCollection, $http)
{
    $scope.data = datasets;

    $scope.$state = $state;

    $scope.selected_ai_tab = 'Dashboard';

    $scope.$on('integration:api', function(event, api_token) {

        $scope.account_id = api_token.account_id;

        $scope.accounting_package_id = api_token.accounting_package_id;

        $scope.accounting_package_name = api_token.accounting_package_name;

        $scope.oAuth = api_token.oAuth;

        $scope.accounting_integration_errors = api_token.accounting_integration_errors;

    });
    /*==========================================================================================================================
     UI methods for breadcrumbs
     ==========================================================================================================================*/
    $scope.setBreadcrumbs = function(crumbs) {
        $scope.dynamic_crumbs = crumbs;
        $scope.selected_ai_tab = crumbs;
    }

    $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
        $scope.in_logs = false;
        if (toState.name.includes('logs')) {
            $scope.in_logs = true;
            $scope.current_log_status = toState.name.split('.')[2];

        }

        var special_case = $scope.checkIfSpecialCase(toState);

        if (!special_case) {
            $scope.special_case = false;
            // hide the subnav and left sidebar in pages with forms
            if(toState.url === '/edit' || toState.name === 'loggedin.accounting_interfaces.notifications_new') {
                $scope.hide_sub_nav = true;
                $scope.prev_url = 'loggedin.accounting_interfaces.notifications';
                var search_param = toState.url.substring(1, toState.url.length),
                    pos = toState.name.search(search_param);

                $scope.prev_state = toState.name.substring(0, pos-1) + '.view';
                //$scope.constructPrevUrl();
            }
            else if(toState.name === 'loggedin.accounting_interfaces.link' || toState.name === 'loggedin.accounting_interfaces.resend') {
                $scope.hide_sub_nav = true;
                $scope.prev_url = 'loggedin.accounting_interfaces';
            }
            else {
                $scope.hide_sub_nav = false;
            }
        } else {
            $scope.special_case = true;

            $scope.special_from_name = 'Dashboard';
            $scope.special_from_state = 'loggedin.accounting_interfaces';
        }

        if (toState.name.search('dashboard') < 0) {
            $scope.accounting_interface_is_link = true;
        } else {
            $scope.accounting_interface_is_link = false;
        }
        if (toState.name.includes('logs')) {
            if ($scope.$state.params.current_log_record_type) {
                var record_type = $scope.$state.params.current_log_record_type.replace(/_/g, " ");
                $scope.record_type = record_type.charAt(0).toUpperCase() + record_type.slice(1);

                var record_status = $scope.$state.current.name.split('.')[2];
                $scope.setBreadcrumbs('Logs / ' + ($scope.$state.params.record_type.charAt(0).toUpperCase() + $scope.$state.params.record_type.slice(1)).replace(/_/g, " ") + ' / ' + $scope.record_type);
                $scope.search_criteria_title = '';
                $scope.search_criteria = '';
                if ($state.params.current_log_record_type === 'customers' || $state.params.current_log_record_type === 'suppliers') {
                    $scope.search_criteria_title = ($state.params.current_log_record_type === 'customers')?'customers':'suppliers';
                    $scope.search_criteria = ($state.params.current_log_record_type === 'customers')?'name, surname':'name';
                }
                else if ($state.params.current_log_record_type === 'customer_invoices' || $state.params.current_log_record_type === 'supplier_invoices') {
                    $scope.search_criteria_title = 'invoices';
                    $scope.search_criteria = 'invoice number';
                }
                else if ($state.params.current_log_record_type === 'customer_payments' || $state.params.current_log_record_type === 'supplier_payments') {
                    $scope.search_criteria_title = 'payments';
                    $scope.search_criteria = 'payment reference'
                }
                else if ($state.params.current_log_record_type === 'customer_creditnotes' || $state.params.current_log_record_type === 'supplier_creditnotes') {
                    $scope.search_criteria_title = 'credit notes';
                    $scope.search_criteria = 'credit note number';
                }
                else if ($state.params.current_log_record_type === 'customer_import_payments' || $state.params.current_log_record_type === 'supplier_import_payments') {
                    $scope.search_criteria_title = 'payments';
                    $scope.search_criteria = 'payment reference';
                }
            }
        }
    });

    $scope.checkIfSpecialCase = function(toState) {
        if (toState.name.includes('logs')) {
            return true
        }
        return false
    }

    $scope.goToSpecialFromState = function(special_from_state) {
        $scope.$state.transitionTo(special_from_state);
    }

    $scope.constructPrevUrl = function() {
        // construct the url so that the customer id state params can be
        // added to this in the breadcrumbs
        var state = 'accounting_interfaces';

        $scope.prev_url = $scope.prev_state
            .substring(state.length + 1)
            .split('.')
            .join('/');

        if ($scope.prev_url === 'link/view' || $scope.prev_url === 'resend/view') {
            $scope.prev_url = 'dashboard/view';
        }
    }
    /*==========================================================================================================================
     Open the modal to adjust transfer settings
     ==========================================================================================================================*/
    $scope.shouldBeOpenSetup = false;
    $scope.adjustTransferSettings = function() {
        $rootScope.AISetup = 'transfer';
        $scope.shouldBeOpenSetup = true;
    }
    $scope.downloadInterface = function() {
        $rootScope.AISetup = 're_download_interface';
        $scope.shouldBeOpenSetup = true;
    }
    $scope.editPackageCredentials = function() {
        $rootScope.$broadcast('setup_accounting_package:edit_package_credentials');
        $scope.shouldBeOpenSetup = true;
    }
    $scope.changeCutOffDate = function() {
        $rootScope.AISetup = 'cutoffdate';
        $scope.shouldBeOpenSetup = true;
    }
    $scope.downloadQuickbooksConfig = function() {
        $rootScope.AISetup = 'download_quickbooks_config';
        $scope.shouldBeOpenSetup = true;
    }
    $scope.reconnectPackage = function() {
        $rootScope.AISetup = 'reconnect';
        $scope.shouldBeOpenSetup = true;
    }
    /*==========================================================================================
     * edit setting code - open modal
     * =========================================================================================*/
    $scope.editSettings = function() {
        $rootScope.AISetup = 'edit_settings';
        $scope.shouldBeOpenSetup = true;
    }
    $rootScope.$on('informationbar:incorrect_accounting_credentials', function() {
        $rootScope.AISetup = ($scope.accounting_package_id == 10)?'info_reconnect':'reconnect';
        $scope.shouldBeOpenSetup = true;
    });
    /*==========================================================================================================================
     DELETE the user's integration
     ==========================================================================================================================*/

    $scope.shouldBeOpenAiDelete = false;

    $scope.triggerDeleteAISetup = function (account_id) {
        //check if records is in processing log showing error message at AI dashboard
        $http.get(Routing.generate('accounting_interfaces_dashboard')).success(function (getIdData) {
            $scope.processQueue = (getIdData.processing)?getIdData.processing.count:0;

            if ($scope.processQueue > 0) {
                $scope.processQueueValidation = [{"type":"warning",
                   "title":"warning",
                   "message":"You cannot delete the accounting integration due to records currently being sent across. Please wait for this data to process before you delete the accounting integration.",
                   "full_message":null,
                   "category":"incorrect_accounting_credentials",
                   "reconnect":false,
                }];
                $rootScope.processqueue_validation = true;
            } else {
                $scope.account_id = account_id;
                $scope.shouldBeOpenAiDelete = true;
                $rootScope.processqueue_validation = false;
            }
       });

    }
    $scope.$on('ai:delete_integration', function(event, data) {
        $scope.triggerDeleteAISetup(data.id);
    });
    $scope.$on('DeletedAccount', function (event, data) {
        $state.transitionTo('loggedin.accounting_interfaces', null, {reload:true});
    });

    /*==========================================================================================================================
     Set up some defaults
     ==========================================================================================================================*/
    $scope.todaysDate = new Date;
    $scope.current_integration_loaded = false;
    $scope.commusoft_can_connect = false;
    $scope.accounting_integration_setup = false;
    $scope.quicklinks_lock = false;

    $scope.$on('integration:setup', function(event, setup) {
        $scope.current_integration_loaded = setup.current_integration_loaded;
        $scope.commusoft_can_connect = setup.commusoft_can_connect;
        $scope.accounting_integration_setup = setup.accounting_integration_setup;
        $scope.quicklinks_lock = setup.quicklinks_lock;
    });

    $scope.$on('records:count', function(event, recordsCount) {
        $scope.recordsCount = recordsCount;
    });

    $scope.$on('ai:setup', function(event, account) {
        $scope.account = account;
        tableCollection.setData('account', account);
        var getIdData = {'account':account};
        AIAccountCheck.call(this, $scope, $state, getIdData);
    });
}

function AIAccountCheck($scope, $state, getIdData){

    $scope.account = getIdData.account;

    if($scope.account) {
        $scope.user_has_started_setup_but_not_finished = false;
        $scope.current_integration_loaded = true;

        if ($scope.account.cutoffdate && $scope.account.isvalid == false) {
            $scope.accounting_integration_setup = true;
        }
        else {
            $scope.user_has_started_setup_but_not_finished = true;
        }

        $scope.oAuth = ($scope.account.packageid == 1 || $scope.account.packageid == 8 || $scope.account.packageid == 5 || $scope.account.packageid == 10) ? false : true;

        var api_token = {
            account_id: $scope.account.id,
            accounting_package_id: $scope.account.packageid,
            accounting_package_name: $scope.account.packagename,
            oAuth: $scope.oAuth,
            accounting_integration_errors: ($scope.account.accounting_integration_errors)?$scope.account.accounting_integration_errors.length:0
        };

        $scope.$emit('integration:api', api_token);

        if($scope.account.accounting_integration_errors){
           var  commusoft_can_connect = ($scope.account.accounting_integration_errors.length)?false:true;
        }

        var setup = {current_integration_loaded:$scope.current_integration_loaded,
            accounting_integration_setup:$scope.accounting_integration_setup,
            commusoft_can_connect:commusoft_can_connect,
            quicklinks_lock:$scope.quicklinks_lock};
    }
    else {
        $scope.accounting_integration_setup = false;
        $scope.current_integration_loaded = true;
        $scope.user_has_started_setup_but_not_finished = false;

        var setup = {current_integration_loaded:$scope.current_integration_loaded,
            accounting_integration_setup:$scope.accounting_integration_setup,
            commusoft_can_connect:false};
    }

    $scope.$emit('integration:setup', setup);
}

function AccountingInterfaceDashboardCtrl($scope, $state, $rootScope, tableCollection, $http, $timeout)
{

    $scope.accounting_integration_setup = false;
    $scope.current_integration_loaded = false;
    $scope.user_has_started_setup_but_not_finished = false;
    $rootScope.processqueue_validation = false;

    $scope.$on('ContinueAccount', function(event, account) {
        if(account) {
            $scope.account = account;
            tableCollection.setData('account', account);
            $scope.user_has_started_setup_but_not_finished = false;
            $scope.current_integration_loaded = true;

            if (account.cutoffdate && account.isvalid == false) {
                $scope.accounting_integration_setup = true;
            }
            else {
                $scope.user_has_started_setup_but_not_finished = true;
            }
        }
    });

    $http.get(Routing.generate('accounting_interfaces_dashboard'))
        .success(function (getIdData) {

            $scope.no_in_queue = (getIdData.waiting)?getIdData.waiting.count:0;

            $scope.no_in_progress = (getIdData.processing)?getIdData.processing.count:0;

            $scope.no_successful = (getIdData.success)?getIdData.success.count:0;

            $scope.no_failed = (getIdData.failed)?getIdData.failed.count:0;

            tableCollection.setData('accounting_packages', getIdData.accounting_packages);

            $scope.accounting_packages = getIdData.accounting_packages;

            $scope.shouldBeOpenSetup = false;

            $scope.setupNewIntegrationTrigger = function () {
                $rootScope.AISetup = 'new';
                $scope.shouldBeOpenSetup = true;
            }
            tableCollection.setData('emailAlreadyExist', getIdData.emailAlreadyExist);
           
            $scope.emailAlreadyExist = getIdData.emailAlreadyExist;
            tableCollection.setData('account', getIdData.account);

            $scope.account = getIdData.account;

            if($scope.account && $scope.account.details) {
                $scope.package_company_name = $scope.account.details.details.packageCompanyName;
            }
            if($scope.account && $scope.account.quicklinks_lock != ''){
               $scope.quicklinks_lock = $scope.account.quicklinks_lock;
            }

            AIAccountCheck.call(this, $scope, $state, getIdData);

            if ($scope.account && $scope.account.isvalid == true) {
                $rootScope.AISetup = 're_download_interface';
                $scope.shouldBeOpenSetup = true;
            }

            $scope.getQueryString = function ( field, url ) {
                var href = url ? decodeURIComponent(url) : decodeURIComponent(window.location.href),
                    reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' ),
                    string = reg.exec(href);

                return string ? string[1] : null;
            };

            $scope.oAuth_token = $scope.getQueryString('oAuthToken');

            if($scope.oAuth_token && $scope.account == null) {
                $rootScope.AISetup = 'new';
                $scope.shouldBeOpenSetup = true;
            }

            if($scope.oAuth_token && $scope.account != null) {
                $rootScope.AISetup = 'info_reconnect';
                $scope.shouldBeOpenSetup = true;
            }

            $scope.continueIntegration = function() {
                $rootScope.AISetup = 'continue';
                $scope.shouldBeOpenSetup = true;
            }
            /*==========================================================================================================================
             Send data from commusoft to the accounting package
             ==========================================================================================================================*/
            $scope.handleSync = function() {
                if($scope.commusoft_can_connect === true && $scope.no_in_queue > 0) {
                    $scope.no_in_queue = 0;
                    $scope.no_in_progress = (getIdData.waiting)?getIdData.waiting.count+getIdData.processing.count:0;
                    $http.get(Routing.generate('send_records')).success(function (data) {
                        $timeout($scope.dashboardCountUpdate, 1000*120);
                    });
                }
            }

            $scope.dashboardCountUpdate = function () {
                if(window.location.pathname == '/company_settings/accounting_interfaces/dashboard/view') {
                    $http.get(Routing.generate('dashboard_count_update')).success(function (data) {
                        $scope.no_in_queue = (data.waiting)?data.waiting.count:0;
                        $scope.no_in_progress = (data.processing)?data.processing.count:0;
                        $scope.no_failed = (data.failed)?data.failed.count:0;
                        $scope.no_successful = (data.success)?data.success.count:0;
                        tableCollection.setData('account', data.account);
                        $scope.account = data.account;
                    });
                    $timeout($scope.dashboardCountUpdate, 1000*120);
                }
            }
            $scope.refreshAIDashboard = function refreshAIDashboard() {
                if(window.location.pathname == '/company_settings/accounting_interfaces/dashboard/view') {
                    $state.transitionTo('loggedin.accounting_interfaces', null, {reload: true});
                }
            }

            if(window.location.pathname == '/company_settings/accounting_interfaces/dashboard/view' && $scope.account) {
                if($scope.account.accounting_integration_errors && $scope.account.accounting_integration_errors.length > 0) { $timeout($scope.refreshAIDashboard, 1000*300); }
            }
        });
}

function AccountingInterfaceLogsCtrl($scope, $state, $timeout, $rootScope, toastBox, tableCollection, getIdData, $http, canLoad) {
    $scope.$state = $state;
    $scope.loading_logs = true;
    $scope.current_integration_loaded = false;
    $scope.selected_ai_tab = 'Logs';
    $scope.log_type = $state.params.current_log_record_type;
    $scope.searchType = 'failed';
    
    tableCollection.setData('ai_logs_'+$state.params.record_type+'_'+$state.params.current_log_record_type, getIdData['ai_logs_'+$state.params.record_type+'_'+$state.params.current_log_record_type]);

    $rootScope.$on('smart_table:milestoneUpdate', function(event, data) {
        $scope.$emit('records:count', data.recordsCount);
    });
    
    if($rootScope.tab_record_type != $state.params.record_type) {
        $scope.$emit('records:count', []);
    }
    //import setting flag if set then show customer/supplier imported payment tabs in failed log
    $state.params.import_flag = getIdData.import_flag;
    $scope.error = null;
    $http.get(Routing.generate('accounting_interfaces_logs', { record_type: $state.params.record_type, current_log_record_type: $state.params.current_log_record_type}))
        .success(function (getIdData) {
            $scope.error =  getIdData.error;
            AIAccountCheck.call(this, $scope, $state, getIdData);

            $rootScope.tab_record_type = $state.params.record_type;

            $scope.log_type = $state.params.current_log_record_type;

            $scope.$emit('records:count', getIdData.recordsCount);

            tableCollection.setData('ai_logs_'+$state.params.record_type+'_'+$state.params.current_log_record_type, getIdData['ai_logs_'+$state.params.record_type+'_'+$state.params.current_log_record_type]);

            $scope.log_record_type = tableCollection.collection['ai_logs_'+$state.params.record_type+'_'+$state.params.current_log_record_type].log_record_type;

            $scope.record_type = tableCollection.collection['ai_logs_'+$state.params.record_type+'_'+$state.params.current_log_record_type].record_type;

            if ($state.params.current_log_record_type == 'customers') {

                $scope.log_title = 'Customers';
            }
            else if ($state.params.current_log_record_type == 'suppliers') {

                $scope.log_title = 'Suppliers';
            }
            else if ($state.params.current_log_record_type == 'customer_invoices') {

                $scope.log_type = 'invoices';

                $scope.log_title = ($state.params.current_log_record_type == 'customer_invoices')?'Customer invoices':'Supplier invoices';

            }
            else if ($state.params.current_log_record_type == 'supplier_invoices') {

                $scope.log_type = 'invoices';

                $scope.log_title = ($state.params.current_log_record_type == 'customer_invoices')?'Customer invoices':'Supplier invoices';
            }
            else if ($state.params.current_log_record_type == 'customer_payments' || $state.params.current_log_record_type == 'supplier_payments') {

                $scope.log_type = 'payments';

                $scope.log_title = ($state.params.current_log_record_type == 'customer_payments')?'Customer payments':'Supplier payments';
            }
            else if ($state.params.current_log_record_type == 'customer_creditnotes' || $state.params.current_log_record_type == 'supplier_creditnotes') {

                $scope.log_type = 'credit_notes';

                $scope.log_title = ($state.params.current_log_record_type == 'customer_creditnotes')?'Customer credit notes':'Supplier credit notes';
            }
            else if ($state.params.current_log_record_type == 'customer_import_payments' || $state.params.current_log_record_type == 'supplier_import_payments') {
                $scope.log_type  = 'import_payments';
                $scope.log_title = ($state.params.current_log_record_type == 'customer_import_payments') ? 'Customer payments' : 'Supplier payments';
            }

            /*=========================================================================================================
             Listen to button clicks in the smart tables
             =========================================================================================================*/
            $scope.shouldBeOpenLogsDelete = false;

            $rootScope.$on('logs:delete', function (event, index, action, category, row) {
                $scope.deleteImportPaymentModal = false;
                $scope.resendDataModal          = false;
                $scope.retryImportPaymentModal  = false;
                $scope.markdonotsendModal       = false;

                if(row) {
                    $scope.selectedId = row[0].id;
                }
                else {
                    $scope.activeRecord = tableCollection.collection[category].data[index];
                    $scope.selectedId = $scope.activeRecord['id'];
                }

                if ($scope.log_record_type == 'customers') {
                    $scope.active_record_title = 'customer';
                }
                else if ($scope.log_record_type == 'suppliers') {
                    $scope.active_record_title = 'supplier';
                }
                else if ($scope.log_record_type == 'customer_payments') {
                    $scope.active_record_title = 'customer payment';
                }
                else if ($scope.log_record_type == 'customer_invoices') {
                    $scope.active_record_title = 'customer invoice';
                }
                else if ($scope.log_record_type == 'customer_creditnotes') {
                    $scope.active_record_title = 'customer credit note';
                }
                else if ($scope.log_record_type == 'supplier_invoices') {
                    $scope.active_record_title = 'supplier invoice';
                }
                else if ($scope.log_record_type == 'supplier_payments') {
                    $scope.active_record_title = 'supplier payment';
                }
                else if ($scope.log_record_type == 'supplier_creditnotes') {
                    $scope.active_record_title = 'supplier credit note';
                }
                else if ($scope.log_record_type == 'customer_import_payments') {
                    $scope.active_record_title = 'Customer payments';
                }
                else if ($scope.log_record_type == 'supplier_import_payments') {
                    $scope.active_record_title = 'Supplier payments';
                }

                $scope.deleteUrl = '/' + action + '/' + $scope.record_type + '/' + $scope.log_record_type;

                $scope.screenName = 'logsDelete';

                if(action == 'do_not_send') {
                    $scope.title = 'Do not send ' + $scope.active_record_title;

                    $scope.buttonText = 'Do not send ' + $scope.active_record_title;
                    $scope.markdonotsendModal = true;

                }
                if(action == 'send_record' && $scope.record_type != 'do_not_send') {
                    $scope.title = 'Re-send ' + $scope.active_record_title;

                    $scope.buttonText = 'Re-send ' + $scope.active_record_title;
                    $scope.resendDataModal = true;
                }
                if(action == 'send_record' && $scope.record_type == 'do_not_send') {
                    $scope.title = 'Send ' + $scope.active_record_title;

                    $scope.buttonText = 'Send ' + $scope.active_record_title;
                }
                //delete failed customer/supplier import payment record
                if(action == 'delete_imported_payment') {
                    $scope.deleteUrl = '/delete_failed_import_payment/' + $scope.record_type + '/' + $scope.log_record_type;
                    $scope.title      = 'Delete import payment failure log';
                    $scope.buttonText = 'Delete failure log';
                    $scope.deleteImportPaymentModal = true;
                }
                //retry failed customer/supplier import payment record
                if(action == 'retry_imported_payment') {
                    $scope.deleteUrl = '/retry_failed_import_payment/' + $scope.record_type + '/' + $scope.log_record_type;
                    $scope.title      = 'Retry import payment failure log';
                    $scope.buttonText = 'Retry import payment';
                    $scope.retryImportPaymentModal = true;
                }
                $scope.shouldBeOpenLogsDelete = true;
            });

            $scope.$on('MarkDoNotSend', function (evt, data) {
                $scope.$broadcast('smart_tablefilter:filtervalue', data);
            });

            $scope.recordsId = [];

            $rootScope.$on('smart_table:active_row_id', function (event, rows_store_id) {
                $scope.rows_store_id = rows_store_id;
            });

            $rootScope.$on('smart_table:pinned_rows_updated', function (event, rows_store) {
                $scope.records_being_resent = rows_store;
                if ($scope.records_being_resent) {
                    $scope.recordsId = [];
                    for (var i = 0, l = $scope.records_being_resent.length; i < l; i++) {
                        if ($scope.recordsId.indexOf($scope.records_being_resent[i][0]['value']) == -1) {
                            $scope.recordsData = tableCollection.collection['ai_logs_'+$state.params.record_type+'_'+$state.params.current_log_record_type].data;
                            $scope.recordData = _.findWhere($scope.recordsData, {id: parseInt($scope.rows_store_id[i])});
                            if ($scope.log_record_type == 'customers') {
                                var thirdColumn = ($scope.records_being_resent[i][3]['value'] != null) ? $scope.records_being_resent[i][3]['value'] : "";
                                var description = ($scope.records_being_resent[i][2]['value'] != null) ? ($scope.records_being_resent[i][2]['value'] + " " + thirdColumn) : thirdColumn;
                                $scope.recordsId.push({
                                    id: $scope.rows_store_id[i],
                                    description: description
                                });
                            }
                            else if ($scope.log_record_type == 'suppliers'
                                || $scope.log_record_type == 'supplier_invoices'
                                || $scope.log_record_type == 'supplier_creditnotes') {
                                var description = $scope.records_being_resent[i][1]['value'];
                                $scope.recordsId.push({
                                    id: $scope.rows_store_id[i],
                                    description: description
                                });
                            }
                            else if ($scope.log_record_type == 'customer_invoices'
                                || $scope.log_record_type == 'customer_creditnotes'
                                || $scope.log_record_type == 'supplier_payments') {
                                $scope.recordsId.push({
                                    id: $scope.rows_store_id[i],
                                    description: $scope.recordData['Description']
                                });

                            }
                            else if ($scope.log_record_type == 'customer_payments') {
                                $scope.recordsId.push({
                                    id: $scope.rows_store_id[i],
                                    description: $scope.recordData['Payment date']+ ', ' +$scope.recordData['Method']+ ', ' +$scope.recordData['Total']
                                });

                            }
                            else if ($scope.log_record_type == 'customer_import_payments' || $scope.log_record_type == 'supplier_import_payments') {
                                $scope.recordsId.push({
                                    id: $scope.rows_store_id[i],
                                });
                            }

                        }
                    }
                }
            });

            //delete multiple failed customer/supplier import payment
            $scope.deleteMultipleImportedFailedPyament = function () {
                if ($scope.recordsId.length > 0) {
                    $scope.selectedId = $scope.recordsId;
                    $scope.active_record_title = $scope.log_record_type.replace(/_/g, " ");
                    $scope.deleteUrl  = '/delete_failed_import_payment/' + $scope.record_type + '/' + $scope.log_record_type;
                    $scope.screenName = 'logsDeleteMultiple';
                    $scope.title      = 'Delete import payment failure log';
                    $scope.buttonText = 'Delete failure log';
                    $scope.shouldBeOpenLogsDelete   = true;
                    $scope.deleteImportPaymentModal = true;
                }
            }

            $scope.doNotSendMultipleRecordsToAccounts = function () {
                if ($scope.recordsId.length > 0) {
                    $scope.selectedId = $scope.recordsId;
                    $scope.active_record_title = $scope.log_record_type.replace(/_/g, " ");
                    $scope.title = 'Do not send ' + $scope.active_record_title;
                    $scope.deleteUrl = '/do_not_send/' + $scope.record_type + '/' + $scope.log_record_type;
                    $scope.screenName = 'logsDeleteMultiple';
                    $scope.buttonText = 'Do not send ' + $scope.active_record_title;
                    $scope.shouldBeOpenLogsDelete = true;
                    $scope.resendDataModal = false;
                    $scope.markdonotsendModal = true;
                }
            }
            $scope.resendMultipleRecordsToAccounts = function() {
                if ($scope.recordsId.length > 0) {
                    $scope.selectedId = $scope.recordsId;
                    $scope.active_record_title = $scope.log_record_type.replace(/_/g, " ");
                    $scope.title = 'Re-send ' + $scope.active_record_title;
                    $scope.deleteUrl = '/send_record/' + $scope.record_type + '/' + $scope.log_record_type;
                    $scope.screenName = 'logsDeleteMultiple';
                    $scope.buttonText = 'Re-send ' + $scope.active_record_title;
                    $scope.shouldBeOpenLogsDelete = true;
                    $scope.resendDataModal = true;
                }
            }
            $scope.sendMultipleRecordsToAccounts = function() {
                if ($scope.recordsId.length > 0) {
                    $scope.selectedId = $scope.recordsId;
                    $scope.active_record_title = $scope.log_record_type.replace(/_/g, " ");
                    $scope.title = 'Send records';
                    $scope.deleteUrl = '/send_record/' + $scope.record_type + '/' + $scope.log_record_type;
                    $scope.screenName = 'logsDeleteMultiple';
                    $scope.buttonText = 'Send records';
                    $scope.shouldBeOpenLogsDelete = true;
                }
            }

            $scope.loading_logs = false;
            $scope.current_integration_loaded = true;
        });

    $scope.moreFilter=[];
    $scope.handleFilters = function() {
        $scope.searchText = this.searchText;
        $scope.searchType = this.searchType;
        var filterData = {};
        filterData['searchText'] = $scope.searchText;
        filterData['searchType'] = $scope.searchType;
        $scope.moreFilter = angular.toJson(filterData);
        $scope.$broadcast('smarttable_and_sidepanel:filtervalues',  angular.toJson(filterData));
    };

    $scope.$on('fromsmarttable:filters', function(evt, data) {
        $scope.$broadcast('tosidepanel:filtervalues',
            {
                'model_name': 'searchText',
                'value': $scope.searchText
            }
        );
    });
}

function AccountingInterfaceDoNotSendCtrl($scope, $state, $timeout, $rootScope, toastBox, tableCollection, getIdData, $http) {
    $scope.$state = $state;
    $scope.loading_logs = true;

    $scope.current_integration_loaded = false;

    tableCollection.setData('do_not_send_logs', getIdData.do_not_send_logs);

    $http.get(Routing.generate('accounting_interfaces_do_not_send'))
        .success(function (getIdData) {
            AIAccountCheck.call(this, $scope, $state, getIdData);
            tableCollection.setData('do_not_send_logs', getIdData.do_not_send_logs);


            $scope.shouldBeOpenLogsDelete = false;

            $rootScope.$on('logs:delete', function(event, index, action, category) {

                $scope.record_type = 'do_not_send';

                $scope.activeRecord = tableCollection.collection[category].data[index];

                $scope.log_record_type = $scope.activeRecord.log_record_type

                if($scope.log_record_type == 'customers') {
                    $scope.active_record_title = 'customer';
                }
                if($scope.log_record_type == 'suppliers') {
                    $scope.active_record_title = 'supplier';
                }
                if($scope.log_record_type == 'customer_payments') {
                    $scope.active_record_title = 'customer payment';
                }
                if($scope.log_record_type == 'customer_invoices') {
                    $scope.active_record_title = 'customer invoice';
                }
                if($scope.log_record_type == 'customer_creditnotes') {
                    $scope.active_record_title = 'customer credit note';
                }
                if($scope.log_record_type == 'supplier_invoices') {
                    $scope.active_record_title = 'supplier invoice';
                }
                if($scope.log_record_type == 'supplier_payments') {
                    $scope.active_record_title = 'supplier payment';
                }
                if($scope.log_record_type == 'supplier_creditnotes') {
                    $scope.active_record_title = 'supplier credit note';
                    $scope.selectedId = $scope.activeRecord['Credit note no'];
                }
                $scope.selectedId = $scope.activeRecord['ID'];

                $scope.title = 'Send ' + $scope.active_record_title;

                $scope.deleteUrl = '/'+action+'/'+$scope.record_type+'/'+$scope.log_record_type;

                $scope.screenName = 'logsDelete';

                $scope.buttonText = 'Re-send ' + $scope.active_record_title;

                $scope.shouldBeOpenLogsDelete = true;
            });

            $scope.$on('ReSend', function(evt, data) {
                $scope.$broadcast('smart_tablefilter:filtervalue', data);
            });


            $scope.searchRecordType = 'all';

            $scope.moreFilter=[];
            $scope.recordType = function(type) {
                $scope.searchRecordType = type;
            }
            $scope.$watch('searchRecordType', function (newVal, oldVal) {
                if (newVal != undefined) {
                    var filterData = {};
                    filterData['searchRecordType'] = $scope.searchRecordType;
                    $scope.moreFilter = angular.toJson(filterData);
                    $scope.$broadcast('smarttable_and_sidepanel:filtervalues',  angular.toJson(filterData));
                }
            });

            $scope.$on('fromsmarttable:filters', function(evt, data) {
                $scope.$broadcast('tosidepanel:filtervalues',
                    {
                        'model_name': 'searchRecordType',
                        'value': $scope.searchRecordType
                    }
                );
            });

            $scope.recordsId = [];

            $rootScope.$on('smart_table:pinned_rows_updated', function (event, rows_store) {
                $scope.records_being_resent = rows_store;
                if ($scope.records_being_resent) {
                    $scope.recordsId = [];
                    for (var i = 0, l = $scope.records_being_resent.length; i < l; i++) {
                        if ($scope.recordsId.indexOf($scope.records_being_resent[i][0]['value']) == -1) {
                            $scope.recordsId.push({
                                id: $scope.records_being_resent[i][1]['value'],
                                description: $scope.records_being_resent[i][2]['value']
                            });
                        }
                    }
                }
            });


            $scope.sendMultipleRecordsToAccounts = function() {
                if ($scope.recordsId.length > 0) {

                    $scope.record_type = 'do_not_send';

                    $scope.log_record_type = tableCollection.collection['do_not_send_logs'].log_record_type;

                    $scope.selectedId = $scope.recordsId;

                    $scope.active_record_title = $scope.log_record_type.replace(/_/g, " ");

                    $scope.title = 'Send records';

                    $scope.deleteUrl = '/send_record/' + $scope.record_type + '/' + $scope.log_record_type;

                    $scope.screenName = 'logsDeleteMultiple';

                    $scope.buttonText = 'Send records';

                    $scope.shouldBeOpenLogsDelete = true;
                }
            }
        });
}

function AccountingInterfaceResendCtrl($scope, $state, $timeout, $rootScope, toastBox, tableCollection, getIdData, $http) {
    $scope.$state = $state;
    $scope.loading_logs = true;
    $scope.current_integration_loaded = false;

    tableCollection.setData('resend_records', getIdData.resend_records);

    $http.get(Routing.generate('accounting_interfaces_resend'))
        .success(function (getIdData) {
            AIAccountCheck.call(this, $scope, $state, getIdData);
            tableCollection.setData('resend_records', getIdData.resend_records);

            $scope.shouldBeOpenLogsDelete = false;

            $rootScope.$on('logs:delete', function(event, index, action, category) {

                $scope.record_type = 'resend';

                $scope.activeRecord = tableCollection.collection[category].data[index];

                $scope.log_record_type = $scope.activeRecord.log_record_type;

                $scope.active_record_title = 'customer';

                if($scope.log_record_type == 'customers') {
                    $scope.active_record_title = 'customer';
                }
                if($scope.log_record_type == 'suppliers') {
                    $scope.active_record_title = 'supplier';
                }
                if($scope.log_record_type == 'customer_payments') {
                    $scope.active_record_title = 'customer payment';
                }
                if($scope.log_record_type == 'customer_invoices') {
                    $scope.active_record_title = 'customer invoice';
                }
                if($scope.log_record_type == 'customer_creditnotes') {
                    $scope.active_record_title = 'customer credit note';
                }
                if($scope.log_record_type == 'supplier_invoices') {
                    $scope.active_record_title = 'supplier invoice';
                }
                if($scope.log_record_type == 'supplier_payments') {
                    $scope.active_record_title = 'supplier payment';
                }
                if($scope.log_record_type == 'supplier_creditnotes') {
                    $scope.active_record_title = 'supplier credit note';
                    $scope.selectedId = $scope.activeRecord['Credit note no'];
                }
                $scope.selectedId = $scope.activeRecord['ID'];

                $scope.title = 'Re-send ' + $scope.active_record_title;

                $scope.deleteUrl = '/'+action+'/'+$scope.record_type+'/'+$scope.log_record_type;

                $scope.screenName = 'logsDelete';

                $scope.buttonText = 'Re-send ' + $scope.active_record_title;

                $scope.shouldBeOpenLogsDelete = true;
            });

            $scope.$on('ReSend', function(evt, data) {
                $scope.$broadcast('smart_tablefilter:filtervalue', data);
            });


            $scope.searchRecordType = 'all';

            $scope.moreFilter=[];
            $scope.recordType = function(type) {
                $scope.searchRecordType = type;
            }
            $scope.$watch('searchRecordType', function (newVal, oldVal) {
                if (newVal != undefined) {
                    var filterData = {};
                    filterData['searchRecordType'] = $scope.searchRecordType;
                    $scope.moreFilter = angular.toJson(filterData);
                    $scope.$broadcast('smarttable_and_sidepanel:filtervalues',  angular.toJson(filterData));
                }
            });

            $scope.$on('fromsmarttable:filters', function(evt, data) {
                $scope.$broadcast('tosidepanel:filtervalues',
                    {
                        'model_name': 'searchRecordType',
                        'value': $scope.searchRecordType
                    }
                );
            });
            $scope.recordsId = [];

            $rootScope.$on('smart_table:pinned_rows_updated', function (event, rows_store) {
                $scope.records_being_resent = rows_store;
                if ($scope.records_being_resent) {
                    $scope.recordsId = [];
                    for (var i = 0, l = $scope.records_being_resent.length; i < l; i++) {
                        if ($scope.recordsId.indexOf($scope.records_being_resent[i][0]['value']) == -1) {
                            $scope.recordsId.push({
                                id: $scope.records_being_resent[i][1]['value'],
                                description: $scope.records_being_resent[i][2]['value']
                            });
                        }
                    }
                }
            });


            $scope.resendMultipleRecordsToAccounts = function() {
                if ($scope.recordsId.length > 0) {

                    $scope.record_type = 'resend';

                    $scope.log_record_type = tableCollection.collection['resend_records'].log_record_type;

                    $scope.selectedId = $scope.recordsId;

                    $scope.active_record_title = $scope.log_record_type.replace(/_/g, " ");

                    $scope.title = 'Re-send records';

                    $scope.deleteUrl = '/send_record/' + $scope.record_type + '/' + $scope.log_record_type;

                    $scope.screenName = 'logsDeleteMultiple';

                    $scope.buttonText = 'Re-send records';

                    $scope.shouldBeOpenLogsDelete = true;
                }
            }
        });
}

function AccountingIntegrationNotificationsCtrl($scope, $state, $http, $rootScope, getIdData)
{
    $scope.email_notifications_loaded = false;
    $http.get(Routing.generate('accounting_interfaces_notifications'))
        .success(function (getIdData) {
            AIAccountCheck.call(this, $scope, $state, getIdData);

            $scope.email_notifications_list = getIdData.email;

            $scope.email_notifications_loaded = true;

            $scope.fetchNotificationContact = function() {
                $scope.email_notifications_loaded = false;
                $http.get(Routing.generate('accounting_interfaces_notifications'))
                    .success(function (data) {
                    $scope.email_notifications_list = data.email;
                    $scope.email_notifications_loaded = true;
                });
            }

            $scope.shouldBeOpen = false;

            $scope.deleteNotificationContact = function(id, email) {
                $scope.selectedId = id;
                $scope.selectedEmail = email;
                $scope.shouldBeOpen = true;
            }

        });
}

function AddNotificationContactCtrl($scope, $state, $timeout, $http, $rootScope, prefix)
{
    $scope.$state = $state;
    $scope.saving = false;

    $scope.checkValidEmail = function() {
      if($scope.email_error === true) {
          $scope.email_error = false;
      }
    }

    $scope.addNotificationContact = function() {
        $scope.email_error = false;
        $scope.saving = true;
        if ($scope.addContact.email == undefined) {
            $scope.saving = false;
            $scope.email_error = true;
            $scope.email_error_message = "Please enter a valid e-mail address";
        }
        else {
            $http.post(prefix + '/add_notifications_email', "email=" + $scope.addContact.email).
                success(function (data) {
                    $scope.saving = false;
                    if (data == 'Success') {
                        $state.transitionTo('loggedin.accounting_interfaces.notifications');
                    }
                    else if (data === "Email already subscribed") {
                        $scope.email_error = true;
                        $scope.email_error_message = "E-mail already subscribed";
                    }
                    else {
                        $scope.email_error = true;
                        $scope.email_error_message = "Please enter a valid e-mail address";
                    }
                });
        }
    }
}

function ViewAccountingInterfaceLog($scope, $state, $timeout, $rootScope, $filter, toastBox, $http, prefix,$window)
{
    $scope.$state = $state;

    if ($state.params.current_log_record_type) {
        $scope.record_type = $state.params.current_log_record_type.replace(/_/g, " ").slice(0, -1);
        $scope.status = $state.params.record_type;
        $scope.last_modified_date_time = $scope.sidepanelData['lastModifiedDateTime'];
    } else {
        $scope.record_type = $scope.sidepanelData.Type.toLowerCase();

        // Need to handle other statuses as well
        $scope.status = 'successful';
    }
    $scope.viewLink = function() {
        var newTab = $window.open('','_blank');
        $http.post(prefix + '/get_ai_view_link', "&current_log_record_type="+$state.params.current_log_record_type+"&record_type=" + $state.params.record_type+"&id=" + $scope.sidepanelData['id']).
            success(function (data, status) {
                if (status == 200) {

                    /*if ($scope.record_type === 'customer') {
                        newTab.location.href = $state.href('loggedin.customer_list.view.property', {
                            'id': data.propertyId,
                            'type': 'customer_list'
                        });
                    }*/
                    if ($scope.record_type === 'customer') {
                        if (data.propertyId === null || data.invoiceType === 2 || data.invoiceType === 3 ) {
                                newTab.location.href = $state.href('loggedin.invoice_address.view.details', {
                                'id': $scope.sidepanelData['id']
                                });
                        } else {
                                newTab.location.href = $state.href('loggedin.customer_list.view.property', {
                                        'id': data.propertyId,
                                        'type': 'customer_list'
                                });

                        }
                     } 
		     else if ($scope.record_type === 'customer invoice') {
                if(data.invoiceType == 'multiple'){
                    if (data.propertyId != null){
                        newTab.location.href = $window.location.origin + '/customers/customer/' + data.propertyId + '/multiple_invoice/' + $scope.sidepanelData['id'] + '/view';
                        }else{
                        newTab.location.href = $window.location.origin + '/invoice_address/' + data.invoiceAddressId + '/multiple_invoice/' + $scope.sidepanelData['id'] + '/view';
                    }
                }else{
                    newTab.location.href = $state.href('loggedin.customer_list.view.job.invoices.view.view_invoice', {
                        'id': data.propertyId,
                        'type': 'customer_list',
                        'jobId': data.jobId,
                        'invoiceId': $scope.sidepanelData['id']
                    });
                }
                    }
                    else if ($scope.record_type === 'customer payment') {
                        if (data.jobId == null){
                            if(data.propertyId != null){
                                newTab.location.href = $state.href('loggedin.customer_list.view.view_multiple_invoice_payment', {
                                    'id': data.propertyId,
                                    'type': 'customer',
                                    'invoiceId': data.invoiceId,
                                    'paymentId': $scope.sidepanelData['id']
                                });
                            }else{
                                newTab.location.href = $state.href('loggedin.invoice_address.view.view_multiple_invoice_payment', {
                                    'id': data.propertyId,
                                    'invoiceId': data.invoiceId,
                                    'paymentId': $scope.sidepanelData['id']
                                });
                            }

                        }else{
                            newTab.location.href = $state.href('loggedin.customer_list.view.job.invoices.view.view_invoice.view_payment', {
                                'id': data.propertyId,
                                'type': 'customer_list',
                                'jobId': data.jobId,
                                'invoiceId': data.invoiceId,
                                'paymentId': $scope.sidepanelData['id']
                            });
                        }
                    }
                    else if ($scope.record_type === 'customer creditnote') {
                        if(data.consolidatedCreditnote === true){
                            if(data.propertyId != null){
                                newTab.location.href = $window.location.origin + '/customers/customer/'+data.propertyId+'/multiple_invoice/'+data.customerInvoiceId+'/credit_note/'+$scope.sidepanelData['id']+'/view';
                            }else{
                                newTab.location.href = $window.location.origin + '/invoice_address/' + data.invoiceAddressId + '/multiple_invoice/' + data.customerInvoiceId + '/credit_note/'+ $scope.sidepanelData['id'] +'/view';
                            }
                        }else{
                            newTab.location.href = $state.href('loggedin.customer_list.view.credit_note.details', {
                                'id': data.propertyId,
                                'type': 'customer_list',
                                'creditNoteId': $scope.sidepanelData['id']
                            });
                        }
                    }
                    else if ($scope.record_type === 'supplier') {
                        newTab.location.href = $state.href("loggedin.list_supplier.view.details", {'id': $scope.sidepanelData['id']});
                    }
                    else if ($scope.record_type === 'supplier invoice') {
                        newTab.location.href = $state.href("loggedin.list_supplier.view.invoice.details", {
                            'id': data.supplierId,
                            'invoiceId':$scope.sidepanelData['id']
                        });
                    }
                    else if ($scope.record_type === 'supplier payment') {
                        newTab.location.href = $state.href('loggedin.list_supplier.view.invoice.view_payment', {
                            'id': data.supplierId,
                            'invoiceId':data.invoiceId,
                            'invoicePaymentId': $scope.sidepanelData['id']
                        });
                    }
                    else if ($scope.record_type === 'supplier creditnote') {
                        newTab.location.href = $state.href('loggedin.list_supplier.view.credit_note.details', {
                            'id': data.supplierId,
                            'creditNoteId': $scope.sidepanelData['id']
                        });
                    }

                    $rootScope.$broadcast('closeAllSidepanels');
                }
            });
    }

    $scope.resendRecord = function() {
        $rootScope.$broadcast('logs:delete', this.$index, 'send_record', $scope.category);
        //toastBox.show('This ' + $scope.record_type + ' will be sent again on the 13th January 2016', 6000);
        $rootScope.$broadcast('closeAllSidepanels');
    }

    $scope.markAsDoNotSend = function() {
        $rootScope.$broadcast('logs:delete', this.$index, 'do_not_send', $scope.category);
        //toastBox.show('This ' + $scope.record_type + ' will not be sent to your accounting package', 6000);
        $rootScope.$broadcast('closeAllSidepanels');
    }
}

function AccountingIntegrationReferencesCtrl($scope, $state, $timeout, $http, prefix, getIdData, defaultPagingValue, canLoad, $q) {
    $scope.records_loaded = false;
    $scope.search_records = false;
    $scope.canceler = null;
    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.handleSettingAccountingReferenceForRecord = function(recordMeta, value, recordsFromXero) {
        var record = _.find($scope.records, {
            type: recordMeta.type,
            id: recordMeta.id,
            lid: recordMeta.lid
        });

        if ($scope.account.packageid === 3 || $scope.account.packageid === 4) {
            var selected_accountingreference = _.find(recordsFromXero, {
                description: value
            });
            record.accountingreference_guid = (selected_accountingreference)?selected_accountingreference.value:null;
            record.accountingreference = (selected_accountingreference)?selected_accountingreference.description:value;
        } else {
            record.accountingreference_guid = null;
            record.accountingreference = value;
        }
        $scope.$apply();
    }

    $scope.linkCustomersAndSuppliersSpreadsheetProps = {
        name: "link_records",
        loadingText: "Loading customers & suppliers",
        disabled: true,
        canAddNewRows: false,
        canDeleteRows: false,
        columnDescriptions: [
            {
                label: "Type",
                name: "type",
                inputType: "input",
                disabled: true
            },
            {
                label: "Commusoft ID",
                name: "id",
                inputType: "input",
                disabled: true
            },
            {
                label: "Name",
                name: "name",
                inputType: "input",
                disabled: true
            },
            {
                label: "Address line 1",
                name: "address",
                inputType: "input",
                disabled: true
            }
        ]
    }

    $http.get(Routing.generate('accounting_interfaces_link'))
        .success(function (getIdData) {
            $scope.account = getIdData.account;
            $scope.records = getIdData.records;
            $scope.recordsCount = getIdData.count;
            $scope.filter = {
                search: '',
                type: 'all',
                with_reference: 'without'
            }
            $scope.records_loaded = true;
            $scope.saving = false;
            $scope.referenceErrors = [];

            $scope.linkCustomersAndSuppliersSpreadsheetProps.existingData = $scope.records;
            $scope.linkCustomersAndSuppliersSpreadsheetProps.disabled = false;

             if ($scope.account.packagename === 'Quickbooks Desktop') {
                $scope.linkCustomersAndSuppliersSpreadsheetProps.columnDescriptions.push({
                    label: "Accounting reference",
                    name: "accountingreference",
                    required: true,
                    inputType: "reselect",
                    loading: false,
                    placeholder: "Please search for the customer/supplier",
                    noResultsTextWithSearch: "We can't find the customer/supplier in your accounting package. Please search again",
                    data: [],
                    saveByDescription: true,
                    ignoreAlreadySelectedValues: true,
                    onChangeFocusNextInput: {
                        empty: true
                    },
                    onChange: function(args) {
                        var cell = args.cell;
                        var column = args.columnDescription;
                        $scope.handleSettingAccountingReferenceForRecord(cell.metaData, cell.value, column.data);
                    }
                });
            } else {
                $scope.linkCustomersAndSuppliersSpreadsheetProps.columnDescriptions.push({
                    label: "Accounting reference",
                    name: "accountingreference",
                    required: true,
                    inputType: "input",
                    placeholder: $scope.account.packagename + " reference",
                    onChange: function(args) {
                        var cell = args.cell;
                        $scope.handleSettingAccountingReferenceForRecord(cell.metaData, cell.value);

                            $('#loading-indicator').show();
                            var recordType = cell.metaData.type;
                            canLoad.setLoadValue(false);
                            if ($scope.canceler) {
                                $scope.canceler.resolve();
                            }
                            $scope.canceler = $q.defer();
                            $http.get(Routing.generate('accounting_interfaces_valid_package_reference', {record_type: recordType}) + '?search=' + cell.value + '&packageId=' + $scope.account.packageid+ '&commusoftid=' + cell.metaData.id, {timeout: $scope.canceler.promise}).then(function (response) {
                                if (cell.value && response.data == 0) {
                                    if (!_.find($scope.referenceErrors, {
                                            rowIndex: args.cell.rowIndex
                                        })) {
                                        $scope.referenceErrors.push(
                                            {
                                                rowIndex: args.cell.rowIndex,
                                                type: "warning",
                                                message: ($scope.account.packageid == 5 || $scope.account.packageid == 10) ?"Duplicate accounting reference" : "Invalid accounting reference",
                                                additional_message: " - " + cell.value
                                            }
                                        );
                                    }
                                    _.find($scope.referenceErrors, function (referenceError, key) {
                                        if (referenceError && (referenceError.rowIndex === args.cell.rowIndex)) {
                                            referenceError.additional_message = " - " + cell.value;
                                            $scope.referenceErrors[key] = referenceError;
                                         }
                                    });
                                }
                                else if (cell.value == '' && response.data == 0) {
                                    _.find($scope.referenceErrors, function (referenceError, key) {
                                        if (referenceError && (referenceError.rowIndex === args.cell.rowIndex)) {
                                            $scope.referenceErrors.splice(key, 1);
                                        }
                                    });
                                }
                                else if(response.data == 1) {
                                    _.find($scope.referenceErrors, function (referenceError, key) {
                                        if (referenceError && (referenceError.rowIndex === args.cell.rowIndex)) {
                                            $scope.referenceErrors.splice(key, 1);
                                        }
                                    });
                                }
                                $('#loading-indicator').hide();
                            });
                    }
                });
            }

            // Handle the search
            var canceler = null;
            $scope.fetchRecords = function (pageNum) {
                canLoad.setLoadValue(false);  // Turn off AJAX
                if (canceler) {
                    canceler.resolve();
                }
                canceler = $q.defer();
                var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
                $http.get(Routing.generate('accounting_interfaces_link') + '?page=' + pageNum + '&limit=' + $scope.limit+'&filter='+angular.toJson($scope.filter), {timeout: canceler.promise})
                    .success(function (data) {
                        $scope.records = data.records;
                        $scope.recordsCount = data.count;
                        $scope.linkCustomersAndSuppliersSpreadsheetProps.existingData = $scope.records;
                        $scope.referenceErrors = [];
                    })
            }

            $scope.handleFilters = function() {
                $scope.search_records = true;
                $scope.fetchRecords($scope.currentPage);
            };

            $scope.saveAccountingReferences = function() {
                $scope.saving = true;
                $http.post(prefix + '/accounting_interfaces_link_update', 'records='+encodeURIComponent(angular.toJson($scope.records)))
                    .success(function (data) {
                        $scope.saving = false;
                        $state.transitionTo('loggedin.accounting_interfaces.link', null, {reload: false});
                    });
            };

            $scope.setActiveRecord = function(record) {
                $scope.active_record = record;
            }

            $scope.completeMeOnSelect = function(complete_me_scope) {
                var selected_record = complete_me_scope.selected_record,
                    record = complete_me_scope.$parent.record;

                record.accountingreference = selected_record;
                record.accountingreference_guid = (complete_me_scope.reference)?complete_me_scope.reference[selected_record]:selected_record;
            }

            $scope.completeMeOnAdd = function(complete_me_scope) {
                var selected_record = complete_me_scope.new_cell_value,
                    record = complete_me_scope.$parent.record;

                record.accountingreference = selected_record;
                record.accountingreference_guid = (complete_me_scope.reference)?complete_me_scope.reference[selected_record]:selected_record;
            }
        });
}

function ShowContractCtrl($rootScope, $scope, datasets, $state, $http, $location, prefix) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    var server = $location.protocol() + '://' + $location.host();
    $scope.contractPdf = server + '/data/' + $rootScope.clientId + '/document/contract/' + datasets.contract.pdfLocation;
    $scope.companyname = datasets.contract.companyname;
    $scope.$emit('change:layoutValue', true);
    $scope.postProcessCheck = function(data, status) {
        $scope.$emit('change:layoutValue', false);
    }
}

function MakePaymentCtrl($scope, datasets, $state, $timeout, $http, prefix, cardExpiryMonths, cardExpiryYears, $rootScope)
{
    $scope.paymentRequiredDetails = datasets.invoiceDetails.details;

    $scope.cardExpiryMonths = cardExpiryMonths.getCardExpiryMonths();
    $scope.cardExpiryYears = cardExpiryYears.getCardExpiryYears();

    $scope.$emit('change:layoutValue', true);

    $scope.savePayment = function($event, formStatus)
    {   
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.current = this;
        $scope.error = false;
        $scope.paymentSaving = true;

        $http.post(prefix + '/make_client_payment', "payment[cardnumber]=" + this.cardNumber
                + "&payment[expirymonth]=" + this.cardExpiryMonth
                + "&payment[expiryyear]=" + this.cardExpiryYear
                + "&payment[cvc]=" + this.cardCvc
                + "&payment[name]=" + this.billingName
                + "&payment[addressline1]=" + this.billingAddressLine1
                + "&payment[addressline2]=" + this.billingAddressLine2
                + "&payment[town]=" + this.billingTown
                + "&payment[county]=" + this.billingCounty
                + "&payment[country]=" + this.billingCountry
                + "&payment[postcode]=" + this.billingPostcode).
            success(function (data, status) {
                
                if( data.records.data && data.records.data.status == 'requires_source_action') {
                    // load stripe js
                    if(_.has($rootScope.loadedScript, 'stripeJs')) {
                        $rootScope.loadedScript.stripeJs.then(function(isLoaded){
                        if( !isLoaded && _.isEmpty($scope.publicKey) ) {
                            throw "StripeJs or Public key not found....";
                        }
                        });
                    }
                    $scope.publicKey = atob(data.records.data.stripe_api_public_key);
                    $scope.stripe = Stripe($scope.publicKey);
                    //
                    $scope.stripe.confirmCardPayment(atob(data.records.data.client_secret))
                    .then(function(result){
                        //console.log('result', result);
                        if( result.error ) {
                            $scope.paymentSaving = false;
                            $scope.error = result.error.message;
                            $scope.status = "error";
                        } else {
                            $scope.status = result['paymentIntent']['status'];
                        }
                        
                        fetch(prefix + '/confirm_client_payment', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                data: data.records.UUID,
                                result: $scope.status,
                                action: "invoice"
                            })
                        }).then(function(confirmResult) {
                            if( $scope.status != "error") {
                                $scope.$emit('change:layoutValue', false);
                                $state.transitionTo('loggedin.customers');
                            }
                                //return confirmResult.json();
                        }).then(function(){
                            if( $scope.status != "error") {
                                $state.transitionTo('loggedin.customers');
                            }

                        });
                        
                });
            } else if (data.records.error) {
                $scope.error = data.records.data;
                $scope.paymentSaving = false;
            } else {
                $scope.$emit('change:layoutValue', false);
                $state.transitionTo('loggedin.customers');
            }
        });
    }
}

function ShowDDMandate($scope, $state, $timeout, $http, prefix,$rootScope)
{
    $scope.formSubmitted = false;
    $scope.isAccountNameVal = '';
    $scope.isSignatoryVal = '';
    $scope.showDDMandateForm = false;
    $scope.showSupportMessage = false;  
    $scope.showConfirmMandate = false;
    $scope.showSuccessMessage = false;
    $scope.$emit('change:layoutValue', true);

    $scope.processPurchase = function($event, formStatus)
    {
        console.log("form submitted")
        $scope.formSubmitted = true;
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        console.log("form submitted2")
        $scope.current = this;
        $scope.error = false;
         
        $http.post(prefix + '/add_client_ddmandate', "ddmandate[firstname]=" + this.givenname
                + "&ddmandate[lastname]=" + this.surname
                + "&ddmandate[address1]=" + this.addressline1
                + "&ddmandate[town]=" + this.city
                + "&ddmandate[postcode]=" + this.postcode
                + "&ddmandate[country]=" + this.country
                + "&ddmandate[accountname]=" + this.name
                + "&ddmandate[sortcode]=" + this.sortcode
                + "&ddmandate[title]=" + this.title
                + "&ddmandate[companyname]=" + this.companyname
                + "&ddmandate[address2]=" + this.addressline2
                + "&ddmandate[address3]=" + this.addressline3
                + "&ddmandate[county]=" + this.county
                + "&ddmandate[email]=" + this.email
                + "&ddmandate[generatepdf]=1"
                + "&ddmandate[accountnumber]=" + this.accountnumber1).
            success(function (data, status) {
                if (data.error) {
                    $scope.error = data.error;
                    $scope.paymentSaving = false;
                } else {
                    $scope.$emit('change:layoutValue', false);
                    //$state.transitionTo('loggedin.customers');
                    $scope.showSuccessMessage = true;
                    $scope.showDDMandateForm = false;
                    $scope.showSupportMessage = false;  
                    $scope.showConfirmMandate = false;
                }
            });
    }
    
    $scope.confirmDDMandate = function($event, formStatus)
    {
        //alert("");
        $scope.formSubmitted = true;
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
       
        $scope.current = this;
        $scope.error = false;
        $scope.showConfirmMandate = true;
        $scope.showDDMandateForm = false;
    }
    
    $scope.isAccountName = function(val) {
        $scope.isAccountNameVal = val;
        if($scope.isSignatoryVal == 'yes' && $scope.isAccountNameVal == 'yes') {
           $scope.showDDMandateForm = true;
           $scope.showSupportMessage = false; 
           $scope.amount = '0.00'; 
           var todaysDate = new Date();
           var todaysMonth = todaysDate.getMonth()+2;
           var todaysYear = todaysDate.getFullYear();

           //$scope.paymentdate = '06/05/2020';
           $scope.paymentdate = '06/'+todaysMonth+'/'+todaysYear;
           $scope.frequency = 'Monthly';  
                    
        } else if($scope.isAccountNameVal == 'no') { 
           $scope.showSupportMessage = true;  
           $scope.showDDMandateForm = false;    
        }
    };
    
    $scope.editDDMandate = function($event) {
        $scope.showConfirmMandate = false;
        $scope.showDDMandateForm = true;
    };
    
    $scope.isSignatory = function(val) {
        $scope.isSignatoryVal = val ;
        if($scope.isSignatoryVal == 'yes' && $scope.isAccountNameVal == 'yes') {
           $scope.showDDMandateForm = true;
           $scope.showSupportMessage = false; 
           $scope.amount = '0.00'; 
           
           var todaysDate = new Date();
           var todaysMonth = todaysDate.getMonth()+2;
           var todaysYear = todaysDate.getFullYear();

           //$scope.paymentdate = '06/05/2020';
           $scope.paymentdate = '06/'+todaysMonth+'/'+todaysYear;
           $scope.frequency = 'Monthly';  
                    
        } else {
           $scope.showSupportMessage = true;  
           $scope.showDDMandateForm = false;    
        }
    };
    
    $scope.showCustomers = function() {
        //alert("showCustomers");
        $scope.$emit('change:layoutValue', false);
        $state.transitionTo('client_show_ddmandate', null, {reload:true});
        //$state.go('loggedin.customers');
        /*$http.post(prefix + '/add_client_ddmandate', "ddmandate[firstname]=1"
                + "&ddmandate[redirectcustomer]=1").
            success(function (data, status) {
                if (data.error) {
                    $scope.error = data.error;
                    $scope.paymentSaving = false;
                } else {
                    //alert("showCustomers-redirect");
                    $scope.$emit('change:layoutValue', false);
                    $state.transitionTo('loggedin.client_show_ddmandate');
                    $state.transitionTo('loggedin.customers');
                    $state.transitionTo('loggedin.client_show_ddmandate');
                    
                }
            });*/
    };
}

function AddCreditCardDetails($scope, datasets, $state, $timeout, $http, prefix, cardExpiryMonths, cardExpiryYears)
{
    $scope.paymentRequiredDetails = datasets.invoiceDetails.details;

    $scope.cardExpiryMonths = cardExpiryMonths.getCardExpiryMonths();
    $scope.cardExpiryYears = cardExpiryYears.getCardExpiryYears();

    $scope.$emit('change:layoutValue', true);

    $scope.savePayment = function($event, formStatus)
    {        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.current = this;
        $scope.error = false;
        $scope.paymentSaving = true;

        $http.post(prefix + '/add_client_creditcard', "payment[cardnumber]=" + this.cardNumber
                + "&payment[expirymonth]=" + this.cardExpiryMonth
                + "&payment[expiryyear]=" + this.cardExpiryYear
                + "&payment[cvc]=" + this.cardCvc
                + "&payment[name]=" + this.billingName
                + "&payment[addressline1]=" + this.billingAddressLine1
                + "&payment[addressline2]=" + this.billingAddressLine2
                + "&payment[town]=" + this.billingTown
                + "&payment[county]=" + this.billingCounty
                + "&payment[country]=" + this.billingCountry
                + "&payment[postcode]=" + this.billingPostcode).
            success(function (data, status) {
                if (data.records.error) {
                    $scope.error = data.records.error;
                    $scope.paymentSaving = false;
                } else {
                    $scope.$emit('change:layoutValue', false);
                    $state.transitionTo(data.records.redirecturl);
                }
            });
    }
}


function SetCurrencyCtrl($scope, $http, $state,prefix, $timeout, datasets, $rootScope, CurrencySymbol){
    $scope.CurrencySymbols = CurrencySymbol.getCurrencySymbol();

    $scope.data = datasets;
    if(datasets.value== 0){
        $scope.CurrencySymbolCode = '';
    }else {

        var decodeHtmlEntity = function(str) {
            return str.replace(/&#(\d+);/g, function(match, dec) {
                return String.fromCharCode(dec);
            });
        };

        var check_symbol = _.where($scope.CurrencySymbols,{code:datasets.value});
        $scope.CurrencySymbolCode = datasets.value;
        if(check_symbol[0].symbol) {
            $rootScope.CustomCurrency = decodeHtmlEntity(check_symbol[0].symbol);
        }else {
            $rootScope.CustomCurrency = '£';
        }
    }

    $scope.save_currency = function($event) {
        $event.preventDefault();

        $http.post(prefix + '/company_settings/set_currency',"value=" + $scope.CurrencySymbolCode).
        success(function(data,status){
            if(status == 200) {
                $scope.saving = true;
                $state.go('loggedin.company_settings');
            }
            else {
                $scope.saving = false;
                console.log('error');
            }

        });

    }
    


}

function PublicHolidayGroupsCtrl($scope, $http, prefix, datasets, formService,$state) {
    $scope.data = datasets;
    $scope.saving = false;
    $scope.$state = $state;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.publichoildayGroupsForm['publichoildaygroups[name]'].$error.checkExists = true;
                $scope.publichoildayGroupsForm['publichoildaygroups[year]'].$error.checkExists = true;
            } else {
                $scope.publichoildayGroupsForm.$setPristine();
                $scope.name = "";
                $scope.year = "";
                $scope.$broadcast("PUBLIC_HOLIDAY_NAME_GROUPS", { 'id': data.id, 'name': data.name,'year': data.year});
            }
            $scope.saving = false;
        });
    }
}


function PublicHolidayGroupsEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedName = $state.params.name;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.public_holiday_groups");
    }

    $scope.editPublicHolidayGroups = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_public_holiday_group', "publicholidaygroups[id]=" + this.selectedId + "&publicholidaygroups[name]=" + encodeURIComponent(this.selectedName)).
        success(function (data, status) {
            if (status == 200) {
                $scope.updating = false;
                if (data.error && data.error == 'Exists') {
                    $scope.current.editPublicHolidayGroupForm['selectedName'].$error.checkExists = true;
                } else {
                    $state.$current.parent.self.data.pageNum = $scope.pageNum;  // Did by inspecting values, TODO: Find out if this is correct
                    $state.$current.parent.self.data.editedId = $scope.selectedId;
                    $state.$current.parent.self.data.limit = $scope.limit;
                    $state.transitionTo("loggedin.public_holiday_groups");
                }
            }
        });
    }
}

function PublicHolidayGroupsListEditCtrl($scope, $state, prefix, $http, publicHoildayGroupsresolver, defaultPagingValue, warningModal) {
    $scope.publicHolidayGroups = publicHoildayGroupsresolver.publicHolidayGroups;
    $scope.totalPublicHolidayGroups = publicHoildayGroupsresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'entity', $state, defaultPagingValue, 'publicHolidayGroups');

    $scope.triggerDelete = function (id, name) {
        $scope.name = name;
            $http.get(prefix + '/check_public_holiday_group_delete/' + id).success(function (data) {
                if (data.warning == true) {
                    warningModal.show('You cannot delete this Public holiday groups because it is used in a contract.', 'Delete the Public holiday groups', data.id);
                } else {
                    $scope.selectedId = id;
                    $scope.shouldBeOpen = true;
                }
            });
    }

    $scope.fetchPublicHolidayGroups = function (pageNum) {
        $http.get(prefix + '/listPublicholidayNames?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.publicHolidayGroups = data.publicHolidayGroups;
            $scope.totalPublicHolidayGroups = data.count;
            publicHoildayGroupsresolver.publicHolidayGroups = data.publicHolidayGroups;
            publicHoildayGroupsresolver.count = data.count;
            $scope.triggerJustAddedUpdatedTags();
        })
    }
    $scope.editPublicHolidayGroup = function (id, name, year) {
        $state.transitionTo('loggedin.public_holiday_groups.edit', {'id': id, 'name': name, 'year': year, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.$on("PUBLIC_HOLIDAY_NAME_GROUPS", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, publicHoildayGroupsresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.publicHolidayGroups.push({'id': message.id, 'name': message.name,'year': message.year, 'justadded': true});
            publicHoildayGroupsresolver.publicHolidayGroups = $scope.publicHolidayGroups;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        publicHoildayGroupsresolver.count = parseInt(publicHoildayGroupsresolver.count) + 1
        $scope.totalPublicHolidayGroups = publicHoildayGroupsresolver.count;

    });
}
function DatePickerWithDefaultDatePublic($scope, $element, $rootScope, clickEvents, $filter) {
    $scope.selectedDate = new Date($scope.reminderDate);
    DatePickerWidget.call(this, $scope, $element, $rootScope, clickEvents, $filter);
}

function ConfigurePublicHolidayDateCtrl($scope, $http, prefix, getIdData,defaultPagingValue, formService,$state,$rootScope) {
    $scope.data = getIdData;
    $scope.saving = false;
    $scope.$state = $state;
    $scope.reminderDate= getIdData.reminderDate;
    $scope.publicHolidayDatesGroups = getIdData.publicHolidayDatesGroups;
    $scope.totalPublicHolidayGroups = getIdData.count;
    $scope.shouldBeOpen = false;
    $scope.minDate = getIdData.minDate;
    $scope.maxDate =getIdData.maxDate;
    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    JustAddedUpdatedChanges.call(this, $scope, 'ConfigurePublicHolidayDate', $state, defaultPagingValue, 'publicHolidayDatesGroups');
    $scope.triggerDelete = function (groupdateid) {
        $scope.selectedId = groupdateid;
        $scope.shouldBeOpen = true;
    };

    $scope.$on('modal:close',function(){
        $scope.shouldBeOpen = false;
    });


    $scope.fetchPublicHolidayDatesGroups = function (pageNum,limit) {
        $http.get(prefix + '/company_settings/public_holiday_groups/'+$state.params.id+'/configure?page=' + pageNum + '&limit=' + limit).success(function (data) {
            $scope.publicHolidayDatesGroups = data.publicHolidayDatesGroups;
            $scope.totalPublicHolidayGroupDates = data.count;
            getIdData.publicHolidayDatesGroups = data.publicHolidayDatesGroups;
            getIdData.count = data.count;
            $scope.currentPage=pageNum;
            $scope.limit=limit;
            $scope.triggerJustAddedUpdatedTags();
        })
    }

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.publichoildayGroupsDateForm['publicholidaygroupdate[holidaydate]'].$error.checkDateExists= true;
                $scope.publichoildayGroupsDateForm.$pristine=false;
            } else {
                $scope.publichoildayGroupsDateForm.$setPristine();
                $scope.holidayDate = '';
                var count = parseInt(getIdData.count) + 1;
                $scope.currentNewPage = (count % $scope.limit == 0) ? Math.floor(count / $scope.limit) : (Math.floor(count / $scope.limit) + 1);

                if ($scope.currentPage == $scope.currentNewPage) {
                    $scope.publicHolidayDatesGroups.push({
                        'id': data.id,
                        'groupdateid': data.groupdateid,
                        'holidaydate': data.holidaydate,
                        'justadded': true
                    });
                    getIdData.publicHolidayDatesGroups = $scope.publicHolidayDatesGroups;
                    $state.current.data.addedId = -1;
                }
                else {
                    $scope.currentPage=$scope.currentNewPage;
                    }
                getIdData.count = parseInt(getIdData.count) + 1
                $scope.totalPublicHolidayGroupDates = getIdData.count;
            }
            $scope.saving = false;
        });
    }
}

function PublicHolidayGroupManager($scope, public_holiday_group) {
$scope.select2OptionsHolidayGroups = {
placeholder: "Select some public holiday groups",
'multiple': true,
id: function (obj) {
    return obj.id;
},
'data': public_holiday_group,
formatResult: function (item) {
    return item.text;
},
formatSelection: function (item) {
    return item.text;
},
formatNoMatches: function (item) {
    if (item != '') {
        return 'No results match "' + item + '"';
    } else {
        return "";
    }
}
};
}

var PublicholidayGroupsResolver = {
public_holiday_group: function ($q, $http, prefix) {
if (typeof(this.templateUrl) == 'string') {
    return;
}
var deferred = $q.defer();
$http.get(prefix + '/search_publicholiday_groups?searchText=').success(function (data) {
    deferred.resolve(data);
});
return deferred.promise;
}
}
