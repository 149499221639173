commusoftCommon.service('formPanelCollection', function(formBuilder, $rootScope, $translate) {
    // Used everywhere postcode lookup is needed
    this.postcode_form_inputs = {
        fields: [
            {
                label: 'Postcode',
                type: 'postcode',
                required: true,
                error_message: $translate('Please.Enter.Valid.Postcode'),
                model: 'postcode'
            }
        ]
    };
    this.county_form_inputs = {
        fields: [
            {
                label: $translate('Province'),
                type: 'text',
                required: true,
                error_message: $translate('Please.enter.province'),
                model: 'county',
                check_exists: 'county',
                check_exists_url: '/is_province_exists?province=',
                exists_error_message: $translate('This.province.description.already.exists')
            }
        ]
    };

    this.quick_add_categories = [];

    this.setFilterData = function setFilterData(Value){
        this.quick_add_categories = Value;
    }

    this.setMoreFilterData = function setMoreFilterData(category, Value){
        this.quick_add_categories[category] = Value;
    }
    this.getMoreFilterData = function getMoreFilterData(category) {
        return this.quick_add_categories[category];
    }
    this.getFieldCollection = function getFieldCollection(category) {
        if (! this.quick_add_categories.hasOwnProperty('postcode')) {
            this.quick_add_categories['postcode'] = this.postcode_form_inputs;
        }
        if (! this.quick_add_categories.hasOwnProperty('county')) {
            this.quick_add_categories['county'] = this.county_form_inputs;
        }
        if(this.quick_add_categories.hasOwnProperty('pricing_item')){
            $rootScope.$broadcast('pricing_item:url',this.quick_add_categories);
        }
        return this.quick_add_categories[category].fields;
    }

    this.handleInputs = function handleInputs(category) {
        var fields = this.getFieldCollection(category);
        formBuilder.assessbleInputs(fields);
    }

    this.getInputTemplates = function getInputTemplates() {
        var templates = formBuilder.getTemplates();
        return templates;
    }
});