commusoftCommon.service('navSidebar', function () {
    var subnavItems = [
        { id: 'accounting_interface',
            firstTier: 'Accounting.Interface',
            secondTier: [
                { id: 'accounting_package_log',
                    item: 'Accounting.Package.Log',
                    state: 'accounting_package_log',
                    description: 'Accounting.Package.Log.Stores',
                    method: 'fetchAIAuditAction'
                },
                { id: 'accounting_package_failure_log',
                    item: 'Accounting.Package.Failure.Log',
                    state: 'accounting_package_failure_log',
                    description: 'Accounting.Package.Failure.Log.Stores'
                }
            ]
        },
        { id: 'transactions',
            firstTier: 'Transactions',
            secondTier: [
                { id: 'text_messages',
                    item: 'Text.Messages',
                    state: 'text_messages',
                    description: 'Text.Messages.Log'
                },
                { id: 'postcode_lookups',
                    item: 'Postcode.Lookups',
                    state: 'postcode_lookups',
                    description: 'Postcode.Lookups.Log'
                },
                { id: 'print_and_post',
                    item: 'Print.And.Post',
                    state: 'print_and_post',
                    description: 'Print.And.Post.Log'
                },
                { id: 'all_edits',
                    item: 'All.Edits',
                    state: 'all_edits',
                    description: 'List.Of.Every.Edit'
                },
                { id: 'all_deletes',
                    item: 'All.Deletes',
                    state: 'all_deletes',
                    description: 'List.Of.Every.Deleted.Records'
                },
                { id: 'invoice_additions',
                    item: 'Added.Records',
                    state: 'invoice_additions',
                    description: 'List.Of.Every.Records'
                }
            ]
        },
        { id: 'customer_and_tenant',
            firstTier: 'Customer.And.Tenant',
            secondTier: [
                { id: 'customer_transfer',
                    item: 'Transfer',
                    state: 'customer_transfer',
                    description: 'Transfer.Log'
                },
                { id: 'customer_merge',
                    item: 'Merge',
                    state: 'customer_merge',
                    description: 'Merge.Log'
                },
                { id: 'customer_convert',
                    item: 'Convert',
                    state: 'customer_convert',
                    description: 'Convert.Log'
                },
                { id: 'customer_login_audit',
                    item: 'Login.Audit',
                    state: 'customer_login_audit',
                    description: 'Login.Audit.Shows'
                }
            ]
        },
        { id: 'estimates',
            firstTier: 'Estimates',
            secondTier: [
                { id: 'move_back_stage',
                    item: 'Moved.Back.a.Stage',
                    state: 'move_back_stage',
                    description: 'Shows.List.Of.Estimates.'
                },
                { id: 'move_to_different_property',
                    item: 'Moved.to.a.Different.Property',
                    state: 'move_to_different_property',
                    description: 'Estimate.Different.Property.log'
                }
            ]
        },
        { id: 'jobs',
            firstTier: 'Jobs',
            secondTier: [
                { id: 'jobs_moved_property',
                    item: 'Moved.to.a.Different.Property',
                    state: 'jobs_moved_property',
                    description: 'Job.Different.Property.log'
                }
            ]
        },
        { id: 'invoices',
            firstTier: 'invoices',
            secondTier: [
                { id: 'invoices_moved_property',
                    item: 'Moved.to.a.Different.Property',
                    state: 'invoices_moved_property',
                    description: 'Invoice.Different.Property.log'
                }
            ]
        },
        { id: 'login_access',
            firstTier: 'Login.Access',
            secondTier: [
                { id: 'login_audit',
                    item: 'Login.Audit',
                    state: 'login_audit',
                    description: 'Login.Audit.Description'
                },
                { id: 'mobile_access',
                    item: 'Mobile.Access',
                    state: 'mobile_access',
                    description: 'Mobile.Access.Description'
                }
            ]
        }
    ];

    this.getsubnavItems = function () {
        return subnavItems;
    };
});