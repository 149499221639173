angular.module('ui.datepicker', ['ui.bootstrap.position'])
  .directive( 'uidatepicker', ['dateFilter', '$parse', 'datepickerConfig', '$log', function (dateFilter, $parse, datepickerConfig, $log) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'template/datepicker/datepicker.html',
      scope: {
        dateDisabled: '&'
      },
      require: ['uidatepicker', '?^ngModel'],
      controller: 'DatepickerController',
      link: function(scope, element, attrs, ctrls) {
        var datepickerCtrl = ctrls[0], ngModel = ctrls[1];

        if (!ngModel) {
          return; // do nothing if no ng-model
        }

        // Configuration parameters
        var mode = 0, selected = new Date(), showWeeks = datepickerConfig.showWeeks;

        if (attrs.showWeeks) {
          scope.$parent.$watch($parse(attrs.showWeeks), function(value) {
            showWeeks = !! value;
            updateShowWeekNumbers();
          });
        } else {
          updateShowWeekNumbers();
        }

        if (attrs.min) {
          scope.$parent.$watch($parse(attrs.min), function(value) {
            datepickerCtrl.minDate = value ? new Date(value) : null;
            refill();
          });
        }
        if (attrs.max) {
          scope.$parent.$watch($parse(attrs.max), function(value) {
            datepickerCtrl.maxDate = value ? new Date(value) : null;
            refill();
          });
        }

        function updateShowWeekNumbers() {
          scope.showWeekNumbers = mode === 0 && showWeeks;
        }

        // Split array into smaller arrays
        function split(arr, size) {
          var arrays = [];
          while (arr.length > 0) {
            arrays.push(arr.splice(0, size));
          }
          return arrays;
        }

        function refill( updateSelected ) {
          var date = null, valid = true;

          if ( ngModel.$modelValue ) {
            date = new Date( ngModel.$modelValue );

            if ( isNaN(date) ) {
              valid = false;
              $log.error('Datepicker directive: "ng-model" value must be a Date object, a number of milliseconds since 01.01.1970 or a string representing an RFC2822 or ISO 8601 date.');
            } else if ( updateSelected ) {
              selected = date;
            }
          }
          ngModel.$setValidity('date', valid);

          var currentMode = datepickerCtrl.modes[mode], data = currentMode.getVisibleDates(selected, date);
          angular.forEach(data.objects, function(obj) {
            obj.disabled = datepickerCtrl.isDisabled(obj.date, mode);
          });

          ngModel.$setValidity('date-disabled', (!date || !datepickerCtrl.isDisabled(date)));

          scope.rows = split(data.objects, currentMode.split);
          scope.labels = data.labels || [];
          scope.title = data.title;
        }

        function setMode(value) {
          mode = value;
          updateShowWeekNumbers();
          refill();
        }

        ngModel.$render = function() {
          refill( true );
        };

        scope.select = function( date ) {
          if ( mode === 0 ) {
            var dt = ngModel.$modelValue ? new Date( ngModel.$modelValue ) : new Date(0, 0, 0, 0, 0, 0, 0);
            dt.setFullYear( date.getFullYear(), date.getMonth(), date.getDate() );
            ngModel.$setViewValue( dt );
            refill( true );
          } else {
            selected = date;
            setMode( mode - 1 );
          }
        };
        scope.move = function(direction) {
          var step = datepickerCtrl.modes[mode].step;
          selected.setMonth( selected.getMonth() + direction * (step.months || 0) );
          selected.setFullYear( selected.getFullYear() + direction * (step.years || 0) );
          refill();
        };
        scope.toggleMode = function() {
          setMode( (mode + 1) % datepickerCtrl.modes.length );
        };
        scope.getWeekNumber = function(row) {
          return ( mode === 0 && scope.showWeekNumbers && row.length === 7 ) ? getISO8601WeekNumber(row[0].date) : null;
        };

        function getISO8601WeekNumber(date) {
          var checkDate = new Date(date);
          checkDate.setDate(checkDate.getDate() + 4 - (checkDate.getDay() || 7)); // Thursday
          var time = checkDate.getTime();
          checkDate.setMonth(0); // Compare with Jan 1
          checkDate.setDate(1);
          return Math.floor(Math.round((time - checkDate) / 86400000) / 7) + 1;
        }
      }
    };
  }])
  .directive('uidatepickerPopup', ['$compile', '$parse', '$document', '$position', 'dateFilter', 'datepickerPopupConfig', 'datepickerConfig',
    function ($compile, $parse, $document, $position, dateFilter, datepickerPopupConfig, datepickerConfig) {
      return {
        restrict: 'EA',
        require: 'ngModel',
        link: function(originalScope, element, attrs, ngModel) {
          var scope = originalScope.$new(),
            dateFormat,
            closeOnDateSelection = angular.isDefined(attrs.closeOnDateSelection) ? originalScope.$eval(attrs.closeOnDateSelection) : datepickerPopupConfig.closeOnDateSelection,
            appendToBody = angular.isDefined(attrs.datepickerAppendToBody) ? originalScope.$eval(attrs.datepickerAppendToBody) : datepickerPopupConfig.appendToBody;

          attrs.$observe('uidatepickerPopup', function(value) {
            dateFormat = value || datepickerPopupConfig.dateFormat;
            ngModel.$setViewValue(dateFilter(ngModel.$modelValue, dateFormat) );
            ngModel.$render();
          });

          scope.showButtonBar = angular.isDefined(attrs.showButtonBar) ? originalScope.$eval(attrs.showButtonBar) : datepickerPopupConfig.showButtonBar;

          originalScope.$on('$destroy', function() {
            $popup.remove();
            scope.$destroy();
          });

          attrs.$observe('currentText', function(text) {
            scope.currentText = angular.isDefined(text) ? text : datepickerPopupConfig.currentText;
          });
          attrs.$observe('toggleWeeksText', function(text) {
            scope.toggleWeeksText = angular.isDefined(text) ? text : datepickerPopupConfig.toggleWeeksText;
          });
          attrs.$observe('clearText', function(text) {
            scope.clearText = angular.isDefined(text) ? text : datepickerPopupConfig.clearText;
          });
          attrs.$observe('closeText', function(text) {
            scope.closeText = angular.isDefined(text) ? text : datepickerPopupConfig.closeText;
          });

          var getIsOpen, setIsOpen;
          if ( attrs.isOpen ) {
            getIsOpen = $parse(attrs.isOpen);
            setIsOpen = getIsOpen.assign;

            originalScope.$watch(getIsOpen, function updateOpen(value) {
              scope.isOpen = !! value;
            });
          }
          scope.isOpen = getIsOpen ? getIsOpen(originalScope) : false; // Initial state

          function setOpen( value ) {
            if (setIsOpen) {
              setIsOpen(originalScope, !!value);
            } else {
              scope.isOpen = !!value;
            }
          }

          var documentClickBind = function(event) {
            if (scope.isOpen && event.target !== element[0]) {
              scope.$apply(function() {
                setOpen(false);
              });
            }
          };

          var elementFocusBind = function() {
            scope.$apply(function() {
              setOpen( true );
            });
          };

          // popup element used to display calendar
          var popupEl = angular.element('<div uidatepicker-popup-wrap><div uidatepicker></div></div>');
          popupEl.attr({
            'ng-model': 'date',
            'ng-change': 'dateSelection()'
          });
          var datepickerEl = angular.element(popupEl.children()[0]),
            datepickerOptions = {};
          if (attrs.datepickerOptions) {
            datepickerOptions = originalScope.$eval(attrs.datepickerOptions);
            datepickerEl.attr(angular.extend({}, datepickerOptions));
          }

          function parseDate(viewValue) {
            if (!viewValue) {
              ngModel.$setValidity('date', true);
              return null;
            } else if (angular.isDate(viewValue)) {
              ngModel.$setValidity('date', true);
              return viewValue;
            } else if (angular.isString(viewValue)) {
              var date = new Date(viewValue);
              if (isNaN(date)) {
                ngModel.$setValidity('date', false);
                return undefined;
              } else {
                ngModel.$setValidity('date', true);
                return date;
              }
            } else {
              ngModel.$setValidity('date', false);
              return undefined;
            }
          }
          ngModel.$parsers.unshift(parseDate);

          // Inner change
          scope.dateSelection = function(dt) {
            if (angular.isDefined(dt)) {
              scope.date = dt;
            }
            ngModel.$setViewValue(dateFilter(scope.date, dateFormat) );
            ngModel.$render();

            if (closeOnDateSelection) {
              setOpen( false );
            }
          };

          element.bind('input change keyup', function() {
            scope.$apply(function() {
              scope.date = ngModel.$modelValue;
            });
          });

          // Outter change
          ngModel.$render = function() {
            var date = ngModel.$viewValue ? dateFilter(ngModel.$modelValue, dateFormat) : '';
            element.val(date);
            scope.date = ngModel.$modelValue;
          };
          originalScope.$watch('watchlookup', function(n){
            var date = ngModel.$viewValue ? dateFilter(ngModel.$modelValue, dateFormat) : '';
            element.val(date);
            scope.date = ngModel.$modelValue;
          });

          function addWatchableAttribute(attribute, scopeProperty, datepickerAttribute) {
            if (attribute) {
              originalScope.$watch($parse(attribute), function(value){
                scope[scopeProperty] = value;
              });
              datepickerEl.attr(datepickerAttribute || scopeProperty, scopeProperty);
            }
          }
          addWatchableAttribute(attrs.min, 'min');
          addWatchableAttribute(attrs.max, 'max');
          if (attrs.showWeeks) {
            addWatchableAttribute(attrs.showWeeks, 'showWeeks', 'show-weeks');
          } else {
            scope.showWeeks = 'show-weeks' in datepickerOptions ? datepickerOptions['show-weeks'] : datepickerConfig.showWeeks;
            datepickerEl.attr('show-weeks', 'showWeeks');
          }
          if (attrs.dateDisabled) {
            datepickerEl.attr('date-disabled', attrs.dateDisabled);
          }

          function updatePosition() {
            scope.position = appendToBody ? $position.offset(element) : $position.position(element);
            scope.position.top = scope.position.top + element.prop('offsetHeight');
          }

          var documentBindingInitialized = false, elementFocusInitialized = false;
          scope.$watch('isOpen', function(value) {
            if (value) {
              updatePosition();
              $document.bind('click', documentClickBind);
              if(elementFocusInitialized) {
                element.unbind('focus', elementFocusBind);
              }
              element[0].focus();
              documentBindingInitialized = true;
            } else {
              if(documentBindingInitialized) {
                $document.unbind('click', documentClickBind);
              }
              element.bind('focus', elementFocusBind);
              elementFocusInitialized = true;
            }

            if ( setIsOpen ) {
              setIsOpen(originalScope, value);
            }
          });

          scope.today = function() {
            scope.dateSelection(new Date());
          };
          scope.clear = function() {
            scope.dateSelection(null);
          };

          var $popup = $compile(popupEl)(scope);
          if ( appendToBody ) {
            $document.find('body').append($popup);
          } else {
            element.after($popup);
          }

            scope.$on("renderDate", function(event, date) {
                scope.dateSelection(date);
                return true;
            });
        }
      };
    }])
  .directive('uidatepickerPopupWrap', function() {
    return {
      restrict:'EA',
      replace: true,
      transclude: true,
      templateUrl: 'template/datepicker/popup.html',
      link:function (scope, element, attrs) {
        element.bind('click', function(event) {
          event.preventDefault();
          event.stopPropagation();
        });
      }
    };
  });




