csmodule.service('stock_data', function() {

    this.stock = [];
    this.setStockData = function setStockData(fullObject){
        this.stock = [fullObject];
    }

  this.new_option = [];
  this.setData = function setData(fullObject){
      this.new_option = [fullObject];
  }

  this.getStock = function getStock() {
    return stock;
  };

  this.getStockById = function getStockById(id) {
    for (var i = 0, l = this.stock.length; i < l; i++) {
      if (this.stock[i].details[0].id === parseInt(id)) {
        return this.stock[i];
      }
    }
  }

  this.getTransferOptions = function getTransferOptions(){
    var tmp_option = [];

    for(var i = 0, l = this.new_option.length; i < l; i++) {
      var tmp_obj = {};
      var cloned_obj = _.extend(tmp_obj, this.new_option[i]);
      tmp_option.push(cloned_obj);
    }

    return tmp_option[0];
  }
  this.getCurrentTransferOptions = function getCurrentTransferOptions(){
    return this.new_option;
  }
});
