csmodule.directive('csDateRangePicker', ['prefix', '$rootScope', '$http', '$modal', '$timeout', function(prefix, $rootScope, $http, $modal, $timeout) {
    return {
        restrict: 'EA',
        scope: true,
        templateUrl: 'template/date_range_picker.html',
        controller:CSDateRangePickerCtrl
    }}]);

function CSDateRangePickerCtrl($scope, $http, prefix, $state, dateRange, clickEvents, $rootScope){
    $scope.$parent = $scope.$parent;
    $scope.minDate = $scope.$parent.minDate;
    $scope.date_range_valid = true;
    $scope.cancelPicker = function () {
        $rootScope.$emit('event:date_range_picker', {'close':true, 'apply': false});
    };
    $scope.applyPickerChanges = function() {
        $rootScope.$emit('event:date_range_picker', {'close':true, 'apply':true,'start_date': $scope.start_date, 'end_date': $scope.end_date});
    };
    /**
     * function to check end date is before the start date
     *
     * @param start
     * @param end
     * return boolean
     */
    function checkValidRange(start, end) {
        var mStart = moment(start);
        var mEnd = moment(end);
        return mStart.isBefore(mEnd);
    }

    /**
     * watch the end date changes
     */
    $scope.$watch('end_date', function(newValues, oldValues) {
        $scope.date_range_valid = checkValidRange($scope.start_date, $scope.end_date);
    });
    /**
     * watch the start date changes
     */
    $scope.$watch('start_date', function(newValues, oldValues) {
        $scope.date_range_valid = checkValidRange($scope.start_date, $scope.end_date)
    });
}