csmodule.service('dateLocale', function($rootScope) {
  this.init = function init() {

    $rootScope.date_locale = 'en-GB'; // set in admin by client services
    //$rootScope.date_locale = 'en-US'; // set in admin by client services

    this.configureMoment();
  }

  this.configureMoment = function configureMoment() {
    var great_britain = $rootScope.date_locale == 'en-GB',
        america = $rootScope.date_locale == 'en-US';

    if(great_britain) {
      $rootScope.date_short_format = 'D/M/YY';
      $rootScope.date_long_format = 'DD/MM/YYYY';
    }else if(america) {
      $rootScope.date_short_format = 'M/D/YY';
      $rootScope.date_long_format = 'MM/DD/YYYY';
    }
  }

  this.isDateValid = function isDateValid(date) {
    var date_is_valid = moment(date, $rootScope.date_long_format, $rootScope.date_locale, true).isValid() ||
                        moment(date, $rootScope.date_short_format, $rootScope.date_locale, true).isValid();

    return date_is_valid;
  }
});