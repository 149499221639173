csmodule.service('supplierCashAllocationHelper', function($rootScope, $q, $timeout) {
    var self;

    this.markAllInvoicesAsPaid = function markAllInvoicesAsPaid(context, directive_scope, batch_process_rows_store) {
        var deferred = $q.defer();
        var amountToAllocateIndex = 6;
        //Todo: this is a temp fix, Need to change this once we got the clear requirement for customer and supplier allocations
        if(directive_scope.category == "new_customer_cash_allocation_invoices") {
            amountToAllocateIndex = 6;
        }

        for(var i = 0, l = batch_process_rows_store.length; i < l; i++) {
            var invoice_amount = batch_process_rows_store[i].row[0].associated_rows[0][amountToAllocateIndex].value,
                row_index_to_activate = batch_process_rows_store[i].row_index;

            batch_process_rows_store[i].row[0].associated_rows[0][amountToAllocateIndex-1].value = invoice_amount.toFixed(2);
            batch_process_rows_store[i].row[0].associated_rows[0][amountToAllocateIndex+1].value = 0;
            directive_scope.spreadsheet_rows[row_index_to_activate].row[0].associated_rows[0][amountToAllocateIndex].highlighted = true;
            directive_scope.spreadsheet_rows[row_index_to_activate].row[0].associated_rows[0][amountToAllocateIndex].withTransition = true;
        }

        for(var i = 0, l = directive_scope.spreadsheet_rows.length; i < l; i ++) {
            directive_scope.spreadsheet_rows[i].batched = false;
        }

        batch_process_rows_store = [];
        deferred.resolve();
        return deferred.promise;
    }
});