/** Job Duration Settings screen resolver ======================================*/
var JobDuartionSettingsResolver = {
    data: function ($q, $http, $stateParams, $state, PubNub, $rootScope, cacheBust, prefix, adminPrefix) {
        var deferred = $q.defer();
        $http.get(Routing.generate('getJobsDurationSettings')).success(function (data, status, headersObj) {
            var headers = headersObj();
            SetRootScopeValues($rootScope, headers, PubNub, cacheBust, $http, prefix, adminPrefix, $state);
            if (data.location && data.location == 'logout') {
                $rootScope.$broadcast('chat:unregister');
                $state.transitionTo('home');
            } else if (data.location && data.location.endsWith("/")) {
                //$state.transitionTo('home');
            } else {
                deferred.resolve(data);
            }
        });

        return deferred.promise;
    }
};

var JobDurationSettingsCtrl = function ($scope, $rootScope, $http, data, $state, $q, $timeout, toastBox) {
    $scope.data = data;
    $scope.formValue = null;
    $scope.action = null;
    $scope.selectedForEdit = null;
    $scope.selectedId = null;
    $scope.shouldBeOpen = false;
    $scope.rightSection = 'LIST_SCREEN';
    $scope.jobDurationIsDirty = false;

    $scope.setJobsDurationSettings = function (data) {
        $scope.formValue = {
            allowJobTimeFix: data.allowJobTimeFix || false,
            jobDuration: data.jobDuration ? moment(data.jobDuration, "HH:mm")._d : moment("03:00", "HH:mm")._d,
            durationWindows: angular.copy(data.durationWindows) || []
        };
    };
    $scope.setJobsDurationSettings($scope.data);

    $scope.getJobDurationMinutes = function () {
        return moment.duration(moment($scope.formValue.jobDuration).format('HH:mm')).asMinutes();
    };

    $scope.durationWindowFormValue = angular.copy(getDurationWindowDefault());
    $scope.editDurationWindowFormValue = angular.copy(getDurationWindowDefault());

    $scope.onAllowJobTimeFixChange = function (allow) {
        if(allow) {
            initSortable();
            $scope.adjustListViewContainerHeight();
        }
    };

    $scope.onDurationChange = function (model) {
        if (moment(model.$modelValue).format('HH:mm') !== $scope.data.jobDuration) {
            setJobDurationIsDirty(true);
        } else {
            setJobDurationIsDirty(false);
        }

        /** Note: Preserving this validation for later

         var durationInMinutes = moment.duration(moment(model.$modelValue).format('HH:mm')).asMinutes();

         var spillOverWindows = $scope.formValue.durationWindows.filter(function (w) {
            if (w.windowType === "OVERDUE") {
                return false;
            }
            else if (w.windowType === "LAST_X_MINUTES") {
                return (durationInMinutes - w.end) < 0;
            }
            else if (w.windowType === 'OTHER') {
                return (durationInMinutes - (w.end + w.start)) < 0;
            }
        });

         if (spillOverWindows.length) {
            model.$error.windowSpillOver = true;
        }
         else model.$error.windowSpillOver = false;

         $scope.formValue.durationWindows.forEach(function (w) {
            w.spillover = spillOverWindows.indexOf(w) >= 0;
        });*/
    };

    $scope.formIsValid = function (form) {
        if ($scope.formValue.allowJobTimeFix) {
            return form.jobDuration
                && !form.jobDuration.$error.windowSpillOver
                && !form.jobDuration.$error.required;
        } else {
            return true;
        }
    };

    $scope.onFormSubmit = function (form) {
        $scope.action = 'SAVING';

        var formData = angular.copy($scope.formValue);
        formData.durationWindows = $scope.data.durationWindows || [];

        $scope.saveJobDurationSettings(formData).then(function (data) {
            data.durationWindows = $scope.formValue.durationWindows;

            $scope.setJobsDurationSettings(data);
            $scope.onWindowTypeChange($scope.durationWindowFormValue.windowType);
            $scope.onWindowTypeChange($scope.editDurationWindowFormValue.windowType, 'EDIT');
            $scope.action = 'SAVED';
            form.$setPristine();
        }).catch(function () {
            $scope.action = 'FAILED_TO_SAVE';
        })

    };

    $scope.resetJobDurationSettingsForm = function (form) {
        var data = angular.copy($scope.data);

        data.durationWindows = $scope.formValue.durationWindows;
        $scope.setJobsDurationSettings(data);

        form.$setPristine();
        setJobDurationIsDirty(false);
    };

    $scope.onWindowTypeChange = function (model, mode) {
        var jobDurationInMins = $scope.getJobDurationMinutes();
        var formValue = mode === 'EDIT' ? $scope.editDurationWindowFormValue : $scope.durationWindowFormValue;

        switch (typeof(model) === 'string' ? model : model.$modelValue) {
            case "OVERDUE": {
                formValue.start = 0;
                formValue.end = 0;
                break;
            }
            case "NOT_OVERDUE": {
                formValue.start = 0;
                formValue.end = 0;
                break;
            }
            case "OTHER": {
                formValue.start = 0;
                formValue.end = jobDurationInMins;
                break;
            }
        }
    };

    $scope.resetAddWindowForm = function (form) {
        $scope.durationWindowFormValue = angular.copy(getDurationWindowDefault());
        form.$setPristine();
    };

    $scope.addDurationWindow = function (form) {
        var formVal = angular.copy($scope.durationWindowFormValue);

        var val = angular.copy($scope.formValue);
        val.durationWindows.push(formVal);
        var index = val.durationWindows.length - 1;

        $scope.action = 'SAVING_WINDOW';
        $scope.saveJobDurationSettings(val)
            .then(function (newValue) {

                newValue.durationWindows = newValue.durationWindows.map(function (w) {
                    w['justadded'] = false;
                    w['justupdated'] = false;
                    return w;
                });

                newValue.durationWindows[index]['justadded'] = true;

                $scope.setJobsDurationSettings(newValue);
                $scope.action = 'WINDOW_SAVED';

                $scope.resetAddWindowForm(form);

                initSortable();
                $scope.adjustListViewContainerHeight();

            })
            .catch(function () {
                $scope.action = 'FAILED_TO_SAVE_WINDOW';
            });

    };

    $scope.showEditDurationWindowForm = function (index) {
        $scope.selectedForEdit = index;
        $scope.editDurationWindowFormValue = $scope.formValue.durationWindows[$scope.selectedForEdit];
        $scope.rightSection = 'EDIT_SCREEN';
    };

    $scope.resetEditWindowForm = function (form) {
        $scope.editDurationWindowFormValue = angular.copy(getDurationWindowDefault());
    };

    $scope.cancelDurationWindowEdit = function (form) {
        $scope.rightSection = 'LIST_SCREEN';
        $scope.formValue.durationWindows = $scope.formValue.durationWindows.map(function (w) {
            w['justadded'] = false;
            w['justupdated'] = false;
            return w;
        });

        initSortable();
        $scope.resetEditWindowForm(form);
    };

    $scope.updateDurationWindow = function (form) {
        var formVal = angular.copy($scope.editDurationWindowFormValue);

        var val = angular.copy($scope.formValue);
        var index = $scope.selectedForEdit;
        val.durationWindows[index] = formVal;

        $scope.action = 'UPDATING_WINDOW';
        $scope.saveJobDurationSettings(val)
            .then(function (newValue) {

                newValue.durationWindows = newValue.durationWindows.map(function (w) {
                    w['justadded'] = false;
                    w['justupdated'] = false;
                    return w;
                });

                newValue.durationWindows[index]['justupdated'] = true;

                $scope.setJobsDurationSettings(newValue);
                $scope.action = 'WINDOW_UPDATED';
                $scope.rightSection = 'LIST_SCREEN';
                initSortable();

                $scope.resetEditWindowForm(form);

                $scope.adjustListViewContainerHeight();

            })
            .catch(function () {
                $scope.action = 'FAILED_TO_UPDATE_WINDOW';
            });

    };

    $scope.showDurationWindowDeleteWarning = function (index) {
        $scope.selectedId = index;
        $scope.shouldBeOpen = true;
    };
    $scope.resetDurationWindowDeleteModalValues = function () {
        $scope.selectedId = null;
        $scope.shouldBeOpen = false;
    };

    $scope.onDurationWindowDeleteSuccess = function (data) {
        $scope.setJobsDurationSettings(data['data']);
        $scope.resetDurationWindowDeleteModalValues();

        initSortable();
        $scope.adjustListViewContainerHeight();

    };

    $scope.$on('modal:close', function (event) {
        $scope.resetDurationWindowDeleteModalValues();
    });

    $scope.saveJobDurationSettings = function (formValue) {
        var value = angular.copy(formValue);

        if (!value.allowJobTimeFix) {
            value.durationWindows = null;
            value.jobDuration = null;
        } else {
            value.jobDuration = moment(value.jobDuration).format('HH:mm');
            value.durationWindows = value.durationWindows.map(function (w) {
                delete w['justadded'];
                delete w['justupdated'];
                return w;
            });
        }

        var deferred = $q.defer();

        $http({
            url: Routing.generate('setJobsDurationSettings'),
            method: 'POST',
            data: JSON.stringify(value)
        }).success(function (result) {

            toastBox.show('Saved', 3000);

            $scope.data = angular.copy(result);
            $scope.setJobsDurationSettings($scope.data);
            setJobDurationIsDirty(false);
            deferred.resolve(result);

        }).error(function (err) {
            console.log(err);
            deferred.reject();
        });

        return deferred.promise;
    };

    $scope.adjustListViewContainerHeight = function () {
        $timeout(function () {
            var listHeight = $('#deadline-windows-list-container #duration-deadline-windows-sortable li').outerHeight() * $('#deadline-windows-list-container #duration-deadline-windows-sortable li').length;
            listHeight = listHeight <= 235 ? 235 : listHeight;

            $('#deadline-windows-list-container').height(listHeight + 60);
        });
    };

    // Default values for duration window form
    function getDurationWindowDefault() {
        return {
            windowName: "",
            windowType: "OVERDUE",
            start: 0,
            end: 0,
            color: "#a72a14"
        };
    }

    function initSortable() {
        $timeout(function () {
            $('#duration-deadline-windows-sortable').sortable('destroy');

            $('#duration-deadline-windows-sortable').sortable({
                update: function (event, ui) {
                    var formValue = angular.copy($scope.formValue);

                    var sorted = [];
                    var count = 1;
                    $('#duration-deadline-windows-sortable').children('li').each(function () {
                        var index = $(this).attr('index');
                        if(index != undefined) {
                            sorted.push(formValue.durationWindows[index]);
                            count++;
                        }
                    });

                    formValue.durationWindows = sorted;

                    $scope.saveJobDurationSettings(formValue).then(function (data) {
                        $scope.setJobsDurationSettings(data);
                    });
                }
            });
        });
    }

    function setJobDurationIsDirty(val) {
        if(!$scope.data.allowJobTimeFix) return;

        if (val) {
            $scope.jobDurationIsDirty = true;
            $('.jobs-duration-windows-section').find('input, select, a, button, li').css('pointer-events', 'none').attr({
                "tabindex": "-1",
                "disabled": "true"
            });
        } else {
            $scope.jobDurationIsDirty = false;
            $('.jobs-duration-windows-section').find('input, select, a, li').css('pointer-events', 'auto').removeAttr("tabindex disabled");
            $('.jobs-duration-windows-section').find('button').not('save_btn').css('pointer-events', 'auto').removeAttr("tabindex");

            var form = angular.element($('ng-form[name=durationWindowForm]')).scope().durationWindowForm;
            if (form.$valid) $('ng-form[name=durationWindowForm]').find('.save_btn').removeAttr("disabled");
            else $('ng-form[name=durationWindowForm]').find('.save_btn').attr({"disabled": "true"});

            if ($scope.rightSection === 'EDIT_SCREEN') {
                form = angular.element($('ng-form[name=editDurationWindowForm]')).scope().editDurationWindowForm;
                if (form.$valid) $('ng-form[name=editDurationWindowForm]').find('.save_btn').removeAttr("disabled");
                else $('ng-form[name=editDurationWindowForm]').find('.save_btn').attr({"disabled": "true"});
            }

        }
    }

    // On initialization
    (function init() {
        setTimeout(function () {
            initSortable();
            $scope.adjustListViewContainerHeight();
        }, 500);
    }());
};
