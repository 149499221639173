'use strict';

var setupWizardModule = angular.module('setup-wizard', []);

setupWizardModule.constant('prefix', window.prefixVal).
    constant('defaultPagingValue', defaultPaginationLimit).
    provider('canLoad',function () {
        this.$get = function () {
            return {
                canLoadAjax: true,
                setLoadValue: function (canLoad) {
                    this.canLoadAjax = canLoad;
                },
                canLoad: function () {
                    return this.canLoadAjax;
                }
            }
        }
    }).
    config(
        ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
            function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {
                $stateProvider.
                    state('setupwizard', {
                        abstract: true,
                        templateUrl: '/template/setup_layout.html'
                    }).
                    state('setupwizard.companydetails', {
                        url: Routing.generateAngularRoute('setup_company_details', true),
                        templateUrl: function (params) {
                            return Routing.generate('setup_company_details')
                        },
                        views: {
                            "setupPane@setupwizard": {
                                template: globalTemplate,
                                controller: SetupRootCtrl,
                                resolve: SetupResolver
                            }
                        }
                    }).
                    state('setupwizard.companynumbers', {
                        url: Routing.generateAngularRoute('setup_companynumbers', false),
                        templateUrl: function (params) {
                            return Routing.generate('setup_companynumbers')
                        },
                        views: {
                            "setupPane@setupwizard": {
                                template: globalTemplate,
                                controller: SetupRootCtrl,
                                resolve: SetupResolver
                            }
                        }
                    }).
                    state('setupwizard.bankdetails', {
                        url: Routing.generateAngularRoute('setup_bankdetails', false),
                        templateUrl: function (params) {
                            return Routing.generate('setup_bankdetails')
                        },
                        views: {
                            "setupPane@setupwizard": {
                                template: globalTemplate,
                                controller: SetupRootCtrl,
                                resolve: SetupResolver
                            }
                        }
                    }).
                    state('setupwizard.setup_users', {
                        url: Routing.generateAngularRoute('setup_users', false),
                        templateUrl: function (params) {
                            return Routing.generate('setup_users')
                        },
                        views: {
                            "setupPane@setupwizard": {
                                templateUrl: "template/settings/users/setup_users.html",
                                controller: SetupUserCtrl,
                                resolve: SetupResolver
                            }
                        }
                    }).
                    state('loggedin.setup_stage_identifier', { // Identify which part of setup_wizard we should go in case it wsnt completed fully
                        url: Routing.generateAngularRoute('setup_stage_identifier', false),
                        template: globalTemplate,
                        templateUrl: function (params) {
                            return Routing.generate('setup_stage_identifier')
                        },
                        controller: SetupRootCtrl,
                        resolve: SetupResolver
                    }).
                    state('setupwizard.firstaccountnumber', {
                        url: Routing.generateAngularRoute('setup_firstaccountnumber', true),
                        templateUrl: function (params) {
                            return Routing.generate('setup_firstaccountnumber')
                        },
                        views: {
                            "setupPane@setupwizard": {
                                template: globalTemplate,
                                controller: SetupFirstNumberCtrl,
                                resolve: SetupResolver
                            }
                        }
                    }).
                    state('setupwizard.firstjobnumber', {
                        url: Routing.generateAngularRoute('setup_firstjobnumber', true),
                        templateUrl: function (params) {
                            return Routing.generate('setup_firstjobnumber')
                        },
                        views: {
                            "setupPane@setupwizard": {
                                template: globalTemplate,
                                controller: SetupFirstNumberCtrl,
                                resolve: SetupResolver
                            }
                        }
                    }).
                    state('setupwizard.firstestimatenumber', {
                        url: Routing.generateAngularRoute('setup_firstestimatenumber', true),
                        templateUrl: function (params) {
                            return Routing.generate('setup_firstestimatenumber')
                        },
                        views: {
                            "setupPane@setupwizard": {
                                template: globalTemplate,
                                controller: SetupFirstNumberCtrl,
                                resolve: SetupResolver
                            }
                        }
                    }).
                    state('setupwizard.setup_postcode', {
                        url: Routing.generateAngularRoute('setup_postcode', true),
                        templateUrl: function (params) {
                            return Routing.generate('setup_postcode')
                        },
                        views: {
                            "setupPane@setupwizard": {
                                template: globalTemplate,
                                controller: SetupRootCtrl,
                                resolve: SetupResolver
                            }
                        }
                    }).
                    state('setupwizard.setup_sms', {
                        url: Routing.generateAngularRoute('setup_sms', true),
                        templateUrl: function (params) {
                            return Routing.generate('setup_sms')
                        },
                        views: {
                            "setupPane@setupwizard": {
                                template: globalTemplate,
                                controller: SetupRootCtrl,
                                resolve: SetupResolver
                            }
                        }
                    }).
                    state('setupwizard.firstinvoicenumber', {
                        url: Routing.generateAngularRoute('setup_firstinvoicenumber', true),
                        templateUrl: function (params) {
                            return Routing.generate('setup_firstinvoicenumber')
                        },
                        views: {
                            "setupPane@setupwizard": {
                                template: globalTemplate,
                                controller: SetupRootCtrl,
                                resolve: SetupResolver
                            }
                        }
                    });
            }
        ]);
