commusoftCommon.directive('pricingcategory', function($compile, $timeout, $rootScope) {
    return {
        restrict: 'A',
        scope: {
            category: '@',
            pricingCategoryName: '@',
            afterAddPanelClose: '&',
            panelData: '='
        },
        templateUrl: '/template/estimate/estimate_details/price/pricing_category.html',
        controller: function ($scope, $element) {
            $scope.addPricingCategory = function () {
                // Update the selected invoice item categories value
                if($scope.pricingCategoryName) {
                    var selectedCats  = _.find($scope.panelData, function(x){
                      return x.id == $scope.pricingCategoryName;
                    })
                    $scope.$emit('update:full-breakdown-category', selectedCats);
                    $scope.$emit('spreadsheet:remove-added-invoice-item',selectedCats);
                    $scope.afterAddPanelClose();
                }else {
                    return false;
                }



            }
        }
    }
});