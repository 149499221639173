commusoftCommon.filter('DateFormatLine', function (CS_LANGUAGE) {
    return function (dateString) {
        if (dateString == '') {
            return '';
        }
        let language = CS_LANGUAGE;
        if(language == 'en'){
            language = 'en-GB';
        }
        moment.locale(language);
        if(language === 'en-GB') {
            return moment(dateString).format('DD/MM/YYYY');
        }else if(language === 'en-US'){
            return moment(dateString).format('MM/DD/YYYY');
        }else{
            return moment(dateString).format('DD/MM/YYYY')
        }

    };
});