'use strict';

var customersModule = angular.module('customers', ['jobParts','estimate','csDynamicForm']);

customersModule.constant('prefix', window.prefixVal).
    constant('defaultPagingValue', defaultPaginationLimit).
    provider('canLoad',function () {
        this.$get = function () {
            return {
                canLoadAjax: true,
                setLoadValue: function (canLoad) {
                    this.canLoadAjax = canLoad;
                },
                canLoad: function () {
                    return this.canLoadAjax;
                }
            }
        }
    }).
    config(
        ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
            function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {

                $stateProvider.
                    state('loggedin.customers', {
                        url: Routing.generateAngularRoute('customers', false),
                        templateUrl: function (params) {
                            return Routing.generate('customers')
                        },
                        template: globalTemplate,
                        controller: CustomerDashboardCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.customer_list', {
                        url: Routing.generateAngularRoute('customer_list', false),
                        templateUrl: function (params) {
                            return Routing.generate('customer_list')
                        },
                        data: {
                            'customerListUrl': '/listCustomers',
                            'pageNum': 1,
                            'editedId': -1,
                            'limit': 10
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: CustomersCtrl,
                                resolve: Resolver
                            },
                            "customersPane@": {
                                templateUrl: 'template/customers/index_customers.html',
                                controller: CustomersListCtrl,

                                resolve: {
                                    customersresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.customerListUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit + '&mode=' + 'customer_list').success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.customer', {
                        url: Routing.generateAngularRoute('customer', false),
                        templateUrl: function (params) {
                            return Routing.generate('customer')
                        },
                        data: {
                            'customerListUrl': '/listCustomers',
                            'pageNum': 1,
                            'editedId': -1,
                            'limit': 10
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: CustomersCtrl,
                                resolve: Resolver
                            },
                            "customersPane@": {
                                templateUrl: 'template/customers/index_customers.html',
                                controller: CustomersListCtrl,

                                resolve: {
                                    customersresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.customerListUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit + '&mode=' + 'customer').success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.workaddresses', {
                        url: Routing.generateAngularRoute('work_address', false),
                        templateUrl: function (params) {
                            return Routing.generate('work_address')
                        },
                        data: {
                            'customerListUrl': '/listCustomers',
                            'pageNum': 1,
                            'editedId': -1,
                            'limit': 10
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: CustomersCtrl,
                                resolve: Resolver
                            },
                            "customersPane@": {
                                templateUrl: 'template/customers/index_customers.html',
                                controller: CustomersListCtrl,

                                resolve: {
                                    customersresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.customerListUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit + '&mode=' + 'work_address').success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.customer_list.view', {
                        url: Routing.generateAngularRoute('customerSubNav', true),
                        templateUrl: function (params) {
                            return Routing.generate('customerSubNav', params)
                        },
                        views: {
                            "customersPane@": {
                                templateUrl: 'template/customers/customer_configure.html',
                                controller: SubNavCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.property', {
                        url: Routing.generateAngularRoute('customerProperty', true),
                        templateUrl: function (params) {
                            return Routing.generate('customerProperty', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10,
                            'limitHistory': 5
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/property/view.html',
                                controller: CustomerPropertyCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.propertyedit', {
                        url: Routing.generateAngularRoute('editProperty', true),
                        templateUrl: function (params) {
                            return Routing.generate('editProperty', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/property/edit_customer.html',
                                controller: EditCustomerDetailsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.invoice_setup', {
                        url: Routing.generateAngularRoute('invoice_setup', true),
                        templateUrl: function (params) {
                            return Routing.generate('get_customer_navigation', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('invoice_setup', params)
                                },
                                controller: CustomerInvoiceSetupCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.notes', {
                        url: Routing.generateAngularRoute('customer_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_note', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/notes/view.html',
                                controller: CustomerCommunicationsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.notes.table', {
                        url: Routing.generateAngularRoute('customer_notes', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_notes', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5,
                            'pageNumPhoneCalls': 1,
                            'limitPhoneCalls': 5,
                            'pageNumSmsMessages': 1,
                            'limitSmsMessages': 5
                        },
                        views: {
                            "": {
                                templateUrl: 'template/customers/notes/tableview.html',
                                controller: CommunicationNotesListCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.notes_new', {
                        url: Routing.generateAngularRoute('customers_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('customers_note', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_notes', params)
                                },
                                controller: CustomerNewNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.notes_edit', {
                        url: Routing.generateAngularRoute('edit_customer_notes', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_customer_notes', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/notes/edit_note.html',
                                controller: CustomerEditNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.notes.timeline', {
                        url: Routing.generateAngularRoute('customer_notes_timeline', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_notes_timeline', params)
                        },
                        views: {
                            "": {
                                templateUrl: 'template/customers/notes/timeline.html',
                                controller: CommunicationNotesTimeViewCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_phone_call', {
                        url: Routing.generateAngularRoute('customer_new_phone_call', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_new_phone_call', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_phone_call', params)
                                },
                                controller: CustomerNewPhoneCallCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_sms_message', {
                        url: Routing.generateAngularRoute('customer_new_sms_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_new_sms_message', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_sms_message', params)
                                },
                                controller: CustomerNewSmsMessageCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.branches', {
                        url: Routing.generateAngularRoute('customer_branches', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_branches', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/branches/branch_view.html',
                                controller: CustomerBranchListCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_branch', {
                        url: Routing.generateAngularRoute('customerAddress', true),
                        templateUrl: function (params) {
                            return Routing.generate('customerAddress', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_customerbranch', params)
                                },
                                controller: CreateNewBranchCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.contacts', {
                        url: Routing.generateAngularRoute('customer_contacts', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_contacts', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/contacts/contacts.html',
                                controller: CustomerContactsCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_contact', {
                        url: Routing.generateAngularRoute('customer_new_contact', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_new_contact', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_contacts', params)
                                },
                                controller: CustomerNewContactsCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.edit_contact', {
                        url: Routing.generateAngularRoute('customer_edit_contact', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_edit_contact', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/contacts/edit_contact.html',
                                controller: CustomerEditContactsCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.edit_branch', {
                        url: Routing.generateAngularRoute('edit_customerbranch', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_customerbranch', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/branches/branch_edit.html',
                                controller: EditCustomerBranchCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.workaddresses', {
                        url: Routing.generateAngularRoute('work', true),
                        templateUrl: function (params) {
                            return Routing.generate('work', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/work/workaddress_view.html',
                                controller: CustomerWorkAddressListCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_work', {
                        url: Routing.generateAngularRoute('add_work', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_work', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_work', params)
                                },
                                controller: CreateNewWorkAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.bulk_work', {
                        url: Routing.generateAngularRoute('bulk_workaddress', true),
                        templateUrl: function (params) {
                            return Routing.generate('get_customer_navigation', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('bulk_workaddress', params)
                                },
                                controller: CustomerBulkWorkAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.invoice_addresses', {
                        url: Routing.generateAngularRoute('invoice_addresses', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoice_addresses', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/invoice_address/view.html',
                                controller: CustomerInvoiceAddressCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.search_invoice_address', {
                        url: Routing.generateAngularRoute('search_invoice_address', true),
                        templateUrl: function (params) {
                            return Routing.generate('search_invoice_address', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/invoice_address/search_invoice_address.html',
                                controller: CustomerInvoiceAddressSearchCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_invoice_address', {
                        url: Routing.generateAngularRoute('new_invoice_address', true),
                        templateUrl: function (params) {
                            return Routing.generate('get_customer_navigation', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_invoice_address', params)
                                },
                                controller: CreateNewInvoiceAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.enable_landlord_invoice_address', {
                        url: Routing.generateAngularRoute('enable_landlord_invoice_address', true),
                        templateUrl: function (params) {
                            return Routing.generate('get_customer_navigation', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('enable_landlord_invoice_address', params)
                                },
                                controller: EnableLandlordInvoiceAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.enable_workaddress_invoice_address', {
                        url: Routing.generateAngularRoute('enable_workaddress_invoice_address', true),
                        templateUrl: function (params) {
                            return Routing.generate('get_customer_navigation', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('enable_workaddress_invoice_address', params)
                                },
                                controller: EnableWorkaddressInvoiceAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.reminders', {
                        url: Routing.generateAngularRoute('reminders', true),
                        templateUrl: function (params) {
                            return Routing.generate('reminders', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5,
                            'serviceReminderPageNum': 1,
                            'serviceReminderLimit': 5
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/reminders/view.html',
                                controller: CustomerRemindersListCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_reminder', {
                        url: Routing.generateAngularRoute('add_reminder', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_reminder', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_reminder', params)
                                },
                                controller: CreateNewReminderCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.edit_reminder', {
                        url: Routing.generateAngularRoute('edit_reminder_url', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_reminder_url', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/reminders/edit_reminder.html',
                                controller: EditReminderCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_service_reminder', {
                        url: Routing.generateAngularRoute('add_service_reminder', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_service_reminder', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_service_reminder', params)
                                },
                                controller: CreateNewServiceReminderCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.edit_customer_service_reminder', {
                        url: Routing.generateAngularRoute('edit_customer_service_reminder', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_customer_service_reminder', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/reminders/edit_service_reminder.html',
                                controller: EditServiceReminderCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.edit_automatic_statements', {
                        url: Routing.generateAngularRoute('edit_automatic_statements', true),
                        templateUrl: function(params) {
                            return Routing.generate('edit_automatic_statements', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/reminders/edit_automatic_statements.html',
                                controller: EditAutomaticStatementsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.edit_automatic_debtchasing', {
                        url: Routing.generateAngularRoute('edit_automatic_debtchasing', true),
                        templateUrl: function(params) {
                            return Routing.generate('edit_automatic_debtchasing', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/reminders/edit_automatic_debtchasing.html',
                                controller: EditAutomaticDebtChasingCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.edit_after_sales_care', {
                       url: Routing.generateAngularRoute('edit_after_sales_care', true),
                        templateUrl: function(params) {
                            return Routing.generate('edit_after_sales_care', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/reminders/edit_after_sales_care.html',
                                controller: EditAfterSalesCareCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.new_customer', {
                        url: Routing.generateAngularRoute('new_customer', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_customer', params)
                        },
                        template: globalTemplate,
                        controller: NewCustomerCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.new_company', {
                        url: Routing.generateAngularRoute('new_company', false),
                        templateUrl: function (params) {
                            return Routing.generate('new_company', params)
                        },
                        template: globalTemplate,
                        controller: NewCompanyCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.customer_list.view.files', {
                        url: Routing.generateAngularRoute('get_customer_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('get_customer_files', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/files/attached_files.html',
                                controller: UploadedFilesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_file', {
                        url: Routing.generateAngularRoute('customer_new_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_new_files', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/files/new_file.html',
                                controller: AddNewFileCtrl,
                                resolve: BreadCrumbsPageLimitResolver

                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_estimate', {
                        url: Routing.generateAngularRoute('customerEstimate', true),
                        templateUrl: function (params) {
                            return Routing.generate('customerEstimate', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_estimate', params)
                                },
                                controller: CreateNewEstimateCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_estimate_project', {
                        url: Routing.generateAngularRoute('customerProject', true),
                        templateUrl: function (params) {
                            return Routing.generate('customerProject', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_estimate_project', params)
                                },
                                controller: CreateNewEstimateCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.appliances', {
                        url: Routing.generateAngularRoute('customer_appliance', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_appliance', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/appliances/index.html',
                                controller: CustomerApplianceViewCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_appliance', {
                        url: Routing.generateAngularRoute('customerAppliances', true),
                        templateUrl: function (params) { return Routing.generate('customerAppliances', params)},
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) { return Routing.generate('new_appliance', params) },
                                controller: CustomerApplianceAddCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.edit_appliance', {
                        url: Routing.generateAngularRoute('edit_customer_appliances', true),
                        templateUrl: function (params) { return Routing.generate('edit_customer_appliances', params)},
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/appliances/edit.html',
                                controller: CustomerApplianceEditCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.customer_technicalreference', {
                        url: Routing.generateAngularRoute('customer_technicalreference', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_technicalreference', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/appliances/technical_reference/edit.html',
                                controller: CustomerTechReferenceCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.technicalreference', {
                        url: Routing.generateAngularRoute('technicalreference', true),
                        templateUrl: function (params) {
                            return Routing.generate('technicalreference', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/technical_reference/index.html',
                                controller: TechReferenceCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.customer_edit_technicalreference', {
                        url: Routing.generateAngularRoute('customer_edit_technicalreference', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_edit_technicalreference', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/technical_reference/edit.html',
                                controller: CustomerEditTechReferenceCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address', {
                        url: Routing.generateAngularRoute('invoice_address', false),
                        templateUrl: function (params) {
                            return Routing.generate('invoice_address')
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: InvoiceAddressCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view', {
                        url: Routing.generateAngularRoute('invoiceAddressSubNav', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressSubNav', params)
                        },
                        views: {
                            "invoicePane@": {
                                templateUrl: 'template/invoice_address/subnav.html',
                                controller: InvoiceSubNavCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.details', {
                        url: Routing.generateAngularRoute('invoiceAddressDetails', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressDetails', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/invoice_address/details/view.html',
                                controller: InvoiceAddressDetailsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.edit', {
                        url: Routing.generateAngularRoute('invoiceAddressEdit', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressEdit', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/invoice_address/details/edit.html',
                                controller: EditInvoiceAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.notes', {
                        url: Routing.generateAngularRoute('invoiceAddressNote', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressNote', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/invoice_address/notes/view.html',
                                controller: NoteInvoiceAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.notes.table', {
                        url: Routing.generateAngularRoute('invoiceAddressNoteTableView', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressNoteTableView', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                templateUrl: 'template/invoice_address/notes/tableview.html',
                                controller: NoteListInvoiceAddressCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.notes.timeline', {
                        url: Routing.generateAngularRoute('invoiceAddressNoteTimelineView', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressNoteTimelineView', params)
                        },
                        views: {
                            "": {
                                templateUrl: 'template/invoice_address/notes/timelineview.html',
                                controller: NoteListInvoiceAddressTimeCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.notes_new', {
                        url: Routing.generateAngularRoute('invoiceAddressGetDetails', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressGetDetails', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('invoiceAddressNewNote', params)
                                },
                                controller: NewNoteInvoiceAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.notes_edit', {
                        url: Routing.generateAngularRoute('invoiceAddressEditNote', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressEditNote', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/invoice_address/notes/edit.html',
                                controller: EditNoteInvoiceAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.new_sms_message', {
                        url: Routing.generateAngularRoute('invoiceAddressNewSmsMessageDetails', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressNewSmsMessageDetails', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('invoiceAddressNewSmsMessage', params)
                                },
                                controller: NewSmsMessageInvoiceAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.new_phone_call', {
                        url: Routing.generateAngularRoute('invoiceAddressNewPhoneCallDetails', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressNewPhoneCallDetails', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('invoiceAddressNewPhoneCall', params)
                                },
                                controller: NewPhoneCallInvoiceAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.reminders', {
                        url: Routing.generateAngularRoute('invoiceAddressReminders', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressReminders', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/invoice_address/reminders/view.html',
                                controller: ReminderInvoiceAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.reminders_automatic_statement', {
                        url: Routing.generateAngularRoute('invoiceAddressAutomaticStatement', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressAutomaticStatement', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/invoice_address/reminders/automatic_statement_edit.html',
                                controller: AutomaticStatementInvoiceAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.reminders_automatic_debt', {
                        url: Routing.generateAngularRoute('invoiceAddressAutomaticDebt', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressAutomaticDebt', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/invoice_address/reminders/automatic_debt_edit.html',
                                controller: AutomaticDebtInvoiceAddressCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.work_address_reminders', {
                        url: Routing.generateAngularRoute('work_address_reminder_due', true),
                        templateUrl: function (params) {
                            return Routing.generate('work_address_reminder_due', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/reminders/work_address_reminder_due.html',
                                controller: WorkAddressReminderDueCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.service_reminders', {
                        url: Routing.generateAngularRoute('customer_service_reminders_view', false),
                        templateUrl: function (params) {
                            return Routing.generate('customer_service_reminders_view')
                        },
                        data: {
                            'customerServiceRemindersListUrl': '/customerServiceRemindersList',
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: CustomersServiceRemindersCtrl,
                                resolve: Resolver
                            },
                            "customersServiceRemindersPane@": {
                                templateUrl: 'template/customers/reminders/service_reminder_view.html',
                                controller: CustomersServiceRemindersListCtrl,

                                resolve: {
                                    customersserviceremindersresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.customerServiceRemindersListUrl + '?page=' + this.data.pageNum +
                                        '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }

                    }).
                    state('loggedin.service_reminders_due_days', {
                        url: Routing.generateAngularRoute('service_reminders_due_days_list', false),
                        templateUrl: function (params) {
                            return Routing.generate('service_reminders_due_days_list')
                        },
                        data: {
                            'serviceReminderListUrl': '/listDueServiceReminder',
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: ServiceReminderCtrl,
                                resolve: Resolver
                            },
                            "serviceReminderPane@": {
                                templateUrl: 'template/customers/reminders/service_reminders_due_days.html',
                                controller: ServiceReminderListCtrl,

                                resolve: {
                                    servicereminderresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.serviceReminderListUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.service_reminders_phone_call', {
                        url: Routing.generateAngularRoute('service_reminders_phone_call_list', false),
                        templateUrl: function (params) {
                            return Routing.generate('service_reminders_phone_call_list')
                        },
                        data: {
                            'listServiceReminderPhoneCallUrl': '/listServiceReminderPhoneCall',
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: ServiceReminderPhoneCallCtrl,
                                resolve: Resolver
                            },
                            "phoneCallPane@": {
                                templateUrl: 'template/customers/reminders/phone_call_list.html',
                                controller: ServiceReminderPhoneCallListCtrl,
                                resolve: {
                                    servicereminderphonecallresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.listServiceReminderPhoneCallUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.service_reminders.processing', {
                        url: Routing.generateAngularRoute('service_reminders_processing', true),
                        templateUrl: function (params) {
                            return Routing.generate('service_reminders_processing',params)
                        },
                        views: {
                            "customersServiceRemindersPane@": {
                                templateUrl: 'template/customers/reminders/processing.html',
                                controller: ServiceReminderProcessingCtrl,
                                resolve: ProcessingResolver
                            }
                        }
                    }).state('loggedin.customer_list.view.service_reminder', {
                        url: Routing.generateAngularRoute('customer_service_reminder_statement', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_service_reminder_statement', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/reminders/service_remainder_statement.html',
                                controller: CustomerServiceReminderStatementCtrl,
                                resolve: ServiceReminderStatementResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.service_reminder.overview', {
                        url: Routing.generateAngularRoute('statement_overview', true),
                        templateUrl: function (params) {
                            return Routing.generate('statement_overview', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5,
                            'category': 'serviceRemindersStatement'
                        },
                        views: {
                            "": {
                                templateUrl: 'template/customers/reminders/service_reminder_statement_overview.html',
                                controller: CustomerServiceReminderStatementOverviewCtrl,
                                resolve: ServiceReminderStatementResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.service_reminder.summary', {
                        url: Routing.generateAngularRoute('statement_summary', true),
                        templateUrl: function (params) {
                            return Routing.generate('statement_summary', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5,
                            'category': 'serviceRemindersStatement'
                        },
                        views: {
                            "": {
                                templateUrl: 'template/customers/reminders/service_reminder_statement_summary.html',
                                controller: CustomerServiceReminderStatementSummaryCtrl,
                                resolve: ServiceReminderStatementResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.credit_note', {
                        url: Routing.generateAngularRoute('credit_note_sub_nav', true),
                        templateUrl: function (params) {
                            return Routing.generate('credit_note_sub_nav', params)
                        },
                        views: {
                            "customersPane@": {
                                templateUrl: 'template/customers/credit_notes/view_credit_note/subnav.html',
                                controller: CreditNoteSubNavCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.credit_note.details', {
                        url: Routing.generateAngularRoute('customer_credit_note_details', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_credit_note_details', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/credit_notes/view.html',
                                controller: CreditNoteDetailsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.credit_note.details.add_allocation', {
                        url: Routing.generateAngularRoute('customer_credit_note_allocation', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_credit_note_allocation', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/credit_notes/view_credit_note/allocations/new.html',
                                controller: CustomerCreditAllocationCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('customer:new_cash_allocation:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('customer:new_cash_allocation:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.credit_note.details.edit_credit_note', {
                        url: Routing.generateAngularRoute('customer_credit_note_edit', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_credit_note_edit', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/credit_notes/new.html',
                                controller: EditCustomerCreditNoteCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('customer:new_cash_allocation:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('customer:new_cash_allocation:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.credit_note.details.edit_customer_receipt', {
                        url: Routing.generateAngularRoute('edit_customer_receive_credit', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_customer_receive_credit', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/credit_notes/receive_credit.html',
                                controller: EditCustomerCreditNoteReceiveCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.credit_note.details.edit_allocation', {
                        url: Routing.generateAngularRoute('customer_credit_note_allocation_edit', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_credit_note_allocation_edit', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/credit_notes/view_credit_note/allocations/edit.html',
                                controller: EditCustomerCreditAllocationCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('customer:new_cash_allocation:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('customer:new_cash_allocation:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.credit_note.customer_receive_credit', {
                        url: Routing.generateAngularRoute('customer_receive_credit', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_receive_credit', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('customer_receive_credit_form', params)
                                },
                                controller: NewCustomerCreditNoteReceiveCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job', {
                        url: Routing.generateAngularRoute('job_subnav', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_subnav', params)
                        },
                        views: {
                            "customersPane@": {
                                templateUrl: 'template/jobs/main_layout.html',
                                controller: JobSubNavCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details', {
                        url: Routing.generateAngularRoute('job_details', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_details', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/view/details/view.html',
                                controller: JobDetailsCtrl,
                                resolve: {
                                    getIdData: BreadCrumbsResolver.getIdData,
                                    skills_data: SkillsResolver.skills_data
                                }
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.visits', {
                        url: Routing.generateAngularRoute('projectJobVisits', true),
                        templateUrl: function (params) {
                            return Routing.generate('projectJobVisits', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/view/visits/view.html',
                                controller: JobVisitsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.visits.view', {
                        url: Routing.generateAngularRoute('projectJobVisitsView', true),
                        templateUrl: function (params) {
                            return Routing.generate('projectJobVisitsView', params)
                        },
                        views: {
                            "JobTabPane@loggedin.customer_list.view.job.visits": {
                                templateUrl: 'template/jobs/view/visits/innerview.html',
                                controller: JobVisitsViewCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.todos', {
                        url: Routing.generateAngularRoute('projectJobTodos', true),
                        templateUrl: function (params) {
                            return Routing.generate('projectJobTodos', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/view/todos/view.html',
                                controller: JobTodosCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.todos.view', {
                        url: Routing.generateAngularRoute('projectJobTodosView', true),
                        templateUrl: function (params) {
                            return Routing.generate('projectJobTodosView', params)
                        },
                        views: {
                            "JobTabPane@loggedin.customer_list.view.job.todos": {
                                templateUrl: 'template/jobs/view/todos/view_todos.html',
                                controller: JobTodosViewCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.cost', {
                        url: Routing.generateAngularRoute('projectJobCosts', true),
                        templateUrl: function (params) {
                            return Routing.generate('projectJobCosts', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/costs/view.html',
                                controller: JobCostingsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).state('loggedin.customer_list.view.job.cost.projectCostView', {
                        url: Routing.generateAngularRoute('projectJobCostsView', true),
                        templateUrl: function (params) {
                            return Routing.generate('projectJobCostsView', params)
                        },
                        data: {
                            'pageNum': 1,
                            'labourPageNumber': 1,
                            'limit': 5
                        },
                        views: {
                            "JobTabPane@loggedin.customer_list.view.job.cost": {
                                templateUrl: 'template/jobs/costs/view_cost.html',
                                controller: JobCostingsViewCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('jobs:costs:spreadsheetGrandTotals') !== null;

                            if(grand_totals_exist) {
                                localStorage.removeItem('jobs:costs:spreadsheetGrandTotals');
                            }
                        }
                    }).state('loggedin.customer_list.view.job.viewcost', {
                        url: Routing.generateAngularRoute('jobCostsView', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobCostsView', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/costs/view_cost.html',
                                controller: JobCostingsViewCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('jobs:costs:spreadsheetGrandTotals') !== null;

                            if(grand_totals_exist) {
                                localStorage.removeItem('jobs:costs:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.additional_works', {
                        url: Routing.generateAngularRoute('jobAdditionalWorks', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobAdditionalWorks', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/additional_works/view.html',
                                controller: JobAdditionalWorksCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onExit: function() {
                            var remove_instance_variables_from_spreadsheet = new CustomEvent('state_with_spreadsheet_exited');
                            document.dispatchEvent(remove_instance_variables_from_spreadsheet);
                        }
                    }).
                    state('loggedin.customer_list.view.job.additional_works.view', {
                        url: Routing.generateAngularRoute('jobAdditionalWorkView', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobAdditionalWorkView', params)
                        },
                        views: {
                            "estimatesTabPane@": {
                                templateUrl: 'template/jobs/additional_works/view/layout.html',
                                controller: JobAdditionalWorkSubNavCtrl,
                                resolve: BreadCrumbsResolver
                            },
                            "estimatesInfoTabPane@": {
                                templateUrl: 'template/estimate/estimate_details/estimate_information_bar.html',
                                controller: JobAdditionalWorkSubNavCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.additional_works.view.new', {
                        url: Routing.generateAngularRoute('jobAdditionalWorkNew', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobAdditionalWorkNew', params)
                        },
                        views: {
                            "estimatesPane@": {
                                templateUrl: 'template/jobs/additional_works/view/view.html',
                                controller: JobAdditionalWorkNewCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.additional_works.view.details', {
                        url: Routing.generateAngularRoute('jobAdditionalWorkDetails', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobAdditionalWorkDetails', params)
                        },
                        views: {
                            "estimatesPane@": {
                                templateUrl: 'template/jobs/additional_works/view/details.html',
                                controller: JobAdditionalWorkNewCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.additional_works.view.todos', {
                        url: Routing.generateAngularRoute('jobAdditionalWorkTodos', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobAdditionalWorkTodos', params)
                        },
                        views: {
                            "estimatesPane@": {
                                templateUrl: 'template/jobs/additional_works/view/todos.html',
                                controller: JobAdditionalWorkTodosCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.additional_works.view.budgetcost', {
                        url: Routing.generateAngularRoute('jobAdditionalWorkBudgetcost', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobAdditionalWorkBudgetcost', params)
                        },
                        views: {
                            "estimatesPane@": {
                                templateUrl: 'template/jobs/additional_works/view/cost.html',
                                controller: JobAdditionalWorkBudgetcostCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('costs:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('costs:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.additional_works.view.price', {
                        url: Routing.generateAngularRoute('jobAdditionalWorkPrice', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobAdditionalWorkPrice', params)
                        },
                        views: {
                            "estimatesPane@": {
                                templateUrl: 'template/jobs/additional_works/view/price.html',
                                controller: JobAdditionalWorkPriceCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('estimates:price:spreadsheetGrandTotals') !== null;

                            if(grand_totals_exist) {
                                localStorage.removeItem('estimates:price:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.additional_works.view.invoice_schedule', {
                        url: Routing.generateAngularRoute('jobAdditionalWorkInvoiceSchedule', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobAdditionalWorkInvoiceSchedule', params)
                        },
                        views: {
                            "estimatesPane@": {
                                templateUrl: 'template/jobs/additional_works/view/invoice_schedule.html',
                                controller: JobAdditionalWorkInvoiceScheduleCtrl,
                                resolve: SpreadsheetBreadCrumbsResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('invoice_schedule:spreadsheetGrandTotals') !== null;

                            if(grand_totals_exist) {
                                localStorage.removeItem('invoice_schedule:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.additional_works.view.send_to_customer', {
                        url: Routing.generateAngularRoute('jobAdditionalWorkSendToCustomer', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobAdditionalWorkSendToCustomer', params)
                        },
                        views: {
                            "estimatesPane@": {
                                templateUrl: 'template/jobs/additional_works/view/send_to_customer.html',
                                controller: JobAdditionalWorkSendToCustomerCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.additional_works.view.accept_or_reject', {
                        url: Routing.generateAngularRoute('jobAdditionalWorkAcceptRejects', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobAdditionalWorkAcceptRejects', params)
                        },
                        views: {
                            "estimatesPane@": {
                                templateUrl: 'template/jobs/additional_works/view/accept_or_reject.html',
                                controller: JobAdditionalWorkAcceptRejectCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.new_part', {
                        url: Routing.generateAngularRoute('new_part', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_part', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('add_new_part', params)
                                },
                                controller: AddNewPartCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).state('loggedin.customer_list.view.job.new_job_part_milestone', {
                        url: Routing.generateAngularRoute('new_job_part_milestone', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_job_part_milestone', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('add_new_part', params)
                                },
                                controller: AddNewPartCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.asset_new_job', {
                        url: Routing.generateAngularRoute('customerAssetJob', true),
                        templateUrl: function (params) {
                            return Routing.generate('customerAssetJob', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_asset_job', params)
                                },
                                controller: CreateNewJobCtrl,
                                resolve: {
                                    getIdData: BreadCrumbsResolver.getIdData,
                                    skills_data: SkillsResolver.skills_data
                                }
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.job_reminders', {
                        url: Routing.generateAngularRoute('job_reminders', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_reminders', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/reminders/view.html',
                                controller: JobRemindersListCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.new_job_reminder', {
                        url: Routing.generateAngularRoute('add_job_reminder', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_job_reminder', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_job_reminder', params)
                                },
                                controller: JobNewReminderCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.edit_job_reminder', {
                        url: Routing.generateAngularRoute('edit_job_reminder_url', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_job_reminder_url', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/reminders/edit_job_reminder.html',
                                controller: EditJobReminderCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.files', {
                        url: Routing.generateAngularRoute('get_job_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('get_job_files', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/files/attached_files.html',
                                controller: GetJobFilesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).

                    state('loggedin.customer_list.view.job.new_file', {
                        url: Routing.generateAngularRoute('new_job_files', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_job_files', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/files/new_file.html',
                                controller: NewJobFileCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.certificates', {
                        url: Routing.generateAngularRoute('job_certificates', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_certificates', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/certificates/view.html',
                                controller: JobCertificateCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.notes', {
                        url: Routing.generateAngularRoute('job_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_note', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/notes/view.html',
                                controller: CustomerJobCommunicationsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.notes.table', {
                        url: Routing.generateAngularRoute('job_notes', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_notes', params)
                        },
                        data : {
                            'pageNum' : 1,
                            'limit' : 5,
                            'pageNumPhoneCalls': 1,
                            'limitPhoneCalls': 5,
                            'pageNumSmsMessages': 1,
                            'limitSmsMessages': 5,
                            'pageNumEmail': 1,
                            'limitEmail': 5
                        },
                        views:{
                            "": {
                                templateUrl:'template/jobs/notes/tableview.html',
                                controller: CustomerJobNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.notes.timeline', {
                        url: Routing.generateAngularRoute('job_notes_timeline', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_notes_timeline', params)
                        },
                        views: {
                            "": {
                                templateUrl: 'template/jobs/notes/timeline.html',
                                controller: CustomerJobNotesTimeViewCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.notes_new', {
                        url: Routing.generateAngularRoute('jobs_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('jobs_note', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('job_new_note', params)
                                },
                                controller: CustomerJobsNewNotesCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.notes_edit', {
                        url: Routing.generateAngularRoute('edit_job_notes', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_job_notes', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/notes/edit_note.html',
                                controller: JobEditNotesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.new_smsmessage', {
                        url: Routing.generateAngularRoute('job_smsmessage', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_smsmessage', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_jobmessages', params)
                                },
                                controller: JobNewSmsMessageCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.new_phonecall', {
                        url: Routing.generateAngularRoute('job_phone_call', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_phone_call', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_jobphone_call', params)
                                },
                                controller: JobNewPhoneCallCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.new_job_part_purchase_order', {
                        url: Routing.generateAngularRoute('new_job_part_purchase_order', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_job_part_purchase_order', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/purchaseorder/new_purchase_order.html',
                                controller: NewPartPurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:new_job_purchase_order_parts_prepopulated:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:new_job_purchase_order_parts_prepopulated:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.new_job_part_purchase_order_operational', {
                        url: Routing.generateAngularRoute('new_job_part_purchase_order_operational', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_job_part_purchase_order', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/purchaseorder/new_purchase_order.html',
                                controller: NewPartPurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:new_job_purchase_order_parts_prepopulated:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:new_job_purchase_order_parts_prepopulated:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.new_job_cost_purchase_order', {
                        url: Routing.generateAngularRoute('new_job_cost_purchase_order', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_job_cost_purchase_order', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/purchaseorder/new_purchase_order.html',
                                controller: NewPartPurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var item_grand_totals_exist = localStorage.getItem('supplier:new_job_purchase_order_items_prepopulated:spreadsheetGrandTotals') !== null;
                            if(item_grand_totals_exist) {
                                localStorage.removeItem('supplier:new_job_purchase_order_items_prepopulated:spreadsheetGrandTotals');
                            }
                        }
                    }).state('loggedin.customer_list.view.job.details.new_job_cost_purchase_order_milestone', {
                        url: Routing.generateAngularRoute('new_job_cost_purchase_order_milestone', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_job_cost_purchase_order_milestone', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/purchaseorder/new_purchase_order.html',
                                controller: NewPartPurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:new_job_purchase_order_items_prepopulated:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:new_job_purchase_order_items_prepopulated:spreadsheetGrandTotals');
                            }
                        }
                    }).state('loggedin.customer_list.view.job.details.new_job_part_purchase_order_milestone', {
                        url: Routing.generateAngularRoute('new_job_part_purchase_order_milestone', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_job_part_purchase_order_milestone', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/purchaseorder/new_purchase_order.html',
                                controller: NewPartPurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:new_job_purchase_order_parts_prepopulated:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:new_job_purchase_order_parts_prepopulated:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.new_supplier_invoice', {
                        url: Routing.generateAngularRoute('job_new_supplier_invoice', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_new_supplier_invoice', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/costs/new_supplier_invoice.html',
                                controller: JobNewSupplierInvoiceCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:new_invoice:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:new_invoice:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.new_supplier_invoice_milestone', {
                        url: Routing.generateAngularRoute('new_supplier_invoice_milestone', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_supplier_invoice_milestone', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/costs/new_supplier_invoice.html',
                                controller: JobNewSupplierInvoiceCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:new_invoice:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:new_invoice:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.estimate.reserve_list', {
                        url: Routing.generateAngularRoute('estimate_reserve_list', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_reserve_list', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('add_estimates_reserve_list', params)
                                },
                                controller: AddNewReserveListCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.project.reserve_list', {
                        url: Routing.generateAngularRoute('project_estimate_reserve_list', true),
                        templateUrl: function (params) {
                            return Routing.generate('project_estimate_reserve_list', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('add_project_estimates_reserve_list', params)
                                },
                                controller: AddNewReserveListCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.reserve_list', {
                        url: Routing.generateAngularRoute('job_reserve_list', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_reserve_list', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('add_job_reserve_list', params)
                                },
                                controller: AddNewReserveListCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.place_job_on_hold',{
                        url: Routing.generateAngularRoute('job_on_hold', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_on_hold', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('place_job_on_hold', params)
                                },
                                controller: PlaceJobOnHoldCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.free_of_charge',{
                        url: Routing.generateAngularRoute('free_of_charge', true),
                        templateUrl: function (params) {
                            return Routing.generate('free_of_charge', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('free_of_charge_job', params)
                                },
                                controller: FreeOfChargeAndAbortJobCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.abort_job',{
                        url: Routing.generateAngularRoute('abort_job', true),
                        templateUrl: function (params) {
                            return Routing.generate('abort_job', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_abort_job', params)
                                },
                                controller: FreeOfChargeAndAbortJobCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.recalls',{
                        url: Routing.generateAngularRoute('recalls', true),
                        templateUrl: function (params) {
                            return Routing.generate('recalls', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('recall_job', params)
                                },
                                controller: RecallJobsCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.service_reminder_new_job', {
                        url: Routing.generateAngularRoute('serviceReminderJob', true),
                        templateUrl: function (params) {
                            return Routing.generate('serviceReminderJob', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('service_reminder_job', params)
                                },
                                controller: CreateNewJobCtrl,
                                resolve: {
                                    getIdData: BreadCrumbsResolver.getIdData,
                                    skills_data: SkillsResolver.skills_data
                                }
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.add_travel_milestone', {
                        url: Routing.generateAngularRoute('add_new_travel_milestone', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_new_travel_milestone', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_travel_milestone', params)
                                },
                                controller: AddNewTravelCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.add_travel', {
                        url: Routing.generateAngularRoute('add_new_travel', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_new_travel', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_travel', params)
                                },
                                controller: AddNewTravelCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.edit_travel', {
                        url: Routing.generateAngularRoute('edit_travel', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_travel', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/costs/travel/edit_travel.html',
                                controller: EditTravelsCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }

                    }).
                    state('loggedin.customer_list.view.job.details.edit_travel_milestone', {
                        url: Routing.generateAngularRoute('edit_travel_milestone', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_travel_milestone', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/costs/travel/edit_travel.html',
                                controller: EditTravelsCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }

                    }).
                    state('loggedin.customer_list.view.job.details.add_new_labour',{
                        url: Routing.generateAngularRoute('add_new_labour', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_new_labour', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_labour', params)
                                },
                                controller: AddNewJobLabourCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.labour_edit',{
                        url: Routing.generateAngularRoute('edit_job_labour', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_job_labour', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/costs/edit_labour.html',
                                controller: EditJobLabourCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.labour_edit_milestone', {
                        url: Routing.generateAngularRoute('edit_job_labour_milestone', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_job_labour_milestone', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/costs/edit_labour.html',
                                controller: EditJobLabourCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }

                    }).
                    state('loggedin.customer_list.view.job.details.add_new_labour_milestone',{
                        url: Routing.generateAngularRoute('add_new_labour_milestone', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_new_labour_milestone', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_labour_milestone', params)
                                },
                                controller: AddNewJobLabourCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.account_credit', {
                        url: Routing.generateAngularRoute('customer_account_credit', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_account_credit', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/account_credit/view.html',
                                controller: CustomerAccountCreditCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.customer_add_payment', {
                        url: Routing.generateAngularRoute('customer_add_payment', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_add_payment', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/account_credit/new.html',
                                controller: CreateNewCustomerAccountCreditCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.account_credit', {
                        url: Routing.generateAngularRoute('invoice_address_account_credit', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoice_address_account_credit', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/invoice_address/account_credit/view.html',
                                controller: InvoiceAddressAccountCreditCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.invoice_address_add_payment', {
                        url: Routing.generateAngularRoute('invoice_address_add_payment', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoice_address_add_payment', params)
                        },
                        views: {
                                "invoiceTabPane@": {
                                    templateUrl: 'template/customers/account_credit/new.html',
                                    controller: CreateNewInvoiceAddressAccountCreditCtrl,
                                    resolve: BreadCrumbsResolver
                                }
                            }
                    }).
                    state('loggedin.customer_list.view.edit_customer_account_credit', {
                        url: Routing.generateAngularRoute('edit_customer_account_credit', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_customer_account_credit', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/account_credit/edit_credit.html',
                                controller: EditCustomerAccountCreditCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.edit_account_credit', {
                        url: Routing.generateAngularRoute('invoiceAddressEditAccountCredit', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressEditAccountCredit', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/invoice_address/account_credit/edit_credit.html',
                                controller: EditInvoiceAddressAccountCreditCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.view_purchase_order', {
                        url: Routing.generateAngularRoute('view_job_purchase_order', true),
                        templateUrl: function (params) {
                            return Routing.generate('view_job_purchase_order', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/purchaseorder/view_purchase_order.html',
                                controller: ViewPurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.view_purchase_order.complete_purchase_order', {
                        url: Routing.generateAngularRoute('complete_job_purchase_order', true),
                        templateUrl: function (params) {
                            return Routing.generate('complete_job_purchase_order', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/suppliers/purchaseorder/complete.html',
                                controller: CustomerCompletePurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:complete_purchase_order:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:complete_purchase_order:spreadsheetGrandTotals');
                            }
                        }
                        }).
                    state('loggedin.customer_list.view.job.details.view_purchase_order.edit_purchase_order', {
                        url: Routing.generateAngularRoute('edit_job_purchase_order', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_job_purchase_order', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/suppliers/purchaseorder/edit.html',
                                controller: CustomerEditPurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:edit_purchase_order:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:edit_purchase_order:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.view_purchase_order_milestone', {
                        url: Routing.generateAngularRoute('view_job_purchase_order_milestone', true),
                        templateUrl: function (params) {
                            return Routing.generate('view_job_purchase_order_milestone', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/purchaseorder/view_purchase_order.html',
                                controller: ViewPurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.view_purchase_order.complete_purchase_order_milestone', {
                        url: Routing.generateAngularRoute('complete_job_purchase_order_milestone', true),
                        templateUrl: function (params) {
                            return Routing.generate('complete_job_purchase_order_milestone', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/suppliers/purchaseorder/complete.html',
                                controller: CustomerCompletePurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:complete_purchase_order:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:complete_purchase_order:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.details.view_purchase_order.edit_purchase_order_milestone', {
                        url: Routing.generateAngularRoute('edit_job_purchase_order_milestone', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_job_purchase_order_milestone', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/suppliers/purchaseorder/edit.html',
                                //templateUrl: 'template/jobs/purchaseorder/edit_purchase_order.html',
                                controller: CustomerEditPurchaseOrderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        },
                        onEnter: function() {
                            var grand_totals_exist = localStorage.getItem('supplier:edit_purchase_order:spreadsheetGrandTotals') !== null;
                            if(grand_totals_exist) {
                                localStorage.removeItem('supplier:edit_purchase_order:spreadsheetGrandTotals');
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.job.job_office_task', {
                        url: Routing.generateAngularRoute('job_office_task', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_office_task', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/jobs/office_task/view.html',
                                controller: JobOfficeTaskCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.service_plan', {
                        url: Routing.generateAngularRoute('service_plans', true),
                        templateUrl: function (params) {
                            return Routing.generate('service_plans', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/service_plan/view.html',
                                controller: CustomerServicePlanCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.new_service_plan', {
                        url: Routing.generateAngularRoute('new_service_plan', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_service_plan', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/service_plan/new.html',
                                controller: AddServicePlanCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.edit_service_plan', {
                        url: Routing.generateAngularRoute('edit_service_plan', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_service_plan', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/service_plan/edit.html',
                                controller: EditServicePlanCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.service_plan', {
                        url: Routing.generateAngularRoute('invoice_address_service_plans', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoice_address_service_plans', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/customers/service_plan/view.html',
                                controller: InvoiceServicePlanCrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.new_service_plan', {
                        url: Routing.generateAngularRoute('invoice_address_new_service_plan', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoice_address_new_service_plan', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/customers/service_plan/new.html',
                                controller: InvoiceAddServicePlanCrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.edit_service_plan', {
                        url: Routing.generateAngularRoute('invoice_address_edit_service_plan', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoice_address_edit_service_plan', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/customers/service_plan/edit.html',
                                controller: InvoiceEditServicePlanCrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.estimate.estimate_office_task', {
                        url: Routing.generateAngularRoute('estimate_office_task', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_office_task', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/estimate/office_task/view.html',
                                controller: EstimateOfficeTaskCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.invoice_address_setup', {
                        url: Routing.generateAngularRoute('invoice_address_setup', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceAddressSubNav', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: function (params) {
                                    return Routing.generate('invoice_address_setup', params)
                                },
                                controller: CustomerInvoiceSetupCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.stored_cards', {
                        url: Routing.generateAngularRoute('stored_cards', true),
                        templateUrl: function (params) {
                            return Routing.generate('stored_cards', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5

                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/stored_cards/view.html',
                                controller: CustomerStoredCardsCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.customer_list.view.stored_cards.new', {
                        url: Routing.generateAngularRoute('new_stored_card', true),
                        templateUrl: function (params) {
                            return Routing.generate('new_stored_card', params)
                        },
                        views: {
                            "customerTabPane@": {
                                templateUrl: 'template/customers/stored_cards/new.html',
                                controller: AddStoredCardCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.stored_cards', {
                        url: Routing.generateAngularRoute('invoice_address_stored_cards', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoice_address_stored_cards', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 5
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/invoice_address/stored_cards/view.html',
                                controller: InvoiceAddressStoredCardsCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.stored_cards.new', {
                        url: Routing.generateAngularRoute('invoice_address_new_stored_card', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoice_address_new_stored_card', params)
                        },
                        views: {
                            "invoiceTabPane@": {
                                templateUrl: 'template/customers/stored_cards/new.html',
                                controller: InvoiceAddressAddStoredCardCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.invoice_address.view.invoice_address_receive_credit', {
                    url: Routing.generateAngularRoute('invoice_address_receive_credit', true),
                    templateUrl: function (params) {
                        return Routing.generate('invoice_address_receive_credit', params)
                    },
                    views: {
                        "invoiceTabPane@": {
                            templateUrl: function (params) {
                                return Routing.generate('invoice_address_receive_credit_form', params)
                            },
                            controller: NewCustomerCreditNoteReceiveCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).
                state('loggedin.invoice_address.view.edit_invoice_address_receive_credit', {
                    url: Routing.generateAngularRoute('edit_invoice_address_receive_credit', true),
                    templateUrl: function (params) {
                        return Routing.generate('edit_invoice_address_receive_credit', params)
                    },
                    views: {
                        "invoiceTabPane@": {
                            templateUrl: 'template/customers/credit_notes/receive_credit.html',
                            controller: EditCustomerCreditNoteReceiveCtrl,
                            resolve: BreadCrumbsResolver
                        }
                    }
                }).
                state('loggedin.customer_list.view.job.pricing_items', {
                    url: Routing.generateAngularRoute('get_items_to_invoice', true),
                    templateUrl: function (params) {
                        return Routing.generate('get_items_to_invoice', params)
                    },
                    data: {
                        'pageNum': 1,
                        'limit': 10
                    },
                    views: {
                        "customerTabPane@": {
                            templateUrl: 'template/jobs/items_to_invoice/view.html',
                            controller: GetPricingItemsCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                }).
                state('loggedin.customer_list.view.job.getJobOpportunities', {
                    url: Routing.generateAngularRoute('getJobOpportunities', true),
                    templateUrl: function (params) {
                        return Routing.generate('getJobOpportunities', params)
                    },
                    data: {
                        'pageNum': 1,
                        'limit': 10
                    },
                    views: {
                        "customerTabPane@": {
                            templateUrl: 'template/jobs/opportunities/view.html',
                            controller: JobOpportunitiesListCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                });
            }
        ]);
