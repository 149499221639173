commusoftCommon.directive('cspostcode', ['prefix', '$http','$modal', function(prefix, $http, $modal) {
    return {
        transclude: true,
        restrict: 'EA',
        scope: {
            postcode: '=',
            shouldBeOpen: '=',
            error: '=',
            addressArray: '=',
            errorMessage: '=',
            moveData: '&'
        },
        controller: function($scope, $element, $attrs, $transclude) {
            $scope.$watch('shouldBeOpen', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal == true) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;

                    modalInstance = $modal.open({
                        templateUrl: 'template/postcode.html',
                        scope: $scope,
                        controller: CSPostCodeCtrl
                    })
                }
            });
        }
    }
}]);