csmodule.directive('graphWidget', ['$document', 'graphGenerator', function($document, graphGenerator) {
    var handleGraphWidget = function handleGraphWidget(scope, element, attrs) {
    scope.config = graphGenerator.generateGraph(
      attrs.type,
      JSON.parse(attrs.graphOptions),
      attrs.category
    );

    attrs.$observe('graphOptions', function(graph_options) {
      scope.config = graphGenerator.generateGraph(
        attrs.type,
        JSON.parse(attrs.graphOptions),
        attrs.category
      );
    })
  }

  return {
    restrict: 'A',
    templateUrl: 'template/graph_widget.html',
    scope: {},
    link: handleGraphWidget
  }
}]);
