commusoftCommon.service('tagList',function ($translate) {
    this.tagging = make_ordered_hash();

    /**
     * Tag should be added in all countries array
     * Tag key , value should be equal
     */

    /** Tag - group list */
    var customer_tags = make_ordered_hash();
    var wa_tags = make_ordered_hash();
    var estimate_tags = make_ordered_hash();
    var job_tags = make_ordered_hash();
    var diary_tags = make_ordered_hash();
    var invoice_tags = make_ordered_hash();
    var supplier_tags = make_ordered_hash();
    var po_tags = make_ordered_hash();
    var contact_tags = make_ordered_hash();
    var sr_tags = make_ordered_hash();
    var sr_reminder_properties_due_tags = make_ordered_hash();
    var client_tags = make_ordered_hash();
    var invoice_address_tags = make_ordered_hash();
    var landlord_tags = make_ordered_hash();
    var supplier_parts_quotation_tags = make_ordered_hash();
    var payment_tags = make_ordered_hash();
    var supplier_parts_reminder_tags = make_ordered_hash();
    var stock_returns_inform_to_supplier_tags = make_ordered_hash();
    var engineer_profile_portal_tags = make_ordered_hash();
    var job_booking_portal_tags = make_ordered_hash();
    var opportunity_tags = make_ordered_hash();

    /** Tags */

    customer_tags.push($translate('customer_account_no'), $translate('customer_account_no'));
    customer_tags.push($translate('customer_name'), $translate('customer_name'));
    customer_tags.push($translate('customer_surname'), $translate('customer_surname'));
    customer_tags.push($translate('customer_telephone'), $translate('customer_telephone'));
    customer_tags.push($translate('customer_mobile'), $translate('customer_mobile'));
    customer_tags.push($translate('customer_email'), $translate('customer_email'));
    customer_tags.push($translate('customer_address_line_1'), $translate('customer_address_line_1'));
    customer_tags.push($translate('customer_address_line_2'), $translate('customer_address_line_2'));
    customer_tags.push($translate('customer_address_line_3'), $translate('customer_address_line_3'));
    customer_tags.push($translate('customer_town'), $translate('customer_town'));
    customer_tags.push($translate('customer_county'), $translate('customer_county'));
    customer_tags.push($translate('customer_postcode'), $translate('customer_postcode'));
    customer_tags.push($translate('customer_advertising'), $translate('customer_advertising'));
    this.tagging.push('customer', customer_tags);

    wa_tags.push($translate('work_address_account_no'), $translate('work_address_account_no'));
    wa_tags.push($translate('work_address_name'), $translate('work_address_name'));
    wa_tags.push($translate('work_address_surname'), $translate('work_address_surname'));
    wa_tags.push($translate('work_address_telephone'), $translate('work_address_telephone'));
    wa_tags.push($translate('work_address_mobile'), $translate('work_address_mobile'));
    wa_tags.push($translate('work_address_email'), $translate('work_address_email'));
    wa_tags.push($translate('work_address_address_line_1'), $translate('work_address_address_line_1'));
    wa_tags.push($translate('work_address_address_line_2'), $translate('work_address_address_line_2'));
    wa_tags.push($translate('work_address_address_line_3'), $translate('work_address_address_line_3'));
    wa_tags.push($translate('work_address_town'), $translate('work_address_town'));
    wa_tags.push($translate('work_address_county'), $translate('work_address_county'));
    wa_tags.push($translate('work_address_postcode'), $translate('work_address_postcode'));
    this.tagging.push('work_address', wa_tags);

    estimate_tags.push($translate('estimate_no'), $translate('estimate_no'));
    estimate_tags.push($translate('estimate_date'), $translate('estimate_created_date'));
    estimate_tags.push($translate('estimate_description'), $translate('estimate_description'));
    estimate_tags.push($translate('estimate_engineer_notes'), $translate('estimate_engineer_notes'));
    estimate_tags.push($translate('estimate_contact_name'), $translate('estimate_contact_name'));
    estimate_tags.push($translate('estimate_address_line_1'), $translate('estimate_address_line_1'));
    estimate_tags.push($translate('estimate_address_line_2'), $translate('estimate_address_line_2'));
    estimate_tags.push($translate('estimate_address_line_3'), $translate('estimate_address_line_3'));
    estimate_tags.push($translate('estimate_address_town'), $translate('estimate_address_town'));
    estimate_tags.push($translate('estimate_address_county'), $translate('estimate_address_county'));
    estimate_tags.push($translate('estimate_address_postcode'), $translate('estimate_address_postcode'));
    estimate_tags.push($translate('estimate_contact_telephone'), $translate('estimate_contact_telephone'));
    estimate_tags.push($translate('estimate_contact_mobile'), $translate('estimate_contact_mobile'));
    estimate_tags.push($translate('estimate_contact_email'), $translate('estimate_contact_email'));
    estimate_tags.push($translate('estimate_address_account_no'), $translate('estimate_address_account_no'));
    estimate_tags.push($translate('estimate_address_name'), $translate('estimate_address_name'));
    estimate_tags.push($translate('estimate_portal_url'), $translate('estimate_portal_url'));
    estimate_tags.push($translate('estimate_total'), $translate('estimate_total'));
    estimate_tags.push($translate('estimate_body'), $translate('estimate_body'));
    this.tagging.push('estimates', estimate_tags);

    job_tags.push($translate('job_no'), $translate('job_no'));
    job_tags.push($translate('job_contact_name'),$translate('job_contact_name'));
    job_tags.push($translate('job_contact_telephone'),$translate('job_contact_telephone'));
    job_tags.push($translate('job_contact_mobile'),$translate('job_contact_mobile'));
    job_tags.push($translate('job_contact_email'),$translate('job_contact_email'));
    job_tags.push($translate('job_date'), $translate('job_created_date'));
    job_tags.push($translate('job_description'), $translate('job_description'));
    job_tags.push($translate('job_po_no'), $translate('job_po_no'));
    job_tags.push($translate('job_priority'), $translate('job_priority'));
    job_tags.push($translate('job_quoted_amount'), $translate('job_quoted_amount'));
    job_tags.push($translate('job_address_name'), $translate('job_address_name'));
    job_tags.push($translate('job_address_line_1'), $translate('job_address_line_1'));
    job_tags.push($translate('job_address_line_2'), $translate('job_address_line_2'));
    job_tags.push($translate('job_address_line_3'), $translate('job_address_line_3'));
    job_tags.push($translate('job_address_town'), $translate('job_address_town'));
    job_tags.push($translate('job_address_county'), $translate('job_address_county'));
    job_tags.push($translate('job_address_postcode'), $translate('job_address_postcode'));
    job_tags.push($translate('job_address_account_no'), $translate('job_address_account_no'));
    job_tags.push($translate('after_sales_care_portal_url'), $translate('after_sales_care_portal_url'));
    this.tagging.push('jobs', job_tags);

    diary_tags.push($translate('diary_date'), $translate('diary_date'));
    diary_tags.push($translate('diary_time'), $translate('diary_time'));
    diary_tags.push($translate('diary_start_time'), $translate('diary_start_time'));
    diary_tags.push($translate('diary_end_time'), $translate('diary_end_time'));
    diary_tags.push($translate('diary_engineer'), $translate('diary_engineer'));
    diary_tags.push($translate('diary_description'), $translate('diary_description'));
    diary_tags.push($translate('diary_engineer_notes'), $translate('diary_engineer_notes'));
    diary_tags.push($translate('diary_service_window'), $translate('diary_service_window'));
    diary_tags.push($translate('rebooking_portal_url'), $translate('rebooking_portal_url'));
    this.tagging.push('diary_events', diary_tags);

    invoice_tags.push($translate('landlord_name'),$translate('landlord_name'));
    invoice_tags.push($translate('invoice_date'), $translate('invoice_date'));
    invoice_tags.push($translate('invoice_no'), $translate('invoice_no'));
    invoice_tags.push($translate('invoice_address_name'), $translate('invoice_address_name'));
    invoice_tags.push($translate('invoice_address_line_1'), $translate('invoice_address_line_1'));
    invoice_tags.push($translate('invoice_address_line_2'), $translate('invoice_address_line_2'));
    invoice_tags.push($translate('invoice_address_line_3'), $translate('invoice_address_line_3'));
    invoice_tags.push($translate('invoice_address_town'), $translate('invoice_address_town'));
    invoice_tags.push($translate('invoice_address_county'), $translate('invoice_address_county'));
    invoice_tags.push($translate('invoice_address_postcode'), $translate('invoice_address_postcode'));
    invoice_tags.push($translate('invoice_description'), $translate('invoice_description'));
    invoice_tags.push($translate('invoice_more_details'), $translate('invoice_more_details'));
    invoice_tags.push($translate('invoice_exl_vat'), $translate('invoice_exl_vat'));
    invoice_tags.push($translate('invoice_vat'), $translate('invoice_vat'));
    invoice_tags.push($translate('invoice_total'), $translate('invoice_total'));
    invoice_tags.push($translate('invoice_total_paid'), $translate('invoice_total_paid'));
    invoice_tags.push($translate('invoice_remainder_to_pay'), $translate('invoice_remainder_to_pay'));
    invoice_tags.push($translate('invoice_portal_url'), $translate('invoice_portal_url'));
    this.tagging.push('invoice', invoice_tags);

    supplier_tags.push($translate('supplier_name'), $translate('supplier_name'));
    supplier_tags.push($translate('supplier_address_line_1'), $translate('supplier_address_line_1'));
    supplier_tags.push($translate('supplier_address_line_2'), $translate('supplier_address_line_2'));
    supplier_tags.push($translate('supplier_address_line_3'), $translate('supplier_address_line_3'));
    supplier_tags.push($translate('supplier_town'), $translate('supplier_town'));
    supplier_tags.push($translate('supplier_county'), $translate('supplier_county'));
    supplier_tags.push($translate('supplier_postcode'), $translate('supplier_postcode'));
    supplier_tags.push($translate('supplier_telephone'), $translate('supplier_telephone'));
    supplier_tags.push($translate('supplier_email'), $translate('supplier_email'));
    this.tagging.push('supplier', supplier_tags);

    po_tags.push($translate('po_date'), $translate('po_date'));
    po_tags.push($translate('po_no'), $translate('po_no'));
    po_tags.push($translate('po_description'), $translate('po_description'));
    this.tagging.push('PO', po_tags);

    contact_tags.push($translate('contact_name'),$translate('contact_name'));
    contact_tags.push($translate('contact_telephone'), $translate('contact_telephone'));
    contact_tags.push($translate('contact_mobile'), $translate('contact_mobile'));
    contact_tags.push($translate('contact_email'), $translate('contact_email'));
    contact_tags.push($translate('contact_position'), $translate('contact_position'));
    contact_tags.push($translate('contact_login_role_name'), $translate('contact_login_role_name'));
    this.tagging.push('contact', contact_tags);

    sr_tags.push($translate('service_reminder_name'), $translate('service_reminder_name'));
    sr_tags.push($translate('service_due_date'),$translate('service_due_date'));
    sr_tags.push($translate('service_contact'),$translate('service_contact'));
    sr_tags.push($translate('service_reminder_booking_portal_url'),$translate('service_reminder_booking_portal_url'));
    this.tagging.push('service_reminder', sr_tags);

    sr_reminder_properties_due_tags.push($translate('service_reminder_properties_due'), $translate('service_reminder_properties_due'));
    this.tagging.push('service_reminder_properties_due', sr_reminder_properties_due_tags);

    client_tags.push($translate('client_name'),$translate('client_name'));
    client_tags.push($translate('client_telephone'), $translate('client_telephone'));
    client_tags.push($translate('client_email'), $translate('client_email'));
    this.tagging.push('client', client_tags);

    invoice_address_tags.push($translate('invoice_address_name'), $translate('invoice_address_name'));
    invoice_address_tags.push($translate('invoice_address_line_1'), $translate('invoice_address_line_1'));
    invoice_address_tags.push($translate('invoice_address_line_2'), $translate('invoice_address_line_2'));
    invoice_address_tags.push($translate('invoice_address_line_3'), $translate('invoice_address_line_3'));
    invoice_address_tags.push($translate('invoice_address_town'), $translate('invoice_address_town'));
    invoice_address_tags.push($translate('invoice_address_county'), $translate('invoice_address_county'));
    invoice_address_tags.push($translate('invoice_address_postcode'), $translate('invoice_address_postcode'));
    invoice_address_tags.push($translate('invoice_address_telephone'), $translate('invoice_address_telephone'));
    invoice_address_tags.push($translate('invoice_address_mobile'), $translate('invoice_address_mobile'));
    invoice_address_tags.push($translate('invoice_address_email'), $translate('invoice_address_email'));
    invoice_address_tags.push($translate('statement_portal_url'), $translate('statement_portal_url'));
    this.tagging.push('invoice_address', invoice_address_tags);

    landlord_tags.push($translate('landlord_name'),$translate('landlord_name'));
    this.tagging.push('landlord', landlord_tags);


    supplier_parts_quotation_tags.push($translate('supplier_parts_quotation'),$translate('supplier_parts_quotation'));
    this.tagging.push('supplier_parts_quotation',supplier_parts_quotation_tags);

    payment_tags.push($translate('Payment_id'),$translate('payment id'));
    payment_tags.push($translate('Payment_date'),$translate('payment date'));
    payment_tags.push($translate('Payment_reference'),$translate('payment reference'));
    payment_tags.push($translate('Payment_method'),$translate('payment method'));
    payment_tags.push($translate('Payment_nominal_account'),$translate('payment nominal account'));
    payment_tags.push($translate('Payment_amount'),$translate('payment amount'));
    payment_tags.push($translate('Payment_charge'),$translate('payment charge'));
    payment_tags.push($translate('Payment_invoice_list'),$translate('payment_invoice_list'));
    this.tagging.push('payment',payment_tags);

    supplier_parts_reminder_tags.push($translate('supplier_parts_reminder_list'),$translate('supplier_parts_reminder_list'));
    this.tagging.push('supplier_parts_reminder',supplier_parts_reminder_tags);

    stock_returns_inform_to_supplier_tags.push($translate('return_parts_list'),$translate('return_parts_list'));
    this.tagging.push('stock_returns_inform_to_supplier',stock_returns_inform_to_supplier_tags);

    engineer_profile_portal_tags.push($translate('engineer_profile_portal_url'), $translate('engineer_profile_portal_url'));
    this.tagging.push('engineer_profile_portal', engineer_profile_portal_tags);

    job_booking_portal_tags.push($translate('booking_portal_link'), $translate('booking_portal_link'));
    this.tagging.push('booking_portal', job_booking_portal_tags );

    opportunity_tags.push($translate('opportunity_no'),$translate('opportunity_no'));
    opportunity_tags.push($translate('opportunity_date'),$translate('opportunity_date'));
    opportunity_tags.push($translate('opportunity_description'),$translate('opportunity_description'));
    opportunity_tags.push($translate('opportunity_engineer_notes'),$translate('opportunity_engineer_notes'));
    opportunity_tags.push($translate('opportunity_customer_reference'),$translate('opportunity_customer_reference'));
    opportunity_tags.push($translate('opportunity_invoice_category'),$translate('opportunity_invoice_category'));
    opportunity_tags.push($translate('opportunity_usergroup'),$translate('opportunity_usergroup'));
    opportunity_tags.push($translate('opportunity_access_method'),$translate('opportunity_access_method'));
    opportunity_tags.push($translate('opportunity_access_notes'),$translate('opportunity_access_notes'));
    opportunity_tags.push($translate('opportunity_parking_instructions'),$translate('opportunity_parking_instructions'));
    opportunity_tags.push($translate('opportunity_pipeline'),$translate('opportunity_pipeline'));
    opportunity_tags.push($translate('opportunity_reporting_line'),$translate('opportunity_reporting_line'));
    opportunity_tags.push($translate('opportunity_sales_person'),$translate('opportunity_sales_person'));
    opportunity_tags.push($translate('opportunity_customer_contact_name'),$translate('opportunity_customer_contact_name'));
    opportunity_tags.push($translate('opportunity_site_contact_name'),$translate('opportunity_site_contact_name'));
    opportunity_tags.push($translate('opportunity_address_line_1'),$translate('opportunity_address_line_1'));
    opportunity_tags.push($translate('opportunity_address_line_2'),$translate('opportunity_address_line_2'));
    opportunity_tags.push($translate('opportunity_address_line_3'),$translate('opportunity_address_line_3'));
    opportunity_tags.push($translate('opportunity_address_town'),$translate('opportunity_address_town'));
    opportunity_tags.push($translate('opportunity_address_county'),$translate('opportunity_address_county'));
    opportunity_tags.push($translate('opportunity_address_postcode'),$translate('opportunity_address_postcode'));
    opportunity_tags.push($translate('opportunity_customer_contact_landline'),$translate('opportunity_customer_contact_landline'));
    opportunity_tags.push($translate('opportunity_customer_contact_mobile'),$translate('opportunity_customer_contact_mobile'));
    opportunity_tags.push($translate('opportunity_customer_contact_email'),$translate('opportunity_customer_contact_email'));
    opportunity_tags.push($translate('opportunity_site_contact_landline'),$translate('opportunity_site_contact_landline'));
    opportunity_tags.push($translate('opportunity_site_contact_mobile'),$translate('opportunity_site_contact_mobile'));
    opportunity_tags.push($translate('opportunity_site_contact_email'),$translate('opportunity_site_contact_email'));
    opportunity_tags.push($translate('opportunity_address_account_no'),$translate('opportunity_address_account_no'));
    opportunity_tags.push($translate('opportunity_address_name'),$translate('opportunity_address_name'));
    opportunity_tags.push($translate('opportunity_address_landline'),$translate('opportunity_address_landline'));
    opportunity_tags.push($translate('opportunity_address_mobile'),$translate('opportunity_address_mobile'));
    opportunity_tags.push($translate('opportunity_address_email'),$translate('opportunity_address_email'));
    opportunity_tags.push($translate('upload_media_portal_url'),$translate('upload_media_portal_url'));
    opportunity_tags.push($translate('view_media_portal_url'),$translate('view_media_portal_url'));
    opportunity_tags.push($translate('supplier_price_portal_url'),$translate('supplier_price_portal_url'));
    this.tagging.push('opportunity', opportunity_tags);
});
