commusoftCommon.directive('phonewidget', ['prefix', '$http', '$timeout', 'countries', 'CS_COUNTRY', function(prefix, $http, $timeout, countries, CS_COUNTRY) {
    return {
        restrict: 'A',
        scope: {
            selectedCode: '@'
        },
        templateUrl: 'template/telephone.html',
        link: function(scope, element, attrs) {
            scope.countryName = '';
            scope.countries = countries.getCountries();
            scope.selected_country_id = CS_COUNTRY.id; //Assign Client Country ID
            scope.selected_country_code = CS_COUNTRY.code;
            scope.getCountries = function getCountries() {
                return scope.countries;
            }
            if (scope.selected_country_code == '' || scope.selected_country_code == undefined || scope.selected_country_code == "null") {
                $http.get(prefix + '/get_client_country_code').success(function (data) {
                    scope.selected_country_id = data.id;
                    scope.selected_country_code = data.code;
                    angular.forEach(scope.countries, function (val, key) {
                        if (key == scope.selected_country_code) {
                            var countryArr = countries.getCountryByCode(key);
                            scope.selected_country_id = countryArr[0].id;
                            scope.selected_country_code = key;
                        }
                    });
                    scope.$emit('GetCode', attrs.labelName, scope.selected_country_code);
                });
            }

            if (scope.selected_country_code != '') {
                angular.forEach(scope.countries, function (val, key) {
                    if (key == scope.selected_country_code) {
                        var countryArr = countries.getCountryByCode(key);
                        scope.selected_country_id = countryArr[0].id;
                        scope.selected_country_code = key;
                    }
                });
                scope.$emit('GetCode', attrs.labelName, scope.selected_country_code);
            }

            scope.getCountryByCode = function getCountryByCode(code) {
                for (var i = 0, l = scope.countries.length; i < l; i++) {
                    if (scope.countries[i].code === code) {
                        return scope.countries[i];
                    }
                }
            }

            if (scope.selectedCode != '' && scope.selectedCode != "null") {
                if (scope.selected_country_id != '') {
                    angular.forEach(scope.countries, function (val, key) {
                        if (key == scope.selectedCode) {
                            var countryArr = countries.getCountryByCode(key);
                            scope.selected_country_id= countryArr[0].id;
                            scope.selected_country_code= key;
                        }
                    });
                    scope.$emit('GetCode', attrs.labelName, scope.selected_country_code);
                }
            }

            scope.countries = scope.getCountries();
            scope.labelName = attrs.labelName

            scope.phone_input = element.parent().find('.phone-number-input');
            scope.input_collection = element.parent().find('.phone-number-wrapper');
            scope.area_code = element.parent().find('.selected-area-code');

            // Handle when a country is selected
            scope.selectCountry = function selectCountry(code) {
                var country_obj = countries.getCountryByCode(code);
                scope.selected_country_id = country_obj[0].id;
                scope.selected_country_code = code;
                scope.show_countries = false;
                // The width of the input changes depends on the number of characters the country code is
                (code.length > 2)? scope.$emit('country_code_width:changed', 3) : scope.$emit('country_code_width:changed', 2);
                scope.$emit('GetCode', attrs.labelName, code);
                $timeout(function() {
                    scope.phone_input.focus();
                }, 1);
            }

            // When the area code is clicked focus the span
            var handleAreaCodeClick = (function() {
                scope.area_code.on('click', function() {
                    element.parent().focus();
                })
            })();

            // When the input is focusssed add focussed class to parent elm
            var handleInputEvents = (function() {
                scope.phone_input.on('focus', function() {
                    element.parent().addClass('focussed');
                });

                scope.phone_input.on('blur', function() {
                    element.parent().removeClass('focussed');
                });
            })();

            var handleErrorMsgWidth = (function() {
                scope.$on('country_code_width:changed', function(e, char_length) {
                    if (char_length === 2) {
                        scope.phone_input.css('width', '150px');
                    }else if(char_length === 3) {
                        scope.phone_input.css('width', '142px');
                    }
                });
            })();
        },
        controller: function($scope) {
            $scope.$on('GetCode', function (evt, labelName, code) {
                if(labelName == 'telephone_code'){
                    $scope.$parent.telephone_code = code;
                }
                if(labelName == 'mobile_code'){
                    $scope.$parent.mobile_code = code;
                }
            });
        }
    }
}]);