'use strict';

var systemSettingsModule = angular.module('system-settings', ['vat']);

systemSettingsModule.constant('prefix', window.prefixVal).
    constant('defaultPagingValue', defaultPaginationLimit).
    provider('canLoad',function () {
        this.$get = function () {
            return {
                canLoadAjax: true,
                setLoadValue: function (canLoad) {
                    this.canLoadAjax = canLoad;
                },
                canLoad: function () {
                    return this.canLoadAjax;
                }
            }
        }
    }).
    config(
        ['$stateProvider', '$routeProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'prefix', '$provide',
            function ($stateProvider, $routeProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, prefix, $provide) {
                $stateProvider.
                    state('loggedin.diary_settings', {
                        url: Routing.generateAngularRoute('diary_settings', true),
                        templateUrl: function (params) {
                            return Routing.generate('diary_settings')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.location', {
                        url: Routing.generateAngularRoute('location', false),
                        templateUrl: function (params) {
                            return Routing.generate('location')
                        },
                        data: {
                            'locationUrl': '/listLocations',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: LocationCtrl,
                                resolve: Resolver
                            },
                            "locationPane@": {
                                templateUrl: 'template/settings/location/list_location.html',
                                controller: LocationListEditCtrl,

                                resolve: {
                                    locationresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.locationUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).state('loggedin.location.edit', {
                        params: ['id', 'selectedLocation', 'pageNum', 'limit'],
                        views: {
                            "locationPane@": {
                                templateUrl: 'template/settings/location/edit_location.html',
                                controller: LocationEditCtrl
                            }
                        }
                    }).
                    state('loggedin.fueltype', {
                        url: Routing.generateAngularRoute('fueltype', false),
                        templateUrl: function (params) {
                            return Routing.generate('fueltype')
                        },
                        data: {
                            'fuelTypeUrl': '/listFueltypes',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: FuelTypeCtrl,
                                resolve: Resolver
                            },
                            "fuelTypePane@": {
                                templateUrl: 'template/settings/fueltypes/list_fuelType.html',
                                controller: FuelTypeListEditCtrl,

                                resolve: {
                                    fuelTypesresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.fuelTypeUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.fueltype.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "fuelTypePane@": {
                                templateUrl: 'template/settings/fueltypes/edit_fuelType.html',
                                controller: FuelTypeEditCtrl
                            }
                        }
                    }).
                    state('loggedin.appliance_groups', {
                        url: Routing.generateAngularRoute('appliance_groups', false),
                        templateUrl: function (params) {
                            return Routing.generate('appliance_groups')
                        },
                        data: {
                            'applianceGroupsUrl': '/listApplianceGroups',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: ApplianceGroupsCtrl,
                                resolve: Resolver
                            },
                            "applianceGroupsPane@": {
                                templateUrl: 'template/settings/appliancegroup/list_appliancegroups.html',
                                controller: ApplianceGroupsListEditCtrl,

                                resolve: {
                                    appliancegroupsresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.applianceGroupsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.appliance_groups.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "applianceGroupsPane@": {
                                templateUrl: 'template/settings/appliancegroup/edit_appliancegroups.html',
                                controller: ApplianceGroupsEditCtrl
                            }
                        }
                    }).
                    state('loggedin.appliance_type', {
                        url: Routing.generateAngularRoute('appliance_type', false),
                        templateUrl: function (params) {
                            return Routing.generate('appliance_type')
                        },
                        data: {
                            'applianceTypesUrl': '/listApplianceType',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: ApplianceTypesCtrl,
                                resolve: Resolver
                            },
                            "applianceTypesPane@": {
                                templateUrl: 'template/settings/appliancetype/list_applianceTypes.html',
                                controller: ApplianceTypesEditListCtrl,

                                resolve: {
                                    appliancetypesresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.applianceTypesUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.appliance_type.edit', {
                        params: ['id', 'description', 'settingsAppliancegroupsDescription', 'applianceGroupId', 'pageNum', 'limit'],
                        data: {
                            'applianceTypesUrl': '/listApplianceType'
                        },
                        views: {
                            "applianceTypesPane@": {
                                templateUrl: 'template/settings/appliancetype/edit_appliancetypes.html',
                                controller: ApplianceTypesEditCtrl,

                                resolve: {
                                    appliancetypesresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.applianceTypesUrl).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.appliance_makes', {
                        url: Routing.generateAngularRoute('appliance_makes', false),
                        templateUrl: function (params) {
                            return Routing.generate('appliance_makes')
                        },
                        data: {
                            'makesUrl': '/list_ApplianceMakes',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: ApplianceMakesCtrl,
                                resolve: Resolver
                            },
                            "listApplianceMakesPane@": {
                                templateUrl: 'template/settings/appliancemakes/list_appliance_makes.html',
                                controller: ListApplianceMakesCtrl,
                                resolve: {
                                    appliancemakesresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.makesUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.appliance_makes.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "listApplianceMakesPane@": {
                                templateUrl: 'template/settings/appliancemakes/edit_list_appliance_makes.html',
                                controller: ApplianceMakesEditCtrl
                            }
                        }
                    }).
                    state('loggedin.appliance_models', {
                        url: Routing.generateAngularRoute('appliance_models', false),
                        templateUrl: function (params) {
                            return Routing.generate('appliance_models')
                        },
                        data: {
                            'applianceModelUrl': '/listApplianceModels',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: ApplianceModelsCtrl,
                                resolve: Resolver
                            },
                            "listApplianceModelPane@": {
                                templateUrl: 'template/settings/appliancemodels/list_appliance_model.html',
                                controller: ListApplianceModelsCtrl,

                                resolve: {
                                    appliancemodelsresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.applianceModelUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.appliance_models.edit', {
                        params: ['id', 'description', 'settingsTypesDescription', 'appliancetypesId', 'settingsMakesDescription', 'appliancemakesId', 'settingsFuelTypesId', 'settingsFuelDescription', 'pageNum', 'limit'],
                        data: {
                            'applianceModelUrl': '/listApplianceModels'
                        },
                        views: {
                            "listApplianceModelPane@": {
                                templateUrl: 'template/settings/appliancemodels/edit_appliance_model.html',
                                controller: ApplianceModelsEditCtrl,

                                resolve: {
                                    appliancemodelsresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.applianceModelUrl).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.technical_reference', {
                        url: Routing.generateAngularRoute('technical_reference', false),
                        templateUrl: function (params) {
                            return Routing.generate('technical_reference')
                        },
                        data: {
                            'technicalReferenceUrl': '/listTechnicalReference',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: TechnicalRefCtrl,
                                resolve: Resolver
                            },
                            "technicalRefPane@": {
                                templateUrl: 'template/settings/technicalreferences/list_technicalreference.html',
                                controller: TechnicalRefListEditCtrl,

                                resolve: {
                                    technicalresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.technicalReferenceUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.technical_reference.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "technicalRefPane@": {
                                templateUrl: 'template/settings/technicalreferences/edit_technicalreference.html',
                                controller: TechnicalRefEditCtrl
                            }
                        }
                    }).
                    state('loggedin.pricing_items', {
                        url: Routing.generateAngularRoute('pricing_items', false),
                        templateUrl: function (params) {
                            return Routing.generate('pricing_items')
                        },
                        data: {
                            'pricingItemsUrl': '/listPricingItems',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: PricingItemsCtrl,
                                resolve: Resolver
                            },
                            "pricingItemsPane@": {
                                templateUrl: 'template/settings/pricingitems/list_pricingitems.html',
                                controller: PricingItemsListEditCtrl,

                                resolve: {
                                    pricingitemsresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.pricingItemsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                }).
                state('loggedin.card_charges', {
                    url: Routing.generateAngularRoute('card_charges', true),
                    templateUrl: function (params) {
                        return Routing.generate('card_charges')
                    },
                    template: globalTemplate,
                    controller: RootCtrl,
                    resolve: Resolver
                }).
                state('loggedin.pricing_items.edit', {
                    params: ['id', 'selectedPricingItem', 'selectedUnitPrice', 'selectedTaxItemId', 'pageNum', 'limit'],
                    data: {
                        'pricingItemsUrl': '/listPricingItems'
                    },
                    views: {
                        "pricingItemsPane@": {
                            templateUrl: 'template/settings/pricingitems/edit_pricingitem.html',
                            controller: PricingItemEditCtrl,
                            resolve: {
                                pricingitemsresolver: function ($http, $q, $state, prefix) {
                                    var deferred = $q.defer();
                                    $http.get(prefix + this.data.pricingItemsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                        deferred.resolve(data);
                                    })
                                    return deferred.promise;
                                }
                            }
                        }
                    }
                }).
                    state('loggedin.nominalgroups', {
                        url: Routing.generateAngularRoute('nominalgroups', false),
                        templateUrl: function (params) {
                            return Routing.generate('nominalgroups')
                        },
                        data: {
                            'nominalGroupsUrl': '/listNominalGroups',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: NominalGroupsCtrl,
                                resolve: Resolver
                            },
                            "nominalGroupsPane@": {
                                templateUrl: 'template/settings/nominalgroups/list_nominalgroups.html',
                                controller: NominalGroupsEditListCtrl,

                                resolve: {
                                    nominalgroupresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.nominalGroupsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.nominalgroups.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "nominalGroupsPane@": {
                                templateUrl: 'template/settings/nominalgroups/edit_nominalgroups.html',
                                controller: NominalGroupsEditCtrl
                            }
                        }
                    }).
                    state('loggedin.nominal_accounts', {
                        url: Routing.generateAngularRoute('nominal_accounts', false),
                        templateUrl: function (params) {
                            return Routing.generate('nominal_accounts')
                        },
                        data: {
                            'NominalAccountsUrl': '/listNominalAccounts',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: NominalAccountsCtrl,
                                resolve: Resolver
                            },
                            "nominalAccountsPane@": {
                                templateUrl: 'template/settings/nominalaccounts/list_nominalAccounts.html',
                                controller: NominalAccountsListEditCtrl,

                                resolve: {
                                    nominalAccountsresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.NominalAccountsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.nominal_accounts.edit', {
                        params: ['id', 'settingsNominaltypesid', 'nominalGroupId', 'description', 'thirdpartyAccountingReference', 'pageNum', 'limit'],

                        data: {
                            'NominalAccountsUrl': '/listNominalAccounts'
                        },
                        views: {
                            "nominalAccountsPane@": {
                                templateUrl: 'template/settings/nominalaccounts/edit_nominalAccounts.html',
                                controller: NominalAccountsEditCtrl,

                                resolve: {
                                    nominalAccountsresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.NominalAccountsUrl).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.telephonetypes', {
                        url: Routing.generateAngularRoute('telephonetypes', false),
                        templateUrl: function (params) {
                            return Routing.generate('telephonetypes')
                        },
                        data: {
                            'telephoneTypeUrl': '/listTelephoneTypes',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: TelephoneTypeCtrl,
                                resolve: Resolver
                            },
                            "telephoneTypesPane@": {
                                templateUrl: 'template/settings/telephonetypes/list_telephonetypes.html',
                                controller: TelephoneTypeListEditCtrl,

                                resolve: {
                                    telephonetyperesolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.telephoneTypeUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.telephonetypes.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "telephoneTypesPane@": {
                                templateUrl: 'template/settings/telephonetypes/edit_telephonetypes.html',
                                controller: TelephoneTypeEditCtrl
                            }
                        }
                    }).
                    state('loggedin.title', {
                        url: Routing.generateAngularRoute('title', false),
                        templateUrl: function (params) {
                            return Routing.generate('title')
                        },
                        data: {
                            'titleUrl': '/listTitles',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: TitleCtrl,
                                resolve: Resolver
                            },
                            "titlesPane@": {
                                templateUrl: 'template/settings/titles/list_titles.html',
                                controller: TitlesListEditCtrl,

                                resolve: {
                                    titleresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.titleUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.title.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "titlesPane@": {
                                templateUrl: 'template/settings/titles/edit_titles.html',
                                controller: TitlesEditCtrl
                            }
                        }
                    }).
                    state('loggedin.firstaccountnumber', {
                        url: Routing.generateAngularRoute('firstaccountnumber', true),
                        templateUrl: function (params) {
                            return Routing.generate('firstaccountnumber')
                        },
                        template: globalTemplate,
                        controller: FirstAccountNumberCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.customer_types', {
                        url: Routing.generateAngularRoute('customer_types', false),
                        templateUrl: function (params) {
                            return Routing.generate('customer_types')
                        },
                        data: {
                            'customerTypesUrl': '/listCustomerTypes',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: CustomerTypesCtrl,
                                resolve: Resolver
                            },
                            "customerTypesPane@": {
                                templateUrl: 'template/settings/customertypes/list_customertypes.html',
                                controller: CustomerTypesListEditCtrl,

                                resolve: {
                                    customertyperesolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.customerTypesUrl + '?page=' + +this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.customer_types.edit', {
                        params: ['id', 'description', 'customerdescription', 'companynamerequired', 'allowforbranches', 'typeofjobaddress', 'pageNum', 'limit'],
                        views: {
                            "customerTypesPane@": {
                                templateUrl: 'template/settings/customertypes/edit_customertypes.html',
                                controller: CustomerTypesEditCtrl
                            }
                        }
                    }).
                    state('loggedin.advertisingtypes', {
                        url: Routing.generateAngularRoute('advertisingtypes', false),
                        templateUrl: function (params) {
                            return Routing.generate('advertisingtypes')
                        },
                        data: {
                            'advertisingTypesUrl': '/listAdvertisingTypes',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: AdvertisingTypeCtrl,
                                resolve: Resolver
                            },
                            "advertisingTypePane@": {
                                templateUrl: 'template/settings/advertisingtypes/list_advertisingtype.html',
                                controller: AdvertisingTypeListEditCtrl,

                                resolve: {
                                    advertisingtyperesolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.advertisingTypesUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.advertisingtypes.edit', {
                        params: ['id', 'description', 'key', 'showtext', 'pageNum', 'limit'],
                        data: {
                            'advertisingTypesUrl': '/allAdvertisingTypes'
                        },
                        views: {
                            "advertisingTypePane@": {
                                templateUrl: 'template/settings/advertisingtypes/edit_advertisingtype.html',
                                controller: AdvertisingTypeEditCtrl,

                                resolve: {
                                    mergeresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.advertisingTypesUrl).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.creditor_days', {
                        url: Routing.generateAngularRoute('creditor_days', false),
                        templateUrl: function (params) {
                            return Routing.generate('creditor_days')
                        },
                        data: {
                            'creditorUrl': '/listCreditordays',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: CreditorCtrl,
                                resolve: Resolver
                            },
                            "creditorPane@": {
                                templateUrl: 'template/settings/creditor/list_creditors.html',
                                controller: CreditorListEditCtrl,

                                resolve: {
                                    creditorresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.creditorUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).state('loggedin.creditor_days.edit', {
                        params: ['id', 'selectedCreditor', 'pageNum', 'limit'],
                        views: {
                            "creditorPane@": {
                                templateUrl: 'template/settings/creditor/edit_creditor.html',
                                controller: CreditorEditCtrl
                            }
                        }
                    }).
                    state('loggedin.customer_creditorday', {
                        url: Routing.generateAngularRoute('customer_creditorday', false),
                        templateUrl: function (params) {
                            return Routing.generate('customer_creditorday')
                        },
                        data: {
                            'creditorUrl': '/listCustomerCreditordays',
                            'pageNum': 1
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: RootCtrl,
                                resolve: Resolver
                            },
                            "customerCreditorPane@": {
                                templateUrl: 'template/settings/customercreditor/customer_creditordays.html',
                                controller: CustomerCreditorListEditCtrl,

                                resolve: {
                                    customercreditorresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.creditorUrl + '?page=' + this.data.pageNum).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.buildingtypes', {
                        url: Routing.generateAngularRoute('buildingtypes', false),
                        templateUrl: function (params) {
                            return Routing.generate('buildingtypes')
                        },
                        data: {
                            'buildingtypeUrl': '/listBuildingTypes',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: BuildingTypesCtrl,
                                resolve: Resolver
                            },
                            "buildingTypePane@": {
                                templateUrl: 'template/settings/buildingtypes/list_buildingtypes.html',
                                controller: BuildingTypesListEditCtrl,

                                resolve: {
                                    buildingtypesresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.buildingtypeUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).state('loggedin.buildingtypes.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "buildingTypePane@": {
                                templateUrl: 'template/settings/buildingtypes/edit_buildingtype.html',
                                controller: BuildingTypeEditCtrl
                            }
                        }
                    }).
                    state('loggedin.customer_sms_template', {
                        url: Routing.generateAngularRoute('customer_sms_template', false),
                        templateUrl: function (params) {
                            return Routing.generate('customer_sms_template')
                        },
                        data: {
                            'customerSmsTemplateUrl': '/listCustomerSmsTemplate',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: CustomerSmsTemplateCtrl,
                                resolve: Resolver
                            },
                            "customerSmsTemplatePane@": {
                                templateUrl: 'template/settings/customersmstemplates/list_customerSmsTemplate.html',
                                controller: CustomerSmsTemplateListEditCtrl,

                                resolve: {
                                    customerSmsTemplateresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.customerSmsTemplateUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.customer_sms_template.edit', {
                        params: ['id', 'title', 'message', 'pageNum', 'limit'],
                        views: {
                            "customerSmsTemplatePane@": {
                                templateUrl: 'template/settings/customersmstemplates/edit_customersmstemplate.html',
                                controller: CustomerSmsTemplateEditCtrl
                            }
                        }
                    }).
                    state('loggedin.firstestimatenumber', {
                        url: Routing.generateAngularRoute('firstestimatenumber', true),
                        templateUrl: function (params) {
                            return Routing.generate('firstestimatenumber')
                        },
                        template: globalTemplate,
                        controller: FirstEstimateNumberCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.estimate_template', {
                        url: Routing.generateAngularRoute('estimate_template', false),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_template')
                        },
                        data: {
                            'estimateTemplateUrl': '/listEstimateTemplate',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: EstimateTemplateCtrl,
                                resolve: Resolver
                            },
                            "hint@": {
                                template: "/ Estimate template"
                            },
                            "templatePane@": {
                                templateUrl: 'template/settings/estimatetemplate/list_estimate_template.html',
                                controller: EstimateTemplateListEditCtrl,

                                resolve: {
                                    estimatetemplateresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.estimateTemplateUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.estimate_template.edit', {
                        params: ['id', 'description', 'colour', 'invoiceCategoryId', 'isQuote','settingsJobDescriptionId', 'pageNum', 'limit'],
                        views: {
                            "templatePane@": {
                                templateUrl: 'template/settings/estimatetemplate/edit_estimate_template.html',
                                controller: EstimateTemplateEditCtrl,
                                resolve: {
                                    estimatetemplateresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + '/estimate_template_details').success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.estimate_template.configure', {
                        params: ['templateId', 'templateSubject', 'templateMessage', 'pageNum', 'limit'],
                        views: {
                            "configurePane@": {
                                templateUrl: 'template/settings/estimatetemplate/configure_estimate_template.html',
                                controller: ConfigureEstimateTemplateCtrl
                            },
                            "hint@": {
                                template: "<a href='' ng-click='backEstimateTemplate()' class='back_to_settings' ><span translate>/Estimate.Template</span></a> <span translate>/Configure.Estimate.Template</span>"
                            }
                        }
                    }).
                    state('loggedin.estimate_note', {
                        url: Routing.generateAngularRoute('estimate_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_note')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.estimate_sheet_message', {
                        url: Routing.generateAngularRoute('estimate_sheet_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_sheet_message')
                        },
                        template: globalTemplate,
                        controller: EngineerEstimatesheetEmailCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.engineer_estimate_sms', {
                        url: Routing.generateAngularRoute('engineer_estimate_sms', true),
                        templateUrl: function (params) {
                            return Routing.generate('engineer_estimate_sms')
                        },
                        template: globalTemplate,
                        controller: EngineerEstimateSmsCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.customer_estimate_confirmation', {
                        url: Routing.generateAngularRoute('customer_estimate_confirmation', true),
                        templateUrl: function (params) {
                            return Routing.generate('customer_estimate_confirmation')
                        },
                        template: globalTemplate,
                        controller: CustomerEstimateConfirmationCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.work_address_estimate_confirmation', {
                        url: Routing.generateAngularRoute('work_address_estimate_confirmation', true),
                        templateUrl: function (params) {
                            return Routing.generate('work_address_estimate_confirmation')
                        },
                        template: globalTemplate,
                        controller: WorkAddressEstimateConfirmationCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.estimate_reminder', {
                        url: Routing.generateAngularRoute('estimate_reminder', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_reminder')
                        },
                        template: globalTemplate,
                        controller: EstimateReminderCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.estimatedefaultemail', {
                        url: Routing.generateAngularRoute('estimatedefaultemail', false),
                        templateUrl: function (params) {
                            return Routing.generate('estimatedefaultemail')
                        },
                        template: globalTemplate,
                        controller: EstimateEmailCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.online_estimateportal', {
                        url: Routing.generateAngularRoute('online_estimateportal', true),
                        templateUrl: function (params) {
                            return Routing.generate('online_estimateportal')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.terms_and_conditions_estimates', {
                        url: Routing.generateAngularRoute('terms_and_conditions_estimates', false),
                        templateUrl: function (params) {
                            return Routing.generate('terms_and_conditions_estimates')
                        },
                        data: {
                            listtermsandconditionsUrl: '/listalltermsandconditions',
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: EstimateTermsAndConditionsCtrl,
                                resolve: Resolver
                            },
                            "termsPane@": {
                                templateUrl: 'template/settings/estimatetemplate/list_estimate_termsandconditions.html',
                                controller: ListEstimateTermsAndConditionsCtrl,
                                resolve: {
                                    termsresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.listtermsandconditionsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.additional_terms_and_conditions_estimates', {
                        url: Routing.generateAngularRoute('additional_terms_and_conditions_estimates', true),
                        templateUrl: function (params) {
                            return Routing.generate('additional_terms_and_conditions_estimates')
                        },
                        template: globalTemplate,
                        controller: AdditionalEstimateTermsAndConditionsCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.edit_terms_and_conditions', {
                        url: Routing.generateAngularRoute('edit_terms_and_conditions', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_terms_and_conditions', params)
                        },
                        views: {
                            "": {
                                templateUrl: 'template/settings/estimatetemplate/edit_estimate_termsandconditions.html',
                                controller: EditTermsAndConditionsCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.firstjobnumber', {
                        url: Routing.generateAngularRoute('firstjobnumber', true),
                        templateUrl: function (params) {
                            return Routing.generate('firstjobnumber')
                        },
                        template: globalTemplate,
                        controller: FirstJobNumberCtrl,
                        resolve: Resolver
                    }).
                state('loggedin.settings_creditcard_option', {
                    url: Routing.generateAngularRoute('settings_creditcard_option', true),
                    templateUrl: function (params) {
                        return Routing.generate('settings_creditcard_option')
                    },
                    controller: creditcard_option,
                    resolve: {
                        creditcardcheckresolver: function ($http, $q, $state, prefix) {
                            var deferred = $q.defer();
                            $http.get(prefix + '/system_settings/creditcard_option/check').success(function (data) {
                                deferred.resolve(data);
                            })
                            return deferred.promise;
                        }
                    }
                }).state('loggedin.job_sheetdesign', {
                    url: Routing.generateAngularRoute('job_sheetdesign', true),
                    templateUrl: function (params) {
                        return Routing.generate('job_sheetdesign')
                    },
                    template: globalTemplate,
                    controller: RootCtrl,
                    resolve: Resolver
                }).state('loggedin.job_summarydesign', {
                    url: Routing.generateAngularRoute('job_summarydesign', true),
                    templateUrl: function (params) {
                        return Routing.generate('job_summarydesign')
                    },
                    template: globalTemplate,
                    controller: JobSummaryDesignCtrl,
                    resolve: Resolver
                }).state('loggedin.engineer_emailjobsheetmessage', {
                    url: Routing.generateAngularRoute('engineer_emailjobsheetmessage', true),
                    templateUrl: function (params) {
                        return Routing.generate('engineer_emailjobsheetmessage')
                    },
                    template: globalTemplate,
                    controller: EngineerJobSheetEmailCtrl,
                    resolve: Resolver
                }).state('loggedin.engineer_jobsheetsms', {
                    url: Routing.generateAngularRoute('engineer_jobsheetsms', true),
                    templateUrl: function (params) {
                        return Routing.generate('engineer_jobsheetsms')
                    },
                    template: globalTemplate,
                    controller: EngineerJobSheetSmsCtrl,
                    resolve: Resolver
                }).state('loggedin.customer_job_confirmation', {
                    url: Routing.generateAngularRoute('customer_job_confirmation', true),
                    templateUrl: function (params) {
                        return Routing.generate('customer_job_confirmation')
                    },
                    template: globalTemplate,
                    controller: CustomerJobConfirmationCtrl,
                    resolve: Resolver
                }).state('loggedin.work_address_job_confirmation', {
                    url: Routing.generateAngularRoute('work_address_job_confirmation', true),
                    templateUrl: function (params) {
                        return Routing.generate('work_address_job_confirmation')
                    },
                    template: globalTemplate,
                    controller: WorkAddressJobConfirmationCtrl,
                    resolve: Resolver,
                    data: {
                        'creditorUrl': '/list_job_confirmation_templates',
                        'pageNum': 1,
                        'limit': 5
                    },
                    views: {
                        "": {
                            template: globalTemplate,
                            controller: WorkAddressJobConfirmationCtrl,
                            resolve: Resolver
                        },
                        "test@": {
                            templateUrl: 'template/settings/job_confirmation_template/job_confirmation.html',
                            controller: JobConfirmationAdvancedCtrl,
                            resolve: {
                                data: function ($http, $q, $state, prefix) {
                                    var deferred = $q.defer();
                                    $http.get(prefix + this.data.creditorUrl + '?page=' + this.data.pageNum).success(function (data) {
                                        deferred.resolve(data);
                                    })
                                    return deferred.promise;
                                }
                            }
                        }
                    }
                }).state('loggedin.add_job_confirmation', {
                    url: Routing.generateAngularRoute('add_job_confirmation_template', false),
                    templateUrl: function (params) {
                        return Routing.generate('add_job_confirmation_template')
                    },
                    template: globalTemplate,
                    controller: JobConfirmationsCtrl,
                    resolve: Resolver
                }).state('loggedin.edit_job_confirmation', {
                    url: Routing.generateAngularRoute('edit_job_confirmation_template', false),
                    templateUrl: function (params) {
                        return Routing.generate('edit_job_confirmation_template', params)
                    },
                    template: globalTemplate,
                    controller: JobConfirmationsCtrl,
                    resolve: Resolver
                }).state('loggedin.job_reminder', {
                    url: Routing.generateAngularRoute('job_reminder', true),
                    templateUrl: function (params) {
                        return Routing.generate('job_reminder')
                    },
                    template: globalTemplate,
                    controller: JobReminderCtrl,
                    resolve: Resolver
                }).state('loggedin.freeofcharge', {
                    url: Routing.generateAngularRoute('freeofcharge', false),
                    templateUrl: function (params) {
                        return Routing.generate('freeofcharge')
                    },
                    data: {
                        'freeOfChargeReasonsUrl': '/listFreeOfChargeReasons',
                        'pageNum': 1,
                        'editedId': -1,
                        'addedId': -1,
                        'limit': 5

                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: FreeOfChargeReasonsCtrl,
                                resolve: Resolver
                            },
                            "freeOfChargeReasonsPane@": {
                                templateUrl: 'template/settings/freeofchargereasons/list_freeofchargereason.html',
                                controller: FreeOfChargeReasonsListEditCtrl,

                                resolve: {
                                    chargereasonresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.freeOfChargeReasonsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.freeofcharge.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "freeOfChargeReasonsPane@": {
                                templateUrl: 'template/settings/freeofchargereasons/edit_freeofchargereason.html',
                                controller: FreeOfChargeReasonsEditCtrl
                            }
                        }
                    }).
                    state('loggedin.abortreason', {
                        url: Routing.generateAngularRoute('abortreason', false),
                        templateUrl: function (params) {
                            return Routing.generate('abortreason')
                        },
                        data: {
                            'abortReasonUrl': '/listAbortReason',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: AbortReasonCtrl,
                                resolve: Resolver
                            },
                            "abortReasonPane@": {
                                templateUrl: 'template/settings/abortreason/list_abortreason.html',
                                controller: AbortReasonListEditCtrl,

                                resolve: {
                                    abortreasonsresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.abortReasonUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.abortreason.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "abortReasonPane@": {
                                templateUrl: 'template/settings/abortreason/edit_abortreason.html',
                                controller: AbortReasonEditCtrl
                            }
                        }
                    }).
                    state('loggedin.remedial_works', {
                        url: Routing.generateAngularRoute('remedial_works', true),
                        templateUrl: function (params) {
                            return Routing.generate('remedial_works')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.certificate_emailmessage', {
                        url: Routing.generateAngularRoute('certificate_emailmessage', true),
                        templateUrl: function (params) {
                            return Routing.generate('certificate_emailmessage')
                        },
                        template: globalTemplate,
                        controller: CertificateEmailMessageCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.default_statement_message', {
                        url: Routing.generateAngularRoute('default_statement_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('default_statement_message')
                        },
                        template: globalTemplate,
                        controller: DefaultStatementMessageCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.printpost_cover_letter', {
                        url: Routing.generateAngularRoute('printpost_cover_letter', true),
                        templateUrl: function (params) {
                            return Routing.generate('printpost_cover_letter')
                        },
                        template: globalTemplate,
                        controller: PrintAndPostCoverLetterCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.customcertificates', {
                        url: Routing.generateAngularRoute('customCertificates', false),
                        templateUrl: function (params) {
                            return Routing.generate('customCertificates')
                        },
                        data: {
                            'customCertificatesUrl': '/listCustomCertificates',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: CustomCertificatesCtrl,
                                resolve: Resolver
                            },
                            "hint@": {
                                template: " / Custom certificates"
                            },
                            "customCertificatesPane@": {
                                templateUrl: 'template/settings/customcertificates/list_customcertificate.html',
                                controller: CustomCertificateListEditCtrl,

                                resolve: {
                                    customcertificatesresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.customCertificatesUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.customcertificates.edit', {
                        params: ['id', 'certificateName', 'description', 'pageNum', 'limit'],
                        views: {
                            "customCertificatesPane@": {
                                templateUrl: 'template/settings/customcertificates/edit_customcertificate.html',
                                controller: CustomCertificatesEditCtrl
                            }
                        }
                    }).
                    state('loggedin.customcertificates.configure', {
                        params: ['customCertificateId', 'certificateName', 'description'],
                        views: {
                            "configureCertificatesPane@": {
                                templateUrl: 'template/settings/customcertificates/configure_customcertificate.html',
                                controller: CustomCertificatesConfigureCtrl
                            },
                            "hint@": {
                                template: " / <a href='' ng-click='showCustomCertificate()' class='back_to_settings' > <span translate>Custom.Certificates</span></a> <span translate>/Configure.Certificate</span>"
                            }
                        }
                    }).state('loggedin.service_reminder', {
                    url: Routing.generateAngularRoute('service_reminder', false),
                    templateUrl: function (params) {
                        return Routing.generate('service_reminder')
                    },
                    data: {
                        'serviceRemindersUrl': '/listServiceReminder',
                        'pageNum': 1,
                        'limit': 5,
                        'editedId': -1,
                        'addedId': -1
                    },
                    views: {
                        "": {
                            template: globalTemplate,
                            controller: ServiceRemindersCtrl,
                            resolve: Resolver
                        },
                        "servicePane@": {
                            templateUrl: 'template/settings/servicereminder/list_service_reminder.html',
                            controller: ServiceRemindersListEditCtrl,

                            resolve: {
                                serviceremindersresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                    var deferred = $q.defer();
                                    $http.get(prefix + this.data.serviceRemindersUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                        deferred.resolve(data);
                                    })
                                    return deferred.promise;
                                }
                            }
                        }
                    }
                }).state('loggedin.service_reminder.edit', {
                    params: ['id', 'name', 'sendLetters', 'servicePeriod', 'sendReminders', 'settingsJobDescriptionId', 'Email', 'Sms', 'Letter', 'Phone', 'pageNum', 'limit'],
                    data: {
                        'serviceRemindersUrl': '/listServiceReminder',
                        'limit': -1,
                    },
                    views: {
                        "servicePane@": {
                            templateUrl: 'template/settings/servicereminder/edit_service_reminder.html',
                            controller: ServiceRemindersEditCtrl,
                            resolve: {
                                serviceremindersresolver: function ($http, $q, $state, prefix) {
                                    var deferred = $q.defer();
                                    $http.get(prefix + this.data.serviceRemindersUrl).success(function (data) {
                                        deferred.resolve(data);
                                    })
                                    return deferred.promise;
                                },
                                jobdesciptionresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                    var deferred = $q.defer();
                                    $http.get(prefix +  '/list_JobDescriptions?limit=' + this.data.limit).success(function (data) {
                                        deferred.resolve(data);
                                    })
                                    return deferred.promise;
                                }
                            }
                        }
                    }
                    }).
                    state('loggedin.service_reminder.configure_single', {
                        url: Routing.generateAngularRoute('service_reminder_configure_single', true),
                        templateUrl: function (params) {
                            return Routing.generate('service_reminder_configure_single', params)
                        },
                        views: {
                            "reminderPane@": {
                                templateUrl: 'template/settings/servicereminder/configure.html',
                                controller: ConfigureServiceRemindersSingleCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.service_reminder.configure_multiple', {
                        url: Routing.generateAngularRoute('service_reminder_configure_multiple', true),
                        templateUrl: function (params) {
                            return Routing.generate('service_reminder_configure_multiple', params)
                        },
                        views: {
                            "reminderPane@": {
                                templateUrl: 'template/settings/servicereminder/configure.html',
                                controller: ConfigureServiceRemindersMultipleCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.service_reminder.followup_single', {
                        url: Routing.generateAngularRoute('service_reminder_configure_single_followup', true),
                        templateUrl: function (params) {
                            return Routing.generate('service_reminder_configure_single_followup', params)
                        },
                        views: {
                            "reminderPane@": {
                                templateUrl: 'template/settings/servicereminder/follow_up.html',
                                controller: FollowUpServiceRemindersSingleCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.service_reminder.followup_multiple', {
                        url: Routing.generateAngularRoute('service_reminder_configure_multiple_followup', true),
                        templateUrl: function (params) {
                            return Routing.generate('service_reminder_configure_multiple_followup', params)
                        },
                        views: {
                            "reminderPane@": {
                                templateUrl: 'template/settings/servicereminder/follow_up.html',
                                controller: FollowUpServiceRemindersMultipleCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.service_reminder.followup_edit', {
                        url: Routing.generateAngularRoute('edit_service_reminder_followup', true),
                        templateUrl: function (params) {
                            return Routing.generate('edit_service_reminder_followup', params)
                        },
                        views: {
                            "reminderPane@": {
                                templateUrl: 'template/settings/servicereminder/follow_up.html',
                                controller: FollowUpServiceRemindersEditCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.service_reminder_notification', {
                        url: Routing.generateAngularRoute('service_reminder_notification', true),
                        templateUrl: function (params) {
                            return Routing.generate('service_reminder_notification', params)
                        },
                        views: {
                            "": {
                                templateUrl: 'template/settings/serviceremindernotification/service_reminder_notification.html',
                                controller: ServiceRemindersNotificationCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.firstinvoicenumber', {
                        url: Routing.generateAngularRoute('firstinvoicenumber', true),
                        templateUrl: function (params) {
                            return Routing.generate('firstinvoicenumber')
                        },
                        template: globalTemplate,
                        controller: FirstInvoiceNumberCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.invoicetemplate', {
                        url: Routing.generateAngularRoute('invoicetemplate', false),
                        templateUrl: function (params) {
                            return Routing.generate('invoicetemplate')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
//                    state('loggedin.invoice_emailmessage', {
//                        url: Routing.generateAngularRoute('invoice_emailmessage', true),
//                        templateUrl: function (params) {
//                            return Routing.generate('invoice_emailmessage')
//                        },
//                        template: globalTemplate,
//                        controller: InvoiceEmailMessageCtrl,
//                        resolve: Resolver
//                    }).
                    state('loggedin.invoicecategories', {
                        url: Routing.generateAngularRoute('invoicecategories', false),
                        templateUrl: function (params) {
                            return Routing.generate('invoicecategories')
                        },
                        data: {
                            'invoiceCategoriesUrl': '/listInvoiceCategories',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: InvoiceCategoriesCtrl,
                                resolve: Resolver
                            },
                            "invoiceCategoryPane@": {
                                templateUrl: 'template/settings/invoicecategory/list_invoicecategory.html',
                                controller: InvoiceCategoriesListEditCtrl,

                                resolve: {
                                    invoicecategoriesresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.invoiceCategoriesUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.invoicecategories.edit', {
                        params: ['id', 'description', 'thirdpartyreference', 'pageNum', 'limit'],
                        data: {
                            'invoiceCategoriesUrl': '/listInvoiceCategories'
                        },
                        views: {
                            "invoiceCategoryPane@": {
                                templateUrl: 'template/settings/invoicecategory/edit_invoicecategory.html',
                                controller: InvoiceCategoriesEditCtrl,

                                resolve: {
                                    invoicecategoriesresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.invoiceCategoriesUrl).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.invoiceitemcategories', {
                        url: Routing.generateAngularRoute('invoiceitemcategories', false),
                        templateUrl: function (params) {
                            return Routing.generate('invoiceitemcategories')
                        },
                        data: {
                            'invoiceItemCategoriesUrl': '/listInvoiceItemCategories',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: InvoiceItemCategoriesCtrl,
                                resolve: Resolver
                            },
                            "invoiceItemCategoryPane@": {
                                templateUrl: 'template/settings/invoiceitemcategory/list_invoiceitemcategory.html',
                                controller: InvoiceItemCategoriesListEditCtrl,

                                resolve: {
                                    invoiceitemcategoriesresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.invoiceItemCategoriesUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.invoiceitemcategories.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "invoiceItemCategoryPane@": {
                                templateUrl: 'template/settings/invoiceitemcategory/edit_invoiceitemcategory.html',
                                controller: InvoiceItemCategoriesEditCtrl
                            }
                        }
                    }).
                    state('loggedin.payment_methods', {
                        url: Routing.generateAngularRoute('payment_methods', false),
                        templateUrl: function (params) {
                            return Routing.generate('payment_methods')
                        },
                        data: {
                            'paymentMethodUrl': '/listPaymentMethods',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: PaymentMethodCtrl,
                                resolve: Resolver
                            },
                            "paymentMethodPane@": {
                                templateUrl: 'template/settings/paymentmethods/list_paymentMethod.html',
                                controller: PaymentMethodsListEditCtrl,

                                resolve: {
                                    paymentMethodresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.paymentMethodUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.payment_methods.edit', {
                        params: ['id', 'description', 'pageNum', 'limit', 'enableCardCharge'],
                        views: {
                            "paymentMethodPane@": {
                                templateUrl: 'template/settings/paymentmethods/edit_paymentMethod.html',
                                controller: PaymentMethodEditCtrl
                            }
                        }
                    }).
                    state('loggedin.invoice_note', {
                        url: Routing.generateAngularRoute('invoice_note', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoice_note')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.invoice_terms_conditions', {
                        url: Routing.generateAngularRoute('invoice_terms_conditions', true),
                        templateUrl: function (params) {
                            return Routing.generate('invoice_terms_conditions')
                        },
                        template: globalTemplate,
                        controller: InvoiceTermsAndConditionsCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.after_sales_communication', {
                        url: Routing.generateAngularRoute('after_sales_communication', true),
                        templateUrl: function (params) {
                            return Routing.generate('after_sales_communication')
                        },
                        template: globalTemplate,
                        controller: AfterSalesCommunicationCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.purchase_order_email', {
                        url: Routing.generateAngularRoute('purchase_order_email', true),
                        templateUrl: function (params) {
                            return Routing.generate('purchase_order_email')
                        },
                        template: globalTemplate,
                        controller: PurchaseOrderEmailMessageCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.supplier_parts_quotation_email', {
                        url: Routing.generateAngularRoute('supplier_parts_quotation_email', true),
                        templateUrl: function (params) {
                            return Routing.generate('supplier_parts_quotation_email')
                        },
                        template: globalTemplate,
                        controller: SupplierPartsQuotationEmail,
                        resolve: Resolver
                    }).
                    state('loggedin.industries', {
                        url: Routing.generateAngularRoute('industries', false),
                        templateUrl: function (params) {
                            return Routing.generate('industries')
                        },
                        data: {
                            'industryUrl': '/listIndustries',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: IndustryCtrl,
                                resolve: Resolver
                            },
                            "industriesPane@": {
                                templateUrl: 'template/settings/industries/list_industry.html',
                                controller: IndustriesListEditCtrl,

                                resolve: {
                                    industryresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.industryUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.industries.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "industriesPane@": {
                                templateUrl: 'template/settings/industries/edit_industry.html',
                                controller: IndustriesEditCtrl
                            }
                        }
                    }).
                    state('loggedin.category', {
                        url: Routing.generateAngularRoute('category', false),
                        templateUrl: function (params) {
                            return Routing.generate('category')
                        },
                        data: {
                            'categoryUrl': '/listCategories',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: CategoriesCtrl,
                                resolve: Resolver
                            },
                            "CategoriesPane@": {
                                templateUrl: 'template/settings/categories/list_categories.html',
                                controller: CategoriesListEditCtrl,

                                resolve: {
                                    categoryresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.categoryUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.category.edit', {
                        params: ['id', 'description', 'imageUrl', 'pageNum', 'limit'],
                        views: {
                            "CategoriesPane@": {
                                templateUrl: 'template/settings/categories/edit_categories.html',
                                controller: CategoriesEditCtrl
                            }
                        }
                    }).
                    state('loggedin.sub_categories', {
                        url: Routing.generateAngularRoute('sub_categories', false),
                        templateUrl: function (params) {
                            return Routing.generate('sub_categories')
                        },
                        data: {
                            'subCategoriesUrl': '/listSubCategories',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: SubCategoriesCtrl,
                                resolve: Resolver
                            },
                            "subCategoriesPane@": {
                                templateUrl: 'template/settings/subcategories/list_subCategories.html',
                                controller: ListSubCategoriesCtrl,

                                resolve: {
                                    subcategoriesresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.subCategoriesUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.sub_categories.edit', {
                        params: ['id', 'description', 'settingsCategoriesDescription', 'categoriesId', 'imageUrl', 'pageNum', 'limit'],
                        data: {
                            'subCategoriesUrl': '/listSubCategories'
                        },
                        views: {
                            "subCategoriesPane@": {
                                templateUrl: 'template/settings/subcategories/edit_subCategories.html',
                                controller: SubCategoriesEditCtrl,

                            resolve: {
                                subcategoriesresolver: function ($http, $q, $state, prefix) {
                                    var deferred = $q.defer();
                                    $http.get(prefix + this.data.subCategoriesUrl).success(function (data) {
                                        deferred.resolve(data);
                                    })
                                    return deferred.promise;
                                }
                            }
                        }
                    }
                })
                /*.state('loggedin.parts_and_prices', {
                    url: Routing.generateAngularRoute('parts_and_prices', true),
                    templateUrl: function (params) {
                        return Routing.generate('parts_and_prices')
                    },
                    data: {
                        'partsAndPricesUrl': '/listPartsAndPrices',
                        'pageNum': 1,
                        'editedId': -1,
                        'addedId': -1,
                        'limit': 5
                    },
                    views: {
                        "": {
                            template: globalTemplate,
                            controller: PartsAndPricesCtrl,
                            resolve: Resolver
                        },
                        "PartsAndPricesPane@": {
                            templateUrl: 'template/settings/partsandprices/list_partsandprices.html',
                            controller: PartsAndPricesListEditCtrl,

                                resolve: {
                                    partsandpriceresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.partsAndPricesUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                }).state('loggedin.parts_and_prices.edit', {
                    params: ['id', 'description', 'type', 'industryId',
                        'subCategoryId', 'applianceTypeId', 'fuelTypeId', 'make', 'model', 'manufacturePartNumber', 'defaultInstallTime',
                        'price', 'markUp', 'taxItemId', 'pageNum', 'limit'],
                    data: {
                        'partsAndPricesUrl': '/listPartsAndPrices'
                    },
                    views: {
                        "PartsAndPricesPane@": {
                            templateUrl: 'template/settings/partsandprices/edit_partsandprices.html',
                            controller: PartsAndPricesEditCtrl,

                            resolve: {
                                partsandpriceresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                    var deferred = $q.defer();
                                    $http.get(prefix + this.data.partsAndPricesUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                        deferred.resolve(data);
                                    })
                                    return deferred.promise;
                                }
                            }
                        }
                    }
                })*/
                .state('loggedin.stock_locations', {
                    url: Routing.generateAngularRoute('stock_locations', false),
                    templateUrl: function (params) {
                        return Routing.generate('stock_locations')
                    },
                    data: {
                        'stockLocationsUrl': '/listStockLocations',
                        'pageNum': 1,
                        'editedId': -1,
                        'addedId': -1,
                        'limit': 5
                    },
                    views: {
                        "": {
                            template: globalTemplate,
                            controller: StockLocationsCtrl,
                            resolve: Resolver
                        },
                        "stockLocationsPane@": {
                            templateUrl: 'template/settings/stocklocations/list_stocklocations.html',
                            controller: StockLocationsListEditCtrl,

                                resolve: {
                                    stocklocationresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.stockLocationsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.stock_locations.edit', {
                        params: ['id', 'description', 'pageNum', 'limit', 'selectedBranch', 'selectedBranchId'],
                        data: {
                            'stockLocationsUrl': '/listStockLocations'
                        },
                        views: {
                            "stockLocationsPane@": {
                                templateUrl: 'template/settings/stocklocations/edit_stocklocation.html',
                                controller: StockLocationsEditCtrl,

                                resolve: {
                                    stocklocationresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.stockLocationsUrl).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.stock_wastage_reasons', {
                        url: Routing.generateAngularRoute('stock_wastage_reasons', false),
                        templateUrl: function (params) {
                            return Routing.generate('stock_wastage_reasons')
                        },
                        data: {
                            'StockWastageReasonsUrl': '/listStockWastageReasons',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: StockWastageReasonsCtrl,
                                resolve: Resolver
                            },
                            "stockWastageReasonsPane@": {
                                templateUrl: 'template/settings/stocklocations/list_stockwastagereasons.html',
                                controller: StockWastageReasonsListEditCtrl,

                                resolve: {
                                    stockwastagereasonsresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.StockWastageReasonsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.stock_wastage_reasons.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "stockWastageReasonsPane@": {
                                templateUrl: 'template/settings/stocklocations/edit_stockwastagereason.html',
                                controller: StockWastageReasonsEditCtrl
                            }
                        }
                    }).
                    state('loggedin.add_qualification', {
                        url: Routing.generateAngularRoute('add_qualification', false),
                        templateUrl: function (params) {
                            return Routing.generate('add_qualification')
                        },
                        data: {
                            'qualificationUrl': '/listQualifications',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: QualificationTypesCtrl,
                                resolve: Resolver
                            },
                            "qualificationPane@": {
                                templateUrl: 'template/settings/qualification/list_qualification.html',
                                controller: QualificationListEditCtrl,

                                resolve: {
                                    qualificationresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.qualificationUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.add_qualification.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "qualificationPane@": {
                                templateUrl: 'template/settings/qualification/edit_qualification.html',
                                controller: QualificationEditCtrl
                            }
                        }
                    }).
                    state('loggedin.user_groups', {
                        url: Routing.generateAngularRoute('user_groups', false),
                        templateUrl: function (params) {
                            return Routing.generate('user_groups')
                        },
                        data: {
                            'UserGroupsUrl': '/listUserGroups',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: UserGroupsCtrl,
                                resolve: Resolver
                            },
                            "userGroupsPane@": {
                                templateUrl: 'template/settings/usergroups/list_userGroups.html',
                                controller: UserGroupsListEditCtrl,

                                resolve: {
                                    userGroupsresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.UserGroupsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.user_groups.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "userGroupsPane@": {
                                templateUrl: 'template/settings/usergroups/edit_userGroups.html',
                                controller: UserGroupsEditCtrl
                            }
                        }
                    }).
                    state('loggedin.vehicles', {
                        url: Routing.generateAngularRoute('vehicles', true),
                        templateUrl: function (params) {
                            return Routing.generate('vehicles')
                        },
                        data: {
                            'vehiclesUrl': '/listVehicles',
                            'pageNum': 1,
                            'editedId': -1,
                            'limit': 10
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: RootCtrl,
                                resolve: Resolver
                            },
                            "vehiclesPane@": {
                                templateUrl: 'template/settings/vehicles/index_vehicles.html',
                                controller: VehiclesListCtrl,

                                resolve: {
                                    vehiclesresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.vehiclesUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.add_vehicle', {
                        url: Routing.generateAngularRoute('add_vehicle', true),
                        templateUrl: function (params) {
                            return Routing.generate('add_vehicle')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.estimate_arrive_signature_message', {
                        url: Routing.generateAngularRoute('estimate_arrive_signature_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_arrive_signature_message')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.job_arrive_signature_message', {
                        url: Routing.generateAngularRoute('job_arrive_signature_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_arrive_signature_message')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.estimate_leave_signature_message', {
                        url: Routing.generateAngularRoute('estimate_leave_signature_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_leave_signature_message')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.job_leave_signature_message', {
                        url: Routing.generateAngularRoute('job_leave_signature_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_leave_signature_message')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.complete_signature_message', {
                        url: Routing.generateAngularRoute('complete_signature_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('complete_signature_message')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.estimate_mobile_engineer_sms', {
                        url: Routing.generateAngularRoute('estimate_mobile_engineer_sms', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_mobile_engineer_sms')
                        },
                        template: globalTemplate,
                        controller: MobileEngineerSmsCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.job_mobile_engineer_sms', {
                        url: Routing.generateAngularRoute('job_mobile_engineer_sms', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_mobile_engineer_sms')
                        },
                        template: globalTemplate,
                        controller: MobileEngineerSmsCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.site_arrival_questions', {
                        url: Routing.generateAngularRoute('site_arrival_questions', false),
                        templateUrl: function (params) {
                            return Routing.generate('site_arrival_questions')
                        },
                        data: {
                            'questionUrl': '/listQuestions',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5,
                            'settingsType': 'Estimate'
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: QuestionsCtrl,
                                resolve: Resolver
                            },
                            "questionsPane@": {
                                templateUrl: 'template/settings/sitearrivalquestions/list_questions.html',
                                controller: QuestionsListEditCtrl,

                                resolve: {
                                    questionresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.questionUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit + '&settingsType=' + this.data.settingsType ).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.site_arrival_questions.edit', {
                        params: ['id', 'question', 'questionHelpText', 'questionRequired', 'questionType', 'questionOptions', 'confirmationNeeded', 'pageNum', 'limit'],
                        views: {
                            "questionsPane@": {
                                templateUrl: 'template/settings/sitearrivalquestions/edit_questions.html',
                                controller: QuestionsEditCtrl
                            }
                        }
                    }).
                    state('loggedin.job_site_arrival_questions', {
                        url: Routing.generateAngularRoute('job_site_arrival_questions', false),
                        templateUrl: function (params) {
                            return Routing.generate('job_site_arrival_questions')
                        },
                        data: {
                            'questionUrl': '/listQuestions',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5,
                            'settingsType': 'Job'
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: QuestionsCtrl,
                                resolve: Resolver
                            },
                            "questionsPane@": {
                                templateUrl: 'template/settings/sitearrivalquestions/list_questions.html',
                                controller: QuestionsListEditCtrl,

                                resolve: {
                                    questionresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.questionUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit + '&settingsType=' + this.data.settingsType).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.job_site_arrival_questions.edit', {
                        params: ['id', 'question', 'questionHelpText', 'questionRequired', 'questionType', 'questionOptions', 'confirmationNeeded', 'pageNum', 'limit'],
                        views: {
                            "questionsPane@": {
                                templateUrl: 'template/settings/sitearrivalquestions/edit_questions.html',
                                controller: QuestionsEditCtrl
                            }
                        }
                    }).
                    state('loggedin.leave_estimate_questions', {
                        url: Routing.generateAngularRoute('leave_estimate_questions', false),
                        templateUrl: function (params) {
                            return Routing.generate('leave_estimate_questions')
                        },
                        data: {
                            'titleUrl': '/list_LeaveQuestions',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5,
                            'settingsType': 'Estimate'
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: LeaveJobQuesCtrl,
                                resolve: Resolver
                            },
                            "listJobQuestionsPane@": {
                                templateUrl: 'template/settings/leavejobquestions/list-leave-job-questions.html',
                                controller: ListLeaveJobQuesCtrl,
                                resolve: {
                                    jobquestionsresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.titleUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit + '&settingsType=' + this.data.settingsType).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.leave_estimate_questions.edit', {
                        params: ['id', 'questiondescription', 'questionhelptext', 'questionrequired', 'questiontype', 'options', 'confirmationNeeded', 'pageNum', 'limit'],
                        views: {
                            "listJobQuestionsPane@": {
                                templateUrl: 'template/settings/leavejobquestions/edit_leavejobquestions.html',
                                controller: LeaveJobQuesEditCtrl
                            }
                        }
                    }).
                    state('loggedin.leave_job_questions', {
                        url: Routing.generateAngularRoute('leave_job_questions', false),
                        templateUrl: function (params) {
                            return Routing.generate('leave_job_questions')
                        },
                        data: {
                            'titleUrl': '/list_LeaveQuestions',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5,
                            'settingsType': 'Job'
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: LeaveJobQuesCtrl,
                                resolve: Resolver
                            },
                            "listJobQuestionsPane@": {
                                templateUrl: 'template/settings/leavejobquestions/list-leave-job-questions.html',
                                controller: ListLeaveJobQuesCtrl,
                                resolve: {
                                    jobquestionsresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.titleUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit + '&settingsType=' + this.data.settingsType).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.leave_job_questions.edit', {
                        params: ['id', 'questiondescription', 'questionhelptext', 'questionrequired', 'questiontype', 'options', 'confirmationNeeded', 'pageNum', 'limit'],
                        views: {
                            "listJobQuestionsPane@": {
                                templateUrl: 'template/settings/leavejobquestions/edit_leavejobquestions.html',
                                controller: LeaveJobQuesEditCtrl
                            }
                        }
                    }).
                    state('loggedin.complete_job_questions', {
                        url: Routing.generateAngularRoute('complete_job_questions', false),
                        templateUrl: function (params) {
                            return Routing.generate('complete_job_questions')
                        },
                        data: {
                            'questionUrl': '/listCompleteJobQuestions',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: QuestionsCtrl,
                                resolve: Resolver
                            },
                            "questionsPane@": {
                                templateUrl: 'template/settings/completejobquestions/list_questions.html',
                                controller: CompleteJobQuestionsListEditCtrl,

                                resolve: {
                                    questionresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.questionUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.complete_job_questions.edit', {
                        params: ['id', 'question', 'questionHelpText', 'questionRequired', 'questionType', 'questionOptions', 'pageNum', 'limit'],
                        views: {
                            "questionsPane@": {
                                templateUrl: 'template/settings/completejobquestions/edit_questions.html',
                                controller: CompleteJobQuestionsEditCtrl
                            }
                        }
                    }).
                    state('loggedin.estimate_no_access_message', {
                        url: Routing.generateAngularRoute('estimate_no_access_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_no_access_message')
                        },
                        template: globalTemplate,
                        controller: NoAccessMessageCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.chat_settings', {
                    url: Routing.generateAngularRoute('chat_settings', false),
                    data: {
                        'usersUrl': '/listUsers',
                        'UserGroupsUrl': '/listUserGroups',
                        'chatApi': '/isChatSetup',
                        'pageNum': 1,
                        'limit': 10,
                        'usersTab': 0
                    },
                    templateUrl: function (params) {
                        return Routing.generate('chat_settings')
                    },
                    controller: ChatSettingsCtrl,
                    resolve: {
                        usersresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                            var deferred = $q.defer();
                            $http.get(prefix + this.data.usersUrl + '?selectedTab=' + this.data.usersTab).success(function (data) {
                                deferred.resolve(data);
                            })
                            return deferred.promise;
                        },
                        userGroupsresolver: function ($http, $q, $state, prefix) {
                            var deferred = $q.defer();
                            $http.get(prefix + this.data.UserGroupsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                deferred.resolve(data);
                            })
                            return deferred.promise;
                        },
                        chatresolver: function ($http, $q, $state, prefix) {
                            var deferred = $q.defer();
                            $http.get(prefix + this.data.chatApi + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                deferred.resolve(data);
                            })
                            return deferred.promise;
                        }
                    }
                    }).
                    state('loggedin.job_no_access_message', {
                        url: Routing.generateAngularRoute('job_no_access_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_no_access_message')
                        },
                        template: globalTemplate,
                        controller: NoAccessMessageCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.customise_mini_app', {
                        url: Routing.generateAngularRoute('customise_mini_app', true),
                        templateUrl: function (params) {
                            return Routing.generate('customise_mini_app')
                        },
                        template: globalTemplate,
                        controller: CustomiseMiniAppCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.role_based_security', {
                        url: Routing.generateAngularRoute('role_based_security', false),
                        templateUrl: function (params) {
                            return Routing.generate('role_based_security')
                        },
                        data: {
                            'securityRolesUrl': '/listSecurityRoles',
                            'pageNum': 1,
                            'addedId': -1,
                            'editedId': -1
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: SecurityRolesCtrl,
                                resolve: Resolver
                            },
                            "securityRolesPane@": {
                                templateUrl: 'template/settings/securityroles/list_securityroles.html',
                                controller: SecurityRolesListEditCtrl,

                                resolve: {
                                    securityrolesresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.securityRolesUrl + '?page=' + this.data.pageNum).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).state('loggedin.role_based_security.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "securityRolesPane@": {
                                templateUrl: 'template/settings/securityroles/edit_securityrole.html',
                                controller: SecurityRolesEditCtrl
                            }
                        }
                    }).
                    state('loggedin.configure_role', {
                        url: Routing.generateAngularRoute('configure_role', true),
                        templateUrl: function (params) {
                            return Routing.generate('configure_role')
                        },
                        template: globalTemplate,
                        controller: ConfigureRoleCtrl,
                        resolve: OpenIdResolver
                    }).
                    state('loggedin.setup_part_collection_workflow', {
                        url: Routing.generateAngularRoute('setup_part_collection_workflow', true),
                        templateUrl: function (params) {
                            return Routing.generate('setup_part_collection_workflow')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).

                    state('loggedin.estimaterejectreasons', {
                        url: Routing.generateAngularRoute('estimate_reject_reasons', false),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_reject_reasons')
                        },
                        data: {
                            'titleUrl': '/list_estimate_reject_reasons',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: EstimateRejectReasonCtrl,
                                resolve: Resolver
                            },
                            "estimateRejectReasonsPane@": {
                                templateUrl: 'template/settings/estimate_reject_reasons/list_estimate_reject_reasons.html',
                                controller: EstimateRejectReasonsListEditCtrl,

                                resolve: {
                                    estimaterejectreasonresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.titleUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.estimaterejectreasons.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "estimateRejectReasonsPane@": {
                                templateUrl: 'template/settings/estimate_reject_reasons/edit_estimate_reject_reasons.html',
                                controller: EstimateRejectReasonsEditCtrl
                            }
                        }
                    }).state('loggedin.debt_chasing_communication_message', {
                        url: Routing.generateAngularRoute('debt_chasing_communication_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('debt_chasing_communication_message')
                        },
                        views: {
                            "": {
                                templateUrl: function (params) {
                                    return Routing.generate('new_debt_chasing_communication', params)
                                },
                                controller: DebtChasingCommunicationCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.debt_chasing_communication_message.followup', {
                        url: Routing.generateAngularRoute('debt_chasing_communication_message_followup', true),
                        templateUrl: function (params) {
                            return Routing.generate('debt_chasing_communication_message_followup')
                        },
                        views: {
                            "debtChasingCommunicationFollowupPane": {
                                templateUrl: 'template/settings/debtchasingcommunication/follow_up.html',
                                controller: DebtChasingCommunicationMessagingCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.debt_chasing_communication_message.followup_edit', {
                        url: Routing.generateAngularRoute('debt_chasing_communication_followup_edit', true),
                        templateUrl: function (params) {
                            return Routing.generate('debt_chasing_communication_followup_edit', params)
                        },
                        views: {
                            "debtChasingCommunicationFollowupPane": {
                                templateUrl: 'template/settings/debtchasingcommunication/follow_up.html',
                                controller: FollowUpDebtChasingCommunicationEditCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.notification_preferences', {
                        url: Routing.generateAngularRoute('notification_preferences', false),
                        templateUrl: function (params) {
                            return Routing.generate('notification_preferences')
                        },
                        views: {
                            "": {
                                templateUrl: 'template/settings/notification_preferences/notification_preferences.html',
                                controller: NotificationPreferencesCtrl,
                                resolve: Resolver
                            }
                        }
                    }).
                    state('loggedin.work_address_messages', {
                        url: Routing.generateAngularRoute('settings_work_address', true),
                        templateUrl: function (params) {
                            return Routing.generate('settings_work_address', params)
                        },
                        views: {
                            "": {
                                templateUrl: 'template/settings/workaddress/work_address_configure.html',
                                controller: WorkAddressMessagesCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.work_address_messages.estimate_work_address_messages', {
                        url: Routing.generateAngularRoute('estimate_work_address_messages', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_work_address_messages', params)
                        },
                        views: {
                            "WorkAddressPane@loggedin.work_address_messages": {
                                templateUrl: 'template/settings/workaddress/work_address_message.html',
                                controller: WorkAddressMessageCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.work_address_messages.estimate_no_answer_messages', {
                        url: Routing.generateAngularRoute('estimate_no_answer_messages', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_no_answer_messages', params)
                        },
                        views: {
                            "WorkAddressPane@loggedin.work_address_messages": {
                                templateUrl: 'template/settings/workaddress/no_result_message.html',
                                controller: NoAnswerMessageCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.work_address_messages.job_work_address_messages', {
                        url: Routing.generateAngularRoute('job_work_address_messages', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_work_address_messages', params)
                        },
                        views: {
                            "WorkAddressPane@loggedin.work_address_messages": {
                                templateUrl: 'template/settings/workaddress/work_address_message.html',
                                controller: WorkAddressMessageCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.work_address_messages.job_no_answer_messages', {
                        url: Routing.generateAngularRoute('job_no_answer_messages', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_no_answer_messages', params)
                        },
                        views: {
                            "WorkAddressPane@loggedin.work_address_messages": {
                                templateUrl: 'template/settings/workaddress/no_result_message.html',
                                controller: NoAnswerMessageCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.work_address_messages.estimate_work_address_message_followup', {
                        url: Routing.generateAngularRoute('estimate_work_address_message_followup', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_work_address_message_followup', params)
                        },
                        views: {
                            "WorkAddressPane@loggedin.work_address_messages": {
                                templateUrl: 'template/settings/workaddress/follow_up.html',
                                controller: WorkAddressFollowUpCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.work_address_messages.job_work_address_message_followup', {
                        url: Routing.generateAngularRoute('job_work_address_message_followup', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_work_address_message_followup', params)
                        },
                        views: {
                            "WorkAddressPane@loggedin.work_address_messages": {
                                templateUrl: 'template/settings/workaddress/follow_up.html',
                                controller: WorkAddressFollowUpCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.work_address_messages.estimate_work_address_message_followup_edit', {
                        url: Routing.generateAngularRoute('estimate_work_address_message_followup_edit', true),
                        templateUrl: function (params) {
                            return Routing.generate('estimate_work_address_message_followup_edit', params)
                        },
                        views: {
                            "WorkAddressPane@loggedin.work_address_messages": {
                                templateUrl: 'template/settings/workaddress/follow_up.html',
                                controller: WorkAddressFollowUpEditCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.work_address_messages.job_work_address_message_followup_edit', {
                        url: Routing.generateAngularRoute('job_work_address_message_followup_edit', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_work_address_message_followup_edit', params)
                        },
                        views: {
                            "WorkAddressPane@loggedin.work_address_messages": {
                                templateUrl: 'template/settings/workaddress/follow_up.html',
                                controller: WorkAddressFollowUpEditCtrl,
                                resolve: BreadCrumbsResolver
                            }
                        }
                    }).
                    state('loggedin.automatic_post_sales_communication', {
                        url: Routing.generateAngularRoute('automatic_post_sales_communication', true),
                        templateUrl: function (params) {
                            return Routing.generate('automatic_post_sales_communication')
                        },
                        template: globalTemplate,
                        controller: AutomaticPostSalesCommunicationCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.automatic_post_sales_communication.view_customer', {
                        url: Routing.generateAngularRoute('automatic_post_sales_communication_view_customer', true),
                        templateUrl: function (params) {
                            return Routing.generate('automatic_post_sales_communication_view_customer', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "automaticPostTabPane@": {
                                templateUrl: 'template/settings/automatic_post_sales_communication/view_customer.html',
                                controller: AutomaticPostSalesCommunicationCustomerCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.automatic_post_sales_communication.view_work_addresses', {
                        url: Routing.generateAngularRoute('automatic_post_sales_communication_view_work_addresses', true),
                        templateUrl: function (params) {
                            return Routing.generate('automatic_post_sales_communication_view_work_addresses', params)
                        },
                        data: {
                            'pageNum': 1,
                            'limit': 10
                        },
                        views: {
                            "automaticPostTabPane@": {
                                templateUrl: 'template/settings/automatic_post_sales_communication/view_work_addresses.html',
                                controller: AutomaticPostSalesCommunicationAddressesCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                }).state('loggedin.settings_communication_preference', {
                        url: Routing.generateAngularRoute('settings_communication_preference', true),
                        templateUrl: function (params) {
                            return Routing.generate('settings_communication_preference')
                        },
                        template: globalTemplate,
                        controller: SettingsCommunicationPreferenceCtrl,
                        resolve: Resolver
                }).state('loggedin.settings_communication_preference.view_customer', {
                    url: Routing.generateAngularRoute('settings_communication_preference_view_customer', true),
                    templateUrl: function (params) {
                        return Routing.generate('settings_communication_preference_view_customer', params)
                    },
                    data: {
                        'pageNum': 1,
                        'limit': 10
                    },
                    views: {
                        "SettingsCommunicationPreferenceTabPane@": {
                            templateUrl: 'template/settings/settings_communication_preference/view_customer.html',
                            controller: SettingsCommunicationPreferenceCustomerCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                }).state('loggedin.settings_communication_preference.view_work_address', {
                    url: Routing.generateAngularRoute('settings_communication_preference_view_work_address', true),
                    templateUrl: function (params) {
                        return Routing.generate('settings_communication_preference_view_work_address', params)
                    },
                    data: {
                        'pageNum': 1,
                        'limit': 10
                    },
                    views: {
                        "SettingsCommunicationPreferenceTabPane@": {
                            templateUrl: 'template/settings/settings_communication_preference/view_work_address.html',
                            controller: SettingsCommunicationPreferenceAddressesCtrl,
                            resolve: BreadCrumbsPageLimitResolver
                        }
                    }
                }).state('loggedin.on_hold_descriptions', {
                    url: Routing.generateAngularRoute('on_hold_descriptions', false),
                    templateUrl: function (params) {
                        return Routing.generate('on_hold_descriptions')
                        },
                        data: {
                            'onholddescriptionUrl': '/list_on_hold_descriptions',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: OnHoldDescriptionCtrl,
                                resolve: Resolver
                            },
                            "onholddescriptionsPane@": {
                                templateUrl: 'template/settings/onholddescriptions/list_on_hold_descriptions.html',
                                controller: OnHoldDescriptionListEditCtrl,

                                resolve: {
                                    onholddescriptionresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.onholddescriptionUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.on_hold_descriptions.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "onholddescriptionsPane@": {
                                templateUrl: 'template/settings/onholddescriptions/edit_on_hold_descriptions.html',
                                controller: OnHoldDescriptionEditCtrl
                            }
                        }
                    }).
                    state('loggedin.statement_preferences', {
                        url: Routing.generateAngularRoute('statement_preferences', true),
                        templateUrl: function (params) {
                            return Routing.generate('statement_preferences')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.job_report_customization', {
                        url: Routing.generateAngularRoute('job_report_customization', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_report_customization')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.job_report_emailmessage', {
                        url: Routing.generateAngularRoute('job_report_emailmessage', true),
                        templateUrl: function (params) {
                            return Routing.generate('job_report_emailmessage')
                        },
                        template: globalTemplate,
                        controller: JobReportEmailMessageCtrl,
                        resolve: Resolver
                    }).
                    state('loggedin.serviceplan', {
                        url: Routing.generateAngularRoute('service_plan', false),
                        templateUrl: function (params) {
                            return Routing.generate('service_plan')
                        },
                        data: {
                            'serviceplanUrl': '/listServicePlan',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: ServicePlanCtrl,
                                resolve: Resolver
                            },
                            "serviceplanPane@": {
                                templateUrl: 'template/settings/service_plan/list_service_plan.html',
                                controller: ServicePlanListCtrl,

                                resolve: {
                                    serviceplanresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.serviceplanUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.serviceplan.edit', {
                        params: ['id', 'description', 'details', 'pageNum', 'limit'],
                        views: {
                            "serviceplanPane@": {
                                templateUrl: 'template/settings/service_plan/edit_service_plan.html',
                                controller: ServicePlanEditCtrl
                            }
                        }
                    }).
                    state('loggedin.certificate_default_list', {
                        url: Routing.generateAngularRoute('certificate_default_list', false),
                        templateUrl: function (params) {
                            return Routing.generate('certificate_default_list')
                        },
                        data: {
                            'defaultCertificateUrl': '/listDefaultCertificates'
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: RootCtrl,
                                resolve: Resolver
                            },
                            "defaultcertificatePane@": {
                                templateUrl: 'template/settings/defaultCertificates/certificateList.html',
                                controller: CertificateDefaultListCtrl,

                                resolve: {
                                    defaultcertificateresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.defaultCertificateUrl).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.merge_appliance_make', {
                        url: Routing.generateAngularRoute('merge_appliance_make', false),
                        templateUrl: function (params) {
                            return Routing.generate('merge_appliance_make')
                        },
                        data: {
                            'makeUrl': '/list_ApplianceMakes',
                            'pageNum': 1,
                            'limit': 30
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: RootCtrl,
                                resolve: Resolver
                            },
                            "applianceMakePane@": {
                                templateUrl: 'template/settings/appliancemakes/appliancemake_merge.html',
                                controller: ApplianceMakeListMergeCtrl,

                                resolve: {
                                    appliancemakeresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.makeUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit + '&list=all&screenType=merge').success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.merge_appliance_model', {
                        url: Routing.generateAngularRoute('merge_appliance_model', false),
                        templateUrl: function (params) {
                            return Routing.generate('merge_appliance_model')
                        },
                        data: {
                            'modelUrl': '/list_ApplianceModels',
                            'pageNum': 1,
                            'limit': 30
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: RootCtrl,
                                resolve: Resolver
                            },
                            "applianceModelPane@": {
                                templateUrl: 'template/settings/appliancemodels/appliancemodel_merge.html',
                                controller: ApplianceModelListMergeCtrl,

                                resolve: {
                                    appliancemodelresolver: function ($http, $q, $state, prefix) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.modelUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit +'&screenType=merge').success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.partsKit', {
                        url: Routing.generateAngularRoute('addPartKit', false),
                        templateUrl: function (params) {
                            return Routing.generate('addPartKit')
                        },
                        data: {
                            'partkitsUrl': '/listPartKits',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: PartKitCtrl,
                                resolve: Resolver
                            },
                            "partkitsPane@": {
                                templateUrl: 'template/settings/partkit/list_partkit.html',
                                controller: PartskitListEditCtrl,

                                resolve: {
                                    partKitresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.partkitsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.partsKit.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "partkitsPane@": {
                                templateUrl: 'template/settings/partkit/edit_partkit.html',
                                controller: PartsKitEditCtrl
                            }
                        }
                    }).
                    state('loggedin.partsKit.add_part', {
                        url: Routing.generateAngularRoute('partsKit_add_part', true),
                        templateUrl: function (params) {
                            return Routing.generate('partsKit_add_part', params)
                        },
                        views: {
                            "addPartPane@": {
                                templateUrl: 'template/settings/partkit/addPart.html',
                                controller: addPartCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.partsReorder', {
                        url: Routing.generateAngularRoute('addPartsReorder', false),
                        templateUrl: function (params) {
                            return Routing.generate('addPartsReorder')
                        },
                        data: {
                            'partsReorderUrl': '/listPartsReorder',
                            'pageNum': 1,
                            'editedId': -1,
                            'addedId': -1,
                            'limit': 5
                        },
                        views: {
                            "": {
                                template: globalTemplate,
                                controller: PartsReorderCtrl,
                                resolve: Resolver
                            },
                            "partsreorderPane@": {
                                templateUrl: 'template/settings/partsreorder/list_partsreorder.html',
                                controller: PartsReorderListEditCtrl,

                                resolve: {
                                    partsReorderresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                        var deferred = $q.defer();
                                        $http.get(prefix + this.data.partsReorderUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                            deferred.resolve(data);
                                        })
                                        return deferred.promise;
                                    }
                                }
                            }
                        }
                    }).
                    state('loggedin.partsReorder.edit', {
                        params: ['id', 'description', 'pageNum', 'limit'],
                        views: {
                            "partsreorderPane@": {
                                templateUrl: 'template/settings/partsreorder/edit_partsreorder.html',
                                controller: PartsReorderEditCtrl
                            }
                        }
                    }).
                    state('loggedin.partsReorder.add_part', {
                        url: Routing.generateAngularRoute('partsReorder_add_part', true),
                        templateUrl: function (params) {
                            return Routing.generate('partsReorder_add_part', params)
                        },
                        views: {
                            "addPartPane@": {
                                templateUrl: 'template/settings/partsreorder/addPartsreorder.html',
                                controller: addPartReorderCtrl,
                                resolve: BreadCrumbsPageLimitResolver
                            }
                        }
                    }).
                    state('loggedin.payment_on_completion_message', {
                        url: Routing.generateAngularRoute('payment_on_completion_message', true),
                        templateUrl: function (params) {
                            return Routing.generate('payment_on_completion_message')
                        },
                        template: globalTemplate,
                        controller: RootCtrl,
                        resolve: Resolver
                    }).
                state('loggedin.payment_receipts_message', {
                    url: Routing.generateAngularRoute('payment_receipt_message', true),
                    templateUrl: function (params) {
                        return Routing.generate('payment_receipt_message')
                    },
                    template: globalTemplate,
                    controller: PaymentReceiptsEmail,
                    resolve: Resolver
                }).
                state('loggedin.setup_bcc_email_address', {
                    url: Routing.generateAngularRoute('setup_bcc_email_address', true),
                    templateUrl: function (params) {
                        return Routing.generate('setup_bcc_email_address')
                    },
                    template: globalTemplate,
                    controller: BccEmail,
                    resolve: Resolver
                }).state('loggedin.setup_tax', {
                    url: Routing.generateAngularRoute('setup_tax', true),
                    templateUrl: function (params) {
                        return Routing.generate('setup_tax')
                    },
                    template: globalTemplate,
                    controller: RootCtrl,
                    resolve: Resolver
                }).state('loggedin.service_windows', {
                    url: Routing.generateAngularRoute('service_window', false),
                    templateUrl: function (params) {
                        return Routing.generate('service_window')
                    },
                    views: {
                        "": {
                            controller: serviceWindowCtrl,
                            templateUrl: 'template/settings/servicewindow/service_window.html',
                            resolve: serviceWindowsResolver
                        }
                    }
                }).
                state('loggedin.add_skill', {
                    url: Routing.generateAngularRoute('add_skill', false),
                    templateUrl: function (params) {
                        return Routing.generate('add_skill')
                    },
                    data: {
                        'skillsUrl': '/list_skills',
                        'pageNum': 1,
                        'editedId': -1,
                        'addedId': -1,
                        'limit': 5
                    },
                    views: {
                        "": {
                            template: globalTemplate,
                            controller: SkillTypesCtrl,
                            resolve: Resolver
                        },
                        "skillsPane@": {
                            templateUrl: 'template/settings/skills/list_skills.html',
                            controller: SkillListEditCtrl,

                            resolve: {
                                skillsresolver: function ($http, $q, $state, prefix) {
                                    var deferred = $q.defer();
                                    $http.get(prefix + this.data.skillsUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                        deferred.resolve(data);
                                    })
                                    return deferred.promise;
                                }
                            }
                        }
                    }
                }).
                state('loggedin.add_skill.edit', {
                    params: ['id', 'description', 'pageNum', 'limit'],
                    views: {
                        "skillsPane@": {
                            templateUrl: 'template/settings/skills/edit_skill.html',
                            controller: SkillEditCtrl
                        }
                    }
                }).
                state('loggedin.gps_views', {
                    url: '/system_settings/gps_views',
                    templateUrl: 'template/settings/gpsviews/gpsviews.html',
                    resolve: GpsViewsResolver,
                    controller: GpsViewsCtrl
                }).state('loggedin.supplier_parts_reminder_email', {
                    url: Routing.generateAngularRoute('supplier_parts_reminder_email', true),
                    templateUrl: function (params) {
                        return Routing.generate('supplier_parts_reminder_email')
                    },
                    template: globalTemplate,
                    controller: SupplierPartsReminderEmail,
                    resolve: Resolver
                }).state('loggedin.supplier_parts_undelivered_notifications', {
                    url: Routing.generateAngularRoute('supplier_parts_undelivered_notifications', true),
                    templateUrl: function (params) {
                        return Routing.generate('supplier_parts_undelivered_notifications')
                    },
                    template: globalTemplate,
                    controller: RootCtrl,
                    resolve: Resolver
                }).state('loggedin.hide_engineer_telephone', {
                    url: Routing.generateAngularRoute('hide_engineer_telephone', true),
                    templateUrl: function (params) {
                        return Routing.generate('hide_engineer_telephone')
                    },
                    template: globalTemplate,
                    controller: RootCtrl,
                    resolve: Resolver
                }).state('loggedin.engineer_shift', {
                    url: Routing.generateAngularRoute('engineer_shift', false),
                    templateUrl: function (params) {
                        return Routing.generate('engineer_shift')
                    },
                    data: {
                        'titleUrl': '/listEngineerShift',
                        'pageNum': 1,
                        'editedId': -1,
                        'addedId': -1,
                        'limit': 5
                    },
                    views: {
                        "": {
                            template: globalTemplate,
                            controller: EngineerShiftCtrl,
                            resolve: Resolver
                        },
                        "shiftPane@": {
                            templateUrl: 'template/settings/engineer_shift/list_engineer_shift.html',
                            controller: EngineerShiftListCtrl,

                            resolve: {
                                shiftresolver: function ($http, $q, $state, prefix, defaultPagingValue) {
                                    var deferred = $q.defer();
                                    $http.get(prefix + this.data.titleUrl + '?page=' + this.data.pageNum + '&limit=' + this.data.limit).success(function (data) {
                                        deferred.resolve(data);
                                    })
                                    return deferred.promise;
                                }
                            }
                        }
                    }
                }).state('loggedin.engineer_shift.edit', {
                    params: ['id', 'description', 'shiftEngineerName', 'pageNum', 'limit'],
                    views: {
                        "shiftPane@": {
                            templateUrl: 'template/settings/engineer_shift/edit_engineer_shift.html',
                            controller: EngineerShiftEditCtrl
                        }
                    }
                }).state('loggedin.configure_shift', {
                    url: Routing.generateAngularRoute('configure_shift', false),
                    templateUrl: function (params) {
                        return Routing.generate('configure_shift', params)
                    },
                    views: {
                        "": {
                            controller: shiftSettingCtrl,
                            templateUrl: 'template/settings/engineer_shift/configure_engineer_shift.html',
                            resolve: shiftSettingsResolver
                        }
                    }
                }).state('loggedin.estimate_disclaimer_message', {
                    url: Routing.generateAngularRoute('estimate_disclaimer_message', true),
                    templateUrl: function (params) {
                        return Routing.generate('estimate_disclaimer_message')
                    },
                    template: globalTemplate,
                    controller: EstimateDisclaimerMessageCtrl,
                    resolve: Resolver
                }).state('loggedin.job_duration_settings', {
                    url: '/system_settings/job_duration_settings',
                    templateUrl: 'template/settings/job_duration/job_duration_settings.html',
                    resolve: JobDuartionSettingsResolver,
                    controller: JobDurationSettingsCtrl
                }).state('loggedin.setup_stock_returns_inform_to_supplier', {
                    url: Routing.generateAngularRoute('stock_returns_inform_to_supplier', true),
                    templateUrl: function (params) {
                        return Routing.generate('stock_returns_inform_to_supplier')
                    },
                    template: globalTemplate,
                    controller: PaymentReceiptsEmail,
                    resolve: Resolver
                }).state('loggedin.payment_authorization_message', {
                    url: '/system_settings/payment_authorization_message',
                    templateUrl: 'template/settings/paymentauthorizationmessage/authorization_message.html',
                    resolve: PaymentMessageResolver,
                    controller: PaymentMessageSettingsCtrl
                }).state('loggedin.purchase_order_number', {
                    url: Routing.generateAngularRoute('purchase_order_number', true),
                    templateUrl: function (params) {
                        return Routing.generate('purchase_order_number')
                    },
                    template: globalTemplate,
                    controller:PurchaseOderNumberCtrl,
                    resolve: Resolver
                }).state('loggedin.authorization_card_payment', {
                    url: Routing.generateAngularRoute('authorization_card_payment', true),
                    templateUrl: function (params) {
                        return Routing.generate('authorization_card_payment')
                    },
                    template: globalTemplate,
                    controller: AuthorizationCardPaymentCtrl,
                    resolve: Resolver
                });
            }
        ]);
