csmodule.directive('addmilestone', function($q, $compile, $timeout, sidePanel, prefix, $http) {
  return {
      restrict: 'A',
      templateUrl: 'template/estimate/estimate_details/todos/add_milestone.html',
      scope: {},
      controller: function ($scope, $element, $attrs, $rootScope) {

          $scope.handleMileStone = function handleMileStone() {
              var milestone_name_valid = $attrs.milestoneName.length > 0;

              if(milestone_name_valid) {
                  $scope.checkMilestoneUrl = $scope.$parent.$parent.checkMilestoneUrl;
                  $http.post($scope.checkMilestoneUrl, 'milestone=' + $attrs.milestoneName).success(function (data, status) {
                    if(data == true) {
                        $scope.updating = false;
                        $scope.$parent.milestone_name_error = false;
                        $scope.$parent.milestone_name_check_error = true;
                    } else {
                        $scope.updating = true;
                        $scope.addMileStone();
                    }
                  });
              }else {
                  $scope.handleErrorUI();
              }
          }

          $scope.addMileStone = function addMileStone() {
              $scope.$parent.milestone_name_error = false;
              $scope.$parent.milestone_name_check_error = false;
              $scope.milestone_title = $attrs.milestoneName;

              // Get the urls to get & post the datas.
              $scope.addMilestoneUrl = $scope.$parent.$parent.addMilestoneUrl;
              $scope.editMilestoneUrl = $scope.$parent.$parent.editMilestoneUrl;
              $scope.deleteMilestoneUrl = $scope.$parent.$parent.deleteMilestoneUrl;
              $scope.getPlanListUrl = $scope.$parent.$parent.getPlanListUrl;
              $scope.addPlanUrl = $scope.$parent.$parent.addPlanUrl;
              $scope.updatePlanUrl = $scope.$parent.$parent.updatePlanUrl;
              $scope.deletePlanUrl = $scope.$parent.$parent.deletePlanUrl;

              // Post the milestone name to store in db and get the response.
              $http.post($scope.addMilestoneUrl, 'milestone=' + $scope.milestone_title).success(function (data, status) {

                  if(status == 200) {
                      $scope.milestoneId = data.milestoneId;

                      if($attrs.category === 'todos') {
                          var milestone_spreadsheet = $scope.makeSpreadSheet();
                          $scope.insertSpreadSheetToDom(milestone_spreadsheet).then(function(milestone_elm) {
                              $scope.updating = false;
                              $scope.$parent.milestone_name = '';
                              sidePanel.close();
                              $scope.scrollToNewSpreadSheet(milestone_elm);
                          });
                      }
                  }
              });
          }

          $scope.handleErrorUI = function handleErrorUI() {
              $scope.$parent.milestone_name_error = true;
              $scope.$parent.milestone_name_check_error = false;
          }

          $scope.makeSpreadSheet = function makeSpreadSheet() {
              var spreadsheet_tpl = '<div spreadsheet ' +
                  'category=' + "'" + $scope.milestone_title + "' " +
                  'category_id=' + "'" + $scope.milestoneId + "' " +
                  'spreadsheet_title=' + "'" + $scope.milestone_title + "' " +
                  'supports_fullscreen="true" ' +
                  'supports_print="true" ' +
                  'supports_download="true" ' +
                  'supports_actions_column="true" ' +
                  'supports_associated_rows="true" ' +
                  'get_list_url = "getPlanListUrl"' +
                  'add_url = "addPlanUrl"' +
                  'update_url = "updatePlanUrl"' +
                  'delete_url = "deletePlanUrl"' +
                  'add_milestone_url = "addMilestoneUrl"' +
                  'edit_milestone_url = "editMilestoneUrl"' +
                  'check_milestone_url = "checkMilestoneUrl"' +
                  'delete_milestone_url = "deleteMilestoneUrl"' +
                  'edit_delete = "estimateProjectMilestoneDelete"' +
                  'klass="todos-spreadsheet"' +
                  'class="spreadsheet-wrapper clearfix"></div>';

              return $compile(spreadsheet_tpl)($scope);
          }

          $scope.insertSpreadSheetToDom = function insertSpreadSheetToDom(milestone_spreadsheet) {
              var deferred = $q.defer();

              $timeout(function() {
                  var milestones_wrapper = document.querySelector('.milestones-wrapper');
                  angular.element(milestones_wrapper).append(milestone_spreadsheet);
                  deferred.resolve(milestone_spreadsheet[0]);
              },100);

              return deferred.promise;
          }

          $scope.scrollToNewSpreadSheet = function scrollToNewSpreadSheet(milestone_elm) {
              var scrollwrapper = document.querySelector('.commusoft_scroll_wrapper'),
                  scrollPos = (milestone_elm.offsetTop - scrollwrapper.offsetHeight) +
                      milestone_elm.offsetHeight;

              scrollTo(scrollwrapper, scrollPos, 200, 'easeInOutCirc');
          }

          $scope.close = function close() {
              $scope.$parent.milestone_name_error = false;
              $scope.$parent.milestone_name_check_error = false;
              $scope.$parent.milestone_name = '';
              sidePanel.close();
          }
      }
  }
});