commusoftCommon.directive('cannotdeletemodal', function($document, $timeout, $filter) {
    var handleCannotDelete = function handleCannotDelete(scope, element, attrs) {
        scope.title = $filter('translate')(attrs.title);
        scope.message = $filter('translate')(attrs.message, attrs.translateparams);
        scope.modal_id = attrs.identifier;

        scope.handleListenerClose = function() {
            $timeout(function() {
                // wait for the modal to close
                var modal_directive = $('.cannot-delete-directive.' + scope.modal_id),
                    modal_elm = $('#' + scope.modal_id);
                modal_elm.remove();

                if (modal_directive) {
                    modal_directive.remove();
                }
            }, 1000);
        }

        attrs.$observe('message', function(message) {
            scope.message = $filter('translate')(attrs.message, attrs.translateparams);
        });

        if (attrs.openOnCompile === "true") {
            $timeout(function(){
                var modal_elm = $('#' + scope.modal_id);
                modal_elm.modal('show');

                if (attrs.removeOnClose === "true") {
                    var elms_to_attach_click_listners = $('button.close, .modal-backdrop');
                    for (var i = 0; i < elms_to_attach_click_listners.length; i++) {
                        var elm = elms_to_attach_click_listners[i];
                        elm.addEventListener('click', scope.handleListenerClose);
                    }
                }
            }, 100);
        }

        scope.close = function close() {
            var modal_elm = $('#' + scope.modal_id);
            modal_elm.modal('hide');
            if(scope.$parent.show_delete_modal_overlay) {
                scope.$emit('hide_cost_overlay');
            }
        }
    }

    return {
        restrict: 'A',
        templateUrl: '/template/shared/cannot_delete_modal.html',
        scope: {},
        link: handleCannotDelete
    }
});