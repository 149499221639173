var TransferGpsDeviceResolver = {
    data: function ($q, $http, $stateParams, $state, PubNub, $rootScope, cacheBust, prefix, adminPrefix) {
        var deferred = $q.defer();

        $http.get(Routing.generate('get_gps_device_transfer_data', $stateParams))
            .success(function (data, status, headersObj) {
                var headers = headersObj();
                SetRootScopeValues($rootScope, headers, PubNub, cacheBust, $http, prefix, adminPrefix, $state);
                if (data.location && data.location == 'logout') {
                    $rootScope.$broadcast('chat:unregister');
                    $state.transitionTo('home');
                } else if (data.location && data.location.endsWith("/")) {
                    //$state.transitionTo('home');
                } else {
                    deferred.resolve(data);
                }
            });

        return deferred.promise;
    }
};

var UserTransferGpsDeviceCtrl = function ($scope, $rootScope, data, $http, toastBox, $state) {
    $scope.deviceDetails = data.deviceDetails;
    $scope.usersList = data.transferableUsers.filter(function (u) {
        return u.id !== $scope.deviceDetails.userId;
    }).map(function (u) {
        u['text'] = u['name'] + ' ' + u['surname'];
        return u;
    });
    $scope.showWarningModal = false;

    // Set breadcrumb
    $scope.$emit('tabUser:selected', {
        id: $scope.deviceDetails.userId,
        username: $scope.deviceDetails.username,
        name: $scope.deviceDetails.userName,
        surname: $scope.deviceDetails.userSurname
    });

    $scope.formValue = {
        deviceId: $scope.deviceDetails.id,
        transferTo: ''
    };

    $scope.usersSelect2Options = {
        placeholder: "Select user",
        'multiple': false,
        allowClear: true,
        id: function (obj) {
            return obj.id;
        },
        'data': $scope.usersList,
        formatResult: function (item) {
            return item.text;
        },
        formatSelection: function (item) {
            return item.text;
        },
        formatNoMatches: function (item) {
            if (item != '') {
                return 'No results match "' + item + '"';
            } else {
                return "";
            }
        }
    };
    $scope.isUserDropdownTouched = false;
    $scope.dropdownValueSelected = function ($model) {
        if (!$scope.isUserDropdownTouched) {
            $model.$setPristine();
        }

        if ($scope.formValue['transferTo']) {
            $model.$valid = true;
            $model.$error.required = false;
        }
        else {
            $model.$valid = false;
            $model.$error.required = true;
        }

        $scope.isUserDropdownTouched = true;

    };

    // Navigate back
    $scope.back = function () {
        if ($rootScope.previousState) $state.transitionTo($rootScope.previousState, $rootScope.previousStateparams);
        else $state.transitionTo('loggedin.users.view.details', {'id': $scope.deviceDetails.userId});
    };

    // Transfer success action
    $scope.onTransferSuccess = function (event) {
        switch (event.status) {
            case "TRANSFERRED" : {
                toastBox.show('GPS device transferred successfully', 3000);
                $scope.back();
                break;
            }
            case "FAILED": {
                toastBox.show('Failed to transfer GPS device. ' + result.statusText, 10000);
                break;
            }
        }
    };

};