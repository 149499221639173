/**
 * Created by sivachandran on 14/2/18.
 */

'use strict';

function JobViewPartsCtrl($scope, $state, prefix, $http, getIdData, $rootScope, tableCollection, defaultPagingValue, changeStatusDrop, jobData, $compile, warningModal, $sce, $translate, formPanelCollection,StockData,$timeout) {
    PermissionCheck.call(this, $scope);
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.selectedTab = "Parts";
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.$emit('displayInvoiceScreen', false);
    jobData.setPartLocations(getIdData.partLocation);
    $scope.jobSubNavStatic = false;
    $scope.hideSubNavCustomerName = true;
    $scope.shouldBeOpenNew = false;
    $scope.$on('modal:close', function(evt,data) {
        $scope.shouldBeOpenNew = false;
    });

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', { 'jobId' : getIdData.jobId, 'jobType' : getIdData.jobDetails.type});
    $rootScope.$broadcast('parts:countupdated', getIdData.partsCount);

    $scope.job = getIdData.jobDetails;
    $scope.jobCompletedReasons =  $scope.job.completedreason;
    $scope.moduleType = $rootScope.moduleType;

    $scope.filterStatus = getIdData.filterStatus;
    if($rootScope.clientJobType != 3){
        $scope.filterStatus = _.omit($scope.filterStatus, 'Returned_To_Stock');
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    }
    else {
        $scope.limit = defaultPagingValue;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    }
    else {
        $scope.currentPage = 1;
    }

    $scope.massfulfillInitiated = function massfulfillInitiated() {
        $scope.$broadcast('sidepanel_mass_fulfillment_stock_refresh','job_parts');
    }
    JobPartsListCtrl.call(this, $scope, $http, getIdData, $rootScope, tableCollection, changeStatusDrop);

    $scope.deleteParam = [
        {
            "available": {
                "url": "/mark_part_available",
                "validationMessage": "CONFIRM to Part as Available",
                "title": "Mark part as available",
                "content": "To confirm you wish to mark this part as available, please type 'CONFIRM' in the box below and click mark part as available.",
                "deleteText": "Mark part as available",
                "deleteArray": "jobPartAvailable[id]="
            },
            "installed": {
                "url": "/mark_part_installed",
                "validationMessage": "CONFIRM to Part as installed",
                "title": "Mark part as installed",
                "content": "To confirm you wish to mark this part as installed, please type 'CONFIRM' in the box below and click mark part as installed.",
                "deleteText": "Mark part as installed",
                "deleteArray": "jobPartInstalled[id]="
            }
        }
    ];

    $rootScope.$on('smart_table:pinned_rows_updated', function(event, rows_store) {
        $scope.chosenParts = rows_store;
    });

    $scope.fetchJobParts = function (pageNum) {
        $scope.$broadcast('smart_tablefilter:newsearch',{'category': $scope.partCategoryBatch, currentPage:pageNum});
    }
    $scope.handleJobCompleted = function() {
        var message = $translate('You.cannot.add.new.part'),
            completeReason = 'completed',
            warningId = 'completed';
        if($scope.jobCompletedReasons) {
            warningId = $scope.jobCompletedReasons.toLowerCase();
            if($scope.jobCompletedReasons.toLowerCase() == 'freeofcharge') {
                completeReason = $translate("free.of.charged");
            } else {
                completeReason = $scope.jobCompletedReasons.toLowerCase();
            }
            message = message + completeReason;
        } else {
            message = message + completeReason;
        }
        warningModal.show(message, "Parts", "parts_"+warningId);
    };

    $scope.endPoint = "/customers/"+$scope.customerTabName+"/"+$scope.selectedId+"/jobs/"+$scope.jobId+"/add_part_kit";
    var partKitLabel = {
        "part_kit": {
            "fields": [
                {
                    "label": "Select a part kit",
                    "type": "smart_filter_dynamic",
                    "url": "/getPartKitList",
                    "required": false,
                    "model": "part_kit",
                    "paidFilter" : "ng-change='getPartKit()'",
                },
                {
                    "filePath": "/template/side_panels/parts_kit.html",
                    "type": "dynamicTemplate",
                    "templateCategory": "display_parts_kit",
                    "data": {
                        "partStatus": getIdData.partStatusOptions
                    }
                }
            ]
        }
    };

    formPanelCollection.setFilterData(partKitLabel);
    var removeListener = $rootScope.$on('panelwithform:form_save_data', function (evt, data) {
         $scope.$broadcast('smartTable:partsReload',1,'');
    });

    $scope.$on("$destroy", removeListener); // remove listener if current scope destroyed COM-11972

    $scope.$on('sidepanel-template', function (evt, instance) {
        instance.isShowPanel = false;
        instance.form_is_valid = false;
        instance.isPartsEmpty = false;
        var isPartStatusRequired = !!(instance.templateData.partStatus.length > 0);
        instance.getPartKit = function () {
                if(instance.part_kit)
                {
                    instance.isShowPanel = false;
                    instance.isPartsEmpty = false;
                    instance.part_status = "";
                    var partKitId = instance.part_kit.id;
                    $http.get(prefix+'/getPartKit?partId='+partKitId).success(function (value,status) {
                        if(typeof value.error == 'undefined' || value.error === false) {
                            instance.form_is_valid = (isPartStatusRequired) ? false : true;
                            instance.isShowPanel = true;
                            instance.parts = value;
                        }else
                        {
                            instance.isPartsEmpty = true;
                        }
                    }).error(function(error) {
                        console.log(error.data);
                    });
                }
            };
    });
}

function JobPartsViewCtrl($scope, $http, getIdData, $rootScope, tableCollection, changeStatusDrop, $state){


    $scope.jobId = $state.params.jobId;
    $scope.selectedId = $state.params.id;
    $scope.milestoneId = $state.params.milestoneId;
    $scope.job = getIdData.jobDetails;
    $scope.filterStatus = getIdData.filterStatus;
    $scope.job['active_milestone_id'] = parseInt($state.params.milestoneId);

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);

    $scope.selectedTab = "Parts";
    $scope.jobSubNavStatic = false;


    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', $scope.jobId);

    if($rootScope.clientJobType != 3){
        $scope.filterStatus = _.omit($scope.filterStatus, 'Returned_To_Stock');
    }

    if ($state && $state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state && $state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    JobPartsListCtrl.call(this, $scope, $http, getIdData, $rootScope, tableCollection, changeStatusDrop);
}

function JobPartsCtrl($scope, $state, prefix, $http, getIdData, $rootScope, tableCollection, changeStatusDrop) {
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);

    $scope.selectedTab = "Parts";
    $scope.jobSubNavStatic = false;


    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', getIdData.jobId);

    $scope.job = getIdData.jobDetails;
    $scope.moduleType = $rootScope.moduleType;
    $scope.milestoneId = getIdData.jobDetails.active_milestone_id;
    $scope.job['active_milestone_id'] = parseInt($state.params.milestoneId);

    if($scope.job.type == 'Project') {
        $rootScope.$on("smart_table:milestoneUpdate", function (data) {
            $http.post(prefix + '/get_jobpart_milestone_data', 'jobId=' + $scope.jobId ).success(function (data) {
                $scope.app = data;
                angular.forEach($scope.job.milestones , function (key, value){
                    $scope.job.milestones[value].installequantity = $scope.app.installPart[key.id];
                    $scope.job.milestones[value].requestquantity = $scope.app.requestPart[key.id];
                });
            });
        });
    }

}

function JobPartsListCtrl($scope, $http, getIdData, $rootScope, tableCollection, changeStatusDrop) {

    var categories = {
        'All':'job_parts',
        'Requested':'job_parts_requested',
        'On_Order':'job_parts_on_order',
        'Available':'job_parts_available',
        'Picked.up':'job_parts_picked_up',
        'Scheduled':'job_parts_scheduled',
        'In_Progress':'job_parts_progress',
        'Installed':'job_parts_installed',
        'Cancelled':'job_parts_cancelled',
        'Returned':'job_parts_returned'
    };

    if($rootScope.clientJobType == 3){
        categories['Returned_To_Stock'] = 'job_parts_returned_to_stock';
        categories['Available'] = 'job_parts_available_with_stock';
    }

    $scope.partCategoryBatch = categories['All'];
    if(!$scope.milestoneId){
        $scope.milestoneId = '';
    }

    /*==========================================================================================================================
     When the parts filter changes, we need to empty the checked rows from the smart tables
     ==========================================================================================================================*/
    $scope.current_part_status = $scope.partsStatus = 'All';
    $scope.handlePartsFilter = function handlePartsFilter(partsStatus) {
        if($scope.current_part_status == partsStatus){$scope.partsStatus = partsStatus = 'All';} else { $scope.partsStatus = partsStatus;}
        $scope.current_part_status = partsStatus;
        $scope.$broadcast('smarttable:pagination:remove');
        $rootScope.rows_store = [];
        $scope.showSmartTable = false;
        $http.get(Routing.generate('list_parts', { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId }) + '?category='+categories[partsStatus]+'&status=' + partsStatus + '&page=' + $scope.currentPage + '&limit=' + $scope.limit + '&moduleId=' + $rootScope.clientJobType + '&jobtodos=' + $rootScope.clientJobTodos + '&milestoneval=' + $scope.milestoneId ).success(function (data) {
            $scope.partCategoryBatch = categories[partsStatus];
            tableCollection.setData(categories[partsStatus], data[categories[partsStatus]]);
            tableCollection.setHeaders(categories[partsStatus], data[categories[partsStatus]])
            changeStatusDrop.setData(categories[partsStatus], data[categories[partsStatus]][0].changeStatus)
            $scope.statusCount = data[categories[partsStatus]].statusCount;
            $scope.showSmartTable = true;
            if(data[categories[partsStatus]]){
                $scope.job_has_parts = true;
            }else{
                $scope.job_has_parts = false;
            }
        })
    }

    $scope.handlePartsSearchFilter = function handlePartsSearchFilter(partsSearch, partsStatus) {
        $rootScope.partsSearch = partsSearch;
        $rootScope.partsStatus = partsStatus;
        JobPartsFilter.call(this, $scope, $rootScope);
    }
    if(getIdData.jobParts) {
        tableCollection.setData('job_parts', getIdData.jobParts.job_parts);
        tableCollection.setHeaders('job_parts', getIdData.jobParts.job_parts);
        changeStatusDrop.setData('job_parts', getIdData.changeStatus);
        $scope.showSmartTable = true;
        $scope.statusCount = getIdData.jobParts.job_parts.statusCount;
        if (getIdData.jobParts.job_parts.data.length) {
            $scope.job_has_parts = true;
        }
    }
    $rootScope.$on("smart_table:milestoneUpdate", function (event, val) {
        if(val[categories[$scope.current_part_status]] && val[categories[$scope.current_part_status]].statusCount){
            $scope.statusCount = val[categories[$scope.current_part_status]].statusCount;
        }
        if(val[$scope.partCategoryBatch] && val[$scope.partCategoryBatch].data){
            if(val[$scope.partCategoryBatch].data.length){
                $scope.job_has_parts = true;
            }else{
                $scope.job_has_parts = false;
            }
        }
    });
}

function BatchAvailablePartsChangeStatus ($scope, $rootScope, $state, $stateParams, $timeout, changeStatusDrop, $http, prefix, tableCollection) {

    $scope.clientJobType = $rootScope.clientJobType;
    $rootScope.$on('smart_table:pinned_rows_part_category', function(event, category) {
        $scope.category = category;
        $scope.jobId = tableCollection.getJobId(category);
    });

    $rootScope.$on('smart_table:pinned_rows_updated', function(event, rows_store) {
        $scope.chosenParts = rows_store;
        $scope.partStatus = typeof $scope.chosenParts[0] != 'undefined' ? $scope.chosenParts[0].part.partStatus : '';
        $scope.chosenSidePanelRecords = [];
        if($scope.chosenParts.length > 0){
            angular.forEach($scope.chosenParts , function (value, key){
                if(inKeyArray($scope.chosenParts[key].part.FulfillType, $scope.chosenSidePanelRecords, 'FulfillType')){
                    var index = getIndexOf($scope.chosenParts[key].part.FulfillType, $scope.chosenSidePanelRecords, 'FulfillType')
                    $scope.chosenSidePanelRecords[index].parts.push({'part': $scope.chosenParts[key].part.Part, 'partId': $scope.chosenParts[key].part.id, 'quantity' : $scope.chosenParts[key].part.Quantity, 'selectedQuantity': '', 'error': false, 'error_message': ''})
                }else{
                    $scope.chosenSidePanelRecords.push({'FulfillType': $scope.chosenParts[key].part.FulfillType, 'parts': [{'part': $scope.chosenParts[key].part.Part, 'partId': $scope.chosenParts[key].part.id, 'quantity' : $scope.chosenParts[key].part.Quantity, 'selectedQuantity': '', 'error': false, 'error_message': ''}], 'changeStatusValues' : [], 'selectedStatus' : ''});
                }
            });
        }
        for(var i = 0, l = $scope.chosenSidePanelRecords.length; i < l; i++) {
            var chosen_part = $scope.chosenSidePanelRecords[i];
            if (chosen_part.FulfillType == 'OrderFromSupplier') {
                var changeStatusValues = changeStatusDrop.getData($scope.category);
                $scope.chosenSidePanelRecords[i].changeStatusValues = _.omit(changeStatusValues, 'Cancelled');
            }
            else if (chosen_part.FulfillType == 'General') {
                var changeStatusValues = changeStatusDrop.getData($scope.category);
                $scope.chosenSidePanelRecords[i].changeStatusValues = _.omit(changeStatusValues, 'Returned');
                $scope.chosenSidePanelRecords[i].changeStatusValues = _.omit($scope.chosenSidePanelRecords[i].changeStatusValues, 'Returned_To_Stock');
            }else if (chosen_part.FulfillType == 'FromStock'){
                var changeStatusValues = changeStatusDrop.getData($scope.category);
                $scope.chosenSidePanelRecords[i].changeStatusValues = _.omit(changeStatusValues, 'Cancelled');
            }
        }
        $scope.checkIfAnyPartsAreInvalid();
    });

    $scope.saveBatchPartStatus = function saveBatchPartStatus(chosenSidePanelRecords){
        $scope.error = false;

        $scope.parts = [];
        angular.forEach(chosenSidePanelRecords, function (value, key){
            $scope.parts.push({'selectedStatus' : value.selectedStatus, 'parts': value.parts})
        });

        $http.post(prefix + '/available_part_batch_status_update', 'partStatus=' + $scope.partStatus + '&partsData=' + encodeURIComponent(angular.toJson($scope.parts))  + '&moduleId=' + $rootScope.clientJobType + '&jobtodos=' + $rootScope.clientJobTodos + '&jobId=' + $scope.jobId ).success(function (data) {
            $rootScope.$broadcast('parts:countupdated', data);
            $rootScope.$broadcast('smart_tablefilter:newsearch',{'category': $scope.category });
            $scope.selectedStatus = "";
        });
    }

    $scope.closePanel = function closePanel() {
        $rootScope.$broadcast('smart_table:unpin_all_pinned_rows');
        $rootScope.$broadcast('closeAllSidepanels');
    }

    $scope.initAvailableQuantity = function initAvailableQuantity(part) {
        part.error = false;
        part.selectedQuantity = parseFloat(part.quantity);
    }

    /*====================================================
     Validate the transfer options
     ====================================================*/
    $scope.validateQuantity = function validateQuantity(part, selectedStatus) {
        var input_is_number = !isNaN(parseFloat(part.selectedQuantity)),
            quantity_valid;

        if(input_is_number) {
            $scope.isQuantityInBounds(part, selectedStatus);
        } else {
            part.error = true;
            part.error_message = 'Please enter a valid quantity';
        }
        $scope.checkIfAnyPartsAreInvalid();
    }

    $scope.isQuantityInBounds = function isQuantityInBounds(part, selectedStatus) {
        if ((parseFloat(part.selectedQuantity) > parseFloat(part.quantity)) || parseFloat(part.selectedQuantity) < 0) {
            part.error = true;
            $scope.quantityError = true;

            if (parseFloat(part.selectedQuantity) < 0) {
                part.error_message = 'Quantity cannot be negative';
            }
            else if (parseFloat(part.selectedQuantity) == 0) {
                part.error_message = 'Please enter a quantity greater than zero';
            }
            else {
                if(selectedStatus =='Cancelled'){var status = 'cancel'}
                if(selectedStatus =='Returned' || selectedStatus == 'Returned_To_Stock'){var status = 'return'}
                part.error_message = 'There aren\'t enough parts to '+status+' this many';
            }
        } else {
            part.error = false;
            $scope.quantityError = false;
        }
        if (parseFloat(part.selectedQuantity) == 0 ){
            part.error = true;
            part.error_message = 'Please enter a quantity greater than zero';
            $scope.quantityError = true;
        }
    }

    $scope.checkIfAnyPartsAreInvalid = function checkIfAnyPartsAreInvalid() {
        $scope.error = false;
        for(var i = 0, l = $scope.chosenSidePanelRecords.length; i < l; i++) {
            var chosen_part = $scope.chosenSidePanelRecords[i].parts;
            for(var count = 0, pl = chosen_part.length; count < pl; count++) {
                if(chosen_part[count].error === true){
                    $scope.error = true;
                }
            }
        }
    }
    $scope.checkSelectedStatus = function checkSelectedStatus(selectedStatus, chosenParts) {
        for(var i = 0, l = chosenParts.length; i < l; i++) {
            chosenParts[i].selectedQuantity = chosenParts[i].quantity;
            chosenParts[i].error_message = undefined;
            chosenParts[i].error = false;
        }
        if(selectedStatus != '' && selectedStatus != undefined) {
            $scope.quantityError = false;
            $scope.error = false;
        }
    }

    $scope.IsSelectedStatusValid = function IsSelectedStatusValid(){
        if(typeof $scope.chosenSidePanelRecords != 'undefined'){
            for(var i = 0, l = $scope.chosenSidePanelRecords.length; i < l; i++) {
                if($scope.chosenSidePanelRecords[i].selectedStatus == undefined || $scope.chosenSidePanelRecords[i].selectedStatus == ''){
                    return false
                }
            }
        }
        return true;
    }
}

function inKeyArray(needle, haystack, key) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i][key] == needle) return true;
    }
    return false;
}

function getIndexOf(needle, haystack, key){
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i][key] == needle) return i;
    }
    return false;
}



