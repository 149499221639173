commusoftCommon.directive('listwidget', function($document, $timeout, confirmationBoxHelper, toastBox, $http, prefix) {
    var handleListWidget = function handleListWidget(scope, element, attrs) {

        scope.sortable_options = {
            update: function(e, ui) {},
            axis: 'x',
            containment: "parent"
        };

        var data = attrs.data,
            input_placeholder = attrs.inputPlaceholder,
            add_button_text = attrs.addButtonText,
            no_results_message = attrs.noResultsMessage,
            no_results_help_text = attrs.noResultsHelpText,
            supports_drag = attrs.supportsDrag,
            supports_edit = attrs.supportsEdit,
            supports_no_results_ui = attrs.supportsNoResultsUi,
            supports_no_results_help_text = attrs.supportsNoResultsHelpText,
            delete_confirmation_message = attrs.deleteConfirmationMessage,
            optionId = attrs.optionid,
            groupId = attrs.groupid;


        $http.get(prefix + '/listCertificatesFieldOptions?optionId=' + optionId + '&groupId=' + groupId).success(function (value,status){
            if(status == 200){
                scope.data = value.options;
                scope.loading = false;
            }
        })

        scope.loading = true;
        if (data) {
            scope.data = JSON.parse(attrs.data);
        } else {
            scope.data = [];
        }
        if (input_placeholder) {
            scope.input_placeholder = attrs.inputPlaceholder;
        } else {
            scope.input_placeholder = 'Add an item';
        }
        if (add_button_text) {
            scope.add_button_text = attrs.addButtonText;
        } else {
            scope.add_button_text = 'Add';
        }
        if (no_results_message) {
            scope.no_results_message = attrs.noResultsMessage;
        } else {
            scope.no_results_message = 'There are no items';
        }
        if (no_results_help_text) {
            scope.no_results_help_text = attrs.noResultsHelpText;
        } else {
            scope.no_results_help_text = 'You can add one here';
        }
        if (supports_edit) {
            scope.supports_edit = JSON.parse(attrs.supportsEdit);
        } else {
            scope.supports_edit = false;
        }
        if (supports_drag) {
            scope.supports_drag = JSON.parse(attrs.supportsDrag);
        } else {
            scope.supports_drag = false;
        }
        if (supports_no_results_ui) {
            scope.supports_no_results_ui = JSON.parse(attrs.supportsNoResultsUi);
        } else {
            scope.supports_no_results_ui = false;
        }
        if (supports_no_results_help_text) {
            scope.supports_no_results_help_text = JSON.parse(attrs.supportsNoResultsHelpText);
        } else {
            scope.supports_no_results_help_text = false;
        }
        if (delete_confirmation_message) {
            scope.delete_confirmation_message = attrs.deleteConfirmationMessage;
        } else {
            scope.delete_confirmation_message = 'Item deleted';
        }


        scope.handleAddingItem = function handleAddingItem(new_item_title) {
            if(new_item_title == ''){
                return false;
            }
            scope.new_item = '';
            var item_already_exists = scope.checkIfItemAlreadyExists(new_item_title),
                confirmation_message = 'This item already exists, are you sure you wish to add it again?';

            if (!item_already_exists) {
                scope.addItem(new_item_title,optionId, groupId);
            } else {
                confirmationBoxHelper.getConfirmation(confirmation_message, this)
                    .then(function() {
                        scope.addItem(new_item_title,optionId, groupId);
                    }, function() {
                        return false
                    });
            }
        }

        scope.addItem = function addItem(new_item_title, optionId, groupId) {
            $http.post(prefix + '/addDefaultCertificates?optionId=' + optionId + '&groupId=' + groupId + '&newOption=' + encodeURIComponent(new_item_title)).success(function (value,status){
                scope.loading = true;
                if(status == 200){
                    scope.data.push(value);
                    scope.flashItemUI(scope.data.length -1);
                    scope.loading = false;
                }
            })
            scope.new_item_title = null;
        }

        scope.handleDeleteItem = function handleDeleteItem(index_to_remove, id) {
            var deleted_item = angular.copy(scope.data[index_to_remove]),
                delete_message = "Deleted " + deleted_item.option.toLowerCase();

            $http.post(prefix+ '/deleteDefaultCertificates?id=' + id).success(function(value,status){
                if(status == 200){
                    $http.get(prefix + '/listCertificatesFieldOptions?optionId=' + optionId + '&groupId=' + groupId).success(function (value,status){
                        if(status == 200){
                            scope.data = value.options;
                            scope.loading = false;
                        }
                    })
                    toastBox.show(delete_message, 2000, 0);
                }
            })
        }

        scope.handleEditItem = function handleEditItem(index_to_edit) {
            scope.editing = true;
            scope.data[index_to_edit].editing = true;
            scope.focusCurrentlyEditingInput();

        }

        scope.handleSaveEdit = function handleSaveEdit(index_to_save, Id) {
            var edited_item = angular.copy(scope.data[index_to_save]),
                edit_message = "Saved " + edited_item.option.toLowerCase();
            if(edited_item.option == ''){
                return false;
            }

            var item_already_exists = scope.checkIfItemAlreadyExists(edited_item.option, index_to_save);

            if (!item_already_exists) {
                scope.editing = false;
                scope.data[index_to_save].editing = false;
                $http.post(prefix + '/editDefaultCertificates?optionId=' + optionId + '&groupId=' + groupId + '&newOption=' + encodeURIComponent(edited_item.option) +'&id=' + Id).success(function (value,status){
                    if(status == 200){
                        toastBox.show(edit_message, 2000, 0);
                        scope.flashItemUI(index_to_save);
                    }
                })
            } else {
                var confirmation_message = 'This item already exists, are you sure you wish to add it again?';

                confirmationBoxHelper.getConfirmation(confirmation_message, this)
                    .then(function() {
                        scope.editing = false;
                        scope.data[index_to_save].editing = false;
                        $http.post(prefix + '/editDefaultCertificates?optionId=' + optionId + '&groupId=' + groupId + '&newOption=' + edited_item.option +'&id=' + Id).success(function (value,status){
                            if(status == 200){
                                toastBox.show(edit_message, 2000, 0);
                                scope.flashItemUI(index_to_save);
                            }
                        })
                    }, function() {
                        scope.focusCurrentlyEditingInput();
                        return false
                    });
            }
        }

        scope.checkIfItemAlreadyExists = function checkIfItemAlreadyExists(new_item_title, index_to_ignore) {
            // Index to ignore is used in edit cases, where we do not care if the edited item matches
            var items = scope.data.map(function(item) {
                return item.option.toLowerCase();
            });

            if (index_to_ignore !== undefined) {
                items.splice(index_to_ignore, 1);
            }

            var item_already_exists = items.indexOf(new_item_title.toLowerCase()) > -1;

            return item_already_exists;
        }

        scope.getIndexOfItem = function getIndexOfItem(item) {
            var index_of_item = scope.data.map(function(item) {
                return item.title;
            }).indexOf(item.title);

            return index_of_item;
        }

        scope.flashItemUI = function flashItemUI(index_to_flash) {
            scope.data[index_to_flash].added = true;
            scope.data[index_to_flash].with_highlight_transition = true;

            $timeout(function() {
                for(var i = 0, l = scope.data.length; i < l; i++) {
                    scope.data[i].added = false;
                }
            }, 50);
            $timeout(function() {
                for(var i = 0, l = scope.data.length; i < l; i++) {
                    scope.data[i].with_highlight_transition = false;
                }
            }, 1500);
        }

        scope.focusCurrentlyEditingInput = function focusCurrentlyEditingInput() {
            window.requestAnimationFrame(function() {
                var input = document.querySelector('.list-widget li input');

                input.focus();
                input.select();
            });
        }
    }

    return {
        restrict: 'A',
        templateUrl: '/template/settings/defaultCertificates/list_widget.html',
        scope: {},
        link: handleListWidget
    }
});