commusoftCommon.directive('iframedpdf',['$http', function($http) {
    return {
        restrict: 'E',
        link: function (scope, element, attrs) {
            var url = scope.$eval(attrs.src);

            const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (url.indexOf("?") === -1) {
                url = url + "?Timezone=" +browserTimezone;
            } else {
                url = url + "&Timezone=" +browserTimezone;
            }

            if (attrs.watermark) {
                url = url + "&watermark=1";
            }
            var id = attrs.id;
            if (id) {
                id = 'id="' + id + '"';
            } else {
                id = "";
            }

            element.replaceWith('<object ' + id + ' type="application/pdf" data="' + url + '"></object>');

        }
    }
}]);