'use strict';

/* Filters */

angular.module('commusoft.filters', []).
    filter('interpolate', ['version', function(version) {
        return function(text) {
            return String(text).replace(/\%VERSION\%/mg, version);
        }
    }]).
    filter('capitalize', function() {
        return function(input, scope) {
            if(!angular.isString(input)){
                return input;
            }
            if (input!=null) {
                return input.substring(0,1).toUpperCase()+input.substring(1);
            }
        }
    }).
    filter('dateFormat', function() {
        return function(dateString) {
            return moment(dateString).format('ddd Do MMM YYYY [at] HH:mm')
        };
    }).
    filter('dateFormatWithoutSec', function() {
        return function(dateString) {
            return moment.utc(dateString).zone(moment().format('Z')).format('ddd Do MMMM YYYY')
        };
    }).
    filter('nextServiceDate', function() {
        return function(dateString) {
            return moment.utc(dateString).zone(moment().format('Z')).format('Do MMMM YYYY')
        };
    }).
    filter('dateFormatShortDate', function() {
        return function(dateString) {
            if(dateString == '' ){
                return '';
            }
            //Note: Changing this filter will affect all over the application - Santha
            //return moment.utc(dateString).zone(moment().format('Z')).format('L');

            //when convert a datestring(10/04/2016 00:00:00) to utc,the format of L ignores the HH:mm:ss so its consider(00:00:00) which means midnight. the utc behind +1 so its -1 from midnight((09/04/2016 23:00:00)) obviously that is previous day)
            return moment(dateString).format('L');
        };
    }).filter('supplierContactDateFormat', function() {
        return function(dateString) {
            return moment(dateString).format('YYYY-MM-DD')
        };
    }).filter('timeWithDateFormat', function() {
        return function(dateString) {
            if(dateString == ''){
                return '';
            }
            return moment.utc(dateString).zone(moment().format('Z')).format('ddd Do MMM YYYY [at] HH:mm a')
        };
    }).filter('timeFormat', function() {
        return function(dateString) {
            if(dateString == '' || dateString == null){
                return '';
            }
            //check for diff zone uk for default +01:00
            return moment.utc(dateString).zone(moment().format('Z')).format('hh:mm A')
        };
    }).filter('dateNewFormat', function() {
        return function(dateString) {
            if(moment().isSame(dateString, 'day')){
                return 'Today';
            }else if(moment().subtract('days', 1).format('YYYY-MM-DD') == moment(dateString).format('YYYY-MM-DD')){
                return 'Yesterday'
            }else{
                return moment(dateString).format('ddd Do MMMM YYYY')
            }
        };
    }).filter('userReminderDateFormat', function() {
        return function(dateString) {
            if (dateString == '' || dateString == undefined) {
                return '';
            }
            return moment(dateString).format('ddd Do MMMM YYYY')
        };
    }).filter('fullTimeFormat', function () {
        return function (dateString) {
            if (dateString == '') {
                return '';
            }
            //check for diff zone uk for default +01:00
            return moment.utc(dateString).zone(moment().format('Z')).format('HH:mm')
        };
    }).filter('fullTimeFormatWithoutZone', function () {
        return function (dateString) {
            if (dateString == '') {
                return '';
            }
            //check for diff zone uk for default +01:00
            return moment(dateString).format('HH:mm')
        };
    }).filter('normalDateFormat', function () {
        return function (dateString) {
            if (dateString == '') {
                return '';
            }
            if(navigator.languages[0] === 'en-GB') {
                return moment(dateString).format('DD/MM/YY');
            }else if(navigator.languages[0] === 'en-US'){
                return moment(dateString).format('MM/DD/YY');
            }else{
                return moment(dateString).format('DD/MM/YY')
            }

        };
    }).filter('normalDateFormatWithFullYear', function () {
        return function (dateString) {
            if (dateString == '') {
                return '';
            }
            if(navigator.languages[0] === 'en-GB') {
                return moment(dateString).format('DD/MM/YYYY');
            }else if(navigator.languages[0] === 'en-US'){
                return moment(dateString).format('MM/DD/YYYY');
            }else{
                return moment(dateString).format('DD/MM/YYYY')
            }


        };
    }).filter('DateFormatLine', function () {
        return function (dateString) {
            if (dateString == '') {
                return '';
            }
            if(navigator.languages[0] === 'en-GB') {
                return moment(dateString).format('DD/MM/YYYY');
            }else if(navigator.languages[0] === 'en-US'){
                return moment(dateString).format('MM/DD/YYYY');
            }else{
                return moment(dateString).format('DD/MM/YYYY')
            }

        };
    }).filter('dateTimeLine', function() {
        return function(dateString) {
            if(moment().isSame(dateString, 'day')){
                return 'Today';
            }else{
                return moment(dateString).format('ddd Do MMMM YYYY')
            }
        };
    }).filter('unSortJSON', function() {
        return function(input) {
            if (!input) {
                return [];
            }
            return Object.keys(input);
        }
    }).filter('removeSpacesThenCapitalize', function () {
        return function (text) {
            var str = text.replace(/[^a-zA-Z0-9]/g,' ');
            return str.substring(0,1).toUpperCase()+str.substring(1);
        };
    }).filter('nospace', function () {
        return function (input) {
            var string  = input.replace(' ', '_');
            return string;
        };
    }).filter('customCurrency', ["$filter", function ($filter) {
        return function (amount, currencySymbol) {
            var currency = $filter('currency');

            if (amount < 0) {
                return currency(amount, currencySymbol).replace("(", "-").replace(")", "");
            }

            return currency(amount, currencySymbol);
        };
    }]).filter('limitHtml', ["$filter", function ($filter) {
        return function(text, limit) {
            var changedString = String(text).replace(/<[^>]+>/gm, '');
            var length = changedString.length;

            return changedString.length > limit ? changedString.substr(0, limit - 1) + ' ...' : changedString;
        };
    }]).filter('twoDecimalPlaces', function() {
        return function(input, scope) {
            var number_needs_rounding = input % 1 !== 0;

            if(number_needs_rounding) {
                return input.toFixed(2);
            }else {
                return input;
            }
        }
    }).filter('TimeDateFormatWithSec', function() {
        return function (dateString) {
            //check for diff zone uk for default +01:00
            return moment(dateString).format('Do MMM YYYY [at] HH:mm a')
        };
    }).filter('dayMonYear', function () {
        return function (dateString) {
            if (dateString == '' || dateString == undefined) {
                return '';
            }
            return moment(dateString).format('ddd Do MMM YYYY')
        };
    }).filter('highlight', ['$sce', function ($sce) {
        return function (input, searchParam) {
            if (!input) return '';
            if (typeof input === 'function') return '';
            if (searchParam) {
                var words = '(' +
                        searchParam.split(/\ /).join(' |') + '|' +
                        searchParam.split(/\ /).join('|') +
                        ')',
                    exp = new RegExp(words, 'gi');
                if (words.length) {
                    input = input.replace(exp, "<span class=\"highlight\">$1</span>");
                }
            }
            return $sce.trustAsHtml(input);
        };
    }]).filter('dateShortFormat', function() {
        return function(dateString) {
            if(dateString == ''){
                return '';
            }
            return moment.utc(dateString).zone(moment().format('Z')).format('ddd Do MMM YYYY');
        };
    }).filter('characters', function () {
        return function (input, chars, breakOnWord) {
            if (isNaN(chars)) return input;
            if (chars <= 0) return '';
            if (input && input.length > chars) {
                input = input.substring(0, chars);

                if (!breakOnWord) {
                    var lastspace = input.lastIndexOf(' ');
                    //get last space
                    if (lastspace !== -1) {
                        input = input.substr(0, lastspace);
                    }
                }else{
                    while(input.charAt(input.length-1) === ' '){
                        input = input.substr(0, input.length -1);
                    }
                }
                return input + '...';
            }
            return input;
        };
    }).filter('diaryEventTimeFormat', function () {
        return function (dateString) {
            if (dateString == '') {
                return '';
            }
            //check for diff zone uk for default +01:00
            return moment(dateString).format('hh:mm a')
        };
    }). filter('nl2br', function(){
        return function(text) {
            return text ? text.replace(/\n/g, '<br>') : '';
        };
    }).filter('fullDateTimeFormat', function() {
        return function(dateString) {
            if(dateString == ''){
                return '';
            }
            return moment.utc(dateString).zone(moment().format('Z')).format('dddd Do MMMM YYYY [at] HH:mma')
        };
    }).filter('expiryDate', function () {
        return function (dateString) {
            if (dateString == '') {
                return '';
            }
            return moment(dateString).format('MM/YY')
        };
    }).filter('isArray', function() {
        return function (input) {
            return angular.isArray(input);
        };
    }).filter('compressCommas', function() {
        return function (value) {
            var find = ', ,';
            while(value.indexOf(find) != -1) {
                value = value.replace(new RegExp(find, 'g'), ',');
            }
            return value;
        };
    }).filter('dynamicDecimalWithCurrency', ["$filter", "$locale", "$rootScope", function ($filter, $locale, $rootScope) {
        var formats = $locale.NUMBER_FORMATS;
        return function (amount, currencySymbol, fractionSize) {

            // Didn't get rootscope value in sidepanel so handled it here
            if(typeof currencySymbol == 'undefined'){
                currencySymbol = $rootScope.CustomCurrency;
            }
           // var currency = $filter('currency');
            var numberFilter = $filter('number');
            // Format the value with our chosen fraction size
            var fraction = numberFilter(amount, fractionSize);
            //Split after the decimal point
            var fractionSplit = fraction.split('.');
            // In decimal four digit number having [00.0000] last two digit as zero, then only display like [00.00]
            var formatFraction = fraction;
            if((typeof fractionSplit[1] != 'undefined') && (fractionSplit[1].length == 4)) {
                formatFraction = fraction.replace(/[0][0]$/,'');
            }
            //var newValue = currency(amount, currencySymbol).replace(/[0-9]+.*[0-9]+/, fraction);
            // Merge the currency symbol to value.
            var finalValue = currencySymbol + formatFraction;
            return finalValue;
        };
    }]).filter('dateTimeLineFromNow', function () {
        return function (dateString) {
            return moment(dateString).fromNow(true);
        };
    })
    // Unit Converters
    .filter('localLength', function ($rootScope) {
        /*
            Converts length into local unit (km/mi)

            @param number value
            @param string sourceUnit --valid units={m|km|mi}
            @param boolean showUnit
            @return float
        */
        var unit = $rootScope.localStandards.length;

        return function (value, sourceUnit, showUnit) {
            if(!sourceUnit) sourceUnit = 'm';
            value = parseFloat(value);

            var meters = 0, converted = 0;
            switch (sourceUnit) {
                case "mi": meters = value * 1.60934 * 1000; break; // miles to meters
                case "km": meters = value * 1000; break; // kilometers to meters
                default: meters = value; break;
            }

            switch (unit) {
                case "mi": converted = meters / (1.60934 * 1000); break; // meters to miles
                case "km": converted = meters / 1000; break; // meters to kilometers
                default: converted = meters; break;
            }

            return showUnit ? converted.toFixed(2) + ' ' + unit : converted.toFixed(2);
        }
    }).filter('localSpeed', function ($rootScope) {
        /*
            Converts speed units into local units (knots,m/s,km/h,mph)

            @param number value
            @param string sourceUnit  --valid units={knots|m/s|km/h|mph}
            @param boolean showUnit
            @return float
        */
        var unit = $rootScope.localStandards.speed;

        return function (value, sourceUnit, showUnit) {
            if(!sourceUnit) sourceUnit = 'kn';
            value = parseFloat(value);

            var meterPerSec = 0, converted = 0;
            switch (sourceUnit) {
                case "kn": meterPerSec = value * (1.852 * 1000 / 3600); break; // knots to meter/sec
                case "mph": meterPerSec = value * (1.60934 * 1000 / 3600); break; // miles/hour to meter/sec
                case "km/h": meterPerSec = value * (1000 / 3600); break; // kilometer/hour to meter/sec
                default: meterPerSec = value; break;
            }

            switch (unit) {
                case "kn": converted = meterPerSec / (1.852 * 1000 / 3600); break; // meter/sec to knots
                case "mph": converted = meterPerSec / (1.60934 * 1000 / 3600); break; // meter/sec to miles/hour
                case "km/h": converted = meterPerSec / (1000 / 3600); break; // meter/sec to kilometer/hour
                default: converted = meterPerSec; break;
            }

            return showUnit ? converted.toFixed(2) + ' ' + unit : converted.toFixed(2);
        }
    }).filter('localVolume', function ($rootScope) {
        /*
            Converts volume into local volume (liters, gallons)

            @param number value
            @param string sourceUnit --valid units={l|gal}
            @param boolean showUnit
            @return float
        */
        var unit = $rootScope.localStandards.volume;

        return function (value, sourceUnit, showUnit) {
            if(!sourceUnit) sourceUnit = 'l';
            value = parseFloat(value);

            var ml = 0, converted = 0;
            switch (sourceUnit) {
                case "gal": ml = value / (3.78541 * 1000); break; // liters to gallons
                default: ml = value * 1000; break;
            }

            switch (unit) {
                case "gal": converted = ml * (3.78541 * 1000); break; // gallons to liters
                default: converted = ml * 1000; break;
            }

            return showUnit ? converted.toFixed(2) + ' ' + unit : converted.toFixed(2);
        }
    }).filter('millisecToHms', function () {
        /*
            Converts milliseconds into combined Hours, Minutes & Seconds

            @param number milliseconds
            @param null format --valid units={null|named}
            @return string
        */

        return function (milliseconds, format) {
            var sigs = { "H": 0, "M": 0, "S": 0 };
            milliseconds = parseInt(milliseconds);

            sigs['S'] = Math.floor(milliseconds / 1000);
            sigs['M'] = Math.floor(sigs['S'] / 60);
            sigs['H'] = Math.floor(sigs['M'] / 60);
            sigs['S'] = sigs['S'] % 60;
            sigs['M'] = sigs['M'] % 60;

            var hms = '';

            function lPad(v, p) {
                v.toString().padStart(p, 0).slice(-p);
            }

            switch (format) {
                case "named": {
                    if (sigs['H'] > 0) hms += sigs['H'] + ' h ';
                    if (sigs['M'] > 0) hms += sigs['M'] + ' m ';
                    if (sigs['S'] > 0) hms += sigs['S'] + ' s ';

                    if (hms === '') hms = '0 h 0 m';
                    break;
                }
                default: {
                    hms = lPad(sigs['H'], 2) + ':' + lPad(sigs['M'], 2) + ':' + lPad(sigs['S'], 2);
                    break;
                }
            }

            return hms;
        }
    }).filter('appendLocalUnit', function ($rootScope) {
        /*
            Appends local unit of the value

            @param number|string value
            @param string unitOf --valid units={length|speed|volume}
            @return string
        */


        return function (value, unitOf) {
            var unit = $rootScope.localStandards[unitOf];

            return value + ' ' + unit;
        }
    }).filter('dateSuffix', function ($filter) {
    var suffixes = ["th", "st", "nd", "rd"];
    return function (input, format) {
        input = new Date(input)
        var dtfilter = $filter('date')(input, format);
        var day = parseInt($filter('date')(input, 'dd'));
        var relevantDigits = (day < 30) ? day % 20 : day % 30;
        var suffix = (relevantDigits <= 3) ? suffixes[relevantDigits] : suffixes[0];
        return dtfilter.replace('oo', suffix);
        //return dtfilter+suffix;
    };
    }).filter('angleToDirections', function () {
        return function (value) {
            var angle = value
            var directions = 8;

            var degree = 360 / directions;
            angle = angle + degree / 2;

            if (angle >= 0 && angle < degree)
                return "N";
            if (angle >= degree && angle < 2 * degree)
                return "NE";
            if (angle >= 2 * degree && angle < 3 * degree)
                return "E";
            if (angle >= 3 * degree && angle < 4 * degree)
                return "SE";
            if (angle >= 4 * degree && angle < 5 * degree)
                return "S";
            if (angle >= 5 * degree && angle < 6 * degree)
                return "SW";
            if (angle >= 6 * degree && angle < 7 * degree)
                return "W";
            if (angle >= 7 * degree && angle < 8 * degree)
                return "NW";
            //Should never happen:
            return "N";
        }
    });