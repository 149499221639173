commusoftCommon.factory('scriptLoader', function($q, externalJs){

  var loadedPromises={};

  function loadScript(url) {
    var script = document.querySelector("script[src*='" + url + "']");
    if(!script) {
      var heads = document.getElementsByTagName("head");
      if (heads && heads.length) {
        var head = heads[0];
        if (head) {
          script = document.createElement('script');
          script.setAttribute('src', url);
          script.setAttribute('type', 'text/javascript');
          head.appendChild(script);
        }
      }
    }
    return script;
  }

  function loadPromise() {
    _.forEach(externalJs, function(scriptUrl, name) {
      var deferred = $q.defer()
      if(loadScript(scriptUrl)) {
        deferred.resolve(document.querySelector("script[src*='" + scriptUrl + "']") ? true : false);
      }
      else {
        deferred.reject(false);
      }
      loadedPromises[name] = deferred.promise;
    });
    return loadedPromises;
  }

  return {
    load: loadPromise
  };
});