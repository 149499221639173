csmodule.controller('filterUsersOnDiary', ['$scope', '$rootScope', '$filter', '$timeout', '$diaryMapScope', function($scope, $rootScope, $filter, $timeout, $diaryMapScope) {
  $scope.sortableListOfUsers = {
    stop: function(e, ui) {
      //$scope.handleChangeToUsersFilter();
    }
  }

    $scope.userSelectionType = 'MULTIPLE';
    $scope.selectedUserId = null;
    if ($scope.panel_content.diary_view === 'diaryMap' && $diaryMapScope.mapTimeBounds.mode === 'BETWEEN_HOURS')  {
        $scope.userSelectionType = 'SINGLE';
        $scope.selectedUserId = $diaryMapScope.mapTimeBounds.userToShowOnHistoricalView;
    }

    $scope.handleChangeToUsersFilter = function handleChangeToUsersFilter() {
        if ($scope.userSelectionType === 'MULTIPLE') {

            for (var i = 0, l = $scope.panel_content.users.length; i < l; i++) {
                $scope.panel_content.users[i].checked = true;
                if ($scope.panel_content.users[i].shown_on_diary == false) {
                    $scope.panel_content.users[i].checked = false;
                }
            }
            $rootScope.$emit('users_on_diary:updated', $scope.panel_content.users);
            $scope.number_of_users_on_diary = $scope.getCurrentNumberOfUsers();
        } else {
            $diaryMapScope.setMapTimeBounds(Object.assign($diaryMapScope.mapTimeBounds, {userToShowOnHistoricalView: $scope.selectedUserId}));
        }
    }

  $scope.handleUserSearchQuery = function handleUserSearchQuery() {
    var result_set = $filter('filter')(this.panel_content.users, this.userSearchQuery),
        not_found = result_set.length < 1;

    not_found ? $scope.show_no_results_ui = true : $scope.show_no_results_ui = false;

    $scope.number_of_users_on_diary = $scope.getCurrentNumberOfUsers();
    if($scope.number_of_users_on_diary == $scope.panel_content.users.length) {
      $scope.selectedCheckbox= true;
      $scope.toggle_message = 'Deselect All Users';
    } else {
      $scope.selectedCheckbox = false;
      $scope.toggle_message = 'Select All Users';
    }
  }

  $scope.getCurrentNumberOfUsers = function() {
    var number_of_users = _.where($scope.panel_content.users, {
      shown_on_diary: true
    }).length;

    return number_of_users;
  }

  $scope.closeFilterUsersOnDiaryPanel = function closeFilterUsersOnDiaryPanel() {
    $scope.$destroy();
    $rootScope.$broadcast('sidepanel:close');
  }

  $scope.saveFilterUsersOnDiaryPanel = function saveFilterUsersOnDiaryPanel() {
    $scope.handleChangeToUsersFilter();
    $scope.closeFilterUsersOnDiaryPanel();
  }

  $scope.toggle_message = 'Select All Users';

  $scope.checkIfOnlyOneUserSelected = function() {
    $scope.only_one_user_selected = $scope.getCurrentNumberOfUsers() === 1;
    $scope.number_of_users_on_diary = $scope.getCurrentNumberOfUsers();
    if($scope.number_of_users_on_diary == $scope.panel_content.users.length) {
      $scope.selectedCheckbox= true;
      $scope.toggle_message = 'Deselect All Users';
    } else {
      $scope.selectedCheckbox = false;
      $scope.toggle_message = 'Select All Users';
    }
  }

  $scope.selectDeselect = function selectDeselect(toggle_message) {
    if(toggle_message == 'Select All Users') {
      for(var i = 0, l = $scope.panel_content.users.length; i < l; i++) {
        $scope.panel_content.users[i].checked = true;
        $scope.panel_content.users[i].shown_on_diary = true;
      }
      $scope.toggle_message = 'Deselect All Users';
      $scope.checkboxValue = true;
      $scope.selectedCheckbox= true;
    } else {
      for(var i = 0, l = $scope.panel_content.users.length; i < l; i++) {
        if (i == 0) {
          $scope.panel_content.users[i].checked = true;
          $scope.panel_content.users[i].shown_on_diary = true;
        } else {
          $scope.panel_content.users[i].checked = false;
          $scope.panel_content.users[i].shown_on_diary = false;
        }
      }
      $scope.checkboxValue = false;
      $scope.selectedCheckbox= false;
      $scope.toggle_message = 'Select All Users';
    }
    $scope.only_one_user_selected = $scope.getCurrentNumberOfUsers() === 1;
  }

  $scope.show_no_results_ui = false;
  $scope.checkIfOnlyOneUserSelected();
  $scope.number_of_users_on_diary = $scope.getCurrentNumberOfUsers();


    /************************ For map usage ******************************* */
    $scope.foreignWatchers = {};
    $scope.panel_content.users_gps_data = {};

    var gpsDataTimer;
    $scope.foreignWatchers['diaryMapUserGpsDataSubscription'] = $rootScope.$on('dmUser:lastIdentifiedInfo',
        function (event, details) {

            if(gpsDataTimer) clearTimeout(gpsDataTimer);
            updateUsersGpsData(details);
        });

    function updateUsersGpsData(details) {
        if (!details instanceof Object) details = {};

        $timeout(function () {
            if ($scope.panel_content instanceof Object)
                $scope.panel_content.users_gps_data = details;
            else $scope.panel_content = {users_gps_data: details}
        });


        gpsDataTimer = setTimeout(function () {
            updateUsersGpsData(details);
        }, 60000);
    }

    // Centering map on user position
    $scope.pointUserOnMap = function (e, userId) {
        e.preventDefault();
        e.stopPropagation();

        $rootScope.$emit('dmUser:recenter', userId);
        $scope.closeFilterUsersOnDiaryPanel();
    };

    // Defines the class for coloring the user gps time info
    $scope.getLptColorClass = function (datetime) {
        var duration = moment.duration(moment().diff(datetime));
        var minutes = duration.asMinutes();

        if (minutes <= 19) return 'green';
        else if (19 < minutes && minutes <= 60) return 'yellow';
        else return 'red';
    };

    $scope.handleUserRadioClick = function(user){
        $scope.selectedUserId = user.id;
    };

    // Removes events
    $scope.$on('$destroy', function () {

        Object.keys($scope.foreignWatchers).forEach(function (n) {
            $scope.foreignWatchers[n]();
        });

        if(gpsDataTimer) clearTimeout(gpsDataTimer);
    });

    $scope.checkedNumberOfUsers = function () {
        return _.where($scope.panel_content.users, {
            checked: true
        }).length;
    };

    // Initialization
    $scope.init = function init() {};

}]).controller('filterSkillsOnDiary', ['$scope', '$rootScope', '$filter', function($scope, $rootScope, $filter) {

  $scope.availableUsers = [];
  $scope.isFormTouched = 0;
  $scope.handleChangeToUsersFilter = function handleChangeToUsersFilter() {
    var selectedSkills = _.where($scope.panel_content.skills_data, { shown_on_diary: true });
    var users = $scope.panel_content.users;
    for(var i = 0, l = users.length; i < l; i++) {
      users[i].checked = true;
      users[i].shown_on_diary = true;
      if($scope.availableUsers.indexOf(users[i].id) == -1) {
        users[i].checked = false;
        users[i].shown_on_diary = false;
      }
    }

    angular.forEach($scope.panel_content.skills_data, function (value, key) {
      if(value.shown_on_diary) {
        value.checked = true;
      } else {
        value.checked = false;
      }
    });

    var data = { users:  users, skills: $scope.panel_content.skills_data, selectedSkills: selectedSkills };
    $rootScope.$emit('skills_on_diary:updated', data);
    $scope.number_of_users_on_diary = $scope.getCurrentNumberOfUsers();
  }

  $scope.handleUserSearchQuery = function handleUserSearchQuery() {
    var result_set = $filter('filter')(this.panel_content.skills_data, this.userSearchQuery),
        not_found = result_set.length < 1;

    not_found ? $scope.show_no_results_ui = true : $scope.show_no_results_ui = false;

    $scope.number_of_users_on_diary = $scope.getCurrentNumberOfUsers();
    if($scope.number_of_users_on_diary == $scope.panel_content.skills_data.length) {
      $scope.selectedCheckbox= true;
      $scope.toggle_message = 'Deselect All Skills';
    } else {
      $scope.selectedCheckbox = false;
      $scope.toggle_message = 'Select All Skills';
    }
  }

  $scope.getCurrentNumberOfUsers = function() {
    var number_of_users = _.where($scope.panel_content.skills_data, {
      shown_on_diary: true
    }).length;

    return number_of_users;
  }

  $scope.closeFilterUsersOnDiaryPanel = function closeFilterUsersOnDiaryPanel() {
    $rootScope.$broadcast('sidepanel:close');
  }

  $scope.saveFilterUsersOnDiaryPanel = function saveFilterUsersOnDiaryPanel() {
    $scope.handleChangeToUsersFilter();
    $scope.closeFilterUsersOnDiaryPanel();
  }

  $scope.toggle_message = 'Select All Skills';

  $scope.checkIfOnlyOneUserSelected = function() {
    $scope.isFormTouched ++;
    $scope.only_one_user_selected = $scope.getCurrentNumberOfUsers() === 1;
    $scope.number_of_users_on_diary = $scope.getCurrentNumberOfUsers();
    if($scope.number_of_users_on_diary == $scope.panel_content.skills_data.length) {
      $scope.selectedCheckbox= true;
      $scope.toggle_message = 'Deselect All Skills';
    } else {
      $scope.selectedCheckbox = false;
      $scope.toggle_message = 'Select All Skills';
    }
    $scope.checkForUsers();
  }

  $scope.checkForUsers = function() {
    var selectedSkills = _.where($scope.panel_content.skills_data, { shown_on_diary: true });
    var users = $scope.panel_content.users;
    var usersWithSkills = [];
    for(var i = 0, l = users.length; i < l; i++) {
      var user = users[i];
      var userAssociatedSkill = user.skills;
      for(var index = 0, len = userAssociatedSkill.length; index < len; index++) {
        var tempSkill = userAssociatedSkill[index];
        var userSkill = _.where(selectedSkills, { id: tempSkill.id });
        if(userSkill && userSkill.length && usersWithSkills.indexOf(user.id) == -1) {
          usersWithSkills.push(user.id);
        }
      }
    }
    $scope.availableUsers = usersWithSkills;
  }

  $scope.selectDeselect = function selectDeselect(toggle_message) {
    if(toggle_message == 'Select All Skills') {
      for(var i = 0, l = $scope.panel_content.skills_data.length; i < l; i++) {
        $scope.panel_content.skills_data[i].checked = true;
        $scope.panel_content.skills_data[i].shown_on_diary = true;
      }
      $scope.toggle_message = 'Deselect All Skills';
      $scope.checkboxValue = true;
      $scope.selectedCheckbox= true;
    } else {
      for(var i = 0, l = $scope.panel_content.skills_data.length; i < l; i++) {
        $scope.panel_content.skills_data[i].checked = false;
        $scope.panel_content.skills_data[i].shown_on_diary = false;
      }
      $scope.checkboxValue = false;
      $scope.selectedCheckbox= false;
      $scope.toggle_message = 'Select All Skills';
      $scope.isFormTouched ++;
    }
    $scope.only_one_user_selected = $scope.getCurrentNumberOfUsers() === 1;
    $scope.checkForUsers();
  }

  $scope.show_no_results_ui = false;
  $scope.checkIfOnlyOneUserSelected();
  $scope.number_of_users_on_diary = $scope.getCurrentNumberOfUsers();
}]);