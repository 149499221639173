commusoftCommon.service('colorSelector', function() {
    var branding_colors = [
        {
            color: 'cs-blue',
            rgba: 'rgba(0,183,201,1)'
        },
        {
            color: 'black',
            rgba: 'rgba(0,0,0,1)'
        },
        {
            color: 'gray',
            rgba: 'rgba(100,100,100,1)'
        },
        {
            color: 'white',
            rgba: 'rgba(255,255,255,1)'
        },
        {
            color: 'light-blue',
            rgba: 'rgba(112,178,177,1)'
        },
        {
            color: 'pale-blue',
            rgba: 'rgba(112,178,177,1)'
        },
        {
            color: 'lighter-blue',
            rgba: 'rgba(115,114,224,1)'
        },
        {
            color: 'true-blue',
            rgba: 'rgba(83,119,204,1)'
        },
        {
            color: 'dark-purple',
            rgba: 'rgba(148,148,185,1)'
        },
        {
            color: 'lightest-pink',
            rgba: 'rgba(255,225,248,1)'
        },
        {
            color: 'pink',
            rgba: 'rgba(255,205,244,1)'
        },
        {
            color: 'pale-purple',
            rgba: 'rgba(206,176,200,1)'
        },
        {
            color: 'purple',
            rgba: 'rgba(178,130,168,1)'
        },
        {
            color: 'light-yellow',
            rgba: 'rgba(254,243,162,1)'
        },
        {
            color: 'yellow',
            rgba: 'rgba(255,240,124,1)'
        },
        {
            color: 'dark-yellow',
            rgba: 'rgba(245,225,77,1)'
        },
        {
            color: 'lightest-orange',
            rgba: 'rgba(254,200,158,1)'
        },
        {
            color: 'light-orange',
            rgba: 'rgba(255,165,96,1)'
        },
        {
            color: 'dark-orange',
            rgba: 'rgba(255,136,96,1)'
        },
        {
            color: 'red',
            rgba: 'rgba(255,101,93,1)'
        },
        {
            color: 'dark-pink',
            rgba: 'rgba(211,133,133,1)'
        },
        {
            color: 'light-brown',
            rgba: 'rgba(204,169,157,1)'
        },
        {
            color: 'brown',
            rgba: 'rgba(172,114,94,1)'
        },
        {
            color: 'light-green',
            rgba: 'rgba(139,255,136,1)'
        },
        {
            color: 'medium-green',
            rgba: 'rgba(109,235,99,1)'
        },
        {
            color: 'dark-green',
            rgba: 'rgba(75,189,66,1)'
        },
        {
            color: 'sludge-green',
            rgba: 'rgba(144,180,141,1)'
        },
        {
            color: 'grey',
            rgba: 'rgba(203,203,203,1)'
        },
        {
            color: 'grey-medium',
            rgba: 'rgba(178,178,178,1)'
        }
    ];

    // var diary_colors = [
    //     {
    //         color: 'cs-event-colour-1',
    //         rgba: 'rgba(236,236,255,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-2',
    //         rgba: 'rgba(112,178,177,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-3',
    //         rgba: 'rgba(255,236,249,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-4',
    //         rgba: 'rgba(255,236,236,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-5',
    //         rgba: 'rgba(255,249,236,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-6',
    //         rgba: 'rgba(249,255,236,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-7',
    //         rgba: 'rgba(237,255,237,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-8',
    //         rgba: 'rgba(236,255,249,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-9',
    //         rgba: 'rgba(238,244,247,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-10',
    //         rgba: 'rgba(238,238,247,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-11',
    //         rgba: 'rgba(244,238,247,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-12',
    //         rgba: 'rgba(247,238,244,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-13',
    //         rgba: 'rgba(247,238,238,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-14',
    //         rgba: 'rgba(245,233,208,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-15',
    //         rgba: 'rgba(232,245,207,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-16',
    //         rgba: 'rgba(207,245,208,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-17',
    //         rgba: 'rgba(219,246,237,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-18',
    //         rgba: 'rgba(226,240,247,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-19',
    //         rgba: 'rgba(233,226,215,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-20',
    //         rgba: 'rgba(222,228,206,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-21',
    //         rgba: 'rgba(207,228,206,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-22',
    //         rgba: 'rgba(217,234,227,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-23',
    //         rgba: 'rgba(237,251,255,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-24',
    //         rgba: 'rgba(236,251,255,1)'
    //     },
    //     {
    //         color: 'cs-event-colour-25',
    //         rgba: 'rgba(223,227,244,1)'
    //     }
    // ];

    var diary_colors = [
        {
            "color": "cs-event-colour-1",
            "rgba": "rgb(151, 193, 231)",
            "rgba_dark": "rgb(90, 155, 216)"
        },
        {
            "color": "cs-event-colour-2",
            "rgba": "rgb(231, 189, 151)",
            "rgba_dark": "rgb(216, 151, 90)"
        },
        {
            "color": "cs-event-colour-3",
            "rgba": "rgb(231, 151, 193)",
            "rgba_dark": "rgb(216, 90, 155)"
        },
        {
            "color": "cs-event-colour-4",
            "rgba": "rgb(189, 151, 231)",
            "rgba_dark": "rgb(151, 90, 216)"
        },
        {
            "color": "cs-event-colour-5",
            "rgba": "rgb(193, 231, 151)",
            "rgba_dark": "rgb(155, 216, 90)"
        },
        {
            "color": "cs-event-colour-6",
            "rgba": "rgb(201, 223, 242)",
            "rgba_dark": "rgb(152, 194, 230)"
        },
        {
            "color": "cs-event-colour-7",
            "rgba": "rgb(242, 221, 201)",
            "rgba_dark": "rgb(230, 190, 152)"
        },
        {
            "color": "cs-event-colour-8",
            "rgba": "rgb(242, 201, 223)",
            "rgba_dark": "rgb(230, 152, 194)"
        },
        {
            "color": "cs-event-colour-9",
            "rgba": "rgb(221, 201, 242)",
            "rgba_dark": "rgb(190, 152, 230)"
        },
        {
            "color": "cs-event-colour-10",
            "rgba": "rgb(223, 242, 201)",
            "rgba_dark": "rgb(194, 230, 152)"
        },
        {
            "color": "cs-event-colour-11",
            "rgba": "rgb(184, 213, 239)",
            "rgba_dark": "rgb(130, 182, 227)"
        },
        {
            "color": "cs-event-colour-12",
            "rgba": "rgb(239, 210, 184)",
            "rgba_dark": "rgb(227, 175, 130)"
        },
        {
            "color": "cs-event-colour-13",
            "rgba": "rgb(239, 184, 213)",
            "rgba_dark": "rgb(227, 130, 182)"
        },
        {
            "color": "cs-event-colour-14",
            "rgba": "rgb(210, 184, 239)",
            "rgba_dark": "rgb(175, 130, 227)"
        },
        {
            "color": "cs-event-colour-15",
            "rgba": "rgb(213, 239, 184)",
            "rgba_dark": "rgb(182, 227, 130)"
        },
        {
            "color": "cs-event-colour-16",
            "rgba": "rgb(168, 203, 235)",
            "rgba_dark": "rgb(110, 168, 222)"
        },
        {
            "color": "cs-event-colour-17",
            "rgba": "rgb(235, 200, 168)",
            "rgba_dark": "rgb(222, 164, 110)"
        },
        {
            "color": "cs-event-colour-18",
            "rgba": "rgb(235, 168, 203)",
            "rgba_dark": "rgb(222, 110, 168)"
        },
        {
            "color": "cs-event-colour-19",
            "rgba": "rgb(200, 168, 235)",
            "rgba_dark": "rgb(164, 110, 222)"
        },
        {
            "color": "cs-event-colour-20",
            "rgba": "rgb(203, 235, 168)",
            "rgba_dark": "rgb(168, 222, 110)"
        },
        {
            "color": "cs-event-colour-21",
            "rgba": "rgb(217, 233, 246)",
            "rgba_dark": "rgb(172, 207, 236)"
        },
        {
            "color": "cs-event-colour-22",
            "rgba": "rgb(246, 231, 217)",
            "rgba_dark": "rgb(236, 203, 172)"
        },
        {
            "color": "cs-event-colour-23",
            "rgba": "rgb(246, 217, 233)",
            "rgba_dark": "rgb(236, 172, 207)"
        },
        {
            "color": "cs-event-colour-24",
            "rgba": "rgb(231, 217, 246)",
            "rgba_dark": "rgb(203, 172, 236)"
        },
        {
            "color": "cs-event-colour-25",
            "rgba": "rgb(233, 246, 217)",
            "rgba_dark": "rgb(207, 236, 172)"
        },
        {
            "color": "cs-event-colour-26",
            "rgba": "rgb(137, 174, 206)",
            "rgba_dark": "rgb(118, 149, 175)"
        },
        {
            "color": "cs-event-colour-27",
            "rgba": "rgb(207, 170, 139)",
            "rgba_dark": "rgb(180, 148, 121)"
        },
        {
            "color": "cs-event-colour-28",
            "rgba": "rgb(198, 132, 166)",
            "rgba_dark": "rgb(164, 112, 139)"
        },
        {
            "color": "cs-event-colour-29",
            "rgba": "rgb(190, 211, 230)",
            "rgba_dark": "rgb(154, 168, 181)"
        },
        {
            "color": "cs-event-colour-30",
            "rgba": "rgb(171, 140, 210)",
            "rgba_dark": "rgb(127, 105, 155)"
        },
        {
            "color": "cs-event-colour-31",
            "rgba": "rgb(113, 180, 238)",
            "rgba_dark": "rgb(85, 137, 182)"
        },
        {
            "color": "cs-event-colour-32",
            "rgba": "rgb(222, 131, 54)",
            "rgba_dark": "rgb(169, 101, 44)"
        },
        {
            "color": "cs-event-colour-33",
            "rgba": "rgb(235, 75, 158)",
            "rgba_dark": "rgb(189, 61, 128)"
        },
        {
            "color": "cs-event-colour-34",
            "rgba": "rgb(43, 147, 231)",
            "rgba_dark": "rgb(32, 95, 146)"
        },
        {
            "color": "cs-event-colour-35",
            "rgba": "rgb(142, 68, 235)",
            "rgba_dark": "rgb(103, 52, 167)"
        },
        {
            "color": "cs-event-colour-36",
            "rgba": "rgb(224, 235, 105)",
            "rgba_dark": "rgb(183, 191, 85)"
        },
        {
            "color": "cs-event-colour-37",
            "rgba": "rgb(106, 238, 175)",
            "rgba_dark": "rgb(95, 201, 150)"
        },
        {
            "color": "cs-event-colour-38",
            "rgba": "rgb(109, 238, 238)",
            "rgba_dark": "rgb(89, 188, 188)"
        },
        {
            "color": "cs-event-colour-39",
            "rgba": "rgb(45, 202, 236)",
            "rgba_dark": "rgb(40, 160, 187)"
        },
        {
            "color": "cs-event-colour-40",
            "rgba": "rgb(130, 237, 45)",
            "rgba_dark": "rgb(103, 186, 38)"
        },
        {
            "color": "cs-event-colour-41",
            "rgba": "rgb(231, 4, 4)",
            "rgba_dark": "rgb(203, 0, 0)"
        },
        {
            "color": "cs-event-colour-42",
            "rgba": "rgb(57, 187, 86)",
            "rgba_dark": "rgb(53, 169, 79)"
        },
        {
            "color": "cs-event-colour-43",
            "rgba": "rgb(168, 8, 161)",
            "rgba_dark": "rgb(145, 10, 139)"
        },
        {
            "color": "cs-event-colour-44",
            "rgba": "rgb(131, 134, 0)",
            "rgba_dark": "rgb(70, 71, 0)"
        }
    ];

    var all_colors = [
        {
            color: 'black',
            rgba: 'rgba(0,0,0,1)'
        },
        {
            color: 'gray',
            rgba: 'rgba(100,100,100,1)'
        },
        {
            color: 'white',
            rgba: 'rgba(255,255,255,1)'
        },
        {
            color: 'light-blue',
            rgba: 'rgba(112,178,177,1)'
        },
        {
            color: 'pale-blue',
            rgba: 'rgba(112,178,177,1)'
        },
        {
            color: 'lighter-blue',
            rgba: 'rgba(115,114,224,1)'
        },
        {
            color: 'true-blue',
            rgba: 'rgba(83,119,204,1)'
        },
        {
            color: 'dark-purple',
            rgba: 'rgba(148,148,185,1)'
        },
        {
            color: 'lightest-pink',
            rgba: 'rgba(255,225,248,1)'
        },
        {
            color: 'pink',
            rgba: 'rgba(255,205,244,1)'
        },
        {
            color: 'pale-purple',
            rgba: 'rgba(206,176,200,1)'
        },
        {
            color: 'purple',
            rgba: 'rgba(178,130,168,1)'
        },
        {
            color: 'light-yellow',
            rgba: 'rgba(254,243,162,1)'
        },
        {
            color: 'yellow',
            rgba: 'rgba(255,240,124,1)'
        },
        {
            color: 'dark-yellow',
            rgba: 'rgba(245,225,77,1)'
        },
        {
            color: 'lightest-orange',
            rgba: 'rgba(254,200,158,1)'
        },
        {
            color: 'light-orange',
            rgba: 'rgba(255,165,96,1)'
        },
        {
            color: 'dark-orange',
            rgba: 'rgba(255,136,96,1)'
        },
        {
            color: 'red',
            rgba: 'rgba(255,101,93,1)'
        },
        {
            color: 'dark-pink',
            rgba: 'rgba(211,133,133,1)'
        },
        {
            color: 'light-brown',
            rgba: 'rgba(204,169,157,1)'
        },
        {
            color: 'brown',
            rgba: 'rgba(172,114,94,1)'
        },
        {
            color: 'light-green',
            rgba: 'rgba(139,255,136,1)'
        },
        {
            color: 'medium-green',
            rgba: 'rgba(109,235,99,1)'
        },
        {
            color: 'dark-green',
            rgba: 'rgba(75,189,66,1)'
        },
        {
            color: 'sludge-green',
            rgba: 'rgba(144,180,141,1)'
        },
        {
            color: 'grey',
            rgba: 'rgba(203,203,203,1)'
        },
        {
            color: 'grey-medium',
            rgba: 'rgba(178,178,178,1)'
        }
    ];

    this.getColors = function(category) {
        if (category === 'branding_background' ||
            category === 'branding_text') {
            return branding_colors;
        } else if (category === 'diary_color') {
            return diary_colors;
        } else {
            return all_colors;
        }
    };
});
