commusoftCommon.directive('smarttable', ['prefix', '$http', '$modal', 'tableCollection', '$document', '$timeout', 'clickEvents', '$rootScope', '$compile', 'dragHelper', '$state', '$filter', 'reportFilterSettings','warningModal','$translate', function(prefix, $http, $modal, tableCollection, $document, $timeout, clickEvents, $rootScope, $compile, dragHelper, $state, $filter, reportFilterSettings, warningModal,translate) {
    var handleSmartTable = function handleSmartTable(scope, element, attrs) {
        $('.inline-pagination', element).detach().appendTo(element.parents('section.data-section'));
        //Remove unwanted <div> inline-pagination
        scope.$on('smarttable:pagination:remove', function(event) {
            angular.element( document.querySelector( '.inline-pagination' ) ).remove();
        });
        $rootScope.$on('closeall', function(event) {
            scope.active_actions_index = null;
            clickEvents.registerIgnoreElements(null);
        });

        $rootScope.$on('closeallwithapply', function(event) {
            scope.$apply(function() {
                scope.active_actions_index = null;
            });
            clickEvents.registerIgnoreElements(null);
        });

        scope.$on('smartTable:partsReload', function(event,pageNum,data) {

            if(scope.currentPage > 1)
            {
                scope.currentPage = pageNum;
            }else
            {
                scope.fetchRecords(pageNum,'');
            }
        });

        scope.showSidepanel = function showSidepanel(e, index, tmplt, tblpos) {
            var cat = $(e.target).parents('.smart-table-container').attr('category'),
                tablePosition = tblpos;
            // fetches the data from the smart table service to inject into our sidepanel
            scope.sidepanelData = tableCollection.collection[cat].data[index];
            // close all open widgets
            $rootScope.$broadcast('closeall');
            scope.$parent.$parent.actions_active_id = false;

            var removeBothElements = function removeBothElements(e){
                var anchor = 'A';
                var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');
                var overlay = document.getElementById('page-overlay');

                // if the target element is an anchor (ie the save the cancel link)
                // then prevent the link changing the URl.
                if (e != undefined && e.currentTarget.tagName === anchor) {
                    e.preventDefault();
                }

                panel[0].classList.remove('in-view');
                overlay.classList.remove('in-view');

                $timeout(function() {
                    overlay.parentNode.removeChild(overlay);
                    panel[0].parentNode.removeChild(panel[0]);
                }, 200);

                // remove keyboard events
                unbindKeyboardEvents();

            }

            var insertPanel = function insertPanel() {
                // close all open widgets
                $rootScope.$broadcast('closeall');
                /*====================================================
                                      Builds the panel element and insert it into the DOM,
                                      the panel is hidden in the CSS so that it can be
                                      animated with a CSS transition with the showPanel
                                      function.
                                      ====================================================*/
                var constructAndInsertPanel = (function() {
                    var panel_wrap = document.createElement('div'),
                        sidepanelTemplate = '';

                    panel_wrap.id = 'more-details-panel';
                    panel_wrap.className = 'smart-table-sidepanel'
                    document.getElementsByTagName('body')[0].appendChild(panel_wrap);

                    /*====================================================
                                                  1. Fetches the sidepanel template to use
                                                  2. Brings the sidepanel data into the sidepanel's scope
                                                  3. Appends a compiled result of the data and template
                                                  into the view
                                                  ====================================================*/
                    var tpl_path = "/template/side_panels/" + tmplt + ".html";

                    scope.sidepanelTemplate = [];
                    var fetchsidepanelTemplate = function fetchsidepanelTemplate() {
                        $http.get(tpl_path).then(function(result){
                            sidepanelTemplate = result.data;
                            panel_wrap.appendChild($compile(result.data)(scope)[0]);
                            /*====================================================
                                                              After the panel is built, push it into view
                                                              and attach keyboard listeners
                                                              ====================================================*/
                            var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');
                            $timeout(function() {
                                panel[0].classList.add('in-view');
                            }, 10);
                            bindKeyboardEvents(index, tablePosition);
                            insertPageOverlay();
                        });
                    }
                    fetchsidepanelTemplate();
                })();
            }

            var insertPageOverlay = function insertPageOverlay() {
                /*====================================================
                                      Builds the overlay element and insert it into the DOM,
                                      the overlay is hidden in the CSS so that it can be
                                      animated with a CSS transition
                                      ====================================================*/
                var constructAndInsertOverlay = (function(){
                    var insertElm = function insertElm() {
                        var wrapper = document.createElement('div');
                        wrapper.id = 'page-overlay';
                        document.getElementsByTagName('body')[0].appendChild(wrapper);
                    }
                    if (document.getElementById('page-overlay') === null) {
                        insertElm();
                    }else {
                        // remove the existing one before inserting the new one
                        document.getElementById('page-overlay').remove();
                        insertElm();
                    }
                })();
                /*====================================================
                                      Show the overlay element and attach click event listeners
                                      to it.
                                      ====================================================*/
                var showAndAttachEvts = (function() {
                    var handleElm = function handleElm() {
                        $timeout(function() {
                            var overlay = document.getElementById('page-overlay');
                            overlay.className = overlay.className + 'in-view';
                        }, 100);

                        // attach click listeners
                        var overlay = document.querySelectorAll('#page-overlay');
                        var save_link = document.querySelectorAll('#more-details-panel.smart-table-sidepanel #save-panel-btn');
                        var cancel_link = document.querySelectorAll('#more-details-panel.smart-table-sidepanel #cancel-panel-btn');
                        for (var i = 0; i < overlay.length; i++) {
                            overlay[i].addEventListener('click', removeBothElements);
                        }
                        for (var i = 0; i < save_link.length; i++) {
                            save_link[i].addEventListener('click', removeBothElements);
                        }
                        for (var i = 0; i < cancel_link.length; i++) {
                            cancel_link[i].addEventListener('click', removeBothElements);
                        }
                    }
                    handleElm();
                })();
            }

            /*====================================================
                                  Update the panel content when a keyboard shortcut
                                  is pressed
                                  ====================================================*/
            var updatePanelContent = function updatePanelContent(index, row){
                scope.sidepanelData = tableCollection.collection[cat].data[row];
                // update the active row in the DOM
                scope.$parent.$parent.actions_active_id = index;
            }

            /*====================================================
                                  Bind keyboard event listeners
                                  - 'esc' closes the panel
                                  - 'up' & 'down' to navigate through the rows
                                  ** gets the correct index for the data from the index
                                  of the table using $index so that pinned rows
                                  are respected.
                                  ====================================================*/
            var bindKeyboardEvents = function bindKeyboardEvents(row, index) {
                $document.bind('keydown.sidepanelShortcuts', function(evt) {
                    var evt = evt || window.event;
                    var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');
                    var totalRows = tableCollection.getOrderedRows().length - 1;
                    if (panel[0] != null) {
                        var handleEscKey = (function() {
                            // esc keydown
                            if (evt.keyCode == 27) {
                                removeBothElements();
                            }
                        })();
                        var handleShortcuts = (function() {
                            // up keydown
                            if (evt.keyCode == 38) {
                                if (index != 0){
                                    index = index-1;
                                    var rowData = $scope.rows[index][0].row
                                    updatePanelContent(index, rowData);
                                }
                            }
                            // down keydown
                            else if (evt.keyCode == 40) {
                                if(index < totalRows){
                                    index = index+1;
                                    var rowData = $scope.rows[index][0].row
                                    updatePanelContent(index, rowData);
                                }
                            }
                        })();
                    }
                })
            }

            /*====================================================
                                  Unbind keyboard events
                                  ====================================================*/
            var unbindKeyboardEvents = function unbindKeyboardEvents() {
                $document.unbind('keydown.sidepanelShortcuts');
            }

            /*====================================================
                                  Instantiate panel instance
                                  ====================================================*/
            insertPanel();
        }

        // Send the category and whether selectable rows are supported in
        // this instance to the smart table service
        tableCollection.handleTable(attrs.category, attrs.supportsAssociatedRows);

        /*==========================================================================================
         Initial table headers and table body variables.
         - These can be updated in the update table columns
         panel.
         ==========================================================================================*/
        scope.elm = element;
        scope.category = attrs.category;

        if(scope.category == 'reporting_jobs_free_of_charge_jobs' ||scope.category == 'reporting_jobs_jobs' || scope.category == 'reporting_jobs_aborted_jobs' || scope.category == 'reporting_jobs_aborted_jobs' ||scope.category == 'reporting_diary_events_diary_events' ||  scope.category == 'reporting_profit_profit_by_job' || scope.category == 'reporting_users_time_sheets' || scope.category == 'reporting_users_office_tasks' || scope.category == 'reporting_sales_Customer_Payments' ){
            scope.show = true;
        }else{
            scope.show = false;
        }
        scope.norecord = attrs.norecord;
        if(attrs.norecordfound){
            scope.recordfound = attrs.norecordfound;
        }
        if(attrs.norecordfoundsearch){
            scope.recordfoundsearch = attrs.norecordfoundsearch;
        }

        if(attrs.navigablefiles == "true"){
            scope.navigableFiles = true;
        }

        scope.clickAction = function(evt, clicked_row_index, category) {
            if ($(evt.target).hasClass('actions-toggle')) {
                return false;
            }
            scope.trySidepanel(evt, clicked_row_index, category);

            if (category == 'attached_files') {
                scope.simpleSelectRow(evt, clicked_row_index);
            }
            /*
                    else {
                         scope.selectRow(evt, clicked_row_index);
                    }
            */
        };

        scope.listUrl = attrs.listurl;
        scope.filtercolumn = attrs.filtercolumn;
        scope.permissioncheck = attrs.permissioncheck || false;
        scope.permissiontext = attrs.permissiontext || false;
        scope.with_configurable_columns = true;
        scope.unique_id = attrs.uniqueId || true;
        scope.selectable_rows = false;
        scope.more_details_col = false;
        scope.with_draggable_rows = false;
        scope.actions_col = false;
        scope.actions_dropdown_col = false;
        scope.actions_col_new = false;
        scope.show_update_col_panel = false;
        scope.show_more_details_panel = false;
        scope.active_rows = false;
        scope.appliance_has_more_details = false;
        scope.supports_associated_rows = false;
        scope.all_columns = tableCollection.getAllColumns(scope.category);
        scope.update_col_link;
        scope.columns = tableCollection.getOrderedColumns();
        scope.rows = tableCollection.getOrderedRows();
        scope.viewType = tableCollection.getViewType();

        scope.toggle_message = 'Select All ( a )';
        scope.totalCount = tableCollection.getCount(attrs.category);
        scope.limit = attrs.pagelimit;
        scope.filter = attrs.moreFilter;
        scope.pageNum = 1;
        scope.currentPage = 1;
        scope.moduleType = $rootScope.moduleType;
        scope.remove_actions_select = attrs.removeActionsSelect;
        scope.limitText = (attrs.limitText)?true:false;
        scope.hasSummaryRow = false;
        scope.screenName = attrs.screenName;
        scope.noOfPages = (scope.totalCount % scope.limit == 0) ? Math.floor(scope.totalCount / scope.limit ): (Math.floor(scope.totalCount / scope.limit) + 1);
        scope.infinitescroll = attrs.infinitescroll || false;
        scope.data_loading = false;
        scope.tagFilterData = {};
        scope.actionstemplate = false;

        scope.getActionIndex = function () {
            for (var index = 0; index < scope.columns.length; index++) {
                if(scope.columns[index]['col_name'] == "Finance status") {
                    scope.actionIndex = index;
                }
            }
        }

        if(attrs.actionstemplate) {
            scope.actionstemplate = attrs.actionstemplate;
        }
        //console.log('actionstemplate', scope.actionstemplate);

        if(attrs.editurl) {
            scope.editUrl = attrs.editurl;
        }

        $rootScope.$on('todosWithDiaryEvent:selected', function(e, pageNum) {
            scope.active_rows = false;
            scope.pinned_rows = false;
            scope.rows_store = [];
            scope.fetchRecords(pageNum, '');
        });

        scope.reloadSmartTableWithCategory = function (data) {

            var smartTable = ' <div smarttable ' +
                'category="stock_current" ' +
                'norecordfound="No.records.found" ' +
                'norecordfoundsearch="No.records.found.for.this.search" ' +
                'actions_dropdown_col="true" ' +
                'listurl=' + "'" + data.url + "' " +
                'with_configurable_columns="false" ' +
                'permissioncheck="Transferandadjuststocklevels,writeaccess" ' +
                'pagelimit="5" ' +
                'class="smart-table-container fix-right"></div> ';

            var smartTableStockCurrent = $compile(smartTable)(scope);

            $timeout(function() {
                var parent = document.querySelector('#replaceStockCurrent');
                $(parent).html(smartTableStockCurrent);

            },100);

        }

        scope.$on('reloadSmartTableWithCategoryOn', function(e, data) {
            scope.reloadSmartTableWithCategory(data);
        });
        $rootScope.$on('reportingInfiniteScroll', function(evt, elem) {
            if(scope.infinitescroll){
                scope.loadMoreResults();
            }
        });
        // function to handle infinite scroll load data's
        scope.loadMoreResults = function(ev){
            if(ev) ev.preventDefault();
            if (scope.data_loading) {
                return;
            }
            if (scope.pageNum >= scope.noOfPages) {
                return;
            }
            scope.data_loading = true;
            scope.pageNum = scope.pageNum + 1;
            scope.fetchRecords(scope.pageNum, scope.tagFilterData);
        };
        scope.setDefaultSelectRows = function() {

        };
        scope.setSelectedPrevRows = function(prev_rows){
            Array.prototype.splice.apply(scope.rows, [0, prev_rows.length].concat(prev_rows));
        };
        scope.fetchRecords = function (pageNum, data) {
            scope.val = '';
            if(data == ''){
                 data = '{}';
            }
            if($state.params.category && $state.params.subcategory){
                scope.filterData = angular.toJson(reportFilterSettings.getAppliedFilters({'category': $state.params.category, 'subcategory': $state.params.subcategory, 'filters': angular.fromJson(data), 'availableFilters': reportFilterSettings.availableFilters}));
            }else{
                scope.filterData = data;
            }
            if($state.current.name == 'loggedin.reporting.outstanding_jobs') {
                scope.isReportingPage = true;
            }
            if(scope.filter && scope.filter == "true"){
                scope.val = '&filter=' + encodeURIComponent(scope.filterData);
            } else if (data && data != '') {
                scope.val = '&filter=' + encodeURIComponent(scope.filterData);
            }
            if(pageNum != undefined) {
                var url = prefix + '/' + scope.listUrl + '?page=' + pageNum + '&limit=' + scope.limit;
                if (scope.listUrl.indexOf('?') !== -1) {
                    url = prefix + '/' + scope.listUrl + '&page=' + pageNum + '&limit=' + scope.limit;
                }


                var prev_rows = scope.rows;
                $http.post(url, scope.val).success(function (data) {
                    if (angular.isObject(scope.filterData)) {
                        reportFilterSettings.updateURLFilters(angular.fromJson(scope.filterData), reportFilterSettings.availableFilters);
                    }

                    tableCollection.summaryRowAdded = false;
                        /* This commented-out block of code is to be used later for infinite scrolling, after smarttable has been refactored to allow large amounts of data to be shown without slowing the browser down. */
			/*
			 * if (infinitescroll) {
			 *         tableCollection.appendData(attrs.category, data[attrs.category]);
			 *         scope.limit = scope.limit*2;
			 *         tableCollection.handleTable(attrs.category);
			 *         scope.totalCount += data[attrs.category]['count'];
			 *         paginationService.setTotalCount(scope.totalCount);
			 * }
			 * else {
			 *         tableCollection.setData(attrs.category, data[attrs.category]);
			 *         tableCollection.handleTable(attrs.category);
			 *         scope.totalCount = tableCollection.getCount(attrs.category);
			 * }
			 */
                    //tableCollection.setData(attrs.category, data[attrs.category]);
                    if(pageNum !== 1 && scope.infinitescroll) {
                        tableCollection.appendData(attrs.category, data[attrs.category]);
                        if(scope.category == 'reporting_jobs_outstanding_jobs') {
                            $rootScope.$emit('smart_table:jobs_outstanding_jobs_rows', {rows:data[attrs.category].data, type: 'append'});
                        }
                    }
                    else {
                        tableCollection.setData(attrs.category, data[attrs.category]);
                        if(scope.category == 'reporting_jobs_outstanding_jobs') {
                            $rootScope.$emit('smart_table:jobs_outstanding_jobs_rows', {rows:data[attrs.category].data, type: 'assign'});
                        }
                    }
                    // reset page number when filters applied in infinite scroll
                    if(pageNum == 1 && scope.infinitescroll) {
                        scope.pageNum = 1;
                    }
                    tableCollection.handleTable(attrs.category);
                    scope.totalCount = tableCollection.getCount(attrs.category);
                    scope.noOfPages = (scope.totalCount % scope.limit == 0) ? Math.floor(scope.totalCount / scope.limit ): (Math.floor(scope.totalCount / scope.limit) + 1);
                    scope.columns = tableCollection.getOrderedColumns();
                    scope.rows = tableCollection.getOrderedRows();
                    scope.viewType = tableCollection.getViewType();
                    scope.hasSummaryRow = tableCollection.hasSummaryRow();
                    scope.getActionIndex();

                    scope.strikeoutRow = function (index) {
                       return tableCollection.strikeoutRow(index);
                    }

                    if(pageNum == 1 && scope.infinitescroll) {
                        scope.setPinnedAttrs();
                        $rootScope.rows_store = [];
                        scope.allRowsUnchecked();
                    }
                    if(scope.remove_actions_select && scope.remove_actions_select == 'true') {
                        scope.removeActionsWithSelect();
                    }
                    $rootScope.$emit('smart_table:milestoneUpdate', data);

                    if(attrs.category == 'job_cost_supplier_returns' && scope.val == ''){
                        scope.$emit('ReturnsDetails', {
                            returnsTotal: data.job_cost_supplier_returns.returnsTotal,
                            returnSupplier: ''
                        });
                    }

                    scope.$emit('smarttable:record-count', scope.totalCount);

                    if(attrs.category == 'job_certificates') {
                        var value = {name: 'certificateCount', value: scope.totalCount};
                        scope.$emit('event:changeJobScope', value);
                    }
                    if(scope.infinitescroll && pageNum !== 1) {
                        scope.setSelectedPrevRows(prev_rows);
                    }
                    scope.data_loading = false;

                })
            }
            if (angular.isObject(data)) {
                scope.setFilterValue(data);
            }
        }

        scope.hasFilter = false;
        scope.setFilterValue = function (data) {
            var hasFilter = false;
            if(data) {
                var dataObject = JSON.parse(data);
                for (var key in dataObject) {
                    //This is to handle the date range object
                    if (typeof dataObject[key] == 'object') {
                        var innerObject = dataObject[key];
                        for (var anotherKey in innerObject) {
                            if (innerObject[anotherKey] && innerObject[anotherKey] != '') {
                                hasFilter = true;
                            }
                        }
                    } else {
                        if (dataObject[key] && dataObject[key] != '') {
                            hasFilter = true;
                        }
                    }
                }
            }
            scope.hasFilter = hasFilter;
        }

        // This method is called from smart_table's table.html and is used when filters are in place. If there
        // are no filters, it calls the standard fetchRecords, otherwise it emits the fromsmarttable:filters, which
        // should be handled by the Calling Controller which then collects the data from sidepanel and submits for
        // pagination.
        scope.fetchRecordsWithFilters = function(pageNum) {
            if((scope.category=='viewAllServiceReminder' && scope.active_rows == true) || (scope.$parent.enableBatchProcessing==true && scope.category=='viewAllServiceReminder')){
                scope.toggleAllRows();
                scope.allRowsUnchecked();
            }
            if (scope.filter && scope.filter == "true") {
                scope.currentPage = pageNum;
                scope.$emit('fromsmarttable:filters', {});
            } else {
                scope.fetchRecords(pageNum, scope.filterData);
            }
        }
        //Remove Actions Button and Selectable rows
        scope.removeActionsWithSelect = function() {
            if($rootScope.selectedStatusType == 'pending'){
                scope.actions_dropdown_col = true;
                scope.selectable_rows = true;
            }
            else{
                scope.actions_dropdown_col = false;
                scope.selectable_rows = false;
            }
            scope.$emit('smarttable:actions:remove');
        }
        scope.all_columns = tableCollection.getAllColumns(scope.category);
        scope.columns = tableCollection.getOrderedColumns();
        scope.rows = tableCollection.getOrderedRows();
        scope.getActionIndex();

        // Send the all_columns object to the sortableTableColumnsCtrl which
        // makes the columns in the edit column panel ui-sortable
        scope.$broadcast('smart_table:columns_loaded', scope.all_columns);

        // handle instance options
        if(attrs.withConfigurableColumns === "false") {
            scope.with_configurable_columns = false;
        }
        if(attrs.selectableRows === "true") {
            scope.selectable_rows = true;
            scope.toggle_message = 'Select All ( a )';
            scope.rows_store = [];

        }
        if(attrs.moreDetailsCol === "true") {
            scope.more_details_col = true;
        }
        if(attrs.actionsCol === "true") {
            scope.actions_col = true;
        }
        if(attrs.actionsColNew === "true") {
            scope.actions_col_new = true;
        }
        if(attrs.actionsDropdownCol === "true") {
            scope.actions_dropdown_col = true;
        }

        if(scope.actions_dropdown_col && scope.permissioncheck ) {
          var checkFeatureArray = [];
          if (scope.permissioncheck.indexOf("|") !== -1) {
            checkFeatureArray = scope.permissioncheck.split("|");
          }
          else {
            checkFeatureArray.push(scope.permissioncheck);
          }
          scope.actions_dropdown_col = _.some(checkFeatureArray, function(feat) {
            var featureArray = feat.split(','),
              featureName = featureArray[0],
              featureAccess = featureArray[1],
              featureId = features[featureName];
            scope.featureId = featureId;
            return $rootScope.hasPermission(featureId, featureAccess);
          });
        }

        if(attrs.withDraggableRows === "true") {
            scope.with_draggable_rows = true;
            scope.drag_channel = attrs.dragChannel;
        }
        if(attrs.supportsAssociatedRows === "true") {
            scope.supports_associated_rows = true;
        }

        attrs.$observe('withDraggableRows', function(withDraggableRows) {
            scope.with_draggable_rows = angular.fromJson(withDraggableRows);
        });
        attrs.$observe('selectableRows', function(selectableRows) {
            if(selectableRows === "true") {
                scope.selectable_rows = true;
                scope.toggle_message = 'Select All ( a )';
                scope.rows_store = [];
            } else {
                scope.selectable_rows = false;
            }
        });

        $rootScope.$on('closeall', function(event) {
            scope.actions_active_id = false;
            clickEvents.registerIgnoreElements(null);
        });

        $rootScope.$on('closeallwithapply', function(event) {
            scope.$apply(function() {
                scope.actions_active_id = false;
            });
            clickEvents.registerIgnoreElements(null);
        });

        $rootScope.$on('smart_table:remove_all_pinned_rows', function(event) {
            // loop through backwards
            for (var i = scope.rows.length -1, l = 0; i >= l; i--) {
                if(scope.rows[i].pinned == true){
                    scope.rows.splice(i,1);
                }
            }
            scope.update_pinned_rows_store();
            scope.$parent.enableBatchProcessing = false;
            $rootScope.rows_store = [];
        });

        /*==========================================================================================
         Generate the edit href
         ==========================================================================================*/
        scope.navigateToEdit = function navigateToEdit(row_index) {
            var edit_state = '';

            if ($state.params.id) {
                var customer_id = $state.params.id;
            }

            if (scope.category === 'appliances') {
                var appliance_id = scope.rows[row_index][0].id;

                $state.transitionTo("loggedin.customer_list.view.edit_appliance", {id : customer_id, 'type': $state.params.type, applianceId : appliance_id});
            }
            /* Update the todos details for job screen */
            else if(scope.category === 'job_assigned_todos') {

                var url = prefix + '/' + scope.editUrl;

                var updateData = {"id": this.instanceId.id, "description" : this.description, "certificateId" : this.certificateId, "partId" : this.partId,
                    "applianceId": this.applianceId, "estimateHours": this.estimateHours, "quantity": this.quantity};

                $http.put(url, updateData).success(function (data) {
                    scope.fetchRecords(scope.currentPage, '');
                    scope.$emit('tabCustomer:successMessage', "Updated");
                    $rootScope.$emit('closeAllSidepanels');
                });

            } else {
                return false;
            }
        }

        scope.numberPattern = function numberPattern() {
            var regexp = /^\d*\.?\d*$/;
            scope.estimateHoursError = regexp.test(this.estimateHours);
        }

        /*==========================================================================================
         Generate the delete href
         ==========================================================================================*/
        scope.navigateToDelete = function navigateToDelete(row_index) {
            var edit_state = '';
            scope.shouldBeOpenAppliance = true;

            if ($state.params.id) {
                var customer_id = $state.params.id;
            }

            if (scope.category === 'appliances') {
                scope.applianceid = scope.rows[row_index][0].id;
            } else {
                return false;
            }
        }

        scope.navigateToAcceptReject = function navigateToAcceptReject(row,index,type) {
            var edit_state = '';
            let value = row[0]['value']
            let regex = /\/(\d+)\/view/;
            let match = value.match(regex);
            let opportunityId = match ? match[1] : null;
            let customerRegex = /customers\/customer\/(\d+)/;
            let customerMatch = value.match(customerRegex);
            let customerId = customerMatch ? customerMatch[1] : null;
            scope.opportunityId = opportunityId;
            scope.customerId = customerId;
            if(type == 'accept') {
                scope.shouldBeOpenAccept = true
            } else {
                scope.shouldBeOpenReject = true
            }
        }

        $rootScope.$on('smart_table:unpin_all_pinned_rows', function(event) {
            // loop through backwards
            for(var i = 0, l = scope.rows.length; i < l; i++){
                scope.rows[i].pinned = false;
                scope.rows[i].active = false;
            }
            scope.update_pinned_rows_store();
            scope.$parent.enableBatchProcessing = false;
            $rootScope.rows_store = [];

            scope.allRowsUnchecked();
        });

        /*==========================================================================================
         This function watches when an associated row needs to be generated
         ==========================================================================================*/
        $rootScope.$on('smart_table:remove_associated_rows_at_index', function(event, index_to_remove) {
            scope.rows[index_to_remove].associated_rows = [];
        });

        /*==========================================================================================
         This function watches when an associated row needs to be generated
         ==========================================================================================*/
        $rootScope.$on('smart_table:handleInsertingAssociatedRow', function(event, row_to_insert, index_to_insert) {
            for(var i = 0, l = row_to_insert.length; i < l; i++){
                scope.rows[index_to_insert].associated_rows.push(row_to_insert[i][0]);
                console.log(row_to_insert[i]);
            }
        });

        /*==========================================================================================
         This function watches when a row needs to be pinned from outside the smart table
         ==========================================================================================*/
        $rootScope.$on('smart_table:selectRow', function(rootscope_event, e, checkbox_cell_index) {
            scope.selectRow(e, checkbox_cell_index);
        });
        /*==========================================================================================
         Insert the tabular data
         ==========================================================================================*/
        scope.handleTable = function handleTable() {
            scope.handleCustomColumns();
        }

        /*==========================================================================================
         Finds a element inside of the elmements child object
         ==========================================================================================*/
        scope.findElm = function findElm(elm) {
            var result;
            var temp_elms = [];
            var elm_children = this.elm.children();

            // Put all of the child elements into an array
            for (var key in elm_children) {
                if (elm_children.hasOwnProperty(key)) {
                    temp_elms.push(elm_children[key]);
                }
            }

            // Loop thorugh the array to find the position of the table
            for(var i = 0, l = temp_elms.length; i < l; i++){
                if(temp_elms[i].id === 'smart-table-wrapper'){
                    var table_elm = temp_elms[i];
                }
                if(temp_elms[i].id === 'update-column-link'){
                    var link_elm = temp_elms[i];
                }
                if(temp_elms[i].id === 'select-all-link'){
                    var select_all_elm = temp_elms[i];
                }
            }

            if (elm === 'table-wrapper') {
                result = table_elm;
            }else if (elm === 'link') {
                result = link_elm;
            }else if (elm === 'select-all-link') {
                result = select_all_elm;
            }

            return result;
        }

        /*==========================================================================================
         Custom Columns
         ==========================================================================================*/
        scope.handleCustomColumns = function handleCustomColumns() {
            if(scope.with_configurable_columns) {
                this.update_col_link = scope.findElm('link');
                this.update_col_link.addEventListener('click', scope.handleElmsToInsert);
            }
        }

        /*==========================================================================================
         Controls the events that occur when the update column
         link is clicked.
         ==========================================================================================*/
        scope.handleElmsToInsert = function handleElmsToInsert() {
            scope.show_update_col_panel = true;
            scope.insertPageOverlay();
        }

        /*==========================================================================================
         - Saves all of the available columns in the category.
         - Column object stores if its currently active so that
         its checkbox can be checked.
         ==========================================================================================*/

        scope.updateTableColumns = function updateTableColumns(keep_panel_open) {

            // Re send the category to the smart table service
            tableCollection.handleTable(attrs.category, attrs.supportsAssociatedRows);
            var updated_cols = tableCollection.updateActiveColumns(scope.all_columns);
            tableCollection.updateTableCols(updated_cols);
            scope.handleTable();

            // store the previous rows so that the active states can be
            // maintained in the new rows collection

            var previous_rows = scope.rows,
                pinned_rows = [],
                pinned_rows_exist = false;
            scope.columns = tableCollection.getOrderedColumns();
            scope.rows = tableCollection.getOrderedRows();
            scope.getActionIndex();

            for (var i = 0, l = scope.rows.length; i < l; i++) {
                if (previous_rows[i].active === true) {
                    scope.rows[i].active = true;
                }

                if(previous_rows[i].pinned === true) {
                    pinned_rows.push(previous_rows[i]);
                    pinned_rows_exist = true;
                }

                scope.rows[i].active = false;
                scope.rows[i].pinned = false;
            }
            if (pinned_rows_exist) {
                var aa = [];

                for(var i = 0, l = pinned_rows.length; i < l; i++) {
                    var row_to_pin_index = pinned_rows[i][0].row;
                    var row_to_pin = scope.rows[row_to_pin_index];
                    aa.push(row_to_pin_index);

                    scope.rows[row_to_pin_index].active = true;
                    scope.rows[row_to_pin_index].pinned = true;
                }

                aa.sort(function(a, b) {
                    return a < b
                });

                var cc = [];

                // removes row from scope.rows
                for(var i = 0, l = aa.length; i < l; i++) {
                    var bb = scope.rows[aa[i]];
                    scope.rows.splice(aa[i], 1);
                    cc.push(bb);
                }

                // add the removed rows to the start of the scope.rows
                for(var i = 0, l = cc.length; i < l; i++) {
                    scope.rows.splice(0, 0, cc[i]);
                }
            }

            if (keep_panel_open === false) {
                // remove the panel and the overlay
                scope.removeBothElements();
                var encodedUri = encodeURIComponent(angular.toJson(scope.columns));
                $http.get(prefix + '/userColumnOrder?columnValue=' + encodedUri + '&screenName=' + (scope.screenName ? scope.screenName : scope.category ) ).success(function (data) {
                })
            }
        }

        /*==========================================================================================
         Called in the edit column panel.
         - triggered when a column is selected/unselected.
         ==========================================================================================*/
        scope.updateSelectedColumn = function updateSelectedColumn(previous_val) {
            var i = this.$index;
            scope.all_columns[i].active = !previous_val;
            scope.all_columns[i].pos = i + 1;

            scope.updateTableColumns(true);
        }
        scope.editPartRecord = function editPartRecord(quantity, salesprice, costprice, id, fulfillType, partStatus, jobId) {
            $http.post(prefix + '/edit_part', 'quantity=' + quantity + '&salesprice=' + salesprice + '&costprice=' + costprice + '&id=' + id + '&moduleId=' + $rootScope.clientJobType + '&jobtodos=' + $rootScope.clientJobTodos + '&partStatus=' + partStatus + '&fulfillType=' + fulfillType  + '&jobId=' + jobId).success(function (data) {
                $rootScope.$broadcast('parts:countupdated', data);
                scope.fetchRecords(scope.currentPage, '');
            });
        }
        scope.editFulfillmentPartRecord = function editPartRecord(fulfillment, jobId) {
            $http.post(prefix + '/edit_fulfillment_part', 'fulfillment=' + angular.toJson(fulfillment) + '&jobId=' + jobId).success(function (data) {
                $rootScope.$broadcast('parts:countupdated', data);
                scope.fetchRecords(scope.currentPage, '');
            });
        }
        scope.savePartStatus = function savePartStatus(selectedPartStatus, id, partStatus, allocatedQuantity, totalQuantity, jobId, inProgressLeftQuantity){

            if(partStatus === 'Requested'){
                $http.post(Routing.generate('job_part_status_update',{'jobId': jobId,'jobPartId':id,'status':selectedPartStatus}), 'partStatus=' + partStatus + '&allocatedQuantity=' + allocatedQuantity + '&totalQuantity=' + totalQuantity + '&moduleId=' + $rootScope.clientJobType + '&jobtodos=' + $rootScope.clientJobTodos ).success(function (data,status) {
                    $rootScope.$broadcast('parts:countupdated', data);
                    $rootScope.$broadcast('spreadsheet:todoupdate');
                    scope.$emit('todosWithDiaryEvent:selected', 1);
                    $rootScope.$emit('closeAllSidepanels');
                }).error(function(data,statusCode){
                    if(statusCode == 400){
                        var message = title = identifier = '';
                        if(data.errorMsg == "supplier_invoice_raised"){
                            identifier = data.errorMsg;
                            title = 'supplier.invoice.raised.title';
                            message = 'supplier.invoice.raised.description';
                        }
                        warningModal.show(message, title, identifier);
                    }
                });
            }else{
                $http.post(Routing.generate('fulfilled_part_status_update',{'jobId': jobId,'jobFulfillmentId':id,'status':selectedPartStatus}), 'partStatus=' + partStatus + '&allocatedQuantity=' + allocatedQuantity + '&totalQuantity=' + totalQuantity + '&moduleId=' + $rootScope.clientJobType + '&jobtodos=' + $rootScope.clientJobTodos ).success(function (data,status) {
                    $rootScope.$broadcast('parts:countupdated', data);
                    $rootScope.$broadcast('spreadsheet:todoupdate');
                    scope.$emit('todosWithDiaryEvent:selected', 1);
                    $rootScope.$emit('closeAllSidepanels');
                }).error(function(data,statusCode){
                    if(statusCode == 400){
                        var message = title = identifier = '';
                        if(data.errorMsg == "supplier_invoice_raised"){
                            identifier = data.errorMsg;
                            title = 'supplier.invoice.raised.title';
                            message = 'supplier.invoice.raised.description';
                        } else if(data.errorMsg == "stock_transaction_exceeds_actual_quantity" || data.errorMsg == "stock_transaction_exceeds_reserved_quantity" || data.errorMsg == "allocation_exceeds_actual_quantity") {
                            identifier = data.errorMsg;
                            title = 'Error';
                            message = data.errorMsg;
                        } else if(data.errorMsg == "multiple_invoice_raised_po") {
                            identifier = data.errorMsg;
                            title = 'supplier.invoice.raised.title';
                            message = 'Multiple supplier invoice has been raised';
                        }
                        warningModal.show(message, title, identifier);
                    }
                });
            }

        }

        scope.$on('smart_table:order_updated', function(event) {
            scope.updateTableColumns(true);
            /*==========================================================================================
             Force dom to update only when the order has been changed
             in sortableTableColumnsCtrl
             ==========================================================================================*/
            scope.$apply(function() {
                scope.rows = scope.rows;
            });
        });

        scope.$on('smarttable_and_sidepanel:filtervalues', function(evt, data, extraData) {
            scope.tagFilterData = data;
            if((scope.category=='viewAllServiceReminder' && scope.active_rows == true) || (scope.$parent.enableBatchProcessing==true && scope.category=='viewAllServiceReminder')){
                scope.toggleAllRows();
                scope.allRowsUnchecked();
            }
            if(!_.isUndefined(extraData) && _.isObject(extraData) && _.has(extraData, 'resetPage') && scope.currentPage != 1) {
              scope.currentPage = 1;
            }
            else {
              scope.fetchRecords(scope.currentPage, data);
            }
        });

        scope.$on('smart_tablefilter:filtervalue', function(evt, data) {
            scope.fetchRecords(scope.pageNum, data);
        });
        scope.$on('smart_tablefilter:fetchRecords', function(evt, pageNum, data) {
            scope.fetchRecords(pageNum, data);
        });
        scope.$on('smart_tablefilter:newsearch', function(evt, data) {
            if(attrs.category == data.category){
                var currentpage = (data.currentPage) ? data.currentPage : scope.currentPage;
                scope.fetchRecords(currentpage, data.filter);
            }
        });

        /*==========================================================================================
         Insert and show the page overlay
         ==========================================================================================*/
        scope.insertPageOverlay = function insertPageOverlay() {
            var insert = (function() {
                var overlay = document.createElement('div');
                overlay.id = 'smart-panel-overlay';
                document.getElementsByTagName('body')[0].appendChild(overlay);
            })();

            var show = (function() {
                $timeout(function() {
                    var overlay = document.getElementById('smart-panel-overlay');
                    overlay.className = overlay.className + 'in-view';

                    // attach click listener
                    overlay.addEventListener('click', scope.removeBothElements);
                }, 10);
            })();
        }

        /*==========================================================================================
         Remove the overlay and the preview panel
         ==========================================================================================*/
        scope.removeBothElements = function removeBothElements(e) {
            if (scope.show_update_col_panel === true) {
                scope.show_update_col_panel = false;
            }else if(scope.show_more_details_panel === true) {
                scope.show_more_details_panel = false;
                scope.$emit("more_details_panel:closed");
            }

            var overlay = document.getElementById('smart-panel-overlay');
            overlay.classList.remove('in-view');

            $timeout(function() {
                overlay.parentElement.removeChild(overlay);
            }, 200);
        }

        /*==========================================================================================
         Remove active state on active more details row when
         panel closed
         ==========================================================================================*/
        scope.$on("more_details_panel:closed", function() {
            for (var i = 0; i < scope.rows.length; i++) {
                if (scope.rows[i].active === true) {
                    scope.rows[i].active = undefined;
                }
            }
        });

        /*==========================================================================================
         Panel Keyboard Shortcuts
         - 'esc' key removes the panel & overlay
         - needs to be 'keydown' when listening for esc
         - 'a' selected all of the rows
         ==========================================================================================*/
        scope.registerKeyboardListeners = function registerKeyboardListeners() {
            $document.bind('keydown', function(evt) {
                var evt = evt || window.event;
                var panel = document.getElementById('update-column-panel');

                // Only register the select all shortcut if the instance
                // supports selectable rows
                if (scope.selectable_rows === true) {
                    // a to select all rows
                    if(evt.keyCode == 65) {
                        // if event triggered in an input do nothing
                        if (evt.target.localName === 'input' || evt.target.localName === 'textarea') {
                            evt.stopImmediatePropagation();
                        }else {
                            scope.toggleAllRows();
                        }
                    }
                }

                // only register keyboard events if either panel is showing
                if (scope.show_update_col_panel === true || scope.show_more_details_panel === true) {
                    var handleEscKey = (function() {
                        // esc keydown
                        if (evt.keyCode == 27) {
                            scope.removeBothElements();
                        }
                    })();
                }

                // only register up and down controls if the instance uses
                // the page_panel service
                if (scope.more_details_col === true) {
                    var handleTableNavigation = (function() {
                        var index;

                        // get the current active row
                        for (var i = 0, l = scope.rows.length; i < l; i++) {
                            if (scope.rows[i].active === true) {
                                index = i;
                            }
                        }

                        /*==========================================================================================
                         check that the new index is in bounds
                         ==========================================================================================*/
                        // up key
                        if (evt.keyCode == 38) {
                            if(scope.rows[index - 1] != undefined) {
                                index--;
                            }
                        }
                        // down key
                        else if (evt.keyCode == 40) {
                            if(scope.rows[index + 1] != undefined) {
                                index++;
                            }
                        }

                        /*==========================================================================================
                         remove the active state from the previously selected row
                         ==========================================================================================*/
                        for (var i = 0; i < scope.rows.length; i++) {
                            if (scope.rows[i].active === true) {
                                scope.rows[i].active = undefined;
                            }
                        }

                        /*==========================================================================================
                         Update the panel content and row active state
                         ==========================================================================================*/

                        if (index != undefined) {
                            scope.rows[index].active = true;
                            scope.injectMoreDetailPanel(index);
                        }
                    })();
                }

            });
        }

        scope.$on('smarttable:unselectAllRows', function(evt) {
            scope.unselectAllRows();
        });

        scope.unselectAllRows = function() {
            angular.forEach(scope.rows, function(row, i) {
                scope.rows[i].active = false;
            });
        }

        scope.$on('smarttable:simpleSelectRow', function(evt, clicked_row_index, eventsdisabled) {
            scope.simpleSelectRow(evt, clicked_row_index, eventsdisabled);
        });

        scope.simpleSelectRow = function(evt, clicked_row_index, eventsdisabled) {
            var number_of_pinned_rows = 0,
                i = this.$index,
                found = '',
                unpinned_rows_array = [];

            if (clicked_row_index || clicked_row_index === 0) {
                i = clicked_row_index;
            }

            var row_to_place = scope.rows[i];
            scope.rows[i].active = !scope.rows[i].active;
            var row_unchecked = scope.rows[i].active === false;

            if (!eventsdisabled) {
                $rootScope.$broadcast('smarttable:rowclicked', {'index': clicked_row_index});
            }
        };


        /*==========================================================================================
         Handle active states on table rows
         ==========================================================================================*/
        scope.selectRow = function selectRow(e, clicked_row_index) {
            /*==========================================================================================
             1. Set variables
             2. Store index
             3. Activate row in the view
             ==========================================================================================*/
            $rootScope.$broadcast('smarttable:rowclicked', {'index': clicked_row_index});

            var number_of_pinned_rows = 0,
                i = this.$index,
                found = '',
                unpinned_rows_array = [];

            if (clicked_row_index || clicked_row_index === 0) {
                i = clicked_row_index;
            }

            var row_to_place = scope.rows[i];

            scope.rows[i].active = !scope.rows[i].active;
            var row_unchecked = scope.rows[i].active === false;


            /*==========================================================================================
             Check if the row has been checked or not
             ==========================================================================================*/
            if(row_unchecked) {
                /*==========================================================================================
                 ==== If the row has been unchecked ====
                 1. Store and remove the row
                 2. Store copies of the pinned and unpinned rows
                 3. Check where the row should be placed
                 4. Replace the row and set pinned attribute
                 ==========================================================================================*/
                var row_to_replace = row_to_place,
                    unpinned_rows_array = [],
                    index_to_reinsert = 0;

                if (scope.supports_associated_rows) {
                    var row_to_replace_original_index = row_to_replace.row[0].row
                }else {
                    var row_to_replace_original_index = row_to_replace[0].row;
                }


                scope.rows.splice(this.$index, 1);

                for (var i = 0, l = scope.rows.length; i < l; i++) {
                    if(scope.rows[i].pinned == true){
                        number_of_pinned_rows = number_of_pinned_rows + 1;
                    }
                }

                for (var i = 0, l = scope.rows.length; i<l; i++) {
                    if (scope.rows[i].pinned === false){
                        unpinned_rows_array.push(scope.rows[i]);
                    }
                }

                /*==========================================================================================
                 If there are no rows unchecked
                 ==========================================================================================*/
                if (number_of_pinned_rows === 0){
                    scope.allRowsUnchecked();
                }

                /*==========================================================================================
                 If the row belongs at the bottom
                 ==========================================================================================*/
                if (row_to_replace_original_index === scope.rows.length) {
                    scope.rows.splice(scope.rows.length,0,row_to_replace);
                    scope.setPinnedAttrFalse(row_to_replace_original_index);
                    scope.update_pinned_rows_store();
                }

                /*==========================================================================================
                 If the row belongs at the top
                 ==========================================================================================*/
                else if (row_to_replace_original_index === 0) {
                    scope.rows.splice(number_of_pinned_rows,0,row_to_replace);
                    scope.setPinnedAttrFalse(number_of_pinned_rows);
                    scope.update_pinned_rows_store();
                }
                /*==========================================================================================
                 If there are no unpinned rows
                 ==========================================================================================*/
                else if (unpinned_rows_array.length === 0) {
                    scope.rows.splice(scope.rows.length,0,row_to_replace);
                    scope.setPinnedAttrFalse(scope.rows.length-1);
                    scope.update_pinned_rows_store();
                }
                else {
                    /*==========================================================================================
                     If the row belongs in the middle
                     ==========================================================================================*/
                    for (var i = 0, l = unpinned_rows_array.length; i<l; i++) {
                        /*==========================================================================================
                         If the row belongs between unpinned rows
                         ==========================================================================================*/
                        if (scope.supports_associated_rows) {
                            var row_belongs_between_unpinned_rows = row_to_replace_original_index < unpinned_rows_array[i].row[0].row
                        } else {
                            var row_belongs_between_unpinned_rows = row_to_replace_original_index < unpinned_rows_array[i][0].row
                        }

                        if (row_belongs_between_unpinned_rows) {
                            var index_to_insert = i + number_of_pinned_rows;
                            scope.rows.splice(index_to_insert,0,row_to_replace);
                            scope.setPinnedAttrFalse(index_to_insert);
                            found = true;
                            scope.update_pinned_rows_store();
                            return
                        }
                    }
                    for (var i = 0, l = unpinned_rows_array.length; i<l; i++) {
                        /*==========================================================================================
                         The row must belong at the bottom
                         ==========================================================================================*/
                        if (found != true) {
                            if (scope.supports_associated_rows) {
                                var row_belongs_at_the_bottom = row_to_replace_original_index > unpinned_rows_array[i].row[0].row
                            } else {
                                var row_belongs_at_the_bottom = row_to_replace_original_index > unpinned_rows_array[i][0].row
                            }
                            if (row_belongs_at_the_bottom) {
                                scope.rows.splice(scope.rows.length,0,row_to_replace);
                                scope.setPinnedAttrFalse(scope.rows.length-1);
                                found = true;
                                scope.update_pinned_rows_store();
                                return
                            }
                        }
                    }
                }
            }
            else {
                /*==========================================================================================
                 ==== If the row has been checked ====
                 1. Set the pinned state on the row to true
                 2. Store the row as a temp variable
                 3. Remove the row from the array
                 4. Re-insert the row at the top of the array
                 ==========================================================================================*/

                scope.rows[i].pinned = true;
                var temprow = scope.rows[i];
                scope.rows.splice(i, 1);
                scope.rows.splice(0,0,temprow);
                scope.$parent.enableBatchProcessing = true;

                for (var i = 0, l = scope.rows.length; i < l; i++) {
                    if(scope.rows[i].pinned == true){
                        number_of_pinned_rows = number_of_pinned_rows + 1;
                    }
                }

                /*==========================================================================================
                 If there are no rows unchecked
                 ==========================================================================================*/
                if (number_of_pinned_rows === scope.rows.length){
                    scope.allRowsChecked();
                }
                scope.update_pinned_rows_store();
            }
            $rootScope.rows_store = scope.rows_store;

            $rootScope.rows_store_id = scope.rows_store_id;
            $rootScope.$emit('smart_table:active_row_id', $rootScope.rows_store_id);
            $rootScope.$emit('smart_table:active_row', number_of_pinned_rows);

        }

        /*==========================================================================================*/

        scope.allRowsUnchecked = function allRowsUnchecked() {
            scope.$parent.enableBatchProcessing = false;
            scope.active_rows = false;
            scope.toggle_message = 'Select All ( a )';
            for (var i = 0, l = scope.rows.length; i < l; i++) {
                scope.rows[i].active = false;
                scope.rows[i].pinned = false;
            }
            scope.update_pinned_rows_store();
        };
        scope.allRowsChecked = function allRowsChecked() {
            scope.active_rows = true;
            scope.toggle_message = 'Un Select All ( a )';
            for (var i = 0, l = scope.rows.length; i < l; i++) {
                scope.rows[i].active = true;
                scope.rows[i].pinned = true;
            }
            scope.update_pinned_rows_store();
        };
        /* scope.update_pinned_rows_store = function update_pinned_rows_store(){
             // update the pined rows store
             scope.rows_store = [];
             scope.rows_store_id = [];
             for (var i = 0, l = scope.rows.length; i < l; i++) {
                 if(scope.rows[i].pinned == true){
                     var original_index_of_row = scope.rows[i][0].row,
                         row_to_push_id = scope.rows[i][0].id,
                         row_to_push = tableCollection.active_rows[original_index_of_row];
                     scope.rows_store.push(row_to_push);
                     scope.rows_store_id.push(row_to_push_id);
                 }
             }
             $rootScope.$emit('smart_table:pinned_rows_updated', scope.rows_store);
             $rootScope.rows_store = scope.rows_store;
             $rootScope.rows_store_id =scope.rows_store_id;
             $rootScope.$emit('smart_table:active_row_id', $rootScope.rows_store_id);
         }*/
        /*====================================================

         Set pinned attribute to false
         ==========================================================================================*/
        scope.setPinnedAttrFalse = function setPinnedAttrFalse(i){
            if (i > scope.rows.length){
                var index_to_insert = scope.rows.length;
            }else{
                var index_to_insert = i;
            }
            scope.rows[index_to_insert].pinned = false;
            scope.rows[index_to_insert].active = false;
        }

        scope.setPinnedAttrs = function setPinnedAttrs(){
            for(var i = 0, l = scope.rows.length; i<l; i++){
                scope.rows[i].pinned = false;
            }
        }

        /*==========================================================================================
         Select all / Un select all
         ==========================================================================================*/
        scope.toggleAllRows = function toggleAllRows() {
            var number_of_pinned_rows;
            var unpinned_rows_array = [];
            scope.active_rows = !scope.active_rows;
            scope.pinned_rows = !scope.pinned_rows;


            if (scope.active_rows === true) {
                scope.$parent.enableBatchProcessing = true;
                scope.toggle_message = 'Un Select All ( a )';
                for (var i = 0, l = scope.rows.length; i < l; i++) {
                    scope.rows[i].active = true;
                    scope.rows[i].pinned = true;
                }
            }else {
                scope.toggle_message = 'Select All ( a )';
                scope.$parent.enableBatchProcessing = false;
                for (var i = 0, l = scope.rows.length; i < l; i++) {
                    scope.rows[i].active = false;
                    scope.rows[i].pinned = false;
                }
            }
            scope.update_pinned_rows_store();
        }
        /*==========================================================================================
         Watch checkboxes to update toggleAllRows when
         checking / unchecking all boxes manually
         ==========================================================================================*/
        scope.allRowsUnchecked = function allRowsUnchecked() {
            scope.$parent.enableBatchProcessing = false;
            scope.active_rows = false;
            scope.toggle_message = 'Select All ( a )';
            for (var i = 0, l = scope.rows.length; i < l; i++) {
                scope.rows[i].active = false;
                scope.rows[i].pinned = false;
            }
            scope.update_pinned_rows_store();
        };

        scope.allRowsChecked = function allRowsChecked() {
            scope.active_rows = true;
            scope.toggle_message = 'Un Select All ( a )';
            for (var i = 0, l = scope.rows.length; i < l; i++) {
                scope.rows[i].active = true;
                scope.rows[i].pinned = true;
            }
            scope.update_pinned_rows_store();
        };

        scope.update_pinned_rows_store = function update_pinned_rows_store(){
            scope.rows_store = [];
            scope.rows_store_id = [];
            scope.unique_row_references = []; // NOTE: this is used to ensure uniqueness (used in fulfil multiple parts in jobs) - see smart_table_selected_records_that_are_the_same
            $rootScope.smart_table_selected_records_that_are_the_same = [];

            var in_job_category = (scope.category === 'job_parts_requested' ||
                scope.category === 'job_parts_on_order' ||
                scope.category === 'job_parts_available' ||
                scope.category === 'job_parts_available_with_stock' ||
                scope.category === 'job_parts_picked_up' ||
                scope.category === 'job_parts_scheduled' ||
                scope.category === 'job_parts_progress' ||
                scope.category === 'job_parts_installed' ||
                scope.category === 'job_parts_cancelled' ||
                scope.category === 'job_parts_returned'  ||
                scope.category === 'job_assigned_todos' ||
                scope.category === 'job_estimate_costs' ||
                scope.category === 'job_cost_supplier_returns');
            // update the pined rows store

            for (var i = 0, l = scope.rows.length; i < l; i++) {
                if(scope.rows[i].pinned == true) {
                    var original_index_of_row;

                    if(scope.supports_associated_rows) {
                        original_index_of_row = scope.rows[i].row[0].row;
                    }
                    else {
                        original_index_of_row = scope.rows[i][0].row;
                        var row_to_push_id = scope.rows[i][0].id;
                    }


                    if(in_job_category) {
                        var part = tableCollection.collection[scope.category].data[original_index_of_row],
                            tmp_data = {};

                        tmp_data.id = part.id;

                        if(scope.category === 'job_assigned_todos') {

                            tmp_data['Type'] = part['Type'];
                            tmp_data['Description'] = part['Description'];

                        } else if(scope.category === 'job_estimate_costs') {
                            tmp_data['supplierId'] = part['supplierId'];
                            tmp_data['jobCostId'] = part['jobCostId'];
                        } else if(scope.category === 'job_cost_supplier_returns'){
                            tmp_data['supplierId'] = part['supplierid'];
                        }else {

                            tmp_data['Quantity'] = part['Quantity'];
                            tmp_data['Part'] = part['Part'];
                            tmp_data['general'] = part['general'];
                            tmp_data['partId'] = part['partId'];
                            tmp_data['partStatus'] = part['Status'];
                            tmp_data['FulfillType'] = part['FulfillType'];

                        }

                        scope.unique_row_references.push({
                            id_reference: part.partId,
                            record: part
                        });


                        scope.rows[i].part = tmp_data;
                    }
                    scope.rows_store_id.push(row_to_push_id);
                    scope.rows_store.push(scope.rows[i]);

                }
            }

            // Check if the user has selected one or more of the same part. Used in fulfil parts for CUI-2669

            if (scope.unique_row_references.length > 1) {
                var array_of_part_ids = _.pluck(scope.unique_row_references, 'id_reference'),
                    id_counts = _.countBy(array_of_part_ids, _.identity);

                array_of_part_ids.map(function(id) {
                    if (id_counts[id] > 1) {
                        var found_record = _.find(scope.unique_row_references, function(record) {
                            return record.id_reference === id
                        });

                        // Note this will push for every occurance
                        $rootScope.smart_table_selected_records_that_are_the_same.push(found_record);
                    }
                });

                $rootScope.smart_table_selected_records_that_are_the_same = _.uniq($rootScope.smart_table_selected_records_that_are_the_same); // Remove duplicates noted above
            }

            $rootScope.$emit('smart_table:pinned_rows_part_category', scope.category);
            $rootScope.rows_store = scope.rows_store;
            $rootScope.rows_store_id = scope.rows_store_id;
            $rootScope.$emit('smart_table:active_row_id', $rootScope.rows_store_id);
            $rootScope.$emit('smart_table:pinned_rows_updated', scope.rows_store);
            scope.handleDragHelper();
        }

        scope.handleDragHelper = function handleDragHelper() {
            dragHelper.setNames('File', 'Files');
            dragHelper.setContextData($rootScope.rows_store);
        };

        /*==========================================================================================
         Handle show more details events
         ==========================================================================================*/
        scope.showMoreDetails = function showMoreDetails() {
            var i = this.$index;
            scope.rows[i].active = true;
            scope.injectMoreDetailPanel(this.$index);
            scope.show_more_details_panel = true;
            scope.insertPageOverlay();
        }

        /*==========================================================================================
         Load the data needed for the more details panel
         ==========================================================================================*/
        scope.injectMoreDetailPanel = function injectMoreDetailPanel(index) {
            scope.appliance_base_details = _.omit(tableCollection.getBasicDetails(scope.category, index), 'id');
            angular.forEach(scope.appliance_base_details, function(val, key) {
                if(scope.appliance_base_details['Fuel type'] != 'Gas Specific'){
                    scope.appliance_base_details = _.omit(scope.appliance_base_details, 'GC Number');
                }
            });
            scope.appliance_title = scope.appliance_base_details.Make + ' ' + scope.appliance_base_details.Model;
            var appliance_extra_details = tableCollection.getExtraDetails(scope.category, index)[0];
            scope.appliance_history = appliance_extra_details.history;

            if (appliance_extra_details.more_details != undefined && appliance_extra_details.more_details.length != 0 ) {
                scope.appliance_has_more_details = true;
                scope.appliance_more_details = appliance_extra_details.more_details;
            }else {
                scope.appliance_has_more_details = false;
            }
        }

        scope.trySidepanel = function triggerSidepanel(e, index, cat) {
            if (e.target.className.indexOf('preview-file-link') > -1) {
                // if user clicks on preview-file-link icon, don't open sidepanel yet
                return false;
            }

            var side_panel_can_open = e.target.hasAttribute('type') != true &&
                e.target.classList.contains('actions-toggle') != true &&
                e.target.classList.contains('actions-dropdown') != true;

            if(side_panel_can_open) {
                if(scope.category == 'notifications') {
                    scope.triggerSidepanel(e, index, cat);
                }
            }
        }

        scope.handleCheckbox = function handleCheckbox(e, index) {
            var side_panel_can_open = scope.selectable_rows &&
                e.target.hasAttribute('type') != true &&
                e.target.classList.contains('actions-toggle') != true &&
                e.target.classList.contains('actions-dropdown') != true

            if(side_panel_can_open) {
                index = this.$index
                // only pin row if the clicked row is not already pinned. If it is pinned then dont do anything
                var row_is_not_pinned = scope.rows[this.$index].pinned === false;
                if (row_is_not_pinned){
                    scope.selectRow(e, index);
                }
            }
        }
        scope.handleRadio = function handleRadio(e, index) {
            var side_panel_can_open = scope.selectable_rows &&
                e.target.hasAttribute('type') != true &&
                e.target.classList.contains('actions-toggle') != true &&
                e.target.classList.contains('actions-dropdown') != true

            if(side_panel_can_open) {
                index = this.$index
                // only pin row if the clicked row is not already pinned. If it is pinned then dont do anything
                var row_is_not_pinned = scope.rows[this.$index].pinned === false;
                if (row_is_not_pinned){
                    scope.selectRow(e, index);
                }
            }
        };

        // initialise instance
        scope.handleTable();
        scope.registerKeyboardListeners();
        scope.setPinnedAttrs();

        $timeout(function(){
          if(! scope.actions_dropdown_col) {
            $(element).addClass('no-actions-col');
          }
          if( scope.with_configurable_columns && scope.rows.length > 0) {
            $(element).addClass('st-with_configurable_columns');
          }
        },10);

        scope.sendJobCertificateEmail = function(val){
            scope.emailJobCertificate({data: val});
        }
    };

    return {
        restrict: 'A',
        templateUrl: 'template/smart_table/table.html',
        controller: SmartTableCtrl,
        link: handleSmartTable,
        scope: {
            jobId: '=',
            emailJobCertificate: '&'
        }
    };
}])
  .directive('actionstemplate', function($compile){
    return {
      restrict: 'E',
      link: function(scope, element, attrs){
        var templateId= attrs.templateId,
          content = $compile($('script#actions_job_parts').html())(scope);
        //console.log('attr', templateId);
        element.html(content);
      }
    };
  });
