commusoftCommon.service('CurrencySymbol', function(){

    var CurrencySymbol = [
        {
            "currency": "United Arab Emirates",
            "code": "AED",
            "symbol": "&#1583;.&#1573;"
        },
        {
            "currency": "Albania Lek",
            "code": "ALL",
            "symbol": "&#76;&#101;&#107;"
        },
        {
            "currency": "Afghanistan Afghani",
            "code": "AFN",
            "symbol": "&#1547;"
        },
        {
            "currency": "Argentina Peso",
            "code": "ARS",
            "symbol": "&#36;"
        },
        {
            "currency": "Aruba Guilder",
            "code": "AWG",
            "symbol": "&#402;"
        },
        {
            "currency": "Australia Dollar",
            "code": "AUD",
            "symbol": "&#36;"
        },
        {
            "currency": "Azerbaijan New Manat",
            "code": "AZN",
            "symbol": "&#1084;&#1072;&#1085;"
        },
        {
            "currency": "Bahamas Dollar",
            "code": "BSD",
            "symbol": "&#36;"
        },
        {
            "currency": "Barbados Dollar",
            "code": "BBD",
            "symbol": "&#36;"
        },
        {
            "currency": "Belarus Ruble",
            "code": "BYR",
            "symbol": "&#112;&#46;"
        },
        {
            "currency": "Belize Dollar",
            "code": "BZD",
            "symbol": "&#66;&#90;&#36;"
        },
        {
            "currency": "Bermuda Dollar",
            "code": "BMD",
            "symbol": "&#36;"
        },
        {
            "currency": "Bolivia Boliviano",
            "code": "BOB",
            "symbol": "&#36;&#98;"
        },
        {
            "currency": "Bosnia and Herzegovina Convertible Marka",
            "code": "BAM",
            "symbol": "&#75;&#77;"
        },
        {
            "currency": "Botswana Pula",
            "code": "BWP",
            "symbol": "&#80;"
        },
        {
            "currency": "Bulgaria Lev",
            "code": "BGN",
            "symbol": "&#1083;&#1074;"
        },
        {
            "currency": "Brazil Real",
            "code": "BRL",
            "symbol": "&#82;&#36;"
        },
        {
            "currency": "Brunei Darussalam Dollar",
            "code": "BND",
            "symbol": "&#36;"
        },
        {
            "currency": "Cambodia Riel",
            "code": "KHR",
            "symbol": "&#6107;"
        },
        {
            "currency": "Canada Dollar",
            "code": "CAD",
            "symbol": "&#36;"
        },
        {
            "currency": "Cayman Islands Dollar",
            "code": "KYD",
            "symbol": "&#36;"
        },
        {
            "currency": "Chile Peso",
            "code": "CLP",
            "symbol": "&#36;"
        },
        {
            "currency": "China Yuan Renminbi",
            "code": "CNY",
            "symbol": "&#165;"
        },
        {
            "currency": "Colombia Peso",
            "code": "COP",
            "symbol": "&#36;"
        },
        {
            "currency": "Costa Rica Colon",
            "code": "CRC",
            "symbol": "&#8353;"
        },
        {
            "currency": "Croatia Kuna",
            "code": "HRK",
            "symbol": "&#107;&#110;"
        },
        {
            "currency": "Cuba Peso",
            "code": "CUP",
            "symbol": "&#8369;"
        },
        {
            "currency": "Czech Republic Koruna",
            "code": "CZK",
            "symbol": "&#75;&#269;"
        },
        {
            "currency": "Denmark Krone",
            "code": "DKK",
            "symbol": "&#107;&#114;"
        },
        {
            "currency": "Dominican Republic Peso",
            "code": "DOP",
            "symbol": "&#82;&#68;&#36;"
        },
        {
            "currency": "East Caribbean Dollar",
            "code": "XCD",
            "symbol": "&#36;"
        },
        {
            "currency": "Egypt Pound",
            "code": "EGP",
            "symbol": "&#163;"
        },
        {
            "currency": "El Salvador Colon",
            "code": "SVC",
            "symbol": "&#36;"
        },
        {
            "currency": "Estonia Kroon",
            "code": "EEK",
            "symbol": "&#107;&#114;"
        },
        {
            "currency": "Euro Member Countries",
            "code": "EUR",
            "symbol": "&#8364;"
        },
        {
            "currency": "Falkland Islands (Malvinas) Pound",
            "code": "FKP",
            "symbol": "&#163;"
        },
        {
            "currency": "Fiji Dollar",
            "code": "FJD",
            "symbol": "&#36;"
        },
        {
            "currency": "Ghana Cedis",
            "code": "GHC",
            "symbol": "&#162;"
        },
        {
            "currency": "Gibraltar Pound",
            "code": "GIP",
            "symbol": "&#163;"
        },
        {
            "currency": "Guatemala Quetzal",
            "code": "GTQ",
            "symbol": "&#81;"
        },
        {
            "currency": "Guernsey Pound",
            "code": "GGP",
            "symbol": "&#163;"
        },
        {
            "currency": "Guyana Dollar",
            "code": "GYD",
            "symbol": "&#36;"
        },
        {
            "currency": "Honduras Lempira",
            "code": "HNL",
            "symbol": "&#76;"
        },
        {
            "currency": "Hong Kong Dollar",
            "code": "HKD",
            "symbol": "&#36;"
        },
        {
            "currency": "Hungary Forint",
            "code": "HUF",
            "symbol": "&#70;&#116;"
        },
        {
            "currency": "Iceland Krona",
            "code": "ISK",
            "symbol": "&#107;&#114;"
        },
        {
            "currency": "India Rupee",
            "code": "INR",
            "symbol": "&#8377;"
        },
        {
            "currency": "Indonesia Rupiah",
            "code": "IDR",
            "symbol": "&#82;&#112;"
        },
        {
            "currency": "Iran Rial",
            "code": "IRR",
            "symbol": "&#65020;"
        },
        {
            "currency": "Isle of Man Pound",
            "code": "IMP",
            "symbol": "&#163;"
        },
        {
            "currency": "Israel Shekel",
            "code": "ILS",
            "symbol": "&#8362;"
        },
        {
            "currency": "Jamaica Dollar",
            "code": "JMD",
            "symbol": "&#74;&#36;"
        },
        {
            "currency": "Japan Yen",
            "code": "JPY",
            "symbol": "&#165;"
        },
        {
            "currency": "Jersey Pound",
            "code": "JEP",
            "symbol": "&#163;"
        },
        {
            "currency": "Kazakhstan Tenge",
            "code": "KZT",
            "symbol": "&#1083;&#1074;"
        },
        {
            "currency": "Korea (North) Won",
            "code": "KPW",
            "symbol": "&#8361;"
        },
        {
            "currency": "Korea (South) Won",
            "code": "KRW",
            "symbol": "&#8361;"
        },
        {
            "currency": "Kyrgyzstan Som",
            "code": "KGS",
            "symbol": "&#1083;&#1074;"
        },
        {
            "currency": "Laos Kip",
            "code": "LAK",
            "symbol": "&#8365;"
        },
        {
            "currency": "Latvia Lat",
            "code": "LVL",
            "symbol": "&#76;&#115;"
        },
        {
            "currency": "Lebanon Pound",
            "code": "LBP",
            "symbol": "&#163;"
        },
        {
            "currency": "Liberia Dollar",
            "code": "LRD",
            "symbol": "&#36;"
        },
        {
            "currency": "Lithuania Litas",
            "code": "LTL",
            "symbol": "&#76;&#116;"
        },
        {
            "currency": "Macedonia Denar",
            "code": "MKD",
            "symbol": "&#1076;&#1077;&#1085;"
        },
        {
            "currency": "Malaysia Ringgit",
            "code": "MYR",
            "symbol": "&#82;&#77;"
        },
        {
            "currency": "Mauritius Rupee",
            "code": "MUR",
            "symbol": "&#8360;"
        },
        {
            "currency": "Mexico Peso",
            "code": "MXN",
            "symbol": "&#36;"
        },
        {
            "currency": "Mongolia Tughrik",
            "code": "MNT",
            "symbol": "&#8366;"
        },
        {
            "currency": "Mozambique Metical",
            "code": "MZN",
            "symbol": "&#77;&#84;"
        },
        {
            "currency": "Namibia Dollar",
            "code": "NAD",
            "symbol": "&#36;"
        },
        {
            "currency": "Nepal Rupee",
            "code": "NPR",
            "symbol": "&#8360;"
        },
        {
            "currency": "Netherlands Antilles Guilder",
            "code": "ANG",
            "symbol": "&#402;"
        },
        {
            "currency": "New Zealand Dollar",
            "code": "NZD",
            "symbol": "&#36;"
        },
        {
            "currency": "Nicaragua Cordoba",
            "code": "NIO",
            "symbol": "&#67;&#36;"
        },
        {
            "currency": "Nigeria Naira",
            "code": "NGN",
            "symbol": "&#8358;"
        },
        {
            "currency": "Korea (North) Won",
            "code": "KPW",
            "symbol": "&#8361;"
        },
        {
            "currency": "Norway Krone",
            "code": "NOK",
            "symbol": "&#107;&#114;"
        },
        {
            "currency": "Oman Rial",
            "code": "OMR",
            "symbol": "&#65020;"
        },
        {
            "currency": "Pakistan Rupee",
            "code": "PKR",
            "symbol": "&#8360;"
        },
        {
            "currency": "Panama Balboa",
            "code": "PAB",
            "symbol": "&#66;&#47;&#46;"
        },
        {
            "currency": "Paraguay Guarani",
            "code": "PYG",
            "symbol": "&#71;&#115;"
        },
        {
            "currency": "Peru Nuevo Sol",
            "code": "PEN",
            "symbol": "&#83;&#47;&#46;"
        },
        {
            "currency": "Philippines Peso",
            "code": "PHP",
            "symbol": "&#8369;"
        },
        {
            "currency": "Poland Zloty",
            "code": "PLN",
            "symbol": "&#122;&#322;"
        },
        {
            "currency": "Qatar Riyal",
            "code": "QAR",
            "symbol": "&#65020;"
        },
        {
            "currency": "Romania New Leu",
            "code": "RON",
            "symbol": "&#108;&#101;&#105;"
        },
        {
            "currency": "Russia Ruble",
            "code": "RUB",
            "symbol": "&#1088;&#1091;&#1073;"
        },
        {
            "currency": "Saint Helena Pound",
            "code": "SHP",
            "symbol": "&#163;"
        },
        {
            "currency": "Saudi Arabia Riyal",
            "code": "SAR",
            "symbol": "&#65020;"
        },
        {
            "currency": "Serbia Dinar",
            "code": "RSD",
            "symbol": "&#1044;&#1080;&#1085;&#46;"
        },
        {
            "currency": "Seychelles Rupee",
            "code": "SCR",
            "symbol": "&#8360;"
        },
        {
            "currency": "Singapore Dollar",
            "code": "SGD",
            "symbol": "&#36;"
        },
        {
            "currency": "Solomon Islands Dollar",
            "code": "SBD",
            "symbol": "&#36;"
        },
        {
            "currency": "Somalia Shilling",
            "code": "SOS",
            "symbol": "&#83;"
        },
        {
            "currency": "South Africa Rand",
            "code": "ZAR",
            "symbol": "&#82;"
        },
        {
            "currency": "Korea (South) Won",
            "code": "KRW",
            "symbol": "&#8361;"
        },
        {
            "currency": "Sri Lanka Rupee",
            "code": "LKR",
            "symbol": "&#8360;"
        },
        {
            "currency": "Sweden Krona",
            "code": "SEK",
            "symbol": "&#107;&#114;"
        },
        {
            "currency": "Switzerland Franc",
            "code": "CHF",
            "symbol": "&#67;&#72;&#70;"
        },
        {
            "currency": "Suriname Dollar",
            "code": "SRD",
            "symbol": "&#36;"
        },
        {
            "currency": "Syria Pound",
            "code": "SYP",
            "symbol": "&#163;"
        },
        {
            "currency": "Taiwan New Dollar",
            "code": "TWD",
            "symbol": "&#78;&#84;&#36;"
        },
        {
            "currency": "Thailand Baht",
            "code": "THB",
            "symbol": "&#3647;"
        },
        {
            "currency": "Trinidad and Tobago Dollar",
            "code": "TTD",
            "symbol": "&#84;&#84;&#36;"
        },
        {
            "currency": "Turkey Lira",
            "code": "TRY",
            "symbol": "&#8378;"
        },
        {
            "currency": "Turkey Lira",
            "code": "TRL",
            "symbol": "&#8356;"
        },
        {
            "currency": "Tuvalu Dollar",
            "code": "TVD",
            "symbol": "&#36;"
        },
        {
            "currency": "Ukraine Hryvna",
            "code": "UAH",
            "symbol": "&#8372;"
        },
        {
            "currency": "United Kingdom Pound",
            "code": "GBP",
            "symbol": "&#163;"
        },
        {
            "currency": "United States Dollar",
            "code": "USD",
            "symbol": "&#36;"
        },
        {
            "currency": "Uruguay Peso",
            "code": "UYU",
            "symbol": "&#36;&#85;"
        },
        {
            "currency": "Uzbekistan Som",
            "code": "UZS",
            "symbol": "&#1083;&#1074;"
        },
        {
            "currency": "Venezuela Bolivar",
            "code": "VEF",
            "symbol": "&#66;&#115;"
        },
        {
            "currency": "Viet Nam Dong",
            "code": "VND",
            "symbol": "&#8363;"
        },
        {
            "currency": "Yemen Rial",
            "code": "YER",
            "symbol": "&#65020;"
        },
        {
            "currency": "Zimbabwe Dollar",
            "code": "ZWD",
            "symbol": "&#90;&#36;"
        }
    ];
    this.getCurrencySymbol = function () {
        return CurrencySymbol;
    }
});