commusoftCommon.filter('twoDecimalPlaces', function() {
    return function(input, scope) {
        var number_needs_rounding = input % 1 !== 0;

        if(number_needs_rounding) {
            return input.toFixed(2);
        }else {
            return input;
        }
    }
});