csmodule.controller('addNewContact', ['$scope', '$rootScope', 'addNewContact', 'prefix', '$http', function($scope, $rootScope, addNewContact, prefix, $http) {
	/*==========================================================================================
    Initial Variables
  ==========================================================================================*/

  $scope.setDefaultFormModels = function setDefaultFormModels() {
	  $scope.add_new_contact_inputs = { branch: "", title: "", name: "", surname: "", position: "", landline: "", telephone_code: "", mobile: "", mobile_code: "", email: "", login_access: "" };
	}

    $scope.$watch('add_new_contact_inputs', function (newVal, oldVal) {
        if (newVal) {
            var id = $scope.propertyId;
            $http.get(prefix + '/diary/customer/details?id=' + id).success(function (data) {
                $scope.branches = data.branches;
                $scope.titlesList = data.titlesList;
                $scope. add_new_contact_inputs.title= $scope.titlesList[0].id;
            });
        }
    });

  // Default form models
  $scope.setDefaultFormModels();

	/*==========================================================================================
		Save contact
	==========================================================================================*/
    $scope.saveContact = function saveContact() {
        $scope.add_new_contact_inputs.telephone_code = document.getElementById('telephone_code')['defaultValue'];
        $scope.add_new_contact_inputs.mobile_code = document.getElementById('mobile_code')['defaultValue'];
        //$scope.add_new_contact_inputs.title = document.getElementById('title')['value'];
        var deferred = addNewContact.saveContact($scope.add_new_contact_inputs, $scope.propertyId);
        $scope.$emit('add_new_contact:pending', deferred);
    }
}]);
