commusoftCommon.service('feedbackBox', function($timeout, $rootScope) {
    this.elm = $('#feedback-box');
    this.message_wrapper = this.elm.find('.text');
    $rootScope.allow_toast_box_to_close = true;

    this.setFeedback = function setFeedback(text) {
        $rootScope.allow_toast_box_to_close = true;
        this.message_wrapper.html(text);
    }

    var self = this;
    this.showFeedback = function showFeedback() {
        this.elm.fadeIn(200);
        this.hideFeedback();
    }
    this.hideFeedback = function hideFeedback() {
        $timeout(function(){
            var allowed_to_close = $rootScope.allow_toast_box_to_close === true;
            if (allowed_to_close) {
                self.elm.fadeOut(300);
            }
        }, 1000);
    }
    $rootScope.$on('closeFeedbackBox', function(){
        $rootScope.allow_toast_box_to_close = true;
        self.elm.fadeOut(300);
    });
});