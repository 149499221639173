'use strict';
angular.module('csDynamicForm')
  .controller('DynamicFormMainCtrl', [
    '$scope',
    '$state',
    '$timeout',
    '$modal',
    'dynamicElementsService',
    'dfDragElementService',
    'dynamicActionService',
    'dfDataManipulationService',
    function($scope, $state, $timeout, $modal, dynamicElementsService, dfDragElementService, dynamicActionService,dfDataManipulationService){
      var modalActions = dynamicActionService.getModalActions();
      $scope.isStandardAsset = false;
      if ($state.current.name.indexOf('settings_standard_asset') > -1) {
        $scope.isStandardAsset = true;
      }
      $scope.cerficateInputTypes = dynamicElementsService.getInputTypes();
      $scope.elementTypesList = dynamicElementsService.getElementTypes($scope.isStandardAsset);
      $scope.selectedElement = null;
      $scope.showNoContent = true;
      $scope.showFieldPlaceholder = false;
      $scope.sectionTypeClass = 'section';
      $scope.updateModel = updateModel;

      $scope.dndAllowableField = [
        'self',
        'text',
        'date',
        'time',
        'checkbox',
        'number',
        'decimal',
        'image',
        'checkboxlist',
        'dropdownlist',
        'radiolist',
        'dynamic_dropdown',
        'toggle',
        'predefined_toggle',
        'barcode',
        'textarea',
        'nested_dropdown',
        'formula',
        'column',
        'user_field'
      ];
      $scope.dndAllowableFieldWithoutColumn = function() {
        var allowedFields = angular.copy($scope.dndAllowableField),
          colIndex = allowedFields.indexOf('column');
        allowedFields.splice(colIndex,1);
        return allowedFields;
      };
      $scope.expandCollapToggle = {};

      $scope.model = {
        sections: [
          {
            id:1,
            typeText: 'Section',
            type: 'section',
            sectionType: 'standard',
            title: 'Untitled',
            description: '',
            dependencies: [],
            screenType: $scope.screenType,
            fields: [],
            sectionID: dynamicElementsService.getUniqueId()
          }
        ]
      };

      $scope.isDeletedAsset = function(item) {
        if(item.referenceId==='') {
          return false;
        }
        if(item.type === 'assets') {
          var exists = _.find($scope.assetFields, function(x){
            return item.referenceId == x.id;
          });
          if(!exists) {
            return true;
          }
          return false;
        }
        return false;
      };

      $scope.$on("Dynamic_Form_Inputs", function (event, message) {
          $scope.model = message.data;
          $scope.dynamicFields = message.dynamicFields;
          $scope.assetFields = message.assetFields;
          $scope.engineerFields = message.engineerFields;
          $scope.standardRecordFields = (typeof message.standardRecordFields != 'undefined') ? message.standardRecordFields : [];
          $scope.groupRecordField = (typeof message.groupRecordField != 'undefined') ? message.groupRecordField : [];
      });

      $scope.getDynamicFieldName = function(element) {
        if( parseInt(element.selectedOption ) > 0) {
          var nameObj = _.find($scope.dynamicFields, function(x){
            return x.id == element.selectedOption;
          });
          return nameObj ? nameObj.name : false;
        }
        else {
          return false;
        }
      };

      $scope.getOptionsTypeItems = function(element) {
        if(_.contains(['checkboxlist','dropdownlist','radiolist','toggle','nested_dropdown'],element.type) && element.items.length===0 && !element.isnew) {
          return true;
        }
        return false;
      };

      $scope.isElementValueType = dynamicElementsService.isElementValueType;

      $scope.onItemInitCallback = function(item) {
        if(item.type === 'nested_dropdown') {
          //item.type = DF_CONFIG_FIELDSTYPE.nested_dropdown;
        }
        if(item.type === 'dynamic_dropdown') {
          var options = _.find($scope.dynamicFields, function(x) {
            return x.id == item.selectedOption;
          });
          item.itemsCount = 0;
          if(options) {
            item.itemsCount = _.has(options,'items') ? options.items.length : 0;
          }
        }
      };

      $scope.showItemsAction = function() {

      };
      $scope.sectionEditMode = function(section) {
        section.editMode=true;
      };

      $scope.hasKey = function(object,key) {
        return object.hasOwnProperty(key);
      };

      $scope.size = function(obj) {
        return _.size(obj);
      };

      $scope.filtersCount = function(item){
        if(!_.has(item, 'filters')) {
          item.filters = {};
        }
        return _.isEmpty(item.filters) ? 0 : 1;
      };

      $scope.dropFieldCallback = function(index, item, external, type) {
        //console.log('dropped field', index, item, external, type);
        if(type != 'self') {
          item.isnew = true;
          item.questionID = dynamicElementsService.getUniqueId();
        }
        /*if(type === 'dynamic_dropdown') {
          item.dynamicOptions=$scope.dynamicFields;
        }*/
        return item;
      };

      $scope.insertedSection = function(items, index, external, type) {
        if(type === 'section-self') {
          updateModel();
        }
      };

      $scope.insertedField = function(section, index, external, type) {
        var item = section.fields[index]; //console.log('type', type, item);
        $scope.showFieldPlaceholder = false;
        if(type !== 'self' && type !== 'column') {
          $scope.modalOpen('add_element', item, index, section, type, 'section');
        }
        else {
          updateModel();
        }
      };

      //column layout insert callback
      $scope.insertedFieldInCol = function(columnField, itemIndex, external, type) {
        var item = columnField[itemIndex];
        $scope.showFieldPlaceholder = false;
        if(type !== 'self') {
          $scope.modalOpen('add_element', item, itemIndex, columnField, type, 'column');
        }
        else {
          updateModel();
        }
      };

      $scope.isFlags = function(item) {
        if( _.isUndefined(item.flag) || item.flag === false ) {
          return false;
        }
        return true;
      };

      //section dropped callback
      $scope.dropSectionCallback = function(index, section, external, type) {
        if(type === 'section' || type === 'assets') {
          var id = type+'ID';
          section[id] = dynamicElementsService.getUniqueId();
          $scope.modalOpen('add_section_action', null, index, section, type);
        }
        section.screenType = $scope.screenType;
        return section;
      };

      $scope.onDragStart = function(item, event, itemType) {
        var el = dfDragElementService.displayDrag(item, event, $scope, itemType);
        event.dataTransfer.setDragImage(el, 10, 0);
        if(item.type != 'section') {
          $scope.showNoContent = false;
          $scope.showFieldPlaceholder = true;
        }

      };
      $scope.onDragEnd = function() {
        dfDragElementService.removeDrag();
      };

      $scope.onMousemove = function(event) {
      };

      $scope.dragoverCallback = function(event, index, external, type) {
        return index > -1;
      };

      $scope.dragOverSection = function(index,external, type) {
        $scope.sectionTypeClass = type;
        return true;
      };

      $scope.onDropCancel = function(){
        $scope.showNoContent = $scope.model.sections.length === 0 ? true : false;
        $scope.showFieldPlaceholder = false;
      };

      $scope.logEvent = function(message) {
        //console.log(message);
      };

      $scope.isShowDependencyAction = function(field) {
        if($scope.hasKey(field, 'dependencies') ) {
          return true;
        }
        return false;
      };

      $scope.isShowValidationAction = function(field) {
        if($scope.hasKey(field, 'validations') && field.type !=='barcode' ) {
          return true;
        }
        return false;
      };

      $scope.isShowFormatAction = function(field) {
        var f = ['date','time', 'decimal'];
        return _.indexOf(f, field.type) > -1;
      };

      $scope.isShowPropertiesAction = function(field){
        var f = ['barcode','image'];
        return _.indexOf(f, field.type) > -1;
      };

      $scope.isShowGroupOptionsAction = function(field){
        var f = ['nested_dropdown'];
        return _.indexOf(f, field.type) > -1;
      };

      $scope.isShowOptionsAction = function(field) {
        var f = [
          'dynamic_dropdown',
          'checkboxlist',
          'dropdownlist',
          'radiolist',
          'toggle',
          'predefined_toggle'];
        return _.indexOf(f, field.type) > -1;
      };

      $scope.isShowDefaultAction = function(field) {
        var f = [
          'text',
          'textarea',
          'checkbox',
          'number',
          'decimal',
          'date',
          'time',
          'checkboxlist',
          'dropdownlist',
          'radiolist',
          'toggle',
          'user_field',
          'predefined_toggle'];
        return _.indexOf(f, field.type) > -1;
      };

      $scope.isShowFormulaAction = function(field) {
        var f = ['formula'];
        return _.indexOf(f, field.type) > -1;
      };

      $scope.sectionTitleUpdate = function(section) {
        if(section.title === '') {
          section.title = 'Untitled';
        }
        section.editMode=false;
      };

      /*modal section */
      $scope.modalOpen = function(action, item, index, fieldSection, type, layout) {
        if(fieldSection &&
          action==='add_section_action' &&
          fieldSection.type==='assets' &&
          type === 'edit_section' &&
          $scope.isDeletedAsset(fieldSection)) {
          return;
        }
        var $_action = modalActions[action], _layout = layout || 'section';
        var modalInstance = $modal.open({
          windowClass: $_action.modalClass ? $_action.modalClass : '',
          templateUrl: $_action.templateUrl,
          controller: $_action.controller,
          keyboard: false,
          resolve: {
            parentItems: function() {
              return {
                dataModal: $scope.model,
                activeAction: $_action,
                currentIndex: index,
                currentAction: action,
                currentItem: item,
                currentSection: fieldSection,
                type: type,
                layout: _layout,
                assetFields: $scope.assetFields,
                dynamicFields: $scope.dynamicFields,
                engineerFields: $scope.engineerFields,
                standardRecordFields: $scope.standardRecordFields,
                groupRecordField: $scope.groupRecordField,
                updateModelCallback: $scope.updateModel
              }
            }
          }
        });
        modalInstance.result.then(
        function(activeItem){
          var extraData = {};
          if(_.has(activeItem,'extraData')) {
            extraData = activeItem['extraData'];
            delete activeItem['extraData'];
          }
          $scope.lastActionItem = activeItem;
          switch (activeItem.currentAction) {
            case 'delete_action':
              // if( _.size(activeItem.currentItem.dependencies) > 0) {
              //   delete scope.model.depRules[activeItem.currentItem.sectionID];
              // }
              activeItem.currentSection.fields.splice(activeItem.currentIndex, 1);
            break;
            case 'remove_section_action':
              // if( _.size(activeItem.currentSection.dependencies) > 0) {
              //   delete $scope.model.depRules[activeItem.currentSection.sectionID];
              // }
              $scope.model.sections.splice(activeItem.currentIndex, 1);
            break;
          }
          $scope.onDropCancel();
          updateModel(extraData);
        },
        function(activeItem){
          switch (activeItem.currentAction) {
            case 'add_element':
              if(activeItem.type === 'edit_element') {
                return;
              }
              if(activeItem.layout === 'section') {
                activeItem.currentSection.fields.splice(activeItem.currentIndex, 1);
              }
              else {
                activeItem.currentSection.splice(activeItem.currentIndex, 1);
              }
              break;
            case 'add_section_action':
              if(activeItem.type === 'edit_section') {
                return;
              }
              $scope.model.sections.splice(activeItem.currentIndex, 1);
              break;
          }
          $scope.onDropCancel();
        });
      };

      $scope.resolveTemplate = function(type) {
        var tpl = type === 'column' ? 'col-container' : 'container';
        return '/template/dynamic-form/'+tpl+'.html';
      };

      function updateModel(extraData, updatedField) {
        $timeout(function(){
          $scope.model.viewElements = dfDataManipulationService.formatViewElements(angular.copy($scope.model));
          $scope.model.subAssets = dfDataManipulationService.formatSubAssets(angular.copy($scope.model));

          if(!_.isUndefined(updatedField)) {
            findAndReplaceModel($scope.model.sections, updatedField.questionID, updatedField.items);
          }
          var postData={
            data: $scope.model
          };
          if(!_.isUndefined(extraData) && _.isObject(extraData)) {
            if(_.has(extraData,'subAssetDeletedId')) {
              postData.subAssetDeleted = extraData.subAssetDeletedId;
            }
            else {
              postData.dynamicDropDownDependencies = extraData;
            }
          }
          console.log('extraData', postData);
          $scope.$emit("Dynamic_Form_Outputs", postData);
          // $scope.$emit("Dynamic_Form_Outputs", {
          //   data: $scope.model,
          //   subAssetDeleted: '',
          //   dynamicDropDownDependencies: _.isUndefined(extraData) ? {} : extraData
          // });
          console.log('Model', $scope.model);
        },400);

      }
      function findAndReplaceModel(sections, questionID, replaceItems) {
        for(var x in sections) {
          for(var y in sections[x]['fields']) {
            if(sections[x]['fields'][y]['type'] === 'column') {
              findAndReplaceModel(sections[x]['fields'][y]['columns'], questionID, replaceItems);
            }
            else {
              if(sections[x]['fields'][y]['questionID'] === questionID) {
                sections[x]['fields'][y]['items'] = replaceItems;
                break;
              }
            }
          }
        }
      }
  }])
  .controller('DynamicFormModalAddSectionCtrl',[
    '_',
    '$scope',
    '$modalInstance',
    'dynamicElementsService',
    'dfConfirmationToast',
    '$timeout',
    'dfDataManipulationService',
    'parentItems', function(_,$scope, $modalInstance, dynamicElementsService, dfConfirmationToast, $timeout, dfDataManipulationService, parentItems){
      $scope.parentData = parentItems;
      $scope.inputs = {};
      console.log('$scope.parentData', $scope.parentData);
      $scope.inputs.title = $scope.parentData.currentSection.title==='Untitled' ? '' : $scope.parentData.currentSection.title;
      $scope.inputs.description = $scope.parentData.currentSection.description;
      $scope.inputs.items = _.has($scope.parentData.currentSection,'items') ?
          angular.copy($scope.parentData.currentSection.items) : $scope.parentData.assetFields;
      $scope.inputs.referenceId = _.has($scope.parentData.currentSection,'referenceId') ?
          $scope.parentData.currentSection.referenceId : '';
      $scope.inputs.referenceData = _.has($scope.parentData.currentSection, 'referenceData') ?
          angular.copy($scope.parentData.currentSection.referenceData) : {};
      $scope.inputs.showStandardFields = _.has($scope.parentData.currentSection, 'showStandardFields') ?
        $scope.parentData.currentSection.showStandardFields : false;
      $scope.existsAssetRef=[];
      $scope.isEdit = $scope.parentData.type === 'edit_section' ? true : false;

      if($scope.parentData.currentSection.type === 'assets') {
        $scope.existsAssetRef = dfDataManipulationService.getExistsAssetReferenceIs($scope.parentData.dataModal);
      }
      $scope.isExistsRef = function(id) {

        let checkVal = _.contains($scope.existsAssetRef, id);
        return checkVal && $scope.inputs.referenceId != id ? true : false;
      };

      if($scope.isEdit===true) {
        $scope.title = 'Update';
      }
      else {
        $scope.title = 'Add';
      }
      $scope.title += $scope.parentData.currentSection.type === 'section' ? ' section' : ' asset';

      $scope.onChangeAsset = function() {
        var selectedItem = _.find($scope.inputs.items, function(x){
          return x.id == $scope.inputs.referenceId;
        });
        if(selectedItem) {
          $scope.inputs.title = selectedItem.name;
          $scope.inputs.referenceData = selectedItem.data;
        }
      };

      $scope.onSubmit = function() {
        if(_.isEmpty($scope.inputs.title)) {
          return false;
        }
        $scope.parentData.currentSection.title = $scope.inputs.title;
        if($scope.parentData.currentSection.type === 'section'){
          $scope.parentData.currentSection.description = $scope.inputs.description;
        }
        else {
          $scope.parentData.currentSection.referenceId = parseInt($scope.inputs.referenceId);
          $scope.parentData.currentSection.referenceData = $scope.inputs.referenceData;
          $scope.parentData.currentSection.showStandardFields = $scope.inputs.showStandardFields;
        }
        $modalInstance.close($scope.parentData);
      };

      $scope.onClose = function() {
        $modalInstance.dismiss($scope.parentData);
      };

      $modalInstance.opened.then(function(isOpened) {
        if (isOpened) {
          dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
          $timeout(function(){
            var $element = $(document);
            $element.bind('keydown', function(e){
              if(e.which === 13) {
                $scope.onSubmit();
              }
            });
          },300);
        }
      });
      $scope.$on('$destroy', function(){
        $(document).unbind('keydown');
        $('body').find('.modal-backdrop').off('click');
      });

  }])
  .controller('DynamicFormModalAddValidationsCtrl',[
    '$scope',
    '$modalInstance',
    '$timeout',
    'dfConfirmationToast',
    'dynamicElementsService',
    'parentItems', function($scope, $modalInstance, $timeout, dfConfirmationToast, dynamicElementsService, parentItems){
      $scope.parentData = parentItems;
      $scope.elemId = 'add-validation-modal';
      $scope.validationList = dynamicElementsService.getValidationTypes();
      $scope.currentValidateItem = $scope.validationList[$scope.parentData.currentItem.type];
      if(!$scope.currentValidateItem) {
        $scope.currentValidateItem = [];
      }
      $scope.inputs = {};
      $scope.inputs.assignValidations = angular.copy($scope.parentData.currentItem.validations);
      $scope.inputs.modelVal=[];
      $scope.inputs.selectedItem=[];
      $scope.disableAddBtn = true;

      //while update
      if($scope.inputs.assignValidations.length > 0) {
        _.forEach($scope.inputs.assignValidations, function(item){
          $scope.inputs.modelVal.push(item.id);
        });
      }

      $scope.validateAddItem = function(){
        //$scope.disableAddBtn = $scope.inputs.assignValidations.length !== $scope.currentValidateItem.length;
        var v=[];
        _.forEach($scope.inputs.assignValidations, function(x){
          if(_.has(x,'require_input') && (x.require_input===true || x.require_input==='checkbox')) {
            v.push( _.isEmpty(x.rule) ? false : true);
          }
        });
        if(v.length > 0 && _.contains(v,false)) {
          $scope.disableAddBtn=true;
        }
        else {
          $scope.disableAddBtn=false;
        }
      };
      $scope.validateAddItem();

      $scope.checkedRule = function(key, value, checked) {
        var idx = $scope.inputs.assignValidations[key].rule.indexOf(value);
        if (idx >= 0 && !checked) {
          $scope.inputs.assignValidations[key].rule.splice(idx, 1);
        }
        if (idx < 0 && checked) {
          $scope.inputs.assignValidations[key].rule.push(value);
        }
        $scope.validateAddItem();
      };

      $scope.addNewItem = function(){
        if(!$scope.disableAddBtn) {
          //return;
        }
        if($scope.currentValidateItem.length === 0) {
          return;
        }
        //var index = $scope.inputs.assignValidations.length;
        //var contains = _.pluck($scope.inputs.assignValidations,'id');
        //console.log($scope.inputs.modelVal)
        var addItems = _.filter(angular.copy($scope.currentValidateItem), function(x){
            return !_.contains($scope.inputs.modelVal, x.id);
        });

        if(addItems.length > 0) {
          $scope.inputs.modelVal.push(addItems[0].id);
          $scope.inputs.assignValidations.push(addItems[0]);
          $scope.validateAddItem();
        }

      };

      $scope.hasRequiredInput = function(index) {
        var assignedValidations = $scope.inputs.assignValidations[index];
        if(!_.isUndefined(assignedValidations) && _.has(assignedValidations, 'require_input')) {
          return true;
        }
        return false;
      };

      $scope.onChangeItem = function(index, item) {
        $scope.inputs.modelVal[index] = item;
        var changedItem = _.find($scope.currentValidateItem, function(x){
          return item === x.id;
        });
        $scope.inputs.assignValidations[index] = changedItem;
        $scope.validateAddItem();

        //console.log($scope.inputs.modelVal[index])
        // $scope.inputs.assignValidations[index] = _.find($scope.currentValidateItem, function(x){
        //   return selectedItem === x.id;
        // });
        // console.log('$scope.inputs.assignValidations', $scope.inputs.assignValidations)
        //console.log('inputs.selectedItem',$scope.inputs.assignValidations, changedItem);
      };

      $scope.onRemoveItem = function(index) {
        $scope.inputs.modelVal.splice(index, 1);
        $scope.inputs.assignValidations.splice(index, 1);
        $scope.validateAddItem();
      };

      $scope.onSubmit = function() {
        $scope.parentData.currentItem.validations = $scope.inputs.assignValidations;
        $modalInstance.close($scope.parentData);
      };

      $scope.onClose = function() {
        $modalInstance.dismiss($scope.parentData);
      };

      $scope.checkExists = function(value){
        return _.find($scope.inputs.assignValidations, function(x){
          return x.id === value;
        });
      };

      $modalInstance.opened.then(function(isOpened) {
        if (isOpened) {
          dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
          $timeout(function(){
            var $element = $(document);
            $element.bind('keydown', function(e){
              if(e.which === 13) {
                $scope.onSubmit();
              }
            });
          },300);
        }
      });
      $scope.$on('$destroy', function(){
        $(document).unbind('keydown');
        $('#'+$scope.elemId).unbind('keydown');
        $('body').find('.modal-backdrop').off('click');
      });
  }])
  .controller('DynamicFormModalFormulasCtrl',[
    '_',
    '$scope',
    '$modalInstance',
    'dynamicElementsService',
    'dfDataManipulationService',
    'dfConfirmationToast',
    'parentItems', function(_, $scope, $modalInstance, dynamicElementsService, dfDataManipulationService, dfConfirmationToast, parentItems){
    $scope.id = 'cs_df_addFormulaModal';
    $scope.filter= {
      itemQuery: {},
      itemQueryBy: 'fieldLabel'
    };
    $scope.inputs = {};
    $scope.parentData = parentItems;
    $scope.inputs.rules = angular.copy($scope.parentData.currentItem.rules);

    $scope.listOfFormulas = dynamicElementsService.getFormulaTypes();

    var jsonModel = angular.copy(parentItems.dataModal);
    $scope.supportedElements = dfDataManipulationService
      .getSupportedFormulaElement(jsonModel, $scope.parentData.currentItem.mappedFieldType);

    function ruleGroupModel() {
      return [
        {
          type: 'group',
          items: [
            {
              type: 'question',
              questionID: '',
              showCustomValue:false,
              customValue: 0
            },
            {
              type: 'operator',
              operator: 'addition'
            },
            {
              type: 'question',
              questionID: '',
              showCustomValue:false,
              customValue: 0
            },
            {
              type: 'operator',
              operator: 'addition'
            }
          ],
          value: null
        },
        {
          type: 'operator',
          operator: 'addition'
        }
      ];
    }
    //defining default ruleSet
    if($scope.inputs.rules.length === 0) {
      $scope.inputs.rules = ruleGroupModel();
    }

    $scope.addNewRuleGroup = function() {
      var tmpRules = ruleGroupModel();
      $scope.inputs.rules.push(tmpRules[0], tmpRules[1]);
    };
    $scope.removeGroupRule = function(index) {
      $scope.inputs.rules.splice(index, 2);
    };

    $scope.onChangeQuestion = function(val, item) {
      item.questionID  = val;
    };

    $scope.switchToCustomValue = function(item){
      item.showCustomValue = item.questionID === 'custom' ? true: false;
    };

    $scope.onSubmit = function() {
      $scope.parentData.currentItem.rules = $scope.inputs.rules;
      $modalInstance.close($scope.parentData);
    };

    $scope.onClose = function() {
      $modalInstance.dismiss($scope.parentData);
    };

    $modalInstance.opened.then(function(isOpened) {
      if (isOpened) {
        dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
      }
    });
    $scope.$on('$destroy', function(){
      $(document).unbind('keydown');
      $('body').find('.modal-backdrop').off('click');
    });
  }])
  .controller('DynamicFormModalAddGroupOptionsCtrl',[
    '$scope',
    '$rootScope',
    '$translate',
    'dynamicElementsService',
    '$modalInstance',
    '$timeout',
    'confirmationBoxHelper',
    'parentItems',
    function($scope, $rootScope, $translate, dynamicElementsService, $modalInstance, $timeout, confirmationBoxHelper, parentItems) {
      $scope.id = 'cs_df_addGroupItemsModal';
      $scope.parentData = parentItems;
      $scope.panelTitle = parentItems.currentItem.fieldLabel + ' ' + parentItems.currentItem.typeText.toLowerCase();
      $scope.inputs = {};
      $scope.inputModel = {};
      $scope.inputModel.addSubItem = [];
      $scope.inputModel.itemToggle = [];
      $scope.orderBy='text';
      $scope.filter= {
        itemQuery: {},
        itemQueryBy: 'text'
      };
      $scope.groupEdit = {
        id: null,
        text: null
      };
      $scope.itemEdit = {value: null, text: null };
      $scope.groupitems = !$scope.parentData.currentItem.items.length ? [] : angular.copy($scope.parentData.currentItem.items);

      $scope.itemEditAction = function(item) {
        $scope.itemEdit.value = item.value;
        $scope.itemEdit.text = item.text;
      };

      $scope.itemRemoveAction = function(parent, index) {
        parent.children.splice(index, 1);
        $scope.onSubmit(true);
      };

      $scope.itemUpdate = function(item) {
        item.text = $scope.itemEdit.text;
        $scope.cancelUpdate();
      };

      function confirmationToast() {
        confirmationBoxHelper.getConfirmation($translate('Are.you.sure.you.want.to.close.side.panel?'), $rootScope)
          .then($scope.onClose);
      }
      $scope.resetInput = function() {
        $scope.inputModel.addGroupItem = '';

      };
      $scope.resetInput();

      $scope.itemIntialize = function(key) {
        $scope.inputModel.addSubItem[key]='';
        $scope.inputModel.itemToggle[key]=false
      };

      $scope.groupItemEdit = function(groupItem) {
        $scope.groupEdit.id = groupItem.id;
        $scope.groupEdit.text = groupItem.text;
      };
      $scope.cancelUpdate = function(type){
        if(type === 'group') {
          $scope.groupItemEdit({id: null, text: null});
        }
        else {
          $scope.itemEditAction({value: null, text: null });
        }
        $scope.onSubmit(true);
      };
      $scope.groupItemUpdate = function(groupItem) {
        groupItem.text = $scope.groupEdit.text;
        $scope.cancelUpdate('group');
      };
      $scope.removeGroupItem = function(index) {
        $scope.groupitems.splice(index, 1);
        $scope.onSubmit(true);
      };

      $scope.showHideToggle = function(key) {
        $scope.inputModel.itemToggle[key] = $scope.inputModel.itemToggle[key] === false ? true : false;
      };

      $scope.addGroup = function() {
        if($scope.inputModel.addGroupItem === '') {
          return;
        }
        $scope.groupitems.unshift({
          id: dynamicElementsService.getUniqueId(),
          text: $scope.inputModel.addGroupItem,
          children: []
        });
        $scope.resetInput();
        $scope.onSubmit(true);
      };

      $scope.addSubItem = function(children, modelKey) {
        if($scope.inputModel.addSubItem[modelKey] === '') {
          return;
        }
        children.unshift({
          value: dynamicElementsService.getUniqueId(),
          text: $scope.inputModel.addSubItem[modelKey]
        });
        $scope.inputModel.addSubItem[modelKey] = '';
        $scope.onSubmit(true);
      };

      $modalInstance.opened.then(function(isOpened){
        if(isOpened) {
          $timeout(function(){
            var $element = $('#'+$scope.id);
            $element.bind('keydown', function(e){
              if(e.which === 27) {
                confirmationToast();
              }
              else if(e.which === 13) {
                var $element = angular.element(e.target), $parent = $element.parent();

                if($element.hasClass('group-item-input') ) {
                  $scope.addGroup();
                }
                else if( $element.hasClass('sub-item-input') ) {
                  $parent.find('button').triggerHandler('click');
                }
              }
            });
            $('body').find('.modal-backdrop').on('click', function(e){
              e.preventDefault();
              confirmationToast();
            });
          },300);
        }
      });

      $scope.onSubmit = function(updateWithoutClose) {
        var groupItems = _.chain($scope.groupitems)
          .sortBy('text')
          .forEach(function(item){
            item.children = _.sortBy(item.children, 'text');
          })
          .value();
        $scope.parentData.currentItem.items = groupItems;
        if(updateWithoutClose) {
          $scope.parentData.updateModelCallback(undefined, $scope.parentData.currentItem);
        }
        else {
          $modalInstance.close($scope.parentData);
        }
      };

      $scope.onClose = function() {
        $modalInstance.dismiss($scope.parentData);
      };

      $scope.$on('$destroy', function(){
        $('#'+$scope.id).unbind('keydown');
        $('body').find('.modal-backdrop').off('click');
      });
  }])
  .controller('DynamicFormModalAddOptionsCtrl',[
    '$scope',
    '$rootScope',
    '$translate',
    '$modalInstance',
    'confirmationBoxHelper',
    'dynamicElementsService',
    '$timeout',
    'parentItems', function($scope, $rootScope, $translate, $modalInstance,confirmationBoxHelper, dynamicElementsService, $timeout, parentItems) {

    function confirmationToast() {
      confirmationBoxHelper.getConfirmation($translate('Are.you.sure.you.want.to.close.side.panel?'), $rootScope)
        .then($scope.onClose);
    }

    $scope.id = 'cs_df_addItemsModal';
    $scope.parentData = parentItems;
    $scope.panelTitle = parentItems.currentItem.fieldLabel + ' ' + parentItems.currentItem.typeText.toLowerCase();
    $scope.suffix = parentItems.currentItem.type === 'number' ? 'measurements' : 'option';
    $scope.inputPlaceholder = 'Add ' + $scope.suffix;

    $scope.dynamicOptionsData = parentItems.dynamicFields;

    $scope.showActions = function(){
      var list = ['predefined_toggle', 'barcode','dynamic_dropdown'];
      return _.indexOf(list, parentItems.currentItem.type) < 0;
    };

    $scope.showInputSelect = function() {
      var list = ['dynamic_dropdown'];
      return _.indexOf(list, parentItems.currentItem.type) > -1;
    };

    $scope.addItemModel = '';
    $scope.edit = {
      id: null,
      text: null
    };
    $scope.filter= {
      itemQuery: {},
      itemQueryBy: 'description'
    };

    $scope.resetInput = function() {
      $scope.inputs = {
        addItem: '',
        dynamicOption: ''
      };
    };
    $scope.resetInput();
    if($scope.parentData.currentItem.type === 'dynamic_dropdown') {
      $scope.items = [];
      $scope.inputs.dynamicOption = angular.copy($scope.parentData.currentItem.selectedOption);
    }
    else {
      if(!$scope.parentData.currentItem.items.length) {
        $scope.items = [];
      }
      else {
        $scope.items = angular.copy($scope.parentData.currentItem.items);
      }
    }


    $scope.addItem = function() {
      if($scope.inputs.addItem === '') {
        return;
      }
      $scope.items.unshift({
        id: dynamicElementsService.getUniqueId(),
        description: $scope.inputs.addItem
      });
      $scope.onSubmit(true);
      $scope.resetInput();
    };

    $scope.switchDynamicOption = function(){
      if($scope.parentData.currentItem.type !== 'dynamic_dropdown') {
        return;
      }

      if($scope.inputs.dynamicOption !== '') {
        var options = _.find($scope.dynamicOptionsData, function(x){
          return x.id == $scope.inputs.dynamicOption;
        });
        if(options) {
          $scope.items = options.items;
        }
      } else {
          $scope.items = [];
      }
    };
    $scope.switchDynamicOption();

    $modalInstance.opened.then(function(isOpened){
      if(isOpened) {
        $timeout(function(){
          var $element = $(document);
          $element.bind('keydown', function(e){
            if(e.which === 27) {
              confirmationToast();
            }
            else if(e.which === 13) {
              $scope.addItem();
            }
          });
          $('body').find('.modal-backdrop').on('click', function(e){
            e.preventDefault();
            confirmationToast();
          });
        },300);
      }
    });

    $scope.removeItem = function(item) {
      if($scope.items.length < 1) {
        return false;
      }
      var index = _.findIndex($scope.items, function(x){
        return x.description.toLowerCase() === item.description.toLowerCase();
      });
      $scope.items.splice(index, 1);
      $scope.onSubmit(true);
    };

    $scope.editItem = function(item) {
      $scope.edit.id = item.id;
      $scope.edit.text = item.description;
    };

    $scope.cancelUpdate = function(){
      $scope.edit.id = null;
      $scope.edit.text = null;
    };

    $scope.updateItem = function(item) {
      item.description = $scope.edit.text;
      $scope.cancelUpdate();
    };

    $scope.clearEmptyItem = function() {
      angular.forEach($scope.items, function(item, index){
        if(item.description === '') {
          $scope.items.splice(index, 1);
        }
      });
    };

    $scope.onSubmit = function(updateWithoutClose) {
      $scope.clearEmptyItem();
      if($scope.parentData.currentItem.type === 'dynamic_dropdown') {
        $scope.parentData.currentItem.selectedOption = $scope.inputs.dynamicOption;
        $scope.parentData.currentItem.selectedOptionUUID = $scope.parentData.currentItem.selectedOptionUUID;
        $scope.parentData.currentItem.itemsCount = _.size($scope.items);
        if(_.has($scope.parentData.currentItem, 'dynamicOptions')) {
          delete $scope.parentData.currentItem['dynamicOptions'];
        }
        if( _.has($scope.parentData.currentItem, 'items') ) {
          delete $scope.parentData.currentItem['items'];
        }
      }
      else {
        $scope.parentData.currentItem.items = _.sortBy($scope.items, 'description');
      }
      if(updateWithoutClose) {
        $scope.parentData.updateModelCallback(undefined, $scope.parentData.currentItem);
      }
      else {
        $modalInstance.close($scope.parentData);
      }
    };

    $scope.onClose = function() {
      $modalInstance.dismiss($scope.parentData);
    };

    $scope.$on('$destroy', function(){
      $(document).unbind('keydown');
      $('body').find('.modal-backdrop').off('click');
    });
  }])
  .controller('DynamicFormModalPropertiesCtrl',[
    '$scope',
    '$rootScope',
    '$modalInstance',
    'DF_Properties',
    'dfConfirmationToast',
    'parentItems', function($scope, $rootScope, $modalInstance, DF_Properties, dfConfirmationToast, parentItems){

      $scope.id = 'cs_df_propertiesModal';
      $scope.parentData = parentItems;

      $scope.inputs = {};
      $scope.inputs.properties = angular.copy($scope.parentData.currentItem.properties);

      $scope.onSubmit = function() {
        if(_.isEmpty($scope.inputs.properties.width[0]) ) {
          $scope.inputs.properties.width[0] = 0;
        }
        if(_.isEmpty($scope.inputs.properties.height[0]) ) {
          $scope.inputs.properties.height[0] = 0;
        }
        $scope.parentData.currentItem.properties = $scope.inputs.properties;
        $modalInstance.close($scope.parentData);
      };

      $scope.onClose = function() {
        $modalInstance.dismiss($scope.parentData);
      };

      $modalInstance.opened.then(function(isOpened) {
        if (isOpened) {
          dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
        }
      });
      $scope.$on('$destroy', function(){
        $(document).unbind('keydown');
        $('body').find('.modal-backdrop').off('click');
      });
   }])
  .controller('DynamicFormModalDefaultsCtrl',[
    '_',
    '$scope',
    '$modalInstance',
    '$filter',
    'dynamicElementsService',
    'parentItems',
    '$timeout',
    'dfConfirmationToast',function(_, $scope, $modalInstance, $filter, dynamicElementsService, parentItems, $timeout, dfConfirmationToast){
      $scope.type = 'text';
      $scope.isElementValueType = dynamicElementsService.isElementValueType;
      $scope.elemId = 'add-default-value-modal';
      $scope.parentData = parentItems;
      $scope.engineerFields = _.has($scope.parentData,'engineerFields') ? $scope.parentData.engineerFields: [];
      $scope.inputs = {};
      $scope.inputs.defaultval = angular.copy($scope.parentData.currentItem.defaultValue);
      if(!$scope.inputs.defaultval[0]) {
        $scope.inputs.defaultval=[];
      }
      $scope.inputs.selectedDate = new Date();
      $scope.inputs.time_hour = '00';
      $scope.inputs.time_min  = '00';
      if($scope.parentData.currentItem.type === 'user_field') {
        $scope.type = 'dropdown';
      }

      $scope.getSeletedItem = function(returnKey) {
        if($scope.inputs.defaultval.length ===0 || $scope.inputs.defaultval[0] === '') {
          return '';
        }
        var item = _.find($scope.engineerFields, function(x){
          return x.id == $scope.inputs.defaultval[0];
        });
        return item[returnKey];
      };

      if($scope.parentData.currentItem.type === 'date' || $scope.parentData.currentItem.type === 'time') {
        $scope.inputs.datepickerOpened = false;
        $scope.inputs.format = $scope.parentData.currentItem.format;
        $scope.hoursRange = _.map(_.range(0,24,1), function(x){
          return x < 10 ? '0'+x : x;
        });
        $scope.minRange = _.map(_.range(0,60,1), function(x){
          return x < 10 ? '0'+x : x;
        });
        if($scope.inputs.defaultval.length === 0) {
          $scope.inputs.defaultval[0] = $filter('date')($scope.inputs.selectedDate, $scope.parentData.currentItem.format);
          $scope.inputs.time_hour = $scope.inputs.selectedDate.getHours();
          $scope.inputs.time_min = $scope.inputs.selectedDate.getMinutes();
        }
        else {
          $scope.inputs.selectedDate = new Date($scope.inputs.defaultval[0]);
          $scope.inputs.defaultval[0] = $filter('date')($scope.inputs.selectedDate, $scope.parentData.currentItem.format);
          $scope.inputs.time_hour = $scope.inputs.selectedDate.getHours();
          $scope.inputs.time_min = $scope.inputs.selectedDate.getMinutes();
        }
      }
      else if($scope.parentData.currentItem.type === 'checkbox' ) {
        if($scope.inputs.defaultval.length === 0) {
          $scope.inputs.defaultval[0] = 'False';
        }
      }

      $scope.pattern = '';

      if($scope.parentData.currentItem.type === 'decimal') {
        var f = $scope.parentData.currentItem.format.split(','),
          p = f[0] == 0 ? '': f[0],
          s = f[1] == 0 ? '': f[1];
        $scope.pattern = '^\\d{0,'+p+'}(\\.\\d{0,'+s+'})?$';
      }

      $scope.onSubmit = function() {
        if($scope.parentData.currentItem.type === 'date' || $scope.parentData.currentItem.type === 'time') {
          $scope.parentData.currentItem.defaultValue[0] = $scope.inputs.selectedDate;
          $scope.parentData.currentItem.format = $scope.inputs.format;
        }
        else {
          if($scope.inputs.defaultval.length > 0 && $scope.inputs.defaultval[0] === '') {
            $scope.inputs.defaultval = [];
          }
          $scope.parentData.currentItem.defaultValue = $scope.inputs.defaultval;
        }

        $modalInstance.close($scope.parentData);
      };

      $scope.onClose = function() {
        $modalInstance.dismiss($scope.parentData);
      };

      //if element type is date
      $scope.date_pickerOpen = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.inputs.datepickerOpened = true;
      };
      $scope.onChangeDate = function() {
        $scope.inputs.defaultval[0] = $filter('date')($scope.inputs.selectedDate, $scope.parentData.currentItem.format);
      };

      $scope.onChangeTime = function(type) {
        if(type==='hour') {
          $scope.inputs.selectedDate.setHours($scope.inputs.time_hour);
        }
        else {
          $scope.inputs.selectedDate.setMinutes($scope.inputs.time_min);
        }
        $scope.inputs.defaultval[0] = $filter('date')($scope.inputs.selectedDate, $scope.parentData.currentItem.format);
      };

      $modalInstance.opened.then(function(isOpened) {
        if (isOpened) {
          dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
          $timeout(function(){
            var $element = $('#'+$scope.elemId);
            $element.bind('keydown', function(e){
              if(e.which === 13) {
                $scope.onSubmit();
              }
            });
          },300);
        }
      });
      $scope.$on('$destroy', function(){
        $(document).unbind('keydown');
        $('#'+$scope.elemId).unbind('keydown');
        $('body').find('.modal-backdrop').off('click');
      });
  }])
  .controller('DynamicFormModalDependantCtrl',[
    '$state',
    '$scope',
    '$timeout',
    '$modalInstance',
    'parentItems',
    'dynamicElementsService',
    'dfConfirmationToast',
    'dfDataManipulationService', function($state, $scope, $timeout, $modalInstance, parentItems, dynamicElementsService, dfConfirmationToast, dfDataManipulationService) {
      var jsonModel = angular.copy(parentItems.dataModal);
      $scope.isSection = parentItems.type === 'section' ? true : false;
      $scope.questionID = $scope.isSection ? parentItems.currentSection.sectionID : parentItems.currentItem.questionID;
      $scope.standardDependantList=[];
      $scope.groupAssetDependantList=[];

      if ($state.current.name.indexOf('settings_standard_asset') > -1) {
        $scope.currentPage = 'standard';
      }
      else if ($state.current.name.indexOf('settings_configure_asset_group') > -1) {
        $scope.currentPage = 'assetGroup';
      }
      else {
        $scope.currentPage = 'assetType';
      }

      $scope.parentData = parentItems;
      $scope.isElementValueType = dynamicElementsService.isElementValueType;
      $scope.dependantList = dfDataManipulationService.getDependantFieldsList(jsonModel, $scope.questionID, $scope.isSection);
      $scope.inputs = {};
      $scope.btnDisabled = true;
      $scope.dynamicOptionsData = parentItems.dynamicFields;

      $scope.standardRecordFields = $scope.parentData.standardRecordFields;
      $scope.groupRecordField = $scope.parentData.groupRecordField;

      if($scope.currentPage === 'assetGroup') {
        $scope.standardDependantList = dfDataManipulationService.getDependantFieldsList($scope.standardRecordFields, $scope.questionID, false);
      }
      else if($scope.currentPage === 'assetType') {
        $scope.standardDependantList = dfDataManipulationService.getDependantFieldsList($scope.standardRecordFields, $scope.questionID, false);
        $scope.groupAssetDependantList = dfDataManipulationService.getDependantFieldsList($scope.groupRecordField, $scope.questionID, false);
      }
      $scope.inputs.rules = prepareRulerInput();
      $scope.countItemQuesions = function() {
        return $scope.dependantList.length + $scope.standardDependantList.length + $scope.groupAssetDependantList.length;
      };

      $scope.addNewItem = function() {
        $scope.inputs.rules.push({
          questionID: '',
          answer: [],
          question: {}
        });
        $scope.validations();
      };
      $scope.onRemoveItem = function(index) {
        $scope.inputs.rules.splice(index, 1);
        if($scope.inputs.rules.length !== 0) {
          $scope.validations();
        }
        else {
          $scope.btnDisabled = false;
        }
      };

      $scope.validations = function() {
        if(_.isEmpty($scope.inputs.rules) ) {
          $scope.btnDisabled = true;
          return false;
        }
        var _f = _.filter($scope.inputs.rules, function(x) {
          return _.isEmpty(x.questionID) || _.isEmpty(x.answer);
        });
        if(_f.length === 0) {
          $scope.btnDisabled = false;
          return true;
        }
        else {
          $scope.btnDisabled = true;
          return false;
        }
      };

      $scope.onChangeQuestionDepenadant = function(model, old) {
        var selectedQuestion = _.find($scope.dependantList, function(x){
          return x.questionID === model.questionID;
        });

        if(!selectedQuestion) {
          selectedQuestion = _.find($scope.standardDependantList, function(x){
            return x.questionID === model.questionID;
          });
        }

        if(!selectedQuestion) {
          selectedQuestion = _.find($scope.groupAssetDependantList, function(x){
            return x.questionID === model.questionID;
          });
        }

        if(selectedQuestion && selectedQuestion.type === 'checkbox') {
          selectedQuestion.items = [{
            id: 'true',
            description: "Checked"
          }];
          model.answer.push('true');
        }
        else if(selectedQuestion && selectedQuestion.type === 'dynamic_dropdown') {
          var options = _.find($scope.dynamicOptionsData, function(x){
            return x.id == selectedQuestion.selectedOption;
          });

          selectedQuestion.items= options ? options.items : [];
        }
        model.question = selectedQuestion ? selectedQuestion : {};
        $scope.validations();
      };

      $scope.onChangeAnswerDepenadant = function(model) {
        $scope.validations();
      };

      $scope.onSubmit = function() {
        var __rules = formatRuler();
        if($scope.isSection) {
          $scope.parentData.currentSection.dependencies=__rules;
        }
        else {
          $scope.parentData.currentItem.dependencies=__rules;
        }
        $modalInstance.close($scope.parentData);
      };

      $scope.onClose = function() {
        $modalInstance.dismiss($scope.parentData);
      };

      function prepareRulerInput() {
        var _data = $scope.isSection ? $scope.parentData.currentSection.dependencies : $scope.parentData.currentItem.dependencies;
        if( _.isEmpty(_data) ) {
          return [];
        }
        return _.map(_data, function(x){
          var question = _.find($scope.dependantList, function(z){return z.questionID === x.questionID;});
          if(!question) {
            question = _.find($scope.standardDependantList, function(z){
              return x.questionID === z.questionID;
            });
          }
          if(!question) {
            question = _.find($scope.groupAssetDependantList, function(z){
              return x.questionID === z.questionID;
            });
          }
          if(question && question.type === 'checkbox') {
            question.items = [{
              id: 'true',
              description: "Checked"
            }];
          }
          else if(question && question.type === 'dynamic_dropdown') {
            var options = _.find($scope.dynamicOptionsData, function(x){
              return x.id == question.selectedOption;
            });
            question.items= options ? options.items : [];
          }

          return {
            questionID: x.questionID,
            answer: x.answer,
            question: question
          };
        });
        //return [];
      }

      function formatRuler() {
        return _.map($scope.inputs.rules, function(rule){
          return {
            questionID: rule.questionID,
            answer: rule.answer
          };
        });
      }

      $modalInstance.opened.then(function(isOpened) {
        if (isOpened) {
          dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
          $timeout(function(){
            var $element = $(document);
            $element.bind('keydown', function(e){
              if(e.which === 13) {
                $scope.onSubmit();
              }
            });
          },300);
        }
      });
      $scope.$on('$destroy', function(){
        $(document).unbind('keydown');
        $('body').find('.modal-backdrop').off('click');
      });

    }])
  .controller('DynamicFormModalAddCtrl', [
    '$scope',
    '$modalInstance',
    'parentItems',
    'dfConfirmationToast',
    'DF_Properties',
    '$timeout',
    '$http',
    'prefix',
    function($scope, $modalInstance, parentItems, dfConfirmationToast, DF_Properties, $timeout, $http, prefix){
      $scope.parentData = parentItems;

      $scope.onChangeBehavior = function() {
        var keySuffixVal = '', propertyKey=$scope.parentData.currentItem.type;
        if(_.has($scope.parentData.currentItem, 'behavior')) {
          keySuffixVal = $scope.parentData.currentItem.behavior;
        }
        if(keySuffixVal!=='') {
          propertyKey = propertyKey+'_'+keySuffixVal;
        }
        if(_.has($scope.parentData.currentItem, 'behavior') || propertyKey==='image') {
          $scope.parentData.currentItem.properties = DF_Properties[propertyKey];
        }
      };
      $scope.onChangeBehavior();
      $scope.isEdit = $scope.parentData.type === 'edit_element' ? true : false;
      if($scope.isEdit===true) {
        $scope.title = 'Update';
      }
      else {
        $scope.title = 'Add';
      }

      $scope.isDisabledSubmit = true;
      $scope.inputs = {};
      $scope.certificateKey = false;
      $scope.inputs.fieldName = angular.copy($scope.parentData.currentItem.fieldLabel);
      $scope.inputs.fieldHelpText = angular.copy($scope.parentData.currentItem.helpText);
      $scope.inputs.flag = _.isUndefined($scope.parentData.currentItem.flag) ? false : $scope.parentData.currentItem.flag;
      $scope.inputs.certificate_key = _.has($scope.parentData.currentItem,'certificateKey') ? $scope.parentData.currentItem.certificateKey : '';

      $scope.onValidateCertificateKey = function(){
        $scope.certificateKey = false;
       /* if($scope.inputs.certificate_key !=''){
          const viewElements = $scope.parentData.dataModal.viewElements;
          var viewElementsTotalResult =[];

          _.map(viewElements, function(item, index){
            if(index== $scope.parentData.currentItem.questionID){
              viewElementsTotalResult.push($scope.inputs.certificate_key)
            }else if(item.certificateKey !=undefined){
              if(item.certificateKey != '' || (typeof item.certificateKey != 'undefined' && item.certificateKey != '')){
                viewElementsTotalResult.push(item.certificateKey)
              }
            }
          });
          let resultToReturn = false;

          const duplicateObjectCheck = new Set(viewElementsTotalResult);
          if(viewElementsTotalResult.length !== duplicateObjectCheck.size){
            resultToReturn = true;
          }
          if(resultToReturn){
            $scope.certificateKey =true;
          }else{
            $scope.certificateKey =false;
          }
        }else{
          $scope.certificateKey =false;
        }*/
      }
      $scope.onSubmit = function() {
        if($scope.inputs.fieldName === '') {
          $scope.isDisabledSubmit = true;
          return false;
        }
        if(!$scope.inputs.flag && _.has($scope.parentData.currentItem, 'flag')) {
          delete $scope.parentData.currentItem['flag'];
        }
        else {
          $scope.parentData.currentItem.flag = $scope.inputs.flag;
        }
       /* $scope.parentData.currentItem.fieldLabel = $scope.inputs.fieldName;
        $scope.parentData.currentItem.helpText = $scope.inputs.fieldHelpText;
        $scope.parentData.currentItem.certificateKey = $scope.inputs.certificate_key;
        delete $scope.parentData.currentItem['isnew'];*/

        $scope.onValidateCertificateKey();
        if($scope.inputs.certificate_key !==''){
          $http.get(prefix + "/assetFormConfigurationExists?certificateKey="+ $scope.inputs.certificate_key+'&questionID='+$scope.parentData.currentItem.questionID)
              .success(function (data) {
                if(data == 'true'){
                  $scope.certificateKey = true;
                }else{
                  $scope.certificateKey = false;
                }
                if(!$scope.certificateKey){
                  $scope.parentData.currentItem.fieldLabel = $scope.inputs.fieldName;
                  $scope.parentData.currentItem.helpText = $scope.inputs.fieldHelpText;
                  $scope.parentData.currentItem.certificateKey = $scope.inputs.certificate_key;
                  delete $scope.parentData.currentItem['isnew'];
                  $modalInstance.close($scope.parentData);
                }
              });
        }else{
          $scope.parentData.currentItem.fieldLabel = $scope.inputs.fieldName;
          $scope.parentData.currentItem.helpText = $scope.inputs.fieldHelpText;
          $scope.parentData.currentItem.certificateKey = $scope.inputs.certificate_key;
          delete $scope.parentData.currentItem['isnew'];
          $modalInstance.close($scope.parentData);
        }
      };

      $scope.onClose = function() {
        $modalInstance.dismiss($scope.parentData);
      };

      $modalInstance.opened.then(function(isOpened) {
        if (isOpened) {
          dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
          $timeout(function(){
            var $element = $(document);
            $element.bind('keydown', function(e){
              if(e.which === 13) {
                $scope.onSubmit();
              }
            });
          },300);
        }
      });
      $scope.$on('$destroy', function(){
        $(document).unbind('keydown');
        $('body').find('.modal-backdrop').off('click');
      });
  }])
  .controller('DynamicFormModalRemoveSectionCtrl', [
    '$scope',
    '$modalInstance',
    '$timeout',
    'parentItems', function($scope, $modalInstance, $timeout, parentItems){
    $scope.isDisabledSubmit = true;
    $scope.showError = false;
    $scope.confirmationText = '';
    $scope.parentData = parentItems;
    $scope.onConfirm = function() {
      if($scope.confirmationText.toLowerCase() === 'delete') {
        $scope.isDisabledSubmit = false;
        $scope.showError = false;
      }
      else {
        $scope.isDisabledSubmit = true;
        $scope.showError = true;
      }
    };

    $scope.onSubmit = function() {
      if($scope.confirmationText.toLowerCase() !== 'delete') {
        return false;
      }
      if($scope.parentData.currentSection.type==='assets') {
        $scope.parentData.extraData = {
          subAssetDeletedId: $scope.parentData.currentSection.referenceId
        };
      }
      $modalInstance.close($scope.parentData);
    };

    $scope.onClose = function() {
      $modalInstance.dismiss('cancel');
    };

      $modalInstance.opened.then(function(isOpened) {
        if (isOpened) {
          $timeout(function(){
            var $element = $(document);
            $element.bind('keydown', function(e){
              if(e.which === 13) {
                $scope.onSubmit();
              }
              if(e.which === 27) {
                $scope.onClose();
              }
            });
          },300);
        }
      });
      $scope.$on('$destroy', function(){
        $(document).unbind('keydown');
        $('body').find('.modal-backdrop').off('click');
      });

  }])
  .controller('DynamicFormModalElementFormatCtrl', [
    '$scope',
    '$timeout',
    '$translate',
    '$rootScope',
    '$modalInstance',
    'parentItems',
    'DF_DATE_FORMATS',
    'dfConfirmationToast', function($scope, $timeout, $translate, $rootScope, $modalInstance, parentItems, DF_DATE_FORMATS,dfConfirmationToast){
      $scope.parentData = parentItems;
      $scope.today = new Date();
      $scope.inputs = {};
      $scope.id = 'cs_df_addFormatModal';

      if($scope.parentData.currentItem.type === 'decimal') {
        $scope.inputs.decimal = angular.copy($scope.parentData.currentItem.format).split(',');
      }
      else if($scope.parentData.currentItem.type === 'date') {
        $scope.parentData.currentItem.formatItems = DF_DATE_FORMATS.items;
        $scope.inputs.date_format = angular.copy($scope.parentData.currentItem.format);
      }
      else {
        $scope.inputs.date_format = angular.copy($scope.parentData.currentItem.format);
      }

      $scope.onSubmit = function() {
        switch ($scope.parentData.currentItem.type) {
          case 'decimal':
            $scope.parentData.currentItem.format = $scope.inputs.decimal.join(',');
            break;
          default:
            $scope.parentData.currentItem.format = $scope.inputs.date_format;
            break;
        }
        $modalInstance.close($scope.parentData);
      };

      $scope.onClose = function() {
        $modalInstance.dismiss($scope.parentData);
      };

      $modalInstance.opened.then(function(isOpened) {
        if (isOpened) {
          dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
        }
      });

      $scope.$on('$destroy', function(){
        $(document).unbind('keydown');
        $('#'+$scope.id).unbind('keydown');
        $('body').find('.modal-backdrop').off('click');
      });
    }
  ])
  .controller('DynamicFormModalDeleteCtrl',[
    '$scope',
    '$modalInstance',
    '$timeout',
    'parentItems',function($scope, $modalInstance, $timeout, parentItems){

    $scope.isDisabledSubmit = true;
    $scope.showError = false;
    $scope.confirmationText = '';
    $scope.parentData = parentItems;

    $scope.onConfirm = function() {
      if($scope.confirmationText.toLowerCase() === 'delete') {
        $scope.isDisabledSubmit = false;
        $scope.showError = false;
      }
      else {
        $scope.isDisabledSubmit = true;
        $scope.showError = true;
      }
    };

    $scope.onSubmit = function() {
      if($scope.confirmationText.toLowerCase() !== 'delete') {
        return false;
      }
      $modalInstance.close($scope.parentData);
    };

    $scope.onClose = function() {
      $modalInstance.dismiss('cancel');
    };

    $modalInstance.opened.then(function(isOpened) {
      if (isOpened) {
        $timeout(function(){
        var $element = $(document);
        $element.bind('keydown', function(e){
          if(e.which === 13) {
            $scope.onSubmit();
          }
          if(e.which === 27) {
            $scope.onClose();
          }
        });
        },300);
      }
    });
    $scope.$on('$destroy', function(){
      $(document).unbind('keydown');
      $('body').find('.modal-backdrop').off('click');
    });
  }])
  .controller('DynamicFormModalQuickFiltersCtrl',[
    '$state',
    'parentItems',
    '$modalInstance',
    '$scope',
    'dfConfirmationToast',
    '$timeout',
    'dfDataManipulationService',
    function($state, parentItems, $modalInstance, $scope, dfConfirmationToast, $timeout, dfDataManipulationService){
      $scope.id = 'add-quick-filters';
      $scope.parentData = parentItems;
      $scope.questionID = parentItems.currentItem.questionID;
      $scope.standardRecordFields = $scope.parentData.standardRecordFields;
      $scope.groupRecordField = $scope.parentData.groupRecordField;
      $scope.input = new Object();

      $scope.currentPage = $state.current.name.indexOf('settings_standard_asset') > -1 ? 'standard'
        : $state.current.name.indexOf('settings_configure_asset_group') > -1 ? 'assetGroup' : 'assetType';

      $scope.dateList = dfDataManipulationService.getDateFieldsList(angular.copy(parentItems.dataModal), $scope.questionID);
      $scope.standardDateList=[];
      $scope.groupAssetDateList=[];
      if($scope.currentPage === 'assetGroup') {
        $scope.standardDateList = dfDataManipulationService.getDateFieldsList($scope.standardRecordFields, $scope.questionID);
      }
      else if($scope.currentPage === 'assetType') {
        $scope.standardDateList   = dfDataManipulationService.getDateFieldsList($scope.standardRecordFields, $scope.questionID);
        $scope.groupAssetDateList = dfDataManipulationService.getDateFieldsList($scope.groupRecordField, $scope.questionID);
      }

      $scope.getAllDateList = function(){
        return $scope.standardDateList.concat($scope.groupAssetDateList, $scope.dateList);
      };
      $scope.getDateListCount = function(){
        return $scope.getAllDateList().length;
      };

      if(_.has($scope.parentData.currentItem, 'filters') && !_.isEmpty($scope.parentData.currentItem.filters)) {
        $scope.input.filters = angular.copy($scope.parentData.currentItem.filters);
      }
      else {
        $scope.input.filters = emptyFilter();
      }
      $scope.input.clearAll=false;
      //console.log('filters',$scope.input.filters);

      $scope.addNewRule = function(rules){
        rules.push({ type: 'days', action: 'add', value: 0 });
      };

      $scope.removeRule = function(index) {
        if($scope.input.filters.rules.length > 1) {
          $scope.input.filters.rules.splice(index, 1);
        }
      };

      $scope.onChange = function(questionID) {
        $scope.input.filters.screenType = getScreenType(questionID);
        //console.log('filterss',$scope.input.filters);
      };

      $scope.validateFilters = function() {
        var filter = $scope.input.filters,
        rules = _.pluck(filter.rules, 'value');
        if($scope.getDateListCount() === 0) {
          return false;
        }
        if($scope.input.clearAll) {
          return true;
        }
        if(_.isEmpty(filter.sourceId)) {
          return false;
        }
        if(_.contains(rules, 0) || _.contains(rules, '')) {
          return false;
        }
        return true;
      };

      $scope.onSubmit = function() {
        if($scope.validateFilters()) {
          $scope.parentData.currentItem.filters = $scope.input.clearAll ? {} : $scope.input.filters;
          $modalInstance.close($scope.parentData);
        }
      };

      $scope.onClose = function() {
        $modalInstance.dismiss('cancel');
      };

      $modalInstance.opened.then(function(isOpened) {
        if (isOpened) {
          dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
          $timeout(function(){
            var $element = $('#'+$scope.id);
            $element.bind('keydown', function(e){
              if(e.which === 13) {

              }
              if(e.which === 27) {
                $scope.onClose();
              }
            });
          },300);
        }
      });

      $scope.$on('$destroy', function(){
        $(document).unbind('keydown');
        $('#'+$scope.id).unbind('keydown');
        $('body').find('.modal-backdrop').off('click');
      });

      function getScreenType(questionID) {
        var screen='';
        var question = _.find($scope.dateList, function(x){return questionID === x.questionID;});
        if(question) {
          screen = $scope.currentPage;
        }
        else {
          var standardQue = _.find($scope.standardDateList, function(x){return questionID === x.questionID;});
          var groupQue = _.find($scope.groupAssetDateList, function(x){return questionID === x.questionID;});
          if(standardQue) {
            screen = 'standard'
          }
          if(groupQue) {
            screen = 'assetGroup';
          }
        }
        return screen;
      }

      function emptyFilter (){
        return {
          sourceId: '',
          screenType: '',
          rules: [{ type: 'days', action: 'add', value: 0 }]
        };
      }
  }])
  .controller('DynamicFormModalValueDependantCtrl',[
    '$state',
    'toastBox',
    '$scope',
    '$modalInstance',
    '$timeout',
    'parentItems',
    'dfConfirmationToast',
    'prefix',
    '$http',
    'dfDataManipulationService', function($state, toastBox, $scope, $modalInstance, $timeout, parentItems, dfConfirmationToast,prefix, $http, dfDataManipulationService) {
      var jsonModel = angular.copy(parentItems.dataModal);
      $scope.elemId = 'add-default-value-modal';
      $scope.inputPlaceholder = 'Add new dynamic field.';
      $scope.parentData = parentItems;
      $scope.customFieldsData = parentItems.dynamicFields;
      $scope.customeFieldsLabel = _.pluck($scope.customFieldsData, 'name');
      $scope.input = {};
      $scope.isAddNew = false;
      $scope.selectedItem = {};

      $scope.input.selectedField = parseInt(angular.copy($scope.parentData.currentItem.selectedOption));
      $scope.input.selectedFieldUUID = parseInt(angular.copy($scope.parentData.currentItem.selectedOptionUUID));
      $scope.input.hasParent=false;
      $scope.input.newField = '';

      $scope.isDiabledAddBtn = false;
      $scope.btnDisabled = true;
      if($scope.input.selectedField > 0 ) {
        $scope.btnDisabled = false;
      }

      $scope.questionID = parentItems.currentItem.questionID;
      $scope.standardListOfQuestions=[];
      $scope.groupListOfQuestions=[];

      if ($state.current.name.indexOf('settings_standard_asset') > -1) {
        $scope.currentPage = 'standard';
      }
      else if ($state.current.name.indexOf('settings_configure_asset_group') > -1) {
        $scope.currentPage = 'assetGroup';
      }
      else {
        $scope.currentPage = 'assetType';
      }
      $scope.standardRecordFields = $scope.parentData.standardRecordFields;
      $scope.groupRecordField = $scope.parentData.groupRecordField;

      $scope.listOfQuestions = dfDataManipulationService.getDynamicFieldsQuestion(jsonModel, $scope.questionID);
      if($scope.currentPage === 'assetGroup') {
        $scope.standardListOfQuestions = dfDataManipulationService.getDynamicFieldsQuestion($scope.standardRecordFields, $scope.questionID, false);
      }
      else if($scope.currentPage === 'assetType') {
        $scope.standardListOfQuestions   = dfDataManipulationService.getDynamicFieldsQuestion($scope.standardRecordFields, $scope.questionID, false);
        $scope.groupListOfQuestions = dfDataManipulationService.getDynamicFieldsQuestion($scope.groupRecordField, $scope.questionID, false);
      }
      $scope.listOfAllQuestions = _.union(
        $scope.listOfQuestions,
        $scope.standardListOfQuestions,
        $scope.groupListOfQuestions
      );

      $scope.input.parents = prepareParentsInput();

      $scope.isActiveSelectedField = function() {
        return !_.contains(['-1', -1, false, 0, '0', null], $scope.input.selectedField) &&
          $scope.customFieldsData.length > 0;
      };

      $scope.countItemQuesions = function() {
        return $scope.listOfQuestions.length + $scope.standardListOfQuestions.length + $scope.groupListOfQuestions.length;
      };

      $scope.getAllQuestions = function() {
        return $scope.listOfQuestions.concat($scope.standardListOfQuestions, $scope.groupListOfQuestions);
      };
      //console.log('$scope.countItemQuesions()', $scope.countItemQuesions(), $scope.listOfQuestions)

      $scope.addNewItem = function(data) {
        var _data = _.isUndefined(data) ? {} : _.isObject(data) ? data : {};
        $scope.input.parents.push({
          questionID: _.has(_data, 'questionID') ? data.questionID : '',
          selectedOption: _.has(_data, 'selectedOption') ? data.selectedOption : '',
          question: _.has(_data, 'question') ? data.question : {}
        });
        $scope.validations();
      };

      $scope.onChangeOption = function() {
        $scope.isAddNew = $scope.input.selectedField == 0;
        if($scope.isAddNew || !$scope.isActiveSelectedField() ) {
          $scope.input.hasParent = $scope.btnDisabled = true;
        }
        else if($scope.isActiveSelectedField() && $scope.input.hasParent === false) {
          $scope.btnDisabled = false;
        }
        $scope.reset();

        var selectedItem = getSelectedItem($scope.input.selectedField),
        allQuestions = $scope.getAllQuestions();
        $scope.input.selectedFieldUUID = (typeof selectedItem != 'undefined') ? selectedItem.uuid : '';
        if(selectedItem && _.isArray(selectedItem.fieldDependency) && selectedItem.fieldDependency.length > 0) {
          var valDeps = _.chain(selectedItem.fieldDependency)
            .map(function(id){
              var qus = _.find(allQuestions, function(x){
                return x.selectedOption == id;
              });
              if(qus) {
                return {
                  questionID: qus.questionID,
                  question: qus,
                  selectedOption: qus.selectedOption
                };
              }
              else {
                return -1;
              }
            })
            .value();

          if(_.contains(valDeps, -1)) {
            $scope.input.parents = [];
            $scope.message = 'You cannot able to set parent, because already having dependent';
            $scope.input.hasParent=false;
          }
          else {
            $scope.input.hasParent=true;
            $scope.input.parents = valDeps;
          }
        }
      };

      $scope.onChangeCheckbox = function() {
        if(!$scope.input.hasParent) {
          $scope.btnDisabled = !$scope.isActiveSelectedField();
          $scope.reset();
          return;
        }
        $scope.btnDisabled = false;
        $scope.addNewItem();
      };

      $scope.validateSelectedOption = function() {
          return getMappedDynamicFieldsQuestions().length === 0;
      };

      $scope.disableParentOption = function () {
        var selectedItem = getSelectedItem($scope.input.selectedField);
        //console.log('disableParentOption',selectedItem, $scope.countItemQuesions())
        if( _.has(selectedItem, 'items') && selectedItem.items.length > 0 && $scope.countItemQuesions() > 0 ) {
          $scope.message = 'You cannot able to set parent because its having options.';
          return true;
        }
        else if($scope.countItemQuesions() === 0) {
          $scope.message = 'You cannot able to set parent because no questions.';
          return true;
        }
        return false;
      };

      $scope.isShowDeleteAction = function(key) {
        //return !$scope.disableParentOption() ? false : key === ($scope.input.parents.length-1) ? true : false;
        if($scope.disableParentOption()) {
          return false;
        }
        return key === ($scope.input.parents.length-1) ? true : false;
      };

      $scope.onChangeQuestion = function(model) {
        var selectedQuestion = _.find($scope.listOfQuestions, function(x){
          return x.questionID === model.questionID;
        });
        if(!selectedQuestion) {
          selectedQuestion = _.find($scope.standardListOfQuestions, function(x){
            return x.questionID === model.questionID;
          });
        }
        if(!selectedQuestion) {
          selectedQuestion = _.find($scope.groupListOfQuestions, function(x){
            return x.questionID === model.questionID;
          });
        }
        //console.log('selectedQuestion',selectedQuestion);

        model.question = selectedQuestion ? selectedQuestion : {};
        model.selectedOption = selectedQuestion.selectedOption;

        if(selectedQuestion && selectedQuestion.valueDependencies.length > 0) {
          pushParentUpward(selectedQuestion.valueDependencies, model);
        }
        $scope.validations();
      };

      function pushParentUpward(valueDependencies, selected) {
        //console.log('pushParent', valueDependencies, $scope.listOfAllQuestions);
        $scope.input.parents=[];
        _.forEach(valueDependencies, function(item){
          var pObj = _.find($scope.listOfAllQuestions,function(x){ return item.questionID === x.questionID;});
          //console.log('pObj', pObj, item.questionID)
          if(pObj) {
            $scope.addNewItem({
              questionID: pObj.questionID,
              selectedOption: pObj.selectedOption,
              question: pObj
            });
          }
        });
        $scope.input.parents.push(selected);
      };

      $scope.handleAssetField = function() {
        if($scope.input.newField === '') {
          return;
        }
        $scope.isDiabledAddBtn = true;
        if(_.contains($scope.customeFieldsLabel, $scope.input.newField.trim())) {
          var item = _.find($scope.customFieldsData, function(x){
            return x.name === $scope.input.newField.trim();
          });
          if(item) {
            $scope.input.selectedField = item.id;
            $scope.input.newField = '';
            $scope.isAddNew = false;
          }
          $scope.isDiabledAddBtn = false;
          return;
        }
        $http.post(prefix + "/saveDynamicAsset", "dynamicAsset[fieldName]=" + $scope.input.newField.trim())
          .success(function (data, status) {
            if (status == 200) {
              data.name = $scope.input.newField.trim();
              data.fieldDependency=null;
              $scope.customFieldsData.push(data);
              $scope.input.selectedField = data.id;
              $scope.input.selectedFieldUUID = data.UUID;
              $scope.input.newField = '';
              $scope.isAddNew = $scope.btnDisabled = $scope.isDiabledAddBtn = false;
              $scope.disableParentOption();
              return data;
            }
          });
      };

      $scope.reset = function() {
        $scope.input.hasParent=false;
        $scope.input.parents = [];
        $scope.message='';
      };

      $scope.onRemoveItem = function(index) {
        $scope.input.parents.splice(index, 1);
      };

      $scope.validations = function() {
        if(_.isEmpty($scope.input.parents) ) {
          $scope.btnDisabled = true;
          return false;
        }
        var _f = _.filter($scope.input.parents, function(x) {
          return _.isEmpty(x.questionID);
        });
        if(_f.length === 0) {
          $scope.btnDisabled = false;
          return true;
        }
        else {
          $scope.btnDisabled = true;
          return false;
        }
      };

      $scope.onSubmit = function() {
        $scope.parentData.currentItem.selectedOption = $scope.input.selectedField;
        $scope.parentData.currentItem.selectedOptionUUID = $scope.input.selectedFieldUUID;

        //$scope.parentData.currentItem.itemsCount = _.size($scope.items);
        $scope.parentData.currentItem.valueDependencies = _.map($scope.input.parents, function(x){
          return {
            questionID: x.questionID,
          };
        });
        var extraData = {
          id: $scope.input.selectedField,
          data: _.map($scope.input.parents, function(x){ return parseInt(x.selectedOption)})
        };
        $scope.parentData.extraData = extraData;

        if(extraData.data.length) {
          var selectedField = _.find($scope.customFieldsData, function(data){
            return data.id == extraData.id;
          });
          if(selectedField) {
            selectedField.fieldDependency = extraData.data;
          }
        }

        $modalInstance.close($scope.parentData);
      };

      $scope.onClose = function() {
        $modalInstance.dismiss('cancel');
      };

      $modalInstance.opened.then(function(isOpened) {
        if (isOpened) {
          dfConfirmationToast.sidePanelConfirmationToast($scope.onClose);
          $timeout(function(){
            var $element = $('#'+$scope.elemId);
            $element.bind('keydown', function(e){
              if(e.which === 13) {
                $scope.isAddNew ? $scope.handleAssetField() : $scope.onSubmit();
              }
              if(e.which === 27) {
                $scope.onClose();
              }
            });
          },300);
        }
      });

      $scope.$on('$destroy', function(){
        $(document).unbind('keydown');
        $('#'+$scope.elemId).unbind('keydown');
        $('body').find('.modal-backdrop').off('click');
      });



      function getSelectedItem(id) {
        if(_.has($scope.selectedItem, id)) {
          return $scope.selectedItem[id];
        }
        var selectedItem = _.find($scope.customFieldsData, function(x){
          return id == x.id;
        });
        if(selectedItem) {
          $scope.selectedItem[id] = selectedItem;
        }
        return $scope.selectedItem[id];
      }

      function getMappedDynamicFieldsQuestions() {
        var listOfQuestions=[], standardListOfQuestions=[], groupListOfQuestions=[];
        listOfQuestions = dfDataManipulationService.getMappedDynamicFieldsQuestions(jsonModel, $scope.questionID);

        if($scope.currentPage === 'assetGroup') {
          standardListOfQuestions = dfDataManipulationService.getMappedDynamicFieldsQuestions($scope.standardRecordFields, $scope.questionID, false);
        }
        else if($scope.currentPage === 'assetType') {
          standardListOfQuestions   = dfDataManipulationService.getMappedDynamicFieldsQuestions($scope.standardRecordFields, $scope.questionID, false);
          groupListOfQuestions = dfDataManipulationService.getMappedDynamicFieldsQuestions($scope.groupRecordField, $scope.questionID, false);
        }
        return _.union(
          listOfQuestions,
          standardListOfQuestions,
          groupListOfQuestions
        );
      }

      function prepareParentsInput(valDeps) {
        var _data = _.isUndefined(valDeps) ? angular.copy($scope.parentData.currentItem.valueDependencies) : _.isArray(valDeps) ? valDeps : null;

        if( _.isEmpty(_data) ) {
          return [];
        }
        $scope.input.hasParent=true;
        return _.map(_data, function(x){
          var question = _.find($scope.listOfQuestions, function(z){return z.questionID === x.questionID;});
          if(!question) {
            question = _.find($scope.standardListOfQuestions, function(z){
              return x.questionID === z.questionID;
            });
          }
          if(!question) {
            question = _.find($scope.groupListOfQuestions, function(z){
              return x.questionID === z.questionID;
            });
          }
          return {
            questionID: x.questionID,
            question: question,
            selectedOption: parseInt(question.selectedOption)
          };
        });
      }
   }]);

