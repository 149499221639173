commusoftCommon.filter('dynamicDecimalWithCurrency', ["$filter", "$locale", "$rootScope", function ($filter, $locale, $rootScope) {
    var formats = $locale.NUMBER_FORMATS;
    return function (amount, currencySymbol, fractionSize) {

        // Didn't get rootscope value in sidepanel so handled it here
        if(typeof currencySymbol == 'undefined'){
            currencySymbol = $rootScope.CustomCurrency;
        }
        // var currency = $filter('currency');
        var numberFilter = $filter('number');
        // Format the value with our chosen fraction size
        var fraction = numberFilter(amount, fractionSize);
        //Split after the decimal point
        var fractionSplit = fraction.split('.');
        // In decimal four digit number having [00.0000] last two digit as zero, then only display like [00.00]
        var formatFraction = fraction;
        if((typeof fractionSplit[1] != 'undefined') && (fractionSplit[1].length == 4)) {
            formatFraction = fraction.replace(/[0][0]$/,'');
        }
        //var newValue = currency(amount, currencySymbol).replace(/[0-9]+.*[0-9]+/, fraction);
        // Merge the currency symbol to value.
        var finalValue = currencySymbol + formatFraction;
        return finalValue;
    };
}]);
