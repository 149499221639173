commusoftCommon.directive('servicetype', function() {
    var handleServiceType = function handleServiceType(scope, element, attrs, ctrl) {
        scope.service_type_options = scope.$parent.service_types;
        if (attrs.required) {
            ctrl.$setValidity(attrs.model, false)
        }

        attrs.$observe('required', function(required) {
            // Note that === null is needed since we're pushing a blank one in on initiation to select -- please choose -- urgh..
            // Also === "true" is needed since angular props interpolate as strings... even when they don't exist! ""
            if (required === "true" && scope.selected_service_types[0].selected_type === null) {
                ctrl.$setValidity(attrs.model, false)
            } else {
                ctrl.$setValidity(attrs.model, true)
            }
        })

        scope.disableExistingServiceTypes = function disableExistingServiceTypes() {
            var existing_service_types = scope.selected_service_types.map(function(type) {
                return parseInt(type.selected_type);
            });

            for(var i = 0, l = scope.service_type_options.length; i < l; i++) {
                var option = scope.service_type_options[i];

                if(existing_service_types.indexOf(option.id) > -1 || (option.status === 'due_booked' || option.status === 'over_due_booked')) {
                    option.available = false;
                }else {
                    option.available = true;
                }
            }
        }

        if(attrs.selectedTypes !== undefined && attrs.selectedTypes.length > 0) {
            scope.selected_service_types = angular.fromJson(attrs.selectedTypes);
            scope.disableExistingServiceTypes();
        }else {
            scope.selected_service_types = [
                {
                    'selected_type' : null
                }
            ];
        }

        scope.handleSelectedServiceType = function handleSelectedServiceType(new_service_type, old_service_type) {
            var old_service_type = angular.fromJson(old_service_type);

            var index_of_new_service_type_option = scope.getIndexOfSelectedServiceTypeInOptions(new_service_type),
                index_of_old_service_type_option = scope.getIndexOfSelectedServiceTypeInOptions(old_service_type);

            scope.service_type_options[index_of_new_service_type_option].available = false;
            var status = '';
            if(index_of_old_service_type_option > -1) {
                status = scope.service_type_options[index_of_old_service_type_option].status;
            }
            if((index_of_old_service_type_option > -1) && (status != "due_booked" && status != "over_due_booked")) {
                scope.service_type_options[index_of_old_service_type_option].available = true;
            }
            scope.$emit("servicetype:selected", scope.selected_service_types);
            scope.setExternalValidity();
        }

        scope.addAnotherServiceType = function addAnotherServiceType() {
            scope.selected_service_types.push({
                'selected_type' : null
            });
        }

        scope.removeServiceType = function removeServiceType(service_type) {
            var index_of_service_type = scope.getIndexOfSelectedServiceTypeInSelectedServiceTypes(service_type),
                index_of_service_type_option = scope.getIndexOfSelectedServiceTypeInOptions(service_type);

            scope.service_type_options[index_of_service_type_option].available = true;
            scope.selected_service_types.splice(index_of_service_type, 1);
            scope.$emit("servicetype:selected", scope.selected_service_types);
            if (scope.selected_service_types.length === 0) {
                scope.selected_service_types.push({
                    'selected_type' : null
                });
            }
            scope.setExternalValidity();
        }

        scope.getIndexOfSelectedServiceTypeInOptions = function getIndexOfSelectedServiceTypeInOptions(service_type) {
            return scope.service_type_options.map(function(option) {
                return parseInt(option.id);
            }).indexOf(parseInt(service_type.selected_type));
        }

        scope.getIndexOfSelectedServiceTypeInSelectedServiceTypes = function getIndexOfSelectedServiceTypeInSelectedServiceTypes(service_type) {
            return scope.selected_service_types.map(function(type) {
                return parseInt(type.selected_type);
            }).indexOf(parseInt(service_type.selected_type));
        }

        scope.setExternalValidity = function() {
            var valid = true
            if (attrs.required === "true" && scope.selected_service_types[0].selected_type === null) {
                valid = false
            }
            ctrl.$setValidity(attrs.model, valid)
        }
    }

    return {
        require: '^form',
        restrict: 'A',
        templateUrl: '/template/service_type.html',
        scope: {},
        link: handleServiceType
    }
});