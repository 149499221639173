commusoftCommon.directive('handsontableSpreadsheet', ['prefix', '$http', '$timeout', 'toastBox', 'confirmationBoxHelper', '$compile', 'warningModal', '$state', function (prefix, $http, $timeout, toastBox, confirmationBoxHelper, $compile, warningModal, $state) {

    return {
        restrict: 'A',
        scope: false,
        link : function ($scope, element, attrs) {

            var isParent = false;
            // Avoid new scope when ng-repeat used
            if(typeof attrs.isParent != "undefined" && attrs.isParent) {
                isParent = true;
            }

            $scope.settings  = JSON.parse(attrs.settings);
            $scope.category  = attrs.category;
            $scope.getUrl    = attrs.getUrl;
            $scope.addUrl    = attrs.addUrl;
            $scope.updateUrl = attrs.updateUrl;
            $scope.deleteUrl = attrs.deleteUrl;

            $scope.spreadsheetInstance  = {};
            $scope.spreadsheetRowValidation = true;
            var additional_settings  = {};
            var spreadsheetLineItems = {};

            var errorRow = [];

            $scope.numericFields      = [{prop:'unitPrice',limit:10}];

            $scope.$on('event:getSettings',function (event,data) {
                additional_settings = data;
            });
            $scope.$emit('spreadsheetData:triggerSettings','');

            if(isParent === false || typeof $scope.$parent.isNewSpreadsheetInstance == "undefined" ) {
                $scope.$parent.isNewSpreadsheetInstance = false;
                $scope.$parent.spreadsheetInstance = {}; // for full breakdown by category
                $scope.$emit('spreadsheetData:lineItems', spreadsheetLineItems);
            }

            initSpreadsheetFunction.call(this,$scope,additional_settings,confirmationBoxHelper,toastBox);

            //# Default spreadsheet settings
            var spreadsheet_settings = {
                colHeaders  : true,
                afterGetColHeader: $scope.requiredFields,
                rowHeaders  : true,
                startRows   : 1,
                autoWrapRow : true,
                viewportRowRenderingOffset : 1000,
                currentColClassName : 'selectedCol',
                currentRowClassName : 'selectedRow',
                stretchH : 'all',
                comments : true,
                formulas : true,
                data     : undefined,
                fillHandle: { direction: 'vertical', autoInsertRow: true },
                afterSelection: $scope.afterSelection,
                beforeValidate: $scope.beforeValidate
            };

            //# Custom spreadsheet settings
            spreadsheet_settings = angular.copy(_.extend(spreadsheet_settings,additional_settings));

            //# For full breakdown by category
            if(typeof attrs.categoryid != "undefined") {
                spreadsheet_settings.dataSchema.categoryId = attrs.categoryid;
            }

            //# Update colHeaders via customer function
            if(additional_settings.column_header) {
                spreadsheet_settings.colHeaders = $scope.customHeader;
            }

            if(typeof additional_settings == "undefined" || additional_settings === {})
            {
                spreadsheetLineItems = undefined;
                $scope.$emit('spreadsheetData:lineItems', spreadsheetLineItems);
            }

            $scope.autoSave_col = additional_settings.autoSave_col;

            //# initialize spreadsheet
            $scope.spreadsheetAfterInit = function () {
                var hotId    = this.getSettings().hotId;
                var instance = $scope.spreadsheetInstance[hotId] = this;
                $scope.$parent.spreadsheetInstance[hotId] = this;

                if(typeof $scope.getUrl != "undefined") {
                    $http.get($scope.getUrl).success(function (data) {
                        if ($scope.category = 'no_breakdown' || 'category_breakdown' || 'full_breakdown' || attrs.categoryid != "undefined") {
                            if (data['deletedStatus']) {
                                warningModal.show(data['message'], 'Estimates', 'Deleted');
                                $state.transitionTo('loggedin.customer_list.view.property', {
                                    'id': $state.params.id,
                                    'type': $state.params.type
                                }, {reload: true});
                            }
                        }
                        spreadsheetLineItems = data.spreadsheetData;
                        $timeout(function () {
                            $scope.spreadsheet_hide_loading = true;
                        }, 100);
                        $scope.$emit('spreadsheetData:lineItems', [spreadsheetLineItems, hotId]);

                        spreadsheet_settings.data = data.spreadsheetData;
                        spreadsheet_settings.startCols = _.keys(data.spreadsheetData).length;
                        instance.updateSettings(spreadsheet_settings);
                    });
                }else {
                    this.updateSettings(spreadsheet_settings);
                }
            };

            //# Update the changes
            $scope.spreadsheetAfterChange = function (changes,src) {
                var instance    = this;
                var hotId       = instance.getSettings().hotId;
                var selectedRow = changes ? changes[0][0] : 0 ;

                var countRows = this.countRows();

                //# Add class name to avoid UI issues
                if(src === 'loadData')
                {
                    $timeout(function () {
                        instance.updateSettings({className: 'overflow_handsontable'});
                        instance.render();
                    }, 1000);
                }

                //# insert new row
                if(!instance.getSettings().row_limit || instance.getSettings().row_limit > countRows)
                {
                    if (src === 'loadData') {
                        this.alter('insert_row', countRows);
                        $timeout(function () {
                            instance.updateSettings({className: 'overflow_handsontable'});
                            instance.render();
                        }, 1000);
                        this.render();

                    } else if (src === 'edit') {
                        $scope.insertRow(this, changes, src);
                    }
                }

                this.scrollViewportTo(selectedRow - 1, 1);

                var data = instance.getSourceDataAtRow(selectedRow);
                var autoSave_col = typeof $scope.autoSave_col != undefined ?  data[$scope.autoSave_col] : true;
                if(changes != null && data['id'] == null && changes[0][1]!='description' && hotId!='no_breakdown'){
                    $scope.spreadsheetRowValidation= false;
                }else if(changes != null && hotId=='no_breakdown' && changes[0][1]!='unitPrice' && data['id'] == null){
                    $scope.spreadsheetRowValidation= false;
                }

                if(src === 'edit' && instance.getSettings().autoSave === true && autoSave_col && $scope.spreadsheetRowValidation)
                {
                    var url  =  (data['id']) ? $scope.updateUrl: $scope.addUrl;
                    $('#loading-indicator').show();
                    $http.post(url,'data='+encodeURIComponent(angular.toJson(data))).success(function (result,status) {
                        if(result['error'] || !data) {
                            errorRow.push(selectedRow);
                            var metaData = instance.getCellMetaAtRow(selectedRow);
                            for (var j = 1; j < metaData.length; j++) {
                                instance.setCellMeta(selectedRow, j, "className", "errorRowList");
                            }
                            instance.render();
                            toastBox.show(result['error'],1500);
                            return false;
                        } else if ($scope.category = 'no_breakdown' || 'category_breakdown' || 'full_breakdown' || attrs.categoryid != "undefined") {
                            if (result['deletedStatus']) {
                                warningModal.show(result['message'], 'Estimates', 'Deleted');
                                $state.transitionTo('loggedin.customer_list.view.property', {
                                    'id': $state.params.id,
                                    'type': $state.params.type
                                }, {reload: true});
                            }
                        } else if (data['id']) {
                            toastBox.show('Row updated', 1500);
                        } else {
                            toastBox.show('Row saved', 1500);
                        }

                        var index = errorRow.indexOf(selectedRow);
                        if (index !== -1) {
                            errorRow.splice(index, 1);
                            var metaData = instance.getCellMetaAtRow(selectedRow);
                            for (var j = 1; j < metaData.length; j++) {
                                instance.setCellMeta(selectedRow, j, "className", "");
                            }
                        }

                        spreadsheetLineItems[selectedRow]['id'] = result.id;
                        $scope.$emit('spreadsheetData:lineItems', [spreadsheetLineItems,hotId]);
                        instance.render();

                    }).error(function (data,status) {
                        errorRow.push(selectedRow);
                        var metaData = instance.getCellMetaAtRow(selectedRow);
                        for (var j = 1; j < metaData.length; j++) {
                            instance.setCellMeta(selectedRow, j, "className", "errorRowList");
                        }
                        instance.render();
                        toastBox.show('Invalid line items',1500);

                    });
                }
            };

            //# Compile the handsontable spreadsheet
            var spreadsheet = $compile('<hot-table\n' +
                '        hot-id="{{category}}"\n' +
                '        settings="{copyPaste: false, afterChange: spreadsheetAfterChange, afterInit: spreadsheetAfterInit, data: spreadsheetLineItems}"\n' +
                '        enter-moves="{row: 0, col: 1}"\n' +
                '        tab-moves ="tabMoves">\n' +
                '</hot-table>')($scope);

            $(element[0]).append(spreadsheet);
        }
    }
}]);

function initSpreadsheetFunction($scope,additional_settings,confirmationBoxHelper,toastBox) {

    var validateErrorRow = false;
    var disableColHeader = '';

    $scope.$emit('spreadsheetData:deleteFunction', {
        'instance': $scope.$parent.spreadsheetInstance,
        'deleteUrl': $scope.deleteUrl
    });

    //# Column hidden from user
    $scope.customHeader = function (index) {
        disableColHeader = '';
        var prop = this.columns[index]['data'];
        var header = this.column_header[index];
        var check = _.where($scope.customisable_spreadsheet_columns, {name: prop});
        if (check.length > 0 && check[0].selected) {
            header = header + '<span  tooltip="Column hidden from customer" tooltip-placement="top" class="ss-ban hidden-column"> </span>';
            disableColHeader = index;
        }
        return header;
    };

    //# Add marker class for required fields
    $scope.requiredFields = function (col, TH) {

        var className = '';
        if(!additional_settings) {
            return false;
        }

        if (disableColHeader) {
            className = "disable-col-header ";
        }
        if (additional_settings.required_column[col]) {
            className += "col_header_bg";
        }

        if (className && TH) {
            TH.className = className;
        }
    };

    $scope.afterSelection = function (r, c, r2, c2, preventScrolling) {
        if (c === 1) {
            this.getSettings().fillHandle = false;
        } else {
            this.getSettings().fillHandle = {direction: 'vertical', autoInsertRow: true};
        }

    };

    //# Function to execute before value render
    $scope.beforeValidate = function (value, row, prop, source) {
        $scope.spreadsheetRowValidation = true;
        if (value != '') {
            var activeEditor = this.getActiveEditor(),
                col = activeEditor.col,
                hotId = this.getSettings().hotId,
                cellType = activeEditor.cellProperties.type;

            var numericField = _.where($scope.numericFields,{prop:prop});

            if(numericField.length > 0) {
                $scope.numericLimitValidator(value, row, col, prop, numericField[0].limit, cellType, hotId);
            }
        }
    };

    //# Update the class name after spreadsheet loaded.
    $scope.scrollingSpreadsheet = function(instance) {
        instance.updateSettings({className: 'overflow_handsontable'});
        instance.render();
    };

    //# If edit the last row then need to insert a new row with default row
    $scope.insertRow = function (instance, changes, src) {
        var selected = instance.getActiveEditor(),
            selectedCol = selected.col,
            selectedRow = selected.row,
            rowVal = instance.getSourceDataAtCell(selectedRow, "1");
        var isRowValidate = !!(selectedCol == 1 && rowVal);


        if (changes != null && changes[0][0] === (instance.countRows() - 1) && src === 'edit' && isRowValidate) {
            instance.alter('insert_row', instance.countRows());
            instance.render();
        }
    };

    //# Numeric limit validate
    $scope.numericLimitValidator = function (value, row, col, prop, limit, cellType, hotId) {

        var isInvalid = false;
        var data = roundFloat(value,0).toString();

        if (isNaN(value)) {
            isInvalid = true;
            toastBox.show('Invalid data and can not saved', 1500);
        } else if (data.length > limit) {
            isInvalid = true;
            toastBox.show('Invalid data length', 1500);
        }

        if (isInvalid) {
            $scope.spreadsheetRowValidation = false;
            $scope.spreadsheetInstance[hotId].setCellMeta(row, col, "className", "errorRowList");
        } else {
            $scope.spreadsheetInstance[hotId].setCellMeta(row, col, "className", "");
        }
        $scope.spreadsheetInstance[hotId].render();

    };

    /** update spreadsheet header columns for 'customer who can see' purpose **/
    $scope.$on('spreadsheet:updateHeaderSettings', function () {
        var hotIds = Object.keys($scope.spreadsheetLineItems);
        angular.forEach(hotIds, function (value) {
            var instance = $scope.$parent.spreadsheetInstance[value];
            instance.render();
            var lastRow = $scope.spreadsheetLineItems[value].length;
            instance.scrollViewportTo(lastRow - 1, 1);
        });
    });
}
