csmodule.controller('JobAddPartCtrl',[
  '$scope',
  '$rootScope',
  '$modalInstance',
  'confirmationBoxHelper',
  'dataSet',
  'prefix',
  '$http',
  '$timeout',
  function($scope, $rootScope, $modalInstance, confirmationBoxHelper, dataSet, prefix, $http, $timeout){
    $scope.taxLabel = $rootScope.taxLabel;
    $scope.formData={};
    console.log('dataSet', dataSet);
    $scope.fields = dataSet.partItems.fields;
    $scope.isDisabled=true;

    $scope.onClose = function() {
      confirmationBoxHelper.hideConfirmationBox();
      confirmationBoxHelper.getConfirmation($rootScope.error_messages.unsaved_sidepanel, $scope)
        .then(function() {
          $modalInstance.dismiss();
          $scope.reset();
        })
    };

    $scope.reset = function() {
      _.forEach($scope.fields, function(field){
        field.error=false;
        if(field.hasOwnProperty('check_exists')) {
          field.exists_error=false;
        }
      });
      $scope.formData={};
    };

    $scope.onSubmit = function() {
      if($scope.isDisabled) {
        return;
      }
      $scope.formData['parttype']=1;
      $http.post(prefix + '/parts_and_prices/add', {partsandprices:$scope.formData, 'requiredFields':['description']})
        .success(function(resp) {
          if(resp.data) {
            $modalInstance.close(resp.data);
            $scope.reset();
          }
        })
    };

    $scope.resetDropdown = function(field) {
      $scope.formData[field.model]='';
      $scope.checkValidation(field);
    };

    $scope.checkValidation = function(field) {
      field.dirty = true;
      _.forEach($scope.fields, function(field){
        if(field.required && $scope.formData[field.model] == '') {
          field.error=true;
          if(field.hasOwnProperty('check_exists')) {
            field.exists_error=false;
          }
          //return;
        }
        else if(field.check_exists && $scope.formData[field.model] != '') {
          field.error=false;
          var _params = field.check_exists.split('|'), params={}, url = prefix+field.check_exists_url;
          _.forEach(_params, function(modelname){
            params[modelname] = $scope.formData[modelname]
          });
          var queryString = serialize(params);
          var comparisionField = _.find($scope.fields, function(f){
            return f.model == field.compareModel;
          });
          $http.get(url+'?'+queryString).success(function(data) {
            if(data=='true') {
              field.exists_error=true;
              if(comparisionField ) {
                comparisionField.exists_error =  false;
              }
            }
            else {
              field.exists_error=false;
              if(comparisionField ) {
                comparisionField.exists_error =  false;
              }
            }
          });
          //return;
        }
        else {
          field.error=false;
          if(field.hasOwnProperty('check_exists')) {
            field.exists_error=false;
          }
        }
      });
      $scope.checkFormSubmit();
    };

    $scope.checkFormSubmit = function() {
      var t=[];
      _.forEach($scope.fields, function(field){
        t.push(field.error);
        if(field.check_exists) {
          t.push(field.exists_error);
        }
      });
      $scope.isDisabled = _.contains(t, true);
    };

    var serialize = function(obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    };

    $scope.onKeyDown = function(e, field) {
      var scale = [7,4];
      if(field.model==='installtime') {
        scale = [7,2];
      }
      else if(field.model==='markup') {
        scale = [3,3];
      }
      // Allow Backspace, tab, end, and home keys
      var key = e.charCode || e.keyCode || 0;
      var isNumeric = ((key == 8) || (key == 9) || (key == 13) || (key == 46)
        || (key == 116) || (key == 123) || ((key >= 35) && (key <= 40))
        || ((key >= 48) && (key <= 57)) || ((key >= 96) && (key <= 105)) || (key == 190 || key == 110) );
      var actionKeys = ((key == 8) || (key == 9) || (key == 46) || (key == 13) || ((key >= 35) && (key <= 40)) );

      if (!isNumeric ) {
        e.preventDefault();
        return;
      }

      if (e.shiftKey ) {
        e.preventDefault();
      }

      var hasDecimal=e.target.value.indexOf('.') !== -1;
      var dotcontains = e.target.value.indexOf(".");
      var newValue = e.target.value;
      var caratPos = e.target.selectionStart;
      if(hasDecimal && (e.keyCode==190 || e.keyCode == 110) ) {  console.log('key', e.keyCode);
        e.preventDefault();
      }

      if(dotcontains > -1) {
        var arrVal = newValue.split(".");
        var scale = caratPos > dotcontains ? scale[1]-1 : scale[0]-1 ;
        var ind = caratPos > dotcontains ? 1 : 0;
        if( arrVal[ind].length > scale && !actionKeys){
          e.preventDefault();
        }
      }
      else{
        newValue.length > scale[0]-1 && !(key == 190 || key == 110) && !actionKeys && e.preventDefault();
      }
    };

    $modalInstance.opened.then(function(isOpened) {
      if (isOpened) {
        $timeout(function(){
          var $element = $(document);
          $element.bind('keydown', function(e){
            if(e.which === 13) {
            }
            if(e.which === 27) {
              $scope.onClose();
            }
          });
          $('body').find('.modal-backdrop').on('click', function(){ console.log('modal-backdrop')
            $scope.onClose();
          });
        },300);
      }
    });
    $scope.$on('$destroy', function(){
      $(document).unbind('keydown');
      $('body').find('.modal-backdrop').off('click');
    });
  }
]);
