csmodule.service('temporaryCookies', function($cookies) {
        return {
                temporaryLocalstorageKeys: [],
                temporaryCookieKeys: [],
                trackCookie: function(cookie) {
                    this.temporaryCookieKeys.push(cookie);
                },
                trackLocalstorage: function(item) {
                    this.temporaryLocalstorageKeys.push(item);
                },
                deleteAll: function() {
                    angular.forEach(this.temporaryCookieKeys, function(cookie, index) {
                        delete $cookies[cookie];
                    }, this);

                    angular.forEach(this.temporaryLocalstorageKeys, function(item, index) {
                        localStorage.removeItem(item);
                    }, this);
                }
        }
    });
